import { IonIcon, IonItem } from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router';
import { CustomButton } from '../../common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import { productFlow } from '../../../store/reducers/lead/leadSlice';
import { useDispatch } from 'react-redux';

export const Sidebar: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const pathName = window.location.pathname;
	const dispatch = useDispatch();
	return (
		<div className='d-none d-md-flex bg-white  pt-3 pe-3 justify-content-start flex-column sidebar-menu-container'>
			<IonItem
				lines={'none'}
				onClick={() => {
					history.push('/dashboard/home');
				}}
				className={
					pathName == '/dashboard/home'
						? 'ms-2  text-blue cursor-pointer mb-1'
						: 'ms-2  text-grey cursor-pointer mb-1'
				}
			>
				<IonIcon
					style={{ width: '15px' }}
					icon={
						pathName == '/dashboard/home'
							? '/images/icons/active-home-tab-icon.svg'
							: '/images/icons/home-tab-icon.svg'
					}
					className='pb-1 me-3'
				/>{' '}
				{t('Home')}
			</IonItem>
			<IonItem
				lines={'none'}
				onClick={() => {
					history.push('/dashboard/lead');
				}}
				className={
					pathName == '/dashboard/lead'
						? 'ms-2  text-blue cursor-pointer mb-1'
						: 'ms-2 text-grey cursor-pointer mb-1'
				}
			>
				<IonIcon
					style={{ width: '15px' }}
					icon={
						pathName == '/dashboard/lead'
							? '/images/icons/active-leads-tab-icon.svg'
							: '/images/icons/leads-tab-icon.svg'
					}
					className='pb-1 me-3'
				/>{' '}
				{t('Leads')}
			</IonItem>
			<IonItem
				className={
					pathName == '/dashboard/products'
						? 'ms-2 text-blue cursor-pointer mb-1'
						: 'ms-2 text-grey cursor-pointer mb-1'
				}
				onClick={() => {
					dispatch(
						productFlow({ is_product: 1, is_lender: 0, lender_show: '0' })
					);
					history.push('/dashboard/products');
				}}
				lines={'none'}
			>
				<IonIcon
					style={{ width: '15px' }}
					icon={
						pathName == '/dashboard/products'
							? '/images/icons/active-products-tab-icon.svg'
							: '/images/icons/products-tab-icon.svg'
					}
					className='pb-1 me-3'
				/>{' '}
				{t('Products')}
			</IonItem>
			<IonItem
				className={
					pathName == '/dashboard/teams'
						? 'ms-2 text-blue cursor-pointer mb-1'
						: 'ms-2 text-grey cursor-pointer mb-1'
				}
				onClick={() => {
					history.push('/dashboard/teams');
				}}
				lines={'none'}
			>
				<IonIcon
					style={{ width: '15px' }}
					icon={
						pathName == '/dashboard/teams'
							? 'images/icons/active-teams-tab-icon.svg'
							: '/images/icons/teams-tab-icon.svg'
					}
					className='pb-1 me-3'
				/>{' '}
				{t('Teams')}
			</IonItem>
			<IonItem
				onClick={() => {
					history.push('/dashboard/add-lead');
				}}
				lines='none'
			>
				<CustomButton
					className={'text-white mt-4 justify-content-center w-100'}
				>
					{' '}
					<span
						className='position-relative fs-24 me-2'
						style={{ top: '-2px' }}
					>
						&#x2b;
					</span>{' '}
					{t('Add')}
				</CustomButton>
			</IonItem>
		</div>
	);
};
