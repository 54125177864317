import React, { useEffect, useState } from 'react';
import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchBureauCustomerReport,
	leadBureauCustomer,
	leadCreationtype,
	leadFlow,
} from '../../store/reducers/lead/leadSlice';
import {
	changeToken,
	dmaFlowData,
	resetUserState,
} from '../../store/reducers/user/userSlice';
import { findCurrentStatus, groupParamsByKey } from '../../helpers/functions';
import {
	LEAD_SCORE_TYPE,
	LEAD_STATUS,
	LeadStatus,
} from '../../helpers/constant';
import { webView } from '../../store/reducers/app/appSlice';
import {
	CUSTOMER_SERVICE,
	DASHBOARD_LEAD_DETAILS,
	DASHBOARD_LEAD_DETAILS_UM,
	PRODUCT_LIST,
} from '../../helpers/api-endpoints';
import axios from 'axios';

export const CustomerService: React.FC = () => {
	const dispatch: any = useDispatch();
	const { token }: any = useParams();
	const history = useHistory();
	const [lead, setLead] = useState<any>([]);
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	const { leadcreatedthrough } = useSelector((state: any) => state?.lead);

	const getFetchBureauCustomerReportData = useSelector(
		(state: any) => state?.lead?.fetchBureauCustomerReportData
	);
	const leadBureauCustomer1 = useSelector(
		(state: any) => state?.lead?.leadBureauCustomerData
	);

	useEffect(() => {
		const url: any = window.location.href.split('=')[1];
		const url1: any = new URLSearchParams(window.atob(url));
		const param: any = groupParamsByKey(url1);
		if (param?.check_score) {
			dispatch(
				leadCreationtype({
					...leadcreatedthrough,
					leadCreationtype: 2,
				})
			);
		} else {
			dispatch(
				leadCreationtype({
					...leadcreatedthrough,
					leadCreationtype: 4,
				})
			);
		}
		callFunc();
	}, [token]);

	const callFunc = async () => {
		dispatch(resetUserState());
		let url: any = window.location.href.split('=')[1];
		//const payload = window.location.href.split('?')[1];
		url = window.atob(url);
		const params = new URLSearchParams(url);
		const output: any = groupParamsByKey(params);
		if (
			typeof output['reporting_manager'] === 'string' ||
			typeof output['campaign_city'] === 'string'
		) {
			Object.keys(output).forEach((item: any) => {
				if (
					['reporting_manager', 'campaign_city'].includes(item) &&
					typeof output[item] === 'string'
				) {
					output[item] = [output[item]];
				}
			});
		}

		let SubProductName: any;
		let productIndex: any;
		let productName: any;
		let subProductIndex: any;
		let purposeName: any;
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		let score_type: any;
		if (window.innerWidth > 768) {
			dispatch(webView(true));
		} else {
			dispatch(webView(false));
		}

		if (output?.campaign_type === 'dma') {
			dispatch(dmaFlowData(output));
			history.push('/login');
			return false;
		}

		const webURL = process.env.REACT_APP_API_ENDPOINT;

		dispatch(leadFlow({}));
		dispatch(
			leadBureauCustomer({
				...leadBureauCustomer1,
				scoreType: output?.score_type,
				reSendCibilOtp: false,
				type: 'VERIFY_LEAD',
			})
		);
		axios
			.post(`${webURL + CUSTOMER_SERVICE}`, {
				d: window.btoa(JSON.stringify(output)),
			})
			.then(async (result: any) => {
				if (result?.data.status) {
					if (result?.data.access_token) {
						axios.defaults.headers.common.Authorization =
							'Bearer ' + result?.data.access_token;

						dispatch(changeToken(result?.data?.access_token));
						if (output?.product_id) {
							axios.get(`${webURL + PRODUCT_LIST}`).then((response: any) => {
								if (response?.data?.status) {
									const findProductIndex =
										response?.data?.product_list?.findIndex(
											(el: any) => el?.id == output?.product_id
										);
									const findProduct = response?.data?.product_list?.find(
										(el: any) => el?.id == output?.product_id
									);

									productIndex = findProductIndex;
									const findsubproduct: any = response?.data?.product_list?.[
										findProductIndex
									]?.category?.find((el: any) => {
										return el?.cat_id == output?.product_category_id;
									});
									productIndex = findProductIndex;
									const findsubproductIndex = response?.data?.product_list?.[
										findProductIndex
									]?.category?.findIndex((el: any) => {
										return el?.cat_id == output?.product_category_id;
									});

									purposeName = findsubproduct?.purpose?.find(
										(el: any) => el?.purpose_id == output.category_purpose_id
									)?.name;
									productName = findProduct?.name;
									subProductIndex = findsubproductIndex;
									SubProductName = findsubproduct?.name;
									score_type = findsubproduct?.score_type;
								}
							});
						}
						if (output?.lead_id) {
							axios
								.get(
									`${webURL + DASHBOARD_LEAD_DETAILS}` +
										`${output?.lead_id}${
											leadInfo?.lead_app_id
												? '&lead_application_id=' + leadInfo?.lead_app_id
												: ''
										}`
								)
								.then(async (response: any) => {
									if (response?.data?.status) {
										setLead(response?.data?.leads);

										let status = '';
										if (leadInfo?.from === 'nimbuss') {
											status = findCurrentStatus(
												response?.data?.leads?.lead_status
											).label;
										} else {
											status = leadInfo?.leadStatus;
										}

										axios
											.get(
												`${webURL + DASHBOARD_LEAD_DETAILS_UM}` +
													`/${output?.lead_id}`
											)
											.then((result: any) => {
												if (result?.data.status) {
													let headerTitle = '';
													let step = '';
													let path = '';
													let needArrow = true;
													let needCross = false;
													switch (response?.data?.leads?.lead_status) {
														case LeadStatus.GENERATED:
															headerTitle = 'Add Lead';
															needArrow = true;
															needCross = false;
															step = 'step2';
															break;
														case LeadStatus.BUREAU:
															step =
																response?.data?.leads?.scope_type?.toLocaleLowerCase() ===
																LEAD_SCORE_TYPE.CBIL.toLocaleLowerCase()
																	? 'step4'
																	: response?.data?.leads?.scope_type?.toLocaleLowerCase() ===
																	  LEAD_SCORE_TYPE.EQUIFAX.toLocaleLowerCase()
																	? 'step3'
																	: response?.data?.leads?.scope_type?.toLocaleLowerCase() ===
																	  LEAD_SCORE_TYPE.BOTH.toLocaleLowerCase()
																	? 'step3'
																	: '';
															needArrow = false;
															needCross = true;
															break;
														case LeadStatus.PROCESSING:
															step = 'step5';
															break;
														case LeadStatus.SELECTION:
															step = 'step6';
															break;
														case LeadStatus.APPLICATIONSFULFILLED:
															step = 'step7';

															break;
													}
													// nimbuss flow do not touch or remove the code in
													if (
														response?.data.leads?.product_id === null &&
														response?.data.leads?.lead_status ===
															LeadStatus.GENERATED
													) {
														path = '/select-lead-product';
													} else if (
														response?.data.leads?.product_id === null &&
														response?.data.leads?.scope_type === ''
													) {
														path = '/select-lead-product';
													}
													if (response?.data.leads?.product_id === 1) {
														switch (step) {
															case 'step5':
																path = '/professional-info';
																break;
															case 'step6':
																path = response?.data.leads?.isLenderSelected
																	? '/selected-lender-list'
																	: '/lenders-list';
																break;
															case 'step7':
																path = '/lender-specific-info';
																break;
															default:
																path = '/loan-lead-info-steps';
																break;
														}
													}
													if (response?.data.leads?.product_id === 2) {
														switch (step) {
															case 'step5':
																path = '/professional-info';
																break;
															case 'step6':
																path = lead?.isLenderSelected
																	? '/selected-lender-list'
																	: '/lenders-list';
																break;
															default:
																path = '/insurance-lead-info-steps';
																break;
														}
													}

													const myPromise = new Promise(function (myResolve) {
														setTimeout(function () {
															myResolve(history.push(path));
														}, 10);
													});

													myPromise.then(async () => {
														dispatch(
															fetchBureauCustomerReport({
																...getFetchBureauCustomerReportData,
																lead_type: 'Lead',
																lead_id: output?.lead_id,
																lead_status: findCurrentStatus(
																	response?.data?.leads?.lead_status
																).label,
																mobile_no: result.data.data.mobile_no,
																id: leadInfo?.leadId,
															})
														);
														dispatch(
															leadFlow({
																...response?.data?.leads,
																selectedProductData: {
																	product_category_id:
																		response?.data?.leads?.product_category_id,
																},
																headerTitle,
																step: step,
																leadId: response?.data.leads?.id,
																leadMobile: result.data.data.mobile_no,
																mobile_no: result.data.data.mobile_no,
																needArrow,
																needCross,
																via: 'share',
																from: 'customer',
																appsToUpdate: [],
																selectedLender: [],
																lead_consent_type: 'Lead',
																scoreType:
																	response?.data.leads?.scope_type ||
																	output?.score_type,
																subHeaderStepsCounter:
																	lead?.lead_status === LeadStatus.BUREAU
																		? false
																		: true,
																updateLeadProcess:
																	leadInfo?.updateLeadProcess + 1 || 1,
																isNewLead: false,
																iSBackFromDsburseAndSanction:
																	findCurrentStatus(
																		response?.data?.leads?.lead_status
																	).label ===
																		LEAD_STATUS.APPLICATION_FULFILLED ||
																	findCurrentStatus(
																		response?.data?.leads?.lead_status
																	).label === LEAD_STATUS.ALL
																		? true
																		: false,
																leadCurrentStep: step,
																isLenderSelected:
																	response?.data.leads?.isLenderSelected,
																isBackLenderOnlineForm: false,
																leadStatus: status,
																leadTypeId: leadInfo?.leadTypeId,

																// productType: selectedProductName,
															})
														);
													});
												}
											});
									}
								});
						} else {
							setTimeout(() => {
								dispatch(
									fetchBureauCustomerReport({
										...getFetchBureauCustomerReportData,
										scoreType: output?.score_type,
										type: 'VERIFY_LEAD',
									})
								);
								dispatch(
									leadFlow({
										...leadInfo,
										from: 'customer',
										via: 'share',
										selectedProductData: {
											product_category_id: output.product_category_id,
										},
										leadId: result?.data?.leadId,
										productId: output?.product_id,
										productIndex: productIndex,
										productName: productName,
										purposeId: output.category_purpose_id,
										purposeName: purposeName,
										subProductId: output.product_category_id,
										subProductIndex: subProductIndex,
										subProductName: SubProductName,
										leadSource: output.source,
										leadSubSource: output.sub_source,
										scoreType: output?.score_type || score_type,
										step: 'step1',
										leadCurrentStep: 'step1',
										lead_consent_type: 'Lead',
										headerTitle: 'Add Lead',
										needArrow: true,
										needCross: true,
										subHeaderStepsCounter: true,
									})
								);
							}, 500);
							setTimeout(() => {
								if (output?.check_score == 'true') {
									dispatch(
										leadCreationtype({
											...leadcreatedthrough,
											leadCreationtype: 2,
										})
									);
									history.push('/add-bureau-score');
								} else {
									if (
										output.product_id &&
										output.product_category_id &&
										output.category_purpose_id &&
										purposeName?.toLowerCase() !== 'others'
									) {
										history.push('/loan-lead-info-steps');
									} else {
										dispatch(
											leadCreationtype({
												...leadcreatedthrough,
												leadCreationtype: 4,
											})
										);
										history.push('/select-lead-product');
									}
								}
							}, 2000);
						}
					}
				}
			});
	};

	return (
		<IonPage>
			<IonContent className='border'>
				<div
					className='position-fixed  w-100  d-flex justify-content-center align-items-center h-100'
					style={{
						top: '0px',
						left: '50%',
						transform: 'translate(-50%,0%)',
						backgroundColor: 'rgba(0,0,0,0.2)',
					}}
				>
					<div className='d-flex gap-4 justify-content-center pt-5  align-items-center flex-column'>
						<IonSpinner name='lines-sharp' />
						<h6 className='text-primary text-center'>
							Please wait while we are proceeding you to the next step...
						</h6>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};
