import React, { useEffect, useRef, useState } from 'react';
import {
	IonButtons,
	IonContent,
	IonImg,
	IonPage,
	//IonSearchbar,
} from '@ionic/react';
import { Header } from '../../components/layout/header/Header';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import './filter.scss';
import { CustomCheckbox } from '../../components/common/custom-checkbox/CustomCheckbox';
import { useDispatch, useSelector } from 'react-redux';

import { CustomInput } from '../../components/common/custom-input/CustomInput';
import {
	filterStatus,
	filterSubProducts,
	getAllLenders,
	leadFilter,
	leadFlow,
	resetLeadFilterState,
} from '../../store/reducers/lead/leadSlice';
import {
	filterCities,
	filterReportingManagerList,
} from '../../store/reducers/dmaSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { Typeahead } from 'react-bootstrap-typeahead';
import { USER_ROLES } from '../../helpers/constant';
import { showToastAlert } from '../../helpers/functions';
//import { LEAD_STATUS } from '../../helpers/constant';

export const LeadFilters: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch: any = useDispatch();
	const role = useSelector((state: any) => state?.users?.user_details);
	//eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [followDate, setFollowDate] = useState<any>('');
	const leadFilterSlctr: any = useSelector((state: any) => state?.lead);
	const [name1, setName] = useState('followUpDate');
	const [new1, setNew1] = useState<any>({});
	const [cities, setCities] = useState([]);
	const [subProducts, setSubProducts] = useState([]);
	const [statuses, setStatuses] = useState([]);
	const [lenders, setLenders] = useState([]);
	const [dmaOwners, setDmaOwners] = useState([]);
	const [citySelection, setCitySelection] = useState([]);
	const [subProductsSelection, setSubProductsSelection] = useState([]);
	const [lendersSelection, setLendersSelection] = useState([]);
	const [dmaOwnerSelection, setDmaOwnerSelection] = useState([]);
	//const [statusesSelection, setStatusesSelection] = useState([]);
	const [selectedButton, setSelectedButton] = useState(0);
	const [isFilterValid, setFilterValid] = useState(true);
	const [lastUpdatedFromDate, setLastUpdatedFromDate] = useState('');
	const [lastUpdatedToDate, setLastUpdatedToDate] = useState('');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [updateCount, setUpdateCount] = useState(
		leadFilterSlctr.updateLeadFilter
	);

	const buttonsList = [
		{
			id: 1,
			value: 'Follow Up Date',
			key: 'followUpDate',
			visible: true,
		},
		{
			id: 2,
			value: 'Last Updated Date',
			key: 'lastUpdatedDate',
			visible: true,
		},
		{ id: 3, value: 'Locations (Cities)', key: 'locationType', visible: true },
		{ id: 4, value: 'Sub Product', key: 'subproduct', visible: true },
		{ id: 5, value: 'Status', key: 'status', visible: true },
		{ id: 6, value: 'Lender', key: 'lender', visible: true },
		{
			id: 7,
			value: 'DMA',
			key: 'dmaOwner',
			visible: [USER_ROLES.DMA].includes(role?.roleType?.[0]) ? false : true,
		},
	];

	const filterTabs = [
		{
			name: 'followUpDate',
			type: 'date',
			optionList: [],
		},
		{
			name: 'lastUpdatedDate',
			type: 'daterange',
			optionList: [],
		},
		{
			name: 'locationType',
			type: 'autocomplete',
			optionList: [],
		},
		{
			name: 'subproduct',
			type: 'autocomplete',
			optionList: [],
		},
		{
			name: 'status',
			type: 'checkbox',
			optionList: new1?.status,
		},
		{
			name: 'lender',
			type: 'autocomplete',
			optionList: [],
		},
		{
			name: 'dmaOwner',
			type: 'autocomplete',
			optionList: [],
		},
	];
	//const [buttonNames, setButtonNames] = useState<any>([]);

	//const [filterData, setFilterData] = useState<any>([]);

	const getLeadFilter = async (data: any, fieldKey: any) => {
		let leadFltr = '';
		/* 	const leadFltr = new1?.[name1]
			?.filter(({ value, checked }: any) => checked && value)
			.map(({ value }: any) => value)
			.toString()
			.toLowerCase(); */
		if (data) {
			leadFltr = data;
		} else {
			leadFltr = new1?.[name1]
				?.filter(({ value, checked }: any) => checked && value)
				.map(({ value, id }: any) =>
					[
						'locationType',
						'subproduct',
						'lender',
						'status',
						'dmaOwner',
					].includes(name1)
						? id
						: value
				)
				.toString()
				.toLowerCase();
		}
		await dispatch(
			leadFilter({
				...leadFilterSlctr,
				[fieldKey]: leadFltr,
			})
		);
	};

	const handleFilter = (e: any, name: any, data: any) => {
		if (e) {
			const data1 = new1?.[name]?.map((i: any) => {
				if (i.value === data) {
					return {
						...i,
						checked: true,
					};
				} else {
					return { ...i };
				}
			});
			setNew1({ ...new1, [name]: data1 });
		} else {
			const data1 = new1?.[name]?.map((i: any) => {
				if (i.value === data) {
					return {
						...i,
						checked: false,
					};
				} else {
					return { ...i };
				}
			});
			setNew1({ ...new1, [name]: data1 });
		}
	};

	const resetCheckBoxes = (name: any) => {
		const data1 = new1?.[name]?.map((i: any) => {
			return {
				...i,
				checked: false,
			};
		});
		setNew1({ ...new1, [name]: data1 });
	};
	const cityRef: any = useRef();
	const lenderRef: any = useRef();
	const dmaOwnerRef: any = useRef();
	const subProductRef: any = useRef();

	useEffect(() => {
		if (leadFilterSlctr.followUpDate === '') {
			setFollowDate('');
		}
		if (leadFilterSlctr.locationType === '') {
			setCitySelection([]);
			cityRef?.current?.clear();
		}
		if (leadFilterSlctr.dmaOwner === '') {
			setDmaOwnerSelection([]);

			dmaOwnerRef.current?.clear();
		}
		if (leadFilterSlctr.lender === '') {
			setLendersSelection([]);
			lenderRef?.current?.clear();
		}
		if (leadFilterSlctr.fromDate === '') {
			setLastUpdatedFromDate('');
		}
		if (leadFilterSlctr.toDate === '') {
			setLastUpdatedToDate('');
		}
		if (leadFilterSlctr.subproduct === '') {
			setSubProductsSelection([]);
			subProductRef?.current?.clear();
		}
	}, [leadFilterSlctr]);

	useEffect(() => {
		if (leadFilterSlctr.locationType === '') {
			setNew1({
				locationType: [],
				subproduct: subProducts,
				status: statuses,
				lender: lenders,
				dmaOwner: dmaOwners,
			});
		}
		if (leadFilterSlctr.dmaOwner === '') {
			setNew1({
				locationType: citySelection,
				subproduct: subProducts,
				status: statuses,
				lender: lenders,
				dmaOwner: [],
			});
		}
		if (leadFilterSlctr.lender === '') {
			setNew1({
				locationType: citySelection,
				subproduct: subProducts,
				status: statuses,
				lender: [],
				dmaOwner: dmaOwnerSelection,
			});
		}

		if (leadFilterSlctr.subproduct === '') {
			setNew1({
				locationType: citySelection,
				subproduct: [],
				status: statuses,
				lender: lenders,
				dmaOwner: dmaOwnerSelection,
			});
		}
	}, []);

	const clearFilter = () => {
		setFollowDate('');
		setCitySelection([]);
		cityRef?.current?.clear();
		dmaOwnerRef.current?.clear();
		lenderRef?.current?.clear();
		subProductRef?.current?.clear();
		setSubProductsSelection([]);
		setLendersSelection([]);
		setDmaOwnerSelection([]);
		setSelectedButton(0);
		setName('');
		dispatch(resetLeadFilterState());
		resetCheckBoxes('status');
		setFilterValid(true);
		setLastUpdatedFromDate('');
		setLastUpdatedToDate('');
		dispatch(leadFlow({ ...leadInfo, clearLeadFilter: true }));
	};
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);

	const handleBackButton = () => {
		const myPromise = new Promise(function (myResolve) {
			setTimeout(function () {
				myResolve(history.goBack()); //history.push('/dashboard/lead')
			}, 10);
		});
		myPromise.then(() => {
			dispatch(resetLeadFilterState());
			setCitySelection([]);
			setSubProductsSelection([]);
			setLendersSelection([]);
			setDmaOwnerSelection([]);
			setSelectedButton(0);
			setName('');
			resetCheckBoxes('status');
			setFilterValid(true);
			setLastUpdatedFromDate('');
			setLastUpdatedToDate('');
			dispatch(leadFlow({ ...leadInfo, clearLeadFilter: true }));
		});
	};

	useEffect(() => {
		if (location.pathname !== '/lead-filter') {
			return;
		}

		if (
			leadFilterSlctr.leadFlowData.updateLeadFilter === true ||
			leadFilterSlctr.leadFlowData.clearLeadFilter === true
		) {
			getFilterData();

			setTimeout(() => {
				dispatch(
					leadFlow({
						...leadInfo,
						updateLeadFilter: false,
						clearLeadFilter: false,
					})
				);
			}, 1500);
		}

		/*if (leadFilterSlctr?.leadFlowData?.applicationStatus === LEAD_STATUS.ALL) {
			const newButtonList = buttonsList;
			newButtonList.push({
				id: 1,
				value: 'Follow Up Date',
				key: 'followUpDate',
			});
			setButtonNames(newButtonList);

			const newFilterTabs = filterTabs;
			newFilterTabs.push({
				name: 'followUpDate',
				type: 'date',
				optionList: [],
			});
			setFilterData(newFilterTabs);
		}
		if (
			![
				LEAD_STATUS.ALL,
				LEAD_STATUS.RAW,
				LEAD_STATUS.GENERATED,
				LEAD_STATUS.BUREAU,
				LEAD_STATUS.PROCESSING,
				LEAD_STATUS.SELECTION,
			].includes(leadFilterSlctr?.leadFlowData?.applicationStatus)
		) {
			const newButtonList = buttonsList;
			newButtonList.push({ id: 6, value: 'Lender', key: 'lender' });
			setButtonNames(newButtonList);

			const newFilterTabs = filterTabs;
			newFilterTabs.push({
				name: 'lender',
				type: 'autocomplete',
				optionList: [],
			});
			setFilterData(newFilterTabs);
		} else {
			setButtonNames(buttonsList);
			setFilterData(filterTabs);
		}*/
	}, [location.pathname]);

	const getFilterData = async () => {
		await dispatch(filterCities({ param: '' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result.status) {
					const data: any = [];
					result?.data?.forEach((el: any) => {
						if (!['unknown'].includes(el?.name?.toLowerCase())) {
							data.push({
								id: el?.id,
								name: el?.name,
								value: el?.name,
								label: el?.name,
								checked: false,
							});
						}
					});
					setCities(data);
				}
			});

		await dispatch(filterSubProducts({ param: '' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result.status) {
					const data = result?.list?.map((el: any) => {
						return {
							id: el?.id,
							name: el?.category_name,
							value: el?.category_name,
							label: el?.category_name,
							checked: false,
						};
					});
					setSubProducts(data);
				}
			});

		await dispatch(filterStatus({ param: '' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result.status) {
					const data = result?.lead_status?.map((el: any) => {
						return {
							id: el?.id,
							name: el?.name,
							value: el?.name,
							checked: false,
						};
					});
					setStatuses(data);
				}
			});

		await dispatch(getAllLenders({ param: '' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result.status) {
					const data = result?.lenderData?.map((el: any) => {
						return {
							id: el?.id,
							name: el?.name,
							value: el?.name,
							label: el?.name,
							checked: false,
						};
					});
					setLenders(data);
				}
			});

		if (![USER_ROLES.DMA, USER_ROLES.DMAUL].includes(role?.roleType?.[0])) {
			await dispatch(
				filterReportingManagerList({
					param: '?manager_type=7&reporting_id=' + role?.userId,
				})
			)
				.then(unwrapResult)
				.then((result: any) => {
					if (result?.status) {
						const data = result?.list?.map((el: any) => {
							return {
								id: el?.id,
								name: el?.value,
								value: el?.value,
								label: el?.value,
								checked: false,
							};
						});
						setDmaOwners(data);
					}
				});
		} else {
			setDmaOwners([]);
		}
	};

	useEffect(() => {
		setNew1({
			locationType: cities,
			subproduct: subProducts,
			status: statuses,
			lender: lenders,
			dmaOwner: dmaOwners,
		});
	}, [
		lenders,
		statuses,
		subProducts,
		cities,
		dmaOwners,
		leadFilterSlctr.leadFlowData.updateLeadFilter,
		leadFilterSlctr.leadFlowData.clearLeadFilter,
	]);

	useEffect(() => {
		if (!['followUpDate'].includes(name1)) {
			getLeadFilter('', name1);
		}
	}, [new1]);

	useEffect(() => {
		const cityIds = citySelection?.map((t: any) => {
			return t?.id;
		});
		const updateCheckedCities = new1?.locationType?.map((item: any) => {
			return cityIds?.includes(item?.id)
				? {
						id: item?.id,
						name: item?.name,
						value: item?.value,
						label: item?.label,
						checked: true,
				  }
				: {
						id: item?.id,
						name: item?.name,
						value: item?.value,
						label: item?.label,
						checked: false,
				  };
		});

		setNew1({
			...new1,
			locationType: updateCheckedCities,
		});
	}, [citySelection]);
	useEffect(() => {
		const spIds = subProductsSelection?.map((t: any) => {
			return t?.id;
		});
		const updateCheckedSps = new1?.subproduct?.map((item: any) => {
			return spIds?.includes(item?.id)
				? {
						id: item?.id,
						name: item?.name,
						value: item?.value,
						label: item?.label,
						checked: true,
				  }
				: {
						id: item?.id,
						name: item?.name,
						value: item?.value,
						label: item?.label,
						checked: false,
				  };
		});

		setNew1({
			...new1,
			subproduct: updateCheckedSps,
		});
	}, [subProductsSelection]);
	useEffect(() => {
		const spIds = lendersSelection?.map((t: any) => {
			return t?.id;
		});
		const updateCheckedLds = new1.lender?.map((item: any) => {
			return spIds?.includes(item?.id)
				? {
						id: item?.id,
						name: item?.name,
						value: item?.value,
						label: item?.label,
						checked: true,
				  }
				: {
						id: item?.id,
						name: item?.name,
						value: item?.value,
						label: item?.label,
						checked: false,
				  };
		});

		setNew1({
			...new1,
			lender: updateCheckedLds,
		});
	}, [lendersSelection]);

	useEffect(() => {
		const dmaIds = dmaOwnerSelection?.map((t: any) => {
			return t?.id;
		});
		const updateCheckedDmas = new1.dmaOwner?.map((item: any) => {
			return dmaIds?.includes(item?.id)
				? {
						id: item?.id,
						name: item?.name,
						value: item?.value,
						label: item?.label,
						checked: true,
				  }
				: {
						id: item?.id,
						name: item?.name,
						value: item?.value,
						label: item?.label,
						checked: false,
				  };
		});

		setNew1({
			...new1,
			dmaOwner: updateCheckedDmas,
		});
	}, [dmaOwnerSelection]);

	useEffect(() => {
		setFollowDate('');
		setCitySelection([]);
		setSubProductsSelection([]);
		setLendersSelection([]);
		setSelectedButton(0);
		setName('');
		resetCheckBoxes('status');
		setFilterValid(true);
		setLastUpdatedFromDate(leadFilterSlctr?.fromDate);
		setLastUpdatedToDate(leadFilterSlctr?.toDate);
	}, [leadFilterSlctr?.leadFlowData?.applicationStatus]);

	return (
		<IonPage>
			<Header
				backButton={true}
				needArrow={true}
				needArrowOnClick={() => {
					handleBackButton();
				}}
				needArrowImg={'/images/icons/backarrow_white_bg.png'}
				needArrowImgStyle={{ height: '26px' }}
				name={t('Filters')}
				endItem={
					<IonButtons slot='end'>
						<IonButtons
							onClick={clearFilter}
							className='fs-14 fw-600 cursor-pointer'
							style={{ color: 'var(--ion-color-primary)' }}
						>
							{t('Clear Filter')}
						</IonButtons>
					</IonButtons>
				}
			/>
			<IonContent className='border'>
				<div className='row h-100'>
					<div className='col-5 pe-0'>
						{buttonsList.map((buttonName: any, index: any) => (
							<button
								key={index}
								className={`filterName btn-primary m-1 fs-14 fw-600 ${
									buttonName.visible ? '' : ' d-none '
								} ${selectedButton === index ? 'selected' : ' '}`}
								style={{
									backgroundColor:
										selectedButton === index ? 'white' : '#f1f1f1',
									color: selectedButton === index ? '#0165E1' : '#0C0E2B',
									border: 'none',
									borderRadius: '0',
								}}
								onClick={() => {
									if (isFilterValid) {
										setSelectedButton(index);
										setName(buttonName.key);
									}
								}}
							>
								{t(buttonName?.value)}
							</button>
						))}
					</div>
					<div className='col-7 ps-0'>
						<div className='Flex ps-3 pe-2'>
							{/*!['followUpDate', 'lastUpdatedDate'].includes(name1) && (
								<IonSearchbar
									color={'white'}
									className='searchbar me-3 p-2 fs-14 text-grey'
									placeholder='Search'
								/>
							)*/}
							{name1 === 'locationType' && (
								<Typeahead
									id={'basic-typeahead-multiple-locationType'}
									labelKey={'name'}
									ref={cityRef}
									multiple
									className='pe-3 mt-3'
									onChange={(e: any) => {
										setCitySelection(e);
									}}
									options={
										new1?.locationType?.filter((i: any) => !i.checked) || []
									}
									placeholder={t('Choose Cities').toString() + ' ...'}
									selected={citySelection || []}
									paginationText={t('Load More Cities')}
								/>
							)}
							{['subproduct'].includes(name1) && (
								<Typeahead
									id={'basic-typeahead-multiple-subproduct'}
									labelKey={'name'}
									ref={subProductRef}
									multiple
									className='pe-3 mt-3'
									onChange={(e: any) => {
										setSubProductsSelection(e);
									}}
									options={
										new1?.subproduct?.filter((i: any) => !i.checked) || []
									}
									placeholder={t('Choose Sub Products').toString() + ' ...'}
									selected={subProductsSelection || []}
									paginationText={t('Load More Sub Products')}
								/>
							)}
							{['lender'].includes(name1) && (
								<Typeahead
									id={'basic-typeahead-multiple-lender'}
									labelKey={'name'}
									ref={lenderRef}
									multiple
									className='pe-3 mt-3'
									onChange={(e: any) => {
										setLendersSelection(e);
									}}
									options={new1?.lender?.filter((i: any) => !i.checked) || []}
									placeholder={t('Choose Lenders').toString() + ' ...'}
									selected={lendersSelection || []}
									paginationText={t('Load More Lenders')}
								/>
							)}
							{['dmaOwner'].includes(name1) && (
								<Typeahead
									id={'basic-typeahead-single-dmaOwner'}
									labelKey={'name'}
									ref={dmaOwnerRef}
									className='pe-3 mt-3'
									onChange={(e: any) => {
										name1 === 'dmaOwner' ? setDmaOwnerSelection(e) : '';
									}}
									options={
										name1 === 'dmaOwner'
											? new1?.dmaOwner?.filter((i: any) => !i.checked)
											: []
									}
									placeholder={
										name1 === 'dmaOwner' ? t('Choose DMA') + ' ...' : ''
									}
									selected={name1 === 'dmaOwner' ? dmaOwnerSelection : []}
									paginationText={
										name1 === 'dmaOwner' ? t('Load More DMAs') : ''
									}
								/>
							)}
							{filterTabs?.map((filter: any, index: any) => {
								return (
									<div
										key={index}
										className={`filter-container ${
											selectedButton === index ? 'show' : 'hide'
										}`}
									>
										<div
											className={`filterList ${
												selectedButton === index ? 'selected' : ''
											}`}
											onClick={() => setSelectedButton(index)}
											style={{
												cursor: 'pointer',
												fontWeight:
													selectedButton === index ? 'bold' : 'normal',
											}}
										>
											{/* <span className='filterName'>{filter.Name}</span> */}
										</div>
										{selectedButton === index && (
											<div className='option-list ps-0'>
												{filter.type === 'date' && (
													<CustomInput
														placeholder={t('Date')}
														type={'date'}
														needSuffixIcon={true}
														suffixClassName={
															'position-absolute date-of-birth-sufix'
														}
														suffixText={
															<IonImg
																src='/images/icons/input-dob-calendar-icon.svg'
																alt='dob'
															/>
														}
														value={leadFilterSlctr.followUpDate}
														onChange={(e: any) => {
															//setName(filter.name);
															//setFollowDate(e.detail.value);
															if (e.detail.value) {
																if (
																	new Date(e.detail.value).getFullYear() < 9999
																) {
																	getLeadFilter(e.detail.value, 'followUpDate');
																} else {
																	showToastAlert({
																		type: 'error',
																		message: 'Please enter valid date',
																	});
																	getLeadFilter('', 'followUpDate');
																}
															} else {
																getLeadFilter('', 'followUpDate');
															}
														}}
														onKeyDown={(evt: any) => {
															if (filter.name === 'followUpDate') {
																evt.preventDefault();
															}
														}}
														outerClassName={`${'position-relative'}`}
													/>
												)}
												{filter.type === 'daterange' && (
													<>
														<CustomInput
															placeholder={t('From Date')}
															type={'date'}
															needSuffixIcon={true}
															suffixClassName={
																'position-absolute date-of-birth-sufix'
															}
															suffixText={
																<IonImg
																	src='/images/icons/input-dob-calendar-icon.svg'
																	alt='dob'
																/>
															}
															value={lastUpdatedFromDate}
															onChange={(e: any) => {
																if (!lastUpdatedToDate && !e.detail.value) {
																	setFilterValid(true);
																} else if (
																	lastUpdatedToDate &&
																	e.detail.value
																) {
																	setFilterValid(true);
																} else {
																	setFilterValid(false);
																}

																if (
																	new Date(e.detail.value) >
																	new Date(
																		new Date().toISOString().split('T')[0]
																	)
																) {
																	if (lastUpdatedToDate) {
																		setFilterValid(false);
																	}
																	showToastAlert({
																		type: 'error',
																		message: 'Future date not allowed',
																	});
																	setLastUpdatedFromDate('');
																	getLeadFilter('', 'fromDate');
																} else if (
																	lastUpdatedToDate &&
																	new Date(e.detail.value) >
																		new Date(lastUpdatedToDate)
																) {
																	setFilterValid(false);
																	showToastAlert({
																		type: 'error',
																		message: 'Future date not allowed',
																	});
																	setLastUpdatedFromDate('');
																	getLeadFilter('', 'fromDate');
																} else {
																	setLastUpdatedFromDate(e.detail.value);
																	getLeadFilter(e.detail.value, 'fromDate');
																}
															}}
															outerClassName={`${'position-relative'}`}
															max={
																leadFilterSlctr.toDate
																	? leadFilterSlctr.toDate
																	: new Date().toISOString().split('T')[0]
															}
															onKeyDown={(e: any) => e.preventDefault()}
														/>

														<CustomInput
															placeholder={t('To Date')}
															type={'date'}
															needSuffixIcon={true}
															suffixClassName={
																'position-absolute date-of-birth-sufix'
															}
															suffixText={
																<IonImg
																	src='/images/icons/input-dob-calendar-icon.svg'
																	alt='dob'
																/>
															}
															value={lastUpdatedToDate}
															onChange={(e: any) => {
																if (!lastUpdatedFromDate && !e.detail.value) {
																	setFilterValid(true);
																} else if (
																	lastUpdatedFromDate &&
																	e.detail.value
																) {
																	setFilterValid(true);
																} else {
																	setFilterValid(false);
																}

																if (
																	new Date(e.detail.value) >
																	new Date(
																		new Date().toISOString().split('T')[0]
																	)
																) {
																	if (lastUpdatedFromDate) {
																		setFilterValid(false);
																	}
																	showToastAlert({
																		type: 'error',
																		message: 'Future date not allowed',
																	});
																	setLastUpdatedToDate('');
																	getLeadFilter('', 'toDate');
																} else if (
																	lastUpdatedFromDate &&
																	new Date(e.detail.value) <
																		new Date(lastUpdatedFromDate)
																) {
																	setFilterValid(false);
																	showToastAlert({
																		type: 'error',
																		message: 'Future date not allowed',
																	});
																	setLastUpdatedToDate('');
																	getLeadFilter('', 'toDate');
																} else {
																	setLastUpdatedToDate(e.detail.value);
																	getLeadFilter(e.detail.value, 'toDate');
																}
															}}
															outerClassName={`${'position-relative'}`}
															min={leadFilterSlctr.fromDate}
															max={new Date().toISOString().split('T')[0]}
															onKeyDown={(e: any) => e.preventDefault()}
														/>
													</>
												)}
												{filter?.optionList?.map((option: any) => {
													return (
														<div
															className='subData mb-2'
															key={option.id}
														>
															<CustomCheckbox
																name={filter.type}
																onChange={(e: any) => {
																	//setName(filter?.name);
																	handleFilter(
																		e.detail.checked,
																		filter?.name,
																		option.value
																	);
																}}
																value={option.value}
																isChecked={option.checked}
																labelPlacement={'end'}
															>
																<label className=' fs-14 subData darkest-grey'>
																	{option.value}
																</label>
															</CustomCheckbox>
														</div>
													);
												})}
											</div>
										)}
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</IonContent>
			<div className='footer border'>
				<div></div>
				<div>
					<button
						onClick={() => {
							history.push('/lead-list');
						}}
						className='btn'
						disabled={!isFilterValid}
					>
						{t('Apply')}
					</button>
				</div>
			</div>
		</IonPage>
	);
};

export default LeadFilters;
