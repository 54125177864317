import React, { useState } from 'react';
import { Header } from '../../components/layout/header/Header';
import {
	IonCard,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonContent,
	IonGrid,
	IonRow,
	IonCol,
	IonImg,
	IonItem,
} from '@ionic/react';
import './SpecificLendersInfo.scss';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
export const SpecificLendersInfo: React.FC = () => {
	const [deleteLenderModal, setDeleteLenderModal] = useState(false);
	const { t } = useTranslation();
	const data = [
		{
			bank_name: 'SBI',
			bank_int_rate: 9.5,
			isOnline: true,
			isRecommended: false,
			isSelected: true,
		},
		{
			bank_name: 'Kotak Bank',
			bank_int_rate: 8.5,
			isOnline: false,
			isRecommended: true,
			isSelected: true,
		},
		{
			bank_name: 'HDFC Bank',
			bank_int_rate: 8.5,
			isOnline: true,
			isRecommended: true,
			isSelected: true,
		},
		{
			bank_name: 'Canara Bank',
			bank_int_rate: 8.5,
			isOnline: true,
			isRecommended: false,
		},
		{
			bank_name: 'Canara Bank',
			bank_int_rate: 8.5,
			isOnline: false,
			isRecommended: false,
		},
		{
			bank_name: 'Canara Bank',
			bank_int_rate: 8.5,
			isOnline: false,
			isRecommended: false,
		},
		{
			bank_name: 'Canara Bank',
			bank_int_rate: 8.5,
			isOnline: false,
			isRecommended: false,
			isSelected: false,
		},
		{
			bank_name: 'Canara Bank',
			bank_int_rate: 8.5,
			isOnline: false,
			isRecommended: false,
			isSelected: true,
		},
		{
			bank_name: 'Canara Bank',
			bank_int_rate: 8.5,
			isOnline: false,
			isRecommended: false,
			isSelected: true,
		},
	];

	return (
		<>
			{/* <Menu /> */}
			<Header
				name={t('Principal Partner Info')}
				needArrow={true}
				backButtonDisabled={false}
				backButton={true}
			/>
			<IonContent>
				<div className='d-flex justify-content-end'>
					<select
						name=''
						className='me-3 form-control form-select'
						id=''
						style={{ width: '100px' }}
					>
						<option value=''>{t('All')}</option>
						<option value=''>{t('Online')}</option>
						<option value=''>{t('Offline')}</option>
					</select>
				</div>
				<IonGrid className='pb-180'>
					<IonRow>
						{data &&
							data?.map((item, index) => {
								return (
									<IonCol
										size='12'
										key={index}
									>
										<IonCard
											routerLink='/info/sbi-bank'
											className={`m-0 w-100  p-2 px-3 col-6  
                      ${
												item?.isOnline && item?.isRecommended
													? 'online-recommended'
													: ''
											}${
												!item?.isOnline && item?.isRecommended
													? 'offline-recommended'
													: ''
											}
                      ${item?.isOnline && !item?.isRecommended ? 'online' : ''}
                  `}
										>
											<IonCardHeader className='ion-no-padding gap-3 d-flex flex-row'>
												<IonImg
													alt='img'
													className='bankimg m-0'
													src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFwAAABcCAMAAADUMSJqAAAAZlBMVEURZt3///8AXtz5+/4AY9wAWtsAXNsAYdwAWNvt8vwAVtro7vtmkeUha97f5/kAVNphjeTQ3faDpOmrwO+dtu20yPG+z/ORqep7nOfX4vg3dODH1vVNgOKWseySruxwl+ZCe+FVh+NBkJuHAAADvUlEQVRogbXaaYOqIBQGYERZXHPJxjSn6f//yYuaN0XQ17LzWZ9hDodFiDhQ+MmlOP/kVZX/tMUl8bG3yPYj1yKqRcAoFX1QygJRR8X1Y9xtKsG48IgWnuBMVI37AZ7kcSB09xUiiPPkTby5MWqXh6Ds1ryBN3/BIhmm8II/K2/Bk5uE6J6XN0tyjLibn2C650+5sWtN+DXezLUeNDZVpgFv9zV7bHyL4JHcT3cho03c35+SMWiszwoansQrg2YrRJys4Ql9I92v8ERix5PlJPKRPsV98qGtdOJb8E/yPYaIzXj6dp1Mg6Ym/PfN+tZD/i7x7HSMTcgp03E3/rgzx/BiV8NzfpRNCM/n+PWwpHRxus7w22FJ6cK7TfELO9ImhF0meH1ow1XT6xd+OajEXyEv//GjGz42vcOzgzPeBcue+P2ACUsPcR9w/wu20v0eL7+QFdWlZY+n32l52uOH1+EQssObr2RF1Uuj8N9DFqBl0F+FPw4fQUOIh0PcGH+cS8nh3ldrBgnRVYKSe5ll5Z2gaeQhycBiCfLnjsTPA+wNmZESa/lzht4z+/OStNB/yYrJXscpIJ225AfpoXHdGgNaFcUPqRA8yOZ4hqRdVCRCGkEdLZBcehFJAdx76Dgy8rwUwsXicycCkqlwJC1vtjwi0BondBx66U7y71VLjg0ikc5xaPFSg6iAhv909KPjnxekwSYuNjn3ANcu2ZAQnBX5ebTP4CQtQ+JiTypd9B8MrkAXALVY4FtzOuDoWqEmO+JAtfgGLnKFwxv/nbgqMOK46KZoJy7dbsf1ByZ9H96tLwrHhtFenBfDFhpM+j6cDVto54HVyy5cdLM0gaeKfXg/XfQfXNDjO9PijDi4D9mBD/ucHnehYtzVcvc/jk10O/DnFPo8WEBOW3Dcex50PfELsCjiuLzMcKTWYbyv8Skebr8D4zTUcKfcTAyKB6Wj40609RaI09feb3JouXUwguHPcxwdDzfGKYbz0Ig7G99eEC6nG7/ZEff6cRSCy9nObH44v1oyAP4qFAO+OrVv4/Md5fJC5MqsNbOFC6bfFS2ucsLa9vYGTutQtwyXUKkl8et4kC4l0/VZaT6aWMMFLw2Q8eIvvJlqcgWXt0VKrLhqPFmuTVacE1Oz7bjjtot9uAXnorVdRduvid0zmZelCfcYOdtvuVcvuMsHm9ynLXCPsoclIQCuurat5XgzP8M9wWXdGrsRxlX4ZVXTgFPBBpwJygNaV+X2zxaAXyx0f+BatlU64GnVllfs9xD/AM8yJjFCzZT3AAAAAElFTkSuQmCC'
												/>
												<div className=' flex-grow-1'>
													<IonCardTitle className=' fs-12 fw-600 text-black'>
														<div className='d-flex justify-content-between'>
															<span> {item?.bank_name}</span>
														</div>
													</IonCardTitle>
													<IonCardSubtitle className='subtitle'>
														<p className='mb-0'>
															<span className='fs-12'>
																{item?.isOnline ? t('Online') : t('Offline')}
															</span>{' '}
															.{' '}
															<span className='fw-500 fs-12'>
																{item?.bank_int_rate}% p.a
															</span>
														</p>
													</IonCardSubtitle>
												</div>
											</IonCardHeader>
										</IonCard>
									</IonCol>
								);
							})}
					</IonRow>
				</IonGrid>
				<div className='bottom-button-setter py-3'>
					<CustomButton
						className='w-100'
						fill='outline'
						title={t('Continue Later')}
					/>
					<CustomButton
						routerLink='/comman-info'
						expand='block'
						title={t('Bulk Status Update')}
					/>
				</div>
			</IonContent>
			{/* AssignModal */}
			<CustomModal
				id='custommodal'
				isOpen={deleteLenderModal}
				className='deletelender'
				modalTitle={t('Update Stage')}
				initialBreakpoint={undefined}
				handleClose={() => setDeleteLenderModal(false)}
				breakpoints={[]}
				modalContent={
					<div className='wrapper pt-3'>
						<IonItem lines='none'>
							<div className='d-flex flex-column'>
								<h5 className='mx-0 fs-20 fw-600'>
									{t('Are you sure you want to remove this lender?')}
								</h5>
							</div>
						</IonItem>
						<div className='d-flex justify-content-center gap-3 px-3 mt-3'>
							<CustomButton
								onClick={() => setDeleteLenderModal(false)}
								className='w-50'
								fill='outline'
								title={t('Cancel')}
							/>

							<CustomButton
								className='w-50'
								fill='solid'
								title={t('Remove')}
							/>
						</div>
					</div>
				}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
