import { IonText } from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";

const Card = ({ card }: any) => {
    const history = useHistory();
    return (
        <div onClick={() => { history.push('/earning-details') }}>
            <div className="ioncards" key={card.id}>
                <div className="flexSub">
                    <sub className='font-small font-color'>{card.subtitle1}</sub>
                    <sub className='m-left font-small font-color'>{card.subtitle2}</sub>
                    <sub className='m-left font-small font-color'>{card.subtitle3}</sub>
                </div>
                <IonText className='ionHeading'>{card.title}</IonText>
                <div className="flex">
                    <sub className='font-small font-color'>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" id="Homeloan_icon" data-name="Homeloan icon" width="16" height="16" viewBox="0 0 16 16">
                                <path id="Subtraction_25" data-name="Subtraction 25" d="M1445.737-1792.5h-7.778a2.336,2.336,0,0,1-2.334-2.333v-5.882H1434l1.625-1.3,5.18-4.144a1.562,1.562,0,0,1,.973-.342,1.559,1.559,0,0,1,.972.342l6.806,5.444h-1.486v5.882A2.336,2.336,0,0,1,1445.737-1792.5Zm-2.266-5.444a1.565,1.565,0,0,0-.853.226,1.459,1.459,0,0,0-.54.6,1.759,1.759,0,0,0-.181.791v.357a1.768,1.768,0,0,0,.179.79,1.465,1.465,0,0,0,.537.6,1.58,1.58,0,0,0,.858.226,1.552,1.552,0,0,0,.85-.227,1.455,1.455,0,0,0,.534-.6,1.793,1.793,0,0,0,.176-.789v-.357a1.792,1.792,0,0,0-.174-.788,1.446,1.446,0,0,0-.531-.6A1.547,1.547,0,0,0,1443.471-1797.943Zm.211-3.784a.253.253,0,0,0-.206.108l-4.657,6.773a.251.251,0,0,0-.015.258.25.25,0,0,0,.221.134h.754a.253.253,0,0,0,.206-.108l4.657-6.773a.251.251,0,0,0,.015-.258.252.252,0,0,0-.221-.134Zm-3.585-.093a1.564,1.564,0,0,0-.852.227,1.469,1.469,0,0,0-.541.6,1.761,1.761,0,0,0-.18.791v.357a1.781,1.781,0,0,0,.178.79,1.452,1.452,0,0,0,.537.6,1.57,1.57,0,0,0,.858.227,1.558,1.558,0,0,0,.851-.227,1.455,1.455,0,0,0,.533-.6,1.8,1.8,0,0,0,.176-.789v-.357a1.8,1.8,0,0,0-.174-.788,1.444,1.444,0,0,0-.53-.6A1.563,1.563,0,0,0,1440.1-1801.82Zm3.374,6.39c-.192,0-.255-.064-.289-.12a.807.807,0,0,1-.109-.423v-.357a.778.778,0,0,1,.112-.419c.036-.058.1-.125.286-.125.211,0,.259.082.279.117a.836.836,0,0,1,.106.427v.357a.809.809,0,0,1-.109.424C1443.713-1795.494,1443.652-1795.43,1443.471-1795.43Zm-3.374-3.876c-.192,0-.255-.065-.288-.121a.794.794,0,0,1-.11-.422v-.357a.769.769,0,0,1,.113-.419c.036-.058.1-.125.285-.125.213,0,.26.082.28.117a.847.847,0,0,1,.1.427v.357a.811.811,0,0,1-.108.424C1440.34-1799.37,1440.278-1799.307,1440.1-1799.307Z" transform="translate(-1434 1807.5)" fill="#6c6" />
                                <g id="Group_135758" data-name="Group 135758" transform="translate(22190.439 2786)">
                                    <g id="Rectangle_28400" data-name="Rectangle 28400" transform="translate(-22178.936 -2783.95)" fill="#6c6" stroke="#6c6" stroke-width="0.5">
                                        <rect width="2.625" height="4.813" stroke="none" />
                                        <rect x="0.25" y="0.25" width="2.125" height="4.313" fill="none" />
                                    </g>
                                    <rect id="Rectangle_28401" data-name="Rectangle 28401" width="3.5" height="0.438" rx="0.219" transform="translate(-22179.373 -2783.95)" fill="#6c6" />
                                </g>
                                <rect id="Rectangle_28822" data-name="Rectangle 28822" width="16" height="16" fill="#fff" opacity="0" />
                            </svg>
                        </span>
                        {card.titlehome}
                    </sub>
                    <sub className='font-small font-color'>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" id="noun-location-5707257" width="16" height="16" viewBox="0 0 16 16">
                                <rect id="Rectangle_28642" data-name="Rectangle 28642" width="16" height="16" fill="rgba(255,255,255,0)" />
                                <path id="Path_229870" data-name="Path 229870" d="M183.52,37.016a4.93,4.93,0,0,0-4.32,7.306h-.01l3.632,6.291a.806.806,0,0,0,1.395,0l3.632-6.291h-.01a4.93,4.93,0,0,0-4.32-7.306Zm0,7.4a2.464,2.464,0,1,1,2.464-2.464A2.464,2.464,0,0,1,183.52,44.411Z" transform="translate(-175.59 -36.016)" fill="#ec0a0a" />
                            </svg>
                        </span>
                        {card.titlelocation}
                    </sub>
                    <sub className='font-small font-color'>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                <g id="Bank_icon" data-name="Bank icon" transform="translate(-260 -47)">
                                    <path id="noun-bank-1698442" d="M130.62,75a.358.358,0,0,0,.358-.358V73.93a.343.343,0,0,0-.193-.315l-6.8-3.578a.337.337,0,0,0-.329,0l-6.8,3.578a.343.343,0,0,0-.193.315v.716a.358.358,0,0,0,.358.358h1.789v6.441h-1.074a.358.358,0,0,0-.358.358v1.431a.358.358,0,0,0,.358.358H129.9a.358.358,0,0,0,.358-.358V81.8a.358.358,0,0,0-.358-.358h-1.074V75Zm-9.661,0h1.789v6.441h-1.789Zm5.725,6.441h-1.789V75h1.789Z" transform="translate(144.335 -21.993)" fill="#0165e1" />
                                    <rect id="Rectangle_28864" data-name="Rectangle 28864" width="16" height="16" transform="translate(260 47)" fill="rgba(255,255,255,0)" />
                                </g>
                            </svg>
                        </span>
                        {card.titlebank}
                    </sub>
                </div>
                <div className='flex m-top'>
                    <sub className='font-small font-color'>{card.loanAmountLabel}</sub>
                    <sub className='font-bold'>{card.loanAmountValue}</sub>
                </div>
                <div className='flex m-6'>
                    <sub className='font-small font-color'>{card.disbursedOnLabel}</sub>
                    <sub className='font-small font-color'>{card.disbursedOnValue}</sub>
                </div>
                <div className="flex payOut m-6">
                    <sub className='bold font-small'>{card.payoutLabel}<span className='normalFont m-left font-color'>{card.payoutDate}</span></sub>
                    <sub className='bold font-small'>{card.payoutAmount}</sub>
                </div>
            </div>
            <div className="cardDivider"></div>
        </div>
    );
};

export default Card;
