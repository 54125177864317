//// @ts-nocheck


import { yupResolver } from "@hookform/resolvers/yup";
import {
  IonCard,
  IonCardContent,
  IonContent,
  IonLabel,
  IonPage
} from "@ionic/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CustomButton } from "../../components/common/custom-button/CustomButton";
import { CustomCheckbox } from "../../components/common/custom-checkbox/CustomCheckbox";
import address_details_schema from "./address_details.schema";
// import {CustomForm } from 'andro-insurance-lib';
import { useContext, useEffect, useMemo, useState } from "react";
import useApi from "../../api/useApi";
import CustomForm from "../../components/common/custom_form/custom_form.component";
import { Header } from "../../components/layout/header/Header";
import ApplicationContext from "../../context/ApplicationContext";
import { useBusyLoading } from "../../context/BusyLoadingContext";
import { ADDRESS_DETAIL, GETPINCODEDETAILS } from "../../helpers/api-endpoints";
import { ADDRESS_TYPE, REDIRECT } from "../../helpers/constant";
import { showToastAlert } from "../../helpers/functions";
import { AddressDetailsForm, AddressDetailsRequest } from "../../model/address_details_bean";




interface Props {
  title: string;
  nextTabUrl?: string;
  inpCallback?: () => void;
  isProposer: boolean;
  addressDetailsForm?: AddressDetailsForm;
  showCopyAddress?: boolean;
  readonly:boolean;
  // AddressType: "Permanent" | "Current";
  // userId: any;
  // setShowBasicInfoForm: any;
  // getUserDetailsInfo: any;
}

const AddressDetails: React.FC<Props> = ({
  title,
  inpCallback,
  showCopyAddress = false,
  isProposer,
  addressDetailsForm,
  nextTabUrl,
  readonly = false,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [validateForm, setValidateForm] = useState<boolean>();

  const { proposerPermAddressDetailsValid, setProposerPermAddressDetailsValid } = useContext(ApplicationContext);
  const { proposerCurAddressDetailsValid, setProposerCurAddressDetailsValid } = useContext(ApplicationContext);
  const { assuredPermAddressDetailsValid, setAssuredPermAddressDetailsValid } = useContext(ApplicationContext);
  const { assuredCurAddressDetailsValid, setAssuredCurAddressDetailsValid } = useContext(ApplicationContext);
  const { applicationBean,setAppBean} = useContext(ApplicationContext);
  const [showPostOffice, setshowPostOffice] = useState<boolean>(false);
  const [callPincodeApi, setCallPincodeApi] = useState<boolean>(false);
  const [checkCopyAddress, setCheckCopyAddress] = useState<boolean>(false);
  const [postOfficeList, setPostOfficeList] = useState<any[]>([]);
  const [srcAddressDetailsForm, setSrcAddressDetailsForm] = useState<AddressDetailsForm>();
  
  const { responseData, loading, executeRequest, error } =
    useApi<any>(ADDRESS_DETAIL);

  const pincodeApi = useApi<any>(GETPINCODEDETAILS);



  const { setBusyState } = useBusyLoading();

  useEffect(() => {
    setBusyState(loading);
  }, [loading]);

  useEffect(() => {
    showToastAlert({ type: "error", message: error?.message! });
  }, [error]);

  useEffect(() => {
    if (responseData?.status) {
      showToastAlert({ type: "success", message: responseData?.message });

      if(inpCallback) {
        inpCallback();  
        if(nextTabUrl)
         history.push(nextTabUrl);
      }
    }
  }, [responseData]);

  useEffect(() => {
    setBusyState(pincodeApi.loading);
  }, [pincodeApi.loading]);

  useEffect(() => {
    showToastAlert({ type: "error", message: pincodeApi.error?.message! });
  }, [pincodeApi.error]);




 

  let addressDetailsFormKeys = [
    {
      name: "line1",
      type: "text",
      placeholder: "Line 1 *",
    },

    {
      name: "line2",
      type: "text",
      placeholder: "Line 2 *",
      // disabled: true,
    },
    {
      name: "landmark",
      type: "text",
      placeholder: "Landmark *",
    },
    {
      name: "pincode",
      type: "text",
      placeholder: "Pin Code *",
    },
    {
      name: "postOffice",
      //hbs change here
      type: "text/combo",
      mandatory: true,
      hidden: !showPostOffice,
      placeholder: "Post Office",
      values: postOfficeList,
    },
    {
      name: "city",
      type: "text",
      disabled: true,
      placeholder: "City",
    },
    {
      name: "state",
      type: "text",
      disabled: true,
      placeholder: "State",
    },
  ];


  
  console.log('addressDetailsForm', addressDetailsForm);
  // console.log('applicationBean address request', applicationBean?.permAddressDetailsProposer);
  


  useEffect(()=>{

    if(showCopyAddress){
      if(isProposer){
        setSrcAddressDetailsForm(applicationBean?.permAddressDetailsProposer);
      
      }else{
        setSrcAddressDetailsForm(applicationBean?.permAddressDetailsAssured);
      }
    }
  },[srcAddressDetailsForm,showCopyAddress]); 

  const {
    control,
    trigger,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(address_details_schema),
    defaultValues: {
      city : addressDetailsForm?.city,
      landmark:addressDetailsForm?.landmark,
      line1:addressDetailsForm?.line1,
      line2:addressDetailsForm?.line2,
      pincode:addressDetailsForm?.pincode,
      state:addressDetailsForm?.state,
      postOffice: undefined, 
    },
  });


  const setFormToValid= (inpValid?:boolean)=>{

    if (addressDetailsForm?.addressType === ADDRESS_TYPE.PERMANENT) {
      if (isProposer) {
        setProposerPermAddressDetailsValid(inpValid??isValid);
      }
      else {
        setAssuredPermAddressDetailsValid(inpValid??isValid);
      }
    } else {
      if (isProposer) {
        setProposerCurAddressDetailsValid(inpValid??isValid);
      }
      else {
        setAssuredCurAddressDetailsValid(inpValid??isValid);
      }
    }



  }
  // console.log("isProposer",isProposer,proposerPermAddressDetailsValid);

  useEffect(() => {
    console.log("addressDetailsForm?.addressType ",addressDetailsForm?.addressType);
   setFormToValid();
  }, [isValid, proposerPermAddressDetailsValid, setProposerPermAddressDetailsValid,
    proposerCurAddressDetailsValid, setProposerCurAddressDetailsValid,
    assuredPermAddressDetailsValid, setAssuredPermAddressDetailsValid,
    assuredCurAddressDetailsValid, setAssuredCurAddressDetailsValid
  ]);

  const onSubmit = (data: any) => {
    trigger();
    console.log(data);
    
    console.log("isValid",isValid);
    
    // console.log(proposerPermAddressDetailsValid);
    let  addReq : AddressDetailsRequest = new AddressDetailsRequest(data);
    addReq.id = addressDetailsForm?.id!;
    // const payload = new AddressDetailsRequest(data);
    const payload = addReq;

    console.log(addReq);
    console.log("payload",payload);


    setFormToValid(true);

    
    executeRequest("put", payload);
    if(!showCopyAddress){
      if(isProposer){
        applicationBean!.permAddressDetailsProposer = data;
      }else{
        applicationBean!.permAddressDetailsAssured = data;
      }
    }
  };


  useMemo(() => {
    showToastAlert({ type: "error", message: error?.message! });
  }, [error]);

  const copyPermanentAddress = (e: any) => {
    if (e.target.checked) {
      setFormToValid(true);
      setCheckCopyAddress(true);
      setValue("line1", srcAddressDetailsForm?.line1 ?? "");
      setValue("line2", srcAddressDetailsForm?.line2 ?? "");
      setValue("landmark", srcAddressDetailsForm?.landmark ?? "");
      setValue("pincode", srcAddressDetailsForm?.pincode ?? "");
      setValue("city", srcAddressDetailsForm?.city ?? "");
      setValue("state", srcAddressDetailsForm?.state ?? "");
    } else {
      setFormToValid(false);
      setCheckCopyAddress(false);
      setValue("line1", addressDetailsForm?.line1 ?? "");
      setValue("line2", addressDetailsForm?.line2 ?? "");
      setValue("landmark", addressDetailsForm?.landmark ?? "");
      setValue("pincode", addressDetailsForm?.pincode ?? "");
      setValue("city", addressDetailsForm?.city ?? "");
      setValue("state", addressDetailsForm?.state ?? "");
    }
  };


  const pincode = watch("pincode");
  const postOffice = watch("postOffice");
  // let postOffice:any;
  let pincodeError = errors.pincode;


  useEffect(() => {
    // const pincode = watch("pincode");


    if (pincode && pincode.length ===6 && callPincodeApi) {
      

      const payload = {
        'pincode': pincode,
      };

      console.log('payload', payload);

      pincodeApi.executeRequest("post", payload,true, false);



    }
    else if(pincode && pincode.length===5){
      setCallPincodeApi(true);
    }
    
    else {
      if(callPincodeApi){
        setValue("city", "");
        setValue("state", "");
      }
      setshowPostOffice(false);
    }
  }, [pincode,pincodeError]);



useEffect(()=>{
  if (pincodeApi.responseData?.status) {
    // setshowPostOffice(true);
    // if(responseData.list && responseData.list.length>0) {

    let stubPostOfficeList:any[] = pincodeApi.responseData.list;
    let stubPostOfficeListFinal:any[] = [];
    // console.log(stubPostOfficeList);

    if(stubPostOfficeList.length>=2) {
      stubPostOfficeList.map((item)=>{
        console.log(item);
        stubPostOfficeListFinal.push({
          value: `${item.Region},${item.State}`, 
          description:  `${item.Region}, ${item.State}`, 
        });
      });
    setPostOfficeList(stubPostOfficeListFinal);
    setshowPostOffice(true);

    }
    else if(stubPostOfficeList.length === 1) {
      setValue("city", stubPostOfficeList[0].Region);
      setValue("state", stubPostOfficeList[0].State);
      
    //   stubPostOfficeList.map((item)=>{
    //     // console.log(item);
    //     stubPostOfficeListFinal.push({
    //       value: `${item.Region},${item.State}`, 
    //       description:  `${item.Region}, ${item.State}`, 
    //     });
    //   });
    // setPostOfficeList(stubPostOfficeListFinal);
    // setshowPostOffice(true);

    }
    // else if(stubPostOfficeList.length>1){

    // }
    // setManagers(responseData?.data);
}



},[pincodeApi.responseData]);


  useEffect(() => {
    console.log(postOffice);

    if (postOffice && postOffice.toString().trim().length > 0) {
      let locStub = postOffice.toString().split(",");
      setValue('city', locStub[0]);
      setValue('state', locStub[1]);
    }

  }, [postOffice]);



  return (
    <IonPage className="bg-web-white container-width-md container-spacing-md border-md-radius-top">

    <Header
        className="card-header"
        name={t(title ?? "Address Details")}
        backButton={true}
        needArrow={true}
        needArrowOnClick={() => {
          // history.push(REDIRECT.DASHBOARD);
          window.location.href = REDIRECT.DASHBOARD;
        }}
      />
      
      <IonContent className="ion-padding-bottom">
        <div className="min-vh-100">
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonCard>
              <IonCardContent>
                {/* <IonTitle>{title ?? 'Address Details'}</IonTitle> */}
                {showCopyAddress && (
                  <CustomCheckbox
                    name={"Same Address Check"}
                    // isBackGround={true}
                    isChecked={checkCopyAddress}
                    labelPlacement="end"
                    onChange={copyPermanentAddress}
                    className={"fs-14 heading-color pb-3"}
                  >
                    <IonLabel className="text-wrap">
                      {t(`Copy from saved permanent address`)}
                    </IonLabel>
                  </CustomCheckbox>
                )}

                <CustomForm
                  readonly={readonly}
                  formKeys={addressDetailsFormKeys}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  errors={errors}
                  isValid={validateForm}
                  trigger={trigger}
                />
              </IonCardContent>
            </IonCard>
            <div
              className="position-fixed w-100 bottom-0 start-0 px-3 pb-3"
              style={{ backgroundColor: "#fff" }}
            >
              <CustomButton
                className="btn-blue"
                type="submit"
                expand="block"
                title="Update"
              // disabled={!isValid}
              // title={t('Update')}
              // disabled={!isValid}
              />
            </div>
          </form>
        </div>
        {/* </section> */}
      </IonContent>
    </IonPage>
  );
};

export default AddressDetails;
