import { IonContent, IonItem, IonList, IonPage } from '@ionic/react';
import { Header } from '../../components/layout/header/Header';
import { FormatDate } from '../../helpers/functions';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
export const DmaActivity: React.FC = () => {
	const { t } = useTranslation();
	const dmaActivity = useSelector((state: any) => state?.lead?.infoActivity);

	return (
		<IonPage>
			<Header
				name={t('Activities')}
				backButton={true}
				needArrow={true}
			/>
			<IonContent className=''>
				<div className='bg-web h-100 py-md-3'>
					<div className='bg-web-white container-width-md border-md-radius-10 h-100 overflow-auto-md'>
						<IonList>
							{dmaActivity?.map((item: any, index: number) => {
								return (
									<IonItem
										key={index}
										className=''
										lines='full'
									>
										<div className='my-3'>
											<h6 className='fw-bold fs-16 fs-500'>
												{item?.message || 'NA'}
											</h6>
											<div>
												{item?.comment && (
													<p className='mb-0 fs-12 fw-500 text-grey position-relative'>
														<ul style={{ listStyle: 'none', paddingLeft: '15px' }}>
															<li style={{ position: 'relative' }}>
																<span style={{ position: 'absolute', left: '-1em' }}>-</span>
																{item?.comment}
															</li>
														</ul>
													</p>
												)}
											</div>
											<div>
												{item?.comments && (
													<>
														{item?.comments?.disposition && (
															<p className='mb-0 fs-12 fw-500 text-grey position-relative'>
																- {item?.comments?.disposition}
															</p>
														)}
														{item?.comments?.disposition_reason && (
															<p className='mb-0 fs-12 fw-500 text-grey position-relative'>
																- {item?.comments?.disposition_reason}
															</p>
														)}
														{item?.comments?.date && (
															<p className='mb-0 fs-12 fw-500 text-grey position-relative'>
																-
																{' ' +
																	FormatDate(
																		item?.comments?.date,
																		'dd mmm yyyy hh:MM TT'
																	)}
															</p>
														)}
														{item?.comments?.comment && (
															<p className='mb-0 fs-12 fw-500 text-grey position-relative'>
																- {item?.comments?.comment}
															</p>
														)}
													</>
												)}
											</div>
											<div className='mt-2'>
												<p className='mb-0 fs-12 fw-500 text-grey position-relative'>
													<span>By {item?.created_by_name || 'NA'}</span>
													<span className='mx-2'>•</span>
													<span>
														{FormatDate(item?.createdAt, 'dd mmm yyyy')}
													</span>
													<span className='mx-2'>•</span>
													<span>{FormatDate(item?.createdAt, 'hh:MM TT')}</span>
												</p>
											</div>
										</div>
									</IonItem>
								);
							})}
						</IonList>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};
