import {
	IonButtons,
	IonChip,
	IonCol,
	IonContent,
	IonFooter,
	IonGrid,
	IonImg,
	IonItem,
	IonPage,
	IonRow,
} from '@ionic/react';
import { Header } from '../../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import DropDownModal from '../../../components/common/custom-dropdown/DropDownModal';
import { useDispatch, useSelector } from 'react-redux';
import { CustomCheckbox } from '../../../components/common/custom-checkbox/CustomCheckbox';
import { useEffect, useState } from 'react';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { unwrapResult } from '@reduxjs/toolkit';
import { CustomNoData } from '../../../components/common/custom-no-data/CustomNoData';
import {
	InputFormatCurrencyValue,
	PageExitPopUp,
	ShareData,
	findCurrentStatus,
	isLenderIncludes,
	// findStatus,
	setBankIcon,
	showToastAlert,
} from '../../../helpers/functions';
import {
	getLeadFlowUrl,
	getLenderSpcfInfo,
	getSelectedLendersList,
	leadFlow,
	lenderSpfDetailsFlow,
	markAsClose,
	selectedLender,
} from '../../../store/reducers/lead/leadSlice';
import { LEAD_STATUS, LeadStatus } from '../../../helpers/constant';
import CustomAllocateManagers from '../../../components/common/CustomAllocateManagers';
import { customSuccessfull } from '../../../store/reducers/user/userSlice';

export const LeanderSpecificInfo: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	const [bulkUpdate, setBulkUpdate] = useState(false);
	const [lenderlist, setLnederList] = useState<any>();

	const [selectedValue, setSelectedValue] = useState<any>('select');
	const [lenderlist1, setLnederList1] = useState<any>();
	const dispatch = useDispatch();
	const [showAssignModal, setShowAssignModal] = useState<boolean>(false);
	const dmaSelector = useSelector((state: any) => state?.users);
	const leadInfo = useSelector((state: any) => state.lead.leadFlowData);
	const productCategoryId = useSelector(
		(state: any) =>
			state?.lead?.leadFlowData?.selectedProductData?.product_category_id
	);

	const [modalPageClose, setModalPageClose] = useState(false);
	const [url, setUrl] = useState('');

	const leadData = useSelector((state: any) => state?.lead?.leadFlowData);

	useEffect(() => {
		if (location.pathname !== '/lender-specific-info') {
			return;
		}
		getSelectedLenderList();
	}, [location.pathname]);

	const getSelectedLenderList = async () => {
		const isShowStatus =
			leadInfo?.leadStatus == LEAD_STATUS.LOGIN_LABEL ||
			leadInfo?.leadStatus == LEAD_STATUS.SANCTIONED_LABEL ||
			leadInfo?.leadStatus == LEAD_STATUS.DISBURSED_LABEL;
		await dispatch(
			getSelectedLendersList({
				param: '/' + leadInfo?.leadId + `?close=${!isShowStatus ? 1 : 0}`,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setLnederList(response.data);
					setLnederList1(response.data);
					setBulkUpdate(false);
				}
			});
		await dispatch(getLeadFlowUrl())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setUrl(result?.url);
				}
			});
	};

	const selectLender = (event: any) => {
		if (event.detail.checked) {
			const updateLenderList = lenderlist.map((item: any) => {
				const isShowDisbale =
					LEAD_STATUS.APPLICATION_CLOSED === item.status ||
					LEAD_STATUS.DISBURSED === item.status ||
					LEAD_STATUS.PARTIALDISBURSED === item.status;

				return {
					...item,
					isChecked: isShowDisbale ? 'disabled' : true,
				};
			});
			setLnederList(updateLenderList);
		} else {
			const updateLenderList = lenderlist.map((item: any) => {
				return {
					...item,
					isChecked: false,
				};
			});
			setLnederList(updateLenderList);
		}
	};

	const activeLender = (event: any, id: any) => {
		const activeLenderList = lenderlist.map((item: any) => {
			const isShowDisbale =
				LEAD_STATUS.CLOSED === item.status ||
				LEAD_STATUS.DISBURSED === item.status ||
				LEAD_STATUS.PARTIALDISBURSED === item.status;
			if (id === item?.id) {
				return {
					...item,
					isChecked: isShowDisbale ? 'disabled' : event.detail.checked,
				};
			} else {
				return {
					...item,
					isChecked: isShowDisbale ? 'disabled' : item?.isChecked,
				};
			}
		});
		setLnederList(activeLenderList);
	};

	const setApplicationStatus = (event: any) => {
		const data =
			event.target.value != 'select'
				? lenderlist1.filter(
						(item: any) => item.app_off_status == event.target.value
				  )
				: lenderlist1;
		setSelectedValue(event.target.value);
		setLnederList(data);
	};
	const isLednerInfo = location?.pathname === '/lender-specific-info';
	useEffect(() => {
		if (isLednerInfo) {
			setLnederList(lenderlist);
		}
	}, [isLednerInfo]);
	const isSelectAll = lenderlist?.every(
		({ isChecked }: any) => isChecked || isChecked === 'disabled'
	);
	const count = lenderlist?.filter(
		({ isChecked }: any) => isChecked === true
	).length;
	const data = lenderlist?.filter(({ isChecked }: any) => isChecked === true);
	const isShowButton = lenderlist?.every(({ status }: any) => status);

	const selectedClodesLender = async () => {
		const data1: any = lenderlist?.filter(
			({ isChecked }: any) => isChecked === true
		);
		const status = data1.length === 1 ? data1[0]?.status : '';
		const myPromiss = new Promise(function (myResolve) {
			setTimeout(function () {
				let path = '';
				if (bulkUpdate) {
					path = '/update-status';
				} else {
					path = '/bank-visit-site';
				}
				myResolve(history.push(path));
			}, 100);
		});
		myPromiss.then(async () => {
			await dispatch(
				leadFlow({
					...leadInfo,
					leadStatus:
						leadInfo?.leadStatus === LeadStatus.PROCESSING
							? LeadStatus.SELECTION
							: leadInfo?.leadStatus,
					applicationStatus: status,
					update: leadInfo.update + 1 || 1,
				})
			);
			await dispatch(selectedLender(data));
			await dispatch(markAsClose(true));
		});
	};

	/**
	 * Get lender specific info
	 */
	const getLenderSpfInfo = async (item: any) => {
		dispatch(selectedLender([item]));
		const bank_app_no: any = lenderlist.find(
			(ele: any) => item?.lender_id === ele?.lender_id
		);

		if (
			(bank_app_no?.bank_app_no && bank_app_no?.bank_app_no.length > 0) ||
			item?.lender_name?.toLowerCase()?.includes('tata') ||
			item?.lender_name?.toLowerCase()?.includes('aadhar') ||
			(item?.lender_name?.toLowerCase()?.includes('bajaj') &&
				item?.product_category_id != '2' &&
				item?.product_category_id != '4') ||
			item?.lender_name?.toLowerCase()?.includes('prefr') ||
			item?.lender_name?.toLowerCase()?.includes('dmi') ||
			item?.lender_name?.toLowerCase()?.includes('indifi')
		) {
			await dispatch(
				getLenderSpcfInfo({
					data: {
						lead_id: leadInfo?.leadId,
						lead_application_id: leadInfo?.lead_app_id,
						lender_id: item.lender_id,
					},
				})
			)
				.then(unwrapResult)
				.then((result: any) => {
					if (result?.status && result?.data) {
						const lenderSpfDetails = result?.data?.lender_spf_details;
						dispatch(
							lenderSpfDetailsFlow({
								step: result?.data?.step || 'step1',
								backButton: true,
								needArrow: true,
								needCross: false,
								lead_spf_detail_id: result?.data?.id,
								lender_spf_details: lenderSpfDetails,
								lender_lead_id: result?.data.lender_lead_id,
							})
						);
					} else {
						dispatch(
							lenderSpfDetailsFlow({
								step: 'step1',
								backButton: true,
								needArrow: true,
								needCross: false,
							})
						);
					}
					history.push('/lead-lender-specific-form-steps');
				});
		} else {
			dispatch(
				lenderSpfDetailsFlow({
					step: 'step1',
					backButton: true,
					needArrow: true,
					needCross: false,
				})
			);
			history.push('/lead-lender-specific-form-steps');
		}
	};

	/**
	 * If user has already filled forms and they click again on same lender then
	 * it will be show message like: Already you have completed process for LENDER_NAME
	 * @param item lender info
	 */
	const handleLenderFormCompleted = (item: any) => {
		showToastAlert({
			type: 'error',
			message: `Already you have completed process for ${item?.lender_name}`,
		});
	};

	return (
		<IonPage>
			<Header
				name={
					leadInfo?.leadStatus == LEAD_STATUS.LOGIN_LABEL ||
					leadInfo?.leadStatus == LEAD_STATUS.SANCTIONED_LABEL ||
					leadInfo?.leadStatus == LEAD_STATUS.DISBURSED_LABEL
						? t('Applications')
						: t('Selected Lender')
				}
				backButton={leadInfo?.from === 'customer' ? false : true}
				needCrossOnClick={async () => {
					if (leadInfo?.from === 'customer') {
						history.push('/selected-lender-list');
					} else {
						setModalPageClose(true);
					}
				}}
				needCross={true}
				style={{ borderBottom: '1px solid #00000029' }}
				endItem={
					<>
						{bulkUpdate ? (
							<IonButtons
								slot='end'
								onClick={() => setBulkUpdate(false)}
							>
								<span className='fw-700 cursor-pointer'>{t('Cancel')}</span>
							</IonButtons>
						) : leadInfo?.from !== 'nimbuss' &&
						  leadInfo?.from !== 'customer' ? (
							<IonButtons slot='end'>
								<IonButtons className=''>
									<IonImg
										className='cursor-pointer'
										src='/images/icons/assign-icon.svg'
										alt='assign'
										onClick={() => setShowAssignModal(true)}
									/>
								</IonButtons>
							</IonButtons>
						) : (
							''
						)}
						{leadInfo?.via !== 'share' ? (
							<IonButtons
								slot='end'
								className='mx-3'
							>
								<IonImg
									className='cursor-pointer'
									src='/images/icons/share-blue.svg'
									alt='assign'
									onClick={() => {
										const finalUrl =
											url.split('?')?.[0] +
											'?d=' +
											window.btoa(`lead_id=${leadInfo?.leadId}`);
										ShareData('', '', finalUrl);
									}}
								/>
							</IonButtons>
						) : (
							''
						)}
					</>
				}
			/>
			<IonContent>
				<div className='bg-web lead-lender-list-container-spacing overflowY-auto h-100'>
					<div className='bg-web-white px-md-4 py-md-3 border-md-radius-10'>
						{leadInfo?.leadStatus !== LEAD_STATUS.LOGIN_LABEL &&
							leadInfo?.leadStatus !== LEAD_STATUS.SANCTIONED_LABEL &&
							leadInfo?.leadStatus !== LEAD_STATUS.DISBURSED_LABEL && (
								<>
									<h6 className='fw-700 mt-3 px-md-6 px-4'>
										{t('Add lender specific info for selected lenders')}
									</h6>
									{leadInfo?.from !== 'customer' && (
										<IonGrid>
											<IonRow className='px-md-4 align-items-center'>
												{lenderlist?.length > 0 && !isShowButton ? (
													<IonCol
														size='6'
														sizeMd='8'
														sizeLg='9'
														className='p-0'
													>
														{!bulkUpdate ? (
															<IonButtons
																className=' my-3 cursor-pointer'
																onClick={() => setBulkUpdate(true)}
															>
																<IonImg
																	className='me-2'
																	src='/images/icons/leads/bulk-update-icon.svg'
																	alt='assign'
																/>
																<p
																	className='m-0 fs-14 fw-700'
																	style={{
																		color: 'var(--ion-color-primary)',
																	}}
																>
																	{t('Bulk Status Update')}
																</p>
															</IonButtons>
														) : (
															<CustomCheckbox
																labelPlacement='end'
																className='ps-1 ps-md-2 my-3'
																onChange={selectLender}
																isChecked={isSelectAll}
															>
																<p
																	className='m-0 fs-14 fw-700'
																	style={{
																		color: 'var(--ion-color-primary)',
																	}}
																>
																	{t('Select All')}
																</p>
															</CustomCheckbox>
														)}
													</IonCol>
												) : (
													<IonCol
														size='6'
														sizeMd='8'
														sizeLg='9'
														className='p-0'
													></IonCol>
												)}

												<IonCol
													size='6'
													sizeMd='4'
													sizeLg='3'
												>
													<DropDownModal
														label={t('Select')}
														data={[
															{
																id: 'select',
																value: t('Select'),
															},
															{
																id: 'online',
																value: t('Online'),
															},
															{
																id: 'offline',
																value: t('Offline'),
															},
														]}
														name={name}
														onChange={setApplicationStatus}
														value={selectedValue}
													/>
												</IonCol>
											</IonRow>
										</IonGrid>
									)}
								</>
							)}
						{lenderlist?.length > 0 ? (
							<IonRow className='mb-2 px-md-4'>
								{lenderlist?.map((item: any) => {
									const enableButton =
										leadInfo?.leadStatus !== LEAD_STATUS.LOGIN_LABEL &&
										leadInfo?.leadStatus !== LEAD_STATUS.SANCTIONED_LABEL &&
										leadInfo?.leadStatus !== LEAD_STATUS.DISBURSED_LABEL &&
										!item.status;

									const buttonCLick =
										(leadInfo?.leadStatus == LEAD_STATUS.LOGIN_LABEL ||
											leadInfo?.leadStatus == LEAD_STATUS.SANCTIONED_LABEL ||
											leadInfo?.leadStatus == LEAD_STATUS.DISBURSED_LABEL) &&
										!item.status;

									const isClosedStatus =
										findCurrentStatus(item?.status).label ===
											LEAD_STATUS.CLOSED_LABEL ||
										findCurrentStatus(item?.status).label ===
											LEAD_STATUS.DISBURSED_LABEL ||
										findCurrentStatus(item?.status).label ===
											LEAD_STATUS.PARTIALDISBURSED_LABEL;
									return (
										<IonCol
											size='12'
											sizeLg='6'
											className={'p-0 p-md-2 d-flex w-100'}
											key={item.id}
										>
											<IonItem
												onClick={() => {
													const myPromise = new Promise(function (myResolve) {
														setTimeout(function () {
															if (enableButton) {
																if (leadInfo?.from === 'customer') {
																	showToastAlert({
																		type: 'error',
																		message: 'Please contact with admin',
																	});
																} else {
																	if (
																		item.app_off_status == 'offline' &&
																		!bulkUpdate
																	) {
																		myResolve(history.push('/bank-visit-site'));
																	}
																	if (
																		item.app_off_status == 'online' &&
																		!bulkUpdate
																	) {
																		if (
																			item.lender_name == 'L&T Finance' &&
																			productCategoryId == 3
																		) {
																			if (
																				item?.lender_form_page_status_flag == 1
																			) {
																				myResolve(
																					history.push('/hl-common-fields-form')
																				);
																			} else if (
																				item?.lender_form_page_status_flag == 2
																			) {
																				myResolve(
																					history.push(
																						'/verify-lender-with-otp'
																					)
																				);
																			} else if (
																				item?.lender_form_page_status_flag == 3
																			) {
																				myResolve(
																					history.push('/rm-selection-form')
																				);
																			} else if (
																				item?.lender_form_page_status_flag == 4
																			) {
																				if (
																					item.lender_name == 'Piramal Capital'
																				) {
																					history.push('/bank-online-form');
																				} else {
																					getLenderSpfInfo(item);
																				}
																			} else if (
																				item?.lender_form_page_status_flag == 5
																			) {
																				handleLenderFormCompleted(item);
																			}
																		} else if (
																			isLenderIncludes(
																				item.lender_name?.toLowerCase()
																			)
																		) {
																			dispatch(
																				leadFlow({
																					...leadInfo,
																					lead_app_id: item?.id,
																				})
																			);
																			if (
																				item?.lender_form_page_status_flag == 1
																			) {
																				if (
																					leadData?.selectedProductData
																						?.product_category_id == 1
																				) {
																					myResolve(
																						history.push(
																							'/pl-common-fields-form'
																						)
																					);
																				} else if (
																					leadData?.selectedProductData
																						?.product_category_id == 2
																				) {
																					myResolve(
																						history.push(
																							'/bl-common-fields-form'
																						)
																					);
																				} else if (
																					leadData?.selectedProductData
																						?.product_category_id == 3
																				) {
																					myResolve(
																						history.push(
																							'/hl-common-fields-form'
																						)
																					);
																				} else if (
																					leadData?.selectedProductData
																						?.product_category_id == 4
																				) {
																					myResolve(
																						history.push(
																							'/lap-common-fields-form'
																						)
																					);
																				}
																			} else if (
																				item?.lender_form_page_status_flag == 2
																			) {
																				myResolve(
																					history.push(
																						'/verify-lender-with-otp'
																					)
																				);
																			} else if (
																				item?.lender_form_page_status_flag == 3
																			) {
																				if (
																					productCategoryId == 2 &&
																					item?.lender_name ===
																						'Aditya Birla Finance Ltd.'
																				) {
																					myResolve(
																						history.push('/rm-selection-form')
																					);
																				} else if (
																					item?.lender_name ===
																						'Aditya Birla Finance Ltd.' ||
																					item?.lender_name === 'CASHe' ||
																					item?.lender_name ===
																						'Bhanix Finance' ||
																					item?.lender_name === 'Moneyview' ||
																					item?.lender_name ===
																						'Fibe (formerly EarlySalary)' ||
																					item?.lender_name === 'Fibe' ||
																					item?.lender_name === 'MoneyTap'
																				) {
																					dispatch(selectedLender([item]));
																					dispatch(
																						lenderSpfDetailsFlow({
																							step: 'step1',
																							backButton: true,
																							needArrow: true,
																							needCross: false,
																						})
																					);
																					history.replace(
																						'/lead-lender-specific-form-steps'
																					);
																				} else {
																					myResolve(
																						history.push('/rm-selection-form')
																					);
																				}
																			} else if (
																				item?.lender_form_page_status_flag == 4
																			) {
																				if (
																					item.lender_name == 'Piramal Capital'
																				) {
																					history.push('/bank-online-form');
																				} else {
																					getLenderSpfInfo(item);
																				}
																			} else if (
																				item?.lender_form_page_status_flag == 5
																			) {
																				handleLenderFormCompleted(item);
																			}
																		} else {
																			myResolve(
																				history.push('/verify-lender-with-otp')
																			);
																		}
																	}
																}
															}
														}, 10);
													});
													myPromise.then(() => {
														dispatch(selectedLender([item]));
													});
												}}
												lines={isWeb ? 'none' : 'full'}
												className={`d-flex align-items-start rounded-3 position-relative w-100 ion-no-padding remove-ion-item-inner-padding-end ${
													isWeb ? ' border' : ''
												} ${enableButton ? 'cursor-pointer' : ''} ${
													bulkUpdate && isClosedStatus
														? 'bg-light-gray-opacity'
														: ''
												}`}
											>
												<div
													className={`d-flex p-2 justify-content-between w-100 rounded-3 h-100 ${
														isWeb ? ' ' : ''
													}`}
												>
													<div className='d-flex'>
														<div className='d-flex justify-content-center me-2'>
															{bulkUpdate && (
																<div>
																	<CustomCheckbox
																		labelPlacement='end'
																		isChecked={
																			isClosedStatus ? false : item.isChecked
																		}
																		disabled={isClosedStatus}
																		onChange={(event: any) =>
																			activeLender(event, item.id)
																		}
																	/>
																</div>
															)}
															<div
																className='border ms-2 d-flex'
																style={{
																	borderRadius: '50%',
																	width: '48px',
																	height: '48px',
																}}
															>
																<img
																	src={setBankIcon(item?.lender_name)}
																	alt={item.lender_name}
																	style={{
																		minWidth: '48px',
																		minHeight: '48px',
																	}}
																/>
															</div>
														</div>
														<div>
															{leadInfo?.leadStatus !==
																LEAD_STATUS.LOGIN_LABEL &&
																leadInfo?.leadStatus !==
																	LEAD_STATUS.SANCTIONED_LABEL &&
																leadInfo?.leadStatus !==
																	LEAD_STATUS.DISBURSED_LABEL && (
																	<>
																		<h4
																			className='m-0 fs-14'
																			style={{
																				color: isClosedStatus
																					? 'gray'
																					: 'initial',
																			}}
																		>
																			{item.lender_name}
																		</h4>
																		{/* <div className='fs-12 text-grey'>
																			{`${item?.roi ? item.roi + '%p.a.' : ''}`}
																		</div> */}
																	</>
																)}
															{(leadInfo?.leadStatus ===
																LEAD_STATUS.LOGIN_LABEL ||
																leadInfo?.leadStatus ===
																	LEAD_STATUS.SANCTIONED_LABEL ||
																leadInfo?.leadStatus ===
																	LEAD_STATUS.DISBURSED_LABEL) && (
																<>
																	<p
																		style={{ color: '#5D5E72' }}
																		className='fs-14 mb-0'
																	>
																		{item.code}
																	</p>

																	<h5 className='fs-16 fw-600'>
																		{item?.lender_name}
																	</h5>

																	<p
																		style={{ color: '#5D5E72' }}
																		className='fs-14 mb-0'
																	>
																		{t('Bank App. No.')}: {item?.bank_app_no}
																	</p>
																	{item?.sanction_amount && (
																		<p
																			style={{ color: '#5D5E72' }}
																			className='fs-14 mb-0'
																		>
																			{t('Sanctioned Amount')}:{' '}
																			{`₹ ${InputFormatCurrencyValue(
																				item?.sanction_amount
																			)}`}
																		</p>
																	)}
																</>
															)}
															{findCurrentStatus(item?.status).label && (
																<div className='d-flex'>
																	<CustomButton
																		className={`fs-12 fw-600 dashboard-add-lead-button ${
																			!buttonCLick
																				? 'button-cursor-unset cursor-pointer-unset'
																				: ''
																		}`}
																		expand='block'
																		fill={'outline'}
																		disabled={isClosedStatus}
																		onClick={() => {
																			if (buttonCLick) {
																				history.push('/update-status');

																				dispatch(selectedLender([item]));

																				dispatch(
																					leadFlow({
																						...leadInfo,
																						leadStatus: findCurrentStatus(
																							item.status
																						).id,
																						redirectToDetailPage: false,
																						update: leadInfo.update + 1 || 1,
																						lead_app_id: item?.id,
																					})
																				);
																			} else {
																				if (
																					item.app_off_status == 'offline' &&
																					!bulkUpdate &&
																					!item.status
																				) {
																					history.push('/bank-visit-site');
																					dispatch(selectedLender([item]));
																				}
																				if (
																					item.app_off_status == 'online' &&
																					!bulkUpdate &&
																					!item.status
																				) {
																					if (
																						isLenderIncludes(
																							item.lender_name?.toLowerCase()
																						)
																					) {
																						dispatch(
																							leadFlow({
																								...leadInfo,
																								lead_app_id: item?.id,
																							})
																						);

																						if (
																							item?.lender_form_page_status_flag ==
																							1
																						) {
																							if (
																								leadData?.selectedProductData
																									?.product_category_id == 1
																							) {
																								history.push(
																									'/pl-common-fields-form'
																								);
																							} else if (
																								leadData?.selectedProductData
																									?.product_category_id == 2
																							) {
																								history.push(
																									'/bl-common-fields-form'
																								);
																							} else if (
																								leadData?.selectedProductData
																									?.product_category_id == 3
																							) {
																								history.push(
																									'/hl-common-fields-form'
																								);
																							} else if (
																								leadData?.selectedProductData
																									?.product_category_id == 4
																							) {
																								history.push(
																									'/lap-common-fields-form'
																								);
																							}
																						} else if (
																							item?.lender_form_page_status_flag ==
																							2
																						) {
																							history.push(
																								'/verify-lender-with-otp'
																							);
																						} else if (
																							item?.lender_form_page_status_flag ==
																							3
																						) {
																							history.push(
																								'/rm-selection-form'
																							);
																						} else if (
																							item?.lender_form_page_status_flag ==
																							4
																						) {
																							if (
																								item.lender_name ==
																								'Piramal Capital'
																							) {
																								history.push(
																									'/bank-online-form'
																								);
																							} else {
																								getLenderSpfInfo(item);
																							}
																						} else if (
																							item?.lender_form_page_status_flag ==
																							5
																						) {
																							handleLenderFormCompleted(item);
																						}
																					} else {
																						history.push(
																							'/verify-lender-with-otp'
																						);
																						dispatch(selectedLender([item]));
																					}
																				}
																			}
																		}}
																		style={{
																			height: '28px',
																		}}
																	>
																		{t(
																			`${findCurrentStatus(item?.status).label}`
																		)}
																		{/* // {leadInfo?.leadStatus == */}
																		{/* {t(`${findStatus(item?.status)}`)} */}
																		{/* {leadInfo?.leadStatus ==
																			LEAD_STATUS.DISBURSED && (
																			<img
																				className='ms-2'
																				src='images/icons/edit.svg'
																				alt='edit'
																			/>
																		)} */}
																	</CustomButton>
																</div>
															)}
														</div>
													</div>
													<div>
														<div className='fs-12 text-capitalize'>
															<IonChip
																className={`fs-12 ${
																	item?.app_off_status?.toLowerCase() ===
																	'online'
																		? 'chip-online'
																		: 'chip-offline'
																}`}
																style={{
																	padding: '10px',
																	height: '23px',
																}}
															>
																{t(item?.app_off_status)}
															</IonChip>
														</div>
													</div>
												</div>
											</IonItem>
										</IonCol>
									);
								})}
							</IonRow>
						) : (
							<CustomNoData
								className='py-3'
								message={t('No Data Found')}
							/>
						)}
					</div>
				</div>
			</IonContent>
			<IonFooter>
				{leadInfo?.from === 'customer' && (
					<div className='ion-padding d-flex justify-content-center justify-content-md-end lead-lender-list-container-spacing'>
						<CustomButton
							className=' fw-bold dashboard-add-lead-button'
							expand='block'
							title={t('Done')}
							style={{ width: isWeb ? '160px' : '100%' }}
							// disabled={leadInfo?.from === 'nimbuss' ? true : false}
							onClick={() => {
								history.replace('/custom-successfull', {
									type: 'AAM',
									message: 'Lead Creation Done Successfully',
									redirectTo: '',
								});
								dispatch(
									customSuccessfull({
										type: 'AAM',
										message: 'Lead Creation Done Successfully',

										redirectTo: '',
									})
								);
							}}
						/>
					</div>
				)}
				{bulkUpdate && (
					<>
						<div className='lead-lender-list-container-spacing ion-padding bg-white'>
							<div className='d-flex align-items-center justify-content-between justify-content-md-end flex-row'>
								<div className='fw-700 me-md-4'>
									{count} {t('Selected')}
								</div>
								<CustomButton
									className=' fw-bold dashboard-add-lead-button text-transform-none'
									expand='block'
									disabled={!count}
									title={t('Mark as Closed')}
									onClick={selectedClodesLender}
								/>
							</div>
						</div>
					</>
				)}
				{lenderlist?.length > 0 && isShowButton && !bulkUpdate && (
					<div className='lead-lender-list-container-spacing ion-padding bg-white'>
						<div className='d-md-flex align-items-center justify-content-between justify-content-md-end flex-row'>
							<CustomButton
								className=' fw-bold dashboard-add-lead-button'
								expand='block'
								title={t('Go to Details')}
								onClick={() => {
									const myPromise = new Promise(function (myResolve) {
										setTimeout(function () {
											if (leadInfo?.from === 'nimbuss') {
												myResolve(
													history.push(
														'/lead-details/' +
															leadInfo.leadId +
															'/' +
															dmaSelector?.user_details?.access_token
													)
												);
											} else {
												myResolve(history.push('/lead-details'));
											}
										}, 10);
									});
									myPromise.then(() => {
										dispatch(
											leadFlow({
												...leadInfo,
												leadStatus: leadInfo.leadStatus,
												isFromLenderSpecifiction: true,
											})
										);
									});
								}}
								fill='outline'
							/>
							{leadInfo?.from !== 'nimbuss' && (
								<CustomButton
									className=' fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Go to Lead Dashboard')}
									// disabled={leadInfo?.from === 'nimbuss' ? true : false}
									onClick={() => {
										history.push('/dashboard/lead');
									}}
								/>
							)}
						</div>
					</div>
				)}
			</IonFooter>
			<CustomModal
				id='customModalRework'
				isOpen={showAssignModal}
				modalTitle={t('Select Assignee')}
				initialBreakpoint={1}
				handleClose={() => setShowAssignModal(false)}
				breakpoints={[0, 1]}
				className='assignModal side-modal'
				modalContent={
					<CustomAllocateManagers
						eventType='Lead'
						id={leadInfo?.leadId}
						setShowAssignModal={setShowAssignModal}
					/>
				}
				scrollY={true}
				needCross={true}
				needArrow={false}
			/>
			<CustomModal
				id=''
				isOpen={modalPageClose}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setModalPageClose(false)}
				breakpoints={[]}
				className='confirm-popup-middle height-auto'
				modalContent={PageExitPopUp(
					() => {
						setModalPageClose(false);
					},
					() => {
						const myPromise = new Promise(function (myResolve) {
							setTimeout(function () {
								setSelectedValue('select');
								myResolve(
									leadInfo?.from === 'nimbuss'
										? history.push(
												'/lead-details/' +
													leadInfo.leadId +
													'/' +
													dmaSelector?.user_details?.access_token
										  )
										: history.push('/dashboard/lead')
								);
							}, 10);
						});

						myPromise.then(() => {
							dispatch(
								leadFlow({
									...leadInfo,
									leadStatus:
										leadInfo?.lead_status === LeadStatus.PROCESSING
											? LEAD_STATUS.FULFILLMENT_LABEL
											: leadInfo?.leadStatus,
								})
							);
							setModalPageClose(false);
						});
					},
					'Cancel',
					'Continue Later',
					'lead-pop-up',
					'Are You Sure You Want to Exit?',
					`${t('You can continue later from where you left off.')}`
				)}
				needCross={false}
				needArrow={false}
				needIonContentWraper={false}
			/>
		</IonPage>
	);
};
