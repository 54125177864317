import { IonContent } from '@ionic/react';
import React, { useEffect, useState } from 'react';
// import { Header } from '../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import './Teams.scss';
import TeamsDma from './teams-dma/TeamsDma';
import TeamsBusinessOps from './teams-business-ops/TeamsBusinessOps';
import { USER_ROLES } from '../../helpers/constant';
import { Team } from './CentralOps/Team';
import { CustomNoData } from '../../components/common/custom-no-data/CustomNoData';
import { useDispatch, useSelector } from 'react-redux';
import { imgUrlUpdate } from '../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { Header } from '../../components/layout/header/Header';
import { Menu } from '../../components/layout/menu/Menu';
import { resetDmaFilterState } from '../../store/reducers/dmaSlice';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';

// const NoAccess = () => {
// 	return <IonContent className='ion-padding text-center'>No Access</IonContent>;
// };
const defaultSrc = '/images/icons/default/user-profile-icon.svg';

export const Teams: React.FC = () => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const role = useSelector((state: any) => state?.users?.user_details);
	const appWeb = useSelector((state: any) => state?.app?.isWeb);
	const homePageData = useSelector((state: any) => state?.users);
	const [src, setSrc] = useState('');
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [teamsVideo, setTeamsVideo] = useState<any>('');

	useEffect(() => {
		dispatch(resetDmaFilterState());
		setTeamsVideo('');
		getUpdateUrl();
	}, [homePageData?.homepage?.profile?.url]);

	const getUpdateUrl = async () => {
		if (homePageData?.homepage?.profile?.url) {
			await dispatch(
				imgUrlUpdate({
					data: {
						url: homePageData?.homepage?.profile?.url,
						expire_time: 50000,
					},
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						setSrc(response?.url);
					}
				});
		} else {
			setSrc(defaultSrc);
		}
	};

	return (
		// <IonPage>
		// <>
		<>
			<Menu
				name={homePageData?.homepage?.profile?.name}
				imgSrc={src}
			/>
			<div
				className='dashboard h-100'
				id='dashboard-main-content-menu'
			>
				<Header
					name={t('Teams')}
					// type={'dashboard'}
					// hideWelecomeText={appWeb}
					style={{ borderBottom: '1px solid #00000029' }}
					src={src}
					// needArrowOnClick={() => history.replace('/dashboard/home')}
					// name={t('Products')}
					type={appWeb ? 'dashboard' : ''}
					hideWelecomeText={appWeb}
					// src={src}
					// style={{ borderBottom: '1px solid #00000029' }}
					endItem={
						teamsVideo ? (
							<p
								className='fs-14 m-0 text-grey cursor-pointer'
								slot='end'
								onClick={() => {
									setOpenModal(true);
								}}
							>
								{t('How To Invite Team Members')}
							</p>
						) : (
							''
						)
					}
				/>

				<RoleBasedComponent
					role={role?.roleType?.[0] ? role?.roleType?.[0] : ''}
					setTeamsVideo={setTeamsVideo}
				/>
				<CustomModal
					id='custommodal'
					modalTitle=''
					initialBreakpoint={undefined}
					className='videoplayerpopup vertical-player'
					isOpen={openModal}
					handleClose={() => setOpenModal(false)}
					breakpoints={[]}
					modalContent={
						<iframe
							src={teamsVideo}
							width='100%'
							height='100%'
							frameBorder='0'
							allow='autoplay; fullscreen; picture-in-picture'
							allowFullScreen
						></iframe>
					}
					needCross={false}
					needArrow={false}
					needIonContentWraper={false}
				/>
			</div>
		</>
		// </>
		// </IonPage>
	);
};
const RoleBasedComponent = ({ role, setTeamsVideo }: any) => {
	switch (role) {
		case USER_ROLES.DMA:
			return <TeamsDma setTeamsVideo={setTeamsVideo} />;
		case USER_ROLES.BM:
		case USER_ROLES.BUSINESSOPS:
		case USER_ROLES.BUHOD:
		case USER_ROLES.BUH:
		case USER_ROLES.TC:
			return <TeamsBusinessOps />;
		case USER_ROLES.CENTRALOPS:
		case USER_ROLES.CENTRALOPSJR:
			return <Team />;
		default:
			return (
				<IonContent>
					<CustomNoData message='No Access' />
				</IonContent>
			);
	}
};
