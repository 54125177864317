import * as yup from 'yup';


export const occupation_details_schema = (isProposer: boolean) => {
  return yup.object({
    occupationType: isProposer 
      ? yup.string().required('Occupation type is required') 
      : yup.string().notRequired(),
    companyName: isProposer 
      ? yup.string().required('Company name is required') 
      : yup.string().notRequired(),
    jobDescription: isProposer 
      ? yup.string().required('Job description is required') 
      : yup.string().notRequired(),
    annualIncome: isProposer 
      ? yup.string().required('Annual income is required') 
      : yup.string().notRequired(),
    totalWorkExp: isProposer 
      ? yup.string().required('Total work experience is required') 
      : yup.string().notRequired(),
  });
};