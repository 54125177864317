import { toast } from 'react-toastify';

export const showToastAlert = ({
	type,
	message,
	autoClose = true,
	isDismiss = true,
	toastId = 1,
}) => {
	if (autoClose) {
		autoClose = 3000;
	}
	if (isDismiss) {
		toast.dismiss();
	}
	if (type === 'error') {
		toast.error(message, {
			toastId: toastId,
			position: 'bottom-center',
			autoClose: autoClose,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			// theme: "light",
			icon: false,
			className: 'toast-msg toast-error',
		});
	} else if (type === 'info') {
		toast.info(message, {
			toastId: toastId,
			position: 'bottom-center',
			autoClose: autoClose,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			// theme: "light",
			icon: false,
			className: 'toast-msg toast-info',
		});
	} else {
		toast.success(message, {
			toastId: toastId,
			position: 'bottom-center',
			autoClose: autoClose,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			// theme: "light",
			icon: false,
			className: 'toast-msg toast-success',
		});
	}
};

export const consentForm = () =>{
    console.log('consentForm');
}