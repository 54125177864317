/* eslint-disable @typescript-eslint/no-explicit-any */
import { Header } from '../../components/layout/header/Header';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { LEADS_TYPE, LEAD_STATUS } from '../../helpers/constant';
import { IonCol, IonContent, IonGrid, IonRow, isPlatform } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import ScrollerTab from '../../components/common/scroller-tab/ScrollerTab';
import { useDispatch, useSelector } from 'react-redux';
import {
	dashboardLeadCount,
	dashboardLeadProductType,
	leadBureauCustomer,
	leadFlow,
	markAsClose,
	resetLeadFilterState,
	selectedLender,
} from '../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { Sidebar } from '../../components/layout/sidebar/Sidebar';
import { imgUrlUpdate } from '../../store/reducers/user/userSlice';
import { Menu } from '../../components/layout/menu/Menu';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
import useApi from '../insurance/api/useApi';
import { INSURANCE_DASHBOARD_LEAD_COUNTS } from '../../helpers/api-endpoints';
import { AuthContext } from '../insurance/context/AuthContext';
import {
	eventAttributes,
	findCurrentStatus,
	notifyHandler,
} from '../../helpers/functions';
import { NotifyVisitors } from 'capacitor-plugin-notifyvisitors';

interface IleadsData {
	total_application_fullfillment_count?: number;
	total_bureau_loan_count?: number;
	total_closed_loan_count?: number;
	total_disbursed_loan_count?: number;
	total_generation_loan_count?: number;
	total_lead_processing_loan_count?: number;
	total_loan_count?: number;
	total_login_loan_count?: number;
	total_sanctioned_loan_count?: number;
	total_selection_loan_count?: number;
	Policy_Bond_Received?: number;
	dueForRenewal?: number;
	Renewed?: number;
	Policy_Bond_Pending?: number;
	PLVC?: number;
	PLVC_Allocated?: number;
	PLVC_Rejected?: number;
	Policy_Login?: number;
	Proposal_Detail_Review?: number;
	Payment?: number;
	Policy_Issuance?: number;
	insuranceSelection?: number;
}

const defaultSrc = '/images/icons/default/user-profile-icon.svg';

export const Leads: React.FC = () => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const history = useHistory();
	const location: any = useLocation();
	const [leadsData, setLeadsData] = useState<IleadsData | any>();
	const [insuranceData, setInsuranceData] = useState([]);
	const [leadTypes, setLeadTypes] = useState<any>([]);
	const [activeLeadType, setActiveLeadType] = useState<any>(LEADS_TYPE.LOAN);
	const [selectedProductId, setSelectedProductId] = useState<any>(1);
	const leadFlowSelector = useSelector((state: any) => state?.lead);
	const homePageData = useSelector((state: any) => state?.users);
	const [src, setSrc] = useState('');
	const appWeb = useSelector((state: any) => state?.app?.isWeb);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [leadDashboardVideo, setLeadDashboardVideo] = useState<any>('');
	const customerInfo = useSelector(
		(state: any) => state?.lead?.leadBureauCustomerData
	);
	// Notify Visitors
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const userName = useSelector(
		(state: any) => state?.users?.homepage?.profile?.name
	);
	const userMobile = useSelector(
		(state: any) => state?.users?.user_details?.mobile
	);
	const entityType = useSelector(
		(state: any) => state?.users?.user_details?.entityType
	);
	const userLang = useSelector(
		(state: any) => state?.oneTimeScreen?.language?.name
	);

	const { isIFA, isDMACoord, isCOPS, isCOPSExecutive } =
		useContext(AuthContext);

	useEffect(() => {
		getLeadsType();
		getUpdateUrl();
	}, [location?.state?.listUpdate]);

	const userAttributes = {
		userId: userId,
		attributes: {
			name: userName,
			id: userId,
			mobile: userMobile,
			user_language: userLang,
			entityType:
				entityType === 'Individual'
					? 'DMA'
					: entityType === 'BTM'
					? 'employee'
					: entityType,
		},
	};
	// Notify Visitors
	useEffect(() => {
		if (isPlatform('android') || isPlatform('ios')) {
			notifyHandler('event', eventAttributes('lead_page'));
			notifyHandler('user', userAttributes.attributes);
			const inAppData = {
				tokens: { name: userAttributes },
				customRules: { banerrType: 'lead page' },
			};
			NotifyVisitors.show(inAppData);
		} else {
			(window as any).nv('event', 'lead_page', 10, 1);
			(window as any).nv('user', userId, userAttributes.attributes);
		}
	}, []);

	const getAllLeadsDetails = async (product_id: any, product_name: any) => {
		await dispatch(
			dashboardLeadCount({
				param: `?product_id=${product_id}&product_name=${product_name}`,
			})
		)
			.then(unwrapResult)
			.then(async (response: any) => {
				if (response?.status) {
					setLeadsData(response?.leads?.[0]);
					setLeadDashboardVideo(response?.video_link);
					await dispatch(selectedLender([]));
				}
			});
	};

	const getLeadsType = async () => {
		await dispatch(dashboardLeadProductType())
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setActiveLeadType(response?.data[0].value);
					setLeadTypes(response?.data);
					setSelectedProductId(response?.data[0].id);
					getAllLeadsDetails(response?.data[0].id, response?.data[0].value);
				}
			});
	};

	const insuranceCountApi = useApi<any>(INSURANCE_DASHBOARD_LEAD_COUNTS);
	useEffect(() => {
		insuranceCountApi.executeRequest('post');
	}, []);
	useEffect(() => {
		if (insuranceCountApi?.responseData?.status) {
			setInsuranceData(insuranceCountApi?.responseData?.data[0]);
		}
	}, [insuranceCountApi?.responseData]);
	window.onload = async function () {
		insuranceCountApi?.executeRequest('post', true).then(() => {
			setInsuranceData(insuranceCountApi?.responseData?.data[0]);
		});
	};
	const handleTabLeadTypeChange = (product: any) => {
		console.log(product, 'product');
		setLeadsData({});
		setActiveLeadType(product.value);
		setSelectedProductId(product.id);
		if (product.value == LEADS_TYPE.LOANS || product.value == LEADS_TYPE.LOAN) {
			getAllLeadsDetails(product.id, product.value);
		} else if (product.value == LEADS_TYPE.INSURANCE) {
			// Insurance Dashboard Call
			// console.log(insuranceData);
			setLeadsData(insuranceData);
		}
	};

	const navigateToLeadList = (lead_status: string) => {
		console.log(lead_status);
		if (activeLeadType === LEADS_TYPE.INSURANCE) {
			console.log(
				findCurrentStatus(lead_status).label,
				'Insurance Tab Selected'
			);
			localStorage.setItem('labelName', lead_status);
			// history.push({
			// 	pathname: '/insurance-lead-list',
			// 	state: { labelName: lead_status }
			// });
			history.push('/insurance-lead-list');
		} else {
			const _listUpdate = (leadFlowSelector?.leadFlowData?.update || 0) + 1;
			const myPromise = new Promise(function (myResolve) {
				setTimeout(function () {
					myResolve(
						history.push('/lead-list', {
							leadStatus: lead_status,
							leadTypeName: activeLeadType,
							leadTypeId: selectedProductId,
							update: _listUpdate,
						})
					);
				}, 10);
			});
			myPromise.then(function () {
				dispatch(resetLeadFilterState());
				dispatch(
					leadFlow({
						...leadFlowSelector.leadFlowData,
						updateLeadFilter: true,
						leadStatus: findCurrentStatus(lead_status).label,
						update: _listUpdate,
						isDisbursedFlow: lead_status === LEAD_STATUS.DISBURSED,
						applicationStatus: lead_status,
						isAllLead: lead_status === 'all' ? 'all' : '',
						isRawLead: lead_status === LEAD_STATUS.RAW ? 'raw' : '',
						leadTypeName:
							lead_status === LEAD_STATUS?.CREDIT_BUREAU
								? null
								: activeLeadType,
						productType:
							lead_status === LEAD_STATUS?.CREDIT_BUREAU
								? null
								: activeLeadType,
						leadTypeId:
							lead_status === LEAD_STATUS?.CREDIT_BUREAU
								? null
								: selectedProductId,
						isPartial: '',
						isLenderSelected: false,
					})
				);

				dispatch(markAsClose(false));
			});
		}
	};

	const getUpdateUrl = async () => {
		if (homePageData?.homepage?.profile?.url) {
			await dispatch(
				imgUrlUpdate({
					data: {
						url: homePageData?.homepage?.profile?.url,
						expire_time: 50000,
					},
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						setSrc(response?.url);
					}
				});
		} else {
			setSrc(defaultSrc);
		}
	};

	return (
		<>
			<Menu
				name={homePageData?.homepage?.profile?.name}
				imgSrc={src}
			/>
			<div
				id='dashboard-main-content-menu'
				className='dashboard h-100'
			>
				<Header
					name={t('Leads')}
					type={appWeb ? 'dashboard' : ''}
					hideWelecomeText={appWeb}
					style={{ borderBottom: '1px solid #00000029' }}
					src={src}
					endItem={
						leadDashboardVideo ? (
							<p
								className='fs-14 m-0 text-grey cursor-pointer'
								slot='end'
								onClick={() => {
									setOpenModal(true);
								}}
							>
								{t('Know Your Dashboard')}
							</p>
						) : (
							''
						)
					}
				/>
				<div className='d-flex'>
					<Sidebar />
					{/* <div className='container container-padding-2'> */}
					<IonContent className='dashboard-main dashboard-height'>
						<div className='bg-web py-md-3'>
							<div className='bg-web-white h-100 container-width-md border-md-radius-10'>
								{leadDashboardVideo ? (
									<span
										className='d-none d-md-inline-block float-end fs-14 m-0 text-grey cursor-pointer text-end me-2 pt-2'
										slot='end'
										onClick={() => {
											setOpenModal(true);
										}}
									>
										{t('Know Your Dashboard')}
									</span>
								) : (
									''
								)}
								<ScrollerTab
									{...{
										handleTabLeadTypeChange,
										activeLeadType,
										leadTypes,
									}}
								/>
								<IonGrid>
									<IonRow>
										<IonCol
											size='12'
											className='mt-3'
										>
											<div
												className='lead-card-linear-gradient cursor-pointer lead-border rounded-3 text-white p-3 align-items-center lead-card-height-1'
												onClick={() => navigateToLeadList('all')}
											>
												<div className='d-flex  align-items-center justify-content-between'>
													<div className=''>
														<img
															src='/images/icons/leads/all.svg'
															alt='all lead'
															style={{
																width: '32px',
																height: '32px',
															}}
														/>
														{t('All Leads')}
													</div>
													<div className='text-center fs-md-24 font-semibold'>
														{leadsData?.All_Leads | 0}
													</div>
												</div>
											</div>
										</IonCol>{' '}
										{/* {leadsData?.rawLeads ? (
											<IonCol
												size='12'
												className=''
											>
												<div
													onClick={() => navigateToLeadList(LEAD_STATUS.RAW)}
													className='blue-corner-linerar-gradient lead-border rounded-3 p-3 lead-card-height-3 cursor-pointer'
												>
													<div className='d-flex  align-items-center justify-content-between'>
														<div className='fs-14'>
															<img
																src='/images/icons/leads/application-fulfilment-icon.svg'
																alt='Application Fulfillment'
															/>
															{t('Raw Leads')}
														</div>
														<div className='text-center fs-md-24 font-semibold'>
															{leadsData?.rawLeads | 0}
														</div>
													</div>
												</div>
											</IonCol>
										) : (
											''
										)} */}
										{[LEADS_TYPE.LOAN, LEADS_TYPE.LOANS].includes(
											activeLeadType
										) && (
											<IonCol
												size='12'
												className=''
											>
												<div
													onClick={() => {
														navigateToLeadList(LEAD_STATUS?.CREDIT_BUREAU);
														dispatch(
															leadBureauCustomer({
																...customerInfo,
																leadId: '',
																cibil_id: '',
															})
														);
													}}
													className='blue-corner-linerar-gradient lead-border rounded-3 p-3 lead-card-height-3 cursor-pointer'
												>
													<div className='d-flex  align-items-center justify-content-between'>
														<div className='fs-14'>
															<img
																src='/images/icons/leads/application-fulfilment-icon.svg'
																alt='Application Fulfillment'
															/>
															{t('Check Bureau')}
														</div>
														<div className='text-center fs-md-24 font-semibold'>
															{leadsData?.check_bureau | 0}
														</div>
													</div>
												</div>
											</IonCol>
										)}
										{isCOPS && activeLeadType === LEADS_TYPE.INSURANCE && (
											<IonCol
												size='12'
												className=''
											>
												<div
													onClick={() => {
														navigateToLeadList(LEAD_STATUS?.PLVC);
													}}
													className='blue-corner-linerar-gradient lead-border rounded-3 p-3 lead-card-height-3 cursor-pointer'
												>
													<div className='d-flex  align-items-center justify-content-between'>
														<div className='fs-14'>
															<img
																src='/images/icons/leads/application-fulfilment-icon.svg'
																alt='Application Fulfillment'
															/>
															{t('PLVC')}
														</div>
														<div className='text-center fs-md-24 font-semibold'>
															{leadsData?.PLVC | 0}
														</div>
													</div>
												</div>
											</IonCol>
										)}
										{[LEADS_TYPE.LOAN, LEADS_TYPE.LOANS].includes(
											activeLeadType
										) && (
											<IonCol
												size='6'
												className=''
											>
												<div
													onClick={() =>
														navigateToLeadList(LEAD_STATUS.GENERATED)
													}
													className='blue-corner-linerar-gradient lead-border rounded-3 px-3 py-2 lead-card-height-2 cursor-pointer'
												>
													<div className='fs-md-24 font-semibold d-flex justify-content-between'>
														{leadsData?.Lead_Generation | 0}
														<img
															src='/images/icons/leads/lead-generation-icon.svg'
															alt='Lead Generation'
														/>
													</div>
													<div className='fs-14'>{t('Leads Generated')}</div>
												</div>
											</IonCol>
										)}
										{[LEADS_TYPE.LOAN, LEADS_TYPE.LOANS].includes(
											activeLeadType
										) && (
											<IonCol
												size='6'
												className=''
											>
												<div
													onClick={() => navigateToLeadList(LEAD_STATUS.BUREAU)}
													className='blue-corner-linerar-gradient lead-border rounded-3 px-3 py-2 lead-card-height-2 cursor-pointer'
												>
													<div className='fs-md-24 font-semibold d-flex justify-content-between'>
														<div>{leadsData?.Lead_Bureau | 0}</div>
														<img
															src='/images/icons/leads/bureau-icon.svg'
															alt='bureau icon'
														/>
													</div>
													<div className='fs-14'>{t('Credit Reports')}</div>
												</div>
											</IonCol>
										)}
										{isIFA && activeLeadType === LEADS_TYPE.INSURANCE && (
											<IonCol
												size='6'
												className=''
											>
												<div
													onClick={() =>
														navigateToLeadList(
															LEAD_STATUS.INSURANCE_LEAD_GENERATION
														)
													}
													className='blue-corner-linerar-gradient lead-border rounded-3 px-3 py-2 lead-card-height-2 cursor-pointer'
												>
													<div className='fs-md-24 font-semibold d-flex justify-content-between'>
														{leadsData?.Lead_Generation | 0}
														<img
															src='/images/icons/leads/lender-selection-icon.svg'
															alt='lender selection icon'
														/>
													</div>
													<div className='fs-14'>{t('Lead Generation')}</div>
												</div>
											</IonCol>
										)}
										{isIFA && activeLeadType === LEADS_TYPE.INSURANCE && (
											<IonCol
												size='6'
												className=''
											>
												<div
													onClick={() =>
														navigateToLeadList(LEAD_STATUS.INSURER_SELECTION)
													}
													className='blue-corner-linerar-gradient lead-border rounded-3 px-3 py-2 lead-card-height-2 cursor-pointer'
												>
													<div className='fs-md-24 font-semibold d-flex justify-content-between'>
														{leadsData?.insuranceSelection | 0}
														<img
															src='/images/icons/leads/lender-selection-icon.svg'
															alt='leander selection icon'
														/>
													</div>
													<div className='fs-14'>{t('Insurer Selection')}</div>
												</div>
											</IonCol>
										)}
										{(isCOPS || isCOPSExecutive) &&
											activeLeadType === LEADS_TYPE.INSURANCE && (
												<IonCol
													size='6'
													className=''
												>
													<div
														onClick={() =>
															navigateToLeadList(LEAD_STATUS.PLVC_ALLOCATED)
														}
														className='blue-corner-linerar-gradient lead-border rounded-3 px-3 py-2 lead-card-height-2 cursor-pointer'
													>
														<div className='fs-md-24 font-semibold d-flex justify-content-between'>
															{leadsData?.PLVC_Allocated | 0}
															<img
																src='/images/icons/leads/lender-selection-icon.svg'
																alt='leander selection icon'
															/>
														</div>
														<div className='fs-14'>{t('PLVC Allocated')}</div>
													</div>
												</IonCol>
											)}
										{isCOPS && activeLeadType === LEADS_TYPE.INSURANCE && (
											<IonCol
												size='6'
												className=''
											>
												<div
													onClick={() =>
														navigateToLeadList(LEAD_STATUS.PLVC_REJECTED)
													}
													className='blue-corner-linerar-gradient lead-border rounded-3 px-3 py-2 lead-card-height-2 cursor-pointer'
												>
													<div className='fs-md-24 font-semibold d-flex justify-content-between'>
														{leadsData?.PLVC_Rejected | 0}
														<img
															src='/images/icons/leads/lender-selection-icon.svg'
															alt='leander selection icon'
														/>
													</div>
													<div className='fs-14'>{t('PLVC Rejected')}</div>
												</div>
											</IonCol>
										)}
										{(isCOPS || isCOPSExecutive) &&
											activeLeadType === LEADS_TYPE.INSURANCE && (
												<IonCol
													size='6'
													className=''
												>
													<div
														onClick={() =>
															navigateToLeadList(LEAD_STATUS.POLICY_LOGIN)
														}
														className='blue-corner-linerar-gradient lead-border rounded-3 px-3 py-2 lead-card-height-2 cursor-pointer'
													>
														<div className='fs-md-24 font-semibold d-flex justify-content-between'>
															{leadsData?.Policy_Login | 0}
															<img
																src='/images/icons/leads/application-fulfilment-icon.svg'
																alt='Application Fulfillment'
															/>
														</div>
														<div className='fs-14'>{t('Policy Login')}</div>
													</div>
												</IonCol>
											)}
										{isCOPSExecutive &&
											activeLeadType === LEADS_TYPE.INSURANCE && (
												<IonCol
													size='6'
													className=''
												>
													<div
														onClick={() =>
															navigateToLeadList(LEAD_STATUS.POLICY_ISSUANCE)
														}
														className='blue-corner-linerar-gradient lead-border rounded-3 px-3 py-2 lead-card-height-2 cursor-pointer'
													>
														<div className='fs-md-24 font-semibold d-flex justify-content-between'>
															{leadsData?.Policy_Issuance | 0}
															<img
																src='/images/icons/leads/application-fulfilment-icon.svg'
																alt='Application Fulfillment'
															/>
														</div>
														<div className='fs-14'>{t('Policy Issuance')}</div>
													</div>
												</IonCol>
											)}
										{isCOPSExecutive &&
											activeLeadType === LEADS_TYPE.INSURANCE && (
												<IonCol
													size='6'
													className=''
												>
													<div
														onClick={() =>
															navigateToLeadList(
																LEAD_STATUS.POLICY_BOND_PENDING
															)
														}
														className='blue-corner-linerar-gradient lead-border rounded-3 px-3 py-2 lead-card-height-2 cursor-pointer'
													>
														<div className='fs-md-24 font-semibold d-flex justify-content-between'>
															{leadsData?.Policy_Bond_Pending | 0}
															<img
																src='/images/icons/leads/application-fulfilment-icon.svg'
																alt='Application Fulfillment'
															/>
														</div>
														<div className='fs-14'>
															{t('Policy Bond Pending')}
														</div>
													</div>
												</IonCol>
											)}
										{(isCOPS || isCOPSExecutive) &&
											activeLeadType === LEADS_TYPE.INSURANCE && (
												<IonCol
													size='6'
													className=''
												>
													<div
														onClick={() =>
															navigateToLeadList(
																LEAD_STATUS.POLICY_BOND_RECEIVED
															)
														}
														className='blue-corner-linerar-gradient lead-border rounded-3 px-3 py-2 lead-card-height-2 cursor-pointer'
													>
														<div className='fs-md-24 font-semibold d-flex justify-content-between'>
															{leadsData?.Policy_Bond_Received | 0}
															<img
																src='/images/icons/leads/application-fulfilment-icon.svg'
																alt='Application Fulfillment'
															/>
														</div>
														<div className='fs-14'>
															{t('Policy Bond Received')}
														</div>
													</div>
												</IonCol>
											)}
										{(isDMACoord || isCOPS) &&
											activeLeadType === LEADS_TYPE.INSURANCE && (
												<IonCol
													size='6'
													className=''
												>
													<div
														onClick={() =>
															navigateToLeadList(LEAD_STATUS.DUE_FOR_RENEWED)
														}
														className='blue-corner-linerar-gradient lead-border rounded-3 px-3 py-2 lead-card-height-2 cursor-pointer'
													>
														<div className='fs-md-24 font-semibold d-flex justify-content-between'>
															{leadsData?.Due_For_Renewal | 0}
															<img
																src='/images/icons/leads/lead-processing-icon.svg'
																alt='lead processing icon'
															/>
														</div>
														<div className='fs-14'>{t('Due For Renewal')}</div>
													</div>
												</IonCol>
											)}
										{(isDMACoord || isCOPS) &&
											activeLeadType === LEADS_TYPE.INSURANCE && (
												<IonCol
													size='6'
													className=''
												>
													<div
														onClick={() =>
															navigateToLeadList(LEAD_STATUS.RENEWED)
														}
														className='blue-corner-linerar-gradient lead-border rounded-3 px-3 py-2 lead-card-height-2 cursor-pointer'
													>
														<div className='fs-md-24 font-semibold d-flex justify-content-between'>
															{leadsData?.Renewed | 0}
															<img
																src='/images/icons/leads/lead-processing-icon.svg'
																alt='lead processing icon'
															/>
														</div>
														<div className='fs-14'>{t('Renewed')}</div>
													</div>
												</IonCol>
											)}
										{isDMACoord && activeLeadType === LEADS_TYPE.INSURANCE && (
											<IonCol
												size='6'
												className=''
											>
												<div
													onClick={() =>
														navigateToLeadList(
															LEAD_STATUS.PROPOSAL_DETAILS_REVIEW
														)
													}
													className='blue-corner-linerar-gradient lead-border rounded-3 px-3 py-2 lead-card-height-2 cursor-pointer'
												>
													<div className='fs-md-24 font-semibold d-flex justify-content-between'>
														{leadsData?.Proposal_Detail_Review | 0}
														<img
															src='/images/icons/leads/lender-selection-icon.svg'
															alt='leander selection icon'
														/>
													</div>
													<div className='fs-14'>
														{t('Proposal Detail Review')}
													</div>
												</div>
											</IonCol>
										)}
										{isDMACoord && activeLeadType === LEADS_TYPE.INSURANCE && (
											<IonCol
												size='6'
												className=''
											>
												<div
													onClick={() =>
														navigateToLeadList(LEAD_STATUS.PAYMENT)
													}
													className='blue-corner-linerar-gradient lead-border rounded-3 px-3 py-2 lead-card-height-2 cursor-pointer'
												>
													<div className='fs-md-24 font-semibold d-flex justify-content-between'>
														{leadsData?.Payment | 0}
														<img
															src='/images/icons/leads/lender-selection-icon.svg'
															alt='leander selection icon'
														/>
													</div>
													<div className='fs-14'>{t('Payment')}</div>
												</div>
											</IonCol>
										)}
										{[LEADS_TYPE.LOAN, LEADS_TYPE.LOANS].includes(
											activeLeadType
										) && (
											<IonCol
												size='6'
												className=''
											>
												<div
													onClick={() =>
														navigateToLeadList(LEAD_STATUS.PROCESSING)
													}
													className='blue-corner-linerar-gradient lead-border rounded-3 px-3 py-2 lead-card-height-2 cursor-pointer'
												>
													<div className='fs-md-24 font-semibold d-flex justify-content-between'>
														<div>{leadsData?.Lead_Processing | 0}</div>
														<img
															src='/images/icons/leads/lead-processing-icon.svg'
															alt='lead processing icon'
														/>
													</div>
													<div className='fs-14'>{t('Leads Processing')}</div>
												</div>
											</IonCol>
										)}
										{[LEADS_TYPE.LOAN, LEADS_TYPE.LOANS].includes(
											activeLeadType
										) && (
											<IonCol
												size='6'
												className=''
											>
												<div
													onClick={() => {
														navigateToLeadList(LEAD_STATUS.SELECTION);
													}}
													className='blue-corner-linerar-gradient lead-border rounded-3 px-3 py-2 lead-card-height-2 cursor-pointer'
												>
													<div className='fs-md-24 font-semibold d-flex justify-content-between'>
														{leadsData?.Lead_Selection | 0}
														<img
															src='/images/icons/leads/lender-selection-icon.svg'
															alt='leander selection icon'
														/>
													</div>
													<div className='fs-14'>{t('Lender Selection')}</div>
												</div>
											</IonCol>
										)}
										{activeLeadType !== LEADS_TYPE.INSURANCE && (
											<IonCol
												size='12'
												className=''
											>
												<div
													onClick={() =>
														navigateToLeadList(LEAD_STATUS.FULFILLMENT)
													}
													className='blue-corner-linerar-gradient lead-border rounded-3 p-3 lead-card-height-3 cursor-pointer'
												>
													<div className='d-flex  align-items-center justify-content-between'>
														<div className='fs-14'>
															<img
																src='/images/icons/leads/application-fulfilment-icon.svg'
																alt='Application Fulfillment'
															/>
															{t('Application Fulfillment')}
														</div>
														<div className='text-center fs-md-24 font-semibold'>
															{leadsData?.total_application_fullfillment_count |
																0}
														</div>
													</div>
												</div>
											</IonCol>
										)}
										{(isIFA || isDMACoord) &&
											activeLeadType === LEADS_TYPE.INSURANCE && (
												<IonCol
													size='12'
													className=''
												>
													<div
														onClick={() =>
															navigateToLeadList(
																LEAD_STATUS.APPLICATION_FULFILLED
															)
														}
														className='blue-corner-linerar-gradient lead-border rounded-3 p-3 lead-card-height-3 cursor-pointer'
													>
														<div className='d-flex  align-items-center justify-content-between'>
															<div className='fs-14'>
																<img
																	src='/images/icons/leads/application-fulfilment-icon.svg'
																	alt='Application Fulfillment'
																/>
																{t('Application Fulfillment')}
															</div>
															<div className='text-center fs-md-24 font-semibold'>
																{leadsData?.Application_Fulfillment | 0}
															</div>
														</div>
													</div>
												</IonCol>
											)}
										{[LEADS_TYPE.LOAN, LEADS_TYPE.LOANS].includes(
											activeLeadType
										) && (
											<>
												<IonCol
													size='6'
													className=''
												>
													<div
														onClick={() =>
															navigateToLeadList(LEAD_STATUS.LOGIN)
														}
														className='blue-corner-linerar-gradient lead-border rounded-3 px-3 py-2 lead-card-height-2 cursor-pointer'
													>
														<div className='fs-md-24 font-semibold d-flex justify-content-between'>
															{leadsData?.total_login_loan_count | 0}
															<img
																src='/images/icons/leads/login-icon.svg'
																alt='login icon'
															/>
														</div>
														<div className='fs-14'>{t('Login')}</div>
													</div>
												</IonCol>
												<IonCol
													size='6'
													className=''
												>
													<div
														onClick={() => {
															navigateToLeadList(LEAD_STATUS.SANCTIONED);
														}}
														className='blue-corner-linerar-gradient lead-border rounded-3 px-3 py-2 lead-card-height-2 cursor-pointer'
													>
														<div className='fs-md-24 font-semibold d-flex justify-content-between'>
															{leadsData?.total_sanctioned_loan_count | 0}
															<img
																src='/images/icons/leads/sanctioned-icon.svg'
																alt='Sanctioned Icon'
															/>
														</div>
														<div className='fs-14'>{t('Sanctioned')}</div>
													</div>
												</IonCol>
												<IonCol
													size='12'
													className=''
												>
													<div
														onClick={() => {
															navigateToLeadList(LEAD_STATUS.DISBURSED);
														}}
														className='blue-corner-linerar-gradient lead-border rounded-3 p-3 lead-card-height-3 cursor-pointer'
													>
														<div className='d-flex  align-items-center justify-content-between'>
															<div className='fs-14 d-flex flex-row'>
																<img
																	src='/images/icons/leads/disbursed-icon.svg'
																	alt='Application Fulfillment'
																/>

																<div
																	style={{
																		display: 'flex',
																		// flexDirection: 'column',
																		alignItems: 'center',
																		marginLeft: '8px',
																	}}
																>
																	<span className='fs-14'>
																		{t('Disbursed')}
																	</span>
																	{/* <br /> */}
																	{/* <span
																		style={{ color: 'var(--text-dark-grey)' }}
																		className='fs-10'
																	>
																		{t('Current Month')}
																	</span> */}
																</div>
															</div>
															<div className=' text-center fs-md-26 font-semibold'>
																{leadsData?.total_disbursed_loan_count | 0}
															</div>
														</div>
													</div>
												</IonCol>
												{leadsData?.total_allocator_count ? (
													<IonCol
														size='12'
														className=''
													>
														<div
															onClick={() =>
																navigateToLeadList(LEAD_STATUS.ALLOCATOR)
															}
															className='blue-corner-linerar-gradient lead-border rounded-3 p-3 lead-card-height-3 cursor-pointer'
														>
															<div className='d-flex  align-items-center justify-content-between'>
																<div className='fs-14'>
																	<img
																		src='/images/icons/leads/application-fulfilment-icon.svg'
																		alt='Application Fulfillment'
																		className='me-2'
																	/>
																	{t('Allocate Leads')}
																</div>
																<div className='text-center fs-md-26 font-semibold'>
																	{leadsData?.total_allocator_count | 0}
																</div>
															</div>
														</div>
													</IonCol>
												) : (
													''
												)}
											</>
										)}
									</IonRow>
								</IonGrid>
								<div className='lead-break-line' />
								<IonGrid className='mb-5'>
									<IonRow>
										<IonCol
											size='6'
											className='mb-1 d-flex'
										>
											<div
												onClick={() =>
													activeLeadType === LEADS_TYPE.INSURANCE
														? navigateToLeadList(LEAD_STATUS.UNDERPROCESS_LABEL)
														: navigateToLeadList(LEAD_STATUS.UNDERPROCESS)
												}
												className='blue-corner-linerar-gradient lead-border rounded-3 py-1 px-3 w-100 lead-card-height-4 cursor-pointer'
											>
												<div className='fs-md-24 font-semibold d-flex justify-content-between'>
													{leadsData?.Under_Process | 0}
													<img
														src='/images/icons/leads/under-process-icon.svg'
														alt='Sanctioned Icon'
													/>
												</div>
												<div className='fs-14'>{t('Under Process')}</div>
												{/* <div className='fs-14'>{t('Current Month')}</div> */}
											</div>
										</IonCol>
										<IonCol
											size='6'
											className='mb-1 d-flex'
										>
											<div
												onClick={() =>
													activeLeadType === LEADS_TYPE.INSURANCE
														? navigateToLeadList(
																LEAD_STATUS.APPLICATION_CLOSED_LABEL
														  )
														: navigateToLeadList(LEAD_STATUS.CLOSED)
												}
												className='blue-corner-linerar-gradient lead-border rounded-3 py-1 px-3 w-100 lead-card-height-4 cursor-pointer'
											>
												<div className='fs-md-24 font-semibold d-flex justify-content-between'>
													{leadsData?.Closed | 0}
													<img
														src='/images/icons/leads/closed Icon.svg'
														alt='Closed Icon'
													/>
												</div>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
													}}
												>
													<span className='fs-14'>{t('Closed')}</span>
													{/* <br /> */}
													{/* <span
														style={{ color: 'var(--text-dark-grey)' }}
														className='fs-10'
													>
														{t('Current Month')}
													</span> */}
												</div>
											</div>
										</IonCol>
									</IonRow>
								</IonGrid>
							</div>
						</div>
						{/* <div
					className='p-3'
					style={{
						backgroundColor: '#0000000f',
					}}
				>
					<div
						className='p-3 d-flex align-items-center justify-content-center fs-14'
						style={{
							backgroundColor: '#FFFFFF',
							borderRadius: '8px',
						}}
					>
						{t('Leads Allocated to Andro Manager')} {' >>'}
					</div>
				</div> */}

						<CustomModal
							id='custommodal'
							modalTitle=''
							initialBreakpoint={undefined}
							className='videoplayerpopup vertical-player'
							isOpen={openModal}
							handleClose={() => setOpenModal(false)}
							breakpoints={[]}
							modalContent={
								<iframe
									src={leadDashboardVideo}
									width='100%'
									height='100%'
									frameBorder='0'
									allow='autoplay; fullscreen; picture-in-picture'
									allowFullScreen
								></iframe>
							}
							needCross={false}
							needArrow={false}
							needIonContentWraper={false}
						/>
					</IonContent>
					{/* </div> */}
				</div>
			</div>
		</>
	);
};
