import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import React from 'react';
import { Header } from '../../components/layout/header/Header';
import './Activity.scss';

export const Activity: React.FC = () => {
	const router = useIonRouter();
	const activity = [
		{
			name: 'Application Submitted to ICICI Bank',
			date: '12 Feb 2023',
			time: '08:45 pm',
			by: 'By Sunil Palkar',
			tagsRelatedTo: 'Application ID #A987021',
		},
		{
			name: 'Application Submitted to SBI Bank',
			date: '12 Feb 2023',
			time: '08:45 pm',
			by: '',
			tagsRelatedTo: 'Application ID #A987021',
		},
		{
			name: 'Schedule a Call',
			date: '12 Feb 2023',
			time: '08:45 pm',
			by: 'By Sunil Palkar',
			tagsRelatedTo: '',
		},
		{
			name: 'Bureau Report Generated',
			date: '12 Feb 2023',
			time: '08:45 pm',
			by: 'By Sunil Palkar',
			tagsRelatedTo: 'Bureau Stage',
		},
		{
			name: 'Lead Created',
			date: '12 Feb 2023',
			time: '08:45 pm',
			by: 'By Sunil Palkar',
			tagsRelatedTo: 'Lead ID #L987021',
		},
	];

	return (
		<IonPage>
			<Header
				name='Activity'
				titleClassName='fw-600'
				backButton={true}
				needCross={true}
				needCrossOnClick={() => router.goBack()}
				style={{ borderBottom: '1px solid grey' }}
			/>
			<IonContent>
				<div className='activity-logs'>
					{activity &&
						activity.map((item, index) => {
							return (
								<div
									key={`activity-${index}`}
									className='pt-3 py-2 border-bottom'
								>
									<h3 className='fs-16 fw-bold'>{item.name}</h3>
									<div className='d-flex'>
										<h3 className='fs-12 pe-2 d-flex ion-justify-content-center ion-align-items-center gap-2'>
											{item.date}{' '}
											<div
												style={{
													width: '5px',
													borderRadius: '50%',
													backgroundColor: 'grey',
													height: '5px',
												}}
											></div>
										</h3>
										<h3 className='fs-12 pe-2 d-flex ion-justify-content-center ion-align-items-center gap-2'>
											{item.time}{' '}
											<div
												style={{
													width: '5px',
													borderRadius: '50%',
													backgroundColor: 'grey',
													height: '5px',
												}}
											></div>
										</h3>
										<h3 className='fs-12 pe-2'>{item.by}</h3>
									</div>
									{item.tagsRelatedTo && (
										<div className='fs-14 text-grey'>{item.tagsRelatedTo}</div>
									)}
								</div>
							);
						})}
				</div>
			</IonContent>
		</IonPage>
	);
};
