import { IonContent, IonItem, IonPage, isPlatform } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Player } from '@lottiefiles/react-lottie-player';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import { JailbreakRoot } from '@basecom-gmbh/capacitor-jailbreak-root-detection';
import { App } from '@capacitor/app';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
export const AppWelcome: React.FC = () => {
	const history = useHistory();
	const appData = useSelector((state: any) => state.oneTimeScreen);
	const userData = useSelector((state: any) => state.users);
	const isBrowser = !isPlatform('capacitor');
	const [showRootAlert, setShowRootAlert] = useState(false);

	const { t } = useTranslation();

	useEffect(() => {
		if (isBrowser) {
			onAnimationComplete();
		}
	}, [isBrowser]);
	const onAnimationComplete = async () => {
		// const checkIfrooted = (await JailbreakRoot.isJailbrokenOrRooted()).result;
		const checkIfrooted = false;
		if (checkIfrooted) {
			setShowRootAlert(true);
		} else {
			let nextRoute;
			if (!appData?.language) {
				nextRoute = '/choose-language';
			} else if (!appData?.gettingStarted) {
				nextRoute = isBrowser ? '/login' : '/getting-started';
			} else if (userData && userData?.user_details?.step === 'register') {
				nextRoute = '/register-yourself';
			} else if (
				userData &&
				(['register_done', 'dashboard'].includes(
					userData?.user_details?.step
				) ||
					userData?.user_details?.access_token)
			) {
				nextRoute = 'dashboard/home';
			} else {
				nextRoute = '/login';
			}
			history.replace(nextRoute);
		}
	};
	if (isBrowser) {
		return null;
	} else {
		return (
			<IonPage>
				<IonContent>
					<div className='d-flex ion-justify-content-center flex-column vh-100 ion-align-items-center'>
						{/* <IonImg
						src='/images/icons/logo.svg'
						className=''
					/> */}
						<Player
							style={{
								width: '100%',
								maxWidth: '450px',
								// paddingRight: '.5rem',
							}}
							autoplay={true}
							loop={false}
							controls={false}
							keepLastFrame={true}
							onEvent={(event) => {
								if (event === 'complete') {
									setTimeout(() => {
										onAnimationComplete();
									}, 500);
								}
							}}
							src='/images/icons/json/AndromedaSplash.json'
						/>
						{/* <video
						autoPlay
						loop
						src='/logo-video/One Andro 3.mp4'
					></video> */}
					</div>
				</IonContent>
				<CustomModal
					id=''
					isOpen={showRootAlert}
					modalTitle=''
					initialBreakpoint={undefined}
					handleClose={() => setShowRootAlert(false)}
					breakpoints={[]}
					className='confirm-popup-middle height-auto'
					modalContent={
						<div className={`wrapper py-4 confirm-popup-middle height-auto`}>
							<IonItem lines='none'>
								<div className='d-flex flex-column'>
									<p
										className='fs-14 mb-2'
										style={{ color: 'var(--text-dark-grey)' }}
									>
										Phone is Rooted. Please unroot your phone to use this app.
									</p>
								</div>
							</IonItem>
							<div className='d-flex justify-content-center gap-3 px-3'>
								<CustomButton
									className='w-50'
									fill='solid'
									onClick={() => {
										App.exitApp();
									}}
									title={t('Ok')}
								/>
							</div>
						</div>
					}
					needIonContentWraper={false}
					needCross={false}
					needArrow={false}
				/>
			</IonPage>
		);
	}
};
