import {
	IonContent,
	IonPage,
	createGesture,
	Gesture,
	// useIonRouter,
} from '@ionic/react';
import React, { useEffect, useRef } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { InstallReferrer } from '@togetherprice/capacitor-plugin-install-referrer';
import ReactGA from 'react-ga4';
// import { Header } from '../../components/layout/header/Header';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
import {
	APP_VERSION,
	GA4_TRACKING_ID,
	STATUS_CODE,
} from '../../helpers/constant';
import { useHistory, useLocation } from 'react-router';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { App as CapacitorApp } from '@capacitor/app';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { REGEX } from '../../helpers/constant';
import CustomNote from '../../components/common/custom-note/CustomNote';
import { StartPage } from '../getting-started/GettingStarted';
import { useDispatch, useSelector } from 'react-redux';
import { apiLogin } from '../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { ga4Data, otpScreenShowPage } from '../../store/reducers/app/appSlice';
import {
	// TrackGoogleAnalyticsEvent,
	disableNumberArrows,
} from '../../helpers/functions';
import { leadFlow } from '../../store/reducers/lead/leadSlice';
import { SmsRetriever } from '@awesome-cordova-plugins/sms-retriever';
import { isPlatform } from '@ionic/core';

const loginSchema = yup.object().shape({
	mobile: yup
		.string()
		.length(10, 'Mobile No. Should Be 10 Digits.')
		.required('Mobile is Required.')
		.matches(REGEX.INDIAN_MOBILE_NUMBERS, 'Please Enter a valid Mobile No.'),
});

export const Login: React.FC = () => {
	const getTokenFromLs = useSelector(
		(state: any) => state.users?.user_details?.access_token
	);
	const userStep = useSelector((state: any) => state.users?.user_details?.step);
	const history = useHistory();
	const location: any = useLocation();
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const lang: any = useSelector(
		(state: any) => state?.oneTimeScreen?.language?.code
	);

	// const ionRouter = useIonRouter();

	useEffect(() => {
		// if (isPlatform('android')) {
		if (location.pathname === '/login') {
			console.log(
				process.env.REACT_APP_API_ENDPOINT ==
					'https://prodcrmapi.oneandro.com/',
				'REACT_APP_API_ENDPOINT'
			);

			ReactGA.initialize(GA4_TRACKING_ID);

			const referrer = InstallReferrer.getReferrerDetails()
				.then((referrerDetails: any) => {
					console.log(referrerDetails, 'referrerDetails inside');
					if (
						process.env.REACT_APP_API_ENDPOINT ==
						'https://prodcrmapi.oneandro.com/'
					) {
						if (isPlatform('desktop') || isPlatform('mobileweb')) {
							ReactGA.send({
								hitType: 'event',
								eventCategory: 'Installation',
								eventAction: 'Referrer',
								eventLabel: referrerDetails?.referrer,
							});
						}
						if (isPlatform('capacitor') || isPlatform('android')) {
							ReactGA.send({
								hitType: 'event',
								eventCategory: 'Installation',
								eventAction: 'Referrer',
								eventLabel: referrerDetails?.referrer,
								appVersion: APP_VERSION,
							});
						}
					}

					return referrerDetails?.referrer;
				})
				.catch((error: any) => {
					console.error(error);
				});

			// Send pageview with a custom path
			console.log('react-ga4 on login page');
			dispatch(ga4Data({ referrer }));
			if (
				process.env.REACT_APP_API_ENDPOINT == 'https://prodcrmapi.oneandro.com/'
			) {
				if (isPlatform('desktop') || isPlatform('mobileweb')) {
					ReactGA.send({
						hitType: 'pageview',
						page: '/login',
						title: 'Login Page',
					});
				}
				if (isPlatform('capacitor') || isPlatform('android')) {
					ReactGA.send({
						hitType: 'screenview',
						screenName: 'Login Screen',
						screenClass: 'LoginScreen',
						appVersion: APP_VERSION,
						eventLabel: referrer,
					});
				}
			}
		}
	}, []);
	useEffect(() => {
		if (getTokenFromLs !== undefined && location.pathname === '/login') {
			if (userStep === 'register') {
				history.replace('/register-yourself');
			} else if (['register_done', 'dashboard'].includes(userStep)) {
				history.replace('/dashboard/home');
			}
		}
	}, []);
	const {
		handleSubmit,
		control,
		setValue,
		trigger,
		getValues,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(loginSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			mobile: location?.state?.userMobile || '',
		},
	});

	const onSubmit = async () => {
		const { mobile } = getValues();
		let sms_code: any;
		if (isPlatform('capacitor')) {
			await SmsRetriever.getAppHash()
				.then((res: any) => {
					sms_code = res;
				})
				.catch((error: any) => console.error(error));
		}

		await dispatch(
			apiLogin({
				data: {
					mobile: Number(mobile),
					Tye: 'crm',
					reLogin: false,
					sms_hc: sms_code,
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.code === STATUS_CODE.SIGNIN_SUCCESS) {
					const myPromise = new Promise(function (myResolve) {
						setTimeout(function () {
							myResolve(history.push('/otp-verification'));
						}, 10);
					});
					myPromise.then(() => {
						dispatch(otpScreenShowPage(true));
					});
				}
			});
	};

	useEffect(() => {
		dispatch(leadFlow({}));
		i18next.changeLanguage(lang || 'en');
	}, []);

	useEffect(() => {
		if (localStorage.getItem('i18nextLng') !== null) {
			getureInit();
		}
	}, []);
	const pageEl = useRef(null);
	const getureInit = () => {
		const card = pageEl.current;
		if (card) {
			const gesture: Gesture = createGesture({
				el: card,
				threshold: 15,
				gestureName: 'my-gesture',
				onEnd: (ev: any) => {
					if (ev.deltaX > window.innerWidth / 2) {
						CapacitorApp.exitApp();
					} else if (ev.deltaX < -window.innerWidth / 2) {
						CapacitorApp.exitApp();
					}
				},
			});
			gesture.enable();
		}
	};

	return (
		<IonPage className='add-mobile'>
			{/* <Header name='' /> */}
			<IonContent
				ref={pageEl}
				className='h-100 '
			>
				<div className='row h-100 overflow-hidden'>
					<div className='col-md-6 border-end d-none d-md-block ion-padding'>
						<div className='getting-started h-100 container'>
							<StartPage />
						</div>
					</div>
					<div className='col-md-6 col-12 d-flex '>
						<div className='right-side-card ion-padding'>
							<div className='pt-5'>
								<h4 className='ion-no-margin title font-semibold fs-24'>
									{t('Enter Your Mobile Number')}
								</h4>
								<p
									className='ion-no-margin fs-14 text-grey description'
									style={{ color: '#A0A4B3' }}
								>
									{t('Enter Mobile Number linked')}
									<br />
									{t('with PAN & Aadhaar')}
								</p>
							</div>
							<div>
								<form
									onSubmit={handleSubmit(onSubmit)}
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											handleSubmit(onSubmit);
										}
									}}
								>
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, name } }) => (
											<CustomInput
												type={'number'}
												placeholder={t('Mobile Number *')}
												onBlur={onBlur}
												name={name}
												onChange={onChange}
												errors={t(errors?.mobile?.message as string)}
												value={getValues('mobile')}
												onBeforeInput={(evt: any) => {
													//Only numbers accepted on user input
													!/[0-9]/.test(evt.data) && evt.preventDefault();
													//Only accepts 10 characters on user input
													if (evt.target.value.length === 10) {
														evt.preventDefault();
													}
												}}
												onPaste={(e: any) => {
													//Only get numbers on user paste
													e.preventDefault();
													let pasteContent = e.clipboardData.getData('Text');
													if (pasteContent != '') {
														pasteContent = pasteContent.replace(/\D/g, '');
														if (pasteContent.length > 10) {
															pasteContent = pasteContent.slice(0, 10);
														}
													}
													setValue('mobile', pasteContent);
													trigger('mobile');
												}}
												onKeyDown={(e: any) => {
													disableNumberArrows(e);
												}}
												onWheel={(e: any) => e.target.blur()}
											/>
										)}
										name={'mobile'}
									/>
									{/* <CustomTest /> */}
									<div className='proceed-btn'>
										<CustomButton
											type='submit'
											disabled={!isValid}
											className='btn-blue'
											expand='block'
											title={t('Get Started')}
										/>
									</div>
								</form>
							</div>
							<CustomNote
								{...{
									header: t('Note'),
									content: `<ul class="ps-3 mb-0"><li class="mb-1">${t(
										'By clicking Get Started, you are agreeing to the'
									)} <a href='https://www.andromedaloans.com/terms-of-use-app/' target='_blank'>${t(
										'Terms of Use'
									)}</a> ${t(
										'and'
									)}   <a href='https://www.andromedaloans.com/privacy-policy-app/' target='_blank'>${t(
										'Privacy Policy'
									)}</a></li><li>${
										t(
											'If signing up as a firm, enter the mobile number of the Authorised Partner/ Director'
										) + '.'
									}</li></ul>`,
								}}
							/>
							<div className='text-end text-grey fs-12 pt-4 pb-2'>
								<span>
									{t('Version')} {APP_VERSION}
								</span>
							</div>
						</div>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};
