import {
	IonContent,
	IonFooter,
	IonImg,
	IonLabel,
	IonSegment,
	IonSegmentButton,
} from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';
import { t } from 'i18next';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
	lenderSpfDetailsFlow,
	saveLenderSpcfInfo,
} from '../../../store/reducers/lead/leadSlice';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import {
	ContinueLaterPopUp,
	FormatDate,
	InputFormatCurrencyValue,
	checkFieldsValidToContinue,
	disableNumberArrows,
	restrictAmountChar,
	showToastAlert,
} from '../../../helpers/functions';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { CustomModalLeadStatus } from '../../../components/common';
import './punjab.scss';
import {
	getLenderUserInfo,
	getProfessionalDetailsMaster,
} from '../../../store/reducers/user/userSlice';
import { LenderMasterID, LenderNameForAPICall } from '../../../helpers/lenders';
import { REGEX, STATUS_CODE } from '../../../helpers/constant';
import { OfferRejectedMsg } from '../OfferRejectedMsg/OfferRejectedMsg';
import { unwrapResult } from '@reduxjs/toolkit';
import { yupResolver } from '@hookform/resolvers/yup';
import DropDownModal from '../../../components/common/custom-dropdown/DropDownModal';
import { CustomCheckbox } from '../../../components/common/custom-checkbox/CustomCheckbox';
import CustomRadioButton from '../../../components/common/custom-radio-button/CustomRadioButton';

export const PunjabHLForm: React.FC<any> = () => {
	const [modalPageClose, setModalPageClose] = useState(false);
	const [leadStatusModalOpen, setLeadStatusModalOpen] = useState(false);
	const [applicationNo] = useState(null);
	const dropdownTypes = ['dropdown', 'dropdownSelect', 'dropdownModal'];
	const [yupSchema, setYupSchema] = useState(yup.object().shape({}));
	const [dropdownMasterData, setDropdownMasterData] = useState<any>({});
	// const [formCPAddressFields, setFormCPAddressFields] = useState<any>({});
	const lenderSpfDetails = useSelector(
		(state: any) => state?.lead?.lenderSpecificDetails
	);

	const [additionalFields, seAdditionalFields] = useState<any>({});

	const [pnbForm, setPnbForm] = useState({
		application_form_page_1: [],
		application_form_page_2: [],
		application_form_page_3: [],
	});

	const [segmentList] = useState<any>([
		{ page: 'Page 1', key: 'application_form_page_1' },
		{ page: 'Page 2', key: 'application_form_page_2' },
		{ page: 'Page 3', key: 'application_form_page_3' },
	]);
	const [segmentValue, setSegmentValue] = useState<string>(
		'application_form_page_1'
	);

	const handleChange = (event: any) => {
		setSegmentValue(event.detail.value);
	};

	const history = useHistory();
	const dispatch = useDispatch();
	const { selectedLender, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);
	const [showOfferErrMsg] = useState('');
	const location = useLocation<any>();

	// const {
	// 	handleSubmit,
	// 	control,
	// 	setValue,
	// 	// getValues,
	// 	// reset,
	// 	formState: { errors, isValid },
	// } = useForm({
	// 	mode: 'all',
	// 	reValidateMode: 'onChange',
	// });

	const {
		handleSubmit,
		control,
		// reset,
		setValue,
		trigger,
		getValues,
		watch,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(yupSchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const generateFormValidationSchemas = (fields: any) => {
		const schemaBuilderObject: any = {};
		fields?.forEach((formKeyName: any) => {
			if (formKeyName?.is_required) {
				const {
					name,
					match_regex,
					test = false,
					dependentLevel,
					dependentParentLabel,
					dependentParent,
					checkCondition,
					min,
					max,
					trim,
					is_comma_separated = false,
				} = formKeyName;
				if (is_comma_separated) {
					schemaBuilderObject[formKeyName.name] = yup.number();
					schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
						formKeyName.name
					]
						.typeError(
							formKeyName.placeholder.replaceAll('*', '') + ' must be a number'
						)
						.required(
							typeof formKeyName?.required_error_message === 'string' &&
								formKeyName?.required_error_message
								? formKeyName?.required_error_message
								: formKeyName.placeholder.replaceAll('*', '') +
										' is a required field'
						);
					if (min) {
						schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
							formKeyName.name
						].min(
							formKeyName['min'],
							`${formKeyName.placeholder.replaceAll(
								'*',
								''
							)} should not be zero or empty`
						);
					}
				} else if (dropdownTypes.includes(formKeyName.type)) {
					if (formKeyName.name === 'company_type') {
						schemaBuilderObject[formKeyName.name] = yup
							.array()
							.required(
								typeof formKeyName?.required_error_message === 'string' &&
									formKeyName?.required_error_message
									? formKeyName?.required_error_message
									: formKeyName.placeholder.replaceAll('*', '') +
											' is a required field'
							);
					} else {
						schemaBuilderObject[formKeyName.name] = yup
							.string()
							.required(
								typeof formKeyName?.required_error_message === 'string' &&
									formKeyName?.required_error_message
									? formKeyName?.required_error_message
									: formKeyName.placeholder.replaceAll('*', '') +
											' is a required field'
							);
					}
				} else if (formKeyName.type === 'number') {
					schemaBuilderObject[formKeyName.name] = yup.string();
					schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
						formKeyName.name
					].required(
						typeof formKeyName?.required_error_message === 'string' &&
							formKeyName?.required_error_message
							? formKeyName?.required_error_message
							: formKeyName.placeholder.replaceAll('*', '') +
									' is a required field'
					);

					if (max) {
						schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
							formKeyName.name
						].max(
							formKeyName?.max,
							typeof formKeyName?.max_error_message === 'string' &&
								formKeyName?.max_error_message
								? formKeyName?.max_error_message
								: `${
										formKeyName.placeholder.replaceAll('*', '') +
										'should not more than ' +
										formKeyName?.max
								  }`
						);
					}
					if (match_regex) {
						schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
							formKeyName.name
						].matches(
							new RegExp(formKeyName['match_regex']),
							typeof formKeyName?.regex_error_message === 'string' &&
								formKeyName?.regex_error_message
								? formKeyName?.regex_error_message
								: formKeyName.placeholder.replaceAll('*', '') +
										' should be a valid format'
						);
					}
				} else if (formKeyName.type === 'date') {
					schemaBuilderObject[formKeyName.name] = yup
						.date()
						.required('Date of birth is required') // Make it required
						.max(
							new Date(),
							`${formKeyName.placeholder.replaceAll(
								'*',
								''
							)} must be in the past`
						);
				} else if (formKeyName.type === 'text') {
					schemaBuilderObject[formKeyName.name] = yup.string();
					schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
						formKeyName.name
					].required(
						typeof formKeyName?.required_error_message === 'string' &&
							formKeyName?.required_error_message
							? formKeyName?.required_error_message
							: formKeyName.placeholder.replaceAll('*', '') +
									' is a required field'
					);
					if (trim) {
						schemaBuilderObject[formKeyName.name] =
							schemaBuilderObject[formKeyName.name].trim();
					}
					if (max) {
						schemaBuilderObject[formKeyName.name] = yup
							.string()
							.max(
								formKeyName?.max,
								typeof formKeyName?.max_error_message === 'string' &&
									formKeyName?.max_error_message
									? formKeyName?.max_error_message
									: `${
											formKeyName.placeholder.replaceAll('*', '') +
											'should not more than ' +
											formKeyName?.max
									  }`
							);
					}
					if (match_regex) {
						schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
							formKeyName.name
						].matches(
							name.includes('name') || name.includes('Name')
								? new RegExp(REGEX?.FULLNAME)
								: new RegExp(formKeyName['match_regex']),
							typeof formKeyName?.regex_error_message === 'string' &&
								formKeyName?.regex_error_message
								? formKeyName?.regex_error_message
								: formKeyName.placeholder.replaceAll('*', '') +
										' should be a valid format'
						);
					}
				} else {
					schemaBuilderObject[formKeyName.name] = formKeyName.match_regex
						? yup
								.string()
								.required(
									typeof formKeyName?.required_error_message === 'string' &&
										formKeyName?.required_error_message
										? formKeyName?.required_error_message
										: formKeyName.placeholder.replaceAll('*', '') +
												' is a required field'
								)
								.matches(
									new RegExp(formKeyName.match_regex),
									typeof formKeyName?.regex_error_message === 'string' &&
										formKeyName?.regex_error_message
										? formKeyName?.regex_error_message
										: formKeyName.placeholder.replaceAll('*', '') +
												' should be a valid format'
								)
						: yup
								.string()
								.required(
									typeof formKeyName?.required_error_message === 'string' &&
										formKeyName?.required_error_message
										? formKeyName?.required_error_message
										: formKeyName.placeholder.replaceAll('*', '') +
												' is a required field'
								);
				}
				if (test) {
					if (
						dependentLevel == '0' &&
						checkCondition &&
						typeof dependentParent === 'string'
					) {
						schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
							formKeyName.name
						].test(
							'custom-test-logic',
							formKeyName.placeholder.replaceAll('*', '') +
								'should not be more than ' +
								dependentParentLabel,
							function (this: yup.TestContext, value: string): boolean {
								const operation = checkCondition || '<=';
								const leftOperand = value;
								const { [dependentParent]: rightOperand } = this.parent;
								let result;
								switch (operation) {
									case '<':
										result = parseInt(leftOperand) < parseInt(rightOperand);
										break;
									case '<=':
										result = parseInt(leftOperand) <= parseInt(rightOperand);
										break;
									case '>':
										result = parseInt(leftOperand) > parseInt(rightOperand);
										break;
									case '>=':
										result = parseInt(leftOperand) >= parseInt(rightOperand);
										break;
									case '==':
										result = parseInt(leftOperand) == parseInt(rightOperand);
										break;
									case '===':
										result = parseInt(leftOperand) === parseInt(rightOperand);
										break;
									default:
										result = true;
								}
								return result;
							}
						);
					}
				}
			} else {
				if (formKeyName?.is_comma_separated) {
					schemaBuilderObject[formKeyName.name] = yup
						.number()
						.typeError(formKeyName.placeholder + ' must be a number');
				} else {
					schemaBuilderObject[formKeyName.name] =
						formKeyName?.match_regex &&
						yup.string().matches(new RegExp(formKeyName.match_regex), {
							message:
								typeof formKeyName?.regex_error_message === 'string' &&
								formKeyName?.regex_error_message
									? formKeyName?.regex_error_message
									: formKeyName.placeholder + ' should be a valid format',
							excludeEmptyString: true,
						});
				}
			}
		});
		setYupSchema(yupSchema.concat(yup.object().shape(schemaBuilderObject)));
	};

	const getLendersFormData = async () => {
		// get all fields from api
		await dispatch(
			getLenderUserInfo({
				param: leadFlowData?.leadId,
				data: {
					lender_name: LenderNameForAPICall.PUNJAB,
					lead_application_id: selectedLender[0]?.id,
					lender_id: LenderMasterID.PUNJAB,
				},
			})
		)
			.then(unwrapResult)
			.then((res: any) => {
				if (res?.status) {
					// check any field as is_required true than generate validation schema
					let allFields = [
						...(res?.data?.application_form_page_1 || []),
						...(res?.data?.application_form_page_2 || []),
						...(res?.data?.application_form_page_3 || []),
					];
					// console.log('allFields', allFields);

					allFields?.forEach((formKeyName: any) => {
						if (formKeyName?.is_required && formKeyName.additional_fields) {
							allFields = [
								...allFields,
								...formKeyName.additional_fields.map((field: any) => {
									return {
										...field,
										name: field.name,
									};
								}),
							];

							seAdditionalFields((prev: any) => ({
								...prev,
								[formKeyName?.name]: formKeyName.additional_fields.map(
									(field: any) => {
										return {
											...field,
											name: field.name,
										};
									}
								),
							}));
						}
					});

					if (allFields.some((formKey: any) => formKey?.is_required === true)) {
						generateFormValidationSchemas(allFields);
					}

					// run loop for dropdown api data
					allFields.forEach((formKeyName: any) => {
						if (formKeyName.type === 'dropdownSelect') {
							dispatch(getProfessionalDetailsMaster({ param: formKeyName.key }))
								.then(unwrapResult)
								.then((dropdownResponse: any) => {
									if (dropdownResponse?.status) {
										setDropdownMasterData((masterData: any) => {
											return {
												...masterData,
												[formKeyName.name]: dropdownResponse.data,
											};
										});
									}
								});
						}
						// else if (
						// 	formKeyName.type === 'checkbox' &&
						// 	formKeyName?.is_current_permanent_address
						// ) {
						// 	setFormCPAddressFields(formKeyName);
						// }

						// set value for form fields whatever the value present in api value key
						if (formKeyName.type === 'date') {
							setValue(
								formKeyName.name,
								FormatDate(formKeyName.value, 'yyyy-mm-dd')
							);
						} else {
							setValue(formKeyName.name, formKeyName.value);
						}
					});

					const formData = {
						application_form_page_1: res?.data?.application_form_page_1 || [],
						application_form_page_2: res?.data?.application_form_page_2 || [],
						application_form_page_3: res?.data?.application_form_page_3 || [],
					};

					// console.log(res?.data);

					setPnbForm(formData);
				}
			});
	};

	const toggleRequired = useCallback(
		(field: any, value: any = null) => {
			let newSchema: any = yupSchema;

			if (field === 'propertyIdentified') {
				if (value) {
					newSchema = yupSchema.clone().shape({
						[`purchasedFrom`]: yup.string().required(),
						[`developerName`]: yup.string().required(),
						[`costOfProperty`]: yup.string().required(),
						[`typeOfTransactn`]: yup.string().required(),
					});
				} else {
					delete newSchema.fields.purchasedFrom;
					delete newSchema.fields.developerName;
					delete newSchema.fields.costOfProperty;
					delete newSchema.fields.typeOfTransactn;

					newSchema._nodes.pop('purchasedFrom');
					newSchema._nodes.pop('developerName');
					newSchema._nodes.pop('costOfProperty');
					newSchema._nodes.pop('typeOfTransactn');
				}
			} else if (field === 'typeOfEmployment') {
				if (value === 'SAL') {
					newSchema = yupSchema.clone().shape({
						[`designation`]: yup.string().required(),
						[`employmentNo`]: yup.string().required(),
						[`employeeCode`]: yup.string().required(),
					});
				} else {
					delete newSchema.fields.designation;
					delete newSchema.fields.employmentNo;
					delete newSchema.fields.employeeCode;

					newSchema._nodes.pop('designation');
					newSchema._nodes.pop('employmentNo');
					newSchema._nodes.pop('employeeCode');
				}
			}

			setYupSchema(newSchema);
			setTimeout(() => {
				trigger('purchasedFrom');
				trigger('developerName');
				trigger('costOfProperty');
				trigger('designation');
			}, 1000);
		},
		[yupSchema, watch()]
	);

	useEffect(() => {
		toggleRequired('typeOfEmployment', watch('typeOfEmployment'));
	}, [watch('typeOfEmployment')]);
	useEffect(() => {
		toggleRequired('propertyIdentified', watch('propertyIdentified'));
	}, [watch('propertyIdentified')]);

	// console.log('pnbForm', pnbForm);
	// console.log(watch());
	// console.log('yupSchema', yupSchema);
	// console.log('errors', errors);
	// console.log('isValid', isValid);

	const fieldsGenerator = useCallback(
		(onlineFormFields: any) => {
			return (
				pnbForm &&
				Object.entries(pnbForm).length > 0 &&
				onlineFormFields.map(
					({
						name,
						placeholder,
						type,
						// value,
						exclude_number_validation,
						dependentLevel,
						triggerValidation,
						is_comma_separated = false,
						is_current_permanent_address = false,
						permanent_address_fields,
						current_address_fields,
						radio_options,
						is_required,
						additional_fields,
					}: any) => {
						const errorText: any =
							errors && Object.keys(errors).length > 0 && errors;
						if (type === 'dropdownSelect') {
							return (
								<>
									<div key={name}>
										<Controller
											key={name}
											control={control}
											render={({ field: { onChange, onBlur, name } }) => (
												<DropDownModal
													label={
														t(`${placeholder}`) + (is_required ? ' *' : '')
													}
													data={
														dropdownMasterData[name]
															? dropdownMasterData[name]
															: []
													}
													name={name}
													onChange={({ detail: { value } }: any) => {
														// name === 'typeOfEmployment' &&
														// 	toggleRequired('typeOfEmployment', value);
														onChange(value);
													}}
													onBlur={onBlur}
													className={
														// errorText[name]?.message
														// 	? 'ion-invalid ion-touched mb-3'
														// 	:
														'mb-3'
													}
													errors={t(errorText[name]?.message as string)}
													value={getValues(name)}
													// disabled={
													// 	value
													// 		? true
													// 		: formCPAddressFields?.is_current_permanent_address &&
													// 		  getValues(formCPAddressFields?.name)
													// 		? formCPAddressFields?.permanent_address_fields.includes(
													// 				name
													// 		  )
													// 		: false
													// }
												/>
											)}
											name={name}
										/>
									</div>
									{name === 'typeOfEmployment' &&
										watch(name) === 'SAL' &&
										additional_fields &&
										fieldsGenerator(additionalFields[name])}
								</>
							);
						} else if (type === 'checkbox') {
							return (
								<div key={name}>
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, value } }) => (
											<CustomCheckbox
												name={name}
												onChange={({ detail: { checked } }: any) => {
													onChange(checked);
													if (is_current_permanent_address) {
														if (checked) {
															permanent_address_fields?.map(
																(item: any, name: any) => {
																	setValue(
																		item,
																		getValues(current_address_fields[name])
																	);
																	trigger(item);
																}
															);
														} else {
															permanent_address_fields?.map((item: any) => {
																setValue(item, '');
																trigger(item);
															});
														}
													}
												}}
												value={value}
												labelPlacement='end'
												className='end-of-page'
												onBlur={onBlur}
												isChecked={value}
												children={
													<span className='text-wrap'>{t(placeholder)}</span>
												}
											></CustomCheckbox>
										)}
										name={name}
									/>
								</div>
							);
						} else if (type === 'radio') {
							return (
								<>
									<div key={name}>
										<Controller
											key={name}
											control={control}
											render={({ field: { onChange, name } }) => (
												<div className='mb-2'>
													<h6 className='mt-3'>
														{t(placeholder) + (is_required ? ' *' : '')}
													</h6>
													<CustomRadioButton
														type='group'
														value={getValues(name)}
														onIonChange={(e: any) => {
															name === 'propertyIdentified' &&
																// toggleRequired(
																// 	'propertyIdentified',
																// 	e?.detail?.value
																// );
																onChange(e);
														}}
														className='mb-2'
														ionRadioClassName='me-4'
														labelPlacement='end'
														data={
															Array.isArray(radio_options) &&
															radio_options?.length
																? radio_options
																: []
														}
													/>
													<div className='error-text px-3 sc-ion-input-md'>
														{t(errorText[name]?.message)}
													</div>
												</div>
											)}
											name={name}
										/>
									</div>
									{name === 'propertyIdentified' &&
										watch(name) &&
										additional_fields &&
										fieldsGenerator(additionalFields[name])}
								</>
							);
						} else if (type === 'date') {
							return (
								<div key={name}>
									<Controller
										key={name}
										control={control}
										render={({ field: { onChange, onBlur, name } }) => (
											<CustomInput
												type={type}
												needSuffixIcon={true}
												suffixClassName={
													'position-absolute date-of-birth-sufix'
												}
												suffixText={
													<IonImg
														src='/images/icons/input-dob-calendar-icon.svg'
														alt='dob'
													/>
												}
												outerClassName={'position-relative'}
												placeholder={t(placeholder) + (is_required ? ' *' : '')}
												onBlur={onBlur}
												name={name}
												onChange={onChange}
												// errors={t(errors[name]?.message)}
												errors={t(errors[name]?.message as any)}
												value={t(getValues(name))}
												// disabled={value ? true : false}
											/>
										)}
										name={name}
									/>
								</div>
							);
						} else {
							return (
								<div key={name}>
									<Controller
										key={name}
										control={control}
										render={({ field: { onChange, onBlur, name } }) => (
											<CustomInput
												type={type}
												placeholder={t(placeholder) + (is_required ? ' *' : '')}
												onBlur={onBlur}
												name={name}
												onChange={(e: any) => {
													if (is_comma_separated) {
														e = isNaN(e.detail.value.replaceAll(',', ''))
															? ''
															: Number(e.detail.value.replace(/,/g, ''));
													}
													if (
														dependentLevel == '1' &&
														Array.isArray(triggerValidation)
													) {
														if (triggerValidation.length > 0) {
															triggerValidation?.map((item: any) => {
																if (getValues(item) != undefined) {
																	trigger(item);
																}
															});
														}
													}
													onChange(e);
												}}
												errors={t(errors[name]?.message as any)}
												value={t(
													is_comma_separated
														? getValues(name)
															? InputFormatCurrencyValue(getValues(name))
															: getValues(name)
														: getValues(name)
												)}
												onBeforeInput={(evt: any) => {
													if (type == 'number') {
														if (!exclude_number_validation) {
															!/[0-9]/.test(evt.data) && evt.preventDefault();
														} else {
															!/[0-9.]/.test(evt.data) && evt.preventDefault();
														}
													} else if (is_comma_separated && type == 'text') {
														restrictAmountChar(
															evt,
															getValues(name)?.toString()
														);
													}
												}}
												onKeyDown={(e: any) => {
													if (type == 'number') {
														disableNumberArrows(e);
													}
												}}
												onWheel={(e: any) => {
													if (type == 'number') {
														e.target.blur();
													}
												}}
												// disabled={
												// 	value
												// 		? true
												// 		: formCPAddressFields?.is_current_permanent_address &&
												// 		  getValues(formCPAddressFields?.name)
												// 		? formCPAddressFields?.permanent_address_fields?.includes(
												// 				name
												// 		  )
												// 		: false
												// }
											/>
										)}
										name={name}
									/>
								</div>
							);
						}
					}
				)
			);
		},
		[
			pnbForm,
			dropdownMasterData,
			watch('propertyIdentified'),
			watch('typeOfEmployment'),
		]
	);

	useEffect(() => {
		if (location.pathname !== '/lead-lender-specific-form-steps') {
			return;
		}
		// console.log(selectedLender[0]);
		getLendersFormData();
	}, [location.pathname]);

	/**
	 * On close of lead status modal redirect to external URL
	 */
	const onLeadStatusModalClose = () => {
		setLeadStatusModalOpen(false);
	};

	// const connectPunjab = async (flag: string) => {
	// 	const req: any = {
	// 		lead_spf_detail_id: lenderSpecificDetails.lead_spf_detail_id,
	// 		customerID: lenderSpecificDetails.lender_lead_id,
	// 		redirectURL: `${location.origin}/lead-lender-specific-form-steps`,
	// 		program: 'BUSINESS_LOAN',
	// 		lender_name: LenderNameForAPICall.ABFL,
	// 	};
	// 	if (flag === 'bank') {
	// 		await dispatch(lenderBankConnect({ data: req }))
	// 			.then(unwrapResult)
	// 			.then((result: any) => {
	// 				if (result?.status) {
	// 					const { data } = result.data;
	// 					showInAppBrowserView(data?.url);
	// 					window.close();
	// 				} else {
	// 					setShowOfferErrMsg(result?.message);
	// 				}
	// 			});
	// 	} else {
	// 		await dispatch(lenderGstConnect({ data: req }))
	// 			.then(unwrapResult)
	// 			.then((result: any) => {
	// 				if (result?.status) {
	// 					const { data } = result.data;
	// 					showInAppBrowserView(data?.url);
	// 					window.close();
	// 				} else {
	// 					setShowOfferErrMsg(result?.message);
	// 				}
	// 			});
	// 	}
	// };

	/**
	 * On submit to save user data and after that
	 */
	const onSubmit = async (data: any = watch(), status: any = false) => {
		console.log(status);

		const errorSet = { ...errors };
		const errorKeys = Object.keys(errorSet);
		const formData = data;

		Object.entries(errorKeys).map((item: any) => {
			delete formData?.[item[1]];
		});

		const apiData = {
			...data,
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lender_name: LenderNameForAPICall.PUNJAB,
			lead_application_id: leadFlowData?.lead_app_id || selectedLender[0]?.id,
			isNewLead: true,
			step: 'step1',
			continue_flag: status,
		};

		await dispatch(
			saveLenderSpcfInfo({
				// param: '/' + leadFlowData?.leadId,
				data: apiData,
			})
		).then((res: any) => {
			if (res?.payload?.code == STATUS_CODE.SUCCESS) {
				// setApplicationNo(res?.payload?.data);
				// setLeadStatusModalOpen(true);
			} else {
				// setShowOfferErrMsg('Please try again later !');
			}
		});
	};

	const orCondition =
		segmentValue === 'application_form_page_1' ||
		segmentValue === 'application_form_page_2';

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<IonSegment
								scrollable={true}
								style={{
									position: 'sticky',
									top: 0,
									zIndex: 999,
									backgroundColor: 'white',
								}}
								onIonChange={handleChange}
								value={segmentValue}
							>
								{segmentList.map((item: any) => (
									<IonSegmentButton
										style={{ padding: '0px 20px' }}
										key={item?.key}
										value={item?.key}
									>
										<IonLabel>{item?.page}</IonLabel>
									</IonSegmentButton>
								))}
							</IonSegment>
						</div>

						<div className='select-product small-container bg-web'>
							<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
								{
									segmentValue === 'application_form_page_1' ? (
										<form onSubmit={handleSubmit(onSubmit)}>
											{fieldsGenerator(pnbForm?.application_form_page_1)}
										</form>
									) : segmentValue === 'application_form_page_2' ? (
										<form onSubmit={handleSubmit(onSubmit)}>
											{fieldsGenerator(pnbForm?.application_form_page_2)}
										</form>
									) : (
										segmentValue === 'application_form_page_3' && (
											<form onSubmit={handleSubmit(onSubmit)}>
												{fieldsGenerator(pnbForm?.application_form_page_3)}
											</form>
										)
									)
									// : (
									// 	segmentValue === 'application_form_page_4' && (
									// 		<form onSubmit={handleSubmit(onSubmit)}>
									// 			{fieldsGenerator(pnbForm?.application_form_page_4)}
									// 		</form>
									// 	)
									// )
								}

								<div className={` d-none d-md-block`}>
									<CustomButton
										className='w-100 fw-bold dashboard-add-lead-button mb-3'
										expand='block'
										fill='outline'
										title={t('Continue Later')}
										onClick={() => {
											if (checkFieldsValidToContinue(errors)) {
												setModalPageClose(true);
											} else {
												showToastAlert({
													type: 'error',
													message: 'Please fix above errors',
												});
											}
										}}
									/>
									<CustomButton
										type='submit'
										className='w-100 fw-bold dashboard-add-lead-button'
										expand='block'
										title={orCondition ? t('Save & Continue') : t('Proceed')}
										disabled={orCondition ? false : !isValid}
										onClick={() => {
											if (orCondition) {
												onSubmit(watch(), true);
												setSegmentValue(
													segmentValue === 'application_form_page_1'
														? 'application_form_page_2'
														: segmentValue === 'application_form_page_2' &&
																'application_form_page_3'

													// : segmentValue === 'application_form_page_3' &&
													//   'application_form_page_4'
												);
											} else {
												dispatch(
													lenderSpfDetailsFlow({
														...lenderSpfDetails,
														step: 'step2',
													})
												);
												onSubmit(watch());
											}
										}}
									/>
								</div>
							</div>
						</div>
					</>
				)}

				<CustomModal
					needIonContentWraper={false}
					isOpen={modalPageClose}
					initialBreakpoint={undefined}
					handleClose={() => setModalPageClose(false)}
					breakpoints={[]}
					className='height-auto confirm-popup-middle continue-later'
					modalContent={ContinueLaterPopUp(
						() => {
							setModalPageClose(false);
						},
						() => {
							new Promise(function (myResolve) {
								setTimeout(function () {
									myResolve(history.push('/dashboard/lead'));
									onSubmit(watch(), false);
									setModalPageClose(false);
								}, 10);
							});
						},
						'Cancel',
						'Continue Later',
						'className',
						'Are You Sure You Want to Exit?',
						`${t('You can continue later from where you left off.')}`
					)}
					needCross={false}
					needArrow={false}
				/>
			</IonContent>

			{leadStatusModalOpen && (
				<CustomModalLeadStatus
					isOpen={leadStatusModalOpen}
					applicationNo={applicationNo}
					handleClose={() => onLeadStatusModalClose()}
				/>
			)}
			{!showOfferErrMsg && (
				<IonFooter className='d-block d-md-none position-relative'>
					<div
						style={{
							backgroundColor: '#fff',
							zIndex: 2,
						}}
					>
						<div className={`ion-padding`}>
							<CustomButton
								className='w-100 fw-bold dashboard-add-lead-button mb-3'
								expand='block'
								fill='outline'
								title={t('Continue Later')}
								onClick={() => {
									if (checkFieldsValidToContinue(errors)) {
										setModalPageClose(true);
									} else {
										showToastAlert({
											type: 'error',
											message: 'Please fix above errors',
										});
									}
								}}
							/>
							<div className='proceed-btn'>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={orCondition ? t('Save & Continue') : t('Proceed')}
									disabled={orCondition ? false : !isValid}
									onClick={() => {
										if (orCondition) {
											onSubmit(watch(), true);
											setSegmentValue(
												segmentValue === 'application_form_page_1'
													? 'application_form_page_2'
													: segmentValue === 'application_form_page_2' &&
															'application_form_page_3'
												// : segmentValue === 'application_form_page_3' &&
												//   'application_form_page_4'
											);
										} else {
											dispatch(
												lenderSpfDetailsFlow({
													...lenderSpfDetails,
													step: 'step2',
												})
											);
											onSubmit(watch());
										}
									}}
								/>
							</div>
						</div>
					</div>
				</IonFooter>
			)}
		</>
	);
};
