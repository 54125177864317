import { useEffect, useState } from 'react';
import { IonButton, IonContent, IonFooter, IonIcon } from '@ionic/react';
import { t } from 'i18next';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import {
	ContinueLaterPopUp,
	FormatDate,
	checkFieldsValidToContinue,
	showToastAlert,
} from '../../../../helpers/functions';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import { CoApplicantForm1 } from './CoApplicantForm1';
import { CoApplicantForm2 } from './CoApplicantForm2';
import { CoApplicantForm3 } from './CoApplicantForm3';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
	coApplicantSchemaOne,
	coApplicantSchemaTwo,
	coApplicantSchemaThree,
} from './TataHlLAP';
import {
	lenderSpfDetailsFlow,
	saveLenderSpcfInfo,
} from '../../../../store/reducers/lead/leadSlice';
import { CustomModalLeadStatus } from '../../../../components/common';
import { unwrapResult } from '@reduxjs/toolkit';
import { LenderNameForAPICall } from '../../../../helpers/lenders';
import { OfferRejectedMsg } from '../..';

export const CoApplicantForm: React.FC<any> = ({
	coTabIndex,
	handleSegmentChange,
	totalCoApplicant,
	setTotalCoApplicant,
	setValidForm,
	isValidForm,
}) => {
	const [modalPageClose, setModalPageClose] = useState(false);
	const [currentPage, setCurrentPage] = useState<any>(1);
	const [coApplicantData, setCoApplicantData] = useState<any>([]);
	const [leadStatusModalOpen, setLeadStatusModalOpen] = useState(false);
	const [applicationNo, setApplicationNo] = useState('');
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const dispatch = useDispatch();
	const { lenderSpecificDetails, leadFlowData, selectedLender } = useSelector(
		(state: any) => state?.lead
	);

	const documentObj = {
		docUploadType: '',
		docUploadName: '',
		preview: '',
		file: '',
		fileObj: {
			name: '',
			type: '',
		},
		message: '',
		docIndex: '',
	};

	const coAppData = JSON.parse(
		JSON.stringify(lenderSpecificDetails?.lender_spf_details?.co_applicant) ||
			'[]'
	);

	const coApplicantFormOne: any = {
		mobile_no: coAppData?.[coTabIndex]?.mobile_no,
		first_name: coAppData?.[coTabIndex]?.first_name,
		middle_name: coAppData?.[coTabIndex]?.middle_name,
		last_name: coAppData?.[coTabIndex]?.last_name,
		dob: coAppData?.[coTabIndex]?.dob || undefined,
		pan_no: coAppData?.[coTabIndex]?.pan_no,
		gender: coAppData?.[coTabIndex]?.gender,
		email: coAppData?.[coTabIndex]?.email,
		net_monthly_income: coAppData?.[coTabIndex]?.net_monthly_income || '',
		req_roi: coAppData?.[coTabIndex]?.req_roi || '',
		mother_first_name: coAppData?.[coTabIndex]?.mother_first_name || '',
		mother_last_name: coAppData?.[coTabIndex]?.mother_last_name || '',
	};

	const coApplicantFormTwo: any = {
		customer_type: coAppData?.[coTabIndex]?.customer_type || '',
		employment_type: coAppData?.[coTabIndex]?.employment_type || '',
		property_type: coAppData?.[coTabIndex]?.property_type || '',
		nature_of_property: coAppData?.[coTabIndex]?.nature_of_property || '',
		application_type: coAppData?.[coTabIndex]?.application_type || '',
		property_cost: coAppData?.[coTabIndex]?.property_cost || '',
		property_address: coAppData?.[coTabIndex]?.property_address || '',
		occupation_type: coAppData?.[coTabIndex]?.occupation_type || '',
		collateral_sub_type: coAppData?.[coTabIndex]?.collateral_sub_type || '',
		loan_purpose: coAppData?.[coTabIndex]?.loan_purpose || '',
		company_name: coAppData?.[coTabIndex]?.company_name || '',
		party_relation: coAppData?.[coTabIndex]?.party_relation || '',
		date_of_incorporation:
			coAppData?.[coTabIndex]?.date_of_incorporation || undefined,
		address_type: coAppData?.[coTabIndex]?.address_type,
		address_line_1: coAppData?.[coTabIndex]?.address_line_1,
		address_line_2: coAppData?.[coTabIndex]?.address_line_2,
		address_line_3: coAppData?.[coTabIndex]?.address_line_3,
		pincode: coAppData?.[coTabIndex]?.pincode,
	};

	const coApplicantFormThree: any = {
		dms_branch_code: coAppData?.[coTabIndex]?.dms_branch_code || '',
		// dms_branch_code: 493,  // UAT
		marital_status: coAppData?.[coTabIndex]?.marital_status || '',
		resident_type: coAppData?.[coTabIndex]?.resident_type || '',
		accomodation_type: coAppData?.[coTabIndex]?.accomodation_type || '',
		nature_of_business: coAppData?.[coTabIndex]?.nature_of_business || '',
		nature_of_business_others:
			coAppData?.[coTabIndex]?.nature_of_business_others || '',
		industry: coAppData?.[coTabIndex]?.industry || '',
		date_of_joining: coAppData?.[coTabIndex]?.date_of_joining || '',
		documents: coAppData?.[coTabIndex]?.documents || [documentObj],
	};

	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		trigger,
		reset,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(
			currentPage === 1
				? coApplicantSchemaOne
				: currentPage === 2
				? coApplicantSchemaTwo
				: coApplicantSchemaThree
		),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues:
			currentPage === 1
				? coApplicantFormOne
				: currentPage === 2
				? coApplicantFormTwo
				: coApplicantFormThree,
	});

	useEffect(() => {
		if (!location.pathname.includes('/lead-lender-specific-form-steps')) {
			return;
		}
		setCoApplicantData(coAppData || []);
	}, [location.pathname]);

	useEffect(() => {
		onPageChange(1);
	}, [coTabIndex]);

	const onPageChange = (pageInd: any) => {
		setCurrentPage(pageInd);
		if (pageInd === 1) {
			firstFormFields();
		} else if (pageInd === 2) {
			secondFormFields();
		} else if (pageInd === 3) {
			thirdFormFields();
		}
	};

	useEffect(() => {
		const applicantInfo = lenderSpecificDetails?.lender_spf_details;
		const coData: any = JSON.parse(JSON.stringify(coApplicantData));
		coData[coTabIndex] = {
			...coData[coTabIndex],
			[`isValid${currentPage}`]: isValid,
		};
		const data = coData.filter((ele: any) => !ele.isValid3) || [];
		if (
			data.length > 0 ||
			!applicantInfo?.isValidApplicant1 ||
			!applicantInfo?.isValidApplicant2
		) {
			setValidForm(false);
		} else {
			setValidForm(true);
		}
	}, [isValid]);

	const firstFormFields = () => {
		reset({
			mobile_no: coAppData?.[coTabIndex]?.mobile_no,
			first_name: coAppData?.[coTabIndex]?.first_name,
			middle_name: coAppData?.[coTabIndex]?.middle_name,
			last_name: coAppData?.[coTabIndex]?.last_name,
			dob: coAppData?.[coTabIndex]?.dob || undefined,
			pan_no: coAppData?.[coTabIndex]?.pan_no,
			gender: coAppData?.[coTabIndex]?.gender,
			email: coAppData?.[coTabIndex]?.email,
			net_monthly_income: coAppData?.[coTabIndex]?.net_monthly_income || '',
			req_roi: coAppData?.[coTabIndex]?.req_roi || '',
			mother_first_name: coAppData?.[coTabIndex]?.mother_first_name || '',
			mother_last_name: coAppData?.[coTabIndex]?.mother_last_name || '',
		});
	};

	const secondFormFields = () => {
		reset({
			customer_type: coAppData?.[coTabIndex]?.customer_type || '',
			employment_type: coAppData?.[coTabIndex]?.employment_type || '',
			property_type: coAppData?.[coTabIndex]?.property_type || '',
			nature_of_property: coAppData?.[coTabIndex]?.nature_of_property || '',
			application_type: coAppData?.[coTabIndex]?.application_type || '',
			property_cost: coAppData?.[coTabIndex]?.property_cost || '',
			property_address: coAppData?.[coTabIndex]?.property_address || '',
			occupation_type: coAppData?.[coTabIndex]?.occupation_type || '',
			collateral_sub_type: coAppData?.[coTabIndex]?.collateral_sub_type || '',
			loan_purpose: coAppData?.[coTabIndex]?.loan_purpose || '',
			company_name: coAppData?.[coTabIndex]?.company_name || '',
			party_relation: coAppData?.[coTabIndex]?.party_relation || '',
			date_of_incorporation:
				coAppData?.[coTabIndex]?.date_of_incorporation || undefined,
			address_type: coAppData?.[coTabIndex]?.address_type,
			address_line_1: coAppData?.[coTabIndex]?.address_line_1,
			address_line_2: coAppData?.[coTabIndex]?.address_line_2,
			address_line_3: coAppData?.[coTabIndex]?.address_line_3,
			pincode: coAppData?.[coTabIndex]?.pincode,
		});
	};

	const thirdFormFields = () => {
		const setData = {
			dms_branch_code: coAppData?.[coTabIndex]?.dms_branch_code || 493,
			marital_status: coAppData?.[coTabIndex]?.marital_status || '',
			resident_type: coAppData?.[coTabIndex]?.resident_type || '',
			accomodation_type: coAppData?.[coTabIndex]?.accomodation_type || '',
			nature_of_business: coAppData?.[coTabIndex]?.nature_of_business || '',
			nature_of_business_others:
				coAppData?.[coTabIndex]?.nature_of_business_others || '',
			industry: coAppData?.[coTabIndex]?.industry || '',
			date_of_joining: coAppData?.[coTabIndex]?.date_of_joining || '',
			documents: [documentObj],
		};

		setTimeout(() => {
			reset(setData);
			if (coAppData?.[coTabIndex]?.documents?.length > 0) {
				reset({ ...setData, documents: coAppData?.[coTabIndex]?.documents });
			}
		}, 1000);
	};

	const addCoApplicant = () => {
		onSubmitAndNext(getValues(), 'AddCoApplicant');
		const count: any = [...totalCoApplicant, totalCoApplicant.length + 1];
		setTotalCoApplicant(count);
		handleSegmentChange(`CoApplicantForm${count.length - 1}`);
		setCoApplicantData([...coApplicantData, {}]);
	};

	const removeApplicant = () => {
		const totalC = totalCoApplicant.filter(
			(el: any, index: number) => index != coTabIndex
		);
		setTotalCoApplicant(totalC);
		handleSegmentChange('CoApplicantForm0');
		onPageChange(1);
		let coData: any = JSON.parse(JSON.stringify(coApplicantData));
		coData = coData.filter((el: any, ind: any) => ind != coTabIndex);
		setCoApplicantData(coData);
		dispatch(
			lenderSpfDetailsFlow({
				...lenderSpecificDetails,
				lender_spf_details: {
					...lenderSpecificDetails?.lender_spf_details,
					co_applicant: coData,
				},
			})
		);
	};

	/**
	 * On submit to save user data and after that
	 * @param data Form data
	 */
	const onSubmit = async (data: any) => {
		onSubmitAndNext(data);
	};

	const onSubmitAndNext = async (data: any, flag?: any) => {
		const documentsArr: any = [];
		const coData: any = JSON.parse(JSON.stringify(coApplicantData));

		coData[coTabIndex] = {
			...coData[coTabIndex],
			...data,
			[`isValid${currentPage}`]: isValid,
		};

		if (getValues('documents')?.length > 0) {
			getValues('documents')?.forEach((el: any) => {
				if (el?.docUploadName) {
					documentsArr.push({
						applicantType: '1',
						docUploadType: el.docUploadType,
						docUploadName: el.docUploadName,
						file: el.file,
					});
				}
			});

			coData[coTabIndex] = {
				...coData[coTabIndex],
				documents: documentsArr,
			};
		}

		if (currentPage === 1) {
			if (coData[coTabIndex].dob) {
				coData[coTabIndex].dob = FormatDate(data?.dob, 'yyyy-mm-dd');
			}
		}
		if (currentPage === 2) {
			if (coData[coTabIndex].date_of_incorporation) {
				coData[coTabIndex].date_of_incorporation = FormatDate(
					data?.date_of_incorporation,
					'yyyy-mm-dd'
				);
			}

			if (coData[coTabIndex].application_type !== 'Property Identified') {
				delete coData[coTabIndex]?.property_cost;
				delete coData[coTabIndex]?.property_address;
			}
		}
		if (currentPage === 3) {
			if (coData[coTabIndex].date_of_joining) {
				coData[coTabIndex].date_of_joining = FormatDate(
					data?.date_of_joining,
					'yyyy-mm-dd'
				);
			}

			if (coData[coTabIndex].nature_of_business !== 'Others') {
				delete coData[coTabIndex]?.nature_of_business_others;
			}
		}
		setCoApplicantData(coData);
		const req: any = {
			lender_spf_details: {
				...JSON.parse(
					JSON.stringify(lenderSpecificDetails?.lender_spf_details)
				),
			},
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.TATA_HL_LAP,
			step: 'step1',
			isNewLead: isValid && currentPage == 3 && !flag,
		};

		if (!req?.lender_spf_details?.co_applicant) {
			req.lender_spf_details.co_applicant = [coData[coTabIndex]];
		} else {
			req.lender_spf_details.co_applicant[coTabIndex] = coData[coTabIndex];
		}

		if (lenderSpecificDetails?.lead_spf_detail_id) {
			req.lead_spf_detail_id = lenderSpecificDetails?.lead_spf_detail_id;
		}

		setShowOfferErrMsg('');
		await dispatch(saveLenderSpcfInfo({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					reset({});
					if (!flag) {
						if (req.isNewLead && currentPage == 3) {
							let appNo: any = `Webtop No: ${result.data?.response?.WEBTOP_ID}\nOpportunity ID: ${result.data?.response?.OpportunityId}`;
							if (result.data?.response?.FinnOne_Application_Number) {
								appNo += `\nFinnOne App. No: ${result.data?.response?.FinnOne_Application_Number}`;
							}
							setApplicationNo(appNo);
							setLeadStatusModalOpen(true);
						} else {
							if (flag !== 'PAGE_CHANGE') {
								onPageChange(currentPage + 1);
							}
						}
					}
					dispatch(
						lenderSpfDetailsFlow({
							...lenderSpecificDetails,
							lender_spf_details: {
								...lenderSpecificDetails?.lender_spf_details,
								...req?.lender_spf_details,
							},
						})
					);
				} else {
					setShowOfferErrMsg(result?.message);
				}
			});
	};

	/**
	 * Continue Later and Submit button
	 */
	const continueLaterAndSubmitBtn = () => {
		return (
			<>
				<CustomButton
					className='w-100 fw-bold dashboard-add-lead-button mb-3'
					expand='block'
					fill='outline'
					title={t('Continue Later')}
					onClick={() => {
						if (checkFieldsValidToContinue('')) {
							setModalPageClose(true);
						} else {
							showToastAlert({
								type: 'error',
								message: 'Please fix above errors',
							});
						}
					}}
				/>
				<CustomButton
					type='submit'
					className='w-100 fw-bold dashboard-add-lead-button'
					expand='block'
					title={t(currentPage === 3 ? 'Proceed' : 'Save & Continue')}
					disabled={currentPage === 3 ? !isValidForm : !isValid}
					onClick={handleSubmit(onSubmit)}
				/>
			</>
		);
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 pt-3 p-md-5 border-radius-10'>
							<form onSubmit={handleSubmit(onSubmit)}>
								{currentPage === 1 && (
									<CoApplicantForm1
										control={control}
										getValues={getValues}
										errors={errors}
									/>
								)}
								{currentPage === 2 && (
									<CoApplicantForm2
										control={control}
										getValues={getValues}
										setValue={setValue}
										trigger={trigger}
										errors={errors}
									/>
								)}
								{currentPage === 3 && (
									<CoApplicantForm3
										control={control}
										getValues={getValues}
										setValue={setValue}
										reset={reset}
										trigger={trigger}
										errors={errors}
										isValid={isValid}
										documentObj={documentObj}
									/>
								)}
								<div className='co_app_form_pagination'>
									<div className='page_item'>
										<span
											className={currentPage == 1 ? 'active_page' : ''}
											onClick={() => {
												onSubmitAndNext(getValues(), 'PAGE_CHANGE');
												onPageChange(1);
											}}
										>
											1
										</span>
										<span
											className={
												currentPage == 2
													? 'active_page'
													: !coApplicantData[coTabIndex]?.isValid1
													? 'disable_page'
													: ''
											}
											onClick={() => {
												if (coApplicantData[coTabIndex]?.isValid1) {
													onSubmitAndNext(getValues(), 'PAGE_CHANGE');
													onPageChange(2);
												}
											}}
										>
											2
										</span>
										<span
											className={
												currentPage == 3
													? 'active_page'
													: !coApplicantData[coTabIndex]?.isValid2
													? 'disable_page'
													: ''
											}
											onClick={() => {
												if (coApplicantData[coTabIndex]?.isValid2) {
													onSubmitAndNext(getValues(), 'PAGE_CHANGE');
													onPageChange(3);
												}
											}}
										>
											3
										</span>
									</div>
									<div className='other_btns'>
										{currentPage === 3 ? (
											<CustomButton
												type='button'
												className='add-co-btn fw-bold dashboard-add-lead-button'
												expand='block'
												title={t('Add Co-Applicant')}
												disabled={!isValid}
												onClick={addCoApplicant}
											/>
										) : (
											''
										)}

										{coTabIndex != 0 ? (
											<IonButton
												shape='round'
												type='button'
												color='danger'
												onClick={() => removeApplicant()}
											>
												<IonIcon src='/images/icons/delete.svg' />
											</IonButton>
										) : (
											''
										)}
									</div>
								</div>
							</form>
							<div className={`d-none d-md-block mt-4`}>
								{continueLaterAndSubmitBtn()}
							</div>
						</div>
					</div>
				)}
			</IonContent>

			{!showOfferErrMsg && (
				<IonFooter className='d-block d-md-none position-relative ion-padding lead_ftr_btn'>
					{continueLaterAndSubmitBtn()}
				</IonFooter>
			)}

			{leadStatusModalOpen && (
				<CustomModalLeadStatus
					isOpen={leadStatusModalOpen}
					applicationNo={applicationNo}
					handleClose={() => setLeadStatusModalOpen(false)}
				/>
			)}

			<CustomModal
				needIonContentWraper={false}
				isOpen={modalPageClose}
				initialBreakpoint={undefined}
				handleClose={() => setModalPageClose(false)}
				breakpoints={[]}
				className='height-auto confirm-popup-middle continue-later'
				modalContent={ContinueLaterPopUp(
					() => {
						setModalPageClose(false);
					},
					() => {
						new Promise((myResolve) => {
							setTimeout(function () {
								myResolve(history.back());
								onSubmitAndNext(getValues(), 'continue_later');
								setModalPageClose(false);
							}, 10);
						});
					},
					'Cancel',
					'Continue Later',
					'className',
					'Are You Sure You Want to Exit?',
					`${t('You can continue later from where you left off.')}`
				)}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
