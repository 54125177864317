import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { basicInfoKycSchema } from '../../../../dashboard/kyc/KycValidationSchema';
import { CustomInput } from '../../../../../components/common/custom-input/CustomInput';
import { CustomButton } from '../../../../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	kycBasicInfo,
	setKycBasicInfo,
} from '../../../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	capFirstLetter,
	disableNumberArrows,
} from '../../../../../helpers/functions';

const basicInfoFormKeys = [
	{
		name: 'first_name',
		type: 'text',
		placeholder: 'First Name (as per Aadhaar) *',
	},
	{
		name: 'middle_name',
		type: 'text',
		placeholder: 'Middle Name (as per Aadhaar)',
	},
	{
		name: 'last_name',
		type: 'text',
		placeholder: 'Last Name (as per Aadhaar) *',
	},
	{
		name: 'mobile',
		type: 'number',
		placeholder: 'Mobile *',
		disabled: true,
	},
	{
		name: 'alternate_mobile',
		type: 'number',
		placeholder: 'Alternate Mobile No.',
	},
	{
		name: 'email',
		type: 'email',
		placeholder: 'Email *',
	},
];
type UserBasicInfoForm = {
	first_name: string;
	middle_name: string;
	last_name: string;
	mobile: number | string;
	alternate_mobile: number | string;
	email: string;
};

interface Props {
	userId: any;
	setShowBasicInfoForm: any;
	getUserDetailsInfo: any;
}

export const BasicInfoEdit: React.FC<Props> = ({
	userId,
	setShowBasicInfoForm,
	getUserDetailsInfo,
}) => {
	const dispatch: any = useDispatch();
	const { t } = useTranslation();
	const {
		handleSubmit,
		reset,
		control,
		trigger,
		setValue,
		getValues,
		formState: { errors, isValid },
	}: any = useForm<UserBasicInfoForm>({
		mode: 'all',
		reValidateMode: 'onChange',
		resolver: yupResolver(basicInfoKycSchema),
		defaultValues: {
			first_name: '',
			middle_name: '',
			last_name: '',
			mobile: '',
			alternate_mobile: '',
			email: '',
		},
	});
	const dmaSelector = useSelector((state: any) => state?.users?.dmaFlow);

	const onSubmit = async (data: UserBasicInfoForm) => {
		await dispatch(
			setKycBasicInfo({
				param: '/' + userId,
				data: {
					...data,
					mobile: Number(data.mobile),
					alternate_mobile: Number(data.alternate_mobile),
					first_name: data?.first_name ? capFirstLetter(data?.first_name) : '',
					middle_name: data?.middle_name
						? capFirstLetter(data?.middle_name)
						: '',
					last_name: data?.last_name ? capFirstLetter(data?.last_name) : '',
					RW: true,
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setShowBasicInfoForm(false);
					getUserDetailsInfo(dmaSelector?.dmaId, dmaSelector?.show_tab);
				}
			});
	};

	const fetchKycBasicInfo = async () => {
		await dispatch(kycBasicInfo({ param: userId + `/step1` }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					reset({
						...result?.data[0]?.basic_info_kyc_data,
					});
				}
			});
	};

	useEffect(() => {
		fetchKycBasicInfo();
	}, []);

	return (
		<section
			className='overflowX-hidden pt-1'
			style={{ height: 'calc(100vh - 180px)' }}
		>
			<div>
				<form onSubmit={handleSubmit(onSubmit)}>
					{basicInfoFormKeys.map(
						({ name, type, placeholder, disabled }: any, index) => {
							return (
								<Controller
									key={`kyc-step-1-${index}`}
									control={control}
									render={({ field: { onChange, onBlur, name } }) => (
										<CustomInput
											type={type}
											placeholder={t(`${placeholder}`)}
											onBlur={onBlur}
											onChange={onChange}
											value={getValues(name)}
											errors={t(errors[name]?.message)}
											disabled={disabled}
											onBeforeInput={(evt: any) => {
												if (type == 'number') {
													!/[0-9]/.test(evt.data) && evt.preventDefault();
												}
												if (name.toLowerCase().includes('mobile')) {
													if (evt.target.value.length === 10) {
														evt.preventDefault();
													}
												}
											}}
											onPaste={(e: any) => {
												//Only get numbers on user paste
												if (name.toLowerCase().includes('mobile')) {
													e.preventDefault();
													let pasteContent = e.clipboardData.getData('Text');
													if (pasteContent != '') {
														pasteContent = pasteContent.replace(/\D/g, '');
														if (pasteContent.length > 10) {
															pasteContent = pasteContent.slice(0, 10);
														}
													}
													setValue(name, pasteContent);
													trigger(name);
												}
											}}
											onKeyDown={(e: any) => {
												if (type == 'number') {
													disableNumberArrows(e);
												}
											}}
											onWheel={(e: any) => {
												if (type == 'number') {
													e.target.blur();
												}
											}}
											className={`${
												['first_name', 'middle_name', 'last_name'].includes(
													name
												)
													? 'text-capitalize '
													: ''
											}`}
											// maxLength={10}
										/>
									)}
									name={name}
									rules={{ maxLength: 10 }}
								/>
							);
						}
					)}
					<div
						className='position-fixed w-100 bottom-0 start-0 px-3 pb-3'
						style={{ backgroundColor: '#fff' }}
					>
						<CustomButton
							className='btn-blue'
							type='submit'
							expand='block'
							title={t('Update')}
							disabled={!isValid}
						/>
					</div>
				</form>
			</div>
		</section>
	);
};
