/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import {
	IonContent,
	IonItem,
	IonList,
	IonPage,
	IonRadio,
	IonRadioGroup,
	IonTextarea,
} from '@ionic/react';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import { Header } from '../../components/layout/header/Header';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { addLeadDisposition } from '../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';

export const AddLeadDisposition: React.FC = () => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [closeReason, setCloseReason] = useState(false);
	// const router = useIonRouter();
	const [stage, setStage] = useState(null);
	const [date, setDate] = useState(null);
	const [time, setTime] = useState(null);
	const [updateData, setUpdateData] = useState(1);
	const [comment, setComment] = useState(null);
	const leadSelector = useSelector((state: any) => state?.lead?.leadFlowData);
	const dispatch: any = useDispatch();
	const history = useHistory();
	const { t } = useTranslation();
	const data = [
		{
			id: 1,
			name: 'Schedule a Call Back',
		},
		{
			id: 2,
			name: 'Follow Up',
		},
		{
			id: 3,
			name: 'Interested',
		},
		{
			id: 4,
			name: 'Not Interested',
		},
		{
			id: 5,
			name: 'Not Reachable',
		},
		{
			id: 6,
			name: 'Not Eligible',
		},
		{
			id: 7,
			name: 'Wrong No.',
		},
	];

	useEffect(() => {
		if (stage === 'Closed') {
			setCloseReason(true);
		} else {
			setCloseReason(false);
		}
	}, [stage]);

	const addDisposition = async () => {
		setUpdateData(updateData + 1);
		await dispatch(
			addLeadDisposition({
				data: {
					disposition: stage,
					lead_id: leadSelector?.leadId,
					date: date,
					time: time,
					comments: comment,
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					history.push('/lead-details', {
						update: updateData,
					});
				}
			});
	};
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	const token = useSelector(
		(state: any) => state.users.user_details.access_token
	);
	return (
		<IonPage>
			<Header
				name={t('Add Disposition')}
				backButton={true}
				needCross={true}
				needArrowOnClick={() => {
					leadInfo?.from === 'nimbuss'
						? history.push('/lead-details/' + leadInfo.leadId + '/' + token)
						: history.push('/lead-details');
				}}
			/>
			<IonContent className='ion-padding'>
				<div>
					<p className='fs-14 text-grey-light m-0'>
						{t('This will Help You Tag the Latest Status to Your Lead')}
					</p>
					<IonList>
						<IonRadioGroup>
							{data &&
								data?.map((item, index) => {
									return (
										<IonItem
											key={index}
											lines='none'
											className='ion-no-padding'
										>
											<IonRadio
												justify='start'
												name='stage'
												labelPlacement='end'
												value={item?.name}
												aria-label='Login'
												className='fw-600'
												onFocus={(e) => setStage(e.target.value)}
											>
												{item?.name}
											</IonRadio>
										</IonItem>
									);
								})}
						</IonRadioGroup>
						<div
							id='disposition'
							className='mt-4 w-100'
						>
							<CustomInput
								labelPlacement='stacked'
								className='w-100 flex-grow-1'
								key={'date'}
								type={'date'}
								value={date}
								onChange={(e: any) => setDate(e.target.value)}
							/>
							<CustomInput
								labelPlacement='stacked'
								className='w-100'
								type={'time'}
								name={'appt-time'}
								step='2'
								value={time}
								onChange={(e: any) => {
									setTime(e.target.value);
								}}
							/>

							<IonTextarea
								className='border border-1'
								placeholder={t('Remarks').toString()}
								maxlength={300}
								label=''
								rows={3}
								value={comment}
								onIonChange={(e: any) => setComment(e.target.value)}
							></IonTextarea>
						</div>
						<br />
						<br />
						<br />
					</IonList>
					<p className='px-3 d-none'>
						{t(
							'To update status to any different stage please use bulk status upload.'
						)}
					</p>
				</div>
			</IonContent>
			<div className='ion-padding'>
				<CustomButton
					disabled={stage ? false : true}
					expand='block'
					title={t('Proceed')}
					onClick={addDisposition}
				/>
			</div>
		</IonPage>
	);
};
