import axios, { AxiosError, AxiosResponse } from "axios";
import { useContext, useState } from "react";
import { ConsentAuthContext } from "../context/ConsentAuthContext";

axios.defaults.timeout = 15000;
axios.defaults.headers["Content-Type"] = "application/json";

type HttpMethod = "get" | "post" | "put" | "delete";

interface responseStatus {
  message: string;
  status_code: number;
  code: number;
  status: boolean;
}


interface ApiResponse<T> {
  responseData: T | null;
  error: AxiosError | null;
  loading: boolean;
}

const useApi = <T>(url: string) => {
  const {consentToken, consentUserId } = useContext(ConsentAuthContext);
  const [response, setResponse] = useState<ApiResponse<T>>({
    responseData: null,
    error: null,
    loading: false,
  });


  const executeRequest = async (method: HttpMethod, requestData?: any,
    authRequired = true,
    userIdRequired = true,
  ) => {
    // console.log("userIdRequired: ",userIdRequired);
    setResponse({ ...response, loading: true });
    try {
      let axiosResponse: AxiosResponse<T>;
      axios.defaults.headers.common['userId'] = undefined;
      if (authRequired) {
        axios.defaults.headers.common['Authorization'] = consentToken;
      }
      if (userIdRequired) {
        axios.defaults.headers.common['userId'] = consentUserId;
      }
      else{
        axios.defaults.headers.common['userId'] = undefined;
      }
      
      switch (method.toLowerCase()) {
        case "get":
          axiosResponse = await axios.get<T>(url, requestData);
          break;
        case "post":
          axiosResponse = await axios.post<T>(url, requestData);
          break;
        case "put":
          axiosResponse = await axios.put<T>(url, requestData);
          break;
        case "delete":
          axiosResponse = await axios.delete<T>(url);
          break;
        default:
          throw new Error(`Unsupported method: ${method}`);
      }

      const stubResp = axiosResponse.data as responseStatus;
      if (
        axiosResponse.status === 200 &&
        ((stubResp.code && stubResp.code.toString() === "200") ||
        (stubResp.status_code && stubResp.status_code.toString() === "200"))
      ) {
        // console.log("hbs axiosResponse.data", axiosResponse.data);
        setResponse({
          responseData: axiosResponse.data,
          error: null,
          loading: false,
        });
      } else {
        setResponse({ responseData: null, error: axiosResponse.data as AxiosError, loading: false });
      }
    } catch (err) {
      setResponse({ responseData: null, error: err as AxiosError, loading: false });
    }
  };

  return { ...response, executeRequest };
};

export default useApi;
 