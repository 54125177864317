/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonInput } from '@ionic/react';
import './CustomInput.scss';
// import '../../../theme/global.scss';
import { useRef } from 'react';

interface Input {
	id?: any;
	placeholder?: any;
	label?: any;
	type?: any;
	labelPlacement?: any | undefined;
	hidden?: boolean;
	ref?: any;
	name?: string;
	onChange?: any;
	onClick?: any;
	onBlur?: any;
	value?: any;
	errors?: any;
	maxLength?: any;
	className?: string;
	defaultValue?: any;
	disabled?: any;
	needSuffixIcon?: any;
	suffixText?: any;
	refs?: any;
	notRequiredValue?: any;
	onInput?: any;
	style?: any;
	suffixClassName?: any;
	onFocus?: any;
	step?: any;
	onKeyDown?: any;
	onBeforeInput?: any;
	outerClassName?: any;
	onPaste?: any;
	onWheel?: any;
	min?: any;
	max?: any;
}

export const CustomInput: React.FC<Input> = ({
	id,
	placeholder,
	label,
	type,
	onFocus,
	labelPlacement,
	hidden,
	name,
	onChange,
	onClick,
	onBlur,
	errors,
	className,
	defaultValue,
	disabled,
	maxLength,
	needSuffixIcon,
	// ref,
	value,
	suffixText,
	// notRequiredValue,
	style,
	suffixClassName,
	step,
	onKeyDown,
	onBeforeInput,
	outerClassName,
	onPaste,
	onWheel,
	min,
	max,
}) => {
	const ionInputEl: any = useRef<HTMLIonInputElement>(null);
	return (
		<div className={`d-flex align-items-center gap-1 ${outerClassName}`}>
			<IonInput
				id={id}
				type={type ? type : 'text'}
				label={label ? label : placeholder}
				placeholder={label}
				fill='outline'
				step={step}
				labelPlacement={labelPlacement ? labelPlacement : 'floating'}
				className={`custom-input position-relative ${
					disabled ? 'ion-disabled ' : ''
				}${errors ? 'ion-invalid ion-touched' : 'ion-valid'} ${className}`}
				hidden={hidden}
				ref={ionInputEl}
				name={name}
				value={value}
				defaultValue={defaultValue}
				onIonInput={onChange}
				onClick={onClick}
				errorText={errors}
				onIonBlur={onBlur}
				onFocus={onFocus}
				disabled={disabled}
				maxlength={maxLength}
				autocomplete='off'
				style={style}
				onKeyDown={onKeyDown}
				onBeforeInput={onBeforeInput}
				onPaste={onPaste}
				onWheel={onWheel}
				min={min}
				max={max}
				mode='md'
			></IonInput>
			{needSuffixIcon && (
				<div
					className={suffixClassName}
					style={{ zIndex: 1111 }}
				>
					{suffixText}
				</div>
			)}
		</div>
	);
};
