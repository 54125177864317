import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  IonCard,
  IonCardContent,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../components/common/custom-button/CustomButton";
import { useHistory } from "react-router-dom";
import useApi from "../../api/useApi";
import CustomForm from "../../components/common/custom_form/custom_form.component";
import ApplicationContext from "../../context/ApplicationContext";
import { useBusyLoading } from "../../context/BusyLoadingContext";
import { COMPANYNAMELIST, OCCUPATION_DETAIL } from "../../helpers/api-endpoints";
import { showToastAlert } from "../../helpers/functions";
import {
  OccupationDetailsCompanyRequest,
  OccupationDetailsForm,
  OccupationDetailsRequest,
} from "../../model/occupation_bean";
import {occupation_details_schema} from "./occupation_details.schema";
import { Header } from "../../components/layout/header/Header";
import { REDIRECT } from "../../helpers/constant";
import CustomPopoverSearch from "../../components/common/custom-popover/CustomPopoverSearch";
import { CustomInput } from "../../components/common/custom-input/CustomInput";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomDropDown from "../../components/common/custom-dropdown/CustomDropDown";

const occupationDetailsFormKeys = [
  {
    name: "occupationType",
    type: "text/combo",
    placeholder: "Occupation Type *",
    mandatory: true,
    values: [
      { value: "salaried", description: "Salaried" },
      { value: "self_employed", description: "Self employed" },
      { value: "business", description: "Business" },
      { value: "professional", description: "Professional" },
      { value: "student", description: "Student" },
      { value: "others", description: "Others" },
    ],
  },
  {
    name: "jobDescription",
    type: "text",
    placeholder: "Job Description *",
  },
  {
    name: "annualIncome",
    type: "number",
    placeholder: "Annual Income *",
  },
  {
    name: "totalWorkExp",
    type: "number",
    placeholder: "Total Work Experience (Years) *",
  },
];

const occupationDetailsCompany = [
  {
    name: "companyName",
    type: "text",
    placeholder: "Company Name *",
  },
];

interface Props {
  title: string;
  nextTabUrl?: string;
  inpCallback?: () => void;
  isProposer: boolean;
  occupationDetailsForm?: OccupationDetailsForm;
  readonly: boolean;
}

const OccupationDetails: React.FC<Props> = ({
  title,
  inpCallback,
  nextTabUrl,
  isProposer,
  occupationDetailsForm,
  readonly = false,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [validateForm, setValidateForm] = useState<boolean>();
  const {
    proposerOccupationDetailsValid,
    setProposerOccupationDetailsValid,
    assuredOccupationDetailsValid,
    setAssuredOccupationDetailsValid,
  } = useContext(ApplicationContext);

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [companyList, setCompanyList] = useState([]);
  const [openCompanyModal, setOpenCompanyModal] = useState(false);
  const companyAPI = useApi<any>(COMPANYNAMELIST);
  const { responseData, loading, executeRequest, error } =
  useApi<any>(OCCUPATION_DETAIL);
  const { setBusyState } = useBusyLoading();
 
  const {
    loading: companyListLoading,
    error: companyListError,
  } = useApi<any>(COMPANYNAMELIST);

  useEffect(()=>{
    if (companyAPI.responseData?.status) {
        setCompanyList(companyAPI.responseData?.companyListData);
    }
  },[companyAPI.responseData]);

  const fetchCompanyList = useCallback((search : string) => {
    const url = `${COMPANYNAMELIST}?searchItem=${search}&page=${page}&size=${size}`;
    companyAPI.executeRequest("get", null, true, false, url);
  }, [search, page, size, companyAPI]);

  useEffect(() => {
    if (search) {
      fetchCompanyList(search);
    }
  }, [search, fetchCompanyList]);

  const {
    control,
    register,
    trigger,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm<OccupationDetailsForm>({
    resolver: yupResolver(occupation_details_schema(isProposer)),
    defaultValues: occupationDetailsForm,
  });

  useEffect(() => {
    setBusyState(loading || companyListLoading);
    if (error || companyListError) {
      showToastAlert({
        type: "error",
        message: error?.message || companyListError?.message,
      });
    }
  }, [loading, companyListLoading, error, companyListError, setBusyState]);

  useEffect(() => {
    if (responseData?.status) {
      showToastAlert({ type: "success", message: responseData?.message });

      if (inpCallback) {
        inpCallback();
        if (nextTabUrl) history.push(nextTabUrl);
      }
    }
  }, [responseData]);

  useEffect(() => {
    if (isProposer) {
      setProposerOccupationDetailsValid(isValid);
    } else {
      setAssuredOccupationDetailsValid(isValid);
    }
  }, [isValid, isProposer, setProposerOccupationDetailsValid, setAssuredOccupationDetailsValid]);

  const onSubmit = async (data: OccupationDetailsForm) => {
    trigger();
    const payload = new OccupationDetailsRequest(data);
    await executeRequest("post", payload);
  };

  return (
    <IonPage className="bg-web-white container-width-md container-spacing-md border-md-radius-top">
      <Header
        className="card-header"
        name={t(title ?? "Occupation Details")}
        backButton={true}
        needArrow={true}
        needArrowOnClick={() => {
          window.location.href = REDIRECT.DASHBOARD;
        }}
      />

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">OccupationDetails</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-padding-bottom">
          <div className="min-vh-100">
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonCard>
                <IonCardContent>
                  <CustomForm
                    readonly={readonly}
                    formKeys={occupationDetailsFormKeys}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    isValid={validateForm}
                    errors={errors}
                    trigger={trigger}
                  />
                  {occupationDetailsCompany.map(
                    (
                      { name, type, placeholder, isDisabled }: any,
                      index: any
                    ) => {
                      const errorText: any =
                        errors && Object.keys(errors).length > 0 && errors;

                      return (
                        <div key={index}>
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur } }) => (
                            <CustomInput
                              type={type}
                              label={t(placeholder)}
                              onBlur={onBlur}
                              id={name}
                              onClick={(e: any) => {
                                if (name === "companyName") {
                                  setOpenCompanyModal(true);
                                }
                              }}
                              value={getValues(name)}
                              errors={t(errorText[name]?.message || "")}
                              onChange={(e: any) => {
                                if (name === "companyName") {
                                  e.preventDefault();
                                  setOpenCompanyModal(true);
                                } else {
                                  onChange(e);
                                }
                              }}
                            />
                          )}
                          name={name}
                        />
                        {name === "companyName" && (
                          <div className="mb-3">
                            <CustomPopoverSearch
                              value={search}
                              searchApi={fetchCompanyList}
                              list={companyList}
                              setList={setCompanyList}
                              isOpen={openCompanyModal}
                              searchListModalClose={setOpenCompanyModal}
                              title={t("Company Names")}
                              searchbarPlaceholder={t("Search Company by “Name”")}
                              customNoDataMessage={t("Search by your company name")}
                              setValue={(val: any) => {
                                setValue("companyName", val);
                                trigger("companyName");
                              }}
                            />
                          </div>
                        )}
                      </div> 
                      );
                    }
                  )}
                </IonCardContent>
              </IonCard>
              <div className="position-fixed w-100 bottom-0 start-0 px-5 pb-3 bg-white">
                <IonGrid>
                  <IonRow class="ion-justify-content-center ion-align-items-center">
                    <CustomButton
                      className="btn-blue w-100"
                      type="submit"
                      expand="block"
                      title="Update"
                      onClick={() => {
                        handleSubmit(onSubmit);
                        setValidateForm(isValid);
                      }}
                      // disabled={!isValid}
                      // title={t('Update')}
                      // disabled={!isValid}
                    />
                  </IonRow>
                </IonGrid>
              </div>
            </form>
          </div>
        </IonContent>
      </IonContent>
    </IonPage>
  );
};

export default OccupationDetails;
