/* eslint-disable @typescript-eslint/no-unused-vars */
import { IonContent, IonItem, IonSpinner, isPlatform } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import './punjab.scss';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { LenderNameForAPICall } from '../../../helpers/lenders';
import { useDispatch, useSelector } from 'react-redux';
import {
	getLeadFlowUrl,
	lenderSpfDetailsFlow,
	pnbEsignGetDocument,
	pnbEsignGetStatus,
	pnbEsignPaymentLink,
} from '../../../store/reducers/lead/leadSlice';
import {
	DownloadFileBlob,
	DownloadFileInApp,
	showInAppBrowserView,
	showToastAlert,
} from '../../../helpers/functions';
import { unwrapResult } from '@reduxjs/toolkit';
import { PushNotifications } from '@capacitor/push-notifications';
import {
	AndroidSettings,
	IOSSettings,
	NativeSettings,
} from 'capacitor-native-settings';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { t } from 'i18next';

export const PunjabEsign: React.FC<any> = () => {
	const location = useLocation<any>();

	const dispatch = useDispatch();
	const { selectedLender, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);
	const leadInfo = useSelector((state: any) => state.lead.leadFlowData);
	const lenderSpfDetails = useSelector(
		(state: any) => state?.lead?.lenderSpecificDetails
	);
	const [disableBtn, setDisableBtn] = useState(true);
	const [url, setUrl] = useState('');

	const [showNotificationModel, setShowNitificationModel] = useState(false);
	const [spinner, setSpinner] = useState(false);

	useEffect(() => {
		showToastAlert({
			type: 'success',
			message: 'E-sign request success.',
			autoClose: false,
		});
	}, []);

	useEffect(() => {
		if (location.pathname !== '/lead-lender-specific-form-steps') {
			return;
		}

		dispatch(getLeadFlowUrl())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					const url = result.url;

					const pathname = new URL(url).origin;

					setUrl(pathname);
				}
			});
	}, [location.pathname]);

	const registerNotifications = async () => {
		const permStatus = await PushNotifications.checkPermissions();

		if (permStatus.receive == 'denied') {
			setShowNitificationModel(true);
			setSpinner(false);
		} else if (permStatus.receive == 'prompt-with-rationale') {
			setShowNitificationModel(true);
			setSpinner(false);
		} else {
			setShowNitificationModel(false);
		}
	};

	const esign = async () => {
		console.log({
			lender_name: LenderNameForAPICall.PUNJAB,
			lead_id: leadInfo?.leadId,
			lead_application_id: leadFlowData?.lead_app_id || selectedLender[0]?.id,
			return_url: window?.location?.href,
		});

		await dispatch(
			pnbEsignGetStatus({
				// param: '/' + leadFlowData?.leadId,
				data: {
					lender_name: LenderNameForAPICall.PUNJAB,
					lead_id: leadInfo?.leadId,
					lead_application_id:
						leadFlowData?.lead_app_id || selectedLender[0]?.id,
					return_url: window?.location?.href,
				},
			})
		).then((res: any) => {
			if (res?.payload?.data?.data?.signer_info[0]?.status === 'signed') {
				setDisableBtn(false);
			} else {
				setDisableBtn(true);
				showToastAlert({
					type: 'error',
					message: 'Lender through usngined esign request.',
				});
				dispatch(
					lenderSpfDetailsFlow({
						...lenderSpfDetails,
						step: 'step4',
					})
				);
			}
		});
	};

	const getDocument = async () => {
		console.log({
			lender_name: LenderNameForAPICall.PUNJAB,
			lead_id: leadInfo?.leadId,
			lead_application_id: leadFlowData?.lead_app_id || selectedLender[0]?.id,
			return_url: '/lead-lender-specific-form-steps',
		});

		await dispatch(
			pnbEsignGetDocument({
				// param: '/' + leadFlowData?.leadId,
				data: {
					lender_name: LenderNameForAPICall.PUNJAB,
					lead_id: leadInfo?.leadId,
					lead_application_id:
						leadFlowData?.lead_app_id || selectedLender[0]?.id,
					return_url: window?.location?.href,
				},
			})
		).then((res: any) => {
			console.log(res);

			if (res?.payload?.status) {
				showToastAlert({ type: 'success', message: 'Download Initiated' });
				if (isPlatform('mobileweb')) {
					DownloadFileBlob(
						res?.payload?.data.data.docket_Info[0].content,
						'pnb_doc',
						'pdf'
					);
				} else if (
					isPlatform('android') ||
					isPlatform('ios') ||
					isPlatform('ipad') ||
					isPlatform('iphone')
				) {
					// setSpinner(true);
					registerNotifications();

					DownloadFileInApp(
						res?.payload?.data.data.docket_Info[0].content,
						'pnb_doc',
						'pdf'
					);
				} else {
					DownloadFileBlob(
						res?.payload?.data.data.docket_Info[0].content,
						'pnb_doc',
						'pdf'
					);
				}
			}

			console.log(res);
		});
	};

	const paymentLink = async () => {
		console.log({
			lender_name: LenderNameForAPICall.PUNJAB,
			lead_id: leadInfo?.leadId,
			lead_application_id: leadFlowData?.lead_app_id || selectedLender[0]?.id,
			return_url: window?.location?.href,
		});

		await dispatch(
			pnbEsignPaymentLink({
				// param: '/' + leadFlowData?.leadId,
				data: {
					lender_name: LenderNameForAPICall.PUNJAB,
					lead_id: leadInfo?.leadId,
					lead_application_id:
						leadFlowData?.lead_app_id || selectedLender[0]?.id,
					return_url: `${url}${location.pathname}`,
				},
			})
		).then((res: any) => {
			if (res?.payload?.status) {
				showToastAlert({ type: 'success', message: 'Payment successful.' });
				showInAppBrowserView(res?.payload?.payment_url);
				dispatch(
					lenderSpfDetailsFlow({
						...lenderSpfDetails,
						step: 'step4',
					})
				);
				window.close();
			}
		});
	};

	return (
		<>
			<IonContent>
				<>
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
							<div className='mb-4 mt-4 '>
								<h6 className='mt-3'>
									Complete your E-SIGN by clicking below button
								</h6>
							</div>
							<CustomButton
								type='submit'
								className='w-100 fw-bold dashboard-add-lead-button'
								expand='block'
								title={'E-SIGN GET STATUS'}
								// disabled={!isValid}
								onClick={() => {
									esign();
									// formDownload();
									// onSubmit(watch());
								}}
							/>
							<CustomButton
								type='submit'
								className='w-100 fw-bold dashboard-add-lead-button'
								expand='block'
								title={'E-SIGN GET DOCUMENT'}
								disabled={disableBtn}
								onClick={() => {
									getDocument();
									// formDownload();
									// onSubmit(watch());
								}}
							/>
							<CustomButton
								type='submit'
								className='w-100 fw-bold dashboard-add-lead-button'
								expand='block'
								title={'PROCEED TO PAYMENT'}
								disabled={disableBtn}
								onClick={() => {
									paymentLink();
									// formDownload();
									// onSubmit(watch());
								}}
							/>
						</div>
					</div>
				</>
			</IonContent>
			{spinner && (
				<div
					className='position-fixed  w-100  d-flex justify-content-center align-items-center h-100'
					style={{
						top: '0px',
						left: '50%',
						transform: 'translate(-50%,0%)',
						backgroundColor: 'rgba(0,0,0,0.2)',
					}}
				>
					<IonSpinner name='lines-sharp' />
				</div>
			)}
			<CustomModal
				id=''
				isOpen={showNotificationModel}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setShowNitificationModel(false)}
				breakpoints={[]}
				className='confirm-popup-middle height-auto'
				modalContent={
					<div className={`wrapper py-4 confirm-popup-middle height-auto`}>
						<IonItem lines='none'>
							<div className='d-flex flex-column'>
								<p
									className='fs-14 mb-2'
									style={{ color: 'var(--text-dark-grey)' }}
								>
									Please enable notification permission from settings.
								</p>
							</div>
						</IonItem>
						<div className='d-flex justify-content-center gap-3 px-3'>
							<CustomButton
								className='w-50'
								fill='solid'
								onClick={() => {
									NativeSettings.open({
										optionAndroid: AndroidSettings.AppNotification,
										optionIOS: IOSSettings.Notifications,
									});
									setShowNitificationModel(false);
								}}
								title={t('Ok')}
							/>
						</div>
					</div>
				}
				needIonContentWraper={false}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
