import { IonImg } from '@ionic/react';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { CustomInput } from '../../../../components/common/custom-input/CustomInput';
import { DynamicDropdown } from '../../../../components/common';
import { coApplicantFormOne, salutationArr } from './TataBl';
import { fetchMasterData } from '../../../../store/reducers/lead/leadSlice';
import CustomRadioButton from '../../../../components/common/custom-radio-button/CustomRadioButton';

export const CoApplicantForm1: React.FC<any> = ({
	control,
	getValues,
	errors,
}) => {
	const [genderList, setGender] = useState([]);
	const [maritalStatus, setMaritalStatus] = useState([]);
	const [religions, setReligions] = useState([]);
	const [category, setCateogry] = useState([]);
	const [occupations, setOccupations] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		getDataFromMaster();
	}, []);

	const getDataFromMaster = () => {
		[
			'GENDER',
			'TATABL_MARITIAL_STATUS',
			'TATABL_RELIGION',
			'TATABL_CATEGORY',
			'HL_OCCUPATION',
		].forEach(async (ele: any) => {
			await dispatch(fetchMasterData({ param: ele }))
				.then(unwrapResult)
				.then((result: any) => {
					if (result?.status) {
						switch (ele) {
							case 'GENDER':
								setGender(result?.data);
								break;
							case 'TATABL_MARITIAL_STATUS':
								setMaritalStatus(result?.data);
								break;
							case 'TATABL_RELIGION':
								setReligions(result?.data);
								break;
							case 'TATABL_CATEGORY':
								setCateogry(result?.data);
								break;
							case 'HL_OCCUPATION':
								setOccupations(result?.data);
								break;
						}
					}
				});
		});
	};

	/**
	 * Set dropdown data
	 * @param name field name
	 */
	const setDropDownOptions = (name: string) => {
		switch (name) {
			case 'salutation':
				return salutationArr;
			case 'gender':
				return genderList;
			case 'marital_status':
				return maritalStatus;
			case 'religion':
				return religions;
			case 'category':
				return category;
			case 'customer_occupation':
				return occupations;
		}
	};

	return (
		<>
			{coApplicantFormOne.map(
				({ name, type, placeholder }: any, index: any) => {
					const errorText: any =
						errors && Object.keys(errors).length > 0 && errors;
					return (
						<div key={index}>
							{type === 'radio' ? (
								<Controller
									key={index}
									control={control}
									render={({ field: { onChange, name } }) => (
										<div className='mb-2'>
											<h6 className='mt-3'>{t(placeholder)}</h6>
											<CustomRadioButton
												type='group'
												value={getValues(name)}
												onIonChange={(e: any) => {
													onChange(e);
												}}
												className='mb-2'
												ionRadioClassName='me-4'
												labelPlacement='end'
												data={salutationArr}
											/>
											<div className='error-text px-3 sc-ion-input-md'>
												{t(errorText[name]?.message)}
											</div>
										</div>
									)}
									name={name}
								/>
							) : type === 'dropdown' ? (
								<div style={{ marginBottom: '15px' }}>
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, name } }) => (
											<DynamicDropdown
												label={t(`${placeholder}`)}
												data={setDropDownOptions(name)}
												name={name}
												onChange={(e: any) => {
													onChange(e);
												}}
												bindField={'value'}
												showField1={'value'}
												value={getValues(name)}
												onBlur={onBlur}
												errors={t(errorText[name]?.message)}
											/>
										)}
										name={name}
									/>
								</div>
							) : type === 'date' ? (
								<Controller
									control={control}
									render={({ field: { onChange, onBlur, name } }) => (
										<CustomInput
											type={type}
											needSuffixIcon={true}
											suffixClassName={'position-absolute date-of-birth-sufix'}
											suffixText={
												<IonImg
													src='/images/icons/input-dob-calendar-icon.svg'
													alt='dob'
												/>
											}
											outerClassName={'position-relative'}
											label={t(placeholder)}
											onBlur={onBlur}
											id={name}
											onChange={(e: any) => {
												onChange(e);
											}}
											value={getValues(name)}
											errors={t(errorText[name]?.message || '')}
										/>
									)}
									name={name}
								/>
							) : (
								<Controller
									control={control}
									render={({ field: { onChange, onBlur } }) => (
										<CustomInput
											type={type}
											label={t(placeholder)}
											onBlur={onBlur}
											id={name}
											onChange={(e: any) => {
												onChange(e);
											}}
											value={getValues(name)}
											className={`${name === 'pan_no' ? 'text-uppercase' : ''}${
												['first_name', 'middle_name', 'last_name'].includes(
													name
												)
													? ' text-capitalize '
													: ''
											}`}
											errors={t(errorText[name]?.message)}
										/>
									)}
									name={name}
								/>
							)}
						</div>
					);
				}
			)}
		</>
	);
};
