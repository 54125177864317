import {
	IonContent,
	IonFooter,
	IonImg,
	IonPage,
	isPlatform,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { Header } from '../../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import { BasicInfoKyc } from './BasicInfoKyc';
import './MyKyc.scss';
import { WelcomeInfoKyc } from './WelcomeInfoKyc';
import { DocumentsKyc } from './DocumentsKyc';
import ProfessionalDetailsKyc from './ProfessionalDetailsKyc';
import { BankDetailKyc } from './BankDetailKyc';
import { useHistory, useLocation } from 'react-router';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { CircularProgressBar } from '../../../components/common/custom-circular-progress-bar/CircularProgressBar';
import { useDispatch, useSelector } from 'react-redux';
import { kycStep } from '../../../store/reducers/user/userSlice';
import { eventAttributes, notifyHandler } from '../../../helpers/functions';
import { NotifyVisitors } from 'capacitor-plugin-notifyvisitors';

export const MyKyc = () => {
	const { t } = useTranslation();
	const dmaSelector = useSelector((state: any) => state?.users);
	const [displayStepsCounter, setDisplayStepsCounter] = useState(true);
	const [currentStep, setCurrentStep] = useState<string | null>(null);
	const [needArrow, setNeedArrow] = useState(true);
	const [needCross, setNeedCross] = useState(false);
	const loaction: any = useLocation();
	const dispatch: any = useDispatch();
	const history: any = useHistory();
	const userData = useSelector((state: any) => state?.users);
	// Notify Visitors
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const userName = useSelector(
		(state: any) => state?.users?.homepage?.profile?.name
	);
	const userMobile = useSelector(
		(state: any) => state?.users?.user_details?.mobile
	);

	const entityType = useSelector(
		(state: any) => state?.users?.user_details?.entityType
	);

	const userLang = useSelector(
		(state: any) => state?.oneTimeScreen?.language?.name
	);

	const userAttributes = {
		userId: userId,
		attributes: {
			name: userName,
			id: userId,
			mobile: userMobile,
			user_language: userLang,
			entityType:
				entityType === 'Individual'
					? 'DMA'
					: entityType === 'BTM'
					? 'employee'
					: entityType,
		},
	};
	useEffect(() => {
		if (isPlatform('android') || isPlatform('ios')) {
			notifyHandler('event', eventAttributes('add_DMA_kyc_page'));
			notifyHandler('user', userAttributes.attributes);
			const inAppData = {
				tokens: { name: userAttributes },
				customRules: { banerrType: 'add dma kyc page' },
			};
			NotifyVisitors.show(inAppData);
		} else {
			(window as any).nv('event', eventAttributes('add_DMA_kyc_page'), 10, 1);
			(window as any).nv('user', userId, userAttributes.attributes);
		}
	}, []);

	useEffect(() => {
		(() => {
			const ch = userData?.kycStep;

			switch (ch) {
				case 'KYC1':
					if (history?.location?.state?.from === 'dashboard') {
						setCurrentStep(null);
					} else {
						setCurrentStep('step1');
					}

					break;
				case 'KYC2':
					setCurrentStep('step2');
					break;
				case 'KYC3':
					setCurrentStep('step3');
					break;
				case 'KYC4':
					setCurrentStep('step4');
					break;
				default:
					setCurrentStep(null);
					break;
			}
		})();
	}, [userData?.kycStep]);

	const handleBackButton = () => {
		if (currentStep !== null) {
			if (loaction?.state?.from === 'update-details') {
				history.push('/update-details');
			}
			if (dmaSelector?.dmaFlow?.dmaId) {
				history.push('/dashboard/teams');
			} else {
				history.push('/dashboard/home');
			}
		}
		if (currentStep === null) {
			if (dmaSelector?.dmaFlow?.dmaId) {
				history.push('/dashboard/teams');
			} else {
				history.push('/dashboard/home');
			}
		}
	};

	return (
		<IonPage className=''>
			{currentStep !== null && (
				<Header
					name={
						loaction?.state?.from === 'update-details'
							? loaction?.state?.title
							: dmaSelector?.dmaFlow?.dmaId
							? t('DMA KYC')
							: t('KYC')
					}
					backButton={true}
					needArrow={needArrow}
					needCross={needCross}
					titleClassName='fw-bold'
					needCrossOnClick={() => handleBackButton()}
					needArrowOnClick={() => handleBackButton()}
					style={{ borderBottom: '1px solid #00000029' }}
				/>
			)}
			{currentStep === null && (
				<Header
					className={'d-none d-md-block'}
					backButton={true}
					needArrow={true}
					backButtonText=''
					needArrowImg={'/images/icons/backarrow_white_bg.png'}
					needArrowImgStyle={{ height: '26px' }}
					needArrowOnClick={() => {
						handleBackButton();
					}}
					name=''
				/>
			)}

			<IonContent
				className={currentStep === null ? `help-desk` : ''}
				scrollY={false}
			>
				<div className='bg-web h-100 overflowY-auto pt-md-3'>
					<div
						className={`container-width-md ${
							currentStep === null
								? 'welcome-kyc conatiner-spacing-md'
								: 'bg-web-white'
						} py-md-3 border-md-radius-10 opacity-1`}
					>
						<div className=''>
							{currentStep === null && (
								<Header
									className={'d-block d-md-none'}
									backButton={true}
									needArrow={true}
									headerBg={'#0165e142'}
									backButtonText=''
									needArrowImg={'/images/icons/backarrow_white_bg.png'}
									needArrowImgStyle={{ height: '26px' }}
									needArrowOnClick={() => {
										handleBackButton();
									}}
									name=''
								/>
							)}
							{loaction?.state?.from !== 'update-details' &&
								currentStep !== null && (
									<div className='kyc-main-content'>
										<div
											className={`steps-header px-3 py-2 font-semibold ${
												displayStepsCounter ? '' : 'd-none'
											}`}
										>
											{currentStep === 'step1' ? (
												<div className='d-flex align-items-center justify-content-between'>
													{dmaSelector?.dmaAbout?.RegisteredAs?.toLowerCase() ===
													'firm'
														? t('Authorised Signatory Info')
														: t('Basic Info')}
													<CircularProgressBar
														strokeWidth='4'
														sqSize='45'
														percentage={25}
														value={'1/4'}
														ringBgColor='#808080'
														ringColor='var(--ion-color-primary)'
														textColor='rgba(12, 14, 43, 1)'
													/>
												</div>
											) : currentStep === 'step2' ? (
												<div className='d-flex align-items-center justify-content-between'>
													{t('Documents')}
													<CircularProgressBar
														strokeWidth='4'
														sqSize='45'
														percentage={50}
														value={'2/4'}
														ringBgColor='#808080'
														ringColor='var(--ion-color-primary)'
														textColor='rgba(12, 14, 43, 1)'
													/>
												</div>
											) : currentStep === 'step3' ? (
												<div className='d-flex align-items-center justify-content-between'>
													{t('Professional Details')}
													<CircularProgressBar
														strokeWidth='4'
														sqSize='45'
														percentage={75}
														value={'3/4'}
														ringBgColor='#808080'
														ringColor='var(--ion-color-primary)'
														textColor='rgba(12, 14, 43, 1)'
													/>
												</div>
											) : currentStep === 'step4' ? (
												<div className='d-flex align-items-center justify-content-between'>
													{t('Bank Details')}
													<CircularProgressBar
														strokeWidth='4'
														sqSize='45'
														percentage={100}
														value={'4/4'}
														ringBgColor='#808080'
														ringColor='var(--ion-color-primary)'
														textColor='rgba(12, 14, 43, 1)'
													/>
												</div>
											) : (
												''
											)}
										</div>
									</div>
								)}
							<div>
								{currentStep === 'step1' ? (
									<BasicInfoKyc setCurrentStep={setCurrentStep} />
								) : currentStep === 'step2' ? (
									<DocumentsKyc setCurrentStep={setCurrentStep} />
								) : currentStep === 'step3' ? (
									<ProfessionalDetailsKyc setCurrentStep={setCurrentStep} />
								) : currentStep === 'step4' ? (
									<BankDetailKyc />
								) : (
									<WelcomeInfoKyc />
								)}
							</div>
						</div>
						<div className='d-none d-md-block'>
							{currentStep === null && (
								<>
									{' '}
									<div className='kyc-footer px-3 px-md-5 pb-md-2'>
										<div className='kyc-footer d-flex justify-content-center'>
											<IonImg
												src='/images/icons/kyc/secure-kyc-icon.svg'
												style={{ width: '18px' }}
												className=''
											/>
											<span
												className='fs-12 ms-2'
												style={{
													color: 'var(--text-dark-grey)',
												}}
											>
												{t('100% secure KYC')}
											</span>
										</div>
										<CustomButton
											expand='block'
											onClick={() => {
												setNeedArrow(false);
												setNeedCross(true);
												setDisplayStepsCounter(true);
												setCurrentStep('step1');
												dispatch(kycStep('KYC1'));
											}}
											className='btn-blue'
											title={t('Get Started')}
										/>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</IonContent>
			<IonFooter className=''>
				<div className='d-block d-md-none'>
					{currentStep === null && (
						<>
							{' '}
							<div className='kyc-footer px-3 px-md-5 pb-md-2'>
								<div className='kyc-footer d-flex justify-content-center'>
									<IonImg
										src='/images/icons/kyc/secure-kyc-icon.svg'
										style={{ width: '18px' }}
										className=''
									/>
									<span
										className='fs-12 ms-2'
										style={{
											color: 'var(--text-dark-grey)',
										}}
									>
										{t('100% secure KYC')}
									</span>
								</div>
								<CustomButton
									expand='block'
									onClick={() => {
										setNeedArrow(false);
										setNeedCross(true);
										setDisplayStepsCounter(true);
										setCurrentStep('step1');
										dispatch(kycStep('KYC1'));
									}}
									className='btn-blue'
									title={t('Get Started')}
								/>
							</div>
						</>
					)}
				</div>
			</IonFooter>
		</IonPage>
	);
};
