import { IonCol, IonContent, IonGrid, IonRow, isPlatform } from '@ionic/react';
import { useEffect, useState } from 'react';
import {
	ACLKeys,
	StatusNum,
	TEAMS_TABS,
	USER_ROLES,
} from '../../../helpers/constant';
import { useHistory } from 'react-router';

import { useTranslation } from 'react-i18next';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import {
	dmaFlowData,
	teamsPage,
	teamsPage1,
} from '../../../store/reducers/user/userSlice';
import { Sidebar } from '../../../components/layout/sidebar/Sidebar';
import { Divider } from '../../../components/common/divider/Divider';
import { manageTabSegment } from '../../../store/reducers/dmaSlice';
import {
	ACL,
	eventAttributes,
	notifyHandler,
} from '../../../helpers/functions';

export default function TeamsBusinessOps() {
	const segmentSelector = useSelector(
		(state: any) => state?.dma?.moduleTabsSegment
	);
	const dmaStatusCount = useSelector((state: any) => state?.users?.teamscount);
	const role = useSelector((state: any) => state?.users?.user_details);
	const permissions = useSelector(
		(state: any) => state?.users?.user_details?.permissions
	);
	const dmaSelector = useSelector((state: any) => state?.users?.dmaFlow);
	const [selectedSegment, setSelectedSegment] = useState(
		segmentSelector?.segmentName || TEAMS_TABS.DMA
	);
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch: any = useDispatch();
	// Notify Visitors
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const userName = useSelector(
		(state: any) => state?.users?.homepage?.profile?.name
	);
	const userMobile = useSelector(
		(state: any) => state?.users?.user_details?.mobile
	);
	const entityType = useSelector(
		(state: any) => state?.users?.user_details?.entityType
	);
	const userLang = useSelector(
		(state: any) => state?.oneTimeScreen?.language?.name
	);

	const userAttributes = {
		attributes: {
			name: userName,
			id: userId,
			mobile: userMobile,
			user_language: userLang,
			entityType:
				entityType === 'Individual'
					? 'DMA'
					: entityType === 'BTM'
					? 'employee'
					: entityType,
		},
	};

	useEffect(() => {
		if (isPlatform('android')) {
			notifyHandler('event', eventAttributes('DMA_page'));
			notifyHandler('user', userAttributes.attributes);
		} else {
			(window as any).nv('event', 'DMA_page', userAttributes, 10, 1);
			(window as any).nv('user', userId, userAttributes.attributes);
		}
	}, []);

	useEffect(() => {
		teamBusinessData();
	}, [selectedSegment]);
	const teamBusinessData = async () => {
		if (selectedSegment === TEAMS_TABS.ANDROTEAM) {
			await dispatch(
				manageTabSegment({
					moduleName: history?.location.pathname,
					segmentName: TEAMS_TABS.ANDROTEAM,
				})
			);
			await dispatch(teamsPage1());
		} else {
			await dispatch(
				manageTabSegment({
					moduleName: history?.location.pathname,
					segmentName: TEAMS_TABS.DMA,
				})
			);
			await dispatch(teamsPage());
		}
		await dispatch(
			dmaFlowData({
				...dmaSelector,
				dmaCode: '',
				title: '',
			})
		);
	};

	const navigateToTeamsList = async (teamStatus: string, pageTitle: string) => {
		await dispatch(
			dmaFlowData({
				...dmaSelector,
				dmaCode: teamStatus,
				title: pageTitle,
				update: dmaSelector?.update + 1 || 1,
				isAndro: false,
			})
		);
		history.push('/team-list');
	};

	return (
		<>
			<div className='d-flex h-100'>
				<Sidebar />
				<div className='w-100 h-100 my-md-3 container-width-md'>
					{/* <div className='p-0 p-md-3'> */}
					<div className='tab-menu  bg-white d-flex justify-content-center align-items-center border-md-radius-top'>
						<div className='tab-backgroud  p-1'>
							<div className=' d-flex  gap-3'>
								<span
									onClick={() => setSelectedSegment(TEAMS_TABS.DMA)}
									className={selectedSegment === TEAMS_TABS.DMA ? 'active' : ''}
								>
									{t('DMA')}
								</span>
								<span
									className={
										selectedSegment === TEAMS_TABS.ANDROTEAM ? 'active' : ''
									}
									onClick={() => setSelectedSegment(TEAMS_TABS.ANDROTEAM)}
								>
									{t('Andro Team')}
								</span>
							</div>
						</div>
					</div>

					{selectedSegment === TEAMS_TABS.DMA ? (
						<>
							<IonContent
								style={{
									borderTop: '1px solid #00000029',
									overflowX: 'hidden',
								}}
								className={`team-dashboard ${
									[USER_ROLES.BUH, USER_ROLES.BUSINESSOPS].includes(
										role.roleType[0]
									)
										? 'teams-hide-buttons'
										: 'teams-show-buttons'
								}`}
							>
								<IonGrid>
									<IonRow>
										<IonCol
											size='12'
											className='my-3'
										>
											<div
												className='bg-primary rounded-3 cursor-pointer  mydma text-white px-3 py-3'
												onClick={() => {
													navigateToTeamsList(StatusNum.ACTIVE, 'My DMA');
												}}
											>
												<div className='row align-items-center'>
													<div className='col-8'>
														<div className='d-flex align-items-center  gap-1'>
															<img
																src='/images/icons/teams/my-dmas.svg'
																className='rounded-circle'
																alt=''
															/>
															<p className='mb-0 fs-16 fw-600'>{t('My DMA')}</p>
														</div>
													</div>
													<div className='col-4 text-center fs-26 fw-700 font-semibold'>
														{dmaStatusCount?.total_dma_count}
													</div>
												</div>
											</div>
										</IonCol>

										<IonCol size='6'>
											<div
												onClick={() => {
													navigateToTeamsList(
														StatusNum.INIT,
														'DMA Soft Code Generation'
													);
												}}
												className='boxes cursor-pointer  border border-1 team-card'
											>
												<div className='col-12 d-flex justify-content-between'>
													<span className=' fs-24 font-semibold'>
														{dmaStatusCount?.total_softcode_generation_count}
													</span>
													<img
														src='/images/icons/teams/dma-softcode.svg'
														alt='dma'
													/>
												</div>
												<p className='mb-0 darkest-grey fs-14'>
													{t('DMA Soft Code')} <br /> {t('Generation')}
												</p>
											</div>
										</IonCol>
										<IonCol size='6'>
											<div
												onClick={() => {
													navigateToTeamsList(StatusNum.KYC, 'KYC Stage');
												}}
												className='boxes cursor-pointer  border border-1 team-card'
											>
												<div className='col-12 d-flex justify-content-between'>
													<span className=' fs-24 font-semibold'>
														{dmaStatusCount?.total_kyc_stage_count}
													</span>
													<img
														src='/images/icons/teams/kyc.svg'
														alt='dma'
													/>
												</div>
												<p className='mb-0 darkest-grey fs-14'>{t('KYC')}</p>
											</div>
										</IonCol>
										<IonCol size='6'>
											<div
												onClick={() => {
													navigateToTeamsList(StatusNum.PAYOUT, 'KYC Stage');
												}}
												className='boxes cursor-pointer  border border-1 team-card'
											>
												<div className='col-12 d-flex justify-content-between'>
													<span className=' fs-24 font-semibold'>
														{dmaStatusCount?.total_payout_count}
													</span>
													<img
														src='/images/icons/teams/add-payout.svg'
														alt='markparent'
													/>
												</div>

												<p className='mb-0 darkest-grey fs-14'>
													{t('Add Payout')}
												</p>
											</div>
										</IonCol>
										<IonCol size='6'>
											<div
												onClick={() => {
													navigateToTeamsList(
														StatusNum.PARENTCHILD,
														t('Mark Parent or Child')
													);
												}}
												className='boxes cursor-pointer  border border-1 team-card'
											>
												<div className='col-12 d-flex justify-content-between'>
													<span className=' fs-24 font-semibold'>
														{dmaStatusCount?.total_parentchild_count}
													</span>
													<img
														src='/images/icons/teams/mark-parent-child.svg'
														alt='markparent'
													/>
												</div>
												<p className='mb-0 darkest-grey fs-14'>
													{t('Mark Parent or Child')}
												</p>
											</div>
										</IonCol>
										{
											// Will add roles once confirmed
											[''].includes(role.roleType[0]) ? (
												''
											) : (
												<IonCol size='12'>
													<div
														onClick={() => {
															navigateToTeamsList(StatusNum.REWORK, 'Rework');
														}}
														className='boxes cursor-pointer d-flex justify-content-between team-card align-items-center border border-1'
													>
														<div className='d-flex gap-2 align-items-center'>
															<img
																src='/images/icons/teams/rework-request.svg'
																alt=''
															/>
															<p className='mb-0 darkest-grey fs-14'>
																{t('Rework Requests')}
															</p>
														</div>
														<div className='col-4 fs-24 text-center font-semibold'>
															{dmaStatusCount?.total_rework_count}
														</div>
													</div>
												</IonCol>
											)
										}
										{[USER_ROLES.TC, USER_ROLES.BUSINESSOPS].includes(
											role?.roleType[0]
										) ? (
											''
										) : (
											<>
												<IonCol size='6'>
													<div
														onClick={() => {
															navigateToTeamsList(
																StatusNum.NEWAPPROVAL,
																t('New DMA Approval & Payout')
															);
														}}
														className='boxes cursor-pointer border border-1 team-card'
													>
														<div className='col-12 d-flex justify-content-between'>
															<span className=' fs-24 font-semibold'>
																{dmaStatusCount?.total_new_approval_count}
															</span>
															<img
																src='/images/icons/teams-dma-verification-icon.svg'
																alt='newdmaapproval'
															/>
														</div>
														<p className='mb-0 darkest-grey fs-14'>
															{t('New DMA Approval & Payout')}
														</p>
													</div>
												</IonCol>
												<IonCol size='6'>
													<div
														onClick={() => {
															navigateToTeamsList(
																StatusNum.EXISTINGAPPROVAL,
																'Existing DMA Approval & Payout'
															);
														}}
														className='boxes cursor-pointer border border-1 team-card'
													>
														<div className='col-12 d-flex justify-content-between'>
															<span className=' fs-24 font-semibold'>
																{dmaStatusCount?.total_existing_approval_count}
															</span>
															<img
																src='/images/icons/teams/existing-dma.svg'
																alt='existingdma'
															/>
														</div>
														<p className='mb-0 darkest-grey fs-14'>
															{t('Existing DMA Approval & Payout')}
														</p>
													</div>
												</IonCol>
											</>
										)}
										<IonCol size='12'>
											<div
												onClick={() => {
													navigateToTeamsList(StatusNum.REVISED, '');
												}}
												className='boxes cursor-pointer  d-flex justify-content-between team-card align-items-center border border-1'
											>
												<div className='d-flex gap-2 align-items-center'>
													<img
														src='/images/icons/teams/rework-request.svg'
														alt=''
													/>
													<p className='mb-0 darkest-grey fs-14'>
														{t('Revised Payout')}
													</p>
												</div>
												<div className='col-4 fs-24 text-center font-semibold'>
													{dmaStatusCount?.total_revised_payout_count}
												</div>
											</div>
										</IonCol>
										{dmaStatusCount?.total_allocator_count ? (
											<IonCol size='12'>
												<div
													onClick={() => {
														navigateToTeamsList(
															StatusNum.ALLOCATOR,
															'Allocate DMA'
														);
													}}
													className='boxes cursor-pointer  d-flex justify-content-between team-card align-items-center border border-1'
												>
													<div className='d-flex gap-2 align-items-center'>
														<img
															src='/images/icons/teams/rework-request.svg'
															alt=''
														/>
														<p className='mb-0 darkest-grey fs-14'>
															{t('Allocate DMA')}
														</p>
													</div>
													<div className='col-4 fs-1 text-center font-semibold'>
														{dmaStatusCount?.total_allocator_count}
													</div>
												</div>
											</IonCol>
										) : (
											''
										)}
									</IonRow>
								</IonGrid>
							</IonContent>
							<Divider
								className='py-1 d-block d-md-none position-relative'
								style={{
									background: '#DFDFDF',
								}}
							/>
							<div className='bg-white p-3 border-md-radius-bottom'>
								<div className='d-flex justify-content-around align-items-center pe-md-2 pt-0 pt-md-2 py-3 gap-3'>
									<div
										onClick={() => {
											navigateToTeamsList(
												StatusNum.UNDERPROCESS,
												'Under Process'
											);
										}}
										className='cursor-pointer team-card w-50 d-flex flex-column flex-grow-1   px-3 py-2 rounded-3'
									>
										<div className='col-12 d-flex justify-content-between'>
											<span className=' fs-24 font-semibold'>
												{dmaStatusCount?.total_processing_count}
											</span>
											<img
												src='/images/icons/teams/under-process.svg'
												alt='dma'
											/>
										</div>
										<p className='mb-0 darkest-grey fs-14'>
											{t('Under Process')}
										</p>
									</div>
									<div
										onClick={() => {
											navigateToTeamsList(StatusNum.REJECTED, 'Rejected');
										}}
										className='cursor-pointer team-card w-50 d-flex flex-column flex-grow-1   px-3 py-2 rounded-3'
									>
										<div className='col-12 d-flex justify-content-between'>
											<span className=' fs-24 font-semibold'>
												{dmaStatusCount?.total_rejected_count}
											</span>
											<img
												src='/images/icons/teams/rejected.svg'
												alt='dma'
											/>
										</div>
										<p className='mb-0 darkest-grey fs-14'>{t('Rejected')}</p>
									</div>
								</div>
								{![USER_ROLES.BUH, USER_ROLES.BUSINESSOPS].includes(
									role.roleType[0]
								) && (
									<div className='d-flex justify-content-between gap-3 '>
										{ACL(permissions, ACLKeys.CREATE_DMA) && (
											<CustomButton
												title={t('Add DMA')}
												className='w-100   border-white'
												style={{ '--border-radius': '22px' }}
												onClick={() => history.push('/add-dma')}
											/>
										)}
										{ACL(permissions, ACLKeys.INVITE_DMA) && (
											<CustomButton
												onClick={() => history.push('/invite-dma')}
												title={t('Invite DMA')}
												className='w-100 bg-white'
												fill={'outline'}
												style={{ '--border-radius': '22px' }}
											/>
										)}
									</div>
								)}
							</div>
						</>
					) : (
						<>
							<IonContent
								style={{
									borderTop: '1px solid #00000029',
									height: 'calc(100vh - 235px)',
									overflowX: 'hidden',
								}}
							>
								<IonGrid>
									<IonRow>
										<IonCol
											size='12'
											className='mb-3'
										>
											<IonRow>
												<IonCol size='6'>
													<div
														onClick={async () => {
															await dispatch(
																dmaFlowData({
																	...dmaSelector,
																	dmaCode: StatusNum.ACTIVE,
																	title: t('My Andro Team').toString(),
																	update: dmaSelector?.update + 1 || 1,
																	isAndro: true,
																})
															);
															history.push('/team-list');
														}}
														className='boxes cursor-pointer team-card border border-1'
													>
														<div className='d-flex justify-content-between'>
															<span className='fs-24 fw-700'>
																{dmaStatusCount?.total_myteam}
															</span>
															<img
																src='/images/icons/teams/my-andro-team.svg'
																alt='myteam'
															/>
														</div>
														<p className='fs-14'>{t('My Andro Team')}</p>
													</div>
												</IonCol>
												<IonCol size='6'>
													<div
														onClick={async () => {
															await dispatch(
																dmaFlowData({
																	...dmaSelector,
																	dmaCode: StatusNum.INVITED,
																	title: t('Invitees'),
																	update: dmaSelector?.update + 1 || 1,
																	isAndro: true,
																})
															);
															history.push('/invitee-list');
														}}
														className='boxes cursor-pointer team-card border border-1'
													>
														<div className='d-flex justify-content-between'>
															<span className='fs-24 fw-700'>
																{dmaStatusCount?.total_invitees}
															</span>
															<img
																src='/images/icons/teams/invitees.svg'
																alt='myteam'
															/>
														</div>
														<p className='fs-14'>{t('Invitees')}</p>
													</div>
												</IonCol>
											</IonRow>
										</IonCol>
									</IonRow>
								</IonGrid>
							</IonContent>
							{ACL(permissions, ACLKeys.CREATE_EMPLOYEE) && (
								<div
									className='bg-web-white p-3 position-relative border-md-radius-bottom'
									style={{ bottom: '12px' }}
								>
									<div className='d-flex justify-content-between gap-3 '>
										<CustomButton
											onClick={() => {
												history.push('/invite-employee');
											}}
											title={t('Invite Employee')}
											className='w-100'
											style={{ '--border-radius': '22px' }}
										/>
									</div>
								</div>
							)}
						</>
					)}
					{/* </div> */}
				</div>
			</div>
		</>
	);
}
