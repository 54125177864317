/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import './AddLoanLeadInfo.scss';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import CustomDropDown from '../../../components/common/custom-dropdown/CustomDropDown';
import { LEAD_STATUS, REGEX, USER_ROLES } from '../../../helpers/constant';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IonContent, IonFooter, isPlatform } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import {
	addlead,
	dashboardLeadDetails,
	dashboardUpdateLead,
	fetchBureauCustomerReport,
	getLeadManagers,
	getLeadUmDetails,
	leadFlow,
} from '../../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	disableNumberArrows,
	eventAttributes,
	notifyHandler,
} from '../../../helpers/functions';
import { NotifyVisitors } from 'capacitor-plugin-notifyvisitors';

const addLeadScema = yup.object().shape({
	mobile: yup
		.string()
		.length(10, 'Mobile No. Should Be 10 Digits.')
		.required('Mobile is Required.')
		.matches(REGEX.INDIAN_MOBILE_NUMBERS, 'Please Enter a valid Mobile No.'),
});

export const LoanLeadStep1: React.FC = () => {
	const leadData = useSelector((state: any) => state?.lead?.leadFlowData);
	const userData = useSelector((state: any) => state?.users?.user_details);
	const { t } = useTranslation();
	const [managers, setManagers] = useState([]);
	const dispatch = useDispatch();
	const [lead, setLeadData] = useState<any>();
	const roleType = useSelector(
		(state: any) => state?.users?.user_details?.roleType?.[0]
	);
	const [leadStepOneSchema, setLeadStepOneSchema] = useState(addLeadScema);

	// Notify Visitors
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const userName = useSelector(
		(state: any) => state?.users?.homepage?.profile?.name
	);
	const userMobile = useSelector(
		(state: any) => state?.users?.user_details?.mobile
	);
	const userDetails = useSelector((state: any) => state?.users?.user_details);
	const { leadcreatedthrough } = useSelector((state: any) => state?.lead);

	const entityType = useSelector(
		(state: any) => state?.users?.user_details?.entityType
	);

	const userLang = useSelector(
		(state: any) => state?.oneTimeScreen?.language?.name
	);

	const {
		handleSubmit,
		control,
		getValues,
		trigger,
		setValue,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(leadStepOneSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			mobile: '',
			andro_lead_manager: 0,
		},
	});
	useEffect(() => {
		setValue('mobile', '');
		setMobileNumber('');
	}, [leadData.clearMobile]);

	const userAttributes = {
		userId: userId,
		attributes: {
			name: userName,
			id: userId,
			mobile: userMobile,
			user_language: userLang,
			entityType:
				entityType === 'Individual'
					? 'DMA'
					: entityType === 'BTM'
					? 'employee'
					: entityType,
		},
	};
	useEffect(() => {
		if (isPlatform('android') || isPlatform('ios')) {
			notifyHandler('event', eventAttributes('lead_creation_page'));
			notifyHandler('user', userAttributes.attributes);
			const inAppData = {
				tokens: { name: userAttributes },
				customRules: { banerrType: 'lead creation page' },
			};
			NotifyVisitors.show(inAppData);
		} else {
			(window as any).nv('event', eventAttributes('lead_creation_page'), 10, 1);
			(window as any).nv('user', userId, userAttributes.attributes);
		}
	}, []);

	const onSubmit = async () => {
		const { mobile, andro_lead_manager } = getValues();
		const body = {
			mobile_no: mobile,
			...leadData?.selectedProductData,
			andro_lead_manager:
				andro_lead_manager && andro_lead_manager != 0
					? andro_lead_manager
					: userData?.lead_default_manager_id,
			lead_type: 'Lead',
			loginUserId: userData?.id,
			lead_generated_through:
				leadcreatedthrough?.leadCreationtype != undefined
					? String(leadcreatedthrough?.leadCreationtype)
					: '4',
		};

		await dispatch(
			leadData?.leadId
				? dashboardUpdateLead({ param: leadData.leadId, data: body })
				: addlead({ data: body })
		)
			.then(unwrapResult)
			.then(async (response: any) => {
				if (response?.status) {
					await dispatch(
						fetchBureauCustomerReport({
							lead_type: 'Lead',
							lead_id: response?.newLoanApplication?.id || leadData.leadId,
							mobile_no: mobile,
						})
					);
					await dispatch(
						leadFlow({
							...leadData,
							step: 'step2',
							headerTitle: 'Add Lead',
							subHeaderStepsCounter: true,
							leadId: response?.newLoanApplication?.id || leadData?.leadId,
							leadMobile: mobile,
							isNewLead: true,
							reSendCibilOtp: false,
						})
					);
					reset({ mobile: '', andro_lead_manager: 0 });
				}
			});
	};
	const [umData, setUmData] = useState<any>('');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [mobileNumber, setMobileNumber] = useState(
		getValues('mobile') || umData?.mobile_no
	);
	useEffect(() => {
		setMobileNumber(umData?.mobile_no);
	}, [umData]);
	useEffect(() => {
		if (leadData?.from !== 'customer') {
			getLeadManager();
		}
		reset({ mobile: '', andro_lead_manager: 0 });

		if (leadData?.leadId) {
			dispatch(dashboardLeadDetails({ param: leadData.leadId }))
				.then(unwrapResult)
				.then((result: any) => {
					setLeadData(result?.leads);
					setValue('andro_lead_manager', result?.leads?.andro_lead_manager);
					trigger('andro_lead_manager');
				});
			dispatch(getLeadUmDetails({ param: leadData.leadId }))
				.then(unwrapResult)
				.then((result: any) => {
					if (result?.status) {
						setUmData(result?.data);
						if (result?.data?.mobile_no) {
							setValue('mobile', result?.data?.mobile_no);
							trigger('mobile');
						}
					}
				});
		}
	}, []);

	const getLeadManager = async () => {
		await dispatch(
			getLeadManagers({
				param: `?subproductid=${leadData?.selectedProductData?.product_category_id}`,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					if ([USER_ROLES.DMA].includes(roleType)) {
						if (response?.data?.length > 0) {
							const updatedSchema = addLeadScema.concat(
								yup.object().shape({
									andro_lead_manager: yup
										.number()
										.min(1, 'Please choose Andro lead manager')
										.required('Andro lead manager is Required.'),
								})
							);
							setLeadStepOneSchema(updatedSchema);
							if (response?.data?.length === 1) {
								const selectedManager = response?.data?.map((item: any) => {
									return {
										...item,
										checked: true,
									};
								});
								setManagers(selectedManager);
								setValue('andro_lead_manager', selectedManager[0]?.id);
							} else {
								setManagers(response?.data);
							}
						}
					} else if (leadData?.from !== 'customer') {
						const updatedSchema = addLeadScema.concat(
							yup.object().shape({
								andro_lead_manager: yup
									.number()
									.min(1, 'Please choose Andro lead manager')
									.required('Andro lead manager is Required.'),
							})
						);
						setLeadStepOneSchema(updatedSchema);
						setManagers(response?.data);
					}
				}
			});
	};

	return (
		<>
			<IonContent
			// className='ion-padding'
			// style={{
			// 	// borderTop: '1px solid #00000029',
			// 	// overflowY: 'auto',
			// 	height: 'calc(100vh - 120px)',
			// }}
			>
				<div className='select-product small-container bg-web h-100'>
					<div className='bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
						<form
							onSubmit={handleSubmit(onSubmit)}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									handleSubmit(onSubmit);
								}
							}}
						>
							<Controller
								control={control}
								render={({ field: { onChange, onBlur, name } }) => (
									<CustomInput
										type={'number'}
										placeholder={`${t('Customer Mobile No.')} *`}
										onBlur={onBlur}
										name={name}
										onChange={(e: any) => {
											onChange(e);
											setMobileNumber(e.detail.value);
										}}
										errors={t(errors?.mobile?.message || '')}
										disabled={
											leadData?.from !== 'customer' && leadData?.leadId
												? true
												: false
										}
										value={mobileNumber}
										className='pt-2'
										onBeforeInput={(evt: any) => {
											//Only numbers accepted on user input
											!/[0-9]/.test(evt.data) && evt.preventDefault();
											//Only accepts 10 characters on user input
											if (evt.target.value.length === 10) {
												evt.preventDefault();
											}
										}}
										onPaste={(e: any) => {
											//Only get numbers on user paste
											e.preventDefault();
											let pasteContent = e.clipboardData.getData('Text');
											if (pasteContent != '') {
												pasteContent = pasteContent.replace(/\D/g, '');
												if (pasteContent.length > 10) {
													pasteContent = pasteContent.slice(0, 10);
												}
											}
											setValue('mobile', pasteContent);
											setMobileNumber(pasteContent);
											trigger('mobile');
										}}
										onKeyDown={(e: any) => {
											disableNumberArrows(e);
										}}
										onWheel={(e: any) => e.target.blur()}
									/>
								)}
								name={'mobile'}
							/>
							{leadData?.from !== 'customer' ? (
								[USER_ROLES.DMA].includes(roleType) ? (
									managers.length > 0 && (
										<>
											<CustomDropDown
												{...{
													className: `${
														errors?.andro_lead_manager && 'dropdown-error'
													} ${
														managers?.length === 1 && 'pe-none custom-disabled'
													}`,
													isCheckbox: false,
													title: t('Select OneAndro Manager') + ' *',
													setData: (data: any) => {
														setManagers(data);
														const val = data.filter(
															(item: any) => item.checked
														);
														setValue('andro_lead_manager', val[0]?.id);
														trigger('andro_lead_manager');
													},
													data: managers,
													placeholder: t('Search Andro Manager by “Name”'),
													isSearch: true,
													modalTitle: t('OneAndro Manager'),
												}}
											/>
											{errors?.andro_lead_manager && (
												<div className='input-bottom ps-3 pt-1'>
													<div className='error-text sc-ion-input-md'>
														{t(errors?.andro_lead_manager?.message || '')}
													</div>
												</div>
											)}
										</>
									)
								) : (
									<>
										<CustomDropDown
											{...{
												className: `${
													errors?.andro_lead_manager && 'dropdown-error'
												}`,
												isCheckbox: false,
												title:
													userDetails?.entityType === 'BTM'
														? t('Select OneAndro Lead Owner *')
														: t('Select OneAndro Manager *'),
												setData: (data: any) => {
													setManagers(data);
													const val = data.filter((item: any) => item.checked);
													setValue('andro_lead_manager', val[0]?.id);
													trigger('andro_lead_manager');
												},
												data: managers,
												placeholder: t('Search Andro Manager by “Name”'),
												isSearch: true,
												modalTitle: t('OneAndro Manager'),
												title2: lead?.andro_manager_name,
												disable:
													leadData?.leadId &&
													leadData?.leadStatus !== LEAD_STATUS.RAW_LABEL
														? true
														: false,
											}}
										/>
										{errors?.andro_lead_manager && (
											<div className='input-bottom ps-3 pt-1'>
												<div className='error-text sc-ion-input-md'>
													{t(errors?.andro_lead_manager?.message || '')}
												</div>
											</div>
										)}
									</>
								)
							) : (
								''
							)}

							<CustomButton
								type='submit'
								// disabled={!isValid}
								//onClick={handleSubmit(onSubmit)}
								className='btn-blue mt-md-4 d-none d-md-block'
								expand='block'
								title={
									leadData?.via == 'share' ? t('Continue') : t('Create Lead')
								}
							/>
						</form>
					</div>
				</div>
			</IonContent>
			<IonFooter className='ion-padding bg-web-white d-block d-md-none'>
				<CustomButton
					type='submit'
					// disabled={!isValid}
					onClick={handleSubmit(onSubmit)}
					className='btn-blue '
					expand='block'
					title={leadData?.via == 'share' ? t('Continue') : t('Create Lead')}
				/>
			</IonFooter>
		</>
	);
};
