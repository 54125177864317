//// @ts-nocheck


import React, { useContext, useEffect, useState } from 'react';
import { IonContent, IonPage, IonHeader, IonToolbar, IonTitle, IonCard, IonCardContent } from '@ionic/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import CustomForm from '../../components/common/custom_form/custom_form.component';
// import {CustomForm} from 'andro-insurance-lib';
import application_basic_details_schema from './application_basic_details.schema';
import { ApplicationBasicDetailsForm, ApplicationBean, GenerateLeadRequest } from '../../model/insurance_bean';
import ApplicationContext from '../../context/ApplicationContext';
import useApi from '../../api/useApi';
import { GENERATE_INSURANCE_LEAD } from '../../helpers/api-endpoints';
import { useBusyLoading } from '../../context/BusyLoadingContext';
import { showToastAlert } from '../../helpers/functions';
import { AuthContext } from '../../context/AuthContext';
import { Header } from '../../components/layout/header/Header';
import { REDIRECT } from '../../helpers/constant';
import { t } from 'i18next';





var applicationBasicDetailsFormKeys = [

  {
    name: 'loanApplicationNumber',
    type: 'text',
    placeholder: 'Loan Application Number',
  },
  {
    name: 'policyProposedFor',
    //hbs change here
    type: 'text/combo',
    mandatory: true,
    placeholder: 'Policy Proposed For *',
    values: [
      { value: 'self', description: 'Self' },
      { value: 'spouse', description: 'Spouse' },
      { value: 'children', description: 'Children' },
      { value: 'grand_children', description: 'Grand Children' },
    ],
  },

];



export interface ApplicationBasicDetailsProps {
  title?: string;
  applicationBasicDetailsForm?: ApplicationBasicDetailsForm;
  showProposedFor?: boolean;

  // userId: any;
  // setShowBasicInfoForm: any;
  // getUserDetailsInfo: any;
}


export const ApplicationBasicDetails: React.FC<ApplicationBasicDetailsProps> = ({ title, applicationBasicDetailsForm, showProposedFor = true }) => {
  const history = useHistory();

  const [validateForm, setValidateForm] = useState<boolean>();
  const { setAppBean, applicationBean } = useContext(ApplicationContext);

  const { isIFA } = useContext(AuthContext);


  // console.log("applicationBean basic details", applicationBean);

  const { responseData, loading, executeRequest, error } =
    useApi<any>(GENERATE_INSURANCE_LEAD);

  const { setBusyState } = useBusyLoading();

  useEffect(() => {
    setBusyState(loading);
  }, [loading]);

  useEffect(() => {
    showToastAlert({ type: "error", message: error?.message! });
  }, [error]);



  const location = useLocation();


  if (applicationBasicDetailsForm === undefined) {

    if (location.pathname === '/application_basic_details') {
      const state = location.state as any;
      const applicationDetailsProps = state.applicationDetailsProps || {};

      applicationBasicDetailsForm = applicationDetailsProps.applicationBasicDetailsForm;
      showProposedFor = applicationDetailsProps.showProposedFor;
      title = applicationDetailsProps.title;
    }
  }


  useEffect(() => {
    if (!showProposedFor) {
      const filteredFormKeys = applicationBasicDetailsFormKeys.filter(item => item.name !== 'policyProposedFor');
      applicationBasicDetailsFormKeys = filteredFormKeys;
      // setValue()
      // applicationBasicDetailsFormKeys.push({
      //     name: 'policyProposedFor',
      //     type: 'text',
      //     placeholder: 'Policy',
      //     disabled: true,
      //     hidden:true,
      // });

    }

  }, [applicationBasicDetailsFormKeys]);



  const { control, register, trigger, handleSubmit, setValue,
    getValues, formState: { errors, isValid, dirtyFields } } = useForm({
      resolver: yupResolver(application_basic_details_schema),
      defaultValues: {
        loanApplicationNumber: applicationBasicDetailsForm?.loanApplicationNumber || '',
        policyProposedFor: applicationBasicDetailsForm?.policyProposedFor,
        // mobile: applicationBasicDetailsForm?.mobile,
        // insuranceTypeId: applicationBasicDetailsForm?.insuranceTypeId,
        // managerId: applicationBasicDetailsForm?.managerId,
      },
    });
  // const [dateOfBirth, setDateOfBirth] = useState(null);

  // const dmaSelector = useSelector((state: any) => state?.users?.dmaFlow);



  const onSubmit = async (data: any) => {




    applicationBean!.loanApplicationNumber = getValues('loanApplicationNumber')!;
    applicationBean!.policyProposedFor = getValues('policyProposedFor')!;
    // applicationBean!.ifaId =  parseInt(userId!);

    console.log("appBeanStub ", applicationBean);
    setAppBean(applicationBean!);



    const generateLeadRequest = new GenerateLeadRequest(applicationBean!);


    const payload = JSON.stringify(generateLeadRequest);

    console.log(payload);




    executeRequest("post", payload, true);


    // history.replace('/ifainsurance');



  };


  useEffect(() => {
    // console.log("hbs resp",responseData);
    // console.log("hbs resp",responseData?.data);


    if (responseData?.data) {
      console.log("hbs success ", responseData?.data);

      const appBeanFin = new ApplicationBean(responseData?.data);

      console.log("hbs appBeanFin ", appBeanFin);

      setAppBean(appBeanFin);
      history.replace('/ifainsurance');
    }

  }, [responseData]);



  return (
    <IonPage className='bg-web-white container-width-md container-spacing-md border-md-radius-top'>
      <Header
        className="card-header"
        name={t(title ?? 'Application Basic Details')}
        backButton={true}
        needArrow={true}
        needArrowOnClick={() => {
          history.push(REDIRECT.DASHBOARD);
        }}
      />

      {/* <IonContent className="custom-ion-content"> */}
      {/* <IonContent> */}
      <IonContent className='ion-padding-bottom'>

        {/* <div className='align-items-center col-6 col-md-3'> */}

        {/* <section
        className='overflowX-hidden'
      // style={{ height: 'calc(100vh - 20px)' }}
      > */}

        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonCard>
              <IonCardContent>
                <CustomForm formKeys={applicationBasicDetailsFormKeys} 
                control={control} getValues={getValues} setValue={setValue}
                 errors={errors} isValid={validateForm} trigger={trigger} />
              </IonCardContent>
            </IonCard>
            <div
              className='position-fixed w-100 bottom-0 start-0 px-3 pb-3'
              style={{ backgroundColor: '#fff' }}
            >
              <CustomButton
                className='btn-blue'
                type='submit'
                expand='block'
                title='Proceed'
                disabled={!isIFA}
              />
            </div>
          </form>
        </div>
        {/* </section> */}
      </IonContent>
    </IonPage>
  );
}

