import {
	IonContent,
	IonFooter,
	IonPage,
	useIonViewWillEnter,
} from '@ionic/react';
import React, { useState } from 'react';
import { Header } from '../../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import './Efranking.scss';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { useHistory } from 'react-router';
import { Player } from '@lottiefiles/react-lottie-player';
// import { INAPPCONFIG } from '../../../helpers/constant';
// import { CircularProgressBar } from '../../../components/common/custom-circular-progress-bar/CircularProgressBar';
// import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	dmaFlowData,
	userENotifiaction,
} from '../../../store/reducers/user/userSlice';
import { CustomStepsCountHeaderBar } from '../../../components/common/custom-steps-count-header-bar/CustomStepsCountHeaderBar';

const stepsProgressSubHeaderData = [
	{
		percentage: 50,
		value: '1',
		text: 'E-franking',
	},
	{
		percentage: 100,
		value: '2',
		text: 'E-franking',
	},
];

export const EfrankingSend: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const dmaSelector = useSelector((state: any) => state?.users);
	const [showEFrankingSuccess, setShowEFrankingSuccess] =
		useState<boolean>(false);
	const [agreementUrl, setAgreementUrl] = useState<any>('');
	const dispatch = useDispatch();

	// const showEfrankingAgreement = async () => {
	// 	InAppBrowser.create(agreementUrl, '_self', INAPPCONFIG);
	// };

	const sendForEsigning = async () => {
		await dispatch(userENotifiaction({ param: dmaSelector?.dmaFlow?.dmaId }))
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setShowEFrankingSuccess(true);
					// Commented to check if tick animation getting impacted
					// setTimeout(() => {
					// 	dispatch(
					// 		dmaFlowData({
					// 			...dmaSelector?.dmaFlow,
					// 			update: dmaSelector?.dmaFlow?.update + 1 || 1,
					// 		})
					// 	);
					// 	history.push('/team-list');
					// 	setShowEFrankingSuccess(false);
					// }, 2000);
				}
			});
	};

	useIonViewWillEnter(() => {
		setAgreementUrl(dmaSelector?.dmaFlow?.dmaResult?.url);
	});

	return (
		<IonPage>
			<Header
				needArrow={true}
				backButton={true}
				name={t('')}
				className={showEFrankingSuccess ? 'd-none' : ''}
			/>
			{!showEFrankingSuccess && (
				<CustomStepsCountHeaderBar
					currentStep={'step2'}
					headerStep={'e-franking-initiate'}
					displayStepsCounter={true}
					data={stepsProgressSubHeaderData}
				/>
			)}
			<IonContent>
				{showEFrankingSuccess ? (
					<div className='bg-web pt-md-4 e-franking-initiate-height d-md-flex align-items-md-center justify-content-md-center'>
						<div
							className='border-md-radius container-width-md p-md-3 green-top-linear-gradient d-md-flex align-items-md-center justify-content-md-center e-franking-send-container-height h-100'
							style={{ flex: 'auto' }}
						>
							<div className='text-center flex-column d-flex align-items-center justify-content-center h-100'>
								<Player
									autoplay={true}
									loop={false}
									controls={false}
									keepLastFrame={true}
									onEvent={(event) => {
										if (event === 'complete') {
											dmaFlowData({
												...dmaSelector?.dmaFlow,
												update: dmaSelector?.dmaFlow?.update + 1 || 1,
											});
											setTimeout(() => {
												history.push('/team-list');
												setShowEFrankingSuccess(false);
											}, 500);
										}
									}}
									src='/images/icons/json/SendforRework.json'
									style={{ height: '102px', width: '102px' }}
								/>
								<h3 className='fs-18 fw-600 darkest-grey'>
									{t('Agreement Sent for')} <br />
									{t('E-Signing')}
								</h3>
							</div>
						</div>
					</div>
				) : (
					<div className='bg-web pt-md-4 d-flex d-md-block e-franking-initiate-height align-items-center justify-content-center'>
						<div className='d-flex d-md-block container-width-md bg-web-white justify-content-center border-md-radius-10 p-md-3'>
							<div className='text-center m-auto container-width-300'>
								<div className='d-inline-block'>
									<Player
										// className="pe-3"
										autoplay={true}
										loop={true}
										controls={false}
										src='/images/icons/json/GreenTick.json'
										style={{ height: '72px', width: '72px' }}
									/>
								</div>
								<h3 className='fs-20 fw-600 darkest-grey mt-2 mb-3'>
									{t('E-franking completed')}
								</h3>
								{agreementUrl && (
									<p
										className='fs-14 heading-color m-auto'
										style={{ maxWidth: '300px' }}
									>
										{t(
											'by clicking the link below, you can view and download the generated agreement for the DMA.'
										)}
									</p>
								)}
								{/* {agreementUrl && (
							<div
								className='d-flex justify-content-center fw-600 primary-text-color'
								onClick={() => showEfrankingAgreement()}
							>
								<IonImg
									src='/images/icons/arrow-top-right-icon.svg'
									alt='arrow icon'
								></IonImg>
								<span className='d-inline-block ms-2'>
									{t('View Agreement')}
								</span>
							</div>
						)} */}
								<div
									className='d-flex gap-2 ion-padding flex-column d-none d-md-block m-auto'
									style={{ maxWidth: '240px' }}
								>
									<CustomButton
										className={'w-100 btn-blue fw-600'}
										title={t('Send for E-Signing')}
										onClick={() => sendForEsigning()}
									/>
								</div>
							</div>
						</div>
					</div>
				)}
			</IonContent>
			{!showEFrankingSuccess && (
				<IonFooter>
					<div className='d-flex gap-2 ion-padding flex-column d-block d-md-none'>
						<CustomButton
							className={'w-100 btn-blue fw-600'}
							title={t('Send for E-Signing')}
							onClick={() => sendForEsigning()}
						/>
					</div>
				</IonFooter>
			)}
		</IonPage>
	);
};
