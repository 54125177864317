// import { configureStore } from '@reduxjs/toolkit';
import userSlice from './reducers/user/userSlice';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';

import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist';
import appSlice from './reducers/app/appSlice';
import leadSlice from './reducers/lead/leadSlice';
import dmaSlice from './reducers/dmaSlice';
import appStarterTemplateSlice from './reducers/app/appStarterTemplateSlice';

// export const store = configureStore({
// 	reducer: {
// 		user: userSlice,
// 	},
// });

const persistConfig = {
	key: 'root',
	version: 1,
	storage,
	transforms: [
		encryptTransform({
			secretKey: 'my-super-secret-key',
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			onError: function (error) {
				// Handle the error.
				// console.log(error);
			},
		}),
	],
};

const rootReducer = combineReducers({
	users: userSlice,
	app: appSlice,
	oneTimeScreen: appStarterTemplateSlice,
	lead: leadSlice,
	dma: dmaSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});

export const persistor: any = persistStore(store);
