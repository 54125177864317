/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonContent, IonImg, IonPage } from '@ionic/react';
import React, { useEffect, useState } from 'react';

//import { useHistory } from 'react-router';

import { useTranslation } from 'react-i18next';
import { Header } from '../../../components/layout/header/Header';
import { Player } from '@lottiefiles/react-lottie-player';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import '../../dashboard/Dashboard.scss';
import './BureauScore.scss';
import { Divider } from '../../../components/common/divider/Divider';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	checkCreditScoreVideo,
	getLeadFlowUrl,
} from '../../../store/reducers/lead/leadSlice';
import { useHistory } from 'react-router';
import { ACL, ShareData } from '../../../helpers/functions';
import { ACLKeys } from '../../../helpers/constant';
export const CheckBureauScore: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const permissions = useSelector(
		(state: any) => state?.users?.user_details?.permissions
	);

	const [openModal, setOpenModal] = useState<boolean>(false);
	const [checkScoreVideo, setCheckScoreVideo] = useState<any>();
	const scoreType = useSelector(
		(state: any) => state?.lead?.leadBureauCustomerData?.scoreType
	);
	// const userId = useSelector(
	// 	(state: any) => state?.users?.user_details?.userId
	// );

	const checkCreditScore = () => {
		dispatch(checkCreditScoreVideo())
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setCheckScoreVideo(response?.video_link);
				}
			});
	};

	useEffect(() => {
		if (location.pathname !== '/check-bureau-score') {
			return;
		}
		checkCreditScore();
	}, [location?.pathname]);

	return (
		<>
			<IonPage className='bg-web'>
				<Header
					name={''}
					backButton={true}
					needArrow={true}
					needArrowImg='/images/icons/backarrow_white_bg.png'
					needArrowImgStyle={{ height: '26px' }}
					className={'d-none d-md-block'}
					needArrowOnClick={() => {
						history.push('/dashboard/home');
					}}
				/>
				<IonContent className=' bg-web background-web '>
					<div className='green-gradiant check-bureau-score h-100 overflowY-auto mt-md-3 border-md-radius-10'>
						<div className={`py-md-3 px-md-5`}>
							<Header
								name={''}
								backButton={true}
								needArrow={true}
								needArrowImg='/images/icons/backarrow_white_bg.png'
								needArrowImgStyle={{ height: '26px' }}
								className={'d-block d-md-none '}
								needArrowOnClick={() => {
									history.push('/dashboard/home');
								}}
							/>
							<div className='d-flex flex-column  ion-padding pt-5 justify-content-center'>
								<Player
									autoplay={true}
									loop={true}
									controls={false}
									src={'/images/icons/json/BureauScoreSpeedometer.json'}
									style={{ height: '52px', width: '60px' }}
								/>
								<h3 className='text-center mt-2 mb-2 fs-18 fw-600'>
									{t('Check Credit Score')}
								</h3>
								{ACL(permissions, ACLKeys.CHECK_BUREAU) && (
									<CustomButton
										className='w-100 dashboard-add-lead-button fw-bold mb-2'
										expand='block'
										routerLink={'/add-bureau-score'}
									>
										<img
											src='/images/icons/generate_for_lead.svg'
											alt='upload'
											className='me-2'
										/>
										{t('Generate for a Lead')}
									</CustomButton>
								)}
								{ACL(permissions, ACLKeys.SHARE_CHECK_BUREAU) && (
									<CustomButton
										className='w-100 dashboard-add-lead-button fw-bold'
										//  expand='block'
										fill='outline'
										onClick={() => {
											dispatch(getLeadFlowUrl())
												.then(unwrapResult)
												.then((result: any) => {
													const appendUrl = `&check_score=true&score_type=${scoreType}`;
													const u: any = window.atob(result.url.split('=')[1]);
													const finalUrl =
														result?.url.split('?')[0] +
														'?d=' +
														window.btoa(u + appendUrl);
													if (result?.status) {
														ShareData('', '', finalUrl);
													}
												});
											//history.push('/select-lead-product', { from: 'check-score' });
										}}
									>
										<img
											src='/images/icons/blue-share-icon.svg'
											alt='upload'
											className='me-2'
										/>
										{t('Share with a Lead')}
									</CustomButton>
								)}
								{/* <CustomButton
									className='w-100 dashboard-add-lead-button fw-bold'
									//  expand='block'
									fill='outline'
									onClick={() => {
										window.open(
											`https://cibilapi.apnapaisa.com?userId=${userId}`,
											'_blank'
										);
									}}
								>
									<Player
										autoplay={true}
										loop={true}
										controls={false}
										src={'/images/icons/json/BureauScoreSpeedometer.json'}
										style={{ height: '35px', width: '45px' }}
									/>
									{'Click Here For CIBIL'}
								</CustomButton> */}
							</div>
							<Divider className='py-1' />
							<div className=' ion-padding'>
								<h5 className='fs-18 fw-600 mb-2'>
									{t('Benefits of having a lead with a good bureau score')}
								</h5>
								<div>
									<div className='d-flex align-items-center mb-3 gap-3'>
										<div>
											<img
												src='/images/icons/lower-interest-rate.svg'
												width={'40'}
												height={'40'}
												alt='icon'
											/>
										</div>
										<div className='fs-16'>{t('Lower Interest Rates')}</div>
									</div>
									<div className='d-flex align-items-center mb-3 gap-3'>
										<div>
											<img
												src='/images/icons/quicker-approval.svg'
												width={'40'}
												height={'40'}
												alt='icon'
											/>
										</div>
										<div className='fs-16'>
											{t('Quicker Approvals on Loan/Credit Cards')}
										</div>
									</div>
									<div className='d-flex align-items-center mb-3 gap-3'>
										<div>
											<img
												src='/images/icons/credit-card-limit.svg'
												width={'40'}
												height={'40'}
												alt='icon'
											/>
										</div>
										<div className='fs-16'>{t('Higher Credit Card Limit')}</div>
									</div>
									{checkScoreVideo ? (
										<div>
											<h4
												className='fs-14 ml-4 m-0 text-primary fw-600 cursor-pointer d-flex align-items-center'
												onClick={() => {
													setOpenModal(true);
												}}
											>
												<IonImg
													className='me-4 credit-score-btn'
													src='/images/icons/small-play-logo.svg'
													alt='play'
												/>{' '}
												<div className='fs-16 ml-4'>
													{t('How To Check Credit Score')}{' '}
												</div>
											</h4>
										</div>
									) : (
										''
									)}
								</div>
							</div>
						</div>
					</div>

					<CustomModal
						id='custommodal'
						modalTitle=''
						initialBreakpoint={undefined}
						className='videoplayerpopup vertical-player'
						isOpen={openModal}
						handleClose={() => setOpenModal(false)}
						breakpoints={[]}
						modalContent={
							<iframe
								src={checkScoreVideo}
								width='100%'
								height='100%'
								frameBorder='0'
								allow='autoplay; fullscreen; picture-in-picture'
								allowFullScreen
							></iframe>
						}
						needCross={false}
						needArrow={false}
						needIonContentWraper={false}
					/>
				</IonContent>
			</IonPage>
		</>
	);
};
