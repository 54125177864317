/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { IonButtons, IonImg, useIonRouter } from '@ionic/react';

import { Header } from '../../../components/layout/header/Header';

import './AddInsuranceLeadInfo.scss';
import { useLocation, useHistory } from 'react-router';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { PageExitPopUp, ShareData } from '../../../helpers/functions';
import { DashboardHome } from '../../dashboard/home/DashboardHome';
import { LeadsFlowGenerator } from '../../../components/common/custom-leads-flow-generator/LeadsFlowGenerator';
import { InsuranceLeadStep1 } from './InsuranceLeadStep1';
import InsuranceLeadStepFour from './InsuranceLeadStepFour';
import InsuranceLeadStepInfoForm from './InsuranceLeadStepInfoForm';
import InsuranceLeadStepThree from './InsuranceLeadStepThree';
import { useDispatch, useSelector } from 'react-redux';
import { getLeadFlowUrl } from '../../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
// import { leadFlow } from '../../../store/reducers/lead/leadSlice';

export const InsuranceLeadBasicInfo: React.FC = () => {
	const location = useLocation<any>();
	const router = useIonRouter();
	const history = useHistory();
	const dispatch = useDispatch();
	// const [currentStep, setCurrentStep] = useState<any>(null);
	// const [leadID, setLeadID] = useState(0);
	// const [leadMobile, setLeadMobile] = useState(0);
	// const [title, setTitle] = useState('Add Leads');
	const [needArrow, setNeedArrow] = useState(true);
	const [needCross, setNeedCross] = useState(false);

	const [displayStepsCounter, setDisplayStepsCounter] = useState(true);
	const [showPageExitPopup, setShowPageExitPopup] = useState(false);
	// const dispatch: any = useDispatch();
	const leadFlowSelector = useSelector(
		(state: any) => state?.lead?.leadFlowData
	);

	const dmaSelector = useSelector((state: any) => state?.users);
	// const { step: currentStep } = leadFlowSelector && leadFlowSelector;

	// const [cibilVerifyPayloadData, setCibilVerifyPayloadData] = useState({
	//  ChallengeConfigGUID: '',
	//  answerKey: '',
	//  questionKey: '',
	// });

	// const setCurrentStep = (value: any) => {
	// 	dispatch(
	// 		leadFlow({
	// 			...leadFlowSelector,
	// 			leadCurrentStep: value,
	// 		})
	// 	);
	// };
	const [url, setUrl] = useState('');

	useEffect(() => {
		setDisplayStepsCounter(true);
	}, [router]);
	useEffect(() => {
		dispatch(getLeadFlowUrl())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setUrl(result?.url);
				}
			});
	}, []);

	// self
	const stepsProgressSubHeaderData =
		leadFlowSelector?.productFor === 'self'
			? [
					{
						percentage: 25,
						value: '1',
						text: 'Lead Creation Info',
					},
					{
						percentage: 50,
						value: '2',
						text: 'Details of Proposer',
					},
					{
						percentage: 75,
						value: '3',
						text: 'Basic Details of Life Assured',
					},
					{
						percentage: 100,
						value: '4',
						text: 'Occupation Details of Life Assured',
					},
			  ]
			: [
					{
						percentage: 16.66,
						value: '1',
						text: 'Lead Creation Info',
					},
					{
						percentage: 33.33,
						value: '2',
						text: 'Details of Proposer',
					},
					{
						percentage: 49.49,
						value: '3',
						text: 'Basic Details of Proposer',
					},
					{
						percentage: 65.65,
						value: '4',
						text: 'Occupation Details of Proposer',
					},
					{
						percentage: 81.81,
						value: '5',
						text: 'Basic Details of Life Assured',
					},
					{
						percentage: 100,
						value: '6',
						text: 'Occupation Details of Life Assured',
					},
			  ];

	const stepsHeader = (
		<Header
			name={leadFlowSelector?.title}
			backButton={true}
			needArrow={needArrow}
			needCross={needCross}
			needCrossOnClick={() => {
				setShowPageExitPopup(true);
			}}
			needArrowOnClick={() => {
				leadFlowSelector?.leadCurrentStep === 'step1' &&
				leadFlowSelector?.from === 'nimbuss'
					? history.push(
							'/lead-details/' +
								leadFlowSelector.leadId +
								'/' +
								dmaSelector?.user_details?.access_token
					  )
					: history.replace('/dashboard/lead');
				leadFlowSelector?.leadCurrentStep !== 'step1' &&
				leadFlowSelector?.from === 'nimbuss'
					? history.push(
							'/lead-details/' +
								leadFlowSelector.leadId +
								'/' +
								dmaSelector?.user_details?.access_token
					  )
					: history.replace('/dashboard/lead');
				// leadFlowSelector?.leadCurrentStep === 'step2' &&
				// 	setCurrentStep('step1');
				// leadFlowSelector?.leadCurrentStep === 'step3' &&
				// 	setCurrentStep('step2');
				// leadFlowSelector?.leadCurrentStep === 'step4' &&
				// 	setCurrentStep('step3');
				// leadFlowSelector?.leadCurrentStep === 'step5' &&
				// 	setCurrentStep('step4');
				// leadFlowSelector?.leadCurrentStep === 'step6' &&
				// 	setCurrentStep('step5');
			}}
			backButtonText=''
			endItem={
				leadFlowSelector?.via !== 'share' ? (
					<IonButtons className='ms-3'>
						<IonImg
							className='cursor-pointer'
							src='/images/icons/share-blue.svg'
							alt='assign'
							onClick={() => {
								ShareData(
									'',
									'',
									url.split('?')?.[0] + `?lead_id=${leadFlowSelector?.leadId}`
								);
							}}
						/>
					</IonButtons>
				) : (
					''
				)
			}
		/>
	);
	const stepsComponents = [
		{
			step: 'step1',
			component: (
				<InsuranceLeadStep1
					setNeedArrow={setNeedArrow}
					setNeedCross={setNeedCross}
				/>
			),
		},
		{
			step: 'step2',
			component: <InsuranceLeadStepInfoForm />,
		},
		{
			step: 'step3',
			component: <InsuranceLeadStepThree />,
		},
		{
			step: 'step4',
			component: <InsuranceLeadStepFour />,
		},
		{
			step: 'step5',
			component: <InsuranceLeadStepThree />,
		},
		{
			step: 'step6',
			component: <InsuranceLeadStepFour />,
		},
	];
	const pageCloseModal = (
		<CustomModal
			id='custommodal'
			isOpen={showPageExitPopup}
			modalTitle=''
			initialBreakpoint={undefined}
			handleClose={() => setShowPageExitPopup(false)}
			breakpoints={[]}
			className='bureau-report'
			modalContent={PageExitPopUp(
				() => {
					setShowPageExitPopup(false);
				},
				() => {
					setShowPageExitPopup(false);
					router.push(
						leadFlowSelector?.from === 'nimbuss'
							? `/lead-details/${leadFlowSelector?.leadId}/${dmaSelector?.user_details?.access_token}`
							: '/dashboard/lead',
						'root'
					);
				}
			)}
			needCross={false}
			needArrow={false}
		/>
	);
	if (location?.pathname === '/dashboard') {
		return <DashboardHome />;
	} else {
		return (
			<LeadsFlowGenerator
				currentStep={leadFlowSelector?.leadCurrentStep}
				stepsHeader={stepsHeader}
				displayStepsCounter={displayStepsCounter}
				stepsProgressSubHeaderData={stepsProgressSubHeaderData}
				stepsComponents={stepsComponents}
				pageCloseModal={pageCloseModal}
				progressDisable={
					leadFlowSelector?.leadCurrentStep === 'step7' ? true : false
				}
			/>
		);
	}
};
