import {
	IonCol,
	IonContent,
	IonItem,
	IonPage,
	IonRow,
	isPlatform,
} from '@ionic/react';
import './BulkUploadHistory.scss';
import { Header } from '../../components/layout/header/Header';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	getDownloadHistory,
	getUploadHistory,
} from '../../store/reducers/user/userSlice';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { DownloadFileBlob, DownloadFileInApp } from '../../helpers/functions';
import { LocalNotifications } from '@capacitor/local-notifications';
import { FileOpener } from '@capacitor-community/file-opener';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
import {
	AndroidSettings,
	IOSSettings,
	NativeSettings,
} from 'capacitor-native-settings';
import { PushNotifications } from '@capacitor/push-notifications';

export const BulkUploadHistory: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	const [tableList, setTableList] = useState<any>([]);
	const userId = useSelector((state: any) => state?.users?.user_details.id);
	const [showNotificationModel, setShowNitificationModel] = useState(false);

	useEffect(() => {
		if (location.pathname === '/bulk-upload-history') {
			getTableList();
		}
	}, [location.pathname]);

	const registerNotifications = async () => {
		const permStatus = await PushNotifications.checkPermissions();

		if (permStatus.receive == 'denied') {
			setShowNitificationModel(true);
		} else if (permStatus.receive == 'prompt-with-rationale') {
			setShowNitificationModel(true);
		} else {
			setShowNitificationModel(false);
		}
	};
	const getTableList = async () => {
		try {
			const response = await dispatch(
				getUploadHistory({ param: `?loginUserId=${userId}` })
			);
			const data = unwrapResult(response);
			if (data?.status) {
				const sortedData = data.data
					.slice()
					.sort(
						(a: any, b: any) =>
							new Date(b.date).getTime() - new Date(a.date).getTime()
					)
					.reverse();
				setTableList(sortedData);
			} else {
				console.error('Error fetching data:', data?.message);
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const handleDownload = async (s3_key: any) => {
		try {
			const response = await dispatch(
				getDownloadHistory({ param: `?s3_key=${s3_key}` })
			);
			if (response?.payload?.status) {
				if (isPlatform('mobileweb')) {
					DownloadFileBlob(response?.payload?.data, s3_key, 'xlsx');
				} else if (
					isPlatform('android') ||
					isPlatform('ios') ||
					isPlatform('ipad') ||
					isPlatform('iphone')
				) {
					registerNotifications();
					DownloadFileInApp(response?.payload?.data, s3_key, 'xlsx');
					const file: any = DownloadFileInApp(
						response?.payload?.data,
						'file_name',
						'xlsx'
					);

					await LocalNotifications.schedule({
						notifications: [
							{
								title: 'Download Completed',
								body: 'Your file has been successfully downloaded.',
								id: 1,
								schedule: { at: new Date(Date.now() + 1000 * 1) },
								actionTypeId: '',
								attachments: [
									{ id: 'downloaded_file', url: `file://${s3_key}` },
								],
								extra: { route: file?.uri }, // Additional data to handle action
							},
						],
					});
				} else {
					DownloadFileBlob(response?.payload?.data, s3_key, 'xlsx');
				}
			}
		} catch (error) {
			console.error('Error downloading history:', error);
		}
	};
	useEffect(() => {
		LocalNotifications.addListener('localNotificationReceived', (data: any) => {
			if (data) {
				// setSpinner(false);
			}
		});
		LocalNotifications.addListener(
			'localNotificationActionPerformed',
			(payload: any) => {
				const route = payload.notification.extra.route;
				FileOpener.open({
					filePath: route,
				});
			}
		);
		return () => {
			LocalNotifications.removeAllListeners();
		};
	}, []);

	return (
		<>
			<IonPage className=''>
				<Header
					backButton={true}
					needArrow={true}
					backButtonText=''
					style={{
						background: isWeb ? 'white' : '#cfdff7',
					}}
					headerBg={'#cfdff7'}
					needArrowOnClick={() => {
						history.push('/dashboard/add-lead');
					}}
					needArrowImg={'/images/icons/backarrow_white_bg.png'}
					needArrowImgStyle={{ height: '26px' }}
					name={t('BULK UPLOAD HISTORY')}
				/>
				<IonContent
					fullscreen
					className=''
				>
					<div className='bg-web bu-py-md-3'>
						<div className='bg-white border-md-radius-10 bulk-upload-container'>
							<IonRow className='flex w-100 mt-2 items-center'>
								<IonCol
									size='4'
									className='text-center bold'
								>
									UUID
								</IonCol>
								<IonCol
									size='4'
									className='text-center bold'
								>
									{t('Status')}
								</IonCol>
								<IonCol
									size='4'
									className='text-center bold'
								>
									{t('Download File')}
								</IonCol>
							</IonRow>
							{tableList.map((item: any, index: number) => (
								<IonRow
									className='flex w-100 mt-2 items-center'
									key={index}
								>
									<IonCol
										size='4'
										className='text-center'
									>
										{item.uuid.toUpperCase()}
									</IonCol>
									<IonCol
										size='4'
										className='text-center'
									>
										<p>
											{t('Total Rows')} : {item.total_rows}
										</p>
										<p>
											{t('Inserted Rows')} :{item.total_inserted_rows}{' '}
										</p>
										<p>
											{t('Error Rows')} : {item.total_error_rows}
										</p>
									</IonCol>
									<IonCol
										size='4'
										className='text-center'
									>
										<CustomButton
											onClick={() => handleDownload(item.s3_key)}
											className='btn-blue w-100'
											size={'small'}
											fill={'solid'}
											disabled={item.s3_key === null}
										>
											Download Source File
										</CustomButton>
										<CustomButton
											onClick={() => handleDownload(item.error_s3_key)}
											className='btn-blue w-100'
											size={'small'}
											fill={'solid'}
											disabled={item.error_s3_key === null}
										>
											Download Error File
										</CustomButton>
									</IonCol>
								</IonRow>
							))}
						</div>
					</div>
				</IonContent>
			</IonPage>
			<CustomModal
				id=''
				isOpen={showNotificationModel}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setShowNitificationModel(false)}
				breakpoints={[]}
				className='confirm-popup-middle height-auto'
				modalContent={
					<div className={`wrapper py-4 confirm-popup-middle height-auto`}>
						<IonItem lines='none'>
							<div className='d-flex flex-column'>
								<p
									className='fs-14 mb-2'
									style={{ color: 'var(--text-dark-grey)' }}
								>
									Please enable notification permission from settings.
								</p>
							</div>
						</IonItem>
						<div className='d-flex justify-content-center gap-3 px-3'>
							<CustomButton
								className='w-50'
								fill='solid'
								onClick={() => {
									NativeSettings.open({
										optionAndroid: AndroidSettings.AppNotification,
										optionIOS: IOSSettings.Notifications,
									});
									setShowNitificationModel(false);
								}}
								title={t('Ok')}
							/>
						</div>
					</div>
				}
				needIonContentWraper={false}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
