import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonItem, IonImg, IonList, IonLabel, isPlatform, IonIcon } from '@ionic/react';
import { Player } from '@lottiefiles/react-lottie-player';

import './BankTab.scss';
import { CircularProgressBar } from '../../../../components/common/custom-circular-progress-bar/CircularProgressBar';
import {
	dataURLtoFile,
	getFileExtByUrl,
	getFileWithUpdatedToken,
	getFilenameByUrl,
	showInAppBrowserView,
} from '../../../../helpers/functions';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { StatusNum } from '../../../../helpers/constant';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import { BankEdit } from './Component/BankEdit';
import { useDispatch, useSelector } from 'react-redux';
import {
	apiKycDocumentUpload,
	kycUserUploadImage,
	imgUrlUpdate,
} from '../../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { syncOutline, addOutline, removeOutline } from 'ionicons/icons';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

interface Props {
	userDetails: any;
	userId: any;
	listStatus: any;
	showPennyDropLoader: boolean;
	showPennyDropFailed: boolean;
	verifyPennyDrop: any;
	setShowRemarkModal: any;
}

export const BankTab: React.FC<Props> = ({
	userDetails,
	userId,
	listStatus,
	showPennyDropLoader,
	showPennyDropFailed,
	verifyPennyDrop,
	setShowRemarkModal,
}) => {
	const { t } = useTranslation();
	const [fileName, setFileName] = useState<any>('');
	const isBrowser = !isPlatform('capacitor');
	const [openUploadModal, setOpenUploadModal] = useState(false);
	const [showBankInfoForm, setShowBankInfoForm] = useState<any>(false);
	const [userData, setUserData] = useState<any>(userDetails);
	const isWeb = useSelector((state: any) => state?.app?.isWeb);

	const dispatch = useDispatch();
	useEffect(() => {
		setUserData(userDetails);
		setFileName(getFilenameByUrl(userDetails?.Url));
	}, [userDetails]);

	const [rotate, setRotate] = useState(0);
	const [zoom, setZoom] = useState(1);
	const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  
	const handleRotate = () => {
	  setRotate((prev) => prev + 90);
	};
  
	const handleZoomIn = () => {
	  setZoom((prev) => Math.min(prev + 0.1, 2)); 
	};
  
	const handleZoomOut = () => {
	  setZoom((prev) => Math.max(prev - 0.1, 0.5)); 
	};

	const startDragging = (e) => {
		setIsDragging(true);
		setDragStart({ x: e.clientX - position.x, y: e.clientY - position.y });
	  };
	
	  const stopDragging = () => {
		setIsDragging(false);
	  };
	
	  const handleDragging = (e) => {
		if (isDragging) {
		  setPosition({
			x: e.clientX - dragStart.x,
			y: e.clientY - dragStart.y,
		  });
		}
	  };

	  const handleTouchStart = (e) => {
		setIsDragging(true);
		setDragStart({ x: e.touches[0].clientX - position.x, y: e.touches[0].clientY - position.y });
	  };
	
	  const handleTouchMove = (e) => {
		if (!isDragging) return;
		setPosition({
		  x: e.touches[0].clientX - dragStart.x,
		  y: e.touches[0].clientY - dragStart.y
		});
	  };
	
	  const handleTouchEnd = () => setIsDragging(false);
  
	  const getAdjustedStyles = () => {
		return {
		  transform: `translate(${position.x}px, ${position.y}px) rotate(${rotate}deg) scale(${zoom})`,
		  transition: 'transform 0.1s ease',
		  cursor: isDragging ? 'grabbing' : 'grab',
		  objectFit: 'contain',
		  width: '100%',
		  height: '100%',
		};
	  };

	const onImgError = () => {
		getFileWithUpdatedToken({
			dispatch,
			Url: userData?.Url,
			imgUrlUpdate,
			unwrapResult,
			setUserFile: null,
			setName: setFileName,
			userData: userData,
			setUserData,
			setUserData2: null,
		});
	};

	const ShareModal = () => {
		const handleUploadChange = async (
			type: string,
			fieldName: string,
			e: any
		) => {
			setOpenUploadModal(false);
			// case1
			const formData = new FormData();
			// let result: any;

			// case 2
			let image: any;
			switch (type) {
				case 'file':
					formData.append('profile', e.target.files[0]);
					await dispatch(kycUserUploadImage({ data: formData }))
						.then(unwrapResult)
						.then(async (response: any) => {
							if (response?.status) {
								await dispatch(
									apiKycDocumentUpload({
										param: '/' + userId,
										data: {
											Type: 'Bank',
											Url: response?.url,
											RW: true,
										},
									})
								)
									.then(unwrapResult)
									.then((responseData: any) => {
										if (responseData.status) {
											const updatedData = {
												...userData,
												Url: response?.url,
											};
											setUserData(updatedData);
											setFileName(getFilenameByUrl(response?.url));
											setOpenUploadModal(false);
										}
									});
							}
						});

					break;
				case 'photo':
					image = await Camera.getPhoto({
						quality: 75,
						allowEditing: false,
						resultType: CameraResultType.DataUrl,
						source: CameraSource.Photos,
					});
					formData.append(
						'profile',
						dataURLtoFile(
							image.dataUrl,
							`${Math.random() * 100000000000000000}.${image.format}`
						)
					);

					await dispatch(kycUserUploadImage({ data: formData }))
						.then(unwrapResult)
						.then(async (response: any) => {
							if (response?.status) {
								await dispatch(
									apiKycDocumentUpload({
										param: '/' + userId,
										data: {
											Type: 'Bank',
											Url: response?.url,
											RW: true,
										},
									})
								)
									.then(unwrapResult)
									.then((responseData: any) => {
										if (responseData.status) {
											const updatedData = {
												...userData,
												Url: response?.url,
											};
											setUserData(updatedData);
											setFileName(getFilenameByUrl(response?.url));
											setOpenUploadModal(false);
										}
									});
							}
						});

					break;
				case 'camera':
					image = await Camera.getPhoto({
						quality: 75,
						allowEditing: false,
						resultType: CameraResultType.DataUrl,
						source: CameraSource.Camera,
					});
					formData.append(
						'profile',
						dataURLtoFile(
							image.dataUrl,
							`${Math.random() * 100000000000000000}.${image.format}`
						)
					);
					await dispatch(kycUserUploadImage({ data: formData }))
						.then(unwrapResult)
						.then(async (response: any) => {
							if (response?.status) {
								await dispatch(
									apiKycDocumentUpload({
										param: '/' + userId,
										data: {
											Type: 'Bank',
											Url: response?.url,
											RW: true,
										},
									})
								)
									.then(unwrapResult)
									.then((responseData: any) => {
										if (responseData.status) {
											const updatedData = {
												...userData,
												Url: response?.url,
											};
											setUserData(updatedData);
											setFileName(getFilenameByUrl(response?.url));
											setOpenUploadModal(false);
										}
									});
							}
						});
					break;
				default:
					break;
			}
		};
		return (
			<>
				<IonList>
					{!isBrowser && (
						<IonItem lines='full'>
							<img
								src='/images/icons/kyc/Image-upload.svg'
								alt='Upload Photo'
							/>
							<IonLabel>
								<h2
									className='fs-16 ps-3 text-dark'
									onClick={(e) => {
										handleUploadChange('photo', '', e);
									}}
								>
									Upload Photo
								</h2>
							</IonLabel>
						</IonItem>
					)}
					<IonItem lines='full'>
						<img
							src='/images/icons/kyc/upload-image-icon.svg'
							alt='Upload Photo'
						/>
						<IonLabel>
							<label htmlFor='upload-photo'>
								<h2 className='fs-16 ps-3 text-dark'>{t('Upload Document')}</h2>
							</label>
							<input
								type='file'
								id='upload-photo'
								accept='image/png, image/jpeg ,application/pdf'
								onChange={(e) => {
									handleUploadChange('file', '', e);
								}}
							/>
						</IonLabel>
					</IonItem>
					{!isBrowser && (
						<IonItem lines='full'>
							<img
								src='/images/icons/kyc/camera-icon.svg'
								alt='Upload Photo'
							/>
							<IonLabel>
								<h2
									className='fs-16 ps-3 text-dark'
									onClick={(e) => {
										handleUploadChange('camera', '', e);
									}}
								>
									Take Photo
								</h2>
							</IonLabel>
						</IonItem>
					)}
				</IonList>
			</>
		);
	};

	return (
		<>
			{/* <div className='bg-web conainer-margin-web'>
				<div className='bg-web-white border-md-radius '> */}
			{showPennyDropLoader === false && showPennyDropFailed === false && (
				<div>
					{(userData?.is_sucessful === 1 ||
						userData?.is_sucessful === true) && (
						<div className='ion-margin'>
							<div
								className='d-flex align-items-center justify-content-between px-4 py-2'
								style={{
									background:
										'linear-gradient(101deg, #66CC66 0%, #0989C9 100%)',
									borderRadius: '8px',
								}}
							>
								<h3 className='fs-16 fw-600 mb-0 text-white'>
									{t('Match percentage')}
								</h3>
								<div>
									<CircularProgressBar
										strokeWidth='6'
										sqSize='65'
										value={userData?.is_match + '%'}
										percentage={userData?.is_match}
										ringBgColor='rgba(255, 255, 255, .5)'
										ringColor='#fff'
										textColor='#fff'
									/>
								</div>
							</div>
						</div>
					)}

					<div className='image-outline ion-margin' style={{ position: 'relative', width: '450px', height: '300px', overflow: 'hidden', border: '1px solid #ccc' }}
						// onMouseDown={startDragging}
						// onMouseMove={handleDragging}
						// onMouseUp={stopDragging}
						// onMouseLeave={stopDragging}
						// onTouchStart={handleTouchStart}
						// onTouchMove={handleTouchMove}
						// onTouchEnd={handleTouchEnd}
					>
					<IonIcon icon={syncOutline} size="large" onClick={handleRotate} style={{ position: 'absolute', top: '10px', left: '10px', zIndex: 1 }} />
             		
					<TransformWrapper
						initialScale={1}
						minScale={0.3}
						maxScale={7}
						centerOnInit={true}
						limitToBounds={true}
						centerZoomedOut={true}
					>
						{({ zoomIn, zoomOut, resetTransform }) => (
							<>
							<IonIcon icon={addOutline} size="large" onClick={() => zoomIn()} style={{ position: 'absolute', top: '10px', right: '50px', zIndex: 1 }} />
							<IonIcon icon={removeOutline} size="large" onClick={() => zoomOut()} style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 1 }} />
							<TransformComponent>
								<IonImg
							className='preview-img'
							style={{

								transform: `rotate(${rotate}deg)`,
								display: 'block',
								margin: '0 80px',
								maxWidth: '300px',
								maxHeight: '250px',
							}}
							src={
								getFileExtByUrl(userData?.Url)?.toLowerCase() === 'pdf'
									? 'images/icons/pdf-placeholder.svg'
									: userData?.Url
							}
							alt='bank img'
							onIonError={onImgError}
							onClick={() => showInAppBrowserView(userData?.Url)}
						></IonImg>
							</TransformComponent>
							</>
						)}
					</TransformWrapper>

						{userData?.approve === 'rework_required' &&
							listStatus === StatusNum.REWORK && (
								<div className='preview-img-edit'>
									<div
										className='d-inline-block fw-600 fs-14 primary-text-color'
										onClick={() => {
											setOpenUploadModal(true);
										}}
									>
										<img
											src='images/icons/user-details-edit-icon.svg'
											className='me-2'
										/>
										{t('Edit')}
									</div>
								</div>
							)}

						<div className='preview-img-fileName'>
							<img
								className='me-2'
								src='images/icons/preview-img-filename-icon.svg'
							/>
							<span className='text-truncate fw-600'>{fileName}</span>
						</div>
					</div>
					<div className='d-flex justify-content-end ion-padding-horizontal'>
						{userData?.approve === 'rework_required' &&
							listStatus === StatusNum.REWORK && (
								<div
									className='d-flex fw-600 fs-14 primary-text-color'
									onClick={() => setShowBankInfoForm(true)}
								>
									<img
										src='images/icons/user-details-edit-icon.svg'
										className='me-2'
									/>
									{t('Edit')}
								</div>
							)}
					</div>
					{(userData?.is_sucessful === 1 ||
						userData?.is_sucessful === true) && (
						<div className='mt-4'>
							<IonItem
								className='ion-no-padding primary-bg-color remove-ion-item-inner-padding-end'
								lines='none'
							>
								<div className='d-block ion-padding-horizontal'>
									<p className='mt-3 mb-2 fs-14'>
										{t('Name as per Penny drop response')}
									</p>
									<p className='mt-2 mb-3 fs-14 fw-600 primary-text-color'>
										{userData?.penny_name ? userData?.penny_name : '-'}
									</p>
								</div>
							</IonItem>
							<IonItem
								className={`ion-no-padding ${
									userData?.is_match === 100
										? 'success-bg-color'
										: 'warning-bg-color'
								} remove-ion-item-inner-padding-end`}
								lines='none'
							>
								<div className='d-block ion-padding-horizontal'>
									<p className='mt-3 mb-2 fs-14'>
										{t('Name as per Application')}
									</p>
									<p
										className={`mt-2 mb-3 fs-14 fw-600 ${
											userData?.is_match === 100
												? 'secondary-text-color'
												: 'warning-text-color'
										}`}
									>
										{userData?.name?.full_name
											? userData?.name?.full_name
											: '-'}
									</p>
								</div>
							</IonItem>
						</div>
					)}
					{(userData?.is_sucessful === 1 ||
						userData?.is_sucessful === true) && (
						<IonItem
							className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
							lines='none'
						>
							<div className='d-block'>
								<p className='mt-3 mb-2 fs-18 fw-600'>
									{t('Other details as per application')}
								</p>
							</div>
						</IonItem>
					)}
					{(userData?.is_sucessful === 0 ||
						userData?.is_sucessful === false) && (
						<IonItem
							className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
							lines='full'
						>
							<div className='d-block'>
								<p className='mt-3 mb-2 fs-14'>
									{t('Name as per Application')}
								</p>
								<p className='mt-2 mb-3 fs-14 fw-600'>
									{userData?.name?.full_name ? userData?.name?.full_name : '-'}
								</p>
							</div>
						</IonItem>
					)}
					<IonItem
						className='ion-no-padding ion-margin-horizontal item-no-padding-end remove-ion-item-inner-padding-end'
						lines='full'
					>
						<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
							<p className='mb-0 fs-14'>{t('Account No') + '.'}</p>
							<p className='mb-0 fs-14 fw-600'>
								{userData?.AccountNo ? userData?.AccountNo : '-'}
							</p>
						</div>
					</IonItem>
					<IonItem
						className='ion-no-padding ion-margin-horizontal item-no-padding-end remove-ion-item-inner-padding-end'
						lines='full'
					>
						<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
							<p className='mb-0 fs-14'>{t('IFSC Code')}</p>
							<p className='mb-0 fs-14 fw-600'>
								{userData?.IFSC ? userData?.IFSC.toUpperCase() : '-'}
							</p>
						</div>
					</IonItem>
					<IonItem
						className='ion-no-padding ion-margin-horizontal item-no-padding-end remove-ion-item-inner-padding-end'
						lines='full'
					>
						<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
							<p className='mb-0 fs-14'>{t('Bank Name')}</p>
							<p className='mb-0 fs-14 fw-600'>
								{userData?.BankName ? userData?.BankName : '-'}
							</p>
						</div>
					</IonItem>
					<IonItem
						className='ion-no-padding ion-margin-horizontal item-no-padding-end remove-ion-item-inner-padding-end'
						lines='full'
					>
						<div className='d-block py-2'>
							<p className='mb-0 fs-14'>{t('Branch Name')}</p>
							<p className='mb-0 fs-14 fw-600'>
								{userData?.BranchName ? userData?.BranchName : '-'}
							</p>
						</div>
					</IonItem>
				</div>
			)}

			{showPennyDropLoader && (
				// <div
				// 	className='d-flex align-items-center justify-content-center bg-white w-100 h-100'
				// 	style={{ position: 'absolute', zIndex: 10, top: 0 }}
				// >
				<div className='text-center'>
					<Player
						// className="pe-3"
						autoplay={true}
						loop={true}
						controls={false}
						src='/images/icons/json/PennyDrop.json'
						style={{ height: '102px', width: '102px' }}
					/>
					<h3 className='fs-18 fw-900'>{t('Initiating Penny Drop')}</h3>
				</div>
				// </div>
			)}
			{showPennyDropFailed && (
				// <div
				// 	className='d-flex align-items-center justify-content-center bg-white  h-100'
				// 	style={{ position: 'absolute', zIndex: 10, top: 0 }}
				// >
				<div
					className='text-center'
					// style={{ width: '250px' }}
				>
					<img
						src='/images/icons/alert-symbol-icon.svg'
						alt='alert icon'
					/>
					<h3 className='fs-14 my-3 heading-color'>
						{t('Penny Drop could not be completed. Please try after sometime') +
							'.'}
					</h3>
					<div
						className='fw-14 fw-700 d-inline-block primary-text-color'
						onClick={() => verifyPennyDrop()}
					>
						<img
							className='me-2'
							src='/images/icons/retry-icon.svg'
							alt='retry icon'
						/>
						{t('Retry')}
					</div>
					<br />
					<div
						className='fw-14 fw-700 d-inline-block primary-text-color mt-2'
						onClick={() => {
							setShowRemarkModal(true);
						}}
					>
						<img
							className='me-2'
							src='/images/icons/retry-icon.svg'
							alt='retry icon'
						/>
						{t('Rework Required')}
					</div>
				</div>
				// </div>
			)}

			{/* Upload Photo Modal */}
			<CustomModal
				id='sharemodal'
				isOpen={openUploadModal}
				initialBreakpoint={isWeb ? 1 : 0.3}
				handleClose={() => setOpenUploadModal(false)}
				className={`side-modal ${isWeb ? '' : 'modal-border-radius'}`}
				breakpoints={[0, 0.3, 0.5, 0.75]}
				modalContent={<ShareModal />}
				needCross={false}
				needArrow={false}
			/>

			<CustomModal
				id='customModalRework'
				isOpen={showBankInfoForm}
				modalTitle={t('Bank Info')}
				initialBreakpoint={1}
				handleClose={() => setShowBankInfoForm(false)}
				breakpoints={[0, 1]}
				className='side-modal'
				modalContent={
					<BankEdit
						userId={userId}
						data={userData}
						setUserData={setUserData}
						setShowBankInfoForm={setShowBankInfoForm}
					/>
				}
				needCross={true}
				needArrow={false}
			></CustomModal>
			{/* </div>
			</div> */}
		</>
	);
};
