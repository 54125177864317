import { IonPage, IonContent, IonImg, IonSpinner, IonItem } from '@ionic/react';

import { Header } from '../../components/layout/header/Header';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import './IdCard.scss';
import { imgUrlUpdate, myIdCard } from '../../store/reducers/user/userSlice';
import { USER_ROLES } from '../../helpers/constant';
import { getDownloadAgreeMent } from '../../helpers/functions';
import { FileSharer } from '@byteowls/capacitor-filesharer';
import { toPng } from 'html-to-image';

import QRCode from 'react-qr-code';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { isPlatform } from '@ionic/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import { FileOpener } from '@capacitor-community/file-opener';
import { getLeadFlowUrl } from '../../store/reducers/lead/leadSlice';
import { PushNotifications } from '@capacitor/push-notifications';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
import {
	AndroidSettings,
	IOSSettings,
	NativeSettings,
} from 'capacitor-native-settings';
const defaultSrc = '/images/icons/default/user-profile-icon.svg';

export const IdCard: React.FC = () => {
	const [segment, setSegment] = useState('id-card');
	const [profileData, setProfileData] = useState<any>([]);
	const [src, setSrc] = useState('');
	const history = useHistory();
	const [url, setUrl] = useState('');
	const dispatch = useDispatch();
	const roleType = useSelector(
		(state: any) => state?.users?.user_details?.roleType?.[0]
	);
	const [showNotificationModel, setShowNitificationModel] = useState(false);
	const [spinner, setSpinner] = useState(false);

	const { t } = useTranslation();

	segment.length > 20 && setSegment('data');
	const handleValueChange = (event: any) => {
		setSegment(event.target.value);
	};

	useEffect(() => {
		getPrfileData();
		// getDownloadAgreeMent();
	}, []);

	useEffect(() => {
		getUpdateUrl();
	}, [profileData?.profile_url]);
	const registerNotifications = async () => {
		const permStatus = await PushNotifications.checkPermissions();

		if (permStatus.receive == 'denied') {
			setShowNitificationModel(true);
			setSpinner(false);
		} else if (permStatus.receive == 'prompt-with-rationale') {
			setShowNitificationModel(true);
			setSpinner(false);
		} else {
			setShowNitificationModel(false);
		}
	};

	const getUpdateUrl = async () => {
		await dispatch(getLeadFlowUrl())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setUrl(result?.url);
				}
			});
		if (profileData?.profile_url) {
			await dispatch(
				imgUrlUpdate({
					data: {
						url: profileData?.profile_url,
						expire_time: 50000,
					},
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						setSrc(response?.url);
					}
				});
		} else {
			setSrc(defaultSrc);
		}
	};

	const getPrfileData = async () => {
		await dispatch(myIdCard())
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setProfileData(response?.user);
				}
			});
	};

	const name =
		(profileData?.first_name ? `${profileData?.first_name} ` : '') +
		(profileData?.middle_name ? `${profileData?.middle_name} ` : '') +
		(profileData?.last_name ? `${profileData?.last_name} ` : '');

	const ref = useRef<HTMLDivElement>(null);

	const onButtonClick = async (name: any) => {
		if (ref.current === null) {
			return;
		}
		if (
			isPlatform('android') ||
			isPlatform('ios') ||
			isPlatform('ipad') ||
			isPlatform('iphone')
		) {
			registerNotifications();
			setSpinner(true);
		}
		await toPng(ref.current, {
			fetchRequestInit: {
				cache: 'no-cache',
			},
		});
		await toPng(ref.current, {
			fetchRequestInit: {
				cache: 'no-cache',
			},
		});
		await toPng(ref.current, {
			fetchRequestInit: {
				cache: 'no-cache',
			},
		});
		const dataUrl = await toPng(ref.current, {
			fetchRequestInit: {
				cache: 'no-cache',
			},
		});
		if (isPlatform('mobileweb')) {
			const link = document.createElement('a');
			link.download = `oneandro-${name}.png`;
			link.href = dataUrl;
			link.click();
			setSpinner(false);
		} else if (isPlatform('android') || isPlatform('ios')) {
			const a = await Filesystem.writeFile({
				path: `oneandro-${name}.png`,
				data: dataUrl,
				directory: Directory.Data,
			});

			await LocalNotifications.schedule({
				notifications: [
					{
						title: 'File Downloaded',
						body: `Click To Open`,
						id: 1,
						schedule: { at: new Date(Date.now() + 1000 * 1) },
						actionTypeId: '',
						extra: {
							route: a?.uri,
						},
					},
				],
			});
		} else {
			const link = document.createElement('a');
			link.download = `oneandro-${name}.png`;
			link.href = dataUrl;
			link.click();
		}
	};
	useEffect(() => {
		LocalNotifications.addListener('localNotificationReceived', (data: any) => {
			if (data) {
				setSpinner(false);
			}
		});
		LocalNotifications.addListener(
			'localNotificationActionPerformed',
			(payload: any) => {
				const route = payload.notification.extra.route;
				FileOpener.open({
					filePath: route,
				});
			}
		);
		return () => {
			LocalNotifications.removeAllListeners();
		};
	}, []);
	const shareQr = async () => {
		if (ref.current === null) {
			return;
		}
		await toPng(ref.current, {
			fetchRequestInit: {
				cache: 'no-cache',
			},
		}).then(async (dataUrl: any) => {
			if (isPlatform('android') || isPlatform('ios')) {
				if (dataUrl) {
					FileSharer.share({
						filename: `oneandro-${segment}.png`,
						base64Data: dataUrl.split(',')[1],
						contentType: 'image/png',
					});
				}
			}
		});
	};

	return (
		<IonPage className=''>
			<Header
				name={t('OneAndro Code')}
				backButton={true}
				needArrowOnClick={() => history.push('/dashboard/home')}
				needArrow={true}
			/>
			<IonContent className=''>
				<div className='bg-web h-100 pt-md-3'>
					{/* <div className='tabs border-bottom bg-web-white d-flex id-card1'>
						<div
							className='text-center'
							style={{ width: '50%' }}
						>
							<button
								className={`id-tab ${segment === 'id-card' && 'active-tab'}`}
								onClick={(e) => {
									handleValueChange(e);
								}}
								value={'id-card'}
							>
								{t('ID Card')}
							</button>
						</div>
						<div
							className=' text-center'
							style={{ width: '50%' }}
						>
							<button
								className={`id-tab ${segment === 'qr-code' && 'active-tab'}`}
								onClick={(e) => {
									handleValueChange(e);
								}}
								value={'qr-code'}
							>
								{t('QR Code')}
							</button>
						</div>
					</div> */}
					<div className='container-width-md bg-web-white border-md-radius-10'>
						<div className='tabs border-bottom d-flex pt-3'>
							<div
								className='text-center'
								style={{ width: '50%' }}
							>
								<button
									className={`id-tab ${segment === 'id-card' && 'active-tab'}`}
									onClick={(e) => {
										handleValueChange(e);
									}}
									value={'id-card'}
								>
									{t('ID Card')}
								</button>
							</div>
							<div
								className=' text-center'
								style={{ width: '50%' }}
							>
								<button
									className={`id-tab ${segment === 'qr-code' && 'active-tab'}`}
									onClick={(e) => {
										handleValueChange(e);
									}}
									value={'qr-code'}
								>
									{t('QR Code')}
								</button>
							</div>
						</div>

						{segment === 'id-card' ? (
							<div
								className='bg-web-white'
								style={{
									height: '100%',
									borderRadius: '10px',
									overflowY: 'auto',
								}}
							>
								<div className='pb-4 my-3 d-flex justify-content-center '>
									<div
										ref={ref}
										className='id-card d-flex flex-column '
									>
										<IonImg
											src='/images/icons/OneAndro_svg R logo.png'
											className='id-logo'
										/>
										<div
											style={{
												backgroundImage: 'url(' + src + ')',
											}}
											className='id-pic'
										/>
										<div className='id-card-top'></div>
										<div
											className='id-card-middle id-card-bg-color id-card-heading-box'
											style={{
												paddingTop: '65px',
											}}
										>
											<div className='id-card-box p-2 /* d-flex flex-column align-items-center justify-content-evenly*/'>
												<span className='id-card-text-one m-0 ion-text-capitalize text-center text-white fs-18 fw-600'>
													{name}
												</span>
												<span className='id-card-text-two text-white text-center m-0 mt-2 fs-14 ion-text-capitalize'>
													{profileData?.name || ''}
												</span>
											</div>
											<div className='/*d-flex flex-column align-items-center justify-content-evenly*/ id-card-highlight'>
												<h3 className='text-white text-center m-0 mb-1 fs-14'>
													{profileData?.mobile || ''}
												</h3>
												<h4 className='text-white text-center m-0 fs-14'>
													{profileData?.email || ''}
												</h4>
											</div>
										</div>
										<div className='id-card-bottom d-flex justify-content-between align-items-center'>
											<div className='id-card-text fw-600'>
												{t(
													[USER_ROLES.DMA].includes(roleType)
														? 'OneAndro Code'
														: 'Emp Code'
												)}
											</div>
											<div className='id-card-text-heading'>
												{profileData?.soft_code || ''}
											</div>
										</div>
									</div>
								</div>
								<div
									className='d-flex flex-column'
									style={{ width: 335, margin: '0 auto' }}
								>
									{[USER_ROLES.DMA].includes(roleType) &&
									profileData?.Is_agreement_download ? (
										<div>
											<CustomButton
												title={t('Download Agreement')}
												className={'btn-primary-outline'}
												onClick={() => getDownloadAgreeMent(dispatch)}
												fill='outline'
												expand='block'
												style={{
													font: 'normal normal 600 16px/20px',
													letterSpacing: 0,
												}}
											/>
										</div>
									) : (
										''
									)}
									<div
										className={`d-flex ${
											isPlatform('android') || isPlatform('ios')
												? 'justify-content-between'
												: 'justify-content-center flex-grow-1'
										} `}
										style={{ marginBottom: 20 }}
									>
										<div
											style={{
												width: `${
													isPlatform('android') || isPlatform('ios')
														? ''
														: '100%'
												}`,
											}}
										>
											<CustomButton
												title={t('Download ID')}
												className={'btn-primary-outline'}
												fill='outline'
												expand='block'
												style={{
													font: 'normal normal 600 16px/20px',
													letterSpacing: 0,
													width: `${
														isPlatform('android') || isPlatform('ios')
															? '160px'
															: ''
													}`,
												}}
												onClick={() => onButtonClick(segment)}
											/>
										</div>
										{isPlatform('android') || isPlatform('ios') ? (
											<div>
												<CustomButton
													title={t('Share ID')}
													className={'btn-primary-outline'}
													fill='outline'
													expand='block'
													style={{
														font: 'normal normal 600 16px/20px',
														letterSpacing: 0,
														width: '160px',
													}}
													onClick={shareQr}
												/>
											</div>
										) : (
											''
										)}
									</div>
								</div>
							</div>
						) : (
							segment === 'qr-code' && (
								<div className='d-flex justify-content-center'>
									<div
										className='bg-web-white'
										style={{ height: '100%', overflowY: 'auto' }}
									>
										<div className='d-flex  justify-content-center my-3 id-card-md'>
											<div
												className='id-card-middle id-card-bg-color w-100 pt-3'
												style={{
													paddingTop: '30px',
													borderRadius: '10px',
												}}
												ref={ref}
											>
												<div
													style={{
														backgroundImage: `url(${src})`,
													}}
													className='id-pic position-relative top-0 mb-2'
												/>
												<div className='id-card-box p-2 /* d-flex flex-column align-items-center justify-content-evenly*/'>
													<span className='id-card-text-one m-0 ion-text-capitalize text-center text-white fs-18 fw-600'>
														{name}
													</span>

													<h5 className='fs-12 text-white mt-2'>
														{t('OneAndro Code')}
													</h5>
													<p className='fs-14 fw-bold text-white'>
														{profileData?.soft_code || ''}
													</p>
												</div>
												<div
													style={{
														borderRadius: '10px',
														width: 'max-content',
														height: '186px',
													}}
													className='mx-auto bg-white d-flex justify-content-center align-items-center px-2 m-4 mt-2'
												>
													<QRCode
														style={{ width: '165px' }}
														value={url}
													/>
												</div>
											</div>
										</div>
										<div
											className='d-flex  my-4 flex-column'
											style={{ width: 335, margin: '0 auto' }}
										>
											<div
												className={`d-flex ${
													isPlatform('android') || isPlatform('ios')
														? 'justify-content-between'
														: 'justify-content-center flex-grow-1'
												} `}
											>
												{isPlatform('android') || isPlatform('ios') ? (
													<div>
														<CustomButton
															title={t('Share QR Code')}
															className={'btn-primary-outline'}
															fill='outline'
															expand='block'
															style={{
																font: 'normal normal 600 16px/20px',
																letterSpacing: 0,
																width: '160px',
															}}
															onClick={shareQr}
														/>
													</div>
												) : (
													''
												)}
												<div
													style={{
														width: `${
															isPlatform('android') || isPlatform('ios')
																? ''
																: '100%'
														}`,
													}}
												>
													<CustomButton
														title={t('Download QR Code')}
														className={'btn-primary-outline'}
														fill='outline'
														expand='block'
														style={{
															font: 'normal normal 600 16px/20px',
															letterSpacing: 0,
															width: `${
																isPlatform('android') || isPlatform('ios')
																	? '160px'
																	: '100%'
															}`,
														}}
														onClick={() => onButtonClick(segment)}
													/>
												</div>
											</div>
										</div>
									</div>
									{/* <div
										className='d-none d-md-flex flex-column'
										style={{ width: 335, margin: '0 auto' }}
									>
										<div
											className='d-flex justify-content-between'
											style={{ marginBottom: 20 }}
										>
											<div>
												<CustomButton
													title={t('Share  QR Code')}
													className={'btn-primary-outline'}
													fill='outline'
													expand='block'
													style={{
														font: 'normal normal 600 16px/20px',
														letterSpacing: 0,
														width: '160px',
													}}
													onClick={shareQr}
												/>
											</div>

											<div>
												<CustomButton
													title={t('Download QR Code')}
													className={'btn-primary-outline'}
													fill='outline'
													expand='block'
													onClick={onButtonClick}
													style={{
														font: 'normal normal 600 16px/20px',
														letterSpacing: 0,
														width: '160px',
													}}
												/>
											</div>
										</div>
									</div> */}
								</div>
							)
						)}
					</div>
				</div>
			</IonContent>
			{spinner && (
				<div
					className='position-fixed  w-100  d-flex justify-content-center align-items-center h-100'
					style={{
						top: '0px',
						left: '50%',
						transform: 'translate(-50%,0%)',
						backgroundColor: 'rgba(0,0,0,0.2)',
					}}
				>
					<IonSpinner name='lines-sharp' />
				</div>
			)}
			<CustomModal
				id=''
				isOpen={showNotificationModel}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setShowNitificationModel(false)}
				breakpoints={[]}
				className='confirm-popup-middle height-auto'
				modalContent={
					<div className={`wrapper py-4 confirm-popup-middle height-auto`}>
						<IonItem lines='none'>
							<div className='d-flex flex-column'>
								<p
									className='fs-14 mb-2'
									style={{ color: 'var(--text-dark-grey)' }}
								>
									Please enable notification permission from settings.
								</p>
							</div>
						</IonItem>
						<div className='d-flex justify-content-center gap-3 px-3'>
							<CustomButton
								className='w-50'
								fill='solid'
								onClick={() => {
									NativeSettings.open({
										optionAndroid: AndroidSettings.AppNotification,
										optionIOS: IOSSettings.Notifications,
									});
									setShowNitificationModel(false);
								}}
								title={t('Ok')}
							/>
						</div>
					</div>
				}
				needIonContentWraper={false}
				needCross={false}
				needArrow={false}
			/>
		</IonPage>
	);
};
