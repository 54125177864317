/* eslint-disable no-constant-condition */
import { IonLabel, IonCheckbox } from '@ionic/react';
import './CustomDropDown.scss';
import { useEffect, useState } from 'react';
import { CustomModal } from '../custom-modal/CustomModal';
import { alphabetColor } from '../../../helpers/functions';
import { CustomSearchbar } from '../custom-searchbar/CustomSearchbar';
import { CustomNoData } from '../custom-no-data/CustomNoData';
import { Divider } from '../divider/Divider';
import { useTranslation } from 'react-i18next';

const DropDownModal = ({
	setOpen,
	serachData,
	data,
	setSearchData,
	setValue,
	// value,
	open,
	isCheckbox,
	setData,
	placeholder,
	isSearch,
	listTitle,
	setSearchValue,
	searchValue: value,
	needUnselect,
}: any) => {
	const { t } = useTranslation();
	return (
		<div
			className={`CustomDropDown_list ${open ? 'CustomDropDown_active' : ''}`}
		>
			<div className='CustomDropDown_container'>
				<CustomSearchbar
					value={value}
					searchIcon='/images/icons/searchbar-icon.svg'
					placeholder={placeholder}
					showClearButton='focus'
					onChange={(value: any) => {
						setValue(value);
						setSearchValue(value);
						if (value !== '') {
							if (value.length >= 3) {
								const results: any = data.filter((item: any) =>
									item?.value?.toLowerCase().includes(value?.toLowerCase())
								);
								setSearchData(results);
								// setData(results);
							}
						} else {
							setSearchData(data);
							// setData(data);
						}
					}}
				/>
				{/* <div className='position-relative'> */}
				{/* <CustomInput
					type={'text'}
					// label={'Search'}
					placeholder={placeholder}
					className={'mt-2 mb-2 serach-input'}
					onChange={(e: any) => {
						setValue(e.detail.value);
						if (e.detail.value !== '') {
							const results: any = data.filter((item: any) =>
								item?.value
									?.toLowerCase()
									.includes(e?.detail?.value?.toLowerCase())
							);
							setSearchData(results);
							// setData(results);
						} else {
							setSearchData(data);
							// setData(data);
						}
					}}
					value={value}
					needSuffixIcon={true}
				/> */}
				{/* <IonIcon
					icon={search}
					className='position-absolute'
					style={{
						top: '14px',
						left: '16px',
						color: '#595959',
					}}
				/> */}
				{/* </div> */}
				{isSearch && (
					<p className='fw-900 fs-18 pt-2 mb-0'>
						{listTitle ? listTitle : t('All Managers')}
					</p>
				)}
				<div className='pt-3'>
					{serachData && serachData.length > 0 ? (
						isCheckbox ? (
							serachData.map(({ id, value, checked }: any, index: number) => (
								// <IonItem key={id}>
								<>
									<IonCheckbox
										key={id}
										id={id}
										value={value}
										color='primary d-flex'
										labelPlacement='end'
										justify='start'
										checked={checked}
										onIonChange={(e: any) => {
											const selectedData = data.map((item: any) => {
												if (parseInt(e.target.id) === item.id) {
													return {
														...item,
														checked: e.detail.checked,
													};
												} else {
													return { ...item };
												}
											});
											setSearchValue('');
											setData(selectedData);
											{
												!isCheckbox ? setOpen(false) : '';
											}
										}}
									>
										{value}
									</IonCheckbox>
									{serachData.length - 1 === index ? (
										''
									) : (
										<Divider className='my-2 padding-top-border' />
									)}
								</>
								// </IonItem>
							))
						) : (
							serachData.map(({ id, value }: any, index: number) => {
								const randomColorss =
									Math.floor(Math.random() * (5 * index + 1)) + 1;
								return (
									// <IonItem key={id}>
									<IonLabel
										key={id}
										title={value}
										onClick={() => {
											const selectedData = data.map((item: any) => {
												if (value === item.value) {
													return {
														...item,
														checked: needUnselect
															? true
															: item.checked === undefined
															? true
															: !item.checked,
													};
												} else {
													return {
														...item,
														checked: false,
													};
												}
											});

											setData(selectedData);
											setOpen(false);
										}}
									>
										{isSearch ? (
											<div className='cursor-pointer d-flex align-items-center'>
												<div className='d-flex justify-content-center align-items-center position-relative me-3'>
													<div
														style={{
															// color: alphabetColor(
															// 	Math.floor(Math.random() * (5 * index + 1)) + 1
															// ),
															background: alphabetColor(randomColorss),
															opacity: 0.1,
															borderRadius: '50%',
															height: '32px',
															width: '32px',
														}}
													></div>
													<span
														style={{
															color: alphabetColor(randomColorss),
														}}
														className='fw-900 position-absolute'
													>
														{value?.charAt(0).toUpperCase()}
													</span>
												</div>
												<div className='darkest-grey fs-16'>{value}</div>
											</div>
										) : (
											<div className='cursor-pointer'>{value}</div>
										)}
										{serachData.length - 1 === index ? (
											''
										) : (
											<Divider className='my-2 padding-top-border' />
										)}
									</IonLabel>
									// </IonItem>
								);
							})
						)
					) : (
						<CustomNoData />
					)}
				</div>
			</div>
		</div>
	);
};

export const CustomDropDown = ({
	isCheckbox,
	data,
	setData,
	title,
	isSearch,
	placeholder,
	modalTitle,
	listTitle,
	title2,
	className,
	needAltImg,
	onClick,
	trigger,
	needUnselect,
	disable,
}: any) => {
	const [open, setOpen] = useState(false);
	const [serachData, setSearchData] = useState(data);
	const [value, setValue] = useState('');
	const [searchValue, setSearchValue] = useState('');
	useEffect(() => {
		setSearchData(data);
	}, [data]);
	// const activeData = value ? serachData : data;
	const headerData =
		data && data.length > 0
			? data.filter((item: any) => item.checked).map(({ value }: any) => value)
			: [];
	const header =
		headerData && headerData.length > 0 ? headerData.toString() : title;

	return (
		<>
			<div
				onClick={!disable ? onClick : () => false}
				className={'cursor-pointer CustomDropDown ' + className}
			>
				<div
					className='CustomDropDown_input'
					onClick={() => (!disable ? setOpen(!open) : false)}
				>
					<IonLabel
						style={disable ? { color: '#dfdfdf' } : {}}
						slot='end'
					>
						{title2 ? title2 : header}
					</IonLabel>
					<img
						src={needAltImg ? needAltImg : '/images/icons/downarrow.svg'}
						alt='drop down'
						style={{
							transform: open ? 'rotate(314deg)' : 'rotate(133deg)',
							transition: 'transform 0.25s',
						}}
					/>
				</div>
			</div>
			<CustomModal
				id='cutomdropdown'
				isOpen={open}
				initialBreakpoint={1}
				handleClose={() => {
					setSearchValue('');
					setSearchData(data);
					setOpen(false);
					trigger && trigger();
				}}
				breakpoints={[0, 0.35, 0.5, 0.75, 1]}
				className='w-100 side-modal'
				modalContent={
					<DropDownModal
						{...{
							setOpen,
							serachData,
							data,
							setSearchData,
							setValue,
							value,
							isCheckbox,
							open,
							setData,
							isSearch,
							placeholder,
							listTitle,
							onClick,
							setSearchValue,
							searchValue,
							needUnselect,
						}}
					/>
				}
				modalTitle={modalTitle}
				needCross={true}
				needArrow={false}
				scrollY={true}
			/>
		</>
	);
};

export default CustomDropDown;
