export class AddressDetailsForm  {
  id:number;
  addressType: string;
  line1: string;
  line2: string;
  landmark: string;
  pincode: string;
  postOffice?: string|undefined;
  city: string;
  state: string;
  profileId:number;

  constructor(request: AddressDetailsRequest) {
    this.id = request.id;
    this.addressType = request.addressType;
    this.line1 = request.line1;
    this.line2 = request.line2;
    this.landmark = request.landmark;
    this.pincode = request.pincode;  // Ensure the correct spelling as per your class definition
    this.city = request.city;
    this.state = request.state;
    this.profileId = request.profileId;
  }
  
};

export class AddressDetailsRequest  {
  id:number;
  addressType: string;
  line1: string;
  line2: string;
  landmark: string;
  pincode: string;
  city: string;
  state: string;
  profileId:number;

  constructor(form: AddressDetailsForm) {
    this.id = form.id;
    this.addressType = form.addressType;
    this.line1 = form.line1;
    this.line2 = form.line2;
    this.landmark = form.landmark;
    this.pincode = form.pincode;  // Ensure the correct spelling as per your class definition
    this.city = form.city;
    this.state = form.state;
    this.profileId = form.profileId;
  }
};





// export interface AddressDetailRequest {
//   id: any;
//   addressType: string;
//   permanent_line1: string;
//   permanent_line2: any;
//   permanent_landmark: string;
//   permanent_pincode: string;
//   permanent_city: string;
//   permanent_state: string;
//   current_line1: string;
//   current_line2: any;
//   current_landmark: string;
//   current_pincode: string;
//   current_city: string;
//   current_state: string;
//   profileId: number;
// }
// type AddressDetailsForm = {
//   permLine1: string,
//   permLine2: string,
//   permLandmark: string,
//   permPincode: string,
//   permCity: string,
//   permState: string,
//   curLine1: string,
//   curLine2: string,
//   curLandmark: string,
//   curPincode: string,
//   curCity: string,
//   curState: string,
// };
//   export default AddressDetailsForm;
