/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonButton } from '@ionic/react';
import React from 'react';

interface ICustomButton {
	children?: any;
	title?: any;
	color?: any;
	type?: any;
	expand?: any;
	size?: any;
	className?: any;
	fill?: any;
	ionBlur?: any;
	ionFocus?: any;
	onClick?: any;
	routerDirection?: any;
	routerLink?: any;
	disabled?: any;
	style?: any;
	id?: any;
	prefixIcon?: any;
	shape?: any;
	href?: any;
}
export const CustomButton: React.FC<ICustomButton> = ({
	color,
	style,
	children,
	title,
	type = 'button',
	routerDirection,
	routerLink,
	expand,
	size,
	fill,
	className,
	onClick,
	disabled,
	id,
	prefixIcon,
	shape,
	href,
}) => {
	return (
		<>
			{children ? (
				<IonButton
					href={href}
					type={type}
					expand={expand}
					size={size ? size : 'default'}
					fill={fill}
					className={`custom-ion-buttom ${className}`}
					onClick={onClick}
					routerDirection={routerDirection}
					routerLink={routerLink}
					disabled={disabled}
					style={style}
					color={color}
					id={id}
				>
					{children}
				</IonButton>
			) : (
				<IonButton
					type={type}
					expand={expand}
					size={size ? size : 'default'}
					fill={fill}
					className={`custom-ion-buttom ${className}`}
					onClick={onClick}
					routerDirection={routerDirection}
					routerLink={routerLink}
					disabled={disabled}
					style={style}
					color={color}
					id={id}
					shape={shape}
				>
					{prefixIcon && (
						<>
							<img
								className='me-2'
								src={prefixIcon}
							/>
						</>
					)}{' '}
					{title}
				</IonButton>
			)}
		</>
	);
};
