import React, { useEffect, useState } from 'react';
import {
	IonButton,
	IonCard,
	IonCardContent,
	IonContent,
	IonHeader,
	IonModal,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { t } from 'i18next';
import CustomForm from '../insurance/components/common/custom_form/custom_form.component';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { useBusyLoading } from '../insurance/context/BusyLoadingContext';
import { customerUploadLas } from '../../store/reducers/lead/leadSlice';

interface ManualUploadModalProps {
	isOpen: boolean;
	onClose: () => void;
	showEquity: boolean;
	showMF: boolean;
	showDebentures: boolean;
	showBonds: boolean;
	onSubmit: () => void;
}

const ManualUploadModal: React.FC<ManualUploadModalProps> = ({
	isOpen,
	onClose,
	onSubmit,
	showEquity,
	showMF,
	showBonds,
	showDebentures,
}) => {
	const [validateForm, setValidateForm] = useState<boolean>(false);
	const [hidePortfoliostatement, setHidePortfoliostatement] =
		useState<boolean>(false);
	const [hideEquityDoc, setHideEquityDoc] = useState<boolean>(true);
	const [hideMFDoc, setHideMFDoc] = useState<boolean>(true);
	const [hideBondsDoc, setHideBondsDoc] = useState<boolean>(true);
	const [hideDebenturesDoc, setHideDebenturesDoc] = useState<boolean>(true);
	const LeadID = useSelector((state: any) => state?.lead?.leadFlowData?.leadId);
	const dispatch: any = useDispatch();
	const [validateDoc, setValidateDoc] = useState(false);

	const { setBusyState } = useBusyLoading();

	//   useEffect(() => {
	//     if (responseData?.status) {
	//       showToastAlert({ type: "success", message: responseData?.message });

	//       if (inpCallback) {
	//         inpCallback();
	//         if (nextTabUrl) history.push(nextTabUrl);
	//       }
	//     }
	//   }, [responseData]);

	const manual_upload_schema = yup.object({
		uploadFormat: yup.string().required(t('Holdings selection is required.')),
		portfoliostatement: yup.mixed().notRequired(),
		docEquity: yup.mixed().notRequired(),
		docMF: yup.mixed().notRequired(),
		docBonds: yup.mixed().notRequired(),
		docDebentures: yup.mixed().notRequired(),

		// portfoliostatement: hidePortfoliostatement
		//     ? yup.mixed().notRequired()
		//     : yup
		//         .object()
		//         .test(
		//             'content',
		//             'Portfolio Statement is required',
		//             value =>
		//                 !hidePortfoliostatement && value &&
		//                 (value as any).content !== null &&
		//                 (value as any).content !== undefined &&
		//                 (value as any).content !== ''
		//         ),
		// docEquity: hideEquityDoc
		//     ? yup.mixed().notRequired()
		//     : yup
		//         .object()
		//         .test(
		//             'content',
		//             'Equity Statement is required',
		//             value =>
		//                 !hideEquityDoc && value &&
		//                 (value as any).content !== null &&
		//                 (value as any).content !== undefined &&
		//                 (value as any).content !== ''
		//         ),
		// docMF: hideMFDoc
		//     ? yup.mixed().notRequired()
		//     : yup
		//         .object()
		//         .test(
		//             'content',
		//             'Mutual Fund Statement is required',
		//             value =>
		//                 !hideMFDoc && value &&
		//                 (value as any).content !== null &&
		//                 (value as any).content !== undefined &&
		//                 (value as any).content !== ''
		//         ),
		// docBonds: hideBondsDoc
		//     ? yup.mixed().notRequired()
		//     : yup
		//         .object()
		//         .test(
		//             'content',
		//             'Bonds Statement is required',
		//             value =>
		//                 !hideBondsDoc && value &&
		//                 (value as any).content !== null &&
		//                 (value as any).content !== undefined &&
		//                 (value as any).content !== ''
		//         ),
		// docDebentures: hideDebenturesDoc
		//     ? yup.mixed().notRequired()
		//     : yup
		//         .object()
		//         .test(
		//             'content',
		//             'Debentures Statement is required',
		//             value =>
		//                 !hideDebenturesDoc && value &&
		//                 (value as any).content !== null &&
		//                 (value as any).content !== undefined &&
		//                 (value as any).content !== ''
		//         ),
	});

	const {
		control,
		trigger,
		handleSubmit,
		setValue,
		getValues,
		watch,
		reset,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(manual_upload_schema),
		defaultValues: {
			uploadFormat: 'excel',
			docEquity: null, // Add all your fields here
			docMF: null,
			docBonds: null,
			docDebentures: null,
			portfoliostatement: null,
		},
	});

	const manualUploadFormKeys = [
		{
			name: 'uploadFormat',
			type: 'text/combo',
			mandatory: true,
			placeholder: 'Fetching Mode',
			values: [
				// { value: 'pdf', description: 'PDF' },
				{ value: 'excel', description: 'Excel' },
			],
		},
		{
			name: 'portfoliostatement',
			type: 'docupload',
			getValuesFunc: getValues,
			setValueFunc: setValue,
			title: 'Upload Portfolio Statement',
			applicationId: null,
			documentTypeId: null,
			profileId: null,
			showCamera: false,
			error: errors,
			allowImages: false,
			allowPdfs: true,
			allowExcel: false,
			allowVideo: false,
			allowAudio: false,
			hidden: hidePortfoliostatement,
			inpUploadFunction: () => {
				const stub = getValues('portfoliostatement');
				if (stub.content) {
					const base64Data = stub.content.split(',')[1];
					// console.log("base64Data", base64Data);
				}
			},
			inpDeleteFunction: () => {},
		},
		{
			name: 'docEquity',
			type: 'docupload',
			// type: "file",
			getValuesFunc: getValues,
			setValueFunc: setValue,
			title: 'Equity',
			// placeholder: "Equity",
			applicationId: null,
			documentTypeId: null,
			profileId: null,
			showCamera: false,
			error: errors,
			allowImages: false,
			allowExcel: true,
			allowPdfs: false,
			allowVideo: false,
			allowAudio: false,
			hidden: hideEquityDoc,
			inpUploadFunction: () => {
				// const stub = getValues('docEquity');

				const stub = getValues('docEquity');
				// console.log("hbs getValues stub",getValues);

				// console.log("hbs equity stub", stub);
				if (stub.content) {
					const base64Data = stub.content.split(',')[1];
					// console.log("base64Data", base64Data);
					handleFileUpload(stub.fileName, base64Data, 'EQ');
				}

				// alert(stub);
			},
			inpDeleteFunction: () => {},
		},
		// {
		//     name: "docEquity",
		//     // type: "docupload",
		//     type: "file",
		//     getValuesFunc: getValues,
		//     setValueFunc: setValue,
		//     title: "Equity",
		//     placeholder: "Equity",
		//     applicationId: null,
		//     documentTypeId: null,
		//     profileId: null,
		//     showCamera: false,
		//     error: errors,
		//     allowImages: false,
		//     allowExcel: true,
		//     allowPdfs: false,
		//     allowVideo: false,
		//     allowAudio: false,
		//     hidden: hideEquityDoc,
		//     inpUploadFunction: () => { },
		//     inpDeleteFunction: () => { },
		// },
		{
			name: 'docMF',
			type: 'docupload',
			getValuesFunc: getValues,
			setValueFunc: setValue,
			title: 'Mutual Fund',
			applicationId: null,
			documentTypeId: null,
			profileId: null,
			showCamera: false,
			error: errors,
			allowImages: false,
			allowExcel: true,
			allowPdfs: false,
			allowVideo: false,
			allowAudio: false,
			hidden: hideMFDoc,
			inpUploadFunction: () => {
				const stub = getValues('docMF');
				// console.log("hbs mf stub", stub);
				if (stub.content) {
					const base64Data = stub.content.split(',')[1];
					// console.log("base64Data", base64Data);
					handleFileUpload(stub.fileName, base64Data, 'MF');
				}
			},
			inpDeleteFunction: () => {},
		},
		{
			name: 'docBonds',
			type: 'docupload',
			getValuesFunc: getValues,
			setValueFunc: setValue,
			title: 'Bonds',
			applicationId: null,
			documentTypeId: null,
			profileId: null,
			showCamera: false,
			error: errors,
			allowImages: false,
			allowExcel: true,
			allowPdfs: false,
			allowVideo: false,
			allowAudio: false,
			hidden: hideBondsDoc,
			inpUploadFunction: () => {
				const stub = getValues('docBonds');
				// console.log("hbs bonds stub", stub);
				if (stub.content) {
					const base64Data = stub.content.split(',')[1];

					// console.log("base64Data", base64Data);
					handleFileUpload(stub.fileName, base64Data, 'BO');
				}
			},
			inpDeleteFunction: () => {},
		},
		{
			name: 'docDebentures',
			type: 'docupload',
			getValuesFunc: getValues,
			setValueFunc: setValue,
			title: 'Debentures',
			applicationId: null,
			documentTypeId: null,
			profileId: null,
			showCamera: false,
			error: errors,
			allowImages: false,
			allowExcel: true,
			allowPdfs: false,
			allowVideo: false,
			allowAudio: false,
			hidden: hideDebenturesDoc,
			inpUploadFunction: () => {
				const stub = getValues('docDebentures');

				// console.log("hbs deb stub", stub);
				if (stub.content) {
					const base64Data = stub.content.split(',')[1];
					// console.log("base64Data", base64Data);
					handleFileUpload(stub.fileName, base64Data, 'debentures');
				}
			},
			inpDeleteFunction: () => {},
		},
	];

	// const customerUpload = async (fileName: string, fileBase64: string, lead_id: string, fund_type: string) => {
	//     try {
	//         const response = await dispatch(
	//             // customerUploadLas({
	//             //     param: '/' + lead_id,
	//             //     data: {
	//             //         fileName: fileName,
	//             //         fileBase64: fileBase64,
	//             //         lead_id: LeadID,
	//             //         fund_type: fund_type,
	//             //     },
	//             // })
	//         );
	//         const result = unwrapResult(response);
	//         if (result.status) {
	//             console.log("Upload successful:", result);
	//         }
	//     } catch (error) {
	//         console.error("Upload failed:", error);
	//     }
	// };

	const uploadFormatWatch = watch('uploadFormat', '');

	useEffect(() => {
		const isExcel = uploadFormatWatch === 'excel';
		setHidePortfoliostatement(isExcel);
		setHideEquityDoc(!(showEquity && isExcel));
		setHideMFDoc(!(showMF && isExcel));
		setHideBondsDoc(!(showBonds && isExcel));
		setHideDebenturesDoc(!(showDebentures && isExcel));
	}, [uploadFormatWatch, showEquity, showMF, showBonds, showDebentures]);

	const handleFileUpload = async (
		fileName: string,
		fileBase64: string,
		fund_type: string
	) => {
		const payload = {
			fileName: fileName,
			fileBase64: fileBase64,
			lead_id: LeadID,
			// lead_id: 1,
			fund_type: fund_type,
		};

		setBusyState(true);
		await dispatch(
			customerUploadLas({
				// param: '/' + leadFlowData?.leadId,
				data: payload,
			})
		).then((res: any) => {
			setBusyState(false);
		});
	};

	useEffect(() => {
		const arr = [];
		manualUploadFormKeys.map(
			(item: any) => item?.hidden == false && arr.push(item?.name)
		);

		const allKeysPresentAndHaveValues: any = arr.every((key) => watch(key));
		setValidateDoc(allKeysPresentAndHaveValues);
	}, [manualUploadFormKeys]);

	return (
		<IonModal
			isOpen={isOpen}
			onDidDismiss={onClose}
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>{t('Manual Upload')}</IonTitle>
					<IonButton
						slot='end'
						onClick={() => {
							reset({
								uploadFormat: 'excel',
								docEquity: null, // Add all your fields here
								docMF: null,
								docBonds: null,
								docDebentures: null,
								portfoliostatement: null,
							});
							onClose();
						}}
					>
						Close
					</IonButton>
				</IonToolbar>
			</IonHeader>
			<IonContent className='ion-padding'>
				<form onSubmit={handleSubmit(onSubmit)}>
					<IonCard>
						<IonCardContent>
							<CustomForm
								formKeys={manualUploadFormKeys}
								control={control}
								getValues={getValues}
								setValue={setValue}
								errors={errors}
								isValid={validateForm}
								trigger={trigger}
							/>
						</IonCardContent>
					</IonCard>
					<div
						className='position-fixed w-100 bottom-0 start-0 px-3 pb-3'
						style={{ backgroundColor: '#fff' }}
					>
						<CustomButton
							className='btn-blue'
							type='submit'
							expand='block'
							title='Submit'
							disabled={!validateDoc}
							onClick={() => {
								handleSubmit(onSubmit);
								setValidateForm(isValid);
								// handleFileUpload();
							}}
						/>
					</div>
				</form>
			</IonContent>
		</IonModal>
	);
};

export default ManualUploadModal;
