import {
	// IonContent,
	IonIcon,
	IonLabel,
	IonRouterOutlet,
	IonTabBar,
	IonTabButton,
	IonTabs,
} from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router';
import { Route } from 'react-router';
import { Leads } from '../leads/Leads';
import { DashboardHome } from './home/DashboardHome';
import { AddLead } from '../add-lead/AddLead';
import { Products } from '../products/Products';
import { useTranslation } from 'react-i18next';
import { Teams } from '../teams/Teams';
import { productFlow } from '../../store/reducers/lead/leadSlice';
import { useDispatch } from 'react-redux';

const tabs = [
	{
		tab: 'Home',
		icon: '/images/icons/home-tab-icon.svg',
		icon_active: '/images/icons/active-home-tab-icon.svg',
		href: '/dashboard/home',
	},
	{
		tab: 'Leads',
		icon: '/images/icons/leads-tab-icon.svg',
		icon_active: '/images/icons/active-leads-tab-icon.svg',
		href: '/dashboard/lead',
	},
	{
		tab: 'Add',
		icon: '/images/icons/add-tab-icon.svg',
		icon_active: '/images/icons/active-add-tab-icon.svg',
		href: '/dashboard/add-lead',
	},
	{
		tab: 'Products',
		icon: '/images/icons/products-tab-icon.svg',
		icon_active: '/images/icons/active-products-tab-icon.svg',
		href: '/dashboard/products',
	},
	{
		tab: 'Teams',
		icon: '/images/icons/teams-tab-icon.svg',
		icon_active: 'images/icons/active-teams-tab-icon.svg',
		href: '/dashboard/teams',
	},
];

export const Dashboard: React.FC = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const [tabName, setTabName] = useState<any>();
	const dispatch: any = useDispatch();
	useEffect(() => {
		setTabName(getTabNameFromActiveDashboardUrls());
	}, [location.pathname]);

	const getTabNameFromActiveDashboardUrls = useCallback(() => {
		switch (window.location.pathname) {
			case '/dashboard':
			case '/dashboard/':
			case '/dashboard/home':
				return 'Home';
			case '/dashboard/lead':
				return 'Leads';
			case '/dashboard/add-lead':
				return 'Add';
			case '/dashboard/products':
				return 'Products';
			case '/dashboard/teams':
				return 'Teams';
		}
	}, [location.pathname]);
	return (
		<IonTabs className='tab-bar'>
			<IonRouterOutlet>
				<Redirect
					exact
					path='/dashboard'
					to='/dashboard/home'
				/>
				<Route
					path='/dashboard'
					render={() => <DashboardHome />}
					exact={true}
				/>
				<Route
					path='/dashboard/home'
					render={() => <DashboardHome />}
					exact={true}
				/>
				<Route
					path='/dashboard/lead'
					render={() => <Leads />}
					exact={true}
				/>
				<Route
					path='/dashboard/add-lead'
					render={() => <AddLead />}
					exact={true}
				/>
				<Route
					path='/dashboard/products'
					render={() => <Products />}
					exact={true}
				/>
				<Route
					path='/dashboard/teams'
					render={() => <Teams />}
					exact={true}
				/>
			</IonRouterOutlet>
			<IonTabBar
				className='tab-bar'
				slot='bottom'
				onIonTabsDidChange={(event: CustomEvent<{ tab: string }>) => {
					if (event?.detail?.tab === 'Products') {
						dispatch(
							productFlow({ is_product: 1, is_lender: 0, lender_show: '0' })
						);
					}

					setTabName(event.detail.tab);
				}}
				style={{ height: '65px' }}
			>
				{tabs.map((item, index) => {
					return (
						<IonTabButton
							key={index}
							className='mt-1'
							tab={item.tab}
							href={item.href}
							style={{ background: 'white' }}
						>
							<IonIcon
								icon={tabName === item.tab ? item?.icon_active : item.icon}
								className='pb-1'
							/>
							<IonLabel>{t(item.tab)}</IonLabel>
						</IonTabButton>
					);
				})}
			</IonTabBar>
		</IonTabs>
	);
};
