import React from 'react';

interface CustomSuccessPage {
	message: string;
	className?: string;
}
export const CustomSuccessPage: React.FC<CustomSuccessPage> = ({ message }) => {
	return (
		<div className='d-flex ion-justify-content-center vh-100 px-5 ion-align-items-center'>
			<h3 className='fs-20 m-0 text-center px-4 fw-bold'>{message}</h3>
		</div>
	);
};
