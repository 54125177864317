// import { useHistory } from 'react-router-dom';
import {
	FormatDate,
	InputFormatCurrencyValue,
	findCurrentStatus,
	findSource,
	// findStatus,
	makeFirstLetterUppercase,
	setBankIcon,
} from '../../../helpers/functions';
// import { IonButton } from '@ionic/react';
import { CustomButton } from '../custom-button/CustomButton';
import './LeadContactCard.scss';
import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonGrid,
	IonLabel,
	IonRow,
} from '@ionic/react';
import { CustomCheckbox } from '../custom-checkbox/CustomCheckbox';
import { Divider } from '../divider/Divider';
import { LEAD_STATUS, StatusNum } from '../../../helpers/constant';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const LeadContactCard = ({
	leads,
	isShowFooter,
	getLeadIds,
	showTag,
	showStatus,
	setGetLeadIds,
	bulkSelection,
	onClick,
	from,
	code,
	isShowLeadStatusButton,
	getAllLead,
	footerStatus,
}: any) => {
	const getAppId = (leadID: any) => {
		return getAllLead
			.filter(({ id, lead_app_id }: any) => id === leadID && lead_app_id)
			.map(({ lead_app_id }: any) => lead_app_id);
	};
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	const handleChange = (evnet: any, leadID: number) => {
		const activeLeads = getLeadIds.map((item: any) => {
			if (leadID === item?.id) {
				return {
					...item,
					checked: evnet.detail.checked,
					appsToUpdate: getAppId(leadID),
				};
			} else {
				return { ...item };
			}
		});
		setGetLeadIds(activeLeads);
	};

	const isLeadList = location.pathname === '/lead-list';
	const { t } = useTranslation();

	return (
		<div className='row gx-0 bg-web px-0'>
			{leads &&
				leads?.map((item: any, index: any) => (
					<div
						className='col-lg-4 col-12 col-md-6 d-flex'
						key={index}
					>
						<IonCard
							className='lead-contact-card-conatiner m-md-2 border-md-radius-10 d-flex flex-column w-100 cursor-pointer'
							mode='md'
							onClick={(e: any) => {
								const checkBoxClasses: any = e?.target?.className?.split(' ');
								if (
									![
										'phone-icon',
										'whatsapp-icon',
										'dots-icon',
										'card-checkbox-input',
									].includes(e?.target?.className) &&
									!checkBoxClasses.includes('lc-card-checkbox-input')
								) {
									from === 'BOPS'
										? onClick({
												id: item?.id,
												tag: item?.tag,
												status: item?.status,
												code: code,
												mobile: item?.mobile,
												lead_app_id: item?.lead_app_id,
												lender_name: item?.lender_name,
												bank_app_no: item?.bank_app_no,
												full_name: `${
													item?.first_name ? item?.first_name : ' '
												} ${item?.last_name ? item?.last_name : ''}`,
										  })
										: onClick({
												id: item?.id,
												status: item?.status,
												leadStatus: item?.lead_status,
												tag: item?.tag,
												mobile: item?.mobile_no,
												lead_app_id: item?.lead_app_id,
												lender_name: item?.lender_name,
												bank_app_no: item?.bank_app_no,
												full_name: `${
													item?.first_name ? item?.first_name : ' '
												} ${item?.last_name ? item?.last_name : ''}`,
												item,
										  });
								}
							}}
							style={{ minHeight: 130 }}
						>
							<IonCardHeader className='d-flex flex-row justify-content-between pb-1'>
								<IonCardSubtitle className='fs-12 text-grey'>
									# {from.toLowerCase() === 'lead' ? 'L' : 'D'}
									{item?.id} {'  '}
									{(LEAD_STATUS.DISBURSED_LABEL == leadInfo?.leadStatus ||
										LEAD_STATUS.LOGIN_LABEL == leadInfo?.leadStatus ||
										LEAD_STATUS.SANCTIONED_LABEL == leadInfo?.leadStatus ||
										LEAD_STATUS.CLOSED_LABEL === leadInfo?.leadStatus ||
										LEAD_STATUS.APPLICATION_FULFILLED ===
											leadInfo?.leadStatus) &&
										item?.lead_app_id && (
											<div className='ml-3 px-4 d-inline-block'>{`# A${item?.lead_app_id}`}</div>
										)}
								</IonCardSubtitle>
								<IonCardSubtitle className='fs-12 text-grey mt-0'>
									{FormatDate(
										from.toLowerCase() === 'lead'
											? item?.updatedAt
											: item?.createdAt,
										'dd mmmm yyyy'
									)}
								</IonCardSubtitle>
							</IonCardHeader>
							<IonCardHeader
								className={`py-0 mb-1 d-flex flex-row ${
									bulkSelection ? '' : 'justify-content-between'
								}`}
							>
								{bulkSelection && (
									<CustomCheckbox
										labelPlacement='end'
										className='me-3 no-padding lc-card-checkbox-input'
										onChange={(event: any) => handleChange(event, item?.id)}
										// indeterminate={!getLeadIds.includes(item?.id)}
										isChecked={getLeadIds[index]?.checked}
									/>
								)}
								{
									<IonCardTitle className='fs-16 fw-600 darkest-grey text-capitalize m-0 cursor-pointer'>
										{(item?.first_name ? item?.first_name : 'Unknown') +
											' ' +
											(item?.middle_name ? item?.middle_name : '') +
											' ' +
											(item?.last_name ? item?.last_name : '')}
									</IonCardTitle>
								}
								{isShowLeadStatusButton &&
									item?.lead_status &&
									from === 'lead' && (
										<CustomButton
											size={'extra small'}
											fill='outline'
											title={t(
												findCurrentStatus(item?.lead_status).label
											).toString()}
											className={'lead-contact-card-button1'}
										/>
									)}
							</IonCardHeader>
							<IonCardContent className='py-md-0 border-md-radius-10 d-flex flex-column h-100 justify-content-between'>
								<div>
									{item?.lead_status && from !== 'lead' && (
										<CustomButton
											fill='outline'
											title={item?.lead_status}
											className={'lead-contact-card-button'}
										/>
									)}
									{isShowLeadStatusButton &&
										item?.lead_status &&
										from === 'lead' && (
											<CustomButton
												size={'extra small'}
												fill='outline'
												title={findCurrentStatus(item?.lead_status).label}
												className={'lead-contact-card-button2'}
											/>
										)}
									{item?.status && showStatus && (
										<CustomButton
											size='small'
											fill='outline'
											title={t(findSource(item.status))}
											className={'lead-contact-card-button'}
										/>
									)}
									{item?.tag && (showTag || showTag === undefined) ? (
										<CustomButton
											size='small'
											fill='outline'
											title={item.tag ? t(item.tag) : 'New'}
											className={'lead-contact-card-button fs-14 mt-2'}
										/>
									) : (
										''
									)}
									<IonGrid className='py-md-0'>
										<IonRow>
											{item?.entityType && (
												<IonCol size='6'>
													<img
														className='me-1'
														src='/images/icons/list-user-type-icon.svg'
														alt='type'
													/>{' '}
													<IonLabel className='text-capitalize'>
														{item?.entityType}
													</IonLabel>
												</IonCol>
											)}
											{item?.category_name && (
												<IonCol size='6'>
													<img
														className='me-1'
														src={`${item?.product_icon}`}
														height={16}
														alt='type'
													/>{' '}
													<IonLabel className='text-capitalize'>
														{item?.category_name}
													</IonLabel>
												</IonCol>
											)}
											{item?.city && (
												<IonCol size='6'>
													<img
														className='me-1'
														src='/images/icons/list-user-location-icon.svg'
														alt='type'
													/>{' '}
													<IonLabel className='text-capitalize'>
														{item?.city}
													</IonLabel>
												</IonCol>
											)}
											{item?.lead_source && from !== 'lead' && (
												<IonCol size='6'>
													<img
														className='me-1'
														src='/images/icons/leads/sub-product.svg'
														alt='type'
													/>{' '}
													<IonLabel className='text-capitalize'>
														{makeFirstLetterUppercase(item?.lead_source)}
													</IonLabel>
												</IonCol>
											)}
											{item?.lead_made_by && from === 'lead' && (
												<IonCol size='6'>
													<img
														className='me-1'
														src='/images/icons/leads/sub-product.svg'
														alt='type'
													/>{' '}
													<IonLabel className='text-capitalize'>
														{findSource(item?.lead_made_by)}
													</IonLabel>
												</IonCol>
											)}
											{footerStatus === LEAD_STATUS.CREDIT_BUREAU && (
												<IonCol size='6'>
													<img
														className='me-1'
														src='/images/icons/assign-icon.svg'
														alt='type'
													/>{' '}
													<IonLabel className='text-capitalize'>
														{findSource(item?.lead_current_assignee_name)}
													</IonLabel>
												</IonCol>
											)}
											{
												// [
												// 	LEAD_STATUS.UNDERPROCESS_LABEL,
												// 	LEAD_STATUS.ALL,
												// ].includes(leadInfo?.leadStatus) &&
												item?.lead_current_assignee_name &&
													(from === 'lead' || from === 'BOPS') && (
														<IonCol
															size='6'
															className='d-flex ps-0'
														>
															<img
																width={25}
																height={25}
																className='me-1'
																src='/images/icons/assign-icon.svg'
																alt='type'
															/>{' '}
															<IonLabel className='text-capitalize'>
																{item?.lead_current_assignee_name}
															</IonLabel>
														</IonCol>
													)
											}
											{item?.reporting_manager_name && from !== 'BOPS' && (
												<IonCol size='6'>
													<img
														className='me-1'
														src='/images/icons/list-user-manager-icon.svg'
														alt='type'
													/>{' '}
													<IonLabel className='text-capitalize'>
														{item?.reporting_manager_name}
													</IonLabel>
												</IonCol>
											)}
											{item?.mobile && from !== 'BOPS' && (
												<IonCol size='6'>
													<img
														className='me-1'
														src='/images/icons/list-user-phone-icon.svg'
														alt='type'
													/>{' '}
													<IonLabel>{item?.mobile}</IonLabel>
												</IonCol>
											)}
											{LEAD_STATUS.DISBURSED_LABEL == leadInfo?.leadStatus && (
												<>
													<IonCol
														size='6'
														className='d-flex ps-0'
													>
														<img
															style={{
																width: '25px',
																height: '25px',
															}}
															className='me-1'
															src={setBankIcon(item?.lender_name)}
															alt='type'
														/>{' '}
														<IonLabel className='text-capitalize align-self-center'>
															{item.lender_name}
														</IonLabel>
													</IonCol>
													{item?.lan && (
														<IonCol
															style={{ width: '100%' }}
															className='d-flex ps-0'
														>
															<div
																className='d-flex align-content-center'
																style={{ width: '100%' }}
															>
																<img
																	className='me-1'
																	src='/images/icons/products/Iconly-Bold-Document.svg'
																	alt='type'
																	height={16}
																	width={16}
																/>{' '}
																<IonLabel
																	className={
																		'text-capitalize align-self-center text-truncate'
																	}
																>
																	LAN{' '}
																	<span className={`fw-700 `}>{item.lan}</span>
																</IonLabel>
															</div>
														</IonCol>
													)}
												</>
											)}
											{(LEAD_STATUS.LOGIN_LABEL == leadInfo?.leadStatus ||
												(LEAD_STATUS.SANCTIONED_LABEL == leadInfo?.leadStatus &&
													item?.bank_app_no)) && (
												<>
													<IonCol
														size='6'
														className='d-flex ps-0'
													>
														<img
															style={{
																width: '25px',
																height: '25px',
															}}
															className='me-1'
															src={setBankIcon(item?.lender_name)}
															alt='type'
														/>{' '}
														<IonLabel className='text-capitalize align-self-center'>
															{item.lender_name}
														</IonLabel>
													</IonCol>
													<IonCol
														style={{ width: '100%' }}
														className='d-flex ps-0'
													>
														<img
															className='me-1'
															src='/images/icons/products/Iconly-Bold-Document.svg'
															alt='type'
															height={16}
															width={16}
														/>{' '}
														<IonLabel
															className={
																'text-capitalize align-self-center text-truncate'
															}
														>
															{t('Bank App. No.')}{' '}
															<span className={`fw-700`}>
																{item?.bank_app_no ? item?.bank_app_no : ''}
															</span>
														</IonLabel>
													</IonCol>
												</>
											)}
											{(LEAD_STATUS.APPLICATION_FULFILLED ==
												leadInfo?.leadStatus ||
												LEAD_STATUS.CLOSED_LABEL == leadInfo?.leadStatus ||
												LEAD_STATUS.ALL == leadInfo?.leadStatus ||
												LEAD_STATUS.FULFILLMENT == leadInfo?.leadStatus) &&
												item?.lender_name && (
													<IonCol
														size='6'
														className='d-flex ps-0'
													>
														<img
															style={{
																width: '25px',
																height: '25px',
															}}
															className='me-1'
															src={setBankIcon(item?.lender_name)}
															alt='type'
														/>{' '}
														<IonLabel className='text-capitalize align-self-center'>
															{item.lender_name}
														</IonLabel>
													</IonCol>
												)}
										</IonRow>
									</IonGrid>
								</div>
								<div>
									{isShowFooter && (
										<>
											<Divider className='padding-top-border' />
											<IonGrid>
												<IonRow>
													{from !== 'BOPS' &&
													footerStatus !== LEAD_STATUS.CREDIT_BUREAU ? (
														<IonCol
															size='6'
															className='d-flex flex-column'
														>
															<IonLabel
																className='fs-12'
																style={{ color: 'var(--text-dark-grey)' }}
															>
																{t('Loan Amount')}
															</IonLabel>
															<IonLabel className='fw-600 d-flex'>
																<img
																	src='/images/icons/leads/rupees-icon.svg'
																	alt='rupees icon'
																	width={7}
																	height={21}
																/>
																&nbsp;
																{InputFormatCurrencyValue(item?.loan_amount)}
															</IonLabel>
														</IonCol>
													) : (
														<IonCol
															size='6'
															className='d-flex flex-column'
														></IonCol>
													)}
													{/* use */}
													<IonCol
														size={from === 'BOPS' ? '12' : '6'}
														className='p-0 d-flex justify-content-end'
													>
														<div
															className={`d-flex ${
																from === 'BOPS' &&
																item?.status === StatusNum?.REJECTED
																	? 'justify-content-between '
																	: 'justify-content-end '
															} align-items-center`}
														>
															{from === 'BOPS' &&
															item.status === StatusNum?.REJECTED ? (
																<h4>
																	{t(`${'Rejected on'}`)} &nbsp;
																	{FormatDate(
																		item?.rejected_on,
																		'dd mmmm yyyy'
																	)}
																</h4>
															) : (
																''
															)}
															<div className='d-flex gap-2 justify-end'>
																<a
																	href={`tel:${
																		from === 'lead'
																			? item?.mobile_no
																			: item?.mobile
																	}`}
																>
																	<img
																		src='/images/icons/leads/phone.svg'
																		alt='phone'
																		className='phone-icon'
																	/>
																</a>
																<a
																	href={`https://api.whatsapp.com/send?phone=91${
																		from === 'lead'
																			? item?.mobile_no
																			: item?.mobile
																	}`}
																	target='_blank'
																>
																	<img
																		src='/images/icons/leads/whatsapp.svg'
																		alt='whatsapp'
																		className='whatsapp-icon'
																	/>
																</a>
																<span
																	className={`dots-icon ${
																		isLeadList ? 'd-none' : 'd-none'
																	}`}
																	onClick={() =>
																		from === 'BOPS'
																			? onClick({
																					id: item?.id,
																					tag: item?.tag,
																					status: item?.status,
																					code: code,
																					mobile: item?.mobile,
																					lender_name: item?.lender_name,
																					bank_app_no: item?.bank_app_no,
																			  })
																			: onClick({
																					id: item?.id,
																					status: item?.status,
																					leadStatus: item?.lead_status,
																					tag: item?.tag,
																					mobile: item?.mobile_no,
																					lender_name: item?.lender_name,
																					bank_app_no: item?.bank_app_no,
																			  })
																	}
																	style={{
																		width: '32px',
																		height: '32px',
																		borderRadius: '50px',
																		background: '#F3F3F3',
																		padding: '3px 8px',
																		display: 'flex',
																	}}
																>
																	<img
																		src='/images/icons/teams/view-dots.svg'
																		alt='whatsapp'
																	/>
																</span>
															</div>
														</div>
													</IonCol>
												</IonRow>
											</IonGrid>
										</>
									)}
								</div>
							</IonCardContent>
							{/* <div className='w-100 my-3 px-3'>
						<div className='d-flex justify-content-between'>
							<div className='fs-12 text-grey'># D{item?.id}</div>
							<div className='fs-12 text-grey'>
								{FormatDate(item?.Date, 'dd mmmm yyyy')}
							</div>
						</div>
						<h2 className='fs-16 fw-600 darkest-grey text-capitalize'>
							{item?.first_name +
								' ' +
								item?.middle_name +
								' ' +
								item?.last_name}
						</h2>
						<CustomButton
							fill='outline'
							title={item.lonaType}
							className={'lead-contact-card-button'}
						/>
						<div className='row mb-1'>
							<div className='col-6 text-grey text-capitalize'>
								<img
									className='me-1'
									src='/images/icons/list-user-type-icon.svg'
									alt='type'
								/>{' '}
								{item?.entityType}
							</div>
							<div className='col-6 text-grey text-capitalize'>
								<img
									className='me-1'
									src='/images/icons/list-user-location-icon.svg'
									alt='location'
								/>{' '}
								{item?.city}
							</div>
						</div>
						<div className='row'>
							<div className='col-6 text-grey text-capitalize'>
								<img
									className='me-1'
									src='/images/icons/list-user-manager-icon.svg'
									alt='manager'
								/>{' '}
								Adani Siddhart
							</div>
							<div className='col-6 text-grey text-capitalize'>
								<img
									className='me-1'
									src='/images/icons/list-user-phone-icon.svg'
									alt='phone'
								/>{' '}
								{item?.mobile}
							</div>
						</div>
					</div>
					<hr /> */}
							<Divider className='hr-card-list d-block d-md-none' />
						</IonCard>
					</div>
				))}
		</div>
	);
};

export default LeadContactCard;
