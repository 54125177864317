import { IonPage } from '@ionic/react';
import { Header } from '../../components/layout/header/Header';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
import { useState } from 'react';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';

export const HdfcDetails: React.FC = () => {
	const { t } = useTranslation();
	const [alertModal, setAlertModal] = useState(false);
	return (
		<>
			<IonPage
				id='main-content'
				className='justify-content-start'
			>
				<Header name='HDFC Bank' />
				<div className='h-100 d-flex align-items-center justify-content-center text-center flex-column'>
					<div className='mb-3 px-4'>
						{t(
							`Continue Application on the Principal Partner’s Official Portal`
						)}
					</div>
					<CustomButton title='Visit Site' />
				</div>
				<div
					className='position-fixed w-100 bottom-0 start-0 px-3 py-3'
					style={{
						backgroundColor: '#fff',
						zIndex: 2,
					}}
				>
					<CustomButton
						expand='block'
						className='mb-2 secondary-btn'
						title={t('Mark as Closed')}
					/>
					<CustomButton
						expand='block'
						onClick={() => setAlertModal(true)}
						title={t('Mark as Login')}
					/>
				</div>
			</IonPage>
			<CustomModal
				id='custommodal'
				isOpen={alertModal}
				modalTitle={t('Update Stage')}
				initialBreakpoint={undefined}
				handleClose={() => setAlertModal(false)}
				breakpoints={[]}
				className=''
				modalContent={
					<div className='p-3'>
						<div className='fs-20 fw-600'>
							{t(`By clicking on mark as login, we are considering that you have
              filled the application for this lender.`)}
						</div>
						<div className='d-flex justify-content-center mt-3'>
							<CustomButton
								className='w-50 me-3'
								fill='outline'
								onClick={() => setAlertModal(false)}
								title={t('Cancel')}
							/>
							<CustomButton
								className='w-50'
								fill='solid'
								onClick={() => setAlertModal(false)}
								title={t('Confirm')}
							/>
						</div>
					</div>
				}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
