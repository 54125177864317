import * as yup from 'yup';
import { REGEX } from '../../../helpers/constant';
import leadStepInfoFormInputs from './InsuranceLeadStepThree.json';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonContent, IonFooter, IonLabel } from '@ionic/react';
import { useHistory } from 'react-router';
import {
	PageExitPopUp,
	disableNumberArrows,
	showToastAlert,
} from '../../../helpers/functions';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { CustomCheckbox } from '../../../components/common/custom-checkbox/CustomCheckbox';
import DropDownModal from '../../../components/common/custom-dropdown/DropDownModal';
import {
	apiGenderDropdown,
	apiMaritalDropdown,
	apiUpdateLeadDetails,
	leadFlow,
	lifeAssuredApi,
} from '../../../store/reducers/lead/leadSlice';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { contributionPinCode } from '../../../store/reducers/user/userSlice';

const InsuranceLeadStepThree: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const [modalPageClose, setModalPageClose] = useState(false);
	const [pinCodeData, setPinCodeData] = useState<any>({});
	const [permanentPinCodeValue, setPermanentPincodeValue] = useState<any>({});
	const [currentPinCodeValue, setCurrentPincodeValue] = useState<any>({});
	const [sameAddressCheck, setSameAddressCheck] = useState(false);
	const [genderApi, setGenderApi] = useState([]);
	const [maritalApi, setMaritalApi] = useState([]);
	const dispatch: any = useDispatch();
	const leadFlowSelector = useSelector((state: any) => state?.lead);
	const { leadFlowData } = leadFlowSelector;
	const userData = useSelector((state: any) => state?.users?.user_details);

	const validationSchema = yup.object().shape({
		first_name: yup
			.string()
			.trim()
			.max(100, 'First Name should not be more than 100')
			.required('First Name is Required')
			.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
		middle_name: yup
			.string()
			.trim()
			.max(100, 'Middle Name should not be more than 100')
			//.required('Middle Name is Required')
			.notRequired()
			.matches(REGEX.ALLOWONLYCHAR, {
				message: 'Please Enter Valid Input',
				excludeEmptyString: true,
			}),
		last_name: yup
			.string()
			.trim()
			.max(100, 'Last Name should not be more than 100')
			.required('Last Name is Required')
			.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
		age: yup
			.number()
			.min(1, 'Age must be at least 1 characters')
			// .max(3, 'Age must be at most 3 characters')
			.required('Age is Required'),
		mobile_no: yup
			.string()
			.length(10, 'Mobile No. Should Be 10 Digits.')
			.required('Mobile is Required.')
			.matches(REGEX.INDIAN_MOBILE_NUMBERS, 'Please Enter Valid Number.'),
		father_first_name: yup
			.string()
			.required('Name is Required')
			.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
		father_last_name: yup
			.string()
			.required('Field is Required')
			.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
		father_middle_name: yup
			.string()
			.required('Field is Required')
			.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
		mother_first_name: yup
			.string()
			.required('Field is Required')
			.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
		mother_middle_name: yup
			.string()
			.required('Field is Required')
			.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
		mother_last_name: yup
			.string()
			.required('Field is Required')
			.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
		spouse_first_name: yup
			.string()
			.required('Name is Required')
			.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
		spouse_middle_name: yup
			.string()
			.required('Field is Required')
			.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
		spouse_last_name: yup
			.string()
			.required('Field is Required')
			.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
		email_proposer: yup
			.string()
			.required('Email is Required')
			.matches(REGEX.EMAIL, 'Please Enter Valid Email'),
		dob: yup
			.date()
			.required('DOB is Required.')
			.max(new Date(Date.now()), 'Date cannot be in the future'),
		permanent_line1: yup.string().required('Address is Required'),
		// .matches(REGEX.ALLOWONLYCHAR, 'Please Enter Current Address'),
		permanent_line2: yup.string().required('Address is Required'),
		// .matches(REGEX.ALLOWONLYCHAR, 'Please Enter Current Address'),
		permanent_landmark: yup.string().required('Landmark is Required'),
		// .matches(REGEX.ALLOWONLYCHAR, 'Please Enter Current Address'),
		permanent_pincode: yup
			.string()
			.required('Pincode is Required')
			.matches(REGEX.PINCODE, 'Please Enter Valid Pincode'),

		gender: yup.string().required('Address is Required'),
		marital_status: yup.string().required('Martial Status is Required'),
	});
	const {
		handleSubmit,
		control,
		trigger,
		setValue,
		getValues,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			same_address_check: false,
			age: 0,
			current_city: '',
			current_landmark: '',
			current_line1: '',
			current_line2: '',
			current_pincode: '',
			current_state: '',
			dob: '',
			email_proposer: '',
			father_first_name: '',
			father_last_name: '',
			father_middle_name: '',
			first_name: '',
			gender: '',
			last_name: '',
			marital_status: '',
			middle_name: '',
			mobile_no: '',
			mother_first_name: '',
			mother_last_name: '',
			mother_middle_name: '',
			pan_no: '',
			permanent_city: '',
			permanent_landmark: '',
			permanent_line1: '',
			permanent_line2: '',
			permanent_pincode: '',
			permanent_state: '',
			spouse_first_name: '',
			spouse_last_name: '',
			spouse_middle_name: '',
		},
	});

	useEffect(() => {
		getGenderAndMaritalDropDown();
	}, []);

	const getGenderAndMaritalDropDown = async () => {
		await dispatch(apiGenderDropdown())
			.then(unwrapResult)
			.then((result: any) => {
				setGenderApi(result.data);
			});
		await dispatch(apiMaritalDropdown())
			.then(unwrapResult)
			.then((result: any) => {
				setMaritalApi(result.data);
			});
	};

	function calculate_age(dob: any) {
		const arr = dob.split('-');
		const d = new Date(parseInt(arr[0]), parseInt(arr[1]), parseInt(arr[2]));
		const diff_ms = Date.now() - d.getTime();
		const age_dt = new Date(diff_ms);

		setValue('age', Math.abs(age_dt.getUTCFullYear() - 1970));
	}

	const onSubmit = async (data: any) => {
		data['permanent_state'] = permanentPinCodeValue.state || '';
		data['permanent_city'] = permanentPinCodeValue.city || '';
		data['current_state'] = currentPinCodeValue.state || '';
		data['current_city'] = currentPinCodeValue.city || '';

		await dispatch(
			(leadFlowData.leadCurrentStep === 'step3' ||
				leadFlowData.leadCurrentStep === 'step4') &&
				leadFlowData.productFor != 'self'
				? apiUpdateLeadDetails({
						param: leadFlowData?.leadId,
						data: {
							loginUserId: userData?.id,
							leadId: leadFlowData?.leadId,
							product: leadFlowData?.productType,
							first_name: data?.first_name,
							middle_name: data?.middle_name,
							last_name: data?.last_name,
							dob: data?.dob,
							age: data?.age,
							gender: data?.gender,

							mobile_no: data?.mobile_no,
							father_first_name: data?.father_first_name,
							father_middle_name: data?.father_middle_name,
							father_last_name: data?.father_last_name,
							mother_first_name: data?.mother_first_name,
							mother_middle_name: data?.mother_middle_name,
							mother_last_name: data?.mother_last_name,
							marital_status: data?.marital_status,
							spouse_first_name: data?.spouse_first_name,
							spouse_middle_name: data?.spouse_middle_name,
							spouse_last_name: data?.spouse_last_name,
							email_assured: data?.email_proposer,
							permanent_line1: data?.permanent_line1,
							permanent_line2: data?.permanent_line2,
							permanent_landmark: data?.permanent_landmark,
							permanent_pincode: data?.permanent_pincode,
							permanent_city: data?.permanent_city,
							permanent_state: data?.permanent_state,
							address_same: !data?.same_address_check,
							current_line1: !sameAddressCheck
								? data?.current_line1
								: data?.permanent_line1,
							current_line2: !sameAddressCheck
								? data?.current_line2
								: data?.permanent_line1,
							current_address: !sameAddressCheck
								? data?.current_line1
								: data?.permanent_line1,
							current_pincode: !sameAddressCheck
								? data?.current_pincode
								: data?.permanent_pincode,
							current_landmark: !sameAddressCheck
								? data?.current_landmark
								: data?.permanent_landmark,
							current_city: !sameAddressCheck
								? data?.current_city
								: data?.permanent_city,
							current_state: !sameAddressCheck
								? data?.current_state
								: data?.permanent_state,
						},
				  })
				: lifeAssuredApi({
						data: {
							leadId: leadFlowData?.leadId,
							product: leadFlowData?.productType,
							first_name: data?.first_name,
							middle_name: data?.middle_name,
							last_name: data?.last_name,
							marital_status: data?.marital_status,
							dob: data?.dob,
							age: data?.age,
							gender: data?.gender,
							mobile_no: data?.mobile_no,
							father_first_name: data?.father_first_name,
							father_middle_name: data?.father_middle_name,
							father_last_name: data?.father_last_name,
							mother_first_name: data?.mother_first_name,
							mother_middle_name: data?.mother_middle_name,
							mother_last_name: data?.mother_last_name,
							spouse_first_name: data?.spouse_first_name,
							spouse_middle_name: data?.spouse_middle_name,
							spouse_last_name: data?.spouse_last_name,
							email_assured: data?.email_proposer,
							permanent_line1: data?.permanent_line1,
							permanent_line2: data?.permanent_line2,
							permanent_landmark: data?.permanent_landmark,
							permanent_pincode: data?.permanent_pincode,
							permanent_city: data?.permanent_city,
							permanent_state: data?.permanent_state,
							address_same: !data?.same_address_check,
							current_line1: !sameAddressCheck
								? data?.current_line1
								: data?.permanent_line1,
							current_line2: !sameAddressCheck
								? data?.current_line2
								: data?.permanent_line1,
							current_address: !sameAddressCheck
								? data?.current_line1
								: data?.permanent_line1,
							current_landmark: !sameAddressCheck
								? data?.current_landmark
								: data?.permanent_landmark,
							current_pincode: !sameAddressCheck
								? data?.current_pincode
								: data?.permanent_pincode,
							current_city: !sameAddressCheck
								? data?.current_city
								: data?.permanent_city,
							current_state: !sameAddressCheck
								? data?.current_state
								: data?.permanent_state,
						},
				  })
		)
			.then(unwrapResult)
			.then(async (result: any) => {
				if (result?.status) {
					leadFlowData.leadCurrentStep === 'step3'
						? await dispatch(
								leadFlow({
									...leadFlowSelector?.leadFlowData,
									leadCurrentStep: 'step4',
								})
						  )
						: await dispatch(
								leadFlow({
									...leadFlowSelector?.leadFlowData,
									leadCurrentStep: 'step6',
								})
						  );
				}
			});
	};

	const pinCodeAction = async (name: any, value: any) => {
		setPinCodeData([]);
		await dispatch(
			contributionPinCode({
				data: {
					pincode: value,
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					if (!result?.list) {
						showToastAlert({
							type: 'error',
							message: 'Please Check Pin Code No.',
						});
						setPinCodeData([]);
					} else {
						setPinCodeData((data: any) => ({
							...data,
							[name]: {
								city: result?.list,
							},
						}));
					}
				}
			});
	};

	type FieldHeadings = {
		[key: string]: string;
	};

	const fieldHeadings: FieldHeadings = {
		current_line1: 'Current Address',
		permanent_line1: 'Permanent Address',
		father_first_name: 'Father Name',
		mother_first_name: 'Mother Name',
		spouse_first_name: 'Spouse Name',
	};

	return (
		<>
			<IonContent className='ion-padding insurance-lead-background'>
				<div className=' very-small-container bg-web h-90'>
					<div className='bg-web-white container-width-md p-md-5 border-radius-10'>
						<form onSubmit={handleSubmit(onSubmit)}>
							{leadFlowData.leadCurrentStep === 'step3' ? (
								<h4 className='fw-bold fs-14'>{t('Name of Proposer')}</h4>
							) : (
								<h4 className='fw-bold fs-14'>{t('Name of Life Assured')}</h4>
							)}
							{leadStepInfoFormInputs.map(
								({ name, type, placeholder }: any) => {
									const errorText: any =
										errors && Object.keys(errors).length > 0 && errors;

									const heading = fieldHeadings[name];
									return (
										<>
											{sameAddressCheck && name.includes('current') ? (
												<></>
											) : (
												heading && <h4 className='fw-bold fs-14'>{heading}</h4>
											)}
											{type === 'dropdown' ? (
												<div style={{ marginBottom: '15px' }}>
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<DropDownModal
																label={t(`${placeholder}`)}
																data={
																	name === 'gender'
																		? genderApi
																		: name === 'marital_status' && maritalApi
																}
																name={name}
																onChange={onChange}
																value={getValues(name)}
																onBlur={onBlur}
																errors={errorText[name]?.message}
															/>
														)}
														name={name}
													/>
												</div>
											) : name === 'permanent_pincode' ||
											  name === 'current_pincode' ? (
												sameAddressCheck && name.includes('current') ? (
													<></>
												) : (
													<>
														<Controller
															control={control}
															render={({ field: { onChange, onBlur } }) => (
																<CustomInput
																	type={type}
																	placeholder={placeholder}
																	onBlur={onBlur}
																	onChange={(e: any) => {
																		if (
																			name === 'permanent_pincode' ||
																			name === 'current_pincode'
																		) {
																			if (e.detail.value.length === 6) {
																				pinCodeAction(name, e.detail.value);
																			}
																		}
																		onChange(e);
																	}}
																	value={getValues(name)}
																	errors={errorText[name]?.message}
																	onBeforeInput={(evt: any) => {
																		if (type == 'number') {
																			!/[0-9]/.test(evt.data) &&
																				evt.preventDefault();
																		}
																	}}
																	onKeyDown={(e: any) => {
																		if (type == 'number') {
																			disableNumberArrows(e);
																		}
																	}}
																	onWheel={(e: any) => {
																		if (type == 'number') {
																			e.target.blur();
																		}
																	}}
																/>
															)}
															name={name}
														/>

														{pinCodeData?.[name] &&
															pinCodeData?.[name]?.city.length > 0 && (
																<>
																	<IonLabel>{t('City')}</IonLabel>
																	<div className='ion-margin-bottom pb-2'>
																		{/*pinCodeData?.[name]?.city.map(
																			({ Name }: any) => {
																				return (
																					<span
																						className={`selected-label selected-label-cursor ${
																							Name ===
																								permanentPinCodeValue.city ||
																							Name === currentPinCodeValue.city
																								? ' selected-label-active'
																								: ''
																						}`}
																						onClick={() => {
																							name === 'permanent_pincode' &&
																								setPermanentPincodeValue({
																									state:
																										pinCodeData?.[name]?.city[0]
																											?.State,
																									city: Name,
																								});

																							name === 'current_pincode' &&
																								setCurrentPincodeValue({
																									state:
																										pinCodeData?.[name]?.city[0]
																											?.State,
																									city: Name,
																								});
																						}}
																					>
																						{Name}
																					</span>
																				);
																			}
																		)*/}
																		<span
																			key={pinCodeData?.[name]?.city[0].Region}
																			className={`selected-label selected-label-cursor ${
																				pinCodeData?.[name]?.city[0].Region ===
																					permanentPinCodeValue.city ||
																				pinCodeData?.[name]?.city[0].Region ===
																					currentPinCodeValue.city
																					? ' selected-label-active'
																					: ''
																			}`}
																			onClick={() => {
																				name === 'permanent_pincode' &&
																					setPermanentPincodeValue({
																						state:
																							pinCodeData?.[name]?.city[0]
																								?.State,
																						city: pinCodeData?.[name]?.city[0]
																							.Region,
																					});

																				name === 'current_pincode' &&
																					setCurrentPincodeValue({
																						state:
																							pinCodeData?.[name]?.city[0]
																								?.State,
																						city: pinCodeData?.[name]?.city[0]
																							.Region,
																					});
																			}}
																		>
																			{pinCodeData?.[name]?.city[0].Region}
																		</span>
																	</div>
																</>
															)}
														{pinCodeData?.[name] &&
															pinCodeData?.[name]?.city.length > 0 && (
																<>
																	<IonLabel>{t('State')}</IonLabel>
																	<div className='ion-margin-bottom'>
																		<span
																			className='selected-label'
																			onChange={() => {
																				name === 'permanent_pincode' &&
																					setValue(
																						'permanent_state',
																						pinCodeData?.[name]?.city[0]?.State
																					);
																				name === 'current_pincode' &&
																					setValue(
																						'current_state',
																						pinCodeData?.[name]?.city[0]?.State
																					);
																			}}
																		>
																			{pinCodeData?.[name]?.city[0]?.State}
																		</span>
																	</div>
																</>
															)}
													</>
												)
											) : name === 'permanent_state' ? (
												<Controller
													control={control}
													render={({ field: { onChange, onBlur, name } }) => (
														<CustomCheckbox
															name={name}
															// isBackGround={true}
															labelPlacement='end'
															// isChecked={getValues('same_address_check')}
															isChecked={sameAddressCheck}
															value={sameAddressCheck}
															// value={getValues('same_address_check')}
															onBlur={onBlur}
															onChange={(event: any) => {
																onChange(event);
																setSameAddressCheck((data) => !data);
																// setValue('same_address_check', event.detail.checked);
															}}
															className={'fs-14 heading-color pb-3'}
															errorText={errors[name]?.message}
														>
															<IonLabel className='text-wrap'>
																{t(
																	`Current Address is same as Permanent Address`
																)}
															</IonLabel>
														</CustomCheckbox>
													)}
													name={'same_address_check'}
												/>
											) : sameAddressCheck && name.includes('current') ? (
												<></>
											) : (
												name !== 'permanent_city' &&
												name !== 'permanent_state' &&
												name !== 'current_city' &&
												name !== 'current_state' && (
													<>
														<Controller
															control={control}
															render={({
																field: { onChange, onBlur, name },
															}) => (
																<CustomInput
																	type={type}
																	label={t(placeholder)}
																	onBlur={onBlur}
																	onChange={(e: any) => {
																		name === 'dob'
																			? (calculate_age(e.target.value),
																			  setValue('dob', e.target.value))
																			: onChange(e);
																	}}
																	// onChange={onChange}
																	value={
																		name === 'pan_no'
																			? getValues('pan_no').toUpperCase()
																			: getValues(name)
																	}
																	// defaultValue={watch(name)}
																	// notRequiredValue={name !== 'pan_no'}
																	errors={errorText[name]?.message}
																	disabled={name === 'age' ? true : false}
																	onBeforeInput={(evt: any) => {
																		if (type == 'number') {
																			!/[0-9]/.test(evt.data) &&
																				evt.preventDefault();
																		}
																		if (name.toLowerCase().includes('mobile')) {
																			if (evt.target.value.length === 10) {
																				evt.preventDefault();
																			}
																		}
																	}}
																	onPaste={(e: any) => {
																		//Only get numbers on user paste
																		if (name.toLowerCase().includes('mobile')) {
																			e.preventDefault();
																			let pasteContent =
																				e.clipboardData.getData('Text');
																			if (pasteContent != '') {
																				pasteContent = pasteContent.replace(
																					/\D/g,
																					''
																				);
																				if (pasteContent.length > 10) {
																					pasteContent = pasteContent.slice(
																						0,
																						10
																					);
																				}
																			}
																			setValue(name, pasteContent);
																			trigger(name);
																		}
																	}}
																	onKeyDown={(e: any) => {
																		if (type == 'number') {
																			disableNumberArrows(e);
																		}
																	}}
																	onWheel={(e: any) => {
																		if (type == 'number') {
																			e.target.blur();
																		}
																	}}
																/>
															)}
															name={name}
														/>

														{name === 'permanent_state' && (
															<Controller
																control={control}
																render={({
																	field: { onChange, onBlur, name },
																}) => (
																	<CustomCheckbox
																		name={name}
																		// isBackGround={true}
																		labelPlacement='end'
																		isChecked={getValues('same_address_check')}
																		value={getValues('same_address_check')}
																		onBlur={onBlur}
																		onChange={(event: any) => {
																			onChange(event);
																			setValue(
																				'same_address_check',
																				event.detail.checked
																			);
																		}}
																		className={'fs-14 heading-color pb-3'}
																		errorText={errors[name]?.message}
																	>
																		<IonLabel className='text-wrap'>
																			{t(
																				`Current Address is same as Permanent Address`
																			)}
																		</IonLabel>
																	</CustomCheckbox>
																)}
																name={'same_address_check'}
															/>
														)}
													</>
												)
											)}
										</>
									);
								}
							)}
							<div
								className={`ion-padding d-none d-md-block`}
								style={{
									backgroundColor: '#fff',
									zIndex: 2,
								}}
							>
								<CustomButton
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='full'
									fill='outline'
									title={t('Continue Later')}
									onClick={() => setModalPageClose(true)}
								/>

								<div className='proceed-btn'>
									<CustomButton
										type='submit'
										className='w-100 fw-bold dashboard-add-lead-button'
										expand='full'
										title={t('Proceed')}
										disabled={!isValid}
										onClick={handleSubmit(onSubmit)}
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
				{/* </div> */}
			</IonContent>

			<IonFooter>
				<div
					className={`ion-padding d-block d-md-none`}
					style={{
						backgroundColor: '#fff',
						zIndex: 2,
					}}
				>
					{leadFlowData?.from !== 'customer' ? (
						<CustomButton
							className='w-100 fw-bold dashboard-add-lead-button'
							expand='full'
							fill='outline'
							title={t('Continue Later')}
							onClick={() => setModalPageClose(true)}
						/>
					) : (
						''
					)}

					<div className='proceed-btn'>
						<CustomButton
							type='submit'
							className='w-100 fw-bold dashboard-add-lead-button'
							expand='full'
							title={t('Proceed')}
							disabled={!isValid}
							onClick={handleSubmit(onSubmit)}
						/>
					</div>
				</div>
			</IonFooter>

			<CustomModal
				id='custommodal'
				isOpen={modalPageClose}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setModalPageClose(false)}
				breakpoints={[]}
				className='leadStep'
				modalContent={PageExitPopUp(
					() => {
						setModalPageClose(false);
					},
					() => {
						setModalPageClose(false);
						history.push('/dashboard/home');
					},
					'Cancel',
					'Continue Later',
					'lead-pop-up',
					'Are You Sure You Want to Exit?',
					`${t('You can continue later from where you left off.')}`
				)}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};

export default InsuranceLeadStepThree;
