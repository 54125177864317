import { SmsRetriever } from '@awesome-cordova-plugins/sms-retriever';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ConsentAuthContext } from '../../context/ConsentAuthContext';
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/react';
import { Otptimer } from 'otp-timer-ts';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import './OTPPage.scss';
import useApi from '../../api/useApi';
import {
	INSURANCE_CONSENT_GENERATE_OTP,
	INSURANCE_CONSENT_VERIFY_OTP,
	UPDATE_VERIFICATION_STATUS,
} from '../../../../helpers/api-endpoints';
import { showToastAlert } from '../../helpers/function';
import { ResendOtpRequest } from '../../models/resendOtpBeans';
import { useConsentBusyLoading } from '../../context/ConsentBusyLoadingContext';
import { VerifyOtpRequest } from '../../models/verifyOtpBeans';
import { useHistory } from 'react-router';
interface OtpVerificationInterface {
	length?: number;
	mobile?: string;
}

export const OTPPage: React.FC<OtpVerificationInterface> = ({ length = 4 }) => {
	const { consentApplicationId, consentMobile , consentToken } = useContext(ConsentAuthContext);
	const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
	const [otp, setOtp] = useState<string[]>(new Array(4).fill(''));
	const [error, setError] = useState<boolean>(false);
	const [nextButtonActive, setNextButtonActive] = useState<boolean>(true);
	const [valid, setValidOtp] = useState<boolean>(false);
	const history = useHistory();
	const generateOtpAPi = useApi<any>(INSURANCE_CONSENT_GENERATE_OTP);
	const verifyOtpApi = useApi<any>(`${INSURANCE_CONSENT_VERIFY_OTP}/${consentMobile}`);
	const updateStatusApi = useApi<any>(UPDATE_VERIFICATION_STATUS);
	const { setBusyStateConsent } = useConsentBusyLoading();

	useEffect(() => {
		setBusyStateConsent(generateOtpAPi?.loading);
	  }, [generateOtpAPi?.loading, setBusyStateConsent]);

	 useEffect(() => {
		setBusyStateConsent(verifyOtpApi?.loading);
	  }, [verifyOtpApi?.loading, setBusyStateConsent]);

	const handleChange = async (element: any | undefined, index: number) => {
		if (isNaN(element.value)) return false;
		setOtp([
			...otp.map((d: any, idx: any) => (idx === index ? element.value : d)),
		]);
		if (index <= 3) {
			element.value && inputRefs.current[index + 1]?.focus();
		}
		if (index >= 0) {
			setError(false);
		}
	};

	const handleKeyDown = (index: number, e: any) => {
		if (e.key === 'Backspace' && index > 0) {
			e.target.value == '' && inputRefs.current[index - 1]?.focus();
		}
		if (otp.length === 3) {
			setNextButtonActive(false);
		} else {
			setNextButtonActive(true);
		}
	};

	useEffect(() => {
		SmsRetriever.startWatching()
			.then((res: any) => {
				if (res?.Message) {
					const otpregex = new RegExp(/^[0-9]{4}$/);
					const msgArray = res.Message.split(' ');
					let getOtp: any;
					msgArray.forEach((i: any) => {
						if (otpregex.test(i)) {
							getOtp = i;
						}
					});
					const otpArray = getOtp.split('').map((i: any) => i);
					setOtp(otpArray);
				}
			})
			.catch((error: any) => console.error(error));
	}, []);

	const validOtp =
		otp[0] !== '' &&
		otp[1] !== '' &&
		otp[2] !== '' &&
		otp[3] !== '' &&
		!valid &&
		error;

	// const updateStatu = useCallback(() => {
	// 		try {
	// 		  const updateStatusData = {
	// 			applicationId: consentApplicationId,
	// 			statusId: 4,
	// 			veribymoboremail: "sms",
	// 		  };
	// 		  updateStatusApi.executeRequest("put", updateStatusData, true);
	// 		} catch (error) {
	// 		  showToastAlert({ type: "error", message: updateStatusApi?.error ? updateStatusApi?.error.message : "" });
	// 		}
	// 	  }, [updateStatusApi, consentApplicationId]);

	const updateStatus = async() =>{
		try {
			const updateStatusData = {
			  applicationId: consentApplicationId,
			  statusId: 4,
			  veribymoboremail: "sms",
			};
			await updateStatusApi.executeRequest("put", updateStatusData, true);
		  } catch (error) {
			showToastAlert({ type: "error", message: updateStatusApi?.error ? updateStatusApi?.error.message : "" });
		  }
	}
		

	const resendOtp = async () => {
		try {
			const resendOtpRequest = new ResendOtpRequest();
			resendOtpRequest.mobile = consentMobile;
			const otpData = resendOtpRequest;
			await generateOtpAPi.executeRequest('post', otpData, true, false);
			inputRefs.current[0]?.focus();
			setOtp(new Array(4).fill(''));
			showToastAlert({
				type: 'success',
				message: 'An OTP has been sent to your mobile number.',
			});
		} catch (error) {
			console.log(error)
			showToastAlert({
				type: 'error',
				message: generateOtpAPi?.error?.message,
			});
		}
	};


	useEffect(() => {
		if (verifyOtpApi?.responseData?.status_code === 200) {
		  updateStatus();
		  showToastAlert({ type: "success", message: verifyOtpApi?.responseData?.message });
		  history.push("/success");
		} else {
		  showToastAlert({ type: "error", message: verifyOtpApi?.error?.message });
		}
	  }, [verifyOtpApi?.responseData])

	//   console.log('consentApplicationId otppage', consentApplicationId)
	//   console.log('consentMobile otppage', consentMobile)

	const verifyLeadOtp = async () => {
		try {
			const otpString = otp.join("");
			const verifyOtpRequest = new VerifyOtpRequest();
			verifyOtpRequest.product_id = "2";
			verifyOtpRequest.sub_product_id = "Insurance";
			verifyOtpRequest.unique_id = consentApplicationId;
			verifyOtpRequest.mobile = consentMobile;
			verifyOtpRequest.otp = otpString;
			verifyOtpRequest.cust_yn = 1;
			const otpData = verifyOtpRequest;
			verifyOtpApi.executeRequest("post", otpData, true, false);
			if (verifyOtpApi?.responseData?.status_code === 200) {
				setValidOtp(true);
			    setOtp(new Array(4).fill(""));
				showToastAlert({
					type: 'success',
					message: verifyOtpApi?.responseData?.message,
				});
			} else {
			  setValidOtp(false);
			  setOtp(new Array(4).fill(""));
			  showToastAlert({
				type: 'error',
				message: verifyOtpApi?.error?.message,
			});
			}
		  } catch (error) {
			console.error(error);
		  }
	};
	const isButtonDisabled = otp.some((value) => value === "");

	return (
		<IonPage className='bg-web-white'>
			{/* <IonHeader collapse='condense'>
					<IonToolbar>
						<IonTitle size='large'>OTP Verification</IonTitle>
					</IonToolbar>
				</IonHeader> */}
			<IonContent className='otp-verification'>
				<div className='select-product small-container h-100 bg-web'>
					<div className='bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
						<IonGrid>
							<IonRow>
								<IonCol>
									<div className='mt-4 mt-md-0'>
										<h2 className='ion-no-margin fw-600 fs-22'>
											We have sent you an OTP
										</h2>
										<div className='ion-no-margin description'>
											<span className='text-grey'>
												Please enter the OTP sent on your
												<br />
												Mobile No. +91 XXXXXX
												{String(consentMobile)?.slice(6)}.
											</span>
										</div>
									</div>
								</IonCol>
							</IonRow>
						</IonGrid>
						<IonGrid className='otp-input-box'>
							<IonRow>
								{[...Array(length)].map((item, index) => (
									<IonCol
										size='3'
										key={index}
									>
										<input
											inputMode='numeric'
											pattern='[0-9]*'
											max='1'
											id={`otp${index}`}
											ref={(ref) => (inputRefs.current[index] = ref)}
											value={otp[index] || ''}
											onChange={(e) => handleChange(e.target, index)}
											onKeyDown={(e) => handleKeyDown(index, e)}
											maxLength={1}
											className={`form-control text-center ${
												valid ? 'input-invalid' : validOtp && 'input-valid'
											}`}
											// className={"form-control text-center"}
											required={error}
										/>
									</IonCol>
								))}
							</IonRow>
							{error && (
								<p className='error fs-12 text-danger ms-2 mb-0'>
									Please Enter OTP
								</p>
							)}
							{valid && (
								<p className='error fs-12 text-danger ms-2 mb-0'>
									Please Enter Valid OTP
								</p>
							)}
						</IonGrid>
						<div className={'pt-2'}>
							<CustomButton
								title='Verify'
								expand='block'
								onClick={verifyLeadOtp}
								disabled={isButtonDisabled}
							/>

							<div className='ion-no-margin pt-3'>
								Didn’t receive OTP?{' '}
								<div className='d-inline-block'>
									<Otptimer
										minutes={0}
										seconds={59}
										onResend={resendOtp} // Call the resend OTP function
										text='Resend OTP in'
										buttonText='Resend OTP'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};
