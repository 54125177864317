import {
	IonCol,
	IonContent,
	IonFooter,
	IonGrid,
	IonItemDivider,
	IonLabel,
	IonRange,
	IonRow,
	IonSpinner,
	IonText,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router';
import {
	saveLenderSpcfInfo,
	lenderOfferAccept,
	lenderGetOffer,
	lenderSpfDetailsFlow,
} from '../../../store/reducers/lead/leadSlice';
import {
	ContinueLaterPopUp,
	checkFieldsValidToContinue,
	converRuppesFormat,
	showToastAlert,
} from '../../../helpers/functions';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { ABFLOfferInfoSchema, OfferInfo } from './ABFLInfo';
import { LenderNameForAPICall } from '../../../helpers/lenders';
import {
	CustomModalLeadStatus,
	DynamicDropdown,
} from '../../../components/common';
import { OfferRejectedMsg } from '..';
import './ABFLForm.scss';

export const ABFLOfferInfoForm: React.FC<any> = () => {
	const [spinner, setSpinner] = useState(false);
	const [abflOffers, setABFLOffer]: any = useState({
		offerID: '',
		slabs: [
			{
				maxAmount: null,
				minAmount: null,
				tenure: null,
				processingFee: null,
				interest: null,
				gst: null,
			},
		],
	});
	console.log(setSpinner);

	const [selectedOffer, setSelectedOffer] = useState({
		id: 0,
		maxAmount: 0,
		minAmount: 0,
		tenure: 0,
		processingFee: 0,
		interest: 0,
		amount: 0,
		gst: 0,
	});
	const [modalPageClose, setModalPageClose] = useState(false);
	const [leadStatusModalOpen, setLeadStatusModalOpen] = useState(false);
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const [filterAmount, setFilterAmount] = useState<any>();
	const [applicationNo, setApplicationNo] = useState(null);
	const history = useHistory();
	const dispatch = useDispatch();
	const { selectedLender, lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);

	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		trigger,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(ABFLOfferInfoSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			offer_id: null || 0,
			amount: null,
		},
	});

	useEffect(() => {
		if (abflOffers?.slabs[0].id) {
			setValue('offer_id', abflOffers?.slabs[abflOffers?.slabs?.length - 1].id);
			getTenureDetailsByAmount(
				abflOffers?.slabs[abflOffers?.slabs?.length - 1],
				abflOffers?.slabs[abflOffers?.slabs?.length - 1].maxAmount
			);
		}
	}, [abflOffers]);

	/**
	 * Get offer based on customer ID
	 */
	useEffect(() => {
		if (!location.pathname.includes('/lead-lender-specific-form-steps')) {
			return;
		}
		getOffers();
	}, [location.pathname]);

	/**
	 * Get offers
	 * Get Offer API will be called if there are not offers data in redux state
	 */
	const getOffers = async () => {
		if (lenderSpecificDetails?.offers) {
			setABFLOffer({
				offerID: lenderSpecificDetails?.offers?.offers[0]?.offerID,
				slabs: lenderSpecificDetails?.offers?.offers[0]?.slabs,
			});
			setDataTenureWise({
				offerID: lenderSpecificDetails?.offers?.offers[0]?.offerID,
				slabs: lenderSpecificDetails?.offers?.offers[0]?.slabs,
			});
		} else {
			const req = {
				lead_spf_detail_id: lenderSpecificDetails.lead_spf_detail_id,
				lender_name: LenderNameForAPICall.ABFL,
			};
			setShowOfferErrMsg('');
			await dispatch(lenderGetOffer({ data: req }))
				.then(unwrapResult)
				.then((result: any) => {
					if (result?.status) {
						const { data } = result.data;
						setABFLOffer({
							offerID: data?.offers[0]?.offerID,
							slabs: data?.offers[0]?.slabs,
						});
						setDataTenureWise({
							offerID: data?.offers[0]?.offerID,
							slabs: data?.offers[0]?.slabs,
						});
						dispatch(
							lenderSpfDetailsFlow({
								...lenderSpecificDetails,
								offers: data,
							})
						);
					} else {
						setShowOfferErrMsg(result?.message);
					}
				});
		}
	};

	const setDataTenureWise = (params: any) => {
		const tenureList: any = [];
		const newObj: any = [];
		for (let i = 0; i < params?.slabs.length; i++) {
			if (!tenureList.includes(params?.slabs[i].tenure)) {
				tenureList.push(params?.slabs[i].tenure);
			}
		}

		tenureList.forEach((tenureData: any, i: number) => {
			const specificTenure = params?.slabs.filter((fullData: any) => {
				return fullData.tenure == tenureData;
			});

			const minAmount = Math.min(
				...specificTenure.map((st: any) => st.minAmount)
			);
			const maxAmount = Math.max(
				...specificTenure.map((st: any) => st.maxAmount)
			);
			newObj.push({
				id: i + 1,
				minAmount,
				maxAmount,
				tenure: tenureData,
				tenureDetails: specificTenure,
			});
		});
		setABFLOffer({
			offerID: params.offerID,
			slabs: newObj,
		});
	};

	/**
	 * Select Offer
	 * @param e change event
	 */
	const _selectedOffer = (e: any) => {
		const { slabs } = abflOffers;
		slabs.forEach((el: any) => {
			if (el.id === e.detail.value) {
				getTenureDetailsByAmount(el, el.maxAmount);
			}
		});
	};

	const onSliderChange = (e: any) => {
		getTenureDetailsByAmount(selectedOffer, e.detail.value);
	};

	const getTenureDetailsByAmount = (el: any, amount: any) => {
		el.tenureDetails.forEach((ele: any) => {
			if (ele.minAmount <= amount && ele.maxAmount >= amount) {
				setFilterAmount({
					interest: ele.interest,
					gst: ele.gst,
					processingFee: ele.processingFee,
					tenure: ele.tenure,
				});
			}
		});
		setSelectedOffer({
			...el,
			amount: amount,
		});
		setValue('amount', amount);
		trigger('amount');
	};

	/**
	 * Set common req params
	 * @param data form value
	 */
	const setCommonReq = (data: any) => {
		const req = {
			offerID: abflOffers.offerID,
			amount: Number(data.amount),
			tenure: filterAmount.tenure,
			interest: filterAmount.interest,
			processingFee: filterAmount.processingFee,
		};
		return req;
	};

	/**
	 * On submit to save accepted offer details
	 * @param data form value
	 */
	const onSubmit = async (data: any, flag: any = '') => {
		const req: any = {
			lead_spf_detail_id: lenderSpecificDetails.lead_spf_detail_id,
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.ABFL,
			customerID: lenderSpecificDetails.lender_lead_id,
			lender_spf_details: {
				...lenderSpecificDetails?.lender_spf_details,
				offerDetails: {},
			},
			step: 'step3',
		};

		if (isValid) {
			req.lender_spf_details.offerDetails = setCommonReq(data);
		}

		await dispatch(saveLenderSpcfInfo({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					if (isValid && flag !== 'continue_later') {
						acceptOffer(data);
					}
				}
			});
	};

	/**
	 * Accept offer
	 * @param data form values
	 */
	const acceptOffer = async (data: any) => {
		const req = {
			customerID: lenderSpecificDetails.lender_lead_id,
			lead_spf_detail_id: lenderSpecificDetails.lead_spf_detail_id,
			lender_name: LenderNameForAPICall.ABFL,
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			offerDetails: setCommonReq(data),
			redirectURL: `${location.origin}/dashboard/home`,
		};
		await dispatch(lenderOfferAccept({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					const { data } = result.data;
					const appNo: any = `
							Application ID: ${result?.data?.andro_id}\n
							ABFL Journey URL: ${data.url}
						`;
					setApplicationNo(appNo);
					if (data && data?.url) {
						setLeadStatusModalOpen(true);
					}
				} else {
					setShowOfferErrMsg(result?.message);
				}
			});
	};

	/**
	 * On close of lead status modal redirect to external URL
	 */
	const onLeadStatusModalClose = () => {
		setLeadStatusModalOpen(false);
	};

	/**
	 * Calculate final amount after minus the GST and Processing Fees
	 */
	const _calculateFinalAmount = () => {
		const processingFeeCalculate = Math.round(
			(filterAmount?.processingFee / 100) * selectedOffer.amount
		);
		const gstCalculate = Math.round(
			(filterAmount?.gst / 100) * processingFeeCalculate
		);
		return selectedOffer.amount - (processingFeeCalculate + gstCalculate);
	};

	/**
	 * Calculate EMI amount
	 * @param loanAmount Loan Amount
	 * @param annualInterestRate Yearly interest rate
	 * @param tenureInMonth Tenure in month
	 */
	const calculateEMI = (
		loanAmount: any,
		annualInterestRate: any,
		tenureInMonth: any
	) => {
		const monthlyInterestRate = annualInterestRate / 12 / 100;
		const monthlyPayment = Math.round(
			(loanAmount *
				monthlyInterestRate *
				Math.pow(1 + monthlyInterestRate, tenureInMonth)) /
				(Math.pow(1 + monthlyInterestRate, tenureInMonth) - 1)
		);
		return monthlyPayment;
	};

	/**
	 * Continue Later and Submit button
	 */
	const continueLaterAndSubmitBtn = () => {
		return (
			<>
				<CustomButton
					className='w-100 fw-bold dashboard-add-lead-button mb-3'
					expand='block'
					fill='outline'
					title={t('Continue Later')}
					onClick={() => {
						if (checkFieldsValidToContinue(errors)) {
							setModalPageClose(true);
						} else {
							showToastAlert({
								type: 'error',
								message: 'Please fix above errors',
							});
						}
					}}
				/>
				<CustomButton
					type='submit'
					className='w-100 fw-bold dashboard-add-lead-button'
					expand='block'
					title={t('Proceed')}
					disabled={!isValid}
					onClick={handleSubmit(onSubmit)}
				/>
			</>
		);
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
							<IonItemDivider className='offer_label'>
								<IonLabel>Loan Offer</IonLabel>
							</IonItemDivider>
							<form onSubmit={handleSubmit(onSubmit)}>
								{selectedOffer.tenure > 0 && (
									<>
										<div className='selected_amount'>
											<h6>You have an Offer of</h6>
											<h5>₹ {converRuppesFormat(selectedOffer.amount)}</h5>
										</div>

										{selectedOffer.minAmount !== selectedOffer.maxAmount && (
											<IonRange
												min={selectedOffer.minAmount}
												max={selectedOffer.maxAmount}
												step={1000}
												value={getValues('amount') || selectedOffer.maxAmount}
												aria-label='Volume'
												onIonInput={(e: any) => {
													onSliderChange(e);
												}}
											>
												<IonText slot='start'>
													{selectedOffer?.minAmount}
												</IonText>
												<IonText slot='end'>{selectedOffer?.maxAmount}</IonText>
											</IonRange>
										)}
									</>
								)}

								<IonGrid className='tenure_grid'>
									<IonRow className='dropdown_emi'>
										<IonCol>
											{OfferInfo.map(
												({ name, type, placeholder }: any, index: any) => {
													const errorText: any =
														errors && Object.keys(errors).length > 0 && errors;
													return (
														<div
															key={index}
															className='mt-4'
														>
															{type === 'dropdown' ? (
																<div style={{ marginBottom: '15px' }}>
																	<Controller
																		control={control}
																		render={({
																			field: { onChange, onBlur, name },
																		}) => {
																			return (
																				<DynamicDropdown
																					label={t(`${placeholder}`)}
																					data={abflOffers.slabs}
																					name={name}
																					bindField={'id'}
																					showField1={'tenure'}
																					showFieldLabel1={'Months'}
																					onChange={(e: any) => {
																						_selectedOffer(e);
																						onChange(e);
																					}}
																					value={getValues(name)}
																					onBlur={onBlur}
																					errors={errorText[name]?.message}
																				/>
																			);
																		}}
																		name={name}
																	/>
																</div>
															) : (
																''
															)}
														</div>
													);
												}
											)}
										</IonCol>
										<IonCol className='interest_pa'>
											<div>
												<p>EMI</p>
												<h5>
													₹{' '}
													{calculateEMI(
														selectedOffer.amount,
														filterAmount?.interest,
														selectedOffer.tenure
													)}
												</h5>
												<h6>
													{selectedOffer.tenure > 0
														? filterAmount?.interest + '%'
														: ''}
												</h6>
												<small>
													{selectedOffer.tenure > 0 ? 'Interest p.a' : ''}
												</small>
											</div>
										</IonCol>
									</IonRow>
								</IonGrid>

								{selectedOffer.tenure > 0 && (
									<div>
										<p>
											<b>Disbursal amount breakdown</b>
										</p>

										<div>
											<IonGrid className='p-0'>
												<IonRow>
													<IonCol className='p-0'>Loan Amount</IonCol>
													<IonCol
														size='auto'
														className='p-0 text-end'
													>
														<div style={{ width: '150px' }}>
															₹ {Math.round(selectedOffer.amount)}
														</div>
													</IonCol>
												</IonRow>
												<IonRow className='pt-2'>
													<IonCol className='p-0'>
														{t('Processing Fee')} {filterAmount?.processingFee}%
													</IonCol>
													<IonCol
														size='auto'
														className='p-0 text-end'
													>
														<div style={{ width: '150px' }}>
															- ₹{' '}
															{Math.round(
																(filterAmount?.processingFee / 100) *
																	selectedOffer.amount
															)}
														</div>
													</IonCol>
												</IonRow>

												<IonRow className='pt-2'>
													<IonCol className='p-0'>
														GST {filterAmount?.gst}%
													</IonCol>
													<IonCol
														size='auto'
														className='p-0 text-end'
													>
														<div style={{ width: '150px' }}>
															- ₹{' '}
															{Math.round(
																(filterAmount?.gst / 100) *
																	(filterAmount?.processingFee / 100) *
																	selectedOffer.amount
															)}
														</div>
													</IonCol>
												</IonRow>
											</IonGrid>
										</div>
										<p className='dotted_line'></p>
										<IonGrid>
											<IonRow className='pt-2'>
												<IonCol className='p-0'>
													<b>Total Disbursal Amount *</b>
												</IonCol>
												<IonCol
													size='auto'
													className='p-0 text-end'
												>
													<div style={{ width: '150px' }}>
														₹ {_calculateFinalAmount()}
													</div>
												</IonCol>
											</IonRow>
										</IonGrid>
									</div>
								)}
							</form>
							<div className={`d-none d-md-block mt-4`}>
								{continueLaterAndSubmitBtn()}
							</div>
						</div>
					</div>
				)}
			</IonContent>

			{!showOfferErrMsg && (
				<IonFooter className='d-block d-md-none position-relative ion-padding lead_ftr_btn'>
					{continueLaterAndSubmitBtn()}
				</IonFooter>
			)}

			<CustomModal
				needIonContentWraper={false}
				isOpen={modalPageClose}
				initialBreakpoint={undefined}
				handleClose={() => setModalPageClose(false)}
				breakpoints={[]}
				className='height-auto confirm-popup-middle continue-later'
				modalContent={ContinueLaterPopUp(
					() => {
						setModalPageClose(false);
					},
					() => {
						new Promise(function (myResolve) {
							setTimeout(function () {
								myResolve(history.push('/dashboard/lead'));
								onSubmit(getValues(), 'continue_later');
								setModalPageClose(false);
							}, 10);
						});
					},
					'Cancel',
					'Continue Later',
					'className',
					'Are You Sure You Want to Exit?',
					`${t('You can continue later from where you left off.')}`
				)}
				needCross={false}
				needArrow={false}
			/>
			{spinner && (
				<div
					className='position-fixed  w-100  d-flex justify-content-center align-items-center h-100'
					style={{
						top: '0px',
						left: '50%',
						transform: 'translate(-50%,0%)',
						backgroundColor: 'rgba(0,0,0,0.2)',
					}}
				>
					<IonSpinner name='lines-sharp' />
				</div>
			)}

			{leadStatusModalOpen && (
				<CustomModalLeadStatus
					isOpen={leadStatusModalOpen}
					applicationNo={applicationNo}
					handleClose={() => onLeadStatusModalClose()}
				/>
			)}
		</>
	);
};
