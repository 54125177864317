import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { IonCard, IonCardContent, IonCardHeader, IonCol } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { capFirstLetter } from '../../../../helpers/functions';

interface ITestimonialsDataKey {
	photo_url: string;
	name: string;
	message: string;
	status:string;
}
interface IPartnersTestimonials {
	data: ITestimonialsDataKey[];
}
const defaultSrc = '/images/icons/default/user-profile-icon.svg';
export const PartnersTestimonials: React.FC<IPartnersTestimonials> = ({
	data,
}) => {
	const { t } = useTranslation();
	const settings = {
		slidesToShow: 4.5,
		width: 500,
		dots: false,
		speed: 300,
		arrows: true,
		infinite: false,
		nextArrow: (
			<div>
				<div className='next-slick-arrow'>
					<img
						src='/images/icons/next scroller button.svg'
						alt='next slick arrow'
					/>
				</div>
			</div>
		),
		prevArrow: (
			<div>
				<div className='prev-slick-arrow'>
					<img
						src='/images/icons/previous scroller button.svg'
						alt='prev slick arrow'
					/>
				</div>
			</div>
		),
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					dots: false,
				},
			},
			{
				breakpoint: 992,
				settings: {
					width: 'auto',
					// arrows: false,
					slidesToShow: 3.5,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2.5,
					dots: true,
					width: 'auto',
					arrows: false,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1.6,
					dots: true,
					width: 'auto',
					arrows: false,
				},
			},
		],
	};
	return (
		<section
			// style={{ paddingRight: '20px', paddingLeft: '20px' }}
			className='border-md-radius-10 bg-web-white py-md-3 px-3 pe-md-0'
		>
			<div className='reviews'>
				<div className='row d-flex'>
					<div className='col-12 d-none d-md-block '>
						<h3 className='m-0 fs-16 pb-1 fw-600 darkest-grey'>
							{t('What Our Associates Say')}&nbsp;{t('About Us')}
						</h3>
					</div>
					<div className='col-12 d-block d-md-none'>
						<h3 className='m-0 fs-16 pb-1 fw-600 darkest-grey'>
							{t('What Our Associates Say')}
							<br />
							{t('About Us')}
						</h3>
					</div>
					<Slider
						{...settings}
						className='testimonials-swiper d-flex'
					>
						{data &&
							data.map((item: ITestimonialsDataKey, index: number) => {
								const src = item?.photo_url ? item.photo_url : defaultSrc;
								return parseInt(item?.status) === 1 && (
									<div
										key={index}
										className='quotes'
									>
										<IonCol className=' flex-fill'>
											<IonCard className='reviews-box p-3 d-flex flex-fill flex-column'>
												<img
													alt='qoutes icon'
													src={'/images/icons/quotes.png'}
													className={'qoutes-icon'}
												/>
												<IonCardContent className='ion-no-padding overflow-hidden mt-2 mb-3'>
													<p
														className='m-0 fs-12 darkest-grey testimonial-text-scroll-bar'
														style={{
															height: '100px',
															overflow: 'auto',
														}}
													>
														{item?.message}
													</p>
												</IonCardContent>
												<IonCardHeader
													className='ion-no-padding'
													style={{ background: 'transparent' }}
												>
													<div className='info d-flex align-items-center'>
														{/* <div
															className='avatar'
															style={{
																backgroundImage: item?.photo_url
																	? 'url("' + item.photo_url + '")'
																	: defaultSrc,
															}}
														></div>    */}
														<div style={{background: `url(${src}) no-repeat center fixed`,}} className='testimonial-card'>
															{/* <img
															src={src}
															alt={item?.name}
															style={{ width: '100%', height: '100%', objectFit: 'cover' }}
														/> */}
														</div>
														<h3 className='ps-2 fw-600 darkest-grey fs-12 m-0 text-start'>
															{capFirstLetter(item?.name)}
														</h3>
													</div>
												</IonCardHeader>
											</IonCard>
										</IonCol>
									</div>
								);
							})}
					</Slider>
				</div>
			</div>
		</section>
	);
};
