import { useHistory } from 'react-router';
import { FormatDate } from '../../helpers/functions';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { activityInfo } from '../../store/reducers/lead/leadSlice';

export const ActivitySection = (props: any) => {
	const history = useHistory();
	const { t } = useTranslation();
	const dispatch: any = useDispatch();

	const { activityList } = props;
	return (
		<div className='m-3 '>
			<h4 className='fw-bold fs-20'>{t('Activity')}</h4>
			{activityList?.length === 0 && (
				<div className='d-flex mt-4 justify-content-center align-items-center flex-column'>
					<img
						src='/images/icons/no-data-folder-icon.svg'
						alt='no data'
						width={54}
						height={48}
					/>
					<p className='fs-15 fw-500 mb-0 mt-3 text-grey'>
						{t('No activity added yet')}
					</p>
				</div>
			)}
			{activityList &&
				activityList.slice(0, 1)?.map((item: any, index: number) => {
					return (
						<div key={index}>
							<h5 className='fw-bold fs-16 pt-3'>{item?.message}</h5>
							<div>
								{item?.comment && (
									<p className='mb-0 fs-12 fw-500 text-grey position-relative'>
									<ul style={{ listStyle: 'none', paddingLeft: '15px' }}>
										<li style={{ position: 'relative' }}>
											<span style={{ position: 'absolute', left: '-1em' }}>-</span>
											{item?.comment}
										</li>
									</ul>
								</p>
								)}
							</div>
							<div>
								{item?.comments && (
									<>
										{item?.comments?.disposition && (
											<p className='mb-0 fs-12 fw-500 text-grey position-relative'>
												- {item?.comments?.disposition}
											</p>
										)}
										{item?.comments?.disposition_reason && (
											<p className='mb-0 fs-12 fw-500 text-grey position-relative'>
												- {item?.comments?.disposition_reason}
											</p>
										)}
										{item?.comments?.date && (
											<p className='mb-0 fs-12 fw-500 text-grey position-relative'>
												-
												{' ' +
													FormatDate(
														item?.comments?.date,
														'dd mmm yyyy hh:MM TT'
													)}
											</p>
										)}
										{item?.comments?.comment && (
											<p className='mb-0 fs-12 fw-500 text-grey position-relative'>
												- {item?.comments?.comment}
											</p>
										)}
									</>
								)}
							</div>
							<div
								className='mt-2 d-flex flex-row pb-3'
								style={{
									borderBottom:
										activityList?.length - 1 === index
											? ''
											: '1px solid #00000029',
								}}
							>
								<p className='mb-0 fs-12 fw-500 text-grey position-relative'>
									<span>By {item?.created_by_name}</span>
									<span className='mx-2'>•</span>
									<span>{FormatDate(item?.createdAt, 'dd mmm yyyy')}</span>
									<span className='mx-2'>•</span>
									<span>{FormatDate(item?.createdAt, 'hh:MM TT')}</span>
								</p>
							</div>
						</div>
					);
				})}

			{activityList?.length > 1 && (
				<div className='pb-3'>
					<h5
						onClick={() => {
							if (activityList?.length !== 0) {
								dispatch(activityInfo(activityList));
								history.push('/dma-activities');
							}
						}}
						className='mt-3 mb-0 fw-600 d-flex align-items-center fs-14 primary-text-color'
					>
						{' '}
						<span
							style={activityList?.length === 0 ? { opacity: '0.5' } : {}}
							className='cursor-pointer'
						>
							<img
								src='/images/icons/teams/activity.svg'
								className='me-2'
								alt='activity'
							/>
							{t('See all activities')}
						</span>
					</h5>
				</div>
			)}
		</div>
	);
};
