import {
	IonContent,
	IonFooter,
	IonImg,
	IonPage,
	isPlatform,
	useIonRouter,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { Header } from '../../components/layout/header/Header';
import { Individual } from './individual/Individual';
import { Firm } from './firm/Firm';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
	apiPanCardValid,
	selfDMARegister,
} from '../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { otpScreenShowPage } from '../../store/reducers/app/appSlice';
import './RegisterYourself.scss';
import { eventAttributes, notifyHandler } from '../../helpers/functions';
import { NotifyVisitors } from 'capacitor-plugin-notifyvisitors';

// array of buttons
const mainButtons = [
	{
		name: 'Individual',
		src: '/images/icons/individual-btn-icon.svg',
	},
	{
		name: 'Firm',
		src: '/images/icons/firm-btn-icon.svg',
	},
];

export const RegisterYourself: React.FC = () => {
	const [selectedMainTab, setSelectedMainTab] = useState('Individual');
	const [selectedFirmInnerTab, setSelectedFirmInnerTab] = useState('');
	const [enableButton, setEnableButton] = useState<any>(false);
	const [value, setValue] = useState('');
	const [managerId, setMangerId] = useState<any>(null);
	const [isInvited, setIsInvited] = useState<any>(false);
	const router = useIonRouter();
	const dispatch: any = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();
	const [constitutionId, setConstitution] = useState(1);

	const userData: any = useSelector((state: any) => state?.users);
	const appData: any = useSelector((state: any) => state?.app);

	// Notify Visitors
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const userName = useSelector(
		(state: any) => state?.users?.homepage?.profile?.name
	);
	const userMobile = useSelector(
		(state: any) => state?.users?.user_details?.mobile
	);

	const entityType = useSelector(
		(state: any) => state?.users?.user_details?.entityType
	);
	const userLang = useSelector(
		(state: any) => state?.oneTimeScreen?.language?.name
	);

	const userAttributes = {
		userId: userId,
		attributes: {
			name: userName,
			id: userId,
			mobile: userMobile,
			user_language: userLang,
			entityType:
				entityType === 'Individual'
					? 'DMA'
					: entityType === 'BTM'
					? 'employee'
					: entityType,
		},
	};
	useEffect(() => {
		if (isPlatform('android') || isPlatform('ios')) {
			notifyHandler('event', eventAttributes('add_DMA_pan_page'));
			notifyHandler('user', userAttributes.attributes);
			const inAppData = {
				tokens: { name: userAttributes },
				customRules: { banerrType: 'add dma pan page' },
			};
			NotifyVisitors.show(inAppData);
		} else {
			(window as any).nv('event', 'add_DMA_pan_page', 10, 1);
			(window as any).nv('user', userId, userAttributes.attributes);
		}
	}, []);

	const individualClick = async () => {
		await dispatch(
			apiPanCardValid({
				param: `?GetEmpConfType=${value?.toUpperCase()}&andro_employee=false&mobile_no=${
					userData?.user_details?.mobile
				}`,
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					const myPromise = new Promise(function (myResolve) {
						setTimeout(function () {
							myResolve(history.push('/add-your-details'));
						}, 10);
					});
					myPromise.then(() => {
						dispatch(
							selfDMARegister({
								...userData.registerUser,
								entityType: selectedMainTab === 'Firm' ? 'firm' : 'individual',
								pan: value?.toUpperCase() || '',
								managerId: managerId
									? parseInt(managerId)
									: userData?.user_details?.id,
								languageId: appData?.language?.id || '',
							})
						);
					});
				}
			});
	};

	useEffect(() => {
		const token = userData?.user_details?.access_token;
		dispatch(otpScreenShowPage(false));
		if (token !== null) {
			setIsInvited(userData?.user_details?.isInvited);
		}
	}, []);

	useEffect(() => {
		if (location.pathname === '/dashboard/teams') {
			setSelectedMainTab('Individual');
		}
	}, [location.pathname]);

	const firmClick = () => {
		dispatch(
			apiPanCardValid({
				param: `?GetEmpConfType=${value?.toUpperCase()}&andro_employee=false&mobile_no=${
					userData?.user_details?.mobile
				}`,
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					dispatch(
						selfDMARegister({
							...userData?.registerUser,
							entityType: selectedMainTab === 'Firm' ? 'firm' : 'individual',
							pan: value?.toUpperCase() || '',
							managerId: managerId
								? parseInt(managerId)
								: userData?.user_details?.id,
							languageId: appData?.language?.id || '',
							constitution_id: constitutionId,
						})
					);
					history.push('/add-firm-details');
					setSelectedFirmInnerTab('');
					setEnableButton(true);
				}
			});
	};

	return (
		<IonPage className=''>
			{userData ? (
				<>
					<Header
						name='Add DMA'
						backButton={userData?.dmaFlow?.dmaId ? true : false}
						needArrow={true}
						needArrowOnClick={() => {
							router.push('/dashboard/teams');
						}}
					/>
					{/* <div className='p-3 fs-16 fw-600 register-dma-title'>
						Register DMA As
					</div> */}
					<div
						// style={{ background: '#EBEBEB' }}
						className='p-3 fs-16 fw-600 register-dma-title d-block d-md-none'
					>
						{t(`Seems like You’re Using It for First Time`)}
					</div>
				</>
			) : (
				<Header name='' />
			)}
			<IonContent className=''>
				<div className='bg-web small-container h-100 overflowY-auto'>
					<div className='bg-web-white container-width-md h-100 position-relative px-3 px-md-0 py-md-3 border-md-radius-10 opacity-1'>
						<div
							// style={{ background: '#EBEBEB' }}
							className='p-3 fs-16 fw-600 register-dma-title d-none d-md-block'
						>
							{t(`Seems like You’re Using It for First Time`)}
						</div>
						<div className='px-0 px-md-3 register-yourself-web-container-height'>
							{!userData && (
								<div>
									<h3 className='fs-24 darkest-grey fw-600 mt-4'>
										{t(`Seems like You’re Using It for First Time`)}
									</h3>
									<h3 className='darkest-grey fs-16 mt-3 fw-600'>
										{t('Register Yourself As')}
									</h3>
								</div>
							)}
							<div>
								{mainButtons.map((item, index) => {
									return (
										<CustomButton
											key={index}
											size='small'
											onClick={() => {
												setValue('');
												setSelectedMainTab(item.name);
												dispatch(
													selfDMARegister({
														pan: '',
														entityType:
															item.name === 'Firm' ? 'firm' : 'individual',
														...userData.registerUser,
													})
												);
											}}
											style={{
												'--background': `${
													item?.name === selectedMainTab
														? '#E9F0FB'
														: 'transparent'
												}`,
											}}
											className={`btn-variant-1  ${
												item?.name === selectedMainTab ? 'active' : ''
											}`}
										>
											<IonImg
												src={item.src}
												style={{ height: '18px', width: '18px' }}
												className='pe-1'
											/>
											{t(item.name)}
										</CustomButton>
									);
								})}
							</div>
							<div>
								{selectedMainTab !== '' && selectedMainTab === 'Individual' ? (
									<Individual
										{...{ setValue, setEnableButton, setMangerId, isInvited }}
									/>
								) : (
									selectedMainTab === 'Firm' && (
										<Firm
											{...{
												selectedFirmInnerTab,
												setSelectedFirmInnerTab,
												setValue,
												setEnableButton,
												setMangerId,
												isInvited,
												setConstitution,
											}}
										/>
									)
								)}
							</div>
						</div>
						<div className='d-none d-md-block bottom-0 w-100 start-0 px-3 pb-3 dma-invite-button'>
							{!userData && (
								<div className='reject-continue-later  d-flex gap-3 ion-padding'>
									<CustomButton
										fill={'outline'}
										title={'Reject'}
										className={'w-100'}
									/>
									<CustomButton
										fill={'outline'}
										title={'Continue Later'}
										onClick={() => history.push('/dashboard/teams')}
										className={'w-100'}
									/>
								</div>
							)}
							<div className='pt-0'>
								{selectedMainTab === 'Individual' ? (
									<CustomButton
										expand='block'
										//routerLink='/add-your-details'
										title={t('Continue')}
										id='a'
										className='btn-blue'
										onClick={individualClick}
										disabled={enableButton}
									/>
								) : (
									<CustomButton
										expand='block'
										//routerLink='/add-firm-details'
										title={t('Continue')}
										id='a'
										className='button-radius btn-blue'
										onClick={firmClick}
										disabled={enableButton}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</IonContent>
			<IonFooter className=' border-0 register-yourself-button'>
				<div className='d-block d-md-none'>
					{/* commented , design not present in xd */}
					{/* {!userData && (
						<div className='reject-continue-later  d-flex gap-3 ion-padding'>
							<CustomButton
								fill={'outline'}
								title={'Reject'}
								className={'w-100'}
							/>
							<CustomButton
								fill={'outline'}
								title={'Continue Later'}
								onClick={() => history.push('/dashboard/teams')}
								className={'w-100'}
							/>
						</div>
					)} */}
					<div className='ion-padding pt-0'>
						{selectedMainTab === 'Individual' ? (
							<CustomButton
								expand='block'
								//routerLink='/add-your-details'
								title={t('Continue')}
								id='a'
								className='btn-blue'
								onClick={individualClick}
								disabled={enableButton}
							/>
						) : (
							<CustomButton
								expand='block'
								//routerLink='/add-firm-details'
								title={t('Continue')}
								id='a'
								className='button-radius btn-blue'
								onClick={firmClick}
								disabled={enableButton}
							/>
						)}
					</div>
				</div>
			</IonFooter>
		</IonPage>
	);
};
