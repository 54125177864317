import { AddressDetailsRequest } from "./address_details_bean";
import { BasicDetailRequest } from "./basic_details_bean";
import { DocumentUploadForm, DocumentUploadRequest } from "./document_bean";
import { ExistingPolicyDetailsRequest } from "./existing_policy_bean";
import { FamilyDetailRequest } from "./family_details_bean";
import { OccupationDetailsRequest } from "./occupation_bean";

export type ApplicationBasicDetailsForm = {
  insuranceTypeId: number;
  managerId: number;
  ifaId: number;
  mobile: string;
  loanApplicationNumber?: string | undefined;
  policyProposedFor?: string;
};


export class Policyform {
  policyId: number | null | undefined;
  applicationId: number | null | undefined;
  policyNumber: number | null | undefined;
  insurerId: number | null | undefined;
  planName: string | null | undefined;
  copsExecId: number | null | undefined;
  verificationByMobOrEmail: string | null | undefined;
  verifiedOn: string | null | undefined;
  sumAssured: number | null | undefined;
  statusId: number | null | undefined;
  issueDate: string | null | undefined;
  expiryDate: string | null | undefined;
  renewalDate: string | null | undefined;
  insurerApplicationNo: string | null | undefined;
  paymentReceipt: number | null | undefined;
  paymentVideo: number | null | undefined;
  policyBondReceivedDate: string | null | undefined;
  policyTerm: number | null | undefined;
  premiumPaymentTerm: number | null | undefined;
  premiumFrequency: string | null | undefined;
  premiumAmount: number | null | undefined;
  closeReason: string | null | undefined;
  plvcRejectedReason: string | null | undefined;
  remarks: string | null | undefined;
  postponementDate: string | null | undefined;
  welcomeCallDate: string | null | undefined;
  welcomeCallRemarks: string | null | undefined;

  constructor(data: PolicyRequest) {
    Object.assign(this, data);
  }
}

export class PolicyRequest {
  policyId: number | null | undefined;
  applicationId: number | null | undefined;
  policyNumber: number | null | undefined;
  insurerId: number | null | undefined;
  planName: string | null | undefined;
  copsExecId: number | null | undefined;
  verificationByMobOrEmail: string | null | undefined;
  verifiedOn: string | null | undefined;
  sumAssured: number | null | undefined;
  statusId: number | null | undefined;
  issueDate: string | null | undefined;
  expiryDate: string | null | undefined;
  renewalDate: string | null | undefined;
  insurerApplicationNo: string | null | undefined;
  paymentReceipt: number | null | undefined;
  paymentVideo: number | null | undefined;
  policyBondReceivedDate: string | null | undefined;
  policyTerm: number | null | undefined;
  premiumPaymentTerm: number | null | undefined;
  premiumFrequency: string | null | undefined;
  premiumAmount: number | null | undefined;
  closeReason: string | null | undefined;
  plvcRejectedReason: string | null | undefined;
  remarks: string | null | undefined;
  postponementDate: string | null | undefined;
  welcomeCallDate: string | null | undefined;
  welcomeCallRemarks: string | null | undefined;

  constructor(data: Policyform) {
    Object.assign(this, data);
  }
}

export class GenerateLeadRequest {
  insuranceTypeId: number;
  managerId: number;
  ifaId: number;
  mobileNumber: string;
  loanApplicationNumber?: string | undefined;
  policyProposedFor?: string;

  // Constructor with parameters
  constructor(applicationBean: ApplicationBean) {
    this.insuranceTypeId = applicationBean.insuranceTypeId!;
    this.managerId = applicationBean.managerId!;

    this.ifaId = applicationBean.ifaId!;
    this.mobileNumber = applicationBean.mobile!;

    this.insuranceTypeId = applicationBean.insuranceTypeId!;
    this.managerId = applicationBean.managerId!;
    this.policyProposedFor = applicationBean.policyProposedFor!;
  }
}

export class ApplicationBean {
  applicationId?: number;
  insuranceTypeId?: number;
  mobile?: string;
  managerId?: number;
  loanApplicationNumber?: string;
  policyProposedFor?: string;
  ifaId?: number;
  proposerProfileId?: number;
  assuredProfileId?: number;
  statusId?: number;
  //     "veribymoboremail": null,
  //     "verifiedOn": null

  basicDetailsProposer?: BasicDetailRequest;
  basicDetailsAssured?: BasicDetailRequest;

  familyDetailsProposer?: FamilyDetailRequest;
  familyDetailsAssured?: FamilyDetailRequest;

  permAddressDetailsProposer?: AddressDetailsRequest;
  curAddressDetailsProposer?: AddressDetailsRequest;

  permAddressDetailsAssured?: AddressDetailsRequest;
  curAddressDetailsAssured?: AddressDetailsRequest;

  occupationDetailsProposer?: OccupationDetailsRequest;
  occupationDetailsAssured?: OccupationDetailsRequest;

  existingPolicyProposer?: ExistingPolicyDetailsRequest;
  existingPolicyAssured?: ExistingPolicyDetailsRequest;

  applicationDocumentsProposer?: DocumentUploadRequest[];
  applicationDocumentsAssured?: DocumentUploadRequest[];

  applicationDocumentsFormProposer?: DocumentUploadRequest[];
  applicationDocumentsFormAssured?: DocumentUploadRequest[];

  
  applicationPolicies?: PolicyRequest[];

  constructor(json: any) {
    var applicationMaster = json.applicationMaster;

    this.applicationId = applicationMaster.applicationId;
    this.proposerProfileId = applicationMaster.proposerProfileId;
    this.assuredProfileId = applicationMaster.assuredProfileId;
    this.insuranceTypeId = applicationMaster.insuranceTypeId;
    this.managerId = applicationMaster.managerId;
    this.ifaId = applicationMaster.ifaId;
    this.statusId = applicationMaster.statusId;
    this.loanApplicationNumber = applicationMaster.loanApplicationNumber;
    this.policyProposedFor = applicationMaster.policyProposedFor;

    this.basicDetailsProposer = json.proposer.basicDetails;
    this.familyDetailsProposer = json.proposer.familyDetails;

    this.applicationDocumentsProposer = json.proposer.documentDetails;

    if(this.applicationDocumentsProposer){

      this.applicationDocumentsFormProposer = this.applicationDocumentsProposer.map(request => new DocumentUploadForm(request));

    }




    const addressMasterProposer = json.proposer.addressMaster;
    this.permAddressDetailsProposer = addressMasterProposer.permanent;
    this.curAddressDetailsProposer = addressMasterProposer.current;

    this.occupationDetailsProposer = json.proposer.occupationDetailsMaster;
    this.existingPolicyProposer = json.proposer.existingPolicyData;


    

    this.applicationPolicies = json.policyMaster;

    if (json.assured) {
      this.basicDetailsAssured = json.assured.basicDetails;
      this.familyDetailsAssured = json.assured.familyDetails;

      const addressMasterAssured = json.assured.addressMaster;
      this.permAddressDetailsAssured = addressMasterAssured.permanent;
      this.curAddressDetailsAssured = addressMasterAssured.current;

      this.occupationDetailsAssured = json.assured.occupationDetailsMaster;
      this.existingPolicyAssured = json.assured.existingPolicyData;
      this.applicationDocumentsAssured = json.assured.documentDetails;




    if(this.applicationDocumentsAssured){
      this.applicationDocumentsFormAssured = this.applicationDocumentsAssured.map(request => new DocumentUploadForm(request));
    }


    }

    //     "veribymoboremail": null,
    //     "verifiedOn": null
  }
  // basicDetailsForm: BasicDetailsForm,
  // existingPolicyDetailsForm: ExistingPolicyDetailsForm,
  // familyDetailsForm: FamilyDetailsForm,
  // occupationDetailsForm: OccupationDetailsForm,
}

export class StatusUpdateRequest {
  applicationId?: number;
  statusId?: number;
  email?:string;
}


export class UpdateVerificationStatusRequest {
  applicationId?: number;
  statusId?: number;
  veribymoboremail?:string;
}





