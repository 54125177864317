import {
	IonContent,
	IonImg,
	IonItem,
	IonLabel,
	IonPage,
	useIonViewWillEnter,
} from '@ionic/react';
import { Header } from '../../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import './DmaUserDetails.scss';
import { FormatDate } from '../../../helpers/functions';
import { InitialLetter } from '../../../components/common/initial-letter/InitialLetter';
import { useDispatch, useSelector } from 'react-redux';
import {
	dmaFlowData,
	getDmaActivity,
	imgUrlUpdate,
} from '../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { StatusNum } from '../../../helpers/constant';
import { ActivitySection } from '../ActivitySection';
import { Divider } from '../../../components/common/divider/Divider';

//const defaultSrc = '/images/icons/default/user-profile-icon.svg';

export const DmaApprovedInfo: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const [userDetails, setUserDetails] = useState<any>([]);
	const [src, setSrc] = useState('');
	const dispatch: any = useDispatch();
	const dmaSelector = useSelector((state: any) => state?.users);

	const userData = useSelector((state: any) => state?.users);
	const getApprovedUserDetails = async () => {
		await dispatch(getDmaActivity({ param: userData?.dmaFlow?.dmaId }))
			.then(unwrapResult)
			.then(async (response: any) => {
				if (response?.status) {
					setUserDetails(response?.user);
					await dispatch(
						imgUrlUpdate({
							data: {
								url: response?.user?.profile_url,
								expire_time: 50000,
							},
						})
					)
						.then(unwrapResult)
						.then((imgResponse: any) => {
							if (imgResponse?.status) {
								setSrc(imgResponse?.url);
							}
						});
				}
			});
	};

	useIonViewWillEnter(() => {
		if (location.pathname !== '/dma-approved-info') {
			return;
		}
		getApprovedUserDetails();
	}, [location.pathname]);

	const handleBackBtn = () => {
		dispatch(
			dmaFlowData({
				...dmaSelector?.dmaFlow,
				dmaCode: StatusNum.APPROVED,
				update: dmaSelector?.dmaFlow?.update + 1 || 1,
			})
		);
		history.push('/team-list');
	};

	return (
		<IonPage>
			<Header
				needArrow={true}
				backButton={true}
				name={t('')}
				needArrowOnClick={() => {
					handleBackBtn();
				}}
			/>
			<IonContent className=''>
				<div className='bg-web h-100 py-md-3'>
					<div className='bg-web-white container-width-md px-md-3 border-md-radius-10 h-100 overflow-auto-md'>
						<IonItem
							className='ion-no-padding ion-padding-vertical ion-margin-horizontal remove-ion-item-inner-padding-end'
							lines='full'
						>
							<div className='d-flex flex-grow-1 align-items-center'>
								<div className='me-3'>
									{src != '' ? (
										<IonImg
											className='ai_profile_img'
											src={src}
											// alt='profile image'
										></IonImg>
									) : (
										<InitialLetter
											value={userDetails?.name}
											height='72px'
											width='72px'
											fontSize='44px'
										/>
									)}
								</div>
								<div>
									<p className='mb-0 fs-14 text-grey'>
										{'# D' + userDetails?.id}
									</p>
									<p className='mb-0 fs-18 fw-600'>{userDetails?.name}</p>
								</div>
							</div>
						</IonItem>
						<IonItem
							className='ion-no-padding ion-margin-horizontal mb-3 text-grey remove-ion-item-inner-padding-end'
							lines='none'
						>
							<div>
								<div className='d-flex align-items-start mb-1'>
									<img
										className='mt-1 me-2'
										src='/images/icons/list-user-type-icon.svg'
										alt='type'
									/>
									<IonLabel className='text-capitalize text-wrap'>
										{userDetails?.entityType}
									</IonLabel>
								</div>
								<div className='d-flex align-items-start mb-1'>
									<img
										className='mt-1 me-2'
										src='/images/icons/list-user-loan-icon.svg'
										alt='type'
									/>
									<IonLabel className='text-capitalize text-wrap'>
										{userDetails?.category_name}
									</IonLabel>
								</div>
								<div className='d-flex align-items-start mb-1'>
									<img
										className='mt-1 me-2'
										src='/images/icons/list-user-manager-icon.svg'
										alt='type'
									/>
									<IonLabel className='text-capitalize text-wrap'>
										{userDetails?.reporting_manager_name}
									</IonLabel>
								</div>
								<div className='d-flex align-items-start mb-1'>
									<img
										className='mt-1 me-2'
										src='/images/icons/list-user-location-icon.svg'
										alt='type'
									/>
									<IonLabel className='text-capitalize text-wrap'>
										{userDetails?.city}
									</IonLabel>
								</div>
								<div className='d-flex align-items-start mb-1'>
									<img
										className='mt-1 me-2'
										src='/images/icons/list-user-phone-icon.svg'
										alt='type'
									/>
									<IonLabel className='text-capitalize text-wrap'>
										{userDetails?.mobile}
									</IonLabel>
								</div>
							</div>
						</IonItem>

						{/* <hr
							className='m-0 pt-1'
							style={{ background: '#f3f3f3', opacity: 1 }}
						/> */}
						<Divider />

						<div className='px-3 mt-4'>
							<h4 className='fw-600 fs-16'>{t('DMA Info')}</h4>
						</div>
						<IonItem
							className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
							lines='full'
						>
							<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
								<p className='mb-0 fs-14'>{t('Created Date')}</p>
								<p className='mb-0 fs-14 fw-600'>
									{userDetails?.dma_createdAt
										? FormatDate(userDetails?.dma_createdAt, 'dd mmmm yyyy')
										: '-'}
								</p>
							</div>
						</IonItem>
						<IonItem
							className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
							lines='none'
						>
							<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
								<p className='mb-0 fs-14'>{t('DMA Type')}</p>
								<p className='mb-0 fs-14 fw-600'>
									{userDetails?.parent_child?.dma_type
										? userDetails?.parent_child?.dma_type
										: '-'}
								</p>
							</div>
						</IonItem>
						<IonItem
							className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
							lines='none'
						>
							<div
								className='d-flex align-items-center'
								onClick={() => {
									dispatch(
										dmaFlowData({
											...dmaSelector?.dmaFlow,
											dmaType: userDetails?.parent_child?.dma_type,
										})
									);
									history.push('/dma-approved-about');
								}}
							>
								<img
									src='images/icons/show-more-info-icon.svg'
									alt='see more info'
								/>
								<p className='ms-2 mb-0 fs-14 fw-600 primary-text-color'>
									{t('See Profile for More Details')}
								</p>
							</div>
						</IonItem>

						{/* <hr
							className='m-0 pt-1'
							style={{ background: '#f3f3f3', opacity: 1 }}
						/> */}
						<Divider />
						<div
							className='px-3 my-4 d-flex align-items-center justify-content-between'
							onClick={() => {
								history.push('/dma-approved-payout-details');
							}}
						>
							<h4 className='fw-600 fs-16 mb-0'>
								{t('Products & Payout info')}
							</h4>
							<img src='/images/icons/right-arrow-blue-icon.svg' />
						</div>

						{/* <hr
							className='m-0 pt-1'
							style={{ background: '#f3f3f3', opacity: 1 }}
						/> */}
						<Divider />
						<div className='px-3 mt-4'>
							<h4 className='fw-600 fs-16'>{t('Team Info')}</h4>
						</div>
						<IonItem
							className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
							lines='full'
						>
							<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
								<p className='mb-0 fs-14'>{t('BUH')}</p>
								<p className='mb-0 fs-14 fw-600'>
									{userDetails?.buh_name ? userDetails?.buh_name : '-'}
								</p>
							</div>
						</IonItem>
						<IonItem
							className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
							lines='full'
						>
							<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
								<p className='mb-0 fs-14'>{t('Senior Manager')}</p>
								<p className='mb-0 fs-14 fw-600'>
									{userDetails?.senior_manager_name
										? userDetails?.senior_manager_name
										: '-'}
								</p>
							</div>
						</IonItem>
						<IonItem
							className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
							lines='none'
						>
							<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
								<p className='mb-0 fs-14'>{t('Manager')}</p>
								<p className='mb-0 fs-14 fw-600'>
									{userDetails?.reporting_manager_name
										? userDetails?.reporting_manager_name
										: '-'}
								</p>
							</div>
						</IonItem>
						<Divider />
						<ActivitySection activityList={userDetails?.activities} />
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};
