// WarningMessage.tsx

import React from 'react';

const WarningMessage: React.FC = () => {
  return (
    <div style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '10px' }}>
      <span style={{ color: 'red' }}>Warning</span>: Do not refresh the page or use the browser  back button, as you may lose your progress.
    </div>
  );
};

export default WarningMessage;
