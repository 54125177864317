/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	IonModal,
	IonContent,
	IonButton,
	IonImg,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
} from '@ionic/react';
import { useRef } from 'react';
import './CustomModal.scss';

interface Modal {
	type?: any;
	isOpen?: boolean;
	content?: string;
	modalTitle?: any;
	modalId?: string;
	handleClose?: any;
	initialBreakpoint: any | null;
	breakpoints?: any | null;
	modalContent: any;
	needArrow?: boolean;
	needCross?: boolean;
	id?: string;
	className?: string | undefined;
	scrollY?: boolean | undefined;
	modalHeight?: string | undefined;
	needIonContentWraper?: any;
	style?: any;
	backdropDismiss?: any;
}

export const CustomModal: React.FC<Modal> = ({
	// type,
	isOpen,
	handleClose,
	initialBreakpoint,
	breakpoints,
	modalContent,
	modalTitle,
	needArrow,
	needCross,
	id,
	className,
	scrollY,
	modalHeight,
	style,
	needIonContentWraper = true,
	backdropDismiss,
}) => {
	const modal = useRef<HTMLIonModalElement>(null);
	modal.current?.onWillDismiss().then(() => handleClose());

	return (
		<IonModal
			id={id}
			ref={modal}
			backdropDismiss={backdropDismiss}
			isOpen={isOpen}
			initialBreakpoint={initialBreakpoint}
			breakpoints={breakpoints}
			style={{ '--height': modalHeight, ...style }}
			className={'modal-border ' + className}
			mode='md'
		>
			{(needCross || needArrow) && (
				<IonHeader>
					<IonToolbar>
						<IonButtons slot='start'>
							<IonButton
								className='pe-0'
								onClick={() => handleClose()}
							>
								{needCross && (
									<IonImg
										className='mb-0 me-1 '
										src='/images/icons/close.svg'
										alt='backarrow'
										onClick={handleClose}
									/>
								)}
								{needArrow && (
									<IonImg
										className='mb-0 me-1 '
										src='/images/icons/backarrow.svg'
										alt='backarrow'
										onClick={handleClose}
									/>
								)}
							</IonButton>
						</IonButtons>
						<IonTitle className='ps-0'>{modalTitle}</IonTitle>
					</IonToolbar>
				</IonHeader>
			)}
			{needIonContentWraper ? (
				<IonContent
					scrollY={scrollY}
					className='ion-no-padding'
				>
					<div
						className={
							id === 'custommodal' || id === 'bulkUploadmodal'
								? ''
								: 'p-3 h-100-web'
						}
					>
						{modalContent}
					</div>
				</IonContent>
			) : (
				modalContent
			)}
		</IonModal>
	);
};
