export type resendOtpData ={
    mobile: string | null;
  }
  
export interface ResendOtpResponseData {
    data: any;
    otp: string;
    mobile: string;
    message: string;
  }

export class ResendOtpRequest {
    mobile:  string | null;
    constructor() {
      this.mobile = null;
    }
  }
 
  
