import * as yup from 'yup';
import { REGEX } from './../../helpers/constant';


const family_floater_schema = yup.object().shape({
  relationship: yup.string().required('Please select relationship.'),
  gender: yup.string().required('Please select Gender'),
  dateOfBirth: yup.date()
    .required('DOB is Required.')
    .max(new Date(), "Date cannot be in the future")
    .typeError('Please select valid date')
    .max(new Date(Date.now()), 'Date cannot be in the future'),
  pincode: yup.string()
    .matches(/^[0-9]{6}$/, 'Pin Code must be 6 digits')
    .required('Pin Code is Required'),
  goodHealth: yup.string().required('Please select if you are in good health.'),
  existingHealthIssues: yup.string().required('Please select existing health issues.'),
  goodHealthDetails: yup.string().test(
    'goodHealthDetails',
    'Please provide details of existing health conditions.',
    function (value) {
      const { goodHealth } = this.parent;
      if (goodHealth === 'no' && !value) {
        return false;
      }
      return true;
    }
  )
});



export default family_floater_schema;