//// @ts-nocheck


import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import useApi from "../../../api/useApi";
import { CustomButton } from "../../../components/common/custom-button/CustomButton";
import CustomForm from "../../../components/common/custom_form/custom_form.component";
import { AuthContext } from "../../../context/AuthContext";
import { UPDATE_POLICY_STATUS, UPDATE_STATUS } from "../../../helpers/api-endpoints";
import { showToastAlert } from "../../../helpers/functions";
import { ApplicationBean, PolicyRequest, StatusUpdateRequest } from "../../../model/insurance_bean";
import { app_status_schema } from "./app_status.schema";
import { useBusyLoading } from "../../../context/BusyLoadingContext";
import { INSURANCE_STATUS, REDIRECT } from "../../../helpers/constant";


export type AppStatusProps = {
  hideRemarks?: boolean;
  hideCloseReason?: boolean;
  hideEmail?: boolean;
  // applicationId:number;
  applicationBean: ApplicationBean;
  statusId: number;
};










export const AppStatusUpdate: React.FC<AppStatusProps> = (props) => {

  const history = useHistory();

  const { userId } = useContext(AuthContext);
  const { setBusyState } = useBusyLoading();




  const {
    hideRemarks,
    hideCloseReason,
    hideEmail,
    // appForm,
  } = props;




  let policyIssuedFormKeys = [

    {
      name: "closeReason",
      type: "text/combo",
      placeholder: "Select Reason*",
      hidden: hideCloseReason,
      mandatory: !hideCloseReason,
      values: [
        { value: "Declined and Closed", description: "Declined and Closed" },
        { value: "Not taken up", description: "Not taken up" },
        { value: "Not Interested", description: "Not Interested" },
      ],
    },
    {
      name: "remarks",
      type: "textarea",
      placeholder: "Remarks",
      hidden: hideRemarks,
    },
    {
      name: "email",
      type: "text",
      placeholder: "Email* (use , in case of multiple emails)",
      hidden: hideEmail,
      mandatory: !hideEmail,
    },
  ];





  const {
    control,
    trigger,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid, isValidating },
  } = useForm({
    resolver: yupResolver(app_status_schema(props)),
    // defaultValues:policyform

  });
  const statusUpdate =
    useApi<any>(`${UPDATE_STATUS}`);

  const polStatus =
    useApi<any>(`${UPDATE_POLICY_STATUS}`);





  useEffect(() => {
    setBusyState(statusUpdate.loading);
  }, [statusUpdate.loading]);


  useEffect(() => {
    showToastAlert({ type: "error", message: statusUpdate.error?.message! });
  }, [statusUpdate.error]);


  useEffect(() => {
    setBusyState(polStatus.loading);
  }, [polStatus.loading]);

  useEffect(() => {
    showToastAlert({ type: "error", message: polStatus.error?.message! });
  }, [polStatus.error]);



  const onSubmit = async (data: any) => {



    //Remarks here


    if ([INSURANCE_STATUS.PAYMENT_PENDING, INSURANCE_STATUS.CLOSED].includes(props.statusId)) {
      let polPayload: PolicyRequest[] = [];
      props.applicationBean?.applicationPolicies?.map(
        (item) => {
          item.statusId = props.statusId;
        }
      );
      polPayload = props.applicationBean?.applicationPolicies ?? [];

      console.log(polPayload);


      polStatus.executeRequest('put', polPayload).then(() => {

      });
    }
    else {
      let statusUpdateRequest = new StatusUpdateRequest();
      statusUpdateRequest.applicationId = props.applicationBean.applicationId;
      statusUpdateRequest.statusId = props.statusId;
      statusUpdateRequest.email = getValues("email");

      console.log(statusUpdateRequest);
      const payload = statusUpdateRequest;
      statusUpdate.executeRequest("put", payload);
    }

  };


  useEffect(() => {
    if (polStatus.responseData?.status) {
      let statusUpdateRequest = new StatusUpdateRequest();
      statusUpdateRequest.applicationId = props.applicationBean.applicationId;
      statusUpdateRequest.statusId = props.statusId;
      
      

      console.log(statusUpdateRequest);
      const payload = statusUpdateRequest;
      statusUpdate.executeRequest("put", payload);
    }
  }, [polStatus.responseData]);



  useEffect(() => {
    if (statusUpdate.responseData?.status) {
      history.push('/cust-ins-message', {
        redirectTo: REDIRECT.DASHBOARD,
        message: `${statusUpdate.responseData?.message}`
      });
    }
  }, [statusUpdate.responseData]
  );



  return (
    <div>

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <form> */}
        <CustomForm
          formKeys={policyIssuedFormKeys}
          control={control}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
          trigger={trigger}
        />


        {/* <div
              className="position-fixed w-100 bottom-0 start-0 px-3 pb-3"
              style={{ backgroundColor: "#fff" }}
            > */}
        <CustomButton
          className="btn-blue"
          type="submit"
          expand="block"
          title="Update"
          onClick={() => {
            handleSubmit(onSubmit);
            // setValidateForm(isValid);
          }}
        />
        {/* </div> */}
      </form>



    </div>
  );
};




