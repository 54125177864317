import {
  IonButton,
  IonCard,
  IonCardContent,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CustomButton } from "../../components/common/custom-button/CustomButton";
import useApi from "../../api/useApi";
import { Header } from "../../components/layout/header/Header";
import ApplicationContext from "../../context/ApplicationContext";
import { useBusyLoading } from "../../context/BusyLoadingContext";
import {
  Add_COMMENT,
  INSURANCE_DISPLAY_APPLICATION_DETAILS,
  INSURANCE_FETCH_APPLICATION_DETAILS,
  UPDATE_POLICY_STATUS,
  UPDATE_STATUS,
} from "../../helpers/api-endpoints";
import { INSURANCE_STATUS, REDIRECT } from "../../helpers/constant";
import { showToastAlert,downloadFile } from "../../helpers/functions";
import {
  ApplicationBean,
  PolicyRequest,
  StatusUpdateRequest,
} from "../../model/insurance_bean";
import { CommentRequest } from "../../model/comment_bean";
import { AuthContext } from "../../context/AuthContext";
import { download } from "ionicons/icons";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

interface Props {}

const rejection_form_schema = yup.object({
  rejectionReason: yup
    .string()
    .trim()
    .max(300)
    .required("Rejection reason is required."),
});

export const DmaApproval: React.FC<Props> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { applicationBean, setAppBean } = useContext(ApplicationContext);
  const [showModal, setShowModal] = useState(false);
  const { userId } = useContext(AuthContext);
  const [dataFields, setDataFields] = useState<any>();

  const commentsAPI = useApi<any>(Add_COMMENT);
  const appDetailsAPI = useApi<any>(INSURANCE_FETCH_APPLICATION_DETAILS);
  const displayAppDetailsAPI = useApi<any>(INSURANCE_DISPLAY_APPLICATION_DETAILS);
  const polStatus = useApi<any>(`${UPDATE_POLICY_STATUS}`);
  const statusUpdate = useApi<any>(`${UPDATE_STATUS}`);

  const { setBusyState } = useBusyLoading();

  let rejectionFormKeys = [
    {
      name: "rejectionReason",
      type: "textarea",
      placeholder: "Remarks",
      mandatory: true,
    },
  ];

  const {
    control,
    trigger,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(rejection_form_schema),
  });

  useEffect(() => {
    setBusyState(statusUpdate.loading);
  }, [statusUpdate.loading]);

  useEffect(() => {
    setBusyState(commentsAPI.loading);
  }, [commentsAPI.loading]);

  useEffect(() => {
    if (statusUpdate.error) {
      showToastAlert({ type: "error", message: statusUpdate.error.message });
    }
  }, [statusUpdate.error]);

  useEffect(() => {
    setBusyState(appDetailsAPI.loading);
  }, [appDetailsAPI.loading]);

  useEffect(() => {
    if (appDetailsAPI.error) {
      showToastAlert({ type: "error", message: appDetailsAPI.error.message });
    }
  }, [appDetailsAPI.error]);

  useEffect(() => {
    setBusyState(displayAppDetailsAPI.loading);
  }, [displayAppDetailsAPI.loading]);

  useEffect(() => {
    if (displayAppDetailsAPI.error) {
      showToastAlert({ type: "error", message: displayAppDetailsAPI.error.message });
    }
  }, [displayAppDetailsAPI.error]);

  useEffect(() => {
    if (commentsAPI.error) {
      showToastAlert({ type: "error", message: commentsAPI.error.message });
    }
  }, [commentsAPI.error]);

  useEffect(() => {
    setBusyState(polStatus.loading);
  }, [polStatus.loading]);

  useEffect(() => {
    if (polStatus.error) {
      showToastAlert({ type: "error", message: polStatus.error.message });
    }
  }, [polStatus.error]);

  useEffect(() => {
    if (applicationBean) {
      const appId: number = applicationBean.applicationId!;
      const payload = JSON.stringify({ applicationId: appId });
      displayAppDetailsAPI.executeRequest("post", payload, true);
    }
  }, [applicationBean]);

  const acceptProposal = async (accept: boolean, rejectionReason: string = "") => {
    if (!accept && rejectionReason === "") {
      setShowModal(true);
      return;
    }

    if (!accept) {
      const exampleComment = new CommentRequest(
        null,
        parseInt(userId!),
        "",
        rejectionReason,
        null,
        applicationBean?.applicationId!,
        null
      );

      const payload = exampleComment;
      await commentsAPI.executeRequest("post", payload);
    }

    let polPayload: PolicyRequest[] = [];
    applicationBean?.applicationPolicies?.forEach((item) => {
      item.statusId = accept ? INSURANCE_STATUS.APPROVED : INSURANCE_STATUS.RESENT_TO_IFA;
    });
    polPayload = applicationBean?.applicationPolicies ?? [];

    await polStatus.executeRequest("put", polPayload);

    let statusUpdateRequest = new StatusUpdateRequest();
    statusUpdateRequest.applicationId = applicationBean?.applicationId;
    statusUpdateRequest.statusId = accept ? INSURANCE_STATUS.APPROVED : INSURANCE_STATUS.RESENT_TO_IFA;

    await statusUpdate.executeRequest("put", statusUpdateRequest);

    setShowModal(false);
    reset();
  };

  const updateProposal = async () => {
    if (applicationBean) {
      const appId: number = applicationBean.applicationId!;
      const payload = JSON.stringify({ applicationId: appId });
      await appDetailsAPI.executeRequest("post", payload, true);
    }
  };

  useEffect(() => {
    if (appDetailsAPI.responseData?.data) {
      const appBeanFin = new ApplicationBean(appDetailsAPI.responseData.data);
      setAppBean(appBeanFin);
      history.push("/dmacoordinsurance");
    }
  }, [appDetailsAPI.responseData]);

  useEffect(() => {
    if (displayAppDetailsAPI.responseData?.data) {
      setDataFields(displayAppDetailsAPI.responseData.data);
    }
  }, [displayAppDetailsAPI.responseData]);

  useEffect(() => {
    if (statusUpdate.responseData) {
      showToastAlert({
        type: "success",
        message: statusUpdate.responseData.message,
      });
      history.push("/cust-ins-message", {
        redirectTo: REDIRECT.DASHBOARD,
        message: `${statusUpdate.responseData.message}. Application Id is ${applicationBean?.applicationId}`,
      });
    }
  }, [statusUpdate.responseData]);

  
  return (
    <IonPage className="bg-web-white container-width-md container-spacing-md border-md-radius-top">
      <Header
        className="card-header"
        name={t("Proposal Detail Review")}
        backButton={true}
        needArrow={true}
        needArrowOnClick={() => {
          history.push(REDIRECT.DASHBOARD);
        }}
      />
      <IonContent className="ion-padding-bottom">
        <div>
          <IonCard key={"dmaCard"}>
            <IonCardContent key={"dmaCardContent"}>
              {dataFields &&
                dataFields.fields.map((field: any, index: number) => {
                  const fieldName = Object.keys(field)[0];
                  const fieldValue = field[fieldName];
                  if (fieldValue !== null && fieldValue !== undefined && fieldValue !== "")
                    return (
                      <IonItem key={index}>
                        <IonLabel>{fieldName}</IonLabel>
                        <IonText style={{ textAlign: "left", paddingLeft: "8px" }}>
                          {fieldValue || "N/A"}
                        </IonText>
                      </IonItem>
                    );
                })}
              {dataFields &&
                dataFields.attachments.length > 0 &&
                dataFields.attachments.map((item: any, index: number) => (
                  <IonCard
                    key={index}
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <IonLabel
                      style={{
                        padding: "10px",
                        fontSize: "1.2em",
                        fontWeight: "bold",
                      }}
                    >
                      {item.header}
                    </IonLabel>
                    <IonCardContent
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{ height: "100%", width: "100%" }}
                        src={`${item.content}`}
                        alt="file-preview"
                      />
                    </IonCardContent>
                    <IonButton onClick={() => downloadFile(item)}>
                      <IonIcon icon={download} />
                    </IonButton>
                  </IonCard>
                ))}
            </IonCardContent>
          </IonCard>
          <div
            className="position-fixed w-100 bottom-0 start-0 px-3 pb-3"
            style={{ backgroundColor: "#fff" }}
          >
            <IonGrid>
              <IonRow class="ion-justify-content-center">
                <CustomButton
                  className="btn-blue"
                  type="submit"
                  expand="block"
                  title={t("Accept")}
                  onClick={() => {
                    acceptProposal(true);
                  }}
                />
                <CustomButton
                  className="btn-blue"
                  expand="block"
                  title={t("Update")}
                  onClick={() => {
                    updateProposal();
                  }}
                />
                <CustomButton
                  className="btn-blue"
                  expand="block"
                  title={t("Reject")}
                  onClick={() => {
                    setShowModal(true);
                  }}
                />
              </IonRow>
            </IonGrid>
          </div>
        </div>
        <IonModal isOpen={showModal}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Reject Proposal</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <form onSubmit={handleSubmit((data) => acceptProposal(false, data.rejectionReason))}>
              <IonTextarea
                value={getValues("rejectionReason")}
                onIonChange={(e) => {
                  setValue("rejectionReason", e.detail.value!);
                }}
                className="border border-1"
                fill="outline"
                placeholder={t("Remarks").toString()}
                maxlength={300}
                label=""
                rows={3}
                mode="md"
              ></IonTextarea>
              <IonRow class="ion-justify-content-between ion-padding">
                <CustomButton
                  className="btn-blue"
                  expand="block"
                  title={t("Cancel")}
                  onClick={() => {
                    setShowModal(false);
                    reset();
                  }}
                />
                <CustomButton
                  className="btn-blue"
                  type="submit"
                  expand="block"
                  title={t("Update")}
                />
              </IonRow>
            </form>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};
