/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	IonContent,
	IonCardTitle,
	IonCardSubtitle,
	IonCardContent,
	IonPage,
	IonCard,
	IonLabel,
	IonItem,
	IonList,
	IonRadioGroup,
	IonRadio,
	IonButtons,
	useIonViewDidEnter,
	IonImg,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { Header } from '../../components/layout/header/Header';
import './DMADetails.scss';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
import { CustomButton } from '../../components/common/custom-button/CustomButton';

import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
import { CustomCheckbox } from '../../components/common/custom-checkbox/CustomCheckbox';

import { REGEX, StatusNum } from '../../helpers/constant';
import { RejectionModal } from './RejectionModal';
import {
	FormatDate,
	disableNumberArrows,
	ionContentScrollToTop,
	showToastAlert,
} from '../../helpers/functions';
import { InitialLetter } from '../../components/common/initial-letter/InitialLetter';
import DropDownModal from '../../components/common/custom-dropdown/DropDownModal';
import { useDispatch, useSelector } from 'react-redux';
import {
	customSuccessfull,
	dmaDueDilligence,
	dmaFlowData,
	getDmaActivity,
	getdmaAbout,
	getdmaInfo,
	kycStep,
	teamDmaMarkParentChild,
	userName,
	disburseCountDropdown,
	disburseCount,
} from '../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { ActivitySection } from './ActivitySection';
import { Divider } from '../../components/common/divider/Divider';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ConsentCheckbox } from '../../components/common/consent-checkbox/ConsentCheckbox';
import CustomAllocateManagers from '../../components/common/CustomAllocateManagers';

export const parentChildSchema = yup.object().shape({
	mobile_no: yup
		.string()
		.length(10, 'Mobile No. Should Be 10 Digits.')
		.required('Mobile is Required.')
		.matches(REGEX.INDIAN_MOBILE_NUMBERS, 'Please Enter a valid Mobile No.'),
	acceptTerms: yup
		.bool()
		.required('You need to check the consent')
		.oneOf([true], 'You need to check the consent'),
});

const MarkParentChildModel = ({
	setMarkParentChildModel,
	dmaSelector,
}: any) => {
	const [state, setState] = useState('child');
	const dispatch = useDispatch();
	const [isMoreContent, setMoreContent] = useState(false);
	const history = useHistory();
	const { t } = useTranslation();
	const {
		handleSubmit,
		control,
		trigger,
		getValues,
		reset,
		watch,
		setValue,
		formState: { errors },
	}: any = useForm({
		resolver: yupResolver(parentChildSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			mobile_no: '',
			child_name: '',
			acceptTerms: false,
		},
	});

	const enableButton =
		!!watch('child_name') && !!watch('mobile_no') && watch('acceptTerms');

	const submit = async () => {
		dispatch(
			teamDmaMarkParentChild({
				param: dmaSelector?.dmaActivity?.user.id,
				data: {
					dma_type: state,
					mobile: Number(
						state === 'parent'
							? dmaSelector?.dmaActivity?.user?.mobile
							: watch('mobile_no')
					),
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setMarkParentChildModel(false);
				} else {
					setMarkParentChildModel(false);
				}
			});
		history.push('/team-list');
		setTimeout(() => {
			dispatch(
				dmaFlowData({
					...dmaSelector?.dmaFlow,
					dmaCode: StatusNum.PARENTCHILD,
					update: (dmaSelector?.dmaFlow?.update || 0) + 1,
				})
			);
		}, 300);
	};

	const firstName = dmaSelector?.dmaActivity
		? dmaSelector?.dmaActivity.user?.name.split(' ')[0]
		: '';

	return (
		<div className=''>
			<p className='fs-10 mt-2'>{t('Type of DMA')}</p>
			<form onSubmit={handleSubmit(submit)}>
				<div className='h-70-web'>
					<IonRadioGroup
						className='d-flex gap-3'
						value={state}
						onIonChange={(e: any) => {
							setState(e.detail.value);
							reset();
						}}
					>
						<IonRadio value='parent'>{t('Parent')}</IonRadio>
						<IonRadio value='child'>{t('Child')}</IonRadio>
					</IonRadioGroup>

					{state === 'child' ? (
						<div className='mt-4 mb-3'>
							<Controller
								control={control}
								render={({ field: { onChange, onBlur, name } }) => (
									<CustomInput
										placeholder={t('Parent Mobile Number') + ' *'}
										className='mt-1'
										type={'number'}
										value={getValues(name)}
										onChange={(e: any) => {
											onChange(e);
											if (watch('mobile_no').length === 10) {
												dispatch(userName({ param: `=${watch('mobile_no')}` }))
													.then(unwrapResult)
													.then((response: any) => {
														if (response?.status) {
															setValue(
																'child_name',
																`${response?.data?.first_name}`
															);
														} else {
															setValue('child_name', '');
														}
													});
											} else {
												setValue('child_name', '');
											}
										}}
										onBlur={onBlur}
										name={name}
										onBeforeInput={(evt: any) => {
											//Only numbers accepted on user input
											!/[0-9]/.test(evt.data) && evt.preventDefault();
											//Only accepts 10 characters on user input
											if (evt.target.value.length === 10) {
												evt.preventDefault();
											}
										}}
										onPaste={(e: any) => {
											//Only get numbers on user paste
											e.preventDefault();
											let pasteContent = e.clipboardData.getData('Text');
											if (pasteContent != '') {
												pasteContent = pasteContent.replace(/\D/g, '');
												if (pasteContent.length > 10) {
													pasteContent = pasteContent.slice(0, 10);
												}
											}
											setValue('mobile_no', pasteContent);
											trigger('mobile_no');
										}}
										onKeyDown={(e: any) => {
											disableNumberArrows(e);
										}}
										onWheel={(e: any) => e.target.blur()}
										errors={t(errors?.mobile_no?.message as string)}
									/>
								)}
								name='mobile_no'
							/>
							<Controller
								control={control}
								render={({ field: { onChange, onBlur, name } }) => (
									<CustomInput
										type={'text'}
										disabled={true}
										onChange={onChange}
										onBlur={onBlur}
										name={name}
										placeholder={t('Parent Name') + ' *'}
										className='mt-1'
										value={getValues('child_name')}
									/>
								)}
								name='child_name'
							/>

							<div className='position-relative'>
								<Controller
									control={control}
									render={({ field: { onChange, onBlur, name } }) => (
										<div
											className='d-flex py-3'
											style={{ background: '#EDF8EC' }}
										>
											<div className='position-relative'>
												<CustomCheckbox
													name={name}
													isBackGround={true}
													labelPlacement='end'
													isChecked={getValues('acceptTerms')}
													value={getValues('acceptTerms')}
													onBlur={onBlur}
													onChange={(event: any) => {
														setValue('acceptTerms', event.detail.checked);
														setTimeout(() => {
															onChange(event);
														}, 500);
													}}
													className={
														(errors[name] ? 'ion-checkbox-inValid' : '') +
														' pt-1 px-3'
													}
													errorText={t(errors[name]?.message as string)}
													errorTextClass='position-absolute checkbox-style1'
												/>
											</div>
											<ConsentCheckbox isMoreContent={isMoreContent} />
										</div>
									)}
									name={'acceptTerms'}
								/>
								<div
									onClick={() => {
										setMoreContent(!isMoreContent);
									}}
									className='position-absolute right-0 ion-padding py-1 text-primary fs-12 cursor-pointer fw-600'
								>
									{isMoreContent ? `- ${t('Less')}` : `+ ${t('More')}`}
								</div>
							</div>
						</div>
					) : (
						<div className='my-2 mt-4'>
							<CustomInput
								type='number'
								placeholder={t('DMA Mobile Number')}
								className='mt-1'
								disabled={true}
								value={dmaSelector?.dmaActivity?.user?.mobile}
								onBeforeInput={(evt: any) =>
									!/[0-9]/.test(evt.data) && evt.preventDefault()
								}
								onKeyDown={(e: any) => {
									disableNumberArrows(e);
								}}
								onWheel={(e: any) => e.target.blur()}
							/>
							<CustomInput
								type='text'
								disabled={true}
								placeholder={t('DMA Name')}
								className='mt-1'
								value={firstName}
							/>
						</div>
					)}
				</div>

				<div className='ion-padding'>
					<CustomButton
						onClick={() => setMarkParentChildModel(false)}
						fill={'outline'}
						title={t('Cancel')}
						className={'w-100'}
					/>
					<CustomButton
						disabled={state === 'parent' ? false : !enableButton}
						title={t('Submit')}
						className={'w-100'}
						onClick={state === 'parent' ? submit : handleSubmit(submit)}
					/>
				</div>
			</form>
		</div>
	);
};

export const DmaDetail: React.FC = () => {
	const dispatch: any = useDispatch();
	const location = useLocation<any>();

	const dmaSelector = useSelector((state: any) => state?.users);
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	const history = useHistory();
	const { t } = useTranslation();
	const [currentStep, setCurrentStep] = useState<string | null>(null);
	const [dueDilligenceModal, setDueDilligenceModal] = useState<boolean>(false);
	const [updateStatusDueDilligenceModal, setUpdateStatusDueDilligenceModal] =
		useState<boolean>(false);
	const [shareModalOpen, setShareModalOpen] = useState(false);
	const [markParentChildModel, setMarkParentChildModel] = useState(false);
	const [rejectionModal, setRejectionModal] = useState(false);
	const [dmaInfo, setDmaInfo] = useState<any>();
	const [actionTitle, setActionTitle] = useState<string>('');
	const [actionTarget, setActionTarget] = useState<any>('');
	const [updateApi, setUpdateApi] = useState(
		location?.state?.update ? location?.state?.update : 1
	);
	const [isButtonNeeded, setIsButtonNeeded] = useState(false);
	const [allocateAndroManagerModal, setAllocateAndroManagerModal] =
		useState<boolean>(false);
	const [showAssignModal, setShowAssignModal] = useState<boolean>(false);
	// const [value, setValue] = useState('');
	const [disburseDropDownLabel, setDisburseDropDownLabel] = useState('');

	const [activityDma, setActivityDma] = useState<any>([]);
	const [dmaMobile, setDmaMobile] = useState();

	const [userAbout, setUserAbout] = useState<any>('');
	const [disbursedDropDown, setDisbursedDropDown] = useState([]);
	const [disbursedLeads, setDisbursedLeads] = useState(0);

	const contentRef = useRef<HTMLIonContentElement | null>(null);
	useIonViewDidEnter(() => {
		ionContentScrollToTop(contentRef);
	});

	useEffect(() => {
		getData();
	}, [
		location?.pathname,
		location?.state?.update,
		dmaSelector?.dmaFlow?.update,
		markParentChildModel,
	]);
	const getData = async () => {
		if (location?.pathname === '/dma-details') {
			await dispatch(getdmaAbout({ param: dmaSelector?.dmaFlow?.dmaId }))
				.then(unwrapResult)
				.then((res: any) => {
					if (res?.status) {
						setUserAbout(res?.data);
					}
				});
			await dispatch(getdmaInfo({ param: dmaSelector?.dmaFlow?.dmaId }))
				.then(unwrapResult)
				.then((res: any) => {
					if (res?.status) {
						setDmaInfo(res?.user);
					}
				});
			await dispatch(getDmaActivity({ param: dmaSelector?.dmaFlow?.dmaId }))
				.then(unwrapResult)
				.then((res: any) => {
					if (res?.status) {
						setActivityDma(res?.user?.activities);
						setDmaMobile(res?.user?.mobile);
					}
				});
			await dispatch(disburseCountDropdown())
				.then(unwrapResult)
				.then((res: any) => {
					setDisbursedDropDown(res.data);
					getDisbursedCount();
				});
		} else {
			setIsButtonNeeded(false);
		}
	};

	useEffect(() => {
		(() => {
			let _actionTitle = '';
			let _actionTarget = '';
			let _isButtonNeeded = false;
			let _currentStep = '';

			if (
				[
					StatusNum.KYC,
					StatusNum.KYC1,
					StatusNum.KYC2,
					StatusNum.KYC3,
					StatusNum.KYC4,
				].includes(userAbout?.status)
			) {
				_actionTitle = t('Complete KYC of the Applicant');
				_actionTarget = '/kyc';
				_isButtonNeeded = true;
			}
			switch (userAbout?.status) {
				case StatusNum.INIT:
					_actionTitle = t('Complete DMA Onboarding Journey');
					_actionTarget = '/register-yourself';
					_isButtonNeeded = true;
					break;
				case StatusNum.CL1:
					_actionTitle = t('Complete DMA Onboarding Journey');
					_actionTarget = '/add-your-details';
					_isButtonNeeded = true;
					break;
				case StatusNum.CL2:
					_actionTitle = t('Complete DMA Onboarding Journey');
					_actionTarget = '/add-firm-details';
					_isButtonNeeded = true;
					break;
				case StatusNum.CL3:
					_actionTitle = t('Complete DMA Onboarding Journey');
					_actionTarget = '/contact-person-details';
					_isButtonNeeded = true;
					break;
				case StatusNum.KYC:
					_currentStep = 'KYC1';
					break;
				case StatusNum.KYC1:
					_currentStep = 'KYC2';
					break;
				case StatusNum.KYC2:
					_currentStep = 'KYC3';
					break;
				case StatusNum.KYC3:
					_currentStep = 'KYC4';
					break;
				case StatusNum.KYC4:
					_actionTitle = t('Complete KYC of the Applicant');
					_actionTarget = '/kyc';
					_isButtonNeeded = true;
					_currentStep = 'KYC4';
					break;
				case StatusNum.PAYOUT:
					_actionTitle = t('Add payout for Applicant');
					_actionTarget = '/proposed-payout';
					_isButtonNeeded = true;
					break;
				case StatusNum.PARENTCHILD:
					_actionTitle = t('Mark Applicant as Parent or Child');
					_isButtonNeeded = true;
					break;
				case StatusNum.PAYOUTRECOMENDED:
				case StatusNum.PAYOUTAPPROVAL:
				case StatusNum.EXISTINGAPPROVAL:
				case StatusNum.EXISTINGAPPROVAL2:
				case StatusNum.REVISEDAPPROVAL:
				case StatusNum.REVISEDAPPROVAL2:
					if (!dmaInfo?.tag) {
						_actionTitle = t('Initiate Due Diligence');
						_isButtonNeeded = true;
					} else if (
						[
							'Due Diligence Initiated Successfully',
							'Due Diligence Initiated',
						].includes(dmaInfo?.tag)
					) {
						_actionTitle = t('Update Due Diligence Status');
						_isButtonNeeded = true;
					} else if (
						[
							'Positive Profile',
							'Negative Profile',
							'Due Diligence not Initiated',
							'Due Diligence Not Initiated',
						].includes(dmaInfo?.tag)
					) {
						_actionTitle = t('View Proposed Payout');
						_actionTarget = '/approve-proposed-payout';
						_isButtonNeeded = true;
					}
					break;
				case StatusNum.REVISED:
					_actionTitle = t('View Revision Request');
					_actionTarget = '/approve-proposed-payout';
					_isButtonNeeded = true;
					break;
				case StatusNum.REWORK:
					_actionTitle = t('Rework required as incomplete');
					_actionTarget = '/dma-verification-status';
					_isButtonNeeded = true;
					break;

				default:
					break;
			}
			if (_actionTitle) {
				setActionTitle(_actionTitle);
			}
			if (_actionTarget) {
				setActionTarget(_actionTarget);
			}
			if (_currentStep) {
				setCurrentStep(_currentStep);
			}
			if (
				_isButtonNeeded &&
				![
					StatusNum.UNDERPROCESS,
					StatusNum.UNDERPROCESSCT,
					StatusNum.ALLOCATOR,
					StatusNum.REJECTED,
				].includes(dmaSelector?.dmaFlow?.dmaCode)
			) {
				setIsButtonNeeded(_isButtonNeeded);
			}
		})();
	}, [dmaInfo, userAbout]);
	const ShareModal = () => {
		return (
			<>
				<IonList>
					{['BM', 'BUHOD', 'BUH']?.includes(
						dmaSelector?.user_details?.roleType?.[0]
					) &&
						dmaSelector?.dmaFlow?.dmaCode === StatusNum?.ACTIVE && (
							<IonItem lines='full'>
								<IonLabel>
									<a
										onClick={() => {
											setShareModalOpen(false);
											history.push('/manage-manager-payout');
										}}
										className=' text-decoration-none text-dark cursor-pointer'
									>
										<img
											src='/images/icons/mng-payout.svg'
											alt='mail'
											className='me-2'
										/>
										{t('Manage Manager & Payout')}
									</a>
								</IonLabel>
							</IonItem>
						)}
					<IonItem
						lines='full'
						disabled={true}
					>
						<IonLabel>
							<a
								href={`mailto:` + userAbout.email}
								className=' text-decoration-none text-dark'
							>
								<img
									src='/images/icons/send-mail.svg'
									alt='mail'
									className='me-2'
								/>
								{t('Send Email')}
							</a>
						</IonLabel>
					</IonItem>
					<IonItem
						lines='full'
						disabled={true}
					>
						<IonLabel class='d-flex'>
							<img
								src='/images/icons/reject-dma.svg'
								alt='mail'
								className='me-2'
							/>
							<h2 className=''>{t('Reject DMA')}</h2>
						</IonLabel>
					</IonItem>
				</IonList>
			</>
		);
	};

	/* const LeadActivityModal = () => {
		return (
			<>
				<IonList>
					{activity &&
						activity.map((item: any, index: number) => {
							return (
								<IonItem
									key={index}
									className='ion-no-padding'
									lines='full'
								>
									<div className='m-3 ms-0'>
										<h6 className='fw-bold fs-16 fs-500'>
											{item?.Comments || 'NA'}
										</h6>
										<div className='mt-2 d-flex justify-content-start gap-3'>
											<p className='mb-0 fs-12'>{FormatDate(item?.Date)}</p>
											<p className='mb-0 fs-12'>{item?.Time}</p>
											<p className='mb-0 fs-12'>By Sunil Palkar</p>
										</div>
										<div className='mt-2 d-flex justify-content-between'>
											<p className='mb-0 fs-14'>Application ID #{item?.id}</p>
										</div>
									</div>
								</IonItem>
							);
						})}
				</IonList>
			</>
		);
	}; */

	const DueDilligenceModal = () => {
		const [flag, setFlag] = useState('');
		const [error, setError] = useState(true);
		const setDueDilligence = async () => {
			setUpdateApi(updateApi + 1);
			await dispatch(
				dmaDueDilligence({
					param: dmaSelector?.dmaFlow?.dmaId + '?flag=' + flag,
				})
			)
				.then(unwrapResult)
				.then((result: any) => {
					if (result?.status) {
						setDueDilligenceModal(false);
						if (flag === 'yes') {
							history.push('/team-list');
							setTimeout(() => {
								dispatch(
									dmaFlowData({
										...dmaSelector?.dmaFlow,
										update: (dmaSelector?.dmaFlow?.update || 0) + 1,
									})
								);
							}, 1000);
						} else {
							dispatch(
								dmaFlowData({
									...dmaSelector?.dmaFlow,
									dmaTag: `Due Diligence Not Initiated`,
								})
							);
							setTimeout(() => {
								history.push('approve-proposed-payout', {
									update: updateApi,
								});
							}, 1000);
						}
					}
				});
		};
		return (
			<div className='h-100-web'>
				<div className='d-flex flex-column justify-content-between h-100-web'>
					{/* <div className='h-100-web'> */}
					<div>
						<p className='fs-14 fw-600'>
							{t('Are you initiating due diligence for this DMA')}?
						</p>
						<IonRadioGroup
							onIonChange={(e: any) => {
								setError(false);
								setFlag(e.detail.value);
							}}
							className='d-flex flex-md-column gap-4'
						>
							<IonRadio
								value={'yes'}
								labelPlacement='end'
								justify='start'
							>
								{t('Yes')}
							</IonRadio>
							<IonRadio
								value={'no'}
								labelPlacement='end'
								justify='start'
							>
								{t('No')}
							</IonRadio>
						</IonRadioGroup>
					</div>

					{/* </div> */}
					<div className='mt-4 mb-4'>
						<CustomButton
							disabled={error}
							expand='block'
							title={t('Submit')}
							onClick={setDueDilligence}
						/>
					</div>
				</div>
			</div>
		);
	};
	const UpdateStatusDueDilligence = () => {
		const [flag, setFlag] = useState('');
		const [error, setError] = useState(true);

		const setDueDilligence = async () => {
			await dispatch(
				dmaDueDilligence({
					param: dmaSelector?.dmaFlow?.dmaId + '?flag=' + flag,
				})
			)
				.then(unwrapResult)
				.then(async (result: any) => {
					if (result?.status) {
						showToastAlert({ type: 'success', message: result?.Data?.tag });
						setUpdateStatusDueDilligenceModal(false);
						await dispatch(
							dmaFlowData({
								...dmaSelector?.dmaFlow,
								dmaTag: `${
									flag.charAt(0).toUpperCase() + flag.slice(1)
								} Profile`,
							})
						);
						setTimeout(() => {
							history.push('/approve-proposed-payout');
						}, 1000);
					}
				});
		};
		return (
			<div className='h-100-web'>
				<p className='fs-14 fw-600'>{t('Due Diligence Status')}?</p>
				<div className='d-flex flex-column justify-content-between h-100-web'>
					<IonRadioGroup
						onIonChange={(e: any) => {
							setError(false);
							setFlag(e.detail.value);
						}}
						className='d-flex flex-md-column gap-3'
					>
						<IonRadio
							value={'positive'}
							labelPlacement='end'
							justify='start'
						>
							{t('Positive')}
						</IonRadio>
						<IonRadio
							value={'negative'}
							labelPlacement='end'
							justify='start'
						>
							{t('Negative')}
						</IonRadio>
					</IonRadioGroup>
					<div className='mt-4 mb-5'>
						<CustomButton
							disabled={error}
							expand='block'
							title={t('Submit')}
							onClick={setDueDilligence}
						/>
					</div>
				</div>
			</div>
		);
	};

	const handleBackBtn = () => {
		const myPromise = new Promise(function (myResolve) {
			setTimeout(function () {
				myResolve(
					history.push('/team-list', {
						update: updateApi,
						code: dmaSelector?.dmaFlow?.dmaCode,
					})
				);
			}, 10);
		});
		myPromise.then(() => {
			dispatch(
				dmaFlowData({
					...dmaSelector?.dmaFlow,
					update: (dmaSelector?.dmaFlow?.update || 1) + 1,
				})
			);
			setUpdateApi(updateApi + 1);
			setDmaInfo({});
			setUserAbout({});
		});
	};

	const getDisbursedCount = async (months: any = '1') => {
		await dispatch(
			disburseCount({ param: `${dmaSelector?.dmaFlow?.dmaId}/${months}` })
		)
			.then(unwrapResult)
			.then((res: any) => {
				setDisbursedLeads(res.count);
				setDisburseDropDownLabel(months);
			});
	};

	return (
		<>
			<IonPage>
				<Header
					name={t('Details')}
					backButton={true}
					needArrow={true}
					needArrowOnClick={() => handleBackBtn()}
					endItem={
						dmaSelector?.dmaFlow?.isAndro ||
						dmaSelector?.dmaFlow?.dmaCode === StatusNum.REJECTED ||
						dmaSelector?.dmaFlow?.dmaCode === StatusNum.UNDERPROCESS ||
						dmaSelector?.dmaFlow?.dmaCode === StatusNum.ALLOCATOR ? (
							''
						) : (
							<IonButtons
								slot='end'
								style={{ justifyContent: 'end' }}
							>
								<IonImg
									className='cursor-pointer'
									src='/images/icons/assign-icon.svg'
									alt='assign'
									onClick={() => {
										setShowAssignModal(true);
									}}
								/>
							</IonButtons>
						)
					}
					// style={{ borderBottom: '1px solid grey' }}
					style={{ borderBottom: '1px solid #00000029' }}
				/>
				<IonContent
					className=''
					ref={contentRef}
					scrollEvents={true}
				>
					<div className='small-container bg-web'>
						<div className='bg-web-white container-width-md border-md-radius-10 opacity-1 py-md-3'>
							<IonItem
								className='ion-no-padding ion-padding-vertical ion-margin-horizontal py-0'
								lines='full'
							>
								<div
									className='d-flex w-100 align-items-center justify-content-start
						 gap-3 px-1'
								>
									<div className='w-auto'>
										<InitialLetter
											value={dmaInfo?.user_name}
											width={72}
											height={72}
											fontSize='40px'
										/>
										{/* <img
									width={72}
									height={72}
									className='rounded-circle'
									alt='Silhouette of mountains'
									src='https://ionicframework.com/docs/img/demos/card-media.png'
								/> */}
									</div>
									<div className='w-100'>
										<IonCardTitle className='fs-14 bg-transparent text-grey fw-normal'>
											#D{dmaInfo?.username_id}
										</IonCardTitle>
										<IonCardSubtitle className='fs-22 text-black bg-transparent'>
											{dmaInfo?.user_name !== ' '
												? dmaInfo?.user_name
												: 'Unknown'}
										</IonCardSubtitle>
									</div>
								</div>
							</IonItem>
							<IonItem lines='none'>
								<IonList className='bg-transparent d-flex flex-column gap-1 text-grey'>
									<div className='d-flex gap-2'>
										<img
											src='/images/icons/teams/dmatype.svg'
											alt='dmatype'
										/>
										<p className='text-grey-light text-capitalize fs-14 mb-0'>
											{userAbout?.RegisteredAs
												? userAbout?.RegisteredAs
												: t('NA')}
											{userAbout?.RegisteredAs?.toLowerCase() === 'firm'
												? ' - ' + userAbout?.firm_name
												: ''}
										</p>
									</div>
									{dmaSelector?.dmaFlow?.isAndro ? (
										''
									) : (
										<div className='d-flex gap-2'>
											<img
												src='/images/icons/teams/product.svg'
												alt='dmatype'
											/>
											<p className='text-grey-light text-capitalize fs-14 mb-0'>
												{userAbout?.ProductName?.length > 0
													? userAbout?.ProductName.join(', ')
													: t('NA')}
											</p>
										</div>
									)}

									<div className='d-flex gap-2'>
										<img
											src='/images/icons/teams/manager.svg'
											alt='dmatype'
										/>
										<p className='text-grey-light text-capitalize fs-14 mb-0'>
											{dmaInfo?.manager_name ? dmaInfo?.manager_name : t('NA')}
										</p>
									</div>
									{dmaSelector?.dmaFlow?.isAndro ? (
										''
									) : (
										<div className='d-flex gap-2'>
											<img
												src='/images/icons/teams/location.svg'
												alt='dmatype'
											/>
											<p className='text-grey-light text-capitalize fs-14 mb-0'>
												{userAbout?.city || userAbout?.state
													? userAbout?.city + ', ' + userAbout?.state
													: t('NA')}
											</p>
										</div>
									)}
								</IonList>
							</IonItem>
							<IonList
								lines='none'
								inset={true}
								className='mt-0 pt-0'
							>
								<div className='d-flex gap-1 gap-md-3'>
									<CustomButton
										href={'tel:+91' + dmaMobile}
										className='flex-grow-1 py-1 fs-16 dma-btn'
									>
										<img
											src='/images/icons/teams/call.svg'
											alt=''
											className='me-2'
										/>

										{isWeb ? '+91 ' + (userAbout?.mobile || '') : t('Call')}
									</CustomButton>
									<CustomButton
										href={'https://api.whatsapp.com/send?phone=91' + dmaMobile}
										className='flex-grow-1  py-1 fs-16 dma-btn'
									>
										<img
											src='/images/icons/leads/whatsapp.svg'
											alt=''
											className='me-1'
										/>
										{t('WhatsApp')}
									</CustomButton>
									{dmaSelector?.dmaFlow?.dmaCode === StatusNum.ACTIVE && (
										<>
											{dmaSelector?.dmaFlow?.isAndro ? (
												''
											) : (
												<CustomButton
													className='dma-btn'
													style={{
														display: 'flex',
														alignSelf: 'center',
														height: '100%',
														justifyContent: 'center',
													}}
													onClick={() => setShareModalOpen(true)}
												>
													<span
														style={{
															width: '32px',
															height: '32px',
															display: 'flex',
															borderRadius: '50px',
															background: '#F3F3F3',
															padding: '3px 2px',
															justifyContent: 'center',
															alignContent: 'center',
														}}
													>
														<img
															src='/images/icons/teams/view-dots.svg'
															alt='whatsapp'
														/>
													</span>
												</CustomButton>
											)}
										</>
									)}
								</div>
							</IonList>
							<Divider />
							{isButtonNeeded && (
								<>
									<IonList
										lines='none'
										inset={true}
										className='text-center p-3 m-0'
									>
										<p className='mb-3 fs-16 darkest-grey fw-600'>
											{actionTitle}
										</p>
										<CustomButton
											className='btn-blue w-100 dashboard-add-lead-button'
											expand='block'
											title={t('Proceed')}
											onClick={() => {
												if (userAbout?.status === StatusNum.PARENTCHILD) {
													setMarkParentChildModel(true);
													return false;
												}
												if (
													userAbout?.status === StatusNum.PAYOUTAPPROVAL &&
													!dmaSelector?.dmaFlow?.dmaTag
												) {
													setDueDilligenceModal(true);
													return false;
												}
												if (
													userAbout?.status === StatusNum.PAYOUTAPPROVAL &&
													[
														'Due Diligence Initiated Successfully',
														'Due Diligence Initiated',
													].includes(dmaSelector?.dmaFlow?.dmaTag)
												) {
													setUpdateStatusDueDilligenceModal(true);
													return false;
												}
												if (userAbout?.status === StatusNum.REWORK) {
													history.replace(actionTarget, {
														id: dmaInfo.username_id,
														list_status: userAbout?.status,
													});
													return false;
												}
												dispatch(kycStep(currentStep ? currentStep : ''));
												history.replace(actionTarget, {
													updateApi: 1,
												});
											}}
										/>
									</IonList>
									<Divider />
								</>
							)}

							<IonList>
								<IonCard className='shadow-none border border-1 blue-corner-linerar-gradient mx-3'>
									<IonCardContent className=''>
										<div className='d-flex justify-content-between flex-grow-1'>
											<p className='mb-0  fs-24 fw-bold text-black'>
												{disbursedLeads}
											</p>
										</div>
										<h4 className='fs-16 mb-2'>{t('Total Disbursed Leads')}</h4>
										{/* <CustomSelect
									setInterface='action-sheet'
									onIonChange={({ detail: { value } }: any) =>
									}
									style={{ minHeight: '30px', width: 'fit-content' }}
									data={[
										{
											id: 1,
											name: 'This Month',
										},
										{
											id: 2,
											name: 'Last 3 Months',
										},
										{
											id: 3,
											name: 'Last 6 Months',
										},
									]}
									placeholder={'This Month'}
									className={'custom-input primary-text-color'}
								/> */}
										<DropDownModal
											data={disbursedDropDown}
											name={'select'}
											// onBlur={onBlur}
											setValue={setDisburseDropDownLabel}
											value={disburseDropDownLabel}
											errors={''}
											onChange={async (e: any) => {
												getDisbursedCount(e.target.value);
											}}
										/>
									</IonCardContent>
								</IonCard>
							</IonList>
							<Divider />
							{dmaSelector?.dmaFlow?.isAndro ? (
								''
							) : (
								<>
									<div className='m-3'>
										<h4 className='fw-600 fs-16 darkest-grey'>
											{t('DMA Info')}
										</h4>
										{/* <div className='mt-2 d-flex justify-content-between'>
							<p className='mb-0 fs-14 darkest-grey  fw-600'>{t('Location')}</p>
							<p className='mb-0 fs-14 darkest-grey fw-600'>
								{userAbout?.city
									? userAbout?.city + ','
									: 'NA' + userAbout?.state}
							</p>
						</div> */}
										{dmaInfo?.status === StatusNum.REJECTED ? (
											''
										) : (
											<div
												className='mt-2 d-flex justify-content-between pb-2'
												style={{ borderBottom: '1px solid #00000029' }}
											>
												<p className='mb-0 fs-14 darkest-grey'>
													{t('Onboarded on')}
												</p>
												<p className='mb-0 fs-14 darkest-grey fw-600'>
													{FormatDate(dmaInfo?.createdAt, 'dd mmmm yyyy')}
												</p>
											</div>
										)}

										{dmaInfo?.status === StatusNum.REJECTED ? (
											<div
												className='mt-2 d-flex justify-content-between pb-2'
												style={{ borderBottom: '1px solid #00000029' }}
											>
												<p className='mb-0 fs-14 darkest-grey'>
													{t('Rejected on')}
												</p>
												<p className='mb-0 fs-14 darkest-grey fw-600'>
													{FormatDate(dmaInfo?.rejected_on, 'dd mmmm yyyy')}
												</p>
											</div>
										) : (
											''
										)}

										<div className='mt-2 d-flex justify-content-between'>
											<p className='mb-0 fs-14 darkest-grey'>{t('Mobile')}</p>
											<p className='mb-0 fs-14 darkest-grey fw-600'>
												{userAbout?.mobile}
											</p>
										</div>

										<CustomButton
											fill='clear'
											onClick={() => history.push('/dma-about')}
											className='my-3 mb-0 fw-bold fs-14 ion-no-padding'
										>
											<img
												src='/images/icons/teams/info.svg'
												alt='info'
												className='me-2'
											/>
											<span className='text-transform-none'>
												{t('See Profile for More Details')}
											</span>
										</CustomButton>
									</div>
									<Divider />
								</>
							)}

							{![
								StatusNum?.INIT,
								StatusNum.KYC,
								StatusNum.KYC1,
								StatusNum.KYC2,
								StatusNum.KYC3,
								StatusNum.KYC4,
								StatusNum.PAYOUT,
							].includes(userAbout?.status) && (
								<>
									{dmaSelector?.dmaFlow?.isAndro ||
									dmaInfo?.status === StatusNum.REJECTED ? (
										''
									) : (
										<>
											<div
												className='ion-padding'
												onClick={() => {
													if (
														[
															StatusNum.KYC,
															StatusNum.KYC1,
															StatusNum.KYC2,
															StatusNum.KYC3,
															StatusNum.KYC4,
														].includes(userAbout?.status) === false
													) {
														if (userAbout?.status !== StatusNum.PAYOUT) {
															history.push('/payout-details', { update: true });
														}
													}
												}}
											>
												<div className='d-flex flex-wrap justify-content-between'>
													<div className='fs-20 fw-600'>
														{t('Products & Payout Info')}
													</div>
													<img src='/images/icons/right-arrow-blue-icon.svg' />
													{[
														StatusNum.KYC,
														StatusNum.KYC1,
														StatusNum.KYC2,
														StatusNum.KYC3,
														StatusNum.KYC4,
													].includes(userAbout?.status) && (
														<div
															style={{ color: '#D86861' }}
															className='w-100  fs-14'
														>
															<img
																src='/images/icons/red-info.svg'
																className='me-1'
																width={16}
																height={16}
																alt=''
															/>{' '}
															{t('Complete the KYC for Payout')}
														</div>
													)}
													{userAbout?.status === StatusNum.PAYOUT && (
														<div
															style={{ color: '#D86861' }}
															className=' fs-14 d-flex ion-align-items-center'
														>
															<img
																src='/images/icons/red-info.svg'
																className='me-1'
																width={16}
																height={16}
																alt=''
															/>{' '}
															{t('Pending')}
														</div>
													)}
												</div>
											</div>
											<Divider />
										</>
									)}
								</>
							)}
							{dmaInfo?.status === StatusNum.REJECTED ? (
								''
							) : (
								<div className='m-3'>
									<h4 className='fw-bold fs-20'>{t('Team Info')}</h4>
									<IonItem
										className='ion-no-padding remove-ion-item-inner-padding-end'
										lines='full'
									>
										<div className='mt-2 d-flex flex-grow-1 justify-content-between'>
											<p className='mb-0 fs-14'>{t('BUH')}</p>
											<p className='mb-0 fs-14 fw-600'>
												{dmaInfo?.buh_name ? dmaInfo?.buh_name : t('NA')}
											</p>
										</div>
									</IonItem>
									<IonItem
										className='ion-no-padding remove-ion-item-inner-padding-end'
										lines='full'
									>
										<div className='mt-2 d-flex flex-grow-1 justify-content-between'>
											<p className='mb-0 fs-14'>{t('Senior Manager')}</p>
											<p className='mb-0 fs-14 fw-600'>
												{dmaInfo?.senior_manager_name
													? dmaInfo?.senior_manager_name
													: t('NA')}
											</p>
										</div>
									</IonItem>
									<IonItem
										className='ion-no-padding remove-ion-item-inner-padding-end'
										lines='none'
									>
										<div className='mt-2 d-flex flex-grow-1 justify-content-between'>
											<p className='mb-0 fs-14'>{t('Manager')}</p>
											<p className='mb-0 fs-14 fw-600'>
												{dmaInfo?.manager_name
													? dmaInfo?.manager_name
													: t('NA')}
											</p>
										</div>
									</IonItem>
								</div>
							)}

							{dmaSelector?.dmaFlow?.isAndro ? (
								''
							) : (
								<>
									<Divider />
									<ActivitySection activityList={activityDma} />
								</>
							)}
						</div>
					</div>
				</IonContent>

				{userAbout?.status === StatusNum.NEWAPPROVAL && (
					<div className='update_reject_dma d-flex justify-content=between ion-padding'>
						<CustomButton
							expand={'block'}
							className='mb-4 w-100 btn-primary-outline'
							fill='outline'
							title={t('Reject')}
							onClick={() => {
								setRejectionModal(true);
							}}
						/>
						<CustomButton
							expand={'block'}
							className='mb-4 w-100  btn-blue'
							title={t('Update-Details')}
							onClick={async () => {
								await dispatch(
									dmaFlowData({
										dmaId: dmaSelector?.dmaFlow?.dmaId,
										dmaMobile: userAbout?.mobile,
									})
								);
								history.push('/required-update-details');
							}}
						/>
					</div>
				)}
			</IonPage>

			<CustomModal
				id='customModalRework'
				isOpen={showAssignModal}
				modalTitle={t('Select Team Member')}
				initialBreakpoint={1}
				handleClose={() => setShowAssignModal(false)}
				breakpoints={[0, 1]}
				className='assignModal side-modal'
				modalContent={
					<CustomAllocateManagers
						eventType='Dma'
						id={dmaSelector?.dmaFlow?.dmaId}
						setShowAssignModal={setShowAssignModal}
					/>
				}
				scrollY={true}
				needCross={true}
				needArrow={false}
			></CustomModal>
			{/* Share Lead Profile Modal */}
			<CustomModal
				id='sharemodal'
				isOpen={shareModalOpen}
				initialBreakpoint={
					isWeb
						? 1
						: dmaSelector?.dmaFlow?.dmaCode === StatusNum?.ACTIVE
						? 0.3
						: 0.2
				}
				handleClose={() => setShareModalOpen(false)}
				breakpoints={[0.3, 0.25, 0.75]}
				modalContent={<ShareModal />}
				needCross={false}
				needArrow={false}
				className={`side-modal ${isWeb ? '' : 'modal-border-radius'}`}
			/>

			<CustomModal
				id='rejectionmodal'
				isOpen={rejectionModal}
				initialBreakpoint={isWeb ? 1 : 0.5}
				className='assignModal side-modal'
				// initialBreakpoint={0.5}
				handleClose={() => setRejectionModal(false)}
				breakpoints={[0, 0.3, 0.5, 0.75]}
				modalContent={<RejectionModal setRejectionModal={setRejectionModal} />}
				needCross={false}
				needArrow={false}
			/>
			<CustomModal
				id='customModalRework'
				isOpen={markParentChildModel}
				initialBreakpoint={1}
				className='side-modal'
				// modalHeight='650px'
				handleClose={() => setMarkParentChildModel(false)}
				breakpoints={[0, 1]}
				modalContent={
					<MarkParentChildModel {...{ setMarkParentChildModel, dmaSelector }} />
				}
				needCross={true}
				needArrow={false}
				scrollY={true}
			/>
			<CustomModal
				id='duedilligence'
				isOpen={dueDilligenceModal}
				initialBreakpoint={isWeb ? 1 : 0.3}
				className='side-modal'
				handleClose={() => setDueDilligenceModal(false)}
				breakpoints={[0, 0.3, 0.55, 0.75, 1]}
				modalContent={<DueDilligenceModal />}
				needCross={false}
				needArrow={false}
			/>
			<CustomModal
				id='updatestatusduedilligence'
				isOpen={updateStatusDueDilligenceModal}
				initialBreakpoint={isWeb ? 1 : 0.3}
				className='side-modal h-100'
				handleClose={() => setUpdateStatusDueDilligenceModal(false)}
				breakpoints={[0, 0.2, 0.55, 0.75]}
				modalContent={<UpdateStatusDueDilligence />}
				needCross={false}
				needArrow={false}
			/>
			{/* Lead Activity Modal */}
			{/* <CustomModal
				id='activity'
				isOpen={leadActivityModalOpen}
				modalTitle={t('Activity')}
				initialBreakpoint={undefined}
				handleClose={() => setExitModal(true)}
				breakpoints={[]}
				modalContent={<LeadActivityModal />}
				needCross={true}
				needArrow={false}
				scrollY={true}
			/> */}

			<CustomModal
				id='custommodalallocate'
				isOpen={allocateAndroManagerModal}
				modalTitle='Update Stage'
				initialBreakpoint={undefined}
				handleClose={() => setAllocateAndroManagerModal(false)}
				breakpoints={[]}
				className='allocate-andro-manager'
				modalContent={
					<div className='wrapper pt-3 '>
						<IonItem lines='none'>
							<div className='d-flex flex-column'>
								<h5 className='mx-0 fs-20'>
									{t(
										'Are you sure you want to allocate this lead to Andro Manager?'
									)}
								</h5>
								<p className='m-0 fs-14'>
									{t(`By doing this you will not be able to take any action on this
                  lead anymore.`)}
								</p>
							</div>
						</IonItem>
						<div className='d-flex justify-content-center gap-3  mt-1'>
							<CustomButton
								onClick={() => setAllocateAndroManagerModal(false)}
								className='w-50 dashboard-add-lead-button'
								fill='outline'
								title={t('Cancel')}
							/>
							<CustomButton
								className='w-50 btn-blue'
								fill='solid'
								onClick={async () => {
									setAllocateAndroManagerModal(false);
									// Allocated to Andro Manager Successfully
									history.replace('/custom-successfull', {
										type: 'AAM',
										message: 'Allocated to Andro Manager Successfully',
										redirectTo: '/dashboard/teams',
									});

									await dispatch(
										customSuccessfull({
											type: 'AAM',
											message: 'Allocated to Andro Manager Successfully',
											redirectTo: '/dashboard/teams',
										})
									);
								}}
								title={t('Confirm')}
							/>
						</div>
					</div>
				}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
