import * as yup from 'yup';
import { REGEX } from '../../../../../helpers/constant';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { CustomInput } from '../../../../../components/common/custom-input/CustomInput';
import { CustomButton } from '../../../../../components/common/custom-button/CustomButton';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { apiKycDocumentUpload } from '../../../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { disableNumberArrows } from '../../../../../helpers/functions';

interface Props {
	userId: number;
	data: any;
	setUserData: any;
	setShowAadharInfoForm: any;
}

const aadharEditInfoSchema = yup.object().shape({
	No: yup
		.string()
		.required('Aadhaar No. is required')
		.max(12, 'Aadhaar No. should not be more than 12 digits')
		.matches(REGEX.AADHAAR, 'Aadhaar No. Should Be XXXX XXXX XXXX Format'),
	Name: yup
		.string()
		.trim()
		.required('Aadhaar Name is required')
		.matches(REGEX.FULLNAME, 'Please Enter Valid Name'),
});

export const AadharEdit: React.FC<Props> = ({
	userId,
	data,
	setUserData,
	setShowAadharInfoForm,
}) => {
	const dispatch: any = useDispatch();
	const { t } = useTranslation();
	useEffect(() => {
		reset(data);
	}, []);

	const {
		handleSubmit,
		control,
		reset,
		trigger,
		setValue,
		getValues,
		formState: { errors, isValid },
	}: any = useForm<any>({
		mode: 'all',
		reValidateMode: 'onChange',
		resolver: yupResolver(aadharEditInfoSchema),
		defaultValues: {
			No: '',
			Name: '',
		},
	});

	const onSubmit = async (formData: any) => {
		data.No = formData.No;
		data.Name = formData.Name;
		setUserData(data);
		await dispatch(
			apiKycDocumentUpload({
				param: '/' + userId,
				data: {
					Type: 'Aadhaar',
					RW: true,
					No: formData.No,
					Name: formData.Name,
				},
			})
		)
			.then(unwrapResult)
			.then((urlresult: any) => {
				if (urlresult?.status) {
					data.No = formData.No;
					data.Name = formData.Name;
					setUserData(data);
					setShowAadharInfoForm(false);
				}
			});
	};

	const inputFields = [
		{
			name: 'No',
			type: 'number',
			placeholder: 'Aadhaar No *',
		},
		{
			name: 'Name',
			type: 'text',
			placeholder: 'Full Name (as per Aadhaar) *',
		},
	];

	return (
		<section
			className='overflowX-hidden'
			style={{ height: 'calc(100vh - 180px)' }}
		>
			<div>
				<form onSubmit={handleSubmit(onSubmit)}>
					{inputFields?.map(({ name, type, placeholder }: any) => {
						return (
							<Controller
								key={name}
								control={control}
								render={({ field: { onChange, onBlur, name: name1 } }) => (
									<CustomInput
										type={type}
										name={name1}
										placeholder={t(placeholder)}
										onBlur={onBlur}
										onChange={onChange}
										value={getValues(name1)}
										errors={t(errors[name1]?.message)}
										maxLength={50}
										onBeforeInput={(evt: any) => {
											if (type === 'number') {
												!/[0-9]/.test(evt.data) && evt.preventDefault();
											}
											if (name === 'No') {
												if (evt.target.value.length === 12) {
													evt.preventDefault();
												}
											}
										}}
										onPaste={(e: any) => {
											//Only get numbers on user paste
											if (name == 'No') {
												e.preventDefault();
												let pasteContent = e.clipboardData.getData('Text');
												if (pasteContent != '') {
													pasteContent = pasteContent.replace(/\D/g, '');
													if (pasteContent.length > 10) {
														pasteContent = pasteContent.slice(0, 12);
													}
												}
												setValue(name, pasteContent);
												trigger(name);
											}
										}}
										onKeyDown={(e: any) => {
											if (type == 'number') {
												disableNumberArrows(e);
											}
										}}
										onWheel={(e: any) => {
											if (type == 'number') {
												e.target.blur();
											}
										}}
										// maxLength={10}
									/>
								)}
								name={name}
							/>
						);
					})}

					<div
						className='position-fixed w-100 bottom-0 start-0 px-3 pb-3'
						style={{ backgroundColor: '#fff' }}
					>
						<CustomButton
							className='btn-blue'
							type='submit'
							expand='block'
							title={t('Update')}
							disabled={!isValid}
						/>
					</div>
				</form>
			</div>
		</section>
	);
};
