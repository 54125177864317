import { IonContent, IonFooter } from '@ionic/react';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	getLeadFlowUrl,
	// lenderSpfDetailsFlow,
	lenderTataApplicationStatus,
	lenderTataUpdateLead,
} from '../../../../store/reducers/lead/leadSlice';
import { CustomInput } from '../../../../components/common/custom-input/CustomInput';
import {
	ContinueLaterPopUp,
	checkFieldsValidToContinue,
	disableNumberArrows,
	fetchStateCityByPincode,
	getBlankKeyFromObject,
	getDecrypted,
	showToastAlert,
} from '../../../../helpers/functions';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import {
	customerType,
	loanPurposeBL,
	accommodationTypeBL,
	correspondingFinancialYearBL,
	// TataPLFormTitle,
	refDetailsTwo,
	TataPLRef2Schema,
	referenceType,
} from '../TataCapitals';
import { LenderNameForAPICall } from '../../../../helpers/lenders';
import {
	CustomModalLeadStatus,
	DynamicDropdown,
} from '../../../../components/common';
import { LENDER_SPECIFIC_DATA, REGEX } from '../../../../helpers/constant';
import StateCity from '../../../../components/common/StateCity';
import { OfferRejectedMsg } from '../../OfferRejectedMsg/OfferRejectedMsg';

export const PLReferenceTwo: React.FC<any> = () => {
	const [modalPageClose, setModalPageClose] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const [url, setUrl] = useState('');
	const [pinCodeData, setPinCodeData] = useState<any>([]);
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const { selectedLender, lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);
	const [applicationNo, setApplicationNo] = useState('');
	const [leadStatusModalOpen, setLeadStatusModalOpen] = useState(false);

	const localData = localStorage.getItem(LENDER_SPECIFIC_DATA)
		? JSON.parse(getDecrypted(localStorage.getItem(LENDER_SPECIFIC_DATA)))
		: '';

	const {
		handleSubmit,
		control,
		getValues,
		formState: { errors, isValid },
		setValue,
		trigger,
	} = useForm({
		resolver: yupResolver(TataPLRef2Schema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			ReferenceFirstName2:
				lenderSpecificDetails?.lender_spf_details?.ReferenceFirstName2,
			ReferenceLastName2:
				lenderSpecificDetails?.lender_spf_details?.ReferenceLastName2,
			ReferenceMobileNumber2:
				lenderSpecificDetails?.lender_spf_details?.ReferenceMobileNumber2,
			ReferenceState2:
				lenderSpecificDetails?.lender_spf_details?.ReferenceState2,
			ReferenceCity2: lenderSpecificDetails?.lender_spf_details?.ReferenceCity2,
			ReferencePincode2:
				lenderSpecificDetails?.lender_spf_details?.ReferencePincode2,
			ReferenceAddress2:
				lenderSpecificDetails?.lender_spf_details?.ReferenceAddress2,
			ReferenceLandmark2:
				lenderSpecificDetails?.lender_spf_details?.ReferenceLandmark2,
			ApplicantRelationship2:
				lenderSpecificDetails?.lender_spf_details?.ApplicantRelationship2,
			AddressType2:
				lenderSpecificDetails?.lender_spf_details?.AddressType2 ||
				'OfficeAddress',
		},
	});

	/**
	 * On submit to save user data and after that
	 * @param data Form data
	 */

	useEffect(() => {
		if (!location.pathname.includes('/lead-lender-specific-form-steps')) {
			return;
		} else {
			const fetchLeadFlowUrl = async () => {
				try {
					const response = await dispatch(getLeadFlowUrl());
					const url = response.payload.url;
					const pathname = new URL(url).origin;
					setUrl(pathname);
				} catch (error) {
					console.error('Error fetching lead flow URL:', error);
				}
			};
			fetchLeadFlowUrl();
		}
	}, [location.pathname]);

	/**
	 * On close of lead status modal redirect to external URL
	 */
	const onLeadStatusModalClose = () => {
		setLeadStatusModalOpen(false);
	};

	const onSubmit = async (data: any) => {
		console.log(data, 'data on submit');
		setShowOfferErrMsg('');
		const blankKey = getBlankKeyFromObject(JSON.parse(JSON.stringify(data)));
		const endpoint = '/lead-lender-specific-form-steps';
		const req: any = {
			lender_spf_details: data,
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.TATA_BL,
			step: Object.keys(blankKey).length > 0 ? 'step2' : 'step3',
			isNewLead: Object.keys(blankKey).length === 0,
			// sourceSystemURL: `${process.env.REACT_APP_UI_ENDPOINT}/lead-lender-specific-form-steps`,
			sourceSystemURL: url + endpoint,
		};

		if (lenderSpecificDetails?.lead_spf_detail_id) {
			req.lead_spf_detail_id = lenderSpecificDetails?.lead_spf_detail_id;
		}

		dispatch(
			lenderTataUpdateLead({
				data: {
					...data,
					lender_name: LenderNameForAPICall.TATA_PL,
					lead_spf_detail_id: lenderSpecificDetails?.lead_spf_detail_id,
					lead_id: Number(lenderSpecificDetails?.tataApplicationId),
					// leadFlowData?.leadId || Number(lenderSpecificDetails?.lead_id),
					ReferenceFirstName1: localData?.ReferenceFirstName1,
					ReferenceLastName1: localData?.ReferenceLastName1,
					ReferenceMobileNumber1: localData?.ReferenceMobileNumber1,
					ReferenceState1: localData?.ReferenceState1,
					ReferenceCity1: localData?.ReferenceCity1,
					ReferencePincode1: localData?.ReferencePincode1,
					ReferenceAddress1: localData?.ReferenceAddress1,
					ReferenceLandmark1: localData?.ReferenceLandmark1,
					ApplicantRelationship1: localData?.ApplicantRelationship1,
					AddressType1: localData?.AddressType1 || 'OfficeAddress',
					lead_application_id: leadFlowData?.lead_app_id,
					// ['APPLICATION-ID']: lenderSpecificDetails?.tataApplicationId,
				},
			})
		).then((res: any) => {
			try {
				if (res?.payload?.status) {
					dispatch(
						lenderTataApplicationStatus({
							data: {
								lead_spf_detail_id: lenderSpecificDetails?.lead_spf_detail_id,
							},
						})
					).then((response: any) => {
						if (response?.payload?.status) {
							if (req.isNewLead) {
								const appNo: any = `
						Application No: ${
							response?.payload?.data?.response?.FinnOne_Application_Number ||
							response?.payload?.data?.andro_id
						}\n
							Webtop No: ${response?.payload?.data?.response?.WEBTOP_ID}\n
							Opportunity ID: ${response?.payload?.data?.response?.OpportunityId}
						`;
								setApplicationNo(appNo);
								setLeadStatusModalOpen(true);
							}
						}
					});
					// dispatch(
					// 	lenderSpfDetailsFlow({
					// 		...lenderSpecificDetails,
					// 		lead_spf_detail_id: lenderSpecificDetails?.lead_spf_detail_id,
					// 		headerTitle: TataPLFormTitle.UPLOAD_DOCUMENTS,
					// 		step: 'step5',
					// 	})
					// );
				} else {
					setShowOfferErrMsg(
						res?.payload?.error?.message || res?.payload?.message
					);
				}
			} catch (err) {
				setShowOfferErrMsg('Something went wrong! Please try again later.');
			}
		});
	};
	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
							<form onSubmit={handleSubmit(onSubmit)}>
								{refDetailsTwo.map(
									({ name, type, placeholder }: any, index: any) => {
										const errorText: any =
											errors && Object.keys(errors).length > 0 && errors;
										return (
											<div key={index}>
												{type === 'dropdown' ? (
													<div style={{ marginBottom: '15px' }}>
														<Controller
															control={control}
															render={({
																field: { onChange, onBlur, name },
															}) => (
																<DynamicDropdown
																	label={t(`${placeholder}`)}
																	data={
																		name == 'ApplicantRelationship2'? referenceType:
																		name === 'customer_type'
																			? customerType
																			: name === 'loan_purpose'
																			? loanPurposeBL
																			: name === 'accommodation_type'
																			? accommodationTypeBL
																			: name === 'corresponding_financial_year'
																			? correspondingFinancialYearBL
																			: []
																	}
																	name={name}
																	onChange={(e: any) => {
																		onChange(e);
																	}}
																	bindField={'value'}
																	showField1={
																		name === 'loan_purpose' ? 'title' : 'value'
																	}
																	value={getValues(name)}
																	onBlur={onBlur}
																	errors={errorText[name]?.message}
																/>
															)}
															name={name}
														/>
													</div>
												) : (
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<CustomInput
																type={type}
																label={t(placeholder)}
																onBlur={onBlur}
																id={name}
																onChange={(e: any) => {
																	if (name === 'ReferencePincode2') {
																		console.log(
																			e.detail.value.length,
																			'e.detail.value.length'
																		);

																		if (e.detail.value.length === 6) {
																			fetchStateCityByPincode(
																				setValue,
																				'ReferenceCity2',
																				'ReferenceState2',
																				setPinCodeData,
																				dispatch,
																				e.detail.value,
																				trigger
																			);
																		} else {
																			setValue('ReferenceState2', '');
																			setValue('ReferenceCity2', '');
																			setPinCodeData([]);
																		}
																	}
																	onChange(e);
																}}
																value={getValues(name)}
																onBeforeInput={(evt: any) => {
																	if (type == 'number') {
																		!REGEX.ALLOWNUMBERS.test(evt.data) &&
																			evt.preventDefault();
																	}
																}}
																onKeyDown={(e: any) => {
																	if (type == 'number') {
																		disableNumberArrows(e);
																	}
																}}
																onWheel={(e: any) => {
																	if (type == 'number') {
																		e.target.blur();
																	}
																}}
																className={`${
																	name === 'pan_no' ? 'text-uppercase' : ''
																}${
																	[
																		'ReferenceFirstName2',
																		'ReferenceLastName2',
																	].includes(name)
																		? ' text-capitalize '
																		: ''
																}`}
																errors={t(errorText[name]?.message || '')}
															/>
														)}
														name={name}
													/>
												)}
											</div>
										);
									}
								)}
								<StateCity
									cityInputName='ReferenceCity2'
									stateInputName='ReferenceState2'
									control={control}
									setValue={setValue}
									pinCodeData={pinCodeData}
									trigger={trigger}
									getValues={getValues}
								/>
							</form>

							<div className={` d-none d-md-block`}>
								<CustomButton
									className='w-100 fw-bold dashboard-add-lead-button mb-3'
									expand='block'
									fill='outline'
									title={t('Continue Later')}
									onClick={() => {
										if (checkFieldsValidToContinue(errors)) {
											setModalPageClose(true);
										} else {
											showToastAlert({
												type: 'error',
												message: 'Please fix above errors',
											});
										}
									}}
								/>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				)}

				<CustomModal
					needIonContentWraper={false}
					isOpen={modalPageClose}
					initialBreakpoint={undefined}
					handleClose={() => setModalPageClose(false)}
					breakpoints={[]}
					className='height-auto confirm-popup-middle continue-later'
					modalContent={ContinueLaterPopUp(
						() => {
							setModalPageClose(false);
						},
						() => {
							new Promise(function (myResolve) {
								setTimeout(function () {
									myResolve(history.push('/dashboard/lead'));
									onSubmit(getValues());
									setModalPageClose(false);
								}, 10);
							});
						},
						'Cancel',
						'Continue Later',
						'className',
						'Are You Sure You Want to Exit?',
						`${t('You can continue later from where you left off.')}`
					)}
					needCross={false}
					needArrow={false}
				/>
			</IonContent>
			{leadStatusModalOpen && (
				<CustomModalLeadStatus
					isOpen={leadStatusModalOpen}
					applicationNo={applicationNo}
					handleClose={() => onLeadStatusModalClose()}
				/>
			)}

			<IonFooter className='d-block d-md-none position-relative'>
				<div
					style={{
						backgroundColor: '#fff',
						zIndex: 2,
					}}
				>
					<div className={`ion-padding`}>
						<CustomButton
							className='w-100 fw-bold dashboard-add-lead-button mb-3'
							expand='block'
							fill='outline'
							title={t('Continue Later')}
							onClick={() => {
								if (checkFieldsValidToContinue(errors)) {
									setModalPageClose(true);
								} else {
									showToastAlert({
										type: 'error',
										message: 'Please fix above errors',
									});
								}
							}}
						/>
						<div className='proceed-btn'>
							<CustomButton
								type='submit'
								className='w-100 fw-bold dashboard-add-lead-button'
								expand='block'
								title={t('Proceed')}
								disabled={!isValid}
								onClick={handleSubmit(onSubmit)}
							/>
						</div>
					</div>
				</div>
			</IonFooter>
		</>
	);
};
