import React, { useState } from 'react';
import { Header } from '../../components/layout/header/Header';
import {
	IonContent,
	IonList,
	IonLabel,
	IonItem,
	IonAccordionGroup,
	IonAccordion,
	IonTextarea,
} from '@ionic/react';
import './CommanInfo.scss';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { UploadImageCard } from '../dashboard/kyc/UploadImageCard';
export const BankRelatedInfo: React.FC = () => {
	const [markCloseModal, setMarkCloseModal] = useState(false);
	const { t } = useTranslation();
	const {
		control,
		// setValue,
		// trigger,
		getValues,
		// reset,
		formState: { errors },
	}: any = useForm({
		// resolver: yupResolver(),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			pan_no: '',
			managerId: '',
		},
	});
	return (
		<>
			{/* <Menu /> */}
			<Header
				name='SBI Bank'
				needArrow={true}
				backButtonDisabled={false}
				backButton={true}
			/>
			<IonContent
				scrollY={true}
				className='background-web'
			>
				<div className='container-width-md bg-web h-80 overflowY-auto my-md-3'>
					<div
						className={` conatiner-spacing-md bg-web-white pt-md-3 border-md-radius-10 opacity-1`}
					>
						<IonList>
							<IonAccordionGroup value={'acc'}>
								<IonAccordion
									className={'my-3'}
									value={'acc'}
								>
									<IonItem
										slot='header'
										color=''
									>
										<IonLabel className='fs-14 fw-600'>Section 1</IonLabel>
									</IonItem>
									<div
										className='ion-padding'
										slot='content'
									>
										<IonItem
											className='ion-no-padding mb-2'
											lines='none'
										>
											<select className='form-control form-select py-2'>
												<option
													selected
													value=''
												>
													Select RM
												</option>
											</select>
										</IonItem>
										{/* <IonItem
									className='ion-no-padding'
									lines='none'
								> */}
										<div className='position-relative'>
											<form>
												<Controller
													control={control}
													render={({ field: { onChange, onBlur, name } }) => (
														<CustomInput
															placeholder={t('Label Name 1')}
															value={getValues(name)}
															onChange={onChange}
															onBlur={onBlur}
															errors={errors[name]?.message}
															needSuffixIcon={true}
															className='text-uppercase'
															suffixClassName={
																'position-absolute top-21 end-12'
															}
														/>
													)}
													name='Label_name_1'
												/>
												<Controller
													control={control}
													render={({ field: { onChange, onBlur, name } }) => (
														<CustomInput
															placeholder={t('Label Name 2')}
															value={getValues(name)}
															onChange={onChange}
															onBlur={onBlur}
															errors={errors[name]?.message}
															needSuffixIcon={true}
															className='text-uppercase'
															suffixClassName={
																'position-absolute top-21 end-12'
															}
														/>
													)}
													name='Label_name_2'
												/>
												<Controller
													control={control}
													render={({ field: { onChange, onBlur, name } }) => (
														<CustomInput
															placeholder={t('Label Name 3')}
															value={getValues(name)}
															onChange={onChange}
															onBlur={onBlur}
															errors={errors[name]?.message}
															needSuffixIcon={true}
															className='text-uppercase'
															suffixClassName={
																'position-absolute top-21 end-12'
															}
														/>
													)}
													name='Label_name_3'
												/>
												<Controller
													control={control}
													render={({ field: { onChange, onBlur, name } }) => (
														<CustomInput
															placeholder={t('Label Name 4')}
															value={getValues(name)}
															onChange={onChange}
															onBlur={onBlur}
															errors={errors[name]?.message}
															needSuffixIcon={true}
															className='text-uppercase'
															suffixClassName={
																'position-absolute top-21 end-12'
															}
														/>
													)}
													name='Label_name_4'
												/>
											</form>
										</div>
									</div>
								</IonAccordion>
							</IonAccordionGroup>

							<IonAccordionGroup className='pb-180'>
								<IonAccordion
									className={'my-3'}
									value={'acc'}
								>
									<IonItem
										slot='header'
										color=''
									>
										<IonLabel className='fs-14 fw-600'>
											{t('Upload Document') + 's'}
										</IonLabel>
									</IonItem>
									<div
										className='ion-padding'
										slot='content'
									>
										<h4 className='m-0 text-grey fs-14 pb-2'>Document Name</h4>
										<UploadImageCard
											{...{
												// setImagePreviewUpload,
												// imagePreviewUpload,
												// isImageAvilable: imagePreviewUpload.aadhaarBack,
												// setClickImageFieldName,
												// setOpenUploadModal,
												imgKey: 'aadhaarBack',
												// setValue: setValue,
												value: 'Aadhaar.Url2',
												title: 'Upload Document Name',
												src: getValues('Aadhaar.Url2'),
											}}
										/>
										<h4 className='m-0 text-grey fs-14 pb-2'>Document Name</h4>
										<UploadImageCard
											{...{
												// setImagePreviewUpload,
												// imagePreviewUpload,
												// isImageAvilable: imagePreviewUpload.aadhaarBack,
												// setClickImageFieldName,
												// setOpenUploadModal,
												imgKey: 'aadhaarBack',
												// setValue: setValue,
												value: 'Aadhaar.Url2',
												title: 'Upload Document Name',
												src: getValues('Aadhaar.Url2'),
											}}
										/>
									</div>
								</IonAccordion>
							</IonAccordionGroup>
							<div className='bottom-button-setter py-1'>
								<CustomButton
									className='w-100'
									onClick={() => setMarkCloseModal(true)}
									fill='outline'
									title={t('Mark as Closed')}
								/>
								<CustomButton
									routerLink='/all-lenders'
									expand='block'
									title={t('Proceed')}
								/>
							</div>
						</IonList>
					</div>
				</div>
			</IonContent>

			<CustomModal
				id='custommodal'
				className='markasclose overflow-hidden'
				isOpen={markCloseModal}
				modalTitle='Mark As Closed'
				initialBreakpoint={undefined}
				handleClose={() => setMarkCloseModal(false)}
				breakpoints={[]}
				modalContent={
					<div className='wrapper pt-3'>
						<IonItem lines='none'>
							<div className='d-flex flex-column'>
								<h5 className='mx-0'>
									{t('Are you sure you want to mark lender/s as closed?')}
								</h5>
								<select
									defaultValue={'Select Reason'}
									name=''
									id=''
									className='mt-3 form-control form-select'
								>
									<option value='Select Reason'>{t('Select Reason')}</option>
									<option value='Rate of Interest'>Rate of Interest</option>
									<option value='Loan Amount'>Loan Amount</option>
									<option value='Requirement already met'>
										Requirement already met
									</option>
									<option value='Picked up by another DSA'>
										Picked up by another DSA
									</option>
									<option value='Already Applied'>Already Applied</option>
								</select>
								<IonTextarea
									className='mt-2 p-1 border border-1'
									value=''
									labelPlacement='start'
									placeholder={`${t('Add Comment')}`}
									fill='outline'
									maxlength={300}
									label=''
									rows={3}
								></IonTextarea>
							</div>
						</IonItem>
						<div className='d-flex justify-content-center gap-3 px-3 mt-3'>
							<CustomButton
								onClick={() => setMarkCloseModal(false)}
								className='w-50'
								fill='outline'
								title={t('Cancel')}
							/>
							<CustomButton
								className='w-50'
								fill='solid'
								title={t('Confirm')}
							/>
						</div>
					</div>
				}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
