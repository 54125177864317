import {
	IonContent,
	IonImg,
	IonItem,
	IonLabel,
	// IonFooter,
	// IonHeader,
	// IonLabel,
	IonPage,
} from '@ionic/react';
import { Header } from '../../header/Header';
import { useHistory, useLocation } from 'react-router';
import './ViewProfile.scss';
import { useEffect, useState } from 'react';
import { converRuppesFormat } from '../../../../helpers/functions';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	imgUrlUpdate,
	userVerificationDetail,
} from '../../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { USER_ROLES } from '../../../../helpers/constant';
import { Divider } from '../../../common/divider/Divider';
import CustomToolTip from '../../../common/custom-tooltip/CustomToolTip';
const basicData = [
	{
		key: 'Mobile',
		value: '',
		entityType: 'individual',
	},
	{
		key: 'Email',
		value: '',
		entityType: 'individual',
	},
	{
		key: 'Firm PAN',
		value: '',
		entityType: 'firm',
	},
	{
		key: 'Firm Name',
		value: '',
		entityType: 'firm',
	},
	{
		key: 'Firm Email',
		value: '',
		entityType: 'firm',
	},
	{
		key: 'Pin Code',
		value: '',
		entityType: 'all',
	},
	{
		key: 'PAN',
		value: '',
		entityType: 'individual',
	},
	{
		key: 'State',
		value: '',
		entityType: 'all',
	},
	{
		key: 'City',
		value: '',
		entityType: 'all',
	},
];

const contactPersonData = [
	{
		key: 'Name',
		value: '',
	},
	{
		key: 'Mobile',
		value: '',
	},
	{
		key: 'Email',
		value: '',
	},
];
const personalData = [
	{
		key: 'Current Business',
		value: '',
	},
	{
		key: 'Associated with DSA',
		value: '',
	},
	{
		key: 'Product',
		value: '',
	},
	{
		key: 'Monthly Business Volume',
		value: '',
	},
	{
		key: 'Nature of Business',
		value: '',
	},
];

const defaultSrc = '/images/icons/default/user-profile-icon.svg';

function ViewProfile() {
	const history = useHistory();
	const location = useLocation();
	const userData = useSelector((state: any) => state?.users);
	const { t } = useTranslation();
	const [basicDetail, setBasicDetail] = useState<any>(basicData);
	const [presonalDetail, setPersonalDetail] = useState<any>(personalData);
	const [contactPersonDetail, setContactPersonDetail] =
		useState<any>(contactPersonData);
	const [src, setSrc] = useState('');
	const [entityType, setEntiyType] = useState('');
	const [signatory, setSignaTory] = useState('');
	const [basicDmaDetail, setBasicDmaDetail] = useState<any>([]);
	const dispatch = useDispatch();
	const roleType = useSelector(
		(state: any) => state?.users?.user_details?.roleType?.[0]
	);
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	console.log(isWeb, 'isWeb');

	useEffect(() => {
		if (location.pathname === '/view-profile') {
			getDashboard();
		}
	}, [location.pathname]);

	useEffect(() => {
		getUpdateUrl();
	}, [userData?.homepage?.profile?.url]);

	const getUpdateUrl = async () => {
		if (userData?.homepage?.profile?.url) {
			await dispatch(
				imgUrlUpdate({
					data: {
						url: userData?.homepage?.profile?.url,
						expire_time: 50000,
					},
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						setSrc(response?.url);
					}
				});
		} else {
			setSrc(defaultSrc);
		}
	};

	const getDashboard = async () => {
		await dispatch(userVerificationDetail())
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					const updateData = [
						{
							key: 'Mobile',
							value: response?.data?.basicInfo?.mobile || '-',
							entityType: 'individual',
						},
						{
							key: 'Email',
							value: response?.data?.basicInfo?.email || '-',
							entityType: 'individual',
						},
						{
							key: 'Firm PAN',
							value: response?.data?.basicInfo?.pan || '-',
							entityType: 'firm',
						},
						{
							key: 'Firm Name',
							value: response?.data?.basicInfo?.name || '-',
							entityType: 'firm',
						},
						{
							key: 'Firm Email',
							value: response?.data?.basicInfo?.email || '-',
							entityType: 'firm',
						},
						{
							key: 'Pin Code',
							value: response?.data?.basicInfo?.pin || '-',
							entityType: 'all',
						},
						{
							key: 'PAN',
							value: response?.data?.basicInfo?.pan || '-',
							entityType: 'individual',
						},
						{
							key: 'State',
							value: response?.data?.basicInfo?.state || '-',
							entityType: 'all',
						},
						{
							key: 'City',
							value: response?.data?.basicInfo?.city || '-',
							entityType: 'all',
						},
					];
					const updatePersonalData = [
						{
							key: 'Current Business',
							value: response?.data?.addressProof?.CurrentBussines || '-',
						},
					];

					if (
						response?.data?.addressProof?.CurrentBussines?.toLowerCase() ===
						'finance'
					) {
						updatePersonalData.push({
							key: 'Associated with DSA',
							value:
								response?.data?.addressProof?.DSAAssociation === '1'
									? 'Yes'
									: 'No',
						});

						if (response?.data?.addressProof?.DSAAssociation === '1') {
							updatePersonalData.push({
								key: 'Products',
								value: response?.data?.addressProof?.Product?.toString() || '-',
							});
							updatePersonalData.push({
								key: 'Monthly Business Volume',
								value: response?.data?.addressProof?.Income
									? '₹ ' +
									  converRuppesFormat(response?.data?.addressProof?.Income)
									: '-',
							});
						}
					}

					if (
						(response?.data?.addressProof?.CurrentBussines?.toLowerCase() ===
							'finance' &&
							response?.data?.addressProof?.DSAAssociation === '0') ||
						response?.data?.addressProof?.CurrentBussines?.toLowerCase() ===
							'non-finance'
					) {
						updatePersonalData.push({
							key: 'Nature of Business',
							value: response?.data?.addressProof?.BusinessNature || '-',
						});
					}

					if (
						response?.data?.addressProof?.CurrentBussines?.toLowerCase() ===
						'non-finance'
					) {
						updatePersonalData.push({
							key: 'Profession',
							value: response?.data?.addressProof?.Profession || '-',
						});
					}

					const contactPersonUpdateData = [
						{
							key: 'Name',
							value: response?.data?.basicInfo?.contact_person_name || '-',
						},
						{
							key: 'Mobile',
							value: response?.data?.basicInfo?.contact_person_mobile || '-',
						},
						{
							key: 'Email',
							value: response?.data?.basicInfo?.contact_person_email || '-',
						},
					];

					const basicData = [
						{
							key: 'Email',
							value: response?.data?.basicInfo?.email || '-',
						},
						{
							key: 'Role',
							value: response?.data?.basicInfo?.role || '-',
						},
						{
							key: 'Mobile',
							value: response?.data?.basicInfo?.mobile || '-',
						},
						{
							key: 'PAN',
							value: response?.data?.basicInfo?.pan || '-',
						},
					];

					setBasicDetail(updateData);
					setPersonalDetail(updatePersonalData);
					setContactPersonDetail(contactPersonUpdateData);
					setBasicDmaDetail(basicData);
					setEntiyType(response?.data?.basicInfo?.entityType || '');
					if (response?.data?.basicInfo?.entityType === 'firm') {
						setSignaTory(response?.data?.basicInfo?.signaTary);
					}
				}
			});
	};
	const userDetailNextLine = [
		'Nature of Business',
		'Email',
		'Firm Name',
		'Firm Email',
		'Products',
	];

	const authorisedSignatoryPan = basicDetail.find(
		(item: any) => item.key === 'PAN'
	);

	const mobileNumber = basicDetail.find((item: any) => item.key === 'Mobile');
	const isIndividual = entityType === 'individual';
	const isFirm = entityType === 'firm';
	const isProprietor = signatory === 'Proprietor';

	return (
		<IonPage className=''>
			<Header
				className={'view-profile-header'}
				backButton={true}
				needArrow={true}
				backButtonText=''
				headerBg={'#000'}
				style={{
					background: isWeb ? 'white' : '#cfdff7',
				}}
				needArrowImg={'/images/icons/backarrow_white_bg.png'}
				needArrowImgStyle={{ height: '26px' }}
				needArrowOnClick={() => history.push('/dashboard/home')}
				name=''
			/>
			<IonContent
				fullscreen
				className='help-desk'
			>
				{/* <IonContent> */}
				<div className='bg-web py-md-3'>
					<div className='bg-web-white container-width-md p-md-3 border-md-radius-10'>
						<div className='view-profile'>
							<div
								style={{
									position: 'relative',
									width: '100%',
									// borderRadius: '2%',
									// marginTop: '8px',
								}}
							>
								<IonImg
									src='/images/icons/shine-background.svg'
									alt='shine background icon'
									style={{
										width: '100%',
										zIndex: 1,
										position: 'absolute',
										right: 0,
										padding: '50px !important',
									}}
								></IonImg>
							</div>
							<IonItem
								className='view-profile-pic border-md-radius-10'
								lines='none'
								style={{ '--inner-padding-end': '0px' }}
							>
								<img
									src={src}
									alt='Profile icon'
									style={{
										height: 85,
										width: 85,
										borderRadius: '50%',
										objectFit: 'cover',
										marginTop: '20px',
									}}
								/>{' '}
								<div
									className='d-flex align-items-center mt-3'
									style={{ maxWidth: '90%' }}
								>
									<IonLabel
										className='text-capitalize fs-18 fw-600'
										style={{ maxWidth: 'calc(100% - 20px)' }}
									>
										{userData?.homepage?.profile?.name || ''}
									</IonLabel>
									<IonImg
										className='ms-1'
										src='/images/icons/verified-check-mark.png'
										alt='verified'
										style={{ width: '16px', height: '16px' }}
									/>
								</div>
							</IonItem>
							{[USER_ROLES.DMA, USER_ROLES.DMAUL].includes(roleType) ? (
								<>
									{isFirm && (
										<Proprietor
											{...{
												authorisedSignatoryPan,
												isProprietor,
												mobileNumber,
											}}
										/>
									)}
									<BasicInfoHead entityType={isIndividual} />
									{basicDetail.map((item: any, index: number) => {
										if (isProprietor && item.key === 'Firm’s PAN') {
											return null;
										} else {
											if (
												item.entityType === entityType ||
												item.entityType === 'all'
											) {
												return (
													<IonItem
														key={`${item.entityType}-${index}`}
														className='ion-margin-horizontal ion-no-padding remove-ion-item-inner-padding-end'
														lines={
															index === basicDetail.length - 1
																? 'none'
																: 'inset'
														}
													>
														<IonLabel>
															{t(item.key)}
															{userDetailNextLine.includes(item.key) && (
																<p
																	className='fs-16 fw-600 text-black ion-text-start'
																	style={{ color: 'black' }}
																>
																	{item.value}
																</p>
															)}
														</IonLabel>
														{!userDetailNextLine.includes(item.key) && (
															<IonLabel
																slot='end'
																className='fs-16 fw-600'
															>
																{item.value}
															</IonLabel>
														)}
													</IonItem>
												);
											}
										}
									})}
									<Divider className='mt-4' />
									{isFirm && <ContactDetail />}
									{isFirm &&
										contactPersonDetail.map((item: any, index: number) => {
											return (
												<IonItem
													key={`contactPersonDetail-${item.key}-${index}`}
													className='ion-margin-horizontal ion-no-padding remove-ion-item-inner-padding-end'
													lines={
														index === contactPersonDetail.length - 1
															? 'none'
															: 'inset'
													}
												>
													<IonLabel>
														{t(item.key)}
														{userDetailNextLine.includes(item.key) && (
															<p
																className='fs-16 fw-600 text-black ion-text-start'
																style={{ color: 'black' }}
															>
																{item.value}
															</p>
														)}
													</IonLabel>
													{!userDetailNextLine.includes(item.key) && (
														<IonLabel
															slot='end'
															className='fs-16 fw-600'
														>
															{item.value}
														</IonLabel>
													)}
												</IonItem>
											);
										})}
									{isFirm && <Divider className='mt-4' />}
									<IonItem lines='none'>
										<IonLabel className='fs-16 fw-600'>
											{t('Professional Info')}
										</IonLabel>
										{/* <IonLabel
						slot='end'
						color={'primary'}
						className='fw-600'
					>
						<img
							src='/images/icons/edit-blue-icon.svg'
							alt='edit icon'
						/>
						Edit
					</IonLabel> */}
									</IonItem>
									{presonalDetail.map((item: any, index: any) => {
										return (
											<IonItem
												className='ion-margin-horizontal ion-no-padding remove-ion-item-inner-padding-end'
												key={`presonalDetail-${index}`}
											>
												<IonLabel>
													{t(item.key)}
													{userDetailNextLine.includes(item.key) && (
														<p
															className='fs-16 fw-600 text-black ion-text-start'
															style={{ color: 'black' }}
														>
															{item.value}
														</p>
													)}
												</IonLabel>
												{!userDetailNextLine.includes(item.key) && (
													<IonLabel
														slot='end'
														className='fs-16 fw-600'
													>
														{item.value}
													</IonLabel>
												)}
											</IonItem>
										);
									})}
								</>
							) : (
								basicDmaDetail.map((item: any, index: number) => {
									return (
										<IonItem
											key={`basicDmaDetail-${index}`}
											className='ion-margin-horizontal ion-no-padding remove-ion-item-inner-padding-end'
											lines={
												index === basicDmaDetail.length - 1 ? 'none' : 'inset'
											}
										>
											<IonLabel>
												{t(item.key)}
												{userDetailNextLine.includes(item.key) &&
													item.value !== '-' && (
														<p
															className='fs-16 fw-600 text-black ion-text-start'
															style={{ color: 'black' }}
														>
															{t(item.value)}
														</p>
													)}
											</IonLabel>
											{item.key === 'Email' && item.value === '-' && (
												<IonLabel
													slot='end'
													className='fs-16 fw-600'
												>
													{item.value}
												</IonLabel>
											)}
											{!userDetailNextLine.includes(item.key) && (
												<IonLabel
													slot='end'
													className='fs-16 fw-600'
												>
													{item.value}
												</IonLabel>
											)}
										</IonItem>
									);
								})
							)}
						</div>
					</div>
				</div>
			</IonContent>

			{/* </IonContent> */}
		</IonPage>
	);
}

const Proprietor = ({
	isProprietor,
	authorisedSignatoryPan,
	mobileNumber,
}: any) => {
	return (
		<>
			<IonItem
				className='ion-margin-horizontal ion-no-padding remove-ion-item-inner-padding-end'
				lines={'none'}
			>
				<IonLabel className='fs-16 fw-600'>
					{t('Authorised Signatory Info')}
				</IonLabel>
			</IonItem>
			<IonItem
				className='ion-margin-horizontal ion-no-padding remove-ion-item-inner-padding-end'
				lines={isProprietor ? 'inset' : 'none'}
			>
				<IonLabel>
					{/* {item.key} */}

					<p
						className='fs-16 fw-600 text-black ion-text-start'
						style={{ color: 'black' }}
					>
						{t('Mobile')}
					</p>
				</IonLabel>

				<IonLabel
					slot='end'
					className='fs-16 fw-600'
				>
					{mobileNumber?.value}
				</IonLabel>
			</IonItem>
			{isProprietor && (
				<IonItem
					className='ion-margin-horizontal ion-no-padding remove-ion-item-inner-padding-end'
					lines={'none'}
				>
					<IonLabel>
						{/* {item.key} */}

						<p
							className='fs-16 fw-600 text-black ion-text-start'
							style={{ color: 'black' }}
						>
							{t('Authorised Signatory PAN')}
						</p>
					</IonLabel>

					<IonLabel
						slot='end'
						className='fs-16 fw-600'
					>
						{authorisedSignatoryPan?.value}
					</IonLabel>
				</IonItem>
			)}
			<Divider className='mt-4' />
		</>
	);
};

const BasicInfoHead = ({ entityType }: any) => {
	const name = entityType ? t('Basic Info') : t('Firm Info');
	return (
		<IonItem lines='none'>
			<IonLabel className='fs-16 fw-600'>{name}</IonLabel>
			<IonLabel
				slot='end'
				color={'primary'}
				className='fw-600'
			>
				<button
					id='edit-info-tooltip-trigger'
					style={{
						background: 'transparent',
						color: 'var(--ion-color-primary)',
					}}
					className='d-flex align-items-center'
				>
					{/* <img
						src='/images/icons/edit-blue-icon.svg'
						alt='edit icon'
					/> */}
					<IonImg
						src='/images/icons/info-icon-primary.svg'
						alt='edit icon'
						className='pe-2'
					/>
					{t('Edit')}
				</button>
				{/* <CustomButton
					size='small'
					id='click-trigger'
					className='btn-primary'
					title={'Edit'}
					prefixIcon='/images/icons/info-icon-primary.svg'
				/> */}
				<CustomToolTip
					trigger='edit-info-tooltip-trigger'
					triggerAction='click'
					tooltiptext={t(
						'Contact Andro Manager for Any Update Required in Profile'
					)}
				/>
			</IonLabel>
		</IonItem>
	);
};

const ContactDetail = () => {
	return (
		<IonItem lines='none'>
			<IonLabel className='fs-16 fw-600'>{t('Contact Details')}</IonLabel>
			{/* <IonLabel
				slot='end'
				color={'primary'}
				className='fw-600'
			>
				<img
					src='/images/icons/edit-blue-icon.svg'
					alt='edit icon'
				/>
				Edit
			</IonLabel> */}
		</IonItem>
	);
};

export default ViewProfile;
