export class CommentRequest {
    id: number | null;
    commented_by: number;
    commented_by_name: string;
    comment: string;
    createdAt: Date | null;
    applicationId: number;
    updatedAt: Date | null;

    constructor(
        id: number | null = null,
        commented_by: number,
        commented_by_name: string = "",
        comment: string,
        createdAt: Date | null = null,
        applicationId: number,
        updatedAt: Date | null = null
    ) {
        this.id = id;
        this.commented_by = commented_by;
        this.commented_by_name = commented_by_name;
        this.comment = comment;
        this.createdAt = createdAt;
        this.applicationId = applicationId;
        this.updatedAt = updatedAt;
    }
}