import { IonHeader, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { screen } from '@testing-library/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import ApplicationContext from '../../context/ApplicationContext';
import { AddressDetailsForm } from '../../model/address_details_bean';
import AddressDetails from './address_details';




interface Props {
  title: string;
  isProposer: boolean;
  nextTabUrl?: string;
  inpCallback?: () => void;
  permAddressDetailsForm?: AddressDetailsForm;
  curAddressDetailsForm?: AddressDetailsForm;
  readonly:boolean;
  // userId: any;
  // setShowBasicInfoForm: any;
  // getUserDetailsInfo: any;
}



const AddressDetailsMain: React.FC<Props> = ({
  //  title, 
  isProposer,
  inpCallback, nextTabUrl,
  permAddressDetailsForm,
  curAddressDetailsForm,
  readonly = false,
}) => {
  const history = useHistory();


  const [currentTab, setCurrentTab] = useState<number>(1);

  // const errorColor:string = 'red';
  const errorColor:any = undefined;
  
  const handleTabChangeAddr = (tabIndex: number) => {
    setCurrentTab(tabIndex);
  };

  const moveToNextTabAddr = () => {
    setCurrentTab((prevTab) => prevTab + 1);
  };

  const { proposerAddressDetailsValid, setProposerAddressDetailsValid } = useContext(ApplicationContext);
  const { proposerPermAddressDetailsValid, setProposerPermAddressDetailsValid } = useContext(ApplicationContext);
  const { proposerCurAddressDetailsValid, setProposerCurAddressDetailsValid } = useContext(ApplicationContext);


  const { assuredAddressDetailsValid, setAssuredAddressDetailsValid } = useContext(ApplicationContext);
  const { assuredPermAddressDetailsValid, setAssuredPermAddressDetailsValid } = useContext(ApplicationContext);
  const { assuredCurAddressDetailsValid, setAssuredCurAddressDetailsValid } = useContext(ApplicationContext);


  const navigateAndmoveToNextTab = (nextTabUrl: string) => {
    if (inpCallback) {
      inpCallback();
    }

    if (nextTabUrl) {
      history.push(nextTabUrl);
    }
  };


  const firstTabButtonRef = useRef<HTMLIonTabButtonElement>(null);


  // useEffect(() => {
  //   // Call handleTabChangeAddr with index 1 to select the first tab
  //   if (firstTabButtonRef.current) {
  //     console.log(firstTabButtonRef.current);
  //     // firstTabButtonRef.current;
  //     history.push(firstTabButtonRef.current.href);
  //     // firstTabButtonRef.current.click();
  //   }

  //   handleTabChangeAddr(1);
  //   // history.push('/insurance/tabs/address/permaddress');
  // }, []); // 

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      
      const tabButtonAddress = screen.getAllByText('Permanent Address');

      let i = tabButtonAddress.length - 1;
      while (i >= 0) {
        tabButtonAddress[i].click();
        i--;
      }
      // tabButtonAddress.click();

    }, 100); // 3000 milliseconds = 3 seconds


    // Cleanup the timeout if the component unmounts
    return () => {
      clearTimeout(timeoutId);
    };
  }, []); 



  useEffect(() => {
    if (isProposer) {
      setProposerAddressDetailsValid(proposerCurAddressDetailsValid && proposerPermAddressDetailsValid);
    }
    else {
      setAssuredAddressDetailsValid(assuredCurAddressDetailsValid && assuredPermAddressDetailsValid);
    }
    // setAddressDetailsValid(curAddressDetailsValid&&permAddressDetailsValid);
  }, [proposerAddressDetailsValid, setProposerAddressDetailsValid,
    proposerPermAddressDetailsValid, setProposerPermAddressDetailsValid,
    proposerCurAddressDetailsValid, setProposerCurAddressDetailsValid,
    assuredAddressDetailsValid, setAssuredAddressDetailsValid,
    assuredPermAddressDetailsValid, setAssuredPermAddressDetailsValid,
    assuredCurAddressDetailsValid, setAssuredCurAddressDetailsValid,
  ]);

  console.log("isProposer",isProposer,proposerPermAddressDetailsValid);


  return (
    <IonReactRouter>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-8">
            <IonHeader>
              {/* <IonToolbar>
                <IonTitle className='ion-tab-title' >{title ?? 'Address Details'}</IonTitle>
              </IonToolbar> */}
            </IonHeader>
            {/* <IonHeader>
          <IonToolbar>
          <IonTitle className='ion-tab-title' >{title ?? 'Address Details'}</IonTitle>
          </IonToolbar>
          </IonHeader> */}
            <IonTabs>
              <IonRouterOutlet onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              
                <Route path="/insurance/tabs/address/permaddress" render={() => <AddressDetails readonly={readonly} isProposer={isProposer} addressDetailsForm={permAddressDetailsForm} inpCallback={moveToNextTabAddr} nextTabUrl='/insurance/tabs/address/curaddress' title='Permanent Address Details'  />} exact={true} />
                <Route path="/insurance/tabs/address/curaddress" render={() => <AddressDetails readonly={readonly} isProposer={isProposer} showCopyAddress={true} addressDetailsForm={curAddressDetailsForm} inpCallback={() => { navigateAndmoveToNextTab('/insurance/tabs/occupation'); }} title='Current Address Details'  />} exact={true} />
                {/* <Redirect exact path="/" to="/tabs/address/permaddress" /> */}
                <Redirect exact path="/tabs/address" to="/tabs/address/permaddress" />



              </IonRouterOutlet>
              <IonTabBar style={{ justifyContent: 'flex-center', display: 'flex', overflowX: 'auto', whiteSpace: 'nowrap', paddingLeft: '10px', paddingRight: '10px', flexWrap: 'nowrap', }} slot="bottom">

                {/* <IonTabBar style={{ overflowX: 'scroll'}} slot="bottom"> */}
                <IonTabButton className='custom-ion-tab-button' tab="permanent" style={{
                  color:
                    (isProposer ? !proposerPermAddressDetailsValid : !assuredPermAddressDetailsValid) ? errorColor : undefined,
                  borderColor: (isProposer ? !proposerPermAddressDetailsValid : !assuredPermAddressDetailsValid) ? errorColor : undefined
                }} href="/insurance/tabs/address/permaddress" onClick={() => handleTabChangeAddr(1)} selected={currentTab === 1}>
                  <IonLabel>Permanent Address</IonLabel>
                </IonTabButton>
                <IonTabButton className='custom-ion-tab-button' tab="current" style={{
                  color:
                    (isProposer ? !proposerCurAddressDetailsValid : !assuredCurAddressDetailsValid) ? errorColor : undefined,
                  borderColor: (isProposer ? !proposerCurAddressDetailsValid : !assuredCurAddressDetailsValid) ? errorColor : undefined
                }} href="/insurance/tabs/address/curaddress" onClick={() => handleTabChangeAddr(2)} selected={currentTab === 2}>
                  <IonLabel>Current Address</IonLabel>
                </IonTabButton>


              </IonTabBar>

            </IonTabs>
          </div>
        </div>
      </div>

    </IonReactRouter>
  );
};

export default AddressDetailsMain;
