import { SmsRetriever } from "@awesome-cordova-plugins/sms-retriever";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Otptimer } from "otp-timer-ts";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { generateOTP, resendOTP, verifyOTP } from "../../api/apiManager"; // Import your API functions for OTP management
import { CustomButton } from "../../components/common/custom-button/CustomButton";
import ApplicationContext from "../../context/ApplicationContext";
import { AuthContext } from "../../context/AuthContext";
import { Header } from "../../components/layout/header/Header";
import {
  INSURANCE_STATUS,
  PRODUCTS_ID,
  REDIRECT,
} from "../../helpers/constant";
import { t } from "i18next";
import useApi from "../../api/useApi";
import {
  INSURANCE_FETCH_APPLICATION_DETAILS,
  UPDATE_STATUS,
  VERIFY_OTP,
  GENERATE_OTP,
  UPDATE_VERIFICATION_STATUS
} from "../../helpers/api-endpoints";
import { useBusyLoading } from "../../context/BusyLoadingContext";
import { showToastAlert } from "../../helpers/functions";
import {
  ApplicationBean,
  StatusUpdateRequest,
  UpdateVerificationStatusRequest,
} from "../../model/insurance_bean";

interface OtpVerificationInterface {
  length?: number;
  title?: string;
  mobile?: string;
}

export const VerifyInsurerWithOtp: React.FC<OtpVerificationInterface> = ({
  length = 4,
  title,
  mobile,
}) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const history = useHistory();
  const [otp, setOtp] = useState<string[]>(new Array(4).fill(""));
  const [error, setError] = useState<boolean>(false);
  const [valid, setValidOtp] = useState<boolean>(false);
  const [nextButtonActive, setNextButtonActive] = useState<boolean>(true);
  const { token } = useContext(AuthContext);

  const { applicationBean, setAppBean } = useContext(ApplicationContext);
  let mobNo = applicationBean?.basicDetailsProposer?.mobile_no!;
  console.log("verifyOtpPage", mobNo);

  const appDetailsApi = useApi<any>(INSURANCE_FETCH_APPLICATION_DETAILS);

  const verificationStatusUpdateApi = useApi<any>(`${UPDATE_VERIFICATION_STATUS}`);
  const verifyOtpApi = useApi<any>(VERIFY_OTP + "/" + mobNo);
  const generateOtp = useApi<any>(GENERATE_OTP)
  const [loading, setLoading] = useState(false);
  const { setBusyState } = useBusyLoading();

  useEffect(() => {
    setBusyState(appDetailsApi.loading);
  }, [appDetailsApi.loading]);

  useEffect(() => {
    showToastAlert({ type: "error", message: appDetailsApi.error?.message! });
    setError(true);

  }, [appDetailsApi.error]);


  useEffect(() => {
    setBusyState(verifyOtpApi.loading);
  }, [verifyOtpApi.loading]);

  useEffect(() => {
    showToastAlert({ type: "error", message: verifyOtpApi.error?.message! });
    setError(true);
  }, [verifyOtpApi.error]);


  useEffect(() => {
    setBusyState(appDetailsApi.loading);
  }, [verificationStatusUpdateApi.loading]);

  useEffect(() => {
    showToastAlert({ type: "error", message: appDetailsApi.error?.message! });
    setError(true);
  }, [verificationStatusUpdateApi.error]);

  const handleChange = async (element: any | undefined, index: number) => {
    if (isNaN(element.value)) return false;
    setOtp([
      ...otp.map((d: any, idx: any) => (idx === index ? element.value : d)),
    ]);
    if (index <= 3) {
      element.value && inputRefs.current[index + 1]?.focus();
    }
    if (index >= 0) {
      setError(false);
    }
  };

  const handleKeyDown = (index: number, e: any) => {
    if (e.key === "Backspace" && index > 0) {
      e.target.value == "" && inputRefs.current[index - 1]?.focus();
    }
    if (otp.length === 3) {
      setNextButtonActive(false);
    } else {
      setNextButtonActive(true);
    }
  };



  const verifyLeadOtp = async () => {
    if (otp.every((digit) => digit !== '')) {
      // try {
        const otpString = otp.join('');

        let applicationId = applicationBean?.applicationId;
        console.log('verifyOtpPage>>>>', applicationId);
        const otpData = {
          otp: otpString,
          mobile: mobNo
        };
        verifyOtpApi.executeRequest('post', otpData, true, false);
        //hbs uncomment this
        // const response = await verifyOTP(otpString, PRODUCTS_ID.INSURANCE.toString(), applicationId!.toString(), mobNo,token!);
        // if (response.status_code == 200) {
        // if (true) {
        //   // const generateLeadRequest = new GenerateLeadRequest(applicationBean!);

        //   let statusUpdateRequest = new StatusUpdateRequest();
        //   statusUpdateRequest.applicationId = applicationBean?.applicationId;
        //   statusUpdateRequest.statusId = INSURANCE_STATUS.APPLICATION_FULFILLMENT;
        //   const payloadApi = statusUpdateRequest;
        //   await statusUpdateApi.executeRequest("put", payloadApi);
        //   const payload = JSON.stringify({ applicationId: applicationId });
        //   // const payload = { applicationId: parseInt(applicationId)};
        //   console.log("payload", payload);
        //   // executeRequest("get", payload, true);
        //   appDetailsApi.executeRequest("post", payload, true).then(
        //     () => {
        //       // console.log("executeRequest",responseData);
        //       if (appDetailsApi.responseData?.data) {
        //         console.log("hbs success ", appDetailsApi.responseData?.data);

        //         const appBeanFin = new ApplicationBean(appDetailsApi.responseData?.data);

        //         console.log("hbs appBeanFin Temp", appBeanFin);

        //         setAppBean(appBeanFin);
        //         history.push("/docuploadMain");
        //       }
        //     }
        //   );

        // }
        // else {
        //   setError(true);
        // }
      // } catch (error) {
      //   console.error('Error verifying OTP:', error);
      //   setError(true);
      }
    // } else {
    //   setError(true);
    // }
  };




  useEffect(() => {


    if (verifyOtpApi.responseData?.status) {
      let updateVerificationStatusRequest = new UpdateVerificationStatusRequest();
      updateVerificationStatusRequest.applicationId = applicationBean?.applicationId;
      updateVerificationStatusRequest.statusId = INSURANCE_STATUS.APPLICATION_FULFILLMENT;
      updateVerificationStatusRequest.veribymoboremail = "mobile";
      const payloadApi = updateVerificationStatusRequest;
      verificationStatusUpdateApi.executeRequest("put", payloadApi);
    }

  }, [verifyOtpApi.responseData]);


  useEffect(() => {


    if (verificationStatusUpdateApi.responseData?.status) {
      const payload = JSON.stringify({ applicationId: applicationBean?.applicationId });
      console.log("payload", payload);
      appDetailsApi.executeRequest("post", payload, true);
    }

  }, [verificationStatusUpdateApi.responseData]);


  useEffect(() => {


    if (appDetailsApi.responseData?.status) {
      console.log("hbs success ", appDetailsApi.responseData?.data);

      const appBeanFin = new ApplicationBean(appDetailsApi.responseData?.data);

      console.log("hbs appBeanFin Temp", appBeanFin);

      setAppBean(appBeanFin);
      history.push("/docuploadMain");
    }

  }, [appDetailsApi.responseData]);





  const sendOtp = async () => {
    try {
      const payload = {
        mobile: mobNo
      }
      await generateOtp.executeRequest("post", payload, true, false);
      // await generateOTP('8976543255');
      //   await generateOTP(mobNo, token!);
      // Focus on the first input field
      inputRefs.current[0]?.focus();
      // Clear the OTP input field
      setOtp(new Array(4).fill(""));
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const resendOtp = async () => {
    try {
      //   await resendOTP(mobNo, token!);
      const payload = {
        mobile: mobNo
      }
      await generateOtp.executeRequest("post", payload, true, false);
      // Focus on the first input field
      inputRefs.current[0]?.focus();
      // Clear the OTP input field
      setOtp(new Array(4).fill(""));
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  useEffect(() => {
    sendOtp();
    if (inputRefs?.current?.length > 0) {
      const value: any = inputRefs.current ? inputRefs.current : false;
      if (value && Array.isArray(value)) {
        setTimeout(() => value?.[0].focus(), 700);
      }
    }
  }, []);

  const validOtp =
    otp[0] !== "" &&
    otp[1] !== "" &&
    otp[2] !== "" &&
    otp[3] !== "" &&
    !valid &&
    error;

  //   useEffect(() => {
  //     SmsRetriever.startWatching()
  //       .then((res: any) => {
  //         if (res?.Message) {
  //           const otpregex = new RegExp(/^[0-9]{4}$/);
  //           const msgArray = res.Message.split(" ");
  //           let getOtp: any;
  //           msgArray.forEach((i: any) => {
  //             if (otpregex.test(i)) {
  //               getOtp = i;
  //             }
  //           });
  //           const otpArray = getOtp.split("").map((i: any) => i);
  //           setOtp(otpArray);
  //         }
  //       })
  //       .catch((error: any) => console.error(error));
  //   }, []);

  return (
    <IonPage className="bg-web-white container-width-md">
      <Header
        className="card-header"
        name={t(title ?? "OTP Verification")}
        backButton={true}
        needArrow={true}
        needArrowOnClick={() => {
          history.push(REDIRECT.DASHBOARD);
        }}
      />

      <IonContent className="ion-padding-bottom">
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">OTP Verification</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="otp-verification">
          <div className="select-product h-100 bg-web">
            <div className="bg-web-white container-width-md px-3 p-md-5 border-radius-10">
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <div className="mt-4 mt-md-0">
                      <h2 className="ion-no-margin fw-600 fs-22">
                        We have sent you an OTP
                      </h2>
                      <div className="ion-no-margin description">
                        <span className="text-grey">
                          Please enter the OTP sent on your
                          <br />
                          Mobile No. +91 XXXXXX
                          {String(mobNo)?.slice(6)}.
                        </span>
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonGrid className="otp-input-box">
                <IonRow>
                  {[...Array(length)].map((item, index) => (
                    <IonCol size="3" key={index}>
                      <input
                        inputMode="numeric"
                        pattern="[0-9]*"
                        max="1"
                        id={`otp${index}`}
                        ref={(ref) => (inputRefs.current[index] = ref)}
                        value={otp[index] || ""}
                        onChange={(e) => handleChange(e.target, index)}
                        onKeyDown={(e) => handleKeyDown(index, e)}
                        maxLength={1}
                        className={`form-control text-center ${valid ? "input-invalid" : validOtp && "input-valid"
                          }`}
                        // className={"form-control text-center"}
                        required={error}
                      />
                    </IonCol>
                  ))}
                </IonRow>
                {error && (
                  <p className="error fs-12 text-danger ms-2 mb-0">
                    Please Enter OTP
                  </p>
                )}
                {valid && (
                  <p className="error fs-12 text-danger ms-2 mb-0">
                    Please Enter Valid OTP
                  </p>
                )}
              </IonGrid>
              <div className={"pt-2"}>
                <CustomButton
                  title="Verify"
                  expand="block"
                  onClick={verifyLeadOtp}
                />

                <div className="ion-no-margin pt-3">
                  Didn’t receive OTP?{" "}
                  <div className="d-inline-block">
                    <Otptimer
                      minutes={0}
                      seconds={59}
                      onResend={resendOtp} // Call the resend OTP function
                      text="Resend OTP in"
                      buttonText="Resend OTP"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </IonContent>
      </IonContent>
    </IonPage>
  );
};
