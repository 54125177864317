import { IonContent, IonFooter } from '@ionic/react';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { postCommonFieldsDataPl } from '../../../store/reducers/lead/leadSlice';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import {
	ContinueLaterPopUp,
	checkFieldsValidToContinue,
	showToastAlert,
} from '../../../helpers/functions';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { CustomModalLeadStatus } from '../../../components/common';
import './Kotak.scss';
import { getLenderUserInfo } from '../../../store/reducers/user/userSlice';
import { LenderNameForAPICall } from '../../../helpers/lenders';
import { STATUS_CODE } from '../../../helpers/constant';
import { OfferRejectedMsg } from '../OfferRejectedMsg/OfferRejectedMsg';

export const KotakPersonalInfoForm: React.FC<any> = () => {
	const [modalPageClose, setModalPageClose] = useState(false);
	const [leadStatusModalOpen, setLeadStatusModalOpen] = useState(false);
	const [applicationNo, setApplicationNo] = useState(null);
	const [kotakForm, setkotakForm] = useState([]);
	console.log(kotakForm, 'kotakForm');

	const history = useHistory();
	const dispatch = useDispatch();
	const { selectedLender, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const location = useLocation<any>();

	const {
		handleSubmit,
		control,
		// getValues,
		// reset,
		formState: { errors, isValid },
	} = useForm({
		mode: 'all',
		reValidateMode: 'onChange',
	});

	useEffect(() => {
		if (location.pathname !== '/lead-lender-specific-form-steps') {
			return;
		}
		dispatch(
			getLenderUserInfo({
				param: leadFlowData?.leadId,
				data: {
					lender_name: selectedLender[0]?.lender_name,
				},
			})
		).then((res: any) => {
			console.log(res, 'kotak response');
			setkotakForm(res?.payload?.data);
		});
	}, [location.pathname]);

	/**
	 * On close of lead status modal redirect to external URL
	 */
	const onLeadStatusModalClose = () => {
		setLeadStatusModalOpen(false);
	};

	/**
	 * On submit to save user data and after that
	 */
	const onSubmit = async () => {
		console.log('In On Submit');

		await dispatch(
			postCommonFieldsDataPl({
				param: '/' + leadFlowData?.leadId,
				data: {
					lead_id: leadFlowData?.leadId,
					lender_id: selectedLender[0]?.lender_id,
					lender_name: LenderNameForAPICall.KOTAK,
					lead_application_id:
						leadFlowData?.lead_app_id || selectedLender[0]?.id,
				},
			})
		).then((res: any) => {
			if (res?.payload?.code == STATUS_CODE.SUCCESS) {
				setApplicationNo(res?.payload?.data);
				setLeadStatusModalOpen(true);
			} else {
				setShowOfferErrMsg('Please try again later !');
			}
		});
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
							<form onSubmit={handleSubmit(onSubmit)}>
								{kotakForm &&
									kotakForm.map(
										({ name, type, placeholder, value }: any, index: any) => {
											const errorText: any =
												errors && Object.keys(errors).length > 0 && errors;
											return (
												<div key={index}>
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<CustomInput
																type={type}
																disabled={value}
																label={t(placeholder)}
																onBlur={onBlur}
																id={name}
																onChange={(e: any) => {
																	onChange(e);
																}}
																value={value}
																errors={t(errorText[name]?.message || '')}
															/>
														)}
														name={name}
													/>
												</div>
											);
										}
									)}
							</form>

							<div className={` d-none d-md-block`}>
								<CustomButton
									className='w-100 fw-bold dashboard-add-lead-button mb-3'
									expand='block'
									fill='outline'
									title={t('Continue Later')}
									onClick={() => {
										if (checkFieldsValidToContinue(errors)) {
											setModalPageClose(true);
										} else {
											showToastAlert({
												type: 'error',
												message: 'Please fix above errors',
											});
										}
									}}
								/>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				)}

				<CustomModal
					needIonContentWraper={false}
					isOpen={modalPageClose}
					initialBreakpoint={undefined}
					handleClose={() => setModalPageClose(false)}
					breakpoints={[]}
					className='height-auto confirm-popup-middle continue-later'
					modalContent={ContinueLaterPopUp(
						() => {
							setModalPageClose(false);
						},
						() => {
							new Promise(function (myResolve) {
								setTimeout(function () {
									myResolve(history.push('/dashboard/lead'));
									// onSubmit();
									setModalPageClose(false);
								}, 10);
							});
						},
						'Cancel',
						'Continue Later',
						'className',
						'Are You Sure You Want to Exit?',
						`${t('You can continue later from where you left off.')}`
					)}
					needCross={false}
					needArrow={false}
				/>
			</IonContent>

			{leadStatusModalOpen && (
				<CustomModalLeadStatus
					isOpen={leadStatusModalOpen}
					applicationNo={applicationNo}
					handleClose={() => onLeadStatusModalClose()}
				/>
			)}
			{!showOfferErrMsg && (
				<IonFooter className='d-block d-md-none position-relative'>
					<div
						style={{
							backgroundColor: '#fff',
							zIndex: 2,
						}}
					>
						<div className={`ion-padding`}>
							<CustomButton
								className='w-100 fw-bold dashboard-add-lead-button mb-3'
								expand='block'
								fill='outline'
								title={t('Continue Later')}
								onClick={() => {
									if (checkFieldsValidToContinue(errors)) {
										setModalPageClose(true);
									} else {
										showToastAlert({
											type: 'error',
											message: 'Please fix above errors',
										});
									}
								}}
							/>
							<div className='proceed-btn'>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				</IonFooter>
			)}
		</>
	);
};
