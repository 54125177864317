import { IonContent, IonItem, IonList, IonPage } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import useApi from "../../api/useApi";
import { Header } from "../../components/layout/header/Header";
import { ActivityContext } from "../../context/ActivityContext";
import ApplicationContext from "../../context/ApplicationContext";
import { useBusyLoading } from "../../context/BusyLoadingContext";
import { GET_COMMENTS } from "../../helpers/api-endpoints";
import { FormatDate } from "../../helpers/functions";
import {
  CommentListData,
} from "../../model/comment_list_bean";

interface LocationState {
  applicationId: number;
}

const getCommentsInsurance: React.FC<CommentListData> = () => {
  const { commentList, setCommentList } = useContext(ActivityContext);
  const location = useLocation<LocationState>();
  const { t } = useTranslation();
  const applicationId = location.state?.applicationId;
  console.log("applicationId", applicationId);

  const getCommentApi = useApi<any>(GET_COMMENTS);
  const { setBusyState } = useBusyLoading();

  useEffect(() => {
    getComment();
  }, [])
  
  useEffect(() => {
    setBusyState(getCommentApi.loading);
  }, [getCommentApi.loading]);

  useEffect(() => {
    if(getCommentApi?.responseData?.status){
      console.log(getCommentApi?.responseData?.data)
      setCommentList(getCommentApi?.responseData?.data);
    }
  }, [getCommentApi?.responseData])

  const getComment = async () =>{
    try {
      const payload = { applicationId : applicationId}
      await getCommentApi.executeRequest('post', payload, true)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <IonPage>
      <Header
        name={t("Comments")}
        backButton={true}
        needArrow={true}
        // needArrowOnClick={() => {
        //   history.push("/insurance-lead-details");
        // }}
      />
      <IonContent className="ion-padding background-web">
        <div className="bg-web h-100 py-md-3">
          <div className="bg-web-white container-width-md border-md-radius-10 h-100 overflow-auto-md">
            <IonList className="ion-no-padding">
              {commentList?.map((item: any, index: number) => {
                return (
                  <IonItem key={index} className="" lines="full">
                    <div className="my-3 ms-0">
                      <h6 className="fw-bold fs-14 darkest-grey fs-500">
                        {item?.comment || "NA"}
                      </h6>
                      <div className="mt-2">
                        <p className="mb-0 fs-12 fw-500 text-grey position-relative">
                          <span>
                            By{" "}
                            {item?.commented_by_name
                              ? item?.commented_by_name
                              : "Unknown"}
                          </span>
                          <span className="mx-2">•</span>
                          <span>
                            {FormatDate(item?.createdAt, "dd mmm yyyy")}
                          </span>
                          <span className="mx-2">•</span>
                          <span>{FormatDate(item?.createdAt, "hh:MM TT")}</span>
                        </p>
                      </div>
                    </div>
                  </IonItem>
                );
              })}
            </IonList>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default getCommentsInsurance;
