export type CommentListData = {
    id: number|null,
    applicationId?:number | null,
    comment?:string,
    commented_by?:string,
    commented_by_name?:string,
    createdAt?:string;
    updatedAt?:string;
}

export class CommentListDetails {
    id: number|null;
    applicationId?:number | null;
    comment?:string;
    commented_by?:string | null;
    commented_by_name?:string;
    createdAt?:string;
    updatedAt?:string;

    constructor(data: CommentListDetailRequest) {
        this.id = data.id;
        this.applicationId = data.applicationId;
        this.comment = data.comment;
        this.commented_by = data.commented_by;
        this.commented_by_name = data.commented_by_name;
        this.createdAt= data.createdAt;
        this.updatedAt = data.updatedAt;
    }
}

export class CommentListDetailRequest {
    id: number|null;
    applicationId?:number | null;
    comment?:string;
    commented_by?:string | null;
    commented_by_name?:string;
    createdAt?:string;
    updatedAt?:string;

    constructor(){
        this.id = null;
        this.applicationId = null;
        this.comment = "null";
        this.commented_by = "null";
        this.commented_by_name = "null";
        this.createdAt= "null";
        this.updatedAt = "null";
    }

}
