import { useSelector } from 'react-redux';
import { LeadsFlowGenerator } from '../../../components/common/custom-leads-flow-generator/LeadsFlowGenerator';
import { Header } from '../../../components/layout/header/Header';
import { useIonRouter } from '@ionic/react';
import { CasheForm } from './CasheForm';

export const InitiateCasheView = () => {
	const router = useIonRouter();
	const lenderSpfDetails = useSelector(
		(state: any) => state?.lead?.lenderSpecificDetails
	);

	const headerNeedArrowOnClick = async () => {
		// dispatch(lenderSpfDetailsFlow({}));
		router.push('/lender-specific-info');
	};

	const stepsComponents = [
		{
			step: 'step1',
			component: <CasheForm />,
		},
	];

	const stepsHeader = (
		<Header
			name={'Bhanix Specific Form'}
			backButton={true}
			needArrow={true}
			needCross={false}
			needArrowOnClick={() => headerNeedArrowOnClick()}
		/>
	);

	return (
		<LeadsFlowGenerator
			currentStep={lenderSpfDetails?.step}
			stepsHeader={stepsHeader}
			displayStepsCounter={lenderSpfDetails?.subHeaderStepsCounter}
			stepsComponents={stepsComponents}
		/>
	);
};
