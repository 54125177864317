/* eslint-disable @typescript-eslint/no-explicit-any */
import { SmsRetriever } from '@awesome-cordova-plugins/sms-retriever';
import { Otptimer } from 'otp-timer-ts';
import {
	IonCol,
	IonContent,
	IonGrid,
	IonImg,
	IonPage,
	IonRow,
	useIonRouter,
} from '@ionic/react';
import ReactGA from 'react-ga4';

import { Device } from '@capacitor/device';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Header } from '../../components/layout/header/Header';
import './OtpVerification.scss';
import { useHistory, useLocation } from 'react-router-dom';
import {
	APP_VERSION,
	BUREAU_SCORE_TABS,
	LEAD_SCORE_TYPE,
	LeadStatus,
	ONBOARDING_STEP,
	OTPTIMER,
	//ONBOARDING_STEP,
	// OTPTIMER,
	STATUS_CODE,
	generatEncryptedEmail,
	//TOKEN_NAME,
} from '../../helpers/constant';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import CustomNote from '../../components/common/custom-note/CustomNote';
import { StartPage } from '../getting-started/GettingStarted';
import { useDispatch, useSelector } from 'react-redux';
// import { addUser } from '../../store/reducers/user/userSlice';
// import { setAuthToken } from '../../store/accessToken';
import {
	apiLogin,
	apiVeryfyOtp,
	customSuccessfull,
	generateCibilOtp,
	useDetails,
} from '../../store/reducers/user/userSlice';
import { AuthContext } from '../insurance/context/AuthContext';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	// TrackGoogleAnalyticsEvent,
	eventAttributes,
	getDecrypted,
	notifyHandler,
	// getEncrypted,
	// showToastAlert,
} from '../../helpers/functions';
import { otpScreenShowPage, uuid } from '../../store/reducers/app/appSlice';
import { LocalNotifications } from '@capacitor/local-notifications';
import {
	dashboardLeadGenerateOtp,
	downloadLeadVerifyOtp,
	fetchCibilBureauReport,
	fetchEquifaxBureauReport,
	leadFlow,
	verifyCibil,
} from '../../store/reducers/lead/leadSlice';
import { Capacitor } from '@capacitor/core';
import { isPlatform } from '@ionic/core';
import { NotifyVisitors } from 'capacitor-plugin-notifyvisitors';
import { InstallReferrer } from '@togetherprice/capacitor-plugin-install-referrer';
interface OtpVerificationInterface {
	length?: number;
	routerLink?: any;
	isPage?: any;
	cibilVerifyPayloadData?: any;
}

export const OtpVerification: React.FC<OtpVerificationInterface> = ({
	length = 4,
	isPage,
}) => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [nextButtonActive, setNextButtonActive] = useState<boolean>(true);
	const history = useHistory();
	const location = useLocation<any>();

	const { login } = useContext(AuthContext);

	const router = useIonRouter();
	const [otp, setOtp] = useState<any>(
		new Array(leadInfo?.step === 'step6' ? 6 : 4).fill('')
	);
	const [error, setError] = useState<boolean>(false);
	// const [timer, setTimer] = useState<any>(OTPTIMER);
	const [valid, setVaidOtp] = useState<any>(false);
	const appData = useSelector((state: any) => state?.app);
	const userData = useSelector((state: any) => state?.users);
	const isPushNotificationsAvailable =
		Capacitor.isPluginAvailable('PushNotifications');

	// Notify Visitors
	const homePageData = useSelector((state: any) => state?.users);
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const userMobile = useSelector((state: any) => state?.users?.user?.mobile);

	const leadSelector = useSelector((state: any) => state?.lead?.leadFlowData);
	const fetchBureauCustomerReport = useSelector(
		(state: any) => state?.lead?.fetchBureauCustomerReportData
	);
	const entityType = useSelector(
		(state: any) => state?.users?.user_details?.entityType
	);
	const userLang = useSelector(
		(state: any) => state?.oneTimeScreen?.language?.name
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [selectedTab, setSelectedTab] = useState<any>(
		leadSelector?.scoreType?.toLocaleLowerCase() ===
			LEAD_SCORE_TYPE.EQUIFAX.toLocaleLowerCase()
			? BUREAU_SCORE_TABS.EQUIFAX
			: leadSelector?.scoreType?.toLocaleLowerCase() ===
			  LEAD_SCORE_TYPE.CBIL.toLocaleLowerCase()
			? BUREAU_SCORE_TABS.CIBIL
			: leadSelector?.scoreType?.toLocaleLowerCase() ===
					LEAD_SCORE_TYPE.BOTH.toLocaleLowerCase() && BUREAU_SCORE_TABS.EQUIFAX
	);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [equifaxDataLoading, setEquifaxDataLoading] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [equifaxData, setEquifaxData] = useState(null);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [bureauFetchError, setBureauFetchError] = useState('');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [bureauRetry, setBureauRetry] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [cbilData, setCbilData] = useState();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [cbilDataLoading, setCbilDataLoading] = useState(false);

	useEffect(() => {
		logDeviceInfo();
		if (inputRefs?.current?.length > 0) {
			const value: any = inputRefs.current ? inputRefs.current : false;
			if (value && Array.isArray(value)) {
				setTimeout(() => value?.[0].focus(), 700);
			}
		}
	}, []);
	useEffect(() => {
		if (leadInfo?.isNewLead === false) {
			leadSendOtp();
		}
	}, [leadInfo?.updateLeadProcess]);

	useEffect(() => {
		setOtp(new Array(leadInfo?.step === 'step6' ? 6 : 4).fill(''));
	}, [leadInfo?.step]);

	const userAttributes = {
		userId: userId,
		attributes: {
			name: homePageData?.homepage?.profile?.name,
			id: userId,
			mobile: userMobile,
			user_language: userLang,
			entityType:
				entityType === 'Individual'
					? 'DMA'
					: entityType === 'BTM'
					? 'employee'
					: entityType,
		},
	};

	useEffect(() => {
		if (isPlatform('android') || isPlatform('ios')) {
			notifyHandler('event', eventAttributes('lead_creation_otp_page'));
			// notifyHandler('user', userAttributes);
			const inAppData = {
				tokens: { name: userAttributes },
				customRules: { banerrType: 'lead creation otp page' },
			};
			NotifyVisitors.show(inAppData);
		} else {
			(window as any).nv('event', 'lead_creation_otp_page', 10, 1);
			(window as any).nv('user', userId, userAttributes.attributes);
		}
	}, []);

	const handleChange = async (element: any | undefined, index: number) => {
		if (isNaN(element.value)) return false;
		setOtp([
			...otp.map((d: any, idx: any) => (idx === index ? element.value : d)),
		]);
		if (index <= (leadInfo?.step === 'step6' ? 5 : 3)) {
			element.value && inputRefs.current[index + 1]?.focus();
		}
		if (index >= 0) {
			setError(false);
		}
	};

	const logDeviceInfo = async () => {
		const info: any = await Device.getId();
		await dispatch(uuid(info?.identifier));
	};
	const handleSubmit = async () => {
		if (otp[0] !== '' && otp[1] !== '' && otp[2] !== '' && otp[3] !== '') {
			const newOtp = otp.join('').toString();
			const uuID = appData?.uuid;
			let permStatus;
			if (isPushNotificationsAvailable) {
				const checkpermStatus: any = (
					await LocalNotifications.checkPermissions()
				).display;
				permStatus = checkpermStatus === 'granted' ? true : false;
			} else {
				permStatus = false;
			}

			const data = {
				mobile: Number(userData?.user?.mobile),
				otp: newOtp,
				device_id: uuID || 'err',
				notification_token: appData?.notification_token || '',
				language: appData?.language?.id || 1,
				allow_notification: permStatus,
				tokenduration: 30,
				utm_data: { ...userData?.dmaFlow },
			};

			await dispatch(apiVeryfyOtp({ data: data }))
				.then(unwrapResult)
				.then(async (result: any) => {
					if (result?.status) {
						// if (isPlatform('android')) {
						// 	console.log(userAttributes, 'user details Checked API');
						// 	notifyHandler('user', userAttributes);
						// } else {
						// 	console.log('user not found');
						// }

						if (
							process.env.REACT_APP_API_ENDPOINT ==
							'https://prodcrmapi.oneandro.com/'
						) {
							InstallReferrer.getReferrerDetails()
								.then((referrerDetails: any) => {
									if (
										process.env.REACT_APP_API_ENDPOINT ==
										'https://prodcrmapi.oneandro.com/'
									) {
										if (isPlatform('desktop') || isPlatform('mobileweb')) {
											ReactGA.send({
												hitType: 'event',
												eventCategory: 'OTP Verification',
												eventAction: 'Referrer',
												eventLabel: referrerDetails?.referrer,
											});
										}
										if (isPlatform('capacitor') || isPlatform('android')) {
											ReactGA.send({
												hitType: 'screenview',
												screenName: 'OTP Screen',
												screenClass: 'OtpVerified',
												appVersion: APP_VERSION,
												eventLabel: referrerDetails?.referrer,
											});
										}
									}

									return referrerDetails;
								})
								.catch((error: any) => {
									console.error(error);
								});
						}

						await dispatch(otpScreenShowPage(true));
						if (!result) {
							result = JSON.parse(
								getDecrypted(userData?.user_details?.access_token)
							);
						}

						// login(`bearer ${result.access_token}`, result.userId,result.mobile,result.roleType);

						await dispatch(useDetails(result));

						login(
							`bearer ${result.access_token}`,
							result.userId,
							result.mobile,
							result.roleType
						);

						// const encodedData = getEncrypted(
						// 	JSON.stringify({
						// 		id: result.id,
						// 		mobile: result.mobile,
						// 		onboarding_step: result.step,
						// 		kycStep: result.kycStep,
						// 		accessToken: result.accessToken
						// 			? result.accessToken
						// 			: result['access_token'],
						// 		roleType: result.roleType,
						// 		isInvited: result.isInvited,
						// 	})
						// );

						setOtp(new Array(4).fill(''));

						if (
							result?.step === ONBOARDING_STEP.REGISTER &&
							result?.roleType[0] !== 'Business Ops'
						) {
							history.replace('/register-yourself');
						} else {
							history.replace('/dashboard/home');
						}
						setVaidOtp(false);
					}
				})
				.catch(() => {
					// setVaidOtp(true);
					setError(true);
				});

			setError(false);
		} else {
			// setVaidOtp(true);
			setError(true);
		}
	};

	const sendAadhaarOtp = async () => {
		if (otp[0] !== '' && otp[1] !== '' && otp[2] !== '' && otp[3] !== '') {
			// const aadhaarOtp = otp.join('').toString();
			history.replace('/custom-successfull', {
				type: 'AAM',
				message: 'Congratulations ! Agreement e-signed successfully!',
				redirectTo: '/dashboard/home',
			});

			await dispatch(
				customSuccessfull({
					type: 'AAM',
					message: 'Congratulations ! Agreement e-signed successfully!',
					redirectTo: '/dashboard/home',
				})
			);

			setError(false);
		} else {
			setError(true);
		}
	};

	const reSendAadhaarOtp = () => {
		inputRefs.current[0]?.focus();
		setOtp(new Array(4).fill(''));
	};

	const getBureauData = async () => {
		if (
			selectedTab === BUREAU_SCORE_TABS.EQUIFAX &&
			(leadSelector?.scoreType?.toLocaleLowerCase() ===
				LEAD_SCORE_TYPE.EQUIFAX.toLocaleLowerCase() ||
				leadSelector?.scoreType?.toLocaleLowerCase() ===
					LEAD_SCORE_TYPE.BOTH.toLocaleLowerCase())
		) {
			setEquifaxDataLoading(true);
			setBureauRetry(false);
			await dispatch(
				fetchEquifaxBureauReport({
					param: fetchBureauCustomerReport?.lead_id,
					data: {
						...fetchBureauCustomerReport,
					},
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (
						leadInfo?.selectedProductData?.product_category_id == 7 &&
						response?.code === 'SUCCESS'
					) {
						history.push('/professional-info');
					} else if (
						leadInfo?.selectedProductData?.product_category_id == 7 &&
						response?.code == 'FAILED'
					) {
						history.replace('/bureau-report');
					} else {
						setEquifaxDataLoading(false);
						if (response?.status) {
							setEquifaxData(response?.data);
							dispatch(
								leadFlow({ ...leadSelector, leadStatus: LeadStatus.PROCESSING })
							);
						} else {
							dispatch(
								leadFlow({ ...leadSelector, leadStatus: LeadStatus.BUREAU })
							);
							setBureauFetchError(
								response?.text ? response?.text : response?.message
							);
						}
						setBureauRetry(response?.retry);
					}
				});
		} else if (
			selectedTab === BUREAU_SCORE_TABS.CIBIL &&
			(leadSelector?.scoreType?.toLocaleLowerCase() ===
				LEAD_SCORE_TYPE.CBIL.toLocaleLowerCase() ||
				leadSelector?.scoreType?.toLocaleLowerCase() ===
					LEAD_SCORE_TYPE.BOTH.toLocaleLowerCase())
		) {
			setBureauRetry(false);
			setCbilDataLoading(true);
			await dispatch(
				fetchCibilBureauReport({
					param: `/${fetchBureauCustomerReport?.lead_id}`,
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					setCbilDataLoading(false);
					if (response?.status) {
						setCbilData(response?.cibil_response);
					} else {
						dispatch(
							leadFlow({
								...leadSelector,
								leadStatus: LeadStatus.BUREAU,
							})
						);
						setBureauFetchError(
							response?.text ? response?.text : response?.message
						);
					}
					setBureauRetry(response?.retry);
				});
		}
	};

	const verifyLeadOtp = async () => {
		// CHECK SCORE TYPE
		if (
			leadInfo?.scoreType?.toLocaleLowerCase() ===
			LEAD_SCORE_TYPE.EQUIFAX.toLocaleLowerCase()
		) {
			if (otp[0] !== '' && otp[1] !== '' && otp[2] !== '' && otp[3] !== '') {
				setError(false);
				await dispatch(
					downloadLeadVerifyOtp({
						param: leadInfo?.leadId,
						data: {
							mobile: leadInfo?.leadMobile,
							otp: otp.join('').toString(),
						},
					})
				)
					.then(unwrapResult)
					.then(async (response: any) => {
						if (response?.code === STATUS_CODE.VERIFICATION_SUCCESSFUL) {
							setVaidOtp(false);
							dispatch(
								leadFlow({
									...leadInfo,
									headerTitle: '',
									subHeaderStepsCounter: false,
									needCibil: false,
									reSendCibilOtp: true,
								})
							);

							if (leadInfo?.selectedProductData?.product_category_id === 7) {
								getBureauData();
							} else {
								history.replace('/bureau-report');
							}
							// CODE COMNETED FOR FLOW CHANGE TO SHOW DIRECT EQUIFAX DATA ON SCORE TYPE
							// await dispatch(
							// 	leadFlow({
							// 		...leadInfo,
							// 		headerTitle: '',
							// 		subHeaderStepsCounter: false,
							// 		step: 'step4',
							// 	})
							// );

							// setCurrentStep('step4'); // after otp verify send to step 4
						} else {
							setVaidOtp(true);
						}
					});
			} else {
				setError(true);
				setVaidOtp(false);
			}
		} else if (
			leadInfo?.scoreType?.toLocaleLowerCase() ===
			LEAD_SCORE_TYPE.BOTH.toLocaleLowerCase()
		) {
			if (otp[0] !== '' && otp[1] !== '' && otp[2] !== '' && otp[3] !== '') {
				setError(false);
				await dispatch(
					downloadLeadVerifyOtp({
						param: leadInfo?.leadId,
						data: {
							mobile: leadInfo?.leadMobile,
							otp: otp.join('').toString(),
						},
					})
				)
					.then(unwrapResult)
					.then(async (response: any) => {
						if (response?.code === STATUS_CODE.VERIFICATION_SUCCESSFUL) {
							dispatch(
								leadFlow({
									...leadInfo,
									headerTitle: '',
									subHeaderStepsCounter: false,
									step: 'step4',
								})
							);
							setVaidOtp(false);
						} else {
							setVaidOtp(true);
						}
					});
			} else {
				setError(true);
				setVaidOtp(false);
			}
		}
	};
	const verifyBureauOtp = async () => {
		if (
			otp[0] !== '' &&
			otp[1] !== '' &&
			otp[2] !== '' &&
			otp[3] !== '' &&
			otp[4] !== '' &&
			otp[5] !== ''
		) {
			await dispatch(
				verifyCibil({
					data: {
						lead_id: leadInfo?.leadId,
						...leadInfo?.cbilResponse,
						otp: otp.join('').toString(),
					},
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						const myPromise = new Promise(function (myResolve) {
							setTimeout(function () {
								myResolve(history.replace('/bureau-report'));
							}, 10);
						});
						myPromise.then(() => {
							dispatch(
								leadFlow({
									...leadInfo,
									needCibil: true,
									leadId: leadInfo?.leadId,
									leadMobile: leadInfo?.leadMobile,
								})
							);
						});
					}
				});
		} else {
			setError(true);
		}
	};

	const handleStepsResendOtp = async () => {
		if (leadInfo?.step === 'step3') {
			inputRefs.current[0]?.focus();
			setOtp(new Array(4).fill(''));
			await dispatch(
				dashboardLeadGenerateOtp({
					data: { mobile: leadInfo?.leadMobile, reLogin: true },
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						if (response?.code === STATUS_CODE.SIGNIN_SUCCESS) {
							setOtp(new Array(4).fill(''));
							// setTimer(OTPTIMER);
							setVaidOtp(false);
						}
					}
				});
		} else if (leadInfo?.step === 'step6') {
			inputRefs.current[0]?.focus();
			setOtp(new Array(6).fill(''));
			dispatch(
				generateCibilOtp({
					data: {
						lead_id: leadInfo?.leadId,
						mobile_no: leadInfo?.leadMobile,
					},
				})
			)
				.then(unwrapResult)
				.then(async (response: any) => {
					if (response.status) {
						dispatch(
							leadFlow({
								...leadInfo,
								reSendCibilOtp: true,
								cbilResponse: {
									ChallengeConfigGUID: response?.ChallengeConfigGUID,
									answerKey: response?.answerKey,
									questionKey: response?.questionKey,
								},
							})
						);
					}
				});
		}
	};

	const resendOtp = async () => {
		inputRefs.current[0]?.focus();
		setOtp(new Array(4).fill(''));
		let sms_code: any;
		if (isPlatform('capacitor')) {
			await SmsRetriever.getAppHash()
				.then((res: any) => {
					sms_code = res;
				})
				.catch((error: any) => console.error(error));
		}
		await dispatch(
			apiLogin({
				data: {
					mobile: Number(userData?.user?.mobile),
					reLogin: true,
					sms_hc: sms_code,
				},
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status && response?.code === STATUS_CODE.SIGNIN_SUCCESS) {
					// setTimer(OTPTIMER);
					// showToastAlert({ type: 'success', message: 'OTP Resent' });
				}
			});
	};

	const leadSendOtp = async () => {
		if (leadInfo?.step === 'step3') {
			inputRefs.current[0]?.focus();
			setOtp(new Array(4).fill(''));
			await dispatch(
				dashboardLeadGenerateOtp({
					data: { mobile: leadInfo?.leadMobile, reLogin: false },
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						if (response?.code === STATUS_CODE.SIGNIN_SUCCESS) {
							setOtp(new Array(4).fill(''));
							// setTimer(OTPTIMER);
							setVaidOtp(false);
						}
					}
				});
		} else if (leadInfo?.step === 'step6') {
			// comnt becoz for cibil will auto generate in lead proccing screen or before that
			// inputRefs.current[0]?.focus();
			// setOtp(new Array(4).fill(''));
			// dispatch(
			// 	generateCibilOtp({
			// 		data: {
			// 			lead_id: leadInfo?.leadId,
			// 			mobile_no: leadInfo?.leadMobile,
			// 		},
			// 	})
			// )
			// 	.then(unwrapResult)
			// 	.then(async (response: any) => {
			// 		if (response.status) {
			// 			dispatch(
			// 				leadFlow({
			// 					...leadInfo,
			// 					cbilResponse: {
			// 						ChallengeConfigGUID: response?.ChallengeConfigGUID,
			// 						answerKey: response?.answerKey,
			// 						questionKey: response?.questionKey,
			// 					},
			// 				})
			// 			);
			// 		}
			// 	});
		}
	};

	const handleKeyDown = (index: number, e: any) => {
		if (e.key === 'Backspace' && index > 0) {
			e.target.value == '' && inputRefs.current[index - 1]?.focus();
		}
		if (otp.length === (leadInfo?.step === 'step6' ? 5 : 3)) {
			setNextButtonActive(false);
		} else {
			setNextButtonActive(true);
		}
	};
	useEffect(() => {
		SmsRetriever.startWatching()
			.then((res: any) => {
				if (res?.Message) {
					const otpregex = new RegExp(/^[0-9]{4}$/);
					const msgArray = res?.Message.split(' ');
					let getOtp: any;
					msgArray.map((i: any) => {
						if (otpregex.test(i)) {
							getOtp = i;
						}
					});
					const otp1 = getOtp.split('').map((i: any) => {
						return i;
					});
					setOtp(otp1);
				}
			})
			.catch((error: any) => console.error(error));
	}, []);
	const validOtp =
		otp[0] !== '' &&
		otp[1] !== '' &&
		otp[2] !== '' &&
		otp[3] !== '' &&
		!valid &&
		error;
	const showPage = appData?.otpScreenShowPage
		? appData?.otpScreenShowPage
		: isPage;
	const isOtpPage = location.pathname !== '/otp-verification';

	return (
		<>
			{showPage ? (
				<IonPage className='otp-verification'>
					{/* {location?.pathname} */}

					<Header
						name=''
						backButton={isOtpPage}
						needArrow={!isPage}
						className={'d-block d-md-none'}
					/>

					<IonContent>
						<div className='row h-100 overflow-hidden'>
							<div className='col-md-6 border-end d-none d-md-block '>
								<div className='getting-started h-100 container ion-padding'>
									<StartPage />
								</div>
							</div>
							<div className='col-md-6 col-12 d-flex'>
								<div className='right-side-card ion-padding'>
									{location?.state?.agreementSignOtp ? (
										<div>
											<div className='fs-14 text-grey pb-3'>
												{location?.state?.contentTitle}
											</div>
										</div>
									) : (
										<IonGrid>
											<IonCol>
												<div className=''>
													<h2 className='ion-no-margin fw-600 fs-24'>
														{t('We have sent you an OTP')}
													</h2>
													<div className='ion-no-margin description fs-14'>
														<span
															className=''
															style={{ color: '#A0A4B3' }}
														>
															{t('Enter OTP sent on')} +91 XXXXXX
															{String(userData?.user?.mobile)?.slice(6)}.
														</span>
														<span
															className='cursor-pointer text-primary'
															onClick={() => {
																setOtp(new Array(4).fill(''));
																history.replace('/login');
																// router.goBack();
															}}
														>
															<b> {t('Change Number')}</b>
														</span>
													</div>
												</div>
											</IonCol>
										</IonGrid>
									)}

									<IonGrid className='otp-input-box'>
										<IonRow>
											{[...Array(length)].map((item, index) => {
												return (
													<IonCol
														size='3'
														key={index}
													>
														<input
															inputMode='numeric'
															pattern='[0-9]*'
															max='1'
															id={'otp' + index}
															ref={(ref) => (inputRefs.current[index] = ref)}
															value={otp[index] || ''}
															onChange={(e: any) =>
																handleChange(e.target, index)
															}
															onKeyDown={(e) => handleKeyDown(index, e)}
															onBeforeInput={(evt: any) => {
																!/[0-9]/.test(evt.data) && evt.preventDefault();
															}}
															onWheel={(e: any) => e.target.blur()}
															maxLength={1}
															className={`form-control ${
																valid
																	? 'input-invalid'
																	: validOtp && 'input-valid'
															}`}
															required={error}
														></input>
													</IonCol>
												);
											})}
										</IonRow>
										{error && (
											<p className='error fs-12 text-danger ms-2 mb-0'>
												{t('Please Enter OTP')}
											</p>
										)}
										{valid && (
											<p className='error fs-12 text-danger ms-2 mb-0'>
												{t('Please Enter Valid OTP')}
											</p>
										)}
									</IonGrid>
									<div className='pt-4'>
										{/* for login */}
										<CustomButton
											title={t('Verify')}
											className='btn-blue'
											expand='block'
											onClick={
												location?.state?.agreementSignOtp
													? sendAadhaarOtp
													: handleSubmit
											}
										/>
										<div className='ion-no-margin pt-3 '>
											{t('Didn’t receive OTP?')}&nbsp;
											<div className='d-inline-block'>
												<Otptimer
													minutes={0}
													seconds={OTPTIMER}
													onResend={() => {
														location?.state?.agreementSignOtp
															? reSendAadhaarOtp()
															: resendOtp();
													}}
													text={t('Resend OTP in').toString()}
													buttonText={t('Resend OTP').toString()}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</IonContent>
				</IonPage>
			) : (
				<IonContent className='otp-verification'>
					{/* <div className='row h-100'>
						<div className='col-md-6 border-end d-none d-md-block ion-padding'>
							<div className='getting-started h-100 container'>
								<StartPage />
							</div>
						</div>
						<div className='col-md-6 col-12 d-flex'>
							<div className='right-side-card'> */}
					<div className='select-product small-container h-100 bg-web'>
						<div className='bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
							<IonGrid>
								<IonRow>
									<IonCol>
										{leadInfo?.step === 'step3' && (
											<CustomNote
												{...{
													header: t('Note'),
													content: t(
														'With this OTP Verification Equifax Bureau Report will be fetched.'
													),
													className: 'mb-4',
												}}
											/>
										)}
										{leadInfo?.step === 'step3' ? (
											<div className='text-grey fs-14'>
												{t(
													`Please enter the OTP we have sent to Lead's Mobile No.`
												)}{' '}
												+91 XXXXXX
												{String(leadInfo?.leadMobile)?.slice(6)}. &nbsp;
												{/* <span
													className='cursor-pointer text-primary'
													onClick={async () => {
														if (leadInfo?.from !== 'nimbuss') {
															await dispatch(
																leadFlow({
																	...leadInfo,
																	step: 'step1',
																	headerTitle: 'Add Lead',
																})
															);
														}
													}}
												>
													<b
														style={{
															opacity: leadInfo?.from === 'nimbuss' ? 0.5 : 1,
														}}
													>
														{t('Change Number')}
													</b>
												</span> */}
											</div>
										) : leadInfo?.step === 'step6' ? (
											<div className='text-grey'>
												{leadInfo?.lead_consent_type?.toLowerCase() ===
												'lead' ? (
													`The One Time Password is sent to Mobile Number ${String(
														leadInfo?.leadMobile
													)?.slice(
														0,
														4
													)}****** and Email ID ${generatEncryptedEmail(
														leadInfo?.leadEmail
													)}. Please
												enter the One Time Password`
												) : (
													<>
														{`${t(`Please enter the OTP on the request screen to
														complete the authententication, We have sent on
														mobile no.`)} +91 XXXXXX
														${String(leadInfo?.leadMobile)?.slice(6)}`}
													</>
												)}
											</div>
										) : (
											<div className=''>
												<h2 className='ion-no-margin'>
													{t('We Have Sent you')}
													<br />
													{t('an OTP')}
												</h2>
												<div className='ion-no-margin description'>
													<span className='text-grey'>
														{t('Please enter the OTP sent on your')}
														<br />
														{t('Mobile No.')} +91 XXXXXX
														{String(userData?.user?.mobile)?.slice(6)}.
													</span>
													<span
														className='cursor-pointer text-primary'
														onClick={() => {
															router.goBack();
														}}
													>
														<b> {t('Change Number')}</b>
													</span>
												</div>
											</div>
										)}
									</IonCol>
								</IonRow>
							</IonGrid>
							<IonGrid className='otp-input-box'>
								<IonRow>
									{[...Array(leadInfo?.step === 'step6' ? 6 : length)].map(
										(item, index) => {
											return (
												<IonCol
													size={leadInfo?.step === 'step6' ? '2' : '3'}
													key={index}
												>
													<input
														inputMode='numeric'
														pattern='[0-9]*'
														max='1'
														id={'otp' + index}
														ref={(ref) => (inputRefs.current[index] = ref)}
														value={otp[index] || ''}
														onChange={(e: any) => handleChange(e.target, index)}
														onKeyDown={(e) => handleKeyDown(index, e)}
														onBeforeInput={(evt: any) => {
															!/[0-9]/.test(evt.data) && evt.preventDefault();
														}}
														onWheel={(e: any) => e.target.blur()}
														maxLength={1}
														className={`form-control text-center ${
															valid
																? 'input-invalid'
																: validOtp && 'input-valid'
														}`}
													></input>
												</IonCol>
											);
										}
									)}
								</IonRow>
								{error && (
									<p className='error fs-12 text-danger ms-2 mb-0'>
										{t('Please Enter OTP')}
									</p>
								)}
								{valid && (
									<p className='error fs-12 text-danger ms-2 mb-0'>
										{t('Please Enter Valid OTP')}
									</p>
								)}
							</IonGrid>
							<div
								className={`${
									['step3', 'step6'].includes(leadInfo?.step)
										? 'pt-2'
										: 'verify-btn'
								}`}
							>
								{!leadInfo?.step && (
									// for login
									<CustomButton
										title={t('Verify')}
										expand='block'
										onClick={handleSubmit}
									/>
								)}
								{['step3', 'step6', 'step6'].includes(leadInfo?.step) && (
									<div
										// className='position-fixed w-100 bottom-0 start-0 px-3 py-3'
										style={{
											backgroundColor: '#fff',
											zIndex: 2,
										}}
									>
										<CustomButton
											title={t('Verify')}
											expand='block'
											onClick={
												leadInfo?.step === 'step3'
													? verifyLeadOtp
													: verifyBureauOtp
											}
										/>
									</div>
								)}
								{leadInfo?.step === 'step6' ? (
									leadInfo?.reSendCibilOtp ? (
										''
									) : (
										<div className='ion-no-margin pt-3'>
											{t('Didn’t receive OTP?')}&nbsp;
											<div className='d-inline-block'>
												<Otptimer
													minutes={0}
													seconds={OTPTIMER}
													onResend={() => {
														handleStepsResendOtp();
													}}
													text={t('Resend OTP in').toString()}
													buttonText={t('Resend OTP').toString()}
												/>
											</div>
										</div>
									)
								) : (
									<div className='ion-no-margin pt-3'>
										{t('Didn’t receive OTP?')}&nbsp;
										<div className='d-inline-block'>
											<Otptimer
												minutes={0}
												seconds={OTPTIMER}
												onResend={() => {
													['step3', 'step6'].includes(leadInfo?.step)
														? handleStepsResendOtp()
														: resendOtp();
												}}
												text={t('Resend OTP in').toString()}
												buttonText={t('Resend OTP').toString()}
											/>
										</div>
									</div>
								)}

								{leadInfo?.step === 'step6' && (
									<div className='ion-float-right pe-2 pe-md-0 mt-5 mt-md-0'>
										<IonImg
											src='/images/icons/powered_by_cbil.jpg'
											style={{ width: '75px' }}
										/>
									</div>
								)}
							</div>

							{/* </div>
						</div>
					</div> */}
						</div>
					</div>
				</IonContent>
			)}
		</>
	);
};
