import React, { useEffect, useRef, useState } from 'react';
import { Header } from '../../components/layout/header/Header';
import {
	IonContent,
	IonList,
	IonRadioGroup,
	IonPage,
	IonFooter,
	IonImg,
} from '@ionic/react';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import CustomRadioButton from '../../components/common/custom-radio-button/CustomRadioButton';
import { useDispatch, useSelector } from 'react-redux';
import {
	LEAD_STATUS,
	LeadStatus,
	REGEX,
	disbursementType,
} from '../../helpers/constant';

import {
	getLenderDetails,
	leadDisbursal,
	leadFlow,
	leadLoanSanctioned,
	lenderMarkAsLogin,
} from '../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	InputFormatCurrencyValue,
	restrictAmountChar,
} from '../../helpers/functions';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory } from 'react-router';

type LenderDetails = {
	lender_name: string;
	loan_amount: number | string;
	bank_app_no?: any;
	lan?: any;
	sanction_amount: number | string;
	disbursement_amount?: any;
	disbursement_date: string | string;
};

export const disbursementSchema: any = yup.object().shape({
	lender_name: yup.string().required('Lender Name is Required.'),
	loan_amount: yup
		.number()
		.min(1, 'Loan Amount must be greater than or equal to 1!')
		.typeError('Loan should be number')
		.required('Loan amount is Required'),
	bank_app_no: yup
		.string()
		.required('Bank App. No. is Required.')
		.matches(REGEX.ACCOUNTNO, 'Please Enter Valid Input.')
		.max(
			30,
			'Invalid Bank Application Number, enter the value less than 30 characters.'
		),
});
export const DisbursementDetailsForm: React.FC = () => {
	const { t } = useTranslation();
	const [isPartialOrFull, setPartialOrFull] = useState(
		disbursementType.PARTIAL
	);

	const selectedLead = useSelector((state: any) => state?.lead);
	const disbursedLenderdetails = useSelector(
		(state: any) => state?.lead?.selectedLender[0]
	);
	// const loanAmount = selectedLead?.leadFlowData?.loan_amount;

	const dispatch = useDispatch();
	const [schema, setSchema] = useState<any>(disbursementSchema);
	const [lenderDetails, setlenderDetails] = useState<any>({});
	const history: any = useHistory();
	const bank_app_no_ref: any = useRef(null);

	useEffect(() => {
		fetchLenderDetails();
		if (bank_app_no_ref.current) {
			const value: any = bank_app_no_ref.current
				? bank_app_no_ref.current
				: false;
			if (value) {
				setTimeout(() => bank_app_no_ref.current?.setFocus(), 500);
			}
		}
	}, [
		history.location.pathname === '/disbursement-details-form',
		bank_app_no_ref.current,
	]);

	useEffect(() => {
		fetchSchema();
	}, [
		isPartialOrFull,
		disbursedLenderdetails?.lender_id,
		history.location.pathname === '/disbursement-details-form',
	]);
	const dmaSelector = useSelector((state: any) => state?.users);
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);

	const {
		control,
		setValue,
		// trigger,
		getValues,
		reset,
		handleSubmit,
		formState: { errors, isValid },
	}: any = useForm<LenderDetails>({
		resolver: yupResolver(schema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			lender_name: '',
			loan_amount: '',
			bank_app_no: '',
			lan: '',
			sanction_amount: '',
			disbursement_amount: '',
			disbursement_date: '',
		},
	});
	const onSubmit = async (data: LenderDetails) => {
		if (
			selectedLead?.leadFlowData?.applicationStatus ===
			LEAD_STATUS.SANCTIONED_LABEL
		) {
			const responseData = {
				...data,
				lender_id: selectedLead?.selectedLender[0]?.lender_id,
				bank_app_no: data?.bank_app_no?.toUpperCase(),
			};
			dispatch(
				leadLoanSanctioned({
					param: selectedLead?.selectedLender[0]?.lead_id,
					data: responseData,
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						reset({
							lender_name: '',
							loan_amount: '',
							bank_app_no: '',
							lan: '',
							sanction_amount: '',
						});

						delete disbursementSchema.fields.disbursement_amount;
						delete disbursementSchema.fields.disbursement_date;
						delete disbursementSchema.fields.lan;
						delete disbursementSchema.fields.sanction_amount;
						if (selectedLead.leadFlowData?.redirectToDetailPage) {
							leadInfo?.from === 'nimbuss'
								? history.push(
										'/lead-details/' +
											leadInfo.leadId +
											'/' +
											dmaSelector?.user_details?.access_token
								  )
								: history.push('/lead-details');
						} else {
							history.push('/lender-specific-info');
						}
					}
				});
		} else if (
			selectedLead?.leadFlowData?.applicationStatus ===
				LEAD_STATUS.DISBURSED_LABEL ||
			selectedLead?.leadFlowData?.applicationStatus ===
				LEAD_STATUS.PARTIALDISBURSED_LABEL
		) {
			const responseData = {
				...data,
				disbursement_date: getValues('disbursement_date'),
				disbursalType: isPartialOrFull,
				lender_id: selectedLead?.selectedLender[0]?.lender_id,
				lan: data?.lan?.toUpperCase(),
				bank_app_no: data?.bank_app_no.toUpperCase(),
			};
			dispatch(
				leadDisbursal({
					param: selectedLead?.selectedLender[0]?.lead_id,
					data: responseData,
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						reset({
							lender_name: '',
							loan_amount: '',
							bank_app_no: '',
							lan: '',
							sanction_amount: '',
						});
						delete disbursementSchema.fields.disbursement_amount;
						delete disbursementSchema.fields.disbursement_date;
						delete disbursementSchema.fields.lan;
						delete disbursementSchema.fields.sanction_amount;
						if (selectedLead.leadFlowData?.redirectToDetailPage) {
							leadInfo?.from === 'nimbuss'
								? history.push(
										'/lead-details/' +
											leadInfo.leadId +
											'/' +
											dmaSelector?.user_details?.access_token
								  )
								: history.push('/lead-details');
						} else {
							history.push('/lender-specific-info');
						}
					}
				});
		} else {
			const responseData = {
				lender_id: selectedLead?.selectedLender[0]?.lender_id,
				bank_app_no: data?.bank_app_no.toUpperCase(),
			};
			dispatch(
				lenderMarkAsLogin({
					param: selectedLead?.selectedLender[0]?.lead_id,
					data: responseData,
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						const myPromise = new Promise(function (myResolve) {
							setTimeout(function () {
								let path = '';
								if (
									selectedLead.leadFlowData?.leadStatus === LeadStatus.SELECTION
								) {
									path = '/lender-specific-info';
								} else {
									leadInfo?.from === 'nimbuss'
										? (path =
												'/lead-details/' +
												leadInfo.leadId +
												'/' +
												dmaSelector?.user_details?.access_token)
										: (path = '/lead-details');
								}
								myResolve(history.push(path));
							});
						});
						myPromise.then(() => {
							dispatch(
								leadFlow({
									...leadInfo,
									...selectedLead.leadFlowData,
									leadStatus:
										selectedLead?.leadFlowData?.lead_status ===
										LeadStatus.PROCESSING
											? LEAD_STATUS.FULFILLMENT
											: selectedLead?.leadFlowData.leadStatus,
								})
							);
							reset({
								lender_name: '',
								loan_amount: '',
								bank_app_no: '',
								lan: '',
								sanction_amount: '',
							});
							delete disbursementSchema.fields.disbursement_amount;
							delete disbursementSchema.fields.disbursement_date;
							delete disbursementSchema.fields.lan;
							delete disbursementSchema.fields.sanction_amount;
						});
					}
				});
		}
	};

	const fetchLenderDetails = async () => {
		dispatch(
			getLenderDetails({
				param: `/${selectedLead?.selectedLender[0]?.id}`,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setlenderDetails(response.data);
					reset({
						lender_name: response.data.lender_name,
						loan_amount: response.data.loan_amount,
						bank_app_no: response.data.bank_app_no,
						lan: response.data.lan,
						sanction_amount: response.data.sanction_amount,
					});
				}
			});
	};

	const fetchSchema = () => {
		if (
			selectedLead?.leadFlowData?.applicationStatus === LEAD_STATUS.LOGIN_LABEL
		) {
			delete disbursementSchema.fields.disbursement_amount;
			delete disbursementSchema.fields.disbursement_date;
			delete disbursementSchema.fields.lan;
			delete disbursementSchema.fields.sanction_amount;
			setSchema(disbursementSchema);
		}
		if (
			selectedLead?.leadFlowData?.applicationStatus ===
			LEAD_STATUS.SANCTIONED_LABEL
		) {
			delete disbursementSchema.fields.Data?.fields.disbursement_amount;
			delete disbursementSchema.fields.Data?.fields.disbursement_date;
			delete disbursementSchema.fields.Data?.fields.lan;
			const someSchema = disbursementSchema.concat(
				yup.object().shape({
					...disbursementSchema.fields,
					sanction_amount: yup
						.number()
						.min(1, 'Sanction Amount must be greater than or equal to 1!')
						.typeError('Sanction Amount should be number')
						.required('Sanction Amount is Required'),
				})
			);
			setSchema(someSchema);
		}
		if (
			selectedLead?.leadFlowData?.applicationStatus ===
				LEAD_STATUS.DISBURSED_LABEL ||
			selectedLead?.leadFlowData?.applicationStatus ===
				LEAD_STATUS.PARTIALDISBURSED_LABEL
		) {
			delete disbursementSchema.fields.disbursement_amount;
			// const currentDate = FormatDate(new Date(), 'mm/dd/yyyy');
			if (isPartialOrFull === disbursementType.FULL) {
				const someSchema = disbursementSchema.concat(
					yup.object().shape({
						...disbursementSchema.fields,
						sanction_amount: yup
							.number()
							.min(1, 'Sanction Amount must be greater than or equal to 1!')
							.typeError('Sanction Amount should be number')
							.required('Sanction Amount is required'),
						disbursement_amount: yup
							.number()
							.min(1, 'Disbursement Amount must be greater than or equal to 1!')
							.typeError('Disbursement Amount should be number')
							.required('Disbursement Amount is Required'),
						disbursement_date: yup
							.date()
							// .min(currentDate, 'Date should not be past date')
							.typeError('Please enter a valid date')
							.required('Disbursement Date is Required ')
							.test(
								'disbursement_date',
								'Please enter a valid date',
								(date) => date.getFullYear() <= 9999
							),
						lan: yup
							.string()
							.matches(REGEX.ACCOUNTNO, 'Please Enter Valid Input.')
							.required('LAN No is Required.')
							.max(
								30,
								'Invalid LAN no, enter the value less than 30 characters.'
							),
					})
				);

				setSchema(someSchema);
			} else {
				const someSchema = disbursementSchema.concat(
					yup.object().shape({
						...disbursementSchema.fields,
						sanction_amount: yup
							.number()
							.min(1, 'Sanction Amount must be greater than or equal to 1!')
							.typeError('Sanction Amount should be number')
							.required('Sanction Amount is required'),
						disbursement_amount: yup
							.number()
							.min(1, 'Disbursement Amount must be greater than or equal to 1!')
							// code commened becoz asked by kiran (andro team)
							// .max(
							// 	(disbursedLenderdetails?.sanction_amount ||
							// 		getValues('sanction_amount')) -
							// 		disbursedLenderdetails?.total_disbursal_amount -
							// 		1,
							// 	'Total Disbursed Amount Cannot Exceed Sanctioned Amount'
							// )
							.typeError('Disbursement Amount should be number')
							.required('Disbursement Amount is Required'),
						disbursement_date: yup
							.date()
							// .min(currentDate, 'Date should not be past date')
							.typeError('Please enter a valid date')
							.required('Disbursement Date required ')
							.test(
								'disbursement_date',
								'Please enter a valid date',
								(date) => date.getFullYear() <= 9999
							),
						lan: yup
							.string()
							.required('LAN No is Required.')
							.matches(REGEX.ACCOUNTNO, 'Please Enter Valid Input.')
							.max(
								30,
								'Invalid LAN no, enter the value less than 30 characters.'
							),
					})
				);
				setSchema(someSchema);
			}
		}
	};

	const handleBackButton = () => {
		const myPromise = new Promise(function (myResolve) {
			setTimeout(function () {
				let path = '';
				if (
					selectedLead?.leadFlowData?.leadStatus === LEAD_STATUS.DISBURSED ||
					selectedLead?.leadFlowData?.applicationStatus ===
						LEAD_STATUS.PARTIALDISBURSED
				) {
					leadInfo?.from === 'nimbuss'
						? (path =
								'/lead-details/' +
								leadInfo.leadId +
								'/' +
								dmaSelector?.user_details?.access_token)
						: (path = '/lead-details');
				} else if (leadInfo?.iSBackFromDsburseAndSanction) {
					path = '/bank-visit-site';
				} else if (leadInfo?.isFromDisbursed) {
					path = '/sanction-and-disbursed-detail';
				} else {
					path = '/update-status';
				}
				myResolve(history.push(path));
			}, 10);
		});
		myPromise.then(() => {
			dispatch(
				leadFlow({
					...selectedLead?.leadFlowData,
					applicationStatus: selectedLead?.leadFlowData?.backUpdateStatus,
					// iSBackFromDsburseAndSanction: false,
					update: selectedLead?.leadFlowData?.update + 1 || 1,
				})
			);
		});
	};

	return (
		<IonPage>
			<Header
				name=''
				needArrow={true}
				backButtonDisabled={false}
				backButton={true}
				needArrowOnClick={() => {
					reset({
						lender_name: '',
						loan_amount: '',
						bank_app_no: '',
						lan: '',
						sanction_amount: '',
					});
					handleBackButton();
				}}
				style={{ borderBottom: '1px solid #00000029' }}
			/>
			<IonContent
				scrollY={true}
				className='background-web'
			>
				<div className='container-width-md bg-web my-md-3'>
					<div
						className={` conatiner-spacing-md bg-web-white overflow-auto border-md-radius-10 opacity-1`}
					>
						<IonList>
							<div>
								{t(
									'Please provide the following details in order to update lead status'
								)}
							</div>
							<div
								className='ion-padding'
								slot='content'
							>
								<div className='position-relative'>
									<form onSubmit={handleSubmit(onSubmit)}>
										<Controller
											control={control}
											render={({ field: { onChange, onBlur, name } }) => (
												<CustomInput
													placeholder={t('Lender')}
													value={getValues(name)}
													onChange={onChange}
													onBlur={onBlur}
													errors={t(errors[name]?.message || '')}
													needSuffixIcon={true}
													className='text-uppercase'
													suffixClassName={'position-absolute top-21 end-12'}
													disabled={lenderDetails?.lender_name}
												/>
											)}
											name='lender_name'
										/>
										<Controller
											control={control}
											render={({ field: { onChange, onBlur, name } }) => (
												<CustomInput
													placeholder={t('Loan Amount')}
													value={
														name == 'loan_amount'
															? getValues(name)
																? InputFormatCurrencyValue(getValues(name))
																: getValues(name)
															: getValues(name)
													}
													onChange={(e: any) => {
														e = isNaN(e.detail.value.replaceAll(',', ''))
															? e
															: Number(e.detail.value.replace(/,/g, ''));
														onChange(e);
													}}
													onBlur={onBlur}
													errors={t(errors[name]?.message || '')}
													needSuffixIcon={true}
													disabled={lenderDetails?.loan_amount}
													className='text-uppercase'
													suffixClassName={'position-absolute top-21 end-12'}
													onBeforeInput={(evt: any) => {
														restrictAmountChar(
															evt,
															getValues(name)?.toString()
														);
													}}
												/>
											)}
											name='loan_amount'
										/>
										<Controller
											control={control}
											render={({ field: { onChange, onBlur, name } }) => (
												<CustomInput
													placeholder={t('Bank App. No.')}
													value={getValues(name)}
													onChange={onChange}
													onBlur={onBlur}
													ref={bank_app_no_ref}
													errors={t(errors[name]?.message || '')}
													disabled={lenderDetails?.bank_app_no}
													needSuffixIcon={true}
													className='text-uppercase'
													suffixClassName={'position-absolute top-21 end-12'}
												/>
											)}
											name='bank_app_no'
										/>

										{selectedLead?.leadFlowData?.applicationStatus ===
											LEAD_STATUS.SANCTIONED_LABEL && (
											<Controller
												control={control}
												render={({ field: { onChange, onBlur, name } }) => (
													<CustomInput
														placeholder={t('Sanctioned Amount')}
														value={
															name == 'sanction_amount'
																? getValues(name)
																	? InputFormatCurrencyValue(getValues(name))
																	: getValues(name)
																: getValues(name)
														}
														onChange={(e: any) => {
															e = isNaN(e.detail.value.replaceAll(',', ''))
																? e
																: Number(e.detail.value.replace(/,/g, ''));
															onChange(e);
														}}
														onBlur={onBlur}
														errors={t(errors[name]?.message || '')}
														needSuffixIcon={true}
														className='text-uppercase'
														suffixClassName={'position-absolute top-21 end-12'}
														maxLength='21'
														// disabled={lenderDetails?.sanction_amount} // code commened becoz asked by kiran (andro team)
														onBeforeInput={(evt: any) => {
															restrictAmountChar(
																evt,
																getValues(name)?.toString()
															);
														}}
													/>
												)}
												name='sanction_amount'
											/>
										)}
										{(selectedLead?.leadFlowData?.applicationStatus ===
											LEAD_STATUS.DISBURSED_LABEL ||
											selectedLead?.leadFlowData?.applicationStatus ===
												LEAD_STATUS.PARTIALDISBURSED_LABEL) && (
											<>
												<Controller
													control={control}
													render={({ field: { onChange, onBlur, name } }) => (
														<CustomInput
															placeholder={t('Sanctioned Amount')}
															value={
																name == 'sanction_amount'
																	? getValues(name)
																		? InputFormatCurrencyValue(getValues(name))
																		: getValues(name)
																	: getValues(name)
															}
															onChange={(e: any) => {
																e = isNaN(e.detail.value.replaceAll(',', ''))
																	? e
																	: Number(e.detail.value.replace(/,/g, ''));
																onChange(e);
															}}
															onBlur={onBlur}
															errors={t(errors[name]?.message || '')}
															needSuffixIcon={true}
															// disabled={lenderDetails?.sanction_amount} // code commened becoz asked by kiran (andro team)
															className='text-uppercase'
															suffixClassName={
																'position-absolute top-21 end-12'
															}
															onBeforeInput={(evt: any) => {
																restrictAmountChar(
																	evt,
																	getValues(name)?.toString()
																);
															}}
														/>
													)}
													name='sanction_amount'
												/>
												<Controller
													control={control}
													render={({ field: { onChange, onBlur, name } }) => (
														<CustomInput
															placeholder={t('LAN')}
															value={getValues(name)}
															onChange={onChange}
															onBlur={onBlur}
															errors={t(errors[name]?.message || '')}
															needSuffixIcon={true}
															disabled={lenderDetails?.lan}
															className='text-uppercase'
															suffixClassName={
																'position-absolute top-21 end-12'
															}
														/>
													)}
													name='lan'
												/>
												<IonRadioGroup
													onIonChange={(e: any) => {
														fetchSchema();
														setPartialOrFull(e.detail.value);
														// setHealthCheck(value);
														// setValue('health', value);
														setValue('disbursement_amount', '');
														setValue('disbursement_date', '');
														reset({
															loan_amount: getValues('loan_amount'),
															lender_name: getValues('lender_name'),
															disbursement_amount: '',
															disbursement_date: '',
															bank_app_no: getValues('bank_app_no'),
															lan: getValues('lan'),
															sanction_amount: getValues('sanction_amount'),
														});
													}}
													value={isPartialOrFull}
												>
													<div className='row border-0'>
														<h6 className=''>
															{t('Is this your final or partial disbursement?')}
														</h6>
														{/* {radioData &&
													radioData?.map((item, index) => {
														return ( */}
														<div className='col-6 ion-no-padding'>
															<CustomRadioButton
																justify='start'
																labelPlacement='end'
																name={'partial'}
																value={disbursementType.PARTIAL}
																label={t('Partial')}
															/>
														</div>
														<div className='col-6 ion-no-padding'>
															<CustomRadioButton
																justify='start'
																labelPlacement='end'
																name={'final'}
																value={disbursementType.FULL}
																label={t('Final')}
															/>
															{/* </IonItem> */}
														</div>
													</div>
												</IonRadioGroup>
												<Controller
													control={control}
													render={({ field: { onChange, onBlur, name } }) => (
														<CustomInput
															placeholder={t('Disbursement Amount')}
															value={
																name == 'disbursement_amount'
																	? getValues(name)
																		? InputFormatCurrencyValue(getValues(name))
																		: getValues(name)
																	: getValues(name)
															}
															onChange={(e: any) => {
																e = isNaN(e.detail.value.replaceAll(',', ''))
																	? e
																	: Number(e.detail.value.replace(/,/g, ''));
																onChange(e);
																fetchSchema();
															}}
															onBlur={onBlur}
															errors={t(errors[name]?.message || '')}
															needSuffixIcon={true}
															className='text-uppercase mt-4'
															suffixClassName={
																'position-absolute top-21 end-12'
															}
															onBeforeInput={(evt: any) => {
																restrictAmountChar(
																	evt,
																	getValues(name)?.toString()
																);
															}}
														/>
													)}
													name='disbursement_amount'
												/>
												<Controller
													control={control}
													render={({ field: { onChange, onBlur, name } }) => (
														<CustomInput
															placeholder={t('Disbursement Date')}
															value={getValues(name)}
															type={'date'}
															needSuffixIcon={true}
															suffixClassName={
																'position-absolute date-of-birth-sufix'
															}
															outerClassName={`${'position-relative'}`}
															suffixText={
																<IonImg
																	src='/images/icons/input-dob-calendar-icon.svg'
																	alt='disbursement_date'
																/>
															}
															onChange={onChange}
															onBlur={onBlur}
															errors={t(errors[name]?.message || '')}
															className='text-uppercase'
														/>
													)}
													name='disbursement_date'
												/>
											</>
										)}
										<div className=' py-4 d-none d-md-block'>
											<CustomButton
												// routerLink='/all-lenders'
												type='submit'
												// onClick={() => {
												// 	updateLenderDetails();
												// }}
												disabled={!isValid}
												expand='block'
												title={t('Update')}
											/>
										</div>
									</form>
								</div>
							</div>
						</IonList>
					</div>
				</div>
			</IonContent>
			<IonFooter>
				<div className=' py-1 d-block d-md-none ion-padding'>
					<CustomButton
						// routerLink='/all-lenders'
						type='submit'
						onClick={handleSubmit(onSubmit)}
						expand='block'
						disabled={!isValid}
						title={t('Update')}
					/>
				</div>
			</IonFooter>
		</IonPage>
	);
};
