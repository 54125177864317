import { createSlice } from '@reduxjs/toolkit';

const initialState = {};
const appStarterTemplateSlice = createSlice({
	name: 'appstartertemplate',
	initialState: initialState,
	reducers: {
		language: (state: any, action) => {
			state.language = action.payload;
		},
		chooseLanguage: (state: any, action) => {
			state.chooseLanguage = action.payload;
		},
		gettingStarted: (state: any, action) => {
			state.gettingStarted = action.payload;
		},
	},
});

export const { language, chooseLanguage, gettingStarted } =
	appStarterTemplateSlice.actions;
export default appStarterTemplateSlice.reducer;
