import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { basicInfoKycSchema } from './KycValidationSchema';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
	kycBasicInfo,
	kycStep,
	// kycStep,
	setKycBasicInfo,
} from '../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	capFirstLetter,
	checkFieldsValidToContinue,
	disableNumberArrows,
	showToastAlert,
} from '../../../helpers/functions';
import { useTranslation } from 'react-i18next';

const basicInfoFormKeys = [
	{
		name: 'first_name',
		type: 'text',
		placeholder: 'First Name (as per Aadhaar) *',
	},
	{
		name: 'middle_name',
		type: 'text',
		placeholder: 'Middle Name (as per Aadhaar)',
	},
	{
		name: 'last_name',
		type: 'text',
		placeholder: 'Last Name (as per Aadhaar) *',
	},
	{
		name: 'mobile',
		type: 'number',
		placeholder: 'Mobile Number *',
		disabled: true,
	},
	{
		name: 'alternate_mobile',
		type: 'number',
		placeholder: 'Alternate Mobile Number',
	},
	{
		name: 'email',
		type: 'email',
		placeholder: 'Email *',
	},
];
type UserBasicInfoForm = {
	first_name: string;
	middle_name: string;
	last_name: string;
	mobile: number | string;
	alternate_mobile: number | string;
	email: string;
};

export const BasicInfoKyc = ({ setCurrentStep }: any) => {
	const location: any = useLocation();
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const dmaSelector = useSelector((state: any) => state?.users);
	let continueLater = false;

	useEffect(() => {
		if (location.pathname !== '/kyc') {
			return;
		}
		fetchKycBasicInfo();
	}, [location.pathname]);
	const {
		handleSubmit,
		control,
		reset,
		trigger,
		setValue,
		getValues,
		formState: { errors, isValid },
	}: any = useForm<UserBasicInfoForm>({
		mode: 'all',
		reValidateMode: 'onChange',
		resolver: yupResolver(basicInfoKycSchema),
		defaultValues: {
			first_name: '',
			middle_name: '',
			last_name: '',
			mobile: '',
			alternate_mobile: '',
			email: '',
		},
	});
	const onSubmit = async (data: UserBasicInfoForm) => {
		let id;
		if (dmaSelector?.dmaFlow?.dmaId) {
			id = dmaSelector?.dmaFlow?.dmaId;
		} else {
			id = dmaSelector?.user_details?.id;
		}

		dispatch(
			setKycBasicInfo({
				param: id,
				data: {
					...data,
					first_name: data?.first_name ? capFirstLetter(data?.first_name) : '',
					middle_name: data?.middle_name
						? capFirstLetter(data?.middle_name)
						: '',
					last_name: data?.last_name ? capFirstLetter(data?.last_name) : '',
					mobile: Number(data.mobile),
					alternate_mobile: Number(data.alternate_mobile),
					RW: false,
					continueLater: continueLater,
				},
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status && !continueLater) {
					if (response?.status && location?.state?.from === 'update-details') {
						history.push('/update-details');
						return false;
					}
					if (response?.status) {
						dispatch(kycStep('KYC2'));
						setCurrentStep('step2');
					}
				} else {
					continueLater = false;
					if (dmaSelector?.dmaFlow?.dmaId) {
						history.replace('/dashboard/teams');
					} else {
						history.replace('/dashboard/home');
					}
				}
			});
	};
	const fetchKycBasicInfo = async () => {
		let id;
		if (dmaSelector?.dmaFlow?.dmaId) {
			id = dmaSelector?.dmaFlow?.dmaId;
		} else {
			id = dmaSelector?.user_details?.id;
		}
		dispatch(kycBasicInfo({ param: id + '/step1' }))
			.then(unwrapResult)
			.then((response: any) => {
				let mobile: any;
				if (response?.status) {
					if (dmaSelector?.dmaFlow?.dmaId && dmaSelector?.dmaFlow?.dmaMobile) {
						mobile = dmaSelector?.dmaFlow?.dmaMobile;
					} else {
						mobile = response?.data[0]?.basic_info_kyc_data?.mobile;
					}
					reset({
						...response?.data[0]?.basic_info_kyc_data,
						mobile: mobile,
					});
				}
			});
	};

	return (
		<section className='conatiner-spacing-md overflowX-hidden kyc-content-height'>
			<div>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className='pt-2'
				>
					{basicInfoFormKeys.map(
						({ name, type, placeholder, disabled }: any, index) => {
							return (
								<Controller
									key={`kyc-step-1-${index}`}
									control={control}
									render={({ field: { onChange, onBlur, name } }) => (
										<CustomInput
											type={type}
											name={name}
											placeholder={t(placeholder)}
											onBlur={onBlur}
											onChange={onChange}
											value={getValues(name)}
											errors={t(errors[name]?.message || '')}
											disabled={disabled}
											onBeforeInput={(evt: any) => {
												if (type == 'number') {
													!/[0-9]/.test(evt.data) && evt.preventDefault();
												}
												if (name.toLowerCase().includes('mobile')) {
													if (evt.target.value.length === 10) {
														evt.preventDefault();
													}
												}
											}}
											className={`${
												['first_name', 'middle_name', 'last_name'].includes(
													name
												)
													? 'text-capitalize '
													: ''
											}`}
											onPaste={(e: any) => {
												//Only get numbers on user paste
												if (name.toLowerCase().includes('mobile')) {
													e.preventDefault();
													let pasteContent = e.clipboardData.getData('Text');
													if (pasteContent != '') {
														pasteContent = pasteContent.replace(/\D/g, '');
														if (pasteContent.length > 10) {
															pasteContent = pasteContent.slice(0, 10);
														}
													}
													setValue(name, pasteContent);
													trigger(name);
												}
											}}
											onKeyDown={(e: any) => {
												if (type == 'number') {
													disableNumberArrows(e);
												}
											}}
											onWheel={(e: any) => {
												if (type == 'number') {
													e.target.blur();
												}
											}}
											// maxLength={10}
										/>
									)}
									name={name}
									rules={{ maxLength: 10 }}
								/>
							);
						}
					)}
					<div
						className='position-fixed-md w-100 bottom-0 start-0 px-3 pb-3 p-md-0'
						style={{ backgroundColor: '#fff', zIndex: 9 }}
					>
						<CustomButton
							className='w-100 fw-bold dashboard-add-lead-button mb-3'
							expand='block'
							fill='outline'
							title={t('Continue Later')}
							onClick={() => {
								if (checkFieldsValidToContinue(errors)) {
									continueLater = true;
									onSubmit(getValues());
								} else {
									showToastAlert({
										type: 'error',
										message: 'Please fix above errors',
									});
								}
							}}
						/>
						<CustomButton
							className='btn-blue'
							type='submit'
							expand='block'
							title={
								location?.state?.from === 'update-details'
									? t('Update')
									: t('Continue')
							}
							disabled={!isValid}
						/>
					</div>
				</form>
			</div>
		</section>
	);
};
