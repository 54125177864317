import * as Yup from 'yup';
import { REGEX } from '../../../../helpers/constant';

export const documentObj = {
    file: '',
    document_type: '',
    password: '',
    preview: '',
    fileObj: {
        name: '',
        type: '',
    },
    message: '',
};

export enum BusinessType {
    PARTNERSHIP = 'Partnership',
    PVT_LTD = 'Private Limited',
    LLP = 'Limited Liability Partnership(LLP)',
    SOLE_PROP = 'Sole Proprietorship'
}

export const documentValidation = (params: any = {}) => {
    let validations: any;
    if (params?.isRequired) {
        validations = Yup.array().of(
            Yup.object({
                file: Yup.string().required(params?.message + ' is required'),
                document_type: Yup.string().required(params?.message + ' Document Type is required'),
                password: Yup.string().notRequired(),
            })
        )
    } else {
        validations = Yup.array().of(
            Yup.object({
                file: Yup.string().notRequired(),
                document_type: Yup.string().notRequired(),
                password: Yup.string().notRequired(),
            })
        )
    }
    return validations;
}

export const commonDocUploadSchema = (isBusinessSchema: boolean = false) => {
    let commonDocSchema: any = {
        cust_pan: Yup.array().of(
            Yup.object({
                file: Yup.string().required('Customer PAN is required'),
                document_type: Yup.string().required('ID Proof Document Type is required'),
            })
        ),
        cust_aadhar_front: Yup.array().of(
            Yup.object({
                file: Yup.string().required('Customer Aadhar Front is required'),
                document_type: Yup.string().required('Address Proof Document Type is required'),
            })
        ),
        cust_aadhar_back: Yup.array().of(
            Yup.object({
                file: Yup.string().required('Customer Aadhar Back is required'),
                document_type: Yup.string().required('Address Proof Document Type is required'),
            })
        ),
        cust_curr_resi_proof: Yup.array().of(
            Yup.object({
                file: Yup.string().required('Customer Current Residence Proof is required'),
                document_type: Yup.string().required('Residence Current Proof Document Type is required'),
                password: Yup.string().notRequired(),
            })
        )
    };

    if (isBusinessSchema) {
        commonDocSchema = {
            ...commonDocSchema,
            gst_doc: Yup.array().of(
                Yup.object({
                    file: Yup.string().required('GST Document is required'),
                    document_type: Yup.string().required('Document Type is required'),
                    password: Yup.string().notRequired(),
                })
            ),

            // If is_owned_business_premises === true
            business_owner_doc: documentValidation({
                isRequired: false
            }),

            // Required for Partnership and LLP
            partnership_deed_doc: documentValidation({
                isRequired: false
            }),

            // PVT LTD
            moa_doc: documentValidation({
                isRequired: false
            }),

            aoa_doc: documentValidation({
                isRequired: false
            }),

            shareholding_doc: documentValidation({
                isRequired: false
            }),
        }
    }

    return commonDocSchema;
}

export const applicantSchema: any = Yup.object({
    business_type: Yup.string().required('Business Type is required'),
    gst_no: Yup.string().required('GST No. is required')
        .matches(REGEX.GST, {
            message: 'GST No. Should Be 22AAAAA0000A1Z5 Format.',
            excludeEmptyString: true,
        }),
    is_key_promoter: Yup.boolean().notRequired(),
    is_owned_business_premises: Yup.boolean().notRequired(),
    is_owned_home_premises: Yup.boolean().notRequired(),
    is_co_applicant: Yup.boolean().notRequired(),
    ...commonDocUploadSchema(true)
});

export const commonDocUploadFields = (isBusinessSchema: boolean = false) => {
    let commonDocFields: any = [
        {
            name: 'cust_pan',
            type: 'file',
            placeholder: 'Customer Pan Card *',
        }, {
            name: 'cust_aadhar_front',
            type: 'file',
            placeholder: 'Customer Aadhar Front *',
        }, {
            name: 'cust_aadhar_back',
            type: 'file',
            placeholder: 'Customer Aadhar Back *',
        }, {
            name: 'cust_curr_resi_proof',
            type: 'file',
            placeholder: 'Customer Current Residence Proof *',
        }
    ];

    if (isBusinessSchema) {
        commonDocFields = [
            ...commonDocFields,
            {
                name: 'gst_doc',
                type: 'file',
                placeholder: 'GST Document *',
            }, {
                name: 'partnership_deed_doc',
                type: 'file',
                placeholder: 'Partnership Deed Document *',
            }, {
                name: 'moa_doc',
                type: 'file',
                placeholder: 'MOA Document *',
            }, {
                name: 'aoa_doc',
                type: 'file',
                placeholder: 'AOA Document *',
            }, {
                name: 'shareholding_doc',
                type: 'file',
                placeholder: 'Shareholding Document *',
            }, {
                name: 'business_owner_doc',
                type: 'file',
                placeholder: 'Business Ownership Document *',
            }
        ]
    }

    return commonDocFields;
}

export const homePremisesField = {
    name: 'is_owned_home_premises',
    type: 'checkbox',
    placeholder: 'I am owner of the current residence',
};

export const applicantFields: any = [
    {
        name: 'business_type',
        type: 'dropdown',
        placeholder: 'Business Type *',
    }, {
        name: 'gst_no',
        type: 'text',
        placeholder: 'GST NO. *',
    }, {
        name: 'is_key_promoter',
        type: 'checkbox',
        placeholder: 'I am the Promoter',
    }, {
        name: 'is_owned_business_premises',
        type: 'checkbox',
        placeholder: 'I owned the Business Premises',
    },
    homePremisesField,
    {
        name: 'is_co_applicant',
        type: 'checkbox',
        placeholder: 'Do you want to add Co-Applicant?',
    },
    ...commonDocUploadFields(true)
];

// Co-Applicant
export const coApplicantSchema: any = Yup.object({
    first_name: Yup.string()
        .trim()
        .required("First Name is Required")
        .max(100, "First Name should not be more than 100")
        .matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
    last_name: Yup.string()
        .trim()
        .required("Last Name is Required")
        .max(100, "Last Name should not be more than 100")
        .matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
    phone_no: Yup.string()
        .length(10, 'Mobile No. Should Be 10 Digits.')
        .required('Mobile is Required.')
        .matches(REGEX.INDIAN_MOBILE_NUMBERS, 'Please Enter a valid Mobile No.'),
    email: Yup.string().trim()
        .required('Email is required')
        .matches(REGEX.EMAIL, 'Please Enter Valid Email'),
    gender: Yup.string().required('Gender is required'),
    pan_no: Yup.string().trim()
        .required('PAN No. is required')
        .matches(
            REGEX.PAN,
            "PAN no. should be in alphanumeric format 'ABCDE1234A' only"
        ),
    dob: Yup.date()
        .required('DOB is Required.')
        .typeError('Please select valid date')
        .max(new Date(Date.now()), 'Date cannot be in the future')
        .test('dob', 'Age must be 18 or older', function (birthdate) {
            const cutoff = new Date();
            cutoff.setFullYear(cutoff.getFullYear() - 18);
            return birthdate <= cutoff;
        }),
    primary_address_line_1: Yup.string().trim().required('Primary Address Line 1 is required'),
    primary_address_line_2: Yup.string().trim().required('Primary Address Line 2 is required'),
    primary_pincode: Yup.string()
        .required('Primary Pincode is required')
        .matches(/^\d{6}$/, 'Pincode should be 6 Digits')
        .length(6, 'Pincode should be 6 Digits'),
    is_owned_home_premises: Yup.boolean().notRequired(),
    is_key_promoter: Yup.boolean().notRequired(),
    ...commonDocUploadSchema(false)
});

export const coApplicantFields: any = [
    {
        name: 'first_name',
        type: 'text',
        placeholder: 'First Name *',
    }, {
        name: 'last_name',
        type: 'text',
        placeholder: 'Last Name *',
    }, {
        name: 'phone_no',
        type: 'text',
        placeholder: 'Phone No. *',
    }, {
        name: 'email',
        type: 'text',
        placeholder: 'Email *',
    }, {
        name: 'gender',
        type: 'dropdown',
        placeholder: 'Gender *',
    }, {
        name: 'pan_no',
        type: 'text',
        placeholder: 'Pan No *',
    }, {
        name: 'dob',
        type: 'date',
        placeholder: 'Date Of Birth *',
    }, {
        name: 'primary_address_line_1',
        type: 'text',
        placeholder: 'Primary Address Line 1 *',
    }, {
        name: 'primary_address_line_2',
        type: 'text',
        placeholder: 'Primary Address Line 2 *',
    }, {
        name: 'primary_pincode',
        type: 'text',
        placeholder: 'Primary Pincode *',
    },
    homePremisesField,
    ...commonDocUploadFields(false)
];

export const formSchemas = (selectedTab: any) => {
    let schemas: any;
    if (selectedTab.includes('PrimaryApplicantForm')) {
        schemas = applicantSchema;
    } else {
        schemas = coApplicantSchema;
    }
    return schemas;
}

export const formDefaultValues = (formData: any, selectedTab: any, coTabIndex: any) => {
    let defaultValues: any = {};
    let spfDetails: any = formData?.lender_spf_details;
    if (selectedTab.includes('PrimaryApplicantForm')) {
        defaultValues = {
            business_type: spfDetails?.business_type || '',
            gst_no: spfDetails?.gst_no || '',
            is_owned_business_premises: spfDetails?.is_owned_business_premises || false,
            is_owned_home_premises: spfDetails?.is_owned_home_premises || false,
            is_key_promoter: spfDetails?.is_key_promoter || false,
            is_co_applicant: spfDetails?.is_co_applicant || false,
            cust_pan: spfDetails?.cust_pan || [{ ...documentObj, document_type: 'pan' }],
            cust_aadhar_front: spfDetails?.cust_aadhar_front || [{ ...documentObj, document_type: 'aadhaar' }],
            cust_aadhar_back: spfDetails?.cust_aadhar_back || [{ ...documentObj, document_type: 'aadhaar' }],
            cust_curr_resi_proof: spfDetails?.cust_curr_resi_proof || [documentObj],
            gst_doc: spfDetails?.gst_doc || [{ ...documentObj, document_type: 'gst_reg_certificate' }],
            partnership_deed_doc: spfDetails?.partnership_deed_doc || [{ ...documentObj, document_type: 'partnership_deed' }],
            moa_doc: spfDetails?.moa_doc || [{ ...documentObj, document_type: 'aoa_mom_coi' }],
            aoa_doc: spfDetails?.aoa_doc || [{ ...documentObj, document_type: 'aoa_mom_coi' }],
            shareholding_doc: spfDetails?.shareholding_doc || [{ ...documentObj, document_type: 'shareholding_and_directors' }],
            business_owner_doc: spfDetails?.business_owner_doc || [documentObj],
        };
    } else {
        let coAppData: any = {};
        if (spfDetails?.co_applicant && spfDetails?.co_applicant?.length > 0) {
            coAppData = spfDetails?.co_applicant?.[coTabIndex];
        }
        defaultValues = {
            first_name: coAppData?.first_name || '',
            last_name: coAppData?.last_name || '',
            phone_no: coAppData?.phone_no || '',
            email: coAppData?.email || '',
            gender: coAppData?.gender || '',
            pan_no: coAppData?.pan_no || '',
            dob: coAppData?.dob || '',
            primary_address_line_1: coAppData?.primary_address_line_1 || '',
            primary_address_line_2: coAppData?.primary_address_line_2 || '',
            primary_pincode: coAppData?.primary_pincode || '',
            is_owned_home_premises: coAppData?.is_owned_home_premises || false,
            is_key_promoter: coAppData?.is_key_promoter || false,
            cust_pan: coAppData?.cust_pan || [{ ...documentObj, document_type: 'pan' }],
            cust_aadhar_front: coAppData?.cust_aadhar_front || [{ ...documentObj, document_type: 'aadhaar' }],
            cust_aadhar_back: coAppData?.cust_aadhar_back || [{ ...documentObj, document_type: 'aadhaar' }],
            cust_curr_resi_proof: coAppData?.cust_curr_resi_proof || [documentObj]
        };
    }
    return defaultValues;
};

export const formFields = (selectedTab: any) => {
    let fieldsOfForm: any = [];
    if (selectedTab.includes('PrimaryApplicantForm')) {
        fieldsOfForm = applicantFields;
    } else {
        fieldsOfForm = coApplicantFields;
    }
    return fieldsOfForm;
}
