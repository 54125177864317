import {
	IonContent,
	IonFooter,
	IonImg,
	IonItem,
	IonPage,
	IonToggle,
	isPlatform,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';

import { Header } from '../../components/layout/header/Header';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useHistory } from 'react-router';
import { INAPPCONFIG } from '../../helpers/constant';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { digio, esigncheck } from '../../store/reducers/user/userSlice';
// import { CustomModal } from '../../components/common/custom-modal/CustomModal';
// import digioApi from 'digio-api';

export default function AgreementSignType() {
	const { t } = useTranslation();
	const history = useHistory();
	const [checked, setChecked] = useState(false);
	// const [userName, setUserName] = useState('');
	const [browserInstance, setBrowserInstance] = useState({});
	const [browserClosed, setBrowserClosed] = useState(false);
	const dispatch = useDispatch();
	// const [open, setOpen] = useState(false);
	// const [src, setSrc] = useState('');
	// const getDashboardData = async () => {

	const userData = useSelector((state: any) => state?.users);
	const checkESign = async () => {
		await dispatch(esigncheck())
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					history.replace('/dashboard');
				}
			});
	};

	useEffect(() => {
		if (browserClosed || window.location.href.includes('status=success')) {
			checkESign();
			setBrowserClosed(false);
		}
	}, [browserClosed]);
	useEffect(() => {
		if (!Object.keys(browserInstance).length) {
			return;
		}
		// browserInstance.on('')
	}, [browserInstance]);
	// var digio_api = require('digio-api');

	// const api = new digioApi('<access_token>');

	// api.domains
	// 	.create('example.com', '127.0.0.1')
	// 	.do(function (err: any, data: any) {
	// 		if (err) return console.error('Error: ' + err.message);
	// 		console.log('Success: ' + data.domain.name + ' created.');
	// 	});
	// const onUrlSuccess = () => {
	// 	console.log('sign done event received')
	// }

	const handledClick = async () => {
		// const isAddhar = checked ? 'aadhaar' : 'electronic';
		const isAddhar = 'aadhaar';
		await dispatch(
			digio({
				data: {
					mobile: Number(userData?.user?.mobile),
					username: userData?.homepage?.profile?.name,
					sign_type: isAddhar,
				},
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (!response?.status) {
					return;
				}
				let digioUrl = response?.redirectionUrl;
				if (!isPlatform('capacitor')) {
					digioUrl += `?redirect_url=${window.location.href}`;
				}
				// extra params for ios
				let config = INAPPCONFIG;
				if (isPlatform('ios')) {
					config += ',location=no';
				}
				// setSrc('https://ext.digio.in/#/gateway/login/DID2306261137025923DVJXDTSZO715X/1234/9870788921');
				// Browser.open({ url: 'https://ext.digio.in/#/gateway/login/DID2306261137025923DVJXDTSZO715X/1234/9870788921' })
				const inAppBrowser = InAppBrowser.create(
					digioUrl,
					isPlatform('ios') ? '_blank' : '_self',
					config
				);
				// const inAppBrowser = InAppBrowser.create('https://ext.digio.in/#/gateway/login/DID2306261137025923DVJXDTSZO715X/1234/9870788921', '_blank', 'hidenavigationbuttons=yes,hideurlbar=yes');
				setBrowserInstance(inAppBrowser);
				inAppBrowser.on('loadstop').subscribe((result: any) => {
					// setBrowserLastUrl(result?.url);
					if (result?.url.includes('type=success')) {
						// browserInstance?.close();
						inAppBrowser.close();
						setBrowserClosed(true);
					}
				});
				if (isPlatform('ios')) {
					inAppBrowser.on('exit').subscribe(() => {
						// setBrowserLastUrl(result?.url);
						// console.log('exit event', result);
						// if (result?.url.includes('type=success')) {
						// browserInstance?.close();
						// inAppBrowser.close();
						setBrowserClosed(true);
						// }
					});
				}
			});
		// checkUrl();
		// setOpen(true);
		// history.push('/otp-verification', {
		// 	agreementSignOtp: true,
		// 	contentTitle: `Enter the OTP we have sent on your Mobile No. +91 XXXXXX${ctx?.userInfo?.mobile} linked with your Aadhaar.`,
		// });
	};
	return (
		<>
			<IonPage>
				<Header
					name={t('Select the type of e-sign')}
					titleClassName='fw-bold'
					backButton={true}
					needArrow={true}
				/>
				<IonContent className=''>
					<div className='bg-web py-md-3 h-100'>
						<div className='h-100 bg-web-white container-width-md border-radius-10 px-3 py-md-3'>
							<div>
								<IonImg src='/images/icons/dummy-aadhaar.svg' />
							</div>
							<IonItem
								lines='none'
								className='pb-2 pt-4'
								style={{ '--padding-end': 0, '--padding-start': 0 }}
							>
								{/* <IonToggle
									justify='space-between'
									onIonChange={(event: any) => setChecked(event.detail.checked)}
								>
								</IonToggle> */}
								{t('This is Aadhaar based e-sign')}
							</IonItem>
							{/* <p className='m-0 fs-14'>
								{t('If you do not OPT for Aadhaar based eSign,')}{' '}
								{t(`it will be considered as a Non-Aadhaar based eSign.`)}
							</p> */}
						</div>
					</div>
				</IonContent>
				<IonFooter className='ion-padding ion-no-border bg-web-white'>
					<CustomButton
						className='btn-blue w-25-md'
						expand='block'
						title={t('Proceed')}
						onClick={handledClick}
					/>
				</IonFooter>
			</IonPage>
			{/* <CustomModal
				id='bulkUploadmodal'
				isOpen={open}
				initialBreakpoint={1}
				handleClose={() => setOpen(false)}
				breakpoints={[]}
				modalContent={<IframeDigio src={src} onSuccess={onUrlSuccess} />}
				needCross={false}
				needArrow={false}
			/> */}
		</>
	);
}

// const IframeDigio = ({ src }: any) => {
// 	const ref: any = useRef(null);

// 	// eslint-disable-next-line @typescript-eslint/no-this-alias
// 	// if (ref?.current?.src.includes())
// 	// useEffect(() => {
// 	// 	const iframe = ref?.current;

// 	// 	const handleUrlChange = (event: any) => {
// 	// 		if (event.data.type === 'urlChange') {
// 	// 			const currentUrl = event.data.url;
// 	// 			console.log('URL changed:', currentUrl);
// 	// 		}
// 	// 	};

// 	// 	const handleMessage = (event: any) => {
// 	// 		handleUrlChange(event);
// 	// 	};

// 	// 	if (iframe) {
// 	// 		window.addEventListener('message', handleMessage);
// 	// 	}

// 	// 	return () => {
// 	// 		window.removeEventListener('message', handleMessage);
// 	// 	};
// 	// }, []);
// 	return (
// 		<div>
// 			<iframe
// 				ref={ref}
// 				style={{
// 					position: 'absolute',
// 					top: 0,
// 					left: 0,
// 					bottom: 0,
// 					right: 0,
// 					width: '100%',
// 					height: '100%',
// 					border: 'none',
// 				}}
// 				src={src}
// 				height={'100%'}
// 				width={'100%'}
// 				// onChange={(ev: any) => console.log('event', ev)}
// 				// onWaiting={(event) => console.log('event', event)}
// 				// onLoad={() => {
// 				// 	const currentUrl = ref?.current?.contentWindow?.location.href;
// 				// 	window.postMessage({ type: 'urlChange', url: currentUrl }, '*');
// 				// }}
// 				// contentWindow={(evne: any)=>console.log('hii')}
// 			/>
// 		</div>
// 	);
// };
