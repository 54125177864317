//// @ts-nocheck


import {
  IonButtons,
  IonChip,
  IonCol,
  IonContent,
  IonFooter,
  IonImg,
  IonPage,
  IonRow
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import useApi from "../../../api/useApi";
import { CustomButton } from "../../../components/common/custom-button/CustomButton";
import CustomNote from "../../../components/common/custom-note/CustomNote";
import { Header } from "../../../components/layout/header/Header";
import ApplicationContext from "../../../context/ApplicationContext";
import { useBusyLoading } from "../../../context/BusyLoadingContext";
import {
  ADD_INSURANCE_POLICY,
  UPDATE_STATUS,
} from "../../../helpers/api-endpoints";
import { bankIcons } from "../../../helpers/bankIcons";
import { INSURANCE_STATUS } from "../../../helpers/constant";
import {
  ShareData,
  showToastAlert
} from "../../../helpers/functions";
import {
  PolicyRequest,
  StatusUpdateRequest,
} from "../../../model/insurance_bean";

interface LocationState {
  selectedLendersdata: any[]; // Adjust 'any[]' to the correct type of your data if possible
}

export const SelectedInsurerList: React.FC = () => {
  const [selectedLendersdata, setSelectedLendersdata] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();

  const { applicationBean, setAppBean } = useContext(ApplicationContext);
  const [showAssignModal, setShowAssignModal] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [removeLender, setRemoveLender] = useState({});
  const [url, setUrl] = useState("");
  const { responseData, loading, executeRequest, error } = useApi<any>(
    `${ADD_INSURANCE_POLICY}`
  );

  const statusUpdateAPI = useApi<any>(`${UPDATE_STATUS}`);

  const { setBusyState } = useBusyLoading();

  useEffect(() => {
    setBusyState(loading);
  }, [loading, statusUpdateAPI.loading]);

  useEffect(() => {
    if (location.state) {
      const selectedLendersdata = (location.state as LocationState)
        .selectedLendersdata;
      setSelectedLendersdata(selectedLendersdata);
    }
  }, [location.state]);

  useEffect(() => {
    if (location.pathname === '/insuranceSelectedList') {
    const filteredData = selectedLendersdata.filter(
      (lender) => lender.checked === true
    );
    setFilteredData(filteredData);
  }
  }, [selectedLendersdata]);

  const setBankIcon = (bankName: string) => {
    const bN = bankName.toLowerCase();
    for (const key of Object.keys(bankIcons)) {
      if (bN.includes(key)) {
        return bankIcons[key];
      }
    }
    return "/images/icons/leads/bank_icon_placeholder.svg";
  };

  useEffect(() => {
    if (responseData?.status) {
      if (applicationBean) {
        applicationBean.applicationPolicies = responseData?.data;
        applicationBean.statusId = INSURANCE_STATUS.AUTHORIZATION_PENDING;

        setAppBean(applicationBean);
        let statusUpdateRequest = new StatusUpdateRequest();
        statusUpdateRequest.applicationId = applicationBean.applicationId;
        statusUpdateRequest.statusId = INSURANCE_STATUS.AUTHORIZATION_PENDING;

        statusUpdateAPI.executeRequest("put", statusUpdateRequest);
      }
    }
  }, [responseData]);

  useEffect(() => {
    if (statusUpdateAPI.responseData?.status) {
      history.push("/shareOtp");
    }
  }, [statusUpdateAPI.responseData]);

  useEffect(() => {
    let errorStub = error;
    if (error) {
      errorStub = error;
    } else if (statusUpdateAPI.error) {
      errorStub = statusUpdateAPI.error;
    }
    showToastAlert({ type: "error", message: errorStub?.message });
  }, [error, statusUpdateAPI.error]);

  const removeLenderFromList = (lenderId: number) => {
    const updatedFilteredData = filteredData.filter(
      (lender) => lender.id !== lenderId
    );
    setFilteredData(updatedFilteredData);
  };

  const leadInfo = {
    leadId: "201854",
    leadStatus: "all",
    from: "lead",
    via: "test",
  };

  return (
    <IonPage>
      <Header
        name={t("Selected Insurers")}
        needCross={true}
        // needCrossOnClick={() => {
        //   if (leadInfo?.from === 'customer') {
        //     history.push('/lenders-list');
        //   } else {
        //     setShowPageExitPopup(true);
        //   }
        // }}
        backButtonDisabled={false}
        backButton={true}
        style={{ borderBottom: "1px solid #00000029" }}
        endItem={
          <IonButtons slot="end">
            {leadInfo?.from !== "nimbuss" && (
              // ||
              //   leadInfo?.from !== 'customer'
              <>
                {leadInfo?.via !== "share" && (
                  <>
                    <IonButtons className="">
                      <IonImg
                        className="cursor-pointer"
                        src="/images/icons/assign-icon.svg"
                        alt="assign"
                        onClick={() => setShowAssignModal(true)}
                      />
                    </IonButtons>
                    <IonButtons className="mx-3">
                      <IonImg
                        className="cursor-pointer"
                        src="/images/icons/share-app-menu-icon.svg"
                        alt="assign"
                        onClick={() => {
                          const finalUrl =
                            url.split("?")?.[0] +
                            "?d=" +
                            window.btoa(`lead_id=${leadInfo?.leadId}`);
                          ShareData("", "", finalUrl);
                        }}
                      />
                    </IonButtons>
                  </>
                )}
              </>
            )}
          </IonButtons>
        }
      />

      <IonContent>
        <div className="bg-web lead-lender-list-container-spacing overflowY-auto h-100">
          <div className="bg-web-white px-md-3 py-md-3 border-md-radius-10">
            <IonButtons
              className="ms-2 my-3 conatiner-spacing-md"
              onClick={() => {
                history.push("/insurerlist");
              }}
            >
              <IonImg
                className="me-2"
                src="/images/icons/add-lenders-button.svg"
                alt="assign"
              />
              <p
                className="m-0 fs-14 fw-700 cursor-pointer"
                style={{
                  color: "var(--ion-color-primary)",
                }}
              >
                {t("Add More Insurers")}
              </p>
            </IonButtons>
            <IonRow className="conatiner-spacing-md">
              {filteredData.map((item: any) => {
                return (
                  <IonCol
                    key={item.id}
                    size="12"
                    sizeMd="6"
                    className="d-flex w-100"
                  >
                    <div className="d-flex justify-content-between border p-2 rounded-3 position-relative m-1 w-100">
                      <div
                        onClick={() => {
                          removeLenderFromList(item.id);
                        }}
                        // onClick={() => {
                        //   setRemoveLender(
                        //     removeLenderFromList(item.id)
                        //   );
                        //   setOpenModal(true);
                        // }}
                        className="position-absolute cursor-pointer"
                        style={{ top: "-15px", right: "-10px" }}
                      >
                        <img
                          src="/images/icons/border-close.svg"
                          alt="close button"
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        <div
                          className="border d-flex justify-content-center align-items-center me-2"
                          style={{
                            borderRadius: "50%",
                            maxWidth: "48px",
                            maxHeight: "48px",
                          }}
                        >
                          <img
                            src={setBankIcon(item?.name)}
                            alt={item.name}
                            style={{
                              minWidth: "48px",
                              minHeight: "48px",
                            }}
                          />
                        </div>
                        <div>
                          <h4 className="m-0 fs-14">{item.name}</h4>
                          {/* <span className="fs-12 text-grey">{`${item.roi} %p.a.`}</span> */}
                        </div>
                      </div>
                      <div>
                        <div className="fs-12 text-capitalize">
                          <IonChip
                            className={`fs-12 ${
                              item.status !== null &&
                              item.status.toLowerCase() === "online"
                                ? "chip-online"
                                : "chip-offline"
                            }`}
                            style={{
                              padding: "10px",
                              height: "23px",
                            }}
                          >
                            {item.status}
                          </IonChip>
                        </div>
                      </div>
                    </div>
                  </IonCol>
                );
              })}
            </IonRow>
          </div>
        </div>
        <div
          className="position-fixed w-100 bottom-0 start-0 px-3 pb-3"
          style={{ backgroundColor: "#fff" }}
        >
          {/* <CustomButton
            className="btn-blue"
            type="submit"
            expand="block"
            title="Update"
            onClick={() => {

              let policyRequestList: PolicyRequest[] = [];

              console.log("hbs final selectedLendersdata", filteredData);

              filteredData.map((item: any) => {
                // applicationBean
                var stub: PolicyRequest = {
                  applicationId: applicationBean?.applicationId!,
                  insurerId: item.id,
                  statusId: INSURANCE_STATUS.AUTHORIZATION_PENDING,
                  expiryDate: null,
                  issueDate: null,
                  planName: null,
                  policyId: null,
                  policyNumber: null,
                  renewalDate: null,
                  sumAssured: null,
                  verificationByMobOrEmail: null,
                  verifiedOn: null,
                };

                policyRequestList.push(stub);
              });


              const payload = policyRequestList;

              executeRequest("post", payload);
              // if(applicationBean) {
              //   applicationBean.applicationPolicies = policyRequestList;
              //   setAppBean(applicationBean);
              // }




              // // history.push('/shareOtp');
            }}
          // disabled={!isValid}
          // title={t('Update')}
          // disabled={!isValid}
          /> */}
        </div>
      </IonContent>

      {/* <CustomModal
				isOpen={openModal}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setOpenModal(false)}
				breakpoints={[]}
				className='height-auto confirm-popup-middle'
				modalContent={PageExitPopUp(
					() => {
						setOpenModal(false);
					},
					() => {
						removeLenderFromList(removeLender);
						setOpenModal(false);
					},
					'Cancel',
					'Remove',
					'className',
					t('Are You Sure You Want to Remove Lender') + '?'
				)}
				needIonContentWraper={false}
				needCross={false}
				needArrow={false}
			/> */}

      <IonFooter>
        <CustomNote
          {...{
            className: "lead-lender-list-container-spacing py-2",
            header: "",
            content: `${t(
              "By clicking on Proceed you will not be able to add or remove insurers"
            )}`,
          }}
        />
        <div className="lead-lender-list-container-spacing ion-padding bg-white">
          <div className="d-flex align-items-center justify-content-between justify-content-md-end flex-row">
            <div className="fw-700 me-md-4">
              {filteredData.length} {t("Selected")}
            </div>
            <CustomButton
              className="fw-bold dashboard-add-lead-button"
              expand="block"
              onClick={() => {
                let policyRequestList: PolicyRequest[] = [];

                console.log("hbs final selectedLendersdata", filteredData);

                filteredData.map((item: any) => {
                  // applicationBean
                  const stub: PolicyRequest = {
                    applicationId: applicationBean?.applicationId!,
                    insurerId: item.id,
                    statusId: INSURANCE_STATUS.AUTHORIZATION_PENDING,
                    expiryDate: null,
                    issueDate: null,
                    planName: null,
                    policyId: null,
                    policyNumber: null,
                    closeReason: null,
                    paymentReceipt:null,
                    plvcRejectedReason:null,
                    policyBondReceivedDate:null,
                    postponementDate:null,
                    remarks:null,
                    welcomeCallDate:null,
                    welcomeCallRemarks:null,
                    renewalDate: null,
                    sumAssured: null,
                    verificationByMobOrEmail: null,
                    verifiedOn: null,
                    copsExecId:null,
                    paymentVideo:null,
                    policyTerm:null,
                    premiumPaymentTerm:null,
                    premiumAmount:null,
                    insurerApplicationNo:null,
                    premiumFrequency:null
                  };

                  policyRequestList.push(stub);
                });

                const payload = policyRequestList;

                executeRequest("post", payload);
                // if(applicationBean) {
                //   applicationBean.applicationPolicies = policyRequestList;
                //   setAppBean(applicationBean);
                // }
                // // history.push('/shareOtp');
              }}
              title={t("Proceed")}
            />
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

