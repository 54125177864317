import { apiService } from '../store/apiService';
export const TOKEN_NAME = 'oocrmt';
export const LENDER_SPECIFIC_DATA = 'LenderSpecificData';
export const ROLE = 'role';
export const LANGUAGE = 'ooal';
import packageInfo from '../../package.json';
export const UUID = 'uuid';
export const APP_VERSION = packageInfo.version;

export const STATUS_CODE = {
	SIGNIN_SUCCESS: 'SIGNIN_SUCCESS',
	SUCCESS: 'SUCCESS',
	VERIFICATION_SUCCESSFUL: 'VERIFICATION_SUCCESSFUL',
};

export const ONBOARDING_STEP = {
	REGISTER: 'register',
	DASHBOARD: 'dashboard',
};

export const LEAD_STATUS = {
	BUREAU_LABEL: 'Bureau',
	GENERATION: 'Generation',
	SANCTIONED_LABEL: 'Sanctioned',
	PROCESSING_LABEL: 'Processing',
	FULFILLMENT_LABEL: 'Application Fulfillment',
	CLOSED_LABEL: 'Closed',
	SELECTION_LABEL: 'Lender Selection',
	DISBURSED_LABEL: 'Disbursed',
	LOGIN_LABEL: 'Login',
	UNDERPROCESS_LABEL: 'Under Process',
	PARTIALDISBURSED_LABEL: 'Partially Disbursed',
	RAW_LABEL: 'Raw Leads',
	GENERATED_LABEL: 'Leads Generated',
	APPLICATION_CLOSED_LABEL: 'Closed',
	CREDIT_BUREAU_LABEL: 'Check Bureau',
	CREDIT_BUREAU: 'CUSTOMER',
	ALL: 'all',
	GENERATED: '1',
	BUREAU: '10',
	PROPOSERDETAIL: '21',
	BASICDETAILSOFLIFEASSURED: '22',
	OCCUPATIONDETAILSOFASSURED: '23',
	DOCUMENTUPLOADED: '24',
	MOBILEVERIFICATION: '25',
	PROCESSING: '30',
	SELECTION: '40',
	CLOSED: '90',
	LOGIN: '50',
	SANCTIONED: '51',
	DISBURSED: '52',
	APPLICATION_CLOSED: '53',
	UNDERPROCESS: '60',
	PARTIALDISBURSED: '54',
	CALLBACKFOLLOWUP: '4',
	CALLBACKUPDATESTAGE: '91',
	FULFILLMENT: '70',
	RAW: '0',
	ALLOCATOR: '110',
	APPLICATION_FULFILLED: 'Application Fulfillment',
	BASIC_DETAILS_OF_LIFE_ASSURED: 'Basic Details Of Lifeassured',
	OCCUPATION_DETAILS_OF_ASSURED: 'Occupation Details Of Assured',
	DOCUMENTUPLOADED_LABEL: 'Document Uploaded',
	MOBILEVERIFICATION_LABEL: 'Mobile Verification',
	PROPOSERDETAIL_LABEL: 'Proposer Detail',
	RAW_LEAD_LABEL: 'Raw Leads',
	ALLOCATOR_LABEL: 'Allocate Leads',

	//insurance dashboard constants
	PLVC: 'PLVC',
	PLVC_REJECTED: 'PLVC Rejected',
	PLVC_ALLOCATED: 'PLVC Allocated',
	POLICY_LOGIN: 'Policy Login',
	POLICY_BOND_RECEIVED: 'Policy Bond Recieved',
	DUE_FOR_RENEWED: 'Due for Renewal',
	RENEWED: 'Renewed',
	POLICY_ISSUANCE: 'Policy Issuance',
	POLICY_BOND_PENDING: 'Policy Bond Pending',
	PROPOSAL_DETAILS_REVIEW: 'Proposal Detail Review',
	PAYMENT: 'Payment',
	INSURER_SELECTION: 'Insurer Selection',
	INSURANCE_LEAD_GENERATION: 'Lead Generation',
};

export const disbursementType = {
	PARTIAL: 'PARTIAL',
	FULL: 'FULL',
};

export const lenderApplicationType = {
	ONLINE: 'Online',
	OFFLINE: 'Offline',
};

export const BUREAU_SCORE_TABS = {
	EQUIFAX: 'Equifax',
	CIBIL: 'CIBIL',
};

export const CREDIT_SCORE_IMPACTS = {
	HIGH_IMPACT: 'High Impact',
	MEDIUM_IMPACT: 'Medium Impact',
	LOW_IMPACT: 'Low Impact',
};

export const CREDIT_SCORE_SCALE_RATING = {
	EXCELLENT: 'excellent',
	GOOD: 'good',
	AVERAGE: 'average',
	POOR: 'poor',
	NH: 'nh',
};

export const PRODUCTS_TYPE = {
	LOAN: 'Loan',
	INSURANCE: 'Insurance',
};

export const PAGINATION_DETAILS = {
	PAGE_SIZE: 10,
	CURRENT_PAGE: 1,
};

export const REGEX = {
	INDIAN_MOBILE_NUMBERS: /^[6-9]{1}[0-9]{9}$/,
	PAN: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
	IFSC: /^[A-Za-z]{4}[A-Za-z0-9]{7}$/,
	ALLOWONLYCHAR: /^[a-zA-Z]+$/i, // Allow Only Alphabets
	ACCOUNTNO: /^[a-zA-Z0-9]+$/,
	EMAIL:
		/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-zA-Z]{2,6}(?:\.[a-zA-Z]{2})?)+$/,
	AADHAAR: /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
	// FULLNAME: /^[a-zA-Z]/,
	FULLNAME: /^[a-zA-Z ]*$/,
	FULLNAME_WITH_DOT: /^[a-zA-Z .]*$/,
	GST: /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z][zZ][0-9a-zA-Z]$/,
	UPPERCASE: /^[A-Z \d\W]+$/,
	ALLOWCHARANDNUMBER: /^([a-zA-Z0-9*)\s+([a-zA-Z0-9 ]*)$/,
	PINCODE: /^[0-9]{6}$/,
	ALLOWNUMBERS: /^\d+$/,
	GREATER_THAN_ZERO: /^[1-9]\d*$/,
	DECIMAL_VALUE: /^-?\d*\.?\d*$/,
	ROI_IRR: /^(?:\d{0,2}(\.\d{1,2})?)$/,
	STABILITY: /^\d{1,3}$/
};
export const TEAMS_TABS = {
	DMA: 'DMA',
	ANDROTEAM: 'ANDROTEAM',
};
export const TIMEOUTMINS = 9;
export const OTPTIMER = 60;
export const FILE_FORMAT_VALIDATON_MESSAGE = 'Please upload a csv file only!';

export const StatusNum = {
	INIT: '1',
	CL1: '2',
	CL2: '3',
	CL3: '4',
	KYC: '10',
	KYC1: '11',
	KYC2: '12',
	KYC3: '13',
	KYC4: '14',
	PAYOUT: '20',
	PAYOUTAPPROVAL: '21',
	PAYOUTRECOMENDED: '22',
	REVISED: '23',
	PAYOUTAPPROVED: '24',
	PARENTCHILD: '30',
	VERIFICATION: '40',
	DMAULACTIVE: '120',
	REWORK: '41',
	REWORKCOMPLETED: '42',
	VERIFICATION1: '43',
	VERIFICATION2: '44',
	REWORKVERIFICATION1: '46',
	REWORKVERIFICATION2: '47',
	APPROVED: '45',
	EXISTINGAPPROVAL: '60',
	REVISEDAPPROVAL: '61',
	EXISTINGAPPROVAL2: '65',
	REVISEDAPPROVAL2: '66',
	INVITED: '70',
	ACTIVE: '80',
	DEACTIVATE: '90',
	NEWAPPROVAL: '50',
	REJECTED: '91',
	UNDERPROCESS: '100',
	UNDERPROCESSCT: '101',
	ALLOCATOR: '110',
};

export const USER_ROLES = {
	USER: 'user',
	ADMIN: 'admin',
	BUHOD: 'BUHOD',
	BUH: 'BUH',
	BUSINESSOPS: 'BOPS',
	BM: 'BM',
	CENTRALOPS: 'COPS',
	CENTRALOPSJR: 'COPSJR',
	TC: 'TC',
	DMA: 'DMA',
	DMAUL: 'DMAUL',
};

export const LeadStatus = {
	GENERATED: '1',
	BUREAU: '10',
	PROPOSERDETAIL: '21',
	BASICDETAILSOFLIFEASSURED: '22',
	OCCUPATIONDETAILSOFASSURED: '23',
	DOCUMENTUPLOADED: '24',
	MOBILEVERIFICATION: '25',
	PROCESSING: '30',
	SELECTION: '40',
	CLOSED: '90',
	LOGIN: '50',
	SANCTIONED: '51',
	DISBURSED: '52',
	UNDERPROCESS: '60',
	PARTIALDISBURSED: '54',
	CALLBACKFOLLOWUP: '4',
	CALLBACKUPDATESTAGE: '91',
	APPLICATIONSFULFILLED: '70',
	RAW: '0',
	ALLOCATOR: '110',
};

export const LeadSourceEnum = {
	MANUAL: '1',
	BULK: '2',
	CAMPAIGN: '3',
};

export const destinationFields = {
	Statement: 'Statement',
	Aggregator: 'Aggregator',
	GST: 'GST',
	Net_Banking_Fetch: 'Net Banking Fetch',
};

export const Lead_name = {
	MANAGER: '406',
};
export const LEADS_TYPE = {
	LOAN: 'Loan',
	LOANS: 'Loans',
	INSURANCE: 'Insurance',
	Cards: 'Cards',
	REAL_ESTATE: 'Real Estate',
};

//Professional Info Fields Amount Keys
export const amountKeys = [
	'monthly_income',
	'annual_income',
	'net_profit',
	'turnover',
	'co_applicant_monthly_income',
	'net_monthly_income',
	'market_value',
	'annual_turnover',
	'agreement_value',
	'existing_loan_outstanding',
	'annualIncome',
	'monthlyIncome',
	'requestedAmount',
	'emiAmount',
	'propertyValue',
	'monthlyIncentive',
	'monthlyNetIncome',
	'monthlyObligations',
	'monthSalaryDetails_salary',
];

export const professionalEquifaxKeys = [
	'total_emi_amount',
	'credit_card_outstanding_amount',
];

export const INAPPCONFIG = 'hidenavigationbuttons=yes,hideurlbar=yes';

export const get = apiService.GET;

export const post = apiService.POST;

export const put = apiService.PUT;

export const LEAD_SCORE_TYPE = {
	CBIL: 'Cibil',
	EQUIFAX: 'Equifax',
	BOTH: 'Both',
};

export const THEME_COLOR = {
	'#FFFFFF': '#F3F3F3',
	'#A1C0EF': '#D4E2F8',
	'#D0E9C8': '#E7FCE5',
	'#FFE0A7': '#FFF4DB',
	'#C0DBD5': '#DEFFF8',
};

export const ACLKeys = {
	CHECK_BUREAU: 1,
	SHARE_CHECK_BUREAU: 2,
	ADD_NEW_LEAD: 3,
	SHARE_LEAD: 4,
	RAW_BULK_UPLOAD: 5,
	DISBURSAL_BULK_UPLOAD: 6,
	CREATE_EMPLOYEE: 7,
	EXPORT_REPORT: 8,
	CREATE_DMA: 9,
	INVITE_DMA: 10,
};

export const gradientArray = [
	'linear-gradient(180deg, rgba(222,242,219,1) 0%, rgba(255,255,255,0.9990589985994398) 100%)',
	'linear-gradient(180deg, rgba(251,233,196,1) 0%, rgba(255,255,255,0.9990589985994398) 100%)',
	'linear-gradient(180deg, rgba(208,223,247,1) 0%, rgba(255,255,255,0.9990589985994398) 100%)',
	'linear-gradient(180deg, rgba(250,194,194,1) 0%, rgba(255,255,255,0.9990589985994398) 100%)',
];

export const generatEncryptedEmail = (email) => {
	const visiblePart = email.substring(0, Math.min(2, email.indexOf('@')));
	const maskedPart = email.indexOf('@') > 0 ? '*'.repeat(4) : '';
	const maskedEmail =
		visiblePart + maskedPart + email.substring(email.indexOf('@'));
	return maskedEmail;
};

export const USER_ROLES_IDS = {
	BUH: '1',
	BUHOD: '2',
	BM: '3',
	TC: '4',
	BOPS: '5',
	COPS: '6',
	DMA: '7',
	DMAUL: '8',
	BTM: '9',
	COPSJR: '10',
	ASSIST_TO: '11',
};

export const lenderSpecificLinks = {
	ABFL_PL:
		// 'https://oneapp.abfldirect.com/esb/login?dsa_hash=f4f648ab7ce0ac9913236711bd00f4a48612db3467429160d160a8b91857f5f9',
		'https://oneapp.abfldirect.com/esb/login?dsa_hash=66d2e2d20b5774013f183387ffb3e56002df194317fca0ef181f29e6d1f02b68',

	BHANIX_PL:
		'https://web.cashe.co.in/VjcvdkZ0b0ZFVDZDMlFGOE1LQzRmTlo0WmRDRUpDdHErcm5IUytZWWgwVmd5a1RxQ25tR0hqOHlHVVlBOVFzZGlBTjVhYUZPZDFlelltVzR3bkRDRVk1UUZtMCtzVGtWbFVDK1pHZXNWSzlTZU1iMnN4S0JxbWc5aFJ6YmZaL1o=',
	FIBE_PL: 'https://bit.ly/3UT1heN',
	INCRED_PL:
		'https://play.google.com/store/apps/details?id=com.incred.customer',
};

// export const GA4_TRACKING_ID = 'G-QHW4J11EB0'; // your Measurement ID G-BGGG70XK9G
// export const GA4_TRACKING_ID = 'G-BGGG70XK9G'; // your Measurement ID G-LSWWDB48LS
export const GA4_TRACKING_ID = 'G-13FENN5F38';
