import React, { ReactNode } from 'react';
import { ConsentBusyLoadingProvider } from '../../pages/insurance-consent-form/context/ConsentBusyLoadingContext';
import { ConsentAuthProvider } from '../../pages/insurance-consent-form/context/ConsentAuthContext';
import { ConsentBusyLoader } from '../../pages/insurance-consent-form/component/loader/ConsentBusyLoader';

interface ConsentAppProvidersProps {
	children: ReactNode;
}

export const ConsentAppProvider: React.FC<ConsentAppProvidersProps> = ({
	children,
}) => {
	return (
		<ConsentBusyLoadingProvider>
			<ConsentBusyLoader />
			<ConsentAuthProvider>
                {children}
            </ConsentAuthProvider>
		</ConsentBusyLoadingProvider>
	);
};
