import { IonContent, IonFooter } from '@ionic/react';
import { useState } from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	lenderSpfDetailsFlow,
	abflRegulatory,
} from '../../../store/reducers/lead/leadSlice';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { CustomCheckbox } from '../../../components/common/custom-checkbox/CustomCheckbox';
import { CustomModalLeadStatus } from '../../../components/common';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans } from 'react-i18next';

export const ABFLTermsAndCondition: React.FC<any> = () => {
	const validationSchema: any = yup.object().shape({
		tnc_1: yup.boolean().oneOf([true], 'You need to check the consent'),
		tnc_2: yup.boolean().oneOf([true], 'You need to check the consent'),
		tnc_4: yup.boolean().oneOf([true], 'You need to check the consent'),
	});

	const [leadStatusModalOpen, setLeadStatusModalOpen] = useState(false);
	const [applicationNo] = useState(null);
	const dispatch = useDispatch();
	const lenderSpfDetails = useSelector(
		(state: any) => state?.lead?.lenderSpecificDetails
	);
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	// const leadLender = useSelector((state: any) => state?.lead);
	const [timeStamps, setTimeStamps] = useState<any>({
		tnc_1: '',
		tnc_2: '',
		tnc_4: '',
	});

	console.log(timeStamps);

	const {
		control,
		getValues,
		setValue,
		formState: { isValid, errors },
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			tnc_1: false,
			tnc_2: false,
			tnc_4: false,
		},
	});

	/**
	 * On close of lead status modal redirect to external URL
	 */
	const onLeadStatusModalClose = () => {
		setLeadStatusModalOpen(false);
	};

	const onSubmit = async () => {
		const req: any = {
			bureau_and_kyc: timeStamps?.tnc_1,
			mfi: timeStamps?.tnc_2,
			privacy_policy: timeStamps?.tnc_4,
			lead_id: leadInfo?.leadId,
		};

		await dispatch(abflRegulatory({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				console.log(result);

				if (result?.status) {
					dispatch(
						lenderSpfDetailsFlow({
							...lenderSpfDetails,
							step: 'step2',
						})
					);
				}
			});
	};

	const content: any = {
		tnc_1: `I hereby authorize Aditya Birla Finance Limited to collect,store, verify my credit bureau report and KYC details (from CERSAI/Digilocker), and contact me through SMS/Whatsapp/call with reference to my loan application.`,
		tnc_2: `I understand and agree that Lenders doesn't grant any microfinance loans and further, I hereby confirm that my annual household income is more than 3 lakh,        `,
		tnc_3: `"Annual household income refers to the yearly income of the entire household`,
	};

	const timestampHandler = (name: any) => {
		setTimeStamps((prev: any) => ({ ...prev, [name]: new Date().getTime() }));
	};

	return (
		<>
			{
				<>
					<IonContent>
						<div className='select-product small-container bg-web pt-4'>
							<div className=' bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
								<h2 className='pb-3 fw-bold'>Terms and Condition</h2>

								<form className='mb-4'>
									<div className='position-relative mt-2 mt-md-0'>
										<Controller
											control={control}
											render={({ field: { onChange, onBlur, name } }) => (
												<div
													className='d-flex py-3'
													style={{ background: '#EDF8EC' }}
												>
													<div className='position-relative'>
														<CustomCheckbox
															labelPlacement='end'
															name={name}
															isBackGround={true}
															// isChecked={confirmCheck}
															isChecked={getValues('tnc_1')}
															value={getValues('tnc_1')}
															onBlur={onBlur}
															onChange={(event: any) => {
																onChange(event);
																setValue('tnc_1', event.detail.checked);
																timestampHandler('tnc_1');
															}}
															className={
																(errors[name] ? 'ion-checkbox-inValid' : '') +
																' pt-1 px-3'
															}
															errorText={t(errors['tnc_1']?.message as string)}
															errorTextClass='position-absolute checkbox-style1'
														/>
													</div>
													<div className={`pe-3 h-auto`}>
														<Trans
															i18nKey={'abflconsent1'}
															defaults={content?.tnc_1}
															values={{ what: 'world' }}
															components={{ a: <a />, br: <br /> }}
														/>
													</div>
												</div>
											)}
											name={'tnc_1'}
										/>
									</div>
									<div className='position-relative mt-2 mt-md-0'>
										<Controller
											control={control}
											render={({ field: { onChange, onBlur, name } }) => (
												<div
													className='d-flex py-3'
													style={{ background: '#EDF8EC' }}
												>
													<div className='position-relative'>
														<CustomCheckbox
															labelPlacement='end'
															name={name}
															isBackGround={true}
															// isChecked={confirmCheck}
															isChecked={getValues('tnc_2')}
															value={getValues('tnc_2')}
															onBlur={onBlur}
															onChange={(event: any) => {
																onChange(event);
																setValue('tnc_2', event.detail.checked);
																timestampHandler('tnc_2');
															}}
															className={
																(errors[name] ? 'ion-checkbox-inValid' : '') +
																' pt-1 px-3'
															}
															errorText={t(errors['tnc_2']?.message as string)}
															errorTextClass='position-absolute checkbox-style1'
														/>
													</div>
													<div className={`pe-3 h-auto`}>
														<Trans
															i18nKey={'abflconsent1'}
															defaults={content?.tnc_2}
															values={{ what: 'world' }}
															components={{ a: <a />, br: <br /> }}
														/>
													</div>
												</div>
											)}
											name={'tnc_2'}
										/>
									</div>
									<div className='position-relative mt-2 mt-md-0'>
										<div
											className='d-flex pb-3'
											style={{ background: '#EDF8EC' }}
										>
											<div className={`ps-5 pe-3 h-auto`}>
												<Trans
													i18nKey={'abflconsent1'}
													defaults={content?.tnc_3}
													values={{ what: 'world' }}
													components={{ a: <a />, br: <br /> }}
												/>
											</div>
										</div>
									</div>
									<div className='position-relative mt-2 mt-md-0'>
										<Controller
											control={control}
											render={({ field: { onChange, onBlur, name } }) => (
												<div
													className='d-flex py-3'
													style={{ background: '#EDF8EC' }}
												>
													<div className='position-relative'>
														<CustomCheckbox
															labelPlacement='end'
															name={name}
															isBackGround={true}
															// isChecked={confirmCheck}
															isChecked={getValues('tnc_4')}
															value={getValues('tnc_4')}
															onBlur={onBlur}
															onChange={(event: any) => {
																onChange(event);
																setValue('tnc_4', event.detail.checked);
																timestampHandler('tnc_4');
															}}
															className={
																(errors[name] ? 'ion-checkbox-inValid' : '') +
																' pt-1 px-3'
															}
															errorText={t(errors['tnc_4']?.message as string)}
															errorTextClass='position-absolute checkbox-style1'
														/>
													</div>
													<div className={`pe-3 h-auto`}>
														<span>
															I accept ABFL Privacy policy (
															<span
																className='text-primary cursor-pointer'
																onClick={() => {
																	window.open(
																		'https://finance.adityabirlacapital.com/privacy-policy'
																	);
																}}
															>
																finance.adityabirlacapital.com/privacy-policy{' '}
															</span>
															)
														</span>
													</div>
												</div>
											)}
											name={'tnc_4'}
										/>
									</div>
								</form>
								<div className={` d-none d-md-block`}>
									<CustomButton
										type='submit'
										className='w-100 fw-bold dashboard-add-lead-button'
										expand='block'
										title={t('Proceed')}
										disabled={!isValid}
										onClick={onSubmit}
									/>
								</div>
							</div>
						</div>
					</IonContent>

					{leadStatusModalOpen && (
						<CustomModalLeadStatus
							isOpen={leadStatusModalOpen}
							applicationNo={applicationNo}
							handleClose={() => onLeadStatusModalClose()}
						/>
					)}

					<IonFooter className='d-block d-md-none position-relative'>
						<div
							style={{
								backgroundColor: '#fff',
								zIndex: 2,
							}}
						>
							<div className={`ion-padding`}>
								<div className='proceed-btn'>
									<CustomButton
										type='submit'
										className='w-100 fw-bold dashboard-add-lead-button'
										expand='block'
										title={t('Proceed')}
										disabled={!isValid}
										onClick={onSubmit}
									/>
								</div>
							</div>
						</div>
					</IonFooter>
				</>
			}
		</>
	);
};
