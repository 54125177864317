import { useEffect, useState } from 'react';
import './TeamsDma.scss';
import { IonCol, IonContent, IonGrid, IonRow, isPlatform } from '@ionic/react';
import { useHistory } from 'react-router';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { dmaFlowData, teamsPage } from '../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { StatusNum } from '../../../helpers/constant';
import { Sidebar } from '../../../components/layout/sidebar/Sidebar';
import { eventAttributes, notifyHandler } from '../../../helpers/functions';
import { NotifyVisitors } from 'capacitor-plugin-notifyvisitors';

export default function TeamsDma({ setTeamsVideo }: any) {
	const history = useHistory();
	const dispatch: any = useDispatch();
	const { t } = useTranslation();
	const [dmaStatusCount, setDmaStatusCount] = useState<any>([]);
	const dmaSelector = useSelector((state: any) => state?.users?.dmaFlow);

	// Notify Visitors
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const userName = useSelector(
		(state: any) => state?.users?.homepage?.profile?.name
	);
	const userMobile = useSelector(
		(state: any) => state?.users?.user_details?.mobile
	);
	const entityType = useSelector(
		(state: any) => state?.users?.user_details?.entityType
	);

	const userLang = useSelector(
		(state: any) => state?.oneTimeScreen?.language?.name
	);

	const userAttributes = {
		attributes: {
			name: userName,
			id: userId,
			mobile: userMobile,
			user_language: userLang,
			entityType:
				entityType === 'Individual'
					? 'DMA'
					: entityType === 'BTM'
					? 'employee'
					: entityType,
		},
	};
	useEffect(() => {
		if (isPlatform('android') || isPlatform('ios')) {
			notifyHandler('event', eventAttributes('Invite_employee'));
			notifyHandler('event', eventAttributes(' DMA_page'));
			notifyHandler('user', userAttributes.attributes);
			const inAppData = {
				tokens: { name: userAttributes },
				customRules: { banerrType: 'invite employee' && 'dma page' },
			};
			NotifyVisitors.show(inAppData);
		} else {
			(window as any).nv('event', 'Invite_employee', 10, 1);
			(window as any).nv('event', ' DMA_page', userAttributes, 10, 1);
			(window as any).nv('user', userId, userAttributes.attributes);
		}
	}, []);

	useEffect(() => {
		getUsersCount();
	}, []);

	const getUsersCount = async () => {
		await dispatch(teamsPage())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setDmaStatusCount(result?.dma?.[0]);
					setTeamsVideo(result?.video_link);
				}
			});
	};

	return (
		<>
			<div className='d-flex'>
				<Sidebar />
				<IonContent className='dashboard-main dashboard-height'>
					<div className='w-100 h-100 pt-md-3 bg-web dashboard-container-spacing'>
						<div
							className='bg-web-white position-relative'
							style={{ height: '96%' }}
						>
							<IonGrid>
								<IonRow>
									<IonCol size='6'>
										<div
											onClick={async () => {
												await dispatch(
													dmaFlowData({
														...dmaSelector,
														dmaCode: StatusNum.DMAULACTIVE,
														title: 'My Team',
														update: dmaSelector?.update + 1 || 1,
													})
												);
												history.push('/team-dma-list');
											}}
											className='boxes cursor-pointer border border-1'
											style={{
												background:
													'transparent linear-gradient(219deg, rgba(1, 101, 225, 0.2588235294) 0%, #ffffff 35%) 0% 0% no-repeat padding-box',
												borderRadius: '8px',
											}}
										>
											<div className='d-flex justify-content-between'>
												<span className='fs-24 fw-700'>
													{dmaStatusCount?.total_myteam}
												</span>
												<img
													src='/images/icons/teams/my-andro-team.svg'
													alt='myteam'
												/>
											</div>
											<p className='fs-14'>{t('My Team')}</p>
										</div>
									</IonCol>
									<IonCol size='6'>
										<div
											onClick={async () => {
												await dispatch(
													dmaFlowData({
														...dmaSelector,
														dmaCode: StatusNum.INVITED,
														title: t('Invitees'),
														update: dmaSelector?.update + 1 || 1,
													})
												);
												history.push('/invitee-list');
											}}
											className='boxes cursor-pointer border border-1 h-100'
											style={{
												background:
													'transparent linear-gradient(219deg, rgba(1, 101, 225, 0.2588235294) 0%, #ffffff 35%) 0% 0% no-repeat padding-box',
												borderRadius: '8px',
											}}
										>
											<div className='d-flex justify-content-between'>
												<span className='fs-24 fw-700'>
													{dmaStatusCount?.total_invitees}
												</span>
												<img
													src='/images/icons/teams/invitees.svg'
													alt='myteam'
												/>
											</div>
											<p className='fs-14'>{t('Invitees')}</p>
										</div>
									</IonCol>
								</IonRow>
							</IonGrid>
							<div className='bottom-0 w-100 start-0 px-3 pb-3 dma-invite-button'>
								<div className='d-flex justify-content-between gap-3 '>
									<CustomButton
										title={t('Invite Team Member')}
										onClick={() => history.push('/teams-invite-dma-member')}
										className='w-100'
										style={{ '--border-radius': '22px' }}
									/>
								</div>
							</div>
						</div>
					</div>
				</IonContent>
			</div>
			{/* <IonFooter>
				<div className=' p-3 bg-white d-block d-md-none'>
					<div className='d-flex justify-content-between gap-3 '>
						<CustomButton
							title={t('Invite Team Member')}
							onClick={() => history.push('/teams-invite-dma-member')}
							className='w-100'
							style={{ '--border-radius': '22px' }}
						/>
					</div>
				</div>
			</IonFooter> */}
			{/* </div> */}
		</>
	);
}
