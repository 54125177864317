/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	IonContent,
	IonCardHeader,
	IonCardTitle,
	IonCardSubtitle,
	IonCardContent,
	IonPage,
	IonCard,
	IonLabel,
	IonItem,
	IonList,
	IonTextarea,
	useIonRouter,
	isPlatform,
	IonButtons,
	IonImg,
	IonSpinner,
	// IonIcon,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Header } from '../../components/layout/header/Header';
import './LeadDetails.scss';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import {
	DownloadFileBlob,
	DownloadFileInApp,
	FormatDate,
	InputFormatCurrencyValue,
	findCurrentStatus,
	sanitizeHtml,
	// getStatus,
	showToastAlert,
} from '../../helpers/functions';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
	LEAD_SCORE_TYPE,
	LEAD_STATUS,
	LeadStatus,
	StatusNum,
} from '../../helpers/constant';
// import { findStatus } from './../../helpers/functions';
import { findSource } from '../../helpers/functions';
import { InitialLetter } from '../../components/common/initial-letter/InitialLetter';
import { useDispatch, useSelector } from 'react-redux';
import {
	// activityInfo,
	addLeadComments,
	addMoreLenders,
	applicationStatusCheck,
	cbilRequestConsentDetails,
	dashboardLeadActivity,
	dashboardLeadDetails,
	downloadEquifaxBureauReport,
	fetchBureauCustomerReport,
	fetchLeadStatusCheck,
	getApplicationStatus,
	getLeadUmDetails,
	getSelectedLendersList,
	leadBureauCustomer,
	leadCallInitiated,
	leadFlow,
	lenderFetchSession,
	moneytapLenderLink,
	selectedLender,
	viewLeadComments,
} from '../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { Divider } from '../../components/common/divider/Divider';
import { ActivitySection } from '../teams/ActivitySection';
import { LeadApplications } from './LeadApplications';
import CustomAllocateManagers from '../../components/common/CustomAllocateManagers';
import { generateCibilOtp } from '../../store/reducers/user/userSlice';
import { LocalNotifications } from '@capacitor/local-notifications';
import { FileOpener } from '@capacitor-community/file-opener';
import { PushNotifications } from '@capacitor/push-notifications';
import {
	AndroidSettings,
	IOSSettings,
	NativeSettings,
} from 'capacitor-native-settings';
import {
	// LenderNameForAPICall,
	SessionApiLendersList,
} from '../../helpers/lenders';
// import { Clipboard } from '@capacitor/clipboard';

export const LeadDetails: React.FC = () => {
	const history: any = useHistory();
	const router = useIonRouter();
	const { t } = useTranslation();
	const [isButtonNeeded, setIsButtonNeeded] = useState(false);
	const [shareModalOpen, setShareModalOpen] = useState(false);
	const [leadInfoModalOpen, setLeadInfoModalOpen] = useState(false);
	const [assignModalOpen, setAssignModalOpen] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [dispution, setDispution] = useState(false);
	const [infoActivity, setInfoActivity] = useState<any>([]);
	const [comment, setComment] = useState('');
	const [commentList, setCommentList] = useState<any>([]);
	const [updateApi, setUpdateApi] = useState(1);
	const { is_dialler, userId } = useSelector(
		(state: any) => state?.users?.user_details
	);
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	const leadStatus = useSelector(
		(state: any) => state?.lead?.leadFlowData?.leadStatus
	);
	const getFetchBureauCustomerReportData = useSelector(
		(state: any) => state?.lead?.fetchBureauCustomerReportData
	);
	const [umData, setUmData] = useState<any>('');
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	const [applicationStatusData, setApplicationStatusData] = useState<any>([]);
	const [selectApplicationStatusValue, setApplicationStatusValue] =
		useState<any>(
			findCurrentStatus(leadInfo?.isPartial ? leadInfo?.isPartial : leadStatus)
				.id
		);
	const isBrowser = !isPlatform('capacitor');
	const onlineLenders = [
		'incred financial services ltd.',
		'lendingkart finance ltd.',
		'tata capital',
		'kotak mahindra bank limited',
		'bajaj finance prime',
		'bajaj finance growth',
		'moneyview',
		'bhanix finance',
		'aditya birla finance ltd.',
		'dmi housing',
		'pnb housing finance limited',
		'indifi',
	];

	const [lead, setLead] = useState<any>([]);
	const [bankDetails, setBankDetails] = useState<any>([]);
	const [showAllocateManagerModal, setShowAllocateManagerModal] =
		useState<boolean>(false);
	const location = useLocation<any>();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [getIdFromUrl, setGetIdFromUrl] = useState(
		location?.pathname?.split('/')[2] || ''
	);
	const [showNotificationModel, setShowNitificationModel] = useState(false);
	const [lenderUiUrl, setLenderUiUrl] = useState<any>('');
	const [lenderOffer, setLenderOffer] = useState<any>({});
	const [pendingStepUrl, setPendingStepsUrl] = useState<any>('');
	// const [isCopied, setCopied] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [getTokenFromUrl, setGetTokenFromUrl] = useState(
		location?.pathname?.split('/')[3] || ''
	);
	const dispatch = useDispatch();

	const registerNotifications = async () => {
		const permStatus = await PushNotifications.checkPermissions();

		if (permStatus.receive == 'denied') {
			setShowNitificationModel(true);
			setSpinner(false);
		} else if (permStatus.receive == 'prompt-with-rationale') {
			setShowNitificationModel(true);
			setSpinner(false);
		} else {
			setShowNitificationModel(false);
		}
	};

	useEffect(() => {
		if (!location.pathname.includes('/lead-details')) {
			return;
		}

		setLenderUiUrl('');
	}, [location.pathname]);

	useEffect(() => {
		if (!location.pathname.includes('/lead-details')) {
			return;
		}
		if (
			leadInfo?.leadStatus == 'Login' ||
			leadInfo?.leadStatus == 'Sanctioned'
		) {
			const data = {
				lender_name: leadInfo?.lender_name,
				lead_spf_detail_id: '',
				lead_application_id: leadInfo?.lead_app_id,
				product_category_id: leadInfo?.selectedProductData?.product_category_id,
			};

			// if (leadInfo?.lender_name.toLowerCase() == 'tata capital') {
			// 	if (
			// 		leadInfo.product_category_id == 3 ||
			// 		leadInfo.product_category_id == 4
			// 	) {
			// 		data.lender_name = LenderNameForAPICall.TATA_HL_LAP;
			// 	} else if (leadInfo.product_category_id == 2) {
			// 		data.lender_name = LenderNameForAPICall.TATA_BL;
			// 	}
			// }
			// if (leadInfo?.lender_name.toLowerCase() == 'aditya birla finance ltd.') {
			// 	if (leadInfo.product_category_id == 1) {
			// 		data.lender_name = LenderNameForAPICall.ABFL_PL;
			// 	} else if (leadInfo.product_category_id == 2) {
			// 		data.lender_name = LenderNameForAPICall.ABFL;
			// 	}
			// }
			if (
				onlineLenders.includes(leadInfo?.lender_name.toLowerCase()) &&
				leadInfo?.bank_app_no != null
			) {
				if (leadInfo?.lender_name.toLowerCase() == 'moneyview') {
					dispatch(
						applicationStatusCheck({
							// param: `${leadInfo?.leadId || leadInfo?.id}${
							// 	leadInfo?.lead_app_id
							// 		? '?lead_application_id=' + leadInfo?.lead_app_id
							// 		: ''
							// }`,
							param: leadInfo?.lead_app_id ? `/${leadInfo.lead_app_id}` : '',
						})
					);
				} else {
					fetchLenderLeadStatus(data);
				}
			}
		}
	}, [leadInfo]);

	const fetchLenderLeadStatus = async (params: any) => {
		await dispatch(fetchLeadStatusCheck({ data: params }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					if (
						params.lender_name?.toLowerCase()?.includes('indifi') &&
						params.product_category_id == '2'
					) {
						if (result?.data?.data) {
							setLenderUiUrl(result.data.data.redirectUrl);
							setLenderOffer(result.data.data?.highest_offer || {});
						}
					}
				}
			});
	};

	useEffect(() => {
		if (!location.pathname.includes('/lead-details')) {
			return;
		}
		if (leadInfo?.leadId) {
			getLeads();
			getComment();
			// getLeads();

			// getComment();

			// setbankType(true);

			getApplicationStatusList();
			setComment('');
			getActivity();
			setInfoActivity([]);
		} else {
			history.replace('/dashboard/lead');
		}
	}, [leadInfo?.update, location.pathname]);

	useEffect(() => {
		if (!location.pathname.includes('/lead-details')) {
			return;
		}

		setApplicationStatusValue(
			findCurrentStatus(
				leadInfo?.showDisbursed
					? leadInfo?.applicationStatus
					: leadInfo?.isPartial
					? leadInfo?.isPartial
					: leadStatus === LEAD_STATUS.ALL
					? lead?.lead_status
					: leadStatus
			)
		);
		if (
			[
				LEAD_STATUS.FULFILLMENT,
				LEAD_STATUS.LOGIN,
				LEAD_STATUS.SANCTIONED,
				LEAD_STATUS.DISBURSED,
				LEAD_STATUS.PARTIALDISBURSED,
				LEAD_STATUS.CLOSED,
				LEAD_STATUS.ALL,
			].includes(findCurrentStatus(leadInfo?.applicationStatus).id)
		) {
			dispatch(
				getSelectedLendersList({
					param:
						'/' +
						leadInfo?.leadId +
						`?status=${
							findCurrentStatus(
								leadInfo?.showDisbursed
									? leadInfo?.applicationStatus
									: leadInfo?.isPartial
									? leadInfo?.isPartial
									: leadStatus === LEAD_STATUS.ALL
									? lead?.lead_status
									: leadStatus
							).id
						}`, //7709
				})
			)
				.then(unwrapResult)

				.then((response: any) => {
					if (response?.status) {
						setBankDetails(response?.data);
						const filterLead = response?.data.filter((item: any) =>
							SessionApiLendersList.includes(item?.lender_name?.toLowerCase())
						);

						const filteredData = response.data.filter(
							(item: any) =>
								item?.lender_name.toLocaleLowerCase() === 'moneytap'
						);
						if (filteredData?.length) {
							getMoneytapUrlLink(leadInfo?.lead_app_id);
						}

						if (
							filterLead &&
							filterLead[0]?.bank_app_no &&
							[
								LeadStatus.LOGIN,
								LeadStatus.SANCTIONED,
								LeadStatus.DISBURSED,
								LeadStatus.PARTIALDISBURSED,
							].includes(filterLead[0]?.status)
						) {
							lenderSessionGet({
								lender_name: filterLead[0]?.lender_name,
								bank_app_no: filterLead[0]?.bank_app_no,
								product_category_id: filterLead[0]?.product_category_id,
							});
						}
					}
				});
		}
	}, [lead?.lead_status, location.pathname]);

	const getApplicationStatusList = () => {
		dispatch(getApplicationStatus())
			.then(unwrapResult)

			.then((response: any) => {
				if (response?.status) {
					setApplicationStatusData(response?.data);
				}
			});
	};

	const getMoneytapUrlLink = async (item: any) => {
		const req: any = {
			lead_application_id: item,
		};
		await dispatch(moneytapLenderLink({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result) {
					setPendingStepsUrl(result?.data?.pendingStepsUrl);
				}
			});
	};

	const lenderSessionGet = async (item: any) => {
		const req: any = {
			lender_name: item.lender_name,
			customerID: item.bank_app_no,
			product_category_id: item.product_category_id,
			redirectURL: `${window.location.origin}/dashboard/lead`,
		};

		await dispatch(lenderFetchSession({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					if (result.data?.data) {
						const { data } = result.data;
						setLenderUiUrl(data.url);
					} else {
						setLenderUiUrl(result?.data?.url);
					}
				}
			});
	};

	/**
	 * @param text value
	 */
	// const copyText = async (text: any) => {
	// 	await Clipboard.write({
	// 		string: text,
	// 	});

	// 	setCopied(true);
	// 	const clearTime = setTimeout(() => {
	// 		setCopied(false);
	// 		clearTimeout(clearTime);
	// 	}, 3000);
	// };
	const selectedApplicationStatus = (event: any) => {
		const getStatusData = applicationStatusData.find(
			(item: any) => event.detail.value === item.id
		);

		setApplicationStatusValue(getStatusData);

		dispatch(
			getSelectedLendersList({
				param: '/' + leadInfo?.leadId + `?status=${event.detail.value}`, //7709
			})
		)
			.then(unwrapResult)

			.then((response: any) => {
				if (response?.status) {
					setBankDetails(response?.data);
				}
			});
	};

	const getLeads = async () => {
		await dispatch(
			dashboardLeadDetails({
				param: `${leadInfo?.leadId || leadInfo?.id}${
					leadInfo?.lead_app_id
						? '?lead_application_id=' + leadInfo?.lead_app_id
						: ''
				}`,
			})
		)
			.then(unwrapResult)
			.then(async (response: any) => {
				if (response?.status) {
					setLead(response?.leads);
					dispatch(
						fetchBureauCustomerReport({
							...getFetchBureauCustomerReportData,
							lead_type: 'Lead',
							lead_id: leadInfo?.leadId,
							lead_status: findCurrentStatus(response?.lead_status).label,
							mobile_no: response?.leads?.mobile_no,
							id: leadInfo?.leadId,
						})
					);
					let status = '';
					if (leadInfo?.from === 'nimbuss') {
						status = findCurrentStatus(response?.leads?.lead_status).label;
					} else {
						status = leadInfo?.leadStatus;
					}

					dispatch(
						leadFlow({
							...leadInfo,
							professional_details:
								response?.leads?.professional_details &&
								Object.keys(response?.leads?.professional_details)?.length > 0
									? response?.leads?.professional_details
									: {},
							leadId: response?.leads?.id,
							// leadStatus:
							// 	findCurrentStatus(response?.leads?.lead_status).id ||
							// 	leadInfo?.leadStatus,
							leadStatus: status,
							leadTypeId: leadInfo?.leadTypeId,
							selectedProductData: {
								product_category_id: response?.leads?.product_category_id,
							},
							applicationStatus: findCurrentStatus(
								leadInfo?.showDisbursed
									? leadInfo?.applicationStatus
									: leadInfo?.isPartial
									? leadInfo?.isPartial
									: leadStatus === LEAD_STATUS.ALL
									? lead?.lead_status || LEAD_STATUS.ALL
									: leadStatus
							).id,
							...response.leads,
						})
					);
				}
			});
		await dispatch(getLeadUmDetails({ param: leadInfo?.leadId }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setUmData(result?.data);
				}
			});
	};
	const getActivity = async () => {
		await dispatch(dashboardLeadActivity({ param: leadInfo?.leadId }))
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setInfoActivity(response?.activities);
				}
			});
	};
	const [spinner, setSpinner] = useState(false);

	useEffect(() => {
		LocalNotifications.addListener('localNotificationReceived', (data: any) => {
			if (data) {
				setSpinner(false);
			}
		});
		LocalNotifications.addListener(
			'localNotificationActionPerformed',
			(payload: any) => {
				const route = payload.notification.extra.route;
				FileOpener.open({
					filePath: route,
				});
			}
		);
		return () => {
			LocalNotifications.removeAllListeners();
		};
	}, []);
	const addComment = async () => {
		await dispatch(
			addLeadComments({
				param: leadInfo?.leadId,
				data: {
					comment: sanitizeHtml(comment),
				},
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setComment('');
					setUpdateApi(updateApi + 1);
					getComment();
				}
			});
	};

	const getComment = async () => {
		await dispatch(viewLeadComments({ param: leadInfo?.leadId }))
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setCommentList(response?.comment);
				}
			});
	};

	const ShareModal = () => {
		// const dispatch = useDispatch();

		const downloadBureauReport = async () => {
			await dispatch(
				downloadEquifaxBureauReport({
					param: `/${leadInfo?.leadId}${
						leadInfo?.from === 'customer' ? '&lead_type=customer' : ''
					}`,
				})
			)
				.then(unwrapResult)
				.then(async (response: any) => {
					if (response.status) {
						showToastAlert({ type: 'success', message: 'Download Initiated' });
						if (isPlatform('mobileweb')) {
							DownloadFileBlob(response.base64String, 'bureau_report', 'pdf');
						} else if (
							isPlatform('android') ||
							isPlatform('ios') ||
							isPlatform('ipad') ||
							isPlatform('iphone')
						) {
							registerNotifications();

							setSpinner(true);

							DownloadFileInApp(response.base64String, 'bureau_report', 'pdf');
						} else {
							DownloadFileBlob(response.base64String, 'bureau_report', 'pdf');
						}
					}
				});
		};

		return (
			<>
				<IonList>
					{/*<IonItem lines='full'>
						<IonLabel>
							{lead && (
								<a
									href={`mailto: rkamble8698@gmail.com`}
									className='ellipsis2 ps-4 text-decoration-none text-dark'
								>
									{t('Send Email')}
								</a>
							)}
						</IonLabel>
					</IonItem>
					<IonItem lines='full'>
						<IonLabel>
							<h2 className='ellipsis2 ps-4'>{t('Share Lead Profile')}</h2>
						</IonLabel>
							</IonItem>*/}
					{umData?.showdownload_report ? (
						<IonItem
							lines='full'
							disabled={
								[
									LeadStatus.RAW,
									LeadStatus.GENERATED,
									LeadStatus.BUREAU,
								].includes(leadInfo?.applicationStatus)
									? true
									: false
							}
						>
							<IonLabel>
								<h2
									onClick={() => {
										downloadBureauReport();
									}}
									className='ellipsis2 d-flex align-items-center darkest-grey cursor-pointer'
								>
									<img
										className='me-1'
										width='24'
										height='24'
										alt='report'
										src='images/icons/download-bureau-report-blue.svg'
									/>
									{t('Download Bureau Report')}
								</h2>
							</IonLabel>
						</IonItem>
					) : (
						''
					)}
				</IonList>
			</>
		);
	};

	const LeadInfoModal = () => {
		return (
			<IonPage className=''>
				<IonList className='ion-padding '>
					<IonItem
						className='ion-no-padding'
						lines='full'
					>
						<div className='d-flex flex-grow-1 justify-content-between'>
							<p className='mb-0 fs-14'>{t('Mobile')}</p>
							<p className='mb-0 fs-14'>{lead?.email}</p>
						</div>
					</IonItem>
					<IonItem
						className='ion-no-padding'
						lines='full'
					>
						<div className='d-flex flex-grow-1 justify-content-between'>
							<p className='mb-0 fs-14'>{t('PAN')}</p>
							<p className='mb-0 fs-14'>{lead?.doc_no}</p>
						</div>
					</IonItem>
					<div className='d-flex mt-3 justify-content-between align-items-center'>
						<h4 className='fw-bold fs-20'>{t('Basic Info')}</h4>
						<CustomButton
							onClick={(e: any) => {
								e.preventDefault();
								router.push('/loan-lead-info-steps', 'forward', 'push', {
									as: 'f',
								});

								setLeadInfoModalOpen(false);
							}}
							fill='outline'
							title={t('Edit')}
						/>
					</div>

					<IonItem
						className='ion-no-padding'
						lines='full'
					>
						<div className='d-flex flex-grow-1 justify-content-between'>
							<p className='mb-0 fs-14'>{t('Name As Per Aadhar')}</p>
							<p className='mb-0 fs-14'>{lead?.first_name}</p>
						</div>
					</IonItem>
					<IonItem
						className='ion-no-padding'
						lines='full'
					>
						<div className='d-flex flex-grow-1 justify-content-between'>
							<p className='mb-0 fs-14'>{t('Pincode')}</p>
							<p className='mb-0 fs-14'>{lead?.pincode}</p>
						</div>
					</IonItem>
					<IonItem
						className='ion-no-padding'
						lines='full'
					>
						<div className='d-flex flex-grow-1 justify-content-between'>
							<p className='mb-0 fs-14'>{t('Date of Birth')}</p>
							<p className='mb-0 fs-14'>{lead?.dob}</p>
						</div>
					</IonItem>
					<IonItem
						className='ion-no-padding'
						lines='full'
					>
						<div className='d-flex flex-grow-1 justify-content-between'>
							<p className='mb-0 fs-14'>{t('Loan Amonut')}</p>
							<p className='mb-0 fs-14'>
								{lead?.loan_amount ? lead?.loan_amount : 'NA'}
							</p>
						</div>
					</IonItem>
					<IonItem
						className='ion-no-padding'
						lines='full'
					>
						<div className='d-flex flex-grow-1 justify-content-between'>
							<p className='mb-0 fs-14'>{t('Selected Andro Manager')}</p>
							<p className='mb-0 fs-14'>{'Not available'}</p>
						</div>
					</IonItem>
					<div className='d-flex mt-3 justify-content-between align-items-center'>
						<h4 className='fw-bold fs-20'>{t('Professional Info')}</h4>
						<CustomButton
							onClick={(e: any) => {
								e.preventDefault();

								const myPromise = new Promise(function (myResolve) {
									setTimeout(function () {
										myResolve(
											history.push('/professional-info', {
												productName: location?.state?.productName,
												productId: location?.state?.productId,
												leadID: lead?.id,
												email: lead?.email,
											})
										);
									}, 10);
								});

								myPromise.then(async () => {
									setLeadInfoModalOpen(false);
									dispatch(
										leadFlow({
											...leadInfo,
											leadId: lead?.id,
											email: lead?.email,
											// productType: selectedProductName,
										})
									);
									dispatch(selectedLender([]));
								});
							}}
							fill='outline'
							title={t('Edit')}
						/>
					</div>

					<IonItem
						className='ion-no-padding'
						lines='full'
					>
						<div className='d-flex flex-grow-1 justify-content-between'>
							<p className='mb-0 fs-14'>{t('Profession Type')}</p>
							<p className='mb-0 fs-14'>
								{lead?.profession_type || 'Not Available'}
							</p>
						</div>
					</IonItem>
					<IonItem
						className='ion-no-padding'
						lines='full'
					>
						<div className='d-flex flex-grow-1 justify-content-between'>
							<p className='mb-0 fs-14'>{t('Company Name')}</p>
							<p className='mb-0 fs-14'>
								{lead?.company_name || 'Not Available'}
							</p>
						</div>
					</IonItem>
					<IonItem
						className='ion-no-padding'
						lines='full'
					>
						<div className='d-flex flex-grow-1 justify-content-between'>
							<p className='mb-0 fs-14'>{t('Company Type')}</p>
							<p className='mb-0 fs-14'>
								{lead?.company_type || 'Not Available'}
							</p>
						</div>
					</IonItem>
					<IonItem
						className='ion-no-padding'
						lines='full'
					>
						<div className='d-flex flex-grow-1 justify-content-between'>
							<p className='mb-0 fs-14'>
								{t('Work Experience in')} <br /> {t('Current Organisation')}
							</p>
							<p className='mb-0 fs-14'>
								{lead?.work_expirience || 'Not Available'}
							</p>
						</div>
					</IonItem>
					<IonItem
						className='ion-no-padding'
						lines='full'
					>
						<div className='d-flex flex-grow-1 justify-content-between'>
							<p className='mb-0 fs-14'>{t('Total Experience')}</p>
							<p className='mb-0 fs-14'>
								{lead?.Total_expirience || 'Not Available'}
							</p>
						</div>
					</IonItem>
					<IonItem
						className='ion-no-padding'
						lines='full'
					>
						<div className='d-flex flex-grow-1 justify-content-between'>
							<p className='mb-0 fs-14'>{t('Income')}</p>
							<p className='mb-0 fs-14'>{lead?.Income || 'Not Available'}</p>
						</div>
					</IonItem>
				</IonList>
			</IonPage>
		);
	};

	const onProceedClick = () => {
		dispatch(addMoreLenders(false));
		let headerTitle = '';
		let step = '';
		let path = '';
		let needArrow = true;
		let needCross = false;
		switch (lead?.lead_status) {
			case LeadStatus.GENERATED:
				headerTitle = 'Add Lead';
				needArrow = true;
				needCross = false;
				step = 'step2';
				break;
			case LeadStatus.BUREAU:
				step =
					lead?.scope_type?.toLocaleLowerCase() ===
					LEAD_SCORE_TYPE.CBIL.toLocaleLowerCase()
						? 'step5'
						: lead?.scope_type?.toLocaleLowerCase() ===
						  LEAD_SCORE_TYPE.EQUIFAX.toLocaleLowerCase()
						? 'step3'
						: lead?.scope_type?.toLocaleLowerCase() ===
						  LEAD_SCORE_TYPE.BOTH.toLocaleLowerCase()
						? 'step3'
						: '';
				needArrow = false;
				needCross = true;
				if (
					lead?.scope_type?.toLocaleLowerCase() ===
						LEAD_SCORE_TYPE.EQUIFAX.toLocaleLowerCase() ||
					(lead?.scope_type?.toLocaleLowerCase() ===
						LEAD_SCORE_TYPE.BOTH.toLocaleLowerCase() &&
						step === 'step3')
				) {
					headerTitle = 'OTP Verification';
				}

				break;
			case LeadStatus.PROCESSING:
				step = 'step6';
				break;
			case LeadStatus.SELECTION:
				step = 'step7';
				break;
			case LeadStatus.APPLICATIONSFULFILLED:
				step = 'step8';

				break;
		}
		// nimbuss flow do not touch or remove the code in
		if (
			lead?.product_id === null &&
			lead?.lead_status === LeadStatus.GENERATED
		) {
			path = '/select-lead-product';
		} else if (lead?.product_id === null && lead?.scope_type === '') {
			path = '/select-lead-product';
		}
		if (lead?.product_id === 1) {
			switch (step) {
				case 'step6':
					path = '/professional-info';
					break;
				case 'step7':
					path = lead?.isLenderSelected
						? '/selected-lender-list'
						: '/lenders-list';
					break;
				case 'step8':
					path = '/lender-specific-info';
					break;
				default:
					path = '/loan-lead-info-steps';
					break;
			}
		}
		if (lead?.product_id === 2) {
			switch (step) {
				case 'step6':
					path = '/professional-info';
					break;
				case 'step7':
					path = lead?.isLenderSelected
						? '/selected-lender-list'
						: '/lenders-list';
					break;
				default:
					path = '/insurance-lead-info-steps';
					break;
			}
		}
		let myPromise: any = null;
		// code for cbil send to step 5 hit api send consent
		if (step === 'step5') {
			myPromise = new Promise(function (myResolve) {
				dispatch(
					cbilRequestConsentDetails({
						param: `${lead.id}`,
					})
				)
					.then(unwrapResult)
					.then(async (cRCResponse: any) => {
						if (cRCResponse.status) {
							if (cRCResponse?.is_consent) {
								if (cRCResponse?.is_otp) {
									path = '/bureau-report';
									setTimeout(function () {
										myResolve(history.push(path));
									}, 500);
								} else {
									step = 'step6';
									await dispatch(
										generateCibilOtp({
											data: {
												lead_id: umData?.id,
												mobile_no: umData?.mobile_no,
											},
										})
									)
										.then(unwrapResult)
										.then(async (cbilOtpResponse: any) => {
											if (cbilOtpResponse.status) {
												setTimeout(function () {
													myResolve(history.push(path));
												}, 500);
												myPromise.then(async () => {
													dispatch(
														leadFlow({
															...leadInfo,
															reSendCibilOtp: false,
															cbilResponse: {
																ChallengeConfigGUID:
																	cbilOtpResponse?.ChallengeConfigGUID,
																answerKey: cbilOtpResponse?.answerKey,
																questionKey: cbilOtpResponse?.questionKey,
															},
															lead_consent_type: 'Lead',
															iSBackFromDsburseAndSanction:
																findCurrentStatus(leadInfo?.leadStatus)
																	.label ===
																	LEAD_STATUS.APPLICATION_FULFILLED ||
																findCurrentStatus(leadInfo?.leadStatus)
																	.label === LEAD_STATUS.ALL
																	? true
																	: false,
														})
													);
												});
											}
										});
								}
							} else {
								dispatch(
									leadBureauCustomer({
										lead_type: 'Lead',
										reSendCibilOtp: false,
									})
								);
								dispatch(
									leadFlow({
										...leadInfo,
										lead_consent_type: 'Lead',
										pccId: cRCResponse?.pccId,
										reSendCibilOtp: false,
									})
								);
								setTimeout(function () {
									myResolve(history.push(path));
								}, 500);
							}
						}
					});
			});
		} else {
			// default code asusual
			myPromise = new Promise(function (myResolve) {
				setTimeout(function () {
					myResolve(history.push(path));
				}, 10);
			});
		}

		myPromise.then(async () => {
			await dispatch(
				leadFlow({
					...leadInfo,
					selectedProductData: {
						product_category_id: lead?.product_category_id,
					},
					headerTitle,
					step: step,
					reSendCibilOtp: false,
					leadId: lead?.id,
					leadMobile: umData?.mobile_no,
					leadEmail: umData?.email,
					needArrow,
					needCross,
					appsToUpdate: [],
					selectedLender: [],
					lead_consent_type: 'Lead',
					scoreType: lead?.scope_type,
					subHeaderStepsCounter:
						lead?.lead_status === LeadStatus.BUREAU ? false : true,
					updateLeadProcess: leadInfo?.updateLeadProcess + 1 || 1,
					isNewLead: false,
					iSBackFromDsburseAndSanction:
						findCurrentStatus(leadInfo?.leadStatus).label ===
							LEAD_STATUS.APPLICATION_FULFILLED ||
						findCurrentStatus(leadInfo?.leadStatus).label === LEAD_STATUS.ALL
							? true
							: false,
					leadCurrentStep: step,
					isLenderSelected: lead?.isLenderSelected,
					isBackLenderOnlineForm: false,
					// productType: selectedProductName,
				})
			);
		});
	};

	useEffect(() => {
		(() => {
			let _isButtonNeeded = false;
			if ([StatusNum.DEACTIVATE].includes(leadInfo?.leadStatus)) {
				_isButtonNeeded = false;
			} else if (
				leadInfo?.lead_app_id
					? leadInfo?.leadStatus == LEAD_STATUS.LOGIN_LABEL ||
					  leadInfo?.lead_status == LEAD_STATUS.LOGIN ||
					  leadInfo?.leadStatus == LEAD_STATUS.SANCTIONED_LABEL ||
					  leadInfo?.lead_status == LEAD_STATUS.SANCTIONED ||
					  leadInfo?.leadStatus == LEAD_STATUS.DISBURSED_LABEL ||
					  leadInfo?.lead_status == LEAD_STATUS.DISBURSED ||
					  leadInfo?.lead_status == LEAD_STATUS.PARTIALDISBURSED ||
					  leadInfo?.leadStatus == LEAD_STATUS.UNDERPROCESS_LABEL ||
					  leadInfo?.lead_status == LEAD_STATUS.UNDERPROCESS ||
					  leadInfo?.leadStatus == LEAD_STATUS.CLOSED_LABEL ||
					  leadInfo?.lead_status == LEAD_STATUS.CLOSED ||
					  leadInfo?.leadStatus == LEAD_STATUS.APPLICATION_CLOSED_LABEL ||
					  leadInfo?.application_status == LEAD_STATUS.APPLICATION_CLOSED
					: leadInfo?.leadStatus == LEAD_STATUS.CLOSED_LABEL ||
					  leadInfo?.lead_status == LEAD_STATUS.CLOSED ||
					  leadInfo?.leadStatus == LEAD_STATUS.UNDERPROCESS_LABEL ||
					  leadInfo?.lead_status == LEAD_STATUS.UNDERPROCESS
			) {
				_isButtonNeeded = false;
			} else {
				_isButtonNeeded = true;
			}
			setIsButtonNeeded(_isButtonNeeded);
		})();
	}, [location.pathname, leadInfo?.lead_status]);

	const handleBackButton = () => {
		dispatch(
			leadFlow({
				...leadInfo,
				showDisbursed: false,
				lead_status: '0',
				leadStatus: leadInfo.via === 'all' ? 'all' : leadInfo.leadStatus,
			})
		);
		const myPromise = new Promise(function (myResolve) {
			setTimeout(function () {
				// if (leadInfo?.isFromLenderSpecifiction) {
				// 	myResolve(history.push('/lender-specific-info'));
				// }
				// else {
				myResolve(history.push('/lead-list'));
				// }
			}, 10);
		});
		myPromise.then(() => {
			setLead([]);
			setTimeout(() => {
				dispatch(
					leadFlow({
						...leadInfo,
						leadData: lead?.leadId,
						update: lead?.update + 1 || 1,
						isFromLenderSpecifiction: false,
						isPartial: '',
						showDisbursed: false,
						lead_status: '0',
						leadStatus: leadInfo.via === 'all' ? 'all' : leadInfo.leadStatus,

						// productType: selectedProductName,
					})
				);
			}, 500);
		});
	};

	const handleCallToLead = () => {
		if (isBrowser && is_dialler) {
			dispatch(leadCallInitiated({ param: lead?.id }))
				.then(unwrapResult)
				.then(async (response: any) => {
					if (response.status) {
						if ('clipboard' in navigator) {
							await navigator.clipboard.writeText(umData?.mobile_no);
						} else {
							// for IE
							document.execCommand('copy', true, umData?.mobile_no);
						}
						setTimeout(() => {
							showToastAlert({
								type: 'success',
								message: 'Number copied to clipboard',
							});
						}, 5000);
					}
				});
		} else {
			return null;
		}
	};

	const showEditIcon = () => {
		let status = false;
		// false - disabled
		if (
			LEAD_STATUS.ALL === leadInfo?.leadStatus &&
			userId === leadInfo?.lead_current_assignee &&
			leadInfo?.lead_status !== LEAD_STATUS.CLOSED
		) {
			status = true;
		} else if (leadInfo?.lead_app_id) {
			if (
				(leadInfo?.application_status === LEAD_STATUS.DISBURSED &&
					leadInfo?.lead_status === LEAD_STATUS.CLOSED) ||
				leadInfo?.lead_status === LEAD_STATUS.CLOSED
			) {
				status = false;
			} else if (
				leadInfo?.lead_status !== LEAD_STATUS.CLOSED &&
				findCurrentStatus(leadInfo?.leadStatus).label !==
					LEAD_STATUS.CLOSED_LABEL &&
				findCurrentStatus(leadInfo?.leadStatus).label !==
					LEAD_STATUS.UNDERPROCESS_LABEL
			) {
				status = true;
			}
		} else if (
			leadInfo?.lead_status !== LEAD_STATUS.CLOSED &&
			findCurrentStatus(leadInfo?.leadStatus).label !==
				LEAD_STATUS.CLOSED_LABEL &&
			findCurrentStatus(leadInfo?.leadStatus).label !==
				LEAD_STATUS.UNDERPROCESS_LABEL
		) {
			status = true;
		}
		return status;
	};

	const isLeadApplicationStatus =
		findCurrentStatus(leadInfo?.leadStatus).label === LEAD_STATUS.LOGIN_LABEL ||
		findCurrentStatus(leadInfo?.leadStatus).label ===
			LEAD_STATUS.SANCTIONED_LABEL ||
		findCurrentStatus(leadInfo?.leadStatus).label ===
			LEAD_STATUS.DISBURSED_LABEL;
	const isShowLeadApplicationCard =
		isLeadApplicationStatus ||
		findCurrentStatus(leadInfo?.leadStatus).label ===
			LEAD_STATUS.APPLICATION_FULFILLED ||
		(findCurrentStatus(leadInfo?.leadStatus).label === LEAD_STATUS.ALL &&
			[
				LEAD_STATUS.FULFILLMENT_LABEL,
				LEAD_STATUS.LOGIN_LABEL,
				LEAD_STATUS.SANCTIONED_LABEL,
			].includes(findCurrentStatus(leadInfo?.lead_status).label));
	return (
		<>
			<IonPage>
				<Header
					name=''
					backButton={getTokenFromUrl ? false : true}
					needArrow={true}
					needArrowOnClick={() => {
						handleBackButton();
					}}
					endItem={
						![
							LEAD_STATUS.CLOSED_LABEL,
							LEAD_STATUS.UNDERPROCESS_LABEL,
							LEAD_STATUS.RAW_LABEL,
							LEAD_STATUS.ALLOCATOR_LABEL,
						].includes(leadInfo?.leadStatus) ? (
							<>
								<IonButtons
									slot='end'
									className=''
								>
									<IonImg
										className='cursor-pointer'
										src='/images/icons/assign-icon.svg'
										alt='assign'
										onClick={() => {
											setShowAllocateManagerModal(true);
										}}
									/>
								</IonButtons>
							</>
						) : (
							''
						)
					}
				/>
				<IonContent
					id='lead-detail'
					className='bg-web lead-details'
					style={{ opacity: lead && Object.keys(lead).length ? 1 : 0 }}
				>
					{' '}
					<div className='lead-details bg-web py-md-3'>
						<div className='container-width-md bg-web-white border-md-radius-10'>
							<IonCard className='m-0 details bg-white px-3'>
								<div className='d-flex align-items-center gap-3 p-3'>
									<div className='w-auto'>
										{/* <img
									width={72}
									height={72}
									className='rounded-circle'
									alt='Silhouette of mountains'
									src='https://ionicframework.com/docs/img/demos/card-media.png'
								/> */}
										<InitialLetter
											{...{
												value: lead?.first_name || 'Unknown',
												height: 72,
												width: 72,
												fontSize: '44px',
											}}
										/>
									</div>
									<div>
										<IonCardTitle className='fs-14 bg-transparent darkest-grey'>
											# L{lead?.id} &nbsp; &nbsp;
											{`${
												leadInfo?.lead_app_id
													? `#A${leadInfo?.lead_app_id}`
													: ''
											}`}
										</IonCardTitle>
										<IonCardSubtitle className='fs-18 fw-600 darkest-grey bg-transparent text-capitalize'>
											{lead?.first_name ? lead?.first_name : 'Unknown'}{' '}
											{lead?.middle_name ? lead?.middle_name : ''}{' '}
											{lead?.last_name ? lead?.last_name : ''}
										</IonCardSubtitle>
										{!isLeadApplicationStatus && (
											<div className='d-flex'>
												<CustomButton
													disabled={!showEditIcon()}
													className='w-100 dashboard-add-lead-button fs-16 fw-600'
													//  expand='block'
													fill='outline'
													style={{ height: '30px', flexBasis: 'content' }}
													onClick={() => {
														const myPromise = new Promise(function (myResolve) {
															setTimeout(function () {
																myResolve(
																	history.push(
																		`/update-status${
																			getTokenFromUrl ? '?from=nimbuss' : ''
																		}`,
																		[leadInfo?.id]
																	)
																);
															}, 10);
														});
														myPromise.then(() => {
															dispatch(selectedLender([]));
															dispatch(
																leadFlow({
																	...leadInfo,
																	isBulk: false,
																	addDisposition: false,
																	leadStatus: lead?.lead_status,
																	getLeadAllSelectedIds: leadInfo?.id,
																	applicationStatus: '',
																	update: leadInfo.update + 1 || 1,
																	iSBackFromDsburseAndSanction: false,
																})
															);
														});
													}}
												>
													{t(
														findCurrentStatus(
															leadInfo?.lead_app_id &&
																LEAD_STATUS.CLOSED_LABEL == leadInfo.leadStatus
																? LEAD_STATUS.CLOSED_LABEL
																: lead?.lead_status
														).label
													)}
													{showEditIcon() ? (
														<img
															className='ms-2'
															src='images/icons/edit.svg'
															alt='edit'
														/>
													) : (
														''
													)}
												</CustomButton>
											</div>
										)}
									</div>
								</div>
								<IonCardHeader className='pt-0'>
									<IonList
										lines='none'
										className='bg-transparent d-flex flex-column gap-1'
									>
										<div className='d-flex gap-2'>
											{lead?.category_name && (
												<img
													src='/images/icons/homeloan.svg'
													alt='dmatype'
												/>
											)}
											<p className='text-grey-light   fs-14 mb-0'>
												{lead?.category_name}
												{'   '}
												{lead?.category_name && (
													<span className='dot-symbol mx-1'></span>
												)}
												{'   '}
												{lead?.purpose_name}
											</p>
										</div>
										<div className='d-flex gap-2'>
											<p className='text-grey-light   fs-14 mb-0'>
												{t('Loan Amount')}{' '}
												<span
													className='fs-14 sw-600'
													style={{ color: '#66CC66' }}
												>
													₹{' '}
												</span>
												{lead?.loan_amount
													? InputFormatCurrencyValue(lead?.loan_amount)
													: 'NA'}
											</p>
										</div>
									</IonList>
									{/* {lenderUiUrl ? (
										<>
											<div className='mb-3 copy_url'>
												<small>Lender Journey URL: {lenderUiUrl}</small>
												<IonIcon
													src='/images/icons/copy-icon.svg'
													className='copy_icon'
													onClick={() => copyText(lenderUiUrl)}
												/>
												{isCopied && (
													<small className='copied'>{t('Copied')}</small>
												)}
											</div>
										</>
									) : (
										''
									)} */}
									<div className='d-flex gap-3'>
										<CustomButton
											href={` ${
												isBrowser && is_dialler
													? 'javascript:void(0)'
													: 'tel:+91' + umData?.mobile_no
											}`}
											className='flex-grow-1 py-1 fs-16 dma-btn'
											onClick={handleCallToLead}
											disabled={getTokenFromUrl ? true : false}
										>
											<img
												src='/images/icons/teams/call.svg'
												alt='call'
												className='me-2'
											/>
											{isWeb ? '+91 ' + (lead?.mobile_no || '') : t('Call')}
										</CustomButton>
										<CustomButton
											href={
												'https://api.whatsapp.com/send?phone=91' +
												umData?.mobile_no
											}
											disabled={getTokenFromUrl ? true : false}
											className='flex-grow-1  py-1 fs-16 dma-btn'
										>
											<img
												src='/images/icons/leads/whatsapp.svg'
												alt=''
												className='me-1'
											/>
											{t('WhatsApp')}
										</CustomButton>
										{/* <CustomButton
											className='flex-grow-1 py-1 fw-bold fs-16 dma-btn'
											onClick={() => setShareModalOpen(true)}
										>
											<img
												src='/images/icons/more-icon.svg'
												alt='more'
											/>
										</CustomButton> */}
										{![
											LeadStatus.RAW,
											LeadStatus.GENERATED,
											LeadStatus.BUREAU,
										].includes(leadInfo?.applicationStatus) &&
										umData?.showdownload_report ? (
											<>
												<CustomButton
													className='dma-btn'
													style={{
														display: 'flex',
														alignSelf: 'center',
														height: '100%',
														justifyContent: 'center',
													}}
													onClick={() => setShareModalOpen(true)}
												>
													<span
														style={{
															width: '32px',
															height: '32px',
															display: 'flex',
															borderRadius: '50px',
															background: '#F3F3F3',
															padding: '3px 2px',
															justifyContent: 'center',
															alignContent: 'center',
														}}
													>
														<img
															src='/images/icons/teams/view-dots.svg'
															alt='dots'
														/>
													</span>
												</CustomButton>
											</>
										) : (
											''
										)}
									</div>
								</IonCardHeader>
							</IonCard>
							<Divider />
							{isButtonNeeded && (
								<IonCard className='shadow-none  bg-web-white px-md-2'>
									<IonCardContent className='text-center continue-tile'>
										<p className='mb-3 fs-16 text-black fw-600'>
											{t('Continue where we left off')}
										</p>
										<CustomButton
											expand='block'
											onClick={() => {
												onProceedClick();
											}}
											className='btn-blue dashbord-buttons lead-detail-buttons'
											title={t('Proceed')}
										/>
										{/* {!infoActivity?.activities &&
											lead?.lead_status === LeadStatus.GENERATED && (
												<CustomButton
													expand='block'
													className='dashbord-buttons lead-detail-buttons mt-3'
													//  expand='block'
													fill='outline'
													onClick={() => {
														dispatch(
															leadFlow({
																...leadInfo,
																isBulk: false,
																addDisposition: true,
																leadId: lead?.id,
															})
														);
														history.push('/update-status', [leadInfo?.id]);
														// history.push('/add-lead-disposition');
													}}
												>
													{t('Add Disposition')}
												</CustomButton>
											)} */}
									</IonCardContent>
								</IonCard>
							)}
							{(leadStatus == LEAD_STATUS.LOGIN_LABEL ||
								leadInfo?.lead_status == LEAD_STATUS.LOGIN ||
								leadInfo?.leadStatus == LEAD_STATUS.SANCTIONED_LABEL ||
								leadInfo?.lead_status == LEAD_STATUS.SANCTIONED ||
								leadInfo?.leadStatus == LEAD_STATUS.DISBURSED_LABEL ||
								leadInfo?.lead_status == LEAD_STATUS.DISBURSED ||
								leadInfo?.lead_status == LEAD_STATUS.PARTIALDISBURSED) && (
								<IonCard className='shadow-none  bg-web-white px-md-2'>
									<IonCardContent className='text-center continue-tile'>
										<p className='mb-3 fs-16 text-black fw-600'>
											{t('View all applications')}
										</p>
										<CustomButton
											expand='block'
											onClick={() => {
												new Promise(function (myResolve) {
													setTimeout(function () {
														myResolve(history.push('/lender-specific-info'));
													}, 10);
												});
											}}
											className='btn-blue dashbord-buttons lead-detail-buttons'
											title={t('View')}
										/>
									</IonCardContent>
								</IonCard>
							)}
							{/* 	<IonCard className='shadow-none border border-1'>
						<IonCardContent className='text-center'>
							<p className='mb-3 fs-16 text-black'>
								{t('View All Applications')}
							</p>
							<CustomButton
								 expand='block' 
								className='btn-blue w-100 dashboard-add-lead-button'
								title={t('View')}
							/>
						</IonCardContent>
					</IonCard> */}

							{/* <div className='ion-padding'>
						<div className='d-flex justify-content-between align-items-center'>
							<h4 className='fs-20 fw-600 flex-grow-1'>{t('Applications')}</h4>
							<select
								className='form-control form-select w-auto'
								name=''
								id=''
							>
								<option value='Login'>Login</option>
							</select>
						</div>
						<div className='mt-3 '>
							<IonCard className='w-100 m-0 px-3 py-2'>
								<div className='d-flex justify-content-between align-items-center'>
									<div className='d-flex gap-3 align-items-center'>
										<div>
											<img
												src='https://ionicframework.com/docs/img/demos/card-media.png'
												width={42}
												height={42}
												className='rounded-circle'
												alt=''
											/>
										</div>
										<div>
											<p className='fs-14 mb-0'># A987021 Offline</p>
											<h6 className='fs-16 fw-bold'>ICICI Bank</h6>
										</div>
									</div>
									<div>
										<p className='py-1 px-3 border border-1 border-dark mb-1 rounded-3'>
											Login{' '}
											<img
												className='ms-2'
												src='images/icons/edit.svg'
												alt='edit'
											/>
										</p>
									</div>
								</div>
							</IonCard>
						</div>
					</div> */}
							{dispution && (
								<IonCard className='shadow-none p-0 mt-3 px-md-2'>
									<IonCardContent className='text-center ion-no-padding'>
										<CustomButton
											expand='block'
											fill='solid'
											className='rounded-3'
											title={t('Continue')}
										/>
										<CustomButton
											expand='block'
											fill='outline'
											className='rounded-3 mt-3 secondary-btn'
											onClick={() => history.push('/add-lead-disposition')}
											title={t('Add Disposition')}
										/>
									</IonCardContent>
								</IonCard>
							)}
							<Divider />
							<div className='py-3 bg-web-white px-3 '>
								<h4 className='fw-bold fs-20'>{t('Lead Info')}</h4>

								<div className='mt-2 d-flex justify-content-between'>
									<p className='mb-0 fs-14'>{t('Lead Created')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{FormatDate(lead?.createdAt, 'dd mmmm yyyy')}
									</p>
								</div>
								<div className='mt-2 d-flex justify-content-between'>
									<p className='mb-0 fs-14'>{t('Source')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{findSource(lead?.lead_made_by)
											? findSource(lead?.lead_made_by)
											: 'NA'}
									</p>
								</div>

								<CustomButton
									fill='clear'
									onClick={() => {
										const myPromise = new Promise(function (myResolve) {
											setTimeout(function () {
												myResolve(
													history.push('/lead-about', {
														...location.state,
														leadData: lead,
													})
												);
											}, 10);
										});
										myPromise.then(() => {
											dispatch(
												leadFlow({
													...leadInfo,
													leadData: lead?.leadId,
												})
											);
										});
									}}
									className='my-3 mb-0 fw-bold fs-14 ion-no-padding'
								>
									<img
										src='/images/icons/teams/info.svg'
										alt='info'
										className='me-2'
									/>
									<span className='text-transform-none'>
										{t('See Profile for More Details')}
									</span>
								</CustomButton>
							</div>
							{isShowLeadApplicationCard && (
								<LeadApplications
									bankData={bankDetails}
									lenderUiUrl={lenderUiUrl}
									lenderOffer={lenderOffer}
									applicationStatusData={applicationStatusData}
									selectedApplicationStatus={selectedApplicationStatus}
									selectApplicationStatusValue={selectApplicationStatusValue}
									pendingStepUrl={pendingStepUrl}
								/>
							)}
							<Divider />
							<ActivitySection activityList={infoActivity} />
							<Divider />
							<div className='ion-padding bg-web-white border-md-radius-bottom'>
								<h4 className='fw-bold fs-20'>{t('Comments')}</h4>

								<IonItem
									className='my-3 ion-no-padding'
									lines='none'
								>
									<div className='d-flex flex-column  w-100 position-relative'>
										{/* <IonLabel className='d-block mb-2'>
											{t('Add Comment')}
										</IonLabel> */}
										<div className='d-flex align-items-center bg-light-grey textarea'>
											<IonTextarea
												className='custom bg-transparent ps-3 mt-0 '
												value={comment}
												maxlength={300}
												rows={1}
												placeholder={t('Add Comment').toString()}
												onIonInput={(e: any) => setComment(e.detail.value)}
											></IonTextarea>

											{comment?.trim().length > 0 && (
												<CustomButton
													style={{ color: '#0165E1' }}
													className={'fs-14 m-0'}
													fill={'none'}
													onClick={() => {
														addComment();
													}}
												>
													{t('Submit')}
												</CustomButton>
											)}
										</div>
									</div>
								</IonItem>
								{commentList.length === 0 && (
									<div className='d-flex mt-4 justify-content-center align-items-center flex-column'>
										<img
											src='/images/icons/no-comment.svg'
											alt='no data'
											width={54}
											height={48}
										/>
										<p className='fs-15 fw-500 mb-0 mt-3 text-grey'>
											{t('No comments added yet')}
										</p>
									</div>
								)}
								{commentList.length > 0 && (
									<h6 className='fw-bold fs-16 fs-500'>
										{commentList[0]?.comment ? commentList[0]?.comment : 'NA'}
									</h6>
								)}
								{commentList.length > 0 && (
									<>
										<div className='mt-2 d-flex justify-content-start gap-3'>
											<p className='mb-0 fs-12 fw-500 text-grey'>
												By{' '}
												{commentList[0]?.commented_by_name
													? commentList[0]?.commented_by_name
													: 'Unknown'}
												<span className='mx-2'>•</span>
												{FormatDate(commentList[0]?.createdAt, 'dd mmm yyyy')}
												<span className='mx-2'>•</span>
												<span>
													{FormatDate(commentList[0]?.createdAt, 'hh:MM TT')}
												</span>
											</p>
										</div>

										<CustomButton
											fill='clear'
											onClick={() => {
												const myPromise = new Promise(function (myResolve) {
													setTimeout(function () {
														myResolve(history.push('/view-lead-comments'));
													}, 10);
												});
												myPromise.then(() => {
													dispatch(
														leadFlow({
															...leadInfo,
															leadId: lead?.id,
															update: leadInfo?.update + 1 || 1,
														})
													);
												});
											}}
											className='my-3 mb-0 fw-bold fs-14 ion-no-padding'
										>
											<img
												src='/images/icons/teams/info.svg'
												alt='info'
												className='me-2'
											/>
											{t('See All Comments')}
										</CustomButton>
									</>
								)}
							</div>
						</div>
					</div>
				</IonContent>
			</IonPage>

			{/* Share Lead Profile Modal */}
			<CustomModal
				id='sharemodal'
				isOpen={shareModalOpen}
				initialBreakpoint={isWeb ? 1 : 0.3}
				handleClose={() => setShareModalOpen(false)}
				breakpoints={[0, 0.3, 0.5, 0.75]}
				modalContent={<ShareModal />}
				needCross={false}
				needArrow={false}
				className={`side-modal ${isWeb ? '' : 'modal-border-radius'}`}
			/>

			{/* Lead info Modal */}
			<CustomModal
				id='leadinfo'
				isOpen={leadInfoModalOpen}
				modalTitle={t('About')}
				initialBreakpoint={undefined}
				handleClose={() => setLeadInfoModalOpen(false)}
				breakpoints={[]}
				modalContent={<LeadInfoModal />}
				needCross={false}
				needArrow={true}
				scrollY={true}
			/>

			{/* AssignModal */}
			<CustomModal
				id='custommodal'
				isOpen={assignModalOpen}
				modalTitle={t('Update Stage')}
				initialBreakpoint={undefined}
				handleClose={() => setAssignModalOpen(false)}
				breakpoints={[]}
				modalContent={
					<div className='wrapper pt-3'>
						<IonItem lines='full'>
							<div className='d-flex flex-column'>
								<h5 className='mx-0'>
									{t(
										'Are you sure you want to allocate this lead to Andro Manager?'
									)}
								</h5>
							</div>
						</IonItem>
						<div className='d-flex justify-content-center gap-3 px-3 mt-3'>
							<CustomButton
								onClick={() => setAssignModalOpen(false)}
								className='w-50'
								fill='outline'
								title={t('Cancel')}
							/>
							<CustomButton
								className='w-50'
								fill='solid'
								title={t('Confirm')}
							/>
						</div>
					</div>
				}
				needCross={false}
				needArrow={false}
			/>
			<CustomModal
				isOpen={showAllocateManagerModal}
				modalTitle={t('Select Assignee')}
				initialBreakpoint={1}
				handleClose={() => setShowAllocateManagerModal(false)}
				breakpoints={[0, 1]}
				className='assignModal side-modal'
				modalContent={
					<CustomAllocateManagers
						eventType='Lead'
						id={leadInfo?.leadId}
						setShowAssignModal={setShowAllocateManagerModal}
						redirectTo='/dashboard/lead'
					/>
				}
				scrollY={true}
				needCross={true}
				needArrow={false}
			></CustomModal>
			{spinner && (
				<div
					className='position-fixed  w-100  d-flex justify-content-center align-items-center h-100'
					style={{
						top: '0px',
						left: '50%',
						transform: 'translate(-50%,0%)',
						backgroundColor: 'rgba(0,0,0,0.2)',
					}}
				>
					<IonSpinner name='lines-sharp' />
				</div>
			)}
			<CustomModal
				id=''
				isOpen={showNotificationModel}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setShowNitificationModel(false)}
				breakpoints={[]}
				className='confirm-popup-middle height-auto'
				modalContent={
					<div className={`wrapper py-4 confirm-popup-middle height-auto`}>
						<IonItem lines='none'>
							<div className='d-flex flex-column'>
								<p
									className='fs-14 mb-2'
									style={{ color: 'var(--text-dark-grey)' }}
								>
									Please enable notification permission from settings.
								</p>
							</div>
						</IonItem>
						<div className='d-flex justify-content-center gap-3 px-3'>
							<CustomButton
								className='w-50'
								fill='solid'
								onClick={() => {
									NativeSettings.open({
										optionAndroid: AndroidSettings.AppNotification,
										optionIOS: IOSSettings.Notifications,
									});
									setShowNitificationModel(false);
								}}
								title={t('Ok')}
							/>
						</div>
					</div>
				}
				needIonContentWraper={false}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
