/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonCol, IonGrid, IonImg, IonRow, isPlatform } from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router';
import { Player } from '@lottiefiles/react-lottie-player';
import { useDispatch, useSelector } from 'react-redux';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import {
	ACL,
	ShareData,
	eventAttributes,
	notifyHandler,
} from '../../../../helpers/functions';
import { ACLKeys } from '../../../../helpers/constant';
import {
	getLeadFlowUrl,
	leadCreationtype,
} from '../../../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { NotifyVisitors } from 'capacitor-plugin-notifyvisitors';

export const ActivityOptions: React.FC = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const permissions = useSelector(
		(state: any) => state?.users?.user_details?.permissions
	);
	const { leadcreatedthrough } = useSelector((state: any) => state?.lead);
	const scoreType = useSelector(
		(state: any) => state?.lead?.leadBureauCustomerData?.scoreType
	);

	// Notify Visitors
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const userName = useSelector(
		(state: any) => state?.users?.homepage?.profile?.name
	);
	const userMobile = useSelector(
		(state: any) => state?.users?.user_details?.mobile
	);

	const entityType = useSelector(
		(state: any) => state?.users?.user_details?.entityType
	);
	const userLang = useSelector(
		(state: any) => state?.oneTimeScreen?.language?.name
	);

	const userAttributes = {
		attributes: {
			name: userName,
			id: userId,
			mobile: userMobile,
			user_language: userLang,
			entityType:
				entityType === 'Individual'
					? 'DMA'
					: entityType === 'BTM'
					? 'employee'
					: entityType,
		},
	};
	const activityOptions = [
		{
			id: ACLKeys.CHECK_BUREAU,
			heading: t('Credit Score'),
			subHeader: 'Check Eligibility for Free',
			buttonName: t('Check'),
			type: 'blue',
			icon: '/images/icons/json/BureauScoreSpeedometer.json',
			shareIconInfo: {
				shareIcon: '/images/icons/bureau-share-icon.svg',
				shareIconId: ACLKeys.SHARE_CHECK_BUREAU,
			},
			shareIcon: '/images/icons/bureau-share-icon.svg',
			shareIconLink: '',
			link: '/check-bureau-score',
			check: 'score',
		},
		{
			id: ACLKeys.ADD_NEW_LEAD,
			heading: t('Add Leads'),
			subHeader: 'Create a New Prospect',
			buttonName: t('Add'),
			type: 'green',
			shareIconInfo: {
				shareIcon: '/images/icons/new-lead-share-icon.svg',
				shareIconId: ACLKeys.SHARE_LEAD,
			},
			icon: '/images/icons/json/NewLead.json',
			shareIconLink: '',
			link: '/dashboard/add-lead',
			check: 'lead',
		},
	];
	return (
		<section className='bg-web-white border-md-radius-10'>
			<IonGrid className='activity-section'>
				<IonRow>
					{activityOptions.map((item: any, index) => {
						return (
							<IonCol
								size='12'
								sizeXs='6'
								sizeMd='6'
								sizeLg='6'
								key={`dashboard-activity-${index}`}
							>
								{' '}
								<div
									className={`action-box-outer ${
										index === 0 ? 'me-1' : 'ms-1'
									}`}
								>
									<div
										className={
											item?.type === 'secondary'
												? 'action-box '
												: `green-box card-gradient-top-${item.type} d-flex flex-column flex-md-row justify-content-between`
										}
									>
										<div className='d-flex gap-2 gap-md-3 align-items-center justify-content-between pb-2'>
											<Player
												autoplay={true}
												loop={true}
												controls={false}
												src={item.icon}
												style={{ height: '38px', width: '38px' }}
											/>
											<h3 className='fw-600 fs-14 m-0 darkest-grey'>
												{item.heading}
											</h3>
										</div>
										<div>
											<div className='d-flex gap-md-3 align-items-center justify-content-between buttons-div'>
												{ACL(permissions, item.id) && (
													// Notify Visitors
													<CustomButton
														size='small'
														fill={'solid'}
														className={`btn-${item.type} fs-14`}
														onClick={() => {
															if (item?.id == 1) {
																dispatch(
																	leadCreationtype({
																		...leadcreatedthrough,
																		leadCreationtype: item?.id,
																	})
																);
																if (
																	isPlatform('android') ||
																	isPlatform('ios')
																) {
																	notifyHandler(
																		'event',
																		eventAttributes('check_credit_score')
																	);
																	notifyHandler(
																		'user',
																		userAttributes.attributes
																	);
																	const inAppData = {
																		tokens: { name: userAttributes },
																		customRules: {
																			banerrType: 'check credit score',
																		},
																	};
																	NotifyVisitors.show(inAppData);
																} else {
																	(window as any).nv(
																		'event',
																		'check_credit_score',
																		10,
																		1
																	);
																	(window as any).nv(
																		'user',
																		userId,
																		userAttributes.attributes
																	);
																}
															} else if (item?.id == 3) {
																dispatch(
																	leadCreationtype({
																		...leadcreatedthrough,
																		leadCreationtype: item?.id,
																	})
																);
																if (
																	isPlatform('android') ||
																	isPlatform('ios')
																) {
																	notifyHandler(
																		'event',
																		eventAttributes('Add_lead')
																	);

																	notifyHandler(
																		'user',
																		userAttributes.attributes
																	);
																	const inAppData = {
																		tokens: { name: userAttributes },
																		customRules: {
																			banerrType: 'Add Lead',
																		},
																	};
																	NotifyVisitors.show(inAppData);
																} else {
																	(window as any).nv(
																		'event',
																		'Add_lead',
																		{ userAttributes },
																		10,
																		1
																	);
																	(window as any).nv(
																		'user',
																		userId,
																		userAttributes.attributes
																	);
																}
															}

															return item?.link ? history.push(item.link) : '';
														}}
														title={item.buttonName}
													/>
												)}
												{ACL(permissions, item.shareIconInfo.shareIconId) && (
													<IonImg
														className='box-share-icon cursor-pointer'
														src={item.shareIconInfo.shareIcon}
														alt='share'
														onClick={() => {
															dispatch(
																leadCreationtype({
																	...leadcreatedthrough,
																	leadCreationtype:
																		item.shareIconInfo.shareIconId,
																})
															);
															dispatch(getLeadFlowUrl())
																.then(unwrapResult)
																.then((result: any) => {
																	let appendUrl: any;
																	let finalUrl: any;
																	if (item?.check === 'score') {
																		dispatch(
																			leadCreationtype({
																				...leadcreatedthrough,
																				leadCreationtype: 2,
																			})
																		);
																		appendUrl = `&check_score=true&score_type=${scoreType}`;
																		const u: any = window.atob(
																			result.url.split('=')[1]
																		);
																		finalUrl =
																			result?.url.split('?')[0] +
																			'?d=' +
																			window.btoa(u + appendUrl);
																	} else {
																		dispatch(
																			leadCreationtype({
																				...leadcreatedthrough,
																				leadCreationtype: 4,
																			})
																		);
																		const u: any = window.atob(
																			result.url.split('=')[1]
																		);
																		finalUrl =
																			result?.url.split('?')[0] +
																			'?d=' +
																			window.btoa(u);
																	}
																	if (result?.status) {
																		ShareData('', '', finalUrl);
																	}
																});
														}}
													/>
												)}
											</div>
										</div>
									</div>
								</div>
							</IonCol>
						);
					})}
				</IonRow>
			</IonGrid>
		</section>
	);
};
