import { IonContent, IonFooter, IonPage } from '@ionic/react';
import React from 'react';
import { Header } from '../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import './Teams.scss';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { REGEX } from '../../helpers/constant';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import {
	customSuccessfull,
	inviteDma,
} from '../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { disableNumberArrows } from '../../helpers/functions';

const inviteSchema = yup.object().shape({
	mobile: yup
		.string()
		.length(10, 'Mobile No. Should Be 10 Digits.')
		.required('Mobile is Required.')
		.matches(REGEX.INDIAN_MOBILE_NUMBERS, 'Please Enter a valid Mobile No.'),
});

export const InviteDma: React.FC = () => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const history = useHistory();

	const {
		handleSubmit,
		control,
		trigger,
		setValue,
		getValues,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(inviteSchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const onSubmit = async () => {
		const { mobile } = getValues();
		await dispatch(inviteDma({ data: { mobile: Number(mobile) } }))
			.then(unwrapResult)
			.then(async (result: any) => {
				if (result?.status) {
					history.replace('/custom-successfull', {
						type: 'DMAINVITESUCCESS',
						message: `${t('Invitation Sent Successfully')} !`,
						redirectTo: '/dashboard/teams',
					});
					await dispatch(
						customSuccessfull({
							type: 'DMAINVITESUCCESS',
							message: `${t('Invitation Sent Successfully')} !`,
							redirectTo: '/dashboard/teams',
						})
					);
				}
			});
	};

	return (
		<IonPage className='teams bg-web'>
			<Header
				name={t('Invite DMA')}
				backButton={true}
				needArrow={true}
			/>

			<IonContent
			// className='bg-web background-web'
			// style={{
			// 	borderTop: '1px solid #00000029',
			// 	height: 'calc(100vh - 113px)',
			// 	overflowX: 'hidden',
			// }}
			>
				<div className='bg-web h-100 pt-md-3'>
					<div className='conatiner-spacing-md py-md-4 bg-web-white container-width-md e-franking-send-container-height border-radius-10 d-md-flex justify-content-md-between flex-md-column'>
						<form
							onSubmit={handleSubmit(onSubmit)}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									handleSubmit(onSubmit);
								}
							}}
						>
							<Controller
								control={control}
								render={({ field: { onChange, onBlur, name } }) => (
									<CustomInput
										type={'number'}
										placeholder={t('Mobile No') + ' .*'}
										onBlur={onBlur}
										name={name}
										onChange={onChange}
										value={getValues(name)}
										errors={t(errors?.mobile?.message as any)}
										onBeforeInput={(evt: any) => {
											//Only numbers accepted on user input
											!/[0-9]/.test(evt.data) && evt.preventDefault();
											//Only accepts 10 characters on user input
											if (evt.target.value.length === 10) {
												evt.preventDefault();
											}
										}}
										onPaste={(e: any) => {
											//Only get numbers on user paste
											e.preventDefault();
											let pasteContent = e.clipboardData.getData('Text');
											if (pasteContent != '') {
												pasteContent = pasteContent.replace(/\D/g, '');
												if (pasteContent.length > 10) {
													pasteContent = pasteContent.slice(0, 10);
												}
											}
											setValue('mobile', pasteContent);
											trigger('mobile');
										}}
										onKeyDown={(e: any) => {
											disableNumberArrows(e);
										}}
										onWheel={(e: any) => e.target.blur()}
									/>
								)}
								name={'mobile'}
							/>
							{/* <CustomTest /> */}
						</form>
						<div className='proceed-btn d-none d-md-block'>
							<CustomButton
								type='submit'
								disabled={!isValid}
								onClick={handleSubmit(onSubmit)}
								className='btn-blue'
								expand='block'
								title={t('Send Invite Link')}
							/>
						</div>
					</div>
				</div>
			</IonContent>
			<IonFooter className='ion-padding d-block d-md-none'>
				<CustomButton
					type='submit'
					disabled={!isValid}
					onClick={handleSubmit(onSubmit)}
					className='btn-blue'
					expand='block'
					title={t('Send Invite Link')}
				/>
			</IonFooter>
		</IonPage>
	);
};
