import {
	IonContent,
	IonItem,
	IonLabel,
	IonList,
	IonPage,
	isPlatform,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
	Camera,
	CameraDirection,
	CameraResultType,
	CameraSource,
} from '@capacitor/camera';
import { getPictureDataByOcr, urltoFile } from '../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { UploadImageCard } from '../dashboard/kyc/UploadImageCard';
import { Header } from '../../components/layout/header/Header';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
import { leadMigrationUpload } from '../../store/reducers/lead/leadSlice';

export const documentsKycSchema = yup.object().shape({
	Doc: yup.object().shape({
		Url: yup.string().required('Doc is Required.'),
	}),
});

export const LeadMigration = () => {
	const isBrowser = !isPlatform('capacitor');
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	const [documentsSchema] = useState<any>(documentsKycSchema);
	const [openUploadModal, setOpenUploadModal] = useState(false);
	const [imagePreviewUpload, setImagePreviewUpload] = useState({
		docImage: false,
	});
	const [docTypeErrorMessage, setDocTypeErrorMessage] = useState({
		typeError: '',
	});
	const [clickImageFieldName, setClickImageFieldName] = useState<any>(null);
	const [uploadedFileName, setUploadedFileName] = useState<string>('');
	const dispatch = useDispatch();
	const { t } = useTranslation();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const history: any = useHistory();
	const [fileValidation, setFileValidation] = useState<any>({
		status: '',
		msg: '',
	});

	const {
		// handleSubmit,
		getValues,
		// watch,
		setValue,
		formState: { errors },
	}: any = useForm({
		resolver: yupResolver(documentsSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			Doc: {
				Type: 'Doc',
				Url: '',
				Data: { No: '' },
				status: 'added',
			},
		},
	});

	useEffect(() => {
		if (location.pathname !== '/lead-migration') {
			return;
		}
	}, [location.pathname]);

	const ShareModal = () => {
		const handleUploadChange = async (
			type: string,
			fieldName: string,
			e: any
		) => {
			setOpenUploadModal(false);
			const formData = new FormData();
			let image: any;
			let getOcrData: any;
			let getImageFileObject: any;
			setDocTypeErrorMessage({
				typeError: '',
			});
			const file = e.target.files?.[0];

			switch (type) {
				case 'file':
					if (
						e.target.files[0].type === 'application/pdf' ||
						e.target.files[0].type === 'application/vnd.ms-excel' ||
						e.target.files[0].type ===
							'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					) {
						formData.append('lead_file', e.target.files[0]);
						dispatch(leadMigrationUpload({ data: formData }))
							.then(unwrapResult)
							.then((response: any) => {
								if (response?.status) {
									setValue(`${fieldName}`, response?.url);
									switch (fieldName) {
										case 'Doc.Url':
											setDocTypeErrorMessage({
												...docTypeErrorMessage,
												typeError: '',
											});
											setImagePreviewUpload({
												...imagePreviewUpload,
												docImage: true,
											});
											setUploadedFileName(file.name);
											setTimeout(() => {
												history.push('/dashboard/home');
											}, 5000);

											break;
									}
								} else {
									setUploadedFileName('');
								}
							});

						break;
					} else {
						switch (fieldName) {
							case 'Doc.Url':
								setDocTypeErrorMessage({
									...docTypeErrorMessage,
									typeError:
										'Uploaded file is invalid. Only JPG/JPEG, PNG and PDF files are allowed.',
								});
								setUploadedFileName('');

								break;
						}
						break;
					}

				case 'age':
					image = await Camera.getPhoto({
						quality: 50,
						allowEditing: false,
						resultType: CameraResultType.Uri,
						source: CameraSource.Photos,
					});

					getImageFileObject = await urltoFile(
						image.webPath,
						`${Math.random() * 100000000000000000}.${image.format}`,
						image.format
					);
					if (!['Age'].includes(fieldName.split('.')[0])) {
						getOcrData = await getPictureDataByOcr(
							'imageWithData',
							image.path,
							image.webPath,
							fieldName.split('.')[0].toLocaleLowerCase()
						);

						if (
							!['not found', 'notfound'].includes(
								getOcrData.processedData.toLowerCase()
							)
						) {
							if (fieldName.split('.')[0] === 'Doc') {
								if (getValues('Doc.Data.No') !== '') {
									if (getValues('Doc.Data.No') === getOcrData.processedData) {
										setFileValidation({
											status: true,
											msg: 'Document details verified.',
										});
									} else {
										setFileValidation({
											status: false,
											msg: 'Document details not verified.',
										});
									}
								} else {
									setValue(
										fieldName.split('.')[0].concat('.Data.No'),
										getOcrData.processedData
									);
								}
							} else {
								setValue(
									fieldName.split('.')[0].concat('.Data.No'),
									getOcrData.processedData
								);
							}
						} else {
							if (fieldName.split('.')[0] === 'Doc') {
								setFileValidation({
									status: false,
									msg: 'Document details not verified.',
								});
							}
						}
					}
					formData.append('lead_file', getImageFileObject);
					dispatch(leadMigrationUpload({ data: formData }))
						.then(unwrapResult)
						.then((response: any) => {
							if (response?.status) {
								setValue(`${fieldName}`, response?.url);
								switch (fieldName) {
									case 'Doc.Url':
										setImagePreviewUpload({
											...imagePreviewUpload,
											docImage: true,
										});
										break;
								}
							}
						});

					setImagePreviewUpload({
						...imagePreviewUpload,
						docImage: true,
					});

					break;
				case 'camera':
					image = await Camera.getPhoto({
						quality: 50,
						allowEditing: false,
						resultType: CameraResultType.Uri,
						source: CameraSource.Camera,
						direction: clickImageFieldName?.includes('Age.Url')
							? CameraDirection.Front
							: CameraDirection.Rear,
					});
					getImageFileObject = await urltoFile(
						image.webPath,
						`${Math.random() * 100000000000000000}.${image.format}`,
						image.format
					);
					if (!['Age'].includes(fieldName.split('.')[0])) {
						getOcrData = await getPictureDataByOcr(
							'imageWithData',
							image.path,
							image.webPath,
							fieldName.split('.')[0].toLocaleLowerCase()
						);

						if (
							!['not found', 'notfound'].includes(
								getOcrData.processedData.toLowerCase()
							)
						) {
							if (fieldName.split('.')[0] === 'Doc') {
								if (getValues('Doc.Data.No') !== '') {
									if (getValues('Doc.Data.No') === getOcrData.processedData) {
										setFileValidation({
											status: true,
											msg: 'Document details verified.',
										});
									} else {
										setFileValidation({
											status: false,
											msg: 'Document details not verified.',
										});
									}
								} else {
									setValue(
										fieldName.split('.')[0].concat('.Data.No'),
										getOcrData.processedData
									);
								}
							} else {
								setValue(
									fieldName.split('.')[0].concat('.Data.No'),
									getOcrData.processedData
								);
							}
						} else {
							if (fieldName.split('.')[0] === 'Doc') {
								setFileValidation({
									status: false,
									msg: 'Document details not verified.',
								});
							}
						}
					}
					formData.append('lead_file', getImageFileObject);

					dispatch(leadMigrationUpload({ data: formData }))
						.then(unwrapResult)
						.then((response: any) => {
							if (response?.status) {
								setValue(`${fieldName}`, response?.url);
								switch (fieldName) {
									case 'Doc.Url':
										setImagePreviewUpload({
											...imagePreviewUpload,
											docImage: true,
										});
										break;
								}
							}
						});
					setImagePreviewUpload({
						...imagePreviewUpload,
						docImage: true,
					});
					break;
				default:
					break;
			}
		};
		return (
			<>
				<IonList>
					{!isBrowser && (
						<IonItem lines='full'>
							<img
								src='/images/icons/kyc/Image-upload.svg'
								alt='Upload Age Proof'
							/>
							<IonLabel>
								<h2
									className='fs-16 ps-3 text-dark'
									onClick={(e) => {
										handleUploadChange('age', clickImageFieldName, e);
									}}
								>
									{t('Upload Age Proof')}
								</h2>
							</IonLabel>
						</IonItem>
					)}
					<IonItem
						lines='full'
						className='cursor-pointer'
					>
						<img
							src='/images/icons/kyc/upload-image-icon.svg'
							alt='Upload Age Proof'
						/>
						<IonLabel>
							<label
								htmlFor='upload-age'
								className='cursor-pointer'
							>
								<h2 className='fs-16 ps-3 text-dark ion-text-wrap'>
									{t('Upload Document')}
								</h2>
							</label>
							<input
								className='d-none'
								accept='image/png, image/jpeg ,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
								type='file'
								id='upload-age'
								onChange={(e) => {
									handleUploadChange('file', clickImageFieldName, e);
								}}
							/>
						</IonLabel>
					</IonItem>
					{!isBrowser && (
						<IonItem lines='full'>
							<img
								src='/images/icons/kyc/camera-icon.svg'
								alt='Upload Age Proof'
							/>
							<IonLabel>
								<h2
									className='fs-16 ps-3 text-dark'
									onClick={(e) => {
										handleUploadChange('camera', clickImageFieldName, e);
									}}
								>
									Take Age
								</h2>
							</IonLabel>
						</IonItem>
					)}
				</IonList>
			</>
		);
	};

	return (
		<IonPage className=''>
			<Header
				name={t('Lead Migration')}
				backButton={true}
				needArrow={true}
				// needArrowOnClick={() => {
				// 	leadInfo?.from === 'nimbuss'
				// 		? history.push(
				// 				'/lead-details/' +
				// 					leadInfo.leadId +
				// 					'/' +
				// 					dmaSelector?.user_details?.access_token
				// 		  )
				// 		: leadInfo?.isBackLenderOnlineForm
				// 		? history.goBack()
				// 		: history.push('/dashboard/lead');
				// }}
				// className={showOnlineFormSuccess ? 'd-none' : ''}
			/>
			<IonContent>
				<div className='bg-web h-100 overflowY-auto pt-md-3'>
					<div
						className={`container-width-md bg-web-white
						} py-md-3 border-md-radius-10 opacity-1`}
					>
						<section className='overflowX-hidden kyc-content-height'>
							<form encType='multipart/form-data'>
								<div
									className='ion-padding conatiner-spacing-md'
									// style={{ borderBottom: '1px solid grey' }}
								>
									<h3 className='m-0 fs-bold fs-20 pb-2'>
										{t('Lead Migration')}
									</h3>
									<div>
										<h4 className='m-0 text-grey fs-14 pb-2'>
											{t('Upload Lead Migration') + ' *'}
										</h4>

										<UploadImageCard
											{...{
												setImagePreviewUpload,
												imagePreviewUpload,
												isImageAvilable: imagePreviewUpload?.docImage,
												imgKey: 'docImage',
												setClickImageFieldName,
												setOpenUploadModal,
												setValue: setValue,
												value: 'Doc.Url',
												title: t('Upload Document'),
												src: getValues('Doc.Url'),
												setUploadedFileName,
											}}
										/>
										{uploadedFileName && (
											<div>
												<p>File Name: {uploadedFileName}</p>
											</div>
										)}
										{!imagePreviewUpload.docImage &&
										!docTypeErrorMessage.typeError &&
										errors?.Doc?.Url?.message ? (
											<div className=''>
												<div className='error-text sc-ion-input-md fs-12'>
													{t(errors?.Doc?.Url?.message)}
												</div>
											</div>
										) : (
											docTypeErrorMessage.typeError && (
												<div className=''>
													<div className='error-text sc-ion-input-md fs-12 pb-2'>
														{t(docTypeErrorMessage.typeError)}
													</div>
												</div>
											)
										)}
										{/* {imagePreviewUpload?.docImage ? (
							<div
								className='fs-10'
								style={{ color: '#00B715' }}
							>
								Doc details verified.
							</div>
						) : (
							''
						)} */}
										{fileValidation.msg && (
											<div
												className={`my-2 fs-12 d-flex align-items-center ${
													fileValidation.status
														? 'secondary-text-color'
														: 'danger-text-color'
												}`}
											>
												<img
													src={
														fileValidation.status
															? 'images/icons/green-info.svg'
															: 'images/icons/red-info.svg'
													}
													className='me-2'
													width={16}
													height={16}
													alt=''
												/>
												{fileValidation.msg}
											</div>
										)}
									</div>
								</div>
							</form>

							{/* Upload Age Proof Modal */}
							<CustomModal
								id='sharemodal'
								isOpen={openUploadModal}
								initialBreakpoint={isWeb ? 1 : 0.3}
								handleClose={() => setOpenUploadModal(false)}
								breakpoints={[0, 0.3, 0.5, 0.75]}
								modalContent={<ShareModal />}
								needCross={false}
								needArrow={false}
								className={`side-modal ${isWeb ? '' : 'modal-border-radius'}`}
							/>
						</section>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};
