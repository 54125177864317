//// @ts-nocheck


import React, { useState } from "react";
import { IonImg, IonItem, IonLabel, IonText, IonTextarea } from "@ionic/react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormatDate, disableNumberArrows } from "../../../helpers/functions";
import { ChoiceChip } from "../choice_chip/choice_chip";
import { CustomInput } from "../custom-input/CustomInput";
import { UploadWidget } from "../custom_file_upload/custom_file_upload";
import { format } from 'date-fns';
import { MultiChoiceChip } from "../choice_chip/multi_choice_chip";

interface Props {
  formKeys: any[];
  control: any;
  getValues: any;
  setValue: any;
  errors?: any;
  trigger?: any;
  isValid?: boolean;
  readonly?: boolean;
}


const CustomForm: React.FC<Props> = ({ formKeys, control, getValues, setValue, errors = undefined, trigger = undefined, isValid = undefined, readonly = false }) => {
  const { t } = useTranslation();

  // const [dateInputValue, setDateInputValue] = useState<string>('');

  // const handleDateInputChange = (e: any) => { 
  //   setDateInputValue(e.target.value);
  //   setValue(e.target.name, e.target.value);
  // };

  // const handleDateInputKeyDown = (e: any) => {
  //   if (e.key === 'Backspace') {
  //     setDateInputValue(prev => prev.slice(0, -1));
  //     setValue(e.target.name, e.target.value.slice(0, -1));
  //   }
  // };



  return (
    <div>
      {formKeys.map(
        ({ allowImages, allowPdfs, allowAudio, allowVideo, allowExcel, maxFileSize, name, type,
          showCamera, policyId, title, subTitle, applicationId, documentTypeId, profileId,
          documentUploadForm, hidden, onChange, mandatory, maxlength, values, value,
          placeholder, trigger, disabled, inpDeleteFunction, inpUploadFunction }: any, index) => {
          if (readonly) {

            let value = getValues(name) || '-';

            if (type === 'text/combo') {
              const item = values.find((item: { value: any; }) => item.value === getValues(name));
              value = item ? item.description : "-";
            }
            if (type === 'date' && value !== '-') {
              value = format(value, 'dd/MM/yyyy');
            }
            return (

              <IonItem key={`name${index}`}>
                <IonLabel>{placeholder}</IonLabel>
                <IonText style={{ textAlign: 'right' }}>{value}</IonText>
              </IonItem>
            );


          } else {
            if (type === 'text/combo') {

              return (
                <div hidden={hidden} key={`name${index}`}>
                  <ChoiceChip key={`${name}-${index}`}
                    //isValid={isValid}
                    name={name} options={values}
                    error={errors[name]}
                    values={getValues(name)}
                    setValueFunc={setValue}
                    onChange={onChange}
                    title={placeholder} mandatory={mandatory} />
                </div>
                //   <ChoiceChip key={name}
                //     name={name} options={values}
                //     values={getValues(name)}
                //     inpCallback={setValue}

                //     title={placeholder} mandatory={mandatory} errorMessage={errors[name]?.message} />
              );
            }
            else if (type === 'text/multicombo') {
              return (
                <div hidden={hidden} key={`name${index}`}>
                  <MultiChoiceChip key={`${name}-${index}`}
                    //isValid={isValid}
                    name={name} options={values}
                    error={errors[name]}
                    values={getValues(name)}
                    setValueFunc={setValue}
                    onChange={onChange}
                    title={placeholder} mandatory={mandatory} />
                </div>
                //   <ChoiceChip key={name}
                //     name={name} options={values}
                //     values={getValues(name)}
                //     inpCallback={setValue}

                //     title={placeholder} mandatory={mandatory} errorMessage={errors[name]?.message} />
              );
            }
            else if (type == 'readonly') {

              return (<div hidden={hidden} key={`name${index}`}>
                {/* <IonItem> */}
                <IonLabel>{placeholder}:</IonLabel>
                <IonText style={{ textAlign: 'left', paddingLeft: '8px' }}>{getValues(name)}</IonText>
                {/* </IonItem> */}
              </div>);


            }


            else if (type == 'docupload') {
              return (<div hidden={hidden} key={`name${index}`}>
                <UploadWidget
                  key={`${name}`}
                  showCamera={showCamera}
                  getValuesFunc={getValues}
                  policyId={policyId}
                  setValueFunc={setValue}
                  title={title}
                  value={getValues[name]}
                  applicationId={applicationId}
                  documentTypeId={documentTypeId}
                  profileId={profileId}
                  subTitle={subTitle}
                  allowImages={allowImages}
                  allowPdfs={allowPdfs}
                  allowAudio={allowAudio}
                  allowVideo={allowVideo}
                  allowExcel={allowExcel}
                  maxFileSize={maxFileSize}
                  error={errors[name] as any}
                  name={name}
                  inpUploadFunction={inpUploadFunction}
                  inpDeleteFunction={inpDeleteFunction}
                  mandatory={mandatory} />
              </div>);

            }

            else if (type == 'textarea') {

              return (<div hidden={hidden} key={`name${index}`}>
                <Controller
                  key={`${name}-${index}`}
                  control={control}
                  render={({ field: { onChange, onBlur, name } }) => (
                    <IonTextarea
                      key={name}
                      className='border border-1'
                      name={name}
                      id={name}
                      fill='outline'
                      placeholder={t(`${placeholder}`).toString()}
                      maxlength={300}
                      label=''
                      rows={3}
                      mode='md'
                    ></IonTextarea>
                  )}
                  name={name}
                  rules={{ maxLength: 10 }}
                />
              </div>);


            }
            else if (type === 'date') {

              var stubVal = getValues(name);
              var formattedValue = '';

              if (stubVal !== undefined && stubVal !== null && stubVal !== '') {
                const date = new Date(stubVal);
                if (date instanceof Date) {
                  formattedValue = format(date, 'yyyy-MM-dd');
                } else {
                  console.warn('stubVal is not a valid Date object:', date);
                }
              }

              return (<div hidden={hidden} key={`name${index}`}>
                <Controller
                  key={`${name}-${index}`}
                  control={control}
                  render={({ field: { onChange, onBlur, name } }) => (
                    <CustomInput
                      type={type}
                      name={name}
                      maxLength={maxlength}
                      needSuffixIcon={true}
                      suffixClassName={
                        'position-absolute date-of-birth-sufix'
                      }
                      suffixText={
                        <IonImg
                          src='/images/icons/input-dob-calendar-icon.svg'
                          alt='date'
                        />
                      }
                      outerClassName={'position-relative'}
                      onBlur={
                        (e: any) => { onChange(e) }
                      }
                      // onChange={onChange}
                      errors={t(errors[name]?.message)}
                      //errors={t(errorText[name]?.message)}
                      defaultValue={value}
                      onChange={(e: any) => {
                        // onChange(e.detail.value); // Make sure to update the form state
                        setValue(name, e.detail.value);
                      }}
                      // value={formattedValue || value} // Fallback to React Hook Form's managed value if needed
                      // value={formattedValue || value || ''}
                      value={
                        name === "dob"
                          ? formattedValue || value || ""
                          : getValues(name) || ""
                      }
                      // value={getValues(name)}
                      // value={getValues(name) != undefined ? format(getValues(name), 'yyyy-MM-dd') : undefined}

                      // value={value}
                      // value={getValues(name)?FormatDate(getValues(name),'dd/mm/yyyy'):undefined}
                      placeholder={placeholder}
                    // onChange={handleDateInputChange} 
                    // value={dateInputValue}
                    // onKeyDown={handleDateInputKeyDown}
                    />
                  )}
                  name={name}
                  rules={{ maxLength: 10 }}
                />
              </div>);
            }

            else {
              return (
                <div hidden={hidden} key={`name${index}`}>
                  <Controller
                    key={`${name}-${index}`}
                    control={control}
                    render={({ field: { onChange, onBlur, name } }) => (
                      <CustomInput
                        name={name}
                        id={name}
                        type={type}
                        placeholder={t(`${placeholder}`)}
                        onBlur={onBlur}
                        onChange={onChange}
                        maxLength={maxlength}
                        value={getValues(name)}
                        errors={errors[name]?.message}
                        disabled={disabled}
                        hidden={hidden}
                        onBeforeInput={(evt: any) => {
                          if (type == 'number') {
                            !/[0-9]/.test(evt.data) && evt.preventDefault();
                          }
                          if (name.toLowerCase().includes('mobile')) {
                            if (evt.target.value.length === 10) {
                              evt.preventDefault();
                            }
                          }
                        }}
                        onPaste={(e: any) => {
                          //Only get numbers on user paste
                          if (name.toLowerCase().includes('mobile')) {
                            e.preventDefault();
                            let pasteContent = e.clipboardData.getData('Text');
                            if (pasteContent != '') {
                              pasteContent = pasteContent.replace(/\D/g, '');
                              if (pasteContent.length > 10) {
                                pasteContent = pasteContent.slice(0, 10);
                              }
                            }
                            setValue(name, pasteContent);
                            trigger(name);
                          }
                        }}
                        onKeyDown={(e: any) => {
                          if (type == 'number') {
                            disableNumberArrows(e);
                          }
                        }}
                        onWheel={(e: any) => {
                          if (type == 'number') {
                            e.target.blur();
                          }
                        }}
                        className={`
                    ${name.toLowerCase().includes(
                          'name'
                        )
                            // ${['first_name', 'middle_name', 'last_name'].includes(
                            //   name
                            // )
                            ? 'text-capitalize '
                            : ''
                          } ${name.toLowerCase().includes(
                            'pan'
                          )
                            // ${['first_name', 'middle_name', 'last_name'].includes(
                            //   name
                            // )
                            ? 'text-uppercase '
                            : ''
                          }`}
                      // maxLength={10}
                      />
                    )}
                    name={name}
                    rules={{ maxLength: 10 }}
                  />
                </div>
              );
            }
          }


        }
      )}
    </div>
  );
}
export default CustomForm;