import { IonContent, IonFooter } from '@ionic/react';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory, useLocation } from 'react-router';
import {
	apiGetLeadDetails,
	getLeadUmDetails,
	saveLenderSpcfInfo,
} from '../../../../store/reducers/lead/leadSlice';
import { CustomInput } from '../../../../components/common/custom-input/CustomInput';
import {
	ContinueLaterPopUp,
	checkFieldsValidToContinue,
	sanitizeHtml,
	showToastAlert,
} from '../../../../helpers/functions';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import { PersonalFormFields } from './PLForms';
import { LenderNameForAPICall } from '../../../../helpers/lenders';
import { CustomModalLeadStatus } from '../../../../components/common';
import { OfferRejectedMsg } from '../../OfferRejectedMsg/OfferRejectedMsg';
import { getCompanyNameList } from '../../../../store/reducers/user/userSlice';
import { PAGINATION_DETAILS } from '../../../../helpers/constant';
import CustomPopoverSearch from '../../../../components/common/custom-popover/CustomPopoverSearch';
import '../Prefr.scss';

export const PersonalInfoForm: React.FC<any> = () => {
	const [modalPageClose, setModalPageClose] = useState(false);
	const [leadStatusModalOpen, setLeadStatusModalOpen] = useState(false);
	const [openCompanyModal, setOpenCompanyModal] = useState(false);
	const [companyList, setCompanyList] = useState([]);
	const [applicationNo, setApplicationNo] = useState(null);
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const [disableFields, setDisableFields] = useState({
		netMonthlyIncome: false,
		compnayName: false,
	});
	const history = useHistory();
	const dispatch = useDispatch();
	const { selectedLender, lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);
	const location = useLocation<any>();

	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		trigger,
		reset,
		formState: { errors, isValid },
	} = useForm({
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			first_name: '',
			middle_name: '',
			last_name: '',
			email: '',
			mobile_no: '',
			dob: '',
			pan: '',
			pincode: '',
			loan_amount: '',
			net_monthly_income: '',
			company_name: '',
		},
	});

	useEffect(() => {
		if (location.pathname !== '/lead-lender-specific-form-steps') {
			return;
		}
		getLeadData();
	}, [location.pathname]);

	/**
	 * Get lenders form data
	 */
	const getLeadData = async () => {
		let netMonthlyIncome: any = '';
		let companyName: any = '';
		await dispatch(apiGetLeadDetails({ param: leadFlowData?.leadId }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					netMonthlyIncome =
						result?.leads?.professional_details?.['Net Monthly Income'];
					companyName = result?.leads?.professional_details?.['Company Name'];
				}
			});
		await dispatch(getLeadUmDetails({ param: leadFlowData?.leadId }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					reset({
						first_name: result?.data.first_name,
						middle_name: result?.data.middle_name,
						last_name: result?.data.last_name,
						email: result?.data.email,
						mobile_no: result?.data.mobile_no,
						dob: result?.data.dob,
						pan: result?.data.pan,
						pincode: result?.data.pincode,
						loan_amount: result?.data.loan_amount,
						net_monthly_income:
							lenderSpecificDetails?.lender_spf_details?.net_monthly_income ||
							netMonthlyIncome,
						company_name:
							lenderSpecificDetails?.lender_spf_details?.company_name ||
							companyName,
					});

					setDisableFields({
						netMonthlyIncome: netMonthlyIncome ? true : false,
						compnayName: companyName ? true : false,
					});
				}
			});
	};

	/**
	 * Get company list
	 * @param searchValue search text
	 */
	const getCompanyList = async (searchValue: any) => {
		await dispatch(
			getCompanyNameList({
				param: `?searchItem=${sanitizeHtml(
					encodeURIComponent(searchValue)
				)}&page=${PAGINATION_DETAILS.CURRENT_PAGE}&size=${
					PAGINATION_DETAILS.PAGE_SIZE
				}`,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setCompanyList(response.companyListData);
				}
			});
	};

	/**
	 * On submit to save user data and after that
	 */
	const onSubmit = async (data: any, flag?: any) => {
		const req: any = {
			lender_spf_details: {
				...lenderSpecificDetails?.lender_spf_details,
				net_monthly_income: data.net_monthly_income,
				company_name: data.company_name,
			},
			step: 'step1',
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.PREFR_PL,
			isNewLead: isValid && flag !== 'continue_later',
		};

		if (lenderSpecificDetails?.lead_spf_detail_id) {
			req.lead_spf_detail_id = lenderSpecificDetails?.lead_spf_detail_id;
		}
		setShowOfferErrMsg('');
		await dispatch(saveLenderSpcfInfo({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					if (req.isNewLead) {
						const appNo: any = `Application ID: ${result?.data?.andro_id}\nPrefr Journey URL: ${result?.data?.url}`;
						setApplicationNo(appNo);
						setLeadStatusModalOpen(true);
					}
				} else {
					setShowOfferErrMsg(result?.message);
				}
			});
	};

	/**
	 * Continue Later and Submit button
	 */
	const continueLaterAndSubmitBtn = () => {
		return (
			<>
				<CustomButton
					className='w-100 fw-bold dashboard-add-lead-button mb-3'
					expand='block'
					fill='outline'
					title={t('Continue Later')}
					onClick={() => {
						if (checkFieldsValidToContinue(errors)) {
							setModalPageClose(true);
						} else {
							showToastAlert({
								type: 'error',
								message: 'Please fix above errors',
							});
						}
					}}
				/>
				<CustomButton
					type='submit'
					className='w-100 fw-bold dashboard-add-lead-button'
					expand='block'
					title={t('Proceed')}
					disabled={!isValid}
					onClick={handleSubmit(onSubmit)}
				/>
			</>
		);
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
							<form onSubmit={handleSubmit(onSubmit)}>
								{PersonalFormFields.map(
									(
										{ name, type, placeholder, isDisabled }: any,
										index: any
									) => {
										const errorText: any =
											errors && Object.keys(errors).length > 0 && errors;
										return (
											<div key={index}>
												<Controller
													control={control}
													render={({ field: { onChange, onBlur, name } }) => (
														<CustomInput
															type={type}
															disabled={
																name === 'net_monthly_income'
																	? disableFields?.netMonthlyIncome
																	: name === 'company_name'
																	? disableFields?.compnayName
																	: isDisabled
															}
															maxLength={
																name === 'net_monthly_income' ? 15 : null
															}
															label={t(placeholder)}
															onBlur={onBlur}
															id={name}
															onChange={(e: any) => {
																onChange(e);
																if (name === 'company_name') {
																	setOpenCompanyModal(true);
																}
															}}
															onFocus={() => {
																if (name === 'company_name') {
																	setOpenCompanyModal(true);
																}
															}}
															value={getValues(name)}
															errors={t(errorText[name]?.message || '')}
														/>
													)}
													name={name}
												/>

												{name === 'company_name' && (
													<div className='mb-3'>
														<CustomPopoverSearch
															searchApi={getCompanyList}
															list={companyList}
															setList={setCompanyList}
															isOpen={openCompanyModal}
															searchListModalClose={setOpenCompanyModal}
															title={t('Company Names')}
															searchbarPlaceholder={t(
																'Search Company by “Name”'
															)}
															customNoDataMessage={t(
																'Search by your company name'
															)}
															setValue={(val: any) => {
																setValue('company_name', val);
																trigger('company_name');
															}}
														/>
													</div>
												)}
											</div>
										);
									}
								)}
							</form>

							<div className={`d-none d-md-block mt-4`}>
								{continueLaterAndSubmitBtn()}
							</div>
						</div>
					</div>
				)}
			</IonContent>

			{!showOfferErrMsg && (
				<IonFooter className='d-block d-md-none position-relative ion-padding lead_ftr_btn'>
					{continueLaterAndSubmitBtn()}
				</IonFooter>
			)}

			<CustomModal
				needIonContentWraper={false}
				isOpen={modalPageClose}
				initialBreakpoint={undefined}
				handleClose={() => setModalPageClose(false)}
				breakpoints={[]}
				className='height-auto confirm-popup-middle continue-later'
				modalContent={ContinueLaterPopUp(
					() => {
						setModalPageClose(false);
					},
					() => {
						new Promise(function (myResolve) {
							setTimeout(function () {
								myResolve(history.push('/dashboard/lead'));
								onSubmit(getValues(), 'continue_later');
								setModalPageClose(false);
							}, 10);
						});
					},
					'Cancel',
					'Continue Later',
					'className',
					'Are You Sure You Want to Exit?',
					`${t('You can continue later from where you left off.')}`
				)}
				needCross={false}
				needArrow={false}
			/>

			{leadStatusModalOpen && (
				<CustomModalLeadStatus
					isOpen={leadStatusModalOpen}
					applicationNo={applicationNo}
					handleClose={() => setLeadStatusModalOpen(false)}
				/>
			)}
		</>
	);
};
