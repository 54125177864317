import { IonCol, IonGrid, IonImg, IonRow, isPlatform } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import { moodleLink } from '../../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';

export const KnowledgeCenter: React.FC = () => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const history = useHistory();

	const getMoodleLink = async () => {
		await dispatch(moodleLink())
			.then(unwrapResult)
			.then(async (response: any) => {
				if (response.status) {
					if (isPlatform('ios')) {
						// open in safari (iOs)
						window?.open(response?.url, '_self');
					} else {
						history.push('/training-sessions');
					}
				}
			});
	};

	return (
		<section className='knowledge-center border-md-radius-10 mb-4 mb-md-2'>
			<IonGrid class='ion-no-padding'>
				<IonRow className='align-items-start align-items-md-center'>
					<IonCol size='8'>
						<div className='pt-4'>
							<h2 className='m-0 fs-16 pb-1 fw-600 darkest-grey'>
								{t('Knowledge Center')}
							</h2>
							<p className='m-0 fs-12 text-grey pb-2'>
								{t('Stay updated with our knowledge center.')}
							</p>
							<CustomButton
								className='btn-blue fs-14 fw-600'
								fill='outline'
								title={t('Explore Videos')}
								onClick={() => {
									// history.push('/training-sessions');
									getMoodleLink();
								}}
							/>
						</div>
					</IonCol>
					<IonCol size='4'>
						<IonImg
							className='pe-0 pe-md-4 d-none d-md-block ml-4'
							src='/images/icons/Desktop-Image.png'
						/>
						<IonImg
							className='pe-0 pe-md-4 d-block d-md-none'
							src='/images/icons/knowledge-center-right-video-image.png'
						/>
					</IonCol>
				</IonRow>
			</IonGrid>
		</section>
	);
};
