import { useEffect, useState } from 'react';
import { IonContent, IonFooter } from '@ionic/react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router';
import { t } from 'i18next';
import {
	ContinueLaterPopUp,
	checkFieldsValidToContinue,
	showToastAlert,
} from '../../../../helpers/functions';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import { OfferRejectedMsg } from '../..';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	lenderSpfDetailsFlow,
	saveLenderSpcfInfo,
} from '../../../../store/reducers/lead/leadSlice';
import {
	bindFieldConditionally,
	primaryAppFormDefaultValues,
	formSchemaConditionally,
} from './HLLAPInfo';
import { LenderNameForAPICall } from '../../../../helpers/lenders';
import { PrimaryApplicantAllForm } from './PrimaryApplicantAllForm';
import { CustomModalLeadStatus } from '../../../../components/common';

export const PrimaryApplicantForms: React.FC<any> = ({
	isCoApplicant,
	setSelectedTabValue,
	setCoApplicant,
	leadInfo,
}) => {
	const [currentPage, setCurrentPage] = useState<any>(1);
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const [modalPageClose, setModalPageClose] = useState(false);
	const [leadStatusModalOpen, setLeadStatusModalOpen] = useState(false);
	const [pinCodeDataError, setPinCodeDataError] = useState<any>({
		property_pincode: '',
		curr_pincode: '',
	});
	const [applicationNo, setApplicationNo] = useState('');
	const dispatch = useDispatch();
	const history = useHistory();
	const { selectedLender, lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);

	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		trigger,
		reset,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(
			formSchemaConditionally(`applicantSchema${currentPage}`)
		),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: primaryAppFormDefaultValues(
			currentPage,
			lenderSpecificDetails,
			leadInfo
		),
	});

	useEffect(() => {
		const lenderSpfDtls = lenderSpecificDetails?.lender_spf_details;
		if (!lenderSpfDtls?.is_co_applicant) {
			setValue('is_co_applicant', isCoApplicant);
			trigger('is_co_applicant');
		}
	}, []);

	/**
	 * Show form pagination
	 */
	const primaryAppFormPage = () => {
		const lenderSpfDtls = lenderSpecificDetails?.lender_spf_details;
		const formPageView: any = [];
		for (let i = 1; i <= 3; i++) {
			formPageView.push(
				<span
					key={i}
					className={currentPage === i ? 'active_page' : ''}
					onClick={() => {
						if (lenderSpfDtls?.validAppFormCount >= i) {
							setCurrentPage(i);
							reset({});
						}
					}}
				>
					{i}
				</span>
			);
		}
		return formPageView;
	};

	const setRequest = (data: any, flag?: any) => {
		let docsName: any = [];
		let setDocs: any = {};

		if (currentPage === 3 && data.is_income_earner === true) {
			if (
				data.profile_segment_type_name === 'Assessed Income' ||
				data.profile_segment_type_name === 'Regular ITR'
			) {
				if (!data?.bank_statement_12_months_doc?.[0]?.file) {
					showToastAlert({
						type: 'error',
						message: '12 Months Bank Statement is required',
					});
					return;
				}
				if (!data?.last_2_years_itr_doc?.[0]?.file) {
					showToastAlert({
						type: 'error',
						message: 'Last 2 Years ITR is required',
					});
					return;
				}
				if (!data?.business_vintage_proof_doc?.[0]?.file) {
					showToastAlert({
						type: 'error',
						message: 'Business Vintage Proof is required',
					});
					return;
				}
				docsName = [
					'bank_statement_12_months_doc',
					'last_2_years_itr_doc',
					'business_vintage_proof_doc',
				];
			} else if (
				data.profile_segment_type_name === 'Cash Salary' ||
				data.profile_segment_type_name === 'Bank Salary'
			) {
				if (!data?.latest_salary_slip_doc?.[0]?.file) {
					showToastAlert({
						type: 'error',
						message: 'Latest Salary Slip is required',
					});
					return;
				}
				if (
					data.profile_segment_type_name === 'Bank Salary' &&
					Number(data.monthly_gross_income) >= 25000 &&
					!data?.form_16_doc?.[0]?.file
				) {
					showToastAlert({
						type: 'error',
						message: 'Form 16 is required',
					});
					return;
				}
				if (!data?.bank_statement_6_months_doc?.[0]?.file) {
					showToastAlert({
						type: 'error',
						message: '6 Months Bank Statement is required',
					});
					return;
				}
				docsName = [
					'latest_salary_slip_doc',
					'form_16_doc',
					'bank_statement_6_months_doc',
				];
			}

			docsName.forEach((ele: any) => {
				setDocs = {
					...setDocs,
					[ele]: [],
				};
				data?.[ele]?.forEach((el: any) => {
					if (el.file) {
						setDocs[ele] = [...setDocs[ele], { file: el.file }];
					}
				});
			});
		} else if (currentPage === 2) {
			[
				'address_proof_doc',
				'kyc_aadhar_front_doc',
				'kyc_aadhar_back_doc',
				'kyc_pan_doc',
			].forEach((ele: any) => {
				setDocs = {
					...setDocs,
					[ele]: [],
				};
				data?.[ele]?.forEach((el: any) => {
					if (el.file) {
						setDocs[ele] = [...setDocs[ele], { file: el.file }];
					}
				});
			});
		}

		const formDatas: any = {};
		const fieldsList: any = bindFieldConditionally(
			currentPage,
			data,
			'applicant'
		);
		fieldsList.forEach((el: any) => {
			if (data[el.name] || data[el.name] === false) {
				formDatas[el.name] = data[el.name];
			}
		});

		if (currentPage === 1) {
			formDatas['sales_officer_contact'] = data['sales_officer_contact'];
		}
		if (currentPage === 2) {
			formDatas['address_proof_document_id'] =
				data['address_proof_document_id'];
			formDatas['address_proof_document_name'] =
				data['address_proof_document_name'];
		} else if (currentPage === 3) {
			formDatas['profile_segment_type_name'] =
				data['profile_segment_type_name'];
		}

		const req: any = {
			lender_spf_details: {
				...lenderSpecificDetails?.lender_spf_details,
				...formDatas,
				...setDocs,
			},
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.DMI_HL_LAP,
			step: 'step1',
			isNewLead:
				currentPage == 3 &&
				isValid &&
				flag !== 'continue_later' &&
				!isCoApplicant,
			// isNewLead: false,
		};

		if (req?.lender_spf_details?.validAppFormCount !== 3) {
			if (isValid || flag === 'continue_later') {
				if (currentPage !== 3) {
					req.lender_spf_details.validAppFormCount = currentPage + 1;
				} else {
					req.lender_spf_details.validAppFormCount = currentPage;
				}
			}
		}

		if (lenderSpecificDetails?.lead_spf_detail_id) {
			req.lead_spf_detail_id = lenderSpecificDetails?.lead_spf_detail_id;
		}

		if (data?.is_co_applicant === false) {
			delete req?.lender_spf_details?.co_applicant;
		}
		return req;
	};

	/**
	 * On submit to save user data and after that
	 * @param data Form data
	 */
	const onSubmit = async (data: any, flag?: any) => {
		const req = setRequest(data, flag);
		if (!req) {
			return;
		}
		setShowOfferErrMsg('');
		await dispatch(saveLenderSpcfInfo({ data: req }))
			.then(unwrapResult)
			.then((res: any) => {
				if (res?.status) {
					dispatch(
						lenderSpfDetailsFlow({
							...lenderSpecificDetails,
							lender_spf_details: res.data
								? res.data.lender_spf_details
								: res.body.lender_spf_details,
						})
					);

					if (currentPage === 3 && isCoApplicant) {
						reset({});
						setSelectedTabValue('CoApplicantForm0');
					} else if (currentPage === 3 && req.isNewLead) {
						const appNo: any = `Application No: ${res.body?.lender_spf_details?.applicationNumber}\nLoan Offer Amount: ${res.body?.lender_spf_details?.loanOfferAmount} *`;
						setApplicationNo(appNo);
						setLeadStatusModalOpen(true);
					} else if (currentPage < 3) {
						setCoApplicant(res?.data?.lender_spf_details?.is_co_applicant);
						setCurrentPage(currentPage + 1);
					}
				} else {
					dispatch(
						lenderSpfDetailsFlow({
							...lenderSpecificDetails,
							lender_spf_details:
								res?.body &&
								res?.body?.lender_spf_details &&
								Object.keys(res?.body?.lender_spf_details)?.length > 0
									? res?.body?.lender_spf_details
									: req.lender_spf_details,
						})
					);
					setShowOfferErrMsg(res?.message);
				}
			});
	};

	/**
	 * Continue Later and Submit button
	 */
	const continueLaterAndSubmitBtn = () => {
		return (
			<>
				<CustomButton
					className='w-100 fw-bold dashboard-add-lead-button mb-3'
					expand='block'
					fill='outline'
					title={t('Continue Later')}
					onClick={() => {
						if (checkFieldsValidToContinue(errors)) {
							setModalPageClose(true);
						} else {
							showToastAlert({
								type: 'error',
								message: 'Please fix above errors',
							});
						}
					}}
				/>
				<CustomButton
					type='submit'
					className='w-100 fw-bold dashboard-add-lead-button'
					expand='block'
					title={t(
						currentPage === 3 && !isCoApplicant ? 'Proceed' : 'Save & Continue'
					)}
					disabled={
						!isValid ||
						!!pinCodeDataError.property_pincode ||
						!!pinCodeDataError.curr_pincode
					}
					onClick={handleSubmit(onSubmit)}
				/>
			</>
		);
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 pt-3 p-md-5 border-radius-10'>
							<form onSubmit={handleSubmit(onSubmit)}>
								{currentPage === 3 && (
									<div className='text-red mb-3'>
										<small>
											Note: Minimun 1 Income Earner is required from Primary
											Applicant OR Co-Applicant
										</small>
									</div>
								)}
								<PrimaryApplicantAllForm
									control={control}
									errors={errors}
									getValues={getValues}
									setValue={setValue}
									trigger={trigger}
									reset={reset}
									isValid={isValid}
									currentPage={currentPage}
									isCoApplicant={isCoApplicant}
									setPinCodeDataError={setPinCodeDataError}
									pinCodeDataError={pinCodeDataError}
									leadInfo={leadInfo}
								/>
								<div className='co_app_form_pagination'>
									<div className='page_item'>{primaryAppFormPage()}</div>
								</div>
							</form>

							<div className={`d-none d-md-block mt-4`}>
								{continueLaterAndSubmitBtn()}
							</div>
						</div>
					</div>
				)}
			</IonContent>

			{!showOfferErrMsg && (
				<IonFooter className='d-block d-md-none position-relative ion-padding lead_ftr_btn'>
					{continueLaterAndSubmitBtn()}
				</IonFooter>
			)}

			{leadStatusModalOpen && (
				<CustomModalLeadStatus
					isOpen={leadStatusModalOpen}
					applicationNo={applicationNo}
					handleClose={() => setLeadStatusModalOpen(false)}
				/>
			)}

			<CustomModal
				needIonContentWraper={false}
				isOpen={modalPageClose}
				initialBreakpoint={undefined}
				handleClose={() => setModalPageClose(false)}
				breakpoints={[]}
				className='height-auto confirm-popup-middle continue-later'
				modalContent={ContinueLaterPopUp(
					() => {
						setModalPageClose(false);
					},
					() => {
						new Promise(function (myResolve) {
							setTimeout(function () {
								myResolve(history.push('/dashboard/lead'));
								onSubmit(getValues(), 'continue_later');
								setModalPageClose(false);
							}, 10);
						});
					},
					'Cancel',
					'Continue Later',
					'className',
					'Are You Sure You Want to Exit?',
					`${t('You can continue later from where you left off.')}`
				)}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
