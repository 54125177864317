import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
// import { Header } from '../../components/layout/header/Header';
import {
	NativeSettings,
	AndroidSettings,
	IOSSettings,
} from 'capacitor-native-settings';

import { CustomModal } from '../../components/common/custom-modal/CustomModal';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import {
	IonCol,
	IonContent,
	IonItem,
	IonRow,
	IonSpinner,
	isPlatform,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import '../dashboard/Dashboard.scss';
import { Player } from '@lottiefiles/react-lottie-player';
import { AddLeadVideoCard } from './AddLeadVideoCard';
import { useHistory } from 'react-router';
import {
	// bulkUploadNewLead,
	bulkUploadDisbursedNewLeadErrorEntries,
	// bulkUploadNewLeadTemplate,
	downloadBulkLeadUploadTemplate,
	leadFlow,
	uploadBulkNewLead,
	// bulkUploadNewLeadErrorEntries,
	getLeadFlowUrl,
	leadCreationtype,
} from '../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	DownloadFileBlob,
	DownloadFileInApp,
	showToastAlert,
	ACL,
	ShareData,
	notifyHandler,
	eventAttributes,
} from '../../helpers/functions';
// import { Header } from '../../components/layout/header/Header';
import { Sidebar } from '../../components/layout/sidebar/Sidebar';
import { Header } from '../../components/layout/header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { imgUrlUpdate } from '../../store/reducers/user/userSlice';
import { Menu } from '../../components/layout/menu/Menu';
import { Divider } from '../../components/common/divider/Divider';
import { ACLKeys } from '../../helpers/constant';
import { LocalNotifications } from '@capacitor/local-notifications';
import { FileOpener } from '@capacitor-community/file-opener';
import { PushNotifications } from '@capacitor/push-notifications';
import { NotifyVisitors } from 'capacitor-plugin-notifyvisitors';

const defaultSrc = '/images/icons/default/user-profile-icon.svg';

export const AddLead: React.FC = () => {
	const [bulkUploadModal, setBulkUploadModal] = useState(false);
	const { t } = useTranslation();
	const history = useHistory();
	const homePageData: any = useSelector((state: any) => state?.users);
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	const [src, setSrc] = useState('');
	const dispatch: any = useDispatch();
	const appWeb = useSelector((state: any) => state?.app?.isWeb);
	const permissions = useSelector(
		(state: any) => state?.users?.user_details?.permissions
	);
	const [showNotificationModel, setShowNitificationModel] = useState(false);
	const [count, setCount] = useState(0);
	const [errorCount, setErrorCount] = useState(0);
	// Notify Visitors
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const userName = useSelector(
		(state: any) => state?.users?.homepage?.profile?.name
	);
	const userMobile = useSelector(
		(state: any) => state?.users?.user_details?.mobile
	);
	const { leadcreatedthrough } = useSelector((state: any) => state?.lead);

	const entityType = useSelector(
		(state: any) => state?.users?.user_details?.entityType
	);

	const userLang = useSelector(
		(state: any) => state?.oneTimeScreen?.language?.name
	);

	const userAttributes = {
		attributes: {
			name: userName,
			id: userId,
			mobile: userMobile,
			user_language: userLang,
			entityType:
				entityType === 'Individual'
					? 'DMA'
					: entityType === 'BTM'
					? 'employee'
					: entityType,
		},
	};
	useEffect(() => {
		getUpdateUrl();
		dispatch(leadFlow({}));
		if (isPlatform('android') || isPlatform('ios')) {
			notifyHandler('event', eventAttributes('bulk_upload_lead_page'));
			notifyHandler('user', userAttributes.attributes);
			const inAppData = {
				tokens: { name: userAttributes },
				customRules: { banerrType: 'bulk upload page' },
			};
			NotifyVisitors.show(inAppData);
		} else {
			(window as any).nv('event', 'bulk_upload_lead_page', 10, 1);
			(window as any).nv('user', userId, userAttributes.attributes);
		}
	}, []);

	const [spinner, setSpinner] = useState(false);

	useEffect(() => {
		LocalNotifications.addListener('localNotificationReceived', (data: any) => {
			if (data) {
				setSpinner(false);
			}
		});
		LocalNotifications.addListener(
			'localNotificationActionPerformed',
			(payload: any) => {
				const route = payload.notification.extra.route;
				FileOpener.open({
					filePath: route,
				});
			}
		);
		return () => {
			LocalNotifications.removeAllListeners();
		};
	}, []);

	const BulkUploadModal = () => {
		const { t } = useTranslation();
		const dispatch = useDispatch();
		const uploadNewLeadInputRef =
			useRef<HTMLInputElement | null>() as MutableRefObject<HTMLInputElement>;

		// const newLeadUploadRef =
		// 	useRef<HTMLInputElement | null>() as MutableRefObject<HTMLInputElement>;
		const registerNotifications = async () => {
			const permStatus = await PushNotifications.checkPermissions();

			if (permStatus.receive == 'denied') {
				setShowNitificationModel(true);
				setSpinner(false);
			} else if (permStatus.receive == 'prompt-with-rationale') {
				setShowNitificationModel(true);
				setSpinner(false);
			} else {
				setShowNitificationModel(false);
			}
		};

		const downloadBulkLeadTemplate = async () => {
			await dispatch(downloadBulkLeadUploadTemplate())
				.then(unwrapResult)
				.then(async (response: any) => {
					if (response.status) {
						showToastAlert({ type: 'success', message: 'Download Initiated' });
						if (isPlatform('mobileweb')) {
							DownloadFileBlob(
								response.data,
								'disbursed_lead_template',
								'xlsx'
							);
						} else if (
							isPlatform('android') ||
							isPlatform('ios') ||
							isPlatform('ipad') ||
							isPlatform('iphone')
						) {
							setSpinner(true);
							registerNotifications();

							DownloadFileInApp(
								response.data,
								'disbursed_lead_template',
								'xlsx'
							);
						} else {
							DownloadFileBlob(
								response.data,
								'disbursed_lead_template',
								'xlsx'
							);
						}
					}
				});
		};

		const handleFileUploadChange = async (event: any) => {
			setBulkUploadModal(false);
			const formData: any = new FormData();
			formData.append('bulkUpload', event.target.files[0]);
			await dispatch(
				uploadBulkNewLead({
					data: formData,
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						setCount(response.total_inserted_row);
						setErrorCount(response.total_error_rows);
						showToastAlert({
							type: 'success',
							message: `${response?.message} Total Success Count ${response?.total_inserted_row} And Total Failed Count ${response?.total_error_rows}`,
						});
					}
					if (response?.status && Object.keys(response?.errorData).length) {
						setTimeout(() => {
							dispatch(
								bulkUploadDisbursedNewLeadErrorEntries({
									param: response?.uuid,
								})
							)
								.then(unwrapResult)
								.then(async (errApiResponse: any) => {
									if (errApiResponse?.status) {
										showToastAlert({
											type: 'success',
											message: errApiResponse?.message,
										});
										if (isPlatform('mobileweb')) {
											DownloadFileBlob(
												errApiResponse.data,
												'disbursed_lead_error_entries',
												'xlsx'
											);
										} else if (
											isPlatform('android') ||
											isPlatform('ios') ||
											isPlatform('ipad') ||
											isPlatform('iphone')
										) {
											setSpinner(true);
											registerNotifications();

											DownloadFileInApp(
												errApiResponse.data,
												'disbursed_lead_error_entries',
												'xlsx'
											);
										} else {
											DownloadFileBlob(
												errApiResponse.data,
												'disbursed_lead_error_entries',
												'xlsx'
											);
										}
									}
								});
						}, 7000);
					}
				});
			// reset file input
			event.target.value = null;
			setBulkUploadModal(false);
		};

		// const downloadNewLeadTemplate = async () => {
		// 	await dispatch(bulkUploadNewLeadTemplate())
		// 		.then(unwrapResult)
		// 		.then(async (response: any) => {
		// 			if (response.status) {
		// 				if (isPlatform('mobileweb')) {
		// 					DownloadFileBlob(
		// 						response.data,
		// 						'bulk_upload_new_lead_template',
		// 						'xlsx'
		// 					);
		// 				} else if (
		// 					isPlatform('android') ||
		// 					isPlatform('ios') ||
		// 					isPlatform('ipad') ||
		// 					isPlatform('iphone')
		// 				) {
		// 					setSpinner(true);
		// 					registerNotifications();

		// 					DownloadFileInApp(
		// 						response.data,
		// 						'bulk_upload_new_lead_template',
		// 						'xlsx'
		// 					);
		// 				} else {
		// 					DownloadFileBlob(
		// 						response.data,
		// 						'bulk_upload_new_lead_template',
		// 						'xlsx'
		// 					);
		// 				}
		// 			}
		// 		});
		// };

		// const uploadNewLeadTemplate = async (event: any) => {
		// 	setBulkUploadModal(false);
		// 	const formData: any = new FormData();
		// 	formData.append('bulkUpload', event.target.files[0]);
		// 	await dispatch(
		// 		bulkUploadNewLead({
		// 			data: formData,
		// 		})
		// 	)
		// 		.then(unwrapResult)
		// 		.then((response: any) => {
		// 			if (response?.status) {
		// 				setCount(response.total_inserted_row);
		// 				setErrorCount(response.total_error_rows);
		// 				showToastAlert({
		// 					type: 'success',
		// 					message: `${response?.message} Total Success Count ${response?.total_inserted_row} And Total Failed Count ${response?.total_error_rows}`,
		// 				});
		// 			}
		// 			if (response?.status && Object.keys(response?.errorData).length) {
		// 				setTimeout(() => {
		// 					dispatch(
		// 						bulkUploadNewLeadErrorEntries({
		// 							param: response?.uuid,
		// 						})
		// 					)
		// 						.then(unwrapResult)
		// 						.then(async (errApiResponse: any) => {
		// 							if (errApiResponse?.status) {
		// 								if (isPlatform('mobileweb')) {
		// 									DownloadFileBlob(
		// 										errApiResponse.data,
		// 										'new_lead_error_entries',
		// 										'xlsx'
		// 									);
		// 								} else if (isPlatform('android') || isPlatform('ios')) {
		// 									setSpinner(true);
		// 									setShowNitificationModel(true);

		// 									const file: any = DownloadFileInApp(
		// 										errApiResponse.data,
		// 										'new_lead_error_entries',
		// 										'xlsx'
		// 									);
		// 									await LocalNotifications.schedule({
		// 										notifications: [
		// 											{
		// 												title: 'File Downloaded',
		// 												body: `Click To Open`,
		// 												id: 1,
		// 												schedule: { at: new Date(Date.now() + 1000 * 1) },
		// 												actionTypeId: '',
		// 												extra: {
		// 													route: file?.uri,
		// 												},
		// 											},
		// 										],
		// 									});
		// 								} else {
		// 									DownloadFileBlob(
		// 										errApiResponse.data,
		// 										'new_lead_error_entries',
		// 										'xlsx'
		// 									);
		// 								}
		// 							}
		// 						});
		// 				}, 7000);
		// 			}
		// 			setBulkUploadModal(false);
		// 		});
		// 	event.target.value = null;
		// };
		return (
			<div style={{ height: '80vh', overflowY: 'scroll' }}>
				<div
					className='px-3 py-4'
					style={{
						backgroundColor: '#F2F2F2',
					}}
				>
					<div className='fs-20 fw-bold'>{t('Bulk Upload')}</div>
					<div className='fs-14'>
						{t('Note : Use our Template to Bulk Upload Easily.')}
					</div>
				</div>
				<div
					className=''
					style={
						isWeb ? {} : { height: 'calc(100vh - 240px)', overflowY: 'auto' }
					}
				>
					{/* {ACL(permissions, ACLKeys.RAW_BULK_UPLOAD) && (
						<div className='p-3'>
							<div className='fs-16 fw-bold m-0 p-2'>{t('New Lead')}</div>
							<CustomButton
								className='btn-blue mb-3 mx-3'
								expand='block'
								fill={'outline'}
								onClick={() => downloadNewLeadTemplate()}
							>
								<img
									src='/images/icons/download-black.svg'
									alt='upload'
									className='me-2'
								/>
								{t('Download Template')}
							</CustomButton>

							<CustomButton
								onClick={() => newLeadUploadRef?.current?.click()}
								className='btn-blue mx-3'
								expand='block'
								fill={'solid'}
							>
								<img
									src='/images/icons/upload-white.svg'
									alt='upload'
									className='me-2'
								/>
								{t('Upload New Lead')}
							</CustomButton>
						
							<input
								type='file'
								style={{ display: 'none' }}
								accept='text/csv,application/csv,.csv,.xlsx,.xls'
								ref={newLeadUploadRef}
								onChange={uploadNewLeadTemplate}
							/>
						</div>
					)} */}
					<Divider className='padding-top-border' />
					{ACL(permissions, ACLKeys.DISBURSAL_BULK_UPLOAD) && (
						<div className='p-3'>
							<div className='fs-16 fw-bold m-0 p-2'>
								{t('Disbursed New Lead')}
							</div>
							<CustomButton
								className='btn-blue mb-3 mx-3'
								expand='block'
								fill={'outline'}
								onClick={() => downloadBulkLeadTemplate()}
							>
								<img
									src='/images/icons/download-black.svg'
									alt='upload'
									className='me-2'
								/>
								{t('Download Template')}
							</CustomButton>
							<CustomButton
								onClick={() => uploadNewLeadInputRef?.current?.click()}
								className='btn-blue mx-3'
								expand='block'
								fill={'solid'}
							>
								<img
									src='/images/icons/upload-white.svg'
									alt='upload'
									className='me-2'
								/>
								{t('Upload New Lead')}
							</CustomButton>
							{/* input file upoad on button click */}
							<input
								type='file'
								style={{ display: 'none' }}
								accept='text/csv,application/csv,.csv,.xlsx,.xls'
								ref={uploadNewLeadInputRef}
								onChange={handleFileUploadChange}
							/>
						</div>
					)}
					{/* <Divider className='padding-top-border' />
					<div className='p-3'>
						<div className='fs-16 fw-bold m-0 p-2'>
							{t('Existing Lead Status')}
						</div>
						<CustomButton
							className='btn-blue mb-3 mx-3'
							expand='block'
							fill={'outline'}
							disabled={true}
							onClick={() => downloadBulkLeadTemplate()}
						>
							<img
								src='/images/icons/download-black.svg'
								alt='upload'
								className='me-2'
							/>
							{t('Download Template')}
						</CustomButton>
						<CustomButton
							disabled={true}
							expand='block'
							className='btn-blue mx-3'
						>
							<img
								src='/images/icons/upload-white.svg'
								alt='upload'
								className='me-2'
							/>
							{t('Upload Existing Lead Status')}
						</CustomButton>
					</div> */}
				</div>
			</div>
		);
	};

	const getUpdateUrl = async () => {
		if (homePageData?.homepage?.profile?.url) {
			await dispatch(
				imgUrlUpdate({
					data: {
						url: homePageData?.homepage?.profile?.url,
						expire_time: 50000,
					},
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						setSrc(response?.url);
					}
				});
		} else {
			setSrc(defaultSrc);
		}
	};

	return (
		<>
			{/* <Menu /> */}
			{/* <Header name='Add / Upload Lead' /> */}

			<>
				<Menu
					name={homePageData?.homepage?.profile?.name}
					imgSrc={src}
				/>
				<div
					id='dashboard-main-content-menu'
					className='dashboard h-100'
				>
					<Header
						name={t('Add Leads')}
						type={appWeb ? 'dashboard' : ''}
						hideWelecomeText={appWeb}
						src={src}
						className={'d-none d-md-block'}
					/>

					<div className='d-flex h-100'>
						<Sidebar />

						<IonContent
							id='main-content'
							className=''
						>
							<div className='pt-md-3 bg-web h-100'>
								<div className='container-width-md'>
									<div
										style={{
											background:
												'transparent linear-gradient(180deg, rgba(1, 101, 225, 0.2588235294) 0%, #ffffff 35%) 0% 0% no-repeat padding-box',
										}}
										className='border-radius-10'
									>
										<div
											className='d-flex align-items-center justify-content-center flex-column px-md-5 px-3'
											style={{
												// borderTop: '1px solid #00000029',
												height: 'calc(100vh - 113px)',
											}}
										>
											<Player
												// className="pe-3"
												autoplay={true}
												loop={true}
												controls={false}
												src='/images/icons/json/AddLead.json'
												style={{ height: '102px', width: '102px' }}
											/>
											<h3 className='fs-18 fw-900'>
												{t('Add / Bulk Upload Leads')}
											</h3>
											<AddLeadVideoCard />
											{ACL(permissions, ACLKeys.ADD_NEW_LEAD) && (
												<CustomButton
													// routerLink='/select-lead-product'
													onClick={() => {
														dispatch(
															leadCreationtype({
																...leadcreatedthrough,
																leadCreationtype: 3,
															})
														);
														history.push('/select-lead-product');
													}}
													className='btn-blue w-100'
													// expand='block'
													size={'default'}
													fill={'solid'}
												>
													<img
														src='/images/icons/add-icon.svg'
														alt='add icon'
														className='me-2'
													/>
													{t('Add Leads')}
												</CustomButton>
											)}
											<IonRow className='d-flex w-100'>
												<IonCol
													// size='12'
													sizeSm={`${
														ACL(permissions, ACLKeys.SHARE_LEAD) ? '6' : '12'
													}`}
													sizeMd={`${
														ACL(permissions, ACLKeys.SHARE_LEAD) ? '6' : '12'
													}`}
													className='text-center'
												>
													<CustomButton
														onClick={() => setBulkUploadModal(true)}
														className=' btn-blue fw-600 fs-14 w-100'
														fill='outline'
														size={'default'}
													>
														<img
															src='/images/icons/upload-outline.svg'
															alt='upload'
															className='me-2'
														/>
														{t('Bulk Upload')}
													</CustomButton>
												</IonCol>
												<IonCol
													// size='12'
													sizeSm='6'
													sizeMd='6'
													className='text-center'
												>
													{ACL(permissions, ACLKeys.SHARE_LEAD) && (
														<CustomButton
															//routerLink='/dashboard/home'
															className=' btn-blue fw-600 fs-14 w-100'
															fill='outline'
															size={'default'}
															prefixIcon={'/images/icons/share-outline.svg'}
															title={t('Share Invite')}
															onClick={() => {
																dispatch(getLeadFlowUrl())
																	.then(unwrapResult)
																	.then((result: any) => {
																		if (result?.status) {
																			ShareData('', '', result?.url);
																		}
																	});
															}}
														/>
													)}
												</IonCol>
											</IonRow>
											<IonRow className='d-flex w-100'>
												<IonCol
													size='6'
													className='text-center bold'
												>
													Total Rows Count : {count}
												</IonCol>
												<IonCol
													size='6'
													className='text-center bold'
												>
													Total Error Rows Count : {errorCount}
												</IonCol>
											</IonRow>
											<div className='flex justify-center'>
												<CustomButton
													onClick={() => history.push('/bulk-upload-history')}
													className='btn-blue w-100'
													size={'default'}
													fill={'solid'}
												>
													{t('History')}
												</CustomButton>
											</div>
											<div className='d-flex ion-justify-content-between w-100 mt-2'></div>
										</div>
									</div>
								</div>
							</div>
						</IonContent>
					</div>
				</div>
			</>
			{/* Bulk Upload modal */}
			<CustomModal
				id='bulkUploadmodal'
				isOpen={bulkUploadModal}
				initialBreakpoint={isWeb ? 1 : 0.9}
				handleClose={() => setBulkUploadModal(false)}
				breakpoints={[0, 0.35, 0.5, 0.75, 0.9]}
				modalContent={<BulkUploadModal />}
				needCross={true}
				needArrow={false}
				className='side-modal'
			/>
			{spinner && (
				<div
					className='position-fixed  w-100  d-flex justify-content-center align-items-center h-100'
					style={{
						top: '0px',
						left: '50%',
						transform: 'translate(-50%,0%)',
						backgroundColor: 'rgba(0,0,0,0.2)',
					}}
				>
					<IonSpinner name='lines-sharp' />
				</div>
			)}
			<CustomModal
				id=''
				isOpen={showNotificationModel}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setShowNitificationModel(false)}
				breakpoints={[]}
				className='confirm-popup-middle height-auto'
				modalContent={
					<div className={`wrapper py-4 confirm-popup-middle height-auto`}>
						<IonItem lines='none'>
							<div className='d-flex flex-column'>
								<p
									className='fs-14 mb-2'
									style={{ color: 'var(--text-dark-grey)' }}
								>
									Please enable notification permission from settings.
								</p>
							</div>
						</IonItem>
						<div className='d-flex justify-content-center gap-3 px-3'>
							<CustomButton
								className='w-50'
								fill='solid'
								onClick={() => {
									NativeSettings.open({
										optionAndroid: AndroidSettings.AppNotification,
										optionIOS: IOSSettings.Notifications,
									});
									setShowNitificationModel(false);
								}}
								title={t('Ok')}
							/>
						</div>
					</div>
				}
				needIonContentWraper={false}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
