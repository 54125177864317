import React, { useEffect, useRef, useState } from 'react';
import {
	IonChip,
	IonCol,
	IonContent,
	IonGrid,
	IonImg,
	IonPage,
	IonRow,
} from '@ionic/react';
import './BankDetails.scss';
import { t } from 'i18next';
import { Header } from '../../components/layout/header/Header';
import { Divider } from '../../components/common/divider/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { getLenderDetailsBySubproduct } from '../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { getIcon, showInAppBrowserView } from '../../helpers/functions';

export const BankDetails: React.FC = () => {
	const [selectedTab, setSelectedTab] = useState('eligibility');
	const [lenderData, setLenderData] = useState<any>([]);
	const [activeTabKey, setActiveTabKey] = useState();
	const [thirdDivHeight, setThirdDivHeight] = useState<number>(0);
	const [windowWidth, setWindowWidth] = useState(0);
	if (selectedTab.length > 20) {
		setSelectedTab('documents-required');
	}

	const handleTabChange = (event: any) => {
		setSelectedTab(event.target.value);
	};

	const dispatch = useDispatch();
	const productFlowSelector = useSelector(
		(state: any) => state?.lead?.productFlowData
	);

	const firstDivRef = useRef<HTMLDivElement>(null);
	const secondDivRef = useRef<HTMLDivElement>(null);
	const mainDivRef = useRef<HTMLDivElement>(null);

	const updateThirdDivHeight = () => {
		if (firstDivRef.current && secondDivRef.current) {
			const firstDivHeight =
				firstDivRef?.current?.getBoundingClientRect()?.height;
			const secondDivHeight =
				secondDivRef?.current?.getBoundingClientRect().height;
			const mainDivHeight =
				mainDivRef?.current?.getBoundingClientRect()?.height || 0;
			const thirdDivHeight =
				mainDivHeight - firstDivHeight - secondDivHeight - 82;
			setThirdDivHeight(thirdDivHeight);
		}
		setWindowWidth(window.innerWidth);
	};

	useEffect(() => {
		setTimeout(() => {
			updateThirdDivHeight();
		}, 300);
	}, []);

	useEffect(() => {
		if (location.pathname !== '/lender-product-detail') {
			return;
		}
		dispatch(
			getLenderDetailsBySubproduct({
				param: `?lender_prod_id=${productFlowSelector?.lender_prod_id}`,
			})
		)
			.then(unwrapResult)
			.then((res: any) => {
				if (res?.status) {
					setLenderData(res?.data);
					if (res?.data?.update_content.length) {
						const d = res?.data?.update_content.filter(
							(item: any) => item.status === 1
						);
						setActiveTabKey(d?.[0]?.tabId);
					}
				}
			});
	}, []);

	return (
		<>
			<IonPage>
				<Header
					name={t(productFlowSelector?.bankTitle)}
					needArrow={true}
					backButtonDisabled={false}
					backButton={true}
				/>
				<Divider className='padding-top-border' />
				<IonContent>
					<div
						className='bg-web loan-card-container-spacing min-vh-100'
						ref={mainDivRef}
					>
						<div
							className='bg-white'
							style={{ borderRadius: '10px' }}
						>
							<div ref={firstDivRef}>
								<div className='ionSection bank-info display-flex align-items-center mt-3'>
									<div className='bank-icon ps-0'>
										<img
											alt='Product'
											src={
												productFlowSelector?.bankIcon
													? productFlowSelector?.bankIcon
													: 'https://ionicframework.com/docs/img/demos/thumbnail.svg'
											}
											className='product-img '
										/>
									</div>
									<div className='bank-name font-bold ms-1'>
										<span>{productFlowSelector?.bankTitle}</span>
									</div>
								</div>

								<div className='ionSection bank-details details-container flex-space'>
									{/* First Row */}
									<div className='details-row display-flex flex-wrap  justify-content-start'>
										{lenderData?.lender_content?.map(
											(item: any, index: any) => {
												return (
													<>
														{productFlowSelector?.is_lender === 1 &&
															item?.is_lender === 1 && (
																<div
																	key={index}
																	className='details-col col-6 col-md-3  mb-3'
																>
																	<div className='details-icon'>
																		<img
																			src={
																				getIcon(item?.icon_url) ||
																				'imagesicons\toolsloan-emi-calculator-icon.svg'
																			}
																			className='bank-svg mt-0 mt-md-2'
																			alt=''
																		/>
																	</div>
																	<div className='details-text'>
																		<div className='rate-label'>
																			{item?.label}
																		</div>
																		<div className='interest-rate'>
																			{item?.content}
																		</div>
																		{item?.tag ? (
																			<IonChip
																				className={
																					item?.tag_colour
																						? 'ms-0'
																						: 'ps-0 ms-0'
																				}
																				style={{
																					color: item?.tag_colour
																						? item?.tag_colour
																						: '#5D5E7280',
																					background: item?.tag_colour
																						? item?.tag_colour + '60'
																						: 'transparent',
																				}}
																				color={
																					item?.tag_colour
																						? item?.tag_colour
																						: '#5D5E7280'
																				}
																			>
																				{item?.tag}
																			</IonChip>
																		) : (
																			''
																		)}
																	</div>
																</div>
															)}
														{productFlowSelector?.is_product === 1 &&
															item?.is_product === 1 && (
																<div
																	key={index}
																	className='details-col col-6 col-md-3 mb-3'
																>
																	<div className='details-icon'>
																		<img
																			src={
																				getIcon(item?.icon_url) ||
																				'imagesicons\toolsloan-emi-calculator-icon.svg'
																			}
																			className='bank-svg mt-0 mt-md-2'
																			alt=''
																		/>
																	</div>
																	<div className='details-text'>
																		<div className='rate-label'>
																			{item?.label}
																		</div>
																		<div className='interest-rate'>
																			{item?.content}
																		</div>
																		{item?.tag ? (
																			<IonChip
																				className={
																					item?.tag_colour
																						? 'ms-0'
																						: 'ps-0 ms-0'
																				}
																				style={{
																					color: item?.tag_colour
																						? item?.tag_colour
																						: '#5D5E7280',
																					background: item?.tag_colour
																						? item?.tag_colour + '60'
																						: 'transparent',
																				}}
																				color={
																					item?.tag_colour
																						? item?.tag_colour
																						: '#5D5E7280'
																				}
																			>
																				{item?.tag}
																			</IonChip>
																		) : (
																			''
																		)}
																	</div>
																</div>
															)}
													</>
												);
											}
										)}
									</div>
								</div>
							</div>
							{lenderData?.lender_tool?.length !== 0 && (
								<div className='cardDivider'></div>
							)}
							<div ref={secondDivRef}>
								{lenderData?.lender_tool?.length !== 0 && (
									<section className='p-4 py-md-0 ionSection'>
										<h3 className='m-0 fs-16 pb-3 fw-600 darkest-grey'>
											{t('My Tools')}
										</h3>
										<IonGrid className='ion-no-padding'>
											<IonRow>
												{lenderData?.lender_tool?.map(
													(item: any, index: number) => {
														return (
															<IonCol
																key={index}
																className={`ion-padding-end col-6 col-md-4 col-lg-3 d-flex tool-column`}
																onClick={() =>
																	item?.tool_url
																		? showInAppBrowserView(item?.tool_url)
																		: null
																}
															>
																<div className='tool-box d-flex flex-column flex-md-row gap-0 gap-md-3 align-items-start align-items-md-center w-100 cursor-pointer'>
																	<IonImg
																		src={item?.tool_photo_url}
																		style={{ width: '26px', height: '26px' }}
																		className='mb-3 mb-md-0'
																	/>
																	<h2 className='m-0 fs-14 fw-600 pb-2 pb-md-0 darkest-grey'>
																		{t(item?.tool_name)}
																	</h2>
																</div>
															</IonCol>
														);
													}
												)}
											</IonRow>
										</IonGrid>
									</section>
								)}
							</div>
							{lenderData?.update_content?.length !== 0 && (
								<div className='cardDivider'></div>
							)}
							<div>
								{lenderData?.update_content?.length ? (
									<div
										id='bank-tab-buttons'
										className='tab-buttons pb-0 justify-content-start ionSection border-bottom d-flex '
									>
										{lenderData?.update_content?.map((item: any) => {
											return (
												<>
													{item?.status === 1 && (
														<div
															key={item?.tabId}
															className='tab-button me-5'
															onClick={() => setActiveTabKey(item?.tabId)}
														>
															<button
																className={`tab-btn medium-font ${
																	item?.tabId === activeTabKey ? 'active' : ''
																}`}
																onClick={(e) => {
																	handleTabChange(e);
																}}
																value={item?.title}
															>
																{t(item?.title)}
															</button>
														</div>
													)}
												</>
											);
										})}
									</div>
								) : (
									''
								)}
								{lenderData?.update_content?.map((item: any) => {
									return (
										<>
											{item?.status === 1 && (
												<div
													className={`ionSection ${
														item?.tabId !== activeTabKey ? 'd-none' : ''
													}`}
													style={{
														overflow: 'auto',
														height:
															windowWidth < 767
																? thirdDivHeight || '200px'
																: '200px',
													}}
												>
													<div
														className='medium-font'
														dangerouslySetInnerHTML={{ __html: item?.body }}
													></div>
												</div>
											)}
										</>
									);
								})}
							</div>
						</div>
					</div>
				</IonContent>
			</IonPage>
		</>
	);
};
