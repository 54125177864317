import * as yup from 'yup';
import { REGEX } from '../../../../../helpers/constant';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { CustomInput } from '../../../../../components/common/custom-input/CustomInput';
import { CustomButton } from '../../../../../components/common/custom-button/CustomButton';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
	apiKycDocumentUpload,
	checkIfscCode,
} from '../../../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';

interface Props {
	userId: number;
	data: any;
	setUserData: any;
	setShowBankInfoForm: any;
}

const bankEditInfoSchema = yup.object().shape({
	AccountNo: yup
		.string()
		.required('Account No is Required.')
		.max(30, 'Max account no should be less than or equal to 30')
		.matches(REGEX.ACCOUNTNO, 'Please enter valid Input.'),
	IFSC: yup
		.string()
		.required('IFSC Code is required')
		.max(11, 'IFSC must be 11 character')
		.trim()
		.matches(REGEX.IFSC, 'IFSC Format Invalid'),
	BankName: yup.string().required('Bank Name is required'),
	BranchName: yup.string().required('Branch Name is required'),
});

export const BankEdit: React.FC<Props> = ({
	userId,
	data,
	setUserData,
	setShowBankInfoForm,
}) => {
	const dispatch: any = useDispatch();
	const { t } = useTranslation();

	useEffect(() => {
		reset(data);
	}, []);

	const {
		handleSubmit,
		control,
		setValue,
		reset,
		getValues,
		formState: { errors, isValid },
	}: any = useForm<any>({
		mode: 'all',
		reValidateMode: 'onChange',
		resolver: yupResolver(bankEditInfoSchema),
		defaultValues: {
			AccountNo: '',
			IFSC: '',
			BankName: '',
			BranchName: '',
		},
	});

	const verifyIfsc = async (value: any) => {
		await dispatch(checkIfscCode({ data: { ifsc_code: value } }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setValue('BankName', result?.ifsc.branch);
					setValue('BranchName', result?.ifsc.bank);
				} else {
					setValue('BankName', '');
					setValue('BranchName', '');
				}
			});
	};

	const onSubmit = async (formData: any) => {
		data.AccountNo = formData.AccountNo;
		data.IFSC = formData.IFSC.toUpperCase();
		data.BankName = formData.BankName;
		data.BranchName = formData.BranchName;
		setUserData(data);
		await dispatch(
			apiKycDocumentUpload({
				param: '/' + userId,
				data: {
					Type: 'Bank',
					RW: true,
					AccountNo: formData.AccountNo,
					IFSC: formData.IFSC.toUpperCase(),
					BankName: formData.BankName,
					BranchName: formData.BranchName,
				},
			})
		)
			.then(unwrapResult)
			.then((urlresult: any) => {
				if (urlresult?.status) {
					data.AccountNo = formData.AccountNo;
					data.IFSC = formData.IFSC;
					data.BankName = formData.BankName;
					data.BranchName = formData.BranchName;
					setUserData(data);
					setShowBankInfoForm(false);
				}
			});
	};

	const inputFields = [
		{
			name: 'AccountNo',
			placeholder: 'Account No. *',
			isUpperCase: false,
			type: 'text',
			disabled: false,
		},
		{
			name: 'IFSC',
			placeholder: 'IFSC Code. *',
			isUpperCase: true,
			type: 'text',
			disabled: false,
		},
		{
			name: 'BankName',
			placeholder: 'Bank Name',
			isUpperCase: false,
			type: 'text',
			disabled: true,
		},
		{
			name: 'BranchName',
			placeholder: 'Branch Name',
			type: 'text',
			isUpperCase: false,
			disabled: true,
		},
	];

	return (
		<section
			className='overflowX-hidden'
			style={{ height: 'calc(100vh - 180px)' }}
		>
			<div>
				<form onSubmit={handleSubmit(onSubmit)}>
					{inputFields?.map(
						({ name, type, placeholder, disabled, isUpperCase }: any) => {
							return (
								<Controller
									key={name}
									control={control}
									render={({ field: { onChange, onBlur, name: name1 } }) => (
										<CustomInput
											type={type}
											name={name1}
											placeholder={t(placeholder)}
											onBlur={onBlur}
											onChange={(e: any) => {
												if (name === 'IFSC') {
													if (e.detail.value.length === 11) {
														verifyIfsc(e.detail.value);
													}
												}
												onChange(e);
											}}
											value={
												isUpperCase
													? getValues(name1)?.toUpperCase()
													: getValues(name1)
											}
											errors={t(errors[name1]?.message)}
											disabled={disabled}
											// maxLength={10}
										/>
									)}
									name={name}
								/>
							);
						}
					)}

					<div
						className='position-fixed w-100 bottom-0 start-0 px-3 pb-3'
						style={{ backgroundColor: '#fff' }}
					>
						<CustomButton
							className='btn-blue'
							type='submit'
							expand='block'
							title={t('Update')}
							disabled={!isValid}
						/>
					</div>
				</form>
			</div>
		</section>
	);
};
