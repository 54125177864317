import { ReactNode, createContext, useContext, useState } from "react";

interface ConsentBusyLoadingContextType {
  isConsentBusy: boolean;
  setBusyStateConsent: (loading: boolean) => void;
}

const ConsentBusyLoadingContext = createContext<ConsentBusyLoadingContextType>({
  isConsentBusy: false,
  setBusyStateConsent: () => {},
});

export const useConsentBusyLoading = () => useContext(ConsentBusyLoadingContext);

interface ConsentBusyLoadingProviderProps {
  children: ReactNode;
}

export const ConsentBusyLoadingProvider = ({ children }: ConsentBusyLoadingProviderProps) => {
  const [isConsentBusy, setConsentBusyState] = useState(false);

  const setBusyStateConsent = (loading: boolean) => {
    setConsentBusyState(loading);
  };

  return (
    <ConsentBusyLoadingContext.Provider value={{ isConsentBusy, setBusyStateConsent }}>
      {children}
    </ConsentBusyLoadingContext.Provider>
  );
};
