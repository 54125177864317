import {
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
	IonRadio,
	IonRadioGroup,
	IonRow,
} from '@ionic/react';
import React, { useState } from 'react';
import { Header } from '../../components/layout/header/Header';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
import { useHistory } from 'react-router';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import { customSuccessfull } from '../../store/reducers/user/userSlice';
import { useDispatch } from 'react-redux';

export const DisbursementDetails: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const [disbursementStatus, setDisbursementStatus] = useState('partial');
	const [disbursementFormPopup, setDisbursementFormPopup] = useState(false);
	const [disbursementMiniPopup, setDisbursementMiniFormPopup] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [disbursementData, setDisbursementData] = useState(true);
	const dispatch: any = useDispatch();

	const DisbursementForm = () => {
		return (
			<div className='p-2'>
				<CustomInput placeholder={t('Sanctioned Amount')} />
				<CustomInput placeholder={t('Bank Application No.')} />
				<CustomInput placeholder={t('Disbursal Amount')} />
				<CustomInput placeholder={t('Disbursal Date')} />
				<h3 className='fs-16'>
					{t('Is this your final or partial disbursement?')}
				</h3>
				<IonRadioGroup
					value='partial'
					className='d-flex gap-3'
				>
					<IonRadio
						value='partial'
						labelPlacement='end'
					>
						{t('Partial')}
					</IonRadio>
					<br />
					<IonRadio
						value='final'
						labelPlacement='end'
					>
						{t('Final')}
					</IonRadio>
					<br />
				</IonRadioGroup>
				<div className='d-flex justify-content-center gap-3 mt-3'>
					<CustomButton
						onClick={() => setDisbursementFormPopup(false)}
						className='w-50'
						fill='outline'
						title={t('Cancel')}
					/>

					<CustomButton
						className='w-50'
						fill='solid'
						onClick={() => {
							setDisbursementFormPopup(false);
							history.replace('/custom-successfull', {
								type: 'AAM',
								message: 'Status updated successfully',
								redirectTo: '/lead-details',
							});

							dispatch(
								customSuccessfull({
									type: 'AAM',
									message: 'Status updated successfully',
									redirectTo: '/lead-details',
								})
							);
						}}
						title={t('Submit')}
					/>
				</div>
			</div>
		);
	};
	const DisbursementMiniForm = () => {
		return (
			<div className='p-2'>
				<CustomInput placeholder={t('Disbursal Amount')} />
				<CustomInput placeholder={t('Disbursal Date')} />
				<h3 className='fs-16'>
					{t('Is this your final or partial disbursement?')}
				</h3>
				<IonRadioGroup
					value='final'
					className='d-flex gap-3'
				>
					<IonRadio
						value='partial'
						labelPlacement='end'
					>
						{t('Partial')}
					</IonRadio>
					<br />
					<IonRadio
						value='final'
						labelPlacement='end'
					>
						{t('Final')}
					</IonRadio>
					<br />
				</IonRadioGroup>
				<div className='d-flex justify-content-center gap-3 mt-3'>
					<CustomButton
						onClick={() => setDisbursementMiniFormPopup(false)}
						className='w-50'
						fill='outline'
						title={t('Cancel')}
					/>

					<CustomButton
						className='w-50'
						fill='solid'
						onClick={() => {
							setDisbursementMiniFormPopup(false);
							setDisbursementStatus('final');
						}}
						title={t('Submit')}
					/>
				</div>
			</div>
		);
	};
	const DisbursementNoData = () => {
		return (
			<>
				{/* If no data found */}
				<IonGrid>
					<IonRow className='mb-3'>
						<IonCol
							size='12'
							className='border rounded '
						>
							<IonRow>
								<IonCol
									size='6'
									className='p-0'
								>
									<h4 className='m-0 fs-14 px-2 pt-1'>
										{t('Total Amount Disbursed')}
									</h4>
								</IonCol>
								<IonCol
									size='6'
									className='p-0'
								>
									<h3 className='m-0 fw-bold fs-18 px-2 pt-1'>0</h3>
								</IonCol>
							</IonRow>
						</IonCol>
					</IonRow>
				</IonGrid>
				<div
					className='d-flex justify-content-center align-items-center flex-column'
					style={{ height: '80%' }}
				>
					<h3 className='fs-16 text-grey pb-2'>
						{t('No Disbursement to show')}
					</h3>
					<CustomButton
						onClick={() => setDisbursementFormPopup(true)}
						title='+ Add Disbursement'
					/>
				</div>
			</>
		);
	};
	const DisbursementDetailsData = () => {
		return (
			<>
				<div
					className=''
					style={{ height: 'calc(100vh - 190px)', overflowX: 'hidden' }}
				>
					<IonGrid>
						<IonRow className='border rounded p-1 mb-3'>
							<IonCol size='8'>
								<h4 className='m-0 fs-14'>{t('Sanctioned Amount')}</h4>
							</IonCol>
							<IonCol size='4'>
								<h3 className='m-0 fw-bold fs-18'>₹ 50,00,000</h3>
							</IonCol>
						</IonRow>
						<IonRow className='border rounded p-1 mb-3'>
							<IonCol size='8'>
								<h4 className='m-0 fs-14'>{t('Total Amount Disbursed')}</h4>
							</IonCol>
							<IonCol size='4'>
								<h3 className='m-0 fw-bold fs-18'>₹ 50,00,000</h3>
							</IonCol>
						</IonRow>
					</IonGrid>
					<div className='p-2 border rounded'>
						<h3 className='fs-14 fw-bold m-0 px-2'>
							{t('Bank Application No.')} #A123
						</h3>
						<IonGrid>
							<IonRow className=''>
								<IonCol size='8'>
									<h3 className='fs-12 m-0 pb-1'>Final Disbursement</h3>
									<h3 className='fs-12 m-0'>27 Feb 2023</h3>
								</IonCol>
								<IonCol size='4'>
									<h3 className='fs-16 m-0 fw-bold text-end'>₹ 49,50,000</h3>
								</IonCol>
							</IonRow>
							<IonRow className=''>
								<IonCol size='8'>
									<h3 className='fs-12 m-0 pb-1'>Disbursement 1</h3>
									<h3 className='fs-12 m-0'>12 Feb 2023</h3>
								</IonCol>
								<IonCol size='4'>
									<h3 className='fs-16 m-0 fw-bold text-end'>₹ 50,000</h3>
								</IonCol>
							</IonRow>
						</IonGrid>
					</div>
				</div>
				<div className=''>
					<CustomButton
						className='w-100'
						fill='outline'
						routerLink='/lead-details'
						title={t('Go to Details')}
					/>
					{disbursementStatus === 'partial' && (
						<CustomButton
							className='w-100'
							onClick={() => setDisbursementMiniFormPopup(true)}
							title={'+' + t('Add Disbursement')}
						/>
					)}
				</div>
			</>
		);
	};
	return (
		<>
			<IonPage>
				<Header
					name={t('Disbursement Details')}
					backButton={true}
					needArrow={true}
				/>
				<IonContent className='ion-padding'>
					{disbursementData ? (
						<DisbursementDetailsData />
					) : (
						<DisbursementNoData />
					)}
				</IonContent>
			</IonPage>

			{/* disbursementformmodal */}
			<CustomModal
				id='disbursementformmodal'
				isOpen={disbursementFormPopup}
				initialBreakpoint={0.35}
				handleClose={() => setDisbursementFormPopup(false)}
				breakpoints={[0, 0.35, 0.5, 0.75]}
				modalContent={<DisbursementForm />}
				needCross={false}
				needArrow={false}
			/>

			{/* DisbursementMiniForm */}
			<CustomModal
				id='disbursementminiform'
				isOpen={disbursementMiniPopup}
				initialBreakpoint={0.35}
				handleClose={() => setDisbursementMiniFormPopup(false)}
				breakpoints={[0, 0.35, 0.5, 0.75]}
				modalContent={<DisbursementMiniForm />}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
