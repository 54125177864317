import { axiosInstatnce } from './accessToken';

const GET = async (ApiName: any, data: any) => {
	try {
		const response = await axiosInstatnce.get(ApiName, data);
		return response?.data;
	} catch (error) {
		console.log("apiService GET error ", error);
	}
};

const POST = async (ApiName: any, data: any) => {
	try {
		const response = await axiosInstatnce.post(ApiName, data);
		return response?.data;
	} catch (error) {
		console.log("apiService GET error ", error);
	}
};

const PUT = async (ApiName: any, data: any) => {
	try {
		const response = await axiosInstatnce.put(ApiName, data);
		return response?.data;
	} catch (error) {
		console.log("apiService GET error ", error);
	}
};

export const apiService: any = {
	GET,
	POST,
	PUT,
};
