import * as Yup from 'yup';
import { REGEX } from '../../../../helpers/constant';

export const primaryApplicantSchemaOne: any = Yup.object({
	mother_first_name: Yup.string()
		.trim()
		.max(100, "Mother's First Name should not be more than 100")
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input')
		.required("Mother's First Name is Required"),
	mother_last_name: Yup.string()
		.trim()
		.max(100, "Mother's Last Name should not be more than 100")
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input')
		.required("Mother's Last Name is Required"),
	employment_type: Yup.string().required('Employment Type is required'),
	property_type: Yup.string().required('Property Type is required'),
	nature_of_property: Yup.string().required('Nature Of Property is required'),
	application_type: Yup.string().required('Application Type is required'),
	property_cost: Yup.string().when('application_type', {
		is: (val: any) => val?.includes('Property Identified'),
		then: (schema) =>
			schema
				.required('Property Cost is required')
				.matches(REGEX.ALLOWNUMBERS, 'Property Cost should be number')
				.matches(
					REGEX.GREATER_THAN_ZERO,
					'Property Cost should be greater than zero'
				),
		otherwise: () => Yup.string(),
	}),
	property_address: Yup.string().when('application_type', {
		is: (val: any) => val?.includes('Property Identified'),
		then: (schema) => schema.required('Property Address is required'),
		otherwise: () => Yup.string(),
	}),
	occupation_type: Yup.string().required('Occupation Type is required'),
	collateral_sub_type: Yup.string().required('Collateral Sub Type is required'),
	loan_purpose: Yup.string().required('Loan Purpose is required'),
	customer_type: Yup.string().required('Customer Type is required'),
	date_of_incorporation: Yup.date()
		.typeError('Please select valid date')
		.when('customer_type', {
			is: (val: any) => val?.includes('Corporate'),
			then: (schema) =>
				schema
					.required('Date of Incorporation is Required')
					.max(new Date(Date.now()), 'Date cannot be in the future'),
			otherwise: () => Yup.date(),
		}),
	company_name: Yup.string().required('Company Name is required'),
	net_monthly_income: Yup.string()
		.required('Net Monthly Income is a required field')
		.matches(REGEX.ALLOWNUMBERS, 'Net Monthly Income should be number')
		.matches(
			REGEX.GREATER_THAN_ZERO,
			'Net Monthly Income should be greater than zero'
		),
	req_roi: Yup.string()
		.trim()
		.matches(REGEX.ROI_IRR, 'Please Enter Valid Requested ROI')
		.max(6, 'Requested ROI maximum be 2 Digits')
		.required('Requested ROI is Required'),
});

export const primaryApplicantSchemaTwo: any = Yup.object({
	dms_branch_code: Yup.string().required('DMS Branch is required'),
	// is_co_applicant: Yup.string().required('Co Applicant is required'),
	marital_status: Yup.string().required('Marital Status is required'),
	resident_type: Yup.string().required('Resident Type is required'),
	accomodation_type: Yup.string().required('Accomodation Type is required'),
	nature_of_business: Yup.string().required('Nature Of Business is required'),
	nature_of_business_others: Yup.string().when('nature_of_business', {
		is: (val: any) => val?.includes('Others'),
		then: (schema) => schema.required('Nature Of Business Others is required'),
		otherwise: () => Yup.string().notRequired(),
	}),
	industry: Yup.string().required('Industry is required'),
	date_of_joining: Yup.date()
		.typeError('Please select valid date')
		.required('Date of Joining is Required')
		.max(new Date(Date.now()), 'Date cannot be in the future'),
});
export const salariedDocuments: any = Yup.object({
	documents: Yup.array()
		.of(
			Yup.object().shape({
				applicantType: Yup.string(),
				docUploadName: Yup.string(),
				docUploadType: Yup.string(),
				file: Yup.string(),
			})
		)
		.test('has-identity-proof', 'Identity Proof is required', (value) => {
			return value.some(
				(document) => document.docUploadType === 'Identity_Proof'
			);
		})
		.test(
			'has-cur-address-proof',
			'Current Address Proof is required',
			(value) => {
				return value.some(
					(document) => document.docUploadType === 'Cur_Address_Proof'
				);
			}
		)
		.test(
			'has-cur-address-proof',
			'Variable Income Proof is required',
			(value) => {
				return value.some(
					(document) => document.docUploadType === 'Variable_Income'
				);
			}
		)
		.test('has-cur-address-proof', 'Bank Statement is required', (value) => {
			return value.some(
				(document) => document.docUploadType === 'Bank_Statement'
			);
		}),
});
export const selfEmployedDocuments: any = Yup.object({
	documents: Yup.array()
		.of(
			Yup.object().shape({
				applicantType: Yup.string(),
				docUploadName: Yup.string(),
				docUploadType: Yup.string(),
				file: Yup.string(),
			})
		)
		.test('has-identity-proof', 'Current Address Proof is required', (value) => {
			return value.some(
				(document) => document.docUploadType === 'self_curr_Address_Proof'
			);
		})
		.test('has-identity-proof', 'Permanent Address Proof is required', (value) => {
			return value.some(
				(document) => document.docUploadType === 'self_perm_Address_Proof'
			);
		})
		.test('has-cur-address-proof', 'Identity Proof is required', (value) => {
			return value.some(
				(document) => document.docUploadType === 'identify_Proof'
			);
		})
		.test('has-pan-proof', 'Pan is required', (value) => {
			return value.some((document) => document.docUploadType === 'pan_entity');
		})
		.test('has-income-proof', 'Income Proof is required', (value) => {
			return value.some((document) => document.docUploadType === 'income_docs');
		})
		.test('has-bank-statement-proof', 'Bank Statement is required', (value) => {
			return value.some(
				(document) => document.docUploadType === 'Bank_Statement_op_business_ac'
			);
		})
		.test(
			'has-udyam-proof',
			'Udyam Registration Certificate is required',
			(value) => {
				return value.some((document) => document.docUploadType === 'self_urc');
			}
		),
});

export const primaryApplicantFormOne: any = [
	{
		name: 'mother_first_name',
		type: 'text',
		placeholder: 'Mother First Name *',
	},
	{
		name: 'mother_last_name',
		type: 'text',
		placeholder: 'Mother Last Name *',
	},
	{
		name: 'employment_type',
		type: 'dropdown',
		placeholder: 'Employment Type *',
	},
	{
		name: 'property_type',
		type: 'dropdown',
		placeholder: 'Property Type *',
	},
	{
		name: 'nature_of_property',
		type: 'dropdown',
		placeholder: 'Nature Of Property *',
	},
	{
		name: 'application_type',
		type: 'dropdown',
		placeholder: 'Application Type *',
	},
	{
		name: 'property_cost',
		type: 'text',
		placeholder: 'Property Cost *',
	},
	{
		name: 'property_address',
		type: 'text',
		placeholder: 'Property Address *',
	},
	{
		name: 'occupation_type',
		type: 'dropdown',
		placeholder: 'Occupation Type *',
	},
	{
		name: 'collateral_sub_type',
		type: 'dropdown',
		placeholder: 'Collateral Sub Type *',
	},
	{
		name: 'loan_purpose',
		type: 'dropdown',
		placeholder: 'Loan Purpose *',
	},
	{
		name: 'customer_type',
		type: 'dropdown',
		placeholder: 'Customer Type *',
	},
	{
		name: 'date_of_incorporation',
		type: 'date',
		placeholder: 'Date of Incorporation *',
	},
	{
		name: 'company_name',
		type: 'text',
		placeholder: 'Company Name *',
	},
	{
		name: 'net_monthly_income',
		type: 'text',
		placeholder: 'Net Monthly Income *',
	},
	{
		name: 'req_roi',
		type: 'text',
		placeholder: 'Requested ROI *',
	},
];

export const primaryApplicantFormTwo: any = [
	{
		name: 'marital_status',
		type: 'dropdown',
		placeholder: 'Marital Status *',
	},
	{
		name: 'resident_type',
		type: 'dropdown',
		placeholder: 'Resident Type *',
	},
	{
		name: 'accomodation_type',
		type: 'dropdown',
		placeholder: 'Accomodation Type *',
	},
	{
		name: 'nature_of_business',
		type: 'dropdown',
		placeholder: 'Nature Of Business *',
	},
	{
		name: 'nature_of_business_others',
		type: 'text',
		placeholder: 'Nature Of Business Others *',
	},
	{
		name: 'industry',
		type: 'dropdown',
		placeholder: 'Industry *',
	},
	{
		name: 'date_of_joining',
		type: 'date',
		placeholder: 'Date Of Joining *',
	},
	{
		name: 'dms_branch_code',
		type: 'dropdown',
		placeholder: 'DMS Branch *',
	},
];

export const LAPCoApplicantField = {
	name: 'is_co_applicant',
	type: 'dropdown',
	placeholder: 'Co Applicant',
};

// CO-APPLICANT
export const coApplicantSchemaOne: any = Yup.object({
	mobile_no: Yup.string()
		.max(10, 'Mobile No. Should Be 10 Digits.')
		.required('Mobile is Required.')
		.matches(/^\d+$/, 'Mobile No. Should Contain Only Digits.')
		.matches(REGEX.INDIAN_MOBILE_NUMBERS, 'Please Enter Valid Number.'),
	first_name: Yup.string()
		.trim()
		.max(100, 'First Name should not be more than 100')
		.required('First Name is Required')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
	middle_name: Yup.string()
		.trim()
		.max(100, 'Middle Name should not be more than 100')
		.notRequired()
		.matches(REGEX.ALLOWONLYCHAR, {
			message: 'Please Enter Valid Input',
			excludeEmptyString: true,
		}),
	last_name: Yup.string()
		.trim()
		.max(100, 'Last Name should not be more than 100')
		.required('Last Name is Required')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
	dob: Yup.date()
		.required('DOB is Required.')
		.typeError('Please select valid date')
		.max(new Date(Date.now()), 'Date cannot be in the future')
		.test('dob', 'Age must be 18 or older', function (birthdate) {
			const cutoff = new Date();
			cutoff.setFullYear(cutoff.getFullYear() - 18);
			return birthdate <= cutoff;
		}),
	gender: Yup.string().required('Gender is Required'),
	pan_no: Yup.string()
		.required('PAN No. is Required.')
		.trim()
		.matches(
			REGEX.PAN,
			"PAN no. should be in alphanumeric format 'ABCDE1234A' only"
		),
	email: Yup.string()
		.trim()
		.matches(REGEX.EMAIL, 'Please Enter Valid Email')
		.required('Email is Required'),
	net_monthly_income: Yup.string()
		.required('Net Monthly Income is a required field')
		.matches(REGEX.ALLOWNUMBERS, 'Net Monthly Income should be number')
		.matches(
			REGEX.GREATER_THAN_ZERO,
			'Net Monthly Income should be greater than zero'
		),
	req_roi: Yup.string()
		.trim()
		.matches(REGEX.ROI_IRR, 'Please Enter Valid Requested ROI')
		.max(6, 'Requested ROI maximum be 2 Digits')
		.required('Requested ROI is Required'),
	mother_first_name: Yup.string()
		.trim()
		.max(100, "Mother's First Name should not be more than 100")
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input')
		.required("Mother's First Name is Required"),
	mother_last_name: Yup.string()
		.trim()
		.max(100, "Mother's Last Name should not be more than 100")
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input')
		.required("Mother's Last Name is Required"),
});

export const coApplicantSchemaTwo: any = Yup.object({
	customer_type: Yup.string().required('Customer Type is required'),
	property_type: Yup.string().required('Property Type is required'),
	nature_of_property: Yup.string().required('Nature Of Property is required'),
	employment_type: Yup.string().required('Employment Type is required'),
	application_type: Yup.string().required('Application Type is required'),
	property_cost: Yup.string().when('application_type', {
		is: (val: any) => val?.includes('Property Identified'),
		then: (schema) =>
			schema
				.required('Property Cost is required')
				.matches(REGEX.ALLOWNUMBERS, 'Property Cost should be number')
				.matches(
					REGEX.GREATER_THAN_ZERO,
					'Property Cost should be greater than zero'
				),
		otherwise: () => Yup.string(),
	}),
	property_address: Yup.string().when('application_type', {
		is: (val: any) => val?.includes('Property Identified'),
		then: (schema) => schema.required('Property Address is required'),
		otherwise: () => Yup.string(),
	}),
	occupation_type: Yup.string().required('Occupation Type is required'),
	collateral_sub_type: Yup.string().required('Collateral Sub Type is required'),
	loan_purpose: Yup.string().required('Loan Purpose is required'),
	party_relation: Yup.string().required('Party Relationship is required'),
	date_of_incorporation: Yup.date()
		.typeError('Please select valid date')
		.when('customer_type', {
			is: (val: any) => val?.includes('Corporate'),
			then: (schema) =>
				schema
					.required('Date of Incorporation is Required')
					.max(new Date(Date.now()), 'Date cannot be in the future'),
			otherwise: () => Yup.date(),
		}),
	company_name: Yup.string().required('Company Name is required'),
	address_type: Yup.string().required('Address Type is required'),
	address_line_1: Yup.string().trim().required('Address Line 1 is Required'),
	address_line_2: Yup.string().trim().required('Address Line 2 is Required'),
	address_line_3: Yup.string().trim().required('Address Line 3 is Required'),
	pincode: Yup.string()
		.trim()
		.required('Pin Code Number is Required')
		.matches(/^\d{6}$/, 'Pincode should be 6 Digits')
		.length(6, 'Pincode should be 6 Digits'),
});

export const coApplicantSchemaThree: any = Yup.object({
	dms_branch_code: Yup.string().required('DMS Branch is required'),
	marital_status: Yup.string().required('Marital Status is required'),
	resident_type: Yup.string().required('Resident Type is required'),
	accomodation_type: Yup.string().required('Accomodation Type is required'),
	nature_of_business: Yup.string().required('Nature Of Business is required'),
	nature_of_business_others: Yup.string().when('nature_of_business', {
		is: (val: any) => val?.includes('Others'),
		then: (schema) => schema.required('Nature Of Business Others is required'),
		otherwise: () => Yup.string().notRequired(),
	}),
	industry: Yup.string().required('Industry is required'),
	date_of_joining: Yup.date()
		.typeError('Please select valid date')
		.required('Date of Joining is Required')
		.max(new Date(Date.now()), 'Date cannot be in the future'),
	// documents: Yup.array().of(
	// 	Yup.object({
	// 		docUploadType: Yup.string().required('Document Type is required'),
	// 		file: Yup.string().required('Document is required'),
	// 	})
	// )
});

export const coApplicantFormOne: any = [
	{
		name: 'mobile_no',
		type: 'text',
		placeholder: 'Mobile Number *',
	},
	{
		name: 'first_name',
		type: 'text',
		placeholder: 'First Name (as per Aadhaar) *',
	},
	{
		name: 'middle_name',
		type: 'text',
		placeholder: 'Middle Name (as per Aadhaar)',
	},
	{
		name: 'last_name',
		type: 'text',
		placeholder: 'Last Name (as per Aadhaar) *',
	},
	{
		name: 'dob',
		type: 'date',
		placeholder: 'Date of Birth *',
	},
	{
		name: 'pan_no',
		type: 'text',
		placeholder: 'PAN *',
	},
	{
		name: 'gender',
		type: 'dropdown',
		placeholder: 'Gender *',
	},
	{
		name: 'email',
		type: 'text',
		placeholder: 'Email *',
	},
	{
		name: 'net_monthly_income',
		type: 'text',
		placeholder: 'Net Monthly Income *',
	},
	{
		name: 'req_roi',
		type: 'text',
		placeholder: 'Requested ROI *',
	},
	{
		name: 'mother_first_name',
		type: 'text',
		placeholder: 'Mother First Name *',
	},
	{
		name: 'mother_last_name',
		type: 'text',
		placeholder: 'Mother Last Name *',
	},
];

export const coApplicantFormTwo: any = [
	{
		name: 'customer_type',
		type: 'dropdown',
		placeholder: 'Customer Type *',
	},
	{
		name: 'employment_type',
		type: 'dropdown',
		placeholder: 'Employment Type *',
	},
	{
		name: 'property_type',
		type: 'dropdown',
		placeholder: 'Property Type *',
	},
	{
		name: 'nature_of_property',
		type: 'dropdown',
		placeholder: 'Nature Of Property *',
	},
	{
		name: 'application_type',
		type: 'dropdown',
		placeholder: 'Application Type *',
	},
	{
		name: 'property_cost',
		type: 'text',
		placeholder: 'Property Cost *',
	},
	{
		name: 'property_address',
		type: 'text',
		placeholder: 'Property Address *',
	},
	{
		name: 'occupation_type',
		type: 'dropdown',
		placeholder: 'Occupation Type *',
	},
	{
		name: 'collateral_sub_type',
		type: 'dropdown',
		placeholder: 'Collateral Sub Type *',
	},
	{
		name: 'loan_purpose',
		type: 'dropdown',
		placeholder: 'Loan Purpose *',
	},
	{
		name: 'date_of_incorporation',
		type: 'date',
		placeholder: 'Date of Incorporation *',
	},
	{
		name: 'company_name',
		type: 'text',
		placeholder: 'Company Name *',
	},
	{
		name: 'party_relation',
		type: 'dropdown',
		placeholder: 'Party Relationship *',
	},
	{
		name: 'address_type',
		type: 'dropdown',
		placeholder: 'Address Type *',
	},
	{
		name: 'address_line_1',
		type: 'text',
		placeholder: 'Address Line 1 *',
	},
	{
		name: 'address_line_2',
		type: 'text',
		placeholder: 'Address Line 2 *',
	},
	{
		name: 'address_line_3',
		type: 'text',
		placeholder: 'Address Line 3 *',
	},
	{
		name: 'pincode',
		type: 'text',
		placeholder: 'Pincode *',
	},
];

export const coApplicantFormThree: any = [
	{
		name: 'marital_status',
		type: 'dropdown',
		placeholder: 'Marital Status *',
	},
	{
		name: 'resident_type',
		type: 'dropdown',
		placeholder: 'Resident Type *',
	},
	{
		name: 'accomodation_type',
		type: 'dropdown',
		placeholder: 'Accomodation Type *',
	},
	{
		name: 'nature_of_business',
		type: 'dropdown',
		placeholder: 'Nature Of Business *',
	},
	{
		name: 'nature_of_business_others',
		type: 'text',
		placeholder: 'Nature Of Business Others *',
	},
	{
		name: 'industry',
		type: 'dropdown',
		placeholder: 'Industry *',
	},
	{
		name: 'date_of_joining',
		type: 'date',
		placeholder: 'Date Of Joining *',
	},
	{
		name: 'dms_branch_code',
		type: 'dropdown',
		placeholder: 'DMS Branch *',
	},
];
