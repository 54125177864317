import {
	IonButtons,
	IonContent,
	IonImg,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonItem,
	IonPage,
} from '@ionic/react';
import { Header } from '../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
/* import { CustomButton } from '../../components/common/custom-button/CustomButton';
 */ import { useEffect, useState } from 'react';

import LeadContactCard from '../../components/common/lead-contact-card/LeadConatctCard';
import { CustomSearchbar } from '../../components/common/custom-searchbar/CustomSearchbar';
import { CustomNoData } from '../../components/common/custom-no-data/CustomNoData';
import { StatusNum, USER_ROLES } from '../../helpers/constant';
import { useDispatch, useSelector } from 'react-redux';
import {
	allocatorAllocateManager,
	bOpsManagerList,
	dmaFlowData,
} from '../../store/reducers/user/userSlice';
import { getdmaList, paginationSize } from '../../store/reducers/dmaSlice';
import { CustomCheckbox } from '../../components/common/custom-checkbox/CustomCheckbox';
import { InitialLetter } from '../../components/common/initial-letter/InitialLetter';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
import {
	PageExitPopUp,
	sanitizeHtml,
	showToastAlert,
} from '../../helpers/functions';
import { unwrapResult } from '@reduxjs/toolkit';

export const TeamList: React.FC = () => {
	const { t } = useTranslation();
	const dmaSelector = useSelector((state: any) => state?.users);
	const dmaPageSizeSelector = useSelector((state: any) => state?.dma);
	const history = useHistory();
	const dispatch: any = useDispatch();
	const location: any = useLocation();
	const [title, setTitle] = useState('');
	const [getDmaIds, setGetDmaIds] = useState<any>([]);
	const [bulkSelection, setBulkSelection] = useState<boolean>(false);
	const [showAssignModal, setShowAssignModal] = useState<boolean>(false);
	const [dmaList, setDmaList] = useState([]);
	const [loadInfiniteInstance, setLoadInfiniteInstance] = useState<any>(true);
	const [disableInfiniteInstance, setDisableInfiniteInstance] =
		useState<any>(false);
	const [cardFooter, setCardFooter] = useState<any>(true);
	const [showTag, setShowTag] = useState<any>(true);
	const role = useSelector((state: any) => state?.users?.user_details);
	const dmaFilter = useSelector((state: any) => state?.dma);
	const [selectAll, setSelectAll] = useState(false);

	const getList = async (
		searchTerm = '',
		pageId: any = '',
		pageSize: any = '',
		resetList = false
	) => {
		setSelectAll(false);
		pageId = !pageId ? dmaPageSizeSelector?.pageId : pageId;
		pageSize = !pageSize ? dmaPageSizeSelector?.pageSize : pageSize;
		searchTerm =
			!searchTerm && !resetList
				? !dmaPageSizeSelector?.searchTerm
					? ''
					: dmaPageSizeSelector?.searchTerm
				: searchTerm;
		let _dmaList = dmaList;
		if (
			(pageId === dmaPageSizeSelector.defaultPageId && searchTerm) ||
			resetList
		) {
			_dmaList = [];
		}
		// to clear _dmaList old data I added this if condition
		if (
			pageId === dmaPageSizeSelector.defaultPageId &&
			pageSize === dmaPageSizeSelector.defaultPageSize &&
			!searchTerm
		) {
			_dmaList = [];
		}
		let apiParam = `?pageId=${pageId}&size=${pageSize}&status=${
			dmaSelector?.dmaFlow?.dmaCode
		}&searchItem=${sanitizeHtml(encodeURIComponent(searchTerm))}${
			dmaFilter?.dmaType ? `&dmaType=${dmaFilter?.dmaType}` : ''
		}${
			dmaFilter?.payoutProduct ? `&subproduct=${dmaFilter?.payoutProduct}` : ''
		}${dmaFilter?.payoutfrom ? `&from=${dmaFilter?.payoutfrom}` : ''}${
			dmaFilter?.payoutto ? `&to=${dmaFilter?.payoutto}` : ''
		}${dmaFilter?.dmafrom ? `&fromDate=${dmaFilter?.dmafrom}` : ''}${
			dmaFilter?.dmato ? `&toDate=${dmaFilter?.dmato}` : ''
		}${dmaFilter?.dmaRel ? `&dmaParentChild=${dmaFilter?.dmaRel}` : ''}${
			dmaFilter?.dmaRm ? `&reportingManager=${dmaFilter?.dmaRm}` : ''
		}${dmaFilter?.dmaCity ? `&locationType=${dmaFilter?.dmaCity}` : ''}`;
		if (dmaSelector?.dmaFlow?.isAndro) {
			apiParam += `&isandro=1`;
		}
		await dispatch(
			getdmaList({
				param: apiParam,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					if (response?.list.length) {
						if (searchTerm?.length > 0 && response?.list.length > 0) {
							const list = response?.list;
							setDmaList(list);
							handleBulkSelectionIds(list);
						} else {
							const list = _dmaList.concat(response?.list);
							setDmaList(list);
							handleBulkSelectionIds(list);
						}
						if (
							response?.list.length !== dmaPageSizeSelector?.defaultPageSize
						) {
							setLoadInfiniteInstance(false);
						} else {
							setLoadInfiniteInstance(true);
						}
					} else {
						setDmaList(_dmaList);
						handleBulkSelectionIds(_dmaList);
					}
				}
			});
	};

	const handleBulkSelectionIds = (dmaList: any) => {
		if (dmaSelector?.dmaFlow?.dmaCode === StatusNum.ALLOCATOR) {
			setBulkSelection(true);
			const setDmaIds = dmaList?.map(({ id }: any) => {
				return {
					id: id,
					checked: selectAll ? true : false,
				};
			});
			setGetDmaIds(setDmaIds);
		}
	};

	const handleBulkUpload = (event: any) => {
		const activeSelectedLeads = getDmaIds.map(({ id }: any) => {
			return {
				id: id,
				checked: event.detail.checked,
			};
		});
		setSelectAll(event.detail.checked);
		setGetDmaIds(activeSelectedLeads);
	};

	const isSelectAll =
		getDmaIds &&
		getDmaIds.length > 0 &&
		getDmaIds.every(({ checked }: any) => checked === true);

	const getDmaAllSelectedIds = getDmaIds
		.filter(({ checked }: any) => checked)
		.map(({ id }: any) => id);

	useEffect(() => {
		if (location.pathname !== '/team-list') {
			return;
		}
		setDisableInfiniteInstance(false);
		resetPaginationInfo();
		getList('');
	}, [dmaSelector?.dmaFlow?.update, location?.pathname]);

	const resetPaginationInfo = async () => {
		await dispatch(
			paginationSize({
				...dmaPageSizeSelector,
				pageSize: dmaPageSizeSelector?.defaultPageSize,
				pageId: dmaPageSizeSelector?.defaultPageId,
				searchTerm: '',
			})
		);
	};

	// useEffect(() => {
	// 	if (location?.pathname !== '/lead-list') {
	// 		return;
	// 	}
	// 	setDisableInfiniteInstance(false);
	// 	resetPaginationInfo();
	// 	getList();
	// }, [dmaSelector?.dmaFlow?.update]);

	// reset the list if not on current page
	useEffect(() => {
		if (location?.pathname !== '/team-list') {
			setDmaList([]);
			setGetDmaIds([]);
			setSelectAll(false);
			resetPaginationInfo();
		}
	}, [location?.pathname]);

	// useEffect added to handle pagination reset on page refresh
	useEffect(() => {
		window.addEventListener('beforeunload', resetPaginationInfo);
		return () => {
			window.removeEventListener('beforeunload', resetPaginationInfo);
		};
	}, []);

	const handleSearch = async (search: string) => {
		await dispatch(
			paginationSize({
				...dmaPageSizeSelector,
				searchTerm: search,
				pageId: dmaPageSizeSelector?.defaultPageId,
			})
		);
		if (search === '' || search.length >= 3) {
			getList(
				search,
				dmaPageSizeSelector?.defaultPageId,
				dmaPageSizeSelector?.defaultPageSize,
				true
			);
			setLoadInfiniteInstance(true);
		}
	};
	const cardClick = async ({ id, tag, status, mobile }: any) => {
		if (
			(dmaSelector?.dmaFlow?.dmaCode === StatusNum.VERIFICATION ||
				dmaSelector?.dmaFlow?.dmaCode === StatusNum.VERIFICATION1 ||
				dmaSelector?.dmaFlow?.dmaCode === StatusNum.VERIFICATION2 ||
				dmaSelector?.dmaFlow?.dmaCode === StatusNum.REWORKCOMPLETED) &&
			[USER_ROLES.CENTRALOPS, USER_ROLES.CENTRALOPSJR].includes(
				role?.roleType?.[0]
			)
		) {
			await dispatch(
				dmaFlowData({
					...dmaSelector?.dmaFlow,
					dmaId: id,
					dmaStatus: status,
					dmaListStatus: dmaSelector?.dmaFlow?.dmaCode,
					update: (dmaSelector?.dmaFlow?.update || 1) + 1,
				})
			);
			history.push('/dma-verify-steps');
		} else if (
			dmaSelector?.dmaFlow?.dmaCode === StatusNum.UNDERPROCESS &&
			[USER_ROLES.CENTRALOPS, USER_ROLES.CENTRALOPSJR].includes(
				role?.roleType?.[0]
			)
		) {
			await dispatch(
				dmaFlowData({
					...dmaSelector?.dmaFlow,
					dmaId: id,
					dmaStatus: status,
					dmaListStatus: dmaSelector?.dmaFlow?.dmaCode,
					update: (dmaSelector?.dmaFlow?.update || 1) + 1,
				})
			);
			history.push('/dma-verification-status');
		} else if (
			dmaSelector?.dmaFlow?.dmaCode === StatusNum.APPROVED &&
			[USER_ROLES.CENTRALOPS, USER_ROLES.CENTRALOPSJR].includes(
				role?.roleType?.[0]
			)
		) {
			await dispatch(
				dmaFlowData({
					...dmaSelector?.dmaFlow,
					dmaId: id,
					dmaStatus: status,
					dmaListStatus: dmaSelector?.dmaFlow?.dmaCode,
					update: (dmaSelector?.dmaFlow?.update || 1) + 1,
				})
			);
			history.push('/dma-approved-info');
		} else {
			history.push('/dma-details', {
				id: id,
				tag: tag,
				status: status,
				code: dmaSelector?.dmaFlow?.dmaCode,
				update: location?.state?.updateApi + 1 || 1,
			});
			await dispatch(
				dmaFlowData({
					...dmaSelector?.dmaFlow,
					dmaId: id,
					dmaTag: tag,
					dmaStatus: status,
					dmaMobile: mobile,
					update: (dmaSelector?.dmaFlow?.update || 1) + 1,
				})
			);
		}
	};

	useEffect(() => {
		const ch = dmaSelector?.dmaFlow?.dmaCode;
		let _title = '';
		let _showCardFooter = true;
		let _showTag = true;

		switch (ch) {
			case StatusNum.INIT:
				_title = t('DMA Soft Code Generation');
				break;
			case StatusNum.KYC:
			case StatusNum.KYC1:
			case StatusNum.KYC2:
			case StatusNum.KYC3:
			case StatusNum.KYC4:
				_title = t('KYC');
				break;
			case StatusNum.PAYOUT:
				_title = t('Add Payout');
				break;
			case StatusNum.PARENTCHILD:
				_title = t('Mark Parent or Child');
				break;
			case StatusNum.REWORK:
				_title = t('Rework Requested');
				break;
			case StatusNum.NEWAPPROVAL:
				_title = t('New DMA Approval & Payout');
				break;
			case StatusNum.EXISTINGAPPROVAL:
				_title = t('Existing DMA Approval & Payout');
				break;
			case StatusNum.REVISED:
				_title = t('Revised Payout');
				break;
			case StatusNum.UNDERPROCESS:
				_title = t('Under Process');
				if (
					[USER_ROLES.CENTRALOPS, USER_ROLES.CENTRALOPSJR].includes(
						role?.roleType?.[0]
					)
				) {
					_showTag = false;
					_showCardFooter = false;
				}
				break;
			case StatusNum.REJECTED:
				_title = t('Rejected');
				_showTag = false;
				break;
			case StatusNum.ACTIVE:
				_title = dmaSelector?.dmaFlow?.isAndro
					? t('My Andro Team')
					: t('My DMA');
				break;
			case StatusNum.INVITED:
				_title = t('Invitees');
				break;
			case StatusNum.ALLOCATOR:
				_title = t('Allocate DMA');
				break;
			case StatusNum.VERIFICATION:
				_title = t('DMA Verification');
				_showTag = false;
				_showCardFooter = false;
				break;
			case StatusNum.REWORKCOMPLETED:
				_title = t('Rework Completed');
				_showTag = false;
				_showCardFooter = false;
				break;
			case StatusNum.APPROVED:
				_title = t('Approved');
				_showTag = false;
				_showCardFooter = false;
				break;
			default:
				break;
		}
		setTitle(_title);
		setCardFooter(_showCardFooter);
		setShowTag(_showTag);
	}, [dmaSelector?.dmaFlow?.dmaCode]);

	const handleBackBtn = () => {
		// reset the pagination count
		setDisableInfiniteInstance(true);
		// load infinite loader for next card click
		setLoadInfiniteInstance(true);
		resetPaginationInfo();
		setSelectAll(false);
		history.push('/dashboard/teams');
	};

	return (
		<IonPage className='teams bg-web-white'>
			<Header
				needArrow={true}
				backButton={true}
				name={t(title)}
				needArrowOnClick={() => handleBackBtn()}
				endItem={
					dmaSelector?.dmaFlow?.dmaCode === StatusNum.ALLOCATOR && (
						<IonButtons slot='end'>
							<IonButtons className='cursor-pointer'>
								<IonImg
									className=''
									src='/images/icons/assign-icon.svg'
									alt='assign'
									onClick={() => {
										getDmaAllSelectedIds.length !== 0
											? setShowAssignModal(true)
											: showToastAlert({
													type: 'error',
													message: 'Please select a DMA',
											  });
									}}
								/>
							</IonButtons>
						</IonButtons>
					)
				}
			/>
			<div className='lead-card-container-spacing pb-md-2 py-md-0'>
				<div className='p-3 d-flex align-items-center bg-web-white'>
					{/* <IonSearchbar
						color={'white'}
						className='searchbar me-3'
						onIonInput={(e: any) => {
							handleSearch(e.detail.value);
						}}
						placeholder='Search DMA by “Name”'
						style={{ 'padding-inline-end': 'unset' }}
					/>
					<img
						src='/images/icons/filter.svg'
						alt='filter'
					/> */}
					<CustomSearchbar
						value={dmaPageSizeSelector?.searchTerm}
						searchIcon='/images/icons/searchbar-icon.svg'
						placeholder={
							dmaSelector?.dmaFlow?.dmaCode === StatusNum.ACTIVE &&
							dmaSelector?.dmaFlow?.isAndro
								? t('Search Team Member by “Name”').toString()
								: t('Search DMA by “Name”').toString()
						}
						className='search-bar-width-md'
						showClearButton='focus'
						onChange={(v: any) => {
							if (!v.length) {
								setSelectAll(false);
								setDmaList([]);
								setGetDmaIds([]);
							}
							handleSearch(v);
						}}
						needFilter={
							dmaSelector?.dmaFlow?.dmaCode === StatusNum.ACTIVE &&
							dmaSelector?.dmaFlow?.isAndro
								? false
								: true
						}
						onFilterClick={() => history.push('/filters')}
						onIonClear={() => {
							setSelectAll(false);
							setDmaList([]);
							setGetDmaIds([]);
							// reset the pagination count
							/*resetPaginationInfo();
							getList(
								'',
								dmaPageSizeSelector?.defaultPageId,
								dmaPageSizeSelector?.defaultPageSize,
								true
							);*/
						}}
					/>
				</div>
				{bulkSelection &&
				dmaSelector?.dmaFlow?.dmaCode === StatusNum.ALLOCATOR &&
				dmaList.length > 0 ? (
					<div className='px-3 pb-3 fs-14 fw-600'>
						<CustomCheckbox
							// onFocus={() => {
							// 	setSelectAll(!selectAll);
							// 	handleBulkUpload();
							// }}
							isChecked={isSelectAll}
							onChange={handleBulkUpload}
							labelPlacement='end'
							// indeterminate={!selectAll}
						>
							{t('Select All')}
						</CustomCheckbox>
					</div>
				) : (
					''
				)}
			</div>
			<IonContent
				style={{
					borderTop: '1px solid #00000029',
					height: 'calc(100vh - 113px)',
					overflowX: 'hidden',
					margin: '20px 15% !important',
				}}
			>
				{dmaList?.length ? (
					<div
						className=' lead-card-container-spacing bg-web'
						style={{
							overflowY: 'auto',
							minHeight: '100%',
							// height: `calc(100vh - ${bulkSelection &&
							// 	dmaSelector?.dmaFlow?.dmaCode === StatusNum.ALLOCATOR
							// 	? '181'
							// 	: '140'
							// 	}px)`,
						}}
					>
						<LeadContactCard
							leads={dmaList}
							getAllLead={dmaList}
							isShowFooter={cardFooter}
							getLeadIds={getDmaIds}
							setGetLeadIds={setGetDmaIds}
							bulkSelection={
								dmaSelector?.dmaFlow?.dmaCode === StatusNum.ALLOCATOR
									? true
									: false
							}
							showStatus={
								[USER_ROLES.CENTRALOPS, USER_ROLES.CENTRALOPSJR].includes(
									role?.roleType?.[0]
								)
									? dmaSelector?.dmaFlow?.dmaCode === StatusNum.UNDERPROCESS
										? true
										: false
									: ''
							}
							showTag={showTag}
							onClick={cardClick}
							from={
								[USER_ROLES.CENTRALOPS, USER_ROLES.CENTRALOPSJR].includes(
									role?.roleType?.[0]
								)
									? 'COPS'
									: 'BOPS'
							}
						/>
						{/* infinite scroller */}
						<IonInfiniteScroll
							threshold='20%'
							disabled={disableInfiniteInstance}
							onIonInfinite={(ev) => {
								if (!loadInfiniteInstance) {
									ev.target.complete();
									return;
								}
								const updatedPageId =
									dmaPageSizeSelector?.pageId +
									dmaPageSizeSelector?.defaultPageId;
								dispatch(
									paginationSize({
										...dmaPageSizeSelector,
										pageId: updatedPageId,
									})
								);
								getList(dmaPageSizeSelector?.searchTerm, updatedPageId);
								setTimeout(() => {
									ev.target.complete();
								}, 200);
							}}
						>
							<IonInfiniteScrollContent
								loadingSpinner={null}
							></IonInfiniteScrollContent>
						</IonInfiniteScroll>
					</div>
				) : (
					<CustomNoData />
				)}
			</IonContent>
			<CustomModal
				id='customModalRework'
				isOpen={showAssignModal}
				modalTitle={t('Select Manager')}
				initialBreakpoint={1}
				handleClose={() => setShowAssignModal(false)}
				breakpoints={[0, 1]}
				className='assignModal side-modal'
				modalContent={
					<ManagerListModal
						{...{
							setShowAssignModal,
							getDmaAllSelectedIds,
							setGetDmaIds,
							setDmaList,
							setSelectAll,
							handleSearch,
						}}
					/>
				}
				scrollY={true}
				needCross={true}
				needArrow={false}
			></CustomModal>
		</IonPage>
	);
};

const ManagerListModal = ({
	setShowAssignModal,
	getDmaAllSelectedIds,
	setGetDmaIds,
	setDmaList,
	setSelectAll,
	handleSearch,
}: any) => {
	const [teamMemberList, setTeamMemberList] = useState<any>([]);
	const [filteredList, setFilteredList] = useState<any>([]);
	const [showPageExitPopup, setShowPageExitPopup] = useState<boolean>(false);
	const [popupName, setPopupName] = useState<string>('');
	const [searchText, setSearchText] = useState<string>('');
	const [reportingManagerId, setReportingManagerId] = useState<number>(0);
	const dispatch: any = useDispatch();

	const getMembersList = async () => {
		await dispatch(
			bOpsManagerList({
				param: '?allocator=true',
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setTeamMemberList(response?.central_exec_list);
					setFilteredList(response?.central_exec_list);
				}
			});
	};

	const onSearchInput = (searchTerm: string) => {
		setSearchText(searchTerm);
		if (searchTerm !== '') {
			if (searchTerm.length >= 3) {
				const searchedList = teamMemberList.filter((member: any) => {
					const mName = member.full_name.toLowerCase();
					return mName.includes(searchTerm.toLowerCase());
				});
				setFilteredList(searchedList);
			}
		} else {
			getMembersList();
		}
	};

	const assignManagerPopup = (mId: number, mName: string) => {
		setReportingManagerId(mId);
		setPopupName(mName);
		setShowPageExitPopup(true);
	};

	const assignManager = async () => {
		await dispatch(
			allocatorAllocateManager({
				param: '/' + reportingManagerId,
				data: {
					dmaIds: JSON.stringify(getDmaAllSelectedIds),
				},
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setShowPageExitPopup(false);
					setShowAssignModal(false);
					setSelectAll(false);
					setGetDmaIds([]);
					setDmaList([]);
					handleSearch('');
				}
			});
	};

	useEffect(() => {
		getMembersList();
	}, []);

	return (
		<div>
			{/* <IonSearchbar
				className='team-dma-search px-0'
				placeholder='Search Team Member by “Name”'
				value={searchText}
				onIonChange={(e) => onSearchInput(e.detail.value!)}
				onIonClear={() => onSearchInput('')}
			></IonSearchbar> */}
			<CustomSearchbar
				value={searchText}
				searchIcon='/images/icons/searchbar-icon.svg'
				placeholder='Search Manager by “Name”'
				showClearButton='focus'
				onChange={onSearchInput}
				onIonClear={() => onSearchInput('')}
			/>
			<div className='mt-3'>
				{filteredList?.map((member: any, index: any) => {
					return (
						<IonItem
							key={index}
							className='ion-no-padding'
							lines='full'
							onClick={() => assignManagerPopup(member.id, member.full_name)}
						>
							<div className='d-flex flex-grow-1 align-items-center my-2'>
								<div style={{ width: '34px', height: '34px' }}>
									<InitialLetter
										value={member?.full_name}
										height='34px'
										width='34px'
										fontSize='22px'
									/>
								</div>
								<div className='ms-3'>
									<p className='mb-0 fs-16'>{member.full_name}</p>
								</div>
							</div>
						</IonItem>
					);
				})}
			</div>

			<CustomModal
				id='custommodal'
				isOpen={showPageExitPopup}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setShowPageExitPopup(false)}
				breakpoints={[]}
				className='central-ops-assign'
				modalContent={PageExitPopUp(
					() => {
						setShowPageExitPopup(false);
					},
					() => {
						assignManager();
					},
					'Cancel',
					'Confirm',
					'',
					'Are you sure you want to allocate selected DMA to <span class="primary-text-color">' +
						popupName +
						'</span> ?',
					'By doing this you will not be able to take any action on this DMA anymore.'
				)}
				needCross={false}
				needArrow={false}
			/>
		</div>
	);
};
