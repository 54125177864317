import { IonModal, IonContent, IonImg } from '@ionic/react';
import { useEffect, useState } from 'react';
// import { Clipboard } from '@capacitor/clipboard';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';

import './CustomModalLeadStatus.scss';
import {
	getSelectedLendersList,
	lenderSpfDetailsFlow,
} from '../../../../store/reducers/lead/leadSlice';
import { setBankIcon } from '../../../../helpers/functions';

interface Modal {
	isOpen?: boolean;
	handleClose?: any;
	modalContent?: any;
	messageText?: any;
	applicationData?: any;
	extraText?: any;
	link?: any;
	copyLink?: any;
	lenderData?: any;
}

export const CustomModalLeadStatus: React.FC<Modal> = ({
	isOpen,
	handleClose,
	messageText = 'Your application submitted successfully.',
	applicationData = '',
	extraText = '',
	copyLink = '',
	lenderData = '',
}) => {
	const [secondCount, setSecondCount] = useState(10);
	const [isOpenModal, setOpenModal] = useState(isOpen);
	// const [isCopied, setCopied] = useState(false);
	const history = useHistory();

	const { leadFlowData, lenderListData } = useSelector(
		(state: any) => state.lead
	);
	console.log(copyLink, 'copyLink');

	const dispatch = useDispatch();

	useEffect(() => {
		if (isOpen) {
			getLeadLender();
		}
	}, []);

	const getLeadLender = async () => {
		let isRedirectOnDashboard: any = true;
		await dispatch(
			getSelectedLendersList({
				param: '/' + leadFlowData?.leadId,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					response?.data?.forEach((el: any) => {
						if (!el.lender_int_comp) {
							isRedirectOnDashboard = false;
						}
					});
				}
			});
		setSecondCounter(isRedirectOnDashboard);
	};

	/**
	 * Set the timer for showing countdown to close modal
	 */
	const setSecondCounter = (isRedirectOnDashboard: any) => {
		let count = 21;
		const interval = setInterval(() => {
			count -= 1;
			setSecondCount(count);
			if (count === 0) {
				clearInterval(interval);
				setOpenModal(false);
				handleClose();
				dispatch(lenderSpfDetailsFlow({}));
				if (isRedirectOnDashboard || lenderListData?.length == 1) {
					history.push('/dashboard/lead');
				} else {
					history.push('/lender-specific-info');
				}
			}
		}, 1000);
	};

	/**
	 * Copy text
	 * @param text value
	 */
	// const copyText = async (text: any) => {
	// 	const finalText = text + `\n${extraText}`;
	// 	await Clipboard.write({
	// 		string: finalText,
	// 	});

	// 	setCopied(true);
	// 	const clearTime = setTimeout(() => {
	// 		setCopied(false);
	// 		clearTimeout(clearTime);
	// 	}, 3000);
	// };

	return (
		<IonModal
			isOpen={isOpenModal}
			style={{ '--height': extraText ? '400px' : '350px', '--width': '400px' }}
			className={'modal-border'}
			backdropDismiss={false}
		>
			<IonContent className='ion-no-padding'>
				<div
					className='modal_wrapper'
					style={{ height: extraText ? '500px' : '350px' }}
				>
					<div className='lender-specific-modal-logo p-0'>
						<IonImg
							alt='img'
							className='lender-specific-modal-logo selected-lender-bank-icon m-0'
							src={setBankIcon(lenderData?.icon)}
						/>
					</div>
					<h4 className='text_success'>{t(messageText)}</h4>

					<div className='text_app_no'>
						<h5>{t('Your application number is')}</h5>
						<div className='app_no_copied'>
							<div className='app_no_text'>
								{String(applicationData?.customerid)
									?.split('\n')
									?.map((el: any, k: any) => (
										<div
											key={k}
											className='app_no_content'
										>
											<span>
												{el?.length > 120 ? el?.substr(0, 120) + '...' : el}
											</span>
										</div>
									))}
							</div>
							{/* <IonIcon
								src='/images/icons/copy-icon.svg'
								className='copy_icon'
								onClick={() => copyText(applicationNo)}
							/>
							{isCopied && <small className='copied'>{t('Copied')}</small>} */}
						</div>
						{applicationData?.status == 'Accepted' && (
							<>
								<h5>
									{t('Sanction Limit :')}
									<span>{Math.round(applicationData?.sanctionLimit)}</span>
								</h5>

								<h5>
									{t('Highest Tenure :')}
									<span>{applicationData?.highestTenure}</span>
								</h5>
							</>
						)}
						{applicationData?.status == 'Suspended' && (
							<>
								<h5>
									{t('Eligible Limit :')}{' '}
									<span>{Math.round(applicationData?.inPrincipleLimit)}</span>
								</h5>

								<h5>
									{t('Eligible Tenure :')}
									<span>{applicationData?.inPrincipleTenure}</span>
								</h5>
							</>
						)}
						{applicationData?.status == 'Rejected' ? (
							<h5>
								{
									'Your loan application is not approved with Fibe due to internal criteria. Please reapply after 90 days'
								}
							</h5>
						) : (
							''
						)}

						{extraText && <p>{extraText}</p>}
					</div>

					<p>
						{t('It will be auto redirected in')} {secondCount} {t('seconds')}
					</p>
				</div>
			</IonContent>
		</IonModal>
	);
};
