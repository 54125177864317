/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef } from 'react';
import {
	IonCol,
	IonContent,
	IonGrid,
	IonImg,
	IonPage,
	IonRow,
	createGesture,
	Gesture,
	IonFooter,
	isPlatform,
	// useIonRouter,
} from '@ionic/react';
import { useHistory } from 'react-router';
import i18next from 'i18next';
import { App as CapacitorApp } from '@capacitor/app';
// import { useTranslation } from 'react-i18next';

import './ChooseLanguage.scss';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	apiChooseLang,
	apiLangList,
	// languageId,
} from '../../store/reducers/app/appSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { Header } from '../../components/layout/header/Header';
import {
	chooseLanguage,
	language,
} from '../../store/reducers/app/appStarterTemplateSlice';

interface languageData {
	id: number;
	name: string;
	code: string;
	createdAt: string;
	updatedAt: string;
}

export const ChooseLanguage: React.FC = () => {
	const history: any = useHistory();
	const dispatch: any = useDispatch();
	const [languages, setLanguage] = useState([]);

	const userData: any = useSelector((state: any) => state?.users);
	const appData: any = useSelector((state: any) => state.oneTimeScreen);
	const isBrowser = !isPlatform('capacitor');

	const { t } = useTranslation();
	const [selectedLanguage, setSelectedLanguage] = useState<any>(
		appData?.language
	);
	useEffect(() => {
		if (
			location.pathname === '/change-language' ||
			location.pathname === '/choose-language'
		) {
			getLangList();
		}
	}, [location.pathname]);

	const getLangList = async () => {
		await dispatch(apiLangList())
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setLanguage(response?.Language);
				}
			});
	};

	const chooseLanguages = async (val: any) => {
		if (userData?.user_details?.access_token) {
			const body: any = {
				lang_id: val?.id,
				user_id: userData?.user_details?.id,
			};
			await dispatch(apiChooseLang({ data: body }))
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						redirectToNextPage();
					}
				});
		} else {
			redirectToNextPage();
		}
	};

	useEffect(() => {
		getureInit();
		// i18next.changeLanguage(
		// 	selectedLanguage?.code
		// 		? selectedLanguage?.code
		// 		: chooseLanguages({
		// 				code: 'en',
		// 				id: 1,
		// 				name: 'English',
		// 		  })
		// );
		// selectedLanguage?.code
		// 	? ''
		// 	: setSelectedLanguage({
		// 			code: 'en',
		// 			id: 1,
		// 			name: 'English',
		// 	  });
		!selectedLanguage?.code &&
			setSelectedLanguage({
				code: 'en',
				id: 1,
				name: 'English',
			});
	}, []);
	const pageEl = useRef(null);
	const getureInit = () => {
		const card = pageEl.current;
		if (card) {
			const gesture: Gesture = createGesture({
				el: card,
				threshold: 15,
				gestureName: 'my-gesture',
				onEnd: (ev: any) => {
					if (ev.deltaX > window.innerWidth / 2) {
						CapacitorApp.exitApp();
					} else if (ev.deltaX < -window.innerWidth / 2) {
						/* CapacitorApp.exitApp(); */
					}
				},
			});
			gesture.enable();
		}
	};

	const redirectToNextPage = () => {
		console.log({ selectedLanguage });
		// const redirectToPage = redirectToNextPage ? '/login' : '/getting-started';
		i18next.changeLanguage(selectedLanguage.code);
		dispatch(language(selectedLanguage));
		if (!appData.language) {
			dispatch(chooseLanguage(true));
		}
		history.location.state?.nextPage
			? history.push(String(history.location.state.nextPage))
			: history.replace(isBrowser ? '/login' : '/getting-started');
	};

	const selectorToken = useSelector(
		(state: any) => state.users?.user_details?.access_token
	);
	return (
		<IonPage className=''>
			{selectorToken && (
				<Header
					backButton={true}
					needArrow={true}
					needArrowOnClick={() => {
						history.push('/settings');
					}}
					name=''
				/>
			)}
			<IonContent
				className=''
				ref={pageEl}
			>
				<div className='small-container bg-web h-100'>
					<div className='bg-web-white border-radius-10 h-100 overflow-auto'>
						<div className='choose-language'>
							{!selectorToken && (
								<IonImg
									src='/images/icons/logo.svg'
									className='ion-padding-top ion-padding-start ion-padding-end'
									style={{ width: '180px' }}
								/>
							)}
							<div className='ion-padding-top ion-padding-start ion-padding-end'>
								<h5 className='fs-16 fw-600 darkest-grey ion-no-margin pt-3 fw-600'>
									{t('Choose App Language')}
								</h5>
								<p className='fs-14 ion-no-margin ion-padding-top text-grey'>
									{t('Use OneAndro App in your preferred language.')}
								</p>
							</div>
							<h4 className='fs-14 fw-600 ion-no-margin ion-padding-horizontal common-used-language-title text-grey'>
								{t('Commonly used languages')}
							</h4>
							<IonGrid>
								<IonRow className=''>
									{languages?.map((item: languageData, index: number) => {
										return (
											index < 2 && (
												<IonCol
													key={index}
													size='6'
													sizeMd='4'
													sizeLg='3'
												>
													<div
														className={`common-language-box cursor-pointer ${
															item?.code === (selectedLanguage?.code || 'en')
																? 'active'
																: 'base'
														} `}
														onClick={() => setSelectedLanguage(item)}
													>
														<div className='fs-30 ion-justify-content-center ion-align-items-center round-sysmbol'>
															<h3 className='title fs-30 fw-600'>
																{item?.code === 'en' ? 'A' : 'अ'}
															</h3>
														</div>
														<div className='title'>
															<h5 className='fs-16 ion-no-margin  ion-text-center'>
																{item.name}
															</h5>
														</div>
													</div>
												</IonCol>
											)
										);
									})}
								</IonRow>
							</IonGrid>
							<h4 className='fs-14 fw-600 ion-no-margin ion-padding-horizontal other-regional-language-title text-grey'>
								{t('Other regional languages')}
							</h4>
							<IonGrid>
								<IonRow style={{ paddingBottom: '30px' }}>
									{languages?.map((item: languageData, index: number) => {
										return (
											index >= 2 && (
												<IonCol
													key={index}
													size='4'
													sizeMd='3'
													sizeLg='2'
												>
													<div
														className={`other-regional-languages h-100 d-flex align-items-center justify-content-center cursor-pointer ${
															item?.code === selectedLanguage?.code
																? 'active'
																: 'base'
														} `}
														onClick={() => {
															setSelectedLanguage(item);
														}}
													>
														<div className='title '>
															<h5 className='fs-16 ion-no-margin ion-text-center'>
																{item.name}
															</h5>
														</div>
													</div>
												</IonCol>
											)
										);
									})}
								</IonRow>
							</IonGrid>
						</div>
					</div>
				</div>
			</IonContent>
			<IonFooter>
				<div
					className='px-3 py-3'
					style={{
						backgroundColor: '#fff',
						zIndex: 2,
					}}
				>
					<CustomButton
						className='btn-blue fs-16 w-25-md'
						onClick={() =>
							selectedLanguage && chooseLanguages(selectedLanguage)
						}
						expand='block'
						disabled={
							selectedLanguage && !(Object.keys(selectedLanguage).length > 0)
						}
						title={t('Proceed')}
					/>
				</div>
			</IonFooter>
		</IonPage>
	);
};
