import {
	IonContent,
	IonFooter,
	IonImg,
	IonPage,
	isPlatform,
} from '@ionic/react';
import { Header } from '../../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	getLenderUserInfo,
	getProfessionalDetailsMaster,
} from '../../../store/reducers/user/userSlice';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { Player } from '@lottiefiles/react-lottie-player';
import { generateOnlineLead } from '../../../store/reducers/lead/leadSlice';
import DropDownModal from '../../../components/common/custom-dropdown/DropDownModal';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	FormatDate,
	InputFormatCurrencyValue,
	disableNumberArrows,
	restrictAmountChar,
	urltoFile,
} from '../../../helpers/functions';
import { UploadImageCard } from '../../dashboard/kyc/UploadImageCard';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { CustomCheckbox } from '../../../components/common/custom-checkbox/CustomCheckbox';
import CustomRadioButton from '../../../components/common/custom-radio-button/CustomRadioButton';
import BankUploadDocuments from '../BankOnlineForm/BankUploadDocuments';
import { ceil } from 'lodash';

export const LenderOnlineForm: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory<any>();
	const dispatch = useDispatch();
	const isBrowser = !isPlatform('capacitor');
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	const dmaSelector = useSelector((state: any) => state?.users);
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	const currentLenderInfo = useSelector(
		(state: any) => state?.lead?.selectedLender
	);
	const newLeadApplicantSelector = useSelector(
		(state: any) => state?.lead?.added_lead?.newLoanApplication
	);
	const [onlineFormFields, setOnlineFormFields] = useState<any>([]);
	const [showCheckIcon, setShowCheckIcon] = useState<boolean>(false);
	const [showOnlineFormSuccess, setShowOnlineFormSuccess] =
		useState<boolean>(false);
	const [showOnlineFormMessage, setShowOnlineFormMessage] = useState<any>('');
	const [dropdownMasterData, setDropdownMasterData] = useState<any>({});
	const [yupSchema, setYupSchema] = useState(yup.object().shape({}));
	const dropdownTypes = ['dropdown', 'dropdownSelect', 'dropdownModal'];
	const [openUploadModal, setOpenUploadModal] = useState(false);
	const [imagePreviewUpload, setImagePreviewUpload]: any = useState({});
	const [docTypeErrorMessage, setDocTypeErrorMessage]: any = useState({});
	const [clickImageFieldName, setClickImageFieldName] = useState<any>(null);
	const [formCPAddressFields, setFormCPAddressFields] = useState<any>({});
	const [page, setPage] = useState(1);

	const {
		handleSubmit,
		control,
		reset,
		setValue,
		trigger,
		getValues,
		// watch,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(yupSchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});
	const onSubmit = async (data: any) => {
		const setData: any = {};
		if (onlineFormFields.some((formKey: any) => formKey?.type === 'document')) {
			setData['doc'] = data?.doc;
		}
		onlineFormFields?.map((field: any) => {
			if (field.type === 'dropdownSelect') {
				const selectedOption = dropdownMasterData[field?.name]?.filter(
					(item: any) => item.id === data[field?.name]?.toString()
				);
				setData[field.name] = data[field.name] ? selectedOption[0]?.value : '';
			} else if (field?.type === 'document') {
				delete setData[field?.name];
			} else if (field?.field_value === 'number') {
				setData[field.name] = data[field.name]
					? parseFloat(data[field.name])
					: data[field.name];
			} else {
				setData[field.name] = data[field.name];
			}
		});
		await dispatch(
			generateOnlineLead({
				param: leadInfo?.leadId
					? leadInfo?.leadId
					: newLeadApplicantSelector?.id,
				data: { ...setData, lender_name: currentLenderInfo[0]?.lender_name },
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				setShowCheckIcon(response?.status ? true : false);
				setShowOnlineFormSuccess(true);
				setShowOnlineFormMessage(response?.message);
			});
		reset({});
	};

	const generateFormValidationSchemas = (fields: any) => {
		const schemaBuilderObject: any = {};
		fields?.forEach((formKeyName: any) => {
			if (formKeyName?.is_required) {
				const {
					match_regex,
					test = false,
					dependentLevel,
					dependentParentLabel,
					dependentParent,
					checkCondition,
					min,
					max,
					trim,
					is_comma_separated = false,
				} = formKeyName;
				if (is_comma_separated) {
					schemaBuilderObject[formKeyName.name] = yup.number();
					schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
						formKeyName.name
					]
						.typeError(
							formKeyName.placeholder.replaceAll('*', '') + ' must be a number'
						)
						.required(
							typeof formKeyName?.required_error_message === 'string' &&
								formKeyName?.required_error_message
								? formKeyName?.required_error_message
								: formKeyName.placeholder.replaceAll('*', '') +
										' is a required field'
						);
					if (min) {
						schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
							formKeyName.name
						].min(
							formKeyName['min'],
							`${formKeyName.placeholder.replaceAll(
								'*',
								''
							)} should not be zero or empty`
						);
					}
				} else if (dropdownTypes.includes(formKeyName.type)) {
					if (formKeyName.name === 'company_type') {
						schemaBuilderObject[formKeyName.name] = yup
							.array()
							.required(
								typeof formKeyName?.required_error_message === 'string' &&
									formKeyName?.required_error_message
									? formKeyName?.required_error_message
									: formKeyName.placeholder.replaceAll('*', '') +
											' is a required field'
							);
					} else {
						schemaBuilderObject[formKeyName.name] = yup
							.number()
							.required(
								typeof formKeyName?.required_error_message === 'string' &&
									formKeyName?.required_error_message
									? formKeyName?.required_error_message
									: formKeyName.placeholder.replaceAll('*', '') +
											' is a required field'
							);
					}
				} else if (formKeyName.type === 'number') {
					schemaBuilderObject[formKeyName.name] = yup.string();
					schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
						formKeyName.name
					].required(
						typeof formKeyName?.required_error_message === 'string' &&
							formKeyName?.required_error_message
							? formKeyName?.required_error_message
							: formKeyName.placeholder.replaceAll('*', '') +
									' is a required field'
					);

					if (max) {
						schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
							formKeyName.name
						].max(
							formKeyName?.max,
							typeof formKeyName?.max_error_message === 'string' &&
								formKeyName?.max_error_message
								? formKeyName?.max_error_message
								: `${
										formKeyName.placeholder.replaceAll('*', '') +
										'should not more than ' +
										formKeyName?.max
								  }`
						);
					}
					if (match_regex) {
						schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
							formKeyName.name
						].matches(
							new RegExp(formKeyName['match_regex']),
							typeof formKeyName?.regex_error_message === 'string' &&
								formKeyName?.regex_error_message
								? formKeyName?.regex_error_message
								: formKeyName.placeholder.replaceAll('*', '') +
										' should be a valid format'
						);
					}
				} else if (formKeyName.type === 'date') {
					schemaBuilderObject[formKeyName.name] = yup
						.date()
						.required('Date of birth is required') // Make it required
						.max(
							new Date(),
							`${formKeyName.placeholder.replaceAll(
								'*',
								''
							)} must be in the past`
						);
				} else if (formKeyName.type === 'text') {
					schemaBuilderObject[formKeyName.name] = yup.string();
					schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
						formKeyName.name
					].required(
						typeof formKeyName?.required_error_message === 'string' &&
							formKeyName?.required_error_message
							? formKeyName?.required_error_message
							: formKeyName.placeholder.replaceAll('*', '') +
									' is a required field'
					);
					if (trim) {
						schemaBuilderObject[formKeyName.name] =
							schemaBuilderObject[formKeyName.name].trim();
					}
					if (max) {
						schemaBuilderObject[formKeyName.name] = yup
							.string()
							.max(
								formKeyName?.max,
								typeof formKeyName?.max_error_message === 'string' &&
									formKeyName?.max_error_message
									? formKeyName?.max_error_message
									: `${
											formKeyName.placeholder.replaceAll('*', '') +
											'should not more than ' +
											formKeyName?.max
									  }`
							);
					}
					if (match_regex) {
						schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
							formKeyName.name
						].matches(
							new RegExp(formKeyName['match_regex']),
							typeof formKeyName?.regex_error_message === 'string' &&
								formKeyName?.regex_error_message
								? formKeyName?.regex_error_message
								: formKeyName.placeholder.replaceAll('*', '') +
										' should be a valid format'
						);
					}
				} else {
					schemaBuilderObject[formKeyName.name] = formKeyName.match_regex
						? yup
								.string()
								.required(
									typeof formKeyName?.required_error_message === 'string' &&
										formKeyName?.required_error_message
										? formKeyName?.required_error_message
										: formKeyName.placeholder.replaceAll('*', '') +
												' is a required field'
								)
								.matches(
									new RegExp(formKeyName.match_regex),
									typeof formKeyName?.regex_error_message === 'string' &&
										formKeyName?.regex_error_message
										? formKeyName?.regex_error_message
										: formKeyName.placeholder.replaceAll('*', '') +
												' should be a valid format'
								)
						: yup
								.string()
								.required(
									typeof formKeyName?.required_error_message === 'string' &&
										formKeyName?.required_error_message
										? formKeyName?.required_error_message
										: formKeyName.placeholder.replaceAll('*', '') +
												' is a required field'
								);
				}
				if (test) {
					if (
						dependentLevel == '0' &&
						checkCondition &&
						typeof dependentParent === 'string'
					) {
						schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
							formKeyName.name
						].test(
							'custom-test-logic',
							formKeyName.placeholder.replaceAll('*', '') +
								'should not be more than ' +
								dependentParentLabel,
							function (this: yup.TestContext, value: string): boolean {
								const operation = checkCondition || '<=';
								const leftOperand = value;
								const { [dependentParent]: rightOperand } = this.parent;
								let result;
								switch (operation) {
									case '<':
										result = parseInt(leftOperand) < parseInt(rightOperand);
										break;
									case '<=':
										result = parseInt(leftOperand) <= parseInt(rightOperand);
										break;
									case '>':
										result = parseInt(leftOperand) > parseInt(rightOperand);
										break;
									case '>=':
										result = parseInt(leftOperand) >= parseInt(rightOperand);
										break;
									case '==':
										result = parseInt(leftOperand) == parseInt(rightOperand);
										break;
									case '===':
										result = parseInt(leftOperand) === parseInt(rightOperand);
										break;
									default:
										result = true;
								}
								return result;
							}
						);
					}
				}
			} else {
				if (formKeyName?.is_comma_separated) {
					schemaBuilderObject[formKeyName.name] = yup
						.number()
						.typeError(formKeyName.placeholder + ' must be a number');
				} else {
					schemaBuilderObject[formKeyName.name] =
						formKeyName?.match_regex &&
						yup.string().matches(new RegExp(formKeyName.match_regex), {
							message:
								typeof formKeyName?.regex_error_message === 'string' &&
								formKeyName?.regex_error_message
									? formKeyName?.regex_error_message
									: formKeyName.placeholder + ' should be a valid format',
							excludeEmptyString: true,
						});
				}
			}
		});
		setYupSchema(yupSchema.concat(yup.object().shape(schemaBuilderObject)));
	};
	const getLendersFormData = async () => {
		// get all fields from api
		await dispatch(
			getLenderUserInfo({
				param: leadInfo?.leadId
					? leadInfo?.leadId
					: newLeadApplicantSelector?.id,
				data: {
					lender_name: currentLenderInfo[0]?.lender_name,
				},
			})
		)
			.then(unwrapResult)
			.then((res: any) => {
				if (res?.status) {
					const _data = [
						{
							name: 'first_name',
							type: 'text',
							placeholder: 'Applicant First Name *',
							is_required: true,
							match_regex: '^[a-zA-Z][a-zA-Z ]*$',
						},
						{
							name: 'last_name',
							type: 'text',
							placeholder: 'Applicant Last Name *',
							is_required: true,
							match_regex: '^[a-zA-Z][a-zA-Z ]*$',
						},
						{
							name: 'dob',
							type: 'date',
							placeholder: 'Applicant Date of Birth *',
							is_required: true,
						},
						{
							name: 'salutation',
							type: 'text',
							placeholder: 'Applicant Salutation *',
							is_required: true,
						},
						{
							name: 'mobile_number',
							type: 'text',
							field_value: 'number',
							placeholder: 'Applicant Mobile Number *',
							is_required: true,
							match_regex: '^[6-9][0-9]{0,9}$',
						},
						{
							name: 'pan',
							type: 'text',
							placeholder: 'Applicant PAN *',
							is_required: true,
						},
						{
							name: 'gender',
							type: 'dropdownSelect',
							placeholder: 'Applicant Gender *',
							key: ['GENDER'],
							is_required: true,
						},
						{
							name: 'employment_type',
							type: 'dropdownSelect',
							placeholder: 'Applicant Employment Type *',
							key: ['BAJAJ_EMPLOYMENT_TYPE'],
							is_required: true,

							//
						},
						{
							name: 'nature_of_business',
							type: 'dropdownSelect',
							placeholder: 'Applicant Nature of Business *',
							key: ['BAJAJ_NATURE_OF_BUSINESS'],
							is_required: true,
						},
						{
							name: 'email',
							type: 'email',
							placeholder: 'Applicant Email *',
							is_required: true,
							match_regex:
								'^([\\w-]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([a-zA-Z]{2,6}(?:\\.[a-zA-Z]{2})?)+$',
						},
						{
							name: 'total_employment_vintage_in_years',
							type: 'text',
							field_value: 'number',
							placeholder: 'Applicant Total Employment Vintage In Years *',
							is_required: true,
						},
						{
							name: 'constitution',
							type: 'text',
							placeholder: 'Applicant Constitution *',
							is_required: true,
						},
						{
							name: 'marital_status',
							type: 'dropdownSelect',
							placeholder: 'Applicant Marital Status',
							key: ['MARITAL_STATUS'],
							is_required: false,
						},
						{
							name: 'profession',
							type: 'text',
							placeholder: 'Applicant Profession',
							is_required: false,
						},
						{
							name: 'customer_type',
							type: 'text',
							placeholder: 'Applicant Customer Type *',
							is_required: true,
						},
						{
							name: 'company_name',
							type: 'text',
							placeholder: 'Applicant Company Name *',
							is_required: true,
						},
						{
							name: 'customer_id',
							type: 'text',
							field_value: 'number',
							placeholder: 'Applicant Customer Id',
							is_required: false,
						},
						{
							name: 'pincode',
							type: 'text',
							field_value: 'number',
							placeholder: 'Applicant Pincode *',
							is_required: true,
						},
						{
							name: 'address_line_1',
							type: 'text',
							placeholder: 'Applicant Address Line 1 *',
							is_required: true,
							match_regex: '^.{1,40}$',
						},
						{
							name: 'address_line_2',
							type: 'text',
							placeholder: 'Applicant Address Line 2 *',
							is_required: true,
						},
						{
							name: 'address_line_3',
							type: 'text',
							placeholder: 'Applicant Address Line 3 *',
							is_required: true,
						},
						{
							name: 'permanent_address_pincode',
							type: 'text',
							field_value: 'number',
							placeholder: 'Applicant Permanent Address Pincode *',
							is_required: true,
						},
						{
							name: 'permanent_address_line_1',
							type: 'text',
							placeholder: 'Applicant Permanent Address Line 1 *',
							is_required: true,
							match_regex: '^.{1,40}$',
						},
						{
							name: 'permanent_address_line_2',
							type: 'text',
							placeholder: 'Applicant Permanent Address Line 2 *',
							is_required: true,
						},
						{
							name: 'permanent_address_line_3',
							type: 'text',
							placeholder: 'Applicant Permanent Address Line 3 *',
							is_required: true,
						},
						{
							name: 'office_address_pincode',
							type: 'text',
							field_value: 'number',
							placeholder: 'Applicant Office Address Pincode *',
							is_required: true,
						},
						{
							name: 'office_address_line_1',
							type: 'text',
							placeholder: 'Applicant Office Address Line 1 *',
							is_required: true,
							match_regex: '^.{1,40}$',
						},
						{
							name: 'office_address_line_2',
							type: 'text',
							placeholder: 'Applicant Office Address Line 2 *',
							is_required: true,
						},
						{
							name: 'office_address_line_3',
							type: 'text',
							placeholder: 'Applicant Office Address Line 3 *',
							is_required: true,
						},
						{
							name: 'bfl_customer_type',
							type: 'text',
							placeholder: 'Applicant Bfl Customer Type',
							is_required: false,
						},
						{
							name: 'partner_sales_allocation_id',
							type: 'text',
							placeholder: 'Applicant Partner Sales Allocation Id *',
							is_required: true,
						},
						{
							name: 'sourcing_channel_category',
							type: 'text',
							placeholder: 'Applicant Sourcing Channel Category *',
							is_required: true,
						},
						{
							name: 'sourcing_channel',
							type: 'text',
							placeholder: 'Applicant Sourcing Channel *',
							is_required: true,
						},
						{
							name: 'sourcing_branch',
							type: 'text',
							field_value: 'number',
							placeholder: 'Applicant Sourcing Branch *',
							is_required: true,
						},
						{
							name: 'product',
							type: 'text',
							placeholder: 'Applicant Product *',
							is_required: true,
						},
						{
							name: 'utm_medium',
							type: 'text',
							placeholder: 'Applicant Utm Medium',
							is_required: false,
						},
						{
							name: 'utm_source',
							type: 'text',
							placeholder: 'Applicant Utm Source',
							is_required: false,
						},
						{
							name: 'utm_content',
							type: 'text',
							placeholder: 'Applicant Utm Content',
							is_required: false,
						},
						{
							name: 'utm_campaign',
							type: 'text',
							placeholder: 'Applicant Utm Campaign',
							is_required: false,
						},
						{
							name: 'process_type',
							type: 'text',
							placeholder: 'Applicant Process Type *',
							is_required: true,
						},
						{
							name: 'program_category',
							type: 'text',
							placeholder: 'Applicant Program Category *',
							is_required: true,
						},
						{
							name: 'program_type',
							type: 'text',
							placeholder: 'Applicant Program Type *',
							is_required: true,
						},
						{
							name: 'source_system',
							type: 'text',
							placeholder: 'Applicant Source System *',
							is_required: true,
						},
						{
							name: 'offer_source',
							type: 'text',
							placeholder: 'Applicant Offer Source',
							is_required: false,
						},
						{
							name: 'customer_disposition',
							type: 'text',
							placeholder: 'Applicant Customer Disposition *',
							is_required: true,
						},
						{
							name: 'sales_dispostion_1',
							type: 'text',
							placeholder: 'Applicant Sales Disposition 1 *',
							is_required: true,
						},
						{
							name: 'requested_roi',
							type: 'text',
							field_value: 'number',
							placeholder: 'Applicant Requested ROI *',
							is_required: true,
						},
						{
							name: 'requested_irr',
							type: 'text',
							field_value: 'number',
							placeholder: 'Applicant Requested IRR *',
							is_required: true,
						},
						{
							name: 'requested_loan_amount',
							type: 'text',
							field_value: 'number',
							placeholder: 'Applicant Requested Loan Amount *',
							is_required: true,
						},
						{
							name: 'requested_tenor_in_months',
							type: 'text',
							field_value: 'number',
							placeholder: 'Applicant Requested Tenure In Months *',
							is_required: true,
						},
						// {
						// 	name: 'is_stp',
						// 	type: 'radio',
						// 	placeholder: 'Applicant Is Stp *',
						// 	is_required: true,
						// },
						// {
						// 	name: 'locked',
						// 	type: 'radio',
						// 	placeholder: 'Applicant Locked *',
						// 	is_required: true,
						// },
						{
							name: 'offer_id',
							type: 'text',
							placeholder: 'Applicant Offer Id',
							is_required: false,
						},
						{
							name: 'offer_type',
							type: 'text',
							placeholder: 'Applicant Offer Type *',
							is_required: true,
						},
						{
							name: 'loan_variant',
							type: 'text',
							placeholder: 'Applicant Loan Variant *',
							is_required: true,
						},
						{
							name: 'subsequent_tenor',
							type: 'text',
							field_value: 'number',
							placeholder: 'Applicant Subsequent Tenure *',
							is_required: true,
						},
						{
							name: 'emi_amount',
							type: 'text',
							field_value: 'number',
							placeholder: 'Applicant Emi Amount',
							is_required: false,
						},
						{
							name: 'process_segmentation',
							type: 'text',
							placeholder: 'Applicant Process Segmentation *',
							is_required: true,
						},
						{
							name: 'endUse',
							type: 'text',
							placeholder: 'Applicant EndUse',
							is_required: false,
						},
						{
							name: 'existing_Line_Id',
							type: 'text',
							placeholder: 'Applicant Existing Line Id',
							is_required: false,
						},
						{
							name: 'process_segmentation_pltb',
							type: 'text',
							placeholder: 'Applicant Process Segmentation PTLB',
							is_required: false,
						},
						{
							name: 'sales_dispostion_2',
							type: 'text',
							placeholder: 'Applicant Sales Dispostion 2 *',
							is_required: true,
						},
						{
							name: 'telecaller_dispostion_status',
							type: 'text',
							placeholder: 'Applicant Telecaller Dispostion Status *',
							is_required: true,
						},
						{
							name: 'existing_lan',
							type: 'text',
							placeholder: 'Applicant Existing Lan',
							is_required: false,
						},
						{
							name: 'risk_band',
							type: 'text',
							placeholder: 'Applicant Risk Band',
							is_required: false,
						},
						{
							name: 'pre_qualified_offer_amount_max',
							type: 'text',
							field_value: 'number',
							placeholder: 'Applicant Pre Qualified Offer Amount Max *',
							is_required: true,
						},
						{
							name: 'pq_emi',
							type: 'text',
							field_value: 'number',
							placeholder: 'Applicant PQ Emi *',
							is_required: true,
						},
						{
							name: 'pq_offer_tenor',
							type: 'text',
							field_value: 'number',
							placeholder: 'Applicant PQ Offer Tenure *',
							is_required: true,
						},
						{
							name: 'pre_qualified_roi',
							type: 'text',
							field_value: 'number',
							placeholder: 'Applicant Pre Qualified ROI *',
							is_required: true,
						},
						{
							name: 'pre_qualified_irr',
							type: 'text',
							field_value: 'number',
							placeholder: 'Applicant Pre Qualified IRR *',
							is_required: true,
						},
						{
							name: 'pre_qualified_offer_roi_monthly',
							type: 'text',
							field_value: 'number',
							placeholder: 'Applicant Pre Qualified Offer ROI Monthly *',
							is_required: true,
						},
						{
							name: 'referance_booking_id',
							type: 'text',
							placeholder: 'Applicant Referance Booking Id',
							is_required: false,
						},
						{
							name: 'bajajDoc',
							type: 'document',
							placeholder: 'Document *',

							is_required: true,
						},
					];

					// check any field as is_required true than generate validation schema
					if (_data.some((formKey: any) => formKey?.is_required === true)) {
						generateFormValidationSchemas(_data);
					}

					// generate doc array if {} for documents(upload)
					if (_data.some((formKey: any) => formKey?.type === 'document')) {
						const uploadDocsCount = _data?.filter(
							(item: any) => item.type === 'document'
						);
						uploadDocsCount?.forEach((uploadKeyName: any, index: any) => {
							setValue(`doc[${index}].key`, uploadKeyName?.name);
							setValue(`doc[${index}].fileName`, '');
						});
					}

					// run loop for dropdown api data
					_data?.forEach((formKeyName: any) => {
						if (formKeyName.type === 'dropdownSelect') {
							dispatch(getProfessionalDetailsMaster({ param: formKeyName.key }))
								.then(unwrapResult)
								.then((dropdownResponse: any) => {
									if (dropdownResponse?.status) {
										setDropdownMasterData((masterData: any) => {
											return {
												...masterData,
												[formKeyName.name]: dropdownResponse.data,
											};
										});
									}
								});
						} else if (formKeyName.type === 'document') {
							setImagePreviewUpload((old: any) => {
								return { ...old, [formKeyName?.name]: false };
							});
						} else if (
							formKeyName.type === 'checkbox' &&
							formKeyName?.is_current_permanent_address
						) {
							setFormCPAddressFields(formKeyName);
						}

						// set value for form fields whatever the value present in api value key
						if (formKeyName.type === 'date') {
							setValue(
								formKeyName.name,
								FormatDate(formKeyName.value, 'yyyy-mm-dd')
							);
						} else {
							setValue(formKeyName.name, formKeyName.value);
						}
					});

					// set all form fields
					const inputFields: any = _data?.every((key: any) => 'seq' in key)
						? _data?.slice()?.sort((a: any, b: any) => a?.seq - b?.seq)
						: _data;
					setOnlineFormFields(inputFields);
				}
			});
	};

	useEffect(() => {
		if (location.pathname !== '/lender-online-form') {
			return;
		}
		setShowCheckIcon(false);
		setShowOnlineFormSuccess(false);
		setShowOnlineFormMessage('');
		getLendersFormData();
		return () => {
			reset({});
			setYupSchema(yup.object().shape({}));
		};
	}, [location.pathname]);

	const paginate = (items: any, page = 1, perPage = 10) =>
		items.slice(perPage * (page - 1), perPage * page);

	// console.log(errors);
	// const errorIndex =
	// 	onlineFormFields.findIndex(
	// 		(val: any) => val?.name === Object.keys(errors)[0]
	// 	) + 1;

	// console.log(Object.keys(errors));
	// console.log(ceil(errorIndex / 10));

	// const arr = Object.entries(watch()).map(([key, value]) => ({ [key]: value }));
	// console.log(watch());

	// console.log(
	// 	arr.map((item: any) => {
	// 		// if (watch(Object.keys(item)[0]) == undefined) {
	// 		// 	return arr.findIndex(
	// 		// 		(data: any) =>
	// 		// 			watch(Object.keys(data)[0]) == watch(Object.keys(item)[0])
	// 		// 	);
	// 		// }else
	// 		if (Object.keys(item)[0] == 'doc') {
	// 			watch(Object.keys(item)[0])[0]?.fileName == '' ?

	// 			arr.findIndex((data: any) => {
	// 				console.log(
	// 					watch(Object.keys(data)[0]),
	// 					'-----',
	// 					watch(Object.keys(item)[0])
	// 				);

	// 				watch(Object.keys(data)[0]) == watch(Object.keys(item)[0]);
	// 			});
	// 		}
	// 	})
	// );

	return (
		<IonPage>
			<Header
				name={t('Lender Online Form')}
				backButton={true}
				needArrow={true}
				needArrowOnClick={() => {
					leadInfo?.from === 'nimbuss'
						? history.push(
								'/lead-details/' +
									leadInfo.leadId +
									'/' +
									dmaSelector?.user_details?.access_token
						  )
						: leadInfo?.isBackLenderOnlineForm
						? history.goBack()
						: history.push('/dashboard/lead');
				}}
				className={showOnlineFormSuccess ? 'd-none' : ''}
			/>
			<IonContent>
				{!showOnlineFormSuccess ? (
					<div className='select-product small-container bg-web  overflow-auto'>
						<div className='h-100 bg-web-white container-width-md px-3 py-2 p-md-5 border-radius-10'>
							<form
								className='ofform'
								onSubmit={handleSubmit(onSubmit)}
							>
								{paginate(onlineFormFields, page)?.map(
									({
										name,
										placeholder,
										type,
										value,
										exclude_number_validation,
										dependentLevel,
										triggerValidation,
										is_comma_separated = false,
										is_current_permanent_address = false,
										permanent_address_fields,
										current_address_fields,
										radio_options,
										is_required,
									}: any) =>
										// index: any
										{
											const errorText: any =
												errors && Object.keys(errors).length > 0 && errors;
											if (type === 'dropdownSelect') {
												return (
													<div key={name}>
														<Controller
															key={name}
															control={control}
															render={({
																field: { onChange, onBlur, name },
															}) => (
																<DropDownModal
																	label={
																		t(`${placeholder}`) +
																		(is_required ? ' *' : '')
																	}
																	data={
																		dropdownMasterData[name]
																			? dropdownMasterData[name]
																			: []
																	}
																	name={name}
																	onChange={onChange}
																	onBlur={onBlur}
																	className={
																		errorText[name]?.message
																			? 'ion-invalid ion-touched mb-3'
																			: 'mb-3'
																	}
																	errors={t(errorText[name]?.message as string)}
																	value={getValues(name)}
																	disabled={
																		value
																			? true
																			: formCPAddressFields?.is_current_permanent_address &&
																			  getValues(formCPAddressFields?.name)
																			? formCPAddressFields?.permanent_address_fields.includes(
																					name
																			  )
																			: false
																	}
																/>
															)}
															name={name}
														/>
													</div>
												);
											} else if (type === 'document') {
												return (
													<React.Fragment key={name}>
														{imagePreviewUpload[name] ? (
															<div className='py-1 fs-14'>
																{t(placeholder) + (is_required ? ' *' : '')}
															</div>
														) : (
															''
														)}
														<UploadImageCard
															{...{
																setImagePreviewUpload,
																imagePreviewUpload,
																isImageAvilable: imagePreviewUpload[name],
																setClickImageFieldName,
																setOpenUploadModal,
																imgKey: name,
																setValue: (valKey: any, valText: any) => {
																	setValue(valKey, valText);
																	trigger(valKey);
																},
																value: name,
																title:
																	t(placeholder) + (is_required ? ' *' : ''),
																src: getValues(name),
															}}
														/>
														{!imagePreviewUpload[name] &&
														!docTypeErrorMessage[name] &&
														errorText[name]?.message ? (
															<div className=''>
																<div className='error-text sc-ion-input-md fs-12'>
																	{t(errorText[name]?.message)}
																</div>
															</div>
														) : (
															docTypeErrorMessage[name] && (
																<div className=''>
																	<div className='error-text sc-ion-input-md fs-12'>
																		{t(docTypeErrorMessage[name])}
																	</div>
																</div>
															)
														)}
													</React.Fragment>
												);
											} else if (type === 'checkbox') {
												return (
													<div key={name}>
														<Controller
															control={control}
															render={({
																field: { onChange, onBlur, value },
															}) => (
																<CustomCheckbox
																	name={name}
																	onChange={({ detail: { checked } }: any) => {
																		onChange(checked);
																		if (is_current_permanent_address) {
																			if (checked) {
																				permanent_address_fields?.map(
																					(item: any, index: any) => {
																						setValue(
																							item,
																							getValues(
																								current_address_fields[index]
																							)
																						);
																						trigger(item);
																					}
																				);
																			} else {
																				permanent_address_fields?.map(
																					(item: any) => {
																						setValue(item, '');
																						trigger(item);
																					}
																				);
																			}
																		}
																	}}
																	value={value}
																	labelPlacement='end'
																	className='end-of-page'
																	onBlur={onBlur}
																	isChecked={value}
																	children={
																		<span className='text-wrap'>
																			{t(placeholder)}
																		</span>
																	}
																></CustomCheckbox>
															)}
															name={name}
														/>
													</div>
												);
											} else if (type === 'radio') {
												return (
													<div key={name}>
														<Controller
															key={name}
															control={control}
															render={({ field: { onChange, name } }) => (
																<div className='mb-2'>
																	<h6 className='mt-3'>
																		{t(placeholder) + (is_required ? ' *' : '')}
																	</h6>
																	<CustomRadioButton
																		type='group'
																		value={getValues(name)}
																		onIonChange={(e: any) => {
																			onChange(e);
																		}}
																		className='mb-2'
																		ionRadioClassName='me-4'
																		labelPlacement='end'
																		data={
																			Array.isArray(radio_options) &&
																			radio_options?.length
																				? radio_options
																				: []
																		}
																	/>
																	<div className='error-text px-3 sc-ion-input-md'>
																		{t(errorText[name]?.message)}
																	</div>
																</div>
															)}
															name={name}
														/>
													</div>
												);
											} else if (type === 'date') {
												return (
													<div key={name}>
														<Controller
															key={name}
															control={control}
															render={({
																field: { onChange, onBlur, name },
															}) => (
																<CustomInput
																	type={type}
																	needSuffixIcon={true}
																	suffixClassName={
																		'position-absolute date-of-birth-sufix'
																	}
																	suffixText={
																		<IonImg
																			src='/images/icons/input-dob-calendar-icon.svg'
																			alt='dob'
																		/>
																	}
																	outerClassName={'position-relative'}
																	placeholder={
																		t(placeholder) + (is_required ? ' *' : '')
																	}
																	onBlur={onBlur}
																	name={name}
																	onChange={onChange}
																	// errors={t(errors[name]?.message)}
																	errors={t(errors[name]?.message as any)}
																	value={t(getValues(name))}
																	disabled={value ? true : false}
																/>
															)}
															name={name}
														/>
													</div>
												);
											} else {
												return (
													<div key={name}>
														<Controller
															key={name}
															control={control}
															render={({
																field: { onChange, onBlur, name },
															}) => (
																<CustomInput
																	type={type}
																	placeholder={
																		t(placeholder) + (is_required ? ' *' : '')
																	}
																	onBlur={onBlur}
																	name={name}
																	onChange={(e: any) => {
																		if (is_comma_separated) {
																			e = isNaN(
																				e.detail.value.replaceAll(',', '')
																			)
																				? ''
																				: Number(
																						e.detail.value.replace(/,/g, '')
																				  );
																		}
																		if (
																			dependentLevel == '1' &&
																			Array.isArray(triggerValidation)
																		) {
																			if (triggerValidation.length > 0) {
																				triggerValidation?.map((item: any) => {
																					if (getValues(item) != undefined) {
																						trigger(item);
																					}
																				});
																			}
																		}
																		onChange(e);
																	}}
																	errors={t(errors[name]?.message as any)}
																	value={t(
																		is_comma_separated
																			? getValues(name)
																				? InputFormatCurrencyValue(
																						getValues(name)
																				  )
																				: getValues(name)
																			: getValues(name)
																	)}
																	onBeforeInput={(evt: any) => {
																		if (type == 'number') {
																			if (!exclude_number_validation) {
																				!/[0-9]/.test(evt.data) &&
																					evt.preventDefault();
																			} else {
																				!/[0-9.]/.test(evt.data) &&
																					evt.preventDefault();
																			}
																		} else if (
																			is_comma_separated &&
																			type == 'text'
																		) {
																			restrictAmountChar(
																				evt,
																				getValues(name)?.toString()
																			);
																		}
																	}}
																	onKeyDown={(e: any) => {
																		if (type == 'number') {
																			disableNumberArrows(e);
																		}
																	}}
																	onWheel={(e: any) => {
																		if (type == 'number') {
																			e.target.blur();
																		}
																	}}
																	disabled={
																		value
																			? true
																			: formCPAddressFields?.is_current_permanent_address &&
																			  getValues(formCPAddressFields?.name)
																			? formCPAddressFields?.permanent_address_fields?.includes(
																					name
																			  )
																			: false
																	}
																/>
															)}
															name={name}
														/>
													</div>
												);
											}
										}
								)}
							</form>
							{onlineFormFields.length > 0 && (
								<div className={`d-none d-md-block`}>
									<CustomButton
										type='submit'
										className='w-100 fw-bold dashboard-add-lead-button'
										expand='block'
										title={t('Previous')}
										disabled={page === 1 && true}
										// onClick={handleSubmit(onSubmit)}
										onClick={() => {
											page > 1 && setPage((prev) => prev - 1);
										}}
									/>
									<CustomButton
										type='submit'
										className='w-100 fw-bold dashboard-add-lead-button'
										expand='block'
										title={t('Save and Continue')}
										// disabled={!isValid}
										// disabled={
										// 	ceil(onlineFormFields.length / 10) === page && true
										// }
										// onClick={handleSubmit(onSubmit)}
										onClick={() => {
											// onSubmit(watch());
											page < ceil(onlineFormFields.length / 10) &&
												setPage((prev) => prev + 1);
										}}
									/>
									<CustomButton
										type='submit'
										className='w-100 fw-bold dashboard-add-lead-button'
										expand='block'
										title={t('Submit')}
										// disabled={!isValid}
										onClick={handleSubmit(onSubmit)}
										// onClick={() => {
										// 	errors &&
										// 		ceil(errorIndex / 10) != 0 &&
										// 		setPage(ceil(errorIndex / 10));

										// 	handleSubmit(onSubmit);
										// }}
									/>
								</div>
							)}
						</div>
					</div>
				) : (
					<div className='bg-web pt-md-4 e-franking-initiate-height d-md-flex align-items-md-center justify-content-md-center'>
						<div
							className='border-md-radius container-width-md p-md-3 green-top-linear-gradient d-md-flex align-items-md-center justify-content-md-center e-franking-send-container-height h-100'
							style={{ flex: 'auto' }}
						>
							<div className='text-center flex-column d-flex align-items-center justify-content-center h-100'>
								{showCheckIcon ? (
									<Player
										autoplay={true}
										loop={false}
										controls={false}
										keepLastFrame={true}
										src='/images/icons/json/SendforRework.json'
										style={{ height: '102px', width: '102px' }}
									/>
								) : (
									''
								)}
								<h3 className='fs-18 fw-600 darkest-grey'>
									{showOnlineFormMessage}
								</h3>
								<CustomButton
									className='fw-bold dashboard-add-lead-button mt-3'
									expand='block'
									title={t('Done')}
									onClick={() => {
										leadInfo?.isBackLenderOnlineForm
											? history.goBack()
											: history.replace('/lender-specific-info');
									}}
								/>
							</div>
						</div>
					</div>
				)}
			</IonContent>
			<IonFooter>
				{!showOnlineFormSuccess && onlineFormFields.length > 0 && (
					<div
						className={`ion-padding d-block d-md-none`}
						style={{
							backgroundColor: '#fff',
							zIndex: 2,
						}}
					>
						<div className='proceed-btn'>
							<CustomButton
								type='submit'
								className='w-100 fw-bold dashboard-add-lead-button'
								expand='block'
								title={t('Proceed')}
								disabled={!isValid}
								onClick={handleSubmit(onSubmit)}
							/>
						</div>
					</div>
				)}
			</IonFooter>
			{/* Upload Photo Modal */}
			<CustomModal
				id='sharemodal'
				isOpen={openUploadModal}
				initialBreakpoint={isWeb ? 1 : 0.3}
				handleClose={() => setOpenUploadModal(false)}
				breakpoints={[0, 0.3, 0.5, 0.75]}
				modalContent={
					<BankUploadDocuments
						setDocTypeErrorMessage={setDocTypeErrorMessage}
						docTypeErrorMessage={docTypeErrorMessage}
						setOpenUploadModal={setOpenUploadModal}
						getValues={getValues}
						dispatch={dispatch}
						unwrapResult={unwrapResult}
						setValue={setValue}
						trigger={trigger}
						setImagePreviewUpload={setImagePreviewUpload}
						imagePreviewUpload={imagePreviewUpload}
						urltoFile={urltoFile}
						isBrowser={isBrowser}
						clickImageFieldName={clickImageFieldName}
						t={t}
					/>
				}
				needCross={false}
				needArrow={false}
				className={`side-modal ${isWeb ? '' : 'modal-border-radius'}`}
			/>
		</IonPage>
	);
};
