/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	IonButtons,
	IonContent,
	IonFooter,
	IonImg,
	IonItem,
	// IonItem,
	IonPage,
	IonSegment,
	IonSegmentButton,
	IonSpinner,
	isPlatform,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Header } from '../../components/layout/header/Header';
import './BureauReport.scss';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
import { BureauData } from './BureauData';
import { useHistory, useLocation } from 'react-router';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import {
	AllocatePopUp,
	DownloadFileBlob,
	DownloadFileInApp,
	PageExitPopUp,
	eventAttributes,
	notifyHandler,
	showToastAlert,
} from '../../helpers/functions';
import { useTranslation } from 'react-i18next';
import {
	BUREAU_SCORE_TABS,
	LEAD_SCORE_TYPE,
	LeadStatus,
	USER_ROLES,
} from '../../helpers/constant';
import { useDispatch, useSelector } from 'react-redux';
import {
	downloadEquifaxBureauReport,
	fetchCibilBureauReport,
	fetchEquifaxBureauReport,
	getLeadManagers,
	leadClose,
	leadFlow,
	processingBegin,
} from '../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import CustomAllocateManagers from '../../components/common/CustomAllocateManagers';
import { webView } from '../../store/reducers/app/appSlice';
import { LocalNotifications } from '@capacitor/local-notifications';
import { FileOpener } from '@capacitor-community/file-opener';
import { PushNotifications } from '@capacitor/push-notifications';
import {
	AndroidSettings,
	IOSSettings,
	NativeSettings,
} from 'capacitor-native-settings';
import { NotifyVisitors } from 'capacitor-plugin-notifyvisitors';

export const BureauReport: React.FC = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const leadSelector = useSelector((state: any) => state?.lead?.leadFlowData);
	const [selectedTab, setSelectedTab] = useState<any>(
		leadSelector?.scoreType?.toLocaleLowerCase() ===
			LEAD_SCORE_TYPE.EQUIFAX.toLocaleLowerCase()
			? BUREAU_SCORE_TABS.EQUIFAX
			: leadSelector?.scoreType?.toLocaleLowerCase() ===
			  LEAD_SCORE_TYPE.CBIL.toLocaleLowerCase()
			? BUREAU_SCORE_TABS.CIBIL
			: leadSelector?.scoreType?.toLocaleLowerCase() ===
					LEAD_SCORE_TYPE.BOTH.toLocaleLowerCase() && BUREAU_SCORE_TABS.EQUIFAX
	);
	const [initiateLoanApplication, setInitiateLoanApplication] = useState(false);
	const [allocateAndroManagerModal, setAllocateAndroManagerModal] =
		useState(false);
	const [modalPageClose, setModalPageClose] = useState(false);
	const [equifaxData, setEquifaxData] = useState(null);
	const [bureauFetchError, setBureauFetchError] = useState('');
	const [bureauRetry, setBureauRetry] = useState(false);
	const dmaSelector = useSelector((state: any) => state?.users);
	const leadInfo = useSelector((state: any) => state.lead.leadFlowData);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [cbilData, setCbilData] = useState();
	const [equifaxDataLoading, setEquifaxDataLoading] = useState(false);
	const [cbilDataLoading, setCbilDataLoading] = useState(false);
	const role = useSelector((state: any) => state?.users?.user_details);
	const fetchBureauCustomerReport = useSelector(
		(state: any) => state?.lead?.fetchBureauCustomerReportData
	);
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	const dispatch = useDispatch();
	const [showAllocateManagerModal, setShowAllocateManagerModal] =
		useState<boolean>(false);
	const [showNotificationModel, setShowNitificationModel] = useState(false);

	const registerNotifications = async () => {
		const permStatus = await PushNotifications.checkPermissions();

		if (permStatus.receive == 'denied') {
			setShowNitificationModel(true);
			setSpinner(false);
		} else if (permStatus.receive == 'prompt-with-rationale') {
			setShowNitificationModel(true);
			setSpinner(false);
		} else {
			setShowNitificationModel(false);
		}
	};
	// Notify Visitors
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const userName = useSelector(
		(state: any) => state?.users?.homepage?.profile?.name
	);
	const userMobile = useSelector(
		(state: any) => state?.users?.user_details?.mobile
	);
	const entityType = useSelector(
		(state: any) => state?.users?.user_details?.entityType
	);

	const userLang = useSelector(
		(state: any) => state?.oneTimeScreen?.language?.name
	);

	const downloadBureauReport = async () => {
		await dispatch(
			downloadEquifaxBureauReport({
				param: `/${fetchBureauCustomerReport?.lead_id}${
					leadInfo?.from === 'customer' ? '&lead_type=customer' : ''
				}`,
			})
		)
			.then(unwrapResult)
			.then(async (response: any) => {
				if (response.status) {
					showToastAlert({ type: 'success', message: 'Download Initiated' });
					if (isPlatform('mobileweb')) {
						DownloadFileBlob(
							response.base64String,
							selectedTab + '_report',
							'pdf'
						);
					} else if (
						isPlatform('android') ||
						isPlatform('ios') ||
						isPlatform('ipad') ||
						isPlatform('iphone')
					) {
						setSpinner(true);
						registerNotifications();

						DownloadFileInApp(
							response.base64String,
							selectedTab + '_report',
							'pdf'
						);
					} else {
						DownloadFileBlob(
							response.base64String,
							selectedTab + '_report',
							'pdf'
						);
					}
				}
			});
	};
	const [spinner, setSpinner] = useState(false);

	useEffect(() => {
		LocalNotifications.addListener('localNotificationReceived', (data: any) => {
			if (data) {
				setSpinner(false);
			}
		});
		LocalNotifications.addListener(
			'localNotificationActionPerformed',
			(payload: any) => {
				const route = payload.notification.extra.route;
				FileOpener.open({
					filePath: route,
				});
			}
		);
		return () => {
			LocalNotifications.removeAllListeners();
		};
	}, []);

	// Notify Visitors
	const userAttributes = {
		userId: userId,
		attributes: {
			name: userName,
			id: userId,
			mobile: userMobile,
			user_language: userLang,
			entityType:
				entityType === 'Individual'
					? 'DMA'
					: entityType === 'BTM'
					? 'employee'
					: entityType,
		},
	};
	useEffect(() => {
		if (isPlatform('android') || isPlatform('ios')) {
			notifyHandler('event', eventAttributes('Equifax_page'));
			notifyHandler('user', userAttributes.attributes);
			const inAppData = {
				tokens: { name: userAttributes },
				customRules: { banerrType: 'equifax page' },
			};
			NotifyVisitors.show(inAppData);
		} else {
			(window as any).nv('event', 'Equifax_page', 10, 1);
			(window as any).nv('user', userId, userAttributes.attributes);
		}
	}, []);

	useEffect(() => {
		if (location.pathname == '/bureau-report') {
			if (window.innerWidth > 768) {
				dispatch(webView(true));
			} else {
				dispatch(webView(false));
			}
			getBureauData();
		}
	}, [selectedTab, location.pathname]);

	const getBureauData = async () => {
		if (
			selectedTab === BUREAU_SCORE_TABS.EQUIFAX &&
			(leadSelector?.scoreType?.toLocaleLowerCase() ===
				LEAD_SCORE_TYPE.EQUIFAX.toLocaleLowerCase() ||
				leadSelector?.scoreType?.toLocaleLowerCase() ===
					LEAD_SCORE_TYPE.BOTH.toLocaleLowerCase())
		) {
			setEquifaxDataLoading(true);
			setBureauRetry(false);
			await dispatch(
				fetchEquifaxBureauReport({
					param: fetchBureauCustomerReport?.lead_id,
					data: {
						...fetchBureauCustomerReport,
					},
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (
						leadInfo?.selectedProductData?.product_category_id == 7 &&
						response?.code == 'FAILED'
					) {
						setIsModalOpen(true);
					}
					setEquifaxDataLoading(false);
					if (response?.status) {
						setEquifaxData(response?.data);
						dispatch(
							leadFlow({
								...leadSelector,
								leadStatus: LeadStatus.PROCESSING,
							})
						);
					} else {
						dispatch(
							leadFlow({
								...leadSelector,
								leadStatus: LeadStatus.BUREAU,
							})
						);
						setBureauFetchError(
							response?.text ? response?.text : response?.message
						);
					}
					setBureauRetry(response?.retry);
				});
		} else if (
			selectedTab === BUREAU_SCORE_TABS.CIBIL &&
			(leadSelector?.scoreType?.toLocaleLowerCase() ===
				LEAD_SCORE_TYPE.CBIL.toLocaleLowerCase() ||
				leadSelector?.scoreType?.toLocaleLowerCase() ===
					LEAD_SCORE_TYPE.BOTH.toLocaleLowerCase())
		) {
			setBureauRetry(false);
			setCbilDataLoading(true);
			await dispatch(
				fetchCibilBureauReport({
					param: `/${fetchBureauCustomerReport?.lead_id}`,
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					setCbilDataLoading(false);
					if (response?.status) {
						setCbilData(response?.cibil_response);
					} else {
						dispatch(
							leadFlow({
								...leadSelector,
								leadStatus: LeadStatus.BUREAU,
							})
						);
						setBureauFetchError(
							response?.text ? response?.text : response?.message
						);
					}
					setBureauRetry(response?.retry);
				});
		}
	};
	const bureauTabs =
		leadSelector?.scoreType?.toLocaleLowerCase() ===
		LEAD_SCORE_TYPE.CBIL.toLocaleLowerCase()
			? [
					{
						name: BUREAU_SCORE_TABS.CIBIL,
					},
			  ]
			: leadSelector?.scoreType?.toLocaleLowerCase() ===
			  LEAD_SCORE_TYPE.EQUIFAX.toLocaleLowerCase()
			? [
					{
						name: BUREAU_SCORE_TABS.EQUIFAX,
					},
			  ]
			: [
					{
						name: BUREAU_SCORE_TABS.EQUIFAX,
					},
					{
						name: BUREAU_SCORE_TABS.CIBIL,
					},
			  ];
	const getLeadManager = async () => {
		return await dispatch(
			getLeadManagers({
				param: `?subproductid=${leadSelector?.selectedProductData?.product_category_id}`,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					if (response?.data?.length > 0) {
						return true;
					} else {
						return false;
					}
				} else {
					return false;
				}
			});
	};
	const updateLeadManager = async (assingToSelf = true) => {
		setInitiateLoanApplication(false);
		dispatch(
			leadFlow({
				...leadSelector,
				leadStatus: LeadStatus.PROCESSING,
			})
		);
		await dispatch(
			processingBegin({
				param: '/' + fetchBureauCustomerReport?.lead_id,
				data: { isSelf: assingToSelf, user_id: role?.lead_default_manager_id },
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (assingToSelf) {
					if (response?.status) {
						setEquifaxData(null);
						history.replace('/professional-info');
					}
				} else {
					if (response?.status) {
						setEquifaxData(null);
						setAllocateAndroManagerModal(false);
						setTimeout(() => {
							history.push('/dashboard/lead');
						}, 500);
					}
				}
			});
	};

	const initiateLoanApp = async () => {
		let checkManagerExists: any;
		if (leadInfo?.from !== 'customer') {
			checkManagerExists = await getLeadManager();
		}
		if (![USER_ROLES.DMA, USER_ROLES.DMAUL].includes(role?.roleType?.[0])) {
			if (checkManagerExists) {
				updateLeadManager();
			} else {
				setInitiateLoanApplication(true);
			}
		} else {
			if (checkManagerExists) {
				setInitiateLoanApplication(true);
			} else {
				updateLeadManager();
			}
		}
	};

	return (
		<>
			<IonPage className='bg-web'>
				<Header
					name={t('Credit Score')}
					backButton={leadInfo?.from !== 'customer' ? true : false}
					needCross={true}
					needCrossOnClick={() =>
						leadInfo?.from !== 'customer' && setModalPageClose(true)
					}
					endItem={
						fetchBureauCustomerReport?.lead_type === 'Lead' &&
						leadInfo?.from !== 'customer' ? (
							<IonButtons
								slot='end'
								className=''
							>
								<IonImg
									className='cursor-pointer'
									src='/images/icons/assign-icon.svg'
									alt='assign'
									onClick={() => {
										setShowAllocateManagerModal(true);
									}}
								/>
							</IonButtons>
						) : (
							''
						)
					}
				/>
				<IonContent
					className='bureau-report'
					scrollY={!isWeb}
				>
					<div className='small-container bg-web h-100'>
						<div className='px-md-4 bg-web-white container-width-md border-radius-10 py-md-3 mb-2 h-100'>
							<div>
								<IonSegment
									className='tab'
									color='dark'
									value={selectedTab}
									onIonChange={(e) => setSelectedTab(e.detail.value as any)}
									mode='md'
								>
									{bureauTabs.map((item, index) => {
										return (
											<IonSegmentButton
												key={`bureau-report-tabs-${index}`}
												className='fs-14 fw-600'
												value={item.name}
												mode='md'
											>
												{t(item.name)}
											</IonSegmentButton>
										);
									})}
								</IonSegment>

								<div>
									{selectedTab === 'Equifax' ? (
										<>
											<BureauData
												bureauRetry={bureauRetry}
												bureauFetchError={bureauFetchError}
												data={equifaxData}
												selectedTab={selectedTab}
												lead_id={fetchBureauCustomerReport?.lead_id}
												getBureauData={getBureauData}
												dataLoading={equifaxDataLoading}
											/>
										</>
									) : (
										<>
											<BureauData
												bureauRetry={bureauRetry}
												bureauFetchError={bureauFetchError}
												data={cbilData}
												selectedTab={selectedTab}
												lead_id={fetchBureauCustomerReport?.lead_id}
												getBureauData={getBureauData}
												dataLoading={cbilDataLoading}
											/>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
					<CustomModal
						// id='customModalRework'
						isOpen={showAllocateManagerModal}
						modalTitle={t('Select Assignee')}
						initialBreakpoint={1}
						handleClose={() => setShowAllocateManagerModal(false)}
						breakpoints={[0, 1]}
						className='assignModal side-modal'
						modalContent={
							<CustomAllocateManagers
								eventType='Lead'
								id={leadInfo?.leadId}
								setShowAssignModal={setShowAllocateManagerModal}
							/>
						}
						scrollY={true}
						needCross={true}
						needArrow={false}
					></CustomModal>
					{isModalOpen && (
						<CustomModal
							isOpen={isModalOpen}
							initialBreakpoint={undefined}
							modalContent={
								<>
									<div className=' pt-md-4 e-franking-initiate-height d-md-flex align-items-md-center justify-content-md-center'>
										<div
											className='border-md-radius container-width-md p-md-3 green-top-linear-gradient d-md-flex align-items-md-center justify-content-md-center e-franking-send-container-height h-100'
											style={{ flex: 'auto' }}
										>
											<div className='text-center flex-column d-flex align-items-center justify-content-center h-100'>
												<h3 className='fs-18 fw-600 darkest-grey'>
													{
														'Invalid PAN Number, please provide correct information and reapply.'
													}
												</h3>
												<CustomButton
													className='fw-bold dashboard-add-lead-button mt-3'
													expand='block'
													title={t('Done')}
													onClick={() => {
														new Promise(function (myResolve) {
															dispatch(
																leadClose({
																	data: {
																		id: fetchBureauCustomerReport?.lead_id,
																	},
																})
															).then(() => {
																showToastAlert({
																	type: 'error',
																	message: 'Lead is Closed',
																});
															});
															setIsModalOpen(false);
															myResolve(history.push('/dashboard/lead'));
														});
													}}
												/>
											</div>
										</div>
									</div>
								</>
							}
						/>
					)}
				</IonContent>
				<IonFooter className='px-3 py-3 bg-web-white'>
					<div className='d-block d-md-none'>
						{selectedTab === 'Equifax' ? (
							<>
								{!equifaxDataLoading ? (
									equifaxData ? (
										<>
											<CustomButton
												expand='block'
												className='btn-blue mb-3'
												fill='outline'
												onClick={downloadBureauReport}
												title={t('Download Report')}
											/>
											<CustomButton
												expand='block'
												className='btn-blue'
												onClick={initiateLoanApp}
												title={t('Initiate Loan Application')}
											/>
										</>
									) : (
										<div className=''>
											{fetchBureauCustomerReport?.lead_type === 'Lead' ? (
												<CustomButton
													expand='block'
													className='btn-blue'
													onClick={() => {
														initiateLoanApp();
														if (isPlatform('android') || isPlatform('ios')) {
															notifyHandler(
																'event',
																eventAttributes('proceed_without_bureau')
															);
															notifyHandler('user', userAttributes.attributes);
															const inAppData = {
																tokens: { name: userAttributes },
																customRules: {
																	banerrType: 'proceed without bureau',
																},
															};
															NotifyVisitors.show(inAppData);
														} else {
															(window as any).nv(
																'event',
																'proceed_without_bureau',
																10,
																1
															);
															(window as any).nv(
																'user',
																userId,
																userAttributes.attributes
															);
														}
													}}
													title={t('Proceed Without Bureau')}
												/>
											) : (
												<CustomButton
													expand='block'
													className='btn-blue cibil-button'
													onClick={() => {
														history.replace('/dashboard/lead');
													}}
													title='Okay'
												/>
											)}
										</div>
									)
								) : (
									''
								)}
							</>
						) : cbilData ? (
							<>
								<CustomButton
									expand='block'
									className='btn-blue mb-3'
									fill='outline'
									onClick={downloadBureauReport}
									title={t('Download Report')}
									disabled={true}
								/>
								<CustomButton
									expand='block'
									className='btn-blue'
									onClick={initiateLoanApp}
									title={t('Initiate Loan Application')}
								/>
							</>
						) : (
							<>
								{fetchBureauCustomerReport.lead_type === 'Lead' ? (
									<CustomButton
										expand='block'
										className='btn-blue'
										onClick={initiateLoanApp}
										title={t('Proceed Without Bureau')}
									/>
								) : (
									<CustomButton
										expand='block'
										className='btn-blue cibil-button'
										onClick={() => {
											history.replace('/dashboard/lead');
										}}
										title='Okay'
									/>
								)}
							</>
						)}
					</div>
					<div className='d-none d-md-block '>
						{selectedTab === 'Equifax' ? (
							<>
								{!equifaxDataLoading ? (
									equifaxData ? (
										<div className='d-flex flex-row align-items-center justify-content-center container-width-md'>
											<CustomButton
												expand='block'
												className='btn-blue cibil-button'
												fill='outline'
												onClick={downloadBureauReport}
												title={t('DownloadReport')}
											/>
											<CustomButton
												expand='block'
												className='btn-blue cibil-button'
												onClick={initiateLoanApp}
												title={t('Initiate Loan Application')}
											/>
										</div>
									) : (
										<div className='d-flex flex-row align-items-center justify-content-center container-width-md'>
											{fetchBureauCustomerReport?.lead_type === 'Lead' ? (
												<CustomButton
													expand='block'
													className='btn-blue'
													onClick={initiateLoanApp}
													title={t('Proceed Without Bureau')}
												/>
											) : (
												<CustomButton
													expand='block'
													className='btn-blue cibil-button'
													onClick={() => {
														history.replace('/dashboard/lead');
													}}
													title='Okay'
												/>
											)}
										</div>
									)
								) : (
									''
								)}
							</>
						) : cbilData ? (
							<div className='d-flex flex-row align-items-center justify-content-center container-width-md'>
								<CustomButton
									expand='block'
									className='btn-blue cibil-button'
									fill='outline'
									onClick={downloadBureauReport}
									title={t('Download Report')}
									disabled={true}
								/>
								<CustomButton
									expand='block'
									className='btn-blue cibil-button'
									onClick={initiateLoanApp}
									title={t('Initiate Loan Application')}
								/>
							</div>
						) : (
							<div className='d-flex flex-row align-items-center justify-content-center container-width-md'>
								{fetchBureauCustomerReport.lead_type === 'Lead' ? (
									<CustomButton
										expand='block'
										className='btn-blue'
										onClick={initiateLoanApp}
										title={t('Proceed Without Bureau')}
									/>
								) : (
									<CustomButton
										expand='block'
										className='btn-blue cibil-button'
										onClick={() => {
											history.replace('/dashboard/lead');
										}}
										title='Okay'
									/>
								)}
							</div>
						)}
					</div>
				</IonFooter>
			</IonPage>

			{/* Initiate Loan Application / Do you want to allocate this lead .... bottom modal */}
			<CustomModal
				id=''
				isOpen={initiateLoanApplication}
				handleClose={() => setInitiateLoanApplication(false)}
				initialBreakpoint={leadInfo?.from !== 'customer' ? 0.24 : 0.15}
				breakpoints={[0, 0.3, 0.4, 0.75]}
				style={{ '--border-radius': '20px' }}
				modalContent={AllocatePopUp(
					() => setAllocateAndroManagerModal(true),
					() => {
						updateLeadManager();
					},
					leadInfo?.from,
					false
				)}
				needIonContentWraper={false}
				needCross={false}
				needArrow={false}
			/>

			{/* Are you sure you want to allocate this lead to Andro Manager....... middle popup */}
			<CustomModal
				id=''
				isOpen={allocateAndroManagerModal}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setAllocateAndroManagerModal(false)}
				breakpoints={[]}
				className='height-auto confirm-popup-middle'
				needIonContentWraper={false}
				modalContent={PageExitPopUp(
					() => {
						setAllocateAndroManagerModal(false);
					},
					() => {
						updateLeadManager(false);
					},
					`${t('Cancel')}`,
					`${t('Confirm')}`,
					'className',
					`${t('Are You Sure You Want to Allocate Lead to AndroManager?')}`,
					`${t(
						'By doing this you will not be able to take any action on this lead anymore'
					)}`
				)}
				needCross={false}
				needArrow={false}
			/>
			{/* on page close popup modal */}
			<CustomModal
				id=''
				isOpen={modalPageClose}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setModalPageClose(false)}
				breakpoints={[]}
				className='confirm-popup-middle height-auto'
				modalContent={PageExitPopUp(
					() => {
						setModalPageClose(false);
					},
					() => {
						setEquifaxData(null);
						setModalPageClose(false);
						leadInfo?.from === 'nimbuss'
							? history.push(
									'/lead-details/' +
										leadInfo.leadId +
										'/' +
										dmaSelector?.user_details?.access_token
							  )
							: history.push('/dashboard/lead');
					},
					t('Cancel'),
					t('Continue Later'),
					t('classname'),
					t('Are you sure you want to exit ?'),
					t('You can continue later from where you left off.')
				)}
				needIonContentWraper={false}
				needCross={false}
				needArrow={false}
			/>
			{spinner && (
				<div
					className='position-fixed  w-100  d-flex justify-content-center align-items-center h-100'
					style={{
						top: '0px',
						left: '50%',
						transform: 'translate(-50%,0%)',
						backgroundColor: 'rgba(0,0,0,0.2)',
					}}
				>
					<IonSpinner name='lines-sharp' />
				</div>
			)}
			<CustomModal
				id=''
				isOpen={showNotificationModel}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setShowNitificationModel(false)}
				breakpoints={[]}
				className='confirm-popup-middle height-auto'
				modalContent={
					<div className={`wrapper py-4 confirm-popup-middle height-auto`}>
						<IonItem lines='none'>
							<div className='d-flex flex-column'>
								<p
									className='fs-14 mb-2'
									style={{ color: 'var(--text-dark-grey)' }}
								>
									Please enable notification permission from settings.
								</p>
							</div>
						</IonItem>
						<div className='d-flex justify-content-center gap-3 px-3'>
							<CustomButton
								className='w-50'
								fill='solid'
								onClick={() => {
									NativeSettings.open({
										optionAndroid: AndroidSettings.AppNotification,
										optionIOS: IOSSettings.Notifications,
									});
									setShowNitificationModel(false);
								}}
								title={t('Ok')}
							/>
						</div>
					</div>
				}
				needIonContentWraper={false}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
