import React from 'react';

interface ThemeProps {
  color: string;
  onClick: () => void;
  selected: boolean;
}

const Theme: React.FC<ThemeProps> = ({ color, onClick, selected }) => {  
  const themeStyle: React.CSSProperties = {
    backgroundColor: color,
    border: selected ? '2px solid #4CAF50' : '1px solid #000000',
    width: '26px',
    height: '26px',
    margin: '5px',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
  };

  const lineStyle: React.CSSProperties = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
  };

  return (
    <div className={`theme ${selected ? 'selected' : ''}`} style={themeStyle} onClick={onClick}>
      {color === '#FFFFFF' && (
        <svg style={lineStyle} width="100%" height="100%" viewBox="0 0 26 26">
          <line x1="0" y1="26" x2="26" y2="0" style={{ stroke: '#000000', strokeWidth: 2 }} />
        </svg>
      )}
    </div>
  );
};

export default Theme;
