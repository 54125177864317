import * as yup from 'yup';
import { AppStatusProps } from './app_status_update';





export const app_status_schema: any = (props: AppStatusProps) => {

  return yup.object().shape({
    closeReason: props.hideCloseReason
    ? yup.string().notRequired()
    : yup.string().required('Reason required'),
  remarks: yup.string().notRequired(),
  email: props.hideEmail
    ? yup.string().notRequired()
    : yup.string().required('Emails are required'),
  });
};


