//// @ts-nocheck



import React, {useState } from 'react';
import { IonItem, IonLabel } from '@ionic/react';
import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { CustomChip, CustomChipLabel, ErrorMessage } from './choice_chip.style'
import { useTranslation } from 'react-i18next';

interface Options {
    value: string;
    description: string;
}

interface Values {
    title: string;
    // id: string;
    name: string;
    mandatory?: boolean;
    options: Options[];
    values?: string;
    error?: FieldErrors,
    setValueFunc: UseFormSetValue<any>;
    onChange?: (val?:any) => void;
    // isValid?: boolean;
}

// const ChoiceChip: React.FC<Values> = ({ title, value, options }) => {
//     const [selectedValue, setSelectedValue] = useState<string>('');
//     // setSelectedValue(value);
export const ChoiceChip: React.FC<Values> = ({ title, values, name, onChange, error, options, mandatory = false, setValueFunc }) => {
    // const [selectedValue, setSelectedValue] = useState<string>('');
    const [selectedValue, setSelectedValue] = useState(values);
    const { t } = useTranslation();



    // useEffect(()=>{
    //     console.log('x');

    // },[error]);


    const handleValueChange = (value: string) => {
        if (selectedValue === value) {
            setSelectedValue('');
            // values = '';
            setValueFunc(name, '');
        }
        else {
            setSelectedValue(value);
            // values = value;
            setValueFunc(name, value);
        }

        { onChange && onChange(value); }


        // console.log(`hbs selected ${value} ${selectedValue}`);
    };


    const checkError = (): boolean => {
        // console.log(error,mandatory,selectedValue);
        if(error &&  mandatory) {
            if(selectedValue === undefined || selectedValue === null || selectedValue === '' ) {
                return true;
            }
        }
        return false;

    }

    // console.log(`hbs init ${selectedValue} ${isValid} ${mandatory || !isValid || !!selectedValue} ${!selectedValue}`);
    // console.log(`hbs init ${isValid}`);

    return (
        <div style={{ paddingBottom: '8px', paddingTop: '8px' }}>
            <IonItem>
                {/* { {(mandatory && selectedValue === '') &&  */}
                {/* {((mandatory && selectedValue === '') || (error  && selectedValue === undefined && mandatory)) &&
                    <IonLabel color={'danger'}>{title}</IonLabel>}
                {!((mandatory && selectedValue === '') || (error && selectedValue === undefined && mandatory)) &&
                    <IonLabel>{title}</IonLabel>}
                     */}

                <IonLabel color={checkError()? 'danger':''}>{title}</IonLabel>


                {/* <IonLabel>{title}</IonLabel> */}
            </IonItem>
            {options.map(({ value, description }) => (
                <CustomChip
                    id={name}
                    key={`${name}-${value}`}
                    selected={selectedValue === value}
                    onClick={() => handleValueChange(value)}
                >
                    <CustomChipLabel selected={selectedValue === value}>{t(description)}</CustomChipLabel>
                </CustomChip>
            ))
            }

            {
                error && error.message && checkError() && (
                    <div>
                        <ErrorMessage>{t(error.message as unknown as string)}</ErrorMessage>
                    </div>
                )
            }

        </div >

    );
};

// export default ChoiceChip;