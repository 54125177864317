import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { CustomInput } from '../../../../../components/common/custom-input/CustomInput';
import { CustomButton } from '../../../../../components/common/custom-button/CustomButton';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropDownModal from '../../../../../components/common/custom-dropdown/DropDownModal';
import { IonItem, IonLabel, IonList, isPlatform } from '@ionic/react';
import { UploadImageCard } from '../../../../dashboard/kyc/UploadImageCard';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import {
	dataURLtoFile,
	disableNumberArrows,
	fetchStateCityByPincode,
} from '../../../../../helpers/functions';
import { CustomModal } from '../../../../../components/common/custom-modal/CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import {
	apiKycDocumentUpload,
	kycAddressProof,
	kycUserUploadImage,
} from '../../../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { CustomCheckbox } from '../../../../../components/common/custom-checkbox/CustomCheckbox';
import StateCity from '../../../../../components/common/StateCity';

interface Props {
	userId: number;
	data: any;
	setUserData: any;
	setShowAddressProofInfoForm: any;
}

const addressproofEditInfoSchema = yup.object().shape({
	AddressProof: yup.string().required('Address Proof is required'),
	PermanentAddress: yup
		.string()
		.required('Permanent Address is required')
		.trim()
		.max(200, 'Permanent Residential Address Should not more than 200')
		.matches(
			/^[a-zA-Z0-9 _",():#-]*$/,
			'Please Enter Valid Format. Only allows (_",():#-)'
		),
	CurrentAddress: yup
		.string()
		.required('Current Residential Address is required')
		.trim()
		.max(200, 'Current Residential Address Should not more than 200')
		.matches(
			/^[a-zA-Z0-9 _",():#-]*$/,
			'Please Enter Valid Format. Only allows (_",():#-)'
		),
	OfficeAddress: yup
		.string()
		.trim()
		.required('Office Address is Required.')
		.max(80, 'Office Address Should not more than 80')
		.matches(
			/^[a-zA-Z0-9 _",():#-]*$/,
			'Please Enter Valid Format. Only allows (_",():#-)'
		),
	PermanentPinCode: yup
		.string()
		.trim()
		.required('Pin Code Number is Required')
		.length(6, 'Pin Code Should Be 6 Digits'),
	PermanentState: yup.string().required(),
	PermanentCity: yup.string().required(),
	CurrentPinCode: yup
		.string()
		.trim()
		.required('Pin Code Number is Required')
		.length(6, 'Pin Code Should Be 6 Digits'),
	CurrentState: yup.string().required(),
	CurrentCity: yup.string().required(),
	OfficePinCode: yup
		.string()
		.trim()
		.required('Pin Code Number is Required')
		.length(6, 'Pin Code Should Be 6 Digits'),
	OfficeState: yup.string().required(),
	OfficeCity: yup.string().required(),
});

export const AddressProofEdit: React.FC<Props> = ({
	userId,
	data,
	setUserData,
	setShowAddressProofInfoForm,
}) => {
	const dispatch: any = useDispatch();
	const { t } = useTranslation();
	const isBrowser = !isPlatform('capacitor');
	const [addressProof, setAddressProof] = useState([]);
	const [imagePreviewUpload, setImagePreviewUpload] = useState({
		addressProofImage: false,
	});
	const [openUploadModal, setOpenUploadModal] = useState(false);
	const [clickImageFieldName, setClickImageFieldName] = useState<any>(null);
	const [addressProofImage, setAddressProofImage] = useState<any>('');
	const [disablePermanentAddress, setDisablePermanentAddress] =
		useState<boolean>(false);
	const [disablePermanentPincode, setDisablePermanentPincode] =
		useState<boolean>(false);
	const [pinCodeData, setPinCodeData] = useState<any>([]);
	const isWeb = useSelector((state: any) => state?.app?.isWeb);

	const {
		handleSubmit,
		control,
		reset,
		trigger,
		setValue,
		getValues,
		formState: { errors, isValid },
	}: any = useForm<any>({
		mode: 'all',
		reValidateMode: 'onChange',
		resolver: yupResolver(addressproofEditInfoSchema),
		defaultValues: {
			AddressProof: '',
			PermanentAddress: '',
			OfficeAddress: '',
			CurrentAddress: '',
			PinCode: '',
			State: '',
			City: '',
		},
	});

	useEffect(() => {
		reset(data);
		fetchUserAddrssProof();

		if (data?.CurrentPinCode?.length === 6) {
			fetchStateCityByPincode(
				setValue,
				'CurrentCity',
				'CurrentState',
				setPinCodeData,
				dispatch,
				data?.CurrentPinCode,
				trigger,
				true,
				'CurrentPinCode'
			);
		}
		if (data?.PermanentPinCode?.length === 6) {
			fetchStateCityByPincode(
				setValue,
				'PermanentCity',
				'PermanentState',
				setPinCodeData,
				dispatch,
				data?.PermanentPinCode,
				trigger,
				true,
				'PermanentPinCode'
			);
		}
		if (data?.OfficePinCode?.toString().length === 6) {
			fetchStateCityByPincode(
				setValue,
				'OfficeCity',
				'OfficeState',
				setPinCodeData,
				dispatch,
				data?.OfficePinCode?.toString(),
				trigger,
				true,
				'OfficePinCode'
			);
		}
	}, []);

	const fetchUserAddrssProof = async () => {
		await dispatch(kycAddressProof())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setAddressProof(result?.data);
					setAddressProofImage(data.Url);

					result?.data?.map((item: any) => {
						if (data.AddressProof === item?.value) {
							setValue('AddressProof', item.id);
						}
						if (data.CurrentPermanentAddressSame) {
							setDisablePermanentAddress(true);
							setDisablePermanentPincode(true);
						}
					});

					setImagePreviewUpload({
						...imagePreviewUpload,
						addressProofImage: true,
					});
				}
			});
	};

	const ShareModal = () => {
		const handleUploadChange = async (
			type: string,
			fieldName: string,
			e: any
		) => {
			setOpenUploadModal(false);
			// case1
			const formData = new FormData();

			// case 2
			let image: any;
			switch (type) {
				case 'file':
					formData.append('profile', e.target.files[0]);
					await dispatch(kycUserUploadImage({ data: formData }))
						.then(unwrapResult)
						.then((result: any) => {
							if (result?.status) {
								setValue(fieldName, result?.url);
								switch (fieldName) {
									case 'Url':
										setImagePreviewUpload({
											...imagePreviewUpload,
											addressProofImage: true,
										});
										setAddressProofImage(result?.url);
										break;
								}
							}
						});

					break;
				case 'photo':
					image = await Camera.getPhoto({
						quality: 75,
						allowEditing: false,
						resultType: CameraResultType.DataUrl,
						source: CameraSource.Photos,
					});

					formData.append(
						'profile',
						dataURLtoFile(
							image.dataUrl,
							`${Math.random() * 100000000000000000}.${image.format}`
						)
					);
					await kycUserUploadImage({ data: formData })
						.then(unwrapResult)
						.then((result: any) => {
							if (result?.status) {
								setValue(fieldName, result?.url);
								switch (fieldName) {
									case 'Url':
										setImagePreviewUpload({
											...imagePreviewUpload,
											addressProofImage: true,
										});
										setAddressProofImage(result?.url);
										break;
								}
							}
						});

					break;
				case 'camera':
					image = await Camera.getPhoto({
						quality: 75,
						allowEditing: false,
						resultType: CameraResultType.DataUrl,
						source: CameraSource.Camera,
					});

					formData.append(
						'profile',
						dataURLtoFile(
							image.dataUrl,
							`${Math.random() * 100000000000000000}.${image.format}`
						)
					);
					await dispatch(kycUserUploadImage({ data: formData }))
						.then(unwrapResult)
						.then((result: any) => {
							if (result?.status) {
								setValue(fieldName, result?.url);
								switch (fieldName) {
									case 'Url':
										setImagePreviewUpload({
											...imagePreviewUpload,
											addressProofImage: true,
										});
										setAddressProofImage(result?.url);
										break;
								}
							}
						});

					break;
				default:
					break;
			}
		};
		return (
			<>
				<IonList>
					{!isBrowser && (
						<IonItem lines='full'>
							<img
								src='/images/icons/kyc/Image-upload.svg'
								alt='Upload Photo'
							/>
							<IonLabel>
								<h2
									className='fs-16 ps-3 text-dark'
									onClick={(e) => {
										handleUploadChange('photo', clickImageFieldName, e);
									}}
								>
									Upload Photo
								</h2>
							</IonLabel>
						</IonItem>
					)}
					<IonItem lines='full'>
						<img
							src='/images/icons/kyc/upload-image-icon.svg'
							alt='Upload Photo'
						/>
						<IonLabel>
							<label htmlFor='upload-photo'>
								<h2 className='fs-16 ps-3 text-dark'>{t('Upload Document')}</h2>
							</label>
							<input
								type='file'
								id='upload-photo'
								accept='image/png, image/jpeg ,application/pdf'
								onChange={(e) => {
									handleUploadChange('file', clickImageFieldName, e);
								}}
							/>
						</IonLabel>
					</IonItem>
					{!isBrowser && (
						<IonItem lines='full'>
							<img
								src='/images/icons/kyc/camera-icon.svg'
								alt='Upload Photo'
							/>
							<IonLabel>
								<h2
									className='fs-16 ps-3 text-dark'
									onClick={(e) => {
										handleUploadChange('camera', clickImageFieldName, e);
									}}
								>
									Take Photo
								</h2>
							</IonLabel>
						</IonItem>
					)}
				</IonList>
			</>
		);
	};

	const onSubmit = async (formData: any) => {
		await dispatch(
			apiKycDocumentUpload({
				param: '/' + userId,
				data: {
					Type: 'AddressProof2',
					RW: true,
					AddressProofName: formData.AddressProof,
					PermanentAddress: formData.PermanentAddress,
					PermanentPinCode: formData.PermanentPinCode,
					PermanentCity: formData.PermanentCity,
					PermanentState: formData.PermanentState,
					CurrentAddress: formData.CurrentAddress,
					CurrentPinCode: formData.CurrentPinCode,
					CurrentCity: formData.CurrentCity,
					CurrentState: formData.CurrentState,
					CurrentPermanentAddressSame: formData.CurrentPermanentAddressSame,
					OfficeAddress: formData.OfficeAddress,
					OfficePinCode: formData.OfficePinCode,
					OfficeCity: formData.OfficeCity,
					OfficeState: formData.OfficeState,
					Url: addressProofImage,
				},
			})
		)
			.then(unwrapResult)
			.then((urlresult: any) => {
				if (urlresult?.status) {
					data.AddressProof = urlresult?.AddressProofName;
					data.PermanentAddress = formData.PermanentAddress;
					data.PermanentPinCode = formData.PermanentPinCode;
					data.PermanentCity = formData.PermanentCity;
					data.PermanentState = formData.PermanentState;
					data.CurrentAddress = formData.CurrentAddress;
					data.CurrentPinCode = formData.CurrentPinCode;
					data.CurrentCity = formData.CurrentCity;
					data.CurrentState = formData.CurrentState;
					data.CurrentPermanentAddressSame =
						formData.CurrentPermanentAddressSame;
					data.OfficeAddress = formData.OfficeAddress;
					data.OfficePinCode = formData.OfficePinCode;
					data.OfficeCity = formData.OfficeCity;
					data.OfficeState = formData.OfficeState;
					data.Url = addressProofImage;
					setUserData(data);
					setShowAddressProofInfoForm(false);
					setAddressProofImage('');
				}
			});
	};

	const inputFields = [
		{
			name: 'AddressProof',
			type: 'dropdown',
			placeholder: 'Address Proof',
		},
		{
			name: 'CurrentAddress',
			type: 'text',
			placeholder: 'Current Residential Address',
		},
		{
			name: 'CurrentPinCode',
			type: 'number',
			placeholder: 'Pin Code',
		},
		{
			name: 'CurrentPermanentAddressSame',
			type: 'checkbox',
			// placeholder: 'Residential Address *',
		},
		{
			name: 'PermanentAddress',
			type: 'text',
			placeholder: 'Permanent Residential Address',
		},
		{
			name: 'PermanentPinCode',
			type: 'number',
			placeholder: 'Pin Code',
		},
		{
			name: 'OfficeAddress',
			type: 'text',
			placeholder: 'Office Address',
		},
		{
			name: 'OfficePinCode',
			type: 'number',
			placeholder: 'Pin Code',
		},
	];

	return (
		<section
			className='overflowX-hidden py-2'
			style={{ height: 'calc(100vh - 180px)' }}
		>
			<div>
				<form onSubmit={handleSubmit(onSubmit)}>
					{inputFields?.map(({ name, type, placeholder }: any) => {
						return (
							<>
								{type === 'dropdown' ? (
									<div>
										<Controller
											control={control}
											render={({ field: { onChange, onBlur } }) => (
												<DropDownModal
													label={t(`${'Address Proof'}`) + ' *'}
													data={addressProof}
													name={'AddressProof'}
													onChange={(e: any) => {
														onChange(e);
														// setAddressProofId(e.target.value);
													}}
													value={getValues('AddressProof')}
													onBlur={onBlur}
													errors={errors['AddressProof']?.message}
												/>
											)}
											name={'AddressProof'}
										/>
										<div className='my-3'>
											<UploadImageCard
												{...{
													setImagePreviewUpload,
													imagePreviewUpload,
													isImageAvilable:
														imagePreviewUpload?.addressProofImage,
													imgKey: 'addressProofImage',
													setClickImageFieldName,
													setOpenUploadModal,
													setValue,
													value: 'Url',
													title: 'Upload Proof',
													src: getValues('Url'),
												}}
											/>
										</div>
									</div>
								) : type === 'checkbox' ? (
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, value } }) => (
											<CustomCheckbox
												name='CurrentPermanentAddressSame'
												onChange={({ detail: { checked } }: any) => {
													onChange(checked);

													if (checked) {
														fetchStateCityByPincode(
															setValue,
															'PermanentCity',
															'PermanentState',
															setPinCodeData,
															dispatch,
															getValues('CurrentPinCode'),
															trigger,
															true,
															'PermanentPinCode'
														);
														setValue(
															'PermanentAddress',
															getValues('CurrentAddress')
														);
														setDisablePermanentAddress(true);
														setValue(
															'PermanentPinCode',
															getValues('CurrentPinCode')
														);
														setDisablePermanentPincode(true);
														trigger(['PermanentAddress', 'PermanentPinCode']);
													} else {
														setValue('PermanentCity', '');
														setValue('PermanentState', '');
														setPinCodeData({
															...pinCodeData,
															PermanentPinCode: [],
														});
														setValue('PermanentAddress', '');
														setDisablePermanentAddress(false);
														setValue('PermanentPinCode', '');
														setDisablePermanentPincode(false);
													}
												}}
												value={value}
												labelPlacement='end'
												className='end-of-page mb-2'
												onBlur={onBlur}
												// errors={errors?.CurrentAddress?.message}
												isChecked={value}
												children={
													<span className='text-wrap'>
														{t('Permanent Address is same as Current Address')}
													</span>
												}
											></CustomCheckbox>
										)}
										name='CurrentPermanentAddressSame'
									/>
								) : (
									<>
										<Controller
											key={name}
											control={control}
											render={({
												field: { onChange, onBlur, name: name1 },
											}) => (
												<CustomInput
													type={type}
													name={name1}
													placeholder={t(`${placeholder}`) + ' *'}
													onBlur={onBlur}
													onChange={(e: any) => {
														if (name1 === 'CurrentPinCode') {
															if (e.detail.value.length === 6) {
																fetchStateCityByPincode(
																	setValue,
																	'CurrentCity',
																	'CurrentState',
																	setPinCodeData,
																	dispatch,
																	e.detail.value,
																	trigger,
																	true,
																	'CurrentPinCode'
																);
															} else {
																setValue('CurrentCity', '');
																setValue('CurrentState', '');
																setPinCodeData({
																	...pinCodeData,
																	CurrentPinCode: [],
																});
															}
														}
														if (name1 === 'PermanentPinCode') {
															if (e.detail.value.length === 6) {
																fetchStateCityByPincode(
																	setValue,
																	'PermanentCity',
																	'PermanentState',
																	setPinCodeData,
																	dispatch,
																	e.detail.value,
																	trigger,
																	true,
																	'PermanentPinCode'
																);
															} else {
																setValue('PermanentCity', '');
																setValue('PermanentState', '');
																setPinCodeData({
																	...pinCodeData,
																	PermanentPinCode: [],
																});
															}
														}
														if (name1 === 'OfficePinCode') {
															if (e.detail.value.length === 6) {
																fetchStateCityByPincode(
																	setValue,
																	'OfficeCity',
																	'OfficeState',
																	setPinCodeData,
																	dispatch,
																	e.detail.value,
																	trigger,
																	true,
																	'OfficePinCode'
																);
															} else {
																setValue('OfficeCity', '');
																setValue('OfficeState', '');
																setPinCodeData({
																	...pinCodeData,
																	OfficePinCode: [],
																});
															}
														}
														onChange(e);
													}}
													value={getValues(name1)}
													errors={t(errors[name1]?.message)}
													onBeforeInput={(evt: any) => {
														if (type == 'number') {
															!/[0-9]/.test(evt.data) && evt.preventDefault();
														}
													}}
													onKeyDown={(e: any) => {
														if (type == 'number') {
															disableNumberArrows(e);
														}
													}}
													onWheel={(e: any) => {
														if (type == 'number') {
															e.target.blur();
														}
													}}
													disabled={
														name === 'PermanentAddress'
															? disablePermanentAddress
															: name === 'PermanentPinCode'
															? disablePermanentPincode
															: false
													}
													// maxLength={10}
												/>
											)}
											name={name}
										/>
										{name === 'CurrentPinCode' ? (
											<div className=''>
												<StateCity
													cityInputName='CurrentCity'
													stateInputName='CurrentState'
													control={control}
													setValue={setValue}
													pinCodeData={pinCodeData['CurrentPinCode']}
													trigger={trigger}
													getValues={getValues}
												/>
												{getValues('CurrentPinCode')?.length === 6 &&
												getValues('CurrentCity') === '' ? (
													<div className='city-state-error error-text sc-ion-input-md px-3'>
														{t('City or State is not selected')}
													</div>
												) : (
													''
												)}
											</div>
										) : (
											''
										)}
										{name === 'PermanentPinCode' ? (
											<div className=''>
												<StateCity
													cityInputName='PermanentCity'
													stateInputName='PermanentState'
													control={control}
													setValue={setValue}
													pinCodeData={pinCodeData['PermanentPinCode']}
													trigger={trigger}
													getValues={getValues}
												/>
												{getValues('PermanentPinCode')?.length === 6 &&
												getValues('PermanentCity') === '' ? (
													<div className='city-state-error error-text sc-ion-input-md px-3'>
														{t('City or State is not selected')}
													</div>
												) : (
													''
												)}
											</div>
										) : (
											''
										)}
										{name === 'OfficePinCode' ? (
											<div className=''>
												<StateCity
													cityInputName='OfficeCity'
													stateInputName='OfficeState'
													control={control}
													setValue={setValue}
													pinCodeData={pinCodeData['OfficePinCode']}
													trigger={trigger}
													getValues={getValues}
												/>
												{getValues('OfficePinCode')?.length === 6 &&
												getValues('OfficeCity') === '' ? (
													<div className='city-state-error error-text sc-ion-input-md px-3'>
														{t('City or State is not selected')}
													</div>
												) : (
													''
												)}
											</div>
										) : (
											''
										)}
									</>
								)}
							</>
						);
					})}

					<div
						className='position-fixed w-100 bottom-0 start-0 px-3 pt-1 pb-3'
						style={{ backgroundColor: '#fff', zIndex: 9 }}
					>
						<CustomButton
							className='btn-blue'
							type='submit'
							expand='block'
							title={t('Update')}
							disabled={!isValid}
						/>
					</div>
				</form>
			</div>
			{/* Upload Photo Modal */}
			<CustomModal
				id='sharemodal'
				isOpen={openUploadModal}
				initialBreakpoint={isWeb ? 1 : 0.3}
				handleClose={() => setOpenUploadModal(false)}
				breakpoints={[0, 0.3, 0.5, 0.75]}
				modalContent={<ShareModal />}
				needCross={false}
				needArrow={false}
				className={`side-modal ${isWeb ? '' : 'modal-border-radius'}`}
			/>
		</section>
	);
};
