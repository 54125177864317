import { IonContent, IonPage } from '@ionic/react';
import { Player } from '@lottiefiles/react-lottie-player';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import './CustomSucessfull.scss';
//import { resetLeadState } from '../../store/reducers/lead/leadSlice';










export default function CustomInsSuccessfull() {
	const history = useHistory();
	const stateLocation= useLocation();
	const state = stateLocation.state as any;

	// useEffect(() => {
	// 	return () => {
	// 	  window.removeEventListener('beforeunload', (e) => e.preventDefault());
	// 	  window.removeEventListener('keydown', (e) => {
	// 		if ((e.ctrlKey && e.key === 'r') || e.key === 'F5' || (e.metaKey && e.key === 'r')) {
	// 		  e.preventDefault();
	// 		}
	// 	  });
	// 	};
	//   }, []);


	useEffect(() => {
		if (history.action === 'POP') {
			history.push('/login');
		}
	}, [history.action]);
	
	
	const compeletdTick = (types: any) => {
		return 'GreenTick';
		
	};
	
	
	
	

	return (
		<IonPage className=''>
			<IonContent className='background-web'>
				{' '}
				<div className='bg-web d-md-flex justify-content-md-center align-items-md-center h-100 '>
					<div className='h-50 container-width-md success-container'>
						<div
							className=' bg-web-white  border-md-radius-10 d-flex ion-justify-content-center successfull-invitation px-5 ion-align-items-center'
							style={{
								flexDirection: 'column',
								background:
									'transparent linear-gradient(180deg, rgba(210, 255, 210, 1) 0%, #ffffff 35%) 0% 0% no-repeat padding-box',
							}}
						>
							<Player
								// className="pe-3"
								style={{
									width: '72px',
									marginBottom: '10px',
								}}
								onEvent={(event) => {
									if (event === 'complete') {
										if (state.redirectTo !== '') {
											setTimeout(() => {
												history.replace(state?.redirectTo);
											}, 500);
										}
									}
								}}
								keepLastFrame={true}
								autoplay={true}
								loop={false}
								controls={false}
								src={`/images/icons/json/${compeletdTick('')}.json`}
							/>
							<h2 className='text-center m-0 fs-18 darkest-grey pt-2'>
								{state.message}
							</h2>
						</div>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
}
