import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { CustomInput } from '../../../../../components/common/custom-input/CustomInput';
// import { CustomCheckbox } from '../../../../../components/common/custom-checkbox/CustomCheckbox';
import {
	IonItem,
	IonLabel,
	IonList,
	IonRadio,
	IonRadioGroup,
	isPlatform,
} from '@ionic/react';
// import { CustomSelect } from '../../../../../components/common/custom-select/CustomSelect';
// import { UploadImageCard } from '../../../../dashboard/kyc/UploadImageCard';
import CustomDropDown from '../../../../../components/common/custom-dropdown/CustomDropDown';
import { CustomModal } from '../../../../../components/common/custom-modal/CustomModal';
import { yupResolver } from '@hookform/resolvers/yup';
import { professionalDetailsKycScema } from '../../../../dashboard/kyc/KycValidationSchema';

import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import {
	InputFormatCurrencyValue,
	dataURLtoFile,
	// showToastAlert,
} from '../../../../../helpers/functions';
import { CustomButton } from '../../../../../components/common/custom-button/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import {
	apiKycDocumentUpload,
	// contributionPinCode,
	imgUrlUpdate,
	// kycAddressProof,
	kycBasicInfo,
	kycLeadProduct,
	kycUserProfession,
	kycUserUploadImage,
} from '../../../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { Divider } from '../../../../../components/common/divider/Divider';
import { REGEX } from '../../../../../helpers/constant';

interface Props {
	userId: any;
	setShowProfessionalInfoForm: any;
	getUserDetailsInfo: any;
}

export const ProfessionalInfoEdit: React.FC<Props> = ({
	userId,
	setShowProfessionalInfoForm,
	getUserDetailsInfo,
}) => {
	const state = useSelector((state: any) => state?.users?.user_details);
	const dispatch: any = useDispatch();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isFinance, setIsFinance] = useState<any>('');
	// const [isAssociated, setIsAssociated] = useState<any>('');
	const { t } = useTranslation();
	const [openUploadModal, setOpenUploadModal] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [clickImageFieldName, setClickImageFieldName] = useState<any>(null);
	const [imagePreviewUpload, setImagePreviewUpload] = useState({
		addressProofImage: false,
	});
	const [productData, setProductData] = useState([]);
	const [userProfession, setUserProfession] = useState([]);
	// const [addressProof, setAddressProof] = useState([]);
	// const [pinCodeData, setPinCodeData] = useState<any>([]);
	// const [pinCodeValue, setPincodeValue] = useState<any>({});
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [RegisteredAsType, setRegisteredAsType] = useState<any>('');
	const [schema, setSchema] = useState<any>(professionalDetailsKycScema);
	const dmaSelector = useSelector((state: any) => state?.users?.dmaFlow);
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	const isBrowser = !isPlatform('capacitor');
	useEffect(() => {
		fetchProfessionalDetailsKyc();
	}, [state]);
	useEffect(() => {
		fetchLeadProduct();
		fetchUserProfession();
		// fetchUserAddrssProof();
	}, []);

	// react hooks form start
	const {
		handleSubmit,
		control,
		setValue,
		trigger,
		reset,
		watch,
		formState: { errors, isValid },
		getValues,
	}: any = useForm({
		resolver: yupResolver(schema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			Type: 'AddressProof',
			Url: '',
			status: 'added',
			Data: {
				PermanentAddress: '',
				CurrentAddress: '',
				CurrentPermanentAddressSame: false,
				DSAAssociation: false,
				PinCode: '',
				State: '',
				City: '',
				OfficeAddress: '',
				AddressProof: '',
				CurrentBussines: '',
				Product: [],
				Income: '',
				BusinessNature: '',
				BusinessNatureFinance: '',
				Profession: '',
				BusinessDetail: '',
				ReferenceName1: '',
				ReferenceMobile1: '',
				ReferenceName2: '',
				ReferenceMobile2: '',
			},
		},
	});

	useEffect(() => {
		getData();
	}, [getValues('Data.CurrentBussines')]);

	const getData = async () => {
		delete professionalDetailsKycScema.fields.Data?.fields.AddressProof;
		if (watch('Data.CurrentBussines') === 'Finance') {
			setIsFinance(true);
			// setIsAssociated('');
			setValue('Data.finance', true);
			trigger();
			// for inner checkbox yes/no
			if (parseInt(watch('Data.DSAAssociation'))) {
				// setIsAssociated(Boolean(parseInt(watch('Data.DSAAssociation'))));
				await dispatch(kycLeadProduct())
					.then(unwrapResult)
					.then((result: any) => {
						if (result?.status) {
							const arrData: any = [];
							result?.data.forEach((val: any) => {
								if (getValues('Data.Product')?.includes(val.value)) {
									arrData.push({ ...val, checked: true });
								} else {
									arrData.push({ ...val });
								}
							});
							setProductData(arrData);
						}
					});
			} else {
				// setIsAssociated(Boolean(parseInt(watch('Data.DSAAssociation'))));
			}
		} else if (watch('Data.CurrentBussines') === 'Non-Finance') {
			// setValue('Data.CurrentBussines', 'Non-Finance');
			// setIsAssociated('');
			setIsFinance(false);
			setValue('Data.finance', false);
			// setIsAssociated('');
			trigger();

			// setIsAssociated(Boolean(parseInt(watch('Data.DSAAssociation'))));
			await dispatch(kycUserProfession())
				.then(unwrapResult)
				.then((result: any) => {
					if (result?.status) {
						const arrData: any = [];
						result?.data.forEach((val: any) => {
							if (getValues('Data.Profession') === val?.id?.toString()) {
								arrData.push({ ...val, checked: true });
							} else {
								arrData.push({ ...val });
							}
						});
						setUserProfession(arrData);
					}
				});
		}
	};

	const onSubmit = async (data: any) => {
		delete data.Data.Product;
		delete data.Data.Profession;
		// delete data.Data.AddressProof;
		const products = productData
			?.filter(({ checked }: any) => checked && checked)
			.map(({ value }: any) => value);
		const profession = userProfession
			?.filter(({ checked }: any) => checked && checked)
			.map(({ id }: any) => id);
		// const address = addressProof
		// 	?.filter(({ checked }: any) => checked && checked)
		// 	.map(({ value }: any) => value);
		data.Data.Product = products;
		data.Data.Profession = profession.toString();
		// data.Data.AddressProof = address.toString();
		// data.Data.City = pinCodeValue.city;
		// data.Data.State = pinCodeValue.state;
		const doc = {
			document: [
				{
					Type: 'AddressProof',
					Url: data.Url,
					Data: { ...data.Data },
					status: 'added',
				},
			],
		};
		await dispatch(
			apiKycDocumentUpload({ param: '/' + userId, data: { ...doc, RW: true } })
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setShowProfessionalInfoForm(false);
					getUserDetailsInfo(dmaSelector?.dmaId, dmaSelector?.show_tab);
					//setCurrentStep('step4');
				}
			});
	};
	const ShareModal = () => {
		const handleUploadChange = async (
			type: string,
			fieldName: string,
			e: any
		) => {
			setOpenUploadModal(false);
			// case1
			const formData = new FormData();

			// case 2
			let image: any;
			switch (type) {
				case 'file':
					formData.append('profile', e.target.files[0]);
					await dispatch(kycUserUploadImage({ data: formData }))
						.then(unwrapResult)
						.then((result: any) => {
							if (result?.status) {
								setValue(fieldName, result?.url);
								switch (fieldName) {
									case 'Url':
										setImagePreviewUpload({
											...imagePreviewUpload,
											addressProofImage: true,
										});
										break;
								}
							}
						});

					break;
				case 'photo':
					image = await Camera.getPhoto({
						quality: 75,
						allowEditing: false,
						resultType: CameraResultType.DataUrl,
						source: CameraSource.Photos,
					});
					formData.append(
						'profile',
						dataURLtoFile(
							image.dataUrl,
							`${Math.random() * 100000000000000000}.${image.format}`
						)
					);
					await dispatch(kycUserUploadImage({ data: formData }))
						.then(unwrapResult)
						.then((result: any) => {
							if (result?.status) {
								setValue(fieldName, result?.url);
								switch (fieldName) {
									case 'Url':
										setImagePreviewUpload({
											...imagePreviewUpload,
											addressProofImage: true,
										});
										break;
								}
							}
						});

					break;
				case 'camera':
					image = await Camera.getPhoto({
						quality: 75,
						allowEditing: false,
						resultType: CameraResultType.DataUrl,
						source: CameraSource.Camera,
					});
					formData.append(
						'profile',
						dataURLtoFile(
							image.dataUrl,
							`${Math.random() * 100000000000000000}.${image.format}`
						)
					);
					await dispatch(kycUserUploadImage({ data: formData }))
						.then(unwrapResult)
						.then((result: any) => {
							if (result?.status) {
								setValue(fieldName, result?.url);
								switch (fieldName) {
									case 'Url':
										setImagePreviewUpload({
											...imagePreviewUpload,
											addressProofImage: true,
										});
										break;
								}
							}
						});

					break;
				default:
					break;
			}
		};
		return (
			<>
				<IonList>
					{!isBrowser && (
						<IonItem lines='full'>
							<img
								src='/images/icons/kyc/Image-upload.svg'
								alt='Upload Photo'
							/>
							<IonLabel>
								<h2
									className='fs-16 ps-3 text-dark'
									onClick={(e) => {
										handleUploadChange('photo', clickImageFieldName, e);
									}}
								>
									Upload Photo
								</h2>
							</IonLabel>
						</IonItem>
					)}
					<IonItem lines='full'>
						<img
							src='/images/icons/kyc/upload-image-icon.svg'
							alt='Upload Photo'
						/>
						<IonLabel>
							<label htmlFor='upload-photo'>
								<h2 className='fs-16 ps-3 text-dark'>{t('Upload Document')}</h2>
							</label>
							<input
								type='file'
								id='upload-photo'
								accept='image/png, image/jpeg ,application/pdf'
								onChange={(e) => {
									handleUploadChange('file', clickImageFieldName, e);
								}}
							/>
						</IonLabel>
					</IonItem>
					{!isBrowser && (
						<IonItem lines='full'>
							<img
								src='/images/icons/kyc/camera-icon.svg'
								alt='Upload Photo'
							/>
							<IonLabel>
								<h2
									className='fs-16 ps-3 text-dark'
									onClick={(e) => {
										handleUploadChange('camera', clickImageFieldName, e);
									}}
								>
									Take Photo
								</h2>
							</IonLabel>
						</IonItem>
					)}
				</IonList>
			</>
		);
	};
	const fetchProfessionalDetailsKyc = async () => {
		await dispatch(kycBasicInfo({ param: userId + `/step3` }))
			.then(unwrapResult)
			.then(async (result: any) => {
				if (result?.status) {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					let url: any = '';
					if (result?.data?.[0]) {
						setRegisteredAsType(result?.data?.[0]?.Data['RegisteredAs']);
					}
					if (result?.data[0]?.Url) {
						await dispatch(
							imgUrlUpdate({
								data: { url: result?.data[0].Url, expire_time: 50000 },
							})
						)
							.then(unwrapResult)
							.then((getSrcUrl: any) => {
								url = getSrcUrl?.url;
							});
					}
					if (result?.data[0]?.Url) {
						setImagePreviewUpload({
							...imagePreviewUpload,
							addressProofImage: true,
						});
					}
					reset({ ...result?.data[0] });

					if (result?.data[0].Data.CurrentBussines === 'Finance') {
						const someSchema = professionalDetailsKycScema.concat(
							yup.object().shape({
								Data: yup.object().shape({
									...professionalDetailsKycScema.fields.Data?.fields,
									DSAAssociation: yup
										.boolean()
										.required('Please check one of this options '),
								}),
							})
						);
						setSchema(someSchema);
					} else if (result?.data[0].Data.CurrentBussines === 'Non-Finance') {
						const someSchema = professionalDetailsKycScema.concat(
							yup.object().shape({
								Data: yup.object().shape({
									...professionalDetailsKycScema.fields.Data?.fields,
									BusinessNature: yup
										.string()
										.trim()
										.required('Nature of Business is required')
										.matches(REGEX.FULLNAME, 'Please Enter Valid Format'),
									Profession: yup
										.string()
										.trim()
										.required('Profession is required'),
								}),
							})
						);
						setSchema(someSchema);
					}

					if (result?.data[0].Data.DSAAssociation === '1') {
						const someSchema = professionalDetailsKycScema.concat(
							yup.object().shape({
								Data: yup.object().shape({
									...professionalDetailsKycScema.fields.Data?.fields,
									Product: yup.array().min(1, 'Select at least 1 product'),
									Income: yup
										.number()
										.min(1, 'Amount must be greater than or equal to 1!')
										.typeError('Amount should be number')
										.required(),
								}),
							})
						);
						setSchema(someSchema);
					} else {
						const someSchema = professionalDetailsKycScema.concat(
							yup.object().shape({
								Data: yup.object().shape({
									...professionalDetailsKycScema.fields.Data?.fields,
									BusinessNature: yup
										.string()
										.trim()
										.required('Nature of Business is required')
										.matches(REGEX.FULLNAME, 'Please Enter Valid Format'),
								}),
							})
						);

						if (result?.data[0].Data.CurrentBussines === 'Finance') {
							setValue('Data.DSAAssociation', false);
						}
						setSchema(someSchema);
					}
				}
			});
	};

	const fetchLeadProduct = async () => {
		await dispatch(kycLeadProduct())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setProductData(result?.data);
				}
			});
	};

	const fetchUserProfession = async () => {
		await dispatch(kycUserProfession())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setUserProfession(result?.data);
				}
			});
	};

	// const fetchUserAddrssProof = async () => {
	// 	dispatch(kycAddressProof())
	// 		.then(unwrapResult)
	// 		.then((result: any) => {
	// 			if (result?.status) {
	// 				const addressProofData = result?.data.map((item: any) => {
	// 					return {
	// 						id: item.id,
	// 						name: item.value,
	// 					};
	// 				});
	// 				setAddressProof(addressProofData);
	// 			}
	// 		});
	// };
	// const pinCodeAction = async (value: any) => {
	// 	setPinCodeData([]);
	// 	dispatch(contributionPinCode({ data: { pincode: value } }))
	// 		.then(unwrapResult)
	// 		.then((result: any) => {
	// 			if (result?.status) {
	// 				if (!result?.list) {
	// 					showToastAlert({
	// 						type: 'error',
	// 						message: 'Please Check Pin Code No.',
	// 					});
	// 					setPinCodeData([]);
	// 				} else {
	// 					setPinCodeData(result?.list);
	// 				}
	// 			}
	// 		});
	// };
	return (
		<section
			className='overflowX-hidden'
			style={{ height: 'calc(100vh - 180px)' }}
		>
			{/*<h4>Address Details</h4>
			<p className='mt-2 mb-4'>
				Entity
				<b className='text-capitalize'> {RegisteredAsType}</b>
	</p>*/}
			<form onSubmit={handleSubmit(onSubmit)}>
				{/*<div className='mb-4'>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, value } }) => (
							<CustomInput
								type='text'
								placeholder='Permanent Residential Address *'
								onBlur={onBlur}
								onChange={onChange}
								value={value}
								errors={errors?.Data?.PermanentAddress?.message}
							/>
						)}
						name='Data.PermanentAddress'
					/>
				</div>
				<div className='mb-4'>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, value } }) => (
							<CustomInput
								type='text'
								placeholder='Current Residential Address *'
								onBlur={onBlur}
								onChange={onChange}
								value={value}
								errors={errors?.Data?.CurrentAddress?.message}
							/>
						)}
						name='Data.CurrentAddress'
					/>
				</div>
				<div className='mb-4'>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, value } }) => (
							<CustomCheckbox
								name='Data.CurrentPermanentAddressSame'
								onChange={({ detail: { checked } }: any) => {
									onChange(checked);

									if (checked) {
										setValue(
											'Data.CurrentAddress',
											getValues('Data.PermanentAddress')
										);
									} else {
										setValue('Data.CurrentAddress', '');
									}
								}}
								value={value}
								labelPlacement='end'
								className='end-of-page'
								onBlur={onBlur}
								// errors={errors?.CurrentAddress?.message}
								isChecked={value}
								children={
									<span className='text-wrap'>
										{t('Current Address is same as Permanent Address.')}
									</span>
								}
							></CustomCheckbox>
						)}
						name='Data.CurrentPermanentAddressSame'
					/>
				</div>
				<div className='mb-4'>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, value } }) => (
							<CustomInput
								type='number'
								placeholder='PinCode *'
								onBlur={onBlur}
								onChange={(e: any) => {
									if (e.detail.value.length === 6) {
										pinCodeAction(e.detail.value);
									}
									onChange(e);
								}}
								value={value}
								errors={errors?.Data?.PinCode?.message}
							/>
						)}
						name='Data.PinCode'
					/>
				</div>
				<div className='mb-4'>
					{pinCodeData && pinCodeData.length > 0 && (
						<>
							<IonLabel>{t('City')}</IonLabel>
							<div className='ion-margin-bottom'>
								{pinCodeData.map(({ Name }: any) => {
									return (
										<span
											className={`selected-label selected-label-cursor ${
												Name === pinCodeValue.city
													? ' selected-label-active'
													: ''
											}`}
											onClick={() =>
												setPincodeValue({
													state: pinCodeData[0].State,
													city: Name,
												})
											}
										>
											{Name}
										</span>
									);
								})}
							</div>
						</>
					)}
					{pinCodeData && pinCodeData.length > 0 && (
						<>
							<IonLabel>{t('State')}</IonLabel>
							<div className='ion-margin-bottom'>
								<span className='selected-label'>{pinCodeData[0].State}</span>
							</div>
						</>
					)}
				</div>
				<div className='mb-4'>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, value } }) => (
							<CustomInput
								type='text'
								placeholder='OfficeAddress *'
								onBlur={onBlur}
								onChange={onChange}
								value={value}
								errors={errors?.Data?.OfficeAddress?.message}
							/>
						)}
						name='Data.OfficeAddress'
					/>
				</div>*/}
				{/* <div>
					<IonLabel className='mb-4'>Address Proof *</IonLabel>
					<Controller
						control={control}
						render={() => (
							// { field: { onChange, onBlur, name } }
							<CustomSelect
								data={addressProof}
								type='manual'
								name={'select'}
								value={getValues('Data.AddressProof')}
								label={'select'}
								onIonChange={(e: any) => {
									setValue('Data.AddressProof', e.target.value);
								}}
								// manualRegister={register(name)}
								// error={errorText[name]?.message}
								className='mt-1 w-100'
							/>
						)}
						name='Data.AddressProof'
					/>

					<div className='my-3'>
						<UploadImageCard
							{...{
								setImagePreviewUpload,
								imagePreviewUpload,
								isImageAvilable: imagePreviewUpload?.addressProofImage,
								imgKey: 'addressProofImage',
								setClickImageFieldName,
								setOpenUploadModal,
								setValue,
								value: 'Url',
								title: 'Upload Proof',
								src: getValues('Url'),
							}}
						/>
					</div>
				</div> */}
				<h4>{t('Business Details')}</h4>

				<div className='mb-4'>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, name } }) => (
							<IonRadioGroup
								value={getValues('Data.CurrentBussines')}
								onIonChange={(e: any) => {
									if (e.detail.value === 'Finance') {
										delete professionalDetailsKycScema.fields.Data?.fields
											.DSAAssociation;
										delete professionalDetailsKycScema.fields.Data?.fields
											.BusinessNature;
										delete professionalDetailsKycScema.fields.Data?.fields
											.Profession;
										if (!errors) {
											trigger();
										}
										const someSchema = professionalDetailsKycScema.concat(
											yup.object().shape({
												Data: yup.object().shape({
													...professionalDetailsKycScema.fields.Data?.fields,
													DSAAssociation: yup
														.boolean()
														.required('Please check one of this options '),
												}),
											})
										);
										setSchema(someSchema);
										setIsFinance(true);
										// setIsAssociated('');
										setValue('Data.CurrentBussines', 'Finance');
										setValue('Data.finance', true);
										setValue('Data.Income', '');
										setValue('Data.Profession', '');
										const unsetProfession: any = userProfession.map(
											(item: any) => {
												return {
													...item,
													checked: false,
												};
											}
										);
										setUserProfession(unsetProfession);
										setValue('Data.BusinessNature', '');
									} else if (e.detail.value === 'Non-Finance') {
										delete professionalDetailsKycScema.fields.Data?.fields
											.DSAAssociation;
										delete professionalDetailsKycScema.fields.Data?.fields
											.Income;
										delete professionalDetailsKycScema.fields.Data?.fields
											.BusinessNature;
										const someSchema = professionalDetailsKycScema.concat(
											yup.object().shape({
												Data: yup.object().shape({
													...professionalDetailsKycScema.fields.Data?.fields,
													BusinessNature: yup
														.string()
														.trim()
														.required('Nature of Business is required')
														.matches(
															REGEX.FULLNAME,
															'Please Enter Valid Format'
														),
													Profession: yup
														.string()
														.trim()
														.required('Profession is required'),
												}),
											})
										);
										setSchema(someSchema);
										setValue('Data.CurrentBussines', 'Non-Finance');
										setValue('Data.DSAAssociation', null);
										const products: any = productData.map((item: any) => {
											return {
												...item,
												checked: false,
											};
										});
										setProductData(products);
										setValue('Data.Product', []);
										setIsFinance(false);
										setValue('Data.finance', false);
										setValue('Data.BusinessNature', '');
										// setIsAssociated('');
										if (!errors) {
											trigger();
										}
									}
									onChange(e);
								}}
								onBlur={onBlur}
								className='mt-3'
								name={name}
							>
								<IonRadio
									aria-label='Finance'
									value='Finance'
									className='me-3'
									id={'finance'}
								>
									{t('Finance')}
								</IonRadio>
								<IonRadio
									aria-label='Non-Finance'
									value='Non-Finance'
									id={'nonfinance'}
								>
									{t('Non-Finance')}
								</IonRadio>
							</IonRadioGroup>
						)}
						name='Data.finance'
					/>
				</div>
				{watch('Data.CurrentBussines') === 'Finance' && (
					<>
						<div className='mb-4'>
							<p className='mb-2'>
								{t('Are you associated with other Bank/DSA?')}
							</p>
							<Controller
								control={control}
								render={({ field: { onChange, onBlur, name } }) => (
									<IonRadioGroup
										value={
											typeof watch('Data.DSAAssociation') === 'string'
												? Boolean(parseInt(watch('Data.DSAAssociation')))
												: watch('Data.DSAAssociation')
										}
										className='mt-3'
										onIonChange={(e: any) => {
											setValue('Data.DSAAssociation', e.detail.value);
											// setIsAssociated(e.detail.value);
											if (e.detail.value) {
												setValue('Data.BusinessNature', '');
												delete professionalDetailsKycScema.fields.Data?.fields
													.BusinessNature;
												const someSchema = professionalDetailsKycScema.concat(
													yup.object().shape({
														Data: yup.object().shape({
															...professionalDetailsKycScema.fields.Data
																?.fields,
															Product: yup
																.array()
																.min(1, 'Select at least 1 product'),
															Income: yup
																.number()
																.min(
																	1,
																	'Amount must be greater than or equal to 1!'
																)
																.typeError('Amount should be number')
																.required(),
														}),
													})
												);
												setSchema(someSchema);
												trigger('Data.DSAAssociation');
												trigger('Data.Product');
												trigger('Data.Income');
											} else {
												setValue('Data.Income', '');
												delete professionalDetailsKycScema.fields.Data?.fields
													.Income;
												const someSchema = professionalDetailsKycScema.concat(
													yup.object().shape({
														Data: yup.object().shape({
															...professionalDetailsKycScema.fields.Data
																?.fields,
															BusinessNature: yup
																.string()
																.trim()
																.required('Nature of Business is required')
																.matches(
																	REGEX.FULLNAME,
																	'Please Enter Valid Format'
																),
														}),
													})
												);
												setSchema(someSchema);
												trigger('Data.BusinessNature');

												const products: any = productData.map((item: any) => {
													return {
														...item,
														checked: false,
													};
												});
												setProductData(products);
												setValue('Data.Product', []);
											}
											onChange(e);
										}}
										onBlur={onBlur}
										name={name}
									>
										<IonRadio
											value={true}
											className='me-3'
										>
											{t('Yes')}
										</IonRadio>
										<IonRadio value={false}>{t('No')}</IonRadio>
									</IonRadioGroup>
								)}
								name='Data.DSAAssociation'
							/>
						</div>
						{watch('Data.DSAAssociation') &&
							watch('Data.CurrentBussines') === 'Finance' && (
								<>
									<div className='mb-4'>
										<p>{t('a. Product you deal with')} *</p>
										<CustomDropDown
											{...{
												isCheckbox: true,
												title: 'Product',
												//setData: setProductData,
												setData: (data: any) => {
													setProductData(data);
													setValue(
														'Data.Product',
														data
															?.filter(({ checked }: any) => checked && checked)
															.map(({ id }: any) => id)
													);
													trigger('Data.Product');
												},
												data: productData,
												placeholder: t('Search'),
												isSearch: false,
												modalTitle: t('Select Product'),
											}}
										/>
									</div>
									<div className='mb-4'>
										<p>{t('b. Monthly Business Volume')} *</p>
										<Controller
											control={control}
											render={({ field: { onChange, onBlur, name } }) => (
												<CustomInput
													type='text'
													placeholder={t('Enter Amount')}
													onBlur={onBlur}
													name={name}
													onChange={(e: any) => {
														e = isNaN(e.detail.value.replaceAll(',', ''))
															? e
															: Number(e.detail.value.replace(/,/g, ''));
														onChange(e);
													}}
													value={
														name == 'Data.Income'
															? getValues(name)
																? InputFormatCurrencyValue(
																		isNaN(getValues(name))
																			? getValues(name)
																			: Number(getValues(name))
																  )
																: getValues(name)
															: getValues(name)
													}
													errors={t(errors?.Data?.Income?.message)}
												/>
											)}
											name='Data.Income'
										/>
									</div>
								</>
							)}
					</>
				)}
				{watch('Data.CurrentBussines') === 'Non-Finance' && (
					<>
						<IonLabel className='mb-4'>{t('Profession')} *</IonLabel>
						<CustomDropDown
							{...{
								isCheckbox: false,
								title: t('Select Profession'),
								setData: (data: any) => {
									setUserProfession(data);
									setValue(
										'Data.Profession',
										data
											?.filter(({ checked }: any) => checked && checked)
											.map(({ id }: any) => id)
											.toString()
									);
									trigger('Data.Profession');
								},
								data: userProfession,
								placeholder: t('Search'),
								isSearch: false,
								modalTitle: t('Select Profession'),
							}}
						/>
					</>
				)}
				{!watch('Data.DSAAssociation') &&
					watch('Data.DSAAssociation') != null &&
					watch('Data.CurrentBussines') === 'Finance' && (
						<div className='mb-4'>
							<Controller
								control={control}
								render={({ field: { onChange, onBlur, value, name } }) => (
									<CustomInput
										type='text'
										placeholder={t('Nature of Business') + ' *'}
										onBlur={onBlur}
										onChange={onChange}
										value={value}
										name={name}
										errors={t(errors?.Data?.BusinessNature?.message)}
									/>
								)}
								name='Data.BusinessNature'
							/>
						</div>
					)}
				{getValues('Data.CurrentBussines') === 'Non-Finance' && (
					<div className='mb-4 mt-3'>
						<Controller
							control={control}
							render={({ field: { onChange, onBlur, value, name } }) => (
								<CustomInput
									type='text'
									placeholder={t('Nature of Business') + ' *'}
									onBlur={onBlur}
									onChange={onChange}
									value={value}
									name={name}
									errors={t(errors?.Data?.BusinessNature?.message)}
								/>
							)}
							name='Data.BusinessNature'
						/>
					</div>
				)}
				<Divider className='my-3' />
				<h4>{t('Reference Details')}</h4>
				<div className='mb-4'>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, value, name } }) => (
							<CustomInput
								type='text'
								placeholder={t('Reference 1 Name') + ' *'}
								onBlur={onBlur}
								onChange={onChange}
								value={value}
								name={name}
								errors={t(errors?.Data?.ReferenceName1?.message)}
							/>
						)}
						name='Data.ReferenceName1'
					/>
				</div>
				<div className='mb-4'>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, value, name } }) => (
							<CustomInput
								type='text'
								placeholder={t('Reference 1 Mobile Number') + ' *'}
								onBlur={onBlur}
								value={value}
								onChange={onChange}
								name={name}
								errors={t(errors?.Data?.ReferenceMobile1?.message)}
							/>
						)}
						name='Data.ReferenceMobile1'
					/>
				</div>
				<div className='mb-4'>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, value, name } }) => (
							<CustomInput
								type='text'
								placeholder={t('Reference 2 Name')}
								onBlur={onBlur}
								onChange={onChange}
								value={value}
								name={name}
								errors={t(errors?.Data?.ReferenceName2?.message)}
							/>
						)}
						name='Data.ReferenceName2'
					/>
				</div>
				<div className='mb-4'>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, value, name } }) => (
							<CustomInput
								type='text'
								placeholder={t('Reference 2 Mobile Number')}
								onBlur={onBlur}
								onChange={onChange}
								value={value}
								name={name}
								errors={t(errors?.Data?.ReferenceMobile2?.message)}
							/>
						)}
						name='Data.ReferenceMobile2'
					/>
				</div>
				<div
					className='position-fixed w-100 bottom-0 start-0 px-3 pb-3'
					style={{ backgroundColor: '#fff' }}
				>
					<CustomButton
						type='submit'
						className='btn-blue'
						expand='block'
						title={t('Update')}
						disabled={!isValid}
					/>
				</div>
			</form>
			{/* Upload Photo Modal */}
			<CustomModal
				id='sharemodal'
				isOpen={openUploadModal}
				initialBreakpoint={isWeb ? 1 : 0.3}
				handleClose={() => setOpenUploadModal(false)}
				breakpoints={[0, 0.3, 0.5, 0.75]}
				modalContent={<ShareModal />}
				needCross={false}
				needArrow={false}
				className={`side-modal ${isWeb ? '' : 'modal-border-radius'}`}
			/>
		</section>
	);
};
