import { IonContent, IonFooter } from '@ionic/react';
import React from 'react';
import { Header } from '../layout/header/Header';
import { CustomButton } from './custom-button/CustomButton';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface TermsAndConditions {
	headerTitle: string;
	termsPara: string;
	afterAcceptRedirect: string;
	needFooter: boolean;
	onClick: () => void;
}
export const TermsAndConditions: React.FC<TermsAndConditions> = ({
	headerTitle,
	termsPara,
	// afterAcceptRedirect,
	needFooter,
	onClick,
}) => {
	const history = useHistory();
	const { t } = useTranslation();
	const dmaSelector = useSelector((state: any) => state?.users);

	return (
		<>
			<Header
				name={headerTitle}
				backButton={true}
				backButtonText=''
				needArrow={true}
				style={{ borderBottom: '1px solid #00000029' }}
				titleClassName='fw-600 text-black'
				// needArrowOnClick={() => {
				// 	if (dmaSelector?.dmaFlow?.dmaId) {
				// 		history.push('/add-your-details');
				// 	} else {
				// 		history.push('/dashboard/home');
				// 	}
				// }}
			/>
			<IonContent
				className=''
				scrollY={false}
				scrollX={false}
			>
				<div className='bg-web py-md-3 h-100'>
					<div className='border-md-radius-10 py-md-4 container-width-md bg-web-white iframe-wrapper h-100'>
						{termsPara && <iframe src={termsPara} />}
					</div>
				</div>
			</IonContent>

			{needFooter ? (
				<IonFooter className='bg-web-white bg-white'>
					{' '}
					<div className=' d-flex align-items-center justify-content-center ion-margin'>
						<CustomButton
							expand='block'
							// routerDirection='root'
							onClick={onClick}
							// routerLink={afterAcceptRedirect}
							className={'btn-blue w-25-md'}
							title={t('Agree & Continue')}
						/>
					</div>
				</IonFooter>
			) : (
				<div className='py-1'></div>
			)}
		</>
	);
};
