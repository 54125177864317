//// @ts-nocheck


import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonModal, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { addCircleOutline } from 'ionicons/icons'; // Importing the plus icon
import React, { useEffect, useState } from 'react';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { FamilyFloaterDetails } from './family_floater';
import { Header } from '../../components/layout/header/Header';
import { REDIRECT } from '../../helpers/constant';
import { t } from 'i18next';
import { useHistory } from 'react-router';


interface Card {
    id: string;
    title: string;
    // firstName: string;
    // lastName: string;
    // phoneNumber: string;
}


interface Props {
    // policyProposedFor: string;
    finalSubmit?: () => void;
}


export const FloaterFamily: React.FC<Props> = ({ finalSubmit }) => {
    const [cards, setCards] = useState<Card[]>([]);
    // var cardId = 0;
    // const [formData, setFormData] = useState({ id: '', firstName: '', lastName: '', phoneNumber: '' });
    const [showModal, setShowModal] = useState(false);
    const [cardId, setCardId] = useState(1);
    const history = useHistory();




    const addCard = () => {
        setCardId(cardId + 1);


        const newCard: Card = {
            id: `cardId${cardId}`,
            title: 'Floater Member',
            // firstName: '',
            // lastName: '',
            // phoneNumber: ''
        };
        setCards([...cards, newCard]);
    };

    const deleteCard = (id: string) => {
        const updatedCards = cards.filter(card => card.id !== id);
        setCards(updatedCards);
    };

    const handleModalSubmit = () => {
        // const updatedCards = cards.map(card => {
        //     if (card.id === formData.id) {
        //         return { ...card, firstName: formData.firstName, lastName: formData.lastName, phoneNumber: formData.phoneNumber };
        //     }
        //     return card;
        // });
        // setCards(updatedCards);
        setShowModal(false);
    };

    const submitFloater = () => {
        if (finalSubmit) {
            finalSubmit();
        }
    };

    useEffect(addCard, []);

    return (
        <IonPage>
            <Header
                className="card-header"
                name={t(`Family Details`)}
                backButton={true}
                needArrow={true}
                needArrowOnClick={() => {
                    history.push(REDIRECT.DASHBOARD);
                }}
            />

            <IonContent>
                <IonGrid>
                    <IonRow>
                        {cards.map(card => {
                            return (
                                <IonCol size="12" key={card.id}>
                                    <IonCard onClick={() => {
                                        setShowModal(true);
                                        // setFormData({ ...card, id: card.id}) ;
                                    }}>

                                        <IonCardHeader>
                                            <IonCardTitle>{card.title}</IonCardTitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            <IonButton onClick={() => deleteCard(card.id)} color="danger">Delete</IonButton>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            )
                        })}
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <IonButton onClick={addCard} expand="block" size="large" color="primary"> {/* Adjusted size and color */}
                                <IonIcon icon={addCircleOutline} slot="icon-only" /> {/* Using the plus icon */}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div
                    className='position-fixed w-100 bottom-0 start-0 px-3 pb-3'
                    style={{ backgroundColor: '#fff' }}
                >
                    <CustomButton
                        className='btn-blue'
                        onClick={submitFloater}
                        type='submit'
                        expand='block'
                        title='Proceed'
                    />
                </div>
            </IonContent>
            <IonModal isOpen={showModal}>
                <FamilyFloaterDetails />
            </IonModal>
        </IonPage>
    );
};

