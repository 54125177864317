import { IonButton, IonContent, IonFooter, IonIcon } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory, useLocation } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	lenderDocumentUpload,
	saveLenderSpcfInfo,
	fetchMasterData,
	lendingKartRmList,
} from '../../../store/reducers/lead/leadSlice';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import {
	ContinueLaterPopUp,
	checkFieldsValidToContinue,
	disableNumberArrows,
	showToastAlert,
} from '../../../helpers/functions';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import {
	BusinessInfo,
	businessRunBy,
	productCategory,
	LendingKartInfoSchema,
	documentTypes,
} from './LendingKartInfo';
import { LenderNameForAPICall } from '../../../helpers/lenders';
import {
	CustomModalLeadStatus,
	DynamicDropdown,
} from '../../../components/common';
import './LendingKart.scss';
import { REGEX } from '../../../helpers/constant';
import CustomDropDown from '../../../components/common/custom-dropdown/CustomDropDown';
import { OfferRejectedMsg } from '../OfferRejectedMsg/OfferRejectedMsg';

export const LendingKartBusinessForm: React.FC<any> = () => {
	const documentObj = {
		document_type: '',
		document_password: '',
		file: '',
		preview: '',
		fileObj: {
			name: '',
			type: '',
		},
		message: '',
	};

	const [modalPageClose, setModalPageClose] = useState(false);
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const [isShowPreview, setToShowPreview] = useState(false);
	const [leadStatusModalOpen, setLeadStatusModalOpen] = useState(false);
	const [natureOfBusiness, setNatureOfBusiness] = useState([]);
	const [prodCategory, setProdCategory] = useState([]);
	const [applicationNo, setApplicationNo] = useState(null);
	const history = useHistory();
	const dispatch = useDispatch();
	const newLeadUploadRef: any = useRef([]);
	const { selectedLender, lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);
	const [rmList, setRmList] = useState<any>([]);
	const [rmData, setRmData] = useState<any>([]);
	const location = useLocation<any>();

	const {
		handleSubmit,
		control,
		getValues,
		watch,
		setValue,
		reset,
		trigger,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(LendingKartInfoSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			businessRunBy:
				lenderSpecificDetails?.lender_spf_details?.businessRunBy || '',
			business_revenue:
				lenderSpecificDetails?.lender_spf_details?.business_revenue || null,
			productCategory:
				lenderSpecificDetails?.lender_spf_details?.productCategory || '',
			nature_of_business:
				lenderSpecificDetails?.lender_spf_details?.nature_of_business || '',
			rm_manager: lenderSpecificDetails?.lender_spf_details?.rm_manager || '',
			cibilConsentForLK: true,
			documents: [documentObj] || [],
		},
	});

	useEffect(() => {
		if (location.pathname !== '/lead-lender-specific-form-steps') {
			return;
		}
		setProdCategory(JSON.parse(JSON.stringify(productCategory)));
		getNatureOfBusiness();

		(async () => {
			await dispatch(lendingKartRmList())
				.then(unwrapResult)
				.then((result: any) => {
					setRmList(result?.data);

					setRmData(
						result?.data.map((item: any) => {
							return { value: item?.name };
						})
					);

					console.log(result);
				});
		})();
	}, [location.pathname]);

	const getNatureOfBusiness = async () => {
		await dispatch(fetchMasterData({ param: 'LENDINGKART_NATURE_OF_BUSINESS' }))
			.then(unwrapResult)
			.then((result: any) => {
				setNatureOfBusiness(result.data);
			});
	};

	/**
	 * On close of lead status modal redirect to external URL
	 */
	const onLeadStatusModalClose = () => {
		setLeadStatusModalOpen(false);
	};

	/**
	 * Upload documents
	 * @param event file upload event
	 * @param key documents array index
	 */
	const uploadDocuments = async (event: any, key: number) => {
		const file = event.target.files[0];
		const fileTypes = ['jpg', 'jpeg', 'png', 'pdf'];
		const fType = file?.type?.split('/')?.reverse()[0];
		if (!fType || !fileTypes?.includes(fType?.toLowerCase())) {
			setToShowPreview(false);
			setValue(
				`documents.${key}.message`,
				'Uploaded file is invalid. Only JPG/JPEG, PNG and PDF files are allowed.'
			);
			return;
		}

		if (file?.size / 1024 > 2048) {
			setToShowPreview(false);
			setValue(`documents.${key}.message`, 'File size should be less than 2MB');
			return;
		}
		setValue(`documents.${key}.message`, '');
		setValue(`documents.${key}.fileObj`, file);
		const objectToUrl: any = URL.createObjectURL(file);
		setValue(`documents.${key}.preview`, objectToUrl);

		const formData: any = new FormData();
		formData.append('lender_name', LenderNameForAPICall.LENDINGKART);
		formData.append('file', file);

		await dispatch(lenderDocumentUpload({ data: formData }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setValue(`documents.${key}.file`, result.key);
					reset({
						...getValues(),
					});
				} else {
					setValue(`documents.${key}.message`, result.message);
				}
				setToShowPreview(false);
			});

		event.target.value = null;
	};

	useEffect(() => {
		setToShowPreview(true);
	}, [isShowPreview, !isShowPreview]);

	/**
	 * Show selected document preview
	 * @param key document array index
	 */
	const showFilePreview = (key: number) => {
		let previewItem: any;
		const fileObj: any = getValues(`documents.${key}.fileObj`);

		if (fileObj?.type?.includes('image')) {
			previewItem = (
				<>
					<img
						src={getValues(`documents.${key}.preview`)}
						alt='preview'
						className='me-2 file_preview'
						width={'100%'}
					/>
					<p className='filename'>{fileObj.name}</p>
				</>
			);
		} else if (fileObj?.type?.includes('pdf')) {
			previewItem = (
				<>
					<img
						src='/images/pdf.svg'
						alt='preview'
						className='me-2 file_preview'
						width={80}
					/>
					<p className='filename'>{fileObj.name}</p>
				</>
			);
		} else if (getValues(`documents.${key}.message`)) {
			previewItem = (
				<p className='text-red'>{t(getValues(`documents.${key}.message`))}</p>
			);
		}
		return previewItem;
	};

	/**
	 * Add new section to upload document
	 */
	const addNewDocument = () => {
		setValue('documents', [...getValues('documents'), documentObj]);
		reset({
			...getValues(),
		});
	};

	/**
	 * Remove specific item
	 * @param key index
	 */
	const removeDocument = (key: number) => {
		const documents = getValues('documents').filter((ele, k) => k !== key);
		setValue('documents', documents);
		reset({
			...getValues(),
		});
	};

	/**
	 * On submit to save user data and after that
	 * @param data Form data
	 */
	const onSubmit = async (data: any) => {
		const documentRequest: any = [];
		getValues('documents')?.forEach((el: any) => {
			documentRequest.push({
				document_type: el.document_type,
				document_password: el.document_password,
				file: el.file,
			});
		});

		const req: any = {
			lender_spf_details: {
				...data,
				referenceCode: rmList.find(
					(item: any) => item?.name === watch('rm_manager')
				)?.referenceCode,
				documents: documentRequest,
			},
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.LENDINGKART,
			step: 'step1',
			isNewLead: isValid,
		};

		if (lenderSpecificDetails?.lead_spf_detail_id) {
			req.lead_spf_detail_id = lenderSpecificDetails?.lead_spf_detail_id;
		}

		setShowOfferErrMsg('');
		await dispatch(saveLenderSpcfInfo({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					if (req.isNewLead) {
						setApplicationNo(result?.data?.andro_id);
						setLeadStatusModalOpen(true);
					}
				} else {
					if (result?.code === 'FAILED') {
						setShowOfferErrMsg(result?.message + '. Please try again later!');
					}
				}
			});
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
							<form onSubmit={handleSubmit(onSubmit)}>
								{BusinessInfo.map(
									({ name, type, placeholder }: any, index: any) => {
										const errorText: any =
											errors && Object.keys(errors).length > 0 && errors;
										return (
											<div key={index}>
												{type === 'dropdown' ? (
													<div style={{ marginBottom: '15px' }}>
														<Controller
															control={control}
															render={({
																field: { onChange, onBlur, name },
															}) => (
																<DynamicDropdown
																	label={t(`${placeholder}`)}
																	data={
																		name === 'businessRunBy'
																			? businessRunBy
																			: name === 'nature_of_business'
																			? natureOfBusiness
																			: name === 'rm_manager'
																			? rmData
																			: []
																	}
																	name={name}
																	onChange={(e: any) => {
																		onChange(e);
																	}}
																	bindField={'value'}
																	showField1={'value'}
																	value={getValues(name)}
																	onBlur={onBlur}
																	errors={errorText[name]?.message}
																/>
															)}
															name={name}
														/>
														{name === 'rm_manager' &&
															watch('rm_manager').length > 0 && (
																<>
																	<div className='d-flex'>
																		<span className='fs-14 m-2 primary-text-color sc-ion-input-md'>
																			Email:{' '}
																			{
																				rmList.find(
																					(item: any) =>
																						item?.name === watch('rm_manager')
																				)?.emailId
																			}
																		</span>
																	</div>
																	<div className='d-flex'>
																		<span className='fs-14 m-2 primary-text-color sc-ion-input-md'>
																			Reference Code:{' '}
																			{
																				rmList.find(
																					(item: any) =>
																						item?.name === watch('rm_manager')
																				)?.referenceCode
																			}
																		</span>
																		<span className='fs-14 m-2 primary-text-color sc-ion-input-md'>
																			Contact No:{' '}
																			{
																				rmList.find(
																					(item: any) =>
																						item?.name === watch('rm_manager')
																				)?.contactNumber
																			}
																		</span>
																	</div>
																</>
															)}
													</div>
												) : type === 'dropdownmodal' ? (
													<div className='mb-3'>
														<CustomDropDown
															{...{
																className: `${
																	errors?.productCategory && 'dropdown-error'
																}`,
																isCheckbox: false,
																title: t('Product Category *'),
																setData: (data: any) => {
																	setProdCategory(data);
																	const val = data.filter(
																		(item: any) => item.checked
																	);
																	console.log(val);
																	setValue('productCategory', val[0]?.value);
																	trigger('productCategory');
																},
																data: prodCategory,
																placeholder: t(
																	'Search product category by “Name”'
																),
																isSearch: true,
																listTitle: t('Product Category'),
																modalTitle: t('Product Category'),
															}}
														/>
													</div>
												) : (
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<CustomInput
																type={type}
																label={t(placeholder)}
																onBlur={onBlur}
																id={name}
																onChange={(e: any) => {
																	onChange(e);
																}}
																value={getValues(name)}
																onBeforeInput={(evt: any) => {
																	if (type == 'number') {
																		!REGEX.ALLOWNUMBERS.test(evt.data) &&
																			evt.preventDefault();
																	}
																}}
																onKeyDown={(e: any) => {
																	if (type == 'number') {
																		disableNumberArrows(e);
																	}
																}}
																onWheel={(e: any) => {
																	if (type == 'number') {
																		e.target.blur();
																	}
																}}
																errors={t(errorText[name]?.message || '')}
																maxLength={15}
															/>
														)}
														name={name}
													/>
												)}
											</div>
										);
									}
								)}

								<>
									{getValues('documents').map((el: any, k: any) => {
										return (
											<div key={k}>
												<Controller
													control={control}
													render={({ field: { onChange, onBlur, name } }) => (
														<DynamicDropdown
															label={t(`Document Type *`)}
															data={documentTypes}
															name={name}
															onChange={(e: any) => {
																onChange(e);
															}}
															bindField={'value'}
															showField1={'title'}
															value={getValues(name)}
															onBlur={onBlur}
															errors={
																errors?.documents &&
																errors?.documents[k]?.document_type?.message
															}
														/>
													)}
													name={`documents.${k}.document_type`}
												/>

												<Controller
													control={control}
													render={({ field: { onChange, onBlur, name } }) => (
														<CustomInput
															type={'text'}
															label={t('Document Password')}
															onBlur={onBlur}
															id={name}
															onChange={(e: any) => {
																onChange(e);
															}}
															value={getValues(name)}
														/>
													)}
													name={`documents.${k}.document_password`}
												/>

												<div className='mb-4'>
													<CustomButton
														onClick={() =>
															newLeadUploadRef?.current[k]?.click()
														}
														expand='block'
														fill={'solid'}
													>
														<img
															src='/images/icons/upload-white.svg'
															alt='upload'
															className='me-2'
														/>
														{t('Select File')}
													</CustomButton>
													<span className='text-muted font-small'>
														Acceptable File Format : png, jpeg, jpg, pdf Maximum
														file size limit : 2MB
													</span>
													{/* input file upoad on button click */}
													<input
														type='file'
														style={{ display: 'none' }}
														accept='image/jpg,image/jpeg,image/png,application/pdf'
														ref={(el) => (newLeadUploadRef.current[k] = el)}
														id={k}
														onChange={(event) => uploadDocuments(event, k)}
													/>

													{isShowPreview && (
														<div className='mt-2 mb-2'>
															{showFilePreview(k)}
														</div>
													)}
												</div>

												<div className='add_rm_buttons'>
													{k === getValues('documents').length - 1 && (
														<IonButton
															type='button'
															shape='round'
															color='primary'
															onClick={() => addNewDocument()}
														>
															<IonIcon src='/images/icons/add-icon.svg' />
														</IonButton>
													)}

													{getValues('documents').length > 1 && (
														<IonButton
															shape='round'
															type='button'
															color='danger'
															onClick={() => removeDocument(k)}
														>
															<IonIcon src='/images/icons/delete.svg' />
														</IonButton>
													)}
												</div>
											</div>
										);
									})}
								</>
							</form>

							<div className={` d-none d-md-block`}>
								<CustomButton
									className='w-100 fw-bold dashboard-add-lead-button mb-3'
									expand='block'
									fill='outline'
									title={t('Continue Later')}
									onClick={() => {
										if (checkFieldsValidToContinue(errors)) {
											setModalPageClose(true);
										} else {
											showToastAlert({
												type: 'error',
												message: 'Please fix above errors',
											});
										}
									}}
								/>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				)}
				<CustomModal
					needIonContentWraper={false}
					isOpen={modalPageClose}
					initialBreakpoint={undefined}
					handleClose={() => setModalPageClose(false)}
					breakpoints={[]}
					className='height-auto confirm-popup-middle continue-later'
					modalContent={ContinueLaterPopUp(
						() => {
							setModalPageClose(false);
						},
						() => {
							new Promise(function (myResolve) {
								setTimeout(function () {
									myResolve(history.push('/dashboard/lead'));
									// onSubmit(getValues());
									setModalPageClose(false);
								}, 10);
							});
						},
						'Cancel',
						'Continue Later',
						'className',
						'Are You Sure You Want to Exit?',
						`${t('You can continue later from where you left off.')}`
					)}
					needCross={false}
					needArrow={false}
				/>
			</IonContent>

			{leadStatusModalOpen && (
				<CustomModalLeadStatus
					isOpen={leadStatusModalOpen}
					applicationNo={applicationNo}
					handleClose={() => onLeadStatusModalClose()}
				/>
			)}

			{!showOfferErrMsg && (
				<IonFooter className='d-block d-md-none position-relative'>
					<div
						style={{
							backgroundColor: '#fff',
							zIndex: 2,
						}}
					>
						<div className={`ion-padding`}>
							<CustomButton
								className='w-100 fw-bold dashboard-add-lead-button mb-3'
								expand='block'
								fill='outline'
								title={t('Continue Later')}
								onClick={() => {
									if (checkFieldsValidToContinue(errors)) {
										setModalPageClose(true);
									} else {
										showToastAlert({
											type: 'error',
											message: 'Please fix above errors',
										});
									}
								}}
							/>
							<div className='proceed-btn'>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				</IonFooter>
			)}
		</>
	);
};
