//// @ts-nocheck




import React, { useContext, useEffect, useState } from 'react';
import { IonTabs, IonTabBar, IonTabButton, IonLabel, IonRouterOutlet } from '@ionic/react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { IonReactRouter } from '@ionic/react-router';
import { ApplicationBean, StatusUpdateRequest, PolicyRequest } from '../../../model/insurance_bean';
import ApplicationContext from '../../../context/ApplicationContext';
import { INSURANCE_POLICY_PROPOSED_FOR, INSURANCE_STATUS, INSURANCE_TYPE, REDIRECT } from '../../../helpers/constant';
import DocumentDetails from '../document_details';
import useApi from '../../../api/useApi';
import { useBusyLoading } from '../../../context/BusyLoadingContext';
import { UPDATE_POLICY_STATUS, UPDATE_STATUS } from '../../../helpers/api-endpoints';
import { showToastAlert } from '../../../helpers/functions';
import { DocumentUploadForm } from '../../../model/document_bean';
// import { useNavigate } from 'react-router-dom';




interface Props {
  appBean?: ApplicationBean,
  // policyProposedFor: string;
  finalSubmit?: () => void;
  readonly?: boolean;

}





export const DocumentUploadMain: React.FC<Props> = ({ appBean, finalSubmit, readonly = false }) => {
  // export const IfaInsuranceForm: React.FC<Props> = () => {
  const history = useHistory();
  // const navigate = useNavigate();
  const { applicationBean } = useContext(ApplicationContext);

  // const [localAppBean, setLocalAppBean] = useState<ApplicationBean|undefined>(appBean);

  const [showAssured, setShowAssured] = useState<boolean>((!(appBean?.policyProposedFor !== INSURANCE_POLICY_PROPOSED_FOR.SELF)) || false);

  const [applicationDocumentsFormProposer, setApplicationDocumentsFormProposer] = useState<DocumentUploadForm[]>([]);
  const [applicationDocumentsFormAssured, setApplicationDocumentsFormAssured] = useState<DocumentUploadForm[]>([]);

  const [applicationId, setApplicationId] = useState<number>();

  const [proposerProfileId, setProposerProfileId] = useState<number>();
  const [assuredProfileId, setAssuredProfileId] = useState<number>();


  const [refreshKeyProposer, setRefreshKeyProposer] = useState(0); // new state to trigger re-renders
  const [refreshKeyAssured, setRefreshKeyAssured] = useState(0); // new state to trigger re-renders



  // const [showAssured, setShowAssured] = useState<boolean>(false);
  // const [showIndividual, setShowIndividual] = useState<boolean>(!(showAssured||showFamilyFloater));

  const { responseData, loading, executeRequest, error } =
    useApi<any>(`${UPDATE_STATUS}`);

  const polStatus =
    useApi<any>(`${UPDATE_POLICY_STATUS}`);

  const { setBusyState } = useBusyLoading();


  // useEffect(() => {
  //   const preventDefault = (e) => {
  //     e.preventDefault();
  //     e.stopPropagation();
  //   };

  //   const preventKeyRefresh = (e) => {
  //     if ((e.ctrlKey && e.key === 'r') || e.key === 'F5' || (e.metaKey && e.key === 'r')) {
  //       preventDefault(e);
  //     }
  //   };

  //   window.addEventListener('beforeunload', preventDefault);
  //   window.addEventListener('keydown', preventKeyRefresh);

  //   return () => {
  //     window.removeEventListener('beforeunload', preventDefault);
  //     window.removeEventListener('keydown', preventKeyRefresh);
  //   };
  // }, []);

  // useEffect(() => {
  //   return () => {
  //     window.removeEventListener('beforeunload', (e) => e.preventDefault());
  //     window.removeEventListener('keydown', (e) => {
  //       if ((e.ctrlKey && e.key === 'r') || e.key === 'F5' || (e.metaKey && e.key === 'r')) {
  //         e.preventDefault();
  //       }
  //     });
  //   };
  // }, []);



  useEffect(() => {
    setBusyState(loading);
  }, [loading]);

  useEffect(() => {
    showToastAlert({ type: "error", message: error?.message! });
  }, [error]);



  useEffect(() => {
    setBusyState(polStatus.loading);
  }, [polStatus.loading]);

  useEffect(() => {
    showToastAlert({ type: "error", message: polStatus.error?.message! });
  }, [polStatus.error]);







  useEffect(() => {
    if (!appBean) {
      appBean = applicationBean!;
    }

    // setLocalAppBean(appBean);
    const stubShowAssured: boolean = appBean?.policyProposedFor !== INSURANCE_POLICY_PROPOSED_FOR.SELF;
    console.log("stubShowAssured", stubShowAssured);

    setApplicationId(appBean.applicationId);
    setProposerProfileId(appBean.proposerProfileId);

    setApplicationDocumentsFormProposer(appBean.applicationDocumentsFormProposer ?? []);


    // if (applicationBean?.applicationDocumentsProposer) {
    //   let stubApplicationDocumentsFormProposer: DocumentUploadForm[] = applicationBean.applicationDocumentsProposer.map(request => new DocumentUploadForm(request));
    //   setApplicationDocumentsFormProposer(stubApplicationDocumentsFormProposer);
    // }
    // if (applicationBean?.applicationDocumentsAssured) {
    //   let stubApplicationDocumentsFormAssured: DocumentUploadForm[] = applicationBean.applicationDocumentsAssured.map(request => new DocumentUploadForm(request));
    //   setApplicationDocumentsFormAssured(stubApplicationDocumentsFormAssured);
    // }




    if (stubShowAssured) {
      setAssuredProfileId(appBean.assuredProfileId);
      setShowAssured(stubShowAssured);
      setApplicationDocumentsFormAssured(appBean.applicationDocumentsFormAssured ?? []);
      setRefreshKeyAssured(k => k + 1);
    }

    console.log("appBean hbs set", appBean);
    setRefreshKeyProposer(k => k + 1);

  }, [appBean, applicationBean, showAssured, applicationDocumentsFormProposer]);





  const [currentTab, setCurrentTab] = useState<number>(1);

  const handleTabChange = (tabIndex: number) => {
    setCurrentTab(tabIndex);
  };

  const moveToNextTab = () => {
    setCurrentTab((prevTab) => prevTab + 1);
  };

  const finalSubmitDef = () => {

    let statusUpdateRequest = new StatusUpdateRequest();
    statusUpdateRequest.applicationId = applicationBean?.applicationId;
    statusUpdateRequest.statusId = INSURANCE_STATUS.ALLOCATED_TO_DMA;
    const payload = statusUpdateRequest;



    executeRequest("put", payload);
  };





  useEffect(() => {
    if (responseData?.status) {
      // if (applicationBean) {
      //   applicationBean.applicationPolicies = responseData?.data;
      //   applicationBean.statusId = INSURANCE_STATUS.AUTHORIZATION_PENDING;

      //   setAppBean(applicationBean);
      //   let statusUpdateRequest = new StatusUpdateRequest();
      //   statusUpdateRequest.applicationId = applicationBean.applicationId;
      //   statusUpdateRequest.statusId = INSURANCE_STATUS.AUTHORIZATION_PENDING;

      //   statusUpdateAPI.executeRequest("put", statusUpdateRequest);
      // }


      let polPayload: PolicyRequest[] = [];
      applicationBean?.applicationPolicies?.map(
        (item) => {
          item.statusId = INSURANCE_STATUS.ALLOCATED_TO_DMA;
        }
      );
      polPayload = applicationBean?.applicationPolicies ?? [];

      polStatus.executeRequest('put', polPayload).then(() => {

      });
      // history.push('/insurancelenders');
    }

  }, [responseData]);


  useEffect(() => {
    if (polStatus.responseData?.status) {
      history.push('/cust-ins-message', {
        redirectTo: REDIRECT.DASHBOARD,
        message: `Insurer Selected and Allocated to DMA-Coordinator. Application Id is ${applicationBean?.applicationId}`
      });
    }
  }, [polStatus.responseData]);




  const tabDocMainUrl: string = '/docuploadMain/tabs';



  if (showAssured) {
    return (
      <IonReactRouter>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-8">
              <IonTabs>
                <IonRouterOutlet onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <Route path={`${tabDocMainUrl}/proposer`} render={() => <DocumentDetails
                    key={refreshKeyProposer}
                    readonly={false}
                    applicationId={applicationId}
                    documentUploadFormList={applicationDocumentsFormProposer}
                    profileId={proposerProfileId}
                    inpCallback={moveToNextTab}
                    nextTabUrl={`${tabDocMainUrl}/assured`} />} exact={true} />

                  <Route path={`${tabDocMainUrl}/assured`} render={() => <DocumentDetails
                    key={refreshKeyAssured}
                    readonly={readonly}
                    applicationId={applicationId}
                    documentUploadFormList={applicationDocumentsFormAssured}
                    profileId={assuredProfileId} 
                    inpCallback={finalSubmit ?? finalSubmitDef}/>}
                    exact={true} 
                    />

                  <Redirect exact path={'/docuploadMain'} to={`${tabDocMainUrl}/proposer`} />
                  {/* <InsuranceRouting moveToNextTab={moveToNextTab}  showDocuments={true}/> */}
                </IonRouterOutlet>
                <IonTabBar style={{ justifyContent: 'flex-center', display: 'flex', overflowX: 'auto', whiteSpace: 'nowrap', paddingLeft: '10px', paddingRight: '10px', flexWrap: 'nowrap', }} slot="top">

                  {/* <IonTabBar style={{ overflowX: 'scroll'}} slot="bottom"> */}
                  <IonTabButton className='custom-ion-tab-button' tab="proposer" href={`${tabDocMainUrl}/proposer`} onClick={() => handleTabChange(1)} selected={currentTab === 1}>
                    <IonLabel>Proposer Documents</IonLabel>
                  </IonTabButton>

                  <IonTabButton className='custom-ion-tab-button' tab="assured" href={`${tabDocMainUrl}/assured`} onClick={() => handleTabChange(2)} selected={currentTab === 2}>
                    <IonLabel>Assured Documents</IonLabel>
                  </IonTabButton>
                </IonTabBar>
              </IonTabs>
            </div>
          </div>
        </div>

      </IonReactRouter>
    );
  }
  // else{
  return (
    <DocumentDetails
      key={refreshKeyProposer}
      readonly={readonly}
      applicationId={applicationId}
      documentUploadFormList={applicationDocumentsFormProposer}
      profileId={proposerProfileId}
      inpCallback={finalSubmit ?? finalSubmitDef}
    />
  );
  // }





};

// export default IfaInsuranceForm;
