export const AddDocument = ({ title }: { title: string }) => {
	return (
		<div>
			<img
				src='/images/icons/kyc/upload-icon.svg'
				width={16}
				height={16}
			/>
			<span
				className='fs-14 fw-600 ms-2'
				style={{
					color: 'var(--ion-color-primary)',
				}}
			>
				{title}
			</span>
		</div>
	);
};
