import * as Yup from 'yup';
import { REGEX } from '../../../../helpers/constant';

export const applicantFormOneSchema = Yup.object({
	customer_type: Yup.string().required('Customer Type is required'),
	marital_status: Yup.string().required('Marital Status is required'),
	religion: Yup.string().required('Religion is required'),
	category: Yup.string().required('Category is required'),
	education: Yup.string().required('Education is required'),
	nature_of_business: Yup.string().required('Nature of Business is required'),
	employment_industry: Yup.string().required('Industry is required'),
	accommodation_type: Yup.string().required('Accommodation Type is required'),
	corresponding_financial_year: Yup.string().required(
		'Corresponding Financial Year is required'
	),
	// constitution_personal: Yup.string().required('Personal Constitution is required'),
	// company_constitution_code: Yup.string().required('Company Constitution is required'),
	constitution_code: Yup.string().required('Constitution is required'),
	occupation_constitution_code: Yup.string().required(
		'Occupation Constitution is required'
	),
	mother_first_name: Yup.string()
		.trim()
		.max(100, "Mother's First Name should not be more than 100")
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input')
		.required("Mother's First Name is Required"),
	mother_last_name: Yup.string()
		.trim()
		.max(100, "Mother's Last Name should not be more than 100")
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input')
		.required("Mother's Last Name is Required"),
});

export const applicantFormTwoSchema = Yup.object({
	net_monthly_income: Yup.string()
		.required('Net Monthly Income is a required field')
		.matches(REGEX.ALLOWNUMBERS, 'Net Monthly Income should be number')
		.matches(
			REGEX.GREATER_THAN_ZERO,
			'Net Monthly Income should be greater than zero'
		),
	ref_first_name_1: Yup.string()
		.trim()
		.max(100, 'Reference First Name should not be more than 100')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input')
		.required('Reference First Name is Required'),
	ref_last_name_1: Yup.string()
		.trim()
		.max(100, 'Reference Last Name should not be more than 100')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input')
		.required('Reference Last Name is Required'),
	ref_mobile_no_1: Yup.string()
		.max(10, 'Mobile No. Should Be 10 Digits.')
		.required('Mobile is Required.')
		.matches(/^\d+$/, 'Mobile No. Should Contain Only Digits.')
		.matches(REGEX.INDIAN_MOBILE_NUMBERS, 'Please Enter Valid Number.'),
	ref_first_name_2: Yup.string()
		.trim()
		.max(100, 'Reference First Name should not be more than 100')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input')
		.required('Reference First Name is Required'),
	ref_last_name_2: Yup.string()
		.trim()
		.max(100, 'Reference Last Name should not be more than 100')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input')
		.required('Reference Last Name is Required'),
	ref_mobile_no_2: Yup.string()
		.max(10, 'Mobile No. Should Be 10 Digits.')
		.required('Mobile is Required.')
		.matches(/^\d+$/, 'Mobile No. Should Contain Only Digits.')
		.matches(REGEX.INDIAN_MOBILE_NUMBERS, 'Please Enter Valid Number.'),
	req_tenure: Yup.string()
		.required('Requested Tenure is required')
		.min(1, 'Requested Tenure minimum be 1 digit')
		.max(3, 'Requested Tenure maximum be 3 digits')
		.matches(REGEX.ALLOWNUMBERS, 'Requested Tenure should be number'),
	req_irr: Yup.string()
		.trim()
		.matches(REGEX.ROI_IRR, 'Please Enter Valid Requested IRR')
		.max(6, 'Requested IRR maximum be 2 Digits')
		.required('Requested IRR is Required'),
	req_roi: Yup.string()
		.trim()
		.matches(REGEX.ROI_IRR, 'Please Enter Valid Requested ROI')
		.max(6, 'Requested ROI maximum be 2 Digits')
		.required('Requested ROI is Required'),
	preferred_address: Yup.string().required('Preferred Address is required'),
	is_co_applicant: Yup.string().required('Co-Applicant is required'),
});

export const applicantFormOne = [
	{
		name: 'customer_type',
		type: 'dropdown',
		placeholder: 'Customer Type *',
	},
	{
		name: 'marital_status',
		type: 'dropdown',
		placeholder: 'Marital Status *',
	},
	{
		name: 'religion',
		type: 'dropdown',
		placeholder: 'Religion *',
	},
	{
		name: 'category',
		type: 'dropdown',
		placeholder: 'Category *',
	},
	{
		name: 'education',
		type: 'dropdown',
		placeholder: 'Education *',
	},
	{
		name: 'nature_of_business',
		type: 'dropdown',
		placeholder: 'Nature of Business *',
	},
	{
		name: 'employment_industry',
		type: 'dropdown',
		placeholder: 'Industry *',
	},
	{
		name: 'accommodation_type',
		type: 'dropdown',
		placeholder: 'Accommodation Type *',
	},
	{
		name: 'corresponding_financial_year',
		type: 'dropdown',
		placeholder: 'Corresponding Financial Year *',
	},
	{
		name: 'constitution_code',
		type: 'dropdown',
		placeholder: 'Constitution *',
	},
	// {
	//     name: 'constitution_personal',
	//     type: 'dropdown',
	//     placeholder: 'Personal Constitution *',
	// },
	// {
	//     name: 'company_constitution_code',
	//     type: 'dropdown',
	//     placeholder: 'Company Constitution *',
	// },
	{
		name: 'occupation_constitution_code',
		type: 'dropdown',
		placeholder: 'Occupation Constitution *',
	},
	{
		name: 'mother_first_name',
		type: 'text',
		placeholder: "Mother's First Name *",
	},
	{
		name: 'mother_last_name',
		type: 'text',
		placeholder: "Mother's Last Name *",
	},
];

export const applicantFormTwo = [
	{
		name: 'net_monthly_income',
		type: 'text',
		placeholder: 'Net Monthly Income *',
	},
	{
		name: 'ref_first_name_1',
		type: 'text',
		placeholder: 'First Name of reference one *',
	},
	{
		name: 'ref_last_name_1',
		type: 'text',
		placeholder: 'Last Name of reference one *',
	},
	{
		name: 'ref_mobile_no_1',
		type: 'text',
		placeholder: 'Mobile No of reference one *',
	},
	{
		name: 'ref_first_name_2',
		type: 'text',
		placeholder: 'First Name of reference two *',
	},
	{
		name: 'ref_last_name_2',
		type: 'text',
		placeholder: 'Last Name of reference two *',
	},
	{
		name: 'ref_mobile_no_2',
		type: 'text',
		placeholder: 'Mobile No of reference two *',
	},
	{
		name: 'req_tenure',
		type: 'text',
		placeholder: 'Requested Tenure (In Months)*',
	},
	{
		name: 'req_irr',
		type: 'text',
		placeholder: 'Requested Loan IRR *',
	},
	{
		name: 'req_roi',
		type: 'text',
		placeholder: 'Requested Loan ROI *',
	},
	{
		name: 'preferred_address',
		type: 'radio',
		placeholder: 'What is your Preferred Address? *',
	},
	{
		name: 'is_co_applicant',
		type: 'dropdown',
		placeholder: 'Want to Add Co-Applicant? *',
	},
];

export const coApplicantFormOneSchema = Yup.object({
	salutation: Yup.string().required('Salutation is required'),
	mobile_no: Yup.string()
		.max(10, 'Mobile No. Should Be 10 Digits.')
		.required('Mobile is Required.')
		.matches(/^\d+$/, 'Mobile No. Should Contain Only Digits.')
		.matches(REGEX.INDIAN_MOBILE_NUMBERS, 'Please Enter Valid Number.'),
	first_name: Yup.string()
		.trim()
		.max(100, 'First Name should not be more than 100')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input')
		.required('First Name is Required'),
	middle_name: Yup.string()
		.trim()
		.max(100, 'Middle should not be more than 100')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input')
		.notRequired(),
	last_name: Yup.string()
		.trim()
		.max(100, 'Last Name should not be more than 100')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input')
		.required('Last Name is Required'),
	dob: Yup.date()
		.required('DOB is Required.')
		.typeError('Please select valid date')
		.max(new Date(Date.now()), 'Date cannot be in the future')
		.test('dob', 'Age must be 18 or older', function (birthdate) {
			const cutoff = new Date();
			cutoff.setFullYear(cutoff.getFullYear() - 18);
			return birthdate <= cutoff;
		}),
	gender: Yup.string().required('Gender is Required'),
	pan_no: Yup.string()
		.required('PAN No. is Required.')
		.trim()
		.matches(
			REGEX.PAN,
			"PAN no. should be in alphanumeric format 'ABCDE1234A' only"
		),
	email: Yup.string()
		.trim()
		.matches(REGEX.EMAIL, 'Please Enter Valid Email')
		.required('Email is Required'),
	marital_status: Yup.string().required('Marital Status is required'),
	religion: Yup.string().required('Religion is required'),
	category: Yup.string().required('Category is required'),
	customer_occupation: Yup.string().required('Occupation is required'),
	net_monthly_income: Yup.string()
		.required('Net Monthly Income is a required field')
		.matches(REGEX.ALLOWNUMBERS, 'Net Monthly Income should be number')
		.matches(
			REGEX.GREATER_THAN_ZERO,
			'Net Monthly Income should be greater than zero'
		),
	share_holding: Yup.string()
		.trim()
		.matches(REGEX.ROI_IRR, 'Please Enter Valid Share Holding')
		.max(6, 'Share Holding maximum be 2 Digits')
		.required('Share Holding is Required'),
});

export const coApplicantFormTwoSchema = Yup.object({
	customer_type: Yup.string().required('Customer Type is required'),
	party_relation: Yup.string().required('Party Relationship is required'),
	// date_of_incorporation: Yup.date()
	// 	.typeError('Please select valid date')
	// 	.required('Date of Incorporation is Required'),
	date_of_incorporation: Yup.date()
		.typeError('Please select valid date')
		.when('customer_type', {
			is: (val: any) => val?.includes('Corporate'),
			then: (schema) =>
				schema
					.required('Date of Incorporation is Required')
					.max(new Date(Date.now()), 'Date cannot be in the future'),
			otherwise: () => Yup.date(),
		}),
	company_name: Yup.string().required('Company Name is required'),
	constitution_code: Yup.string().required('Constitution is required'),
	// constitution_personal: Yup.string().required('Personal Constitution is required'),
	curr_add_1: Yup.string().trim().required('Address Line 1 is Required'),
	curr_add_2: Yup.string().trim().required('Address Line 2 is Required'),
	curr_pincode: Yup.string()
		.trim()
		.required('Pin Code Number is Required')
		.matches(/^\d{6}$/, 'Pincode should be 6 Digits')
		.length(6, 'Pincode should be 6 Digits'),
	per_add_1: Yup.string().trim().required('Address Line 1 is Required'),
	per_add_2: Yup.string().trim().required('Address Line 2 is Required'),
	per_pincode: Yup.string()
		.trim()
		.required('Pin Code Number is Required')
		.matches(/^\d{6}$/, 'Pincode should be 6 Digits')
		.length(6, 'Pincode should be 6 Digits'),
	off_add_1: Yup.string().trim().required('Address Line 1 is Required'),
	off_add_2: Yup.string().trim().required('Address Line 2 is Required'),
	off_pincode: Yup.string()
		.trim()
		.required('Pin Code Number is Required')
		.matches(/^\d{6}$/, 'Pincode should be 6 Digits')
		.length(6, 'Pincode should be 6 Digits'),
	preferred_address: Yup.string().required('Preferred Address is required'),
});

export const coApplicantFormOne = [
	{
		name: 'salutation',
		type: 'radio',
		placeholder: 'Salutation *',
	},
	{
		name: 'mobile_no',
		type: 'text',
		placeholder: 'Mobile Number *',
	},
	{
		name: 'first_name',
		type: 'text',
		placeholder: 'First Name *',
	},
	{
		name: 'middle_name',
		type: 'text',
		placeholder: 'Middle Name',
	},
	{
		name: 'last_name',
		type: 'text',
		placeholder: 'Last Name *',
	},
	{
		name: 'dob',
		type: 'date',
		placeholder: 'Date Of Birth *',
	},
	{
		name: 'email',
		type: 'text',
		placeholder: 'Email *',
	},
	{
		name: 'pan_no',
		type: 'text',
		placeholder: 'PAN *',
	},
	{
		name: 'gender',
		type: 'dropdown',
		placeholder: 'Gender *',
	},
	{
		name: 'religion',
		type: 'dropdown',
		placeholder: 'Religion *',
	},
	{
		name: 'category',
		type: 'dropdown',
		placeholder: 'Category *',
	},
	{
		name: 'marital_status',
		type: 'dropdown',
		placeholder: 'Marital Status *',
	},
	{
		name: 'customer_occupation',
		type: 'dropdown',
		placeholder: 'Occupation *',
	},
	{
		name: 'net_monthly_income',
		type: 'text',
		placeholder: 'Net Monthly Income *',
	},
	{
		name: 'share_holding',
		type: 'text',
		placeholder: 'Share Holding *',
	},
];

export const coApplicantFormTwo = [
	{
		name: 'customer_type',
		type: 'dropdown',
		placeholder: 'Customer Type *',
	},
	{
		name: 'party_relation',
		type: 'dropdown',
		placeholder: 'Party Relation *',
	},
	{
		name: 'date_of_incorporation',
		type: 'date',
		placeholder: 'Date Of Incorporation *',
	},
	{
		name: 'company_name',
		type: 'dynamicselect',
		placeholder: 'Company Name *',
	},
	{
		name: 'constitution_code',
		type: 'dropdown',
		placeholder: 'Constitution *',
	},
	// {
	//     name: 'constitution_personal',
	//     type: 'dropdown',
	//     placeholder: 'Personal Constitution *',
	// },
	{
		name: 'curr_add_1',
		type: 'text',
		placeholder: 'Current Address 1 *',
	},
	{
		name: 'curr_add_2',
		type: 'text',
		placeholder: 'Current Address 2 *',
	},
	{
		name: 'curr_pincode',
		type: 'text',
		placeholder: 'Current Pincode *',
	},
	{
		name: 'curr_per_add_same',
		type: 'checkbox',
		placeholder: 'Permanent Address is same as Current Address',
	},
	{
		name: 'per_add_1',
		type: 'text',
		placeholder: 'Permanent Address 1 *',
	},
	{
		name: 'per_add_2',
		type: 'text',
		placeholder: 'Permanent Address 2 *',
	},
	{
		name: 'per_pincode',
		type: 'text',
		placeholder: 'Permanent Pincode *',
	},
	{
		name: 'off_add_1',
		type: 'text',
		placeholder: 'Office Address 1 *',
	},
	{
		name: 'off_add_2',
		type: 'text',
		placeholder: 'Office Address 2 *',
	},
	{
		name: 'off_pincode',
		type: 'text',
		placeholder: 'Office Pincode *',
	},
	{
		name: 'preferred_address',
		type: 'radio',
		placeholder: 'What is your Preferred Address? *',
	},
];

export const applicantUploadDocSchema = Yup.object({
	documents: Yup.array().of(
		Yup.object({
			docUploadType: Yup.string().required('Document Type is required'),
			file: Yup.string().required('Document is required'),
		})
	),
});

export const salutationArr = [
	{ label: 'Mr.', value: 'Mr', name: 'Mr' },
	{ label: 'Ms.', value: 'Ms', name: 'Ms' },
	{ label: 'Mrs.', value: 'Mrs', name: 'Mrs' },
	{ label: 'Dr.', value: 'Dr', name: 'Dr' },
];
