/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { IonContent, IonItem, IonList, IonPage } from '@ionic/react';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { Header } from '../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getdmaAbout } from '../../store/reducers/user/userSlice';
import { useHistory, useLocation } from 'react-router';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	FormatDate,
	InputFormatCurrencyValue,
} from '../../helpers/functions';

export const DmaAbout: React.FC = () => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const history = useHistory();
	const location: any = useLocation();
	const dmaSelector = useSelector((state: any) => state?.users);
	const dispatch: any = useDispatch();
	const { t } = useTranslation();
	const [userAbout, setUserAbout] = useState<any>();

	useEffect(() => {
		if (location.pathname !== '/dma-about') {
			return;
		}
		dispatch(getdmaAbout({ param: dmaSelector?.dmaFlow?.dmaId }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setUserAbout(result?.data);
				}
			});
	}, [location?.pathname]);

	return (
		<IonPage className='ion-scroll'>
			<Header
				type=''
				name={t('About')}
				needArrow={true}
				backButton={true}
				needArrowOnClick={() => {
					history.push('/dma-details', {
						update: location?.state?.updateApi + 1 || 1,
					});
				}}
				style={{ borderBottom: '1px solid #00000029' }}
			/>

			<IonContent className='ion-padding background-web'>
				<div className='bg-web'>
					<div className='bg-web-white container-width-md px-md-3 border-md-radius-10'>
						<IonList>
							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 justify-content-between'>
									<p className='mb-0 fs-14 darkest-grey'>{t('Mobile')}</p>
									<p className='mb-0 fs-14 fw-600'>{userAbout?.mobile}</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end '
								lines='full'
							>
								<div className='d-flex flex-grow-1 justify-content-between'>
									<p className='mb-0 fs-14'>{t('DMA Type')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{userAbout?.DMAType ? userAbout?.DMAType : t('NA')}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 justify-content-between '>
									<p className='mb-0 fs-14'>{t('Onboarded on')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{userAbout?.JoinedOn
											? FormatDate(userAbout?.JoinedOn, 'dd mmmm yyyy')
											: t('NA')}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 justify-content-between '>
									<p className='mb-0 fs-14'>{t('PAN')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{userAbout?.pan ? userAbout?.pan : t('NA')}
									</p>
								</div>
							</IonItem>
							<div className='d-flex mt-3 justify-content-between align-items-center '>
								<h4 className='fw-bold fs-20'>{t('Basic Info')}</h4>
								<CustomButton
									onClick={(e: any) => {
										e.preventDefault();
									}}
									disabled={true}
									fill='outline'
									title={t('Edit')}
								/>
							</div>

							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div
									className={`d-flex flex-grow-1 ${
										userAbout?.NameAsPerAadhar &&
										userAbout?.NameAsPerAadhar.length > 17 &&
										'flex-column'
									} justify-content-between `}
									style={{ overflow: 'hidden' }}
								>
									<p className='mb-0 fs-14'>{t('Name as per Aadhaar')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{userAbout?.NameAsPerAadhar
											? userAbout?.NameAsPerAadhar
											: t('NA')}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 justify-content-between '>
									<p className='mb-0 fs-14'>{t('Alternate Mobile Number')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{userAbout?.AlternateMobile != '0'
											? userAbout?.AlternateMobile
											: t('NA')}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div
									className={`d-flex flex-grow-1 ${
										userAbout?.email &&
										userAbout?.email.length > 25 &&
										'flex-column'
									} justify-content-between `}
									style={{ overflow: 'hidden' }}
								>
									<p className='mb-0 fs-14'>{t('Email')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{userAbout?.email ? userAbout?.email : t('NA')}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 justify-content-between '>
									<p className='mb-0 fs-14'>{t('City')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{userAbout?.city ? userAbout?.city : t('NA')}
									</p>
								</div>
							</IonItem>

							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 justify-content-between '>
									<p className='mb-0 fs-14'>{t('State')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{userAbout?.state ? userAbout?.state : t('NA')}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 justify-content-between '>
									<p className='mb-0 fs-14'>{t('Pincode')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{userAbout?.pincode ? userAbout?.pincode : t('NA')}
									</p>
								</div>
							</IonItem>
							<div className='d-flex mt-3 justify-content-between align-items-center '>
								<h4 className='fw-bold fs-20'>{t('Professional Info')}</h4>
								<CustomButton
									onClick={(e: any) => {
										e.preventDefault();
									}}
									disabled={true}
									fill='outline'
									title={t('Edit')}
								/>
							</div>

							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 justify-content-between '>
									<p className='mb-0 fs-14'>{t('Registered As')}</p>
									<p className='mb-0 fs-14 text-capitalize fw-600'>
										{userAbout?.RegisteredAs
											? userAbout?.RegisteredAs
											: t('NA')}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 justify-content-between '>
									<p className='mb-0 fs-14'>{t('Current Business')}</p>
									<p className='mb-0 fs-14 text-capitalize fw-600'>
										{userAbout?.CurrentBusiness
											? userAbout?.CurrentBusiness
											: t('NA')}
									</p>
								</div>
							</IonItem>

							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div
									className={`d-flex flex-grow-1 justify-content-between ${
										userAbout?.CurrentBusinessAddress
											? 'flex-column'
											: 'flex-row'
									}`}
									style={{ overflow: 'hidden' }}
								>
									<p className='mb-0 fs-14'>{t('Current Business Address')}</p>
									<p
										className='mb-0 fs-14 fw-600'
										style={
											{
												// textOverflow: 'ellipsis',
												// whiteSpace: 'nowrap',
												// overflow: 'hidden',
											}
										}
									>
										{userAbout?.CurrentBusinessAddress
											? userAbout?.CurrentBusinessAddress
											: t('NA')}
									</p>
								</div>
							</IonItem>
							{userAbout?.CurrentBusiness === 'Finance' && (
								<>
									<IonItem
										className='ion-no-padding remove-ion-item-inner-padding-end'
										lines='full'
									>
										<div className='d-flex flex-grow-1 justify-content-between '>
											<p className='mb-0 fs-14'>{t('Associated with DSA')}</p>
											<p className='mb-0 fs-14 fw-600'>
												{userAbout?.DSAAssociation === '0' ? 'No' : 'Yes'}
											</p>
										</div>
									</IonItem>

									{userAbout?.DSAAssociation === '1' && (
										<>
											<IonItem
												className='ion-no-padding remove-ion-item-inner-padding-end'
												lines='full'
											>
												<div
													className={
														userAbout?.ProductName.length > 2
															? 'd-block py-2'
															: 'd-flex flex-grow-1 justify-content-between'
													}
												>
													<p className='mb-0 fs-14'>{t('Products')}</p>
													<p className='mb-0 fs-14 fw-600'>
														{userAbout?.ProductName
															? userAbout?.ProductName.join(', ')
															: t('NA')}
													</p>
												</div>
											</IonItem>
											<IonItem
												className='ion-no-padding remove-ion-item-inner-padding-end'
												lines='full'
											>
												<div className='d-flex flex-grow-1 justify-content-between'>
													<p className='mb-0 fs-14'>
														{t('Monthly Business Volume')} <br />
													</p>
													<p className='mb-0 fs-14 fw-600'>
														{userAbout?.MonthlyBusinessVolumeInLakhs
															? '₹ ' +
															  InputFormatCurrencyValue(
																	parseInt(
																		userAbout?.MonthlyBusinessVolumeInLakhs
																	)
															  )
															: t('NA')}
													</p>
												</div>
											</IonItem>
										</>
									)}
								</>
							)}
							{(userAbout?.CurrentBusiness?.toLowerCase() === 'finance' &&
								userAbout?.DSAAssociation === '0') ||
							userAbout?.CurrentBusiness?.toLowerCase() === 'non-finance' ? (
								<IonItem
									className='ion-no-padding remove-ion-item-inner-padding-end'
									lines='full'
								>
									<div className='d-flex flex-grow-1 justify-content-between'>
										<p className='mb-0 fs-14'>{t('Nature of Business')}</p>
										<p className='mb-0 fs-14 fw-600'>
											{userAbout?.NatureOfBusiness
												? userAbout?.NatureOfBusiness
												: t('NA')}
										</p>
									</div>
								</IonItem>
							) : (
								''
							)}
							{userAbout?.CurrentBusiness === 'Non-Finance' && (
								<IonItem
									className='ion-no-padding remove-ion-item-inner-padding-end'
									lines='full'
								>
									<div className='d-flex flex-grow-1 justify-content-between'>
										<p className='mb-0 fs-14'>{t('Profession')}</p>
										<p className='mb-0 fs-14 fw-600'>
											{userAbout?.Profession ? userAbout?.Profession : t('NA')}
										</p>
									</div>
								</IonItem>
							)}
							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 justify-content-between'>
									<p className='mb-0 fs-14'>{t('Reference 1 Name')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{userAbout?.ReferenceName1
											? userAbout?.ReferenceName1
											: t('NA')}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 justify-content-between'>
									<p className='mb-0 fs-14'>
										{t('Reference 1 Mobile Number') + '.'}
									</p>
									<p className='mb-0 fs-14 fw-600'>
										{userAbout?.ReferenceMobile1
											? userAbout?.ReferenceMobile1
											: t('NA')}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 justify-content-between'>
									<p className='mb-0 fs-14'>{t('Reference 2 Name')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{userAbout?.ReferenceName2
											? userAbout?.ReferenceName2
											: t('NA')}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 justify-content-between '>
									<p className='mb-0 fs-14'>
										{t('Reference 2 Mobile Number') + '.'}
									</p>
									<p className='mb-0 fs-14 fw-600'>
										{userAbout?.ReferenceMobile2
											? userAbout?.ReferenceMobile2
											: t('NA')}
									</p>
								</div>
							</IonItem>
						</IonList>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};
