// import React, { useState } from 'react'
// import { IonContent } from '@ionic/react';
import { IonPage } from '@ionic/react';
import { CustomStepsCountHeaderBar } from '../custom-steps-count-header-bar/CustomStepsCountHeaderBar';
import React from 'react';

export const LeadsFlowGenerator = ({
	currentStep,
	stepsHeader,
	displayStepsCounter,
	stepsProgressSubHeaderData,
	stepsComponents,
	pageCloseModal,
	progressDisable,
}: any) => {
	if (!stepsComponents.length) {
		return null;
	}
	return (
		<IonPage>
			{stepsHeader}
			{/* <IonContent> */}

			{!progressDisable && displayStepsCounter && (
				<CustomStepsCountHeaderBar
					currentStep={currentStep}
					displayStepsCounter={displayStepsCounter}
					data={stepsProgressSubHeaderData}
				/>
			)}

			{/* render steps components from array */}
			{stepsComponents.map((item: any) => {
				return (
					<React.Fragment key={`stepsComponents-${item.step}`}>
						{currentStep === item.step && item.component}
					</React.Fragment>
				);
			})}
			{pageCloseModal}
		</IonPage>
	);
};
