import { REGEX } from '../../../helpers/constant';
import { PersonalInfo } from '../LenderSpecificInfo';
import * as Yup from 'yup';

export const OtherInfoSchema = Yup.object({
	net_monthly_income: Yup.string()
		.required('Net Monthly Income is a required field')
		.matches(REGEX.ALLOWNUMBERS, 'Net Monthly Income should be number')
		.matches(
			REGEX.GREATER_THAN_ZERO,
			'Net Monthly Income should be greater than zero'
		),
});

export const OtherInfo = [
	...PersonalInfo,
	{
		name: 'net_monthly_income',
		type: 'text',
		placeholder: 'Net Monthly Income *',
		isDisabled: true,
	},
];
