/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import {
	IonBadge,
	IonContent,
	IonIcon,
	IonImg,
	IonItem,
	// IonItem,
	// IonLabel,
	// IonList,
	// IonCard,
	// IonCardHeader,
	// IonCardTitle,
	// IonCardSubtitle,
	IonPage,
	isPlatform,
	// IonCardContent,
	// IonAccordion,
	// IonAccordionGroup,
	// IonItem,
	// IonLabel,
} from '@ionic/react';
import moment from 'moment';
import querystring from 'querystring';
import './MisReports.scss';
import { Header } from '../../components/layout/header/Header';
// import { CustomButton } from '../../components/common/custom-button/CustomButton';
// import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	getCityNameList,
	getMisExport,
	getMisFilters,
	getMisReports,
	getUserNameList,
} from '../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
// import { Divider } from '../../components/common/divider/Divider';
import DropDownModal from '../../components/common/custom-dropdown/DropDownModal';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
import { Controller, useForm } from 'react-hook-form';
import { Divider } from '../../components/common/divider/Divider';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	DownloadFileBlob,
	DownloadFileInApp,
	showToastAlert,
} from '../../helpers/functions';
import { close } from 'ionicons/icons';
import { REGEX } from '../../helpers/constant';
import CustomPopoverSearch from '../../components/common/custom-popover/CustomPopoverSearch';
import { PushNotifications } from '@capacitor/push-notifications';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
import {
	AndroidSettings,
	IOSSettings,
	NativeSettings,
} from 'capacitor-native-settings';
import { LocalNotifications } from '@capacitor/local-notifications';
import { FileOpener } from '@capacitor-community/file-opener';

const validationSchema = yup.object().shape({
	report: yup.string().required('Please select report from given options'),
	to_date: yup
		.date()
		.required('End Date is Required.')
		.min('2023-10-01', 'Date is too early, pick date onwards 2023-10-01 ')
		.typeError('Please select valid date')
		.max(new Date(), 'Date cannot be in the future')
		.test({
			name: 'is-later-than-start',
			message: 'End Date should not be earlier than Start Date',
			test: function (value) {
				const startDate = this.parent.from_date;
				return startDate ? value >= startDate : true;
			},
		}),
	from_date: yup
		.date()
		.required('Start Date is Required.')
		.min('2023-10-01', 'Date is too early, pick date onwards 2023-10-01 ')
		.typeError('Please select valid date')
		.max(new Date(), 'Date cannot be in the future'),
});

export const MisReports: React.FC = () => {
	const history = useHistory();
	// const location = useLocation();
	const dispatch = useDispatch();
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	const [misList, setMisList] = useState<any>([]);
	const [filterList, setFilterList] = useState<any>([]);
	const [filterDropdown, setFilterDropdown] = useState<any>([]);
	const [list, setList] = useState<any>([]);
	const [userList, setUserList] = useState<any>([]);
	const [openCompanyModal, setOpenCompanyModal] = useState<any>(false);
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const [showNotificationModel, setShowNitificationModel] = useState(false);
	const { t } = useTranslation();

	const {
		handleSubmit,
		control,
		setValue,
		reset,
		getValues,
		trigger,
		watch,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			report: '',
			to_date: '',
			from_date: '',
			filter: '',
			searchBy: '',
		},
	});

	useEffect(() => {
		if (!location.pathname.includes('/mis-reports')) {
			return;
		}
		getMisList();
		getFilterList();
	}, [getValues('report')]);

	const registerNotifications = async () => {
		const permStatus = await PushNotifications.checkPermissions();

		if (permStatus.receive == 'denied') {
			setShowNitificationModel(true);
		} else if (permStatus.receive == 'prompt-with-rationale') {
			setShowNitificationModel(true);
		} else {
			setShowNitificationModel(false);
		}
	};
	const getMisData = async (params: any) => {
		const paramsObj = { ...params };
		delete paramsObj?.filter;
		delete paramsObj?.report;
		delete paramsObj?.searchBy;

		const dateTime = moment().format('YYYY-MM-DD-hmmss');
		const report_name = misList.find((item: any) => item.id == watch('report'));
		const file_name = report_name?.file_name + dateTime;

		await dispatch(
			getMisExport({
				param: `${params?.report}?${querystring.stringify(
					paramsObj
				)}&user_id=${userId}`,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response.status) {
					showToastAlert({ type: 'success', message: 'Download Initiated' });
					if (isPlatform('mobileweb')) {
						DownloadFileBlob(response.data, file_name, 'xlsx');
					} else if (
						isPlatform('android') ||
						isPlatform('ios') ||
						isPlatform('ipad') ||
						isPlatform('iphone')
					) {
						// setSpinner(true);
						registerNotifications();
						DownloadFileInApp(response.data, file_name, 'xlsx');
					} else {
						DownloadFileBlob(response.data, file_name, 'xlsx');
					}
				}
			});
	};
	useEffect(() => {
		LocalNotifications.addListener('localNotificationReceived', (data: any) => {
			if (data) {
				// setSpinner(false);
			}
		});
		LocalNotifications.addListener(
			'localNotificationActionPerformed',
			(payload: any) => {
				const route = payload.notification.extra.route;
				FileOpener.open({
					filePath: route,
				});
			}
		);
		return () => {
			LocalNotifications.removeAllListeners();
		};
	}, []);
	const getMisList = async () => {
		await dispatch(getMisReports())
			.then(unwrapResult)
			.then((res: any) => {
				if (res?.status) {
					if (res?.data?.length > 0) {
						setMisList(res?.data);
						return res.data[0].id;
					}
				}
			});
	};

	const getFilterList = async () => {
		const id = getValues('report') || 1;
		await dispatch(
			getMisFilters({
				param: `/${id}`,
			})
		)
			.then(unwrapResult)
			.then((res: any) => {
				if (res?.status) {
					if (res?.data?.length > 0) {
						setFilterDropdown(res?.data);
					}
				}
			});
	};

	const dateFormat = (date: any) => {
		return moment(date).format('DD-MM-YYYY');
	};

	const onSubmit = async (data: any) => {
		getMisData({
			...data,
			to_date: dateFormat(data?.to_date),
			from_date: dateFormat(data?.from_date),
		});
	};

	const handleFilter = (flag: any, filter: any = null) => {
		if (flag === 'add') {
			const check = filterList.find(
				(item: any) => item?.id === watch('filter')
			);

			!check &&
				setFilterList([
					...filterList,
					filterDropdown.find((item: any) => item.id === watch('filter')),
				]);
			setValue(filter, watch('searchBy'));
			setValue('filter', '');
			setValue('searchBy', '');
		} else if (flag === 'remove') {
			const deleted = filterList.filter((item: any) => item?.id != filter?.id);

			setFilterList(deleted);
		}
	};

	useEffect(() => {
		setValue('searchBy', '');
	}, [watch('filter')]);

	useEffect(() => {
		setValue('to_date', '');
		setValue('from_date', '');
	}, [watch('report')]);

	const getCityList = async (searchValue: any, type: any) => {
		await dispatch(
			getCityNameList({
				param: `?searchItem=${searchValue}&type=${type}`,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					const data = response?.data;
					const unique = data
						.map((item: any) => item?.city.toUpperCase())
						.filter((value: any, index: any, self: any) => {
							return self.indexOf(value.toUpperCase()) === index;
						});

					const cityList =
						unique.length > 0 &&
						unique.map((item: any) => ({
							name: item,
						}));

					setList(cityList);
				}
			});
	};
	const getUserList = async (searchValue: any) => {
		let searchType = '';
		switch (watch('filter')) {
			case 'manager':
				searchType = 'Manager';
				break;
			case 'buh':
				searchType = 'BUH';
				break;
			case 'manager_one_down':
				searchType = 'Manager one down';
				break;
			case 'sr_manager':
				searchType = 'Senior Manager';
				break;
			case 'telecaller':
				searchType = 'TeleCaller';
				break;
			case 'product':
				searchType = 'Product';
				break;
			case 'sub_product':
				searchType = 'Sub Product';
				break;
			default:
				searchType = '';
				break;
		}
		await dispatch(
			getUserNameList({
				param: `?searchItem=${searchValue}&type=${searchType}`,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				{
					if (watch('filter') === 'buh') {
						setUserList(response.data);
					} else {
						setList(response.data);
					}
				}
			});
	};

	const filterLabel = () => {
		const label =
			filterDropdown.length > 0 &&
			filterDropdown.find((item: any) => item?.id == watch('filter'));

		return label && label?.value;
	};
	return (
		<>
			<IonPage className=''>
				<Header
					backButton={true}
					needArrow={true}
					backButtonText=''
					style={{
						background: isWeb ? 'white' : '#cfdff7',
					}}
					headerBg={'#cfdff7'}
					needArrowOnClick={() => {
						history.push('/dashboard/home');
						setFilterList([]);
						setList([]);
						setUserList([]);
						setFilterDropdown([]);
						reset();
					}}
					needArrowImg={'/images/icons/backarrow_white_bg.png'}
					needArrowImgStyle={{ height: '26px' }}
					name={t('MIS REPORTS')}
				/>
				<IonContent
					fullscreen
					className='help-desk'
				>
					{/* <IonContent> */}
					<div className='bg-web py-md-3'>
						<div className='bg-white border-md-radius-10 container-width-md'>
							<form
								onSubmit={handleSubmit(onSubmit)}
								encType='multipart/form-data'
							>
								<div className='mt-3 px-3 pt-3'>
									{/* <h6 className='fs-16 fw-600 mb-4'>{t('Select Report')}</h6> */}
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, name } }) => (
											<>
												<DropDownModal
													placeholder={'Mis'}
													label={'Select MIS Report*'}
													data={misList}
													name={name}
													onChange={onChange}
													value={getValues(name)}
													onBlur={onBlur}
												/>
												{/* <span className='ps-3'>
													{errors[name]?.message || ''}
												</span> */}
											</>
										)}
										name={'report'}
									/>
									<Controller
										// key={`leadStepInfoFormInputs-${index}`}
										control={control}
										render={({ field: { onChange, onBlur, name } }) => (
											<CustomInput
												type={'date'}
												label={t('Start Date*')}
												onBlur={onBlur}
												id={'startdate'}
												needSuffixIcon={true}
												suffixClassName={'position-absolute date-range'}
												suffixText={
													<IonImg
														src='/images/icons/input-dob-calendar-icon.svg'
														alt='dob'
													/>
												}
												onChange={(e: any) => {
													onChange(e);
												}}
												value={getValues(name)}
												outerClassName={`${'position-relative'}`}
												className={'text-uppercase pt-2 '}
												errors={t(errors[name]?.message || '')}
											/>
										)}
										name={'from_date'}
									/>
									<Controller
										// key={`leadStepInfoFormInputs-${index}`}
										control={control}
										render={({ field: { onChange, onBlur, name } }) => (
											<CustomInput
												type={'date'}
												label={t('End Date*')}
												onBlur={onBlur}
												id={'from_date'}
												needSuffixIcon={true}
												suffixClassName={'position-absolute date-range'}
												suffixText={
													<IonImg
														src='/images/icons/input-dob-calendar-icon.svg'
														alt='dob'
													/>
												}
												onChange={(e: any) => {
													onChange(e);
												}}
												value={getValues(name)}
												outerClassName={`${'position-relative'}`}
												className={'text-uppercase pt-2 '}
												errors={t(errors[name]?.message || '')}
											/>
										)}
										name={'to_date'}
									/>
								</div>
								<Divider className='mt-4' />
								<div className='px-3 py-1'>
									{filterList.length > 0 && (
										<div className='border-2 border py-2 border-radius-10'>
											{filterList.map((item: any, i: any) => (
												<IonBadge
													key={i}
													className='px-2 mx-2 cursor-pointer'
													slot='start'
													onClick={() => {
														handleFilter('remove', item);
													}}
												>
													<div
														className='flex'
														style={{ fontSize: 11 }}
													>
														<div className=''>{item?.value}: </div>
														<div className='px-2'>{watch(item?.id)}</div>
													</div>
													<IonIcon
														icon={close}
														style={{
															position: 'absolute',
															top: '-5px',
															right: '-5px',
															fontSize: '16px',
															color: 'red',
															backgroundColor: 'white',
															padding: '2px',
															borderRadius: '100%',
															border: '1px solid black',
														}}
													/>
												</IonBadge>
											))}
										</div>
									)}

									<Controller
										control={control}
										render={({ field: { onChange, onBlur, name } }) => (
											<>
												<DropDownModal
													label={'Select Filter'}
													data={filterDropdown}
													name={name}
													onChange={onChange}
													value={getValues(name)}
													onBlur={onBlur}
												/>
												{/* <span className='ps-3'>
													{errors[name]?.message || ''}
												</span> */}
											</>
										)}
										name={'filter'}
									/>
									{watch('filter') && (
										<>
											{watch('filter') === 'city' ? (
												<>
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<CustomInput
																type={'text'}
																name={name}
																onFocus={() => setOpenCompanyModal(true)}
																onChange={(e: any) => {
																	setOpenCompanyModal(true);
																	onChange(e);
																}}
																label={'City'}
																placeholder={filterDropdown.map(
																	(item: any) =>
																		item?.id === watch('filter') && item?.value
																)}
																onBlur={onBlur}
																value={getValues(name)}
																// errors={errorText[name]?.message}
															/>
														)}
														name={'searchBy'}
													/>
													{
														<CustomPopoverSearch
															searchApi={getCityList}
															list={list}
															setList={setList}
															isOpen={openCompanyModal}
															searchListModalClose={setOpenCompanyModal}
															title='City Names'
															searchbarPlaceholder='Search City by “Name”'
															customNoDataMessage={'Search City by “Name”'}
															setValue={(val: any) => {
																setValue('searchBy', val);
																trigger('searchBy');
															}}
														/>
													}
												</>
											) : watch('filter') === 'buh' ? (
												<>
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<CustomInput
																type={'text'}
																name={name}
																onFocus={() => setOpenCompanyModal(true)}
																onChange={(e: any) => {
																	setOpenCompanyModal(true);
																	onChange(e);
																}}
																label={'BUH'}
																placeholder={filterDropdown.map(
																	(item: any) =>
																		item?.id === watch('filter') && item?.value
																)}
																onBlur={onBlur}
																value={getValues(name)}
																// errors={errorText[name]?.message}
															/>
														)}
														name={'searchBy'}
													/>
													{
														<CustomPopoverSearch
															searchApi={getUserList}
															list={userList}
															setList={setUserList}
															isOpen={openCompanyModal}
															searchListModalClose={setOpenCompanyModal}
															title='BUH'
															searchbarPlaceholder='Search BUH by “Name”'
															customNoDataMessage={'Search BUH by “Name”'}
															setValue={(val: any) => {
																setValue('searchBy', val);
																trigger('searchBy');
															}}
														/>
													}
												</>
											) : watch('filter') === 'manager' ? (
												<>
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<CustomInput
																type={'text'}
																name={name}
																onFocus={() => setOpenCompanyModal(true)}
																onChange={(e: any) => {
																	setOpenCompanyModal(true);
																	onChange(e);
																}}
																label={'Manager'}
																placeholder={filterDropdown.map(
																	(item: any) =>
																		item?.id === watch('filter') && item?.value
																)}
																onBlur={onBlur}
																value={getValues(name)}
																// errors={errorText[name]?.message}
															/>
														)}
														name={'searchBy'}
													/>
													{
														<CustomPopoverSearch
															searchApi={getUserList}
															list={list}
															setList={setList}
															isOpen={openCompanyModal}
															searchListModalClose={setOpenCompanyModal}
															title='Manager'
															searchbarPlaceholder='Search Manager by “Name”'
															customNoDataMessage={'Search Manager by “Name”'}
															setValue={(val: any) => {
																setValue('searchBy', val);
																trigger('searchBy');
															}}
														/>
													}
												</>
											) : watch('filter') === 'sr_manager' ? (
												<>
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<CustomInput
																type={'text'}
																name={name}
																onFocus={() => setOpenCompanyModal(true)}
																onChange={(e: any) => {
																	setOpenCompanyModal(true);
																	onChange(e);
																}}
																label={'Sr Manager'}
																placeholder={filterDropdown.map(
																	(item: any) =>
																		item?.id === watch('filter') && item?.value
																)}
																onBlur={onBlur}
																value={getValues(name)}
																// errors={errorText[name]?.message}
															/>
														)}
														name={'searchBy'}
													/>
													{
														<CustomPopoverSearch
															searchApi={getUserList}
															list={list}
															setList={setList}
															isOpen={openCompanyModal}
															searchListModalClose={setOpenCompanyModal}
															title='Sr. Manager'
															searchbarPlaceholder='Search Sr Manager by “Name”'
															customNoDataMessage={
																'Search Sr Manager by “Name”'
															}
															setValue={(val: any) => {
																setValue('searchBy', val);
																trigger('searchBy');
															}}
														/>
													}
												</>
											) : watch('filter') === 'manager_one_down' ? (
												<>
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<CustomInput
																type={'text'}
																name={name}
																onFocus={() => setOpenCompanyModal(true)}
																onChange={(e: any) => {
																	setOpenCompanyModal(true);
																	onChange(e);
																}}
																label={'Manager One Down'}
																placeholder={filterDropdown.map(
																	(item: any) =>
																		item?.id === watch('filter') && item?.value
																)}
																onBlur={onBlur}
																value={getValues(name)}
																// errors={errorText[name]?.message}
															/>
														)}
														name={'searchBy'}
													/>
													{
														<CustomPopoverSearch
															searchApi={getUserList}
															list={list}
															setList={setList}
															isOpen={openCompanyModal}
															searchListModalClose={setOpenCompanyModal}
															title='Manager One Down'
															searchbarPlaceholder='Search Manager One Down by “Name”'
															customNoDataMessage={
																'Search Manager One Down by “Name”'
															}
															setValue={(val: any) => {
																setValue('searchBy', val);
																trigger('searchBy');
															}}
														/>
													}
												</>
											) : watch('filter') === 'telecaller' ? (
												<>
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<CustomInput
																type={'text'}
																name={name}
																onFocus={() => setOpenCompanyModal(true)}
																onChange={(e: any) => {
																	setOpenCompanyModal(true);
																	onChange(e);
																}}
																label={'Telecaller'}
																placeholder={filterDropdown.map(
																	(item: any) =>
																		item?.id === watch('filter') && item?.value
																)}
																onBlur={onBlur}
																value={getValues(name)}
																// errors={errorText[name]?.message}
															/>
														)}
														name={'searchBy'}
													/>
													{
														<CustomPopoverSearch
															searchApi={getUserList}
															list={list}
															setList={setList}
															isOpen={openCompanyModal}
															searchListModalClose={setOpenCompanyModal}
															title='Telecaller'
															searchbarPlaceholder='Search Telecaller by “Name”'
															customNoDataMessage={
																'Search Telecaller by “Name”'
															}
															setValue={(val: any) => {
																setValue('searchBy', val);
																trigger('searchBy');
															}}
														/>
													}
												</>
											) : watch('filter') === 'product' ? (
												<>
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<CustomInput
																type={'text'}
																name={name}
																onFocus={() => setOpenCompanyModal(true)}
																onChange={(e: any) => {
																	setOpenCompanyModal(true);
																	onChange(e);
																}}
																label={'Product'}
																placeholder={filterDropdown.map(
																	(item: any) =>
																		item?.id === watch('filter') && item?.value
																)}
																onBlur={onBlur}
																value={getValues(name)}
																// errors={errorText[name]?.message}
															/>
														)}
														name={'searchBy'}
													/>
													{
														<CustomPopoverSearch
															searchApi={getUserList}
															list={list}
															setList={setList}
															isOpen={openCompanyModal}
															searchListModalClose={setOpenCompanyModal}
															title='Product'
															searchbarPlaceholder='Search Product by “Name”'
															customNoDataMessage={'Search Product by “Name”'}
															setValue={(val: any) => {
																setValue('searchBy', val);
																trigger('searchBy');
															}}
														/>
													}
												</>
											) : watch('filter') === 'sub_product' ? (
												<>
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<CustomInput
																type={'text'}
																name={name}
																onFocus={() => setOpenCompanyModal(true)}
																onChange={(e: any) => {
																	setOpenCompanyModal(true);
																	onChange(e);
																}}
																label={'Sub Product'}
																placeholder={filterDropdown.map(
																	(item: any) =>
																		item?.id === watch('filter') && item?.value
																)}
																onBlur={onBlur}
																value={getValues(name)}
																// errors={errorText[name]?.message}
															/>
														)}
														name={'searchBy'}
													/>
													{
														<CustomPopoverSearch
															searchApi={getUserList}
															list={list}
															setList={setList}
															isOpen={openCompanyModal}
															searchListModalClose={setOpenCompanyModal}
															title='Sub Product'
															searchbarPlaceholder='Search Sub Product by “Name”'
															customNoDataMessage={
																'Search Sub Product by “Name”'
															}
															setValue={(val: any) => {
																setValue('searchBy', val);
																trigger('searchBy');
															}}
														/>
													}
												</>
											) : (
												<Controller
													control={control}
													render={({ field: { onChange, onBlur, name } }) => (
														<CustomInput
															type={
																watch('filter') === 'phone_number'
																	? 'number'
																	: 'text'
															}
															label={filterLabel()}
															onBlur={onBlur}
															id={'searchBy'}
															onChange={(e: any) => {
																// watch('filter') === 'phone_number'
																// 	? REGEX?.INDIAN_MOBILE_NUMBERS.test(
																// 			e.detail.value
																// 	  ) && setValue(name, e.detail.value)
																// 	:
																onChange(e);
															}}
															value={getValues(name)}
															disabled={false}
															outerClassName={`${'position-relative'}`}
															className={' pt-2 '}
															errors={
																watch('filter') === 'phone_number' &&
																watch('searchBy')
																	? !REGEX?.INDIAN_MOBILE_NUMBERS.test(
																			watch('searchBy')
																	  ) && 'Enter Valid Phone Number'
																	: errors[name] || ''
															}
														/>
													)}
													name={'searchBy'}
												/>
											)}
										</>
									)}
									<div className='flex'>
										<CustomButton
											style={{ width: '50%' }}
											onClick={() => {
												setFilterList([]);
												setValue('filter', '');
												setValue('searchBy', '');
											}}
											title={t('Reset')}
											disabled={
												filterList.length > 0 ||
												watch('filter') ||
												watch('searchBy')
													? false
													: true
											}
											size={'small'}
										/>
										<CustomButton
											style={{ width: '50%' }}
											onClick={() => {
												handleFilter('add', watch('filter'));
											}}
											size={'small'}
											title={t('Apply')}
											disabled={!watch('filter') || !watch('searchBy')}
										/>
									</div>
								</div>
								<Divider className='mt-2' />
								<div className=' px-3 pt-3'>
									<CustomButton
										type={'submit'}
										expand='block'
										onClick={() => {
											handleSubmit(onSubmit);
										}}
										title={t('DOWNLOAD')}
										disabled={
											watch('searchBy') && watch('filter')
												? true
												: (!watch('searchBy') == !watch('filter')) === false
												? true
												: false == false && isValid == false
										}
									/>
								</div>
							</form>
						</div>
					</div>
				</IonContent>

				{/* </IonContent> */}
			</IonPage>
			<CustomModal
				id=''
				isOpen={showNotificationModel}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setShowNitificationModel(false)}
				breakpoints={[]}
				className='confirm-popup-middle height-auto'
				modalContent={
					<div className={`wrapper py-4 confirm-popup-middle height-auto`}>
						<IonItem lines='none'>
							<div className='d-flex flex-column'>
								<p
									className='fs-14 mb-2'
									style={{ color: 'var(--text-dark-grey)' }}
								>
									Please enable notification permission from settings.
								</p>
							</div>
						</IonItem>
						<div className='d-flex justify-content-center gap-3 px-3'>
							<CustomButton
								className='w-50'
								fill='solid'
								onClick={() => {
									NativeSettings.open({
										optionAndroid: AndroidSettings.AppNotification,
										optionIOS: IOSSettings.Notifications,
									});
									setShowNitificationModel(false);
								}}
								title={t('Ok')}
							/>
						</div>
					</div>
				}
				needIonContentWraper={false}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
