//// @ts-nocheck



import { IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useBusyLoading } from '../../..//context/BusyLoadingContext';
import { UPDATE_STATUS } from '../../..//helpers/api-endpoints';
import useApi from '../../../api/useApi';
import ApplicationContext from '../../../context/ApplicationContext';
import {AuthContext} from '../../../context/AuthContext';
import { INSURANCE_POLICY_PROPOSED_FOR, INSURANCE_STATUS, INSURANCE_TYPE, REDIRECT } from '../../../helpers/constant';
import { showToastAlert } from '../../../helpers/functions';
import { AddressDetailsForm, AddressDetailsRequest } from '../../../model/address_details_bean';
import  {BasicDetailsForm, BasicDetailRequest } from '../../../model/basic_details_bean';
import { ExistingPolicyDetailsForm, ExistingPolicyDetailsRequest } from '../../../model/existing_policy_bean';
import { FamilyDetailRequest, FamilyDetailsForm } from '../../../model/family_details_bean';
import { ApplicationBean, StatusUpdateRequest } from '../../../model/insurance_bean';
import { OccupationDetailsForm, OccupationDetailsRequest } from '../../../model/occupation_bean';
import { FloaterFamily } from '../../../pages/floater/family_floater_main';
import InsuranceForm from '../insurance_form';
import { DocumentUploadForm } from '../../../model/document_bean';
import { useBackButtonHandler } from '../../../helpers/redirects';
// import { useNavigate } from 'react-router-dom';

interface Props {
  appBean?: ApplicationBean,
  // policyProposedFor: string;
  // finalSubmit?: () => void;
}


export const DmaCoordInsuranceForm: React.FC<Props> = ({ appBean }) => {
  // export const IfaInsuranceForm: React.FC<Props> = () => {
  const history = useHistory();
  // const navigate = useNavigate();
  const { applicationBean, setAssuredBasicDetailsValid,
    setAssuredFamilyDetailsValid,
    setAssuredAddressDetailsValid,
    setAssuredPermAddressDetailsValid,
    setAssuredCurAddressDetailsValid,
    setAssuredOccupationDetailsValid } = useContext(ApplicationContext);

  const { isDMACoord } = useContext(AuthContext);


  const [showAssured, setShowAssured] = useState<boolean>((!(appBean?.policyProposedFor !== INSURANCE_POLICY_PROPOSED_FOR.SELF)) || false);
  const [showFamilyFloater, setShowFamilyFloater] = useState<boolean>(appBean?.insuranceTypeId === INSURANCE_TYPE.HEALTH_FAMILY || false);
  // const [showIndividual, setShowIndividual] = useState<boolean>(!(showAssured||showFamilyFloater));



  const [basicDetailsProposer, setBasicDetailsProposer] = useState<BasicDetailRequest | undefined>();
  const [basicDetailsAssured, setBasicDetailsAssured] = useState<BasicDetailRequest | undefined>();

  const [familyDetailsProposer, setFamilyDetailsProposer] = useState<FamilyDetailRequest | undefined>();
  const [familyDetailsAssured, setFamilyDetailsAssured] = useState<FamilyDetailRequest | undefined>();

  // permAddressDetailsProposer?:AddressDetailsRequest;
  //   curAddressDetailsProposer?:AddressDetailsRequest;


  //   permAddressDetailsAssured?:AddressDetailsRequest;
  //   curAddressDetailsAssured?:AddressDetailsRequest;


  const [permAddressDetailsProposer, setPermAddressDetailsProposer] = useState<AddressDetailsRequest | undefined>();
  const [curAddressDetailsProposer, setCurAddressDetailsProposer] = useState<AddressDetailsRequest | undefined>();

  const [permAddressDetailsAssured, setPermAddressDetailsAssured] = useState<AddressDetailsRequest | undefined>();
  const [curAddressDetailsAssured, setCurAddressDetailsAssured] = useState<AddressDetailsRequest | undefined>();


  const [occupationDetailsProposer, setOccupationDetailsProposer] = useState<OccupationDetailsRequest | undefined>();
  const [occupationDetailsAssured, setOccupationDetailsAssured] = useState<OccupationDetailsRequest | undefined>();


  const [existingPolicyProposer, setExistingPolicyProposer] = useState<ExistingPolicyDetailsRequest | undefined>();
  const [existingPolicyAssured, setExistingPolicyAssured] = useState<ExistingPolicyDetailsRequest | undefined>();


  const [applicationDocumentsFormProposer, setApplicationDocumentsFormProposer] = useState<DocumentUploadForm[]>([]);
  const [applicationDocumentsFormAssured, setApplicationDocumentsFormAssured] = useState<DocumentUploadForm[]>([]);

  const [applicationId, setApplicationId] = useState<number>(0);

  const [proposerProfileId, setProposerProfileId] = useState<number>(0);
  const [assuredProfileId, setAssuredProfileId] = useState<number>(0);


  const { responseData, loading, executeRequest, error } =
    useApi<any>(`${UPDATE_STATUS}`);

  const { setBusyState } = useBusyLoading();

  useEffect(() => {
    setBusyState(loading);
  }, [loading]);


  // useEffect(() => {
  //   const preventDefault = (e) => {
  //     e.preventDefault();
  //     e.stopPropagation();
  //   };

  //   const preventKeyRefresh = (e) => {
  //     if ((e.ctrlKey && e.key === 'r') || e.key === 'F5' || (e.metaKey && e.key === 'r')) {
  //       preventDefault(e);
  //     }
  //   };

  //   window.addEventListener('beforeunload', preventDefault);
  //   window.addEventListener('keydown', preventKeyRefresh);

  //   return () => {
  //     window.removeEventListener('beforeunload', preventDefault);
  //     window.removeEventListener('keydown', preventKeyRefresh);
  //   };
  // }, []);

  // useEffect(() => {
  //   return () => {
  //     window.removeEventListener('beforeunload', (e) => e.preventDefault());
  //     window.removeEventListener('keydown', (e) => {
  //       if ((e.ctrlKey && e.key === 'r') || e.key === 'F5' || (e.metaKey && e.key === 'r')) {
  //         e.preventDefault();
  //       }
  //     });
  //   };
  // }, []);




  // var showAssured: boolean = true;
  // var showFamilyFloater: boolean = false;


  useEffect(() => {
    if (!appBean) {
      appBean = applicationBean!;
      const stubShowAssured: boolean = appBean?.policyProposedFor !== INSURANCE_POLICY_PROPOSED_FOR.SELF;
      const stubShowFamilyFloater: boolean = appBean?.insuranceTypeId === INSURANCE_TYPE.HEALTH_FAMILY;
      console.log("stubShowAssured, stubShowFamilyFloater", stubShowAssured, stubShowFamilyFloater);

      setApplicationId(appBean.applicationId!);
      setProposerProfileId(appBean.proposerProfileId!);
      setApplicationDocumentsFormProposer(appBean.applicationDocumentsFormProposer ?? []);

      if (stubShowAssured || stubShowFamilyFloater) {
        if (stubShowAssured) {
          setAssuredBasicDetailsValid(false);
          setAssuredFamilyDetailsValid(false);
          setAssuredAddressDetailsValid(false);
          setAssuredPermAddressDetailsValid(false);
          setAssuredCurAddressDetailsValid(false);
          setAssuredOccupationDetailsValid(false);

          setAssuredProfileId(appBean.assuredProfileId!);
          setShowAssured(stubShowAssured);
          setApplicationDocumentsFormAssured(appBean.applicationDocumentsFormAssured ?? []);
        }
        setShowFamilyFloater(stubShowFamilyFloater);
        setShowAssured(stubShowAssured);
      }



    }
    console.log("appBean hbs set", appBean);
    console.log("appBean.familyDetailsProposer", appBean.familyDetailsProposer);



    setBasicDetailsProposer(appBean.basicDetailsProposer);
    setFamilyDetailsProposer(appBean.familyDetailsProposer);
    setPermAddressDetailsProposer(appBean.permAddressDetailsProposer);
    setCurAddressDetailsProposer(appBean.curAddressDetailsProposer);
    setOccupationDetailsProposer(appBean.occupationDetailsProposer);
    setExistingPolicyProposer(appBean.existingPolicyProposer);

    if (showAssured) {
      setBasicDetailsAssured(appBean.basicDetailsAssured);
      setFamilyDetailsAssured(appBean.familyDetailsAssured);
      setPermAddressDetailsAssured(appBean.permAddressDetailsAssured);
      setCurAddressDetailsAssured(appBean.curAddressDetailsAssured);
      setOccupationDetailsAssured(appBean.occupationDetailsAssured);
      setExistingPolicyAssured(appBean.existingPolicyAssured);
    }

  }, [appBean, showAssured, showFamilyFloater,
    basicDetailsProposer, basicDetailsAssured,
    familyDetailsProposer, familyDetailsAssured,
    permAddressDetailsProposer, curAddressDetailsProposer,
    applicationId,
    proposerProfileId, assuredProfileId,
    applicationDocumentsFormProposer,
    applicationDocumentsFormAssured,
    permAddressDetailsAssured, curAddressDetailsAssured,
    occupationDetailsProposer, occupationDetailsAssured,
    existingPolicyProposer, existingPolicyAssured,]);



  // console.log("appBean hbs", appBean);
  // console.log("showFamilyFloater ", showAssured, showFamilyFloater);
  // console.log("showAssured || showFamilyFloater ", showAssured || showFamilyFloater);


  const [currentTab, setCurrentTab] = useState<number>(1);

  const handleTabChange = (tabIndex: number) => {
    setCurrentTab(tabIndex);
  };

  const moveToNextTab = () => {
    setCurrentTab((prevTab) => prevTab + 1);
  };

  useBackButtonHandler()




  const finalSubmit = () => {

    let statusUpdateRequest = new StatusUpdateRequest();
    statusUpdateRequest.applicationId = applicationBean?.applicationId;
    statusUpdateRequest.statusId = INSURANCE_STATUS.ALLOCATED_TO_DMA;
    const payload = statusUpdateRequest;
    executeRequest("put", payload);
  };

  useEffect(() => {
    showToastAlert({ type: "error", message: error?.message! });
  }, [error]);



  useEffect(() => {
    if (responseData?.status) {
      showToastAlert({ type: "success", message: responseData?.message });
      history.push('/cust-ins-message', {
        redirectTo: REDIRECT.DASHBOARD,
        message: `Insurer Selected and Allocated to DMA-Coordinator. Application Id is ${applicationBean?.applicationId}`
      })
    }

  }, [responseData]);


  if (showAssured || showFamilyFloater) {
    return (
      <IonReactRouter>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-8">
              <IonTabs>
                <IonRouterOutlet onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <Route path="/dmacoordinsurance/tabs/proposer" render={() => <InsuranceForm isProposer={true} showDocuments={true}
                    applicationId={applicationId}
                    profileId={proposerProfileId}
                    applicationDocumentsForm={applicationDocumentsFormProposer}
                    showexistingPolicyDetails={false}
                    readonly={!isDMACoord}
                    basicDetailsForm={basicDetailsProposer && new BasicDetailsForm(basicDetailsProposer)}
                    familyDetailsForm={familyDetailsProposer && new FamilyDetailsForm(familyDetailsProposer)}
                    permAddressDetailsForm={permAddressDetailsProposer && new AddressDetailsForm(permAddressDetailsProposer)}
                    curAddressDetailsForm={curAddressDetailsProposer && new AddressDetailsForm(curAddressDetailsProposer)}
                    occupationDetailsForm={occupationDetailsProposer && new OccupationDetailsForm(occupationDetailsProposer)}
                    existingPolicyDetailsForm={existingPolicyProposer && new ExistingPolicyDetailsForm(existingPolicyProposer)}
                    showAssuredTab={true}
                  />} exact={true} />
                  <Route path="/dmacoordinsurance/tabs/assured"
                    render={() => showFamilyFloater ? <FloaterFamily finalSubmit={finalSubmit} /> :
                      <InsuranceForm
                        readonly={!isDMACoord}
                        isProposer={false} showDocuments={true} finalSubmit={finalSubmit}
                        applicationId={applicationId}
                        profileId={assuredProfileId}
                        applicationDocumentsForm={applicationDocumentsFormAssured}
                        basicDetailsForm={basicDetailsAssured && new BasicDetailsForm(basicDetailsAssured)}
                        familyDetailsForm={familyDetailsAssured && new FamilyDetailsForm(familyDetailsAssured)}
                        permAddressDetailsForm={permAddressDetailsAssured && new AddressDetailsForm(permAddressDetailsAssured)}
                        curAddressDetailsForm={curAddressDetailsAssured && new AddressDetailsForm(curAddressDetailsAssured)}
                        occupationDetailsForm={occupationDetailsAssured && new OccupationDetailsForm(occupationDetailsAssured)}
                        existingPolicyDetailsForm={existingPolicyAssured && new ExistingPolicyDetailsForm(existingPolicyAssured)}
                      />} exact={true} />
                  <Redirect exact path="/dmacoordinsurance" to="/dmacoordinsurance/tabs/proposer" />

                </IonRouterOutlet>
                <IonTabBar style={{ justifyContent: 'flex-center', display: 'flex', overflowX: 'auto', whiteSpace: 'nowrap', paddingLeft: '10px', paddingRight: '10px', flexWrap: 'nowrap', }} slot="top">

                  {/* <IonTabBar style={{ overflowX: 'scroll'}} slot="bottom"> */}
                  <IonTabButton className='custom-ion-tab-button' tab="proposer" href="/dmacoordinsurance/tabs/proposer" onClick={() => handleTabChange(1)} selected={currentTab === 1}>
                    <IonLabel>Proposer Details</IonLabel>
                  </IonTabButton>

                  <IonTabButton className='custom-ion-tab-button' tab="assured" href="/dmacoordinsurance/tabs/assured" onClick={() => handleTabChange(2)} selected={currentTab === 2}>
                    <IonLabel>Assured Details</IonLabel>
                  </IonTabButton>

                </IonTabBar>


              </IonTabs>
            </div>
          </div>
        </div>

      </IonReactRouter>
    );
  }
  else {
    return (
      <InsuranceForm
        applicationId={applicationId}
        profileId={proposerProfileId}
        applicationDocumentsForm={applicationDocumentsFormProposer}
        readonly={!isDMACoord}
        isProposer={true} showDocuments={true} finalSubmit={finalSubmit}
        basicDetailsForm={basicDetailsProposer && new BasicDetailsForm(basicDetailsProposer)}
        familyDetailsForm={familyDetailsProposer && new FamilyDetailsForm(familyDetailsProposer)}
        permAddressDetailsForm={permAddressDetailsProposer && new AddressDetailsForm(permAddressDetailsProposer)}
        curAddressDetailsForm={curAddressDetailsProposer && new AddressDetailsForm(curAddressDetailsProposer)}
        occupationDetailsForm={occupationDetailsProposer && new OccupationDetailsForm(occupationDetailsProposer)}
        existingPolicyDetailsForm={existingPolicyProposer && new ExistingPolicyDetailsForm(existingPolicyProposer)}
      />
    );
  }

};

