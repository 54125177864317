import { IonContent, IonPopover } from '@ionic/react';

function CustomToolTip({
	trigger,
	triggerAction,
	render,
	size = 'auto',
	alignment,
	isOpen,
	onDidDismiss,
	tooltiptext,
}: any) {
	return (
		<IonPopover
			isOpen={isOpen}
			onDidDismiss={onDidDismiss}
			trigger={trigger}
			triggerAction={triggerAction}
			size={size}
			alignment={alignment}
		>
			{render ? (
				render
			) : (
				<IonContent className='ion-padding'>
					<p className='m-0 fs-14'>{tooltiptext}</p>
				</IonContent>
			)}
		</IonPopover>
	);
}

export default CustomToolTip;

// isOpen is used to open tooltip programmatically
// render used to render custom component
