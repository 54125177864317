import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './DashboardBannerSlider.scss';

interface IDashboardBannerSliderKey {
	descr: string;
	name: string;
	status: boolean;
	photo_url: string;
	// themeBannerColor?:any;
}
interface IDashboardBannerSlider {
	data: IDashboardBannerSliderKey[];
	// themeBannerColor?:any;
}
const getLocalStorageThemeBannerColor = () => {
	return localStorage.getItem('themeBannerColor') || '#F3F3F3';
  };
  
export const DashboardBannerSlider: React.FC<IDashboardBannerSlider> = ({
	data,
	// themeBannerColor,
}) => {
	const SlickButton = ({
		currentSlide,
		slideCount,
		children,
		...props
	}: any) => (
		<span
			aria-current={currentSlide}
			slot={slideCount}
			{...props}
		>
			{children}
		</span>
	);
	const settings = {
		dots: true,
		speed: 500,
		infinite: true,
		autoplay: true,
		slidesToShow: 2,
		slidesToScroll: 1,
		swipeToSlide: true,
		nextArrow: (
			<SlickButton>
				<div className='next-slick-arrow'>
					<img
						src='/images/icons/next scroller button.svg'
						alt='next slick arrow'
					/>
				</div>
			</SlickButton>
		),
		prevArrow: (
			<SlickButton>
				<div className='prev-slick-arrow'>
					<img
						src='/images/icons/previous scroller button.svg'
						alt='prev slick arrow'
					/>
				</div>
			</SlickButton>
		),
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					dots: true,
					arrows: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1.06,
					infinite: false,
					slidesToScroll: 1,
					arrows: false,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1.06,
					infinite: false,
					slidesToScroll: 1,
					arrows: false,
				},
			},
		],
	};
	return (
		<section
			id='bs'
			className='banner-slider-section bg-web'
			style={{ background: getLocalStorageThemeBannerColor()}}
		>
			<div className='px-3 px-md-0'>
				{data && (
					<Slider
						{...settings}
						className=''
					>
						{data &&
							data.map((item: IDashboardBannerSliderKey, index: number) => {
								return (
									<div key={`dashboard-banner-${index} `}>
										<div className=' pe-2 dashboard-banne-container'>
											<div
												className='dashboard-banner-img'
												style={{
													backgroundImage: `url(${item.photo_url})`,
												}}
											/>
											{/* <h3 className='m-0 fs-18'>{item.name}</h3>
											<p className='mb-0 fs-14'>{item.descr}</p> */}
										</div>
									</div>
								);
							})}
					</Slider>
				)}
			</div>
		</section>
	);
};
