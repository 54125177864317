/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonContent, IonItem, IonList, IonPage } from '@ionic/react';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useHistory } from 'react-router';
import { Header } from '../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { leadFlow } from '../../store/reducers/lead/leadSlice';
import { FormatDate, InputFormatCurrencyValue } from '../../helpers/functions';
import { amountKeys, professionalEquifaxKeys } from '../../helpers/constant';

export const LeadAbout: React.FC = () => {
	const history = useHistory();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);

	const token = useSelector(
		(state: any) => state.users.user_details.access_token
	);
	return (
		<IonPage className='ion-scroll'>
			<Header
				type=''
				name={`${leadInfo?.first_name || 'Unknown'}'s Profile`}
				needArrow={true}
				backButton={true}
				style={{ borderBottom: '1px solid #00000029' }}
				needArrowOnClick={() => {
					leadInfo?.from === 'nimbuss'
						? history.push('/lead-details/' + leadInfo.leadId + '/' + token)
						: history.push('/lead-details');
				}}
			/>
			<IonContent className='ion-padding background-web'>
				<div className='bg-web'>
					<div className='bg-web-white container-width-md px-md-3 border-md-radius-10'>
						<IonList>
							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 justify-content-between'>
									<p className='mb-0 fs-14'>{t('Mobile')}</p>
									<p className='mb-0 fs-14 fw-600'>{leadInfo?.mobile_no}</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 justify-content-between'>
									<p className='mb-0 fs-14'>{t('PAN')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{leadInfo?.doc_no || leadInfo?.pan || t('Not available')}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 justify-content-between'>
									<p className='mb-0 fs-14'>{t('Email')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{leadInfo?.email || t('Not available')}
									</p>
								</div>
							</IonItem>
							<div className='d-flex mt-3 justify-content-between align-items-center'>
								<h4 className='fw-bold fs-20'>{t('Basic Info')}</h4>
								<CustomButton
									className={'d-none'}
									onClick={(e: any) => {
										e.preventDefault();
										const myPromise = new Promise(function (myResolve) {
											setTimeout(function () {
												myResolve(history.push('/loan-lead-info-steps'));
											}, 10);
										});
										myPromise.then(() => {
											dispatch(
												leadFlow({
													...leadInfo,
													step: 'step2',
													productName: leadInfo.product,
													productId: leadInfo?.product_id,
													leadID: leadInfo?.id,
													email: leadInfo?.email,
													// productType: selectedProductName,
												})
											);
										});
									}}
									fill='outline'
									title={t('Edit')}
								/>
							</div>

							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div
									className={`d-flex flex-grow-1 ${
										leadInfo?.first_name
											? leadInfo?.first_name
													?.concat(leadInfo?.middle_name)
													.concat(leadInfo?.last_name).length > 17 &&
											  'flex-column'
											: ''
									} justify-content-between`}
								>
									<p className='mb-0 fs-14'>{t('Name as per Aadhaar')}</p>
									<p className='mb-0 fs-14 fw-600 text-capitalize'>
										{leadInfo?.first_name
											? (leadInfo?.first_name || '') +
											  ' ' +
											  (leadInfo?.middle_name || '') +
											  ' ' +
											  (leadInfo?.last_name || '')
											: t('Not available')}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 justify-content-between'>
									<p className='mb-0 fs-14'>{t('Pin Code')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{leadInfo?.pincode || t('Not available')}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 justify-content-between'>
									<p className='mb-0 fs-14'>{t('Date of Birth')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{leadInfo?.dob
											? FormatDate(leadInfo?.dob, 'dd mmmm yyyy')
											: t('Not available')}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 justify-content-between'>
									<p className='mb-0 fs-14'>{t('Loan Amount')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{leadInfo?.loan_amount
											? '₹ ' + InputFormatCurrencyValue(leadInfo?.loan_amount)
											: t('Not available')}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 justify-content-between'>
									<p className='mb-0 fs-14'>{t('Selected Andro Manager')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{leadInfo?.andro_manager_name || t('Not available')}
									</p>
								</div>
							</IonItem>
							{leadInfo?.professional_details &&
							Object.keys(leadInfo?.professional_details).length ? (
								<div className='d-flex mt-3 justify-content-between align-items-center'>
									<h4 className='fw-bold fs-20'>{t('Professional Info')}</h4>
									<CustomButton
										className={'d-none'}
										onClick={(e: any) => {
											e.preventDefault();

											history.push('/professional-info');

											dispatch(
												leadFlow({
													...leadInfo,
													productName: leadInfo.product,
													productId: leadInfo?.product_id,
													leadID: leadInfo?.id,
													email: leadInfo?.email,
													// productType: selectedProductName,
												})
											);
										}}
										fill='outline'
										title={t('Edit')}
									/>
								</div>
							) : (
								''
							)}

							{leadInfo?.professional_details &&
							Object.keys(leadInfo?.professional_details).length
								? Object.keys(leadInfo?.professional_details).map(
										(key, index) => {
											return (
												<IonItem
													key={`professional_details-${index}`}
													className='ion-no-padding remove-ion-item-inner-padding-end'
													lines='full'
												>
													<div
														className={
															[
																'company_name',
																'salary_account_bank_name',
															].includes(key.replaceAll(' ', '_').toLowerCase())
																? 'd-block py-3'
																: 'd-flex flex-grow-1 justify-content-between'
														}
													>
														<p className='mb-0 fs-14'>{t(key)}</p>
														<p className='mb-0 fs-14 fw-600'>
															{amountKeys.includes(
																key.replaceAll(' ', '_').toLowerCase()
															) ||
															professionalEquifaxKeys.includes(
																key.replaceAll(' ', '_').toLowerCase()
															)
																? '₹ ' +
																  InputFormatCurrencyValue(
																		parseInt(
																			leadInfo?.professional_details[key]
																		)
																  )
																: t(leadInfo?.professional_details[key] || '')}
														</p>
													</div>
												</IonItem>
											);
										}
								  )
								: ''}
						</IonList>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};
