import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en-translation.json';
import translationHI from './locales/hi-translation.json';
import translationBN from './locales/bn-translation.json';
import translationKN from './locales/kn-translation.json';
import translationML from './locales/ml-translation.json';
import translationMR from './locales/mr-translation.json';
import translationTA from './locales/ta-translation.json';
import translationTE from './locales/te-translation.json';

const fallbackLng = ['en'];
const availableLanguages = [
	'en',
	'hi',
	'bn',
	'kn',
	'ml',
	'mr',
	'ta',
	'te',
];

const resources = {
	en: {
		translation: translationEN,
	},
	hi: {
		translation: translationHI,
	},
	bn: {
		translation: translationBN,
	},
	kn: {
		translation: translationKN,
	},
	ml: {
		translation: translationML,
	},
	mr: {
		translation: translationMR,
	},
	ta: {
		translation: translationTA,
	},
	te: {
		translation: translationTE,
	},
};

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng,

		detection: {
			checkWhitelist: true,
		},

		debug: false,

		whitelist: availableLanguages,

		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
