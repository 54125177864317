/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { IonContent, IonFooter, IonImg, isPlatform } from '@ionic/react';
import './AddLoanLeadInfo.scss';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	cbilRequestConsentDetails,
	// cbilRequestUpdateConsentDetails,
	dashboardLeadGenerateOtp,
	getLeadUmDetails,
	leadBureauCustomer,
	leadFlow,
} from '../../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { LEAD_SCORE_TYPE, STATUS_CODE } from '../../../helpers/constant';
import { useHistory } from 'react-router';
import { generateCibilOtp } from '../../../store/reducers/user/userSlice';
import { eventAttributes, notifyHandler } from '../../../helpers/functions';
import { NotifyVisitors } from 'capacitor-plugin-notifyvisitors';

export const GenerateBureauReport: React.FC = () => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const leadSelector = useSelector((state: any) => state?.lead?.leadFlowData);
	const [umData, setUmData] = useState<any>('');
	const history = useHistory();
	// Notify Visitors
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const userName = useSelector(
		(state: any) => state?.users?.homepage?.profile?.name
	);
	const userMobile = useSelector(
		(state: any) => state?.users?.user_details?.mobile
	);
	const entityType = useSelector(
		(state: any) => state?.users?.user_details?.entityType
	);

	const userLang = useSelector(
		(state: any) => state?.oneTimeScreen?.language?.name
	);

	useEffect(() => {
		getLeadFlow();
	}, []);

	const userAttributes = {
		userId: userId,
		attributes: {
			name: userName,
			id: userId,
			mobile: userMobile,
			user_language: userLang,
			entityType:
				entityType === 'Individual'
					? 'DMA'
					: entityType === 'BTM'
					? 'employee'
					: entityType,
		},
	};
	useEffect(() => {
		if (isPlatform('android') || isPlatform('ios')) {
			notifyHandler('event', eventAttributes('bureau_pull_page'));
			notifyHandler('user', userAttributes.attributes);
			const inAppData = {
				tokens: { name: userAttributes },
				customRules: { banerrType: 'bureau pull page' },
			};
			NotifyVisitors.show(inAppData);
		} else {
			(window as any).nv('event', 'bureau_pull_page', 10, 1);
			(window as any).nv('user', userId, userAttributes.attributes);
		}
	}, []);

	const getLeadFlow = async () => {
		await dispatch(
			leadFlow({
				...leadSelector,
				needArrow: false,
				needCross: true,
				reSendCibilOtp: false,
			})
		);
		await dispatch(getLeadUmDetails({ param: leadSelector?.leadId }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setUmData(result?.data);
				}
			});
	};

	const handleSkipCibil = () => {
		if (
			leadSelector?.scoreType?.toLocaleLowerCase() ===
			LEAD_SCORE_TYPE.BOTH.toLocaleLowerCase()
		) {
			dispatch(
				leadFlow({
					...leadSelector,
					headerTitle: '',
					subHeaderStepsCounter: false,
					scoreType: LEAD_SCORE_TYPE.EQUIFAX,
					isNewLead: true,
					reSendCibilOtp: false,
				})
			);
			history.replace('/bureau-report');
		} else if (
			leadSelector?.scoreType?.toLocaleLowerCase() ===
			LEAD_SCORE_TYPE.CBIL.toLocaleLowerCase()
		) {
			dispatch(
				dashboardLeadGenerateOtp({
					data: {
						mobile: umData?.mobile_no
							? umData?.mobile_no
							: leadSelector?.leadMobile,
						reLogin: false,
					},
				})
			)
				.then(unwrapResult)
				.then(async (response: any) => {
					if (response?.code === STATUS_CODE.SIGNIN_SUCCESS) {
						dispatch(
							leadFlow({
								...leadSelector,
								step: 'step3',
								headerTitle: 'OTP Verification',
								scoreType: LEAD_SCORE_TYPE.EQUIFAX,
								isNewLead: true,
								reSendCibilOtp: false,
							})
						);
					}
				});
		} else if (
			leadSelector?.scoreType?.toLocaleLowerCase() ===
			LEAD_SCORE_TYPE.EQUIFAX.toLocaleLowerCase()
		) {
			dispatch(
				dashboardLeadGenerateOtp({
					data: { mobile: leadSelector?.leadMobile, reLogin: false },
				})
			)
				.then(unwrapResult)
				.then(async (response: any) => {
					if (response?.code === STATUS_CODE.SIGNIN_SUCCESS) {
						dispatch(
							leadFlow({
								...leadSelector,
								step: 'step3',
								headerTitle: 'OTP Verification',
								scoreType: LEAD_SCORE_TYPE.EQUIFAX,
								isNewLead: true,
								reSendCibilOtp: false,
							})
						);
					}
				});
		}
	};

	const handleGenerateReport = async () => {
		dispatch(
			cbilRequestConsentDetails({
				param: `${leadSelector?.leadId}`,
			})
		)
			.then(unwrapResult)
			.then(async (response: any) => {
				if (response.status) {
					if (response?.is_consent) {
						if (response?.is_otp) {
							history.replace('/bureau-report');
						} else {
							await dispatch(
								generateCibilOtp({
									data: {
										lead_id: leadSelector?.leadId,
										mobile_no: leadSelector?.leadMobile,
									},
								})
							)
								.then(unwrapResult)
								.then(async (cbilOtpResponse: any) => {
									if (cbilOtpResponse.status) {
										dispatch(
											leadFlow({
												...leadSelector,
												cbilResponse: {
													ChallengeConfigGUID:
														cbilOtpResponse?.ChallengeConfigGUID,
													answerKey: cbilOtpResponse?.answerKey,
													questionKey: cbilOtpResponse?.questionKey,
												},
												lead_consent_type: 'Lead',
												step: 'step6',
												headerTitle: '',
												subHeaderStepsCounter: false,
												needCibil: true,
												needCross: false,
												needArrow: true,
												reSendCibilOtp: false,
											})
										);
									}
								});
						}
					} else {
						// dispatch(
						// 	cbilRequestUpdateConsentDetails({
						// 		param: response?.pccId + '?consentVal=1',
						// 	})
						// )
						// 	.then(unwrapResult)
						// 	.then(async (cbilUpdateResponse: any) => {
						// 		if (cbilUpdateResponse?.status) {
						// 			dispatch(
						// 				leadBureauCustomer({
						// 					lead_type: 'Lead',
						// 				})
						// 			);
						// 			dispatch(
						// 				leadFlow({
						// 					...leadSelector,
						// 					lead_consent_type: 'Lead',
						// 					pccId: response?.pccId,
						// 					step: 'step5',
						// 					headerTitle: '',
						// 					subHeaderStepsCounter: false,
						// 					needCibil: true,
						// 					needCross: false,
						// 					needArrow: true,
						// 					isNewLead: true,
						// 				})
						// 			);
						// 		}
						// 	});
						dispatch(
							leadBureauCustomer({
								lead_type: 'Lead',
								reSendCibilOtp: false,
							})
						);
						dispatch(
							leadFlow({
								...leadSelector,
								lead_consent_type: 'Lead',
								pccId: response?.pccId,
								step: 'step5',
								headerTitle: '',
								subHeaderStepsCounter: false,
								needCibil: true,
								needCross: false,
								needArrow: true,
								isNewLead: true,
								reSendCibilOtp: false,
							})
						);
					}
				}
			});
	};
	return (
		<>
			<IonContent className=''>
				<div className='select-product small-container bg-web h-100'>
					<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
						<div className='d-flex align-items-center flex-column justify-content-center px-3 py-3'>
							<img
								src='/images/icons/leads/cibil-report-icon.svg'
								alt='Cibil Report Icon'
							/>
							<div className='fs-18 fw-600 darkest-grey text-center mt-3'>
								{t('Are you sure you want to generate CIBIL Report?')}
							</div>

							<div className='d-none d-md-block w-100'>
								<div className='py-5 flex flex-row-reverse'>
									<IonImg
										src='/images/icons/powered_by_cbil.jpg'
										style={{ width: '75px' }}
									/>
								</div>
								<CustomButton
									className={'btn-blue mb-3'}
									fill={'solid'}
									onClick={() => {
										handleSkipCibil();
										if (isPlatform('android') || isPlatform('ios')) {
											notifyHandler('event', eventAttributes('skip_cibil'));
											notifyHandler('user', userAttributes.attributes);
											const inAppData = {
												tokens: { name: userAttributes },
												customRules: {
													banerrType: 'skip cibil',
												},
											};
											NotifyVisitors.show(inAppData);
										} else {
											(window as any).nv('event', 'skip_cibil', 10, 1);
											(window as any).nv(
												'user',
												userId,
												userAttributes.attributes
											);
										}
									}}
									title={t('Skip CIBIL')}
									expand='block'
								/>
								<CustomButton
									className={'btn-blue'}
									expand='block'
									fill={'outline'}
									onClick={() => {
										handleGenerateReport();
										if (isPlatform('android') || isPlatform('ios')) {
											notifyHandler('event', eventAttributes('Request_cibil'));
											notifyHandler('user', userAttributes.attributes);
											const inAppData = {
												tokens: { name: userAttributes },
												customRules: {
													banerrType: 'request cibil',
												},
											};
											NotifyVisitors.show(inAppData);
										} else {
											(window as any).nv('event', 'Requrest_cibil', 10, 1);
											(window as any).nv(
												'user',
												userId,
												userAttributes.attributes
											);
										}
									}}
									title={t('Request Consent For CIBIL')}
								/>
							</div>
						</div>
					</div>
				</div>
			</IonContent>
			<IonFooter className='d-block d-md-none ion-padding bg-web-white '>
				<div className='pb-2 flex flex-row-reverse'>
					<IonImg
						src='/images/icons/powered_by_cbil.jpg'
						style={{ width: '75px' }}
					/>
				</div>
				<CustomButton
					className={'btn-blue mb-3'}
					fill={'solid'}
					onClick={handleSkipCibil}
					title={t('Skip CIBIL')}
					expand='block'
				/>
				<CustomButton
					className={'btn-blue'}
					expand='block'
					fill={'outline'}
					onClick={handleGenerateReport}
					title={t('Request Consent For CIBIL')}
				/>
			</IonFooter>
		</>
	);
};
