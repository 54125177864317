import { alphabetColor } from '../../../helpers/functions';

export const InitialLetter = ({ value, height, width, fontSize }: any) => {
	const randomColorss = Math.floor(Math.random() * 5) + 1;
	return (
		<div className='d-flex justify-content-center align-items-center position-relative'>
			<div
				style={{
					background: alphabetColor(randomColorss),
					opacity: 0.1,
					borderRadius: '50%',
					height: height,
					width: width,
				}}
			></div>
			<span
				style={{
					color: alphabetColor(randomColorss),
					fontSize: fontSize,
				}}
				className='position-absolute'
			>
				{value?.charAt(0).toUpperCase()}
			</span>
		</div>
	);
};
