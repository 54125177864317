import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import { IonItem, IonLabel, IonList, isPlatform } from '@ionic/react';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
	Camera,
	CameraDirection,
	CameraResultType,
	CameraSource,
} from '@capacitor/camera';
import { documentsKycSchema } from './KycValidationSchema';
import {
	capFirstLetter,
	checkFieldsValidToContinue,
	disableNumberArrows,
	getPictureDataByOcr,
	showToastAlert,
	urltoFile,
} from '../../../helpers/functions';
import { UploadImageCard } from './UploadImageCard';
import { useDispatch, useSelector } from 'react-redux';
import {
	aadhaarName,
	//imgUrlUpdate,
	kycBasicInfo,
	kycDocumnetUpload,
	kycDocumnetUploadStepTwo,
	kycStep,
	kycUserUploadImage,
} from '../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { REGEX } from '../../../helpers/constant';

// declare let mltext: any;
// // declare const mltext: any;

// interface OCRResults {
//     blocks: {
//         blockframe: Frame[];
//         blockpoints: Points[];
//         blocktext: string[];
//     };
//     lines: {
//         lineframe: Frame[];
//         linepoints: Points[];
//         linetext: string[];
//     };
//     words: {
//         wordframe: Frame[];
//         wordpoints: Points[];
//         wordtext: string[];
//     };
// }

// interface Points {
//     x1: number | string;
//     x2: number | string;
//     x3: number | string;
//     x4: number | string;
//     y1: number | string;
//     y2: number | string;
//     y3: number | string;
//     y4: number | string;
// }

// interface Frame {
//     x: number | string;
//     y: number | string;
//     height: number | string;
//     width: number | string;
// }

export const DocumentsKyc = ({ setCurrentStep }: any) => {
	const dmaSelector = useSelector((state: any) => state?.users);
	const isBrowser = !isPlatform('capacitor');
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	const [documentsSchema, setDocumentsSchema] =
		useState<any>(documentsKycSchema);
	const [openUploadModal, setOpenUploadModal] = useState(false);
	const [imagePreviewUpload, setImagePreviewUpload] = useState({
		panImage: false,
		Aadhaar: false,
		aadhaarBack: false,
		photo: false,
		gstCertificate: false,
	});
	const [docTypeErrorMessage, setDocTypeErrorMessage] = useState({
		panTypeError: '',
		AadhaarTypeError: '',
		aadhaarBackTypeError: '',
		photoTypeError: '',
		gstCertificateTypeError: '',
	});
	const [clickImageFieldName, setClickImageFieldName] = useState<any>(null);
	const [docImages, setDocImages] = useState<any>({
		panUrl: '',
		aadhaarUrl: '',
		aadhaarUrl2: '',
		photoUrl: '',
		gstUrl: '',
	});

	const [getData, setData] = useState<any>({});
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history: any = useHistory();
	const [panValidation, setPanValidation] = useState<any>({
		status: '',
		msg: '',
	});
	let continueLater = false;
	const [registeredAs, setRegisteredAs] = useState('');
	const [panWriteable, setPanWriteable] = useState(true);

	// react hooks form start
	const {
		handleSubmit,
		control,
		trigger,
		getValues,
		reset,
		watch,
		setValue,
		formState: { errors },
	}: any = useForm({
		resolver: yupResolver(documentsSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			Pan: {
				Type: 'Pan',
				Url: '',
				Data: { No: '' },
				status: 'added',
			},
			Aadhaar: {
				Type: 'Aadhaar',
				Url: '',
				Url2: '',
				Data: { No: '', Name: '' },
				status: 'added',
			},
			Photo: {
				Type: 'Photo',
				Url: '',
				Data: '',
				status: 'added',
			},
			GST: {
				Type: 'GST',
				Url: '',
				Data: { No: '' },
				status: 'added',
			},
		},
	});

	useEffect(() => {
		getUpdateUrl();
	}, [getData]);

	useEffect(() => {
		if (location.pathname !== '/kyc') {
			return;
		}
		fetchDocumentsKyc();
	}, [dmaSelector?.user_details?.id, location.pathname]);

	useEffect(() => {
		let schemaBuilder = null;
		// if (
		// 	(getValues('GST.Url')?.length || getValues('GST.Data.No')?.length) &&
		// 	registeredAs !== 'firm'
		// ) {
		// 	schemaBuilder = documentsKycSchema.concat(
		// 		yup.object().shape({
		// 			GST: yup.object().shape({
		// 				Url: yup.string().required('GST Certificate Required.'),
		// 				Data: yup.object().shape({
		// 					No: yup
		// 						.string()
		// 						.required('GST No. is required')
		// 						.matches(REGEX.GST, {
		// 							message: 'GST No. Should Be 22AAAAA0000A1Z5 Format.',
		// 							excludeEmptyString: true,
		// 						}),
		// 				}),
		// 			}),
		// 		})
		// 	);
		// 	setDocumentsSchema(schemaBuilder);
		// 	trigger('GST.Url');
		// 	trigger('GST.Data.No');
		// } else
		if (registeredAs !== 'firm') {
			schemaBuilder = documentsSchema?.fields;
			delete schemaBuilder.GST;
			setDocumentsSchema(
				documentsKycSchema.concat(
					yup.object().shape({
						...schemaBuilder,
					})
				)
			);
			trigger('GST.Url');
			trigger('GST.Data.No');
		}
	}, [watch('GST.Url'), watch('GST.Data.No')]);

	const getUpdateUrl = async () => {
		if (docImages?.panUrl) {
			setDocImages({ panUrl: docImages?.panUrl, ...docImages });
			setValue('Pan.Url', docImages?.panUrl);
			/*dispatch(
				imgUrlUpdate({
					data: {
						url: docImages?.panUrl,
						expire_time: 50000,
					},
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						setDocImages({ panUrl: response?.url, ...docImages });
						setValue('Pan.Url', response?.url);
					}
				});*/
		}
		if (docImages?.aadhaarUrl) {
			setDocImages({ aadhaarUrl: docImages?.aadhaarUrl, ...docImages });
			setValue('Aadhaar.Url', docImages?.aadhaarUrl);
			/*dispatch(
				imgUrlUpdate({
					data: {
						url: docImages?.aadhaarUrl,
						expire_time: 50000,
					},
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						setDocImages({ aadhaarUrl: response?.url, ...docImages });
						setValue('Aadhaar.Url', response?.url);
					}
				});*/
		}
		if (docImages?.aadhaarUrl2) {
			setDocImages({ aadhaarUrl2: docImages?.aadhaarUrl2, ...docImages });
			setValue('Aadhaar.Url2', docImages?.aadhaarUrl2);
			/*dispatch(
				imgUrlUpdate({
					data: {
						url: docImages?.aadhaarUrl,
						expire_time: 50000,
					},
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						setDocImages({ aadhaarUrl2: response?.url, ...docImages });
						setValue('Aadhaar.Url2', response?.url);
					}
				});*/
		}
		if (docImages?.photoUrl) {
			setDocImages({ photoUrl: docImages?.photoUrl, ...docImages });
			setValue('Photo.Url', docImages?.photoUrl);
			/*dispatch(
				imgUrlUpdate({
					data: {
						url: docImages?.aadhaarUrl,
						expire_time: 50000,
					},
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						setDocImages({ photoUrl: response?.url, ...docImages });
						setValue('Photo.Url', response?.url);
					}
				});*/
		}
		if (docImages?.gstUrl) {
			setDocImages({ gstUrl: docImages?.gstUrl, ...docImages });
			setValue('GST.Url', docImages?.gstUrl);
			/*dispatch(
				imgUrlUpdate({
					data: {
						url: docImages?.gstUrl,
						expire_time: 50000,
					},
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						setDocImages({ gstUrl: response?.url, ...docImages });
						setValue('GST.Url', response?.url);
					}
				});*/
		}
	};

	const ShareModal = () => {
		const handleUploadChange = async (
			type: string,
			fieldName: string,
			e: any
		) => {
			setOpenUploadModal(false);
			const formData = new FormData();
			// let result: any;
			let image: any;
			let getOcrData: any;
			let getImageFileObject: any;
			setDocTypeErrorMessage({
				panTypeError: '',
				AadhaarTypeError: '',
				aadhaarBackTypeError: '',
				photoTypeError: '',
				gstCertificateTypeError: '',
			});
			switch (type) {
				case 'file':
					if (
						e.target.files[0].type === 'image/png' ||
						e.target.files[0].type === 'image/jpeg' ||
						(e.target.files[0].type === 'application/pdf' &&
							!fieldName?.includes('Photo.Url'))
					) {
						formData.append('profile', e.target.files[0]);
						// setDocTypeErrorMessage({
						// 	...docTypeErrorMessage,
						// 	panTypeError: '',
						// });
						dispatch(kycUserUploadImage({ data: formData }))
							.then(unwrapResult)
							.then((response: any) => {
								if (response?.status) {
									setValue(`${fieldName}`, response?.url);
									switch (fieldName) {
										case 'Pan.Url':
											setDocTypeErrorMessage({
												...docTypeErrorMessage,
												panTypeError: '',
											});
											setImagePreviewUpload({
												...imagePreviewUpload,
												panImage: true,
											});
											break;
										case 'Aadhaar.Url':
											setDocTypeErrorMessage({
												...docTypeErrorMessage,
												AadhaarTypeError: '',
											});
											setImagePreviewUpload({
												...imagePreviewUpload,
												Aadhaar: true,
											});
											break;
										case 'Aadhaar.Url2':
											setDocTypeErrorMessage({
												...docTypeErrorMessage,
												aadhaarBackTypeError: '',
											});
											setImagePreviewUpload({
												...imagePreviewUpload,
												aadhaarBack: true,
											});
											break;
										case 'Photo.Url':
											setDocTypeErrorMessage({
												...docTypeErrorMessage,
												photoTypeError: '',
											});
											setImagePreviewUpload({
												...imagePreviewUpload,
												photo: true,
											});
											break;
										case 'GST.Url':
											setDocTypeErrorMessage({
												...docTypeErrorMessage,
												gstCertificateTypeError: '',
											});
											setImagePreviewUpload({
												...imagePreviewUpload,
												gstCertificate: true,
											});
											break;
									}
								}
							});
						break;
					} else {
						switch (fieldName) {
							case 'Pan.Url':
								setDocTypeErrorMessage({
									...docTypeErrorMessage,
									panTypeError:
										'Uploaded file is invalid. Only JPG/JPEG, PNG and PDF files are allowed.',
								});
								break;
							case 'Aadhaar.Url':
								setDocTypeErrorMessage({
									...docTypeErrorMessage,
									AadhaarTypeError:
										'Uploaded file is invalid. Only JPG/JPEG, PNG and PDF files are allowed.',
								});
								break;
							case 'Aadhaar.Url2':
								setDocTypeErrorMessage({
									...docTypeErrorMessage,
									aadhaarBackTypeError:
										'Uploaded file is invalid. Only JPG/JPEG, PNG and PDF files are allowed.',
								});
								break;
							case 'Photo.Url':
								setDocTypeErrorMessage({
									...docTypeErrorMessage,
									photoTypeError:
										'Uploaded file is invalid. Only JPG/JPEG and PNG files are allowed.',
								});
								break;
							case 'GST.Url':
								setDocTypeErrorMessage({
									...docTypeErrorMessage,
									gstCertificateTypeError:
										'Uploaded file is invalid. Only JPG/JPEG, PNG and PDF files are allowed.',
								});
								break;
						}
						break;
					}

				case 'photo':
					image = await Camera.getPhoto({
						quality: 50,
						allowEditing: false,
						resultType: CameraResultType.Uri,
						source: CameraSource.Photos,
					});

					getImageFileObject = await urltoFile(
						image.webPath,
						`${Math.random() * 100000000000000000}.${image.format}`,
						image.format
					);
					if (
						!['Photo', 'GST'].includes(fieldName.split('.')[0]) &&
						!['Aadhaar.Url2'].includes(fieldName)
					) {
						getOcrData = await getPictureDataByOcr(
							'imageWithData',
							image.path,
							image.webPath,
							fieldName.split('.')[0].toLocaleLowerCase()
						);

						if (
							!['not found', 'notfound'].includes(
								getOcrData.processedData.toLowerCase()
							)
						) {
							if (fieldName.split('.')[0] === 'Pan') {
								if (getValues('Pan.Data.No') !== '') {
									if (getValues('Pan.Data.No') === getOcrData.processedData) {
										setPanValidation({
											status: true,
											msg: 'PAN details verified.',
										});
									} else {
										setPanValidation({
											status: false,
											msg: 'PAN details not verified.',
										});
									}
								} else {
									setValue(
										fieldName.split('.')[0].concat('.Data.No'),
										getOcrData.processedData
									);
								}
							} else {
								setValue(
									fieldName.split('.')[0].concat('.Data.No'),
									getOcrData.processedData
								);
							}
						} else {
							if (fieldName.split('.')[0] === 'Pan') {
								setPanValidation({
									status: false,
									msg: 'PAN details not verified.',
								});
							}
						}
					}
					formData.append('profile', getImageFileObject);
					dispatch(kycUserUploadImage({ data: formData }))
						.then(unwrapResult)
						.then((response: any) => {
							if (response?.status) {
								setValue(`${fieldName}`, response?.url);
								switch (fieldName) {
									case 'Pan.Url':
										setImagePreviewUpload({
											...imagePreviewUpload,
											panImage: true,
										});
										break;
									case 'Aadhaar.Url':
										setImagePreviewUpload({
											...imagePreviewUpload,
											Aadhaar: true,
										});
										break;
									case 'Aadhaar.Url2':
										setImagePreviewUpload({
											...imagePreviewUpload,
											aadhaarBack: true,
										});
										break;
									case 'Photo.Url':
										setImagePreviewUpload({
											...imagePreviewUpload,
											photo: true,
										});
										break;
									case 'GST.Url':
										setImagePreviewUpload({
											...imagePreviewUpload,
											gstCertificate: true,
										});
										break;
								}
							}
						});

					break;
				case 'camera':
					image = await Camera.getPhoto({
						quality: 50,
						allowEditing: false,
						resultType: CameraResultType.Uri,
						source: CameraSource.Camera,
						direction: clickImageFieldName?.includes('Photo.Url')
							? CameraDirection.Front
							: CameraDirection.Rear,
					});
					getImageFileObject = await urltoFile(
						image.webPath,
						`${Math.random() * 100000000000000000}.${image.format}`,
						image.format
					);
					if (
						!['Photo', 'GST'].includes(fieldName.split('.')[0]) &&
						!['Aadhaar.Url2'].includes(fieldName)
					) {
						getOcrData = await getPictureDataByOcr(
							'imageWithData',
							image.path,
							image.webPath,
							fieldName.split('.')[0].toLocaleLowerCase()
						);

						if (
							!['not found', 'notfound'].includes(
								getOcrData.processedData.toLowerCase()
							)
						) {
							if (fieldName.split('.')[0] === 'Pan') {
								if (getValues('Pan.Data.No') !== '') {
									if (getValues('Pan.Data.No') === getOcrData.processedData) {
										setPanValidation({
											status: true,
											msg: 'PAN details verified.',
										});
									} else {
										setPanValidation({
											status: false,
											msg: 'PAN details not verified.',
										});
									}
								} else {
									setValue(
										fieldName.split('.')[0].concat('.Data.No'),
										getOcrData.processedData
									);
								}
							} else {
								setValue(
									fieldName.split('.')[0].concat('.Data.No'),
									getOcrData.processedData
								);
							}
						} else {
							if (fieldName.split('.')[0] === 'Pan') {
								setPanValidation({
									status: false,
									msg: 'PAN details not verified.',
								});
							}
						}
					}
					formData.append('profile', getImageFileObject);

					dispatch(kycUserUploadImage({ data: formData }))
						.then(unwrapResult)
						.then((response: any) => {
							if (response?.status) {
								setValue(`${fieldName}`, response?.url);
								switch (fieldName) {
									case 'Pan.Url':
										setImagePreviewUpload({
											...imagePreviewUpload,
											panImage: true,
										});
										break;
									case 'Aadhaar.Url':
										setImagePreviewUpload({
											...imagePreviewUpload,
											Aadhaar: true,
										});
										break;
									case 'Aadhaar.Url2':
										setImagePreviewUpload({
											...imagePreviewUpload,
											aadhaarBack: true,
										});
										break;
									case 'Photo.Url':
										setImagePreviewUpload({
											...imagePreviewUpload,
											photo: true,
										});
										break;
									case 'GST.Url':
										setImagePreviewUpload({
											...imagePreviewUpload,
											gstCertificate: true,
										});
										break;
								}
							}
						});

					break;
				default:
					break;
			}
		};
		return (
			<>
				<IonList>
					{!isBrowser && (
						<IonItem lines='full'>
							<img
								src='/images/icons/kyc/Image-upload.svg'
								alt='Upload Photo'
							/>
							<IonLabel>
								<h2
									className='fs-16 ps-3 text-dark'
									onClick={(e) => {
										handleUploadChange('photo', clickImageFieldName, e);
									}}
								>
									{t('Upload Photo')}
								</h2>
							</IonLabel>
						</IonItem>
					)}
					<IonItem
						lines='full'
						className='cursor-pointer'
					>
						<img
							src='/images/icons/kyc/upload-image-icon.svg'
							alt='Upload Photo'
						/>
						<IonLabel>
							<label
								htmlFor='upload-photo'
								className='cursor-pointer'
							>
								<h2 className='fs-16 ps-3 text-dark ion-text-wrap'>
									{t('Upload Document')}
								</h2>
							</label>
							<input
								className='d-none'
								accept='image/png, image/jpeg ,application/pdf'
								type='file'
								id='upload-photo'
								onChange={(e) => {
									handleUploadChange('file', clickImageFieldName, e);
								}}
							/>
						</IonLabel>
					</IonItem>
					{!isBrowser && (
						<IonItem lines='full'>
							<img
								src='/images/icons/kyc/camera-icon.svg'
								alt='Upload Photo'
							/>
							<IonLabel>
								<h2
									className='fs-16 ps-3 text-dark'
									onClick={(e) => {
										handleUploadChange('camera', clickImageFieldName, e);
									}}
								>
									Take Photo
								</h2>
							</IonLabel>
						</IonItem>
					)}
				</IonList>
			</>
		);
	};

	const onSubmit = async (data: any) => {
		const ObjectData: any = [];

		data.Pan.Data.No = data?.Pan?.Data?.No.toUpperCase();
		data.GST.Data.No = data?.GST?.Data?.No
			? data?.GST?.Data?.No.toUpperCase()
			: '';
		data.Aadhaar.Data.Name = data?.Aadhaar?.Data?.Name
			? capFirstLetter(data?.Aadhaar?.Data?.Name)
			: '';
		data.GST.Data.is_added =
			data?.GST?.Data?.No?.length && data?.GST?.Url?.length ? 1 : 0;

		let id: any;
		if (dmaSelector?.dmaFlow?.dmaId) {
			id = dmaSelector?.dmaFlow?.dmaId;
		} else {
			id = dmaSelector?.user_details?.id;
		}

		const gstDataPreserve = data?.GST?.Data;

		delete data?.GST?.Data;
		// send only gst data
		dispatch(
			kycDocumnetUploadStepTwo({
				param: '/' + id,
				data: {
					data: { ...gstDataPreserve, ...data?.GST },
					continueLater: continueLater,
				},
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					// removed gst object
					delete data?.GST;
					Object.keys(data).forEach((key) => {
						ObjectData.push(data[key]);
					});
					dispatch(
						kycDocumnetUpload({
							param: '/' + id,
							data: {
								document: ObjectData,
								continueLater: continueLater,
							},
						})
					)
						.then(unwrapResult)
						.then((document_upload_response: any) => {
							if (document_upload_response?.status) {
								if (!continueLater) {
									setCurrentStep('step3');
									dispatch(kycStep('KYC3'));
									dispatch(aadhaarName(data['Aadhaar']?.Data?.Name));
								} else {
									continueLater = false;
									if (dmaSelector?.dmaFlow?.dmaId) {
										history.replace('/dashboard/teams');
									} else {
										history.replace('/dashboard/home');
									}
								}
							}
						});
				}
			});
	};

	const fetchDocumentsKyc = async () => {
		let id;
		if (dmaSelector?.dmaFlow?.dmaId) {
			id = dmaSelector?.dmaFlow?.dmaId;
		} else {
			id = dmaSelector?.user_details?.id;
		}

		dispatch(kycBasicInfo({ param: id + '/step2' }))
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					if (response?.data[0]?.Pan?.No) {
						setPanWriteable(true);
					} else {
						setPanWriteable(false);
					}
					const data: any = response?.data[0];
					setRegisteredAs(response?.data[0]?.RegisteredAs);
					// if (response?.data[0]?.RegisteredAs === 'firm') {
					// 	const newGstSchema = documentsKycSchema.concat(
					// 		yup.object().shape({
					// 			GST: yup.object().shape({
					// 				Url: yup.string().required('GST Certificate Required.'),
					// 				Data: yup.object().shape({
					// 					No: yup
					// 						.string()
					// 						.required('GST No. is required')
					// 						.matches(REGEX.GST, {
					// 							message: 'GST No. Should Be 22AAAAA0000A1Z5 Format.',
					// 							excludeEmptyString: true,
					// 						}),
					// 				}),
					// 			}),
					// 		})
					// 	);
					// 	setDocumentsSchema(newGstSchema);
					// }
					setData(response?.data[0]);
					setDocImages({
						panUrl: data?.Pan?.Url,
						aadhaarUrl: data?.Aadhaar?.Url,
						aadhaarUrl2: data?.Aadhaar?.Url2,
						photoUrl: data?.Photo?.profile_url,
						gstUrl: data?.GST?.Url,
					});
					reset({
						Pan: {
							Type: 'Pan',
							Url: docImages?.panUrl,
							Data: { No: data?.Pan?.No },
							status: 'added',
						},
						Aadhaar: {
							Type: 'Aadhaar',
							Url: docImages?.aadhaarUrl,
							Url2: docImages?.aadhaarUrl2,
							Data: { No: data?.Aadhaar?.No, Name: data?.Aadhaar?.Name },
							status: 'added',
						},
						Photo: {
							Type: 'Photo',
							Url: docImages?.photoUrl,
							Data: '',
							status: 'added',
						},
						GST: {
							Type: 'GST',
							Url: docImages?.gstUrl,
							Data: { No: data?.GST?.No },
							status: 'added',
						},
					});

					setImagePreviewUpload({
						panImage: data?.Pan?.Url ? true : false,
						Aadhaar: data?.Aadhaar?.Url ? true : false,
						aadhaarBack: data?.Aadhaar?.Url2 ? true : false,
						photo: data?.Photo?.profile_url ? true : false,
						gstCertificate: data?.GST?.Url ? true : false,
					});
				}
			});
	};
	return (
		<section className='overflowX-hidden kyc-content-height'>
			<form
				onSubmit={handleSubmit(onSubmit)}
				encType='multipart/form-data'
			>
				<div
					className='ion-padding conatiner-spacing-md'
					style={{ borderBottom: '1px solid grey' }}
				>
					{/* PAN */}
					<h3 className='m-0 fs-bold fs-20 pb-2'>{t('PAN')}</h3>
					<div>
						<h4 className='m-0 text-grey fs-14 pb-2'>
							{t('Upload PAN Card') + ' *'}
						</h4>

						<UploadImageCard
							{...{
								setImagePreviewUpload,
								imagePreviewUpload,
								isImageAvilable: imagePreviewUpload?.panImage,
								imgKey: 'panImage',
								setClickImageFieldName,
								setOpenUploadModal,
								setValue: setValue,
								value: 'Pan.Url',
								title: t('Upload PAN'),
								src: getValues('Pan.Url'),
							}}
						/>
						{!imagePreviewUpload.panImage &&
						!docTypeErrorMessage.panTypeError &&
						errors?.Pan?.Url?.message ? (
							<div className=''>
								<div className='error-text sc-ion-input-md fs-12'>
									{t(errors?.Pan?.Url?.message)}
								</div>
							</div>
						) : (
							docTypeErrorMessage.panTypeError && (
								<div className=''>
									<div className='error-text sc-ion-input-md fs-12 pb-2'>
										{t(docTypeErrorMessage.panTypeError)}
									</div>
								</div>
							)
						)}
						{/* {imagePreviewUpload?.panImage ? (
							<div
								className='fs-10'
								style={{ color: '#00B715' }}
							>
								Pan details verified.
							</div>
						) : (
							''
						)} */}
						{panValidation.msg && (
							<div
								className={`my-2 fs-12 d-flex align-items-center ${
									panValidation.status
										? 'secondary-text-color'
										: 'danger-text-color'
								}`}
							>
								<img
									src={
										panValidation.status
											? 'images/icons/green-info.svg'
											: 'images/icons/red-info.svg'
									}
									className='me-2'
									width={16}
									height={16}
									alt=''
								/>
								{panValidation.msg}
							</div>
						)}
					</div>
					<div className=''>
						<Controller
							control={control}
							render={({ field: { onChange, onBlur, name } }) => (
								<CustomInput
									type='text'
									placeholder={t('PAN No.') + ' *'}
									onBlur={onBlur}
									name={name}
									disabled={panWriteable}
									value={getValues('Pan.Data.No')}
									onChange={onChange}
									errors={t(errors?.Pan?.Data?.No?.message)}
									className='text-uppercase'
								/>
							)}
							name='Pan.Data.No'
						/>
					</div>
				</div>
				<div
					className='ion-padding conatiner-spacing-md'
					style={{ borderBottom: '1px solid grey' }}
				>
					{/* Aadhaar */}
					<h3 className='m-0 fs-bold fs-20 pb-2'>{t('Aadhaar')}</h3>
					<div>
						<h4 className='m-0 text-grey fs-14 pb-2'>
							{t('Upload Aadhaar Card') + ' *'}
						</h4>
						<UploadImageCard
							{...{
								setImagePreviewUpload,
								imagePreviewUpload,
								isImageAvilable: imagePreviewUpload.Aadhaar,
								setClickImageFieldName,
								setOpenUploadModal,
								setValue: setValue,
								imgKey: 'Aadhaar',
								value: 'Aadhaar.Url',
								title: t('Front View'),
								src: getValues('Aadhaar.Url'),
							}}
						/>
						{!imagePreviewUpload.Aadhaar &&
						!docTypeErrorMessage.AadhaarTypeError &&
						errors?.Aadhaar?.Url?.message ? (
							<div className=''>
								<div className='error-text sc-ion-input-md fs-12'>
									{t(errors?.Aadhaar?.Url?.message)}
								</div>
							</div>
						) : (
							docTypeErrorMessage.AadhaarTypeError && (
								<div className=''>
									<div className='error-text sc-ion-input-md fs-12'>
										{t(docTypeErrorMessage.AadhaarTypeError)}
									</div>
								</div>
							)
						)}
						<UploadImageCard
							{...{
								setImagePreviewUpload,
								imagePreviewUpload,
								isImageAvilable: imagePreviewUpload.aadhaarBack,
								setClickImageFieldName,
								setOpenUploadModal,
								imgKey: 'aadhaarBack',
								setValue: setValue,
								value: 'Aadhaar.Url2',
								title: t('Back View'),
								src: getValues('Aadhaar.Url2'),
							}}
						/>
						{!imagePreviewUpload.aadhaarBack &&
						!docTypeErrorMessage.aadhaarBackTypeError &&
						errors?.Aadhaar?.Url2?.message ? (
							<div className=''>
								<div className='error-text sc-ion-input-md fs-12'>
									{t(errors?.Aadhaar?.Url2?.message)}
								</div>
							</div>
						) : (
							docTypeErrorMessage.aadhaarBackTypeError && (
								<div className=''>
									<div className='error-text sc-ion-input-md fs-12'>
										{t(docTypeErrorMessage.aadhaarBackTypeError)}
									</div>
								</div>
							)
						)}
					</div>
					<div className='mt-2'>
						<Controller
							control={control}
							render={({ field: { onChange, onBlur } }) => (
								<CustomInput
									type='number'
									placeholder={t('Aadhaar Number *')}
									onBlur={onBlur}
									onChange={onChange}
									value={getValues('Aadhaar.Data.No')}
									errors={t(errors?.Aadhaar?.Data?.No?.message)}
									onBeforeInput={(evt: any) => {
										//Only numbers accepted on user input
										!/[0-9]/.test(evt.data) && evt.preventDefault();
										//Only accepts 12 characters on user input
										if (evt.target.value.length === 12) {
											evt.preventDefault();
										}
									}}
									onPaste={(e: any) => {
										//Only get numbers on user paste
										e.preventDefault();
										let pasteContent = e.clipboardData.getData('Text');
										if (pasteContent != '') {
											pasteContent = pasteContent.replace(/\D/g, '');
											if (pasteContent.length > 10) {
												pasteContent = pasteContent.slice(0, 12);
											}
										}
										setValue('Aadhaar.Data.No', pasteContent);
										trigger('Aadhaar.Data.No');
									}}
									onKeyDown={(e: any) => {
										disableNumberArrows(e);
									}}
									onWheel={(e: any) => e.target.blur()}
								/>
							)}
							name='Aadhaar.Data.No'
						/>
					</div>
					<div className=''>
						<Controller
							control={control}
							render={({ field: { onChange, onBlur } }) => (
								<CustomInput
									type='text'
									placeholder={t('Full Name (as per Aadhaar)') + '*'}
									onBlur={onBlur}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										e.target.value =
											e.target.value.length <= 50
												? e.target.value
												: e.target.value.substring(0, 50);
										onChange(e);
									}}
									value={getValues('Aadhaar.Data.Name')}
									errors={t(errors?.Aadhaar?.Data?.Name?.message)}
									className='text-capitalize'
								/>
							)}
							name='Aadhaar.Data.Name'
						/>
					</div>
				</div>
				<div
					className='ion-padding  conatiner-spacing-md'
					style={{ borderBottom: '1px solid grey' }}
				>
					{/* Passport Size Photo */}
					<h3 className='m-0 fs-bold fs-20 pb-3'>
						{t('Passport Size Photo') + ' *'}
					</h3>
					<UploadImageCard
						{...{
							setImagePreviewUpload,
							imagePreviewUpload,
							isImageAvilable: imagePreviewUpload.photo,
							setClickImageFieldName,
							setOpenUploadModal,
							imgKey: 'photo',
							setValue: setValue,
							value: 'Photo.Url',
							title: t('Upload Photo'),
							src: getValues('Photo.Url'),
						}}
					/>
					{!imagePreviewUpload.photo &&
					!docTypeErrorMessage.photoTypeError &&
					errors?.Photo?.Url?.message ? (
						<div className=''>
							<div className='error-text sc-ion-input-md fs-12'>
								{t(errors?.Photo?.Url?.message)}
							</div>
						</div>
					) : (
						docTypeErrorMessage.photoTypeError && (
							<div className=''>
								<div className='error-text sc-ion-input-md fs-12'>
									{t(docTypeErrorMessage.photoTypeError)}
								</div>
							</div>
						)
					)}
				</div>
				<div
					className='ion-padding mb-md-5 conatiner-spacing-md'
					style={{}}
				>
					{/* GST Certificate */}
					<h3 className='m-0 fs-bold fs-20 pb-3'>
						{t('GST Certificate')}{' '}
						{documentsSchema?.fields['GST'] !== undefined ? '*' : ''}
					</h3>
					<UploadImageCard
						{...{
							setImagePreviewUpload,
							imagePreviewUpload,
							isImageAvilable: imagePreviewUpload.gstCertificate,
							setClickImageFieldName,
							setOpenUploadModal,
							imgKey: 'gstCertificate',
							setValue: setValue,
							value: 'GST.Url',
							title: t('Upload GST Certificate'),
							src: getValues('GST.Url'),
						}}
					/>
					{!imagePreviewUpload.gstCertificate &&
					!docTypeErrorMessage.gstCertificateTypeError &&
					errors?.GST?.Url?.message ? (
						<div className=''>
							<div className='error-text sc-ion-input-md fs-12'>
								{t(errors?.GST?.Url?.message)}
							</div>
						</div>
					) : (
						docTypeErrorMessage.gstCertificateTypeError && (
							<div className=''>
								<div className='error-text sc-ion-input-md fs-12 pb-2'>
									{t(docTypeErrorMessage.gstCertificateTypeError)}
								</div>
							</div>
						)
					)}
					<div className='mt-2'>
						<Controller
							control={control}
							render={({ field: { onChange, onBlur, name } }) => (
								<CustomInput
									type='text'
									placeholder={
										documentsSchema?.fields['GST'] !== undefined
											? t('GST Certificate Number') + ' *'
											: t('GST Certificate Number')
									}
									name={name}
									onBlur={onBlur}
									onChange={onChange}
									value={getValues('GST.Data.No')}
									errors={t(errors?.GST?.Data?.No?.message)}
									className='text-uppercase'
								/>
							)}
							name='GST.Data.No'
						/>
					</div>
				</div>
				{/* submit button */}
				<div
					className='position-fixed-md w-100 bottom-0 start-0 conatiner-spacing-md pb-3 pt-md-3'
					style={{ backgroundColor: '#fff', zIndex: 9 }}
				>
					<CustomButton
						className='w-100 fw-bold dashboard-add-lead-button mb-3'
						expand='block'
						fill='outline'
						title={t('Continue Later')}
						onClick={() => {
							if (checkFieldsValidToContinue(errors)) {
								continueLater = true;
								onSubmit(getValues());
							} else {
								showToastAlert({
									type: 'error',
									message: 'Please fix above errors',
								});
							}
						}}
					/>
					<CustomButton
						className='btn-blue'
						type='submit'
						expand='block'
						title={t('Continue')}
						// disabled={!isValid}
					/>
				</div>
			</form>

			{/* Upload Photo Modal */}
			<CustomModal
				id='sharemodal'
				isOpen={openUploadModal}
				initialBreakpoint={isWeb ? 1 : 0.3}
				handleClose={() => setOpenUploadModal(false)}
				breakpoints={[0, 0.3, 0.5, 0.75]}
				modalContent={<ShareModal />}
				needCross={false}
				needArrow={false}
				className={`side-modal ${isWeb ? '' : 'modal-border-radius'}`}
			/>
		</section>
	);
};
