/* eslint-disable @typescript-eslint/no-explicit-any */
import { Header } from '../../components/layout/header/Header';
import { useEffect, useRef, useState } from 'react';
import { informationCircle } from 'ionicons/icons';

import './Products.scss';
import { useHistory } from 'react-router';
import {
	IonAccordion,
	IonAccordionGroup,
	IonContent,
	IonIcon,
	IonItem,
	IonLabel,
	IonPage,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

export const ProductDetail: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch: any = useDispatch();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [categoryList, setCategoryList] = useState<any>();
	const productSelector = useSelector(
		(state: any) => state?.users?.productFlow
	);

	const [viewBtn, setViewBtn] = useState<any>('View More');

	useEffect(() => {
		getProductList();
	}, []);

	const getProductList = async () => {
		await dispatch(getProductList())
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setCategoryList(response?.product_list[productSelector?.id]);
				}
			});
	};
	const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);
	const toggleAccordion = () => {
		if (!accordionGroup.current) {
			return;
		}
		const nativeEl = accordionGroup.current;

		if (nativeEl.value === 'show') {
			nativeEl.value = undefined;
			setViewBtn('View More');
		} else {
			nativeEl.value = 'show';
			setViewBtn('View Less');
		}
	};
	return (
		<IonPage className='lead-list'>
			<Header
				name=''
				type=''
				needArrow={true}
				backButton={true}
			/>
			<IonContent
				style={{
					borderTop: '1px solid #00000029',
					height: 'calc(100vh - 113px)',
					overflowX: 'hidden',
				}}
			>
				<div className='banner'>
					<div className='position-absolute data'>
						<h5 className='text-dark fs-20 fs-600'>Home Loan</h5>
						<p className='fs-12'>
							{t('that make you feel home, starting at')} 8.35% p.a
						</p>
					</div>
				</div>
				<div className='ion-padding'>
					<IonItem
						routerLink='/financial-tools'
						lines='none'
						className='border border-1'
						href='#'
					>
						<IonIcon
							icon={informationCircle}
							slot='start'
						></IonIcon>
						<IonLabel>{t('Financial Tools')}</IonLabel>
					</IonItem>
				</div>
				<hr />
				<div className=''>
					<div className='d-flex ion-padding justify-content-between align-items-center'>
						<p className='mb-0 fs-16 fw-600'>{t('All Lenders')}</p>
						<p
							onClick={() => history.push('/product-lenders')}
							className='mb-0 fs-14 fw-600'
						>
							{t('View All')}
						</p>
					</div>
					<div className='mt-3 lenders'>
						<IonItem
							lines='none'
							className='bg-transparent  ion-no-padding'
							detail={true}
						>
							<IonLabel className='bg'>
								<div className='d-flex gap-2'>
									<img
										alt='Product'
										src='https://ionicframework.com/docs/img/demos/thumbnail.svg'
										className='product-img '
									/>

									<div>
										<h3>SBI Home Loans</h3>
										<p className='mb-0'>{t('Interest Rate')} 11% to 15%</p>
									</div>
								</div>
							</IonLabel>
						</IonItem>
					</div>
					<div className='mt-3 lenders'>
						<IonItem
							lines='none'
							className='bg-transparent  ion-no-padding'
							detail={true}
						>
							<IonLabel className='bg-transparent'>
								<div className='d-flex gap-2'>
									<img
										alt='Product'
										src='https://ionicframework.com/docs/img/demos/thumbnail.svg'
										className='product-img '
									/>

									<div>
										<h3>SBI Home Loans</h3>
										<p className='mb-0'>{t('Interest Rate')} 11% to 15%</p>
									</div>
								</div>
							</IonLabel>
						</IonItem>
					</div>
					<div className='mt-3 lenders'>
						<IonItem
							lines='none'
							className='bg-transparent  ion-no-padding'
							detail={true}
						>
							<IonLabel className='bg-transparent'>
								<div className='d-flex gap-2'>
									<img
										alt='Product'
										src='https://ionicframework.com/docs/img/demos/thumbnail.svg'
										className='product-img '
									/>

									<div>
										<h3>SBI Home Loans</h3>
										<p className='mb-0'>{t('Interest Rate')} 11% to 15%</p>
									</div>
								</div>
							</IonLabel>
						</IonItem>
					</div>
				</div>
				<div className='eligibility-wrapper ion-padding mt-5'>
					<h4 className='fs-20 fw-600'>{t('Eligibility')}</h4>
					<div className='pt-3 pe-3 d-flex align-items-center w-100 overflow-auto text-nowrap'>
						<div className={`tab-chip fs-14 d-flex gap-3`}>
							<img
								alt='Product'
								src='https://ionicframework.com/docs/img/demos/thumbnail.svg'
								className='product-img '
							/>

							<div>
								{t('Eligibility')} <br /> {t('Factors')}
							</div>
						</div>
						<div className={`tab-chip fs-14 d-flex gap-3`}>
							<img
								alt='Product'
								src='https://ionicframework.com/docs/img/demos/thumbnail.svg'
								className='product-img '
							/>

							<div>
								{t('Documents')} <br /> {t('Required')}
							</div>
						</div>
						<div className={`tab-chip fs-14 d-flex gap-3`}>
							<img
								alt='Product'
								src='https://ionicframework.com/docs/img/demos/thumbnail.svg'
								className='product-img '
							/>

							<div>
								{t('Documents')} <br /> {t('Required')}
							</div>
						</div>
					</div>

					<div className='mt-4'>
						<h5 className='fs-20 fw-600'>{t('Eligibility Factors')}</h5>
						<p className='fs-14'>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi non
							fuga error eius sint placeat itaque voluptatibus quaerat quam
							temporibus impedit neque dolor, quibusdam, eligendi quis doloribus
							nihil perspiciatis. Dolor!
						</p>
						<IonAccordionGroup ref={accordionGroup}>
							<IonAccordion
								value='show'
								className='bg-transparent'
							>
								<div
									className='ion-no-padding'
									slot='content'
								>
									<h5 className='fs-20 fw-600'>{t('Eligibility Factors')}</h5>
									<p className='fs-14'>
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Sequi non fuga error eius sint placeat itaque voluptatibus
										quaerat quam temporibus impedit neque dolor, quibusdam,
										eligendi quis doloribus nihil perspiciatis. Dolor!
									</p>
								</div>
							</IonAccordion>
						</IonAccordionGroup>
						<b
							className={' d-block mb-3'}
							onClick={toggleAccordion}
						>
							{t(viewBtn)}
						</b>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};
