//// @ts-nocheck

import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import useApi from "../../../api/useApi";
import CustomDropDown from "../../../components/common/custom-dropdown/CustomDropDown";
import CustomForm from "../../../components/common/custom_form/custom_form.component";
import { useBusyLoading } from "../../../context/BusyLoadingContext";
import { COPS_MANAGER_ASSIGN_LIST, DOCUMENT_UPLOAD, UPDATE_POLICY_STATUS } from "../../../helpers/api-endpoints";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { policy_issued_schema } from "./policy_issued.schema";
import { PolicyRequest, Policyform } from "../../../model/insurance_bean";
import { CustomButton } from "../../../components/common/custom-button/CustomButton";
import { downloadFile, showToastAlert } from "../../../helpers/functions";
import { DocumentUploadForm, DocumentUploadRequest } from "../../../model/document_bean";
import { INSURANCE_DOCUMENT_TYPE, REDIRECT } from "../../../helpers/constant";
import { AuthContext } from "../../../context/AuthContext";
import { useHistory } from "react-router-dom";
import { IonButton, IonCard, IonCardContent, IonIcon } from "@ionic/react";
import { download } from "ionicons/icons";
import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { Share } from '@capacitor/share';

export type PolicyIssuedProps = {
  hidePolicyIdNumber?: boolean;
  hidePolicyIssuanceDate?: boolean;
  hideTotalSumAssured?: boolean;
  hidePolicyTerm?: boolean;
  hidePremiumPaymentTerm?: boolean;
  hidePostponementDate?: boolean;
  hidePremiumFrequency?: boolean;
  hidePremiumAmount?: boolean;
  hideInsurerApplicationNo?: boolean;
  hidePaymentReceipt?: boolean;
  hidePaymentVideo?: boolean;
  hidePolicyBondReceivedDate?: boolean;
  hideRemarks?: boolean;
  hideCloseReason?: boolean;
  hidePLVCRejectedReason?: boolean;
  hideCopsExecutive?: boolean;
  hideUploadedPaymentReceipt?: boolean;
  hideUploadedPaymentVideo?: boolean;
  policyform: Policyform;
  policyDocs?: DocumentUploadForm[] | undefined;
};

export const PolicyIssued: React.FC<PolicyIssuedProps> = (props) => {
  const history = useHistory();
  const [managers, setManagers] = useState([]);
  const { userId, isCOPS, isCOPSExecutive } = useContext(AuthContext);

  const {
    hidePolicyIdNumber,
    hidePolicyIssuanceDate,
    hideTotalSumAssured,
    hidePolicyTerm,
    hidePremiumPaymentTerm,
    hidePostponementDate,
    hidePremiumFrequency,
    hidePremiumAmount,
    hideInsurerApplicationNo,
    hidePaymentReceipt,
    hidePaymentVideo,
    hidePolicyBondReceivedDate,
    hideRemarks,
    hideCloseReason,
    hidePLVCRejectedReason,
    hideCopsExecutive,
    hideUploadedPaymentReceipt,
    hideUploadedPaymentVideo,
    policyform,
    policyDocs,
  } = props;

  const {
    control,
    trigger,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid, isValidating },
  } = useForm({
    resolver: yupResolver(policy_issued_schema(props)),
    defaultValues: policyform
  });

  let policyIssuedFormKeys = [
    {
      name: "closeReason",
      type: "text/combo",
      placeholder: "Select Reason",
      hidden: hideCloseReason,
      mandatory: !hideCloseReason,
      values: [
        { value: "Declined and Closed", description: "Declined and Closed" },
        { value: "Not taken up", description: "Not taken up" },
        { value: "Not Interested", description: "Not Interested" },
      ],
    },
    {
      name: "plvcRejectedReason",
      type: "text/combo",
      placeholder: "Select Reason",
      hidden: hidePLVCRejectedReason,
      mandatory: !hidePLVCRejectedReason,
      values: [
        { value: "Mergers Of Policy", description: "Mergers Of Policy" },
        { value: "Third Party Payment", description: "Third Party Payment" },
        { value: "Customer Not Interested", description: "Customer Not Interested" },
        { value: "Promised Cashback", description: "Promised Cashback" },
        { value: "Committed Loan", description: "Committed Loan" },
        { value: "Refund Of Old, Lapsed Pol", description: "Refund Of Old, Lapsed Pol" },
        { value: "GST Payment", description: "GST Payment" },
        { value: "Others", description: "Others" },
      ],
    },
    {
      name: "remarks",
      type: "textarea",
      placeholder: "Remarks",
      hidden: hideRemarks,
    },
    {
      name: "insurerApplicationNo",
      type: "text",
      placeholder: "Application No.",
      hidden: hideInsurerApplicationNo,
    },
    {
      name: "paymentReciept",
      type: "docupload",
      getValuesFunc: getValues,
      setValueFunc: setValue,
      title: "Upload Payment Receipt",
      applicationId: policyform.applicationId,
      documentTypeId: INSURANCE_DOCUMENT_TYPE.PAYMENT_RECIEPT,
      profileId: null,
      showCamera: false,
      policyId: policyform.policyId,
      error: errors,
      mandatory: !hidePaymentReceipt,
      allowImages: true,
      allowPdfs: true,
      allowVideo: false,
      allowAudio: false,
      hidden: hidePaymentReceipt,
    },
    {
      name: "paymentVideo",
      type: "docupload",
      getValuesFunc: getValues,
      setValueFunc: setValue,
      title: "Upload Video/Audio",
      applicationId: policyform.applicationId,
      documentTypeId: INSURANCE_DOCUMENT_TYPE.PAYMENT_VIDEO,
      profileId: null,
      showCamera: false,
      policyId: policyform.policyId,
      error: errors,
      mandatory: !hidePaymentVideo,
      allowImages: false,
      allowPdfs: false,
      maxFileSize: 20971520,
      hidden: hidePaymentVideo,
    },
    {
      name: "policyNumber",
      type: "text",
      placeholder: "Policy ID Number *",
      hidden: hidePolicyIdNumber,
    },
    {
      name: "issueDate",
      type: "date",
      placeholder: "Policy Issuance Date *",
      hidden: hidePolicyIssuanceDate,
    },
    {
      name: "postponementDate",
      type: "date",
      placeholder: "Policy Postponement Date *",
      hidden: hidePostponementDate,
    },
    {
      name: "policyBondReceivedDate",
      type: "date",
      placeholder: "Policy Bond Recieved Date",
      hidden: hidePolicyBondReceivedDate,
    },
    {
      name: "sumAssured",
      type: "number",
      placeholder: "Total Sum Assured *",
      hidden: hideTotalSumAssured,
    },
    {
      name: "policyTerm",
      type: "number",
      placeholder: "Policy Term (In Years)*",
      hidden: hidePolicyTerm,
    },
    {
      name: "premiumPaymentTerm",
      type: "number",
      placeholder: "Premium Payment Term (In Years)*",
      hidden: hidePremiumPaymentTerm,
    },
    {
      name: "premiumFrequency",
      type: "text/combo",
      placeholder: "Premium Frequency *",
      mandatory: true,
      values: [
        { value: "monthly", description: "Monthly" },
        { value: "quarterly", description: "Quarterly" },
        { value: "halfYearly", description: "Half-Yearly" },
        { value: "yearly", description: "Yearly" },
      ],
      hidden: hidePremiumFrequency,
    },
    {
      name: "premiumAmount",
      type: "number",
      placeholder: "Premium Amount *",
      hidden: hidePremiumAmount,
    },
  ];

  const { responseData, loading, executeRequest, error } = useApi<any>(`${COPS_MANAGER_ASSIGN_LIST}`);
  const { setBusyState } = useBusyLoading();
  const docUpload = useApi<any>(DOCUMENT_UPLOAD);

  useEffect(() => {
    setBusyState(docUpload.loading);
  }, [docUpload.loading]);

  useEffect(() => {
    showToastAlert({ type: "error", message: docUpload.error?.message ?? '' });
  }, [docUpload.error]);

  useEffect(() => {
    setBusyState(loading);
  }, [loading]);

  useEffect(() => {
    showToastAlert({ type: "error", message: error?.message ?? '' });
  }, [error]);

  const polStatus = useApi<any>(`${UPDATE_POLICY_STATUS}`);

  useEffect(() => {
    setBusyState(polStatus.loading);
  }, [polStatus.loading]);

  const uploadFile = async (documentUploadForm: DocumentUploadRequest) => {
    const payload = documentUploadForm;
    return await docUpload.executeRequest('post', payload);
  };

  useEffect(() => {
    if (!hideCopsExecutive) {
      executeRequest("get", null, true, false);
    }
  }, []);

  useEffect(() => {
    if (responseData?.status) {
      setManagers(responseData?.central_exec_list);
    }
  }, [responseData]);

  const onSubmit = (data: any) => {
    trigger();
    const policyformStub: Policyform = data;
    let polPayload: PolicyRequest[] = [new PolicyRequest(policyformStub)];
    polStatus.executeRequest('put', polPayload).then(() => {});
  };

  useEffect(() => {
    if (polStatus.responseData?.status) {
      history.push('/cust-ins-message', {
        redirectTo: REDIRECT.DASHBOARD,
        message: `${polStatus.responseData?.message}`
      });
    }
  }, [polStatus.responseData]);

  const checkFilesystemPermission = async () => {
    try {
      const permission = await Filesystem.requestPermissions();
      if (permission.publicStorage !== 'granted') {
        throw new Error('Filesystem permissions not granted');
      }
    } catch (error) {
      console.error('Error requesting filesystem permissions', error);
    }
  };

  // const downloadFile = async (item: any) => {
  //   const filePreview = item.content;
  //   const fileName = item.name;
  //   const base64Data = filePreview.split(',')[1];

  //   try {
  //     if (Capacitor.getPlatform() === 'web') {
  //       // Create a link element for web download
  //       const link = document.createElement('a');
  //       link.href = filePreview;
  //       link.download = fileName;
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     } else {
  //       const result = await Filesystem.writeFile({
  //         path: fileName,
  //         data: base64Data,
  //         directory: Directory.Documents,
  //         encoding: Encoding.UTF8,
  //       });

  //       if (Capacitor.getPlatform() === 'android') {
  //         await checkFilesystemPermission();
  //         await FileOpener.open({
  //           filePath: result.uri,
  //           contentType: 'application/octet-stream',
  //         });
  //       } else if (Capacitor.getPlatform() === 'ios') {
  //         await checkFilesystemPermission();
  //         await Share.share({
  //           title: 'Downloaded File',
  //           text: 'Here is the downloaded file.',
  //           url: result.uri,
  //           dialogTitle: 'Open with',
  //         });
  //       }

  //       showToastAlert({ type: "success", message: "File downloaded successfully!" });
  //     }
  //   } catch (error) {
  //     showToastAlert({ type: "error", message: `Error downloading file: ${error}` });
  //   }
  // };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomForm
          formKeys={policyIssuedFormKeys}
          control={control}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
          trigger={trigger}
        />
        <div hidden={hideUploadedPaymentReceipt && hideUploadedPaymentVideo}>
          {policyDocs && policyDocs.length > 0 &&
            policyDocs.map((item) => {
              if (!hideUploadedPaymentReceipt && item.documentTypeId === INSURANCE_DOCUMENT_TYPE.PAYMENT_RECIEPT) {
                return (
                  <IonCard key={item.documentId} style={{ height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <IonCardContent style={{ height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <img style={{ height: '100%', width: '100%' }} src={`${item.content}`} alt="file-preview" />
                      <IonButton onClick={() => downloadFile(item)}>
                        <IonIcon icon={download} />
                      </IonButton>
                    </IonCardContent>
                  </IonCard>
                );
              } else if (!hideUploadedPaymentVideo && (isCOPS || isCOPSExecutive) && item.documentTypeId === INSURANCE_DOCUMENT_TYPE.PAYMENT_VIDEO) {
                return (
                  <IonCard key={item.documentId} style={{ height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <IonCardContent style={{ height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <p style={{ height: '100%', width: '100%', textAlign: 'center' }}>{item.name}</p>
                      <IonButton onClick={() => downloadFile(item)}>
                        <IonIcon icon={download} />
                      </IonButton>
                    </IonCardContent>
                  </IonCard>
                );
              } else {
                return null;
              }
            })}
        </div>
        <div hidden={hideCopsExecutive}>
          <CustomDropDown
            {...{
              className: `${errors?.copsExecId && 'dropdown-error'
                }`,
              isCheckbox: false,
              title: t('Select Team Member'),
              setData: (data: any) => {
                setManagers(data);
                const val = data.filter((item: any) => item.checked);
                setValue('copsExecId', val[0]?.id);
                trigger('copsExecId');
              },
              data: managers,
              placeholder: t('Search Team Member by “Name”'),
              isSearch: true,
              modalTitle: t('Team Member'),
            }}
          />
          {errors?.copsExecId && (
            <div className='input-bottom ps-3 pt-1'>
              <div className='error-text sc-ion-input-md'>
                {t(errors?.copsExecId?.message || '')}
              </div>
            </div>
          )}
        </div>
        <CustomButton
          className="btn-blue"
          type="submit"
          expand="block"
          title="Update"
          onClick={() => {
            handleSubmit(onSubmit);
          }}
        />
      </form>
    </div>
  );
};
