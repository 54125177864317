import { IonPage, IonContent } from '@ionic/react';
import { Header } from '../../components/layout/header/Header';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { IonChip, IonCardContent } from '@ionic/react';
import { IonSearchbar } from '@ionic/react';
import Card from './Card';
import { useTranslation } from 'react-i18next';

export const MyBusiness: React.FC = () => {
	const [segment, setSegment] = useState('loan');
	const history = useHistory();
	const totalEarnings = 2300000;
	const { t } = useTranslation();
	segment.length > 20 && setSegment('data');
	const handleValueChange = (event: any) => {
		setSegment(event.target.value);
	};
	const cardData = [
		{
			id: 1,
			subtitle1: '#L987021',
			subtitle2: '#A987021',
			subtitle3: 'LAN A12333672323',
			title: 'Suresh Shreeram Sharma',
			titlehome: 'Home Loan',
			titlelocation: 'Mumbai',
			titlebank: 'State Bank of India',
			icon1: 'Homeloan_icon',
			icon2: 'noun-location-5707257',
			icon3: 'Bank_icon',
			text1: 'Home Loan',
			text2: 'Mumbai',
			text3: 'State Bank Of India',
			loanAmountLabel: 'Loan Amount',
			loanAmountValue: '₹ 1,50,00,000',
			disbursedOnLabel: 'Last Disbursal on',
			disbursedOnValue: '10 July 2023',
			payoutLabel: 'Payout',
			payoutDate: '20 July 2023 at 5:56PM',
			payoutAmount: '₹ 1,50,000',
		},
		{
			id: 2,
			subtitle1: '#L987021',
			subtitle2: '#A987021',
			subtitle3: 'LAN A12333672323',
			title: 'Vikram Dasharath Patil',
			titlehome: 'Home Loan',
			titlelocation: 'Chennai',
			titlebank: 'Bank of India',
			icon1: 'Homeloan_icon',
			icon2: 'noun-location-5707257',
			icon3: 'Bank_icon',
			text1: 'Home Loan',
			text2: 'Mumbai',
			text3: 'State Bank Of India',
			loanAmountLabel: 'Loan Amount',
			loanAmountValue: '150,000',
			disbursedOnLabel: 'Last Disbursed on',
			disbursedOnValue: '10 July 2023',
			payoutLabel: 'Payout',
			payoutDate: '20 July 2023 at 5:56PM',
			payoutAmount: '1,50,000',
		},
	];

	return (
		<IonPage className=''>
			<div className='flex'>
				<Header
					name={t('My Earnings')}
					backButton={true}
					needArrowOnClick={() => history.push('/dashboard/home')}
					needArrow={true}
					// style={{ marginTop: '20px', marginBottom: '20px' }}
				/>
				<div className='HeaderIcon'>
					<img
						src='images\icons\export-icon.svg'
						alt=''
					/>
				</div>
			</div>
			<IonContent className='ps-0 bg-web'>
				<div className='my-business bg-web'>
					<div className='tabs border-bottom d-flex '>
						<div
							className='text-center'
							style={{ width: '50%' }}
						>
							<button
								className={`id-tab ${segment === 'loan' && 'active-tab'}`}
								onClick={(e) => {
									handleValueChange(e);
								}}
								value={'loan'}
							>
								{t('Loans')}
							</button>
						</div>
						<div
							className='text-center'
							style={{ width: '50%' }}
						>
							<button
								className={`id-tab ${segment === 'insurance' && 'active-tab'}`}
								onClick={(e) => {
									handleValueChange(e);
								}}
								value={'insurance'}
							>
								{t('Insurance')}
							</button>
						</div>
						<div
							className='text-center'
							style={{ width: '50%' }}
						>
							<button
								className={`id-tab ${segment === 'card' && 'active-tab'}`}
								onClick={(e) => {
									handleValueChange(e);
								}}
								value={'card'}
							>
								{t('Card')}
							</button>
						</div>
						<div
							className=' text-center'
							style={{ width: '80%' }}
						>
							<button
								className={`id-tab ${
									segment === 'real-estate' && 'active-tab'
								}`}
								onClick={(e) => {
									handleValueChange(e);
								}}
								value={'real-estate'}
							>
								{t('Real Estate')}
							</button>
						</div>
					</div>

					{segment === 'loan' ? (
						<>
							<div className='chips'>
								<IonChip
									className='active-chip'
									style={{ color: '#fff' }}
								>
									{t('Earnings')}
								</IonChip>
								<IonChip outline={true}>{t('Disbursal')}</IonChip>
							</div>
							<div className='ionSection'>
								<IonCardContent
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										border: '1px solid #d7d7d7',
										background: 'linear-gradient(251deg, #D0E9C842, #FFFFFF)',
										borderRadius: '8px',
									}}
								>
									<div>
										<img
											src='/images/icons/totalearn.svg'
											alt='totalearn'
										/>
										<span style={{ marginLeft: '5px' }}>Total Earnings</span>
									</div>
									<div style={{ fontSize: '24px', fontWeight: 'bold' }}>
										₹{totalEarnings}
									</div>
								</IonCardContent>
								<div className='margin-top-1 d-flex align-items-center w-100'>
									<IonSearchbar
										color={'white'}
										className='searchbar me-3'
										placeholder='Search Lead by “Name”'
									/>
									<img
										src='/images/icons/filter.svg'
										alt='filter'
									/>
								</div>
							</div>
							{cardData.map((data) => (
								<Card card={data} />
							))}
						</>
					) : (
						segment === 'qr-code' && (
							<div className='my-5 mx-4 display-1'>{t('QR CODE')}</div>
						)
					)}

					{segment === 'insurance'
						? cardData.map((data) => <Card card={data} />)
						: segment === 'qr-code' && (
								<div className='my-5 mx-4 display-1'>{t('QR CODE')}</div>
						  )}

					{segment === 'card'
						? cardData.map((data) => <Card card={data} />)
						: segment === 'qr-code' && (
								<div className='my-5 mx-4 display-1'>{t('QR CODE')}</div>
						  )}

					{segment === 'real-estate'
						? cardData.map((data) => <Card card={data} />)
						: segment === 'qr-code' && (
								<div className='my-5 mx-4 display-1'>{t('QR CODE')}</div>
						  )}
				</div>
			</IonContent>
		</IonPage>
	);
};
