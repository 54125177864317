import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	IonItem,
	IonImg,
	IonList,
	IonLabel,
	isPlatform,
	IonIcon,
} from '@ionic/react';
import {
	dataURLtoFile,
	getFileExtByUrl,
	getFileWithUpdatedToken,
	getFilenameByUrl,
	showInAppBrowserView,
} from '../../../../helpers/functions';
// import {
// 	KYC_DOCUMENT_UPLOAD,
// 	KYC_USER_UPLOAD_IMAGE,
// } from '../../../../helpers/api-endpoints';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { StatusNum } from '../../../../helpers/constant';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import { AddressProofEdit } from './Component/AddressProofEdit';
import {
	apiKycDocumentUpload,
	imgUrlUpdate,
	kycUserUploadImage,
} from '../../../../store/reducers/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { syncOutline, addOutline, removeOutline } from 'ionicons/icons';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

interface Props {
	userDetails: any;
	userId: number;
	listStatus: any;
}

export const AddressProofTab: React.FC<Props> = ({
	userDetails,
	userId,
	listStatus,
}) => {
	const { t } = useTranslation();
	const isBrowser = !isPlatform('capacitor');
	const [fileName, setFileName] = useState<any>('');
	const [fileName2, setFileName2] = useState<any>('');
	const [openUploadModal, setOpenUploadModal] = useState(false);
	const [showAddressProofInfoForm, setShowAddressProofInfoForm] =
		useState<any>(false);
	const [userData, setUserData] = useState<any>(userDetails);
	const dispatch: any = useDispatch();
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	useEffect(() => {
		setUserData(userDetails);
		setFileName(getFilenameByUrl(userDetails?.Url));
		setFileName2(getFilenameByUrl(userDetails?.Url2));
	}, [userDetails]);
	const [rotate, setRotate] = useState(0);
	const handleRotate = () => {
		setRotate((prev) => prev + 90);
	};

	const onImgError = () => {
		getFileWithUpdatedToken({
			dispatch,
			Url: userData?.Url,
			imgUrlUpdate,
			unwrapResult,
			setUserFile: null,
			setName: setFileName,
			userData: userData,
			setUserData,
			setUserData2: null,
		});
	};

	const onImgError2 = () => {
		getFileWithUpdatedToken({
			dispatch,
			Url: userData?.Url2,
			imgUrlUpdate,
			unwrapResult,
			setUserFile: null,
			setName: setFileName2,
			userData: userData,
			setUserData: null,
			setUserData2: setUserData,
		});
	};

	const ShareModal = () => {
		const handleUploadChange = async (
			type: string,
			fieldName: string,
			e: any
		) => {
			setOpenUploadModal(false);
			// case1
			const formData = new FormData();
			// let result: any;

			// case 2
			let image: any;
			switch (type) {
				case 'file':
					formData.append('profile', e.target.files[0]);

					await dispatch(kycUserUploadImage({ data: formData }))
						.then(unwrapResult)
						.then(async (result: any) => {
							if (result?.status) {
								userDetails.profile_url = result?.url;
								setFileName(getFilenameByUrl(result?.url));
								await dispatch(
									apiKycDocumentUpload({
										param: '/' + userId,
										data: { Type: 'AddressProof', Url: result?.url, RW: true },
									})
								)
									.then(unwrapResult)
									.then((urlresult: any) => {
										if (urlresult?.status) {
											const updatedData = { ...userData, Url: result?.url };
											setUserData(updatedData);
											setFileName(getFilenameByUrl(result?.url));
											setOpenUploadModal(false);
										}
									});
							}
						});
					break;
				case 'photo':
					image = await Camera.getPhoto({
						quality: 75,
						allowEditing: false,
						resultType: CameraResultType.DataUrl,
						source: CameraSource.Photos,
					});
					formData.append(
						'profile',
						dataURLtoFile(
							image.dataUrl,
							`${Math.random() * 100000000000000000}.${image.format}`
						)
					);

					await dispatch(kycUserUploadImage({ data: formData }))
						.then(unwrapResult)
						.then(async (result: any) => {
							if (result?.status) {
								await dispatch(
									apiKycDocumentUpload({
										param: '/' + userId,
										data: {
											Type: 'AddressProof',
											Url: result?.url,
											RW: true,
										},
									})
								)
									.then(unwrapResult)
									.then((urlresult: any) => {
										if (urlresult?.status) {
											const updatedData = { ...userData, Url: result?.url };
											setUserData(updatedData);
											setFileName(getFilenameByUrl(result?.url));
											setOpenUploadModal(false);
										}
									});
							}
						});

					break;
				case 'camera':
					image = await Camera.getPhoto({
						quality: 75,
						allowEditing: false,
						resultType: CameraResultType.DataUrl,
						source: CameraSource.Camera,
					});
					formData.append(
						'profile',
						dataURLtoFile(
							image.dataUrl,
							`${Math.random() * 100000000000000000}.${image.format}`
						)
					);

					await dispatch(kycUserUploadImage({ data: formData }))
						.then(unwrapResult)
						.then(async (result: any) => {
							if (result?.status) {
								await dispatch(
									apiKycDocumentUpload({
										param: '/' + userId,
										data: {
											Type: 'AddressProof',
											Url: result?.url,
											RW: true,
										},
									})
								)
									.then(unwrapResult)
									.then((urlresult: any) => {
										if (urlresult?.status) {
											const updatedData = { ...userData, Url: result?.url };
											setUserData(updatedData);
											setFileName(getFilenameByUrl(result?.url));
											setOpenUploadModal(false);
										}
									});
							}
						});

					break;
				default:
					break;
			}
		};
		return (
			<>
				<IonList>
					{!isBrowser && (
						<IonItem lines='full'>
							<img
								src='/images/icons/kyc/Image-upload.svg'
								alt='Upload Photo'
							/>
							<IonLabel>
								<h2
									className='fs-16 ps-3 text-dark'
									onClick={(e) => {
										handleUploadChange('photo', '', e);
									}}
								>
									Upload Photo
								</h2>
							</IonLabel>
						</IonItem>
					)}
					<IonItem lines='full'>
						<img
							src='/images/icons/kyc/upload-image-icon.svg'
							alt='Upload Photo'
						/>
						<IonLabel>
							<label htmlFor='upload-photo'>
								<h2 className='fs-16 ps-3 text-dark'>{t('Upload Document')}</h2>
							</label>
							<input
								type='file'
								id='upload-photo'
								accept='image/png, image/jpeg ,application/pdf'
								onChange={(e) => {
									handleUploadChange('file', '', e);
								}}
							/>
						</IonLabel>
					</IonItem>
					{!isBrowser && (
						<IonItem lines='full'>
							<img
								src='/images/icons/kyc/camera-icon.svg'
								alt='Upload Photo'
							/>
							<IonLabel>
								<h2
									className='fs-16 ps-3 text-dark'
									onClick={(e) => {
										handleUploadChange('camera', '', e);
									}}
								>
									Take Photo
								</h2>
							</IonLabel>
						</IonItem>
					)}
				</IonList>
			</>
		);
	};

	return (
		<div>
			<div
				className='image-outline ion-margin'
				style={{
					position: 'relative',
					width: '450px',
					height: '300px',
					overflow: 'hidden',
					border: '1px solid #ccc',
				}}
			>
				<IonIcon
					icon={syncOutline}
					size='large'
					onClick={handleRotate}
					style={{
						position: 'absolute',
						top: '10px',
						left: '10px',
						zIndex: 1,
					}}
				/>

				<TransformWrapper
					initialScale={1}
					minScale={0.3}
					maxScale={5}
					centerOnInit={true}
					limitToBounds={true}
					centerZoomedOut={true}
				>
					{({ zoomIn, zoomOut }) => (
						<>
							<IonIcon
								icon={addOutline}
								size='large'
								onClick={() => zoomIn()}
								style={{
									position: 'absolute',
									top: '10px',
									right: '50px',
									zIndex: 1,
								}}
							/>
							<IonIcon
								icon={removeOutline}
								size='large'
								onClick={() => {
									zoomOut();
								}}
								style={{
									position: 'absolute',
									top: '10px',
									right: '10px',
									zIndex: 1,
								}}
							/>
							<TransformComponent>
								<IonImg
									className='preview-img'
									style={{
										transform: `rotate(${rotate}deg)`,
										display: 'block',
										margin: '10px 30px',
										maxWidth: '100%',
										maxHeight: '100%',
									}}
									src={
										getFileExtByUrl(userData?.Url)?.toLowerCase() === 'pdf'
											? 'images/icons/pdf-placeholder.svg'
											: userData?.Url
									}
									onIonError={onImgError}
									alt='pan card img'
								/>
							</TransformComponent>
						</>
					)}
				</TransformWrapper>

				{userData?.approve === 'rework_required' &&
					listStatus === StatusNum.REWORK && (
						<div className='preview-img-edit'>
							<div
								className='d-inline-block fw-600 fs-14 primary-text-color'
								onClick={() => {
									setOpenUploadModal(true);
								}}
							>
								<img
									src='images/icons/user-details-edit-icon.svg'
									className='me-2'
								/>
								{t('Edit')}
							</div>
						</div>
					)}

				<div className='preview-img-fileName'>
					<img
						className='me-2'
						src='images/icons/preview-img-filename-icon.svg'
					/>
					<span className='text-truncate fw-600'>{fileName}</span>
				</div>
			</div>
			{fileName2 ? (
				<div className='ion-margin image-outline position-relative'>
					<IonImg
						className='preview-img'
						src={
							getFileExtByUrl(userData?.Url2)?.toLowerCase() === 'pdf'
								? 'images/icons/pdf-placeholder.svg'
								: userData?.Url2
						}
						alt='pan card img'
						onIonError={onImgError2}
						onClick={() => showInAppBrowserView(userData?.Url2)}
					></IonImg>

					<div className='preview-img-fileName'>
						<img
							className='me-2'
							src='images/icons/preview-img-filename-icon.svg'
						/>
						<span className='text-truncate fw-600'>{fileName2}</span>
					</div>
				</div>
			) : (
				''
			)}
			<div className='d-flex justify-content-end ion-padding-horizontal'>
				{userData?.approve === 'rework_required' &&
					listStatus === StatusNum.REWORK && (
						<div
							className='d-flex fw-600 fs-14 primary-text-color'
							onClick={() => setShowAddressProofInfoForm(true)}
						>
							<img
								src='images/icons/user-details-edit-icon.svg'
								className='me-2'
							/>
							{t('Edit')}
						</div>
					)}
			</div>
			<IonItem
				className='ion-no-padding ion-margin-horizontal item-no-padding-end remove-ion-item-inner-padding-end'
				lines='full'
			>
				<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
					<p className='mb-0 fs-14'>{t('Address Proof Name')}</p>
					<p className='mb-0 fs-14 fw-600'>
						{userData?.AddressProof ? userData?.AddressProof : '-'}
					</p>
				</div>
			</IonItem>
			<IonItem
				className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
				lines='full'
			>
				<div className='d-block'>
					<p className='mt-3 mb-2 fs-14'>{t('Current Residential Address')}</p>
					<p className='mt-2 mb-3 fs-14 fw-600'>
						{userData?.CurrentAddress ? userData?.CurrentAddress : '-'}
					</p>
				</div>
			</IonItem>
			<IonItem
				className='ion-no-padding ion-margin-horizontal item-no-padding-end remove-ion-item-inner-padding-end'
				lines='full'
			>
				<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
					<p className='mb-0 fs-14'>{t('Current Address Pincode')}</p>
					<p className='mb-0 fs-14 fw-600'>
						{userData?.CurrentPinCode ? userData?.CurrentPinCode : '-'}
					</p>
				</div>
			</IonItem>
			<IonItem
				className='ion-no-padding ion-margin-horizontal item-no-padding-end remove-ion-item-inner-padding-end'
				lines='full'
			>
				<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
					<p className='mb-0 fs-14'>{t('Current Address City')}</p>
					<p className='mb-0 fs-14 fw-600'>
						{userData?.CurrentCity ? userData?.CurrentCity : '-'}
					</p>
				</div>
			</IonItem>
			<IonItem
				className='ion-no-padding ion-margin-horizontal item-no-padding-end remove-ion-item-inner-padding-end'
				lines='full'
			>
				<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
					<p className='mb-0 fs-14'>{t('Current Address State')}</p>
					<p className='mb-0 fs-14 fw-600'>
						{userData?.CurrentState ? userData?.CurrentState : '-'}
					</p>
				</div>
			</IonItem>
			<IonItem
				className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
				lines='full'
			>
				<div className='d-block'>
					<p className='mt-3 mb-2 fs-14'>
						{t('Permanent Residential Address')}
					</p>
					<p className='mt-2 mb-3 fs-14 fw-600'>
						{userData?.PermanentAddress ? userData?.PermanentAddress : '-'}
					</p>
				</div>
			</IonItem>
			<IonItem
				className='ion-no-padding ion-margin-horizontal item-no-padding-end remove-ion-item-inner-padding-end'
				lines='full'
			>
				<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
					<p className='mb-0 fs-14'>{t('Permanent Address Pincode')}</p>
					<p className='mb-0 fs-14 fw-600'>
						{userData?.PermanentPinCode ? userData?.PermanentPinCode : '-'}
					</p>
				</div>
			</IonItem>
			<IonItem
				className='ion-no-padding ion-margin-horizontal item-no-padding-end remove-ion-item-inner-padding-end'
				lines='full'
			>
				<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
					<p className='mb-0 fs-14'>{t('Permanent Address City')}</p>
					<p className='mb-0 fs-14 fw-600'>
						{userData?.PermanentCity ? userData?.PermanentCity : '-'}
					</p>
				</div>
			</IonItem>
			<IonItem
				className='ion-no-padding ion-margin-horizontal item-no-padding-end remove-ion-item-inner-padding-end'
				lines='full'
			>
				<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
					<p className='mb-0 fs-14'>{t('Permanent Address State')}</p>
					<p className='mb-0 fs-14 fw-600'>
						{userData?.PermanentState ? userData?.PermanentState : '-'}
					</p>
				</div>
			</IonItem>
			<IonItem
				className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
				lines='full'
			>
				<div className='d-block'>
					<p className='mt-3 mb-2 fs-14'>{t('Office Address')}</p>
					<p className='mt-2 mb-3 fs-14 fw-600'>
						{userData?.OfficeAddress ? userData?.OfficeAddress : '-'}
					</p>
				</div>
			</IonItem>
			<IonItem
				className='ion-no-padding ion-margin-horizontal item-no-padding-end remove-ion-item-inner-padding-end'
				lines='full'
			>
				<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
					<p className='mb-0 fs-14'>{t('Office Pincode')}</p>
					<p className='mb-0 fs-14 fw-600'>
						{userData?.OfficePinCode ? userData?.OfficePinCode : '-'}
					</p>
				</div>
			</IonItem>
			<IonItem
				className='ion-no-padding ion-margin-horizontal item-no-padding-end remove-ion-item-inner-padding-end'
				lines='full'
			>
				<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
					<p className='mb-0 fs-14'>{t('Office City')}</p>
					<p className='mb-0 fs-14 fw-600'>
						{userData?.OfficeCity ? userData?.OfficeCity : '-'}
					</p>
				</div>
			</IonItem>
			<IonItem
				className='ion-no-padding ion-margin-horizontal item-no-padding-end remove-ion-item-inner-padding-end'
				lines='full'
			>
				<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
					<p className='mb-0 fs-14'>{t('Office State')}</p>
					<p className='mb-0 fs-14 fw-600'>
						{userData?.OfficeState ? userData?.OfficeState : '-'}
					</p>
				</div>
			</IonItem>
			{/* Upload Photo Modal */}
			<CustomModal
				id='sharemodal'
				isOpen={openUploadModal}
				initialBreakpoint={isWeb ? 1 : 0.3}
				handleClose={() => setOpenUploadModal(false)}
				breakpoints={[0, 0.3, 0.5, 0.75]}
				modalContent={<ShareModal />}
				needCross={false}
				needArrow={false}
				className={`side-modal ${isWeb ? '' : 'modal-border-radius'}`}
			/>

			<CustomModal
				id='customModalRework'
				isOpen={showAddressProofInfoForm}
				modalTitle={t('AddressProof Info')}
				initialBreakpoint={1}
				handleClose={() => setShowAddressProofInfoForm(false)}
				breakpoints={[0, 1]}
				className='side-modal'
				modalContent={
					<AddressProofEdit
						userId={userId}
						data={userData}
						setUserData={setUserData}
						setShowAddressProofInfoForm={setShowAddressProofInfoForm}
					/>
				}
				needCross={true}
				needArrow={false}
			></CustomModal>
		</div>
	);
};
