//// @ts-nocheck

// AuthContext.tsx
import React, { createContext, useState, ReactNode } from 'react';
import { ApplicationBean } from '../model/insurance_bean';



// type InsurancFormTabValidationStatus = {
//   basicDetailsValid: boolean; // New variable to store the validity state
//   familyDetailsValid: boolean; // New variable to store the validity state
//   addressDetailsValid: boolean; // New variable to store the validity state
//   permAddressDetailsValid: boolean; // New variable to store the validity state
//   curAddressDetailsValid: boolean; // New variable to store the validity state
//   occupationDetailsValid: boolean; // New variable to store the validity state
// }


interface ApplicationContextType {
  applicationBean: ApplicationBean | null;
  setAppBean: (applicationBean: ApplicationBean) => void;
  // proposerTabStatus: InsurancFormTabValidationStatus;
  // setProposerTabStatus: (proposerTabStatus: InsurancFormTabValidationStatus) => void; // New method to update the validity state
  // assuredTabStatus: InsurancFormTabValidationStatus;
  // setAssuredTabStatus: (proposerTabStatus: InsurancFormTabValidationStatus) => void; // New method to update the validity state


  proposerBasicDetailsValid: boolean; // New variable to store the validity state
  setProposerBasicDetailsValid: (isValid: boolean) => void; // New method to update the validity state
  proposerFamilyDetailsValid: boolean; // New variable to store the validity state
  setProposerFamilyDetailsValid: (isValid: boolean) => void; // New method to update the validity state
  proposerAddressDetailsValid: boolean; // New variable to store the validity state
  setProposerAddressDetailsValid: (isValid: boolean) => void; // New method to update the validity state
  proposerPermAddressDetailsValid: boolean; // New variable to store the validity state
  setProposerPermAddressDetailsValid: (isValid: boolean) => void; // New method to update the validity state
  proposerCurAddressDetailsValid: boolean; // New variable to store the validity state
  setProposerCurAddressDetailsValid: (isValid: boolean) => void; // New method to update the validity state
  proposerOccupationDetailsValid: boolean; // New variable to store the validity state
  setProposerOccupationDetailsValid: (isValid: boolean) => void; // New method to update the validity state


  assuredBasicDetailsValid: boolean; // New variable to store the validity state
  setAssuredBasicDetailsValid: (isValid: boolean) => void; // New method to update the validity state
  assuredFamilyDetailsValid: boolean; // New variable to store the validity state
  setAssuredFamilyDetailsValid: (isValid: boolean) => void; // New method to update the validity state
  assuredAddressDetailsValid: boolean; // New variable to store the validity state
  setAssuredAddressDetailsValid: (isValid: boolean) => void; // New method to update the validity state
  assuredPermAddressDetailsValid: boolean; // New variable to store the validity state
  setAssuredPermAddressDetailsValid: (isValid: boolean) => void; // New method to update the validity state
  assuredCurAddressDetailsValid: boolean; // New variable to store the validity state
  setAssuredCurAddressDetailsValid: (isValid: boolean) => void; // New method to update the validity state
  assuredOccupationDetailsValid: boolean; // New variable to store the validity state
  setAssuredOccupationDetailsValid: (isValid: boolean) => void; // New method to update the validity state


}


const ApplicationContext = createContext<ApplicationContextType>({
  applicationBean: null,
  setAppBean: () => { },
  // proposerTabStatus: {
  //   basicDetailsValid: true, // New variable to store the validity state
  //   familyDetailsValid: true,
  //   occupationDetailsValid: true,
  //   addressDetailsValid: true,
  //   permAddressDetailsValid: true,
  //   curAddressDetailsValid: true,
  // }, // Default value for basicDetailsValid
  // setProposerTabStatus: () => { },
  // assuredTabStatus: {
  //   basicDetailsValid: true, // New variable to store the validity state
  //   familyDetailsValid: true,
  //   occupationDetailsValid: true,
  //   addressDetailsValid: true,
  //   permAddressDetailsValid: true,
  //   curAddressDetailsValid: true,
  // }, // New variable to store the validity state
  // setAssuredTabStatus: () => { },

  proposerBasicDetailsValid: true,  // New variable to store the validity state
  setProposerBasicDetailsValid: () => { },// New method to update the validity state
  proposerFamilyDetailsValid: true,  // New variable to store the validity state
  setProposerFamilyDetailsValid: () => { },// New method to update the validity state
  proposerAddressDetailsValid: true,  // New variable to store the validity state
  setProposerAddressDetailsValid: () => { },// New method to update the validity state
  proposerPermAddressDetailsValid: true,  // New variable to store the validity state
  setProposerPermAddressDetailsValid: () => { },// New method to update the validity state
  proposerCurAddressDetailsValid: true,  // New variable to store the validity state
  setProposerCurAddressDetailsValid: () => { },// New method to update the validity state
  proposerOccupationDetailsValid: true,  // New variable to store the validity state
  setProposerOccupationDetailsValid: () => { },// New method to update the validity state


  assuredBasicDetailsValid: true,  // New variable to store the validity state
  setAssuredBasicDetailsValid: () => { },// New method to update the validity state
  assuredFamilyDetailsValid: true,  // New variable to store the validity state
  setAssuredFamilyDetailsValid: () => { },// New method to update the validity state
  assuredAddressDetailsValid: true,  // New variable to store the validity state
  setAssuredAddressDetailsValid: () => { },// New method to update the validity state
  assuredPermAddressDetailsValid: true,  // New variable to store the validity state
  setAssuredPermAddressDetailsValid: () => { },// New method to update the validity state
  assuredCurAddressDetailsValid: true,  // New variable to store the validity state
  setAssuredCurAddressDetailsValid: () => { },// New method to update the validity state
  assuredOccupationDetailsValid: true,  // New variable to store the validity state
  setAssuredOccupationDetailsValid: () => { },// New method to update the validity state

});

export const ApplicationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [applicationBean, setApplicationBean] = useState<ApplicationBean | null>(null);
  // const [proposerTabStatus, setProposerTabStatus] = useState<InsurancFormTabValidationStatus>(); // State for managing validity
  // const [assuredTabStatus, setAssuredTabStatus] = useState<InsurancFormTabValidationStatus>(); // State for managing validity

  const [proposerBasicDetailsValid, setProposerBasicDetailsValid] = useState<boolean>(false); // State for managing validity
  const [proposerFamilyDetailsValid, setProposerFamilyDetailsValid] = useState<boolean>(false); // State for managing validity
  const [proposerAddressDetailsValid, setProposerAddressDetailsValid] = useState<boolean>(false); // State for managing validity
  const [proposerPermAddressDetailsValid, setProposerPermAddressDetailsValid] = useState<boolean>(false); // State for managing validity
  const [proposerCurAddressDetailsValid, setProposerCurAddressDetailsValid] = useState<boolean>(false); // State for managing validity
  const [proposerOccupationDetailsValid, setProposerOccupationDetailsValid] = useState<boolean>(false); // State for managing validity

  const [assuredBasicDetailsValid, setAssuredBasicDetailsValid] = useState<boolean>(true); // State for managing validity
  const [assuredFamilyDetailsValid, setAssuredFamilyDetailsValid] = useState<boolean>(true); // State for managing validity
  const [assuredAddressDetailsValid, setAssuredAddressDetailsValid] = useState<boolean>(true); // State for managing validity
  const [assuredPermAddressDetailsValid, setAssuredPermAddressDetailsValid] = useState<boolean>(true); // State for managing validity
  const [assuredCurAddressDetailsValid, setAssuredCurAddressDetailsValid] = useState<boolean>(true); // State for managing validity
  const [assuredOccupationDetailsValid, setAssuredOccupationDetailsValid] = useState<boolean>(true); // State for managing validity


  const setAppBean = (applicationBean: ApplicationBean) => {
    setApplicationBean(applicationBean);
  };


  // const login = (token: string) => {
  //   setToken(token);
  // };

  // const logout = () => {
  //   setToken(null);
  // };

  return (
    // <ApplicationContext.Provider value={{
    //   setAppBean, applicationBean,
    //   proposerTabStatus, setProposerTabStatus,
    //   assuredTabStatus, setAssuredTabStatus


    // }}>
    <ApplicationContext.Provider value={{
      setAppBean, applicationBean,
      proposerBasicDetailsValid, setProposerBasicDetailsValid,
      proposerFamilyDetailsValid, setProposerFamilyDetailsValid,
      proposerAddressDetailsValid, setProposerAddressDetailsValid,
      proposerPermAddressDetailsValid, setProposerPermAddressDetailsValid,
      proposerCurAddressDetailsValid, setProposerCurAddressDetailsValid,
      proposerOccupationDetailsValid, setProposerOccupationDetailsValid,

      assuredBasicDetailsValid, setAssuredBasicDetailsValid,
      assuredFamilyDetailsValid, setAssuredFamilyDetailsValid,
      assuredAddressDetailsValid, setAssuredAddressDetailsValid,
      assuredPermAddressDetailsValid, setAssuredPermAddressDetailsValid,
      assuredCurAddressDetailsValid, setAssuredCurAddressDetailsValid,
      assuredOccupationDetailsValid, setAssuredOccupationDetailsValid,

    }}>
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationContext;


