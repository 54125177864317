import "./ConsentBusyLoader.scss";
import { useConsentBusyLoading } from "../../context/ConsentBusyLoadingContext";
export const ConsentBusyLoader = () => {
  const { isConsentBusy } = useConsentBusyLoading();

  if (!isConsentBusy) {
    return null;
  }
  return <div id="cover-spin"></div>;
};

