/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '../../components/layout/header/Header';

import './Products.scss';
import {
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
} from '@ionic/react';
import { useHistory, useLocation } from 'react-router';
//import { productFlow } from '../../store/reducers/lead/leadSlice';
import { CustomSearchbar } from '../../components/common/custom-searchbar/CustomSearchbar';
import { useEffect, useState } from 'react';
import {
	getLenderConsultations,
	paginationSize,
	productCosnultationFilter,
	resetLeadFilterState,
} from '../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { CustomNoData } from '../../components/common/custom-no-data/CustomNoData';
import { useTranslation } from 'react-i18next';
import { sanitizeHtml } from '../../helpers/functions';

export const LenderLoanConsultants: React.FC = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const productFlowSelector = useSelector(
		(state: any) => state?.lead?.productFlowData
	);
	const [loadInfiniteInstance, setLoadInfiniteInstance] = useState<any>(true);
	const [disableInfiniteInstance, setDisableInfiniteInstance] =
		useState<any>(false);
	const leadInfoPageSizeSelector = useSelector((state: any) => state?.lead);

	const productFilterSlctr: any = useSelector((state: any) => state?.lead);
	const [searchString, setSearch] = useState('');

	const [consultants, setConsultants] = useState<any>([]);

	const consultantPageSize = 20;
	const location = useLocation();
	useEffect(() => {
		if (location.pathname !== '/lender-loan-consultants') {
			return;
		}
		setDisableInfiniteInstance(false);
		resetPaginationInfo();

		getLenderCst(
			'',
			leadInfoPageSizeSelector?.defaultPageId,
			leadInfoPageSizeSelector?.defaultPageSize
		);
		setSearch('');
	}, [
		location.pathname,
		productFilterSlctr?.cstCity,
		productFilterSlctr?.cstDesignation,
	]);
	const resetPaginationInfo = () => {
		dispatch(
			paginationSize({
				...leadInfoPageSizeSelector,
				pageSize: leadInfoPageSizeSelector?.defaultPageSize,
				pageId: leadInfoPageSizeSelector?.defaultPageId,
				searchTerm: '',
			})
		);
	};
	/* 	useEffect(() => {
		window.addEventListener('beforeunload', resetPaginationInfo);
		return () => {
			window.removeEventListener('beforeunload', resetPaginationInfo);
		};
	}, []); */
	const getLenderCst = async (
		searchTerm = '',
		pageId: any = '',
		pageSize: any = '',
		resetList = false
	) => {
		pageId = !pageId ? leadInfoPageSizeSelector?.pageId : pageId;
		pageSize = !pageSize ? leadInfoPageSizeSelector?.pageSize : pageSize;
		searchTerm =
			!searchTerm && !resetList
				? !leadInfoPageSizeSelector?.searchTerm
					? ''
					: leadInfoPageSizeSelector?.searchTerm
				: searchTerm;
		let _consultantList = consultants;
		if (
			(pageId === leadInfoPageSizeSelector.defaultPageId && searchTerm) ||
			resetList
		) {
			// setDmaList([]);
			_consultantList = [];
		}
		// to clear _leadList old data I added this if condition
		if (
			pageId === leadInfoPageSizeSelector.defaultPageId &&
			pageSize === leadInfoPageSizeSelector.defaultPageSize &&
			!searchTerm
		) {
			_consultantList = [];
		}

		pageSize += 10;
		setTimeout(() => {
			dispatch(
				getLenderConsultations({
					param: `?size=${pageSize}&pageId=${pageId}&subproduct_id=${
						productFlowSelector?.subProductId
					}&lender_id=${productFlowSelector?.lenderId}${
						searchTerm
							? `&searchItem=${sanitizeHtml(encodeURIComponent(searchTerm))}`
							: ''
					}${
						productFilterSlctr?.cstCity
							? `&city=${productFilterSlctr?.cstCity}`
							: ''
					}${
						productFilterSlctr?.cstDesignation
							? `&designation=${productFilterSlctr?.cstDesignation}`
							: ''
					}`,
				})
			)
				.then(unwrapResult)
				.then((result: any) => {
					if (result?.status) {
						if (result?.status) {
							setConsultants(result?.data);
							if (result?.data.length !== consultantPageSize) {
								setLoadInfiniteInstance(false);
							} else {
								setLoadInfiniteInstance(true);
							}

							setConsultants(_consultantList.concat(result?.data));
						}
					}
				});
		}, 300);
	};

	const handleSearch = async (search: string) => {
		dispatch(
			paginationSize({
				...leadInfoPageSizeSelector,
				searchTerm: search,
				pageId: leadInfoPageSizeSelector?.defaultPageId,
			})
		);
		setLoadInfiniteInstance(true);
		if (search.length >= 1) {
			await getLenderCst(
				search,
				leadInfoPageSizeSelector?.defaultPageId,
				leadInfoPageSizeSelector?.defaultPageSize
			);
		}
	};
	useEffect(() => {
		if (searchString === '' || searchString.length >= 3) {
			getLenderCst();
		}
	}, [searchString]);
	return (
		<IonPage className='lead-list'>
			<Header
				name={
					t(productFlowSelector.loanTitle).toString() + ' ' + t('Consultants')
				}
				type=''
				needArrow={true}
				backButton={true}
				needArrowOnClick={() => {
					setSearch('');
					setDisableInfiniteInstance(true);
					dispatch(resetLeadFilterState());
					resetPaginationInfo();
					setConsultants([]);

					productCosnultationFilter({
						...productFilterSlctr,
						cstCity: '',
						cstDesignation: '',
						searchTerm: '',
					});
					history.push('/lender-loan-types');
				}}
			/>
			<div className='d-flex py-3 ion-padding bg-white'>
				<CustomSearchbar
					searchIcon='/images/icons/searchbar-icon.svg'
					placeholder={t('Search Consultants by “Name”')}
					showClearButton='focus'
					onChange={(v: any) => {
						if (!v.length) {
							setConsultants([]);
						}
						handleSearch(v);
						setSearch(v);
					}}
					onIonClear={() => {
						setConsultants([]);
						resetPaginationInfo();
						getLenderCst(
							'',
							leadInfoPageSizeSelector?.defaultPageId,
							leadInfoPageSizeSelector?.defaultPageSize,
							true
						);
					}}
					value={leadInfoPageSizeSelector?.searchTerm}
					needFilter={true}
					onFilterClick={() => history.push('/consultation-filter')}
				/>
			</div>
			<IonContent>
				<div className={`container-width-lg h-100 bg-web`}>
					<div
						className={`  ${
							consultants?.length
								? ''
								: 'd-flex justify-content-center align-items-center h-100'
						}
						`}
					>
						{consultants?.length ? (
							<div className='row bg-web h-100 ion-padding'>
								{consultants?.map((item: any) => {
									return (
										<div
											key={item?.id}
											className='col-12 col-lg-4 mb-3 h-100'
										>
											<div
												style={{ borderBottom: '1px solid #DFDFDF' }}
												className='consultant-card ion-padding'
											>
												<div className='d-flex gap-3 justify-content-between align-items-center'>
													<div className='w-75'>
														<h2
															style={{ color: '#0C0E2B' }}
															className='fs-16 fw-bold'
														>
															{item?.full_name}
														</h2>
														<h5
															style={{ color: '#5D5E72' }}
															className='fs-14'
														>
															{item?.designation}
														</h5>
														<div className='d-flex align-items-center gap-2'>
															<img
																src='/images/icons/list-user-location-icon.svg'
																alt=''
															/>
															<p
																style={{ color: '#5D5E72' }}
																className='mb-0 fs-14'
															>
																{item?.location}
															</p>
														</div>
													</div>
													<div className='d-flex gap-3 align-items-center'>
														<div>
															<a
																className='cursor-pointer'
																href={`tel: ${item?.mobile_number}`}
															>
																<img
																	src='/images/icons/leads/phone.svg'
																	alt='call'
																/>
															</a>
														</div>
														<div>
															<a
																className='cursor-pointer'
																href={`mailto:${item?.email}`}
															>
																<img
																	src='/images/icons/message.svg'
																	alt='email'
																/>
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									);
								})}
								{consultants?.length ? (
									<IonInfiniteScroll
										threshold='20%'
										disabled={disableInfiniteInstance}
										onIonInfinite={(ev) => {
											if (!loadInfiniteInstance) {
												ev.target.complete();
												return;
											}
											const updatedPageId =
												leadInfoPageSizeSelector?.pageId +
												leadInfoPageSizeSelector?.defaultPageId;
											dispatch(
												paginationSize({
													...leadInfoPageSizeSelector,
													pageId: updatedPageId,
												})
											);
											getLenderCst(
												leadInfoPageSizeSelector?.searchTerm,
												updatedPageId
											);

											setTimeout(() => {
												ev.target.complete();
											}, 200);
										}}
									>
										<IonInfiniteScrollContent
											loadingSpinner={null}
										></IonInfiniteScrollContent>
									</IonInfiniteScroll>
								) : (
									''
								)}
							</div>
						) : (
							<CustomNoData className='pt-0 mt-0' />
						)}
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};
