import * as yup from 'yup';


const application_basic_details_schema = yup.object().shape({
  loanApplicationNumber: yup.string().notRequired(),
  policyProposedFor: yup.string().required('Mandatory Field'),
  // insuranceTypeId: yup.number(),
  // managerId: yup.number(),
  // mobile: yup.string(),
});





export default application_basic_details_schema;