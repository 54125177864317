/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import { IonChip, IonImg } from '@ionic/react';
import ProgressHalfCircle from '../../components/common/progress-half-circle/ProgressHalfCircle';
import { useHistory } from 'react-router';
import {
	// CREDIT_SCORE_IMPACTS,
	CREDIT_SCORE_SCALE_RATING,
	LEAD_SCORE_TYPE,
} from '../../helpers/constant';
import { useDispatch, useSelector } from 'react-redux';
import { bureauStatsDetails } from '../../store/reducers/lead/leadSlice';

interface BureauData {
	bureauRetry: boolean;
	bureauFetchError?: any;
	leadType?: any;
	getBureauData?: any;
	data?: any;
	selectedTab?: any;
	lead_id?: any;
	dataLoading?: any;
	height?: any;
}

export const BureauData: React.FC<BureauData> = ({
	bureauRetry,
	bureauFetchError,
	getBureauData,
	data,
	selectedTab,
	lead_id,
	dataLoading,
	height,
}) => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch: any = useDispatch();
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	return (
		<>
			{data &&
			typeof data === 'object' &&
			Object.keys(data) &&
			'score' in data ? (
				<div
					className='bureau-data'
					style={{
						// height: `calc(100vh - ${
						// 	leadType === 'customer' ? '190px' : '230px'
						// })`,
						height: isWeb ? (height ? height : '64vh') : '',
						overflowY: isWeb ? 'auto' : 'unset',
					}}
				>
					<div
						className={`${
							selectedTab?.toLocaleLowerCase() ===
							LEAD_SCORE_TYPE.CBIL.toLocaleLowerCase()
								? 'pb-1'
								: 'pb-4'
						}`}
					>
						<ProgressHalfCircle
							score={data?.score | 0}
							scoreStroke={
								data?.scoreText?.toLowerCase() ===
								CREDIT_SCORE_SCALE_RATING.POOR.toLowerCase()
									? '#FF0000'
									: data?.scoreText.toLowerCase() ===
									  CREDIT_SCORE_SCALE_RATING.GOOD.toLowerCase()
									? '#70AD47'
									: data?.scoreText.toLowerCase() ===
									  CREDIT_SCORE_SCALE_RATING.EXCELLENT.toLowerCase()
									? '#548235'
									: data?.scoreText.toLowerCase() ===
									  CREDIT_SCORE_SCALE_RATING.AVERAGE.toLowerCase()
									? '#FFC000'
									: data?.scoreText.toLowerCase() ===
											CREDIT_SCORE_SCALE_RATING.NH.toLowerCase() && ''
							}
						/>
						<div className='text-center pt-2 pb-2'>
							<IonChip
								className={`fs-12 ${
									data?.scoreText.toLowerCase() ===
									CREDIT_SCORE_SCALE_RATING.POOR.toLowerCase()
										? 'chip-red'
										: data?.scoreText.toLowerCase() ===
										  CREDIT_SCORE_SCALE_RATING.GOOD.toLowerCase()
										? 'chip-good'
										: data?.scoreText.toLowerCase() ===
										  CREDIT_SCORE_SCALE_RATING.EXCELLENT.toLowerCase()
										? 'chip-excellent'
										: data?.scoreText.toLowerCase() ===
										  CREDIT_SCORE_SCALE_RATING.AVERAGE.toLowerCase()
										? 'chip-average'
										: data?.scoreText.toLowerCase() ===
												CREDIT_SCORE_SCALE_RATING.NH.toLowerCase() && ''
								} `}
								style={{
									padding: '8px 8px 8px',
									height: '20px',
								}}
							>
								{t(data?.scoreText)}
							</IonChip>
						</div>
						<h4 className='m-0 fs-14 text-grey text-center'>
							{data?.updateText}
						</h4>
						{data?.update && (
							<div className='text-center py-2'>
								<CustomButton
									onClick={getBureauData}
									size='small'
									title={t('Update available')}
								/>
							</div>
						)}
					</div>
					<div>
						{selectedTab?.toLocaleLowerCase() ===
						LEAD_SCORE_TYPE.CBIL.toLocaleLowerCase() ? (
							<div className='flex pb-2'>
								<div className='fs-14'>
									Control Number
									<br />
									{data?.control_number}
								</div>
								<div className='pb-2'>
									<IonImg
										src='/images/icons/powered_by_cbil.jpg'
										style={{ width: '75px' }}
									/>
								</div>
							</div>
						) : (
							''
						)}
						<h2 className={`m-0 fs-16 pb-2 fw-600`}>{t('Overview')}</h2>
						{data?.source?.length
							? data?.source.map((item: any, index: any) => {
									return (
										<div
											key={index}
											className='bureau-data-box cursor-pointer'
											onClick={() => {
												const myPromise = new Promise(function (myResolve) {
													setTimeout(function () {
														myResolve(
															history.push('report-details', {
																selectedTab: selectedTab,
																lead_id: lead_id,
																source: item.name,
																headerTitle: item.header,
															})
														);
													}, 10);
												});
												myPromise.then(() => {
													dispatch(
														bureauStatsDetails({
															selectedTab: selectedTab,
															source: item.name,
															headerTitle: item.header,
															leadId: lead_id,
														})
													);
												});
											}}
										>
											<div className='d-flex justify-content-between pb-1'>
												<div className='d-flex gap-3 align-items-center pb-1'>
													<h3 className='m-0 fs-16 fw-600 '>{t(item.name)}</h3>
													<img
														src='/images/icons/bright-arrow.svg'
														alt='arrrow'
													/>
												</div>
												<h3 className='m-0 fs-16 fw-bold'>{item.value}</h3>
											</div>
											<div className='d-flex justify-content-between align-items-center'>
												<h3 className='m-0 fs-12 text-grey'>
													{t(item?.label)}
												</h3>
												<IonChip
													className={`fs-12 `}
													style={{
														padding: '8px 8px 8px',
														height: '20px',
														'--background': item?.bg_color,
														'--color': item?.color,
													}}
												>
													{t(item.impacts)}
												</IonChip>
											</div>
										</div>
									);
							  })
							: ''}
					</div>
				</div>
			) : (
				<div className='bureau-report-no-data bueauData-container-height'>
					<div className='fs-14 m-0 text-center'>
						{dataLoading ? (
							<h4 className='fs-14 text-center text-grey'>
								{t('Your report is generating...')}
							</h4>
						) : (
							<>
								<IonImg
									src='/images/icons/bureau_invalid_retry.svg'
									style={{
										margin: 'auto',
										width: '46px',
										height: '55px',
									}}
									className='pb-2'
								/>
								<h4 className='fs-14 text-center text-grey'>
									{typeof bureauFetchError === 'string' && bureauFetchError
										? t(bureauFetchError)
										: ''}
								</h4>
								{bureauRetry ? (
									<div>
										<CustomButton
											expand='block'
											className='btn-transparent fs-16'
											fill='outline'
											onClick={getBureauData}
											title={t('Retry')}
											prefixIcon='/images/icons/retry-icon.svg'
										/>
									</div>
								) : (
									''
								)}
							</>
						)}
					</div>
				</div>
			)}
		</>
	);
};
