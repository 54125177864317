import { IonContent, IonFooter } from '@ionic/react';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory, useLocation } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	getIndustryMaster,
	getSubIndustryMaster,
	saveLenderSpcfInfo,
	lenderSpfDetailsFlow,
	fetchMasterData,
} from '../../../store/reducers/lead/leadSlice';
import {
	ContinueLaterPopUp,
	checkFieldsValidToContinue,
	showToastAlert,
} from '../../../helpers/functions';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { DynamicDropdown } from '../../../components/common';
import { ABFLOtherInfoSchema, OtherInfo } from './ABFLInfo';
import { LenderNameForAPICall } from '../../../helpers/lenders';
import './ABFLForm.scss';
import { OfferRejectedMsg } from '../OfferRejectedMsg/OfferRejectedMsg';

export const ABFLBusinessInfoForm: React.FC<any> = () => {
	const [industryList, setIndustry] = useState([]);
	const [subIndustryList, setSubIndustry]: any = useState([]);
	const [natureOfBusiness, setNatureOfBusiness]: any = useState([]);
	const [modalPageClose, setModalPageClose] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const { selectedLender, lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const location = useLocation<any>();
	useEffect(() => {
		if (location.pathname !== '/lead-lender-specific-form-steps') {
			return;
		}
		getIndustry();
		getNatureOfBusiness();
		if (lenderSpecificDetails?.lead_spf_detail_id) {
			getSubIndustry({
				detail: {
					value: lenderSpecificDetails?.lender_spf_details?.industry,
				},
			});
		}
	}, [location.pathname]);

	/**
	 * Get ABFL industry list from ABFL industry master.
	 */
	const getIndustry = async () => {
		await dispatch(
			getIndustryMaster({ param: `?lender_name=${LenderNameForAPICall.ABFL}` })
		)
			.then(unwrapResult)
			.then((result: any) => {
				setIndustry(result.data);
			});
	};

	/**
	 * Get ABFL sub industry list from ABFL industry master.
	 * Sub Industry will be came based on industry selection.
	 */
	const getSubIndustry = async (e: any) => {
		await dispatch(
			getSubIndustryMaster({
				param: `?industry_name=${e.detail.value}&lender_name=${LenderNameForAPICall.ABFL}`,
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				setSubIndustry(result.data);
			});
	};

	const getNatureOfBusiness = async () => {
		await dispatch(fetchMasterData({ param: 'ABFL_NATURE_OF_BUSINESS' }))
			.then(unwrapResult)
			.then((result: any) => {
				setNatureOfBusiness(result.data);
			});
	};

	const {
		handleSubmit,
		control,
		getValues,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(ABFLOtherInfoSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			industry: lenderSpecificDetails?.lender_spf_details?.industry || '',
			sub_industry:
				lenderSpecificDetails?.lender_spf_details?.sub_industry || '',
			nature_of_business:
				lenderSpecificDetails?.lender_spf_details?.nature_of_business || '',
		},
	});

	/**
	 * On submit to save user data and after that
	 * CREATE AND UPDATE ABFL USER API will be called from Backend
	 * @param data Form data
	 */
	const onSubmit = async (data: any, flag: any = '') => {
		const req: any = {
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			step: isValid && flag !== 'continue_later' ? 'step2' : 'step1',
			isNewLead: isValid && flag !== 'continue_later',
			lender_name: LenderNameForAPICall.ABFL,
			lender_spf_details: {
				...data,
				consents_timestamp: String(Date.parse(Date())),
			},
		};

		if (lenderSpecificDetails?.lead_spf_detail_id) {
			req.lead_spf_detail_id = lenderSpecificDetails?.lead_spf_detail_id;
		}

		await dispatch(saveLenderSpcfInfo({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					if (isValid && flag !== 'continue_later') {
						dispatch(
							lenderSpfDetailsFlow({
								...lenderSpecificDetails,
								lender_spf_details: {
									...lenderSpecificDetails?.lender_spf_details,
									...req.lender_spf_details,
								},
								lead_spf_detail_id: result.data.lead_spf_detail_id,
								lender_lead_id: result.data.customerID,
								step: 'step2',
							})
						);
					}
				} else {
					setShowOfferErrMsg(
						`${result?.error?.data?.errors[0]?.field} ${result?.error?.data?.errors[0]?.message}` ||
							'Please try again later !'
					);
				}
			});
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
							<form onSubmit={handleSubmit(onSubmit)}>
								{OtherInfo.map(
									({ name, type, placeholder }: any, index: any) => {
										const errorText: any =
											errors && Object.keys(errors).length > 0 && errors;
										return (
											<div key={index}>
												{type === 'dropdown' && (
													<div style={{ marginBottom: '15px' }}>
														<Controller
															control={control}
															render={({
																field: { onChange, onBlur, name },
															}) => (
																<DynamicDropdown
																	label={t(`${placeholder}`)}
																	data={
																		name === 'industry'
																			? industryList
																			: name === 'sub_industry'
																			? subIndustryList
																			: name === 'nature_of_business'
																			? natureOfBusiness
																			: []
																	}
																	name={name}
																	onChange={(e: any) => {
																		if (name === 'industry') {
																			getSubIndustry(e);
																		}
																		onChange(e);
																	}}
																	bindField={
																		name === 'industry'
																			? 'industry_name'
																			: name === 'sub_industry'
																			? 'sub_industry_name'
																			: 'value'
																	}
																	showField1={
																		name === 'industry'
																			? 'industry_name'
																			: name === 'sub_industry'
																			? 'sub_industry_name'
																			: 'value'
																	}
																	value={getValues(name)}
																	onBlur={onBlur}
																	errors={errorText[name]?.message}
																/>
															)}
															name={name}
														/>
													</div>
												)}
											</div>
										);
									}
								)}
							</form>
							<div className={` d-none d-md-block`}>
								<CustomButton
									className='w-100 fw-bold dashboard-add-lead-button mb-3'
									expand='block'
									fill='outline'
									title={t('Continue Later')}
									onClick={() => {
										if (checkFieldsValidToContinue(errors)) {
											setModalPageClose(true);
										} else {
											showToastAlert({
												type: 'error',
												message: 'Please fix above errors',
											});
										}
									}}
								/>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				)}
				<CustomModal
					needIonContentWraper={false}
					isOpen={modalPageClose}
					initialBreakpoint={undefined}
					handleClose={() => setModalPageClose(false)}
					breakpoints={[]}
					className='height-auto confirm-popup-middle continue-later'
					modalContent={ContinueLaterPopUp(
						() => {
							setModalPageClose(false);
						},
						() => {
							new Promise(function (myResolve) {
								setTimeout(function () {
									myResolve(history.push('/dashboard/lead'));
									onSubmit(getValues(), 'continue_later');
									setModalPageClose(false);
								}, 10);
							});
						},
						'Cancel',
						'Continue Later',
						'className',
						'Are You Sure You Want to Exit?',
						`${t('You can continue later from where you left off.')}`
					)}
					needCross={false}
					needArrow={false}
				/>
			</IonContent>

			{!showOfferErrMsg && (
				<IonFooter className='d-block d-md-none position-relative'>
					<div
						style={{
							backgroundColor: '#fff',
							zIndex: 2,
						}}
					>
						<div className={`ion-padding`}>
							<CustomButton
								className='w-100 fw-bold dashboard-add-lead-button mb-3'
								expand='block'
								fill='outline'
								title={t('Continue Later')}
								onClick={() => {
									if (checkFieldsValidToContinue(errors)) {
										setModalPageClose(true);
									} else {
										showToastAlert({
											type: 'error',
											message: 'Please fix above errors',
										});
									}
								}}
							/>
							<div className='proceed-btn'>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				</IonFooter>
			)}
		</>
	);
};
