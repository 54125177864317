import { createAction, createSlice } from '@reduxjs/toolkit';
import { apiAction } from '../../apiAction';
// stash
import { post, get, put } from '../../../helpers/constant';
import {
	ADD_LEAD,
	ADD_LEADS_MANAGER_LIST,
	DASHBOARD_ADD_LEAD_DISPOSITION,
	DASHBOARD_GET_ANDRO_MANAGER,
	DASHBOARD_LEAD_DETAILS,
	DASHBOARD_LEAD_GENERATE_OTP,
	DASHBOARD_LEAD_VERIFY_OTP,
	DASHBOARD_LEAD_STATUS_UPDATE,
	DASHBOARD_UPDATE_LEAD,
	DOWNLOAD_EQUIFAX_BUREAU_REPORT,
	FETCH_CIBIL_BUREAU_REPORT,
	FETCH_EQUIFAX_BUREAU_REPORT,
	GET_ALL_LENDERS,
	PROCESSINGBEGIN,
	GENERATE_CIBIL_OTP,
	VERIFY_CIBIL,
	EQUIFAXDETAILS,
	ADDLEADCOMMENTS,
	DASHBOARD_LEAD_ACTIVITY,
	VIEWLEADCOMMENTS,
	RESONSLISTDATA,
	DASHBOARD_LEAD_COUNTS,
	DASHBOARD_LEAD_PRODUCTS_TYPE,
	DASHBOARD_LEAD_List,
	EQUIFAX_ADDITIONAL_INFO_DETAILS,
	EQUIFAX_ADDITIONAL_INFO,
	USERLEADMANAGER,
	DOWNLOAD_BULK_LEAD_UPLOAD_TEMPLATE,
	LEADS_MASTER_DISPOSITIONS_REASON,
	LEADS_MASTER_DISPOSITIONS,
	DASHBOARD_LIFE_ASSURED,
	GENDER_DROPDOWN,
	MARTIAL_STATUS_DROPDOWN,
	QUALIFICATION,
	BAJAJ_NATURE_OF_BUSINESS,
	OCCUPATION_DROPDOWN,
	POLICY_STATUS_DROPDOWN,
	ADD_LENDERS,
	SELECTED_LENDERS,
	REMOVE_SELECTED_LENDERS,
	GET_NON_SELECTED_LENDERS,
	MARK_AS_LOGIN,
	MARK_AS_CLOSE,
	GET_LENDER_DETAILS,
	DASHBOARD_LIFE_ASSURED_UPDATE,
	LEADS_MASTER_DISPOSITIONS_NEW,
	APPLICATION_STATUS_DROPDOWN,
	LEAD_ADD_DISBURSAL,
	LEAD_LOAN_SANCTIONED,
	LEAD_LENDER_CALL_BACK,
	GET_SANCTIONED_DISBURSED_DETAILS,
	GET_LEAD_DISBURSAL_DATA,
	GET_LOGIN_SANTIONED_DATA,
	LEADS_CALL_INITIATED,
	GET_LENDER_WEB_LINK,
	CIBIL_CONSENT_DETAILS,
	CHECK_SCORE_VIDEO,
	CIBIL_REQUEST_CONSENT,
	UPLOAD_BULK_LEADS,
	LENDERS_BY_SUBPRODUCT,
	GET_LENDER_DETAILS_SB,
	DASHBOARD_LEAD_DETAILS_UM,
	TEAM_DMA_SUBPRODUCTS,
	GET_ALL_LEAD_STATUS,
	ALL_LENDERS,
	GET_LENDER_LOANS,
	GET_CONSULTANTS,
	GENERATE_ONLINE_LEAD,
	LEAD_ALLOCATOR_ALLOCATE_MANAGER,
	GET_CITIES2,
	BULKUPLOAD_NEW_LEAD_TEMPLATE_DOWNLOAD,
	BULK_UPLOAD_NEW_LEAD,
	BULKUPLOAD_ERROR_SHEET,
	GET_LENDER_SITE_LINK,
	CUSTOMER_SERVICE,
	DESIGNATION_LIST,
	BULKUPLOAD_NEW_ERROR_SHEET,
	CIBIL_CONSENT_UPDATE,
	CIBILDETAIL,
	GET_LEAD_FLOW_URL,
	GET_ALL_CITY,
	GET_CITYWISE_RM_LIST,
	UPDATE_RM_DETAILS,
	GET_COMMON_FIELDS_PL,
	FETCH_COMMON_FIELDS_PL,
	POST_COMMON_FIELDS_PL,
	GET_LENDER_PAGE_WISE_STATUS,
	LENDER_SPFC_ADD_INFO,
	LENDER_SPFC_FETCH_INFO,
	LENDER_GET_INDUSTRY,
	LENDER_GET_SUB_INDUSTRY,
	LENDER_GENERATE_OFFER,
	LENDER_ACCEPT_OFFER,
	LENDER_ELIGIBLITY_CHECK,
	LENDER_DOCUMENT_UPLOAD,
	LENDER_GENERATE_OTP,
	LENDER_VERIFY_OTP,
	FETCH_MASTER_DATA,
	ALL_RELIGION,
	GET_RM_DETAILS,
	LEAD_STATUS_CHECK,
	APPLICATION_STATUS,
	LENDER_BANK_CONNECT,
	LENDER_GST_CONNECT,
	LENDER_TATA_PL_GENERATE_OTP,
	LENDER_TATA_PL_VERIFY_OTP,
	LENDER_PARTNER_ELIGIBLITY,
	LENDER_UPDATE_LEAD,
	LENDER_BANK_STMT,
	LENDER_TATA_CKYC,
	LENDER_TATA_UPLOAD_DOCUMENT,
	CREATE_DOCKET,
	GET_APPLICATION_STATUS,
	LENDER_GET_OFFER,
	LENDER_FETCH_SESSION,
	LENDER_PERFIOS_DOC_UPLOAD,
	LENDER_PERFIOS_FETCH_STATUS,
	LENDER_PRODUCT_FETCH,
	LENDER_SUB_PRODUCT_FETCH,
	LENDER_LOAN_PURPOSE_FETCH,
	LENDER_BRANCH_FETCH,
	LENDER_PINCODE_DETAILS_FETCH,
	LEAD_MIGRATION,
	BAJAJ_TOKEN_GENERATE,
	BAJAJ_LEAD_ASSIGN,
	BAJAJ_RM_LIST,
	BAJAJ_CREATE_LOAN,
	BAJAJ_UPLOAD_DOC,
	BAJAJ_LEAD_STATUS,
	LENDER_SECOND_PARTNER_ELIGIBLITY,
	LEAD_CLOSE,
	PNB_FILE_DOWNLOAD,
	PNB_ESIGN_GET_STATUS,
	LEAD_CLOSE_API,
	LENDINGKART_RM_LIST,
	ABFL_REGULATORY,
	PNB_ESIGN_GET_DOCUMENT,
	PNB_ESIGN_PAYMENT_LINK,
	LENDER_RM_FETCH,
	MONEYTAP_LENDER_LINK,
	LENDER_PNB_UPLOAD_DOCUMENT,
	PNB_PAYMENT_STATUS,
	PNB_PAYMENT_DECRYPT,
	GET_ALL_LAS_LENDERS,
	LEAD_ENC_PAN,
	LEAD_DEC_PAN,
	LAS_PORTFOLIO_UPDATE_DETAILS,
	LAS_CUSTOMER_UPLOAD,
	LAS_OVERWRITE_DATA,
} from '../../../helpers/api-endpoints';

const initialState = {
	defaultPageSize: 10, // do not change this
	defaultPageId: 1, // do not change this
	pageSize: 10,
	pageId: 1,

	searchTerm: '',
	followUpDate: '',
	cstCity: '',
	cstDesignation: '',
};

export const addlead = apiAction('api/add-lead', post, ADD_LEAD);

export const leadManagerList = apiAction(
	'api/lead-manager-list',
	get,
	ADD_LEADS_MANAGER_LIST
);
export const dashboardLeadCount = apiAction(
	'dashboard/lead-count',
	get,
	DASHBOARD_LEAD_COUNTS
);
export const dashboardLeadProductType = apiAction(
	'dashboard/lead/product/type',
	get,
	DASHBOARD_LEAD_PRODUCTS_TYPE
);
export const dashboardGetAndroManager = apiAction(
	'api/dashboard-get-andro-manager',
	get,
	DASHBOARD_GET_ANDRO_MANAGER
);
export const dashboardLeadGenerateOtp = apiAction(
	'api/lead-lead-generate-otp',
	post,
	DASHBOARD_LEAD_GENERATE_OTP
);

export const apiGetLeadDetails = apiAction(
	'api/insurance/getLeadDetails',
	get,
	DASHBOARD_LEAD_DETAILS
);
export const getLenderBySubproduct = apiAction(
	'api/get_lender_by_subproduct',
	get,
	LENDERS_BY_SUBPRODUCT
);
export const getAllLenders = apiAction('api/get_lenders', get, ALL_LENDERS);
export const getLenderDetailsBySubproduct = apiAction(
	'api/get_lenderdetails_by_subproduct',
	get,
	GET_LENDER_DETAILS_SB
);
export const dashboardLeadDetails = apiAction(
	'api/dashboard-lead-details',
	get,
	DASHBOARD_LEAD_DETAILS
);
export const apiUpdateLeadDetails = apiAction(
	'api/insurance/updateLeadDetails',
	post,
	DASHBOARD_UPDATE_LEAD
);
export const lifeAssuredApi = apiAction(
	'api/lead/life_assured',
	post,
	DASHBOARD_LIFE_ASSURED
);
export const getLeadUmDetails = apiAction(
	'api/um-lead-details',
	get,
	DASHBOARD_LEAD_DETAILS_UM
);
export const updateLifeAssuredApi = apiAction(
	'api/lead/update_life_assured',
	post,
	DASHBOARD_LIFE_ASSURED_UPDATE
);
export const apiGenderDropdown = apiAction(
	'api/lead/genderDropdown',
	get,
	GENDER_DROPDOWN
);
export const apiOccupationDropdown = apiAction(
	'api/lead/OCCUPATION',
	get,
	OCCUPATION_DROPDOWN
);
export const apiPolicyDropdown = apiAction(
	'api/lead/POLICY_STATUS',
	get,
	POLICY_STATUS_DROPDOWN
);
export const apiMaritalDropdown = apiAction(
	'api/lead/MARITAL_STATUS',
	get,
	MARTIAL_STATUS_DROPDOWN
);

export const apiQualificationDropdown = apiAction(
	'api/lead/QUALIFICATION',
	get,
	QUALIFICATION
);

export const apiNatureOfBusinessDropdown = apiAction(
	'api/lead/BAJAJ_NATURE_OF_BUSINESS',
	get,
	BAJAJ_NATURE_OF_BUSINESS
);

export const apiAllReligionDropdown = apiAction(
	'api/lead/ALL_RELIGION',
	get,
	ALL_RELIGION
);

export const dashboardUpdateLead = apiAction(
	'api/dashboard-update-lead',
	post,
	DASHBOARD_UPDATE_LEAD
);

export const generateOnlineLead = apiAction(
	'api/generate-online-lead',
	post,
	GENERATE_ONLINE_LEAD
);

export const leadCallInitiated = apiAction(
	'api/lead-call-initiated-api',
	post,
	LEADS_CALL_INITIATED
);

export const getAllLendersList = apiAction(
	'api/get-all-lender',
	get,
	GET_ALL_LENDERS
);

export const getAllLASLendersList = apiAction(
	'api/get-all-las-lender',
	get,
	GET_ALL_LAS_LENDERS
);

export const addLendersList = apiAction('api/add-lender', post, ADD_LENDERS);

export const getSelectedLendersList = apiAction(
	'api/get-selected-lender',
	get,
	SELECTED_LENDERS
);

export const removeSelectedLendersList = apiAction(
	'api/remove-selected-lender',
	post,
	REMOVE_SELECTED_LENDERS
);

export const getNonSelectedLendersList = apiAction(
	'api/get-non-selected-lender-list',
	get,
	GET_NON_SELECTED_LENDERS
);

export const getLenderDetails = apiAction(
	'api/get-selected-lender-details',
	get,
	GET_LENDER_DETAILS
);

export const lenderMarkAsLogin = apiAction(
	'api/lender-mark-as-login',
	post,
	MARK_AS_LOGIN
);

export const lenderMarkAsClose = apiAction(
	'api/lender-mark-as-close',
	post,
	MARK_AS_CLOSE
);

export const downloadEquifaxBureauReport = apiAction(
	'api/download-equifax-bureau-report',
	get,
	DOWNLOAD_EQUIFAX_BUREAU_REPORT
);

export const downloadBulkLeadUploadTemplate = apiAction(
	'api/download-bulk-lead-upload-template',
	get,
	DOWNLOAD_BULK_LEAD_UPLOAD_TEMPLATE
);

export const bulkUploadNewLeadTemplate = apiAction(
	'api/bulk-upload-new-lead-template',
	get,
	BULKUPLOAD_NEW_LEAD_TEMPLATE_DOWNLOAD
);

export const bulkUploadNewLead = apiAction(
	'api/bulk-upload-new-lead',
	post,
	BULK_UPLOAD_NEW_LEAD
);

export const bulkUploadDisbursedNewLeadErrorEntries = apiAction(
	'api/bulk-upload-new-lead-disbursed',
	get,
	BULKUPLOAD_ERROR_SHEET
);

export const bulkUploadNewLeadErrorEntries = apiAction(
	'api/bulk-upload-new-lead',
	get,
	BULKUPLOAD_NEW_ERROR_SHEET
);

export const uploadBulkNewLead = apiAction(
	'api/upload-new-bulk-lead',
	post,
	UPLOAD_BULK_LEADS
);

export const fetchEquifaxBureauReport = apiAction(
	'api/fetch-equifax-bureau-report',
	post,
	FETCH_EQUIFAX_BUREAU_REPORT
);

export const fetchCibilBureauReport = apiAction(
	'api/fetch-cibil-bureau-report',
	get,
	FETCH_CIBIL_BUREAU_REPORT
);

export const processingBegin = apiAction(
	'api/processingBegin',
	post,
	PROCESSINGBEGIN
);

export const downloadLeadVerifyOtp = apiAction(
	'api/download-lead-verify-otp',
	post,
	DASHBOARD_LEAD_VERIFY_OTP
);

export const cbilConsentDetails = apiAction(
	'api/cibil-consent-details',
	get,
	CIBIL_CONSENT_DETAILS
);

export const cbilRequestConsentDetails = apiAction(
	'api/cibil-request-consent-details',
	get,
	CIBIL_REQUEST_CONSENT
);

export const cbilRequestUpdateConsentDetails = apiAction(
	'api/cibil-request-update-consents',
	put,
	CIBIL_CONSENT_UPDATE
);

export const addLeadDisposition = apiAction(
	'api/add/lead-disposition',
	post,
	DASHBOARD_ADD_LEAD_DISPOSITION
);
export const updateLeadStatus = apiAction(
	'api/update/lead-status',
	put,
	DASHBOARD_LEAD_STATUS_UPDATE
);

export const generateCibilOtp = apiAction(
	'api/generate-cibil-otp',
	post,
	GENERATE_CIBIL_OTP
);

export const equifaxDetails = apiAction(
	'api/equifax-details',
	get,
	EQUIFAXDETAILS
);

export const cbilDetails = apiAction(
	'api/cbil-inner-details',
	get,
	CIBILDETAIL
);

export const getMasterDispositions = apiAction(
	'api/update-stage-master-disposition',
	get,
	LEADS_MASTER_DISPOSITIONS
);

export const getMasterDispositionsUpdateStage = apiAction(
	'api/update-stage-master-disposition-new',
	get,
	LEADS_MASTER_DISPOSITIONS_NEW
);

export const getMasterDispositionReasons = apiAction(
	'api/update-stage-master-disposition-reasons',
	get,
	LEADS_MASTER_DISPOSITIONS_REASON
);

export const addLeadComments = apiAction(
	'api/add-lead-comments',
	post,
	ADDLEADCOMMENTS
);

export const dashboardLeadActivity = apiAction(
	'api/dashboardLeadActivity',
	get,
	DASHBOARD_LEAD_ACTIVITY
);

export const viewLeadComments = apiAction(
	'api/view-lead-comments',
	get,
	VIEWLEADCOMMENTS
);

export const reasonListData = apiAction(
	'api/reason-list-data',
	get,
	RESONSLISTDATA
);

export const dashboardLeadList = apiAction(
	'api/dashboard-lead-list',
	get,
	DASHBOARD_LEAD_List
);

export const equifaxAdditionalInfoDetail = apiAction(
	'api/equifax-additional-info-detail',
	get,
	EQUIFAX_ADDITIONAL_INFO_DETAILS
);

export const equifaxAdditionalInfo = apiAction(
	'api/equifax-additional-info',
	get,
	EQUIFAX_ADDITIONAL_INFO
);

export const getLeadManagers = apiAction(
	'api/lead-manager',
	get,
	USERLEADMANAGER
);

export const getApplicationStatus = apiAction(
	'api/application-status',
	get,
	APPLICATION_STATUS_DROPDOWN
);

export const getsanctionedDisbursedDetails = apiAction(
	'api/get-sanction-disburse-details',
	get,
	GET_SANCTIONED_DISBURSED_DETAILS
);

export const leadLoanSanctioned = apiAction(
	'api/get-lender-amount-detail',
	put,
	LEAD_LOAN_SANCTIONED
);

export const leadDisbursal = apiAction(
	'api/lead-disbursal',
	post,
	LEAD_ADD_DISBURSAL
);

export const leadLenderCallBack = apiAction(
	'api/lead-lender-call-back',
	post,
	LEAD_LENDER_CALL_BACK
);

export const getLeadDisbursalData = apiAction(
	'api/get-lead-disbursal-data',
	get,
	GET_LEAD_DISBURSAL_DATA
);

export const getLoginSanctionedData = apiAction(
	'api/get-login-santioned-data',
	get,
	GET_LOGIN_SANTIONED_DATA
);

export const getLenderWebLink = apiAction(
	'api/get-lender-web-link',
	get,
	GET_LENDER_WEB_LINK
);

export const getLenderSiteLink = apiAction(
	'api/get-lender-site-link',
	get,
	GET_LENDER_SITE_LINK
);

export const filterSubProducts = apiAction(
	'api/filter-data/subproducts',
	get,
	TEAM_DMA_SUBPRODUCTS
);

export const filterStatus = apiAction(
	'api/filter-data/status',
	get,
	GET_ALL_LEAD_STATUS
);

export const allocateManager = apiAction(
	'api/allocate-manager',
	post,
	LEAD_ALLOCATOR_ALLOCATE_MANAGER
);

export const lenderTataPLGenerateOTP = apiAction(
	`api/${LENDER_TATA_PL_GENERATE_OTP}`,
	post,
	LENDER_TATA_PL_GENERATE_OTP
);

export const lenderTataPLVerifyOTP = apiAction(
	`api/${LENDER_TATA_PL_VERIFY_OTP}`,
	post,
	LENDER_TATA_PL_VERIFY_OTP
);
export const lenderTataPartnerEligiblity = apiAction(
	`api/${LENDER_PARTNER_ELIGIBLITY}`,
	post,
	LENDER_PARTNER_ELIGIBLITY
);
export const lenderGetOffer = apiAction(
	`api/${LENDER_GET_OFFER}`,
	post,
	LENDER_GET_OFFER
);
export const lenderTataUpdateLead = apiAction(
	`api/${LENDER_UPDATE_LEAD}`,
	post,
	LENDER_UPDATE_LEAD
);

export const lenderTataCheckCkyc = apiAction(
	`api/${LENDER_TATA_CKYC}`,
	post,
	LENDER_TATA_CKYC
);

export const lenderTataSecondPartnerEligiblity = apiAction(
	`api/${LENDER_SECOND_PARTNER_ELIGIBLITY}`,
	post,
	LENDER_SECOND_PARTNER_ELIGIBLITY
);

export const lenderTataFetchBankStmt = apiAction(
	`api/${LENDER_BANK_STMT}`,
	post,
	LENDER_BANK_STMT
);
export const lenderTataUploadDocument = apiAction(
	`api/${LENDER_TATA_UPLOAD_DOCUMENT}`,
	post,
	LENDER_TATA_UPLOAD_DOCUMENT
);

export const lenderPnbUploadDocument = apiAction(
	`api/${LENDER_PNB_UPLOAD_DOCUMENT}`,
	post,
	LENDER_PNB_UPLOAD_DOCUMENT
);
export const lenderTataDocket = apiAction(
	`api/${CREATE_DOCKET}`,
	post,
	CREATE_DOCKET
);

export const lenderTataApplicationStatus = apiAction(
	`api/${GET_APPLICATION_STATUS}`,
	post,
	GET_APPLICATION_STATUS
);
export const getCitiesNew = apiAction('api/get-cities', get, GET_CITIES2);
export const loanSanctioned = apiAction('api/lead-loan-sanctioned', post);
export const verifyCibil = apiAction('api/verify-cibil', post, VERIFY_CIBIL);
export const resetLeadState = createAction('Reset_all');
export const resetLeadFilterState = createAction('reset_lead_filter');

export const checkCreditScoreVideo = apiAction(
	'api/check-credit-score-video',
	get,
	CHECK_SCORE_VIDEO
);
export const getLenderProducts = apiAction(
	'api/get-lender-products',
	get,
	GET_LENDER_LOANS
);
export const getLenderConsultations = apiAction(
	'api/get-lender-consultations',
	get,
	GET_CONSULTANTS
);
export const getCustomerService = apiAction(
	'api/customer-service',
	post,
	CUSTOMER_SERVICE
);
export const getDesignations = apiAction(
	'api/designations',
	get,
	DESIGNATION_LIST
);
export const getLeadFlowUrl = apiAction(
	'api/lead-flow-url',
	get,
	GET_LEAD_FLOW_URL
);

export const getCity = apiAction('api/city', get, GET_ALL_CITY);
export const getLenderRM = apiAction('api/rm', get, GET_RM_DETAILS);
export const getRMManagerByCity = apiAction(
	'api/rmmanager',
	post,
	GET_CITYWISE_RM_LIST
);

export const getCommonFieldsDataPl = apiAction(
	'lead/common_fields',
	get,
	GET_COMMON_FIELDS_PL
);

export const getLenderPageWiseStatus = apiAction(
	'lead/ender_page_wise_status/id',
	get,
	GET_LENDER_PAGE_WISE_STATUS
);

export const fetchCommonFieldsDataPl = apiAction(
	'lead/common_data_fetch',
	get,
	FETCH_COMMON_FIELDS_PL
);

export const postCommonFieldsDataPl = apiAction(
	'lead/lead_generate/id',
	post,
	POST_COMMON_FIELDS_PL
);

export const updateRMManager = apiAction(
	'api/update-rmmanager',
	post,
	UPDATE_RM_DETAILS
);

export const saveLenderSpcfInfo = apiAction(
	`api/${LENDER_SPFC_ADD_INFO}`,
	post,
	LENDER_SPFC_ADD_INFO
);
export const abflRegulatory = apiAction(
	`api/${ABFL_REGULATORY}`,
	post,
	ABFL_REGULATORY
);

export const pnbFormDownload = apiAction(
	`api/${PNB_FILE_DOWNLOAD}`,
	post,
	PNB_FILE_DOWNLOAD
);

export const pnbEsignGetStatus = apiAction(
	`api/${PNB_ESIGN_GET_STATUS}`,
	post,
	PNB_ESIGN_GET_STATUS
);

export const pnbPaymentStatus = apiAction(
	`api/${PNB_PAYMENT_STATUS}`,
	post,
	PNB_PAYMENT_STATUS
);

export const pnbPaymentDecrypt = apiAction(
	`api/${PNB_PAYMENT_DECRYPT}`,
	post,
	PNB_PAYMENT_DECRYPT
);

export const pnbEsignGetDocument = apiAction(
	`api/${PNB_ESIGN_GET_DOCUMENT}`,
	post,
	PNB_ESIGN_GET_DOCUMENT
);

export const pnbEsignPaymentLink = apiAction(
	`api/${PNB_ESIGN_PAYMENT_LINK}`,
	post,
	PNB_ESIGN_PAYMENT_LINK
);

export const lendingKartRmList = apiAction(
	`api/${LENDINGKART_RM_LIST}`,
	get,
	LENDINGKART_RM_LIST
);

export const getLenderSpcfInfo = apiAction(
	`api/${LENDER_SPFC_FETCH_INFO}`,
	post,
	LENDER_SPFC_FETCH_INFO
);

export const lenderDocumentUpload = apiAction(
	`api/${LENDER_DOCUMENT_UPLOAD}`,
	post,
	LENDER_DOCUMENT_UPLOAD
);

export const lenderEligiblityCheck = apiAction(
	`api/${LENDER_ELIGIBLITY_CHECK}`,
	post,
	LENDER_ELIGIBLITY_CHECK
);

export const fetchMasterData = apiAction(
	`api/${FETCH_MASTER_DATA}`,
	get,
	FETCH_MASTER_DATA
);

export const fetchLeadStatusCheck = apiAction(
	`api/${LEAD_STATUS_CHECK}`,
	post,
	LEAD_STATUS_CHECK
);
export const applicationStatusCheck = apiAction(
	`api/${APPLICATION_STATUS}`,
	get,
	APPLICATION_STATUS
);
export const getIndustryMaster = apiAction(
	`api/${LENDER_GET_INDUSTRY}`,
	get,
	LENDER_GET_INDUSTRY
);

export const getSubIndustryMaster = apiAction(
	`api/${LENDER_GET_SUB_INDUSTRY}`,
	post,
	LENDER_GET_SUB_INDUSTRY
);
export const lenderOfferGenerate = apiAction(
	`api/${LENDER_GENERATE_OFFER}`,
	post,
	LENDER_GENERATE_OFFER
);
export const lenderGenerateOTP = apiAction(
	`api/${LENDER_GENERATE_OTP}`,
	post,
	LENDER_GENERATE_OTP
);

export const lenderVerifyOTP = apiAction(
	`api/${LENDER_VERIFY_OTP}`,
	post,
	LENDER_VERIFY_OTP
);
export const lenderOfferAccept = apiAction(
	`api/${LENDER_ACCEPT_OFFER}`,
	post,
	LENDER_ACCEPT_OFFER
);
export const lenderBankConnect = apiAction(
	`api/${LENDER_BANK_CONNECT}`,
	post,
	LENDER_BANK_CONNECT
);
export const lenderGstConnect = apiAction(
	`api/${LENDER_GST_CONNECT}`,
	post,
	LENDER_GST_CONNECT
);
export const lenderFetchSession = apiAction(
	`api/${LENDER_FETCH_SESSION}`,
	post,
	LENDER_FETCH_SESSION
);
export const moneytapLenderLink = apiAction(
	`api/${MONEYTAP_LENDER_LINK}`,
	post,
	MONEYTAP_LENDER_LINK
);
export const lenderPerfiosDocUpload = apiAction(
	`api/${LENDER_PERFIOS_DOC_UPLOAD}`,
	post,
	LENDER_PERFIOS_DOC_UPLOAD
);
export const lenderPerfiosFetchStatus = apiAction(
	`api/${LENDER_PERFIOS_FETCH_STATUS}`,
	post,
	LENDER_PERFIOS_FETCH_STATUS
);
export const lenderProductFetch = apiAction(
	`api/${LENDER_PRODUCT_FETCH}`,
	post,
	LENDER_PRODUCT_FETCH
);
export const lenderSubProductFetch = apiAction(
	`api/${LENDER_SUB_PRODUCT_FETCH}`,
	post,
	LENDER_SUB_PRODUCT_FETCH
);
export const lenderLoanPurposeFetch = apiAction(
	`api/${LENDER_LOAN_PURPOSE_FETCH}`,
	post,
	LENDER_LOAN_PURPOSE_FETCH
);
export const lenderBranchFetch = apiAction(
	`api/${LENDER_BRANCH_FETCH}`,
	post,
	LENDER_BRANCH_FETCH
);
export const lenderPincodeDetailsFetch = apiAction(
	`api/${LENDER_PINCODE_DETAILS_FETCH}`,
	post,
	LENDER_PINCODE_DETAILS_FETCH
);

export const leadMigrationUpload = apiAction(
	`api/${LEAD_MIGRATION}`,
	post,
	LEAD_MIGRATION
);

export const bajajGetToken = apiAction(
	`api/${BAJAJ_TOKEN_GENERATE}`,
	post,
	BAJAJ_TOKEN_GENERATE
);

export const bajajAssignLead = apiAction(
	`api/${BAJAJ_LEAD_ASSIGN}`,
	post,
	BAJAJ_LEAD_ASSIGN
);
export const bajajRmList = apiAction(
	`api/${BAJAJ_RM_LIST}`,
	post,
	BAJAJ_RM_LIST
);
export const bajajBLLAPCreateLoan = apiAction(
	`api/${BAJAJ_CREATE_LOAN}`,
	post,
	BAJAJ_CREATE_LOAN
);
export const bajajUploadDoc = apiAction(
	`api/${BAJAJ_UPLOAD_DOC}`,
	post,
	BAJAJ_UPLOAD_DOC
);
export const bajajGetLeadStatus = apiAction(
	`api/${BAJAJ_LEAD_STATUS}`,
	post,
	BAJAJ_LEAD_STATUS
);
export const lenderRmFetch = apiAction(
	`api/${LENDER_RM_FETCH}`,
	post,
	LENDER_RM_FETCH
);
LEAD_CLOSE;

export const lenderLeadClose = apiAction(`api/${LEAD_CLOSE}`, post, LEAD_CLOSE);
export const leadClose = apiAction(
	`api/${LEAD_CLOSE_API}`,
	post,
	LEAD_CLOSE_API
);

export const getPanNo = apiAction(`api/${LEAD_ENC_PAN}`, get, LEAD_ENC_PAN);

export const postPanNo = apiAction(`api/${LEAD_DEC_PAN}`, post, LEAD_DEC_PAN);

export const updatePortfolioDetailsLas = apiAction(
	'api/update/portfolio-las',
	put,
	LAS_PORTFOLIO_UPDATE_DETAILS
);

export const customerUploadLas = apiAction(
	`api/las/${LAS_CUSTOMER_UPLOAD}`,
	post,
	LAS_CUSTOMER_UPLOAD
);

export const overWriteDataLas = apiAction(
	`api/${LAS_OVERWRITE_DATA}`,
	post,
	LAS_OVERWRITE_DATA
);

export const leadSlice = createSlice({
	name: 'lead',
	initialState: initialState,
	reducers: {
		paginationSize: (state: any, action) => {
			state.pageSize = action.payload.pageSize;
			state.pageId = action.payload.pageId;
			state.searchTerm = action.payload.searchTerm;
		},
		leadFlow: (state: any, action) => {
			state.leadFlowData = action.payload;
		},
		activityInfo: (state: any, action) => {
			state.infoActivity = action.payload;
		},
		fetchBureauCustomerReport: (state: any, action) => {
			state.fetchBureauCustomerReportData = action.payload;
		},
		leadBureauCustomer: (state: any, action) => {
			state.leadBureauCustomerData = action.payload;
		},
		bureauStatsDetails: (state: any, action) => {
			state.bureauStatsDetailsData = action.payload;
		},
		lenderListData: (state: any, action) => {
			state.lenderListData = action.payload;
		},
		addMoreLenders: (state: any, action) => {
			state.addMoreLenders = action.payload;
		},
		lenderUpdateStatus: (state: any, action) => {
			state.lenderUpdateStatus = action.payload;
		},
		lenderSelectionProcess: (state: any, action) => {
			state.lenderSelectionProcess = action.payload;
		},
		selectedLender: (state: any, action) => {
			state.selectedLender = action.payload;
		},
		leadFilter: (state: any, action) => {
			state.followUpDate = action.payload.followUpDate;
			state.fromDate = action.payload.fromDate;
			state.toDate = action.payload.toDate;
			state.locationType = action.payload.locationType;
			state.subproduct = action.payload.subproduct;
			state.status = action.payload.status;
			state.lender = action.payload.lender;
			state.dmaOwner = action.payload.dmaOwner;
		},
		activeLender: (state: any, action) => {
			state.activeLender = action.payload;
		},
		markAsClose: (state: any, action) => {
			state.isMarkAsClose = action.payload;
		},
		productFlow: (state: any, action) => {
			state.productFlowData = action.payload;
		},
		productCosnultationFilter: (state: any, action: any) => {
			state.cstCity = action.payload.cstCity;
			state.cstDesignation = action.payload.cstDesignation;
			state.searchTerm = action.payload.searchTerm;
			state.citySelection = action.payload.citySelection;
			state.designationSelection = action.payload.designationSelection;
		},
		lenderSpfDetailsFlow: (state: any, action) => {
			state.lenderSpecificDetails = action.payload;
		},
		leadCreationtype: (state: any, action) => {
			state.leadcreatedthrough = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(resetLeadState, () => initialState)
			.addCase(getCity.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getCity.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(getCity.rejected, (state: any, action: any) => {
				updateRMManager;
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(getRMManagerByCity.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getRMManagerByCity.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(getRMManagerByCity.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(updateRMManager.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(updateRMManager.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(updateRMManager.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(getLeadFlowUrl.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getLeadFlowUrl.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(getLeadFlowUrl.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(getLenderConsultations.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getLenderConsultations.fulfilled, (state: any, action: any) => {
				state.designation = action?.payload?.designations;
				state.isLoading = false;
			})
			.addCase(getLenderConsultations.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(getDesignations.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getDesignations.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(getDesignations.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(getCustomerService.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getCustomerService.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(getCustomerService.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(getLenderProducts.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getLenderProducts.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(getLenderProducts.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(getCommonFieldsDataPl.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getCommonFieldsDataPl.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(getCommonFieldsDataPl.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(getLenderPageWiseStatus.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getLenderPageWiseStatus.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(getLenderPageWiseStatus.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(fetchCommonFieldsDataPl.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(fetchCommonFieldsDataPl.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(fetchCommonFieldsDataPl.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(postCommonFieldsDataPl.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(postCommonFieldsDataPl.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(postCommonFieldsDataPl.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(getLenderBySubproduct.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getLenderBySubproduct.fulfilled, (state: any, action: any) => {
				state.isLoading = false;
				state.lenderBySubproduct = action?.payload;
			})
			.addCase(getLenderBySubproduct.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(getAllLenders.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getAllLenders.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(getAllLenders.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(getLenderDetailsBySubproduct.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(
				getLenderDetailsBySubproduct.fulfilled,
				(state: any, action: any) => {
					state.isLoading = false;
					state.lenderBySubproduct = action?.payload;
				}
			)
			.addCase(
				getLenderDetailsBySubproduct.rejected,
				(state: any, action: any) => {
					state.isLoading = false;
					state.isError = true;
					state.message = action.payload;
				}
			)
			.addCase(lenderBankConnect.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderBankConnect.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(lenderBankConnect.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(lenderGstConnect.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderGstConnect.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(lenderGstConnect.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(addlead.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(addlead.fulfilled, (state: any, action: any) => {
				state.isLoading = false;
				state.added_lead = action?.payload;
			})
			.addCase(addlead.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(getLeadUmDetails.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getLeadUmDetails.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(getLeadUmDetails.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(apiGetLeadDetails.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(apiGetLeadDetails.fulfilled, (state: any, action: any) => {
				state.isLoading = false;
				state.added_lead = action?.payload;
			})
			.addCase(apiGetLeadDetails.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(apiUpdateLeadDetails.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(apiUpdateLeadDetails.fulfilled, (state: any, action: any) => {
				state.isLoading = false;
				state.added_lead = action?.payload;
			})
			.addCase(apiUpdateLeadDetails.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(lifeAssuredApi.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lifeAssuredApi.fulfilled, (state: any, action: any) => {
				state.isLoading = false;
				state.added_lead = action?.payload;
			})
			.addCase(lifeAssuredApi.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(updateLifeAssuredApi.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(updateLifeAssuredApi.fulfilled, (state: any, action: any) => {
				state.isLoading = false;
				state.added_lead = action?.payload;
			})
			.addCase(updateLifeAssuredApi.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(apiGenderDropdown.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(apiGenderDropdown.fulfilled, (state: any, action: any) => {
				state.isLoading = false;
				state.added_lead = action?.payload;
			})
			.addCase(apiGenderDropdown.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(apiMaritalDropdown.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(apiMaritalDropdown.fulfilled, (state: any, action: any) => {
				state.isLoading = false;
				state.added_lead = action?.payload;
			})
			.addCase(apiMaritalDropdown.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(apiQualificationDropdown.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(
				apiQualificationDropdown.fulfilled,
				(state: any, action: any) => {
					state.isLoading = false;
					state.added_lead = action?.payload;
				}
			)
			.addCase(apiQualificationDropdown.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(apiNatureOfBusinessDropdown.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(
				apiNatureOfBusinessDropdown.fulfilled,
				(state: any, action: any) => {
					state.isLoading = false;
					state.added_lead = action?.payload;
				}
			)
			.addCase(
				apiNatureOfBusinessDropdown.rejected,
				(state: any, action: any) => {
					state.isLoading = false;
					state.isError = true;
					state.message = action.payload;
				}
			)
			.addCase(apiAllReligionDropdown.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(apiAllReligionDropdown.fulfilled, (state: any, action: any) => {
				state.isLoading = false;
				state.added_lead = action?.payload;
			})
			.addCase(apiAllReligionDropdown.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(leadManagerList.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(leadManagerList.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(leadManagerList.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(dashboardLeadGenerateOtp.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(dashboardLeadGenerateOtp.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(dashboardLeadGenerateOtp.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(dashboardLeadDetails.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(dashboardLeadDetails.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(dashboardLeadDetails.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(dashboardUpdateLead.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(dashboardUpdateLead.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(dashboardUpdateLead.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(generateOnlineLead.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(generateOnlineLead.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(generateOnlineLead.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(getAllLendersList.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getAllLendersList.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(getAllLendersList.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(getAllLASLendersList.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getAllLASLendersList.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(getAllLASLendersList.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(downloadEquifaxBureauReport.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(downloadEquifaxBureauReport.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(downloadEquifaxBureauReport.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(fetchEquifaxBureauReport.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(fetchEquifaxBureauReport.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(fetchEquifaxBureauReport.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(fetchCibilBureauReport.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(fetchCibilBureauReport.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(fetchCibilBureauReport.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(processingBegin.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(processingBegin.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})

			.addCase(processingBegin.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(updateLeadStatus.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(updateLeadStatus.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})

			.addCase(verifyCibil.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(verifyCibil.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(verifyCibil.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})

			.addCase(updateLeadStatus.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(addLeadDisposition.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(addLeadDisposition.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})

			.addCase(equifaxDetails.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(equifaxDetails.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(equifaxDetails.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})

			.addCase(dashboardLeadActivity.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(dashboardLeadActivity.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(dashboardLeadActivity.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})

			.addCase(addLeadComments.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(addLeadComments.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(addLeadComments.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})

			.addCase(reasonListData.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(reasonListData.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(reasonListData.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})

			.addCase(addLeadDisposition.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(dashboardLeadCount.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(dashboardLeadCount.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(dashboardLeadCount.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(dashboardLeadProductType.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(dashboardLeadProductType.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(dashboardLeadProductType.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(dashboardLeadList.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(dashboardLeadList.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(dashboardLeadList.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(equifaxAdditionalInfoDetail.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(equifaxAdditionalInfoDetail.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(equifaxAdditionalInfoDetail.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(equifaxAdditionalInfo.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(equifaxAdditionalInfo.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(equifaxAdditionalInfo.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(getLeadManagers.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getLeadManagers.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(getLeadManagers.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(downloadLeadVerifyOtp.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(downloadLeadVerifyOtp.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(downloadLeadVerifyOtp.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(downloadBulkLeadUploadTemplate.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(downloadBulkLeadUploadTemplate.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(downloadBulkLeadUploadTemplate.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(getNonSelectedLendersList.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getNonSelectedLendersList.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(getNonSelectedLendersList.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(lenderMarkAsClose.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderMarkAsClose.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(lenderMarkAsClose.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(getApplicationStatus.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getApplicationStatus.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(getApplicationStatus.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(updatePortfolioDetailsLas.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(updatePortfolioDetailsLas.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(updatePortfolioDetailsLas.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(overWriteDataLas.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(overWriteDataLas.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(overWriteDataLas.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(leadLoanSanctioned.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(leadLoanSanctioned.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(leadLoanSanctioned.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(leadDisbursal.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(leadDisbursal.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(leadDisbursal.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(lenderMarkAsLogin.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderMarkAsLogin.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(lenderMarkAsLogin.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(leadLenderCallBack.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(leadLenderCallBack.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(leadLenderCallBack.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(getLeadDisbursalData.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getLeadDisbursalData.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(getLeadDisbursalData.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(checkCreditScoreVideo.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(checkCreditScoreVideo.fulfilled, (state: any, action: any) => {
				state.isLoading = false;
				state.credit_video = action?.payload;
			})
			.addCase(checkCreditScoreVideo.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(filterSubProducts.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(filterSubProducts.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(filterSubProducts.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(filterStatus.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(filterStatus.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(filterStatus.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(allocateManager.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(allocateManager.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(allocateManager.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(lenderTataPLGenerateOTP.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderTataPLGenerateOTP.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(lenderTataPLGenerateOTP.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(lenderTataPLVerifyOTP.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderTataPLVerifyOTP.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(lenderTataPLVerifyOTP.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(lenderTataPartnerEligiblity.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderTataPartnerEligiblity.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(lenderTataPartnerEligiblity.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(lenderTataSecondPartnerEligiblity.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderTataSecondPartnerEligiblity.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(lenderTataSecondPartnerEligiblity.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(lenderGetOffer.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderGetOffer.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(lenderGetOffer.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(lenderTataUpdateLead.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderTataUpdateLead.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(lenderTataUpdateLead.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(lenderTataCheckCkyc.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderTataCheckCkyc.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(lenderTataCheckCkyc.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(lenderTataFetchBankStmt.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderTataFetchBankStmt.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(lenderTataFetchBankStmt.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(lenderTataUploadDocument.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderTataUploadDocument.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(lenderTataUploadDocument.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(lenderPnbUploadDocument.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderPnbUploadDocument.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(lenderPnbUploadDocument.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(lenderTataDocket.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderTataDocket.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(lenderTataDocket.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(lenderTataApplicationStatus.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderTataApplicationStatus.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(lenderTataApplicationStatus.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(saveLenderSpcfInfo.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(saveLenderSpcfInfo.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(saveLenderSpcfInfo.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(pnbFormDownload.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(pnbFormDownload.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(pnbFormDownload.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(pnbEsignGetStatus.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(pnbEsignGetStatus.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(pnbEsignGetStatus.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(pnbPaymentStatus.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(pnbPaymentStatus.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(pnbPaymentStatus.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(pnbPaymentDecrypt.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(pnbPaymentDecrypt.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(pnbPaymentDecrypt.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(pnbEsignGetDocument.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(pnbEsignGetDocument.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(pnbEsignGetDocument.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(pnbEsignPaymentLink.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(pnbEsignPaymentLink.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(pnbEsignPaymentLink.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(lendingKartRmList.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lendingKartRmList.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(lendingKartRmList.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(abflRegulatory.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(abflRegulatory.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(abflRegulatory.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(getLenderSpcfInfo.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getLenderSpcfInfo.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(getLenderSpcfInfo.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(fetchMasterData.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(fetchMasterData.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(fetchMasterData.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(fetchLeadStatusCheck.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(fetchLeadStatusCheck.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(fetchLeadStatusCheck.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(applicationStatusCheck.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(applicationStatusCheck.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(applicationStatusCheck.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(lenderDocumentUpload.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderDocumentUpload.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(lenderDocumentUpload.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(getIndustryMaster.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getIndustryMaster.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(getIndustryMaster.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(getSubIndustryMaster.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getSubIndustryMaster.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(getSubIndustryMaster.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(lenderOfferGenerate.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderOfferGenerate.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(lenderOfferGenerate.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(lenderOfferAccept.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderOfferAccept.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(lenderOfferAccept.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(lenderGenerateOTP.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderGenerateOTP.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(lenderGenerateOTP.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(lenderVerifyOTP.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderVerifyOTP.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(lenderVerifyOTP.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(lenderEligiblityCheck.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderEligiblityCheck.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(lenderEligiblityCheck.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(lenderFetchSession.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderFetchSession.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(lenderFetchSession.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(moneytapLenderLink.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(moneytapLenderLink.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(moneytapLenderLink.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(lenderPerfiosDocUpload.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderPerfiosDocUpload.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(lenderPerfiosDocUpload.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(lenderPerfiosFetchStatus.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderPerfiosFetchStatus.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(lenderPerfiosFetchStatus.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(lenderProductFetch.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderProductFetch.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(lenderProductFetch.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(lenderSubProductFetch.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderSubProductFetch.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(lenderSubProductFetch.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(lenderLoanPurposeFetch.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderLoanPurposeFetch.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(lenderLoanPurposeFetch.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(lenderBranchFetch.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderBranchFetch.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(lenderBranchFetch.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(lenderPincodeDetailsFetch.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderPincodeDetailsFetch.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(lenderPincodeDetailsFetch.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(leadMigrationUpload.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(leadMigrationUpload.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(leadMigrationUpload.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(bajajGetToken.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(bajajGetToken.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(bajajGetToken.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(bajajAssignLead.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(bajajAssignLead.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(bajajAssignLead.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(bajajRmList.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(bajajRmList.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(bajajRmList.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(bajajBLLAPCreateLoan.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(bajajBLLAPCreateLoan.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(bajajBLLAPCreateLoan.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(bajajUploadDoc.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(bajajUploadDoc.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(bajajUploadDoc.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(bajajGetLeadStatus.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(bajajGetLeadStatus.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(bajajGetLeadStatus.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(lenderLeadClose.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderLeadClose.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(lenderLeadClose.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(leadClose.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(leadClose.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(leadClose.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(getPanNo.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getPanNo.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(getPanNo.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(postPanNo.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(postPanNo.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(postPanNo.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(getSelectedLendersList.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getSelectedLendersList.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(getSelectedLendersList.rejected, (state: any, action: any) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(lenderRmFetch.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(lenderRmFetch.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(lenderRmFetch.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(resetLeadFilterState, (state: any) => {
				(state.defaultPageSize = 10),
					(state.defaultPageId = 1),
					(state.pageSize = 10),
					(state.pageId = 1);
				state.followUpDate = '';
				state.fromDate = '';
				state.toDate = '';
				state.locationType = '';
				state.subproduct = '';
				state.status = '';
				state.lender = '';
				state.dmaOwner = '';
				state.cstCity = '';
				state.cstDesignation = '';
				state.designationSelection = '';
				state.citySelection = '';
			})
			.addCase(customerUploadLas.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(customerUploadLas.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.added_lead = action?.payload;
			})
			.addCase(customerUploadLas.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			});
	},
});

export const {
	paginationSize,
	leadFlow,
	activityInfo,
	leadBureauCustomer,
	fetchBureauCustomerReport,
	bureauStatsDetails,
	lenderListData,
	addMoreLenders,
	lenderUpdateStatus,
	lenderSelectionProcess,
	selectedLender,
	leadFilter,
	productFlow,
	activeLender,
	markAsClose,
	productCosnultationFilter,
	lenderSpfDetailsFlow,
	leadCreationtype,
} = leadSlice.actions;
export default leadSlice.reducer;
