import { IonContent, IonPage } from '@ionic/react';
import React, { useEffect } from 'react';
import { CustomSuccessPage } from '../../components/common/custom-success-page/CustomSuccessPage';
import { useHistory, useLocation } from 'react-router';

export default function Successfull() {
	const history = useHistory();
	const location = useLocation<{ toPath: string; message: string; data: any }>();
	useEffect(() => {
		setTimeout(
			() => history.push(location?.state?.toPath, { direction: 'root', ...location?.state?.data }),
			2000
		);
	}, []);
	return (
		<IonPage>
			<IonContent>
				<CustomSuccessPage
					message={
						location?.state?.message ||
						'Allocated to Andro Manager Successfully'
					}
				/>
			</IonContent>
		</IonPage>
	);
}
