import { useEffect, useState } from 'react';
import { IonButton, IonContent, IonFooter, IonIcon } from '@ionic/react';
import { t } from 'i18next';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import {
	ContinueLaterPopUp,
	FormatDate,
	checkFieldsValidToContinue,
	showToastAlert,
} from '../../../../helpers/functions';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
	lenderSpfDetailsFlow,
	saveLenderSpcfInfo,
} from '../../../../store/reducers/lead/leadSlice';
import { CustomModalLeadStatus } from '../../../../components/common';
import { unwrapResult } from '@reduxjs/toolkit';
import { LenderNameForAPICall } from '../../../../helpers/lenders';
import { OfferRejectedMsg } from '../..';
import {
	coAppFormDefaultValues,
	formSchemaConditionally,
	bindFieldConditionally,
} from './HLLAPInfo';
import { CoApplicantAllForm } from './CoApplicantAllForm';

export const CoApplicantForms: React.FC<any> = ({
	coTabIndex,
	handleSegmentChange,
	totalCoApplicant,
	setTotalCoApplicant,
}) => {
	const [modalPageClose, setModalPageClose] = useState(false);
	const [currentPage, setCurrentPage] = useState<any>(1);
	const [coApplicantData, setCoApplicantData] = useState<any>([]);
	const [leadStatusModalOpen, setLeadStatusModalOpen] = useState(false);
	const [pinCodeDataError, setPinCodeDataError] = useState<any>('');
	const [applicationNo, setApplicationNo] = useState('');
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const dispatch = useDispatch();
	const { lenderSpecificDetails, leadFlowData, selectedLender } = useSelector(
		(state: any) => state?.lead
	);

	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		trigger,
		reset,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(
			formSchemaConditionally(`coApplicantSchema${currentPage}`)
		),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: coAppFormDefaultValues(
			currentPage,
			lenderSpecificDetails,
			coTabIndex
		),
	});

	useEffect(() => {
		const coApplicant: any =
			lenderSpecificDetails?.lender_spf_details?.co_applicant;
		if (coApplicant) {
			const coData = JSON.parse(JSON.stringify(coApplicant));
			setCoApplicantData(coData || []);
		}
	}, []);

	useEffect(() => {
		onPageChange(1);
	}, [coTabIndex]);

	const onPageChange = (pageInd: any) => {
		setCurrentPage(pageInd);
	};

	const addCoApplicant = () => {
		onSubmit(getValues(), 'add_co_applicant');
		const count: any = [...totalCoApplicant, totalCoApplicant.length + 1];
		setTotalCoApplicant(count);
		handleSegmentChange(`CoApplicantForm${count.length - 1}`);
		setCoApplicantData([...coApplicantData, {}]);
	};

	const removeApplicant = () => {
		const totalC = totalCoApplicant.filter(
			(el: any, index: number) => index != coTabIndex
		);
		setTotalCoApplicant(totalC);
		handleSegmentChange('CoApplicantForm0');
		onPageChange(1);
		reset(coAppFormDefaultValues(1, lenderSpecificDetails, 0));
		let coData: any = JSON.parse(JSON.stringify(coApplicantData));
		coData = coData.filter((el: any, ind: any) => ind != coTabIndex);
		setCoApplicantData(coData);
		dispatch(
			lenderSpfDetailsFlow({
				...lenderSpecificDetails,
				lender_spf_details: {
					...lenderSpecificDetails?.lender_spf_details,
					co_applicant: coData,
				},
			})
		);
	};

	/**
	 * Show form pagination
	 */
	const coAppFormPage = () => {
		let spfDetails: any = {};
		if (
			lenderSpecificDetails?.lender_spf_details?.co_applicant &&
			lenderSpecificDetails?.lender_spf_details?.co_applicant?.length > 0
		) {
			spfDetails =
				lenderSpecificDetails?.lender_spf_details?.co_applicant?.[coTabIndex];
		}
		const formPageView: any = [];
		for (let i = 1; i <= 3; i++) {
			formPageView.push(
				<span
					key={i}
					className={currentPage === i ? 'active_page' : ''}
					onClick={() => {
						if (spfDetails?.validCoFormCount >= i) {
							setCurrentPage(i);
							reset({});
						}
					}}
				>
					{i}
				</span>
			);
		}
		return formPageView;
	};

	/**
	 * On submit to save user data and after that
	 * @param data Form data
	 */
	const onSubmit = async (data: any, flag?: any) => {
		let docsName: any = [];
		let setDocs: any = {};

		if (currentPage === 3 && data.is_income_earner === true) {
			if (
				data.profile_segment_type_name === 'Assessed Income' ||
				data.profile_segment_type_name === 'Regular ITR'
			) {
				if (!data?.bank_statement_12_months_doc?.[0]?.file) {
					showToastAlert({
						type: 'error',
						message: '12 Months Bank Statement is required',
					});
					return;
				}
				if (!data?.last_2_years_itr_doc?.[0]?.file) {
					showToastAlert({
						type: 'error',
						message: 'Last 2 Years ITR is required',
					});
					return;
				}
				if (!data?.business_vintage_proof_doc?.[0]?.file) {
					showToastAlert({
						type: 'error',
						message: 'Business Vintage Proof is required',
					});
					return;
				}
				docsName = [
					'bank_statement_12_months_doc',
					'last_2_years_itr_doc',
					'business_vintage_proof_doc',
				];
			} else if (
				data.profile_segment_type_name === 'Cash Salary' ||
				data.profile_segment_type_name === 'Bank Salary'
			) {
				if (!data?.latest_salary_slip_doc?.[0]?.file) {
					showToastAlert({
						type: 'error',
						message: 'Latest Salary Slip is required',
					});
					return;
				}
				if (
					data.profile_segment_type_name === 'Bank Salary' &&
					Number(data.monthly_gross_income) >= 25000 &&
					!data?.form_16_doc?.[0]?.file
				) {
					showToastAlert({
						type: 'error',
						message: 'Form 16 is required',
					});
					return;
				}
				if (!data?.bank_statement_6_months_doc?.[0]?.file) {
					showToastAlert({
						type: 'error',
						message: '6 Months Bank Statement is required',
					});
					return;
				}
				docsName = [
					'latest_salary_slip_doc',
					'form_16_doc',
					'bank_statement_6_months_doc',
				];
			}
			docsName.forEach((ele: any) => {
				setDocs = {
					...setDocs,
					[ele]: [],
				};
				data?.[ele]?.forEach((el: any) => {
					if (el.file) {
						setDocs[ele] = [...setDocs[ele], { file: el.file }];
					}
				});
			});
		} else if (currentPage === 2) {
			[
				'address_proof_doc',
				'kyc_aadhar_front_doc',
				'kyc_aadhar_back_doc',
				'kyc_pan_doc',
			].forEach((ele: any) => {
				setDocs = {
					...setDocs,
					[ele]: [],
				};
				data?.[ele]?.forEach((el: any) => {
					if (el.file) {
						setDocs[ele] = [...setDocs[ele], { file: el.file }];
					}
				});
			});
		}

		const coData: any = JSON.parse(JSON.stringify(coApplicantData));

		const formDatas: any = {};
		const fieldsList: any = bindFieldConditionally(
			currentPage,
			data,
			'co_applicant'
		);
		fieldsList.forEach((el: any) => {
			if (data[el.name] || data[el.name] === false) {
				formDatas[el.name] = data[el.name];
			}
		});

		if (currentPage === 2) {
			formDatas['address_proof_document_id'] =
				data['address_proof_document_id'];
			formDatas['address_proof_document_name'] =
				data['address_proof_document_name'];
		} else if (currentPage === 3) {
			formDatas['profile_segment_type_name'] =
				data['profile_segment_type_name'];
		}

		coData[coTabIndex] = {
			...coData[coTabIndex],
			...formDatas,
			...setDocs,
			form_16_doc: null,
			latest_salary_slip_doc: null,
			last_2_years_itr_doc: null,
			bank_statement_6_months_doc: null,
			bank_statement_12_months_doc: null,
			business_vintage_proof_doc: null,
		};

		if (coData[coTabIndex]?.validCoFormCount !== 3) {
			if (isValid || flag === 'continue_later') {
				if (currentPage !== 3) {
					coData[coTabIndex].validCoFormCount = currentPage + 1;
				} else {
					coData[coTabIndex].validCoFormCount = currentPage;
				}
			}
		}

		if (currentPage === 1) {
			if (coData[coTabIndex].dob) {
				coData[coTabIndex].dob = FormatDate(data?.dob, 'yyyy-mm-dd');
			}
		}

		if (
			currentPage === 3 &&
			!lenderSpecificDetails?.lender_spf_details?.is_income_earner
		) {
			let isIncomeEarner: any = false;
			coData.forEach((el: any) => {
				if (el.is_income_earner) {
					isIncomeEarner = true;
				}
			});

			if (!isIncomeEarner) {
				showToastAlert({
					type: 'error',
					message:
						'Minimun 1 Income Earner is required from Primary Applicant OR Co-Applicant',
				});
				return;
			}
		}

		setCoApplicantData(coData);

		const req: any = {
			lender_spf_details: JSON.parse(
				JSON.stringify(lenderSpecificDetails?.lender_spf_details)
			),
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.DMI_HL_LAP,
			step: 'step1',
			isNewLead:
				currentPage == 3 &&
				isValid &&
				flag !== 'continue_later' &&
				flag !== 'add_co_applicant',
		};

		if (!req?.lender_spf_details?.co_applicant) {
			req.lender_spf_details = {
				...req.lender_spf_details,
				co_applicant: [coData[coTabIndex]],
			};
		} else {
			req.lender_spf_details.co_applicant[coTabIndex] = coData[coTabIndex];
		}

		if (lenderSpecificDetails?.lead_spf_detail_id) {
			req.lead_spf_detail_id = lenderSpecificDetails?.lead_spf_detail_id;
		}

		// console.log(req); return
		setShowOfferErrMsg('');
		await dispatch(saveLenderSpcfInfo({ data: req }))
			.then(unwrapResult)
			.then((res: any) => {
				if (res?.status) {
					dispatch(
						lenderSpfDetailsFlow({
							...lenderSpecificDetails,
							lender_spf_details: res.data
								? res.data.lender_spf_details
								: res.body.lender_spf_details,
						})
					);
					if (!flag || typeof flag !== 'string') {
						if (req.isNewLead && currentPage === 3) {
							const appNo: any = `Application No: ${res.body?.lender_spf_details?.applicationNumber}\nLoan Offer Amount: ${res.body?.lender_spf_details?.loanOfferAmount} *`;
							setApplicationNo(appNo);
							setLeadStatusModalOpen(true);
						} else if (currentPage < 3) {
							setCurrentPage(currentPage + 1);
							reset({});
						}
					}
				} else {
					dispatch(
						lenderSpfDetailsFlow({
							...lenderSpecificDetails,
							lender_spf_details:
								res?.body &&
								res?.body?.lender_spf_details &&
								Object.keys(res?.body?.lender_spf_details)?.length > 0
									? res?.body?.lender_spf_details
									: req.lender_spf_details,
						})
					);
					setShowOfferErrMsg(res?.message);
				}
			});
	};

	/**
	 * Continue Later and Submit button
	 */
	const continueLaterAndSubmitBtn = () => {
		return (
			<>
				<CustomButton
					className='w-100 fw-bold dashboard-add-lead-button mb-3'
					expand='block'
					fill='outline'
					title={t('Continue Later')}
					onClick={() => {
						if (checkFieldsValidToContinue('')) {
							setModalPageClose(true);
						} else {
							showToastAlert({
								type: 'error',
								message: 'Please fix above errors',
							});
						}
					}}
				/>
				<CustomButton
					type='submit'
					className='w-100 fw-bold dashboard-add-lead-button'
					expand='block'
					title={t(currentPage === 3 ? 'Proceed' : 'Save & Continue')}
					disabled={!isValid || !!pinCodeDataError}
					onClick={handleSubmit(onSubmit)}
				/>
			</>
		);
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 pt-3 p-md-5 border-radius-10'>
							<form onSubmit={handleSubmit(onSubmit)}>
								{currentPage === 3 && (
									<div className='text-red mb-3'>
										<small>
											Note: Minimun 1 Income Earner is required from Primary
											Applicant OR Co-Applicant
										</small>
									</div>
								)}
								<CoApplicantAllForm
									control={control}
									errors={errors}
									getValues={getValues}
									setValue={setValue}
									trigger={trigger}
									reset={reset}
									currentPage={currentPage}
									coTabIndex={coTabIndex}
									setPinCodeDataError={setPinCodeDataError}
									pinCodeDataError={pinCodeDataError}
									coAppCurrentFormData={
										lenderSpecificDetails?.lender_spf_details?.co_applicant?.[
											coTabIndex
										]
									}
								/>
								<div className='co_app_form_pagination'>
									<div className='page_item'>{coAppFormPage()}</div>
									<div className='other_btns'>
										{currentPage === 3 && (
											<CustomButton
												type='button'
												className='add-co-btn fw-bold dashboard-add-lead-button'
												expand='block'
												title={t('Add Co-Applicant')}
												disabled={!isValid}
												onClick={addCoApplicant}
											/>
										)}

										{coTabIndex != 0 && (
											<IonButton
												shape='round'
												type='button'
												color='danger'
												onClick={() => removeApplicant()}
											>
												<IonIcon src='/images/icons/delete.svg' />
											</IonButton>
										)}
									</div>
								</div>
							</form>
							<div className={`d-none d-md-block mt-4`}>
								{continueLaterAndSubmitBtn()}
							</div>
						</div>
					</div>
				)}
			</IonContent>

			{!showOfferErrMsg && (
				<IonFooter className='d-block d-md-none position-relative ion-padding lead_ftr_btn'>
					{continueLaterAndSubmitBtn()}
				</IonFooter>
			)}

			{leadStatusModalOpen && (
				<CustomModalLeadStatus
					isOpen={leadStatusModalOpen}
					applicationNo={applicationNo}
					handleClose={() => setLeadStatusModalOpen(false)}
				/>
			)}

			<CustomModal
				needIonContentWraper={false}
				isOpen={modalPageClose}
				initialBreakpoint={undefined}
				handleClose={() => setModalPageClose(false)}
				breakpoints={[]}
				className='height-auto confirm-popup-middle continue-later'
				modalContent={ContinueLaterPopUp(
					() => {
						setModalPageClose(false);
					},
					() => {
						new Promise((myResolve) => {
							setTimeout(function () {
								myResolve(history.back());
								onSubmit(getValues(), 'continue_later');
								setModalPageClose(false);
							}, 10);
						});
					},
					'Cancel',
					'Continue Later',
					'className',
					'Are You Sure You Want to Exit?',
					`${t('You can continue later from where you left off.')}`
				)}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
