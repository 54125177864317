import { IonContent, IonPage } from '@ionic/react';
import React from 'react';

export const DefaultPage: React.FC = () => {

	return (
		
        <IonPage>
            <IonContent className='text-center'>
                Lender specific form not created
            </IonContent>
        </IonPage>
        
	);
};
