/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';

import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { REGEX } from '../../../helpers/constant';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import CustomDropDown from '../../../components/common/custom-dropdown/CustomDropDown';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { IonContent, IonPage } from '@ionic/react';
import { Header } from '../../../components/layout/header/Header';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	addlead,
	dashboardGetAndroManager,
} from '../../../store/reducers/lead/leadSlice';
import { useDispatch, useSelector } from 'react-redux';
import { disableNumberArrows } from '../../../helpers/functions';
import { useHistory } from 'react-router';

interface LeadStep {
	step?: any;
	setCurrentStep?: any;
	selectProductData?: any;
	setLeadID?: any;
	setLeadMobile?: any;
}
const addLeadScema = yup.object().shape({
	mobile: yup
		.string()
		.length(10, 'Mobile No. Should Be 10 Digits.')
		.required('Mobile is Required.')
		.matches(REGEX.INDIAN_MOBILE_NUMBERS, 'Please Enter a valid Mobile No.'),
});

export const AddInviteLead: React.FC<LeadStep> = ({
	setCurrentStep,
	selectProductData,
	setLeadID,
	setLeadMobile,
}) => {
	const { t } = useTranslation();
	const [managers, setManagers] = useState([]);
	const userData = useSelector((state: any) => state?.users?.user_details);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, setManagerId] = useState(null);
	const dispatch = useDispatch();

	const {
		handleSubmit,
		control,
		trigger,
		setValue,
		getValues,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(addLeadScema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			mobile: '',
		},
	});
	const onSubmit = async () => {
		const { mobile } = getValues();
		const body = {
			mobile_no: parseInt(mobile),
			...selectProductData,
			loginUserId: userData?.id,
		};

		await dispatch(addlead({ data: body }))
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setLeadID(response?.newLoanApplication?.id);
					setLeadMobile(mobile);
					setCurrentStep('step2');
				}
			});
		setCurrentStep('step2');
	};
	useEffect(() => {
		getLeadManager();
	}, []);

	const getLeadManager = async () => {
		await dispatch(dashboardGetAndroManager())
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setManagers(response?.data);
				}
			});
	};

	const history = useHistory();

	return (
		<IonPage>
			<Header
				name={t('Add Lead Details')}
				backButton={true}
				needArrow={true}
				needArrowOnClick={() => {
					history.push('/select-lead-product');
				}}
			/>
			<IonContent>
				<div
					className='p-3 mt-3'
					style={{
						height: 'calc(100vh - 130px)',
					}}
				>
					<form
						onSubmit={handleSubmit(onSubmit)}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleSubmit(onSubmit);
							}
						}}
					>
						<Controller
							control={control}
							render={({ field: { onChange, onBlur, name } }) => (
								<CustomInput
									type={'number'}
									placeholder={t('Customer Mobile No.')}
									onBlur={onBlur}
									name={name}
									onChange={onChange}
									errors={errors?.mobile?.message}
									notRequiredValue={true}
									onBeforeInput={(evt: any) => {
										//Only numbers accepted on user input
										!/[0-9]/.test(evt.data) && evt.preventDefault();
										//Only accepts 10 characters on user input
										if (evt.target.value.length === 10) {
											evt.preventDefault();
										}
									}}
									onPaste={(e: any) => {
										//Only get numbers on user paste
										e.preventDefault();
										let pasteContent = e.clipboardData.getData('Text');
										if (pasteContent != '') {
											pasteContent = pasteContent.replace(/\D/g, '');
											if (pasteContent.length > 10) {
												pasteContent = pasteContent.slice(0, 10);
											}
										}
										setValue('mobile', pasteContent);
										trigger('mobile');
									}}
									onKeyDown={(e: any) => {
										disableNumberArrows(e);
									}}
									onWheel={(e: any) => e.target.blur()}
								/>
							)}
							name={'mobile'}
						/>
						<CustomDropDown
							{...{
								isCheckbox: false,
								title: t('OneAndro Manager'),
								setData: setManagers,
								data: managers,
								placeholder: t('Search Andro Manager by “Name”'),
								isSearch: true,
								modalTitle: t('OneAndro Manager'),
							}}
						/>
						{/* <CustomTest /> */}
						<div
							className='position-fixed w-100 bottom-0 start-0 px-3 pb-3'
							style={{
								backgroundColor: '#fff',
							}}
						>
							<CustomButton
								type='submit'
								disabled={!isValid}
								className='btn-blue'
								expand='block'
								title={t('Invite Lead to Join')}
							/>
						</div>
					</form>
				</div>
			</IonContent>
		</IonPage>
	);
};
