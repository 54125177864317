import React, { useEffect, useState } from 'react';
import { Header } from '../../components/layout/header/Header';
import {
	// IonCard,
	IonContent,
	IonGrid,
	IonRow,
	IonCol,
	IonImg,
	IonItem,
	// IonFooter,
	IonPage,
	IonCardContent,
	IonLabel,
	IonList,
	isPlatform,
} from '@ionic/react';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import { CustomSearchbar } from '../../components/common/custom-searchbar/CustomSearchbar';
import { useHistory } from 'react-router';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
import './HomeLoanLenders.scss';
import { Divider } from '../../components/common/divider/Divider';
import { useDispatch, useSelector } from 'react-redux';
import {
	getLenderBySubproduct,
	productFlow,
} from '../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { CustomNoData } from '../../components/common/custom-no-data/CustomNoData';
import {
	eventAttributes,
	notifyHandler,
	sanitizeHtml,
} from '../../helpers/functions';
import { NotifyVisitors } from 'capacitor-plugin-notifyvisitors';

interface HomeLendersData {
	name: string;
	id: number;
	cat_id: number;
	icon: string;
	lender_id: string;
	lender_prod_id: number;
	status: string;
}

export const HomeLoanLenders: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const [pincodeUploadModal, setPincodeUploadModal] = useState(false);
	const [selectedAlphabet, setSelectedAlphabet] = useState<string | null>(null);
	const productFlowSelector = useSelector(
		(state: any) => state?.lead?.productFlowData
	);
	const productSelector = useSelector(
		(state: any) => state?.users?.productFlow
	);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [value, setValue] = useState(productFlowSelector?.searchTerm || '');

	const [normalLenders, setNormalLenders] = useState([]);
	const [topLenders, setTopLenders] = useState<any>([]);

	const handleAlphabetClick = (alphabet: string) => {
		setSelectedAlphabet(alphabet);
	};

	const alphabets = [...Array(26)].map((_, index) =>
		String.fromCharCode(65 + index)
	);

	// Notify Visitors
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const userName = useSelector(
		(state: any) => state?.users?.homepage?.profile?.name
	);
	const userMobile = useSelector(
		(state: any) => state?.users?.user_details?.mobile
	);

	const entityType = useSelector(
		(state: any) => state?.users?.user_details?.entityType
	);
	const userLang = useSelector(
		(state: any) => state?.oneTimeScreen?.language?.name
	);

	const userAttributes = {
		userId: userId,
		attributes: {
			name: userName,
			id: userId,
			mobile: userMobile,
			user_language: userLang,
			entityType:
				entityType === 'Individual'
					? 'DMA'
					: entityType === 'BTM'
					? 'employee'
					: entityType,
		},
	};
	useEffect(() => {
		if (isPlatform('android') || isPlatform('ios')) {
			notifyHandler('event', eventAttributes('lead_journey_all_lender_page'));
			notifyHandler('user', userAttributes.attributes);
			const inAppData = {
				tokens: { name: userAttributes },
				customRules: { banerrType: 'all lenders' },
			};
			NotifyVisitors.show(inAppData);
		} else {
			(window as any).nv('event', 'lead_journey_all_lender_page', 10, 1);
			(window as any).nv('user', userId, userAttributes.attributes);
		}
	}, []);

	const PincodeUploadModal = () => {
		const { t } = useTranslation();
		return (
			<>
				<div className='ion-padding'>
					<h6 className='fs-16 fw-bold'>{t(`Filter By Pincode`)}</h6>

					<div className='mt-3'>
						<CustomInput placeholder={t('Pincode')} />
					</div>
					<div style={{ width: '100%' }}>
						<CustomButton
							onClick={() => setPincodeUploadModal(false)}
							className='w-100'
							fill='outline'
							title={t('Cancel')}
						/>
						<CustomButton
							onClick={() => setPincodeUploadModal(false)}
							className='w-100'
							fill='solid'
							title={t('Check')}
						/>
					</div>
				</div>
			</>
		);
	};
	useEffect(() => {
		dispatch(productFlow({ ...productFlowSelector, searchTerm: value }));
	}, [value]);

	useEffect(() => {
		if (location.pathname !== '/all-lenders') {
			return;
		}
		dispatch(
			getLenderBySubproduct({
				param: `${
					productFlowSelector?.subProductId
						? `?subproduct_id=${productFlowSelector?.subProductId}&lender_show=${productFlowSelector.lender_show}`
						: `?lender_show=${productFlowSelector.lender_show}`
				}${
					productFlowSelector?.subProductId
						? `${
								value
									? `&searchItem=${sanitizeHtml(
											encodeURIComponent(productFlowSelector?.searchTerm)
									  )}`
									: ''
						  }`
						: `${
								value
									? `&searchLender=${sanitizeHtml(
											encodeURIComponent(productFlowSelector?.searchTerm)
									  )}`
									: ''
						  }`
				}`,
			})
		)
			.then(unwrapResult)
			.then((res: any) => {
				if (res?.status) {
					setNormalLenders(res?.normal_lenders || res?.normalLenders);
					setTopLenders(res?.top_lenders || res?.topLenders);
				}
			});
	}, [location.pathname]);

	const handleSearch = async (inputVal: string) => {
		if (inputVal.length > 0 && inputVal.length <= 2) return;
		await dispatch(
			getLenderBySubproduct({
				param: `${
					productFlowSelector?.subProductId
						? `?subproduct_id=${productFlowSelector?.subProductId}&lender_show=${productFlowSelector.lender_show}`
						: `?lender_show=${productFlowSelector.lender_show}`
				}${
					productFlowSelector?.subProductId
						? `${
								inputVal
									? `&searchItem=${sanitizeHtml(encodeURIComponent(inputVal))}`
									: ''
						  }`
						: `${
								inputVal
									? `&searchLender=${sanitizeHtml(
											encodeURIComponent(inputVal)
									  )}`
									: ''
						  }`
				}`,
			})
		)
			.then(unwrapResult)
			.then((res: any) => {
				if (res?.status) {
					setNormalLenders(res?.normalLenders);
					setTopLenders(res?.topLenders);
				}
			});
	};
	
	return (
		<IonPage>
			<Header
				name={
					productSelector?.id == 1
						? t(productFlowSelector?.title?.trim()) + ' ' + t('Lenders')
						: t(productFlowSelector?.title?.trim()) + ' ' + t('Insurers')
				}
				needArrow={true}
				backButtonDisabled={false}
				backButton={true}
				needArrowOnClick={() => {
					history.push(
						productFlowSelector.from === 'dashboard'
							? '/dashboard/home'
							: '/sub-products'
					);
					dispatch(
						productFlow({
							title: '',
							from: '',
							is_product: 1,
							is_lender: 0,
						})
					);
					setValue('');
				}}
			/>
			<IonContent className=' '>
				<IonContent className=''>
					{/* Searchbar */}
					<div className='position-relative mx-3 my-3'>
						<CustomSearchbar
							searchIcon='/images/icons/searchbar-icon.svg'
							placeholder={t('Search Lender by “Name”')}
							showClearButton='focus'
							onChange={(value: any) => {
								setValue(value);
								handleSearch(value);
							}}
							value={value}
						/>
					</div>

					{/* Filter by pincode */}
					{/* 	<div className='d-flex flex-row w-100'>
						<CustomButton
							onClick={() => setPincodeUploadModal(true)}
							fill='clear'
							className='fs-14 fw-600 mb-1'
							size={'default'}
						>
							<img
								src='/images/icons/teams/location-blue.svg'
								style={{ width: 20, height: 20 }}
								alt='location'
								className='me-1'
							/>
							{t('Filter by Pincode')}
						</CustomButton>
					</div>
					<Divider className='padding-top-border' /> */}

					{/* Top Lenders */}
					{topLenders?.length !== 0 ? (
						<IonGrid>
							{topLenders?.length &&
								topLenders?.find((el: any) => el?.status === '1') && (
									<h6 className='fs-16 fw-bold headings'>{t('Top Lenders')}</h6>
								)}{' '}
							<IonRow className='d-flex align-items-start'>
								{topLenders
									?.filter((item: any) =>
										selectedAlphabet
											? item.bank_name.charAt(0).toUpperCase() ===
											  selectedAlphabet
											: true
									)
									.map((item: any, index: number) => {
										return parseInt(item.status) === 1 ? (
											<IonCol
												size='4'
												key={index}
												style={{ zIndex: 2, padding: 'unset' }}
											>
												<IonCardContent
													className='d-flex justify-content-center align-items-center flex-column ion-padding'
													id={item.id}
													style={{ cursor: 'pointer' }}
													onClick={() => {
														dispatch(
															productFlow({
																...productFlowSelector,
																lender_prod_id: item?.lender_prod_id,
																lenderId: item?.id || item?.lender_id,
																bankTitle: item?.name,
																bankIcon: item?.icon,
															})
														);
														setValue('');

														history.push(
															productFlowSelector.from === 'dashboard'
																? '/lender-loan-types'
																: '/lender-product-detail'
														);
													}}
												>
													<div
														className='selected-lender-bank-icon-container'
														style={{
															width: '50px',
															height: '50px',
														}}
													>
														<div className='selected-lender-bank-icon-border d-flex justify-content-center align-items-center m-auto'>
															<img
																src={`${item.icon}`}
																className='bankimg selected-lender-bank-icon m-0'
																style={{
																	width: '49px',
																	height: '49px',
																	borderRadius: '50%',
																}}
																alt='img'
															/>
														</div>
													</div>

													<IonLabel className='text-center mt-2'>
														{item?.name}
													</IonLabel>
												</IonCardContent>
											</IonCol>
										) : (
											''
										);
									})}
							</IonRow>
						</IonGrid>
					) : (
						''
					)}

					{/* Other Lenders */}
					{normalLenders?.length !== 0 && (
						<IonList>
							{normalLenders?.length !== 0 && (
								<h6 className='fs-16 fw-bold headings'>{t('Other Lenders')}</h6>
							)}
							{normalLenders?.map((item: HomeLendersData) => {
								return parseInt(item.status) === 1 ? (
									<IonItem
										style={{ cursor: 'pointer' }}
										key={item.id}
										onClick={() => {
											if (item.name === 'Home Loan') {
												history.push('/all-lenders');
											} else {
												dispatch(
													productFlow({
														...productFlowSelector,
														lender_prod_id: item?.lender_prod_id,
														lenderId: item?.id || item?.lender_id,
														bankTitle: item?.name,
														bankIcon: item?.icon,
													})
												);
												setValue('');

												history.push(
													productFlowSelector.from === 'dashboard'
														? '/lender-loan-types'
														: '/lender-product-detail'
												);
											}
										}}
									>
										<div
											className=' d-flex align-items-center '
											style={{
												padding: '10px 0',
											}}
										>
											<div className={` select-product-sub-icon d-flex`}>
												<IonImg
													//src='/images/icons/leads/sbi-bank.svg'
													alt='icon'
													src={`${item.icon}`}
													style={{
														width: '40px',
														height: '40px',
													}}
												/>
												<Divider className='padding-top-border' />
											</div>
											<IonLabel className='ms-3 text-center'>
												{item?.name}
											</IonLabel>
										</div>
									</IonItem>
								) : (
									''
								);
							})}
						</IonList>
					)}
					{topLenders?.length === 0 && normalLenders?.length === 0 ? (
						<CustomNoData />
					) : (
						''
					)}
				</IonContent>
				{/* Alphabets list navigation */}
				<div
					className='alphabates'
					style={{
						display: 'grid',
					}}
				>
					{alphabets.map((alphabet) => {
						return (
							<span
								key={alphabet}
								className={selectedAlphabet === alphabet ? 'active' : ''}
								onClick={() => handleAlphabetClick(alphabet)}
							>
								{alphabet}
							</span>
						);
					})}
				</div>
			</IonContent>
			{/* Filter By pincode modal */}
			<CustomModal
				id='pincodeUploadmodal'
				isOpen={pincodeUploadModal}
				initialBreakpoint={0.6}
				handleClose={() => setPincodeUploadModal(false)}
				breakpoints={[0, 0.35, 0.5, 0.75]}
				modalContent={<PincodeUploadModal />}
				needCross={false}
				needArrow={false}
				modalHeight='55%'
			/>
		</IonPage>
	);
};
