import {
	IonButton,
	IonCard,
	IonCardContent,
	IonContent,
	IonPage,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { Header } from '../../components/layout/header/Header';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomForm from '../insurance/components/common/custom_form/custom_form.component';
import { t } from 'i18next';
import OtpModal from './otpModal';
import ManualUploadModal from './manualUploadModal';
import { useDispatch, useSelector } from 'react-redux';
import {
	getPanNo,
	postPanNo,
	updatePortfolioDetailsLas,
} from '../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';

interface Props {}

export const SelectHoldings: React.FC<Props> = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch: any = useDispatch();
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	const LeadID = useSelector((state: any) => state?.lead?.leadFlowData?.leadId);
	console.log('LeadID', LeadID);
	const [validateForm, setValidateForm] = useState<boolean>(false);
	// const [showPortfoliostatement, setShowPortfoliostatement] = useState<boolean>(false);
	const [showOtpModal, setShowOtpModal] = useState<boolean>(false);

	const [otpSubmitted, setOtpSubmitted] = useState<boolean>(false);
	const [manualUploadSubmitted, setManualUploadSubmitted] =
		useState<boolean>(false);

	const [showManualUploadModal, setShowManualUploadModal] =
		useState<boolean>(false);
	const [currentCustodian, setCurrentCustodian] = useState<string | null>(null);
	const [custodiansQueue, setCustodiansQueue] = useState<string[]>([]);

	const [custodianOptions, setCustodianOptions] = useState<any[]>([]);
	const [panEncData, setPanEncData] = useState<any>('');
	const [panDecData, setPanDecData] = useState<any>('');
	const [custodiansMandatory, setCustodiansMandatory] =
		useState<boolean>(false);

	const holdings_form_schema = yup.object({
		currentHoldings: yup
			.array()
			.of(yup.string())
			.min(1, t('Holdings selection is required.'))
			.required(),
		fetchingMode: yup
			.array()
			.of(yup.string())
			.min(1, t('Fetching Mode is required.'))
			.required(),
		custodians: custodiansMandatory
			? yup
					.array()
					.of(yup.string())
					.min(1, t('Custodian selection is required.'))
					.required()
			: yup.array().of(yup.string()).notRequired(),
	});

	const {
		control,
		trigger,
		handleSubmit,
		setValue,
		getValues,
		watch,
		reset,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(holdings_form_schema),
		defaultValues: {
			custodians: [],
			currentHoldings: [],
			fetchingMode: [],
		},
	});

	useEffect(() => {
		const fetchData = async () => {
			await getEncNumber();
			setTimeout(() => {
				getDecNumber();
			}, 4000);
		};
		fetchData();
	}, []);

	useEffect(() => {
		reset({
			custodians: [],
			currentHoldings: [],
			fetchingMode: [],
		});
	}, [location.pathname]);

	const getEncNumber = async () => {
		await dispatch(getPanNo({ param: '/' + LeadID }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setPanEncData(result?.data);
				}
			});
	};

	const getDecNumber = async () => {
		await dispatch(
			postPanNo({
				param: '/' + LeadID,
				data: {
					encrypted_panno: panEncData?.encrypted_panno,
					keyring_id: panEncData?.keyring_id,
				},
			})
		)
			.then(unwrapResult)
			.then((res: any) => {
				if (res?.status) {
					setPanDecData(res.data);
				}
			});
	};
	console.log('PAN', panDecData?.pan_no);

	const selectHoldingsFormKeys = [
		{
			name: 'currentHoldings',
			type: 'text/multicombo',
			mandatory: true,
			placeholder: 'Select Current Holdings',
			values: [
				{ value: 'stocks', description: 'Stocks' },
				{ value: 'mf', description: 'Mutual Funds' },
				{ value: 'bonds', description: 'Bonds' },
				// { value: 'debentures', description: 'Debentures' },
			],
		},
		{
			name: 'fetchingMode',
			type: 'text/multicombo',
			mandatory: true,
			placeholder: 'Fetching Mode',
			values: [
				// { value: 'custodians', description: 'Custodians' },
				{ value: 'manual', description: 'Manual Upload' },
			],
		},
		// {
		// 	name: 'custodians',
		// 	type: 'text/multicombo',
		// 	mandatory: true,
		// 	placeholder: 'Select Custodians',
		// 	values: custodianOptions,
		// },
		// {
		//     name: "portfoliostatement",
		//     type: "docupload",
		//     getValuesFunc: getValues,
		//     setValueFunc: setValue,
		//     title: "Upload Portfolio Statement",
		//     applicationId: null,
		//     documentTypeId: null,
		//     profileId: null,
		//     showCamera: false,
		//     error: errors,
		//     allowImages: false,
		//     allowPdfs: true,
		//     allowVideo: false,
		//     allowAudio: false,
		//     hidden: !showPortfoliostatement,
		//     inpUploadFunction: () => { },
		//     inpDeleteFunction: () => { },
		// },
	];

	const currentHoldingsWatch = watch('currentHoldings', []);
	const fetchingModeWatch = watch('fetchingMode', []);
	const custodiansWatch = watch('custodians', []);

	useEffect(() => {
		const currentHoldings = currentHoldingsWatch || [];
		let options = [];
		if (currentHoldings.includes('stocks')) {
			options.push(
				{ value: 'nsdl', description: 'NSDL' },
				{ value: 'cdsl', description: 'CDSL' }
			);
		}
		if (currentHoldings.includes('mf')) {
			options.push(
				{ value: 'kfin', description: 'Kfintech' },
				{ value: 'cams', description: 'CAMS' }
			);
		}
		if (currentHoldings.includes('bonds')) {
			options.push({ value: 'bonds', description: 'Bonds' });
		}
		if (currentHoldings.includes('debentures')) {
			options.push({ value: 'debentures', description: 'Debentures' });
		}
		setCustodianOptions(options);
	}, [currentHoldingsWatch]);

	useEffect(() => {
		setCustodiansMandatory(fetchingModeWatch.includes('custodians'));
	}, [custodiansMandatory, fetchingModeWatch]);

	// useEffect(() => {
	//     setShowPortfoliostatement(fetchingModeWatch.includes('manual'));
	// }, [fetchingModeWatch]);

	const onSubmit = async (data: any) => {
		if (fetchingModeWatch.includes('manual')) {
			setShowManualUploadModal(true);
		}
		if (fetchingModeWatch.includes('custodians')) {
			const custodiansStub = custodiansWatch;
			setCustodiansQueue(custodiansStub);
			if (custodiansStub.length > 0) {
				setCurrentCustodian(custodiansStub[0]);
				setShowOtpModal(true);
			}
		}
	};

	const handleOtpSubmit = () => {
		if (fetchingModeWatch.includes('custodians')) {
			const nextQueue = custodiansQueue.slice(1);
			setCustodiansQueue(nextQueue);
			if (nextQueue.length > 0) {
				setCurrentCustodian(nextQueue[0]);
			} else {
				setShowOtpModal(false);
				alert('All OTPs submitted');
				setOtpSubmitted(true);

				if (fetchingModeWatch.includes('manual')) {
					if (manualUploadSubmitted) {
						handleFinalSubmit();
					}
				} else {
					handleFinalSubmit();
				}
				// if (fetchingModeWatch.includes('custodians')) {
			}
		}
	};

	const handleManualUploadSubmit = () => {
		console.log('handleManualUploadSubmit');
		setManualUploadSubmitted(true);

		if (fetchingModeWatch.includes('custodians')) {
			if (otpSubmitted) {
				handleFinalSubmit();
			}
		} else {
			handleFinalSubmit();
		}
		setShowManualUploadModal(false);

		// if (fetchingModeWatch.includes('manual')) {
		//     const nextQueue = custodiansQueue.slice(1);
		//     setCustodiansQueue(nextQueue);
		//     if (nextQueue.length > 0) {
		//         setCurrentCustodian(nextQueue[0]);
		//     } else {
		//         setShowOtpModal(false);
		//         alert('All OTPs submitted');
		//     }
		// }
	};

	const handleFinalSubmit = async () => {
		await dispatch(
			updatePortfolioDetailsLas({
				param: '/' + LeadID,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				// if (response.status) {
				console.log('res->', response);
				history.push('/showHoldings');

				// }
			});
	};

	return (
		<IonPage className='bg-web-white container-width-md container-spacing-md border-md-radius-top'>
			<Header
				className='card-header'
				name={t('Custodian Selection')}
				backButton={true}
				needArrow={true}
				needArrowOnClick={() => {
					history.push('/dashboard');
				}}
			/>
			<IonContent className='ion-padding-bottom'>
				<form onSubmit={handleSubmit(onSubmit)}>
					<IonCard>
						<IonCardContent>
							<CustomForm
								formKeys={selectHoldingsFormKeys}
								control={control}
								getValues={getValues}
								setValue={setValue}
								errors={errors}
								isValid={validateForm}
								trigger={trigger}
							/>
						</IonCardContent>
					</IonCard>
					<div
						className='position-fixed w-100 bottom-0 start-0 px-3 pb-3'
						style={{ backgroundColor: '#fff' }}
					>
						<CustomButton
							className='btn-blue'
							type='submit'
							expand='block'
							title='Submit'
							onClick={() => {
								handleSubmit(onSubmit);
								setValidateForm(isValid);
							}}
						/>
					</div>
				</form>

				<OtpModal
					isOpen={showOtpModal}
					onClose={() => setShowOtpModal(false)}
					custodian={currentCustodian}
					onSubmit={handleOtpSubmit}
				/>
				<ManualUploadModal
					isOpen={showManualUploadModal}
					onClose={() => setShowManualUploadModal(false)}
					showBonds={currentHoldingsWatch.includes('bonds')}
					showDebentures={currentHoldingsWatch.includes('debentures')}
					showEquity={currentHoldingsWatch.includes('stocks')}
					showMF={currentHoldingsWatch.includes('mf')}
					onSubmit={handleManualUploadSubmit}
				/>
			</IonContent>
		</IonPage>
	);
};
