import React, { useState } from 'react';
import {
    IonButton,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

interface OtpModalProps {
    isOpen: boolean;
    onClose: () => void;
    custodian: string | null;
    onSubmit: () => void;
}

const OtpModal: React.FC<OtpModalProps> = ({ isOpen, onClose, custodian, onSubmit }) => {
    const { t } = useTranslation();
    const [otp, setOtp] = useState<string>("");
    const history = useHistory();

    const handleOtpSubmit = () => {
        alert(`OTP Submitted for ${custodian}: ${otp}`);
        setOtp("");
        onSubmit();
        // history.push('/showHoldings');
    };

    const handleResendOtp = () => {
        alert(`OTP Resent for ${custodian}`);
    };

    return (
        <IonModal isOpen={isOpen} onDidDismiss={onClose}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{t("Enter OTP")}</IonTitle>
                    <IonButton slot="end" onClick={onClose}>
                        Close
                    </IonButton>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonItem>
                    <IonLabel position="floating">{t("OTP for")} {custodian}</IonLabel>
                    <IonInput
                        type="text"
                        value={otp}
                        onIonChange={(e) => setOtp(e.detail.value!)}
                    />
                </IonItem>
                <IonButton expand="block" onClick={handleOtpSubmit}>
                    {t("Submit OTP")}
                </IonButton>
                <IonButton expand="block" color="medium" onClick={handleResendOtp}>
                    {t("Resend OTP")}
                </IonButton>
            </IonContent>
        </IonModal>
    );
};

export default OtpModal;
