import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

interface ConsentAuthContextType {
  consentToken: string | null;
  consentUserId: string | null;
  consentApplicationId: string | null;
  consentMobile: string | null;
  tokenForOtp: string | null;
}

export const ConsentAuthContext = createContext<ConsentAuthContextType>({
  consentToken: null,
  consentUserId: null,
  consentApplicationId: null,
  consentMobile: null,
  tokenForOtp: null
});

export const ConsentAuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const location = useLocation();

  const [consentToken, setConsentToken] = useState<string | null>(null);
  const [consentUserId, setConsentUserId] = useState<string | null>(null);
  const [consentApplicationId, setConsentApplicationId] = useState<string | null>(null);
  const [consentMobile, setConsentMobile] = useState<string | null>(null);
  const [ tokenForOtp , setTokenForOtp ] = useState<string | null>(null);

  useEffect(() => {
    const url = new URL(window.location.href);
    // const url = new URL('https://crmdev.dromecrm.com/term-of-use/eyJhbGciOiJIUzI1NiIsImtpZCI6Im9uZS1hbmRyby1rZXkifQ.eyJleHAiOjE3MjM5NjM2MzIsImp0aSI6ImQ1NzllZDJjLTVlNjktNDg1Zi1iYjI3LTA3YjYyNmZiOGNiOCIsInJvbGVzIjpbIkRNQSJdLCJ1c2VySWQiOjMzMjMxfQ.si5I-qGJQvHYIABvHY-RYTkF_LkRE5NnbTZxjT1b_7M/33231/727/9762676150/')
    const pathname = url.pathname;

    const pathSegments = pathname.split('/').filter(Boolean); 
    console.log('pathsegment', pathSegments)

    const tokenFromUrl = pathSegments[1];
    // console.log('token',tokenFromUrl )
    const userIdFromUrl = pathSegments[2];
    // console.log('userId', userIdFromUrl)
    const appIdFromUrl = pathSegments[3];
    // console.log('appId', appIdFromUrl)
    const mob = pathSegments[4];
    // console.log('mob', mob)

    if (tokenFromUrl && userIdFromUrl && appIdFromUrl && mob) {
      setTokenForOtp(tokenFromUrl)
      setConsentToken(`Bearer ${tokenFromUrl}`);
      setConsentUserId(userIdFromUrl);
      setConsentApplicationId(appIdFromUrl);
      setConsentMobile(mob);
    }
  }, [location]);
  console.log('setConsentToken ',consentToken);


  return (
    <ConsentAuthContext.Provider value={{ consentToken, consentUserId, consentApplicationId, consentMobile, tokenForOtp }}>
      {children}
    </ConsentAuthContext.Provider>
  );
};
