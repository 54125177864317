import { createAsyncThunk } from '@reduxjs/toolkit';
// import { apiService } from './apiService';

export const apiAction: any = (actionName: any, method: any, endPoint: any) =>
	createAsyncThunk(actionName, async (data: any, thunkAPI: any) => {
		const endPointUrl = data?.param ? endPoint + data?.param : endPoint;

		try {
			return await method(endPointUrl, data?.data);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	});
