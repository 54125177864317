import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { Otptimer } from 'otp-timer-ts';
import {
	dashboardLeadGenerateOtp,
	downloadLeadVerifyOtp,
	lenderSpfDetailsFlow,
} from '../../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { STATUS_CODE } from '../../../helpers/constant';
import '../../otp-screen/OtpVerification.scss';
import { Header } from '../../../components/layout/header/Header';
import { SmsRetriever } from '@awesome-cordova-plugins/sms-retriever';
import {
	directLenderSpecific,
	isLenderIncludes,
	rmNotRequired,
} from '../../../helpers/functions';

interface OtpVerificationInterface {
	length?: number;
}

export const VerifyLenderWithOtp: React.FC<OtpVerificationInterface> = ({
	length = 4,
}) => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
	const history = useHistory();
	const [otp, setOtp] = useState<any>(new Array(4).fill(''));
	const [error, setError] = useState<boolean>(false);
	const [valid, setVaidOtp] = useState<any>(false);
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	const leadLender = useSelector((state: any) => state?.lead);
	const productCategoryId = useSelector(
		(state: any) =>
			state?.lead?.leadFlowData?.selectedProductData?.product_category_id
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [nextButtonActive, setNextButtonActive] = useState<boolean>(true);

	const handleChange = async (element: any | undefined, index: number) => {
		if (isNaN(element.value)) return false;
		setOtp([
			...otp.map((d: any, idx: any) => (idx === index ? element.value : d)),
		]);
		if (index <= 3) {
			element.value && inputRefs.current[index + 1]?.focus();
		}
		if (index >= 0) {
			setError(false);
		}
	};

	const handleKeyDown = (index: number, e: any) => {
		if (e.key === 'Backspace' && index > 0) {
			e.target.value == '' && inputRefs.current[index - 1]?.focus();
		}
		if (otp.length === 3) {
			setNextButtonActive(false);
		} else {
			setNextButtonActive(true);
		}
	};

	const sendOtp = async () => {
		inputRefs.current[0]?.focus();
		setOtp(new Array(4).fill(''));

		await dispatch(
			dashboardLeadGenerateOtp({
				data: {
					mobile: leadInfo?.leadMobile,
					reLogin: false,
					lender: leadLender?.selectedLender[0]?.lender_name,
				},
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					if (response?.code === STATUS_CODE.SIGNIN_SUCCESS) {
						setOtp(new Array(4).fill(''));
						setVaidOtp(false);
					}
				}
			});
	};

	const resendOtp = async () => {
		inputRefs.current[0]?.focus();
		setOtp(new Array(4).fill(''));

		await dispatch(
			dashboardLeadGenerateOtp({
				data: {
					mobile: leadInfo?.leadMobile,
					reLogin: true,
					lender: leadLender?.selectedLender[0]?.lender_name,
				},
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					if (response?.code === STATUS_CODE.SIGNIN_SUCCESS) {
						setOtp(new Array(4).fill(''));
						// setTimer(OTPTIMER);
						setVaidOtp(false);
					}
				}
			});
	};

	// verify otp
	const verifyLeadOtp = async () => {
		if (otp[0] !== '' && otp[1] !== '' && otp[2] !== '' && otp[3] !== '') {
			await dispatch(
				downloadLeadVerifyOtp({
					param: leadInfo?.leadId,
					data: {
						mobile: leadInfo?.leadMobile,
						otp: otp.join('').toString(),
						lender: leadLender?.selectedLender[0]?.lender_name,
						lead_app_id: leadLender?.selectedLender[0]?.id,
					},
				})
			)
				.then(unwrapResult)
				.then(async (response: any) => {
					if (response?.code === STATUS_CODE.VERIFICATION_SUCCESSFUL) {
						/*dispatch(
						leadFlow({
							...leadInfo,
							headerTitle: '',
							subHeaderStepsCounter: false,
							needCibil: false,
						})
					);*/
						// VerifyOtp
						if (
							(leadLender?.selectedLender[0]?.lender_name == 'L&T Finance' ||
								leadLender?.selectedLender[0]?.lender_name ==
									'Piramal Capital') &&
							(productCategoryId == 3 || productCategoryId == 4)
						) {
							history.replace('/rm-selection-form', {
								lender_name:
									leadLender?.selectedLender[0]?.lender_name?.toLowerCase(),
							});
						} else if (
							(leadLender?.selectedLender[0]?.lender_name.toLowerCase() ==
								'aditya birla finance ltd.' ||
								leadLender?.selectedLender[0]?.lender_name.toLowerCase() ==
									'dmi housing') &&
							productCategoryId === 2
						) {
							history.replace('/rm-selection-form', {
								lender_name:
									leadLender?.selectedLender[0]?.lender_name?.toLowerCase(),
							});
						} else if (
							isLenderIncludes(
								leadLender?.selectedLender[0]?.lender_name?.toLowerCase()
							) &&
							!rmNotRequired(
								leadLender?.selectedLender[0]?.lender_name.toLowerCase()
							)
						) {
							history.replace('/rm-selection-form', {
								lender_name:
									leadLender?.selectedLender[0]?.lender_name?.toLowerCase(),
							});
						} else if (
							directLenderSpecific(
								leadLender?.selectedLender[0]?.lender_name.toLowerCase()
							)
						) {
							dispatch(
								lenderSpfDetailsFlow({
									step: 'step1',
									backButton: true,
									needArrow: true,
									needCross: false,
								})
							);
							history.replace('/lead-lender-specific-form-steps', {
								lender_name:
									leadLender?.selectedLender[0]?.lender_name?.toLowerCase(),
							});
							history.push('/lead-lender-specific-form-steps');
						} else {
							history.replace('/bank-online-form');
						}
						setVaidOtp(false);
					}
				});
		} else {
			setError(true);
		}
	};

	useEffect(() => {
		sendOtp();
		if (inputRefs?.current?.length > 0) {
			const value: any = inputRefs.current ? inputRefs.current : false;
			if (value && Array.isArray(value)) {
				setTimeout(() => value?.[0].focus(), 700);
			}
		}
	}, []);

	const validOtp =
		otp[0] !== '' &&
		otp[1] !== '' &&
		otp[2] !== '' &&
		otp[3] !== '' &&
		!valid &&
		error;

	useEffect(() => {
		SmsRetriever.startWatching()
			.then((res: any) => {
				if (res?.Message) {
					const otpregex = new RegExp(/^[0-9]{4}$/);
					const msgArray = res?.Message.split(' ');
					let getOtp: any;
					msgArray.map((i: any) => {
						if (otpregex.test(i)) {
							getOtp = i;
						}
					});
					const otp1 = getOtp.split('').map((i: any) => {
						return i;
					});
					setOtp(otp1);
				}
			})
			.catch((error: any) => console.error(error));
	}, []);

	return (
		<IonPage>
			<Header
				name={t('OTP Verification')}
				backButton={true}
				needArrow={true}
				style={{ borderBottom: '1px solid #00000029' }}
			/>
			<IonContent className='otp-verification'>
				{/* <div className='row h-100'>
						<div className='col-md-6 border-end d-none d-md-block ion-padding'>
							<div className='getting-started h-100 container'>
								<StartPage />
							</div>
						</div>
						<div className='col-md-6 col-12 d-flex'>
							<div className='right-side-card'> */}
				<div className='select-product small-container h-100 bg-web'>
					<div className='bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
						<IonGrid>
							<IonRow>
								<IonCol>
									<div className='mt-4 mt-md-0'>
										<h2 className='ion-no-margin fw-600 fs-22'>
											{t('We have sent you an OTP')}
										</h2>
										<div className='ion-no-margin description'>
											<span className='text-grey'>
												{t('Please enter the OTP sent on your')}
												<br />
												{t('Mobile No.')} +91 XXXXXX
												{String(leadInfo?.leadMobile)?.slice(6)}.
											</span>
										</div>
									</div>
								</IonCol>
							</IonRow>
						</IonGrid>
						<IonGrid className='otp-input-box'>
							<IonRow>
								{[...Array(length)].map((item, index) => {
									return (
										<IonCol
											size='3'
											key={index}
										>
											<input
												inputMode='numeric'
												pattern='[0-9]*'
												max='1'
												id={'otp' + index}
												ref={(ref) => (inputRefs.current[index] = ref)}
												value={otp[index] || ''}
												onChange={(e: any) => handleChange(e.target, index)}
												onKeyDown={(e) => handleKeyDown(index, e)}
												maxLength={1}
												className={`form-control text-center ${
													valid ? 'input-invalid' : validOtp && 'input-valid'
												}`}
												required={error}
											></input>
										</IonCol>
									);
								})}
							</IonRow>
							{error && (
								<p className='error fs-12 text-danger ms-2 mb-0'>
									{t('Please Enter OTP')}
								</p>
							)}
							{valid && (
								<p className='error fs-12 text-danger ms-2 mb-0'>
									{t('Please Enter Valid OTP')}
								</p>
							)}
						</IonGrid>
						<div className={'pt-2'}>
							<CustomButton
								title={t('Verify')}
								expand='block'
								onClick={verifyLeadOtp}
							/>

							<div className='ion-no-margin pt-3'>
								{t('Didn’t receive OTP?')}{' '}
								<div className='d-inline-block'>
									<Otptimer
										minutes={0}
										seconds={59}
										onResend={() => {
											resendOtp();
										}}
										text={t('Resend OTP in').toString()}
										buttonText={t('Resend OTP').toString()}
									/>
								</div>
							</div>
						</div>

						{/* </div>
						</div>
					</div> */}
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};
