import React, { useEffect } from 'react';
import { useIonRouter } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';

import { Header } from '../../../components/layout/header/Header';
import { LeadsFlowGenerator } from '../../../components/common/custom-leads-flow-generator/LeadsFlowGenerator';
import { lenderSpfDetailsFlow } from '../../../store/reducers/lead/leadSlice';
import { HLLAPForms } from './HL_LAP_Forms/HLLAPForms';

export const InitiateDMILead: React.FC = () => {
	type ComponentType = React.ReactNode | React.ReactFragment;
	type StepsComponents = {
		step: string;
		component: ComponentType;
	};
	const dispatch = useDispatch();
	const router = useIonRouter();
	const { lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);

	useEffect(() => {
		if (!location.pathname.includes('/lead-lender-specific-form-steps')) {
			return;
		}
	}, [location.pathname]);

	/**
	 * Go back to click on back icon
	 */
	const headerNeedArrowOnClick = async () => {
		if (leadFlowData?.selectedProductData?.product_category_id == 2) {
			// switch (lenderSpecificDetails?.step) {
			// 	case 'step4':
			// 		dispatch(
			// 			lenderSpfDetailsFlow({
			// 				...lenderSpecificDetails,
			// 				step: 'step3',
			// 			})
			// 		);
			// 		break;
			// 	case 'step3':
			// 		dispatch(
			// 			lenderSpfDetailsFlow({
			// 				...lenderSpecificDetails,
			// 				step: 'step2',
			// 			})
			// 		);
			// 		break;
			// 	case 'step2':
			// 		dispatch(
			// 			lenderSpfDetailsFlow({
			// 				...lenderSpecificDetails,
			// 				step: 'step1',
			// 			})
			// 		);
			// 		break;
			// 	case 'step1':
			// 		dispatch(lenderSpfDetailsFlow({}));
			// 		router.push('/lender-specific-info');
			// 		break;
			// }
		} else {
			dispatch(lenderSpfDetailsFlow({}));
			router.push('/lender-specific-info');
		}
	};

	const stepsComponentsHLLAP: StepsComponents[] = [
		{
			step: 'step1',
			component: <HLLAPForms />,
		},
	];

	const stepsHeader: ComponentType = (
		<Header
			name={'DMI Specific Form'}
			backButton={true}
			needArrow={true}
			needCross={false}
			needArrowOnClick={() => headerNeedArrowOnClick()}
		/>
	);

	return (
		<>
			<LeadsFlowGenerator
				currentStep={lenderSpecificDetails?.step}
				stepsHeader={stepsHeader}
				displayStepsCounter={lenderSpecificDetails?.subHeaderStepsCounter}
				stepsComponents={stepsComponentsHLLAP}
			/>
		</>
	);
};
