import { IonImg } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
// import { CustomButton } from '../../../components/common/custom-button/CustomButton';

export const WelcomeInfoKyc = () => {
	const { t } = useTranslation();
	return (
		<>
			<div className='ion-padding'>
				<div>
					<h4 className='fs-16 mt-2 pb-2 fw-600'>
						{t('Complete Your KYC in Just 2 Simple Steps')}
					</h4>
					<div className='d-flex align-items-baseline mb-3'>
						<div>
							<span className='welcome-number'>1</span>
						</div>

						<div className='ms-2'>
							<h4
								className='fs-14 m-0 pt-2 fw-600'
								style={{
									color: 'var(--text-color-darkest-grey)',
								}}
							>
								{t('Enter Personal & Professional details required for KYC')}
							</h4>
						</div>
					</div>
					<IonImg
						src='/images/icons/kyc/basic-info-icon.svg'
						style={{ width: '112px' }}
						className='mb-5 m-auto'
					/>
				</div>
				<div>
					<div className='d-flex align-items-baseline mb-3'>
						<div>
							<span className='welcome-number'>2</span>
						</div>

						<div className='ms-2'>
							<h4
								className='fs-14 m-0 pt-2 fw-600'
								style={{
									color: 'var(--text-color-darkest-grey)',
								}}
							>
								{t('Keep these documents ready')}
							</h4>

							<p
								className='fs-12 mt-2'
								style={{
									color: 'var(--text-dark-grey)',
								}}
							>
								{t(
									'PAN, Aadhaar, Passport Photo, GST Certificate, Registration Certificate, Address proof, Cancelled Cheque'
								)}
							</p>
						</div>
					</div>
					<IonImg
						src='/images/icons/kyc/documents-icon.svg'
						style={{ width: '182px' }}
						className='mb-5 m-auto'
					/>
				</div>
				{/* <div className='w-100 position-absolute bottom-0 start-0 px-3 py-3'>
					<div className='d-flex'>
						<IonImg
							src='/images/icons/kyc/secure-kyc-icon.svg'
							style={{ width: '18px' }}
							className='pb-2 pt-2 m-auto'
						/>
						<span
							className='fs-12'
							style={{
								color: 'var(--text-dark-grey)',
							}}
						>
							100% secure KYC
						</span>
					</div>
					<CustomButton
						expand='block'
						onClick={() => {
							setNeedArrow(false);
							setNeedCross(true);
							setDisplayStepsCounter(true);
							setCurrentStep('step1');
						}}
						className='btn-blue'
						title='Get Started'
					/>
				</div> */}
			</div>
		</>
	);
};
