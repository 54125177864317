/* eslint-disable @typescript-eslint/no-explicit-any */
import { SmsRetriever } from '@awesome-cordova-plugins/sms-retriever';

import {
	IonCol,
	IonContent,
	IonGrid,
	IonImg,
	IonPage,
	IonRow,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import './BureauScore.scss';
import { useTranslation } from 'react-i18next';
import {
	LEAD_SCORE_TYPE,
	OTPTIMER,
	STATUS_CODE,
} from '../../../helpers/constant';
import { Header } from '../../../components/layout/header/Header';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { showToastAlert } from '../../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import {
	cbilRequestConsentDetails,
	// cbilRequestUpdateConsentDetails,
	dashboardLeadGenerateOtp,
	downloadLeadVerifyOtp,
	generateCibilOtp,
	leadBureauCustomer,
	leadFlow,
	verifyCibil,
} from '../../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	apiLogin,
	// teamDmaVerifyOtp,
} from '../../../store/reducers/user/userSlice';
import CustomNote from '../../../components/common/custom-note/CustomNote';
import { Otptimer } from 'otp-timer-ts';
import { isPlatform } from '@ionic/core';
interface OtpVerificationInterface {
	length?: number;
	isLeadSteps?: any;

	currentStep?: string;
	routerLink?: any;
}
export const BureauReportOtp: React.FC<OtpVerificationInterface> = ({
	length = 4,
}) => {
	const { t } = useTranslation();
	const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [nextButtonActive, setNextButtonActive] = useState<boolean>(true);
	const [confirmPopup, setConfirmPopup] = useState<boolean>(false);
	const history = useHistory();
	const [otp, setOtp] = useState<any>(new Array(4).fill(''));
	const [error, setError] = useState<boolean>(false);
	const dispatch = useDispatch();
	const leadBureauCustomerSelector = useSelector(
		(state: any) => state?.lead?.leadBureauCustomerData
	);

	const fetchBureauCustomerReportData = useSelector(
		(state: any) => state?.lead?.fetchBureauCustomerReportData
	);
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);

	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	const location = useLocation<any>();

	useEffect(() => {
		setOtp(
			new Array(
				leadBureauCustomerSelector?.type === 'VERIFY_CIBIL_OTP' ? 6 : 4
			).fill('')
		);
		if (inputRefs?.current?.length > 0) {
			const value: any = inputRefs.current ? inputRefs.current : false;
			if (value && Array.isArray(value)) {
				setTimeout(() => value?.[0].focus(), 700);
			}
		}
	}, [
		leadBureauCustomerSelector?.leadId,
		leadBureauCustomerSelector?.type,
		leadBureauCustomerSelector?.leadMobile,
	]);

	useEffect(() => {
		if (isPlatform('capacitor')) {
			SmsRetriever.startWatching()
				.then((res: any) => {
					if (res?.Message) {
						const otpregex = new RegExp(/^[0-9]{4}$/);
						const msgArray = res?.Message.split(' ');
						let getOtp: any;
						msgArray.map((i: any) => {
							if (otpregex.test(i)) {
								getOtp = i;
							}
						});
						const otp1 = getOtp.split('').map((i: any) => {
							return i;
						});
						setOtp(otp1);
					}
				})
				.catch((error: any) => console.error(error));
		}
	}, []);

	const handleChange = async (element: any | undefined, index: number) => {
		if (isNaN(element.value)) return false;
		setOtp([
			...otp.map((d: any, idx: any) => (idx === index ? element.value : d)),
		]);
		if (
			index <= (leadBureauCustomerSelector?.type === 'VERIFY_CIBIL_OTP' ? 5 : 3)
		) {
			element.value && inputRefs.current[index + 1]?.focus();
		}
		if (index >= 0) {
			setError(false);
		}
	};
	const handleSubmit = () => {
		if (leadBureauCustomerSelector?.type === 'VERIFY_LEAD') {
			if (
				leadBureauCustomerSelector?.scoreType?.toLocaleLowerCase() ===
					LEAD_SCORE_TYPE.EQUIFAX.toLocaleLowerCase() ||
				leadBureauCustomerSelector?.scoreType?.toLocaleLowerCase() ===
					LEAD_SCORE_TYPE.BOTH.toLocaleLowerCase()
			) {
				if (otp[0] !== '' && otp[1] !== '' && otp[2] !== '' && otp[3] !== '') {
					setError(false);
					const newOtp = otp.join('').toString();
					dispatch(
						downloadLeadVerifyOtp({
							param:
								location?.state?.leadId ||
								leadBureauCustomerSelector?.leadId ||
								fetchBureauCustomerReportData?.lead_id,
							data: {
								mobile: leadBureauCustomerSelector?.leadMobile,
								otp: newOtp,
								lead_id:
									location?.state?.leadId ||
									leadBureauCustomerSelector?.leadId ||
									fetchBureauCustomerReportData?.lead_id,
							},
						})
					)
						.then(unwrapResult)
						.then((response: any) => {
							dispatch(
								leadBureauCustomer({
									...leadBureauCustomerSelector,
									cibil_id: null,
								})
							);
							if (response?.status) {
								if (response?.is_cibil) {
									history.replace('/view-bureau-report');

									dispatch(
										leadBureauCustomer({
											...leadBureauCustomerSelector,
											cibil_id: response?.lead_id,
										})
									);
								} else if (
									leadBureauCustomerSelector?.scoreType?.toLocaleLowerCase() ===
									LEAD_SCORE_TYPE.BOTH.toLocaleLowerCase()
								) {
									setConfirmPopup(true);
								} else {
									handlePopupSkip();
								}
							}
						});
				} else {
					setError(true);
				}
			} else if (
				leadBureauCustomerSelector?.scoreType?.toLocaleLowerCase() ===
				LEAD_SCORE_TYPE.CBIL.toLocaleLowerCase()
			) {
				if (otp[0] !== '' && otp[1] !== '' && otp[2] !== '' && otp[3] !== '') {
					setError(false);
					const newOtp = otp.join('').toString();
					dispatch(
						downloadLeadVerifyOtp({
							param: leadBureauCustomerSelector?.leadId,
							data: {
								mobile: leadBureauCustomerSelector?.leadMobile,
								otp: newOtp,
							},
						})
					)
						.then(unwrapResult)
						.then((response: any) => {
							if (response?.status) {
								if (
									leadBureauCustomerSelector?.scoreType?.toLocaleLowerCase() ===
									LEAD_SCORE_TYPE.BOTH.toLocaleLowerCase()
								) {
									setConfirmPopup(true);
								} else {
									handlePopupSkip();
								}
							}
						});
				} else {
					setError(true);
				}
			}
		} else if (leadBureauCustomerSelector?.type === 'VERIFY_CIBIL_OTP') {
			if (
				otp[0] !== '' &&
				otp[1] !== '' &&
				otp[2] !== '' &&
				otp[3] !== '' &&
				otp[4] !== '' &&
				otp[5] !== ''
			) {
				setError(false);
				const newOtp = otp.join('').toString();
				dispatch(
					verifyCibil({
						data: {
							...leadBureauCustomerSelector?.cbilResponse,
							lead_id: leadBureauCustomerSelector?.leadId,
							otp: newOtp,
						},
					})
				)
					.then(unwrapResult)
					.then((response: any) => {
						if (response?.status) {
							// dispatch({ ...leadBureauCustomerSelector, needCibil: true });
							history.replace('/view-bureau-report');
						}
					});
			} else {
				setError(true);
			}
		}
	};

	const resendOtp: any = async () => {
		let sms_code: any;
		if (isPlatform('capacitor')) {
			await SmsRetriever.getAppHash()
				.then((res: any) => {
					sms_code = res;
				})
				.catch((error: any) => console.error(error));
		}
		if (leadBureauCustomerSelector?.type === 'VERIFY_LEAD') {
			inputRefs.current[0]?.focus();
			setOtp(new Array(4).fill(''));
			await dispatch(
				dashboardLeadGenerateOtp({
					data: {
						mobile: leadBureauCustomerSelector?.leadMobile,
						reLogin: true,
						sms_hc: sms_code,
					},
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						dispatch(
							leadBureauCustomer({
								...leadBureauCustomerSelector,
								reSendCibilOtp: true,
							})
						);
						// setTimer(OTPTIMER);
						//showToastAlert({ type: 'success', message: 'OTP Resent' });
					}
				});
		} else if (leadBureauCustomerSelector?.type === 'VERIFY_CIBIL_OTP') {
			inputRefs.current[0]?.focus();
			setOtp(new Array(6).fill(''));
			// FETCH_CIBIL_BUREAU_REPORT
			await dispatch(
				generateCibilOtp({
					data: {
						...leadBureauCustomerSelector?.checkScoreBody,
						lead_id: leadBureauCustomerSelector?.leadId,
						mobile_no: leadBureauCustomerSelector?.leadMobile,
						sms_hc: sms_code,
					},
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						dispatch(
							leadBureauCustomer({
								...leadBureauCustomerSelector,
								reSendCibilOtp: true,
							})
						);
						showToastAlert({ type: 'success', message: 'OTP Resent' });
					}
				});
		} else {
			inputRefs.current[0]?.focus();
			setOtp(new Array(4).fill(''));

			await dispatch(
				apiLogin({
					data: {
						mobile: Number(leadBureauCustomerSelector?.leadMobile),
						sms_hc: sms_code,
					},
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.code === STATUS_CODE.SIGNIN_SUCCESS) {
						// setTimer(OTPTIMER);
						showToastAlert({ type: 'success', message: 'OTP Resent' });
					}
				});
		}
	};

	const handleKeyDown = (index: number, e: any) => {
		if (e.key === 'Backspace' && index > 0) {
			e.target.value == '' && inputRefs.current[index - 1]?.focus();
		}
		if (otp.length === 3) {
			setNextButtonActive(false);
		} else {
			setNextButtonActive(true);
		}
	};

	const handlePopupProceed = async () => {
		setOtp(new Array(4).fill(''));
		setConfirmPopup(false);
		dispatch(
			cbilRequestConsentDetails({
				param: `${leadBureauCustomerSelector?.leadId}`,
			})
		)
			.then(unwrapResult)
			.then(async (response: any) => {
				if (response.status) {
					if (response?.is_consent) {
						if (response?.is_otp) {
							history.replace('/view-bureau-report');
						} else {
							console.log('data:', {
								...leadBureauCustomerSelector?.checkScoreBody,
								lead_id: leadBureauCustomerSelector?.leadId,
								mobile_no: leadBureauCustomerSelector?.leadMobile,
							});

							await dispatch(
								generateCibilOtp({
									data: {
										...leadBureauCustomerSelector?.checkScoreBody,
										lead_id: leadBureauCustomerSelector?.leadId,
										mobile_no: leadBureauCustomerSelector?.leadMobile,
									},
								})
							)
								.then(unwrapResult)
								.then(async (cbilOtpResponse: any) => {
									if (cbilOtpResponse.status) {
										dispatch(
											leadBureauCustomer({
												...leadBureauCustomerSelector,
												cbilResponse: {
													ChallengeConfigGUID:
														cbilOtpResponse?.ChallengeConfigGUID,
													answerKey: cbilOtpResponse?.answerKey,
													questionKey: cbilOtpResponse?.questionKey,
												},
												type: 'VERIFY_CIBIL_OTP',
												reSendCibilOtp: false,
												needCibil: true,
											})
										);
										history.push('/verify-otp-bureau-report', {
											lead_id:
												location?.state?.leadId ||
												leadBureauCustomerSelector?.leadId ||
												fetchBureauCustomerReportData?.lead_id,
										});
									}
								});
						}
					} else {
						// dispatch(
						// 	cbilRequestUpdateConsentDetails({
						// 		param: response?.pccId + '?consentVal=1',
						// 	})
						// )
						// 	.then(unwrapResult)
						// 	.then(async (cbilUpdateResponse: any) => {
						// 		if (cbilUpdateResponse?.status) {
						// 			dispatch(
						// 				leadFlow({
						// 					lead_consent_type: 'Customer',
						// 					leadId: leadBureauCustomerSelector?.leadId,
						// 					pccId: response?.pccId,
						// 				})
						// 			);
						// 			history.push('/customer-consent-processing');
						// 		}
						// 	});
						dispatch(
							leadFlow({
								...leadInfo,
								lead_consent_type: 'Customer',
								leadId: leadBureauCustomerSelector?.leadId,
								pccId: response?.pccId,
								leadMobile: leadBureauCustomerSelector.leadMobile,
							})
						);
						history.push('/customer-consent-processing');
					}
				}
			});
	};
	const handlePopupSkip = () => {
		setOtp(new Array(4).fill(''));
		setConfirmPopup(false);
		const myPromise = new Promise(function (myResolve) {
			setTimeout(function () {
				myResolve(
					history.replace('/view-bureau-report', {
						lead_id:
							location?.state?.leadId ||
							leadBureauCustomerSelector?.leadId ||
							fetchBureauCustomerReportData?.lead_id,
					})
				);
			}, 10);
		});
		myPromise.then(() => {
			dispatch(
				leadBureauCustomer({
					...leadBureauCustomerSelector,
					// needCibil: false,
					scoreType: LEAD_SCORE_TYPE.EQUIFAX,
				})
			);
		});
	};

	return (
		<>
			<IonPage className=''>
				<Header
					name={t('OTP Verification')}
					backButton={true}
					needArrow={
						leadBureauCustomerSelector?.type === 'VERIFY_LEAD' ? true : false
					}
					needCross={
						leadBureauCustomerSelector?.type === 'VERIFY_CIBIL_OTP'
							? true
							: false
					}
					needArrowOnClick={() => {
						if (leadBureauCustomerSelector?.type === 'VERIFY_CIBIL_OTP') {
							setConfirmPopup(true);
						}
						if (leadBureauCustomerSelector?.type === 'VERIFY_LEAD') {
							// history.push(location?.state?.url);
							history.push('/check-bureau-score');
						}
						// if (leadBureauCustomerSelector?.type === 'VERIFY_LEAD') {
						// 	history.push('/add-bureau-score');
						// }
					}}
					needCrossOnClick={() => {
						if (leadBureauCustomerSelector?.type === 'VERIFY_CIBIL_OTP') {
							history.replace('/add-bureau-score');
						}
						if (leadBureauCustomerSelector?.type === 'VERIFY_LEAD') {
							history.replace('/add-bureau-score');
						}
					}}
					style={{ borderBottom: '1px solid #00000029' }}
				/>

				<IonContent className='background-web'>
					<div className='bg-web'>
						<div className='bureau-report-otpverification border-md-radius-10 bg-web-white mt-4 px-3 px-md-3'>
							<IonGrid>
								<IonCol className='pt-0'>
									<div className=''>
										{leadBureauCustomerSelector?.type === 'VERIFY_LEAD' ? (
											<CustomNote
												{...{
													header: t('Important'),
													content: t(
														'With this OTP Verification Equifax Bureau Report will be fetched.'
													),
													className: 'mb-4',
												}}
											/>
										) : (
											''
										)}

										{/* <div
											className='note'
											style={{
												background: '#FEF7EA',
												borderRadius: '10px',
												padding: '6px 16px',
											}}
										>
											<h5
												className='text-uppercase fs-12 fw-600'
												style={{ color: '#FFA403' }}
											>
												Important
											</h5>
											<p className='fs-14 mb-0'>
												{t(`With this OTP Verification Equifax Bureau Report will be
											fetched.`)}
											</p>
										</div> */}
										<div className='ion-no-margin description'>
											<span
												className='text-wrap'
												style={{ color: '#A0A4B3' }}
											>
												{leadBureauCustomerSelector?.type === 'VERIFY_LEAD'
													? t(
															`Please enter the OTP we have sent to Lead's Mobile No.`
													  )
													: t(
															'Please enter the OTP on the request screen to complete the authententication, We have sent on mobile no.'
													  )}
												+91 XXXXXX
												{String(leadBureauCustomerSelector?.leadMobile)?.slice(
													6
												)}
												.
											</span>
											{/* {leadBureauCustomerSelector?.type ===
											'VERIFY_CIBIL_OTP' ? (
												''
											) : (
												<span
													className='cursor-pointer text-primary 12'
													onClick={() => {
														setOtp(new Array(4).fill(''));
														history.goBack();
													}}
												>
													<b> {t('Change Number')}</b>
												</span>
											)} */}
										</div>
									</div>
								</IonCol>
							</IonGrid>

							<IonGrid className='otp-input-box'>
								<IonRow>
									{[
										...Array(
											leadBureauCustomerSelector?.type === 'VERIFY_CIBIL_OTP'
												? 6
												: length
										),
									].map((item, index) => {
										return (
											<IonCol
												size={
													leadBureauCustomerSelector?.type ===
													'VERIFY_CIBIL_OTP'
														? '2'
														: '3'
												}
												key={index}
											>
												<input
													inputMode='numeric'
													pattern='[0-9]*'
													max='1'
													id={'otp' + index}
													ref={(ref) => (inputRefs.current[index] = ref)}
													value={otp[index] || ''}
													onChange={(e: any) => handleChange(e.target, index)}
													onKeyDown={(e) => handleKeyDown(index, e)}
													onBeforeInput={(evt: any) => {
														!/[0-9]/.test(evt.data) && evt.preventDefault();
													}}
													onWheel={(e: any) => e.target.blur()}
													maxLength={1}
													className='form-control text-center'
												></input>
											</IonCol>
										);
									})}
								</IonRow>
								{error && (
									<p className='error fs-12 text-danger ms-2 mb-0'>
										{t('Please Enter OTP')}
									</p>
								)}
							</IonGrid>
							<div className='pt-4 py-md-3'>
								<CustomButton
									title={t('Verify')}
									className='btn-blue'
									expand='block'
									onClick={handleSubmit}
								/>
								{leadBureauCustomerSelector?.type === 'VERIFY_CIBIL_OTP' ? (
									leadBureauCustomerSelector.reSendCibilOtp ? (
										''
									) : (
										<div className='ion-no-margin pt-3 '>
											{t('Didn’t receive OTP?')}&nbsp;
											<div className='d-inline-block'>
												<Otptimer
													minutes={0}
													seconds={OTPTIMER}
													onResend={() => resendOtp()}
													text={t('Resend OTP in').toString()}
													buttonText={t('Resend OTP').toString()}
												/>
											</div>
										</div>
									)
								) : (
									<div className='ion-no-margin pt-3 '>
										{t('Didn’t receive OTP?')}&nbsp;
										<div className='d-inline-block'>
											<Otptimer
												minutes={0}
												seconds={OTPTIMER}
												onResend={() => resendOtp()}
												text={t('Resend OTP in').toString()}
												buttonText={t('Resend OTP').toString()}
											/>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</IonContent>
				{/* {leadBureauCustomerSelector?.proceed && (
					<div className='ion-padding'>
						<p className='mb-0 d-flex align-items-center'>
							{t("Don't want to generate CIBIL?")}{' '}
							<CustomButton
								title={'View Equifax'}
								fill={'clear'}
								onClick={() => {
									dispatch(
										leadBureauCustomer({
											...leadBureauCustomerSelector,
											needCibil: false,
										})
									);
									dispatch(
										leadBureauCustomer({
											...leadBureauCustomerSelector,
											needCibil: false,
										})
									);
									history.push('view-bureau-report');
								}}
							/>
						</p>
					</div>
				)} */}
				{confirmPopup && (
					<div
						className='select-product small-container bg-web mt-4'
						style={{ height: '100%', width: '100%' }}
					>
						<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
							<img
								src='/images/icons/report.svg'
								alt='report'
								className='d-block mx-auto'
							/>
							<p className='fs-18 fw-600 my-3 mb-5 text-center'>
								{t('Are you sure you want to generate CIBIL Report?')}
							</p>
							<div className='py-1 flex flex-row-reverse'>
								<IonImg
									src='/images/icons/powered_by_cbil.jpg'
									style={{ width: '75px' }}
								/>
							</div>
							<CustomButton
								title={t('Skip CIBIL')}
								className='w-100 dashboard-add-lead-button fw-bold'
								//  expand='block'
								fill='solid'
								onClick={handlePopupSkip}
							/>
							<CustomButton
								title={t('Request Consent For CIBIL')}
								className='btn-blue fw-bold'
								expand='block'
								fill='outline'
								onClick={handlePopupProceed}
							/>
							<div />
						</div>
					</div>
				)}
			</IonPage>
			{/* <CustomModal
				id='generate-cbil-popup'
				isOpen={confirmPopup}
				initialBreakpoint={isWeb ? 1 : 0.65}
				handleClose={() => setConfirmPopup(false)}
				breakpoints={[0, 0.45, 0.5, 0.75]}
				// modalHeight='750px'
				modalContent={
					<div className='mt-4'>
						<img
							src='/images/icons/report.svg'
							alt='report'
							className='d-block mx-auto'
						/>
						<p className='fs-18 fw-600 my-3 mb-5 text-center'>
							{t('Are you sure you want to generate CIBIL Report?')}
						</p>
						<div className='py-1 flex flex-row-reverse'>
							<IonImg
								src='/images/icons/powered_by_cbil.jpg'
								style={{ width: '75px' }}
							/>
						</div>
						<CustomButton
							title={t('Skip CIBIL')}
							className='w-100 dashboard-add-lead-button fw-bold'
							//  expand='block'
							fill='solid'
							onClick={handlePopupSkip}
						/>
						<CustomButton
							title={t('Request Consent For CIBIL')}
							className='btn-blue fw-bold'
							expand='block'
							fill='outline'
							onClick={handlePopupProceed}
						/>
					</div>
				}
				needCross={false}
				needArrow={false}
				className={`side-modal ${isWeb ? '' : 'modal-border-radius'}`}
			/> */}
		</>
	);
};
