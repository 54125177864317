import { IonContent, IonFooter, IonPage } from '@ionic/react';
import { Header } from '../../../components/layout/header/Header';
import { Divider } from '../../../components/common/divider/Divider';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	getsanctionedDisbursedDetails,
	leadFlow,
} from '../../../store/reducers/lead/leadSlice';
import {
	FormatDate,
	InputFormatCurrencyValue,
	setBankIcon,
} from '../../../helpers/functions';
import { useHistory, useLocation } from 'react-router';

export const SanctionAndDisbursedDetail: React.FC = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const selectedLender = useSelector(
		(state: any) => state?.lead?.selectedLender[0] || {}
	);
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	const [disbursementDetails, setDisbursementDetails] = useState<any>();
	const [timeLine, setTimeLine] = useState([]);

	useEffect(() => {
		if (
			location.pathname === '/sanction-and-disbursed-detail' &&
			Object.keys(selectedLender)?.length
		) {
			getLenderDetails();
		}
	}, [location?.pathname, selectedLender]);

	const getLenderDetails = async () => {
		await dispatch(
			getsanctionedDisbursedDetails({
				param:
					'/' +
					`${selectedLender?.lead_id}` +
					'/' +
					`${selectedLender?.lender_id}`,
			})
		)
			.then(unwrapResult)

			.then((response: any) => {
				if (response?.status) {
					setDisbursementDetails(response.data);
					setTimeLine(response?.data?.time_line || []);
				}
			});
	};
	const dmaSelector = useSelector((state: any) => state?.users);

	const handleBackButton = () => {
		const myPromiss = new Promise(function (myResolve) {
			setTimeout(function () {
				myResolve(
					leadInfo?.from === 'nimbuss'
						? history.push(
								'/lead-details/' +
									leadInfo.leadId +
									'/' +
									dmaSelector?.user_details?.access_token
						  )
						: history.push('/lead-details')
				);
			}, 10);
		});
		myPromiss.then(() => {
			dispatch(
				leadFlow({
					...leadInfo,
					showDisbursed: true,
				})
			);
		});
	};
	return (
		<IonPage>
			<Header
				name={'Disbursement Details'}
				needArrow={true}
				backButtonDisabled={false}
				backButton={true}
				needArrowOnClick={() => handleBackButton()}
				style={{ borderBottom: '1px solid #00000029' }}
			/>
			<IonContent>
				<div className='bg-web lead-lender-list-container-spacing overflowY-auto h-100'>
					<div className='bg-web-white px-md-4 py-md-3 border-md-radius-10 container-width-md'>
						<div className='mt-4 ion-padding'>
							<div className='d-flex'>
								<div
									className='border ms-2 d-flex'
									style={{
										width: '72px',
										height: '72px',
										borderRadius: '50%',
										alignSelf: 'start',
										margin: '5px',
									}}
								>
									<img
										className='selected-lender-bank-icon m-0'
										style={{
											minWidth: '48px',
											minHeight: '48px',
										}}
										src={
											disbursementDetails != undefined
												? setBankIcon(disbursementDetails?.lender_icon)
												: ''
										}
										alt='bank image'
									/>
								</div>
								<div className='ms-3'>
									<h6 className='m-0 fw-700 fs-16'>
										{disbursementDetails?.lender_name}
									</h6>
									<p className='m-0 fs-14'>
										{t('Bank App. No.')} :{' '}
										<span className='fw-700'>
											{disbursementDetails?.bank_app_no}
										</span>
									</p>
									<p className='m-0 fs-14'>
										{t('LAN')}:{' '}
										<span className='fw-700'>{disbursementDetails?.lan}</span>
									</p>
								</div>
							</div>
							<div className='mt-4 d-flex justify-content-between border py-2 px-3 blue-right-linear-gradient card-border-radius-10'>
								<div>{t('Sanctioned Amount')}</div>
								<div>
									<span className='fw-700'>
										{`₹ ${InputFormatCurrencyValue(
											disbursementDetails?.sanctioned_amount
										)}`}
									</span>
								</div>
							</div>
							<div className='mt-3 d-flex justify-content-between border py-2 px-3 blue-right-linear-gradient card-border-radius-10'>
								<div>{t('Total Amount Disbursed')}</div>
								<div>
									<span className='fw-700'>
										{`₹ ${InputFormatCurrencyValue(
											disbursementDetails?.total_amount_disbursed
										)}`}
									</span>
								</div>
							</div>
						</div>
						<Divider />
						<div className='mt-4 ion-padding'>
							<h6>{t('Timeline')}</h6>
							{timeLine.map((item: any, index: number) => (
								<Fragment key={index}>
									<div className='d-flex justify-content-between my-3'>
										<div>
											<span className='fw-700 text-capitalize'>
												{item.disbursement_type}
											</span>
											<p className='m-0 fs-12'>
												{FormatDate(item.date, 'dd mmm yyyy')}
												{}
											</p>
										</div>
										<div>
											<h5 className='fw-700 fs-16'>
												{`₹ ${InputFormatCurrencyValue(
													item.disbursement_amount || item.final_disbursement
												)}`}
											</h5>
										</div>
									</div>
									<Divider className='padding-top-border' />
								</Fragment>
							))}
						</div>
						<div className='d-none d-md-block'>
							<CustomButton
								className=' fw-bold dashboard-add-lead-button'
								expand='block'
								fill='outline'
								title={t('Go to Details')}
								onClick={() => {
									dispatch(
										leadFlow({
											...leadInfo,
											showDisbursed: true,
										})
									);
									new Promise(function (myResolve) {
										setTimeout(function () {
											myResolve(history.push('/lead-details'));
										}, 10);
									});
								}}
							/>
							{!disbursementDetails?.full_disbursement && (
								<CustomButton
									className='fw-bold dashboard-add-lead-button'
									expand='block'
									onClick={() => {
										new Promise(function (myResolve) {
											setTimeout(function () {
												myResolve(history.push('/disbursement-details-form'));
											}, 10);
										});
										dispatch(
											leadFlow({
												...leadInfo,
												isFromDisbursed: true,
												update: leadInfo?.update + 1 || 1,
											})
										);
									}}
									title={t('Add Disbursement')}
								/>
							)}
						</div>
					</div>
				</div>
			</IonContent>
			<IonFooter className='ion-padding bg-web-white d-block d-md-none'>
				<div className='d-md-flex justify-content-md-center'>
					<CustomButton
						className=' fw-bold dashboard-add-lead-button'
						expand='block'
						fill='outline'
						onClick={() => {
							dispatch(
								leadFlow({
									...leadInfo,
									showDisbursed: true,
								})
							);
							new Promise(function (myResolve) {
								setTimeout(function () {
									myResolve(history.push('/lead-details'));
								}, 10);
							});
						}}
						title={t('Go to Details')}
					/>
					{!disbursementDetails?.full_disbursement && (
						<CustomButton
							className='fw-bold dashboard-add-lead-button'
							expand='block'
							onClick={() => {
								new Promise(function (myResolve) {
									setTimeout(function () {
										myResolve(history.push('/disbursement-details-form'));
									}, 10);
								});
								dispatch(
									leadFlow({
										...leadInfo,
										isFromDisbursed: true,
										update: leadInfo?.update + 1 || 1,
									})
								);
							}}
							title={t('Add Disbursement')}
						/>
					)}
				</div>
			</IonFooter>
		</IonPage>
	);
};
