export const bankIcons: { [key: string]: string } = {
    hdfc: "/images/icons/bank/lender-hdfc-bank-icon.png",
    axis: "/images/icons/bank/lender-axis-bank-icon.png",
    bajaj: "/images/icons/bank/lender-bajaj-finance-icon.png",
    icici: "/images/icons/bank/lender-icici-bank-icon.png",
    idfc: "/images/icons/bank/lender-idfc-bank-icon.png",
    aditya: "/images/icons/bank/lender-aditya-birla-icon.png",
    baroda: "/images/icons/bank/lender-bank-of-baroda-icon.png",
    chola: "/images/icons/bank/lender-chola-icon.png",
    deutsche: "/images/icons/bank/lender-deutsche-bank-icon.png",
    fullerton: "/images/icons/bank/lender-fullerton-icon.png",
    kotak: "/images/icons/bank/lender-kotak-mahindra-bank-icon.png",
    piramal: "/images/icons/bank/lender-piramal-icon.png",
    punjab: "/images/icons/bank/lender-punjab-national-bank-icon.png",
    yes: "/images/icons/bank/lender-yes-bank-icon.png",
    tata: "/images/icons/bank/lender-tata-icon.png",
    shriram: "/images/icons/bank/lender-shriram-icon.png",
  };