// InsuranceInfoContext.tsx
import React, { createContext, useState, ReactNode } from "react";

interface InsuranceInfoContextType {
  leadStatus: string | null;
  update: number | null;
  isDisbursedFlow: boolean | null;
  applicationStatus: string | null;
  isAllLead: string | null;
  leadTypeName: string | null;
  productType: string | null;
  leadTypeId: number | null;
  isLenderSelected: boolean | null;
}

const InsuranceInfoContext = createContext<InsuranceInfoContextType>({
  leadStatus: "all",
  update: 1,
  isDisbursedFlow: false,
  applicationStatus: "all",
  isAllLead: "all",
  leadTypeName: "Loans",
  productType: "Loans",
  leadTypeId: 1,
  isLenderSelected: false,
});

export const InsuranceInfoProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [leadStatus, setLeadStatus] = useState<string | null>(null);
  const [update, setUpdate] = useState<number | null>(null);
  const [isDisbursedFlow, setIsDisbursedFlow] = useState<boolean | null>(false);
  const [applicationStatus, setApplicationStatus] = useState<string | null>(null);
  const [isAllLead, setIsAllLead] = useState<string | null>(null);
  const [leadTypeName, setLeadTypeName] = useState<string | null>(null);
  const [productType, setProductType] = useState<string | null>(null);
  const [leadTypeId, setLeadTypeId] = useState<number | null>(null);
  const [isLenderSelected, setIsLenderSelected] = useState<boolean | null>(false);

  return (
    <InsuranceInfoContext.Provider
      value={{
        leadStatus,
        update,
        isDisbursedFlow,
        applicationStatus,
        isAllLead,
        leadTypeName,
        productType,
        leadTypeId,
        isLenderSelected,
      }}
    >
      {children}
    </InsuranceInfoContext.Provider>
  );
};

export default InsuranceInfoContext;
