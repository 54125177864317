import * as yup from 'yup';
import { REGEX } from '../../../../../helpers/constant';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { CustomInput } from '../../../../../components/common/custom-input/CustomInput';
import { CustomButton } from '../../../../../components/common/custom-button/CustomButton';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { apiKycDocumentUpload } from '../../../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';

const gstEditInfoSchema = yup.object().shape({
	No: yup
		.string()
		.required('GST No. is required')
		.matches(REGEX.GST, 'GST No. Should Be 22AAAAA0000A1Z5 Format.'),
});

interface Props {
	userId: number;
	data: any;
	setUserData: any;
	setShowGstInfoForm: any;
}

export const GstEdit: React.FC<Props> = ({
	userId,
	data,
	setUserData,
	setShowGstInfoForm,
}) => {
	const dispatch: any = useDispatch();
	const { t } = useTranslation();
	const {
		handleSubmit,
		control,
		reset,
		getValues,
		formState: { errors, isValid },
	}: any = useForm<any>({
		mode: 'all',
		reValidateMode: 'onChange',
		resolver: yupResolver(gstEditInfoSchema),
		defaultValues: {
			No: '',
		},
	});

	useEffect(() => {
		reset(data);
	}, []);

	const onSubmit = async (formData: any) => {
		data.No = formData.No.toUpperCase();
		setUserData(data);
		await dispatch(
			apiKycDocumentUpload({
				param: '/' + userId,
				data: {
					Type: 'GST',
					RW: true,
					No: formData.No.toUpperCase(),
					Name: formData.Name,
				},
			})
		)
			.then(unwrapResult)
			.then((urlresult: any) => {
				if (urlresult?.status) {
					data.No = formData.No.toUpperCase();
					data.Name = formData.Name;
					setUserData(data);
					setShowGstInfoForm(false);
				}
			});
	};

	const inputFields = [
		{
			name: 'No',
			type: 'text',
			placeholder: 'GST No *',
		},
	];

	return (
		<section
			className='overflowX-hidden py-2'
			style={{ height: 'calc(100vh - 180px)' }}
		>
			<div>
				<form onSubmit={handleSubmit(onSubmit)}>
					{inputFields?.map(({ name, type, placeholder }: any) => {
						return (
							<Controller
								key={name}
								control={control}
								render={({ field: { onChange, onBlur, name: name1 } }) => (
									<CustomInput
										type={type}
										name={name1}
										placeholder={t(placeholder)}
										onBlur={onBlur}
										onChange={onChange}
										value={getValues(name1)}
										errors={t(errors[name1]?.message)}
										className='text-uppercase'
										// maxLength={10}
									/>
								)}
								name={name}
							/>
						);
					})}

					<div
						className='position-fixed w-100 bottom-0 start-0 px-3 pb-3'
						style={{ backgroundColor: '#fff' }}
					>
						<CustomButton
							className='btn-blue'
							type='submit'
							expand='block'
							title={t('Update')}
							disabled={!isValid}
						/>
					</div>
				</form>
			</div>
		</section>
	);
};
