import { useEffect, useState } from 'react';
// import { CustomSelect } from '../../components/common/custom-select/CustomSelect';
import { IonTextarea } from '@ionic/react';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { USER_ROLES } from '../../helpers/constant';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	apiReasonList,
	teamDmaRejectRevise,
} from '../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { sanitizeHtml } from '../../helpers/functions';

export const RejectionModal = (props: any) => {
	const history: any = useHistory();

	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const { setRejectionModal, title, resetPinCodeData } = props;
	const [remark, setRemark] = useState<any>('');
	const rejectionSelector = useSelector((state: any) => state?.users);
	useEffect(() => {
		getData();
	}, []);
	const getData = async () => {
		await dispatch(apiReasonList());
	};

	const rejectDma: any = async () => {
		setRejectionModal(false);
		await dispatch(
			teamDmaRejectRevise({
				param: rejectionSelector?.dmaFlow?.dmaId,
				data: {
					remark: sanitizeHtml(remark),
					status:
						rejectionSelector?.user_details?.roleType?.[0] === USER_ROLES.BUH
							? 'revised'
							: 'rejected',
				},
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setRemark(null);
					history.push('/dashboard/teams');
					resetPinCodeData();
				} else {
					setRemark(null);
				}
			});
	};
	const validationSchema = Yup.object().shape({
		remark: Yup.string().required('Comment is required'),
	});

	const formik = useFormik({
		initialValues: {
			remark: '',
		},
		validationSchema: validationSchema,
		onSubmit: () => {
			rejectDma();
		},
	});

	const validateSubmit = () => {
		if (!formik.isValid) {
			return false;
		}
		return true;
	};

	return (
		<div className='d-flex flex-column justify-content-md-between h-100'>
			<div>
				<h4 className='mt-3'>{title}</h4>

				<IonTextarea
					fill='outline'
					className='mt-3'
					maxlength={150}
					rows={3}
					name='remark'
					value={formik.values.remark}
					placeholder={`${t('Add Comment')} *`}
					onIonInput={(e: any) => {
						formik.handleChange(e);
						setRemark(e.detail.value);
					}}
					onBlur={formik.handleBlur}
					mode='md'
				/>
				{formik.touched.remark && formik.errors.remark && (
					<div className='error-text sc-ion-input-md'>
						{t(formik.errors.remark)}
					</div>
				)}
			</div>
			<div>
				<CustomButton
					onClick={() => {
						setRejectionModal(false);
					}}
					fill={'outline'}
					title={t('Cancel')}
					className={'w-100 mt-3'}
				/>
				<CustomButton
					onClick={() => {
						if (validateSubmit()) {
							formik.handleSubmit();
						}
					}}
					// fill={'outline'}
					disabled={!formik.values.remark}
					title={t('Submit')}
					className={'w-100'}
				/>
			</div>
		</div>
	);
};
