import { useContext, useEffect, useState } from 'react';
import './TermsAndConditions.scss'; // Import the SCSS file
import { GenerateOtpRequest } from '../../models/generateOtpBeans';
import useApi from '../../api/useApi';
import { INSURANCE_CONSENT_GENERATE_OTP } from '../../../../helpers/api-endpoints';
import { useConsentBusyLoading } from '../../context/ConsentBusyLoadingContext';
import { useHistory } from 'react-router-dom';
import { showToastAlert } from '../../helpers/function';
import { ConsentAuthContext } from '../../context/ConsentAuthContext';
import {
	IonPage,
	IonContent,
	IonButton,
	IonCheckbox,
	IonList,
	IonItem,
	IonText,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';


export const TermsAndConditions: React.FC = () => {
	const { t } = useTranslation();
	const { consentMobile, consentToken, consentUserId, consentApplicationId, tokenForOtp } = useContext(ConsentAuthContext);
	const [isChecked, setIsChecked] = useState(false);
	const { setBusyStateConsent } = useConsentBusyLoading();
	const history = useHistory();

	const handleCheckboxChange = () => {
		setIsChecked(!isChecked);
	};

	const { responseData, loading, error, executeRequest } = useApi<any>(
		INSURANCE_CONSENT_GENERATE_OTP
	);

	useEffect(() => {
		setBusyStateConsent(loading);
	}, [loading, setBusyStateConsent]);

	function removeBearerPrefix(token) {
		const prefix = 'Bearer%20';
		
		if (token === null) {
		  return null;
		}
		
		if (token.startsWith(prefix)) {
		  return token.slice(prefix.length);
		}
		
		return token;
	  }
	  

	const consentTokenWithoutBearer = removeBearerPrefix(consentToken);
	console.log('consentTokenWithoutBearer',consentTokenWithoutBearer)

	useEffect(() => {
		if (responseData?.status_code === 200) {
			showToastAlert({ type: 'success', message: responseData?.message });
			setTimeout(() => {
				history.push(`/otp-page/${tokenForOtp}/${consentUserId}/${consentApplicationId}/${consentMobile}`);
			}, 500);
		} else {
			showToastAlert({ type: 'error', message: error?.message });
		}
	}, [responseData, error]);

	const generateOTP = async () => {
		try {
			const generateOtpRequest = new GenerateOtpRequest();
			generateOtpRequest.mobile = consentMobile;
			const payload = generateOtpRequest;
			await executeRequest('post', payload, true, false);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<IonPage>
			<IonContent>
				<div className='select-product small-container bg-web'>
					<div className='bg-web-white container-width-md terms-container justify-content-center'>
						<IonText>
							<h1>{t('Terms and Conditions')}</h1>
						</IonText>
						<IonItem lines='none'>
							<IonCheckbox
								slot='start'
								checked={isChecked}
								onIonChange={handleCheckboxChange}
							/>
							<IonText>
								{t('I have read and agree to')}{' '}
								<a
									href='https://www.andromedaloans.com/privacy-policy-app/'
									target='_blank'
									rel='noopener noreferrer'
								>
									{t('Privacy Policy')}
								</a>{' '}
								{t('and')}{' '}
								<a
									href='https://www.andromedaloans.com/terms-of-use-app/'
									target='_blank'
									rel='noopener noreferrer'
								>
									{t('Terms of Use')}
								</a>
							</IonText>
						</IonItem>
						<IonItem>
							<IonText>
								<p>
									{t('By submitting this, I hereby authorize Andromeda to do all of the following in connection with providing me the Services:')}
								</p>
							</IonText>
						</IonItem>
						<IonList>
							<IonItem lines='none'>
								<IonText>
									<ol>
										<li>
											{t('I authorize Andromeda to receive my credit information from TransUnion CIBIL and Equifax.')}
										</li>
										<li>
											{t('I authorize Andromeda or its employees or agents to collect and share my details with its lending partners to the extent necessary or recommended for a proper and effective provision of services by Andromeda.')}
										</li>
									</ol>
								</IonText>
							</IonItem>
						</IonList>
						<IonButton
							expand='block'
							disabled={!isChecked}
							onClick={generateOTP}
						>
							{t('Generate OTP')}
						</IonButton>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};
