import { IonSelect, IonSelectOption } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import './CustomDynamicDropdown.scss';

export const DynamicDropdown: React.FC<any> = ({
	data,
	disabled,
	setValue,
	className = ' mb-3 ',
	label,
	onChange,
	onBlur,
	name,
	value,
	bindField = 'id',
	showField1 = 'value',
	showField2 = '',
	showFieldLabel1 = '',
	showFieldLabel2 = '',
	showLabelFirst = false,
}: any) => {
	const { t } = useTranslation();
	const _showDropDownValue = (item: any) => {
		let title: any = item[showField1];
		if (showLabelFirst) {
			if (showFieldLabel1 && showFieldLabel2) {
				title = `${t(showFieldLabel1)} ${item[showField1]} - ${t(
					showFieldLabel2
				)} ${item[showField2]}`;
			} else if (showFieldLabel1) {
				title = `${t(showFieldLabel1)} ${item[showField1]}`;
			}
		} else {
			if (showFieldLabel1 && showFieldLabel2) {
				title = `${item[showField1]} ${t(showFieldLabel1)} - ${
					item[showField2]
				} ${t(showFieldLabel2)}`;
			} else if (showFieldLabel1) {
				title = `${item[showField1]} ${t(showFieldLabel1)}`;
			}
		}
		return title;
	};

	return (
		<IonSelect
			disabled={disabled}
			label={t(`${label ? label : 'Select'}`).toString()}
			labelPlacement='floating'
			fill='outline'
			name={name}
			value={value}
			aria-label='Select'
			interface='popover'
			className={`dropdown_modal mt-2 ${className}`}
			onIonChange={(e) => {
				setValue && setValue(e.target.value);
				onChange(e);
			}}
			onIonBlur={onBlur}
			mode='md'
		>
			{data.map((item: any, index: number) => (
				<IonSelectOption
					key={`dropdown-modal-option-${index}`}
					value={item[bindField]}
				>
					{_showDropDownValue(item)}
				</IonSelectOption>
			))}
		</IonSelect>
	);
};
