/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { Header } from '../../components/layout/header/Header';

import { IonContent, IonPage, IonRange } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Divider } from '../../components/common/divider/Divider';

export const Tools: React.FC = () => {
	const { t } = useTranslation();
	const [amount, setAmount] = useState<any>(0);
	const [tenure, setTenure] = useState<any>(0);
	const [interest, setInterest] = useState<any>(0);
	return (
		<IonPage className='calculators'>
			<Header
				name={t('Calculators')}
				backButton={true}
				needArrow={true}
			/>
			<IonContent
				className='ion-padding'
				style={{
					borderTop: '1px solid #00000029',
					height: 'calc(100vh - 113px)',
					overflowX: 'hidden',
				}}
			>
				<div className='pt-3 px-3 ps-0 d-flex align-items-center w-100 overflowX-auto overflowY-hidden text-nowrap'>
					<div className={`tab-chip fs-14 `}>{t('EMI Calculator')}</div>
					<div className={`tab-chip fs-14 `}>{t('Eligibility Calculator')}</div>
					<div className={`tab-chip fs-14 `}>
						{t('Balance Transfer Calculator')}
					</div>
				</div>

				<div className='mt-3'>
					<div className='d-flex justify-content-between align-items-center'>
						<b>{t('Loan Amounts')}</b>
						<div>
							₹{' '}
							<p
								style={{ width: '105px' }}
								className='p-2 border d-inline-block text-end border-1 rounded-3 mb-0'
							>
								{amount}
							</p>
						</div>
					</div>
					<div>
						<IonRange
							onIonChange={(e) => {
								setAmount(e.detail.value);
							}}
							min={1}
							max={10000000}
							aria-label='Volume'
						></IonRange>
					</div>
				</div>
				<div className='mt-3'>
					<div className='d-flex justify-content-between align-items-center'>
						<b>{t('Interest Rate')}</b>
						<div>
							%{' '}
							<p
								style={{ width: '105px' }}
								className='p-2 border d-inline-block text-end border-1 rounded-3 mb-0'
							>
								{interest}
							</p>
						</div>
					</div>
					<div>
						<IonRange
							ticks={true}
							snaps={true}
							min={4}
							max={24}
							aria-label='Volume'
							onIonChange={(e) => {
								setInterest(e.detail.value);
							}}
						></IonRange>
					</div>
				</div>
				<div className='mt-3'>
					<div className='d-flex justify-content-between align-items-center'>
						<b>{t('Tenure')}</b>
						<div>
							{t('Yrs')}{' '}
							<p
								style={{ width: '105px' }}
								className='p-2 border d-inline-block text-end border-1 rounded-3 mb-0'
							>
								{tenure}
							</p>
						</div>
					</div>
					<div>
						<IonRange
							ticks={true}
							snaps={true}
							min={5}
							max={30}
							aria-label='Volume'
							onIonChange={(e) => {
								setTenure(e.detail.value);
							}}
						></IonRange>
					</div>
				</div>
				<Divider className='padding-top-border' />
				<div>
					<div>
						<p className='fs-14'>{t('Loan EMI')}</p>
						<h2 className='fs-26 fw-600'>
							₹ 12,133 <span className='fs-14 fw-400'>{t('per month')}</span>
						</h2>
					</div>
					<div>
						<p className='fs-14'>{t('Total Payable Amount')}</p>
						<h2 className='fs-26 fw-600'>₹ 14,55,931</h2>
					</div>
				</div>
				<Divider className='padding-top-border' />
				<div>
					<div className='d-flex align-items-center justify-content-between'>
						<p>{t('Principal Amount')}</p>
						<p>₹ 10,00,000</p>
					</div>
					<div className='d-flex align-items-center justify-content-between'>
						<p>{t('Interest Amount')}</p>
						<p>₹ 4,55,931</p>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};
