import {
	IonToggle,
	IonContent,
	IonPage,
	IonItem,
	IonLabel,
	IonIcon,
} from '@ionic/react';
import './Setting.scss';
import { Header } from '../../components/layout/header/Header';
import { useEffect, useState } from 'react';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
// import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useHistory } from 'react-router';

import { chevronForwardOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import {
	apiAppNotification,
	apiDeleteUser,
	// apiDeleteUser,
} from '../../store/reducers/app/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { userDeactivate } from '../../store/reducers/user/userSlice';
import { PageExitPopUp, logOut } from '../../helpers/functions';
import Theme from '../../components/theme/theme';
import { THEME_COLOR } from '../../helpers/constant';
export const Settings: React.FC = () => {
	const defaultThemeColor = '#FFFFFF';
	const [selectedColor, setSelectedColor] = useState<any>(defaultThemeColor);

	const [isOpen, setIsOpen] = useState(false);
	const [isAppNotification, setIsAppNotification] = useState<any>(false);
	const [isDeleteUser, setIsDeleteUser] = useState<any>(false);
	const [deleteModal, setDeleteModal] = useState(false);

	const history = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const navigateSettingsMenus = (path: any, nextPage: any) => {
		history.push(path, { nextPage: nextPage });
	};
	const roleType = useSelector(
		(state: any) => state?.users?.user_details?.roleType?.[0]
	);
	const updateNotificationStatus = async (isNotificationChange = 'false') => {
		await dispatch(
			apiAppNotification({
				data: {
					isChange: isNotificationChange,
					isNotificationVisible: isAppNotification ? 0 : 1,
				},
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response.status) {
					if (response.notification_status) {
						setIsAppNotification(true);
					} else {
						setIsAppNotification(false);
					}
				}
			});
	};

	{
		/* User Delete */
	}
	const handleDeleteModalClose = () => {
		setIsDeleteUser(false);
		setDeleteModal(false);
	};

	const deactivateUser = async () => {
		await dispatch(userDeactivate())
			.then(unwrapResult)
			.then(() => {
				setIsOpen(false);
				logOut(dispatch, history);
			});
	};

	useEffect(() => {
		const storedHeaderColor =
			localStorage.getItem('themeHeaderColor') || defaultThemeColor;
		setSelectedColor(storedHeaderColor);

		updateNotificationStatus('false');
	}, []);

	const updateLocalStorageThemeColors = (
		headerColor: string,
		bannerColor: string
	) => {
		localStorage.setItem('themeHeaderColor', headerColor);
		localStorage.setItem('themeBannerColor', bannerColor);
	};

	const handleColorChange = (color: keyof typeof THEME_COLOR) => {
		setSelectedColor(color);
		const headerColor: string = color;
		const bannerColor: string = THEME_COLOR[color];
		updateLocalStorageThemeColors(headerColor, bannerColor);
	};

	return (
		<IonPage className=''>
			<Header
				name={t('Settings')}
				backButton={true}
				needArrow={true}
				needArrowOnClick={() => {
					history.push('/dashboard/home');
				}}
			/>
			<IonContent className=''>
				<div className='bg-web h-100 pt-md-3'>
					<div className='bg-web-white container-width-md border-radius-2 border-md-radius-10 height-96-md d-md-flex flex-md-column justify-content-md-between pt-md-3'>
						<div>
							<IonItem
								className='pt-md-2'
								lines='full'
							>
								<IonToggle
									className='app-notification'
									checked={isAppNotification}
									onIonChange={() => updateNotificationStatus('true')}
								>
									<span className='fw-600 fs-16'>{t('App Notifictions')}</span>
									<p className='text-wrap fs-12 me-3'>
										{t(
											'Keep notifications turned on to never miss important alerts'
										)}
									</p>
								</IonToggle>
							</IonItem>
							<IonItem
								className='py-1'
								onClick={() => {
									navigateSettingsMenus('/change-language', '/settings');
								}}
								lines='full'
							>
								<IonLabel>
									<span className='fw-600 fs-16 cursor-pointer'>
										{t('Choose Language')}
									</span>
								</IonLabel>
								<IonIcon
									icon={chevronForwardOutline}
									style={{
										color: 'var(--ion-color-primary)',
									}}
								/>
							</IonItem>
							<IonItem
								className='py-1'
								lines='full'
							>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<IonLabel>
										<span className='fw-600 fs-16'>{t('Theme')}</span>
									</IonLabel>
									<div
										className='color-picker'
										style={{ paddingBottom: '12px' }}
									>
										{Object.entries(THEME_COLOR).map(([color]) => (
											<Theme
												key={color}
												color={color as keyof typeof THEME_COLOR}
												onClick={() =>
													handleColorChange(color as keyof typeof THEME_COLOR)
												}
												selected={
													selectedColor === (color as keyof typeof THEME_COLOR)
												}
											/>
										))}
									</div>
								</div>
							</IonItem>
							{roleType === 'DMA' && (
								<IonItem
									className='pt-md-2'
									lines='full'
								>
									<IonToggle
										className='app-notification'
										checked={isDeleteUser}
										onIonChange={() => {
											setIsDeleteUser(true);
											setDeleteModal(true);
										}}
									>
										<span className='fw-600 fs-16'>{t('Delete Account')}</span>
										<p className='text-wrap fs-12 me-3'>
											{t('Keep it turned off to save your data.')}
										</p>
									</IonToggle>
								</IonItem>
							)}
						</div>
					</div>
				</div>
			</IonContent>
			{/* deactivate modal */}
			<CustomModal
				id='custommodal'
				className='deactivateModal'
				isOpen={isOpen}
				modalTitle='Update Stage'
				initialBreakpoint={undefined}
				handleClose={() => setIsOpen(false)}
				breakpoints={[]}
				modalContent={PageExitPopUp(
					() => {
						setIsOpen(false);
					},
					() => {
						deactivateUser();
					},
					'Cancel',
					'Deactivate',
					'',
					'Are you sure you want to deactivate your account?',
					'Deactivation will delete your account permanently. You will lose all data!'
				)}
				needCross={false}
				needArrow={false}
			/>
			{/* Delete  User Modal*/}
			<CustomModal
				id='custommodal'
				className='deactivateModal'
				isOpen={deleteModal}
				modalTitle='Update Stage'
				initialBreakpoint={undefined}
				handleClose={handleDeleteModalClose}
				breakpoints={[]}
				modalContent={PageExitPopUp(
					() => {
						dispatch(apiDeleteUser({ param: 'delete?flag=1' }))
							.then(unwrapResult)
							.then(async (result: any) => {
								console.log(result);
								setIsDeleteUser(true);
								setDeleteModal(false);
								if (result?.status) {
									logOut(dispatch, history);
								}
							})
							.catch(() => {
								console.log('error');
							});
					},
					() => {
						setDeleteModal(false); // Close modal on "No" click
					},
					'Yes',
					'No',
					'',
					'Are you sure you want Delete your account?',
					'This will delete your account permanently. You will lose all data!'
				)}
				needCross={false}
				needArrow={false}
			/>
		</IonPage>
	);
};
