import { construct } from "ionicons/icons";
// import { FormatDate } from "../helpers/functions";
// import { dateFormat } from "dateFormat";
import { format } from "date-fns";



export class BasicDetailsForm {
  // mobileNumber: number | string,
  id: number | undefined;
  profileId: number | undefined;
  mobileNumber: string;
  panNumber: string;
  firstName: string;
  middleName: string | undefined;
  lastName: string;
  gender: string;
  // pincode: number;
  age: string|undefined;
  // dob?: Date;
  dob?: string;
  emailId: string;
  alternateNumber: string | undefined;

  constructor(basicDetailRequest: BasicDetailRequest) {
    this.id = basicDetailRequest.id;
    this.profileId = basicDetailRequest.profileId;
    this.mobileNumber = basicDetailRequest.mobile_no;
    this.panNumber = basicDetailRequest.pan;
    this.firstName = basicDetailRequest.first_name;
    this.middleName = basicDetailRequest.middle_name;
    this.lastName = basicDetailRequest.last_name;
    this.gender = basicDetailRequest.gender;
    // this.pincode= basicDetailRequest.p;
    // this.dateOfBirth =  basicDetailRequest.dob;
    // this.dob = basicDetailRequest.dob ? new Date(basicDetailRequest.dob) : undefined;

    // this.dob = basicDetailRequest.dob??'';
    // this.dob = basicDetailRequest.dob ? dateformat(basicDetailRequest.dob, "yyyy-mm-dd") : undefined;
    this.dob = basicDetailRequest.dob ;
    this.emailId = basicDetailRequest.email;
    this.alternateNumber = basicDetailRequest.altMobile;
  }
}

export class BasicDetailRequest {
  id: any;
  pan: string;
  mobile_no: string;
  first_name: string;
  middle_name?: string;
  last_name: string;
  gender: string;
  // dob?: Date;
  dob?: string;
  altMobile?: string;
  email: string;
  profileId?: number;

  constructor(basicDetailsForm: BasicDetailsForm) {
    this.id = basicDetailsForm.id;
    this.profileId = basicDetailsForm.profileId;
    this.mobile_no = basicDetailsForm.mobileNumber;
    this.pan = basicDetailsForm.panNumber;
    this.first_name = basicDetailsForm.firstName;
    this.middle_name = basicDetailsForm.middleName;
    this.last_name = basicDetailsForm.lastName;
    this.gender = basicDetailsForm.gender;
    // this.pincode= basicDetailsForm.p;
    // this.dob = format(basicDetailsForm.dob,'yyyy-MM-dd');
    // this.dob = basicDetailsForm.dob ? format(basicDetailsForm.dob, 'yyyy-MM-dd') : '';
    this.dob = basicDetailsForm.dob;
    this.email = basicDetailsForm.emailId;
    this.altMobile = basicDetailsForm.alternateNumber;
  }
}

// export function convertToApiObject(formData: BasicDetailsForm): BasicDetailsApiObject {
//   // Handle potential missing data (optional)
//   const {
//     mobileNumber = "",
//     panNumber = "",
//     loanApplicationNumber = "",
//     firstName = "",
//     middleName = "",
//     lastName = "",
//     gender = "",
//     pincode = "",
//     dateOfBirth = "",
//     emailId = "",
//     alternate_mobile = "",
//     policyProposedFor = "",
//   } = formData;

//   // Map form data to API object format, handling data type differences
//   return {
//     id: null, // Likely set elsewhere based on API logic
//     leadId: 0, // Might need adjustment based on your API
//     pan: panNumber,
//     mobile_no: typeof mobileNumber === "string" ? mobileNumber : "", // Ensure string for mobile_no
//     first_name: firstName,
//     middle_name: middleName,
//     last_name: lastName,
//     gender: gender,
//     dob: dateOfBirth,
//     altMobile: typeof alternate_mobile === "string" ? alternate_mobile : "", // Ensure string for altMobile
//     email: emailId,
//     profileId: 0, // Might need adjustment based on your API
//   };
// }


