import { createAction, createSlice } from '@reduxjs/toolkit';
import {
	FILTER_DMA_REL_DATA,
	FILTER_DMA_TYPE_DATA,
	GET_CITIES,
	REPORTING_MANAGER_LIST,
	TEAM_DMA_LIST,
} from '../../helpers/api-endpoints';
import { get } from '../../helpers/constant';
import { apiAction } from '../apiAction';

const initialState = {
	defaultPageSize: 10, // do not change this
	defaultPageId: 1, // do not change this
	pageSize: 10,
	pageId: 1,
	searchTerm: '',
	dmaType: null,
	dmaRel: null,
	dmaRm: null,
	dmaCity: null,
	dmafrom: '',
	dmato: '',
	payoutProduct: '',
};

export const getdmaList = apiAction('api/dma-list', get, TEAM_DMA_LIST);
export const filterDmaTypeData = apiAction(
	'api/filter-data/dmatype',
	get,
	FILTER_DMA_TYPE_DATA
);
export const filterDmaRelData = apiAction(
	'api/filter-data/dmarel',
	get,
	FILTER_DMA_REL_DATA
);
export const filterReportingManagerList = apiAction(
	'api/filter-data/rm-list',
	get,
	REPORTING_MANAGER_LIST
);
export const filterCities = apiAction(
	'api/filter-data/cities',
	get,
	GET_CITIES
);
export const resetDmaState = createAction('Reset_all');
export const resetDmaFilterState = createAction('reset_dma_filter');

const dmaSlice = createSlice({
	name: 'dma',
	initialState: initialState,
	reducers: {
		paginationSize: (state: any, action) => {
			state.pageSize = action.payload.pageSize;
			state.pageId = action.payload.pageId;
			state.searchTerm = action.payload.searchTerm;
		},
		dmaFilter: (state: any, action) => {
			state.dmaType = action.payload.dmaType;
			state.dmaRel = action.payload.dmaRel;
			state.dmaCity = action.payload.dmaCity;
			state.dmaRm = action.payload.dmaRm;
			state.dmafrom = action.payload.dmafrom;
			state.dmato = action.payload.dmato;
			state.payoutto = action.payload.payoutto;
			state.payoutfrom = action.payload.payoutfrom;
			state.payoutProduct = action.payload.payoutProduct;
		},
		manageTabSegment: (state: any, action) => {
			state.moduleTabsSegment = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(filterCities.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(filterCities.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(filterCities.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(getdmaList.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getdmaList.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.dmaList = action?.payload?.list;
			})
			.addCase(getdmaList.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(filterReportingManagerList.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(filterReportingManagerList.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(filterReportingManagerList.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(filterDmaTypeData.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(filterDmaTypeData.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(filterDmaTypeData.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(filterDmaRelData.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(filterDmaRelData.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(filterDmaRelData.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(resetDmaState, () => initialState)
			.addCase(resetDmaFilterState, () => initialState);
	},
});

export const { paginationSize, dmaFilter, manageTabSegment } = dmaSlice.actions;

export default dmaSlice.reducer;
