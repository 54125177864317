import { Context, useContext, useEffect } from "react";
import { INSURANCE_STATUS, REDIRECT } from "./constant";
import { useHistory } from "react-router-dom";
import { INSURANCE_FETCH_APPLICATION_DETAILS } from "./api-endpoints";

import { App } from '@capacitor/app';


export function openAppDetails(
    applicationId: number,
    statusId: number,
    userContext: any,
    history:any,

): void {

    // const userContext = useContext(AuthContext);
    // const history = useHistory();
    
    // const {
    //     setAppBean,
    //     applicationBean
    // } = useContext(ApplicationContext);

    
    console.log("hbs status id", statusId, INSURANCE_STATUS.ALLOCATED_TO_DMA);


    if (userContext.isIFA) {
        switch (statusId) {
            case INSURANCE_STATUS.DRAFT:
            case INSURANCE_STATUS.RESENT_TO_IFA:
                history.push('/ifainsurance');
                break;
            case INSURANCE_STATUS.INSURER_SELECTION:
                history.push('/insurerlist');
                break;

            case INSURANCE_STATUS.AUTHORIZATION_PENDING:
                history.push("/shareOtp");
                break;    

            case INSURANCE_STATUS.APPLICATION_FULFILLMENT:
                history.push('/docuploadMain');
                break;
            case INSURANCE_STATUS.ALLOCATED_TO_DMA:
                history.push('/cust-ins-message', {
                    redirectTo: REDIRECT.DASHBOARD,
                    message: `Insurer Selected and Allocated to DMA-Coordinator. Application Id is ${applicationId}`
                })
                break;
            default:
                history.push(REDIRECT.DASHBOARD);
                break;

        }
    }
    else if (userContext.isDMACoord) {

        // history.push('/dmapproval');


        switch (statusId) {
            case INSURANCE_STATUS.ALLOCATED_TO_DMA:
                history.push('/dmapproval');
                break;
            default:
                history.push(REDIRECT.DASHBOARD);
                break;
        }
    }
    else {
        switch (statusId) {
            default:
                history.push(REDIRECT.DASHBOARD);
                break;  
        }
    }



    // return REDIRECT.DASHBOARD;
}

export const useBackButtonHandler = () => {
    useEffect(() => {
      const setupBackButtonHandler = async () => {
        const handler = await App.addListener('backButton', (event) => {
          // event.preventDefault(); // Prevent default back button operation
          console.log('Back button is pressed'); // Optionally perform other actions
        });
  
        return () => {
          handler.remove();
        };
      };
  
      const cleanup = setupBackButtonHandler();
  
      return () => {
        cleanup.then((removeHandler) => removeHandler());
      };
    }, []);
  };

// export const useBackButtonHandler = () => {
//     useEffect(() => {
//       const handler = App.addListener('backButton', (event) => {
//         // event.preventDefault(); // Prevent default back button operation
//         // event.preventDefault(); // Prevent default back button operation
//         console.log('Back button is pressed'); // Optionally perform other actions
//       });
  
//       return () => {
//         handler.remove();
//       };
//     }, []);
//   };