import { FormatDate } from "../helpers/functions";

export class ExistingPolicyDetailsForm  {
  id?: number;
  vehicleDetails?:string;
  carType?:string;
  carMakeModel?:string;
  sumAssured?: number;
  registrationNo?:string;
  existingPolicyNumber?:string;
  insurerName?: string;
  planName?: string;
  // issueDate?: Date;
  issueDate?: string;
  policyStatus?: string;
  goodHealth?: string;
  healthDetails?: string;
  policyNo?: string;
  application_id: any;
  profileId?: number;

  constructor(request: any) {
    this.id = request.id;
    this.vehicleDetails = request.vehicleDetails;
    this.carType= request.carType;
    this.carMakeModel= request.carMakeModel;
    this.registrationNo = request.registrationNo;
    this.sumAssured = request.sumAssured ? parseInt(request.sumAssured) : undefined;
    this.existingPolicyNumber = request.existingPolicyNumber;
    this.insurerName = request.insurerName;
    this.planName = request.planName;
    this.issueDate = (request.issueDate!==null&&request.issueDate!=='')?FormatDate(request.issueDate,'yyyy-mm-dd'):undefined;
    this.policyStatus = request.policyStatus;
    this.goodHealth = request.goodHealth;
    this.healthDetails = request.healthDetails;
    this.policyNo = request.policyNo;
    this.application_id = request.application_id;
    this.profileId = request.profileId;
  }

};


export class ExistingPolicyDetailsRequest {
  id?: number;
  vehicleDetails?:string;
  carType?:string;
  carMakeModel?:string;
  registrationNo?:string;
  sumAssured?: string;
  existingPolicyNumber?: string;
  insurerName?: string;
  planName?: string;
  issueDate?: string;
  policyStatus?: string;
  goodHealth?: string;
  healthDetails?: string;
  policyNo?: string;
  application_id: any;
  profileId?: number;

  // constructor(form: ExistingPolicyDetailsForm) {
    constructor(form: any) {  
    this.id = form.id;
    this.vehicleDetails = form.vehicleDetails;
    this.carType= form.carType;
    this.carMakeModel= form.carMakeModel;
    this.registrationNo = form.registrationNo;
    this.sumAssured = form.sumAssured?.toString();
    this.existingPolicyNumber = form.existingPolicyNumber;
    this.insurerName = form.insurerName;
    this.planName = form.planName;
    // this.issueDate = form.issueDate instanceof Date ? FormatDate(form.issueDate,'yyyy-mm-dd') : form.issueDate;
    this.issueDate = form.issueDate;
    this.policyStatus = form.policyStatus;
    this.goodHealth = form.goodHealth;
    this.healthDetails = form.healthDetails;
    this.policyNo = form.policyNo;
    this.application_id = form.application_id;
    this.profileId = form.profileId;
  }
}
