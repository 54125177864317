export type GenerateOtpData ={
    mobile: string | null;
  }

export  interface GenerateOtpResponse {
  otp: string,
  mobile: string | null,
  message : string
}


export class GenerateOtpDetails {
  mobile: string | null;
  constructor() {
    this.mobile = null;
  }

}

export class GenerateOtpRequest {
  mobile:  string | null;
  constructor() {
    this.mobile = null;
  }
}