import { IonCol, IonGrid, IonImg, IonRow } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { showInAppBrowserView } from '../../../../helpers/functions';

interface IMyToolsDataKey {
	description: string;
	photo_url: string;
	id: number;
	name: string;
	status: boolean;
	label?: string;
	url?: string;
}
interface IMyTools {
	data: IMyToolsDataKey[];
}

export const MyTools: React.FC<IMyTools> = ({ data }) => {
	const { t } = useTranslation();
	return (
		<section className='flex-50 p-4 py-md-3 ms-lg-3 ms-md-0 mt-lg-0 mt-md-3 bg-web-white border-md-radius-10'>
			<h3 className='m-0 fs-16 pb-3 fw-600 darkest-grey'>{t('My Tools')}</h3>
			<IonGrid className='ion-no-padding'>
				<IonRow>
					{data &&
						data.map((item: IMyToolsDataKey, index: number) => {
							return (
								<IonCol
									onClick={() =>
										item.url ? showInAppBrowserView(item.url) : null
									}
									key={index}
									size='6'
									className={`${
										index === 0 && 'ion-padding-end'
									} d-flex position-relative mb-2 ${
										item.url ? ' cursor-pointer ' : ''
									}`}
								>
									{item.label && (
										<div
											className='position-absolute d-flex justify-content-center w-100'
											style={{ top: '-5px' }}
										>
											<span className='comming-soon-label text-white px-1 fs-8 w-800 text-capitalize'>
												{t(item.label)}
											</span>
										</div>
									)}
									<div className='tool-box d-flex flex-column w-100'>
										<IonImg
											src={item.photo_url}
											style={{ width: '38px', height: '38px' }}
											className='mb-3'
										/>
										<h2 className='m-0 fs-14 fw-600 pb-2 darkest-grey'>
											{t(item.name)}
										</h2>
										<p className='m-0 text-grey fs-12'>{t(item.description)}</p>
									</div>
								</IonCol>
							);
						})}
				</IonRow>
			</IonGrid>
		</section>
	);
};
