//// @ts-nocheck


import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonImg,
  IonLabel,
  IonPage,
  IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import useApi from "../../api/useApi";
import { CustomButton } from "../../components/common/custom-button/CustomButton";
import { CustomSearchbar } from "../../components/common/custom-searchbar/CustomSearchbar";
import { Header } from "../../components/layout/header/Header";
import { useBusyLoading } from "../../context/BusyLoadingContext";
import { INSURANCE_LENDER_LIST } from "../../helpers/api-endpoints";
import { bankIcons } from "../../helpers/bankIcons";
import { showToastAlert } from "../../helpers/functions";
import ApplicationContext from "../../context/ApplicationContext";



export const AllInsurers: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  // const extractedData = lendersData[0].extractedData;

  // var extractedData = [];


  // const [fetchLendersData, setFetchLendersData] = useState(extractedData);

  const [fetchLendersDataOrg, setFetchLendersDataOrg] = useState([]);

  const [fetchLendersData, setFetchLendersData]: any[] = useState([]);

  const [searchText, setSearchText] = useState("");
  const { applicationBean } = useContext(ApplicationContext);




  const { responseData, loading, executeRequest, error } =
    useApi<any>(`${INSURANCE_LENDER_LIST}`);


  const { setBusyState } = useBusyLoading();





  useEffect(() => {
    setBusyState(loading);
  }, [loading]);


  useEffect(() => {
    if (applicationBean) {
      const payload = {
        applicationId: applicationBean.applicationId
      }
      executeRequest("post", payload);
    }
  }, []);

  useEffect(() => {
    console.log(responseData);
    if (responseData?.status) {
      setFetchLendersDataOrg(responseData?.data);
      setFetchLendersData(responseData?.data);
    }
  }, [responseData]);

  useEffect(() => {
    console.log(fetchLendersData);
  }, [fetchLendersData]);

  useEffect(() => {
    showToastAlert({ type: "error", message: error?.message! });
  }, [error]);




  useEffect(() => {
    if (searchText && searchText !== "") {
      const filteredData = fetchLendersData.filter((item: any) => {
        return Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText.toLowerCase())
        );
      });
      setFetchLendersData(filteredData);
    } else {
      // setFetchLendersData(extractedData);
      setFetchLendersData(fetchLendersDataOrg);
    }
  }, [searchText]);

  const setBankIcon = (bankName: string) => {
    const bN = bankName.toLowerCase();
    for (const key of Object.keys(bankIcons)) {
      if (bN.includes(key)) {
        return bankIcons[key];
      }
    }
    return "/images/icons/leads/bank_icon_placeholder.svg";
  };

  const cardClick = async (event: any) => {
    const storeResult = fetchLendersData.map((activeData: any) => {
      if (event.target.id === activeData.id.toString()) {
        return {
          ...activeData,
          checked: !activeData.checked,
          is_insert: "true",
        };
      } else {
        return {
          checked:
            activeData.checked === undefined ? false : activeData.checked,
          ...activeData,
        };
      }
    });

    setFetchLendersData(storeResult);
  };
  const selectedBankCount = fetchLendersData?.filter(
    ({ checked }: any) => checked
  ).length;

  const onProccedClick = () => {

    console.log(fetchLendersData);

    history.push({
      pathname: "/insuranceSelectedList",
      state: { selectedLendersdata: fetchLendersData },
    });
  };

  return (
    <IonPage>
      <Header
        className="card-header"
        name={t("All Insurers")}
        backButton={false}
        needArrow={true}
        endItem={
          <>
            <IonButtons
              slot='end'
              style={{
                padding: '20px',
              }}
            >
              <IonImg
                className='cursor-pointer'
                src='/images/icons/assign-icon.svg'
                alt='assign'
              />
            </IonButtons>
          </>
        }
      />
      <IonContent>
        <div className=" bg-web lead-lender-list-container-spacing overflowY-auto h-100">
          <div className="bg-web-white px-3 py-md-3 border-md-radius-10">
            <div className="fs-16 fw-600 darkest-grey mx-3 my-3">
              {t("Choose from the insurers given below")}
            </div>
            <div className="position-relative mx-3 mt-3 d-flex justify-content-between">
              <CustomSearchbar
                searchIcon="/images/icons/searchbar-icon.svg"
                placeholder={t("Search by Insurers")}
                showClearButton="focus"
                value={searchText}
                onChange={(value: any) => {
                  setSearchText(value);
                }}
              />
            </div>
            {fetchLendersData.length > 0 ? (
              <IonGrid>
                <IonRow>
                  {fetchLendersData.map((item: any, index: any) => (
                    <IonCol
                      size="6"
                      size-md="6"
                      size-lg="3"
                      size-xl="3"
                      key={index}
                    >
                      <IonCard
                        onClick={cardClick}
                        className={`h-100 m-0 cursor-pointer blue-corner-linerar-gradient ${item?.status === "online" ? "online" : ""
                          }`}
                      >
                        <IonCardContent className="d-flex flex-column justify-content-between align-items-center"
                          id={item.id}>
                          <div className="d-flex w-100 justify-content-between align-items-center">
                            <div className="d-flex">
                              <IonCheckbox
                                name={item.name}
                                value={item.id}
                                checked={item.checked}
                                style={{ zIndex: -11111 }}
                                mode="md"
                              />
                            </div>
                            <div style={{ minHeight: "20px" }}>
                              {item?.recommended === true && (
                                <IonImg
                                  alt="img"
                                  className="bankimg m-0"
                                  src={`/images/icons/leads/${item?.checked === true
                                    ? "crown-marked"
                                    : "crown"
                                    }.svg`}
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <div className="selected-lender-bank-icon-container">
                            <div className="selected-lender-bank-icon-border p-0">
                              <IonImg
                                alt="img"
                                className="bankimg selected-lender-bank-icon m-0"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                }}
                                src={setBankIcon(item?.name)}
                              />
                            </div>
                          </div>
                          <IonLabel className="fw-900 my-2 text-center">
                            {item?.name}
                          </IonLabel>
                          {/* <IonLabel
                          className="mb-2 mt-0"
                          style={{ color: "var(--text-dark-grey)" }}
                        >
                          {item?.roi ? `${item?.roi}% p.a` : ""}
                        </IonLabel> */}
                          <span
                            className={`fs-12 py-1 px-2 selected-lender-status-${item?.status?.toLowerCase() === "online"
                              ? "online"
                              : "offline"
                              }`}
                          >
                            {item?.status?.toLowerCase() === "online"
                              ? "online"
                              : "offline"}
                          </span>
                        </IonCardContent>
                        <div
                          id={item.id}
                          style={{
                            background: "transparent",
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            top: 0,
                            zIndex: 111111,
                          }}
                        //onClick={cardClick}
                        />
                      </IonCard>
                    </IonCol>
                  ))}
                </IonRow>
              </IonGrid>
            ) : (
              <div
                className="d-flex justify-content-center"
                style={{ height: "80vh" }}
              ></div>
            )}
          </div>
        </div>
      </IonContent>
      <IonFooter>
        <div className="lead-lender-list-container-spacing ion-padding bg-white">
          <div className=" px-3 d-flex align-items-center justify-content-between justify-content-md-end flex-row">
            {fetchLendersData?.length > 0 && (
              <>
                <div className="fw-900 me-md-4">
                  {selectedBankCount} {t("Selected")}
                </div>
                <CustomButton
                  style={{ minWidth: "150px" }}
                  className="fw-bold dashboard-add-lead-button"
                  expand="block"
                  title={t("Proceed")}
                  disabled={selectedBankCount === 0}
                  onClick={onProccedClick}
                />
              </>
            )}
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

