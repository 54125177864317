import { IonPage } from '@ionic/react';
//import React, { useEffect, useState } from 'react';
import { TermsAndConditions } from '../../components/common/TermsAndConditions';
import { useTranslation } from 'react-i18next';
// import { PARTNER_REGISTRATION } from '../../helpers/api-endpoints';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	dmaRegister,
	kycStep,
	selfDMARegister,
	//termsAndConditions,
	useDetails,
} from '../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';

export const TermsPage: React.FC = () => {
	const dmaSelector = useSelector((state: any) => state?.users);
	const histroy = useHistory();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	//const [termsandconditions, setTermsandconditions] = useState('');
	const userData: any = useSelector((state: any) => state);

	/*useEffect(() => {
		getTermsData();
	}, []);*/

	const handleClik = async () => {
		const body = userData?.users?.registerUser;
		const setObj = Object.keys(body);
		const obj: any = {};
		for (const x of setObj) {
			if (body[x]) {
				obj[x] = body[x];
			}
		}

		// not need id in user Registration api

		let id;
		if (dmaSelector?.dmaFlow?.dmaId) {
			obj.mobile = dmaSelector?.dmaFlow?.dmaMobile;
			obj.continueLater = false;
			id = dmaSelector?.dmaFlow?.dmaId;
		} else {
			obj.continueLater = false;
			id = dmaSelector?.user_details?.id;
		}
		await dispatch(dmaRegister({ param: '/' + id, data: obj }))
			.then(unwrapResult)
			.then(async (response: any) => {
				const registerData = {
					...userData?.users?.registerUser,
					finalSoftcode: response?.soft_code,
				};
				await dispatch(selfDMARegister(registerData));
				await dispatch(
					useDetails({ ...userData?.users?.user_details, step: 'dashboard' })
				);
				if (response?.status) {
					await dispatch(kycStep('KYC1'));
					histroy.replace('/partner-soft-code');
				}
			});
	};

	/*const getTermsData = () => {
		dispatch(termsAndConditions())
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setTermsandconditions(response?.Content);
				}
			});
	};*/
	return (
		<IonPage className=''>
			<TermsAndConditions
				needFooter={userData?.users?.needFooter}
				headerTitle={t('Terms & Conditions')}
				termsPara={'https://www.andromedaloans.com/terms-of-use-app/'}
				afterAcceptRedirect=''
				onClick={() => handleClik()}
			/>
		</IonPage>
	);
};
