import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonCard, IonCardContent } from '@ionic/react';
import './alertsMain.scss';
import { Header } from '../../components/layout/header/Header';
import { t } from 'i18next';
import { useHistory } from 'react-router';

const AlertsMain: React.FC = () => {
    const history = useHistory<any>();


    const items = [
        { title: "Home Loan Balance Transfer", icon: "HLBT.png", path: "/showAlerts" },
        { title: "Personal Loan Balance Transfer", icon: "PLBT.png", path: "/showAlerts" },
        { title: "Home Loan Top-Up", icon: "HLTopUp.png", path: "/showAlerts" },
        { title: "Business Loan Top-Up", icon: "BLTopUp.png", path: "/showAlerts" },
        { title: "Loan Against Property", icon: "LAP.png", path: "/showAlerts" },
        { title: "Credit Card", icon: "CreditCard.png", path: "/showAlerts" },
        { title: "Auto Insurance", icon: "AutoInsurance.png", path: "/showAlerts" },
        { title: "Life Insurance", icon: "LifeInsurance.png", path: "/showAlerts" },
    ];

    return (
        // <IonPage>

        <IonPage className="bg-web-white container-width-md container-spacing-md border-md-radius-top">
            <Header
                name={t('Insights')}
                backButton={true}
                needArrow={true}
                needArrowOnClick={() => {
                    history.goBack();
                }}
                className={''}
            />
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow>
                        {items.map((item, index) => (
                            // <IonCol size="6" size-sm="4" size-md="3" size-lg="2" key={index}>
                            <IonCol size="6" size-sm="6" size-md="6" size-lg="6" key={index}>
                                <IonCard routerLink={item.path} className="insight-card">
                                    <IonCardContent className="ion-text-center">
                                        <img src={`images/alerts/${item.icon}`} alt={item.title} className="insight-icon" />
                                        <p className="insight-title">{item.title}</p>
                                        <div className="insight-badge">10</div>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        ))}
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default AlertsMain;
