import { IonContent, IonFooter, IonPage } from '@ionic/react';
import { useEffect, useState } from 'react';
import { Header } from '../../components/layout/header/Header';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
// import { CustomSelect } from '../../components/common/custom-select/CustomSelect';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useHistory } from 'react-router';
import { REGEX, StatusNum } from '../../helpers/constant';
import DropDownModal from '../../components/common/custom-dropdown/DropDownModal';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	customSuccessfull,
	dmaFlowData,
	getAllRoles,
	getNimbusIp,
	inviteAndroEmployee,
} from '../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import CustomRadioButton from '../../components/common/custom-radio-button/CustomRadioButton';
import { disableNumberArrows } from '../../helpers/functions';

const InviteEmployeFormSchema = yup.object().shape({
	ECode: yup.string().trim().required('Employee ID is Required.'),
	EmployeeName: yup
		.string()
		.trim()
		.required('Employee First Name is Required.')
		.matches(REGEX.FULLNAME_WITH_DOT, 'Please Enter Valid First Name.'),
	LastName: yup
		.string()
		.trim()
		// .required('Employee Last Name is Required.')
		.matches(REGEX.FULLNAME, 'Please Enter Valid Last Name.'),
	Mobile: yup
		.string()
		.trim()
		.required('Mobile is Required.')
		.matches(REGEX.INDIAN_MOBILE_NUMBERS, 'Please Enter Valid Mobile Number.'),
	EMail: yup
		.string()
		.trim()
		.required('Email is Required.')
		.matches(REGEX.EMAIL, 'Please Enter Valid Email.'),
	Designation: yup.number().required('Designation is Required.'),
	allow_dialler: yup.boolean().required('Please choose one of this options.'),
});

const empFormFields = [
	{
		name: 'ECode',
		type: 'text',
		placeholder: 'Employee ID',
	},
	{
		name: 'EmployeeName',
		type: 'text',
		placeholder: 'Employee First Name',
	},
	{
		name: 'LastName',
		type: 'text',
		placeholder: 'Employee Last Name',
	},
	{
		name: 'Mobile',
		type: 'text',
		placeholder: 'Mobile',
	},
	{
		name: 'EMail',
		type: 'text',
		placeholder: 'Email',
	},
	{
		name: 'Designation',
		type: 'dropdown',
		placeholder: 'Designation *',
	},
	{
		name: 'allow_dialler',
		type: 'radio',
		placeholder: 'Allow Nimbus *',
		fields: [
			{
				name: 'dialler_id',
				type: 'number',
				placeholder: 'Nimbus UserId *',
			},
			{
				name: 'server_ip',
				type: 'dropdown',
				placeholder: 'Nimbus IP *',
			},
		],
	},
];
export const InviteEmployeForm = () => {
	const [formValidationsSchema, setFormValidationsSchema] = useState(
		InviteEmployeFormSchema
	);
	const history = useHistory();
	const { t } = useTranslation();
	const empSelector = useSelector((state: any) => state?.users?.empFlow);
	const dmaSelector = useSelector((state: any) => state?.users?.dmaFlow);
	const [dropDownRoles, setDropDownRoles] = useState([]);
	const [nimbusIpDropDown, setNimbusIpDropDown] = useState([]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [updateRadioChange, setUpdateRadioChange] = useState(false);
	const dispatch = useDispatch();

	const {
		handleSubmit,
		control,
		getValues,
		trigger,
		reset,
		setValue,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(formValidationsSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			ECode: empSelector?.ECode || '',
			EmployeeName: empSelector?.EmployeeName || '',
			LastName: empSelector?.LastName || '',
			PermanentNo: empSelector?.PermanentNo,
			Mobile: empSelector?.Mobile || '',
			EMail: empSelector?.EMail || '',
			Designation: null,
			allow_dialler: false,
			dialler_id: null,
			server_ip: '',
		},
	});

	useEffect(() => {
		reset(empSelector);
		getRoles();
	}, []);

	const getRoles = async () => {
		await dispatch(getAllRoles())
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setDropDownRoles(response?.data);
					if (response?.data) {
						// trigger();
					}
				}
			});
	};

	const getNimbusIpDropdown = async () => {
		await dispatch(getNimbusIp())
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setNimbusIpDropDown(response?.data);
					if (response?.data) {
						// trigger();
					}
				}
			});
	};

	const onSubmit = async (data: any) => {
		data['MiddleName'] = '';
		if (data['dialler_id']) {
			data['dialler_id'] = Number(data['dialler_id']);
		}
		await dispatch(inviteAndroEmployee({ data: data }))
			.then(unwrapResult)
			.then(async (response: any) => {
				if (response?.status) {
					await dispatch(
						dmaFlowData({
							...dmaSelector,
							dmaCode: StatusNum.INVITED,
							title: t('Invitees'),
							update: dmaSelector?.update + 1 || 1,
							isAndro: true,
						})
					);
					history.replace('/custom-successfull', {
						type: 'DMAINVITESUCCESS',
						message: `${t('Invitation Sent Successfully')} !`,
						redirectTo: '/invitee-list',
					});

					await dispatch(
						customSuccessfull({
							type: 'DMAINVITESUCCESS',
							message: `${t('Invitation Sent Successfully')} !`,
							redirectTo: '/invitee-list',
						})
					);
				}
			});
	};
	return (
		<IonPage>
			<Header
				name=''
				backButton={true}
				needArrow={true}
			/>

			<IonContent className=''>
				<div
					className='bg-web py-md-3'
					style={{ minHeight: '100%' }}
				>
					<div className='conatiner-spacing-md py-md-4 bg-web-white container-width-md border-radius-10'>
						<form
							onSubmit={handleSubmit(onSubmit)}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									handleSubmit(onSubmit);
								}
							}}
						>
							<div className='pt-2 pb-2 mt-2'>
								{empFormFields &&
									empFormFields.map(
										({ name, type, placeholder, fields }: any, index: any) => {
											const errorText: any =
												errors && Object.keys(errors).length > 0 && errors;
											if (type === 'text') {
												return (
													<Controller
														key={index}
														control={control}
														render={({ field: { onChange, onBlur } }) => (
															<CustomInput
																disabled={
																	empSelector?.[name] && name !== 'LastName'
																		? true
																		: false
																}
																placeholder={t(placeholder)}
																onBlur={onBlur}
																name={name}
																value={getValues(name)}
																onChange={onChange}
																errors={t(errorText?.[name]?.message as string)}
																onBeforeInput={(evt: any) => {
																	if (type == 'number') {
																		!/[0-9]/.test(evt.data) &&
																			evt.preventDefault();
																	}
																}}
																onKeyDown={(e: any) => {
																	if (type == 'number') {
																		disableNumberArrows(e);
																	}
																}}
																onWheel={(e: any) => {
																	if (type == 'number') {
																		e.target.blur();
																	}
																}}
															/>
														)}
														name={name}
													/>
												);
											} else if (type === 'radio') {
												return (
													<>
														<Controller
															key={index}
															control={control}
															render={({ field: { onChange, name } }) => (
																<div className='mb-2'>
																	<h6 className='mt-3'>{t(placeholder)}</h6>
																	<CustomRadioButton
																		type='group'
																		value={getValues(name)}
																		onIonChange={(e: any) => {
																			onChange(e);
																			setUpdateRadioChange(e.detail.value);
																			if (e.detail.value) {
																				getNimbusIpDropdown();
																				const newSchema: any =
																					InviteEmployeFormSchema.concat(
																						yup.object().shape({
																							...InviteEmployeFormSchema.fields,
																							dialler_id: yup
																								.number()
																								.typeError(
																									'Nimbus userid required'
																								)
																								.required(
																									'Nimbus userid required'
																								),
																							server_ip: yup
																								.string()
																								.min(
																									1,
																									'Please select nimbus ip'
																								)
																								.required(
																									'Nimbus Ip is required.'
																								),
																						})
																					);
																				setFormValidationsSchema(newSchema);
																			} else if (e.detail.value === false) {
																				setValue('server_ip', '');
																				setValue('dialler_id', null);
																				setFormValidationsSchema(
																					InviteEmployeFormSchema
																				);
																			}
																			setTimeout(() => {
																				trigger();
																			}, 100);
																		}}
																		className='mb-2'
																		ionRadioClassName='me-4'
																		labelPlacement='end'
																		data={[
																			{
																				label: t('Yes'),
																				value: true,
																			},
																			{
																				label: t('No'),
																				value: false,
																			},
																		]}
																	/>
																	<div className='error-text px-3 sc-ion-input-md'>
																		{t(errorText[name]?.message)}
																	</div>
																</div>
															)}
															name={name}
														/>
														{fields &&
															getValues(name) &&
															fields.map((dynamicField: any, index: any) => {
																if (
																	dynamicField.type === 'text' ||
																	dynamicField.type === 'number'
																) {
																	return (
																		<Controller
																			key={`dynamic-form-fields-` + index}
																			control={control}
																			render={({
																				field: { onChange, onBlur },
																			}) => (
																				<CustomInput
																					disabled={
																						empSelector?.[dynamicField.name] &&
																						dynamicField.name !== 'LastName'
																							? true
																							: false
																					}
																					placeholder={t(
																						dynamicField.placeholder
																					)}
																					onBlur={onBlur}
																					name={dynamicField.name}
																					value={getValues(dynamicField.name)}
																					onChange={onChange}
																					errors={t(
																						errorText?.[dynamicField.name]
																							?.message
																					)}
																					onBeforeInput={(evt: any) => {
																						if (dynamicField.type == 'number') {
																							!/[0-9]/.test(evt.data) &&
																								evt.preventDefault();
																						}
																					}}
																					onKeyDown={(e: any) => {
																						if (dynamicField.type == 'number') {
																							disableNumberArrows(e);
																						}
																					}}
																					onWheel={(e: any) => {
																						if (dynamicField.type == 'number') {
																							e.target.blur();
																						}
																					}}
																				/>
																			)}
																			name={dynamicField.name}
																		/>
																	);
																} else if (dynamicField.type === 'dropdown') {
																	return (
																		<>
																			<Controller
																				key={`dynamic-form-fields-` + index}
																				control={control}
																				render={({
																					field: { onChange, onBlur },
																				}) => (
																					<DropDownModal
																						data={nimbusIpDropDown}
																						label={t(dynamicField.placeholder)}
																						name={dynamicField.name}
																						onChange={onChange}
																						onBlur={onBlur}
																						value={getValues(dynamicField.name)}
																						errors={
																							errorText?.[dynamicField.name]
																								?.message
																						}
																					/>
																				)}
																				name={dynamicField.name}
																			/>
																		</>
																	);
																}
															})}
													</>
												);
											} else if (type === 'dropdown') {
												return (
													<>
														<Controller
															key={index}
															control={control}
															render={({
																field: { onChange, onBlur, name },
															}) => (
																<DropDownModal
																	className={
																		errors?.Designation &&
																		'text-danger dropdown-error'
																	}
																	data={dropDownRoles}
																	label={t(placeholder)}
																	name={name}
																	onChange={(e: any) => {
																		onChange();
																		setValue('Designation', e.detail.value);
																		trigger('Designation');
																	}}
																	onBlur={onBlur}
																	value={getValues(name)}
																	errors={errorText?.[name]?.message}
																/>
															)}
															name={name}
														/>
														{errors?.Designation && (
															<div className='input-bottom ps-3 pt-1'>
																<div className='error-text sc-ion-input-md'>
																	{t(errors?.Designation?.message as string)}
																</div>
															</div>
														)}
													</>
												);
											}
										}
									)}
							</div>

							<div className='d-none d-md-block'>
								<CustomButton
									disabled={Object.keys(errors)?.length === 0 ? false : true}
									expand={'block'}
									type={'submit'}
									className={'btn-blue'}
									title={t('Invite')}
									// disabled={!isValid}
								/>
							</div>
						</form>
					</div>
				</div>
			</IonContent>
			<IonFooter className='d-block d-md-none ion-padding'>
				<CustomButton
					expand={'block'}
					type={'submit'}
					className={'btn-blue'}
					title={t('Invite')}
					disabled={!isValid}
					onClick={handleSubmit(onSubmit)}
				/>
			</IonFooter>
		</IonPage>
	);
};
