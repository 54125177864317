export const PersonalInfo = [
    {
        "name": "first_name",
        "type": "text",
        "placeholder": "First Name (as per Aadhaar) *",
        isDisabled: true
    },
    {
        "name": "middle_name",
        "type": "text",
        "placeholder": "Middle Name (as per Aadhaar)",
        isDisabled: true
    },
    {
        "name": "last_name",
        "type": "text",
        "placeholder": "Last Name (as per Aadhaar) *",
        isDisabled: true
    },
    {
        "name": "email",
        "type": "text",
        "placeholder": "Email *",
        isDisabled: true
    },
    {
        "name": "mobile_no",
        "type": "text",
        "placeholder": "Mobile No",
        isDisabled: true
    },
    {
        "name": "dob",
        "type": "date",
        "placeholder": "Date of Birth *",
        isDisabled: true
    },
    {
        "name": "pan",
        "type": "text",
        "placeholder": "PAN *",
        isDisabled: true
    },
    {
        "name": "pincode",
        "type": "text",
        "placeholder": "Pincode *",
        isDisabled: true
    },
    {
        "name": "loan_amount",
        "type": "text",
        "placeholder": "Loan Amount *",
        isDisabled: true
    },
];