import * as yup from 'yup';
import { REGEX } from '../../../helpers/constant';

export const basicInfoKycSchema = yup.object().shape({
	first_name: yup
		.string()
		.trim()
		.max(100, 'First Name should not be more than 100')
		.required('First Name is Required.')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input.'),
	middle_name: yup
		.string()
		.trim()
		.max(100, 'Middle Name should not be more than 100')
		.notRequired()
		.matches(REGEX.ALLOWONLYCHAR, {
			message: 'Please Enter Valid Middle Name',
			excludeEmptyString: true,
		}),
	last_name: yup
		.string()
		.trim()
		.max(100, 'Last Name should not be more than 100')
		.required('Last Name is Required.')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input.'),
	// mobile: yup.string().matches(/^[6-9]\d{9}$/, {
	//     message: 'Please Enter a valid Mobile No.',
	//     excludeEmptyString: false,
	// }).required('Mobile Number is required'),
	mobile: yup.string().notRequired(),
	alternate_mobile: yup
		.string()
		.notRequired()
		.notOneOf(
			[yup.ref('mobile'), null],
			'Alternate Mobile No. should not be same as Mobile No.'
		)
		.matches(REGEX.INDIAN_MOBILE_NUMBERS, {
			message: 'Please Enter Valid Alternate Mobile No.',
			excludeEmptyString: true,
		}),

	// .matches(REGEX.INDIAN_MOBILE_NUMBERS, 'Please Enter Valid Input.'),
	email: yup
		.string()
		.required('Email is Required')
		.matches(REGEX.EMAIL, 'Please Enter Valid Email'),
});

export const documentsKycSchema = yup.object().shape({
	Pan: yup.object().shape({
		Url: yup.string().required('Pan Image Required.'),
		Data: yup.object().shape({
			No: yup
				.string()
				.required('PAN No. is Required.')
				.trim()
				// .matches(REGEX.UPPERCASE, 'Please enter value in uppercase')
				.matches(
					REGEX.PAN,
					"PAN no. should be in alphanumeric format 'ABCDE1234A' only"
				),
		}),
	}),
	Aadhaar: yup.object().shape({
		Url: yup.string().required('Front Image Required.'),
		Url2: yup.string().required('Back Image Required.'),
		Data: yup.object().shape({
			No: yup
				.string()
				.required('Aadhaar No. is required')
				.max(12, 'Aadhaar No. should not be more than 12 digits')
				.matches(REGEX.AADHAAR, {
					message: 'Aadhaar No. Should Be XXXX XXXX XXXX Format',
					excludeEmptyString: true,
				}),
			Name: yup
				.string()
				.required('Aadhaar Name is required')
				.trim()
				.matches(REGEX.FULLNAME, {
					message: 'Please Enter Valid Name',
					excludeEmptyString: true,
				}),
		}),
	}),
	Photo: yup.object().shape({
		Url: yup.string().required('Photo is Required.'),
	}),
	// GST: yup.object().shape({
	// 	Url: yup.string().required('GST Certificate Required.'),
	// 	Data: yup.object().shape({
	// 		No: yup.string().required('GST No. is required').matches(REGEX.GST, {
	// 			message: 'GST No. Should Be 22AAAAA0000A1Z5 Format.',
	// 			excludeEmptyString: true,
	// 		}),
	// 	}),
	// }),
});

export const professionalDetailsKycScema: any = yup.object().shape({
	Url: yup.string().required('Address Proof Image is Required.'),
	Data: yup.object().shape({
		PermanentAddress: yup
			.string()
			.required('Permanent Residential Address is required')
			.trim()
			.max(200, 'Permanent Residential Address Should not more than 200')
			.matches(
				/^[a-zA-Z0-9 _",():#-]*$/,
				'Please Enter Valid Format. Only allows (_",():#-)'
			),
		//Product: yup.array().min(1, 'Select at least 1 product'),

		CurrentAddress: yup
			.string()
			.required('Current Residential Address is required')
			.trim()
			.max(200, 'Current Residential Address Should not more than 200')
			.matches(
				/^[a-zA-Z0-9 _",():#-]*$/,
				'Please Enter Valid Format. Only allows (_",():#-)'
			),
		CurrentPinCode: yup
			.string()
			.trim()
			.required('Current Address Pincode is Required.')
			.length(6, 'Pincode Should Be 6 Digits.'),
		CurrentState: yup.string().required('State is Required'),
		CurrentCity: yup.string().required('City is Required'),
		OfficePinCode: yup
			.string()
			.trim()
			.required('Current Address Pincode is Required.')
			.length(6, 'Pincode Should Be 6 Digits.'),
		OfficeState: yup.string().required('State is Required'),
		OfficeCity: yup.string().required('City is Required'),
		PermanentPinCode: yup
			.string()
			.trim()
			.required('Pincode Number is Required.')
			.length(6, 'Pincode Should Be 6 Digits.'),
		PermanentState: yup.string().required('State is Required'),
		PermanentCity: yup.string().required('City is Required'),
		OfficeAddress: yup
			.string()
			.required('Office Address is Required.')
			.trim()
			.max(80, 'Office Address Should not more than 80')
			.matches(
				/^[a-zA-Z0-9 _",():#-]*$/,
				'Please Enter Valid Format. Only allows (_",():#-)'
			),
		AddressProof: yup.string().required('AddressProof is Required'),
		ReferenceName1: yup
			.string()
			.required('Reference Name is Required')
			.trim()
			.max(150, 'Reference Name Should not more than 150')
			.matches(REGEX.FULLNAME, 'Please Enter Valid Name Format'),
		ReferenceMobile1: yup
			.string()
			.required('Reference Mobile is Required')
			.matches(REGEX.INDIAN_MOBILE_NUMBERS, {
				message: 'Please Enter Valid Mobile',
				excludeEmptyString: true,
			}),
		ReferenceName2: yup.string().notRequired().trim().matches(REGEX.FULLNAME, {
			message: 'Please Enter Valid Name Format',
			excludeEmptyString: true,
		}),
		finance: yup.boolean().required('Please select one of this options'),
		ReferenceMobile2: yup
			.string()
			.notRequired()
			.matches(REGEX.INDIAN_MOBILE_NUMBERS, {
				message: 'Please Enter Valid Mobile',
				excludeEmptyString: true,
			}),
	}),
});

export const BankDetailKycSchema = yup.object().shape({
	Bank: yup.object().shape({
		Url: yup.string().required('Cancelled Cheque Image Required.'),
		AccountNo: yup
			.string()
			.required('Account No is Required.')
			.max(30, 'Max account no should be less than or equal to 30')
			.matches(REGEX.ACCOUNTNO, 'Please enter valid Input.'),
		IFSC: yup
			.string()
			.required('IFSC Code is required')
			// .matches(REGEX.UPPERCASE, 'Please enter value in Uppercase.')
			.max(11, 'IFSC code must be 11 character')
			.trim()

			.matches(REGEX.IFSC, 'IFSC Format Invalid'),
		BankName: yup.string().required('Bank Name is required'),
		BranchName: yup.string().required('Branch Name is required'),
	}),
});
