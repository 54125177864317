import React from 'react';
import { useIonRouter } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';

import { Header } from '../../../components/layout/header/Header';
import { InCredPersonalInfoForm } from './InCredPersonalInfoForm';
import { InCredOfferInfoForm } from './InCredOfferInfoForm';
import { LeadsFlowGenerator } from '../../../components/common/custom-leads-flow-generator/LeadsFlowGenerator';
import { lenderSpfDetailsFlow } from '../../../store/reducers/lead/leadSlice';

export const InitiateInCredLead: React.FC = () => {
	type ComponentType = React.ReactNode | React.ReactFragment;
	type StepsComponents = {
		step: string;
		component: ComponentType;
	};
	// @typescript-eslint/no-unused-vars
	const dispatch = useDispatch();
	const router = useIonRouter();
	const lenderSpfDetails = useSelector(
		(state: any) => state?.lead?.lenderSpecificDetails
	);

	const stepsComponents: StepsComponents[] = [
		{
			step: 'step1',
			component: <InCredPersonalInfoForm />,
		},
		{
			step: 'step2',
			component: <InCredOfferInfoForm />,
		},
	];

	const headerNeedArrowOnClick = async () => {
		switch (lenderSpfDetails?.step) {
			case 'step2':
				dispatch(
					lenderSpfDetailsFlow({
						...lenderSpfDetails,
						step: 'step1',
					})
				);
				break;
			case 'step1':
				dispatch(lenderSpfDetailsFlow({}));
				router.push('/lender-specific-info');
				break;
		}
	};

	const stepsHeader: ComponentType = (
		<Header
			name={'InCred Specific Form'}
			backButton={true}
			needArrow={true}
			needCross={false}
			needArrowOnClick={() => headerNeedArrowOnClick()}
			backButtonText=''
		/>
	);

	return (
		<>
			<LeadsFlowGenerator
				currentStep={lenderSpfDetails?.step}
				stepsHeader={stepsHeader}
				displayStepsCounter={lenderSpfDetails?.subHeaderStepsCounter}
				stepsComponents={stepsComponents}
			/>
		</>
	);
};
