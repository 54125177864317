import { yupResolver } from "@hookform/resolvers/yup";
import {
  IonCard,
  IonCardContent,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";
import CustomForm from "../../../components/common/custom_form/custom_form.component";

import { CustomButton } from "../../../components/common/custom-button/CustomButton";
import welcome_call_status_schema from "./welcome_call_status.schema";
import { Header } from "../../../components/layout/header/Header";
import { REDIRECT } from "../../../helpers/constant";
// import {CustomForm} from 'andro-insurance-lib';

const WelcomeStatusCallFormKeys = [
  {
    name: "welcome_call_status",
    //hbs change here
    type: "text/combo",
    placeholder: "Welcome Call Status",
    mandatory: true,
    values: [
      { value: "pending", description: "Pending" },
      { value: "done", description: "Done" },
    ],
  },
  {
    name: "welcome_call_date",
    type: "text",
    placeholder: "Date",
    mandatory: true,
  },
 
  {
    name: "remarks",
    type: "textarea",
    placeholder: "Remarks",
    // disabled: true,
  },
];

interface Props {
  title: string;
  nextTabUrl?: string;
  inpCallback?: () => void;
  // occupationDetailsForm?: OccupationDetailsForm;
  // userId: any;
  // setShowBasicInfoForm: any;
  // getUserDetailsInfo: any;
}

export const WelcomeStatusCall: React.FC<Props> = ({
  title,
  inpCallback,
  nextTabUrl,
  // occupationDetailsForm,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [validateForm, setValidateForm] = useState<boolean>();





  


  const {
    control,
    register,
    trigger,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(welcome_call_status_schema),
    // defaultValues: occupationDetailsForm,
  });

 
  const onSubmit = async (data: any) => {
    // console.log("hbs occ in func");
    console.log(data);

    // const payload: OccupationDetailsRequest = {
    //   occupation_type: data.occupationType,
    //   company_name: data.companyName,
    //   job_description: data.jobDescription,
    //   annual_income: parseInt(data.annualIncome),
    //   total_work_exp: data.totalWorkExp,
    //   id: undefined,
    //   profileId: 0,
    // };
    // // exec;
    // // Handle form submission logic here
    // // inpCallback();
    // // history.push(nextTabUrl);

    // await executeRequest("post", payload);

    // if (inpCallback) {
    //   inpCallback();
    // }

    // if (nextTabUrl) {
    //   history.push(nextTabUrl);
    // }

  };
  

  return (
    <IonPage className="bg-web-white container-width-md container-spacing-md border-md-radius-top">
      <Header
				className="card-header"
				name={t(title ?? "Welcome Status Call")}
				backButton={true}
				needArrow={true}
				needArrowOnClick={() => {
					history.push(REDIRECT.DASHBOARD);
				}}
			/>
      
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">WelcomeStatusCall</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* <section
          className='overflowX-hidden pt-1'
          style={{ height: 'calc(100vh - 20px)' }}
        > */}
        <IonContent className="ion-padding-bottom">
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonCard>
                <IonCardContent>
                  <CustomForm
                    formKeys={WelcomeStatusCallFormKeys}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    isValid={validateForm}
                    errors={errors}
                    trigger={trigger}
                  />
                </IonCardContent>
              </IonCard>
              <div
                className="position-fixed w-100 bottom-0 start-0 px-3 pb-3"
                style={{ backgroundColor: "#fff" }}
              >
                <IonGrid>
                  <IonRow class="ion-justify-content-center ion-align-items-center">
                    <CustomButton
                      className="btn-blue"
                      type="submit"
                      expand="block"
                      title="Update"
                      onClick={() => {
                        handleSubmit(onSubmit);
                        setValidateForm(isValid);
                      }}
                    // disabled={!isValid}
                    // title={t('Update')}
                    // disabled={!isValid}
                    />

                  </IonRow>
                </IonGrid>

              </div>
            </form>
          </div>
          {/* </section> */}
        </IonContent>
      </IonContent>

    </IonPage>
  );
};

