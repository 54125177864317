import React from 'react';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import topBrands from './TopBrands.json';
import Slider from 'react-slick';
import { useHistory } from 'react-router';
import { productFlow } from '../../../../store/reducers/lead/leadSlice';
import { useDispatch } from 'react-redux';

function sliceIntoChunks(arr: any, chunkSize: number) {
	const res = [];
	for (let i = 0; i < arr.length; i += chunkSize) {
		const chunk = arr.slice(i, i + chunkSize);
		res.push(chunk);
	}
	return res;
}

export const TopBrands: React.FC = () => {
	const { t } = useTranslation();
	const divideArr = sliceIntoChunks(topBrands, topBrands.length / 2);
	const history = useHistory();
	const dispatch = useDispatch();

	const settings = {
		arrows: false,
		dots: false,
		autoplay: true,
		slidesToShow: 8,
		slidesToScroll: 3,
		autoplaySpeed: 0,
		// cssEase: 'linear',
		speed: 4000,
		infinite: true,
		pauseOnFocus: false,
		pauseOnHover: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 390,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 300,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 2,
				},
			},
		],
	};

	return (
		<section className='bg-web-white border-md-radius-10 py-md-3 mb-md-3'>
			<div className='top-brands'>
				<div className='pb-4'>
					<h3 className='fs-16 fw-600 m-0 text-center darkest-grey'>
						{t('We Have')} 140+ {t('Principal Partners')}
					</h3>
				</div>
				<Slider
					{...settings}
					rtl={false}
				>
					{divideArr[0].map((icon: string) => {
						return (
							<div
								key={icon}
								className='px-2'
								// className='smooth-slider-slide'
							>
								<div className=''>
									<img
										src={`/images/icons/Bank-logos/${icon}.svg`}
										alt={icon}
									/>
								</div>
							</div>
						);
					})}
				</Slider>
				<Slider
					{...settings}
					rtl={true}
				>
					{divideArr[0].map((icon: string) => {
						return (
							<div
								key={icon}
								// className='smooth-slider-slide'
								className='px-2'
							>
								<div className=''>
									<img
										src={`/images/icons/Bank-logos/${icon}.svg`}
										alt={icon}
									/>
								</div>
							</div>
						);
					})}
				</Slider>
				{/* <div className='brands-slider'>
					<div className='brands-box-row smooth-slider'>
						<div className='pb-2  smooth-slider-track'>
							{divideArr[0].map((icon: string) => {
								return (
									<div
										key={icon}
										className='smooth-slider-slide'
									>
										<div className='brands-box'>
											<img
												src={`/images/icons/Bank-logos/${icon}.svg`}
												alt={icon}
											/>
										</div>
									</div>
								);
							})}
						</div>
						<div className='pb-2 smooth-slider-track smooth-slider-reverse'>
							{divideArr[1].map((icon: string) => {
								return (
									<div
										key={`${icon}`}
										className='smooth-slider-slide'
									>
										<div className='brands-box'>
											<img
												src={`/images/icons/Bank-logos/${icon}.svg`}
												alt={icon}
											/>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div> */}
				<div className='pt-2 px-3 d-flex align-items-center justify-content-center'>
					<CustomButton
						expand='block'
						className='btn-blue fw-600 fs-14 button-width dashbord-buttons'
						fill='outline'
						title={t('Explore All Principal Partners')}
						style={{
							marginTop: '16px',
							display: 'block',
						}}
						onClick={() => {
							dispatch(
								productFlow({
									title: 'All',
									from: 'dashboard',
									is_lender: 1,
									is_product: 0,
									lender_show: '1',
								})
							);
							history.push('/all-lenders');
						}}
					/>
				</div>
			</div>
		</section>
	);
};
