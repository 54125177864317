import * as yup from "yup";
import { REGEX } from "./../../helpers/constant";

const basic_details_schema = yup.object({
  // id: yup
  //   .number()
  //   .notRequired(),
  // mobileNumber: yup
  //   .string()
  //   .length(10, "Mobile No. Should Be 10 Digits.")
  //   .notRequired()
  //   .matches(REGEX.INDIAN_MOBILE_NUMBERS, "Please Enter a valid Mobile No."),
    mobileNumber: yup
    .string()
    .notRequired()
    .matches(REGEX.INDIAN_MOBILE_NUMBERS, {
      message: "Please Enter a valid Mobile No.",
      excludeEmptyString: true,
    }),  
  // mobileNumber: yup.string().notRequired(),  
  alternateNumber: yup
    .string()
    .notRequired()
    // .notOneOf(
    //   [yup.ref("mobileNumber"), null],
    //   "Alternate Mobile No. should not be same as Mobile No."
    // )
    .matches(REGEX.INDIAN_MOBILE_NUMBERS, {
      message: "Please Enter Valid Alternate Mobile No.",
      excludeEmptyString: true,
    }),
  panNumber: yup.string().required("PAN No. is Required."),
  firstName: yup
    .string()
    .trim()
    .max(100, "First Name should not be more than 100")
    .required("First Name is Required.")
    .matches(REGEX.ALLOWONLYCHAR, "Please Enter Valid Input."),
  middleName: yup
    .string()
    .trim()
    .max(100, "Middle Name should not be more than 100")
    .notRequired()
    .matches(REGEX.ALLOWONLYCHAR, {
      message: "Please Enter Valid Middle Name",
      excludeEmptyString: true,
    }),
  lastName: yup
    .string()
    .trim()
    .max(100, "Last Name should not be more than 100")
    .required("Last Name is Required.")
    // .matches(REGEX.ALLOWONLYCHAR, "Please Enter Valid Input.") 
    ,
  gender: yup.string().required("Please select Gender"),
  // pincode: yup.number().required("Pincode Number is Required."),
  age:yup.string().notRequired(),
  dob: yup
    .date()
    .required("DOB is Required.")
    .min('1969-11-13', 'Date is too early')
    .typeError("Please select valid date")
    .max(new Date(Date.now()), "Date cannot be in the future")
    .test('dob', "Age must be 18 or older", function (birthdate) {
      const cutoff = new Date();
      cutoff.setFullYear(cutoff.getFullYear() - 18);
      return birthdate <= cutoff;
    }),
  emailId: yup.string().required("Email is Required"),
  //SS.matches(REGEX.EMAIL, "Please Enter Valid Email"),
});

export default basic_details_schema;
