export class DocumentUploadForm {
    documentId: number | null;
    documentTypeId: number;
    content: string | null;
    name: string;
    extension: string;
    policyId: number| null;
    profileId: number| null;
    applicationId: number| null;
    deletedFlag: boolean | null;
    // createdAt:string | null;
    // updatedAt:string | null;


    constructor(request?: DocumentUploadRequest) {
        if (request) {
            // Initialize from a request object
            this.documentId = request.documentId;
            this.documentTypeId = request.documentTypeId;
            this.content = request.content;
            this.name = request.name;
            this.extension = request.extension;
            this.policyId = request.policyId;
            this.profileId = request.profileId;
            this.applicationId = request.applicationId;
            this.deletedFlag = request.deletedFlag;
            // this.createdAt=null;
            // this.updatedAt=null;
        } else {
            // Initialize with default values
            this.documentId = null;
            this.documentTypeId = 0; // You can replace 0 with an appropriate default
            this.content = null;
            this.name = '';
            this.extension = '';
            this.policyId = 0;
            this.profileId = 0;
            this.applicationId = 0;
            this.deletedFlag = null;
            // this.createdAt=null;
            // this.updatedAt=null;
        }
    }

};

export class DocumentUploadRequest {
    documentId: number | null;
    documentTypeId: number;
    content: string | null;
    name: string;
    extension: string;
    policyId: number| null;;
    profileId: number| null;;
    applicationId: number| null;
    deletedFlag: boolean | null;
    // createdAt:string | null;
    // updatedAt:string | null;

    constructor(form: DocumentUploadForm) {
        this.documentId = form.documentId;
        this.documentTypeId = form.documentTypeId;
        this.content = form.content;
        this.name = form.name;
        this.extension = form.extension;
        this.policyId = form.policyId;
        this.profileId = form.profileId;
        this.applicationId = form.applicationId;
        this.deletedFlag = form.deletedFlag;
        // this.createdAt = form.createdAt;
        // this.updatedAt = form.updatedAt;

    }
};

