import {
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonContent,
	IonImg,
	IonItem,
	IonList,
	IonPage,
	IonTextarea,
	isPlatform,
} from '@ionic/react';
import './lead_details.scss';
// import * as React from "react";
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { Divider } from '../../components/common/divider/Divider';
import { InitialLetter } from '../../components/common/initial-letter/InitialLetter';
// import url: string, GET_COMMENTS: stringfrom "../../components/layout/header/Header";
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import useApi from '../../api/useApi';
import { useBusyLoading } from '../../context/BusyLoadingContext';
import {
	Add_COMMENT,
	DASHBOARD_INSURANCE_LEAD_DATA,
	GET_ACTIVITY_DETAILS,
	GET_COMMENTS,
	INSURANCE_FETCH_APPLICATION_DETAILS,
	INSURANCE_APPLICATION_LEAD_DETAILS,
} from '../../helpers/api-endpoints';
import {
	ActivityDetails,
	ActivityDetailsData,
} from '../../model/activity_details_bean';
// import { Add_COMMENT, GET_ACTIVITY_DETAILS, GET_COMMENTS, INSURANCE_FETCH_APPLICATION_DETAILS } from "../../helpers/api-endpoints";
import { INSURANCE_POLICY_PROPOSED_FOR, LEAD_STATUS, LeadStatus } from '../../helpers/constant';
import {
	findSource,
	showToastAlert,
	maskMobile,
	capFirstLetter,
} from '../../helpers/functions';
// import {
//   ActivityDetails,
//   ActivityDetailsData
// } from "../../model/activity_details_bean";
import InsuranceLeadApplications from './LeadApplications';
// import { yupResolver } from "@hookform/resolvers/yup";
// import activityDetailsSchema from "./activity_details_schema";
// import { Controller, useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { ActivityContext } from '../../context/ActivityContext';
// import ApplicationContext from "../../context/ApplicationContext";
import ApplicationContext from '../../context/ApplicationContext';
import { AuthContext } from '../../context/AuthContext';
import { useLeadDetails } from '../../context/LeadDetailsContext'; // Import the custom hook
import { FormatDate } from '../../helpers/functions';
import { openAppDetails } from '../../helpers/redirects';
import { BasicDetailsForm } from '../../model/basic_details_bean';
import {
	CommentListData,
	CommentListDetailRequest,
	CommentListDetails,
} from '../../model/comment_list_bean';
import { FamilyDetailsForm } from '../../model/family_details_bean';
import { ApplicationBean } from '../../model/insurance_bean';
import { OccupationDetailsForm } from '../../model/occupation_bean';
import basic_details_schema from '../basic_details/basic_details.schema';
import family_details_schema from '../family_details/family_details.schema';
import { occupation_details_schema } from '../occupation_details/occupation_details.schema';
import { InsuranceActivitySection } from './InsuranceActivitySection';
import { ApplicationStatusProps } from '../update-status/update_application_status/update_application_status';
import { Header } from '../../components/layout/header/Header';
import address_details_schema from '../address_details/address_details.schema';
import { AddressDetailsForm } from '../../model/address_details_bean';

// const lead = {
//   andro_lead_manager: "148",
//   andro_manager_name: "KEDAR KIRAN JOSHI",
//   call_date: null,
//   category_name: "Business Loan",
//   category_purpose_id: 16,
//   city: "AHMEDABAD",
//   createdAt: "2024-04-01T18:23:03.000Z",
//   dob: "19 June 2002",
//   doc_no: "**********",
//   email: "***************",
//   first_name: "Hiren",
//   id: 794,
//   isLenderSelected: false,
//   is_consent: 1,
//   last_name: "Samtani",
//   lead_current_assignee: 148,
//   lead_source: "1",
//   lead_status: "30",
//   loan_amount: 100,
//   middle_name: "",
//   mobile_no: "******5665",
//   pan: "**********",
//   pincode: "382481",
//   product_category_id: 2,
//   product_id: 1,
//   product_name: "Loans",
//   purpose_name: "Debt Consolidation",
//   scope_type: "Equifax",
//   state: "GUJARAT",
// };
// const leadInfo = {
//   leadStatus: "all",
//   update: 2,
//   isDisbursedFlow: false,
//   applicationStatus: "all",
//   isAllLead: "all",
//   isRawLead: "",
//   leadTypeName: "Loans",
//   productType: "Loans",
//   leadTypeId: 1,
//   isPartial: "",
//   isLenderSelected: false,
//   leadId: 794,
//   lead_app_id: null,
//   leadMobile: "7654321678",
//   id: 794,
//   selectedProductData: {
//     product_category_id: 2,
//   },
//   andro_lead_manager: "148",
//   andro_manager_name: "KEDAR KIRAN JOSHI",
//   call_date: null,
//   category_name: "Business Loan",
//   category_purpose_id: 16,
//   city: "AHMEDABAD",
//   createdAt: "2024-04-01T18:23:03.000Z",
//   dob: "19 June 2002",
//   doc_no: "**********",
//   email: "***************",
//   first_name: "KAJAL",
//   is_consent: 1,
//   last_name: "Vaghela",
//   lead_current_assignee: 148,
//   lead_source: "1",
//   lead_status: "30",
//   loan_amount: 100,
//   middle_name: "Bhagvanji",
//   mobile_no: "******1678",
//   pan: "**********",
//   pincode: "382481",
//   product_category_id: 2,
//   product_id: 1,
//   product_name: "Loans",
//   purpose_name: "Debt Consolidation",
//   scope_type: "Equifax",
//   state: "GUJARAT",
// };
// const leadStatus = "all";

// export interface ActivityProps {
//   ActivityDetails?: ActivityDetails;
//   ActivityDetailsData?: ActivityDetailsData;
// }
// export interface CommentProps {
//   CommentListData?: CommentListData;
//   CommentListDetails?: CommentListDetails;
// }
// interface ActivitySectionProps {
//   activityList: ActivityDetails[];
//   onChange?: () => void;
// }

interface CustomButtonProps {
	data?: any;
}
interface LocationState {
	applicationId: number;
	id: null | number;
	labelName: string | null;
}

interface LeadDetailsInsuranceProps {
	activityList: ActivityDetails[];
	onChange?: () => void;
	CommentListData?: CommentListData;
	CommentListDetails?: CommentListDetails;
	ActivityDetails?: ActivityDetails;
	ActivityDetailsData?: ActivityDetailsData;
	bankDetails: [];
}

const LeadDetailsInsurance: React.FC<LeadDetailsInsuranceProps> = () => {
	const isBrowser = !isPlatform('capacitor');
	const { commentList, setCommentList } = useContext(ActivityContext);
	const { infoActivity, setInfoActivity } = useContext(ActivityContext);
	const location = useLocation<LocationState>();
	const applicationId = location.state?.id;
	const initialStatus = capFirstLetter(location.state?.labelName);
	// const initialStatus = "Login"
	console.log(initialStatus);

	// console.log("applicationId>>>>>", applicationId);
	// const [leadData, setLeadData] = useState<LeadListData>();
	const { leadData, setLeadData, setProinfo } = useLeadDetails();
	const {
		setAppBean,
		setProposerBasicDetailsValid,
		setProposerFamilyDetailsValid,
		setProposerOccupationDetailsValid,
		setProposerPermAddressDetailsValid,
		setProposerCurAddressDetailsValid,
		setAssuredBasicDetailsValid,
		setAssuredFamilyDetailsValid,
		setAssuredOccupationDetailsValid,
		setAssuredPermAddressDetailsValid,
		setAssuredCurAddressDetailsValid,
		applicationBean,
	} = useContext(ApplicationContext);
	const userContext = useContext(AuthContext);
	const { userId } = useContext(AuthContext);
	console.log(userId, '<<<<<<<');
	const history = useHistory();
	const { t } = useTranslation();
	const [comment, setComment] = useState<string>('');
	const { setBusyState } = useBusyLoading();
	const [bankDetails, setBankDetails] = useState([]);
	const [showDropdown, setShowDropdown] = useState(false);
	const [applicationStatusData, setApplicationStatusData] = useState<any>([]);
	const [newComment, setNewComment] = useState<string | undefined>();
	// console.log("c>>>>>>>>>>",commentList);
	const [selectApplicationStatusValue, setApplicationStatusValue] =
		useState<any>();
	const [isShowLeadApplicationCard, setIsShowLeadApplicationCard] =
		useState<boolean>(false);

	const leadDataApi = useApi<any>(DASHBOARD_INSURANCE_LEAD_DATA);
	const activityInfoApi = useApi<any>(GET_ACTIVITY_DETAILS);
	const addCommentApi = useApi<any>(Add_COMMENT);
	const initialCommentApi = useApi<any>(GET_COMMENTS);
	const getCommentAPi = useApi<any>(GET_COMMENTS);
	const proceedButtonApi = useApi<any>(INSURANCE_FETCH_APPLICATION_DETAILS);
	const applicationDetailsApi = useApi<any>(INSURANCE_APPLICATION_LEAD_DETAILS);
	//console.log(">>>>>>>>>>",applicationDetailsData);

	const getAppDetails = useApi<any>(INSURANCE_FETCH_APPLICATION_DETAILS);

	useEffect(() => {
		setBusyState(getAppDetails.loading);
		showToastAlert({ type: 'error', message: getAppDetails.error?.message! });
	}, [getAppDetails.loading, getAppDetails.error]);

	// useEffect(() => {
	//     showToastAlert({ type: "error", message: getAppDetails.error?.message! });
	// }, [getAppDetails.error]);

	useEffect(() => {
		setBusyState(leadDataApi.loading);
		showToastAlert({ type: 'error', message: leadDataApi.error?.message! });
	}, [leadDataApi.loading, leadDataApi.error]);

	useEffect(() => {
		setBusyState(activityInfoApi.loading);
		showToastAlert({ type: 'error', message: activityInfoApi.error?.message! });
	}, [activityInfoApi.loading, activityInfoApi.error]);

	useEffect(() => {
		setBusyState(initialCommentApi.loading);
		showToastAlert({
			type: 'error',
			message: initialCommentApi.error?.message!,
		});
	}, [initialCommentApi.loading, initialCommentApi.error]);

	useEffect(() => {
		setBusyState(addCommentApi.loading);
		showToastAlert({ type: 'error', message: addCommentApi.error?.message! });
	}, [addCommentApi.loading, addCommentApi.error]);

	useEffect(() => {
		setBusyState(applicationDetailsApi.loading);
		showToastAlert({
			type: 'error',
			message: applicationDetailsApi.error?.message!,
		});
	}, [applicationDetailsApi.loading, applicationDetailsApi.error]);

	const payload = {
		applicationId: applicationId,
	};

	useEffect(() => {
		leadDataApi.executeRequest('post', payload);
		activityInfoApi.executeRequest('post', payload);
		applicationDetailsApi.executeRequest('post', payload);
		getCommentAPi.executeRequest('post', payload);
	}, []);

	useEffect(() => {
		if (getCommentAPi?.responseData?.status) {
			setCommentList(getCommentAPi?.responseData?.data);
			if (commentList.length > 0 && commentList[0]?.comment) {
				setNewComment(commentList[0].comment);
			}
		}
	}, [getCommentAPi?.responseData, commentList]);

	useEffect(() => {
		if (leadDataApi.responseData?.status) {
			setLeadData(leadDataApi.responseData?.data);
			setProinfo(leadDataApi.responseData?.data.proinfo);
		}
	}, [leadDataApi.responseData]);

	useEffect(() => {
		if (activityInfoApi.responseData?.status) {
			setInfoActivity(activityInfoApi.responseData?.data);
		}
	}, [activityInfoApi.responseData]);

	useEffect(() => {
		if (applicationDetailsApi.responseData?.status) {
			setBankDetails(applicationDetailsApi.responseData?.data);
			getApplicationStatusList();
		}
	}, [applicationDetailsApi.responseData]);
	// console.log(applicationDetailsData?.data);

	useEffect(() => {
		if (initialCommentApi.responseData?.status) {
			setCommentList([...commentList, initialCommentApi.responseData?.data]);
			setNewComment(initialCommentApi.responseData?.data[0]?.comment);
			getCommentAPi.executeRequest('post', payload);
		}
	}, [initialCommentApi.responseData]);

	useEffect(() => {
		if (addCommentApi.responseData?.status) {
			setCommentList([...commentList, getCommentAPi?.responseData?.data]);
			setNewComment(getCommentAPi?.responseData?.data[0]?.comment);
			getCommentAPi.executeRequest('post', payload);
		}
	}, [addCommentApi.responseData]);

	const addComment = async () => {
		try {
			let commentListDetailRequest = new CommentListDetailRequest();
			commentListDetailRequest.id = null;
			commentListDetailRequest.comment = comment;
			commentListDetailRequest.commented_by = userId;
			commentListDetailRequest.commented_by_name = 'null';
			commentListDetailRequest.applicationId = applicationId;
			commentListDetailRequest.createdAt = 'null';
			commentListDetailRequest.updatedAt = 'null';
			const commentPayload = commentListDetailRequest;
			await addCommentApi.executeRequest('post', commentPayload, true, true);
			// executeGetCommentsRequest('post', payload, true)
			setComment('');
			showToastAlert({ type: 'info', message: 'Comment added successfully' });
		} catch (error) {
			console.log(error);
		}
	};

	const onProceedClick = () => {
		proceedButtonApi.executeRequest('post', payload);
		// history.push('/ifainsurance')
	};

	useEffect(() => {
		// console.log("hbs resp",responseData);
		// console.log("hbs resp",responseData?.data);

		if (proceedButtonApi.responseData?.data) {
			// console.log("hbs success ", responseData?.data);

			const appBeanFin = new ApplicationBean(
				proceedButtonApi.responseData?.data
			);

			console.log('hbs appBeanFin Temp', appBeanFin);

			setAppBean(appBeanFin);

			basic_details_schema
				.validate(new BasicDetailsForm(appBeanFin.basicDetailsProposer!))
				.then((val) => {
					console.info('valid', 'basicDetails');
					setProposerBasicDetailsValid(true);
				})
				.catch((error) => {
					console.info('invalid', 'basicDetails');
					setProposerBasicDetailsValid(false);
				});

			family_details_schema
				.validate(new FamilyDetailsForm(appBeanFin.familyDetailsProposer!))
				.then(() => setProposerFamilyDetailsValid(true))
				.catch(() => setProposerFamilyDetailsValid(false));

			const occDetailsSchemaProposer = occupation_details_schema(false);
			occDetailsSchemaProposer
				.validate(
					new OccupationDetailsForm(appBeanFin.occupationDetailsProposer!)
				)
				.then((val) => setProposerOccupationDetailsValid(true))
				.catch((error) => setProposerOccupationDetailsValid(false));

			address_details_schema
				.validate(appBeanFin.permAddressDetailsProposer!)
				.then(() => {
					setProposerPermAddressDetailsValid(true);
				})
				.catch(() => {
					setProposerPermAddressDetailsValid(false);
				});

			address_details_schema
				.validate(appBeanFin.curAddressDetailsProposer!)
				.then(() => {
					setProposerCurAddressDetailsValid(true);
				})
				.catch(() => {
					setProposerCurAddressDetailsValid(false);
				});


			
			if(appBeanFin?.policyProposedFor !== INSURANCE_POLICY_PROPOSED_FOR.SELF){
					basic_details_schema
				.validate(new BasicDetailsForm(appBeanFin.basicDetailsAssured!))
				.then((val) => {
					console.info('valid', 'basicDetails Assured');
					setAssuredBasicDetailsValid(true);
				})
				.catch((error) => {
					console.info('invalid', 'basicDetails');
					setAssuredBasicDetailsValid(false);
				});

			family_details_schema
				.validate(new FamilyDetailsForm(appBeanFin.familyDetailsAssured!))
				.then(() => setAssuredFamilyDetailsValid(true))
				.catch(() => setAssuredFamilyDetailsValid(false));

			const occDetailsSchemaAssured = occupation_details_schema(false);
			occDetailsSchemaAssured
				.validate(
					new OccupationDetailsForm(appBeanFin.occupationDetailsAssured!)
				)
				.then((val) => setAssuredOccupationDetailsValid(true))
				.catch((error) => setAssuredOccupationDetailsValid(false));

			address_details_schema
				.validate(appBeanFin.permAddressDetailsAssured!)
				.then(() => {
					setAssuredPermAddressDetailsValid(true);
				})
				.catch(() => {
					setAssuredPermAddressDetailsValid(false);
				});

			address_details_schema
				.validate(appBeanFin.curAddressDetailsAssured!)
				.then(() => {
					setAssuredCurAddressDetailsValid(true);
				})
				.catch(() => {
					setAssuredCurAddressDetailsValid(false);
				});
			}	

			openAppDetails(
				appBeanFin?.applicationId!,
				appBeanFin.statusId!,
				userContext,
				history
			);
			// history.replace("/ifainsurance");
		}
	}, [proceedButtonApi.responseData]);

  
  
  const handleCallToLead = () => {
		if (isBrowser) {
			if ('clipboard' in navigator) {
				navigator.clipboard.writeText(leadData?.mobile_no ?? '');
			} else {
				document.execCommand('copy', true, leadData?.mobile_no ?? '');
			}
			setTimeout(() => {
				showToastAlert({
					type: 'success',
					message: 'Number copied to clipboard',
				});
			}, 3000); 
		} else {
			return null;
		}
	};

	const handleSendMail = () => {
		if (leadData?.email) {
			window.location.href = `mailto:${leadData.email}`;
		} else {
			alert('Email client is required to send an email.');
		}
	};

	useEffect(() => {
		if (
			Array.isArray(applicationDetailsApi.responseData?.data) &&
			applicationDetailsApi.responseData?.data.length === 0
		) {
			setIsShowLeadApplicationCard(false);
		} else {
			setIsShowLeadApplicationCard(true);
		}
	}, [applicationDetailsApi.responseData, setIsShowLeadApplicationCard]);

	const getApplicationStatusList = () => {
		if (applicationDetailsApi.responseData?.data) {
			// console.log(applicationDetailsData.responseData);
			const allStatuses = applicationDetailsApi.responseData.data.map(
				(item: { id: number; updateStatus: string }, index: number) => ({
					id: item.id.toString(),
					value: item.updateStatus,
				})
			);
			const uniqueStatusesSet = new Set(
				allStatuses.map((status: any) => status.value)
			);
			const uniqueStatuses = Array.from(uniqueStatusesSet).map(
				(value, index) => ({
					id: (index + 1).toString(),
					value,
				})
			);
			setApplicationStatusData(uniqueStatuses);
			if (initialStatus) {
				const initialFiltered = applicationDetailsApi.responseData.data.filter(
					(item: any) => item.updateStatus === initialStatus
				);
				setBankDetails(initialFiltered);
				const initialStatusItem = uniqueStatuses.find(
					(status) => status.value === initialStatus
				);
				if (initialStatusItem) {
					setApplicationStatusValue(initialStatusItem);
				} else {
					setBankDetails(applicationDetailsApi.responseData?.data);
				}
			}
		}
	};

	const selectedApplicationStatus = (event: any) => {
		const getStatusData = applicationStatusData.find(
			(item: any) => event.detail.value === item.id
		);
		// console.log(getStatusData);
		setApplicationStatusValue(getStatusData);
		if (getStatusData) {
			const filtered = applicationDetailsApi.responseData.data.filter(
				(item: any) => item.updateStatus === getStatusData.value
			);
			setBankDetails(filtered);
		}
	};

	const showEditIcon = () => {
		let status = true;
		return status;
	};

	return (
		<>
			<IonPage>
				{/* <Header
          name=""
          // backButton={getTokenFromUrl ? false : true}
          backButton={true}
          needArrow={true}
          needArrowOnClick={() => {
            // handleBackButton();
            history.push('/insurance-lead-list')
          }}
          endItem={
            ![
              LEAD_STATUS.CLOSED_LABEL,
              LEAD_STATUS.UNDERPROCESS_LABEL,
              LEAD_STATUS.RAW_LABEL,
            ].includes(leadInfo?.leadStatus) ? (
              <>
                <IonButtons slot="end" className="">
                  <IonImg
                    className="cursor-pointer"
                    src="/images/icons/assign-icon.svg"
                    alt="assign"
                    // onClick={() => {
                    //   setShowAllocateManagerModal(true);
                    // }}
                  />
                </IonButtons>
              </>
            ) : (
              ""
            )
          }
        /> */}
				<Header
					name=''
					backButton={true}
					needArrow={true}
					needArrowOnClick={() => {
						history.push('/insurance-lead-list');
					}}
					endItem={
						<IonButtons
							slot='end'
							className=''
						>
							<IonImg
								className='cursor-pointer'
								src='/images/icons/assign-icon.svg'
								alt='assign'
								onClick={() => {
									setShowDropdown(true);
								}}
							/>
						</IonButtons>
					}
				/>
				{/* {showDropdown && (
        <CustomDropDown
          isCheckbox={false}  
          title={t("Select Assignee")}
          isSearch={true}
          modalTitle={t("Select Assignee")}
          handleClose={() => setShowDropdown(false)}
        />
      )} */}
				{/* {showDropdown && (
          <CustomDropDown
            {...{
              isCheckbox: false,
              title: t("Select Assignee"),
              isSearch: true,
              modalTitle: t("Select Assignee"),
              // onclick: ()=>{ isCheckbox : true ? setShowDropdown(false) : setShowDropdown(true)},
            }}
          />
        )} */}

				<IonContent
					id='lead-detail'
					className='bg-web lead-details'
				>
					{' '}
					<div className='lead-details bg-web py-md-3'>
						<div className='container-width-md bg-web-white border-md-radius-10'>
							<IonCard className='m-0 details bg-white px-3'>
								<div className='d-flex align-items-center gap-3 p-3'>
									<div className='w-auto'>
										{/* <img
									width={72}
									height={72}
									className='rounded-circle'
									alt='Silhouette of mountains'
									src='https://ionicframework.com/docs/img/demos/card-media.png'
								/> */}
										<InitialLetter
											{...{
												value: leadData?.first_name || 'Unknown',
												height: 72,
												width: 72,
												fontSize: '44px',
											}}
										/>
									</div>
									<div>
										<IonCardTitle className='fs-14 bg-transparent darkest-grey'>
											# L{applicationId}
											{/* # L{leadData?.id} */}
											{/* {`${leadInfo?.lead_app_id
                        ? `#A${leadInfo?.lead_app_id}`
                        : ""
                        }`} */}
										</IonCardTitle>
										<IonCardSubtitle className='fs-18 fw-600 darkest-grey bg-transparent text-capitalize'>
											{leadData?.first_name ? leadData?.first_name : 'Unknown'}{' '}
											{leadData?.middle_name ? leadData?.middle_name : ''}{' '}
											{leadData?.last_name ? leadData?.last_name : ''}
										</IonCardSubtitle>
										{/* {!isLeadApplicationStatus && ( */}
										<div className='d-flex'>
											{/* <CustomButton
                          disabled={!showEditIcon()}
                          className="w-100 dashboard-add-lead-button fs-16 fw-600"
                          //  expand='block'
                          fill="outline"
                          style={{ height: "30px", flexBasis: "content" }}
                          // onClick={() => {
                          //   const myPromise = new Promise(function (myResolve) {
                          //     setTimeout(function () {
                          //       myResolve(
                          //         history.push(
                          //           `/update-status${
                          //             getTokenFromUrl ? "?from=nimbuss" : ""
                          //           }`,
                          //           [leadInfo?.id]
                          //         )
                          //       );
                          //     }, 10);
                          //   });
                          //   myPromise.then(() => {
                          //     dispatch(selectedLender([]));
                          //     dispatch(
                          //       leadFlow({
                          //         ...leadInfo,
                          //         isBulk: false,
                          //         addDisposition: false,
                          //         leadStatus: lead?.lead_status,
                          //         getLeadAllSelectedIds: leadInfo?.id,
                          //         applicationStatus: "",
                          //         update: leadInfo.update + 1 || 1,
                          //       })
                          //     );
                          //   });
                          // }}
                        >
                          {
                            // findCurrentStatus(
                            //   leadInfo?.lead_app_id &&
                            //     LEAD_STATUS.CLOSED_LABEL == leadInfo.leadStatus
                            //     ? LEAD_STATUS.CLOSED_LABEL
                            //     : lead?.lead_status
                            // ).label
                            // t({leadData?.updateStatus}
                          }
                          {leadData?.updateStatus}
                          {showEditIcon() ? (
                            <img
                              className="ms-2"
                              src="images/icons/edit.svg"
                              alt="edit"
                            />
                          ) : (
                            ""
                          )}
                        </CustomButton> */}
											<CustomButton
												className='w-100 dashboard-add-lead-button fs-16 fw-600'
												fill='outline'
												style={{ height: '30px', flexBasis: 'content' }}
												onClick={async () => {
													const props: ApplicationStatusProps = {
														inpApplicationId: applicationId!,
														inpCurrentStatusLabel:
															leadData?.updateStatus?.toString(),
														// inpCurrentStatus: appBeanFin.statusId,
													};
													console.log('applicationId', applicationId);

													history.push('/updatePolicyStatus', {
														applicationStatusProps: props,
													});
												}}
											>
												{`Update Status`}
												{leadData?.updateStatus && (
													<img
														className='ms-2'
														src='images/icons/edit.svg'
														alt='edit'
													/>
												)}
											</CustomButton>
										</div>
										{/* )} */}
									</div>
								</div>
								<IonCardHeader className='pt-0'>
									<IonList
										lines='none'
										className='bg-transparent d-flex flex-column gap-1'
									>
										<div className='d-flex gap-2'>
											{leadData?.categoryName && (
												<img
													src='https://oneandro-crm-static.s3.ap-south-1.amazonaws.com/prod/category/1704709911083-Life_Insurance.png'
													alt='insurance_type'
												/>
											)}
											<p className='text-grey-light   fs-14 mb-0'>
												{leadData?.categoryName}
												{'   '}
												{leadData?.categoryName && (
													<span className='dot-symbol mx-1'></span>
												)}
												{'   '}
												{/* {leadData?.purpose_name} */}
											</p>
										</div>
										{/* <div className="d-flex gap-2">
                      <p className="text-grey-light   fs-14 mb-0">
                        {t("Loan Amount")}{" "}
                        <span
                          className="fs-14 sw-600"
                          style={{ color: "#66CC66" }}
                        >
                          ₹{" "}
                        </span>
                        {lead?.loan_amount
                          ? InputFormatCurrencyValue(lead?.loan_amount)
                          : "NA"}
                      </p>
                    </div> */}
									</IonList>
									<div className='d-flex gap-3'>
										<CustomButton
											//   href={` ${
											//     isBrowser && is_dialler
											//       ? "javascript:void(0)"
											//       : "tel:+91" + umData?.mobile_no
											//   }`}
											className='flex-grow-1 py-1 fs-16 dma-btn'
											onClick={handleCallToLead}
											//   disabled={getTokenFromUrl ? true : false}
										>
											<img
												src='/images/icons/teams/call.svg'
												alt='call'
												className='me-2'
											/>
											{true
												? '+91 ' + (maskMobile(leadData?.mobile_no) || '')
												: t('Call')}
										</CustomButton>
										<CustomButton
											href={
												'https://api.whatsapp.com/send?phone=' +
												leadData?.mobile_no
												// umData?.mobile_no
											}
											//   disabled={getTokenFromUrl ? true : false}
											className='flex-grow-1  py-1 fs-16 dma-btn'
										>
											<img
												src='/images/icons/leads/whatsapp.svg'
												alt='call'
												className='me-1'
											/>
											{t('WhatsApp')}
										</CustomButton>
										{/* {
                        <CustomButton
                        className="dma-btn"
                        style={{
                          display: "flex",
                          alignSelf: "center",
                          height: "100%",
                          justifyContent: "center",
                        }}
                      onClick={() => setShareModalOpen(true)}
                      >
                        <span
                          style={{
                            width: "32px",
                            // height: "32px",
                            display: "flex",
                            borderRadius: "50px",
                            background: "#F3F3F3",
                            padding: "3px 2px",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <img
                            src="/images/icons/teams/view-dots.svg"
                            alt="dots"
                          />
                        </span>
                      </CustomButton>
                    } */}
										{/* {isShareModalOpen &&
                        <ShareModal
                        isOpen={isShareModalOpen}
                        onClose={() => setShareModalOpen(false)}
                        // customerEmail={leadData?.email}
                        onSendMail={handleSendMail}
                        // onShareLeadProfile={handleShareLeadProfile}
                      />
                    } */}

										{/* {
                      ![
                        LeadStatus.RAW,
                        LeadStatus.GENERATED,
                        LeadStatus.BUREAU,
                      // ].includes(leadInfo?.applicationStatus) && (
                      ].includes(leadData?.updateStatus) && (
                        // umData?.showdownload_report ? (
                        <CustomButton
                          className="dma-btn"
                          style={{
                            display: "flex",
                            alignSelf: "center",
                            height: "100%",
                            justifyContent: "center",
                          }}
                        // onClick={() => setShareModalOpen(true)}
                        >
                          <span
                            style={{
                              width: "32px",
                              // height: "32px",
                              display: "flex",
                              borderRadius: "50px",
                              background: "#F3F3F3",
                              padding: "3px 2px",
                              justifyContent: "center",
                              alignContent: "center",
                            }}
                          >
                            <img
                              src="/images/icons/teams/view-dots.svg"
                              alt="dots"
                            />
                          </span>
                        </CustomButton>
                      )
                      // ) : (
                      //   ""
                      // )
                    } */}
									</div>
								</IonCardHeader>
							</IonCard>
							<Divider />
							{/* {isButtonNeeded && ( */}
							<IonCard className='shadow-none  bg-web-white px-md-2 ion-card-detail'>
								<IonCardContent className='text-center continue-tile'>
									<p className='mb-3 fs-16 text-black fw-600'>
										{t('Continue where we left off.')}
									</p>
									<CustomButton
										expand='block'
										onClick={() => {
											onProceedClick();
										}}
										className='btn-blue dashbord-buttons lead-detail-buttons'
										title={t('Proceed')}
									/>
									{/* {!infoActivity?.activities &&
											lead?.lead_status === LeadStatus.GENERATED && (
												<CustomButton
													expand='block'
													className='dashbord-buttons lead-detail-buttons mt-3'
													//  expand='block's
													fill='outline'
													onClick={() => {
														dispatch(
															leadFlow({
																...leadInfo,
																isBulk: false,
																addDisposition: true,
																leadId: lead?.id,
															})
														);
														history.push('/update-status', [leadInfo?.id]);
														// history.push('/add-lead-disposition');
													}}
												>
													{t('Add Disposition')}
												</CustomButton>
											)} */}
								</IonCardContent>
							</IonCard>
							{/* ) */}
							{/* } */}
							{/* {(leadStatus == LEAD_STATUS.LOGIN_LABEL ||
                leadInfo?.leadStatus == LEAD_STATUS.SANCTIONED_LABEL ||
                leadInfo?.leadStatus == LEAD_STATUS.DISBURSED_LABEL) && (
                  <IonCard className="shadow-none  bg-web-white px-md-2">
                    <IonCardContent className="text-center continue-tile">
                      <p className="mb-3 fs-16 text-black fw-600">
                        {t("View all applications")}
                      </p>
                      <CustomButton
                        expand="block"
                        //   onClick={() => {
                        //     new Promise(function (myResolve) {
                        //       setTimeout(function () {
                        //         myResolve(history.push("/lender-specific-info"));
                        //       }, 10);
                        //     });
                        //   }}
                        className="btn-blue dashbord-buttons lead-detail-buttons"
                        title={t("View")}
                      />
                    </IonCardContent>
                  </IonCard>
                )} */}
							<Divider />
							{/* {dispution && ( 
                <IonCard className="shadow-none p-0 mt-3 px-md-2 ion-card-detail ionc_padding">
                  <IonCardContent className="text-center ion-no-padding">
                    <CustomButton
                      expand="block"
                      fill="solid"
                      className="rounded-3"
                      title={t("Continue")}
                    />
                    <CustomButton
                      expand="block"
                      fill="outline"
                      className="rounded-3 mt-3 secondary-btn"
                    //   onClick={() => history.push("/add-lead-disposition")}
                      title={t("Add Disposition")}
                    />
                  </IonCardContent>
                </IonCard>
               )}  */}
							{/* <Divider /> */}
							<div className='py-3 bg-web-white px-3 ionic-card-bagroundColor'>
								<h4 className='fw-bold fs-20'>{t('Lead Info')}</h4>

								<div className='mt-2 d-flex justify-content-between'>
									<p className='mb-0 fs-14'>{t('Lead Created')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{FormatDate(leadData?.createdDate, 'dd mmm yyyy') ||
											t('NA')}
									</p>
								</div>
								<hr className='w-100 '></hr>
								<div className='mt-2 d-flex justify-content-between'>
									<p className='mb-0 fs-14'>{t('Source')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{/* {findSource(leadData?.lead_status)
                      ? findSource(leadData?.lead_status)
                      : "NA"} */}
										{capFirstLetter(leadData?.lead_status)}
									</p>
								</div>

								<CustomButton
									fill='clear'
									// onClick={history.push('/insurance-lead-about')}
									//   onClick={() => {
									//     const myPromise = new Promise(function (myResolve) {
									//       setTimeout(function () {
									//         myResolve(
									//           history.push("/lead-about", {
									//             ...location.state,
									//             leadData: lead,
									//           })
									//         );
									//       }, 10);
									//     });
									//     myPromise.then(() => {
									//       dispatch(
									//         leadFlow({
									//           ...leadInfo,
									//           leadData: lead?.leadId,
									//         })
									//       );
									//     });
									//   }}
									className='my-3 mb-0 fw-bold fs-14 ion-no-padding'
									onClick={() => {
										history.push('/insurance-lead-about');
									}}
								>
									<img
										src='/images/icons/teams/info.svg'
										alt='info'
										className='me-2'
									/>
									<span className='text-transform-none'>
										{t('See Profile for More Details')}
									</span>
								</CustomButton>
							</div>
							{isShowLeadApplicationCard && (
								<InsuranceLeadApplications
									bankData={bankDetails}
									applicationStatusData={applicationStatusData}
									selectedApplicationStatus={selectedApplicationStatus}
									selectApplicationStatusValue={selectApplicationStatusValue}
								/>
							)}
							<Divider />
							<InsuranceActivitySection
								activityList={infoActivity}
								applicationId={applicationId}
							/>
							<Divider />
							<div className='ion-padding bg-web-white border-md-radius-bottom ionic-card-bagroundColor'>
								<h4 className='fw-bold fs-20'>{t('Comments')}</h4>

								<IonItem
									className='my-3 ion-no-padding'
									lines='none'
								>
									<div className='d-flex flex-column  w-100 position-relative'>
										{/* <IonLabel className='d-block mb-2'>
											{t('Add Comment')}
										  </IonLabel>  */}
										<div className='d-flex align-items-center bg-light-grey textarea'>
											<IonTextarea
												className='custom bg-transparent ps-3 mt-0 '
												value={comment}
												maxlength={300}
												rows={1}
												placeholder={t('Add Comment').toString()}
												onIonInput={(e: any) => setComment(e.detail.value)}
											></IonTextarea>
											{comment?.trim().length > 0 && (
												<CustomButton
													style={{ color: '#0165E1' }}
													className={'fs-14 m-0'}
													fill={'none'}
													onClick={() => {
														addComment();
														// window.location.reload();
													}}
												>
													{t('Submit')}
												</CustomButton>
											)}
										</div>
									</div>
								</IonItem>
								{commentList.length === 0 && (
									<div className='d-flex mt-4 justify-content-center align-items-center flex-column'>
										<img
											src='/images/icons/no-comment.svg'
											alt='no data'
											width={54}
											height={48}
										/>
										<p className='fs-15 fw-500 mb-0 mt-3 text-grey'>
											{t('No comments added yet')}
										</p>
									</div>
								)}
								{commentList?.length > 0 && (
									<h6 className='fw-bold fs-16 fs-500'>
										{/* {commentList[0]?.comment ? commentList[0]?.comment : "NA"} */}
										{newComment}
									</h6>
								)}
								{commentList.length > 0 && (
									<>
										<div className='mt-2 d-flex justify-content-start gap-3'>
											<p className='mb-0 fs-12 fw-500 text-grey'>
												By{' '}
												{/* {commentList[0]?.commented_by_name
                          ? commentList[0]?.commented_by_name
                          : "Unknown"} */}
												{commentList[0]?.commented_by_name}
												<span className='mx-2'>•</span>
												{FormatDate(commentList[0]?.createdAt, 'dd mmm yyyy')}
												<span className='mx-2'>•</span>
												<span>
													{FormatDate(commentList[0]?.createdAt, 'hh:MM TT')}
												</span>
											</p>
										</div>

										<CustomButton
											fill='clear'
											onClick={() => {
												history.push({
													pathname: '/insurance-lead-comments',
													state: { applicationId: applicationId },
												});
												// const myPromise = new Promise(function (myResolve) {
												//   setTimeout(function () {
												//     myResolve(history.push("/insurance-lead-comments"));
												//   }, 10);
												// });
												// myPromise.then(() => {
												//   dispatch(
												//     leadFlow({
												//       ...leadInfo,
												//       leadId: lead?.id,
												//       update: leadInfo?.update + 1 || 1,
												//     })
												//   );
												// });
											}}
											className='my-3 mb-0 fw-bold fs-14 ion-no-padding'
										>
											<img
												src='/images/icons/teams/info.svg'
												alt='info'
												className='me-2'
											/>
											{t('See All Comments')}
										</CustomButton>
									</>
								)}
							</div>
						</div>
					</div>
				</IonContent>
			</IonPage>
		</>
	);
};
export default LeadDetailsInsurance;
