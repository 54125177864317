import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getLenderUserInfo,
	getProfessionalDetailsMaster,
} from '../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { IonContent, IonFooter } from '@ionic/react';
import { OfferRejectedMsg } from '../OfferRejectedMsg/OfferRejectedMsg';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import {
	ContinueLaterPopUp,
	FormatDate,
	checkFieldsValidToContinue,
	fetchStateCityByPincode,
	showToastAlert,
} from '../../../helpers/functions';
import StateCity from '../../../components/common/StateCity';
import { t } from 'i18next';
import DropDownModal from '../../../components/common/custom-dropdown/DropDownModal';
import { getLeadUmDetails, saveLenderSpcfInfo } from '../../../store/reducers/lead/leadSlice';
import { LenderNameForAPICall } from '../../../helpers/lenders';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { useHistory } from 'react-router';
import { CustomModalLeadStatus } from '../../../components/common';

const MoneyTapForm = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { leadFlowData, selectedLender } = useSelector(
		(state: any) => state?.lead
	);

	const [formFields, setFormFields] = useState([]);
	const [formValidationSchemas, setFormValidationSchemas] = useState(
		yup.object()
	);
	const [pinCodeData, setPinCodeData] = useState<any>([]);
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const [dropdownMasterData, setDropdownMasterData] = useState<any>({});
	const [continueLaterValue, setContinueLaterValue] = useState({});
	const [modalPageClose, setModalPageClose] = useState(false);
    const [leadStatusModalOpen, setLeadStatusModalOpen] = useState(false);
    const [applicationData, setApplicationData] = useState<any>();

	const {
		setValue,
		trigger,
		getValues,
		handleSubmit,
		control,
		reset,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(formValidationSchemas),
	});

	useEffect(() => {
		if (location.pathname !== '/lead-lender-specific-form-steps') {
			return;
		}
		getLeadData();
	}, [location.pathname]);

	useEffect(() => {
		reset(continueLaterValue);
	}, [continueLaterValue]);

    const onLeadStatusModalClose = () => {
		setLeadStatusModalOpen(false);
	};

	const getLeadData = async () => {
		await dispatch(
			getLenderUserInfo({
				param: leadFlowData?.leadId,
				data: {
					lender_name: 'moneytap',
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status && Object.keys(result.data).length) {
					const schemaBuilderObject: any = {};
					result?.data.map((i: any) => {
						setContinueLaterValue((old) => {
							return {
								...old,
								[i?.name]: i?.value,
							};
						});
					});
					result?.data?.forEach((formKeyName: any) => {
						if (formKeyName.type === 'dropdownSelect') {
							dispatch(getProfessionalDetailsMaster({ param: formKeyName.key }))
								.then(unwrapResult)
								.then((dropdownResponse: any) => {
									if (dropdownResponse?.status) {
										setDropdownMasterData((masterData: any) => {
											return {
												...masterData,
												[formKeyName.name]: dropdownResponse.data,
											};
										});
									}
								});
						}
						if (formKeyName?.is_required) {
							if (formKeyName?.is_comma_separated) {
								schemaBuilderObject[formKeyName.name] = yup.number();
								schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
									formKeyName.name
								]
									.typeError(
										formKeyName.placeholder.replaceAll('*', '') +
											' must be a number'
									)
									.required(
										typeof formKeyName?.required_error_message === 'string' &&
											formKeyName?.required_error_message
											? formKeyName?.required_error_message
											: formKeyName.placeholder.replaceAll('*', '') +
													' is a required field'
									);
								if (formKeyName?.min) {
									schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
										formKeyName.name
									].min(
										formKeyName['min'],
										`${formKeyName.placeholder.replaceAll(
											'*',
											''
										)} should not be zero or empty`
									);
								}
							} else if (formKeyName?.type === 'dropdownSelect') {
								schemaBuilderObject[formKeyName.name] = yup
									.number()
									.required(
										typeof formKeyName?.required_error_message === 'string' &&
											formKeyName?.required_error_message
											? formKeyName?.required_error_message
											: formKeyName.placeholder.replaceAll('*', '') +
													' is a required field'
									);
							} else if (formKeyName.type === 'text') {
								schemaBuilderObject[formKeyName.name] = yup.string();
								schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
									formKeyName.name
								].required(
									typeof formKeyName?.required_error_message === 'string' &&
										formKeyName?.required_error_message
										? formKeyName?.required_error_message
										: formKeyName.placeholder.replaceAll('*', '') +
												' is a required field'
								);
							} else {
								schemaBuilderObject[formKeyName.name] = yup
									.string()
									.required(
										typeof formKeyName?.required_error_message === 'string' &&
											formKeyName?.required_error_message
											? formKeyName?.required_error_message
											: formKeyName.placeholder.replaceAll('*', '') +
													' is a required field'
									);
							}
						}
					});

					const pincodeData = result?.data.find(
						(i: any) => i.name === 'pincode'
					)?.value;
					if (pincodeData) {
						fetchStateCityByPincode(
							setValue,
							'city',
							'state',
							setPinCodeData,
							dispatch,
							pincodeData,
							trigger
						);
					}
					setFormValidationSchemas(
						formValidationSchemas.concat(
							yup.object().shape(schemaBuilderObject)
						)
					);
					setFormFields(result.data);
				}
			});
		await dispatch(getLeadUmDetails({param: leadFlowData?.leadId}))
		.then(unwrapResult)
		.then ((result : any)=>{
			setContinueLaterValue((old)=>{
				return {
					...old,
					net_monthly_income: result?.proinfo.net_monthly_income.toString(),
				}
			})
		})	
	};

	const onSubmit = async (data: any, flag: any = '') => {
		const extrafields: any = {};
		formFields?.forEach((field: any) => {
			if (field.type === 'dropdownSelect') {
				const selectedOption = dropdownMasterData[field?.name]?.filter(
					(item: any) => item.id === data[field?.name]?.toString()
				);
				extrafields[field.name] = data[field.name]
					? selectedOption[0]?.value
					: '';
			}else{

				extrafields[field.name] = data[field.name];
			}
		});
		const req: any = {
			lender_spf_details: {
				orgCity: pinCodeData[0]?.Region,
				orgState: pinCodeData[0]?.State,
				net_monthly_income: getValues('net_monthly_income'),
				cibil_consent: {
					status: 'Y',
					date: FormatDate(new Date(), "UTC:yyyy-mm-dd'T'HH:MM:ss'.969Z'"),
				},
				...extrafields,
			},
			step: 'step1',
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.MONEYTAP,
			lead_id: leadFlowData?.leadId,
			isNewLead: isValid,
			continue_later: flag === 'continue_later' ? true : false,
		};
		await dispatch(saveLenderSpcfInfo({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					if (flag === 'continue_later') {
						setShowOfferErrMsg('');
					} else {
						setApplicationData(result?.data);
						setLeadStatusModalOpen(true);
						// setShowOfferErrMsg(
						// 	`${result?.message} ${
						// 		result?.data?.customerid
						// 			? `Lead Id - ${result?.data?.customerid}`
						// 			: ''
						// 	}`
						// );
					}
				} else {
					setShowOfferErrMsg(result?.error?.message || result?.message);
				}
			});
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
							<form onSubmit={handleSubmit(onSubmit)}>
								{formFields.map(
									(
										{ name, placeholder, type, value, is_required }: any,
										index: any
									) => {
										const errorText: any =
											errors && Object.keys(errors).length > 0 && errors;
										if (type === 'text') {
											return (
												<Controller
													key={`controller-${index}`}
													name={name}
													control={control}
													render={({ field: { onChange, onBlur, name } }) => (
														<>
															<CustomInput
																type={type}
																label={t(placeholder)}
																onBlur={onBlur}
																id={name}
																disabled={value ? true : false}
																onChange={(e: any) => {
																	onChange(e);
																	if (name === 'pincode') {
																		if (e?.target?.value?.length === 6) {
																			fetchStateCityByPincode(
																				setValue,
																				'city',
																				'state',
																				setPinCodeData,
																				dispatch,
																				e.target.value,
																				trigger
																			);
																		}
																	} else {
																		// setValue('state', '');
																		// setValue('city', '');
																		// setPinCodeData([]);
																	}
																}}
																value={getValues(name)}
															/>
															{name === 'pincode' && (
																<StateCity
																	cityInputName='city'
																	control={control}
																	setValue={setValue}
																	pinCodeData={pinCodeData}
																	trigger={trigger}
																	getValues={getValues}
																/>
															)}
														</>
													)}
												/>
											);
										} else if (type === 'dropdownSelect') {
											return (
												<div key={index + '-dropdownSelect'}>
													<>
														<Controller
															key={`controller-${index}`}
															control={control}
															render={({
																field: { onChange, onBlur },
															}: any) => (
																<DropDownModal
																	label={
																		t(`${placeholder}`) +
																		(is_required ? ' *' : '')
																	}
																	data={
																		dropdownMasterData[name]
																			? dropdownMasterData[name]
																			: []
																	}
																	name={name}
																	onChange={onChange}
																	onBlur={onBlur}
																	className={
																		errorText[name]?.message
																			? 'ion-invalid ion-touched mb-3'
																			: 'mb-3'
																	}
																	errors={t(errorText[name]?.message as string)}
																	value={value ? value : getValues(name)}
																	// disabled={
																	//     disabledFields.includes(name) ? true : false
																	// }
																/>
															)}
															name={name}
														/>
													</>
												</div>
											);
										}
									}
								)}
							</form>
							<div className={` d-none d-md-block`}>
								<CustomButton
									className='w-100 fw-bold dashboard-add-lead-button mb-3'
									expand='block'
									fill='outline'
									title={t('Continue Later')}
									onClick={() => {
										if (checkFieldsValidToContinue(errors)) {
											setModalPageClose(true);
										} else {
											showToastAlert({
												type: 'error',
												message: 'Please fix above errors',
											});
										}
									}}
								/>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				)}

				<CustomModal
					needIonContentWraper={false}
					isOpen={modalPageClose}
					initialBreakpoint={undefined}
					handleClose={() => setModalPageClose(false)}
					breakpoints={[]}
					className='height-auto confirm-popup-middle continue-later'
					modalContent={ContinueLaterPopUp(
						() => {
							setModalPageClose(false);
						},
						() => {
							new Promise(function (myResolve) {
								setTimeout(function () {
									myResolve(history.push('/dashboard/lead'));
									onSubmit(getValues(), 'continue_later');
									reset();
									setModalPageClose(false);
								}, 30);
							});
						},
						'Cancel',
						'Continue Later',
						'className',
						'Are You Sure You Want to Exit?',
						`${t('You can continue later from where you left off.')}`
					)}
					needCross={false}
					needArrow={false}
				/>
			</IonContent>
            {leadStatusModalOpen && (
				<CustomModalLeadStatus
					isOpen={leadStatusModalOpen}
					applicationNo={applicationData?.customerid}
					copyLink={applicationData?.redirectionUrl}
					handleClose={() => onLeadStatusModalClose()}
				/>
			)}
			{!showOfferErrMsg && (
				<IonFooter className='d-block d-md-none position-relative'>
					<div
						style={{
							backgroundColor: '#fff',
							zIndex: 2,
						}}
					>
						<div className={`ion-padding`}>
							<CustomButton
								className='w-100 fw-bold dashboard-add-lead-button mb-3'
								expand='block'
								fill='outline'
								title={t('Continue Later')}
								onClick={() => {
									if (checkFieldsValidToContinue(errors)) {
										setModalPageClose(true);
									} else {
										showToastAlert({
											type: 'error',
											message: 'Please fix above errors',
										});
									}
								}}
							/>
							<div className='proceed-btn'>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									// disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				</IonFooter>
			)}
		</>
	);
};

export default MoneyTapForm;
