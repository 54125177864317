//// @ts-nocheck


import { yupResolver } from '@hookform/resolvers/yup';
import { IonCard, IonCardContent, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { UploadWidget } from '../../components/common/custom_file_upload/custom_file_upload';
import CustomForm from '../../components/common/custom_form/custom_form.component';
import { FamilyFloaterForm } from '../../model/family_details_bean';
import family_floater_schema from './family_floater.schema';
import { Header } from '../../components/layout/header/Header';
import { REDIRECT } from '../../helpers/constant';





const familyFloaterFormKeys = [
  {
    name: 'relationship',
    //hbs change here
    type: 'text/combo',
    mandatory: true,
    placeholder: 'Relationship *',
    values: [
      { value: 'self', description: 'Self' },
      { value: 'spouse', description: 'Spouse' },
      { value: 'child', description: 'Child' },
      { value: 'parent', description: 'Parent' },
      { value: 'grandParent', description: 'Grand Parent' },
      { value: 'inLaw', description: 'In-Law (Father, Mother)' },
    ],
  },
  {
    name: "pincode",
    type: "text",
    placeholder: "Pin Code",
  },
  {
    name: 'gender',
    //hbs change here
    type: 'text/combo',
    mandatory: true,
    placeholder: 'Gender *',
    values: [
      { value: 'male', description: 'Male' },
      { value: 'female', description: 'Female' },
      { value: 'transgender', description: 'Transgender' },
    ],
  },
  {
    name: "dateOfBirth",
    type: "date",
    placeholder: "Date of Birth"
  },
  {
    name: "goodHealth",
    //hbs change here
    type: "text/combo",
    mandatory: true,
    // hidden: hideGoodHealth,
    // onChange:onChangeGoodHealth,
    placeholder: "Are you of Good health?",
    values: [
      { value: "yes", description: "Yes" },
      { value: "no", description: "No" },
    ],
  },
  {
    name: 'existingHealthIssues',
    //hbs change here
    type: 'text/multicombo',
    mandatory: true,
    placeholder: 'Existing Health Issues',
    values: [
      { value: 'diabetes', description: 'Diabetes' },
      { value: 'bp', description: 'Blood Pressure' },
      { value: 'surgery', description: 'Any Surgery' },
      { value: 'thyroid', description: 'Thyroid' },
      { value: 'asthma', description: 'Asthma' },
      { value: 'others', description: 'Others' },
    ],
  },
  {
    name: "goodHealthDetails",
    //hbs change here
    type: "textarea",
    // hidden: hideGoodHealthDetails,
    placeholder: "Existing Health Condition Details",
  },
];



interface Props {
  inpCallback?: () => void;
  familyFloaterForm?: FamilyFloaterForm;
  // userId: any;
  // setShowBasicInfoForm: any;
  // getUserDetailsInfo: any;
}


export const FamilyFloaterDetails: React.FC<Props> = ({ familyFloaterForm, inpCallback }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [validateForm, setValidateForm] = useState<boolean>();





  const { control, trigger, handleSubmit, setValue,
    getValues, formState: { errors, isValid } } = useForm<FamilyFloaterForm>({
      resolver: yupResolver(family_floater_schema),
      defaultValues: familyFloaterForm,
    });
  // const [dateOfBirth, setDateOfBirth] = useState(null);

  // const dmaSelector = useSelector((state: any) => state?.users?.dmaFlow);


  const onSubmit = async (data: FamilyFloaterForm) => {
    alert('x');

    // await getUsers();
    // if (isValid) {
    //   // Handle form submission here

    //   inpCallback();
    //   history.push(nextTabUrl);
    // }
  };

  return (
    <IonPage className='bg-web-white container-width-md container-spacing-md border-md-radius-top'>
      <Header
        className="card-header"
        name={t('Family Member Details')}
        needCross={true}
        backButtonDisabled={false}
        backButton={true}
        needArrowOnClick={() => {
          history.push("/lead-details");
        }}
      // backButton={true}
      // needArrow={true}
      // needArrowOnClick={() => {
      //   history.push(REDIRECT.DASHBOARD);
      // }}
      />


      {/* <IonContent className="custom-ion-content"> */}
      {/* <IonContent> */}
      <IonContent className='ion-padding-bottom'>

        {/* <div className='align-items-center col-6 col-md-3'> */}

        {/* <section
        className='overflowX-hidden'
      // style={{ height: 'calc(100vh - 20px)' }}
      > */}

        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonCard>
              <IonCardContent>
                <CustomForm formKeys={familyFloaterFormKeys} control={control} getValues={getValues} setValue={setValue} errors={errors} isValid={validateForm} trigger={trigger} />
              </IonCardContent>
            </IonCard>
            <div
              className='position-fixed w-100 bottom-0 start-0 px-3 pb-3'
              style={{ backgroundColor: '#fff' }}
            >
              <CustomButton
                className='btn-blue'
                type='submit'
                expand='block'
                title='Submit'
                onClick={() => { handleSubmit(onSubmit); setValidateForm(isValid); }}

              // disabled={!isValid}
              // title={t('Update')}
              // disabled={!isValid}
              />
            </div>
          </form>
        </div>
        {/* </section> */}
      </IonContent>
    </IonPage>
  );
}


