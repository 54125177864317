// import React from 'react';
import { useTranslation } from 'react-i18next';

const ScrollerTab = ({
	leadTypes,
	activeLeadType,
	handleTabLeadTypeChange,
}: any) => {
	const { t } = useTranslation();
	return (
		<div className='lead-label-scroll py-2 px-3 d-flex align-items-center w-100 overflowX-auto overflowY-hidden text-nowrap'>
			{leadTypes &&
				leadTypes?.map((item: any, index: number) => (
					<div
						key={index}
						className={`tab-chip fs-14 ${
							item.value === activeLeadType ? 'active' : ''
						}`}
						onClick={() => handleTabLeadTypeChange(item)}
					>
						<img
							src={item.icon}
							alt='icon'
							width={18}
							height={18}
							className='me-2'
						/>
						{t(item?.value)}
					</div>
				))}
		</div>
	);
};

export default ScrollerTab;
