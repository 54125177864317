import { REGEX } from '../../helpers/constant';
import * as yup from 'yup';


const addLeadScema = yup.object().shape({
	mobile: yup
		.string()
		.length(10, 'Mobile No. Should Be 10 Digits.')
		.required('Mobile is Required.')
		.matches(REGEX.INDIAN_MOBILE_NUMBERS, 'Please Enter a valid Mobile No.'),
	andro_lead_manager: yup
		.number()
		.min(1, 'Please choose Andro lead manager')
		.required('Andro lead manager is Required.'),
});

  export default addLeadScema;