/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	IonContent,
	IonImg,
	// IonPage,
	IonText,
	isPlatform,
	// createGesture,
	// Gesture,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';

import './DashboardHome.scss';
import { Header } from '../../../components/layout/header/Header';
import { DashboardBannerSlider } from './banner-slider/DashboardBannerSlider';
import { MyLeads } from './sections/MyLeads';
import { MyLoyalty } from './sections/MyLoyalty';
import { MyTools } from './sections/MyTools';
import { Products } from './sections/Products';
import { ActivityOptions } from './sections/ActivityOptions';
import { KnowledgeCenter } from './sections/KnowledgeCenter';
import { TopBrands } from './sections/TopBrands';
import { PartnersTestimonials } from './sections/PartnersTestimonials';
import { Menu } from '../../../components/layout/menu/Menu';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { InView } from 'react-intersection-observer';
import { Player } from '@lottiefiles/react-lottie-player';
import { CircularProgressBar } from '../../../components/common/custom-circular-progress-bar/CircularProgressBar';
import { Sidebar } from '../../../components/layout/sidebar/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import {
	dmaAbout,
	getUserSocialLinks,
	homePage,
	imgUrlUpdate,
	kycStep,
} from '../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { otpScreenShowPage } from '../../../store/reducers/app/appSlice';
import { USER_ROLES } from '../../../helpers/constant';
import {
	eventAttributes,
	getDownloadAgreeMent,
	notifyHandler,
} from '../../../helpers/functions';
import { leadBureauCustomer } from '../../../store/reducers/lead/leadSlice';
import { NotifyVisitors } from 'capacitor-plugin-notifyvisitors';
// import { AlertsSection } from './sections/AlertsSection';

const defaultSrc = '/images/icons/default/user-profile-icon.svg';

export const DashboardHome: React.FC = () => {
	const dispatch: any = useDispatch();
	const { t } = useTranslation();
	const history = useHistory<any>();
	const [productShow, setProductShow] = useState(false);
	const [testimonialShow, setTestimonialShow] = useState(false);
	const [loyalityShow, setLoyalityShow] = useState(false);
	const [socialLinks, setSocialLinks] = useState<any>();
	const [src, setSrc] = useState('');
	const homePageData = useSelector((state: any) => state?.users);
	const [appLanguageData] = useState(localStorage.getItem('i18nextLng'));

	// const appLanguageData = useSelector(
	// 	(state: any) => state?.users?.user?.lang?.code
	// );
	// const themeHeaderColor = useSelector((state:any) => state.app.themeHeaderColor);
	// const themeBannerColor = useSelector((state: any) => state.app.themeBannerColor);
	// const themeHeaderColor = localStorage.getItem('themeHeaderColor') || "#FFFFFF";
	// const themeBannerColor = localStorage.getItem('themeBannerColor') || "#F3F3F3";

	// Notify Visitors
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const userName = useSelector(
		(state: any) => state?.users?.homepage?.profile?.name
	);
	const userMobile = useSelector(
		(state: any) => state?.users?.user_details?.mobile
	);

	const isMigratedUser = useSelector(
		(state: any) => state?.users?.user_details?.migrate_flag
	);
	const entityType = useSelector(
		(state: any) => state?.users?.user_details?.entityType
	);
	const userLang = useSelector(
		(state: any) => state?.oneTimeScreen?.language?.name
	);
	console.log(userLang, 'Selected langgggggggggg');
	const userAttributes = {
		userId: String(userId),
		attributes: {
			name: userName,
			id: userId,
			mobile: userMobile,
			user_language: userLang,
			entityType:
				entityType === 'Individual'
					? 'DMA'
					: entityType === 'BTM'
					? 'employee'
					: entityType,
		},
	};
	console.log(
		'Entity Type during login:',
		userAttributes.attributes.entityType
	);
	useEffect(() => {
		if (isPlatform('android') || isPlatform('ios')) {
			notifyHandler('event', eventAttributes('homepage'));
			notifyHandler('user', userAttributes);
			const inAppData = {
				tokens: { name: userAttributes },
				customRules: { page: 'dashboard' },
			};

			NotifyVisitors.show(inAppData);
		} else {
			(window as any).nv('event', 'homepage', 10, 1);
			(window as any).nv('user', userId, userAttributes.attributes);
		}
	}, []);

	const customerInfo = useSelector(
		(state: any) => state?.lead?.leadBureauCustomerData
	);
	useEffect(() => {
		dispatch(otpScreenShowPage(false));
		dispatch(homePage())
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					if (response?.data?.scope_type) {
						dispatch(
							leadBureauCustomer({
								...customerInfo,
								scoreType: response?.data.scope_type,
								reSendCibilOtp: false,
							})
						);
					}
				}
			});
	}, []);

	useEffect(() => {
		getSocialLinks();
	}, [homePageData?.user_details?.id]);

	const getSocialLinks = async () => {
		await dispatch(getUserSocialLinks())
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					if (response?.data.length) {
						setSocialLinks(response?.data?.[0]);
					}
				}
			});
	};

	useEffect(() => {
		getUpdateUrl();
	}, [homePageData?.homepage?.profile?.url]);

	const getUpdateUrl = async () => {
		if (homePageData?.homepage?.profile?.url) {
			await dispatch(
				imgUrlUpdate({
					data: {
						url: homePageData?.homepage?.profile?.url,
						expire_time: 50000,
					},
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						setSrc(response?.url);
					}
				});
		} else {
			setSrc(defaultSrc);
		}
	};

	// gesture effect

	// useEffect(() => {
	// 	getureInit();
	// }, []);
	// const pageEl = useRef<HTMLInputElement>(null);
	// const getureInit = () => {
	// 	const card = pageEl.current;
	// 	if (card) {
	// 		const gesture: Gesture = createGesture({
	// 			el: card,
	// 			threshold: 15,
	// 			gestureName: 'my-gesture',
	// 			onEnd: (ev: any) => {
	// 				if (ev.deltaX > window.innerWidth / 2) {
	// 					CapacitorApp.exitApp();
	// 				} else if (ev.deltaX < -window.innerWidth / 2) {
	// 					/* CapacitorApp.exitApp(); */
	// 				}
	// 			},
	// 		});
	// 		gesture.enable();
	// 	}
	// };

	const redirectKyc = () => {
		const nextScreen = homePageData?.homepage?.registerationstage?.nextScreen;
		switch (nextScreen) {
			case 'KYC1':
				return true;
			case 'KYC2':
				return true;
			case 'KYC3':
				return true;
			case 'KYC4':
				return true;
			case 'E-SIGNING':
				return true;
			case 'DOWNLOAD':
				return true;
			case '':
				return false;
			default:
				return null;
		}
	};

	const redirectScreen = redirectKyc();

	const isDamUser = !!homePageData?.user_details?.roleType?.find(
		(item: string) => item === 'DMA'
	);

	return (
		<>
			<Menu
				name={homePageData?.homepage?.profile?.name}
				imgSrc={src}
			/>
			<div
				id='dashboard-main-content-menu'
				className='dashboard'
			>
				<Header
					type='dashboard'
					name={homePageData?.homepage?.profile?.name}
					src={src}
				// themeHeaderColor={themeHeaderColor}
				/>
				<div className='d-flex'>
					<Sidebar />
					<IonContent
						className={`dashboard-main dashboard-height dashboard-home-ribbon`}
					>
						{/* <div className='bg-web dashboard-container-spacing '> */}
						<DashboardBannerSlider data={homePageData?.homepage?.banners} />
						<div className='home-container-padding mx-0 bg-web dashboard-container-spacing'>
							<div className='dashboard-home-main-content bg-transparent-md'>
								<div className='pt-1'>
									<div className='top-line'></div>
								</div>
								<ActivityOptions />
								<div className='section-divider mb-4 mb-md-2'></div>
								<MyLeads />
								{/* <div className='section-divider mb-4 mb-md-2'></div>
								<AlertsSection/> */}
								<div className='section-divider mb-4 mb-md-2'></div>

								{/* <InView
							as='div'
							onChange={(inView) => {
								if (inView) {
									setEarning(true);
								}
							}}
						>
							{earning && <MyEarnings />}
						</InView> */}
								<div className='d-lg-flex flex-lg-row'>
									{![USER_ROLES.DMAUL].includes(
										homePageData?.user_details?.roleType?.[0]
									) ? (
										<div className='d-flex flex-column flex-lg-row gap-3 flex-50'>
											<InView
												className='my-loyality d-md-flex'
												as='div'
												onChange={(inView) => {
													if (inView) {
														setLoyalityShow(true);
													}
												}}
											>
												{loyalityShow && <MyLoyalty />}
											</InView>
										</div>
									) : (
										''
									)}
									{/* <div className='flex-50'> */}
									<MyTools data={homePageData?.homepage?.tools} />
									{/* </div> */}
								</div>
								<div className='section-divider mb-4 mb-md-2'></div>

								<InView
									as='div'
									onChange={(inView) => {
										if (inView) {
											setProductShow(true);
										}
									}}
								>
									{productShow && <Products />}
								</InView>

								<div className='section-divider mb-4 mb-md-2'></div>
								<TopBrands />

								<KnowledgeCenter />
								<div className='section-divider mb-4 mb-md-2'></div>
								<InView
									as='div'
									onChange={(inView) => {
										if (inView) {
											setTestimonialShow(true);
										}
									}}
								>
									{testimonialShow && (
										<PartnersTestimonials
											data={homePageData?.homepage?.testimonials}
										/>
									)}
								</InView>

								<section className='company-info bg-web-white border-md-radius-10'>
									<div className='pb-4'>
										<h3 className='fs-48 text-grey fw-900'>
											<IonImg
												className='mt-4'
												src='/images/icons/OneAndro_svg R logo.png'
												alt='logo'
												style={{ width: '150px' }}
											/>
										</h3>
										<div className='d-flex flex-column gap-0 gap-md-3 align-items-start align-items-md-center flex-md-row'>
											<IonText
												color='primary'
												className='d-block d-md-none'
											>
												{!appLanguageData ||
													(appLanguageData === 'en' && (
														<h3 className='fs-26 fw-900 pt-2 text-prinary'>
															{t(`INDIA'S`)} &nbsp;
															{t('LARGEST')}
														</h3>
													))}
												<h1 className='fs-26  fw-900 text-uppercase position-relative'>
													{!appLanguageData || appLanguageData === 'en' ? (
														<>
															{t(`L`)}
															<Player
																style={{
																	width: '31px',
																	display: 'inline-block',
																	position: 'absolute',
																	top: '-4px',
																}}
																autoplay={true}
																loop={true}
																controls={false}
																src='/images/icons/json/LoyaltyRewardsCoin.json'
															// style={{ height: 120 }}
															/>
															&nbsp; &nbsp; &nbsp;{t(`an`)} &nbsp;
															{t(`Distributor`)}
														</>
													) : (
														<>
															{t(`India's Largest Loan Distributor`)}
															<Player
																style={{
																	width: '31px',
																	display: 'inline-block',
																	position: 'absolute',
																	top: '-4px',
																}}
																autoplay={true}
																loop={true}
																controls={false}
																src='/images/icons/json/LoyaltyRewardsCoin.json'
															// style={{ height: 120 }}
															/>
														</>
													)}
												</h1>
											</IonText>
											<div className='d-none d-md-block'>
												<IonText
													color='primary'
													className='mt-2 '
												>
													<h1 className='fs-26  fw-900 text-uppercase position-relative'>
														{!appLanguageData || appLanguageData === 'en' ? (
															<>
																{t(`INDIA'S`)} &nbsp;
																{t('LARGEST')} &nbsp;
																{t(`L`)}
																<Player
																	style={{
																		width: '31px',
																		display: 'inline-block',
																		position: 'absolute',
																		top: '-4px',
																	}}
																	autoplay={true}
																	loop={true}
																	controls={false}
																	src='/images/icons/json/LoyaltyRewardsCoin.json'
																// style={{ height: 120 }}
																/>
																&nbsp; &nbsp; &nbsp;{t(`an`)} &nbsp;
																{t(`Distributor`)}
															</>
														) : (
															<>
																{t(`India's Largest Loan Distributor`)}
																<Player
																	className='fdfd'
																	style={{
																		width: '31px',
																		display: 'inline-block',
																		position: 'relative',
																		top: '3px',
																		left: '5px',
																	}}
																	autoplay={true}
																	loop={true}
																	controls={false}
																	src='/images/icons/json/LoyaltyRewardsCoin.json'
																// style={{ height: 120 }}
																/>
															</>
														)}
													</h1>
												</IonText>
											</div>
											{/* <IonText color='primary'>
												<h1 className='fs-26 mb-2 mb-md-0 fw-900 text-uppercase position-relative'>
													{t(`L`)}
													<Player
														style={{
															width: '31px',
															display: 'inline-block',
															position: 'absolute',
															top: '-4px',
														}}
														autoplay={true}
														loop={true}
														controls={false}
														src='/images/icons/json/LoyaltyRewardsCoin.json'
														// style={{ height: 120 }}
													/>
													&nbsp; &nbsp; &nbsp;{t(`an`)} &nbsp;
													{t(`Distributor`)}
												</h1>
											</IonText> */}
										</div>
										<div className='d-flex gap-3 pt-4 pb-2'>
											<h4>
												<a href={socialLinks?.facebook || '#'}>
													<IonImg
														className=''
														src='/images/icons/facebook.png'
														alt='facebook'
														style={{ width: '31px', height: '31px' }}
													/>
												</a>
											</h4>
											<h4>
												<a href={socialLinks?.twitter || '#'}>
													<IonImg
														className=''
														src='/images/icons/twitter.svg'
														alt='twitter'
														style={{ width: '31px', height: '31px' }}
													/>
												</a>
											</h4>
											<h4>
												<a href={socialLinks?.linkedin || '#'}>
													<IonImg
														className=''
														src='/images/icons/linkedin.png'
														alt='linkedin'
														style={{ width: '31px', height: '31px' }}
													/>
												</a>
											</h4>
											<h4>
												<a href={socialLinks?.youtube || '#'}>
													<IonImg
														className=''
														src='/images/icons/youtube.png'
														alt='youtube'
														style={{ width: '31px', height: '31px' }}
													/>
												</a>
											</h4>
											<h4>
												<a href={socialLinks?.instagram || '#'}>
													<IonImg
														className=''
														src='/images/icons/instagram.png'
														alt='instagram'
														style={{ width: '31px', height: '31px' }}
													/>
												</a>
											</h4>
										</div>
									</div>
								</section>
							</div>

							{/* </div> */}
						</div>

						{isDamUser &&
							homePageData?.homepage?.registerationstage &&
							isMigratedUser == 0 && (
								<div
									className={`complete-kyc-box bg-primary justify-content-md-center justify-content-${homePageData?.homepage?.registerationstage?.percentage < 0
											? 'around'
											: 'between'
										}`}
									onClick={() => {
										let redirectTo = '';

										if (redirectScreen) {
											const nextScreen: any =
												homePageData?.homepage?.registerationstage?.nextScreen;

											switch (nextScreen) {
												case 'E-SIGNING':
													redirectTo = '/agreement';
													dispatch(kycStep(nextScreen));
													history.push(
														redirectTo,
														{ from: 'dashboard' } // to show welcome screen if we go from dashboard
													);
													break;
												case 'DOWNLOAD':
													getDownloadAgreeMent(dispatch);
													break;
												default:
													dispatch(
														dmaAbout({
															RegisteredAs:
																homePageData?.user_details?.entityType,
														})
													);
													redirectTo = '/kyc';
													dispatch(kycStep(nextScreen));
													history.push(
														redirectTo,
														{ from: 'dashboard' } // to show welcome screen if we go from dashboard
													);
													break;
											}
										}
									}}
								>
									{homePageData?.homepage?.registerationstage?.percentage >=
										0 && (
											<div style={{ display: 'flex', marginRight: '12px' }}>
												<CircularProgressBar
													ringColor='var(--ion-color-secondary)'
													ringBgColor='#FFFFFF'
													strokeWidth='5'
													sqSize='50'
													value={
														homePageData?.homepage?.registerationstage
															?.percentage + '%'
													}
													percentage={
														homePageData?.homepage?.registerationstage?.percentage
													}
													textColor='white'
													textClass='fw-600'
												/>
											</div>
										)}
									{homePageData?.homepage?.registerationstage?.message && (
										<h4
											className={`fs-14 text-white m-0 ps-${homePageData?.homepage?.registerationstage?.percentage >
													0
													? 3
													: 0
												}`}
										>
											{t(homePageData?.homepage?.registerationstage?.message)}
										</h4>
									)}
									{redirectScreen && (
										<div
											className='ms-5'
											style={{ zIndex: 10 }}
										>
											<IonImg
												src='/images/icons/right arrow-white.svg'
												style={{ width: '12px' }}
											/>
										</div>
									)}
								</div>
							)}
					</IonContent>
				</div>
			</div>
		</>
	);
};
