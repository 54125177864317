import { IonItem, IonLabel, IonList } from '@ionic/react';
import {
	Camera,
	CameraDirection,
	CameraResultType,
	CameraSource,
} from '@capacitor/camera';
import { kycUserUploadImage } from '../../../store/reducers/user/userSlice';

export default function BankUploadDocuments({
	setDocTypeErrorMessage,
	docTypeErrorMessage,
	setOpenUploadModal,
	getValues,
	dispatch,
	unwrapResult,
	setValue,
	trigger,
	setImagePreviewUpload,
	imagePreviewUpload,
	urltoFile,
	isBrowser,
	clickImageFieldName,
	t,
}: any) {
	const handleUploadChange = async (
		type: string,
		fieldName: string,
		e: any
	) => {
		setOpenUploadModal(false);
		const formData = new FormData();
		// let getBase64String: any;
		let image: any;
		// let getOcrData: any;
		let getImageFileObject: any;
		switch (type) {
			case 'file':
				if (
					e.target.files[0].type === 'image/png' ||
					e.target.files[0].type === 'image/jpeg' ||
					e.target.files[0].type === 'application/pdf'
				) {
					setDocTypeErrorMessage({
						...docTypeErrorMessage,
						[fieldName]: '',
					});
					formData.append('profile', e.target.files[0]);
					dispatch(kycUserUploadImage({ data: formData }))
						.then(unwrapResult)
						.then(async (response: any) => {
							if (response?.status) {
								if (getValues('doc')?.length > 0) {
									// getBase64String = await getBase64FromFileObject(
									// 	e.target.files[0]
									// );
									const updatedDoc = getValues('doc')?.map((fn: any) => {
										if (fn.key === fieldName) {
											return {
												...fn,
												fileName: response?.url,
												originalname: response?.file?.originalname,
												// blob: getBase64String,
											};
										} else {
											return fn;
										}
									});
									setValue('doc', updatedDoc);
								}
								setValue(`${fieldName}`, response?.url);
								trigger(`${fieldName}`);
								setImagePreviewUpload({
									...imagePreviewUpload,
									[fieldName]: true,
								});
							}
						});
					break;
				} else {
					setDocTypeErrorMessage({
						...docTypeErrorMessage,
						[fieldName]:
							'Uploaded file is invalid. Only JPG/JPEG, PNG and PDF files are allowed.',
					});

					break;
				}
			case 'photo':
				image = await Camera.getPhoto({
					quality: 50,
					allowEditing: false,
					resultType: CameraResultType.Uri,
					source: CameraSource.Photos,
				});

				getImageFileObject = await urltoFile(
					image.webPath,
					`${Math.random() * 100000000000000000}.${image.format}`,
					image.format
				);
				formData.append('profile', getImageFileObject);
				dispatch(kycUserUploadImage({ data: formData }))
					.then(unwrapResult)
					.then(async (response: any) => {
						if (response?.status) {
							if (getValues('doc')?.length > 0) {
								// getBase64String = await getBase64FromFileObject(
								// 	getImageFileObject
								// );
								const updatedDoc = getValues('doc')?.map((fn: any) => {
									if (fn.key === fieldName) {
										return {
											...fn,
											fileName: response?.url,
											originalname: response?.file?.originalname,
											// blob: getBase64String,
										};
									} else {
										return fn;
									}
								});
								setValue('doc', updatedDoc);
							}
							setValue(`${fieldName}`, response?.url);
							setImagePreviewUpload({
								...imagePreviewUpload,
								[fieldName]: true,
							});
						}
					});
				break;
			case 'camera':
				image = await Camera.getPhoto({
					quality: 50,
					allowEditing: false,
					resultType: CameraResultType.Uri,
					source: CameraSource.Camera,
					direction: CameraDirection.Rear,
				});
				getImageFileObject = await urltoFile(
					image.webPath,
					`${Math.random() * 100000000000000000}.${image.format}`,
					image.format
				);
				formData.append('profile', getImageFileObject);
				dispatch(kycUserUploadImage({ data: formData }))
					.then(unwrapResult)
					.then(async (response: any) => {
						if (response?.status) {
							if (getValues('doc')?.length > 0) {
								// getBase64String = await getBase64FromFileObject(
								// 	getImageFileObject
								// );
								const updatedDoc = getValues('doc')?.map((fn: any) => {
									if (fn.key === fieldName) {
										return {
											...fn,
											fileName: response?.url,
											originalname: response?.file?.originalname,
											// blob: getBase64String,
										};
									} else {
										return fn;
									}
								});
								setValue('doc', updatedDoc);
							}
							setValue(`${fieldName}`, response?.url);
							setImagePreviewUpload({
								...imagePreviewUpload,
								[fieldName]: true,
							});
						}
					});
				break;
			default:
				break;
		}
	};
	return (
		<>
			<IonList>
				{!isBrowser && (
					<IonItem lines='full'>
						<img
							src='/images/icons/kyc/Image-upload.svg'
							alt='Upload Photo'
						/>
						<IonLabel>
							<h2
								className='fs-16 ps-3 text-dark'
								onClick={(e) => {
									handleUploadChange('photo', clickImageFieldName, e);
								}}
							>
								{t('Upload Photo')}
							</h2>
						</IonLabel>
					</IonItem>
				)}
				<IonItem
					lines='full'
					className='cursor-pointer'
				>
					<img
						src='/images/icons/kyc/upload-image-icon.svg'
						alt='Upload Photo'
					/>
					<IonLabel>
						<label
							htmlFor='upload-photo'
							className='cursor-pointer'
						>
							<h2 className='fs-16 ps-3 text-dark'>{t('Upload Document')}</h2>
						</label>
						<input
							accept='image/png, image/jpeg ,application/pdf'
							type='file'
							id='upload-photo'
							onChange={(e) => {
								handleUploadChange('file', clickImageFieldName, e);
							}}
						/>
					</IonLabel>
				</IonItem>
				{!isBrowser && (
					<IonItem lines='full'>
						<img
							src='/images/icons/kyc/camera-icon.svg'
							alt='Upload Photo'
						/>
						<IonLabel>
							<h2
								className='fs-16 ps-3 text-dark'
								onClick={(e) => {
									handleUploadChange('camera', clickImageFieldName, e);
								}}
							>
								Take Photo
							</h2>
						</IonLabel>
					</IonItem>
				)}
			</IonList>
		</>
	);
}
