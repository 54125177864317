//// @ts-nocheck


import { IonItem, isPlatform } from '@ionic/react';
import { CustomButton } from '../components/common/custom-button/CustomButton';
import {
	INAPPCONFIG,
	LeadSourceEnum,
	// LeadStatus,
	REGEX,
	StatusNum,
} from './constant';
import { LEAD_STATUS } from './constant';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from 'dateformat';
import { unwrapResult } from '@reduxjs/toolkit';

import { Share } from '@capacitor/share';

import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';



export const ShareData = (title, text, url, file) => {
	if (isPlatform('android') || isPlatform('ios')) {
		Share.share({
			title: title,
			url: url,
			text: text,
			files: file,
		});
	} else {
		if (navigator.share) {
			navigator
				.share({
					title: title,
					url: url,
					text: text,
				})
				.then(() => console.log('Successful share'))
				.catch((error) => console.log('Error sharing', error));
		}
	}
};

export const FormatDate = (text, format) => {
	if (text) {
		return dateFormat(text, format);
	}
};
  
export function dataURLtoFile(dataurl, filename) {
	// only works for dataURL, not for other types of url.
	const arr = dataurl.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[arr.length - 1]);
	let n = bstr.length;	
	const u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], filename, { type: mime });
}

export async function urltoFile(url, filename, mimeType) {
	// works for any type of url, (http url, dataURL, blobURL, etc...)
	// return a promise that resolves with a File instance
	if (url.startsWith('data:')) {
		var arr = url.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[arr.length - 1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		var file = new File([u8arr], filename, { type: mime || mimeType });
		return Promise.resolve(file);
	}
	return fetch(url)
		.then((res) => res?.arrayBuffer())
		.then((buf) => new File([buf], filename, { type: 'image/' + mimeType }));
}
//Usage example:
// urltoFile('data:text/plain;base64,aGVsbG8=', 'hello.txt', 'text/plain').then(
// 	function (file) {
// 	}
// );

export const nFormatter = (num) => {
	if (num >= 1000000000) {
		return (
			(num / 1000000000).toFixed(1).replace(/\.0$/, '') +
			"<span class='fs-10'> Cr</span>"
		);
	}
	if (num >= 100000) {
		return (
			(num / 100000).toFixed(1).replace(/\.0$/, '') +
			"<span class='fs-10'> L</span>"
		);
	}
	if (num >= 1000) {
		return (
			(num / 1000).toFixed(1).replace(/\.0$/, '') +
			"<span class='fs-10'> K</span>"
		);
	}
	return num;
};

export const PageExitPopUp = (
	handleClose,
	exitOnClick,
	cancelButtonTitle,
	confirmButtonTitle,
	className,
	header,
	subHeader
) => {
	const { t } = useTranslation();
	return (
		<div className={`wrapper py-4 ${className}`}>
			<IonItem lines='none'>
				<div className='d-flex flex-column'>
					<h5
						className='mx-0 fs-18 fw-900'
						dangerouslySetInnerHTML={{ __html: t(header) }}
					/>
					<p
						className='fs-14 mb-2'
						style={{ color: 'var(--text-dark-grey)' }}
					>
						{t(subHeader)}
					</p>
				</div>
			</IonItem>
			<div className='d-flex justify-content-center gap-3 px-3'>
				<CustomButton
					onClick={handleClose}
					className='w-50'
					fill='outline'
					title={t(cancelButtonTitle)}
				/>
				<CustomButton
					className='w-50'
					fill='solid'
					onClick={exitOnClick}
					title={t(confirmButtonTitle)}
				/>
			</div>
		</div>
	);
};
export const ContinueLaterPopUp = (
	handleClose,
	exitOnClick,
	cancelButtonTitle,
	confirmButtonTitle,
	className,
	header,
	subHeader
) => {
	const { t } = useTranslation();
	return (
		<div className={`wrapper py-4 ${className}`}>
			<IonItem lines='none'>
				<div className='d-flex flex-column'>
					<h5
						className='mx-0 fs-18 fw-900'
						dangerouslySetInnerHTML={{ __html: t(header) }}
					/>
					<p
						className='fs-14 mb-2'
						style={{ color: 'var(--text-dark-grey)' }}
					>
						{t(subHeader)}
					</p>
				</div>
			</IonItem>
			<div className='d-flex justify-content-center gap-3 px-3 mt-2'>
				<CustomButton
					onClick={handleClose}
					className='w-50'
					fill='outline'
					title={t(cancelButtonTitle)}
				/>
				<CustomButton
					className='w-50'
					fill='solid'
					onClick={exitOnClick}
					title={t(confirmButtonTitle)}
				/>
			</div>
		</div>
	);
};
export const AllocatePopUp = (androManager, fullFill) => {
	const { t } = useTranslation();
	return (
		<div className='p-3'>
			<p className='fs-20 fw-bold m-0 p-2'>
				Do you want to allocate this lead to Andro Manager or fulfill loan
				application journey?
			</p>
			<CustomButton
				expand='block'
				className='btn-primary-outline mb-3'
				onClick={androManager}
				title={t('Allocate to Andro Manager')}
			/>
			<CustomButton
				expand='block'
				className='btn-primary-large'
				onClick={fullFill}
				title={t('Fulfill Application Myself')}
			/>
		</div>
	);
};

export const InputFormatCurrencyValue = (value) => {
	return value?.toLocaleString('en-IN');
};

export const DownloadFileBlob = (
	fileData,
	fileName,
	fileExtensions = 'xlsx',
	isURl
) => {
	var a = document.createElement('a'); //Create <a>

	if (isURl) {
		a.href = fileData;
		a.download = `${fileName + '_' + new Date().getTime()}.${fileExtensions}`;
	} else {
		a.href = 'data:application/;base64,' + fileData; //Base64 Goes here
		a.download = `${fileName + '_' + new Date().getTime()}.${fileExtensions}`; //File name Here
	}

	a.click(); //Downloaded file
	showToastAlert({ type: 'success', message: 'Download Successful' });
};

export const capFirstLetter = (str) => {
	return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
		return $1.toUpperCase();
	});
};

export const ionContentScrollToTop = (contentRef) => {
	contentRef.current && contentRef.current.scrollToTop();
};

export const dataFinder = (data, type) => {
	let regex;
	if (type === 'aadhaar') {
		regex = new RegExp(/^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/);
	} else if (type === 'pan') {
		regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
	} else if (type === 'cheque') {
		regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
	}

	let getExpectedData;
	data?.map((i) => {
		if (regex.test(i)) {
			getExpectedData = i;
		}
	});
	return getExpectedData;
};

export const panNumber = (value) => REGEX.PAN.test(value);

export const getEncrypted = (str) => {
	return btoa(str);
};

export const getDecrypted = (str) => {
	return atob(str);
};

export const alphabetColor = (char) => {
	let _char = '#0165E1';
	switch (char) {
		case 1:
			_char = '#66CC66';
			break;
		case 2:
			_char = '#0165E1';
			break;
		case 3:
			_char = '#EC0A0A';
			break;
		case 4:
			_char = '#FFA403';
			break;
		case 5:
			_char = '#0AC1EC';
			break;
		default:
			_char = '#0165E1';
	}
	return _char;
};



export const findCurrentStatus = (status) => {
	let _status = { id: '', label: '' };
	switch (status) {
		case LEAD_STATUS.DISBURSED:
		case LEAD_STATUS.DISBURSED_LABEL:
			_status = {
				id: LEAD_STATUS.DISBURSED,
				label: LEAD_STATUS.DISBURSED_LABEL,
			};
			break;
		case LEAD_STATUS.SANCTIONED:
		case LEAD_STATUS.SANCTIONED_LABEL:
			_status = {
				id: LEAD_STATUS.SANCTIONED,
				label: LEAD_STATUS.SANCTIONED_LABEL,
			};
			break;
		case LEAD_STATUS.CLOSED:
		case LEAD_STATUS.CLOSED_LABEL:
			_status = { id: LEAD_STATUS.CLOSED, label: LEAD_STATUS.CLOSED_LABEL };
			break;
		case LEAD_STATUS.APPLICATION_CLOSED:
		case LEAD_STATUS.APPLICATION_CLOSED_LABEL:
			_status = {
				id: LEAD_STATUS.APPLICATION_CLOSED,
				label: LEAD_STATUS.APPLICATION_CLOSED_LABEL,
			};
			break;
		case LEAD_STATUS.SELECTION:
		case LEAD_STATUS.SELECTION_LABEL:
			_status = {
				id: LEAD_STATUS.SELECTION,
				label: LEAD_STATUS.SELECTION_LABEL,
			};
			break;
		case LEAD_STATUS.LOGIN:
		case LEAD_STATUS.LOGIN_LABEL:
			_status = { id: LEAD_STATUS.LOGIN, label: LEAD_STATUS.LOGIN_LABEL };
			break;
		case LEAD_STATUS.BUREAU:
		case LEAD_STATUS.BUREAU_LABEL:
			_status = { id: LEAD_STATUS.BUREAU, label: LEAD_STATUS.BUREAU_LABEL };
			break;
		case LEAD_STATUS.GENERATED:
		case LEAD_STATUS.GENERATED_LABEL:
			_status = {
				id: LEAD_STATUS.GENERATED,
				label: LEAD_STATUS.GENERATED_LABEL,
			};
			break;
		case LEAD_STATUS.GENERATION:
			_status = { label: LEAD_STATUS.GENERATION };
			break;
		case LEAD_STATUS.PROCESSING:
		case LEAD_STATUS.PROCESSING_LABEL:
			_status = {
				id: LEAD_STATUS.PROCESSING,
				label: LEAD_STATUS.PROCESSING_LABEL,
			};
			break;
		case LEAD_STATUS.UNDERPROCESS:
		case LEAD_STATUS.UNDERPROCESS_LABEL:
			_status = {
				id: LEAD_STATUS.UNDERPROCESS,
				label: LEAD_STATUS.UNDERPROCESS_LABEL,
			};
			break;
		case LEAD_STATUS.FULFILLMENT:
		case LEAD_STATUS.FULFILLMENT_LABEL:
			_status = {
				id: LEAD_STATUS.FULFILLMENT,
				label: LEAD_STATUS.FULFILLMENT_LABEL,
			};
			break;
		case LEAD_STATUS.PARTIALDISBURSED:
		case LEAD_STATUS.PARTIALDISBURSED_LABEL:
			_status = {
				id: LEAD_STATUS.PARTIALDISBURSED,
				label: LEAD_STATUS.PARTIALDISBURSED_LABEL,
			};
			break;
		case LEAD_STATUS.RAW:
		case LEAD_STATUS.RAW_LABEL:
			_status = { id: LEAD_STATUS.RAW, label: LEAD_STATUS.RAW_LABEL };
			break;
		case LEAD_STATUS.ALL:
			_status = { id: LEAD_STATUS.ALL, label: LEAD_STATUS.ALL };
			break;
		case LEAD_STATUS.APPLICATION_FULFILLED:
			_status = {
				label: LEAD_STATUS.APPLICATION_FULFILLED,
			};
			break;
		case LEAD_STATUS.BASIC_DETAILS_OF_LIFE_ASSURED:
			_status = {
				label: LEAD_STATUS.BASIC_DETAILS_OF_LIFE_ASSURED,
			};
			break;
		case LEAD_STATUS.OCCUPATION_DETAILS_OF_ASSURED:
			_status = {
				label: LEAD_STATUS.OCCUPATION_DETAILS_OF_ASSURED,
			};
			break;
		case LEAD_STATUS.DOCUMENTUPLOADED_LABEL:
			_status = {
				label: LEAD_STATUS.DOCUMENTUPLOADED_LABEL,
			};
			break;
		case LEAD_STATUS.MOBILEVERIFICATION_LABEL:
			_status = {
				label: LEAD_STATUS.MOBILEVERIFICATION_LABEL,
			};
			break;
		case LEAD_STATUS.PROPOSERDETAIL_LABEL:
			_status = {
				label: LEAD_STATUS.PROPOSERDETAIL_LABEL,
			};
			break;
		case LEAD_STATUS.RAW_LEAD_LABEL:
			_status = {
				label: LEAD_STATUS.RAW_LEAD_LABEL,
			};
			break;
		case LEAD_STATUS.ALLOCATOR:
		case LEAD_STATUS.ALLOCATOR_LABEL:
			_status = {
				id: LEAD_STATUS.ALLOCATOR,
				label: LEAD_STATUS.ALLOCATOR_LABEL,
			};
			break;
		default:
			_status = { id: '', label: '' };
	}
	return _status;
};
export const findSource = (id) => {
	let source = '';
	switch (id) {
		case LeadSourceEnum.MANUAL:
			source = 'Created';
			break;
		case LeadSourceEnum.CAMPAIGN:
			source = 'Campaign';
			break;
		case LeadSourceEnum.BULK:
			source = 'Bulk';
			break;
		case StatusNum.VERIFICATION:
			source = 'Verification Pending';
			break;
		case StatusNum.VERIFICATION1:
			source = 'Verification Pending';
			break;
		case StatusNum.VERIFICATION2:
			source = 'Verification Pending';
			break;
		case StatusNum.REWORKCOMPLETED:
			source = 'Re-Verification Pending';
			break;
		case StatusNum.REWORKVERIFICATION1:
			source = 'Re-Verification Pending';
			break;
		case StatusNum.REWORKVERIFICATION2:
			source = 'Re-Verification Pending';
			break;
		case StatusNum.REWORK:
			source = 'Send for Rework';
			break;
		case StatusNum.REJECTED:
			source = 'Rejected';
			break;
		default:
			source;
	}
	return source;
};

export const getPictureDataByOcr = async (
	docType,
	path,
	webPath,
	regType = ''
) => {
	let finalData;
	const ocrData = await performOCR(path);
	let processedData;
	switch (docType) {
		case 'imageWithData':
			processedData = dataFinder(ocrData?.lines?.linetext, regType);
			processedData = processedData ? processedData : 'NOT FOUND';
			finalData = {
				image: webPath,
				processedData: processedData.replaceAll(' ', ''),
			};
			break;
		default:
			finalData = {};
			break;
	}
	return finalData;
};

const performOCR = (imgData) => {
	return new Promise((resolve, reject) => {
		// document.addEventListener("deviceready", () => {
		const ocrOptions = { imgType: 0, imgSrc: imgData };

		function onSuccess(recognizedText) {
			resolve(recognizedText);
		}

		function onFail(message) {
			reject(message);
		}

		if (window && window.mltext && window.mltext.getText) {
			window?.mltext?.getText(onSuccess, onFail, ocrOptions);
		} else {
			onSuccess({ processedData: 'not found' });
		}
		// }, false);
	});
};



export const performTesseractOCR = (imgDataBase64) => {

	Tesseract.recognize(
		imgDataBase64,
		'eng', // language
		{ logger: m => console.log(m) } // logger
	  ).then(({ data: { text } }) => {
		console.log(text); // Extracted text from the image
		return text;
	  }).catch(error => {
		console.error('Error performing OCR:', error);
		return null;
	  });
};


export const getFilenameByUrl = (url) => {
	let fileName = '';
	if (!url) {
		fileName = '';
	} else if (url.includes('/tmp/')) {
		fileName = decodeURIComponent(new URL(url).pathname.split('/tmp/')[1]);
	} else if (url.includes('Document/')) {
		fileName = url.split('Document/')[1].split('?')[0];
	}
	return fileName;
};

export const getFileExtByUrl = (url) => {
	let fileExt = '';
	if (!url) {
		fileExt = '';
	} else if (url.includes('/tmp/')) {
		fileExt = decodeURIComponent(new URL(url).pathname.split('/tmp/')[1])
			.split('.')
			.pop();
	} else if (url.includes('Document/')) {
		fileExt = url.split('Document/')[1].split('.').pop();
	}
	return fileExt;
};

export const converRuppesFormat = (num) =>
	new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(
		parseInt(num)
	);

export const getFileWithUpdatedToken = ({
	dispatch,
	Url = '',
	imgUrlUpdate,
	unwrapResult,
	setUserFile,
	setName,
	userData,
	setUserData,
	setUserData2,
}) => {
	if (Url) {
		dispatch(
			imgUrlUpdate({
				data: {
					url: Url,
					expire_time: 50000,
				},
			})
		)
			.then(unwrapResult)
			.then((response) => {
				if (response?.status) {
					setUserFile && setUserFile(response?.url);
					userData &&
						setUserData &&
						setUserData({ ...userData, Url: response?.url });
					userData &&
						setUserData2 &&
						setUserData2({ ...userData, Url2: response?.url });
					setName && setName(getFilenameByUrl(response?.url));
				}
			});
	} else {
		return '';
	}
};
export const showToastAlert = ({ type, message }) => {
	toast.dismiss();
	if (type === 'error') {
		if (typeof message === 'string' && message.trim().length > 0) {
			toast.error(message, {
				toastId: 1,
				position: 'bottom-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				// theme: "light",
				theme: 'colored',
				icon: false,
				className: 'toast-msg toast-error',
			});
		}
		
	} else if (type === 'info') {
		toast.info(message, {
			toastId: 1,
			position: 'bottom-center',
			autoClose: 3000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			// theme: "light",
			theme: 'colored',
			icon: false,
			className: 'toast-msg toast-info',
		});
	} else {
		toast.success(message, {
			toastId: 1,
			position: 'bottom-center',
			autoClose: 3000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			// theme: "light",
			theme: 'colored',
			icon: false,
			className: 'toast-msg toast-success',
		});
	}
};
export const makeFirstLetterUppercase = (word) => {
	return word?.charAt(0).toUpperCase() + word?.slice(1);
};

export const debounceInput = (func, timeout) => {
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			func.apply(this, args);
		}, timeout);
	};
};

export const getUserAge = (date) => {
	const today = new Date();
	const userDate = new Date(date);
	let age = today.getFullYear() - userDate.getFullYear();
	const m = today.getMonth() - userDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < userDate.getDate())) {
		age--;
	}
	return age;
};



export const setBankIcon = (bankName) => {
	const bN = bankName?.toLowerCase();
	let icon_path = '';
	if (bN?.includes('hdfc')) {
		icon_path = '/images/icons/bank/lender-hdfc-bank-icon.png';
	} else if (bN?.includes('axis')) {
		icon_path = '/images/icons/bank/lender-axis-bank-icon.png';
	} else if (bN?.includes('bajaj')) {
		icon_path = '/images/icons/bank/lender-bajaj-finance-icon.png';
	} else if (bN?.includes('icici')) {
		icon_path = '/images/icons/bank/lender-icici-bank-icon.png';
	} else if (bN?.includes('idfc')) {
		icon_path = '/images/icons/bank/lender-idfc-bank-icon.png';
	} else if (bN?.includes('aditya')) {
		icon_path = '/images/icons/bank/lender-aditya-birla-icon.png';
	} else if (bN?.includes('baroda')) {
		icon_path = '/images/icons/bank/lender-bank-of-baroda-icon.png';
	} else if (bN?.includes('chola')) {
		icon_path = '/images/icons/bank/lender-chola-icon.png';
	} else if (bN?.includes('deutsche')) {
		icon_path = '/images/icons/bank/lender-deutsche-bank-icon.png';
	} else if (bN?.includes('fullerton')) {
		icon_path = '/images/icons/bank/lender-fullerton-icon.png';
	} else if (bN?.includes('kotak')) {
		icon_path = '/images/icons/bank/lender-kotak-mahindra-bank-icon.png';
	} else if (bN?.includes('piramal')) {
		icon_path = '/images/icons/bank/lender-piramal-icon.png';
	} else if (bN?.includes('punjab')) {
		icon_path = '/images/icons/bank/lender-punjab-national-bank-icon.png';
	} else if (bN?.includes('yes')) {
		icon_path = '/images/icons/bank/lender-yes-bank-icon.png';
	} else if (bN?.includes('tata')) {
		icon_path = '/images/icons/bank/lender-tata-icon.png';
	} else if (bN?.includes('shriram')) {
		icon_path = '/images/icons/bank/lender-shriram-icon.png';
	} else {
		icon_path = '/images/icons/leads/bank_icon_placeholder.svg';
	}
	return icon_path;
};

export const disableNumberArrows = (e) => {
	//Disable Arrow Up and Down in number field
	if (e.keyCode === 38 || e.keyCode === 40) {
		e.preventDefault();
	}
};

export const getIcon = (ch) => {
	switch (ch) {
		case '1':
			return '/images/icons/lender-sub-product-icon/processing_fees.svg';
		case '2':
			return '/images/icons/lender-sub-product-icon/tenure_range.svg';
		case '3':
			return '/images/icons/lender-sub-product-icon/loan_amount.svg';
		case '4':
			return '/images/icons/lender-sub-product-icon/interest_rate.svg';
		default:
			return '';
	}
};
export const groupParamsByKey = (params) =>
	[...params.entries()].reduce((acc, tuple) => {
		const [key, val] = tuple;
		if (Object.prototype.hasOwnProperty.call(acc, key)) {
			if (Array.isArray(acc[key])) {
				acc[key] = [...acc[key], val];
			} else {
				acc[key] = [acc[key], val];
			}
		} else {
			acc[key] = val;
		}

		return acc;
	}, {});

export const ACL = (roleArr = [], compId) => {
	const permissions = roleArr.filter((item) => item.id === compId);
	if (permissions.length) {
		return true;
	}
	return false;
};

export const checkFieldsValidToContinue = (errors) => {
	let _valid = true;
	if (Object.keys(errors).length > 0) {
		Object.keys(errors)?.map((key) => {
			if (errors[key].type != 'required' && errors[key].type != 'optionality') {
				if (
					(key === 'dob' && errors[key].type === 'typeError') ||
					key === 'acceptTerms'
				) {
					_valid = _valid ? true : false;
				} else {
					if (_valid) {
						_valid = false;
					}
				}
			}
		});
	} else {
		_valid = true;
	}
	return _valid;
};

export const getFileExtension = (file) => {
	
	if (file && file.name) {
        const fileName = file.name;
        const lastDotIndex = fileName.lastIndexOf('.');

        if (lastDotIndex !== -1) {
            return fileName.substring(lastDotIndex + 1).toLowerCase();
        }
    }
    return '';
};

export const maskPan = (pan) => {
	if (!pan) return pan;
	return '*'.repeat(pan.length);
  };
  
  // Function to fully mask email (mask local part entirely)
  export const maskEmail = (email) => {
	if (!email) return email;
	return '*'.repeat(email.length);
  };

  // Function to mask mobile number except last 4 digits
export const maskMobile = (mobile) => {
	if (!mobile || mobile.length < 4) return mobile;
	const maskLength = mobile.length - 4;
	return '*'.repeat(maskLength) + mobile.slice(-4);
  };


  const checkFilesystemPermission = async () => {
	try {
	  const permission = await Filesystem.requestPermissions();
	  if (permission.publicStorage !== 'granted') {
		throw new Error('Filesystem permissions not granted');
	  }
	} catch (error) {
	  console.error('Error requesting filesystem permissions', error);
	}
  };
  
  

  export const downloadFile = async (item) => {


	const filePreview = item.content;
	const fileName = item.file_name??item.name;
	// const fileName = item.name;
	// const base64Data = filePreview.split(',')[1];
	const base64Data = filePreview;



  
	try {
	  if (Capacitor.getPlatform() === 'web') {
		// Create a link element for web download
		const link = document.createElement('a');
		link.href = filePreview;
		link.download = fileName;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	  } else {
		const result = await Filesystem.writeFile({
		  path: fileName,
		  data: base64Data,
		  directory: Directory.Documents,
		//   encoding: Encoding.UTF8,
		});
  
		if (Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios') {
		  await checkFilesystemPermission();
		
		  await FileOpener.open({
			filePath: result.uri,
			// contentType: 'application/octet-stream',
			// openWithDefault: true,
		  });
		} 
		// else if (Capacitor.getPlatform() === 'ios') {
		//   await checkFilesystemPermission();
		// //   await Share.share({
		// // 	title: 'Downloaded File',
		// // 	text: 'Here is the downloaded file.',
		// // 	url: result.uri,
		// // 	dialogTitle: 'Open with',
		// //   });
		// await FileOpener.open({
		// 	filePath: result.uri,
		// 	// contentType: 'application/octet-stream',
		// 	openWithDefault: true,
		//   });
		// }
  
		showToastAlert({ type: "success", message: "File downloaded successfully!" });
	  }
	} catch (error) {
	  showToastAlert({ type: "error", message: `Error downloading file: ${error}` });
	}
  };