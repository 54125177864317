import { IonContent, IonFooter, IonPage } from '@ionic/react';
import React from 'react';
import { Header } from '../../components/layout/header/Header';
import './GrantPermission.scss';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
export const GrantPermission: React.FC = () => {
	const { t } = useTranslation();
	const permissionList = [
		{
			name: 'Location',
		},
		{
			name: 'Call',
		},
		{
			name: 'SMS',
		},
	];
	return (
		<IonPage className='grant-permission'>
			<Header name='' />
			<IonContent className='ion-padding'>
				<h3 className='title fs-22 m-0'>
					{t('We take the following')} <br /> {t('Permissions')}
				</h3>
				<div>
					{permissionList.map((item, index) => {
						return (
							<h3
								key={index}
								className='permission-name m-0 fs-22'
							>
								{t(item.name)}
							</h3>
						);
					})}
				</div>
			</IonContent>
			<IonFooter>
				<div className='ion-padding'>
					<CustomButton
						expand='block'
						routerLink='/'
						title={t('Grant Permission')}
					/>
				</div>
			</IonFooter>
		</IonPage>
	);
};
