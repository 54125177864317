//// @ts-nocheck


import { yupResolver } from "@hookform/resolvers/yup";
import {
  IonCard,
  IonCardContent,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useApi from "../../api/useApi";
import { CustomButton } from "../../components/common/custom-button/CustomButton";
import CustomForm from "../../components/common/custom_form/custom_form.component";
import { Header } from "../../components/layout/header/Header";
import ApplicationContext from "../../context/ApplicationContext";
import { useBusyLoading } from "../../context/BusyLoadingContext";
import { EXISTING_DETAIL } from "../../helpers/api-endpoints";
import { INSURANCE_TYPE, REDIRECT } from "../../helpers/constant";
import { showToastAlert } from "../../helpers/functions";
import {
  ExistingPolicyDetailsForm,
  ExistingPolicyDetailsRequest,
} from "../../model/existing_policy_bean";
import "./existing_policy_details.module.scss";
import existing_policy_details_schema from "./existing_policy_details.schema";

// import {CustomForm} from 'andro-insurance-lib';


interface Props {
  title: string;
  nextTabUrl?: string;
  inpCallback?: () => void;
  finalSubmit?: () => void;
  existingPolicyDetailsForm?: ExistingPolicyDetailsForm;
  readonly:boolean;

  // userId: any;
  // setShowBasicInfoForm: any;
  // getUserDetailsInfo: any;
}

const ExistingPolicyDetails: React.FC<Props> = ({
  title,
  existingPolicyDetailsForm,
  inpCallback,
  nextTabUrl,
  finalSubmit,
  readonly = false
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [validateForm, setValidateForm] = useState<boolean>();

  // State hooks prefixed with "show" for visibility toggling
  const [hideVehicleDetails, setHideVehicleDetails] = useState<boolean>(true);
  const [hideCarType, setHideCarType] = useState<boolean>(true);
  const [hideCarMakeModel, setHideCarMakeModel] = useState<boolean>(true);
  const [hideRegistrationNo, setHideRegistrationNo] = useState<boolean>(true);
  // const [hideExistingPolicyNumber, setHideExistingPolicyNumber] = useState<boolean>(true);
  const [hideSumAssured, setHideSumAssured] = useState<boolean>(true);
  const [hideInsurerName, setHideInsurerName] = useState<boolean>(true);
  const [hidePlanName, setHidePlanName] = useState<boolean>(true);
  const [hideIssueDate, setHideIssueDate] = useState<boolean>(true);
  const [hidePolicyStatus, setHidePolicyStatus] = useState<boolean>(true);
  const [hideGoodHealth, setHideGoodHealth] = useState<boolean>(true);
  const [hideGoodHealthDetails, setHideGoodHealthDetails] = useState<boolean>(true);
  
  

  const {
    proposerBasicDetailsValid,
    proposerFamilyDetailsValid,
    proposerPermAddressDetailsValid,
    proposerCurAddressDetailsValid,
    proposerOccupationDetailsValid,

    assuredBasicDetailsValid,
    assuredFamilyDetailsValid,
    assuredPermAddressDetailsValid,
    assuredCurAddressDetailsValid,
    assuredOccupationDetailsValid,
  } = useContext(ApplicationContext); 


    const validateFinal: () => boolean = () => {
    const boolValidate = proposerBasicDetailsValid &&
    proposerFamilyDetailsValid &&
    proposerPermAddressDetailsValid &&
    proposerCurAddressDetailsValid &&
    proposerOccupationDetailsValid &&

    assuredBasicDetailsValid &&
    assuredFamilyDetailsValid &&
    assuredPermAddressDetailsValid &&
    assuredCurAddressDetailsValid &&
    assuredOccupationDetailsValid;

    console.log("hbs boolValidate ", boolValidate);
    console.log('validate values',proposerBasicDetailsValid ,
      proposerFamilyDetailsValid ,
      proposerPermAddressDetailsValid ,
      proposerCurAddressDetailsValid ,
      proposerOccupationDetailsValid ,
  
      assuredBasicDetailsValid ,
      assuredFamilyDetailsValid ,
      assuredPermAddressDetailsValid ,
      assuredCurAddressDetailsValid ,
      assuredOccupationDetailsValid);
    

    return boolValidate;
  }

  // const [validateFinal, setValidateFinal] = useState<boolean>();



  const onChangeGoodHealth = (value:string) => {
    let stub:boolean = value!=='no';
    console.log('called ',value, stub);


    setHideGoodHealthDetails(stub);
  }
  

  let existingPolicyDetailsFormKeys = [

    {
      name: "vehicleDetails",
      type: "text",
      placeholder: "Vehicle Details",
      hidden: hideVehicleDetails,
    },
    {
      name: "carType",
      //hbs change here
      type: "text/combo",
      hidden: hideCarType,
      placeholder: "Car Type",
      values: [
        { value: "old", description: "Old" },
        { value: "new", description: "New" },
      ],
    },
    {
      name: "carMakeModel",
      type: "text",
      hidden: hideCarMakeModel,
      placeholder: "Car Make/Model",
    },
    {
      name: "registrationNo",
      type: "text",
      hidden: hideRegistrationNo,
      placeholder: "Registration No.",
    },
    {
      name: "policyNo",
      type: "text",
      hidden: false,
      placeholder: "Existing Policy number",
    },
    {
      name: "insurerName",
      type: "text",
      hidden: hideInsurerName,
      placeholder: "Insurer Name",
    },

    {
      name: "planName",
      type: "text",
      hidden: hidePlanName,
      placeholder: "Plan Name",
      // disabled: true,
    },
    {
      name: "sumAssured",
      type: "number",
      hidden: hideSumAssured,
      placeholder: "Sum Assured",
    },
    {
      name: "policyStatus",
      //hbs change here
      type: "text/combo",
      hidden: hidePolicyStatus,
      placeholder: "Policy Status",
      values: [
        { value: "Inforce", description: "Inforce" },
        { value: "Lapse", description: "Lapse" },
        { value: "Postponed", description: "Postponed" },
        { value: "Declined", description: "Declined" },
      ],
    },
    {
      name: "issueDate",
      type: "date",
      hidden: hideIssueDate,
      placeholder: "Issue Date",
    },
    {
      name: "goodHealth",
      //hbs change here
      type: "text/combo",
      hidden: hideGoodHealth,
      onChange:onChangeGoodHealth,
      placeholder: "Is assured of good health?",
      values: [
        { value: "yes", description: "Yes" },
        { value: "no", description: "No" },
      ],
    },
    {
      name: "goodHealthDetails",
      //hbs change here
      type: "textarea",
      hidden: hideGoodHealthDetails,
      placeholder: "Existing Health Condition Details",
    },
  ];




  const { responseData, loading, executeRequest, error } =
    useApi<any>(EXISTING_DETAIL);

  const { setBusyState } = useBusyLoading();
  const { applicationBean } = useContext(ApplicationContext);
  


  useEffect(() => {
    setBusyState(loading);
  }, [loading]);

  useEffect(() => {
    showToastAlert({ type: "error", message: error?.message! });
  }, [error]);

  useEffect(() => {
    if (responseData?.status) {
      showToastAlert({ type: "success", message: responseData?.message });

      // if(inpCallback) {
      //   inpCallback();  
      //   if(nextTabUrl)
      //    history.push(nextTabUrl);
      // }
    }
  }, [responseData]);

  const {
    control,
    trigger,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm<ExistingPolicyDetailsForm>({
    resolver: yupResolver(existing_policy_details_schema), 
    defaultValues: existingPolicyDetailsForm,
  });



  const [users, setUsers] = useState<any[]>([]);
  // const customHeader = { 'Authorization': 'Bearer YOUR_ACCESS_TOKEN' };
  const customHeader = {};

  const onSubmit = async (data: ExistingPolicyDetailsForm) => {
    console.log(data);
    console.log(isValid);
    // const payload: ExistingPolicyDetailsRequest = {
    //   id: undefined,
    //   sum_assured: data.sumAssured?.toString()!,
    //   existing_policy_number: "",
    //   insurer_name: data.insurerName!,
    //   plan_name: data.planName!,
    //   issued_date: data.issueDate as string,
    //   policy_status: data.policyStatus!,
    //   goodHealthFlag: data.goodHealth!,
    //   policyNo: 0,
    //   application_id: applicationBean?.applicationId,
    //   profileId: 0,
    // };

    const payload: ExistingPolicyDetailsRequest = new ExistingPolicyDetailsRequest(data);
    console.log(payload);


    executeRequest("put", payload);
    // executeRequest("post", payload);
    // Handle form submission logic here

    //inpCallback();
    //history.push(nextTabUrl);
  };
  
  useMemo(() => {
    showToastAlert({ type: "error", message: error?.message! });
  }, [error]);

  
  // const issueDate = watch(["issueDate"]);

  // useEffect(() => {
  //   // setBusyState(loading);
  //   console.log('issueDate', issueDate);
  // }, [issueDate]);


  useEffect(() => {

    switch (applicationBean?.insuranceTypeId) {
      case INSURANCE_TYPE.GENERAL_VEHICLE: {
        setHideVehicleDetails(false);
        setHideCarType(false);
        setHideCarMakeModel(false);
        setHideRegistrationNo(false);
        // setHideExistingPolicyNumber(false);
        break;
      }
      case INSURANCE_TYPE.LIFE_ENDOWMENT:
      case INSURANCE_TYPE.LIFE_GUARNTEED_RETURN:
      case INSURANCE_TYPE.LIFE_MONEYBACK:
      case INSURANCE_TYPE.LIFE_PENSION:
      case INSURANCE_TYPE.LIFE_TERM_LIFE_INSURANCE:
      case INSURANCE_TYPE.LIFE_UNIT_LINKED:
        {


          setHideSumAssured(false);
          // setHideExistingPolicyNumber(false);
          setHideInsurerName(false);
          setHidePlanName(false);
          setHideIssueDate(false);
          setHidePolicyStatus(false);
          setHideGoodHealth(false);
          // setHideGoodHealthDetails(false);

          break;
        }
      case INSURANCE_TYPE.HEALTH_INDIVIDUAL:
      case INSURANCE_TYPE.HEALTH_FAMILY:
        {

          setHideSumAssured(false);
          setHidePlanName(false);
          // setHideExistingPolicyNumber(false);
          setHidePolicyStatus(false);
          setHideGoodHealth(false);
          // setHideGoodHealthDetails(false);
          break;
        }
      default: {
        break;
      }
    }

    

  }, [existingPolicyDetailsFormKeys]);


  useEffect(() => {

    // console.log(existingPolicyDetailsFormKeys)

  }, [hideGoodHealthDetails,existingPolicyDetailsFormKeys]);







  return (
    <IonPage className="bg-web-white container-width-md container-spacing-md border-md-radius-top">
      <Header
        className="card-header"
        name={t(title ?? "Existing Policy Details")}
        backButton={true}
        needArrow={true}
        needArrowOnClick={() => {
          // history.push(REDIRECT.DASHBOARD);
          window.location.href = REDIRECT.DASHBOARD;
        }}
      />

     
      <IonContent className="ion-padding-bottom">
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">ExistingPolicyDetails</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* <section
			className='overflowX-hidden pt-1'
			style={{ height: 'calc(100vh - 20px)' }}
		> */}
        <div style={{
           minHeight: 'calc(100vh - 70px)', 
           overflowY: 'auto',
           paddingBottom: '70px' 
        }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonCard>
              <IonCardContent>
                <CustomForm
                  readonly = {readonly}
                  formKeys={existingPolicyDetailsFormKeys}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  isValid={validateForm}
                  errors={errors}
                  trigger={trigger}
                />
              </IonCardContent>
            </IonCard>
            <div
              className="position-fixed w-100 bottom-0 start-0 px-3 pb-3"
              style={{ backgroundColor: "#fff" }}
            >
              <IonGrid>
                <IonRow class="ion-justify-content-center">
                  <CustomButton
                    className="btn-blue"
                    type="submit"
                    expand="block"
                    title="Update"
                    onClick={() => {
                      handleSubmit(onSubmit);
                      setValidateForm(isValid);
                    }}
                  // disabled={!isValid}
                  // title={t('Update')}
                  // disabled={!isValid}
                  />
                  {/* </IonRow>
                <IonRow> */}

                  {validateFinal() && finalSubmit && <CustomButton
                  // {finalSubmit && <CustomButton
                    className="btn-blue"
                    expand="block"
                    title={t('Proceed')}
                    onClick={() => {

                      if (finalSubmit) {
                        console.log("final insurer");
                        handleSubmit(onSubmit);
                        setValidateForm(isValid);
                        finalSubmit();
                      }
                      // handleSubmit(onSubmit);
                      // setValidateForm(isValid);
                    }}
                  disabled={!finalSubmit}
                  // disabled={!isValid}
                  />}


                </IonRow>

              </IonGrid>




            </div>
          </form>
        </div>
        {/* </section> */}
      </IonContent>
    </IonPage>
  );
};

export default ExistingPolicyDetails;
