//// @ts-nocheck



import { screen } from '@testing-library/react';

import {
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import React, { useContext, useEffect, useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import ApplicationContext from "../../context/ApplicationContext";
import { AddressDetailsForm } from "../../model/address_details_bean";
import { BasicDetailsForm } from "../../model/basic_details_bean";
import { ExistingPolicyDetailsForm } from "../../model/existing_policy_bean";
import { FamilyDetailsForm } from "../../model/family_details_bean";
import { OccupationDetailsForm } from "../../model/occupation_bean";
import AddressDetailsMain from "../address_details/address_details_main";
import BasicDetails from "../basic_details/basic_details";
import ExistingPolicyDetails from "../existing_policy_details/existing_policy_details";
import FamilyDetails from "../family_details/family_details";
import OccupationDetails from "../occupation_details/occupation_details";
import "./insurance_form.module.scss";
import DocumentDetails from '../document_details/document_details';
import { DocumentUploadForm } from '../../model/document_bean';

interface Props {
  showDocuments?: boolean;
  showexistingPolicyDetails?: boolean;

  isProposer: boolean;
  basicDetailsForm: BasicDetailsForm | undefined;
  familyDetailsForm: FamilyDetailsForm | undefined;

  permAddressDetailsForm: AddressDetailsForm | undefined;
  curAddressDetailsForm: AddressDetailsForm | undefined;


  applicationId: number;
  applicationDocumentsForm?: DocumentUploadForm[];
  profileId: number;

  occupationDetailsForm: OccupationDetailsForm | undefined;
  existingPolicyDetailsForm: ExistingPolicyDetailsForm | undefined;
  readonly: boolean;
  showAssuredTab?: boolean;
  finalSubmit?: () => void;
}


const InsuranceForm: React.FC<Props> = ({ showDocuments = true, showexistingPolicyDetails = true, finalSubmit, isProposer,
  basicDetailsForm,
  familyDetailsForm,
  permAddressDetailsForm,
  curAddressDetailsForm,
  occupationDetailsForm,
  existingPolicyDetailsForm,
  applicationId,
  profileId,
  applicationDocumentsForm = [],
  readonly = false,
  showAssuredTab = false
}) => {
  const tabMainUrl: string = "/insurance/tabs";
  const basicDetailPath = `${tabMainUrl}/basic`;
  const familyDetailPath = `${tabMainUrl}/family`;
  const addressDetailPath = `${tabMainUrl}/address`;
  const occupationDetailPath = `${tabMainUrl}/occupation`;
  const existingPolicyPath = `${tabMainUrl}/existing-policy`;

  // const errorColor?:string = 'red';
  const errorColor: any = undefined;


  const history = useHistory();
  const {
    proposerBasicDetailsValid,
    setProposerBasicDetailsValid,
    proposerFamilyDetailsValid,
    setProposerFamilyDetailsValid,
    proposerAddressDetailsValid,
    setProposerAddressDetailsValid,
    proposerPermAddressDetailsValid,
    setProposerPermAddressDetailsValid,
    proposerCurAddressDetailsValid,
    setProposerCurAddressDetailsValid,
    proposerOccupationDetailsValid,
    setProposerOccupationDetailsValid,

    assuredBasicDetailsValid,
    setAssuredBasicDetailsValid,
    assuredFamilyDetailsValid,
    setAssuredFamilyDetailsValid,
    assuredAddressDetailsValid,
    setAssuredAddressDetailsValid,
    assuredPermAddressDetailsValid,
    setAssuredPermAddressDetailsValid,
    assuredCurAddressDetailsValid,
    setAssuredCurAddressDetailsValid,
    assuredOccupationDetailsValid,
    setAssuredOccupationDetailsValid,
  } = useContext(ApplicationContext);

  const [currentTab, setCurrentTab] = useState<number>(1);

  const handleTabChange = (tabIndex: number) => {
    setCurrentTab(tabIndex);
  };

  const moveToNextTab = () => {
    setCurrentTab((prevTab) => prevTab + 1);
  };

  // const occupationDetails: OccupationDetailsForm = {
  //   occupationType: 'self_employed',
  //   companyName: 'ABC Company',
  //   jobDescription: 'Developing web applications',
  //   annualIncome: '80000',
  //   totalWorkExp: '5',
  // };


  useEffect(() => {
    const timeoutId = setTimeout(() => {

      const tabButtonBasic = screen.getAllByText('Basic Details');
      console.log("hbs tabButtonBasic ", tabButtonBasic);


      let i = tabButtonBasic.length - 1;
      while (i >= 0) {
        tabButtonBasic[i].click();
        i--;
      }



    }, 200); // 3000 milliseconds = 3 seconds


    // Cleanup the timeout if the component unmounts
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);



  useEffect(() => {
    const timeoutId = setTimeout(() => {

      const tabButton = screen.getAllByText('Basic Details');

      if (!isProposer) {
        tabButton[1].click();
      }

    }, 300); // 3000 milliseconds = 3 seconds


    // Cleanup the timeout if the component unmounts
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);


  // useEffect(() => { handleTabChange(1); }, []);

  // useEffect(() => {
  //   debugger;
  //   if (!proposerBasicDetailsValid) history.push(basicDetailPath);
  //   else if (!proposerFamilyDetailsValid) history.push(familyDetailPath);
  //   else if (!proposerAddressDetailsValid) history.push(addressDetailPath);
  //   else if (!proposerOccupationDetailsValid)
  //     history.push(occupationDetailPath);
  // }, [
  //   proposerBasicDetailsValid,
  //   proposerFamilyDetailsValid,
  //   proposerAddressDetailsValid,
  //   proposerPermAddressDetailsValid,
  //   proposerCurAddressDetailsValid,
  //   proposerOccupationDetailsValid,
  // ]);

  // console.log("hbs family details ", familyDetailsForm);

  // console.log("hbs permAddressDetailsForm ", permAddressDetailsForm);

  return (
    <IonReactRouter>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-8">
            <IonTabs>
              <IonRouterOutlet onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <Route path={basicDetailPath} render={() => <BasicDetails readonly={readonly} isProposer={isProposer} basicDetailsForm={basicDetailsForm} inpCallback={moveToNextTab} nextTabUrl={`${tabMainUrl}/family`} title='Basic Info' />} exact={true} />
                <Route path={familyDetailPath} render={() => <FamilyDetails readonly={readonly} isProposer={isProposer} familyDetailsForm={familyDetailsForm} inpCallback={moveToNextTab} nextTabUrl={`${tabMainUrl}/address`} title='Family Info' />} exact={true} />
                <Route path={addressDetailPath} render={() => <AddressDetailsMain readonly={readonly} isProposer={isProposer}
                  permAddressDetailsForm={permAddressDetailsForm}
                  curAddressDetailsForm={curAddressDetailsForm}
                  inpCallback={moveToNextTab} nextTabUrl={`${tabMainUrl}/occupation`} title='Address Info' />} exact={true} />
                <Route path={occupationDetailPath} render={() => <OccupationDetails readonly={readonly} isProposer={isProposer} occupationDetailsForm={occupationDetailsForm}


                  inpCallback={() => {
                    if (showexistingPolicyDetails) {
                      moveToNextTab();
                    }
                    else {
                      if (showAssuredTab) {
                        const tabButtonAssured = screen.getAllByText('Assured Details');
                        tabButtonAssured[0].click();
                      }
                    }

                  }}

                  nextTabUrl={showexistingPolicyDetails ? `${tabMainUrl}/existing-policy` : ''}

                  title='Occupation Info' />} exact={true} />


                <Route path={existingPolicyPath} render={() => <ExistingPolicyDetails
                  readonly={readonly}
                  existingPolicyDetailsForm={existingPolicyDetailsForm}
                  inpCallback={() => {
                    if (showDocuments) {
                      moveToNextTab();
                    }
                    // else {
                    //   if(showAssuredTab){
                    //     const tabButtonAssured = screen.getAllByText('Assured Details');
                    //     tabButtonAssured[0].click();
                    //   }
                    // }
                    // else if (finalSubmit) {
                    //   finalSubmit();
                    // }
                  }} nextTabUrl={`${tabMainUrl}/document`}
                  finalSubmit={showDocuments ? undefined : finalSubmit}
                  title='Existing Policy Info' />}
                  exact={true} />



                <Route
                  path={`${tabMainUrl}/docuploadMain`}
                  render={() => {
                    if (showDocuments)
                      return <DocumentDetails
                        readonly={readonly}
                        applicationId={applicationId}
                        documentUploadFormList={applicationDocumentsForm}
                        profileId={profileId}
                        inpCallback={
                          () => {
                            if (showAssuredTab) {
                              const tabButtonAssured = screen.getAllByText('Assured Details');
                              tabButtonAssured[0].click();
                            }
                            else {
                              if (finalSubmit) {
                                finalSubmit();
                              }
                            }



                          }


                        }
                      />;





                    // <DocumentUploadMain readonly={readonly}/>;
                    else {
                      return <></>;
                    }
                  }}
                  exact={true}
                />

                <Redirect exact path="/insurance" to={basicDetailPath} />
              </IonRouterOutlet>

              <IonTabBar
                style={{
                  justifyContent:
                    window.innerWidth <= 600 ? "flex-start" : "flex-center",
                  display: "flex",
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  flexWrap: "nowrap",
                }}
                slot="bottom"
              >
                {/* <IonTabBar style={{ overflowX: 'scroll'}} slot="bottom"> */}
                <IonTabButton
                  className="custom-ion-tab-button"
                  tab="basic"
                  style={{
                    color: (
                      isProposer
                        ? !proposerBasicDetailsValid
                        : !assuredBasicDetailsValid
                    )
                      ? errorColor
                      : undefined,
                    borderColor: (
                      isProposer
                        ? !proposerBasicDetailsValid
                        : !assuredBasicDetailsValid
                    )
                      ? errorColor
                      : undefined,
                  }}
                  href={basicDetailPath}
                  onClick={() => handleTabChange(1)}
                  selected={currentTab === 1}
                >
                  <IonLabel>Basic Details</IonLabel>
                </IonTabButton>

                <IonTabButton
                  className="custom-ion-tab-button"
                  style={{
                    color: (
                      isProposer
                        ? !proposerFamilyDetailsValid
                        : !assuredFamilyDetailsValid
                    )
                      ? errorColor
                      : undefined,
                    borderColor: (
                      isProposer
                        ? !proposerFamilyDetailsValid
                        : !assuredFamilyDetailsValid
                    )
                      ? errorColor
                      : undefined,
                  }}
                  tab="family"
                  href={familyDetailPath}
                  onClick={() => handleTabChange(2)}
                  selected={currentTab === 2}
                >
                  <IonLabel>Family Details</IonLabel>
                </IonTabButton>

                <IonTabButton
                  className="custom-ion-tab-button"
                  tab="address"
                  style={{
                    color: (
                      isProposer
                        ? !proposerAddressDetailsValid
                        : !assuredAddressDetailsValid
                    )
                      ? errorColor
                      : undefined,
                    borderColor: (
                      isProposer
                        ? !proposerAddressDetailsValid
                        : !assuredAddressDetailsValid
                    )
                      ? errorColor
                      : undefined,
                  }}
                  href={addressDetailPath}
                  onClick={() => handleTabChange(3)}
                  selected={currentTab === 3}
                >
                  <IonLabel>Address Details</IonLabel>
                </IonTabButton>

                <IonTabButton
                  className="custom-ion-tab-button"
                  style={{
                    color: (
                      isProposer
                        ? !proposerOccupationDetailsValid
                        : !assuredOccupationDetailsValid
                    )
                      ? errorColor
                      : undefined,
                    borderColor: (
                      isProposer
                        ? !proposerOccupationDetailsValid
                        : !assuredOccupationDetailsValid
                    )
                      ? errorColor
                      : undefined,
                  }}
                  tab="occupation"
                  href={occupationDetailPath}
                  onClick={() => handleTabChange(4)}
                  selected={currentTab === 4}
                >
                  <IonLabel>Occupation Details</IonLabel>
                </IonTabButton>

                {showexistingPolicyDetails && <IonTabButton
                  className="custom-ion-tab-button"
                  tab="existing-policy"
                  href={existingPolicyPath}
                  onClick={() => handleTabChange(5)}
                  selected={currentTab === 5}
                >
                  <IonLabel>Existing Policy Details</IonLabel>
                </IonTabButton>}

                {showDocuments && (
                  <IonTabButton
                    className="custom-ion-tab-button"
                    tab="document"
                    href={`${tabMainUrl}/docuploadMain`}
                    onClick={() => handleTabChange(6)}
                    selected={currentTab === 6}
                  >
                    <IonLabel>Document Details</IonLabel>
                  </IonTabButton>
                )}
              </IonTabBar>
            </IonTabs>
          </div>
        </div>
      </div>
    </IonReactRouter>
  );
};

export default InsuranceForm;
