import React, { useEffect } from 'react';
import { useIonRouter } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';

import { Header } from '../../../components/layout/header/Header';
import { LeadsFlowGenerator } from '../../../components/common/custom-leads-flow-generator/LeadsFlowGenerator';
import { lenderSpfDetailsFlow } from '../../../store/reducers/lead/leadSlice';
import { ABFLBusinessInfoForm } from './ABFLBusinessInfoForm';
import { ABFLOfferInfoForm } from './ABFLOfferInfoForm';
import { ABFLCheckOffer } from './ABFLCheckOffer';
import { ABFLFormPL } from './PL/ABFLFormPL';
import { ABFLTermsAndCondition } from './ABFLTermsAndCondition';

export const InitiateABFLLead: React.FC = () => {
	type ComponentType = React.ReactNode | React.ReactFragment;
	type StepsComponents = {
		step: string;
		component: ComponentType;
	};

	const dispatch = useDispatch();
	const router = useIonRouter();
	const leadFlowDataState: any = useSelector(
		(state: any) => state?.lead?.leadFlowData
	);
	const lenderSpfDetails = useSelector(
		(state: any) => state?.lead?.lenderSpecificDetails
	);
	useEffect(() => {
		if (!location.pathname.includes('/lead-lender-specific-form-steps')) {
			return;
		}
	}, [location.pathname]);

	const stepsComponents: StepsComponents[] = [
		{
			step: 'step1',
			component: <ABFLBusinessInfoForm />,
		},
		{
			step: 'step2',
			component: <ABFLCheckOffer />,
		},
		{
			step: 'step3',
			component: <ABFLOfferInfoForm />,
		},
	];

	const stepsComponentsPL: StepsComponents[] = [
		{
			step: 'step2',
			component: <ABFLFormPL />,
		},
		{
			step: 'step1',
			component: <ABFLTermsAndCondition />,
		},
	];

	const headerNeedArrowOnClick = async () => {
		switch (lenderSpfDetails?.step) {
			case 'step3':
				dispatch(
					lenderSpfDetailsFlow({
						...lenderSpfDetails,
						step: 'step2',
					})
				);
				router.push('/lead-lender-specific-form-steps');

				break;
			case 'step2':
				dispatch(
					lenderSpfDetailsFlow({
						...lenderSpfDetails,
						step: 'step1',
					})
				);
				break;
			case 'step1':
				dispatch(lenderSpfDetailsFlow({}));
				router.push('/lender-specific-info');
				break;
		}
	};

	const stepsHeader: ComponentType = (
		<Header
			name={'ABFL Specific Form'}
			backButton={true}
			needArrow={true}
			needCross={false}
			needArrowOnClick={() => headerNeedArrowOnClick()}
		/>
	);

	return (
		<>
			<LeadsFlowGenerator
				currentStep={lenderSpfDetails?.step}
				stepsHeader={stepsHeader}
				displayStepsCounter={lenderSpfDetails?.subHeaderStepsCounter}
				stepsComponents={
					leadFlowDataState?.selectedProductData?.product_category_id == 1
						? stepsComponentsPL
						: leadFlowDataState?.selectedProductData?.product_category_id ==
								2 && stepsComponents
				}
			/>
		</>
	);
};
