import * as yup from "yup";




const existing_policy_details_schema = yup.object({
  id: yup.number().notRequired(),
  vehicleDetails: yup.string().notRequired(),
  carType: yup.string().notRequired(),
  carMakeModel: yup.string().notRequired(), // corrected field name
  registrationNo: yup.string().notRequired(),
  sumAssured: yup.number().notRequired(),
  existingPolicyNumber: yup.string().notRequired(),
  insurerName: yup.string().notRequired(),
  planName: yup.string().notRequired(),
  issueDate: yup.date().notRequired().typeError("Please select valid date")
    .max(new Date(Date.now()), "Date cannot be in the future"),// corrected field name and validation message
  policyStatus: yup.string().notRequired(), // missing in the original schema
  goodHealth: yup.string().notRequired(),
  healthDetails: yup.string().notRequired(), // added to match the class fields
  policyNo: yup.string().notRequired(), // ensure alignment with class fields
  application_id: yup.mixed().notRequired(), // added to match class
  profileId: yup.number().notRequired(), // added to match class
});

export default existing_policy_details_schema;

export interface existingPolicyRequest {
  id: any;
  insurer_name: string;
  sum_assured: string;
  plan_name: string;
  policy_state: string;
  leadId: number;
  existing_policy_number: string;
  issued_date: string;
}
