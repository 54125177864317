import { createAction, createSlice } from '@reduxjs/toolkit';
import {
	CHANGE_LANGUAGE,
	CHOOSE_LANGUAGE_LIST,
	REFRESH_TOKEN,
	UPDATE_NOTIFICATION_STATUS,
	DELETE_USER,
} from '../../../helpers/api-endpoints';
import { get, post } from '../../../helpers/constant';
import { apiAction } from '../../apiAction';

const initialState = {
	// themeHeaderColor: "#FFFFFF",
	// themeBannerColor: "#F3F3F3"
};

export const apiLangList = apiAction('app/language', get, CHOOSE_LANGUAGE_LIST);

export const apiChooseLang = apiAction(
	'app/language/choose',
	post,
	CHANGE_LANGUAGE
);
export const refreshToken = apiAction('app/refresh-token', post, REFRESH_TOKEN);

export const apiAppNotification = apiAction(
	'app/notification/status',
	post,
	UPDATE_NOTIFICATION_STATUS
);
export const apiDeleteUser = apiAction('', post, DELETE_USER);
export const resetAppState = createAction('Reset_all');

const appSlice = createSlice({
	name: 'app',
	initialState: initialState,
	reducers: {
		appNotification: (state: any, action) => {
			state.notification_token = action.payload;
		},
		deviceId: (state: any, action) => {
			state.device_id = action.payload;
		},
		uuid: (state: any, action) => {
			state.uuid = action.payload;
		},
		otpScreenShowPage: (state: any, action) => {
			state.otpScreenShowPage = action.payload;
		},
		webView: (state: any, action) => {
			state.isWeb = action.payload;
		},
		ga4Data: (state: any, action) => {
			state.ga4Data = action.payload;
		},
		// themeHeaderColor: (state: any, action) => {
		//     state.themeHeaderColor = action.payload;
		// },
		// themeBannerColor: (state: any, action) => {
		// 	state.themeBannerColor = action.payload;
		//   },
	},
	extraReducers(builder) {
		builder
			.addCase(apiLangList.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(apiLangList.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(apiLangList.rejected, (state: any, action) => {
				state.isLoading = false;
				// state.languages = [];
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(apiChooseLang.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(apiChooseLang.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.choosedLang = action?.payload;
			})
			.addCase(apiChooseLang.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(apiAppNotification.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(apiAppNotification.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.choosedLang = action?.payload;
			})
			.addCase(apiAppNotification.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(apiDeleteUser.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(apiDeleteUser.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.choosedLang = action?.payload;
			})
			.addCase(apiDeleteUser.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(refreshToken.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(refreshToken.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.choosedLang = action?.payload;
			})
			.addCase(refreshToken.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(resetAppState, () => initialState);
	},
});

export const {
	uuid,
	appNotification,
	deviceId,
	otpScreenShowPage,
	webView,
	ga4Data,
} = appSlice.actions;

export default appSlice.reducer;
