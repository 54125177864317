import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  FormatDate,
  InputFormatCurrencyValue,
  findCurrentStatus,
  findSource,
  setBankIcon,
} from "../../../helpers/functions";
import { CustomButton } from "../custom-button/CustomButton";
import "./style.scss";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonLabel,
  IonPage,
  IonRow,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/react";
import { LEAD_STATUS, REDIRECT } from "../../../helpers/constant";
import { useTranslation } from "react-i18next";
import { Divider } from "../divider/Divider";
import { CustomSearchbar } from "../custom-searchbar/CustomSearchbar";
import { Header } from "../../layout/header/Header";
import { DASHBOARD_INSURANCE_LEAD_LIST, DASHBOARD_INSURANCE_LEAD_LIST_COPS_HEAD } from "../../../helpers/api-endpoints";
import useApi from "../../../api/useApi";
import { useBusyLoading } from "../../../context/BusyLoadingContext";
import { AuthContext } from "../../../context/AuthContext";
import InsuranceLeadListCards, { InsuranceLeadListCardsRequest } from '../../../model/lead_list_bean';
import LeadListData from '../../../model/lead_details_bean';
import InsuranceInfoContext from '../../../context/InsuranceInfoContext';
import FilterContext from '../../../context/FilterContext';
import { CustomNoData } from '../custom-no-data/CustomNoData';
interface InsuranceLeadListCardsProps {
  InsuranceLeadListCards?: InsuranceLeadListCards;
  LeadListData?: LeadListData;
}

const InsuranceLeadList: React.FC<InsuranceLeadListCardsProps> = () => {
  const from = "lead";
  const history = useHistory();
  const location = useLocation<any>();
  const [searchString, setSearch] = useState("");
  const [leadList, setLeadList] = useState<InsuranceLeadListCards[]>([]);
  const { leadStatus } = useContext(InsuranceInfoContext);
  const isLeadList = location.pathname === "/lead-list";
  const { t } = useTranslation();
  const { token, userId, isCOPS } = useContext(AuthContext);

  const {citySelection, insurerSelection,statusesSelection,subProductsSelection, filterFlag,lastUpdatedFromDate, lastUpdatedToDate} = useContext(FilterContext);

  const extractNames = (selections: { name?: string }[]): string | null => {
    const names = selections.map(selection => selection.name).filter(name => name);
    return names.length ? names.join(', ') : null;
  };
  
  const [pageId, setPageId] = useState(1);
  const [pageSize] = useState(10);
  const [lastFetchedPage, setLastFetchedPage] = useState(0);
  const [disableInfiniteInstance, setDisableInfiniteInstance] = useState(false);

  const apiEndpoint = isCOPS ? DASHBOARD_INSURANCE_LEAD_LIST_COPS_HEAD : `${DASHBOARD_INSURANCE_LEAD_LIST}?pageId=${pageId}&pageSize=${pageSize}`;
  const { responseData: leadListData, error: leadListError, loading: leadListLoading, executeRequest: getLeadList } = useApi<any>(apiEndpoint);
  const { setBusyState } = useBusyLoading();
  // const labelName = location.state?.labelName;
    const labelName = localStorage.getItem('labelName') || ' ';
    console.log(labelName);


    const constructPayload = (searchString: string, product_id: number | undefined) => {
      const payload: InsuranceLeadListCardsRequest = {
        searchItem: searchString,
        lead_status: labelName,
        product_id: 2,
      };
    
      if (filterFlag) {
        const hasSelections = [
          subProductsSelection,
          citySelection,
          insurerSelection,
          statusesSelection,
        ].some(selection => extractNames(selection));

        const hasDateRange = lastUpdatedFromDate || lastUpdatedToDate;
    
        if (hasSelections || hasDateRange) {
          // Only include the parameters if filterFlag is true and parameters have values
          if (extractNames(subProductsSelection)) {
            payload.subproduct = extractNames(subProductsSelection);
          }
          if (extractNames(citySelection)) {
            payload.city = extractNames(citySelection);
          }
          if (extractNames(insurerSelection)) {
            payload.insurer = extractNames(insurerSelection);
          }
          if (extractNames(statusesSelection)) {
            payload.status = extractNames(statusesSelection);
          }
          if (lastUpdatedFromDate) {
            payload.fromDate = lastUpdatedFromDate;
          }
          if (lastUpdatedToDate) {
            payload.toDate = lastUpdatedToDate;
          }
          payload.filterFlag = filterFlag;
        }
      }
      return payload;
    };
    
  const fetchData = useCallback(() => {
    const payload = constructPayload(searchString, 2);
    getLeadList("post", payload, true);
  }, [pageId,searchString, subProductsSelection, citySelection, insurerSelection,statusesSelection, filterFlag,lastUpdatedFromDate, lastUpdatedToDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setBusyState(leadListLoading);
  }, [leadListLoading, setBusyState]);

  useEffect(() => {
    if (leadListData?.status) {
      setLeadList(prevLeadList => {
        const filteredData = leadListData.data.filter((newItem: any) =>
          !prevLeadList.some(prevItem => prevItem.id === newItem.id)
        );
        return [...prevLeadList, ...filteredData];
      });

      // Update last fetched page and pageId if a full page of data was received
      if (leadListData.data.length === pageSize) {
        setLastFetchedPage(pageId);
      } else {
        setDisableInfiniteInstance(true); // Disable infinite scroll if no more data
        
      }
    }
  }, [leadListData, pageSize, pageId]);

  const handleLeadDetails = (item: any) => {
    history.push({
      pathname: `/insurance-lead-details`,
      state: { id: item.id,
               labelName: labelName,
       }
    });
  };

  const loadMoreData = (ev: any) => {
    ev.target.complete();
    setPageId(lastFetchedPage + 1); // Increment pageId based on last fetched page
  };

  const handleSearch = useCallback((search: string, item: any) => {
    setPageId(1);
    setLastFetchedPage(0);
    setLeadList([]);
    setDisableInfiniteInstance(false);
    setSearch(search);
    const payload = constructPayload(search, item?.product_id);
    getLeadList("post", payload, true);
  }, [pageSize, pageId,subProductsSelection, citySelection, insurerSelection, statusesSelection,
     filterFlag]);

  return (
    <IonPage id="main-content" className="lead-list justify-content-start">
      <Header
        className="card-header"
        name={labelName === 'all' ? 'All Leads' : labelName}
        backButton={true}
        needArrow={true}
        needArrowOnClick={() => {
        // history.push('/dashboard/home');
        history.push(REDIRECT.DASHBOARD);
        }}
      />
      <IonContent>
        <div className="lead-card-container-spacing pb-md-2 py-md-0 bg-web-white">
        <div className="d-flex mt-3 mx-3 justify-content-between align-items-center">
          <div className="d-flex flex-grow-1">
            <CustomSearchbar
              value={searchString}
              searchIcon="/images/icons/searchbar-icon.svg"
              placeholder={t("Search Lead by “Name”")}
              showClearButton="focus"
              onChange={(v: any, item: any) => {
                if (!v.length) {
                  setLeadList([]);
                }
                handleSearch(v, item);
                setSearch(v);
              }}
              needFilter={true}
              onFilterClick={() =>
								history.push('/lead-filters')
							}
            />
          </div>
        </div>
      </div>
      {leadList.length ? (
        <div>
      <div className="row overflow_flex lead-card-container-spacing bg-web">
        {leadList && leadList.map((item: any, index: any) => (
            <div
              className="col-lg-4 col-12 col-md-6 d-flex gx-0 card-height"
              key={index}
            >
              <IonCard
                className="lead-contact-card-conatiner m-md-2 border-md-radius-10 d-flex flex-column w-100"
                 mode="md"
                onClick={() => handleLeadDetails(item)}
              >
                <IonCardHeader className="d-flex flex-row justify-content-between pb-1">
                  <IonCardSubtitle className="fs-12 text-grey">
                    # {from.toLowerCase() === "lead" ? "L" : "D"}
                    {item?.id}{" "}
                    {(LEAD_STATUS.DISBURSED_LABEL == leadStatus ||
                      LEAD_STATUS.LOGIN_LABEL == leadStatus ||
                      LEAD_STATUS.SANCTIONED_LABEL == leadStatus ||
                      LEAD_STATUS.CLOSED_LABEL === leadStatus ||
                      LEAD_STATUS.APPLICATION_FULFILLED === leadStatus) &&
                      item?.lead_app_id && (
                        <div className="ml-3 px-4 d-inline-block">{`# A${item?.lead_app_id}`}</div>
                      )}
                  </IonCardSubtitle>
                  <IonCardSubtitle className="fs-12 text-grey mt-0">
                    {FormatDate(item?.createdAt, "dd mmmm yyyy")}
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonCardHeader
                  className={`py-0 mb-1 d-flex flex-row 
                    justify-content-between
                    `}
                >
                  <IonCardTitle
                    className="fs-16 fw-600 darkest-grey text-capitalize m-0 cursor-pointer"
                  >
                    {(item?.first_name ? item?.first_name : "Unknown") +
                      " " +
                      (item?.middle_name ? item?.middle_name : "") +
                      " " +
                      (item?.last_name ? item?.last_name : "")}
                  </IonCardTitle>
                  {(leadStatus === "all" ||
                    leadStatus === LEAD_STATUS.UNDERPROCESS_LABEL) &&
                    item?.lead_status &&
                    from === "lead" && (
                      <CustomButton
                        size={"extra small"}
                        fill="outline"
                        title={findCurrentStatus(item?.lead_status)}
                        className={"lead-contact-card-button1"}
                      />
                    )}
                </IonCardHeader>
                <IonCardContent className="py-md-0 border-md-radius-10 d-flex flex-column h-100 card-content-padding">
                  <div>
                    <IonGrid className="py-md-0">
                      <IonRow>
                      {item?.category_name && (
                          <IonCol size="5">
                            <img
                              className="me-1"
                              // src="/images/icons/leads/sub-product.svg"
                              src="https://oneandro-crm-static.s3.ap-south-1.amazonaws.com/prod/category/1704709911083-Life_Insurance.png"
                              height={16}
                              alt="type"
                            />{" "}
                            <IonLabel className="text-capitalize">
                              {item?.category_name}
                            </IonLabel>
                          </IonCol>
                        )}
                        {item?.lead_status && (
                          <IonCol size="5">
                            <img
                              className="me-1"
                              src="/images/icons/leads/sub-product.svg"
                              height={16}
                              alt="type"
                            />{" "}
                            <IonLabel className="text-capitalize">
                              {item?.lead_status}
                            </IonLabel>
                          </IonCol>
                        )}
                        {item?.city && (
                          <IonCol size="4">
                            <img
                              className="me-1"
                              src="/images/icons/list-user-location-icon.svg"
                              alt="type"
                            />{" "}
                            <IonLabel className="text-capitalize">
                              {item?.city}
                            </IonLabel>
                          </IonCol>
                        )}
                        {item?.lead_current_assignee_name &&
                          from === "lead" && (
                            <IonCol size="3" className="d-flex ps-0">
                              <img
                                height={16}
                                className="me-1"
                                src="/images/icons/leads/sub-product.svg"
                                alt="type"
                              />{" "}
                              <IonLabel className="text-capitalize">
                                {item?.lead_status}
                              </IonLabel>
                            </IonCol>
                          )}
                      </IonRow>
                    </IonGrid>
                  </div>
                  <div>
                    {true && (
                      <>
                        <Divider className="padding-top-border" />
                        <IonGrid>
                          <IonRow>
                             
                              <IonCol size="6" className="d-flex flex-column">
                                <IonLabel
                                  className="fs-12"
                                  style={{ color: "var(--text-dark-grey)" }}
                                >
                                  {t("Premium Amount")}
                                </IonLabel>
                                <IonLabel className="fw-600 d-flex">
                                  <img
                                    src="/images/icons/leads/rupees-icon.svg"
                                    alt="rupees icon"
                                  />
                                  &nbsp;
                                  {InputFormatCurrencyValue(
                                    item?.premium_amount
                                  )}
                                </IonLabel>
                              </IonCol>
                            
                            <IonCol
                              size="6"
                              className="p-0"
                            >
                              <div
                                className={`d-flex justify-content-end align-items-center`}
                              >
                                <div className="d-flex gap-2">
                                  <a
                                    href={`tel:${
                                      from === "lead"
                                        ? item?.mobile_no
                                        : item?.mobile
                                    }`}
                                  >
                                    <img
                                      src="/images/icons/leads/phone.svg"
                                      alt="phone"
                                      className="phone-icon"
                                    />
                                  </a>
                                  <a
                                    href={`https://api.whatsapp.com/send?phone=91${
                                      from === "lead"
                                        ? item?.mobile_no
                                        : item?.mobile
                                    }`}
                                    target="_blank"
                                  >
                                    <img
                                      src="/images/icons/leads/whatsapp.svg"
                                      alt="whatsapp"
                                      className="whatsapp-icon"
                                    />
                                  </a>
                                  <span
                                    className={isLeadList ? "d-none" : ""}
                                    style={{
                                      width: "32px",
                                      height: "32px",
                                      borderRadius: "50px",
                                      background: "#F3F3F3",
                                      padding: "3px 8px",
                                      display: "flex",
                                    }}
                                  >
                                    <img
                                      src="/images/icons/teams/view-dots.svg"
                                      alt="whatsapp"
                                    />
                                  </span>
                                </div>
                              </div>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </>
                    )}
                  </div>
                </IonCardContent>
                <Divider className="hr-card-list d-block d-md-none" />
              </IonCard>
            </div>
          ))}
      </div>
      <IonInfiniteScroll
              threshold="100px"
              disabled={disableInfiniteInstance}
              onIonInfinite={loadMoreData}
            >
              <IonInfiniteScrollContent
                loadingSpinner="bubbles"
              ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        </div>
      ) : (
        <CustomNoData />
      )}
      </IonContent>
    </IonPage>
  );
};

export default InsuranceLeadList;
