import {
	IonContent,
	IonImg,
	IonItem,
	IonLabel,
	IonList,
	IonPage,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { Header } from '../../../components/layout/header/Header';

// import { StatusNum } from '../../../helpers/constant';
import { CustomSearchbar } from '../../../components/common/custom-searchbar/CustomSearchbar';
import { CustomNoData } from '../../../components/common/custom-no-data/CustomNoData';
// import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	apiDmaInvite,
	getdmaList,
	resendAndroEmployeeInvite,
} from '../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { USER_ROLES } from '../../../helpers/constant';
import { useTranslation } from 'react-i18next';
import { sanitizeHtml } from '../../../helpers/functions';

export default function TeamsDmaInvites() {
	const dispatch: any = useDispatch();
	const [invitedDma, setInvitedDma] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const { t } = useTranslation();
	const dmaSelector = useSelector((state: any) => state?.users);
	const role = useSelector((state: any) => state?.users?.user_details);

	useEffect(() => {
		getInviteList();
	}, []);
	const getInviteList = async (searchItem = searchQuery) => {
		dispatch(
			getdmaList({
				param: `?pageId=1&size=50&status=${
					dmaSelector?.dmaFlow?.dmaCode
				}&searchItem=${sanitizeHtml(encodeURIComponent(searchItem))}`,
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setInvitedDma(result?.list);
				}
			});
	};
	const resendDmaInvite = async (mobile: any) => {
		switch (role?.roleType?.[0]) {
			case USER_ROLES.DMA:
				return await dispatch(
					apiDmaInvite({ data: { mobile: Number(mobile) } })
				);
			case USER_ROLES.BM:
			case USER_ROLES.BUSINESSOPS:
			case USER_ROLES.BUHOD:
			case USER_ROLES.BUH:
			case USER_ROLES.TC:
				return await dispatch(
					resendAndroEmployeeInvite({ data: { mobile: Number(mobile) } })
				);
		}
	};
	const searchInvites = (value: any) => {
		if (value.length >= 3) {
			// getInviteList(value, null, null, 1, pageSize);
			let debounceTimeout: any = 0;
			setSearchQuery(value);
			clearTimeout(debounceTimeout);
			debounceTimeout = setTimeout(() => {
				getInviteList(value);
			}, 2000); // Debounce interval of 2000ms
		} else if (value.length === 0) {
			// setSearchValue('');
			// getInviteList(null, null, null, currentPage, pageSize);
			let debounceTimeout: any = 0;
			setSearchQuery('');
			clearTimeout(debounceTimeout);
			debounceTimeout = setTimeout(() => {
				getInviteList(value);
			}, 2000); // Debounce interval of 2000ms
		}
	};
	return (
		<IonPage>
			<Header
				needArrow={true}
				backButton={true}
				name={dmaSelector?.dmaFlow?.title}
				style={{ borderBottom: '1px solid #00000029' }}
			/>
			<IonContent className='background-web'>
				<div className='bg-web my-md-4'>
					<div className='container-width-md bg-web-white  border-md-radius-10 '>
						<div className='p-3 d-flex align-items-center justify-content-center w-100'>
							<CustomSearchbar
								value={searchQuery}
								searchIcon='/images/icons/searchbar-icon.svg'
								placeholder={t('Search Invitee by “Name”')}
								showClearButton='focus'
								onChange={searchInvites}
							/>
						</div>
						<IonList
							lines='full'
							style={{
								overflowY: 'auto',
								height: 'calc(100vh - 140px)',
							}}
						>
							{invitedDma.length ? (
								invitedDma.map((item: any, index: any) => {
									return (
										<IonItem key={index}>
											<IonLabel className='fs-14 fw-600 darkest-grey'>
												{item?.first_name} {item?.last_name}
											</IonLabel>
											<IonImg
												src='/images/icons/resend-invite.svg'
												onClick={() => {
													resendDmaInvite(item.mobile);
												}}
											/>
										</IonItem>
									);
								})
							) : (
								<CustomNoData />
							)}
						</IonList>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
}
