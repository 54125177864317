/* eslint-disable @typescript-eslint/no-explicit-any */
import { Header } from '../../components/layout/header/Header';
import { useEffect, useState } from 'react';
import './Products.scss';
import { useHistory } from 'react-router';
import {
	IonCol,
	IonContent,
	IonGrid,
	IonList,
	IonRow,
	isPlatform,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import '../select-lead-product/SelectLeadProduct.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	getProductList,
	imgUrlUpdate,
	productFlowData,
} from '../../store/reducers/user/userSlice';
import { Sidebar } from '../../components/layout/sidebar/Sidebar';
import { unwrapResult } from '@reduxjs/toolkit';
import { Menu } from '../../components/layout/menu/Menu';
import { gradientArray } from '../../helpers/constant';
import { eventAttributes, notifyHandler } from '../../helpers/functions';
import { NotifyVisitors } from 'capacitor-plugin-notifyvisitors';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
interface ProductData {
	name: string;
	id: number;
	icon: string;
	category: CategoryData[];
}

interface CategoryData {
	name: string;
	cat_id: number;
	purpose: PurchaseData[];
}

interface PurchaseData {
	name: string;
	purpose_id: number;
}

export const Products: React.FC = () => {
	const dispatch: any = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();
	const productList = useSelector((state: any) => state?.users?.productList);
	const homePageData = useSelector((state: any) => state?.users);
	const appWeb = useSelector((state: any) => state?.app?.isWeb);
	const [src, setSrc] = useState('');
	const defaultSrc = '/images/icons/default/user-profile-icon.svg';
	// Notify Visitors
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const userName = useSelector(
		(state: any) => state?.users?.homepage?.profile?.name
	);
	const userMobile = useSelector(
		(state: any) => state?.users?.user_details?.mobile
	);

	const entityType = useSelector(
		(state: any) => state?.users?.user_details?.entityType
	);

	const userLang = useSelector(
		(state: any) => state?.oneTimeScreen?.language?.name
	);

	const getProducts = async () => {
		await dispatch(getProductList());
	};

	useEffect(() => {
		getUpdateUrl();
	}, [homePageData?.homepage?.profile?.url]);

	const userAttributes = {
		attributes: {
			name: userName,
			id: userId,
			mobile: userMobile,
			user_language: userLang,
			entityType:
				entityType === 'Individual'
					? 'DMA'
					: entityType === 'BTM'
					? 'employee'
					: entityType,
		},
	};

	useEffect(() => {
		getProducts();
		if (isPlatform('android') || isPlatform('ios')) {
			notifyHandler('event', eventAttributes('product_page'));
			notifyHandler('user', userAttributes.attributes);
			const inAppData = {
				tokens: { name: userAttributes },
				customRules: { banerrType: 'product page' },
			};
			NotifyVisitors.show(inAppData);
		} else {
			(window as any).nv('event', 'product_page', 10, 1);
			(window as any).nv('user', userId, userAttributes.attributes);
		}
	}, []);

	const getUpdateUrl = async () => {
		if (homePageData?.homepage?.profile?.url) {
			await dispatch(
				imgUrlUpdate({
					data: {
						url: homePageData?.homepage?.profile?.url,
						expire_time: 50000,
					},
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						setSrc(response?.url);
					}
				});
		} else {
			setSrc(defaultSrc);
		}
	};

	return (
		<>
			<Menu
				name={homePageData?.homepage?.profile?.name}
				imgSrc={src}
			/>
			<div
				id='dashboard-main-content-menu'
				className='dashboard h-100'
			>
				<Header
					name={t('Products')}
					type={appWeb ? 'dashboard' : ''}
					hideWelecomeText={appWeb}
					src={src}
					style={{ borderBottom: '1px solid #00000029' }}
				/>
				<div className='d-flex'>
					<Sidebar />

					<IonContent
						// style={{
						// 	borderTop: '1px solid #00000029',
						// 	height: 'calc(100vh - 113px)',
						// 	overflowX: 'hidden',
						// }}
						className='dashboard-main dashboard-height'
					>
						<div className='w-100 product-container-height dashboard-container-spacing bg-web'>
							<IonGrid className='bg-web-white prodduct-card-height'>
								<IonRow>
									{productList?.map((item: ProductData, index: any) => (
										<IonCol
											size='6'
											sizeMd='4'
											sizeLg='3'
											className='product-item'
											key={item?.id}
										>
											<IonList
												onClick={() => {
													if (item?.category?.length > 0) {
														const myPromise = new Promise(function (myResolve) {
															setTimeout(function () {
																myResolve(history.push('/sub-products'));
															}, 10);
														});
														myPromise.then(() => {
															dispatch(
																productFlowData({
																	id: item?.id,
																	productName: item?.name,
																})
															);
														});
													}
												}}
												className='cursor-pointer'
											>
												<div
													className={`product-list text-center select-product-${item?.icon}`}
													style={{ background: gradientArray[index % 4] }}
												>
													<img
														alt={item.name}
														src={item.icon}
														className='product-img mt-3'
													/>
													<div className='font-semibold fs-16 my-1'>
														{t(item.name)}
													</div>
													{item?.category.length > 0 ? (
														<div
															className='font-regular fs-12'
															style={{
																color: '#A0A4B3',
															}}
														>
															{item?.category.length} {t('Categories')}
														</div>
													) : (
														''
													)}
												</div>
											</IonList>
										</IonCol>
									))}
								</IonRow>
							</IonGrid>
						</div>
					</IonContent>
				</div>
			</div>
		</>
	);
};
