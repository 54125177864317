import {
	IonContent,
	IonFooter,
	IonImg,
	IonRadio,
	IonRadioGroup,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	apiGenderDropdown,
	bajajAssignLead,
	bajajGetToken,
	getLeadFlowUrl,
	lenderSpfDetailsFlow,
} from '../../../../store/reducers/lead/leadSlice';
import { CustomInput } from '../../../../components/common/custom-input/CustomInput';
import {
	ContinueLaterPopUp,
	FormatDate,
	checkFieldsValidToContinue,
	disableNumberArrows,
	fetchStateCityByPincode,
	// fetchStateCityByPincode,
	getBlankKeyFromObject,
	getEncrypted,
	showToastAlert,
} from '../../../../helpers/functions';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import '../../TataCapitals/TataCapitals';
import { LenderNameForAPICall } from '../../../../helpers/lenders';
import { DynamicDropdown } from '../../../../components/common';
import { LENDER_SPECIFIC_DATA, REGEX } from '../../../../helpers/constant';
// import StateCity from '../../../../components/common/StateCity';
import {
	ApplicantType,
	Constitution,
	CustomerType,
	bajajBLHLSelectionFields,
	bajajBLHLSelectionFieldsSchema,
	bajajBLLAPvalidationSchema,
	coApplicantDetails,
	employmentType,
	isCoApplicant,
	productBLLAP,
} from '../BajajFinance';
import { unwrapResult } from '@reduxjs/toolkit';
import { CustomCheckbox } from '../../../../components/common/custom-checkbox/CustomCheckbox';
import { OfferRejectedMsg } from '../../OfferRejectedMsg/OfferRejectedMsg';

export const BLLAPCoApplicantDetails: React.FC<any> = () => {
	const [modalPageClose, setModalPageClose] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const [url, setUrl] = useState('');
	const { selectedLender, lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);
	const [genderApi, setGenderApi] = useState([]);
	const [pinCodeData, setPinCodeData] = useState<any>([]);

	const [selectCoApplicant, setSelectCoApplicant] = useState('No');
	const [selectConstitution, setSelectConstitution] = useState('');
	const [selectCustomerType, setSelectCustomerType] = useState('');
	const [selectApplicantType, setSelectApplicantType] = useState('');
	const [selectApplicantEmploymentType, setSelectApplicantEmploymentType] =
		useState('');
	const [schema, setSchema] = useState<any>(bajajBLHLSelectionFieldsSchema);
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');

	const fetchSchema = () => {
		if (selectCoApplicant == 'Yes') {
			const someSchema = bajajBLHLSelectionFieldsSchema.concat(
				bajajBLLAPvalidationSchema
			);
			setSchema(someSchema);
		} else {
			setSchema(bajajBLHLSelectionFieldsSchema);
		}
	};

	useEffect(() => {
		fetchSchema();
	}, [selectCoApplicant]);

	const {
		handleSubmit,
		control,
		getValues,
		formState: { errors, isValid },
		setValue,
		trigger,
	} = useForm({
		resolver: yupResolver(schema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			Product__c: '',
			CoApplicant: 'No',
			Salutation: '',
			First_Name: '',
			Last_Name: '',
			PAN: '',
			Gender: '',
			Date_of_Birth: '',
			Mobile_Number: '',
			co_applicants_address_1: '',
			co_applicants_address_2: '',
			co_applicants_address_3: '',
			co_applicants_pincode: '',
			lead_spf_detail_id: '',
		},
	});

	/**
	 * On submit to save user data and after that
	 * @param data Form data
	 */

	const getToken = async () => {
		await dispatch(
			bajajGetToken({
				data: {
					lead_application_id: leadFlowData?.lead_app_id,
					lender_id: selectedLender?.[0]?.id,
					lead_id: leadFlowData?.leadId,
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				dispatch(
					lenderSpfDetailsFlow({
						...lenderSpecificDetails,
						bajajAccessToken: result?.data?.access_token,
						lead_spf_detail_id: result?.lead_lender_specific_id,
					})
				);
			});
	};
	useEffect(() => {
		if (!location.pathname.includes('/lead-lender-specific-form-steps')) {
			return;
		} else {
			getGenderAndMaritalDropDown();
			getToken();
			const fetchLeadFlowUrl = async () => {
				try {
					const response = await dispatch(getLeadFlowUrl());
					const url = response.payload.url;
					const pathname = new URL(url).origin;
					setUrl(pathname);
				} catch (error) {
					console.error('Error fetching lead flow URL:', error);
				}
			};
			fetchLeadFlowUrl();
		}
	}, [location.pathname]);

	const getGenderAndMaritalDropDown = async () => {
		await dispatch(apiGenderDropdown())
			.then(unwrapResult)
			.then((result: any) => {
				setGenderApi(result.data);
			});
	};

	const onSubmit = async (data: any) => {
		console.log(data, 'data on submit');

		const blankKey = getBlankKeyFromObject(JSON.parse(JSON.stringify(data)));
		const endpoint = '/lead-lender-specific-form-steps';
		const req: any = {
			lender_spf_details: data,
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.TATA_BL,
			step: Object.keys(blankKey).length > 0 ? 'step2' : 'step3',
			isNewLead: Object.keys(blankKey).length === 0,
			// sourceSystemURL: `${process.env.REACT_APP_UI_ENDPOINT}/lead-lender-specific-form-steps`,
			sourceSystemURL: url + endpoint,
		};

		if (lenderSpecificDetails?.lead_spf_detail_id) {
			req.lead_spf_detail_id = lenderSpecificDetails?.lead_spf_detail_id;
		}

		const encodedData = getEncrypted(
			JSON.stringify({
				Product__c: data.Product__c,
				Salutation: data.Salutation,
				co_applicants_first_name: data.First_Name,
				co_applicants_last_name: data.Last_Name,
				co_applicants_pan: data.PAN,
				co_applicants_gender: data.Gender,
				co_applicants_dob: data.Date_of_Birth,
				co_applicants_mobile: data.Mobile_Number,
				co_applicants_address_1: data.co_applicants_address_1,
				co_applicants_address_2: data.co_applicants_address_2,
				co_applicants_address_3: data.co_applicants_address_3,
				co_applicants_pincode: data.co_applicants_pincode,
			})
		);

		localStorage.setItem(LENDER_SPECIFIC_DATA, encodedData);
		try {
			await dispatch(
				bajajAssignLead({
					data: {
						Product__c: data.Product__c,
						salutation: data.Salutation,
						co_applicants_first_name: data.First_Name,
						co_applicants_last_name: data.Last_Name,
						co_applicants_pan: data.PAN,
						co_applicants_gender: data.Gender,
						co_applicants_dob: FormatDate(data.Date_of_Birth, 'dd/mm/yyyy'),
						co_applicants_mobile: data.Mobile_Number,
						co_applicants_address_1: data.co_applicants_address_1,
						co_applicants_address_2: data.co_applicants_address_2,
						co_applicants_address_3: data.co_applicants_address_3,
						co_applicants_pincode: data.co_applicants_pincode,
						co_applicants_emp_type: data.Employment_Type,
						token: lenderSpecificDetails?.bajajAccessToken,
						lead_spf_detail_id: lenderSpecificDetails?.lead_spf_detail_id,
						isCoApplicant: selectCoApplicant,
						Constitution: selectConstitution,
						CustomerType: selectCustomerType,
						ApplicantType: selectApplicantType,
						ApplicantEmpType: selectApplicantEmploymentType,
					},
				})
			)
				.then(unwrapResult)
				.then((res: any) => {
					if (res?.data?.Success) {
						localStorage.setItem('prospectId', res?.data?.prospectId);
						dispatch(
							lenderSpfDetailsFlow({
								...lenderSpecificDetails,
								token: lenderSpecificDetails?.bajajAccessToken,
								prospectId: res?.data?.ProspectId,
								offerId: res?.data?.OfferId,
								product: res?.data?.ProductName,
								step: 'step2',
							})
						);
					} else {
						setShowOfferErrMsg('Something went wrong! Please try again later.');
					}
				});
		} catch (error) {
			console.error('Error In Partner Eligiliblity:', error);
			setShowOfferErrMsg('Something went wrong! Please try again later.');
		}
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
							<form onSubmit={handleSubmit(onSubmit)}>
								{bajajBLHLSelectionFields.map(
									({ name, placeholder }: any, index: any) => {
										const errorText: any =
											errors && Object.keys(errors).length > 0 && errors;
										return (
											<div key={index}>
												<div style={{ marginBottom: '15px' }}>
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<DynamicDropdown
																label={t(`${placeholder}`)}
																data={
																	name === 'Product__c'
																		? productBLLAP
																		: name === 'CoApplicant'
																		? isCoApplicant
																		: name === 'Constitution'
																		? Constitution
																		: name === 'CustomerType'
																		? CustomerType
																		: name === 'ApplicantType'
																		? ApplicantType
																		: name === 'ApplicantEmpType'
																		? employmentType
																		: []
																}
																name={name}
																onChange={(e: any) => {
																	onChange(e);
																	if (name === 'CoApplicant') {
																		setSelectCoApplicant(getValues(name));
																	} else if (name === 'Constitution') {
																		setSelectConstitution(getValues(name));
																	} else if (name === 'CustomerType') {
																		setSelectCustomerType(getValues(name));
																	} else if (name === 'ApplicantType') {
																		setSelectApplicantType(getValues(name));
																	} else if (name === 'ApplicantEmpType') {
																		setSelectApplicantEmploymentType(
																			getValues(name)
																		);
																	}
																}}
																bindField={'value'}
																showField1={
																	name === 'loan_purpose' ? 'title' : 'value'
																}
																value={getValues(name)}
																onBlur={onBlur}
																errors={errorText[name]?.message}
															/>
														)}
														name={name}
													/>
												</div>
											</div>
										);
									}
								)}
								{selectCoApplicant == 'Yes' && (
									<>
										{coApplicantDetails.map(
											(
												{ name, type, placeholder, maxLength }: any,
												index: any
											) => {
												const errorText: any =
													errors && Object.keys(errors).length > 0 && errors;
												return (
													<div key={index}>
														{type === 'radio' ? (
															<div
																key={index}
																className='d-flex align-items-center gap-1 mb-3'
															>
																<div>
																	<h6 className='mt-3'>{t(placeholder)}</h6>
																	<Controller
																		control={control}
																		render={({
																			field: { onChange, onBlur, name },
																		}) => (
																			<>
																				<IonRadioGroup
																					onIonChange={onChange}
																					onBlur={onBlur}
																					name={name}
																					value={getValues(name)}
																				>
																					<IonRadio
																						className='me-4'
																						labelPlacement='end'
																						value='Mr.'
																					>
																						{t('Mr') + '.'}
																					</IonRadio>
																					<IonRadio
																						className='me-4'
																						labelPlacement='end'
																						value='Ms.'
																					>
																						{t('Ms') + '.'}
																					</IonRadio>
																					<IonRadio
																						className='me-4'
																						labelPlacement='end'
																						value='Mrs.'
																					>
																						{t('Mrs') + '.'}
																					</IonRadio>
																					<IonRadio
																						labelPlacement='end'
																						value='Dr.'
																					>
																						{t('Dr') + '.'}
																					</IonRadio>
																				</IonRadioGroup>
																				<div className='error-text px-3 sc-ion-input-md'>
																					{t(errorText[name]?.message)}
																				</div>
																			</>
																		)}
																		name={name}
																	/>
																</div>
															</div>
														) : type === 'dropdown' ? (
															<div style={{ marginBottom: '15px' }}>
																<Controller
																	control={control}
																	render={({
																		field: { onChange, onBlur, name },
																	}) => (
																		<DynamicDropdown
																			label={t(`${placeholder}`)}
																			data={
																				name === 'Gender'
																					? genderApi
																					: name === 'Employment_Type'
																					? employmentType
																					: []
																			}
																			name={name}
																			onChange={(e: any) => {
																				onChange(e);
																			}}
																			bindField={'value'}
																			showField1={
																				name === 'loan_purpose'
																					? 'title'
																					: 'value'
																			}
																			value={getValues(name)}
																			onBlur={onBlur}
																			errors={errorText[name]?.message}
																		/>
																	)}
																	name={name}
																/>
															</div>
														) : type == 'checkbox' ? (
															<div key={index}>
																<Controller
																	control={control}
																	render={({
																		field: { onChange, onBlur, value },
																	}) => (
																		<CustomCheckbox
																			name={name}
																			onChange={({
																				detail: { checked },
																			}: any) => {
																				onChange(checked);
																			}}
																			value={value}
																			labelPlacement='end'
																			className='end-of-page'
																			onBlur={onBlur}
																			isChecked={value}
																			children={
																				<span className='text-wrap'>
																					{t(placeholder)}
																				</span>
																			}
																		></CustomCheckbox>
																	)}
																	name={name}
																/>
															</div>
														) : (
															<Controller
																control={control}
																render={({
																	field: { onChange, onBlur, name },
																}) => (
																	<CustomInput
																		type={type}
																		label={t(placeholder)}
																		onBlur={onBlur}
																		id={name}
																		onChange={(e: any) => {
																			if (name == 'co_applicants_pincode') {
																				if (e.detail.value.length === 6) {
																					fetchStateCityByPincode(
																						setValue,
																						'city',
																						'state',
																						setPinCodeData,
																						dispatch,
																						e.detail.value,
																						trigger
																					);
																				}
																			}
																			onChange(e);
																		}}
																		value={getValues(name)}
																		onBeforeInput={(evt: any) => {
																			if (type == 'number') {
																				!REGEX.ALLOWNUMBERS.test(evt.data) &&
																					evt.preventDefault();
																			}
																		}}
																		onKeyDown={(e: any) => {
																			if (type == 'number') {
																				disableNumberArrows(e);
																			}
																		}}
																		onWheel={(e: any) => {
																			if (type == 'number') {
																				e.target.blur();
																			}
																		}}
																		className={`${
																			name === 'PAN' ? 'text-uppercase' : ''
																		}${
																			['First_Name', 'Last_Name'].includes(name)
																				? ' text-capitalize '
																				: ''
																		}`}
																		errors={
																			name == 'co_applicants_pincode'
																				? pinCodeData?.isPincodeError
																					? pinCodeData?.pincodeErrorMsg
																					: ''
																				: t(errorText[name]?.message || '')
																		}
																		needSuffixIcon={name === 'Date_of_Birth'}
																		suffixClassName={
																			'position-absolute date-of-birth-sufix'
																		}
																		suffixText={
																			<IonImg
																				src='/images/icons/input-dob-calendar-icon.svg'
																				alt='Date_of_Birth'
																			/>
																		}
																		outerClassName={`${
																			name === 'Date_of_Birth'
																				? 'position-relative'
																				: ''
																		}`}
																		maxLength={maxLength}
																	/>
																)}
																name={name}
															/>
														)}
													</div>
												);
											}
										)}
									</>
								)}
							</form>

							<div className={` d-none d-md-block`}>
								<CustomButton
									className='w-100 fw-bold dashboard-add-lead-button mb-3'
									expand='block'
									fill='outline'
									title={t('Continue Later')}
									onClick={() => {
										if (checkFieldsValidToContinue(errors)) {
											setModalPageClose(true);
										} else {
											showToastAlert({
												type: 'error',
												message: 'Please fix above errors',
											});
										}
									}}
								/>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									disabled={!isValid || pinCodeData?.isPincodeError}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				)}

				<CustomModal
					needIonContentWraper={false}
					isOpen={modalPageClose}
					initialBreakpoint={undefined}
					handleClose={() => setModalPageClose(false)}
					breakpoints={[]}
					className='height-auto confirm-popup-middle continue-later'
					modalContent={ContinueLaterPopUp(
						() => {
							setModalPageClose(false);
						},
						() => {
							new Promise(function (myResolve) {
								setTimeout(function () {
									myResolve(history.push('/dashboard/lead'));
									// onSubmit(getValues());
									setModalPageClose(false);
								}, 10);
							});
						},
						'Cancel',
						'Continue Later',
						'className',
						'Are You Sure You Want to Exit?',
						`${t('You can continue later from where you left off.')}`
					)}
					needCross={false}
					needArrow={false}
				/>
			</IonContent>

			{showOfferErrMsg ? (
				<>
					<OfferRejectedMsg message={showOfferErrMsg} />
				</>
			) : (
				<IonFooter className='d-block d-md-none position-relative'>
					<div
						style={{
							backgroundColor: '#fff',
							zIndex: 2,
						}}
					>
						<div className={`ion-padding`}>
							<CustomButton
								className='w-100 fw-bold dashboard-add-lead-button mb-3'
								expand='block'
								fill='outline'
								title={t('Continue Later')}
								onClick={() => {
									if (checkFieldsValidToContinue(errors)) {
										setModalPageClose(true);
									} else {
										showToastAlert({
											type: 'error',
											message: 'Please fix above errors',
										});
									}
								}}
							/>
							<div className='proceed-btn'>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				</IonFooter>
			)}
		</>
	);
};
