/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	IonContent,
	IonFooter,
	IonItem,
	IonLabel,
	IonPage,
	IonSegment,
	IonSegmentButton,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Header } from '../../components/layout/header/Header';
import './DMADetails.scss';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getPayoutListInfo } from '../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { CustomButton } from '../../components/common/custom-button/CustomButton';

export const ManageManagerPayout: React.FC = () => {
	const dmaSelector = useSelector((state: any) => state?.users);
	const dispatch: any = useDispatch();
	const location: any = useLocation();
	const [list, setList] = useState<any>([]);
	const history: any = useHistory();
	const { t } = useTranslation();
	const [payout, setPayout] = useState<any>();
	const [key, setKey] = useState<any>(0);
	useEffect(() => {
		getDetails();
	}, [key, history?.location?.state?.update]);
	const getDetails = async () => {
		await dispatch(
			getPayoutListInfo({ param: dmaSelector?.dmaFlow?.dmaId + '/' + 1 })
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setPayout(result?.Data);
					const type = Object?.keys(result?.Data)?.[key];
					setList(result?.Data?.[type]);
				}
			});
	};

	return (
		<IonPage>
			<Header
				name={t('Manage Manager & Payout')}
				backButton={true}
				needArrow={true}
				needArrowOnClick={() =>
					history.push('/dma-details', {
						update: location?.state?.updateApi + 1 || 1,
					})
				}
			/>
			<IonContent>
				<div
					className='bg-web small-container overflowY-auto'
					style={{ minHeight: '100%' }}
				>
					<div className='bg-web-white container-width-md h-100 border-md-radius-10'>
						<div className='d-flex justify-content-center border border-1 border-top-0 border-start-0 border-end-0'>
							<IonSegment
								style={{ width: 'fit-content' }}
								value='default'
								mode='md'
							>
								{payout &&
									Object?.keys(payout)?.map((item, index) => {
										return (
											<IonSegmentButton
												key={index}
												value='default'
												onClick={() => setKey(index)}
												mode='md'
											>
												<IonLabel className='fs-16 darkest-grey fw-600'>
													{t(item)}
												</IonLabel>
											</IonSegmentButton>
										);
									})}
							</IonSegment>
						</div>

						<div
							className='ion-padding'
							id='payout'
						>
							<div className='d-flex header justify-content-between mb-2'>
								<div className='w-50 fs-14 fw-600 text-grey'>
									{t('Sub Product')}
								</div>
								<div className='d-flex w-50 justify-content-end align-items-center'>
									<div className='fs-14 fw-600 text-grey'>{t('Payout %')}</div>
								</div>
							</div>
							{list &&
								list?.subproducts?.map((item: any, index: any) => {
									return (
										<IonItem
											key={index}
											lines={
												list?.subproducts.length === index ? 'none' : 'full'
											}
										>
											<div className='d-flex header justify-content-between align-items-baseline w-100'>
												<img
													src={`/images/icons/products/${item?.icon}.svg`}
													alt=''
													width={16}
													height={16}
													className='me-2 position-relative'
													style={{ top: '5px' }}
												/>
												<div className='w-50  darkest-grey d-flex flex-column'>
													<span className='fs-14 fw-600'>
														{item?.category_name}
													</span>
													<span className='fs-14  fw-400 mb-0 text-grey'>
														{item?.reporting_s_name
															? item?.reporting_s_name
															: list?.owner_name}
													</span>
												</div>
												<div className='d-flex w-50 justify-content-end align-items-center'>
													<div className='fs-14 fw-600'>
														{item?.percentage} %
													</div>
												</div>
											</div>
										</IonItem>
									);
								})}
						</div>
					</div>
				</div>
			</IonContent>
			<IonFooter className='ion-padding bg-web-white'>
				<div className='cibil-button'>
					<CustomButton
						title={t('Add/Update')}
						className={'w-100'}
						onClick={() => history.push('/add-update-manager-payout')}
					/>
				</div>
			</IonFooter>
		</IonPage>
	);
};
