/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
// import { IonSelect, IonSelectOption } from '@ionic/react';
// import { useFormik } from 'formik';
import * as yup from 'yup';

import './AddInsuranceLeadInfo.scss';

import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import CustomDropDown from '../../../components/common/custom-dropdown/CustomDropDown';
import { REGEX } from '../../../helpers/constant';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IonContent, IonFooter } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import {
	addlead,
	getLeadManagers,
	leadFlow,
} from '../../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { disableNumberArrows } from '../../../helpers/functions';
interface LeadStep {
	setNeedArrow: any;
	setNeedCross: any;
}

const addLeadScema = yup.object().shape({
	mobile: yup
		.string()
		.length(10, 'Mobile No. Should Be 10 Digits.')
		.required('Mobile is Required.')
		.matches(REGEX.INDIAN_MOBILE_NUMBERS, 'Please Enter a valid Mobile No.'),
	andro_lead_manager: yup
		.number()
		.min(1, 'Please choose Andro lead manager')
		.required('Andro lead manager is Required.'),
});

export const InsuranceLeadStep1: React.FC<LeadStep> = ({
	setNeedArrow,
	setNeedCross,
}) => {
	const { t } = useTranslation();
	const [managers, setManagers] = useState([]);
	const dispatch: any = useDispatch();
	const leadFlowSelector = useSelector(
		(state: any) => state?.lead?.leadFlowData
	);
	const userData = useSelector((state: any) => state?.users?.user_details);
	const leadSelectedProductsDetails = useSelector(
		(state: any) => state?.lead?.leadFlowData.selectedProductData
	);

	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		trigger,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(addLeadScema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			mobile: '',
			andro_lead_manager: 0,
		},
	});
	const onSubmit = async () => {
		const { mobile, andro_lead_manager } = getValues();
		// const selectedManager: any = managers.filter((item: any) => item.checked);
		const body = {
			mobile_no: mobile,
			product: leadFlowSelector?.productType,
			product_id: leadFlowSelector?.selectedProductData?.product_id,
			lead_type: 'Lead',
			andro_lead_manager,
			category_purpose_id:
				leadFlowSelector?.selectedProductData?.category_purpose_id,
			product_category_id:
				leadFlowSelector?.selectedProductData?.product_category_id,
			loginUserId: userData?.id,
		};
		await dispatch(addlead({ data: body }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					dispatch(
						leadFlow({
							...leadFlowSelector,
							leadId: result?.newLoanApplication?.id,
							leadMobile: mobile,
							leadCurrentStep: 'step2',
							title: 'Add Leads',
							product: leadFlowSelector?.productType,
						})
					);
				}
			});

		// setCurrentStep('step2');
	};
	useEffect(() => {
		if (leadFlowSelector.from !== 'customer') {
			getLeadManager();
		}
		setNeedArrow(true);
		setNeedCross(false);
	}, []);
	const getLeadManager = async () => {
		await dispatch(
			getLeadManagers({
				param: `?subproductid=${leadSelectedProductsDetails.product_category_id}`,
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) setManagers(result?.data);
			});
	};
	return (
		<>
			<IonContent
				className=''
				// style={{
				//  height: 'calc(100vh - 130px)',
				// }}
			>
				<div className='select-product small-container bg-web h-100 pt-2'>
					<div className='bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
						<form
							onSubmit={handleSubmit(onSubmit)}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									handleSubmit(onSubmit);
								}
							}}
						>
							<Controller
								control={control}
								render={({ field: { onChange, onBlur, name } }) => (
									<CustomInput
										type={'number'}
										placeholder={`Customer Mobile No.`}
										onBlur={onBlur}
										name={name}
										onChange={onChange}
										errors={errors?.mobile?.message}
										value={getValues(name)}
										onBeforeInput={(evt: any) => {
											//Only numbers accepted on user input
											!/[0-9]/.test(evt.data) && evt.preventDefault();
											//Only accepts 10 characters on user input
											if (evt.target.value.length === 10) {
												evt.preventDefault();
											}
										}}
										onPaste={(e: any) => {
											//Only get numbers on user paste
											e.preventDefault();
											let pasteContent = e.clipboardData.getData('Text');
											if (pasteContent != '') {
												pasteContent = pasteContent.replace(/\D/g, '');
												if (pasteContent.length > 10) {
													pasteContent = pasteContent.slice(0, 10);
												}
											}
											setValue('mobile', pasteContent);
											trigger('mobile');
										}}
										onKeyDown={(e: any) => {
											disableNumberArrows(e);
										}}
										onWheel={(e: any) => e.target.blur()}
									/>
								)}
								name={'mobile'}
							/>
							<CustomDropDown
								{...{
									className: `${
										errors?.andro_lead_manager && 'dropdown-error'
									}`,
									isCheckbox: false,
									title: t('OneAndro Manager'),
									setData: (data: any) => {
										setManagers(data);
										const val = data.filter((item: any) => item.checked);
										setValue('andro_lead_manager', val[0]?.id);
										trigger('andro_lead_manager');
									},
									data: managers,
									placeholder: t('Search Andro Manager by “Name”'),
									isSearch: true,
									modalTitle: t('OneAndro Manager'),
								}}
							/>
							{errors?.andro_lead_manager && (
								<div className='input-bottom ps-3 pt-1'>
									<div className='error-text sc-ion-input-md'>
										{t(errors?.andro_lead_manager?.message || '')}
									</div>
								</div>
							)}
							<CustomButton
								type='submit'
								disabled={!isValid}
								onClick={handleSubmit(onSubmit)}
								className='btn-blue mt-md-4 d-none d-md-block'
								expand='block'
								title={t('Create Lead')}
							/>
						</form>
					</div>
				</div>
			</IonContent>
			<IonFooter className='ion-padding bg-web-white d-block d-md-none'>
				<CustomButton
					type='submit'
					// disabled={!isValid}
					onClick={handleSubmit(onSubmit)}
					className='btn-blue'
					expand='block'
					title={t('Create Lead')}
				/>
			</IonFooter>
		</>
	);
};
