/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	IonButton,
	IonContent,
	IonFooter,
	IonIcon,
	IonImg,
	IonItem,
	IonLabel,
	IonSegment,
	IonSegmentButton,
	IonSpinner,
	isPlatform,
} from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';
import { t } from 'i18next';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
	getLeadFlowUrl,
	lenderSpfDetailsFlow,
	pnbFormDownload,
	saveLenderSpcfInfo,
} from '../../../store/reducers/lead/leadSlice';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import {
	ContinueLaterPopUp,
	DownloadFileBlob,
	DownloadFileInApp,
	FormatDate,
	InputFormatCurrencyValue,
	checkFieldsValidToContinue,
	disableNumberArrows,
	restrictAmountChar,
	showInAppBrowserView,
	showToastAlert,
} from '../../../helpers/functions';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { CustomModalLeadStatus } from '../../../components/common';
import './punjab.scss';
import {
	getLenderUserInfo,
	getProfessionalDetailsMaster,
} from '../../../store/reducers/user/userSlice';
import { LenderMasterID, LenderNameForAPICall } from '../../../helpers/lenders';
import { REGEX, STATUS_CODE } from '../../../helpers/constant';
import { OfferRejectedMsg } from '../OfferRejectedMsg/OfferRejectedMsg';
import { unwrapResult } from '@reduxjs/toolkit';
import { yupResolver } from '@hookform/resolvers/yup';
import DropDownModal from '../../../components/common/custom-dropdown/DropDownModal';
import { CustomCheckbox } from '../../../components/common/custom-checkbox/CustomCheckbox';
import CustomRadioButton from '../../../components/common/custom-radio-button/CustomRadioButton';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { PushNotifications } from '@capacitor/push-notifications';
import {
	AndroidSettings,
	IOSSettings,
	NativeSettings,
} from 'capacitor-native-settings';

export const PunjabCoapplicant: React.FC<any> = () => {
	const [modalPageClose, setModalPageClose] = useState(false);
	const [leadStatusModalOpen, setLeadStatusModalOpen] = useState(false);
	const [applicationNo] = useState(null);
	const dropdownTypes = ['dropdown', 'dropdownSelect', 'dropdownModal'];
	const [yupSchema, setYupSchema] = useState<any>(yup.object().shape({}));
	const [dropdownMasterData, setDropdownMasterData] = useState<any>({});

	const [coApplicantForm, setCoApplicantForm] = useState<any>({});
	// console.log('🚀 ~ coApplicantForm:', coApplicantForm);

	const [coApplicantData, setCoApplicantData] = useState<any>([]);
	// console.log('🚀 ~ coApplicantData:', coApplicantData);

	const [segmentValue, setSegmentValue] = useState<string>(
		'co_applicant_page_1'
	);
	const [coApplicantIndex, setCoApplicantIndex] = useState<any>(0);

	const [additionalFields, seAdditionalFields] = useState<any>({});

	const [editCount, setEditCount] = useState(0);

	const [segmentList] = useState<any>([
		{
			page: `page 1`,
			key: 'co_applicant_page_1',
		},
		{
			page: `page 2`,
			key: 'co_applicant_page_2',
		},
		{
			page: `page 3`,
			key: 'co_applicant_page_3',
		},
	]);
	const handleChange = (event: any) => {
		setSegmentValue(event.detail.value);
	};

	const history = useHistory();
	const dispatch = useDispatch();
	const { selectedLender, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);

	const [showOfferErrMsg] = useState('');
	const location = useLocation<any>();
	const arr = Object.keys(coApplicantForm).sort();
	const currentIndexCheck: any = arr.findIndex(
		(item: any) => item == coApplicantIndex
	);

	const lenderSpfDetails = useSelector(
		(state: any) => state?.lead?.lenderSpecificDetails
	);

	const [showNotificationModel, setShowNitificationModel] = useState(false);
	const [spinner, setSpinner] = useState(false);

	// const {
	// 	handleSubmit,
	// 	control,
	// 	setValue,
	// 	// getValues,
	// 	// reset,
	// 	formState: { errors, isValid },
	// } = useForm({
	// 	mode: 'all',
	// 	reValidateMode: 'onChange',
	// });

	const {
		// handleSubmit,
		control,
		// reset,
		setValue,
		trigger,
		getValues,
		watch,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(yupSchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	console.log(watch());
	console.log('yupSchema', yupSchema);
	console.log('errors', errors);
	console.log('isValid', isValid);
	const [url, setUrl] = useState('');
	useEffect(() => {
		if (location.pathname !== '/lead-lender-specific-form-steps') {
			return;
		}
		// console.log(selectedLender[0]);
		getLendersFormData();

		dispatch(getLeadFlowUrl())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					const url = result.url;

					const pathname = new URL(url).origin;

					setUrl(pathname);
				}
			});
	}, [location.pathname]);

	// useEffect(() => {
	// 	trigger();
	// }, [coApplicantIndex]);

	useEffect(() => {
		setEditCount(Object.entries(coApplicantForm).length);
	}, [coApplicantForm]);

	useEffect(() => {
		if (editCount > 0 && coApplicantData.length > 0) {
			for (let index = 0; index < editCount; index++) {
				fieldHandler(coApplicantData[index], index);
			}
		} else if (coApplicantData.length > 0) {
			fieldHandler(coApplicantData[0]);
		}
	}, [coApplicantData]);

	const generateFormValidationSchemas = useCallback(
		(fields: any, newSchema: any = false) => {
			// console.log('🚀 ~ generateFormValidationSchemas ~ fields:', fields);
			const schemaBuilderObject: any = {};
			fields?.forEach((formKeyName: any) => {
				if (formKeyName?.is_required) {
					const {
						name,
						match_regex,
						test = false,
						dependentLevel,
						dependentParentLabel,
						dependentParent,
						checkCondition,
						min,
						max,
						trim,
						is_comma_separated = false,
					} = formKeyName;
					if (is_comma_separated) {
						schemaBuilderObject[formKeyName.name] = yup.number();
						schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
							formKeyName.name
						]
							.typeError(
								formKeyName.placeholder.replaceAll('*', '') +
									' must be a number'
							)
							.required(
								typeof formKeyName?.required_error_message === 'string' &&
									formKeyName?.required_error_message
									? formKeyName?.required_error_message
									: formKeyName.placeholder.replaceAll('*', '') +
											' is a required field'
							);
						if (min) {
							schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
								formKeyName.name
							].min(
								formKeyName['min'],
								`${formKeyName.placeholder.replaceAll(
									'*',
									''
								)} should not be zero or empty`
							);
						}
					} else if (dropdownTypes.includes(formKeyName.type)) {
						schemaBuilderObject[formKeyName.name] = yup
							.string()
							.required(
								typeof formKeyName?.required_error_message === 'string' &&
									formKeyName?.required_error_message
									? formKeyName?.required_error_message
									: formKeyName.placeholder.replaceAll('*', '') +
											' is a required field'
							);
					} else if (formKeyName.type === 'number') {
						schemaBuilderObject[formKeyName.name] = yup.string();
						schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
							formKeyName.name
						].required(
							typeof formKeyName?.required_error_message === 'string' &&
								formKeyName?.required_error_message
								? formKeyName?.required_error_message
								: formKeyName.placeholder.replaceAll('*', '') +
										' is a required field'
						);

						if (max) {
							schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
								formKeyName.name
							].max(
								formKeyName?.max,
								typeof formKeyName?.max_error_message === 'string' &&
									formKeyName?.max_error_message
									? formKeyName?.max_error_message
									: `${
											formKeyName.placeholder.replaceAll('*', '') +
											'should not more than ' +
											formKeyName?.max
									  }`
							);
						}
						if (match_regex) {
							schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
								formKeyName.name
							].matches(
								new RegExp(formKeyName['match_regex']),
								typeof formKeyName?.regex_error_message === 'string' &&
									formKeyName?.regex_error_message
									? formKeyName?.regex_error_message
									: formKeyName.placeholder.replaceAll('*', '') +
											' should be a valid format'
							);
						}
					} else if (formKeyName.type === 'date') {
						schemaBuilderObject[formKeyName.name] = yup
							.date()
							.required('Date of birth is required') // Make it required
							.max(
								new Date(),
								`${formKeyName.placeholder.replaceAll(
									'*',
									''
								)} must be in the past`
							);
					} else if (formKeyName.type === 'text') {
						schemaBuilderObject[formKeyName.name] = yup.string();
						schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
							formKeyName.name
						].required(
							typeof formKeyName?.required_error_message === 'string' &&
								formKeyName?.required_error_message
								? formKeyName?.required_error_message
								: formKeyName.placeholder.replaceAll('*', '') +
										' is a required field'
						);
						if (trim) {
							schemaBuilderObject[formKeyName.name] =
								schemaBuilderObject[formKeyName.name].trim();
						}
						if (max) {
							schemaBuilderObject[formKeyName.name] = yup
								.string()
								.max(
									formKeyName?.max,
									typeof formKeyName?.max_error_message === 'string' &&
										formKeyName?.max_error_message
										? formKeyName?.max_error_message
										: `${
												formKeyName.placeholder.replaceAll('*', '') +
												'should not more than ' +
												formKeyName?.max
										  }`
								);
						}
						if (match_regex) {
							schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
								formKeyName.name
							].matches(
								name.includes('name') || name.includes('Name')
									? new RegExp(REGEX?.FULLNAME)
									: new RegExp(formKeyName['match_regex']),
								typeof formKeyName?.regex_error_message === 'string' &&
									formKeyName?.regex_error_message
									? formKeyName?.regex_error_message
									: formKeyName.placeholder.replaceAll('*', '') +
											' should be a valid format'
							);
						}
					} else {
						schemaBuilderObject[formKeyName.name] = formKeyName.match_regex
							? yup
									.string()
									.required(
										typeof formKeyName?.required_error_message === 'string' &&
											formKeyName?.required_error_message
											? formKeyName?.required_error_message
											: formKeyName.placeholder.replaceAll('*', '') +
													' is a required field'
									)
									.matches(
										new RegExp(formKeyName.match_regex),
										typeof formKeyName?.regex_error_message === 'string' &&
											formKeyName?.regex_error_message
											? formKeyName?.regex_error_message
											: formKeyName.placeholder.replaceAll('*', '') +
													' should be a valid format'
									)
							: yup
									.string()
									.required(
										typeof formKeyName?.required_error_message === 'string' &&
											formKeyName?.required_error_message
											? formKeyName?.required_error_message
											: formKeyName.placeholder.replaceAll('*', '') +
													' is a required field'
									);
					}
					if (test) {
						if (
							dependentLevel == '0' &&
							checkCondition &&
							typeof dependentParent === 'string'
						) {
							schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
								formKeyName.name
							].test(
								'custom-test-logic',
								formKeyName.placeholder.replaceAll('*', '') +
									'should not be more than ' +
									dependentParentLabel,
								function (this: yup.TestContext, value: string): boolean {
									const operation = checkCondition || '<=';
									const leftOperand = value;
									const { [dependentParent]: rightOperand } = this.parent;
									let result;
									switch (operation) {
										case '<':
											result = parseInt(leftOperand) < parseInt(rightOperand);
											break;
										case '<=':
											result = parseInt(leftOperand) <= parseInt(rightOperand);
											break;
										case '>':
											result = parseInt(leftOperand) > parseInt(rightOperand);
											break;
										case '>=':
											result = parseInt(leftOperand) >= parseInt(rightOperand);
											break;
										case '==':
											result = parseInt(leftOperand) == parseInt(rightOperand);
											break;
										case '===':
											result = parseInt(leftOperand) === parseInt(rightOperand);
											break;
										default:
											result = true;
									}
									return result;
								}
							);
						}
					}
				} else {
					if (formKeyName?.is_comma_separated) {
						schemaBuilderObject[formKeyName.name] = yup
							.number()
							.typeError(formKeyName.placeholder + ' must be a number');
					} else {
						schemaBuilderObject[formKeyName.name] =
							formKeyName?.match_regex &&
							yup.string().matches(new RegExp(formKeyName.match_regex), {
								message:
									typeof formKeyName?.regex_error_message === 'string' &&
									formKeyName?.regex_error_message
										? formKeyName?.regex_error_message
										: formKeyName.placeholder + ' should be a valid format',
								excludeEmptyString: true,
							});
					}
				}
			});

			newSchema
				? setYupSchema(
						yupSchema.concat(yup.object().shape(schemaBuilderObject))
				  )
				: setYupSchema(yup.object().shape(schemaBuilderObject));
			// trigger();
		},
		[yupSchema]
	);

	const getLendersFormData = async () => {
		// get all fields from api
		await dispatch(
			getLenderUserInfo({
				param: leadFlowData?.leadId,
				data: {
					lender_name: LenderNameForAPICall.PUNJAB,
					lead_application_id: selectedLender[0]?.id,
					lender_id: LenderMasterID.PUNJAB,
				},
			})
		)
			.then(unwrapResult)
			.then((res: any) => {
				if (res?.status) {
					setEditCount(res?.coAppCount);

					const updateNameValues = (data: any) => {
						return data.map((page: any, index: any) => {
							return {
								...page,
								co_applicant_page_1: page.co_applicant_page_1.map(
									(field: any) => {
										return {
											...field,
											name: field.name + '_' + (index + 1),
										};
									}
								),
								co_applicant_page_2: page.co_applicant_page_2.map(
									(field: any) => {
										return {
											...field,
											name: field.name + '_' + (index + 1),
										};
									}
								),
								co_applicant_page_3: page.co_applicant_page_3.map(
									(field: any) => {
										return {
											...field,
											name: field.name + '_' + (index + 1),
										};
									}
								),
							};
						});
					};

					const updatedDataArray = updateNameValues(res?.data?.co_applicant);

					setCoApplicantData(updatedDataArray);
					fieldHandler(updatedDataArray[coApplicantIndex]);
				}
			});
	};

	const fieldHandler = (data: any, tag: any = 0) => {
		// console.log('coApplicantForm', coApplicantForm);
		// console.log('Entries', Object.entries(coApplicantForm));

		// const checkCondition = Object.entries(coApplicantForm).find(
		// 	(item: any) => item[0] == tag
		// );

		// if (!checkCondition) {
		// check any field as is_required true than generate validation schema
		let allFields = [
			...(data?.co_applicant_page_1 || []),
			...(data?.co_applicant_page_2 || []),
			...(data?.co_applicant_page_3 || []),
		];

		allFields?.forEach((formKeyName: any) => {
			if (formKeyName?.is_required && formKeyName.additional_fields) {
				allFields = [
					...allFields,
					...formKeyName.additional_fields.map((field: any) => {
						return {
							...field,
							name: field.name + '_' + (tag + 1),
						};
					}),
				];

				seAdditionalFields((prev: any) => ({
					...prev,
					[formKeyName?.name]: formKeyName.additional_fields.map(
						(field: any) => {
							return {
								...field,
								name: field.name + '_' + (tag + 1),
							};
						}
					),
				}));
			}
		});

		if (allFields.some((formKey: any) => formKey?.is_required === true)) {
			generateFormValidationSchemas(allFields);
		}

		// run loop for dropdown api data
		allFields.forEach((formKeyName: any) => {
			if (formKeyName.type === 'dropdownSelect') {
				dispatch(getProfessionalDetailsMaster({ param: formKeyName.key }))
					.then(unwrapResult)
					.then((dropdownResponse: any) => {
						if (dropdownResponse?.status) {
							setDropdownMasterData((masterData: any) => {
								return {
									...masterData,
									[formKeyName.name]: dropdownResponse.data,
								};
							});
						}
					});
			}

			// set value for form fields whatever the value present in api value key
			if (formKeyName.type === 'date') {
				setValue(formKeyName.name, FormatDate(formKeyName.value, 'yyyy-mm-dd'));
			} else {
				setValue(formKeyName.name, formKeyName.value);
			}
		});

		setCoApplicantForm((prev: any) => ({
			...prev,
			[tag]: {
				co_applicant_page_1: data?.co_applicant_page_1,
				co_applicant_page_2: data?.co_applicant_page_2,
				co_applicant_page_3: data?.co_applicant_page_3,
			},
		}));
		// } else {
		// 	console.log('ELSE....................');
		// }
	};

	const fieldsGenerator = useCallback(
		(onlineFormFields: any) => {
			// console.log(onlineFormFields);

			return (
				coApplicantForm &&
				Object.entries(coApplicantForm).length > 0 &&
				onlineFormFields.map(
					(
						{
							name,
							placeholder,
							type,
							// value,
							exclude_number_validation,
							dependentLevel,
							triggerValidation,
							is_comma_separated = false,
							is_current_permanent_address = false,
							permanent_address_fields,
							current_address_fields,
							radio_options,
							is_required,
							additional_fields,
						}: any,
						index: any
					) => {
						const errorText: any =
							errors && Object.keys(errors).length > 0 && errors;
						if (type === 'dropdownSelect') {
							return (
								<div key={index}>
									<>
										<Controller
											key={`controller-${index}`}
											control={control}
											render={({ field: { onChange, onBlur, name } }) => (
												<DropDownModal
													label={
														t(`${placeholder}`) + (is_required ? ' *' : '')
													}
													data={
														dropdownMasterData[name]
															? dropdownMasterData[name]
															: []
													}
													name={name}
													onChange={({ detail: { value } }: any) => {
														onChange(value);
													}}
													onBlur={onBlur}
													className={
														errorText[name]?.message
															? 'ion-invalid ion-touched mb-3'
															: 'mb-3'
													}
													errors={t(errorText[name]?.message as string)}
													value={getValues(name)}
												/>
											)}
											name={name}
										/>
									</>
								</div>
							);
						} else if (type === 'checkbox') {
							return (
								<div key={name}>
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, value } }) => (
											<CustomCheckbox
												name={name}
												onChange={({ detail: { checked } }: any) => {
													onChange(checked);
													if (is_current_permanent_address) {
														if (checked) {
															permanent_address_fields?.map(
																(item: any, name: any) => {
																	setValue(
																		item,
																		getValues(current_address_fields[name])
																	);
																	trigger(item);
																}
															);
														} else {
															permanent_address_fields?.map((item: any) => {
																setValue(item, '');
																trigger(item);
															});
														}
													}
												}}
												value={value}
												labelPlacement='end'
												className='end-of-page'
												onBlur={onBlur}
												isChecked={value}
												children={
													<span className='text-wrap'>{t(placeholder)}</span>
												}
											></CustomCheckbox>
										)}
										name={name}
									/>
								</div>
							);
						} else if (type === 'radio') {
							return (
								<>
									<div key={name}>
										<Controller
											key={name}
											control={control}
											render={({ field: { onChange, name } }) => (
												<div className='mb-2'>
													<h6 className='mt-3'>
														{t(placeholder) + (is_required ? ' *' : '')}
													</h6>
													<CustomRadioButton
														type='group'
														value={getValues(name)}
														onIonChange={(e: any) => {
															// name.includes('coappConsiderIncome') &&
															// 	toggleRequired(
															// 		'coappConsiderIncome',
															// 		e?.detail?.value
															// 	);
															onChange(e);
														}}
														className='mb-2'
														ionRadioClassName='me-4'
														labelPlacement='end'
														data={
															Array.isArray(radio_options) &&
															radio_options?.length
																? radio_options
																: []
														}
													/>
													<div className='error-text px-3 sc-ion-input-md'>
														{t(errorText[name]?.message)}
													</div>
												</div>
											)}
											name={name}
										/>
									</div>
									{name.includes('coappConsiderIncome') &&
										watch(name) &&
										additional_fields &&
										fieldsGenerator(additionalFields[name])}
								</>
							);
						} else if (type === 'date') {
							return (
								<div key={name}>
									<Controller
										key={name}
										control={control}
										render={({ field: { onChange, onBlur, name } }) => (
											<CustomInput
												type={type}
												needSuffixIcon={true}
												suffixClassName={
													'position-absolute date-of-birth-sufix'
												}
												suffixText={
													<IonImg
														src='/images/icons/input-dob-calendar-icon.svg'
														alt='dob'
													/>
												}
												outerClassName={'position-relative'}
												placeholder={t(placeholder) + (is_required ? ' *' : '')}
												onBlur={onBlur}
												name={name}
												onChange={onChange}
												// errors={t(errors[name]?.message)}
												errors={t(errors[name]?.message as any)}
												value={t(getValues(name))}
											/>
										)}
										name={name}
									/>
								</div>
							);
						} else {
							return (
								<div key={name}>
									<Controller
										key={name}
										control={control}
										render={({ field: { onChange, onBlur, name } }) => (
											<CustomInput
												type={type}
												placeholder={t(placeholder) + (is_required ? ' *' : '')}
												onBlur={onBlur}
												name={name}
												onChange={(e: any) => {
													if (is_comma_separated) {
														e = isNaN(e.detail.value.replaceAll(',', ''))
															? ''
															: Number(e.detail.value.replace(/,/g, ''));
													}
													if (
														dependentLevel == '1' &&
														Array.isArray(triggerValidation)
													) {
														if (triggerValidation.length > 0) {
															triggerValidation?.map((item: any) => {
																if (getValues(item) != undefined) {
																	trigger(item);
																}
															});
														}
													}
													onChange(e);
												}}
												errors={t(errors[name]?.message as any)}
												value={t(
													is_comma_separated
														? getValues(name)
															? InputFormatCurrencyValue(getValues(name))
															: getValues(name)
														: getValues(name)
												)}
												onBeforeInput={(evt: any) => {
													if (type == 'number') {
														if (!exclude_number_validation) {
															!/[0-9]/.test(evt.data) && evt.preventDefault();
														} else {
															!/[0-9.]/.test(evt.data) && evt.preventDefault();
														}
													} else if (is_comma_separated && type == 'text') {
														restrictAmountChar(
															evt,
															getValues(name)?.toString()
														);
													}
												}}
												onKeyDown={(e: any) => {
													if (type == 'number') {
														disableNumberArrows(e);
													}
												}}
												onWheel={(e: any) => {
													if (type == 'number') {
														e.target.blur();
													}
												}}
											/>
										)}
										name={name}
									/>
								</div>
							);
						}
					}
				)
			);
		},
		[coApplicantForm, dropdownMasterData, additionalFields, watch()]
	);

	const toggleRequired = useCallback(
		(field: any, value: any = null) => {
			let newSchema: any = yupSchema;
			console.log(value);

			if (field === 'coappConsiderIncome') {
				if (value) {
					newSchema = yupSchema.clone().shape({
						[`coappofcAddress1_${coApplicantIndex + 1}`]: yup
							.string()
							.required(),
						[`coappofcAddress2_${coApplicantIndex + 1}`]: yup
							.string()
							.required(),
						[`coappofcAddress3_${coApplicantIndex + 1}`]: yup
							.string()
							.required(),
						[`coappofcPincode_${coApplicantIndex + 1}`]: yup
							.string()
							.required(),
					});
				} else {
					delete newSchema.fields?.[`coappofcAddress1_${coApplicantIndex + 1}`];
					delete newSchema.fields?.[`coappofcAddress2_${coApplicantIndex + 1}`];
					delete newSchema.fields?.[`coappofcAddress3_${coApplicantIndex + 1}`];
					delete newSchema.fields?.[`coappofcPincode_${coApplicantIndex + 1}`];

					newSchema._nodes.pop(`coappofcAddress1_${coApplicantIndex + 1}`);
					newSchema._nodes.pop(`coappofcAddress2_${coApplicantIndex + 1}`);
					newSchema._nodes.pop(`coappofcAddress3_${coApplicantIndex + 1}`);
					newSchema._nodes.pop(`coappofcPincode_${coApplicantIndex + 1}`);
				}
			}

			setYupSchema(newSchema);
			setTimeout(() => {
				trigger(`coappofcAddress1_${coApplicantIndex + 1}`);
				trigger(`coappofcAddress2_${coApplicantIndex + 1}`);
				trigger(`coappofcAddress3_${coApplicantIndex + 1}`);
				trigger(`coappofcPincode_${coApplicantIndex + 1}`);
			}, 1000);
		},
		[yupSchema, watch()]
	);

	useEffect(() => {
		toggleRequired(
			'coappConsiderIncome',
			watch(`coappConsiderIncome_${coApplicantIndex + 1}`)
		);
	}, [watch(`coappConsiderIncome_${coApplicantIndex + 1}`)]);

	const registerNotifications = async () => {
		const permStatus = await PushNotifications.checkPermissions();

		if (permStatus.receive == 'denied') {
			setShowNitificationModel(true);
			setSpinner(false);
		} else if (permStatus.receive == 'prompt-with-rationale') {
			setShowNitificationModel(true);
			setSpinner(false);
		} else {
			setShowNitificationModel(false);
		}
	};

	/**
	 * On close of lead status modal redirect to external URL
	 */
	const onLeadStatusModalClose = () => {
		setLeadStatusModalOpen(false);
	};

	/**
	 * On submit to save user data and after that
	 */
	const onSubmit = async (data: any = watch(), status: any = false) => {
		console.log(status);

		const errorSet = { ...errors };
		const errorKeys = Object.keys(errorSet);
		const formData = data;
		console.log('errorKeys', errorKeys);

		Object.entries(errorKeys).map((item: any) => {
			delete formData?.[item[1]];
		});
		// console.log('after data', data);

		const result: { [key: string]: string }[] = [];

		Object.keys(watch()).forEach((key) => {
			const suffix = key.split('_')[1];
			const existingIndex = result.findIndex((obj) =>
				Object.keys(obj)[0].endsWith(suffix)
			);
			if (existingIndex === -1) {
				result.push({ [key]: watch(key) });
			} else {
				result[existingIndex][key] = watch(key);
			}
		});

		const coappList = result.map((obj: any) => {
			return Object.keys(obj).reduce((acc: any, key: any) => {
				const newKey = key.replace(/_(\d+)$/, '');
				acc[newKey] = obj[key];
				return acc;
			}, {});
		});

		await dispatch(
			saveLenderSpcfInfo({
				// param: '/' + leadFlowData?.leadId,
				data: {
					coappList: coappList,
					lead_id: leadFlowData?.leadId,
					lender_id: selectedLender[0]?.lender_id,
					lender_name: LenderNameForAPICall.PUNJAB,
					lead_application_id:
						leadFlowData?.lead_app_id || selectedLender[0]?.id,
					isNewLead: true,
					step: 'step2',
					continue_flag: status,
					return_url: `${url}${location.pathname}?esign=success`,
				},
			})
		).then((res: any) => {
			// console.log(res);
			if (!status) {
				if (res?.payload?.status) {
					!status &&
						dispatch(
							lenderSpfDetailsFlow({
								...lenderSpfDetails,
								step: 'step3',
							})
						);
					showInAppBrowserView(res?.payload?.data.data[0]?.INVITATION_LINK);
					window.close();
					// setApplicationNo(res?.payload?.data);
					// setLeadStatusModalOpen(true);
				} else {
					// setShowOfferErrMsg('Please try again later !');
				}
			}
		});
	};

	const formDownload = () => {
		// onSubmit();

		setTimeout(async () => {
			await dispatch(
				pnbFormDownload({
					// param: '/' + leadFlowData?.leadId,
					data: {
						lender_name: LenderNameForAPICall.PUNJAB,
						lead_application_id:
							leadFlowData?.lead_app_id || selectedLender[0]?.id,
					},
				})
			).then((res: any) => {
				// console.log(res);

				if (res?.payload?.status) {
					showToastAlert({ type: 'success', message: 'Download Initiated' });
					if (isPlatform('mobileweb')) {
						DownloadFileBlob(res?.payload?.data, 'pnb_form', 'pdf');
					} else if (
						isPlatform('android') ||
						isPlatform('ios') ||
						isPlatform('ipad') ||
						isPlatform('iphone')
					) {
						// setSpinner(true);
						registerNotifications();

						DownloadFileInApp(res?.payload?.data, 'pnb_form', 'pdf');
					} else {
						DownloadFileBlob(res?.payload?.data, 'pnb_form', 'pdf');
					}
				}

				if (res?.payload?.code == STATUS_CODE.SUCCESS) {
					// showInAppBrowserView(response?.presignedUrl);
				} else {
					// setShowOfferErrMsg('Please try again later !');
				}
			});
		}, 1000);
	};

	const orCondition =
		segmentValue === 'co_applicant_page_1' ||
		segmentValue === 'co_applicant_page_2';

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<IonSegment
								scrollable={true}
								onIonChange={handleChange}
								value={segmentValue}
							>
								{segmentList.map((item: any) => (
									<IonSegmentButton
										style={{ padding: '0px 20px' }}
										key={item?.key}
										value={item?.key}
									>
										<IonLabel>{item?.page}</IonLabel>
									</IonSegmentButton>
								))}
							</IonSegment>
						</div>

						<div className='select-product small-container bg-web'>
							<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10 pt-3'>
								<p className='h5  fw-bold mb-2'>
									TOTAL CO-APPLICANT. {Object.entries(coApplicantForm).length}
								</p>
								<p className='h6 fw-bold pb-2'>
									CO-APPLICANT {coApplicantIndex + 1}
								</p>
								{segmentValue === 'co_applicant_page_1' && (
									<form>
										{fieldsGenerator(
											coApplicantForm[coApplicantIndex]?.co_applicant_page_1
										)}
									</form>
								)}
								{segmentValue === 'co_applicant_page_2' && (
									<form>
										{fieldsGenerator(
											coApplicantForm[coApplicantIndex]?.co_applicant_page_2
										)}
									</form>
								)}
								{segmentValue === 'co_applicant_page_3' && (
									<form>
										{fieldsGenerator(
											coApplicantForm[coApplicantIndex]?.co_applicant_page_3
										)}
									</form>
								)}

								<div className={` d-none d-md-block`}>
									{/* <div className='flex'>
										<img
											style={{
												minWidth: '38px',
												minHeight: '38px',
												cursor: `${
													arr.length == 1 || currentIndexCheck == 0
														? 'not-allowed'
														: 'pointer'
												}`,
											}}
											className='selected-lender-bank-icon m-0'
											src={'/images/icons/backarrow.svg'}
											alt='...'
											onClick={() => {
												(arr.length !== 1 || currentIndexCheck !== 0) &&
													setCoApplicantIndex(
														Object.keys(coApplicantForm).length > 1 &&
															coApplicantIndex > 0
															? +arr[currentIndexCheck - 1]
															: +arr[arr.length - 1]
													);
											}}
										/>
										<IonButton
											shape='round'
											type='button'
											color='danger'
											size='small'
											onClick={() => {
												if (Object.entries(coApplicantForm).length > 0) {
													// setYupSchema(schema);
													const fields: any = [];
													const obj = { ...coApplicantForm };

													delete obj[coApplicantIndex];
													Object.entries(obj).map((item: any) =>
														Object.entries(item[1]).map((data: any) =>
															data[1].map((itm: any) => fields.push(itm))
														)
													);
													generateFormValidationSchemas(fields);
													// console.log('OBJ', obj);

													const newObject = Object.entries(obj).map(
														// eslint-disable-next-line @typescript-eslint/no-unused-vars
														([key, value], index) => {
															return { [index]: value };
														}
													);

													const keysHub: any = {};

													Object.keys(watch()).forEach((key) => {
														const match = key.match(/_(\d+)$/);
														if (match) {
															const id = match[1];
															if (!keysHub[id]) {
																keysHub[id] = [];
															}
															keysHub[id].push(key.split('_')[0]);
														}
													});

													console.log('keysHub', keysHub);
													const schemaObject: any = {};
													Object.entries(keysHub).map((item: any, i: any) => {
														const index: any = i + 1;
														item[1].map((key: any) => {
															schemaObject[`${key}_${index}`] =
																yupSchema.fields[`${key}_${index + 1}`];
															console.log(`${key}_${index + 1}`);
															console.log(yupSchema.fields);
														});
													});

													let newSchema: any = yupSchema;
													newSchema = yupSchema.clone().shape({
														newFirstName: yupSchema.fields.coappEmail_1,
														newLastName: yupSchema.fields.coappEmail_2,
													});
													setYupSchema(newSchema);
													console.log('schemaObject', schemaObject);

													// console.log('coApplicantForm', coApplicantForm);

													Object.entries(coApplicantForm).length > 0 &&
														Object.entries(coApplicantForm).map(
															(_: any, i: any) => {
																const index = i + 1;

																keysHub[index].map((key: any) => {
																	setValue(
																		`${key}_${index}`,
																		watch(`${key}_${index + 1}`)
																	);
																});
															}
														);

													setCoApplicantForm(Object.assign({}, ...newObject));
													setCoApplicantIndex(+Object.keys(coApplicantForm)[0]);
												}

												// delete newSchema.fields.name;
											}}
										>
											<span className='p-2'>
												<IonIcon src='/images/icons/delete.svg' />
											</span>
										</IonButton>
										<IonButton
											shape='round'
											type='button'
											color='primary'
											size='small'
											disabled={!isValid}
											onClick={() => {
												if (Object.keys(coApplicantForm).length != 5) {
													fieldHandler(
														coApplicantData[coApplicantIndex + 1],
														coApplicantIndex + 1
													);
													setCoApplicantIndex(coApplicantIndex + 1);
													setSegmentValue('co_applicant_page_1');
												}
												onSubmit(watch());
											}}
										>
											<span className='p-2'>
												<IonIcon src='/images/icons/add-icon.svg' />
											</span>
											Co Applicant
										</IonButton>
										<img
											style={{
												minWidth: '38px',
												minHeight: '38px',
												cursor: `${
													currentIndexCheck == arr[arr.length - 1]
														? 'not-allowed'
														: 'pointer'
												}`,
												transform: 'rotate(180deg)',
											}}
											className='selected-lender-bank-icon m-0 transform rotate-180'
											src={'/images/icons/backarrow.svg'}
											alt='...'
											onClick={() => {
												if (currentIndexCheck !== arr[arr.length - 1]) {
													fieldHandler(
														coApplicantForm[coApplicantIndex + 1],
														coApplicantIndex + 1
													);
													setCoApplicantIndex(
														arr.length < 5 &&
															arr.length > 1 &&
															coApplicantIndex < 5 &&
															coApplicantIndex + 1 != arr.length
															? +arr[currentIndexCheck + 1]
															: +arr[arr.length - 1]
													);
													toggleRequired(
														'coappConsiderIncome',
														watch(`coappConsiderIncome_${coApplicantIndex + 1}`)
													);
												}

												// onSubmit(watch());
											}}
										/>
									</div> */}
									<CustomButton
										className='w-100 fw-bold dashboard-add-lead-button mb-3'
										expand='block'
										fill='outline'
										title={t('Continue Later')}
										onClick={() => {
											if (checkFieldsValidToContinue(errors)) {
												setModalPageClose(true);
											} else {
												showToastAlert({
													type: 'error',
													message: 'Please fix above errors',
												});
											}
										}}
									/>
									<CustomButton
										type='submit'
										className='w-100 fw-bold dashboard-add-lead-button'
										expand='block'
										title={t('Download')}
										disabled={!isValid}
										onClick={() => {
											// redirectApk();
											onSubmit(watch(), true);
											setTimeout(() => {
												formDownload();
											}, 3000);
										}}
									/>
									<CustomButton
										type='submit'
										className='w-100 fw-bold dashboard-add-lead-button'
										expand='block'
										title={orCondition ? t('Save & Continue') : t('Proceed')}
										disabled={orCondition ? false : !isValid}
										onClick={() => {
											if (orCondition) {
												onSubmit(watch(), true);
												setSegmentValue(
													segmentValue === 'co_applicant_page_1'
														? 'co_applicant_page_2'
														: segmentValue === 'co_applicant_page_2' &&
																'co_applicant_page_3'
												);
											} else {
												onSubmit(watch(), false);
											}
										}}
									/>
								</div>
							</div>
						</div>
					</>
				)}

				<CustomModal
					needIonContentWraper={false}
					isOpen={modalPageClose}
					initialBreakpoint={undefined}
					handleClose={() => setModalPageClose(false)}
					breakpoints={[]}
					className='height-auto confirm-popup-middle continue-later'
					modalContent={ContinueLaterPopUp(
						() => {
							setModalPageClose(false);
						},
						() => {
							new Promise(function (myResolve) {
								setTimeout(function () {
									onSubmit(watch());
									myResolve(history.push('/dashboard/lead'));
									setModalPageClose(false);
									setDropdownMasterData({});
									setCoApplicantForm({});
									setCoApplicantData([]);
									setCoApplicantIndex(0);
									seAdditionalFields({});
								}, 10);
							});
						},
						'Cancel',
						'Continue Later',
						'className',
						'Are You Sure You Want to Exit?',
						`${t('You can continue later from where you left off.')}`
					)}
					needCross={false}
					needArrow={false}
				/>
			</IonContent>

			{leadStatusModalOpen && (
				<CustomModalLeadStatus
					isOpen={leadStatusModalOpen}
					applicationNo={applicationNo}
					handleClose={() => onLeadStatusModalClose()}
				/>
			)}

			{!showOfferErrMsg && (
				<IonFooter className='d-block d-md-none position-relative'>
					<div
						style={{
							backgroundColor: '#fff',
							zIndex: 2,
						}}
					>
						<div className={`ion-padding`}>
							{/* <div className='flex'>
								<img
									style={{
										minWidth: '38px',
										minHeight: '38px',
										cursor: `${
											arr.length == 1 || currentIndexCheck == 0
												? 'not-allowed'
												: 'pointer'
										}`,
									}}
									className='selected-lender-bank-icon m-0'
									src={'/images/icons/backarrow.svg'}
									alt='...'
									onClick={() => {
										(arr.length !== 1 || currentIndexCheck !== 0) &&
											setCoApplicantIndex(
												Object.keys(coApplicantForm).length > 1 &&
													coApplicantIndex > 0
													? +arr[currentIndexCheck - 1]
													: +arr[arr.length - 1]
											);
									}}
								/>
								<IonButton
									shape='round'
									type='button'
									color='danger'
									size='small'
									onClick={() => {
										if (Object.entries(coApplicantForm).length > 0) {
											// setYupSchema(schema);
											const fields: any = [];
											const obj = { ...coApplicantForm };

											delete obj[coApplicantIndex];
											Object.entries(obj).map((item: any) =>
												Object.entries(item[1]).map((data: any) =>
													data[1].map((itm: any) => fields.push(itm))
												)
											);
											generateFormValidationSchemas(fields);
											// console.log('OBJ', obj);

											const newObject = Object.entries(obj).map(
												// eslint-disable-next-line @typescript-eslint/no-unused-vars
												([key, value], index) => {
													return { [index]: value };
												}
											);

											setCoApplicantForm(Object.assign({}, ...newObject));
											setCoApplicantIndex(+Object.keys(coApplicantForm)[0]);
										}
									}}
								>
									<span className='p-2'>
										<IonIcon src='/images/icons/delete.svg' />
									</span>
								</IonButton>
								<IonButton
									shape='round'
									type='button'
									color='primary'
									size='small'
									onClick={() => {
										if (Object.keys(coApplicantForm).length != 5) {
											fieldHandler(
												coApplicantData[coApplicantIndex + 1],
												coApplicantIndex + 1
											);
											setCoApplicantIndex(coApplicantIndex + 1);
											setSegmentValue('co_applicant_page_1');
										}
										onSubmit(watch());
									}}
								>
									<span className='p-2'>
										<IonIcon src='/images/icons/add-icon.svg' />
									</span>
									Co Applicant
								</IonButton>
								<img
									style={{
										minWidth: '38px',
										minHeight: '38px',
										cursor: `${
											currentIndexCheck == arr[arr.length - 1]
												? 'not-allowed'
												: 'pointer'
										}`,
										transform: 'rotate(180deg)',
									}}
									className='selected-lender-bank-icon m-0 transform rotate-180'
									src={'/images/icons/backarrow.svg'}
									alt='...'
									onClick={() => {
										if (currentIndexCheck !== arr[arr.length - 1]) {
											fieldHandler(
												coApplicantData[coApplicantIndex + 1],
												coApplicantIndex + 1
											);
											setCoApplicantIndex(
												arr.length < 5 &&
													arr.length > 1 &&
													coApplicantIndex < 5 &&
													coApplicantIndex + 1 != arr.length
													? +arr[currentIndexCheck + 1]
													: +arr[arr.length - 1]
											);
											toggleRequired(
												'coappConsiderIncome',
												watch(`coappConsiderIncome_${coApplicantIndex + 1}`)
											);
										}

										// onSubmit(watch());
									}}
								/>
							</div> */}
							<CustomButton
								className='w-100 fw-bold dashboard-add-lead-button mb-3'
								expand='block'
								fill='outline'
								title={t('Continue Later')}
								onClick={() => {
									if (checkFieldsValidToContinue(errors)) {
										setModalPageClose(true);
									} else {
										showToastAlert({
											type: 'error',
											message: 'Please fix above errors',
										});
									}
								}}
							/>
							<div className='proceed-btn'>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Download')}
									disabled={!isValid}
									onClick={() => {
										onSubmit(watch(), true);
										setTimeout(() => {
											formDownload();
										}, 3000);
									}}
								/>
							</div>
							<div className='proceed-btn'>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={orCondition ? t('Save & Continue') : t('Proceed')}
									disabled={orCondition ? false : !isValid}
									onClick={() => {
										if (orCondition) {
											onSubmit(watch(), true);
											setSegmentValue(
												segmentValue === 'co_applicant_page_1'
													? 'co_applicant_page_2'
													: segmentValue === 'co_applicant_page_2' &&
															'co_applicant_page_3'
											);
										} else {
											onSubmit(watch(), false);
										}
									}}
								/>
							</div>
						</div>
					</div>
				</IonFooter>
			)}
			{spinner && (
				<div
					className='position-fixed  w-100  d-flex justify-content-center align-items-center h-100'
					style={{
						top: '0px',
						left: '50%',
						transform: 'translate(-50%,0%)',
						backgroundColor: 'rgba(0,0,0,0.2)',
					}}
				>
					<IonSpinner name='lines-sharp' />
				</div>
			)}
			<CustomModal
				id=''
				isOpen={showNotificationModel}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setShowNitificationModel(false)}
				breakpoints={[]}
				className='confirm-popup-middle height-auto'
				modalContent={
					<div className={`wrapper py-4 confirm-popup-middle height-auto`}>
						<IonItem lines='none'>
							<div className='d-flex flex-column'>
								<p
									className='fs-14 mb-2'
									style={{ color: 'var(--text-dark-grey)' }}
								>
									Please enable notification permission from settings.
								</p>
							</div>
						</IonItem>
						<div className='d-flex justify-content-center gap-3 px-3'>
							<CustomButton
								className='w-50'
								fill='solid'
								onClick={() => {
									NativeSettings.open({
										optionAndroid: AndroidSettings.AppNotification,
										optionIOS: IOSSettings.Notifications,
									});
									setShowNitificationModel(false);
								}}
								title={t('Ok')}
							/>
						</div>
					</div>
				}
				needIonContentWraper={false}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
