import { IonContent, IonFooter, IonPage } from '@ionic/react';
import { Header } from '../../components/layout/header/Header';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const rawData = `This document (Hereinafter referred to as “Terms of Use”) is an electronic record in terms of existing and applicable Information Technology laws and the amended provisions thereto pertaining to electronic records in various allied statutes as amended pursuant to the Information Technology laws. This electronic record has been generated by a computer system and does not require any authentication. 
<br/><br/>
Terms of Use 
<br/><br/>
The use of the websites, https://www.andromedaloans.com/ and / or any other associate website (hereinafter referred to as “Site” or “Website”) is subject to the terms of a legal agreement between the user of the Website and Andromeda Sales and Distribution Private Limited (Hereinafter referred to as the “Company”). The Company is incorporated under the provisions of the Companies Act, 2013, having its registered office at 158 CST Road, Kalina, Santacruz (East), Mumbai, Mumbai City (400098). 
<br/><br/>
The following terms and conditions will be deemed to have been accepted by you (“User”) on mere visitation and / or usage of the services of this Website. You are requested to read them carefully before you visit / use the services of this Site. The term “User”, “Client” or “You” (or any variant of the said pronoun) shall refer to the user who is browsing or using the services of this Site. The term “Company” shall refer to the cor persons about the TOU and/or Privacy Policy of the Company) that apply to the purchase you have made on their behalf (including all rules and restrictions applicable thereto). The following terms and conditions will be deemed to have been accepted by you (“User”) on mere visitation and / or usage of the services of this Website. 
<br/><br/>
You are requested to read them carefully before you visit / use the services of this Site. The term “User”, “Client” or “You” (or any variant of the said pronoun) shall refer to the user who is browsing or using the services of this Site. 
<br/><br/>
The term “Company” shall refer to the cor persons about the TOU and/or Privacy Policy of the Company) that apply to the purchase you have made on their behalf (including all rules and restrictions applicable thereto).`;

export default function SignAgreement() {
	const { t } = useTranslation();
	const history = useHistory();

	return (
		<IonPage>
			<Header
				name={t('Agreement')}
				backButton={true}
				needArrow={true}
				needArrowOnClick={() => history.push('/dashboard/home')}
			/>
			<IonContent
				class=''
				// style={{ height: 'calc(100vh - 180px)' }}
			>
				<div className='bg-web py-md-3'>
					<div className='h-100 bg-web-white container-width-md border-radius-10'>
						<p
							className='m-0 text-grey px-3 py-md-3'
							dangerouslySetInnerHTML={{ __html: rawData }}
						></p>
					</div>
				</div>
			</IonContent>
			<IonFooter className='ion-padding ion-no-border bg-web-white'>
				<CustomButton
					className='btn-blue w-25-md'
					// routerDirection='root'
					routerLink='agreement-sign-type'
					expand='block'
					title={t('Proceed to E sign')}
				/>
			</IonFooter>
		</IonPage>
	);
}
