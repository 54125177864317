/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	IonAccordion,
	IonAccordionGroup,
	IonContent,
	IonItem,
	IonLabel,
	IonPage,
} from '@ionic/react';
import './HelpDesk.scss';
import { Header } from '../../components/layout/header/Header';
// import { CustomButton } from '../../components/common/custom-button/CustomButton';
// import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	getFaqTypes,
	helpDeskFaq,
	helpdesk,
	imgUrlUpdate,
} from '../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { Divider } from '../../components/common/divider/Divider';
import DropDownModal from '../../components/common/custom-dropdown/DropDownModal';
// import DropDownModal from '../../components/common/custom-dropdown/DropDownModal';

const defaultSrc = '/images/icons/default/user-profile-icon.svg';

export const HelpDesk: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const [helpdeskData, setHelpdeskData] = useState<any>({});
	const [src, setSrc] = useState('');
	const [faq, setFaq] = useState([]);
	const [faqTypes, setFaqTypes] = useState([]);
	const [selectedFaqType, setSelectedFaqType] = useState('');
	const accordionRef = useRef<any>(null);
	const dispatch = useDispatch();
	const isWeb = useSelector((state: any) => state?.app?.isWeb);

	useEffect(() => {
		getHelpdeskData();
		getHelpdeskFaqTypes();
	}, []);

	// useEffect(() => {
	// 	console.log('location', location.pathname);

	// 	const launcherBox = document.getElementById('zohohc-asap-web-helper-main');
	// 	if (launcherBox) {
	// 		if (location.pathname === '/help-desk') {
	// 			launcherBox.style.display = 'block';
	// 		} else {
	// 			launcherBox.style.display = 'none';
	// 		}
	// 	}
	// }, [location.pathname]);
	const getHelpdeskData = async () => {
		await dispatch(helpdesk())
			.then(unwrapResult)
			.then(async (response: any) => {
				if (response?.status) {
					setHelpdeskData(response?.data);
					if (response?.data?.manager?.profile_url) {
						await dispatch(
							imgUrlUpdate({
								data: {
									url: response?.data?.manager?.profile_url,
									expire_time: 50000,
								},
							})
						)
							.then(unwrapResult)
							.then((response: any) => {
								if (response?.status) {
									setSrc(response?.url);
								}
							});
					} else {
						setSrc(defaultSrc);
					}
				}
			});
	};

	const getHelpdeskFaqData = async (type = '') => {
		await dispatch(helpDeskFaq({ param: `?type=${type}` }))
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setFaq(response?.faq_list);
				}
			});
	};

	const getHelpdeskFaqTypes = async () => {
		await dispatch(getFaqTypes())
			.then(unwrapResult)
			.then((res: any) => {
				if (res?.status) {
					setFaqTypes(res?.data);
					res?.data?.length > 0 ? setSelectedFaqType(res?.data[0].id) : '';
					if (res?.data?.length > 0) {
						getHelpdeskFaqData(res?.data[0].value);
					}
				}
			});
	};
	// useEffect(() => {
	// 	if (location.pathname !== '/help-desk') {
	// 		return;
	// 	}

	// 	const script = document.createElement('script');
	// 	script.type = 'text/javascript';
	// 	script.nonce = '{place_your_nonce_value_here}';
	// 	script.src =
	// 		'https://desk.zoho.in/portal/api/web/inapp/136447000002214126?orgId=60026304046';
	// 	script.defer = true;
	// 	document.body.appendChild(script);

	// 	return () => {
	// 		const script = document.querySelector(
	// 			`script[src="${'https://desk.zoho.in/portal/api/web/inapp/136447000002214126?orgId=60026304046'}"]`
	// 		);
	// 		if (script) {
	// 			document.body.removeChild(script);
	// 		}
	// 		const launcherBox = document.getElementById(
	// 			'zohohc-asap-web-launcherbox'
	// 		);
	// 		if (launcherBox) {
	// 			launcherBox.style.display = 'none';
	// 		}
	// 	};
	// }, [location.pathname]);
	return (
		<>
			<IonPage className=''>
				<Header
					backButton={true}
					needArrow={true}
					backButtonText=''
					style={{
						background: isWeb ? 'white' : '#cfdff7',
					}}
					headerBg={'#cfdff7'}
					needArrowOnClick={() => {
						history.push('/dashboard/home');
					}}
					needArrowImg={'/images/icons/backarrow_white_bg.png'}
					needArrowImgStyle={{ height: '26px' }}
					name={t('Helpdesk')}
				/>
				<IonContent
					fullscreen
					className='help-desk'
				>
					{/* <IonContent> */}
					<div className='bg-web py-md-3'>
						<div className='help-desk-content border-md-radius-10 container-width-md'>
							{helpdeskData?.manager && (
								<div className='d-flex m-auto flex-column align-items-center ion-padding border-radius-2'>
									<img
										src={src}
										alt='profile icon'
										style={{
											height: 60,
											width: 60,
											borderRadius: '50%',
										}}
										className='d-flex m-auto mb-3'
									/>
									<div>
										<img
											src='/images/icons/hand-icon.svg'
											alt='hand icon'
										/>
										<span>
											{t('Hey, this is')} <b>{helpdeskData?.manager?.name}</b>
										</span>
										<p>{t('Your Andro Manager. How may I assist you?')}</p>
									</div>
									<div className='d-flex m-auto align-items-center'>
										<a href={`tel:${helpdeskData?.manager?.mobile}`}>
											<img
												src='/images/icons/leads/phone.svg'
												alt='phone icon'
												style={{
													width: 32,
													height: 32,
												}}
												className='me-3'
											/>
										</a>
										<a
											href={
												'https://api.whatsapp.com/send?phone=91' +
												helpdeskData?.manager?.mobile
											}
										>
											<img
												src='/images/icons/leads/whatsapp.svg'
												alt='phone icon'
												style={{
													width: 32,
													height: 32,
												}}
											/>
										</a>
									</div>
								</div>
							)}
							{helpdeskData?.manager && <Divider className='mt-4' />}
							<div className='ion-padding'>
								<h6 className='fs-16 fw-600'>{t('OneAndro Support')}</h6>
								<p style={{ color: '#5D5E72' }}>
									{t('Need help with any concerns?')}
								</p>
								{helpdeskData?.address?.contactno && (
									<div className='d-flex'>
										<p
											className=''
											style={{ color: '#5D5E72' }}
										>
											{t('Toll Free Number')}:{' '}
											<a
												href={`tel: ${helpdeskData?.address?.contactno}`}
												style={{ fontWeight: 'bold' }}
											>
												{helpdeskData?.address?.contactno}
											</a>
										</p>
									</div>
								)}
								<div
									className='cursor-pointer'
									onClick={() => {
										history.push('/raise-a-complaint');
									}}
								>
									<img
										src='/images/icons/complaint-alert-icon.svg'
										alt='direction'
									/>
									<span
										className='fs-14 fw-600 ms-3'
										style={{ color: 'var(--ion-color-primary)' }}
									>
										{t('Raise a Complaint')}
									</span>
								</div>
							</div>
							<Divider className='mt-4' />
							<div className='ion-padding'>
								<h6 className='fs-16 fw-600'>{t('Contact Us')}</h6>
								<p style={{ color: '#5D5E72' }}>
									{helpdeskData?.address?.address}
								</p>
								<div
									className=''
									onClick={() => {
										window.open(`${helpdeskData?.address?.maplink}`, '_blank');
									}}
								>
									<img
										src='/images/icons/direction-icon.svg'
										alt='direction'
									/>
									<span
										className='fs-14 fw-600 ms-3 cursor-pointer'
										style={{ color: 'var(--ion-color-primary)' }}
									>
										{t('Get Direction')}
									</span>
								</div>
							</div>
							<Divider className='mt-4' />
							<div className='mt-3 px-3'>
								<h6 className='fs-16 fw-600 mb-4'>{t('FAQs')}</h6>
								<DropDownModal
									label={t('Select Type')}
									data={faqTypes}
									name={'faq_type'}
									onChange={(e: any) => {
										setSelectedFaqType(e.detail.value);
										const newFaqType = faqTypes
											.filter((item: any) => item.id === e.detail.value)
											.map(({ value }: any) => value);
										getHelpdeskFaqData(newFaqType[0]);
										accordionRef?.current?.scrollIntoView({
											behavior: 'smooth',
											block: 'center',
										});
									}}
									value={selectedFaqType}
								></DropDownModal>
								<IonAccordionGroup
									style={
										faq.length > 0
											? { minHeight: '150px' }
											: { minHeight: 'auto' }
									}
									className='mb-3'
									ref={accordionRef}
								>
									{faq.map((item: any, index: any) => {
										return (
											<IonAccordion
												key={index}
												className={`${
													faq.length - 1 === index ? '' : 'border-bottom'
												} bg-transparent help-desk-accordion`}
												value={'acc' + index}
											>
												<IonItem
													slot='header'
													color=''
													className='p-0'
													style={{ '--inner-padding-end': '0px' }}
												>
													<IonLabel
														className='fs-14 my-1 fw-600 ion-text-wrap'
														color={'#0C0E2B'}
													>
														{item?.question}
													</IonLabel>
												</IonItem>
												<div
													className='pe-3 fs-14 mb-3'
													slot='content'
													style={{
														color: '#5D5E72',
													}}
													dangerouslySetInnerHTML={{ __html: item?.answer }}
												></div>
											</IonAccordion>
										);
									})}
								</IonAccordionGroup>
							</div>
						</div>
					</div>
				</IonContent>

				{/* </IonContent> */}
			</IonPage>
		</>
	);
};
