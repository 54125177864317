import { IonContent, IonFooter, IonSpinner } from '@ionic/react';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory, useLocation } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	getCity,
	getLeadFlowUrl,
	lenderLeadClose,
	lenderSpfDetailsFlow,
	lenderTataCheckCkyc,
	lenderTataPartnerEligiblity,
	lenderTataSecondPartnerEligiblity,
} from '../../../../store/reducers/lead/leadSlice';
import { CustomInput } from '../../../../components/common/custom-input/CustomInput';
import {
	ContinueLaterPopUp,
	checkFieldsValidToContinue,
	disableNumberArrows,
	findDestination,
	paplPopUp,
	showInAppBrowserView,
	showToastAlert,
} from '../../../../helpers/functions';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import {
	userInfoPL,
	destinationType,
	TataPLSchema,
	TataPLFormTitle,
} from '../TataCapitals';
import { LenderNameForAPICall } from '../../../../helpers/lenders';
import { REGEX } from '../../../../helpers/constant';
import CustomDropDown from '../../../../components/common/custom-dropdown/CustomDropDown';
import { OfferRejectedMsg } from '../../OfferRejectedMsg/OfferRejectedMsg';

export const PLPersonalInfoForm: React.FC<any> = () => {
	const [modalPageClose, setModalPageClose] = useState(false);
	const [paplmodalPageClose, setPaplModalPageClose] = useState(false);
	const [secondEligiblitymodalClose, setsecondEligiblityModalClose] =
		useState(false);
	const [paplModalData, setPaplModalData] = useState<any>();
	const [secondPEModalData, setSecondPEModalData] = useState<any>();
	const history = useHistory();
	const dispatch = useDispatch();
	const { lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);

	const [city, setCity] = useState<any>([]);
	const [destination, setDestination] = useState<any>(destinationType);
	const [url, setUrl] = useState('');
	const [spinner, setSpinner] = useState(false);
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const [parsedData, setParsedData] = useState<any>();
	const [partnerEligiblityData, setPartnerEligiblityData] = useState<any>();

	const location = useLocation();

	useEffect(() => {
		if (location.pathname !== '/lead-lender-specific-form-steps') {
			return;
		}
		dispatch(getLeadFlowUrl())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					const url = result.url;

					const pathname = new URL(url).origin;

					setUrl(pathname);
				}
			});
	}, []);

	useEffect(() => {
		if (location.pathname !== '/lead-lender-specific-form-steps') {
			return;
		}
		dispatch(getCity())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status_code == 200) {
					const ids = result?.data.map(({ city }: any) => city);
					const filtered = result?.data.filter(
						({ city }: any, index: any) => !ids.includes(city, index + 1)
					);

					const arrData: any = filtered.map((obj: any) => {
						obj['value'] = obj['city'];
						delete obj['city'];
						return obj;
					});

					setCity(arrData);
				}
			});
	}, []);

	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(TataPLSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			perm_city: '',
			perm_state: '',
			destination: '',
			webtopNumber: '',
			sourceSystemURL: '',
			req_irr: '',
		},
	});

	/**
	 * On submit to save user data and after that
	 * @param data Form data
	 */
	const onSubmit = async (data: any) => {
		setSpinner(true);
		dispatch(getLeadFlowUrl())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					const url = result.url;

					const pathname = new URL(url).origin;

					setUrl(pathname);
				}
			});
		setShowOfferErrMsg('');
		dispatch(
			await lenderTataPartnerEligiblity({
				data: {
					lead_spf_detail_id: lenderSpecificDetails?.lead_spf_detail_id,
					lender_name: LenderNameForAPICall.TATA_PL,
					sourceSystemURL: `${url}${location.pathname}`,
					destination: 'Statement' || findDestination(data.destination),
					perfios: 1,
					req_irr: data.req_irr,
				},
			})
		).then(async (res: any) => {
			try {
				setSpinner(false);
				if (res?.payload?.status) {
					dispatch(
						lenderSpfDetailsFlow({
							...lenderSpecificDetails,
							tataApplicationId:
								res?.payload?.eligibilityreq?.REQUEST.HEADER['APPLICATION-ID'],
						})
					);
					if (res?.payload?.papl_res || res?.payload?.vm_res) {
						if (res?.payload?.preapproved_offer_data) {
							setPaplModalPageClose(true);
							setPaplModalData(res?.payload?.preapproved_offer_data[0]);
							setPartnerEligiblityData(res?.payload);
							const paresed = JSON.parse(
								res?.payload?.eligibility_data?.response
							);
							setParsedData(paresed);
							dispatch(
								lenderSpfDetailsFlow({
									...lenderSpecificDetails,
									webtopNo: paresed?.REQUEST?.HEADER?.['WEBTOP-ID'],
									tataApplicationId: paresed?.REQUEST.HEADER['APPLICATION-ID'],
								})
							);
						}
					} else if (!res?.payload?.papl_res || !res?.payload?.vm_res) {
						dispatch(
							lenderLeadClose({
								data: {
									lead_application_id: leadFlowData?.lead_app_id,
								},
							})
						).then(() => {
							showToastAlert({
								type: 'error',
								message: 'Lead is Closed',
							});
						});
					}
				} else {
					const paresed = JSON.parse(res?.payload?.eligibility_data?.response);
					dispatch(
						lenderSpfDetailsFlow({
							...lenderSpecificDetails,
							webtopNo: paresed?.REQUEST?.HEADER?.['WEBTOP-ID'],
							tataApplicationId:
								res?.payload?.eligibilityreq?.REQUEST.HEADER['APPLICATION-ID'],
						})
					);
					setShowOfferErrMsg(
						res?.payload?.error?.message || res?.payload?.message
					);
				}
			} catch (error) {
				dispatch(
					lenderSpfDetailsFlow({
						...lenderSpecificDetails,
						lead_spf_detail_id: lenderSpecificDetails?.lead_spf_detail_id,
						headerTitle: TataPLFormTitle.REFERENCE_ONE,
						tataApplicationId:
							res?.payload?.eligibilityreq?.REQUEST.HEADER['APPLICATION-ID'],
						step: 'step2',
					})
				);
				console.error('Error In Partner Eligiliblity:', error);
				setShowOfferErrMsg('Something went wrong! Please try again later.');
			}
		});
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
							<form onSubmit={handleSubmit(onSubmit)}>
								{userInfoPL.map(
									({ name, type, placeholder }: any, index: any) => {
										const errorText: any =
											errors && Object.keys(errors).length > 0 && errors;
										return (
											<div key={index}>
												{type === 'dropdown' ? (
													<div style={{ marginBottom: '15px' }}>
														<Controller
															control={control}
															render={({ field: { name } }) => (
																<CustomDropDown
																	{...{
																		isCheckbox: false,
																		title:
																			name == 'perm_city'
																				? t('Permanent City *')
																				: t('Destination *'),
																		setData: (data: any) => {
																			name == 'perm_city'
																				? setCity(data)
																				: setDestination(data);
																			setValue(
																				name,
																				data
																					?.filter(
																						({ checked }: any) =>
																							checked && checked
																					)
																					.map(({ value }: any) => value)
																					.toString()
																			);
																		},
																		data:
																			name == 'perm_city' ? city : destination,
																		placeholder: t('Search'),
																		isSearch: true,
																		modalTitle:
																			name == 'perm_city'
																				? t('Select City')
																				: t('Select Destination'),
																		listTitle:
																			name == 'perm_city'
																				? t('Cities')
																				: t('Destinations'),
																	}}
																/>
															)}
															name={name}
														/>
														{errors && (
															<div className='input-bottom ps-3 pt-1'>
																<div className='error-text sc-ion-input-md'>
																	{name == 'perm_city'
																		? t(errors?.perm_city?.message || '')
																		: t(errors?.destination?.message || '')}
																</div>
															</div>
														)}
													</div>
												) : (
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<CustomInput
																type={type}
																label={t(placeholder)}
																onBlur={onBlur}
																id={name}
																onChange={(e: any) => {
																	onChange(e);
																}}
																value={getValues(name)}
																onBeforeInput={(evt: any) => {
																	if (type == 'number') {
																		!REGEX.ALLOWNUMBERS.test(evt.data) &&
																			evt.preventDefault();
																	}
																}}
																onKeyDown={(e: any) => {
																	if (type == 'number') {
																		disableNumberArrows(e);
																	}
																}}
																onWheel={(e: any) => {
																	if (type == 'number') {
																		e.target.blur();
																	}
																}}
																errors={t(errorText[name]?.message || '')}
															/>
														)}
														name={name}
													/>
												)}
											</div>
										);
									}
								)}
							</form>

							<div className={` d-none d-md-block`}>
								<CustomButton
									className='w-100 fw-bold dashboard-add-lead-button mb-3'
									expand='block'
									fill='outline'
									title={t('Continue Later')}
									onClick={() => {
										if (checkFieldsValidToContinue(errors)) {
											setModalPageClose(true);
										} else {
											showToastAlert({
												type: 'error',
												message: 'Please fix above errors',
											});
										}
									}}
								/>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									// disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				)}

				<CustomModal
					needIonContentWraper={false}
					isOpen={modalPageClose}
					initialBreakpoint={undefined}
					handleClose={() => setModalPageClose(false)}
					breakpoints={[]}
					className='height-auto confirm-popup-middle continue-later'
					modalContent={ContinueLaterPopUp(
						() => {
							setModalPageClose(false);
						},
						() => {
							new Promise(function (myResolve) {
								setTimeout(function () {
									myResolve(history.push('/dashboard/lead'));
									setModalPageClose(false);
								}, 10);
							});
						},
						'Cancel',
						'Continue Later',
						'className',
						'Are You Sure You Want to Exit?',
						`${t('You can continue later from where you left off.')}`
					)}
					needCross={false}
					needArrow={false}
				/>
				<CustomModal
					needIonContentWraper={false}
					isOpen={paplmodalPageClose}
					initialBreakpoint={undefined}
					handleClose={() => setPaplModalPageClose(false)}
					breakpoints={[]}
					backdropDismiss={false}
					className='height-auto confirm-popup-middle continue-later'
					modalContent={paplPopUp(
						() => {
							setPaplModalPageClose(false);

							setSpinner(true);
							dispatch(
								lenderTataCheckCkyc({
									data: {
										lead_spf_detail_id:
											lenderSpecificDetails?.lead_spf_detail_id,
										webtopNo: parsedData?.REQUEST?.HEADER?.['WEBTOP-ID'],
									},
								})
							).then((res: any) => {
								setSpinner(false);

								showToastAlert({
									type: 'error',
									message: res?.message,
								});
								dispatch(
									lenderSpfDetailsFlow({
										...lenderSpecificDetails,
										lead_spf_detail_id:
											lenderSpecificDetails?.lead_spf_detail_id,
										headerTitle: TataPLFormTitle.UPLOAD_DOCUMENTS,
										step: 'step3',
									})
								);
							});
						},
						() => {
							new Promise(function () {
								setTimeout(function () {
									dispatch(
										lenderTataSecondPartnerEligiblity({
											data: {
												lead_spf_detail_id:
													lenderSpecificDetails?.lead_spf_detail_id,
												lender_name: LenderNameForAPICall.TATA_PL,
											},
										})
									).then((res: any) => {
										try {
											if (res?.payload?.status) {
												setsecondEligiblityModalClose(true);
												setSecondPEModalData(res?.payload);
											}
										} catch (error) {
											console.error('Error In ckyc:...', error);
										}
									});

									setPaplModalPageClose(false);
								}, 10);
							});
						},
						'Yes',
						'No',
						'className',
						'Do you Want to continue with PAPL 0ffer?',
						`Approved IRR : ${paplModalData?.['APPROVED-IRR']}`,
						`Approved Loan Amount : ${paplModalData?.['APPROVED-LOAN-AMOUNT']}`,
						`Approved Loan Tenure : ${paplModalData?.['APPROVED-LOAN-TENOR']}`,
						'Note :Salary details/ Bank Statement of the customer will be required for Income based program',
						!partnerEligiblityData?.papl_res,
						!partnerEligiblityData?.vm_res
					)}
					needCross={false}
					needArrow={false}
				/>
				<CustomModal
					needIonContentWraper={false}
					isOpen={secondEligiblitymodalClose}
					initialBreakpoint={undefined}
					handleClose={() => setsecondEligiblityModalClose(false)}
					breakpoints={[]}
					backdropDismiss={false}
					className='height-auto confirm-popup-middle continue-later'
					modalContent={paplPopUp(
						() => {
							new Promise(function () {
								setTimeout(function () {
									// if (partnerEligiblityData?.redirectUrl) {
									// 	showInAppBrowserView(partnerEligiblityData?.redirectUrl);
									// }
									if (partnerEligiblityData?.data?.redirectUrl) {
										showInAppBrowserView(
											partnerEligiblityData?.data?.redirectUrl
										);
									}
									dispatch(
										lenderSpfDetailsFlow({
											...lenderSpecificDetails,
											lead_spf_detail_id:
												lenderSpecificDetails?.lead_spf_detail_id,
											headerTitle: TataPLFormTitle.REFERENCE_ONE,
											step: 'step4',
											OpportunityId: partnerEligiblityData?.OpportunityId,
											webTop:
												parsedData?.['REQUEST']?.['HEADER']?.['WEBTOP-ID'],
											applicationId:
												parsedData?.['REQUEST']?.['HEADER']?.['APPLICATION-ID'],
											source:
												'Statement' ||
												findDestination(getValues('destination')),
											lead_id:
												parsedData?.['REQUEST']?.['HEADER']?.['APPLICATION-ID'],
										})
									);

									dispatch(
										lenderTataCheckCkyc({
											data: {
												lead_spf_detail_id:
													lenderSpecificDetails?.lead_spf_detail_id,
												webtopNo:
													lenderSpecificDetails?.webTop ||
													parsedData?.['REQUEST']?.['HEADER']?.['WEBTOP-ID'],
											},
										})
									).then(() => {
										try {
											dispatch(
												lenderSpfDetailsFlow({
													...lenderSpecificDetails,
													lead_spf_detail_id:
														lenderSpecificDetails?.lead_spf_detail_id,
													headerTitle: TataPLFormTitle.REFERENCE_ONE,
													step: 'step4',
													OpportunityId: partnerEligiblityData?.OpportunityId,
													webTop:
														parsedData?.['REQUEST']?.['HEADER']?.['WEBTOP-ID'],
													applicationId:
														parsedData?.['REQUEST']?.['HEADER']?.[
															'APPLICATION-ID'
														],
													source:
														'Statement' ||
														findDestination(getValues('destination')),
													lead_id:
														parsedData?.['REQUEST']?.['HEADER']?.[
															'APPLICATION-ID'
														],
												})
											);
										} catch (error) {
											console.error('Error In ckyc:...', error);
										}
									});
									// dispatch(
									// 	lenderTataSecondPartnerEligiblity({
									// 		data: {
									// 			lead_spf_detail_id:
									// 				lenderSpecificDetails?.lead_spf_detail_id,
									// 			lender_name: LenderNameForAPICall.TATA_PL,
									// 		},
									// 	})
									// ).then((res: any) => {
									// 	try {
									// 		console.log(
									// 			res,
									// 			'response of lenderTataSecondPartnerEligiblity'
									// 		);

									// 	} catch (error) {
									// 		console.error('Error In ckyc:...', error);
									// 	}
									// });

									setsecondEligiblityModalClose(false);
								}, 10);
							});
							// dispatch(
							// 	lenderSpfDetailsFlow({
							// 		...lenderSpecificDetails,
							// 		lead_spf_detail_id: lenderSpecificDetails?.lead_spf_detail_id,
							// 		headerTitle: TataPLFormTitle.UPLOAD_DOCUMENTS,
							// 		step: 'step5',
							// 	})
							// );
						},
						() => {
							new Promise(function (myResolve) {
								dispatch(
									lenderLeadClose({
										data: {
											lead_application_id: leadFlowData?.lead_app_id,
										},
									})
								).then(() => {
									showToastAlert({
										type: 'error',
										message: 'Lead is Closed',
									});
								});

								myResolve(history.push('/dashboard/lead'));
								setsecondEligiblityModalClose(false);
							});
						},
						'Yes',
						'No',
						'className',
						'Do you Want to continue with Loan Offer?',
						`Approved Loan Amount : ${secondPEModalData?.['approved_amount']}`
					)}
					needCross={false}
					needArrow={false}
				/>
			</IonContent>

			{!showOfferErrMsg && (
				<IonFooter className='d-block d-md-none position-relative'>
					<div
						style={{
							backgroundColor: '#fff',
							zIndex: 2,
						}}
					>
						<div className={`ion-padding`}>
							<CustomButton
								className='w-100 fw-bold dashboard-add-lead-button mb-3'
								expand='block'
								fill='outline'
								title={t('Continue Later')}
								onClick={() => {
									if (checkFieldsValidToContinue(errors)) {
										setModalPageClose(true);
									} else {
										showToastAlert({
											type: 'error',
											message: 'Please fix above errors',
										});
									}
								}}
							/>
							<div className='proceed-btn'>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									// disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				</IonFooter>
			)}
			{spinner && (
				<div
					className='position-fixed  w-100  d-flex justify-content-center align-items-center h-100'
					style={{
						top: '0px',
						left: '50%',
						transform: 'translate(-50%,0%)',
						backgroundColor: 'rgba(0,0,0,0.2)',
					}}
				>
					<IonSpinner name='lines-sharp' />
				</div>
			)}
		</>
	);
};
