import { IonPage } from '@ionic/react';
//import React, { useEffect, useState } from 'react';
import { PrivacyPolicy } from '../../components/common/PrivacyPolicy';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
//import { privacyPolicy } from '../../store/reducers/user/userSlice';
//import { unwrapResult } from '@reduxjs/toolkit';
import './RegisterYourself.scss';

export const PrivacyPage: React.FC = () => {
	//const [privacypolicy, setPrivacypolicy] = useState('');
	const { t } = useTranslation();
	//const dispatch = useDispatch();
	const userSelector = useSelector((state: any) => state?.users);

	/*useEffect(() => {
		getPolicyData();
	}, []);

	const getPolicyData = () => {
		dispatch(privacyPolicy())
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setPrivacypolicy(response?.Content);
				}
			});
	};*/

	return (
		<IonPage className='bg-web'>
			<PrivacyPolicy
				needFooter={userSelector?.needFooter ? true : false}
				headerTitle={t('Privacy Policy')}
				termsPara={'https://www.andromedaloans.com/privacy-policy-app/'}
				afterAcceptRedirect='/partner-soft-code'
			/>
		</IonPage>
	);
};
