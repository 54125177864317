import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLenderUserInfo } from '../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
	ContinueLaterPopUp,
	FormatDate,
	checkFieldsValidToContinue,
	fetchStateCityByPincode,
	showToastAlert,
} from '../../../helpers/functions';
import { IonContent, IonFooter } from '@ionic/react';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import { t } from 'i18next';
import StateCity from '../../../components/common/StateCity';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { useHistory } from 'react-router';
import { saveLenderSpcfInfo } from '../../../store/reducers/lead/leadSlice';
import { LenderNameForAPICall } from '../../../helpers/lenders';
import { OfferRejectedMsg } from '../OfferRejectedMsg/OfferRejectedMsg';
import { CustomModalLeadStatus } from './custom-modal-lead-status/CustomModalLeadStatus';
// import { CustomModalLeadStatus } from '../../../components/common';

const FibePL_Form = () => {
	const dispatch = useDispatch();
	const [modalPageClose, setModalPageClose] = useState(false);
	const { leadFlowData, selectedLender } = useSelector(
		(state: any) => state?.lead
	);
	const [formValidationSchemas, setFormValidationSchemas] = useState(
		yup.object()
	);
	const [pinCodeData, setPinCodeData] = useState<any>([]);
	const [formFields, setFormFields] = useState([]);
	const history = useHistory();
	const [continueLaterValue, setContinueLaterValue] = useState({});
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const [leadStatusModalOpen, setLeadStatusModalOpen] = useState(false);
	const [applicationData, setApplicationData] = useState<any>();
	const onLeadStatusModalClose = () => {
		setLeadStatusModalOpen(false);
	};

	const {
		setValue,
		trigger,
		getValues,
		handleSubmit,
		control,
		reset,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(formValidationSchemas),
	});

	useEffect(() => {
		reset(continueLaterValue);
	}, [continueLaterValue]);

	useEffect(() => {
		if (location.pathname !== '/lead-lender-specific-form-steps') {
			return;
		}
		getLeadData();
	}, [location.pathname]);

	const getLeadData = async () => {
		await dispatch(
			getLenderUserInfo({
				param: leadFlowData?.leadId,
				data: {
					lender_name: 'fibe_pl',
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					const schemaBuilderObject = {};
					setFormValidationSchemas(
						formValidationSchemas.concat(
							yup.object().shape(schemaBuilderObject)
						)
					);

					const pincodeValue = result.data.find(
						(i: any) => i.name === 'pincode'
					)?.value;

					if (pincodeValue) {
						fetchStateCityByPincode(
							setValue,
							'city',
							'state',
							setPinCodeData,
							dispatch,
							pincodeValue,
							trigger
						);
					}

					setFormFields(result.data);

					result?.data.map((i: any) => {
						setContinueLaterValue((old) => {
							return {
								...old,
								[i?.name]: i?.value,
							};
						});
					});
				}
			});
	};

	const onSubmit = async (data: any, flag: any = '') => {
		const extrafields: any = {};
		formFields?.forEach((field: any) => {
			extrafields[field.name] = data[field.name];
		});
		const req: any = {
			lender_spf_details: {
				orgCity: pinCodeData[0]?.Region,
				orgState: pinCodeData[0]?.State,
				net_monthly_income: getValues('net_monthly_income'),
				cibil_consent: {
					status: 'Y',
					date: FormatDate(new Date(), "UTC:yyyy-mm-dd'T'HH:MM:ss'.969Z'"),
				},
				...extrafields,
			},
			step: 'step1',
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.FIBE,
			lead_id: leadFlowData?.leadId,
			isNewLead: isValid,
			continue_later: flag === 'continue_later' ? true : false,
		};
		await dispatch(saveLenderSpcfInfo({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					if (flag === 'continue_later') {
						setShowOfferErrMsg('');
					} else {
						setApplicationData(result?.data);
						setLeadStatusModalOpen(true);
						// setShowOfferErrMsg(
						// 	`${result?.message} ${
						// 		result?.data?.customerid
						// 			? `Lead Id - ${result?.data?.customerid}`
						// 			: ''
						// 	}`
						// );
					}
				} else {
					setShowOfferErrMsg(result?.error?.message || result?.message);
				}
			});
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
							<form onSubmit={handleSubmit(onSubmit)}>
								{formFields.map(
									({ name, placeholder, type, value }: any, index: any) => {
										if (type === 'text') {
											return (
												<Controller
													key={`controller-${index}`}
													name={name}
													control={control}
													render={({ field: { onChange, onBlur, name } }) => (
														<>
															<CustomInput
																type={type}
																label={t(placeholder)}
																onBlur={onBlur}
																id={name}
																disabled={value ? true : false}
																onChange={(e: any) => {
																	onChange(e);
																	if (name === 'pincode') {
																		if (e?.target?.value?.length === 6) {
																			fetchStateCityByPincode(
																				setValue,
																				'city',
																				'state',
																				setPinCodeData,
																				dispatch,
																				e.target.value,
																				trigger
																			);
																		}
																	} else {
																		setValue('state', '');
																		setValue('city', '');
																		setPinCodeData([]);
																	}
																}}
																value={getValues(name)}
																// errors={t(errorText[name]?.message || '')}
															/>
															{name === 'pincode' && (
																<StateCity
																	cityInputName='city'
																	control={control}
																	setValue={setValue}
																	pinCodeData={pinCodeData}
																	trigger={trigger}
																	getValues={getValues}
																/>
															)}
														</>
													)}
												/>
											);
										}
									}
								)}
							</form>
							<div className={` d-none d-md-block`}>
								<CustomButton
									className='w-100 fw-bold dashboard-add-lead-button mb-3'
									expand='block'
									fill='outline'
									title={t('Continue Later')}
									onClick={() => {
										if (checkFieldsValidToContinue(errors)) {
											setModalPageClose(true);
										} else {
											showToastAlert({
												type: 'error',
												message: 'Please fix above errors',
											});
										}
									}}
								/>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				)}

				<CustomModal
					needIonContentWraper={false}
					isOpen={modalPageClose}
					initialBreakpoint={undefined}
					handleClose={() => setModalPageClose(false)}
					breakpoints={[]}
					className='height-auto confirm-popup-middle continue-later'
					modalContent={ContinueLaterPopUp(
						() => {
							setModalPageClose(false);
						},
						() => {
							new Promise(function (myResolve) {
								setTimeout(function () {
									myResolve(history.push('/dashboard/lead'));
									onSubmit(getValues(), 'continue_later');
									reset();
									setModalPageClose(false);
								}, 30);
							});
						},
						'Cancel',
						'Continue Later',
						'className',
						'Are You Sure You Want to Exit?',
						`${t('You can continue later from where you left off.')}`
					)}
					needCross={false}
					needArrow={false}
				/>
			</IonContent>
			{leadStatusModalOpen && (
				<CustomModalLeadStatus
					isOpen={leadStatusModalOpen}
					applicationData={applicationData}
					copyLink={applicationData?.redirectionUrl}
					handleClose={() => onLeadStatusModalClose()}
					lenderData={selectedLender?.[0]}
				/>
			)}
			{!showOfferErrMsg && (
				<IonFooter className='d-block d-md-none position-relative'>
					<div
						style={{
							backgroundColor: '#fff',
							zIndex: 2,
						}}
					>
						<div className={`ion-padding`}>
							<CustomButton
								className='w-100 fw-bold dashboard-add-lead-button mb-3'
								expand='block'
								fill='outline'
								title={t('Continue Later')}
								onClick={() => {
									console.log('clicked');
									// if (checkFieldsValidToContinue(errors)) {
									// 	setModalPageClose(true);
									// } else {
									// 	showToastAlert({
									// 		type: 'error',
									// 		message: 'Please fix above errors',
									// 	});
									// }
								}}
							/>
							<div className='proceed-btn'>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									// disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				</IonFooter>
			)}
		</>
	);
};

export default FibePL_Form;
