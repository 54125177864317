//// @ts-nocheck


import { IonContent, IonFooter, IonImg, IonPage } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
// import {
// 	cbilConsentDetails,
// 	cbilRequestConsentDetails,
// 	leadBureauCustomer,
// 	leadFlow,
// } from '../../../store/reducers/lead/leadSlice';
// import { useDispatch, useSelector } from 'react-redux';
// import { unwrapResult } from '@reduxjs/toolkit';
// import { generateCibilOtp } from '../../../store/reducers/user/userSlice';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { CustomButton } from '../../components/common/custom-button/CustomButton';

import { Header } from "../../components/layout/header/Header";
import useApi from '../../api/useApi';
import { INSURANCE_FETCH_APPLICATION_DETAILS } from '../../helpers/api-endpoints';
import { useBusyLoading } from '../../context/BusyLoadingContext';
import { showToastAlert } from '../../helpers/functions';
import { AuthContext } from '../../context/AuthContext';
import ApplicationContext from '../../context/ApplicationContext';
import { ApplicationBean } from '../../model/insurance_bean';
import { INSURANCE_STATUS, REDIRECT } from '../../helpers/constant';
import { openAppDetails } from '../../helpers/redirects';


export default function AwaitCustomerApproval({ isFullPage = true }) {
	const [startApiInterval, setStartApiInterval] = useState(true);
	const [startCountDown, setStartCountDown] = useState(true);
	const [consentDetails, setConsentDetails]: any = useState({
		status: true,
		message: 'Waiting for consent from customer',
		is_rejected: false,
	});
	const { t } = useTranslation();
	const history = useHistory();
	const userContext = useContext(AuthContext);
	const { applicationBean, setAppBean } = useContext(ApplicationContext);


	// const leadBureauCustomerSelector = useSelector(
	// 	(state: any) => state?.lead?.leadBureauCustomerData
	// );

	// const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	// const dispatch = useDispatch();

	const { responseData, loading, executeRequest, error } = useApi<any>(
		INSURANCE_FETCH_APPLICATION_DETAILS
	);

	const { setBusyState } = useBusyLoading();

	useEffect(() => {
		setBusyState(loading);
	}, [loading]);

	useEffect(() => {
		showToastAlert({ type: "error", message: error?.message! });
	}, [error]);


	const redirectScreen = async () => {
		var applicationId = applicationBean?.applicationId;
		// const generateLeadRequest = new GenerateLeadRequest(applicationBean!);

		const payload = JSON.stringify({ applicationId: applicationId });
		// const payload = { applicationId: parseInt(applicationId)};

		console.log("payload", payload);
		executeRequest("post", payload, true);
	}

	useEffect(() => {

        if (responseData?.status) {
            // console.log("hbs getPolicyDetails.responseData?.data", getPolicyDetails.responseData?.data);
            const appBeanFin = new ApplicationBean(responseData?.data);
            setAppBean(appBeanFin);
			if(appBeanFin.statusId === INSURANCE_STATUS.APPLICATION_FULFILLMENT){
				openAppDetails(appBeanFin?.applicationId!, appBeanFin.statusId!, userContext, history);
			}
			else{
				// openAppDetails(appBeanFin?.applicationId!, appBeanFin.statusId!, userContext, history);

				history.replace(REDIRECT.DASHBOARD);
			}
            // console.log("hbs policyform", policyform);
        }
    }, [responseData]);



	const [seconds, setSeconds] = useState(60);
	useEffect(() => {
		let timerCountDown: any;
		if (startCountDown) {
			timerCountDown = setInterval(() => {
				// Decrease the seconds by 1
				setSeconds((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0));
			}, 1000);

			// Clear the interval when the component unmounts
		}
		return () => {
			if (timerCountDown) {
				clearInterval(timerCountDown);
			}
			setStartCountDown(false);
		};
	}, [startCountDown]);

	// Reset the timer when it reaches 0
	useEffect(() => {
		if (seconds === 0 && startCountDown) {
			// Reset the timer to 60 seconds
			setSeconds(60);
		}
		else if(seconds === 0){
			redirectScreen();
		}
	}, [seconds]);

	useEffect(() => {
		// if (
		// 	window?.location?.pathname === '/customer-consent-processing' &&
		// 	!startApiInterval
		// ) {
		// 	setStartApiInterval(true);
		// }
		// if (
		// 	window?.location?.pathname === '/customer-consent-processing' &&
		// 	!startCountDown
		// ) {
		// 	setStartCountDown(true);
		// }
		setStartCountDown(true);
	}, [window.location.pathname]);


	useEffect(() => {
		let intervalId: any;
		if (startApiInterval) {
			intervalId = setInterval(() => {
				checkConsent();
			}, 10000);
		} else {
			if (intervalId) {
				clearInterval(intervalId);
			}
		}
		return () => {
			if (intervalId) {
				clearInterval(intervalId);
				// setConsentDetails({ ...consentDetails, status: true });
			}
			// setStartCountDown(false);
		};
	}, [startApiInterval]);

	const checkConsent = async () => {
		setStartApiInterval(false);
		// dispatch(
		// 	cbilConsentDetails({
		// 		param: `${leadInfo?.leadId}/${leadInfo?.pccId}`,
		// 	})
		// )
		// 	.then(unwrapResult)
		// 	.then(async (response: any) => {
		// 		setStartApiInterval(true);
		// 		setConsentDetails({
		// 			status: response?.status,
		// 			message: response?.message
		// 				? response?.message
		// 				: 'Waiting for consent from customer',
		// 			is_rejected: response?.is_rejected,
		// 		});
		// 		if (response?.status && response?.message) {
		// 			setStartCountDown(false);
		// 			setSeconds(60);
		// 			setStartApiInterval(false);
		// 			await dispatch(
		// 				generateCibilOtp({
		// 					data: {
		// 						lead_id: leadInfo?.leadId,
		// 						mobile_no: leadInfo?.leadMobile,
		// 					},
		// 				})
		// 			)
		// 				.then(unwrapResult)
		// 				.then(async (cbilOtpResponse: any) => {
		// 					if (cbilOtpResponse.status) {
		// 						if (leadInfo?.lead_consent_type === 'Customer') {
		// 							dispatch(
		// 								leadBureauCustomer({
		// 									...leadBureauCustomerSelector,
		// 									cbilResponse: {
		// 										ChallengeConfigGUID:
		// 											cbilOtpResponse?.ChallengeConfigGUID,
		// 										answerKey: cbilOtpResponse?.answerKey,
		// 										questionKey: cbilOtpResponse?.questionKey,
		// 									},
		// 									type: 'VERIFY_CIBIL_OTP',
		// 									reSendCibilOtp: false,
		// 									needCibil: true,
		// 								})
		// 							);
		// 							history.push('/verify-otp-bureau-report');
		// 						} else {
		// 							dispatch(
		// 								leadFlow({
		// 									...leadInfo,
		// 									cbilResponse: {
		// 										ChallengeConfigGUID:
		// 											cbilOtpResponse?.ChallengeConfigGUID,
		// 										answerKey: cbilOtpResponse?.answerKey,
		// 										questionKey: cbilOtpResponse?.questionKey,
		// 									},
		// 									step: 'step6',
		// 									headerTitle: '',
		// 									subHeaderStepsCounter: false,
		// 									needCibil: true,
		// 									needCross: false,
		// 									needArrow: true,
		// 									reSendCibilOtp: false,
		// 								})
		// 							);
		// 						}
		// 					}
		// 				});
		// 		} else if (!response?.status && response?.message) {
		// 			setStartCountDown(false);
		// 			setSeconds(60);
		// 			setStartApiInterval(false);
		// 			// history.push('/dashboard/lead');
		// 		}
		// 	});
	};

	const reSendConsentRequest = async () => {
		// dispatch(
		// 	cbilRequestConsentDetails({
		// 		param: leadInfo?.leadId,
		// 	})
		// )
		// 	.then(unwrapResult)
		// 	.then(async (response: any) => {
		// 		if (response.status) {
		// 			// no else condition , already on same page
		// 			if (response?.is_consent) {
		// 				setStartCountDown(false);
		// 				setSeconds(60);
		// 				setStartApiInterval(false);
		// 				if (response?.is_otp) {
		// 					if (leadInfo?.lead_consent_type === 'Customer') {
		// 						// for check score
		// 						history.replace('/view-bureau-report');
		// 					} else {
		// 						history.replace('/bureau-report');
		// 					}
		// 				} else {
		// 					// generate cibil otp
		// 					// for check score
		// 					await dispatch(
		// 						generateCibilOtp({
		// 							data: {
		// 								lead_id: leadInfo?.leadId,
		// 								mobile_no: leadInfo?.leadMobile,
		// 							},
		// 						})
		// 					)
		// 						.then(unwrapResult)
		// 						.then(async (cbilOtpResponse: any) => {
		// 							if (cbilOtpResponse.status) {
		// 								if (leadInfo?.lead_consent_type === 'Customer') {
		// 									dispatch(
		// 										leadBureauCustomer({
		// 											...leadBureauCustomerSelector,
		// 											cbilResponse: {
		// 												ChallengeConfigGUID:
		// 													cbilOtpResponse?.ChallengeConfigGUID,
		// 												answerKey: cbilOtpResponse?.answerKey,
		// 												questionKey: cbilOtpResponse?.questionKey,
		// 											},
		// 											type: 'VERIFY_CIBIL_OTP',
		// 											reSendCibilOtp: false,
		// 											needCibil: true,
		// 										})
		// 									);
		// 									history.push('/verify-otp-bureau-report');
		// 								} else {
		// 									dispatch(
		// 										leadFlow({
		// 											...leadInfo,
		// 											cbilResponse: {
		// 												ChallengeConfigGUID:
		// 													cbilOtpResponse?.ChallengeConfigGUID,
		// 												answerKey: cbilOtpResponse?.answerKey,
		// 												questionKey: cbilOtpResponse?.questionKey,
		// 											},
		// 											lead_consent_type: 'Lead',
		// 											step: 'step6',
		// 											headerTitle: '',
		// 											subHeaderStepsCounter: false,
		// 											needCibil: true,
		// 											needCross: false,
		// 											needArrow: true,
		// 											reSendCibilOtp: false,
		// 										})
		// 									);
		// 								}
		// 							}
		// 						});
		// 				}
		// 			}
		// 		}
		// 	});
	};

	return (
		<>
			{isFullPage ? (
				<IonPage>
					<Header
						name=''
						backButton={true}
						backButtonText=''
						needArrow={true}
						needArrowOnClick={() => history.goBack()}
					/>
					<IonContent>
						<div className={`pt-5 mt-5`}>
							{/* <IonImg
								src='/images/icons/no-data-folder-icon.svg'
								style={{ height: '48px' }}
							/> */}
							<h2 className='m-0 text-center py-2'>{t('Customer Approval')}</h2>

							<h3
								className='fs-14 text-center py-2'
								style={{ color: '#5D5E72' }}
							>
								<div className='py-2'>{t(consentDetails?.message)}</div>

								{/* <div
									className='py-2 text-decoration-underline cursor-pointer'
									onClick={checkConsent}
								>
									{t('Resent consent request')}
								</div> */}
								<div
									className='py-2 text-decoration-underline cursor-pointer'
									onClick={reSendConsentRequest}
								>
									{t('Resend consent request')}
								</div>
							</h3>
							{startCountDown && (
								<h4 className='text-center py-2 m-0 fs-16'>
									00:{seconds < 10 ? `0${seconds}` : seconds}
								</h4>
							)}
						</div>
					</IonContent>
					<IonFooter>
						{
							// leadInfo?.from !== 'customer' && consentDetails?.is_rejected 
							true
								? (
									<div className='position-absolute bottom-0 w-100 py-2'>
										<div className='text-center'>
											<CustomButton
												expand=''
												className='btn-blue'
												onClick={() => {
													redirectScreen();
												}}
												title='Okay'
											/>
										</div>
									</div>
								)
								: (
									''
								)
						}
					</IonFooter>
				</IonPage>
			) : (
				<IonContent>
					<div
						className='position-relative'
						style={{ height: '100%' }}
					>
						<div
							className='d-flex flex-column justify-content-center'
							style={{ height: '400px' }}
						>
							<div className={`pt-5 mt-5`}>
								{/* <IonImg
							src='/images/icons/no-data-folder-icon.svg'
							style={{ height: '48px' }}
						/> */}

								<h2 className='m-0 text-center py-2'>{t('Customer Approval')}</h2>

								<h3
									className='fs-14 text-center py-2 m-0'
									style={{ color: '#5D5E72' }}
								>
									<div className='py-2'>{t(consentDetails?.message)}</div>
									<div
										className='py-2 text-decoration-underline cursor-pointer'
										onClick={reSendConsentRequest}
									>
										{t('Resent consent request')}
									</div>
								</h3>
								{startCountDown && (
									<h4 className='text-center py-2 m-0 fs-16'>
										00:{seconds < 10 ? `0${seconds}` : seconds}
									</h4>
								)}
							</div>
							<div className='d-flex justify-content-end pe-2 mt-5'>
								<IonImg
									src='/images/icons/powered_by_cbil.jpg'
									style={{ width: '75px' }}
								/>
							</div>
						</div>
						{consentDetails?.is_rejected ? (
							<div className='position-absolute bottom-0 w-100 py-2'>
								<div className='text-center'>
									<CustomButton
										expand=''
										className='btn-blue'
										onClick={() => {
											history.replace('/dashboard/lead');
										}}
										title='Okay'
									/>
								</div>
							</div>
						) : (
							''
						)}
					</div>
				</IonContent>
			)}
		</>
	);
}
