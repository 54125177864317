import { IonContent, IonFooter } from '@ionic/react';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	fetchMasterData,
	lenderSpfDetailsFlow,
	saveLenderSpcfInfo,
} from '../../../../store/reducers/lead/leadSlice';
import { CustomInput } from '../../../../components/common/custom-input/CustomInput';
import {
	ContinueLaterPopUp,
	checkFieldsValidToContinue,
	showToastAlert,
} from '../../../../helpers/functions';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import { applicantFormTwo, applicantFormTwoSchema } from './TataBl';
import { LenderNameForAPICall } from '../../../../helpers/lenders';
import { DynamicDropdown } from '../../../../components/common';
import CustomRadioButton from '../../../../components/common/custom-radio-button/CustomRadioButton';
import { OfferRejectedMsg } from '../..';

export const PrimaryApplicantForm2: React.FC<any> = ({
	isCoApplicant,
	setSelectedTabValue,
	setCoApplicant,
	setValidForm,
}) => {
	const [modalPageClose, setModalPageClose] = useState(false);
	const [coApplicantList, setCoApplicantList] = useState([]);
	const [addressType, setAddressType] = useState([]);
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const history = useHistory();
	const dispatch = useDispatch();
	const { selectedLender, lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);

	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		trigger,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(applicantFormTwoSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			net_monthly_income:
				lenderSpecificDetails?.lender_spf_details?.net_monthly_income || '',
			ref_first_name_1:
				lenderSpecificDetails?.lender_spf_details?.ref_first_name_1 || '',
			ref_last_name_1:
				lenderSpecificDetails?.lender_spf_details?.ref_last_name_1 || '',
			ref_mobile_no_1:
				lenderSpecificDetails?.lender_spf_details?.ref_mobile_no_1 || '',
			ref_first_name_2:
				lenderSpecificDetails?.lender_spf_details?.ref_first_name_2 || '',
			ref_last_name_2:
				lenderSpecificDetails?.lender_spf_details?.ref_last_name_2 || '',
			ref_mobile_no_2:
				lenderSpecificDetails?.lender_spf_details?.ref_mobile_no_2 || '',
			req_tenure: lenderSpecificDetails?.lender_spf_details?.req_tenure || '',
			req_irr: lenderSpecificDetails?.lender_spf_details?.req_irr || '',
			req_roi: lenderSpecificDetails?.lender_spf_details?.req_roi || '',
			preferred_address:
				lenderSpecificDetails?.lender_spf_details?.preferred_address || '',
			is_co_applicant:
				lenderSpecificDetails?.lender_spf_details?.is_co_applicant || '',
		},
	});

	useEffect(() => {
		if (
			lenderSpecificDetails?.lender_spf_details?.customer_type === 'Corporate'
		) {
			setValue('is_co_applicant', 'Yes');
			onDropDownChange('is_co_applicant');
		}
		if (getValues('is_co_applicant') === 'Yes') {
			setCoApplicant(true);
		} else {
			setCoApplicant(false);
		}
		getDataFromMaster();
	}, []);

	useEffect(() => {
		setValidForm(isValid);
	}, [isValid]);

	const getDataFromMaster = () => {
		['HOME_CO_APPLICANT', 'TATALAP_ADDRESS_TYPE'].forEach(async (ele: any) => {
			await dispatch(fetchMasterData({ param: ele }))
				.then(unwrapResult)
				.then((result: any) => {
					if (result?.status) {
						let addType: any = [];
						switch (ele) {
							case 'HOME_CO_APPLICANT':
								setCoApplicantList(result.data);
								break;
							case 'TATALAP_ADDRESS_TYPE':
								addType = result.data.map((item: any) => ({
									value: item.value,
									label: item.value,
									name: item.value,
								}));
								setAddressType(addType);
								break;
						}
					}
				});
		});
	};

	/**
	 * Set dropdown data
	 * @param name field name
	 */
	const setDropDownOptions = (name: string) => {
		switch (name) {
			case 'is_co_applicant':
				return coApplicantList;
		}
	};

	const onDropDownChange = (name: string) => {
		if (name === 'is_co_applicant') {
			if (getValues('is_co_applicant') === 'Yes') {
				setCoApplicant(true);
			} else {
				setCoApplicant(false);
			}
			dispatch(
				lenderSpfDetailsFlow({
					...lenderSpecificDetails,
					lender_spf_details: {
						...lenderSpecificDetails?.lender_spf_details,
						is_co_applicant: getValues('is_co_applicant'),
					},
				})
			);
			trigger(name);
		}
	};

	/**
	 * On submit to save user data and after that
	 * @param data Form data
	 */
	const onSubmit = async (data: any, flag?: any) => {
		const req: any = {
			lender_spf_details: {
				...lenderSpecificDetails?.lender_spf_details,
				...data,
				isValidApplicant2: isValid,
			},
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.TATA_BL,
			step: isCoApplicant ? 'step2' : 'step3',
			isNewLead: flag !== 'continue_later' && !isCoApplicant,
		};

		if (data.is_co_applicant !== 'Yes') {
			delete req.lender_spf_details.co_applicant;
		}

		if (lenderSpecificDetails?.lead_spf_detail_id) {
			req.lead_spf_detail_id = lenderSpecificDetails?.lead_spf_detail_id;
		}

		setShowOfferErrMsg('');
		await dispatch(saveLenderSpcfInfo({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					if (isCoApplicant) {
						setSelectedTabValue('CoApplicantForm0');
					}
					dispatch(
						lenderSpfDetailsFlow({
							...lenderSpecificDetails,
							lender_spf_details: req.lender_spf_details,
							step: isCoApplicant ? 'step2' : 'step3',
						})
					);
				} else {
					setShowOfferErrMsg(result?.message);
				}
			});
	};

	const isDisabledDropDown = (name: string) => {
		if (name === 'is_co_applicant') {
			return (
				lenderSpecificDetails?.lender_spf_details?.customer_type === 'Corporate'
			);
		}
	};

	/**
	 * Continue Later and Submit button
	 */
	const continueLaterAndSubmitBtn = () => {
		return (
			<>
				<CustomButton
					className='w-100 fw-bold dashboard-add-lead-button mb-3'
					expand='block'
					fill='outline'
					title={t('Continue Later')}
					onClick={() => {
						if (checkFieldsValidToContinue(errors)) {
							setModalPageClose(true);
						} else {
							showToastAlert({
								type: 'error',
								message: 'Please fix above errors',
							});
						}
					}}
				/>
				<CustomButton
					type='submit'
					className='w-100 fw-bold dashboard-add-lead-button'
					expand='block'
					title={t(
						getValues('is_co_applicant') === 'Yes'
							? 'Save & Continue'
							: 'Proceed'
					)}
					disabled={
						!lenderSpecificDetails?.lender_spf_details?.isValidApplicant1 ||
						!isValid
					}
					onClick={handleSubmit(onSubmit)}
				/>
			</>
		);
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 pt-3 p-md-5 border-radius-10'>
							<form onSubmit={handleSubmit(onSubmit)}>
								{applicantFormTwo.map(
									({ name, type, placeholder }: any, index: any) => {
										const errorText: any =
											errors && Object.keys(errors).length > 0 && errors;
										return (
											<div key={index}>
												{type === 'dropdown' ? (
													<div style={{ marginBottom: '15px' }}>
														<Controller
															control={control}
															render={({
																field: { onChange, onBlur, name },
															}) => (
																<DynamicDropdown
																	label={t(`${placeholder}`)}
																	data={setDropDownOptions(name)}
																	name={name}
																	onChange={(e: any) => {
																		onChange(e);
																		onDropDownChange(name);
																	}}
																	bindField={'value'}
																	showField1={'value'}
																	value={getValues(name)}
																	onBlur={onBlur}
																	errors={errorText[name]?.message}
																	disabled={isDisabledDropDown(name)}
																/>
															)}
															name={name}
														/>
													</div>
												) : type === 'radio' ? (
													<Controller
														key={index}
														control={control}
														render={({ field: { onChange, name } }) => (
															<div className='mb-3'>
																<h6>{t(placeholder)}</h6>
																<CustomRadioButton
																	type='group'
																	value={getValues(name)}
																	onIonChange={(e: any) => {
																		onChange(e);
																	}}
																	className='mb-2'
																	ionRadioClassName='me-4'
																	labelPlacement='end'
																	data={addressType}
																/>
																<div className='error-text px-3 sc-ion-input-md'>
																	{t(errorText[name]?.message)}
																</div>
															</div>
														)}
														name={name}
													/>
												) : (
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<CustomInput
																type={type}
																label={t(placeholder)}
																onBlur={onBlur}
																id={name}
																onChange={(e: any) => {
																	onChange(e);
																}}
																value={getValues(name)}
																errors={t(errorText[name]?.message || '')}
															/>
														)}
														name={name}
													/>
												)}
											</div>
										);
									}
								)}
							</form>

							<div className={`d-none d-md-block mt-4`}>
								{continueLaterAndSubmitBtn()}
							</div>
						</div>
					</div>
				)}
			</IonContent>

			{!showOfferErrMsg && (
				<IonFooter className='d-block d-md-none position-relative ion-padding lead_ftr_btn'>
					{continueLaterAndSubmitBtn()}
				</IonFooter>
			)}

			<CustomModal
				needIonContentWraper={false}
				isOpen={modalPageClose}
				initialBreakpoint={undefined}
				handleClose={() => setModalPageClose(false)}
				breakpoints={[]}
				className='height-auto confirm-popup-middle continue-later'
				modalContent={ContinueLaterPopUp(
					() => {
						setModalPageClose(false);
					},
					() => {
						new Promise(function (myResolve) {
							setTimeout(function () {
								myResolve(history.push('/dashboard/lead'));
								onSubmit(getValues(), 'continue_later');
								setModalPageClose(false);
							}, 10);
						});
					},
					'Cancel',
					'Continue Later',
					'className',
					'Are You Sure You Want to Exit?',
					`${t('You can continue later from where you left off.')}`
				)}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
