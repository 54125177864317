import * as yup from 'yup';


const welcome_call_status_schema = yup.object({
  welcome_call_date: yup
    .date().max(new Date(), "Date cannot be in the future")
    .required("Mandatory Field"),
  welcome_call_status: yup.string().required('Mandatory Field'),
  Remarks: yup.string().notRequired(),
});

  export default welcome_call_status_schema;