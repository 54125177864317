import {
  IonContent,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonPage,
  IonSearchbar
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { Header } from '../../components/layout/header/Header';
import './showAlerts.scss';  // Import the SCSS file
import { CustomButton } from '../../components/common/custom-button/CustomButton';

export const ShowAlerts: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory<any>();
  // const dmaSelector = useSelector((state: any) => state?.users);
  // const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
  const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout | null>(null);
  const [searchText, setSearchText] = useState('');  // State to manage search input


  const handleSingleClick = () => {
    history.push('/select-lead-product');
  };

  const handleDoubleClick = () => {
    alert('Item double-clicked!');
  };

  const handleClick = () => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
      handleDoubleClick();
    } else {
      const timeout = setTimeout(() => {
        handleSingleClick();
        setClickTimeout(null);
      }, 300); // Adjust the delay as needed
      setClickTimeout(timeout);
    }
  };

  return (
    <IonPage className="bg-web-white container-width-md container-spacing-md border-md-radius-top">
      <Header
        name={t('Cross-sell Alerts')}
        backButton={true}
        needArrow={true}
        needArrowOnClick={() => {

          history.goBack();

        }}
        className={''}
      />
      <IonContent className="ion-no-border">
        <IonSearchbar
          value={searchText}
          onIonChange={e => setSearchText(e.detail.value!)}
          debounce={500}  // Adjust the debounce delay as needed
        />
        <IonList>
          {Array.from({ length: 10 }, (_, i) => (
            <IonItemSliding key={i}>
              <IonItem button onClick={handleClick} className="custom-item">
                <IonLabel className="multiline-label">
                  Your lead (Lead-Name) is eligible for {["HL_BT", "PL_TOP-UP", "PL_BT", "HL_TOP-UP", "CREDIT CARD", "AUTO INSURANCE"][i % 6]}
                </IonLabel>
              </IonItem>
              <IonItemOptions side="start" className="custom-item-options">
                <IonItemOption color="danger" onClick={() => alert('Delete clicked')} className="custom-item-option">
                  Delete Left
                </IonItemOption>
              </IonItemOptions>
              <IonItemOptions side="end" className="custom-item-options">
                <IonItemOption color="danger" onClick={() => alert('Delete clicked')} className="custom-item-option">
                  Delete Right
                </IonItemOption>
              </IonItemOptions>
            </IonItemSliding>
          ))}
        </IonList>
        <div
          className='position-fixed w-100 bottom-0 start-0 px-3 pb-3'
          style={{ backgroundColor: '#fff' }}
        >
          <CustomButton
            className='btn-blue'
            expand='block'
            title='Back'
            onClick={() => {

              history.goBack();

            }}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};
