import React, { useEffect } from 'react';
import { useIonRouter } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';

import { Header } from '../../../components/layout/header/Header';
import { LeadsFlowGenerator } from '../../../components/common/custom-leads-flow-generator/LeadsFlowGenerator';
import { lenderSpfDetailsFlow } from '../../../store/reducers/lead/leadSlice';
import { BLForms } from './BLForms/BLForms';

export const InitiateIndifiLead: React.FC = () => {
	type ComponentType = React.ReactNode | React.ReactFragment;
	type StepsComponents = {
		step: string;
		component: ComponentType;
	};
	const dispatch = useDispatch();
	const router = useIonRouter();
	const { lenderSpecificDetails } = useSelector((state: any) => state?.lead);

	useEffect(() => {
		if (!location.pathname.includes('/lead-lender-specific-form-steps')) {
			return;
		}
	}, [location.pathname]);

	/**
	 * Go back to click on back icon
	 */
	const headerNeedArrowOnClick = async () => {
		dispatch(lenderSpfDetailsFlow({}));
		router.push('/lender-specific-info');
	};

	const stepsComponentsBL: StepsComponents[] = [
		{
			step: 'step1',
			component: <BLForms />,
		},
	];

	const stepsHeader: ComponentType = (
		<Header
			name={'INDIFI Specific Form'}
			backButton={true}
			needArrow={true}
			needCross={false}
			needArrowOnClick={() => headerNeedArrowOnClick()}
		/>
	);

	return (
		<>
			<LeadsFlowGenerator
				currentStep={lenderSpecificDetails?.step}
				stepsHeader={stepsHeader}
				displayStepsCounter={lenderSpecificDetails?.subHeaderStepsCounter}
				stepsComponents={stepsComponentsBL}
			/>
		</>
	);
};
