import {
	IonCard,
	IonCardContent,
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { Header } from '../../../components/layout/header/Header';
import { REDIRECT } from '../../../helpers/constant';
import useApi from '../../../api/useApi';
import { GET_TERMS_CONDITIONS } from '../../../helpers/api-endpoints';
import { showToastAlert } from '../../../helpers/functions';
import ApplicationContext from '../../../context/ApplicationContext';
import { AuthContext } from '../../../context/AuthContext';

// import {CustomForm} from 'andro-insurance-lib';

interface Props {
	title: string;
}

const ShareOtp: React.FC<Props> = ({ title }) => {
	const { t } = useTranslation();
	const history = useHistory();

	//   const onSubmit = async (data: ExistingPolicyDetailsForm) => {
	//     console.log(data);
	//     console.log(isValid);

	//     await getUsers();

	//     // Handle form submission logic here

	//     inpCallback();
	//     history.push(nextTabUrl);
	//   };

	const [issueDate, setIssueDate] = useState(null);
	const { applicationBean } = useContext(ApplicationContext);
	const { token, userId } = useContext(AuthContext);
	const applicationId = applicationBean?.applicationId;
	let mobile = applicationBean?.basicDetailsProposer?.mobile_no!;
	const customerConsetApi = useApi<any>(
		`${GET_TERMS_CONDITIONS}/${applicationId}`
	);

	console.log('tokennnn', token);

	function removeBearerPrefixAndStore(value) {
		const prefix = 'Bearer ';
		let cleanedValue = value || ''; // Default value to empty string if value is null

		if (cleanedValue.toLowerCase().startsWith(prefix.toLowerCase())) {
			cleanedValue = cleanedValue.slice(prefix.length);
		}

		return cleanedValue;
	}
	const cleanedToken = removeBearerPrefixAndStore(token);
	console.log('cleanedToken????????', cleanedToken);

	const customerConsent = async () => {
		try {
			const payload = {
				applicationId: applicationId,
				mobile: mobile,
				userId: userId,
				token: cleanedToken,
			};
			await customerConsetApi
				.executeRequest('post', payload, true, false)
				.then((response) => {
					if (customerConsetApi?.responseData?.data?.status_code == 200) {
						showToastAlert({
							type: 'success',
							message: customerConsetApi?.responseData?.data?.message!,
						});
					}
				});
		} catch (error) {
			showToastAlert({
				type: 'error',
				message: customerConsetApi?.error?.message!,
			});
		}
	};

	return (
		<IonPage className='bg-web-white container-width-md container-spacing-md border-md-radius-top'>
			<Header
				className='card-header'
				name={t(title ?? 'Share OTP')}
				backButton={true}
				needArrow={true}
				needArrowOnClick={() => {
					history.push(REDIRECT.DASHBOARD);
				}}
			/>

			<IonContent className='ion-padding-bottom'>
				<IonHeader collapse='condense'>
					<IonToolbar>
						<IonTitle size='large'>ShareOTP</IonTitle>
					</IonToolbar>
				</IonHeader>
				{/* <section
			className='overflowX-hidden pt-1'
			style={{ height: 'calc(100vh - 20px)' }}
		> */}

				<IonCard>
					<IonCardContent>
						<CustomButton
							className='btn-blue'
							type='submit'
							expand='block'
							title='Share Link'
							onClick={() => {
                                customerConsent();
								history.push('/await-customer-approval');
							}}
							// disabled={!isValid}
							// title={t('Update')}
							// disabled={!isValid}
						/>
						<div style={{ height: '50px' }} /> {/* Two-line spacer */}
						<CustomButton
							className='btn-blue'
							type='submit'
							expand='block'
							title='Enter OTP'
							onClick={() => {
								history.push('/verify-insurer-with-otp');
							}}
							// disabled={!isValid}
							// title={t('Update')}
							// disabled={!isValid}
						/>
					</IonCardContent>
				</IonCard>

				{/* </section> */}
			</IonContent>
		</IonPage>
	);
};

export default ShareOtp;
