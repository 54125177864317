import axios from 'axios';

// eslint-disable-next-line no-undef
const webURL = process.env.REACT_APP_API_ENDPOINT;
export const axiosInstatnce = axios.create({ baseURL: webURL });
export const setAuthToken: any = (token: any) => {
	if (token) {
		axiosInstatnce.defaults.headers.common['Authorization'] = 'Bearer ' + token;
	} else {
		delete axiosInstatnce.defaults.headers.common['Authorization'];
	}
};
