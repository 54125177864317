/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	IonButtons,
	IonContent,
	IonFooter,
	IonImg,
	// IonIcon,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonItem,
	IonLabel,
	IonPage,
} from '@ionic/react';
import { Header } from '../../components/layout/header/Header';
import './LeadList.scss';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { CustomCheckbox } from '../../components/common/custom-checkbox/CustomCheckbox';
import { useTranslation } from 'react-i18next';
import LeadContactCard from '../../components/common/lead-contact-card/LeadConatctCard';
import ScrollerTab from '../../components/common/scroller-tab/ScrollerTab';
// import { CustomInput } from '../../components/common/custom-input/CustomInput';
// import { search } from 'ionicons/icons';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { CustomNoData } from '../../components/common/custom-no-data/CustomNoData';
import {
	PageExitPopUp,
	findCurrentStatus,
	sanitizeHtml,
	showToastAlert,
} from '../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import {
	activeLender,
	allocateManager,
	dashboardLeadList,
	dashboardLeadProductType,
	fetchBureauCustomerReport,
	getLeadDisbursalData,
	getLoginSanctionedData,
	leadBureauCustomer,
	leadFilter,
	leadFlow,
	markAsClose,
	paginationSize,
	resetLeadFilterState,
} from '../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { CustomSearchbar } from '../../components/common/custom-searchbar/CustomSearchbar';
import {
	LEAD_STATUS,
	LeadStatus,
	disbursementType,
} from '../../helpers/constant';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
import { InitialLetter } from '../../components/common/initial-letter/InitialLetter';
import { bOpsManagerList } from '../../store/reducers/user/userSlice';

export const LeadList: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();

	const [leads, setLeads] = useState<any>([]);
	const [bulkSelection, setBulkSelection] = useState<boolean>(false);
	const [getLeadIds, setGetLeadIds] = useState<any>([]);
	const [leadTypes, setLeadTypes] = useState<any>([]);
	const [activeLeadType, setActiveLeadType] = useState<any>('Loan');
	const [value, setValue] = useState('');
	const [loadInfiniteInstance, setLoadInfiniteInstance] = useState<any>(true);
	const [disableInfiniteInstance, setDisableInfiniteInstance] =
		useState<any>(false);
	const dispatch = useDispatch();
	const [selectAll, setSelectAll] = useState(false);
	const [searchString, setSearch] = useState('');

	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	const leadInfoPageSizeSelector = useSelector((state: any) => state?.lead);
	const [isPartialOrFull, setPartialOrFull] = useState(
		disbursementType.PARTIAL
	);
	const [getAllLead, setAllLead] = useState([]);
	const [showAssignModal, setShowAssignModal] = useState<boolean>(false);
	const leadBureauCustomerSelector = useSelector(
		(state: any) => state?.lead?.leadBureauCustomerData
	);

	useEffect(() => {
		// setSelectAll(false);
		if (leadInfo?.leadStatus === LEAD_STATUS.ALLOCATOR_LABEL) {
			setBulkSelection(true);
		} else {
			setBulkSelection(false);
		}
	}, [history.location.state]);

	// useEffect added to handle pagination reset on page refresh
	useEffect(() => {
		window.addEventListener('beforeunload', resetPaginationInfo);
		return () => {
			window.removeEventListener('beforeunload', resetPaginationInfo);
		};
	}, []);

	useEffect(() => {
		if (location?.pathname !== '/lead-list') {
			return;
		}
		setDisableInfiniteInstance(false);
		resetPaginationInfo();
		getLeads(
			'',
			leadInfoPageSizeSelector?.defaultPageId,
			leadInfoPageSizeSelector?.defaultPageSize
		);
		setSearch('');
	}, [leadInfo?.update, location.pathname, isPartialOrFull]);

	useEffect(() => {
		if (searchString === '' || searchString.length >= 3) {
			getLeads();
		}
	}, [searchString]);

	const resetPaginationInfo = () => {
		dispatch(
			paginationSize({
				...leadInfoPageSizeSelector,
				pageSize: leadInfoPageSizeSelector?.defaultPageSize,
				pageId: leadInfoPageSizeSelector?.defaultPageId,
				searchTerm: '',
			})
		);
	};

	const handleSearch = async (search: string) => {
		dispatch(
			paginationSize({
				...leadInfoPageSizeSelector,
				searchTerm: search,
				pageId: leadInfoPageSizeSelector?.defaultPageId,
			})
		);
		setLoadInfiniteInstance(true);
		if (search === '' && search.length >= 3) {
			await getLeads(
				search,
				leadInfoPageSizeSelector?.defaultPageId,
				leadInfoPageSizeSelector?.defaultPageSize
			);
		}
	};

	// reset the list if not on current page
	useEffect(() => {
		if (location?.pathname !== '/lead-list') {
			setLeads([]);
			setGetLeadIds([]);
			setSelectAll(false);
			// resetPaginationInfo();
		}
	}, [location?.pathname]);

	const getLeads = async (
		searchTerm = '',
		pageId: any = '',
		pageSize: any = '',
		resetList = false
	) => {
		pageId = !pageId ? leadInfoPageSizeSelector?.pageId : pageId;
		pageSize = !pageSize ? leadInfoPageSizeSelector?.pageSize : pageSize;
		searchTerm =
			!searchTerm && !resetList
				? !leadInfoPageSizeSelector?.searchTerm
					? ''
					: leadInfoPageSizeSelector?.searchTerm
				: searchTerm;
		let _leadList = leads;
		if (
			(pageId === leadInfoPageSizeSelector.defaultPageId && searchTerm) ||
			resetList
		) {
			// setDmaList([]);
			_leadList = [];
		}
		// to clear _leadList old data I added this if condition
		if (
			pageId === leadInfoPageSizeSelector.defaultPageId &&
			pageSize === leadInfoPageSizeSelector.defaultPageSize &&
			!searchTerm
		) {
			_leadList = [];
		}
		const leadStatus =
			leadInfo?.leadStatus === 'all'
				? 'all'
				: leadInfo?.leadStatus ===
						findCurrentStatus(LEAD_STATUS.DISBURSED).label &&
				  isPartialOrFull === disbursementType.PARTIAL
				? LEAD_STATUS.PARTIALDISBURSED
				: findCurrentStatus(leadInfo?.leadStatus).id;

		if (
			leadInfo?.leadStatus === LEAD_STATUS.DISBURSED &&
			isPartialOrFull === disbursementType.PARTIAL
		) {
			dispatch(
				leadFlow({
					...leadInfo,
					isPartial: LEAD_STATUS.PARTIALDISBURSED_LABEL,
				})
			);
		}
		if (isPartialOrFull !== disbursementType.PARTIAL) {
			dispatch(
				leadFlow({
					...leadInfo,
					isPartial: '',
				})
			);
		}
		const apiParam = `?pageId=${pageId}&size=${pageSize}&searchItem=${sanitizeHtml(
			encodeURIComponent(searchTerm)
		)}&lead_status=${
			leadInfo?.applicationStatus === LEAD_STATUS?.CREDIT_BUREAU
				? LEAD_STATUS?.BUREAU
				: leadStatus
		}&product_id=${
			leadInfo?.applicationStatus === LEAD_STATUS?.CREDIT_BUREAU
				? ''
				: leadInfo?.leadTypeId || 1
		}${
			leadInfoPageSizeSelector.followUpDate
				? '&date=' + leadInfoPageSizeSelector.followUpDate
				: ''
		}${
			leadInfoPageSizeSelector.fromDate
				? '&fromDate=' + leadInfoPageSizeSelector.fromDate
				: ''
		}${
			leadInfoPageSizeSelector.toDate
				? '&toDate=' + leadInfoPageSizeSelector.toDate
				: ''
		}${
			leadInfoPageSizeSelector.locationType
				? '&locationType=' + leadInfoPageSizeSelector.locationType
				: ''
		}${
			leadInfoPageSizeSelector.subproduct
				? '&subproduct=' + leadInfoPageSizeSelector.subproduct
				: ''
		}${
			leadInfoPageSizeSelector.status
				? '&status=' + leadInfoPageSizeSelector.status
				: ''
		}${
			leadInfoPageSizeSelector.lender
				? '&lender_id=' + leadInfoPageSizeSelector.lender
				: ''
		}${
			leadInfoPageSizeSelector.dmaOwner
				? '&dma_owner=' + leadInfoPageSizeSelector.dmaOwner
				: ''
		}${
			leadInfo?.leadStatus === findCurrentStatus(LEAD_STATUS.DISBURSED).label
				? `&type=${isPartialOrFull}`
				: ''
		}`;

		await dispatch(
			dashboardLeadList({
				param: apiParam,
			})
		)
			.then(unwrapResult)
			.then(async (response: any) => {
				if (response?.status) {
					setAllLead(response?.LeadlistData);
					const setLeadsIds = response?.LeadlistData.map(({ id }: any) => {
						return {
							id: id,
							checked: selectAll ? true : false,
						};
					});

					if (
						response?.LeadlistData.length !==
						leadInfoPageSizeSelector?.defaultPageSize
					) {
						setLoadInfiniteInstance(false);
					} else {
						setLoadInfiniteInstance(true);
					}
					if (pageId > 1) {
						setGetLeadIds([...getLeadIds, ...setLeadsIds]);
					} else {
						setGetLeadIds(setLeadsIds);
					}
					setLeads(_leadList.concat(response?.LeadlistData));
					await dispatch(activeLender([]));
				}
			});
	};

	const handleBulkUpload = (event: any) => {
		const activeSelectedLeads = getLeadIds.map(({ id }: any) => {
			return {
				id: id,
				checked: event.detail.checked,
			};
		});
		setSelectAll(event.detail.checked);
		setGetLeadIds(activeSelectedLeads);
	};

	const handleTabLeadTypeChange = (product: any) => {
		setActiveLeadType(product.value);
		// getAllLeadsDetails(product.id);
	};

	const onCancel = () => {
		setBulkSelection(false);
		const setLeadsIds = getLeadIds?.map(({ id }: any) => {
			return {
				id: id,
				checked: false,
			};
		});
		setGetLeadIds(setLeadsIds);
		// setSelectAll(false);
	};

	const isSelectAll = getLeadIds.every(({ checked }: any) => checked === true);
	const countSelectedLeads = getLeadIds.filter(
		({ checked }: any) => checked
	).length;

	const isShowBulkOption = leadInfo?.leadStatus !== 'all';

	const getLeadAllSelectedIdsData = getLeadIds
		.filter(({ checked }: any) => checked)
		.map(({ id }: any) => id);

	const getLeadAllSelectedIds = getLeadAllSelectedIdsData?.filter(
		(item: any, index: any) =>
			getLeadAllSelectedIdsData?.indexOf(item) === index
	);

	const appsToUpdateArr = getLeadIds
		.filter(({ checked }: any) => checked)
		.map(({ appsToUpdate }: any) => appsToUpdate);

	const appsToUpdate = appsToUpdateArr?.flat();

	const appState = appsToUpdate?.filter(
		(item: any, index: any) => appsToUpdate?.indexOf(item) === index
	);

	const cardClick = ({
		id,
		leadStatus,
		mobile,
		lead_id,
		lead_app_id,
		full_name,
		lender_name,
		bank_app_no,
		otp_verified,
		item,
	}: any) => {
		console.log(item?.otp_verified, 'otp');
		if (
			!item?.otp_verified &&
			LEAD_STATUS?.CREDIT_BUREAU === leadInfo?.applicationStatus
		) {
			history.push('/add-bureau-score', {
				preData: item,
			});
		} else {
			const myPromise = new Promise(function (myResolve, myReject) {
				dispatch(
					leadBureauCustomer({
						...leadBureauCustomerSelector,
						cibil_id: item?.cibil_id || '',
						lead_id: id || '',
					})
				);
				setTimeout(function () {
					item?.otp_verified == 'YES' &&
					LEAD_STATUS?.CREDIT_BUREAU === leadInfo?.applicationStatus
						? myResolve(
								history.push('/view-bureau-report', {
									lead_id: id,
									mobile: mobile,
									full_name,
									pathname: '/lead-list',
								})
						  )
						: myResolve(history.push('/lead-details'));
				}, 10);
			});
			myPromise.then(function () {
				dispatch(
					fetchBureauCustomerReport({
						lead_type: 'Lead',
						lead_id: id,
						mobile_no: mobile,
						id: id,
					})
				);

				dispatch(
					leadFlow({
						...leadInfo,
						leadStatus: [
							LEAD_STATUS.LOGIN_LABEL,
							LEAD_STATUS.SANCTIONED_LABEL,
							LEAD_STATUS.DISBURSED_LABEL,
							LEAD_STATUS.UNDERPROCESS_LABEL,
							LEAD_STATUS.UNDERPROCESS,
							LEAD_STATUS.CLOSED_LABEL,
							LEAD_STATUS.CLOSED,
							LEAD_STATUS.PARTIALDISBURSED,
							LEAD_STATUS.PARTIALDISBURSED_LABEL,
						].includes(findCurrentStatus(leadStatus).label)
							? ['Partially Disbursed'].includes(
									findCurrentStatus(leadStatus).label
							  )
								? 'Disbursed'
								: findCurrentStatus(leadStatus).label
							: leadInfo?.leadStatus,
						via: leadInfo?.leadStatus,
						leadId: id,
						lead_app_id: lead_app_id,
						lead_id: lead_id,
						leadMobile: mobile,
						isPartial:
							isPartialOrFull === disbursementType.PARTIAL &&
							leadInfo?.leadStatus === LEAD_STATUS.DISBURSED_LABEL
								? LEAD_STATUS.PARTIALDISBURSED_LABEL
								: '',
						id: id,
						update: leadInfo?.update + 1 || 1,
						lender_name: lender_name,
						bank_app_no: bank_app_no,
					})
				);
			});
		}
	};

	const isDisbursed =
		leadInfo?.leadStatus === findCurrentStatus(LEAD_STATUS.DISBURSED).label;
	const isPartial = isPartialOrFull === disbursementType.PARTIAL;
	const isFull = isPartialOrFull === disbursementType.FULL;

	const handleBackBtn = () => {
		const myPromise = new Promise(function (myResolve, myReject) {
			setTimeout(function () {
				myResolve(history.push('/dashboard/lead'));
			}, 10);
		});
		myPromise.then(function () {
			setDisableInfiniteInstance(true);
			// reset dma list
			dispatch(resetLeadFilterState());

			setLeads([]);
			resetPaginationInfo();
			dispatch(
				leadFilter({
					locationType: '',
					followUpDate: '',
					fromDate: '',
					toDate: '',
					status: '',
					dmaOwner: '',
					lender: '',
					subproduct: '',
				})
			);
			setPartialOrFull(disbursementType.PARTIAL);
		});
	};

	return (
		<IonPage
			id='main-content'
			className='lead-list justify-content-start'
		>
			{/* <IonHeader> */}
			<Header
				name={t(
					isShowBulkOption
						? t(`${findCurrentStatus(leadInfo?.leadStatus).label}`)
						: t('All Leads')
				)}
				isCancle={
					leadInfo?.leadStatus != LEAD_STATUS.ALLOCATOR_LABEL
						? bulkSelection
						: false
				}
				onCancel={onCancel}
				needArrow={true}
				backButton={true}
				needArrowOnClick={() => {
					handleBackBtn();
				}}
				style={{ borderBottom: '1px solid #00000029' }}
				endItem={
					leadInfo?.leadStatus === LEAD_STATUS.ALLOCATOR_LABEL && (
						<IonButtons slot='end'>
							<IonButtons className='cursor-pointer'>
								<IonImg
									className=''
									src='/images/icons/assign-icon.svg'
									alt='assign'
									onClick={() => {
										getLeadAllSelectedIds.length !== 0
											? setShowAssignModal(true)
											: showToastAlert({
													type: 'error',
													message: 'Please select a Lead',
											  });
									}}
								/>
							</IonButtons>
						</IonButtons>
					)
				}
			/>
			<div className=' lead-card-container-spacing pb-md-2 py-md-0 bg-web-white '>
				{isDisbursed && (
					<div className='d-flex mt-2 mx-3'>
						<CustomButton
							className={`button-text-wrap ${
								!isPartial ? 'tab-gray-button' : ''
							}`}
							fill={isPartial ? 'solid' : 'outline'}
							title={t('Partially Disbursed')}
							onClick={() => setPartialOrFull(disbursementType.PARTIAL)}
						/>

						<CustomButton
							className={`button-text-wrap ${!isFull ? 'tab-gray-button' : ''}`}
							fill={isFull ? 'solid' : 'outline'}
							title={t('Fully Disbursed')}
							onClick={() => setPartialOrFull(disbursementType.FULL)}
						/>
					</div>
				)}
				<div className='d-flex mt-3 mx-3 justify-content-between align-items-center'>
					{/* <span> */}
					<div className='d-flex flex-grow-1'>
						<CustomSearchbar
							value={leadInfoPageSizeSelector?.searchTerm}
							searchIcon='/images/icons/searchbar-icon.svg'
							placeholder={t('Search Lead by “Name”')}
							showClearButton='focus'
							onChange={(v: any) => {
								if (!v.length) {
									setLeads([]);
								}
								handleSearch(v);
								setSearch(v);
							}}
							needFilter={
								leadInfo?.applicationStatus !== LEAD_STATUS.CREDIT_BUREAU
							}
							onFilterClick={() =>
								//leadInfo.leadStatus === 'all' && history.push('/lead-filter')
								history.push('/lead-filter')
							}
							onIonClear={() => {
								setLeads([]);
								// reset the pagination count
								resetPaginationInfo();
								getLeads(
									'',
									leadInfoPageSizeSelector?.defaultPageId,
									leadInfoPageSizeSelector?.defaultPageSize,
									true
								);
							}}
						/>
					</div>
					{/* </span> */}
					{leadInfo?.applicationStatus !== LEAD_STATUS.CREDIT_BUREAU && (
						<div className='d-none py-2 d-md-block'>
							{!isDisbursed &&
								isShowBulkOption &&
								leadInfo?.leadStatus !== LEAD_STATUS.CREDIT_BUREAU && (
									<>
										{leads.length > 0 && bulkSelection ? (
											<div className='px-3 pb-md-0 fs-14 fw-600 cursor-pointer'>
												<CustomCheckbox
													isChecked={isSelectAll}
													onChange={handleBulkUpload}
													labelPlacement='end'
												>
													{t('Select All')}
												</CustomCheckbox>
											</div>
										) : (
											![
												LEAD_STATUS.CLOSED_LABEL,
												LEAD_STATUS.UNDERPROCESS_LABEL,
											].includes(leadInfo?.leadStatus) &&
											leads.length > 0 && (
												<IonLabel
													className='px-3 fs-14 fw-600 cursor-pointer'
													style={{ color: 'var(--ion-color-primary)' }}
													onClick={() => {
														setBulkSelection(true);
														// setGetLeadIds([]);
													}}
												>
													<img
														src='/images/icons/leads/bulk-update-icon.svg'
														alt='bulk update icon'
														className='me-2'
													/>
													{t('Update Status in Bulk')}
												</IonLabel>
											)
										)}
									</>
								)}
						</div>
					)}
				</div>

				{leadInfo?.applicationStatus !== LEAD_STATUS.CREDIT_BUREAU && (
					<div>
						{!isDisbursed &&
							isShowBulkOption &&
							leadInfo?.leadStatus !== LEAD_STATUS.CREDIT_BUREAU && (
								<div className='mt-md-3 d-block d-md-none py-2'>
									{leads.length > 0 && bulkSelection ? (
										<div className='px-3 fs-14 fw-600 cursor-pointer'>
											<CustomCheckbox
												isChecked={isSelectAll}
												onChange={handleBulkUpload}
												labelPlacement='end'
											>
												{t('Select All')}
											</CustomCheckbox>
										</div>
									) : (
										![
											LEAD_STATUS.CLOSED_LABEL,
											LEAD_STATUS.UNDERPROCESS_LABEL,
										].includes(leadInfo?.leadStatus) &&
										leads.length > 0 && (
											<IonLabel
												className='px-3 fs-14 fw-600 cursor-pointer'
												style={{ color: 'var(--ion-color-primary)' }}
												onClick={() => {
													setBulkSelection(true);
													// setGetLeadIds([]);
												}}
											>
												<img
													src='/images/icons/leads/bulk-update-icon.svg'
													alt='bulk update icon'
													className='me-2'
												/>
												{t('Update Status in Bulk')}
											</IonLabel>
										)
									)}
								</div>
							)}
					</div>
				)}
			</div>
			<IonContent>
				{leads.length ? (
					<div
						className='lead-card-container-spacing  bg-web'
						style={{ minHeight: '100%' }}
					>
						<LeadContactCard
							leads={leads}
							isShowFooter={true}
							getLeadIds={getLeadIds}
							setGetLeadIds={setGetLeadIds}
							bulkSelection={bulkSelection}
							onClick={cardClick}
							getAllLead={getAllLead}
							from='lead'
							isShowLeadStatusButton={
								leadInfo?.leadStatus === 'all' ||
								leadInfo?.leadStatus === LEAD_STATUS.UNDERPROCESS_LABEL
							}
							footerStatus={leadInfo?.applicationStatus}
							// leadInfo?.applicationStatus === LEAD_STATUS?.CREDIT_BUREAU
						/>

						{/* infinite scroller */}
						<IonInfiniteScroll
							threshold='20%'
							disabled={disableInfiniteInstance}
							onIonInfinite={(ev) => {
								if (!loadInfiniteInstance) {
									ev.target.complete();
									return;
								}
								const updatedPageId =
									leadInfoPageSizeSelector?.pageId +
									leadInfoPageSizeSelector?.defaultPageId;
								dispatch(
									paginationSize({
										...leadInfoPageSizeSelector,
										pageId: updatedPageId,
									})
								);
								getLeads(leadInfoPageSizeSelector?.searchTerm, updatedPageId);
								setTimeout(() => {
									ev.target.complete();
								}, 200);
							}}
						>
							<IonInfiniteScrollContent
								loadingSpinner={null}
							></IonInfiniteScrollContent>
						</IonInfiniteScroll>
					</div>
				) : (
					<CustomNoData />
				)}
			</IonContent>
			<CustomModal
				id='customModalRework'
				isOpen={showAssignModal}
				modalTitle={t('Select Manager')}
				initialBreakpoint={1}
				handleClose={() => setShowAssignModal(false)}
				breakpoints={[0, 1]}
				className='assignModal side-modal'
				modalContent={
					<ManagerListModal
						{...{
							setShowAssignModal,
							getLeadAllSelectedIds,
							setGetLeadIds,
							setLeads,
							setSelectAll,
							getLeads,
							leadInfo,
						}}
					/>
				}
				scrollY={true}
				needCross={true}
				needArrow={false}
			></CustomModal>

			<IonFooter>
				{leads.length > 0 &&
				bulkSelection &&
				leadInfo?.leadStatus != LEAD_STATUS.ALLOCATOR_LABEL ? (
					<div
						className='ion-padding bg-white button-size-md lead-card-container-spacing'
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<div
							style={{ flex: '80%' }}
							className='fw-900'
						>
							{countSelectedLeads} {t('selected')}
						</div>
						<CustomButton
							// routerLink='/comman-info'
							className='w-100 fw-bold dashboard-add-lead-button'
							expand='block'
							disabled={!countSelectedLeads}
							onClick={() => {
								dispatch(
									leadFlow({
										...leadInfo,
										isBulk: true,
										addDisposition: false,
										getLeadAllSelectedIds,
										appsToUpdate: appState,
										showDispsition: appState.lnegth > 1,
									})
								);
								dispatch(markAsClose(true));
								history.push('/update-status', getLeadAllSelectedIds);
							}}
							title={t('Proceed')}
						/>
					</div>
				) : null}
			</IonFooter>
		</IonPage>
	);
};

const ManagerListModal = ({
	setShowAssignModal,
	getLeadAllSelectedIds,
	setGetLeadIds,
	setLeads,
	setSelectAll,
	getLeads,
	leadInfo,
}: any) => {
	const [teamMemberList, setTeamMemberList] = useState<any>([]);
	const [filteredList, setFilteredList] = useState<any>([]);
	const [showPageExitPopup, setShowPageExitPopup] = useState<boolean>(false);
	const [popupName, setPopupName] = useState<string>('');
	const [searchText, setSearchText] = useState<string>('');
	const [reportingManagerId, setReportingManagerId] = useState<number>(0);
	const dispatch: any = useDispatch();

	const getMembersList = async () => {
		await dispatch(
			bOpsManagerList({
				param:
					leadInfo?.leadStatus === LEAD_STATUS.ALLOCATOR_LABEL
						? '?allocator=true'
						: '',
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setTeamMemberList(response?.central_exec_list);
					setFilteredList(response?.central_exec_list);
				}
			});
	};

	const onSearchInput = (searchTerm: string) => {
		setSearchText(searchTerm);
		if (searchTerm !== '') {
			if (searchTerm.length >= 3) {
				const searchedList = teamMemberList.filter((member: any) => {
					const mName = member.full_name.toLowerCase();
					return mName.includes(searchTerm.toLowerCase());
				});
				setFilteredList(searchedList);
			}
		} else {
			getMembersList();
		}
	};

	const assignManagerPopup = (mId: number, mName: string) => {
		setReportingManagerId(mId);
		setPopupName(mName);
		setShowPageExitPopup(true);
	};

	const assignManager = async () => {
		await dispatch(
			allocateManager({
				param: getLeadAllSelectedIds,
				data: {
					user_id: reportingManagerId,
				},
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setShowPageExitPopup(false);
					setShowAssignModal(false);
					setSelectAll(false);
					setGetLeadIds([]);
					setLeads([]);
					getLeads();
				}
			});
	};

	useEffect(() => {
		getMembersList();
	}, []);

	return (
		<div>
			{/* <IonSearchbar
				className='team-dma-search px-0'
				placeholder='Search Team Member by “Name”'
				value={searchText}
				onIonChange={(e) => onSearchInput(e.detail.value!)}
				onIonClear={() => onSearchInput('')}
			></IonSearchbar> */}
			<CustomSearchbar
				value={searchText}
				searchIcon='/images/icons/searchbar-icon.svg'
				placeholder='Search Manager by “Name”'
				showClearButton='focus'
				onChange={onSearchInput}
				onIonClear={() => onSearchInput('')}
			/>
			<div className='mt-3'>
				{filteredList?.map((member: any, index: any) => {
					return (
						<IonItem
							key={index}
							className='ion-no-padding'
							lines='full'
							onClick={() => assignManagerPopup(member.id, member.full_name)}
						>
							<div className='d-flex flex-grow-1 align-items-center my-2'>
								<div style={{ width: '34px', height: '34px' }}>
									<InitialLetter
										value={member?.full_name}
										height='34px'
										width='34px'
										fontSize='22px'
									/>
								</div>
								<div className='ms-3'>
									<p className='mb-0 fs-16'>{member.full_name}</p>
								</div>
							</div>
						</IonItem>
					);
				})}
			</div>

			<CustomModal
				id='custommodal'
				isOpen={showPageExitPopup}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setShowPageExitPopup(false)}
				breakpoints={[]}
				className='central-ops-assign'
				modalContent={PageExitPopUp(
					() => {
						setShowPageExitPopup(false);
					},
					() => {
						assignManager();
					},
					'Cancel',
					'Confirm',
					'',
					'Are you sure you want to allocate selected Lead to <span class="primary-text-color">' +
						popupName +
						'</span> ?',
					'By doing this you will not be able to take any action on this Lead anymore.'
				)}
				needCross={false}
				needArrow={false}
			/>
		</div>
	);
};
