import { IonRouterOutlet, useIonRouter } from '@ionic/react';
import { Redirect, Route } from 'react-router';
import { ChooseLanguage } from '../../pages/app-language/ChooseLanguage';
import { AddMobile } from '../../pages/add-mobile/AddMobile';
import { OtpVerification } from '../../pages/otp-screen/OtpVerification';
import { RegisterYourself } from '../../pages/register-yourself/RegisterYourself';
import { AddYourDetails } from '../../pages/register-yourself/individual/AddYourDetail';
import { TermsPage } from '../../pages/register-yourself/TermsPage';
import { PartnerSoftCode } from '../../pages/partner-soft-code/PartnerSoftCode';
import { GrantPermission } from '../../pages/grant-permission/GrantPermission';
import { Dashboard } from '../../pages/dashboard/Dashboard';
import { Login } from '../../pages/login/Login';
import { GettingStarted } from '../../pages/getting-started/GettingStarted';
import { AddFirmDetails } from '../../pages/register-yourself/firm/AddFirmDetails';
import { ContactPersonDetails } from '../../pages/register-yourself/firm/ContactPersonDetails';
import { PrivacyPage } from '../../pages/register-yourself/PrivacyPage';
import { Settings } from '../../pages/settings/Settings';
import { LeadDetails } from '../../pages/lead-details/LeadDetails';
import { SelectLeadProduct } from '../../pages/select-lead-product/SelectLeadProduct';
import { HelpDesk } from '../../pages/helpdesk/HelpDesk';
import { ApplicationPreview } from '../../pages/application-preview/ApplicationPreview';
import { InitiateLoanLead } from '../../pages/add-lead-info/loan/InitiateLoanLead';
import { AllLenders } from '../../pages/all-lenders/AllLenders';
import { SelectedLenders } from '../../pages/selected-lenders/SelectedLenders';
import { CommanInfo } from '../../pages/comman-info/CommanInfo';
import { BankRelatedInfo } from '../../pages/bankRelaltedInfo/BankRelatedInfo';
import { SpecificLendersInfo } from '../../pages/specific-lender-info/SpecificLendersInfo';
import { BureauReport } from '../../pages/bureau-report/BureauReport';
import Successfull from '../../pages/flash-screens/Successfull';
import { Activity } from '../../pages/activity/Activity';
import { AppWelcome } from '../../pages/flash-screens/AppWelcome';
import { LeadList } from '../../pages/lead-list/LeadList';
import { DisbursementDetails } from '../../pages/disbursement-details/DisbursementDetails';
// import { ProfessionalInfo } from '../../pages/professional-info/ProfessionalInfo';
import { HdfcDetails } from '../../pages/hdfc-details/HdfcDetails';
import { UpdateStatus } from '../../pages/lead-details/UpdateStatus';
import { OpenRoutes } from './OpenRoutes';
import { PrivateRoutes } from './PrivateRoutes';
import { LeadAbout } from '../../pages/lead-details/LeadAbout';
import { SubProducts } from '../../pages/products/SubProducts';
import { ProductDetail } from '../../pages/products/ProductDetail';
import { AllProductLenders } from '../../pages/products/AllProductLenders';
//import { LenderProductDetail } from '../../pages/products/LenderProductDetail';
import { Tools } from '../../pages/products/Tools';
import { MyKyc } from '../../pages/dashboard/kyc/MyKyc';
import SignAgreement from '../../pages/e-sign-agreement/SignAgreement';
import AgreementSignType from '../../pages/e-sign-agreement/AgreementSignType';
import { OCRTest } from '../../pages/OcrTest';
import { Notification } from '../../pages/allnotification/Notification';
import { TeamList } from '../../pages/teams/TeamList';
import { useContext, useEffect } from 'react';
import { DmaDetail } from '../../pages/teams/DmaDetail';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { DmaAbout } from '../../pages/teams/DmaAbout';
import { PayoutDetail } from '../../pages/teams/PayoutDetail';
import { RequiredUpdateDetail } from '../../pages/teams/RequiredUpdateDetails';
import { ProposedPayout } from '../../pages/teams/ProposedPayout';
import { UpdateDetail } from '../../pages/teams/UpdateDetail';
import { InviteDma } from '../../pages/teams/InviteDma';
import { AddDma } from '../../pages/teams/AddDma/AddDma';
import { VerifyDmaWithOtpVerification } from '../../pages/teams/AddDma/VerifyDmaWithOtp';
import { Team } from '../../pages/teams/CentralOps/Team';
import { DmaList } from '../../pages/teams/CentralOps/DmaList';
import { DmaVerifySteps } from '../../pages/teams/CentralOps/DmaVerifySteps';
import { DmaVerificationStatus } from '../../pages/teams/CentralOps/DmaVerificationStatus';
import { DmaUserDetails } from '../../pages/teams/CentralOps/DmaUserDetails';

import { CheckBureauScore } from '../../pages/bureau-report/dashboard-bureau/CheckBureauScore';
import { BureauReportOtp } from '../../pages/bureau-report/dashboard-bureau/BureauReportOtp';
import { ViewBureauReport } from '../../pages/bureau-report/dashboard-bureau/ViewBureauReport';
import AddBureauDetail from '../../pages/bureau-report/dashboard-bureau/AddBureauDetails';
import ProfessionalInfoInputs from '../../pages/professional-info/ProfessionalInfoInputs';
import { ApproveProposedPayout } from '../../pages/teams/ApprovePayout';
import { InnerDetails } from '../../pages/bureau-report/dashboard-bureau/InnerDetails';
import { AddInviteLead } from '../../pages/bureau-report/dashboard-bureau/AddInviteLead';
import { InsuranceLeadBasicInfo } from '../../pages/add-lead-info/insurance_old/InsuranceLeadBasicInfo';
import { IdCard } from '../../pages/id-card/IdCard';
import { MyBusiness } from '../../pages/my-business/MyBusiness';
import { EarningDetails } from '../../pages/my-business/EarningDetails';
import { Filters } from '../../pages/filters/Filters';
import { EfrankingInitiate } from '../../pages/teams/CentralOps/EfrankingInitiate';
import { EfrankingSend } from '../../pages/teams/CentralOps/EfrankingSend';
import ViewProfile from '../layout/menu/view-profile/ViewProfile';
import { DmaApprovedInfo } from '../../pages/teams/CentralOps/DmaApprovedInfo';
import TeamsMyDmaList from '../../pages/teams/teams-dma/TeamsMyDmaList';
import TeamsDmaInvites from '../../pages/teams/teams-dma/TeamsDmaInvites';
import TeamsDmaInviteMember from '../../pages/teams/teams-dma/TeamsDmaInviteMember';
import { AddLeadDisposition } from '../../pages/lead-details/AddLeadDisposition';
import { LeadDisposition } from '../../pages/lead-details/LeadDisposition';
import { DmaActivity } from '../../pages/teams/DmaActivities';
import { LeadComments } from '../../pages/lead-details/LeadComments';
import CustomSuccessfull from '../../pages/flash-screens/CustomSuccessfull';
import { DmaApprovedAbout } from '../../pages/teams/CentralOps/DmaApprovedAbout';
import { InviteEmployee } from '../../pages/teams/InviteEmployee';
import { InviteEmployeForm } from '../../pages/teams/InviteEmployeForm';
import { DmaApprovedPayoutDetails } from '../../pages/teams/CentralOps/DmaApprovedPayoutDetails';
// import { EmployeeDetails } from '../../pages/teams/EmployeeDetails';
import { HomeLoanLenders } from '../../pages/products/AllLenders';
import { useDispatch } from 'react-redux';
import {
	appNotification,
	//backButtonUpdate,
	webView,
} from '../../store/reducers/app/appSlice';
import { BankDetails } from '../../pages/products/BankDetails';
import { ManageManagerPayout } from '../../pages/teams/ManageManagerPayout';
import { AddUpdateManagerPayout } from '../../pages/teams/AddUpdateManagerPayout';
import { Nimbuzz } from '../../pages/nimbuzz/Nimbuzz';
import { DisbursementDetailsForm } from '../../pages/disbursement-details/DisbursementDetailsForm';
import { SelectedLendersList } from '../../pages/all-lenders/SelectedLendersList/SelectedLendersList';
import { LeanderSpecificInfo } from '../../pages/all-lenders/LendeSpecificInfo/LenderSpecificInfo';
import { BankVisitSite } from '../../pages/all-lenders/BankVisitSite/BankVistSite';
import { SanctionAndDisbursedDetail } from '../../pages/all-lenders/SanctionAndDisbursedDetail/SanctionAndDisbursedDetail';
import LeadFilters from '../../pages/filters/LeadFilter';
import LeadCustomerConsentProcessing from '../../pages/add-lead-info/loan/LeadCustomerConsentProcessing';
import { CloseAndDispositionForm } from '../../pages/lead-details/CloseAndDispositionForm';
import { CustomerService } from '../../pages/qr-journey/CustomerService';
import { LenderLoanList } from '../../pages/products/LenderLoanList';
import { LenderLoanConsultants } from '../../pages/products/LenderLoanConsultants';
import { ConsultationFilters } from '../../pages/filters/ConsultaionFilter';
import { BankOnlineForm } from '../../pages/all-lenders/BankOnlineForm/BankOnlineForm';
import { VerifyLenderWithOtp } from '../../pages/all-lenders/VerifyLenderWithOtp/VerifyLenderWithOtp';
import RaiseComplaint from '../../pages/raise-a-complaint/RaiseComplaint';
import { MisReports } from '../../pages/misreports/MisReports';
import TrainingSessions from '../../pages/training-sessions/TrainingSessions';
import { BulkUploadHistory } from '../../pages/bulk-upload-history/BulkUploadHistory';
import { App } from '@capacitor/app';
import { RMSelectionForm } from '../../pages/all-lenders/RMSelectionForm/RMSelectionForm';
import PlCommonFieldsForm from '../../pages/all-lenders/CommonForms/PlCommonFieldsForm';
import BlCommonFieldsForm from '../../pages/all-lenders/CommonForms/BlCommonFieldsForm';
import HlCommonFieldsForm from '../../pages/all-lenders/CommonForms/HlCommonFieldsForm';
import LapCommonFieldsForm from '../../pages/all-lenders/CommonForms/LapCommonFieldsForm';
import { LenderOnlineForm } from '../../pages/all-lenders/LenderOnlineForm/LenderOnlineForm';
import { LeadLenderSpecificForms } from '../../pages/lead-lender-specific-forms/LeadLenderSpecificForms';
import { DefaultPage } from '../../pages/lead-lender-specific-forms/DefaultPage';
import { LeadMigration } from '../../pages/lead-migration/LeadMigration';

import LeadContactCardInsurance from '../../pages/insurance/components/common/custom-cardList/custom-cardList';
import AwaitCustomerApproval from '../../pages/insurance/pages/await_customer_approval/await_customer_approval';
import { IfaInsuranceForm } from '../../pages/insurance/pages/insurance_form/ifa/ifa_insurance_form';
import { DmaCoordInsuranceForm } from '../../pages/insurance/pages/insurance_form/dma_coordinator/dma_insurance_form';
import { DmaApproval } from '../../pages/insurance/pages/dma_approval/dma_approval';
import InsuranceForm from '../../pages/insurance/pages/insurance_form/insurance_form';
import { ApplicationBasicDetails } from '../../pages/insurance/pages/application_basic_details/application_basic_details';
import ShareOtp from '../../pages/insurance/pages/otp/share_otp/share_otp';
import { ManagerSelection } from '../../pages/insurance/pages/manager_selection/manager_selection';
import { FloaterFamily } from '../../pages/insurance/pages/floater/family_floater_main';
import { FamilyFloaterDetails } from '../../pages/insurance/pages/floater/family_floater';
import DocumentDetails from '../../pages/insurance/pages/document_details/document_details';
import { DocumentUploadMain } from '../../pages/insurance/pages/document_details/document_details_main/document_details_main';
import { UpdateApplicationStatus } from '../../pages/insurance/pages/update-status/update_application_status/update_application_status';
import { AllInsurers } from '../../pages/insurance/pages/all-insurers/AllInsurerList';
import { SelectedInsurerList } from '../../pages/insurance/pages/all-insurers/SelecedInsurerList/SelectedInsuranceLendersList';
import { VerifyInsurerWithOtp } from '../../pages/insurance/pages/otp/verify_insurer_with_otp';
import { LeadDetailsProvider } from '../../pages/insurance/context/LeadDetailsContext';
import { ActivityProvider } from '../../pages/insurance/context/ActivityContext';
import LeadDetailsInsurance from '../../pages/insurance/pages/lead_Details/lead_insurance_details';
import LeadAboutInsurance from '../../pages/insurance/pages/lead_Details/LeadAbout';
import getCommentsInsurance from '../../pages/insurance/pages/lead_Details/LeadComments';
import { ActivitiesInsurance } from '../../pages/insurance/pages/lead_Details/insurance_activities';
import MessageNotification from '../../pages/insurance/pages/notification/notification-model';
import { WelcomeStatusCall } from '../../pages/insurance/pages/update-status/welcome_call_status/welcome_call_status';
import CustomInsSuccessfull from '../../pages/insurance/pages/flash-screens/CustomSuccessfull';
import { ShowAlerts } from '../../pages/alerts/showAlerts';
import AlertsMain from '../../pages/alerts/alertsMain';
// import { ABFLBusinessInfoForm } from '../../pages/lead-lender-specific-forms/ABFLForms_BL/ABFLBusinessInfoForm';
import { FilterProvider } from '../../pages/insurance/context/FilterContext';
import InsuranceFilter from '../../pages/insurance/pages/filters/InsuranceFilter';

// insurance Consent form
import { TermsAndConditions } from '../../pages/insurance-consent-form/pages/terms-and-conditions/TermsAndConditions';
import { OTPPage } from '../../pages/insurance-consent-form/pages/otp-page/OTPPage';
import { SuccessPage } from '../../pages/insurance-consent-form/pages/success-page/SuccessPage';
import { ConsentAuthProvider } from '../../pages/insurance-consent-form/context/ConsentAuthContext';
import { ConsentBusyLoadingProvider } from '../../pages/insurance-consent-form/context/ConsentBusyLoadingContext';
import { ConsentBusyLoader } from '../../pages/insurance-consent-form/component/loader/ConsentBusyLoader';
import { ConsentAppProvider } from './ConsentAppProvider';
import ApplicationContext from '../../pages/insurance/context/ApplicationContext';
import { AuthContext } from '../../pages/insurance/context/AuthContext';
import InsurancePolicyDetails from '../../pages/insurance/pages/lead_Details/policy_details';
import { SelectHoldings } from '../../pages/las/selectHoldings';
import { ShowHoldings } from '../../pages/las/showHoldings';

const addListeners = async (ionRouter: any, dispatch: any) => {
	await PushNotifications.addListener('registration', (token) => {
		console.info('DMA APP -- Registration token: ', token.value);
		dispatch(appNotification(token.value));
	});

	await PushNotifications.addListener('registrationError', (err) => {
		console.error('DMA APP -- Registration error: ', err.error);
	});

	await PushNotifications.addListener(
		'pushNotificationReceived',
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		(notification) => {
			// console.log('DMA APP -- Push notification received: ', notification);
		}
	);

	await PushNotifications.addListener(
		'pushNotificationActionPerformed',
		(notification) => {
			// console.log(
			// 	'DMA APP -- Push notification action performed',
			// 	notification
			// );

			ionRouter.push(notification?.notification?.data?.route);
		}
	);
};

const registerNotifications = async () => {
	let permStatus = await PushNotifications.checkPermissions();

	if (permStatus.receive === 'prompt') {
		permStatus = await PushNotifications.requestPermissions();
	}

	if (permStatus.receive !== 'granted') {
		throw new Error('User denied permissions!');
	}

	await PushNotifications.register();
};

export const Routing: React.FC = () => {
	//const [presentAlert, closePresntAlrt] = useIonAlert();

	//const location = useLocation();
	const dispatch: any = useDispatch();
	const ionRouter = useIonRouter();
	const isPushNotificationsAvailable =
		Capacitor.isPluginAvailable('PushNotifications');

	const { applicationBean } = useContext(ApplicationContext);
	const { token, userId } = useContext(AuthContext);
	const applicationId = applicationBean?.applicationId;
	let mobile = applicationBean?.basicDetailsProposer?.mobile_no!;

	function removeBearerPrefixAndStore(value) {
		const prefix = 'Bearer ';
		let cleanedValue = value || ''; // Default value to empty string if value is null

		if (cleanedValue.toLowerCase().startsWith(prefix.toLowerCase())) {
			cleanedValue = cleanedValue.slice(prefix.length);
		}

		return cleanedValue;
	}
	const cleanedToken = removeBearerPrefixAndStore(token);
	// console.log('routing page cleanedToken????????', cleanedToken);
	// console.log(
	// 	'routing page url????????',
	// 	`/term-of-use/${cleanedToken}/${userId}/${applicationId}/${mobile}`
	// );

	// pathname we can get from location object
	// special path if we need to redirect we can get from param
	// we can create function if there are no of operation to perform on back button then we can use that for both hardware and ui back button

	/* 	const backBtnUpdateCount = useSelector((state: any) => state?.app);
	const exitAppFun = () => {
		presentAlert({
			keyboardClose: true,
			header: '',
			subHeader: '',
			message: 'Are you sure want to exit',

			buttons: [
				{
					text: 'cancel',
					role: 'cancel',
					handler: () => {
						closePresntAlrt();
					},
				},
				{
					text: 'confirm',
					role: 'confirm',
					handler: () => {
						CapacitorApp.exitApp();
					},
				},
			],
		});
	}; */

	/* 	const onBackEvent = (ev: any) => {
		ev.detail.register(1, () => {
			ev?.preventDefault();
			ev?.stopPropogation();
		});
	}; */

	useEffect(() => {
		if (isPushNotificationsAvailable) {
			addListeners(ionRouter, dispatch);
			registerNotifications();
		}
		document.addEventListener('ionBackButton', (event) => {
			// CapacitorApp.addListener('backButton', (event) => {
			event.preventDefault();
			event.stopPropagation();
			/* 	if (
				['/dashboard/home', '/login', '/choose-language'].includes(
					window.location.pathname
				)
			) {
				exitAppFun();
				event.preventDefault();
			} else if (
				['/partner-soft-code', '/register-yourself'].includes(
					window.location.pathname
				)
			) {
				// console.log('back wala aaya', event);
				onBackEvent(event);
				// return false;
			} else {
				event?.preventDefault();
				event?.stopPropagation();
				dispatch(backButtonUpdate(backBtnUpdateCount.backBtnCount + 1));
			} */
		});

		if (window.innerWidth > 768) {
			dispatch(webView(true));
		} else {
			dispatch(webView(false));
		}

		return () => {
			App.removeAllListeners();
		};
	}, []);

	return (
		<IonRouterOutlet>
			<Route path='/terms-conditions'>
				<TermsPage />
			</Route>
			<Route path='/privacy-policy'>
				<PrivacyPage />
			</Route>
			<Route path='/getting-started'>
				<GettingStarted />
			</Route>
			<Route path='/choose-language'>
				<ChooseLanguage />
			</Route>
			<Route path='/ocr-test'>
				<OCRTest />
			</Route>
			{/* open routes (if token available then redirect to dashboard) */}
			<Route path='/app-welcome'>
				{/* <OpenRoutes> */}
				<AppWelcome />
				{/* </OpenRoutes> */}
			</Route>
			<Route path='/add-mobile'>
				<OpenRoutes>
					<AddMobile />
				</OpenRoutes>
			</Route>
			<Route path='/otp-verification'>
				<OtpVerification />
			</Route>
			<Route path='/register-yourself'>
				<PrivateRoutes>
					<RegisterYourself />
				</PrivateRoutes>
			</Route>
			<Route path='/customer-consent-processing'>
				<PrivateRoutes>
					<LeadCustomerConsentProcessing />
				</PrivateRoutes>
			</Route>
			<Route path='/add-your-details'>
				<PrivateRoutes>
					<AddYourDetails />
				</PrivateRoutes>
			</Route>
			<Route path='/nimbuss_service/:id/:token'>
				<Nimbuzz />
			</Route>
			<Route path='/customer-service/'>
				<CustomerService />
			</Route>
			<Route path='/add-firm-details'>
				<PrivateRoutes>
					<AddFirmDetails />
				</PrivateRoutes>
			</Route>
			<Route path='/contact-person-details'>
				<PrivateRoutes>
					<ContactPersonDetails />
				</PrivateRoutes>
			</Route>
			<Route path='/partner-soft-code'>
				<PrivateRoutes>
					<PartnerSoftCode />
				</PrivateRoutes>
			</Route>
			<Route path='/grant-permission'>
				<OpenRoutes>
					<GrantPermission />
				</OpenRoutes>
			</Route>
			<Route path='/login'>
				{/* <OpenRoutes> */}
				<Login />
				{/* </OpenRoutes> */}
			</Route>
			{/* private routes (if token not available then redirect to login) */}
			<Route path='/settings'>
				<PrivateRoutes>
					<Settings />
				</PrivateRoutes>
			</Route>
			<Route path='/change-language'>
				<PrivateRoutes>
					<ChooseLanguage />
				</PrivateRoutes>
			</Route>

			<Route path='/id-card'>
				<PrivateRoutes>
					<IdCard />
				</PrivateRoutes>
			</Route>
			<Route path='/my-earning'>
				<PrivateRoutes>
					<MyBusiness />
				</PrivateRoutes>
			</Route>
			<Route path='/earning-details'>
				<PrivateRoutes>
					<EarningDetails />
				</PrivateRoutes>
			</Route>
			<Route path='/filters'>
				<PrivateRoutes>
					<Filters />
				</PrivateRoutes>
			</Route>
			<Route path='/lead-filter'>
				<PrivateRoutes>
					<LeadFilters />
				</PrivateRoutes>
			</Route>
			<Route path='/consultation-filter'>
				<PrivateRoutes>
					<ConsultationFilters />
				</PrivateRoutes>
			</Route>
			<Route path='/dashboard'>
				<PrivateRoutes>
					<Dashboard />
				</PrivateRoutes>
			</Route>
			<Route path='/kyc'>
				<PrivateRoutes>
					<MyKyc />
				</PrivateRoutes>
			</Route>
			<Route path='/agreement'>
				<PrivateRoutes>
					<SignAgreement />
				</PrivateRoutes>
			</Route>
			<Route path='/agreement-sign-type'>
				<PrivateRoutes>
					<AgreementSignType />
				</PrivateRoutes>
			</Route>
			<Route path='/lead-details'>
				<PrivateRoutes>
					<LeadDetails />
				</PrivateRoutes>
			</Route>

			<Route path='/lead-about'>
				<PrivateRoutes>
					<LeadAbout />
				</PrivateRoutes>
			</Route>
			<Route path='/loan-lead-info-steps'>
				<PrivateRoutes>
					<InitiateLoanLead />
				</PrivateRoutes>
			</Route>
			<Route path='/insurance-lead-info-steps'>
				<PrivateRoutes>
					<InsuranceLeadBasicInfo />
				</PrivateRoutes>
			</Route>
			<Route path='/bureau-report'>
				<PrivateRoutes>
					<BureauReport />
				</PrivateRoutes>
			</Route>
			<Route path='/select-lead-product'>
				<PrivateRoutes>
					<SelectLeadProduct />
				</PrivateRoutes>
			</Route>
			<Route path='/bulk-upload-history'>
				<PrivateRoutes>
					<BulkUploadHistory />
				</PrivateRoutes>
			</Route>
			<Route path='/help-desk'>
				<PrivateRoutes>
					<HelpDesk />
				</PrivateRoutes>
			</Route>
			<Route path='/raise-a-complaint'>
				<PrivateRoutes>
					<RaiseComplaint />
				</PrivateRoutes>
			</Route>
			<Route path='/mis-reports'>
				<PrivateRoutes>
					<MisReports />
				</PrivateRoutes>
			</Route>
			<Route>
				<PrivateRoutes>
					<LeadMigration />
				</PrivateRoutes>
			</Route>
			<Route path='/training-sessions'>
				<PrivateRoutes>
					<TrainingSessions />
				</PrivateRoutes>
			</Route>
			<Route path='/application-preview'>
				<PrivateRoutes>
					<ApplicationPreview />
				</PrivateRoutes>
			</Route>
			<Route path='/lenders-list'>
				<PrivateRoutes>
					<AllLenders />
				</PrivateRoutes>
			</Route>
			<Route path='/bank-specific-details'>
				<PrivateRoutes>
					<InitiateLoanLead />
				</PrivateRoutes>
			</Route>

			{/* <Route path='/selected-lenders'> */}
			<Route path='/all-lenders'>
				<PrivateRoutes>
					<SelectedLenders />
				</PrivateRoutes>
			</Route>
			<Route path='/selected-lender-list'>
				<PrivateRoutes>
					<SelectedLendersList />
				</PrivateRoutes>
			</Route>
			<Route path='/bank-visit-site'>
				<PrivateRoutes>
					<BankVisitSite />
				</PrivateRoutes>
			</Route>
			<Route path='/bank-online-form'>
				<PrivateRoutes>
					<BankOnlineForm />
				</PrivateRoutes>
			</Route>
			<Route path='/lender-online-form'>
				<PrivateRoutes>
					<LenderOnlineForm />
				</PrivateRoutes>
			</Route>
			<Route path='/rm-selection-form'>
				<PrivateRoutes>
					<RMSelectionForm />
				</PrivateRoutes>
			</Route>
			<Route path='/pl-common-fields-form'>
				<PrivateRoutes>
					<PlCommonFieldsForm />
				</PrivateRoutes>
			</Route>
			<Route path='/bl-common-fields-form'>
				<PrivateRoutes>
					<BlCommonFieldsForm />
				</PrivateRoutes>
			</Route>
			<Route path='/hl-common-fields-form'>
				<PrivateRoutes>
					<HlCommonFieldsForm />
				</PrivateRoutes>
			</Route>
			<Route path='/lap-common-fields-form'>
				<PrivateRoutes>
					<LapCommonFieldsForm />
				</PrivateRoutes>
			</Route>
			<Route path='/lead-lender-specific-form-steps'>
				<PrivateRoutes>
					<LeadLenderSpecificForms />
				</PrivateRoutes>
			</Route>
			<Route path='/verify-lender-with-otp'>
				<PrivateRoutes>
					<VerifyLenderWithOtp />
				</PrivateRoutes>
			</Route>
			<Route path='/default-page'>
				<PrivateRoutes>
					<DefaultPage />
				</PrivateRoutes>
			</Route>
			<Route path='/lender-specific-info'>
				<PrivateRoutes>
					<LeanderSpecificInfo />
				</PrivateRoutes>
			</Route>
			<Route path='/sanction-and-disbursed-detail'>
				<PrivateRoutes>
					<SanctionAndDisbursedDetail />
				</PrivateRoutes>
			</Route>
			<Route path='/comman-info'>
				<PrivateRoutes>
					<CommanInfo />
				</PrivateRoutes>
			</Route>
			<Route path='/info/:bankname'>
				<PrivateRoutes>
					<BankRelatedInfo />
				</PrivateRoutes>
			</Route>
			<Route path='/professional-info'>
				<PrivateRoutes>
					{/* <ProfessionalInfo /> */}
					<ProfessionalInfoInputs />
				</PrivateRoutes>
			</Route>
			{/* <Route path='/professional-info1'>
				<PrivateRoutes>
					<ProfessionalInfoInputs />
				</PrivateRoutes>
			</Route> */}
			<Route path='/specific-lenders-info'>
				<PrivateRoutes>
					<SpecificLendersInfo />
				</PrivateRoutes>
			</Route>
			<Route path='/successfull'>
				<PrivateRoutes>
					<Successfull />
				</PrivateRoutes>
			</Route>
			<Route path='/custom-successfull'>
				<PrivateRoutes>
					<CustomSuccessfull />
				</PrivateRoutes>
			</Route>
			<Route path='/activity'>
				<PrivateRoutes>
					<Activity />
				</PrivateRoutes>
			</Route>
			<Route path='/lead-list'>
				<PrivateRoutes>
					<LeadList />
				</PrivateRoutes>
			</Route>
			<Route path='/add-lead-disposition'>
				<PrivateRoutes>
					<AddLeadDisposition />
				</PrivateRoutes>
			</Route>
			<Route path='/view-lead-activities'>
				<PrivateRoutes>
					<LeadDisposition />
				</PrivateRoutes>
			</Route>
			<Route path='/view-lead-comments'>
				<PrivateRoutes>
					<LeadComments />
				</PrivateRoutes>
			</Route>
			<Route path='/disbursement-details'>
				<PrivateRoutes>
					<DisbursementDetails />
				</PrivateRoutes>
			</Route>
			<Route path='/disbursement-details-form'>
				<PrivateRoutes>
					<DisbursementDetailsForm />
				</PrivateRoutes>
			</Route>
			<Route path='/hdfc-details'>
				<PrivateRoutes>
					<HdfcDetails />
				</PrivateRoutes>
			</Route>
			<Route path='/update-status'>
				<PrivateRoutes>
					<UpdateStatus />
				</PrivateRoutes>
			</Route>
			<Route path='/dma-activities'>
				<PrivateRoutes>
					<DmaActivity />
				</PrivateRoutes>
			</Route>
			<Route path='/sub-products'>
				<PrivateRoutes>
					<SubProducts />
				</PrivateRoutes>
			</Route>
			<Route path='/product-detail'>
				<PrivateRoutes>
					<ProductDetail />
				</PrivateRoutes>
			</Route>
			<Route path='/product-lenders'>
				<PrivateRoutes>
					<AllProductLenders />
				</PrivateRoutes>
			</Route>
			<Route path='/financial-tools'>
				<PrivateRoutes>
					<Tools />
				</PrivateRoutes>
			</Route>
			<Route path='/lender-product-detail'>
				<PrivateRoutes>
					<BankDetails />
				</PrivateRoutes>
			</Route>
			<Route path='/notifications'>
				<PrivateRoutes>
					<Notification />
				</PrivateRoutes>
			</Route>
			<Route path='/team-dma-list'>
				<PrivateRoutes>
					<TeamsMyDmaList />
				</PrivateRoutes>
			</Route>
			<Route path='/invitee-list'>
				<PrivateRoutes>
					<TeamsDmaInvites />
				</PrivateRoutes>
			</Route>
			<Route path='/team-list'>
				<PrivateRoutes>
					<TeamList />
				</PrivateRoutes>
			</Route>
			<Route path='/dma-details'>
				<PrivateRoutes>
					<DmaDetail />
				</PrivateRoutes>
			</Route>
			<Route path='/dma-about'>
				<PrivateRoutes>
					<DmaAbout />
				</PrivateRoutes>
			</Route>
			<Route path='/payout-details'>
				<PrivateRoutes>
					<PayoutDetail />
				</PrivateRoutes>
			</Route>
			<Route path='/manage-manager-payout'>
				<PrivateRoutes>
					<ManageManagerPayout />
				</PrivateRoutes>
			</Route>
			<Route path='/add-update-manager-payout'>
				<PrivateRoutes>
					<AddUpdateManagerPayout />
				</PrivateRoutes>
			</Route>
			<Route path='/required-update-details'>
				<PrivateRoutes>
					<RequiredUpdateDetail />
				</PrivateRoutes>
			</Route>
			<Route path='/proposed-payout'>
				<PrivateRoutes>
					<ProposedPayout />
				</PrivateRoutes>
			</Route>
			<Route path='/update-details'>
				<PrivateRoutes>
					<UpdateDetail />
				</PrivateRoutes>
			</Route>
			<Route path='/teams-invite-dma-member'>
				<PrivateRoutes>
					<TeamsDmaInviteMember />
				</PrivateRoutes>
			</Route>
			<Route path='/invite-employee'>
				<PrivateRoutes>
					<InviteEmployee />
				</PrivateRoutes>
			</Route>
			<Route path='/invite-employee-form'>
				<PrivateRoutes>
					<InviteEmployeForm />
				</PrivateRoutes>
			</Route>
			<Route path='/invite-dma'>
				<PrivateRoutes>
					<InviteDma />
				</PrivateRoutes>
			</Route>
			<Route path='/add-dma'>
				<PrivateRoutes>
					<AddDma />
				</PrivateRoutes>
			</Route>
			<Route path='/verify-dma'>
				<PrivateRoutes>
					<VerifyDmaWithOtpVerification />
				</PrivateRoutes>
			</Route>
			<Route path='/team'>
				<PrivateRoutes>
					<Team />
				</PrivateRoutes>
			</Route>
			<Route path='/dma-list'>
				<PrivateRoutes>
					<DmaList />
				</PrivateRoutes>
			</Route>
			<Route path='/dma-verify-steps'>
				<PrivateRoutes>
					<DmaVerifySteps />
				</PrivateRoutes>
			</Route>
			<Route path='/dma-verification-status'>
				<PrivateRoutes>
					<DmaVerificationStatus />
				</PrivateRoutes>
			</Route>
			<Route path='/dma-user-details'>
				<PrivateRoutes>
					<DmaUserDetails />
				</PrivateRoutes>
			</Route>
			<Route path='/efranking-initiate'>
				<PrivateRoutes>
					<EfrankingInitiate />
				</PrivateRoutes>
			</Route>
			<Route path='/efranking-send'>
				<PrivateRoutes>
					<EfrankingSend />
				</PrivateRoutes>
			</Route>
			<Route path='/dma-approved-info'>
				<PrivateRoutes>
					<DmaApprovedInfo />
				</PrivateRoutes>
			</Route>
			<Route path='/dma-approved-about'>
				<PrivateRoutes>
					<DmaApprovedAbout />
				</PrivateRoutes>
			</Route>
			<Route path='/dma-approved-payout-details'>
				<PrivateRoutes>
					<DmaApprovedPayoutDetails />
				</PrivateRoutes>
			</Route>
			<Route path='/check-bureau-score'>
				<PrivateRoutes>
					<CheckBureauScore />
				</PrivateRoutes>
			</Route>
			<Route path='/add-bureau-score'>
				<PrivateRoutes>
					<AddBureauDetail />
				</PrivateRoutes>
			</Route>
			<Route path='/verify-otp-bureau-report'>
				<PrivateRoutes>
					<BureauReportOtp />
				</PrivateRoutes>
			</Route>
			<Route path='/view-bureau-report'>
				<PrivateRoutes>
					<ViewBureauReport />
				</PrivateRoutes>
			</Route>
			<Route path='/approve-proposed-payout'>
				<PrivateRoutes>
					<ApproveProposedPayout />
				</PrivateRoutes>
			</Route>
			<Route path='/report-details'>
				<PrivateRoutes>
					<InnerDetails />
				</PrivateRoutes>
			</Route>
			<Route path='/add-invite-lead'>
				<PrivateRoutes>
					<AddInviteLead />
				</PrivateRoutes>
			</Route>
			<Route path='/view-profile'>
				<PrivateRoutes>
					<ViewProfile />
				</PrivateRoutes>
			</Route>
			<Route path='/close-and-disposition-form'>
				<PrivateRoutes>
					<CloseAndDispositionForm />
				</PrivateRoutes>
			</Route>
			{/* <Route path='/emplyee-Detail'>
				<PrivateRoutes>
					<EmployeeDetails />
				</PrivateRoutes>
			</Route> */}
			{/* default route */}
			<Route
				path='/'
				exact
			>
				<Redirect
					exact
					from='/'
					to={'/app-welcome'}
					/* login removed added /dashboard , becoz we r sending forcefully to login without checking user logined in or not. */
				/>
			</Route>
			<Route path='/all-lenders'>
				<PrivateRoutes>
					<HomeLoanLenders />
				</PrivateRoutes>
			</Route>
			<Route path='/lender-loan-types'>
				<PrivateRoutes>
					<LenderLoanList />
				</PrivateRoutes>
			</Route>
			<Route path='/lender-loan-consultants'>
				<PrivateRoutes>
					<LenderLoanConsultants />
				</PrivateRoutes>
			</Route>
			<Route path='/bank-details'>
				<PrivateRoutes>
					<BankDetails />
				</PrivateRoutes>
			</Route>
			{/* <Route path='/abfl-business-form'>
				<PrivateRoutes>
					<ABFLBusinessInfoForm />
				</PrivateRoutes>
			</Route> */}

			<Route path='/showAlertsMain'>
				<PrivateRoutes>
					<AlertsMain />
				</PrivateRoutes>
			</Route>

			<Route path='/showAlerts'>
				<PrivateRoutes>
					<ShowAlerts />
				</PrivateRoutes>
			</Route>

			<Route path='/selectHoldings'>
				<PrivateRoutes>
					<SelectHoldings />
				</PrivateRoutes>
			</Route>

			<Route path='/showHoldings'>
				<PrivateRoutes>
					<ShowHoldings />
				</PrivateRoutes>
			</Route>
			
			

			{/* insurance consent form */}
			<Route
				path={`/term-of-use/:cleanedToken/:userId/:applicationId/:mobile`}
				render={() => (
					<ConsentAppProvider>
						<TermsAndConditions />
					</ConsentAppProvider>
				)}
			/>
			<Route
				path='/otp-page/:cleanedToken/:userId/:applicationId/:mobile'
				render={() => (
					<ConsentAppProvider>
						<OTPPage />
					</ConsentAppProvider>
				)}
			/>
			<Route
				path='/success'
				render={() => (
					<ConsentAppProvider>
						<SuccessPage />
					</ConsentAppProvider>
				)}
			/>
			{/* insurance routes */}

			<Route
				path='/await-customer-approval'
				component={AwaitCustomerApproval}
				exact
			/>

			<Route
				path='/ifainsurance'
				component={IfaInsuranceForm}
				exact
			/>
			<Route
				path='/dmacoordinsurance'
				component={DmaCoordInsuranceForm}
				exact
			/>
			<Route
				path='/dmapproval'
				component={DmaApproval}
				exact
			/>
			<Route
				path='/insurance'
				component={InsuranceForm}
				exact
			/>
			<Route
				path='/application_basic_details'
				component={ApplicationBasicDetails}
				exact
			/>
			<Route
				path='/shareOtp'
				component={ShareOtp}
				exact
			/>
			<Route
				path='/managerSelection'
				component={ManagerSelection}
				exact
			/>
			<Route
				path='/floaterFamily'
				component={FloaterFamily}
				exact
			/>
			<Route
				path='/floaterFamilyInd'
				component={FamilyFloaterDetails}
				exact
			/>
			<Route
				path='/docupload'
				component={DocumentDetails}
				exact
			/>
			<Route
				path='/docuploadMain'
				component={DocumentUploadMain}
				exact
			/>
			<Route
				path='/updatePolicyStatus'
				component={UpdateApplicationStatus}
				exact
			/>
			<Route
				path='/insurerlist'
				component={AllInsurers}
				exact
			/>
			<Route
				path='/insuranceSelectedList'
				component={SelectedInsurerList}
				exact
			/>
			<Route
				path='/verify-insurer-with-otp'
				component={VerifyInsurerWithOtp}
				exact
			/>

			<FilterProvider>
				<LeadDetailsProvider>
					<ActivityProvider>
						<Route
							path='/insurance-lead-list'
							component={LeadContactCardInsurance}
							exact
						/>
						<Route
							path='/insurance-lead-details'
							component={LeadDetailsInsurance}
							exact
						/>
						<Route
							path='/insurance-lead-about'
							component={LeadAboutInsurance}
							exact
						/>
						<Route
							path='/view-policy-details'
							component={InsurancePolicyDetails}
							exact
						/>
						<Route
							path='/insurance-lead-comments'
							component={getCommentsInsurance}
							exact
						/>
						<Route
							path='/insurance_lead_activities'
							component={ActivitiesInsurance}
							exact
						/>
						<Route
							path='/lead-filters'
							component={InsuranceFilter}
							exact={true}
						/>

						<Route
							path='/notification-model'
							component={MessageNotification}
							exact
						/>
						<Route
							path='/welcome-status-call'
							component={WelcomeStatusCall}
							exact
						/>
						<Route
							path='/cust-ins-message'
							component={CustomInsSuccessfull}
							exact
						/>
					</ActivityProvider>
				</LeadDetailsProvider>
			</FilterProvider>
		</IonRouterOutlet>
	);
};
