import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { getSelectedLendersList } from '../../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';

export const OfferRejectedMsg: React.FC<any> = ({ message }) => {
	const { t } = useTranslation();
	const [isRedirectOnDashboard, setToRedirectOnDashboard] = useState(true);
	const history = useHistory<any>();
	const { leadFlowData, lenderListData } = useSelector(
		(state: any) => state.lead
	);

	const dispatch = useDispatch();

	useEffect(() => {
		if (message) {
			getLeadLender();
		}
	}, []);

	const getLeadLender = async () => {
		await dispatch(
			getSelectedLendersList({
				param: '/' + leadFlowData?.leadId,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					response?.data?.forEach((el: any) => {
						if (!el.lender_int_comp) {
							setToRedirectOnDashboard(false);
						}
					});
				}
			});
	};

	const onClickDone = () => {
		isRedirectOnDashboard || lenderListData?.length == 1
			? history.replace('/dashboard/lead')
			: history.replace('/lender-specific-info');
	};

	return (
		<>
			<div className='bg-web pt-md-4 e-franking-initiate-height d-md-flex align-items-md-center justify-content-md-center'>
				<div
					className='border-md-radius container-width-md p-md-3 green-top-linear-gradient d-md-flex align-items-md-center justify-content-md-center e-franking-send-container-height h-100'
					style={{ flex: 'auto' }}
				>
					<div className='text-center flex-column d-flex align-items-center justify-content-center h-100'>
						<h3 className='fs-18 fw-600 darkest-grey'>{message}</h3>
						<CustomButton
							className='fw-bold dashboard-add-lead-button mt-3'
							expand='block'
							title={t('Done')}
							onClick={onClickDone}
						/>
					</div>
				</div>
			</div>
		</>
	);
};
