import { t } from 'i18next';
import { Controller } from 'react-hook-form';
import { CustomInput } from '../../../../components/common/custom-input/CustomInput';
import { DynamicDropdown } from '../../../../components/common';
import { IonButton, IonIcon, IonImg } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { LenderNameForAPICall } from '../../../../helpers/lenders';
import {
	fetchMasterData,
	lenderBranchFetch,
	lenderDocumentUpload,
} from '../../../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { coApplicantFormThree } from './TataHlLAP';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';

export const CoApplicantForm3: React.FC<any> = ({
	control,
	getValues,
	setValue,
	trigger,
	errors,
	isValid,
	reset,
	documentObj,
}) => {
	const [isShowPreview, setToShowPreview] = useState(false);
	const [dmsBranchCode, setDmsBranchCode] = useState([]);
	const [maritalStatus, setMaritalStatus] = useState([]);
	const [residentType, setResidentType] = useState([]);
	const [accomodationType, setAccomodationType] = useState([]);
	const [natureOfBusiness, setNatureOfBusiness] = useState([]);
	const [documentTypes, setDocumentTypes] = useState([]);
	const [industry, setIndustry] = useState([]);
	const coDocUpload: any = useRef([]);
	const dispatch = useDispatch();
	const { selectedLender } = useSelector((state: any) => state?.lead);

	useEffect(() => {
		getDataFromMaster();
	}, []);

	const getDataFromMaster = async () => {
		await dispatch(fetchMasterData({ param: 'TATALAP_MARITAL_STATUS' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setMaritalStatus(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATALAP_RESIDENT_TYPE' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setResidentType(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATALAP_ACCOMODATON_TYPE' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setAccomodationType(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATAHLLAP_NATURE_OF_BUSINESS' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setNatureOfBusiness(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATAHLLAP_INDUSTRY' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setIndustry(result.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATALAP_DOCUMENT_TYPE' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setDocumentTypes(result.data);
				}
			});

		await dispatch(
			lenderBranchFetch({
				data: {
					product: 'HL_LAP',
					city: selectedLender[0]?.rm_branch,
					lender_name: LenderNameForAPICall.TATA_HL_LAP,
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setDmsBranchCode(result?.data);
				}
			});
	};

	/**
	 * Set dropdown data
	 * @param name field name
	 */
	const setDropDownOptions = (name: string) => {
		switch (name) {
			case 'dms_branch_code':
				return dmsBranchCode;
			case 'marital_status':
				return maritalStatus;
			case 'resident_type':
				return residentType;
			case 'accomodation_type':
				return accomodationType;
			case 'nature_of_business':
				return natureOfBusiness;
			case 'industry':
				return industry;
		}
	};

	/**
	 * Upload documents
	 * @param event file upload event
	 * @param key documents array index
	 */
	const uploadDocuments = async (event: any, key: number) => {
		const file = event.target.files[0];
		const fileTypes = ['jpg', 'jpeg', 'png', 'pdf'];
		const fType = file?.type?.split('/')?.reverse()[0];
		if (!fType || !fileTypes?.includes(fType?.toLowerCase())) {
			setToShowPreview(false);
			setValue(
				`documents.${key}.message`,
				'Uploaded file is invalid. Only JPG/JPEG, PNG and PDF files are allowed.'
			);
			return;
		}

		if (file?.size / 1024 > 2048) {
			setToShowPreview(false);
			setValue(`documents.${key}.message`, 'File size should be less than 2MB');
			return;
		}
		setValue(`documents.${key}.message`, '');

		const formData: any = new FormData();
		formData.append('lender_name', LenderNameForAPICall.TATA_HL_LAP);
		formData.append('file', file);

		await dispatch(lenderDocumentUpload({ data: formData }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					const fileName = result.key?.split('/')?.reverse();
					setValue(`documents.${key}.docUploadName`, fileName[0]);
					setValue(`documents.${key}.file`, result?.key);
					setValue(`documents.${key}.fileObj`, file);
					setValue(`documents.${key}.preview`, URL.createObjectURL(file));
				} else {
					setValue(`documents.${key}`, {
						...documentObj,
						docUploadType: getValues(`documents.${key}.docUploadType`),
						message: result.message,
					});
				}
				trigger('documents');
				setToShowPreview(false);
			});
		event.target.value = null;
	};

	useEffect(() => {
		setToShowPreview(true);
	}, [isShowPreview, !isShowPreview]);

	/**
	 * Show selected document preview
	 * @param key document array index
	 */
	const showFilePreview = (key: number) => {
		let previewItem: any;
		const fileObj: any = getValues(`documents.${key}.fileObj`);

		if (fileObj?.type?.includes('image')) {
			previewItem = (
				<>
					<img
						src={getValues(`documents.${key}.preview`)}
						alt='preview'
						className='me-2 file_preview'
						width={'100%'}
					/>
					<p className='filename'>{fileObj.name}</p>
				</>
			);
		} else if (fileObj?.type?.includes('pdf')) {
			previewItem = (
				<>
					<img
						src='/images/pdf.svg'
						alt='preview'
						className='me-2 file_preview'
						width={80}
					/>
					<p className='filename'>{fileObj.name}</p>
				</>
			);
		} else if (getValues(`documents.${key}.message`)) {
			previewItem = (
				<p className='text-red'>{t(getValues(`documents.${key}.message`))}</p>
			);
		}
		return previewItem;
	};

	/**
	 * Add new section to upload document
	 */
	const addNewDocument = () => {
		setValue('documents', [...getValues('documents'), documentObj]);
		reset({
			...getValues(),
		});
	};

	/**
	 * Remove specific item
	 * @param key index
	 */
	const removeDocument = (key: number) => {
		const documents = getValues('documents').filter(
			(ele: any, k: number) => k !== key
		);
		setValue('documents', documents);
		reset({
			...getValues(),
		});
	};

	return (
		<>
			{coApplicantFormThree.map(
				({ name, type, placeholder }: any, index: any) => {
					const errorText: any =
						errors && Object.keys(errors).length > 0 && errors;
					return (
						<div key={index}>
							{type === 'dropdown' ? (
								<div style={{ marginBottom: '15px' }}>
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, name } }) => (
											<DynamicDropdown
												label={t(`${placeholder}`)}
												data={setDropDownOptions(name)}
												name={name}
												onChange={(e: any) => {
													onChange(e);
													if (name === 'nature_of_business') {
														trigger(name);
														setValue('nature_of_business_others', null);
													}
												}}
												bindField={
													name === 'accomodation_type'
														? 'id'
														: name === 'dms_branch_code'
														? 'branch_code'
														: 'value'
												}
												showField1={
													name === 'dms_branch_code' ? 'branch_name' : 'value'
												}
												value={getValues(name)}
												onBlur={onBlur}
												errors={errorText[name]?.message}
											/>
										)}
										name={name}
									/>
								</div>
							) : name === 'date_of_joining' ? (
								<Controller
									control={control}
									render={({ field: { onChange, onBlur, name } }) => (
										<CustomInput
											type={type}
											needSuffixIcon={true}
											suffixClassName={'position-absolute date-of-birth-sufix'}
											suffixText={
												<IonImg
													src='/images/icons/input-dob-calendar-icon.svg'
													alt='dob'
												/>
											}
											outerClassName={'position-relative'}
											label={t(placeholder)}
											onBlur={onBlur}
											id={name}
											onChange={(e: any) => {
												onChange(e);
											}}
											value={getValues(name)}
											errors={t(errorText[name]?.message || '')}
										/>
									)}
									name={name}
								/>
							) : name === 'nature_of_business_others' ? (
								getValues('nature_of_business') === 'Others' && (
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, name } }) => (
											<CustomInput
												type={type}
												label={t(placeholder)}
												onBlur={onBlur}
												id={name}
												onChange={(e: any) => {
													onChange(e);
												}}
												value={getValues(name)}
												errors={t(errorText[name]?.message || '')}
											/>
										)}
										name={name}
									/>
								)
							) : (
								<Controller
									control={control}
									render={({ field: { onChange, onBlur, name } }) => (
										<CustomInput
											type={type}
											label={t(placeholder)}
											onBlur={onBlur}
											id={name}
											onChange={(e: any) => {
												onChange(e);
											}}
											value={getValues(name)}
											errors={t(errorText[name]?.message || '')}
										/>
									)}
									name={name}
								/>
							)}
						</div>
					);
				}
			)}

			<>
				{getValues('documents')?.map((el: any, k: any) => {
					return (
						<div key={k}>
							<Controller
								control={control}
								render={({ field: { onChange, onBlur, name } }) => (
									<DynamicDropdown
										label={t(`Document Type *`)}
										data={documentTypes}
										name={name}
										onChange={(e: any) => {
											onChange(e);
										}}
										bindField={'value'}
										showField1={'value'}
										value={getValues(name)}
										onBlur={onBlur}
										errors={
											errors?.documents &&
											errors?.documents[k]?.docUploadType?.message
										}
									/>
								)}
								name={`documents.${k}.docUploadType`}
							/>

							<div className='mb-4'>
								<CustomButton
									onClick={() => coDocUpload?.current[k]?.click()}
									expand='block'
									fill={'solid'}
								>
									<img
										src='/images/icons/upload-white.svg'
										alt='upload'
										className='me-2'
									/>
									{t('Select File')}
								</CustomButton>

								{/* input file upoad on button click */}
								<input
									type='file'
									style={{ display: 'none' }}
									accept='image/jpg,image/jpeg,image/png,application/pdf'
									ref={(el) => (coDocUpload.current[k] = el)}
									id={k}
									onChange={(event) => uploadDocuments(event, k)}
								/>

								{isShowPreview && (
									<div className='mt-2 mb-2'>{showFilePreview(k)}</div>
								)}
								<span className='text-muted font-small'>
									Acceptable File Format : png, jpeg, jpg, pdf Maximum file size
									limit : 2MB
								</span>
							</div>

							<div className='add_rm_buttons'>
								{k === getValues('documents').length - 1 && (
									<IonButton
										type='button'
										shape='round'
										color='primary'
										disabled={!isValid}
										onClick={() => addNewDocument()}
									>
										<IonIcon src='/images/icons/add-icon.svg' />
									</IonButton>
								)}

								{getValues('documents').length > 1 && (
									<IonButton
										shape='round'
										type='button'
										color='danger'
										onClick={() => removeDocument(k)}
									>
										<IonIcon src='/images/icons/delete.svg' />
									</IonButton>
								)}
							</div>
						</div>
					);
				})}
			</>
		</>
	);
};
