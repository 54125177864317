

import { yupResolver } from '@hookform/resolvers/yup';
import { IonContent, IonGrid, IonPage } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { UploadWidget } from '../../components/common/custom_file_upload/custom_file_upload';
import { Header } from "../../components/layout/header/Header";
import ApplicationContext from '../../context/ApplicationContext';
import { INSURANCE_DOCUMENT_TYPE, INSURANCE_TYPE, REDIRECT } from '../../helpers/constant';
import { DocumentUploadForm } from '../../model/document_bean';
import { t } from 'i18next';
import WarningMessage from '../../components/common/warning/back_refresh_warning';




interface Props {
  title?: string;
  nextTabUrl?: string;
  inpCallback?: () => void;
  profileId?: number;
  applicationId?: number;
  documentUploadFormList?: DocumentUploadForm[];
  readonly: boolean;
  // setShowBasicInfoForm: any;
  // getUserDetailsInfo: any;
}






// const schemaStubMain = {

//   // const schema = yup.object({  
//   passportSizePhoto: yup.object().required('Passport size photo is required'),
//   pan: yup.object().required('Passport size photo is required'),

//   aadharFront: yup.object().required('Passport size photo is required'),
//   aadharBack: yup.object().required('Passport size photo is required'),


//   incomeProof: yup.object().notRequired(),
//   incomeSurrogates: yup.object().notRequired(),


//   cancelledCheque: yup.object().required('Passport size photo is required'),
//   rc: yup.object().required('Passport size photo is required'),

//   drivingLicence: yup.object().required('Passport size photo is required'),
//   existingPolicy: yup.object().notRequired(),

//   others: yup.object().notRequired(),

// };



const DocumentDetails: React.FC<Props> = ({ title, nextTabUrl, profileId, applicationId, documentUploadFormList, inpCallback, readonly = false }) => {
  const history = useHistory();



  const { applicationBean } = useContext(ApplicationContext);


  

  const [isRequired, setIsRequired] = useState({
    passportSizePhoto: true,
    pan: true,
    aadharFront: true,
    aadharBack: true,
    cancelledCheque: true,
    rc: true,
    drivingLicence: true,
    // existingPolicy: false,
    existingPolicy: true,
  });

  const [documentListFormKeys, setDocumentListFormKeys] = useState([
    {
      documentTypeId: INSURANCE_DOCUMENT_TYPE.PASSPORT_SIZE_PHOTO,
      title: "Passport size photo",
      value: '',
      key: 'passportSizePhoto',
      mandatory: isRequired.passportSizePhoto,
    },
    {
      documentTypeId: INSURANCE_DOCUMENT_TYPE.PAN,
      title: "Pan",
      key: 'pan',
      value: '',
      mandatory: isRequired.pan,
    },
    {
      documentTypeId: INSURANCE_DOCUMENT_TYPE.AADHAR_FRONT,
      title: "Aadhar Front",
      key: 'aadharFront',
      value: '',
      mandatory: isRequired.aadharFront,
    },
    {
      documentTypeId: INSURANCE_DOCUMENT_TYPE.AADHAR_BACK,
      title: "Aadhar Back",
      key: 'aadharBack',
      value: '',
      mandatory: isRequired.aadharBack,
    },
    {
      documentTypeId: INSURANCE_DOCUMENT_TYPE.INCOME_PROOF,
      title: 'Income proof',
      subTitle: 'ITR, Form 16, 3 month Salary slips',
      key: 'incomeProof',
      value: '',
      mandatory: false,
    },
    {
      documentTypeId: INSURANCE_DOCUMENT_TYPE.INCOME_SURROGATES,
      title: '6 month Bank Statement',
      key: 'incomeSurrogates',
      value: '',
      mandatory: false,
    },
    {
      documentTypeId: INSURANCE_DOCUMENT_TYPE.CANCELLED_CHEQUE,
      title: 'Copy of cancelled cheque',
      key: 'cancelledCheque',
      value: '',
      mandatory: isRequired.cancelledCheque,
    },
    {
      documentTypeId: INSURANCE_DOCUMENT_TYPE.RC,
      title: "RC",
      key: 'rc',
      value: '',
      mandatory: isRequired.rc,
    },
    {
      documentTypeId: INSURANCE_DOCUMENT_TYPE.DRIVING_LICENCE,
      title: "Driving Licence",
      key: 'drivingLicence',
      value: '',
      mandatory: isRequired.drivingLicence,
    },
    {
      documentTypeId: INSURANCE_DOCUMENT_TYPE.EXISTING_POLICY,
      title: "Existing Policy",
      key: 'existingPolicy',
      value: '',
      mandatory: isRequired.existingPolicy,
    },
    {
      documentTypeId: INSURANCE_DOCUMENT_TYPE.OTHERS,
      title: "Others",
      key: 'others',
      value: '',
      mandatory: false,
    },
  ]);



  // useEffect(() => {
  //   return () => {
  //     window.removeEventListener('beforeunload', (e) => e.preventDefault());
  //     window.removeEventListener('keydown', (e) => {
  //       if ((e.ctrlKey && e.key === 'r') || e.key === 'F5' || (e.metaKey && e.key === 'r')) {
  //         e.preventDefault();
  //       }
  //     });
  //   };
  // }, []);

  const schema = yup.object({
    passportSizePhoto: isRequired.passportSizePhoto
      ? yup
          .object()
          .test(
            'content',
            'Passport size photo is required',
            value =>
              value &&
              (value as any).content !== null &&
              (value as any).content !== undefined &&
              (value as any).content !== ''
          )
      : yup.object(),
    pan: isRequired.pan
      ? yup
          .object()
          .test(
            'content',
            'Pan is required',
            value =>
              value &&
              (value as any).content !== null &&
              (value as any).content !== undefined &&
              (value as any).content !== ''
          )
      : yup.object(),
    aadharFront: isRequired.aadharFront
      ? yup
          .object()
          .test(
            'content',
            'Aadhar Front is required',
            value =>
              value &&
              (value as any).content !== null &&
              (value as any).content !== undefined &&
              (value as any).content !== ''
          )
      : yup.object(),
    aadharBack: isRequired.aadharBack
      ? yup
          .object()
          .test(
            'content',
            'Aadhar Back is required',
            value =>
              value &&
              (value as any).content !== null &&
              (value as any).content !== undefined &&
              (value as any).content !== ''
          )
      : yup.object(),
    incomeProof: yup.object().notRequired(),
    incomeSurrogates: yup.object().notRequired(),
    cancelledCheque: isRequired.cancelledCheque
      ? yup
          .object()
          .test(
            'content',
            'Copy of cancelled cheque is required',
            value =>
              value &&
              (value as any).content !== null &&
              (value as any).content !== undefined &&
              (value as any).content !== ''
          )
      : yup.object(),
    rc: isRequired.rc
      ? yup
          .object()
          .test(
            'content',
            'RC is required',
            value =>
              value &&
              (value as any).content !== null &&
              (value as any).content !== undefined &&
              (value as any).content !== ''
          )
      : yup.object(),
    drivingLicence: isRequired.drivingLicence
      ? yup
          .object()
          .test(
            'content',
            'Driving Licence is required',
            value =>
              value &&
              (value as any).content !== null &&
              (value as any).content !== undefined &&
              (value as any).content !== ''
          )
      : yup.object(),
    existingPolicy: isRequired.existingPolicy
      ? yup
          .object()
          .test(
            'content',
            'Existing Policy is required',
            value =>
              value &&
              (value as any).content !== null &&
              (value as any).content !== undefined &&
              (value as any).content !== ''
          )
      : yup.object(),
    others: yup.object().notRequired(),
  });
  

  const [refreshKey, setRefreshKey] = useState(0); // new state to trigger re-renders




  // console.log("hbs stubDoc documentUploadFormList",documentUploadFormList);





  const onSubmit = (data: any) => {






    if (inpCallback) {
      inpCallback();
    }

    if (nextTabUrl) {
      history.push(nextTabUrl);
    }


  };





  const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {

    var filteredFormKeys = documentListFormKeys;
    var docList: number[] = [];

    if ([INSURANCE_TYPE.LIFE_ENDOWMENT, INSURANCE_TYPE.LIFE_GUARNTEED_RETURN,
    INSURANCE_TYPE.LIFE_MONEYBACK, INSURANCE_TYPE.LIFE_PENSION,
    INSURANCE_TYPE.LIFE_TERM_LIFE_INSURANCE, INSURANCE_TYPE.LIFE_UNIT_LINKED].includes(applicationBean?.insuranceTypeId!)) {
      docList = [
        INSURANCE_DOCUMENT_TYPE.PASSPORT_SIZE_PHOTO,
        INSURANCE_DOCUMENT_TYPE.PAN,
        INSURANCE_DOCUMENT_TYPE.AADHAR_FRONT,
        INSURANCE_DOCUMENT_TYPE.AADHAR_BACK,
        INSURANCE_DOCUMENT_TYPE.INCOME_PROOF,
        INSURANCE_DOCUMENT_TYPE.INCOME_SURROGATES,
        INSURANCE_DOCUMENT_TYPE.CANCELLED_CHEQUE,
        INSURANCE_DOCUMENT_TYPE.OTHERS,
      ]
      setIsRequired({
        passportSizePhoto: true,
        pan: true,
        aadharFront: true,
        aadharBack: true,
        cancelledCheque: true,
        rc: false,
        drivingLicence: false,
        existingPolicy: false,
      });
    }


    else if ([INSURANCE_TYPE.HEALTH_FAMILY, INSURANCE_TYPE.HEALTH_INDIVIDUAL].includes(applicationBean?.insuranceTypeId!)) {
      docList = [
        INSURANCE_DOCUMENT_TYPE.PASSPORT_SIZE_PHOTO,
        INSURANCE_DOCUMENT_TYPE.PAN,
        INSURANCE_DOCUMENT_TYPE.AADHAR_FRONT,
        INSURANCE_DOCUMENT_TYPE.AADHAR_BACK,
        INSURANCE_DOCUMENT_TYPE.OTHERS,
      ]


      setIsRequired({
        passportSizePhoto: true,
        pan: true,
        aadharFront: true,
        aadharBack: true,
        cancelledCheque: false,
        rc: false,
        drivingLicence: false,
        existingPolicy: false,
      });

    }
    else if (applicationBean?.insuranceTypeId === INSURANCE_TYPE.GENERAL_VEHICLE) {
      docList = [
        INSURANCE_DOCUMENT_TYPE.PASSPORT_SIZE_PHOTO,
        INSURANCE_DOCUMENT_TYPE.PAN,
        INSURANCE_DOCUMENT_TYPE.AADHAR_FRONT,
        INSURANCE_DOCUMENT_TYPE.AADHAR_BACK,
        INSURANCE_DOCUMENT_TYPE.RC,
        INSURANCE_DOCUMENT_TYPE.DRIVING_LICENCE,
        INSURANCE_DOCUMENT_TYPE.EXISTING_POLICY,
        INSURANCE_DOCUMENT_TYPE.OTHERS,
      ]
      
      setIsRequired({
        passportSizePhoto: true,
        pan: true,
        aadharFront: true,
        aadharBack: true,
        cancelledCheque: false,
        rc: true,
        drivingLicence: true,
        existingPolicy: true,
      });

    }

    //hbs remove this
    // else{
    //     docList = [
    //       INSURANCE_DOCUMENT_TYPE.PASSPORT_SIZE_PHOTO,
    //       INSURANCE_DOCUMENT_TYPE.PAN,
    //       INSURANCE_DOCUMENT_TYPE.AADHAR_FRONT,
    //       INSURANCE_DOCUMENT_TYPE.AADHAR_BACK,
    //       // INSURANCE_DOCUMENT_TYPE.RC,
    //       // INSURANCE_DOCUMENT_TYPE.DRIVING_LICENCE,
    //       INSURANCE_DOCUMENT_TYPE.EXISTING_POLICY,
    //       INSURANCE_DOCUMENT_TYPE.OTHERS,
    //     ]

    // }
    filteredFormKeys = documentListFormKeys.filter(item => docList.includes(item.documentTypeId));
    // documentListFormKeys = filteredFormKeys;

    setDocumentListFormKeys(filteredFormKeys);
    // documentListFormKeys = filteredFormKeys;

    documentListFormKeys.map((item)=>{
      if(item.mandatory){
        if(!item.title.includes('*')){
          item.title = item.title+"*";
        }
        
      }
    });
  }, []);






  useEffect(
    () => {
      // const timeoutId = setTimeout(() => {
      if (documentUploadFormList) {
        documentUploadFormList.map((stubDoc) => {
          console.log("hbs stubDoc documentUploadFormList", stubDoc);


          let stubFormKey = documentListFormKeys.filter(item => ((item.documentTypeId === stubDoc.documentTypeId) && (stubDoc.profileId === profileId)));

          if (stubFormKey) {
            const docKey: string = stubFormKey[0].key;

            setValue(docKey, stubDoc);

            console.log("hbs getVal ",getValues());

          }
          else {
            // stubDoc = new DocumentUploadForm();
            // stubDoc.applicationId = applicationId!;
            // stubDoc.profileId = profileId!;
            // stubDoc.documentTypeId = documentTypeId;
          }
        });
      }


      // documentListFormKeys.map(
      //   (item)=>{

      //     console.log("hbs getVal  init",getValues(item.key));

      //     if(getValues(item.key)===undefined){
      //       let stubDoc: DocumentUploadForm;
      //       stubDoc = new DocumentUploadForm();
      //       stubDoc.applicationId = applicationId!;
      //       stubDoc.profileId = profileId!;
      //       stubDoc.documentTypeId = item.documentTypeId;



      //       setValue(item.key,stubDoc);



      //     }

      //   }
      // ); 
      setRefreshKey(k => k + 1); // increment to trigger re-render



    }
    // , 2000);
    // return () => {
    //   clearTimeout(timeoutId);
    // };
    // }
    , [documentUploadFormList,setValue,getValues]
    // , [documentUploadFormList, refreshKey]
  );




  // console.error(errors);

  // const schemaStub: Record<string, any> = {};











  // const { handleSubmit, formState: { errors } } = useForm({
  //   resolver: yupResolver(schema)
  // });




  return (
    <IonPage className="bg-web-white container-width-md container-spacing-md border-md-radius-top">
      <Header
        className="card-header"
        name={t(title ?? "Document Details")}
        backButton={true}
        needArrow={true}
        needArrowOnClick={() => {
          // history.push(REDIRECT.DASHBOARD);
          window.location.href = REDIRECT.DASHBOARD;
        }}
      />


      {/* <section
        className='overflowX-hidden pt-1'
        style={{ height: 'calc(100vh - 20px)' }}
      > */}
      <IonContent className="ion-padding-bottom" key={refreshKey}>
      <WarningMessage />
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonGrid>
              {documentListFormKeys.map(
                ({ title, subTitle, documentTypeId, key, mandatory }: any, index) => {



                  // console.log("hbs build again");

                  let stubDoc: DocumentUploadForm;

                  stubDoc = getValues[key];
                  console.log("hbs stubDoc ", stubDoc, key);

                  if (stubDoc === undefined || stubDoc === null) {
                    stubDoc = new DocumentUploadForm();
                    stubDoc.applicationId = applicationId!;
                    stubDoc.profileId = profileId!;
                    stubDoc.documentTypeId = documentTypeId;

                  }

                  console.log("hbs stubDoc fin", stubDoc);



                  return <UploadWidget
                    key={key}
                    getValuesFunc={getValues}
                    setValueFunc={setValue}
                    title={title}
                    value={getValues[key]}
                    applicationId={applicationId}
                    documentTypeId={documentTypeId}
                    profileId={profileId}
                    subTitle={subTitle}
                    allowVideo={false}
                    error={errors[key] as any}
                    name={key}
                    mandatory={mandatory} />
                }

              )}

            </IonGrid>
            <div
              className="position-fixed w-100 bottom-0 start-0 px-3 pb-3"
              style={{ backgroundColor: "#fff" }}
            >
              <CustomButton
                className='btn-blue'
                type='submit'
                expand='block'
                title='Update'
                onClick={() => { 
                  console.log(errors)
                  // handleSubmit(onSubmit); 
                }}
              // disabled={!isValid}
              // title={t('Update')}
              // disabled={!isValid}
              />
            </div>
          </form>
        </div>
        {/* </section> */}
      </IonContent>
    </IonPage>
  );
};

export default DocumentDetails;
