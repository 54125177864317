import { IonChip, IonContent, IonItem, IonLabel, IonList, IonPage, IonText } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router';
import { Header } from "../../components/layout/header/Header";
import {
  showToastAlert,
  setBankIcon,
} from "../../helpers/functions";
// import { CustomButton } from "../../components/common/custom-button/CustomButton";
import useApi from "../../api/useApi";
import { GET_INSURANCE_POLICY_DISPLAY, INSURANCE_FETCH_APPLICATION_DETAILS } from "../../helpers/api-endpoints";
import { useBusyLoading } from "../../context/BusyLoadingContext";
import { useLocation } from "react-router";
import { CustomButton } from "../../components/common/custom-button/CustomButton";
import {
  lenderApplicationType,
} from "../../helpers/constant";




export interface PolicyDetailsProps {
  inpItem?: any;
  inpPolicyId?: number;
}



const InsurancePolicyDetails: React.FC<PolicyDetailsProps> = ({ inpItem, inpPolicyId }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [policyId, setPolicyId] = useState(inpPolicyId);
  const [item, setItem] = useState<any>();
  const { setBusyState } = useBusyLoading();
  const [showLoading, setshowLoading] = useState<boolean>(true);
  const history = useHistory();

  const { responseData, loading, executeRequest, error } = useApi<any>(`${GET_INSURANCE_POLICY_DISPLAY}`);

  const [dataFields, setDataFields] = useState<any>();


  useEffect(() => {
    setBusyState(loading);
  }, [loading]);


  useEffect(() => {
    setshowLoading(false);
    showToastAlert({ type: "error", message: error?.message! });
  }, [error]);






  useEffect(() => {
    if (location.pathname === '/view-policy-details') {
      const state = location.state as any;
      const policyDetailsProps = state?.policyDetailsProps || {};
      if (inpItem === undefined) {
        setItem(policyDetailsProps.inpItem);
      }
      if (policyId === undefined) {
        setPolicyId(policyDetailsProps.inpPolicyId);
      }

      console.log("policyDetailsProps", policyDetailsProps);

      if (policyId !== undefined) {
        const payload = {
          policyId: policyId
          // policyId: 279
        }
        executeRequest("post", payload);
      }
    }
  }, [location, inpItem, policyId]);


  // useEffect(() => {
  //     if (policyId !== undefined) {
  //       const payload = {
  //         // policyId: policyId
  //         policyId: 279

  //       }
  //       executeRequest("post", payload);
  //   }
  // }, [policyId]);

  useEffect(() => {
    if (responseData?.data) {


      let stub = Object.entries(responseData?.data).map(([key, value]) => ({ [key]: value !== null && value !== undefined && value !== "" ? value : "N/A" }));

      setDataFields(stub);

      console.log("datafields", responseData.data);
    }
  }, [responseData]);

  return (
    <IonPage className="ion-scroll">
      <Header
        className={"shadow-sm"}
        type=""
        name={`Policy Details`}
        needArrow={true}
        backButton={true}
        style={{ borderBottom: "1px solid #00000029" }}
      // needArrowOnClick={() => {
      // 	leadData?.from === 'nimbuss'
      // 		? history.push('/lead-details/' + leadData.leadId + '/' + token)
      // 		: history.push('/lead-details');
      // }}
      />
      <IonContent className="ion-padding background-web">
        <div className="bg-web">
          <div className="bg-web-white container-width-md px-md-3 border-md-radius-top">
            {/* <IonItem className="your-accounts"> */}
            {item && (
              <>
                <div className="d-flex align-items-center justify-content-between" style={{ padding: '10px', borderRadius: '8px', backgroundColor: '#FFFFFF' }}>
                  <div className="d-flex align-items-center">
                    <div
                      className="me-2 d-flex"
                      style={{
                        width: "48px",
                        height: "48px",
                        borderRadius: "50%",
                        alignSelf: "start",
                        margin: "5px",
                      }}
                    >
                      <img
                        style={{
                          minWidth: "48px",
                          minHeight: "48px",
                        }}
                        className="selected-lender-bank-icon m-0"
                        src={setBankIcon(item?.icon)}
                        alt="..."
                      />
                    </div>

                    <IonLabel className="ion-text-wrap">
                      <h5 className="fs-16 fw-600">{item?.lender_name}</h5>
                      <p style={{ color: "#5D5E72" }} className="fs-12 mb-0 fw-600">
                        {t("Policy ID No.")}#A{item.id}
                      </p>
                      {item?.bank_app_no && (
                        <p style={{ color: "#5D5E72" }} className="fs-14 mb-0">
                          {t("Insurer App. No.")} :{" "}
                          <span className={`fw-600`}>{item?.insurer_app_no}</span>
                        </p>
                      )}
                    </IonLabel>
                  </div>

                  <IonChip
                    disabled={item.app_off_status?.toLowerCase() !== "online"}
                    onClick={() => {
                      // Implement your onClick logic here
                    }}
                    className={`fs-12 ${item.app_off_status?.toLowerCase() === "online" ? "chip-online" : "chip-offline"}`}
                    style={{
                      padding: "8px 8px 8px",
                      height: "23px",
                      opacity: 1,
                    }}
                  >
                    {item.app_off_status?.toLowerCase() === "online" ? lenderApplicationType.ONLINE : lenderApplicationType.OFFLINE}
                  </IonChip>
                </div>






                {dataFields &&
                  dataFields.map((field: any, index: number) => {
                    const fieldName = Object.keys(field)[0];
                    const fieldValue = field[fieldName];
                    if (fieldValue !== null && fieldValue !== undefined && fieldValue !== "")
                      return (
                        <IonItem key={index}>
                          <IonLabel>{fieldName}</IonLabel>
                          <IonText style={{ textAlign: "left", paddingLeft: "8px" }}>
                            {fieldValue || "-"}
                          </IonText>
                        </IonItem>
                      );
                  })}

                <CustomButton
                  className='w-100'
                  fill='outline'
                  routerLink='/lead-details'
                  title={t('Go to Details')}
                  onClick={() => {
                    history.goBack();
                  }}
                />
              </>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default InsurancePolicyDetails;
