import { IonContent, IonFooter } from '@ionic/react';
import { useState } from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { CustomNoData } from '../../../components/common/custom-no-data/CustomNoData';
import { CustomModalLeadStatus } from '../../../components/common';
import { InCredAppLink } from './InCredInfo';
import './InCredForm.scss';

export const InCredOfferInfoForm: React.FC<any> = () => {
	const [leadStatusModalOpen, setLeadStatusModalOpen] = useState(false);
	const { lenderSpecificDetails } = useSelector((state: any) => state?.lead);

	/**
	 * On close of lead status modal redirect to external URL
	 */
	const onLeadStatusModalClose = () => {
		setLeadStatusModalOpen(false);
	};

	return (
		<>
			<IonContent>
				<div className='select-product small-container bg-web'>
					<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
						<h4 className='mt-3 mt-md-2'>OFFERS</h4>
						{lenderSpecificDetails?.offers?.length > 0 ? (
							<>
								{lenderSpecificDetails?.offers.map((el: any, k: number) => {
									return (
										<div
											key={k}
											className='incred_offer'
										>
											<p>
												{t('Loan Type')}: {el.TYPE}
											</p>
											<p>
												{t('Processing Fee')}: {el.PROCESSING_FEE}%
											</p>
											<p>
												{t('Loan Max Tenure')}: {el.LOAN_MAX_TENURE}{' '}
												{t('Months')}
											</p>
											<p>
												{t('Loan Amount')}: Upto {el.LOAN_AMOUNT} *
											</p>
											<p className='mb-0'>
												{t('Loan Rate')}: {el.LOAN_RATE}%
											</p>
										</div>
									);
								})}
							</>
						) : (
							<div
								className='d-flex justify-content-center'
								style={{ height: '60vh' }}
							>
								<CustomNoData
									className='py-3 d-flex flex-column align-items-center justify-content-center'
									message={t('No Data Found')}
								/>
							</div>
						)}
						<div className={`d-none d-md-block mt-4`}>
							<CustomButton
								className='w-100 fw-bold dashboard-add-lead-button mb-3'
								expand='block'
								fill='outline'
								title={t('Close')}
								onClick={() => {
									setLeadStatusModalOpen(true);
								}}
							/>
						</div>
					</div>
				</div>
			</IonContent>

			{leadStatusModalOpen && (
				<CustomModalLeadStatus
					isOpen={leadStatusModalOpen}
					applicationNo={lenderSpecificDetails?.andro_id}
					extraText='Click on below link to get InCred App'
					link={InCredAppLink}
					handleClose={() => onLeadStatusModalClose()}
				/>
			)}

			<IonFooter className='d-block d-md-none position-relative'>
				<div
					style={{
						backgroundColor: '#fff',
						zIndex: 2,
					}}
				>
					<div className={`ion-padding`}>
						<CustomButton
							className='w-100 fw-bold dashboard-add-lead-button mb-3'
							expand='block'
							fill='outline'
							title={t('Close')}
							onClick={() => setLeadStatusModalOpen(true)}
						/>
					</div>
				</div>
			</IonFooter>
		</>
	);
};
