/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonCheckbox } from '@ionic/react';
import React from 'react';
import './CustomCheckbox.scss';
import { useTranslation } from 'react-i18next';

interface ICustomCheckbox {
	children?: any;
	name?: any;
	onChange?: any;
	value?: any;
	labelPlacement?: any;
	className?: any;
	onBlur?: any;
	onFocus?: any;
	indeterminate?: any;
	isChecked?: any;
	ref?: any;
	disabled?: boolean;
	errorText?: any;
	isBackGround?: any;
	errorTextClass?: any;
}
export const CustomCheckbox: React.FC<ICustomCheckbox> = ({
	children,
	name,
	onChange,
	value,
	labelPlacement,
	className,
	onBlur,
	onFocus,
	indeterminate,
	isChecked,
	ref,
	disabled,
	errorText,
	isBackGround,
	errorTextClass,
}) => {
	const { t } = useTranslation();
	return (
		<div
			style={isBackGround ? { background: '#EDF8EC' } : {}}
			className='term'
		>
			<IonCheckbox
				ref={ref}
				name={name}
				onIonChange={onChange}
				value={value}
				labelPlacement={labelPlacement}
				className={className}
				onIonBlur={onBlur}
				onIonFocus={onFocus}
				indeterminate={indeterminate}
				defaultChecked={false}
				checked={isChecked}
				disabled={disabled}
				placeholder=''
				mode='md'
			>
				{children}
			</IonCheckbox>
			{errorText && (
				<span
					className={`error-text d-flex mr-2 ${errorTextClass}`}
					style={name === 'acceptTerms' ? { left: '16px' } : {}}
				>
					{t(`${errorText}`)}
				</span>
			)}
		</div>
	);
};
