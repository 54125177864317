import React, { useEffect, useState } from 'react';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import { useTranslation } from 'react-i18next';
import {
	getPictureDataByOcr,
	showToastAlert,
} from '../../../helpers/functions';
import { IonImg, IonItem, IonLabel, IonList } from '@ionic/react';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { REGEX } from '../../../helpers/constant';
import CustomDropDown from '../../../components/common/custom-dropdown/CustomDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { invitereferral } from '../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';

export const Individual: React.FC<{
	setValue: any;
	setEnableButton: any;
	setMangerId: any;
	isInvited: any;
}> = ({ setValue: setText, setEnableButton, setMangerId, isInvited }) => {
	const { t } = useTranslation();
	const dmaSelector = useSelector((state: any) => state?.users?.dmaFlow);
	const [openUploadModal, setOpenUploadModal] = useState(false);
	const [referalData, setReferralData] = useState([]);
	const userData: any = useSelector((state: any) => state?.users);
	const dispatch: any = useDispatch();
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	const individualSchema = yup.object().shape({
		pan_no: yup
			.string()
			.required('PAN No. is Required.')
			.trim()
			.matches(
				REGEX.PAN,
				"PAN no. should be in alphanumeric format 'ABCDE1234A' only"
			),
		managerId:
			isInvited && !dmaSelector?.dmaId
				? yup.string().required('Manager is Required.')
				: yup.string().notRequired(),
	});
	const {
		control,
		setValue,
		trigger,
		getValues,
		reset,
		formState: { errors, isValid },
	}: any = useForm({
		resolver: yupResolver(individualSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			pan_no: '',
			managerId: '',
		},
	});

	useEffect(() => {
		setEnableButton(!isValid);
		setText(getValues('pan_no'));
	}, [isValid]);

	useEffect(() => {
		const referrals =
			referalData &&
			referalData
				?.filter(({ checked }: any) => checked && checked)
				.map(({ id }: any) => id);
		referrals.length && setMangerId(referrals[0]);
	}, [referalData]);

	useEffect(() => {
		fetchInviteReferral();
	}, []);

	const isDmaPage = location.pathname === '/add-dma';

	useEffect(() => {
		if (isDmaPage) {
			reset({
				pan_no: '',
				managerId: '',
			});
		}
	}, [isDmaPage]);

	const ShareModal = () => {
		const handleUploadChange = async (type: string) => {
			let image: any;
			let getOcrData: any;
			switch (type) {
				case 'photo':
					image = await Camera.getPhoto({
						quality: 50,
						allowEditing: false,
						resultType: CameraResultType.Uri,
						source: CameraSource.Photos,
					});
					getOcrData = await getPictureDataByOcr(
						'imageWithData',
						image.path,
						image.webPath,
						'pan'
					);
					if (
						!['not found', 'notfound'].includes(
							getOcrData.processedData.toLowerCase()
						)
					) {
						trigger('pan_no');
						setValue('pan_no', getOcrData.processedData);

						setOpenUploadModal(false);
					} else {
						trigger('pan_no');
						setValue('pan_no', '');
						setOpenUploadModal(false);
						showToastAlert({ type: 'error', message: 'Please Retry' });
					}
					break;
				case 'camera':
					image = await Camera.getPhoto({
						quality: 50,
						allowEditing: false,
						resultType: CameraResultType.Uri,
						source: CameraSource.Camera,
					});
					setOpenUploadModal(false);
					getOcrData = await getPictureDataByOcr(
						'imageWithData',
						image.path,
						image.webPath,
						'pan'
					);
					if (
						!['not found', 'notfound'].includes(
							getOcrData.processedData.toLowerCase()
						)
					) {
						setValue('pan_no', getOcrData.processedData);
						trigger('pan_no');
					} else {
						setValue('pan_no', '');
						trigger('pan_no');
						showToastAlert({ type: 'error', message: 'Please Retry' });
					}
					break;
				default:
					break;
			}
		};
		return (
			<>
				<IonList>
					<IonItem lines='full'>
						<img
							src='/images/icons/kyc/Image-upload.svg'
							alt='Upload Photo'
						/>
						<IonLabel>
							<h2
								className='fs-16 ps-3 text-dark'
								onClick={() => {
									handleUploadChange('photo');
								}}
							>
								Upload Photo
							</h2>
						</IonLabel>
					</IonItem>
					<IonItem lines='full'>
						<img
							src='/images/icons/kyc/camera-icon.svg'
							alt='Upload Photo'
						/>
						<IonLabel>
							<h2
								className='fs-16 ps-3 text-dark'
								onClick={() => {
									handleUploadChange('camera');
								}}
							>
								Take Photo
							</h2>
						</IonLabel>
					</IonItem>
				</IonList>
			</>
		);
	};

	const fetchInviteReferral = async () => {
		await dispatch(
			invitereferral({ param: '?mobile=' + userData?.user_details?.mobile })
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setReferralData(response?.data);
				}
			});
	};
	useEffect(() => {
		setEnableButton(!isValid);
		if (!getValues('managerId')) {
			setValue('managerId', '');
		} else {
			setValue('managerId', getValues('managerId'));
		}
	}, [isValid, getValues('managerId')]);
	return (
		<>
			<div className='individual'>
				<h3 className='darkest-grey fs-16 mt-4'>
					{dmaSelector?.dmaId ? t('Enter DMA PAN Number') : t('Add Your PAN')}
					{/* {!dmaSelector?.dmaFlow?.dmaId && <br />} {t('PAN Number')} */}
				</h3>
				<p className='fs-14 text-grey'>
					{t('Enter PAN linked with Mobile')} +91 XXXXXX
					{dmaSelector?.dmaId
						? String(dmaSelector?.dmaMobile)?.slice(6)
						: String(userData?.user?.mobile)?.slice(6)}
				</p>
			</div>
			<div className='position-relative'>
				<form>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, name } }) => (
							<CustomInput
								placeholder={t('Enter PAN') + ' *'}
								value={getValues(name)}
								onChange={onChange}
								onBlur={onBlur}
								errors={t(errors[name]?.message)}
								needSuffixIcon={true}
								className='text-uppercase'
								suffixClassName={'position-absolute top-21 end-12'}
								suffixText={
									isWeb ? (
										''
									) : (
										<IonImg
											onClick={() => {
												setOpenUploadModal(true);
											}}
											src='/images/icons/input-scan-icon.svg'
										/>
									)
								}
							/>
						)}
						name='pan_no'
					/>
				</form>
				{isInvited && !dmaSelector?.dmaId && (
					<>
						<CustomDropDown
							{...{
								isCheckbox: false,
								data: referalData,
								trigger: () => trigger(),
								setValue1: () => {
									setValue('managerId', '');
									trigger();
								},
								setData: (data: any) => {
									setReferralData(data);
									setValue('managerId', data?.[0]?.id);
								},
								title: 'Select Referral Manager',
								isSearch: true,
								placeholder: 'Select Referral Manager',
								modalTitle: 'Select Referral Manager',
							}}
						/>
						{errors?.managerId?.message ? (
							<div className='text-danger fs-10 mt-1 ms-3'>
								{errors?.managerId?.message}
							</div>
						) : (
							''
						)}
					</>
				)}

				<CustomModal
					id='sharemodal'
					isOpen={openUploadModal}
					initialBreakpoint={isWeb ? 1 : 0.2}
					handleClose={() => setOpenUploadModal(false)}
					breakpoints={[0, 0.3, 0.5, 0.75]}
					modalContent={<ShareModal />}
					needCross={false}
					needArrow={false}
					className={`side-modal ${isWeb ? '' : 'modal-border-radius'}`}
				/>
				{/* <IonInput type="text" label="PAN No." labelPlacement="stacked" placeholder="Mobile No."></IonInput> */}
			</div>
		</>
	);
};
