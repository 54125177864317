import { useEffect, useRef, useState } from 'react';
import {
	IonButton,
	IonContent,
	IonFooter,
	IonIcon,
	IonImg,
} from '@ionic/react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router';
import { t } from 'i18next';
import {
	ContinueLaterPopUp,
	FormatDate,
	checkFieldsValidToContinue,
	showToastAlert,
} from '../../../../helpers/functions';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import { OfferRejectedMsg } from '../..';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	fetchMasterData,
	lenderDocumentUpload,
	lenderSpfDetailsFlow,
	saveLenderSpcfInfo,
} from '../../../../store/reducers/lead/leadSlice';
import {
	formDefaultValues,
	documentObj,
	formSchemas,
	formFields,
	documentValidation,
	BusinessType,
} from './BLInfo';
import { LenderNameForAPICall } from '../../../../helpers/lenders';
import {
	CustomModalLeadStatus,
	DynamicDropdown,
} from '../../../../components/common';
import { CustomInput } from '../../../../components/common/custom-input/CustomInput';
import { contributionPinCode } from '../../../../store/reducers/user/userSlice';
import { CustomCheckbox } from '../../../../components/common/custom-checkbox/CustomCheckbox';

export const ApplicantForms: React.FC<any> = ({
	selectedTab,
	handleSegmentChange,
	coTabIndex,
	setTotalCoApplicant,
	totalCoApplicant,
	setCoApplicant,
}) => {
	const [businessType, setBusinessType] = useState([]);
	const [genderList, setGenderList] = useState([]);
	const [residenceProofList, setResidenceProof] = useState([]);
	const [residenceOwnProofList, setResidenceOwnProof] = useState([]);
	const [leadStatusModalOpen, setLeadStatusModalOpen] = useState(false);
	const [applicationNo, setApplicationNO] = useState(null);

	const [pinCodeDataError, setPinCodeDataError] = useState('');
	const [isValidForm, setValidForm] = useState(false);
	const [coApplicantData, setCoApplicantData] = useState<any>([]);
	const [fields, setFields] = useState<any>([]);
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const [modalPageClose, setModalPageClose] = useState(false);
	const docUploadRef: any = useRef({});
	const dispatch = useDispatch();
	const history = useHistory();
	const { selectedLender, lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);
	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		trigger,
		reset,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(formSchemas(selectedTab)),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: formDefaultValues(
			lenderSpecificDetails,
			selectedTab,
			coTabIndex
		),
	});

	useEffect(() => {
		const lenderSpfc: any = lenderSpecificDetails?.lender_spf_details;
		if (getValues('is_co_applicant') === false) {
			setValue('is_key_promoter', true);
			trigger('is_key_promoter');
		}
		setFields(formFields(selectedTab));
		setDataInitially();
		if (!lenderSpfc?.is_co_applicant) {
			handleSegmentChange('PrimaryApplicantForm');
		}
		if (lenderSpfc?.co_applicant && lenderSpfc?.co_applicant?.length > 0) {
			setCoApplicantData(JSON.parse(JSON.stringify(lenderSpfc?.co_applicant)));
		}
	}, [selectedTab]);

	const setDataInitially = () => {
		getDataFromMaster();
		let schemas: any = formSchemas(selectedTab);
		if (getValues('cust_curr_resi_proof')?.[0]?.document_type === 'aadhaar') {
			schemas.fields.cust_curr_resi_proof = documentValidation({
				isRequired: false,
			});
		}

		if (selectedTab === 'PrimaryApplicantForm') {
			if (getValues('is_owned_business_premises')) {
				schemas.fields.business_owner_doc = documentValidation({
					isRequired: true,
					message: 'Business Ownership Document',
				});
			}

			if (
				getValues('business_type') === BusinessType.PARTNERSHIP ||
				getValues('business_type') === BusinessType.LLP
			) {
				schemas.fields.partnership_deed_doc = documentValidation({
					isRequired: true,
					message: 'Partnership Deed Document',
				});
				trigger('partnership_deed_doc');
			} else if (getValues('business_type') === BusinessType.PVT_LTD) {
				[
					{ name: 'moa_doc', msg: 'MOA Document' },
					{ name: 'aoa_doc', msg: 'AOA Document' },
					{ name: 'shareholding_doc', msg: 'Shareholding Document' },
				].forEach((el: any) => {
					schemas.fields[el.name] = documentValidation({
						isRequired: true,
						message: el.msg,
					});
					trigger(el);
				});
			}
		}
	};

	const getDataFromMaster = async () => {
		let masterApis: any = [
			'INDIFIBL_RESIDENCE_PROOF',
			'INDIFIBL_RESIDENCE_OWNERSHIP_PROOF',
		];

		if (selectedTab === 'PrimaryApplicantForm') {
			masterApis = ['INDIFIBL_BUSINESS_TYPE', ...masterApis];
		} else {
			masterApis = ['GENDER', ...masterApis];
		}

		masterApis.forEach(async (el: any) => {
			await dispatch(fetchMasterData({ param: el }))
				.then(unwrapResult)
				.then((res: any) => {
					if (res?.status) {
						switch (el) {
							case 'INDIFIBL_BUSINESS_TYPE':
								setBusinessType(res.data);
								break;
							case 'INDIFIBL_RESIDENCE_PROOF':
								setResidenceProof(res.data);
								break;
							case 'INDIFIBL_RESIDENCE_OWNERSHIP_PROOF':
								setResidenceOwnProof(res.data);
								break;
							case 'GENDER':
								setGenderList(res.data);
								break;
						}
					}
				});
		});
	};

	/**
	 * Set dropdown data
	 * @param name field name
	 */
	const setDropDownOptions = (name: string) => {
		if (name === 'business_type') {
			return businessType;
		} else if (name === 'gender') {
			return genderList;
		} else if (name.includes('cust_curr_resi_proof')) {
			if (getValues('is_owned_home_premises')) {
				return residenceOwnProofList;
			}
			return residenceProofList;
		} else if (name.includes('business_owner_doc')) {
			return residenceOwnProofList;
		}
	};

	/**
	 * Upload documents
	 * @param event file upload event
	 * @param key documents array index
	 */
	const uploadDocuments = async (name: any, event: any, key: any) => {
		const file = event.target.files[0];
		const fileTypes = ['jpg', 'jpeg', 'png', 'pdf'];
		const fType = file?.type?.split('/')?.reverse()[0];
		if (!fileTypes?.includes(fType?.toLowerCase())) {
			setValue(
				`${name}.${key}.message`,
				'Uploaded file is invalid. Only JPG/JPEG, PNG and PDF files are allowed.'
			);
			trigger(name);
			return;
		}

		if (file?.size / 1024 > 1024) {
			setValue(`${name}.${key}.message`, 'File size should be less than 1MB');
			trigger(name);
			return;
		}
		setValue(`${name}.${key}.message`, '');

		const formData: any = new FormData();
		formData.append('lender_name', LenderNameForAPICall.INDIFI_BL);
		formData.append('file', file);

		await dispatch(lenderDocumentUpload({ data: formData }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setValue(`${name}.${key}.file`, result?.key);
					setValue(`${name}.${key}.fileObj`, file);
					setValue(`${name}.${key}.preview`, URL.createObjectURL(file));
				} else {
					setValue(`${name}.${key}`, {
						...documentObj,
						message: result.message,
					});
				}
			});
		trigger(name);
		event.target.value = null;
	};

	/**
	 * Show selected document preview
	 * @param key document array index
	 */
	const showFilePreview = (name: any, key: any) => {
		let previewItem: any;
		const fileObj: any = getValues(`${name}.${key}.fileObj`);

		if (fileObj?.type?.includes('image')) {
			previewItem = (
				<>
					<img
						src={getValues(`${name}.${key}.preview`)}
						alt='preview'
						className='me-2 file_preview'
						width={'100%'}
					/>
					<p className='filename'>{fileObj.name}</p>
				</>
			);
		} else if (fileObj?.type?.includes('pdf')) {
			previewItem = (
				<>
					<img
						src='/images/pdf.svg'
						alt='preview'
						className='me-2 file_preview'
						width={80}
					/>
					<p className='filename'>{fileObj.name}</p>
				</>
			);
		} else if (getValues(`${name}.${key}.message`)) {
			previewItem = (
				<p className='text-red'>{t(getValues(`${name}.${key}.message`))}</p>
			);
		}
		return previewItem;
	};

	const checkPincode = async (name: string) => {
		const val: any = getValues(name)?.trim();
		if (val?.length === 6) {
			await dispatch(contributionPinCode({ data: { pincode: val } }))
				.then(unwrapResult)
				.then((result: any) => {
					setPinCodeDataError('');
					if (result?.status) {
						if (!result?.list) {
							showToastAlert({
								type: 'error',
								message: 'Please Check Pin Code No.',
							});
						} else {
							showToastAlert({ type: 'success', message: result?.message });
						}
					} else {
						setPinCodeDataError(result?.message);
					}
				});
		}
	};

	const addCoApplicant = () => {
		onSubmit(getValues(), 'add_co_applicant');
		const count: any = [...totalCoApplicant, totalCoApplicant.length + 1];
		setTotalCoApplicant(count);
		handleSegmentChange(`CoApplicantForm${count.length - 1}`);
		setCoApplicantData([...coApplicantData, {}]);
	};

	const removeApplicant = () => {
		const totalC = totalCoApplicant.filter(
			(el: any, index: number) => index != coTabIndex
		);
		setTotalCoApplicant(totalC);
		handleSegmentChange('CoApplicantForm0');
		let coData: any = JSON.parse(JSON.stringify(coApplicantData));
		coData = coData.filter((el: any, ind: any) => ind != coTabIndex);
		setCoApplicantData(coData);
		dispatch(
			lenderSpfDetailsFlow({
				...lenderSpecificDetails,
				lender_spf_details: {
					...lenderSpecificDetails?.lender_spf_details,
					co_applicant: coData,
				},
			})
		);
	};

	useEffect(() => {
		disabledSubmitBtn();
	}, [isValid, !isValid]);

	const disabledSubmitBtn = () => {
		let isDisabled: any = true;
		if (selectedTab === 'PrimaryApplicantForm') {
			isDisabled = !isValid;
		} else {
			isDisabled = !isValid || !!pinCodeDataError;
		}
		setValidForm(isDisabled);
	};

	const setDocumentFields = (name: any) => {
		let docArrObj: any = [];
		if (getValues(name)?.length > 0) {
			getValues(name).forEach((el: any) => {
				if (el.document_type) {
					docArrObj.push({
						file: el.file,
						document_type: el.document_type,
						password: el?.password || '',
					});
				} else {
					docArrObj = null;
				}
			});
		}
		return docArrObj;
	};

	const onDocumentTypChange = (name: string, k: any) => {
		let passwordKey: any = JSON.parse(JSON.stringify(name));
		passwordKey = passwordKey.replace('document_type', 'password');
		setValue(passwordKey, '');

		if (name.includes('cust_curr_resi_proof')) {
			let schemas: any = formSchemas(selectedTab);
			if (getValues(name) === 'aadhaar') {
				schemas.fields.cust_curr_resi_proof = documentValidation({
					isRequired: false,
				});
			} else {
				schemas.fields.cust_curr_resi_proof = documentValidation({
					isRequired: true,
					message: 'Customer Current Residence Proof',
				});
				setValue('cust_curr_resi_proof', [
					{ ...documentObj, document_type: getValues(name) },
				]);
			}
			trigger('cust_curr_resi_proof');
		}
		trigger(name);
	};

	const renderUploadDocument = (name: string, placeholder: string) => {
		if (selectedTab === 'PrimaryApplicantForm') {
			if (
				name === 'partnership_deed_doc' &&
				getValues('business_type') !== BusinessType.PARTNERSHIP &&
				getValues('business_type') !== BusinessType.LLP
			) {
				return false;
			}

			if (
				(name === 'moa_doc' ||
					name === 'aoa_doc' ||
					name === 'shareholding_doc') &&
				getValues('business_type') !== BusinessType.PVT_LTD
			) {
				return false;
			}

			if (
				name === 'business_owner_doc' &&
				!getValues('is_owned_business_premises')
			) {
				return false;
			}
		}

		return getValues(name)?.map((el: any, k: any) => {
			return (
				<div key={k}>
					{(name === 'cust_curr_resi_proof' ||
						name === 'business_owner_doc') && (
						<>
							{getValues('is_owned_home_premises') && (
								<small className='text-red'>
									NOTE: In case of married female promoter ownership can be
									consider from Inlaws owned property.
								</small>
							)}
							<Controller
								control={control}
								render={({ field: { onChange, onBlur, name } }) => (
									<DynamicDropdown
										label={t(`${placeholder.replace('*', 'Type *')}`)}
										data={setDropDownOptions(name)}
										name={name}
										onChange={(e: any) => {
											onChange(e);
											onDocumentTypChange(name, k);
										}}
										bindField={'value_1'}
										showField1={'value'}
										value={getValues(name)}
										onBlur={onBlur}
										errors={
											errors?.[name] &&
											errors?.[name][k]?.document_type?.message
										}
									/>
								)}
								name={`${name}.${k}.document_type`}
							/>
						</>
					)}

					{getValues(`${name}.${k}.document_type`) === 'bank_statement' && (
						<Controller
							control={control}
							render={({ field: { onChange, onBlur, name } }) => (
								<CustomInput
									type={'text'}
									label={t('Password (Optional)')}
									onBlur={onBlur}
									id={name}
									onChange={(e: any) => {
										onChange(e);
									}}
									value={getValues(name)}
								/>
							)}
							name={`${name}.${k}.password`}
						/>
					)}

					{name === 'cust_curr_resi_proof' &&
					getValues(`${name}.${k}.document_type`) === 'aadhaar' ? (
						<div className='mb-5'></div>
					) : (
						<div className='mb-4'>
							<CustomButton
								onClick={() => docUploadRef?.current[name + k]?.click()}
								expand='block'
								fill={'solid'}
							>
								<img
									src='/images/icons/upload-white.svg'
									alt='upload'
									className='me-2'
								/>
								{t(placeholder)}
							</CustomButton>

							<input
								type='file'
								style={{ display: 'none' }}
								accept='image/jpg,image/jpeg,image/png,application/pdf'
								ref={(el) => {
									docUploadRef.current[name + k] = el;
								}}
								id={name + k}
								onChange={(event) => {
									uploadDocuments(name, event, k);
								}}
							/>

							<span className='text-muted font-small'>
								Acceptable File Format : png, jpeg, jpg, pdf Maximum file size
								limit : 1MB
							</span>

							<div className='mt-2 mb-2'>{showFilePreview(name, k)}</div>
						</div>
					)}
				</div>
			);
		});
	};

	const onDropDownChange = (name: string) => {
		trigger(name);
		if (name === 'business_type') {
			getDataFromMaster();

			if (selectedTab === 'PrimaryApplicantForm') {
				let schemas: any = formSchemas(selectedTab);
				[
					'partnership_deed_doc',
					'moa_doc',
					'aoa_doc',
					'shareholding_doc',
				].forEach((el: any) => {
					schemas.fields[el] = documentValidation({ isRequired: false });
					setValue(el, []);
					trigger(el);
				});

				if (
					getValues('business_type') === BusinessType.PARTNERSHIP ||
					getValues('business_type') === BusinessType.LLP
				) {
					schemas.fields.partnership_deed_doc = documentValidation({
						isRequired: true,
						message: 'Partnership Deed Document',
					});
					setValue('partnership_deed_doc', [
						{ ...documentObj, document_type: 'partnership_deed' },
					]);
					trigger('partnership_deed_doc');
				} else if (getValues('business_type') === BusinessType.PVT_LTD) {
					[
						{ name: 'moa_doc', msg: 'MOA Document', docType: 'aoa_mom_coi' },
						{ name: 'aoa_doc', msg: 'AOA Document', docType: 'aoa_mom_coi' },
						{
							name: 'shareholding_doc',
							msg: 'Shareholding Document',
							docType: 'shareholding_and_directors',
						},
					].forEach((el: any) => {
						schemas.fields[el.name] = documentValidation({
							isRequired: true,
							message: el.msg,
						});
						setValue(el.name, [{ ...documentObj, document_type: el.docType }]);
						trigger(el.name);
					});
				}
			}
		}
	};

	const onCheckboxChange = (name: string) => {
		trigger(name);
		if (name === 'is_co_applicant' && !getValues(name)) {
			setValue('is_key_promoter', true);
			trigger('is_key_promoter');
		}

		if (name === 'is_owned_home_premises') {
			setValue('cust_curr_resi_proof', [documentObj]);
			setDropDownOptions('cust_curr_resi_proof');
		}

		if (name === 'is_owned_business_premises') {
			let schemas: any = formSchemas(selectedTab);
			schemas.fields.business_owner_doc = documentValidation({
				isRequired: getValues('is_owned_business_premises'),
				message: 'Business Ownership Document',
			});
			setValue('business_owner_doc', [documentObj]);
			trigger('business_owner_doc');
		}
	};

	/**
	 * On submit to save user data and after that
	 * @param data Form data
	 */
	const onSubmit = async (data: any, flag?: any) => {
		const req: any = {
			lender_spf_details: {},
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.INDIFI_BL,
			step: 'step1',
		};

		if (lenderSpecificDetails?.lender_spf_details) {
			req.lender_spf_details = JSON.parse(
				JSON.stringify(lenderSpecificDetails?.lender_spf_details)
			);
		}

		if (selectedTab.includes('CoApplicantForm')) {
			if (req.lender_spf_details.is_co_applicant) {
				req.isNewLead =
					isValid &&
					typeof flag !== 'string' &&
					totalCoApplicant.length - 1 == coTabIndex;
			}
			const coData: any = JSON.parse(JSON.stringify(coApplicantData));
			if (data?.pan_no) {
				data.pan_no = data.pan_no?.toUpperCase();
			}
			if (data?.dob) {
				data.dob = FormatDate(data.dob, 'yyyy-mm-dd');
			}
			coData[coTabIndex] = {
				...coData[coTabIndex],
				...data,
				cust_pan: setDocumentFields('cust_pan'),
				cust_aadhar_front: setDocumentFields('cust_aadhar_front'),
				cust_aadhar_back: setDocumentFields('cust_aadhar_back'),
				cust_curr_resi_proof: setDocumentFields('cust_curr_resi_proof'),
				isValid: isValid,
			};
			setCoApplicantData(coData);

			if (!req?.lender_spf_details?.co_applicant) {
				req.lender_spf_details = {
					...req.lender_spf_details,
					co_applicant: [coData[coTabIndex]],
				};
			} else {
				req.lender_spf_details.co_applicant[coTabIndex] = coData[coTabIndex];
			}
		} else {
			req.lender_spf_details = {
				...lenderSpecificDetails?.lender_spf_details,
				...data,
				gst_no: data?.gst_no?.toUpperCase(),
				cust_pan: setDocumentFields('cust_pan'),
				cust_aadhar_front: setDocumentFields('cust_aadhar_front'),
				cust_aadhar_back: setDocumentFields('cust_aadhar_back'),
				cust_curr_resi_proof: setDocumentFields('cust_curr_resi_proof'),
				gst_doc: setDocumentFields('gst_doc'),
				partnership_deed_doc: setDocumentFields('partnership_deed_doc'),
				moa_doc: setDocumentFields('moa_doc'),
				aoa_doc: setDocumentFields('aoa_doc'),
				shareholding_doc: setDocumentFields('shareholding_doc'),
				business_owner_doc: setDocumentFields('business_owner_doc'),
			};

			req.isNewLead =
				isValid && typeof flag !== 'string' && !data?.is_co_applicant;
		}

		/**
		 *  Note: Only one is_key_promoter would be true from Applicant and Co-Applicant
		 * 	IF Primary Applicant is not Key promoter then 1st Co-Applicant will be Key Promoter
		 *  IF Co-Applicant is not added then Primary Applicant will be Key Promoter
		 */
		if (
			req.lender_spf_details?.co_applicant?.length > 0 &&
			(coTabIndex == 0 || selectedTab.includes('PrimaryApplicantForm'))
		) {
			const coAppData: any = JSON.parse(
				JSON.stringify(req.lender_spf_details?.co_applicant)
			);
			coAppData[0].is_key_promoter = !req.lender_spf_details.is_key_promoter;
			req.lender_spf_details = {
				...req.lender_spf_details,
				co_applicant: coAppData,
			};
			setCoApplicantData(req.lender_spf_details.co_applicant);
		}

		if (req?.lender_spf_details?.is_co_applicant === false) {
			delete req.lender_spf_details.co_applicant;
		}

		if (lenderSpecificDetails?.lead_spf_detail_id) {
			req.lead_spf_detail_id = lenderSpecificDetails?.lead_spf_detail_id;
		}

		setShowOfferErrMsg('');
		await dispatch(saveLenderSpcfInfo({ data: req }))
			.then(unwrapResult)
			.then((res: any) => {
				if (res?.status) {
					setCoApplicant(req?.lender_spf_details?.is_co_applicant);
					dispatch(
						lenderSpfDetailsFlow({
							...lenderSpecificDetails,
							lender_spf_details:
								res?.body?.lender_spf_details || res?.data?.lender_spf_details,
						})
					);
					if (req.isNewLead) {
						let appNo: any = `Application No: ${res.data?.application_id}\nINDIFI LINK: ${res.data?.redirectUrl}`;
						setApplicationNO(appNo);
						setLeadStatusModalOpen(true);
					} else {
						reset({});
						if (selectedTab === 'PrimaryApplicantForm') {
							handleSegmentChange('CoApplicantForm0');
						}
					}
				} else {
					dispatch(
						lenderSpfDetailsFlow({
							...lenderSpecificDetails,
							lender_spf_details:
								res?.body?.lender_spf_details ||
								res?.data?.lender_spf_details ||
								req.lender_spf_details,
						})
					);
					setShowOfferErrMsg(res?.message);
				}
			});
	};

	/**
	 * Continue Later and Submit button
	 */
	const continueLaterAndSubmitBtn = () => {
		let submitBtnLabel = 'Save & Continue';
		if (
			(selectedTab.includes('CoApplicantForm') &&
				totalCoApplicant.length - 1 == coTabIndex) ||
			(selectedTab.includes('PrimaryApplicantForm') &&
				!getValues('is_co_applicant'))
		) {
			submitBtnLabel = 'Proceed';
		}

		return (
			<>
				<CustomButton
					className='w-100 fw-bold dashboard-add-lead-button mb-3'
					expand='block'
					fill='outline'
					title={t('Continue Later')}
					onClick={() => {
						if (checkFieldsValidToContinue(errors)) {
							setModalPageClose(true);
						} else {
							showToastAlert({
								type: 'error',
								message: 'Please fix above errors',
							});
						}
					}}
				/>
				<CustomButton
					type='submit'
					className='w-100 fw-bold dashboard-add-lead-button'
					expand='block'
					title={t(submitBtnLabel)}
					disabled={isValidForm}
					onClick={handleSubmit(onSubmit)}
				/>
			</>
		);
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 pt-3 p-md-5 border-radius-10'>
							{!lenderSpecificDetails?.lender_spf_details?.is_key_promoter &&
							selectedTab.includes('CoApplicantForm') &&
							coTabIndex == 0 ? (
								<h6 className='mb-4'>Promoter Details</h6>
							) : (
								''
							)}
							<form onSubmit={handleSubmit(onSubmit)}>
								<>
									{fields.map(
										({ name, type, placeholder }: any, index: any) => {
											const errorText: any =
												errors && Object.keys(errors).length > 0 && errors;
											return (
												<div key={index}>
													{type === 'dropdown' ? (
														<div style={{ marginBottom: '15px' }}>
															<Controller
																control={control}
																render={({
																	field: { onChange, onBlur, name },
																}) => (
																	<DynamicDropdown
																		label={t(`${placeholder}`)}
																		data={setDropDownOptions(name)}
																		name={name}
																		onChange={(e: any) => {
																			onChange(e);
																			onDropDownChange(name);
																		}}
																		bindField={'value'}
																		showField1={'value'}
																		value={getValues(name)}
																		onBlur={onBlur}
																		className={'mb-0'}
																		errors={errorText[name]?.message}
																	/>
																)}
																name={name}
															/>
														</div>
													) : type === 'checkbox' ? (
														<div className='mb-2'>
															<Controller
																control={control}
																render={({
																	field: { onChange, onBlur, name },
																}) => (
																	<CustomCheckbox
																		name={name}
																		onChange={({
																			detail: { checked },
																		}: any) => {
																			onChange(checked);
																			onCheckboxChange(name);
																		}}
																		value={getValues(name)}
																		labelPlacement='end'
																		className='end-of-page'
																		onBlur={onBlur}
																		isChecked={getValues(name)}
																		disabled={
																			name === 'is_key_promoter' &&
																			!getValues('is_co_applicant')
																		}
																		children={
																			<span className='text-wrap'>
																				{t(placeholder)}
																			</span>
																		}
																	/>
																)}
																name={name}
															/>
														</div>
													) : type === 'file' ? (
														<div className='mt-4'>
															{renderUploadDocument(name, placeholder)}
														</div>
													) : type === 'date' ? (
														<Controller
															control={control}
															render={({
																field: { onChange, onBlur, name },
															}) => (
																<CustomInput
																	type={type}
																	needSuffixIcon={true}
																	suffixClassName={
																		'position-absolute date-of-birth-sufix'
																	}
																	suffixText={
																		<IonImg
																			src='/images/icons/input-dob-calendar-icon.svg'
																			alt='dob'
																		/>
																	}
																	outerClassName={'position-relative'}
																	label={t(placeholder)}
																	onBlur={onBlur}
																	id={name}
																	onChange={(e: any) => {
																		onChange(e);
																	}}
																	value={getValues(name)}
																	errors={t(errorText[name]?.message || '')}
																/>
															)}
															name={name}
														/>
													) : (
														<>
															<Controller
																control={control}
																render={({
																	field: { onChange, onBlur, name },
																}) => (
																	<CustomInput
																		type={type}
																		label={t(placeholder)}
																		onBlur={onBlur}
																		id={name}
																		onChange={(e: any) => {
																			onChange(e);
																			if (name === 'primary_pincode') {
																				checkPincode(name);
																			}
																		}}
																		value={
																			name === 'pan_no' || name === 'gst_no'
																				? getValues(name)?.toUpperCase()
																				: getValues(name)
																		}
																		errors={
																			name === 'primary_pincode'
																				? t(
																						pinCodeDataError[name] ||
																							errorText[name]?.message
																				  )
																				: t(errorText[name]?.message)
																		}
																	/>
																)}
																name={name}
															/>
														</>
													)}
												</div>
											);
										}
									)}

									{selectedTab.includes('CoApplicantForm') && (
										<div className='co_app_form_pagination'>
											<div className='other_btns w-100'>
												<CustomButton
													type='button'
													className='add-co-btn fw-bold dashboard-add-lead-button'
													expand='block'
													title={t('Add Co-Applicant')}
													disabled={!isValid}
													onClick={addCoApplicant}
												/>

												{coTabIndex != 0 ? (
													<IonButton
														shape='round'
														type='button'
														color='danger'
														onClick={() => removeApplicant()}
													>
														<IonIcon src='/images/icons/delete.svg' />
													</IonButton>
												) : (
													''
												)}
											</div>
										</div>
									)}
								</>
							</form>

							<div className={`d-none d-md-block mt-4`}>
								{continueLaterAndSubmitBtn()}
							</div>
						</div>
					</div>
				)}
			</IonContent>

			{!showOfferErrMsg && (
				<IonFooter className='d-block d-md-none position-relative ion-padding lead_ftr_btn'>
					{continueLaterAndSubmitBtn()}
				</IonFooter>
			)}

			<CustomModal
				needIonContentWraper={false}
				isOpen={modalPageClose}
				initialBreakpoint={undefined}
				handleClose={() => setModalPageClose(false)}
				breakpoints={[]}
				className='height-auto confirm-popup-middle continue-later'
				modalContent={ContinueLaterPopUp(
					() => {
						setModalPageClose(false);
					},
					() => {
						new Promise(function (myResolve) {
							setTimeout(function () {
								myResolve(history.push('/dashboard/lead'));
								onSubmit(getValues(), 'continue_later');
								setModalPageClose(false);
							}, 10);
						});
					},
					'Cancel',
					'Continue Later',
					'className',
					'Are You Sure You Want to Exit?',
					`${t('You can continue later from where you left off.')}`
				)}
				needCross={false}
				needArrow={false}
			/>

			{leadStatusModalOpen && (
				<CustomModalLeadStatus
					isOpen={leadStatusModalOpen}
					applicationNo={applicationNo}
					handleClose={() => setLeadStatusModalOpen(false)}
				/>
			)}
		</>
	);
};
