import {
    IonButton,
    IonCard,
    IonCardContent,
    IonContent,
    IonLabel,
    IonPage,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CustomButton } from "../../components/common/custom-button/CustomButton";
import { Header } from "../../components/layout/header/Header";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomForm from "../insurance/components/common/custom_form/custom_form.component";
import { t } from "i18next";
import OtpModal from "./otpModal";
import ManualUploadModal from "./manualUploadModal";

interface Props {}

interface Holding {
    isin: string;
    qty: number;
    fundName: string;
    currentRate: number;
    marketValue: number;
    type: "Equity" | "Mutual fund" | "Bond" | "Debenture";
}

export const ShowHoldings: React.FC<Props> = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [holdings, setHoldings] = useState<Holding[]>([
        { isin: "US1234567890", qty: 100, fundName: "Fund A", currentRate: 150.25, marketValue: 15025.00, type: "Equity" },
        { isin: "US0987654321", qty: 200, fundName: "Fund B", currentRate: 75.50, marketValue: 15100.00, type: "Mutual fund" },
        { isin: "US2345678901", qty: 50, fundName: "Fund C", currentRate: 200.75, marketValue: 10037.50, type: "Bond" },
        { isin: "US3456789012", qty: 75, fundName: "Fund D", currentRate: 80.25, marketValue: 6018.75, type: "Debenture" },
        { isin: "US4567890123", qty: 150, fundName: "Fund E", currentRate: 120.00, marketValue: 18000.00, type: "Equity" },
        { isin: "US5678901234", qty: 90, fundName: "Fund F", currentRate: 140.50, marketValue: 12645.00, type: "Mutual fund" },
        { isin: "US6789012345", qty: 110, fundName: "Fund G", currentRate: 60.75, marketValue: 6682.50, type: "Bond" },
        { isin: "US7890123456", qty: 200, fundName: "Fund H", currentRate: 90.25, marketValue: 18050.00, type: "Debenture" },
        { isin: "US8901234567", qty: 80, fundName: "Fund I", currentRate: 110.00, marketValue: 8800.00, type: "Equity" },
        { isin: "US9012345678", qty: 175, fundName: "Fund J", currentRate: 130.50, marketValue: 22837.50, type: "Mutual fund" },
        { isin: "US0123456789", qty: 60, fundName: "Fund K", currentRate: 95.75, marketValue: 5745.00, type: "Bond" },
        { isin: "US1234509876", qty: 85, fundName: "Fund L", currentRate: 105.25, marketValue: 8946.25, type: "Debenture" },
        { isin: "US2345610987", qty: 120, fundName: "Fund M", currentRate: 85.00, marketValue: 10200.00, type: "Equity" },
        { isin: "US3456721098", qty: 95, fundName: "Fund N", currentRate: 125.75, marketValue: 11946.25, type: "Mutual fund" },
        { isin: "US4567832109", qty: 130, fundName: "Fund O", currentRate: 70.50, marketValue: 9165.00, type: "Bond" },
        { isin: "US5678943210", qty: 45, fundName: "Fund P", currentRate: 155.25, marketValue: 6986.25, type: "Debenture" },
        { isin: "US6789054321", qty: 65, fundName: "Fund Q", currentRate: 98.50, marketValue: 6402.50, type: "Equity" },
        { isin: "US7890165432", qty: 110, fundName: "Fund R", currentRate: 120.75, marketValue: 13282.50, type: "Mutual fund" },
        { isin: "US8901276543", qty: 140, fundName: "Fund S", currentRate: 115.25, marketValue: 16135.00, type: "Bond" },
        { isin: "US9012387654", qty: 160, fundName: "Fund T", currentRate: 145.50, marketValue: 23280.00, type: "Debenture" },
    ]);

    const onSubmit = async () => {
        // Handle submit logic here
        history.push('/lenders-list');
    };

    return (
        <IonPage className="bg-web-white container-width-md container-spacing-md border-md-radius-top">
            <Header
                className="card-header"
                name={t("Portfolio Status")}
                backButton={true}
                needArrow={true}
                needArrowOnClick={() => {
                    history.push("/dashboard");
                }}
            />
            <IonContent className="ion-padding-bottom">
                <IonLabel style={{ textAlign: 'center', display: 'block' }}>
                    Portfolio updated successfully
                </IonLabel>
                {/* {holdings.map((holding, index) => (
                    <IonCard key={index}>
                        <IonCardContent>
                            <div>ISIN: {holding.isin}</div>
                            <div>Quantity: {holding.qty}</div>
                            <div>Fund Name: {holding.fundName}</div>
                            <div>Type: {holding.type}</div>
                            <div>Current Rate: ₹{holding.currentRate.toFixed(2)}</div>
                            <div>Current Rate Date: 07-08-2024 </div>
                            <div>Market Value: ₹{holding.marketValue.toFixed(2)}</div>
                        </IonCardContent>
                    </IonCard>
                ))} */}
                <div
                    className='position-fixed w-100 bottom-0 start-0 px-3 pb-3'
                    style={{ backgroundColor: '#fff' }}
                >
                    <CustomButton
                        className='btn-blue'
                        type='submit'
                        expand='block'
                        title='Submit'
                        onClick={() => {
                            onSubmit();
                        }}
                    />
                </div>
            </IonContent>
        </IonPage>
    );
};
