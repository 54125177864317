import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonText
} from '@ionic/react';
import { camera, fileTray, trash, download } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { FieldErrors, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import useApi from '../../../api/useApi';
import { useBusyLoading } from '../../../context/BusyLoadingContext';
import { DOCUMENT_UPLOAD } from '../../../helpers/api-endpoints';
import { getFileExtension, showToastAlert } from '../../../helpers/functions';
import { DocumentUploadForm, DocumentUploadRequest } from '../../../model/document_bean';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Share } from '@capacitor/share';

interface inpProps {
  name: string;
  title: string;
  subTitle?: string;
  mandatory?: boolean;
  error?: FieldErrors;
  showCamera?: boolean;
  getValuesFunc: UseFormGetValues<any>;
  setValueFunc: UseFormSetValue<any>;
  inpUploadFunction?: () => void;
  inpDeleteFunction?: () => void;
  value?: DocumentUploadForm;
  applicationId?: number;
  policyId?: number | undefined;
  profileId?: number;
  documentTypeId: number;
  allowVideo?: boolean;
  allowImages?: boolean;
  allowPdfs?: boolean;
  allowExcel?: boolean;
  allowAudio?: boolean;
  maxFileSize?: number;
}

export const UploadWidget: React.FC<inpProps> = ({ name, title, subTitle, value, showCamera = true, applicationId, policyId, profileId, documentTypeId, getValuesFunc, setValueFunc, mandatory = false,
  allowVideo = true, allowImages = true,
  allowAudio = true,
  allowPdfs = true,
  allowExcel = false,
  maxFileSize = 2097152,
  error = undefined, inpUploadFunction = undefined, inpDeleteFunction = undefined }) => {

  const [filePreview, setFilePreview] = useState<string | undefined>();
  const [isUploading, setIsUploading] = useState(false); // State variable to track upload status
  const fileInputRef = useRef<HTMLInputElement>(null);
  const docUpload = useApi<any>(DOCUMENT_UPLOAD);
  const { setBusyState } = useBusyLoading();
  const [docId, setDocId] = useState<number | null>(null);
  let validFileTypes: string[] = [];
  const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
  const validVideoTypes = ['video/mp4', 'video/quicktime'];
  const validPdfTypes = ['application/pdf'];
  const validAudioTypes = ['audio/mpeg', 'audio/wav', 'audio/*'];
  const validExcelTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];


  if (allowImages) {
    validFileTypes = validFileTypes.concat(validImageTypes);
  }
  if (allowVideo) {
    validFileTypes = validFileTypes.concat(validVideoTypes);
  }
  if (allowPdfs) {
    validFileTypes.push(...validPdfTypes);
  }
  if (allowAudio) {
    validFileTypes = validFileTypes.concat(validAudioTypes);
  }
  if (allowExcel) {
    validFileTypes = validFileTypes.concat(validExcelTypes);
  }


  let documentUploadFormStub: DocumentUploadForm = getValuesFunc(name);

  useEffect(() => {
    setBusyState(docUpload.loading);
  }, [docUpload.loading]);

  useEffect(() => {
    showToastAlert({ type: "error", message: docUpload.error?.message ?? '' });
  }, [docUpload.error]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      // console.log("hbs file.type", file.type);
      if (!validFileTypes.includes(file.type)) {
        showToastAlert({ type: "error", message: `Invalid file type. Allowed types are: ${validFileTypes.join(", ")}.` });
        return;
      }
      if (file.size > maxFileSize) {
        showToastAlert({ type: "error", message: `File is too large. Maximum allowed size is ${maxFileSize / 1024 / 1024}MB.` });
        return;
      }
    }
    const reader = new FileReader();
    reader.onloadend = async () => {
      

      if(inpUploadFunction === undefined) {
        const stub = getValuesFunc(name);
        if (stub !== undefined && stub instanceof DocumentUploadForm) {
          documentUploadFormStub = stub;
        }
        else {
          documentUploadFormStub = new DocumentUploadForm();
          documentUploadFormStub.applicationId = applicationId!;
          documentUploadFormStub.policyId = policyId ?? null;
          documentUploadFormStub.profileId = profileId!;
          documentUploadFormStub.documentTypeId = documentTypeId!;
        }
        documentUploadFormStub.documentId = docId;
        documentUploadFormStub.content = reader.result?.toString() ?? null;
        documentUploadFormStub.deletedFlag = false;
        documentUploadFormStub.name = file?.name ?? '';
        documentUploadFormStub.extension = getFileExtension(file);
        uploadFile();

      }
      else{
        console.log("name",name);
        setIsUploading(true); // Disable buttons
        setValueFunc(name,{
          fileName : file?.name ?? '',
          content : reader.result?.toString() ?? null,
          extension: getFileExtension(file)

        });
        const stub = getValuesFunc(name);
        await inpUploadFunction();
        setIsUploading(false); // 
        setFilePreview(reader.result?.toString()?? null);
      }
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const respData = docUpload.responseData;
    if (respData?.status) {
      const stubData = respData?.data;
      if (stubData) {
        let documentUploadRequest: DocumentUploadRequest | null | undefined;
        documentUploadRequest = stubData;
        if (documentUploadRequest) {
          setDocId(documentUploadRequest.documentId);
          documentUploadFormStub = new DocumentUploadForm(documentUploadRequest!);
          if (documentUploadFormStub.deletedFlag) {
            setFilePreview(undefined);
          }
          else {
            setValueFunc(name, documentUploadFormStub);
            setFilePreview(documentUploadFormStub.content!);
          }
        }
      }
    }
  }, [docUpload.responseData]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const stubVal = getValuesFunc(name);
      if (stubVal) {
        if (documentUploadFormStub && documentUploadFormStub.content) {
          setFilePreview(documentUploadFormStub.content!);
        }
      }
    }
      , 50);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const handleDelete = () => {
    if (inpDeleteFunction === undefined) {
      documentUploadFormStub.documentId = docId;
      documentUploadFormStub.content = null;
      documentUploadFormStub.extension = '';
      documentUploadFormStub.name = '';
      documentUploadFormStub.deletedFlag = true;
      uploadFile();
    }
    else {
      setValueFunc(name, undefined);
      setFilePreview(undefined);
    }

  };

  const handleFileButtonClick = () => {
    if (fileInputRef.current !== null) {
      fileInputRef.current.click();
    }
  };

  const takePhoto = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      source: CameraSource.Camera,
      resultType: CameraResultType.Base64
    });

    const estimatedSize = image.base64String ? (image.base64String.length * 3) / 4 : 0;
    if (estimatedSize > maxFileSize) {
      showToastAlert({ type: "error", message: `File is too large. Maximum allowed size is ${maxFileSize / 1024 / 1024}MB.` });
      return;
    }

    const stub = getValuesFunc(name);
    if (stub !== undefined && stub instanceof DocumentUploadForm) {
      documentUploadFormStub = stub;
    }
    else {
      documentUploadFormStub = new DocumentUploadForm();
      documentUploadFormStub.applicationId = applicationId!;
      documentUploadFormStub.policyId = policyId ?? null;
      documentUploadFormStub.profileId = profileId!;
      documentUploadFormStub.documentTypeId = documentTypeId!;
    }
    documentUploadFormStub.documentId = docId;
    documentUploadFormStub.content = `data:image/jpeg;base64,${image.base64String}`;
    documentUploadFormStub.deletedFlag = false;
    const timestamp = new Date().getTime();
    documentUploadFormStub.extension = 'jpg';
    documentUploadFormStub.name = `photo_${timestamp}.${documentUploadFormStub.extension}`;
    uploadFile();
  };

  const isWeb = Capacitor.getPlatform() === 'web'; // Check if the platform is web

  // const downloadFile = async () => {
  //   if (filePreview) {
  //     const fileName = documentUploadFormStub.name;
  //     const base64Data = filePreview.split(',')[1];

  //     if (Capacitor.getPlatform() === 'web') {
  //       // Create a link element for web download
  //       const link = document.createElement('a');
  //       link.href = filePreview;
  //       link.download = fileName;
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     } else {
  //       try {
  //         await Filesystem.writeFile({
  //           path: fileName,
  //           data: base64Data,
  //           directory: Directory.Documents,
  //           encoding: Encoding.UTF8
  //         });

  //         showToastAlert({ type: "success", message: "File downloaded successfully!" });
  //       } catch (error) {
  //         showToastAlert({ type: "error", message: `Error downloading file: ${error}` });
  //       }
  //     }
  //   }
  // };

  async function checkFilesystemPermission() {
    try {
      const permission = await Filesystem.requestPermissions();
      if (permission.publicStorage !== 'granted') {
        throw new Error('Filesystem permissions not granted');
      }
    } catch (error) {
      console.error('Error requesting filesystem permissions', error);
    }
  }

  // async function checkMicrophonePermission() {
  //   if (Capacitor.getPlatform() === 'android') {
  //     try {
  //       const permission = await Camera.requestPermissions({ permissions: ['microphone'] });
  //       if (permission.microphone !== 'granted') {
  //         throw new Error('Microphone permissions not granted');
  //       }
  //     } catch (error) {
  //       console.error('Error requesting microphone permissions', error);
  //     }
  //   }
  // }

  const downloadFile = async () => {
    if (!filePreview) return;

    const timestamp = new Date().getTime();
    let documentStubExtension = '';
    let documentStubName = `file_${timestamp}.${documentStubExtension}`;



    const fileName = documentUploadFormStub?.name??documentStubName;
    
    
    const base64Data = filePreview.split(',')[1];


    try {
      if (Capacitor.getPlatform() === 'web') {
        // Create a link element for web download
        const link = document.createElement('a');
        link.href = filePreview;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        // Handle Android and iOS download

        const result = await Filesystem.writeFile({
          path: fileName,
          data: base64Data,
          directory: Directory.Documents,
          encoding: Encoding.UTF8,
        });


        if (Capacitor.getPlatform() === 'android') {

          await checkFilesystemPermission();
          // For Android, use FileOpener to open the downloaded file
          await FileOpener.open({
            filePath: result.uri,
            contentType: 'application/octet-stream', // Adjust the content type if needed
          });
        } else if (Capacitor.getPlatform() === 'ios') {
          await checkFilesystemPermission();
          // For iOS, use Share plugin to open the file
          await Share.share({
            title: 'Downloaded File',
            text: 'Here is the downloaded file.',
            url: result.uri,
            dialogTitle: 'Open with',
          });
        }

        showToastAlert({ type: "success", message: "File downloaded successfully!" });
      }
    } catch (error) {
      showToastAlert({ type: "error", message: `Error downloading file: ${error}` });
    }
  };

  const uploadFile = async () => {
    setIsUploading(true); // Disable buttons

    if (inpUploadFunction === undefined) {
      const payload = new DocumentUploadRequest(documentUploadFormStub);
      await docUpload.executeRequest('post', payload);
    } else {
      await inpUploadFunction();
    }
    setIsUploading(false); // Enable buttons after upload completes
  };



  return (
    <IonCard style={{ height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <IonGrid>
        <IonCol>
          <IonCardTitle>{title.replace(/\*/g, '')}{mandatory ? ' *' : ''}</IonCardTitle>
        </IonCol>
        {subTitle && <IonCol>
          <IonCardSubtitle>{subTitle}</IonCardSubtitle>
        </IonCol>}
        <IonCol>
          <IonCardContent style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {
              filePreview ? <>
                <IonCard style={{ height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <IonCardContent style={{ height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img style={{ height: '100%', width: '100%' }} src={`${filePreview}`} alt="file-preview" />
                  </IonCardContent>
                </IonCard>
                <IonButton onClick={() => handleDelete()}>
                  <IonIcon icon={trash} />
                </IonButton>
                <IonButton onClick={downloadFile}>
                  <IonIcon icon={download} />
                </IonButton>
              </>
                :
                <IonRow>
                  <IonCard style={{ height: "100%", alignItems: 'center', justifyContent: 'center' }}>
                    <IonCardContent style={{ height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <label htmlFor={name} className="file-input-label">
                        <IonButton expand="full" onClick={handleFileButtonClick} disabled={isUploading}>
                          <IonIcon icon={fileTray} />
                        </IonButton>
                      </label>
                      <input
                        ref={fileInputRef}
                        name={name}
                        id={name}
                        type="file"
                        onChange={(event) => handleFileChange(event)}
                        accept={validFileTypes.join(",")}
                        style={{ display: 'none' }}
                      />
                    </IonCardContent>
                  </IonCard>

                  {!isWeb && showCamera && <IonCard style={{ height: "100%", alignItems: 'center', justifyContent: 'center' }}>
                    <IonCardContent style={{ height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <IonButton expand="full" onClick={() => {
                        takePhoto();
                      }} disabled={isUploading}>
                        <IonIcon icon={camera} />
                      </IonButton>
                    </IonCardContent>
                  </IonCard>}

                </IonRow>

            }

            {mandatory && error && <IonCol><IonText color="danger">{error.message as unknown as string}</IonText></IonCol>}

          </IonCardContent>

        </IonCol>
      </IonGrid>
    </IonCard>
  );
};
