import React, { useEffect, useState } from 'react';
import { IonContent, IonFooter, IonPage, isPlatform } from '@ionic/react';
import { Header } from '../../../components/layout/header/Header';
import './AddYourDetails.scss';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { CustomCheckbox } from '../../../components/common/custom-checkbox/CustomCheckbox';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import inputData from './inputs.json';
import { useHistory } from 'react-router';
import { REGEX, USER_ROLES } from '../../../helpers/constant';
import { useDispatch, useSelector } from 'react-redux';
import {
	dmaRegister,
	getdmaRegisterDetails,
	// contributionPinCode,
	needFooter,
	selfDMARegister,
} from '../../../store/reducers/user/userSlice';
import {
	capFirstLetter,
	checkFieldsValidToContinue,
	disableNumberArrows,
	eventAttributes,
	fetchStateCityByPincode,
	FormatDate,
	notifyHandler,
	showToastAlert,
} from '../../../helpers/functions';
import StateCity from '../../../components/common/StateCity';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { RejectionModal } from '../../teams/RejectionModal';
import { ConsentCheckbox } from '../../../components/common/consent-checkbox/ConsentCheckbox';
import { unwrapResult } from '@reduxjs/toolkit';
import { NotifyVisitors } from 'capacitor-plugin-notifyvisitors';
// import { unwrapResult } from '@reduxjs/toolkit';
// import 'react-toastify/dist/ReactToastify.css';

const validationSchema = yup.object().shape({
	first_name: yup
		.string()
		.trim()
		.max(100, 'First Name should not be more than 100')
		.required('First Name is Required')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid First Name'),
	middle_name: yup
		.string()
		.trim()
		.max(100, 'Middle Name should not be more than 100')
		.notRequired()
		.matches(REGEX.ALLOWONLYCHAR, {
			message: 'Please Enter Valid Middle Name',
			excludeEmptyString: true,
		}),
	last_name: yup
		.string()
		.trim()
		.max(100, 'Last Name should not be more than 100')
		.required('Last Name is Required')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Last Name'),
	dob: yup.date()
		.required('DOB is Required.')
		.typeError('Please select valid date')
		.max(new Date(Date.now()), 'Date cannot be in the future')
		.test('dob', 'Age must be 18 or older', function (birthdate) {
			const cutoff = new Date();
			cutoff.setFullYear(cutoff.getFullYear() - 18);
			return birthdate <= cutoff;
		}),
	pincode: yup
		.string()
		.trim()
		.required('Pin Code Number is Required')
		.length(6, 'Pin Code Should Be 6 Digits'),
	state: yup.string().trim().required('State is Required'),
	city: yup.string().trim().required('City is Required'),
	email: yup
		.string()
		.required('Email is Required')
		.matches(REGEX.EMAIL, 'Please Enter Valid Email Address'),
	acceptTerms: yup.boolean().oneOf([true], 'You need to check the consent'),
});

export const AddYourDetails: React.FC = () => {
	const [pinCodeData, setPinCodeData] = useState<any>([]);
	// const [pinCodeValue, setPincodeValue] = useState<any>({});
	const history = useHistory();
	const dmaSelector = useSelector((state: any) => state?.users);
	const dispatch = useDispatch();
	const addYourData: any = useSelector((state: any) => state);
	const { t } = useTranslation();
	const [rejectionModal, setRejectionModal] = useState(false);
	const [isMoreContent, setMoreContent] = useState(false);
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	let continueLaterFlag = false;
	// Notify Visitors
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const userName = useSelector(
		(state: any) => state?.users?.homepage?.profile?.name
	);
	const userMobile = useSelector(
		(state: any) => state?.users?.user_details?.mobile
	);

	const entityType = useSelector(
		(state: any) => state?.users?.user_details?.entityType
	);
	const userLang = useSelector(
		(state: any) => state?.oneTimeScreen?.language?.name
	);

	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		trigger,
		reset,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			first_name: '',
			middle_name: '',
			last_name: '',
			dob:'',
			pincode: '',
			state: '',
			city: '',
			email: '',
			acceptTerms: false,
		},
	});

	const onSubmit = async (data: any) => {
		const registerData = {
			...data,
			...addYourData?.users?.registerUser,
			first_name: data?.first_name ? capFirstLetter(data?.first_name) : '',
			middle_name: data?.middle_name ? capFirstLetter(data?.middle_name) : '',
			last_name: data?.last_name ? capFirstLetter(data?.last_name) : '',
			dob: FormatDate(data?.dob, 'yyyy-mm-dd')			
			// state: pinCodeValue.state || '',
			// city: pinCodeValue.city || '',
		};
		const myPromise = new Promise(function (myResolve) {
			setTimeout(function () {
				myResolve(history.push('/terms-conditions'));
			}, 10);
		});
		myPromise.then(() => {
			dispatch(selfDMARegister(registerData));
			reset({
				first_name: '',
				middle_name: '',
				last_name: '',
				dob: '',
				state: '',
				city: '',
				pincode: '',
				email: '',
				acceptTerms: false,
			});
			dispatch(needFooter(true));
			setPinCodeData([]);
		});
	};

	const isDmaPage = location.pathname === '/add-dma';

	useEffect(() => {
		if (isDmaPage) {
			reset({
				first_name: '',
				middle_name: '',
				last_name: '',
				dob: '',
				state: '',
				city: '',
				pincode: '',
				email: '',
				acceptTerms: false,
			});
			setPinCodeData([]);
		}
	}, [isDmaPage]);



	const userAttributes = {
		userId: userId,
		attributes: {
			name: userName,
			id: userId,
			mobile: userMobile,
			user_language: userLang,
			entityType:
				entityType === 'Individual'
					? 'DMA'
					: entityType === 'BTM'
					? 'employee'
					: entityType,
		},
	};
	useEffect(() => {
		if (isPlatform('android') || isPlatform('ios')) {
			notifyHandler('event', eventAttributes('add_DMA_personal_details'));
			notifyHandler('user', userAttributes.attributes);
			const inAppData = {
				tokens: { name: userAttributes },
				customRules: { banerrType: 'add dma personal details' },
			};
			NotifyVisitors.show(inAppData);
		} else {
			(window as any).nv('event', 'add_DMA_personal_details', {}, 10, 1);
			(window as any).nv('user', userId, userAttributes.attributes);
		}
	}, []);

	useEffect(() => {
		if (location.pathname !== '/add-your-details') {
			return;
		}
		if (dmaSelector?.dmaFlow?.dmaId) {
			getDmaDetails();
		}
	}, [location.pathname]);

	// const pinCodeAction = async (value: any) => {
	// 	setPinCodeData([]);
	// 	dispatch(contributionPinCode({ data: { pincode: value } }))
	// 		.then(unwrapResult)
	// 		.then((response: any) => {
	// 			if (response?.status) {
	// 				if (!response?.list) {
	// 					showToastAlert({
	// 						type: 'error',
	// 						message: 'Please Check Pin Code No.',
	// 					});
	// 					setPinCodeData([]);
	// 				} else {
	// 					setPinCodeData(response?.list);
	// 				}
	// 			}
	// 		});
	// };

	// const enableButton: boolean =
	// 	Object.keys(errors).length === 0 && Object.keys(pinCodeValue).length !== 0;
	const continueLater = async () => {
		if (checkFieldsValidToContinue(errors)) {
			continueLaterFlag = true;

			let id;
			if (dmaSelector?.dmaFlow?.dmaId) {
				id = dmaSelector?.dmaFlow?.dmaId;
			} else {
				id = dmaSelector?.user_details?.id;
			}

			const obj: any = getValues();
			obj['continueLater'] = continueLaterFlag;
			obj['pan'] = dmaSelector?.registerUser?.pan || dmaSelector?.dmaAbout?.pan;
			obj['entityType'] =
				dmaSelector?.registerUser?.entityType ||
				dmaSelector?.dmaAbout?.RegisteredAs;
			obj['status'] = '2';
			await dispatch(dmaRegister({ param: '/' + id, data: obj }))
				.then(unwrapResult)
				.then(async (response: any) => {
					if (response?.status) {
						reset({
							first_name: '',
							middle_name: '',
							last_name: '',
							dob:'',
							state: '',
							city: '',
							pincode: '',
							email: '',
							acceptTerms: false,
						});
						setPinCodeData([]);
						continueLaterFlag = false;
						history.replace('/dashboard/teams/');
					}
				});
		} else {
			showToastAlert({
				type: 'error',
				message: 'Please fix above errors',
			});
		}
	};

	const getDmaDetails = async () => {
		if (dmaSelector?.dmaFlow?.dmaId) {
			await dispatch(
				getdmaRegisterDetails({ param: '/' + dmaSelector?.dmaFlow?.dmaId })
			)
				.then(unwrapResult)
				.then(async (response: any) => {
					if (response?.status) {
						reset(response?.user);
						if (response?.user?.pincode?.length === 6) {
							fetchStateCityByPincode(
								setValue,
								'city',
								'state',
								setPinCodeData,
								dispatch,
								response?.user?.pincode,
								trigger
							);
						}
					}
				});
		}
	};

	return (
		<IonPage className=''>
			{dmaSelector?.dmaFlow?.dmaId ? (
				<>
					<Header
						name={t('Add DMA')}
						backButton={true}
						needArrow={true}
						needArrowOnClick={() => {
							if (dmaSelector?.dmaFlow?.dmaId) history.push('/dashboard/teams');
						}}
					/>
					<div
						style={{ background: '#EBEBEB' }}
						className='p-3 fs-16 fw-600 d-block d-md-none'
					>
						{t('Enter DMA Detail')}
					</div>
				</>
			) : (
				<Header
					name=''
					backButton={true}
					needArrow={true}
				/>
			)}

			<IonContent className='add-your-details background-web'>
				<div className='bg-web overflowY-md-auto'>
					<div className='bg-web-white container-width-md d-md-flex justify-content-md-between flex-md-column h-100 border-md-radius-10 opacity-1'>
						<div
							style={{ background: '#EBEBEB' }}
							className='p-3 mt-md-3 fs-16 fw-600 d-none d-md-block'
						>
							{t('Enter DMA Detail')}
						</div>
						<div className='overflowY-md-auto'>
							<div className='px-3 pt-3 py-md-3'>
								<div>
									{!dmaSelector?.dmaFlow?.dmaId && (
										<h4 className='ion-no-margin fw-600 darkest-grey mb-3'>
											{t('Add Your Personal Info')}
										</h4>
									)}
									{!dmaSelector?.dmaFlow?.dmaId ? (
										''
									) : (
										<p className='ion-no-margin description'>
											{t('Please add DMA name as per Aadhaar')}
										</p>
									)}
								</div>
								<div>
									{inputData.map(({ name, type, placeholder }: any) => {
										const t_error: any = errors;
										return (
											<Controller
												key={name}
												control={control}
												render={({ field: { onChange, onBlur, name } }) => (
													<CustomInput
														type={type}
														placeholder={t(placeholder)}
														onBlur={onBlur}
														onChange={(e: any) => {
															if (name === 'pincode') {
																if (e.detail.value.length === 6) {
																	fetchStateCityByPincode(
																		setValue,
																		'city',
																		'state',
																		setPinCodeData,
																		dispatch,
																		e.detail.value,
																		trigger
																	);
																	// pinCodeAction(e.detail.value);
																} else {
																	setValue('state', '');
																	setValue('city', '');
																	setPinCodeData([]);
																}
															}
															onChange(e);
														}}
														value={getValues(name)}
														name={name}
														errors={t(t_error[name]?.message as string)}
														onBeforeInput={(evt: any) => {
															if (type == 'number') {
																!/[0-9]/.test(evt.data) && evt.preventDefault();
															}
														}}
														onKeyDown={(e: any) => {
															if (type == 'number') {
																disableNumberArrows(e);
															}
														}}
														onWheel={(e: any) => {
															if (type == 'number') {
																e.target.blur();
															}
														}}
														className={`${
															[
																'first_name',
																'middle_name',
																'last_name',
															].includes(name)
																? 'text-capitalize '
																: ''
														}`}
													/>
												)}
												name={name}
											/>
										);
									})}
									{/* {pinCodeData && pinCodeData.length > 0 && (
									<>
										<IonLabel>{t('City')}</IonLabel>
										<div className='ion-margin-bottom'> */}
									{/* Remove as per client requirement */}
									{/*pinCodeData.map(({ Name }: any) => {
												return (
													<span
														key={Name}
														className={`selected-label selected-label-cursor ${
															Name === pinCodeValue.city
																? ' selected-label-active'
																: ''
														}`}
														onClick={() => {
															setPincodeValue({
																state: pinCodeData[0].State,
																city: Name,
															});
														}}
													>
														{Name}
													</span>
												);
											})*/}
									{/* <span
												key={pinCodeData[0].Region}
												className={`selected-label selected-label-cursor ${
													pinCodeData[0].Region === pinCodeValue.city
														? ' selected-label-active'
														: ''
												}`}
												onClick={() => {
													setPincodeValue({
														state: pinCodeData[0].State,
														city: pinCodeData[0].Region,
													});
												}}
											>
												{pinCodeData[0].Region}
											</span>
										</div>
									</>
								)} */}
									{/* {pinCodeData && pinCodeData.length > 0 && (
									<>
										<IonLabel>{t('State')}</IonLabel>
										<div className='ion-margin-bottom'>
											<span className='selected-label'>
												{pinCodeData[0].State}
											</span>
										</div>
									</>
								)} */}
									<StateCity
										cityInputName='city'
										stateInputName='state'
										control={control}
										setValue={setValue}
										pinCodeData={pinCodeData}
										trigger={trigger}
										getValues={getValues}
									/>
									{/* </form> */}
								</div>
							</div>
							<div className='position-relative mt-2 mt-md-0'>
								<Controller
									control={control}
									render={({ field: { onChange, onBlur, name } }) => (
										<div
											className='d-flex py-3'
											style={{ background: '#EDF8EC' }}
										>
											<div className='position-relative'>
												<CustomCheckbox
													labelPlacement='end'
													name={name}
													isBackGround={true}
													// isChecked={confirmCheck}
													isChecked={getValues('acceptTerms')}
													value={getValues('acceptTerms')}
													onBlur={onBlur}
													onChange={(event: any) => {
														onChange(event);
														setValue('acceptTerms', event.detail.checked);
													}}
													className={
														(errors[name] ? 'ion-checkbox-inValid' : '') +
														' pt-1 px-3'
													}
													errorText={t(
														errors['acceptTerms']?.message as string
													)}
													errorTextClass='position-absolute checkbox-style1'
												/>
											</div>
											<ConsentCheckbox isMoreContent={isMoreContent} />
										</div>
									)}
									name={'acceptTerms'}
								/>
								<div
									onClick={() => {
										setMoreContent(!isMoreContent);
									}}
									className='position-absolute right-0 ion-padding py-1 text-primary fs-12 cursor-pointer fw-600'
								>
									{isMoreContent ? `- ${t('Less')}` : `+ ${t('More')}`}
								</div>
							</div>
						</div>
						<div className='d-none d-md-block bg-web-white position-relative'>
							{dmaSelector?.dmaFlow?.dmaId && (
								<div className=' ion-padding reject-continue-later  d-flex gap-3 my-2'>
									<CustomButton
										onClick={() => {
											setRejectionModal(true);
										}}
										fill={'outline'}
										title={t('Reject')}
										className={'w-100'}
									/>
									<CustomButton
										fill={'outline'}
										title={t('Continue Later')}
										onClick={continueLater}
										className={'w-100'}
									/>
								</div>
							)}
							<div
								className={` ion-padding ${
									dmaSelector?.dmaFlow?.dmaId && 'pt-0'
								}`}
							>
								<CustomButton
									expand='block'
									disabled={!isValid}
									className={'btn-blue'}
									// routerLink='/terms-conditions'
									title={t('Proceed')}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				</div>
			</IonContent>

			<IonFooter className='add-firm-details-button'>
				<div className='d-block d-md-none position-relative'>
					{dmaSelector?.dmaFlow?.dmaId && (
						<div className=' ion-padding reject-continue-later  d-flex gap-3 my-2'>
							<CustomButton
								onClick={() => {
									setRejectionModal(true);
								}}
								fill={'outline'}
								title={'Reject'}
								className={'w-100'}
							/>
							<CustomButton
								fill={'outline'}
								title={'Continue Later'}
								onClick={continueLater}
								className={'w-100'}
							/>
						</div>
					)}
					<div
						className={` ion-padding ${dmaSelector?.dmaFlow?.dmaId && 'pt-0'}`}
					>
						<CustomButton
							expand='block'
							disabled={!isValid}
							className={'btn-blue'}
							// routerLink='/terms-conditions'
							title={t('Proceed')}
							onClick={handleSubmit(onSubmit)}
						/>
					</div>
				</div>
			</IonFooter>
			<CustomModal
				id='rejectionmodal'
				isOpen={rejectionModal}
				// initialBreakpoint={0.4}
				initialBreakpoint={isWeb ? 1 : 0.4}
				handleClose={() => setRejectionModal(false)}
				breakpoints={[0, 0.4, 0.6, 0.75]}
				className='side-modal'
				modalContent={
					<RejectionModal
						title={
							dmaSelector?.user_details?.roleType?.[0] === USER_ROLES.BUH
								? 'Request Revision'
								: 'Reason for Rejection'
						}
						reasonNeeded={
							dmaSelector?.user_details?.roleType?.[0] === USER_ROLES.BUH
								? false
								: true
						}
						resetPinCodeData={() => {
							setPinCodeData([]);
							// setPincodeValue({});
						}}
						setRejectionModal={setRejectionModal}
					/>
				}
				needCross={false}
				needArrow={false}
			/>
		</IonPage>
	);
};
