import "./BusyLoader.scss";
import { useBusyLoading } from "../../../context/BusyLoadingContext";
const BusyLoader = () => {
  const { isBusy } = useBusyLoading();

  if (!isBusy) {
    return null;
  }
  return <div id="cover-spin"></div>;
};

export default BusyLoader;
