import { IonPage, IonContent, useIonViewWillEnter } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Header } from '../../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import LeadContactCard from '../../../components/common/lead-contact-card/LeadConatctCard';
import { StatusNum } from '../../../helpers/constant';
import { CustomNoData } from '../../../components/common/custom-no-data/CustomNoData';
import { useDispatch, useSelector } from 'react-redux';
import {
	dmaFlowData,
	getdmaList,
} from '../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { CustomSearchbar } from '../../../components/common/custom-searchbar/CustomSearchbar';
import { sanitizeHtml } from '../../../helpers/functions';

export const DmaList: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const [dmalist, setDmaList] = useState<any>([]);
	const [dmaListStatus, setDmaListStatus] = useState<any>();
	const [title, setTitle] = useState('');
	const dmaSelector = useSelector((state: any) => state?.users);
	const dispatch = useDispatch();

	const getList = async (searchInput = '') => {
		await dispatch(
			getdmaList({
				param: `?pageId=1&size=50&status=${
					dmaSelector?.dmaFlow?.dmaCode || dmaListStatus
				}&searchItem=${sanitizeHtml(encodeURIComponent(searchInput))}`,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setDmaList(response?.list);
				}
			});
	};
	useIonViewWillEnter(() => {
		setDmaListStatus(dmaSelector?.dmaFlow?.dmaCode);
		getList();
	}, []);

	const handleSearch = async (search: string) => {
		await getList(search);
	};

	const cardClick = async ({
		id,
		status,
		list_status = dmaSelector?.dmaFlow?.dmaCode || dmaListStatus,
	}: any) => {
		if (
			list_status === StatusNum.VERIFICATION ||
			list_status === StatusNum.VERIFICATION1 ||
			list_status === StatusNum.VERIFICATION2 ||
			list_status === StatusNum.REWORKCOMPLETED
		) {
			await dispatch(
				dmaFlowData({
					...dmaSelector?.dmaFlow,
					dmaId: id,
					dmaStatus: status,
					dmaListStatus: list_status,
				})
			);
			history.push('/dma-verify-steps');
		} else if (list_status === StatusNum.UNDERPROCESS) {
			await dispatch(
				dmaFlowData({
					...dmaSelector?.dmaFlow,
					dmaId: id,
					dmaStatus: status,
					dmaListStatus: list_status,
				})
			);
			history.push('/dma-verification-status');
		} else if (list_status === StatusNum.APPROVED) {
			await dispatch(
				dmaFlowData({
					...dmaSelector?.dmaFlow,
					dmaId: id,
					dmaStatus: status,
					dmaListStatus: list_status,
				})
			);
			history.push('/dma-approved-info');
		}
	};
	useEffect(() => {
		const ch = dmaSelector?.dmaFlow?.dmaCode;
		let _title = '';
		switch (ch) {
			case StatusNum.VERIFICATION:
				_title = t('DMA Verification');
				break;
			case StatusNum.REWORKCOMPLETED:
				_title = t('Rework Completed');
				break;
			case StatusNum.UNDERPROCESS:
				_title = t('Under Process');
				break;
			case StatusNum.APPROVED:
				_title = t('Approved');
				break;
			default:
				break;
		}
		setTitle(_title);
	}, [dmaSelector?.dmaFlow?.dmaCode]);

	return (
		<IonPage>
			<Header
				needArrow={true}
				backButton={true}
				name={t(title)}
				needArrowOnClick={() => history.replace('/dashboard/teams')}
			/>
			<IonContent>
				<div className='p-3 d-flex align-items-center w-100'>
					<CustomSearchbar
						searchIcon='/images/icons/searchbar-icon.svg'
						placeholder='Search DMA by “Name”'
						showClearButton='focus'
						onChange={handleSearch}
						needFilter={true}
					/>
				</div>
				{dmalist.length ? (
					<LeadContactCard
						showStatus={
							dmaSelector?.dmaFlow?.dmaCode === StatusNum.UNDERPROCESS
								? true
								: false
						}
						showTag={false}
						leads={dmalist}
						onClick={cardClick}
					/>
				) : (
					<CustomNoData />
				)}
			</IonContent>
		</IonPage>
	);
};
