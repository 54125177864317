/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Header } from '../../components/layout/header/Header';
import {
	IonContent,
	IonTextarea,
	IonPage,
	IonFooter,
	useIonViewDidLeave,
	IonImg,
} from '@ionic/react';
import './UpdateStatus.scss';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import CustomNote from '../../components/common/custom-note/CustomNote';
import '../../components/common/custom-input/CustomInput.scss';
import './UpdateStatus.scss';
import DropDownModal from '../../components/common/custom-dropdown/DropDownModal';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
	getMasterDispositionReasons,
	getMasterDispositionsUpdateStage,
	leadFlow,
	leadLenderCallBack,
	markAsClose,
	updateLeadStatus,
} from '../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { LEAD_STATUS } from '../../helpers/constant';
import {
	//ContinueLaterPopUp,
	FormatDate,
	findCurrentStatus,
	sanitizeHtml,
} from '../../helpers/functions';
import { Divider } from '../../components/common/divider/Divider';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
//import { CustomModal } from '../../components/common/custom-modal/CustomModal';

export const CloseAndDispositionForm: React.FC = () => {
	const [reasonData, setReasonData] = useState<any>([]);
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();
	const [listUpdate, setListUpdate] = useState(0);
	const [dispositionsReasons, setDispositionsReasons] = useState<any>([]);
	const dispatch = useDispatch();
	const leadFlowSelector = useSelector((state: any) => state?.lead);
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	const token = useSelector(
		(state: any) => state.users.user_details.access_token
	);
	const bulkIds = leadFlowSelector?.leadFlowData?.getLeadAllSelectedIds;
	const appsToUpdate = leadFlowSelector?.leadFlowData?.appsToUpdate;
	const bulkLenderIdes = leadFlowSelector?.selectedLender?.map(
		({ lender_id }: any) => lender_id
	);
	const closeReason = leadInfo?.isClosedStage === LEAD_STATUS.CLOSED;
	//const [modalPageClose, setModalPageClose] = useState(false);

	const updateStageSchema = yup.object().shape({
		disposition_id: !closeReason
			? yup.string().notRequired()
			: yup.string().trim().required('Please select disposition'),
		disposition_reason: yup
			.string()
			.trim()
			.required('Please select disposition reason'),
		date: closeReason
			? yup.date().notRequired()
			: yup
					.date()
					.typeError('Please enter valid date')
					.required('Date is a required field')
					.test(
						'date',
						'Please enter valid date',
						(date) => date.getFullYear() <= 9999
					),
		remark: yup.string().trim().required('Please enter comment'),
	});

	const {
		control,
		handleSubmit,
		reset,
		setValue,
		getValues,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(updateStageSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			status: '',
			disposition_id: '',
			disposition_reason: '',
			remark: '',
			date: '',
		},
	});

	useEffect(() => {
		if (location.pathname !== '/close-and-disposition-form') {
			return;
		}
		getBulkUpdateLead();
		getMasterDispReasons();

		//setModalPageClose(false);
		if (closeReason) {
			reset({
				disposition_id: '',
				disposition_reason: '',
				remark: '',
			});
		} else {
			reset({
				disposition_id: LEAD_STATUS.CALLBACKFOLLOWUP,
				date: '',
				disposition_reason: '',
				remark: '',
			});
		}

		// setValue('remark', '');
	}, [leadInfo.update, location.pathname]);

	const getBulkUpdateLead = async () => {
		await dispatch(getMasterDispositionsUpdateStage())
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setReasonData(response?.data);
				}
			});
	};

	const getMasterDispReasons = async () => {
		await dispatch(
			getMasterDispositionReasons({
				param: '4',
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setDispositionsReasons(response?.data);
				}
			});
	};

	useIonViewDidLeave(() => {
		setDispositionsReasons([]);
	});

	const onSubmit = async (data: any) => {
		setListUpdate(listUpdate + 1);

		if (leadFlowSelector?.selectedLender?.length > 0) {
			await dispatch(
				leadLenderCallBack({
					param: leadFlowSelector?.selectedLender[0]?.lead_id,
					data: {
						lender_id: bulkLenderIdes,
						disposition_id: data.disposition_id,
						disposition_reason: data.disposition_reason,
						call_date: FormatDate(data.date, 'mm/dd/yyyy HH:MM:ss'),
						status: closeReason
							? LEAD_STATUS?.APPLICATION_CLOSED
							: findCurrentStatus(
									leadFlowSelector?.leadFlowData?.applicationStatus
							  ).id,
						remark: sanitizeHtml(data?.remark),
					},
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						setDispositionsReasons([]);
						dispatch(markAsClose(false));
						if (leadInfo.leadStatus === LEAD_STATUS.SELECTION) {
							history.push('/lender-specific-info');
						} else {
							if (leadInfo.redirectToDetailPage) {
								if (leadInfo?.from === 'nimbuss') {
									history.push(
										`/lead-details/${leadInfo?.leadId + '/' + token}`
									);
								} else {
									history.push('/lead-details');
								}
							} else {
								history.push('/lender-specific-info');
							}
						}
					}
				});
		} else {
			await dispatch(
				updateLeadStatus({
					data: {
						data: Array.isArray(bulkIds) ? bulkIds : [bulkIds],
						status: closeReason
							? LEAD_STATUS?.CLOSED
							: findCurrentStatus(leadFlowSelector?.leadFlowData?.leadStatus)
									.id === LEAD_STATUS.ALL
							? findCurrentStatus(leadFlowSelector?.leadFlowData?.lead_status)
									.id
							: findCurrentStatus(leadFlowSelector?.leadFlowData?.leadStatus).id
							? findCurrentStatus(leadFlowSelector?.leadFlowData?.leadStatus).id
							: LEAD_STATUS?.CLOSED,
						appsToUpdate: appsToUpdate || [],
						disposition_id: parseInt(data.disposition_id),
						disposition_reason: data.disposition_reason.toString(),
						remark: data.remark,
						date: FormatDate(data.date, 'mm/dd/yyyy HH:MM:ss'),
					},
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						setDispositionsReasons([]);
						dispatch(markAsClose(false));
						if (leadInfo?.from === 'nimbuss') {
							history.push(`/lead-details/${leadInfo?.leadId + '/' + token}`);
						} else {
							history.push('/dashboard/lead', { listUpdate: listUpdate });
						}
					}
				});
		}
	};
	// const dmaSelector = useSelector((state: any) => state?.users);

	const handleBackBtn = () => {
		const myPromise = new Promise(function (myResolve) {
			setTimeout(function () {
				myResolve(history.push('/update-status'));
			}, 10);
		});
		myPromise.then(() => {
			dispatch(
				leadFlow({
					...leadInfo,
					isClosedStage: '',
					update: leadInfo?.update + 1 || 1,
				})
			);
		});
	};

	return (
		<IonPage>
			<Header
				name={t('')}
				// needCross={false}
				needArrow={true}
				needArrowOnClick={() => {
					handleBackBtn();
				}}
				backButton={true}
			/>
			<IonContent className=''>
				<div
					className=' bg-web py-md-3'
					style={{ minHeight: '100%' }}
				>
					<div className='container-width-md conatiner-spacing-md py-3 bg-web-white border-md-radius-10'>
						<CustomNote
							{...{
								header: t('Note'),
								content: t(
									'Use the Update Status In Bulk option to update status to different stages'
								),
							}}
						/>
						<div>
							<div className='d-flex justify-content-between align-items-center mb-4 mt-3'>
								<h6
									className='fs-14 fw-bold mb-0'
									style={{ color: 'var(--text-dark-grey)' }}
								>
									{t('Current Stage')}
								</h6>
								{leadFlowSelector?.selectedLender?.length <= 1 && (
									<CustomButton
										className={'btn-blue fw-600'}
										fill='outline'
										style={{ height: '24px' }}
										title={t(
											findCurrentStatus(
												leadFlowSelector?.leadFlowData?.applicationStatus
													? leadFlowSelector?.leadFlowData?.applicationStatus
													: leadFlowSelector?.leadFlowData?.leadStatus ===
													  LEAD_STATUS.ALL
													? leadFlowSelector?.leadFlowData?.lead_status
													: leadFlowSelector?.leadFlowData?.leadStatus
											).label
										)}
									/>
								)}
							</div>
							<Divider />
						</div>
						<form onSubmit={handleSubmit(onSubmit)}>
							{closeReason ? (
								<>
									<div>
										<Controller
											control={control}
											render={({ field: { onBlur, name, onChange } }) => (
												<DropDownModal
													data={reasonData}
													name={name}
													value={getValues(name)}
													className={`${
														errors?.disposition_id ? 'mb-1' : 'mb-3'
													}`}
													onChange={async (e: any) => {
														onChange(e);
														setValue('disposition_reason', '');

														await dispatch(
															getMasterDispositionReasons({
																param: e.detail.value,
															})
														)
															.then(unwrapResult)
															.then((response: any) => {
																if (response?.status) {
																	setDispositionsReasons(response?.data);
																}
															});
													}}
													label={t('Select Disposition')}
													onBlur={onBlur}
													// errors={errors[name]?.message}
												/>
											)}
											name={'disposition_id'}
										/>
										{errors && errors?.disposition_id && (
											<div className=''>
												<div className='error-text sc-ion-input-md fs-12'>
													{t(`${errors?.disposition_id?.message}`)}
												</div>
											</div>
										)}
									</div>
									<div>
										<Controller
											control={control}
											render={({ field: { onBlur, name, onChange } }) => {
												return (
													<DropDownModal
														className={`${
															errors?.disposition_reason ? 'mb-1' : 'mb-3'
														}`}
														data={dispositionsReasons}
														name={name}
														value={getValues(name)}
														onChange={onChange}
														label={t('Select Disposition Reason')}
														onBlur={onBlur}
													/>
												);
											}}
											name={'disposition_reason'}
										/>
										{errors && errors?.disposition_reason && (
											<div className=''>
												<div className='error-text sc-ion-input-md fs-12'>
													{t(`${errors?.disposition_reason?.message}`)}
												</div>
											</div>
										)}
									</div>
									<div>
										<Controller
											control={control}
											render={({ field: { onChange, onBlur, name } }) => {
												return (
													<IonTextarea
														className='lead-update-status-textarea mt-2 mb-1'
														// value=''
														value={getValues(name)}
														fill='outline'
														placeholder={`${t('Add Comment')} *`}
														maxlength={300}
														labelPlacement='fixed'
														// onIonInput={onChange}
														// onIonChange={onChange}
														onIonInput={onChange}
														rows={3}
														onBlur={onBlur}
														name={name}
														mode='md'
													/>
												);
											}}
											name={'remark'}
										/>
										{errors && errors?.remark && (
											<div className=''>
												<div className='error-text sc-ion-input-md fs-12'>
													{t(`${errors?.remark?.message}`)}
												</div>
											</div>
										)}
									</div>
								</>
							) : (
								<>
									<div>
										<Controller
											control={control}
											render={({ field: { onBlur, name, onChange } }) => {
												return (
													<DropDownModal
														className={`${
															errors?.disposition_reason ? 'mb-1' : 'mb-3'
														}`}
														data={dispositionsReasons}
														name={name}
														value={getValues(name)}
														onChange={onChange}
														label={t('Select Disposition Reason')}
														onBlur={onBlur}
													/>
												);
											}}
											name={'disposition_reason'}
										/>
										{errors && errors?.disposition_reason && (
											<div className=''>
												<div className='error-text sc-ion-input-md fs-12'>
													{t(`${errors?.disposition_reason?.message}`)}
												</div>
											</div>
										)}
									</div>
									<div>
										<Controller
											control={control}
											render={({ field: { onChange, onBlur, name } }) => (
												<CustomInput
													type={'datetime-local'}
													label={t('Date')}
													onBlur={onBlur}
													id={'datetime'}
													needSuffixIcon={true}
													suffixClassName={
														'position-absolute date-time-sufix-icon'
													}
													name={name}
													suffixText={
														<IonImg
															src='/images/icons/input-dob-calendar-icon.svg'
															alt='Date Time'
														/>
													}
													onChange={onChange}
													value={getValues(name)}
													outerClassName={`position-relative`}
												/>
											)}
											name={'date'}
										/>
										{errors && errors?.date && (
											<div className=''>
												<div className='error-text sc-ion-input-md fs-12'>
													{t(`${errors?.date?.message}`)}
												</div>
											</div>
										)}
									</div>
									<div>
										<Controller
											control={control}
											render={({ field: { name, onChange, onBlur } }) => {
												return (
													<IonTextarea
														className='lead-update-status-textarea mt-2 mb-1'
														value={getValues(name)}
														fill='outline'
														placeholder={t('Add Comment') + ' *'}
														maxlength={300}
														labelPlacement='fixed'
														onIonInput={onChange}
														// onIonChange={onChange}
														rows={3}
														onBlur={onBlur}
														name={name}
														mode='md'
													/>
												);
											}}
											name={'remark'}
										/>
										{errors && errors?.remark && (
											<div className=''>
												<div className='error-text sc-ion-input-md fs-12'>
													{t(`${errors?.remark?.message}`)}
												</div>
											</div>
										)}
									</div>
								</>
							)}
							<div className='d-none d-md-block py-4'>
								<CustomButton
									disabled={!isValid}
									expand='block'
									className='w-100 fw-bold dashboard-add-lead-button'
									title={t('Update')}
									type={'submit'}
									/*onClick={async () => {
										closeReason
											? await onSubmit(getValues())
											: setModalPageClose(true);
									}}*/
								/>
							</div>
						</form>
					</div>
				</div>
				{/*<CustomModal
					needIonContentWraper={false}
					isOpen={modalPageClose}
					initialBreakpoint={undefined}
					handleClose={() => setModalPageClose(false)}
					breakpoints={[]}
					className='height-auto confirm-popup-middle continue-later'
					modalContent={ContinueLaterPopUp(
						() => {
							setModalPageClose(false);
						},
						async () => {
							await onSubmit(getValues());
							setModalPageClose(false);
						},
						'Cancel',
						'Proceed',
						'className',
						'Confirm',
						`${
							leadFlowSelector?.leadFlowData?.call_date
								? t(
										'A callback is already set for this lead on ' +
											FormatDate(
												leadFlowSelector?.leadFlowData?.call_date?.split(
													'.'
												)[0],
												'dd/mm/yyyy HH:MM:ss'
											) +
											'. Proceed to overwrite?'
								  )
								: t('Are you sure you want to save the callback?')
						}`
					)}
					needCross={false}
					needArrow={false}
				/>*/}
			</IonContent>
			<IonFooter className='p-3 d-block d-md-none'>
				<CustomButton
					disabled={!isValid}
					onClick={handleSubmit(onSubmit)}
					/*onClick={async () => {
						closeReason ? await onSubmit(getValues()) : setModalPageClose(true);
					}}*/
					expand='block'
					className='w-100 fw-bold dashboard-add-lead-button'
					title={t('Update')}
				/>
			</IonFooter>
		</IonPage>
	);
};
