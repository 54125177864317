import React, { useEffect, useState } from 'react';
import { Header } from '../../components/layout/header/Header';
import {
	IonCard,
	IonContent,
	IonGrid,
	IonRow,
	IonCol,
	IonImg,
	IonItem,
	IonFooter,
	IonPage,
	IonCardContent,
	IonLabel,
	IonCheckbox,
} from '@ionic/react';
import './SelectedLenders.scss';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import { CustomSearchbar } from '../../components/common/custom-searchbar/CustomSearchbar';

const data: any = [
	{
		bank_name: 'SBI',
		bank_int_rate: 9.5,
		isOnline: true,
		isRecommended: false,
		isSelected: true,
		status: false,
		id: 1,
	},
	{
		bank_name: 'Kotak Bank',
		bank_int_rate: 8.5,
		isOnline: false,
		isRecommended: true,
		isSelected: true,
		status: false,
		id: 2,
	},
	{
		bank_name: 'HDFC Bank',
		bank_int_rate: 8.5,
		isOnline: true,
		isRecommended: true,
		isSelected: true,
		status: false,
		id: 3,
	},
	{
		bank_name: 'Canara Bank',
		bank_int_rate: 8.5,
		isOnline: true,
		isRecommended: false,
		status: false,
		id: 4,
	},
	{
		bank_name: 'Canara Bank',
		bank_int_rate: 8.5,
		isOnline: false,
		isRecommended: false,
		status: false,
		id: 5,
	},
	{
		bank_name: 'Canara Bank',
		bank_int_rate: 8.5,
		isOnline: false,
		isRecommended: false,
		status: false,
		id: 6,
	},
	{
		bank_name: 'Canara Bank',
		bank_int_rate: 8.5,
		isOnline: false,
		isRecommended: false,
		isSelected: false,
		status: false,
		id: 7,
	},
	{
		bank_name: 'Canara Bank',
		bank_int_rate: 8.5,
		isOnline: false,
		isRecommended: false,
		isSelected: true,
		status: false,
		id: 8,
	},
	{
		bank_name: 'Canara Bank',
		bank_int_rate: 8.5,
		isOnline: false,
		isRecommended: false,
		isSelected: true,
		status: false,
		id: 9,
	},
];

export const SelectedLenders: React.FC = () => {
	const [deleteLenderModal, setDeleteLenderModal] = useState(false);
	const { t } = useTranslation();

	// const [serachData, setSearchData] = useState([]);
	const [selectedData, setSelectedData] = useState<any>(data);
	const [serachData, setSearchData] = useState<any>([]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [value, setValue] = useState('');
	// const activeData = value ? serachData : selectedData;
	const status = true;

	useEffect(() => {
		setSelectedData(data);
	}, []);

	const cardClick = (event: any) => {
		const storeResult = selectedData.map((activeData: any) => {
			if (event.target.id === activeData.id.toString()) {
				return {
					...activeData,
					status: !activeData.status,
				};
			} else {
				return {
					status: activeData.status === undefined ? false : activeData.status,
					...activeData,
				};
			}
		});
		setSelectedData(storeResult);
		setSearchData(storeResult);
	};

	const slectedBank = selectedData.filter(({ status }: any) => status).length;
	return (
		<IonPage>
			{/* <Menu /> */}
			<Header
				name={t('All Lenders')}
				needArrow={true}
				backButtonDisabled={false}
				backButton={true}
			/>
			<IonContent>
				<div className='position-relative mx-3 mt-3'>
					{/* <CustomInput
						type={'text'}
						// label={'Search'}
						placeholder={'Search by Lender'}
						className={'serach-input mt-2 mb-2'}
						onChange={(e: any) => {
							setValue(e.detail.value);
							if (e.detail.value !== '') {
								const results: any = data.filter((item: any) =>
									item.bank_name
										.toLowerCase()
										.includes(e.detail.value.toLowerCase())
								);
								const finalResult = results.map((item: any) => {
									const finResult = selectedData.some(
										(test: any) => test.id === item.id
									);
									return {
										...item,
										status: finResult?.status,
									};
								});
								setSelectedData(finalResult);
								// setSearchData();
							} else {
								const finalResult = data.map((item: any) => {
									const finResult = selectedData.some(
										(test: any) => test.id === item.id
									);
									return {
										...item,
										status: finResult?.status,
									};
								});
								setSelectedData(finalResult);
							}
						}}
						value={value}
						needSuffixIcon={true}
					/> */}
					{/* <IonIcon
						icon={search}
						className='position-absolute'
						style={{
							top: '14px',
							left: '16px',
							color: '#595959',
						}}
					/> */}
					<CustomSearchbar
						searchIcon='/images/icons/searchbar-icon.svg'
						placeholder={t('Search by “Lenders”')}
						showClearButton='focus'
						onChange={(value: any) => {
							setValue(value);
							if (value !== '') {
								if (value.length >= 3) {
									const results: any = data.filter((item: any) =>
										item.bank_name.toLowerCase().includes(value.toLowerCase())
									);
									const finalResult = results.map((item: any) => {
										const finResult = selectedData.some(
											(test: any) => test.id === item.id
										);
										return {
											...item,
											status: finResult?.status,
										};
									});
									setSelectedData(finalResult);
									// setSearchData();
								}
							} else {
								const finalResult = data.map((item: any) => {
									const finResult = selectedData.some(
										(test: any) => test.id === item.id
									);
									return {
										...item,
										status: finResult?.status,
									};
								});
								setSelectedData(finalResult);
							}
						}}
					/>
				</div>

				<div className='d-flex justify-content-end'>
					<CustomButton
						fill='clear'
						routerLink='/'
						className='fs-14 fw-600 mb-1 '
						title={t('Add More Lenders')}
					/>
				</div>

				<IonGrid className='pb-180'>
					<IonRow>
						{selectedData &&
							selectedData?.map((item: any, index: number) => {
								const findStatus: any = serachData.find(
									(test: any) => test.id === item.id
								);

								return (
									<IonCol
										size='6'
										key={index}
										// itemID={serachData[index]?.id}
										// id={item.id}
										style={{
											zIndex: 2,
										}}
									>
										<IonCard
											// id={item.id}
											// onClick={cardClick}
											className={`m-0 blue-corner-linerar-gradient
                      ${
												item?.isOnline && item?.isRecommended
													? 'online-recommended'
													: ''
											}${
												!item?.isOnline && item?.isRecommended
													? 'offline-recommended'
													: ''
											}
                      ${item?.isOnline && !item?.isRecommended ? 'online' : ''}
                  `}
										>
											<IonCardContent
												className='d-flex justify-content-center align-items-center flex-column'
												id={item.id}
											>
												<div className='d-flex w-100 justify-content-between align-items-center'>
													<div className='d-flex'>
														<IonCheckbox
															name={item.bank_name}
															value={item.id}
															checked={findStatus?.status}
															style={{ zIndex: -11111 }}
														/>
													</div>
													<div>
														<IonImg
															alt='img'
															className='bankimg m-0'
															src={`/images/icons/leads/${
																item.status ? 'crown-marked' : 'crown'
															}.svg`}
															style={{
																width: '20px',
																height: '20px',
															}}
														/>
													</div>
												</div>
												<div className='selected-lender-bank-icon-container'>
													<div className='selected-lender-bank-icon-border'>
														<IonImg
															alt='img'
															className='bankimg selected-lender-bank-icon m-0'
															style={{
																width: '24px',
																height: '24px',
															}}
															src='/images/icons/leads/sbi-bank.svg'
														/>
													</div>
												</div>

												<IonLabel className='fw-900 my-2'>
													{item.bank_name}
												</IonLabel>
												<IonLabel
													className='mb-2 mt-0'
													style={{ color: 'var(--text-dark-grey)' }}
												>
													8.9%p.a
												</IonLabel>
												<span
													className={`fs-12 py-1 px-2 selected-lender-status-${
														!status ? 'online' : 'offline'
													}`}
												>
													{!status ? 'online' : 'offline'}
												</span>
											</IonCardContent>
											<div
												id={item.id}
												style={{
													background: 'transparent',
													height: '100%',
													width: '100%',
													position: 'absolute',
													top: 0,
													zIndex: 111111,
												}}
												onClick={cardClick}
											/>
										</IonCard>
									</IonCol>
								);
							})}
					</IonRow>
				</IonGrid>
			</IonContent>
			{/* AssignModal */}
			<IonFooter>
				<div
					className='ion-padding'
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					{/* <CustomButton
						className='w-100 fw-bold dashboard-add-lead-button'
						fill='outline'
						title={t('Continue Later')}
					/> */}
					<div
						style={{ flex: '80%' }}
						className='fw-900'
					>
						{slectedBank} {t('Selected')}
					</div>
					<CustomButton
						// routerLink='/comman-info'
						className='w-100 fw-bold dashboard-add-lead-button'
						expand='block'
						title={t('Proceed')}
					/>
				</div>
			</IonFooter>
			<CustomModal
				id='custommodal'
				isOpen={deleteLenderModal}
				className='deletelender'
				modalTitle={t('Update Stage')}
				initialBreakpoint={undefined}
				handleClose={() => setDeleteLenderModal(false)}
				breakpoints={[]}
				modalContent={
					<div className='wrapper pt-3'>
						<IonItem lines='none'>
							<div className='d-flex flex-column'>
								<h5 className='mx-0 fs-20 fw-600'>
									{t('Are you sure you want to remove this lender?')}
								</h5>
							</div>
						</IonItem>
						<div className='d-flex justify-content-center gap-3 px-3 mt-3'>
							<CustomButton
								onClick={() => setDeleteLenderModal(false)}
								className='w-50'
								fill='outline'
								title={t('Cancel')}
							/>
							<CustomButton
								className='w-50'
								fill='solid'
								title={t('Remove')}
							/>
						</div>
					</div>
				}
				needCross={false}
				needArrow={false}
			/>
		</IonPage>
	);
};
