import { IonImg } from '@ionic/react';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { CustomInput } from '../../../../components/common/custom-input/CustomInput';
import { DynamicDropdown } from '../../../../components/common';
import { coApplicantFormOne } from './TataHlLAP';
import { fetchMasterData } from '../../../../store/reducers/lead/leadSlice';

export const CoApplicantForm1: React.FC<any> = ({
	control,
	getValues,
	errors,
}) => {
	const [genderList, setGender] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		getDataFromMaster();
	}, []);

	const getDataFromMaster = async () => {
		await dispatch(fetchMasterData({ param: 'GENDER' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setGender(result?.data);
				}
			});
	};

	/**
	 * Set dropdown data
	 * @param name field name
	 */
	const setDropDownOptions = (name: string) => {
		switch (name) {
			case 'gender':
				return genderList;
		}
	};

	return (
		<>
			{coApplicantFormOne.map(
				({ name, type, placeholder }: any, index: any) => {
					const errorText: any =
						errors && Object.keys(errors).length > 0 && errors;
					return (
						<div key={index}>
							{type === 'dropdown' ? (
								<div style={{ marginBottom: '15px' }}>
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, name } }) => (
											<DynamicDropdown
												label={t(`${placeholder}`)}
												data={setDropDownOptions(name)}
												name={name}
												onChange={(e: any) => {
													onChange(e);
												}}
												bindField={'value'}
												showField1={'value'}
												value={getValues(name)}
												onBlur={onBlur}
												errors={t(errorText[name]?.message)}
											/>
										)}
										name={name}
									/>
								</div>
							) : name === 'dob' ? (
								<Controller
									control={control}
									render={({ field: { onChange, onBlur, name } }) => (
										<CustomInput
											type={type}
											needSuffixIcon={true}
											suffixClassName={'position-absolute date-of-birth-sufix'}
											suffixText={
												<IonImg
													src='/images/icons/input-dob-calendar-icon.svg'
													alt='dob'
												/>
											}
											outerClassName={'position-relative'}
											label={t(placeholder)}
											onBlur={onBlur}
											id={name}
											onChange={(e: any) => {
												onChange(e);
											}}
											value={getValues(name)}
											errors={t(errorText[name]?.message || '')}
										/>
									)}
									name={name}
								/>
							) : (
								<Controller
									control={control}
									render={({ field: { onChange, onBlur } }) => (
										<CustomInput
											type={type}
											label={t(placeholder)}
											onBlur={onBlur}
											id={name}
											onChange={(e: any) => {
												onChange(e);
											}}
											value={getValues(name)}
											className={`${name === 'pan_no' ? 'text-uppercase' : ''}${
												['first_name', 'middle_name', 'last_name'].includes(
													name
												)
													? ' text-capitalize '
													: ''
											}`}
											errors={t(errorText[name]?.message)}
										/>
									)}
									name={name}
								/>
							)}
						</div>
					);
				}
			)}
		</>
	);
};
