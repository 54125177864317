import { IonContent, IonFooter } from '@ionic/react';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	fetchMasterData,
	lenderSpfDetailsFlow,
	saveLenderSpcfInfo,
} from '../../../../store/reducers/lead/leadSlice';
import { CustomInput } from '../../../../components/common/custom-input/CustomInput';
import {
	ContinueLaterPopUp,
	checkFieldsValidToContinue,
	showToastAlert,
} from '../../../../helpers/functions';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import { applicantFormOneSchema, applicantFormOne } from './TataBl';
import { LenderNameForAPICall } from '../../../../helpers/lenders';
import { DynamicDropdown } from '../../../../components/common';
import { OfferRejectedMsg } from '../..';

export const PrimaryApplicantForm1: React.FC<any> = ({
	setSelectedTabValue,
	setValidForm,
}) => {
	const [modalPageClose, setModalPageClose] = useState(false);
	const [customerType, setCustomerType] = useState<any>([]);
	const [maritalStatus, setMaritalStatus] = useState([]);
	const [religions, setReligions] = useState([]);
	const [category, setCateogry] = useState([]);
	const [educationQualification, setEducationQualification] = useState([]);
	const [natureOfBusiness, setNatureOfBusiness] = useState([]);
	const [employmentIndustry, setEmploymentIndustry] = useState([]);
	const [accommodationType, setAccommodationType] = useState([]);
	const [correspondingFinancialYear, setCorrespondingFinancialYear] = useState(
		[]
	);
	const [contitution, setContitution] = useState([]);
	// const [personalContitution, setPersonalContitution] = useState([]);
	// const [companyContitution, setCompanyContitution] = useState([]);
	const [occupationContitution, setOccupationContitution] = useState([]);
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const history = useHistory();
	const dispatch = useDispatch();
	const { selectedLender, lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);

	const {
		handleSubmit,
		control,
		getValues,
		// setValue,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(applicantFormOneSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			customer_type:
				lenderSpecificDetails?.lender_spf_details?.customer_type || '',
			marital_status:
				lenderSpecificDetails?.lender_spf_details?.marital_status || '',
			religion: lenderSpecificDetails?.lender_spf_details?.religion || '',
			category: lenderSpecificDetails?.lender_spf_details?.category || '',
			education: lenderSpecificDetails?.lender_spf_details?.education || '',
			nature_of_business:
				lenderSpecificDetails?.lender_spf_details?.nature_of_business || '',
			employment_industry:
				lenderSpecificDetails?.lender_spf_details?.employment_industry || '',
			accommodation_type:
				lenderSpecificDetails?.lender_spf_details?.accommodation_type || '',
			corresponding_financial_year:
				lenderSpecificDetails?.lender_spf_details
					?.corresponding_financial_year || '',
			// company_constitution_code:
			// 	lenderSpecificDetails?.lender_spf_details?.company_constitution_code ||
			// 	'',
			// constitution_personal:
			// 	lenderSpecificDetails?.lender_spf_details?.constitution_personal || '',
			constitution_code:
				lenderSpecificDetails?.lender_spf_details?.constitution_code || '',
			occupation_constitution_code:
				lenderSpecificDetails?.lender_spf_details
					?.occupation_constitution_code || '',
			mother_first_name:
				lenderSpecificDetails?.lender_spf_details?.mother_first_name || '',
			mother_last_name:
				lenderSpecificDetails?.lender_spf_details?.mother_last_name || '',
		},
	});

	useEffect(() => {
		getDataFromMaster();
		// if (getValues('customer_type')) {
		// 	getPersonalConstitution(getValues('customer_type'));
		// }
	}, []);

	useEffect(() => {
		setValidForm(isValid);
	}, [isValid]);

	const getDataFromMaster = () => {
		[
			'TATA_CUSTOMER_TYPE',
			'TATABL_MARITIAL_STATUS',
			'TATABL_RELIGION',
			'TATABL_CATEGORY',
			'TATABL_EDUCATION',
			'TATABL_NATURE_OF_BUSINESS',
			'TATABL_EMPLOYMENT_INDUSTRY',
			'TATALAP_ACCOMODATON_TYPE',
			'TATABL_CORRESPONDING_FINANCIAL_YEAR',
			// 'TATABL_COMPANY_CONSTITUTION',
			'TATABL_CONSTITUTION',
			'TATABL_OCCUPATION_CONSTITUTION',
		].forEach(async (ele: any) => {
			await dispatch(fetchMasterData({ param: ele }))
				.then(unwrapResult)
				.then((result: any) => {
					if (result?.status) {
						let religionValue: any;
						switch (ele) {
							case 'TATA_CUSTOMER_TYPE':
								setCustomerType(result?.data);
								break;
							case 'TATABL_MARITIAL_STATUS':
								setMaritalStatus(result?.data);
								break;
							case 'TATABL_RELIGION':
								religionValue = result.data.map((item: any) => ({
									value: item.value,
								}));
								setReligions(religionValue);
								break;
							case 'TATABL_CATEGORY':
								setCateogry(result?.data);
								break;
							case 'TATABL_EDUCATION':
								setEducationQualification(result?.data);
								break;
							case 'TATABL_NATURE_OF_BUSINESS':
								setNatureOfBusiness(result?.data);
								break;
							case 'TATABL_EMPLOYMENT_INDUSTRY':
								setEmploymentIndustry(result?.data);
								break;
							case 'TATALAP_ACCOMODATON_TYPE':
								setAccommodationType(result?.data);
								break;
							case 'TATABL_CORRESPONDING_FINANCIAL_YEAR':
								setCorrespondingFinancialYear(result?.data);
								break;
							// case 'TATABL_COMPANY_CONSTITUTION':
							// 	setCompanyContitution(result?.data);
							// 	break;
							case 'TATABL_CONSTITUTION':
								setContitution(result?.data);
								break;
							case 'TATABL_OCCUPATION_CONSTITUTION':
								setOccupationContitution(result?.data);
								break;
						}
					}
				});
		});
	};

	/**
	 * Set dropdown data
	 * @param name field name
	 */
	const setDropDownOptions = (name: string) => {
		switch (name) {
			case 'customer_type':
				return customerType;
			case 'marital_status':
				return maritalStatus;
			case 'religion':
				return religions;
			case 'category':
				return category;
			case 'education':
				return educationQualification;
			case 'nature_of_business':
				return natureOfBusiness;
			case 'employment_industry':
				return employmentIndustry;
			case 'accommodation_type':
				return accommodationType;
			case 'corresponding_financial_year':
				return correspondingFinancialYear;
			// case 'company_constitution_code':
			// 	return companyContitution;
			// case 'constitution_personal':
			// 	return personalContitution;
			case 'occupation_constitution_code':
				return occupationContitution;
			case 'constitution_code':
				return contitution;
		}
	};

	// const getPersonalConstitution = async (e: any) => {
	// 	setValue('constitution_personal', '');
	// 	const queryParams =
	// 		e === 'Corporate'
	// 			? 'TATABL_CONSTITUTION_PERSONAL_CORPORATE'
	// 			: 'TATABL_CONSTITUTION_PERSONAL_INDIVIDUAL';
	// 	await dispatch(fetchMasterData({ param: queryParams }))
	// 		.then(unwrapResult)
	// 		.then((result: any) => {
	// 			if (result?.status) {
	// 				setPersonalContitution(result?.data);
	// 			}
	// 		});
	// };

	/**
	 * On submit to save user data and after that
	 * @param data Form data
	 */
	const onSubmit = async (data: any) => {
		const req: any = {
			lender_spf_details: {
				...lenderSpecificDetails?.lender_spf_details,
				...data,
				isValidApplicant1: isValid,
			},
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.TATA_BL,
			step: 'step2',
		};

		if (lenderSpecificDetails?.lead_spf_detail_id) {
			req.lead_spf_detail_id = lenderSpecificDetails?.lead_spf_detail_id;
		}

		setShowOfferErrMsg('');
		await dispatch(saveLenderSpcfInfo({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					if (isValid) {
						setSelectedTabValue('ApplicantFormTwo');
						dispatch(
							lenderSpfDetailsFlow({
								...lenderSpecificDetails,
								lender_spf_details: req.lender_spf_details,
							})
						);
					}
				} else {
					setShowOfferErrMsg(result?.message);
				}
			});
	};

	/**
	 * Continue Later and Submit button
	 */
	const continueLaterAndSubmitBtn = () => {
		return (
			<>
				<CustomButton
					className='w-100 fw-bold dashboard-add-lead-button mb-3'
					expand='block'
					fill='outline'
					title={t('Continue Later')}
					onClick={() => {
						if (checkFieldsValidToContinue(errors)) {
							setModalPageClose(true);
						} else {
							showToastAlert({
								type: 'error',
								message: 'Please fix above errors',
							});
						}
					}}
				/>
				<CustomButton
					type='submit'
					className='w-100 fw-bold dashboard-add-lead-button'
					expand='block'
					title={t('Save & Continue')}
					disabled={!isValid}
					onClick={handleSubmit(onSubmit)}
				/>
			</>
		);
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 pt-3 p-md-5 border-radius-10'>
							<form onSubmit={handleSubmit(onSubmit)}>
								{applicantFormOne.map(
									({ name, type, placeholder }: any, index: any) => {
										const errorText: any =
											errors && Object.keys(errors).length > 0 && errors;
										return (
											<div key={index}>
												{type === 'dropdown' ? (
													<div style={{ marginBottom: '15px' }}>
														<Controller
															control={control}
															render={({
																field: { onChange, onBlur, name },
															}) => (
																<DynamicDropdown
																	label={t(`${placeholder}`)}
																	data={setDropDownOptions(name)}
																	name={name}
																	onChange={(e: any) => {
																		onChange(e);
																		// if (name === 'customer_type') {
																		// 	getPersonalConstitution(e.detail.value);
																		// }
																	}}
																	bindField={
																		// name === 'company_constitution_code' ||
																		// name === 'constitution_personal' ||
																		name === 'constitution_code' ||
																		name === 'occupation_constitution_code' ||
																		name === 'employment_industry' ||
																		name === 'accommodation_type' ||
																		name === 'nature_of_business'
																			? 'id'
																			: 'value'
																	}
																	showField1={'value'}
																	value={getValues(name)}
																	onBlur={onBlur}
																	errors={errorText[name]?.message}
																/>
															)}
															name={name}
														/>
													</div>
												) : (
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<CustomInput
																type={type}
																label={t(placeholder)}
																onBlur={onBlur}
																id={name}
																onChange={(e: any) => {
																	onChange(e);
																}}
																value={getValues(name)}
																errors={t(errorText[name]?.message || '')}
															/>
														)}
														name={name}
													/>
												)}
											</div>
										);
									}
								)}
							</form>

							<div className={`d-none d-md-block mt-4`}>
								{continueLaterAndSubmitBtn()}
							</div>
						</div>
					</div>
				)}
			</IonContent>

			{!showOfferErrMsg && (
				<IonFooter className='d-block d-md-none position-relative ion-padding lead_ftr_btn'>
					{continueLaterAndSubmitBtn()}
				</IonFooter>
			)}

			<CustomModal
				needIonContentWraper={false}
				isOpen={modalPageClose}
				initialBreakpoint={undefined}
				handleClose={() => setModalPageClose(false)}
				breakpoints={[]}
				className='height-auto confirm-popup-middle continue-later'
				modalContent={ContinueLaterPopUp(
					() => {
						setModalPageClose(false);
					},
					() => {
						new Promise(function (myResolve) {
							setTimeout(function () {
								myResolve(history.push('/dashboard/lead'));
								onSubmit(getValues());
								setModalPageClose(false);
							}, 10);
						});
					},
					'Cancel',
					'Continue Later',
					'className',
					'Are You Sure You Want to Exit?',
					`${t('You can continue later from where you left off.')}`
				)}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
