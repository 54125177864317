import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { Header } from '../../components/layout/header/Header';
import './AddMobile.scss';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';

export const AddMobile: React.FC = () => {
	const { t } = useTranslation();
	return (
		<IonPage className='add-mobile'>
			<Header name='' />
			<IonContent className='ion-padding'>
				<div>
					<h4 className='ion-no-margin title'>
						{t('Add your')} <br /> {t('Mobile Numbers')}
					</h4>
					<p className='ion-no-margin text-grey description'>
						{t('Enter your Mobile Number linked')}
						<br />
						{t('with your PAN & AADHAR')}
					</p>
				</div>
				<div>
					<CustomInput placeholder='Mobile No.' />
					<div className='proceed-btn'>
						<CustomButton
							className='btn-blue'
							expand='block'
							routerLink='/otp-verification'
							title={t('Proceed')}
						/>
					</div>
				</div>
				<p className='ion-no-margin text-grey'>
					{t('Note: If you are registering as a Firm, enter mobile')}
					<br />
					{t('no. of the authorised partner/ director')}
				</p>
			</IonContent>
		</IonPage>
	);
};
