import {
	IonContent,
	IonCardHeader,
	IonCardSubtitle,
	IonPage,
	IonCard,
	IonLabel,
	IonItem,
	IonList,
	IonAccordionGroup,
	IonAccordion,
} from '@ionic/react';
import React from 'react';
import { Header } from '../../components/layout/header/Header';
import { Menu } from '../../components/layout/menu/Menu';
import './ApplicationPreview.scss';
import { useTranslation } from 'react-i18next';

export const ApplicationPreview: React.FC = () => {
	const { t } = useTranslation();
	const basicInfo = [
		{ name: 'PAN', value: 'BHZPN9118H' },
		{ name: 'Name As Aadhar Card', value: 'Navdeep' },
		{ name: 'Pincode', value: '131103' },
		{ name: 'Date of Birth', value: '02 MAY 1999' },
		{ name: 'Email', value: 'nd@gmail.com' },
		{ name: 'Loan Amount', value: '$ 3,00,000,000,000' },
	];

	return (
		<>
			<Menu />
			<IonPage
				id='main-content'
				className='dashboard'
			>
				<Header
					type='dashboard'
					name=''
				/>
				<IonContent>
					<IonCard className='m-0 details'>
						<div className='d-flex align-items-center gap-3 p-3'>
							<div className='w-auto'>
								<img
									width={72}
									height={72}
									className='rounded-circle'
									alt='Silhouette of mountains'
									src='https://ionicframework.com/docs/img/demos/card-media.png'
								/>
							</div>
							<div>
								<IonCardSubtitle className='fs-22 text-black bg-transparent'>
									Louis Lynch
								</IonCardSubtitle>
							</div>
						</div>
						<IonCardHeader className='pt-0'>
							<IonList
								lines='none'
								className='bg-transparent'
							>
								<p className='text-grey-light ps-4  fs-14 ellipsis'>
									{t('Applied for')} <b>Home loan</b>
								</p>
								<p className='text-grey-light ps-4  fs-14 ellipsis'>
									{t('Loan Amount')} <b>₹ 1,50,00,000</b>
								</p>
								<p className='text-grey-light ps-4  fs-14 ellipsis'>
									{t('Mobile No.')} <b>8087582544</b>
								</p>
							</IonList>
						</IonCardHeader>
					</IonCard>

					<div className=''>
						<IonAccordionGroup>
							<IonAccordion
								className='ion-no-border'
								value='first'
							>
								<IonItem
									slot='header'
									className='ion-no-border'
								>
									<IonLabel className='ion-no-border'>
										<b>{t('Basic Info')}</b>
									</IonLabel>
								</IonItem>
								<div
									className='ion-padding'
									slot='content'
								>
									<IonList>
										{basicInfo &&
											basicInfo?.map((item, index) => {
												return (
													<IonItem
														key={index}
														className='ion-no-padding'
														lines='full'
													>
														<div className='d-flex flex-grow-1 justify-content-between'>
															<p className='mb-0 fs-14'>{item?.name}</p>
															<p className='mb-0 fs-14'>{item?.value}</p>
														</div>
													</IonItem>
												);
											})}
									</IonList>
								</div>
							</IonAccordion>
							<IonAccordion
								className='mt-3'
								value='second'
							>
								<IonItem slot='header'>
									<IonLabel>
										<b>{t('Professional Info')}</b>
									</IonLabel>
								</IonItem>
								<div
									className='ion-padding'
									slot='content'
								>
									<IonList>
										{basicInfo &&
											basicInfo?.map((item, index) => {
												return (
													<IonItem
														key={index}
														className='ion-no-padding'
														lines='full'
													>
														<div className='d-flex flex-grow-1 justify-content-between'>
															<p className='mb-0 fs-14'>{item?.name}</p>
															<p className='mb-0 fs-14'>{item?.value}</p>
														</div>
													</IonItem>
												);
											})}
									</IonList>
								</div>
							</IonAccordion>
							<h6 className='mt-3 mx-3 fw-bold'>{t('Lenders Info')}</h6>
							<IonAccordion
								className='mt-3'
								value='third'
							>
								<IonItem slot='header'>
									<IonLabel>
										<b>{t('Comman Info')}</b>
									</IonLabel>
								</IonItem>
								<div
									className='ion-padding'
									slot='content'
								>
									<IonList>
										{basicInfo &&
											basicInfo?.map((item, index) => {
												return (
													<IonItem
														key={index}
														className='ion-no-padding'
														lines='full'
													>
														<div className='d-flex flex-grow-1 justify-content-between'>
															<p className='mb-0 fs-14'>{item?.name}</p>
															<p className='mb-0 fs-14'>{item?.value}</p>
														</div>
													</IonItem>
												);
											})}
									</IonList>
								</div>
							</IonAccordion>
							<IonAccordion
								className='mt-3'
								value='fourth'
							>
								<IonItem slot='header'>
									<IonLabel>
										<b>SBI Bank</b>
									</IonLabel>
								</IonItem>
								<div
									className='ion-padding'
									slot='content'
								>
									<IonList>
										{basicInfo &&
											basicInfo?.map((item, index) => {
												return (
													<IonItem
														key={index}
														className='ion-no-padding'
														lines='full'
													>
														<div className='d-flex flex-grow-1 justify-content-between'>
															<p className='mb-0 fs-14'>{item?.name}</p>
															<p className='mb-0 fs-14'>{item?.value}</p>
														</div>
													</IonItem>
												);
											})}
									</IonList>
								</div>
							</IonAccordion>
						</IonAccordionGroup>
					</div>
				</IonContent>
			</IonPage>
		</>
	);
};
