import React, { useEffect, useState } from 'react';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { IonContent, IonFooter } from '@ionic/react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { unwrapResult } from '@reduxjs/toolkit';
import { t } from 'i18next';
import {
	getLeadUmDetails,
	// lenderSpfDetailsFlow,
	saveLenderSpcfInfo,
} from '../../../store/reducers/lead/leadSlice';
import {
	ContinueLaterPopUp,
	FormatDate,
	checkFieldsValidToContinue,
	disableNumberArrows,
	fetchStateCityByPincode,
	showInAppBrowserView,
	showToastAlert,
} from '../../../helpers/functions';
import { LenderNameForAPICall } from '../../../helpers/lenders';
import { OfferRejectedMsg } from '../OfferRejectedMsg/OfferRejectedMsg';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import { REGEX } from '../../../helpers/constant';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { MoneyViewFormFields } from './MoneyViewFormFields';
import { MoneyViewFormValidationSchema } from './MoneyViewFormValidationSchema';
import {
	getLenderUserInfo,
	getProfessionalDetailsMaster,
} from '../../../store/reducers/user/userSlice';
import * as yup from 'yup';
import DropDownModal from '../../../components/common/custom-dropdown/DropDownModal';
import StateCity from '../../../components/common/StateCity';

export default function MoneyViewPersonalInfoForm() {
	const [modalPageClose, setModalPageClose] = useState(false);
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const [netMonthlyIncomeDisableCheck, setNetMonthlyIncomeDisableCheck] =
		useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const { selectedLender, lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);
	const [dropdownMasterData, setDropdownMasterData] = useState<any>({});
	const [formValidationSchemas, setFormValidationSchemas] = useState<any>(
		MoneyViewFormValidationSchema
	);
	const [formFields, setFormFields] = useState(MoneyViewFormFields);
	const [continueLaterValue, setContinueLaterValue] = useState({});
	const [pinCodeData, setPinCodeData] = useState<any>([]);

	const location = useLocation<any>();

	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		trigger,
		reset,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(formValidationSchemas),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			first_name: '',
			middle_name: '',
			last_name: '',
			email: '',
			mobile_no: '',
			dob: '',
			pan: '',
			pincode: '',
			loan_amount: '',
			net_monthly_income: null,
			address_type: '',
			residence_type: '',
			education_level: '',
			income_mode: '',
			city: '',
			state: '',
		},
	});

	useEffect(() => {
		if (location.pathname !== '/lead-lender-specific-form-steps') {
			return;
		}
		getLeadData();
	}, [location.pathname]);

	useEffect(() => {
		reset(continueLaterValue);
	}, [continueLaterValue]);

	const getLeadData = async () => {
		await dispatch(
			getLenderUserInfo({
				param: leadFlowData?.leadId,
				data: {
					lender_name: selectedLender[0]?.lender_name,
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status && Object.keys(result.data).length) {
					const schemaBuilderObject: any = {};
					result?.data?.forEach((formKeyName: any) => {
						if (formKeyName.type === 'dropdownSelect') {
							dispatch(getProfessionalDetailsMaster({ param: formKeyName.key }))
								.then(unwrapResult)
								.then((dropdownResponse: any) => {
									if (dropdownResponse?.status) {
										setDropdownMasterData((masterData: any) => {
											return {
												...masterData,
												[formKeyName.name]: dropdownResponse.data,
											};
										});

										const continueLaterVal = dropdownResponse.data.filter(
											({ value }: any) => value === formKeyName.value
										);

										if (formKeyName.value) {
											setContinueLaterValue((old) => {
												return {
													...old,
													[formKeyName.name]: continueLaterVal[0]?.id,
												};
											});
										} else {
											setContinueLaterValue((old) => {
												return {
													...old,
													[formKeyName.name]: '',
												};
											});
										}
									}
								});
							if (formKeyName?.is_required) {
								schemaBuilderObject[formKeyName.name] = yup
									.number()
									.required(
										typeof formKeyName?.required_error_message === 'string' &&
											formKeyName?.required_error_message
											? formKeyName?.required_error_message
											: formKeyName.placeholder.replaceAll('*', '') +
													' is a required field'
									);
							}
						}
					});
					setFormValidationSchemas(
						formValidationSchemas.concat(
							yup.object().shape(schemaBuilderObject)
						)
					);
					setFormFields(() => [...MoneyViewFormFields, ...result.data]);
				}
			});
		await dispatch(getLeadUmDetails({ param: leadFlowData?.leadId }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setNetMonthlyIncomeDisableCheck(
						typeof result?.proinfo.net_monthly_income === 'number'
					);
					setContinueLaterValue((old) => {
						return {
							...old,
							first_name: result?.data.first_name,
							middle_name: result?.data.middle_name,
							last_name: result?.data.last_name,
							email: result?.data.email,
							mobile_no: result?.data.mobile_no,
							dob: result?.data.dob,
							pan: result?.data.pan,
							pincode: result?.data.pincode,
							loan_amount: result?.data.loan_amount,
							net_monthly_income: result?.proinfo.net_monthly_income,
							state: result?.data?.orgState,
							city: result?.data?.orgCity,
						};
					});
					if (result?.data.pincode) {
						fetchStateCityByPincode(
							setValue,
							'city',
							'state',
							setPinCodeData,
							dispatch,
							result?.data.pincode,
							trigger
						);
					}
				}
			});
	};

	const onSubmit = async (data: any, flag: any = '') => {
		const extrafields: any = {};
		formFields?.forEach((field: any) => {
			if (field.type === 'dropdownSelect') {
				const selectedOption = dropdownMasterData[field?.name]?.filter(
					(item: any) => item.id === data[field?.name]?.toString()
				);
				extrafields[field.name] = data[field.name]
					? selectedOption[0]?.value
					: '';
			}
		});
		const req: any = {
			lender_spf_details: {
				net_monthly_income: getValues('net_monthly_income'),
				cibil_consent: {
					status: 'Y',
					date: FormatDate(new Date(), "UTC:yyyy-mm-dd'T'HH:MM:ss'.969Z'"),
				},
				...extrafields,
			},
			step: 'step2',
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.MONEYVIEW,
			isNewLead: isValid,
			continue_later: flag === 'continue_later' ? true : false,
			orgCity: pinCodeData[0]?.Region,
			orgState: pinCodeData[0]?.State,
		};

		if (lenderSpecificDetails?.lead_spf_detail_id) {
			req.lead_spf_detail_id = lenderSpecificDetails?.lead_spf_detail_id;
		}
		await dispatch(saveLenderSpcfInfo({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					if (result?.data) {
						showInAppBrowserView(result.data?.url);
					}
					if (flag === 'continue_later') {
						setShowOfferErrMsg('');
					} else {
						setShowOfferErrMsg(
							`${result?.message} ${
								result?.data?.lender_lead_id
									? `${result?.data?.lender_lead_id}`
									: ''
							}`
						);
					}
					// dispatch(
					// 	lenderSpfDetailsFlow({
					// 		...lenderSpecificDetails,
					// 		lender_spf_details: {
					// 			...lenderSpecificDetails?.lender_spf_details,
					// 			...req.lender_spf_details,
					// 		},
					// 		lead_spf_detail_id:
					// 			result.data.lead_spf_detail_id || result.data.id,
					// 		step: 'step2',
					// 		offers: result?.data?.offer?.response?.LOAN_OFFERS,
					// 		andro_id: result?.data?.events[0]?.['APPLICATION_ID'],
					// 	})
					// );
				} else {
					setShowOfferErrMsg(result?.error?.message || result?.message);
				}
			});
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
							<form onSubmit={handleSubmit(onSubmit)}>
								{formFields.map(
									(
										{ name, type, placeholder, isDisabled, is_required }: any,
										index: any
									) => {
										const errorText: any =
											errors && Object.keys(errors).length > 0 && errors;
										if (type === 'dropdownSelect') {
											return (
												<div key={index + '-dropdownSelect'}>
													<>
														<Controller
															key={`controller-${index}`}
															control={control}
															render={({
																field: { onChange, onBlur, name },
															}) => (
																<DropDownModal
																	label={
																		t(`${placeholder}`) +
																		(is_required ? ' *' : '')
																	}
																	data={
																		dropdownMasterData[name]
																			? dropdownMasterData[name]
																			: []
																	}
																	name={name}
																	onChange={onChange}
																	onBlur={onBlur}
																	className={
																		errorText[name]?.message
																			? 'ion-invalid ion-touched mb-3'
																			: 'mb-3'
																	}
																	errors={t(errorText[name]?.message as string)}
																	value={getValues(name)}
																	// disabled={value ? true : false}
																/>
															)}
															name={name}
														/>
													</>
												</div>
											);
										} else {
											return (
												<div key={index + '-text'}>
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<>
																<CustomInput
																	type={type}
																	disabled={
																		name === 'net_monthly_income' &&
																		netMonthlyIncomeDisableCheck === false
																			? false
																			: isDisabled
																	}
																	label={t(placeholder)}
																	onBlur={onBlur}
																	id={name}
																	onChange={(e: any) => {
																		onChange(e);
																		if (e?.detail?.value?.length === 6) {
																			if (name === 'pincode') {
																				fetchStateCityByPincode(
																					setValue,
																					'city',
																					'state',
																					setPinCodeData,
																					dispatch,
																					e.detail.value,
																					trigger
																				);
																			}
																		} else {
																			setValue('state', '');
																			setValue('city', '');
																			setPinCodeData([]);
																		}
																	}}
																	value={getValues(name)}
																	maxLength={
																		name === 'net_monthly_income' ? 15 : null
																	}
																	onBeforeInput={(evt: any) => {
																		if (type == 'number') {
																			!REGEX.ALLOWNUMBERS.test(evt.data) &&
																				evt.preventDefault();
																		}
																	}}
																	onKeyDown={(e: any) => {
																		if (type == 'number') {
																			disableNumberArrows(e);
																		}
																	}}
																	onWheel={(e: any) => {
																		if (type == 'number') {
																			e.target.blur();
																		}
																	}}
																	errors={t(errorText[name]?.message || '')}
																/>

																{name === 'pincode' && (
																	<StateCity
																		cityInputName='city'
																		control={control}
																		setValue={setValue}
																		getValues={getValues}
																		pinCodeData={pinCodeData}
																		trigger={trigger}
																	/>
																)}
															</>
														)}
														name={name}
													/>
												</div>
											);
										}
									}
								)}
							</form>

							<div className={` d-none d-md-block`}>
								<CustomButton
									className='w-100 fw-bold dashboard-add-lead-button mb-3'
									expand='block'
									fill='outline'
									title={t('Continue Later')}
									onClick={() => {
										if (checkFieldsValidToContinue(errors)) {
											setModalPageClose(true);
										} else {
											showToastAlert({
												type: 'error',
												message: 'Please fix above errors',
											});
										}
									}}
								/>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				)}
				<CustomModal
					needIonContentWraper={false}
					isOpen={modalPageClose}
					initialBreakpoint={undefined}
					handleClose={() => setModalPageClose(false)}
					breakpoints={[]}
					className='height-auto confirm-popup-middle continue-later'
					modalContent={ContinueLaterPopUp(
						() => {
							setModalPageClose(false);
						},
						() => {
							new Promise(function (myResolve) {
								setTimeout(function () {
									myResolve(history.push('/dashboard/lead'));
									onSubmit(getValues(), 'continue_later');
									setModalPageClose(false);
								}, 30);
							});
						},
						'Cancel',
						'Continue Later',
						'className',
						'Are You Sure You Want to Exit?',
						`${t('You can continue later from where you left off.')}`
					)}
					needCross={false}
					needArrow={false}
				/>
			</IonContent>
			{!showOfferErrMsg && (
				<IonFooter className='d-block d-md-none position-relative'>
					<div
						style={{
							backgroundColor: '#fff',
							zIndex: 2,
						}}
					>
						<div className={`ion-padding`}>
							<CustomButton
								className='w-100 fw-bold dashboard-add-lead-button mb-3'
								expand='block'
								fill='outline'
								title={t('Continue Later')}
								onClick={() => {
									if (checkFieldsValidToContinue(errors)) {
										setModalPageClose(true);
									} else {
										showToastAlert({
											type: 'error',
											message: 'Please fix above errors',
										});
									}
								}}
							/>
							<div className='proceed-btn'>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				</IonFooter>
			)}
		</>
	);
}
