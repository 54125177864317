import { IonContent, IonPage } from '@ionic/react';

import { Header } from '../components/layout/header/Header';
import { useState } from 'react';
import { CustomButton } from '../components/common/custom-button/CustomButton';
import { useHistory } from 'react-router';
import { takePicture } from '../helpers/functions';
import { useTranslation } from 'react-i18next';

export const OCRTest: React.FC = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const [aadharImgUrl, setAadharImgUrl] = useState(null);
	const [aadharNumber, setAadharNumber] = useState('');

	const [panImgUrl, setPanImgUrl] = useState(null);
	const [panNumber, setPanNumber] = useState('');

	const [chequeImgUrl, setChequeImgUrl] = useState(null);
	const [ifscCode, setIfscCode] = useState('');

	const setData = (data: any, dataType: string) => {
		switch (dataType) {
			case 'aadhar':
				setAadharImgUrl(data.image);
				setAadharNumber(data.processedData);
				break;
			case 'pan':
				setPanImgUrl(data.image);
				setPanNumber(data.processedData);
				break;
			case 'cheque':
				setChequeImgUrl(data.image);
				setIfscCode(data.processedData);
				break;

			default:
				break;
		}
	};

	return (
		<IonPage className='add-mobile'>
			<Header
				name='OCR'
				backButton={true}
				needArrowOnClick={() => history.push('/dashboard/home')}
				needArrow={true}
			/>
			<IonContent className='ps-0'>
				<div style={{ textAlign: 'center' }}>
					<div style={{ marginBottom: '20px' }}>
						{aadharImgUrl ? (
							<img
								style={{ maxHeight: '150px', width: 'auto' }}
								src={aadharImgUrl}
							/>
						) : (
							''
						)}
						<div>
							<CustomButton
								onClick={async () => {
									const responseData = await takePicture(
										'imageWithData',
										'aadhaar'
									);
									setData(responseData, 'aadhar');
								}}
							>
								Scan Aadhar
							</CustomButton>
						</div>
						{aadharNumber ? (
							<div>
								Aadhar no. <b>{aadharNumber}</b>
							</div>
						) : (
							''
						)}
					</div>
					<div style={{ marginBottom: '20px' }}>
						{panImgUrl ? (
							<img
								style={{ maxHeight: '150px', width: 'auto' }}
								src={panImgUrl}
							/>
						) : (
							''
						)}
						<div>
							<CustomButton
								onClick={async () => {
									const responseData = await takePicture(
										'imageWithData',
										'pan'
									);
									setData(responseData, 'pan');
								}}
							>
								Scan PAN
							</CustomButton>
						</div>
						{panNumber ? (
							<div>
								PAN no. <b>{panNumber}</b>
							</div>
						) : (
							''
						)}
					</div>
					<div style={{ marginBottom: '20px' }}>
						{chequeImgUrl ? (
							<img
								style={{ maxHeight: '150px', width: 'auto' }}
								src={chequeImgUrl}
							/>
						) : (
							''
						)}
						<div>
							<CustomButton
								onClick={async () => {
									const responseData = await takePicture(
										'imageWithData',
										'cheque'
									);
									setData(responseData, 'cheque');
								}}
							>
								Scan Cheque
							</CustomButton>
						</div>
						{ifscCode ? (
							<div>
								{t('IFSC Code')}: <b>{ifscCode}</b>
							</div>
						) : (
							''
						)}
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};
