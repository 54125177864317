export class FamilyDetailsForm  {
  id: number|undefined;
  maritalStatus: string;
  spouseFirstName: string;
  spouseMiddleName: string;
  spouseLastName: string;
  fatherFirstName: string;
  fatherMiddleName: string;
  fatherLastName: string;
  motherFirstName: string;
  motherMiddleName: string;
  motherLastName: string;
  profileId: number|undefined;


  constructor(request: FamilyDetailRequest) {
    this.id = request.id;
    this.maritalStatus = request.marital_status;
    this.spouseFirstName = request.spouse_first_name || ''; // Handle 'any' type, assuming it could be undefined
    this.spouseMiddleName = request.spouse_middle_name || ''; // Same assumption as above
    this.spouseLastName = request.spouse_last_name;
    this.fatherFirstName = request.father_first_name;
    this.fatherMiddleName = request.father_middle_name || ''; // Same assumption as above
    this.fatherLastName = request.father_last_name;
    this.motherFirstName = request.mother_first_name;
    this.motherMiddleName = request.mother_middle_name;
    this.motherLastName = request.mother_last_name;
    this.profileId = request.profileId;
  }
};

export type FamilyFloaterForm = {
  relationship: string;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  ageProof: string;
};

export class FamilyDetailRequest {
  id: number|undefined;
  marital_status: string;
  spouse_first_name: any;
  spouse_middle_name: any;
  spouse_last_name: string;
  father_first_name: string;
  father_middle_name: any;
  father_last_name: string;
  mother_first_name: string;
  mother_middle_name: string;
  mother_last_name: string;
  profileId: number|undefined;

  constructor(form: FamilyDetailsForm) {
    this.id = form.id;
    this.marital_status = form.maritalStatus;
    this.spouse_first_name = form.spouseFirstName;
    this.spouse_middle_name = form.spouseMiddleName;
    this.spouse_last_name = form.spouseLastName;
    this.father_first_name = form.fatherFirstName;
    this.father_middle_name = form.fatherMiddleName;
    this.father_last_name = form.fatherLastName;
    this.mother_first_name = form.motherFirstName;
    this.mother_middle_name = form.motherMiddleName;
    this.mother_last_name = form.motherLastName;
    this.profileId = form.profileId;
  }




}
