/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	IonContent,
	IonFooter,
	IonItem,
	IonPage,
	IonSegment,
	IonSegmentButton,
	IonSpinner,
	isPlatform,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Header } from '../../../components/layout/header/Header';
import '../BureauReport.scss';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { BureauData } from '../BureauData';
import { useHistory, useLocation } from 'react-router';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import {
	AllocatePopUp,
	DownloadFileBlob,
	DownloadFileInApp,
	PageExitPopUp,
	showToastAlert,
} from '../../../helpers/functions';
import { useTranslation } from 'react-i18next';
import { BUREAU_SCORE_TABS, LEAD_SCORE_TYPE } from '../../../helpers/constant';
import {
	downloadEquifaxBureauReport,
	fetchCibilBureauReport,
	fetchEquifaxBureauReport,
} from '../../../store/reducers/lead/leadSlice';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import './BureauScore.scss';
import { webView } from '../../../store/reducers/app/appSlice';
import { LocalNotifications } from '@capacitor/local-notifications';
import { FileOpener } from '@capacitor-community/file-opener';
import { PushNotifications } from '@capacitor/push-notifications';
import {
	AndroidSettings,
	IOSSettings,
	NativeSettings,
} from 'capacitor-native-settings';

export const ViewBureauReport: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const { state } = useLocation<any>();

	const leadBureauCustomerSelector = useSelector(
		(state: any) => state?.lead?.leadBureauCustomerData
	);
	const fetchBureauCustomerReportData = useSelector(
		(state: any) => state?.lead?.fetchBureauCustomerReportData
	);
	const reportDownlaodSelector = useSelector(
		(state: any) => state?.lead?.leadFlowData?.id
	);
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	const [bureauRetry, setBureauRetry] = useState(false);
	const [bureauFetchError, setBureauFetchError] = useState('');
	const [selectedTab, setSelectedTab] = useState<any>(
		leadBureauCustomerSelector?.scoreType?.toLocaleLowerCase() ===
			LEAD_SCORE_TYPE.EQUIFAX.toLocaleLowerCase()
			? BUREAU_SCORE_TABS.EQUIFAX
			: leadBureauCustomerSelector?.scoreType?.toLocaleLowerCase() ===
			  LEAD_SCORE_TYPE.CBIL.toLocaleLowerCase()
			? BUREAU_SCORE_TABS.CIBIL
			: leadBureauCustomerSelector?.scoreType?.toLocaleLowerCase() ===
					LEAD_SCORE_TYPE.BOTH.toLocaleLowerCase() && BUREAU_SCORE_TABS.EQUIFAX
	);
	const [initiateLoanApplication, setInitiateLoanApplication] = useState(false);
	const [allocateAndroManagerModal, setAllocateAndroManagerModal] =
		useState(false);
	const [modalPageClose, setModalPageClose] = useState(false);
	const [equifaxData, setEquifaxData] = useState();
	const [cbilData, setCbilData] = useState();
	const [equifaxDataLoading, setEquifaxDataLoading] = useState(false);
	const [cbilDataLoading, setCbilDataLoading] = useState(false);
	const [showNotificationModel, setShowNitificationModel] = useState(false);
	const location = useLocation<any>();

	const dispatch = useDispatch();
	const registerNotifications = async () => {
		const permStatus = await PushNotifications.checkPermissions();

		if (permStatus.receive == 'denied') {
			setShowNitificationModel(true);
			setSpinner(false);
		} else if (permStatus.receive == 'prompt-with-rationale') {
			setShowNitificationModel(true);
			setSpinner(false);
		} else {
			setShowNitificationModel(false);
		}
	};
	const downloadBureauReport = async () => {
		await dispatch(
			downloadEquifaxBureauReport({
				param: `/${
					leadBureauCustomerSelector?.leadId || reportDownlaodSelector
				}${leadInfo?.from === 'customer' ? '&lead_type=customer' : ''}`,
			})
		)
			.then(unwrapResult)
			.then(async (response: any) => {
				if (response?.status) {
					showToastAlert({ type: 'success', message: 'Download Initiated' });

					if (isPlatform('mobileweb')) {
						DownloadFileBlob(
							response?.base64String,
							selectedTab + '_report',
							'pdf'
						);
					} else if (
						isPlatform('android') ||
						isPlatform('ios') ||
						isPlatform('ipad') ||
						isPlatform('iphone')
					) {
						registerNotifications();

						setSpinner(true);

						DownloadFileInApp(
							response.base64String,
							selectedTab + '_report',
							'pdf'
						);
					} else {
						DownloadFileBlob(
							response?.base64String,
							selectedTab + '_report',
							'pdf'
						);
					}
				}
			});
	};

	useEffect(() => {
		if (window.innerWidth > 768) {
			dispatch(webView(true));
		} else {
			dispatch(webView(false));
		}
		getBureauData();
	}, [selectedTab]);
	const [spinner, setSpinner] = useState(false);

	useEffect(() => {
		LocalNotifications.addListener('localNotificationReceived', (data: any) => {
			if (data) {
				setSpinner(false);
			}
		});

		LocalNotifications.addListener(
			'localNotificationActionPerformed',
			(payload: any) => {
				const route = payload.notification.extra.route;
				FileOpener.open({
					filePath: route,
				});
			}
		);
		return () => {
			LocalNotifications.removeAllListeners();
		};
	}, []);
	const getBureauData = async () => {
		if (
			selectedTab === BUREAU_SCORE_TABS.EQUIFAX &&
			(leadBureauCustomerSelector?.scoreType?.toLocaleLowerCase() ===
				LEAD_SCORE_TYPE.EQUIFAX.toLocaleLowerCase() ||
				leadBureauCustomerSelector?.scoreType?.toLocaleLowerCase() ===
					LEAD_SCORE_TYPE.BOTH.toLocaleLowerCase())
		) {
			setBureauRetry(false);
			setEquifaxDataLoading(true);
			await dispatch(
				fetchEquifaxBureauReport({
					param: state?.lead_id
						? state?.lead_id
						: leadBureauCustomerSelector?.leadId ||
						  fetchBureauCustomerReportData?.lead_id,

					data: {
						mobile_no: state?.mobile
							? state?.mobile
							: leadBureauCustomerSelector?.leadMobile,
					},
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					setEquifaxDataLoading(false);
					if (response?.status) {
						setEquifaxData(response?.data);
					} else {
						setBureauFetchError(
							response?.text ? response?.text : response?.message
						);
					}
					setBureauRetry(response?.retry);
				});
		} else if (
			selectedTab === BUREAU_SCORE_TABS.CIBIL &&
			(leadBureauCustomerSelector?.scoreType?.toLocaleLowerCase() ===
				LEAD_SCORE_TYPE.CBIL.toLocaleLowerCase() ||
				leadBureauCustomerSelector?.scoreType?.toLocaleLowerCase() ===
					LEAD_SCORE_TYPE.BOTH.toLocaleLowerCase())
		) {
			setBureauRetry(false);
			setCbilDataLoading(true);
			await dispatch(
				fetchCibilBureauReport({
					param: `?new_lead_id=${
						leadBureauCustomerSelector?.lead_id ||
						leadBureauCustomerSelector?.leadId
					}&lead_id=${leadBureauCustomerSelector?.cibil_id} `,
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					setCbilDataLoading(false);
					if (response?.status) {
						setCbilData(response?.cibil_response);
					} else {
						setBureauFetchError(
							response?.text ? response?.text : response?.message
						);
					}
					setBureauRetry(response?.retry);
				});
		}
	};

	const bureauTabs =
		leadBureauCustomerSelector?.scoreType?.toLocaleLowerCase() ===
		LEAD_SCORE_TYPE.CBIL.toLocaleLowerCase()
			? [
					{
						name: BUREAU_SCORE_TABS.CIBIL,
					},
			  ]
			: leadBureauCustomerSelector?.scoreType?.toLocaleLowerCase() ===
			  LEAD_SCORE_TYPE.EQUIFAX.toLocaleLowerCase()
			? [
					{
						name: BUREAU_SCORE_TABS.EQUIFAX,
					},
			  ]
			: [
					{
						name: BUREAU_SCORE_TABS.EQUIFAX,
					},
					{
						name: BUREAU_SCORE_TABS.CIBIL,
					},
			  ];

	return (
		<>
			<IonPage className='bg-web'>
				<Header
					name={t('Credit Report')}
					// endItem={
					// 	<IonButtons slot='end'>
					// 		<IonButtons className='ion-no-padding'>
					// 			<IonImg
					// 				className='ms-1'
					// 				src='/images/icons/share-black.svg'
					// 				alt='share'
					// 				style={{ width: '25px', height: '25px' }}
					// 			/>
					// 		</IonButtons>
					// 	</IonButtons>
					// }
					needCrossOnClick={async () => {
						if (leadInfo?.from === 'customer') {
							history.go(-1);
						} else {
							if (bureauFetchError) {
								setModalPageClose(false);
								history.replace('/dashboard/lead');
							} else {
								setModalPageClose(true);
							}
						}
					}}
					backButton={true}
					needCross={true}
				/>

				<IonContent
					className='bureau-report background-web '
					// scrollY={false}
				>
					<div className='bg-web'>
						<div className='h-100 conainer-margin-web border-md-radius bg-web-white mt-4 px-3 px-md-3'>
							{state?.full_name && (
								<div className='h6 text-center pt-4 text-uppercase'>
									{state?.full_name}
								</div>
							)}
							<div>
								<IonSegment
									className='tab'
									color='dark'
									value={selectedTab}
									onIonChange={(e) => setSelectedTab(e.detail.value as any)}
									mode='md'
								>
									{bureauTabs.map((item, index) => {
										return (
											<IonSegmentButton
												key={`bureau-report-tabs-${index}`}
												className='fs-14 fw-600'
												value={item.name}
												mode='md'
											>
												{t(item.name)}
											</IonSegmentButton>
										);
									})}
								</IonSegment>

								<div
									//
									style={{
										height: `calc(100vh - ${
											state?.full_name ? '234px' : '190px'
										})`,
									}}
								>
									{selectedTab === 'Equifax' ? (
										<>
											<BureauData
												bureauRetry={bureauRetry}
												bureauFetchError={bureauFetchError}
												leadType='customer'
												getBureauData={getBureauData}
												data={equifaxData}
												selectedTab={selectedTab}
												lead_id={leadBureauCustomerSelector?.leadId}
												dataLoading={equifaxDataLoading}
												height={state?.full_name ? '68vh' : '74vh'}
											/>
										</>
									) : (
										<>
											<BureauData
												bureauRetry={bureauRetry}
												bureauFetchError={bureauFetchError}
												leadType='customer'
												getBureauData={getBureauData}
												data={cbilData}
												selectedTab={selectedTab}
												lead_id={leadBureauCustomerSelector?.leadId}
												dataLoading={cbilDataLoading}
											/>
										</>
									)}
								</div>
							</div>
							<div className='d-none d-md-block px-3 py-3 mt-4'>
								{selectedTab === 'Equifax' ? (
									<>
										{!equifaxDataLoading ? (
											equifaxData ? (
												<>
													<CustomButton
														expand='block'
														className='btn-blue mb-3 mt-3'
														fill='outline'
														onClick={downloadBureauReport}
														title={t('Download Report')}
													/>
												</>
											) : (
												<>
													{!['customer', 'nimbuss'].includes(leadInfo?.from) ? (
														<CustomButton
															expand='block'
															className='btn-blue'
															onClick={() => {
																history.replace('/dashboard/home');
															}}
															title={t('Okay')}
														/>
													) : (
														''
													)}
												</>
											)
										) : (
											''
										)}
									</>
								) : cbilData ? (
									<>
										{/* <CustomButton
											expand='block'
											className='btn-blue mb-3'
											fill='outline'
											onClick={downloadBureauReport}
											title={t('Download Report')}
											disabled={true}
										/> */}
									</>
								) : (
									<>
										{!['customer', 'nimbuss'].includes(leadInfo?.from) ? (
											<CustomButton
												expand='block'
												className='btn-blue'
												onClick={() => {
													history.replace('/dashboard/home');
												}}
												title={t('Okay')}
											/>
										) : (
											''
										)}
									</>
								)}
							</div>
						</div>
					</div>
				</IonContent>
				<IonFooter className='px-3 py-3'>
					<div className='d-block d-md-none mt-5'>
						{selectedTab === 'Equifax' ? (
							equifaxData ? (
								<>
									<CustomButton
										expand='block'
										className='btn-blue mb-3 mt-3'
										fill='outline'
										onClick={downloadBureauReport}
										title={t('Download Report')}
									/>
								</>
							) : (
								<>
									{!['customer', 'nimbuss'].includes(leadInfo?.from) ? (
										<CustomButton
											expand='block'
											className='btn-blue'
											onClick={() => {
												history.replace('/dashboard/home');
											}}
											title={t('Okay')}
										/>
									) : (
										''
									)}
								</>
							)
						) : cbilData ? (
							<>
								{/* <CustomButton
									expand='block'
									className='btn-blue mb-3'
									fill='outline'
									onClick={downloadBureauReport}
									title={t('Download Report')}
									disabled={true}
								/> */}
							</>
						) : (
							<>
								{!['customer', 'nimbuss'].includes(leadInfo?.from) ? (
									<CustomButton
										expand='block'
										className='btn-blue'
										onClick={() => {
											history.replace('/dashboard/home');
										}}
										title={t('Okay')}
									/>
								) : (
									''
								)}
							</>
						)}
					</div>
				</IonFooter>
			</IonPage>

			{/* Initiate Loan Application / Do you want to allocate this lead .... bottom modal */}
			<CustomModal
				id=''
				isOpen={initiateLoanApplication}
				handleClose={() => setInitiateLoanApplication(false)}
				initialBreakpoint={leadInfo?.from !== 'customer' ? 0.37 : 0.3}
				breakpoints={[0, 0.3, 0.37, 0.75]}
				modalContent={AllocatePopUp(
					() => setAllocateAndroManagerModal(true),
					() => {
						setInitiateLoanApplication(false);
						history.push('/professional-info');
					},
					leadInfo?.from
				)}
				style={{ '--border-radius': '20px' }}
				needIonContentWraper={false}
				needCross={false}
				needArrow={false}
			/>

			{/* Are you sure you want to allocate this lead to Andro Manager....... middle popup */}
			<CustomModal
				id=''
				isOpen={allocateAndroManagerModal}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setAllocateAndroManagerModal(false)}
				breakpoints={[]}
				className='height-auto confirm-popup-middle'
				needIonContentWraper={false}
				modalContent={PageExitPopUp(
					() => {
						setAllocateAndroManagerModal(false);
					},
					() => {
						setInitiateLoanApplication(false);
						setAllocateAndroManagerModal(false);
						history.replace('/dashboard/home');
					},
					'Cancel',
					'Confirm',
					'className',
					'Are you sure you want to allocate this lead to Andro Manager?',
					'By doing this you will not be able to take any action on this lead anymore'
				)}
				needCross={false}
				needArrow={false}
			/>

			{/* on page close popup modal */}
			<CustomModal
				id=''
				isOpen={modalPageClose}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setModalPageClose(false)}
				breakpoints={[]}
				className='confirm-popup-middle height-auto'
				modalContent={PageExitPopUp(
					() => {
						setModalPageClose(false);
					},
					() => {
						setModalPageClose(false);
						if (location?.state?.pathname) {
							history.replace('/lead-list');
						} else {
							history.replace('/dashboard/lead');
						}
					},
					'Cancel',
					'Exit',
					'classname',
					'Are you sure you want to exit ?'
					// "The generated report won't be stored, you will have to generate the report again."
				)}
				needIonContentWraper={false}
				needCross={false}
				needArrow={false}
			/>
			{spinner && (
				<div
					className='position-fixed  w-100  d-flex justify-content-center align-items-center h-100'
					style={{
						top: '0px',
						left: '50%',
						transform: 'translate(-50%,0%)',
						backgroundColor: 'rgba(0,0,0,0.2)',
					}}
				>
					<IonSpinner name='lines-sharp' />
				</div>
			)}
			<CustomModal
				id=''
				isOpen={showNotificationModel}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setShowNitificationModel(false)}
				breakpoints={[]}
				className='confirm-popup-middle height-auto'
				modalContent={
					<div className={`wrapper py-4 confirm-popup-middle height-auto`}>
						<IonItem lines='none'>
							<div className='d-flex flex-column'>
								<p
									className='fs-14 mb-2'
									style={{ color: 'var(--text-dark-grey)' }}
								>
									Please enable notification permission from settings.
								</p>
							</div>
						</IonItem>
						<div className='d-flex justify-content-center gap-3 px-3'>
							<CustomButton
								className='w-50'
								fill='solid'
								onClick={() => {
									NativeSettings.open({
										optionAndroid: AndroidSettings.AppNotification,
										optionIOS: IOSSettings.Notifications,
									});
									setShowNitificationModel(false);
								}}
								title={t('Ok')}
							/>
						</div>
					</div>
				}
				needIonContentWraper={false}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
