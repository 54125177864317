import {
	IonButton,
	IonContent,
	IonFooter,
	IonIcon,
	IonImg,
} from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	fetchMasterData,
	lenderBranchFetch,
	lenderDocumentUpload,
	lenderSpfDetailsFlow,
	saveLenderSpcfInfo,
} from '../../../../store/reducers/lead/leadSlice';
import { CustomInput } from '../../../../components/common/custom-input/CustomInput';
import {
	ContinueLaterPopUp,
	FormatDate,
	checkFieldsValidToContinue,
	showToastAlert,
} from '../../../../helpers/functions';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import {
	primaryApplicantSchemaTwo,
	primaryApplicantFormTwo,
	LAPCoApplicantField,
	selfEmployedDocuments,
	salariedDocuments,
} from './TataHlLAP';
import { LenderNameForAPICall } from '../../../../helpers/lenders';
import {
	CustomModalLeadStatus,
	DynamicDropdown,
} from '../../../../components/common';
import { OfferRejectedMsg } from '../..';

export const PrimaryApplicantForm2: React.FC<any> = ({
	isCoApplicant,
	setSelectedTabValue,
	setCoApplicant,
	setValidForm,
}) => {
	const [yupSchema, setYupSchema] = useState<any>(primaryApplicantSchemaTwo);
	const [modalPageClose, setModalPageClose] = useState(false);
	const [isShowPreview, setToShowPreview] = useState(false);
	const [dmsBranchCode, setDmsBranchCode] = useState([]);
	const [maritalStatus, setMaritalStatus] = useState([]);
	const [residentType, setResidentType] = useState([]);
	const [accomodationType, setAccomodationType] = useState([]);
	const [natureOfBusiness, setNatureOfBusiness] = useState([]);
	const [documentTypes, setDocumentTypes] = useState([]);
	const [coApplicantList, setCoApplicantList] = useState([]);
	const [industry, setIndustry] = useState([]);
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const [primaryApplicantForm, setPrimaryApplicantForm] = useState<any>([
		...primaryApplicantFormTwo,
	]);

	const [leadStatusModalOpen, setLeadStatusModalOpen] = useState(false);
	const [applicationNo, setApplicationNo] = useState('');
	const [occupationdata, setOccupationdata] = useState('');
	const applicantDocUpload: any = useRef([]);
	const history = useHistory();
	const dispatch = useDispatch();
	const { selectedLender, lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);
	// console.log('lenderSpecificDetails', lenderSpecificDetails);
	const documentObj = {
		docUploadType: '',
		docUploadName: '',
		file: '',
		preview: '',
		fileObj: {
			name: '',
			type: '',
		},
		message: '',
	};

	const formFields = {
		dms_branch_code:
			lenderSpecificDetails?.lender_spf_details?.dms_branch_code || '',
		// dms_branch_code: 493,  // UAT
		marital_status:
			lenderSpecificDetails?.lender_spf_details?.marital_status || '',
		resident_type:
			lenderSpecificDetails?.lender_spf_details?.resident_type || '',
		accomodation_type:
			lenderSpecificDetails?.lender_spf_details?.accomodation_type || '',
		nature_of_business:
			lenderSpecificDetails?.lender_spf_details?.nature_of_business || '',
		nature_of_business_others:
			lenderSpecificDetails?.lender_spf_details?.nature_of_business_others ||
			'',
		industry: lenderSpecificDetails?.lender_spf_details?.industry || '',
		date_of_joining:
			lenderSpecificDetails?.lender_spf_details?.date_of_joining || undefined,
		documents: [documentObj] || [],
	};

	const extraFormFields = {
		...formFields,
		is_co_applicant:
			lenderSpecificDetails?.lender_spf_details?.is_co_applicant || '',
	};

	const {
		handleSubmit,
		control,
		watch,
		getValues,
		setValue,
		reset,
		trigger,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(yupSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues:
			leadFlowData?.selectedProductData?.product_category_id == 4
				? extraFormFields
				: formFields,
	});

	useEffect(() => {
		const combinedSchema =
			occupationdata === 'Salaried'
				? primaryApplicantSchemaTwo.concat(salariedDocuments)
				: primaryApplicantSchemaTwo.concat(selfEmployedDocuments);
		setYupSchema(combinedSchema);
	}, [occupationdata]);

	// console.log('🚀 ~ yupSchema:', yupSchema);
	// console.log('🚀 ~ errors:', errors);
	// console.log('🚀 ~ isValid:', isValid);
	// console.log('🚀 ~ watch:', watch());

	useEffect(() => {
		if (lenderSpecificDetails?.lender_spf_details?.documents?.length > 0) {
			setValue(
				'documents',
				lenderSpecificDetails?.lender_spf_details.documents
			);
			trigger('documents');
		}

		if (leadFlowData?.selectedProductData?.product_category_id == 4) {
			setPrimaryApplicantForm([
				LAPCoApplicantField,
				...primaryApplicantFormTwo,
			]);

			if (
				lenderSpecificDetails?.lender_spf_details?.is_co_applicant === 'Yes'
			) {
				setCoApplicant(true);
			} else {
				setCoApplicant(false);
			}
		}

		getDataFromMaster();
		setOccupationState();
	}, []);

	useEffect(() => {
		setValidForm(isValid);
	}, [isValid]);

	const getDataFromMaster = async () => {
		await dispatch(fetchMasterData({ param: 'TATALAP_MARITAL_STATUS' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setMaritalStatus(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATALAP_RESIDENT_TYPE' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setResidentType(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATALAP_ACCOMODATON_TYPE' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setAccomodationType(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATAHLLAP_NATURE_OF_BUSINESS' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setNatureOfBusiness(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATAHLLAP_INDUSTRY' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setIndustry(result.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'HOME_CO_APPLICANT' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setCoApplicantList(result.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATALAP_DOCUMENT_TYPE' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setDocumentTypes(result.data);
				}
			});

		await dispatch(
			lenderBranchFetch({
				data: {
					product: 'HL_LAP',
					city: selectedLender[0]?.rm_branch,
					lender_name: LenderNameForAPICall.TATA_HL_LAP,
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setDmsBranchCode(result?.data);
				}
			});
	};

	/**
	 * function to set the Occupation
	 */

	const setOccupationState = async () => {
		setOccupationdata(leadFlowData.professional_details.Occupation);
		// console.log('IsValid Applications1 ');
		// console.log(lenderSpecificDetails?.lender_spf_details?.isValidApplicant1);
	};

	/**
	 * Set dropdown data
	 * @param name field name
	 */
	const setDropDownOptions = (name: string) => {
		switch (name) {
			case 'dms_branch_code':
				return dmsBranchCode;
			case 'marital_status':
				return maritalStatus;
			case 'resident_type':
				return residentType;
			case 'accomodation_type':
				return accomodationType;
			case 'nature_of_business':
				return natureOfBusiness;
			case 'industry':
				return industry;
			case 'is_co_applicant':
				return coApplicantList;
		}
	};

	/**
	 * Upload documents
	 * @param event file upload event
	 * @param key documents array index
	 */
	const uploadDocuments = async (
		event: any,
		key: number,
		docUploadType: string
	) => {
		const file = event.target.files[0];
		const fileTypes = ['jpg', 'jpeg', 'png', 'pdf'];
		const fType = file?.type?.split('/')?.reverse()[0];
		if (!fType || !fileTypes?.includes(fType?.toLowerCase())) {
			setToShowPreview(false);
			setValue(
				`documents.${key}.message`,
				'Uploaded file is invalid. Only JPG/JPEG, PNG and PDF files are allowed.'
			);
			return;
		}

		if (file?.size / 1024 > 2048) {
			setToShowPreview(false);
			setValue(`documents.${key}.message`, 'File size should be less than 2MB');
			return;
		}
		setValue(`documents.${key}.message`, '');

		const formData: any = new FormData();
		formData.append('lender_name', LenderNameForAPICall.TATA_HL_LAP);
		formData.append('file', file);

		await dispatch(lenderDocumentUpload({ data: formData }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					const fileName = result.key?.split('/')?.reverse();
					setValue(`documents.${key}.docUploadName`, fileName[0]);
					setValue(`documents.${key}.file`, result?.key);
					setValue(`documents.${key}.fileObj`, file);
					setValue(`documents.${key}.preview`, URL.createObjectURL(file));
					setValue(`documents.${key}.docUploadType`, docUploadType);
				} else {
					// setValue(`documents.${key}`, {
					// 	...documentObj,
					// 	docUploadType: docUploadType,
					// 	message: result.message,
					// });
				}
				trigger('documents');
				setToShowPreview(false);
			});
		event.target.value = null;
	};

	useEffect(() => {
		setToShowPreview(true);
	}, [isShowPreview, !isShowPreview]);

	/**
	 * Show selected document preview
	 * @param key document array index
	 */
	const showFilePreview = (key: number) => {
		let previewItem: any;
		const fileObj: any = getValues(`documents.${key}.fileObj`);

		if (fileObj?.type?.includes('image')) {
			previewItem = (
				<>
					<img
						src={getValues(`documents.${key}.preview`)}
						alt='preview'
						className='me-2 file_preview'
						width={'100%'}
					/>
					<p className='filename'>{fileObj.name}</p>
				</>
			);
		} else if (fileObj?.type?.includes('pdf')) {
			previewItem = (
				<>
					<img
						src='/images/pdf.svg'
						alt='preview'
						className='me-2 file_preview'
						width={80}
					/>
					<p className='filename'>{fileObj.name}</p>
				</>
			);
		} else if (getValues(`documents.${key}.message`)) {
			previewItem = (
				<p className='text-red'>{t(getValues(`documents.${key}.message`))}</p>
			);
		}
		return previewItem;
	};

	/**
	 * Add new section to upload document
	 */
	const addNewDocument = () => {
		setValue('documents', [...getValues('documents'), documentObj]);
		reset({
			...getValues(),
		});
	};

	/**
	 * Remove specific item
	 * @param key index
	 */
	const removeDocument = (key: number) => {
		const documents = getValues('documents').filter(
			(ele: any, k: any) => k !== key
		);
		setValue('documents', documents);
		reset({
			...getValues(),
		});
	};

	/**
	 * On submit to save user data and after that
	 * @param data Form data
	 */
	const onSubmit = async (data: any, flag?: any) => {
		const documentsArr: any = [];

		getValues('documents')?.forEach((el: any) => {
			el.docUploadType &&
				el.docUploadName &&
				el.file &&
				documentsArr.push({
					applicantType: '1',
					docUploadType: el.docUploadType,
					docUploadName: el.docUploadName,
					file: el.file,
				});
		});

		const req: any = {
			lender_spf_details: {
				...lenderSpecificDetails?.lender_spf_details,
				...data,
				date_of_joining: FormatDate(data?.date_of_joining, 'yyyy-mm-dd'),
				documents: documentsArr,
				isValidApplicant2: isValid,
			},
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.TATA_HL_LAP,
			step: 'step1',
			isNewLead: flag !== 'continue_later' && !isCoApplicant,
		};

		if (lenderSpecificDetails?.lead_spf_detail_id) {
			req.lead_spf_detail_id = lenderSpecificDetails?.lead_spf_detail_id;
		}

		if (req.lender_spf_details.nature_of_business !== 'Others') {
			delete req.lender_spf_details.nature_of_business_others;
		}

		setShowOfferErrMsg('');
		// console.log('req');
		// console.log(req);
		await dispatch(saveLenderSpcfInfo({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					if (isCoApplicant) {
						setSelectedTabValue('CoApplicantForm0');
					} else {
						if (req.isNewLead) {
							let appNo: any = `Webtop No: ${result.data?.response?.WEBTOP_ID}\nOpportunity ID: ${result.data?.response?.OpportunityId}`;
							if (result.data?.response?.FinnOne_Application_Number) {
								appNo += `\nFinnOne App. No: ${result.data?.response?.FinnOne_Application_Number}`;
							}
							setApplicationNo(appNo);
							setLeadStatusModalOpen(true);
						}
					}
					dispatch(
						lenderSpfDetailsFlow({
							...lenderSpecificDetails,
							lender_spf_details: req.lender_spf_details,
						})
					);
				} else {
					setShowOfferErrMsg(result?.message);
				}
			});
	};

	/**
	 * Continue Later and Submit button
	 */
	const continueLaterAndSubmitBtn = () => {
		return (
			<>
				<CustomButton
					className='w-100 fw-bold dashboard-add-lead-button mb-3'
					expand='block'
					fill='outline'
					title={t('Continue Later')}
					onClick={() => {
						if (checkFieldsValidToContinue(errors)) {
							setModalPageClose(true);
						} else {
							showToastAlert({
								type: 'error',
								message: 'Please fix above errors',
							});
						}
					}}
				/>
				<CustomButton
					type='submit'
					className='w-100 fw-bold dashboard-add-lead-button'
					expand='block'
					title={t('Proceed')}
					disabled={
						!lenderSpecificDetails?.lender_spf_details?.isValidApplicant1 ||
						!isValid
					}
					onClick={() => {
						onSubmit(getValues());
					}}
				/>
			</>
		);
	};

	console.log(">>", !lenderSpecificDetails?.lender_spf_details?.isValidApplicant1 ||
		!isValid)

	const docList: any = [
		{
			id: 1,
			heading: 'KYC Documents',
			label: 'Identity Proof',
			key: 'Identity_Proof',
			occupationCondition1: 'Salaried',
			occupationCondition2: '',
		},
		{
			id: 2,
			heading: '',
			subHeading: 'Address Proof',
			label: 'Current Address',
			key: 'Cur_Address_Proof',
			occupationCondition1: 'Salaried',
			occupationCondition2: '',
		},
		{
			id: 3,
			heading: '',
			subHeading: '',
			label: 'Permanent Address',
			key: 'Per_Address_Proof',
			occupationCondition1: 'Salaried',
			occupationCondition2: '',
		},
		{
			id: 4,
			heading: 'NRI Documents',
			subHeading: '',
			label: 'KYC of POA (to be collected along with GPOA)',
			key: 'Kyc_of_Poa',
			occupationCondition1: 'Salaried',
			occupationCondition2: '',
		},
		{
			id: 5,
			heading: '',
			subHeading: '',
			label: 'Valid Passport (Visa with minimum 6 months validity)',
			key: 'Valid_Passport',
			occupationCondition1: 'Salaried',
			occupationCondition2: '',
		},
		{
			id: 6,
			heading: 'Income Documents',
			subHeading: '',
			label: 'Fixed Income (Latest 3-month salary slip )',
			key: 'Fixed_Income',
			occupationCondition1: 'Salaried',
			occupationCondition2: '',
		},
		{
			id: 7,
			heading: '',
			subHeading: 'Variable Income (Incentive or overtime)',
			label: 'Last 12 months as per policy ',
			key: 'Variable_Income',
			occupationCondition1: 'Salaried',
			occupationCondition2: '',
		},
		{
			id: 8,
			heading: '',
			subHeading: '',
			label: 'Latest Form 16 / Form 26 AS / ITR',
			key: 'Form_16',
			occupationCondition1: 'Salaried',
			occupationCondition2: '',
		},
		{
			id: 9,
			heading: '',
			subHeading: 'Appointment Letter',
			label: 'In case of recent job change, appointment letter along with CTC breakup is required',
			key: 'job_change',
			occupationCondition1: 'Salaried',
			occupationCondition2: '',
		},
		{
			id: 10,
			heading: 'Bank Statement Documents',
			subHeading: '',
			label: 'Bank Statement (Last 12 month reflecting salary credit)',
			key: 'Bank_Statement',
			occupationCondition1: 'Salaried',
			occupationCondition2: '',
		},
		{
			id: 11,
			heading: 'Obligation Details',
			subHeading: '',
			label: 'Repayment Track Record / Last 12 months Bank Statement with EMI reflection of all existing loans Loan/OD account',
			key: 'Obligation_Bank_Statement',
			occupationCondition1: 'Salaried',
			occupationCondition2: '',
		},
		{
			id: 12,
			heading: 'Other',
			subHeading: '',
			label: 'Balance Transfer Cases (Bank Statement for EMI reflection)',
			key: 'Balance_Transfer_Cases',
			occupationCondition1: 'Salaried',
			occupationCondition2: '',
		},
		{
			id: 13,
			heading: 'KYC Documents',
			subHeading: 'Individual',
			label: 'Identity Proof',
			key: 'identify_Proof',
			occupationCondition1: 'Self Employed - Professional',
			occupationCondition2: 'Self Employed - Business',
		},
		{
			id: 14,
			heading: '',
			subHeading: 'Address Proof',
			label:'Current Address',
			key: 'self_curr_Address_Proof',
			occupationCondition1: 'Self Employed - Professional',
			occupationCondition2: 'Self Employed - Business',
		},
		{
			id: 15,
			heading: '',
			subHeading: '',
			label: 'Permanent Address',
			key: 'self_perm_Address_Proof',
			occupationCondition1: 'Self Employed - Professional',
			occupationCondition2: 'Self Employed - Business',
		},
		{
			id: 16,
			heading: 'Non Individual (Sole Proprietorship)',
			subHeading: '',
			label: 'Valid Office address proof ',
			key: 'non_indi_self_Address_Proof',
			occupationCondition1: 'Self Employed - Professional',
			occupationCondition2: 'Self Employed - Business',
		},
		{
			id: 17,
			heading: '',
			subHeading: '',
			label: 'Valid Entity Proof/ITR ',
			key: 'itr_Proof',
			occupationCondition1: 'Self Employed - Professional',
			occupationCondition2: 'Self Employed - Business',
		},
		{
			id: 18,
			heading: 'Non Individual (Partnership Firm)',
			subHeading: '',
			label: 'PAN of Entity',
			key: 'pan_entity',
			occupationCondition1: 'Self Employed - Professional',
			occupationCondition2: 'Self Employed - Business',
		},
		{
			id: 19,
			heading: 'Non Individual (Pvt/Ltd Company)',
			subHeading: '',
			label: 'KYC of Authorised Signatory',
			key: 'KYC_of_Authorised_Signatory',
			occupationCondition1: 'Self Employed - Professional',
			occupationCondition2: 'Self Employed - Business',
		},
		{
			id: 20,
			heading: 'Income Docs',
			subHeading: '',
			label: 'Business continuity proof /ITR for 2 years',
			key: 'income_docs',
			occupationCondition1: 'Self Employed - Professional',
			occupationCondition2: 'Self Employed - Business',
		},
		{
			id: 21,
			heading: 'Bank Statement',
			subHeading: '',
			label:
				'Self-attested last 12 months Bank Statement of all operative business A/Cs',
			key: 'Bank_Statement_op_business_ac',
			occupationCondition1: 'Self Employed - Professional',
			occupationCondition2: 'Self Employed - Business',
		},
		{
			id: 22,
			heading: '',
			subHeading: '',
			label: 'Self-attested last 12 months Bank Statement of all applicants',
			key: 'Bank_Statement_all_app',
			occupationCondition1: 'Self Employed - Professional',
			occupationCondition2: 'Self Employed - Business',
		},
		{
			id: 23,
			heading: 'Obligation Details',
			subHeading: '',
			label:
				'Repayment Track Record / Last 12 months Bank Statement with EMI reflection of all existing loans Loan/OD account',
			key: 'Obligation_Details',
			occupationCondition1: 'Self Employed - Professional',
			occupationCondition2: 'Self Employed - Business',
		},
		{
			id: 24,
			heading: 'Other',
			subHeading: '',
			label: 'BT case/seller BT cases SOA/ Bank statement 12 months',
			key: 'Balance_transfer',
			occupationCondition1: 'Self Employed - Professional',
			occupationCondition2: 'Self Employed - Business',
		},
		{
			id: 25,
			heading: 'HE-MSME Cases',
			subHeading: '',
			label: 'Udyam Registration Certificate',
			key: 'self_urc',
			occupationCondition1: 'Self Employed - Professional',
			occupationCondition2: 'Self Employed - Business',
		},
	];

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 pt-3 p-md-5 border-radius-10'>
							<form onSubmit={handleSubmit(onSubmit)}>
								{primaryApplicantForm.map(
									({ name, type, placeholder }: any, index: any) => {
										const errorText: any =
											errors && Object.keys(errors).length > 0 && errors;
										return (
											<div key={index}>
												{type === 'dropdown' ? (
													<div style={{ marginBottom: '15px' }}>
														<Controller
															control={control}
															render={({
																field: { onChange, onBlur, name },
															}) => (
																<DynamicDropdown
																	label={t(`${placeholder}`)}
																	data={setDropDownOptions(name)}
																	name={name}
																	onChange={(e: any) => {
																		onChange(e);

																		if (name === 'is_co_applicant') {
																			if (e.detail.value === 'Yes') {
																				setCoApplicant(true);
																			} else {
																				setCoApplicant(false);
																			}
																			dispatch(
																				lenderSpfDetailsFlow({
																					...lenderSpecificDetails,
																					lender_spf_details: {
																						...lenderSpecificDetails?.lender_spf_details,
																						is_co_applicant: e.detail.value,
																					},
																				})
																			);
																		}

																		if (name === 'nature_of_business') {
																			trigger(name);
																			setValue(
																				'nature_of_business_others',
																				null
																			);
																		}
																	}}
																	bindField={
																		name === 'accomodation_type'
																			? 'id'
																			: name === 'dms_branch_code'
																			? 'branch_code'
																			: 'value'
																	}
																	showField1={
																		name === 'dms_branch_code'
																			? 'branch_name'
																			: 'value'
																	}
																	value={getValues(name)}
																	onBlur={onBlur}
																	errors={errorText[name]?.message}
																/>
															)}
															name={name}
														/>
													</div>
												) : name === 'date_of_joining' ? (
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<CustomInput
																type={type}
																needSuffixIcon={true}
																suffixClassName={
																	'position-absolute date-of-birth-sufix'
																}
																suffixText={
																	<IonImg
																		src='/images/icons/input-dob-calendar-icon.svg'
																		alt='dob'
																	/>
																}
																outerClassName={'position-relative'}
																label={t(placeholder)}
																onBlur={onBlur}
																id={name}
																onChange={(e: any) => {
																	onChange(e);
																}}
																value={getValues(name)}
																errors={t(errorText[name]?.message || '')}
															/>
														)}
														name={name}
													/>
												) : name === 'nature_of_business_others' ? (
													getValues('nature_of_business') === 'Others' && (
														<Controller
															control={control}
															render={({
																field: { onChange, onBlur, name },
															}) => (
																<CustomInput
																	type={type}
																	label={t(placeholder)}
																	onBlur={onBlur}
																	id={name}
																	onChange={(e: any) => {
																		onChange(e);
																	}}
																	value={getValues(name)}
																	errors={t(errorText[name]?.message || '')}
																/>
															)}
															name={name}
														/>
													)
												) : (
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<CustomInput
																type={type}
																label={t(placeholder)}
																onBlur={onBlur}
																id={name}
																onChange={(e: any) => {
																	onChange(e);
																}}
																value={getValues(name)}
																errors={t(errorText[name]?.message || '')}
															/>
														)}
														name={name}
													/>
												)}
											</div>
										);
									}
								)}

								{docList.map(
									(docItem: any) =>
										(occupationdata === docItem?.occupationCondition1 ||
											occupationdata === docItem?.occupationCondition2) && (
											<div className='border-top pt-2'>
												<h4 className=''>{t(docItem?.heading)}</h4>
												<h6>{docItem?.subHeading}</h6>
												<label>{docItem?.label}</label>
												<div key={docItem?.key}>
													<div className='mb-4'>
														<CustomButton
															onClick={() =>
																applicantDocUpload?.current[
																	docItem?.key
																]?.click()
															}
															expand='block'
															fill={'solid'}
														>
															<img
																src='/images/icons/upload-white.svg'
																alt='upload'
																className='me-2'
															/>
															{t('Select File')}
														</CustomButton>

														{/* input file upoad on button click */}
														<input
															type='file'
															style={{ display: 'none' }}
															accept='image/jpg,image/jpeg,image/png,application/pdf'
															ref={(el) =>
																(applicantDocUpload.current[docItem?.key] = el)
															}
															id={docItem?.key}
															onChange={(event) =>
																uploadDocuments(
																	event,
																	docItem?.id,
																	docItem?.key
																)
															}
														/>

														{isShowPreview && (
															<div className='mt-2 mb-2'>
																{showFilePreview(docItem?.id)}
															</div>
														)}
														<span className='text-muted font-small'>
															Acceptable File Format : png, jpeg, jpg, pdf
															Maximum file size limit : 2MB
														</span>
													</div>
													{/* 
													<div className='add_rm_buttons'>
														{getValues('documents').length > 1 && (
															<IonButton
																shape='round'
																type='button'
																color='danger'
																onClick={() => removeDocument(docItem?.id)}
															>
																<IonIcon src='/images/icons/delete.svg' />
															</IonButton>
														)}
													</div> */}
												</div>
											</div>
										)
								)}
							</form>

							<div className={`d-none d-md-block mt-4`}>
								{continueLaterAndSubmitBtn()}
							</div>
						</div>
					</div>
				)}
			</IonContent>

			{!showOfferErrMsg && (
				<IonFooter className='d-block d-md-none position-relative ion-padding lead_ftr_btn'>
					{continueLaterAndSubmitBtn()}
				</IonFooter>
			)}

			{leadStatusModalOpen && (
				<CustomModalLeadStatus
					isOpen={leadStatusModalOpen}
					applicationNo={applicationNo}
					handleClose={() => setLeadStatusModalOpen(false)}
				/>
			)}

			<CustomModal
				needIonContentWraper={false}
				isOpen={modalPageClose}
				initialBreakpoint={undefined}
				handleClose={() => setModalPageClose(false)}
				breakpoints={[]}
				className='height-auto confirm-popup-middle continue-later'
				modalContent={ContinueLaterPopUp(
					() => {
						setModalPageClose(false);
					},
					() => {
						new Promise(function (myResolve) {
							setTimeout(function () {
								myResolve(history.push('/dashboard/lead'));
								onSubmit(getValues(), 'continue_later');
								setModalPageClose(false);
							}, 10);
						});
					},
					'Cancel',
					'Continue Later',
					'className',
					'Are You Sure You Want to Exit?',
					`${t('You can continue later from where you left off.')}`
				)}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
