import React, { useEffect, useState } from 'react';
import { IonContent, IonFooter, IonPage } from '@ionic/react';
import './AddFirmDetails.scss';
import { Header } from '../../../components/layout/header/Header';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { CustomCheckbox } from '../../../components/common/custom-checkbox/CustomCheckbox';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import inputData from './inputs.json';
import { useHistory } from 'react-router';
import { REGEX, USER_ROLES } from '../../../helpers/constant';
import { useDispatch, useSelector } from 'react-redux';
import {
	dmaRegister,
	getdmaRegisterDetails,
	selfDMARegister,
} from '../../../store/reducers/user/userSlice';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { RejectionModal } from '../../teams/RejectionModal';
import {
	checkFieldsValidToContinue,
	disableNumberArrows,
	fetchStateCityByPincode,
	showToastAlert,
} from '../../../helpers/functions';
import StateCity from '../../../components/common/StateCity';
import { ConsentCheckbox } from '../../../components/common/consent-checkbox/ConsentCheckbox';
import { unwrapResult } from '@reduxjs/toolkit';

const validationSchema = yup.object().shape({
	firm_name: yup
		.string()
		.required('Firm Name is Required.')
		.matches(REGEX.ALLOWCHARANDNUMBER, 'Please Enter Valid Input.'),
	pincode: yup
		.string()
		.trim()
		.required('Pincode Number is Required.')
		.length(6, 'Pin Code Should Be 6 Digits'),
	state: yup.string().trim().required('State is Required'),
	city: yup.string().trim().required('City is Required'),
	firm_email: yup
		.string()
		.required('Firm Email is Required')
		.matches(REGEX.EMAIL, 'Please Enter Valid Email'),
	acceptTerms: yup.boolean().oneOf([true], 'You need to check the consent'),
});

export const AddFirmDetails: React.FC = () => {
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	const dmaSelector = useSelector((state: any) => state?.users);
	const [rejectionModal, setRejectionModal] = useState(false);
	const [pinCodeData, setPinCodeData] = useState<any>([]);
	const [isMoreContent, setMoreContent] = useState(false);
	const dispatch: any = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();
	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		trigger,
		reset,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			firm_name: '',
			firm_email: '',
			pincode: '',
			state: '',
			city: '',
			acceptTerms: false,
		},
	});

	const addFirmData: any = useSelector((state: any) => state);
	let continueLaterFlag = false;
	const onSubmit = (data: any) => {
		const myPromise = new Promise(function (myResolve) {
			setTimeout(function () {
				// if (dmaSelector?.dmaFlow?.dmaId) {
				continueLater(true);
				// }
				myResolve(true);
			}, 10);
		});
		myPromise.then(() => {
			dispatch(
				selfDMARegister({
					// state: pinCodeValue.state || '',
					// city: pinCodeValue.city || '',
					...data,
					...addFirmData?.users?.registerUser,
				})
			);
		});
	};

	const isDmaPage = location.pathname === '/add-dma';

	useEffect(() => {
		if (isDmaPage) {
			reset({
				firm_name: '',
				firm_email: '',
				state: '',
				city: '',
				pincode: '',
				acceptTerms: false,
			});
			setPinCodeData([]);
		}
	}, [isDmaPage]);

	useEffect(() => {
		if (location.pathname !== '/add-firm-details') {
			return;
		}
		if (dmaSelector?.dmaFlow?.dmaId) {
			getDmaDetails();
		}
	}, [location.pathname]);

	const continueLater = async (isProceed = false) => {
		if (checkFieldsValidToContinue(errors)) {
			continueLaterFlag = true;

			let id;
			if (dmaSelector?.dmaFlow?.dmaId) {
				id = dmaSelector?.dmaFlow?.dmaId;
			} else {
				id = dmaSelector?.user_details?.id;
			}

			const obj: any = getValues();
			obj['pan'] = dmaSelector?.registerUser?.pan || dmaSelector?.dmaAbout?.pan;
			obj['entityType'] =
				dmaSelector?.registerUser?.entityType ||
				dmaSelector?.dmaAbout?.RegisteredAs;
			obj['continueLater'] = continueLaterFlag;
			obj['status'] = '3';
			await dispatch(dmaRegister({ param: '/' + id, data: obj }))
				.then(unwrapResult)
				.then(async (response: any) => {
					if (response?.status) {
						reset({
							firm_name: '',
							firm_email: '',
							state: '',
							city: '',
							pincode: '',
							acceptTerms: false,
						});
						setPinCodeData([]);
						continueLaterFlag = false;
						if (!isProceed) {
							history.replace('/dashboard/teams/');
						} else {
							history.replace('/contact-person-details');
						}
					}
				});
		} else {
			showToastAlert({
				type: 'error',
				message: 'Please fix above errors',
			});
		}
	};

	const getDmaDetails = async () => {
		if (dmaSelector?.dmaFlow?.dmaId) {
			await dispatch(
				getdmaRegisterDetails({ param: '/' + dmaSelector?.dmaFlow?.dmaId })
			)
				.then(unwrapResult)
				.then(async (response: any) => {
					if (response?.status) {
						reset(response?.user);
						if (response?.user?.pincode?.length === 6) {
							fetchStateCityByPincode(
								setValue,
								'city',
								'state',
								setPinCodeData,
								dispatch,
								response?.user?.pincode,
								trigger
							);
						}
					}
				});
		}
	};

	return (
		<IonPage className=''>
			<Header
				name=''
				backButton={true}
				needArrow={true}
				backButtonText=''
			/>
			<IonContent className=''>
				<div className='bg-web small-container h-100 overflowY-auto'>
					<div className='bg-web-white container-width-md border-md-radius-10 h-100 d-md-flex justify-content-md-between flex-md-column'>
						<div className='overflowY-md-auto'>
							<div className='px-3 py-md-3'>
								<h4 className='ion-no-margin title fs-24 darkest-grey pb-2'>
									{t('Firm Info')}
								</h4>
								{inputData.map(({ name, type, placeholder }: any) => {
									const T: any = errors;
									return (
										<Controller
											key={name}
											control={control}
											render={({ field: { onChange, onBlur } }) => (
												<CustomInput
													type={type}
													placeholder={t(placeholder) + '*'}
													onBlur={onBlur}
													onChange={(e: any) => {
														if (name === 'pincode') {
															if (e.detail.value.length === 6) {
																fetchStateCityByPincode(
																	setValue,
																	'city',
																	'state',
																	setPinCodeData,
																	dispatch,
																	e.detail.value,
																	trigger
																);
																// pinCodeAction(e.detail.value);
															} else {
																setValue('state', '');
																setValue('city', '');
																setPinCodeData([]);
															}
														}
														onChange(e);
													}}
													onBeforeInput={(evt: any) => {
														if (type == 'number') {
															!/[0-9]/.test(evt.data) && evt.preventDefault();
														}
													}}
													onKeyDown={(e: any) => {
														if (type == 'number') {
															disableNumberArrows(e);
														}
													}}
													onWheel={(e: any) => {
														if (type == 'number') {
															e.target.blur();
														}
													}}
													value={getValues(name)}
													errors={t(T[name]?.message)}
												/>
											)}
											name={name}
										/>
									);
								})}
								<StateCity
									cityInputName='city'
									control={control}
									setValue={setValue}
									getValues={getValues}
									pinCodeData={pinCodeData}
									trigger={trigger}
								/>
							</div>
							{/* </form> */}

							<div className='position-relative'>
								<Controller
									control={control}
									render={({ field: { onChange, onBlur, name } }) => (
										<div
											className='d-flex py-3'
											style={{ background: '#EDF8EC' }}
										>
											<div className='position-relative'>
												<CustomCheckbox
													labelPlacement='end'
													name={name}
													isBackGround={true}
													// isChecked={confirmCheck}
													isChecked={getValues('acceptTerms')}
													value={getValues('acceptTerms')}
													onBlur={onBlur}
													onChange={(event: any) => {
														onChange(event);
														setValue('acceptTerms', event.detail.checked);
													}}
													className={
														(errors[name] ? 'ion-checkbox-inValid' : '') +
														' pt-1 px-3'
													}
													errorText={errors['acceptTerms']?.message}
													errorTextClass='position-absolute checkbox-style1'
												/>
											</div>
											<ConsentCheckbox isMoreContent={isMoreContent} />
										</div>
									)}
									name={'acceptTerms'}
								/>
								<div
									onClick={() => {
										setMoreContent(!isMoreContent);
									}}
									className='position-absolute right-0 ion-padding py-1 text-primary fs-12 cursor-pointer fw-600'
								>
									{isMoreContent ? `- ${t('Less')}` : `+ ${t('More')}`}
								</div>
							</div>
						</div>
						<div className='d-none d-md-block'>
							{dmaSelector?.dmaFlow?.dmaId && (
								<div className=' ion-padding reject-continue-later  d-flex gap-3 my-2'>
									<CustomButton
										onClick={() => {
											setRejectionModal(true);
										}}
										fill={'outline'}
										title={t('Reject')}
										className={'w-100'}
									/>
									<CustomButton
										fill={'outline'}
										title={t('Continue Later')}
										onClick={() => {
											continueLater();
										}}
										className={'w-100'}
									/>
								</div>
							)}
							<div
								className={` ion-padding ${
									dmaSelector?.dmaFlow?.dmaId && 'pt-0'
								}`}
							>
								<CustomButton
									expand='block'
									disabled={!isValid}
									title={t('Proceed')}
									className={'btn-blue'}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				</div>
			</IonContent>
			<IonFooter className='add-firm-details-button'>
				<div className='d-block d-md-none position-relative'>
					{dmaSelector?.dmaFlow?.dmaId && (
						<div className=' ion-padding reject-continue-later  d-flex gap-3 my-2'>
							<CustomButton
								onClick={() => {
									setRejectionModal(true);
								}}
								fill={'outline'}
								title={'Reject'}
								className={'w-100'}
							/>
							<CustomButton
								fill={'outline'}
								title={'Continue Later'}
								onClick={() => {
									continueLater();
								}}
								className={'w-100'}
							/>
						</div>
					)}
					<div
						className={` ion-padding ${dmaSelector?.dmaFlow?.dmaId && 'pt-0'}`}
					>
						<CustomButton
							expand='block'
							disabled={!isValid}
							title={t('Proceed')}
							className={'btn-blue'}
							onClick={handleSubmit(onSubmit)}
						/>
					</div>
				</div>
			</IonFooter>
			<CustomModal
				id='rejectionmodal'
				isOpen={rejectionModal}
				// initialBreakpoint={0.4}
				initialBreakpoint={isWeb ? 1 : 0.4}
				handleClose={() => setRejectionModal(false)}
				breakpoints={[0, 0.4, 0.6, 0.75]}
				className='side-modal'
				modalContent={
					<RejectionModal
						title={
							dmaSelector?.user_details?.roleType?.[0] === USER_ROLES.BUH
								? 'Request Revision'
								: t('Reason for Rejection')
						}
						reasonNeeded={
							dmaSelector?.user_details?.roleType?.[0] === USER_ROLES.BUH
								? false
								: true
						}
						resetPinCodeData={() => {
							setPinCodeData([]);
							// setPincodeValue({});
						}}
						setRejectionModal={setRejectionModal}
					/>
				}
				needCross={false}
				needArrow={false}
			/>
		</IonPage>
	);
};
