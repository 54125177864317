import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IonSegment, IonSegmentButton } from '@ionic/react';
import { t } from 'i18next';
import { ApplicantForms } from './ApplicantForms';

export const BLForms: React.FC<any> = () => {
	const [selectedTab, setSelectedTab] = useState('PrimaryApplicantForm');
	const [isShowForm, setShowForm] = useState<any>(false);
	const [coApplicantTabIndex, setCoApplicantTabIndex] = useState(0);
	const [totalCoApplicant, setTotalCoApplicant] = useState([1]);
	const [isCoApplicant, setCoApplicant] = useState(false);
	const { lenderSpecificDetails } = useSelector((state: any) => state?.lead);

	useEffect(() => {
		if (location.pathname !== '/lead-lender-specific-form-steps') {
			return;
		}
		if (lenderSpecificDetails?.lender_spf_details?.is_co_applicant) {
			setCoApplicant(true);
		}
		if (lenderSpecificDetails?.lender_spf_details?.co_applicant) {
			const coAppData = JSON.parse(
				JSON.stringify(lenderSpecificDetails?.lender_spf_details?.co_applicant)
			);
			for (let i = 0; i < coAppData.length - 1; i++) {
				setTotalCoApplicant([...totalCoApplicant, i]);
			}
		}
	}, []);

	useEffect(() => {
		setShowForm(true);
	}, [!isShowForm]);

	/**
	 * Handle tab change
	 * @param value tab value
	 */
	const handleSegmentChange = (value: any) => {
		setSelectedTab(value);
		setShowForm(false);
		if (value.includes('CoApplicantForm')) {
			setCoApplicantTabIndex(value.slice(-1));
		}
	};

	/**
	 * Check device inner width
	 */
	const isMobileDevice = () => {
		return window.innerWidth <= 768;
	};

	return (
		<>
			<IonSegment
				scrollable={true}
				value={selectedTab}
				onIonChange={(e) => handleSegmentChange(e.target.value)}
				style={{
					position: 'sticky',
					top: 0,
					zIndex: 999,
					backgroundColor: 'white',
				}}
			>
				<IonSegmentButton value='PrimaryApplicantForm'>
					<label>
						<span>{t(isMobileDevice() ? 'App. Form' : 'Applicant Form')}</span>
					</label>
				</IonSegmentButton>

				{isCoApplicant &&
					totalCoApplicant.map((el: any, idx: number) => {
						return (
							<IonSegmentButton
								value={`CoApplicantForm${idx}`}
								key={idx}
							>
								<label>
									<span>{t(`Co-Form ${idx + 1}`)}</span>
								</label>
							</IonSegmentButton>
						);
					})}
			</IonSegment>

			{isShowForm && (
				<ApplicantForms
					selectedTab={selectedTab}
					handleSegmentChange={handleSegmentChange}
					coTabIndex={coApplicantTabIndex}
					setTotalCoApplicant={setTotalCoApplicant}
					totalCoApplicant={totalCoApplicant}
					setCoApplicant={setCoApplicant}
				/>
			)}
		</>
	);
};
