import { useEffect, useState } from 'react';
import { IonButton, IonContent, IonFooter, IonIcon } from '@ionic/react';
import { t } from 'i18next';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import {
	ContinueLaterPopUp,
	FormatDate,
	checkFieldsValidToContinue,
	showToastAlert,
} from '../../../../helpers/functions';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import { CoApplicantForm1 } from './CoApplicantForm1';
import { CoApplicantForm2 } from './CoApplicantForm2';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { coApplicantFormOneSchema, coApplicantFormTwoSchema } from './TataBl';
import {
	lenderSpfDetailsFlow,
	saveLenderSpcfInfo,
} from '../../../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { LenderNameForAPICall } from '../../../../helpers/lenders';
import { OfferRejectedMsg } from '../..';

export const CoApplicantForm: React.FC<any> = ({
	coTabIndex,
	handleSegmentChange,
	totalCoApplicant,
	setTotalCoApplicant,
	setValidForm,
	isValidForm,
}) => {
	const [modalPageClose, setModalPageClose] = useState(false);
	const [currentPage, setCurrentPage] = useState<any>(1);
	const [coApplicantData, setCoApplicantData] = useState<any>([]);
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const dispatch = useDispatch();
	const { lenderSpecificDetails, leadFlowData, selectedLender } = useSelector(
		(state: any) => state?.lead
	);

	const coAppData = JSON.parse(
		JSON.stringify(lenderSpecificDetails?.lender_spf_details?.co_applicant) ||
			'[]'
	);

	const coApplicantFormOne: any = {
		salutation: coAppData?.[coTabIndex]?.salutation,
		mobile_no: coAppData?.[coTabIndex]?.mobile_no,
		first_name: coAppData?.[coTabIndex]?.first_name,
		middle_name: coAppData?.[coTabIndex]?.middle_name,
		last_name: coAppData?.[coTabIndex]?.last_name,
		dob: coAppData?.[coTabIndex]?.dob || undefined,
		gender: coAppData?.[coTabIndex]?.gender,
		pan_no: coAppData?.[coTabIndex]?.pan_no,
		email: coAppData?.[coTabIndex]?.email,
		marital_status: coAppData?.[coTabIndex]?.marital_status,
		religion: coAppData?.[coTabIndex]?.religion,
		category: coAppData?.[coTabIndex]?.category,
		customer_occupation: coAppData?.[coTabIndex]?.customer_occupation,
		net_monthly_income: coAppData?.[coTabIndex]?.net_monthly_income || '',
		share_holding: coAppData?.[coTabIndex]?.share_holding || '',
	};

	const coApplicantFormTwo: any = {
		customer_type: coAppData?.[coTabIndex]?.customer_type || '',
		party_relation: coAppData?.[coTabIndex]?.party_relation || '',
		date_of_incorporation:
			coAppData?.[coTabIndex]?.date_of_incorporation || undefined,
		company_name: coAppData?.[coTabIndex]?.company_name || '',
		constitution_code: coAppData?.[coTabIndex]?.constitution_code || '',
		// constitution_personal: coAppData?.[coTabIndex]?.constitution_personal || '',
		curr_add_1: coAppData?.[coTabIndex]?.curr_add_1,
		curr_add_2: coAppData?.[coTabIndex]?.curr_add_2,
		curr_pincode: coAppData?.[coTabIndex]?.curr_pincode,
		curr_per_add_same: coAppData?.[coTabIndex]?.curr_per_add_same || false,
		per_add_1: coAppData?.[coTabIndex]?.per_add_1,
		per_add_2: coAppData?.[coTabIndex]?.per_add_2,
		per_pincode: coAppData?.[coTabIndex]?.per_pincode,
		off_add_1: coAppData?.[coTabIndex]?.off_add_1,
		off_add_2: coAppData?.[coTabIndex]?.off_add_2,
		off_pincode: coAppData?.[coTabIndex]?.off_pincode,
		preferred_address: coAppData?.[coTabIndex]?.preferred_address || '',
	};

	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		trigger,
		reset,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(
			currentPage === 1 ? coApplicantFormOneSchema : coApplicantFormTwoSchema
		),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: currentPage === 1 ? coApplicantFormOne : coApplicantFormTwo,
	});

	useEffect(() => {
		if (!location.pathname.includes('/lead-lender-specific-form-steps')) {
			return;
		}
		setCoApplicantData(coAppData || []);
	}, [location.pathname]);

	useEffect(() => {
		onPageChange(1);
	}, [coTabIndex]);

	const onPageChange = (pageInd: any) => {
		setCurrentPage(pageInd);
		if (pageInd === 1) {
			firstFormFields();
		} else if (pageInd === 2) {
			secondFormFields();
		}
	};

	useEffect(() => {
		const applicantInfo = lenderSpecificDetails?.lender_spf_details;
		const coData: any = JSON.parse(JSON.stringify(coApplicantData));
		coData[coTabIndex] = {
			...coData[coTabIndex],
			[`isValid${currentPage}`]: isValid,
		};
		const data = coData.filter((ele: any) => !ele.isValid2) || [];
		if (
			data.length > 0 ||
			!applicantInfo?.isValidApplicant1 ||
			!applicantInfo?.isValidApplicant2
		) {
			setValidForm(false);
		} else {
			setValidForm(true);
		}
	}, [isValid]);

	const firstFormFields = () => {
		reset({
			salutation: coAppData?.[coTabIndex]?.salutation,
			mobile_no: coAppData?.[coTabIndex]?.mobile_no,
			first_name: coAppData?.[coTabIndex]?.first_name,
			middle_name: coAppData?.[coTabIndex]?.middle_name,
			last_name: coAppData?.[coTabIndex]?.last_name,
			dob: coAppData?.[coTabIndex]?.dob || undefined,
			gender: coAppData?.[coTabIndex]?.gender,
			pan_no: coAppData?.[coTabIndex]?.pan_no,
			email: coAppData?.[coTabIndex]?.email,
			marital_status: coAppData?.[coTabIndex]?.marital_status,
			religion: coAppData?.[coTabIndex]?.religion,
			category: coAppData?.[coTabIndex]?.category,
			customer_occupation: coAppData?.[coTabIndex]?.customer_occupation,
			net_monthly_income: coAppData?.[coTabIndex]?.net_monthly_income || '',
			share_holding: coAppData?.[coTabIndex]?.share_holding || '',
		});
	};

	const secondFormFields = () => {
		reset({
			customer_type: coAppData?.[coTabIndex]?.customer_type || '',
			party_relation: coAppData?.[coTabIndex]?.party_relation || '',
			date_of_incorporation:
				coAppData?.[coTabIndex]?.date_of_incorporation || undefined,
			company_name: coAppData?.[coTabIndex]?.company_name || '',
			constitution_code: coAppData?.[coTabIndex]?.constitution_code || '',
			// constitution_personal:
			// 	coAppData?.[coTabIndex]?.constitution_personal || '',
			curr_add_1: coAppData?.[coTabIndex]?.curr_add_1,
			curr_add_2: coAppData?.[coTabIndex]?.curr_add_2,
			curr_pincode: coAppData?.[coTabIndex]?.curr_pincode,
			curr_per_add_same: coAppData?.[coTabIndex]?.curr_per_add_same || false,
			per_add_1: coAppData?.[coTabIndex]?.per_add_1,
			per_add_2: coAppData?.[coTabIndex]?.per_add_2,
			per_pincode: coAppData?.[coTabIndex]?.per_pincode,
			off_add_1: coAppData?.[coTabIndex]?.off_add_1,
			off_add_2: coAppData?.[coTabIndex]?.off_add_2,
			off_pincode: coAppData?.[coTabIndex]?.off_pincode,
			preferred_address: coAppData?.[coTabIndex]?.preferred_address || '',
		});
	};

	const addCoApplicant = () => {
		onSubmitAndNext(getValues(), 'AddCoApplicant');
		const count: any = [...totalCoApplicant, totalCoApplicant.length + 1];
		setTotalCoApplicant(count);
		handleSegmentChange(`CoApplicantForm${count.length - 1}`);
		setCoApplicantData([...coApplicantData, {}]);
	};

	const removeApplicant = () => {
		const totalCo = totalCoApplicant.filter(
			(el: any, index: number) => index != coTabIndex
		);
		setTotalCoApplicant(totalCo);
		handleSegmentChange('CoApplicantForm0');
		onPageChange(1);
		let coData: any = JSON.parse(JSON.stringify(coApplicantData));
		coData = coData.filter((el: any, ind: any) => ind != coTabIndex);
		setCoApplicantData(coData);
		dispatch(
			lenderSpfDetailsFlow({
				...lenderSpecificDetails,
				lender_spf_details: {
					...lenderSpecificDetails?.lender_spf_details,
					co_applicant: coData,
				},
			})
		);
	};

	/**
	 * On submit to save user data and after that
	 * @param data Form data
	 */
	const onSubmit = async (data: any) => {
		onSubmitAndNext(data);
	};

	const onSubmitAndNext = async (data: any, flag?: any) => {
		const coData: any = JSON.parse(JSON.stringify(coApplicantData));
		coData[coTabIndex] = {
			...coData[coTabIndex],
			...data,
			[`isValid${currentPage}`]: isValid,
		};

		if (currentPage === 1) {
			if (coData[coTabIndex].dob) {
				coData[coTabIndex].dob = FormatDate(data?.dob, 'yyyy-mm-dd');
			}
		}

		if (currentPage === 2) {
			if (coData[coTabIndex].date_of_incorporation) {
				coData[coTabIndex].date_of_incorporation = FormatDate(
					data?.date_of_incorporation,
					'yyyy-mm-dd'
				);
			}
		}
		setCoApplicantData(coData);
		const req: any = {
			lender_spf_details: {
				...JSON.parse(
					JSON.stringify(lenderSpecificDetails?.lender_spf_details)
				),
			},
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.TATA_BL,
			step: isValid && currentPage == 2 && !flag ? 'step3' : 'step2',
			isNewLead: isValid && currentPage == 2 && !flag,
		};

		if (!req?.lender_spf_details?.co_applicant) {
			req.lender_spf_details.co_applicant = [coData[coTabIndex]];
		} else {
			req.lender_spf_details.co_applicant[coTabIndex] = coData[coTabIndex];
		}

		if (lenderSpecificDetails?.lead_spf_detail_id) {
			req.lead_spf_detail_id = lenderSpecificDetails?.lead_spf_detail_id;
		}
		await dispatch(saveLenderSpcfInfo({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					reset({});
					if (!flag && currentPage < 2) {
						onPageChange(currentPage + 1);
					}
					dispatch(
						lenderSpfDetailsFlow({
							...lenderSpecificDetails,
							lender_spf_details: {
								...lenderSpecificDetails?.lender_spf_details,
								...req?.lender_spf_details,
							},
							lead_spf_detail_id: result.data.lead_spf_detail_id,
							step: req.step,
						})
					);
				} else {
					setShowOfferErrMsg(result?.message);
				}
			});
	};

	/**
	 * Continue Later and Submit button
	 */
	const continueLaterAndSubmitBtn = () => {
		return (
			<>
				<CustomButton
					className='w-100 fw-bold dashboard-add-lead-button mb-3'
					expand='block'
					fill='outline'
					title={t('Continue Later')}
					onClick={() => {
						if (checkFieldsValidToContinue('')) {
							setModalPageClose(true);
						} else {
							showToastAlert({
								type: 'error',
								message: 'Please fix above errors',
							});
						}
					}}
				/>
				<CustomButton
					type='submit'
					className='w-100 fw-bold dashboard-add-lead-button'
					expand='block'
					title={t(currentPage === 2 ? 'Proceed' : 'Save & Continue')}
					disabled={currentPage === 2 ? !isValidForm : !isValid}
					onClick={handleSubmit(onSubmit)}
				/>
			</>
		);
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 pt-3 p-md-5 border-radius-10'>
							<form onSubmit={handleSubmit(onSubmit)}>
								{currentPage === 1 && (
									<CoApplicantForm1
										control={control}
										getValues={getValues}
										errors={errors}
									/>
								)}
								{currentPage === 2 && (
									<CoApplicantForm2
										control={control}
										getValues={getValues}
										setValue={setValue}
										trigger={trigger}
										errors={errors}
									/>
								)}
								<div className='co_app_form_pagination'>
									<div className='page_item'>
										<span
											className={currentPage == 1 ? 'active_page' : ''}
											onClick={() => {
												onSubmitAndNext(getValues(), 'PAGE_CHANGE');
												onPageChange(1);
											}}
										>
											1
										</span>
										<span
											className={
												currentPage == 2
													? 'active_page'
													: !coApplicantData[coTabIndex]?.isValid1
													? 'disable_page'
													: ''
											}
											onClick={() => {
												if (coApplicantData[coTabIndex]?.isValid1) {
													onSubmitAndNext(getValues(), 'PAGE_CHANGE');
													onPageChange(2);
												}
											}}
										>
											2
										</span>
									</div>
									<div className='other_btns'>
										{currentPage === 2 ? (
											<CustomButton
												type='button'
												className='add-co-btn fw-bold dashboard-add-lead-button'
												expand='block'
												title={t('Add Co-Applicant')}
												disabled={!isValid}
												onClick={addCoApplicant}
											/>
										) : (
											''
										)}

										{coTabIndex != 0 ? (
											<IonButton
												shape='round'
												type='button'
												color='danger'
												onClick={() => removeApplicant()}
											>
												<IonIcon src='/images/icons/delete.svg' />
											</IonButton>
										) : (
											''
										)}
									</div>
								</div>
							</form>
							<div className={`d-none d-md-block mt-4`}>
								{continueLaterAndSubmitBtn()}
							</div>
						</div>
					</div>
				)}
			</IonContent>

			{!showOfferErrMsg && (
				<IonFooter className='d-block d-md-none position-relative ion-padding lead_ftr_btn'>
					{continueLaterAndSubmitBtn()}
				</IonFooter>
			)}

			<CustomModal
				needIonContentWraper={false}
				isOpen={modalPageClose}
				initialBreakpoint={undefined}
				handleClose={() => setModalPageClose(false)}
				breakpoints={[]}
				className='height-auto confirm-popup-middle continue-later'
				modalContent={ContinueLaterPopUp(
					() => {
						setModalPageClose(false);
					},
					() => {
						new Promise((myResolve) => {
							setTimeout(function () {
								myResolve(history.back());
								onSubmitAndNext(getValues(), 'continue_later');
								setModalPageClose(false);
							}, 10);
						});
					},
					'Cancel',
					'Continue Later',
					'className',
					'Are You Sure You Want to Exit?',
					`${t('You can continue later from where you left off.')}`
				)}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
