import { IonImg } from '@ionic/react';
import { useState } from 'react';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const AddLeadVideoCard = () => {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const handleVideoModal = () => {
		setOpenModal(true);
	};
	const { t } = useTranslation();
	const leadVideo = useSelector((state: any) => state?.users?.lead_video);
	return (
		<>
			<div className='p-3 mt-3 mb-4 d-flex justify-content-between dashboard-add-lead-video-card'>
				<div>
					<h3 className='mr-1 fs-16 m-0 darkest-grey'>
						{t('Doing it for the first time?')}
					</h3>
				</div>
				<div>
					<h4
						className='fs-14 m-0 text-primary fw-600 cursor-pointer d-flex align-items-center'
						onClick={handleVideoModal}
					>
						<IonImg
							className='me-2'
							src='/images/icons/small-play-logo.svg'
							alt='play'
						/>
						{t('How to Add Leads')}
					</h4>
				</div>
			</div>
			<CustomModal
				id='custommodal'
				modalTitle=''
				initialBreakpoint={undefined}
				className='videoplayerpopup vertical-player'
				isOpen={openModal}
				handleClose={() => setOpenModal(false)}
				breakpoints={[]}
				modalContent={
					<iframe
						src={leadVideo}
						width='100%'
						height='100%'
						frameBorder='0'
						allow='autoplay; fullscreen; picture-in-picture'
						allowFullScreen
					></iframe>
				}
				needCross={false}
				needArrow={false}
				needIonContentWraper={false}
			/>
		</>
	);
};
