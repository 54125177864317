/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonCol, IonGrid, IonImg, IonRow } from '@ionic/react';
import React, { useEffect } from 'react';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
	getProductList,
	productFlowData,
} from '../../../../store/reducers/user/userSlice';
import { productFlow } from '../../../../store/reducers/lead/leadSlice';
import { gradientArray } from '../../../../helpers/constant';

export const Products: React.FC = () => {
	const dispatch: any = useDispatch();
	const productList = useSelector((state: any) => state?.users?.productList);
	const { t } = useTranslation();
	const history = useHistory();
	useEffect(() => {
		getProducts();
	}, []);

	const getProducts = async () => {
		await dispatch(getProductList());
	};

	// let counter = 0;
	return (
		<section className='our-products bg-web-white border-md-radius-10 py-md-3 mb-md-3'>
			<div className='pb-2 pt-md-3'>
				<h3 className='m-0 fw-600 text-start text-md-center fs-16 darkest-grey'>
					{t('Start Selling Our Products')}
				</h3>
			</div>
			<IonGrid className='px-md-5'>
				<IonRow>
					{/* {
                        products.map((item, index) => {
                            return <IonCol className='' size='3' key={index} >
                                <div className='position-relative'>
                                    {
                                        item.discount && <IonImg src={item.discount} className='discount-layer' />
                                    }
                                    <div className='product-box' style={{ background: "url('" + item.bg + "')" }}>
                                        <div className='product-icon'>
                                            <IonImg src={item.icon} style={{ height: "30px", width: "30px" }} />
                                        </div>
                                    </div>
                                    <h3 className='m-0 fs-12 fw-600 text-center pt-3'>{item.name}</h3>
                                </div>
                            </IonCol>
                        })
                    } */}
					{productList &&
						productList.map((item: any, index: any) => {
							return (
								<IonCol
									className=''
									size='3'
									key={`dashboard-products-${index}`}
								>
									<div
										className={`position-relative ${
											item?.category?.length > 0 ? ' cursor-pointer ' : ' '
										} `}
										onClick={() => {
											if (item?.category?.length > 0) {
												dispatch(
													productFlowData({
														id: item?.id,
														productName: item?.name,
														isHome: true,
													})
												);
												history.push('/sub-products');
											}
										}}
									>
										{item.label && (
											<span className='discount-layer fs-8 w-800 text-capitalize'>
												{t(item.label)}
											</span>
										)}
										<div
											className='product-box'
											style={{
												background: "url('" + item.bg + "')",
												display: 'flex',
												justifyContent: 'center',
											}}
										>
											<div
												className='product-icon'
												style={{
													height: '80px',
													width: '80px',
													borderRadius: '10px',
													background: gradientArray[index % 4],
												}}
											>
												<IonImg
													src={item.icon}
													style={{ height: '40px', width: '40px' }}
												/>
											</div>
										</div>
										<h3 className='m-0 fs-12 fw-600 text-center pt-3'>
											{t(item.name)}
										</h3>
									</div>
								</IonCol>
							);
						})}
				</IonRow>
			</IonGrid>
			<div className='pt-2 d-flex align-items-center justify-content-center'>
				<CustomButton
					routerLink={'/dashboard/products'}
					className='btn-blue fw-600 fs-14 button-width dashbord-buttons'
					fill='outline'
					title={t('Explore All Products')}
					style={{ marginTop: '24px' }}
					onClick={() => {
						dispatch(
							productFlow({ is_product: 1, is_lender: 0, lender_show: '0' })
						);
					}}
				/>
			</div>
		</section>
	);
};
