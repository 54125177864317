import React, { SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
	bOpsManagerList,
	leadAllocateManager,
	userAllocateManager,
} from '../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { CustomModal } from './custom-modal/CustomModal';
import { InitialLetter } from './initial-letter/InitialLetter';
import { IonItem } from '@ionic/react';
import { CustomSearchbar } from './custom-searchbar/CustomSearchbar';
import { PageExitPopUp } from '../../helpers/functions';
import { useTranslation } from 'react-i18next';

type CustomAllocateManagersPropsType = {
	eventType: 'Lead' | 'Dma';
	id: number;
	setShowAssignModal: React.Dispatch<SetStateAction<boolean>>;
	redirectTo?: string;
};
type teamMemberListType = {
	full_name: string;
	id: number;
	name: string;
};
function CustomAllocateManagers({
	eventType,
	id,
	setShowAssignModal,
	redirectTo,
}: CustomAllocateManagersPropsType) {
	const dispatch: any = useDispatch();
	const history = useHistory();
	const [searchText, setSearchText] = useState<string>('');
	const [managersList, setManagersList] = useState<teamMemberListType[]>([]);
	const [filteredList, setFilteredList] = useState<teamMemberListType[]>([]);
	const [confirmAllocatePopup, setConfirmAllocatePopup] =
		useState<boolean>(false);
	const [popupName, setPopupName] = useState<string>('');
	const [reportingManagerId, setReportingManagerId] = useState<number>(0);
	const { t } = useTranslation();
	const eventName = eventType === 'Lead' ? 'Lead' : 'DMA';

	useEffect(() => {
		getAllManagerList();
	}, []);
	const getAllManagerList = async () => {
		await dispatch(bOpsManagerList())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setManagersList(result?.central_exec_list);
					setFilteredList(result?.central_exec_list);
				}
			});
	};
	const onSearchInput = (searchTerm: string): void => {
		setSearchText(searchTerm);
		if (searchTerm !== '') {
			if (searchTerm.length >= 3) {
				const searchedList = managersList.filter((member: any) => {
					const mName = member?.full_name;
					return mName?.toLowerCase()?.includes(searchTerm?.toLowerCase());
				});
				setFilteredList(searchedList);
			}
		} else {
			getAllManagerList();
		}
	};

	const assignManagerPopup = (mId: number, mName: string): void => {
		setReportingManagerId(mId);
		setPopupName(mName);
		setConfirmAllocatePopup(true);
	};

	const assignManager = async () => {
		if (eventType === 'Lead') {
			await dispatch(
				leadAllocateManager({
					param: id,
					data: { user_id: reportingManagerId },
				})
			)
				.then(unwrapResult)
				.then((result: any) => {
					if (result?.status) {
						setConfirmAllocatePopup(false);
						setShowAssignModal(false);
						history.push(redirectTo ? redirectTo : '/dashboard/lead');
					}
				});
		} else if (eventType === 'Dma') {
			// for dma details type allocation
			await dispatch(
				userAllocateManager({
					param: id,
					data: {
						reportingManager_id: reportingManagerId,
					},
				})
			)
				.then(unwrapResult)
				.then((result: any) => {
					if (result?.status) {
						setConfirmAllocatePopup(false);
						setShowAssignModal(false);
						history.push(redirectTo ? redirectTo : '/dashboard/teams');
					}
				});
		}
	};
	return (
		<div>
			<CustomSearchbar
				searchIcon='/images/icons/searchbar-icon.svg'
				placeholder={t('Search Team Member by “Name”')}
				showClearButton='focus'
				onChange={onSearchInput}
				onIonClear={() => onSearchInput('')}
				value={searchText}
			/>
			<div className='mt-3'>
				{filteredList?.map((member: any, index: any) => {
					return (
						<IonItem
							key={index}
							className='ion-no-padding cursor-pointer'
							lines='full'
							onClick={() => assignManagerPopup(member.id, member.full_name)}
						>
							<div className='d-flex flex-grow-1 align-items-center my-2'>
								<div style={{ width: '34px', height: '34px' }}>
									<InitialLetter
										value={member?.full_name}
										height='34px'
										width='34px'
										fontSize='22px'
									/>
								</div>
								<div className='ms-3'>
									<p className='mb-0 fs-16'>{member.full_name}</p>
								</div>
							</div>
						</IonItem>
					);
				})}
			</div>

			<CustomModal
				id=''
				isOpen={confirmAllocatePopup}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setConfirmAllocatePopup(false)}
				breakpoints={[]}
				className='confirm-popup-middle height-auto'
				modalContent={PageExitPopUp(
					() => {
						setConfirmAllocatePopup(false);
					},
					() => {
						assignManager();
					},
					'Cancel',
					'Confirm',
					'',
					`${
						eventType === 'Lead'
							? t('Are you sure you want to allocate this Lead to')
							: t('Are you sure you want to allocate this DMA to')
					} <span class="primary-text-color"> ${popupName} </span> ?`,
					`${
						eventName === 'Lead'
							? t(
									`By doing this you will not be able to take any action on this lead anymore.`
							  )
							: t(
									`By doing this you will not be able to take any action on this DMA anymore.`
							  )
					}`
				)}
				needIonContentWraper={false}
				needCross={false}
				needArrow={false}
			/>
		</div>
	);
}

export default CustomAllocateManagers;
