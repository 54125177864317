import React, { useEffect, useState } from 'react';
import { IonContent, IonFooter, IonPage } from '@ionic/react';
import './AddFirmDetails.scss';
import { Header } from '../../../components/layout/header/Header';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { CustomCheckbox } from '../../../components/common/custom-checkbox/CustomCheckbox';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import contactPersonInput from './contactPersonInputs.json';
import { useHistory } from 'react-router';
import { REGEX, USER_ROLES } from '../../../helpers/constant';
import { useDispatch, useSelector } from 'react-redux';
import {
	dmaRegister,
	getdmaRegisterDetails,
	needFooter,
	selfDMARegister,
} from '../../../store/reducers/user/userSlice';
import {
	capFirstLetter,
	checkFieldsValidToContinue,
	disableNumberArrows,
	showToastAlert,
} from '../../../helpers/functions';
import { ConsentCheckbox } from '../../../components/common/consent-checkbox/ConsentCheckbox';
import { unwrapResult } from '@reduxjs/toolkit';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { RejectionModal } from '../../teams/RejectionModal';

const validationSchema = yup.object().shape({
	first_name: yup
		.string()
		.trim()
		.max(100, 'First Name should not be more than 100')
		.required('First Name is Required')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
	middle_name: yup
		.string()
		.trim()
		.max(100, 'Middle Name should not be more than 100')
		.notRequired()
		.matches(REGEX.ALLOWONLYCHAR, {
			message: 'Please Enter Valid Middle Name',
			excludeEmptyString: true,
		}),
	last_name: yup
		.string()
		.trim()
		.max(100, 'Last Name should not be more than 100')
		.required('Last Name is Required')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
	contact_person_email: yup
		.string()
		.required('Contact Person Email is Required')
		.matches(REGEX.EMAIL, 'Please Enter Valid Email'),
	contact_person_mobile: yup
		.string()
		.required('Contact Person Mobile is Required')
		.length(10, 'Mobile No. Should Be 10 Digits.')
		.matches(REGEX.INDIAN_MOBILE_NUMBERS, 'Please Enter Valid Input'),
	acceptTerms: yup.boolean().oneOf([true], 'You need to check the consent'),
});

export const ContactPersonDetails: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const dmaSelector = useSelector((state: any) => state?.users);
	const dispatch: any = useDispatch();
	const [isMoreContent, setMoreContent] = useState(false);
	const [rejectionModal, setRejectionModal] = useState(false);
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	let continueLaterFlag = false;
	const {
		handleSubmit,
		control,
		trigger,
		formState: { errors, isValid },
		setValue,
		reset,
		getValues,
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			first_name: '',
			middle_name: '',
			last_name: '',
			contact_person_email: '',
			contact_person_mobile: '',
			acceptTerms: false,
		},
	});
	const constactData: any = useSelector((state) => state);
	const onSubmit = (data: any) => {
		const myPromise = new Promise(function (myResolve) {
			setTimeout(function () {
				myResolve(history.push('/terms-conditions'));
			}, 10);
		});
		myPromise.then(() => {
			dispatch(
				selfDMARegister({
					...constactData?.users?.registerUser,
					...data,
					first_name: data?.first_name ? capFirstLetter(data?.first_name) : '',
					middle_name: data?.middle_name
						? capFirstLetter(data?.middle_name)
						: '',
					last_name: data?.last_name ? capFirstLetter(data?.last_name) : '',
				})
			);
			dispatch(needFooter(true));
		});
	};

	const isDmaPage =
		location.pathname === '/add-dma' || location.pathname === '/dma-details';

	useEffect(() => {
		if (isDmaPage) {
			reset({
				first_name: '',
				middle_name: '',
				last_name: '',
				contact_person_email: '',
				contact_person_mobile: '',
				acceptTerms: false,
			});
		}
	}, [isDmaPage]);

	useEffect(() => {
		if (location.pathname !== '/contact-person-details') {
			return;
		}
		if (dmaSelector?.dmaFlow?.dmaId) {
			getDmaDetails();
		}
	}, [location.pathname]);

	const continueLater = async () => {
		if (checkFieldsValidToContinue(errors)) {
			continueLaterFlag = true;

			let id;
			if (dmaSelector?.dmaFlow?.dmaId) {
				id = dmaSelector?.dmaFlow?.dmaId;
			} else {
				id = dmaSelector?.user_details?.id;
			}

			const obj: any = getValues();
			obj['continueLater'] = continueLaterFlag;
			obj['status'] = '4';
			await dispatch(dmaRegister({ param: '/' + id, data: obj }))
				.then(unwrapResult)
				.then(async (response: any) => {
					if (response?.status) {
						reset({
							first_name: '',
							middle_name: '',
							last_name: '',
							contact_person_email: '',
							contact_person_mobile: '',
							acceptTerms: false,
						});
						continueLaterFlag = false;
						history.replace('/dashboard/teams/');
					}
				});
		} else {
			showToastAlert({
				type: 'error',
				message: 'Please fix above errors',
			});
		}
	};

	const getDmaDetails = async () => {
		if (dmaSelector?.dmaFlow?.dmaId) {
			await dispatch(
				getdmaRegisterDetails({ param: '/' + dmaSelector?.dmaFlow?.dmaId })
			)
				.then(unwrapResult)
				.then(async (response: any) => {
					if (response?.status) {
						const cData = response?.user;
						if (cData.contact_person_mobile === 0) {
							cData.contact_person_mobile = '';
						}
						reset(cData);
					}
				});
		}
	};

	return (
		<IonPage className=''>
			<Header
				name=''
				backButton={true}
				needArrow={true}
				backButtonText=''
			/>
			<IonContent className=''>
				{/* <div className='ion-padding'>
					{t('Please add your name as per your Aadhaar')}
				</div> */}
				<div className='bg-web small-container h-100 overflowY-auto'>
					<div className='bg-web-white container-width-md h-100 d-md-flex justify-content-md-between flex-md-column border-md-radius-10'>
						{/* <div className='ion-padding'> */}
						<div className='overflowY-md-auto'>
							<div className='px-3 py-md-3'>
								<h4 className='fw-600 fs-24 darkest-grey'>
									{t('Add Contact Person’s Info')}
								</h4>
								{contactPersonInput.map(({ name, type, placeholder }: any) => {
									const T: any = errors;
									return (
										<Controller
											control={control}
											key={name}
											render={({ field: { onChange, onBlur } }) => (
												<CustomInput
													type={type}
													placeholder={t(`${placeholder}`)}
													onBlur={onBlur}
													onChange={onChange}
													value={getValues(name)}
													errors={t(T[name]?.message)}
													onBeforeInput={(evt: any) => {
														if (type == 'number') {
															!/[0-9]/.test(evt.data) && evt.preventDefault();
														}
														if (name.toLowerCase().includes('mobile')) {
															if (evt.target.value.length === 10) {
																evt.preventDefault();
															}
														}
													}}
													className={`${
														['first_name', 'middle_name', 'last_name'].includes(
															name
														)
															? 'text-capitalize '
															: ''
													}`}
													onPaste={(e: any) => {
														//Only get numbers on user paste
														if (name.toLowerCase().includes('mobile')) {
															e.preventDefault();
															let pasteContent =
																e.clipboardData.getData('Text');
															if (pasteContent != '') {
																pasteContent = pasteContent.replace(/\D/g, '');
																if (pasteContent.length > 10) {
																	pasteContent = pasteContent.slice(0, 10);
																}
															}
															setValue(name, pasteContent);
															trigger(name);
														}
													}}
													onKeyDown={(e: any) => {
														if (type == 'number') {
															disableNumberArrows(e);
														}
													}}
													onWheel={(e: any) => {
														if (type == 'number') {
															e.target.blur();
														}
													}}
												/>
											)}
											name={name}
										/>
									);
								})}
							</div>
							<div className='position-relative'>
								<Controller
									control={control}
									render={({ field: { onChange, onBlur, name } }) => (
										<div
											className='d-flex py-3'
											style={{ background: '#EDF8EC' }}
										>
											<div className='position-relative'>
												<CustomCheckbox
													labelPlacement='end'
													name={name}
													isBackGround={true}
													// isChecked={confirmCheck}
													isChecked={getValues('acceptTerms')}
													value={getValues('acceptTerms')}
													onBlur={onBlur}
													onChange={(event: any) => {
														setValue('acceptTerms', event.detail.checked);
														setTimeout(() => {
															onChange(event);
														}, 500);
													}}
													className={
														(errors[name] ? 'ion-checkbox-inValid' : '') +
														' pt-1 px-3'
													}
													errorText={errors['acceptTerms']?.message}
													errorTextClass='position-absolute checkbox-style1'
												/>
											</div>
											<ConsentCheckbox isMoreContent={isMoreContent} />
										</div>
									)}
									name={'acceptTerms'}
								/>
								<div
									onClick={() => {
										setMoreContent(!isMoreContent);
									}}
									className='position-absolute right-0 ion-padding py-1 text-primary fs-12 cursor-pointer fw-600'
								>
									{isMoreContent ? `- ${t('Less')}` : `+ ${t('More')}`}
								</div>
							</div>
						</div>
						<div className='d-none d-md-block bg-web-white position-relative'>
							{dmaSelector?.dmaFlow?.dmaId && (
								<div className=' ion-padding reject-continue-later  d-flex gap-3 my-2'>
									<CustomButton
										onClick={() => {
											setRejectionModal(true);
										}}
										fill={'outline'}
										title={t(`${'Reject'}`)}
										className={'w-100'}
									/>
									<CustomButton
										fill={'outline'}
										title={t(`${'Continue Later'}`)}
										onClick={continueLater}
										className={'w-100'}
									/>
								</div>
							)}
							<div
								className={` ion-padding ${
									dmaSelector?.dmaFlow?.dmaId && 'pt-0'
								}`}
							>
								<CustomButton
									disabled={!isValid}
									expand='block'
									title={t('Proceed')}
									className={'btn-blue'}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				</div>
				{/* </div> */}
			</IonContent>
			<IonFooter className='add-firm-details-button'>
				<div className='d-block d-md-none position-relative'>
					{dmaSelector?.dmaFlow?.dmaId && (
						<div className=' ion-padding reject-continue-later  d-flex gap-3 my-2'>
							<CustomButton
								onClick={() => {
									setRejectionModal(true);
								}}
								fill={'outline'}
								title={'Reject'}
								className={'w-100'}
							/>
							<CustomButton
								fill={'outline'}
								title={'Continue Later'}
								onClick={continueLater}
								className={'w-100'}
							/>
						</div>
					)}
					<div
						className={` ion-padding ${dmaSelector?.dmaFlow?.dmaId && 'pt-0'}`}
					>
						<CustomButton
							disabled={!isValid}
							expand='block'
							title={t('Proceed')}
							className={'btn-blue'}
							onClick={handleSubmit(onSubmit)}
						/>
					</div>
				</div>
			</IonFooter>
			<CustomModal
				id='rejectionmodal'
				isOpen={rejectionModal}
				// initialBreakpoint={0.4}
				initialBreakpoint={isWeb ? 1 : 0.4}
				handleClose={() => setRejectionModal(false)}
				breakpoints={[0, 0.4, 0.6, 0.75]}
				className='side-modal'
				modalContent={
					<RejectionModal
						title={
							dmaSelector?.user_details?.roleType?.[0] === USER_ROLES.BUH
								? 'Request Revision'
								: 'Reason for Rejection'
						}
						reasonNeeded={
							dmaSelector?.user_details?.roleType?.[0] === USER_ROLES.BUH
								? false
								: true
						}
						setRejectionModal={setRejectionModal}
					/>
				}
				needCross={false}
				needArrow={false}
			/>
		</IonPage>
	);
};
