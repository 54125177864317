import { useIonRouter } from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Header } from '../../../components/layout/header/Header';
import { LeadsFlowGenerator } from '../../../components/common/custom-leads-flow-generator/LeadsFlowGenerator';
import FibePL_Form from './FibePL_Form';

const InitiateFibePlForm = () => {
	const router = useIonRouter();
	const lenderSpfDetails = useSelector(
		(state: any) => state?.lead?.lenderSpecificDetails
	);
	const headerNeedArrowOnClick = async () => {
		// dispatch(lenderSpfDetailsFlow({}));
		router.push('/lender-specific-info');
	};
	const stepsComponents = [
		{
			step: 'step1',
			component: <FibePL_Form />,
		},
	];

	const stepsHeader = (
		<Header
			name={'Fibe (Formerly EarlySalary) Form'}
			backButton={true}
			needArrow={true}
			needCross={false}
			needArrowOnClick={() => headerNeedArrowOnClick()}
		/>
	);
	return (
		<div>
			<LeadsFlowGenerator
				currentStep={lenderSpfDetails?.step}
				stepsHeader={stepsHeader}
				displayStepsCounter={lenderSpfDetails?.subHeaderStepsCounter}
				stepsComponents={stepsComponents}
			/>
		</div>
	);
};

export default InitiateFibePlForm;
