import * as yup from "yup";



const address_details_schema = yup.object({
  // id: yup.number().notRequired(),
  line1: yup.string().required('Line 1 is required'),
  line2: yup.string().required('Line 2 is required'),
  landmark: yup.string().required('Landmark is required'),
  pincode: yup.string().required('Pincode is required').length(6, 'Pincode must be 6 digits'),
  postOffice:yup.string().notRequired(),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
});


// const address_details_schema = yup.object({
  
//     permLine1: yup.string().required('Line 1 is required'),
//     permLine2: yup.string(),
//     permLandmark: yup.string().required('Landmark is required'),
//     permPincode: yup.string().required('Pincode is required').length(6, 'Pincode must be 6 digits'),
//     permCity: yup.string().required('City is required'),
//     permState: yup.string().required('State is required'),
  
//   // Same schema as permanentAddress. Need to optmize here
  
//     curLine1: yup.string().required('Line 1 is required'),
//     curLine2: yup.string(),
//     curLandmark: yup.string().required('Landmark is required'),
//     curPincode: yup.string().required('Pincode is required').length(6, 'Pincode must be 6 digits'),
//     curCity: yup.string().required('City is required'),
//     curState: yup.string().required('State is required'),
  
// });

// const address_details_schema = yup.object({
//   permanentAddress: yup.object({
//     permLine1: yup.string().required('Line 1 is required'),
//     permLine2: yup.string(),
//     permLandmark: yup.string().required('Landmark is required'),
//     permPincode: yup.string().required('Pincode is required').length(6, 'Pincode must be 6 digits'),
//     permCity: yup.string().required('City is required'),
//     permState: yup.string().required('State is required'),
//   }),
//   // Same schema as permanentAddress. Need to optmize here
//   currentAddress: yup.object({
//     curLine1: yup.string().required('Line 1 is required'),
//     curLine2: yup.string(),
//     curLandmark: yup.string().required('Landmark is required'),
//     curPincode: yup.string().required('Pincode is required').length(6, 'Pincode must be 6 digits'),
//     curCity: yup.string().required('City is required'),
//     curState: yup.string().required('State is required'),
//   }),
// });

// const address_details_schema = yup.object({
//   permanentAddress: yup.object({
//     line1: yup.string().required('Line 1 is required'),
//     line2: yup.string(),
//     landmark: yup.string().required('Landmark is required'),
//     pincode: yup.string().required('Pincode is required').length(6, 'Pincode must be 6 digits'),
//     city: yup.string().required('City is required'),
//     state: yup.string().required('State is required'),
//   }),
//   currentAddress: yup.object({
//     // Same schema as permanentAddress. Need to optmize here
//     line1: yup.string().required('Line 1 is required'),
//     line2: yup.string(),
//     landmark: yup.string().required('Landmark is required'),
//     pincode: yup.string().required('Pincode is required').length(6, 'Pincode must be 6 digits'),
//     city: yup.string().required('City is required'),
//     state: yup.string().required('State is required'),
//   }),
// });

export default address_details_schema;
