import { Trans } from 'react-i18next';

const defaultContent = `I have read and agree to <a href='https://www.andromedaloans.com/privacy-policy-app' target='_blank'> 
Privacy Policy</a> and <a href='https://www.andromedaloans.com/terms-of-use-app/' target='_blank' rel='noopener noreferrer'>
Terms of Use </a> By submitting this, I hereby authorize Andromeda to do all of the following in connection with 
providing me the Services: <br /> <br /> 1. I authorize Andromeda to receive my credit information from 
TransUnion CIBIL and Equifax. <br /> <br /> 2. I authorize Andromeda or its employees or agents to 
collect and share my details with its lending partners to the extent necessary or recommended for a proper
and effective provision of services by Andromeda`;

export const ConsentCheckbox = ({
	key,
	content,
	isMoreContent,
}: {
	key?: string;
	content?: string;
	isMoreContent: boolean;
}) => {
	return (
		<div className={`pe-3 ${isMoreContent ? 'h-auto' : 'consent-height'}`}>
			<Trans
				i18nKey={key ? key : 'tnc'}
				defaults={content ? content : defaultContent}
				values={{ what: 'world' }}
				components={{ a: <a />, br: <br /> }}
			/>
		</div>
	);
};
