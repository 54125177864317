import { IonContent, IonFooter } from '@ionic/react';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	saveLenderSpcfInfo,
	lenderEligiblityCheck,
} from '../../../store/reducers/lead/leadSlice';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { CustomCheckbox } from '../../../components/common/custom-checkbox/CustomCheckbox';
import { LenderNameForAPICall } from '../../../helpers/lenders';
import { CustomModalLeadStatus } from '../../../components/common';
import './KreditBee.scss';
import { OfferRejectedMsg } from '../OfferRejectedMsg/OfferRejectedMsg';

export const KreditBeeTermsConditionForm: React.FC<any> = () => {
	const [termsConditionList, setTermsConditionList]: any = useState([]);
	const [selectedTnc, setSelectedTnc]: any = useState([]);
	const [leadStatusModalOpen, setLeadStatusModalOpen] = useState(false);
	const [applicationNo, setApplicationNo] = useState(null);
	const dispatch = useDispatch();
	const lenderSpfDetails = useSelector(
		(state: any) => state?.lead?.lenderSpecificDetails
	);
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	const leadLender = useSelector((state: any) => state?.lead);
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');

	useEffect(() => {
		if (lenderSpfDetails?.termsConditions) {
			setTermsConditionList(
				JSON.parse(JSON.stringify(lenderSpfDetails?.termsConditions))
			);
		}
	}, []);

	/**
	 * On close of lead status modal redirect to external URL
	 */
	const onLeadStatusModalClose = () => {
		setLeadStatusModalOpen(false);
	};

	const onCheckBoxChecked = (event: any, el: any, k: any) => {
		termsConditionList[k] = {
			...termsConditionList[k],
			isChecked: event.detail.checked,
		};
		setTermsConditionList([...termsConditionList]);
		if (!termsConditionList[k]?.isChecked) {
			const filterItem = selectedTnc.filter((val: any) => val !== el.consentId);
			setSelectedTnc(filterItem);
		} else {
			setSelectedTnc([...selectedTnc, el.consentId]);
		}
	};

	/**
	 * On submit to save user data and after that
	 * CREATE AND UPDATE ABFL USER API will be called from Backend
	 * @param data Form data
	 */
	const onSubmit = async () => {
		setShowOfferErrMsg('');
		const req: any = {
			lender_spf_details: {
				...lenderSpfDetails?.lender_spf_details,
				consentIdList: selectedTnc,
			},
			step: 'step2',
			lead_id: leadInfo?.leadId,
			lender_id: leadLender?.selectedLender[0]?.lender_id,
			lead_application_id: leadLender?.selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.KREDITBEE,
		};

		if (lenderSpfDetails?.lead_spf_detail_id) {
			req.lead_spf_detail_id = lenderSpfDetails?.lead_spf_detail_id;
		}
		await dispatch(saveLenderSpcfInfo({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					checkLenderEligiblity();
				}
			});
	};

	const checkLenderEligiblity = async () => {
		const req: any = {
			lender_name: LenderNameForAPICall.KREDITBEE,
			consentIdList: selectedTnc,
			lead_spf_detail_id: lenderSpfDetails.lead_spf_detail_id,
		};

		await dispatch(lenderEligiblityCheck({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setApplicationNo(result?.data?.andro_id);
					setLeadStatusModalOpen(true);
				} else {
					setShowOfferErrMsg('Please try again later !');
				}
			});
	};

	return (
		<>
			{showOfferErrMsg ? (
				<>
					<OfferRejectedMsg message={showOfferErrMsg} />
				</>
			) : (
				<>
					<IonContent>
						<div className='select-product small-container bg-web pt-4'>
							<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
								<form>
									{termsConditionList &&
										termsConditionList.length > 0 &&
										termsConditionList?.map((el: any, k: number) => {
											return (
												<div key={k}>
													<>
														<div>
															<div style={{ display: 'flex' }}>
																<CustomCheckbox
																	labelPlacement='end'
																	name={`selectedTnc.${k}`}
																	isChecked={el?.isChecked}
																	value={el.consentId}
																	onChange={(event: any) => {
																		onCheckBoxChecked(event, el, k);
																	}}
																/>
																<div style={{ marginLeft: '15px' }}>
																	<div
																		dangerouslySetInnerHTML={{
																			__html: el.consentBody,
																		}}
																	></div>
																	<div
																		dangerouslySetInnerHTML={{
																			__html: el.hinConsent,
																		}}
																	></div>
																</div>
															</div>
														</div>
													</>
												</div>
											);
										})}
								</form>
								<div className={` d-none d-md-block`}>
									<CustomButton
										type='submit'
										className='w-100 fw-bold dashboard-add-lead-button'
										expand='block'
										title={t('Proceed')}
										disabled={
											selectedTnc?.length === 0 ||
											selectedTnc?.length !== termsConditionList?.length
										}
										onClick={onSubmit}
									/>
								</div>
							</div>
						</div>
					</IonContent>

					{leadStatusModalOpen && (
						<CustomModalLeadStatus
							isOpen={leadStatusModalOpen}
							applicationNo={applicationNo}
							handleClose={() => onLeadStatusModalClose()}
						/>
					)}

					<IonFooter className='d-block d-md-none position-relative'>
						<div
							style={{
								backgroundColor: '#fff',
								zIndex: 2,
							}}
						>
							<div className={`ion-padding`}>
								<div className='proceed-btn'>
									<CustomButton
										type='submit'
										className='w-100 fw-bold dashboard-add-lead-button'
										expand='block'
										title={t('Proceed')}
										disabled={
											selectedTnc?.length === 0 ||
											selectedTnc?.length !== termsConditionList?.length
										}
										onClick={onSubmit}
									/>
								</div>
							</div>
						</div>
					</IonFooter>
				</>
			)}
		</>
	);
};
