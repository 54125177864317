import * as yup from 'yup';
import { LEAD_SCORE_TYPE, REGEX } from '../../../helpers/constant';
import leadStepInfoFormInputs from './LeadStepInfoForm.json';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonContent, IonFooter, IonImg, IonPage } from '@ionic/react';
import { CustomCheckbox } from '../../../components/common/custom-checkbox/CustomCheckbox';

import { useHistory, useLocation } from 'react-router';
import {
	FormatDate,
	PageExitPopUp,
	capFirstLetter,
	disableNumberArrows,
	fetchStateCityByPincode,
} from '../../../helpers/functions';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { Header } from '../../../components/layout/header/Header';
import { useDispatch, useSelector } from 'react-redux';
import './BureauScore.scss';
import {
	addlead,
	cbilRequestConsentDetails,
	// cbilRequestUpdateConsentDetails,
	dashboardLeadGenerateOtp,
	leadBureauCustomer,
	leadFlow,
} from '../../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { ConsentCheckbox } from '../../../components/common/consent-checkbox/ConsentCheckbox';
import { generateCibilOtp } from '../../../store/reducers/user/userSlice';
import StateCity from '../../../components/common/StateCity';
import DropDownModal from '../../../components/common/custom-dropdown/DropDownModal';

const validationSchema = yup.object().shape({
	pan_no: yup
		.string()
		.required('PAN No. is Required.')
		.trim()
		.matches(
			REGEX.PAN,
			'PAN no. should be in alphanumeric format "ABCDE1234A" only'
		),
	first_name: yup
		.string()
		.trim()
		.max(100, 'First Name should not be more than 100')
		.required('First Name is Required')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
	middle_name: yup
		.string()
		.trim()
		.max(100, 'Middle Name should not be more than 100')
		.notRequired()
		.matches(REGEX.ALLOWONLYCHAR, {
			message: 'Please Enter Valid Input',
			excludeEmptyString: true,
		}),
	last_name: yup
		.string()
		.trim()
		.max(100, 'Last Name should not be more than 100')
		.required('Last Name is Required')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
	mobile: yup
		.string()
		.length(10, 'Mobile No. Should Be 10 Digits.')
		.required('Mobile is Required.')
		.matches(REGEX.INDIAN_MOBILE_NUMBERS, 'Please Enter a valid Mobile No.'),
	dob: yup
		.date()
		.required('DOB is Required.')
		// .min('1969-11-13', 'Date is too early')
		.typeError('Please select valid date')
		.max(new Date(Date.now()), 'Date cannot be in the future')
		.test('dob', 'Age must be 18 or older', function (birthdate) {
			const cutoff = new Date();
			cutoff.setFullYear(cutoff.getFullYear() - 18);
			return birthdate <= cutoff;
		}),
	pincode: yup
		.string()
		.trim()
		.required('Pin Code Number is Required')
		.length(6, 'Pin Code Should Be 6 Digits'),
	state: yup.string().trim().required('State is Required'),
	city: yup.string().trim().required('City is Required'),
	email: yup
		.string()
		.required('Email is Required')
		.matches(REGEX.EMAIL, 'Please Enter Valid Email'),
	gender: yup.string().required('Please select Gender'),
	acceptTerms: yup
		.bool()
		.required('You need to check the consent')
		.oneOf([true], 'You need to check the consent'),
});

const AddBureauDetail: React.FC<any> = ({ setCurrentStep }: any) => {
	const [confirmPopup, setConfirmPopup] = useState<boolean>(false);
	const { t } = useTranslation();
	const history = useHistory();
	const [modalPageClose, setModalPageClose] = useState(false);
	const [isMoreContent, setMoreContent] = useState(false);
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	const customerInfo = useSelector(
		(state: any) => state?.lead?.leadBureauCustomerData
	);
	const userData = useSelector((state: any) => state?.users?.user_details);
	const dispatch = useDispatch();
	const leadData = useSelector((state: any) => state?.lead?.leadFlowData);
	const location = useLocation<any>();
	const [preFillData, setPreFillData] = useState<any>(
		location?.state?.preData || ''
	);
	const { leadcreatedthrough } = useSelector((state: any) => state?.lead);
	const [pinCodeData, setPinCodeData] = useState<any>([]);

	const {
		handleSubmit,
		control,
		trigger,
		setValue,
		getValues,
		watch,
		reset,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			pan_no: preFillData?.pan_no || '',
			first_name: preFillData?.first_name || '',
			middle_name: preFillData?.middle_name || '',
			last_name: preFillData?.last_name || '',
			mobile: preFillData?.mobile_no || '',
			acceptTerms: false,
			lead_id: preFillData?.id,
			pincode: preFillData?.pincode || '',
			state: preFillData?.state || '',
			city: preFillData?.city || '',
			dob: preFillData?.dob || '',
			email: preFillData?.email || '',
			gender: preFillData?.gender || '',
		},
	});

	useEffect(() => {
		const preData = location?.state?.preData;

		location?.state?.preData
			? setPreFillData(location?.state?.preData)
			: setPreFillData(null);

		location?.state?.preData
			? reset({
					pan_no: preData?.pan_no || '',
					first_name: preData?.first_name || '',
					middle_name: preData?.middle_name || '',
					last_name: preData?.last_name || '',
					mobile: preData?.mobile_no || '',
					pincode: preFillData?.pincode || '',
					state: preData?.state || '',
					city: preData?.city || '',
					dob: preData?.dob || '',
					email: preData?.email || '',
					gender: preData?.gender || '',
					acceptTerms: false,
					lead_id: preFillData?.id,
			  })
			: reset({
					pan_no: '',
					first_name: '',
					middle_name: '',
					last_name: '',
					mobile: '',
					pincode: '',
					state: '',
					city: '',
					dob: '',
					email: '',
					gender: '',
					acceptTerms: false,
					lead_id: preFillData?.id,
			  });
		setPinCodeData([]);
	}, [location?.state?.preData]);

	useEffect(() => {
		if (location.pathname !== '/add-bureau-score') {
			return;
		}
		return () => {
			if (preFillData) {
				reset({
					pan_no: preFillData?.pan_no || '',
					first_name: preFillData?.first_name || '',
					middle_name: preFillData?.middle_name || '',
					last_name: preFillData?.last_name || '',
					mobile: preFillData?.mobile_no || '',
					pincode: preFillData?.pincode || '',
					state: preFillData?.state || '',
					city: preFillData?.city || '',
					dob: preFillData?.dob || '',
					email: preFillData?.email || '',
					gender: preFillData?.gender || '',
					acceptTerms: false,
					lead_id: preFillData?.id,
				});
			} else {
				reset({
					pan_no: '',
					first_name: '',
					middle_name: '',
					last_name: '',
					mobile: '',
					pincode: '',
					state: '',
					city: '',
					dob: '',
					email: '',
					gender: '',
					acceptTerms: false,
					lead_id: preFillData?.id,
				});
			}
		};
		setPinCodeData([]);
	}, [location.pathname]);
	const onSubmit = async (data: any) => {
		const body = {
			first_name: data?.first_name ? capFirstLetter(data?.first_name) : '',
			middle_name: data?.middle_name ? capFirstLetter(data?.middle_name) : '',
			last_name: data?.last_name ? capFirstLetter(data?.last_name) : '',
			mobile_no: data?.mobile,
			pincode: data?.pincode,
			state: data?.state,
			city: data?.city,
			dob: FormatDate(data?.dob, 'yyyy-mm-dd'),
			email: data?.email,
			gender: data?.gender,
			doc_no: data?.pan_no.toUpperCase(),
			pan: data?.pan_no.toUpperCase(),
			is_consent: getValues('acceptTerms'),
			lead_type: 'Customer',
			loginUserId: userData?.id,
			lead_id: preFillData?.id,
			lead_generated_through:
				leadcreatedthrough?.leadCreationtype != undefined &&
				leadcreatedthrough?.leadCreationtype == 1
					? String(leadcreatedthrough?.leadCreationtype)
					: '2',
		};
		await dispatch(addlead({ data: body }))
			.then(unwrapResult)
			.then(async (addLeadResponse: any) => {
				if (addLeadResponse?.status) {
					if (
						customerInfo?.scoreType?.toLocaleLowerCase() ===
						LEAD_SCORE_TYPE.EQUIFAX.toLocaleLowerCase()
					) {
						await dispatch(
							dashboardLeadGenerateOtp({
								data: { mobile: data?.mobile, reLogin: false },
							})
						)
							.then(unwrapResult)
							.then((generateOtpResponse: any) => {
								if (generateOtpResponse?.status) {
									const myPromise = new Promise(function (myResolve) {
										setTimeout(function () {
											myResolve(
												history.push('/verify-otp-bureau-report', {
													leadMobile: data?.mobile,
													type: 'VERIFY_LEAD',
													leadId:
														addLeadResponse?.newLoanApplication?.id ||
														preFillData?.id,
													url: preFillData
														? '/lead-list'
														: '/check-bureau-score',
												})
											);
										}, 10);
									});
									myPromise.then(() => {
										dispatch(
											leadBureauCustomer({
												...customerInfo,
												lead_type: 'Customer',
												leadMobile: data?.mobile,
												type: 'VERIFY_LEAD',
												reSendCibilOtp: false,
												leadId: addLeadResponse?.newLoanApplication?.id,
												checkScoreBody: body,
											})
										);
										reset({
											pan_no: '',
											first_name: '',
											middle_name: '',
											last_name: '',
											mobile: '',
											pincode: '',
											state: '',
											city: '',
											dob: '',
											email: '',
											gender: '',
											acceptTerms: false,
										});
									});
									setPinCodeData([]);
								}
							});
					} else if (
						customerInfo?.scoreType?.toLocaleLowerCase() ===
						LEAD_SCORE_TYPE.CBIL.toLocaleLowerCase()
					) {
						// setConfirmPopup(true);
						dispatch(
							leadBureauCustomer({
								...customerInfo,
								lead_type: 'Customer',
								leadMobile: data?.mobile,
								type: 'VERIFY_LEAD',
								reSendCibilOtp: false,
								leadId: addLeadResponse?.newLoanApplication?.id,
								checkScoreBody: body,
							})
						);
						dispatch(
							cbilRequestConsentDetails({
								param: addLeadResponse?.newLoanApplication?.id,
							})
						)
							.then(unwrapResult)
							.then(async (response: any) => {
								setConfirmPopup(false);
								if (response.status) {
									if (response?.is_consent) {
										if (response?.is_otp) {
											history.replace('/view-bureau-report');
										} else {
											await dispatch(
												generateCibilOtp({
													data: {
														...customerInfo?.checkScoreBody,
														lead_id: addLeadResponse?.newLoanApplication?.id,
														mobile_no: data?.mobile,
													},
												})
											)
												.then(unwrapResult)
												.then(async (cbilOtpResponse: any) => {
													if (cbilOtpResponse.status) {
														dispatch(
															leadBureauCustomer({
																...customerInfo,
																cbilResponse: {
																	ChallengeConfigGUID:
																		cbilOtpResponse?.ChallengeConfigGUID,
																	answerKey: cbilOtpResponse?.answerKey,
																	questionKey: cbilOtpResponse?.questionKey,
																},
																type: 'VERIFY_CIBIL_OTP',
																reSendCibilOtp: false,
																needCibil: true,
																checkScoreBody: body,
															})
														);
														history.push('/verify-otp-bureau-report');
													}
												});
										}
									} else {
										// dispatch(
										// 	cbilRequestUpdateConsentDetails({
										// 		param: response?.pccId + '?consentVal=1',
										// 	})
										// )
										// 	.then(unwrapResult)
										// 	.then(async (cbilUpdateResponse: any) => {
										// 		if (cbilUpdateResponse?.status) {
										// 			dispatch(
										// 				leadFlow({
										// 					lead_consent_type: 'Customer',
										// 					leadId: addLeadResponse?.newLoanApplication?.id,
										// 					pccId: response?.pccId,
										// 				})
										// 			);
										// 			history.replace('/customer-consent-processing');
										// 		}
										// 	});
										dispatch(
											leadFlow({
												...leadData,
												lead_consent_type: 'Customer',
												leadId: addLeadResponse?.newLoanApplication?.id,
												leadMobile: data?.mobile,
												pccId: response?.pccId,
											})
										);
										history.replace('/customer-consent-processing');
									}

									reset({
										pan_no: '',
										first_name: '',
										middle_name: '',
										last_name: '',
										mobile: '',
										pincode: '',
										state: '',
										city: '',
										dob: '',
										email: '',
										gender: '',
										acceptTerms: false,
									});
									setPinCodeData([]);
								}
							});
					} else {
						dispatch(
							leadFlow({
								...leadData,
								lead_consent_type: 'Customer',
								leadId: addLeadResponse?.newLoanApplication?.id,
								leadMobile: data?.mobile,
							})
						);
						await dispatch(
							dashboardLeadGenerateOtp({
								data: { mobile: data?.mobile, reLogin: false },
							})
						)
							.then(unwrapResult)
							.then((generateOtpResponse: any) => {
								if (generateOtpResponse?.status) {
									const myPromise = new Promise(function (myResolve) {
										setTimeout(function () {
											myResolve(
												history.push('/verify-otp-bureau-report', {
													leadMobile: data?.mobile,
													type: 'VERIFY_LEAD',
													leadId: addLeadResponse?.newLoanApplication?.id,
												})
											);
										}, 10);
									});
									myPromise.then(() => {
										dispatch(
											leadBureauCustomer({
												...customerInfo,
												lead_type: 'Customer',
												leadMobile: data?.mobile,
												type: 'VERIFY_LEAD',
												reSendCibilOtp: false,
												leadId: addLeadResponse?.newLoanApplication?.id,
												checkScoreBody: body,
											})
										);
										reset({
											pan_no: '',
											first_name: '',
											middle_name: '',
											last_name: '',
											mobile: '',
											pincode: '',
											state: '',
											city: '',
											dob: '',
											email: '',
											gender: '',
											acceptTerms: false,
										});
										setPinCodeData([]);
									});
								}
							});
					}
				}
			});
	};
	const handlePopupSkip = async () => {
		setConfirmPopup(false);
		await dispatch(
			dashboardLeadGenerateOtp({
				data: { mobile: customerInfo?.leadMobile, reLogin: false },
			})
		)
			.then(unwrapResult)
			.then((generateOtpResponse: any) => {
				if (generateOtpResponse?.status) {
					const myPromise = new Promise(function (myResolve) {
						setTimeout(function () {
							myResolve(history.push('/verify-otp-bureau-report'));
						}, 10);
					});
					myPromise.then(() => {
						dispatch(
							leadBureauCustomer({
								...customerInfo,
								type: 'VERIFY_LEAD',
								// needCibil: false,
								reSendCibilOtp: false,
								scoreType: LEAD_SCORE_TYPE.EQUIFAX,
							})
						);
						reset({
							pan_no: '',
							first_name: '',
							middle_name: '',
							last_name: '',
							mobile: '',
							pincode: '',
							state: '',
							city: '',
							dob: '',
							email: '',
							gender: '',
							acceptTerms: false,
						});
						setPinCodeData([]);
					});
					reset({
						pan_no: '',
						first_name: '',
						middle_name: '',
						last_name: '',
						mobile: '',
						pincode: '',
						state: '',
						city: '',
						dob: '',
						email: '',
						gender: '',
						acceptTerms: false,
					});
					setPinCodeData([]);
				}
			});
	};
	const handlePopupProceed = async () => {
		dispatch(
			cbilRequestConsentDetails({
				param: `${customerInfo?.leadId}`,
			})
		)
			.then(unwrapResult)
			.then(async (response: any) => {
				setConfirmPopup(false);
				if (response.status) {
					if (response?.is_consent) {
						if (response?.is_otp) {
							history.replace('/view-bureau-report');
						} else {
							await dispatch(
								generateCibilOtp({
									data: {
										...customerInfo?.checkScoreBody,
										lead_id: customerInfo?.leadId,
										mobile_no: customerInfo?.leadMobile,
									},
								})
							)
								.then(unwrapResult)
								.then(async (cbilOtpResponse: any) => {
									if (cbilOtpResponse.status) {
										dispatch(
											leadBureauCustomer({
												...customerInfo,
												cbilResponse: {
													ChallengeConfigGUID:
														cbilOtpResponse?.ChallengeConfigGUID,
													answerKey: cbilOtpResponse?.answerKey,
													questionKey: cbilOtpResponse?.questionKey,
												},
												type: 'VERIFY_CIBIL_OTP',
												reSendCibilOtp: false,
												needCibil: true,
											})
										);
										history.push('/verify-otp-bureau-report');
									}
								});
						}
					} else {
						// dispatch(
						// 	cbilRequestUpdateConsentDetails({
						// 		param: response?.pccId + '?consentVal=1',
						// 	})
						// )
						// 	.then(unwrapResult)
						// 	.then(async (cbilUpdateResponse: any) => {
						// 		if (cbilUpdateResponse?.status) {
						// 			dispatch(
						// 				leadFlow({
						// 					leadId: customerInfo?.leadId,
						// 					pccId: response?.pccId,
						// 				})
						// 			);
						// 			history.replace('/customer-consent-processing');
						// 		}
						// 	});
						dispatch(
							leadFlow({
								...leadData,
								leadId: customerInfo?.leadId,
								pccId: response?.pccId,
							})
						);
						history.replace('/customer-consent-processing');
					}

					reset({
						pan_no: '',
						first_name: '',
						middle_name: '',
						last_name: '',
						mobile: '',
						pincode: '',
						state: '',
						city: '',
						dob: '',
						email: '',
						gender: '',
						acceptTerms: false,
					});
					setPinCodeData([]);
				}
			});
	};

	const genderApi = [
		{
			id: 'Male',
			value: 'Male',
		},
		{
			id: 'Female',
			value: 'Female',
		},
		{
			id: 'Transgender',
			value: 'Transgender',
		},
	];
	return (
		<>
			<IonPage className='bg-web'>
				<Header
					name={t('Check Credit Score')}
					backButton={true}
					needArrow={leadData?.from !== 'customer' ? true : false}
					style={{ borderBottom: '1px solid #00000029' }}
					needArrowOnClick={() => {
						if (preFillData) {
							history.push('/lead-list');
						} else {
							history.push('/check-bureau-score');
						}
					}}
				/>
				<IonContent className='bg-web background-web '>
					<div className='bg-web'>
						<div className='pt-md-4 pb-md-3 add-bureau-score border-md-radius-10 bg-web-white my-4'>
							<div className='px-3'>
								<form onSubmit={handleSubmit(onSubmit)}>
									{leadStepInfoFormInputs.map(
										({ name, type, placeholder }: any, index: any) => {
											const errorText: any =
												errors && Object.keys(errors).length > 0 && errors;

											return type === 'dropdownSelect' ? (
												<div style={{ paddingTop: '5px' }}>
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<DropDownModal
																label={`${t(placeholder)} *`}
																data={name === 'gender' && genderApi}
																value={getValues(name)}
																name={`${t(name)} *`}
																onChange={onChange}
																onBlur={onBlur}
																errors={t(errorText[name]?.message)}
															/>
														)}
														name={name}
													/>
													{errorText[name] && (
														<span
															style={{
																color: '#eb445a',
																fontSize: '10px',
																textTransform: 'initial',
															}}
														>
															{t(errorText[name]?.message)}
														</span>
													)}
												</div>
											) : (
												<Controller
													key={`add-bureau-check-score-${index}`}
													control={control}
													render={({
														field: { onChange, onBlur, name, value },
													}) => (
														<CustomInput
															type={type}
															label={t(placeholder)}
															needSuffixIcon={name === 'dob'}
															suffixClassName={
																'position-absolute date-of-birth-sufix'
															}
															suffixText={
																<IonImg
																	src='/images/icons/input-dob-calendar-icon.svg'
																	alt='dob'
																/>
															}
															outerClassName={`${
																name === 'dob' ? 'position-relative' : ''
															}`}
															onBlur={onBlur}
															onChange={(e: any) => {
																if (name === 'pincode') {
																	if (e.detail.value.length === 6) {
																		fetchStateCityByPincode(
																			setValue,
																			'city',
																			'state',
																			setPinCodeData,
																			dispatch,
																			e.detail.value,
																			trigger
																		);
																	} else {
																		setValue('state', '');
																		setValue('city', '');
																		setPinCodeData([]);
																	}
																}
																onChange(e);
															}}
															value={
																name === 'pan_no'
																	? getValues('pan_no').toUpperCase()
																	: value
															}
															// notRequiredValue={name === 'pan_no'}
															errors={t(errorText[name]?.message)}
															onBeforeInput={(evt: any) => {
																if (type == 'number') {
																	!/[0-9]/.test(evt.data) &&
																		evt.preventDefault();
																}
																if (name.toLowerCase().includes('mobile')) {
																	if (evt.target.value.length === 10) {
																		evt.preventDefault();
																	}
																}
															}}
															className={`${
																[
																	'first_name',
																	'middle_name',
																	'last_name',
																].includes(name)
																	? 'text-capitalize '
																	: ''
															}`}
															onPaste={(e: any) => {
																//Only get numbers on user paste
																if (name.toLowerCase().includes('mobile')) {
																	e.preventDefault();
																	let pasteContent =
																		e.clipboardData.getData('Text');
																	if (pasteContent != '') {
																		pasteContent = pasteContent.replace(
																			/\D/g,
																			''
																		);
																		if (pasteContent.length > 10) {
																			pasteContent = pasteContent.slice(0, 10);
																		}
																	}
																	setValue(name, pasteContent);
																	trigger(name);
																}
															}}
															onKeyDown={(e: any) => {
																if (type == 'number') {
																	disableNumberArrows(e);
																}
															}}
															onWheel={(e: any) => {
																if (type == 'number') {
																	e.target.blur();
																}
															}}
														/>
													)}
													name={name}
												/>
											);
										}
									)}
									{pinCodeData.length > 0 && (
										<StateCity
											cityInputName='city'
											stateInputName='state'
											control={control}
											setValue={setValue}
											pinCodeData={pinCodeData}
											trigger={trigger}
											getValues={getValues}
										/>
									)}
								</form>
							</div>

							<div
								className={`pt-3 position-relative`}
								style={{
									backgroundColor: '#fff',
									zIndex: 2,
								}}
							>
								<Controller
									control={control}
									name={'acceptTerms'}
									render={({ field: { onChange, onBlur, name } }) => (
										<div
											className='d-flex py-3'
											style={{ background: '#EDF8EC' }}
										>
											<div className='position-relative'>
												<CustomCheckbox
													isBackGround={false}
													name={name}
													labelPlacement='end'
													isChecked={getValues('acceptTerms')}
													value={getValues('acceptTerms')}
													onChange={(event: any) => {
														onChange(event);
														setValue('acceptTerms', event.detail.checked);
														trigger();
													}}
													onBlur={onBlur}
													className={
														(errors[name] ? 'ion-checkbox-inValid' : '') +
														' pt-1 px-3'
													}
													errorText={errors[name]?.message}
													errorTextClass='position-absolute checkbox-style1'
												/>
											</div>
											<ConsentCheckbox isMoreContent={isMoreContent} />
										</div>
									)}
								></Controller>
								<div
									onClick={() => {
										setMoreContent(!isMoreContent);
									}}
									className='position-absolute right-0 ion-padding py-1 text-primary fs-12 cursor-pointer fw-600'
								>
									{isMoreContent ? `- ${t('Less')}` : `+ ${t('More')}`}
								</div>
							</div>
							<div className='d-none d-md-block py-3'>
								<CustomButton
									type='submit'
									expand='block'
									className='mt-3 btn-blue px-3'
									title={t('Proceed')}
									disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				</IonContent>
				<IonFooter>
					<div
						className={`d-block d-md-none position-relative ion-padding`}
						style={{
							backgroundColor: '#fff',
							zIndex: 2,
						}}
					>
						<CustomButton
							type='submit'
							expand='block'
							className='my-3 btn-blue px-3'
							title={t('Proceed')}
							disabled={!isValid}
							onClick={handleSubmit(onSubmit)}
						/>
					</div>
				</IonFooter>
			</IonPage>
			<CustomModal
				id='custommodal'
				isOpen={modalPageClose}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setModalPageClose(false)}
				breakpoints={[]}
				className='leadStep'
				modalContent={PageExitPopUp(
					() => {
						setModalPageClose(false);
					},
					() => {
						setModalPageClose(false);
						history.push('/dashboard/home');
						setCurrentStep(null);
					},
					'Cancel',
					'Continue Later',
					'lead-pop-up',
					`${t('You can continue later from where you left off.')}`
				)}
				needCross={false}
				needArrow={false}
			/>
			<CustomModal
				id='generate-cbil-popup'
				isOpen={confirmPopup}
				initialBreakpoint={isWeb ? 1 : 0.65}
				handleClose={() => setConfirmPopup(false)}
				breakpoints={[0, 0.45, 0.5, 0.75]}
				// modalHeight='750px'
				modalContent={
					<div className='mt-4'>
						<img
							src='/images/icons/report.svg'
							alt='report'
							className='d-block mx-auto'
						/>
						<p className='fs-18 fw-600 my-3 mb-5 text-center'>
							{t('Do you want to proceed generating the CIBIL Bureau Report?')}
						</p>
						<CustomButton
							title={t('Skip CIBIL')}
							className='w-100 dashboard-add-lead-button fw-bold'
							//  expand='block'
							fill='solid'
							onClick={handlePopupSkip}
						/>
						<CustomButton
							title={t('Request Consent For CIBIL')}
							className='btn-blue fw-bold'
							expand='block'
							fill='outline'
							onClick={handlePopupProceed}
						/>
					</div>
				}
				needCross={false}
				needArrow={false}
				className={`side-modal ${isWeb ? '' : 'modal-border-radius'}`}
			/>
		</>
	);
};

export default AddBureauDetail;
