import { PersonalInfo } from '../LenderSpecificInfo';

export const MoneyViewFormFields = [
	...PersonalInfo,
	{
		name: 'net_monthly_income',
		type: 'text',
		placeholder: 'Net Monthly Income *',
		isDisabled: true,
	},
];
