import { IonChip, IonItem, IonLabel, IonList } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { CustomNoData } from "../../components/common/custom-no-data/CustomNoData";
import { Divider } from "../../components/common/divider/Divider";
import {
  LEAD_STATUS,
  LeadStatus,
  lenderApplicationType,
} from "../../helpers/constant";
import {
  findCurrentStatus,
  // findStatus,
  // getStatus,
  setBankIcon,
} from "../../helpers/functions";
import { useHistory } from "react-router";
import { ApplicationStatusProps } from "../update-status/update_application_status/update_application_status";
import { CustomButton } from "../../components/common/custom-button/CustomButton";
import DropDownModal from "../../components/common/custom-dropdown/DropDownModal";
import { PolicyDetailsProps } from "./policy_details";

interface InsuranceLeadApplicationsProps {
  bankData: any;
  applicationStatusData: any;
  selectedApplicationStatus: any;
  selectApplicationStatusValue: any;
}

const InsuranceLeadApplications: React.FC<InsuranceLeadApplicationsProps> = ({
  bankData,
  applicationStatusData,
  selectedApplicationStatus,
  selectApplicationStatusValue,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  console.log(applicationStatusData);
    return (
    <>
      <Divider />
      <div className="m-3 bg-web-white">
        <div className="mt-2 d-flex justify-content-between align-items-center">
          <h4 className="fw-bold fs-20">{t("Applications")}</h4>
          <div className="" style={{ width: "200px" }}>
            <DropDownModal
            data={applicationStatusData}
            onChange={(e: any) => selectedApplicationStatus(e)}
            value={selectApplicationStatusValue?.id}
            />
          </div>
        </div>

        {bankData.length > 0 ? (
          <div>
            {bankData.map((item: any, index: any) => {
              return (
                <IonList
                  lines={bankData.length - 1 !== index ? "full" : "none"}
                  key={`inner-details-${index}`}
                >
                  <IonItem className="your-accounts">
                    <div
                      className="border ms-2 d-flex"
                      style={{
                        width: "48px",
                        height: "48px",
                        borderRadius: "50%",
                        alignSelf: "start",
                        margin: "5px",
                      }}
                    >
                      <img
                        style={{
                          minWidth: "48px",
                          minHeight: "48px",
                        }}
                        className="selected-lender-bank-icon m-0"
                        src={setBankIcon(item?.icon)}
                        alt="..."
                      />
                    </div>
                    <IonLabel className="ion-text-wrap">
                      <p
                        style={{ color: "#5D5E72" }}
                        className="fs-12 mb-0 fw-600"
                      >
                        #A{item.id}
                      </p>
                      <h5 className="fs-16 fw-600">{item?.lender_name}</h5>
                      {/* {item?.bank_app_no && (
                        <p style={{ color: "#5D5E72" }} className="fs-14 mb-0 ">
                          {t("Bank App. No.")} :
                          <span className={`fw-600`}> {item?.bank_app_no}</span>
                        </p>
                      )} */}
                      {item?.bank_app_no && (
                        <p style={{ color: "#5D5E72" }} className="fs-14 mb-0 ">
                          {t("Insurer App. No.")} :
                          <span className={`fw-600`}>
                            {" "}
                            {item?.insurer_app_no}
                          </span>
                        </p>
                      )}
                      {item?.lan && (
                        <p style={{ color: "#5D5E72" }} className="fs-14 mb-0">
                          {t("Policy ID No.")}:{" "}
                          <span className={`fw-600`}>{item?.policy_id_no}</span>
                        </p>
                      )}

                      {/* {item?.sanction_amount && (
                        <p style={{ color: "#5D5E72" }} className="fs-14 mb-0">
                          {t("Sanctioned Amount")}:{" "}
                          <span className="fw-600">
                            {`₹ ${InputFormatCurrencyValue(
                              item?.sanction_amount
                            )}`}
                          </span>
                        </p>
                      )} */}
                      {/* {item?.total_disbursal_amount && (
                        <p style={{ color: "#5D5E72" }} className="fs-14 mb-0">
                          {t("Total Amount Disbursed")}:{" "}
                          <span className="fw-600">
                            {`₹ ${InputFormatCurrencyValue(
                              parseInt(item?.total_disbursal_amount)
                            )}`}
                          </span>
                        </p>
                      )} */}

                      {/* <CustomButton
                        size={"extra small"}
                        fill="outline"
                        className={"text-wrap"}
                        disabled={
                          findCurrentStatus(item.status).label ===
                          LEAD_STATUS.CLOSED_LABEL
                        }
                        onClick={() => {
                          if (
                            findCurrentStatus(item.status).label !==5
                            LEAD_STATUS.CLOSED_LABEL
                          ) {
                            let path = "";
                            if (
                              item.status == LeadStatus.PARTIALDISBURSED ||
                              item.status == LeadStatus.DISBURSED
                            ) {
                              path = "/sanction-and-disbursed-detail";
                            } else {
                              leadInfo?.from === "nimbuss"
                                ? (path = "/update-status?from=nimbuss")
                                : item.app_off_status?.toLowerCase() ===
                                  "online"
                                ? item.status ===
                                  LeadStatus.APPLICATIONSFULFILLED
                                  ? (path = "/verify-lender-with-otp")
                                  : (path = "/update-status")
                                : (path = "/update-status");
                            }

                            dispatch(markAsClose(false));
                            dispatch(
                              leadFlow({
                                ...leadInfo,
                                applicationStatus: findCurrentStatus(
                                  item.status
                                ).label,
                                backUpdateStatus: findCurrentStatus(item.status)
                                  .label,
                                redirectToDetailPage: true,
                                update: leadInfo.update + 1 || 1,
                                disbursedLenderDetails: item,
                                getLeadAllSelectedIds: [],
                                appsToUpdate: [],
                                iSBackFromDsburseAndSanction: false,
                                isFromDisbursed: false,
                                isBackLenderOnlineForm: true,
                              })
                            );
                            dispatch(selectedLender([item]));
                            history.push(path);
                          }
                        }}
                      >
                        {findCurrentStatus(item.status).label}
                        {findCurrentStatus(item.status).label !==
                          LEAD_STATUS.CLOSED_LABEL && (
                          <img
                            className="ps-2"
                            src="images/icons/edit.svg"
                            alt="edit"
                          />
                        )}
                      </CustomButton> */}
                      <CustomButton
                        size={"extra small"}
                        fill="outline"
                        className={"text-wrap"}
                        onClick={() => {
                          console.log("hbs item.updateStatus", item.updateStatus);
                          const props: ApplicationStatusProps = {
                            inpApplicationId:undefined,
                            inpPolicyId:item.id,
                            inpCurrentStatusLabel:item.updateStatus,
                          };

                          history.push('/updatePolicyStatus',{
                            applicationStatusProps:props,
                          });
                        }}
                      >
                        {item.updateStatus}
                        {item?.updateStatus && (
                          <img
                            className="ps-2"
                            src="images/icons/edit.svg"
                            alt="edit"
                          />
                        )}
                      
                      </CustomButton>
                      <CustomButton
                        size={"extra small"}
                        fill="outline"
                        className={"text-wrap"}
                        onClick={() => {
                          console.log("hbs item.updateStatus", item.updateStatus);
                          const props: PolicyDetailsProps = {
                            inpItem:item,
                            inpPolicyId: item.id
                          };

                          history.push('/view-policy-details',{
                            policyDetailsProps:props,
                          });
                        }}
                      >
                        {`View Policy Details`}
                        { 
                          <img
                            className="ps-2"
                            src="images/icons/right-arrow-blue-icon.svg"
                            alt="edit"
                          />
                        }
                      
                      </CustomButton>
                    </IonLabel>
                    <IonLabel
                      slot="end"
                      className="m-0 align-self-start position-absoulte right-0"
                      // style={{ alignSelf: 'start' }}
                    >
                      {item.app_off_status?.toLowerCase() === "online" ? (
                        <IonChip
                        onClick={() => {
                          // // id

                          // const props: ApplicationStatusProps = {
                          //   inpApplicationId:undefined,
                          //   // inpCurrentStatus:item.status,
                          //   inpCurrentStatus:21,
                          //   inpPolicyId:item.id,

                          // };

                          // history.push('/updatePolicyStatus',{
                          //   applicationStatusProps:props,
                          // });
                        
                        }}
                          className={`fs-12 chip-online`}
                          style={{
                            padding: "8px 8px 8px",
                            height: "23px",
                            opacity: 1,
                          }}
                        >
                          {lenderApplicationType.ONLINE}
                        </IonChip>
                      ) : (
                        <IonChip
                          disabled={false}
                          onClick={() => {
                            // const props: ApplicationStatusProps = {
                            //   inpApplicationId:undefined,
                            //   // inpCurrentStatus:item.status,
                            //   inpCurrentStatus:21,
                            //   inpPolicyId:item.id,
  
                            // };
  
                            // history.push('/updatePolicyStatus',{
                            //   applicationStatusProps:props,
                            // });
                          }}
                          className={`fs-12 chip-offline`}
                          style={{
                            padding: "8px 8px 8px",
                            height: "23px",
                            opacity: 1,
                          }}
                        >
                          {lenderApplicationType.OFFLINE}
                        </IonChip>
                      )}
                    </IonLabel>
                  </IonItem>
                </IonList>
              );
            })}
          </div>
        ) : (
          <CustomNoData className="py-3" message={t("No Data Found")} />
        )}
      </div>
    </>
  );
};

export default InsuranceLeadApplications;
