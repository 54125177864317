import {
	IonCol,
	IonContent,
	IonFooter,
	IonGrid,
	IonImg,
	IonPage,
	IonRow,
} from '@ionic/react';
import React from 'react';
import { Header } from '../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import './Teams.scss';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
import { useHistory } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { REGEX } from '../../helpers/constant';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import {
	apiPanCardValid,
	empFlowData,
} from '../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';

const loginSchema = yup.object().shape({
	pan: yup
		.string()
		.required('Pan Number is Required.')
		.matches(REGEX.PAN, 'Please enter valid PAN number.'),
});

export const InviteEmployee: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();

	const {
		handleSubmit,
		control,
		getValues,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(loginSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			pan: '',
		},
	});
	const onSubmit = async () => {
		const { pan } = getValues();

		await dispatch(
			apiPanCardValid({
				param: `?GetEmpConfType=${pan?.toUpperCase()}&andro_employee=true`,
			})
		)
			.then(unwrapResult)
			.then(async (result: any) => {
				console.log("result Data");
				console.log(result?.data);
				if (result?.status) {
					 console.log(result?.data?.[0]);
					 
					await dispatch(empFlowData(result?.data?.[0]));
					history.replace('/invite-employee-form', {
						employeeData: result?.data?.[0],
					});
				}else{
					console.log("No data is Available");
				}
			});
	};
	return (
		<IonPage className='teams'>
			<Header
				name={t('Invite Employee')}
				backButton={true}
				needArrow={true}
			/>

			<IonContent
			// style={{
			// 	borderTop: '1px solid #00000029',
			// 	height: 'calc(100vh - 113px)',
			// 	overflowX: 'hidden',
			// }}
			>
				<div className='bg-web h-100 pt-md-3'>
					<div className=' conatiner-spacing-md py-md-4 bg-web-white border-radius-10 container-width-md e-franking-send-container-height d-md-flex justify-content-md-between flex-md-column'>
						<form
							onSubmit={handleSubmit(onSubmit)}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									handleSubmit(onSubmit);
								}
							}}
						>
							<IonGrid>
								<IonRow>
									<IonCol
										size='12'
										// className='my-3'
									>
										<Controller
											control={control}
											render={({ field: { onChange, onBlur, name } }) => (
												<CustomInput
													placeholder={t('PAN *')}
													onBlur={onBlur}
													name={name}
													value={getValues(name)}
													onChange={onChange}
													errors={errors?.pan?.message}
													needSuffixIcon={true}
													className='text-uppercase'
													suffixClassName={'position-absolute top-25 end-12'}
													suffixText={
														<IonImg src='/images/icons/input-scan-icon.svg' />
													}
												/>
											)}
											name={'pan'}
										/>
									</IonCol>
								</IonRow>
							</IonGrid>
						</form>
						<div className='d-none d-md-block'>
							<CustomButton
								expand={'block'}
								type={'submit'}
								className={'btn-primary-small'}
								onClick={handleSubmit(onSubmit)}
								title={t('Proceed')}
								disabled={!isValid}
							/>
						</div>
					</div>
				</div>
			</IonContent>
			<IonFooter className='ion-padding d-block d-md-none'>
				<CustomButton
					expand={'block'}
					type={'submit'}
					className={'btn-primary-small'}
					onClick={handleSubmit(onSubmit)}
					title={t('Proceed')}
					disabled={!isValid}
				/>
			</IonFooter>
		</IonPage>
	);
};
