import { ReactNode, createContext, useContext, useState } from "react";

interface BusyLoadingContextType {
  isBusy: boolean;
  setBusyState: (loading: boolean) => void;
}

const BusyLoadingContext = createContext<BusyLoadingContextType>({
  isBusy: false,
  setBusyState: () => {},
});

export const useBusyLoading = () => useContext(BusyLoadingContext);

interface BusyLoadingProviderProps {
  children: ReactNode;
}

export const BusyLoadingProvider = ({ children }: BusyLoadingProviderProps) => {
  const [isBusy, setBusy] = useState(false);

  const setBusyState = (loading: boolean) => {
    setBusy(loading);
  };

  return (
    <BusyLoadingContext.Provider value={{ isBusy, setBusyState }}>
      {children}
    </BusyLoadingContext.Provider>
  );
};
