import React, { useState } from 'react';
import {
	IonContent,
	IonPage,
	IonItem,
	isPlatform,
	// useIonRouter,
} from '@ionic/react';
import * as yup from 'yup';
import { useHistory } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { REGEX, STATUS_CODE } from '../../../helpers/constant';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import { Header } from '../../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
	apiLogin,
	checkUserMobile,
	dmaFlowData,
} from '../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { disableNumberArrows } from '../../../helpers/functions';
import { SmsRetriever } from '@awesome-cordova-plugins/sms-retriever';
// import { showToastAlert } from '../../../helpers/functions';

const loginSchema = yup.object().shape({
	mobile: yup
		.string()
		.length(10, 'Mobile No. Should Be 10 Digits.')
		.required('Mobile is Required.')
		.matches(REGEX.INDIAN_MOBILE_NUMBERS, 'Please Enter a valid Mobile No.'),
});

export const AddDma: React.FC = () => {
	const history = useHistory();
	const dispatch: any = useDispatch();
	const { t } = useTranslation();
	const [showExitingDmaPayout, setShowExitingDmaPayout] = useState(false);
	const [existingDmaId, setExistingDmaId] = useState(null);

	const {
		handleSubmit,
		control,
		trigger,
		setValue,
		getValues,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(loginSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			mobile: '',
		},
	});

	const onSubmit = async () => {
		const { mobile } = getValues();
		let sms_code: any;
		if (isPlatform('capacitor')) {
			await SmsRetriever.getAppHash()
				.then((res: any) => {
					sms_code = res;
				})
				.catch((error: any) => console.error(error));
		}
		await dispatch(checkUserMobile({ param: `?mobile=${mobile}` }))
			.then(unwrapResult)
			.then(async (response: any) => {
				if (response?.status) {
					if (response.payout) {
						setShowExitingDmaPayout(true);
						setExistingDmaId(response?.id);
					} else {
						await dispatch(
							apiLogin({
								data: {
									mobile: Number(mobile),
									reLogin: false,
									sms_hc: sms_code,
								},
							})
						)
							.then(unwrapResult)
							.then(async (result: any) => {
								if (result?.code === STATUS_CODE.SIGNIN_SUCCESS) {
									await dispatch(dmaFlowData({ dmaMobile: String(mobile) }));
									history.replace('/verify-dma', {
										userMobile: String(mobile),
									});
								}
							});
					}
				}
			});
	};

	return (
		<IonPage
			className='add-mobile bg-web'
			style={{ background: '#EBEBEB' }}
		>
			<Header
				name={t('Add DMA')}
				backButton={true}
				needArrow={true}
			/>

			<IonContent className='bg-web background-web'>
				<div className='bg-web h-90'>
					<div className='py-md-3 border-md-radius-10 opacity-1 bg-web-white mt-md-3 container-width-md'>
						<div
							style={{ background: '#EBEBEB' }}
							className='p-3 fs-16 fw-600'
						>
							{t("Add DMA's Mobile Number")}
						</div>
						<div className='pt-3 px-3'>
							<form
								onSubmit={handleSubmit(onSubmit)}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										handleSubmit(onSubmit);
									}
								}}
							>
								<Controller
									control={control}
									render={({ field: { onChange, onBlur, name } }) => (
										<CustomInput
											type={'number'}
											placeholder={t('Mobile Number') + ' *'}
											onBlur={onBlur}
											name={name}
											onChange={onChange}
											value={getValues(name)}
											onBeforeInput={(evt: any) => {
												//Only numbers accepted on user input
												!/[0-9]/.test(evt.data) && evt.preventDefault();
												//Only accepts 10 characters on user input
												if (evt.target.value.length === 10) {
													evt.preventDefault();
												}
											}}
											onPaste={(e: any) => {
												//Only get numbers on user paste
												e.preventDefault();
												let pasteContent = e.clipboardData.getData('Text');
												if (pasteContent != '') {
													pasteContent = pasteContent.replace(/\D/g, '');
													if (pasteContent.length > 10) {
														pasteContent = pasteContent.slice(0, 10);
													}
												}
												setValue('mobile', pasteContent);
												trigger('mobile');
											}}
											onKeyDown={(e: any) => {
												disableNumberArrows(e);
											}}
											onWheel={(e: any) => e.target.blur()}
											errors={t(errors?.mobile?.message as string)}
										/>
									)}
									name={'mobile'}
								/>
								{/* <CustomTest /> */}
								<div className='proceed-btn'>
									<CustomButton
										type='submit'
										disabled={!isValid}
										className='btn-blue'
										expand='block'
										title={t('Proceed')}
									/>
								</div>
							</form>
							<p>
								{t(
									`Note: If signing up as a firm, enter the mobile number of the Authorised Partner/ Director.`
								)}
							</p>
						</div>
						<CustomModal
							id=''
							isOpen={showExitingDmaPayout}
							modalTitle='Update Stage'
							handleClose={() => setShowExitingDmaPayout(false)}
							initialBreakpoint={0.4}
							breakpoints={[0, 0.35, 0.5, 0.75]}
							style={{ '--border-radius': '20px' }}
							modalContent={
								<div className='wrapper pt-3'>
									<IonItem lines='none'>
										<div className='d-flex flex-column'>
											<h5 className='mx-0 fs-20'>
												{t(
													'Already an existing DMA. Do you want to Add Payout for this DMA now or later?'
												)}
											</h5>
											{/* <p className='m-0 fs-14'>
									{t(`By doing this you will not be able to take any action on this
lead anymore.`)}
								</p> */}
										</div>
									</IonItem>
									<div className='d-block justify-content-center gap-3 px-3 mt-1'>
										<CustomButton
											onClick={() => {
												setShowExitingDmaPayout(false);
												history.push('');
											}}
											className='w-100'
											fill='outline'
											title={t('Continue Later')}
										/>
										<CustomButton
											className='w-100'
											fill='solid'
											onClick={() => {
												setShowExitingDmaPayout(false);
												history.replace('/proposed-payout', {
													existingDmaId: existingDmaId,
												});
											}}
											title={t('Add Proposed Payout')}
										/>
									</div>
								</div>
							}
							needIonContentWraper={false}
							needCross={false}
							needArrow={false}
						/>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};
