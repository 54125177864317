import { IonSegment, IonSegmentButton } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { PrimaryApplicantForm1 } from './PrimaryApplicantForm1';
import { PrimaryApplicantForm2 } from './PrimaryApplicantForm2';
import { CoApplicantForm } from './CoApplicantForm';
import {
	apiGetLeadDetails,
	fetchCommonFieldsDataPl,
} from '../../../../store/reducers/lead/leadSlice';

export const HLLAPForms: React.FC<any> = () => {
	const [selectedTab, setSelectedTab] = useState('ApplicantFormOne');
	const [coApplicantTabIndex, setCoApplicantTabIndex] = useState(0);
	const [totalCoApplicant, setTotalCoApplicant] = useState([1]);
	const [isCoApplicant, setCoApplicant] = useState(false);
	const [isValidForm, setValidForm] = useState(false);
	const [leadInfo, setLeadInfo] = useState<any>(null);
	const dispatch = useDispatch();
	const { leadFlowData, lenderSpecificDetails } = useSelector(
		(state: any) => state?.lead
	);
	useEffect(() => {
		if (location.pathname !== '/lead-lender-specific-form-steps') {
			return;
		}
		if (
			leadFlowData?.selectedProductData?.product_category_id == 4 &&
			lenderSpecificDetails?.lender_spf_details?.is_co_applicant === 'Yes'
		) {
			setCoApplicant(true);
		}
		if (lenderSpecificDetails?.lender_spf_details?.co_applicant) {
			const coAppData = JSON.parse(
				JSON.stringify(lenderSpecificDetails?.lender_spf_details?.co_applicant)
			);
			for (let i = 0; i < coAppData.length - 1; i++) {
				setTotalCoApplicant([...totalCoApplicant, i]);
			}
		}
		getLeadData();
	}, []);

	/**
	 * Get lead data
	 */
	const getLeadData = async () => {
		let commonData: any;
		let personalInfo: any;
		await dispatch(
			fetchCommonFieldsDataPl({
				param: '/' + leadFlowData?.leadId,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response.status) {
					commonData = response.data;
				}
			});

		await dispatch(apiGetLeadDetails({ param: leadFlowData?.leadId }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					personalInfo = result.leads;
					if (result?.leads?.professional_details['Co Applicant'] === 'Yes') {
						setCoApplicant(true);
					}
				}
			});

		setLeadInfo({
			commonData: commonData,
			personalInfo: personalInfo,
		});
	};

	/**
	 * Handle tab change
	 * @param value tab value
	 */
	const handleSegmentChange = (value: any) => {
		setSelectedTab(value);
		if (value.includes('CoApplicantForm')) {
			setCoApplicantTabIndex(value.slice(-1));
		}
	};

	/**
	 * Check device inner width
	 */
	const isMobileDevice = () => {
		return window.innerWidth <= 768;
	};

	return (
		<>
			<IonSegment
				scrollable={true}
				value={selectedTab}
				onIonChange={(e) => handleSegmentChange(e.target.value)}
				style={{
					position: 'sticky',
					top: 0,
					zIndex: 999,
					backgroundColor: 'white',
				}}
			>
				<IonSegmentButton value='ApplicantFormOne'>
					<label>
						<span>
							{t(isMobileDevice() ? 'Form One' : 'Applicant Form One')}
						</span>
					</label>
				</IonSegmentButton>

				<IonSegmentButton value='ApplicantFormTwo'>
					<label>
						<span>
							{t(isMobileDevice() ? 'Form Two' : 'Applicant Form Two')}
						</span>
					</label>
				</IonSegmentButton>
				{isCoApplicant &&
					totalCoApplicant.map((el: any, idx: number) => {
						return (
							<IonSegmentButton
								value={`CoApplicantForm${idx}`}
								key={idx}
							>
								<label>
									<span>{t(`Co-Form ${idx + 1}`)}</span>
								</label>
							</IonSegmentButton>
						);
					})}
			</IonSegment>

			{leadInfo && selectedTab === 'ApplicantFormOne' ? (
				<PrimaryApplicantForm1
					setSelectedTabValue={setSelectedTab}
					leadInfo={leadInfo}
					setValidForm={setValidForm}
				/>
			) : leadInfo && selectedTab === 'ApplicantFormTwo' ? (
				<PrimaryApplicantForm2
					isCoApplicant={isCoApplicant}
					setSelectedTabValue={setSelectedTab}
					setCoApplicant={setCoApplicant}
					setValidForm={setValidForm}
				/>
			) : leadInfo &&
			  selectedTab === `CoApplicantForm${coApplicantTabIndex}` ? (
				<CoApplicantForm
					coTabIndex={coApplicantTabIndex}
					handleSegmentChange={(e: any) => handleSegmentChange(e)}
					setTotalCoApplicant={setTotalCoApplicant}
					totalCoApplicant={totalCoApplicant}
					setValidForm={setValidForm}
					isValidForm={isValidForm}
				/>
			) : (
				''
			)}
		</>
	);
};
