import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonItem, IonImg, IonList, IonLabel, isPlatform } from '@ionic/react';
import {
	dataURLtoFile,
	getFileExtByUrl,
	getFileWithUpdatedToken,
	getFilenameByUrl,
	showInAppBrowserView,
} from '../../../../helpers/functions';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import { StatusNum } from '../../../../helpers/constant';
import { GstEdit } from './Component/GstEdit';
import { useDispatch, useSelector } from 'react-redux';
import {
	apiKycDocumentUpload,
	imgUrlUpdate,
	kycUserUploadImage,
} from '../../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';

interface Props {
	userDetails: any;
	userId: any;
	listStatus: any;
}

export const GstTab: React.FC<Props> = ({
	userDetails,
	userId,
	listStatus,
}) => {
	const { t } = useTranslation();
	const isBrowser = !isPlatform('capacitor');
	const [fileName, setFileName] = useState<any>('');
	const [openUploadModal, setOpenUploadModal] = useState(false);
	const [showGstInfoForm, setShowGstInfoForm] = useState<any>(false);
	const [userData, setUserData] = useState<any>(userDetails);
	const dispatch = useDispatch();
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	useEffect(() => {
		setUserData(userDetails);
		setFileName(getFilenameByUrl(userDetails?.Url));
	}, [userDetails]);

	const onImgError = () => {
		getFileWithUpdatedToken({
			dispatch,
			Url: userData?.Url,
			imgUrlUpdate,
			unwrapResult,
			setUserFile: null,
			setName: setFileName,
			userData: userData,
			setUserData: setUserData,
			setUserData2: null,
		});
	};
	const ShareModal = () => {
		const handleUploadChange = async (
			type: string,
			fieldName: string,
			e: any
		) => {
			setOpenUploadModal(false);
			// case1
			const formData = new FormData();
			// let result: any;

			// case 2
			let image: any;
			switch (type) {
				case 'file':
					formData.append('profile', e.target.files[0]);
					await dispatch(kycUserUploadImage({ data: formData }))
						.then(unwrapResult)
						.then(async (response: any) => {
							if (response?.status) {
								await dispatch(
									apiKycDocumentUpload({
										param: '/' + userId,
										data: {
											Type: 'GST',
											Url: response?.url,
											RW: true,
										},
									})
								)
									.then(unwrapResult)
									.then((responseData: any) => {
										if (responseData.status) {
											const updatedData = {
												...userData,
												Url: response?.url,
											};
											setUserData(updatedData);
											setFileName(getFilenameByUrl(response?.url));
											setOpenUploadModal(false);
										}
									});
							}
						});
					break;
				case 'photo':
					image = await Camera.getPhoto({
						quality: 75,
						allowEditing: false,
						resultType: CameraResultType.DataUrl,
						source: CameraSource.Photos,
					});
					formData.append(
						'profile',
						dataURLtoFile(
							image.dataUrl,
							`${Math.random() * 100000000000000000}.${image.format}`
						)
					);
					await dispatch(kycUserUploadImage({ data: formData }))
						.then(unwrapResult)
						.then(async (response: any) => {
							if (response?.status) {
								await dispatch(
									apiKycDocumentUpload({
										param: '/' + userId,
										data: {
											Type: 'GST',
											Url: response?.url,
											RW: true,
										},
									})
								)
									.then(unwrapResult)
									.then((responseData: any) => {
										if (responseData.status) {
											const updatedData = {
												...userData,
												Url: response?.url,
											};
											setUserData(updatedData);
											setFileName(getFilenameByUrl(response?.url));
											setOpenUploadModal(false);
										}
									});
							}
						});
					break;
				case 'camera':
					image = await Camera.getPhoto({
						quality: 75,
						allowEditing: false,
						resultType: CameraResultType.DataUrl,
						source: CameraSource.Camera,
					});
					formData.append(
						'profile',
						dataURLtoFile(
							image.dataUrl,
							`${Math.random() * 100000000000000000}.${image.format}`
						)
					);
					await dispatch(kycUserUploadImage({ data: formData }))
						.then(unwrapResult)
						.then(async (response: any) => {
							if (response?.status) {
								await dispatch(
									apiKycDocumentUpload({
										param: '/' + userId,
										data: {
											Type: 'GST',
											Url: response?.url,
											RW: true,
										},
									})
								)
									.then(unwrapResult)
									.then((responseData: any) => {
										if (responseData.status) {
											const updatedData = {
												...userData,
												Url: response?.url,
											};
											setUserData(updatedData);
											setFileName(getFilenameByUrl(response?.url));
											setOpenUploadModal(false);
										}
									});
							}
						});

					break;
				default:
					break;
			}
		};
		return (
			<>
				<IonList>
					{!isBrowser && (
						<IonItem lines='full'>
							<img
								src='/images/icons/kyc/Image-upload.svg'
								alt='Upload Photo'
							/>
							<IonLabel>
								<h2
									className='fs-16 ps-3 text-dark'
									onClick={(e) => {
										handleUploadChange('photo', '', e);
									}}
								>
									Upload Photo
								</h2>
							</IonLabel>
						</IonItem>
					)}
					<IonItem lines='full'>
						<img
							src='/images/icons/kyc/upload-image-icon.svg'
							alt='Upload Photo'
						/>
						<IonLabel>
							<label htmlFor='upload-photo'>
								<h2 className='fs-16 ps-3 text-dark'>{t('Upload Document')}</h2>
							</label>
							<input
								type='file'
								id='upload-photo'
								accept='image/png, image/jpeg ,application/pdf'
								onChange={(e) => {
									handleUploadChange('file', '', e);
								}}
							/>
						</IonLabel>
					</IonItem>
					{!isBrowser && (
						<IonItem lines='full'>
							<img
								src='/images/icons/kyc/camera-icon.svg'
								alt='Upload Photo'
							/>
							<IonLabel>
								<h2
									className='fs-16 ps-3 text-dark'
									onClick={(e) => {
										handleUploadChange('camera', '', e);
									}}
								>
									Take Photo
								</h2>
							</IonLabel>
						</IonItem>
					)}
				</IonList>
			</>
		);
	};

	return (
		<div>
			<div className='ion-margin image-outline position-relative'>
				<IonImg
					className='preview-img'
					src={
						getFileExtByUrl(userData?.Url)?.toLowerCase() === 'pdf'
							? 'images/icons/pdf-placeholder.svg'
							: userData?.Url
					}
					alt='pan card img'
					onIonError={onImgError}
					onClick={() => showInAppBrowserView(userData?.Url)}
				></IonImg>

				{userData?.approve === 'rework_required' &&
					listStatus === StatusNum.REWORK && (
						<div className='preview-img-edit'>
							<div
								className='d-inline-block fw-600 fs-14 primary-text-color'
								onClick={() => {
									setOpenUploadModal(true);
								}}
							>
								<img
									src='images/icons/user-details-edit-icon.svg'
									className='me-2'
								/>
								{t('Edit')}
							</div>
						</div>
					)}

				<div className='preview-img-fileName'>
					<img
						className='me-2'
						src='images/icons/preview-img-filename-icon.svg'
					/>
					<span className='text-truncate fw-600'>{fileName}</span>
				</div>
			</div>
			<div className='d-flex justify-content-end ion-padding-horizontal'>
				{userData?.approve === 'rework_required' &&
					listStatus === StatusNum.REWORK && (
						<div
							className='d-flex fw-600 fs-14 primary-text-color'
							onClick={() => setShowGstInfoForm(true)}
						>
							<img
								src='images/icons/user-details-edit-icon.svg'
								className='me-2'
							/>
							{t('Edit')}
						</div>
					)}
			</div>
			<IonItem
				className='ion-no-padding ion-margin-horizontal item-no-padding-end remove-ion-item-inner-padding-end'
				lines='full'
			>
				<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
					<p className='mb-0 fs-14'>{t('GST')}</p>
					<p className='mb-0 fs-14 fw-600'>
						{userData?.No ? userData?.No : '-'}
					</p>
				</div>
			</IonItem>
			{/* Upload Photo Modal */}
			<CustomModal
				id='sharemodal'
				isOpen={openUploadModal}
				initialBreakpoint={isWeb ? 1 : 0.3}
				handleClose={() => setOpenUploadModal(false)}
				breakpoints={[0, 0.3, 0.5, 0.75]}
				modalContent={<ShareModal />}
				needCross={false}
				needArrow={false}
				className={`side-modal ${isWeb ? '' : 'modal-border-radius'}`}
			/>

			<CustomModal
				id='customModalRework'
				isOpen={showGstInfoForm}
				modalTitle={t('GST Info')}
				initialBreakpoint={1}
				handleClose={() => setShowGstInfoForm(false)}
				breakpoints={[0, 1]}
				className='side-modal'
				modalContent={
					<GstEdit
						userId={userId}
						data={userData}
						setUserData={setUserData}
						setShowGstInfoForm={setShowGstInfoForm}
					/>
				}
				needCross={true}
				needArrow={false}
			></CustomModal>
		</div>
	);
};
