export type verifyOtpData = {
    product_id : string | null,
    sub_product_id : string | null,
    unique_id: string | null,
    mobile: string | null,    
    otp : string | null,
    cust_yn: number | null
}

export interface verifyOtpResponse {
    otp: string | null,
    mobile: string | null,
    message : string | null,
    success: boolean;
}

export class VerifyOtpRequest {
    product_id : string | null = '2';
    sub_product_id : string | null = 'insurance';
    unique_id: string | null ;
    mobile: string | null; 
    otp : string | null;
    cust_yn: number | null = 1;

    constructor() {
        this.product_id = null;
        this.sub_product_id = null;
        this.unique_id = null;
        this.mobile = null;
        this.otp = null;
        this.cust_yn = null;
    }
  }
 