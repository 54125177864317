import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	InitiateABFLLead,
	InitiateInCredLead,
	InitiateLendingKartLead,
	InitiateKreditBeeLead,
	InitiateLnTLead,
	InitiateTataLead,
	InitiateAadharLead,
	InitiatePrefrLead,
	InitiateDMILead,
	InitiateIndifiLead,
} from './index';
import { LenderMasterID } from '../../helpers/lenders';
import { InitiateBajajLead } from './Bajaj/InitiateBajajLead';
import './LeadLenderSpecificForms.scss';
import { InitiateKotakLead } from './Kotak_HL/InitiateKotakLead';
import { DefaultPage } from './DefaultPage';
import { InitiateMoneyView } from './money-view/InitiateMoneyView';
import { InitiateCasheView } from './cashe/InitiateCasheView';
import { InitiatePunjabLead } from './Punjab_HL/InitiatePunjabLead';
import InitiateFibePlForm from './Fibe_PL/InitiateFibePlForm';
import InitiateMoneyTapForm from './MoneyTap/InitiateMoneyTapForm';

export const LeadLenderSpecificForms: React.FC = () => {
	const { selectedLender, lenderSpecificDetails } = useSelector(
		(state: any) => state?.lead
	);
	const [lenderForm, setLenderForm] = useState(null);

	useEffect(() => {
		if (!location.pathname.includes('/lead-lender-specific-form-steps')) {
			return;
		}

		if (lenderSpecificDetails?.step) {
			loadLeadApplicantForms();
		}
	}, [location.pathname]);

	/**
	 * Show applicant form based on the lender selection
	 */
	const loadLeadApplicantForms = () => {
		let lenderFm: any = null;
		switch (selectedLender[0]?.lender_id) {
			case LenderMasterID.ADITYA_BIRLA_FINANCE_LTD:
				lenderFm = <InitiateABFLLead />;
				break;
			case LenderMasterID.INCRED:
				lenderFm = <InitiateInCredLead />;
				break;
			case LenderMasterID.LENDINGKART:
				lenderFm = <InitiateLendingKartLead />;
				break;
			case LenderMasterID.KREDITBEE:
				lenderFm = <InitiateKreditBeeLead />;
				break;
			case LenderMasterID.L_AND_T_FINANCE:
				lenderFm = <InitiateLnTLead />;
				break;
			case LenderMasterID.TATA_CAPITAL:
				lenderFm = <InitiateTataLead />;
				break;
			case LenderMasterID.KOTAK_MAHINDRA:
				lenderFm = <InitiateKotakLead />;
				break;
			case LenderMasterID.BAJAJ_FINANCE_GROWTH:
				lenderFm = <InitiateBajajLead />;
				break;
			case LenderMasterID.BAJAJ_FINANCE_FINANCE:
				lenderFm = <InitiateBajajLead />;
				break;
			case LenderMasterID.AADHAR_FINANCE:
				lenderFm = <InitiateAadharLead />;
				break;
			case LenderMasterID.BAJAJ_FINANCE_LTD:
				lenderFm = <InitiateBajajLead />;
				break;
			case LenderMasterID.MONEYVIEW:
				lenderFm = <InitiateMoneyView />;
				break;
			case LenderMasterID.BHANIX_FINANCE:
				lenderFm = <InitiateCasheView />;
				break;
			case LenderMasterID.PUNJAB:
				lenderFm = <InitiatePunjabLead />;
				break;
			case LenderMasterID.PREFR:
				lenderFm = <InitiatePrefrLead />;
				break;
			case LenderMasterID.FIBE_PL:
				lenderFm = <InitiateFibePlForm />;
				break;
			case LenderMasterID.MONEY_TAP:
				lenderFm = <InitiateMoneyTapForm />;
				break;
			case LenderMasterID.DMI_HOUSING:
				lenderFm = <InitiateDMILead />;
				break;
			case LenderMasterID.INDIFI:
				lenderFm = <InitiateIndifiLead />;
				break;

			default:
				lenderFm = <DefaultPage />;
		}
		setLenderForm(lenderFm);
	};
	return <>{lenderForm}</>;
};
