import {
	IonList,
	// IonItem,
	IonModal,
	IonHeader,
	IonToolbar,
	IonContent,
	IonItem,
} from '@ionic/react';
import { CustomSearchbar } from '../custom-searchbar/CustomSearchbar';
import { CustomNoData } from '../custom-no-data/CustomNoData';
import { useRef, useState } from 'react';
import { CustomButton } from '../custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
export default function CustomPopoverSearch({
	isOpen,
	title,
	searchListModalClose,
	setValue,
	searchApi,
	list,
	setList,
	searchbarPlaceholder,
	customNoDataMessage = 'Search by your company name',
}: any) {
	const modal = useRef<HTMLIonModalElement>(null);
	const [searchString, setSearchString] = useState('');
	const { t } = useTranslation();

	const cancelChanges = () => {
		setList([]);
		setSearchString('');
		searchListModalClose(false);
		modal.current?.dismiss();
	};
	modal.current?.onWillDismiss().then(() => cancelChanges());

	return (
		<IonModal
			isOpen={isOpen}
			ref={modal}
			initialBreakpoint={1}
			breakpoints={[0, 0.35, 0.5, 0.75, 1]}
			className='side-modal'
		>
			<IonHeader className='ion-padding ion-no-border'>
				{/* {list.length > 0 && (
					<p className='fw-900 fs-18 pt-2 ps-2 mb-2'>{title}</p>
				)} */}
				<p className='fw-900 fs-18 pt-2 ps-2 mb-2'>{title}</p>
				<IonToolbar>
					<CustomSearchbar
						debounce='500'
						value={searchString}
						searchIcon='/images/icons/searchbar-icon.svg'
						placeholder={searchbarPlaceholder}
						showClearButton='focus'
						onChange={(val: any) => {
							if (val === '' || val.length >= 3) {
								setSearchString(val);
								searchApi(val);
							}
						}}
					/>
				</IonToolbar>
			</IonHeader>

			<IonContent color='light'>
				{searchString === '' ? (
					<CustomNoData message={t(customNoDataMessage)} />
				) : list.length > 0 ? (
					<IonList
						id='modal-list'
						className='ion-no-padding ion-no-margin'
						lines='full'
						style={{ height: 'calc(100vh - 144px)', overflowY: 'auto' }}
					>
						{list.map((item: any, index: any) => (
							<IonItem
								key={index}
								onClick={() => {
									setValue(item.name);
									cancelChanges();
								}}
							>
								{item.name}
							</IonItem>
						))}
					</IonList>
				) : (
					<>
						<CustomNoData message={t('No Data Found')} />
						<div className='text-center'>
							<CustomButton
								type='button'
								className='btn-primary'
								size={'sm'}
								title={t('Set this')}
								onClick={() => {
									setValue(searchString);
									cancelChanges();
								}}
							/>
						</div>
					</>
				)}
			</IonContent>
		</IonModal>
	);
}
