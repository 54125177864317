import React from 'react';
import { useIonRouter } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';

import { Header } from '../../../components/layout/header/Header';
import { LeadsFlowGenerator } from '../../../components/common/custom-leads-flow-generator/LeadsFlowGenerator';
import { lenderSpfDetailsFlow } from '../../../store/reducers/lead/leadSlice';
import { BajajPersonalInfoForm } from './BajajPersonalInfoForm';
import { BLLAPCoApplicantDetails } from './Bajaj_BL_LAP/BajajCoApplicantForm';
import { BLLAPCoApplicantOtherDetails } from './Bajaj_BL_LAP/BajajCoApplicantOtherDtls';
import { BLLAPDocUploadForm } from './Bajaj_BL_LAP/PLDocUploadForm';
// import { BLLAPCoApplicantDetails } from './Bajaj_BL_LAP/BajajCoApplicantForm';

export const InitiateBajajLead: React.FC = () => {
	console.log('inside initiate');

	type ComponentType = React.ReactNode | React.ReactFragment;
	type StepsComponents = {
		step: string;
		component: ComponentType;
	};
	const dispatch = useDispatch();
	const router = useIonRouter();

	const { lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);
	const selectedProduct =
		leadFlowData?.selectedProductData?.product_category_id;
	console.log(lenderSpecificDetails, 'lenderSpfDetails');

	const headerNeedArrowOnClick = async () => {
		dispatch(lenderSpfDetailsFlow({}));
		router.push('/lender-specific-info');
	};
	// @typescript-eslint/no-unused-vars
	const stepsComponents: StepsComponents[] = [
		{
			step: 'step1',
			component: <BajajPersonalInfoForm />,
		},
	];
	const stepsComponentsBLLAP: StepsComponents[] = [
		{
			step: 'step1',
			component: <BLLAPCoApplicantDetails />,
		},
		{
			step: 'step2',
			component: <BLLAPCoApplicantOtherDetails />,
		},
		{
			step: 'step3',
			component: <BLLAPDocUploadForm />,
		},
	];
	console.log(stepsComponentsBLLAP);

	const stepsHeader: ComponentType = (
		<Header
			name={'Bajaj Specific Form'}
			backButton={true}
			needArrow={true}
			needCross={false}
			needArrowOnClick={() => headerNeedArrowOnClick()}
		/>
	);

	return (
		<>
			<LeadsFlowGenerator
				currentStep={lenderSpecificDetails?.step}
				stepsHeader={stepsHeader}
				displayStepsCounter={lenderSpecificDetails?.subHeaderStepsCounter}
				stepsComponents={
					selectedProduct == 2 || selectedProduct == 4
						? stepsComponentsBLLAP
						: stepsComponents
				}
			/>
		</>
	);
};
