//// @ts-nocheck


import { yupResolver } from "@hookform/resolvers/yup";
import {
  IonCard,
  IonCardContent,
  IonContent,
  IonPage
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CustomButton } from "../../components/common/custom-button/CustomButton";
import CustomForm from "../../components/common/custom_form/custom_form.component";
import {
  FamilyDetailRequest,
  FamilyDetailsForm,
} from "../../model/family_details_bean";
import "./family_details.module.scss";
import family_details_schema from "./family_details.schema";
import { FAMILY_DETAIL } from "../../helpers/api-endpoints";
import { showToastAlert } from "../../helpers/functions";
// import {CustomForm} from 'andro-insurance-lib';
import useApi from "../../api/useApi";
import { Header } from "../../components/layout/header/Header";
import ApplicationContext from "../../context/ApplicationContext";
import { useBusyLoading } from "../../context/BusyLoadingContext";
import { REDIRECT } from "../../helpers/constant";
import * as yup from 'yup';




interface Props {
  title: string;
  nextTabUrl?: string;
  inpCallback?: () => void;
  isProposer: boolean;
  familyDetailsForm?: FamilyDetailsForm;
  readonly: boolean;
  // userId: any;
  // setShowBasicInfoForm: any;
  // getUserDetailsInfo: any;
}

const FamilyDetails: React.FC<Props> = ({
  title,
  familyDetailsForm,
  inpCallback,
  isProposer,
  nextTabUrl,
  readonly = false,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [validateForm, setValidateForm] = useState<boolean>();

  const { proposerFamilyDetailsValid, setProposerFamilyDetailsValid,
    assuredFamilyDetailsValid, setAssuredFamilyDetailsValid } = useContext(ApplicationContext);


  // const { familyDetailsValid,setFamilyDetailsValid } = useContext(ApplicationContext);
  const [showSpouseFirstName, setShowSpouseFirstName] = useState<boolean>(false);

  const [showSpouseMiddleName, setShowSpouseMiddleName] = useState<boolean>(false);

  const [showSpouseLastName, setShowSpouseLastName] = useState<boolean>(false);


  const onChangeMaritalStatus = (value: string) => {
    console.log('called');
    setShowSpouseFirstName(value !== 'single');

    setShowSpouseMiddleName(value !== 'single');

    setShowSpouseLastName(value !== 'single');

    setValue('spouseFirstName', '');
    setValue('spouseMiddleName', '');
    setValue('spouseLastName', '');

  }



  var familyDetailsFormKeys = [
    {
      name: "maritalStatus",
      //hbs change here
      type: "text/combo",
      mandatory: true,
      onChange: onChangeMaritalStatus,
      placeholder: "Marital Status *",
      values: [
        { value: "single", description: "Single" },
        { value: "married", description: "Married" },
        { value: "divorced", description: "Divorced" },
        { value: "separated", description: "Separated" },
      ],
    },
    {
      name: "spouseFirstName",
      type: "text",
      placeholder: "Spouse First Name *",
      hidden: !showSpouseFirstName,
    },
    {
      name: "spouseMiddleName",
      type: "text",
      placeholder: "Spouse Middle Name",
      hidden: !showSpouseMiddleName,
    },
    {
      name: "spouseLastName",
      type: "text",
      placeholder: "Spouse Last Name *",
      hidden: !showSpouseLastName,
    },
    {
      name: "fatherFirstName",
      type: "text",
      placeholder: "Father First Name *",
    },
    {
      name: "fatherMiddleName",
      type: "text",
      placeholder: "Father Middle Name",
    },
    {
      name: "fatherLastName",
      type: "text",
      placeholder: "Father Last Name *",
    },
    {
      name: "motherFirstName",
      type: "text",
      placeholder: "Mother First Name *",
    },
    {
      name: "motherMiddleName",
      type: "text",
      placeholder: "Mother Middle Name",
    },
    {
      name: "motherLastName",
      type: "text",
      placeholder: "Mother Last Name *",
    },
  ];


  // const family_details_schema = yup.object({
  //   maritalStatus: yup.string().required('Mandatory field'),
  //   spouseFirstName: (showSpouseFirstName?yup.string().required('Spouse First Name is required'):yup.string().notRequired()),
  //   spouseMiddleName: yup.string(),
  //   spouseLastName: (showSpouseLastName?yup.string().required('Spouse Last Name is required'):yup.string().notRequired()),
  //   fatherFirstName: yup.string().required('Father First Name is required'),
  //   fatherMiddleName: yup.string(),
  //   fatherLastName: yup.string().required('Father Last Name is required'),
  //   motherFirstName: yup.string().required('Mother First Name is required'),
  //   motherMiddleName: yup.string(),
  //   motherLastName: yup.string().required('Mother Last Name is required')
  // });



  const { responseData, loading, executeRequest, error } =
    useApi<any>(FAMILY_DETAIL);

  const { setBusyState } = useBusyLoading();

  useEffect(() => {
    setBusyState(loading);
  }, [loading]);

  useEffect(() => {
    showToastAlert({ type: "error", message: error?.message! });
  }, [error]);

  useEffect(() => {
    if (responseData?.status) {
      showToastAlert({ type: "success", message: responseData?.message });

      if(inpCallback) {
        inpCallback();  
        if(nextTabUrl)
         history.push(nextTabUrl);
      }
    }
  }, [responseData]);

  const {
    control,
    register,
    trigger,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid, isValidating },
  } = useForm({
    resolver: yupResolver(family_details_schema),
    defaultValues: familyDetailsForm,
  });


  const setFormToValid= (inpValid?:boolean)=>{

    if (isProposer) {
      setProposerFamilyDetailsValid(inpValid??isValid);
    }
    else {
      setAssuredFamilyDetailsValid(inpValid??isValid);
    }
  }


  useEffect(() => {
    // console.log("family details use effect called ",familyDetailsValid);

    setFormToValid();
   
  }, [isValid, proposerFamilyDetailsValid, setProposerFamilyDetailsValid,
    assuredFamilyDetailsValid, setAssuredFamilyDetailsValid]);






  const onSubmit = (data: FamilyDetailsForm) => {
    // console.log("Family Details:", data);
    // handle form submission here (e.g., save data)
    // const payload: FamilyDetailRequest = {
    //   marital_status: data.maritalStatus,
    //   spouse_first_name: data.spouseFirstName,
    //   spouse_middle_name: data.spouseMiddleName,
    //   spouse_last_name: data.spouseLastName,
    //   father_first_name: data.fatherFirstName,
    //   father_middle_name: data.fatherMiddleName,
    //   father_last_name: data.fatherLastName,
    //   mother_first_name: data.motherFirstName,
    //   mother_middle_name: data.motherMiddleName,
    //   mother_last_name: data.motherLastName,
    //   id: undefined,
    //   profileId: 0
    // };

    trigger();

    const payload = new FamilyDetailRequest(data);
    setFormToValid(true);

    executeRequest("put", payload);

    // inpCallback();
    // history.push(nextTabUrl);
  };






  return (
    <IonPage className="bg-web-white container-width-md container-spacing-md border-md-radius-top">
      <Header
        className="card-header"
        name={t(title ?? "Family Details")}
        backButton={true}
        needArrow={true}
        needArrowOnClick={() => {
          // history.push(REDIRECT.DASHBOARD);
          window.location.href = REDIRECT.DASHBOARD;
        }}
      />
      
      {/* <section
        className='overflowX-hidden pt-1'
        style={{ height: 'calc(100vh - 20px)' }}
      > */}
      <IonContent className="ion-padding-bottom">
        <div style={{
           minHeight: 'calc(100vh - 70px)', 
           overflowY: 'auto',
           paddingBottom: '70px' 
        }}>
          <form onSubmit={handleSubmit(onSubmit)} className="form-label">
            <IonCard className="ion-card-family">
              <IonCardContent>
                <CustomForm
                  readonly={readonly}
                  formKeys={familyDetailsFormKeys}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  isValid={validateForm}
                  errors={errors}
                  trigger={trigger}
                />
              </IonCardContent>
            </IonCard>
            <div
              className="position-fixed w-100 bottom-0 start-0 px-3 pb-3 bg-white"
              style={{ zIndex:100 }}
            >
              <CustomButton
                className="btn-blue"
                type="submit"
                expand="block"
                title="Update"
                onClick={() => {
                  handleSubmit(onSubmit);
                  setValidateForm(isValid);
                }}
              // disabled={!isValid}
              // title={t('Update')}
              // disabled={!isValid}
              />
            </div>
          </form>
        </div>
        {/* </section> */}
      </IonContent>
    </IonPage>
  );
};
export default FamilyDetails;
