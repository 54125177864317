import React, { useContext, useEffect, useState } from 'react';
import { IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useBusyLoading } from '../../..//context/BusyLoadingContext';
import { UPDATE_STATUS } from '../../..//helpers/api-endpoints';
import useApi from '../../../api/useApi';
import ApplicationContext from '../../../context/ApplicationContext';
import { AuthContext } from '../../../context/AuthContext';
import { INSURANCE_POLICY_PROPOSED_FOR, INSURANCE_STATUS, INSURANCE_TYPE, REDIRECT } from '../../../helpers/constant';
import { showToastAlert } from '../../../helpers/functions';
import { AddressDetailsForm, AddressDetailsRequest } from '../../../model/address_details_bean';
import { BasicDetailsForm, BasicDetailRequest } from '../../../model/basic_details_bean';
import { ExistingPolicyDetailsForm, ExistingPolicyDetailsRequest } from '../../../model/existing_policy_bean';
import { FamilyDetailRequest, FamilyDetailsForm } from '../../../model/family_details_bean';
import { ApplicationBean, StatusUpdateRequest } from '../../../model/insurance_bean';
import { OccupationDetailsForm, OccupationDetailsRequest } from '../../../model/occupation_bean';
import { FloaterFamily } from '../../../pages/floater/family_floater_main';
import InsuranceForm from '../insurance_form';
import { DocumentUploadForm } from '../../../model/document_bean';
import { useBackButtonHandler } from '../../../helpers/redirects';
import { isDraft } from '@reduxjs/toolkit';

interface Props {
  appBean?: ApplicationBean;
}


export const IfaInsuranceForm: React.FC<Props> = ({ appBean }) => {
  const history = useHistory();
  const { applicationBean, setAssuredBasicDetailsValid, setAssuredFamilyDetailsValid, setAssuredAddressDetailsValid, setAssuredPermAddressDetailsValid, setAssuredCurAddressDetailsValid, setAssuredOccupationDetailsValid } = useContext(ApplicationContext);
  const { isIFA } = useContext(AuthContext);

  const [showAssured, setShowAssured] = useState<boolean>((!(appBean?.policyProposedFor !== INSURANCE_POLICY_PROPOSED_FOR.SELF)) || false);
  const [showFamilyFloater, setShowFamilyFloater] = useState<boolean>(appBean?.insuranceTypeId === INSURANCE_TYPE.HEALTH_FAMILY || false);
  const [statusIsDraft, setStatusIsDraft] = useState<boolean>((appBean?.statusId !== INSURANCE_STATUS.DRAFT) || true);

  const [basicDetailsProposer, setBasicDetailsProposer] = useState<BasicDetailRequest | undefined>();
  const [basicDetailsAssured, setBasicDetailsAssured] = useState<BasicDetailRequest | undefined>();

  const [familyDetailsProposer, setFamilyDetailsProposer] = useState<FamilyDetailRequest | undefined>();
  const [familyDetailsAssured, setFamilyDetailsAssured] = useState<FamilyDetailRequest | undefined>();

  const [permAddressDetailsProposer, setPermAddressDetailsProposer] = useState<AddressDetailsRequest | undefined>();
  const [curAddressDetailsProposer, setCurAddressDetailsProposer] = useState<AddressDetailsRequest | undefined>();

  const [permAddressDetailsAssured, setPermAddressDetailsAssured] = useState<AddressDetailsRequest | undefined>();
  const [curAddressDetailsAssured, setCurAddressDetailsAssured] = useState<AddressDetailsRequest | undefined>();

  const [occupationDetailsProposer, setOccupationDetailsProposer] = useState<OccupationDetailsRequest | undefined>();
  const [occupationDetailsAssured, setOccupationDetailsAssured] = useState<OccupationDetailsRequest | undefined>();

  const [existingPolicyProposer, setExistingPolicyProposer] = useState<ExistingPolicyDetailsRequest | undefined>();
  const [existingPolicyAssured, setExistingPolicyAssured] = useState<ExistingPolicyDetailsRequest | undefined>();

  const [applicationDocumentsFormProposer, setApplicationDocumentsFormProposer] = useState<DocumentUploadForm[]>([]);
  const [applicationDocumentsFormAssured, setApplicationDocumentsFormAssured] = useState<DocumentUploadForm[]>([]);

  const [applicationId, setApplicationId] = useState<number>();
  const [proposerProfileId, setProposerProfileId] = useState<number>();
  const [assuredProfileId, setAssuredProfileId] = useState<number>();

  const { responseData, loading, executeRequest, error } = useApi<any>(`${UPDATE_STATUS}`);
  const { setBusyState } = useBusyLoading();

  useEffect(() => {
    setBusyState(loading);
  }, [loading]);

  // useEffect(() => {
  //   const preventDefault = (e) => {
  //     e.preventDefault();
  //     e.stopPropagation();
  //   };

  //   const preventKeyRefresh = (e) => {
  //     if ((e.ctrlKey && e.key === 'r') || e.key === 'F5' || (e.metaKey && e.key === 'r')) {
  //       preventDefault(e);
  //     }
  //   };

  //   window.addEventListener('beforeunload', preventDefault);
  //   window.addEventListener('keydown', preventKeyRefresh);

  //   return () => {
  //     window.removeEventListener('beforeunload', preventDefault);
  //     window.removeEventListener('keydown', preventKeyRefresh);
  //   };
  // }, []);

  // useEffect(() => {
  //   return () => {
  //     window.removeEventListener('beforeunload', (e) => e.preventDefault());
  //     window.removeEventListener('keydown', (e) => {
  //       if ((e.ctrlKey && e.key === 'r') || e.key === 'F5' || (e.metaKey && e.key === 'r')) {
  //         e.preventDefault();
  //       }
  //     });
  //   };
  // }, []);

  useEffect(() => {
    if (!appBean) {
      appBean = applicationBean!;
      const stubShowAssured: boolean = appBean?.policyProposedFor !== INSURANCE_POLICY_PROPOSED_FOR.SELF;
      const stubShowFamilyFloater: boolean = appBean?.insuranceTypeId === INSURANCE_TYPE.HEALTH_FAMILY;

      if (stubShowAssured || stubShowFamilyFloater) {
        if (stubShowAssured) {
          setAssuredBasicDetailsValid(false);
          setAssuredFamilyDetailsValid(false);
          setAssuredAddressDetailsValid(false);
          setAssuredPermAddressDetailsValid(false);
          setAssuredCurAddressDetailsValid(false);
          setAssuredOccupationDetailsValid(false);
        }
        setShowFamilyFloater(stubShowFamilyFloater);
        setShowAssured(stubShowAssured);
      }
      setStatusIsDraft(applicationBean?.statusId === INSURANCE_STATUS.DRAFT ?? false);
    }

    setBasicDetailsProposer(appBean.basicDetailsProposer);
    setFamilyDetailsProposer(appBean.familyDetailsProposer);
    setPermAddressDetailsProposer(appBean.permAddressDetailsProposer);
    setCurAddressDetailsProposer(appBean.curAddressDetailsProposer);
    setOccupationDetailsProposer(appBean.occupationDetailsProposer);
    setExistingPolicyProposer(appBean.existingPolicyProposer);

    if (showAssured) {
      setBasicDetailsAssured(appBean.basicDetailsAssured);
      setFamilyDetailsAssured(appBean.familyDetailsAssured);
      setPermAddressDetailsAssured(appBean.permAddressDetailsAssured);
      setCurAddressDetailsAssured(appBean.curAddressDetailsAssured);
      setOccupationDetailsAssured(appBean.occupationDetailsAssured);
      setExistingPolicyAssured(appBean.existingPolicyAssured);
    }
  }, [appBean, showAssured, showFamilyFloater, basicDetailsProposer, basicDetailsAssured, familyDetailsProposer, familyDetailsAssured, permAddressDetailsProposer, curAddressDetailsProposer, permAddressDetailsAssured, curAddressDetailsAssured, occupationDetailsProposer, occupationDetailsAssured, existingPolicyProposer, existingPolicyAssured, statusIsDraft]);

  const [currentTab, setCurrentTab] = useState<number>(1);

  const handleTabChange = (tabIndex: number) => {
    setCurrentTab(tabIndex);
  };

  const moveToNextTab = () => {
    setCurrentTab((prevTab) => prevTab + 1);
  };

  useBackButtonHandler();

  const finalSubmit = () => {
    let statusUpdateRequest = new StatusUpdateRequest();
    statusUpdateRequest.applicationId = applicationBean?.applicationId;
    statusUpdateRequest.statusId = statusIsDraft ? INSURANCE_STATUS.INSURER_SELECTION : INSURANCE_STATUS.ALLOCATED_TO_DMA;
    const payload = statusUpdateRequest;
    executeRequest("put", payload);
  };

  useEffect(() => {
    showToastAlert({ type: "error", message: error?.message! });
  }, [error]);

  useEffect(() => {
    if (responseData?.status) {
      showToastAlert({ type: "success", message: responseData?.message });
      if (statusIsDraft) {
        history.push('/insurerlist');
      } else {
        history.push("/docuploadMain");
      }
    }
  }, [responseData]);

  if (showAssured || showFamilyFloater) {
    return (
      <IonReactRouter>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-8">
              <IonTabs>
                <IonRouterOutlet onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <Route path="/ifainsurance/tabs/proposer" render={() =>
                    <InsuranceForm isProposer={true} showDocuments={false}
                      applicationId={applicationBean?.applicationId!} profileId={applicationBean?.proposerProfileId!}
                      showexistingPolicyDetails={false}
                      basicDetailsForm={basicDetailsProposer && new BasicDetailsForm(basicDetailsProposer)}
                      familyDetailsForm={familyDetailsProposer && new FamilyDetailsForm(familyDetailsProposer)}
                      permAddressDetailsForm={permAddressDetailsProposer && new AddressDetailsForm(permAddressDetailsProposer)}
                      curAddressDetailsForm={curAddressDetailsProposer && new AddressDetailsForm(curAddressDetailsProposer)}
                      occupationDetailsForm={occupationDetailsProposer && new OccupationDetailsForm(occupationDetailsProposer)}
                      existingPolicyDetailsForm={existingPolicyProposer && new ExistingPolicyDetailsForm(existingPolicyProposer)}
                      readonly={!isIFA}
                      showAssuredTab={true}
                    />} exact={true} />
                  <Route path="/ifainsurance/tabs/assured"
                    render={() => showFamilyFloater ? <FloaterFamily finalSubmit={finalSubmit} /> :
                      <InsuranceForm isProposer={false} showDocuments={false} finalSubmit={finalSubmit}
                        readonly={!isIFA}
                        applicationId={applicationBean?.applicationId!} profileId={applicationBean?.assuredProfileId!}
                        basicDetailsForm={basicDetailsAssured && new BasicDetailsForm(basicDetailsAssured)}
                        familyDetailsForm={familyDetailsAssured && new FamilyDetailsForm(familyDetailsAssured)}
                        permAddressDetailsForm={permAddressDetailsAssured && new AddressDetailsForm(permAddressDetailsAssured)}
                        curAddressDetailsForm={curAddressDetailsAssured && new AddressDetailsForm(curAddressDetailsAssured)}
                        occupationDetailsForm={occupationDetailsAssured && new OccupationDetailsForm(occupationDetailsAssured)}
                        existingPolicyDetailsForm={existingPolicyAssured && new ExistingPolicyDetailsForm(existingPolicyAssured)}
                      />} exact={true} />
                  <Redirect exact path="/ifainsurance" to="/ifainsurance/tabs/proposer" />
                </IonRouterOutlet>
                <IonTabBar style={{ justifyContent: 'flex-center', display: 'flex', overflowX: 'auto', whiteSpace: 'nowrap', paddingLeft: '10px', paddingRight: '10px', flexWrap: 'nowrap', }} slot="top">
                  <IonTabButton className='custom-ion-tab-button' tab="proposer" href="/ifainsurance/tabs/proposer" onClick={() => handleTabChange(1)} selected={currentTab === 1}>
                    <IonLabel>Proposer Details</IonLabel>
                  </IonTabButton>
                  <IonTabButton className='custom-ion-tab-button' tab="assured" href="/ifainsurance/tabs/assured" onClick={() => handleTabChange(2)} selected={currentTab === 2}>
                    <IonLabel>Assured Details</IonLabel>
                  </IonTabButton>
                </IonTabBar>
              </IonTabs>
            </div>
          </div>
        </div>
      </IonReactRouter>
    );
  } else {
    return (
      <>
        <InsuranceForm isProposer={true} showDocuments={false} finalSubmit={finalSubmit}
          readonly={!isIFA}
          basicDetailsForm={basicDetailsProposer && new BasicDetailsForm(basicDetailsProposer)}
          familyDetailsForm={familyDetailsProposer && new FamilyDetailsForm(familyDetailsProposer)}
          permAddressDetailsForm={permAddressDetailsProposer && new AddressDetailsForm(permAddressDetailsProposer)}
          curAddressDetailsForm={curAddressDetailsProposer && new AddressDetailsForm(curAddressDetailsProposer)}
          occupationDetailsForm={occupationDetailsProposer && new OccupationDetailsForm(occupationDetailsProposer)}
          existingPolicyDetailsForm={existingPolicyProposer && new ExistingPolicyDetailsForm(existingPolicyProposer)}
          applicationId={applicationBean?.applicationId!} profileId={applicationBean?.proposerProfileId!} />
      </>
    );
  }
};
