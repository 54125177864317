import React, { useEffect, useRef } from 'react';
import { Header } from '../../components/layout/header/Header';
import { useHistory } from 'react-router';
import { IonContent, IonPage } from '@ionic/react';
import './RaiseComplaint.scss';

export default function RaiseComplaint() {
	const iframeRef: any = useRef(null);
	const history = useHistory();
	useEffect(() => {
		reloadIframe();
	}, [window.location.pathname]);
	const reloadIframe = () => {
		// eslint-disable-next-line no-self-assign
		iframeRef.current.src = iframeRef?.current?.src;
	};

	// useEffect(() => {
	// 	if (location.pathname !== '/raise-a-complaint') {
	// 		return;
	// 	}

	// 	const script = document.createElement('script');
	// 	script.type = 'text/javascript';
	// 	script.nonce = '{place_your_nonce_value_here}';
	// 	script.src =
	// 		'https://desk.zoho.in/portal/api/web/inapp/136447000002214126?orgId=60026304046';
	// 	script.defer = true;
	// 	document.body.appendChild(script);

	// 	return () => {
	// 		const script = document.querySelector(
	// 			`script[src="${'https://desk.zoho.in/portal/api/web/inapp/136447000002214126?orgId=60026304046'}"]`
	// 		);
	// 		if (script) {
	// 			document.body.removeChild(script);
	// 		}
	// 		const launcherBox = document.getElementById(
	// 			'zohohc-asap-web-launcherbox'
	// 		);
	// 		if (launcherBox) {
	// 			launcherBox.style.display = 'none';
	// 		}
	// 	};
	// }, [location.pathname]);

	return (
		<IonPage>
			<Header
				name='Raise a Complaint'
				backButton={true}
				backButtonText=''
				needArrow={true}
				style={{ borderBottom: '1px solid #00000029' }}
				titleClassName='fw-600 text-black'
				needArrowOnClick={() => {
					history.push('/help-desk');
				}}
			/>
			<IonContent
				className='raise-a-complaint'
				scrollY={false}
				scrollX={false}
			>
				<div className='bg-web py-md-4 h-100'>
					<div
						className='border-md-radius-10 py-md-4 container-width-md bg-web-white iframe-wrapper inner-height-frame'
						style={{ overflowY: 'auto' }}
					>
						{/* {termsPara && <iframe src={termsPara} />} */}
						<iframe
							ref={iframeRef}
							className=''
							id='zsfeedbackFrame'
							height='570'
							name='zsfeedbackFrame'
							allowTransparency={false}
							src='https://desk.zoho.in/support/fbw?formType=AdvancedWebForm&fbwId=edbsn51461d87fdc854b9b363be4d77e5c2bb770169b9c5d4ed10e9a83ab28ae4ca98&xnQsjsdp=edbsna22f4b53c7c85448e232fa24c742e787&mode=showNewWidget&displayType=iframe'
						></iframe>
					</div>
				</div>
			</IonContent>
			<div className='py-1'></div>
		</IonPage>
	);
}
