import * as yup from 'yup';
import { LEAD_SCORE_TYPE, REGEX, STATUS_CODE } from '../../../helpers/constant';
import leadStepInfoFormInputs from './LoanLeadStepInfoForm.json';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	IonContent,
	IonFooter,
	IonImg,
	IonRadio,
	IonRadioGroup,
	isPlatform,
} from '@ionic/react';
import { CustomCheckbox } from '../../../components/common/custom-checkbox/CustomCheckbox';
import { useHistory } from 'react-router';
import {
	ContinueLaterPopUp,
	FormatDate,
	InputFormatCurrencyValue,
	capFirstLetter,
	checkFieldsValidToContinue,
	disableNumberArrows,
	eventAttributes,
	fetchStateCityByPincode,
	getUserAge,
	notifyHandler,
	restrictAmountChar,
	showToastAlert,
} from '../../../helpers/functions';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import {
	cbilRequestConsentDetails,
	dashboardLeadGenerateOtp,
	dashboardUpdateLead,
	getLeadUmDetails,
	leadBureauCustomer,
	leadFlow,
} from '../../../store/reducers/lead/leadSlice';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { otpScreenShowPage } from '../../../store/reducers/app/appSlice';
import StateCity from '../../../components/common/StateCity';
import { ConsentCheckbox } from '../../../components/common/consent-checkbox/ConsentCheckbox';
import { generateCibilOtp } from '../../../store/reducers/user/userSlice';
import { NotifyVisitors } from 'capacitor-plugin-notifyvisitors';
import DropDownModal from '../../../components/common/custom-dropdown/DropDownModal';

const validationSchema = yup.object().shape({
	salutation: yup.string().required('Salutation is Required'),
	pan_no: yup
		.string()
		.required('PAN No. is Required.')
		.trim()
		.matches(
			REGEX.PAN,
			"PAN no. should be in alphanumeric format 'ABCDE1234A' only"
		),
	first_name: yup
		.string()
		.trim()
		.max(100, 'First Name should not be more than 100')
		.required('First Name is Required')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
	middle_name: yup
		.string()
		.trim()
		.max(100, 'Middle Name should not be more than 100')
		//.required('Middle Name is Required')
		.notRequired()
		.matches(REGEX.ALLOWONLYCHAR, {
			message: 'Please Enter Valid Input',
			excludeEmptyString: true,
		}),
	last_name: yup
		.string()
		.trim()
		.max(100, 'Last Name should not be more than 100')
		.required('Last Name is Required')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
	dob: yup
		.date()
		.required('DOB is Required.')
		// .min('1969-11-13', 'Date is too early')
		.typeError('Please select valid date')
		.max(new Date(Date.now()), 'Date cannot be in the future')
		.test('dob', 'Age must be 18 or older', function (birthdate) {
			const cutoff = new Date();
			cutoff.setFullYear(cutoff.getFullYear() - 18);
			return birthdate <= cutoff;
		}),
	pincode: yup
		.string()
		.trim()
		.required('Pin Code Number is Required')
		.length(6, 'Pin Code Should Be 6 Digits'),
	state: yup.string().trim().required('State is Required'),
	city: yup.string().trim().required('City is Required'),
	email: yup
		.string()
		.required('Email is Required')
		.matches(REGEX.EMAIL, 'Please Enter Valid Email'),
	loan: yup
		.number()
		.min(1, 'Loan Amount should not be zero')
		.typeError('Loan Amount should be number')
		.required('Loan Amount is Required.'),
	acceptTerms: yup.boolean().oneOf([true], 'You need to check the consent'),
	gender: yup.string().required('Please select Gender'),
});
const LoanLeadStepInfoForm: React.FC<any> = () => {
	const { t } = useTranslation();
	const [pinCodeData, setPinCodeData] = useState<any>([]);
	const history = useHistory();
	const [modalPageClose, setModalPageClose] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [lead, setLead] = useState();
	const [isMoreContent, setMoreContent] = useState(false);
	const dispatch = useDispatch();
	const dmaSelector = useSelector((state: any) => state?.users);
	const leadData = useSelector((state: any) => state?.lead?.leadFlowData);
	let continueLaterFlag = false;
	const userData = useSelector((state: any) => state?.users?.user_details);

	// Notify Visitors
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const userName = useSelector(
		(state: any) => state?.users?.homepage?.profile?.name
	);
	const userMobile = useSelector(
		(state: any) => state?.users?.user_details?.mobile
	);
	const entityType = useSelector(
		(state: any) => state?.users?.user_details?.entityType
	);

	const userLang = useSelector(
		(state: any) => state?.oneTimeScreen?.language?.name
	);

	const { leadcreatedthrough } = useSelector((state: any) => state?.lead);

	const {
		handleSubmit,
		control,
		setValue,
		reset,
		getValues,
		trigger,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			salutation: '',
			pan_no: '',
			first_name: '',
			middle_name: '',
			last_name: '',
			pincode: '',
			state: '',
			city: '',
			dob: '',
			age: 0,
			email: '',
			loan: '',
			acceptTerms: false,
			gender: '',
		},
	});

	useEffect(() => {
		if (location.pathname !== '/loan-lead-info-steps') {
			return;
		}
		reset({
			salutation: '',
			pan_no: '',
			first_name: '',
			middle_name: '',
			last_name: '',
			pincode: '',
			state: '',
			city: '',
			dob: '',
			age: 0,
			email: '',
			loan: '',
			acceptTerms: false,
			gender: '',
		});
		setPinCodeData([]);
		getLeads();
	}, [location?.pathname]);

	const userAttributes = {
		userId: userId,
		attributes: {
			name: userName,
			id: userId,
			mobile: userMobile,
			user_language: userLang,
			entityType:
				entityType === 'Individual'
					? 'DMA'
					: entityType === 'BTM'
					? 'employee'
					: entityType,
		},
	};
	useEffect(() => {
		if (isPlatform('android') || isPlatform('ios')) {
			notifyHandler('event', eventAttributes('lead_basic_info_page'));
			notifyHandler('user', userAttributes.attributes);
			const inAppData = {
				tokens: { name: userAttributes },
				customRules: { banerrType: 'lead basic info page' },
			};
			NotifyVisitors.show(inAppData);
		} else {
			(window as any).nv('event', 'lead_basic_info_page', 10, 1);
			(window as any).nv('user', userId, userAttributes.attributes);
		}
	}, []);

	const getLeads = async () => {
		dispatch(getLeadUmDetails({ param: leadData?.leadId }))
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setLead(response?.data);

					if (response?.data?.pincode) {
						reset({
							salutation: response?.data?.salutation,
							pan_no: response?.data?.doc_no || response?.data?.pan,
							first_name: response?.data?.first_name,
							middle_name: response?.data?.middle_name,
							last_name: response?.data?.last_name,
							pincode: response?.data?.pincode,
							dob: response?.data?.dob,
							email: response?.data?.email,
							loan: response?.data?.loan_amount,
							acceptTerms: response?.data?.is_consent,
							state: response?.data?.state,
							city: response?.data?.city,
							gender: response?.data?.gender,
						});
						if (response?.data?.city != '' && response?.data?.state != '') {
							setPinCodeData([
								{
									Region: response?.data?.city,
									State: response?.data?.state,
								},
							]);
						}
					} else {
						reset({
							salutation: response?.data?.salutation,
							pan_no: response?.data?.doc_no || response?.data?.pan,
							first_name: response?.data?.first_name,
							middle_name: response?.data?.middle_name,
							last_name: response?.data?.last_name,
							pincode: response?.data?.pincode,
							dob: response?.data?.dob,
							email: response?.data?.email,
							loan: response?.data?.loan_amount,
							acceptTerms: response?.data?.is_consent,
							gender: response?.data?.gender,
						});
					}
				}
			});
	};
	const onSubmit = async (data: any) => {
		await dispatch(
			dashboardUpdateLead({
				param: leadData?.leadId,
				data: {
					...data,
					loginUserId: userData?.id,
					product: leadData?.selectedProductData?.selectedProductName,
					salutation: data?.salutation,
					doc_no: data?.pan_no?.toUpperCase(),
					pan: data?.pan_no?.toUpperCase(),
					first_name: data?.first_name ? capFirstLetter(data?.first_name) : '',
					middle_name: data?.middle_name
						? capFirstLetter(data?.middle_name)
						: '',
					last_name: data?.last_name ? capFirstLetter(data?.last_name) : '',
					pincode: data?.pincode,
					dob: FormatDate(data?.dob, 'mm/dd/yyyy'),
					age: getUserAge(data?.dob ? data?.dob : 0),
					email: data?.email,
					loan_amount: data?.loan,
					is_consent: getValues('acceptTerms'),
					gender: data?.gender,
					continue_later: continueLaterFlag,
					lead_generated_through:
						leadcreatedthrough?.leadCreationtype != undefined &&
						leadcreatedthrough?.leadCreationtype == 3
							? String(leadcreatedthrough?.leadCreationtype)
							: '4',
				},
			})
		)
			.then(unwrapResult)
			.then(async (response: any) => {
				if (response?.code === STATUS_CODE.SUCCESS) {
					if (!continueLaterFlag) {
						// CHECK SCORE TYPE (1/2/3)
						if (
							leadData?.scoreType?.toLocaleLowerCase() ===
							LEAD_SCORE_TYPE.CBIL.toLocaleLowerCase()
						) {
							dispatch(
								cbilRequestConsentDetails({
									param: leadData?.leadId,
								})
							)
								.then(unwrapResult)
								.then(async (response: any) => {
									if (response.status) {
										if (response?.is_consent) {
											if (response?.is_otp) {
												history.replace('/bureau-report');
											} else {
												await dispatch(
													generateCibilOtp({
														data: {
															lead_id: leadData?.leadId,
															mobile_no: leadData?.leadMobile,
														},
													})
												)
													.then(unwrapResult)
													.then(async (cbilOtpResponse: any) => {
														if (cbilOtpResponse.status) {
															dispatch(
																leadFlow({
																	...leadData,
																	cbilResponse: {
																		ChallengeConfigGUID:
																			cbilOtpResponse?.ChallengeConfigGUID,
																		answerKey: cbilOtpResponse?.answerKey,
																		questionKey: cbilOtpResponse?.questionKey,
																	},
																	leadEmail: data?.email,
																	lead_consent_type: 'Lead',
																	step: 'step6',
																	headerTitle: '',
																	subHeaderStepsCounter: false,
																	needCibil: true,
																	needCross: false,
																	needArrow: true,
																	reSendCibilOtp: false,
																})
															);
														}
													});
											}
										} else {
											dispatch(
												leadBureauCustomer({
													lead_type: 'Lead',
													reSendCibilOtp: false,
													leadEmail: data?.email,
												})
											);
											dispatch(
												leadFlow({
													...leadData,
													lead_consent_type: 'Lead',
													leadEmail: data?.email,
													pccId: response?.pccId,
													step: 'step5',
													headerTitle: '',
													subHeaderStepsCounter: false,
													needCibil: true,
													needCross: true,
													needArrow: false,
													isNewLead: true,
													reSendCibilOtp: false,
												})
											);
										}
									}
								});
						} else if (
							leadData?.scoreType?.toLocaleLowerCase() ===
							LEAD_SCORE_TYPE.EQUIFAX.toLocaleLowerCase()
						) {
							await dispatch(
								dashboardLeadGenerateOtp({
									data: { mobile: leadData?.leadMobile, reLogin: false },
								})
							)
								.then(unwrapResult)
								.then(async (response: any) => {
									if (response?.code === STATUS_CODE.SIGNIN_SUCCESS) {
										dispatch(otpScreenShowPage(false));
										dispatch(
											leadFlow({
												...leadData,
												step: 'step3',
												headerTitle: 'OTP Verification',
												leadEmail: data?.email,
												pan: data?.pan_no,
												isNewLead: true,
												reSendCibilOtp: false,
											})
										);
									}
								});
						} else {
							await dispatch(
								dashboardLeadGenerateOtp({
									data: { mobile: leadData?.leadMobile, reLogin: false },
								})
							)
								.then(unwrapResult)
								.then(async (response: any) => {
									if (response?.code === STATUS_CODE.SIGNIN_SUCCESS) {
										dispatch(otpScreenShowPage(false));
										dispatch(
											leadFlow({
												...leadData,
												step: 'step3',
												headerTitle: 'OTP Verification',
												pan: data?.pan_no,
												leadEmail: data?.email,
												isNewLead: true,
												reSendCibilOtp: false,
											})
										);
									}
								});
						}
					} else {
						continueLaterFlag = false;
					}
				} else {
					showToastAlert({ type: 'error', message: response?.message });
				}
			});
	};

	const genderApi = [
		{
			id: 'Male',
			value: 'Male',
		},
		{
			id: 'Female',
			value: 'Female',
		},
		{
			id: 'Transgender',
			value: 'Transgender',
		},
	];

	return (
		<>
			<IonContent>
				<div className='select-product small-container bg-web'>
					<div className='bg-web-white container-width-md conatiner-spacing-md border-md-radius-top'>
						<div className=''>
							<form onSubmit={handleSubmit(onSubmit)}>
								{leadStepInfoFormInputs.map(
									({ name, type, placeholder }: any, index: any) => {
										const errorText: any =
											errors && Object.keys(errors).length > 0 && errors;

										return type === 'dropdownSelect' ? (
											<div style={{ paddingTop: '5px' }}>
												<Controller
													control={control}
													render={({ field: { onChange, onBlur, name } }) => (
														<DropDownModal
															label={`${t(placeholder)} *`}
															data={name === 'gender' && genderApi}
															value={getValues(name)}
															name={`${t(name)} *`}
															onChange={onChange}
															onBlur={onBlur}
															errors={t(errorText[name]?.message)}
														/>
													)}
													name={name}
												/>
												{errorText[name] && (
													<span
														style={{
															color: '#eb445a',
															fontSize: '10px',
															textTransform: 'initial',
														}}
													>
														{t(errorText[name]?.message)}
													</span>
												)}
											</div>
										) : type === 'radio' ? (
											<div
												key={index}
												className='d-flex align-items-center gap-1 mb-3'
											>
												<div>
													<h6 className='mt-3'>{t(placeholder)}</h6>
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<>
																<IonRadioGroup
																	onIonChange={onChange}
																	onBlur={onBlur}
																	name={name}
																	value={getValues(name)}
																>
																	<IonRadio
																		className='me-4'
																		labelPlacement='end'
																		value='Mr'
																	>
																		{t('Mr') + '.'}
																	</IonRadio>
																	<IonRadio
																		className='me-4'
																		labelPlacement='end'
																		value='Ms'
																	>
																		{t('Ms') + '.'}
																	</IonRadio>
																	<IonRadio
																		className='me-4'
																		labelPlacement='end'
																		value='Mrs'
																	>
																		{t('Mrs') + '.'}
																	</IonRadio>
																	<IonRadio
																		labelPlacement='end'
																		value='Dr'
																	>
																		{t('Dr') + '.'}
																	</IonRadio>
																</IonRadioGroup>
																<div className='error-text px-3 sc-ion-input-md'>
																	{t(errorText[name]?.message)}
																</div>
															</>
														)}
														name={name}
													/>
												</div>
											</div>
										) : (
											<Controller
												key={`leadStepInfoFormInputs-${index}`}
												control={control}
												render={({ field: { onChange, onBlur, name } }) => (
													<CustomInput
														type={type}
														label={t(placeholder)}
														onBlur={onBlur}
														id={name}
														needSuffixIcon={name === 'dob'}
														suffixClassName={
															'position-absolute date-of-birth-sufix'
														}
														suffixText={
															<IonImg
																src='/images/icons/input-dob-calendar-icon.svg'
																alt='dob'
															/>
														}
														onChange={(e: any) => {
															if (name === 'pincode') {
																if (e.detail.value.length === 6) {
																	fetchStateCityByPincode(
																		setValue,
																		'city',
																		'state',
																		setPinCodeData,
																		dispatch,
																		e.detail.value,
																		trigger
																	);
																} else {
																	setValue('state', '');
																	setValue('city', '');
																	setPinCodeData([]);
																}
															} else if (name === 'loan') {
																e = isNaN(e.detail.value.replaceAll(',', ''))
																	? e
																	: Number(e.detail.value.replace(/,/g, ''));
															}
															onChange(e);
														}}
														onBeforeInput={(evt: any) => {
															if (type == 'number') {
																!/[0-9]/.test(evt.data) && evt.preventDefault();
															} else if (name === 'loan' && type == 'text') {
																restrictAmountChar(
																	evt,
																	getValues(name)?.toString()
																);
															}
														}}
														value={
															name == 'loan'
																? getValues(name)
																	? InputFormatCurrencyValue(getValues(name))
																	: getValues(name)
																: getValues(name)
														}
														disabled={name === 'age' ? true : false}
														outerClassName={`${
															name === 'dob' ? 'position-relative' : ''
														}`}
														className={`${
															name === 'pan_no' ? 'text-uppercase pt-2' : ''
														}${
															[
																'first_name',
																'middle_name',
																'last_name',
															].includes(name)
																? ' text-capitalize '
																: ''
														}`}
														onKeyDown={(e: any) => {
															if (type == 'number') {
																disableNumberArrows(e);
															}
														}}
														onWheel={(e: any) => {
															if (type == 'number') {
																e.target.blur();
															}
														}}
														errors={t(errorText[name]?.message || '')}
													/>
												)}
												name={name}
											/>
										);
									}
								)}
								{pinCodeData.length > 0 && (
									<StateCity
										cityInputName='city'
										stateInputName='state'
										control={control}
										setValue={setValue}
										pinCodeData={pinCodeData}
										trigger={trigger}
										getValues={getValues}
									/>
								)}
							</form>
						</div>
					</div>
					<div className='bg-web-white container-width-md'>
						<div
							style={{
								backgroundColor: '#fff',
								zIndex: 2,
							}}
							className='position-relative mb-3 mb-mb-0'
						>
							<Controller
								control={control}
								render={({ field: { onChange, onBlur, name } }) => (
									// <CustomCheckbox
									// 	name={name}
									// 	isBackGround={true}
									// 	labelPlacement='end'
									// 	isChecked={getValues('acceptTerms')}
									// 	value={getValues('acceptTerms')}
									// 	onBlur={onBlur}
									// 	onChange={(event: any) => {
									// setValue('acceptTerms', event.detail.checked);
									// setTimeout(() => {
									// 	onChange(event);
									// }, 500);
									// 	}}
									// 	className={`checkbox-input ion-padding ${
									// 		errors[name] ? 'ion-checkbox-inValid' : ''
									// 	}`}
									// 	errorText={errors[name]?.message}
									// 	errorTextClass='position-absolute'
									// >
									// 	<IonLabel className='text-wrap ms-3 d-flex'>
									// 		{t(
									// 			`I agree to the Privacy Policy and Terms of use
									// 							and hereby appoint the OneAndro as my authorised
									// 							representative to receive my credit information
									// 							from Equifax`
									// 		)}
									// 	</IonLabel>
									// </CustomCheckbox>

									<div
										className='d-flex py-3'
										style={{ background: '#EDF8EC' }}
									>
										<div className='position-relative'>
											<CustomCheckbox
												isBackGround={false}
												name={name}
												labelPlacement='end'
												isChecked={getValues('acceptTerms')}
												value={getValues('acceptTerms')}
												onChange={(event: any) => {
													setValue('acceptTerms', event.detail.checked);
													setTimeout(() => {
														onChange(event);
													}, 500);
												}}
												onBlur={onBlur}
												className={
													(errors[name] ? 'ion-checkbox-inValid' : '') +
													' pt-1 px-3'
												}
												errorText={t(errors[name]?.message as string)}
												errorTextClass='position-absolute checkbox-style1'
											/>
										</div>
										<ConsentCheckbox isMoreContent={isMoreContent} />
									</div>
								)}
								name={'acceptTerms'}
							/>
							<div
								onClick={() => {
									setMoreContent(!isMoreContent);
								}}
								className='position-absolute right-0 ion-padding py-1 text-primary fs-12 cursor-pointer fw-600'
							>
								{isMoreContent ? `- ${t('Less')}` : `+ ${t('More')}`}
							</div>
							<div
								className={` d-none d-md-block conatiner-spacing-md py-md-3`}
							>
								{leadData?.from !== 'customer' ? (
									<CustomButton
										className='w-100 fw-bold dashboard-add-lead-button mb-3'
										expand='block'
										fill='outline'
										title={t('Continue Later')}
										onClick={() => {
											if (checkFieldsValidToContinue(errors)) {
												setModalPageClose(true);
											} else {
												showToastAlert({
													type: 'error',
													message: 'Please fix above errors',
												});
											}
										}}
									/>
								) : (
									''
								)}
								<div className='proceed-btn'>
									<CustomButton
										type='submit'
										className='w-100 fw-bold dashboard-add-lead-button'
										expand='block'
										title={t('Proceed')}
										disabled={!isValid}
										onClick={handleSubmit(onSubmit)}
									/>
								</div>
							</div>
						</div>
						{/* </IonPage> */}

						{/* continue later popup */}
						<CustomModal
							needIonContentWraper={false}
							isOpen={modalPageClose}
							initialBreakpoint={undefined}
							handleClose={() => setModalPageClose(false)}
							breakpoints={[]}
							className='height-auto confirm-popup-middle continue-later'
							modalContent={ContinueLaterPopUp(
								() => {
									setModalPageClose(false);
								},
								() => {
									const myPromise = new Promise(function (myResolve) {
										setTimeout(function () {
											myResolve(
												history.push(
													leadData?.from === 'nimbuss'
														? `/lead-details/${leadData?.leadId}/${dmaSelector?.user_details?.access_token}`
														: '/dashboard/lead'
												)
											);
											continueLaterFlag = true;
											onSubmit(getValues());
											setModalPageClose(false);
										}, 10);
									});
									myPromise.then(() => {
										if (!continueLaterFlag) {
											dispatch(
												leadFlow({
													...leadData,
													step: 'step1',
												})
											);
										}
									});
								},
								'Cancel',
								'Continue Later',
								'className',
								'Are You Sure You Want to Exit?',
								`${t('You can continue later from where you left off.')}`
							)}
							needCross={false}
							needArrow={false}
							// modalTitle=''
						/>
					</div>
				</div>
			</IonContent>
			<IonFooter className='d-block d-md-none position-relative'>
				<div
					style={{
						backgroundColor: '#fff',
						zIndex: 2,
					}}
				>
					<div className={`ion-padding`}>
						{leadData?.from !== 'customer' ? (
							<CustomButton
								className='w-100 fw-bold dashboard-add-lead-button mb-3'
								expand='block'
								fill='outline'
								title={t('Continue Later')}
								onClick={() => {
									if (checkFieldsValidToContinue(errors)) {
										setModalPageClose(true);
									} else {
										showToastAlert({
											type: 'error',
											message: 'Please fix above errors',
										});
									}
								}}
							/>
						) : (
							''
						)}
						<div className='proceed-btn'>
							<CustomButton
								type='submit'
								className='w-100 fw-bold dashboard-add-lead-button'
								expand='block'
								title={t('Proceed')}
								disabled={!isValid}
								onClick={handleSubmit(onSubmit)}
							/>
						</div>
					</div>
				</div>
			</IonFooter>
		</>
	);
};

export default LoanLeadStepInfoForm;
