/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '../../components/layout/header/Header';

import './Products.scss';
import { IonContent, IonPage } from '@ionic/react';
import { useHistory, useLocation } from 'react-router';
import {
	getLenderProducts,
	productFlow,
} from '../../store/reducers/lead/leadSlice';
import { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { CustomNoData } from '../../components/common/custom-no-data/CustomNoData';
import { getIcon } from '../../helpers/functions';
import { useTranslation } from 'react-i18next';
import { USER_ROLES } from '../../helpers/constant';

export const LenderLoanList: React.FC = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const role = useSelector((state: any) => state?.users?.user_details);
	const productFlowSelector = useSelector(
		(state: any) => state?.lead?.productFlowData
	);
	const location = useLocation();
	const [products, setProducts] = useState([]);
	useEffect(() => {
		if (location.pathname !== '/lender-loan-types') {
			return;
		}
		dispatch(getLenderProducts({ param: productFlowSelector?.lenderId }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setProducts(result?.data);
				}
			});
	}, [location.pathname]);

	return (
		<IonPage className='lead-list'>
			<Header
				name={productFlowSelector.bankTitle}
				type=''
				needArrow={true}
				backButton={true}
				needArrowOnClick={() => {
					dispatch(
						productFlow({
							...productFlowSelector,
							subProductId: null,
							lenderId: null,
						})
					);
					history.push('/all-lenders');
				}}
			/>
			<IonContent
				style={{
					borderTop: '1px solid #00000029',
					height: 'calc(100vh - 113px)',
					overflowX: 'hidden',
				}}
				className=''
			>
				<div className='bg-web loan-card-container-spacing ion-padding h-100'>
					<div className=' bg-white h-100  rounded-3 ion-padding'>
						<div
							className={`row  ${
								!products?.length
									? 'd-flex justify-content-center h-100 align-items-center'
									: ''
							}`}
						>
							{products?.length ? (
								<>
									{products?.map((item: any) => {
										return (
											<div className='col-12 col-sm-6 col-lg-4 px-0 px-sm-3'>
												<div
													key={item?.category_id}
													className='loan-card mb-3 cursor-pointer'
												>
													<div className='d-flex justify-content-start ps-3 align-items-center gap-3 pt-2'>
														<div className='product-icon'>
															<img
																src={
																	item?.icon.startsWith('https')
																		? item?.icon
																		: `images/icons/products/${item?.icon}.svg`
																}
																alt=''
																className='img-holder'
															/>
														</div>
														<div>
															<h5 className='fs-16 fw-600'>
																{item?.category_name}
															</h5>
															<h4 className='fs-14 fw-600'>
																{item?.lender_description}
															</h4>
														</div>
													</div>
													<hr style={{ background: '#DFDFDF', opacity: 1 }} />
													<div
														className={`d-flex h-100 mt-4 justify-content-between flex-wrap ${
															[USER_ROLES.DMA, USER_ROLES.DMAUL].includes(
																role?.roleType?.[0]
															)
																? 'mb-3'
																: ''
														}`}
													>
														{item?.content?.map((item: any) => {
															return (
																<div
																	key={item?.id}
																	className='d-flex  align-items-center mb-2 gap-1 col-6'
																>
																	{item?.icon_url ? (
																		<img
																			src={getIcon(item?.icon_url)}
																			alt=''
																			width={40}
																			height={20}
																		/>
																	) : (
																		''
																	)}

																	<div
																		className={!item?.icon_url ? 'ps-3' : ''}
																	>
																		<p
																			className='mb-0 fs-12'
																			style={{ color: '#5D5E72' }}
																		>
																			{item?.label}
																		</p>
																		<h4 className='fs-14 mb-0 fw-600'>
																			{item?.content}
																		</h4>
																	</div>
																</div>
															);
														})}
													</div>
													{![USER_ROLES.DMA, USER_ROLES.DMAUL].includes(
														role?.roleType?.[0]
													) ? (
														<div
															onClick={() => {
																dispatch(
																	productFlow({
																		...productFlowSelector,
																		loanTitle: item?.category_name,
																		subProductId: item?.category_id,
																	})
																);
																history.push('/lender-loan-consultants');
															}}
															className='col-12 mt-3 d-flex gap-2 mb-3 align-items-center ps-3'
														>
															<img
																src='/images/icons/search.svg'
																alt=''
															/>
															<p
																className='fs-16 mb-0 fw-600'
																style={{ color: '#0165E1' }}
															>
																{t('Find Consultants')}
															</p>
														</div>
													) : (
														''
													)}
												</div>
											</div>
										);
									})}
								</>
							) : (
								<CustomNoData className='pt-0 mt-0' />
							)}
						</div>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};
