import {
	IonPage,
	IonContent,
	IonGrid,
	IonRow,
	IonCol,
	IonButtons,
	IonImg,
	IonItem,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { Header } from '../../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { StatusNum } from '../../../helpers/constant';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { PageExitPopUp } from '../../../helpers/functions';
import { CustomSearchbar } from '../../../components/common/custom-searchbar/CustomSearchbar';
import { InitialLetter } from '../../../components/common/initial-letter/InitialLetter';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	copsManagerAssignList,
	userAllocateManager,
	dmaFlowData,
} from '../../../store/reducers/user/userSlice';
import { CustomNoData } from '../../../components/common/custom-no-data/CustomNoData';
import { leadFlow } from '../../../store/reducers/lead/leadSlice';

export const DmaVerifySteps: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const [showAssignModal, setShowAssignModal] = useState<boolean>(false);
	const dmaSelector = useSelector((state: any) => state?.users);
	const leadFlowSelector = useSelector((state: any) => state?.lead);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [title, setTitle] = useState('');

	const dispatch = useDispatch();

	useEffect(() => {
		const ch = dmaSelector?.dmaFlow?.dmaCode;
		let _title = '';
		switch (ch) {
			case StatusNum.VERIFICATION:
				_title = t('DMA Verification');
				break;
			case StatusNum.REWORKCOMPLETED:
				_title = 'Rework Completed';
				break;
			case StatusNum.UNDERPROCESS:
				_title = 'Under Process';
				break;
			case StatusNum.APPROVED:
				_title = 'Approved';
				break;
			default:
				break;
		}
		setTitle(_title);
	}, [dmaSelector?.dmaFlow?.dmaCode]);
	// console.log(title);

	const handleBackBtn = () => {
		const myPromise = new Promise(function (myResolve) {
			setTimeout(function () {
				myResolve(history.replace('/team-list'));
			}, 10);
		});
		myPromise.then(() => {
			dispatch(
				dmaFlowData({
					...dmaSelector?.dmaFlow,
					update: dmaSelector?.dmaFlow?.update + 1 || 1,
				})
			);
		});
	};
	return (
		<IonPage className='bg-web'>
			<Header
				needArrow={true}
				backButton={true}
				name={t('')}
				needArrowOnClick={() => handleBackBtn()}
				endItem={
					<IonButtons slot='end'>
						<IonButtons className='cursor-pointer'>
							<IonImg
								className=''
								src='/images/icons/assign-icon.svg'
								alt='assign'
								onClick={() => setShowAssignModal(true)}
							/>
						</IonButtons>
					</IonButtons>
				}
			/>
			<IonContent className='bg-web background-web'>
				<div className='bg-web'>
					<div className='conainer-margin-web bg-web-white border-md-radius-10 opacity-1'>
						<IonGrid>
							<IonRow>
								<IonCol
									className='p-2'
									size='12'
								>
									<div
										className={`cursor-pointer team-box m-0 d-flex align-items-center justify-content-between ${
											dmaSelector?.dmaFlow?.dmaStatus ===
												StatusNum.VERIFICATION1 ||
											dmaSelector?.dmaFlow?.dmaStatus ===
												StatusNum.VERIFICATION2 ||
											dmaSelector?.dmaFlow?.dmaStatus ===
												StatusNum.REWORKVERIFICATION1 ||
											dmaSelector?.dmaFlow?.dmaStatus ===
												StatusNum.REWORKVERIFICATION2
												? ' card-gradient-top-left-green'
												: 'card-gradient-top-left'
										}`}
										onClick={() => {
											dispatch(
												leadFlow({
													...leadFlowSelector?.leadFlowData,
													productFor: '',
													productType: '',
												})
											);
											dmaSelector?.dmaFlow?.dmaStatus ===
												StatusNum.VERIFICATION1 ||
											dmaSelector?.dmaFlow?.dmaStatus ===
												StatusNum.VERIFICATION2 ||
											dmaSelector?.dmaFlow?.dmaStatus ===
												StatusNum.REWORKVERIFICATION1 ||
											dmaSelector?.dmaFlow?.dmaStatus ===
												StatusNum.REWORKVERIFICATION2
												? ''
												: history.push('/dma-verification-status');
										}}
									>
										<div className='d-flex'>
											<img
												src={
													dmaSelector?.dmaFlow?.dmaStatus ===
														StatusNum.VERIFICATION1 ||
													dmaSelector?.dmaFlow?.dmaStatus ===
														StatusNum.VERIFICATION2 ||
													dmaSelector?.dmaFlow?.dmaStatus ===
														StatusNum.REWORKVERIFICATION1 ||
													dmaSelector?.dmaFlow?.dmaStatus ===
														StatusNum.REWORKVERIFICATION2
														? '/images/icons/teams-dma-verification-green-icon.svg'
														: '/images/icons/teams-dma-verification-icon.svg'
												}
												alt='User Verify'
											/>
											<div className='ms-2 fs-16 fw-600'>
												{t('User Verification')}
											</div>
										</div>
										{dmaSelector?.dmaFlow?.dmaStatus ===
											StatusNum.VERIFICATION1 ||
										dmaSelector?.dmaFlow?.dmaStatus ===
											StatusNum.VERIFICATION2 ||
										dmaSelector?.dmaFlow?.dmaStatus ===
											StatusNum.REWORKVERIFICATION1 ||
										dmaSelector?.dmaFlow?.dmaStatus ===
											StatusNum.REWORKVERIFICATION2 ? (
											<div>
												<span className='status-chip green'>
													{t('Completed')}
												</span>
											</div>
										) : (
											''
										)}
									</div>
									<div className='d-flex justify-content-center'>
										<div
											style={{
												width: '1px',
												height: '44px',
												borderRight: '2px dashed #5D5E72',
											}}
										></div>
									</div>
									<div
										className={`cursor-pointer team-box m-0 d-flex align-items-center justify-content-between ${
											dmaSelector?.dmaFlow?.dmaStatus ===
												StatusNum.VERIFICATION1 ||
											dmaSelector?.dmaFlow?.dmaStatus ===
												StatusNum.VERIFICATION2 ||
											dmaSelector?.dmaFlow?.dmaStatus ===
												StatusNum.REWORKVERIFICATION1 ||
											dmaSelector?.dmaFlow?.dmaStatus ===
												StatusNum.REWORKVERIFICATION2
												? ' card-gradient-top-left'
												: ''
										}`}
										onClick={() => {
											dispatch(
												leadFlow({
													...leadFlowSelector?.leadFlowData,
													productFor: '',
													productType: '',
												})
											);
											dmaSelector?.dmaFlow?.dmaStatus ===
												StatusNum.VERIFICATION1 ||
											dmaSelector?.dmaFlow?.dmaStatus ===
												StatusNum.VERIFICATION2 ||
											dmaSelector?.dmaFlow?.dmaStatus ===
												StatusNum.REWORKVERIFICATION1 ||
											dmaSelector?.dmaFlow?.dmaStatus ===
												StatusNum.REWORKVERIFICATION2
												? history.push(
														dmaSelector?.dmaFlow?.dmaStatus ===
															StatusNum.VERIFICATION2
															? '/efranking-send'
															: '/efranking-initiate'
												  )
												: '';
										}}
									>
										<div className='d-flex'>
											<img
												src={
													dmaSelector?.dmaFlow?.dmaStatus ===
														StatusNum.VERIFICATION1 ||
													dmaSelector?.dmaFlow?.dmaStatus ===
														StatusNum.VERIFICATION2 ||
													dmaSelector?.dmaFlow?.dmaStatus ===
														StatusNum.REWORKVERIFICATION1 ||
													dmaSelector?.dmaFlow?.dmaStatus ===
														StatusNum.REWORKVERIFICATION2
														? '/images/icons/e-franking-icon.svg'
														: '/images/icons/e-franking-disabled-icon.svg'
												}
												alt='e franking'
											/>
											<div
												className={`ms-2 fs-16 fw-600 ${
													dmaSelector?.dmaFlow?.dmaStatus ===
														StatusNum.VERIFICATION1 ||
													dmaSelector?.dmaFlow?.dmaStatus ===
														StatusNum.VERIFICATION2 ||
													dmaSelector?.dmaFlow?.dmaStatus ===
														StatusNum.REWORKVERIFICATION1 ||
													dmaSelector?.dmaFlow?.dmaStatus ===
														StatusNum.REWORKVERIFICATION2
														? ''
														: 'text-grey'
												}`}
											>
												{t('E-franking')}
											</div>
										</div>
									</div>
								</IonCol>
							</IonRow>
						</IonGrid>
					</div>
				</div>
			</IonContent>
			<CustomModal
				id='customModalRework'
				isOpen={showAssignModal}
				modalTitle={t('Select Team Member')}
				initialBreakpoint={1}
				handleClose={() => setShowAssignModal(false)}
				breakpoints={[0, 1]}
				className='assignModal side-modal'
				modalContent={
					<TeamMemberList
						{...{ userId: dmaSelector?.dmaFlow?.dmaId, setShowAssignModal }}
					/>
				}
				scrollY={true}
				needCross={true}
				needArrow={false}
			></CustomModal>

			{/*<CustomModal
				id='cutomdropdown'
				isOpen={open}
				initialBreakpoint={1}
				handleClose={() => setOpen(false)}
				breakpoints={[0, 0.35, 0.5, 0.75, 1]}
				className='w-100'
				modalContent={
					<DropDownWitoutModal
						{...{
							setOpen,
							serachData,
							data,
							setSearchData,
							setValue,
							value,
							isCheckbox,
							open,
							setData,
							isSearch,
							placeholder,
						}}
					/>
				}
				modalTitle={'Select Team Member'}
				needCross={true}
				needArrow={false}
			/>*/}
		</IonPage>
	);
};

const TeamMemberList = ({ userId, setShowAssignModal }: any) => {
	const history = useHistory();
	const { t } = useTranslation();
	const [searchText, setSearchText] = useState<any>('');
	const [teamMemberList, setTeamMemberList] = useState<any>([]);
	const [filteredList, setFilteredList] = useState<any>([]);
	const [showPageExitPopup, setShowPageExitPopup] = useState<boolean>(false);
	const [popupName, setPopupName] = useState<string>('');
	const [reportingManagerId, setReportingManagerId] = useState<number>(0);
	const dispatch = useDispatch();
	const getMembersList = async () => {
		await dispatch(copsManagerAssignList())
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setTeamMemberList(response?.central_exec_list);
					setFilteredList(response?.central_exec_list);
				}
			});
	};

	const onSearchInput = (searchTerm: string) => {
		setSearchText(searchTerm);
		if (searchTerm !== '') {
			if (searchTerm.length >= 3) {
				const searchedList = teamMemberList.filter((member: any) => {
					const mName = member.value;
					return mName.toLowerCase().includes(searchTerm);
				});
				setFilteredList(searchedList);
			}
		} else {
			getMembersList();
		}
	};

	const assignManagerPopup = (mId: number, mName: string) => {
		setReportingManagerId(mId);
		setPopupName(mName);
		setShowPageExitPopup(true);
	};

	const assignManager = async () => {
		await dispatch(
			userAllocateManager({
				param: userId,
				data: {
					reportingManager_id: reportingManagerId,
				},
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setShowPageExitPopup(false);
					setShowAssignModal(false);
					history.push('/dashboard/teams');
				}
			});
	};

	useEffect(() => {
		getMembersList();
	}, []);

	return (
		<div>
			{/* <IonSearchbar
				className='team-dma-search px-0'
				placeholder='Search Team Member by “Name”'
				value={searchText}
				onIonChange={(e) => onSearchInput(e.detail.value!)}
				onIonClear={() => onSearchInput('')}
			></IonSearchbar> */}
			<CustomSearchbar
				value={searchText}
				searchIcon='/images/icons/searchbar-icon.svg'
				placeholder={t('Search Team Member by “Name”')}
				showClearButton='focus'
				onChange={onSearchInput}
				onIonClear={() => onSearchInput('')}
			/>
			<div className='mt-3'>
				{filteredList.length > 0 ? (
					filteredList?.map((member: any, index: any) => {
						return (
							<IonItem
								key={index}
								className='ion-no-padding'
								lines='full'
								onClick={() => assignManagerPopup(member.id, member.value)}
							>
								<div className='d-flex flex-grow-1 align-items-center my-2'>
									<div style={{ width: '34px', height: '34px' }}>
										<InitialLetter
											value={member?.value}
											height='34px'
											width='34px'
											fontSize='22px'
										/>
									</div>
									<div className='ms-3'>
										<p className='mb-0 fs-16'>{member.value}</p>
										<p className='mb-0 fs-14 text-grey'>{member.name}</p>
									</div>
								</div>
							</IonItem>
						);
					})
				) : (
					<CustomNoData />
				)}
			</div>

			<CustomModal
				id='custommodal'
				isOpen={showPageExitPopup}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setShowPageExitPopup(false)}
				breakpoints={[]}
				className='central-ops-assign'
				modalContent={PageExitPopUp(
					() => {
						setShowPageExitPopup(false);
					},
					() => {
						assignManager();
					},
					'Cancel',
					'Confirm',
					'',
					'Are you sure you want to allocate this DMA to <span class="primary-text-color">' +
						popupName +
						'</span> ?',
					'By doing this you will not be able to take any action on this DMA anymore.'
				)}
				needCross={false}
				needArrow={false}
			/>
		</div>
	);
};
