import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonItem } from '@ionic/react';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import { BasicInfoEdit } from './Component/BasicInfoEdit';
import { ProfessionalInfoEdit } from './Component/ProfessionalInfoEdit';
import { StatusNum } from '../../../../helpers/constant';
import { Divider } from '../../../../components/common/divider/Divider';
import { InputFormatCurrencyValue } from '../../../../helpers/functions';

interface Props {
	userDetails: any;
	userId: number;
	listStatus: any;
	getUserDetailsInfo: any;
}

export const UserInfoTab: React.FC<Props> = ({
	userDetails,
	userId,
	listStatus,
	getUserDetailsInfo,
}) => {
	const { t } = useTranslation();
	const [showBasicInfoForm, setShowBasicInfoForm] = useState<boolean>(false);
	const [showProfessionalInfoForm, setShowProfessionalInfoForm] =
		useState<boolean>(false);

	return (
		<div>
			<div className='basic-info'>
				<div className='px-3 mt-4 mb-2 d-flex align-items-center justify-content-between'>
					<h4 className='fw-600 fs-16 mb-0'>
						{userDetails?.basicInfo?.entityType == 'firm'
							? t('Authorised Signatory Info')
							: t('Basic Info')}
					</h4>
					{userDetails?.approve === 'rework_required' &&
						listStatus === StatusNum.REWORK && (
							<div
								className='d-flex fw-600 fs-14 primary-text-color'
								onClick={() => setShowBasicInfoForm(true)}
							>
								<img
									src='images/icons/user-details-edit-icon.svg'
									className='me-2'
								/>
								{t('Edit')}
							</div>
						)}
				</div>
				{userDetails?.basicInfo?.entityType == 'firm' && (
					<>
						<IonItem
							className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
							lines='full'
						>
							<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
								<p className='mb-0 fs-14'>{t('Firm Name')}</p>
								<p className='mb-0 fs-14 fw-600'>
									{userDetails?.basicInfo?.name
										? userDetails?.basicInfo?.name
										: '-'}
								</p>
							</div>
						</IonItem>
						{userDetails?.basicInfo?.signaTary == 'Proprietor' && (
							<IonItem
								className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
									<p className='mb-0 fs-14'>{t('Authorised Signatory PAN')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{userDetails?.basicInfo?.pan
											? userDetails?.basicInfo?.pan
											: '-'}
									</p>
								</div>
							</IonItem>
						)}
					</>
				)}
				<IonItem
					className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
					lines='full'
				>
					<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
						<p className='mb-0 fs-14'>{t('Mobile')}</p>
						<p className='mb-0 fs-14 fw-600'>
							{userDetails?.basicInfo?.mobile
								? userDetails?.basicInfo?.mobile
								: '-'}
						</p>
					</div>
				</IonItem>
				<IonItem
					className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
					lines='full'
				>
					<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
						<p className='mb-0 fs-14'>{t('Alternate Mobile Number')}</p>
						<p className='mb-0 fs-14 fw-600'>
							{userDetails?.basicInfo?.alternate_mobile
								? userDetails?.basicInfo?.alternate_mobile
								: '-'}
						</p>
					</div>
				</IonItem>
				<IonItem
					className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
					lines='full'
				>
					<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
						<p className='mb-0 fs-14'>{t('Email')}</p>
						<p className='mb-0 fs-14 fw-600'>
							{userDetails?.basicInfo?.email
								? userDetails?.basicInfo?.email
								: '-'}
						</p>
					</div>
				</IonItem>
				<IonItem
					className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
					lines='full'
				>
					<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
						<p className='mb-0 fs-14'>{t('City')}</p>
						<p className='mb-0 fs-14 fw-600'>
							{userDetails?.basicInfo?.city
								? userDetails?.basicInfo?.city
								: '-'}
						</p>
					</div>
				</IonItem>
				<IonItem
					className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
					lines='full'
				>
					<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
						<p className='mb-0 fs-14'>{t('State')}</p>
						<p className='mb-0 fs-14 fw-600'>
							{userDetails?.basicInfo?.state
								? userDetails?.basicInfo?.state
								: '-'}
						</p>
					</div>
				</IonItem>
				<IonItem
					className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
					lines='none'
				>
					<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
						<p className='mb-0 fs-14'>{t('Pincode')}</p>
						<p className='mb-0 fs-14 fw-600'>
							{userDetails?.basicInfo?.pin ? userDetails?.basicInfo?.pin : '-'}
						</p>
					</div>
				</IonItem>
			</div>
			{userDetails?.basicInfo?.entityType == 'firm' && (
				<>
					{/* <hr
						className='m-0 pt-1'
						style={{ background: '#f3f3f3', opacity: 1 }}
					/> */}
					<Divider />
					<div className=''>
						<div className='px-3 mt-4 mb-2 d-flex align-items-center justify-content-between'>
							<h4 className='fw-600 fs-16 mb-0'>
								{t('Contact Person Details')}
							</h4>
						</div>
					</div>
					<IonItem
						className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
						lines='full'
					>
						<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
							<p className='mb-0 fs-14'>{t('Name')}</p>
							<p className='mb-0 fs-14 fw-600'>
								{userDetails?.basicInfo?.contact_person_name
									? userDetails?.basicInfo?.contact_person_name
									: '-'}
							</p>
						</div>
					</IonItem>
					<IonItem
						className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
						lines='full'
					>
						<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
							<p className='mb-0 fs-14'>{t('Mobile')}</p>
							<p className='mb-0 fs-14 fw-600'>
								{userDetails?.basicInfo?.contact_person_mobile
									? userDetails?.basicInfo?.contact_person_mobile
									: '-'}
							</p>
						</div>
					</IonItem>
					<IonItem
						className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
						lines='none'
					>
						<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
							<p className='mb-0 fs-14'>{t('Email')}</p>
							<p className='mb-0 fs-14 fw-600'>
								{userDetails?.basicInfo?.contact_person_email
									? userDetails?.basicInfo?.contact_person_email
									: '-'}
							</p>
						</div>
					</IonItem>
				</>
			)}
			{/* <hr
				className='m-0 pt-1'
				style={{ background: '#f3f3f3', opacity: 1 }}
			/> */}
			<Divider />
			<div className=''>
				<div className='px-3 mt-4 mb-2 d-flex align-items-center justify-content-between'>
					<h4 className='fw-600 fs-16 mb-0'>{t('Professional Info')}</h4>
					{userDetails?.approve === 'rework_required' &&
						listStatus === StatusNum.REWORK && (
							<div
								className='d-flex fw-600 fs-14 primary-text-color'
								onClick={() => setShowProfessionalInfoForm(true)}
							>
								<img
									src='images/icons/user-details-edit-icon.svg'
									className='me-2'
								/>
								{t('Edit')}
							</div>
						)}
				</div>
				<IonItem
					className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
					lines='full'
				>
					<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
						<p className='mb-0 fs-14'>{t('Registered As')}</p>
						<p className='mb-0 fs-14 fw-600 text-capitalize'>
							{userDetails?.basicInfo?.entityType
								? userDetails?.basicInfo?.entityType
								: '-'}
						</p>
					</div>
				</IonItem>
				<IonItem
					className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
					lines='full'
				>
					<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
						<p className='mb-0 fs-14'>{t('Current Business')}</p>
						<p className='mb-0 fs-14 fw-600'>
							{userDetails?.addressProof?.CurrentBussines
								? userDetails?.addressProof?.CurrentBussines
								: '-'}
						</p>
					</div>
				</IonItem>
				<IonItem
					className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
					lines='full'
				>
					<div className='d-block'>
						<p className='mt-3 mb-2 fs-14'>{t('Current Business Address')}</p>
						<p className='mt-2 mb-3 fs-14 fw-600'>
							{userDetails?.addressProof?.OfficeAddress
								? userDetails?.addressProof?.OfficeAddress
								: '-'}
						</p>
					</div>
				</IonItem>
				{userDetails?.addressProof?.CurrentBussines === 'Finance' && (
					<>
						<IonItem
							className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
							lines='full'
						>
							<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
								<p className='mb-0 fs-14'>{t('Associated with DSA')}</p>
								<p className='mb-0 fs-14 fw-600'>
									{userDetails?.addressProof?.DSAAssociation === '1'
										? 'Yes'
										: 'No'}
								</p>
							</div>
						</IonItem>
						{userDetails?.addressProof?.DSAAssociation === '1' && (
							<>
								<IonItem
									className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
									lines='full'
								>
									<div
										className={
											userDetails?.addressProof?.Product?.length > 2
												? 'd-block py-2'
												: 'd-flex flex-grow-1 align-items-center justify-content-between'
										}
									>
										<p className='mb-0 fs-14'>{t('Products')}</p>
										<p className='mb-0 fs-14 fw-600'>
											{userDetails?.addressProof?.Product?.length
												? userDetails?.addressProof?.Product?.join(', ')
												: '-'}
										</p>
									</div>
								</IonItem>
								<IonItem
									className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
									lines='full'
								>
									<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
										<p className='mb-0 fs-14'>{t('Monthly Business Volume')}</p>
										<p className='mb-0 fs-14 fw-600 text-nowrap'>
											{userDetails?.addressProof?.Income
												? '₹ ' +
												  InputFormatCurrencyValue(
														parseInt(userDetails?.addressProof?.Income)
												  )
												: '-'}
										</p>
									</div>
								</IonItem>
							</>
						)}
					</>
				)}
				{(userDetails?.addressProof?.CurrentBussines?.toLowerCase() ===
					'finance' &&
					userDetails?.addressProof?.DSAAssociation === '0') ||
				userDetails?.addressProof?.CurrentBussines?.toLowerCase() ===
					'non-finance' ? (
					<IonItem
						className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
						lines='full'
					>
						<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
							<p className='mb-0 fs-14'>{t('Nature of Business')}</p>
							<p className='mb-0 fs-14 fw-600'>
								{userDetails?.addressProof?.BusinessNature
									? userDetails?.addressProof?.BusinessNature
									: '-'}
							</p>
						</div>
					</IonItem>
				) : (
					''
				)}
				{userDetails?.addressProof?.CurrentBussines === 'Non-Finance' && (
					<IonItem
						className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
						lines='full'
					>
						<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
							<p className='mb-0 fs-14'>{t('Profession')}</p>
							<p className='mb-0 fs-14 fw-600'>
								{userDetails?.addressProof?.Profession
									? userDetails?.addressProof?.Profession
									: '-'}
							</p>
						</div>
					</IonItem>
				)}
				<IonItem
					className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
					lines='full'
				>
					<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
						<p className='mb-0 fs-14'>{t('Reference 1 Name')}</p>
						<p className='mb-0 fs-14 fw-600'>
							{userDetails?.addressProof?.ReferenceName1
								? userDetails?.addressProof?.ReferenceName1
								: '-'}
						</p>
					</div>
				</IonItem>
				<IonItem
					className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
					lines='full'
				>
					<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
						<p className='mb-0 fs-14'>{t('Reference 1 Mobile Number')}</p>
						<p className='mb-0 fs-14 fw-600'>
							{userDetails?.addressProof?.ReferenceMobile1
								? userDetails?.addressProof?.ReferenceMobile1
								: '-'}
						</p>
					</div>
				</IonItem>
				<IonItem
					className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
					lines='full'
				>
					<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
						<p className='mb-0 fs-14'>{t('Reference 2 Name')}</p>
						<p className='mb-0 fs-14 fw-600'>
							{userDetails?.addressProof?.ReferenceName2
								? userDetails?.addressProof?.ReferenceName2
								: '-'}
						</p>
					</div>
				</IonItem>
				<IonItem
					className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
					lines='none'
				>
					<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
						<p className='mb-0 fs-14'>{t('Reference 2 Mobile Number')}</p>
						<p className='mb-0 fs-14 fw-600'>
							{userDetails?.addressProof?.ReferenceMobile2
								? userDetails?.addressProof?.ReferenceMobile2
								: '-'}
						</p>
					</div>
				</IonItem>
			</div>
			<Divider />
			<div className='product-and-payout-info'>
				<div className='px-3 mt-4'>
					<h4 className='fw-600 fs-16'>{t('Product & Payout Info')}</h4>
				</div>
				<IonItem
					className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
					lines='full'
				>
					<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
						<p className='mb-0 fs-14'>{t('Threshold Breached')}</p>
						<p className='mb-0 fs-14 fw-600'>
							{userDetails?.payout?.threshold_breached === 1 ? 'Yes' : 'No'}
						</p>
					</div>
				</IonItem>
				<IonItem
					className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
					lines='full'
				>
					<div className='d-flex flex-grow-1 justify-content-between my-3'>
						<p className='mb-0 fs-14'>{t('Owner')}</p>
						<div className='text-end'>
							<p className='mb-0 fs-14 fw-600'>
								{userDetails?.payout?.owner_name
									? userDetails?.payout?.owner_name
									: '-'}
							</p>
							<p className='mb-0 fs-14'>
								{userDetails?.payout?.owner_role
									? userDetails?.payout?.owner_role
									: '-'}
							</p>
						</div>
					</div>
				</IonItem>
				<IonItem lines='full'>
					<div className='d-flex flex-grow-1 justify-content-between my-3'>
						<p className='mb-0 fs-14'>{t('Approved By')}</p>
						<div className='text-end'>
							<p className='mb-0 fs-14 fw-600'>
								{userDetails?.payout?.approved_by_name
									? userDetails?.payout?.approved_by_name
									: '-'}
							</p>
							<p className='mb-0 fs-14'>
								{userDetails?.payout?.approver_role
									? userDetails?.payout?.approver_role
									: '-'}
							</p>
						</div>
					</div>
				</IonItem>
				<IonItem
					className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
					lines='none'
				>
					<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
						<p className='mb-0 fs-14 fw-600'>{t('Loan')}</p>
					</div>
				</IonItem>
				<IonItem
					className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
					lines='none'
				>
					<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
						<p className='mb-0 fs-14 fw-600 heading-color'>
							{t('Sub Product')}
						</p>
						<p className='mb-0 fs-14 fw-600 heading-color'>
							{t('Proposed Payout')}
						</p>
					</div>
				</IonItem>
				{userDetails?.payout?.subproducts?.map(
					(subProduct: any, index: any) => {
						return (
							<IonItem
								key={index}
								className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
									<p className='mb-0 fs-14'>
										{subProduct?.category_name
											? t(subProduct?.category_name)
											: ''}
									</p>
									<p className='mb-0 fs-14 fw-600'>
										{subProduct?.percentage ? subProduct?.percentage + '%' : ''}
									</p>
								</div>
							</IonItem>
						);
					}
				)}
			</div>

			<CustomModal
				id='customModalRework'
				isOpen={showBasicInfoForm}
				modalTitle={
					userDetails?.basicInfo?.entityType == 'firm'
						? t('Authorised Signatory Info')
						: t('Basic Info')
				}
				initialBreakpoint={1}
				handleClose={() => setShowBasicInfoForm(false)}
				breakpoints={[0, 1]}
				className=' side-modal'
				modalContent={
					<BasicInfoEdit
						{...{
							userId: userId,
							setShowBasicInfoForm,
							getUserDetailsInfo,
						}}
					/>
				}
				needCross={true}
				needArrow={false}
			></CustomModal>

			<CustomModal
				id='customModalRework'
				isOpen={showProfessionalInfoForm}
				modalTitle={t('Professional Info')}
				initialBreakpoint={1}
				handleClose={() => setShowProfessionalInfoForm(false)}
				breakpoints={[0, 1]}
				className='side-modal'
				modalContent={
					<ProfessionalInfoEdit
						{...{
							userId: userId,
							setShowProfessionalInfoForm,
							getUserDetailsInfo,
						}}
					/>
				}
				needCross={true}
				needArrow={false}
			></CustomModal>
		</div>
	);
};
