/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	IonContent,
	IonLabel,
	IonPage,
	IonSegment,
	IonSegmentButton,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Header } from '../../components/layout/header/Header';
import './DMADetails.scss';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useHistory, useLocation } from 'react-router';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
import { RejectionModal } from './RejectionModal';
import { StatusNum, USER_ROLES } from '../../helpers/constant';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	apiFinalizePayout,
	apiUpdatePayout,
	dmaAnalogy,
	getPayoutListInfo,
} from '../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { disableNumberArrows } from '../../helpers/functions';

export const ApproveProposedPayout: React.FC = () => {
	const dmaSelector = useSelector((state: any) => state?.users);
	const dispatch: any = useDispatch();
	const [payoutList, setPayoutList] = useState<any>([]);
	const [isFlatPayout, setIsFlatPayout] = useState<string>();
	const [rejectionModal, setRejectionModal] = useState(false);
	const [isEdit, setIsEdit] = useState<any>(false);
	const [newPayout, setNewPayout] = useState<any>([]);
	const [updateApi, setUpdateApi] = useState(2);
	const { t } = useTranslation();
	const history = useHistory();
	const location: any = useLocation();
	const [isBreached, setIsBreached] = useState(false);
	const [payout, setPayout] = useState<any>();
	const [key, setKey] = useState<any>(0);
	const isWeb = useSelector((state: any) => state?.app?.isWeb);

	// eslint-disable-next-line prefer-const

	// Managers Get APi and Data Creation
	useEffect(() => {
		// if (location.pathname === '/approve-proposed-payout') {
		if (
			dmaSelector?.dmaFlow?.dmaStatus === StatusNum?.EXISTINGAPPROVAL &&
			dmaSelector?.user_details?.roleType?.[0] === USER_ROLES.BUH
		) {
			getDmaAnalogyList();
		} else {
			getPayoutList();
		}
		// }
	}, [dmaSelector?.dmaFlow?.dmaId, location?.state?.updateApi, key, isEdit]);

	const getDmaAnalogyList = () => {
		dispatch(dmaAnalogy({ param: dmaSelector?.dmaFlow?.dmaId }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setPayout(result?.data);
					const type = Object?.keys(result?.data)?.[key];
					setPayoutList(result?.data?.[type]);
				}
			});
	};

	const getPayoutList = async () => {
		dispatch(
			getPayoutListInfo({ param: dmaSelector?.dmaFlow?.dmaId + '/' + 0 })
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					const type = Object?.keys(result?.Data)?.[key];
					setPayout(result?.Data);
					setPayoutList(result?.Data?.[type]?.subproducts);

					setIsFlatPayout(
						result?.Data?.[type]?.commission_type === 'fixed'
							? 'all-flat-payout'
							: 'per-product'
					);

					if (dmaSelector?.user_details?.roleType?.[0] !== USER_ROLES.BUH) {
						const checkIfBreached: any = result?.Data?.[
							type
						]?.subproducts?.findIndex(
							(item: any) => Number(item?.percentage) > Number(item?.threshold)
						);
						setIsBreached(checkIfBreached === -1 ? false : true);
					}

					const data: any = result?.Data?.[type]?.subproducts?.map(
						(item: any) => {
							return {
								sub_product_id: item?.id,
								percentage: item?.percentage,
							};
						}
					);

					setNewPayout(data);
				}
			});
	};
	const [error, setError] = useState(true);

	useEffect(() => {
		for (let i = 0; i < newPayout?.length; i++) {
			if (
				newPayout[i].percentage === undefined ||
				newPayout[i].percentage === ''
			) {
				setError(true);
				break;
			} else {
				setError(false);
			}
		}
		if (newPayout?.length === 0) {
			setError(true);
		}
	}, [newPayout, isFlatPayout]);
	const getActiveKeyName = () => {
		return payout && Object.keys(payout)?.[key];
	};

	const finalizePayout = () => {
		dispatch(
			apiFinalizePayout({
				param: `?payout_id=${
					dmaSelector?.dmaFlow?.dmaStatus === StatusNum?.EXISTINGAPPROVAL &&
					dmaSelector?.user_details?.roleType?.[0] === USER_ROLES.BUH
						? payout?.[getActiveKeyName()]?.[0]?.proposed_pid
						: payout?.[getActiveKeyName()]?.id
				}
				&flag=${getApiFlag('api')}
		`,
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setUpdateApi(updateApi + 1);

					setTimeout(() => {
						getPayoutList();

						history.push('/dashboard/teams', { update: updateApi });
					}, 2000);
				}
			});
	};

	const updatePayout = async () => {
		dispatch(
			apiUpdatePayout({
				param: payout?.[getActiveKeyName()]?.id,
				data: {
					subproduct: newPayout,
					owner_id: payout?.[getActiveKeyName()]?.owner_id,
					commission_type:
						isFlatPayout === 'per-product' ? 'variable' : 'fixed',
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					getPayoutList();
					setIsEdit(false);
				}
			});
	};
	const updateThreshold = (id: any, value: any) => {
		const findIndex = newPayout.findIndex(
			(el: any) => el.sub_product_id === id
		);
		const data: any = newPayout;
		const row = data[findIndex];
		row.percentage = value;
		setNewPayout([...data]);
	};

	const getApiFlag = (type = 'button') => {
		let flag = type === 'button' ? 'Approve' : 'Approved';
		if (dmaSelector?.dmaFlow?.dmaCode === StatusNum.REVISED) {
			flag = type === 'button' ? 'Submit' : 'Revised';
		} else if (
			!isBreached &&
			dmaSelector?.dmaFlow?.dmaStatus === StatusNum.EXISTINGAPPROVAL2
		) {
			flag = type === 'button' ? 'Approve' : 'Approved';
		} else if (
			isBreached &&
			dmaSelector?.dmaFlow?.dmaStatus === StatusNum.EXISTINGAPPROVAL2
		) {
			flag = type === 'button' ? 'Recommend' : 'Recommended';
		} else if (
			(isBreached &&
				dmaSelector?.dmaFlow?.dmaCode !== StatusNum.EXISTINGAPPROVAL) ||
			(dmaSelector?.dmaFlow?.dmaCode === StatusNum.EXISTINGAPPROVAL &&
				dmaSelector?.user_details?.roleType[0] !== USER_ROLES.BUH)
		) {
			flag = type === 'button' ? 'Recommend' : 'Recommended';
		}
		return flag;
	};

	return (
		<>
			<IonPage>
				<Header
					name={t('Products & Payout info')}
					backButton={true}
					needCross={true}
					needCrossOnClick={() =>
						history.push('/dma-details', {
							update: location?.state?.updateApi + 1 || 1,
						})
					}
				/>
				<IonContent className='background-web'>
					<div className='bg-web h-90'>
						<div className='bg-web-white container-width-md mt-4 border-md-radius-10 '>
							<div className='d-flex justify-content-center border-md-radius-10  px-3 border border-1 border-top-0 border-start-0 border-end-0'>
								<IonSegment
									style={{ width: 'fit-content' }}
									value='default'
									mode='md'
								>
									{payout &&
										Object?.keys(payout)?.map((item, index) => {
											return (
												<IonSegmentButton
													key={index}
													value='default'
													onClick={() => setKey(index)}
													mode='md'
												>
													<IonLabel className='fs-16 darkest-grey fw-600'>
														{t(item)}
													</IonLabel>
												</IonSegmentButton>
											);
										})}
								</IonSegment>
							</div>
							{payout && payout?.[getActiveKeyName()]?.message && (
								<div
									style={{ backgroundColor: '#FEF7EA' }}
									className='ion-padding darkest-grey'
								>
									<img
										src='/images/icons/alert.svg'
										alt='alert'
									/>{' '}
									{payout?.[getActiveKeyName()]?.message}
								</div>
							)}

							<div className='ion-padding'>
								{dmaSelector?.dmaFlow?.dmaStatus !==
									StatusNum.EXISTINGAPPROVAL &&
									dmaSelector?.user_details?.roleType?.[0] !== USER_ROLES.BUH &&
									!isEdit && (
										<div className='d-flex justify-content-end'>
											<CustomButton
												className={'ion-no-padding'}
												fill={'clear'}
												onClick={() => {
													setIsEdit(true);
												}}
											>
												<img
													src='/images/icons/edit.svg'
													alt='edit'
													className='me-2'
												/>{' '}
												{t('Edit')}
											</CustomButton>
										</div>
									)}
								<div className='d-flex justify-content-between my-3'>
									<h5 className='fs-14'>{t('Sub Product')}</h5>
									<h5 className='fs-14'>{t('Proposed Payout')}</h5>
								</div>

								<div className='d-flex flex-column gap-2'>
									{dmaSelector?.dmaFlow?.dmaStatus ===
										StatusNum.EXISTINGAPPROVAL &&
									dmaSelector?.user_details?.roleType?.[0] === USER_ROLES?.BUH
										? payoutList &&
										  payoutList?.map((item: any, index: number) => {
												return (
													<>
														<div
															key={index}
															className='d-flex gap-1 mb-1  align-items-center justify-content-between'
														>
															<div className='d-flex flex-column w-100 gap-1  '>
																<div>
																	<div className='d-flex align-items-center mb-2 '>
																		<img
																			src={`/images/icons/products/${item?.icon}.svg`}
																			alt=''
																			width={16}
																			height={16}
																			className='me-2 position-relative'
																		/>
																		<span className='fs-14 text-grey'>
																			{item?.name}
																		</span>
																	</div>
																	{item?.current && (
																		<div className='ms-4'>
																			<div className='d-flex justify-content-between'>
																				<span className='fs-16 fw-600 darkest-grey'>
																					{item?.current?.reportingManagerName}
																				</span>
																				<span className='fs-16 fw-600'>
																					{item?.current?.percentage + ' %'}
																				</span>
																			</div>
																			<p className='fs-12  text-grey mb-0'>
																				Current Manager
																			</p>
																		</div>
																	)}
																</div>
																{item?.proposed && (
																	<div className='ms-4 mt-1'>
																		<span className=''>
																			<div className='d-flex justify-content-between'>
																				<span className='fs-16 fw-600 darkest-grey'>
																					{item?.proposed?.reportingManagerName}
																				</span>
																				<span className='fs-16 fw-600'>
																					{item?.proposed?.percentage + ' %'}
																				</span>
																			</div>
																			<p className='fs-12 text-grey mb-0'>
																				Proposed Manager
																			</p>
																		</span>
																	</div>
																)}
															</div>
														</div>
													</>
												);
										  })
										: payoutList &&
										  payoutList?.map((item: any, index: number) => {
												const findIndex = newPayout.findIndex(
													(el: any) => el.sub_product_id === item?.id
												);
												const constant = newPayout[findIndex]?.percentage;
												const class_name: any = isEdit
													? Number(item?.percentage) > item?.threshold
														? 'border-red'
														: ''
													: Number(item?.percentage) > item?.threshold
													? 'color-red'
													: '';
												return (
													<>
														<div
															key={index}
															className='d-flex gap-1  align-items-center justify-content-between'
														>
															<div className='d-flex flex-column w-75 gap-1  '>
																<span className='fs-14 fw-600'>
																	<img
																		src={`/images/icons/products/${item?.icon}.svg`}
																		alt=''
																		width={16}
																		height={16}
																		className='me-2 position-relative'
																	/>{' '}
																	{item?.category_name}
																</span>
																<span className='fs-12 ms-4 ps-1'>
																	{item.reporting_s_name}
																</span>
															</div>
															<CustomInput
																type={'string'}
																className={class_name + ' my-0 text-center'}
																suffixClassName={'mb-0'}
																maxLength={2}
																style={
																	!isEdit
																		? {
																				backgroundColor: 'white',
																				maxWidth: '60px',
																				minWidth: '60px',
																				width: '100%',
																				marginRight: '10px',
																				'--border-color': 'none',
																		  }
																		: {
																				maxWidth: '60px',
																				minWidth: '60px',
																				marginRight: '10px',
																				width: '100%',
																		  }
																}
																value={constant}
																disabled={!isEdit}
																needSuffixIcon={true}
																suffixText={' %'}
																onChange={(e: any) => {
																	updateThreshold(item?.id, e.detail.value);
																}}
																onBeforeInput={(evt: any) => {
																	!/[0-9]/.test(evt.data) &&
																		evt.preventDefault();
																}}
																onKeyDown={(e: any) => {
																	disableNumberArrows(e);
																}}
																onWheel={(e: any) => e.target.blur()}
															/>
														</div>

														{Number(item?.percentage) > item?.threshold && (
															<div className='w-100 d-flex justify-content-end'>
																<p
																	className='fs-12 mb-0'
																	style={{ color: '#EC0A0A' }}
																>
																	<img
																		src='/images/icons/red-info.svg'
																		alt='info'
																	/>{' '}
																	{t('Threshold Breached')}
																</p>
															</div>
														)}
													</>
												);
										  })}
								</div>
							</div>
							{isEdit ? (
								<div className='ion-padding d-flex flex-column gap-3 d-none d-md-block'>
									<CustomButton
										title={t('Cancel')}
										className={'w-100 '}
										onClick={() => setIsEdit(false)}
										fill={'outline'}
									/>
									<CustomButton
										disabled={error}
										title={t('Update')}
										className={'w-100 '}
										onClick={updatePayout}
									/>
								</div>
							) : (
								<div className='ion-padding d-flex flex-column gap-3 d-none d-md-block'>
									{dmaSelector?.user_details?.roleType?.[0] !==
										USER_ROLES.BUH && (
										<CustomButton
											title={t('Reject')}
											className={'w-100 '}
											onClick={() => setRejectionModal(true)}
											fill={'outline'}
										/>
									)}
									{dmaSelector?.user_details?.roleType?.[0] ===
										USER_ROLES.BUH && (
										<CustomButton
											title={t('Request Revision')}
											className={'w-100 '}
											onClick={() => setRejectionModal(true)}
											fill={'outline'}
										/>
									)}
									<CustomButton
										title={t(getApiFlag())}
										className={'w-100 '}
										onClick={finalizePayout}
									/>
								</div>
							)}
						</div>
					</div>
				</IonContent>
				{isEdit ? (
					<div className='ion-padding d-flex flex-column gap-3 d-block d-md-none'>
						<CustomButton
							title={t('Cancel')}
							className={'w-100 '}
							onClick={() => setIsEdit(false)}
							fill={'outline'}
						/>
						<CustomButton
							disabled={error}
							title={t('Update')}
							className={'w-100 '}
							onClick={updatePayout}
						/>
					</div>
				) : (
					<div className='ion-padding d-flex flex-column gap-3 d-block d-md-none'>
						{dmaSelector?.user_details?.roleType?.[0] !== USER_ROLES.BUH && (
							<CustomButton
								title={t('Reject')}
								className={'w-100 '}
								onClick={() => setRejectionModal(true)}
								fill={'outline'}
							/>
						)}
						{dmaSelector?.user_details?.roleType?.[0] === USER_ROLES.BUH && (
							<CustomButton
								title={t('Request Revision')}
								className={'w-100 '}
								onClick={() => setRejectionModal(true)}
								fill={'outline'}
							/>
						)}
						<CustomButton
							title={t(getApiFlag())}
							className={'w-100 '}
							onClick={finalizePayout}
						/>
					</div>
				)}
			</IonPage>
			<CustomModal
				id='rejectionmodal'
				isOpen={rejectionModal}
				initialBreakpoint={isWeb ? 1 : 0.4}
				handleClose={() => setRejectionModal(false)}
				breakpoints={[0, 0.4, 0.6, 0.75]}
				className='side-modal'
				modalContent={
					<RejectionModal
						title={
							dmaSelector?.user_details?.roleType?.[0] === USER_ROLES.BUH
								? t('Request Revision')
								: t('Reason for Rejection')
						}
						reasonNeeded={
							dmaSelector?.user_details?.roleType?.[0] === USER_ROLES.BUH
								? false
								: true
						}
						setRejectionModal={setRejectionModal}
					/>
				}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
