import { IonContent, IonFooter } from '@ionic/react';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory, useLocation } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
	saveLenderSpcfInfo,
	lenderSpfDetailsFlow,
	getSelectedLendersList,
} from '../../../../store/reducers/lead/leadSlice';
import {
	ContinueLaterPopUp,
	checkFieldsValidToContinue,
	disableNumberArrows,
	fetchStateCityByPincode,
	showToastAlert,
} from '../../../../helpers/functions';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import { ABFLOtherInfoSchema } from '../ABFLInfo';
import { LenderNameForAPICall } from '../../../../helpers/lenders';
import '../ABFLForm.scss';
import { OfferRejectedMsg } from '../../OfferRejectedMsg/OfferRejectedMsg';
import {
	getLenderUserInfo,
	getProfessionalDetailsMaster,
} from '../../../../store/reducers/user/userSlice';
import DropDownModal from '../../../../components/common/custom-dropdown/DropDownModal';
import { CustomInput } from '../../../../components/common/custom-input/CustomInput';
import { LEAD_STATUS, REGEX } from '../../../../helpers/constant';
import CustomRadioButton from '../../../../components/common/custom-radio-button/CustomRadioButton';
import StateCity from '../../../../components/common/StateCity';

export const ABFLFormPL: React.FC<any> = () => {
	const [formValidationSchemas, setFormValidationSchemas] = useState<any>(
		yup.object().shape({})
	);
	const [abflFormFields, setAbflFormFields]: any = useState([]);
	const [dropdownMasterData, setDropdownMasterData] = useState<any>({});
	const [modalPageClose, setModalPageClose] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const { selectedLender, lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);

	const [pinCodeData, setPinCodeData] = useState<any>([]);
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const location = useLocation<any>();
	const leadInfo = useSelector((state: any) => state.lead.leadFlowData);

	// useEffect(() => {
	// 	if (leadFlowData?.selectedProductData?.product_category_id) {
	// 		getLeadData();
	// 	}
	// }, []);

	useEffect(() => {
		if (location.pathname !== '/lead-lender-specific-form-steps') {
			return;
		}
		getLeadData();
	}, [location.pathname]);

	const getLeadData = async () => {
		await dispatch(
			getLenderUserInfo({
				param: leadFlowData?.leadId,
				data: {
					lender_name: LenderNameForAPICall.ABFL_PL,
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status && Object.keys(result.data).length) {
					const schemaBuilderObject: any = {};
					result?.data?.forEach((formKeyName: any) => {
						if (formKeyName.type === 'dropdownSelect') {
							dispatch(getProfessionalDetailsMaster({ param: formKeyName.key }))
								.then(unwrapResult)
								.then((dropdownResponse: any) => {
									if (dropdownResponse?.status) {
										setDropdownMasterData((masterData: any) => {
											return {
												...masterData,
												[formKeyName.name]: dropdownResponse.data,
											};
										});
									}
								});
						}
						if (formKeyName?.is_required) {
							if (formKeyName?.is_comma_separated) {
								schemaBuilderObject[formKeyName.name] = yup.number();
								schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
									formKeyName.name
								]
									.typeError(
										formKeyName.placeholder.replaceAll('*', '') +
											' must be a number'
									)
									.required(
										typeof formKeyName?.required_error_message === 'string' &&
											formKeyName?.required_error_message
											? formKeyName?.required_error_message
											: formKeyName.placeholder.replaceAll('*', '') +
													' is a required field'
									);
								if (formKeyName?.min) {
									schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
										formKeyName.name
									].min(
										formKeyName['min'],
										`${formKeyName.placeholder.replaceAll(
											'*',
											''
										)} should not be zero or empty`
									);
								}
							} else if (formKeyName?.type === 'dropdownSelect') {
								schemaBuilderObject[formKeyName.name] = yup
									.number()
									.required(
										typeof formKeyName?.required_error_message === 'string' &&
											formKeyName?.required_error_message
											? formKeyName?.required_error_message
											: formKeyName.placeholder.replaceAll('*', '') +
													' is a required field'
									);
							} else if (formKeyName.type === 'text') {
								schemaBuilderObject[formKeyName.name] = yup.string();
								schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
									formKeyName.name
								].required(
									typeof formKeyName?.required_error_message === 'string' &&
										formKeyName?.required_error_message
										? formKeyName?.required_error_message
										: formKeyName.placeholder.replaceAll('*', '') +
												' is a required field'
								);
								if (formKeyName?.match_regex) {
									if (formKeyName?.name == 'roi') {
										schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
											formKeyName.name
										].matches(
											REGEX.ROI_IRR,
											'Please Enter Valid Requested ROI'
										);
									} else {
										schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
											formKeyName.name
										].matches(
											new RegExp(formKeyName['match_regex']),
											typeof formKeyName?.regex_error_message === 'string' &&
												formKeyName?.regex_error_message
												? formKeyName?.regex_error_message
												: formKeyName.placeholder.replaceAll('*', '') +
														' should be a valid format'
										);
									}
								}
							} else {
								schemaBuilderObject[formKeyName.name] = yup
									.string()
									.required(
										typeof formKeyName?.required_error_message === 'string' &&
											formKeyName?.required_error_message
											? formKeyName?.required_error_message
											: formKeyName.placeholder.replaceAll('*', '') +
													' is a required field'
									);
							}
						}
					});

					const OrgPincodeData = result?.data.find(
						(i: any) => i.name === 'orgPincode'
					)?.value;
					if (OrgPincodeData) {
						fetchStateCityByPincode(
							setValue,
							'city',
							'state',
							setPinCodeData,
							dispatch,
							OrgPincodeData,
							trigger
						);
					}
					setFormValidationSchemas(
						formValidationSchemas.concat(
							yup.object().shape(schemaBuilderObject)
						)
					);
					setAbflFormFields(result.data);
				}
			});
	};

	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		trigger,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(
			leadFlowData?.selectedProductData?.product_category_id == 1
				? formValidationSchemas
				: ABFLOtherInfoSchema
		),
		mode: 'all',
		reValidateMode: 'onChange',
		// defaultValues: {},
	});

	const onSubmit = async (data: any, flag: any = '') => {
		const req: any = {
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			step: isValid && flag !== 'continue_later' ? 'step1' : 'step1',
			isNewLead: isValid && flag !== 'continue_later',
			lender_name: LenderNameForAPICall.ABFL_PL,
			lender_spf_details: {
				...data,
				consents_timestamp: String(Date.parse(Date())),
			},
			continue_later: flag === 'continue_later' ? true : false,
		};

		if (lenderSpecificDetails?.lead_spf_detail_id) {
			req.lead_spf_detail_id = lenderSpecificDetails?.lead_spf_detail_id;
		}

		await dispatch(saveLenderSpcfInfo({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					if (isValid && flag !== 'continue_later') {
						dispatch(
							lenderSpfDetailsFlow({
								...lenderSpecificDetails,
								lender_spf_details: {
									...lenderSpecificDetails?.lender_spf_details,
									...req.lender_spf_details,
								},
								lead_spf_detail_id: result.data.lead_spf_detail_id,
								lender_lead_id: result.data.customerID,
								step: 'step2',
							})
						);
					}
					if (flag === 'continue_later') {
						setShowOfferErrMsg('');
					} else {
						setShowOfferErrMsg(
							`${result?.message} Lead Id-${result?.data?.data?.abfl_application_id}`
						);
					}
				} else {
					setShowOfferErrMsg(
						`${result?.message}` || 'Please try again later !'
					);
				}
			});
	};

	const getSelectedLenderList = async () => {
		const isShowStatus =
			leadInfo?.leadStatus == LEAD_STATUS.LOGIN_LABEL ||
			leadInfo?.leadStatus == LEAD_STATUS.SANCTIONED_LABEL ||
			leadInfo?.leadStatus == LEAD_STATUS.DISBURSED_LABEL;
		await dispatch(
			getSelectedLendersList({
				param: '/' + leadInfo?.leadId + `?close=${!isShowStatus ? 1 : 0}`,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					const continueLaterCondition: any = response?.data.map(
						(item: any) =>
							item?.status == 50 || item?.status == 90 || item?.status == 53
					);

					continueLaterCondition.find((item: any) => item == false) === false
						? history.push('/lender-specific-info')
						: history.push('/dashboard/lead');
				}
			});
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
							<form onSubmit={handleSubmit(onSubmit)}>
								{abflFormFields?.map(
									(
										{
											name,
											type,
											placeholder,
											is_required,
											value,
											radio_options,
											is_comma_separated = false,
										}: any,
										index: any
									) => {
										const errorText: any =
											errors && Object.keys(errors).length > 0 && errors;
										if (value) {
											setValue(name, value);
										}
										const disabledFields = [
											'orgName',
											'orgEmailId',
											'orgPincode',
											'requestedLoanAmount',
											'tenure',
										];
										if (type === 'dropdownSelect') {
											return (
												<div key={index + '-dropdownSelect'}>
													<>
														<Controller
															key={`controller-${index}`}
															control={control}
															render={({
																field: { onChange, onBlur },
															}: any) => (
																<DropDownModal
																	label={
																		t(`${placeholder}`) +
																		(is_required ? ' *' : '')
																	}
																	data={
																		dropdownMasterData[name]
																			? dropdownMasterData[name]
																			: []
																	}
																	name={name}
																	onChange={onChange}
																	onBlur={onBlur}
																	className={
																		errorText[name]?.message
																			? 'ion-invalid ion-touched mb-3'
																			: 'mb-3'
																	}
																	errors={t(errorText[name]?.message as string)}
																	value={value ? value : getValues(name)}
																	disabled={
																		disabledFields.includes(name) ? true : false
																	}
																/>
															)}
															name={name}
														/>
													</>
												</div>
											);
										} else if (type === 'radio') {
											return (
												<div key={index}>
													<Controller
														key={index}
														control={control}
														render={({ field: { onChange, name } }) => (
															<div className='mb-2'>
																<h6 className='mt-3'>
																	{t(placeholder) + (is_required ? ' *' : '')}
																</h6>
																<CustomRadioButton
																	type='group'
																	value={getValues(name)}
																	onIonChange={(e: any) => {
																		onChange(e);
																	}}
																	className='mb-2'
																	ionRadioClassName='me-4'
																	labelPlacement='end'
																	data={
																		Array.isArray(radio_options) &&
																		radio_options?.length
																			? radio_options
																			: []
																	}
																/>
																<div className='error-text px-3 sc-ion-input-md'>
																	{t(errorText[name]?.message)}
																</div>
															</div>
														)}
														name={name}
													/>
												</div>
											);
										} else {
											return (
												<div key={index + '-text'}>
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<>
																<CustomInput
																	type={type}
																	disabled={
																		disabledFields.includes(name) ? true : false
																	}
																	label={t(placeholder)}
																	onBlur={onBlur}
																	id={name}
																	onChange={(e: any) => {
																		onChange(e);
																		if (is_comma_separated) {
																			e = isNaN(
																				e.detail.value.replaceAll(',', '')
																			)
																				? ''
																				: Number(
																						e.detail.value.replace(/,/g, '')
																				  );
																		}

																		if (name === 'orgPincode') {
																			if (e?.detail?.value?.length === 6) {
																				fetchStateCityByPincode(
																					setValue,
																					'city',
																					'state',
																					setPinCodeData,
																					dispatch,
																					e.detail.value,
																					trigger
																				);
																			} else {
																				setValue('state', '');
																				setValue('city', '');
																				setPinCodeData([]);
																			}
																		}
																	}}
																	value={value ? value : getValues(name)}
																	maxLength={
																		name === 'net_monthly_income' ? 15 : null
																	}
																	onBeforeInput={(evt: any) => {
																		if (type == 'number') {
																			!REGEX.ALLOWNUMBERS.test(evt.data) &&
																				evt.preventDefault();
																		}
																	}}
																	onKeyDown={(e: any) => {
																		if (type == 'number') {
																			disableNumberArrows(e);
																		}
																	}}
																	onWheel={(e: any) => {
																		if (type == 'number') {
																			e.target.blur();
																		}
																	}}
																	errors={t(errorText[name]?.message || '')}
																/>
																{name === 'orgPincode' && (
																	<StateCity
																		cityInputName='city'
																		control={control}
																		setValue={setValue}
																		pinCodeData={pinCodeData}
																		trigger={trigger}
																		getValues={getValues}
																	/>
																)}
															</>
														)}
														name={name}
													/>
												</div>
											);
										}
									}
								)}
							</form>
							<div className={` d-none d-md-block`}>
								<CustomButton
									className='w-100 fw-bold dashboard-add-lead-button mb-3'
									expand='block'
									fill='outline'
									title={t('Continue Later')}
									onClick={() => {
										if (checkFieldsValidToContinue(errors)) {
											setModalPageClose(true);
										} else {
											showToastAlert({
												type: 'error',
												message: 'Please fix above errors',
											});
										}
									}}
								/>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				)}
				<CustomModal
					needIonContentWraper={false}
					isOpen={modalPageClose}
					initialBreakpoint={undefined}
					handleClose={() => setModalPageClose(false)}
					breakpoints={[]}
					className='height-auto confirm-popup-middle continue-later'
					modalContent={ContinueLaterPopUp(
						() => {
							setModalPageClose(false);
						},
						() => {
							new Promise(function () {
								setTimeout(function () {
									getSelectedLenderList();

									// onSubmit(getValues(), 'continue_later');
									setModalPageClose(false);
								}, 10);
							});
						},
						'Cancel',
						'Continue Later',
						'className',
						'Are You Sure You Want to Exit?',
						`${t('You can continue later from where you left off.')}`
					)}
					needCross={false}
					needArrow={false}
				/>
			</IonContent>

			{!showOfferErrMsg && (
				<IonFooter className='d-block d-md-none position-relative'>
					<div
						style={{
							backgroundColor: '#fff',
							zIndex: 2,
						}}
					>
						<div className={`ion-padding`}>
							<CustomButton
								className='w-100 fw-bold dashboard-add-lead-button mb-3'
								expand='block'
								fill='outline'
								title={t('Continue Later')}
								onClick={() => {
									if (checkFieldsValidToContinue(errors)) {
										setModalPageClose(true);
									} else {
										showToastAlert({
											type: 'error',
											message: 'Please fix above errors',
										});
									}
								}}
							/>
							<div className='proceed-btn'>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				</IonFooter>
			)}
		</>
	);
};
