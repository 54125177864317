import {
	IonContent,
	IonFooter,
	IonImg,
	IonRadio,
	IonRadioGroup,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	apiGenderDropdown,
	bajajBLLAPCreateLoan,
	bajajRmList,
	getLeadFlowUrl,
	lenderSpfDetailsFlow,
} from '../../../../store/reducers/lead/leadSlice';
import { CustomInput } from '../../../../components/common/custom-input/CustomInput';
import {
	ContinueLaterPopUp,
	checkFieldsValidToContinue,
	disableNumberArrows,
	// fetchStateCityByPincode,
	getBlankKeyFromObject,
	getEncrypted,
	showToastAlert,
} from '../../../../helpers/functions';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import // customerType,

'../../TataCapitals/TataCapitals';
import { LenderNameForAPICall } from '../../../../helpers/lenders';
// import { DynamicDropdown } from '../../../../components/common';
import { LENDER_SPECIFIC_DATA, REGEX } from '../../../../helpers/constant';
// import StateCity from '../../../../components/common/StateCity';
import {
	CoapplicantOtherdetails,
	bajajBLLAPvalidationOtherDtlsSchema,
} from '../BajajFinance';
import { unwrapResult } from '@reduxjs/toolkit';
import CustomDropDown from '../../../../components/common/custom-dropdown/CustomDropDown';
import { OfferRejectedMsg } from '../../OfferRejectedMsg/OfferRejectedMsg';

export const BLLAPCoApplicantOtherDetails: React.FC<any> = () => {
	const [modalPageClose, setModalPageClose] = useState(false);
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');

	const history = useHistory();
	const dispatch = useDispatch();
	const [url, setUrl] = useState('');
	// const [pinCodeData, setPinCodeData] = useState<any>([]);
	const { selectedLender, lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);
	const [genderApi, setGenderApi] = useState([]);
	console.log(genderApi);

	const [Rm, setRm] = useState<any>([]);
	const [rmData, setRmData] = useState<any>();

	const {
		handleSubmit,
		control,
		getValues,
		formState: { errors, isValid },
		setValue,
		// trigger,
	} = useForm({
		resolver: yupResolver(bajajBLLAPvalidationOtherDtlsSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			RMManager: 'test',
		},
	});

	/**
	 * On submit to save user data and after that
	 * @param data Form data
	 */

	useEffect(() => {
		if (!location.pathname.includes('/lead-lender-specific-form-steps')) {
			return;
		} else {
			getGenderAndMaritalDropDown();
			getRMList();
			const fetchLeadFlowUrl = async () => {
				try {
					const response = await dispatch(getLeadFlowUrl());
					const url = response.payload.url;
					const pathname = new URL(url).origin;
					setUrl(pathname);
				} catch (error) {
					console.error('Error fetching lead flow URL:', error);
				}
			};
			fetchLeadFlowUrl();
		}
	}, [location.pathname]);

	const getGenderAndMaritalDropDown = async () => {
		await dispatch(apiGenderDropdown())
			.then(unwrapResult)
			.then((result: any) => {
				setGenderApi(result.data);
			});
	};

	const getRMList = async () => {
		try {
			await dispatch(
				bajajRmList({
					data: {
						token: lenderSpecificDetails?.token,
						prospectId: lenderSpecificDetails?.prospectId,
						offerId: lenderSpecificDetails?.offerId,
						product: lenderSpecificDetails?.product,
						lead_spf_detail_id: lenderSpecificDetails?.lead_spf_detail_id,
					},
				})
			)
				.then(unwrapResult)
				.then((result: any) => {
					if (result?.status) {
						setRmData(result.data);
						const d = JSON.parse(result.data.SalesUser);

						const arrData: any = d.map((obj: any) => {
							obj['value'] = obj['Name'];
							delete obj['Name'];
							return obj;
						});

						setRm(arrData);
					}else {
			setShowOfferErrMsg('Something went wrong! Please try again later.');

					}
				});
		} catch (err) {
			setShowOfferErrMsg('Something went wrong! Please try again later.');
		}
	};

	const onSubmit = async (data: any) => {
		const blankKey = getBlankKeyFromObject(JSON.parse(JSON.stringify(data)));
		const endpoint = '/lead-lender-specific-form-steps';
		const req: any = {
			lender_spf_details: data,
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.TATA_BL,
			step: Object.keys(blankKey).length > 0 ? 'step2' : 'step3',
			isNewLead: Object.keys(blankKey).length === 0,
			// sourceSystemURL: `${process.env.REACT_APP_UI_ENDPOINT}/lead-lender-specific-form-steps`,
			sourceSystemURL: url + endpoint,
		};

		if (lenderSpecificDetails?.lead_spf_detail_id) {
			req.lead_spf_detail_id = lenderSpecificDetails?.lead_spf_detail_id;
		}

		const encodedData = getEncrypted(
			JSON.stringify({
				RMManager: data.RMManager,
			})
		);
		localStorage.setItem(LENDER_SPECIFIC_DATA, encodedData);
		try {
			dispatch(
				bajajBLLAPCreateLoan({
					data: {
						token: lenderSpecificDetails?.token,
						OwnerId: Rm?.[0]?.OwnerId, // OwnerId from response of get user list from sales user tag
						OfferId: rmData?.OfferId, //From Get User List Response
						product: rmData?.productName, // Response of get User List API
						userId: Rm?.[0]?.Id, //Id of user list API Response
						rmName: Rm?.[0]?.value,
						leadApplicationId: leadFlowData?.lead_app_id,
						lead_spf_detail_id: lenderSpecificDetails?.lead_spf_detail_id,
					},
				})
			).then((res: any) => {
				if (res?.payload?.data?.Success) {
					dispatch(
						lenderSpfDetailsFlow({
							...lenderSpecificDetails,
							loanApplicationID: res?.payload?.data.loanApplicationID,
							// lead_spf_detail_id: lenderSpecificDetails?.lead_spf_detail_id,
							// headerTitle: TataPLFormTitle.REFERENCE_TWO,
							step: 'step3',
						})
					);
				} else {
					setShowOfferErrMsg('Something went wrong! Please try again later.');
				}
			});
		} catch (err) {
			setShowOfferErrMsg('Something went wrong! Please try again later.');
		}
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
							<form onSubmit={handleSubmit(onSubmit)}>
								{CoapplicantOtherdetails.map(
									({ name, type, placeholder }: any, index: any) => {
										const errorText: any =
											errors && Object.keys(errors).length > 0 && errors;
										return (
											<div key={index}>
												{type === 'radio' ? (
													<div
														key={index}
														className='d-flex align-items-center gap-1 mb-3'
													>
														<div>
															<h6 className='mt-3'>{t(placeholder)}</h6>
															<Controller
																control={control}
																render={({
																	field: { onChange, onBlur, name },
																}) => (
																	<>
																		<IonRadioGroup
																			onIonChange={onChange}
																			onBlur={onBlur}
																			name={name}
																			value={getValues(name)}
																		>
																			<IonRadio
																				className='me-4'
																				labelPlacement='end'
																				value='Mr'
																			>
																				{t('Mr') + '.'}
																			</IonRadio>
																			<IonRadio
																				className='me-4'
																				labelPlacement='end'
																				value='Ms'
																			>
																				{t('Ms') + '.'}
																			</IonRadio>
																			<IonRadio
																				className='me-4'
																				labelPlacement='end'
																				value='Mrs'
																			>
																				{t('Mrs') + '.'}
																			</IonRadio>
																			<IonRadio
																				labelPlacement='end'
																				value='Dr'
																			>
																				{t('Dr') + '.'}
																			</IonRadio>
																		</IonRadioGroup>
																		<div className='error-text px-3 sc-ion-input-md'>
																			{t(errorText[name]?.message)}
																		</div>
																	</>
																)}
																name={name}
															/>
														</div>
													</div>
												) : type === 'dropdown' ? (
													<div style={{ marginBottom: '15px' }}>
														<Controller
															control={control}
															render={({ field: { onChange, onBlur } }) => (
																<CustomDropDown
																	{...{
																		isCheckbox: false,
																		title: t('RM Manager *'),
																		setData: (data: any) => {
																			setRm(data);
																			setValue(
																				'RMManager',
																				data
																					?.filter(
																						({ checked }: any) =>
																							checked && checked
																					)
																					.map(({ value }: any) => value)
																					.toString()
																			);
																		},
																		onChange: onChange,
																		onBlur: onBlur,
																		data: Rm,
																		placeholder: t('Search'),
																		isSearch: true,
																		modalTitle: t('Select RM Manager'),
																	}}
																/>
															)}
															name={name}
														/>
													</div>
												) : (
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<CustomInput
																type={type}
																label={t(placeholder)}
																onBlur={onBlur}
																id={name}
																onChange={(e: any) => {
																	onChange(e);
																}}
																value={getValues(name)}
																onBeforeInput={(evt: any) => {
																	if (type == 'number') {
																		!REGEX.ALLOWNUMBERS.test(evt.data) &&
																			evt.preventDefault();
																	}
																}}
																onKeyDown={(e: any) => {
																	if (type == 'number') {
																		disableNumberArrows(e);
																	}
																}}
																onWheel={(e: any) => {
																	if (type == 'number') {
																		e.target.blur();
																	}
																}}
																className={`${
																	name === 'PAN' ? 'text-uppercase' : ''
																}${
																	['First_Name', 'Last_Name'].includes(name)
																		? ' text-capitalize '
																		: ''
																}`}
																errors={t(errorText[name]?.message || '')}
																needSuffixIcon={name === 'Date_of_Birth'}
																suffixClassName={
																	'position-absolute date-of-birth-sufix'
																}
																suffixText={
																	<IonImg
																		src='/images/icons/input-dob-calendar-icon.svg'
																		alt='Date_of_Birth'
																	/>
																}
																outerClassName={`${
																	name === 'Date_of_Birth'
																		? 'position-relative'
																		: ''
																}`}
															/>
														)}
														name={name}
													/>
												)}
											</div>
										);
									}
								)}
							</form>

							<div className={` d-none d-md-block`}>
								<CustomButton
									className='w-100 fw-bold dashboard-add-lead-button mb-3'
									expand='block'
									fill='outline'
									title={t('Continue Later')}
									onClick={() => {
										if (checkFieldsValidToContinue(errors)) {
											setModalPageClose(true);
										} else {
											showToastAlert({
												type: 'error',
												message: 'Please fix above errors',
											});
										}
									}}
								/>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				)}

				<CustomModal
					needIonContentWraper={false}
					isOpen={modalPageClose}
					initialBreakpoint={undefined}
					handleClose={() => setModalPageClose(false)}
					breakpoints={[]}
					className='height-auto confirm-popup-middle continue-later'
					modalContent={ContinueLaterPopUp(
						() => {
							setModalPageClose(false);
						},
						() => {
							new Promise(function (myResolve) {
								setTimeout(function () {
									myResolve(history.push('/dashboard/lead'));
									onSubmit(getValues());
									setModalPageClose(false);
								}, 10);
							});
						},
						'Cancel',
						'Continue Later',
						'className',
						'Are You Sure You Want to Exit?',
						`${t('You can continue later from where you left off.')}`
					)}
					needCross={false}
					needArrow={false}
				/>
			</IonContent>

			{showOfferErrMsg ? (
				<>
					<OfferRejectedMsg message={showOfferErrMsg} />
				</>
			) : (
				<IonFooter className='d-block d-md-none position-relative'>
					<div
						style={{
							backgroundColor: '#fff',
							zIndex: 2,
						}}
					>
						<div className={`ion-padding`}>
							<CustomButton
								className='w-100 fw-bold dashboard-add-lead-button mb-3'
								expand='block'
								fill='outline'
								title={t('Continue Later')}
								onClick={() => {
									if (checkFieldsValidToContinue(errors)) {
										setModalPageClose(true);
									} else {
										showToastAlert({
											type: 'error',
											message: 'Please fix above errors',
										});
									}
								}}
							/>
							<div className='proceed-btn'>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				</IonFooter>
			)}
		</>
	);
};
