export const LenderMasterID = {
	ADITYA_BIRLA_FINANCE_LTD: 568,
	KOTAK_MAHINDRA: 84,
	LENDINGKART: 578,
	TATA_CAPITAL: 580,
	INCRED: 595,
	KREDITBEE: 596,
	BAJAJ_FINANCE_GROWTH: 656,
	BAJAJ_FINANCE_FINANCE: 657,
	L_AND_T_FINANCE: 1049,
	AADHAR_FINANCE: 1054,
	BAJAJ_FINANCE_LTD: 581,
	MONEYVIEW: 1096,
	PUNJAB: 1055,
	BHANIX_FINANCE: 1353,
	PREFR: 1354,
	FIBE_PL: 1056,
	MONEY_TAP: 598,
	DMI_HOUSING: 1357,
	INDIFI: 576
};

export const LenderNameForAPICall = {
	ABFL: 'abfl_bl',
	ABFL_PL: 'abfl_pl',
	LENDINGKART: 'lendingkart',
	INCRED: 'incred',
	KREDITBEE: 'kreditbee',
	LNT_HL: 'lnt_hl',
	BAJAJ: 'bajaj',
	KOTAK: 'kotak',
	TATA_HL_LAP: 'tata_hl_lap',
	TATA_BL: 'tata_bl',
	TATA_PL: 'tata_pl',
	AADHAR_HL: 'aadhar_hl',
	BAJAJ_BL_LAP: 'bajaj_bl_lap',
	MONEYVIEW: 'moneyview',
	CASHE: 'cashe',
	PREFR_PL: 'prefr_pl',
	FIBE: 'fibe_pl',
	BHANIX_FINANCE: 'bhanix_finance',
	MONEYTAP: 'moneytap',
	PUNJAB: 'pnb_hl_lap',
	DMI_HL_LAP: 'dmi_hl_lap',
	DMI_BL: 'dmi_bl',
	INDIFI_BL: 'indifi_bl'
};

export const SessionApiLendersList = ['aditya birla finance ltd.', 'prefr'];
