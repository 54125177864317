import './CustomNote.scss';

function CustomNote({ className, header, icon, content }: any) {
	return (
		<div className={`custom-note-container ${className ? className : ''}`}>
			{header ? (
				<h6 className='custom-note-header fs-12 fw-900'>{header}</h6>
			) : (
				''
			)}
			<div className='d-flex'>
				{icon ? (
					<img
						className='me-2 pe-1'
						style={{ width: '24px' }}
						src={icon}
						alt='icon'
					/>
				) : (
					''
				)}
				<p
					className='custom-note-content fs-14 m-0'
					dangerouslySetInnerHTML={{ __html: content }}
				></p>
			</div>
		</div>
	);
}

export default CustomNote;
