import React, { useEffect } from 'react';
import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import { useHistory, useParams } from 'react-router';
import { axiosInstatnce, setAuthToken } from '../../store/accessToken';
import { useDispatch } from 'react-redux';
import { leadFlow } from '../../store/reducers/lead/leadSlice';
import { changeToken } from '../../store/reducers/user/userSlice';
//import { apiVeryfyOtp } from '../../store/reducers/user/userSlice';
//import { unwrapResult } from '@reduxjs/toolkit';

export const Nimbuzz: React.FC = () => {
	const dispatch: any = useDispatch();
	const { id, token }: any = useParams();
	const history = useHistory();
	useEffect(() => {
		if (token) {
			axiosInstatnce.interceptors.request.use(setAuthToken(token));
			dispatch(changeToken(token));

			setTimeout(() => {
				dispatch(leadFlow({ leadId: id, id: id, from: 'nimbuss' }));
				history.push('/lead-details/' + id + '/' + token);
			}, 3000);
		}
	}, [token]);

	return (
		<IonPage>
			<IonContent className='border'>
				<div
					className='position-fixed  w-100  d-flex justify-content-center align-items-center h-100'
					style={{
						top: '0px',
						left: '50%',
						transform: 'translate(-50%,0%)',
						backgroundColor: 'rgba(0,0,0,0.2)',
					}}
				>
					<div className='d-flex gap-4 justify-content-center align-items-center flex-column'>
						<IonSpinner name='lines-sharp' />
						<h6 className='text-primary text-center'>
							Please wait while we are proceeding you to the next step...
						</h6>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};
