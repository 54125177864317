import * as Yup from 'yup';
import { REGEX } from '../../../../helpers/constant';

export const documentObj = {
    file: '',
    preview: '',
    fileObj: {
        name: '',
        type: '',
    },
    message: '',
};

// If is_property_identified === true
export const propertyConditionalFields = (msg: string) => {
    return Yup.string().trim()
        .when('is_property_identified', {
            is: (val: any) => val === true,
            then: (schema) => {
                if (msg === 'Tentative Property Value') {
                    return schema.required('Tentative Property Value is required')
                        .matches(REGEX.ALLOWNUMBERS, 'Property Value should be number')
                        .matches(
                            REGEX.GREATER_THAN_ZERO,
                            'Property Value should be greater than zero'
                        )
                } else if (msg === 'Property Pincode') {
                    return schema.required('Property Pincode is required')
                        .matches(/^\d{6}$/, 'Pincode should be 6 Digits')
                        .length(6, 'Pincode should be 6 Digits')
                } else {
                    return schema.required(msg + ' is required')
                }
            },
            otherwise: () => Yup.string(),
        })
}

export const applicantSchema1: any = Yup.object({
    loan_purpose_id: Yup.string().required('Loan Purpose is required'),
    branch_id: Yup.string().required('Branch is required'),
    interest_type_id: Yup.string().required('Interest Type is required'),
    sales_officer_emp_id: Yup.string().required('Sales Officer is required'),
    sales_officer_contact: Yup.string().notRequired(),
    is_property_identified: Yup.boolean().notRequired(),
    owner_of_property: Yup.boolean().notRequired(),
    tentative_property_value: propertyConditionalFields('Tentative Property Value'),
    property_type_id: propertyConditionalFields('Property Type'),
    property_address_line_1: propertyConditionalFields('Property Address Line 1'),
    property_address_line_2: propertyConditionalFields('Property Address Line 2'),
    property_pincode: propertyConditionalFields('Property Pincode'),
    is_co_applicant: Yup.boolean().notRequired(),
});

export const applicantFields1 = [
    {
        name: 'loan_purpose_id',
        type: 'dropdown',
        placeholder: 'Loan Purpose *',
    }, {
        name: 'branch_id',
        type: 'dropdown',
        placeholder: 'Branch *',
    }, {
        name: 'interest_type_id',
        type: 'dropdown',
        placeholder: 'Interest Type *',
    }, {
        name: 'sales_officer_emp_id',
        type: 'dropdown',
        placeholder: 'Sales Officer (RM Selection) *',
    }, {
        name: 'is_property_identified',
        type: 'checkbox',
        placeholder: 'Is Property Identified?',
    }
];

export const applicantFields1_1 = [
    {
        name: 'owner_of_property',
        type: 'checkbox',
        placeholder: 'Are you owner of property?',
    }, {
        name: 'property_type_id',
        type: 'dropdown',
        placeholder: 'Property Type *',
    }, {
        name: 'tentative_property_value',
        type: 'text',
        placeholder: 'Tentative Property Value *',
    }, {
        name: 'property_address_line_1',
        type: 'text',
        placeholder: 'Property Address Line 1 *',
    }, {
        name: 'property_address_line_2',
        type: 'text',
        placeholder: 'Property Address Line 2 *',
    }, {
        name: 'property_pincode',
        type: 'text',
        placeholder: 'Property Pincode *',
    }
];

export const coApplicantCheckbox = [
    {
        name: 'is_co_applicant',
        type: 'checkbox',
        placeholder: 'Do you want to add co-applicant?',
    },
]

export const applicantSchema2: any = Yup.object({
    gender_type_id: Yup.string().required('Gender is required'),
    marital_status_type_id: Yup.string().required('Marital Status is required'),
    aadhar_no: Yup.string().trim()
        .required('Aadhaar is required')
        .matches(REGEX.AADHAAR, 'Aadhaar Should Be XXXX XXXX XXXX Format'),
    address_proof_type_id: Yup.string().required('Address Proof Type is required'),
    address_proof_document_id: Yup.string().notRequired(),
    address_proof_document_name: Yup.string().notRequired(),
    address_proof_doc: Yup.array().of(
        Yup.object({
            file: Yup.string().required('Document is required'),
        })
    ),
    kyc_aadhar_front_doc: Yup.array().of(
        Yup.object({
            file: Yup.string().required('Aadhar Front Doc is required'),
        })
    ),
    kyc_aadhar_back_doc: Yup.array().of(
        Yup.object({
            file: Yup.string().required('Aadhar Back Doc is required'),
        })
    ),
    kyc_pan_doc: Yup.array().of(
        Yup.object({
            file: Yup.string().required('Pan Card is required'),
        })
    ),
    curr_pincode: Yup.string().trim()
        .required('Current Pincode is required')
        .matches(/^\d{6}$/, 'Pincode should be 6 Digits')
        .length(6, 'Pincode should be 6 Digits'),
    father_first_name: Yup.string()
        .trim()
        .max(100, "Father's First Name should not be more than 100")
        .matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input')
        .required("Father's First Name is Required"),
    father_last_name: Yup.string()
        .trim()
        .max(100, "Father's Last Name should not be more than 100")
        .matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input')
        .required("Father's Last Name is Required"),
    company_name: Yup.string().required('Company Name is required'),
    company_address_line_1: Yup.string().required('Company Address Line 1 is required'),
    company_address_line_2: Yup.string().required('Company Address Line 2 is required'),
    emp_sector_type_id: Yup.string().required('Sector Type is required'),
});

export const applicantFields2 = [
    {
        name: 'gender_type_id',
        type: 'dropdown',
        placeholder: 'Gender *',
    }, {
        name: 'marital_status_type_id',
        type: 'dropdown',
        placeholder: 'Marital Status *',
    }, {
        name: 'aadhar_no',
        type: 'text',
        placeholder: 'Aadhar Number *',
    }, {
        name: 'address_proof_type_id',
        type: 'dropdown',
        placeholder: 'Address Proof Type *',
    }, {
        name: 'address_proof_doc',
        type: 'file',
        placeholder: 'Address Proof Documents *'
    }, {
        name: 'kyc_aadhar_front_doc',
        type: 'file',
        placeholder: 'Aadhar Card Front Document *'
    }, {
        name: 'kyc_aadhar_back_doc',
        type: 'file',
        placeholder: 'Aadhar Card Back Document *'
    }, {
        name: 'kyc_pan_doc',
        type: 'file',
        placeholder: 'Pan Card Document *'
    }, {
        name: 'curr_pincode',
        type: 'text',
        placeholder: 'Current Pincode *',
    }, {
        name: 'father_first_name',
        type: 'text',
        placeholder: "Father's First Name *",
    }, {
        name: 'father_last_name',
        type: 'text',
        placeholder: "Father's Last Name *",
    }, {
        name: 'company_name',
        type: 'text',
        placeholder: "Company Name *",
    }, {
        name: 'company_address_line_1',
        type: 'text',
        placeholder: "Company Address Line 1 *",
    }, {
        name: 'company_address_line_2',
        type: 'text',
        placeholder: "Company Address Line 2 *",
    }, {
        name: 'emp_sector_type_id',
        type: 'dropdown',
        placeholder: "Sector Type *",
    },
];

// If profile_segment_type_id === Assessed Income / Regular ITR
export const assessedAndITRConditionalFields = (msg: string) => {
    return Yup.string()
        .when('profile_segment_type_name', {
            is: (val: any) => val === 'Assessed Income' || val === 'Regular ITR',
            then: (schema) => {
                if (msg === 'Business Vintage') {
                    return schema
                        .required(msg + ' is required')
                        .matches(REGEX.STABILITY, 'Please enter a valid number')
                }
                return schema.required(msg + ' is required')
            },
            otherwise: () => Yup.string(),
        })
}

// If profile_segment_type_id === Assessed Income
export const assessedConditionalFields = (msg: string) => {
    return Yup.string()
        .when('profile_segment_type_name', {
            is: (val: any) => val === 'Assessed Income',
            then: (schema) => {
                if (msg === 'Annual Assessed Income') {
                    return schema.required(msg + ' is required')
                        .matches(REGEX.ALLOWNUMBERS, msg + ' should be number')
                        .matches(
                            REGEX.GREATER_THAN_ZERO,
                            msg + ' should be greater than zero'
                        );
                } else {
                    return schema.required(msg + ' is required')
                }
            },
            otherwise: () => Yup.string(),
        })
}

// If profile_segment_type_id === Regular ITR
export const ITRConditionalFields = (msg: string) => {
    return Yup.string()
        .when('profile_segment_type_name', {
            is: (val: any) => val === 'Regular ITR',
            then: (schema) => schema.required(msg + ' is required'),
            otherwise: () => Yup.string(),
        })
}

// If profile_segment_type_id === Cash / Bank Salary
export const cashBankSalaryConditionalFields = (msg: string) => {
    return Yup.string()
        .when('profile_segment_type_name', {
            is: (val: any) => val === 'Cash Salary' || val === 'Bank Salary',
            then: (schema) => schema.required(msg + ' is required'),
            otherwise: () => Yup.string(),
        })
}

export const applicantSchema3: any = Yup.object().shape({
    is_income_earner: Yup.boolean().notRequired(),

    profile_segment_type_id: Yup.string().when('is_income_earner', {
        is: (val: any) => val === true,
        then: (schema) => schema.required('Profile Segment Type is required'),
        otherwise: () => Yup.string(),
    }),
    profile_segment_type_name: Yup.string().notRequired(),

    // Assessed Income
    monthly_assessed_income: assessedConditionalFields('Monthly Assessed Income'),

    // Regular ITR
    itr_last_year_income: ITRConditionalFields('ITR Last Year Income'),

    // Assessed Income & Regular ITR
    itr_current_year_income: assessedAndITRConditionalFields('ITR Current Year Income'),
    business_vintage: assessedAndITRConditionalFields('Business Vintage'),
    business_vintage_proof_type_id: assessedAndITRConditionalFields('Business Vintage Proof Type'),
    bank_statement_12_months_doc: Yup.array().of(Yup.object().shape({
        file: Yup.string().when('profile_segment_type_name', {
            is: (val: any) => val === 'Assessed Income' || val === 'Regular ITR',
            then: (schema) => schema.required('Document is required'),
            otherwise: () => Yup.string(),
        }),
    })),
    last_2_years_itr_doc: Yup.array().of(Yup.object({
        file: Yup.string().when('profile_segment_type_name', {
            is: (val: any) => val === 'Assessed Income' || val === 'Regular ITR',
            then: (schema) => schema.required('Document is required'),
            otherwise: () => Yup.string(),
        }),
    })),
    business_vintage_proof_doc: Yup.array().of(Yup.object({
        file: Yup.string().when('profile_segment_type_name', {
            is: (val: any) => val === 'Assessed Income' || val === 'Regular ITR',
            then: (schema) => schema.required('Document is required'),
            otherwise: () => Yup.string(),
        }),
    })),

    // Cash / Bank Salary
    monthly_gross_income: Yup.string().test(
        "gross_income_validation",
        "",
        (value, validationContext) => {
            const { createError, parent: { profile_segment_type_name } } = validationContext;
            if (['Cash Salary', 'Bank Salary'].includes(profile_segment_type_name)) {
                let msg = "";
                if (profile_segment_type_name === 'Cash Salary') {
                    if (Number(value) > 20000) {
                        msg = "Monthly Gross Income should be less than or equal to 20,000";
                    }
                }
                const errMsg = !value ? "Monthly Gross Income is required" :
                    !REGEX.ALLOWNUMBERS.test(value) ? "Monthly Gross Income should be number" :
                        !REGEX.GREATER_THAN_ZERO.test(value) ? "Monthly Gross Income should be greater than zero" : msg;
                if (errMsg) {
                    return createError({ message: errMsg });
                }
            }
            return true;
        }
    ),
    monthly_deduction: Yup.string().test(
        "monthly_deduction_validation",
        "",
        (value, validationContext) => {
            const { createError, parent: { profile_segment_type_name, monthly_gross_income } } = validationContext;
            if (['Cash Salary', 'Bank Salary'].includes(profile_segment_type_name)) {
                let msg = "";
                if (Number(value) >= Number(monthly_gross_income)) {
                    msg = "Monthly Deduction Amount should be less than Monthly Gross Income";
                }
                const errMsg = !value ? "Monthly Deduction Amount is required" :
                    !REGEX.ALLOWNUMBERS.test(value) ? "Monthly Deduction Amount should be number" :
                        !REGEX.GREATER_THAN_ZERO.test(value) ? "Monthly Deduction Amount should be greater than zero" : msg;
                if (errMsg) {
                    return createError({ message: errMsg });
                }
            }
            return true;
        }
    ),
    total_experience: cashBankSalaryConditionalFields('Total Experience'),
    retirement_age: cashBankSalaryConditionalFields('Retirement Age'),
    latest_salary_slip_doc: Yup.array().of(Yup.object({
        file: Yup.string().when('profile_segment_type_name', {
            is: (val: any) => val === 'Cash Salary' || val === 'Bank Salary',
            then: (schema) => schema.required('Document is required'),
            otherwise: () => Yup.string(),
        }),
    })),
    form_16_doc: Yup.array().of(Yup.object({
        file: Yup.string().when('profile_segment_type_name', {
            is: (val: any) => val === 'Bank Salary',
            then: (schema) => schema.required('Document is required'),
            otherwise: () => Yup.string(),
        }),
    })),
    bank_statement_6_months_doc: Yup.array().of(Yup.object({
        file: Yup.string().when('profile_segment_type_name', {
            is: (val: any) => val === 'Cash Salary' || val === 'Bank Salary',
            then: (schema) => schema.required('Document is required'),
            otherwise: () => Yup.string(),
        }),
    }))
});

export const applicantFields3: any = [
    {
        name: 'is_income_earner',
        type: 'checkbox',
        placeholder: 'Is Income Earner?',
    }
];

export const applicantFields3_1: any = [{
    name: 'profile_segment_type_id',
    type: 'dropdown',
    placeholder: 'Profile Segment Type *',
}]

export const applicantAssessedIncomeFields3: any = [
    {
        name: 'monthly_assessed_income',
        type: 'text',
        placeholder: 'Monthly Assessed Income *',
    }
];

export const applicantITRIncomeFields3: any = [
    {
        name: 'itr_last_year_income',
        type: 'text',
        placeholder: 'ITR Last Year Income *',
    }
];

export const applicantAssessedOrITRIncomeFields3: any = [
    {
        name: 'itr_current_year_income',
        type: 'text',
        placeholder: 'ITR Current Year Income *',
    }, {
        name: 'business_vintage',
        type: 'text',
        placeholder: 'Business Vintage (In Years) *',
    }, {
        name: 'business_vintage_proof_type_id',
        type: 'dropdown',
        placeholder: 'Business Vintage Proof Type *',
    }, {
        name: 'business_vintage_proof_doc',
        type: 'file',
        placeholder: 'Business Vintage Proof *',
    }, {
        name: 'bank_statement_12_months_doc',
        type: 'file',
        placeholder: '12 Months Bank Statement *',
    }, {
        name: 'last_2_years_itr_doc',
        type: 'file',
        placeholder: 'Last 2 Years ITR *',
    }
];

export const applicantCashOrBankIncomeFields3: any = [
    {
        name: 'monthly_gross_income',
        type: 'text',
        placeholder: 'Monthly Gross Income *',
    }, {
        name: 'monthly_deduction',
        type: 'text',
        placeholder: 'Monthly Deduction Amount *',
    }, {
        name: 'total_experience',
        type: 'text',
        placeholder: 'Total Experience (In Year) *',
    }, {
        name: 'retirement_age',
        type: 'text',
        placeholder: 'Retirement Age *',
    }, {
        name: 'latest_salary_slip_doc',
        type: 'file',
        placeholder: 'Latest Salary Slip *',
    }, {
        name: 'bank_statement_6_months_doc',
        type: 'file',
        placeholder: '6 Months Bank Statement *',
    }
];

export const form16Field = [
    {
        name: 'form_16_doc',
        type: 'file',
        placeholder: 'Form 16 *',
    }
];

export const form3DefaultValues = (spfDetails: any) => {
    return {
        is_income_earner: spfDetails?.is_income_earner || false,
        profile_segment_type_id: spfDetails?.profile_segment_type_id || '',
        profile_segment_type_name: spfDetails?.profile_segment_type_name || '',
        monthly_assessed_income: spfDetails?.monthly_assessed_income || '',
        itr_last_year_income: spfDetails?.itr_last_year_income || '',
        business_vintage: spfDetails?.business_vintage || '',
        business_vintage_proof_type_id: spfDetails?.business_vintage_proof_type_id || '',
        itr_current_year_income: spfDetails?.itr_current_year_income || '',
        bank_statement_12_months_doc: spfDetails?.bank_statement_12_months_doc || [documentObj],
        last_2_years_itr_doc: spfDetails?.last_2_years_itr_doc || [documentObj],
        business_vintage_proof_doc: spfDetails?.business_vintage_proof_doc || [documentObj],
        monthly_gross_income: spfDetails?.monthly_gross_income || '',
        monthly_deduction: spfDetails?.monthly_deduction || '',
        total_experience: spfDetails?.total_experience || '',
        retirement_age: spfDetails?.retirement_age || '',
        latest_salary_slip_doc: spfDetails?.latest_salary_slip_doc || [documentObj],
        form_16_doc: spfDetails?.form_16_doc || [documentObj],
        bank_statement_6_months_doc: spfDetails?.bank_statement_6_months_doc || [documentObj],
    }
}

export const primaryAppFormDefaultValues = (pageId: any, formData: any, leadInfo: any) => {
    const spfDetails = formData?.lender_spf_details;
    const forms: any = {
        form1: {
            loan_purpose_id: spfDetails?.loan_purpose_id || '',
            branch_id: spfDetails?.branch_id || '',
            interest_type_id: spfDetails?.interest_type_id || '',
            sales_officer_emp_id: Number(spfDetails?.sales_officer_emp_id) || '',
            sales_officer_contact: spfDetails?.sales_officer_contact || '',
            is_property_identified: spfDetails?.is_property_identified || false,
            owner_of_property: spfDetails?.owner_of_property || false,
            property_type_id: spfDetails?.property_type_id || '',
            tentative_property_value: spfDetails?.tentative_property_value || '',
            property_address_line_1: spfDetails?.property_address_line_1 || '',
            property_address_line_2: spfDetails?.property_address_line_2 || '',
            property_pincode: spfDetails?.property_pincode || '',
            is_co_applicant: spfDetails?.is_co_applicant || false,
        },
        form2: {
            gender_type_id: spfDetails?.gender_type_id || '',
            marital_status_type_id: spfDetails?.marital_status_type_id || '',
            aadhar_no: leadInfo?.commonData?.aadhaarno || spfDetails?.aadhar_no || '',
            address_proof_type_id: spfDetails?.address_proof_type_id || '',
            address_proof_document_id: spfDetails?.address_proof_document_id || '',
            address_proof_document_name: spfDetails?.address_proof_document_name || '',
            address_proof_doc: spfDetails?.address_proof_doc || [documentObj],
            kyc_aadhar_front_doc: spfDetails?.kyc_aadhar_front_doc || [documentObj],
            kyc_aadhar_back_doc: spfDetails?.kyc_aadhar_back_doc || [documentObj],
            kyc_pan_doc: spfDetails?.kyc_pan_doc || [documentObj],
            curr_pincode: leadInfo?.commonData?.curr_pincode || spfDetails?.curr_pincode || '',
            father_first_name: leadInfo?.commonData?.father_first_name || spfDetails?.father_first_name || '',
            father_last_name: leadInfo?.commonData?.father_last_name || spfDetails?.father_last_name || '',
            company_name: leadInfo?.personalInfo?.professional_details?.['Company Name'] || spfDetails?.company_name || '',
            company_address_line_1: leadInfo?.commonData?.off_add_1 || spfDetails?.company_address_line_1 || '',
            company_address_line_2: leadInfo?.commonData?.off_add_2 + ',' + leadInfo?.commonData?.off_add_3 || spfDetails?.company_address_line_2 || '',
            emp_sector_type_id: spfDetails?.emp_sector_type_id || '',
        },
        form3: form3DefaultValues(spfDetails)
    };

    return forms?.['form' + pageId];
}

// CO-APPLICANT
export const coApplicantSchema1: any = Yup.object({
    first_name: Yup.string()
        .trim()
        .required("First Name is Required")
        .max(100, "First Name should not be more than 100")
        .matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
    last_name: Yup.string()
        .trim()
        .required("Last Name is Required")
        .max(100, "Last Name should not be more than 100")
        .matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
    mobile_no: Yup.string()
        .length(10, 'Mobile No. Should Be 10 Digits.')
        .required('Mobile is Required.')
        .matches(REGEX.INDIAN_MOBILE_NUMBERS, 'Please Enter a valid Mobile No.'),
    dob: Yup.date()
        .required('DOB is Required.')
        .typeError('Please select valid date')
        .max(new Date(Date.now()), 'Date cannot be in the future')
        .test('dob', 'Age must be 18 or older', function (birthdate) {
            const cutoff = new Date();
            cutoff.setFullYear(cutoff.getFullYear() - 18);
            return birthdate <= cutoff;
        }),
    aadhar_no: Yup.string().trim()
        .required('Aadhaar is required')
        .matches(REGEX.AADHAAR, 'Aadhaar Should Be XXXX XXXX XXXX Format'),
    pan_no: Yup.string().trim()
        .required('PAN No. is Required.')
        .matches(
            REGEX.PAN,
            "PAN no. should be in alphanumeric format 'ABCDE1234A' only"
        ),
    relation_with_applicant_type_id: Yup.string().required('Relation With Applicant is required'),
    gender_type_id: Yup.string().required('Gender is required'),
    marital_status_type_id: Yup.string().required('Marital Status is required'),
});

export const coApplicantFields1 = [
    {
        name: 'first_name',
        type: 'text',
        placeholder: 'First Name *',
    }, {
        name: 'last_name',
        type: 'text',
        placeholder: 'Last Name *',
    }, {
        name: 'mobile_no',
        type: 'text',
        placeholder: 'Mobile No. *',
    }, {
        name: 'dob',
        type: 'date',
        placeholder: 'Date Of Birth *',
    }, {
        name: 'aadhar_no',
        type: 'text',
        placeholder: 'Aadhar No. *',
    }, {
        name: 'pan_no',
        type: 'text',
        placeholder: 'Pan No. *',
    }, {
        name: 'relation_with_applicant_type_id',
        type: 'dropdown',
        placeholder: 'Relation With Applicant *',
    }, {
        name: 'gender_type_id',
        type: 'dropdown',
        placeholder: 'Gender *',
    }, {
        name: 'marital_status_type_id',
        type: 'dropdown',
        placeholder: 'Marital Status *',
    }
];

export const addressFieldsConditionalRequired = (msg: string) => {
    return Yup.string()
        .when('address_same_as_applicant', {
            is: (val: any) => val === false,
            then: (schema) => {
                if (msg === 'Current Pincode') {
                    return schema
                        .required('Current Pincode is required')
                        .matches(/^\d{6}$/, 'Pincode should be 6 Digits')
                        .length(6, 'Pincode should be 6 Digits')
                } else {
                    return schema.required(msg + ' is required')
                }
            },
            otherwise: () => Yup.string(),
        });
}

export const coApplicantSchema2: any = Yup.object({
    father_first_name: Yup.string()
        .trim()
        .required("Father's First Name is Required")
        .max(100, "Father's First Name should not be more than 100")
        .matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
    father_last_name: Yup.string()
        .trim()
        .required("Father's Last Name is Required")
        .max(100, "Father's Last Name should not be more than 100")
        .matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
    company_name: Yup.string().required('Company Name is required'),
    company_address_line_1: Yup.string().required('Company Address Line 1 is required'),
    company_address_line_2: Yup.string().required('Company Address Line 2 is required'),
    emp_sector_type_id: Yup.string().required('Sector Type is required'),
    address_proof_type_id: Yup.string().required('Address Proof Type is required'),
    address_proof_document_id: Yup.string().notRequired(),
    address_proof_document_name: Yup.string().notRequired(),
    address_proof_doc: Yup.array().of(
        Yup.object({
            file: Yup.string().required('Document is required'),
        })
    ),
    kyc_aadhar_front_doc: Yup.array().of(
        Yup.object({
            file: Yup.string().required('Aadhar Front Doc is required'),
        })
    ),
    kyc_aadhar_back_doc: Yup.array().of(
        Yup.object({
            file: Yup.string().required('Aadhar Back Doc is required'),
        })
    ),
    kyc_pan_doc: Yup.array().of(
        Yup.object({
            file: Yup.string().required('Pan Card is required'),
        })
    ),
    address_same_as_applicant: Yup.boolean().notRequired(),
    curr_address_line_1: addressFieldsConditionalRequired('Address Line 1'),
    curr_address_line_2: addressFieldsConditionalRequired('Address Line 2'),
    curr_pincode: addressFieldsConditionalRequired('Current Pincode'),
});

export const coApplicantFields2 = [
    {
        name: 'father_first_name',
        type: 'text',
        placeholder: "Father's First Name *",
    }, {
        name: 'father_last_name',
        type: 'text',
        placeholder: "Father's Last Name *",
    }, {
        name: 'company_name',
        type: 'text',
        placeholder: "Company Name *",
    }, {
        name: 'company_address_line_1',
        type: 'text',
        placeholder: "Company Address Line 1 *",
    }, {
        name: 'company_address_line_2',
        type: 'text',
        placeholder: "Company Address Line 2 *",
    }, {
        name: 'emp_sector_type_id',
        type: 'dropdown',
        placeholder: "Sector Type *",
    }, {
        name: 'address_proof_type_id',
        type: 'dropdown',
        placeholder: 'Address Proof Type *',
    }, {
        name: 'address_proof_doc',
        type: 'file',
        placeholder: 'Address Proof Documents *'
    }, {
        name: 'kyc_aadhar_front_doc',
        type: 'file',
        placeholder: 'Aadhar Card Front Document *'
    }, {
        name: 'kyc_aadhar_back_doc',
        type: 'file',
        placeholder: 'Aadhar Card Back Document *'
    }, {
        name: 'kyc_pan_doc',
        type: 'file',
        placeholder: 'Pan Card Document *'
    }, {
        name: 'address_same_as_applicant',
        type: 'checkbox',
        placeholder: 'Is Address same as Applicant?'
    }
];

export const coApplicantAddressFields2 = [
    {
        name: 'curr_address_line_1',
        type: 'text',
        placeholder: 'Current Address Line 1 *',
    }, {
        name: 'curr_address_line_2',
        type: 'text',
        placeholder: 'Current Address Line 2 *',
    }, {
        name: 'curr_pincode',
        type: 'text',
        placeholder: 'Current Pincode *',
    }
];

export const coApplicantSchema3 = applicantSchema3;

export const coAppFormDefaultValues = (pageId: any, formData: any, coTabIndex: any) => {
    let spfDetails: any = {};
    if (formData?.lender_spf_details?.co_applicant && formData?.lender_spf_details?.co_applicant?.length > 0) {
        spfDetails = formData?.lender_spf_details?.co_applicant?.[coTabIndex];
    }
    const forms: any = {
        form1: {
            first_name: spfDetails?.first_name || '',
            last_name: spfDetails?.last_name || '',
            mobile_no: spfDetails?.mobile_no || '',
            dob: spfDetails?.dob || null,
            aadhar_no: spfDetails?.aadhar_no || '',
            pan_no: spfDetails?.pan_no || '',
            relation_with_applicant_type_id: spfDetails?.relation_with_applicant_type_id || false,
            gender_type_id: spfDetails?.gender_type_id || '',
            marital_status_type_id: spfDetails?.marital_status_type_id || ''
        },
        form2: {
            father_first_name: spfDetails?.father_first_name || '',
            father_last_name: spfDetails?.father_last_name || '',
            company_name: spfDetails?.company_name || '',
            company_address_line_1: spfDetails?.company_address_line_1 || '',
            company_address_line_2: spfDetails?.company_address_line_2 || '',
            emp_sector_type_id: spfDetails?.emp_sector_type_id || '',
            address_proof_type_id: spfDetails?.address_proof_type_id || '',
            address_proof_document_id: spfDetails?.address_proof_document_id || '',
            address_proof_document_name: spfDetails?.address_proof_document_name || '',
            address_proof_doc: spfDetails?.address_proof_doc || [documentObj],
            kyc_aadhar_front_doc: spfDetails?.kyc_aadhar_front_doc || [documentObj],
            kyc_aadhar_back_doc: spfDetails?.kyc_aadhar_back_doc || [documentObj],
            kyc_pan_doc: spfDetails?.kyc_pan_doc || [documentObj],
            address_same_as_applicant: spfDetails?.address_same_as_applicant || false,
            curr_address_line_1: spfDetails?.curr_address_line_1 || '',
            curr_address_line_2: spfDetails?.curr_address_line_2 || '',
            curr_pincode: spfDetails?.curr_pincode || '',
        },
        form3: form3DefaultValues(spfDetails)
    };

    return forms?.['form' + pageId];
}

export const formWiseDataSet = (flag: string, pageId: any, leadFlowData?: any) => {
    let masterApis: any = [];
    let fieldsForm: any = [];
    let loanPurposeFlag: any = 'DMIHL_LOAN_PURPOSE';
    switch (pageId) {
        case 1:
            if (flag === 'applicant') {
                fieldsForm = applicantFields1;
                if (leadFlowData?.product_category_id == 4) {
                    loanPurposeFlag = 'DMILAP_LOAN_PURPOSE';
                }
                masterApis = [
                    loanPurposeFlag,
                    'DMIHLLAP_BRANCH',
                    'DMIHLLAP_INTEREST_TYPE',
                    'DMIHLLAP_PROPERTY_TYPE'
                ];
            } else {
                fieldsForm = coApplicantFields1;
                masterApis = [
                    'DMIHLLAP_RELATIONSHIP',
                    'DMIHLLAP_GENDER',
                    'DMIHLLAP_MARITIAL_STATUS'
                ];
            }
            break;
        case 2:
            if (flag === 'applicant') {
                fieldsForm = applicantFields2;
                masterApis = [
                    'DMIHLLAP_GENDER',
                    'DMIHLLAP_MARITIAL_STATUS',
                    'DMIHLLAP_ADDRESS_PROOF_TYPE',
                    'DMIHLLAP_SECTOR_TYPE'
                ];
            } else {
                fieldsForm = coApplicantFields2;
                masterApis = [
                    'DMIHLLAP_SECTOR_TYPE',
                    'DMIHLLAP_ADDRESS_PROOF_TYPE'
                ];
            }
            break;
        case 3:
            fieldsForm = applicantFields3;
            masterApis = [
                'DMIHLLAP_SEGMENT_TYPE',
                'DMIHLLAP_BUSINESS_VINTAGE_PROOF_TYPE'
            ];
            break;
    }

    return {
        fieldsForm,
        masterApis
    }
}

export const formSchemaConditionally = (flag: string) => {
    const schemas: any = {
        applicantSchema1,
        applicantSchema2,
        applicantSchema3,
        coApplicantSchema1,
        coApplicantSchema2,
        coApplicantSchema3
    };

    return schemas?.[flag];
};

export const bindFieldConditionally = (currentPage: any, data: any, flag: string) => {
    let fieldsList: any = [];
    switch (currentPage) {
        case 1:
            if (flag === 'applicant') {
                fieldsList = [...applicantFields1, ...applicantFields1_1, ...coApplicantCheckbox];
            } else {
                fieldsList = coApplicantFields1;
            }
            break;
        case 2:
            if (flag === 'applicant') {
                fieldsList = applicantFields2;
            } else {
                fieldsList = [...coApplicantFields2, ...coApplicantAddressFields2];
            }
            break;
        case 3:
            if (!data['is_income_earner']) {
                fieldsList = [...applicantFields3];
            } else {
                fieldsList =
                    data['profile_segment_type_name'] === 'Assessed Income' ?
                        [
                            ...applicantFields3,
                            ...applicantFields3_1,
                            ...applicantAssessedIncomeFields3,
                            ...applicantAssessedOrITRIncomeFields3
                        ] :
                        data['profile_segment_type_name'] === 'Regular ITR' ?
                            [
                                ...applicantFields3,
                                ...applicantFields3_1,
                                ...applicantITRIncomeFields3,
                                ...applicantAssessedOrITRIncomeFields3
                            ] : [
                                ...applicantFields3,
                                ...applicantFields3_1,
                                ...applicantCashOrBankIncomeFields3
                            ];
            }
            break;
    }
    return fieldsList;
}