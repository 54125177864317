import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import { Header } from '../../../components/layout/header/Header';
import { useHistory } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { REGEX, StatusNum } from '../../../helpers/constant';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	apiDmaInvite,
	customSuccessfull,
	dmaFlowData,
} from '../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	capFirstLetter,
	disableNumberArrows,
	fetchStateCityByPincode,
} from '../../../helpers/functions';
import { useState } from 'react';
import StateCity from '../../../components/common/StateCity';

const inviteSchema = yup.object().shape({
	first_name: yup
		.string()
		.trim()
		.max(100, 'First Name should not be more than 100')
		.required('First Name is Required.')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
	middle_name: yup
		.string()
		.trim()
		.max(100, 'Middle Name should not be more than 100')
		.notRequired()
		.matches(REGEX.ALLOWONLYCHAR, {
			message: 'Please Enter Valid Middle Name',
			excludeEmptyString: true,
		}),
	last_name: yup
		.string()
		.trim()
		.max(100, 'Last Name should not be more than 100')
		.required('Last Name is Required.')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
	mobile: yup
		.string()
		.length(10, 'Mobile No. Should Be 10 Digits.')
		.required('Mobile is Required.')
		.matches(REGEX.INDIAN_MOBILE_NUMBERS, 'Please Enter a valid Mobile No.'),
	email: yup
		.string()
		.required('Email is Required.')
		.matches(REGEX.EMAIL, 'Please Enter Valid Email.'),
	pincode: yup
		.string()
		.trim()
		.required('Pin Code Number is Required')
		.length(6, 'Pin Code Should Be 6 Digits'),
	state: yup.string().trim().required('State is Required'),
	city: yup.string().trim().required('City is Required'),
});

export default function TeamsDmaInviteMember() {
	const history = useHistory();
	const dmaSelector = useSelector((state: any) => state?.users?.dmaFlow);
	const dispatch: any = useDispatch();
	const { t } = useTranslation();
	const [pinCodeData, setPinCodeData] = useState<any>([]);

	const {
		handleSubmit,
		control,
		trigger,
		setValue,
		getValues,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(inviteSchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const onSubmit = async (data: any) => {
		// const { mobile } = getValues();
		await dispatch(
			apiDmaInvite({
				data: {
					...data,
					first_name: data?.first_name ? capFirstLetter(data?.first_name) : '',
					middle_name: data?.middle_name
						? capFirstLetter(data?.middle_name)
						: '',
					last_name: data?.last_name ? capFirstLetter(data?.last_name) : '',
					mobile: Number(data?.mobile),
				},
			})
		)
			.then(unwrapResult)
			.then(async (result: any) => {
				if (result?.status) {
					await dispatch(
						dmaFlowData({
							...dmaSelector,
							dmaCode: StatusNum.INVITED,
							title: t('Invitees'),
							update: dmaSelector?.update + 1 || 1,
						})
					);

					history.replace('/custom-successfull', {
						type: 'DMAINVITESUCCESS',
						message: `${t('Invitation Sent Successfully')} !`,
						redirectTo: '/invitee-list',
					});

					await dispatch(
						customSuccessfull({
							type: 'DMAINVITESUCCESS',
							message: `${t('Invitation Sent Successfully')} !`,
							redirectTo: '/invitee-list',
						})
					);
				}
			});
	};
	return (
		<IonPage>
			<Header
				needArrow={true}
				backButton={true}
				name={t('Invite Team Member')}
			/>
			<IonContent className='background-web'>
				<div className='bg-web my-md-4'>
					<div className='container-width-md bg-web-white  border-md-radius-10 conatiner-spacing-md'>
						<IonGrid>
							<IonRow>
								<IonCol
									size='12'
									className='my-1'
								>
									<form
										onSubmit={handleSubmit(onSubmit)}
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
												handleSubmit(onSubmit);
											}
										}}
									>
										<Controller
											control={control}
											render={({ field: { onChange, onBlur, name } }) => (
												<CustomInput
													type={'text'}
													placeholder='First Name (as per Aadhaar) *'
													onBlur={onBlur}
													name={name}
													onChange={onChange}
													errors={errors?.first_name?.message}
													value={getValues(name)}
													className={'text-capitalize'}
												/>
											)}
											name={'first_name'}
										/>
										<Controller
											control={control}
											render={({ field: { onChange, onBlur, name } }) => (
												<CustomInput
													type={'text'}
													placeholder='Middle Name (as per Aadhaar)'
													onBlur={onBlur}
													name={name}
													onChange={onChange}
													value={getValues(name)}
													errors={errors?.middle_name?.message}
													className={'text-capitalize'}
												/>
											)}
											name={'middle_name'}
										/>
										<Controller
											control={control}
											render={({ field: { onChange, onBlur, name } }) => (
												<CustomInput
													type={'text'}
													placeholder='Last Name (as per Aadhaar) *'
													onBlur={onBlur}
													name={name}
													onChange={onChange}
													errors={errors?.last_name?.message}
													value={getValues(name)}
													className={'text-capitalize'}
												/>
											)}
											name={'last_name'}
										/>
										<Controller
											control={control}
											render={({ field: { onChange, onBlur, name } }) => (
												<CustomInput
													type={'number'}
													placeholder={t('Mobile Number') + ' *'}
													onBlur={onBlur}
													name={name}
													onChange={onChange}
													errors={errors?.mobile?.message}
													value={getValues(name)}
													onBeforeInput={(evt: any) => {
														//Only numbers accepted on user input
														!/[0-9]/.test(evt.data) && evt.preventDefault();
														//Only accepts 10 characters on user input
														if (evt.target.value.length === 10) {
															evt.preventDefault();
														}
													}}
													onPaste={(e: any) => {
														//Only get numbers on user paste
														e.preventDefault();
														let pasteContent = e.clipboardData.getData('Text');
														if (pasteContent != '') {
															pasteContent = pasteContent.replace(/\D/g, '');
															if (pasteContent.length > 10) {
																pasteContent = pasteContent.slice(0, 10);
															}
														}
														setValue('mobile', pasteContent);
														trigger('mobile');
													}}
													onKeyDown={(e: any) => {
														disableNumberArrows(e);
													}}
													onWheel={(e: any) => e.target.blur()}
												/>
											)}
											name={'mobile'}
										/>
										<Controller
											control={control}
											render={({ field: { onChange, onBlur, name } }) => (
												<CustomInput
													type={'email'}
													placeholder={'Email *'}
													onBlur={onBlur}
													name={name}
													onChange={onChange}
													errors={errors?.email?.message}
													value={getValues(name)}
												/>
											)}
											name={'email'}
										/>
										<Controller
											key={'pincode'}
											control={control}
											render={({ field: { onChange, onBlur, name } }) => (
												<CustomInput
													type={'number'}
													placeholder={t('Pincode *')}
													onBlur={onBlur}
													onChange={(e: any) => {
														if (e.detail.value.length === 6) {
															fetchStateCityByPincode(
																setValue,
																'city',
																'state',
																setPinCodeData,
																dispatch,
																e.detail.value,
																trigger
															);
															// pinCodeAction(e.detail.value);
														} else {
															setValue('state', '');
															setValue('city', '');
															setPinCodeData([]);
														}
														onChange(e);
													}}
													value={getValues(name)}
													name={name}
													// errors={t(t_error[name]?.message as string)}
													onBeforeInput={(evt: any) => {
														!/[0-9]/.test(evt.data) && evt.preventDefault();
													}}
													onKeyDown={(e: any) => {
														disableNumberArrows(e);
													}}
													onWheel={(e: any) => {
														e.target.blur();
													}}
												/>
											)}
											name={'pincode'}
										/>
										<StateCity
											cityInputName='city'
											stateInputName='state'
											control={control}
											setValue={setValue}
											pinCodeData={pinCodeData}
											trigger={trigger}
											getValues={getValues}
										/>
										{/* <CustomTest /> */}
										<div className='proceed-btn py-md-2'>
											<CustomButton
												type='submit'
												disabled={!isValid}
												className='btn-blue'
												expand='block'
												title={t('Invite')}
											/>
										</div>
									</form>
								</IonCol>
							</IonRow>
						</IonGrid>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
}
