export class OccupationDetailsForm  {
  id?: number;
  occupationType: string;
  companyName: string;
  jobDescription: string;
  annualIncome: number;
  totalWorkExp: string;
  profileId?: number;

  constructor(request: OccupationDetailsRequest) {
    this.id = request.id;
    this.occupationType = request.occupation_type;
    this.companyName = request.company_name;
    this.jobDescription = request.job_description;
    this.annualIncome = request.annual_income;
    // this.annualIncome = request.annual_income !== null? request.annual_income.toString():'';
    this.totalWorkExp = request.total_work_exp;
    this.profileId = request.profileId;
  }

};
export class OccupationDetailsRequest {
  id?: number;
  occupation_type: string;
  company_name: string;
  job_description: string;
  annual_income: number;
  total_work_exp: string;
  profileId?: number;

  constructor(form: OccupationDetailsForm) {
    this.id = form.id;
    this.occupation_type = form.occupationType;
    this.company_name = form.companyName;
    this.job_description = form.jobDescription;
    this.annual_income = Number(form.annualIncome);
    this.total_work_exp = form.totalWorkExp;
    this.profileId = form.profileId;
  }
}

export interface OccupationDetailsCompanyRequest {
  searchItem?: any;
  page?: number;
  size?: number;
}
