import { IonContent, IonItem, IonList, IonPage } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Header } from "../../components/layout/header/Header";
import { useLeadDetails } from "../../context/LeadDetailsContext";
import LeadListData from "../../model/lead_details_bean";
import {
  FormatDate,
  maskEmail,
  maskMobile,
  maskPan,
  showToastAlert,
  capFirstLetter,
} from "../../helpers/functions";
// import { CustomButton } from "../../components/common/custom-button/CustomButton";
import useApi from "../../api/useApi";
import { INSURANCE_FETCH_APPLICATION_DETAILS } from "../../helpers/api-endpoints";
import { useBusyLoading } from "../../context/BusyLoadingContext";

const LeadAboutInsurance: React.FC = () => {
  const { t } = useTranslation();
  const { leadData, proinfo } = useLeadDetails();

  console.log("leadData>>", leadData);
  console.log("proinfo>>", proinfo);
  const applicationId = leadData?.id;
  console.log("id>>", leadData?.id);
  const [assuredDetails, setAssuredDetails] = useState<any>();
  const infoApi = useApi<any>(INSURANCE_FETCH_APPLICATION_DETAILS);
  const { setBusyState } = useBusyLoading();
  const [showBasicDetails, setShowBasicDetails] = useState(false);
  const [showOccExiDetails, setShowOccExiDetails] = useState(false);
  const [isSelf, setIsSelf] = useState<boolean>();

  useEffect(() => {
    const payload = {
      applicationId: applicationId,
    };
    infoApi.executeRequest("post", payload, true);
  }, []);

  useEffect(() => {
    setBusyState(infoApi.loading);
    showToastAlert({ type: "error", message: infoApi.error?.message! });
  }, [infoApi.loading, infoApi.error]);

  useEffect(() => {
    if (infoApi.responseData?.code === "200") {
      setAssuredDetails(infoApi?.responseData?.data);
    }
  }, [infoApi?.responseData]);

  useEffect(() => {
    if (assuredDetails?.applicationMaster?.policyProposedFor === "self") {
      setIsSelf(true);
    } else if (
      assuredDetails?.applicationMaster?.policyProposedFor !== "self"
    ) {
      setIsSelf(false);
    }
  }, [assuredDetails, isSelf]);


  useEffect(() => {
    if (isSelf && assuredDetails?.proposer?.basicDetails) {
      const { first_name, last_name, dob, gender, mobile_no, email } =
        assuredDetails.proposer.basicDetails;
      if (first_name && last_name && dob && gender && mobile_no && email) {
        setShowBasicDetails(true);
      } else {
        setShowBasicDetails(false);
      }
    } else if (!isSelf && assuredDetails?.assured?.basicDetails) {
      const { first_name, last_name, dob, gender, mobile_no, email } =
        assuredDetails.assured.basicDetails;
      if (first_name && last_name && dob && gender && mobile_no && email) {
        setShowBasicDetails(true);
      } else {
        setShowBasicDetails(false);
      }
    } else {
      setShowBasicDetails(false);
    }
  }, [assuredDetails, isSelf]);

  useEffect(() => {
    if (isSelf && assuredDetails?.proposer?.occupationDetailsMaster) {
      const {
        occupation_type,
        company_name,
        job_description,
        annual_income,
        total_work_exp,
      } = assuredDetails.proposer.occupationDetailsMaster;
      if (
        occupation_type &&
        company_name &&
        job_description &&
        annual_income &&
        total_work_exp
      ) {
        setShowOccExiDetails(true);
      } else {
        setShowOccExiDetails(false);
      }
    } else if (!isSelf && assuredDetails?.assured?.occupationDetailsMaster) {
      const {
        occupation_type,
        company_name,
        job_description,
        annual_income,
        total_work_exp,
      } = assuredDetails.assured.occupationDetailsMaster;
      if (
        occupation_type &&
        company_name &&
        job_description &&
        annual_income &&
        total_work_exp
      ) {
        setShowOccExiDetails(true);
      } else {
        setShowOccExiDetails(false);
      }
    } else {
      setShowOccExiDetails(false);
    }
  }, [assuredDetails, isSelf]);

  return (
    <IonPage className="ion-scroll">
      <Header
        className={"shadow-sm"}
        type=""
        name={`${capFirstLetter(leadData?.first_name) || "Unknown"}'s Profile`}
        needArrow={true}
        backButton={true}
        style={{ borderBottom: "1px solid #00000029" }}
        // needArrowOnClick={() => {
        // 	leadData?.from === 'nimbuss'
        // 		? history.push('/lead-details/' + leadData.leadId + '/' + token)
        // 		: history.push('/lead-details');
        // }}
      />
      <IonContent className="ion-padding background-web">
        <div className="bg-web">
          <div className="bg-web-white container-width-md px-md-3 border-md-radius-top">
            <IonList>
              <IonItem
                className="ion-no-padding remove-ion-item-inner-padding-end"
                lines="full"
              >
                <div className="d-flex flex-grow-1 justify-content-between">
                  <p className="mb-0 fs-14">{t("Mobile")}</p>
                  <p className="mb-0 fs-14 fw-600">
                    {maskMobile(leadData?.mobile_no) || t("Not available")}
                  </p>
                </div>
              </IonItem>
              <IonItem
                className="ion-no-padding remove-ion-item-inner-padding-end"
                lines="full"
              >
                <div className="d-flex flex-grow-1 justify-content-between">
                  <p className="mb-0 fs-14">{t("Andro Manger")}</p>
                  <p className="mb-0 fs-14 fw-600">
                    {assuredDetails?.proposer?.managerName ||
                      t("Not available")}
                  </p>
                </div>
              </IonItem>
              <IonItem
                className="ion-no-padding remove-ion-item-inner-padding-end"
                lines="full"
              >
                <div className="d-flex flex-grow-1 justify-content-between">
                  <p className="mb-0 fs-14">{t("Created Date")}</p>
                  <p className="mb-0 fs-14 fw-600">
                    {FormatDate(leadData?.createdDate, "dd mmm yyyy") ||
                      t("Not available")}
                  </p>
                </div>
              </IonItem>
              <IonItem
                className="ion-no-padding remove-ion-item-inner-padding-end"
                lines="full"
              >
                <div className="d-flex flex-grow-1 justify-content-between">
                  <p className="mb-0 fs-14">{t("Source")}</p>
                  <p className="mb-0 fs-14 fw-600">
                    {leadData?.lead_status || t("Not available")}
                  </p>
                </div>
              </IonItem>

              {/* ---------------------- Details of Proposer ----------------------------- */}
              <div className="d-flex mt-3 justify-content-between align-items-center">
                <h4 className="fw-bold fs-20">{t("Details of Proposer")}</h4>
              </div>
              <IonItem
                className="ion-no-padding remove-ion-item-inner-padding-end"
                lines="full"
              >
                <div className="d-flex flex-grow-1 justify-content-between">
                  <p className="mb-0 fs-14">{t("Name as per Aadhaar")}</p>
                  <p className="mb-0 fs-14 fw-600">
                    {/* {t('Not available')} */}
                    {assuredDetails?.proposer?.basicDetails?.first_name
                      ? (leadData?.first_name || "") +
                        " " +
                        (assuredDetails?.proposer?.familyDetails
                          ?.father_first_name || "") +
                        " " +
                        (assuredDetails?.proposer?.basicDetails?.last_name ||
                          "")
                      : t("Not available")}
                  </p>
                </div>
              </IonItem>
              <IonItem
                className="ion-no-padding remove-ion-item-inner-padding-end"
                lines="full"
              >
                <div className="d-flex flex-grow-1 justify-content-between">
                  <p className="mb-0 fs-14">{t("Gender")}</p>
                  <p className="mb-0 fs-14 fw-600">
                    {assuredDetails?.proposer?.basicDetails?.gender ||
                      t("Not available")}
                    {/* {t('Not available')} */}
                  </p>
                </div>
              </IonItem>
              <IonItem
                className="ion-no-padding remove-ion-item-inner-padding-end"
                lines="full"
              >
                <div className="d-flex flex-grow-1 justify-content-between">
                  <p className="mb-0 fs-14">{t("Marital Status")}</p>
                  <p className="mb-0 fs-14 fw-600">
                    {assuredDetails?.proposer?.familyDetails?.marital_status ||
                      t("Not available")}
                  </p>
                </div>
              </IonItem>
              <IonItem
                className="ion-no-padding remove-ion-item-inner-padding-end"
                lines="full"
              >
                <div className="d-flex flex-grow-1 justify-content-between">
                  <p className="mb-0 fs-14">{t("Pincode")}</p>
                  <p className="mb-0 fs-14 fw-600">
                    {assuredDetails?.proposer?.addressMaster?.permanent
                      ?.pincode || t("Not available")}
                  </p>
                </div>
              </IonItem>
              <IonItem
                className="ion-no-padding remove-ion-item-inner-padding-end"
                lines="full"
              >
                <div className="d-flex flex-grow-1 justify-content-between">
                  <p className="mb-0 fs-14">{t("City")}</p>
                  <p className="mb-0 fs-14 fw-600">
                    {assuredDetails?.proposer?.addressMaster?.permanent?.city ||
                      t("Not available")}
                  </p>
                </div>
              </IonItem>
              <IonItem
                className="ion-no-padding remove-ion-item-inner-padding-end"
                lines="full"
              >
                <div className="d-flex flex-grow-1 justify-content-between">
                  <p className="mb-0 fs-14">{t("State")}</p>
                  <p className="mb-0 fs-14 fw-600">
                    {assuredDetails?.proposer?.addressMaster?.permanent
                      ?.state || t("Not available")}
                  </p>
                </div>
              </IonItem>
              <IonItem
                className="ion-no-padding remove-ion-item-inner-padding-end"
                lines="full"
              >
                <div className="d-flex flex-grow-1 justify-content-between">
                  <p className="mb-0 fs-14">{t("Policy Proposed for")}</p>
                  <p className="mb-0 fs-14 fw-600">
                    {assuredDetails?.applicationMaster?.policyProposedFor ||
                      t("Not available")}
                  </p>
                </div>
              </IonItem>
              {/* ----------------------/details of proposer--------------------- */}

              {/* ---------------------- Basic Info ----------------------------- */}
              {showBasicDetails && (
                <>
                  <div className="d-flex mt-3 justify-content-between align-items-center">
                    <h4 className="fw-bold fs-20">
                      {t("Basic Details of Assured")}
                    </h4>
                  </div>
                  <IonItem
                    className="ion-no-padding remove-ion-item-inner-padding-end"
                    lines="full"
                  >
                    <div
                      // className={`d-flex flex-grow-1 ${
                      //   leadData?.first_name
                      //     ? leadData?.first_name
                      //         ?.concat(leadData?.middle_name || "")
                      //         .concat(leadData?.last_name || "").length > 17 &&
                      //       "flex-row1"
                      //     : ""
                      // } justify-content-between`}
                      className={`d-flex flex-grow-1 ${
                        (
                          (isSelf
                            ? assuredDetails?.proposer?.basicDetails?.first_name || ""
                            : assuredDetails?.assured?.basicDetails?.first_name || "") +
                          " " +
                          (isSelf
                            ? assuredDetails?.proposer?.basicDetails?.middle_name || ""
                            : assuredDetails?.assured?.basicDetails?.middle_name || "") +
                          " " +
                          (isSelf
                            ? assuredDetails?.proposer?.basicDetails?.last_name || ""
                            : assuredDetails?.assured?.basicDetails?.last_name || "")
                        ).replace(/\s+/g, "").length > 17 && "flex-row1"
                      } justify-content-between`}
                      
                    >
                      <p className="mb-0 fs-14">{t("Full Name")}</p>
                      <p className="mb-0 fs-14 fw-600 text-capitalize">
                        {isSelf
                          ? (assuredDetails?.proposer?.basicDetails
                              ?.first_name || "") +
                            " " +
                            (assuredDetails?.proposer?.basicDetails
                              ?.middle_name || "") +
                            " " +
                            (assuredDetails?.proposer?.basicDetails
                              ?.last_name || "")
                          : (assuredDetails?.assured?.basicDetails
                              ?.first_name || "") +
                              " " +
                              (assuredDetails?.assured?.basicDetails
                                ?.middle_name || "") +
                              " " +
                              (assuredDetails?.assured?.basicDetails
                                ?.last_name || "") || t("Not available")}
                      </p>
                    </div>
                  </IonItem>
                  <IonItem
                    className="ion-no-padding remove-ion-item-inner-padding-end"
                    lines="full"
                  >
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <p className="mb-0 fs-14">{t("Gender")}</p>
                      <p className="mb-0 fs-14 fw-600">
                        {isSelf
                          ? assuredDetails?.proposer?.basicDetails?.gender ||
                            t("Not available")
                          : assuredDetails?.assured?.basicDetails?.gender ||
                            t("Not available")}
                      </p>
                    </div>
                  </IonItem>
                  <IonItem
                    className="ion-no-padding remove-ion-item-inner-padding-end"
                    lines="full"
                  >
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <p className="mb-0 fs-14">{t("Date of Birth")}</p>
                      <p className="mb-0 fs-14 fw-600">
                        {isSelf
                          ? assuredDetails?.proposer?.basicDetails?.dob
                            ? FormatDate(
                                assuredDetails?.proposer?.basicDetails?.dob,
                                "dd mmmm yyyy"
                              )
                            : (t("Not available") as string)
                          : assuredDetails?.assured?.basicDetails?.dob
                          ? FormatDate(
                              assuredDetails?.assured?.basicDetails?.dob,
                              "dd mmmm yyyy"
                            )
                          : (t("Not available") as string)}
                      </p>
                    </div>
                  </IonItem>
                  <IonItem
                    className="ion-no-padding remove-ion-item-inner-padding-end"
                    lines="full"
                  >
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <p className="mb-0 fs-14">{t("Mobile Number")}</p>
                      <p className="mb-0 fs-14 fw-600">
                        {maskMobile(leadData?.mobile_no) || t("Not available")}
                      </p>
                    </div>
                  </IonItem>
                  <IonItem
                    className="ion-no-padding remove-ion-item-inner-padding-end"
                    lines="full"
                  >
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <p className="mb-0 fs-14">{t("Marital Status")}</p>
                      <p className="mb-0 fs-14 fw-600">
                        {isSelf
                          ? assuredDetails?.proposer?.familyDetails
                              ?.marital_status || t("Not available")
                          : assuredDetails?.assured?.familyDetails
                              ?.marital_status || t("Not available")}
                      </p>
                    </div>
                  </IonItem>
                  <IonItem
                    className="ion-no-padding remove-ion-item-inner-padding-end"
                    lines="full"
                  >
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <p className="mb-0 fs-14">{t("Father Name")}</p>
                      <p className="mb-0 fs-14 fw-600">
                        {isSelf
                          ? assuredDetails?.proposer?.familyDetails
                              ?.father_first_name || t("Not available")
                          : assuredDetails?.assured?.familyDetails
                              ?.father_first_name || t("Not available")}
                      </p>
                    </div>
                  </IonItem>
                  <IonItem
                    className="ion-no-padding remove-ion-item-inner-padding-end"
                    lines="full"
                  >
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <p className="mb-0 fs-14">{t("Mother Name")}</p>
                      <p className="mb-0 fs-14 fw-600">
                        {isSelf
                          ? assuredDetails?.proposer?.familyDetails
                              ?.mother_first_name || t("Not available")
                          : assuredDetails?.assured?.familyDetails
                              ?.mother_first_name || t("Not available")}
                      </p>
                    </div>
                  </IonItem>
                  <IonItem
                    className="ion-no-padding remove-ion-item-inner-padding-end"
                    lines="full"
                  >
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <p className="mb-0 fs-14">{t("Spouse Name")}</p>
                      <p className="mb-0 fs-14 fw-600">
                        {isSelf
                          ? assuredDetails?.proposer?.familyDetails
                              ?.spouse_first_name || t("Not available")
                          : assuredDetails?.assured?.familyDetails
                              ?.spouse_first_name || t("Not available")}
                      </p>
                    </div>
                  </IonItem>
                  <IonItem
                    className="ion-no-padding remove-ion-item-inner-padding-end"
                    lines="full"
                  >
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <p className="mb-0 fs-14">{t("Email")}</p>
                      <p className="mb-0 fs-14 fw-600">
                        {isSelf
                          ? maskEmail(
                              assuredDetails?.proposer?.basicDetails?.email
                            ) || t("Not available")
                          : maskEmail(
                              assuredDetails?.assured?.basicDetails?.email
                            ) || t("Not available")}
                      </p>
                    </div>
                  </IonItem>
                  {/* <IonItem
                    className="ion-no-padding remove-ion-item-inner-padding-end"
                    lines="full"
                  >
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <p className="mb-0 fs-14">
                        {t("Current Business Address")}
                      </p>
                      <p className="mb-0 fs-14 fw-600">{t("Not available")}</p>
                    </div>
                  </IonItem> */}
                  <IonItem
                    className="ion-no-padding remove-ion-item-inner-padding-end"
                    lines="full"
                  >
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <p className="mb-0 fs-14">{t("PAN")}</p>
                      <p className="mb-0 fs-14 fw-600">
                        {isSelf
                          ? maskPan(
                              assuredDetails?.proposer?.basicDetails?.pan
                            ) || t("Not available")
                          : maskPan(
                              assuredDetails?.assured?.basicDetails?.pan
                            ) || t("Not available")}
                      </p>
                    </div>
                  </IonItem>
                </>
              )}
              {/* ----------------------/ Basic Info ----------------------------- */}

              {/* **************************Occupation and existing details**************** */}
              {showOccExiDetails && (
                <>
                  <div className="d-flex mt-3 justify-content-between align-items-center">
                    <h4 className="fw-bold fs-20">
                      {t("Occupation Details of Assured")}
                    </h4>
                  </div>
                  <IonItem
                    className="ion-no-padding remove-ion-item-inner-padding-end"
                    lines="full"
                  >
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <p className="mb-0 fs-14">{t("Occupation Type")}</p>
                      <p className="mb-0 fs-14 fw-600">
                        {isSelf
                          ? assuredDetails?.proposer?.occupationDetailsMaster
                              ?.occupation_type || t("Not available")
                          : assuredDetails?.assured?.occupationDetailsMaster
                              ?.occupation_type || t("Not available")}
                      </p>
                    </div>
                  </IonItem>
                  <IonItem
                    className="ion-no-padding remove-ion-item-inner-padding-end"
                    lines="full"
                  >
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <p className="mb-0 fs-14">{t("Company Name")}</p>
                      <p className="mb-0 fs-14 fw-600">
                        {"   "}
                        {isSelf
                          ? assuredDetails?.proposer?.occupationDetailsMaster
                              ?.company_name || t("Not available")
                          : assuredDetails?.assured?.occupationDetailsMaster
                              ?.company_name || t("Not available")}
                      </p>
                    </div>
                  </IonItem>

                  <IonItem
                    className="ion-no-padding remove-ion-item-inner-padding-end"
                    lines="full"
                  >
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <p className="mb-0 fs-14">{t("Designation")}</p>
                      <p className="mb-0 fs-14 fw-600">
                        {isSelf
                          ? assuredDetails?.proposer?.occupationDetailsMaster
                              ?.job_description || t("Not available")
                          : assuredDetails?.assured?.occupationDetailsMaster
                              ?.job_description || t("Not available")}
                      </p>
                    </div>
                  </IonItem>

                  <IonItem
                    className="ion-no-padding remove-ion-item-inner-padding-end"
                    lines="full"
                  >
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <p className="mb-0 fs-14">{t("Annual Income")}</p>
                      <p className="mb-0 fs-14 fw-600">
                        {isSelf
                          ? assuredDetails?.proposer?.occupationDetailsMaster
                              ?.annual_income || t("Not available")
                          : assuredDetails?.assured?.occupationDetailsMaster
                              ?.annual_income || t("Not available")}
                      </p>
                    </div>
                  </IonItem>

                  <IonItem
                    className="ion-no-padding remove-ion-item-inner-padding-end"
                    lines="full"
                  >
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <p className="mb-0 fs-14">{t("Total Experience ")}</p>
                      <p className="mb-0 fs-14 fw-600">
                        {isSelf
                          ? assuredDetails?.proposer?.occupationDetailsMaster
                              ?.total_work_exp || t("Not available")
                          : assuredDetails?.assured?.occupationDetailsMaster
                              ?.total_work_exp || t("Not available")}
                      </p>
                    </div>
                  </IonItem>

                  <IonItem
                    className="ion-no-padding remove-ion-item-inner-padding-end"
                    lines="full"
                  >
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <p className="mb-0 fs-14">
                        {t("Existing Policy Number")}
                      </p>
                      <p className="mb-0 fs-14 fw-600">
                        {isSelf
                          ? assuredDetails?.proposer?.existingPolicyData
                              ?.policyNo || t("Not available")
                          : assuredDetails?.assured?.existingPolicyData
                              ?.policyNo || t("Not available")}
                      </p>
                    </div>
                  </IonItem>

                  <IonItem
                    className="ion-no-padding remove-ion-item-inner-padding-end"
                    lines="full"
                  >
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <p className="mb-0 fs-14">{t("Insurer Name")}</p>
                      <p className="mb-0 fs-14 fw-600">
                        {isSelf
                          ? assuredDetails?.proposer?.existingPolicyData
                              ?.insurerName || t("Not available")
                          : assuredDetails?.assured?.existingPolicyData
                              ?.insurerName || t("Not available")}
                      </p>
                    </div>
                  </IonItem>

                  <IonItem
                    className="ion-no-padding remove-ion-item-inner-padding-end"
                    lines="full"
                  >
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <p className="mb-0 fs-14">{t("Plan Name")}</p>
                      <p className="mb-0 fs-14 fw-600">
                        {isSelf
                          ? assuredDetails?.proposer?.existingPolicyData
                              ?.planName || t("Not available")
                          : assuredDetails?.assured?.existingPolicyData
                              ?.planName || t("Not available")}
                      </p>
                    </div>
                  </IonItem>

                  <IonItem
                    className="ion-no-padding remove-ion-item-inner-padding-end"
                    lines="full"
                  >
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <p className="mb-0 fs-14">{t("Sum Assured")}</p>
                      <p className="mb-0 fs-14 fw-600">
                        {isSelf
                          ? assuredDetails?.proposer?.existingPolicyData
                              ?.sumAssured || t("Not available")
                          : assuredDetails?.assured?.existingPolicyData
                              ?.sumAssured || t("Not available")}
                      </p>
                    </div>
                  </IonItem>

                  <IonItem
                    className="ion-no-padding remove-ion-item-inner-padding-end"
                    lines="full"
                  >
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <p className="mb-0 fs-14">{t("Policy Status")}</p>
                      <p className="mb-0 fs-14 fw-600">
                        {isSelf
                          ? assuredDetails?.proposer?.existingPolicyData
                              ?.policyStatus || t("Not available")
                          : assuredDetails?.assured?.existingPolicyData
                              ?.policyStatus || t("Not available")}
                      </p>
                    </div>
                  </IonItem>

                  <IonItem
                    className="ion-no-padding remove-ion-item-inner-padding-end"
                    lines="full"
                  >
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <p className="mb-0 fs-14">{t("Issued Date")}</p>
                      <p className="mb-0 fs-14 fw-600">
                        {isSelf
                          ? assuredDetails?.proposer?.existingPolicyData
                              ?.issueDate
                            ? FormatDate(
                                assuredDetails?.proposer?.existingPolicyData
                                  ?.issueDate,
                                "dd mmmm yyyy"
                              )
                            : (t("Not available") as string)
                          : assuredDetails?.assured?.existingPolicyData
                              ?.issueDate
                          ? FormatDate(
                              assuredDetails?.assured?.existingPolicyData
                                ?.issueDate,
                              "dd mmmm yyyy"
                            )
                          : (t("Not available") as string)}
                      </p>
                    </div>
                  </IonItem>

                  <IonItem
                    className="ion-no-padding remove-ion-item-inner-padding-end"
                    lines="full"
                  >
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <p className="mb-0 fs-14">
                        {t("Are you of Good Health")}
                      </p>
                      <p className="mb-0 fs-14 fw-600">
                        {isSelf
                          ? assuredDetails?.proposer?.existingPolicyData
                              ?.goodHealth || t("Not available")
                          : assuredDetails?.assured?.existingPolicyData
                              ?.goodHealth || t("Not available")}
                      </p>
                    </div>
                  </IonItem>
                </>
              )}
            </IonList>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LeadAboutInsurance;
