import * as yup from 'yup';
import leadStepInfoFormInputs from './InsuranceLeadStepFour.json';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	IonContent,
	IonFooter,
	IonItem,
	IonLabel,
	IonRadioGroup,
} from '@ionic/react';
import { useHistory } from 'react-router';
import {
	InputFormatCurrencyValue,
	PageExitPopUp,
	sanitizeHtml,
} from '../../../helpers/functions';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import CustomRadioButton from '../../../components/common/custom-radio-button/CustomRadioButton';
import DropDownModal from '../../../components/common/custom-dropdown/DropDownModal';
import {
	leadFlow,
	apiUpdateLeadDetails,
	apiOccupationDropdown,
	apiPolicyDropdown,
	updateLifeAssuredApi,
} from '../../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import CustomPopoverSearch from '../../../components/common/custom-popover/CustomPopoverSearch';
import { getCompanyNameList } from '../../../store/reducers/user/userSlice';
import { PAGINATION_DETAILS, REGEX } from '../../../helpers/constant';

const InsuranceLeadStepFour: React.FC = () => {
	// const [confirmCheck, setConfirmCheck] = useState(false);
	const { t } = useTranslation();
	const history = useHistory();
	const [modalPageClose, setModalPageClose] = useState(false);
	const [occupationApi, setOccupationApi] = useState([]);
	const [policyApi, setPolicyApi] = useState([]);
	const [existing_policy, setExisting_policy] = useState([1]);
	const dispatch: any = useDispatch();
	const leadFlowSelector = useSelector((state: any) => state?.lead);
	const { leadFlowData } = leadFlowSelector;
	const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);
	const userData = useSelector((state: any) => state?.users?.user_details);

	useEffect(() => {
		if (!accordionGroup.current) {
			return;
		}

		accordionGroup.current.value = ['first', 'third'];
	}, []);
	const validationSchema =
		leadFlowData.productFor === 'self' ||
		leadFlowData.leadCurrentStep === 'step6'
			? yup.object().shape({
					profession_type: yup.string().required('Profession is Required'),
					company_name: yup.string().required('Company Name is Required'),
					job_description: yup
						.string()
						.required('Job Description is Required')
						.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
					income: yup
						.number()
						.typeError('Amount should be number')
						.required('Annual Income is Required.'),
					Total_experience: yup
						.string()
						.trim()
						.min(1, 'Total Work Experience is Required')
						.max(2, 'Total Work Experience Should Be 2 Digits')
						.required('Total Work Experience is Required'),
					existing_policy_number_1: yup.string().notRequired(),
					insurer_name_1: yup
						.string()
						.matches(REGEX.ALLOWONLYCHAR, {
							message: 'Please Enter Valid Input',
							excludeEmptyString: true,
						})
						.notRequired(),
					plan_name_1: yup
						.string()
						.matches(REGEX.ALLOWONLYCHAR, {
							message: 'Please Enter Valid Input',
							excludeEmptyString: true,
						})
						.notRequired(),
					sum_assured_1: yup
						.string()
						.matches(REGEX.ALLOWNUMBERS, {
							message: 'Amount should be number',
							excludeEmptyString: true,
						})
						.notRequired(),
					issued_date_1: yup.string().notRequired(),
					policy_state_1: yup.string().notRequired(),
					existing_policy_number_2: yup.string().notRequired(),
					insurer_name_2: yup
						.string()
						.matches(REGEX.ALLOWONLYCHAR, {
							message: 'Please Enter Valid Input',
							excludeEmptyString: true,
						})
						.notRequired(),
					plan_name_2: yup
						.string()
						.matches(REGEX.ALLOWONLYCHAR, {
							message: 'Please Enter Valid Input',
							excludeEmptyString: true,
						})
						.notRequired(),
					sum_assured_2: yup
						.string()
						.matches(REGEX.ALLOWNUMBERS, {
							message: 'Amount should be number',
							excludeEmptyString: true,
						})
						.notRequired(),
					issued_date_2: yup.string().notRequired(),
					policy_state_2: yup.string().notRequired(),
					existing_policy_number_3: yup.string().notRequired(),
					insurer_name_3: yup
						.string()
						.notRequired()
						.matches(REGEX.ALLOWONLYCHAR, {
							message: 'Please Enter Valid Input',
							excludeEmptyString: true,
						}),
					plan_name_3: yup
						.string()
						.notRequired()
						.matches(REGEX.ALLOWONLYCHAR, {
							message: 'Please Enter Valid Input',
							excludeEmptyString: true,
						})
						.notRequired(),
					sum_assured_3: yup
						.string()
						.matches(REGEX.ALLOWNUMBERS, {
							message: 'Amount should be number',
							excludeEmptyString: true,
						})
						.notRequired(),
					issued_date_3: yup.string().notRequired(),
					policy_state_3: yup.string().notRequired(),
					existing_policy_number_4: yup.string().notRequired(),
					insurer_name_4: yup
						.string()
						.matches(REGEX.ALLOWONLYCHAR, {
							message: 'Please Enter Valid Input',
							excludeEmptyString: true,
						})
						.notRequired(),
					plan_name_4: yup
						.string()
						.matches(REGEX.ALLOWONLYCHAR, {
							message: 'Please Enter Valid Input',
							excludeEmptyString: true,
						})
						.notRequired(),
					sum_assured_4: yup
						.string()
						.matches(REGEX.ALLOWNUMBERS, {
							message: 'Amount should be number',
							excludeEmptyString: true,
						})
						.notRequired(),
					issued_date_4: yup.string().notRequired(),
					policy_state_4: yup.string().notRequired(),
					existing_policy_number_5: yup.string().notRequired(),
					insurer_name_5: yup
						.string()
						.matches(REGEX.ALLOWONLYCHAR, {
							message: 'Please Enter Valid Input',
							excludeEmptyString: true,
						})
						.notRequired(),
					plan_name_5: yup
						.string()
						.matches(REGEX.ALLOWONLYCHAR, {
							message: 'Please Enter Valid Input',
							excludeEmptyString: true,
						})
						.notRequired(),
					sum_assured_5: yup
						.string()
						.matches(REGEX.ALLOWNUMBERS, {
							message: 'Amount should be number',
							excludeEmptyString: true,
						})
						.notRequired(),
					issued_date_5: yup.string().notRequired(),
					policy_state_5: yup.string().notRequired(),
			  })
			: yup.object().shape({
					profession_type: yup.string().required('Profession is Required'),
					company_name: yup.string().required('Company Name is Required'),
					job_description: yup.string().required('Job Description is Required'),
					income: yup
						.number()
						.typeError('Amount should be number')
						.required('Annual Income is Required.'),
					Total_experience: yup
						.string()
						.trim()
						.min(1, 'Total Work Experience is Required')
						.max(2, 'Total Work Experience Should Be 2 Digits')
						.required('Total Work Experience is Required'),
					sum_assured: yup
						.string()
						.matches(REGEX.ALLOWNUMBERS, {
							message: 'Amount should be number',
							excludeEmptyString: true,
						})
						.notRequired(),
			  });

	const [list, setList] = useState([]);
	const [openCompanyModal, setOpenCompanyModal] = useState(false);
	const [healthCheck, setHealthCheck] = useState(undefined);

	const getCompanyList = async (searchValue: any) => {
		await dispatch(
			getCompanyNameList({
				param: `?searchItem=${sanitizeHtml(searchValue)}&page=${
					PAGINATION_DETAILS.CURRENT_PAGE
				}&size=${PAGINATION_DETAILS.PAGE_SIZE}`,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setList(response.companyListData);
				}
			});
	};

	const radioData = [
		{
			id: 1,
			name: 'health',
			label: 'Yes',
			value: 'yes',
		},
		{
			id: 2,
			name: 'health',
			label: 'No',
			value: 'no',
		},
	];

	const {
		handleSubmit,
		control,
		setValue,
		watch,
		// register,
		trigger,
		getValues,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			profession_type: '',
			pan_no: '',
			company_name: '',
			job_description: '',
			Total_experience: '',
			existing_policy_number_1: '',
			insurer_name_1: '',
			plan_name_1: '',
			sum_assured_1: '',
			issued_date_1: '',
			policy_state_1: '',
			existing_policy_number_2: '',
			insurer_name_2: '',
			plan_name_2: '',
			sum_assured_2: '',
			issued_date_2: '',
			policy_state_2: '',
			existing_policy_number_3: '',
			insurer_name_3: '',
			plan_name_3: '',
			sum_assured_3: '',
			issued_date_3: '',
			policy_state_3: '',
			existing_policy_number_4: '',
			insurer_name_4: '',
			plan_name_4: '',
			sum_assured_4: '',
			issued_date_4: '',
			policy_state_4: '',
			existing_policy_number_5: '',
			insurer_name_5: '',
			plan_name_5: '',
			sum_assured_5: '',
			issued_date_5: '',
			policy_state_5: '',
			income: '',
			health: '',
			health_condition: '',
		},
	});

	const clearValues = (val: any) => {
		existing_policy.map((item, i) => {
			const index: any = i + 1;
			if (index >= val) {
				const epn: any = (n: any) =>
					!n
						? 'existing_policy_number_' + index
						: 'existing_policy_number_' + (index + 1);
				setValue(epn(), getValues(epn(true)));
				setValue(epn(true), '');

				const i_n: any = (n: any) =>
					!n ? 'insurer_name_' + index : 'insurer_name_' + (index + 1);
				setValue(i_n(), getValues(i_n(true)));
				setValue(i_n(true), '');

				const p_n: any = (n: any) =>
					!n ? 'plan_name_' + index : 'plan_name_' + (index + 1);
				setValue(p_n(), getValues(p_n(true)));
				setValue(p_n(true), '');

				const s_u: any = (n: any) =>
					!n ? 'sum_assured_' + index : 'sum_assured_' + (index + 1);

				setValue(s_u(), getValues(s_u(true)));
				setValue(s_u(true), '');

				const p_s: any = (n: any) =>
					!n ? 'policy_state_' + index : 'policy_state_' + (index + 1);
				setValue(p_s(), getValues(p_s(true)));
				setValue(p_s(true), '');

				const i_d: any = (n: any) =>
					!n ? 'issued_date_' + index : 'issued_date_' + (index + 1);
				setValue(i_d(), getValues(i_d(true)));
				setValue(i_d(true), '');
			}
		});
	};

	let disableCheck = {
		policy_1: true,
		policy_2: true,
		policy_3: true,
		policy_4: true,
		policy_5: true,
	};

	let policyValidation: any = {};
	let p_1 = {};
	let p_2 = {};
	let p_3 = {};
	let p_4 = {};
	let p_5 = {};

	const data: any = watch();

	for (const val in data) {
		if (val.includes('_1')) {
			p_1 = { ...p_1, [val]: data?.[val] };
			policyValidation = { ...policyValidation, policy_1: p_1 };
		} else if (val.includes('_2')) {
			p_2 = { ...p_2, [val]: data?.[val] };
			policyValidation = { ...policyValidation, policy_2: p_2 };
		} else if (val.includes('_3')) {
			p_3 = { ...p_3, [val]: data?.[val] };
			policyValidation = { ...policyValidation, policy_3: p_3 };
		} else if (val.includes('_4')) {
			p_4 = { ...p_4, [val]: data?.[val] };
			policyValidation = { ...policyValidation, policy_4: p_4 };
		} else if (val.includes('_5')) {
			p_5 = { ...p_5, [val]: data?.[val] };
			policyValidation = { ...policyValidation, policy_5: p_5 };
		}
	}

	const validatePolicyObject = (policyObject: any) => {
		// Check if all keys have a value
		const allKeysHaveValue = Object.values(policyObject).every(
			(value) => value !== ''
		);
		// Check if all keys are empty
		const allKeysAreEmpty = Object.values(policyObject).every(
			(value) => value === ''
		);
		// Return true if all keys have a value or if all keys are empty
		if (allKeysHaveValue || allKeysAreEmpty) {
			return true;
		}
		// Return false if some keys have a value and some don't
		return false;
	};

	for (const key in policyValidation) {
		disableCheck = {
			...disableCheck,
			[key]: validatePolicyObject(policyValidation?.[key]),
		};
	}

	useEffect(() => {
		getPolicyAndOccupation();
	}, []);

	const getPolicyAndOccupation = async () => {
		await dispatch(apiOccupationDropdown())
			.then(unwrapResult)
			.then((result: any) => {
				setOccupationApi(result.data);
			});
		await dispatch(apiPolicyDropdown())
			.then(unwrapResult)
			.then((result: any) => {
				setPolicyApi(result.data);
			});
	};

	const onSubmit = async (data: any) => {
		const helper_array = [
			{
				sum_assured: data?.sum_assured_1,
				existing_policy_number: data?.existing_policy_number_1,
				insurer_name: data?.insurer_name_1,
				plan_name: data?.plan_name_1,
				issued_date: data?.issued_date_1,
				policy_state: data?.policy_state_1,
			},
			{
				sum_assured: data?.sum_assured_2,
				existing_policy_number: data?.existing_policy_number_2,
				insurer_name: data?.insurer_name_2,
				plan_name: data?.plan_name_2,
				issued_date: data?.issued_date_2,
				policy_state: data?.policy_state_2,
			},
			{
				sum_assured: data?.sum_assured_3,
				existing_policy_number: data?.existing_policy_number_3,
				insurer_name: data?.insurer_name_3,
				plan_name: data?.plan_name_3,
				issued_date: data?.issued_date_3,
				policy_state: data?.policy_state_3,
			},
			{
				sum_assured: data?.sum_assured_4,
				existing_policy_number: data?.existing_policy_number_4,
				insurer_name: data?.insurer_name_4,
				plan_name: data?.plan_name_4,
				issued_date: data?.issued_date_4,
				policy_state: data?.policy_state_4,
			},
			{
				sum_assured: data?.sum_assured_5,
				existing_policy_number: data?.existing_policy_number_5,
				insurer_name: data?.insurer_name_5,
				plan_name: data?.plan_name_5,
				issued_date: data?.issued_date_5,
				policy_state: data?.policy_state_5,
			},
		];

		let existing_policy_data: object = {};
		helper_array.map((item, i) => {
			if (
				item?.sum_assured &&
				item?.existing_policy_number &&
				item?.insurer_name &&
				item?.plan_name &&
				item?.policy_state &&
				item?.issued_date
			) {
				existing_policy_data = {
					...existing_policy_data,
					[`policy_${i + 1}`]: item,
				};
			}
		});

		await dispatch(
			(leadFlowData.leadCurrentStep === 'step4' ||
				leadFlowData.leadCurrentStep === 'step3') &&
				leadFlowData.productFor != 'self'
				? apiUpdateLeadDetails({
						param: '/' + leadFlowData?.leadId,
						data: {
							loginUserId: userData?.id,
							leadId: leadFlowData?.leadId,
							occupation_type: data?.profession_type,
							product: leadFlowData?.productType,
							company_name: data?.company_name,
							job_description: data?.job_description,
							annual_income: data?.income,
							total_work_exp: data?.Total_experience,
							sum_assured: data?.sum_assured_1,
							existing_policy_data: {
								policy_1: {
									sum_assured: data?.sum_assured_1,
								},
							},
							health_details:
								data?.health === 'yes' ? data?.health : data?.health_condition,
						},
				  })
				: updateLifeAssuredApi({
						param: '/' + leadFlowData?.leadId,
						data: {
							leadId: leadFlowData?.leadId,
							occupation_type: data?.profession_type,
							product: leadFlowData?.productType,
							company_name: data?.company_name,
							job_description: data?.job_description,
							annual_income: data?.income,
							total_work_exp: data?.Total_experience,
							existing_policy_data: existing_policy_data,
							health_details:
								data?.health === 'yes' ? data?.health : data?.health_condition,
						},
				  })
		)
			.then(unwrapResult)
			.then(async (result: any) => {
				if (result?.status) {
					leadFlowData.productFor === 'self'
						? history.push('/lenders-list', { leadID: leadFlowData?.leadId })
						: leadFlowData.leadCurrentStep === 'step6'
						? history.push('/lenders-list', { leadID: leadFlowData?.leadId })
						: await dispatch(
								leadFlow({
									...leadFlowSelector?.leadFlowData,
									leadCurrentStep: 'step5',
								})
						  );
				}
			});
	};

	const inputTemplate = (name: any, type: any, placeholder: any) => {
		const errorText: any = errors && Object.keys(errors).length > 0 && errors;

		return name.includes('sum_assured') ? (
			<Controller
				control={control}
				render={({ field: { onChange, onBlur, name } }) => (
					<CustomInput
						type={type}
						label={t(placeholder)}
						onBlur={onBlur}
						name={name}
						onChange={(e: any) => {
							e = isNaN(e.detail.value.replaceAll(',', ''))
								? e
								: Number(e.detail.value.replace(/,/g, ''));
							onChange(e);
						}}
						value={InputFormatCurrencyValue(getValues(name))}
						errors={errorText[name]?.message}
					/>
				)}
				name={name}
			/>
		) : type === 'dropdown' ? (
			<Controller
				control={control}
				render={({ field: { onChange, onBlur, name } }) => (
					<DropDownModal
						label={placeholder}
						data={
							name.includes('profession_type')
								? occupationApi
								: name.includes('policy_state') && policyApi
						}
						name={name}
						onChange={onChange}
						value={getValues(name)}
						onBlur={onBlur}
						errors={errorText[name]?.message}
					/>
				)}
				name={name}
			/>
		) : (
			<>
				<Controller
					control={control}
					render={({ field: { onChange, onBlur, name } }) => (
						<CustomInput
							type={type}
							name={name}
							onFocus={() =>
								name.includes('company_name') ? setOpenCompanyModal(true) : ''
							}
							onChange={(e: any) => {
								if (name.includes('company_name')) {
									setOpenCompanyModal(true);
								} else if (
									name.includes('sum_assured') ||
									name.includes('income')
								) {
									e = isNaN(e.detail.value.replaceAll(',', ''))
										? e
										: Number(e.detail.value.replace(/,/g, ''));
								}
								onChange(e);
							}}
							label={placeholder}
							onBlur={onBlur}
							value={
								name.includes('sum_assured') || name.includes('income')
									? getValues(name)
										? InputFormatCurrencyValue(getValues(name))
										: getValues(name)
									: getValues(name)
							}
							errors={errorText[name]?.message}
						/>
					)}
					name={name}
				/>
				{name.includes('company_name') && (
					<CustomPopoverSearch
						searchApi={getCompanyList}
						list={list}
						setList={setList}
						isOpen={openCompanyModal}
						searchListModalClose={setOpenCompanyModal}
						title='Company Names'
						searchbarPlaceholder='Search Company by “Name”'
						setValue={(val: any) => {
							setValue('company_name', val);
							trigger('company_name');
						}}
					/>
				)}
			</>
		);
	};

	return (
		<>
			<IonContent className='ion-padding insurance-lead-background'>
				<div className=' very-small-container bg-web h-90'>
					<div className='bg-web-white container-width-md p-md-5 border-radius-10'>
						<form onSubmit={handleSubmit(onSubmit)}>
							{leadStepInfoFormInputs?.[
								leadFlowData.productFor === 'self'
									? 'self'
									: leadFlowData.leadCurrentStep === 'step6'
									? 'self'
									: 'other'
							].map(({ name, type, placeholder, heading, data }: any) => {
								if (name === 'existing_policy_number') {
									return (
										<>
											<h4 className='fw-bold fs-14 heading-color'>
												{t(heading)}
											</h4>
											{inputTemplate(name, type, placeholder)}
										</>
									);
								} else if (name === 'sum_assured') {
									return (
										<>
											<h4 className='fw-bold fs-14 heading-color'>
												{t(heading)}
											</h4>
											{inputTemplate(name, type, placeholder)}
										</>
									);
								}
								return type === 'dropdown' ? (
									<>{inputTemplate(name, type, placeholder)}</>
								) : type === 'accordion' ? (
									<>
										<h4 className='fw-bold fs-14 heading-color flex'>
											{t(heading)}
										</h4>

										{existing_policy.map((e_p_data, index) => (
											<div className=' border p-2 rounded-3 position-relative mt-3 px-3'>
												<div
													onClick={() => {
														if (existing_policy.length !== 1) {
															clearValues(index + 1);
															setExisting_policy((data) =>
																data.filter((item) => item !== e_p_data)
															);
														}
													}}
													className='position-absolute cursor-pointer'
													style={{
														top: '-10px',
														right: '-10px',
														lineHeight: '0',
													}}
												>
													<img
														src='/images/icons/border-close.svg'
														alt='close button'
													/>
												</div>

												{data &&
													data.length &&
													data.map((item: any, i: any) => {
														const inputName = `${item.name}_${index + 1}`;
														return (
															<>
																{i === 0 && (
																	<div className='flex'>
																		<IonLabel className='fs-14 text-grey'>
																			Policy {index + 1}
																		</IonLabel>
																	</div>
																)}
																{inputTemplate(
																	inputName,
																	item?.type,
																	item?.placeholder
																)}
															</>
														);
													})}
											</div>
										))}
										{existing_policy.length < 5 && (
											<div
												style={{
													display: 'flex',
													justifyContent: 'center',
												}}
											>
												<span
													className='fs-12 text-center text-primary mt-3'
													onClick={() => {
														existing_policy.length < 5 &&
															setExisting_policy((data) => [
																...data,
																Date.now(),
															]);
													}}
												>
													Add more policy
												</span>
											</div>
										)}
									</>
								) : (
									<>{inputTemplate(name, type, placeholder)}</>
								);
							})}

							<IonRadioGroup
								onIonChange={({ detail: { value } }: any) => {
									setHealthCheck(value);
									setValue('health', value);
								}}
							>
								<div className='row border-0 mt-3'>
									<h6 className='heading-color'>{t('Health Details')}</h6>
									<h6 className='heading-color'>
										{t('Are you of Good Health?')}
									</h6>
									{radioData &&
										radioData?.map((item, index) => {
											return (
												<div className='col-6'>
													<IonItem
														key={index}
														className='ion-no-padding'
													>
														<CustomRadioButton
															justify='start'
															labelPlacement='end'
															name={item.name}
															value={item.value}
															label={item.label}
														/>
													</IonItem>
												</div>
											);
										})}
								</div>
							</IonRadioGroup>

							{healthCheck === 'no' && (
								<Controller
									control={control}
									render={({ field: { onChange, onBlur } }) => (
										<CustomInput
											type={'text'}
											label={t('Explain your health condition')}
											onBlur={onBlur}
											name={'health_condition'}
											onChange={onChange}
											value={getValues('health_condition')}
										/>
									)}
									name={'health_condition'}
								/>
							)}

							<div
								className={`ion-padding d-none d-md-block`}
								style={{
									backgroundColor: '#fff',

									zIndex: 2,
								}}
							>
								{leadFlowData?.from !== 'customer' ? (
									<CustomButton
										className='w-100 fw-bold dashboard-add-lead-button'
										expand='full'
										fill='outline'
										title={t('Continue Later')}
										onClick={() => setModalPageClose(true)}
									/>
								) : (
									''
								)}
								<div className='proceed-btn'>
									<CustomButton
										type='submit'
										className='w-100 fw-bold dashboard-add-lead-button'
										expand='full'
										title={t('Proceed')}
										disabled={
											isValid &&
											disableCheck?.policy_1 &&
											disableCheck?.policy_2 &&
											disableCheck?.policy_3 &&
											disableCheck?.policy_4 &&
											disableCheck?.policy_5
												? false
												: true
										}
										onClick={handleSubmit(onSubmit)}
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</IonContent>
			<IonFooter>
				<div
					className={`ion-padding d-block d-md-none`}
					style={{
						backgroundColor: '#fff',

						zIndex: 2,
					}}
				>
					{leadFlowData?.from !== 'customer' ? (
						<CustomButton
							className='w-100 fw-bold dashboard-add-lead-button'
							expand='full'
							fill='outline'
							title={t('Continue Later')}
							onClick={() => setModalPageClose(true)}
						/>
					) : (
						''
					)}
					<div className='proceed-btn'>
						<CustomButton
							type='submit'
							className='w-100 fw-bold dashboard-add-lead-button'
							expand='full'
							title={t('Proceed')}
							disabled={
								isValid &&
								disableCheck?.policy_1 &&
								disableCheck?.policy_2 &&
								disableCheck?.policy_3 &&
								disableCheck?.policy_4 &&
								disableCheck?.policy_5
									? false
									: true
							}
							onClick={handleSubmit(onSubmit)}
						/>
					</div>
				</div>
			</IonFooter>
			<CustomModal
				id='custommodal'
				isOpen={modalPageClose}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setModalPageClose(false)}
				breakpoints={[]}
				className='leadStep'
				modalContent={PageExitPopUp(
					() => {
						setModalPageClose(false);
					},
					() => {
						setModalPageClose(false);
						history.push('/dashboard/home');
					},
					'Cancel',
					'Continue Later',
					'lead-pop-up',
					'Are You Sure You Want to Exit?',
					`${t('You can continue later from where you left off.')}`
				)}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};

export default InsuranceLeadStepFour;
