/* eslint-disable @typescript-eslint/no-explicit-any */
import { SmsRetriever } from '@awesome-cordova-plugins/sms-retriever';

import {
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
	IonRow,
	isPlatform,
	useIonRouter,
} from '@ionic/react';
import { Device } from '@capacitor/device';
import React, { useEffect, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { STATUS_CODE } from '../../../helpers/constant';
import { Header } from '../../../components/layout/header/Header';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import {
	apiLogin,
	dmaFlowData,
	teamDmaVerifyOtp,
} from '../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	deviceId,
	otpScreenShowPage,
} from '../../../store/reducers/app/appSlice';
import { Otptimer } from 'otp-timer-ts';
import { eventAttributes, notifyHandler } from '../../../helpers/functions';
import { NotifyVisitors } from 'capacitor-plugin-notifyvisitors';
interface OtpVerificationInterface {
	length?: number;
	isLeadSteps?: any;
	setCurrentStep?: any;
	currentStep?: string;
	routerLink?: any;
	leadMobile?: any;

	isPage?: any;
	setTitle?: any;
}

export const VerifyDmaWithOtpVerification: React.FC<
	OtpVerificationInterface
> = ({
	length = 4,
	isLeadSteps = false,
	setCurrentStep = false,

	leadMobile,

	isPage = true,
	setTitle,
}) => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [nextButtonActive, setNextButtonActive] = useState<boolean>(true);
	const history = useHistory();
	const router = useIonRouter();
	const [otp, setOtp] = useState<any>(new Array(4).fill(''));
	const [error, setError] = useState<boolean>(false);
	const dmaSelector = useSelector((state: any) => state?.users);
	const appData = useSelector((state: any) => state?.app);
	// Notify Visitors
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const userName = useSelector(
		(state: any) => state?.users?.homepage?.profile?.name
	);
	const userMobile = useSelector(
		(state: any) => state?.users?.user_details?.mobile
	);

	const entityType = useSelector(
		(state: any) => state?.users?.user_details?.entityType
	);
	const userLang = useSelector(
		(state: any) => state?.oneTimeScreen?.language?.name
	);

	useEffect(() => {
		isPage === false && isLeadSteps === 'step5' && setTitle('OTP Verification');
		logDeviceInfo();
		if (inputRefs?.current?.length > 0) {
			const value: any = inputRefs.current ? inputRefs.current : false;
			if (value && Array.isArray(value)) {
				setTimeout(() => value?.[0].focus(), 700);
			}
		}
	}, [window?.location?.pathname]);

	const userAttributes = {
		attributes: {
			name: userName,
			id: userId,
			mobile: userMobile,
			user_language: userLang,
			entityType:
				entityType === 'Individual'
					? 'DMA'
					: entityType === 'BTM'
					? 'employee'
					: entityType,
		},
	};
	useEffect(() => {
		if (isPlatform('android') || isPlatform('ios')) {
			notifyHandler('event', eventAttributes('add_DMA_otp_page'));
			notifyHandler('user', userAttributes.attributes);
			const inAppData = {
				tokens: { name: userAttributes },
				customRules: { banerrType: 'add dma otp page' },
			};
			NotifyVisitors.show(inAppData);
		} else {
			(window as any).nv('event', 'add_DMA_otp_page', 10, 1);
			(window as any).nv('user', userId, userAttributes.attributes);
		}
	}, []);

	const handleChange = async (element: any | undefined, index: number) => {
		if (isNaN(element.value)) return false;
		setOtp([
			...otp.map((d: any, idx: any) => (idx === index ? element.value : d)),
		]);
		if (index <= 3) {
			element.value && inputRefs.current[index + 1]?.focus();
		}
		if (index >= 0) {
			setError(false);
		}
	};
	const logDeviceInfo = async () => {
		const info: any = await Device.getId();
		await dispatch(deviceId(info?.identifier));
	};
	const handleSubmit = async () => {
		if (otp[0] !== '' && otp[1] !== '' && otp[2] !== '' && otp[3] !== '') {
			const newOtp = otp.join('').toString();

			await dispatch(
				teamDmaVerifyOtp({
					data: {
						mobile: Number(dmaSelector?.dmaFlow?.dmaMobile),
						otp: newOtp,
					},
				})
			)
				.then(unwrapResult)
				.then(async (result: any) => {
					await dispatch(otpScreenShowPage(true));
					if (result?.status) {
						setOtp(new Array(4).fill(''));
						dispatch(
							dmaFlowData({
								dmaId: result?.id,
								dmaMobile: result?.mobile,
							})
						);
						router.push('/register-yourself');
					}

					setError(false);
				});
		} else {
			setError(true);
		}
	};

	const resendOtp = async () => {
		inputRefs.current[0]?.focus();
		setOtp(new Array(4).fill(''));
		let sms_code: any;
		if (isPlatform('capacitor')) {
			await SmsRetriever.getAppHash()
				.then((res: any) => {
					sms_code = res;
				})
				.catch((error: any) => console.error(error));
		}
		await dispatch(
			apiLogin({
				data: {
					mobile: Number(dmaSelector?.dmaFlow?.dmaMobile),
					reLogin: true,
					sms_hc: sms_code,
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.code === STATUS_CODE.SIGNIN_SUCCESS) {
					// showToastAlert({ type: 'success', message: 'OTP Resent' });
				}
			});
	};

	const handleKeyDown = (index: number, e: any) => {
		if (e.key === 'Backspace' && index > 0) {
			e.target.value == '' && inputRefs.current[index - 1]?.focus();
		}
		if (otp.length === 3) {
			setNextButtonActive(false);
		} else {
			setNextButtonActive(true);
		}
	};
	useEffect(() => {
		SmsRetriever.startWatching()
			.then((res: any) => {
				if (res?.Message) {
					const otpregex = new RegExp(/^[0-9]{4}$/);
					const msgArray = res?.Message.split(' ');
					let getOtp: any;
					msgArray.map((i: any) => {
						if (otpregex.test(i)) {
							getOtp = i;
						}
					});
					const otp1 = getOtp.split('').map((i: any) => {
						return i;
					});
					setOtp(otp1);
				}
			})
			.catch((error: any) => console.error(error));
	}, []);
	const showPage = appData?.otpScreenShowPage
		? appData?.otpScreenShowPage
		: isPage;

	return (
		<>
			{showPage ? (
				<IonPage className='otp-verification background-web'>
					<Header
						name={t('Add DMA')}
						backButton={true}
						needArrow={true}
					/>

					<IonContent className=' background-web'>
						<div className='py-md-3 very-small-container bg-web h-90'>
							<div className='border-md-radius-10 opacity-1 bg-web-white container-width-md pt-3'>
								<div
									style={{ background: '#EBEBEB' }}
									className='p-3 fs-16 fw-600'
								>
									{t("Verify DMA's Mobile Number")}
								</div>
								<div className='ion-padding'>
									<IonGrid>
										<IonCol>
											<div className='ion-no-margin description'>
												<span
													className=''
													style={{ color: '#A0A4B3' }}
												>
													{t('Enter the OTP we have sent on your')}
													<br />
													{t('Mobile No.')} +91 XXXXXX
													{String(dmaSelector?.dmaFlow?.dmaMobile)?.slice(6)}.
												</span>
												<span
													className='cursor-pointer text-primary'
													onClick={() => {
														setOtp(new Array(4).fill(''));
														history.push('/add-dma', {
															userMobile: dmaSelector?.dmaFlow?.dmaMobile,
														});
														// router.goBack();
													}}
												>
													<b> {t('Change Number')}</b>
												</span>
											</div>
										</IonCol>
									</IonGrid>

									<IonGrid className='otp-input-box'>
										<IonRow>
											{[...Array(length)].map((item, index) => {
												return (
													<IonCol
														size='3'
														key={index}
													>
														<input
															inputMode='numeric'
															pattern='[0-9]*'
															max='1'
															id={'otp' + index}
															ref={(ref) => (inputRefs.current[index] = ref)}
															value={otp[index] || ''}
															onChange={(e: any) =>
																handleChange(e.target, index)
															}
															onKeyDown={(e) => handleKeyDown(index, e)}
															onBeforeInput={(evt: any) => {
																!/[0-9]/.test(evt.data) && evt.preventDefault();
															}}
															onWheel={(e: any) => e.target.blur()}
															maxLength={1}
															className='form-control text-center'
														></input>
													</IonCol>
												);
											})}
										</IonRow>
										{error && (
											<p className='error fs-12 text-danger ms-2 mb-0'>
												{t('Please Enter OTP')}
											</p>
										)}
									</IonGrid>
									<div className='pt-4'>
										{/* for login */}
										<CustomButton
											title={t('Verify')}
											className='btn-blue'
											expand='block'
											onClick={handleSubmit}
										/>
										<div className='ion-no-margin pt-3 '>
											{t('Didn’t receive OTP?') + ' '}
											<div className='d-inline-block'>
												{/* <span
										className={` fw-bold ${timer !== 0 ? 'disabled' : ''}`}
										onClick={() => (timer !== 0 ? '' : resendOtp())}
									>
										{t('Resend OTP')}{' '}
									</span>
									{timer !== 0 ? 'in ' + timer + ' secs' : ''} */}

												<Otptimer
													minutes={0}
													seconds={59}
													onResend={() => {
														resendOtp();
													}}
													text={t('Resend OTP in').toString()}
													buttonText={t('Resend OTP').toString()}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</IonContent>
				</IonPage>
			) : (
				<div className='otp-verification ion-padding'>
					<IonGrid>
						<IonRow>
							<IonCol>
								{isLeadSteps === 'step3' ? (
									<div className='text-grey'>
										{t(
											`Please enter the OTP we have sent to Lead's Mobile No.`
										)}{' '}
										+91 XXXXXX
										{String(leadMobile)?.slice(6)}.{' '}
										<span
											className='cursor-pointer text-primary'
											onClick={() => {
												setCurrentStep('step1');
											}}
										>
											<b>{t('Change Number')}</b>
										</span>
									</div>
								) : isLeadSteps === 'step5' ? (
									<div className='text-grey'>
										{t(
											'Please enter the OTP for Bureau Report we have sent on Mobile No.'
										)}
										+91 XXXXXX{String(leadMobile)?.slice(6)}.
									</div>
								) : (
									<div className=''>
										<h2 className='ion-no-margin'>
											{t('We have sent you')}
											<br />
											{t('an OTP')}
										</h2>
										<div className='ion-no-margin description'>
											<span className='text-grey'>
												{t('Enter the OTP we have sent on your')}
												<br />
												{t('Mobile No.')} +91 XXXXXX
												{String(dmaSelector?.dmaFlow?.dmaMobile)?.slice(6)}.
											</span>
											<span
												className='cursor-pointer text-primary'
												onClick={() => {
													router.goBack();
												}}
											>
												<b> {t('Change Number')}</b>
											</span>
										</div>
									</div>
								)}
							</IonCol>
						</IonRow>
					</IonGrid>
					<IonGrid className=''>
						<IonRow>
							{[...Array(length)].map((item, index) => {
								return (
									<IonCol key={index}>
										<input
											inputMode='numeric'
											pattern='[0-9]*'
											max='1'
											id={'otp' + index}
											ref={(ref) => (inputRefs.current[index] = ref)}
											value={otp[index] || ''}
											onChange={(e: any) => handleChange(e.target, index)}
											onKeyDown={(e) => handleKeyDown(index, e)}
											maxLength={1}
											className='form-control text-center'
										></input>
									</IonCol>
								);
							})}
						</IonRow>
						{error && (
							<p className='error fs-12 text-danger ms-2 mb-0'>
								{t('Please Enter OTP')}
							</p>
						)}
					</IonGrid>
					<div
						className={`${
							['step3', 'step5'].includes(isLeadSteps) ? 'pt-2' : 'verify-btn'
						}`}
					>
						{!isLeadSteps && (
							// for login
							<CustomButton
								title={t('Verify')}
								expand='block'
								onClick={handleSubmit}
							/>
						)}
						<div className='ion-no-margin pt-3'>
							{t('Didn’t receive OTP?')}{' '}
							<div className='d-inline-block'>
								{/* <span
									className={` fw-bold ${timer !== 0 ? 'disabled' : ''}`}
									onClick={() => (timer !== 0 ? '' : resendOtp())}
								>
									{' '}
									{t('Resend OTP')}
								</span>{' '}
								{timer !== 0 ? 'in ' + timer + ' secs' : ''} */}
								<Otptimer
									minutes={0}
									seconds={59}
									onResend={() => {
										resendOtp();
									}}
									text={t('Resend OTP in').toString()}
									buttonText={t('Resend OTP').toString()}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
