import { IonContent, IonFooter } from '@ionic/react';
import React from 'react';
import { Header } from '../layout/header/Header';
import { CustomButton } from './custom-button/CustomButton';
import { useHistory } from 'react-router';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
interface PrivacyPolicy {
	headerTitle: string;
	termsPara: string;
	afterAcceptRedirect: string;
	needFooter: boolean;
}
export const PrivacyPolicy: React.FC<PrivacyPolicy> = ({
	headerTitle,
	termsPara,
	afterAcceptRedirect,
	needFooter,
}) => {
	const history = useHistory();

	return (
		<>
			<Header
				name={headerTitle}
				backButton={true}
				backButtonText=''
				needArrow={true}
				style={{ borderBottom: '1px solid #00000029' }}
				titleClassName='fw-600 text-black'
				needArrowOnClick={() => {
					history.push('/dashboard/home');
				}}
			/>
			<IonContent
				className=''
				scrollY={false}
				scrollX={false}
			>
				<div className='bg-web py-md-3 h-100'>
					<div className='border-md-radius-10 py-md-4 container-width-md bg-web-white iframe-wrapper h-100'>
						{termsPara && (
							<iframe
								src={termsPara}
							/>
						)}
					</div>
				</div>
			</IonContent>

			{needFooter ? (
				<IonFooter className='bg-web-white'>
					<div className='ion-margin '>
						<CustomButton
							routerDirection='root'
							routerLink={afterAcceptRedirect}
							expand='block'
							className={'btn-blue w-25-md'}
							title='Agree & Continue'
						/>
					</div>
				</IonFooter>
			) : (
				<div className='py-1'></div>
			)}
		</>
	);
};
