import {
	IonPage,
	IonContent,
	useIonViewWillEnter,
	IonLabel,
} from '@ionic/react';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Header } from '../../../components/layout/header/Header';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { UserInfoTab } from './UserDetailsTabs/UserInfoTab';
import { PanCardTab } from './UserDetailsTabs/PanCardTab';
import { AadhaarTab } from './UserDetailsTabs/AadhaarTab';
import { PhotoTab } from './UserDetailsTabs/PhotoTab';
import { GstTab } from './UserDetailsTabs/GstTab';
import { AddressProofTab } from './UserDetailsTabs/AddressProof';
import { BankTab } from './UserDetailsTabs/BankTab';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import './DmaUserDetails.scss';
import CustomNote from '../../../components/common/custom-note/CustomNote';
import { StatusNum } from '../../../helpers/constant';
import { CustomCheckbox } from '../../../components/common/custom-checkbox/CustomCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import {
	partnerUserVerification,
	partnerUserVerificationDetail,
	verifyBankUserId,
} from '../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { sanitizeHtml } from '../../../helpers/functions';

export const DmaUserDetails: React.FC = () => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const history = useHistory();
	const [userId, setUserId] = useState(0);
	const [tabslist, setTabsList] = useState<any>([]);
	const [showTab, setShowTab] = useState('');
	const [tabsCount, setTabsCount] = useState(0);
	const [showTabIndex, setShowTabIndex] = useState<any>('');
	const [showRemarkModal, setShowRemarkModal] = useState<boolean>(false);
	const [userDetails, setUserDetails] = useState<any>();
	const dmaSelector = useSelector((state: any) => state?.users);
	const [showPennyDropLoader, setShowPennyDropLoader] =
		useState<boolean>(false);
	const [showPennyDropFailed, setShowPennyDropFailed] =
		useState<boolean>(false);
	const [showActionButtons, setShowActionButtons] = useState<any>();
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	const tabsPanelRef = useRef<any>(null);

	const updateUserApproval = async () => {
		await dispatch(
			partnerUserVerification({
				param: userId,
				data: {
					Type: tabslist[showTabIndex]?.name,
					approve: 'yes',
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					if (showTabIndex < tabsCount) {
						if (showTabIndex > 1) {
							tabsPanelRef.current.scrollBy({
								top: 0,
								left: 90 * showTabIndex,
								behavior: 'smooth',
							});
						}
						setShowTabIndex(showTabIndex + 1);
						setShowTab(tabslist[showTabIndex + 1]?.name);
						getUserDetailsInfo(
							dmaSelector?.dmaFlow?.dmaId,
							tabslist[showTabIndex + 1]?.name
						);
					} else {
						userVerificationDone();
					}
				}
			});
	};

	const verifyPennyDrop = async () => {
		setShowPennyDropLoader(true);
		setShowPennyDropFailed(false);
		await dispatch(verifyBankUserId({ param: userId }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					getUserDetailsInfo(userId, 'Bank');
					setTimeout(() => {
						setShowPennyDropLoader(false);
					}, 2000);
				} else {
					setShowPennyDropLoader(false);
					setShowPennyDropFailed(true);
				}
			});
	};

	const getUserDetailsInfo = async (userId: number, detailsType: string) => {
		await dispatch(
			partnerUserVerificationDetail({ param: userId + '/' + detailsType })
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setUserDetails(result?.data);
				}
			});
	};

	const nextStep = () => {
		if (showTabIndex > 1) {
			tabsPanelRef.current.scrollBy({
				top: 0,
				left: 90 * showTabIndex,
				behavior: 'smooth',
			});
		}
		if (showTabIndex < tabsCount) {
			setShowTabIndex(showTabIndex + 1);
			setShowTab(tabslist[showTabIndex + 1]?.name);
			getUserDetailsInfo(
				dmaSelector?.dmaFlow?.dmaId,
				tabslist[showTabIndex + 1]?.name
			);
		} else {
			userVerificationDone();
		}
	};

	const tabClick = (tabIndex: any) => {
		if (tabIndex !== showTabIndex) {
			setShowTabIndex(tabIndex);
			setShowTab(tabslist[tabIndex]?.name);
			getUserDetailsInfo(dmaSelector?.dmaFlow?.dmaId, tabslist[tabIndex]?.name);
			showActionButtons === StatusNum.REWORK &&
				userDetails?.approve === 'rework_required' &&
				reset();
		}
	};

	const userVerificationDone = () => {
		history.replace('/dma-verification-status');
	};

	const approveUserDetail = () => {
		if (showTabIndex <= tabsCount) {
			updateUserApproval();
		}
	};

	const validationSchemaMark = yup.object().shape({
		markRework: yup
			.bool()
			.required('You need to mark for rework confirmation')
			.oneOf([true], 'You need to mark for rework confirmation'),
	});

	const {
		reset,
		handleSubmit,
		control,
		setValue,
		// trigger,
		getValues,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(validationSchemaMark),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			markRework: false,
		},
	});

	const onSubmit = async () => {
		await dispatch(
			partnerUserVerification({
				param: userId,
				data: {
					Type: tabslist[showTabIndex]?.name,
					approve: 'reworked',
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setValue('markRework', false);
					nextStep();
				}
			});
	};

	useIonViewWillEnter(() => {
		//useEffect(() => {
		setUserId(dmaSelector?.dmaFlow?.dmaId);
		setTabsList(dmaSelector?.dmaFlow?.user_detail_tabs);
		setShowTab(dmaSelector?.dmaFlow?.show_tab);
		setTabsCount(dmaSelector?.dmaFlow?.user_detail_tabs.length - 1);
		setShowTabIndex(dmaSelector?.dmaFlow?.show_tab_index);
		setShowActionButtons(dmaSelector?.dmaFlow?.dmaCode);
		getUserDetailsInfo(
			dmaSelector?.dmaFlow?.dmaId,
			dmaSelector?.dmaFlow?.show_tab
		);
		if (dmaSelector?.dmaFlow?.show_tab_index > 1) {
			setTimeout(() => {
				tabsPanelRef.current.scrollBy({
					top: 0,
					left: 100 * dmaSelector?.dmaFlow?.show_tab_index,
					behavior: 'smooth',
				});
			}, 100);
		} else {
			tabsPanelRef.current.scrollLeft = 0;
		}
	}, []);

	return (
		<IonPage className='bg-web-white'>
			<Header
				needArrow={true}
				backButton={true}
				name={t('')}
			/>
			<IonContent
				className='position-relative background-web'
				style={{
					height: 'calc(100vh - 113px)',
					overflowX: 'hidden',
				}}
			>
				<div
					ref={tabsPanelRef}
					className='user-detail-tab scroll-navbar bg-white border-bottom ion-padding-horizontal d-flex gap-4 position-sticky'
					style={{ top: 0, zIndex: 11 }}
				>
					{tabslist?.map((item: any, index: any) => {
						return (
							<div
								key={index}
								className={`text-nowrap pt-2 pb-3 cursor-pointer ${
									showTab === item?.name ? 'fw-600 activeTab' : 'heading-color'
								}`}
								onClick={() => tabClick(index)}
							>
								{t(item?.display_name)}
							</div>
						);
					})}
				</div>
				<div className='bg-web'>
					<div className='container-width-md my-md-3 conainer-height-web bg-web-white border-md-radius-10 opacity-1 px-md-2 pt-2 '>
						{userDetails?.remark &&
						showPennyDropLoader === false &&
						showPennyDropFailed === false ? (
							<CustomNote
								{...{
									className: 'rounded-0 py-3',
									header: '',
									icon: '/images/icons/alert-yellow-icon.svg',
									content: userDetails?.remark,
								}}
							/>
						) : (
							''
						)}

						{(() => {
							switch (showTab?.toLowerCase()) {
								case 'userdetail':
									return (
										<UserInfoTab
											userDetails={userDetails}
											userId={userId}
											listStatus={showActionButtons}
											getUserDetailsInfo={getUserDetailsInfo}
										/>
									);
								case 'pan':
									return (
										<PanCardTab
											userDetails={userDetails}
											userId={userId}
											listStatus={showActionButtons}
										/>
									);
								case 'aadhaar':
									return (
										<AadhaarTab
											userDetails={userDetails}
											userId={userId}
											listStatus={showActionButtons}
										/>
									);
								case 'photo':
									return (
										<PhotoTab
											userDetails={userDetails}
											userId={userId}
											listStatus={showActionButtons}
										/>
									);
								case 'gst':
									return (
										<GstTab
											userDetails={userDetails}
											userId={userId}
											listStatus={showActionButtons}
										/>
									);
								case 'adressproof':
								case 'addressproof':
									return (
										<AddressProofTab
											userDetails={userDetails}
											userId={userId}
											listStatus={showActionButtons}
										/>
									);
								case 'bank':
									return (
										<BankTab
											userDetails={userDetails}
											userId={userId}
											listStatus={showActionButtons}
											showPennyDropLoader={showPennyDropLoader}
											showPennyDropFailed={showPennyDropFailed}
											verifyPennyDrop={verifyPennyDrop}
											setShowRemarkModal={setShowRemarkModal}
										/>
									);
								default:
									return null;
							}
						})()}
					</div>
				</div>
			</IonContent>
			{showActionButtons !== StatusNum.UNDERPROCESS &&
			showActionButtons !== StatusNum.REWORK ? (
				<>
					{showPennyDropLoader === false && showPennyDropFailed === false ? (
						<>
							{userDetails?.approve === 'yes' ||
							userDetails?.approve === 'rework_required' ? (
								<>
									<div className='d-none d-md-block'>
										{userDetails?.approve === 'yes' && (
											<div className=' d-flex align-items-center justify-content-center success-bg-color ion-padding'>
												<img
													className='me-2 pe-1'
													src='images/icons/verification-green-tick-icon.svg'
													alt='verified tick'
												/>
												{t('Approved')}
											</div>
										)}
										<div className='d-flex  align-items-center justify-content-center gap-2 ion-padding flex-column bg-web-white'>
											<CustomButton
												className={'w-25 btn-blue fw-600'}
												title={t('Next')}
												onClick={() => nextStep()}
											/>
										</div>
									</div>
									<div className='d-block d-md-none'>
										{userDetails?.approve === 'yes' && (
											<div className=' d-flex align-items-center success-bg-color ion-padding'>
												<img
													className='me-2 pe-1'
													src='images/icons/verification-green-tick-icon.svg'
													alt='verified tick'
												/>
												{t('Approved')}
											</div>
										)}
										<div className='d-flex gap-2 ion-padding flex-column'>
											<CustomButton
												className={'w-100 btn-blue fw-600'}
												title={t('Next')}
												onClick={() => nextStep()}
											/>
										</div>
									</div>
								</>
							) : (
								<>
									{showTab.toLowerCase() === 'bank' &&
									(userDetails?.is_sucessful === 0 ||
										userDetails?.is_sucessful === false) ? (
										<>
											<div className='d-flex align-items-center justify-content-center gap-2 ion-padding penny-drop-comment'>
												{t(
													'To verify Bank details proceed with initiate Penny Drop'
												)}
											</div>
											<div className='d-none d-md-block'>
												<div className=' d-flex align-items-center justify-content-center  gap-2 ion-padding flex-column bg-web-white'>
													<CustomButton
														className={'w-25 btn-blue fw-600'}
														title={t('Initiate Penny Drop')}
														onClick={() => verifyPennyDrop()}
													/>
												</div>
											</div>
											<div className=' d-block d-md-none d-flex gap-2 ion-padding flex-column bg-web-white'>
												<CustomButton
													className={'w-100 btn-blue fw-600'}
													title={t('Initiate Penny Drop')}
													onClick={() => verifyPennyDrop()}
												/>
											</div>
										</>
									) : (
										<>
											<div className='d-block d-md-none'>
												<div className='d-flex gap-2 ion-padding flex-column'>
													<CustomButton
														onClick={() => setShowRemarkModal(true)}
														className={'w-100 btn-blue fw-600'}
														fill='outline'
														title={t('Rework Required')}
													/>
													<CustomButton
														className={'w-100 btn-blue fw-600'}
														title={t('Approve')}
														onClick={() => approveUserDetail()}
													/>
												</div>
											</div>
											<div className='d-none d-md-block bg-web-white'>
												<div className='d-flex align-items-center justify-content-center gap-2 ion-padding flex-row'>
													<CustomButton
														onClick={() => setShowRemarkModal(true)}
														className={'w-25 btn-blue fw-600'}
														fill='outline'
														title={t('Rework Required')}
													/>
													<CustomButton
														className={'w-25 btn-blue fw-600'}
														title={t('Approve')}
														onClick={() => approveUserDetail()}
													/>
												</div>
											</div>
										</>
									)}
								</>
							)}
						</>
					) : (
						''
					)}
				</>
			) : (
				''
			)}

			{showActionButtons === StatusNum.REWORK ? (
				userDetails?.approve === 'rework_required' ? (
					<div className=' text-md-center bg-web-white'>
						<div className='position-relative'>
							<Controller
								control={control}
								name={'markRework'}
								render={({ field: { onChange, onBlur, name } }) => (
									<CustomCheckbox
										isBackGround={true}
										name={name}
										labelPlacement='end'
										isChecked={getValues('markRework')}
										value={getValues('markRework')}
										onChange={(event: any) => {
											onChange(event);
											setValue('markRework', event.detail.checked);
										}}
										onBlur={onBlur}
										className={`ion-padding-horizontal py-2 ${
											errors[name] ? 'ion-checkbox-inValid' : ''
										}`}
										errorText={errors[name]?.message}
										errorTextClass='position-absolute error-text-position-rework-mark'
									>
										<IonLabel className='text-wrap'>
											{t(`Mark this rework as completed`)}
										</IonLabel>
									</CustomCheckbox>
								)}
							></Controller>
						</div>
						<div className='d-flex align-items-center justify-content-center'>
							<CustomButton
								type='submit'
								className='ion-margin-vertical d-none d-md-block w-25 btn-blue ion-padding-horizontal bg-web-white'
								title={t('Proceed')}
								disabled={!isValid}
								onClick={handleSubmit(onSubmit)}
							/>
						</div>
						<CustomButton
							type='submit'
							className='ion-margin-vertical d-block d-md-none w-100 btn-blue ion-padding-horizontal bg-web-white'
							title={t('Proceed')}
							disabled={!isValid}
							onClick={handleSubmit(onSubmit)}
						/>
					</div>
				) : (
					<>
						<div className='w-25-md m-auto d-block d-md-none text-aligh-center'>
							<div className=' d-flex align-items-center justify-content-center success-bg-color ion-padding'>
								<img
									className='me-2 pe-1'
									src='images/icons/verification-green-tick-icon.svg'
									alt='verified tick'
								/>
								{t('Rework Completed')}
							</div>
							<div className='d-flex gap-2 ion-padding flex-column'>
								<CustomButton
									className={'w-100 btn-blue fw-600'}
									title={t('Next')}
									onClick={() => nextStep()}
								/>
							</div>
						</div>
						<div className='d-none d-md-block'>
							<div className=' d-flex align-items-center justify-content-center success-bg-color ion-padding'>
								<img
									className='me-2 pe-1'
									src='images/icons/verification-green-tick-icon.svg'
									alt='verified tick'
								/>
								{t('Rework Completed')}
							</div>
							<div className='d-flex align-items-center justify-content-center gap-2 ion-padding flex-column'>
								<CustomButton
									className={'w-25 btn-blue fw-600'}
									title={t('Next')}
									onClick={() => nextStep()}
								/>
							</div>
						</div>
					</>
				)
			) : (
				''
			)}

			<CustomModal
				id='customModalRework'
				isOpen={showRemarkModal}
				modalTitle={t('Rework Required')}
				initialBreakpoint={isWeb ? 1 : 0.45}
				handleClose={() => setShowRemarkModal(false)}
				breakpoints={[0, 1]}
				className='side-modal'
				modalContent={
					<ReworkModal
						{...{
							userId,
							tab: tabslist[showTabIndex]?.name,
							setShowRemarkModal,
							nextStep,
						}}
					/>
				}
				needCross={false}
				needArrow={false}
			></CustomModal>
		</IonPage>
	);
};

const validationSchema = yup.object().shape({
	rework_msg: yup.string().required('Remark is Required.').trim(),
});

const ReworkModal = ({ userId, tab, setShowRemarkModal, nextStep }: any) => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const {
		handleSubmit,
		control,
		// trigger,
		getValues,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			rework_msg: '',
		},
	});

	const submit = async (data: any) => {
		await dispatch(
			partnerUserVerification({
				param: userId,
				data: {
					Type: tab,
					remark: sanitizeHtml(data.rework_msg),
					approve: 'rework_required',
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setShowRemarkModal(false);
					nextStep();
				}
			});
	};

	return (
		<div className='px-3 py-4 h-100-web'>
			<h4 className='fs-18 fw-600'>{t('Rework Required')}</h4>
			<form
				onSubmit={handleSubmit(submit)}
				className='h-80-web '
			>
				<div className='h-100-web'>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, name } }) => (
							<CustomInput
								type={'text'}
								label={`${t('Add Comment')}. *`}
								onBlur={onBlur}
								// onChange={onChange}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									const { value } = e.target;
									if (value?.length <= 100) {
										onChange(e);
									} else {
										e.target.value = value.slice(0, 100);
										onChange(e);
									}
								}}
								value={getValues(name)}
								name={name}
								errors={t(errors['rework_msg']?.message as string)}
							/>
						)}
						name={'rework_msg'}
					/>
				</div>
				<div className='d-flex gap-2 flex-column'>
					<CustomButton
						type='button'
						className='w-100 btn-primary-outline'
						fill='outline'
						title={t('Cancel')}
						onClick={() => setShowRemarkModal(false)}
					/>
					<CustomButton
						type='submit'
						className='w-100 btn-blue'
						disabled={!isValid}
						title={t('Submit')}
					/>
				</div>
			</form>
		</div>
	);
};
