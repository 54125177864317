import { IonContent, IonItem, IonList, IonPage } from '@ionic/react';
import { Header } from '../../components/layout/header/Header';
import { FormatDate } from '../../helpers/functions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const LeadDisposition: React.FC = () => {
	const leadSelector = useSelector((state: any) => state?.lead?.infoActivity);
	const { t } = useTranslation();
	return (
		<IonPage>
			<Header
				name={t('Activity')}
				backButton={true}
				needArrow={true}
			/>
			<IonContent className='ion-padding'>
				<IonList>
					{leadSelector?.data?.map((item: any, index: number) => {
						return (
							<IonItem
								key={index}
								className='ion-no-padding'
								lines='full'
							>
								<div className='m-3 ms-0'>
									<h6 className='fw-bold fs-16 fs-500'>
										{item?.Comments || 'NA'}
									</h6>
									<div className='mt-2 d-flex justify-content-start gap-3'>
										<p className='mb-0 fs-12'>
											{FormatDate(item?.Date, 'dd mmmm yyyy')}
										</p>
										<p className='mb-0 fs-12'>{item?.Time}</p>
										<p className='mb-0 fs-12'>By {item?.created_by_name}</p>
									</div>
									<div className='mt-2 d-flex justify-content-between'>
										<p className='mb-0 fs-14'>Application ID #{item?.id}</p>
									</div>
								</div>
							</IonItem>
						);
					})}
				</IonList>
			</IonContent>
		</IonPage>
	);
};
