import { IonContent, IonGrid, IonPage } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';

import {
	getCity,
	// getLenderRM,
	// getLenderSpcfInfo,
	getRMManagerByCity,
	lenderSpfDetailsFlow,
	selectedLender,
	updateRMManager,
} from '../../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';

import '../../otp-screen/OtpVerification.scss';
import { Header } from '../../../components/layout/header/Header';

import CustomDropDown from '../../../components/common/custom-dropdown/CustomDropDown';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
interface OtpVerificationInterface {
	length?: number;
}

const rmSchema = yup.object().shape({
	City: yup.string().required('City is Required.'),
	RMManager: yup.string().required('RM Manager is Required.'),
});

export const RMSelectionForm: React.FC<OtpVerificationInterface> = () => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const history = useHistory();
	const location = useLocation<any>();
	const leadData = useSelector((state: any) => state?.lead?.leadFlowData);
	const lenderData = useSelector(
		(state: any) => state?.lead?.selectedLender?.[0]
	);

	const [city, setCity] = useState<any>([]);
	const [rm, setRm] = useState<any>([]);
	const [rmPresent, setRmPresent] = useState<any>(false);

	const {
		handleSubmit,
		// control,
		setValue,
		// trigger,
		reset,
		// watch,
		formState: { errors },
		getValues,
	}: any = useForm({
		resolver: yupResolver(rmSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			City: '',
			RMManager: '',
			emp_id: '',
			emp_mobile: '',
			emp_branch: '',
			branch_code: '',
		},
	});

	useEffect(() => {
		if (location.pathname !== '/rm-selection-form') {
			return;
		}
		dispatch(
			updateRMManager({
				param:
					'?' + `leadApplicationId=${lenderData?.id || leadData?.lead_app_id}`,
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result.isRmPresent) {
					setRmPresent(result.isRmPresent);
					reset({
						City: result.data.rm_branch,
						RMManager: result.data.rm_name,
						emp_id: result.data.rm_id,
					});
				} else {
					setRmPresent(false);
					reset({
						emp_id: '',
						emp_mobile: '',
						emp_branch: '',
					});
				}
			});
	}, [location.pathname]);

	useEffect(() => {
		if (location.pathname !== '/rm-selection-form') {
			return;
		}
		dispatch(getCity())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status_code == 200) {
					const ids = result?.data.map(({ city }: any) => city);
					const filtered = result?.data.filter(
						({ city }: any, index: any) => !ids.includes(city, index + 1)
					);

					const arrData: any = filtered.map((obj: any) => {
						obj['value'] = obj['city'];
						delete obj['city'];
						return obj;
					});
					setCity(arrData);
				}
			});

		// if (lenderData?.lender_name == 'LENDINGKART Finance Ltd.')
		// 	dispatch(getLenderRM())
		// 		.then(unwrapResult)
		// 		.then((result: any) => {
		// 			console.log(result, 'RM REsult');
		// 		});
	}, [location.pathname]);

	useEffect(() => {
		if (location.pathname !== '/rm-selection-form') {
			return;
		}

		getValues('City') &&
			dispatch(
				getRMManagerByCity({
					data: {
						product_cat_id: leadData?.product_category_id,
						category_name: leadData?.category_name,
						lender: lenderData?.lender_name,
						city: getValues('City'),
					},
				})
			)
				.then(unwrapResult)
				.then((result: any) => {
					if (result?.status_code == 200) {
						const arrData: any = result?.data.map((obj: any) => {
							obj['value'] = obj['rm_name'];
							obj['id'] = obj['emp_id'];
							delete obj['rm_name'];
							delete obj['emp_id'];
							return obj;
						});
						setRm(arrData);
					}
				});
	}, [getValues('City')]);

	const onSubmit = async (data: any) => {
		await dispatch(
			updateRMManager({
				param:
					'?' + `leadApplicationId=${lenderData?.id || leadData?.lead_app_id}`,
				data: {
					rm_name: data.RMManager,
					rmId: data.emp_id,
					city: data.City,
					category_name: leadData?.category_name,
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					dispatch(
						selectedLender([
							{
								...lenderData,
								rm_branch: data.emp_branch,
								rm_id: data.emp_id,
								rm_name: data.RMManager,
							},
						])
					);
					// history.push('/lender-specific-info');
					if (
						lenderData?.lender_name == 'Piramal Capital'
					) {
						history.push('/bank-online-form');
						setValue('RMManager', '');
						setValue('City', '');
						setValue('emp_id', '');
						setValue('emp_mobile', '');
						setValue('emp_branch', '');
						setCity([]);
						setRm([]);
					} else {
						dispatch(
							lenderSpfDetailsFlow({
								step: 'step1',
								backButton: true,
								needArrow: true,
								needCross: false,
								rmName: data.RMManager,
								rmId: data.emp_id,
								rmBranch: data.emp_branch,
								rmBranchCode: data.branch_code,
								rmCity: data.City,
							})
						);
						setValue('RMManager', '');
						setValue('City', '');
						setValue('emp_id', '');
						setValue('emp_mobile', '');
						setValue('emp_branch', '');
						setCity([]);
						setRm([]);
						history.push('/lead-lender-specific-form-steps');
					}
				}
			});
	};

	return (
		<IonPage>
			<Header
				name={t('RM Selection')}
				backButton={true}
				needArrow={true}
				style={{ borderBottom: '1px solid #00000029' }}
			/>

			<IonContent className='otp-verification'>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className='select-product small-container h-100 bg-web'>
						<div className='bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
							<IonGrid className='otp-input-box'>
								<div className='m-2'>
									{/* <IonLabel className='mb-4'>{t('City')} *</IonLabel> */}
									<CustomDropDown
										{...{
											isCheckbox: false,
											title: rmPresent
												? getValues('City')
												: t('Sourcing City *'),
											setData: (data: any) => {
												setCity(data);
												setValue(
													'City',
													data
														?.filter(({ checked }: any) => checked && checked)
														.map(({ value }: any) => value)
														.toString()
												);
											},
											data: city,
											placeholder: t('Search'),
											isSearch: true,
											modalTitle: t('Select City'),
											listTitle: t('Cities'),
											disable: rmPresent,
										}}
									/>
									{errors?.City && (
										<div className='input-bottom ps-3 pt-1'>
											<div className='error-text sc-ion-input-md'>
												{t(errors?.City?.message || '')}
											</div>
										</div>
									)}
								</div>
								<div className='m-2'>
									{/* <IonLabel className='mb-4'>{t('RM Manager')} *</IonLabel> */}
									<CustomDropDown
										{...{
											isCheckbox: false,
											title: rmPresent
												? getValues('RMManager')
												: t('RM Manager *'),
											setData: (data: any) => {
												setRm(data);
												setValue(
													'RMManager',
													data
														?.filter(({ checked }: any) => checked && checked)
														.map(({ value }: any) => value)
														.toString()
												);
												setValue(
													'emp_id',
													data
														?.filter(({ checked }: any) => checked && checked)
														.map(({ id }: any) => id)
														.toString()
												);
												setValue(
													'emp_mobile',
													data
														?.filter(({ checked }: any) => checked && checked)
														.map(({ mobile }: any) => mobile)
														.toString()
												);
												setValue(
													'emp_branch',
													data
														?.filter(({ checked }: any) => checked && checked)
														.map(({ branch }: any) => branch)
														.toString()
												);
												setValue(
													'branch_code',
													data
														?.filter(({ checked }: any) => checked && checked)
														.map(({ branch_code }: any) => branch_code)
														.toString()
												);
											},

											data: rm,
											placeholder: t('Search'),
											isSearch: true,
											modalTitle: t('Select RM Manager'),
											disable: rmPresent,
										}}
									/>
									{errors?.RMManager && (
										<div className='input-bottom ps-3 pt-1'>
											<div className='error-text sc-ion-input-md'>
												{t(errors?.RMManager?.message || '')}
											</div>
										</div>
									)}
									<div className='d-flex'>
										{getValues('emp_id') ? (
											<span className=' fs-14 m-2 primary-text-color sc-ion-input-md'>
												{`Employee Id: ${getValues('emp_id')}`}
											</span>
										) : (
											''
										)}
										{getValues('emp_mobile') ? (
											<span className=' fs-14 m-2 primary-text-color sc-ion-input-md'>
												{`Mobile: ${getValues('emp_mobile')}`}
											</span>
										) : (
											''
										)}
										{getValues('emp_branch') ? (
											<span className=' fs-14 m-2 primary-text-color sc-ion-input-md'>
												{`Branch: ${getValues('emp_branch')}`}
											</span>
										) : (
											''
										)}
									</div>
								</div>
							</IonGrid>
							<div className={'pt-2'}>
								<CustomButton
									title={t('Submit')}
									expand='block'
									onClick={handleSubmit(onSubmit)}
									// disabled={rmPresent}
								/>
							</div>
						</div>
					</div>
				</form>
			</IonContent>
		</IonPage>
	);
};
