import * as Yup from 'yup';
import { REGEX } from '../../../helpers/constant';

export const MoneyViewFormValidationSchema = Yup.object({
	net_monthly_income: Yup.string()
		.required('Net Monthly Income is a required field')
		.matches(REGEX.ALLOWNUMBERS, 'Net Monthly Income should be number')
		.matches(
			REGEX.GREATER_THAN_ZERO,
			'Net Monthly Income should be greater than zero'
		),
});
