import {
	IonButtons,
	IonChip,
	IonCol,
	IonContent,
	IonFooter,
	IonImg,
	IonPage,
	IonRow,
} from '@ionic/react';
import { Header } from '../../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import {
	PageExitPopUp,
	ShareData,
	setBankIcon,
} from '../../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import CustomNote from '../../../components/common/custom-note/CustomNote';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import {
	activeLender,
	addLendersList,
	addMoreLenders,
	getLeadFlowUrl,
	getSelectedLendersList,
	leadFlow,
	lenderListData,
	removeSelectedLendersList,
} from '../../../store/reducers/lead/leadSlice';
import { LEAD_STATUS } from '../../../helpers/constant';
import CustomAllocateManagers from '../../../components/common/CustomAllocateManagers';

export const SelectedLendersList: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const [openModal, setOpenModal] = useState(false);
	const [removeLender, setRemoveLender] = useState({});
	const dispatch = useDispatch();
	const [showAssignModal, setShowAssignModal] = useState<boolean>(false);
	const [showPageExitPopup, setShowPageExitPopup] = useState(false);
	const [lenderList, setLenderList] = useState([]);
	const leadInfo = useSelector((state: any) => state.lead.leadFlowData);
	const leadLenders: any = useSelector((state: any) => state.lead);
	const [url, setUrl] = useState('');
	const dmaSelector = useSelector((state: any) => state?.users);
	const [isProceedActive, setIsProceedActive] = useState(false);

	useEffect(() => {
		if (location.pathname !== '/selected-lender-list') {
			return;
		}
		getSelectedLenderList();
	}, [location.pathname]);

	const getSelectedLenderList = async () => {
		await dispatch(
			getSelectedLendersList({
				param: '/' + leadInfo?.leadId,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setIsProceedActive(response.data?.length ? false : true);
					setLenderList(response.data);
				} else {
					setIsProceedActive(true);
				}
				dispatch(getLeadFlowUrl())
					.then(unwrapResult)
					.then((result: any) => {
						if (result?.status) {
							setUrl(result?.url);
						}
					});
			});
	};

	const removedLenderList = async (lenders: any) => {
		await dispatch(
			removeSelectedLendersList({
				data: { lenders },
				param: '/' + leadInfo?.leadId,
			})
		)
			.then(unwrapResult)
			.then(async (response: any) => {
				if (response.status) {
					await dispatch(
						leadFlow({
							...leadInfo,
							updateLeadProcess: leadInfo?.updateLeadProcess + 1 || 1,
						})
					);
					dispatch(
						lenderListData(
							leadLenders?.lenderListData?.filter(
								(item: any) => item?.id !== lenders?.[0]?.id
							)
						)
					);
					const modifiedData = leadLenders?.activeLender.map((obj: any) => {
						if (obj?.id === lenders?.[0]?.id) {
							return { ...obj, checked: false };
						}
						return obj;
					});

					dispatch(activeLender(modifiedData));
					getSelectedLenderList();
				}
			});
	};
	const addLenders = async (lenders: any) => {
		await dispatch(addMoreLenders(false));
		await dispatch(
			addLendersList({
				data: { lenders },
				param: '/' + leadInfo?.leadId + '/' + 'false',
			})
		)
			.then(unwrapResult)
			.then(async (response: any) => {
				if (response?.status) {
					history.push('/lender-specific-info');
					await dispatch(activeLender([]));
					await dispatch(
						leadFlow({
							...leadInfo,
							leadStatus: LEAD_STATUS.APPLICATION_FULFILLED,
						})
					);
				}
			});
	};

	return (
		<IonPage>
			<Header
				name={t('Selected Lenders')}
				needCross={true}
				needCrossOnClick={() => {
					if (leadInfo?.from === 'customer') {
						history.push('/lenders-list');
					} else {
						setShowPageExitPopup(true);
					}
				}}
				backButtonDisabled={false}
				backButton={true}
				style={{ borderBottom: '1px solid #00000029' }}
				endItem={
					<IonButtons slot='end'>
						{(leadInfo?.from !== 'nimbuss' ||
							leadInfo?.from !== 'customer') && (
							<>
								{leadInfo?.via !== 'share' && (
									<>
										<IonButtons className=''>
											<IonImg
												className='cursor-pointer'
												src='/images/icons/assign-icon.svg'
												alt='assign'
												onClick={() => setShowAssignModal(true)}
											/>
										</IonButtons>
										<IonButtons className='mx-3'>
											<IonImg
												className='cursor-pointer'
												src='/images/icons/share-app-menu-icon.svg'
												alt='assign'
												onClick={() => {
													const finalUrl =
														url.split('?')?.[0] +
														'?d=' +
														window.btoa(`lead_id=${leadInfo?.leadId}`);
													ShareData('', '', finalUrl);
												}}
											/>
										</IonButtons>
									</>
								)}
							</>
						)}
					</IonButtons>
				}
			/>
			<IonContent>
				<div className='bg-web lead-lender-list-container-spacing overflowY-auto h-100'>
					<div className='bg-web-white px-md-3 py-md-3 border-md-radius-10'>
						<IonButtons
							className='ms-2 my-3 conatiner-spacing-md'
							onClick={() => {
								dispatch(addMoreLenders(true));
								setLenderList([]);

								dispatch(
									leadFlow({
										...leadInfo,
										updateLeadProcess: leadInfo?.updateLeadProcess + 1 || 1,
									})
								);
								history.push('/lenders-list');
							}}
						>
							<IonImg
								className='me-2'
								src='/images/icons/add-lenders-button.svg'
								alt='assign'
							/>
							<p
								className='m-0 fs-14 fw-700 cursor-pointer'
								style={{
									color: 'var(--ion-color-primary)',
								}}
							>
								{t('Add More Lenders')}
							</p>
						</IonButtons>
						<IonRow className='conatiner-spacing-md'>
							{lenderList.map((item: any) => {
								return (
									<IonCol
										size='12'
										sizeMd='6'
										key={item.id}
										className='d-flex w-100'
									>
										<div className='d-flex justify-content-between border p-2 rounded-3 position-relative m-1 w-100'>
											<div
												onClick={() => {
													setRemoveLender([
														{ id: item.lender_id, name: item.lender_name },
													]);
													setOpenModal(true);
												}}
												className='position-absolute cursor-pointer'
												style={{ top: '-15px', right: '-10px' }}
											>
												<img
													src='/images/icons/border-close.svg'
													alt='close button'
												/>
											</div>
											<div className='d-flex align-items-center'>
												<div
													className='border d-flex justify-content-center align-items-center me-2'
													style={{
														borderRadius: '50%',
														maxWidth: '48px',
														maxHeight: '48px',
													}}
												>
													<img
														src={setBankIcon(item?.lender_name)}
														alt={item.name}
														style={{
															minWidth: '48px',
															minHeight: '48px',
														}}
													/>
												</div>
												<div>
													<h4 className='m-0 fs-14'>{item.lender_name}</h4>
													{/* <span className='fs-12 text-grey'>
														{`${item?.roi ? item.roi + '%p.a.' : ''}`}
													</span> */}
												</div>
											</div>
											<div>
												<div className='fs-12 text-capitalize'>
													<IonChip
														className={`fs-12 ${
															item.app_off_status !== null &&
															item.app_off_status.toLowerCase() === 'online'
																? 'chip-online'
																: 'chip-offline'
														}`}
														style={{
															padding: '10px',
															height: '23px',
														}}
													>
														{t(item.app_off_status || '')}
													</IonChip>
												</div>
											</div>
										</div>
									</IonCol>
								);
							})}
						</IonRow>
					</div>
				</div>
			</IonContent>
			<CustomModal
				isOpen={openModal}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setOpenModal(false)}
				breakpoints={[]}
				className='height-auto confirm-popup-middle'
				modalContent={PageExitPopUp(
					() => {
						setOpenModal(false);
					},
					() => {
						removedLenderList(removeLender);
						setOpenModal(false);
					},
					'Cancel',
					'Remove',
					'className',
					t('Are You Sure You Want to Remove Lender') + '?'
				)}
				needIonContentWraper={false}
				needCross={false}
				needArrow={false}
			/>
			<CustomModal
				id='customModalRework'
				isOpen={showAssignModal}
				modalTitle={t('Select Assignee')}
				initialBreakpoint={1}
				handleClose={() => setShowAssignModal(false)}
				breakpoints={[0, 1]}
				className='assignModal side-modal'
				modalContent={
					<CustomAllocateManagers
						eventType='Lead'
						id={leadInfo?.leadId}
						setShowAssignModal={setShowAssignModal}
					/>
				}
				scrollY={true}
				needCross={true}
				needArrow={false}
			></CustomModal>
			<CustomModal
				isOpen={showPageExitPopup}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setShowPageExitPopup(false)}
				breakpoints={[]}
				className='height-auto confirm-popup-middle'
				modalContent={PageExitPopUp(
					() => {
						setShowPageExitPopup(false);
					},
					() => {
						const myPromise = new Promise(function (myResolve) {
							setTimeout(function () {
								leadInfo?.from === 'nimbuss'
									? myResolve(
											history.push(
												'/lead-details/' +
													leadInfo.leadId +
													'/' +
													dmaSelector?.user_details?.access_token
											)
									  )
									: myResolve(history.push('/dashboard/lead'));
							}, 10);
						});

						myPromise.then(async () => {
							setLenderList([]);
							await dispatch(addMoreLenders(true));
							await dispatch(
								leadFlow({
									...leadInfo,
									updateLeadProcess: leadInfo?.updateLeadProcess + 1 || 1,
								})
							);
							setShowPageExitPopup(false);
						});
					},
					'Cancel',
					'Continue Later',
					'className',
					'Are You Sure You Want to Exit?',
					`${t('You can continue later from where you left off.')}`
				)}
				needIonContentWraper={false}
				needCross={false}
				needArrow={false}
			/>
			<IonFooter>
				<CustomNote
					{...{
						className: 'lead-lender-list-container-spacing py-2',
						header: '',
						content: `${t(
							'By clicking on Proceed you will not be able to add or remove lenders'
						)}`,
					}}
				/>
				<div className='lead-lender-list-container-spacing ion-padding bg-white'>
					<div className='d-flex align-items-center justify-content-between justify-content-md-end flex-row'>
						<div className='fw-700 me-md-4'>
							{lenderList.length} {t('Selected')}
						</div>
						<CustomButton
							className='fw-bold dashboard-add-lead-button'
							expand='block'
							disabled={isProceedActive}
							onClick={() => {
								addLenders(lenderList);
							}}
							title={t('Proceed')}
						/>
					</div>
				</div>
			</IonFooter>
		</IonPage>
	);
};
