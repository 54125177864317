import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import { IonItem, IonLabel, IonList, isPlatform } from '@ionic/react';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
	capFirstLetter,
	checkFieldsValidToContinue,
	disableNumberArrows,
	getPictureDataByOcr,
	showInAppBrowserView,
	showToastAlert,
	urltoFile,
} from '../../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { kycUserUploadImage } from '../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { UploadImageCard } from '../../dashboard/kyc/UploadImageCard';
import { LenderNameForAPICall } from '../../../helpers/lenders';
import {
	pnbPaymentStatus,
	selectedLender,
	pnbEsignPaymentLink,
	lenderSpfDetailsFlow,
	pnbPaymentDecrypt,
	lenderPnbUploadDocument,
	lenderDocumentUpload,
} from '../../../store/reducers/lead/leadSlice';
import {
	CustomModalLeadStatus,
	DynamicDropdown,
} from '../../../components/common';

export const documentsKycSchema = yup.object().shape({
	Pan: yup.object().shape({
		Url: yup.string().required('Pan Required.'),
		// Data: yup.object().shape({
		// 	No: yup
		// 		.string()
		// 		.required('PAN No. is Required.')
		// 		.trim()
		// 		// .matches(REGEX.UPPERCASE, 'Please enter value in uppercase')
		// 		.matches(
		// 			REGEX.PAN,
		// 			"PAN no. should be in alphanumeric format 'ABCDE1234A' only"
		// 		),
		// }),
	}),
	Cheque: yup.object().shape({
		Url: yup.string().required('Cheque Required.'),
	}),
	Aadhar: yup.object().shape({
		Url: yup.string().required('Aadhar Required.'),
		Data: yup.object().shape({
			No: yup.string().required('Document is Required.').trim(),
		}),
	}),
	IdProofNonIndividual: yup.object().shape({
		Url: yup.string().required('Id Proof Non Individual Required.'),
		Data: yup.object().shape({
			No: yup.string().required('Document is Required.').trim(),
		}),
	}),
	OfficeAddress: yup.object().shape({
		Url: yup.string().required('Office Address Required.'),
	}),
	IndividualITR: yup.object().shape({
		Url: yup.string().required('Individual ITR Required.'),
	}),
	BusinessITR: yup.object().shape({
		Url: yup.string().required('Business ITR Required.'),
	}),
	Bank_Statement: yup.object().shape({
		Url: yup.string().required('Bank Statement Required.'),
	}),
	OtherDocs: yup.object().shape({
		Url: yup.string().required('Other Docs Required.'),
	}),
});
export const PunjabDocUpload = ({ setCurrentStep }: any) => {
	const dmaSelector = useSelector((state: any) => state?.users);
	const isBrowser = !isPlatform('capacitor');
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	const [documentsSchema, setDocumentsSchema] =
		useState<any>(documentsKycSchema);
	const [openUploadModal, setOpenUploadModal] = useState(false);
	const [imagePreviewUpload, setImagePreviewUpload] = useState({
		panImage: false,
		chequeImage: false,
		aadharImage: false,
		IdProofNonIndividualImage: false,
		OfficeAddressImage: false,
		IndividualITRImage: false,
		BusinessITRImage: false,
		bankStatementImage: false,
		otherDocsImage: false,
	});

	const [docTypeErrorMessage, setDocTypeErrorMessage] = useState<any>({
		panTypeError: '',
		chequeTypeError: '',
		aadharTypeError: '',
		IdProofNonIndividualTypeError: '',
		officeAddressTypeError: '',
		individualITRTypeError: '',
		BusinessITRTypeError: '',
		bankStatementTypeError: '',
		otherDocsTypeError: '',
	});
	const [clickImageFieldName, setClickImageFieldName] = useState<any>(null);
	const [docImages, setDocImages] = useState<any>({
		panUrl: '',
		chequeUrl: '',
		aadharUrl: '',
		IdProofNonIndividualUrl: '',
		officeAddressUrl: '',
		individualITRUrl: '',
		BusinessITRUrl: '',
		bankStatementUrl: '',
		otherDocsUrl: '',
	});

	const [getData, setData] = useState<any>({});
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { selectedLender, lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);
	const leadInfo = useSelector((state: any) => state.lead.leadFlowData);
	const lenderSpfDetails = useSelector(
		(state: any) => state?.lead?.lenderSpecificDetails
	);
	const [url, setUrl] = useState('');
	const [statusContainer, setStatusContainer] = useState<any>('');

	const history: any = useHistory();
	const [panValidation, setPanValidation] = useState<any>({
		status: '',
		msg: '',
	});
	const [chequeValidation, setChequeValidation] = useState<any>({
		status: '',
		msg: '',
	});
	const [aadharValidation, setAadharValidation] = useState<any>({
		status: '',
		msg: '',
	});
	const [
		IdProofNonIndividualValidation,
		setIdProofNonIndividualValidationValidation,
	] = useState<any>({
		status: '',
		msg: '',
	});
	const [officeAddressValidation, setOfficeAddressValidation] = useState<any>({
		status: '',
		msg: '',
	});
	const [individualITRValidation, setIndividualITRValidation] = useState<any>({
		status: '',
		msg: '',
	});
	const [BusinessITRValidation, setBusinessITRValidation] = useState<any>({
		status: '',
		msg: '',
	});
	const [bankStatementValidation, setBankStatementValidation] = useState<any>({
		status: '',
		msg: '',
	});
	const [otherDocsValidation, setOtherDocsValidation] = useState<any>({
		status: '',
		msg: '',
	});

	const [applicationNo, setApplicationNo] = useState<any>(null);
	const [leadStatusModalOpen, setLeadStatusModalOpen] = useState<any>(false);

	const onLeadStatusModalClose = () => {
		setLeadStatusModalOpen(false);
	};

	// react hooks form start
	const {
		handleSubmit,
		control,
		trigger,
		getValues,
		reset,
		watch,
		setValue,
		formState: { errors, isValid },
	}: any = useForm({
		resolver: yupResolver(documentsSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			Pan: {
				Type: 'Pan',
				Url: '',
				Data: { No: 'Pan Card' },
				status: 'added',
			},
			Cheque: {
				Type: 'Cheque',
				Url: '',
				Data: { No: 'Cheque' },
				status: 'added',
			},
			Aadhar: {
				Type: 'Aadhar',
				Url: '',
				Data: { No: '' },
				status: 'added',
			},
			IdProofNonIndividual: {
				Type: 'IdProofNonIndividual',
				Url: '',
				Data: { No: '' },
				status: 'added',
			},
			OfficeAddress: {
				Type: 'OfficeAddress',
				Url: '',
				Data: { No: '' },
				status: 'added',
			},
			IndividualITR: {
				Type: 'IndividualITR',
				Url: '',
				Data: { No: 'Individual ITR' },
				status: 'added',
			},
			BusinessITR: {
				Type: 'BusinessITR',
				Url: '',
				Data: { No: 'Business ITR' },
				status: 'added',
			},
			BankStatement: {
				Type: 'BankStatement',
				Url: '',
				Data: { No: 'Bank Statement' },
				status: 'added',
			},
			OtherDocs: {
				Type: 'OtherDocs',
				Url: '',
				Data: { No: '' },
				status: 'added',
			},
		},
	});
	// useEffect(() => {
	// 	trigger();
	// }, [watch()]);
	// console.log({ errors, isValid, VALUES: watch() });

	useEffect(() => {
		getUpdateUrl();
	}, [getData]);

	useEffect(() => {
		showToastAlert({
			type: 'success',
			message: 'Transaction completed successfully.',
			autoClose: false,
		});
	}, []);

	useEffect(() => {
		if (location?.search.includes('paymentResKey=')) {
			payment_decrypt(location?.search.split('paymentResKey=')[1]);
		}
	}, [location.pathname]);

	const payment_status = async () => {
		await dispatch(
			pnbPaymentStatus({
				// param: '/' + leadFlowData?.leadId,
				data: {
					lender_name: LenderNameForAPICall.PUNJAB,
					lead_id: leadInfo?.leadId,
					lead_application_id:
						leadFlowData?.lead_app_id || selectedLender[0]?.id,
				},
			})
		).then((res: any) => {
			console.log(res);
		});
	};

	const paymentLink = async () => {
		console.log({
			lender_name: LenderNameForAPICall.PUNJAB,
			lead_id: leadInfo?.leadId,
			lead_application_id: leadFlowData?.lead_app_id || selectedLender[0]?.id,
			return_url: window?.location?.href,
		});

		await dispatch(
			pnbEsignPaymentLink({
				// param: '/' + leadFlowData?.leadId,
				data: {
					lender_name: LenderNameForAPICall.PUNJAB,
					lead_id: leadInfo?.leadId,
					lead_application_id:
						leadFlowData?.lead_app_id || selectedLender[0]?.id,
					return_url: `${url}${location.pathname}`,
				},
			})
		).then((res: any) => {
			showInAppBrowserView(res?.payload?.payment_url);
			dispatch(
				lenderSpfDetailsFlow({
					...lenderSpfDetails,
					step: 'step4',
				})
			);
			// window.close();
		});
	};

	const payment_decrypt = async (code: any) => {
		await dispatch(
			pnbPaymentDecrypt({
				// param: '/' + leadFlowData?.leadId,
				data: {
					lender_name: LenderNameForAPICall.PUNJAB,
					lead_id: leadInfo?.leadId,
					lead_application_id:
						leadFlowData?.lead_app_id || selectedLender[0]?.id,
					paymentResKey: code,
				},
			})
		).then((res: any) => {
			setStatusContainer(res?.payload?.paymentStatusMessage);
		});
	};

	const getUpdateUrl = async () => {
		if (docImages?.panUrl) {
			setDocImages({ panUrl: docImages?.panUrl, ...docImages });
			setValue('Pan.Url', docImages?.panUrl);
		} else if (docImages?.chequeUrl) {
			setDocImages({ chequeUrl: docImages?.chequeUrl, ...docImages });
			setValue('Cheque.Url', docImages?.chequeUrl);
		} else if (docImages?.aadharUrl) {
			setDocImages({ aadharUrl: docImages?.aadharUrl, ...docImages });
			setValue('Aadhar.Url', docImages?.aadharUrl);
		} else if (docImages?.IdProofNonIndividualUrl) {
			setDocImages({
				IdProofNonIndividualUrl: docImages?.IdProofNonIndividualUrl,
				...docImages,
			});
			setValue(
				'IdProofNonIndividualUrl.Url',
				docImages?.IdProofNonIndividualUrl
			);
		} else if (docImages?.officeAddressUrl) {
			setDocImages({
				officeAddressUrl: docImages?.officeAddressUrl,
				...docImages,
			});
			setValue('officeAddressUrl.Url', docImages?.officeAddressUrl);
		} else if (docImages?.individualITRUrl) {
			setDocImages({
				individualITRUrl: docImages?.individualITRUrl,
				...docImages,
			});
			setValue('individualITRUrl.Url', docImages?.individualITRUrl);
		} else if (docImages?.BusinessITRUrl) {
			setDocImages({
				BusinessITRUrl: docImages?.BusinessITRUrl,
				...docImages,
			});
			setValue('BusinessITRUrl.Url', docImages?.BusinessITRUrl);
		} else if (docImages?.bankStatementUrl) {
			setDocImages({
				bankStatementUrl: docImages?.bankStatementUrl,
				...docImages,
			});
			setValue('bankStatementUrl.Url', docImages?.bankStatementUrl);
		} else if (docImages?.otherDocsUrl) {
			setDocImages({
				otherDocsUrl: docImages?.otherDocsUrl,
				...docImages,
			});
			setValue('otherDocsUrl.Url', docImages?.otherDocsUrl);
		}
	};

	const ShareModal = () => {
		const handleUploadChange = async (
			type: string,
			fieldName: string,
			e: any
		) => {
			setOpenUploadModal(false);
			const formData = new FormData();
			// let result: any;
			let image: any;
			let getOcrData: any;
			let getImageFileObject: any;
			setDocTypeErrorMessage({
				panTypeError: '',
			});
			switch (type) {
				case 'file':
					if (
						e.target.files[0].type === 'image/png' ||
						e.target.files[0].type === 'image/jpeg' ||
						(e.target.files[0].type === 'application/pdf' &&
							!fieldName?.includes('Photo.Url'))
					) {
						formData.append('file', e.target.files[0]);
						formData.append('lender_name', LenderNameForAPICall.PUNJAB);

						dispatch(lenderDocumentUpload({ data: formData }))
							.then(unwrapResult)
							.then((response: any) => {
								if (response?.status) {
									setValue(`${fieldName}`, response?.key);
									switch (fieldName) {
										case 'Pan.Url':
											setDocTypeErrorMessage({
												...docTypeErrorMessage,
												panTypeError: '',
											});
											setImagePreviewUpload({
												...imagePreviewUpload,
												panImage: true,
											});
											break;
										case 'Cheque.Url':
											setDocTypeErrorMessage({
												...docTypeErrorMessage,
												chequeTypeError: '',
											});
											setImagePreviewUpload({
												...imagePreviewUpload,
												chequeImage: true,
											});
											break;
										case 'Aadhar.Url':
											setDocTypeErrorMessage({
												...docTypeErrorMessage,
												aadharTypeError: '',
											});
											setImagePreviewUpload({
												...imagePreviewUpload,
												aadharImage: true,
											});
											break;
										case 'IdProofNonIndividual.Url':
											setDocTypeErrorMessage({
												...docTypeErrorMessage,
												IdProofNonIndividualTypeError: '',
											});
											setImagePreviewUpload({
												...imagePreviewUpload,
												IdProofNonIndividualImage: true,
											});
											break;
										case 'OfficeAddress.Url':
											setDocTypeErrorMessage({
												...docTypeErrorMessage,
												officeAddressTypeError: '',
											});
											setImagePreviewUpload({
												...imagePreviewUpload,
												OfficeAddressImage: true,
											});
											break;
										case 'IndividualITR.Url':
											setDocTypeErrorMessage({
												...docTypeErrorMessage,
												individualITRTypeError: '',
											});
											setImagePreviewUpload({
												...imagePreviewUpload,
												IndividualITRImage: true,
											});
											break;
										case 'BusinessITR.Url':
											setDocTypeErrorMessage({
												...docTypeErrorMessage,
												BusinessITRTypeError: '',
											});
											setImagePreviewUpload({
												...imagePreviewUpload,
												BusinessITRImage: true,
											});
											break;
										case 'Bank_Statement.Url':
											setDocTypeErrorMessage({
												...docTypeErrorMessage,
												bankStatementTypeError: '',
											});
											setImagePreviewUpload({
												...imagePreviewUpload,
												bankStatementImage: true,
											});
											break;
										case 'OtherDocs.Url':
											setDocTypeErrorMessage({
												...docTypeErrorMessage,
												otherDocsTypeError: '',
											});
											setImagePreviewUpload({
												...imagePreviewUpload,
												otherDocsImage: true,
											});
											break;
									}
								}
							});
						break;
					} else {
						switch (fieldName) {
							case 'Pan.Url':
								setDocTypeErrorMessage({
									...docTypeErrorMessage,
									panTypeError:
										'Uploaded file is invalid. Only JPG/JPEG, PNG and PDF files are allowed.',
								});
								break;
							case 'Cheque.Url':
								setDocTypeErrorMessage({
									...docTypeErrorMessage,
									chequeTypeError:
										'Uploaded file is invalid. Only JPG/JPEG, PNG and PDF files are allowed.',
								});
								break;
							case 'Aadhar.Url':
								setDocTypeErrorMessage({
									...docTypeErrorMessage,
									aadharTypeError:
										'Uploaded file is invalid. Only JPG/JPEG, PNG and PDF files are allowed.',
								});
								break;
							case 'IdProofNonIndividual.Url':
								setDocTypeErrorMessage({
									...docTypeErrorMessage,
									IdProofNonIndividualTypeError:
										'Uploaded file is invalid. Only JPG/JPEG, PNG and PDF files are allowed.',
								});
								break;
							case 'OfficeAddress.Url':
								setDocTypeErrorMessage({
									...docTypeErrorMessage,
									officeAddressTypeError:
										'Uploaded file is invalid. Only JPG/JPEG, PNG and PDF files are allowed.',
								});
								break;
							case 'IndividualITR.Url':
								setDocTypeErrorMessage({
									...docTypeErrorMessage,
									individualITRTypeError:
										'Uploaded file is invalid. Only JPG/JPEG, PNG and PDF files are allowed.',
								});
								break;
							case 'BusinessITR.Url':
								setDocTypeErrorMessage({
									...docTypeErrorMessage,
									BusinessITRTypeError:
										'Uploaded file is invalid. Only JPG/JPEG, PNG and PDF files are allowed.',
								});
								break;
							case 'Bank_Statement.Url':
								setDocTypeErrorMessage({
									...docTypeErrorMessage,
									otherDocsTypeError:
										'Uploaded file is invalid. Only JPG/JPEG, PNG and PDF files are allowed.',
								});
								break;
							case 'OtherDocs.Url':
								setDocTypeErrorMessage({
									...docTypeErrorMessage,
									otherDocsTypeError:
										'Uploaded file is invalid. Only JPG/JPEG, PNG and PDF files are allowed.',
								});
								break;
						}
						break;
					}
				default:
					break;
			}
			trigger();
		};
		return (
			<>
				<IonList>
					<IonItem
						lines='full'
						className='cursor-pointer'
					>
						<img
							src='/images/icons/kyc/upload-image-icon.svg'
							alt='Upload Photo'
						/>
						<IonLabel>
							<label
								htmlFor='upload-photo'
								className='cursor-pointer'
							>
								<h2 className='fs-16 ps-3 text-dark ion-text-wrap'>
									{t('Upload Document')}
								</h2>
							</label>
							<input
								className='d-none'
								accept='image/png, image/jpeg ,application/pdf'
								type='file'
								id='upload-photo'
								onChange={(e) => {
									handleUploadChange('file', clickImageFieldName, e);
								}}
							/>
						</IonLabel>
					</IonItem>
				</IonList>
			</>
		);
	};

	const onSubmit = async (data: any) => {
		console.log('🚀 ~ onSubmit ~ data:', data);
		const documentRequest: any = [];

		Object.entries(watch()).map((item: any) =>
			documentRequest.push({
				applicantType: '3',
				docType: item[1]?.Type,
				docName: item[1]?.Data?.No,
				file: item[1]?.Url,
			})
		);
		dispatch(
			await lenderPnbUploadDocument({
				// param: `?web_top_no=${lenderSpecificDetails?.webtopNo}`,
				data: {
					lead_id: leadFlowData?.leadId,
					lender_id: selectedLender[0]?.lender_id,
					lead_application_id: selectedLender[0]?.id,
					lender_name: LenderNameForAPICall.PUNJAB,
					lender_documents: documentRequest,
				},
			})
		).then(async (res: any) => {
			console.log(res);
			setApplicationNo(res?.payload?.data?.applicationId);
			showToastAlert({ type: 'success', message: res?.payload?.message });
			setLeadStatusModalOpen(true);
			reset({});
		});
	};

	const aadharDocumentList: any = [
		{ value: 'Photocopy of Passport' },
		{ value: 'Voter ID Card' },
		{ value: 'Driving License' },
		{ value: 'Ration Card' },
		{
			value:
				'Original letter from Employer on the letterhead and authosed by Authorized signatory',
		},
		{ value: 'Utility bill' },
		{ value: 'Rental agreement /Bank statement' },
	];
	const idForNonIndividualDocumentList: any = [
		{ value: 'Shops & Establishment Certificate' },
		{ value: 'Trade License Certificate' },
		{ value: 'SSI Registration Certificate' },
		{ value: 'Sales Tax' },
		{
			value: 'VAT Registration Certificate',
		},
		{ value: 'Partnership Deed' },
		{ value: 'Memorandum of Birth Certificate (Govt agency) ' },
		{ value: 'School Leaving certificate (10th)' },
		{
			value:
				'Employee ID (only for PSU/Govt Employees) Association (MOA) for companies',
		},
		{ value: 'Export-Import Code Certificate' },
	];
	const officeAddressDocumentList: any = [
		{ value: 'Shops & Establishment Certificate' },
		{ value: 'Sales Tax certificate' },
		{ value: 'Title document of shop' },
		{ value: 'business premises' },
		{ value: 'Any other Govt authority issued, license, document' },
		{ value: 'Salary Slip' },
	];
	const OtherDocumentList: any = [
		{ value: 'Loan account statement' },
		{
			value:
				'Sanction letter along with Bank statement reflecting EMI clerance of Last 12 EMIs of exsiting loan and Loan outstanding statement for BT cases alog with others',
		},
	];

	return (
		<div className=' small-container bg-web'>
			<div
				className=' bg-web-white px-3 p-md-5 border-radius-10'
				style={{ height: '95vh' }}
			>
				<form
					className='pb-3'
					style={{ height: '100%', overflowY: 'scroll', overflowX: 'hidden' }}
					onSubmit={handleSubmit(onSubmit)}
					encType='multipart/form-data'
				>
					{statusContainer === 'Pending' && (
						<CustomButton
							onClick={payment_status}
							expand='block'
							fill={'solid'}
							className={' '}
						>
							{t('Payment Status')}
						</CustomButton>
					)}
					{statusContainer === 'Txn_Failure' && (
						<CustomButton
							onClick={paymentLink}
							expand='block'
							fill={'solid'}
							className={' '}
						>
							{t('PROCEED TO ')}
						</CustomButton>
					)}

					{statusContainer == 'Success' && (
						<>
							<div
								className='ion-padding conatiner-spacing-md'
								style={{ borderBottom: '1px solid grey' }}
							>
								{/* PAN */}
								<h3 className='m-0 fs-bold fs-20 pb-2'>{t('PAN')}</h3>
								<div>
									<h4 className='m-0 text-grey fs-14 pb-2'>
										{t('Upload PAN Card') + ' *'}
									</h4>

									<UploadImageCard
										{...{
											setImagePreviewUpload,
											imagePreviewUpload,
											isImageAvilable: imagePreviewUpload?.panImage,
											imgKey: 'panImage',
											setClickImageFieldName,
											setOpenUploadModal,
											setValue: setValue,
											value: 'Pan.Url',
											title: t('Upload PAN'),
											src: getValues('Pan.Url'),
										}}
									/>
									{!imagePreviewUpload.panImage &&
									!docTypeErrorMessage.panTypeError &&
									errors?.Pan?.Url?.message ? (
										<div className=''>
											<div className='error-text sc-ion-input-md fs-12'>
												{t(errors?.Pan?.Url?.message)}
											</div>
										</div>
									) : (
										docTypeErrorMessage.panTypeError && (
											<div className=''>
												<div className='error-text sc-ion-input-md fs-12 pb-2'>
													{t(docTypeErrorMessage.panTypeError)}
												</div>
											</div>
										)
									)}

									{panValidation.msg && (
										<div
											className={`my-2 fs-12 d-flex align-items-center ${
												panValidation.status
													? 'secondary-text-color'
													: 'danger-text-color'
											}`}
										>
											<img
												src={
													panValidation.status
														? 'images/icons/green-info.svg'
														: 'images/icons/red-info.svg'
												}
												className='me-2'
												width={16}
												height={16}
												alt=''
											/>
											{panValidation.msg}
										</div>
									)}
								</div>
								{/* <div className=''>
						<Controller
							control={control}
							render={({ field: { onChange, onBlur, name } }) => (
								<CustomInput
									type='text'
									placeholder={t('PAN No.') + ' *'}
									onBlur={onBlur}
									name={name}
									// disabled={panWriteable}
									value={getValues('Pan.Data.No')}
									onChange={onChange}
									errors={t(errors?.Pan?.Data?.No?.message)}
									className='text-uppercase'
								/>
							)}
							name='Pan.Data.No'
						/>
					</div> */}
							</div>
							<div
								className='ion-padding conatiner-spacing-md'
								style={{ borderBottom: '1px solid grey' }}
							>
								{/* Cheque */}
								<h3 className='m-0 fs-bold fs-20 pb-2'>{t('Cheque leaf')}</h3>
								<div>
									<h4 className='m-0 text-grey fs-14 pb-2'>
										{t('Upload Cheque leaf') + ' *'}
									</h4>

									<UploadImageCard
										{...{
											setImagePreviewUpload,
											imagePreviewUpload,
											isImageAvilable: imagePreviewUpload?.chequeImage,
											imgKey: 'chequeImage',
											setClickImageFieldName,
											setOpenUploadModal,
											setValue: setValue,
											value: 'Cheque.Url',
											title: t('Upload Cheque leaf'),
											src: getValues('Cheque.Url'),
										}}
									/>
									{!imagePreviewUpload.chequeImage &&
									!docTypeErrorMessage.chequeTypeError &&
									errors?.Cheque?.Url?.message ? (
										<div className=''>
											<div className='error-text sc-ion-input-md fs-12'>
												{t(errors?.Cheque?.Url?.message)}
											</div>
										</div>
									) : (
										docTypeErrorMessage.chequeTypeError && (
											<div className=''>
												<div className='error-text sc-ion-input-md fs-12 pb-2'>
													{t(docTypeErrorMessage.chequeTypeError)}
												</div>
											</div>
										)
									)}

									{chequeValidation.msg && (
										<div
											className={`my-2 fs-12 d-flex align-items-center ${
												chequeValidation.status
													? 'secondary-text-color'
													: 'danger-text-color'
											}`}
										>
											<img
												src={
													chequeValidation.status
														? 'images/icons/green-info.svg'
														: 'images/icons/red-info.svg'
												}
												className='me-2'
												width={16}
												height={16}
												alt=''
											/>
											{chequeValidation.msg}
										</div>
									)}
								</div>
							</div>
							<div
								className='ion-padding conatiner-spacing-md'
								style={{ borderBottom: '1px solid grey' }}
							>
								{/* Aadhar */}
								<h3 className='m-0 fs-bold fs-20 pb-2'>{t('Aadhar')}</h3>
								<Controller
									control={control}
									render={({ field: { onChange, onBlur, name } }) => (
										<DynamicDropdown
											label={t(`Document Type *`)}
											data={aadharDocumentList}
											name={name}
											onChange={(e: any) => {
												setValue('Aadhar.Url', '');
												setImagePreviewUpload({
													...imagePreviewUpload,
													[`aadharImage`]: false,
												});
												onChange(e);
											}}
											value={getValues('Aadhar.Data.No')}
											onBlur={onBlur}
											errors={t(errors?.Aadhar?.Data?.No?.message)}
											required={true}
										/>
									)}
									name={`Aadhar.Data.No`}
								/>
								<div>
									<h4 className='m-0 text-grey fs-14 pb-2'>
										{t('Upload Aadhar') + ' *'}
									</h4>

									<UploadImageCard
										{...{
											disabled: !watch('Aadhar.Data.No'),
											setImagePreviewUpload,
											imagePreviewUpload,
											isImageAvilable: imagePreviewUpload?.aadharImage,
											imgKey: 'aadharImage',
											setClickImageFieldName,
											setOpenUploadModal,
											setValue: setValue,
											value: 'Aadhar.Url',
											title: t('Upload Aadhar'),
											src: getValues('Aadhar.Url'),
										}}
									/>
									{!imagePreviewUpload.aadharImage &&
									!docTypeErrorMessage.aadharTypeError &&
									errors?.Aadhar?.Url?.message ? (
										<div className=''>
											<div className='error-text sc-ion-input-md fs-12'>
												{t(errors?.Aadhar?.Url?.message)}
											</div>
										</div>
									) : (
										docTypeErrorMessage.aadharTypeError && (
											<div className=''>
												<div className='error-text sc-ion-input-md fs-12 pb-2'>
													{t(docTypeErrorMessage.aadharTypeError)}
												</div>
											</div>
										)
									)}

									{aadharValidation.msg && (
										<div
											className={`my-2 fs-12 d-flex align-items-center ${
												aadharValidation.status
													? 'secondary-text-color'
													: 'danger-text-color'
											}`}
										>
											<img
												src={
													aadharValidation.status
														? 'images/icons/green-info.svg'
														: 'images/icons/red-info.svg'
												}
												className='me-2'
												width={16}
												height={16}
												alt=''
											/>
											{aadharValidation.msg}
										</div>
									)}
								</div>
							</div>
							<div
								className='ion-padding conatiner-spacing-md'
								style={{ borderBottom: '1px solid grey' }}
							>
								{/* IdProofNonIndividual */}
								<h3 className='m-0 fs-bold fs-20 pb-2'>
									{t('Id Proof For Non Individual')}
								</h3>
								<Controller
									control={control}
									render={({ field: { onChange, onBlur, name } }) => (
										<DynamicDropdown
											label={t(`Document Type *`)}
											data={idForNonIndividualDocumentList}
											name={name}
											onChange={(e: any) => {
												setValue('IdProofNonIndividual.Url', '');
												setImagePreviewUpload({
													...imagePreviewUpload,
													[`IdProofNonIndividualImage`]: false,
												});
												onChange(e);
											}}
											value={getValues('IdProofNonIndividual.Data.No')}
											onBlur={onBlur}
											errors={t(
												errors?.IdProofNonIndividual?.Data?.No?.message
											)}
											required={true}
										/>
									)}
									name={`IdProofNonIndividual.Data.No`}
								/>
								<div>
									<h4 className='m-0 text-grey fs-14 pb-2'>
										{t('Upload Id Proof For Non Individual') + ' *'}
									</h4>

									<UploadImageCard
										{...{
											disabled: !watch('IdProofNonIndividual.Data.No'),
											setImagePreviewUpload,
											imagePreviewUpload,
											isImageAvilable:
												imagePreviewUpload?.IdProofNonIndividualImage,
											imgKey: 'IdProofNonIndividualImage',
											setClickImageFieldName,
											setOpenUploadModal,
											setValue: setValue,
											value: 'IdProofNonIndividual.Url',
											title: t('Upload Id Proof Non Individual'),
											src: getValues('IdProofNonIndividual.Url'),
										}}
									/>
									{!imagePreviewUpload.IdProofNonIndividualImage &&
									!docTypeErrorMessage.IdProofNonIndividualTypeError &&
									errors?.IdProofNonIndividual?.Url?.message ? (
										<div className=''>
											<div className='error-text sc-ion-input-md fs-12'>
												{t(errors?.IdProofNonIndividual?.Url?.message)}
											</div>
										</div>
									) : (
										docTypeErrorMessage.idProofForNonIndividualTypeError && (
											<div className=''>
												<div className='error-text sc-ion-input-md fs-12 pb-2'>
													{t(
														docTypeErrorMessage.idProofForNonIndividualTypeError
													)}
												</div>
											</div>
										)
									)}

									{IdProofNonIndividualValidation.msg && (
										<div
											className={`my-2 fs-12 d-flex align-items-center ${
												IdProofNonIndividualValidation.status
													? 'secondary-text-color'
													: 'danger-text-color'
											}`}
										>
											<img
												src={
													IdProofNonIndividualValidation.status
														? 'images/icons/green-info.svg'
														: 'images/icons/red-info.svg'
												}
												className='me-2'
												width={16}
												height={16}
												alt=''
											/>
											{IdProofNonIndividualValidation.msg}
										</div>
									)}
								</div>
							</div>
							<div
								className='ion-padding conatiner-spacing-md'
								style={{ borderBottom: '1px solid grey' }}
							>
								{/* Office Address */}
								<h3 className='m-0 fs-bold fs-20 pb-2'>
									{t('Office Address')}
								</h3>
								<div>
									<h4 className='m-0 text-grey fs-14 pb-2'>
										{t('Upload Office Address') + ' *'}
									</h4>
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, name } }) => (
											<DynamicDropdown
												label={t(`Document Type *`)}
												data={officeAddressDocumentList}
												name={name}
												onChange={(e: any) => {
													setValue('OfficeAddress.Url', '');
													setImagePreviewUpload({
														...imagePreviewUpload,
														[`OfficeAddressImage`]: false,
													});
													onChange(e);
												}}
												value={getValues('OfficeAddress.Data.No')}
												onBlur={onBlur}
												errors={t(errors?.OfficeAddress?.Data?.No?.message)}
												required={true}
											/>
										)}
										name={`OfficeAddress.Data.No`}
									/>
									<UploadImageCard
										{...{
											disabled: !watch('OfficeAddress.Data.No'),
											setImagePreviewUpload,
											imagePreviewUpload,
											isImageAvilable: imagePreviewUpload?.OfficeAddressImage,
											imgKey: 'OfficeAddressImage',
											setClickImageFieldName,
											setOpenUploadModal,
											setValue: setValue,
											value: 'OfficeAddress.Url',
											title: t('Upload Office Address'),
											src: getValues('OfficeAddress.Url'),
										}}
									/>
									{!imagePreviewUpload.OfficeAddressImage &&
									!docTypeErrorMessage.officeAddressTypeError &&
									errors?.OfficeAddress?.Url?.message ? (
										<div className=''>
											<div className='error-text sc-ion-input-md fs-12'>
												{t(errors?.OfficeAddress?.Url?.message)}
											</div>
										</div>
									) : (
										docTypeErrorMessage.officeAddressTypeError && (
											<div className=''>
												<div className='error-text sc-ion-input-md fs-12 pb-2'>
													{t(docTypeErrorMessage.officeAddressTypeError)}
												</div>
											</div>
										)
									)}

									{officeAddressValidation.msg && (
										<div
											className={`my-2 fs-12 d-flex align-items-center ${
												officeAddressValidation.status
													? 'secondary-text-color'
													: 'danger-text-color'
											}`}
										>
											<img
												src={
													officeAddressValidation.status
														? 'images/icons/green-info.svg'
														: 'images/icons/red-info.svg'
												}
												className='me-2'
												width={16}
												height={16}
												alt=''
											/>
											{officeAddressValidation.msg}
										</div>
									)}
								</div>
							</div>
							<div
								className='ion-padding conatiner-spacing-md'
								style={{ borderBottom: '1px solid grey' }}
							>
								{/* Individual ITR */}
								<h3 className='m-0 fs-bold fs-20 pb-2'>
									{t('Individual ITR')}
								</h3>
								<div>
									<h4 className='m-0 text-grey fs-14 pb-2'>
										{t(`Upload Individual ITR -Individual  ITR last 3 years`) +
											' *'}
									</h4>

									<UploadImageCard
										{...{
											setImagePreviewUpload,
											imagePreviewUpload,
											isImageAvilable: imagePreviewUpload?.IndividualITRImage,
											imgKey: 'IndividualITRImage',
											setClickImageFieldName,
											setOpenUploadModal,
											setValue: setValue,
											value: 'IndividualITR.Url',
											title: t('Upload Individual ITR'),
											src: getValues('IndividualITR.Url'),
										}}
									/>
									{!imagePreviewUpload.IndividualITRImage &&
									!docTypeErrorMessage.individualITRTypeError &&
									errors?.IndividualITR?.Url?.message ? (
										<div className=''>
											<div className='error-text sc-ion-input-md fs-12'>
												{t(errors?.IndividualITR?.Url?.message)}
											</div>
										</div>
									) : (
										docTypeErrorMessage.individualITRTypeError && (
											<div className=''>
												<div className='error-text sc-ion-input-md fs-12 pb-2'>
													{t(docTypeErrorMessage.individualITRTypeError)}
												</div>
											</div>
										)
									)}

									{individualITRValidation.msg && (
										<div
											className={`my-2 fs-12 d-flex align-items-center ${
												individualITRValidation.status
													? 'secondary-text-color'
													: 'danger-text-color'
											}`}
										>
											<img
												src={
													individualITRValidation.status
														? 'images/icons/green-info.svg'
														: 'images/icons/red-info.svg'
												}
												className='me-2'
												width={16}
												height={16}
												alt=''
											/>
											{individualITRValidation.msg}
										</div>
									)}
								</div>
							</div>
							<div
								className='ion-padding conatiner-spacing-md'
								style={{ borderBottom: '1px solid grey' }}
							>
								{/* Business ITR */}
								<h3 className='m-0 fs-bold fs-20 pb-2'>{t('Business ITR')}</h3>
								<div>
									<h4 className='m-0 text-grey fs-14 pb-2'>
										{t('Upload Business ITR') + ' *'}
									</h4>

									<UploadImageCard
										{...{
											setImagePreviewUpload,
											imagePreviewUpload,
											isImageAvilable: imagePreviewUpload?.BusinessITRImage,
											imgKey: 'BusinessITRImage',
											setClickImageFieldName,
											setOpenUploadModal,
											setValue: setValue,
											value: 'BusinessITR.Url',
											title: t('Upload Business ITR'),
											src: getValues('BusinessITR.Url'),
										}}
									/>
									{!imagePreviewUpload.BusinessITRImage &&
									!docTypeErrorMessage.BusinessITRTypeError &&
									errors?.BusinessITR?.Url?.message ? (
										<div className=''>
											<div className='error-text sc-ion-input-md fs-12'>
												{t(errors?.BusinessITR?.Url?.message)}
											</div>
										</div>
									) : (
										docTypeErrorMessage.BusinessITRTypeError && (
											<div className=''>
												<div className='error-text sc-ion-input-md fs-12 pb-2'>
													{t(docTypeErrorMessage.BusinessITRTypeError)}
												</div>
											</div>
										)
									)}

									{BusinessITRValidation.msg && (
										<div
											className={`my-2 fs-12 d-flex align-items-center ${
												BusinessITRValidation.status
													? 'secondary-text-color'
													: 'danger-text-color'
											}`}
										>
											<img
												src={
													BusinessITRValidation.status
														? 'images/icons/green-info.svg'
														: 'images/icons/red-info.svg'
												}
												className='me-2'
												width={16}
												height={16}
												alt=''
											/>
											{BusinessITRValidation.msg}
										</div>
									)}
								</div>
							</div>
							<div
								className='ion-padding conatiner-spacing-md'
								style={{ borderBottom: '1px solid grey' }}
							>
								{/* Bank Statement */}
								<h3 className='m-0 fs-bold fs-20 pb-2'>
									{t('Bank Statement')}
								</h3>
								<div>
									<h4 className='m-0 text-grey fs-14 pb-2'>
										{t('Upload Bank Statement') + ' *'}
									</h4>

									<UploadImageCard
										{...{
											setImagePreviewUpload,
											imagePreviewUpload,
											isImageAvilable: imagePreviewUpload?.bankStatementImage,
											imgKey: 'bankStatementImage',
											setClickImageFieldName,
											setOpenUploadModal,
											setValue: setValue,
											value: 'Bank_Statement.Url',
											title: t('Upload Bank Statement'),
											src: getValues('Bank_Statement.Url'),
										}}
									/>
									{!imagePreviewUpload.bankStatementImage &&
									!docTypeErrorMessage.bankStatementTypeError &&
									errors?.Bank_Statement?.Url?.message ? (
										<div className=''>
											<div className='error-text sc-ion-input-md fs-12'>
												{t(errors?.Bank_Statement?.Url?.message)}
											</div>
										</div>
									) : (
										docTypeErrorMessage.bankStatementTypeError && (
											<div className=''>
												<div className='error-text sc-ion-input-md fs-12 pb-2'>
													{t(docTypeErrorMessage.bankStatementTypeError)}
												</div>
											</div>
										)
									)}

									{bankStatementValidation.msg && (
										<div
											className={`my-2 fs-12 d-flex align-items-center ${
												bankStatementValidation.status
													? 'secondary-text-color'
													: 'danger-text-color'
											}`}
										>
											<img
												src={
													bankStatementValidation.status
														? 'images/icons/green-info.svg'
														: 'images/icons/red-info.svg'
												}
												className='me-2'
												width={16}
												height={16}
												alt=''
											/>
											{bankStatementValidation.msg}
										</div>
									)}
								</div>
								{/* <div className=''>
						<Controller
							control={control}
							render={({ field: { onChange, onBlur, name } }) => (
								<CustomInput
									type='text'
									placeholder={t('PAN No.') + ' *'}
									onBlur={onBlur}
									name={name}
									// disabled={panWriteable}
									value={getValues('Pan.Data.No')}
									onChange={onChange}
									errors={t(errors?.Pan?.Data?.No?.message)}
									className='text-uppercase'
								/>
							)}
							name='Pan.Data.No'
						/>
					</div> */}
							</div>
							<div
								className='ion-padding conatiner-spacing-md'
								style={{ borderBottom: '1px solid grey' }}
							>
								{/* Other Docs */}
								<h3 className='m-0 fs-bold fs-20 pb-2'>{t('Other Docs')}</h3>
								<Controller
									control={control}
									render={({ field: { onChange, onBlur, name } }) => (
										<DynamicDropdown
											label={t(`Document Type *`)}
											data={OtherDocumentList}
											name={name}
											onChange={(e: any) => {
												setValue('OtherDocs.Url', '');
												setImagePreviewUpload({
													...imagePreviewUpload,
													[`otherDocsImage`]: false,
												});
												onChange(e);
											}}
											value={getValues('OtherDocs.Data.No')}
											onBlur={onBlur}
											errors={t(errors?.OtherDocs?.Data?.No?.message)}
											required={true}
										/>
									)}
									name={`OtherDocs.Data.No`}
								/>
								<div>
									<h4 className='m-0 text-grey fs-14 pb-2'>
										{t('Upload Other Docs') + ' *'}
									</h4>

									<UploadImageCard
										{...{
											disabled: !watch('OtherDocs.Data.No'),
											setImagePreviewUpload,
											imagePreviewUpload,
											isImageAvilable: imagePreviewUpload?.otherDocsImage,
											imgKey: 'otherDocsImage',
											setClickImageFieldName,
											setOpenUploadModal,
											setValue: setValue,
											value: 'OtherDocs.Url',
											title: t('Upload Other Docs'),
											src: getValues('OtherDocs.Url'),
										}}
									/>
									{!imagePreviewUpload.otherDocsImage &&
									!docTypeErrorMessage.otherDocsTypeError &&
									errors?.OtherDocs?.Url?.message ? (
										<div className=''>
											<div className='error-text sc-ion-input-md fs-12'>
												{t(errors?.OtherDocs?.Url?.message)}
											</div>
										</div>
									) : (
										docTypeErrorMessage.otherDocsTypeError && (
											<div className=''>
												<div className='error-text sc-ion-input-md fs-12 pb-2'>
													{t(docTypeErrorMessage.otherDocsTypeError)}
												</div>
											</div>
										)
									)}

									{otherDocsValidation.msg && (
										<div
											className={`my-2 fs-12 d-flex align-items-center ${
												otherDocsValidation.status
													? 'secondary-text-color'
													: 'danger-text-color'
											}`}
										>
											<img
												src={
													otherDocsValidation.status
														? 'images/icons/green-info.svg'
														: 'images/icons/red-info.svg'
												}
												className='me-2'
												width={16}
												height={16}
												alt=''
											/>
											{otherDocsValidation.msg}
										</div>
									)}
								</div>
							</div>
						</>
					)}

					{leadStatusModalOpen && (
						<CustomModalLeadStatus
							isOpen={leadStatusModalOpen}
							applicationNo={applicationNo}
							handleClose={() => onLeadStatusModalClose()}
						/>
					)}

					{/* submit button */}
					<div
						className=' w-100 my-3'
						style={{ backgroundColor: '#fff', zIndex: 9 }}
					>
						<CustomButton
							className='w-100 fw-bold dashboard-add-lead-button mb-3'
							expand='block'
							fill='outline'
							title={t('Continue Later')}
							onClick={() => {
								if (checkFieldsValidToContinue(errors)) {
									onSubmit(getValues());
								} else {
									showToastAlert({
										type: 'error',
										message: 'Please fix above errors',
									});
								}
							}}
						/>
						<CustomButton
							className='btn-blue'
							type='submit'
							expand='block'
							title={t('Submit')}
							// disabled={!isValid}
						/>
					</div>
				</form>
			</div>

			{/* Upload Photo Modal */}
			<CustomModal
				id='sharemodal'
				isOpen={openUploadModal}
				initialBreakpoint={isWeb ? 1 : 0.3}
				handleClose={() => setOpenUploadModal(false)}
				breakpoints={[0, 0.3, 0.5, 0.75]}
				modalContent={<ShareModal />}
				needCross={false}
				needArrow={false}
				className={`side-modal ${isWeb ? '' : 'modal-border-radius'}`}
			/>
		</div>
	);
};
