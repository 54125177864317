import React, { createContext, useState, useContext, ReactNode } from 'react';

interface LeadData {
    category_purpose_id?: number | null;
    city?: string;
    dob?: Date | string | null;
    doc_no?: string | null;
    email?: string | null;
    first_name?: string;
    id?: number | string;
    is_consent?: boolean | null;
    last_name?: string;
    lead_status?: string | null;
    categoryName?: string | null;
    loan_amount?: number | null;
    middle_name?: string | null;
    mobile_no?: string;
    net_monthly_income?: number | null;
    pan?: string | null;
    pincode?: string | null;
    product_category_id?: number | null;
    product_id?: number;
    salutation?: string | null;
    showdownload_report?: boolean | null;
    state?: string | null;
    updateStatus?:string | null;
    createdAt?: Date | string;
    createdDate?: Date | string;
    proinfo: {
      occupationType: string | null;
      companyName: string | null;
      designation: string | null;
      annualIncome: number | null;
      totalExperience: number | null;
      existingPolicyNumber: string | null;
      insurerName: string | null;
      planName: string | null;
      sumAuusred: number | null;
      policyStatus: string | null;
      issueDate: string | null;
      areYouOfGoodHealth: boolean | null;
  };
}

interface LeadDetailsContextType {
  leadData: LeadData | null;
  setLeadData: React.Dispatch<React.SetStateAction<LeadData | null>>;
  proinfo: any;
  setProinfo : React.Dispatch<React.SetStateAction<any>>
}

const LeadDetailsContext = createContext<LeadDetailsContextType | undefined>(undefined);

export const useLeadDetails = () => {
  const context = useContext(LeadDetailsContext);
  if (!context) {
    throw new Error('useLeadDetails must be used within a LeadDetailsProvider');
  }
  return context;
};

export const LeadDetailsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [leadData, setLeadData] = useState<LeadData | null>(null);
  const [proinfo, setProinfo] = useState({});

  return (
    <LeadDetailsContext.Provider value={{ leadData, setLeadData , proinfo , setProinfo }}>
      {children}
    </LeadDetailsContext.Provider>
  );
};
