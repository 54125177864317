import { IonLabel } from '@ionic/react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function StateCity({
	control,
	setValue,
	pinCodeData,
	cityInputName = 'city',
	stateInputName = 'state',
	trigger,
	getValues,
}: any) {
	const { t } = useTranslation();

	const cityList: any = [];
	const stateList: any = [];

	pinCodeData?.map((item: any) => {
		!cityList.includes(item?.Region) && cityList.push(item?.Region);
		!stateList.includes(item?.State) && stateList.push(item?.State);
	});
	return (
		<div className='pb-5'>
			{cityList && cityList.length > 0 && (
				<>
					<IonLabel>{t('City')}</IonLabel>
					<div className='ion-margin-bottom'>
						{/*pinCodeData.map(({ Name }: any) => {
												return (
													<Controller
														control={control}
														render={({ field: { onChange, value } }) => {
															return (
																<span
																	className={`selected-label selected-label-cursor ${
																		Name === value
																			? ' selected-label-active'
																			: ''
																	}`}
																	onClick={() => {
																		onChange(Name);
																		setValue('city', Name);
																		setPincodeValue({
																			state: pinCodeData[0].State,
																			city: Name,
																		});
																	}}
																>
																	{Name}
																</span>
															);
														}}
														name={'city'}
													/>
												);
											})*/}

						{cityList &&
							cityList.length > 0 &&
							cityList.map((item: any) => (
								<Controller
									control={control}
									render={({ field: { onChange } }) => {
										return (
											<span
												className={`selected-label selected-label-cursor ${
													getValues(cityInputName) === item
														? 'selected-label-active'
														: ''
												}`}
												onClick={() => {
													onChange(item);
													setValue(cityInputName, item);
													trigger(cityInputName);
													if (cityList.length > 0 && stateList.length > 0) {
														const selectedData = pinCodeData.find(
															(product: any) => product?.Region === item
														);

														setValue(stateInputName, selectedData?.State);
														trigger(stateInputName);
													}
												}}
											>
												{item}
											</span>
										);
									}}
									name={cityInputName}
								/>
							))}
					</div>
				</>
			)}

			{stateList && stateList.length > 0 && (
				<>
					<IonLabel>{t('State')}</IonLabel>
					<div className='ion-margin-bottom'></div>
				</>
			)}
			{stateList &&
				stateList.length > 0 &&
				stateList.map((item: any) => (
					<Controller
						control={control}
						render={({ field: { onChange } }) => {
							return (
								<span
									className={`selected-label selected-label-cursor ${
										getValues(stateInputName) === item
											? 'selected-label-active'
											: ''
									}`}
									onClick={() => {
										onChange(item);
										setValue(stateInputName, item);
										trigger(stateInputName);
										if (cityList.length > 0 && stateList.length > 0) {
											const selectedData = pinCodeData.find(
												(product: any) => product?.State === item
											);

											setValue(cityInputName, selectedData?.Region);
											trigger(cityInputName);
										}
									}}
								>
									{item}
								</span>
							);
						}}
						name={stateInputName}
					/>
				))}
		</div>
	);
}
