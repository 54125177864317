/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Header } from '../../components/layout/header/Header';
import { IonContent, IonItem, IonList, IonPage } from '@ionic/react';
import './Notification.scss';
import { FormatDate } from '../../helpers/functions';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { notificationData } from '../../store/reducers/user/userSlice';
import { useHistory } from 'react-router';
import { NotifyVisitors } from 'capacitor-plugin-notifyvisitors';

export const Notification: React.FC = () => {
	const { t } = useTranslation();
	const userData = useSelector((state: any) => state?.users);
	const dispatch = useDispatch();
	const history = useHistory();

	const [notification, setNotification] = useState([]);

	// Notification Center
	const appInboxInfo = {
		label_one: 'promotional',
		name_one: 'Promotion',

		label_two: 'transactional',
		name_two: 'Transaction',

		label_three: 'test',
		name_three: 'Test',

		selectedTabTextColor: '#FF3D00',
		unselectedTabTextColor: '#FF6D00',

		selectedTabBgColor: '#E1BEE7',
		unselectedTabBgColor_ios: '#00796B',
		selectedTabIndex_ios: '0',
		tabTextFontName_ios: 'xxx',
		tabTextFontSize_ios: '13',
	};

	const dismissValue = '0';
	const finalData = { appInboxInfo, dismissValue };

	useEffect(() => {
		NotifyVisitors.showNotifications(finalData);
	}, []);

	const getNotifications = async () => {
		await dispatch(
			notificationData({
				param: `?${userData?.user_details?.id}`,
				// param: userData?.user_details?.id,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setNotification(response?.userNotification);
				}
			});
	};
	useEffect(() => {
		getNotifications();
	}, [history?.location?.pathname]);
	return (
		<IonPage>
			{/* <Menu /> */}
			<Header
				name={t('Notifications')}
				needCross={false}
				needArrow={true}
				backButtonDisabled={false}
				backButton={true}
				needArrowOnClick={() => {
					history.push('/dashboard/home');
				}}
			/>
			<IonContent className=''>
				<div
					className={`bg-web py-md-3 ${
						notification?.length === 0 ? 'h-100' : ' '
					}`}
					style={{ minHeight: '100%' }}
				>
					<div
						className={`bg-web-white h-100 container-width-md border-md-radius-10  py-md-3 ${
							notification?.length === 0
								? 'e-franking-send-container-height'
								: ' '
						}`}
					>
						{notification?.length === 0 && (
							<div className='fs-14 fw-500 text-center text-grey h-100 d-flex flex-column justify-content-center align-items-center'>
								<img
									src='/images/icons/notification-bell.svg'
									alt='bell'
									className='mb-3'
								/>
								{t('No Notifications')}
							</div>
						)}
						{notification && notification?.length > 0 && (
							<IonList>
								{notification?.map((item: any, index) => {
									return (
										<IonItem
											key={`notification-${index}`}
											className='ion-no-padding remove-ion-item-inner-padding-end'
											lines='full'
										>
											<div className='m-3 px-3 ms-0'>
												<h6 className='fw-bold fs-14 fw-500 position-relative'>
													<span className='dot ms-3'>{item?.message}.</span>
												</h6>
												<div className='mt-2 d-flex ms-3 justify-content-start gap-3'>
													<p className='mb-0 fs-12'>
														{FormatDate(
															item?.createdAt,
															'dS mmmm yyyy h:MM TT'
														)}
													</p>
													{/* <p className='mb-0 fs-12 dot position-relative ps-3'>
												08:45 pm
											</p> */}
												</div>
											</div>
										</IonItem>
									);
								})}
							</IonList>
						)}
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};
