import React, { createContext, useState, ReactNode, useContext } from 'react';

interface FilterContextType {
  citySelection: any[];
  subProductsSelection: any[];
  insurerSelection: any[];
  statusesSelection: any[];
  lastUpdatedFromDate: string;
  lastUpdatedToDate: string;
  setCitySelection: React.Dispatch<React.SetStateAction<any[]>>;
  setSubProductsSelection: React.Dispatch<React.SetStateAction<any[]>>;
  setInsurerSelection: React.Dispatch<React.SetStateAction<any[]>>;
  setStatusesSelection: React.Dispatch<React.SetStateAction<any[]>>;
  setLastUpdatedFromDate: React.Dispatch<React.SetStateAction<string>>;
  setLastUpdatedToDate: React.Dispatch<React.SetStateAction<string>>;
  filterFlag: boolean;
  setFilterFlag: React.Dispatch<React.SetStateAction<boolean>>; 
}

const defaultState: FilterContextType = {
  citySelection: [],
  subProductsSelection: [],
  insurerSelection: [],
  statusesSelection: [],
  lastUpdatedFromDate: '',
  lastUpdatedToDate: '',
  setCitySelection: () => {},
  setSubProductsSelection: () => {}, 
  setInsurerSelection: () => {}, 
  setStatusesSelection: () => {}, 
  setLastUpdatedFromDate: () => {},
  setLastUpdatedToDate: () => {},
  filterFlag: false,
  setFilterFlag: () => {}, 
};

const FilterContext = createContext<FilterContextType>(defaultState);

export const FilterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [citySelection, setCitySelection] = useState<any[]>([]);
  const [subProductsSelection, setSubProductsSelection] = useState<any[]>([]);
  const [insurerSelection, setInsurerSelection] = useState<any[]>([]);
  const [statusesSelection, setStatusesSelection] = useState<any[]>([]);
  const [lastUpdatedFromDate, setLastUpdatedFromDate] = useState<string>('');
  const [lastUpdatedToDate, setLastUpdatedToDate] = useState<string>('');
  const [filterFlag, setFilterFlag] = useState(false);

  return (
    <FilterContext.Provider
      value={{
        citySelection,
        subProductsSelection,
        insurerSelection,
        statusesSelection,
        lastUpdatedFromDate,
        lastUpdatedToDate,
        setCitySelection,
        setSubProductsSelection,
        setInsurerSelection,
        setStatusesSelection,
        setLastUpdatedFromDate,
        setLastUpdatedToDate,
        filterFlag,
        setFilterFlag
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterContext;
