import { IonImg } from '@ionic/react';
import { useTranslation } from 'react-i18next';

export const CustomNoData = ({ message, className }: any) => {
	const { t } = useTranslation();
	return (
		<div className={className ? `${className}` : `pt-5 mt-5`}>
			<IonImg
				src='/images/icons/no-data-folder-icon.svg'
				style={{ height: '48px' }}
			/>
			<h3
				className='fs-14 text-center py-2'
				style={{ color: '#5D5E72' }}
			>
				{message ? message : `${t('No Data Found')}`}
			</h3>
		</div>
	);
};
