import { useDispatch, useSelector } from 'react-redux';
import { appLogout } from '../../store/reducers/user/userSlice';

export const PrivateRoutes = ({ children }) => {
	const dispatch = useDispatch();
	const getTokenFromLs = useSelector(
		(state) => state.users?.user_details?.access_token
	);

	if (
		getTokenFromLs === undefined ||
		getTokenFromLs === '' ||
		getTokenFromLs === null
	) {
		// setTimeout(() => {
		// }, 1200);

		dispatch(appLogout());
		window.location.href = '/login';
	} else {
		return children;
	}
};
