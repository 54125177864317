import { IonContent, IonFooter, IonImg, IonPage } from '@ionic/react';
// import { useState } from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import './GettingStarted.scss';
import gettingStartedData from './GettingStarted.json';
import { alphabetColor } from '../../helpers/functions';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { gettingStarted } from '../../store/reducers/app/appStarterTemplateSlice';

// let enableButton = false;
export const StartPage: React.FC<IStartPage> = (props) => {
	const [opacity, setOpacity] = useState(0);
	const { t } = useTranslation();
	const { onSkip, showSkip } = props;
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		autoplay: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		onInit: () => {
			setTimeout(() => {
				setOpacity(1);
			}, 110);
		},
	};
	return (
		<div
			className='h-100'
			style={{ opacity: opacity === 1 ? '1' : '0' }}
		>
			<div className='row align-items-center gs-header-bar'>
				<div className='col-6'>
					<IonImg
						src='/images/icons/dashboard-one-andro-logo.png'
						style={{ height: '18px', width: '110px' }}
					/>
				</div>
				{showSkip === true ? (
					<div className='col-6 text-end'>
						<span
							className='fs-16 fw-600'
							style={{ color: '#0165E1' }}
							onClick={() => onSkip()}
						>
							{t('Skip')}
						</span>
					</div>
				) : (
					''
				)}
			</div>
			<div
				className='d-flex justify-content-center flex-column'
				style={{
					height: '90%',
				}}
			>
				<Slider {...settings}>
					{gettingStartedData.map(
						({ header, subHeader, image }: any, index: any) => (
							<div key={header}>
								<IonImg
									src={image}
									style={{ height: '290px' }}
								/>
								<div
									className='text-center fs-22 fw-600 mt-4'
									style={{ color: alphabetColor(index) }}
								>
									{t(header)}
								</div>
								<div
									className='fs-14 m-auto pt-0 text-center'
									style={{
										borderRadius: '10px',
										padding: '14px',
									}}
								>
									{t(subHeader)}
								</div>
							</div>
						)
					)}
				</Slider>
			</div>
		</div>
	);
};

export const GettingStarted: React.FC = () => {
	const history = useHistory();

	// const [activeIndex, setActiveIndex] = useState(0);
	const { t } = useTranslation();
	const dispatch: any = useDispatch();

	// if (activeIndex === 2) {
	// 	enableButton = true;
	// }
	const getStarted = () => {
		const myPromise = new Promise(function (myResolve) {
			setTimeout(function () {
				myResolve(history.push('/login'));
			}, 10);
		});
		myPromise.then(() => {
			dispatch(gettingStarted(true));
		});
	};
	return (
		<IonPage className='getting-started'>
			<IonContent className='ion-padding-horizontal'>
				<StartPage
					onSkip={getStarted}
					showSkip={true}
				/>
			</IonContent>
			<IonFooter className='ion-padding'>
				<CustomButton
					className='btn-blue'
					// disabled={!enableButton}
					expand='block'
					onClick={getStarted}
					title={t('Get Started')}
				/>
			</IonFooter>
		</IonPage>
	);
};

interface IStartPage {
	onSkip?: any;
	showSkip?: boolean;
}
