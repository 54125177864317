/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import './CustomUpload.scss';
import { useRef } from 'react';

interface Input {
	placeholder?: string;
	label?: string;
	type?: any;
	labelPlacement?: any | undefined;
	hidden?: boolean;
	ref?: any;
}

export const CustomUpload: React.FC<Input> = ({
	placeholder,
	type,
	hidden,
}) => {
	const fileRef = useRef<HTMLInputElement>(null);
	const { t } = useTranslation();

	const focusInputFile = () => {
		fileRef.current?.click();
	};
	return (
		<div
			onClick={focusInputFile}
			className='p-3 fs-16 d-flex position-relative justify-content-center align-items-center flex-grow-1 w-100 upload'
		>
			<p className='w-100 text-center pt-3'>{t('Upload Document')}</p>
			<input
				type={type ? type : 'file'}
				placeholder={placeholder}
				className='custom-input border-0 border position-absolute'
				hidden={hidden}
				ref={fileRef}
				style={{ visibility: 'hidden' }}
			/>
		</div>
	);
};
