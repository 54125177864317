/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	IonButtons,
	IonHeader,
	IonMenuButton,
	IonTitle,
	IonToolbar,
	IonImg,
	IonIcon,
	// IonItem,
} from '@ionic/react';
import { Player } from '@lottiefiles/react-lottie-player';
import { reorderThreeOutline } from 'ionicons/icons';

import './Header.scss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { CustomModal } from '../../common/custom-modal/CustomModal';
import { useState } from 'react';
import { useSelector } from 'react-redux';

interface HeaderProps {
	type?: string;
	name: string;
	backButton?: boolean;
	backButtonDisabled?: boolean;
	backButtonText?: string;
	backButtonIcon?: any;
	backButtonSlot?: string;
	needCross?: boolean;
	needCrossOnClick?: any;
	needArrow?: boolean;
	style?: any;
	endItem?: any;
	headerBg?: any;
	titleClassName?: any;
	needArrowOnClick?: any;
	src?: any;
	isCancle?: boolean;
	onCancel?: any;
	needArrowImg?: any;
	needArrowImgStyle?: any;
	hideWelecomeText?: any;
	className?: any;
	// themeHeaderColor?: any;
}

export const Header: React.FC<HeaderProps> = ({
	type,
	name,
	backButton,
	backButtonSlot = 'start',
	needCross,
	needCrossOnClick,
	needArrow,
	style,
	endItem,
	titleClassName,
	needArrowOnClick,
	src,
	isCancle,
	onCancel,
	needArrowImg,
	needArrowImgStyle,
	headerBg,
	hideWelecomeText,
	className,
	// themeHeaderColor,
}) => {
	const { t } = useTranslation();
	const history = useHistory();
	// const homePageData = useSelector((state: any) => state?.users);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const getLocalStorageThemeColor = () => {
		return localStorage.getItem('themeHeaderColor') || '#FFFFFF';
	  };
	  

	return (
		<IonHeader
			className={`ion-no-border headr-bottom-shadow-md header ${
				type === 'dashboard' && 'dashboard'
			} ${className}`}
			style={style}
		>
			<IonToolbar
				className='px-3 px-md-0 header-bar-height-md'
				color={headerBg}
				style={{ '--background': getLocalStorageThemeColor() }}
			>
				{type === 'dashboard' ? (
					<>
						<IonButtons
							slot='start'
							className='menu-icon order-md-5 me-md-5'
						>
							<IonMenuButton id='dashboard-main-content-menu'>
								<div
									className='d-flex align-items-center flex-md-row-reverse'
									style={{
										background: '#F3F3F3',
										borderRadius: '24px',
										height: '26px',
										width: '60px',
									}}
								>
									<img
										src={src}
										className='profile-icon'
										style={{
											width: '26px',
											height: '26px',
											borderRadius: '50%',
										}}
									/>
									<IonIcon
										icon={reorderThreeOutline}
										slot='start'
										size='large'
										color='primary'
										style={{
											margin: '0 6px 0 4px',
										}}
									/>
								</div>
							</IonMenuButton>
						</IonButtons>
						<IonTitle className='text-start  ion-no-padding dashboard-profile-name'>
							<h5 className='mb-0 fs-14 darkest-grey d-flex gap-1 align-items-center'>
								<div className='sidebar-menu-container logo-container-md'>
									<IonImg
										src='/images/icons/logo.svg'
										className='ion-padding-end d-none d-md-block'
										style={{ width: '180px' }}
									/>
								</div>
								<span className='d-flex flex-column flex-md-row ms-md-5'>
									{!hideWelecomeText && t('Welcome') + ','} <br />
									<span className='fs-16 fw-600 ms-0 ms-md-2 d-inline-flex align-items-center text-capitalize'>
										<span className='dashboard-username'>
											{name ? name : null}
										</span>
										{!hideWelecomeText && (
											<IonImg
												className='ms-1'
												src='/images/icons/verified-check-mark.png'
												alt='verified'
												style={{ width: '13px', height: '13px' }}
											/>
										)}
									</span>
								</span>
							</h5>
						</IonTitle>
						<IonButtons
							slot='end'
							style={{ justifyContent: 'end' }}
							className='order-4'
						>
							<IonButtons
								style={{ width: 'fit-content' }}
								className='pe-2 cursor-pointer'
								onClick={() => setOpenModal(true)}
							>
								<img
									src={'/images/icons/home-tour-icon.svg'}
									style={{
										width: '25px',
										height: '25px',
									}}
								/>
							</IonButtons>
							<IonButtons
								style={{ width: 'fit-content' }}
								className='pe-2 cursor-pointer'
								onClick={() => history.push('/notifications')}
							>
								<Player
									// className="pe-3"
									autoplay={true}
									loop={true}
									controls={false}
									src='/images/icons/json/Notification-blue-bell.json'
									style={{ height: '25px', width: '25px' }}
								/>
							</IonButtons>
							<IonButtons
								style={{ width: 'fit-content' }}
								onClick={() => history.push('/help-desk')}
								className='cursor-pointer'
							>
								<Player
									// className="pe-3"
									autoplay={true}
									loop={true}
									controls={false}
									src='/images/icons/json/Support-blue-icon.json'
									style={{ height: '25px', width: '25px' }}
								/>
							</IonButtons>
						</IonButtons>

						{/* <CustomModal
							id='custommodal'
							modalTitle=''
							initialBreakpoint={undefined}
							className='videoplayerpopup vertical-player'
							isOpen={openModal}
							handleClose={() => setOpenModal(false)}
							breakpoints={[]}
							modalContent={
								<iframe
									src={homePageData?.homepage?.header_video_link}
									width='100%'
									height='100%'
									frameBorder='0'
									allow='autoplay; fullscreen; picture-in-picture'
									allowFullScreen
								></iframe>
							}
							needCross={false}
							needArrow={false}
							needIonContentWraper={false}
						/> */}
					</>
				) : (
					<>
						{/* start btns */}
						{backButton && (
							<IonButtons
								slot={backButtonSlot}
								className='cursor-pointer'
							>
								{needCross && (
									<IonImg
										className='mb-0'
										src='/images/icons/kyc/close-blue-icon.svg'
										alt='backarrow'
										onClick={
											needCrossOnClick
												? needCrossOnClick
												: () => {
														history.goBack();
												  }
										}
									/>
								)}
								{needArrow && (
									<IonImg
										className='mb-0'
										src={` ${
											needArrowImg
												? needArrowImg
												: '/images/icons/backarrow.svg'
										}`}
										alt='backarrow'
										style={needArrowImgStyle}
										onClick={
											needArrowOnClick
												? needArrowOnClick
												: () => {
														history.goBack();
												  }
										}
									/>
								)}
							</IonButtons>
						)}
						{/* title section header */}
						<div className={`${isCancle ? 'd-flex' : ''}`}>
							<IonTitle
								className={`ion-no-padding text-start ps-2 d-flex justify-content-sm-between position-relative`}
							>
								<h1
									className={`fs-18 m-0 fw-600 ${titleClassName}`}
									style={{ color: 'var(--text-color-darkest-grey)' }}
								>
									{t(name)}
								</h1>
							</IonTitle>
							{isCancle && (
								<span
									className='fs-16 fw-600 cursor-pointer'
									style={{ color: 'var(--ion-color-primary)' }}
									onClick={onCancel}
								>
									{t('Cancel')}
								</span>
							)}
						</div>

						{/* end  btns */}
						{endItem && endItem}
					</>
				)}
			</IonToolbar>
			{/* <div>step</div> */}
		</IonHeader>
	);
};
