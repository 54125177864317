import React, { useState, useEffect, useContext } from 'react';
import { IonPage, IonButtons, IonContent, IonImg } from '@ionic/react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Header } from '../../components/layout/header/Header';
import { t } from 'i18next';
import "./filter.scss";
import useApi from '../../api/useApi';
import { ALL_STATUS_LIST, GET_CITIES } from '../../helpers/api-endpoints';
import { AuthContext } from '../../context/AuthContext';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
import { showToastAlert } from '../../helpers/functions';
import FilterContext from '../../context/FilterContext';
import { useHistory } from 'react-router';

interface SubProduct {
  purposeName: string | null;
  display_name: string | null;
  type: string;
}

const InsuranceFilter: React.FC = () => {
  const [selectedButton, setSelectedButton] = useState<number>(0);
  const [cities, setCities] = useState<any[]>([]);
  const [statuses, setStatuses] = useState<SubProduct[]>([]);
  const [lenders, setLenders] = useState<any[]>([]);
  const [isFilterValid, setFilterValid] = useState(true);
  const history = useHistory();
  const [name1, setName] = useState('followUpDate');
  const { token, userId } = useContext(AuthContext);
  const filterCities = useApi<any>(GET_CITIES);
  const filterStatus = useApi<any>(ALL_STATUS_LIST);

  const { 
    citySelection, setCitySelection, 
    subProductsSelection, setSubProductsSelection, 
    insurerSelection, setInsurerSelection, 
    statusesSelection, setStatusesSelection,
    lastUpdatedFromDate, setLastUpdatedFromDate,
    lastUpdatedToDate, setLastUpdatedToDate,
    filterFlag, setFilterFlag
  } = useContext(FilterContext);

  useEffect(() => {
    filterCities.executeRequest("get", null, true, false);
  }, []);

  useEffect(() => {
    if (filterCities.responseData?.status) {
      setCities(filterCities.responseData?.data);
    }
  }, [filterCities.responseData]);

  useEffect(() => {
    if (filterStatus.responseData?.status) {
      setStatuses(filterStatus.responseData?.list || []);
      setLenders(filterStatus.responseData?.lender || []);
    }
  }, [filterStatus.responseData]);

  const buttonsList = [
    { id: 2, value: 'Last Updated Date', key: 'lastUpdatedDate', visible: true },
    { id: 3, value: 'Locations (Cities)', key: 'locationType', visible: true },
    { id: 4, value: 'Sub Product', key: 'subproduct', visible: true },
    { id: 5, value: 'Status', key: 'status', visible: true },
    { id: 6, value: 'Insurer', key: 'insurer', visible: true },
  ];

  const filterTabs = [
    {
      name: 'lastUpdatedDate',
      type: 'daterange',
      optionList: [],
    },
    {
      name: 'locationType',
      type: 'autocomplete',
      optionList: cities,
    },
    {
      name: 'subproduct',
      type: 'autocomplete',
      optionList: statuses,
    },
    {
      name: 'status',
      type: 'checkbox',
      optionList: statuses,
    },
    {
      name: 'insurer',
      type: 'autocomplete',
      optionList: statuses,
    },
  ];

  const clearFilters = () => {
    setCitySelection([]);
    setSubProductsSelection([]);
    setStatusesSelection([]);
    setInsurerSelection([]);
    setLastUpdatedFromDate('');
    setLastUpdatedToDate('');
    setFilterFlag(false);
  };
  
  const handleButtonClick = (index: number, key: string) => {
    setSelectedButton(index);
    setName(key);

    if (['subproduct', 'status', 'insurer'].includes(key)) {
      const payload: SubProduct = {
        type: key,
        purposeName: null,
        display_name: null,
      };
      filterStatus.executeRequest("post", payload, true);
    }
  };

  return (
    <IonPage>
      <Header
        backButton={true}
        needArrow={true}
        needArrowImg={'/images/icons/backarrow_white_bg.png'}
        needArrowImgStyle={{ height: '26px' }}
        name={t('Filters')}
        endItem={
          <IonButtons slot="end">
            <IonButtons className="fs-14 fw-600 cursor-pointer" style={{ color: 'var(--ion-color-primary)' }}
            onClick={clearFilters}>
              {t('Clear Filter')}
            </IonButtons>
          </IonButtons>
        }
      />
      <IonContent className="border">
        <div className='row h-100'>
          <div className='col-5 pe-0'>
            {buttonsList.map((buttonName, index) => (
              <button
                key={index}
                className={`filterName btn-primary m-1 fs-14 fw-600 ${buttonName.visible ? '' : ' d-none '} ${
                  selectedButton === index ? 'selected' : ' '
                }`}
                style={{
                  backgroundColor: selectedButton === index ? 'white' : '#f1f1f1',
                  color: selectedButton === index ? '#0165E1' : '#0C0E2B',
                  border: 'none',
                  borderRadius: '0',
                  width: "100%"
                }}
                onClick={() => handleButtonClick(index, buttonName.key)}
              >
                {t(buttonName?.value)}
              </button>
            ))}
          </div>
          <div className="col-7 ps-0">
            <div className="Flex ps-3 pe-2">
              {name1 === 'locationType' && (
                <Typeahead
                  id={'basic-typeahead-multiple-locationType'}
                  labelKey={'name'}
                  multiple
                  className="pe-3 mt-3"
                  onChange={(e: any) => setCitySelection(e)}
                  options={cities?.filter((i: any) => !i.checked) || []}
                  selected={citySelection || []}
                  placeholder={t('Choose Cities').toString() + ' ...'}
                  paginationText={t('Load More Cities')}
                />
              )}
              {['subproduct'].includes(name1) && (
                <Typeahead
                  id={'basic-typeahead-multiple-subproduct'}
                  labelKey={'name'}
                  multiple
                  className="pe-3 mt-3"
                  onChange={(e: any) => setSubProductsSelection(e)}
                  options={statuses.map(status => ({ name: status.purposeName }))}
                  selected={subProductsSelection || []}
                  placeholder={t('Choose Sub Products').toString() + ' ...'}
                  paginationText={t('Load More Sub Products')}
                />
              )}
              {['status'].includes(name1) && (
                <Typeahead
                  id={'basic-typeahead-multiple-subproduct'}
                  labelKey={'name'}
                  multiple
                  className="pe-3 mt-3"
                  onChange={(e: any) => setStatusesSelection(e)}
                  options={statuses?.map(status => ({ name: status.display_name }))}
                  selected={statusesSelection || []}
                  placeholder={t('Choose Status').toString() + ' ...'}
                  paginationText={t('Load More Statuses')}
                />
              )}
              {['insurer'].includes(name1) && (
                <Typeahead
                  id={'basic-typeahead-multiple-insurer'}
                  labelKey={'name'}
                  multiple
                  className="pe-3 mt-3"
                  onChange={(e: any) => setInsurerSelection(e)}
                  options={lenders.map(lender => ({ id: lender.id, name: lender.name }))}
                  selected={insurerSelection || []}
                  placeholder={t('Choose Lenders').toString() + ' ...'}
                  paginationText={t('Load More Lenders')}
                />
              )}
              {/* Date Inputs */}
              {filterTabs.map((filter, index) => (
                <div key={index} className='option-list ps-0'>
                  {selectedButton === index && (
                    <>
                      {filter.type === 'daterange' && (
                        <>
                          <CustomInput
                            placeholder={t('From Date')}
                            type={'date'}
                            needSuffixIcon={true}
                            suffixClassName={'position-absolute date-of-birth-sufix'}
                            suffixText={
                              <IonImg
                                src='/images/icons/input-dob-calendar-icon.svg'
                                alt='dob'
                              />
                            }
                            value={lastUpdatedFromDate}
                            onChange={(e: any) => {
                              if (!lastUpdatedToDate && !e.detail.value) {
                                setFilterValid(true);
                              } else if (lastUpdatedToDate && e.detail.value) {
                                setFilterValid(true);
                              } else {
                                setFilterValid(false);
                              }

                              if (new Date(e.detail.value) > new Date(new Date().toISOString().split('T')[0])) {
                                if (lastUpdatedToDate) {
                                  setFilterValid(false);
                                }
                                showToastAlert({
                                  type: 'error',
                                  message: 'Future date not allowed',
                                });
                                setLastUpdatedFromDate('');
                              } else if (lastUpdatedToDate && new Date(e.detail.value) > new Date(lastUpdatedToDate)) {
                                setFilterValid(false);
                                showToastAlert({
                                  type: 'error',
                                  message: 'Future date not allowed',
                                });
                                setLastUpdatedFromDate('');
                              } else {
                                setLastUpdatedFromDate(e.detail.value);
                              }
                            }}
                            outerClassName={'position-relative'}
                            onKeyDown={(e: any) => e.preventDefault()}
                          />

                          <CustomInput
                            placeholder={t('To Date')}
                            type={'date'}
                            needSuffixIcon={true}
                            suffixClassName={'position-absolute date-of-birth-sufix'}
                            suffixText={
                              <IonImg
                                src='/images/icons/input-dob-calendar-icon.svg'
                                alt='dob'
                              />
                            }
                            value={lastUpdatedToDate}
                            onChange={(e: any) => {
                              if (!lastUpdatedFromDate && !e.detail.value) {
                                setFilterValid(true);
                              } else if (lastUpdatedFromDate && e.detail.value) {
                                setFilterValid(true);
                              } else {
                                setFilterValid(false);
                              }

                              if (new Date(e.detail.value) > new Date(new Date().toISOString().split('T')[0])) {
                                if (lastUpdatedFromDate) {
                                  setFilterValid(false);
                                }
                                showToastAlert({
                                  type: 'error',
                                  message: 'Future date not allowed',
                                });
                                setLastUpdatedToDate('');
                              } else if (lastUpdatedFromDate && new Date(e.detail.value) < new Date(lastUpdatedFromDate)) {
                                setFilterValid(false);
                                showToastAlert({
                                  type: 'error',
                                  message: 'Future date not allowed',
                                });
                                setLastUpdatedToDate('');
                              } else {
                                setLastUpdatedToDate(e.detail.value);
                              }
                            }}
                            outerClassName={'position-relative'}
                            max={new Date().toISOString().split('T')[0]}
                            onKeyDown={(e: any) => e.preventDefault()}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              ))}
              {/* End Date Inputs */}
            </div>
          </div>
        </div>
      </IonContent>
      <div className='footer border'>
        <div></div>
        <div>
          <button
            className='btn'
            onClick={() => {
              setFilterFlag(true);
              history.push('/insurance-lead-list');
            }}
          >
            {t('Apply')}
          </button>
        </div>
      </div>
    </IonPage>
  );
};

export default InsuranceFilter;
