/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	IonContent,
	IonFooter,
	IonLabel,
	IonPage,
	IonRadio,
	IonRadioGroup,
	IonSegment,
	IonSegmentButton,
} from '@ionic/react';

import React, { useEffect, useState } from 'react';
import { Header } from '../../components/layout/header/Header';
import './DMADetails.scss';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
import { CustomCheckbox } from '../../components/common/custom-checkbox/CustomCheckbox';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
// import { AppContext } from '../../helpers/app-context';
import { useHistory } from 'react-router';
// import DropDownWitoutModal from '../../components/common/custom-dropdown/DropDownWitoutModal';
import CustomDropDown from '../../components/common/custom-dropdown/CustomDropDown';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	createPayoutApi,
	getPayoutListInfo,
	managerList,
	subProductsList,
} from '../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { disableNumberArrows, showToastAlert } from '../../helpers/functions';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';

export const AddUpdateManagerPayout: React.FC = () => {
	const dmaSelector = useSelector((state: any) => state?.users);
	const [managers, setManagers] = useState<any>([]);

	const [subProducts, setSubProductss] = useState<any>([]);
	const [selectAll, setSelectAll] = useState<boolean>(true);
	const [isFlatPayout, setIsFlatPayout] = useState<boolean>(true);
	const [flatValue, setFlatValue] = useState<any>('');
	const [error, setError] = useState(true);
	const dispatch: any = useDispatch();
	const { t } = useTranslation();
	const [already, setAlready] = useState<any>([]);
	const [ida, setIds] = useState<any>([]);
	const [newProducts, setNewProducts] = useState<any>([]);
	const [update, setUpdate] = useState(1);
	const [editId, setEditId] = useState('');
	const [confirmModal, setConfirmModal] = useState(false);
	const [key, setKey] = useState<any>(0);
	const [payout, setPayout] = useState<any>();

	const history = useHistory();
	// eslint-disable-next-line prefer-const
	let [selectedSubProducts, setSelectedSubProducts] = useState<any>([]);

	useEffect(() => {
		if (location.pathname === '/add-update-manager-payout') {
			getDetails();
			getManagerList();
			getSubProductList();
		}
	}, [key, location.pathname]);
	const getDetails = () => {
		dispatch(
			getPayoutListInfo({ param: dmaSelector?.dmaFlow?.dmaId + '/' + 1 })
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setPayout(result?.Data);

					const type = Object?.keys(result?.Data)?.[key];
					setAlready(result?.Data?.[type]?.subproducts);
					setIsFlatPayout(
						result?.Data?.[type]?.commission_type === 'fixed' ? true : false
					);
					if (!isFlatPayout) {
						setFlatValue(result?.Data?.[type]?.subproducts?.[0]?.percentage);
					}

					const ids = result?.Data?.[type]?.subproducts?.map((t: any) => {
						return t?.id;
					});
					setIds(ids);
				}
			});
	};

	const getSubProductList = () => {
		dispatch(subProductsList())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setSubProductss(result?.list);
				}
				setUpdate(update + 1);
			});
	};

	const getManagerList = async () => {
		dispatch(managerList())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setManagers(result?.list);
				}
			});
	};
	const handleBulkSelect = () => {
		if (selectAll) {
			const newdata = subProducts.map((el: any) => {
				const findIndex = selectedSubProducts.findIndex(
					(e: any) => e.sub_product_id === el?.id
				);
				return {
					sub_product_id: el?.id,
					percentage: flatValue
						? Number(flatValue)
						: selectedSubProducts[findIndex]?.percentage,
				};
			});
			setSelectedSubProducts(newdata);
		} else {
			setSelectedSubProducts([]);
			setError(true);
		}
	};
	useEffect(() => {
		const getId = managers
			?.filter((el: any) => el?.checked)
			?.map(({ id }: any) => id);
		const getName = managers
			?.filter((el: any) => el?.checked)
			?.map(({ value }: any) => value);
		const findIndex = selectedSubProducts.findIndex(
			(el: any) => el.sub_product_id === editId
		);
		const findIndex2 = newProducts.findIndex((el: any) => el.id === editId);
		const data: any = selectedSubProducts;
		const data2: any = newProducts;
		const row2: any = data2?.[findIndex2];
		if (row2) {
			row2.reporting_manager = Number(getId);
			row2.reporting_name = getName?.toString();
		}
		setNewProducts([...data2]);

		const row: any = data?.[findIndex];
		if (row) {
			row.reporting_manager = Number(getId);
			row.reporting_name = getName?.toString();
		}
		setSelectedSubProducts([...data]);
		setUpdate(update + 1);
	}, [managers]);

	useEffect(() => {
		if (isFlatPayout) {
			if (flatValue === '') {
				setError(true);
			}
		}
		for (let i = 0; i < selectedSubProducts?.length; i++) {
			if (
				selectedSubProducts[i].reporting_manager === '' ||
				selectedSubProducts[i].reporting_manager === 0 ||
				selectedSubProducts[i].reporting_manager === undefined
			) {
				setError(true);
				break;
			} else if (
				selectedSubProducts[i].percentage === undefined ||
				selectedSubProducts[i].percentage === ''
			) {
				setError(true);
				break;
			} else {
				setError(false);
			}
		}
		if (selectedSubProducts?.length === 0) {
			setError(true);
		}
	}, [selectedSubProducts, flatValue, managers, newProducts]);

	const setHandleThresHoldValue = (val: any) => {
		if (selectedSubProducts.length <= 0) {
			showToastAlert({
				type: 'error',
				message: `Please select Products First To Enter Payout Percentage`,
			});
		}
		selectedSubProducts?.forEach((item: any, index: any) => {
			selectedSubProducts[index].percentage = val ? Number(val) : flatValue;
		});
	};

	const handleParticularProduct = (e: any, id: any) => {
		if (e.detail.checked) {
			const findProduct = subProducts?.filter((el: any) => el.id === id);
			const newProductData: any = [];
			newProductData.push(...selectedSubProducts, {
				sub_product_id: findProduct[0]?.id,
				percentage: flatValue ? Number(flatValue) : '',
				reporting_manager: '',
			});
			setSelectedSubProducts(newProductData);
		} else {
			const data = selectedSubProducts?.filter(
				(i: any) => i.sub_product_id !== id
			);
			setSelectedSubProducts(data);
		}
	};

	const handleParticalProductPayout = (e: any, id: any) => {
		if (e.target.value) {
			e.target.value = e.target.value.replace('.', '');
		}
		if (selectedSubProducts.length <= 0) {
			showToastAlert({
				type: 'error',
				message: `Please select Products First To Enter Payout Percentage`,
			});
		}

		const findIndex = selectedSubProducts.findIndex(
			(el: any) => el.sub_product_id === id
		);
		const data: any = selectedSubProducts;
		const row = data[findIndex];
		row.percentage = e.target.value;
		setSelectedSubProducts([...data]);
	};
	const getActiveKeyName = () => {
		return payout && Object.keys(payout)?.[key];
	};
	useEffect(() => {
		if (isFlatPayout) {
			setFlatValue('');
		}
	}, [isFlatPayout]);

	const updatePayout = async () => {
		dispatch(
			createPayoutApi({
				param: dmaSelector?.dmaFlow?.dmaId,
				data: {
					subproduct: selectedSubProducts,
					owner_id: payout?.[getActiveKeyName()]?.owner_id,
					commission_type: isFlatPayout ? 'fixed' : 'variable',
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setConfirmModal(false);
					history.push('/manage-manager-payout', { update: true });
				} else {
					setConfirmModal(false);
				}
			});
	};

	useEffect(() => {
		const all = subProducts?.filter((g: any) => !ida?.includes(g.id));
		const alreadyAdded = already?.concat(all).map((item: any) => {
			return {
				payout_id: already[0]?.payout_id,
				id: item?.id,
				percentage: isFlatPayout ? flatValue : item?.percentage,
				category_name: item?.category_name,
				reporting_name: item?.reporting_s_name || '',
				reporting_manager: item?.reporting_s_id || '',
			};
		});
		setSelectedSubProducts(
			already?.map((item: any) => {
				return {
					sub_product_id: item?.id,
					percentage: isFlatPayout ? flatValue : item?.percentage,
					reporting_manager: item?.reporting_s_id || '',
					reporting_name: item?.reporting_s_name || '',
				};
			})
		);

		setNewProducts(alreadyAdded);
	}, [subProducts, ida, isFlatPayout, flatValue]);

	const handleBackBtn = () => {
		const myPromise = new Promise(function (myResolve) {
			setTimeout(function () {
				myResolve(history.push('/manage-manager-payout'));
			}, 10);
		});
		myPromise.then(() => {
			setIsFlatPayout(true);
			setSelectedSubProducts([]);
			setNewProducts([]);
			setFlatValue('');
		});
	};
	return (
		<IonPage>
			<Header
				name={t('Add Proposed Products & Payout')}
				backButton={true}
				needCross={true}
				needCrossOnClick={() => {
					handleBackBtn();
				}}
			/>
			<IonContent>
				<div
					className='bg-web small-container overflowY-auto'
					style={{ minHeight: '100%' }}
				>
					<div className='bg-web-white container-width-md h-100 border-md-radius-10'>
						<div className='d-flex justify-content-center border border-1 border-top-0 border-start-0 border-end-0'>
							<IonSegment
								style={{ width: 'fit-content' }}
								value='default'
								mode='md'
							>
								{payout &&
									Object?.keys(payout)?.map((item, index) => {
										return (
											<IonSegmentButton
												key={index}
												value='default'
												onClick={() => setKey(index)}
												mode='md'
											>
												<IonLabel className='fs-16 darkest-grey fw-600'>
													{t(item)}
												</IonLabel>
											</IonSegmentButton>
										);
									})}
							</IonSegment>
						</div>
						<div className='ion-padding'>
							<IonRadioGroup
								onIonChange={(e: any) => {
									if (e?.detail?.value === 'all-flat-payout') {
										setIsFlatPayout(true);
									} else {
										setIsFlatPayout(false);
									}
								}}
								value={isFlatPayout ? 'all-flat-payout' : 'per-product'}
							>
								<IonRadio
									value={'all-flat-payout'}
									labelPlacement='end'
								>
									{t('Set flat payout % for all sub-products')}
								</IonRadio>
								<IonRadio
									value={'per-product'}
									labelPlacement='end'
								>
									{t('Set payout % per sub-product')}
								</IonRadio>
							</IonRadioGroup>
							{isFlatPayout && (
								<div className='d-flex justify-content-between align-items-center mt-3'>
									<h5 className='w-75 fs-14'>{t('Enter Flat payout')}</h5>
									<CustomInput
										type='string'
										className={'my-0 text-center'}
										suffixClassName={'mb-0'}
										maxLength={2}
										style={{
											maxWidth: '60px',
											minWidth: '60px',
											width: '100%',
											marginRight: '10px',
										}}
										value={flatValue}
										needSuffixIcon={true}
										suffixText={'%'}
										onChange={(e: any) => {
											setFlatValue(e.detail.value);
											setHandleThresHoldValue(e?.detail?.value);
										}}
										onBeforeInput={(evt: any) => {
											!/[0-9]/.test(evt.data) && evt.preventDefault();
										}}
										onKeyDown={(e: any) => {
											disableNumberArrows(e);
										}}
										onWheel={(e: any) => e.target.blur()}
									/>
								</div>
							)}

							<div className='d-flex justify-content-between my-3'>
								<h5 className='fs-14 text-grey fw-600'>{t('Sub Product')}</h5>
								<h5 className='fs-14 text-grey fw-600'>{t('Payout')} %</h5>
							</div>
							<div className='d-flex flex-column gap-2'>
								<div className='d-flex gap-3 align-items-center'>
									<CustomCheckbox
										onChange={() => {
											setSelectAll(!selectAll);
											handleBulkSelect();
										}}
										isChecked={!selectAll}
										labelPlacement='end'
										// indeterminate={selectAll}
									>
										{t('Select All')}
									</CustomCheckbox>
								</div>
								{newProducts &&
									newProducts?.map((item: any, index: number) => {
										const findIndex = selectedSubProducts.findIndex(
											(el: any) => el?.sub_product_id === item?.id
										);

										const constant = isFlatPayout
											? flatValue || selectedSubProducts[findIndex]?.percentage
											: selectedSubProducts[findIndex]?.percentage;

										return (
											<div
												key={index}
												className='d-flex gap-3 align-items-center justify-content-between'
											>
												<div className='d-flex w-75 gap-1 flex-column align-items-start'>
													<CustomCheckbox
														isChecked={selectedSubProducts.some(
															(el: any) => el.sub_product_id === item?.id
														)}
														onChange={(e: any) => {
															handleParticularProduct(e, item?.id);
														}}
														className={'mb-0 pb-0 text-wrap'}
														labelPlacement={'end'}
														children={
															<>
																<span className='text-wrap fs-14 fw-600 darkest-grey'>
																	{item?.category_name}
																</span>
															</>
														}
													></CustomCheckbox>
													{findIndex !== -1 ? (
														<>
															<CustomDropDown
																{...{
																	onClick: () => setEditId(item?.id),
																	isCheckbox: false,
																	title: null,
																	title2:
																		selectedSubProducts[findIndex]
																			?.reporting_name || 'Select Manager',
																	setData: setManagers,
																	data: managers,
																	placeholder: t('Search Manager by “Name”'),
																	isSearch: true,
																	modalTitle: t('Select Manager'),
																	className: 'custom-dpdown',
																	needAltImg: '/images/icons/brarw.svg',
																	needUnselect: true,
																}}
															/>
														</>
													) : (
														''
													)}
												</div>
												<CustomInput
													type={'string'}
													className={'my-0 text-center'}
													suffixClassName={'mb-0'}
													maxLength={2}
													style={{
														maxWidth: '60px',
														minWidth: '60px',
														width: '100%',
														marginRight: '10px',
													}}
													value={constant}
													disabled={
														!isFlatPayout &&
														selectedSubProducts.some(
															(el: any) => el.sub_product_id === item?.id
														) === true
															? false
															: isFlatPayout
															? true
															: true
													}
													needSuffixIcon={true}
													onChange={(e: any) => {
														handleParticalProductPayout(e, item?.id);
													}}
													onBeforeInput={(evt: any) => {
														!/[0-9]/.test(evt.data) && evt.preventDefault();
													}}
													onKeyDown={(e: any) => {
														disableNumberArrows(e);
													}}
													onWheel={(e: any) => e.target.blur()}
													suffixText={'%'}
												/>
											</div>
										);
									})}
							</div>
						</div>
					</div>
				</div>
			</IonContent>
			<IonFooter className='ion-padding bg-web-white'>
				<div className='cibil-button'>
					<CustomButton
						disabled={error}
						title={t('Update')}
						className={'w-100'}
						onClick={() => setConfirmModal(true)}
					/>
				</div>
			</IonFooter>
			<CustomModal
				isOpen={confirmModal}
				handleClose={() => setConfirmModal(false)}
				initialBreakpoint={undefined}
				breakpoints={[]}
				className='height-auto confirm-popup-middle continue-later'
				modalContent={
					<div className='p-3'>
						<p className='fs-20 fw-bold m-0 p-2'>
							{t('Are you sure you want to update the payout?')}
						</p>
						<div className='d-flex gap-3'>
							<CustomButton
								expand='block'
								className='btn-primary-outline mb-3 w-100'
								onClick={() => setConfirmModal(false)}
								title={t('Cancel')}
							/>
							<CustomButton
								expand='block'
								className='btn-primary-large w-100'
								onClick={updatePayout}
								title={t('Confirm')}
							/>
						</div>
					</div>
				}
				needIonContentWraper={false}
				needCross={false}
				needArrow={false}
			/>
		</IonPage>
	);
};
