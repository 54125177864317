import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
	CHECK_IFSC_CODE,
	CONTRIBUTION_PIN_CODE,
	DASHBOARD_DATA,
	DASHBOARD_HOME_EARNINGS,
	DASHBOARD_HOME_MYLEAD_COUNTS,
	DASHBOARD_HOME_MYLOYALTYREWARDS,
	KYC_ADDRESSPROOF,
	KYC_BASIC_INFO,
	KYC_BASIC_INFO_KYC,
	KYC_DOCUMENT_UPLOAD,
	KYC_LEAD_PRODUCT,
	KYC_USERPROFESSION,
	KYC_USER_UPLOAD_IMAGE,
	DMAACTIVITY,
	LOGIN,
	PARTNER_REGISTRATION,
	PRODUCT_LIST,
	TEAM_DASHBOARD_USER_COUNT,
	TEAM_DASHBOARD_USER_COUNT1,
	TEAM_DMA_ABOUT,
	TEAM_DMA_BY_ID,
	TEAM_DMA_CREATE_PAYOUT,
	TEAM_DMA_INVITE,
	TEAM_DMA_LIST,
	URLUPDATE,
	TEAM_DMA_OWNER_LIST,
	TEAM_DMA_PAYOUT_LIST_2,
	TEAM_DMA_SUBPRODUCTS,
	VERIFYOTP,
	PAYOUT_REJECT_REASON,
	PANCARDVALID,
	CHECK_USER_MOBILE,
	TEAM_DMA_PAYOUT_FINALIZE,
	TEAM_DMA_UPDATE_PAYOUT,
	DASHBOARD_HOME_MYLOYALTYDETAILS,
	PROFESSIONALDETAIL_MASTERS,
	PROFESSIONALINFO_FIELDS,
	CONSTITUTION,
	INVITREFERRAL,
	USERVERIFICATIONDETAIL,
	GENERATE_CIBIL_OTP,
	NOTIFICATION,
	GET_USER_SOCIAL_LINKS,
	DIGIO,
	HELPDESK,
	HELPDESKFAQ,
	ESIGNCHECK,
	MYIDCARD,
	DOWNLOADAGREEMENT,
	TEAM_DMA_USER_INVITE,
	TEAM_DMA_VERIFY_OTP,
	PRIVACYPOLICY,
	TERMSANDCONDITIONS,
	GET_ALL_ROLES,
	INVITE_ANDRO_EMPLOYEE,
	TEAM_DMA_REJECT_REVISE,
	TEAM_DMA_PAYOUT_LIST,
	USERENOTIFICATION,
	USER_EFRANKING,
	COPS_MANAGER_ASSIGN_LIST,
	USER_ALLOCATE_MANAGER,
	PAYOUTPRODUCTINFO,
	BOPS_MANAGER_ASSIGN_LIST,
	ALLOCATOR_ALLOCATE_MANAGER,
	TEAM_DMA_DUE_DILLIGENEC,
	TEAM_DMA_USER_LIST,
	TEMA_DMA_PARENT_CHILD,
	PARTNER_USER_VERIFICATION,
	VERIFY_BANK_USER,
	PARTNER_USER_VERIFICATION_DETAIL,
	APPROVEUSERSTATUS,
	USERVERIFICATIONPAGE,
	COMPANYNAMELIST,
	DMA_ANALOGY,
	USER_DEACTIVATE,
	GET_NIMBUS_IP_DORPDOWN,
	USER_NAME,
	BANKSNAMELIST,
	FAQ_DROPDOWN,
	PARTNER_REGISTRATION_DETAILS,
	LEAD_ALLOCATE_MANAGER,
	GET_LENDERS_USER_INFO,
	REINVITE_ANDRO_EMPLOYEE,
	KYC_DOCUMENT_UPLOAD_PROFESSIONAL_DETAIL,
	KYC_DOCUMENT_UPLOAD_STEP_THREE,
	KYC_DOCUMENT_UPLOAD_STEP_FOUR,
	REFRESH_TOKEN,
	UPLOAD_HISTORY_LIST,
	DOWNLOAD_HISTORY,
	MIS_DROPDOWN,
	MIS_EXPORT,
	MIS_FILTER_DROPDOWN,
	CITYNAMELIST,
	USERNAMELIST,
	GENERATE_MOODLE_LINK,
	DISBURSED_DROPDOWN,
	DISBURSED_COUNT
} from '../../../helpers/api-endpoints';
import { apiAction } from '../../apiAction';
import { ROLE, TOKEN_NAME, get, post } from '../../../helpers/constant';
import axios from 'axios';

const initialState: any = {
	user_details: {
		access_token: '',
	},
};

export const apiLogin = apiAction('user/login', post, LOGIN);
export const apiVeryfyOtp = apiAction('user/verify-otp', post, VERIFYOTP);
export const userEfranking = apiAction('user/efranking', post, USER_EFRANKING);
export const teamsPage = apiAction(
	'api/teams/page',
	get,
	TEAM_DASHBOARD_USER_COUNT
);
export const teamsPage1 = apiAction(
	'api/teams/page1',
	get,
	TEAM_DASHBOARD_USER_COUNT1
);
export const apiKycDocumentUpload = apiAction(
	'api/upload/kyc-document',
	post,
	KYC_DOCUMENT_UPLOAD
);
export const earningData = apiAction(
	'api/myearningdata',
	get,
	DASHBOARD_HOME_EARNINGS
);
export const moodleLink = apiAction(
	'api/moodleLink',
	get,
	GENERATE_MOODLE_LINK
);

export const homePage = apiAction('api/homepage', get, DASHBOARD_DATA);

export const dashboardLeadCounts = apiAction(
	'api/dashboard/lead-counts',
	get,
	DASHBOARD_HOME_MYLEAD_COUNTS
);
export const myLoyality = apiAction(
	'api/homepage/myloyality',
	get,
	DASHBOARD_HOME_MYLOYALTYREWARDS
);
export const disburseCountDropdown= apiAction(
	'api/dma-details',
	get,
	DISBURSED_DROPDOWN
)
export const disburseCount = apiAction("api/dma-details/disbursed-count", get, DISBURSED_COUNT)

export const getdmaList = apiAction('api/dma-list', get, TEAM_DMA_LIST);
export const getdmaAbout = apiAction('api/dma/about', get, TEAM_DMA_ABOUT);
export const getdmaInfo = apiAction('api/dma/info', get, TEAM_DMA_BY_ID);
export const getDmaActivity = apiAction('api/dma/activity', get, DMAACTIVITY);
export const getProductList = apiAction('api/product-list', get, PRODUCT_LIST);
export const getFaqTypes = apiAction('api/faq-types', get, FAQ_DROPDOWN);
export const getMisReports = apiAction('api/mis-reports', get, MIS_DROPDOWN);
export const getMisFilters = apiAction(
	'api/mis-filters',
	get,
	MIS_FILTER_DROPDOWN
);
export const getMisExport = apiAction('api/mis-export', get, MIS_EXPORT);
export const getProfessionalDetailsMaster = apiAction(
	'api/professional-details-master',
	get,
	PROFESSIONALDETAIL_MASTERS
);
export const getProfessionalFields = apiAction(
	'api/lead/fields_data',
	get,
	PROFESSIONALINFO_FIELDS
);
export const getLenderUserInfo = apiAction(
	'api/lead/get_lenders_user_info',
	post,
	GET_LENDERS_USER_INFO
);
export const apiDmaInvite = apiAction(
	'api/dma/invite',
	post,
	TEAM_DMA_USER_INVITE
);
export const teamDmaMarkParentChild = apiAction(
	'api/mark-parent-child',
	post,
	TEMA_DMA_PARENT_CHILD
);
export const teamDmaUserList = apiAction(
	'api/dma-user-list',
	get,
	TEAM_DMA_USER_LIST
);
export const dmaRegister = apiAction(
	'api/dma_Registaer',
	post,
	PARTNER_REGISTRATION
);
export const getdmaRegisterDetails = apiAction(
	'api/dma_Register_details',
	get,
	PARTNER_REGISTRATION_DETAILS
);

export const kycBasicInfo = apiAction(
	'api/kyc-basic-info',
	get,
	KYC_BASIC_INFO
);

export const setKycBasicInfo = apiAction(
	'api/set-kyc-basic-info',
	post,
	KYC_BASIC_INFO_KYC
);

export const kycDocumnetUpload = apiAction(
	'api/kyc-documnet-upload',
	post,
	KYC_DOCUMENT_UPLOAD
);

export const kycDocumnetUploadProfessionalDetail = apiAction(
	'api/kyc-documnet-upload-professional_detail',
	post,
	KYC_DOCUMENT_UPLOAD_PROFESSIONAL_DETAIL
);
export const kycDocumnetUploadBank = apiAction(
	'api/kyc-documnet-upload-bank',
	post,
	KYC_DOCUMENT_UPLOAD_STEP_FOUR
);

export const kycDocumnetUploadStepTwo = apiAction(
	'api/kyc-documnet-upload-step-two',
	post,
	KYC_DOCUMENT_UPLOAD_STEP_THREE
);

export const imgUrlUpdate = apiAction('api/img-url-update', post, URLUPDATE);

export const helpDeskFaq = apiAction('api/help-desk-faq', get, HELPDESKFAQ);

export const myIdCard = apiAction('api/my-id-card', get, MYIDCARD);

export const kycLeadProduct = apiAction(
	'api/kyc-lead-product',
	get,
	KYC_LEAD_PRODUCT
);
export const dmaAnalogy = apiAction('api/dma-analogy', get, DMA_ANALOGY);

export const kycUserUploadImage = apiAction(
	'api/kyc-user-upload-image',
	post,
	KYC_USER_UPLOAD_IMAGE
);

export const kycUserProfession = apiAction(
	'api/kyc-user-profession',
	get,
	KYC_USERPROFESSION
);

export const kycAddressProof = apiAction(
	'api/kyc-address-prood',
	get,
	KYC_ADDRESSPROOF
);

export const contributionPinCode = apiAction(
	'api/contribution-pin-code',
	post,
	CONTRIBUTION_PIN_CODE
);

export const checkIfscCode = apiAction(
	'api/check-ifsc-code',
	post,
	CHECK_IFSC_CODE
);
export const getPayoutListInfo = apiAction(
	'api/dma_payout',
	get,
	TEAM_DMA_PAYOUT_LIST_2
);
export const inviteDma = apiAction('api/invite-dma', post, TEAM_DMA_INVITE);
export const managerList = apiAction(
	'api/manager-list',
	get,
	TEAM_DMA_OWNER_LIST
);
export const subProductsList = apiAction(
	'api/sub-products-list',
	get,
	TEAM_DMA_SUBPRODUCTS
);
export const createPayoutApi = apiAction(
	'api/create-payout',
	post,
	TEAM_DMA_CREATE_PAYOUT
);
export const apiReasonList = apiAction(
	'api/reason-list',
	get,
	PAYOUT_REJECT_REASON
);
export const apiPanCardValid = apiAction(
	'api/check-valid-pan',
	post,
	PANCARDVALID
);

export const checkUserMobile = apiAction(
	'api/check-user-mobile',
	get,
	CHECK_USER_MOBILE
);
export const apiFinalizePayout = apiAction(
	'api/finalize-payout',
	post,
	TEAM_DMA_PAYOUT_FINALIZE
);
export const apiUpdatePayout = apiAction(
	'api/update-payout',
	post,
	TEAM_DMA_UPDATE_PAYOUT
);

export const invitereferral = apiAction(
	'api/invitereferral',
	get,
	INVITREFERRAL
);
export const apiMyLoyalityLink = apiAction(
	'api/my-loyality-link',
	get,
	DASHBOARD_HOME_MYLOYALTYDETAILS
);

export const constitution = apiAction('api/constitution', get, CONSTITUTION);

export const userVerificationDetail = apiAction(
	'api/user-verification-detail',
	get,
	USERVERIFICATIONDETAIL
);

export const generateCibilOtp = apiAction(
	'api/generate-cibil-otp',
	post,
	GENERATE_CIBIL_OTP
);

export const notificationData = apiAction(
	'api/notification',
	get,
	NOTIFICATION
);

export const getUserSocialLinks = apiAction(
	'api/user-social-links',
	get,
	GET_USER_SOCIAL_LINKS
);

export const privacyPolicy = apiAction(
	'api/privacy-policy',
	get,
	PRIVACYPOLICY
);

export const esigncheck = apiAction('api/esign-check', post, ESIGNCHECK);
export const digio = apiAction('api/digio', post, DIGIO);

export const helpdesk = apiAction('api/helpdesk', get, HELPDESK);

export const downloadAgreement = apiAction(
	'api/download-agreement',
	get,
	DOWNLOADAGREEMENT
);

export const teamDmaVerifyOtp = apiAction(
	'api/team-dma-verify-otp',
	post,
	TEAM_DMA_VERIFY_OTP
);
export const dmaDueDilligence = apiAction(
	'api/due-dilligence',
	post,
	TEAM_DMA_DUE_DILLIGENEC
);

export const termsAndConditions = apiAction(
	'api/terms-and-conditions',
	get,
	TERMSANDCONDITIONS
);

export const inviteAndroEmployee = apiAction(
	'api/invite-andro-Employee',
	post,
	INVITE_ANDRO_EMPLOYEE
);

export const resendAndroEmployeeInvite = apiAction(
	'api/resend-andro-employee-invite',
	post,
	REINVITE_ANDRO_EMPLOYEE
);

export const teamDmaRejectRevise = apiAction(
	'api/team-dma-reject-revise',
	post,
	TEAM_DMA_REJECT_REVISE
);

export const teamDmaPayoutList = apiAction(
	'api/team-dma-payout-list',
	get,
	TEAM_DMA_PAYOUT_LIST
);

export const userENotifiaction = apiAction(
	'api/e-notification',
	post,
	USERENOTIFICATION
);

export const copsManagerAssignList = apiAction(
	'api/c-ops-manager-assign-list',
	get,
	COPS_MANAGER_ASSIGN_LIST
);

export const userAllocateManager = apiAction(
	'api/user-allocate-manager',
	post,
	USER_ALLOCATE_MANAGER
);

export const leadAllocateManager = apiAction(
	'api/lead-allocate-manager',
	post,
	LEAD_ALLOCATE_MANAGER
);

export const payoutProductInfo = apiAction(
	'api/payout-product-info',
	get,
	PAYOUTPRODUCTINFO
);

export const bOpsManagerList = apiAction(
	'api/b-ops-manager-list',
	get,
	BOPS_MANAGER_ASSIGN_LIST
);
export const partnerUserVerification = apiAction(
	'api/partner-user-verification',
	post,
	PARTNER_USER_VERIFICATION
);
export const partnerUserVerificationDetail = apiAction(
	'api/partner-user-verification-detail',
	get,
	PARTNER_USER_VERIFICATION_DETAIL
);
export const allocatorAllocateManager = apiAction(
	'api/allocator-allocate-manager',
	post,
	ALLOCATOR_ALLOCATE_MANAGER
);
export const verifyBankUserId = apiAction(
	'api/verify-bank-user',
	post,
	VERIFY_BANK_USER
);

export const approveUserStatus = apiAction(
	'api/approve-user-status',
	post,
	APPROVEUSERSTATUS
);

export const userVerificationPage = apiAction(
	'api/user-verification-page',
	get,
	USERVERIFICATIONPAGE
);

export const getCompanyNameList = apiAction(
	'api/company-name-list',
	get,
	COMPANYNAMELIST
);
export const getCityNameList = apiAction(
	'api/city-name-list',
	get,
	CITYNAMELIST
);
export const getUserNameList = apiAction(
	'api/user_data_role_base',
	get,
	USERNAMELIST
);

export const getBanksList = apiAction(
	'api/banks-name-list',
	get,
	BANKSNAMELIST
);

export const userDeactivate = apiAction(
	'api/user-deactivate',
	get,
	USER_DEACTIVATE
);

export const getAllRoles = apiAction('api/get-all-roles', get, GET_ALL_ROLES);

export const getNimbusIp = apiAction(
	'api/get-nimbus-ip',
	get,
	GET_NIMBUS_IP_DORPDOWN
);

export const getUploadHistory = apiAction(
	'api/get-upload-history',
	get,
	UPLOAD_HISTORY_LIST
);

export const getDownloadHistory = apiAction(
	'api/get-download-history',
	get,
	DOWNLOAD_HISTORY
);

export const refreshTokenNew: any = createAsyncThunk(
	'refreshToken',
	async (data, thunkAPI: any) => {
		try {
			axios.defaults.headers.common.Authorization =
				'Bearer ' + thunkAPI.getState().users.user_details.refresh_token;

			const response = await axios.post(
				`${process.env.REACT_APP_API_ENDPOINT}${REFRESH_TOKEN}`
			);
			return response.data;
		} catch (err: any) {
			return err.response.status;
			// Use `err.response.data` as `action.payload` for a `rejected` action,
			// by explicitly returning it using the `rejectWithValue()` utility
		}
	}
);

export const userName = apiAction('api/get-user-name', get, USER_NAME);

export const resetUserState = createAction('Reset_all');

export const appLogout = createAction('logout');

const userSlice: any = createSlice({
	name: 'user',
	initialState: initialState,
	reducers: {
		selfDMARegister: (state: any, action) => {
			state.registerUser = action.payload;
		},
		needFooter: (state: any, action) => {
			state.needFooter = action.payload;
		},
		dmaFlowData: (state: any, action) => {
			state.dmaFlow = action.payload;
		},
		empFlowData: (state: any, action) => {
			state.empFlow = action.payload;
		},
		productFlowData: (state: any, action) => {
			state.productFlow = action.payload;
		},
		kycStep: (state: any, action) => {
			state.kycStep = action.payload;
		},
		aadhaarName: (state: any, action) => {
			state.aadhaarName = action.payload;
		},
		customSuccessfull: (state: any, action: any) => {
			state.customSuccessfull = action.payload;
		},
		useDetails: (state: any, action: any) => {
			state.user_details = action.payload;
		},
		changeToken: (state: any, action: any) => {
			state.user_details.access_token = action.payload;
		},
		changeRefToken: (state: any, action: any) => {
			state.user_details.refresh_token = action.payload;
		},

		userToken: (state: any, action: any) => {
			state.userToken = action.payload;
		},
		dmaAbout: (state: any, action: any) => {
			state.dmaAbout = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(homePage.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(homePage.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.homepage = action?.payload?.data;
			})
			.addCase(homePage.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(refreshTokenNew.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(refreshTokenNew.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.user_details.access_token = action?.payload?.access_token;
				state.user_details.refresh_token = action?.payload?.refresh_token;
			})
			.addCase(refreshTokenNew.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})

			.addCase(teamsPage.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(teamsPage.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.teamscount = action.payload?.dma?.[0];
			})
			.addCase(teamsPage.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})

			.addCase(teamsPage1.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(teamsPage1.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.teamscount = action.payload?.dma?.[0];
			})
			.addCase(teamsPage1.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(apiLogin.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(apiLogin.fulfilled, (state: any, action: any) => {
				state.isLoading = false;
				state.user = action?.payload;
			})
			.addCase(apiLogin.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(apiVeryfyOtp.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(apiVeryfyOtp.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.user_details = action?.payload;
			})
			.addCase(apiVeryfyOtp.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(earningData.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(earningData.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.earning_data = action?.payload;
			})
			.addCase(earningData.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(moodleLink.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(moodleLink.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.moodle_login_link = action?.payload?.url;
			})
			.addCase(moodleLink.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(dashboardLeadCounts.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(dashboardLeadCounts.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.lead_count = action?.payload?.leads?.[0];
				state.lead_video = action?.payload?.video_link;
			})
			.addCase(dashboardLeadCounts.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(myLoyality.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(myLoyality.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.myloyality = action?.payload;
			})
			.addCase(myLoyality.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(getProductList.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getProductList.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.productList = action?.payload?.product_list;
			})
			.addCase(getProductList.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(getFaqTypes.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getFaqTypes.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.loyality_link = action?.payload;
			})
			.addCase(getFaqTypes.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(getMisReports.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getMisReports.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.loyality_link = action?.payload;
			})
			.addCase(getMisReports.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(getMisFilters.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getMisFilters.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.loyality_link = action?.payload;
			})
			.addCase(getMisFilters.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(getMisExport.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getMisExport.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.loyality_link = action?.payload;
			})
			.addCase(getMisExport.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			// .addCase(dmaList.pending, (state: any) => {
			.addCase(getdmaList.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getdmaList.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.dmaList = action?.payload?.list;
			})
			.addCase(getdmaList.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(dmaRegister.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(dmaRegister.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(dmaRegister.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})

			.addCase(getdmaRegisterDetails.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getdmaRegisterDetails.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(getdmaRegisterDetails.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})

			.addCase(kycBasicInfo.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(kycBasicInfo.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.kycBasicInfo = action?.payload;
			})
			.addCase(kycBasicInfo.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(setKycBasicInfo.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(setKycBasicInfo.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(setKycBasicInfo.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(kycDocumnetUpload.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(kycDocumnetUpload.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(kycDocumnetUpload.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(imgUrlUpdate.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(imgUrlUpdate.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(imgUrlUpdate.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(kycUserUploadImage.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(kycUserUploadImage.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(kycUserUploadImage.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(kycLeadProduct.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(kycLeadProduct.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(kycLeadProduct.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(kycAddressProof.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(kycAddressProof.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(kycAddressProof.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(contributionPinCode.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(contributionPinCode.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(contributionPinCode.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(getdmaAbout.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getdmaAbout.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.dmaAbout = action?.payload?.data;
			})
			.addCase(getdmaAbout.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(getdmaInfo.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getdmaInfo.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.dmaInfo = action?.payload?.user;
			})
			.addCase(getdmaInfo.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(getDmaActivity.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getDmaActivity.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.dmaActivity = action?.payload;
			})
			.addCase(getDmaActivity.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(getPayoutListInfo.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getPayoutListInfo.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.dmaPayout = action?.payload;
			})
			.addCase(getPayoutListInfo.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(inviteDma.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(inviteDma.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(inviteDma.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(managerList.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(managerList.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.managerlist = action.payload.list;
			})
			.addCase(managerList.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(subProductsList.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(subProductsList.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.subProductlist = action.payload.list;
			})
			.addCase(subProductsList.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(createPayoutApi.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(createPayoutApi.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(createPayoutApi.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(apiReasonList.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(apiReasonList.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.rej_reasons = action?.payload?.data;
			})
			.addCase(apiReasonList.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(checkUserMobile.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(checkUserMobile.fulfilled, (state: any) => {
				state.isLoading = false;
			})
			.addCase(checkUserMobile.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
			})

			.addCase(apiFinalizePayout.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(apiFinalizePayout.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.payout_finalize = action?.payload;
			})
			.addCase(apiFinalizePayout.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(apiUpdatePayout.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(apiUpdatePayout.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.payout_update = action?.payload;
			})
			.addCase(apiUpdatePayout.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(apiMyLoyalityLink.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(apiMyLoyalityLink.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.loyality_link = action?.payload;
			})
			.addCase(apiMyLoyalityLink.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(getProfessionalDetailsMaster.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getProfessionalDetailsMaster.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.loyality_link = action?.payload;
			})
			.addCase(getProfessionalDetailsMaster.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(getProfessionalFields.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getProfessionalFields.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.loyality_link = action?.payload;
			})
			.addCase(getProfessionalFields.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(getLenderUserInfo.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getLenderUserInfo.fulfilled, (state: any, action) => {
				state.isLoading = false;
				state.loyality_link = action?.payload;
			})
			.addCase(getLenderUserInfo.rejected, (state: any, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			.addCase(constitution.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(constitution.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(constitution.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(invitereferral.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(invitereferral.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(invitereferral.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(userVerificationDetail.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(userVerificationDetail.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(userVerificationDetail.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(generateCibilOtp.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(generateCibilOtp.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(generateCibilOtp.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(notificationData.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(notificationData.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(notificationData.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(getUserSocialLinks.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getUserSocialLinks.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(getUserSocialLinks.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(digio.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(digio.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(digio.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(helpdesk.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(helpdesk.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(helpdesk.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(helpDeskFaq.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(helpDeskFaq.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(helpDeskFaq.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(esigncheck.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(esigncheck.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(esigncheck.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(myIdCard.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(myIdCard.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(myIdCard.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(downloadAgreement.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(downloadAgreement.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(downloadAgreement.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(apiDmaInvite.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(apiDmaInvite.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(apiDmaInvite.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(teamDmaVerifyOtp.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(teamDmaVerifyOtp.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(teamDmaVerifyOtp.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(privacyPolicy.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(privacyPolicy.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(privacyPolicy.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(termsAndConditions.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(termsAndConditions.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(termsAndConditions.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(getAllRoles.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getAllRoles.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(getAllRoles.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(getUploadHistory.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getUploadHistory.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(getUploadHistory.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(inviteAndroEmployee.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(inviteAndroEmployee.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(inviteAndroEmployee.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(teamDmaRejectRevise.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(teamDmaRejectRevise.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(teamDmaRejectRevise.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(teamDmaPayoutList.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(teamDmaPayoutList.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(teamDmaPayoutList.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(userEfranking.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(userEfranking.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(userEfranking.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(userENotifiaction.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(userENotifiaction.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(userENotifiaction.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(copsManagerAssignList.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(copsManagerAssignList.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(copsManagerAssignList.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(userAllocateManager.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(userAllocateManager.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(userAllocateManager.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(payoutProductInfo.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(payoutProductInfo.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(payoutProductInfo.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(bOpsManagerList.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(bOpsManagerList.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(bOpsManagerList.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(allocatorAllocateManager.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(allocatorAllocateManager.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(allocatorAllocateManager.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(dmaDueDilligence.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(dmaDueDilligence.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(dmaDueDilligence.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(teamDmaUserList.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(teamDmaUserList.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(teamDmaUserList.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(teamDmaMarkParentChild.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(teamDmaMarkParentChild.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(teamDmaMarkParentChild.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(partnerUserVerification.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(partnerUserVerification.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(partnerUserVerification.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(verifyBankUserId.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(verifyBankUserId.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(verifyBankUserId.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(partnerUserVerificationDetail.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(partnerUserVerificationDetail.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(partnerUserVerificationDetail.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(approveUserStatus.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(approveUserStatus.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(approveUserStatus.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(userVerificationPage.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(userVerificationPage.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(userVerificationPage.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(getCompanyNameList.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getCompanyNameList.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(getCompanyNameList.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(getCityNameList.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(getCityNameList.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(getCityNameList.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(dmaAnalogy.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(dmaAnalogy.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(dmaAnalogy.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})
			.addCase(userDeactivate.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(userDeactivate.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(userDeactivate.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(userName.pending, (state: any) => {
				state.isLoading = true;
			})
			.addCase(userName.fulfilled, (state: any) => {
				state.isLoading = false;
				// state.loyality_link = action?.payload;
			})
			.addCase(userName.rejected, (state: any) => {
				state.isLoading = false;
				state.isError = true;
				// state.message = action.payload;
			})

			.addCase(appLogout, () => {
				// commented for preserving slice state clearing manually by actions
				// localStorage.removeItem('persist:root');
				localStorage.removeItem(TOKEN_NAME);
				localStorage.removeItem(ROLE);
			})

			.addCase(resetUserState, () => initialState);
	},
});
export const {
	selfDMARegister,
	needFooter,
	kycStep,
	dmaFlowData,
	aadhaarName,
	empFlowData,
	customSuccessfull,
	productFlowData,
	useDetails,
	userToken,
	changeToken,
	changeRefToken,
	dmaAbout,
} = userSlice.actions;
export default userSlice.reducer;
