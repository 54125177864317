import { IonContent, IonFooter } from '@ionic/react';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory, useLocation } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	getLeadUmDetails,
	lenderSpfDetailsFlow,
	saveLenderSpcfInfo,
} from '../../../store/reducers/lead/leadSlice';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import {
	ContinueLaterPopUp,
	checkFieldsValidToContinue,
	disableNumberArrows,
	showToastAlert,
} from '../../../helpers/functions';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { LenderNameForAPICall } from '../../../helpers/lenders';
import { OtherInfo, OtherInfoSchema } from './KreditBeeInfo';
import { REGEX } from '../../../helpers/constant';
import './KreditBee.scss';
import { CustomModalLeadStatus } from '../../../components/common';
import { OfferRejectedMsg } from '../OfferRejectedMsg/OfferRejectedMsg';

export const KreditBeePersonalInfoForm: React.FC<any> = () => {
	const [modalPageClose, setModalPageClose] = useState(false);
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const [leadStatusModalOpen, setLeadStatusModalOpen] = useState(false);
	const [applicationNo, setApplicationNo] = useState(null);
	const history = useHistory();
	const location = useLocation<any>();
	const dispatch = useDispatch();
	const { selectedLender, lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);
	const [netMonthlyIncomeDisableCheck, setNetMonthlyIncomeDisableCheck] =
		useState(false);

	const {
		handleSubmit,
		control,
		getValues,
		reset,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(OtherInfoSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			first_name: '',
			middle_name: '',
			last_name: '',
			email: '',
			mobile_no: '',
			dob: '',
			pan: '',
			pincode: '',
			loan_amount: '',
			net_monthly_income: null,
		},
	});

	useEffect(() => {
		if (location.pathname !== '/lead-lender-specific-form-steps') {
			return;
		}
		getLeadData();
	}, [location.pathname]);

	/**
	 * Get lenders form data
	 */
	const getLeadData = async () => {
		await dispatch(getLeadUmDetails({ param: leadFlowData?.leadId }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setNetMonthlyIncomeDisableCheck(
						typeof result?.proinfo.net_monthly_income === 'number'
					);
					reset({
						first_name: result?.data.first_name,
						middle_name: result?.data.middle_name,
						last_name: result?.data.last_name,
						email: result?.data.email,
						mobile_no: result?.data.mobile_no,
						dob: result?.data.dob,
						pan: result?.data.pan,
						pincode: result?.data.pincode,
						loan_amount: result?.data.loan_amount,
						net_monthly_income: result?.proinfo.net_monthly_income,
					});
				}
			});
	};

	/**
	 * On submit to save user data
	 */
	const onSubmit = async () => {
		const req: any = {
			lender_spf_details: {
				net_monthly_income: getValues('net_monthly_income'),
			},
			step: 'step2',
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.KREDITBEE,
			isNewLead: true,
		};

		if (lenderSpecificDetails?.lead_spf_detail_id) {
			req.lead_spf_detail_id = lenderSpecificDetails?.lead_spf_detail_id;
		}

		setShowOfferErrMsg('');
		await dispatch(saveLenderSpcfInfo({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					if (
						result?.data?.state == 'eligible' ||
						result?.data?.state == 'confirm'
					) {
						setApplicationNo(result?.data?.andro_id);
						setLeadStatusModalOpen(true);
						return;
					} else {
						dispatch(
							lenderSpfDetailsFlow({
								...lenderSpecificDetails,
								lender_spf_details: {
									net_monthly_income: getValues('net_monthly_income'),
								},
								lead_spf_detail_id: result.data.lead_spf_detail_id,
								step: 'step2',
								termsConditions: result.data.model || [],
							})
						);
					}
				} else {
					setShowOfferErrMsg(result?.error?.message || result?.message);
				}
			});
	};

	/**
	 * On close of lead status modal redirect to external URL
	 */
	const onLeadStatusModalClose = () => {
		setLeadStatusModalOpen(false);
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
							<form onSubmit={handleSubmit(onSubmit)}>
								{OtherInfo.map(
									(
										{ name, type, placeholder, isDisabled }: any,
										index: any
									) => {
										const errorText: any =
											errors && Object.keys(errors).length > 0 && errors;
										return (
											<div key={index}>
												<Controller
													control={control}
													render={({ field: { onChange, onBlur, name } }) => (
														<CustomInput
															type={type}
															label={t(placeholder)}
															disabled={
																name === 'net_monthly_income' &&
																netMonthlyIncomeDisableCheck === false
																	? false
																	: isDisabled
															}
															onBlur={onBlur}
															id={name}
															onChange={(e: any) => {
																onChange(e);
															}}
															value={getValues(name)}
															maxLength={
																name === 'net_monthly_income' ? 15 : null
															}
															onBeforeInput={(evt: any) => {
																if (type == 'number') {
																	!REGEX.ALLOWNUMBERS.test(evt.data) &&
																		evt.preventDefault();
																}
															}}
															onKeyDown={(e: any) => {
																if (type == 'number') {
																	disableNumberArrows(e);
																}
															}}
															onWheel={(e: any) => {
																if (type == 'number') {
																	e.target.blur();
																}
															}}
															errors={t(errorText[name]?.message || '')}
														/>
													)}
													name={name}
												/>
											</div>
										);
									}
								)}
							</form>
							<div className={` d-none d-md-block`}>
								<CustomButton
									className='w-100 fw-bold dashboard-add-lead-button mb-3'
									expand='block'
									fill='outline'
									title={t('Continue Later')}
									onClick={() => {
										if (checkFieldsValidToContinue(errors)) {
											setModalPageClose(true);
										} else {
											showToastAlert({
												type: 'error',
												message: 'Please fix above errors',
											});
										}
									}}
								/>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				)}
				<CustomModal
					needIonContentWraper={false}
					isOpen={modalPageClose}
					initialBreakpoint={undefined}
					handleClose={() => setModalPageClose(false)}
					breakpoints={[]}
					className='height-auto confirm-popup-middle continue-later'
					modalContent={ContinueLaterPopUp(
						() => {
							setModalPageClose(false);
						},
						() => {
							new Promise(function (myResolve) {
								setTimeout(function () {
									myResolve(history.push('/dashboard/lead'));
									// onSubmit();
									setModalPageClose(false);
								}, 30);
							});
						},
						'Cancel',
						'Continue Later',
						'className',
						'Are You Sure You Want to Exit?',
						`${t('You can continue later from where you left off.')}`
					)}
					needCross={false}
					needArrow={false}
				/>
			</IonContent>

			{leadStatusModalOpen && (
				<CustomModalLeadStatus
					isOpen={leadStatusModalOpen}
					applicationNo={applicationNo}
					handleClose={() => onLeadStatusModalClose()}
				/>
			)}

			{!showOfferErrMsg && (
				<IonFooter className='d-block d-md-none position-relative'>
					<div
						style={{
							backgroundColor: '#fff',
							zIndex: 2,
						}}
					>
						<div className={`ion-padding`}>
							<CustomButton
								className='w-100 fw-bold dashboard-add-lead-button mb-3'
								expand='block'
								fill='outline'
								title={t('Continue Later')}
								onClick={() => {
									if (checkFieldsValidToContinue(errors)) {
										setModalPageClose(true);
									} else {
										showToastAlert({
											type: 'error',
											message: 'Please fix above errors',
										});
									}
								}}
							/>
							<div className='proceed-btn'>
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					</div>
				</IonFooter>
			)}
		</>
	);
};
