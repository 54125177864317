import { IonImg } from '@ionic/react';
import { AddDocument } from './AddDocument';
import { getFilenameByUrl } from '../../../helpers/functions';

export const UploadImageCard = ({
	disabled,
	setImagePreviewUpload,
	imagePreviewUpload,
	setValue,
	value,
	setClickImageFieldName,
	setOpenUploadModal,
	isImageAvilable,
	title,
	imgKey,
	src,
}: // setUploadedFileName,
any) => {
	const fileName = getFilenameByUrl(src);
	return (
		<div
			className='d-flex justify-content-center align-items-center mb-1 position-relative cursor-pointer'
			onClick={() => {
				setClickImageFieldName(value);
				setOpenUploadModal(true);
			}}
			style={{
				pointerEvents: disabled ? 'none' : 'auto',
				opacity: disabled ? '0.40' : '1',
				borderRadius: '5px',
				color: '#D3D3E2',
				border: '1px dashed #D3D3E2',
				// width: '160px',
				minHeight: '80px',
			}}
		>
			{isImageAvilable ? (
				<div
					className='justify-content-between d-flex overflow-hidden'
					style={{
						border: '1px solid var(--ion-border-color)',
						borderRadius: '4px',
						margin: '6px',
						width: 'calc(100% - 12px)',
					}}
				>
					<div className='d-flex align-items-center w-100'>
						<div
							style={{
								padding: '15px 12px',
								background: '#e9f0fb',
								borderRadius: '4px',
								margin: '6px',
							}}
						>
							<IonImg
								src='/images/icons/kyc/document-upload-icon.svg'
								style={{ width: '21px', height: '24px' }}
							/>
						</div>
						<h6
							className='m-1 fs-14 fw-600'
							style={{
								color: 'var(--text-color-darkest-grey)',
								wordBreak: 'break-word',
							}}
						>
							{fileName}
						</h6>
					</div>

					<div
						// className='position-absolute'
						onClick={(e) => {
							e.stopPropagation();
							setValue(value, '');
							setImagePreviewUpload({
								...imagePreviewUpload,
								[`${imgKey}`]: false,
							});
							// setUploadedFileName('');
						}}
						className='me-3'
						style={{
							width: '25px',
							display: 'flex',
							justifyContent: 'center',
							cursor: 'pointer',
							top: '-10px',
							right: '-10px',
						}}
					>
						<img
							src='/images/icons/border-close.svg'
							alt='close button'
						/>
					</div>
				</div>
			) : (
				<AddDocument title={title} />
			)}
		</div>
	);
};
