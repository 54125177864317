import {
	IonContent,
	IonItem,
	IonList,
	IonPage,
	// IonTextarea,
} from '@ionic/react';
import { Header } from '../../components/layout/header/Header';
import { FormatDate } from '../../helpers/functions';
import { useEffect, useState } from 'react';
// import { CustomButton } from '../../components/common/custom-button/CustomButton';
import './LeadDetails.scss';
import { useTranslation } from 'react-i18next';
import { viewLeadComments } from '../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
export const LeadComments: React.FC = () => {
	const { t } = useTranslation();
	// const [comment, setComment] = useState('');
	const [commentList, setCommentList] = useState<any>([]);
	const [updateApi] = useState(1);
	const leadSelector = useSelector((state: any) => state?.lead?.leadFlowData);
	const history = useHistory();

	useEffect(() => {
		getComment();
	}, [updateApi, leadSelector?.update]);
	const dispatch = useDispatch();

	const getComment = async () => {
		await dispatch(viewLeadComments({ param: leadSelector?.leadId }))
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setCommentList(response?.comment);
				}
			});
	};
	return (
		<IonPage>
			<Header
				name={t('Comments')}
				backButton={true}
				needArrow={true}
				needArrowOnClick={() => {
					history.push('/lead-details');
				}}
			/>
			<IonContent className=''>
				<div className='bg-web h-100 py-md-3'>
					<div className='bg-web-white container-width-md border-md-radius-10 h-100 overflow-auto-md'>
						<IonList className='ion-no-padding'>
							{commentList?.map((item: any, index: number) => {
								return (
									<IonItem
										key={index}
										className=''
										lines='full'
									>
										<div className='my-3 ms-0'>
											<h6 className='fw-bold fs-14 darkest-grey fs-500'>
												{item?.comment || 'NA'}
											</h6>
											<div className='mt-2'>
												<p className='mb-0 fs-12 fw-500 text-grey position-relative'>
													<span>
														By{' '}
														{item?.commented_by_name
															? item?.commented_by_name
															: 'Unknown'}
													</span>
													<span className='mx-2'>•</span>
													<span>
														{FormatDate(item?.createdAt, 'dd mmm yyyy')}
													</span>
													<span className='mx-2'>•</span>
													<span>{FormatDate(item?.createdAt, 'hh:MM TT')}</span>
												</p>
											</div>
										</div>
									</IonItem>
								);
							})}
						</IonList>
					</div>
				</div>
			</IonContent>
			{/* <div
				className='ion-padding'
				id='lead-detail'
			>
				<div className='d-flex flex-column  w-100 position-relative'>
					<div className='d-flex align-items-center bg-light-grey textarea'>
						<IonTextarea
							className='custom bg-transparent ps-3 mt-0 '
							value={comment}
							maxlength={300}
							rows={1}
							placeholder='Add Comment'
							onIonInput={(e: any) => setComment(e.detail.value)}
						></IonTextarea>
						{comment.length > 0 && (
							<CustomButton
								style={{ color: '#0165E1' }}
								className={'fs-14 m-0'}
								fill={'none'}
								onClick={() => {
									addComment();
								}}
							>
								{t('Submit')}
							</CustomButton>
						)}
					</div>
				</div>
			</div> */}
		</IonPage>
	);
};
