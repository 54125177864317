import { IonContent, IonFooter, IonImg } from '@ionic/react';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	fetchMasterData,
	lenderSpfDetailsFlow,
	saveLenderSpcfInfo,
} from '../../../../store/reducers/lead/leadSlice';
import { getCompanyNameList } from '../../../../store/reducers/user/userSlice';
import { CustomInput } from '../../../../components/common/custom-input/CustomInput';
import {
	ContinueLaterPopUp,
	FormatDate,
	checkFieldsValidToContinue,
	sanitizeHtml,
	showToastAlert,
} from '../../../../helpers/functions';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import {
	primaryApplicantSchemaOne,
	primaryApplicantFormOne,
} from './TataHlLAP';
import { LenderNameForAPICall } from '../../../../helpers/lenders';
import { DynamicDropdown } from '../../../../components/common';
import { PAGINATION_DETAILS } from '../../../../helpers/constant';
import CustomPopoverSearch from '../../../../components/common/custom-popover/CustomPopoverSearch';
import { OfferRejectedMsg } from '../..';

export const PrimaryApplicantForm1: React.FC<any> = ({
	setSelectedTabValue,
	leadInfo,
	setValidForm,
}) => {
	const [modalPageClose, setModalPageClose] = useState(false);
	const [propertyType, setPropertyType] = useState([]);
	const [natureOfProperty, setNatureOfProperty] = useState([]);
	const [applicationType, setApplicationType] = useState([]);
	const [occupationType, setOccupationType] = useState([]);
	const [collateralSubType, setCollateralSubType] = useState([]);
	const [employmentType, setEmploymentType] = useState<any>([]);
	const [customerType, setCustomerType] = useState<any>([]);
	const [companyList, setCompanyList] = useState([]);
	const [loanPurpose, setLoanPurpose] = useState([]);
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const [openCompanyModal, setOpenCompanyModal] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const { selectedLender, lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);

	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		trigger,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(primaryApplicantSchemaOne),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			employment_type:
				lenderSpecificDetails?.lender_spf_details?.employment_type || '',
			mother_first_name:
				lenderSpecificDetails?.lender_spf_details?.mother_first_name ||
				leadInfo?.commonData?.mother_first_name ||
				'',
			mother_last_name:
				lenderSpecificDetails?.lender_spf_details?.mother_last_name ||
				leadInfo?.commonData?.mother_last_name ||
				'',
			property_type:
				lenderSpecificDetails?.lender_spf_details?.property_type || '',
			nature_of_property:
				lenderSpecificDetails?.lender_spf_details?.nature_of_property || '',
			application_type:
				lenderSpecificDetails?.lender_spf_details?.application_type || '',
			property_cost:
				lenderSpecificDetails?.lender_spf_details?.property_cost || '',
			property_address:
				lenderSpecificDetails?.lender_spf_details?.property_address || '',
			occupation_type:
				lenderSpecificDetails?.lender_spf_details?.occupation_type || '',
			collateral_sub_type:
				lenderSpecificDetails?.lender_spf_details?.collateral_sub_type || '',
			loan_purpose:
				lenderSpecificDetails?.lender_spf_details?.loan_purpose || '',
			customer_type:
				lenderSpecificDetails?.lender_spf_details?.customer_type || '',
			date_of_incorporation:
				lenderSpecificDetails?.lender_spf_details?.date_of_incorporation ||
				undefined,
			company_name:
				lenderSpecificDetails?.lender_spf_details?.company_name || '',
			net_monthly_income:
				lenderSpecificDetails?.lender_spf_details?.net_monthly_income ||
				leadInfo?.personalInfo?.professional_details['Net Monthly Income'] ||
				'',
			req_roi: lenderSpecificDetails?.lender_spf_details?.req_roi || '',
		},
	});

	useEffect(() => {
		getDataFromMaster();
	}, []);

	useEffect(() => {
		setValidForm(isValid);
	}, [isValid]);

	const getDataFromMaster = async () => {
		await dispatch(fetchMasterData({ param: 'TATAHLLAP_EMPLOYMENT_TYPE' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setEmploymentType(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATALAP_PROPERTY_TYPE' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setPropertyType(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATALAP_NATURE_OF_PROPERTY' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setNatureOfProperty(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATALAP_APPLICATION_TYPE' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setApplicationType(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATALAP_OCCUPATION_TYPE' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setOccupationType(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATALAP_COLLATERAL_SUBTYPE' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setCollateralSubType(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATA_CUSTOMER_TYPE' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setCustomerType(result?.data);
				}
			});

		await dispatch(
			fetchMasterData({
				param:
					leadFlowData?.selectedProductData?.product_category_id == 3
						? 'TATAHL_LOAN_PURPOSE'
						: 'TATALAP_LOAN_PURPOSE',
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setLoanPurpose(result?.data);
				}
			});
	};

	/**
	 * Get company list
	 * @param searchValue search text
	 */
	const getCompanyList = async (searchValue: any) => {
		await dispatch(
			getCompanyNameList({
				param: `?searchItem=${sanitizeHtml(
					encodeURIComponent(searchValue)
				)}&page=${PAGINATION_DETAILS.CURRENT_PAGE}&size=${
					PAGINATION_DETAILS.PAGE_SIZE
				}`,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setCompanyList(response.companyListData);
				}
			});
	};

	/**
	 * Set dropdown data
	 * @param name field name
	 */
	const setDropDownOptions = (name: string) => {
		switch (name) {
			case 'employment_type':
				return employmentType;
			case 'property_type':
				return propertyType;
			case 'nature_of_property':
				return natureOfProperty;
			case 'application_type':
				return applicationType;
			case 'occupation_type':
				return occupationType;
			case 'collateral_sub_type':
				return collateralSubType;
			case 'loan_purpose':
				return loanPurpose;
			case 'customer_type':
				return customerType;
		}
	};

	/**
	 * On drop down value change set some data
	 * @param name field name
	 */
	const onDropDownChange = (name: any) => {
		if (name === 'customer_type') {
			if (getValues(name) !== 'Corporate') {
				setValue('date_of_incorporation', undefined);
			}

			if (lenderSpecificDetails?.lender_spf_details?.co_applicant?.length > 0) {
				const coApplicant: any = JSON.parse(
					JSON.stringify(lenderSpecificDetails)
				);
				coApplicant.lender_spf_details?.co_applicant?.forEach((el: any) => {
					el.party_relation = '';
				});
				dispatch(
					lenderSpfDetailsFlow({
						...lenderSpecificDetails,
						...coApplicant,
					})
				);
			}
		} else if (name === 'application_type') {
			if (getValues(name) !== 'Property Identified') {
				setValue('property_cost', undefined);
				setValue('property_address', undefined);
			}
		}
		trigger(name);
	};

	/**
	 * On submit to save user data and after that
	 * @param data Form data
	 */
	const onSubmit = async (data: any) => {
		const req: any = {
			lender_spf_details: {
				...lenderSpecificDetails?.lender_spf_details,
				...data,
				isValidApplicant1: isValid,
			},
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.TATA_HL_LAP,
			step: 'step1',
		};

		if (lenderSpecificDetails?.lead_spf_detail_id) {
			req.lead_spf_detail_id = lenderSpecificDetails?.lead_spf_detail_id;
		}

		if (getValues('customer_type') !== 'Corporate') {
			delete req?.lender_spf_details?.date_of_incorporation;
		}
		 if (req?.lender_spf_details?.date_of_incorporation) {
			req.lender_spf_details.date_of_incorporation = FormatDate(
				req?.lender_spf_details?.date_of_incorporation,
				'yyyy-mm-dd'
			);
		}

		if (getValues('application_type') !== 'Property Identified') {
			delete req?.lender_spf_details?.property_cost;
			delete req?.lender_spf_details?.property_address;
		}

		setShowOfferErrMsg('');
		await dispatch(saveLenderSpcfInfo({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					if (isValid) {
						setSelectedTabValue('ApplicantFormTwo');
						dispatch(
							lenderSpfDetailsFlow({
								...lenderSpecificDetails,
								lender_spf_details: req.lender_spf_details,
							})
						);
					}
				} else {
					setShowOfferErrMsg(result?.message);
				}
			});
	};

	/**
	 * Continue Later and Submit button
	 */
	const continueLaterAndSubmitBtn = () => {
		return (
			<>
				<CustomButton
					className='w-100 fw-bold dashboard-add-lead-button mb-3'
					expand='block'
					fill='outline'
					title={t('Continue Later')}
					onClick={() => {
						if (checkFieldsValidToContinue(errors)) {
							setModalPageClose(true);
						} else {
							showToastAlert({
								type: 'error',
								message: 'Please fix above errors',
							});
						}
					}}
				/>
				<CustomButton
					type='submit'
					className='w-100 fw-bold dashboard-add-lead-button'
					expand='block'
					title={t('Save & Continue')}
					disabled={!isValid}
					onClick={handleSubmit(onSubmit)}
				/>
			</>
		);
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 pt-3 p-md-5 border-radius-10'>
							<form onSubmit={handleSubmit(onSubmit)}>
								{primaryApplicantFormOne.map(
									({ name, type, placeholder }: any, index: any) => {
										const errorText: any =
											errors && Object.keys(errors).length > 0 && errors;
										return (
											<div key={index}>
												{type === 'dropdown' ? (
													<div style={{ marginBottom: '15px' }}>
														<Controller
															control={control}
															render={({
																field: { onChange, onBlur, name },
															}) => (
																<DynamicDropdown
																	label={t(`${placeholder}`)}
																	data={setDropDownOptions(name)}
																	name={name}
																	onChange={(e: any) => {
																		onChange(e);
																		onDropDownChange(name);
																	}}
																	bindField={
																		name === 'loan_purpose' ||
																		name === 'accomodation_type'
																			? 'id'
																			: 'value'
																	}
																	showField1={'value'}
																	value={getValues(name)}
																	onBlur={onBlur}
																	errors={errorText[name]?.message}
																/>
															)}
															name={name}
														/>
													</div>
												) : name === 'property_cost' || name === 'property_address' ? (
													getValues('application_type') ===
														'Property Identified' && (
														<Controller
															control={control}
															render={({
																field: { onChange, onBlur, name },
															}) => (
																<CustomInput
																	type={type}
																	label={t(placeholder)}
																	onBlur={onBlur}
																	id={name}
																	onChange={(e: any) => {
																		onChange(e);
																	}}
																	value={getValues(name)}
																	errors={t(errorText[name]?.message || '')}
																/>
															)}
															name={name}
														/>
													)
												) : name === 'date_of_incorporation' ? (
													getValues('customer_type') === 'Corporate' && (
														<Controller
															control={control}
															render={({
																field: { onChange, onBlur, name },
															}) => (
																<CustomInput
																	type={type}
																	needSuffixIcon={true}
																	suffixClassName={
																		'position-absolute date-of-birth-sufix'
																	}
																	suffixText={
																		<IonImg
																			src='/images/icons/input-dob-calendar-icon.svg'
																			alt='dob'
																		/>
																	}
																	outerClassName={'position-relative'}
																	label={t(placeholder)}
																	onBlur={onBlur}
																	id={name}
																	onChange={(e: any) => {
																		onChange(e);
																	}}
																	value={getValues(name)}
																	errors={t(errorText[name]?.message || '')}
																/>
															)}
															name={name}
														/>
													)
												) : name === 'company_name' ? (
													<>
														<Controller
															control={control}
															render={({ field: { onChange, onBlur } }) => (
																<CustomInput
																	type={type}
																	label={t(placeholder)}
																	onBlur={() => {
																		onBlur();
																	}}
																	id={name}
																	onChange={(e: any) => {
																		onChange(e);
																		setOpenCompanyModal(true);
																	}}
																	value={getValues(name)}
																	onFocus={() => {
																		setOpenCompanyModal(true);
																	}}
																	errors={t(errorText[name]?.message)}
																/>
															)}
															name={name}
														/>
														<div className='mb-3'>
															<CustomPopoverSearch
																searchApi={getCompanyList}
																list={companyList}
																setList={setCompanyList}
																isOpen={openCompanyModal}
																searchListModalClose={setOpenCompanyModal}
																title={t('Company Names')}
																searchbarPlaceholder={t(
																	'Search Company by “Name”'
																)}
																customNoDataMessage={t(
																	'Search by your company name'
																)}
																setValue={(val: any) => {
																	setValue('company_name', val);
																	trigger('company_name');
																}}
															/>
														</div>
													</>
												) : (
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<CustomInput
																type={type}
																label={t(placeholder)}
																onBlur={onBlur}
																id={name}
																onChange={(e: any) => {
																	onChange(e);
																}}
																value={getValues(name)}
																errors={t(errorText[name]?.message || '')}
															/>
														)}
														name={name}
													/>
												)}
											</div>
										);
									}
								)}
							</form>

							<div className={`d-none d-md-block mt-4`}>
								{continueLaterAndSubmitBtn()}
							</div>
						</div>
					</div>
				)}
			</IonContent>

			{!showOfferErrMsg && (
				<IonFooter className='d-block d-md-none position-relative ion-padding lead_ftr_btn'>
					{continueLaterAndSubmitBtn()}
				</IonFooter>
			)}

			<CustomModal
				needIonContentWraper={false}
				isOpen={modalPageClose}
				initialBreakpoint={undefined}
				handleClose={() => setModalPageClose(false)}
				breakpoints={[]}
				className='height-auto confirm-popup-middle continue-later'
				modalContent={ContinueLaterPopUp(
					() => {
						setModalPageClose(false);
					},
					() => {
						new Promise(function (myResolve) {
							setTimeout(function () {
								myResolve(history.push('/dashboard/lead'));
								onSubmit(getValues());
								setModalPageClose(false);
							}, 10);
						});
					},
					'Cancel',
					'Continue Later',
					'className',
					'Are You Sure You Want to Exit?',
					`${t('You can continue later from where you left off.')}`
				)}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
