import { IonApp, IonSpinner, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import './i18nextConf.js';
import { InstallReferrer } from '@togetherprice/capacitor-plugin-install-referrer';
import ReactGA from 'react-ga4';
// import { Mutex } from 'async-mutex';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

// Theme fonts
import './theme/fonts.scss';

// Theme global css
import './theme/global.scss';
import './theme/utilities.scss';

import { Routing } from './components/routing/Routing';
import { ToastContainer } from 'react-toastify';
import { axiosInstatnce, setAuthToken } from './store/accessToken';
import { useDispatch, useSelector } from 'react-redux';
import { logOut, showToastAlert } from './helpers/functions.js';
import { useContext, useEffect, useState } from 'react';
import {
	changeRefToken,
	changeToken,
	refreshTokenNew,
} from './store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router';
import {
	BULKUPLOAD_NEW_ERROR_SHEET,
	BULK_UPLOAD_NEW_LEAD,
	UPLOAD_BULK_LEADS,
} from './helpers/api-endpoints.js';
import { useTranslation } from 'react-i18next';
import { BusyLoadingProvider } from './pages/insurance/context/BusyLoadingContext';
import { ApplicationProvider } from './pages/insurance/context/ApplicationContext';
import {
	AuthContext,
	AuthProvider,
} from './pages/insurance/context/AuthContext';
import { InsuranceInfoProvider } from './pages/insurance/context/InsuranceInfoContext';
import BusyLoader from './pages/insurance/components/common/loader/BusyLoader';
import './pages/insurance/globalInsuranceCss.scss';
import * as Sentry from '@sentry/react';
import { GA4_TRACKING_ID } from './helpers/constant.js';
// import { useHistory } from 'react-router';
// import { refreshTokenNew } from './store/reducers/user/userSlice';
let show = true;
let isRefreshing = false;
let failedQueue: any = [];

export const App: React.FC = () => {
	const { t } = useTranslation();
	const [spinner, setSpinner] = useState(false);
	const dispatch: any = useDispatch();
	const history = useHistory();
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	const { setToken } = useContext(AuthContext);
	const disableDefaultToast: any = [
		UPLOAD_BULK_LEADS,
		BULK_UPLOAD_NEW_LEAD,
		BULKUPLOAD_NEW_ERROR_SHEET,
	];
	// const mutex = new Mutex();
	// useEffect(() => {
	// 	console.log('location', location.pathname);

	// 	const launcherBox = document.getElementById('zohohc-asap-web-helper-main');
	// 	if (launcherBox) {
	// 		if (location.pathname === '/help-desk') {
	// 			launcherBox.style.display = 'block';
	// 		} else {
	// 			launcherBox.style.display = 'none';
	// 		}
	// 	}
	// }, [location.pathname]);
	Sentry.init({
		dsn: 'https://e87076fd7bb697d4fa60313c6b9b5e95@o4504122161364992.ingest.us.sentry.io/4507651473014784',
		integrations: [
			Sentry.browserTracingIntegration(),
			// Sentry.replayIntegration({
			// 	maskAllText: false,
			// 	blockAllMedia: false,
			// }),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
	setupIonicReact({
		hardwareBackButton: false,
		swipeBackEnabled: false,
	});
	const selectorToken = useSelector(
		(state: any) => state.users?.user_details?.access_token
	);
	const refreshToken = useSelector(
		(state: any) => state.users?.user_details?.refresh_token
	);
	if (selectorToken) {
		axiosInstatnce.interceptors.request.use(setAuthToken(selectorToken));
	}

	useEffect(() => {
		axiosInstatnce.interceptors.request.use(setAuthToken(selectorToken));
	}, [selectorToken]);
	useEffect(() => {
		axiosInstatnce.interceptors.request.use(
			(req: any) => {
				setSpinner(true);

				show = true;

				return req;
			},
			(err) => {
				setSpinner(false);
				show = false;
				return Promise.reject(err);
			}
		);
		const processQueue = (error: any, token = null) => {
			failedQueue.forEach((prom: any) => {
				if (error) {
					prom.reject(error);
				} else {
					prom.resolve(token);
				}
			});

			failedQueue = [];
		};
		axiosInstatnce.interceptors.response?.use(
			function (response) {
				if (show) {
					if (response && response?.data?.status_code === 401) {
						logOut(dispatch, history);
					}
					if (response && response?.data?.status) {
						if (
							response?.data?.message &&
							!disableDefaultToast.includes(response?.config?.url?.toString())
						) {
							showToastAlert({
								type: 'sucess',
								message: t(response?.data?.message),
							});
							show = false;
							setSpinner(false);
						}
					}

					if (response && !response?.data?.status) {
						if (response?.data?.message) {
							showToastAlert({
								type: 'error',
								message: t(response?.data?.message),
							});
							show = false;
							setSpinner(false);
						}
					}
				}
				setSpinner(false);
				return response;
			},
			async function (error) {
				const originalRequest = error.config;
				// await mutex.waitForUnlock();
				if (!selectorToken) {
					window.location.href = '/login';
				}
				if (error?.response?.status === 403) {
					logOut(dispatch, history);
					return;
				}
				if (
					selectorToken &&
					error.response.status === 401 &&
					!originalRequest._retry
				) {
					originalRequest._retry = true;

					if (isRefreshing) {
						return new Promise(function (resolve, reject) {
							failedQueue.push({ resolve, reject });
						})
							.then((token) => {
								originalRequest.headers['Authorization'] = 'Bearer ' + token;
								return axiosInstatnce(originalRequest);
							})
							.catch((err) => {
								return Promise.reject(err);
							});
					}

					originalRequest._retry = true;
					isRefreshing = true;

					return new Promise(function (resolve, reject) {
						axiosInstatnce.defaults.headers.common.Authorization =
							'Bearer ' + refreshToken;
						dispatch(refreshTokenNew())
							.then(unwrapResult)
							.then((result: any) => {
								if (result === 401) {
									logOut(dispatch, history);
								}
								axiosInstatnce.defaults.headers.common['Authorization'] =
									'Bearer ' + result.access_token;
								originalRequest.headers['Authorization'] =
									'Bearer ' + result.access_token;
								processQueue(null, result.access_token);
								dispatch(changeToken(result.access_token));
								dispatch(changeRefToken(result.refresh_token));

								console.log('hbs set token called', result.access_token);
								setToken(`bearer ${result.access_token}`);

								setAuthToken(result.access_token);
								resolve(axiosInstatnce(originalRequest));
							})
							.catch((err: any) => {
								processQueue(err, null);
								reject(err);
							})
							.finally(() => {
								isRefreshing = false;
							});
					});
				} else if (
					leadInfo?.from === 'nimbuss' &&
					error?.response?.status === 401
				) {
					showToastAlert({ type: 'error', message: 'Link Expired' });
				}
				// else if (error?.response?.status === 401) {
				// 	logOut(dispatch, history);
				// }

				if (show) {
					showToastAlert({ type: 'error', message: error?.message });
					show = false;
					setSpinner(false);
				}
				return Promise.reject(error);
			}
		);
	}, [dispatch]);

	useEffect(() => {
		// commented because giving issue in ios, do not uncomment.
		/*
		if (
			isPlatform('android')
			// enabled only on android
			//  ||
			isPlatform('ios') ||
			isPlatform('ipad') ||
			isPlatform('iphone')
			
		) {
			(async () => {
				await PrivacyScreen.disable();
			})();
		}
		*/

		// check internet connection
		window.addEventListener('online', handleOnline);
		window.addEventListener('offline', handleOffline);

		return () => {
			window.removeEventListener('online', handleOnline);
			window.removeEventListener('offline', handleOffline);
		};
	}, []);

	const handleOnline = () => {
		showToastAlert({
			type: 'sucess',
			message: t('Internet Available'),
			autoClose: false,
			toastId: 10,
		});
	};

	const handleOffline = () => {
		showToastAlert({
			type: 'error',
			message: t('Internet Not Available'),
			autoClose: false,
			toastId: 10,
		});
	};

	useEffect(() => {
		// if (isPlatform('android')) {
		const referrer = InstallReferrer.getReferrerDetails();
		console.log(referrer);

		ReactGA.initialize(GA4_TRACKING_ID);
		// Send pageview with a custom path
		console.log('react-ga4');

		// ReactGA.send({
		// 	hitType: 'pageview',
		// 	page: '/language-selection',
		// 	title: 'Landing Page',
		// });
		// }
	}, []);

	return (
		<BusyLoadingProvider>
			<BusyLoader />
			<IonApp>
				<AuthProvider>
					<InsuranceInfoProvider>
						<ApplicationProvider>
							<IonReactRouter>
								<Routing />
							</IonReactRouter>
							<ToastContainer limit={2} />
							{spinner && (
								<div
									className='position-fixed  w-100  d-flex justify-content-center align-items-center h-100'
									style={{
										top: '0px',
										left: '50%',
										transform: 'translate(-50%,0%)',
										backgroundColor: 'rgba(0,0,0,0.2)',
									}}
								>
									<IonSpinner name='lines-sharp' />
								</div>
							)}
						</ApplicationProvider>
					</InsuranceInfoProvider>
					{/* <LoadingIndicator /> */}
					<ToastContainer limit={1} />
				</AuthProvider>
			</IonApp>
		</BusyLoadingProvider>
	);
};
