import {
	IonContent,
	IonFooter,
	IonImg,
	IonLabel,
	IonPage,
	isPlatform,
} from '@ionic/react';
import { Header } from '../../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	getLenderUserInfo,
	getProfessionalDetailsMaster,
} from '../../../store/reducers/user/userSlice';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { Player } from '@lottiefiles/react-lottie-player';
import { generateOnlineLead } from '../../../store/reducers/lead/leadSlice';
import DropDownModal from '../../../components/common/custom-dropdown/DropDownModal';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	FormatDate,
	InputFormatCurrencyValue,
	disableNumberArrows,
	fetchStateCityByPincode,
	restrictAmountChar,
	urltoFile,
} from '../../../helpers/functions';
import { UploadImageCard } from '../../dashboard/kyc/UploadImageCard';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { CustomCheckbox } from '../../../components/common/custom-checkbox/CustomCheckbox';
import CustomRadioButton from '../../../components/common/custom-radio-button/CustomRadioButton';
import BankUploadDocuments from './BankUploadDocuments';
import { apiAction } from '../../../store/apiAction';
import { get, post } from '../../../helpers/constant';
import StateCity from '../../../components/common/StateCity';

export const BankOnlineForm: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory<any>();
	const dispatch = useDispatch();
	const isBrowser = !isPlatform('capacitor');
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	const dmaSelector = useSelector((state: any) => state?.users);
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	const currentLenderInfo = useSelector(
		(state: any) => state?.lead?.selectedLender
	);
	const newLeadApplicantSelector = useSelector(
		(state: any) => state?.lead?.added_lead?.newLoanApplication
	);
	const [onlineFormFields, setOnlineFormFields] = useState<any>([]);
	const [showCheckIcon, setShowCheckIcon] = useState<boolean>(false);
	const [showOnlineFormSuccess, setShowOnlineFormSuccess] =
		useState<boolean>(false);
	const [showOnlineFormMessage, setShowOnlineFormMessage] = useState<any>('');
	const [dropdownMasterData, setDropdownMasterData] = useState<any>({});
	const [yupSchema, setYupSchema] = useState<any>(yup.object().shape({}));

	const dropdownTypes = ['dropdown', 'dropdownSelect', 'dropdownModal'];
	const [openUploadModal, setOpenUploadModal] = useState(false);
	const [imagePreviewUpload, setImagePreviewUpload]: any = useState({});
	const [docTypeErrorMessage, setDocTypeErrorMessage]: any = useState({});
	const [clickImageFieldName, setClickImageFieldName] = useState<any>(null);
	const [formCPAddressFields, setFormCPAddressFields] = useState<any>({});
	const [dropdrowInnerDetailFields, setDropdrowInnerDetailFields] =
		useState<any>({});
	const [coApplicantFieldsData, setCoApplicantFieldsData] = useState<any>([]);
	const [pinCodeData, setPinCodeData] = useState<any>([]);

	const {
		handleSubmit,
		control,
		reset,
		setValue,
		trigger,
		getValues,
		unregister,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(yupSchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const onSubmit = async (data: any) => {
		const setData: any = {};
		if (onlineFormFields.some((formKey: any) => formKey?.type === 'document')) {
			setData['doc'] = data?.doc;
		}
		onlineFormFields?.map((field: any) => {
			if (field.type === 'dropdownSelect') {
				const selectedOption = dropdownMasterData[field?.name]?.filter(
					(item: any) => item.id === data[field?.name]?.toString()
				);
				setData[field.name] = data[field.name] ? selectedOption[0]?.value : '';
			} else if (field?.type === 'document') {
				delete setData[field?.name];
			} else if (field?.field_value === 'number') {
				setData[field.name] = data[field.name]
					? parseFloat(data[field.name])
					: data[field.name];
			} else {
				setData[field.name] = data[field.name];
			}
		});
		coApplicantFieldsData.flat().map((field: any) => {
			if (field.type === 'dropdownSelect') {
				const fieldName = field?.name.split('_')[0];
				const selectedOption = dropdownMasterData[fieldName]?.filter(
					(item: any) => item.id === data[field?.name]?.toString()
				);
				setData[field.name] = data[field.name] ? selectedOption[0]?.value : '';
			} else {
				setData[field.name] = data[field.name];
			}
		});
		await dispatch(
			generateOnlineLead({
				param: leadInfo?.leadId
					? leadInfo?.leadId
					: newLeadApplicantSelector?.id,
				data: {
					...setData,
					lender_name: currentLenderInfo[0]?.lender_name,
					lead_application_id: leadInfo?.lead_app_id,
				},
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				setShowCheckIcon(response?.status ? true : false);
				setShowOnlineFormSuccess(true);
				setShowOnlineFormMessage(
					response?.text ? response?.text : response?.message
				);
			});
		reset({});
	};
	const generateFormValidationSchemas = (fields: any) => {
		const schemaBuilderObject: any = {};
		fields?.forEach((formKeyName: any) => {
			if (formKeyName?.is_required) {
				const {
					match_regex,
					test = false,
					dependentLevel,
					dependentParentLabel,
					dependentParent,
					checkCondition,
					min,
					max,
					trim,
					is_comma_separated = false,
					name,
				} = formKeyName;

				if (
					name === 'postalCode_currentAddress' ||
					name === 'postalCode_permanentAddress' ||
					name === 'postalCode'
				) {
					schemaBuilderObject[name + '_City'] = yup
						.string()
						.required(name + ' is a required field');

					schemaBuilderObject[name + '_State'] = yup
						.string()
						.required(name + ' is a required field');
				}
				if (is_comma_separated) {
					schemaBuilderObject[formKeyName.name] = yup.number();
					schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
						formKeyName.name
					]
						.typeError(
							formKeyName.placeholder.replaceAll('*', '') + ' must be a number'
						)
						.required(
							typeof formKeyName?.required_error_message === 'string' &&
								formKeyName?.required_error_message
								? formKeyName?.required_error_message
								: formKeyName.placeholder.replaceAll('*', '') +
										' is a required field'
						);
					if (min) {
						schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
							formKeyName.name
						].min(
							formKeyName['min'],
							`${formKeyName.placeholder.replaceAll(
								'*',
								''
							)} should not be zero or empty`
						);
					}
				} else if (dropdownTypes.includes(formKeyName.type)) {
					if (formKeyName.name === 'company_type') {
						schemaBuilderObject[formKeyName.name] = yup
							.array()
							.required(
								typeof formKeyName?.required_error_message === 'string' &&
									formKeyName?.required_error_message
									? formKeyName?.required_error_message
									: formKeyName.placeholder.replaceAll('*', '') +
											' is a required field'
							);
					} else {
						schemaBuilderObject[formKeyName.name] = yup
							.number()
							.required(
								typeof formKeyName?.required_error_message === 'string' &&
									formKeyName?.required_error_message
									? formKeyName?.required_error_message
									: formKeyName.placeholder.replaceAll('*', '') +
											' is a required field'
							);
					}
				} else if (formKeyName.type === 'number') {
					schemaBuilderObject[formKeyName.name] = yup.string();
					schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
						formKeyName.name
					].required(
						typeof formKeyName?.required_error_message === 'string' &&
							formKeyName?.required_error_message
							? formKeyName?.required_error_message
							: formKeyName.placeholder.replaceAll('*', '') +
									' is a required field'
					);

					if (max) {
						schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
							formKeyName.name
						].max(
							formKeyName?.max,
							typeof formKeyName?.max_error_message === 'string' &&
								formKeyName?.max_error_message
								? formKeyName?.max_error_message
								: `${
										formKeyName.placeholder.replaceAll('*', '') +
										'should not more than ' +
										formKeyName?.max
								  }`
						);
					}
					if (match_regex) {
						schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
							formKeyName.name
						].matches(
							new RegExp(formKeyName['match_regex']),
							typeof formKeyName?.regex_error_message === 'string' &&
								formKeyName?.regex_error_message
								? formKeyName?.regex_error_message
								: formKeyName.placeholder.replaceAll('*', '') +
										' should be a valid format'
						);
					}
				} else if (formKeyName.type === 'date') {
					schemaBuilderObject[formKeyName.name] = yup
						.date()
						.required('Date of birth is required') // Make it required
						.max(
							new Date(),
							`${formKeyName.placeholder.replaceAll(
								'*',
								''
							)} must be in the past`
						);
				} else if (formKeyName.type === 'text') {
					schemaBuilderObject[formKeyName.name] = yup.string();
					schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
						formKeyName.name
					].required(
						typeof formKeyName?.required_error_message === 'string' &&
							formKeyName?.required_error_message
							? formKeyName?.required_error_message
							: formKeyName.placeholder.replaceAll('*', '') +
									' is a required field'
					);
					if (trim) {
						schemaBuilderObject[formKeyName.name] =
							schemaBuilderObject[formKeyName.name].trim();
					}
					if (max) {
						schemaBuilderObject[formKeyName.name] = yup
							.string()
							.max(
								formKeyName?.max,
								typeof formKeyName?.max_error_message === 'string' &&
									formKeyName?.max_error_message
									? formKeyName?.max_error_message
									: `${
											formKeyName.placeholder.replaceAll('*', '') +
											'should not more than ' +
											formKeyName?.max
									  }`
							);
					}
					if (match_regex) {
						schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
							formKeyName.name
						].matches(
							new RegExp(formKeyName['match_regex']),
							typeof formKeyName?.regex_error_message === 'string' &&
								formKeyName?.regex_error_message
								? formKeyName?.regex_error_message
								: formKeyName.placeholder.replaceAll('*', '') +
										' should be a valid format'
						);
					}
				} else {
					schemaBuilderObject[formKeyName.name] = formKeyName.match_regex
						? yup
								.string()
								.required(
									typeof formKeyName?.required_error_message === 'string' &&
										formKeyName?.required_error_message
										? formKeyName?.required_error_message
										: formKeyName.placeholder.replaceAll('*', '') +
												' is a required field'
								)
								.matches(
									new RegExp(formKeyName.match_regex),
									typeof formKeyName?.regex_error_message === 'string' &&
										formKeyName?.regex_error_message
										? formKeyName?.regex_error_message
										: formKeyName.placeholder.replaceAll('*', '') +
												' should be a valid format'
								)
						: yup
								.string()
								.required(
									typeof formKeyName?.required_error_message === 'string' &&
										formKeyName?.required_error_message
										? formKeyName?.required_error_message
										: formKeyName.placeholder.replaceAll('*', '') +
												' is a required field'
								);
				}
				if (test) {
					if (
						dependentLevel == '0' &&
						checkCondition &&
						typeof dependentParent === 'string'
					) {
						schemaBuilderObject[formKeyName.name] = schemaBuilderObject[
							formKeyName.name
						].test(
							'custom-test-logic',
							formKeyName.placeholder.replaceAll('*', '') +
								'should not be more than ' +
								dependentParentLabel,
							function (this: yup.TestContext, value: string): boolean {
								const operation = checkCondition || '<=';
								const leftOperand = value;
								const { [dependentParent]: rightOperand } = this.parent;
								let result;
								switch (operation) {
									case '<':
										result = parseInt(leftOperand) < parseInt(rightOperand);
										break;
									case '<=':
										result = parseInt(leftOperand) <= parseInt(rightOperand);
										break;
									case '>':
										result = parseInt(leftOperand) > parseInt(rightOperand);
										break;
									case '>=':
										result = parseInt(leftOperand) >= parseInt(rightOperand);
										break;
									case '==':
										result = parseInt(leftOperand) == parseInt(rightOperand);
										break;
									case '===':
										result = parseInt(leftOperand) === parseInt(rightOperand);
										break;
									default:
										result = true;
								}
								return result;
							}
						);
					}
				}
			} else {
				if (formKeyName?.is_comma_separated) {
					schemaBuilderObject[formKeyName.name] = yup
						.number()
						.typeError(formKeyName.placeholder + ' must be a number');
				} else {
					schemaBuilderObject[formKeyName.name] =
						formKeyName?.match_regex &&
						yup.string().matches(new RegExp(formKeyName.match_regex), {
							message:
								typeof formKeyName?.regex_error_message === 'string' &&
								formKeyName?.regex_error_message
									? formKeyName?.regex_error_message
									: formKeyName.placeholder + ' should be a valid format',
							excludeEmptyString: true,
						});
				}
			}
		});
		setYupSchema(yupSchema.concat(yup.object().shape(schemaBuilderObject)));
	};
	const getLendersFormData = async () => {
		// get all fields from api
		await dispatch(
			getLenderUserInfo({
				param: leadInfo?.leadId
					? leadInfo?.leadId
					: newLeadApplicantSelector?.id,
				data: {
					lender_name: currentLenderInfo[0]?.lender_name,
				},
			})
		)
			.then(unwrapResult)
			.then((res: any) => {
				if (res?.status) {
					// check any field as is_required true than generate validation schema
					if (res?.data.some((formKey: any) => formKey?.is_required === true)) {
						generateFormValidationSchemas(res?.data);
					}

					// generate doc array if {} for documents(upload)
					if (res?.data.some((formKey: any) => formKey?.type === 'document')) {
						const uploadDocsCount = res?.data?.filter(
							(item: any) => item.type === 'document'
						);
						uploadDocsCount?.forEach((uploadKeyName: any, index: any) => {
							setValue(`doc[${index}].key`, uploadKeyName?.name);
							setValue(`doc[${index}].fileName`, '');
						});
					}

					// run loop for dropdown api data
					res?.data?.forEach((formKeyName: any) => {
						if (formKeyName.type === 'dropdownSelect') {
							if ('other_api' in formKeyName && formKeyName?.other_api) {
								const otherApiCall = apiAction(
									`api/${formKeyName.type}-${formKeyName.name}`,
									post,
									formKeyName?.url
								);
								dispatch(
									otherApiCall({
										param: `${leadInfo?.leadId}`,
										data: { lendername: currentLenderInfo[0]?.lender_name },
									})
								)
									.then(unwrapResult)
									.then((dropdownResponse: any) => {
										if (dropdownResponse?.status) {
											setDropdownMasterData((masterData: any) => {
												return {
													...masterData,
													[formKeyName.name]: dropdownResponse.data,
												};
											});
										}
									});
							} else {
								dispatch(
									getProfessionalDetailsMaster({ param: formKeyName.key })
								)
									.then(unwrapResult)
									.then((dropdownResponse: any) => {
										if (dropdownResponse?.status) {
											setDropdownMasterData((masterData: any) => {
												return {
													...masterData,
													[formKeyName.name]: dropdownResponse.data,
												};
											});
										}
									});
							}
						} else if (formKeyName.type === 'document') {
							setImagePreviewUpload((old: any) => {
								return { ...old, [formKeyName?.name]: false };
							});
						} else if (
							formKeyName.type === 'checkbox' &&
							formKeyName?.is_current_permanent_address
						) {
							setFormCPAddressFields(formKeyName);
						}

						// set value for form fields whatever the value present in api value key
						if (formKeyName.type === 'date') {
							setValue(
								formKeyName.name,
								FormatDate(formKeyName.value, 'yyyy-mm-dd')
							);
						} else {
							setValue(formKeyName.name, formKeyName.value);
						}
					});

					// set all form fields
					const inputFields: any = res?.data?.every((key: any) => 'seq' in key)
						? res?.data?.slice()?.sort((a: any, b: any) => a?.seq - b?.seq)
						: res?.data;
					setOnlineFormFields(inputFields);
				}
			});
	};

	useEffect(() => {
		if (location.pathname !== '/bank-online-form') {
			return;
		}
		setShowCheckIcon(false);
		setShowOnlineFormSuccess(false);
		setShowOnlineFormMessage('');
		getLendersFormData();
		setPinCodeData([]);
		setCoApplicantFieldsData([]);
		return () => {
			reset({});
			setYupSchema(yup.object().shape({}));
		};
	}, [location.pathname]);

	const coApplicationDataMultipleFields = (obj: any, num: any) => {
		const data = [];
		for (let i = 0; i < num; i++) {
			const newData = obj.map((item: any) => ({
				...item,
				name: `${item.name}_${i + 1}`,
				placeholder: `${item.placeholder} ${i + 1}`,
			}));
			data.push(newData);
		}
		setCoApplicantFieldsData(data);
		generateFormValidationSchemas(data.flat());
	};

	let timer: ReturnType<typeof setTimeout>;

	function debounceFunction(fn: any, d: any) {
		if (timer) {
			clearTimeout(timer);
		}
		timer = setTimeout(fn, d);
	}

	const coApplicantFieldFunc = (obj: any, e: any) => {
		const fieldsData = coApplicantFieldsData;
		const schema = yupSchema;

		fieldsData.flat().map((i: any) => {
			delete schema.fields[i?.name];
			setYupSchema(schema);
			unregister(i.name);
		});

		coApplicationDataMultipleFields(obj, e.target.value);
	};
	return (
		<IonPage>
			<Header
				name={t('Online Form')}
				backButton={true}
				needArrow={true}
				needArrowOnClick={() => {
					leadInfo?.from === 'nimbuss'
						? history.push(
								'/lead-details/' +
									leadInfo.leadId +
									'/' +
									dmaSelector?.user_details?.access_token
						  )
						: leadInfo?.isBackLenderOnlineForm
						? history.goBack()
						: history.push('/dashboard/lead');
				}}
				className={showOnlineFormSuccess ? 'd-none' : ''}
			/>
			<IonContent>
				{!showOnlineFormSuccess ? (
					<div className='select-product small-container bg-web  overflow-auto'>
						<div className='h-100 bg-web-white container-width-md px-3 py-2 p-md-5 border-radius-10'>
							<form
								className='ofform'
								onSubmit={handleSubmit(onSubmit)}
							>
								{onlineFormFields?.map(
									(
										{
											name,
											placeholder,
											type,
											value,
											exclude_number_validation,
											dependentLevel,
											triggerValidation,
											is_comma_separated = false,
											is_current_permanent_address = false,
											permanent_address_fields,
											current_address_fields,
											radio_options,
											is_required,
											on_change,
											dropdown_change_api_call,
											static_field,
											static_keys,
											hidden,
										}: any,
										index: any
									) => {
										const errorText: any =
											errors && Object.keys(errors).length > 0 && errors;
										if (type === 'dropdownSelect') {
											return (
												<div key={index}>
													<>
														<Controller
															key={`controller-${index}`}
															control={control}
															render={({
																field: { onChange, onBlur, name },
															}) => (
																<DropDownModal
																	label={
																		t(`${placeholder}`) +
																		(is_required ? ' *' : '')
																	}
																	data={
																		dropdownMasterData[name]
																			? dropdownMasterData[name]
																			: []
																	}
																	name={name}
																	onChange={({ detail: { value } }: any) => {
																		if (on_change) {
																			if (
																				dropdown_change_api_call &&
																				typeof dropdown_change_api_call ===
																					'string'
																			) {
																				const otherApiCall = apiAction(
																					`api/${type}-${name}`,
																					get,
																					dropdown_change_api_call
																				);

																				const getRmName = dropdownMasterData[
																					name
																				].find(
																					(i: any) => i.id === value
																				)?.value;

																				dispatch(
																					otherApiCall({
																						param: `?lead_id=${leadInfo?.leadId}&rm_name=${getRmName}`,
																					})
																				)
																					.then(unwrapResult)
																					.then(
																						(rmLenderDetailsResponse: any) => {
																							if (
																								rmLenderDetailsResponse.status
																							) {
																								if (static_field) {
																									setDropdrowInnerDetailFields(
																										(old: any) => {
																											return {
																												...old,
																												[name]:
																													rmLenderDetailsResponse?.data,
																											};
																										}
																									);
																								}
																							}
																						}
																					);
																			}
																		}

																		onChange(value);
																	}}
																	onBlur={onBlur}
																	className={
																		errorText[name]?.message
																			? 'ion-invalid ion-touched mb-3'
																			: 'mb-3'
																	}
																	errors={t(errorText[name]?.message as string)}
																	value={getValues(name)}
																	disabled={
																		value
																			? true
																			: formCPAddressFields?.is_current_permanent_address &&
																			  getValues(formCPAddressFields?.name)
																			? formCPAddressFields?.permanent_address_fields.includes(
																					name
																			  )
																			: false
																	}
																/>
															)}
															name={name}
														/>
														{static_field && Array.isArray(static_keys)
															? typeof dropdrowInnerDetailFields?.[name] ===
																	'object' &&
															  dropdrowInnerDetailFields?.[name] &&
															  Object.keys(dropdrowInnerDetailFields?.[name])
																	.length > 0
																? static_keys?.map((itemKeys: any) => {
																		if (
																			dropdrowInnerDetailFields?.[name][
																				itemKeys
																			]
																		)
																			return (
																				<>
																					<IonLabel className='ion-text-capitalize'>
																						{itemKeys}
																					</IonLabel>
																					<div className='pb-3'>
																						<span className='selected-label'>
																							{
																								dropdrowInnerDetailFields?.[
																									name
																								][itemKeys]
																							}
																						</span>
																					</div>
																				</>
																			);
																  })
																: ''
															: ''}
													</>
												</div>
											);
										} else if (type === 'document') {
											return (
												<React.Fragment key={index}>
													{imagePreviewUpload[name] ? (
														<div className='py-1 fs-14'>
															{t(placeholder) + (is_required ? ' *' : '')}
														</div>
													) : (
														''
													)}
													<UploadImageCard
														{...{
															setImagePreviewUpload,
															imagePreviewUpload,
															isImageAvilable: imagePreviewUpload[name],
															setClickImageFieldName,
															setOpenUploadModal,
															imgKey: name,
															setValue: (valKey: any, valText: any) => {
																setValue(valKey, valText);
																trigger(valKey);
															},
															value: name,
															title: t(placeholder) + (is_required ? ' *' : ''),
															src: getValues(name),
														}}
													/>
													{!imagePreviewUpload[name] &&
													!docTypeErrorMessage[name] &&
													errorText[name]?.message ? (
														<div className=''>
															<div className='error-text sc-ion-input-md fs-12'>
																{t(errorText[name]?.message)}
															</div>
														</div>
													) : (
														docTypeErrorMessage[name] && (
															<div className=''>
																<div className='error-text sc-ion-input-md fs-12'>
																	{t(docTypeErrorMessage[name])}
																</div>
															</div>
														)
													)}
												</React.Fragment>
											);
										} else if (type === 'checkbox') {
											return (
												<div key={index}>
													<Controller
														control={control}
														render={({
															field: { onChange, onBlur, value },
														}) => (
															<CustomCheckbox
																name={name}
																onChange={({ detail: { checked } }: any) => {
																	onChange(checked);

																	if (checked) {
																		fetchStateCityByPincode(
																			setValue,
																			'postalCode_permanentAddress' + '_City',
																			'postalCode_permanentAddress' + '_State',
																			setPinCodeData,
																			dispatch,
																			getValues('postalCode_currentAddress'),
																			trigger,
																			true,
																			'postalCode_permanentAddress'
																		);
																	} else {
																		setValue(name + '_City', '');
																		setValue(name + '_State', '');
																		setValue('postalCode_permanentAddress', '');
																		setPinCodeData({
																			...pinCodeData,
																			postalCode_permanentAddress: [],
																		});
																	}
																	if (is_current_permanent_address) {
																		if (checked) {
																			permanent_address_fields?.map(
																				(item: any, index: any) => {
																					setValue(
																						item,
																						getValues(
																							current_address_fields[index]
																						)
																					);
																					trigger(item);
																				}
																			);
																		} else {
																			permanent_address_fields?.map(
																				(item: any) => {
																					setValue(item, '');
																					trigger(item);
																				}
																			);
																		}
																	}
																}}
																value={value}
																labelPlacement='end'
																className='end-of-page'
																onBlur={onBlur}
																isChecked={value}
																children={
																	<span className='text-wrap'>
																		{t(placeholder)}
																	</span>
																}
															></CustomCheckbox>
														)}
														name={name}
													/>
												</div>
											);
										} else if (type === 'radio') {
											return (
												<div key={index}>
													<Controller
														key={index}
														control={control}
														render={({ field: { onChange, name } }) => (
															<div className='mb-2'>
																<h6 className='mt-3'>
																	{t(placeholder) + (is_required ? ' *' : '')}
																</h6>
																<CustomRadioButton
																	type='group'
																	value={getValues(name)}
																	onIonChange={(e: any) => {
																		onChange(e);
																	}}
																	className='mb-2'
																	ionRadioClassName='me-4'
																	labelPlacement='end'
																	data={
																		Array.isArray(radio_options) &&
																		radio_options?.length
																			? radio_options
																			: []
																	}
																/>
																<div className='error-text px-3 sc-ion-input-md'>
																	{t(errorText[name]?.message)}
																</div>
															</div>
														)}
														name={name}
													/>
												</div>
											);
										} else if (type === 'date') {
											return (
												<div key={index}>
													<Controller
														key={index}
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<CustomInput
																type={type}
																needSuffixIcon={true}
																suffixClassName={
																	'position-absolute date-of-birth-sufix'
																}
																suffixText={
																	<IonImg
																		src='/images/icons/input-dob-calendar-icon.svg'
																		alt='dob'
																	/>
																}
																outerClassName={'position-relative'}
																placeholder={
																	t(placeholder) + (is_required ? ' *' : '')
																}
																onBlur={onBlur}
																name={name}
																onChange={onChange}
																// errors={t(errors[name]?.message)}
																errors={t(errors[name]?.message as any)}
																value={t(getValues(name))}
																disabled={value ? true : false}
															/>
														)}
														name={name}
													/>
												</div>
											);
										} else {
											const obj =
												name === 'co_applicant' ? hidden[0]?.field : '';
											return (
												<div key={index}>
													<Controller
														key={index}
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<>
																<CustomInput
																	type={type}
																	placeholder={
																		t(placeholder) + (is_required ? ' *' : '')
																	}
																	onBlur={onBlur}
																	name={name}
																	onChange={(e: any) => {
																		if (name === 'co_applicant') {
																			debounceFunction(
																				() => coApplicantFieldFunc(obj, e),
																				500
																			);
																		}
																		if (
																			name === 'postalCode_currentAddress' ||
																			name === 'postalCode_permanentAddress' ||
																			name === 'postalCode'
																		) {
																			if (e.detail.value.length === 6) {
																				fetchStateCityByPincode(
																					setValue,
																					name + '_City',
																					name + '_State',
																					setPinCodeData,
																					dispatch,
																					e.detail.value,
																					trigger,
																					true,
																					name
																				);
																			} else {
																				setValue(name + '_City', '');
																				setValue(name + '_State', '');

																				const data = pinCodeData;
																				delete pinCodeData[name];
																				setPinCodeData(data);
																			}
																		}

																		if (is_comma_separated) {
																			e = isNaN(
																				e.detail.value.replaceAll(',', '')
																			)
																				? ''
																				: Number(
																						e.detail.value.replace(/,/g, '')
																				  );
																		}
																		if (
																			dependentLevel == '1' &&
																			Array.isArray(triggerValidation)
																		) {
																			if (triggerValidation.length > 0) {
																				triggerValidation?.map((item: any) => {
																					if (getValues(item) != undefined) {
																						trigger(item);
																					}
																				});
																			}
																		}
																		onChange(e);
																	}}
																	errors={t(errors[name]?.message as any)}
																	value={t(
																		is_comma_separated
																			? getValues(name)
																				? InputFormatCurrencyValue(
																						getValues(name)
																				  )
																				: getValues(name)
																			: getValues(name)
																	)}
																	onBeforeInput={(evt: any) => {
																		if (type == 'number') {
																			if (!exclude_number_validation) {
																				!/[0-9]/.test(evt.data) &&
																					evt.preventDefault();
																			} else {
																				!/[0-9.]/.test(evt.data) &&
																					evt.preventDefault();
																			}
																		} else if (
																			is_comma_separated &&
																			type == 'text'
																		) {
																			restrictAmountChar(
																				evt,
																				getValues(name)?.toString()
																			);
																		}
																	}}
																	className={`${
																		name === 'pan_no'
																			? 'text-uppercase pt-2'
																			: ''
																	}${
																		[
																			'applicant_middle_name',
																			'fatherName',
																			'motherName',
																			'panCardDetail',
																			'partnerSalesPersonBranch',
																			'partnerSalesPersonName',
																		].includes(name)
																			? ' text-capitalize '
																			: ''
																	}`}
																	onKeyDown={(e: any) => {
																		if (type == 'number') {
																			disableNumberArrows(e);
																		}
																	}}
																	onWheel={(e: any) => {
																		if (type == 'number') {
																			e.target.blur();
																		}
																	}}
																	disabled={
																		value
																			? true
																			: formCPAddressFields?.is_current_permanent_address &&
																			  getValues(formCPAddressFields?.name)
																			? formCPAddressFields?.permanent_address_fields?.includes(
																					name
																			  )
																			: false
																	}
																/>

																{(name === 'postalCode_currentAddress' ||
																	name === 'postalCode_permanentAddress' ||
																	name === 'postalCode') && (
																	<StateCity
																		cityInputName={name + '_City'}
																		stateInputName={name + '_State'}
																		control={control}
																		setValue={setValue}
																		pinCodeData={pinCodeData[name]}
																		trigger={trigger}
																		getValues={getValues}
																	/>
																)}
																{getValues(name)?.length === 6 &&
																getValues(name + '_City') === '' &&
																!errors[name] ? (
																	<div className='city-state-error error-text sc-ion-input-md px-3'>
																		{t('City or State is not selected')}
																	</div>
																) : (
																	''
																)}

																{name === 'co_applicant' &&
																	coApplicantFieldsData.map(
																		(innerArray: any) => {
																			const errorText: any =
																				errors &&
																				Object.keys(errors).length > 0 &&
																				errors;
																			return innerArray.map(
																				(
																					{
																						name,
																						placeholder,
																						type,
																						value,
																						exclude_number_validation,
																						dependentLevel,
																						triggerValidation,
																						is_comma_separated = false,

																						radio_options,
																						is_required,
																					}: any,
																					index: any
																				) => {
																					if (type === 'text') {
																						return (
																							<div key={index}>
																								<Controller
																									key={index}
																									control={control}
																									render={({
																										field: {
																											onChange,
																											onBlur,
																											name,
																										},
																									}) => (
																										<CustomInput
																											type={type}
																											placeholder={
																												t(placeholder) +
																												(is_required
																													? ' *'
																													: '')
																											}
																											onBlur={onBlur}
																											name={name}
																											onChange={(e: any) => {
																												if (
																													is_comma_separated
																												) {
																													e = isNaN(
																														e.detail.value.replaceAll(
																															',',
																															''
																														)
																													)
																														? ''
																														: Number(
																																e.detail.value.replace(
																																	/,/g,
																																	''
																																)
																														  );
																												}
																												if (
																													dependentLevel ==
																														'1' &&
																													Array.isArray(
																														triggerValidation
																													)
																												) {
																													if (
																														triggerValidation.length >
																														0
																													) {
																														triggerValidation?.map(
																															(item: any) => {
																																if (
																																	getValues(
																																		item
																																	) != undefined
																																) {
																																	trigger(item);
																																}
																															}
																														);
																													}
																												}
																												onChange(e);
																											}}
																											errors={t(
																												errors[name]
																													?.message as any
																											)}
																											value={t(
																												is_comma_separated
																													? getValues(name)
																														? InputFormatCurrencyValue(
																																getValues(name)
																														  )
																														: getValues(name)
																													: getValues(name)
																											)}
																											onBeforeInput={(
																												evt: any
																											) => {
																												if (type == 'number') {
																													if (
																														!exclude_number_validation
																													) {
																														!/[0-9]/.test(
																															evt.data
																														) &&
																															evt.preventDefault();
																													} else {
																														!/[0-9.]/.test(
																															evt.data
																														) &&
																															evt.preventDefault();
																													}
																												} else if (
																													is_comma_separated &&
																													type == 'text'
																												) {
																													restrictAmountChar(
																														evt,
																														getValues(
																															name
																														)?.toString()
																													);
																												}
																											}}
																											onKeyDown={(e: any) => {
																												if (type == 'number') {
																													disableNumberArrows(
																														e
																													);
																												}
																											}}
																											onWheel={(e: any) => {
																												if (type == 'number') {
																													e.target.blur();
																												}
																											}}
																											disabled={
																												value
																													? true
																													: formCPAddressFields?.is_current_permanent_address &&
																													  getValues(
																															formCPAddressFields?.name +
																																index
																													  )
																													? formCPAddressFields?.permanent_address_fields?.includes(
																															name
																													  )
																													: false
																											}
																										/>
																									)}
																									name={name}
																								/>
																							</div>
																						);
																					} else if (
																						type === 'dropdownSelect'
																					) {
																						const dropdownName =
																							name.split('_')[0];
																						return (
																							<div key={index}>
																								<Controller
																									key={`controller-${index}`}
																									control={control}
																									render={({
																										field: {
																											onChange,
																											onBlur,
																											name,
																										},
																									}) => (
																										<DropDownModal
																											label={
																												t(`${placeholder}`) +
																												(is_required
																													? ' *'
																													: '')
																											}
																											data={
																												dropdownMasterData[
																													dropdownName
																												]
																													? dropdownMasterData[
																															dropdownName
																													  ]
																													: []
																											}
																											name={name}
																											onChange={({
																												detail: { value },
																											}: any) => {
																												onChange(value);
																											}}
																											onBlur={onBlur}
																											className={
																												errorText[name]?.message
																													? 'ion-invalid ion-touched mb-3'
																													: 'mb-3'
																											}
																											errors={t(
																												errorText[name]
																													?.message as string
																											)}
																											value={getValues(name)}
																											disabled={
																												value
																													? true
																													: formCPAddressFields?.is_current_permanent_address &&
																													  getValues(
																															formCPAddressFields?.name
																													  )
																													? formCPAddressFields?.permanent_address_fields.includes(
																															name
																													  )
																													: false
																											}
																										/>
																									)}
																									name={name}
																								/>
																							</div>
																						);
																					} else if (type === 'radio') {
																						return (
																							<div key={index}>
																								<Controller
																									key={index}
																									control={control}
																									render={({
																										field: { onChange, name },
																									}) => (
																										<div className='mb-2'>
																											<h6 className='mt-3'>
																												{t(placeholder) +
																													(is_required
																														? ' *'
																														: '')}
																											</h6>
																											<CustomRadioButton
																												type='group'
																												value={getValues(name)}
																												onIonChange={(
																													e: any
																												) => {
																													onChange(e);
																												}}
																												className='mb-2'
																												ionRadioClassName='me-4'
																												labelPlacement='end'
																												data={
																													Array.isArray(
																														radio_options
																													) &&
																													radio_options?.length
																														? radio_options
																														: []
																												}
																											/>
																											<div className='error-text px-3 sc-ion-input-md'>
																												{t(
																													errorText[name]
																														?.message
																												)}
																											</div>
																										</div>
																									)}
																									name={name}
																								/>
																							</div>
																						);
																					} else if (type === 'date') {
																						return (
																							<div key={index}>
																								<Controller
																									key={index}
																									control={control}
																									render={({
																										field: {
																											onChange,
																											onBlur,
																											name,
																										},
																									}) => (
																										<CustomInput
																											type={type}
																											needSuffixIcon={true}
																											suffixClassName={
																												'position-absolute date-of-birth-sufix'
																											}
																											suffixText={
																												<IonImg
																													src='/images/icons/input-dob-calendar-icon.svg'
																													alt='dob'
																												/>
																											}
																											outerClassName={
																												'position-relative'
																											}
																											placeholder={
																												t(placeholder) +
																												(is_required
																													? ' *'
																													: '')
																											}
																											onBlur={onBlur}
																											name={name}
																											onChange={onChange}
																											// errors={t(errors[name]?.message)}
																											errors={t(
																												errors[name]
																													?.message as any
																											)}
																											value={t(getValues(name))}
																											// disabled={
																											// 	value ? true : false
																											// }
																										/>
																									)}
																									name={name}
																								/>
																							</div>
																						);
																					}
																				}
																			);
																		}
																	)}
															</>
														)}
														name={name}
													/>
												</div>
											);
										}
									}
								)}
							</form>
							{onlineFormFields.length > 0 && (
								<div className={`d-none d-md-block`}>
									<CustomButton
										type='submit'
										className='w-100 fw-bold dashboard-add-lead-button'
										expand='block'
										title={t('Proceed')}
										disabled={!isValid}
										onClick={handleSubmit(onSubmit)}
									/>
								</div>
							)}
						</div>
					</div>
				) : (
					<div className='bg-web py-md-4 h-100 d-md-flex align-items-md-center justify-content-md-center'>
						<div className='border-md-radius container-width-md p-md-3 green-top-linear-gradient h-100 overflowY-auto d-flex align-items-center justify-content-center'>
							<div className='text-center h-auto pb-3 pb-md-0'>
								{showCheckIcon ? (
									<Player
										autoplay={true}
										loop={false}
										controls={false}
										keepLastFrame={true}
										src='/images/icons/json/SendforRework.json'
										style={{ height: '102px', width: '102px' }}
									/>
								) : (
									''
								)}
								<h3 className='fs-18 fw-600 darkest-grey'>
									{showOnlineFormMessage}
								</h3>
								<CustomButton
									className='fw-bold dashboard-add-lead-button mt-3'
									expand='block'
									title={t('Done')}
									onClick={() => {
										leadInfo?.isBackLenderOnlineForm
											? history.goBack()
											: history.replace('/lender-specific-info');
									}}
								/>
							</div>
						</div>
					</div>
				)}
			</IonContent>
			<IonFooter>
				{!showOnlineFormSuccess && onlineFormFields.length > 0 && (
					<div
						className={`ion-padding d-block d-md-none`}
						style={{
							backgroundColor: '#fff',
							zIndex: 2,
						}}
					>
						<div className='proceed-btn'>
							<CustomButton
								type='submit'
								className='w-100 fw-bold dashboard-add-lead-button'
								expand='block'
								title={t('Proceed')}
								disabled={!isValid}
								onClick={handleSubmit(onSubmit)}
							/>
						</div>
					</div>
				)}
			</IonFooter>
			{/* Upload Photo Modal */}
			<CustomModal
				id='sharemodal'
				isOpen={openUploadModal}
				initialBreakpoint={isWeb ? 1 : 0.3}
				handleClose={() => setOpenUploadModal(false)}
				breakpoints={[0, 0.3, 0.5, 0.75]}
				modalContent={
					<BankUploadDocuments
						setDocTypeErrorMessage={setDocTypeErrorMessage}
						docTypeErrorMessage={docTypeErrorMessage}
						setOpenUploadModal={setOpenUploadModal}
						getValues={getValues}
						dispatch={dispatch}
						unwrapResult={unwrapResult}
						setValue={setValue}
						trigger={trigger}
						setImagePreviewUpload={setImagePreviewUpload}
						imagePreviewUpload={imagePreviewUpload}
						urltoFile={urltoFile}
						isBrowser={isBrowser}
						clickImageFieldName={clickImageFieldName}
						t={t}
					/>
				}
				needCross={false}
				needArrow={false}
				className={`side-modal ${isWeb ? '' : 'modal-border-radius'}`}
			/>
		</IonPage>
	);
};
