import { IonCol, IonContent, IonGrid, IonRow } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { Otptimer } from 'otp-timer-ts';
import { SmsRetriever } from '@awesome-cordova-plugins/sms-retriever';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import {
	lenderSpfDetailsFlow,
	lenderTataPLGenerateOTP,
	lenderTataPLVerifyOTP,
} from '../../../../store/reducers/lead/leadSlice';
import '../../../otp-screen/OtpVerification.scss';
import { TataPLFormTitle } from '../TataCapitals';

interface OtpVerificationInterface {
	length?: number;
}

export const PLVerifyOtp: React.FC<OtpVerificationInterface> = ({
	length = 6,
}) => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
	const [otp, setOtp] = useState<any>(new Array(6).fill(''));
	const [error, setError] = useState<boolean>(false);
	const [valid, setVaidOtp] = useState<any>(false);
	const [otpResponse, setOTPResponse] = useState<any>(null);
	const { lenderSpecificDetails, leadFlowData, selectedLender } = useSelector(
		(state: any) => state?.lead
	);
	const validOtp =
		otp[0] !== '' &&
		otp[1] !== '' &&
		otp[2] !== '' &&
		otp[3] !== '' &&
		otp[4] !== '' &&
		otp[5] !== '';

	const handleChange = async (element: any | undefined, index: number) => {
		if (isNaN(element.value)) return false;
		setOtp([
			...otp.map((d: any, idx: any) => (idx === index ? element.value : d)),
		]);
		if (index <= 5) {
			element.value && inputRefs.current[index + 1]?.focus();
		}
		if (index >= 0) {
			setError(false);
		}
	};

	const handleKeyDown = (index: number, e: any) => {
		if (e.key === 'Backspace' && index > 0) {
			e.target.value == '' && inputRefs.current[index - 1]?.focus();
		}
	};

	const sendOtp = async () => {
		inputRefs.current[0]?.focus();
		setOtp(new Array(6).fill(''));

		await dispatch(
			lenderTataPLGenerateOTP({
				data: {
					mobile_no: leadFlowData?.leadMobile,
					lead_id: leadFlowData?.leadId,
					lender_id: selectedLender[0]?.lender_id,
					lead_application_id: selectedLender[0]?.id,
				},
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setOTPResponse(response.data);
					setOtp(new Array(6).fill(''));
					setVaidOtp(false);
				}
			});
	};

	// verify otp
	const verifyLeadOtp = async () => {
		if (validOtp) {
			await dispatch(
				lenderTataPLVerifyOTP({
					data: {
						otpRefNo: otpResponse?.otpRefNo,
						otp: otp.join('').toString(),
						// step: 'step2',
						lead_spf_detail_id: otpResponse?.lead_spf_detail_id,
						// lender_name: LenderNameForAPICall.TATA_BL,
					},
				})
			)
				.then(unwrapResult)
				.then(async (response: any) => {
					if (response?.status) {
						dispatch(
							lenderSpfDetailsFlow({
								...lenderSpecificDetails,
								lead_spf_detail_id: otpResponse?.lead_spf_detail_id,
								headerTitle: TataPLFormTitle.ADDRESS_INFO,
								step: 'step2',
							})
						);

						setVaidOtp(false);
					}
				});
		} else {
			setError(true);
		}
	};

	useEffect(() => {
		sendOtp();
		if (inputRefs?.current?.length > 0) {
			const value: any = inputRefs.current ? inputRefs.current : false;
			if (value && Array.isArray(value)) {
				setTimeout(() => value?.[0].focus(), 700);
			}
		}
	}, []);

	useEffect(() => {
		SmsRetriever.startWatching()
			.then((res: any) => {
				if (res?.Message) {
					const otpregex = new RegExp(/^[0-9]{6}$/);
					const msgArray = res?.Message.split(' ');
					let getOtp: any;
					msgArray.map((i: any) => {
						if (otpregex.test(i)) {
							getOtp = i;
						}
					});
					const otp1 = getOtp.split('').map((i: any) => {
						return i;
					});
					setOtp(otp1);
				}
			})
			.catch((error: any) => console.error(error));
	}, []);

	return (
		<IonContent className='otp-verification'>
			<div className='select-product small-container h-100 bg-web'>
				<div className='bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
					<IonGrid>
						<IonRow>
							<IonCol>
								<div className='mt-4 mt-md-0'>
									<h2 className='ion-no-margin fw-600 fs-22'>
										{t('We have sent you an OTP')}
									</h2>
									<div className='ion-no-margin description'>
										<span className='text-grey'>
											{t('Please enter the OTP sent on your')}
											<br />
											{t('Mobile No.')} +91 XXXXXX
											{String(leadFlowData?.leadMobile)?.slice(6)}.
										</span>
									</div>
								</div>
							</IonCol>
						</IonRow>
					</IonGrid>
					<IonGrid className='otp-input-box'>
						<IonRow>
							{[...Array(length)].map((item, index) => {
								return (
									<IonCol
										size='2'
										key={index}
									>
										<input
											inputMode='numeric'
											pattern='[0-9]*'
											max='1'
											id={'otp' + index}
											ref={(ref) => (inputRefs.current[index] = ref)}
											value={otp[index] || ''}
											onChange={(e: any) => handleChange(e.target, index)}
											onKeyDown={(e) => handleKeyDown(index, e)}
											maxLength={1}
											className={`form-control text-center ${
												valid
													? 'input-invalid'
													: validOtp && !valid && error && 'input-valid'
											}`}
											required={error}
										></input>
									</IonCol>
								);
							})}
						</IonRow>
						{error && (
							<p className='error fs-12 text-danger ms-2 mb-0'>
								{t('Please Enter OTP')}
							</p>
						)}
						{valid && (
							<p className='error fs-12 text-danger ms-2 mb-0'>
								{t('Please Enter Valid OTP')}
							</p>
						)}
					</IonGrid>
					<div className={'pt-2'}>
						<CustomButton
							title={t('Verify')}
							expand='block'
							onClick={verifyLeadOtp}
						/>

						<div className='ion-no-margin pt-3'>
							{t('Didn’t receive OTP?')}{' '}
							<div className='d-inline-block'>
								<Otptimer
									minutes={0}
									seconds={59}
									onResend={() => {
										sendOtp();
									}}
									text={t('Resend OTP in').toString()}
									buttonText={t('Resend OTP').toString()}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</IonContent>
	);
};
