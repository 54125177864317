import * as yup from 'yup';
import { REGEX } from '../../../helpers/constant';
import InsuranceLeadStepInfoForm from './InsuranceLeadStepInfoForm.json';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	IonContent,
	IonFooter,
	IonItem,
	IonLabel,
	IonRadioGroup,
} from '@ionic/react';
import CustomRadioButton from '../../../components/common/custom-radio-button/CustomRadioButton';

import DropDownModal from '../../../components/common/custom-dropdown/DropDownModal';
import {
	disableNumberArrows,
	showToastAlert,
} from '../../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import {
	apiGenderDropdown,
	apiGetLeadDetails,
	apiMaritalDropdown,
	apiUpdateLeadDetails,
	leadFlow,
} from '../../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { contributionPinCode } from '../../../store/reducers/user/userSlice';

const validationSchema = yup.object().shape({
	pan: yup
		.string()
		.required('PAN No. is Required.')
		.trim()
		.matches(
			REGEX.PAN,
			'PAN no. should be in alphanumeric format "ABCDE1234A" only'
		),
	// application_number: yup.string().required('First Name is Required'),
	// .matches(REGEX, 'Please Enter Valid Input'),
	first_name: yup
		.string()
		.trim()
		.max(100, 'First Name should not be more than 100')
		.required('First Name is Required')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
	middle_name: yup
		.string()
		.trim()
		.max(100, 'Middle Name should not be more than 100')
		//.required('Middle Name is Required')
		.notRequired()
		.matches(REGEX.ALLOWONLYCHAR, {
			message: 'Please Enter Valid Input',
			excludeEmptyString: true,
		}),
	last_name: yup
		.string()
		.trim()
		.max(100, 'Last Name should not be more than 100')
		.required('Last Name is Required')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
	gender: yup.string().required('Gender is Required'),
	marital_status: yup.string().required('Martial Status is Required'),
	pincode: yup
		.string()
		.trim()
		.required('Pin Code Number is Required')
		.length(6, 'Pin Code Should Be 6 Digits'),
});

const LoanLeadStepInfoForm: React.FC = () => {
	const { t } = useTranslation();
	// const location = useLocation<any>();
	const [pinCodeData, setPinCodeData] = useState<any>([]);
	const [pinCodeValue, setPincodeValue] = useState<any>({});
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [modalPageClose, setModalPageClose] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [lead, setLead] = useState();
	const dispatch: any = useDispatch();
	const leadFlowSelector = useSelector((state: any) => state?.lead);
	const { leadFlowData } = leadFlowSelector;
	const [genderApi, setGenderApi] = useState([]);
	const [maritalApi, setMaritalApi] = useState([]);
	const userData = useSelector((state: any) => state?.users?.user_details);

	const {
		handleSubmit,
		control,
		setValue,
		reset,
		getValues,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			pan: '',
			application_number: '',
			first_name: '',
			middle_name: '',
			last_name: '',
			gender: '',
			marital_status: '',
			pincode: '',
			city: '',
			state: '',
			policy_proposed_for: '',
		},
	});

	useEffect(() => {
		getLeads();
		getGenderAndMaritalDropDown();
	}, []);

	const getGenderAndMaritalDropDown = async () => {
		await dispatch(apiGenderDropdown())
			.then(unwrapResult)
			.then((result: any) => {
				setGenderApi(result.data);
			});
		await dispatch(apiMaritalDropdown())
			.then(unwrapResult)
			.then((result: any) => {
				setMaritalApi(result.data);
			});
	};

	useEffect(() => {
		leadFlowData?.leadCurrentStep === 'step2' &&
			dispatch(
				leadFlow({
					...leadFlowSelector?.leadFlowData,
					productFor: 'self',
				})
			);
	}, [leadFlowData?.leadCurrentStep]);

	const getLeads = async () => {
		await dispatch(apiGetLeadDetails({ param: leadFlowData?.leadId }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setLead(result?.leads);
					reset({
						pan: result?.leads?.pan,
						first_name: result?.leads?.first_name,
						middle_name: result?.leads?.middle_name,
						last_name: result?.leads?.last_name,
						pincode: result?.leads?.pincode,
						gender: result?.leads?.gender,
						marital_status: result?.leads?.marital_status,
						city: result?.leads?.city,
						state: result?.leads?.state,
						policy_proposed_for: result?.leads?.policy_proposed_for,
					});
					if (result?.leads?.pincode) {
						setPincodeValue({
							state: result?.leads?.state,
							city: result?.leads?.city,
						});
					}
				}
			});
	};

	const onSubmit = async (data: any) => {
		data['state'] = pinCodeValue?.state || '';
		data['city'] = pinCodeValue?.city || '';

		await dispatch(
			apiUpdateLeadDetails({
				param: leadFlowData?.leadId,
				data: {
					...data,
					loginUserId: userData?.id,
					product: leadFlowData?.productType,
					doc_no: data?.pan,
					application_number: data?.pan,
					first_name: data?.first_name,
					middle_name: data?.middle_name,
					last_name: data?.last_name,
					gender: data?.gender,
					marital_status: data?.marital_status,
					pincode: data?.pincode,
					city: pinCodeValue?.city,
					state: pinCodeValue?.state,
					policy_proposed_for: data?.policy_proposed_for,
					insurance_page_no: '',
				},
			})
		)
			.then(unwrapResult)
			.then(async (result: any) => {
				if (result?.status) {
					await dispatch(
						leadFlow({
							...leadFlowSelector?.leadFlowData,
							leadCurrentStep: 'step3',
							title: 'Add Lead',
							productFor: data?.policy_proposed_for,
						})
					);
				}
			});
	};

	const enableButton: boolean =
		Object.keys(errors).length === 0 && Object.keys(pinCodeValue).length !== 0;

	const pinCodeAction = async (value: any) => {
		setPinCodeData([]);
		await dispatch(
			contributionPinCode({
				data: {
					pincode: value,
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					if (!result?.list) {
						showToastAlert({
							type: 'error',
							message: 'Please Check Pin Code No.',
						});
						setPinCodeData([]);
					} else {
						// showToastAlert({ type: 'success', message: result?.message });
						setPinCodeData(result?.list);
						setPincodeValue({ city: result?.leads?.city });
					}
				}
			});
	};

	return (
		<>
			<IonContent className='insurance-lead-background ion-padding bg-web'>
				<div className=' very-small-container bg-web h-90'>
					<div className='bg-web-white container-width-md p-md-5 border-radius-10'>
						<form onSubmit={handleSubmit(onSubmit)}>
							{InsuranceLeadStepInfoForm.map(
								({ name, type, data, placeholder }: any, i: any) => {
									const errorText: any =
										errors && Object.keys(errors).length > 0 && errors;

									if (name === 'first_name') {
										return (
											<div key={i}>
												<h4 className='fw-bold fs-14 heading-color'>
													{t('Proposer Name for Lead')}
												</h4>
												<Controller
													control={control}
													render={({ field: { onChange, onBlur, name } }) => (
														<CustomInput
															type={type}
															label={placeholder}
															onBlur={onBlur}
															name={name}
															onChange={onChange}
															value={getValues(name)}
															errors={errorText[name]?.message}
															onBeforeInput={(evt: any) => {
																if (type == 'number') {
																	!/[0-9]/.test(evt.data) &&
																		evt.preventDefault();
																}
															}}
															onKeyDown={(e: any) => {
																if (type == 'number') {
																	disableNumberArrows(e);
																}
															}}
															onWheel={(e: any) => {
																if (type == 'number') {
																	e.target.blur();
																}
															}}
														/>
													)}
													name={name}
												/>
											</div>
										);
									}
									return type === 'dropdownSelect' ? (
										<div style={{ marginBottom: '15px' }}>
											<Controller
												control={control}
												render={({ field: { onChange, onBlur, name } }) => (
													<DropDownModal
														label={t(`{placeholder}`)}
														data={
															name === 'gender'
																? genderApi
																: name === 'marital_status' && maritalApi
														}
														value={getValues(name)}
														name={name}
														onChange={onChange}
														onBlur={onBlur}
														errors={errorText[name]?.message}
													/>
												)}
												name={name}
											/>
										</div>
									) : type === 'radio' ? (
										<IonRadioGroup
											onIonChange={({ detail: { value } }: any) => {
												setValue('policy_proposed_for', value);
											}}
										>
											{name == 'policy_proposed_for' && (
												<h4 className='fw-bold fs-14 heading-color'>
													{t('Policy Proposed for')}
												</h4>
											)}
											<div className='row border-0'>
												{data &&
													data?.map((item: any, index: any) => {
														return (
															<div className='col-6'>
																<IonItem
																	key={index}
																	// lines={item?.name === 'Closed' ? 'none' : 'full'}
																	className='ion-no-padding'
																>
																	<CustomRadioButton
																		justify='start'
																		labelPlacement='end'
																		name={item.name}
																		value={item.value}
																		label={item.label}
																	/>
																</IonItem>
															</div>
														);
													})}
												{errors?.policy_proposed_for ? (
													<div className='input-bottom sc-ion-input-md ml-2'>
														<div className='error-text'>
															{errors?.policy_proposed_for?.message}
														</div>
													</div>
												) : (
													''
												)}
											</div>
										</IonRadioGroup>
									) : name === 'pincode' ? (
										<>
											<Controller
												control={control}
												render={({ field: { onChange, onBlur } }) => (
													<CustomInput
														type={type}
														placeholder={placeholder}
														onBlur={onBlur}
														name={name}
														onChange={(e: any) => {
															if (name === 'pincode') {
																if (e.detail.value.length === 6) {
																	pinCodeAction(e.detail.value);
																}
															}
															onChange(e);
														}}
														value={getValues(name)}
														errors={errorText[name]?.message}
													/>
												)}
												name={name}
											/>

											{pinCodeData && pinCodeData.length > 0 && (
												<>
													<IonLabel>{t('City')}</IonLabel>
													<div className='ion-margin-bottom pb-2'>
														{/*pinCodeData.map(({ Name }: any) => {
															return (
																<span
																	className={`selected-label selected-label-cursor ${
																		Name === pinCodeValue.city
																			? ' selected-label-active'
																			: ''
																	}`}
																	onClick={() => {
																		setPincodeValue({
																			state: pinCodeData[0].State,
																			city: Name,
																		});
																	}}
																>
																	{Name}
																</span>
															);
														})*/}
														<span
															key={pinCodeData[0].Region}
															className={`selected-label selected-label-cursor ${
																pinCodeData[0].Region === pinCodeValue.city
																	? ' selected-label-active'
																	: ''
															}`}
															onClick={() => {
																setPincodeValue({
																	state: pinCodeData[0].State,
																	city: pinCodeData[0].Region,
																});
															}}
														>
															{pinCodeData[0].Region}
														</span>
													</div>
												</>
											)}
											{pinCodeData && pinCodeData.length > 0 && (
												<>
													<IonLabel>{t('State')}</IonLabel>
													<div className='ion-margin-bottom'>
														<span
															className='selected-label'
															onChange={() => {
																setValue('state', pinCodeData[0].State);
															}}
														>
															{pinCodeData[0].State}
														</span>
													</div>
												</>
											)}
										</>
									) : (
										name !== 'city' &&
										name !== 'state' && (
											<Controller
												control={control}
												render={({ field: { onChange, onBlur, name } }) => (
													<CustomInput
														type={type}
														label={placeholder}
														onBlur={onBlur}
														onChange={(e: any) => {
															onChange(e);
														}}
														value={
															name === 'pan' && getValues('pan') != null
																? getValues('pan').toUpperCase()
																: getValues(name)
														}
														notRequiredValue={name !== 'pan'}
														errors={errorText[name]?.message}
													/>
												)}
												name={name}
											/>
										)
									);
								}
							)}
							<div className={`ion-padding mt-md-4 d-none d-md-block`}>
								{leadFlowData?.from !== 'customer' ? (
									<CustomButton
										className='w-100 fw-bold dashboard-add-lead-button mb-3'
										expand='block'
										fill='outline'
										title={t('Continue Later')}
										onClick={() => setModalPageClose(true)}
									/>
								) : (
									''
								)}
								<div className='proceed-btn'>
									<CustomButton
										type='submit'
										className='w-100 fw-bold dashboard-add-lead-button'
										expand='block'
										title={t('Proceed')}
										disabled={!enableButton}
										onClick={handleSubmit(onSubmit)}
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</IonContent>
			<IonFooter>
				<div
					style={{
						backgroundColor: '#fff',
						zIndex: 2,
					}}
				>
					<div className={`ion-padding bg-web-white d-block d-md-none`}>
						{leadFlowData?.from !== 'customer' ? (
							<CustomButton
								className='w-100 fw-bold dashboard-add-lead-button mb-3'
								expand='block'
								fill='outline'
								title={t('Continue Later')}
								onClick={() => setModalPageClose(true)}
							/>
						) : (
							''
						)}
						<div className='proceed-btn'>
							<CustomButton
								type='submit'
								className='w-100 fw-bold dashboard-add-lead-button'
								expand='block'
								title={t('Proceed')}
								disabled={!enableButton}
								onClick={handleSubmit(onSubmit)}
							/>
						</div>
					</div>
				</div>
			</IonFooter>
		</>
	);
};

export default LoanLeadStepInfoForm;
