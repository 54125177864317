import {
	IonContent,
	IonPage,
	IonSegment,
	IonSegmentButton,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { unwrapResult } from '@reduxjs/toolkit';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import lapCommonFormPersonalDetails from './lapCommonFormPersonalDetails.json';
import laplCommonFormAddressDetails from './laplCommonFormAddressDetails.json';
// import laplCommonFormAddressDetails from './laplCommonFormAddressDetails.json';
import lapCommonFormLenderDetails from './lapCommonFormLenderDetails.json';
import './LapCommonFieldsForm.scss';
import { useHistory } from 'react-router';
import {
	apiGenderDropdown,
	fetchCommonFieldsDataPl,
	postCommonFieldsDataPl,
} from '../../../store/reducers/lead/leadSlice';
import { Header } from '../../../components/layout/header/Header';
import DropDownModal from '../../../components/common/custom-dropdown/DropDownModal';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import {
	capFirstChar,
	disableNumberArrows,
	showToastAlert,
} from '../../../helpers/functions';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { CustomCheckbox } from '../../../components/common/custom-checkbox/CustomCheckbox';
import { contributionPinCode } from '../../../store/reducers/user/userSlice';
const LapCommonFieldsForm = () => {
	const [selectedTab, setSelectedTab] = useState('personalDetails');
	const [pinCodeDataError, setPinCodeDataError] = useState<any>({});
	const [validationSchema] = useState<any>(
		yup.object().shape({
			gender: yup.string().required('Please select Gender'),
			req_tenure: yup
				.string()
				.trim()
				.max(3, 'Requested Tenure should be 3 Digits')
				.required('Requested Tenure is Required'),
			curr_add_1: yup
				.string()
				.trim()
				.max(100, 'Current Address 1 should not be more than 100')
				.matches(
					/^[a-zA-Z0-9 _",():#-]*$/,
					'Please Enter Valid Format. Only allows (_",():#-)'
				)
				.required('Current Address 1 is Required'),
			curr_add_2: yup
				.string()
				.trim()
				.max(100, 'Current Address 2 should not be more than 100')
				.matches(
					/^[a-zA-Z0-9 _",():#-]*$/,
					'Please Enter Valid Format. Only allows (_",():#-)'
				)
				.required('Current Address 2 is Required'),
			curr_add_3: yup
				.string()
				.trim()
				.max(100, 'Current Address 3 should not be more than 100')
				.matches(
					/^[a-zA-Z0-9 _",():#-]*$/,
					'Please Enter Valid Format. Only allows (_",():#-)'
				)
				.required('Current Address 3 is Required'),
			curr_pincode: yup
				.string()
				.trim()
				.matches(/^\d{6}$/, 'Current Pincode should be 6 Digits')
				.length(6, 'Current Pincode should be 6 Digits')
				.required('Current Pincode is Required'),
			per_add_1: yup
				.string()
				.trim()
				.max(100, 'Permanent Address 1 should not be more than 100')
				.matches(
					/^[a-zA-Z0-9 _",():#-]*$/,
					'Please Enter Valid Format. Only allows (_",():#-)'
				)
				.required('Permanent Address 1 is Required'),
			per_add_2: yup
				.string()
				.trim()
				.max(100, 'Permanent Address 2 should not be more than 100')
				.matches(
					/^[a-zA-Z0-9 _",():#-]*$/,
					'Please Enter Valid Format. Only allows (_",():#-)'
				)
				.required('Permanent Address 2 is Required'),
			per_add_3: yup
				.string()
				.trim()
				.max(100, 'Permanent Address 3 should not be more than 100')
				.matches(
					/^[a-zA-Z0-9 _",():#-]*$/,
					'Please Enter Valid Format. Only allows (_",():#-)'
				)
				.required('Permanent Address 3 is Required'),
			per_pincode: yup
				.string()
				.trim()
				.matches(/^\d{6}$/, 'Permanent Pincode should be 6 Digits')
				.length(6, 'Permanent Pincode should be 6 Digits')
				.required('Permanent Pincode is Required'),
			off_add_1: yup
				.string()
				.trim()
				.max(100, 'Office Address 1 should not be more than 100')
				.matches(
					/^[a-zA-Z0-9 _",():#-]*$/,
					'Please Enter Valid Format. Only allows (_",():#-)'
				)
				.required('Office Address 1 is Required'),
			off_add_2: yup
				.string()
				.trim()
				.max(100, 'Office Address 2 should not be more than 100')
				.matches(
					/^[a-zA-Z0-9 _",():#-]*$/,
					'Please Enter Valid Format. Only allows (_",():#-)'
				)
				.required('Office Address 2 is Required'),
			off_add_3: yup
				.string()
				.trim()
				.max(100, 'Office Address 3 should not be more than 100')
				.matches(
					/^[a-zA-Z0-9 _",():#-]*$/,
					'Please Enter Valid Format. Only allows (_",():#-)'
				)
				.required('Office Address 3 is Required'),
			off_pincode: yup
				.string()
				.trim()
				.matches(/^\d{6}$/, 'Office Pincode should be 6 Digits')
				.length(6, 'Office Pincode should be 6 Digits')
				.required('Office Pincode is Required'),
		})
	);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const location = useLocation();
	const [genderApi, setGenderApi] = useState([]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [pinCodeData, setPinCodeData] = useState<any>({});
	const [disablePermanentAddress, setDisablePermanentAddress] =
		useState<boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars

	const history = useHistory();
	const LeadID = useSelector((state: any) => state?.lead?.leadFlowData?.leadId);
	const leadData = useSelector((state: any) => state?.lead?.leadFlowData);
	const { selectedLender } = useSelector((state: any) => state?.lead);

	const [errorStatus, setErrorStatus] = useState(false);
	const handleSegmentChange = (value: any) => {
		setSelectedTab(value);
	};
	const {
		setValue,
		getValues,
		trigger,
		control,
		reset: resetLenderDetails,
		control: controlLenderDetails,
		getValues: getValuesLenderDetails,
		handleSubmit: handleSubmitLenderDetails,
		watch: lenderVal,
		watch: watchCurrentPermanentAddressSame,
		formState: { errors: errorsLenderDetails },
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			gender: '',
			curr_add_1: '',
			curr_add_2: '',
			curr_add_3: '',
			curr_pincode: '',
			current_permanent_add_same: false,
			per_add_1: '',
			per_add_2: '',
			per_add_3: '',
			per_pincode: '',
			off_add_1: '',
			off_add_2: '',
			off_add_3: '',
			off_pincode: '',
			req_tenure: '',
		},
	});

	useEffect(() => {
		getGenderAndMaritalDropDown();
	}, []);

	const getGenderAndMaritalDropDown = async () => {
		await dispatch(apiGenderDropdown())
			.then(unwrapResult)
			.then((result: any) => {
				const genderValue = result.data.map((item: any) => ({
					value: item.value,
				}));
				setGenderApi(genderValue);
			});
	};

	const pinCodeAction = async (value: any, pincode_key: any) => {
		await dispatch(
			contributionPinCode({
				data: {
					pincode: value,
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					if (!result?.list) {
						showToastAlert({
							type: 'error',
							message: 'Please Check Pin Code No.',
						});
						setPinCodeData({});
					} else {
						showToastAlert({ type: 'success', message: result?.message });
						setPinCodeData((prev: any) => ({
							...prev,
							[pincode_key]: result?.list,
						}));
						const pincodeHelper = { ...pinCodeDataError };
						delete pincodeHelper[pincode_key];
						setPinCodeDataError(pincodeHelper);
					}
				} else {
					setPinCodeDataError((prev: any) => ({
						...prev,
						[pincode_key]: result?.message,
					}));
				}
			});
	};
	useEffect(() => {
		if (location.pathname !== '/lap-common-fields-form') {
			return;
		}
		setSelectedTab('personalDetails');
		resetLenderDetails({
			gender: '',
			curr_add_1: '',
			curr_add_2: '',
			curr_add_3: '',
			curr_pincode: '',
			per_add_1: '',
			per_add_2: '',
			per_add_3: '',
			per_pincode: '',
			off_add_1: '',
			off_add_2: '',
			off_add_3: '',
			off_pincode: '',
			req_tenure: '',
		});
		setPinCodeData([]);
		getFormData();
	}, [location?.pathname]);

	const getFormData = async () => {
		await dispatch(
			fetchCommonFieldsDataPl({
				param: '/' + LeadID,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response.status) {
					if (response?.current_permanent_add_same) {
						setDisablePermanentAddress(true);
					}
					resetLenderDetails({
						gender: response?.data?.gender,
						curr_add_1: response?.data?.curr_add_1,
						curr_add_2: response?.data?.curr_add_2,
						curr_add_3: response?.data?.curr_add_3,
						curr_pincode: response?.data?.curr_pincode,
						per_add_1: response?.data?.per_add_1,
						per_add_2: response?.data?.per_add_2,
						per_add_3: response?.data?.per_add_3,
						per_pincode: response?.data?.per_pincode,
						off_add_1: response?.data?.off_add_1,
						off_add_2: response?.data?.off_add_2,
						off_add_3: response?.data?.off_add_3,
						off_pincode: response?.data?.off_pincode,
						req_tenure: response?.data?.req_tenure,
						current_permanent_add_same:
							response?.data?.current_permanent_add_same,
					});
				}
			});
	};

	// Personal Details Tab
	const onSubmitPersonalDetails = async () => {
		const errorSet = { ...errorsLenderDetails };
		const data: any = {
			lender_name: selectedLender[0]?.lender_name,
			gender: lenderVal('gender'),
		};
		Object.entries(errorSet).forEach(([key]) => {
			if (key in data) {
				delete data[key];
			}
		});
		await dispatch(
			postCommonFieldsDataPl({
				param:
					'/' +
					LeadID +
					'?btn_flag=personal_dtls&policy=' +
					leadData?.selectedProductData?.product_category_id,
				data: data,
			})
		);
		setSelectedTab('addressDetails');
	};

	const onSubmitAddressDetails = async () => {
		const errorSet = { ...errorsLenderDetails, ...pinCodeDataError };

		const data: any = {
			// lender_name: currentLenderInfo[0]?.lender_name ,
			lender_name: selectedLender[0]?.lender_name,
			curr_add_1: lenderVal('curr_add_1'),
			curr_add_2: lenderVal('curr_add_2'),
			curr_add_3: lenderVal('curr_add_3'),
			curr_pincode: lenderVal('curr_pincode'),
			off_add_1: lenderVal('off_add_1'),
			off_add_2: lenderVal('off_add_2'),
			off_add_3: lenderVal('off_add_3'),
			off_pincode: lenderVal('off_pincode'),
			per_add_1: lenderVal('per_add_1'),
			per_add_2: lenderVal('per_add_1'),
			per_add_3: lenderVal('per_add_1'),
			per_pincode: lenderVal('per_pincode'),
			current_permanent_add_same: lenderVal('current_permanent_add_same'),
		};

		Object.entries(errorSet).forEach(([key]) => {
			if (key in data) {
				delete data[key];
			}
		});

		dispatch(
			postCommonFieldsDataPl({
				param:
					'/' +
					LeadID +
					'?btn_flag=address_dtls&policy=' +
					leadData?.selectedProductData?.product_category_id,
				data: data,
			})
		);
		setSelectedTab('lenderDetails');
	};

	const onSubmitLenderDetails = async () => {
		const errorSet = { ...errorsLenderDetails };
		const data: any = {
			...lenderVal(),
			lender_name: selectedLender[0]?.lender_name,
			req_tenure: lenderVal('req_tenure'),
		};
		Object.entries(errorSet).forEach(([key]) => {
			if (key in data) {
				delete data[key];
			}
		});

		if (
			Object.values(errorsLenderDetails).length > 0 ||
			Object.values(pinCodeDataError).length > 0
		) {
			setErrorStatus(!errorStatus);
		} else if (
			pinCodeDataError?.curr_pincode ||
			pinCodeDataError?.per_pincode ||
			pinCodeDataError?.off_pincode
		) {
			setSelectedTab('addressDetails');
		} else {
			await dispatch(
				postCommonFieldsDataPl({
					param:
						'/' +
						LeadID +
						'?btn_flag=lender_common_dtls&policy=' +
						leadData?.selectedProductData?.product_category_id,
					data: data,
				})
			);
			setErrorStatus(!errorStatus);
			history.push('/verify-lender-with-otp');
		}
	};

	const onSubmitLenderDetailsContinue = async () => {
		const errorSet = { ...errorsLenderDetails };
		const data: any = {
			...lenderVal(),
			lender_name: selectedLender[0]?.lender_name,
			req_tenure: lenderVal('req_tenure'),
		};
		Object.entries(errorSet).forEach(([key]) => {
			if (key in data) {
				delete data[key];
			}
		});

		await dispatch(
			postCommonFieldsDataPl({
				param:
					'/' +
					LeadID +
					'?btn_flag=lender_common_dtls&policy=' +
					leadData?.product_category_id +
					'&ctn_btn_flag=lead',
				data: data,
			})
		);
		history.push('/verify-lender-with-otp');
	};

	const isMobileDevice = () => {
		return window.innerWidth <= 768;
	};

	const errorText: any =
		errorsLenderDetails &&
		Object.keys(errorsLenderDetails).length > 0 &&
		errorsLenderDetails;
	const hasPinCodeErrors = Object.values(pinCodeDataError).some(
		(error) => error !== undefined
	);

	useEffect(() => {
		if (errorText?.gender?.message) {
			setSelectedTab('personalDetails');
		} else if (
			errorText?.curr_add_1?.message ||
			errorText?.curr_add_2?.message ||
			errorText?.curr_add_3?.message ||
			errorText?.curr_pincode?.message ||
			errorText?.per_add_1?.message ||
			errorText?.per_add_2?.message ||
			errorText?.per_add_3?.message ||
			errorText?.per_pincode?.message ||
			errorText?.off_add_1?.message ||
			errorText?.off_add_2?.message ||
			errorText?.off_add_3?.message ||
			errorText?.off_pincode?.message ||
			pinCodeDataError?.curr_pincode ||
			pinCodeDataError?.per_pincode ||
			pinCodeDataError?.off_pincode ||
			hasPinCodeErrors == true
		) {
			setSelectedTab('addressDetails');
		} else if (errorText?.req_tenure?.message) {
			setSelectedTab('lenderDetails');
		}
	}, [errorText]);

	return (
		<IonPage>
			<Header
				name={t('Loan Against Property - Common Form')}
				needCross={true}
				backButtonDisabled={false}
				backButton={true}
			/>
			<IonContent>
				<IonSegment
					value={selectedTab}
					onIonChange={(e) => handleSegmentChange(e.target.value)}
					style={{
						position: 'sticky',
						top: 0,
						zIndex: 999,
						backgroundColor: 'white',
					}}
				>
					{/* Personal Details Segment Button */}
					<IonSegmentButton
						value='personalDetails'
						style={{ padding: '5px' }}
					>
						<label>
							<span>{t('Personal')}</span>
							{isMobileDevice() ? null : <span> {t('Details')}</span>}
						</label>
					</IonSegmentButton>

					{/* Address Details Segment Button */}
					<IonSegmentButton value='addressDetails'>
						<label>
							<span>{t('Address')}</span>
							{isMobileDevice() ? null : <span> {t('Details')}</span>}
						</label>
					</IonSegmentButton>

					{/* Lender Details Segment Button */}
					<IonSegmentButton value='lenderDetails'>
						<label>
							<span>{t('Lender')}</span>
							{isMobileDevice() ? null : <span> {t('Details')}</span>}
						</label>
					</IonSegmentButton>
				</IonSegment>

				{/* Personal Details Tab */}
				{selectedTab === 'personalDetails' && (
					<div className='select-product small-container bg-web '>
						<div className='bg-web-white container-width-md conatiner-spacing-md border-md-radius-top mt-20'>
							<form
								className='ofform'
								onSubmit={handleSubmitLenderDetails(onSubmitPersonalDetails)}
							>
								{lapCommonFormPersonalDetails.map(
									({ name, type, placeholder }: any) => {
										const errorText: any =
											errorsLenderDetails &&
											Object.keys(errorsLenderDetails).length > 0 &&
											errorsLenderDetails;

										return type === 'dropdownSelect' ? (
											<div style={{ paddingTop: '5px' }}>
												<Controller
													control={controlLenderDetails}
													render={({ field: { onChange, onBlur, name } }) => (
														<DropDownModal
															label={`${t(placeholder)} *`}
															data={name === 'gender' ? genderApi : null}
															value={
																capFirstChar(getValuesLenderDetails(name)) || ''
															}
															name={`${t(name)} *`}
															onChange={onChange}
															onBlur={onBlur}
															errors={t(errorText[name]?.message)}
														/>
													)}
													name={name}
												/>
												{errorText[name] && (
													<span
														style={{
															color: '#eb445a',
															fontSize: '10px',
															textTransform: 'initial',
														}}
													>
														{t(errorText[name]?.message)}
													</span>
												)}
											</div>
										) : (
											<div>
												<Controller
													control={controlLenderDetails}
													render={({ field: { onChange, onBlur, name } }) => (
														<CustomInput
															type={type}
															label={`${t(placeholder)} *`}
															onBlur={onBlur}
															name={`${t(name)} *`}
															onChange={onChange}
															value={getValuesLenderDetails(name)}
															errors={t(errorText[name]?.message)}
															onBeforeInput={(evt: any) => {
																if (type === 'number') {
																	!/[0-9]/.test(evt.data) &&
																		evt.preventDefault();
																}
															}}
															onKeyDown={(e: any) => {
																if (type === 'number') {
																	disableNumberArrows(e);
																}
															}}
															onWheel={(e: any) => {
																if (type === 'number') {
																	e.target.blur();
																}
															}}
														/>
													)}
													name={name}
												/>
											</div>
										);
									}
								)}

								<div
									className='button-container container-width-md container-spacing-md'
									style={{ display: 'flex', justifyContent: 'center' }}
								>
									<CustomButton
										onClick={() => {
											onSubmitPersonalDetails().then(() => {
												history.push('/dashboard/lead');
											});
										}}
										className='common-button'
										routerDirection='root'
										title={t('Continue Later')}
									/>

									<CustomButton
										// type='submit'
										className='fw-bold common-button'
										expand='block'
										onClick={onSubmitPersonalDetails}
										title={t('Save and Continue')}
									/>
								</div>
							</form>
						</div>
					</div>
				)}

				{/* Address Details Tab */}
				{selectedTab === 'addressDetails' && (
					<div className='select-product small-container bg-web'>
						<div className='bg-web-white container-width-md conatiner-spacing-md border-md-radius-top pt-2'>
							<form
								className='ofform'
								onSubmit={handleSubmitLenderDetails(onSubmitAddressDetails)}
							>
								{laplCommonFormAddressDetails.map(
									({ name, type, placeholder }: any, i: any) => {
										const errorText: any =
											errorsLenderDetails &&
											Object.keys(errorsLenderDetails).length > 0 &&
											errorsLenderDetails;
										return (
											<div key={i}>
												<Controller
													control={controlLenderDetails}
													render={({ field: { onChange, onBlur, name } }) => (
														<CustomInput
															type={type}
															label={`${t(placeholder)} *`}
															onBlur={onBlur}
															name={`${t(name)} *`}
															onChange={(e: any) => {
																if (
																	name === 'curr_pincode' ||
																	name === 'off_pincode' ||
																	name === 'per_pincode'
																) {
																	if (e.detail.value.length === 6) {
																		pinCodeAction(e.detail.value, name);
																	}
																}
																onChange(e);
															}}
															value={capFirstChar(getValuesLenderDetails(name))}
															errors={
																pinCodeDataError[name]
																	? pinCodeDataError[name]
																	: t(errorText[name]?.message)
															}
															onBeforeInput={(evt: any) => {
																if (type === 'number') {
																	!/[0-9]/.test(evt.data) &&
																		evt.preventDefault();
																}
															}}
															onKeyDown={(e: any) => {
																if (type === 'number') {
																	disableNumberArrows(e);
																}
															}}
															onWheel={(e: any) => {
																if (type === 'number') {
																	e.target.blur();
																}
															}}
															disabled={
																disablePermanentAddress
																	? watchCurrentPermanentAddressSame() &&
																	  [
																			'per_add_1',
																			'per_add_2',
																			'per_add_3',
																			'per_pincode',
																	  ].includes(name)
																	: setDisablePermanentAddress(false)
															}
														/>
													)}
													name={name}
												/>
												{name === 'curr_pincode' && (
													<div className=''>
														<Controller
															control={control}
															render={({
																field: { onChange, onBlur, value },
															}) => (
																<CustomCheckbox
																	name='current_permanent_add_same'
																	onChange={({ detail: { checked } }: any) => {
																		onChange(checked);

																		if (checked) {
																			setValue(
																				'per_add_1',
																				getValues('curr_add_1')
																			);
																			setValue(
																				'per_add_2',
																				getValues('curr_add_2')
																			);
																			setValue(
																				'per_add_3',
																				getValues('curr_add_3')
																			);
																			setValue(
																				'per_pincode',
																				getValues('curr_pincode')
																			);
																			trigger([
																				'per_add_1',
																				'per_add_2',
																				'per_add_3',
																				'per_pincode',
																			]);
																			setDisablePermanentAddress(true);
																		} else {
																			setValue('per_add_1', '');
																			setValue('per_add_2', '');
																			setValue('per_add_3', '');
																			setValue('per_pincode', '');
																			setDisablePermanentAddress(false);
																		}
																	}}
																	value={value}
																	labelPlacement='end'
																	className='end-of-page'
																	onBlur={onBlur}
																	isChecked={value}
																	children={
																		<span className='text-wrap'>
																			{t(
																				'Permanent Address is same as Current Address'
																			) + '.'}
																		</span>
																	}
																	// disabled={watchCurrentPermanentAddressSame() && ['per_add_1', 'per_add_2', 'per_add_3', 'per_pincode'].includes(name)}
																/>
															)}
															name='current_permanent_add_same'
														/>
													</div>
												)}
											</div>
										);
									}
								)}

								<div
									className='button-container container-width-md container-spacing-md'
									style={{ display: 'flex', justifyContent: 'center' }}
								>
									<CustomButton
										className='common-button'
										routerDirection='root'
										title={t('Continue Later')}
										onClick={() => {
											onSubmitAddressDetails().then(() => {
												history.push('/dashboard/lead');
											});
										}}
									/>
									<CustomButton
										// type='submit'
										className='fw-bold common-button'
										onClick={onSubmitAddressDetails}
										expand='block'
										title={t('Save and Continue')}
									/>
								</div>
							</form>
						</div>
					</div>
				)}

				{/* Lender Details Tab */}
				{selectedTab === 'lenderDetails' && (
					<div className='select-product small-container bg-web'>
						<div className='bg-web-white container-width-md conatiner-spacing-md border-md-radius-top lender-container'>
							<form
								className='ofform'
								onSubmit={handleSubmitLenderDetails(onSubmitLenderDetails)}
							>
								{lapCommonFormLenderDetails.map(
									({ name, type, placeholder }: any, i: any) => {
										const errorText: any =
											errorsLenderDetails &&
											Object.keys(errorsLenderDetails).length > 0 &&
											errorsLenderDetails;
										return (
											<div key={i}>
												<Controller
													control={controlLenderDetails}
													render={({ field: { onChange, onBlur, name } }) => (
														<CustomInput
															type={type}
															label={`${t(placeholder)} *`}
															onBlur={onBlur}
															name={`${t(name)} *`}
															onChange={onChange}
															value={getValuesLenderDetails(name)}
															errors={t(errorText[name]?.message)}
															onBeforeInput={(evt: any) => {
																if (type === 'number') {
																	!/[0-9]/.test(evt.data) &&
																		evt.preventDefault();
																}
															}}
															onKeyDown={(e: any) => {
																if (type === 'number') {
																	disableNumberArrows(e);
																}
															}}
															onWheel={(e: any) => {
																if (type === 'number') {
																	e.target.blur();
																}
															}}
														/>
													)}
													name={name}
												/>
											</div>
										);
									}
								)}

								<div
									className='button-container container-width-md container-spacing-md'
									style={{ display: 'flex', justifyContent: 'center' }}
								>
									<CustomButton
										onClick={() => {
											onSubmitLenderDetailsContinue().then(() => {
												history.push('/dashboard/lead');
											});
										}}
										className='common-button'
										routerDirection='root'
										title={t('Continue Later')}
									/>
									<CustomButton
										type='submit'
										className='fw-bold common-button'
										expand='block'
										onClick={onSubmitLenderDetails}
										title={t('Save and Continue')}
									/>
								</div>
							</form>
						</div>
					</div>
				)}
			</IonContent>
		</IonPage>
	);
};

export default LapCommonFieldsForm;
