//// @ts-nocheck

import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardContent,
} from "@ionic/react";

import { Header } from "../../components/layout/header/Header";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import basic_details_schema from "./basic_details.schema";
import { CustomButton } from "../../components/common/custom-button/CustomButton";
import { useTranslation } from "react-i18next";
import "./basic_details.module.scss";
import { useHistory } from "react-router-dom";
import CustomForm from "../../components/common/custom_form/custom_form.component";
// import {CustomForm} from 'andro-insurance-lib';
import {
  BasicDetailsForm,
  BasicDetailRequest,
} from "../../model/basic_details_bean";
import useApi from "../../api/useApi";
import { useBusyLoading } from "../../context/BusyLoadingContext";
import { BASIC_DETAILS } from "../../helpers/api-endpoints";
import { getUserAge, showToastAlert } from "../../helpers/functions";
import ApplicationContext from "../../context/ApplicationContext";
import { REDIRECT } from "../../helpers/constant";
import WarningMessage from "../../components/common/warning/back_refresh_warning";



interface Props {
  title: string;
  nextTabUrl?: string;
  isProposer: boolean;
  inpCallback?: () => void;
  basicDetailsForm?: BasicDetailsForm;
  readonly: boolean;

  // userId: any;
  // setShowBasicInfoForm: any;
  // getUserDetailsInfo: any;
}

const BasicDetails: React.FC<Props> = ({
  title,
  basicDetailsForm,
  inpCallback,
  isProposer,
  nextTabUrl,
  readonly = false,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [validateForm, setValidateForm] = useState<boolean>();

  const {
    proposerBasicDetailsValid,
    setProposerBasicDetailsValid,
    assuredBasicDetailsValid,
    setAssuredBasicDetailsValid,
  } = useContext(ApplicationContext);



  const basicDetailsFormKeys = [
    {
      name: "panNumber",
      type: "text",
      placeholder: "PAN *",
      maxlength:10,
    },
  
    {
      name: "mobileNumber",
      // type: "tel",
      type: "number",
      placeholder: "Mobile Number",
      maxlength:10,
      disabled: isProposer,
    },
    {
      name: "firstName",
      type: "text",
      placeholder: "First Name (as per Aadhaar) *",
    },
    {
      name: "middleName",
      type: "text",
      placeholder: "Middle Name (as per Aadhaar)",
    },
    {
      name: "lastName",
      type: "text",
      placeholder: "Last Name (as per Aadhaar) *",
    },
    {
      name: "gender",
      //hbs change here
      type: "text/combo",
      mandatory: true,
      placeholder: "Gender *",
      values: [
        { value: "male", description: "Male" },
        { value: "female", description: "Female" },
        { value: "transgender", description: "Transgender" },
      ],
    },
    // {
    //   name: "pincode",
    //   type: "number",
    //   placeholder: "Pincode *",
    // },
    {
      name: "dob",
      type: "date",
      placeholder: "Date of Birth",
    },
    {
      name: "age",
      type: "readonly",
      placeholder: "Age",
      value: "- Years",
    },
    {
      name: "emailId",
      type: "email",
      placeholder: "Email *",
    },
    {
      name: "alternateNumber",
      type: "tel",
      placeholder: "Alternate Mobile No.",
      maxlength:10,
    },
  ];


  const { responseData, loading, executeRequest, error } =
    useApi<any>(BASIC_DETAILS);

  const { setBusyState } = useBusyLoading();

  useEffect(() => {
    setBusyState(loading);
  }, [loading]);

  useEffect(() => {
    showToastAlert({ type: "error", message: error?.message! });
  }, [error]);

  useEffect(() => {
    if (responseData?.status) {
      showToastAlert({ type: "success", message: responseData?.message });

      if (inpCallback) {
        inpCallback();
        if (nextTabUrl) history.push(nextTabUrl);
      }
    }
  }, [responseData]);

  const {
    control,
    trigger,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(basic_details_schema),
    defaultValues: basicDetailsForm,
  });

  

  // const dmaSelector = useSelector((state: any) => state?.users?.dmaFlow);

  const dob = watch("dob");

  useEffect(() => {
    if(dob){
      try {
        const age = getUserAge(dob);
        setValue("age",`${age} years`);
        console.log("age>>>>>>>>>>",age);
      } catch (error) {
        console.error("Error calculating age: ", error)
        setValue("age",'-')
      }
    } else {
      setValue("age", '-');
    }
  }, [dob, setValue, getUserAge]);

  useEffect(() => {
    // console.log("basic details use effect called");
    if (isProposer) {
      setProposerBasicDetailsValid(isValid);
    } else {
      setAssuredBasicDetailsValid(isValid);
    }
  }, [
    isValid,
    proposerBasicDetailsValid,
    setProposerBasicDetailsValid,
    assuredBasicDetailsValid,
    setAssuredBasicDetailsValid,
  ]);

  // useEffect(() => {
  //   console.log("ages",getValues);

  //   setValue('age',getUserAge(getValues('dob')));
  //   // getUserAge(getValues('dob'));

  // }, [setValue,getValues]);

  // console.info(errors);

  const onSubmit = async (data: BasicDetailsForm) => {
    // console.log(data);

    // console.log(getValues('dob'));
    trigger();

    const payload = new BasicDetailRequest(data);
    // console.log("payload",payload);

    executeRequest("put", payload);
  };

  useMemo(() => {
    showToastAlert({ type: "error", message: error?.message! });
  }, [error]);

  // console.log('basic details readonly',readonly);

  return (

    <IonPage className="bg-web-white container-width-md container-spacing-md border-md-radius-top">
      <Header
        className="card-header"
        name={t(title ?? "Basic Details")}
        backButton={true}
        needArrow={true}
        needArrowOnClick={()=>{
          window.location.href = REDIRECT.DASHBOARD;
        }}
      />
      <IonContent className="ion-padding-bottom">
        <div className="mb-5 position-relative">
        <WarningMessage />
          <form onSubmit={handleSubmit(onSubmit)} className="form-label">
            <IonCard>
              <IonCardContent>
                <CustomForm
                  readonly={readonly}
                  formKeys={basicDetailsFormKeys}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  errors={errors}
                  isValid={isValid}
                  trigger={trigger}
                />
              </IonCardContent>
            </IonCard>
            <div
              className="position-fixed w-100 bottom-0 start-0 px-3 pb-2 bg-white"
              style={{ zIndex: 1000 }}
            >
              <CustomButton
                className="btn-blue"
                type="submit"
                expand="block"
                title="Update"
                onClick={() => {
                  handleSubmit(onSubmit);
                  setValidateForm(isValid);
                }}
              />
            </div>
          </form>
        </div>
      </IonContent>
    </IonPage>

  );
};

export default BasicDetails;
