import { IonSearchbar } from '@ionic/react';
import './CustomSearchbar.scss';
export const CustomSearchbar = ({
	searchIcon,
	placeholder,
	showClearButton,
	onChange,
	needFilter,
	onFilterClick,
	onIonClear,
	value = '',
	debounce = 500,
}: any) => {
	return (
		<>
			<IonSearchbar
				value={value}
				debounce={debounce}
				className='custom-search-bar p-0 search-bar-width-md'
				searchIcon={searchIcon}
				placeholder={placeholder}
				showClearButton={showClearButton}
				onIonInput={({ detail: { value } }: any) => {
					onChange(value);
				}}
				onIonClear={onIonClear}
				mode='md'
			/>
			{needFilter && (
				<img
					src='/images/icons/filter.svg'
					alt='filter'
					style={{
						paddingLeft: '16px',
					}}
					className='cursor-pointer'
					onClick={onFilterClick}
				/>
			)}
		</>
	);
};
/*
-- Guide

showClearButton - (focus/always/never/always)
searchIcon - any valida url or icon


*/
