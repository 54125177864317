import React from 'react';
import { IonPage, IonContent, IonImg, IonText } from '@ionic/react';
import tickImage from '../../public/tick.svg';
import './SuccessPage.scss';
import { useTranslation } from 'react-i18next';


export const SuccessPage: React.FC = () => {
	const { t } = useTranslation();
	return (
			<IonPage className='container'>
				<div className='success-page'>
					<IonImg
						src={tickImage}
						alt='Success'
						className='tick-image'
					/>
					<IonText className='success-message'>
						{t('Application Status updated successfully')}
					</IonText>{' '}
					<IonText className='success-description'>
						{t('Thank you for submitting your application.')}
					</IonText>
				</div>
		</IonPage>
	);
};
