import React, { useEffect } from 'react';
import { useIonRouter } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';

import { Header } from '../../../components/layout/header/Header';
import { LeadsFlowGenerator } from '../../../components/common/custom-leads-flow-generator/LeadsFlowGenerator';
import { lenderSpfDetailsFlow } from '../../../store/reducers/lead/leadSlice';
import { HLLAPForms } from './HLLAPForms/HLLAPForms';
import { BLVerifyOtp } from './BLForms/BLVerifyOTP';
import { BLFormStep1 } from './BLForms/BLFormStep1';
import { BLPerfiosVerification } from './BLForms/BLPerfiosVerification';
import { BLDocUploadForm } from './BLForms/BLDocUploadForm';
import { PLVerifyOtp } from './PLForms/PLVerifyOTP';
import { PLPersonalInfoForm } from './PLForms/PLPersonalInfoForm';
import { PLDocUploadForm } from './PLForms/PLDocUploadForm';
import { PLReferenceOne } from './PLForms/PLReferenceOne';
import { PLReferenceTwo } from './PLForms/PLReferenceTwo';
import './TataCapitals.scss';

export const InitiateTataLead: React.FC = () => {
	type ComponentType = React.ReactNode | React.ReactFragment;
	type StepsComponents = {
		step: string;
		component: ComponentType;
	};
	const dispatch = useDispatch();
	const router = useIonRouter();
	const { lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);

	useEffect(() => {
		if (!location.pathname.includes('/lead-lender-specific-form-steps')) {
			return;
		}
	}, [location.pathname]);

	/**
	 * Go back to click on back icon
	 */
	const headerNeedArrowOnClick = async () => {
		if (leadFlowData?.selectedProductData?.product_category_id == 2) {
			switch (lenderSpecificDetails?.step) {
				case 'step4':
					dispatch(
						lenderSpfDetailsFlow({
							...lenderSpecificDetails,
							step: 'step3',
						})
					);
					break;
				case 'step3':
					dispatch(
						lenderSpfDetailsFlow({
							...lenderSpecificDetails,
							step: 'step2',
						})
					);
					break;
				case 'step2':
					dispatch(
						lenderSpfDetailsFlow({
							...lenderSpecificDetails,
							step: 'step1',
						})
					);
					break;
				case 'step1':
					dispatch(lenderSpfDetailsFlow({}));
					router.push('/lender-specific-info');
					break;
			}
		} else {
			dispatch(lenderSpfDetailsFlow({}));
			router.push('/lender-specific-info');
		}
	};

	const stepsComponentsHLLAP: StepsComponents[] = [
		{
			step: 'step1',
			component: <HLLAPForms />,
		},
	];

	const stepsComponentsBL: StepsComponents[] = [
		{
			step: 'step1',
			component: <BLVerifyOtp />,
		},
		{
			step: 'step2',
			component: <BLFormStep1 />,
		},
		{
			step: 'step3',
			component: <BLPerfiosVerification />,
		},
		{
			step: 'step4',
			component: <BLDocUploadForm />,
		},
	];

	const stepsComponentsPL: StepsComponents[] = [
		{
			step: 'step1',
			component: <PLVerifyOtp />,
		},
		{
			step: 'step2',
			component: <PLPersonalInfoForm />,
		},
		{
			step: 'step3',
			component: <PLDocUploadForm />,
		},
		{
			step: 'step4',
			component: <PLReferenceOne />,
		},
		{
			step: 'step5',
			component: <PLReferenceTwo />,
		},
	];

	const stepsHeader: ComponentType = (
		<Header
			name={'TATA Capital Specific Form'}
			backButton={true}
			needArrow={true}
			needCross={false}
			needArrowOnClick={() => headerNeedArrowOnClick()}
		/>
	);

	return (
		<>
			<LeadsFlowGenerator
				currentStep={lenderSpecificDetails?.step}
				stepsHeader={stepsHeader}
				displayStepsCounter={lenderSpecificDetails?.subHeaderStepsCounter}
				stepsComponents={
					leadFlowData?.selectedProductData?.product_category_id == 1
						? stepsComponentsPL
						: leadFlowData?.selectedProductData?.product_category_id == 2
						? stepsComponentsBL
						: stepsComponentsHLLAP
				}
			/>
		</>
	);
};
