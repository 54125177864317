/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	IonContent,
	IonCardHeader,
	IonCardTitle,
	IonCardSubtitle,
	IonPage,
	IonCard,
	IonChip,
	IonCardContent,
} from '@ionic/react';
import { Header } from '../../components/layout/header/Header';
import './MyBusiness.scss';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import { InitialLetter } from '../../components/common/initial-letter/InitialLetter';

export const EarningDetails: React.FC = () => {
	const { t } = useTranslation();
	return (
		<>
			<IonPage>
				<Header
					name=''
					backButton={true}
					needArrow={true}
				/>
				<IonContent id='lead-detail'>
					<IonCard className='m-0 details bg-white'>
						<div className='imgCard'>
							<InitialLetter
								value={'S'}
								height='72px'
								width='72px'
								fontSize='44px'
							/>
							<div className='textCard'>
								<IonCardTitle className='cardId'>
									<span>#L98701</span> <span>#A98701</span>
								</IonCardTitle>
								<IonCardSubtitle className='EarningTitle'>
									Suresh Sriram Sharma
								</IonCardSubtitle>
								<CustomButton
									fill='clear'
									className='moreInfo'
								>
									<img
										src='/images/icons/teams/info.svg'
										alt='info'
										className='me-2'
									/>
									{t('See Detail Profile')}
								</CustomButton>
							</div>
						</div>
						<IonCardHeader>
							<div className=''>
								<div className='Info'>
									<span className='Dflex'>
										<img
											className='m-right'
											src='/images/icons/homeloan.svg'
											alt=''
										/>
										Home Loan<div className='Divider'></div> Buy ready to occupy
										home
									</span>
								</div>
								<div className='Info'>
									<span>
										<img
											className='m-right'
											src='images\icons\teams\location.svg'
											alt=''
										/>
										Mumbai
									</span>
								</div>
								<div className='Info'>
									<span>
										<svg
											className='m-right'
											xmlns='http://www.w3.org/2000/svg'
											width='16'
											height='16'
											viewBox='0 0 16 16'
										>
											<g
												id='Bank_icon'
												data-name='Bank icon'
												transform='translate(-260 -47)'
											>
												<path
													id='noun-bank-1698442'
													d='M130.62,75a.358.358,0,0,0,.358-.358V73.93a.343.343,0,0,0-.193-.315l-6.8-3.578a.337.337,0,0,0-.329,0l-6.8,3.578a.343.343,0,0,0-.193.315v.716a.358.358,0,0,0,.358.358h1.789v6.441h-1.074a.358.358,0,0,0-.358.358v1.431a.358.358,0,0,0,.358.358H129.9a.358.358,0,0,0,.358-.358V81.8a.358.358,0,0,0-.358-.358h-1.074V75Zm-9.661,0h1.789v6.441h-1.789Zm5.725,6.441h-1.789V75h1.789Z'
													transform='translate(144.335 -21.993)'
													fill='#0165e1'
												/>
												<rect
													id='Rectangle_28864'
													data-name='Rectangle 28864'
													width='16'
													height='16'
													transform='translate(260 47)'
													fill='rgba(255,255,255,0)'
												/>
											</g>
										</svg>
										State Bank Of India
									</span>
								</div>
								<div className='Info'>
									<span>
										<svg
											className='m-right'
											xmlns='http://www.w3.org/2000/svg'
											width='16'
											height='16'
											viewBox='0 0 16 16'
										>
											<g
												id='Iconly_Bold_Document'
												data-name='Iconly/Bold/Document'
												transform='translate(-1 -1)'
											>
												<g
													id='Document'
													transform='translate(3 2)'
												>
													<path
														id='Path_229954'
														data-name='Path 229954'
														d='M8.99,0a2.986,2.986,0,0,1,3.278,3.292h0v7.04a2.976,2.976,0,0,1-3.278,3.3H3.278A2.991,2.991,0,0,1,0,10.332H0V3.292A3,3,0,0,1,3.278,0H8.99ZM3.462,9.364a.535.535,0,0,0-.511.252.542.542,0,0,0,0,.572.529.529,0,0,0,.511.245H8.806a.539.539,0,0,0,0-1.07H3.462ZM8.806,6.256H3.462a.532.532,0,0,0,0,1.064H8.806a.532.532,0,0,0,0-1.064ZM5.5,3.169H3.462v.007a.532.532,0,1,0,0,1.063H5.5a.535.535,0,0,0,0-1.07Z'
														fill='#5d5e72'
													/>
												</g>
												<rect
													id='Rectangle_28642'
													data-name='Rectangle 28642'
													width='16'
													height='16'
													transform='translate(1 1)'
													fill='rgba(255,255,255,0)'
												/>
											</g>
										</svg>
										Bank App No <b>A123345564</b>
									</span>
								</div>
								<div className='m-bottom'>
									<span>
										<svg
											className='m-right'
											xmlns='http://www.w3.org/2000/svg'
											width='16'
											height='16'
											viewBox='0 0 16 16'
										>
											<g
												id='Iconly_Bold_Document'
												data-name='Iconly/Bold/Document'
												transform='translate(-1 -1)'
											>
												<g
													id='Document'
													transform='translate(3 2)'
												>
													<path
														id='Path_229954'
														data-name='Path 229954'
														d='M8.99,0a2.986,2.986,0,0,1,3.278,3.292h0v7.04a2.976,2.976,0,0,1-3.278,3.3H3.278A2.991,2.991,0,0,1,0,10.332H0V3.292A3,3,0,0,1,3.278,0H8.99ZM3.462,9.364a.535.535,0,0,0-.511.252.542.542,0,0,0,0,.572.529.529,0,0,0,.511.245H8.806a.539.539,0,0,0,0-1.07H3.462ZM8.806,6.256H3.462a.532.532,0,0,0,0,1.064H8.806a.532.532,0,0,0,0-1.064ZM5.5,3.169H3.462v.007a.532.532,0,1,0,0,1.063H5.5a.535.535,0,0,0,0-1.07Z'
														fill='#5d5e72'
													/>
												</g>
												<rect
													id='Rectangle_28642'
													data-name='Rectangle 28642'
													width='16'
													height='16'
													transform='translate(1 1)'
													fill='rgba(255,255,255,0)'
												/>
											</g>
										</svg>
										LAN <b>A123345564676776</b>
									</span>
								</div>
								<div className='Info'>
									<span>Loan Amount </span>{' '}
									<span className='ColorGreen'> ₹</span>
									<span className='FontTimeline'> 1,50,00,000</span>
								</div>
								<div className='Info'>
									<span>{t('Last Disbursal on')} 10 July 2023</span>
								</div>
								<div className='d-flex gap-3'>
									<CustomButton
										href={'tel:+91'}
										className='flex-grow-1 py-1 fs-16 dma-btn'
									>
										<img
											src='/images/icons/teams/call.svg'
											alt='call'
											className='me-2'
										/>
										{t('Call')}
									</CustomButton>
									<CustomButton
										href={'https://api.whatsapp.com/send?phone='}
										className='flex-grow-1  py-1 fs-16 dma-btn'
									>
										<img
											src='/images/icons/leads/whatsapp.svg'
											alt='call'
											className='me-1'
										/>
										{t('WhatsApp')}
									</CustomButton>
									<CustomButton className='flex-grow-1 py-1 fw-bold fs-16 dma-btn'>
										<img
											src='/images/icons/more-icon.svg'
											alt='more'
										/>
									</CustomButton>
								</div>
							</div>
						</IonCardHeader>
						<div className='cardDivider'></div>

						<div className='chips'>
							<IonChip
								className='active-chip'
								style={{ color: '#fff' }}
							>
								{t('Earnings')}
							</IonChip>
							<IonChip outline={true}>{t('Disbursal')}</IonChip>
						</div>
						<IonCardHeader class='PaddingAround'>
							<IonCardContent className='AmountCard'>
								<div>
									<span style={{ marginLeft: '5px' }}>
										{t('Sanctioned Amount')}
									</span>
								</div>
								<div
									style={{
										fontSize: '16px',
										fontWeight: 'bold',
										color: '#000',
									}}
								>
									₹ 15000000
								</div>
							</IonCardContent>
						</IonCardHeader>
						<div className='TimelineHeading'>{t('Timeline')}</div>
						<div className='TimeLine'>
							<div className='TimelinePayout'>
								<div className='TimeLineContent'>
									<div className='FontTimeline'>Payout3</div>
									<div>20 July 2023 at 5:56PM</div>
								</div>
								<span className='FontTimeline'>₹ 50,000</span>
							</div>
							<div className='TimelinePayout'>
								<div className='TimeLineContent'>
									<div className='FontTimeline'>Payout3</div>
									<div>20 July 2023 at 5:56PM</div>
								</div>
								<span className='FontTimeline'>₹ 50,000</span>
							</div>
						</div>
					</IonCard>
				</IonContent>
			</IonPage>
		</>
	);
};
