import { IonContent, IonItem, IonPage } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Header } from '../../../components/layout/header/Header';
import { FormatDate } from '../../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { userVerificationDetail } from '../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { Divider } from '../../../components/common/divider/Divider';

export const DmaApprovedAbout: React.FC = () => {
	const { t } = useTranslation();
	const [userDetails, setUserDetails] = useState<any>([]);
	const dispatch = useDispatch();
	const userData = useSelector((state: any) => state?.users);

	const getUserDetailsInfo = async () => {
		await dispatch(
			userVerificationDetail({
				param: `${userData?.dmaFlow?.dmaId}/UserDetail`,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setUserDetails(response?.data);
				}
			});
	};

	useEffect(() => {
		getUserDetailsInfo();
	}, []);
	return (
		<IonPage>
			<Header
				needArrow={true}
				backButton={true}
				name={t('About')}
			/>
			<IonContent>
				<div className='bg-web h-100 py-md-3'>
					<div className='bg-web-white container-width-md px-md-3 border-md-radius-10 h-100 overflow-auto-md'>
						{/* <div> */}
						<IonItem
							className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
							lines='full'
						>
							<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
								<p className='mb-0 fs-14'>{t('Mobile')}</p>
								<p className='mb-0 fs-14 fw-600'>
									{userDetails?.basicInfo?.mobile
										? userDetails?.basicInfo?.mobile
										: '-'}
								</p>
							</div>
						</IonItem>
						<IonItem
							className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
							lines='full'
						>
							<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
								<p className='mb-0 fs-14'>{t('DMA Type')}</p>
								<p className='mb-0 fs-14 fw-600'>
									{userData?.dmaFlow?.dmaType
										? userData?.dmaFlow?.dmaType
										: '-'}
								</p>
							</div>
						</IonItem>
						<IonItem
							className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
							lines='none'
						>
							<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
								<p className='mb-0 fs-14'>{t('Created Date')}</p>
								<p className='mb-0 fs-14 fw-600'>
									{userDetails?.basicInfo?.createdAt
										? FormatDate(
												userDetails?.basicInfo?.createdAt,
												'dd mmmm yyyy'
										  )
										: '-'}
								</p>
							</div>
						</IonItem>
						{/* </div> */}
						<Divider />
						<div className='basic-info'>
							<div className='px-3 mt-4'>
								<h4 className='fw-600 fs-16'>{t('Basic Info')}</h4>
							</div>
							<IonItem
								className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
									<p className='mb-0 fs-14'>{t('Alternate Mobile')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{userDetails?.basicInfo?.alternate_mobile
											? userDetails?.basicInfo?.alternate_mobile
											: '-'}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
									<p className='mb-0 fs-14'>{t('Email')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{userDetails?.basicInfo?.email
											? userDetails?.basicInfo?.email
											: '-'}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
									<p className='mb-0 fs-14'>{t('City')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{userDetails?.basicInfo?.city
											? userDetails?.basicInfo?.city
											: '-'}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
									<p className='mb-0 fs-14'>{t('State')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{userDetails?.basicInfo?.state
											? userDetails?.basicInfo?.state
											: '-'}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
								lines='none'
							>
								<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
									<p className='mb-0 fs-14'>{t('Pincode')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{userDetails?.basicInfo?.pin
											? userDetails?.basicInfo?.pin
											: '-'}
									</p>
								</div>
							</IonItem>
						</div>
						<Divider />
						<div className=''>
							<div className='px-3 mt-4'>
								<h4 className='fw-600 fs-16'>{t('Professional Info')}</h4>
							</div>
							<IonItem
								className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
									<p className='mb-0 fs-14'>{t('Registered As')}</p>
									<p className='mb-0 fs-14 fw-600 text-capitalize'>
										{userDetails?.basicInfo?.entityType
											? userDetails?.basicInfo?.entityType
											: '-'}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
									<p className='mb-0 fs-14'>{t('Current Business')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{userDetails?.addressProof?.CurrentBussines
											? userDetails?.addressProof?.CurrentBussines
											: '-'}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-block'>
									<p className='mt-3 mb-2 fs-14'>
										{t('Current Business Address')}
									</p>
									<p className='mt-2 mb-3 fs-14 fw-600'>
										{userDetails?.addressProof?.OfficeAddress
											? userDetails?.addressProof?.OfficeAddress
											: '-'}
									</p>
								</div>
							</IonItem>
							{userDetails?.addressProof?.CurrentBussines === 'Finance' && (
								<>
									<IonItem
										className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
										lines='full'
									>
										<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
											<p className='mb-0 fs-14'>{t('Associated with DSA')}</p>
											<p className='mb-0 fs-14 fw-600'>
												{userDetails?.addressProof?.DSAAssociation === '1'
													? 'Yes'
													: 'No'}
											</p>
										</div>
									</IonItem>
									{userDetails?.addressProof?.DSAAssociation === '1' && (
										<>
											<IonItem
												className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
												lines='full'
											>
												<div
													className={
														userDetails?.addressProof?.Product?.length > 2
															? 'd-block py-2'
															: 'd-flex flex-grow-1 align-items-center justify-content-between'
													}
												>
													<p className='mb-0 fs-14'>{t('Products')}</p>
													<p className='mb-0 fs-14 fw-600'>
														{userDetails?.addressProof?.Product?.length
															? userDetails?.addressProof?.Product?.join(', ')
															: '-'}
													</p>
												</div>
											</IonItem>
											<IonItem
												className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
												lines='full'
											>
												<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
													<p className='mb-0 fs-14'>
														{t('Monthly Business Volume')}
													</p>
													<p className='mb-0 fs-14 fw-600 text-nowrap'>
														{userDetails?.addressProof?.Income
															? '₹ ' + userDetails?.addressProof?.Income
															: '-'}
													</p>
												</div>
											</IonItem>
										</>
									)}
								</>
							)}
							{(userDetails?.addressProof?.CurrentBussines?.toLowerCase() ===
								'finance' &&
								userDetails?.addressProof?.DSAAssociation === '0') ||
							userDetails?.addressProof?.CurrentBussines?.toLowerCase() ===
								'non-finance' ? (
								<IonItem
									className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
									lines='full'
								>
									<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
										<p className='mb-0 fs-14'>{t('Nature of Business')}</p>
										<p className='mb-0 fs-14 fw-600'>
											{userDetails?.addressProof?.BusinessNature
												? userDetails?.addressProof?.BusinessNature
												: '-'}
										</p>
									</div>
								</IonItem>
							) : (
								''
							)}
							{userDetails?.addressProof?.CurrentBussines === 'Non-Finance' && (
								<IonItem
									className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
									lines='full'
								>
									<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
										<p className='mb-0 fs-14'>{t('Profession')}</p>
										<p className='mb-0 fs-14 fw-600'>
											{userDetails?.addressProof?.Profession
												? userDetails?.addressProof?.Profession
												: '-'}
										</p>
									</div>
								</IonItem>
							)}
							<IonItem
								className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
									<p className='mb-0 fs-14'>{t('Reference 1 Name')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{userDetails?.addressProof?.ReferenceName1
											? userDetails?.addressProof?.ReferenceName1
											: '-'}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
									<p className='mb-0 fs-14'>{t('Reference 1 Mobile No.')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{userDetails?.addressProof?.ReferenceMobile1
											? userDetails?.addressProof?.ReferenceMobile1
											: '-'}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
									<p className='mb-0 fs-14'>{t('Reference 2 Name')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{userDetails?.addressProof?.ReferenceName2
											? userDetails?.addressProof?.ReferenceName2
											: '-'}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
								lines='none'
							>
								<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
									<p className='mb-0 fs-14'>{t('Reference 2 Mobile No.')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{userDetails?.addressProof?.ReferenceMobile2
											? userDetails?.addressProof?.ReferenceMobile2
											: '-'}
									</p>
								</div>
							</IonItem>
						</div>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};
