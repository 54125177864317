import { IonItem, isPlatform } from '@ionic/react';
import dateFormat from 'dateformat';

import { CustomButton } from '../components/common/custom-button/CustomButton';
import {
	INAPPCONFIG,
	LeadSourceEnum,
	Lead_name,
	// LeadStatus,
	REGEX,
	StatusNum,
	destinationFields,
} from './constant';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { LEAD_STATUS } from './constant';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { resetAppState } from '../store/reducers/app/appSlice';
import {
	appLogout,
	contributionPinCode,
	downloadAgreement,
	resetUserState,
} from '../store/reducers/user/userSlice';
import { resetLeadState } from '../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { persistor } from '../store/store';
import { Share } from '@capacitor/share';
import { LocalNotifications } from '@capacitor/local-notifications';
import { NotifyVisitors } from 'capacitor-plugin-notifyvisitors';

export const ShareData = (title, text, url, file) => {
	if (isPlatform('android') || isPlatform('ios')) {
		Share.share({
			title: title,
			url: url,
			text: text,
			files: file,
		});
	} else {
		if (navigator.share) {
			navigator
				.share({
					title: title,
					url: url,
					text: text,
				})
				.then(() => {
					// console.log('Successful share');
				})
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				.catch((error) => {
					// console.log('Error sharing', error);
				});
		}
	}
};

export const FormatDate = (text, format) => {
	if (text) {
		return dateFormat(text, format);
	}
};
export function dataURLtoFile(dataurl, filename) {
	// only works for dataURL, not for other types of url.
	const arr = dataurl.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[arr.length - 1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], filename, { type: mime });
}

export async function getBase64FromFileObject(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			resolve(reader.result);
		};
		reader.onerror = reject;
	});
}

export async function urltoFile(url, filename, mimeType) {
	// works for any type of url, (http url, dataURL, blobURL, etc...)
	// return a promise that resolves with a File instance
	if (url.startsWith('data:')) {
		var arr = url.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[arr.length - 1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		var file = new File([u8arr], filename, { type: mime || mimeType });
		return Promise.resolve(file);
	}
	return fetch(url)
		.then((res) => res?.arrayBuffer())
		.then((buf) => new File([buf], filename, { type: 'image/' + mimeType }));
}
//Usage example:
// urltoFile('data:text/plain;base64,aGVsbG8=', 'hello.txt', 'text/plain').then(
// 	function (file) {
// 	}
// );

export const nFormatter = (num) => {
	if (num >= 1000000000) {
		return (
			(num / 1000000000).toFixed(1).replace(/\.0$/, '') +
			"<span class='fs-10'> Cr</span>"
		);
	}
	if (num >= 100000) {
		return (
			(num / 100000).toFixed(1).replace(/\.0$/, '') +
			"<span class='fs-10'> L</span>"
		);
	}
	if (num >= 1000) {
		return (
			(num / 1000).toFixed(1).replace(/\.0$/, '') +
			"<span class='fs-10'> K</span>"
		);
	}
	return num;
};

export const PageExitPopUp = (
	handleClose,
	exitOnClick,
	cancelButtonTitle,
	confirmButtonTitle,
	className,
	header,
	subHeader
) => {
	const { t } = useTranslation();
	return (
		<div className={`wrapper py-4 ${className}`}>
			<IonItem lines='none'>
				<div className='d-flex flex-column'>
					<h5
						className='mx-0 fs-18 fw-900'
						dangerouslySetInnerHTML={{ __html: t(header) }}
					/>
					<p
						className='fs-14 mb-2'
						style={{ color: 'var(--text-dark-grey)' }}
					>
						{t(subHeader)}
					</p>
				</div>
			</IonItem>
			<div className='d-flex justify-content-center gap-3 px-3'>
				<CustomButton
					onClick={handleClose}
					className='w-50'
					fill='outline'
					title={t(cancelButtonTitle)}
				/>
				<CustomButton
					className='w-50'
					fill='solid'
					onClick={exitOnClick}
					title={t(confirmButtonTitle)}
				/>
			</div>
		</div>
	);
};
export const ContinueLaterPopUp = (
	handleClose,
	exitOnClick,
	cancelButtonTitle,
	confirmButtonTitle,
	className,
	header,
	subHeader
) => {
	const { t } = useTranslation();
	return (
		<div className={`wrapper py-4 ${className}`}>
			<IonItem lines='none'>
				<div className='d-flex flex-column'>
					<h5
						className='mx-0 fs-18 fw-900'
						dangerouslySetInnerHTML={{ __html: t(header) }}
					/>
					<p
						className='fs-14 mb-2'
						style={{ color: 'var(--text-dark-grey)' }}
					>
						{t(subHeader)}
					</p>
				</div>
			</IonItem>
			<div className='d-flex justify-content-center gap-3 px-3 mt-2'>
				<CustomButton
					onClick={handleClose}
					className='w-50'
					fill='outline'
					title={t(cancelButtonTitle)}
				/>
				<CustomButton
					className='w-50'
					fill='solid'
					onClick={exitOnClick}
					title={t(confirmButtonTitle)}
				/>
			</div>
		</div>
	);
};

export const paplPopUp = (
	handleClose,
	exitOnClick,
	cancelButtonTitle,
	confirmButtonTitle,
	className,
	header,
	subHeader1,
	subHeader2,
	subHeader3,
	subHeader4,
	disable1,
	disable2
) => {
	const { t } = useTranslation();
	return (
		<div className={`wrapper py-4 ${className}`}>
			<IonItem lines='none'>
				<div className='d-flex flex-column justify-content-center'>
					<h5
						className='mx-0 fs-18 fw-900'
						dangerouslySetInnerHTML={{ __html: t(header) }}
					/>
					<p
						className='fs-14 mb-2'
						style={{ color: 'var(--text-dark-grey)' }}
					>
						{t(subHeader1)}
					</p>
					<p
						className='fs-14 mb-2'
						style={{ color: 'var(--text-dark-grey)' }}
					>
						{t(subHeader2)}
					</p>
					<p
						className='fs-14 mb-2'
						style={{ color: 'var(--text-dark-grey)' }}
					>
						{t(subHeader3)}
					</p>
					<h5>{t(subHeader4)}</h5>
				</div>
			</IonItem>
			<div className='d-flex justify-content-center gap-3 px-3 mt-2'>
				<CustomButton
					onClick={handleClose}
					className='w-50'
					fill='outline'
					title={t(cancelButtonTitle)}
					disabled={disable1}
				/>
				<CustomButton
					className='w-50'
					fill='solid'
					onClick={exitOnClick}
					title={t(confirmButtonTitle)}
					disabled={disable2}
				/>
			</div>
		</div>
	);
};
export const AllocatePopUp = (
	androManager,
	fullFill,
	from,
	needText = true
) => {
	const { t } = useTranslation();
	return (
		<div className='p-3'>
			{needText ? (
				<p className='fs-20 fw-bold m-0 p-2'>
					Do you want to allocate this lead to Andro Manager or fulfill loan
					application journey?
				</p>
			) : (
				''
			)}
			{from !== 'customer' ? (
				<CustomButton
					expand='block'
					className='btn-primary-outline mb-3'
					onClick={androManager}
					title={t('Allocate to Andro Manager')}
				/>
			) : (
				''
			)}
			<CustomButton
				expand='block'
				className='btn-primary-large'
				onClick={fullFill}
				title={t('Fulfill Application Myself')}
			/>
		</div>
	);
};

export const InputFormatCurrencyValue = (value) => {
	return value?.toLocaleString('en-IN');
};

export const DownloadFileBlob = (
	fileData,
	fileName,
	fileExtensions = 'xlsx',
	isURl
) => {
	var a = document.createElement('a'); //Create <a>

	if (isURl) {
		a.href = fileData;
		a.download = `${fileName + '_' + new Date().getTime()}.${fileExtensions}`;
	} else {
		a.href = 'data:application/;base64,' + fileData; //Base64 Goes here
		a.download = `${fileName + '_' + new Date().getTime()}.${fileExtensions}`; //File name Here
	}

	a.click(); //Downloaded file
	showToastAlert({
		type: 'success',
		message: 'Download Successful',
		autoClose: 3000,
		isDismiss: false,
	});
};

export const capFirstLetter = (str) => {
	return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
		return $1.toUpperCase();
	});
};

export const ionContentScrollToTop = (contentRef) => {
	contentRef.current && contentRef.current.scrollToTop();
};

export const dataFinder = (data, type) => {
	let regex;
	if (type === 'aadhaar') {
		regex = new RegExp(/^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/);
	} else if (type === 'pan') {
		regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
	} else if (type === 'cheque') {
		regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
	}

	let getExpectedData;
	data?.map((i) => {
		if (regex.test(i)) {
			getExpectedData = i;
		}
	});
	return getExpectedData;
};

export const panNumber = (value) => REGEX.PAN.test(value);

export const getEncrypted = (str) => {
	return btoa(str);
};

export const getDecrypted = (str) => {
	return atob(str);
};

export const alphabetColor = (char) => {
	let _char = '#0165E1';
	switch (char) {
		case 1:
			_char = '#66CC66';
			break;
		case 2:
			_char = '#0165E1';
			break;
		case 3:
			_char = '#EC0A0A';
			break;
		case 4:
			_char = '#FFA403';
			break;
		case 5:
			_char = '#0AC1EC';
			break;
		default:
			_char = '#0165E1';
	}
	return _char;
};

// export const findStatus = (id) => {
// 	let status = '';
// 	switch (id) {
// 		case LEAD_STATUS.FULFILLMENT:
// 			status = 'Application Fulfillment';
// 			break;
// 		case LEAD_STATUS.PROCESSING:
// 			status = 'Leads Processing';
// 			break;
// 		case LEAD_STATUS.SELECTION:
// 			status = 'Lender Selection';
// 			break;
// 		case LEAD_STATUS.LOGIN:
// 			status = 'Login';
// 			break;
// 		case LeadStatus.LOGIN:
// 			status = 'Login';
// 			break;
// 		case LEAD_STATUS.SANCTIONED:
// 			status = 'Sanctioned';
// 			break;
// 		case LeadStatus.SANCTIONED:
// 			status = 'Sanctioned';
// 			break;
// 		case LeadStatus.PARTIALDISBURSED:
// 			status = 'Partially Disbursed';
// 			break;
// 		case LEAD_STATUS.DISBURSED:
// 			status = 'Disbursed';
// 			break;
// 		case LeadStatus.DISBURSED:
// 			status = 'Disbursed';
// 			break;
// 		case LeadStatus.CALLBACKUPDATESTAGE:
// 		case LeadStatus.CLOSED:
// 			status = 'Closed';
// 			break;
// 		case LEAD_STATUS.UNDERPROCESS:
// 			status = 'Under Process';
// 			break;
// 		case LEAD_STATUS.ALL:
// 			status = 'Processing';
// 			break;
// 		case LeadStatus.GENERATED:
// 			status = 'Leads Generated';
// 			break;
// 		case LeadStatus.BUREAU:
// 			status = 'Credit Report';
// 			break;
// 		case LeadStatus.PROCESSING:
// 			status = 'Processing';
// 			break;
// 		case LeadStatus.SELECTION:
// 			status = 'Lender Selection';
// 			break;
// 		case LeadStatus.APPLICATIONSFULFILLED:
// 			status = 'Applications Fulfilled';
// 			break;
// 		case LeadStatus.BASICDETAILSOFLIFEASSURED:
// 			status = 'Basic Details Of Lifeassured';
// 			break;
// 		case LeadStatus.OCCUPATIONDETAILSOFASSURED:
// 			status = 'Occupation Details Of Assured';
// 			break;
// 		case LeadStatus.DOCUMENTUPLOADED:
// 			status = 'Document Uploaded';
// 			break;
// 		case LeadStatus.MOBILEVERIFICATION:
// 			status = 'Mobile Verification';
// 			break;
// 		case LeadStatus.PROPOSERDETAIL:
// 			status = 'Proposer Detail';
// 			break;
// 		case LeadStatus.UNDERPROCESS:
// 			status = 'Under Process';
// 			break;
// 		case LeadStatus.RAW:
// 			status = 'Raw Leads';
// 			break;
// 		default:
// 			status = '';
// 	}
// 	return status;
// };

// export const getStatus = (id) => {
// 	let _status = '';
// 	switch (id) {
// 		case LeadStatus.SANCTIONED:
// 			_status = 'Sanctioned';
// 			break;
// 		case LeadStatus.DISBURSED:
// 			_status = 'Disbursed';
// 			break;
// 		case LeadStatus.PARTIALDISBURSED:
// 			_status = 'Partially Disbursed';
// 			break;
// 		case LeadStatus.LOGIN:
// 			_status = 'Login';
// 			break;
// 		case LeadStatus.CLOSED:
// 			_status = 'Closed';
// 			break;
// 		case LeadStatus.APPLICATIONSFULFILLED:
// 			_status = 'Fulfillment';
// 			break;
// 		case LeadStatus.GENERATED:
// 			_status = 'Lead Generated';
// 			break;
// 		default:
// 			_status = '';
// 	}
// 	return _status;
// };

export const findCurrentStatus = (status) => {
	let _status = { id: '', label: '' };
	switch (status) {
		case LEAD_STATUS.DISBURSED:
		case LEAD_STATUS.DISBURSED_LABEL:
			_status = {
				id: LEAD_STATUS.DISBURSED,
				label: LEAD_STATUS.DISBURSED_LABEL,
			};
			break;
		case LEAD_STATUS.SANCTIONED:
		case LEAD_STATUS.SANCTIONED_LABEL:
			_status = {
				id: LEAD_STATUS.SANCTIONED,
				label: LEAD_STATUS.SANCTIONED_LABEL,
			};
			break;
		case LEAD_STATUS.CLOSED:
		case LEAD_STATUS.CLOSED_LABEL:
			_status = { id: LEAD_STATUS.CLOSED, label: LEAD_STATUS.CLOSED_LABEL };
			break;
		case LEAD_STATUS.APPLICATION_CLOSED:
		case LEAD_STATUS.APPLICATION_CLOSED_LABEL:
			_status = {
				id: LEAD_STATUS.APPLICATION_CLOSED,
				label: LEAD_STATUS.APPLICATION_CLOSED_LABEL,
			};
			break;
		case LEAD_STATUS.SELECTION:
		case LEAD_STATUS.SELECTION_LABEL:
			_status = {
				id: LEAD_STATUS.SELECTION,
				label: LEAD_STATUS.SELECTION_LABEL,
			};
			break;
		case LEAD_STATUS.LOGIN:
		case LEAD_STATUS.LOGIN_LABEL:
			_status = { id: LEAD_STATUS.LOGIN, label: LEAD_STATUS.LOGIN_LABEL };
			break;
		case LEAD_STATUS.BUREAU:
		case LEAD_STATUS.BUREAU_LABEL:
			_status = { id: LEAD_STATUS.BUREAU, label: LEAD_STATUS.BUREAU_LABEL };
			break;
		case LEAD_STATUS.GENERATED:
		case LEAD_STATUS.GENERATED_LABEL:
			_status = {
				id: LEAD_STATUS.GENERATED,
				label: LEAD_STATUS.GENERATED_LABEL,
			};
			break;
		case LEAD_STATUS.GENERATION:
			_status = { label: LEAD_STATUS.GENERATION };
			break;
		case LEAD_STATUS.PROCESSING:
		case LEAD_STATUS.PROCESSING_LABEL:
			_status = {
				id: LEAD_STATUS.PROCESSING,
				label: LEAD_STATUS.PROCESSING_LABEL,
			};
			break;
		case LEAD_STATUS.UNDERPROCESS:
		case LEAD_STATUS.UNDERPROCESS_LABEL:
			_status = {
				id: LEAD_STATUS.UNDERPROCESS,
				label: LEAD_STATUS.UNDERPROCESS_LABEL,
			};
			break;
		case LEAD_STATUS.FULFILLMENT:
		case LEAD_STATUS.FULFILLMENT_LABEL:
			_status = {
				id: LEAD_STATUS.FULFILLMENT,
				label: LEAD_STATUS.FULFILLMENT_LABEL,
			};
			break;
		case LEAD_STATUS.PARTIALDISBURSED:
		case LEAD_STATUS.PARTIALDISBURSED_LABEL:
			_status = {
				id: LEAD_STATUS.PARTIALDISBURSED,
				label: LEAD_STATUS.PARTIALDISBURSED_LABEL,
			};
			break;
		case LEAD_STATUS.RAW:
		case LEAD_STATUS.RAW_LABEL:
			_status = { id: LEAD_STATUS.RAW, label: LEAD_STATUS.RAW_LABEL };
			break;
		case LEAD_STATUS.ALL:
			_status = { id: LEAD_STATUS.ALL, label: LEAD_STATUS.ALL };
			break;
		case LEAD_STATUS.APPLICATION_FULFILLED:
			_status = {
				label: LEAD_STATUS.APPLICATION_FULFILLED,
			};
			break;
		case LEAD_STATUS.BASIC_DETAILS_OF_LIFE_ASSURED:
			_status = {
				label: LEAD_STATUS.BASIC_DETAILS_OF_LIFE_ASSURED,
			};
			break;
		case LEAD_STATUS.OCCUPATION_DETAILS_OF_ASSURED:
			_status = {
				label: LEAD_STATUS.OCCUPATION_DETAILS_OF_ASSURED,
			};
			break;
		case LEAD_STATUS.DOCUMENTUPLOADED_LABEL:
			_status = {
				label: LEAD_STATUS.DOCUMENTUPLOADED_LABEL,
			};
			break;
		case LEAD_STATUS.MOBILEVERIFICATION_LABEL:
			_status = {
				label: LEAD_STATUS.MOBILEVERIFICATION_LABEL,
			};
			break;
		case LEAD_STATUS.PROPOSERDETAIL_LABEL:
			_status = {
				label: LEAD_STATUS.PROPOSERDETAIL_LABEL,
			};
			break;
		case LEAD_STATUS.RAW_LEAD_LABEL:
			_status = {
				label: LEAD_STATUS.RAW_LEAD_LABEL,
			};
			break;
		case LEAD_STATUS.ALLOCATOR:
		case LEAD_STATUS.ALLOCATOR_LABEL:
			_status = {
				id: LEAD_STATUS.ALLOCATOR,
				label: LEAD_STATUS.ALLOCATOR_LABEL,
			};
			break;
		case LEAD_STATUS.CREDIT_BUREAU:
		case LEAD_STATUS.CREDIT_BUREAU_LABEL:
			_status = {
				id: LEAD_STATUS.CREDIT_BUREAU,
				label: LEAD_STATUS.CREDIT_BUREAU_LABEL,
			};
			break;
		default:
			_status = { id: '', label: '' };
	}
	return _status;
};
export const findSource = (id) => {
	let source = '';
	switch (id) {
		case LeadSourceEnum.MANUAL:
			source = 'Created';
			break;
		case Lead_name.LEAD_NAME:
			source = 'Created By:';
			break;
		case LeadSourceEnum.CAMPAIGN:
			source = 'Campaign';
			break;
		case LeadSourceEnum.BULK:
			source = 'Bulk';
			break;
		case StatusNum.VERIFICATION:
			source = 'Verification Pending';
			break;
		case StatusNum.VERIFICATION1:
			source = 'Verification Pending';
			break;
		case StatusNum.VERIFICATION2:
			source = 'Verification Pending';
			break;
		case StatusNum.REWORKCOMPLETED:
			source = 'Re-Verification Pending';
			break;
		case StatusNum.REWORKVERIFICATION1:
			source = 'Re-Verification Pending';
			break;
		case StatusNum.REWORKVERIFICATION2:
			source = 'Re-Verification Pending';
			break;
		case StatusNum.REWORK:
			source = 'Send for Rework';
			break;
		case StatusNum.REJECTED:
			source = 'Rejected';
			break;
		default:
			source = id;
	}
	return source;
};

export const findDestination = (destination) => {
	let source = '';
	switch (destination) {
		case destinationFields.Statement:
			source = 'statement';
			break;
		case destinationFields.Aggregator:
			source = 'Aggregator';
			break;
		case destinationFields.GST:
			source = 'gst';
			break;
		default:
			source = 'netbankingFetch';
	}
	return source;
};
export const takePicture = async (docType, regType = '') => {
	const image = await Camera.getPhoto({
		quality: 90,
		allowEditing: false,
		resultType: CameraResultType.Uri,
		source: CameraSource.Camera,
	});

	let finalData;
	const imageUrl = image.webPath;
	const ocrData = await performOCR(image.path);

	let processedData;

	// Can be set to the src of an image now
	switch (docType) {
		case 'imageWithData':
			processedData = dataFinder(ocrData.lines.linetext, regType);
			processedData = processedData ? processedData : 'NOT FOUND';
			finalData = { image: imageUrl, processedData: processedData };
			break;
		case 'image':
			finalData = { image: imageUrl };
			break;
		default:
			finalData = {};
			break;
	}
	return finalData;
};

export const getPictureDataByOcr = async (
	docType,
	path,
	webPath,
	regType = ''
) => {
	let finalData;
	const ocrData = await performOCR(path);
	let processedData;
	switch (docType) {
		case 'imageWithData':
			processedData = dataFinder(ocrData?.lines?.linetext, regType);
			processedData = processedData ? processedData : 'NOT FOUND';
			finalData = {
				image: webPath,
				processedData: processedData.replaceAll(' ', ''),
			};
			break;
		default:
			finalData = {};
			break;
	}
	return finalData;
};

const performOCR = (imgData) => {
	return new Promise((resolve, reject) => {
		// document.addEventListener("deviceready", () => {
		const ocrOptions = { imgType: 0, imgSrc: imgData };

		function onSuccess(recognizedText) {
			resolve(recognizedText);
		}

		function onFail(message) {
			reject(message);
		}

		if (window && window.mltext && window.mltext.getText) {
			window?.mltext?.getText(onSuccess, onFail, ocrOptions);
		} else {
			onSuccess({ processedData: 'not found' });
		}
		// }, false);
	});
};

export const getFilenameByUrl = (url) => {
	let fileName = '';
	if (!url) {
		fileName = '';
	} else if (url.includes('/tmp/')) {
		fileName = decodeURIComponent(new URL(url).pathname.split('/tmp/')[1]);
	} else if (url.includes('Document/')) {
		fileName = url.split('Document/')[1].split('?')[0];
	}
	return fileName;
};

export const getFileExtByUrl = (url) => {
	let fileExt = '';
	if (!url) {
		fileExt = '';
	} else if (url.includes('/tmp/')) {
		fileExt = decodeURIComponent(new URL(url).pathname.split('/tmp/')[1])
			.split('.')
			.pop();
	} else if (url.includes('Document/')) {
		fileExt = url.split('Document/')[1].split('.').pop();
	}
	return fileExt;
};

export const converRuppesFormat = (num) =>
	new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(
		parseInt(num)
	);

export const getFileWithUpdatedToken = ({
	dispatch,
	Url = '',
	imgUrlUpdate,
	unwrapResult,
	setUserFile,
	setName,
	userData,
	setUserData,
	setUserData2,
}) => {
	if (Url) {
		dispatch(
			imgUrlUpdate({
				data: {
					url: Url,
					expire_time: 50000,
				},
			})
		)
			.then(unwrapResult)
			.then((response) => {
				if (response?.status) {
					setUserFile && setUserFile(response?.url);
					userData &&
						setUserData &&
						setUserData({ ...userData, Url: response?.url });
					userData &&
						setUserData2 &&
						setUserData2({ ...userData, Url2: response?.url });
					setName && setName(getFilenameByUrl(response?.url));
				}
			});
	} else {
		return '';
	}
};
export const showToastAlert = ({
	type,
	message,
	autoClose = true,
	isDismiss = true,
	toastId = 1,
}) => {
	if (autoClose) {
		autoClose = 3000;
	}
	if (isDismiss) {
		toast.dismiss();
	}
	if (type === 'error') {
		toast.error(message, {
			toastId: toastId,
			position: 'bottom-center',
			autoClose: autoClose,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			// theme: "light",
			icon: false,
			className: 'toast-msg toast-error',
		});
	} else if (type === 'info') {
		toast.info(message, {
			toastId: toastId,
			position: 'bottom-center',
			autoClose: autoClose,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			// theme: "light",
			icon: false,
			className: 'toast-msg toast-info',
		});
	} else {
		toast.success(message, {
			toastId: toastId,
			position: 'bottom-center',
			autoClose: autoClose,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			// theme: "light",
			icon: false,
			className: 'toast-msg toast-success',
		});
	}
};
export const makeFirstLetterUppercase = (word) => {
	return word?.charAt(0).toUpperCase() + word?.slice(1);
};

export const debounceInput = (func, timeout) => {
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			func.apply(this, args);
		}, timeout);
	};
};

export const getUserAge = (date) => {
	const today = new Date();
	const userDate = new Date(date);
	let age = today.getFullYear() - userDate.getFullYear();
	const m = today.getMonth() - userDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < userDate.getDate())) {
		age--;
	}
	return age;
};

export const DownloadFileInApp = async (fileData, fileName, fileExtensions) => {
	const a = await Filesystem.writeFile({
		path: fileName + '_' + new Date().getTime() + '.' + fileExtensions,
		data: 'data:application/;base64,' + fileData,
		directory: Directory.Data,
		// encoding: Encoding.UTF8,
	});
	await LocalNotifications.schedule({
		notifications: [
			{
				title: 'File Downloaded',
				body: `Click To Open`,
				id: 1,
				schedule: { at: new Date(Date.now() + 1000 * 1) },
				actionTypeId: '',
				extra: {
					route: a?.uri,
				},
			},
		],
	});

	showToastAlert({
		type: 'success',
		message: 'Download Successful',
		autoClose: 3000,
		isDismiss: false,
	});
};

export const logOut = async (dispatch, history) => {
	await dispatch(resetAppState());
	await dispatch(resetUserState());
	await dispatch(resetLeadState());
	await dispatch(appLogout());
	await persistor.purge();
	await persistor.flush();
	// if possible we need to remove below code in future
	history?.replace('/login');
	window.location.href = '/login';
};

export const getDownloadAgreeMent = (dispatch) => {
	dispatch(downloadAgreement())
		.then(unwrapResult)
		.then((response) => {
			if (response?.status) {
				/*showToastAlert({ type: 'success', message: 'Download Initiated' });
				if (isPlatform('android') || isPlatform('ios')) {
					DownloadFileInApp(
						response?.presignedUrl,
						'OneAndro_agreement',
						'pdf'
					);
				} else {
					DownloadFileBlob(response?.presignedUrl, 'OneAndro_agreement', 'pdf');
				}*/
				showInAppBrowserView(response?.presignedUrl);
			}
		});
};

export const showInAppBrowserView = (url, urlTarget = '_self') => {
	if (url !== '' && typeof url === 'string') {
		if (!isPlatform('capacitor')) {
			urlTarget = '_blank';
		} else if (
			isPlatform('capacitor') &&
			getFileExtByUrl(url)?.toLowerCase().includes('pdf')
		) {
			urlTarget = '_system';
		}
		InAppBrowser.create(url, urlTarget, INAPPCONFIG);
	} else {
		console.warn('inappbrowser error url is not a valid string');
	}
};

export const fetchStateCityByPincode = async (
	setValue,
	cityName = 'city',
	stateName = 'state',
	setPinCodeData,
	dispatch,
	value,
	trigger,
	isMultiple = false,
	objKey
) => {
	{
		/*
			setValue, - to set/update city/state value
			cityName = 'city',
			stateName = 'state',
			setPinCodeData, setData to components state
			dispatch, 
			value, - pincode value
			trigger - trigger validations for yup to specific value
		*/
	}
	setValue(cityName, '');
	if (isMultiple && objKey != '') {
		setPinCodeData((pinCodeData) => {
			return {
				...pinCodeData,
				[objKey]: [],
			};
		});
	} else {
		setPinCodeData([]);
	}
	dispatch(contributionPinCode({ data: { pincode: value } }))
		.then(unwrapResult)
		.then((response) => {
			if (response?.status) {
				if (!response?.list) {
					setValue(stateName, '');
					setValue(cityName, '');
					showToastAlert({
						type: 'error',
						message: 'Please Check Pin Code No.',
					});
					if (isMultiple && objKey != '') {
						setPinCodeData((pinCodeData) => {
							return {
								...pinCodeData,
								[objKey]: [],
							};
						});
					} else {
						setPinCodeData([]);
					}
				} else {
					const cityList = [];
					const stateList = [];

					response?.list.map((item) => {
						!cityList.includes(item?.Region) && cityList.push(item?.Region);
						!stateList.includes(item?.State) && stateList.push(item?.State);
					});

					if (cityList.length == 1) {
						setValue(cityName, response?.list[0].Region);
					}
					if (stateList.length == 1) {
						setValue(stateName, response?.list[0].State);
					}
					if (isMultiple && objKey != '') {
						setPinCodeData((pinCodeData) => {
							return {
								...pinCodeData,
								[objKey]: response?.list,
							};
						});
					} else {
						setPinCodeData(response?.list);
					}
				}
				trigger(cityName);
			} else {
				const pincodeError = true;
				setPinCodeData({
					isPincodeError: pincodeError,
					pincodeErrorMsg: response?.message,
				});
			}
		});
};

export const setBankIcon = (bankName) => {
	const bN = bankName?.toLowerCase();
	let icon_path = '';
	if (bN?.includes('hdfc')) {
		icon_path = '/images/icons/bank/lender-hdfc-bank-icon.png';
	} else if (bN?.includes('axis')) {
		icon_path = '/images/icons/bank/lender-axis-bank-icon.png';
	} else if (bN?.includes('bajaj')) {
		icon_path = '/images/icons/bank/lender-bajaj-finance-icon.png';
	} else if (bN?.includes('icici')) {
		icon_path = '/images/icons/bank/lender-icici-bank-icon.png';
	} else if (bN?.includes('idfc')) {
		icon_path = '/images/icons/bank/lender-idfc-bank-icon.png';
	} else if (bN?.includes('aditya')) {
		icon_path = '/images/icons/bank/lender-aditya-birla-icon.png';
	} else if (bN?.includes('baroda')) {
		icon_path = '/images/icons/bank/lender-bank-of-baroda-icon.png';
	} else if (bN?.includes('chola')) {
		icon_path = '/images/icons/bank/lender-chola-icon.png';
	} else if (bN?.includes('deutsche')) {
		icon_path = '/images/icons/bank/lender-deutsche-bank-icon.png';
	} else if (bN?.includes('fullerton')) {
		icon_path = '/images/icons/bank/lender-fullerton-icon.png';
	} else if (bN?.includes('kotak')) {
		icon_path = '/images/icons/bank/lender-kotak-mahindra-bank-icon.png';
	} else if (bN?.includes('piramal')) {
		icon_path = '/images/icons/bank/lender-piramal-icon.png';
	} else if (bN?.includes('punjab')) {
		icon_path = '/images/icons/bank/lender-punjab-national-bank-icon.png';
	} else if (bN?.includes('yes')) {
		icon_path = '/images/icons/bank/lender-yes-bank-icon.png';
	} else if (bN?.includes('tata')) {
		icon_path = '/images/icons/bank/lender-tata-icon.png';
	} else if (bN?.includes('shriram')) {
		icon_path = '/images/icons/bank/lender-shriram-icon.png';
	} else if (bN?.includes('fibe') || bN?.includes('earlysalary')) {
		icon_path = '/images/icons/bank/fibe.png';
	} else {
		icon_path = '/images/icons/leads/bank_icon_placeholder.svg';
	}
	return icon_path;
};

export const disableNumberArrows = (e) => {
	//Disable Arrow Up and Down in number field
	if (e.keyCode === 38 || e.keyCode === 40) {
		e.preventDefault();
	}
};

export const restrictAmountChar = (e, value, maxLength = 15) => {
	// value must be a string
	if (typeof value === 'string') {
		if (value.length >= maxLength) {
			e.preventDefault();
		}
	} else {
		return;
	}
};

export const getIcon = (ch) => {
	switch (ch) {
		case '1':
			return '/images/icons/lender-sub-product-icon/processing_fees.svg';
		case '2':
			return '/images/icons/lender-sub-product-icon/tenure_range.svg';
		case '3':
			return '/images/icons/lender-sub-product-icon/loan_amount.svg';
		case '4':
			return '/images/icons/lender-sub-product-icon/interest_rate.svg';
		default:
			return '';
	}
};
export const groupParamsByKey = (params) =>
	[...params.entries()].reduce((acc, tuple) => {
		const [key, val] = tuple;
		if (Object.prototype.hasOwnProperty.call(acc, key)) {
			if (Array.isArray(acc[key])) {
				acc[key] = [...acc[key], val];
			} else {
				acc[key] = [acc[key], val];
			}
		} else {
			acc[key] = val;
		}

		return acc;
	}, {});

export const ACL = (roleArr = [], compId) => {
	const permissions = roleArr.filter((item) => item.id === compId);
	if (permissions.length) {
		return true;
	}
	return false;
};

export const checkFieldsValidToContinue = (errors) => {
	let _valid = true;
	if (Object.keys(errors).length > 0) {
		Object.keys(errors)?.map((key) => {
			if (errors[key]?.Data) {
				_valid = checkFieldsValidToContinue(errors[key]?.Data);
			} else if (errors?.Bank) {
				_valid = checkFieldsValidToContinue(errors?.Bank);
			} else if (errors?.Photo) {
				_valid = checkFieldsValidToContinue(errors?.Photo);
			} else if (errors?.Data) {
				_valid = checkFieldsValidToContinue(errors?.Data);
			} else {
				if (
					errors[key].type != 'required' &&
					errors[key].type != 'optionality' &&
					errors[key].type != 'nullable' &&
					errors[key]?.Url?.type != 'required' &&
					errors[key]?.Url2?.type != 'required'
				) {
					if (
						(key === 'dob' && errors[key].type === 'typeError') ||
						key === 'acceptTerms'
					) {
						_valid = _valid ? true : false;
					} else {
						if (_valid) {
							_valid = false;
						}
					}
				}
			}
		});
	} else {
		_valid = true;
	}
	return _valid;
};

export const sanitizeHtml = (dirty) => {
	var tagsToReplace = {
		'&': '&amp;',
		'<': '&lt;',
		'>': '&gt;',
	};
	return dirty.replace(/[&<>]/g, function (tag) {
		return tagsToReplace[tag] || tag;
	});
};

/**
 * Get blank key from object
 * @param {*} obj
 */
export const getBlankKeyFromObject = (obj) => {
	for (var propName in obj) {
		if (obj[propName]) {
			delete obj[propName];
		}
	}
	return obj;
};

export const capFirstChar = (value) => {
	if (value) {
		const str = value;
		const modStr = str.replace(/^./, str[0].toUpperCase());
		return modStr;
	} else {
		return undefined;
	}
};
export const lowerCaseText = (value) => {
	if (value) {
		const str = value;
		const modStr = str.toLowerCase();
		return modStr;
	} else {
		return undefined;
	}
};

export const onlineLenders = [
	'aditya birla finance ltd.',
	'bajaj finance growth',
	'bajaj finance prime',
	'incred financial services ltd.',
	'lendingkart finance ltd.',
	'tata capital',
	'kreditbee',
	'L&T Finance',
	'kotak mahindra bank limited',
	'moneyview',
	'aadhar housing finance ltd',
	'pnb housing finance limited',
	'bajaj finance ltd.',
	'piramal capital',
	'prefr',
	'cashe',
	'bhanix finance',
	'fibe',
	'moneytap',
	'dmi housing',
	'indifi',
];

export const rmNotRequired = (lender) => {
	const lendersList = [
		'kreditbee',
		'bajaj finance ltd.',
		'moneyview',
		'bhanix finance',
		'prefr',
		'aditya birla finance ltd.',
		'fibe',
		'lendingkart finance ltd.',
		'bajaj finance ltd.',
		'moneytap',
		'dmi housing',
	];
	return lendersList.includes(lender.toLowerCase());
};

export const lenderJourneyURL = (lender) => {
	const lendersList = [
		'aditya birla finance ltd.',
		'bhanix finance',
		'fibe',
		'incred financial services ltd.',
	];
	return lendersList.includes(lender.toLowerCase());
};

export const directLenderSpecific = (lender) => {
	const lendersList = [
		'kreditbee',
		'bajaj finance ltd.',
		'moneyview',
		'bhanix finance',
		'prefr',
		'aditya birla finance ltd.',
		'CASHe',
		'Bhanix Finance',
		'Fibe (formerly EarlySalary)',
		'fibe',
		'lendingkart finance ltd.',
		'moneytap',
		'dmi housing',
		'indifi',
	];
	return lendersList.includes(lender.toLowerCase());
};

export const isLenderIncludes = (lender) => {
	return onlineLenders.includes(lender) ? true : false;
};

/**
 * Calculate EMI amount
 * @param loanAmount Loan Amount
 * @param annualInterestRate Yearly interest rate
 * @param tenureInMonth Tenure in month
 */
export const calculateEMI = (loanAmount, annualInterestRate, tenureInMonth) => {
	const monthlyInterestRate = Number(annualInterestRate) / 12 / 100;
	const monthlyPayment = Math.round(
		(Number(loanAmount) *
			monthlyInterestRate *
			Math.pow(1 + monthlyInterestRate, Number(tenureInMonth))) /
			(Math.pow(1 + monthlyInterestRate, Number(tenureInMonth)) - 1)
	);
	return monthlyPayment;
};

// Notify Visitors
export function notifyHandler(tracking, data) {
	if (isPlatform('android') || isPlatform('ios')) {
		if (tracking === 'event') {
			NotifyVisitors.event(data).then((value) => {
				console.log(value);
			});
		} else if (tracking === 'user') {
			NotifyVisitors.userIdentifier(data);
		}
	} else {
		console.log('web hittt');
	}
}

export function eventAttributes(event_name, userAttributes) {
	return {
		eventName: event_name,
		attributes: { userAttributes },
		lifeTimeValue: '1',
		scope: '7',
	};
}

export const TrackGoogleAnalyticsEvent = (
	ReactGA4,
	category,
	event_name,
	label,
	data
) => {
	console.log('GA event:', category, ':', event_name, ':', label);

	let event_params = {
		category,
		label,
		...data,
	};
	// Send GA4 Event
	ReactGA4.event(event_name, event_params);
};
