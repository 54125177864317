//// @ts-nocheck


import { IonItem, IonRadio, IonRadioGroup } from "@ionic/react";

export default function CustomRadioButton({
  type,
  data,
  name,
  value,
  label,
  labelPlacement,
  justify,
  ionRadioGroupClassName,
  ionRadioClassName,
  onIonChange,
  alignment,
}: any) {
  return (
    <>
      {type === "group" ? (
        <IonRadioGroup
          value={value}
          onIonChange={onIonChange}
          className={ionRadioGroupClassName}
        >
          {data &&
            data.map((item: any, index: any) => {
              return (
                <IonItem key={item?.name} lines="none" className="ion-no-padding">
                  <IonRadio
                    key={`custom-radio-${index}`}
                    value={item?.value}
                    name={item?.name}
                    justify={justify}
                    labelPlacement={labelPlacement}
                    className={ionRadioClassName}
                  >
                    {item?.name}
                  </IonRadio>
                </IonItem>
              );
            })}
        </IonRadioGroup>
      ) : (
        <IonRadio
          value={value}
          name={name}
          justify={justify}
          labelPlacement={labelPlacement}
          className={ionRadioClassName}
        >
          {label}
        </IonRadio>
      )}
    </>
  );
}
{
  /* 
    if group 
    eg ->
    [{
        id:1,
        name:'same',
        value:'v1',
        label:'Value 1'
    }]


   if single 
    eg for signle
    <CustomRadioButton
	name={item?.name}
	value={item?.value}
	label={item?.label}
	labelPlacement='end'
	justify='start'
	className='fs-14'
/> */
}
