import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { IonItem, IonLabel, IonList, isPlatform } from '@ionic/react';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BankDetailKycSchema } from './KycValidationSchema';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import {
	checkFieldsValidToContinue,
	getPictureDataByOcr,
	showToastAlert,
	urltoFile,
} from '../../../helpers/functions';
import { UploadImageCard } from './UploadImageCard';
import { useDispatch, useSelector } from 'react-redux';
import {
	checkIfscCode,
	customSuccessfull,
	imgUrlUpdate,
	kycBasicInfo,
	kycDocumnetUploadBank,
	kycUserUploadImage,
} from '../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';

export const BankDetailKyc = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const dmaSelector = useSelector((state: any) => state?.users);
	const [openUploadModal, setOpenUploadModal] = useState(false);
	const [clickImageFieldName, setClickImageFieldName] = useState<any>(null);
	const [imagePreviewUpload, setImagePreviewUpload] = useState({
		cancelledCheque: false,
	});
	const [docTypeErrorMessage, setDocTypeErrorMessage] = useState({
		urlError: '',
	});
	const isBrowser = !isPlatform('capacitor');
	const isWeb = useSelector((state: any) => state?.app?.isWeb);

	const dispatch = useDispatch();
	// const [src, setSrc] = useState('');
	const userData = useSelector((state: any) => state?.users);
	let continueLater = false;
	const {
		handleSubmit,
		control,
		setValue,
		trigger,
		reset,
		formState: { errors, isValid },
		getValues,
	}: any = useForm({
		resolver: yupResolver(BankDetailKycSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			Type: 'Bank',
			Bank: {
				Url: null,
				AccountNo: '',
				IFSC: '',
				BankName: '',
				BranchName: '',
			},
			status: 'added',
		},
	});

	useEffect(() => {
		if (location.pathname !== '/kyc') {
			return;
		}
		fetchBankDetailKyc();
	}, [dmaSelector?.user_details?.id, location.pathname]);

	//Commented because now we dont get full signed url on file upload
	/*useEffect(() => {
		getUpdateUrl();
	}, [getValues('Bank.Url')]);

	const getUpdateUrl = async () => {
		if (getValues('Bank.Url')) {
			dispatch(
				imgUrlUpdate({
					data: {
						url: getValues('Bank.Url'),
						expire_time: 50000,
					},
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						setValue('Bank.Url', response?.url);
						setImagePreviewUpload({
							...imagePreviewUpload,
							cancelledCheque: true,
						});
					}
				});
		}
	};*/
	const ShareModal = () => {
		const handleUploadChange = async (
			type: string,
			fieldName: string,
			e: any
		) => {
			setOpenUploadModal(false);
			const formData = new FormData();
			let image: any;
			let getOcrData: any;
			let getImageFileObject: any;
			setDocTypeErrorMessage({
				urlError: '',
			});
			switch (type) {
				case 'file':
					if (
						e.target.files[0].type === 'image/png' ||
						e.target.files[0].type === 'image/jpeg' ||
						e.target.files[0].type === 'application/pdf'
					) {
						formData.append('profile', e.target?.files?.[0]);
						dispatch(kycUserUploadImage({ data: formData }))
							.then(unwrapResult)
							.then((response: any) => {
								if (response?.status) {
									setValue('Bank.Url', response?.url);
									switch (fieldName) {
										case 'Bank.Url':
											trigger('Bank.Url');
											setDocTypeErrorMessage({
												...docTypeErrorMessage,
												urlError: '',
											});
											setImagePreviewUpload({
												...imagePreviewUpload,
												cancelledCheque: true,
											});
											break;
									}
								}
							});
						break;
					} else {
						switch (fieldName) {
							case 'Bank.Url':
								setDocTypeErrorMessage({
									...docTypeErrorMessage,
									urlError:
										'Uploaded file is invalid. Only JPG/JPEG, PNG and PDF files are allowed.',
								});
								break;
						}
						break;
					}

				case 'photo':
					image = await Camera.getPhoto({
						quality: 50,
						allowEditing: false,
						resultType: CameraResultType.Uri,
						source: CameraSource.Photos,
					});
					getImageFileObject = await urltoFile(
						image.webPath,
						`${Math.random() * 100000000000000000}.${image.format}`,
						image.format
					);
					getOcrData = await getPictureDataByOcr(
						'imageWithData',
						image.path,
						image.webPath,
						'cheque'
					);
					if (
						!['not found', 'notfound'].includes(
							getOcrData.processedData.toLowerCase()
						)
					) {
						setValue(
							fieldName.split('.')[0].concat('.IFSC'),
							getOcrData.processedData
						);
					} else {
						//console.log('ocr data not found invalid doc');
					}
					formData.append('profile', getImageFileObject);
					dispatch(kycUserUploadImage({ data: formData }))
						.then(unwrapResult)
						.then((response: any) => {
							if (response?.status) {
								setValue('Bank.Url', response?.url);
								switch (fieldName) {
									case 'Bank.Url':
										setImagePreviewUpload({
											...imagePreviewUpload,
											cancelledCheque: true,
										});
										break;
								}
							}
						});
					break;
				case 'camera':
					image = await Camera.getPhoto({
						quality: 50,
						allowEditing: false,
						resultType: CameraResultType.Uri,
						source: CameraSource.Camera,
					});
					getImageFileObject = await urltoFile(
						image.webPath,
						`${Math.random() * 100000000000000000}.${image.format}`,
						image.format
					);
					getOcrData = await getPictureDataByOcr(
						'imageWithData',
						image.path,
						image.webPath,
						'cheque'
					);
					if (
						!['not found', 'notfound'].includes(
							getOcrData.processedData.toLowerCase()
						)
					) {
						setValue(
							fieldName.split('.')[0].concat('.IFSC'),
							getOcrData.processedData
						);
					} else {
						//console.log('ocr data not found invalid doc');
					}
					formData.append('profile', getImageFileObject);
					dispatch(kycUserUploadImage({ data: formData }))
						.then(unwrapResult)
						.then((response: any) => {
							if (response?.status) {
								setValue('Bank.Url', response?.url);
								switch (fieldName) {
									case 'Bank.Url':
										setImagePreviewUpload({
											...imagePreviewUpload,
											cancelledCheque: true,
										});
										break;
								}
							}
						});
					break;
				default:
					break;
			}
		};
		return (
			<>
				<IonList>
					{!isBrowser && (
						<IonItem lines='full'>
							<img
								src='/images/icons/kyc/Image-upload.svg'
								alt='Upload Photo'
							/>
							<IonLabel>
								<h2
									className='fs-16 ps-3 text-dark'
									onClick={(e) => {
										handleUploadChange('photo', clickImageFieldName, e);
									}}
								>
									Upload Photo
								</h2>
							</IonLabel>
						</IonItem>
					)}
					<IonItem
						lines='full'
						className='cursor-pointer'
					>
						<img
							src='/images/icons/kyc/upload-image-icon.svg'
							alt='Upload Photo'
						/>
						<IonLabel>
							<label
								style={{ display: 'block' }}
								htmlFor='upload-photo'
								className='cursor-pointer'
							>
								<h2 className='fs-16 ps-3 text-dark'>{t('Upload Document')}</h2>
							</label>
							<input
								style={{ display: 'none' }}
								type='file'
								id='upload-photo'
								accept='image/png, image/jpeg ,application/pdf'
								onChange={(e) => {
									handleUploadChange('file', clickImageFieldName, e);
								}}
							/>
						</IonLabel>
					</IonItem>
					{!isBrowser && (
						<IonItem lines='full'>
							<img
								src='/images/icons/kyc/camera-icon.svg'
								alt='Upload Photo'
							/>
							<IonLabel>
								<h2
									className='fs-16 ps-3 text-dark'
									onClick={(e) => {
										handleUploadChange('camera', clickImageFieldName, e);
									}}
								>
									Take Photo
								</h2>
							</IonLabel>
						</IonItem>
					)}
				</IonList>
			</>
		);
	};

	const BankDetailsKycInputs = [
		{
			name: 'AccountNo',
			placeholder: `${t('Account No')} . *`,
			type: 'text',
			isUpperCase: false,
			disabled: false,
		},
		{
			name: 'IFSC',
			placeholder: `${t('IFSC Code')} *`,
			type: 'text',
			disabled: false,
			isUpperCase: true,
		},
		{
			name: 'BankName',
			placeholder: t('Bank Name'),
			type: 'text',
			isUpperCase: false,
			disabled: true,
		},
		{
			name: 'BranchName',
			placeholder: t('Branch Name'),
			type: 'text',
			isUpperCase: false,
			disabled: true,
		},
	];
	const onSubmit = async (data: any) => {
		const name = userData?.aadhaarName;
		const doc = {
			Data: {
				AccountNo: data.Bank.AccountNo,
				IFSC: data.Bank.IFSC.toUpperCase(),
				BankName: data.Bank.BankName,
				BranchName: data.Bank.BranchName,
				name: name,
			},
			Type: data.Type,
			status: data.status,
			Url: data.Bank.Url,
		};
		let id;
		if (dmaSelector?.dmaFlow?.dmaId) {
			id = dmaSelector?.dmaFlow?.dmaId;
		} else {
			id = dmaSelector?.user_details?.id;
		}

		dispatch(
			kycDocumnetUploadBank({
				param: '/' + id,
				data: {
					...doc,
					continueLater: continueLater,
				},
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					if (!continueLater) {
						let path;
						if (dmaSelector?.dmaFlow?.dmaId) {
							path = '/proposed-payout';
						} else {
							path = '/dashboard/home';
						}
						history.replace('/custom-successfull');

						dispatch(
							customSuccessfull({
								type: 'KYCSUCCESS',
								message: t('Your KYC has been Submitted Successfully'),
								redirectTo: path,
							})
						);
					} else {
						continueLater = false;
						if (dmaSelector?.dmaFlow?.dmaId) {
							history.replace('/dashboard/teams');
						} else {
							history.replace('/dashboard/home');
						}
					}
				}
			});
	};
	const verifyIfsc = async (value: any) => {
		dispatch(checkIfscCode({ data: { ifsc_code: value } }))
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setValue('Bank.BankName', response?.ifsc?.branch);
					setValue('Bank.BranchName', response?.ifsc?.bank);
				} else {
					setValue('Bank.BankName', '');
					setValue('Bank.BranchName', '');
				}
				trigger('IFSC');
			});
	};
	const fetchBankDetailKyc = async () => {
		let id;
		if (dmaSelector?.dmaFlow?.dmaId) {
			id = dmaSelector?.dmaFlow?.dmaId;
		} else {
			id = dmaSelector?.user_details?.id;
		}

		dispatch(kycBasicInfo({ param: id + '/step4' }))
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					let url = '';
					if (response?.data[0]?.Url) {
						dispatch(
							imgUrlUpdate({
								data: {
									url: response?.data[0]?.Url,
									expire_time: 50000,
								},
							})
						)
							.then(unwrapResult)
							.then((response: any) => {
								url = response?.url;
								setValue('Bank.Url', response?.url);
								setImagePreviewUpload({
									...imagePreviewUpload,
									cancelledCheque: true,
								});
							});
					}
					if (response?.data[0]) {
						reset({
							Type: 'Bank',
							Bank: {
								Url: url || '',
								AccountNo: response?.data[0]?.Data?.AccountNo,
								IFSC: response?.data[0]?.Data?.IFSC,
								BankName: response?.data[0]?.Data?.BankName,
								BranchName: response?.data[0]?.Data?.BranchName,
							},
							status: 'added',
						});
						setTimeout(() => {
							if (
								response?.data[0]?.Data?.AccountNo != '' &&
								response?.data[0]?.Data?.IFSC != '' &&
								response?.data[0]?.Data?.BankName != '' &&
								response?.data[0]?.Data?.BranchName != ''
							) {
								trigger();
							}
						}, 100);
					}
				}
			});
	};

	return (
		<section className='conatiner-spacing-md overflowX-hidden kyc-content-height'>
			<form
				onSubmit={handleSubmit(onSubmit)}
				encType='multipart/form-data'
			>
				<div>
					<h4 className='m-0 text-grey fs-14 pb-2'>
						{t('Cancelled Cheque Image')} *
					</h4>
					<UploadImageCard
						{...{
							setImagePreviewUpload,
							imagePreviewUpload,
							isImageAvilable: imagePreviewUpload?.cancelledCheque,
							imgKey: 'cancelledCheque',
							setClickImageFieldName,
							setOpenUploadModal,
							setValue,
							value: 'Bank.Url',
							title: t('Upload Cheque'),
							src: getValues('Bank.Url'),
						}}
					/>
					{/* {errors?.Bank?.Url?.message && (
						<div className=''>
							<div className='error-text sc-ion-input-md fs-12'>
								{errors?.Bank?.Url?.message}
							</div>
						</div>
					)} */}
					{!imagePreviewUpload.cancelledCheque &&
					!docTypeErrorMessage.urlError &&
					errors?.Bank?.Url?.message ? (
						<div className=''>
							<div className='error-text sc-ion-input-md fs-12'>
								{t(errors?.Bank?.Url?.message)}
							</div>
						</div>
					) : (
						docTypeErrorMessage.urlError && (
							<div className=''>
								<div className='error-text sc-ion-input-md fs-12 pb-2'>
									{docTypeErrorMessage.urlError}
								</div>
							</div>
						)
					)}
				</div>
				{BankDetailsKycInputs.map(
					({ name, placeholder, type, disabled, isUpperCase }: any, index) => {
						return (
							<div
								className=''
								key={`step-4-${index}`}
							>
								<Controller
									control={control}
									render={({ field: { onChange } }) => {
										return (
											<CustomInput
												type={type}
												placeholder={placeholder}
												onChange={(e: any) => {
													if (name === 'IFSC') {
														if (e.detail.value.length === 11) {
															verifyIfsc(e.detail.value);
														} else {
															setValue('Bank.BankName', '');
															setValue('Bank.BranchName', '');
														}
													}
													onChange(e);
												}}
												value={
													isUpperCase
														? getValues(`Bank.${name}`)?.toUpperCase()
														: getValues(`Bank.${name}`)
												}
												// onBlur={onBlur}
												errors={t(errors?.['Bank']?.[name]?.message)}
												disabled={disabled}
											/>
										);
									}}
									name={`Bank.${name}`}
								/>
							</div>
						);
					}
				)}
				{/* submit button */}
				<div
					className='position-fixed-md w-100 bottom-0 start-0 px-3 px-md-0 pb-3'
					style={{ backgroundColor: '#fff', zIndex: 9 }}
				>
					<CustomButton
						className='w-100 fw-bold dashboard-add-lead-button mb-3'
						expand='block'
						fill='outline'
						title={t('Continue Later')}
						onClick={() => {
							if (checkFieldsValidToContinue(errors)) {
								continueLater = true;
								onSubmit(getValues());
							} else {
								showToastAlert({
									type: 'error',
									message: 'Please fix above errors',
								});
							}
						}}
					/>
					<CustomButton
						className='btn-blue'
						type='submit'
						expand='block'
						title={t('Proceed')}
						disabled={!isValid}
					/>
				</div>
			</form>
			{/* Upload Photo Modal */}
			<CustomModal
				id='sharemodal'
				isOpen={openUploadModal}
				initialBreakpoint={isWeb ? 1 : 0.3}
				handleClose={() => setOpenUploadModal(false)}
				breakpoints={[0, 0.3, 0.5, 0.75]}
				modalContent={<ShareModal />}
				className={`side-modal ${isWeb ? '' : 'modal-border-radius'}`}
				needCross={false}
				needArrow={false}
			/>
		</section>
	);
};
