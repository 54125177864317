import {
	IonContent,
	IonMenu,
	IonLabel,
	IonItem,
	IonImg,
	IonIcon,
	IonAccordionGroup,
	IonAccordion,
	// IonList,
	// useIonRouter,
} from '@ionic/react';
import { isPlatform } from '@ionic/core';

import { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { APP_VERSION, USER_ROLES } from '../../../helpers/constant';
import { CustomButton } from '../../common/custom-button/CustomButton';

import './Menu.scss';
import { Player } from '@lottiefiles/react-lottie-player';
import { CustomModal } from '../../common/custom-modal/CustomModal';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	apiMyLoyalityLink,
	moodleLink,
	needFooter,
} from '../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { logOut, showInAppBrowserView } from '../../../helpers/functions';
import { Share } from '@capacitor/share';

interface MenuProps {
	name?: string;
	imgSrc?: string;
}

export const Menu: React.FC<MenuProps> = ({ name, imgSrc }) => {
	const history = useHistory();
	const menu = useRef<HTMLIonMenuElement>(null);
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const roleType = useSelector(
		(state: any) => state?.users?.user_details?.roleType?.[0]
	);
	const UserMobile = useSelector(
		(state: any) => state?.users?.user_details?.mobile
	);

	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	function closeMenu() {
		menu.current?.setOpen(false);
	}
	const getMoodleLink = async () => {
		await dispatch(moodleLink())
			.then(unwrapResult)
			.then(async (response: any) => {
				if (response.status) {
					if (isPlatform('ios')) {
						// open in safari (iOs)
						window?.open(response?.url, '_self');
					} else {
						history.push('/training-sessions');
					}
				}
			});
		closeMenu();
	};

	const policyClick = async () => {
		menu.current?.setOpen(false);
		await dispatch(needFooter(false));
		history.push('/privacy-policy', { needFooter: false });
	};

	const termsClick = async () => {
		menu.current?.setOpen(false);
		await dispatch(needFooter(false));
		history.push('/terms-conditions', { needFooter: false });
	};
	const myLoyaltyLink = async () => {
		await dispatch(apiMyLoyalityLink())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					showInAppBrowserView(result.url);
				}
			});
	};

	return (
		<>
			<IonMenu
				ref={menu}
				contentId='dashboard-main-content-menu'
				className='ps-0 dashboard-menu'
				side={isWeb ? 'end' : 'start'}
			>
				<IonContent className='ps-0'>
					<div className='p-md-3'>
						<div
							id='menu-profile-section'
							className='blue-top-linear-gradient pb-3'
						>
							<div
								style={{
									position: 'relative',
									width: '100%',
									borderRadius: '2%',
									// marginTop: '8px',
								}}
							>
								<IonImg
									src='/images/icons/shine-background.svg'
									alt=''
									style={{
										width: '100%',
										zIndex: 1,
										position: 'absolute',
										right: 0,
										padding: '50px !important',
									}}
								></IonImg>
							</div>
							<div
								id='side-menu-profile-image'
								style={{
									backgroundImage: `url(${imgSrc})`,
								}}
							></div>
							<span className='fs-18 fw-600 d-inline-flex align-items-center text-capitalize'>
								{name}
								<IonImg
									className='ms-1'
									src='/images/icons/verified-check-mark.png'
									alt='verified'
									style={{ width: '13px', height: '13px' }}
								/>
							</span>
							{![USER_ROLES.DMAUL].includes(roleType) ? (
								<div className='fs-14 fw-600'>
									<Link
										className='text-decoration-none'
										to={'/view-profile'}
										onClick={closeMenu}
									>
										{t('View Profile')}
									</Link>
								</div>
							) : (
								''
							)}
						</div>
						<div className='menu-list-web'>
							{![USER_ROLES.DMAUL].includes(roleType) ? (
								<>
									<IonItem
										//routerLink='/id-card'
										className='cursor-pointer'
										onClick={() => history.push('/id-card')}
										lines='full'
									>
										<IonIcon
											src='/images/icons/my-id-card-and-agreement-menu-icon.svg'
											className='pe-2'
										/>
										<IonLabel className='fs-16 fw-500 text-wrap'>
											{t('My OneAndro ID')}
										</IonLabel>
									</IonItem>

									{/* <IonItem
										// routerLink='/my-earning' // commented because development is pending
										lines='full'
									>
										<IonIcon
											src='/images/icons/my-business-icon.svg'
											className='pe-2'
										/>
										<IonLabel className='fs-16 fw-500'>
											{t('My Earnings')}
										</IonLabel>
									</IonItem> */}
									<IonItem
										className='cursor-pointer'
										// routerLink='/privacy-policy'
										onClick={() => myLoyaltyLink()}
										lines='full'
									>
										<Player
											style={{
												width: '31px',
												paddingRight: '.5rem',
											}}
											autoplay={true}
											loop={true}
											controls={false}
											src='/images/icons/json/LoyaltyRewardsCoin.json'
										/>
										<IonLabel className='fs-16 fw-500'>
											{t('My Rewards')}
										</IonLabel>
									</IonItem>
								</>
							) : (
								''
							)}
							<IonItem
								className='cursor-pointer'
								lines='full'
								onClick={() => {
									getMoodleLink();
									// history.push('/training-sessions');
									// closeMenu;
								}}
							>
								<IonIcon
									src='/images/icons/training-sessions-icon.svg'
									className='pe-2'
								/>
								<IonLabel className='fs-16 fw-500'>
									{t('Training Sessions')}
								</IonLabel>
							</IonItem>
							<IonItem
								className='cursor-pointer'
								lines='full'
								onClick={() => {
									history.push('/mis-reports');
									closeMenu;
								}}
							>
								<IonIcon
									src='/images/icons/privacy-policy-menu-icon.svg'
									className='pe-2'
								/>
								<IonLabel className='fs-16 fw-500'>{t('MIS Reports')}</IonLabel>
							</IonItem>
							{(UserMobile == '9665702050' || UserMobile == '9970555665') && (
								<IonItem
									className='cursor-pointer'
									lines='full'
									onClick={() => {
										history.push('/lead-migration');
										closeMenu();
									}}
								>
									<IonIcon
										src='/images/icons/privacy-policy-menu-icon.svg'
										className='pe-2'
									/>
									<IonLabel className='fs-16 fw-500'>
										{t('Lead Migration')}
									</IonLabel>
								</IonItem>
							)}

							<IonItem
								className='cursor-pointer'
								onClick={() => {
									history.push('/help-desk');
									closeMenu;
								}}
								//routerLink='/help-desk'
								lines='full'
							>
								<img
									src='/images/icons/help-desk-icon.svg'
									alt='helpdesk icon'
									className='me-2'
								/>
								<IonLabel
									// color={'#0C0E2B'}
									className='fs-16 fw-500'
								>
									{t('Helpdesk')}
								</IonLabel>
							</IonItem>
							{/* <IonItem
						lines='full'
						>
						<IonIcon src='/images/icons/menu-setting-icon.svg' className='pe-2' />
						<IonLabel >Settings</IonLabel>

						</IonItem > */}
							{/* <IonAccordionGroup>
						<IonAccordion value='Settings'>
							<IonItem slot='header'>
								{/* <IonIcon
									icon='/images/icons/verified-check-mark.png'
									className="ion-accordion-toggle-icon custom-icon"
									name="logo-ionic"
									slot="end" >
								</IonIcon> */}
							<IonItem
								className='ion-no-padding remove-ion-item-inner-padding-end'
								lines='full'
							>
								<IonAccordionGroup className='custom-ion-item-accordion-dropdown w-100'>
									<IonAccordion value='Settings'>
										<IonItem
											slot='header'
											className='drop-down-icon'
										>
											<IonLabel className='fw-500 text-wrap'>
												{t('Settings & Privacy')}
											</IonLabel>
										</IonItem>
										<div
											slot='content'
											onClick={() => history.push('/settings')}
										>
											<IonItem
												className='sub-menu-item px-1'
												lines='none'
											>
												<img
													src='/images/icons/settings-bar-menu-icon.svg'
													className='pe-3'
												/>
												<IonLabel className='fs-14 my-0 fw-500 cursor-pointer'>
													{t('Settings')}
												</IonLabel>
											</IonItem>
										</div>
										<div
											slot='content'
											onClick={policyClick}
										>
											<IonItem
												className='sub-menu-item px-1'
												lines='none'
											>
												<img
													src='/images/icons/privacy-policy-menu-icon.svg'
													className='pe-3'
												/>
												<IonLabel className='fs-14 my-0 fw-500 cursor-pointer'>
													{t('Privacy Policy')}
												</IonLabel>
											</IonItem>
										</div>
										<div
											slot='content'
											onClick={termsClick}
										>
											<IonItem
												className='sub-menu-item px-1'
												lines='none'
											>
												<img
													src='/images/icons/terms-and-conditions-menu-icon.svg'
													className='pe-3'
												/>
												<IonLabel className='fs-14 my-0 fw-500 cursor-pointer text-break'>
													{t('Terms and Conditions')}
												</IonLabel>
											</IonItem>
										</div>
									</IonAccordion>
								</IonAccordionGroup>
							</IonItem>
							<IonItem
								onClick={() => {
									if (isPlatform('android') || isPlatform('ios')) {
										Share.share({
											title: 'OneAndro: Loans & Insurance',
											text: `Hi! 
	
	Greetings from OneAndro!
	
	Become an Independent Financial Advisor & start earning 50K+ every month. 
	
	What's in it for you!
	☑ Sell Financial Products, all from One Platform
	☑ Access to Credit Report
	☑ Check Loan Eligibility. Instantly!
	☑ Track Your Leads Effectively
	☑ Access Your Data. Anytime! Anywhere! 
	
	Download Now & Start Earning!
	
	Register here: https://play.google.com/store/apps/details?id=com.oneandro
	
	Regards,
	OneAndro Team
	`,

											//url: 'https://play.google.com/store/apps/details?id=com.oneandro',
										});
									} else {
										window.open(
											'https://play.google.com/store/apps/details?id=com.oneandro',
											'_blank'
										);
									}
								}}
								lines='full'
								className='cursor-pointer'
							>
								<IonIcon
									src='/images/icons/share-app-menu-icon.svg'
									className='pe-2'
								/>
								<IonLabel className='fs-16 fw-500'>{t('Share App')}</IonLabel>
							</IonItem>
							{![USER_ROLES.DMAUL].includes(roleType) ? (
								<IonItem
									lines='full'
									href={
										isPlatform('ios') ||
										isPlatform('ipad') ||
										isPlatform('iphone')
											? 'https://apps.apple.com/in/app/oneandro-loans-insurance/id6478778926'
											: 'https://play.google.com/store/apps/details?id=com.oneandro'
									}
									target='_blank'
								>
									<IonIcon
										src='/images/icons/rate-us-menu-icon.svg'
										className='pe-2'
									/>
									<IonLabel className='fs-16 fw-500 text-wrap'>
										{t('Rate Us')}
									</IonLabel>
								</IonItem>
							) : (
								''
							)}
							{/* uncomment only to test ocr, do not push on dev - SAURABH */}
							{/* <IonItem
						onClick={closeMenu}
						routerLink='/ocr-test'
						lines='full'
						>
						<IonIcon
							src='/images/icons/input-scan-icon.svg'
							className='pe-2'
						/>
						<IonLabel className='fw-500'>{t('OCR Test')}</IonLabel>
						</IonItem> */}
						</div>
						<div
							id='menu-version'
							className='text-center text-grey fs-12 pt-4 pb-3'
						>
							<span>
								{t('Version')} {APP_VERSION}
							</span>
						</div>

						<div className='px-3'>
							<CustomButton
								style={{ color: '#EC0A0A' }}
								className='btn-white fs-16 fw-600'
								onClick={() => setOpen(true)}
								expand='block'
								fill='outline'
								title={t('Sign Out')}
							/>
						</div>
					</div>
				</IonContent>
			</IonMenu>
			<CustomModal
				id='custommodal'
				isOpen={open}
				modalTitle={t('Update Stage')}
				initialBreakpoint={undefined}
				handleClose={() => setOpen(false)}
				breakpoints={[]}
				className='allocate-andro-manager'
				modalContent={
					<div className='wrapper pt-3'>
						<IonItem lines='full'>
							<div className='w-100 text-center mb-3'>
								<h5 className='mx-0 fs-20'>{t('Sign Out')}</h5>
								<p className='m-0 fs-14'>
									{t(`Are you sure you want to sign out`) + '?'}
								</p>
							</div>
						</IonItem>
						<div className='d-flex justify-content-center gap-3 px-3 mt-3'>
							<CustomButton
								onClick={() => setOpen(false)}
								className='w-50'
								fill='outline'
								title={t('Cancel')}
							/>
							<CustomButton
								className='w-50'
								fill='solid'
								onClick={() => logOut(dispatch, history)}
								title={t('Confirm')}
							/>
						</div>
					</div>
				}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
