import { createContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';
// import useApi from '../api/useApi';
import { ActivityDetails, ActivityDetailsData } from '../model/activity_details_bean';
// import { GET_ACTIVITY_DETAILS, GET_COMMENTS } from '../helpers/api-endpoints';
// import { useBusyLoading } from './BusyLoadingContext';
import { CommentListData, CommentListDetails } from '../model/comment_list_bean';

interface ActivityContextType {
  infoActivity: ActivityDetailsData[];
  commentList: CommentListData[];
  setInfoActivity: Dispatch<SetStateAction<ActivityDetailsData[]>>;
  setCommentList: Dispatch<SetStateAction<CommentListData[]>>;
}

const defaultContextValue: ActivityContextType = {
  infoActivity: [],
  commentList: [],
  setInfoActivity: () => {},
  setCommentList: () => {},
};

const ActivityContext = createContext<ActivityContextType>(defaultContextValue);

interface ActivityProviderProps {
  children: ReactNode;
}

const ActivityProvider = ({ children }: ActivityProviderProps) => {
  const [infoActivity, setInfoActivity] = useState<ActivityDetailsData[]>([]);
  const [commentList, setCommentList] = useState<CommentListData[]>([]);

  // const { responseData: activityData, error: activityError, loading: activityLoading, executeRequest: getActivity } = useApi<any>(GET_ACTIVITY_DETAILS);
  // const { responseData: commentData, error: commentError, loading: commentLoading, executeRequest: getCommentData } = useApi<any>(GET_COMMENTS);
  // const { setBusyState } = useBusyLoading();

  // useEffect(() => {
  //   getActivity("post", {"applicationId" : 1});
  //   getCommentData('post', {"applicationId" : 1});
  // }, []);
  
  // useEffect(() => {
  // if (activityData?.status) {
  //   setInfoActivity(activityData?.data);  
  // }if (commentData?.status) {
  //   setCommentList(commentData?.data);
  // }
  // },[activityData, commentData]);

  // useEffect(() => {
  //   setBusyState(commentLoading);
  // }, [commentLoading]);

  return (
    <ActivityContext.Provider value={{ infoActivity, commentList, setInfoActivity, setCommentList }}>
      {children}
    </ActivityContext.Provider>
  );
}

export { ActivityProvider, ActivityContext };




