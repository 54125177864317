/* eslint-disable no-case-declarations */
import { useEffect, useRef, useState } from 'react';
import { IonButton, IonIcon, IonImg } from '@ionic/react';
import { t } from 'i18next';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	fetchMasterData,
	lenderDocumentUpload,
	lenderPincodeDetailsFetch,
} from '../../../../store/reducers/lead/leadSlice';
import { CustomInput } from '../../../../components/common/custom-input/CustomInput';
import { DynamicDropdown } from '../../../../components/common';
import { CustomCheckbox } from '../../../../components/common/custom-checkbox/CustomCheckbox';
import {
	coAppFormDefaultValues,
	coApplicantAddressFields2,
	documentObj,
	formWiseDataSet,
	applicantFields3_1,
	applicantAssessedIncomeFields3,
	applicantAssessedOrITRIncomeFields3,
	applicantITRIncomeFields3,
	applicantCashOrBankIncomeFields3,
	form16Field,
} from './HLLAPInfo';
import { LenderNameForAPICall } from '../../../../helpers/lenders';
import { getCompanyNameList } from '../../../../store/reducers/user/userSlice';
import { sanitizeHtml } from '../../../../helpers/functions';
import { PAGINATION_DETAILS } from '../../../../helpers/constant';
import CustomPopoverSearch from '../../../../components/common/custom-popover/CustomPopoverSearch';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';

export const CoApplicantAllForm: React.FC<any> = ({
	control,
	errors,
	getValues,
	setValue,
	trigger,
	reset,
	currentPage,
	setPinCodeDataError,
	pinCodeDataError,
	coTabIndex,
}) => {
	// FORM 1
	const [relationType, setRelationType] = useState([]);
	const [genderType, setGenderType] = useState([]);
	const [maritialStatus, setMaritialStatus] = useState([]);
	// FORM 2
	const [sectorType, setSectorType] = useState([]);
	const [addressProofType, setAddressProofType] = useState([]);
	const [companyList, setCompanyList] = useState([]);
	const [openCompanyModal, setOpenCompanyModal] = useState(false);
	// FORM 3
	const [segmentType, setSegmentType] = useState([]);
	const [businessVintageType, setBusinessVintageType] = useState([]);

	const [isShowPreview, setToShowPreview] = useState(false);
	const [formFields, setFormFields] = useState<any>([]);
	const docUploadRef: any = useRef({});
	const dispatch = useDispatch();
	const { lenderSpecificDetails } = useSelector((state: any) => state?.lead);
	const { masterApis, fieldsForm } = formWiseDataSet(
		'co_applicant',
		currentPage
	);

	useEffect(() => {
		reset(
			coAppFormDefaultValues(currentPage, lenderSpecificDetails, coTabIndex)
		);
		setDataInitially();
		setFormDataConditionally();
	}, [currentPage]);

	const setDataInitially = () => {
		setFormFields(fieldsForm);
		getDataFromMaster();
	};

	const setFormDataConditionally = () => {
		switch (currentPage) {
			case 2:
				[
					'address_proof_doc',
					'kyc_aadhar_front_doc',
					'kyc_aadhar_back_doc',
					'kyc_pan_doc',
				].forEach((el: any) => {
					if (!getValues(el) || (getValues(el) && getValues(el).length === 0)) {
						setValue(el, [documentObj]);
					}
				});
				if (!getValues('address_same_as_applicant')) {
					setFormFields([...fieldsForm, ...coApplicantAddressFields2]);
				}
				break;
			case 3:
				if (getValues('is_income_earner')) {
					setFormFields([...fieldsForm, ...applicantFields3_1]);
				}

				switch (getValues('profile_segment_type_name')) {
					case 'Assessed Income':
						setFormFields([
							...fieldsForm,
							...applicantFields3_1,
							...applicantAssessedIncomeFields3,
							...applicantAssessedOrITRIncomeFields3,
						]);
						setDocumentDefaultValue(applicantAssessedOrITRIncomeFields3);
						break;
					case 'Regular ITR':
						setFormFields([
							...fieldsForm,
							...applicantFields3_1,
							...applicantITRIncomeFields3,
							...applicantAssessedOrITRIncomeFields3,
						]);
						setDocumentDefaultValue(applicantAssessedOrITRIncomeFields3);
						break;
					case 'Cash Salary':
					case 'Bank Salary':
						let fields: any = [
							...fieldsForm,
							...applicantFields3_1,
							...applicantCashOrBankIncomeFields3,
						];
						setDocumentDefaultValue(applicantCashOrBankIncomeFields3);
						if (
							getValues('profile_segment_type_name') === 'Bank Salary' &&
							Number(getValues('monthly_gross_income')) >= 25000
						) {
							fields = [...fields, ...form16Field];
							setDocumentDefaultValue(form16Field);
						}
						setFormFields(fields);
						break;
				}
				break;
		}
	};

	const setDocumentDefaultValue = (data: any) => {
		data.forEach((el: any) => {
			if (el.type === 'file') {
				if (
					!getValues(el.name) ||
					(getValues(el.name) && getValues(el.name).length === 0)
				) {
					setValue(el.name, [documentObj]);
				}
			}
		});
	};

	const getDataFromMaster = async () => {
		if (masterApis?.length > 0) {
			masterApis.forEach(async (el: any) => {
				await dispatch(fetchMasterData({ param: el }))
					.then(unwrapResult)
					.then((res: any) => {
						if (res?.status) {
							switch (el) {
								// FORM 1
								case 'DMIHLLAP_RELATIONSHIP':
									setRelationType(res?.data);
									break;
								case 'DMIHLLAP_GENDER':
									setGenderType(res?.data);
									break;
								case 'DMIHLLAP_MARITIAL_STATUS':
									setMaritialStatus(res?.data);
									break;
								// FORM 2
								case 'DMIHLLAP_SECTOR_TYPE':
									setSectorType(res?.data);
									break;
								case 'DMIHLLAP_ADDRESS_PROOF_TYPE':
									setAddressProofType(res?.data);
									break;
								// FORM 3
								case 'DMIHLLAP_SEGMENT_TYPE':
									setSegmentType(res?.data);
									break;
								case 'DMIHLLAP_BUSINESS_VINTAGE_PROOF_TYPE':
									setBusinessVintageType(res?.data);
									break;
							}
						}
					});
			});
		}
	};

	/**
	 * Get company list
	 * @param searchValue search text
	 */
	const getCompanyList = async (searchValue: any) => {
		await dispatch(
			getCompanyNameList({
				param: `?searchItem=${sanitizeHtml(
					encodeURIComponent(searchValue)
				)}&page=${PAGINATION_DETAILS.CURRENT_PAGE}&size=${
					PAGINATION_DETAILS.PAGE_SIZE
				}`,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setCompanyList(response.companyListData);
				}
			});
	};

	/**
	 * Set dropdown data
	 * @param name field name
	 */
	const setDropDownOptions = (name: string) => {
		switch (name) {
			// FORM 1
			case 'relation_with_applicant_type_id':
				return relationType;
			case 'gender_type_id':
				return genderType;
			case 'marital_status_type_id':
				return maritialStatus;
			// FORM 2
			case 'emp_sector_type_id':
				return sectorType;
			case 'address_proof_type_id':
				return addressProofType;
			// FORM 3
			case 'profile_segment_type_id':
				return segmentType;
			case 'business_vintage_proof_type_id':
				return businessVintageType;
		}
	};

	const onDropDownChanged = (name: string) => {
		switch (name) {
			// FORM 2
			case 'address_proof_type_id':
				if (getValues(name)) {
					const filterItem: any = addressProofType.filter(
						(el: any) => el.id == getValues(name)
					)[0];
					setValue('address_proof_document_id', filterItem?.id_1);
					setValue('address_proof_document_name', filterItem?.value_1);
				}
				break;
			// FORM 3
			case 'profile_segment_type_id':
				if (getValues(name)) {
					const filterItem: any = segmentType.filter(
						(el: any) => el.id == getValues(name)
					)[0];
					setValue('profile_segment_type_name', filterItem?.value);
					trigger('profile_segment_type_name');
					setFormFields(fieldsForm);
					[
						...applicantAssessedIncomeFields3,
						...applicantAssessedOrITRIncomeFields3,
						...applicantITRIncomeFields3,
						...applicantCashOrBankIncomeFields3,
						...form16Field,
					].forEach((el: any) => {
						if (el.name.includes('_doc')) {
							setValue(el.name, []);
						} else {
							setValue(el.name, '');
						}
					});
					setFormDataConditionally();
				}
				break;
		}
	};

	const onInputChange = (name: string) => {
		switch (name) {
			case 'curr_pincode':
				checkPincode(name);
				break;
			case 'monthly_gross_income':
				setFormDataConditionally();
				break;
		}
	};

	const checkPincode = async (name: string) => {
		const val: any = getValues(name)?.trim();
		if (val.length === 6) {
			await dispatch(
				lenderPincodeDetailsFetch({
					data: {
						lender_name: LenderNameForAPICall.DMI_HL_LAP,
						pincode: val,
					},
				})
			)
				.then(unwrapResult)
				.then((result: any) => {
					if (result?.status) {
						if (
							!result.data?.responseObj ||
							result.data?.responseObj?.length === 0
						) {
							setPinCodeDataError('Invalid Pincode');
						}
					}
				});
		}
	};

	/**
	 * Upload documents
	 * @param event file upload event
	 * @param key documents array index
	 */
	const uploadDocuments = async (name: any, event: any, key: number) => {
		const file = event.target.files[0];
		const fileTypes = ['jpg', 'jpeg', 'png', 'pdf'];
		const fType = file?.type?.split('/')?.reverse()[0];
		if (!fType || !fileTypes?.includes(fType?.toLowerCase())) {
			setToShowPreview(false);
			setValue(
				`${name}.${key}.message`,
				'Uploaded file is invalid. Only JPG/JPEG, PNG and PDF files are allowed.'
			);
			return;
		}

		if (file?.size / 1024 > 1024) {
			setToShowPreview(false);
			setValue(`${name}.${key}.message`, 'File size should be less than 1MB');
			return;
		}
		setValue(`${name}.${key}.message`, '');

		const formData: any = new FormData();
		formData.append('lender_name', LenderNameForAPICall.DMI_HL_LAP);
		formData.append('file', file);

		await dispatch(lenderDocumentUpload({ data: formData }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setValue(`${name}.${key}.file`, result?.key);
					setValue(`${name}.${key}.fileObj`, file);
					setValue(`${name}.${key}.preview`, URL.createObjectURL(file));
				} else {
					setValue(`${name}.${key}`, {
						...documentObj,
						message: result.message,
					});
				}
				trigger(name);
				setToShowPreview(false);
			});
		event.target.value = null;
	};

	useEffect(() => {
		setToShowPreview(true);
	}, [isShowPreview, !isShowPreview]);

	/**
	 * Show selected document preview
	 * @param key document array index
	 */
	const showFilePreview = (name: any, key: number) => {
		let previewItem: any;
		const fileObj: any = getValues(`${name}.${key}.fileObj`);

		if (fileObj?.type?.includes('image')) {
			previewItem = (
				<>
					<img
						src={getValues(`${name}.${key}.preview`)}
						alt='preview'
						className='me-2 file_preview'
						width={'100%'}
					/>
					<p className='filename'>{fileObj.name}</p>
				</>
			);
		} else if (fileObj?.type?.includes('pdf')) {
			previewItem = (
				<>
					<img
						src='/images/pdf.svg'
						alt='preview'
						className='me-2 file_preview'
						width={80}
					/>
					<p className='filename'>{fileObj.name}</p>
				</>
			);
		} else if (getValues(`${name}.${key}.message`)) {
			previewItem = (
				<p className='text-red'>{t(getValues(`${name}.${key}.message`))}</p>
			);
		}
		return previewItem;
	};

	/**
	 * Add new section to upload document
	 */
	const addNewDocument = (name: any) => {
		setValue(name, [...getValues(name), documentObj]);
		reset({
			...getValues(),
		});
	};

	/**
	 * Remove specific item
	 * @param key index
	 */
	const removeDocument = (name: any, key: number) => {
		const filterDocs = getValues(name).filter((ele: any, k: any) => k !== key);
		setValue(name, filterDocs);
		reset({
			...getValues(),
		});
	};

	return (
		<>
			{formFields.map(({ name, type, placeholder }: any, index: any) => {
				const errorText: any =
					errors && Object.keys(errors).length > 0 && errors;
				return (
					<div key={index}>
						{type === 'dropdown' ? (
							<div style={{ marginBottom: '15px' }}>
								<Controller
									control={control}
									render={({ field: { onChange, onBlur, name } }) => (
										<DynamicDropdown
											label={t(`${placeholder}`)}
											data={setDropDownOptions(name)}
											name={name}
											onChange={(e: any) => {
												onChange(e);
												onDropDownChanged(name);
											}}
											bindField={'id'}
											showField1={'value'}
											value={getValues(name)}
											onBlur={onBlur}
											className={'mb-0'}
											errors={errorText[name]?.message}
										/>
									)}
									name={name}
								/>
							</div>
						) : type === 'checkbox' ? (
							<div className='mb-2'>
								<Controller
									control={control}
									render={({ field: { onChange, onBlur, name } }) => (
										<CustomCheckbox
											name={name}
											onChange={({ detail: { checked } }: any) => {
												onChange(checked);
												trigger(name);
												if (name === 'is_income_earner') {
													[
														{ name: 'profile_segment_type_name' },
														...applicantFields3_1,
														...applicantAssessedIncomeFields3,
														...applicantAssessedOrITRIncomeFields3,
														...applicantITRIncomeFields3,
														...applicantCashOrBankIncomeFields3,
													].forEach((el: any) => {
														if (el.name.includes('_doc')) {
															setValue(el.name, [documentObj]);
														} else {
															setValue(el.name, '');
														}
													});
													if (checked) {
														setFormFields([
															...fieldsForm,
															...applicantFields3_1,
														]);
													} else {
														setFormFields(fieldsForm);
													}
												} else if (name === 'address_same_as_applicant') {
													coApplicantAddressFields2.forEach((el: any) => {
														setValue(el.name, '');
													});
													setPinCodeDataError('');
													if (checked) {
														setFormFields(fieldsForm);
													} else {
														setFormFields([
															...fieldsForm,
															...coApplicantAddressFields2,
														]);
													}
												}
											}}
											value={getValues(name)}
											labelPlacement='end'
											className='end-of-page'
											onBlur={onBlur}
											isChecked={getValues(name)}
											children={
												<span className='text-wrap'>{t(placeholder)}</span>
											}
										/>
									)}
									name={name}
								/>
							</div>
						) : type === 'date' ? (
							<Controller
								control={control}
								render={({ field: { onChange, onBlur, name } }) => (
									<CustomInput
										type={type}
										needSuffixIcon={true}
										suffixClassName={'position-absolute date-of-birth-sufix'}
										suffixText={
											<IonImg
												src='/images/icons/input-dob-calendar-icon.svg'
												alt='dob'
											/>
										}
										outerClassName={'position-relative'}
										label={t(placeholder)}
										onBlur={onBlur}
										id={name}
										onChange={(e: any) => {
											onChange(e);
										}}
										value={getValues(name)}
										errors={t(errorText[name]?.message || '')}
									/>
								)}
								name={name}
							/>
						) : type === 'file' ? (
							<>
								{getValues(name)?.map((el: any, k: any) => {
									return (
										<div key={k}>
											<div className='mb-4'>
												<CustomButton
													onClick={() => docUploadRef?.current[name]?.click()}
													expand='block'
													fill={'solid'}
												>
													<img
														src='/images/icons/upload-white.svg'
														alt='upload'
														className='me-2'
													/>
													{t(placeholder)}
												</CustomButton>

												<input
													type='file'
													style={{ display: 'none' }}
													accept='image/jpg,image/jpeg,image/png,application/pdf'
													ref={(el) => {
														docUploadRef.current[name] = el;
													}}
													id={name + k}
													onChange={(event) => uploadDocuments(name, event, k)}
												/>

												{isShowPreview && (
													<div className='mt-2 mb-2'>
														{showFilePreview(name, k)}
													</div>
												)}
												<span className='text-muted font-small'>
													Acceptable File Format : png, jpeg, jpg, pdf Maximum
													file size limit : 1MB
												</span>
											</div>

											{name !== 'kyc_aadhar_front_doc' &&
												name !== 'kyc_aadhar_back_doc' &&
												name !== 'kyc_pan_doc' && (
													<div className='add_rm_buttons'>
														{k === getValues(name).length - 1 && (
															<IonButton
																type='button'
																shape='round'
																color='primary'
																onClick={() => addNewDocument(name)}
															>
																<IonIcon src='/images/icons/add-icon.svg' />
															</IonButton>
														)}

														{getValues(name).length > 1 && (
															<IonButton
																shape='round'
																type='button'
																color='danger'
																onClick={() => removeDocument(name, k)}
															>
																<IonIcon src='/images/icons/delete.svg' />
															</IonButton>
														)}
													</div>
												)}
										</div>
									);
								})}
							</>
						) : (
							<>
								<Controller
									control={control}
									render={({ field: { onChange, onBlur, name } }) => (
										<CustomInput
											type={type}
											label={t(placeholder)}
											onBlur={onBlur}
											id={name}
											onChange={(e: any) => {
												onChange(e);
												onInputChange(name);
												trigger(name);
												if (name === 'monthly_gross_income') {
													trigger('monthly_deduction');
												}
											}}
											onFocus={() => {
												if (name === 'company_name') {
													setOpenCompanyModal(true);
												}
											}}
											value={
												name === 'pan_no'
													? getValues(name)?.toUpperCase()
													: getValues(name)
											}
											errors={
												name === 'curr_pincode'
													? t(pinCodeDataError || errorText[name]?.message)
													: t(errorText[name]?.message)
											}
										/>
									)}
									name={name}
								/>
								{name === 'company_name' && (
									<div>
										<CustomPopoverSearch
											searchApi={getCompanyList}
											list={companyList}
											setList={setCompanyList}
											isOpen={openCompanyModal}
											searchListModalClose={setOpenCompanyModal}
											title={t('Company Names')}
											searchbarPlaceholder={t('Search Company by “Name”')}
											customNoDataMessage={t('Search by your company name')}
											setValue={(val: any) => {
												setValue(name, val);
												trigger(name);
											}}
										/>
									</div>
								)}
							</>
						)}
					</div>
				);
			})}
		</>
	);
};
