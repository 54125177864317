//// @ts-nocheck


import { yupResolver } from '@hookform/resolvers/yup';
import { IonButtons, IonContent, IonImg, IonPage } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import useApi from '../../api/useApi';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import CustomDropDown from '../../components/common/custom-dropdown/CustomDropDown';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
import { Header } from '../../components/layout/header/Header';
import ApplicationContext from '../../context/ApplicationContext';
import {AuthContext} from '../../context/AuthContext';
import { useBusyLoading } from '../../context/BusyLoadingContext';
import { GETMANAGERLIST } from '../../helpers/api-endpoints';
import { INSURANCE_POLICY_PROPOSED_FOR, INSURANCE_TYPE } from '../../helpers/constant';
import {
    disableNumberArrows,
} from '../../helpers/functions';
import { ApplicationBean } from '../../model/insurance_bean';
import { ApplicationBasicDetailsProps } from '../application_basic_details/application_basic_details';
import addLeadScema from './manager_selection.schema';


export interface ManagerProps {
    title?: string;
    inpCallback?: () => void;
    insuranceTypeId?: number;
    //   familyDetailsForm?: FamilyDetailsForm;
    // userId: any;
    // setShowBasicInfoForm: any;
    // getUserDetailsInfo: any;
}




export const ManagerSelection: React.FC<ManagerProps> = ({ insuranceTypeId, title, inpCallback }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [validateForm, setValidateForm] = useState<boolean>();
    const [managers, setManagers] = useState([]);
    const [showAllocateManagerModal, setShowAllocateManagerModal] =
		useState<boolean>(false);
    const location = useLocation();
    const { setAppBean } = useContext(ApplicationContext);
    const { userId } = useContext(AuthContext);


    // const { myState } = location.state || {};

    // managerProps: ManagerProps;
    //  = location.state.state || {};


    // useEffect(()=>{
        if (insuranceTypeId === undefined) {
            if (location.pathname === '/managerSelection') {
    
                const state = location.state as any;
                const managerProps = state?.managerProps || {};
                insuranceTypeId = managerProps?.insuranceTypeId;
            }
        }
    // },[location]);
    

    // console.log(token);

    // console.log("hbs insuranceTypeId", insuranceTypeId);




    const { control, register, trigger, handleSubmit, setValue,
        getValues, formState: { errors, isValid } } = useForm({
            resolver: yupResolver(addLeadScema),
            //   defaultValues: familyDetailsForm
        });

    const onSubmit = (data: any) => {
        // console.log('Family Details:', data);
        // handle form submission here (e.g., save data)
        // inpCallback();



        // console.log("hbs",props);
        var policyProposedFor: string | undefined;
        var showProposedFor: boolean = true;

        if ([INSURANCE_TYPE.GENERAL_VEHICLE, INSURANCE_TYPE.HEALTH_INDIVIDUAL].includes(insuranceTypeId!)) {
            policyProposedFor = INSURANCE_POLICY_PROPOSED_FOR.SELF;
            showProposedFor = false;
        }

        const applicationBean: ApplicationBean = {
            insuranceTypeId: insuranceTypeId!,
            managerId: getValues('andro_lead_manager'),
            mobile: getValues('mobile'),
            policyProposedFor: policyProposedFor,
            ifaId:parseInt(userId!),
        };

        console.log("hbs applicationBean ",applicationBean);

        if (insuranceTypeId == undefined) {
            return;
        }

        setAppBean(applicationBean);

        

        const props: ApplicationBasicDetailsProps = {
            showProposedFor: showProposedFor,
            applicationBasicDetailsForm: {
                insuranceTypeId: insuranceTypeId!,
                managerId: getValues('andro_lead_manager'),
                mobile: getValues('mobile'),
                policyProposedFor: policyProposedFor,
                ifaId:parseInt(userId!),
            }
        };



        history.push('/application_basic_details', {
            applicationDetailsProps: props,
        });

        // history.push('/application_basic_details');
    };


    const { responseData, loading, executeRequest, error } =
        useApi<any>(`${GETMANAGERLIST}?subproductid=${insuranceTypeId}`);

    const { setBusyState } = useBusyLoading();





    useEffect(() => {
        setBusyState(loading);
    }, [loading]);






    useEffect(() => {

        // executeRequest("get",null, true, false);
        executeRequest("get",null, true, false);


        //hbs remove this in future
        
        // setValue("mobile",'9970555665');

    }, []);

    useEffect(() => {
        console.log(responseData); 
        if (responseData?.status) {
            setManagers(responseData?.data);
        }
    }, [responseData]);

    useEffect(() => {
        // console.log(managers);
    }, [managers]);
    // setManagers(data);

    return (


        <IonPage className='bg-web-white container-width-md container-spacing-md border-md-radius-top'>
                <Header
					name='Manager Selection'
                    backButton={true}
					needArrow={true}
					endItem={
							<>
								<IonButtons
									slot='end'
                                    style={{
                                        padding: '20px',
                                      }}
								>
									<IonImg
										className='cursor-pointer'
										src='/images/icons/assign-icon.svg'
										alt='assign'
									/>
								</IonButtons>
							</>
					}
				/>
            <IonContent className='ion-padding-bottom'>
                <div className='select-product small-container bg-web h-100 pt-2'>
                    {/* <div className='select-product small-container bg-web h-100 pt-2'> */}
                    {/* <div className='bg-web-white container-width-md px-3 p-md-5 border-radius-10'> */}
                    <div className='padding'>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSubmit(onSubmit);
                                }
                            }}
                        >
                            <Controller
                                control={control}
                                render={({ field: { onChange, onBlur, name } }) => (
                                    <CustomInput
                                        type={'number'}
                                        placeholder={`Customer Mobile No.`}
                                        onBlur={onBlur}
                                        name={name}
                                        onChange={onChange}
                                        errors={errors?.mobile?.message}
                                        value={getValues(name)}
                                        onBeforeInput={(evt: any) => {
                                            //Only numbers accepted on user input
                                            !/[0-9]/.test(evt.data) && evt.preventDefault();
                                            //Only accepts 10 characters on user input
                                            if (evt.target.value.length === 10) {
                                                evt.preventDefault();
                                            }
                                        }}
                                        onPaste={(e: any) => {
                                            //Only get numbers on user paste
                                            e.preventDefault();
                                            let pasteContent = e.clipboardData.getData('Text');
                                            if (pasteContent != '') {
                                                pasteContent = pasteContent.replace(/\D/g, '');
                                                if (pasteContent.length > 10) {
                                                    pasteContent = pasteContent.slice(0, 10);
                                                }
                                            }
                                            setValue('mobile', pasteContent);
                                            trigger('mobile');
                                        }}
                                        onKeyDown={(e: any) => {
                                            disableNumberArrows(e);
                                        }}
                                        onWheel={(e: any) => e.target.blur()}
                                    />
                                )}
                                name={'mobile'}
                            />
                            <CustomDropDown
                                {...{
                                    className: `${errors?.andro_lead_manager && 'dropdown-error'
                                        }`,
                                    isCheckbox: false,
                                    title: t('OneAndro Manager'),
                                    setData: (data: any) => {
                                        setManagers(data);
                                        const val = data.filter((item: any) => item.checked);
                                        setValue('andro_lead_manager', val[0]?.id);
                                        trigger('andro_lead_manager');
                                    },
                                    data: managers,
                                    placeholder: t('Search Andro Manager by “Name”'),
                                    isSearch: true,
                                    modalTitle: t('OneAndro Manager'),
                                }}
                            />
                            {errors?.andro_lead_manager && (
                                <div className='input-bottom ps-3 pt-1'>
                                    <div className='error-text sc-ion-input-md'>
                                        {t(errors?.andro_lead_manager?.message as string || '')}
                                    </div>
                                </div>
                            )}


                            {/* <CustomButton
                                className='btn-blue'
                                type='submit'
                                expand='block'
                                title={t('Create Lead')}
                                onClick={() => { handleSubmit(onSubmit); }}
                            // disabled={!isValid}
                            // title={t('Update')}
                            // disabled={!isValid}
                            /> */}
                            <CustomButton
                                type='submit'
                                disabled={!isValid}
                                onClick={handleSubmit(onSubmit)}
                                className='btn-blue mt-md-4 d-md-block'
                                // className='btn-blue mt-md-4 d-none d-md-block'
                                expand='block'
                                title={t('Create Lead')}
                            />
                        </form>
                    </div>
                </div>
            </IonContent>

        </IonPage>

    );
}


