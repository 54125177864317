import { IonContent, IonPage, IonTextarea } from '@ionic/react';
import { Header } from '../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
import { useState } from 'react';
// import { CustomSelect } from '../../components/common/custom-select/CustomSelect';
import { useHistory, useLocation } from 'react-router';
import DropDownModal from '../../components/common/custom-dropdown/DropDownModal';

export const RequiredUpdateDetail: React.FC = () => {
	const { t } = useTranslation();
	const [rejectionModal, setRejectionModal] = useState(false);
	const history = useHistory();
	const location: any = useLocation();
	const RejectionModal = () => {
		return (
			<div>
				<h4 className='mt-3'>Reason for Rejection</h4>
				<p className='fs-10 mb-1'>Rejection Reason</p>
				{/* <CustomSelect
					data={[
						{
							id: 1,
							name: 'Rejection',
						},
					]}
					className='mt-1 w-100'
				/> */}
				<DropDownModal
					data={[
						{
							id: 1,
							name: 'Rejection',
						},
					]}
					name={'select'}
					// onChange={onChange}
					// onBlur={onBlur}
					// setValue={setReason}
					// errors={reason}
				/>
				<IonTextarea
					fill='outline'
					className='mb-3'
					placeholder={`${t('Add Comment')}. *`}
					mode='md'
				/>
				<CustomButton
					onClick={() => setRejectionModal(false)}
					fill={'outline'}
					title={'Cancel'}
					className={'w-100'}
				/>
				<CustomButton
					title={'Submit'}
					className={'w-100'}
				/>
			</div>
		);
	};
	return (
		<IonPage className='teams'>
			<Header
				needArrow={true}
				backButton={true}
				name={t('Update Details')}
			/>

			<IonContent
				style={{
					borderTop: '1px solid #00000029',
					height: 'calc(100vh - 113px)',
					overflowX: 'hidden',
				}}
			>
				<div className='p-4 d-flex flex-column gap-2'>
					<div className='d-flex align-items-center gap-5'>
						<h4 className='fs-18 fw-600 w-50 '>{t('User Details')}</h4>
						<p
							onClick={() => history.push('/update-details')}
							className='border mb-0  border-1 fs-12 fw-600 rounded-3'
						>
							{t('Rework Required')}
						</p>
					</div>
					<div className='d-flex align-items-center gap-5'>
						<h4 className='fs-18 fw-600 w-50 '>
							{t('Passpoert Size')} <br />
							{t('Photo')}
						</h4>
						<p
							onClick={() => history.push('/update-details')}
							className='border mb-0  border-1 fs-12 fw-600 rounded-3'
						>
							{t('Rework Required')}
						</p>
					</div>
					<div className='d-flex align-items-center gap-5'>
						<h4 className='fs-18 fw-600 w-50 '>{t('Address Proof')}</h4>
						<p className='border mb-0  border-1 fs-12 fw-600 rounded-3'>
							{t('Rework Required')}
						</p>
					</div>
					<div className='d-flex align-items-center gap-5'>
						<h4 className='fs-18 fw-600 w-50 '>{t('Bank Details')}</h4>
						<p className='border mb-0  border-1 fs-12 fw-600 rounded-3'>
							{t('Rework Required')}
						</p>
					</div>
				</div>
			</IonContent>
			<div className='d-flex ion-padding  gap-3'>
				<CustomButton
					className={'w-100'}
					title={t('Reject')}
					fill={'outline'}
					onClick={() => setRejectionModal(true)}
				/>
				<CustomButton
					className={'w-100'}
					title={t('Continue Later')}
					fill={'outline'}
					onClick={() =>
						history.push('/dma-details', {
							update: location?.state?.updateApi + 1 || 1,
						})
					}
				/>
			</div>
			<CustomModal
				id='rejectionmodal'
				isOpen={rejectionModal}
				initialBreakpoint={0.45}
				handleClose={() => setRejectionModal(false)}
				breakpoints={[0, 0.3, 0.45, 0.75]}
				modalContent={<RejectionModal />}
				needCross={false}
				needArrow={false}
			/>
		</IonPage>
	);
};
