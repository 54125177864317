import { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { Controller } from 'react-hook-form';
import { showToastAlert } from '../../../../helpers/functions';
import { CustomInput } from '../../../../components/common/custom-input/CustomInput';
import { DynamicDropdown } from '../../../../components/common';
import { coApplicantFormTwo } from './TataBl';
import CustomPopoverSearch from '../../../../components/common/custom-popover/CustomPopoverSearch';
import {
	getCompanyNameList,
	contributionPinCode,
} from '../../../../store/reducers/user/userSlice';
import { sanitizeHtml } from '../../../../helpers/functions';
import { PAGINATION_DETAILS } from '../../../../helpers/constant';
import { fetchMasterData } from '../../../../store/reducers/lead/leadSlice';
import { CustomCheckbox } from '../../../../components/common/custom-checkbox/CustomCheckbox';
import CustomRadioButton from '../../../../components/common/custom-radio-button/CustomRadioButton';
import { IonImg } from '@ionic/react';

export const CoApplicantForm2: React.FC<any> = ({
	control,
	getValues,
	setValue,
	trigger,
	errors,
}) => {
	const [customerType, setCustomerType] = useState([]);
	const [companyList, setCompanyList] = useState([]);
	const [partyRelation, setPartyRelation] = useState([]);
	const [contitution, setContitution] = useState([]);
	// const [personalContitution, setPersonalContitution] = useState([]);
	const [addressType, setAddressType] = useState([]);
	const [pinCodeDataError, setPinCodeDataError] = useState<any>({
		curr_pincode: '',
		per_pincode: '',
		off_pincode: '',
	});
	const [conditionalFields, setConditionalFields] = useState({
		doi: false,
	});
	const [openCompanyModal, setOpenCompanyModal] = useState(false);
	const [disablePermanentAddress, setDisablePermanentAddress] = useState(false);
	const dispatch = useDispatch();
	const { lenderSpecificDetails } = useSelector((state: any) => state?.lead);

	useEffect(() => {
		let showConditionalFields: any = conditionalFields;
		getDataFromMaster();
		if (
			lenderSpecificDetails?.lender_spf_details?.customer_type === 'Corporate'
		) {
			setValue('customer_type', 'Individual');
			getPersonalConstitution();
		} else {
			if (getValues('customer_type')) {
				getPersonalConstitution();
			}
		}
		setDisablePermanentAddress(getValues('curr_per_add_same'));

		if (getValues('customer_type') === 'Corporate') {
			showConditionalFields = {
				...showConditionalFields,
				doi: true,
			};
		}
		setConditionalFields(showConditionalFields);
	}, []);

	const getDataFromMaster = () => {
		[
			'TATA_CUSTOMER_TYPE',
			'TATABL_RELATION',
			'TATABL_CONSTITUTION',
			'TATALAP_ADDRESS_TYPE',
		].forEach(async (ele: any) => {
			await dispatch(fetchMasterData({ param: ele }))
				.then(unwrapResult)
				.then((result: any) => {
					if (result?.status) {
						let addType: any = [];
						switch (ele) {
							case 'TATA_CUSTOMER_TYPE':
								setCustomerType(result?.data);
								break;
							case 'TATABL_RELATION':
								setPartyRelation(result?.data);
								break;
							case 'TATABL_CONSTITUTION':
								setContitution(result?.data);
								break;
							case 'TATALAP_ADDRESS_TYPE':
								addType = result.data.map((item: any) => ({
									value: item.value,
									label: item.value,
									name: item.value,
								}));
								setAddressType(addType);
								break;
						}
					}
				});
		});
	};

	const getPersonalConstitution = async () => {
		if (getValues('customer_type') === 'Corporate') {
			setConditionalFields({
				...conditionalFields,
				doi: true,
			});
		} else {
			setValue('date_of_incorporation', undefined);
			setConditionalFields({
				...conditionalFields,
				doi: false,
			});
		}
		// const queryParams =
		// 	e === 'Corporate'
		// 		? 'TATABL_CONSTITUTION_PERSONAL_CORPORATE'
		// 		: 'TATABL_CONSTITUTION_PERSONAL_INDIVIDUAL';
		// await dispatch(fetchMasterData({ param: queryParams }))
		// 	.then(unwrapResult)
		// 	.then((result: any) => {
		// 		if (result?.status) {
		// 			setPersonalContitution(result?.data);
		// 		}
		// 	});
	};

	const pinCodeAction = async (value: any, name: any) => {
		await dispatch(
			contributionPinCode({
				data: {
					pincode: value,
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					if (!result?.list) {
						showToastAlert({
							type: 'error',
							message: 'Please Check Pin Code No.',
						});
					} else {
						showToastAlert({ type: 'success', message: result?.message });
					}
					setPinCodeDataError({
						...pinCodeDataError,
						[name]: '',
					});
				} else {
					setPinCodeDataError({
						...pinCodeDataError,
						[name]: result?.message,
					});
				}
			});
	};

	/**
	 * Get company list
	 * @param searchValue search text
	 */
	const getCompanyList = async (searchValue: any) => {
		await dispatch(
			getCompanyNameList({
				param: `?searchItem=${sanitizeHtml(
					encodeURIComponent(searchValue)
				)}&page=${PAGINATION_DETAILS.CURRENT_PAGE}&size=${
					PAGINATION_DETAILS.PAGE_SIZE
				}`,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setCompanyList(response.companyListData);
				}
			});
	};

	/**
	 * Set dropdown data
	 * @param name field name
	 */
	const setDropDownOptions = (name: string) => {
		switch (name) {
			case 'customer_type':
				return customerType;
			case 'constitution_code':
				return contitution;
			// case 'constitution_personal':
			// 	return personalContitution;
			case 'party_relation':
				return partyRelation;
		}
	};

	/**
	 * Disabled dropdown based on condition
	 * @param name field name
	 */
	const isDisabledDropDown = (name: any) => {
		if (
			name === 'customer_type' &&
			lenderSpecificDetails?.lender_spf_details?.customer_type === 'Corporate'
		) {
			return true;
		}
	};

	const onInputChange = (e: any, name: any) => {
		if (
			['curr_pincode', 'per_pincode', 'off_pincode'].includes(name) &&
			e.detail.value.length === 6
		) {
			pinCodeAction(e.detail.value, name);
		}

		if (getValues('curr_per_add_same')) {
			setValue('per_add_1', getValues('curr_add_1'));
			setValue('per_add_2', getValues('curr_add_2'));
			setValue('per_pincode', getValues('curr_pincode'));
		}
	};

	return (
		<>
			{coApplicantFormTwo.map(
				({ name, type, placeholder }: any, index: any) => {
					const errorText: any =
						errors && Object.keys(errors).length > 0 && errors;
					return (
						<div key={index}>
							{type === 'dropdown' ? (
								<div style={{ marginBottom: '15px' }}>
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, name } }) => (
											<DynamicDropdown
												label={t(`${placeholder}`)}
												data={setDropDownOptions(name)}
												name={name}
												onChange={(e: any) => {
													onChange(e);
													if (name === 'customer_type') {
														getPersonalConstitution();
														// setValue('constitution_personal', '')
													}
												}}
												bindField={
													name === 'constitution_code'
													//  || name === 'constitution_personal'
														? 'id'
														: 'value'
												}
												showField1={'value'}
												value={getValues(name)}
												onBlur={onBlur}
												disabled={isDisabledDropDown(name)}
												errors={errorText[name]?.message}
											/>
										)}
										name={name}
									/>
								</div>
							) : type === 'dynamicselect' ? (
								<>
									<Controller
										control={control}
										render={({ field: { onChange, onBlur } }) => (
											<CustomInput
												type={type}
												label={t(placeholder)}
												onBlur={() => {
													onBlur();
												}}
												id={name}
												onChange={(e: any) => {
													onChange(e);
													setOpenCompanyModal(true);
												}}
												value={getValues(name)}
												onFocus={() => {
													setOpenCompanyModal(true);
												}}
												errors={t(errorText[name]?.message)}
											/>
										)}
										name={name}
									/>
									<div className='mb-3'>
										<CustomPopoverSearch
											searchApi={getCompanyList}
											list={companyList}
											setList={setCompanyList}
											isOpen={openCompanyModal}
											searchListModalClose={setOpenCompanyModal}
											title={t('Company Names')}
											searchbarPlaceholder={t('Search Company by “Name”')}
											customNoDataMessage={t('Search by your company name')}
											setValue={(val: any) => {
												setValue('company_name', val);
												trigger('company_name');
											}}
										/>
									</div>
								</>
							) : type === 'checkbox' ? (
								<div className='mt-3 mb-3'>
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, value } }) => (
											<CustomCheckbox
												name={name}
												onChange={({ detail: { checked } }: any) => {
													onChange(checked);
													if (checked) {
														setValue('per_add_1', getValues('curr_add_1'));
														setValue('per_add_2', getValues('curr_add_2'));
														setValue('per_pincode', getValues('curr_pincode'));
														setDisablePermanentAddress(true);
														setPinCodeDataError({
															...pinCodeDataError,
															per_pincode: '',
														});
													} else {
														setValue('per_add_1', '');
														setValue('per_add_2', '');
														setValue('per_pincode', '');
														setDisablePermanentAddress(false);
													}
													trigger(['per_add_1', 'per_add_2', 'per_pincode']);
												}}
												value={value}
												labelPlacement='end'
												className='end-of-page'
												onBlur={onBlur}
												isChecked={value}
												children={
													<span className='text-wrap'>
														{t(placeholder) + '.'}
													</span>
												}
											/>
										)}
										name={name}
									/>
								</div>
							) : type === 'radio' ? (
								<Controller
									key={index}
									control={control}
									render={({ field: { onChange, name } }) => (
										<div className='mb-3'>
											<h6>{t(placeholder)}</h6>
											<CustomRadioButton
												type='group'
												value={getValues(name)}
												onIonChange={(e: any) => {
													onChange(e);
												}}
												className='mb-2'
												ionRadioClassName='me-4'
												labelPlacement='end'
												data={addressType}
											/>
											<div className='error-text px-3 sc-ion-input-md'>
												{t(errorText[name]?.message)}
											</div>
										</div>
									)}
									name={name}
								/>
							) : name === 'date_of_incorporation' ? (
								conditionalFields?.doi && (
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, name } }) => (
											<CustomInput
												type={type}
												needSuffixIcon={true}
												suffixClassName={
													'position-absolute date-of-birth-sufix'
												}
												suffixText={
													<IonImg
														src='/images/icons/input-dob-calendar-icon.svg'
														alt='dob'
													/>
												}
												outerClassName={'position-relative'}
												label={t(placeholder)}
												onBlur={onBlur}
												id={name}
												onChange={(e: any) => {
													onChange(e);
												}}
												value={getValues(name)}
												errors={t(errorText[name]?.message || '')}
											/>
										)}
										name={name}
									/>
								)
							) : (
								<Controller
									control={control}
									render={({ field: { onChange, onBlur, name } }) => (
										<CustomInput
											type={type}
											label={t(placeholder)}
											onBlur={onBlur}
											id={name}
											onChange={(e: any) => {
												onChange(e);
												onInputChange(e, name);
											}}
											value={getValues(name)}
											errors={
												['curr_pincode', 'per_pincode', 'off_pincode'].includes(
													name
												)
													? t(
															pinCodeDataError[name] || errorText[name]?.message
													  )
													: t(errorText[name]?.message)
											}
											disabled={
												disablePermanentAddress &&
												['per_add_1', 'per_add_2', 'per_pincode'].includes(name)
											}
										/>
									)}
									name={name}
								/>
							)}
						</div>
					);
				}
			)}
		</>
	);
};
