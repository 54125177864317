import * as yup from 'yup';
import { REGEX } from '../../../helpers/constant';

export const coApplicantDetails = [
	{
		name: 'Salutation',
		type: 'radio',
		placeholder: 'Please Select Co-Applicant Salutation *',
	},
	{
		name: 'First_Name',
		type: 'text',
		placeholder: 'Co-Applicant First Name *',
	},
	{
		name: 'Last_Name',
		type: 'text',
		placeholder: 'Co-Applicant Last Name *',
	},
	{
		name: 'PAN',
		type: 'text',
		placeholder: 'Co-Applicant PAN No *',
	},
	{
		name: 'Gender',
		type: 'dropdown',
		placeholder: 'Please Select Co-Applicant Gender *',
	},
	{
		name: 'Date_of_Birth',
		type: 'date',
		placeholder: 'Co-Applicant Date of Birth *',
	},
	{
		name: 'Mobile_Number',
		type: 'text',
		placeholder: 'Co-Applicant Mobile Number *',
		maxLength: 10,
	},
	{
		name: 'co_applicants_address_1',
		type: 'text',
		placeholder: 'Co Applicant Address 1*',
	},
	{
		name: 'co_applicants_address_2',
		type: 'text',
		placeholder: 'Co Applicant Address 2*',
	},
	{
		name: 'co_applicants_address_3',
		type: 'text',
		placeholder: 'Co Applicant Address 3*',
	},
	{
		name: 'co_applicants_pincode',
		type: 'text',
		placeholder: 'Co-Applicant Pincode *',
	},
	{
		name: 'Employment_Type',
		type: 'dropdown',
		placeholder: 'Co-Applicant Employment Type *',
	},
];

export const bajajBLHLSelectionFields = [
	{
		name: 'Product__c',
		type: 'dropdown',
		placeholder: 'Please Select Product *',
	},
	{
		name: 'CustomerType',
		type: 'dropdown',
		placeholder: 'Please Select Customer Type *',
	},
	{
		name: 'Constitution',
		type: 'dropdown',
		placeholder: 'Please Select Constitution *',
	},

	{
		name: 'ApplicantType',
		type: 'dropdown',
		placeholder: 'Please Select Applicant Type  *',
	},
	{
		name: 'ApplicantEmpType',
		type: 'dropdown',
		placeholder: 'Please Select Applicant Employment Type  *',
	},
	{
		name: 'CoApplicant',
		type: 'dropdown',
		placeholder: 'Co Applicant *',
	},
];

export const CoapplicantOtherdetails = [
	{
		name: 'RM',
		type: 'dropdown',
		placeholder: 'Select RM *',
	},
];

export const productBLLAP = [{ value: 'PSBL' }, { value: 'BLSE' }];

export const Constitution = [
	{ value: 'Individual' },
	{ value: 'Private Ltd Company' },
	{ value: 'Partnership' },
	{ value: 'Proprietorship' },
	{ value: 'Company' },
];
export const CustomerType = [{ value: 'Individual' }, { value: 'Corporate' }];

export const ApplicantType = [
	{ value: 'Primary' },
	{ value: 'Primary Financial CoApplicant' },
];

export const isCoApplicant = [
	{ label: 'Yes', value: 'Yes' },
	{ label: 'No', value: 'No' },
];
export const employmentType = [
	{ value: 'Salaried' },
	{ value: 'Self Employed Professional' },
	{ value: 'Self Employed Non Professional' },
];

export const bajajBLHLSelectionFieldsSchema = yup.object().shape({
	Product__c: yup.string().required('Prouct is Required.').trim(),
	CoApplicant: yup.string().required('CoApplicant is Required.').trim(),
	Constitution: yup.string().required('Constitution is Required.').trim(),
	CustomerType: yup.string().required('Customer Type is Required.').trim(),
	ApplicantType: yup.string().required('Applicant Type is Required.').trim(),
	ApplicantEmpType: yup
		.string()
		.required('Applicant Empolyment Type is Required.')
		.trim(),
});

export const bajajBLLAPvalidationSchema = yup.object().shape({
	Salutation: yup.string().required('Salutation is Required'),
	PAN: yup
		.string()
		.required('PAN No. is Required.')
		.trim()
		.matches(
			REGEX.PAN,
			"PAN no. should be in alphanumeric format 'ABCDE1234A' only"
		),
	First_Name: yup
		.string()
		.trim()
		.max(100, 'First Name should not be more than 100')
		.required('First Name is Required')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),

	Last_Name: yup
		.string()
		.trim()
		.max(100, 'Last Name should not be more than 100')
		.required('Last Name is Required')
		.matches(REGEX.ALLOWONLYCHAR, 'Please Enter Valid Input'),
	Date_of_Birth: yup
		.date()
		.required('DOB is Required.')
		// .min('1969-11-13', 'Date is too early')
		.typeError('Please select valid date')
		.max(new Date(Date.now()), 'Date cannot be in the future')
		.test('dob', 'Age must be 18 or older', function (birthdate) {
			const cutoff = new Date();
			cutoff.setFullYear(cutoff.getFullYear() - 18);
			return birthdate <= cutoff;
		}),
	Mobile_Number: yup
		.string()
		.max(10, 'Mobile No. Should Be 10 Digits.')
		.required('Mobile is Required.')
		.matches(/^\d+$/, 'Mobile No. Should Contain Only Digits.')
		.matches(REGEX.INDIAN_MOBILE_NUMBERS, 'Please Enter Valid Number.'),
	Product__c: yup.string().trim().required('Product is Required'),
	Gender: yup.string().trim().required('Gender is Required'),
	co_applicants_address_1: yup
		.string()
		.trim()
		.required('Current Residence Address Line 1 is Required'),
	co_applicants_address_2: yup
		.string()
		.trim()
		.required('Current Residence Address Line 2 is Required'),
	co_applicants_address_3: yup
		.string()
		.trim()
		.required('Current Residence Address Line 3 is Required'),
	co_applicants_pincode: yup
		.string()
		.trim()
		.required('Pin Code Number is Required')
		.matches(/^[1-9][0-9]{5}$/, 'Pincode should be 6 Digits')
		.length(6, 'Current Residence Pincode should be 6 Digits'),
	Employment_Type: yup.string().trim().required('Employment Type is Required'),
});

export const bajajBLLAPvalidationOtherDtlsSchema = yup.object().shape({
	RMManager: yup.string().trim().required('RM Manager is Required'),
});
