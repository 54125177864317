import { IonContent, IonFooter, IonPage, isPlatform } from '@ionic/react';
import React, { useEffect } from 'react';
import './PartnerSoftCode.scss';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import CustomNote from '../../components/common/custom-note/CustomNote';
import { Player } from '@lottiefiles/react-lottie-player';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { dmaAbout, selfDMARegister } from '../../store/reducers/user/userSlice';
import { eventAttributes, notifyHandler } from '../../helpers/functions';
import { NotifyVisitors } from 'capacitor-plugin-notifyvisitors';

export const PartnerSoftCode: React.FC = () => {
	const { t } = useTranslation();
	const dmaSelector = useSelector((state: any) => state?.users);
	const userData: any = useSelector((state) => state);
	const history = useHistory();
	const dispatch: any = useDispatch();
	// Notify Visitors
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const userName = useSelector(
		(state: any) => state?.users?.homepage?.profile?.name
	);
	const userMobile = useSelector(
		(state: any) => state?.users?.user_details?.mobile
	);

	const entityType = useSelector(
		(state: any) => state?.users?.user_details?.entityType
	);

	const userLang = useSelector(
		(state: any) => state?.oneTimeScreen?.language?.name
	);

	const userAttributes = {
		userId: userId,
		attributes: {
			name: userName,
			id: userId,
			mobile: userMobile,
			user_language: userLang,
			entityType:
				entityType === 'Individual'
					? 'DMA'
					: entityType === 'BTM'
					? 'employee'
					: entityType,
		},
	};
	useEffect(() => {
		if (isPlatform('android') || isPlatform('ios')) {
			notifyHandler('event', eventAttributes('add_DMA_soft_code_page'));
			notifyHandler('user', userAttributes.attributes);
			const inAppData = {
				tokens: { name: userAttributes },
				customRules: { banerrType: 'add dma soft code' },
			};
			NotifyVisitors.show(inAppData);
		} else {
			(window as any).nv('event', 'add_DMA_soft_code_page', 10, 1);
			(window as any).nv('user', userId, userAttributes.attributes);
		}
	}, []);
	return (
		<IonPage className='container-width-md my-md-4 border-md-radius-10 opacity-1'>
			{/* <Header name='' /> */}
			<IonContent className='ion-padding partner-soft-code'>
				<div className='text-center andro-generated-soft-code'>
					{dmaSelector?.dmaFlow?.dmaId ? (
						<Player
							// className="pe-3"
							autoplay={true}
							loop={true}
							controls={false}
							src='/images/icons/json/GreenTick.json'
							style={{
								height: '72px',
								width: '72px',
								marginTop: '6rem',
								// position: 'absolute',
								// left: 0,
								// right: 0,
								// top: '9rem',
							}}
						/>
					) : (
						<>
							<Player
								// className="pe-3"
								autoplay={true}
								loop={true}
								controls={false}
								src='/images/icons/json/Confetti.json'
								style={{
									height: '190px',
									width: '190px	',
								}}
							/>
							<Player
								// className="pe-3"
								autoplay={true}
								loop={true}
								controls={false}
								src='/images/icons/json/GreenTick.json'
								style={{
									height: '72px',
									width: '72px',
									position: 'absolute',
									left: 0,
									right: 0,
									top: '9rem',
								}}
							/>
						</>
					)}

					{dmaSelector?.dmaFlow?.dmaId ? (
						<h3 className='fs-16 darkest-grey fw-600 m-0 pt-2'>
							{t(
								'DMA created successfully. Below generated is DMA’s OneAndro Code.'
							)}
						</h3>
					) : (
						<>
							<h3 className='fs-18 darkest-grey fw-600 m-0 pt-2'>
								{t('CONGRATULATIONS!')}
							</h3>
							<h5 className='fs-16 darkest-grey fw-600'>
								{' '}
								{t('Your OneAndro Code is')}
							</h5>
						</>
					)}

					<h1 className='ion-no-margin fs-24 fw-bold text-primary py-2'>
						{dmaSelector?.dmaFlow?.dmaId
							? 'XXXXXXXXXXX' +
							  userData?.users?.registerUser?.finalSoftcode?.slice(11)
							: userData?.users?.registerUser?.finalSoftcode || ''}
					</h1>
					<p className='text-grey fs-14'>
						{t(
							`This code is temporary. On approval of KYC, permanent OneAndro code will be generated.`
						)}
					</p>
				</div>
			</IonContent>
			<IonFooter className='partner-soft-code-footer'>
				<div className='ion-padding'>
					{dmaSelector?.dmaFlow?.dmaId ? (
						<>
							<CustomButton
								expand='block'
								className={'btn-blue mt-2 fs-16'}
								fill='outline'
								onClick={() => {
									const myPromise = new Promise(function (myResolve) {
										setTimeout(function () {
											myResolve(history.push('/dashboard/teams'));
										}, 10);
									});
									myPromise.then(() => {
										dispatch(
											dmaAbout({
												RegisteredAs: userData?.users?.registerUser?.entityType,
											})
										);
										dispatch(selfDMARegister(''));
									});
								}}
								title={t('Continue Later')}
							/>
							<CustomButton
								expand='block'
								className={'btn-blue mt-2 fs-16'}
								onClick={() => {
									const myPromise = new Promise(function (myResolve) {
										setTimeout(function () {
											myResolve(history.replace('/kyc'));
										}, 10);
									});
									myPromise.then(() => {
										dispatch(
											dmaAbout({
												RegisteredAs: userData?.users?.registerUser?.entityType,
											})
										);
										dispatch(selfDMARegister(''));
									});
								}}
								title={t('Proceed with KYC')}
							/>
						</>
					) : (
						<>
							<CustomNote
								{...{
									header: t('Note'),
									content: t(
										'An Andromeda Manager will get in touch with you shortly to complete the onboarding process.'
									),
								}}
								className='mb-3'
							/>
							<CustomButton
								expand='block'
								className={'btn-blue mt-2'}
								onClick={() => {
									const myPromise = new Promise(function (myResolve) {
										setTimeout(function () {
											myResolve(
												history.replace(
													dmaSelector?.dmaFlow?.dmaId ? '/kyc' : '/dashboard'
												)
											);
										}, 10);
									});
									myPromise.then(() => {
										dispatch(
											dmaAbout({
												RegisteredAs: userData?.users?.registerUser?.entityType,
											})
										);
										dispatch(selfDMARegister(''));
									});
								}}
								title={t('Start Earning Now')}
							/>
						</>
					)}
				</div>
			</IonFooter>
		</IonPage>
	);
};
