import { IonContent, IonPage } from '@ionic/react';
import { useRef } from 'react';
import { Header } from '../../components/layout/header/Header';
import { useHistory } from 'react-router';
import './TrainingSessions.scss';
import { useSelector } from 'react-redux';

export default function TrainingSessions() {
	const iframeRef: any = useRef(null);
	const history = useHistory();
	const moodleLoginLink = useSelector(
		(state: any) => state?.users?.moodle_login_link
	);
	return (
		<IonPage>
			<Header
				name='Training Sessions'
				backButton={true}
				backButtonText=''
				needArrow={true}
				style={{ borderBottom: '1px solid #00000029' }}
				titleClassName='fw-600 text-black'
				needArrowOnClick={() => {
					if (history?.location?.pathname === '/training-sessions') {
						history.push('/dashboard/home');
					} else {
						history.goBack();
					}
				}}
			/>
			<IonContent
				className='training-sessions'
				scrollY={false}
				scrollX={false}
			>
				{moodleLoginLink ? (
					<iframe
						ref={iframeRef}
						className=''
						id='trainingSessionsFrame'
						height='100%'
						name='training-sessions-frame'
						allowTransparency={false}
						src={moodleLoginLink}
					></iframe>
				) : (
					''
				)}
			</IonContent>
		</IonPage>
	);
}
