import { IonModal, IonContent, IonIcon } from '@ionic/react';
import { useEffect, useState } from 'react';
import { Clipboard } from '@capacitor/clipboard';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { showInAppBrowserView } from '../../../helpers/functions';
import {
	getSelectedLendersList,
	lenderSpfDetailsFlow,
} from '../../../store/reducers/lead/leadSlice';
// import './CustomModalLeadStatus.scss';

interface Modal {
	isOpen?: boolean;
	handleClose?: any;
	modalContent?: any;
	messageText?: any;
	applicationNo?: any;
	extraText?: any;
	link?: any;
	copyLink?: any;
}

export const CustomModalLeadStatus: React.FC<Modal> = ({
	isOpen,
	handleClose,
	messageText = 'Your application submitted successfully.',
	applicationNo = '',
	extraText = '',
	link = '',
	copyLink = '',
}) => {
	const [secondCount, setSecondCount] = useState(10);
	const [isOpenModal, setOpenModal] = useState(isOpen);
	const [isCopied, setCopied] = useState(false);
	const history = useHistory();

	const { leadFlowData, lenderListData } = useSelector(
		(state: any) => state.lead
	);

	const dispatch = useDispatch();

	useEffect(() => {
		if (isOpen) {
			getLeadLender();
		}
	}, []);

	const getLeadLender = async () => {
		let isRedirectOnDashboard: any = true;
		await dispatch(
			getSelectedLendersList({
				param: '/' + leadFlowData?.leadId,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					response?.data?.forEach((el: any) => {
						if (!el.lender_int_comp) {
							isRedirectOnDashboard = false;
						}
					});
				}
			});
		setSecondCounter(isRedirectOnDashboard);
	};

	/**
	 * Set the timer for showing countdown to close modal
	 */
	const setSecondCounter = (isRedirectOnDashboard: any) => {
		let count = 10;
		const interval = setInterval(() => {
			count -= 1;
			setSecondCount(count);
			if (count === 0) {
				clearInterval(interval);
				setOpenModal(false);
				handleClose();
				dispatch(lenderSpfDetailsFlow({}));
				if (isRedirectOnDashboard || lenderListData?.length == 1) {
					history.push('/dashboard/lead');
				} else {
					history.push('/lender-specific-info');
				}
			}
		}, 1000);
	};

	/**
	 * Copy text
	 * @param text value
	 */
	const copyText = async (text: any) => {
		const finalText = text + `\n${extraText}`;
		await Clipboard.write({
			string: finalText,
		});

		setCopied(true);
		const clearTime = setTimeout(() => {
			setCopied(false);
			clearTimeout(clearTime);
		}, 3000);
	};

	return (
		<IonModal
			isOpen={isOpenModal}
			style={{ '--height': extraText ? '400px' : '350px', '--width': '400px' }}
			className={'modal-border'}
			backdropDismiss={false}
		>
			<IonContent className='ion-no-padding'>
				<div
					className='modal_wrapper'
					style={{ height: extraText ? '500px' : '350px' }}
				>
					<h4 className='text_success'>{t(messageText)}</h4>

					<div className='text_app_no'>
						<h5>{t('Your application number is')}</h5>
						<div className='app_no_copied'>
							<div className='app_no_text'>
								{String(applicationNo)
									?.split('\n')
									?.map((el: any, k: any) => (
										<div
											key={k}
											className='app_no_content'
										>
											<span>
												{el?.length > 120 ? el?.substr(0, 120) + '...' : el}
											</span>
										</div>
									))}
							</div>
							<IonIcon
								src='/images/icons/copy-icon.svg'
								className='copy_icon'
								onClick={() => copyText(applicationNo)}
							/>
							{isCopied && <small className='copied'>{t('Copied')}</small>}
						</div>
					</div>

					{extraText && <p>{extraText}</p>}

					{link && (
						<a
							onClick={() => showInAppBrowserView(link)}
							className='text_link'
						>
							{link}
						</a>
					)}
					{copyLink && (
						<span>
							{copyLink}{' '}
							<IonIcon
								src='/images/icons/copy-icon.svg'
								className='copy_icon'
								onClick={() => copyText(copyLink)}
							/>
							{isCopied && <small className='copied'>{t('Copied')}</small>}
						</span>
					)}

					<p>
						{t('It will be auto redirected in')} {secondCount} {t('seconds')}
					</p>
				</div>
			</IonContent>
		</IonModal>
	);
};
