import { IonChip, IonIcon, IonItem, IonLabel, IonList } from '@ionic/react';
import DropDownModal from '../../components/common/custom-dropdown/DropDownModal';
import { Divider } from '../../components/common/divider/Divider';
import {
	InputFormatCurrencyValue,
	findCurrentStatus,
	// findStatus,
	// getStatus,
	setBankIcon,
} from '../../helpers/functions';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import {
	LEAD_STATUS,
	LeadStatus,
	lenderApplicationType,
	lenderSpecificLinks,
} from '../../helpers/constant';
import {
	leadFlow,
	markAsClose,
	selectedLender,
} from '../../store/reducers/lead/leadSlice';
import { CustomNoData } from '../../components/common/custom-no-data/CustomNoData';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Clipboard } from '@capacitor/clipboard';
import { useState } from 'react';
import { SessionApiLendersList } from '../../helpers/lenders';

interface Props {
	bankData: any;
	applicationStatusData: any;
	selectedApplicationStatus: any;
	selectApplicationStatusValue: any;
	lenderUiUrl: any;
	lenderOffer: any;
	pendingStepUrl?: any;
}

export const LeadApplications: React.FC<Props> = ({
	bankData,
	applicationStatusData,
	selectedApplicationStatus,
	selectApplicationStatusValue,
	lenderUiUrl,
	lenderOffer,
	pendingStepUrl,
}) => {
	const history: any = useHistory();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	const [isCopied, setCopied] = useState<any>();
	// const onlineLenders = [
	// 	'aditya birla finance ltd.',
	// 	'bajaj finance growth',
	// 	'incred financial services ltd.',
	// 	'lendingkart finance ltd.',
	// 	'tata capital financial services ltd.',
	// ];

	// const isLenderIncludes = (lender: any) => {
	// 	return onlineLenders.includes(lender) ? true : false;
	// };
	const copyText = async (text: any, lenderName: any) => {
		const finalText = text;
		await Clipboard.write({
			string: finalText,
		});
		setCopied(lenderName);
		const clearTime = setTimeout(() => {
			clearTimeout(clearTime);
		}, 3000);
	};
	return (
		<>
			<Divider />
			<div className='m-3 bg-web-white'>
				<div className='mt-2 d-flex justify-content-between align-items-center'>
					<h4 className='fw-bold fs-20'>{t('Applications')}</h4>
					<div
						className=''
						style={{ width: '200px' }}
					>
						<DropDownModal
							data={applicationStatusData}
							onChange={(e: any) => selectedApplicationStatus(e)}
							value={selectApplicationStatusValue?.id}
						/>
					</div>
				</div>
				{bankData.length > 0 ? (
					<div>
						{bankData.map((item: any, index: any) => {
							return (
								<IonList
									lines={bankData.length - 1 !== index ? 'full' : 'none'}
									key={`inner-details-${index}`}
								>
									<IonItem className='your-accounts'>
										<div
											className='border ms-2 d-flex'
											style={{
												width: '48px',
												height: '48px',
												borderRadius: '50%',
												alignSelf: 'start',
												margin: '5px',
											}}
										>
											<img
												style={{
													minWidth: '48px',
													minHeight: '48px',
												}}
												className='selected-lender-bank-icon m-0'
												src={setBankIcon(item?.icon)}
												alt='...'
											/>
										</div>
										<IonLabel className='ion-text-wrap'>
											<p
												style={{ color: '#5D5E72' }}
												className='fs-12 mb-0 fw-600'
											>
												#A{item.id}
											</p>
											{/* {item?.lender_lead_id && (
												<p
													style={{ color: '#5D5E72' }}
													className='fs-14 mb-0 '
												>
													Lender Application ID :&nbsp;
													<span className={`fw-600`}>
														{item?.lender_lead_id}
													</span>
												</p>
											)} */}
											{item?.rm_name && (
												<p
													style={{ color: '#5D5E72' }}
													className='fs-14 mb-0 '
												>
													{t('RM Name')} :
													<span className={`fw-600`}> {item?.rm_name}</span>
												</p>
											)}
											{item?.rm_mobile && (
												<p
													style={{ color: '#5D5E72' }}
													className='fs-14 mb-0 '
												>
													{t('RM Mobile')} :
													<span className={`fw-600`}> {item?.rm_mobile}</span>
												</p>
											)}
											{item?.rm_branch && (
												<p
													style={{ color: '#5D5E72' }}
													className='fs-14 mb-0 '
												>
													{t('RM Branch')} :
													<span className={`fw-600`}> {item?.rm_branch}</span>
												</p>
											)}
											<h5 className='fs-16 fw-600'>{item?.lender_name}</h5>
											{item?.lender_name == 'Aditya Birla Finance Ltd.' &&
												item?.product_category_id == '1' &&
												item?.product_id == '1' &&
												(leadInfo?.leadStatus == 'Login' ||
													leadInfo?.leadStatus == 'Sanctioned') && (
													<p
														style={{ color: '#5D5E72', overflow: 'hidden' }}
														className='fs-14 mb-0 '
													>
														{t('Lender Journey URL')} :
														<small> {'ABFL Link'}</small>
														<IonIcon
															src='/images/icons/copy-icon.svg'
															className='copy_icon ml-2'
															onClick={() =>
																copyText(lenderSpecificLinks?.ABFL_PL, 'abfl')
															}
														/>
														{isCopied == 'abfl' && (
															<small className='copied'>{t('Copied')}</small>
														)}
													</p>
												)}
											{item?.lender_name == 'InCred Financial Services Ltd.' &&
												item?.product_category_id == '1' &&
												item?.product_id == '1' &&
												(leadInfo?.leadStatus == 'Login' ||
													leadInfo?.leadStatus == 'Sanctioned') && (
													<p
														style={{ color: '#5D5E72', overflow: 'hidden' }}
														className='fs-14 mb-0 '
													>
														{t('Lender Journey URL')} :
														<small> {'INCRED Link'}</small>
														<IonIcon
															src='/images/icons/copy-icon.svg'
															className='copy_icon ml-2'
															onClick={() =>
																copyText(
																	lenderSpecificLinks?.INCRED_PL,
																	'incred'
																)
															}
														/>
														{isCopied == 'incred' && (
															<small className='copied'>{t('Copied')}</small>
														)}
													</p>
												)}
											{item?.lender_name == 'Bhanix Finance' &&
												item?.product_category_id == '1' &&
												item?.product_id == '1' &&
												(leadInfo?.leadStatus == 'Login' ||
													leadInfo?.leadStatus == 'Sanctioned') && (
													<p
														style={{ color: '#5D5E72', overflow: 'hidden' }}
														className='fs-14 mb-0 '
													>
														{t('Lender Journey URL')} :
														<small> {'Bhanix Finance Link'}</small>
														<IonIcon
															src='/images/icons/copy-icon.svg'
															className='copy_icon ml-2'
															onClick={() =>
																copyText(
																	lenderSpecificLinks?.BHANIX_PL,
																	'bhanix'
																)
															}
														/>
														{isCopied == 'bhanix' && (
															<small className='copied'>{t('Copied')}</small>
														)}
													</p>
												)}
											{item?.lender_name == 'Fibe' &&
												item?.product_category_id == '1' &&
												item?.product_id == '1' &&
												(leadInfo?.leadStatus == 'Login' ||
													leadInfo?.leadStatus == 'Sanctioned') && (
													<p
														style={{ color: '#5D5E72', overflow: 'hidden' }}
														className='fs-14 mb-0 '
													>
														{t('Lender Journey URL')} :
														<small> {'Fibe Link'}</small>
														<IonIcon
															src='/images/icons/copy-icon.svg'
															className='copy_icon ml-2'
															onClick={() =>
																copyText(lenderSpecificLinks?.FIBE_PL, 'fibe')
															}
														/>
														{isCopied == 'fibe' && (
															<small className='copied'>{t('Copied')}</small>
														)}
													</p>
												)}
											{
												// SessionApiLendersList.includes(
												// 	item?.lender_name?.toLowerCase()
												// ) &&
												lenderUiUrl &&
													(leadInfo?.leadStatus == 'Login' ||
														leadInfo?.leadStatus == 'Sanctioned') && (
														<p
															style={{ color: '#5D5E72', overflow: 'hidden' }}
															className='fs-14 mb-0 '
														>
															{t('Lender Journey URL')} :
															<small> {`${item?.lender_name} URL`}</small>
															<IonIcon
																src='/images/icons/copy-icon.svg'
																className='copy_icon ml-2'
																onClick={() =>
																	copyText(lenderUiUrl, item?.lender_name)
																}
															/>
															{isCopied == item?.lender_name && (
																<small className='copied'>{t('Copied')}</small>
															)}
														</p>
													)
											}

											{lenderOffer &&
												Object.keys(lenderOffer)?.length > 0 &&
												(leadInfo?.leadStatus == 'Login' ||
													leadInfo?.leadStatus == 'Sanctioned') && (
													<div
														style={{ color: '#5D5E72', overflow: 'hidden' }}
														className='fs-14 mb-0 '
													>
														<p>
															<span className='fw-600'>
																{t('Lender Offer')}:
															</span>{' '}
															<IonIcon
																src='/images/icons/copy-icon.svg'
																className='copy_icon ml-2'
																onClick={() => {
																	const highestOffer = `Offer Amount:₹${lenderOffer.offer_amount}\nTenure:${lenderOffer.offer_duration_in_months} Months\nInterest:${lenderOffer.offer_interest_rate}%`;
																	copyText(highestOffer, item?.lender_name);
																}}
															/>
															{isCopied == item?.lender_name && (
																<small className='copied'>{t('Copied')}</small>
															)}
														</p>
														<p>
															Offer Amount:{' '}
															<span className='fw-600'>
																₹{' '}
																{InputFormatCurrencyValue(
																	lenderOffer.offer_amount
																)}
															</span>
														</p>
														<p>
															Tenure:{' '}
															<span className='fw-600'>
																{lenderOffer.offer_duration_in_months} Months
															</span>
														</p>
														<p>
															Interest:{' '}
															<span className='fw-600'>
																{lenderOffer.offer_interest_rate}%
															</span>
														</p>
													</div>
												)}
											{pendingStepUrl &&
												(leadInfo?.leadStatus == 'Login' ||
													leadInfo?.leadStatus == 'Sanctioned') && (
													<p
														style={{ color: '#5D5E72', overflow: 'hidden' }}
														className='fs-14 mb-0 '
													>
														{t('Lender Journey URL')} :
														<small> {`${item?.lender_name} URL`}</small>
														<IonIcon
															src='/images/icons/copy-icon.svg'
															className='copy_icon ml-2'
															onClick={() =>
																copyText(pendingStepUrl, item?.lender_name)
															}
														/>
														{isCopied == item?.lender_name && (
															<small className='copied'>{t('Copied')}</small>
														)}
													</p>
												)}
											{item?.bank_app_no && (
												<p
													style={{ color: '#5D5E72' }}
													className='fs-14 mb-0 '
												>
													{t('Bank App. No.')} :
													<span className={`fw-600`}> {item?.bank_app_no}</span>
												</p>
											)}
											{item?.lan && (
												<p
													style={{ color: '#5D5E72' }}
													className='fs-14 mb-0'
												>
													{t('LAN')}:{' '}
													<span className={`fw-600`}>{item?.lan}</span>
												</p>
											)}
											{item?.sanction_amount && (
												<p
													style={{ color: '#5D5E72' }}
													className='fs-14 mb-0'
												>
													{t('Sanctioned Amount')}:{' '}
													<span className='fw-600'>
														{`₹ ${InputFormatCurrencyValue(
															item?.sanction_amount
														)}`}
													</span>
												</p>
											)}
											{item?.total_disbursal_amount && (
												<p
													style={{ color: '#5D5E72' }}
													className='fs-14 mb-0'
												>
													{t('Total Amount Disbursed')}:{' '}
													<span className='fw-600'>
														{`₹ ${InputFormatCurrencyValue(
															parseInt(item?.total_disbursal_amount)
														)}`}
													</span>
												</p>
											)}
											<CustomButton
												size={'extra small'}
												fill='outline'
												className={'text-wrap'}
												disabled={
													false
													// item?.app_off_status === 'offline'
													// 	? false
													// 	: isLenderIncludes(item.lender_name?.toLowerCase())
													// 	? item?.lender_form_page_status_flag == 1
													// 	: findCurrentStatus(item.status).label ===
													// 	  LEAD_STATUS.CLOSED_LABEL
												}
												onClick={() => {
													if (
														findCurrentStatus(item.status).label !==
														LEAD_STATUS.CLOSED_LABEL
													) {
														let path = '';
														if (
															item.status == LeadStatus.PARTIALDISBURSED ||
															item.status == LeadStatus.DISBURSED
														) {
															path = '/sanction-and-disbursed-detail';
														} else {
															if (leadInfo?.from === 'nimbuss') {
																path = '/update-status?from=nimbuss';
															} else if (
																item.app_off_status?.toLowerCase() === 'online'
															) {
																if (
																	item.status ===
																	LeadStatus.APPLICATIONSFULFILLED
																) {
																	if (
																		(item?.lender_name === 'L&T Finance' &&
																			item?.product_category_id == 1) ||
																		(item?.lender_name === 'Piramal Capital' &&
																			item?.product_category_id == 4) ||
																		(item?.lender_name === 'Piramal Capital' &&
																			item?.product_category_id == 3)
																	) {
																		path = '/verify-lender-with-otp';
																	} else if (
																		item?.lender_form_page_status_flag == 2
																	) {
																		path = '/verify-lender-with-otp';
																	} else if (
																		item?.lender_form_page_status_flag == 3
																	) {
																		path = '/rm-selection-form';
																	} else if (
																		item?.lender_form_page_status_flag == 4
																	) {
																		path = '/lender-specific-info';
																	} else if (
																		item?.lender_form_page_status_flag == 1 &&
																		item?.product_category_id == 1
																	) {
																		path = '/pl-common-fields-form';
																	} else if (
																		item?.lender_form_page_status_flag == 1 &&
																		item?.product_category_id == 2
																	) {
																		path = '/bl-common-fields-form';
																	} else if (
																		item?.lender_form_page_status_flag == 1 &&
																		item?.product_category_id == 3
																	) {
																		path = '/hl-common-fields-form';
																	} else if (
																		item?.lender_form_page_status_flag == 1 &&
																		item?.product_category_id == 4
																	) {
																		path = '/lap-common-fields-form';
																	} else {
																		path = '/verify-lender-with-otp';
																	}
																} else {
																	path = '/update-status';
																}
															} else {
																path = '/update-status';
															}
														}

														dispatch(markAsClose(false));
														dispatch(
															leadFlow({
																...leadInfo,
																applicationStatus: findCurrentStatus(
																	item.status
																).label,
																backUpdateStatus: findCurrentStatus(item.status)
																	.label,
																redirectToDetailPage: true,
																update: leadInfo.update + 1 || 1,
																disbursedLenderDetails: item,
																getLeadAllSelectedIds: [],
																appsToUpdate: [],
																iSBackFromDsburseAndSanction: false,
																isFromDisbursed: false,
																isBackLenderOnlineForm: true,
															})
														);
														dispatch(selectedLender([item]));
														history.push(path);
													}
												}}
											>
												{t(findCurrentStatus(item.status).label)}
												{findCurrentStatus(item.status).label !==
													LEAD_STATUS.CLOSED_LABEL && (
													<img
														className='ps-2'
														src='images/icons/edit.svg'
														alt='edit'
													/>
												)}
											</CustomButton>
										</IonLabel>
										<IonLabel
											slot='end'
											className='m-0 align-self-start position-absoulte right-0'
											// style={{ alignSelf: 'start' }}
										>
											{item.app_off_status?.toLowerCase() === 'online' ? (
												<IonChip
													className={`fs-12 chip-online`}
													style={{
														padding: '8px 8px 8px',
														height: '23px',
														opacity: 1,
													}}
												>
													{t(lenderApplicationType.ONLINE)}
												</IonChip>
											) : (
												<IonChip
													disabled={true}
													className={`fs-12 chip-offline`}
													style={{
														padding: '8px 8px 8px',
														height: '23px',
														opacity: 1,
													}}
												>
													{t(lenderApplicationType.OFFLINE)}
												</IonChip>
											)}
										</IonLabel>
									</IonItem>
								</IonList>
							);
						})}
					</div>
				) : (
					<CustomNoData
						className='py-3'
						message={t('No Data Found')}
					/>
				)}
			</div>
		</>
	);
};
