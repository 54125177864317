//// @ts-nocheck

import React, { useState } from 'react';
import { IonItem, IonLabel } from '@ionic/react';
import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { CustomChip, CustomChipLabel, ErrorMessage } from './choice_chip.style';
import { useTranslation } from 'react-i18next';

interface Options {
  value: string;
  description: string;
}

interface Values {
  title: string;
  name: string;
  mandatory?: boolean;
  options: Options[];
  values?: string[];
  error?: FieldErrors;
  setValueFunc: UseFormSetValue<any>;
  onChange?: (val?: any) => void;
}

export const MultiChoiceChip: React.FC<Values> = ({ title, values = [], name, onChange, error, options, mandatory = false, setValueFunc }) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(values);
  const { t } = useTranslation();

  const handleValueChange = (value: string) => {
    let updatedValues = [];
    if (selectedValues.includes(value)) {
      updatedValues = selectedValues.filter(selectedValue => selectedValue !== value);
    } else {
      updatedValues = [...selectedValues, value];
    }

    setSelectedValues(updatedValues);
    setValueFunc(name, updatedValues);

    if (onChange) {
      onChange(updatedValues);
    }
  };

  const checkError = (): boolean => {
    if (error && mandatory) {
      if (selectedValues.length === 0) {
        return true;
      }
    }
    return false;
  };

  return (
    <div style={{ paddingBottom: '8px', paddingTop: '8px' }}>
      <IonItem>
        <IonLabel color={checkError() ? 'danger' : ''}>{title}</IonLabel>
      </IonItem>
      {options.map(({ value, description }) => (
        <CustomChip
          id={name}
          key={`${name}-${value}`}
          selected={selectedValues.includes(value)}
          onClick={() => handleValueChange(value)}
        >
          <CustomChipLabel selected={selectedValues.includes(value)}>{t(description)}</CustomChipLabel>
        </CustomChip>
      ))}
      {error && error.message && checkError() && (
        <div>
          <ErrorMessage>{t(error.message as unknown as string)}</ErrorMessage>
        </div>
      )}
    </div>
  );
};
