import React from 'react';
import { Header } from '../../components/layout/header/Header';
import {
	IonContent,
	IonList,
	IonLabel,
	IonItem,
	IonAccordionGroup,
	IonAccordion,
} from '@ionic/react';
import './CommanInfo.scss';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
import { CustomUpload } from '../../components/common/custom-upload/CustomUpload';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
export const CommanInfo: React.FC = () => {
	const { t } = useTranslation();
	return (
		<>
			{/* <Menu /> */}
			<Header
				name={t('Comman Info')}
				needArrow={true}
				backButtonDisabled={false}
				backButton={true}
			/>
			<IonContent>
				<IonList>
					<IonAccordionGroup value={'acc'}>
						<IonAccordion
							className={'my-3'}
							value={'acc'}
						>
							<IonItem
								slot='header'
								color=''
							>
								<IonLabel className='fs-14 fw-600'>Section 1</IonLabel>
							</IonItem>
							<div
								className='ion-padding'
								slot='content'
							>
								<IonItem
									className='ion-no-padding'
									lines='none'
								>
									<CustomInput
										labelPlacement='stacked'
										label='Floating Label'
										placeholder='Field 1'
									/>
								</IonItem>
								<IonItem
									className='ion-no-padding'
									lines='none'
								>
									<CustomInput
										labelPlacement='stacked'
										label='Floating Label'
										placeholder='Field 2'
									/>
								</IonItem>
								<IonItem
									className='ion-no-padding'
									lines='none'
								>
									<CustomInput
										labelPlacement='stacked'
										label='Floating Label'
										placeholder='Field 3'
									/>
								</IonItem>
								<IonItem
									className='ion-no-padding'
									lines='none'
								>
									<CustomInput
										labelPlacement='stacked'
										label='Floating Label'
										placeholder='Field 4'
									/>
								</IonItem>
								<IonItem
									className='ion-no-padding'
									lines='none'
								>
									<CustomInput
										labelPlacement='stacked'
										label='Floating Label'
										placeholder='Field 5'
									/>
								</IonItem>
							</div>
						</IonAccordion>
					</IonAccordionGroup>

					<IonAccordionGroup className='pb-180'>
						<IonAccordion
							className={'my-3'}
							value={'acc'}
						>
							<IonItem
								slot='header'
								color=''
							>
								<IonLabel className='fs-14 fw-600'>
									{t('Upload Document') + 's'}
								</IonLabel>
							</IonItem>
							<div
								className='ion-padding'
								slot='content'
							>
								<IonLabel className='fs-14 fw-600 mb-1'>Document 1</IonLabel>
								<IonItem
									className='ion-no-padding'
									lines='none'
								>
									<CustomUpload />
								</IonItem>
							</div>
						</IonAccordion>
					</IonAccordionGroup>
				</IonList>
				<div className='bottom-button-setter py-3'>
					<CustomButton
						className='w-100'
						fill='outline'
						title={t('Continue Later')}
					/>
					<CustomButton
						routerLink='/specific-lenders-info'
						expand='block'
						title={t('Proceed')}
					/>
				</div>
			</IonContent>
		</>
	);
};
