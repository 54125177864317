// LOGIN/LOGOUT APIs
// export const CRM_API_ENDPOINT = "https://crmdevapi.dromecrm.com/";
export const CRM_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
console.log('crm CRM_API_ENDPOINT>>', CRM_API_ENDPOINT);
export const LOGIN = 'user/login';
export const VERIFYOTP = 'user/verifyotp';
export const REFRESH_TOKEN = 'user/token';
// Dashboard API
export const DASHBOARD_DATA = 'user/homepage';
export const NOTIFICATION = 'user/notification';
export const MYIDCARD = 'user/myids';
export const URLUPDATE = 'user/url';
export const DOWNLOADAGREEMENT = 'user/digiodirectdownload';
export const UPDATE_NOTIFICATION_STATUS = 'user/update_notif_status';
export const USER_DEACTIVATE = 'user/partner/deactivate_user';
export const DELETE_USER = 'user/';

// LEAD APIs
export const DASHBOARD_LEAD_COUNTS = 'lead/lead_dashboard_count';

export const INSURANCE_API_ENDPOINT = process.env.REACT_APP_INSURANCE_API;
console.log('crm INSURANCE_API_ENDPOINT', INSURANCE_API_ENDPOINT);

// export const INSURANCE_API_ENDPOINT = "https://insuranceapi.dromecrm.com:8184/";

//Insurance Lead Dashboard API's
// export const INSURANCE_DASHBOARD_LEAD_COUNTS =
// 	process.env.INSURANCE_API + 'insurance/leadstatusdetails';

export const INSURANCE_DASHBOARD_LEAD_COUNTS =
	INSURANCE_API_ENDPOINT + 'insurance/leadstatusdetails';

// export const INSURANCE_DASHBOARD_LEAD_COUNTS =
// 	'https://insuranceapi.oneandro.com:8184/' + 'insurance/leadstatusdetails';

//Insurance consent form API's
export const INSURANCE_CONSENT_GENERATE_OTP =
	CRM_API_ENDPOINT + 'lead/insurance_generate_otp';
export const INSURANCE_CONSENT_VERIFY_OTP =
	CRM_API_ENDPOINT + 'lead/insurance_verify_otp';
export const UPDATE_VERIFICATION_STATUS =
	INSURANCE_API_ENDPOINT + 'insurance/updateverificationstatus';

//process.env.REACT_APP_API_ENDPOINT

export const DASHBOARD_LEAD_PRODUCTS_TYPE = 'lead/product';
export const DASHBOARD_LEAD_DETAILS = 'lead/details/';
export const DASHBOARD_LEAD_DETAILS_UM = 'lead/data/';
export const DASHBOARD_LEAD_List = 'lead/leadlist';
export const DASHBOARD_UPDATE_LEAD = 'lead/update_lead/';
export const DASHBOARD_LIFE_ASSURED = 'lead/life_assured';
export const DASHBOARD_LIFE_ASSURED_UPDATE = 'lead/update_life_assured';
export const DASHBOARD_LEAD_GENERATE_OTP = 'lead/generateotp';
export const DASHBOARD_LEAD_VERIFY_OTP = 'lead/verifyotp/';
export const DASHBOARD_LEAD_STATUS_UPDATE = 'lead/bulkUpdateLeadStatus';
export const DASHBOARD_ADD_LEAD_DISPOSITION = 'lead/loan/add_activity';
export const DASHBOARD_LEAD_ACTIVITY = 'lead/activity/';
export const DASHBOARD_GET_ANDRO_MANAGER = 'user/manager';
export const ADD_LEADS_MANAGER_LIST = 'lead/andro_lead_manager';
export const RESONSLISTDATA = 'lead/reasons_list_data';
export const DMAACTIVITY = 'user/dma_activity/';
export const PROFESSIONALINFO_FIELDS = 'lead/fields_data/';
export const USERVERIFICATIONDETAIL = 'user/partner/user_verification_detail/';
export const ADDLEADCOMMENTS = 'lead/add_comments/';
export const VIEWLEADCOMMENTS = 'lead/view_comments/';
export const USERENOTIFICATION = 'user/enotification/';
export const APPROVEUSERSTATUS = 'user/approve_user_status/';
export const USERVERIFICATIONPAGE = 'user/partner/user_verification_page/';
export const USERLEADMANAGER = 'user/lead_manager_info';
export const LEADS_MASTER_DISPOSITIONS = 'user/master_api/DISPOSITION';
export const LEADS_MASTER_DISPOSITIONS_NEW = 'user/master/lead_disposition';
export const LEADS_MASTER_DISPOSITIONS_REASON = 'user/master/disposition/';
export const LEAD_LOAN_SANCTIONED = 'lead/loan_sanctioned/';
export const LEAD_ADD_DISBURSAL = 'lead/add_disbursal/';
export const LEAD_LENDER_CALL_BACK = 'lead/lender_call_back/';
export const LEADS_CALL_INITIATED = 'lead/call_history/';
export const GET_ALL_LEAD_STATUS = '/lead/all_status';
export const GET_LENDERS_USER_INFO = 'lead/get_lenders_user_info/';
export const GENERATE_ONLINE_LEAD = 'lead/lead_generate/';
export const LEAD_ALLOCATOR_ALLOCATE_MANAGER = 'lead/manager/assign/';
export const GET_CITYWISE_RM_LIST = '/rmlender/get_rm_by_city';
export const GET_ALL_CITY = 'user/get_city_data';
export const UPDATE_RM_DETAILS = 'lead/rm_city_update';
export const GET_RM_DETAILS =
	'https://lkext.lendingkart.com/admin/lead/v2/partner/leads/rm-mapping';

//Lender APIs
export const LEAD_CLOSE = 'lender/lead_close';
export const LEAD_CLOSE_API = 'lead/close';

//TATA
export const LENDER_TATA_PL_GENERATE_OTP = 'lender/tataplgenerateotp';
export const LENDER_TATA_PL_VERIFY_OTP = 'lender/tataplvalidateotp';
export const LENDER_PARTNER_ELIGIBLITY = 'lender/partner_eligibility';
export const LENDER_SECOND_PARTNER_ELIGIBLITY = 'lender/second_eligibility';
export const LENDER_UPDATE_LEAD = 'lender/update_pl_lead';
export const LENDER_BANK_STMT = '/lender/get_bank_stmt_id';
export const LENDER_TATA_UPLOAD_DOCUMENT = '/lender/upload_pl_document';
export const LENDER_PNB_UPLOAD_DOCUMENT = '/lead/pnb_document_upload';
export const LENDER_TATA_CKYC = '/lender/getckycdata';
export const CREATE_DOCKET = '/lender/creategendocket';
export const GET_APPLICATION_STATUS = '/lender/get_application_status';

// BAJAJ

export const BAJAJ_TOKEN_GENERATE = 'lead/get_Bajaj_Token';
export const BAJAJ_LEAD_ASSIGN = 'lead/lead_assign';
export const BAJAJ_RM_LIST = 'lead/get_Bajaj_Userlist';
export const BAJAJ_CREATE_LOAN = 'lead/bajaj_create_Loan';
export const BAJAJ_UPLOAD_DOC = 'lead/bajaj_upload_doc';
export const BAJAJ_LEAD_STATUS = 'lead/bajaj_get_Status';
// KYC APIs
export const KYC_BASIC_INFO = 'user/partner/basic_info/';
export const KYC_BASIC_INFO_KYC = 'user/partner/basic_info_kyc/';
export const KYC_DOCUMENT_UPLOAD = 'user/partner/document_upload';
export const KYC_DOCUMENT_UPLOAD_PROFESSIONAL_DETAIL =
	'user/partner/update_address';
export const KYC_DOCUMENT_UPLOAD_STEP_FOUR = 'user/partner/update_bank';
export const KYC_DOCUMENT_UPLOAD_STEP_THREE = 'user/update_gst';
export const KYC_USER_UPLOAD_IMAGE = 'user/upload';
export const CHECK_IFSC_CODE = 'user/partner/check_ifsc_code';
export const KYC_LEAD_PRODUCT = 'lead/product';
export const KYC_USERPROFESSION = 'user/master_api/userprofession';
export const KYC_ADDRESSPROOF = 'user/master_api/ADDRESSPROOF_TYPE';
export const ESIGNCHECK = 'user/esignCheck';

// Lenders API
export const GET_ALL_LENDERS = 'lead/rule_engine';
export const GET_ALL_LAS_LENDERS = 'las/rule_engine_LAS';
export const ADD_LENDERS = 'lead/product/add_lead_application';
export const SELECTED_LENDERS = 'lead/product/getLeadAppData';
export const REMOVE_SELECTED_LENDERS = 'lead/product/remove_lead_application';
export const GET_NON_SELECTED_LENDERS = 'lead/product/getLeadLenderDataAdd';
export const MARK_AS_CLOSE = 'lead/mark_as_close';
export const MARK_AS_LOGIN = 'lead/mark_as_login/';
export const GET_LENDER_DETAILS = '/lead/application';
export const GET_SANCTIONED_DISBURSED_DETAILS = '/lead/get_disbursal_detail';
export const GET_LEAD_DISBURSAL_DATA = '/lead/disbursal_data';
export const GET_LOGIN_SANTIONED_DATA = '/lead/login_sactioned_count_details';
export const GET_LENDER_WEB_LINK = '/company/get_lender';
export const GET_LENDER_SITE_LINK = '/user/get_lender_subproduct_url';

// Helpdesk API
export const HELPDESK = 'user/helpdesk';
export const HELPDESKFAQ = 'user/partner/get_faq';

// Bureau APIs
export const FETCH_EQUIFAX_BUREAU_REPORT = 'lead/equifax/';
export const FETCH_CIBIL_BUREAU_REPORT = 'lead/cibil/get_assets';
export const DOWNLOAD_EQUIFAX_BUREAU_REPORT = 'lead/download_equifax_report';
export const VERIFY_CIBIL = 'lead/cibil/fulfilloffer';
export const GENERATE_CIBIL_OTP = 'lead/cibil';
export const EQUIFAXDETAILS = 'lead/equifaxDetail/';
export const CIBILDETAIL = 'lead/cibil/cibilDetail/';
export const EQUIFAX_ADDITIONAL_INFO = 'lead/equifax_additional_info/';
export const EQUIFAX_ADDITIONAL_INFO_DETAILS =
	'lead/equifax_additional_info_details/';
export const PROCESSINGBEGIN = 'lead/processingbegin';
export const DOWNLOAD_BULK_LEAD_UPLOAD_TEMPLATE = '/lead/lead_demo_download';
export const CHECK_SCORE_VIDEO = 'user/check_score';

// Home Page API
// for dashboard home use => DASHBOARD_HOME this prefix
export const DASHBOARD_HOME_MYLEAD_COUNTS = 'lead/lead_count';
export const DASHBOARD_HOME_MYLOYALTYREWARDS = 'user/loyalty';
export const DASHBOARD_HOME_MYLOYALTYDETAILS = 'user/loyalty/rewards';
export const DASHBOARD_HOME_EARNINGS = 'lead/earnings';
export const GENERATE_MOODLE_LINK = 'user/training_sessions';
export const GET_USER_SOCIAL_LINKS = 'user/links';

//Language API
export const CHOOSE_LANGUAGE_LIST = 'user/lang';
export const CHANGE_LANGUAGE = 'user/changelang';

// common components API
export const TERMSANDCONDITIONS = 'user/term';
export const PRIVACYPOLICY = 'user/policy';

//product list API
export const PRODUCT_LIST = 'lead/product_list';
export const ADD_LEAD = 'lead/add_lead';

//individual and firm Registaration API
export const CONSTITUTION = 'user/constitution';
export const CONTRIBUTION_PIN_CODE = 'user/partner/check_pincode';
export const PARTNER_REGISTRATION = 'user/partner/user_registration';
export const PARTNER_REGISTRATION_DETAILS = 'user/partner/regi_detail';
export const PANCARDVALID = 'user/partner/check_user_exists';
export const DIGIO = 'user/digio';
export const INVITREFERRAL = 'user/invite_referral';

// Teams Dashboard
export const TEAM_DASHBOARD_USER_COUNT = 'user/count';
export const TEAM_DASHBOARD_USER_COUNT1 = 'user/count/1';
export const TEAM_DMA_INVITE = 'user/dma_invite';
export const TEAM_DMA_USER_INVITE = 'user/invite';
export const TEAM_DMA_ABOUT = 'user/about/';
export const TEAM_DMA_BY_ID = 'user/get_user_by_id/';
export const TEMA_DMA_PARENT_CHILD = 'user/parentchild/';
export const TEAM_DMA_REJECT_REVISE = 'user/reject_revise_users/';
export const TEAM_DMA_LIST = 'user/dma_list';
export const TEAM_DMA_VERIFY_OTP = 'user/verifyotp/newDMA';
export const TEAM_DMA_SUBPRODUCTS = 'lead/subproduct_list';
export const TEAM_DMA_CREATE_PAYOUT = 'user/payout/create_payout/';
export const TEAM_DMA_UPDATE_PAYOUT = 'user/payout/payout_update/';
export const TEAM_DMA_OWNER_LIST = 'user/owner_list';
export const TEAM_DMA_USER_LIST = 'user/user_list';
export const INVITE_ANDRO_EMPLOYEE = 'user/partner/invite_andro_employee';
export const REINVITE_ANDRO_EMPLOYEE = 'user/partner/reinvite_andro_employee';
export const PAYOUT_REJECT_REASON = 'user/reason/all_reason';
export const BOPS_MANAGER_ASSIGN_LIST = 'user/central_childlist';
export const COPS_MANAGER_ASSIGN_LIST = 'user/central_managerlist';
export const USER_ALLOCATE_MANAGER = 'user/allocate_manager/';
export const LEAD_ALLOCATE_MANAGER = 'lead/update_assignee/';
export const ALLOCATOR_ALLOCATE_MANAGER = 'user/manager';
export const CHECK_USER_MOBILE = 'user/check_mobileNumber';
export const USER_EFRANKING = 'user/efranking/';
export const PAYOUTPRODUCTINFO = 'user/payout/product_payout_info/';
export const PARTNER_USER_VERIFICATION = 'user/partner/user_verification/';
export const VERIFY_BANK_USER = 'user/verify_bank/';
export const PARTNER_USER_VERIFICATION_DETAIL =
	'user/partner/user_verification_detail/';
export const DMA_ANALOGY = 'user/payout/analogy/';
export const USER_NAME = 'user/user_name?mobile';
export const DISBURSED_COUNT = '/lead/disbursedcount/';

//Profession form

export const TEAM_DMA_DUE_DILLIGENEC = 'user/dma_approval/';
export const TEAM_DMA_PAYOUT_LIST = 'user/payout/payout_list/';
export const TEAM_DMA_PAYOUT_LIST_2 = 'user/payout/product_payout_info/';
export const TEAM_DMA_PAYOUT_FINALIZE = 'user/payout/payout_finalize';
export const PROFESSIONALDETAIL_MASTERS = 'user/master_api/';
export const COMPANYNAMELIST = 'company/company_name_list';
export const CITYNAMELIST = 'user/get_city_data';
export const USERNAMELIST = 'mis/user_data_role_base';

export const BANKSNAMELIST = 'company/bank_name_list';
export const UPLOAD_HISTORY_LIST = 'lead/bulk_lead_history';
export const DOWNLOAD_HISTORY = '/lead/download_file';

// Get Roles
export const GET_ALL_ROLES = 'user/role/all_role';

// Nimbus
export const GET_NIMBUS_IP_DORPDOWN = 'user/master_api/NIMBUS_IP';

// Filters

export const FILTER_DMA_TYPE_DATA = 'user/master_api/DMA_TYPE';
export const FILTER_DMA_REL_DATA = 'user/master_api/DMA_RELATIONSHIP';

// Dropdown
export const GENDER_DROPDOWN = 'user/master_api/GENDER';
export const OCCUPATION_DROPDOWN = 'user/master_api/OCCUPATION';
export const MARTIAL_STATUS_DROPDOWN = 'user/master_api/MARITAL_STATUS';
export const QUALIFICATION = 'user/master_api/QUALIFICATION';
export const BAJAJ_NATURE_OF_BUSINESS =
	'user/master_api/BAJAJ_NATURE_OF_BUSINESS';
export const ALL_RELIGION = 'user/master_api/ALL_RELIGION';
export const POLICY_STATUS_DROPDOWN = 'user/master_api/POLICY_STATUS';
export const APPLICATION_STATUS_DROPDOWN = 'user/master_api/APPLICATION_STATUS';
export const FAQ_DROPDOWN = 'user/master_api/FAQ';
export const MIS_DROPDOWN = 'mis/list_of_mis_services';
export const MIS_FILTER_DROPDOWN = 'mis/mis_search_filters';
export const MIS_EXPORT = 'mis/report/';

export const CIBIL_CONSENT_DETAILS = 'lead/cibil_consent_details/';
export const CIBIL_REQUEST_CONSENT = 'lead/request_consent/';
export const CIBIL_CONSENT_UPDATE = 'lead/cibil_consent_update/';
export const DISBURSED_DROPDOWN = 'user/master_api/REPORT_MONTH';

export const UPLOAD_BULK_LEADS = 'lead/add_bulk_lead';
// Products

export const LENDERS_BY_SUBPRODUCT = '/user/lenders_by_subprod';
export const ALL_LENDERS = '/company/all_lenders';
export const GET_LENDER_DETAILS_SB = '/user/get_lender_details';

export const REPORTING_MANAGER_LIST = '/user/reporting_manager_list';
export const GET_LENDER_LOANS = '/user/get_lender/';
export const GET_CONSULTANTS = '/user/consultant_list';
export const GET_CITIES = '/user/get_cities?city=';
export const GET_CITIES2 = '/user/location/get_location';
export const DESIGNATION_LIST = '/user/designation/get_designation';
export const BULKUPLOAD_NEW_LEAD_TEMPLATE_DOWNLOAD =
	'/lead/onlyleadDemoBulkUploadDownload';
export const BULK_UPLOAD_NEW_LEAD = 'lead/LeadbulkUpload';
export const BULKUPLOAD_ERROR_SHEET = 'lead/bulkUpload_error_sheet/';
export const BULKUPLOAD_NEW_ERROR_SHEET =
	'lead/only_lead_bulkUpload_error_sheet/';

export const CUSTOMER_SERVICE = 'lead/utm_token';
export const GET_LEAD_FLOW_URL = '/user/get_url';

//Common form
export const GET_COMMON_FIELDS_PL = 'lead/common_fields';
export const POST_COMMON_FIELDS_PL = 'lead/lead_generate';
export const FETCH_COMMON_FIELDS_PL = 'lead/common_data_fetch';
export const GET_LENDER_PAGE_WISE_STATUS = 'lead/lender_page_wise_status';

// LENDER SPECIFIC APIs
export const LENDER_SPFC_FETCH_INFO = '/lead/lender_spf_fetch_details';
export const LENDER_SPFC_ADD_INFO = '/lead/lender_spf_add_detail';
export const ABFL_REGULATORY = 'lead/abfl_regulatory';
export const LENDER_GET_INDUSTRY = '/lead/lender_industry_fetch';
export const LENDER_GET_SUB_INDUSTRY = '/lead/lender_sub_industry_fetch';
export const LENDER_GENERATE_OFFER = '/lead/lender_generate_offer';
export const LENDER_ACCEPT_OFFER = '/lead/lender_accept_offer';
export const LENDER_ELIGIBLITY_CHECK = '/lead/lender_eligiblity_check';
export const LENDER_DOCUMENT_UPLOAD = '/lead/lender_document_upload';
export const LENDER_GENERATE_OTP = '/lead/lender_generate_otp';
export const LENDER_VERIFY_OTP = '/lead/lender_verify_otp';
export const FETCH_MASTER_DATA = '/user/master_api/';
export const LEAD_STATUS_CHECK = 'lead/lender_fetch_status';
export const APPLICATION_STATUS = 'lead/money_view/application_status';
export const LENDER_BANK_CONNECT = 'lead/lender_bank_connect';
export const LENDER_GST_CONNECT = 'lead/lender_gst_connect';
export const LENDER_GET_OFFER = 'lead/lender_get_offer';
export const LENDER_FETCH_SESSION = 'lead/lender_fetch_session';
export const LENDER_PERFIOS_DOC_UPLOAD = 'lead/lender_perfios_doc_upload';
export const LENDER_PERFIOS_FETCH_STATUS = 'lead/lender_perfios_fetch_status';
export const LENDER_PRODUCT_FETCH = 'lead/lender_product_fetch';
export const LENDER_SUB_PRODUCT_FETCH = 'lead/lender_sub_product_fetch';
export const LENDER_LOAN_PURPOSE_FETCH = 'lead/lender_loan_purpose_fetch';
export const LENDER_BRANCH_FETCH = 'lead/lender_branch_fetch';
export const LENDER_PINCODE_DETAILS_FETCH = 'lead/lender_pincode_detail_fetch';
export const PNB_FILE_DOWNLOAD = 'lead/pdf_download';
export const PNB_ESIGN_GET_STATUS = 'lead/esign_get_status';
export const PNB_ESIGN_GET_DOCUMENT = 'lead/esign_get_document';
export const PNB_PAYMENT_STATUS = 'lead/payment_status';
export const PNB_PAYMENT_DECRYPT = 'lead/payment_decrpt';
export const PNB_ESIGN_PAYMENT_LINK = 'lead/payment_link_generate';
export const LENDINGKART_RM_LIST = 'lead/lending_kart_rm_list';
export const LENDER_RM_FETCH = 'lead/lender_rm_detail_fetch';
export const MONEYTAP_LENDER_LINK = 'lead/moneytap/ApplicationStatus';
export const LENDER_OFFERS_FETCH = 'lead/lender_offers_fetch';
// export const NATURE_OF_BUSINESS = '/user/master_api/';

// Lender Migration Upload
export const LEAD_MIGRATION = '/lead/lead_migration';

//Pan no apis - insurance
export const LEAD_ENC_PAN = '/lead/encrypt_lead_data';
export const LEAD_DEC_PAN = '/lead/decrypt_lead_data';

//LAS
export const LAS_PORTFOLIO_UPDATE_DETAILS =
	'/las/lead-portfolio-trn/update-details';
// export const LAS_CUSTOMER_UPLOAD  = CRM_API_ENDPOINT+'/las/CustomerISINUpload';
export const LAS_CUSTOMER_UPLOAD = '/las/CustomerISINUpload';
export const LAS_OVERWRITE_DATA = '/las/CustomerISINUpload';
