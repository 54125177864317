/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Header } from '../../components/layout/header/Header';

import {
	IonCard,
	IonContent,
	IonGrid,
	IonRow,
	IonCol,
	IonImg,
	IonPage,
	IonFooter,
	IonCardContent,
	IonLabel,
	IonCheckbox,
	IonButtons,
	IonSpinner,
	isPlatform,
} from '@ionic/react';
import './AllLenders.scss';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { CustomSearchbar } from '../../components/common/custom-searchbar/CustomSearchbar';
import { useDispatch, useSelector } from 'react-redux';
import {
	addLendersList,
	getAllLendersList,
	getNonSelectedLendersList,
	lenderListData,
	addMoreLenders,
	leadFlow,
	activeLender,
	getLeadFlowUrl,
	getSelectedLendersList,
	getAllLASLendersList,
} from '../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
// import { findStatus } from '../../helpers/functions';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
import {
	PageExitPopUp,
	ShareData,
	eventAttributes,
	notifyHandler,
	sanitizeHtml,
	setBankIcon,
} from '../../helpers/functions';
import { CustomNoData } from '../../components/common/custom-no-data/CustomNoData';
import CustomAllocateManagers from '../../components/common/CustomAllocateManagers';
import { NotifyVisitors } from 'capacitor-plugin-notifyvisitors';

export const AllLenders: React.FC = () => {
	const history = useHistory();
	const [selectedData, setSelectedData] = useState<any>([]);
	const [filteredData, setFilteredData] = useState<any>([]);
	const [showPageExitPopup, setShowPageExitPopup] = useState(false);
	const [value, setValue] = useState('');
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	const newLeadApplicantSelector = useSelector(
		(state: any) => state?.lead?.added_lead?.newLoanApplication
	);
	const activeLendesAfterSearch = useSelector(
		(state: any) => state?.lead?.activeLender
	);
	const addMoreLendersKey = useSelector(
		(state: any) => state.lead.addMoreLenders
	);
	const [showAllocateManagerModal, setShowAllocateManagerModal] =
		useState<boolean>(false);
	const [url, setUrl] = useState('');
	const [proceedBtn, setProceedBtn] = useState(false);
	const [spinner, setSpinner] = useState(false);
	// Notify Visitors
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const userName = useSelector(
		(state: any) => state?.users?.homepage?.profile?.name
	);
	const userMobile = useSelector(
		(state: any) => state?.users?.user_details?.mobile
	);

	useEffect(() => {
		if (location?.pathname !== '/lenders-list') {
			return;
		}
		getAllLenders();
		getSelectedLenderList();
	}, [leadInfo?.updateLeadProcess, location.pathname]);

	const entityType = useSelector(
		(state: any) => state?.users?.user_details?.entityType
	);

	const userLang = useSelector(
		(state: any) => state?.oneTimeScreen?.language?.name
	);

	const userAttributes = {
		userId: userId,
		attributes: {
			name: userName,
			id: userId,
			mobile: userMobile,
			user_language: userLang,
			entityType:
				entityType === 'Individual'
					? 'DMA'
					: entityType === 'BTM'
					? 'employee'
					: entityType,
		},
	};
	useEffect(() => {
		if (isPlatform('android') || isPlatform('ios')) {
			notifyHandler('event', eventAttributes('personal_loan_lenders'));
			notifyHandler('user', userAttributes.attributes);
			const inAppData = {
				tokens: { name: userAttributes },
				customRules: { banerrType: 'personal loan lenders' },
			};
			NotifyVisitors.show(inAppData);
		} else {
			(window as any).nv('event', 'personal_loan_lenders', 10, 1);
			(window as any).nv('user', userId, userAttributes.attributes);
		}
	}, []);

	const getAllLenders = async () => {
		setSelectedData([]);
		setFilteredData([]);
		if (addMoreLendersKey || leadInfo?.isLenderSelected) {
			await dispatch(
				getNonSelectedLendersList({
					param:
						'/' +
						(leadInfo?.leadId
							? leadInfo?.leadId
							: newLeadApplicantSelector?.id) +
						`?searchItem=${sanitizeHtml(encodeURIComponent(value))}`,
				})
			)
				.then(unwrapResult)
				.then(async (response: any) => {
					if (response?.status) {
						setSelectedData(response?.lenderDataAdd);
						setFilteredData(response?.lenderDataAdd);
						await dispatch(getLeadFlowUrl())
							.then(unwrapResult)
							.then((result: any) => {
								if (result?.status) {
									setUrl(result?.url);
								}
							});
					}
				});
		} else {
			setSpinner(true);

			if (leadInfo?.product_category_id == '7') {
				await dispatch(
					getAllLASLendersList({
						param:
							'/' +
							(leadInfo?.leadId
								? leadInfo?.leadId
								: newLeadApplicantSelector?.id),
					})
				)
					.then(unwrapResult)
					.then(async (response: any) => {
						if (response?.status) {
							setSpinner(false);
							const storeResult = response?.extractedData?.map(
								(activeData: any) => {
									const lenderChecked =
										activeLendesAfterSearch?.length > 0 &&
										activeLendesAfterSearch.find(
											({ id }: any) => activeData.id === id
										);
									return {
										...activeData,
										checked: lenderChecked?.checked,
									};
								}
							);
							setSelectedData(storeResult);
							setFilteredData(storeResult);
							await dispatch(getLeadFlowUrl())
								.then(unwrapResult)
								.then((result: any) => {
									if (result?.status) {
										setUrl(result?.url);
									}
								});
						} else {
							setSpinner(false);
						}
						//
					});
			} else {
				await dispatch(
					getAllLendersList({
						param:
							'/' +
							(leadInfo?.leadId
								? leadInfo?.leadId
								: newLeadApplicantSelector?.id) +
							`?searchItem=${sanitizeHtml(encodeURIComponent(value))}`,
					})
				)
					.then(unwrapResult)
					.then(async (response: any) => {
						if (response?.status) {
							setSpinner(false);

							const storeResult = response?.extractedData?.map(
								(activeData: any) => {
									const lenderChecked =
										activeLendesAfterSearch?.length > 0 &&
										activeLendesAfterSearch.find(
											({ id }: any) => activeData.id === id
										);
									return {
										...activeData,
										checked: lenderChecked?.checked,
									};
								}
							);
							setSelectedData(storeResult);
							setFilteredData(storeResult);
							await dispatch(getLeadFlowUrl())
								.then(unwrapResult)
								.then((result: any) => {
									if (result?.status) {
										setUrl(result?.url);
									}
								});
						} else {
							setSpinner(false);
						}
						//
					});
			}
		}
	};
	const addLenders = async (lenders: any) => {
		if (lenders.length === 0) {
			history.push('/selected-lender-list');
		} else {
			await dispatch(
				addLendersList({
					data: { lenders },
					param:
						'/' +
						(leadInfo?.leadId
							? leadInfo?.leadId
							: newLeadApplicantSelector?.id) +
						'/' +
						'true',
				})
			)
				.then(unwrapResult)
				.then(async (response: any) => {
					if (response?.status) {
						const myPromiss = new Promise(function (myResolve) {
							setTimeout(function () {
								myResolve(history.push('/selected-lender-list'));
							}, 10);
						});
						myPromiss.then(async () => {
							setSelectedData([]);
							setFilteredData([]);
							await dispatch(
								leadFlow({
									...leadInfo,
									updateLeadProcess: leadInfo?.updateLeadProcess + 1 || 1,
								})
							);
						});
					}
				});
		}
	};

	// const setBankIcon = (bankName: any) => {
	// 	const bN = bankName.toLowerCase();
	// 	let icon_path = '';
	// 	if (bN.includes('hdfc')) {
	// 		icon_path = '/images/icons/bank/lender-hdfc-bank-icon.png';
	// 	} else if (bN.includes('axis')) {
	// 		icon_path = '/images/icons/bank/lender-axis-bank-icon.png';
	// 	} else if (bN.includes('bajaj')) {
	// 		icon_path = '/images/icons/bank/lender-bajaj-finance-icon.png';
	// 	} else if (bN.includes('icici')) {
	// 		icon_path = '/images/icons/bank/lender-icici-bank-icon.png';
	// 	} else if (bN.includes('idfc')) {
	// 		icon_path = '/images/icons/bank/lender-idfc-bank-icon.png';
	// 	} else if (bN.includes('aditya')) {
	// 		icon_path = '/images/icons/bank/lender-aditya-birla-icon.png';
	// 	} else if (bN.includes('baroda')) {
	// 		icon_path = '/images/icons/bank/lender-bank-of-baroda-icon.png';
	// 	} else if (bN.includes('chola')) {
	// 		icon_path = '/images/icons/bank/lender-chola-icon.png';
	// 	} else if (bN.includes('deutsche')) {
	// 		icon_path = '/images/icons/bank/lender-deutsche-bank-icon.png';
	// 	} else if (bN.includes('fullerton')) {
	// 		icon_path = '/images/icons/bank/lender-fullerton-icon.png';
	// 	} else if (bN.includes('kotak')) {
	// 		icon_path = '/images/icons/bank/lender-kotak-mahindra-bank-icon.png';
	// 	} else if (bN.includes('piramal')) {
	// 		icon_path = '/images/icons/bank/lender-piramal-icon.png';
	// 	} else if (bN.includes('punjab')) {
	// 		icon_path = '/images/icons/bank/lender-punjab-national-bank-icon.png';
	// 	} else if (bN.includes('yes')) {
	// 		icon_path = '/images/icons/bank/lender-yes-bank-icon.png';
	// 	} else if (bN.includes('tata')) {
	// 		icon_path = '/images/icons/bank/lender-tata-icon.png';
	// 	} else if (bN.includes('shriram')) {
	// 		icon_path = '/images/icons/bank/lender-shriram-icon.png';
	// 	} else {
	// 		icon_path = '/images/icons/leads/bank_icon_placeholder.svg';
	// 	}
	// 	return icon_path;
	// };

	const cardClick = async (event: any) => {
		const storeResult = filteredData.map((activeData: any) => {
			// const lenderChecked =
			// 	activeLendesAfterSearch?.length > 0 &&
			// 	activeLendesAfterSearch.find(({ id }: any) => activeData.id === id);
			if (event.target.id === activeData.id.toString()) {
				return {
					...activeData,
					checked: !activeData.checked,
					is_insert: 'true',
				};
			} else {
				return {
					checked:
						activeData.checked === undefined ? false : activeData.checked,
					...activeData,
				};
			}
		});
		const storeResultFiltered = selectedData.map((activeData: any) => {
			// const lenderChecked =
			// 	activeLendesAfterSearch?.length > 0 &&
			// 	activeLendesAfterSearch.find(({ id }: any) => activeData.id === id);
			if (event.target.id === activeData.id.toString()) {
				return {
					...activeData,
					checked: !activeData.checked,
					is_insert: 'true',
				};
			} else {
				return {
					checked:
						activeData.checked === undefined ? false : activeData.checked,
					...activeData,
				};
			}
		});

		await dispatch(activeLender([...storeResult]));
		setSelectedData(storeResultFiltered);
		setFilteredData(storeResult);
	};

	const getSelectedLenderList = async () => {
		await dispatch(
			getSelectedLendersList({
				param: '/' + leadInfo?.leadId,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					response?.data.length > 0
						? setProceedBtn(true)
						: setProceedBtn(false);
				}
			});
	};

	const selectedBank = filteredData?.filter(({ checked }: any) => checked);
	const slectedBankCount = selectedBank ? selectedBank.length : 0;
	const token = useSelector(
		(state: any) => state.users.user_details.access_token
	);

	const handleBackButton = () => {
		const myPromise = new Promise(function (myResolve) {
			//Code commented to send user to professional info page on back button click
			/*let path = '';
			if (addMoreLendersKey) {
				path = '/selected-lender-list';
			} else if (leadInfo?.from === 'nimbuss') {
				path = '/lead-details/' + leadInfo.leadId + '/' + token;
			} else {
				path = '/professional-info';
			}*/

			setTimeout(function () {
				myResolve(history.push('/professional-info'));
			}, 10);
		});
		myPromise.then(async () => {
			setValue('');
			setSelectedData([]);
			setFilteredData([]);
			dispatch(activeLender([]));
			dispatch(addMoreLenders(false));
			dispatch(lenderListData([]));
			await dispatch(
				leadFlow({
					...leadInfo,
					isLenderSelected: false,
				})
			);
		});
	};

	return (
		<IonPage className='all-'>
			{/* <Menu /> */}
			<Header
				name={t('All Lenders')}
				backButtonDisabled={false}
				backButton={true}
				needArrow={true}
				//needCrossOnClick={() => setShowPageExitPopup(true)}
				needArrowOnClick={() => {
					handleBackButton();
				}}
				style={{ borderBottom: '1px solid #00000029' }}
				endItem={
					leadInfo?.via !== 'share' ? (
						<IonButtons
							slot='end'
							className=''
						>
							<IonImg
								className='cursor-pointer'
								src='/images/icons/assign-icon.svg'
								alt='assign'
								onClick={() => {
									setShowAllocateManagerModal(true);
								}}
							/>
							<IonImg
								className='cursor-pointer mx-3'
								src='/images/icons/share-blue.svg'
								alt='share'
								onClick={() => {
									const finalUrl =
										url.split('?')?.[0] +
										'?d=' +
										window.btoa(`lead_id=${leadInfo?.leadId}`);
									ShareData('', '', finalUrl);
								}}
							/>
						</IonButtons>
					) : (
						''
					)
				}
			/>
			<IonContent>
				<div className='bg-web lead-lender-list-container-spacing overflowY-auto h-100'>
					<div className='bg-web-white px-3 py-md-3 border-md-radius-10'>
						<div className='fs-16 fw-600 darkest-grey mx-3 my-3'>
							{t('Choose from the lenders given below')}
						</div>
						<div className='position-relative mx-3 mt-3 d-flex justify-content-between'>
							<CustomSearchbar
								searchIcon='/images/icons/searchbar-icon.svg'
								placeholder={t('Search by “Lenders”')}
								showClearButton='focus'
								value={value}
								onChange={(value: any) => {
									setValue(value);
									if (value.length >= 3) {
										const filteredArray = selectedData?.filter((item: any) =>
											item.name?.toLowerCase().includes(value?.toLowerCase())
										);
										setSelectedData(filteredArray);
									} else {
										setSelectedData(filteredData);
									}
								}}
							/>
						</div>

						{/*<div className='d-flex justify-content-end'>
							<CustomButton
								fill='clear'
								//routerLink='/'
								className='fs-14 fw-600 mb-1 '
								title={t('Add More Lenders')}
							/>
							</div>*/}
						{selectedData.length > 0 ? (
							<IonGrid className=''>
								<IonRow>
									{selectedData?.map((item: any, index: number) => {
										return (
											<IonCol
												size='6'
												sizeMd='4'
												sizeLg='3'
												key={index}
												// itemID={serachData[index]?.id}
												// id={item.id}
												style={{
													zIndex: 2,
												}}
											>
												<IonCard
													// id={item.id}
													onClick={cardClick}
													className={`h-100 m-0 cursor-pointer blue-corner-linerar-gradient
                      ${item?.status === 'online' ? 'online' : ''}
                  `}
												>
													<IonCardContent
														className='d-flex justify-content-center align-items-center flex-column'
														id={item.id}
													>
														<div className='d-flex w-100 justify-content-between align-items-center'>
															<div className='d-flex'>
																<IonCheckbox
																	name={item.name}
																	value={item.id}
																	checked={item.checked}
																	style={{ zIndex: -11111 }}
																	mode='md'
																/>
															</div>
															<div style={{ minHeight: '20px' }}>
																{item?.recommended === true && (
																	<IonImg
																		alt='img'
																		className='bankimg m-0'
																		src={`/images/icons/leads/${
																			item?.checked === true
																				? 'crown-marked'
																				: 'crown'
																		}.svg`}
																		style={{
																			width: '20px',
																			height: '20px',
																		}}
																	/>
																)}
															</div>
														</div>
														<div className='selected-lender-bank-icon-container'>
															<div className='selected-lender-bank-icon-border p-0'>
																<IonImg
																	alt='img'
																	className='bankimg selected-lender-bank-icon m-0'
																	style={{
																		width: '40px',
																		height: '40px',
																	}}
																	src={setBankIcon(item?.name)}
																/>
															</div>
														</div>

														<IonLabel className='fw-900 my-2 text-center'>
															{item?.name}
														</IonLabel>
														{/* <IonLabel
															className='mb-2 mt-0'
															style={{ color: 'var(--text-dark-grey)' }}
														>
															{item?.roi ? item?.roi + '% p.a' : ''}
														</IonLabel> */}
														<span
															className={`fs-12 py-1 px-2 selected-lender-status-${
																item?.status?.toLowerCase() === 'online'
																	? 'online'
																	: 'offline'
															}`}
														>
															{t(
																item?.status?.toLowerCase() === 'online'
																	? t('online')
																	: t('offline')
															)}
														</span>
														{
															leadInfo?.product_category_id == '7' && (
																<>
																	<span className='mt-2 fs-12 selected-lender-bank-icon-container justify-content-between'>
																		<span className='fw-bold text-dark '>
																			Eligible Loan Amount
																		</span>
																		<span className='ml-4 text-dark'>
																			{item?.eligible_loan_amount}
																		</span>
																	</span>
																	<span className='mt-2 fs-12 selected-lender-bank-icon-container justify-content-between'>
																		<span className='fw-bold text-dark '>
																			Processing Fee
																		</span>
																		<span className='ml-4 text-dark'>
																			{item?.processing_fees}
																		</span>
																	</span>
																	<span className='mt-2 fs-12 selected-lender-bank-icon-container justify-content-between'>
																		<span className='fw-bold text-dark '>
																			Intrest Rate (p.a.)
																		</span>
																		<span className='ml-4 text-dark'>
																			{`${item?.roi} %`}
																		</span>
																	</span>
																	<span className='mt-2 fs-12 selected-lender-bank-icon-container justify-content-between'>
																		{item?.Document_type ? (
																			<>
																				<span className='fw-bold text-dark '>
																					Income Proof
																				</span>
																				<span className='ml-4 text-dark'>
																					{`${
																						item?.Document_type
																							? item?.Document_type
																							: ''
																					}`}
																				</span>
																			</>
																		) : (
																			''
																		)}
																	</span>
																	<span className='mt-2 fs-12 selected-lender-bank-icon-container justify-content-between'>
																		{item?.co_applicant ? (
																			<>
																				<span className='fw-bold text-dark '>
																					Co-Applicant
																				</span>
																				<span className='ml-4 text-dark'>
																					{`${
																						item?.co_applicant
																							? item?.co_applicant
																							: ''
																					}`}
																				</span>
																			</>
																		) : (
																			''
																		)}
																	</span>
																</>
															)
															// 						eliglible_loan_amount: '500000',
															// processing_fee: '0.50%',
															// income_proof: 'Required',
															// co_applicant: 'Required',
															// roi: '-100',
														}
													</IonCardContent>
													<div
														id={item.id}
														style={{
															background: 'transparent',
															height: '100%',
															width: '100%',
															position: 'absolute',
															top: 0,
															zIndex: 111111,
														}}
														onClick={cardClick}
													/>
												</IonCard>
											</IonCol>
										);
									})}
								</IonRow>
							</IonGrid>
						) : (
							<div
								className='d-flex justify-content-center'
								style={{ height: '80vh' }}
							>
								{spinner && (
									<div
										className='position-fixed  w-100  d-flex justify-content-center align-items-center h-100'
										style={{
											top: '0px',
											left: '50%',
											transform: 'translate(-50%,0%)',
											backgroundColor: 'rgba(0,0,0,0.2)',
										}}
									>
										<IonSpinner name='lines-sharp' />
									</div>
								)}
								<CustomNoData
									className='py-3 d-flex flex-column align-items-center justify-content-center'
									message={t('No Data Found')}
								/>
							</div>
						)}
					</div>
				</div>
			</IonContent>

			<CustomModal
				// id='customModalRework'
				isOpen={showAllocateManagerModal}
				modalTitle={t('Select Manager')}
				initialBreakpoint={1}
				handleClose={() => setShowAllocateManagerModal(false)}
				breakpoints={[0, 1]}
				className='assignModal side-modal'
				modalContent={
					<CustomAllocateManagers
						eventType='Lead'
						id={leadInfo?.leadId}
						setShowAssignModal={setShowAllocateManagerModal}
					/>
				}
				scrollY={true}
				needCross={true}
				needArrow={false}
			></CustomModal>
			{/* AssignModal */}
			<CustomModal
				// id='custommodal'
				isOpen={showPageExitPopup}
				modalTitle=''
				initialBreakpoint={undefined}
				handleClose={() => setShowPageExitPopup(false)}
				breakpoints={[]}
				className='height-auto confirm-popup-middle'
				modalContent={PageExitPopUp(
					() => {
						setShowPageExitPopup(false);
					},
					() => {
						setShowPageExitPopup(false);
						history.replace(
							leadInfo?.from === 'nimbuss'
								? `/lead-details/${leadInfo?.leadId}/${token}`
								: '/dashboard/lead'
						);
					},
					'Cancel',
					'Continue Later',
					'className',
					'Are You Sure You Want to Exit?',
					`${t('You can continue later from where you left off.')}`
				)}
				needIonContentWraper={false}
				needCross={false}
				needArrow={false}
			/>
			<IonFooter>
				<div className='lead-lender-list-container-spacing ion-padding bg-white'>
					<div className=' px-3 d-flex align-items-center justify-content-between justify-content-md-end flex-row'>
						{(value && selectedData?.length <= 0) ||
							(filteredData?.length > 0 && (
								<>
									<div className='fw-900 me-md-4'>
										{slectedBankCount} {t('Selected')}
									</div>
									<CustomButton
										// routerLink='/comman-info'
										style={{ minWidth: '150px' }}
										className='fw-bold dashboard-add-lead-button '
										expand='block'
										title={t('Proceed')}
										onClick={() => {
											setValue('');
											addLenders(selectedBank);
											dispatch(addMoreLenders(false));
											dispatch(lenderListData(selectedBank));
										}}
										disabled={
											!((selectedBank && selectedBank.length > 0) || proceedBtn)
										} // disabled because dev pending - saurabh
									/>
								</>
							))}
						{(addMoreLendersKey || leadInfo?.isLenderSelected) &&
							filteredData.length == 0 && (
								<CustomButton
									expand='block'
									onClick={() => {
										setValue('');
										dispatch(addMoreLenders(false));
										history.push('/selected-lender-list');
									}}
									style={{ maxWidth: '200px' }}
									className={'btn-blue'}
									title='Proceed'
								/>
							)}
					</div>
				</div>
			</IonFooter>
		</IonPage>
	);
};
