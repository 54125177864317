/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	IonChip,
	IonContent,
	IonImg,
	IonItem,
	IonLabel,
	IonList,
	IonPage,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Header } from '../../../components/layout/header/Header';
import './Details.scss';
import './BureauScore.scss';
import { useTranslation } from 'react-i18next';
import { BUREAU_SCORE_TABS } from '../../../helpers/constant';
import { useDispatch, useSelector } from 'react-redux';
import {
	cbilDetails,
	equifaxDetails,
} from '../../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';

const SourceDescriptionPanel = ({ source, sourceDetails }: any) => {
	const { t } = useTranslation();
	const getDescriptionPanelData = () => {
		switch (source) {
			case 'Payments':
				return (
					<div className='d-flex bg gap-3 ion-padding align-items-center'>
						<div style={{ minWidth: 'fit-content' }}>
							{/* excellent/average/fair */}
							{sourceDetails?.image_name ? (
								<img
									src={`images/icons/credit_score_inner_thumb_${sourceDetails?.image_name?.toLowerCase()}.svg`}
									width={40}
									height={40}
									className='rounded-circle'
									alt=''
								/>
							) : (
								''
							)}
						</div>
						<div>
							<h4 className='fs-16 fw-600'>{t(sourceDetails?.text1)}</h4>
							<p className='fs-14 mb-0'>{t(sourceDetails?.text2)}</p>
						</div>
					</div>
				);
			case 'Limits':
				return (
					<div className='d-flex bg gap-3 ion-padding align-items-center'>
						<div style={{ minWidth: 'fit-content' }}>
							{/* excellent/average/fair */}
							{sourceDetails?.image_name ? (
								<img
									src={`images/icons/credit_score_inner_thumb_${sourceDetails?.image_name?.toLowerCase()}.svg`}
									width={40}
									height={40}
									className='rounded-circle'
									alt=''
								/>
							) : (
								''
							)}
						</div>
						<div>
							<h4 className='fs-16 fw-600'>{t(sourceDetails?.text1)}</h4>
							<p className='fs-14 mb-0'>{t(sourceDetails?.text2)}</p>
						</div>
					</div>
				);
			case 'Age':
				return (
					<div className='d-flex bg gap-3 ion-padding align-items-center'>
						<div style={{ minWidth: 'fit-content' }}>
							{/* excellent/average/fair */}
							{sourceDetails?.image_name ? (
								<img
									src={`images/icons/credit_score_inner_thumb_${sourceDetails?.image_name?.toLowerCase()}.svg`}
									width={40}
									height={40}
									className='rounded-circle'
									alt=''
								/>
							) : (
								''
							)}
						</div>
						<div>
							<h4 className='fs-16 fw-600'>{t(sourceDetails?.text1)}</h4>
							<p className='fs-14 mb-0'>{t(sourceDetails?.text2)}</p>
						</div>
					</div>
				);
			case 'Accounts':
				return (
					<div className='d-flex bg gap-3 ion-padding align-items-center'>
						<div style={{ minWidth: 'fit-content' }}>
							{/* excellent/average/fair */}
							{sourceDetails?.image_name ? (
								<img
									src={`images/icons/credit_score_inner_thumb_${sourceDetails?.image_name?.toLowerCase()}.svg`}
									width={40}
									height={40}
									className='rounded-circle'
									alt=''
								/>
							) : (
								''
							)}
						</div>
						<div>
							<h4 className='fs-16 fw-600'>{t(sourceDetails?.text1)}</h4>
							<p className='fs-14 mb-0'>{t(sourceDetails?.text2)}</p>
						</div>
					</div>
				);
			case 'Enquiries':
				return (
					<div className='d-flex bg gap-3 ion-padding align-items-center'>
						<div style={{ minWidth: 'fit-content' }}>
							{/* excellent/average/fair */}
							{sourceDetails?.image_name ? (
								<img
									src={`images/icons/credit_score_inner_thumb_${sourceDetails?.image_name?.toLowerCase()}.svg`}
									width={40}
									height={40}
									className='rounded-circle'
									alt=''
								/>
							) : (
								''
							)}
						</div>
						<div>
							<h4 className='fs-16 fw-600'>{t(sourceDetails?.text1)}</h4>
							<p className='fs-14 mb-0'>{t(sourceDetails?.text2)}</p>
						</div>
					</div>
				);
			case 'Profile':
				return (
					<div className='d-flex bg gap-3 ion-padding align-items-center'>
						<div style={{ minWidth: 'fit-content' }}>
							{/* excellent/average/fair */}
							{sourceDetails?.image_name ? (
								<img
									src={`images/icons/credit_score_inner_thumb_${sourceDetails?.image_name?.toLowerCase()}.svg`}
									width={40}
									height={40}
									className='rounded-circle'
									alt=''
								/>
							) : (
								''
							)}
						</div>
						<div>
							<h4 className='fs-16 fw-600'>{t(sourceDetails?.text1)}</h4>
							<p className='fs-14 mb-0'>{t(sourceDetails?.text2)}</p>
						</div>
					</div>
				);
		}
	};
	return <>{getDescriptionPanelData()}</>;
};

const CreditScorePerksPanel = ({ source, sourceDetails }: any) => {
	const { t } = useTranslation();
	const getPerksPanelData = () => {
		switch (source) {
			case 'Payments':
				return (
					<>
						<div>
							<h4 className='fs-16 fw-600'>
								{t(sourceDetails?.score_perks_text)}
							</h4>
							<p className='mb-0 fs-14'>
								{t(sourceDetails?.score_perks_value)}
							</p>
						</div>
						<div>
							<IonChip
								className={`fs-12 `}
								style={{
									padding: '10px',
									height: '23px',
									'--background': sourceDetails?.bg_color,
									'--color': sourceDetails?.color,
								}}
							>
								{t(sourceDetails?.impacts)}
							</IonChip>
						</div>
					</>
				);
			case 'Limits':
				return (
					<>
						<div>
							<h4 className='fs-16 fw-600'>
								{t(sourceDetails?.score_perks_text)}
							</h4>
							<p className='mb-0 fs-14'>
								{t(sourceDetails?.score_perks_value)}
							</p>
						</div>
						<div>
							<IonChip
								className={`fs-12`}
								style={{
									padding: '10px',
									height: '23px',
									'--background': sourceDetails?.bg_color,
									'--color': sourceDetails?.color,
								}}
							>
								{t(sourceDetails?.impacts)}
							</IonChip>
						</div>
					</>
				);
			case 'Age':
				return (
					<>
						<div>
							<h4 className='fs-16 fw-600'>
								{t(sourceDetails?.score_perks_text)}
							</h4>
							<p className='mb-0 fs-14'>
								{t(sourceDetails?.score_perks_value)}
							</p>
						</div>
						<div>
							<IonChip
								className={`fs-12 `}
								style={{
									padding: '10px',
									height: '23px',
									'--background': sourceDetails?.bg_color,
									'--color': sourceDetails?.color,
								}}
							>
								{t(sourceDetails?.impacts)}
							</IonChip>
						</div>
					</>
				);
			case 'Accounts':
				return (
					<>
						<div>
							<h4 className='fs-16 fw-600'>
								{t(sourceDetails?.score_perks_text)}
							</h4>
							<p className='mb-0 fs-14 text-grey'>
								{t(sourceDetails?.score_perks_value)}
							</p>
						</div>
						<div>
							<IonChip
								className={`fs-12 `}
								style={{
									padding: '10px',
									height: '23px',
									'--background': sourceDetails?.bg_color,
									'--color': sourceDetails?.color,
								}}
							>
								{t(sourceDetails?.impacts)}
							</IonChip>
						</div>
					</>
				);
			case 'Enquiries':
				return (
					<>
						<div>
							<h4 className='fs-16 fw-600'>
								{t(sourceDetails?.score_perks_text)}
							</h4>
							<p className='mb-0 fs-14 text-grey'>
								{t(sourceDetails?.score_perks_value)}
							</p>
						</div>
						<div>
							<IonChip
								className={`fs-12 `}
								style={{
									padding: '10px',
									height: '23px',
									'--background': sourceDetails?.bg_color,
									'--color': sourceDetails?.color,
								}}
							>
								{t(sourceDetails?.impacts)}
							</IonChip>
						</div>
					</>
				);
			case 'Profile':
				return (
					<>
						<div>
							<h4 className='fs-16 fw-600'>
								{t(sourceDetails?.score_perks_text)}
							</h4>
							<p className='mb-0 fs-14 text-grey'>
								{t(sourceDetails?.score_perks_value)}
							</p>
						</div>
						<div>
							<IonChip
								className={`fs-12 `}
								style={{
									padding: '10px',
									height: '23px',
									'--background': sourceDetails?.bg_color,
									'--color': sourceDetails?.color,
								}}
							>
								{t(sourceDetails?.impacts)}
							</IonChip>
						</div>
					</>
				);
		}
	};

	return (
		<div className='ion-padding'>
			<div className='d-flex justify-content-between align-items-baseline'>
				{getPerksPanelData()}
			</div>
		</div>
	);
};

const BureauTotalCard = ({ source, sourceDetails }: any) => {
	const { t } = useTranslation();
	const getTotalBoxBySource = () => {
		switch (source) {
			case 'Payments':
				return (
					<>
						<div
							className='d-flex justify-content-between ion-padding blue-corner-linerar-gradient'
							style={{ borderRadius: '8px', border: '1px solid #DFDFDF' }}
						>
							<div>
								<h4 className='fw-bold fs-24'>
									{sourceDetails?.total_payment
										? sourceDetails?.total_payment
										: 0}
								</h4>
								<p className='mb-0 fs-14'>{t('Total Payments')}</p>
							</div>
							<div className='me-5'>
								<h4 className='fw-bold fs-24'>
									{sourceDetails?.payment_on_time
										? sourceDetails?.payment_on_time
										: 0}
								</h4>
								<p className='mb-0 fs-14'>{t('Payment On Time')}</p>
							</div>
						</div>
					</>
				);
			case 'Limits':
				return (
					<>
						<div
							className='d-flex justify-content-between ion-padding blue-corner-linerar-gradient'
							style={{ borderRadius: '8px', border: '1px solid #DFDFDF' }}
						>
							<div>
								<h4 className='fw-bold fs-24'>
									{sourceDetails?.total_credit_limit
										? sourceDetails?.total_credit_limit
										: 0}
								</h4>
								<p className='mb-0 fs-14'>{t('Total Credit Limit')}</p>
							</div>
							<div className='me-5'>
								<h4 className='fw-bold fs-24'>
									{sourceDetails?.limit_utilized
										? sourceDetails?.limit_utilized
										: 0}
								</h4>
								<p className='mb-0 fs-14'>{t('Limit Utilisation')}</p>
							</div>
						</div>
					</>
				);
			case 'Age':
				return (
					<>
						<div
							className='d-flex justify-content-between ion-padding blue-corner-linerar-gradient'
							style={{ borderRadius: '8px', border: '1px solid #DFDFDF' }}
						>
							<div>
								<h4 className='fw-bold fs-24'>
									{sourceDetails?.total_active_account
										? sourceDetails?.total_active_account
										: 0}
								</h4>
								<p className='mb-0 fs-14'>{t('Total Active Account')}</p>
							</div>
							<div className='me-5'>
								<h4 className='fw-bold fs-24'>
									{sourceDetails?.age_of_account
										? sourceDetails?.age_of_account
										: 0}
								</h4>
								<p className='mb-0 fs-14'>{t('Age Of Accounts')}</p>
							</div>
						</div>
					</>
				);
			case 'Accounts':
				return (
					<>
						<div
							className='d-flex ion-padding blue-corner-linerar-gradient'
							style={{ borderRadius: '8px', border: '1px solid #DFDFDF' }}
						>
							<div>
								<h4 className='fw-bold fs-24'>
									{sourceDetails?.total_active_account
										? sourceDetails?.total_active_account
										: 0}
								</h4>
								<p className='mb-0 fs-14'>{t('Total Active Account')}</p>
							</div>
							{/* <div>
							<h4 className='fw-bold fs-24'>{sourceDetails?.limit_utilized}</h4>
							<p className='mb-0 fs-14'>Limit Utilisation</p>
						</div> */}
						</div>
					</>
				);
			case 'Enquiries':
				return (
					<>
						<div
							className='d-flex ion-padding blue-corner-linerar-gradient'
							style={{ borderRadius: '8px', border: '1px solid #DFDFDF' }}
						>
							<div>
								<h4 className='fw-bold fs-24'>
									{sourceDetails?.total_account_enquiry
										? sourceDetails?.total_account_enquiry
										: 0}
								</h4>
								<p className='mb-0 fs-14'>{t('Total Account Enquiries')}</p>
							</div>
							<div>
								{/* <h4 className='fw-bold fs-24'>{sourceDetails?.limit_utilized}</h4>
							<p className='mb-0 fs-14'>Limit Utilisation</p> */}
							</div>
						</div>
					</>
				);
			case 'Profile':
				return (
					<>
						<div
							className='d-flex justify-content-between ion-padding blue-corner-linerar-gradient'
							style={{ borderRadius: '8px', border: '1px solid #DFDFDF' }}
						>
							<div>
								<h4 className='fw-bold fs-24'>
									{sourceDetails?.total_account
										? sourceDetails?.total_account
										: 0}
								</h4>
								<p className='mb-0 fs-14'>{t('Total Account')}</p>
							</div>
							<div className='me-5'>
								<h4 className='fw-bold fs-24'>
									{sourceDetails?.total_active_account
										? sourceDetails?.total_active_account
										: 0}
								</h4>
								<p className='mb-0 fs-14'>{t('Total Active Account')}</p>
							</div>
						</div>
					</>
				);
		}
	};
	return <div className='ion-padding'>{getTotalBoxBySource()}</div>;
};

export const InnerDetails: React.FC = () => {
	const { t } = useTranslation();
	const [sourceDetails, setSourceDetails] = useState<any>();
	const innerStats = useSelector(
		(state: any) => state?.lead?.bureauStatsDetailsData
	);
	const leadFlowSelector = useSelector(
		(state: any) => state?.lead?.leadFlowData
	);

	const dispatch = useDispatch();

	useEffect(() => {
		getSourceDetails();
	}, [innerStats?.source]);

	const getSourceDetails = async () => {
		if (innerStats?.selectedTab === BUREAU_SCORE_TABS.EQUIFAX) {
			await dispatch(
				equifaxDetails({
					param: `${innerStats?.source}/${
						innerStats?.leadId || leadFlowSelector?.leadId
					}`,
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						setSourceDetails(response?.data[0]);
					}
				});
		} else if (innerStats?.selectedTab === BUREAU_SCORE_TABS.CIBIL) {
			await dispatch(
				cbilDetails({
					param: `${innerStats?.source}/${
						innerStats?.leadId || leadFlowSelector?.leadId
					}`,
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						setSourceDetails(response?.data[0]);
					}
				});
		}
	};

	return (
		<>
			<IonPage className=''>
				<Header
					name={t(innerStats?.headerTitle)}
					backButton={true}
					needArrow={true}
					style={{ borderBottom: '2px solid #00000029' }}
				/>

				<IonContent className='detail-report background-web'>
					<div className='bg-web'>
						<div className='inner-details border-md-radius-10 bg-web-white mt-4'>
							{/* SourceDescriptionPanel*/}
							<div className='pt-md-4'>
								<SourceDescriptionPanel
									source={innerStats?.source}
									sourceDetails={sourceDetails}
								/>
							</div>

							{/* score perks */}
							<CreditScorePerksPanel
								source={innerStats?.source}
								sourceDetails={sourceDetails}
							/>

							{/* totals details */}
							<BureauTotalCard
								source={innerStats?.source}
								sourceDetails={sourceDetails}
							/>
							{sourceDetails?.accounts?.length > 0 && (
								<div className=' px-3 px-md-3'>
									<h4>{t('Your Accounts')}</h4>
									{sourceDetails?.accounts.map((item: any, index: any) => {
										return (
											<IonList
												lines='full'
												key={`inner-details-${index}`}
											>
												<IonItem className='your-accounts'>
													<IonImg
														slot='start'
														src='/images/icons/common_bank_icon.svg'
														className='me-3'
													/>
													<IonLabel className='ion-text-wrap'>
														<h5 className='fs-16 fw-600'>{item.bank_name}</h5>
														<p
															style={{ color: '#5D5E72' }}
															className='fs-14 mb-0'
														>
															{item.accType}: {item.card_no}
														</p>
														<p
															style={{ color: '#66CC66' }}
															className='fs-14 mb-0'
														>
															{t('Timely Payments')}: {item.timely_payment}
														</p>
													</IonLabel>
													<IonLabel slot='end'>
														{item.status === 'Yes' ? (
															<IonChip
																className={`fs-12 chip-green`}
																style={{
																	padding: '8px 8px 8px',
																	height: '23px',
																}}
															>
																{t('Active')}
															</IonChip>
														) : (
															<IonChip
																disabled={true}
																className={`fs-12`}
																style={{
																	padding: '8px 8px 8px',
																	height: '23px',
																}}
															>
																{t('InActive')}
															</IonChip>
														)}
													</IonLabel>
												</IonItem>
											</IonList>
										);
									})}
								</div>
							)}
						</div>
					</div>
				</IonContent>
			</IonPage>
		</>
	);
};
