import {
    IonContent,
    IonPage,
} from '@ionic/react';
import { useLocation } from 'react-router';
import './style.scss';



//needs redirection
const MessageNotification= () => {
    const stateLocation= useLocation()
    const state = stateLocation.state as any;

return (
<IonPage>
<IonContent className='ion-padding'>
    <div className='main_modal'>
    <div style={{marginLeft:'15px'}}>
    <img src={state.src} height={60} width={60}/>
    </div>
<h3>{state.message}</h3>
    </div>
   

</IonContent>
</IonPage>    
);
};

export default MessageNotification;