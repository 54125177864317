import { IonPage, IonContent, IonItem, IonImg, IonLabel } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Header } from '../../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import '../../dashboard/kyc/MyKyc.scss';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { CustomCheckbox } from '../../../components/common/custom-checkbox/CustomCheckbox';
// import { CircularProgressBar } from '../../../components/common/custom-circular-progress-bar/CircularProgressBar';
import { StatusNum, USER_ROLES } from '../../../helpers/constant';
import { Player } from '@lottiefiles/react-lottie-player';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	approveUserStatus,
	dmaFlowData,
	userVerificationPage,
} from '../../../store/reducers/user/userSlice';
import { CustomStepsCountHeaderBar } from '../../../components/common/custom-steps-count-header-bar/CustomStepsCountHeaderBar';

const stepsProgressSubHeaderData = [
	{
		percentage: 50,
		value: '1',
		text: 'User Verification',
	},
	{
		percentage: 100,
		value: '2',
		text: 'E-franking',
	},
];

export const DmaVerificationStatus: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const location: any = useLocation();
	const [showSentForRework, setShowSentForRework] = useState<boolean>(false);
	const [userVerificationList, setUserVerificationList] = useState<any>([]);
	const [verificationListCount, setVerificationListCount] = useState<any>();
	const [verifiedTabCount, setVerifiedTabCount] = useState<number>(0);
	const [listStatus, setListStatus] = useState<any>();
	const [userId, setUserId] = useState<any>();
	const [requiredTabCount, setRequiredTabCount] = useState<number>(0);
	const [noTabCount, setNoTabCount] = useState<number>(0);
	const dmaSelector = useSelector((state: any) => state?.users?.dmaFlow);
	const role = useSelector((state: any) => state?.users?.user_details);

	const dispatch = useDispatch();
	let vTabCount = 0;
	let rrTabCount = 0;
	let nTabCount = 0;

	const getVerficationList = async () => {
		await dispatch(
			userVerificationPage({ param: dmaSelector?.dmaId || userId })
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					if ((dmaSelector?.dmaCode || listStatus) !== StatusNum.REWORK) {
						setUserVerificationList(response?.data);
						setVerificationListCount(response?.data?.length);
					} else {
						const newVerificationList: any = [];
						response?.data?.map((tabItem: any) => {
							if (
								tabItem.approve === 'rework_required' ||
								tabItem.approve === 'reworked'
							) {
								newVerificationList.push(tabItem);
							}
						});
						setUserVerificationList(newVerificationList);
						setVerificationListCount(newVerificationList.length);
					}

					response?.data?.map((tab: any) => {
						if (tab.approve === 'yes') {
							vTabCount++;
						} else if (tab.approve === 'rework_required') {
							rrTabCount++;
						} else if (tab.approve === '') {
							nTabCount++;
						}
					});
					setVerifiedTabCount(vTabCount);
					setRequiredTabCount(rrTabCount);
					setNoTabCount(nTabCount);
				}
			});
	};

	const sendForRework = async () => {
		await dispatch(
			approveUserStatus({
				param: userId,
				data: { step: 'step1' },
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setShowSentForRework(true);
				}
			});
	};

	const sendForApproval = async () => {
		await dispatch(
			approveUserStatus({
				param: userId,
				data: {
					step: 'step1',
					RW: true,
				},
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					history.push('/team-list');
					setTimeout(() => {
						dispatch(
							dmaFlowData({
								...dmaSelector,
								update: dmaSelector?.update + 1 || 1,
							})
						);
					}, 100);
				}
			});
	};

	/*useEffect(() => {
		getVerficationList();
	}, []);*/

	useEffect(() => {
		if (location.pathname !== '/dma-verification-status') {
			return;
		}
		setUserId(dmaSelector?.dmaId);
		setListStatus(dmaSelector?.dmaCode);
		getVerficationList();
	}, [location?.pathname]);

	const validationSchema = yup.object().shape({
		approveVerfication: yup
			.bool()
			.required('You need to check for confirmation')
			.oneOf([true], 'You need to check for confirmation'),
	});

	const {
		handleSubmit,
		control,
		setValue,
		// trigger,
		getValues,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			approveVerfication: false,
		},
	});

	const onSubmit = async () => {
		await dispatch(
			approveUserStatus({
				param: userId,
				data: {
					step: 'step1',
				},
			})
		)
			.then(unwrapResult)
			.then(async (response: any) => {
				if (response?.status) {
					await dispatch(
						dmaFlowData({
							...dmaSelector,
							dmaStatus: response?.dmastatus,
							dmaVerified: true,
						})
					);
					history.push('/dma-verify-steps');
				}
			});
	};

	const handleBackBtn = async () => {
		await dispatch(
			dmaFlowData({
				...dmaSelector,
				update: dmaSelector?.update + 1 || 1,
			})
		);
		if (
			[USER_ROLES.CENTRALOPS, USER_ROLES.CENTRALOPSJR].includes(
				role?.roleType?.[0]
			)
		) {
			if (dmaSelector.dmaCode === StatusNum.UNDERPROCESS) {
				history.push('/team-list');
			} else {
				history.goBack();
			}
		} else {
			history.push('/dma-details', {
				update: location?.state?.updateApi + 1 || 1,
			});
		}
	};

	return (
		<IonPage>
			<Header
				needArrow={true}
				backButton={true}
				name={t('')}
				needArrowOnClick={() => handleBackBtn()}
			/>
			{listStatus !== StatusNum.REWORK && (
				<CustomStepsCountHeaderBar
					currentStep={'step1'}
					headerStep={'e-franking-initiate'}
					displayStepsCounter={true}
					data={stepsProgressSubHeaderData}
				/>
			)}
			<IonContent className='background-web'>
				{/* {listStatus !== StatusNum.REWORK && (
					<div className='primary-bg-color px-3 py-2 font-semibold'>
						<div className='d-flex align-items-center justify-content-between efranking-wrapper'>
							{t('User Verification')}
							<CircularProgressBar
								strokeWidth='4'
								sqSize='45'
								percentage={50}
								value={'1/2'}
								ringBgColor='#808080'
								ringColor='var(--ion-color-primary)'
								textColor='rgba(12, 14, 43, 1)'
							/>
						</div>
					</div>
				)} */}
				<div className='bg-web'>
					<div className='conainer-margin-web-30 bg-web-white border-md-radius-10 opacity-1 '>
						{userVerificationList?.map((item: any, index: any) => {
							return (
								<IonItem
									className='ion-no-padding'
									key={index}
								>
									<div
										className='cursor-pointer ion-padding pe-0 w-100 d-flex align-items-center justify-content-between'
										onClick={async () => {
											await dispatch(
												dmaFlowData({
													...dmaSelector,
													show_tab: item?.name,
													show_tab_index: index,
													user_detail_tabs: userVerificationList,
												})
											);
											history.push('/dma-user-details');
										}}
									>
										<div className='d-flex align-items-center font-semibold'>
											<IonImg
												src={`/images/icons/${item?.icon_url}`}
												alt={item?.display_name}
											></IonImg>
											<div className='mx-3'>{t(item?.display_name)}</div>
										</div>
										<div>
											{item?.approve === 'yes' ? (
												<span className='status-chip green'>
													{t('Approved')}
												</span>
											) : (
												''
											)}
											{item?.approve === 'reworked' ? (
												dmaSelector?.dmaCode === StatusNum.REWORK ? (
													<span className='status-chip green'>
														{t('Rework Completed')}
													</span>
												) : (
													<span className='status-chip yellow'>
														{t('Reworked')}
													</span>
												)
											) : (
												''
											)}
											{item?.approve === 'rework_required' ? (
												<span className='status-chip red'>
													{t('Rework Required')}
												</span>
											) : (
												''
											)}
										</div>
									</div>
								</IonItem>
							);
						})}
					</div>
				</div>
			</IonContent>
			{listStatus !== StatusNum.UNDERPROCESS ? (
				<>
					{verifiedTabCount == verificationListCount &&
					listStatus !== StatusNum.REWORK ? (
						<div>
							<div className='d-block d-md-none position-relative'>
								<Controller
									control={control}
									name={'approveVerfication'}
									render={({ field: { onChange, onBlur, name } }) => (
										<CustomCheckbox
											isBackGround={true}
											name={name}
											labelPlacement='end'
											isChecked={getValues('approveVerfication')}
											value={getValues('approveVerfication')}
											onChange={(event: any) => {
												onChange(event);
												setValue('approveVerfication', event.detail.checked);
											}}
											onBlur={onBlur}
											className={`ion-padding-horizontal py-2 ${
												errors[name] ? 'ion-checkbox-inValid' : ''
											}`}
											errorText={errors[name]?.message}
											errorTextClass='position-absolute ms-3'
										>
											<IonLabel className='text-wrap d-flex'>
												{t('I confirm that all the documents are verified')}.
											</IonLabel>
										</CustomCheckbox>
									)}
								></Controller>

								<CustomButton
									type='submit'
									className='ion-margin-vertical w-100 btn-blue ion-padding-horizontal'
									title={t('Approve')}
									disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>

							<div className='d-none d-md-block'>
								<div className='d-block align-items-center justify-content-center text-align-center position-relative'>
									<Controller
										control={control}
										name={'approveVerfication'}
										render={({ field: { onChange, onBlur, name } }) => (
											<CustomCheckbox
												isBackGround={true}
												name={name}
												labelPlacement='end'
												isChecked={getValues('approveVerfication')}
												value={getValues('approveVerfication')}
												onChange={(event: any) => {
													onChange(event);
													setValue('approveVerfication', event.detail.checked);
												}}
												onBlur={onBlur}
												className={`ion-padding-horizontal py-2 ${
													errors[name] ? 'ion-checkbox-inValid' : ''
												}`}
												errorText={errors[name]?.message}
												errorTextClass='position-absolute error-text-position-md'
											>
												<IonLabel className='text-wrap'>
													{t(`I confirm that all the documents are verified`)}.
												</IonLabel>
											</CustomCheckbox>
										)}
									></Controller>
								</div>

								<div className=' d-flex align-items-center justify-content-center bg-web-white'>
									<CustomButton
										type='submit'
										className='ion-margin-vertical w-25 btn-blue ion-padding-horizontal'
										title={t('Approve')}
										disabled={!isValid}
										onClick={handleSubmit(onSubmit)}
									/>
								</div>
							</div>
						</div>
					) : (
						''
					)}
					{noTabCount === 0 &&
						requiredTabCount !== 0 &&
						listStatus !== StatusNum.REWORK && (
							<div className='d-flex gap-2 ion-padding flex-column bg-web-white'>
								<CustomButton
									className={'w-25-md m-auto btn-blue fw-600'}
									title={t('Send for Rework')}
									onClick={() => sendForRework()}
								/>
							</div>
						)}
				</>
			) : (
				''
			)}

			{listStatus === StatusNum.REWORK && requiredTabCount === 0 && (
				<div className='d-flex gap-2 ion-padding flex-column bg-web-white'>
					<CustomButton
						className={'w-100 d-block d-md-none btn-blue fw-600'}
						title={t('Update')}
						onClick={() => sendForApproval()}
					/>
					<div className='d-none d-md-block text-align-center d-flex align-items-center justify-content-center'>
						<CustomButton
							className={'w-25 btn-blue fw-600'}
							title={t('Update')}
							onClick={() => sendForApproval()}
						/>
					</div>
				</div>
			)}

			{showSentForRework && (
				<div
					className='d-flex align-items-center justify-content-center bg-white w-100 h-100 green-top-linear-gradient'
					style={{ position: 'absolute', zIndex: 10, top: 0 }}
				>
					<div className='text-center'>
						<Player
							// className="pe-3"
							autoplay={true}
							loop={false}
							controls={false}
							keepLastFrame={true}
							onEvent={async (event) => {
								if (event === 'complete') {
									await dispatch(
										dmaFlowData({
											...dmaSelector,
											update: dmaSelector?.update + 1 || 1,
										})
									);
									setTimeout(() => {
										history.push('/team-list');
										setShowSentForRework(false);
									}, 500);
								}
							}}
							src='/images/icons/json/SendforRework.json'
							style={{ height: '102px', width: '102px' }}
						/>
						<h3 className='fs-18 fw-600 darkest-grey'>
							{t('Request Sent for Rework')}
						</h3>
					</div>
				</div>
			)}
		</IonPage>
	);
};
