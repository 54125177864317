import {
	IonAccordion,
	IonAccordionGroup,
	IonContent,
	IonItem,
	IonLabel,
	IonList,
	IonPage,
} from '@ionic/react';
import { Header } from '../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { CustomCheckbox } from '../../components/common/custom-checkbox/CustomCheckbox';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getdmaAbout,
	teamDmaPayoutList,
} from '../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';

export const UpdateDetail: React.FC = () => {
	const { t } = useTranslation();
	const dmaSelector = useSelector((state: any) => state?.users);
	const history = useHistory();
	const [dmaInfo, setDmaInfo] = useState<any>();
	useEffect(() => {
		getDmainfo();
		getPayout();
	}, []);
	const dispatch: any = useDispatch();

	const getDmainfo = async () => {
		await dispatch(getdmaAbout({ param: dmaSelector?.dmaFlow?.dmaId }))
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setDmaInfo(response?.data);
				}
			});
	};

	const getPayout = async () => {
		await dispatch(teamDmaPayoutList());
	};
	return (
		<IonPage className='teams'>
			<Header
				needArrow={true}
				backButton={true}
				name={t('Update Details')}
			/>

			<IonContent
				style={{
					borderTop: '1px solid #00000029',
					height: 'calc(100vh - 113px)',
					overflowX: 'hidden',
				}}
			>
				<div className='scroll-navbar ion-padding d-flex gap-4'>
					<CustomButton
						fill={'none'}
						className='d-block bg-transparent active'
						title={t('User Details')}
					/>

					<CustomButton
						fill={'none'}
						className='d-block bg-transparent'
						title={t('PAN Card')}
					/>
					<CustomButton
						fill={'none'}
						className='d-block bg-transparent'
						title={t('Aadhaar Card')}
					/>

					<CustomButton
						fill={'none'}
						className='d-block bg-transparent'
						title={t('Passport Size Photo')}
					/>
					<CustomButton
						fill={'none'}
						className='d-block bg-transparent'
						title={t('GST Certificate')}
					/>
					<CustomButton
						fill={'none'}
						className='d-block bg-transparent'
						title={t('Address Proof')}
					/>
					<CustomButton
						fill={'none'}
						className='d-block bg-transparent'
						title={t('Bank Details')}
					/>
				</div>
				<div className='bg-dark ion-padding d-flex gap-3'>
					<img
						src='/images/icons/warning-icon.svg'
						alt='warning'
					/>
					<p className='mb-0 text-white fs-14'>Need Professional Email ID.</p>
				</div>
				<div className='details'>
					<IonList>
						<div className='d-flex mt-3 justify-content-between align-items-center px-3'>
							<h4 className='fw-bold fs-14 heading-color'>{t('Basic Info')}</h4>
							<CustomButton
								onClick={(e: any) => {
									e.preventDefault();
									history.push('/kyc', {
										nextScreen: 'KYC1',
										from: 'update-details',
										title: 'Basic Info',
									});
								}}
								fill='outline'
								title={t('Edit')}
							/>
						</div>

						<IonItem
							className='ion-no-padding'
							lines='full'
						>
							<div className='d-flex flex-grow-1 justify-content-between px-3'>
								<p className='mb-0 fs-14'>{t('First Name')}</p>
								<p className='mb-0 fs-14'>NA</p>
							</div>
						</IonItem>
						<IonItem
							className='ion-no-padding'
							lines='full'
						>
							<div className='d-flex flex-grow-1 justify-content-between px-3'>
								<p className='mb-0 fs-14'>{t('Middle Name')}</p>
								<p className='mb-0 fs-14'>NA</p>
							</div>
						</IonItem>
						<IonItem
							className='ion-no-padding'
							lines='full'
						>
							<div className='d-flex flex-grow-1 justify-content-between  px-3'>
								<p className='mb-0 fs-14'>{t('Last Name')}</p>
								<p className='mb-0 fs-14'>NA</p>
							</div>
						</IonItem>
						<IonItem
							className='ion-no-padding'
							lines='full'
						>
							<div className='d-flex flex-grow-1 justify-content-between px-3'>
								<p className='mb-0 fs-14'>{t('Mobile')}</p>
								<p className='mb-0 fs-14'>
									{dmaInfo?.mobile ? dmaInfo?.mobile : 'NA'}
								</p>
							</div>
						</IonItem>
						<IonItem
							className='ion-no-padding'
							lines='full'
						>
							<div className='d-flex flex-grow-1 justify-content-between px-3'>
								<p className='mb-0 fs-14'>{t('Alternate Mobile No.')}</p>
								<p className='mb-0 fs-14'>NA</p>
							</div>
						</IonItem>
						<IonItem
							className='ion-no-padding'
							lines='full'
						>
							<div className='d-flex flex-grow-1 justify-content-between px-3'>
								<p className='mb-0 fs-14'>{t('Email')}</p>
								<p className='mb-0 fs-14'>
									{dmaInfo?.email ? dmaInfo?.email : 'NA'}
								</p>
							</div>
						</IonItem>
						<div className='d-flex mt-3 justify-content-between align-items-center px-3'>
							<h4 className='fw-bold fs-14 heading-color'>
								{t('Professional Info')}
							</h4>
							<CustomButton
								onClick={() => {
									history.push('/kyc', {
										nextScreen: 'KYC3',
										from: 'update-details',
										title: 'Professional Info',
									});
								}}
								fill='outline'
								title={t('Edit')}
							/>
						</div>

						<IonItem
							className='ion-no-padding'
							lines='full'
						>
							<div className='d-flex flex-grow-1 justify-content-between px-3'>
								<p className='mb-0 fs-14'>{t('Pincode')}</p>
								<p className='mb-0 fs-14'>
									{dmaInfo?.pincode ? dmaInfo?.pincode : 'NA'}
								</p>
							</div>
						</IonItem>
						<IonItem
							className='ion-no-padding'
							lines='full'
						>
							<div className='d-flex flex-grow-1 justify-content-between px-3'>
								<p className='mb-0 fs-14'>{t('City')}</p>
								<p className='mb-0 fs-14'>
									{dmaInfo?.city ? dmaInfo?.city : 'NA'}
								</p>
							</div>
						</IonItem>
						<IonItem
							className='ion-no-padding'
							lines='full'
						>
							<div className='d-flex flex-grow-1 justify-content-between px-3'>
								<p className='mb-0 fs-14'>{t('State')}</p>
								<p className='mb-0 fs-14'>
									{dmaInfo?.state ? dmaInfo?.state : 'NA'}
								</p>
							</div>
						</IonItem>
						<IonItem
							className='ion-no-padding'
							lines='full'
						>
							<div className='d-flex flex-grow-1 justify-content-between px-3'>
								<p className='mb-0 fs-14'>{t('Current Business')}</p>
								<p className='mb-0 fs-14'>
									{dmaInfo?.CurrentBusiness ? dmaInfo?.CurrentBusiness : 'NA'}
								</p>
							</div>
						</IonItem>
						<IonItem
							className='ion-no-padding'
							lines='full'
						>
							<div className='d-flex flex-grow-1 justify-content-between px-3'>
								<p className='mb-0 fs-14'>{t('Associated with DSA')}</p>
								<p className='mb-0 fs-14'>
									{dmaInfo?.DSAAssociation ? dmaInfo?.DSAAssociation : 'NA'}
								</p>
							</div>
						</IonItem>
						<IonItem
							className='ion-no-padding'
							lines='full'
						>
							<div className='d-flex flex-grow-1 justify-content-between px-3'>
								<p className='mb-0 fs-14'>{t('Product')}</p>
								<p className='mb-0 fs-14'>
									{dmaInfo?.ProductName ? dmaInfo?.ProductName : 'NA'}
								</p>
							</div>
						</IonItem>
						<IonItem
							className='ion-no-padding'
							lines='full'
						>
							<div className='d-flex flex-grow-1 justify-content-between px-3'>
								<p className='mb-0 fs-14'>{t('Monthly Business Volume')}</p>
								<p className='mb-0 fs-14'>
									{dmaInfo?.MonthlyBusinessVolumeInLakhs
										? dmaInfo?.MonthlyBusinessVolumeInLakhs
										: 'NA'}
								</p>
							</div>
						</IonItem>
						<div>
							<IonAccordionGroup>
								<IonAccordion>
									<IonItem
										slot='header'
										lines='none'
									>
										<IonLabel>{t('Loan')}</IonLabel>
									</IonItem>
									<div
										className='ion-padding'
										slot='content'
									>
										<div className='d-flex header justify-content-between mb-2'>
											<div className='w-50 fs-14 fw-600'>Subproduct</div>
											<div className='w-50 fs-14 fw-600 text-end'>
												{t('Proposed Payout')}
											</div>
										</div>
										<div className='d-flex header justify-content-between'>
											<div className='w-50 fs-14'>Home Loan</div>
											<div className='w-50 fs-14 text-end'>0.01%</div>
										</div>
										<div className='d-flex header justify-content-between'>
											<div className='w-50 fs-14'>Loan Against property</div>
											<div className='w-50 fs-14 text-end'>0.02%</div>
										</div>
									</div>
								</IonAccordion>
							</IonAccordionGroup>
						</div>
					</IonList>
				</div>
			</IonContent>
			<div className='d-flex gap-3 ion-padding flex-column'>
				<div
					className='d-flex align-items-center gap-3 text-dark
				'
				>
					<CustomCheckbox /> {t('Mark this rework as completed')}
				</div>
				<CustomButton
					className={'w-100'}
					title={'Proceed'}
				/>
			</div>
		</IonPage>
	);
};
