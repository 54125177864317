import * as Yup from 'yup';
import { REGEX } from '../../../helpers/constant';

export const TataPLSchema = Yup.object({
	// perm_city: Yup.string().required('Permanent City is required'),
	// perm_state: Yup.string().required('Permanent State is required'),
	req_irr: Yup.string()
		.trim()
		.matches(/^(?:\d{0,2}(\.\d{1,2})?)$/, 'Please Enter Valid Requested IRR')
		.max(5, 'Requested IRR maximum be 2 Digits')
		.required('Requested IRR is Required'),
	// destination: Yup.string().required('Destination Type is required'),
});

export const TataPLRef1Schema = Yup.object({
	ReferenceFirstName1: Yup.string().required(
		'Reference First Name  is required'
	),
	ReferenceLastName1: Yup.string().required('Reference Last Name is required'),
	ReferenceMobileNumber1: Yup.string()
		.max(10, 'Mobile No. Should Be 10 Digits.')
		.required('Mobile is Required.')
		.matches(/^\d+$/, 'Mobile No. Should Contain Only Digits.')
		.matches(REGEX.INDIAN_MOBILE_NUMBERS, 'Please Enter Valid Number.'),
	ReferenceState1: Yup.string().required('Reference State is required'),
	ReferenceCity1: Yup.string().required('Reference City is required'),
	ReferencePincode1: Yup.string()
		.trim()
		.required('Pin Code Number is Required')
		.matches(/^\d{6}$/, 'Pincode should be 6 Digits')
		.length(6, 'Office Pincode should be 6 Digits'),
	ReferenceAddress1: Yup.string().required('Reference Address is required'),
	ReferenceLandmark1: Yup.string().required('Reference Landmark is required'),
	ApplicantRelationship1: Yup.string().required(
		'Applicant Relationship is required'
	),
});

export const TataPLRef2Schema = Yup.object({
	ReferenceFirstName2: Yup.string().required(
		'Reference First Name  is required'
	),
	ReferenceLastName2: Yup.string().required('Reference Last Name is required'),
	ReferenceMobileNumber2: Yup.string()
		.max(10, 'Mobile No. Should Be 10 Digits.')
		.required('Mobile is Required.')
		.matches(/^\d+$/, 'Mobile No. Should Contain Only Digits.')
		.matches(REGEX.INDIAN_MOBILE_NUMBERS, 'Please Enter Valid Number.'),
	ReferenceState2: Yup.string().required('Reference State is required'),
	ReferenceCity2: Yup.string().required('Reference City is required'),
	ReferencePincode2: Yup.string()
		.trim()
		.required('Pin Code Number is Required')
		.matches(/^\d{6}$/, 'Pincode should be 6 Digits')
		.length(6, 'Office Pincode should be 6 Digits'),
	ReferenceAddress2: Yup.string().required('Reference Address is required'),
	ReferenceLandmark2: Yup.string().required('Reference Landmark is required'),
	ApplicantRelationship2: Yup.string().required(
		'Applicant Relationship is required'
	),
});

export const TataBLUploadDocSchema = Yup.object({
	documents: Yup.array().of(
		Yup.object({
			docUploadType: Yup.string().required('Document Type is required'),
			file: Yup.string().required('Document is required'),
		})
	),
});

export const TataPLUploadDocSchema = Yup.object({
	documents: Yup.array().of(
		Yup.object({
			docUploadType: Yup.string().required('Document Type is required'),
			file: Yup.string().required('Document is required'),
		})
	),
});

export const TataPLFormTitle = {
	MOBILE_NO_VERIFICATION: 'Verify Mobile No',
	ADDRESS_INFO: 'Address Info',
	CKYC: 'C-KYC',
	UPLOAD_DOCUMENTS: 'Upload Document',
	REFERENCE_ONE: 'Reference One',
	REFERENCE_TWO: 'Reference Two',
};

export const customerType = [{ value: 'Individual' }, { value: 'Corporate' }];
export const referenceType = [{ value: 'Relative' }, { value: 'Friend' }];

export const userInfoPL = [
	// {
	// 	name: 'perm_city',
	// 	type: 'dropdown',
	// 	placeholder: 'Permanent City *',
	// },
	// {
	// 	name: 'perm_state',
	// 	type: 'text',
	// 	placeholder: 'Permanent State *',
	// },
	{
		name: 'req_irr',
		type: 'text',
		placeholder: 'Requested IRR *',
	},
	// {
	// 	name: 'destination',
	// 	type: 'dropdown',
	// 	placeholder: 'Select Destination *',
	// },
];

export const refDetailsOne = [
	{
		name: 'ReferenceFirstName1',
		type: 'text',
		placeholder: 'Reference 1 First Name (as per Aadhaar) *',
	},
	{
		name: 'ReferenceLastName1',
		type: 'text',
		placeholder: 'Reference 1 Last Name (as per Aadhaar) *',
	},
	{
		name: 'ReferenceMobileNumber1',
		type: 'text',
		placeholder: 'Reference 1 Mobile Number *',
	},
	{
		name: 'ReferenceAddress1',
		type: 'text',
		placeholder: 'Reference 1 Address  *',
	},
	{
		name: 'ReferenceLandmark1',
		type: 'text',
		placeholder: 'Reference 1 Landmark *',
	},

	{
		name: 'ApplicantRelationship1',
		type: 'dropdown',
		placeholder: 'Reference 1 Applicant Relationship  *',
	},
	{
		name: 'ReferencePincode1',
		type: 'text',
		placeholder: 'Reference 1 Pincode *',
	},
];

export const refDetailsTwo = [
	{
		name: 'ReferenceFirstName2',
		type: 'text',
		placeholder: 'Reference 2 First Name (as per Aadhaar) *',
	},
	{
		name: 'ReferenceLastName2',
		type: 'text',
		placeholder: 'Reference 2 Last Name (as per Aadhaar) *',
	},
	{
		name: 'ReferenceMobileNumber2',
		type: 'text',
		placeholder: 'Reference 2 Mobile Number *',
	},
	{
		name: 'ReferenceAddress2',
		type: 'text',
		placeholder: 'Reference 2 Address  *',
	},
	{
		name: 'ReferenceLandmark2',
		type: 'text',
		placeholder: 'Reference 2 Landmark *',
	},

	{
		name: 'ApplicantRelationship2',
		type: 'dropdown',
		placeholder: 'Reference 2 Applicant Relationship  *',
	},
	{
		name: 'ReferencePincode2',
		type: 'text',
		placeholder: 'Reference 2 Pincode *',
	},
];

export const destinationType = [
	{ value: 'Statement' },
	{ value: 'Aggregator' },
	{ value: 'GST' },
	{ value: 'Net Banking Fetch' },
];

export const destinationValue = [
	{ Statement: 'statement' },
	{ Aggregator: 'Aggregator' },
	{ GST: 'gst' },
	{ Net_Banking_Fetch: 'netbankingFetch' },
];

export const loanPurposeBL = [
	{ title: 'Balance Transfer', value: 'PURCHASE_ON_IDENTIFIED_PROPERTY' },
	{ title: 'Business Expansion', value: 'BUSINESS-EXPANSION' },
	{ title: 'Business Purpose', value: 'PRONOTIDENTIFIED' },
	{ title: 'Business Purpose', value: 'LAP_BUS_PUR' },
	{ title: 'Business Working Capital', value: 'BUSINESS-WORKING-CAPITAL' },
	{ title: 'Education Purpose', value: 'EDUCATION_PURPOSE' },
	{ title: 'Extension/ Improvement/Renovation', value: 'LAP_RE_IM' },
	{ title: 'Family Celebration', value: 'FAMILY_CELEBRATION' },
	{ title: 'Health and Wellness', value: 'HEALTH_AND_WELLNESS' },
	{ title: 'Home Balance Transfer', value: 'HLBT' },
	{ title: 'Home Balance Transfer and Top Up', value: 'HLBTTOP' },
	{ title: 'Home BT - Under Construction', value: 'BTUCONST' },
	{ title: 'Home Construction', value: 'HL_CONST' },
	{ title: 'Home Construction', value: 'LAP_CONST' },
	{ title: 'Home Energy Efficient', value: 'ENERGY' },
	{ title: 'Home for Women', value: 'WOMEN' },
	{ title: 'Home-Loan Online', value: 'ONLINE' },
	{ title: 'Home Renovation', value: 'PLHomeRenovation' },
	{ title: 'LAP', value: 'LAP' },
	{ title: 'Marriage', value: 'PLMarriage' },
	{ title: 'Medical Expense', value: 'MEDICAL_EXPENSE' },
	{ title: 'Memorial Service', value: 'MEMORIAL_SERVICE' },
	{ title: 'Multipurpose', value: 'MULTIPURPOSE' },
	{ title: 'New Asset Purchase', value: 'CLNAP' },
	{ title: 'New Asset Purchase', value: 'New Asset Purchase' },
	{ title: 'New Vehicle Purchase', value: 'CRP' },
	{ title: 'Purchase of NRP', value: 'Purchase of NRP' },
	{ title: 'OnlyConstruction', value: 'LAP_Ocons' },
	{ title: 'Only Construction', value: 'HL_Ocons' },
	{ title: 'Others', value: 'OTHERS' },
	{ title: 'Personal Use', value: 'LAP_PER_USE' },
	{ title: 'Personal Use', value: 'HL_PER_USE' },
	{ title: 'Plot Purchase and Self Construction', value: 'HLPLOT' },
	{ title: 'Property Purchase', value: 'MICRO_HOUSING_LOAN_PURPOSE' },
	{ title: 'Purchase Of Commercial Property', value: 'LAP_PCP' },
	{ title: 'Purchase Of Residential Property', value: 'TRANSFER_EXISTING_HL' },
	{
		title: 'Purchase Of Residential Property For Self Occupation',
		value: 'MHL_PURCHASE',
	},
	{ title: 'Purchasing Vehicle', value: 'MHL_PUR_VEH' },
	{ title: 'Refinance', value: 'AL_REF' },
	{ title: 'Home Refinance', value: 'HLREF' },
	{ title: 'Refinance Existing Vehicle', value: 'CVREA' },
	{ title: 'Refinance Existing Vehicle', value: 'REF' },
	{ title: 'RHF-Nano', value: 'NANO' },
	{ title: 'Self Construction', value: 'HLSELF' },
	{ title: 'Special Offer - SALARIED', value: 'SPLSAL' },
	{ title: 'Special Offer - SENP', value: 'SPLSENP' },
	{ title: 'Cash Out', value: 'HL_Take' },
	{ title: 'Cash Out', value: 'LAP_Take' },
	{ title: 'Top Up', value: 'PLTopUp' },
	{ title: 'Top Up/ Additonal Funding', value: 'Top Up/ Additonal Funding' },
	{ title: 'TopUp/ Enhancement', value: 'LAP_Top' },
	{ title: 'Top Up/Enhancement', value: 'HL_Top' },
	{ title: 'TravelEMI', value: 'PLTravelEMI' },
	{ title: 'Used Vehicle Purchase', value: 'CVUAP' },
	{ title: 'Used Vehicle Purchase', value: 'PUU' },
	{ title: 'Vacation', value: 'VACATION' },
	{ title: 'Balance Transfer', value: 'LAP_BUS_TRF' },
	{ title: 'Direct Allotment', value: 'DRCTALLOT' },
	{ title: 'Home Builder Purchase(Ready Property)', value: 'HLREADY' },
	{ title: 'Home Builder Purchase(Under Construction)', value: 'HLUCONST' },
	{ title: 'Home Purchase(Resale)', value: 'RESALE' },
	{ title: 'Balance Transfer and Top up', value: 'LAP_BTTOPUP' },
	{ title: 'Home Renovation / Improvement', value: 'HL_RE_IM' },
	{ title: 'Education Purpose', value: 'LAP_EDU_PUR' },
	{ title: 'Enhancement', value: 'HEENH' },
	{ title: 'Refinance', value: 'HEREFIN' },
	{ title: 'Top up/ Additional Funding', value: 'Top up/ Additional Funding' },
];

export const accommodationTypeBL = [
	{ value: 'Bachelor Accommodation' },
	{ value: 'Company Provided' },
	{ value: 'Hostel' },
	{ value: 'Owned by Parent/Sibling' },
	{ value: 'Owned by Self/ Spouse' },
	{ value: 'Paying Guest' },
	{ value: 'Relative' },
	{ value: 'Rented' },
	{ value: 'Detail Not Available' },
];

export const correspondingFinancialYearBL = [
	{ value: 'FY 17-19' },
	{ value: 'FY 16-17' },
	{ value: 'FY 17-18' },
	{ value: 'FY 15-16' },
	{ value: 'FY 14-15' },
];

export const documentTypeBL = [
	{ value: 'Id Proof' },
	{ value: 'Signature Mismatch Declaration' },
	{ value: 'FI Document' },
	{ value: 'Income Proof' },
	{ value: 'Cibil_Consent' },
	{ value: 'Additional Documents' },
];
