import React, { useEffect, useRef, useState } from 'react';
import {
	IonButtons,
	IonContent,
	//IonImg,
	IonPage,
	//useIonViewDidEnter,
} from '@ionic/react';
import { Header } from '../../components/layout/header/Header';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import './filter.scss';
//import { CustomCheckbox } from '../../components/common/custom-checkbox/CustomCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
//import 'react-bootstrap-typeahead/css/Typeahead.css';
//import { CustomInput } from '../../components/common/custom-input/CustomInput';
//import CustomDropDown from '../../components/common/custom-dropdown/CustomDropDown';
import {
	getCitiesNew,
	getDesignations,
	productCosnultationFilter,
	resetLeadFilterState,
} from '../../store/reducers/lead/leadSlice';
export const ConsultationFilters: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();
	const dispatch: any = useDispatch();
	const [cities, setCities] = useState([]);
	const [new1, setNew1] = useState<any>({});
	const [name1, setName] = useState('cstCity');
	const productFilterSlctr: any = useSelector((state: any) => state?.lead);
	const [block, setblock] = useState(true);

	const [citySelection, setCitySelection] = useState<any>();
	const [designationSelection, setDesignationSelection] = useState<any>();
	const [designations, setDesignations] = useState<any>([]);

	//const [subProducts, setSubProducts] = useState<any>([]);

	useEffect(() => {
		if (location?.pathname !== '/consultation-filter') {
			return;
		}
		setblock(true);
		getProductFilter();
	}, [location.pathname]);
	useEffect(() => {
		if (productFilterSlctr?.citySelection !== '') {
			setCitySelection(productFilterSlctr?.citySelection);
		} else {
			refCity?.current?.clear();
			setCitySelection([]);
		}
		if (productFilterSlctr?.designationSelection !== '') {
			setDesignationSelection(productFilterSlctr?.designationSelection);
		} else {
			setDesignationSelection([]);

			refDsg?.current?.clear();
		}
	}, [
		productFilterSlctr?.citySelection,
		productFilterSlctr?.designationSelection,
		location.pathname,
	]);
	const getProductFilter = async () => {
		await dispatch(getCitiesNew())
			.then(unwrapResult)
			.then(async (result: any) => {
				if (result.status) {
					const data = result?.data?.map((el: any, index: any) => {
						return {
							id: index,
							name: el?.location,
							value: el?.location,
							label: el?.location,
							checked: false,
						};
					});
					setCities(data);
					await dispatch(getDesignations())
						.then(unwrapResult)
						.then((result: any) => {
							if (result?.status) {
								setDesignations(
									result?.data?.map((el: any, index: any) => {
										return {
											id: index,
											name: el?.designation,
											value: el?.designation,
											label: el?.designation,
											checked: false,
										};
									})
								);
								setTimeout(() => {
									setblock(false);
								}, 700);
							}
						});
				}
			});

		if (productFilterSlctr?.citySelection?.length === 0) {
			refCity?.current?.clear();
		}
		if (productFilterSlctr?.designationSelection?.length === 0) {
			refDsg?.current?.clear();
		}
	};
	const refCity: any = useRef();
	const refDsg: any = useRef();

	useEffect(() => {
		if (!citySelection) {
			setCitySelection([]);
			refCity.current?.clear();
		}

		if (!designationSelection) {
			setDesignationSelection([]);
			refDsg.current?.clear();
		}
	}, [
		productFilterSlctr?.citySelection,
		productFilterSlctr?.designationSelection,
	]);

	useEffect(() => {
		setNew1({
			cstCity: cities,
			cstDesignation: designations,
		});
	}, [cities, designations]);

	useEffect(() => {
		getDmaFilter('', '');
	}, [new1]);

	const buttonNames = [
		{ id: 0, value: 'City', key: 'cstCity' },
		{ id: 1, value: 'Designation', key: 'cstDesignation' },
	];

	/* const filterData = [
		{ name: 'cstCity', type: 'autocomplete', optionList: new1?.cstCity },
		{ name: 'cstDesignation', type: 'autocomplete', optionList: new1?.cstCity },
	]; */
	const getDmaFilter = async (name: any, data: any) => {
		let productFltr;
		if (name) {
			productFltr = data;
		} else {
			productFltr = new1?.[name1]
				?.filter(({ value, checked }: any) => checked && value)
				.map(({ value, id }: any) => (['cstRm'].includes(name1) ? id : value))
				.toString()
				.toLowerCase()
				.replace(/,/g, '::')
				.replaceAll(' :: ', ' , ');
		}

		dispatch(
			productCosnultationFilter({
				...productFilterSlctr,
				[name1]: productFltr,
			})
		);
	};
	useEffect(() => {
		const cityData =
			productFilterSlctr?.citySelection &&
			productFilterSlctr?.citySelection
				?.map(({ value, id }: any) => (['cstRm'].includes(name1) ? id : value))
				.toString()
				.toLowerCase()
				.replace(/,/g, '::')
				.replaceAll(' :: ', ' , ');
		const dsgData =
			productFilterSlctr?.designationSelection &&
			productFilterSlctr?.designationSelection
				?.map(({ value, id }: any) => (['cstRm'].includes(name1) ? id : value))
				.toString()
				.toLowerCase()
				.replace(/,/g, '::')
				.replaceAll(' :: ', ' , ');

		setTimeout(() => {
			dispatch(
				productCosnultationFilter({
					...productFilterSlctr,
					cstCity: cityData,
					cstDesignation: dsgData,
				})
			);
		}, 1000);
	}, [location.pathname, name1]);

	/* 	const handleFilter = (e: any, name: any, data: any) => {
		if (e) {
			const data1 = new1?.[name]?.map((i: any) => {
				if (i.value === data) {
					return {
						...i,
						checked: true,
					};
				} else {
					return { ...i };
				}
			});
			setNew1({ ...new1, [name]: data1 });
		} else {
			const data1 = new1?.[name]?.map((i: any) => {
				if (i.value === data) {
					return {
						...i,
						checked: false,
					};
				} else {
					return { ...i };
				}
			});
			setNew1({ ...new1, [name]: data1 });
		}
	}; */

	const clearFilter = async () => {
		refCity?.current?.clear();
		refDsg?.current?.clear();
		dispatch(resetLeadFilterState());
		setCitySelection([]);
		setDesignationSelection([]);
		getProductFilter();
	};

	const [selectedButton, setSelectedButton] = useState(0);

	const handleBackButton = () => {
		const myPromise = new Promise(function (myResolve) {
			setTimeout(function () {
				myResolve(history.push('/lender-loan-consultants'));
			}, 10);
		});
		myPromise.then(() => {
			dispatch(resetLeadFilterState());
			setCitySelection([]);
			setSelectedButton(0);
			setName('cstCity');
		});
	};
	useEffect(() => {
		const cityIds = citySelection?.map((t: any) => {
			return t?.id;
		});
		const updateCheckedCities = new1.cstCity?.map((item: any) => {
			return cityIds?.includes(item?.id)
				? {
						id: item?.id,
						name: item?.name,
						value: item?.value,
						label: item?.label,
						checked: true,
				  }
				: {
						id: item?.id,
						name: item?.name,
						value: item?.value,
						label: item?.label,
						checked: false,
				  };
		});

		setNew1({
			...new1,
			cstCity: updateCheckedCities,
		});
		dispatch(
			productCosnultationFilter({
				...productFilterSlctr,
				citySelection: citySelection,
			})
		);
	}, [citySelection]);

	useEffect(() => {
		const dsgIds = designationSelection?.map((t: any) => {
			return t?.id;
		});

		const updateCheckedDesignation = new1.cstDesignation?.map((item: any) => {
			return dsgIds?.includes(item?.id)
				? {
						id: item?.id,
						name: item?.name,
						value: item?.value,
						label: item?.label,
						checked: true,
				  }
				: {
						id: item?.id,
						name: item?.name,
						value: item?.value,
						label: item?.label,
						checked: false,
				  };
		});

		setNew1({
			...new1,
			cstDesignation: updateCheckedDesignation,
		});
		dispatch(
			productCosnultationFilter({
				...productFilterSlctr,
				designationSelection: designationSelection,
			})
		);
	}, [designationSelection]);

	return (
		<IonPage>
			<Header
				backButton={true}
				needArrow={true}
				needArrowOnClick={() => {
					handleBackButton();
				}}
				needArrowImg={'/images/icons/backarrow_white_bg.png'}
				needArrowImgStyle={{ height: '26px' }}
				name={t('Filters')}
				endItem={
					<IonButtons slot='end'>
						<IonButtons
							onClick={clearFilter}
							className='fs-14 fw-600 cursor-pointer'
							style={{ color: 'var(--ion-color-primary)' }}
						>
							{t('Clear Filter')}
						</IonButtons>
					</IonButtons>
				}
			/>
			<IonContent className='border'>
				<div className='row h-100'>
					<div className='col-5 pe-0'>
						{buttonNames.map((buttonName: any, index) => (
							<button
								key={index}
								className={`filterName btn-primary m-1 fs-14 fw-600 ${
									selectedButton === index ? 'selected' : ''
								}`}
								style={{
									backgroundColor:
										selectedButton === index ? 'white' : '#f1f1f1',
									color: selectedButton === index ? '#0165E1' : '#0C0E2B',
									border: 'none',
									borderRadius: '0',
								}}
								onClick={() => {
									setSelectedButton(index);
									setName(buttonName.key);
								}}
							>
								{t(buttonName?.value)}
							</button>
						))}
					</div>
					<div className='col-7 ps-0'>
						<div className='Flex ps-3'>
							{name1 && ['cstCity'].includes(name1) ? (
								<Typeahead
									id={'cstCity'}
									labelKey={'name'}
									multiple
									className='pe-3 mt-3'
									onChange={(e: any) => {
										setCitySelection(e);
									}}
									ref={refCity}
									options={
										new1?.cstCity
											? new1?.cstCity?.filter((el: any) => el.checked === false)
											: []
									}
									defaultSelected={citySelection}
									placeholder={t('Choose Cities').toString() + '...'}
									paginationText={t('Load More Cities')}
								/>
							) : (
								''
							)}
							{name1 && ['cstDesignation'].includes(name1) ? (
								<Typeahead
									id={'cstDesignation'}
									labelKey={'name'}
									multiple
									className='pe-3 mt-3'
									onChange={(e: any) => {
										setDesignationSelection(e);
									}}
									ref={refDsg}
									options={
										designations
											? designations.filter((el: any) => el.checked === false)
											: []
									}
									defaultSelected={designationSelection}
									placeholder={t('Choose Designations').toString() + '...'}
									paginationText={t('Load More Designations')}
								/>
							) : (
								''
							)}
						</div>
					</div>
				</div>
			</IonContent>
			<div className='footer border'>
				<div></div>
				<div>
					<button
						onClick={() => {
							setTimeout(() => {
								history.push('/lender-loan-consultants');
							}, 200);
						}}
						className='btn'
						disabled={block}
					>
						{t('Apply')}
					</button>
				</div>
			</div>
		</IonPage>
	);
};
