import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
import {
	IonButtons,
	IonContent,
	IonFooter,
	IonImg,
	IonItem,
	IonLabel,
	IonList,
	IonPage,
	isPlatform,
} from '@ionic/react';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
// import DropDownWitoutModal from '../../components/common/custom-dropdown/DropDownWitoutModal';
import { useEffect, useState } from 'react';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
import { useHistory } from 'react-router';
import { Header } from '../../components/layout/header/Header';
import DropDownModal from '../../components/common/custom-dropdown/DropDownModal';
import CustomDropDown from '../../components/common/custom-dropdown/CustomDropDown';
import { useDispatch, useSelector } from 'react-redux';
import {
	dashboardUpdateLead,
	equifaxAdditionalInfo,
	equifaxAdditionalInfoDetail,
	leadFlow,
	getLeadUmDetails,
	getLeadFlowUrl,
} from '../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	getBanksList,
	getCompanyNameList,
	getProfessionalDetailsMaster,
	getProfessionalFields,
} from '../../store/reducers/user/userSlice';
import './CommanInfo.scss';
import {
	ContinueLaterPopUp,
	InputFormatCurrencyValue,
	ShareData,
	capFirstLetter,
	checkFieldsValidToContinue,
	disableNumberArrows,
	eventAttributes,
	fetchStateCityByPincode,
	notifyHandler,
	restrictAmountChar,
	sanitizeHtml,
	showToastAlert,
} from '../../helpers/functions';
import CustomPopoverSearch from '../../components/common/custom-popover/CustomPopoverSearch';
import {
	LeadStatus,
	PAGINATION_DETAILS,
	REGEX,
	amountKeys,
} from '../../helpers/constant';
import StateCity from '../../components/common/StateCity';
import CustomAllocateManagers from '../../components/common/CustomAllocateManagers';
import { NotifyVisitors } from 'capacitor-plugin-notifyvisitors';

const validationSchema = yup.object().shape({});

const CcOutStandingModal = ({ leadID }: any) => {
	const { t } = useTranslation();
	const [ccInfo, setCcInfo] = useState<any>([]);
	const dispatch = useDispatch();

	const getEquifaxAdditionalInfoCC = async () => {
		await dispatch(equifaxAdditionalInfoDetail({ param: 'cc/' + leadID }))
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setCcInfo(response?.data);
				}
			});
	};
	// Notify Visitors
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const userName = useSelector(
		(state: any) => state?.users?.homepage?.profile?.name
	);
	const userMobile = useSelector(
		(state: any) => state?.users?.user_details?.mobile
	);
	const entityType = useSelector(
		(state: any) => state?.users?.user_details?.entityType
	);
	const userLang = useSelector(
		(state: any) => state?.oneTimeScreen?.language?.name
	);

	useEffect(() => {
		getEquifaxAdditionalInfoCC();
	}, []);

	// Notify Visitors
	const userAttributes = {
		userId: userId,
		attributes: {
			name: userName,
			id: userId,
			mobile: userMobile,
			user_language: userLang,
			entityType:
				entityType === 'Individual'
					? 'DMA'
					: entityType === 'BTM'
					? 'employee'
					: entityType,
		},
	};
	useEffect(() => {
		if (isPlatform('android') || isPlatform('ios')) {
			notifyHandler(
				'event',
				eventAttributes('add_lead_journey_professional_info')
			);
			notifyHandler('user', userAttributes.attributes);
			const inAppData = {
				tokens: { name: userAttributes },
				customRules: { banerrType: 'add lead journey professional info' },
			};
			NotifyVisitors.show(inAppData);
		} else {
			(window as any).nv('event', 'add_lead_journey_professional_info', 10, 1);
			(window as any).nv('user', userId, userAttributes.attributes);
		}
	}, []);

	return (
		<div className='p-3'>
			<IonList>
				<h4 className='fs-20 fw-600 text-dark mt-3'>
					{t('CC Outstanding')} ({ccInfo.length})
				</h4>
				{ccInfo?.map((data: any, index: any) => {
					return (
						<IonItem
							key={index}
							lines='none'
							className='ion-no-padding mb-0'
						>
							<IonLabel>
								<div className='d-flex justify-content-between'>
									<h2 className='fs-16 text-dark text-wrap'>{data?.account}</h2>
									<h2 className='fs-16 text-dark'>₹ {data?.amount}</h2>
								</div>
								<p className='fs-16 mb-0'>
									{data?.status.toLowerCase() === 'yes' ? 'Active' : 'Inactive'}
								</p>
							</IonLabel>
						</IonItem>
					);
				})}
			</IonList>
		</div>
	);
};

const ActiveEmiModal = ({ leadID }: any) => {
	const { t } = useTranslation();
	const [emiInfo, setEmiInfo] = useState<any>([]);
	const dispatch = useDispatch();

	const getEquifaxAdditionalInfoEMI = async () => {
		await dispatch(equifaxAdditionalInfoDetail({ param: 'loan/' + leadID }))
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setEmiInfo(response?.data);
				}
			});
	};

	useEffect(() => {
		getEquifaxAdditionalInfoEMI();
	}, []);

	return (
		<div className='p-3'>
			<IonList>
				<h4 className='fs-20 fw-600 text-dark mt-3'>
					{t('Active EMI')} ({emiInfo.length})
				</h4>
				{emiInfo?.map((data: any, index: any) => {
					return (
						<IonItem
							key={index}
							lines='none'
							className='ion-no-padding mb-0'
						>
							<IonLabel>
								<div className='d-flex justify-content-between'>
									<h2 className='fs-16 text-dark'>{t(data?.account)}</h2>
									<h2 className='fs-16 text-dark'>₹ {data?.amount}</h2>
								</div>
								<p className='fs-16 mb-0'>
									{data?.status.toLowerCase() === 'yes' ? 'Active' : 'Inactive'}
								</p>
							</IonLabel>
						</IonItem>
					);
				})}
			</IonList>
		</div>
	);
};

export default function ProfessionalInfoInputs() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [showAdditionalInfo, setShowAdditionalInfo] = useState<boolean>(false);
	const [additionalInfoData, setAdditionalInfoData] = useState<any>({});
	// const [companyName, setCompanyName] = useState([]);
	const [activeEmi, setActiveEmi] = useState(false);
	// const [additionalInfo, setAdditionalInfo] = useState(false);
	const [ccOutStanding, setCCOutStanding] = useState(false);
	const [openCompanyModal, setOpenCompanyModal] = useState(false);
	const [openBankModal, setOpenBankModal] = useState(false);
	const [professionalInfoFields, setProfessionalInfoFields] = useState<any>([]);
	//const [hiddenInfoFields, setHiddenInfoFields] = useState<any>([]);
	//const [showOtherFields, setShowOtherFields] = useState<any>([]);
	const [masterData, setMasterData] = useState<any>({});
	const [apiProcessed, setApiProcessed] = useState(false);
	const dmaSelector = useSelector((state: any) => state?.users);

	//const [validateYup, setValidateYup] = useState(validationSchema);
	const [list, setList] = useState([]);
	const [bankNamesSearchList, setBankNamesSearchList] = useState([]);
	const history = useHistory<any>();
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	const lasId = leadInfo?.selectedProductData?.product_category_id == 7;
	const newLeadApplicantSelector = useSelector(
		(state: any) => state?.lead?.added_lead?.newLoanApplication
	);
	const [fieldStates, setFieldStates] = useState<any>({
		hiddenInfoFields: [],
		showOtherFields: [],
		validateYup: yup.object().shape({}),
	});
	const [pinCodeData, setPinCodeData] = useState<any>([]);
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	const dropdownTypes = ['dropdown', 'dropdownSelect', 'dropdownModal'];
	const othersTextValue = ['other', 'others'];
	const monthsFields = [
		'work_experience_in_current_company',
		'total_work_experience',
		'in_business_since',
		'current_residence_stability',
	];
	const [showAllocateManagerModal, setShowAllocateManagerModal] =
		useState<boolean>(false);
	const capitalizeFields = ['gst_number'];
	const [modalPageClose, setModalPageClose] = useState(false);
	let continueLaterFlag = false;
	const userData = useSelector((state: any) => state?.users?.user_details);

	// Notify Visitors
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const userName = useSelector(
		(state: any) => state?.users?.homepage?.profile?.name
	);
	const userMobile = useSelector(
		(state: any) => state?.users?.user_details?.mobile
	);

	const {
		handleSubmit,
		control,
		//watch,
		reset,
		setValue,
		trigger,
		getValues,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(fieldStates.validateYup),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const onSubmit = async (data: any) => {
		const setData: any = {};
		console.log(getValues());

		professionalInfoFields?.map((field: any) => {
			if (!fieldStates['hiddenInfoFields'].includes(field.name)) {
				if (field.type === 'dropdownModal') {
					if (field.name === 'company_type') {
						const fieldValue = masterData[field.name]
							.filter((item: any) => item.checked)
							.map(({ value }: any) => value);
						setData[field.name] = fieldValue[0];
					} else {
						setData[field.name] = field.name;
					}
				} else if (field.type === 'dropdownSelect') {
					const selectedOption = masterData[field?.name]?.filter(
						(item: any) => item.id === data[field?.name]?.toString()
					);
					setData[field.name] = data[field.name]
						? selectedOption[0]?.value //masterData[field?.name][data[field?.name] - 1]?.value
						: '';
				} else {
					setData[field.name] = capitalizeFields.includes(field.name)
						? data[field.name]
							? data[field.name].toUpperCase()
							: data[field.name]
						: data[field.name];
				}

				if (field.others) {
					setData[field.others_key] = data[field.others_key];
				}
				setData['continue_later'] = continueLaterFlag;
				setData['status'] = LeadStatus.PROCESSING;
			}
		});

		await dispatch(
			dashboardUpdateLead({
				param: leadInfo?.leadId
					? leadInfo?.leadId
					: newLeadApplicantSelector?.id,
				data: {
					...setData,
					property_city: getValues('property_city'),
					property_state: getValues('property_state'),
					loginUserId: userData?.id,
				},
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					const myPromise = new Promise(function (myResolve) {
						setTimeout(function () {					
							myResolve(
									leadInfo?.selectedProductData?.product_category_id == 7 && !continueLaterFlag 
									?  history.push('/selectHoldings') 
									:   !continueLaterFlag 
									?  history.push('/lenders-list')
									: leadInfo?.from === 'nimbuss'
									? history.push(
											'/lead-details/' +
												leadInfo.leadId +
												'/' +
												dmaSelector?.user_details?.access_token
									  )
									: history.push('/dashboard/lead')
							);
						}, 10);
					});
					myPromise.then(() => {
						setList([]);
						setBankNamesSearchList([]);
						reset({});
						setPinCodeData([]);
						dispatch(
							leadFlow({
								...leadInfo,
								leadId: leadInfo?.leadId,
							})
						);
					});
				}
			});
	};

	const getProfessionalInfoFields = async () => {
		//Reset Fields and Validation on page Load
		setProfessionalInfoFields([]);
		setFieldStates({
			hiddenInfoFields: [],
			showOtherFields: [],
			validateYup: yup.object().shape({}),
		});
		await dispatch(
			getProfessionalFields({
				param: leadInfo?.leadId
					? leadInfo?.leadId
					: newLeadApplicantSelector?.id,
			})
		)
			.then(unwrapResult)
			.then((reponse: any) => {
				if (reponse?.status) {
					initiateValidation(reponse?.fields);
					setProfessionalInfoFields(reponse?.fields);
					const total_api_count = reponse?.fields?.length;
					let total_api_processed = 0;
					reponse?.fields?.forEach((parameter: any) => {
						total_api_processed++;
						if (parameter.api_param) {
							dispatch(
								getProfessionalDetailsMaster({ param: parameter.api_param })
							)
								.then(unwrapResult)
								.then((res: any) => {
									if (res?.status) {
										setMasterData((masterData: any) => {
											return { ...masterData, [parameter.name]: res.data };
										});
										if (total_api_processed === total_api_count) {
											setTimeout(() => {
												setApiProcessed(true);
											}, 200);
										}
									}
								});
						}
					});
					reset({});
					setPinCodeData([]);
				}
			});
	};

	const initiateValidation = (fields: any = []) => {
		const shapeObj: any = {};
		fields?.forEach((parameter: any) => {
			if (!fieldStates['hiddenInfoFields'].includes(parameter.name)) {
				if (parameter.is_required) {
					if (parameter.is_pincode) {
						shapeObj[parameter.city] = yup
							.string()
							.required(parameter.city + ' is a required field');

						shapeObj[parameter.state] = yup
							.string()
							.required(parameter.state + ' is a required field');
					}
					if (amountKeys.includes(parameter.name)) {
						if (
							['net_monthly_income', 'annual_turnover'].includes(parameter.name)
						) {
							shapeObj[parameter.name] = yup
								.number()
								.min(
									1,
									parameter.placeholder.replaceAll('*', '') +
										' should be greater than zero'
								)
								.typeError('This field required only numbers')
								.required(
									parameter.placeholder.replaceAll('*', '') +
										' is a required field'
								);
						} else if (parameter.name === 'net_profit') {
							shapeObj[parameter.name] = yup
								.number()
								.required(
									parameter.placeholder.replaceAll('*', '') +
										' is a required field'
								)
								.typeError(
									parameter.placeholder.replaceAll('*', '') +
										' is a required field'
								)
								.test(
									'net_profit_compare',
									parameter.placeholder.replaceAll('*', '') +
										' should not be more than Annual Turnover',
									function (net_profit: number): boolean {
										return net_profit
											? net_profit <= this.parent.annual_turnover
											: true;
									}
								);
						} else {
							shapeObj[parameter.name] = yup
								.number()
								.typeError('This field required only numbers')
								.required(
									parameter.placeholder.replaceAll('*', '') +
										' is a required field'
								);
						}
					} else if (dropdownTypes.includes(parameter.type)) {
						if (parameter.name === 'company_type') {
							shapeObj[parameter.name] = yup
								.array()
								.required(
									parameter.placeholder.replaceAll('*', '') +
										' is a required field'
								);
						} else {
							shapeObj[parameter.name] = yup
								.number()
								.required(
									parameter.placeholder.replaceAll('*', '') +
										' is a required field'
								);
						}
					} else if (parameter.type === 'number') {
						if (parameter.name === 'total_work_experience') {
							shapeObj[parameter.name] = yup
								.number()
								.required(
									parameter.placeholder.replaceAll('*', '') +
										' is a required field'
								)
								.max(
									999,
									parameter.placeholder.replaceAll('*', '') + ' max of 3 digits'
								)
								.typeError(
									parameter.placeholder.replaceAll('*', '') +
										' is a required field'
								)
								.test(
									'total_experience_compare',
									parameter.placeholder.replaceAll('*', '') +
										' should not be less than Work experience in current company',
									function (total_work_experience: number): boolean {
										return total_work_experience
											? total_work_experience >=
													this.parent.work_experience_in_current_company
											: true;
									}
								);
						} else if (
							parameter.name === 'work_experience_in_current_company'
						) {
							shapeObj[parameter.name] = yup
								.number()
								.required(
									parameter.placeholder.replaceAll('*', '') +
										' is a required field'
								)
								.max(
									999,
									parameter.placeholder.replaceAll('*', '') + ' max of 3 digits'
								)
								.typeError(
									parameter.placeholder.replaceAll('*', '') +
										' is a required field'
								)
								.test(
									'experience_compare',
									parameter.placeholder.replaceAll('*', '') +
										' should not be more than Total work experience',
									function (
										work_experience_in_current_company: number
									): boolean {
										return work_experience_in_current_company
											? work_experience_in_current_company <=
													this.parent.total_work_experience
											: true;
									}
								);
						} else {
							shapeObj[parameter.name] = parameter.match_regex
								? yup
										.string()
										.required(
											parameter.placeholder.replaceAll('*', '') +
												' is a required field'
										)
										.matches(
											new RegExp(parameter.match_regex),
											monthsFields.includes(parameter.name)
												? parameter.placeholder.replaceAll('*', '') +
														' max of 3 digits'
												: parameter.placeholder.replaceAll('*', '') +
														' should be a valid format'
										)
								: yup
										.string()
										.required(
											parameter.placeholder.replaceAll('*', '') +
												' is a required field'
										);
						}
					} else if (parameter.type === 'text') {
						shapeObj[parameter.name] = parameter.match_regex
							? yup
									.string()
									.required(
										parameter.placeholder.replaceAll('*', '') +
											' is a required field'
									)
									.matches(
										new RegExp(parameter.match_regex),
										parameter.placeholder.replaceAll('*', '') +
											' should be a valid format'
									)
							: yup
									.string()
									.required(
										parameter.placeholder.replaceAll('*', '') +
											' is a required field'
									);
					} else {
						shapeObj[parameter.name] = parameter.match_regex
							? yup
									.string()
									.required(
										parameter.placeholder.replaceAll('*', '') +
											' is a required field'
									)
									.matches(
										new RegExp(parameter.match_regex),
										parameter.placeholder.replaceAll('*', '') +
											' should be a valid format'
									)
							: yup
									.string()
									.required(
										parameter.placeholder.replaceAll('*', '') +
											' is a required field'
									);
					}
				} else {
					if (amountKeys.includes(parameter.name)) {
						shapeObj[parameter.name] = yup
							.number()
							.typeError(parameter.placeholder + ' must be a number');
					} else {
						shapeObj[parameter.name] =
							parameter.match_regex &&
							yup.string().matches(new RegExp(parameter.match_regex), {
								message: parameter.placeholder + ' should be a valid format',
								excludeEmptyString: true,
							});
					}
				}

				if (parameter.others) {
					let userSelectedValue = '';
					masterData[parameter.name]?.map((item: any) => {
						if (item?.id === getValues(parameter.name)) {
							userSelectedValue = item?.value?.toLowerCase();
						}
					});

					if (userSelectedValue) {
						if (othersTextValue.includes(userSelectedValue)) {
							shapeObj[parameter.others_key] = yup
								.string()
								.required(
									capFirstLetter(parameter.others_key.replaceAll('_', ' ')) +
										' is a required field'
								)
								.matches(REGEX.FULLNAME, 'Please Enter Valid Format');
						}
					}
				}
			}
		});

		const validationUpdateSchema = yup.object().shape({
			...shapeObj,
		});

		const newValidationSchema = validationSchema.concat(validationUpdateSchema);

		//setValidateYup(newValidationSchema);
		setFieldStates((masterState: any) => {
			return { ...masterState, validateYup: newValidationSchema };
		});
	};

	const updateMasterData = async (name = '', api_param = '', apply_on = '') => {
		if (name !== '' && api_param !== '') {
			await dispatch(getProfessionalDetailsMaster({ param: api_param }))
				.then(unwrapResult)
				.then((res: any) => {
					if (res?.status) {
						const keyValue = getValues(name);
						let updateSelectedData: any = [];
						if (keyValue != '') {
							if (apply_on === 'company_type') {
								updateSelectedData = res?.data?.map((item: any) => {
									if (keyValue === item.value) {
										return {
											...item,
											checked: true,
										};
									} else {
										return { ...item };
									}
								});

								const setFieldValue = updateSelectedData
									?.filter((item: any) => item.checked)
									.map(({ id }: any) => id);
								if (setFieldValue?.length != 0) {
									setValue('company_type', setFieldValue);
								} else {
									setValue('company_type', undefined);
								}
							} else {
								updateSelectedData = res?.data;
								if (isNaN(keyValue)) {
									setValue(
										name,
										updateSelectedData
											?.filter(({ value }: any) => value === keyValue)
											.map(({ id }: any) => id)[0]
									);
								} else {
									setValue(name, keyValue);
								}
							}
							setMasterData((masterData: any) => {
								return {
									...masterData,
									[name]: updateSelectedData,
								};
							});
							setTimeout(() => {
								if (keyValue != undefined) {
									trigger(name);
								}
							}, 500);
						} else {
							setMasterData((masterData: any) => {
								return { ...masterData, [name]: res.data };
							});
						}
					}
				});
		}
	};
	const [url, setUrl] = useState('');

	const getLeadProfessionalInfo = () => {
		//setApiProcessed(false);
		dispatch(getLeadFlowUrl())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setUrl(result?.url);
				}
			});
		dispatch(
			getLeadUmDetails({
				param: leadInfo?.leadId
					? leadInfo?.leadId
					: newLeadApplicantSelector?.id,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setTimeout(() => {
						//setValue('occupation', '2');
						if (response?.proinfo) {
							reset(response?.proinfo);
							if (Object.keys(response?.proinfo).length) {
								let _hiddenInfoFields: any = [];
								const _showOtherFields: any = [];
								Object.keys(response?.proinfo).map((key) => {
									professionalInfoFields?.map((field: any) => {
										if (field.type === 'dropdownSelect') {
											if (
												response?.proinfo[key] != '' &&
												field.name === key &&
												masterData[field.name]?.length > 0
											) {
												setValue(
													field.name,
													masterData[field.name]
														?.filter(
															({ value }: any) =>
																value === response?.proinfo[key]
														)
														.map(({ id }: any) => id)[0]
												);
											}
											if (field.hidden) {
												if (field.name === key) {
													const fieldText = response?.proinfo[key];
													field.hidden.map((hItem: any) => {
														if (
															fieldText?.toLowerCase() ===
															hItem?.value?.toLowerCase()
														) {
															_hiddenInfoFields = _hiddenInfoFields.concat(
																hItem.field
															);

															// setHiddenInfoFields(
															// 	_hiddenInfoFields
															// );

															if (hItem.on_change) {
																updateMasterData(
																	hItem.apply_on,
																	hItem.master_key,
																	hItem.apply_on
																);
															}
														}
													});
												}
											}
											if (
												field.others &&
												field.name === key &&
												othersTextValue.includes(
													response?.proinfo[key]?.toLowerCase()
												)
											) {
												_showOtherFields.push(field.others_key);
											}
										}
										if (
											field.type === 'dropdownModal' &&
											field.name === key &&
											response?.proinfo[key] != ''
										) {
											const updateModalData = masterData[field.name]?.map(
												(item: any) => {
													if (response?.proinfo[key] === item.value) {
														return {
															...item,
															checked: true,
														};
													} else {
														return { ...item };
													}
												}
											);
											setMasterData((masterData: any) => {
												return {
													...masterData,
													[key]: updateModalData,
												};
											});
										}
										if (field.is_pincode && field.name === key) {
											if (response?.proinfo[key].length === 6) {
												fetchStateCityByPincode(
													setValue,
													field.city,
													field.state,
													setPinCodeData,
													dispatch,
													response?.proinfo[key],
													trigger,
													true,
													field.name
												);
											}
										}
									});
								});
								setFieldStates((masterState: any) => {
									return {
										...masterState,
										hiddenInfoFields: _hiddenInfoFields,
										showOtherFields: _showOtherFields,
									};
								});
								/*setTimeout(() => {
									setApiProcessed(true);
								}, 500);*/
							}
						}
					}, 10);
				}
			});
	};

	const getEquifaxAdditionalInfo = async () => {
		await dispatch(
			equifaxAdditionalInfo({
				param: leadInfo?.leadId
					? leadInfo?.leadId
					: newLeadApplicantSelector?.id,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setShowAdditionalInfo(true);
					setAdditionalInfoData(response?.data);

					// Sets Total EMI and Credit Outstanding Amount in form fields
					setTimeout(() => {
						if (response?.data?.ccBalance) {
							setValue('total_emi_amount', response?.data?.loanBalance);
						}

						if (response?.data?.loanBalance) {
							setValue(
								'credit_card_outstanding_amount',
								response?.data?.ccBalance
							);
						}
					}, 500);
				}
			});
	};
	useEffect(() => {
		if (location.pathname !== '/professional-info') {
			return;
		}
		setShowAdditionalInfo(false);
		setAdditionalInfoData({});
		getProfessionalInfoFields();
		getEquifaxAdditionalInfo();
	}, [location?.pathname]);

	useEffect(() => {
		if (location.pathname !== '/professional-info') {
			return;
		}
		if (professionalInfoFields?.length > 0 && apiProcessed) {
			getLeadProfessionalInfo();
		}
	}, [professionalInfoFields, apiProcessed]);

	useEffect(() => {
		initiateValidation(professionalInfoFields);
	}, [fieldStates.hiddenInfoFields, fieldStates.showOtherFields]);

	const getCompanyList = async (searchValue: any) => {
		await dispatch(
			getCompanyNameList({
				param: `?searchItem=${sanitizeHtml(
					encodeURIComponent(searchValue)
				)}&page=${PAGINATION_DETAILS.CURRENT_PAGE}&size=${
					PAGINATION_DETAILS.PAGE_SIZE
				}`,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setList(response.companyListData);
				}
			});
	};

	const getBankNamesList = async (searchValue: any) => {
		await dispatch(
			getBanksList({
				param: `?searchItem=${sanitizeHtml(
					encodeURIComponent(searchValue)
				)}&page=${PAGINATION_DETAILS.CURRENT_PAGE}&size=${
					PAGINATION_DETAILS.PAGE_SIZE
				}`,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setBankNamesSearchList(response.bankListData);
				}
			});
	};

	return (
		<>
			<IonPage className=''>
				<Header
					name={t('Professional Info')}
					backButton={leadInfo?.from === 'customer' ? false : true}
					needArrow={true}
					needArrowOnClick={() => {
						if (leadInfo?.from !== 'customer') {
							leadInfo?.from === 'nimbuss'
								? history.push(
										'/lead-details/' +
											leadInfo.leadId +
											'/' +
											dmaSelector?.user_details?.access_token
								  )
								: history.push('/dashboard/lead');
						}
					}}
					endItem={
						<>
							{leadInfo?.from !== 'customer' && leadInfo?.via !== 'share' ? (
								<IonButtons
									slot='end'
									className=''
								>
									<IonImg
										className='cursor-pointer'
										src='/images/icons/assign-icon.svg'
										alt='assign'
										onClick={() => {
											setShowAllocateManagerModal(true);
										}}
									/>
									<IonImg
										className='cursor-pointer mx-3'
										src='/images/icons/share-blue.svg'
										alt='share'
										onClick={() => {
											const finalUrl =
												url.split('?')?.[0] +
												'?d=' +
												window.btoa(`lead_id=${leadInfo?.leadId}`);
											ShareData('', '', finalUrl);
										}}
									/>
								</IonButtons>
							) : (
								''
							)}
						</>
					}
				/>
				<IonContent>
					<div className='select-product small-container bg-web  overflow-auto'>
						<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
							<form
								className='piform'
								onSubmit={handleSubmit(onSubmit)}
							>
								{apiProcessed &&
									professionalInfoFields?.map(
										(
											{
												name: pifName,
												type,
												placeholder,
												others,
												others_key,
												is_pincode,
												city,
												state,
												exclude_number_validation,
												is_required,
											}: any,
											index: any
										) => {
											const errorText: any =
												errors && Object.keys(errors).length > 0 && errors;

											if (type === 'dropdownSelect') {
												if (
													!fieldStates['hiddenInfoFields']?.includes(pifName)
												) {
													let other_value: any;
													return (
														<div key={index}>
															<Controller
																key={index}
																control={control}
																render={({
																	field: { onChange, onBlur, name },
																}) => (
																	<DropDownModal
																		label={`${t(placeholder)} ${
																			is_required === true ? ' *' : ''
																		}`}
																		data={
																			masterData[pifName]
																				? masterData[pifName]
																				: []
																		}
																		name={name}
																		onChange={(e: any) => {
																			let _hiddenInfoFields: any = [];
																			const _showOtherFields: any = [];
																			professionalInfoFields?.map(
																				(field: any) => {
																					if (field.type === 'dropdownSelect') {
																						if (field.hidden) {
																							if (field.name === pifName) {
																								masterData[pifName].map(
																									(item: any) => {
																										if (
																											item?.id ===
																											e.detail.value
																										) {
																											const fieldText =
																												item?.value?.toLowerCase();
																											field.hidden.map(
																												(hItem: any) => {
																													if (
																														fieldText ===
																														hItem?.value?.toLowerCase()
																													) {
																														_hiddenInfoFields =
																															_hiddenInfoFields.concat(
																																hItem.field
																															);
																														// setHiddenInfoFields(
																														// 	_hiddenInfoFields
																														// );

																														if (
																															hItem.on_change
																														) {
																															updateMasterData(
																																hItem.apply_on,
																																hItem.master_key,
																																hItem.apply_on
																															);
																														}
																													}
																												}
																											);
																										}
																									}
																								);
																							} else {
																								if (
																									getValues(field.name) !==
																									undefined
																								) {
																									masterData[field.name].map(
																										(item: any) => {
																											if (
																												item?.id ===
																												getValues(field.name)
																											) {
																												const fieldText =
																													item?.value?.toLowerCase();
																												field.hidden.map(
																													(hItem: any) => {
																														if (
																															fieldText ===
																															hItem?.value?.toLowerCase()
																														) {
																															_hiddenInfoFields =
																																_hiddenInfoFields.concat(
																																	hItem.field
																																);
																															// setHiddenInfoFields(
																															// 	_hiddenInfoFields
																															// );
																														}
																													}
																												);
																											}
																										}
																									);
																								}
																							}
																						}

																						if (field.others) {
																							if (field.name === pifName) {
																								other_value = masterData[
																									pifName
																								].find(
																									(item: any) =>
																										item.id === e.detail.value
																								);
																								if (
																									othersTextValue.includes(
																										other_value?.value?.toLowerCase()
																									)
																								) {
																									_showOtherFields.push(
																										field.others_key
																									);
																								}
																							} else {
																								other_value = masterData[
																									field.name
																								].find(
																									(item: any) =>
																										item.id ===
																										getValues(field.name)
																								);
																								if (
																									othersTextValue.includes(
																										other_value?.value?.toLowerCase()
																									)
																								) {
																									_showOtherFields.push(
																										field.others_key
																									);
																								}
																							}
																						}
																					}
																				}
																			);
																			// setHiddenInfoFields(_hiddenInfoFields);
																			// setShowOtherFields(_showOtherFields);

																			setFieldStates((masterState: any) => {
																				return {
																					...masterState,
																					hiddenInfoFields: _hiddenInfoFields,
																					showOtherFields: _showOtherFields,
																				};
																			});

																			onChange(e);
																		}}
																		onBlur={onBlur}
																		errors={t(
																			errorText[name]?.message as string
																		)}
																		value={getValues(name)}
																	/>
																)}
																name={pifName}
															/>
															{others === true &&
																fieldStates['showOtherFields'].includes(
																	others_key
																) && (
																	<Controller
																		key={100 + index}
																		control={control}
																		render={({
																			field: { onChange, onBlur },
																		}) => (
																			<CustomInput
																				type={type}
																				placeholder={
																					t(
																						capFirstLetter(
																							others_key.replaceAll('_', ' ')
																						)
																					) + (is_required === true ? ' *' : '')
																				}
																				onBlur={onBlur}
																				name={others_key}
																				onChange={onChange}
																				errors={t(
																					errorText[others_key]
																						?.message as string
																				)}
																				value={getValues(others_key)}
																				onBeforeInput={(evt: any) => {
																					if (type == 'number') {
																						!/[0-9]/.test(evt.data) &&
																							evt.preventDefault();
																					}
																				}}
																				onKeyDown={(e: any) => {
																					if (type == 'number') {
																						disableNumberArrows(e);
																					}
																				}}
																				onWheel={(e: any) => {
																					if (type == 'number') {
																						e.target.blur();
																					}
																				}}
																			/>
																		)}
																		name={others_key}
																	/>
																)}
														</div>
													);
												}
											} else if (type === 'dropdownModal') {
												return (
													<CustomDropDown
														key={index}
														{...{
															isCheckbox: false,
															title:
																(t(placeholder) as any) +
																(is_required === true ? ' *' : ''),
															setData: (data: any) => {
																setMasterData((masterData: any) => {
																	return {
																		...masterData,
																		[pifName]: data,
																	};
																});

																const setFieldValue = data
																	.filter((item: any) => item.checked)
																	.map(({ id }: any) => id);
																if (setFieldValue?.length != 0) {
																	setValue(pifName, setFieldValue);
																} else {
																	setValue(pifName, undefined);
																}
																trigger(pifName);
															},
															data: masterData[pifName],
															placeholder: t(placeholder),
															isSearch: true,
															modalTitle: t(placeholder),
															listTitle: t('All Company Type'),
														}}
													/>
												);
											} else {
												if (
													!fieldStates['hiddenInfoFields']?.includes(pifName)
												) {
													return (
														<div key={index}>
															<Controller
																key={index}
																control={control}
																render={({
																	field: { onChange, onBlur, name },
																}) => (
																	<CustomInput
																		type={type}
																		placeholder={
																			(t(placeholder) as any) +
																			(is_required === true ? ' *' : '')
																		}
																		onBlur={onBlur}
																		name={name}
																		onFocus={() =>
																			name === 'company_name'
																				? setOpenCompanyModal(true)
																				: name === 'salary_account_bank_name'
																				? setOpenBankModal(true)
																				: ''
																		}
																		// onChange={onChange}
																		onChange={(e: any) => {
																			if (amountKeys.includes(name)) {
																				e = isNaN(
																					e.detail.value.replaceAll(',', '')
																				)
																					? ''
																					: Number(
																							e.detail.value.replace(/,/g, '')
																					  );
																			}
																			if (name === 'company_name') {
																				setOpenCompanyModal(true);
																			}
																			if (name === 'salary_account_bank_name') {
																				setOpenBankModal(true);
																			}
																			if (is_pincode) {
																				if (e.detail.value.length === 6) {
																					fetchStateCityByPincode(
																						setValue,
																						city,
																						state,
																						setPinCodeData,
																						dispatch,
																						e.detail.value,
																						trigger,
																						true,
																						pifName
																					);
																					// pinCodeAction(e.detail.value);
																				} else {
																					setValue(state, '');
																					setValue(city, '');
																					//setPinCodeData([]);
																					setPinCodeData((pinCodeData: any) => {
																						return {
																							...pinCodeData,
																							[pifName]: [],
																						};
																					});
																				}
																			}
																			if (
																				name === 'annual_turnover' &&
																				getValues('net_profit') != undefined
																			) {
																				setTimeout(() => {
																					trigger('net_profit');
																				}, 100);
																			}
																			if (
																				name === 'total_work_experience' &&
																				getValues(
																					'work_experience_in_current_company'
																				) != undefined
																			) {
																				setTimeout(() => {
																					trigger(
																						'work_experience_in_current_company'
																					);
																				}, 100);
																			}
																			if (
																				name ===
																					'work_experience_in_current_company' &&
																				getValues('total_work_experience') !=
																					undefined
																			) {
																				setTimeout(() => {
																					trigger('total_work_experience');
																				}, 100);
																			}
																			onChange(e);
																		}}
																		// errors={errors?[?.message}
																		errors={t(
																			errorText[name]?.message as string
																		)}
																		value={
																			amountKeys.includes(name)
																				? getValues(name)
																					? InputFormatCurrencyValue(
																							getValues(name)
																					  )
																					: getValues(name)
																				: getValues(name)
																		}
																		onBeforeInput={(evt: any) => {
																			if (type == 'number') {
																				if (!exclude_number_validation) {
																					!/[0-9]/.test(evt.data) &&
																						evt.preventDefault();
																				} else {
																					!/[0-9.]/.test(evt.data) &&
																						evt.preventDefault();
																				}
																			} else if (
																				amountKeys.includes(name) &&
																				type == 'text'
																			) {
																				restrictAmountChar(
																					evt,
																					getValues(name)?.toString()
																				);
																			}
																		}}
																		onKeyDown={(e: any) => {
																			if (type == 'number') {
																				disableNumberArrows(e);
																			}
																		}}
																		onWheel={(e: any) => {
																			if (type == 'number') {
																				e.target.blur();
																			}
																		}}
																		className={
																			capitalizeFields.includes(name)
																				? 'text-uppercase'
																				: ''
																		}
																		// notRequiredValue={true}
																	/>
																)}
																name={pifName}
															/>
															{is_pincode && pinCodeData[pifName] ? (
																<StateCity
																	cityInputName={city}
																	stateInputName={state}
																	control={control}
																	setValue={setValue}
																	pinCodeData={pinCodeData[pifName]}
																	trigger={trigger}
																	getValues={getValues}
																/>
															) : (
																''
															)}
															{is_pincode &&
															getValues(pifName) != '' &&
															getValues(city) === '' ? (
																<div className='error-text sc-ion-input-md px-3'>
																	{t('City or State is not selected')}
																</div>
															) : (
																''
															)}
															{pifName === 'company_name' ? (
																<CustomPopoverSearch
																	searchApi={getCompanyList}
																	list={list}
																	setList={setList}
																	isOpen={openCompanyModal}
																	searchListModalClose={setOpenCompanyModal}
																	title={t('Company Names')}
																	searchbarPlaceholder={t(
																		'Search Company by “Name”'
																	)}
																	customNoDataMessage={t(
																		'Search by your company name'
																	)}
																	setValue={(val: any) => {
																		setValue('company_name', val);
																		trigger('company_name');
																	}}
																/>
															) : (
																''
															)}
															{pifName === 'salary_account_bank_name' ? (
																<CustomPopoverSearch
																	searchApi={getBankNamesList}
																	list={bankNamesSearchList}
																	setList={setBankNamesSearchList}
																	isOpen={openBankModal}
																	searchListModalClose={setOpenBankModal}
																	title={t('Bank Names')}
																	searchbarPlaceholder={t(
																		'Search Bank by “Name”'
																	)}
																	customNoDataMessage={t('Search by Bank Name')}
																	setValue={(val: any) => {
																		setValue('salary_account_bank_name', val);
																		trigger('salary_account_bank_name');
																	}}
																/>
															) : (
																''
															)}
														</div>
													);
												}
											}
										}
									)}
							</form>
							{/* </div> */}
							 {!lasId && showAdditionalInfo && (
								<>
									<h4 className='my-3 fs-16 fw-600 px-3'>
										{t('Additional Info')}
									</h4>
									<div>
										<IonItem
											button
											detail={true}
											onClick={() => setActiveEmi(true)}
											lines='none'
										>
											<IonLabel
												style={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent: 'space-between',
												}}
											>
												<h3 className='fs-14 fw-600'>
													{t('Active EMI')} (
													{additionalInfoData?.totalAcitveloanAcounts})
												</h3>
												<p className='fs-14 fw-600 opacity-50'>
													₹ {additionalInfoData?.loanBalance}
												</p>
											</IonLabel>
										</IonItem>
										<IonItem
											button
											detail={true}
											onClick={() => setCCOutStanding(true)}
											lines='none'
										>
											<IonLabel
												style={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent: 'space-between',
												}}
											>
												<h3 className='fs-14 fw-600'>
													{t('CC Outstanding')} (
													{additionalInfoData?.totalActiveccAccounts})
												</h3>
												<p className='fs-14 fw-600 opacity-50'>
													₹ {additionalInfoData?.ccBalance}
												</p>
											</IonLabel>
										</IonItem>
									</div>
								</>
							)}
							{professionalInfoFields?.length > 0 && (
								<div
									className={` d-none d-md-block`}
									// style={{
									// 	backgroundColor: '#fff',
									// 	zIndex: 2,
									// }}
								>
									<div className=''>
										{leadInfo?.from !== 'customer' ? (
											<CustomButton
												className='w-100 fw-bold dashboard-add-lead-button mb-3'
												expand='block'
												fill='outline'
												title={t('Continue Later')}
												onClick={() => {
													const userAttributes = {
														userId: userId,
														attributes: {
															name: userName,
															id: userId,
															mobile: userMobile,
														},
													};
													if (checkFieldsValidToContinue(errors)) {
														setModalPageClose(true);
														if (isPlatform('android') || isPlatform('ios')) {
															notifyHandler(
																'event',
																eventAttributes(
																	'continue_later_ add_lead_journey_professional_info'
																)
															);
															notifyHandler('user', userAttributes.attributes);
															const inAppData = {
																tokens: { name: userAttributes },
																customRules: {
																	banerrType: 'continue later',
																},
															};
															NotifyVisitors.show(inAppData);
														} else {
															(window as any).nv(
																'event',
																'continue_later_ add_lead_journey_professional_info',
																10,
																1
															);
															(window as any).nv(
																'user',
																userId,
																userAttributes.attributes
															);
														}
													} else {
														showToastAlert({
															type: 'error',
															message: 'Please fix above errors',
														});
													}
												}}
											/>
										) : (
											''
										)}
										<CustomButton
											type='submit'
											className='w-100 fw-bold dashboard-add-lead-button'
											expand='block'
											title={t('Proceed')}
											disabled={!isValid}
											onClick={
												// const userAttributes = {
												// 	userId: userId,
												// 	attributes: {
												// 		name: userName,
												// 		id: userId,
												// 		mobile: userMobile,
												// 	},
												// };
												// if (isPlatform('android')) {
												// 	notifyHandler(
												// 		'event',
												// 		eventAttributes(
												// 			'Proceed_add_lead_journey_professional_info'
												// 		)
												// 	);
												// 	// notifyHandler('user', userAttributes.attributes);
												// 	const inAppData = {
												// 		tokens: { name: userAttributes },
												// 		customRules: {
												// 			banerrType: 'proceed',
												// 		},
												// 	};
												// 	NotifyVisitors.show(inAppData);
												// } else {
												// 	(window as any).nv(
												// 		'event',
												// 		'Proceed_add_lead_journey_professional_info',
												// 		10,
												// 		1
												// 	);
												// 	(window as any).nv(
												// 		'user',
												// 		userId,
												// 		userAttributes.attributes
												// 	);
												// }
												handleSubmit(onSubmit)
											}
										/>
									</div>
								</div>
							)}
						</div>
					</div>
					<CustomModal
						// id='customModalRework'
						isOpen={showAllocateManagerModal}
						modalTitle={t('Select Manager')}
						initialBreakpoint={1}
						handleClose={() => setShowAllocateManagerModal(false)}
						breakpoints={[0, 1]}
						className='assignModal side-modal'
						modalContent={
							<CustomAllocateManagers
								eventType='Lead'
								id={leadInfo?.leadId}
								setShowAssignModal={setShowAllocateManagerModal}
							/>
						}
						scrollY={true}
						needCross={true}
						needArrow={false}
					></CustomModal>
				</IonContent>
				<IonFooter>
					{professionalInfoFields?.length > 0 && (
						<div
							className={`ion-padding d-block d-md-none`}
							style={{
								backgroundColor: '#fff',
								zIndex: 2,
							}}
						>
							<div className='proceed-btn'>
								{leadInfo?.from !== 'customer' ? (
									<CustomButton
										className='w-100 fw-bold dashboard-add-lead-button mb-3'
										expand='block'
										fill='outline'
										title={t('Continue Later')}
										onClick={() => {
											if (checkFieldsValidToContinue(errors)) {
												setModalPageClose(true);
											} else {
												showToastAlert({
													type: 'error',
													message: 'Please fix above errors',
												});
											}
										}}
									/>
								) : (
									''
								)}
								<CustomButton
									type='submit'
									className='w-100 fw-bold dashboard-add-lead-button'
									expand='block'
									title={t('Proceed')}
									disabled={!isValid}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</div>
					)}
					{/* continue later popup */}
					<CustomModal
						needIonContentWraper={false}
						isOpen={modalPageClose}
						initialBreakpoint={undefined}
						handleClose={() => setModalPageClose(false)}
						breakpoints={[]}
						className='height-auto confirm-popup-middle continue-later'
						modalContent={ContinueLaterPopUp(
							() => {
								setModalPageClose(false);
							},
							() => {
								setModalPageClose(false);
								continueLaterFlag = true;
								onSubmit(getValues());
							},
							`${t('Cancel')}`,
							`${t('Continue Later')}`,
							'className',
							`${t('Are You Sure You Want to Exit?')}`,
							`${t('You can continue later from where you left off.')}`
						)}
						needCross={false}
						needArrow={false}
						// modalTitle=''
					/>
				</IonFooter>
			</IonPage>
			<CustomModal
				id='cutomdropdown'
				isOpen={activeEmi}
				initialBreakpoint={isWeb ? 1 : 0.5}
				handleClose={() => setActiveEmi(false)}
				breakpoints={[0, 0.25, 0.5, 0.75, 1]}
				className='leadStep side-modal'
				modalContent={
					<ActiveEmiModal
						{...{
							leadID: leadInfo?.leadId
								? leadInfo?.leadId
								: newLeadApplicantSelector?.id,
						}}
					/>
				}
				// needCross={false}
				// needArrow={false}
			/>

			{/* cc outstanding modal */}
			<CustomModal
				// id='cutomdropdown'
				isOpen={ccOutStanding}
				initialBreakpoint={isWeb ? 1 : 0.5}
				// onDis
				handleClose={() => setCCOutStanding(false)}
				breakpoints={[0, 0.25, 0.5, 0.75, 1]}
				modalContent={
					<CcOutStandingModal
						{...{
							leadID: leadInfo?.leadId
								? leadInfo?.leadId
								: newLeadApplicantSelector?.id,
						}}
					/>
				}
				needCross={false}
				className='leadStep side-modal'
				needArrow={false}
				scrollY={false}
			/>
		</>
	);
}
