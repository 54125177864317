import * as yup from 'yup';


const family_details_schema = yup.object({
  maritalStatus: yup.string().required('Mandatory field'),


// //   spouseFirstName: yup.string().notRequired().required('Spouse First Name is required'),
//   spouseFirstName: yup.string().notRequired(),
//   spouseMiddleName: yup.string(),
// //   spouseLastName: yup.string().required('Spouse Last Name is required'),
//   spouseLastName: yup.string().notRequired(),

  spouseFirstName: yup.string().test('spouseFirstName', 'Spouse First Name is required', function (value) {
    const maritalStatus = this.parent.maritalStatus;
    return maritalStatus && ['married', 'divorced', 'separated'].includes(maritalStatus.toLowerCase()) ? !!value : true;
  }),
  spouseMiddleName: yup.string().notRequired(),
  spouseLastName: yup.string().test('spouseLastName', 'Spouse Last Name is required', function (value) {
    const maritalStatus = this.parent.maritalStatus;
    return maritalStatus && ['married', 'divorced', 'separated'].includes(maritalStatus.toLowerCase()) ? !!value : true;
  }),
  

  fatherFirstName: yup.string().required('Father First Name is required'),
  fatherMiddleName: yup.string(),
  fatherLastName: yup.string().required('Father Last Name is required'),
  motherFirstName: yup.string().required('Mother First Name is required'),
  motherMiddleName: yup.string().notRequired(),
  motherLastName: yup.string().required('Mother Last Name is required')
});

  export default family_details_schema;