/* eslint-disable @typescript-eslint/no-explicit-any */
import { Header } from '../../components/layout/header/Header';
import { useEffect, useState } from 'react';
import './Products.scss';
import { useHistory } from 'react-router';
import { IonContent, IonItem, IonLabel, IonPage } from '@ionic/react';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { getProductList } from '../../store/reducers/user/userSlice';

export const AllProductLenders: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [categoryList, setCategoryList] = useState<any>();
	const productSelector = useSelector(
		(state: any) => state?.users?.productFlow
	);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	// const [viewBtn, setViewBtn] = useState<any>('View More');
	const dispatch = useDispatch();

	useEffect(() => {
		getProductLists();
	}, []);

	const getProductLists = async () => {
		await dispatch(getProductList())
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setCategoryList(response?.product_list[productSelector?.id]);
				}
			});
	};

	// unused toggleAccordion and accordionGroup commented by rohit kamble 19-05-2023

	// const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);
	// const toggleAccordion = () => {
	// 	if (!accordionGroup.current) {
	// 		return;
	// 	}
	// 	const nativeEl = accordionGroup.current;

	// 	if (nativeEl.value === 'show') {
	// 		nativeEl.value = undefined;
	// 		setViewBtn('View More');
	// 	} else {
	// 		nativeEl.value = 'show';
	// 		setViewBtn('View Less');
	// 	}
	// };
	return (
		<IonPage className='lead-list'>
			<Header
				name={t('All Lenders')}
				type=''
				needArrow={true}
				backButton={true}
			/>
			<IonContent
				className='ion-padding'
				style={{
					borderTop: '1px solid #00000029',
					height: 'calc(100vh - 113px)',
					overflowX: 'hidden',
				}}
			>
				<div>
					<p className='fs-14'>
						{t(`Please enter your pincode to check availability of banks in your
            city`)}
					</p>
					<div className='mt-3'>
						<CustomInput placeholder={t('Pincode')} />
					</div>
				</div>

				<div className=''>
					<div className='lenders m-0 mt-3'>
						<IonItem
							lines='none'
							className='bg-transparent ion-no-padding'
							detail={true}
							onClick={() => history.push('/lender-product-detail')}
						>
							<IonLabel className='bg'>
								<div className='d-flex gap-2'>
									<img
										alt='Product'
										src='https://ionicframework.com/docs/img/demos/thumbnail.svg'
										className='product-img '
									/>

									<div>
										<h3 className='fw-600'>SBI Home Loans</h3>
										<p className='mb-0'>
											{t('Interest Rate')}{' '}
											<span className='fw-600'>11% to 15%</span>
										</p>
									</div>
								</div>
							</IonLabel>
						</IonItem>
					</div>
					<div className=' lenders m-0 mt-3'>
						<IonItem
							lines='none'
							className='bg-transparent  ion-no-padding'
							detail={true}
							onClick={() => history.push('/lender-product-detail')}
						>
							<IonLabel className='bg-transparent'>
								<div className='d-flex gap-2'>
									<img
										alt='Product'
										src='https://ionicframework.com/docs/img/demos/thumbnail.svg'
										className='product-img '
									/>

									<div>
										<h3 className='fw-600'>SBI Home Loans</h3>
										<p className='mb-0'>
											{t('Interest Rate')}{' '}
											<span className='fw-600'>11% to 15%</span>
										</p>
									</div>
								</div>
							</IonLabel>
						</IonItem>
					</div>
					<div className=' lenders m-0 mt-3'>
						<IonItem
							lines='none'
							className='bg-transparent  ion-no-padding'
							detail={true}
							onClick={() => history.push('/lender-product-detail')}
						>
							<IonLabel className='bg-transparent'>
								<div className='d-flex gap-2'>
									<img
										alt='Product'
										src='https://ionicframework.com/docs/img/demos/thumbnail.svg'
										className='product-img '
									/>

									<div>
										<h3 className='fw-600'>SBI Home Loans</h3>
										<p className='mb-0'>
											{t('Interest Rate')}{' '}
											<span className='fw-600'>11% to 15%</span>
										</p>
									</div>
								</div>
							</IonLabel>
						</IonItem>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};
