import { IonContent, IonGrid, IonRow, IonCol } from '@ionic/react';
import React, { useState, useEffect } from 'react';
// import { Header } from '../../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { StatusNum } from '../../../helpers/constant';
import { useDispatch, useSelector } from 'react-redux';
import { dmaFlowData, teamsPage } from '../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { Sidebar } from '../../../components/layout/sidebar/Sidebar';
import { Divider } from '../../../components/common/divider/Divider';

export const Team: React.FC = () => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const history = useHistory();
	const [dmaStatusCount, setDmaStatusCount] = useState<any>([]);
	const dmaSelector = useSelector((state: any) => state?.users?.dmaFlow);
	useEffect(() => {
		getUsersCount();
	}, []);
	const getUsersCount = async () => {
		await dispatch(teamsPage())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status && result?.dma && result?.dma.length) {
					setDmaStatusCount(result?.dma[0]);
				}
			});
	};
	const goToDmaList = async (code: any, pageTitle: any) => {
		await dispatch(
			dmaFlowData({
				...dmaSelector,
				dmaCode: code,
				title: pageTitle,
				update: dmaSelector?.update + 1 || 1,
			})
		);
		history.push('/team-list');
	};

	return (
		// <IonPage>
		// <Header name={t('Team')} />

		<div className='d-flex'>
			<Sidebar />
			{/* <div className='container'> */}
			<IonContent className='dashboard-main dashboard-height background-web'>
				<div className='w-100 h-100 pt-md-3 bg-web container-width-md'>
					<div className='bg-web-white h-90 border-md-radius-10'>
						<IonGrid>
							<IonRow>
								<IonCol size='6'>
									<div
										className='team-box cursor-pointer card-gradient-top-right'
										onClick={() =>
											goToDmaList(StatusNum.VERIFICATION, 'DMA Verification')
										}
									>
										<div className='d-flex justify-content-between'>
											<div className='fw-bold fs-22'>
												{dmaStatusCount?.total_verification_stage_count}
											</div>
											<img
												src='/images/icons/teams-dma-verification-icon.svg'
												alt='dma verification'
											/>
										</div>
										<div className='mt-3 fs-14'>{t('DMA Verification')}</div>
									</div>
								</IonCol>
								<IonCol size='6'>
									<div
										className='team-box cursor-pointer card-gradient-top-right'
										onClick={() =>
											goToDmaList(StatusNum.REWORKCOMPLETED, 'Rework Completed')
										}
									>
										<div className='d-flex justify-content-between'>
											<div className='fw-bold fs-22'>
												{dmaStatusCount?.total_rework_count}
											</div>
											<img
												src='/images/icons/teams-rework-complete-icon.svg'
												alt='rework complete'
											/>
										</div>
										<div className='mt-3 fs-14'>{t('Rework Completed')}</div>
									</div>
								</IonCol>
							</IonRow>
						</IonGrid>
						<Divider />
						<IonGrid>
							<IonRow>
								<IonCol size='6'>
									<div
										className='team-box cursor-pointer card-gradient-top-right'
										onClick={() =>
											goToDmaList(StatusNum.UNDERPROCESS, 'Under Process')
										}
									>
										<div className='d-flex justify-content-between'>
											<div className='fw-bold fs-22'>
												{dmaStatusCount?.total_processing_count}
											</div>
											<img
												src='/images/icons/teams-under-process-icon.svg'
												alt='Under Process'
											/>
										</div>
										<div className='fs-14'>{t('Under Process')}</div>
									</div>
								</IonCol>
								<IonCol size='6'>
									<div
										className='team-box cursor-pointer card-gradient-top-right'
										onClick={() => goToDmaList(StatusNum.APPROVED, 'Approved')}
									>
										<div className='d-flex justify-content-between'>
											<div className='fw-bold fs-22'>
												{dmaStatusCount?.total_approve_count}
											</div>
											<img
												src='/images/icons/teams-approved-icon.svg'
												alt='approved'
											/>
										</div>
										<div className='fs-14'>{t('Approved')}</div>
									</div>
								</IonCol>
							</IonRow>
						</IonGrid>
					</div>
				</div>
			</IonContent>
			{/* </div> */}
		</div>
		// </IonPage>
	);
};
