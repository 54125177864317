import { IonContent, IonFooter, IonSpinner, isPlatform } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { useHistory } from 'react-router';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import {
	saveLenderSpcfInfo,
	lenderPerfiosDocUpload,
	lenderPerfiosFetchStatus,
	lenderSpfDetailsFlow,
} from '../../../../store/reducers/lead/leadSlice';
import { LenderNameForAPICall } from '../../../../helpers/lenders';
import { OfferRejectedMsg } from '../../OfferRejectedMsg/OfferRejectedMsg';
import { INAPPCONFIG } from '../../../../helpers/constant';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import { ContinueLaterPopUp } from '../../../../helpers/functions';

export const BLPerfiosVerification: React.FC<any> = () => {
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const [browserInstance, setBrowserInstance] = useState({});
	const [browserClosed, setBrowserClosed] = useState(false);
	const [modalPageClose, setModalPageClose] = useState(false);
	const [spinner, setSpinner] = useState(false);
	const [applicationId, setApplicationId] = useState<any>();
	const [isValid, setValid] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const { selectedLender, lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);

	useEffect(() => {
		if (!location.pathname.includes('/lead-lender-specific-form-steps')) {
			return;
		} else {
			fetchPerfiosStatus();
		}
	}, [location.pathname]);

	useEffect(() => {
		if (browserClosed) {
			fetchPerfiosStatus();
			setBrowserClosed(false);
		}
	}, [browserClosed]);

	useEffect(() => {
		if (!Object.keys(browserInstance).length) {
			return;
		}
	}, [browserInstance]);

	/**
	 * Get perfios status
	 */
	const fetchPerfiosStatus = async () => {
		setSpinner(true);
		setTimeout(async () => {
			const req = {
				lead_spf_detail_id: lenderSpecificDetails.lead_spf_detail_id,
				lender_name: LenderNameForAPICall.TATA_BL,
			};
			setShowOfferErrMsg('');
			setSpinner(false);
			await dispatch(lenderPerfiosFetchStatus({ data: req }))
				.then(unwrapResult)
				.then((result: any) => {
					if (result?.status) {
						if (result.data?.application_id) {
							setApplicationId(result.data?.application_id);
							dispatch(
								lenderSpfDetailsFlow({
									...lenderSpecificDetails,
									lender_spf_details: {
										...lenderSpecificDetails?.lender_spf_details,
										application_id: result.data.application_id,
									},
								})
							);
						}
						if (result?.data?.isValid) {
							setValid(true);
						} else {
							setValid(false);
						}
					} else {
						// if (!result?.data) {
						// 	setShowOfferErrMsg(result?.message);
						// }
					}
				});
		}, 10000);
	};

	/**
	 * Upload document for perfios verification
	 */
	const perfiosDocUpload = async () => {
		const req = {
			lead_spf_detail_id: lenderSpecificDetails.lead_spf_detail_id,
			lender_name: LenderNameForAPICall.TATA_BL,
			sourceSystemURL: `${location.origin}/lead-lender-specific-form-steps`,
		};
		setShowOfferErrMsg('');
		await dispatch(lenderPerfiosDocUpload({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					inAppBrowserFeature(result?.data?.redirectUrl);
				} else {
					setShowOfferErrMsg(result?.message);
				}
			});
	};

	/**
	 * Open and handle 3rd party url in app browser
	 * @param data 3rd party url
	 */
	const inAppBrowserFeature = (url: any) => {
		let config = INAPPCONFIG;
		if (isPlatform('ios')) {
			config += ',location=no';
		}
		const inAppBrowser = InAppBrowser.create(
			url,
			isPlatform('ios') ? '_blank' : '_self',
			config
		);
		setBrowserInstance(inAppBrowser);
		inAppBrowser.on('loadstop')?.subscribe(() => {
			inAppBrowser.close();
			setBrowserClosed(true);
		});
		if (isPlatform('ios')) {
			inAppBrowser.on('exit')?.subscribe(() => {
				setBrowserClosed(true);
			});
		}
	};

	/**
	 * On submit to save data
	 * @param data form value
	 */
	const onSubmit = async () => {
		const req: any = {
			lead_spf_detail_id: lenderSpecificDetails.lead_spf_detail_id,
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.TATA_BL,
			lender_spf_details: {
				...lenderSpecificDetails?.lender_spf_details,
			},
			step: isValid ? 'step4' : 'step3',
		};

		if (applicationId) {
			req.lender_spf_details.application_id = applicationId;
		}

		await dispatch(saveLenderSpcfInfo({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					if (isValid) {
						dispatch(
							lenderSpfDetailsFlow({
								...lenderSpecificDetails,
								step: 'step4',
							})
						);
					}
				}
			});
	};

	/**
	 * Continue Later and Submit button
	 */
	const continueLaterAndSubmitBtn = () => {
		return (
			<>
				<CustomButton
					className='w-100 fw-bold dashboard-add-lead-button mb-3'
					expand='block'
					fill='outline'
					title={t('Continue Later')}
					onClick={() => {
						setModalPageClose(true);
					}}
				/>
				<CustomButton
					type='submit'
					className='w-100 fw-bold dashboard-add-lead-button'
					expand='block'
					title={t('Proceed')}
					disabled={!isValid}
					onClick={onSubmit}
				/>
			</>
		);
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10 text-center'>
							<img
								src='/images/checklist.png'
								height={120}
							/>

							<h4 className='mt-4'>
								<b>One last step before we give you an offer</b>
							</h4>

							<div>
								<small className='small_text'>
									Connect your bank statement to get the highest possible loan
									amount
								</small>

								<div className='bank_statement'>
									<div
										className='statement_sec'
										onClick={() => perfiosDocUpload()}
									>
										<div className='statement_icon'>
											<img
												src='/images/statement.png'
												height={35}
											/>
										</div>
										<div className='statement_text'>
											<span>Bank Statement</span>
										</div>
									</div>
								</div>
							</div>
							<div className={`d-none d-md-block mt-4`}>
								{continueLaterAndSubmitBtn()}
							</div>
						</div>
					</div>
				)}

				{spinner && (
					<div
						className='position-fixed  w-100  d-flex justify-content-center align-items-center h-100'
						style={{
							top: '0px',
							left: '50%',
							transform: 'translate(-50%,0%)',
							backgroundColor: 'rgba(0,0,0,0.2)',
						}}
					>
						<IonSpinner name='lines-sharp' />
					</div>
				)}
			</IonContent>

			<IonFooter className='d-block d-md-none position-relative ion-padding lead_ftr_btn'>
				{continueLaterAndSubmitBtn()}
			</IonFooter>

			<CustomModal
				needIonContentWraper={false}
				isOpen={modalPageClose}
				initialBreakpoint={undefined}
				handleClose={() => setModalPageClose(false)}
				breakpoints={[]}
				className='height-auto confirm-popup-middle continue-later'
				modalContent={ContinueLaterPopUp(
					() => {
						setModalPageClose(false);
					},
					() => {
						new Promise(function (myResolve) {
							setTimeout(function () {
								myResolve(history.push('/dashboard/lead'));
								setModalPageClose(false);
							}, 10);
						});
					},
					'Cancel',
					'Continue Later',
					'className',
					'Are You Sure You Want to Exit?',
					`${t('You can continue later from where you left off.')}`
				)}
				needCross={false}
				needArrow={false}
			/>
		</>
	);
};
