import { IonCol, IonContent, IonPage } from '@ionic/react';
import { useEffect, useState } from 'react';
import { Header } from '../../../components/layout/header/Header';
import { FormatDate, sanitizeHtml } from '../../../helpers/functions';
import { CustomSearchbar } from '../../../components/common/custom-searchbar/CustomSearchbar';
import { CustomNoData } from '../../../components/common/custom-no-data/CustomNoData';
// import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getdmaList } from '../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { Divider } from '../../../components/common/divider/Divider';
import { useTranslation } from 'react-i18next';

export default function TeamsMyDmaList() {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const [dmalist, setDmaList] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const dmaSelector = useSelector((state: any) => state?.users);
	// const { t } = useTranslation();
	useEffect(() => {
		getList();
	}, []);
	const getList = async (searchItem = searchQuery) => {
		await dispatch(
			getdmaList({
				param: `?pageId=1&size=50&status=${
					dmaSelector?.dmaFlow?.dmaCode
				}&searchItem=${sanitizeHtml(encodeURIComponent(searchItem))}`,
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setDmaList(result?.list);
				}
			});
	};
	const searchInvitedDma = (value: any) => {
		if (value.length >= 3) {
			// getList(value, null, null, 1, pageSize);
			let debounceTimeout: any = 0;
			setSearchQuery(value);
			clearTimeout(debounceTimeout);
			debounceTimeout = setTimeout(() => {
				getList(value);
			}, 2000); // Debounce interval of 2000ms
		} else if (value.length === 0) {
			// setSearchValue('');
			// getList(null, null, null, currentPage, pageSize);
			let debounceTimeout: any = 0;
			setSearchQuery('');
			clearTimeout(debounceTimeout);
			debounceTimeout = setTimeout(() => {
				getList(value);
			}, 2000); // Debounce interval of 2000ms
		}
	};
	return (
		<IonPage>
			<Header
				needArrow={true}
				backButton={true}
				name={dmaSelector?.dmaFlow?.title}
				style={{ borderBottom: '1px solid #00000029' }}
			/>
			<IonContent>
				<div className='px-3 pt-3 d-flex align-items-center w-100'>
					<CustomSearchbar
						value={searchQuery}
						searchIcon='/images/icons/searchbar-icon.svg'
						placeholder={t('Search Team Member by “Name”')}
						showClearButton='focus'
						onChange={searchInvitedDma}
					/>
				</div>

				<div
					style={{
						overflowY: 'auto',
						height: 'calc(100vh - 140px)',
					}}
				>
					{dmalist && dmalist?.length ? (
						dmalist?.map((item: any, index) => {
							return (
								<IonCol
									size='12'
									key={index}
								>
									<div
										className='bg-white px-3 pb-3'
										style={{ borderBottom: '4px solid #F3F3F3' }}
									>
										<div className='d-flex ion-justify-content-between'>
											<h3 className='fs-16 fw-600'>
												{item.first_name + ' ' + item.last_name}
											</h3>
											<h3 className='fs-14 text-grey'>
												{FormatDate(item?.createdAt, 'mmmm dd, yyyy')}
											</h3>
										</div>
										{/* <div className='d-flex ion-justify-content-between'>
											<h3 className='fs-16 text-grey d-flex'>
												<IonImg
													src='/images/icons/lead-added-icon.svg'
													style={{ height: '16px', paddingRight: '4px' }}
												/>{' '}
												Leads added (This Month)
											</h3>
											<h3 className='fs-14 fw-600'>-</h3>
										</div>
										<div className='d-flex ion-justify-content-between'>
											<h3 className='fs-16 m-0 text-grey d-flex'>
												<IonImg
													src='/images/icons/leads-completely-disbursed.svg'
													style={{ height: '16px', paddingRight: '4px' }}
												/>
												Leads disbursed (This Month)
											</h3>
											<h3 className='fs-14 m-0 fw-600'>-</h3>
										</div> */}
										<Divider className='padding-top-border my-2' />
										<div className='d-flex justify-content-end'>
											<div className='d-flex gap-3 align-items-center'>
												<a href={`tel:${item?.mobile}`}>
													<img
														src='/images/icons/leads/phone.svg'
														alt='phone'
													/>
												</a>
												<a
													href={
														'https://api.whatsapp.com/send?phone=91' +
														item?.mobile
													}
												>
													<img
														src='/images/icons/leads/whatsapp.svg'
														alt='whatsapp'
													/>
												</a>
												<a href={`mailto:${item?.email}`}>
													<span
														style={{
															width: '32px',
															height: '32px',
															borderRadius: '50px',
															background: '#F3F3F3',
															// padding: '3px 10px',
														}}
													>
														<img
															src='/images/icons/mail-icon.svg'
															alt='mail'
														/>
													</span>
												</a>
											</div>
										</div>
									</div>
								</IonCol>
							);
						})
					) : (
						<CustomNoData />
					)}
				</div>
			</IonContent>
		</IonPage>
	);
}
