import { IonButton, IonContent, IonFooter, IonIcon } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	fetchMasterData,
	lenderDocumentUpload,
	saveLenderSpcfInfo,
} from '../../../../store/reducers/lead/leadSlice';
import {
	ContinueLaterPopUp,
	checkFieldsValidToContinue,
	showToastAlert,
} from '../../../../helpers/functions';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import { applicantUploadDocSchema } from './TataBl';
import { LenderNameForAPICall } from '../../../../helpers/lenders';
import {
	CustomModalLeadStatus,
	DynamicDropdown,
} from '../../../../components/common';
import { OfferRejectedMsg } from '../..';

export const BLDocUploadForm: React.FC<any> = () => {
	const documentObj = {
		docUploadType: '',
		docUploadName: '',
		file: '',
		preview: '',
		fileObj: {
			name: '',
			type: '',
		},
		message: '',
	};

	const [modalPageClose, setModalPageClose] = useState(false);
	const [documentTypes, setDocumentTypes] = useState([]);
	const [isShowPreview, setToShowPreview] = useState(false);
	const [leadStatusModalOpen, setLeadStatusModalOpen] = useState(false);
	const [applicationNo, setApplicationNO] = useState(null);
	const [extraText, setExtraText] = useState('');
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const history = useHistory();
	const dispatch = useDispatch();
	const blDocUpload: any = useRef([]);
	const { selectedLender, lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);

	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		reset,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(applicantUploadDocSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			documents: [documentObj] || [],
		},
	});

	useEffect(() => {
		if (!location.pathname.includes('/lead-lender-specific-form-steps')) {
			return;
		} else {
			getDataFromMaster();
		}
	}, [location.pathname]);

	/**
	 * Get data from masters
	 */
	const getDataFromMaster = async () => {
		await dispatch(fetchMasterData({ param: 'TATABL_DOCUMENT_TYPE' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setDocumentTypes(result?.data);
				}
			});
	};

	/**
	 * Upload documents
	 * @param event file upload event
	 * @param key documents array index
	 */
	const uploadDocuments = async (event: any, key: number) => {
		const file = event.target.files[0];
		const fileTypes = ['jpg', 'jpeg', 'png', 'pdf'];
		const fType = file?.type?.split('/')?.reverse()[0];
		if (!fType || !fileTypes?.includes(fType?.toLowerCase())) {
			setToShowPreview(false);
			setValue(
				`documents.${key}.message`,
				'Uploaded file is invalid. Only JPG/JPEG, PNG and PDF files are allowed.'
			);
			return;
		}

		if (file?.size / 1024 > 2048) {
			setToShowPreview(false);
			setValue(`documents.${key}.message`, 'File size should be less than 2MB');
			return;
		}
		setValue(`documents.${key}.message`, '');

		const formData: any = new FormData();
		formData.append('lender_name', LenderNameForAPICall.TATA_BL);
		formData.append('file', file);

		await dispatch(lenderDocumentUpload({ data: formData }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					const fileName = result.key?.split('/')?.reverse();
					setValue(`documents.${key}.docUploadName`, fileName[0]);
					setValue(`documents.${key}.file`, result?.key);
					setValue(`documents.${key}.fileObj`, file);
					const objectToUrl: any = URL.createObjectURL(file);
					setValue(`documents.${key}.preview`, objectToUrl);
					reset({
						...getValues(),
					});
				} else {
					setValue(`documents.${key}.message`, result.message);
				}
				setToShowPreview(false);
			});

		event.target.value = null;
	};

	useEffect(() => {
		setToShowPreview(true);
	}, [isShowPreview, !isShowPreview]);

	/**
	 * Show selected document preview
	 * @param key document array index
	 */
	const showFilePreview = (key: number) => {
		let previewItem: any;
		const fileObj: any = getValues(`documents.${key}.fileObj`);

		if (fileObj?.type?.includes('image')) {
			previewItem = (
				<>
					<img
						src={getValues(`documents.${key}.preview`)}
						alt='preview'
						className='me-2 file_preview'
						width={'100%'}
					/>
					<p className='filename'>{fileObj.name}</p>
				</>
			);
		} else if (fileObj?.type?.includes('pdf')) {
			previewItem = (
				<>
					<img
						src='/images/pdf.svg'
						alt='preview'
						className='me-2 file_preview'
						width={80}
					/>
					<p className='filename'>{fileObj.name}</p>
				</>
			);
		} else if (getValues(`documents.${key}.message`)) {
			previewItem = (
				<p className='text-red'>{t(getValues(`documents.${key}.message`))}</p>
			);
		}
		return previewItem;
	};

	/**
	 * Add new section to upload document
	 */
	const addNewDocument = () => {
		setValue('documents', [...getValues('documents'), documentObj]);
		reset({
			...getValues(),
		});
	};

	/**
	 * Remove specific item
	 * @param key index
	 */
	const removeDocument = (key: number) => {
		const documents = getValues('documents').filter((ele, k) => k !== key);
		setValue('documents', documents);
		reset({
			...getValues(),
		});
	};

	/**
	 * On submit to save user data and after that
	 */
	const onSubmit = async (data?: any, flag?: any) => {
		const documentRequest: any = [];
		getValues('documents')?.forEach((el: any) => {
			documentRequest.push({
				applicantType: '1',
				docUploadType: el.docUploadType,
				docUploadName: el.docUploadName,
				file: el.file,
			});
		});

		const req: any = {
			lender_spf_details: {
				...lenderSpecificDetails?.lender_spf_details,
				documents: documentRequest,
			},
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.TATA_BL,
			step: 'step4',
			isNewLead: isValid && flag !== 'continue_later',
		};

		if (lenderSpecificDetails?.lead_spf_detail_id) {
			req.lead_spf_detail_id = lenderSpecificDetails?.lead_spf_detail_id;
		}
		setShowOfferErrMsg('');
		await dispatch(saveLenderSpcfInfo({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					if (req.isNewLead) {
						let appNo: any = `Webtop No: ${result.data?.response?.WEBTOP_ID}\nOpportunity ID: ${result.data?.response?.OpportunityId}`;
						if (result.data?.approve_amount > 0) {
							appNo += `\nYour approved amount is ₹ ${result.data?.approve_amount} *`;
						} else {
							setExtraText(
								'Loan offer is not generated basis the bank statement, Please try with Netbanking or IT Online or IT Statement or GST statement.'
							);
						}
						setApplicationNO(appNo);
						setLeadStatusModalOpen(true);
					}
				} else {
					setShowOfferErrMsg(result?.message);
				}
			});
	};

	/**
	 * Continue Later and Submit button
	 */
	const continueLaterAndSubmitBtn = () => {
		return (
			<>
				<CustomButton
					className='w-100 fw-bold dashboard-add-lead-button mb-3'
					expand='block'
					fill='outline'
					title={t('Continue Later')}
					onClick={() => {
						if (checkFieldsValidToContinue(errors)) {
							setModalPageClose(true);
						} else {
							showToastAlert({
								type: 'error',
								message: 'Please fix above errors',
							});
						}
					}}
				/>
				<CustomButton
					type='submit'
					className='w-100 fw-bold dashboard-add-lead-button'
					expand='block'
					title={t('Proceed')}
					disabled={!isValid}
					onClick={handleSubmit(onSubmit)}
				/>
			</>
		);
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
							<form onSubmit={handleSubmit(onSubmit)}>
								<>
									{getValues('documents').map((el: any, k: any) => {
										return (
											<div key={k}>
												<Controller
													control={control}
													render={({ field: { onChange, onBlur, name } }) => (
														<DynamicDropdown
															label={t(`Document Type *`)}
															data={documentTypes}
															name={name}
															onChange={(e: any) => {
																onChange(e);
															}}
															bindField={'value'}
															showField1={'value'}
															value={getValues(name)}
															onBlur={onBlur}
															errors={
																errors?.documents &&
																errors?.documents[k]?.docUploadType?.message
															}
														/>
													)}
													name={`documents.${k}.docUploadType`}
												/>

												<div className='mb-4'>
													<CustomButton
														onClick={() => blDocUpload?.current[k]?.click()}
														expand='block'
														fill={'solid'}
													>
														<img
															src='/images/icons/upload-white.svg'
															alt='upload'
															className='me-2'
														/>
														{t('Select File')}
													</CustomButton>
													<span className='text-muted font-small'>
														Acceptable File Format : png, jpeg, jpg, pdf Maximum
														file size limit : 2MB
													</span>
													{/* input file upoad on button click */}
													<input
														type='file'
														style={{ display: 'none' }}
														accept='image/jpg,image/jpeg,image/png,application/pdf'
														ref={(el) => (blDocUpload.current[k] = el)}
														id={k}
														onChange={(event) => uploadDocuments(event, k)}
													/>

													{isShowPreview && (
														<div className='mt-2 mb-2'>
															{showFilePreview(k)}
														</div>
													)}
												</div>

												<div className='add_rm_buttons'>
													{k === getValues('documents').length - 1 && (
														<IonButton
															type='button'
															shape='round'
															color='primary'
															onClick={() => addNewDocument()}
														>
															<IonIcon src='/images/icons/add-icon.svg' />
														</IonButton>
													)}

													{getValues('documents').length > 1 && (
														<IonButton
															shape='round'
															type='button'
															color='danger'
															onClick={() => removeDocument(k)}
														>
															<IonIcon src='/images/icons/delete.svg' />
														</IonButton>
													)}
												</div>
											</div>
										);
									})}
								</>
							</form>

							<div className={`d-none d-md-block`}>
								{continueLaterAndSubmitBtn()}
							</div>
						</div>
					</div>
				)}
			</IonContent>

			{!showOfferErrMsg && (
				<IonFooter className='d-block d-md-none position-relative ion-padding lead_ftr_btn'>
					{continueLaterAndSubmitBtn()}
				</IonFooter>
			)}

			<CustomModal
				needIonContentWraper={false}
				isOpen={modalPageClose}
				initialBreakpoint={undefined}
				handleClose={() => setModalPageClose(false)}
				breakpoints={[]}
				className='height-auto confirm-popup-middle continue-later'
				modalContent={ContinueLaterPopUp(
					() => {
						setModalPageClose(false);
					},
					() => {
						new Promise(function (myResolve) {
							setTimeout(function () {
								myResolve(history.push('/dashboard/lead'));
								onSubmit(getValues(), 'continue_later');
								setModalPageClose(false);
							}, 10);
						});
					},
					'Cancel',
					'Continue Later',
					'className',
					'Are You Sure You Want to Exit?',
					`${t('You can continue later from where you left off.')}`
				)}
				needCross={false}
				needArrow={false}
			/>

			{leadStatusModalOpen && (
				<CustomModalLeadStatus
					isOpen={leadStatusModalOpen}
					applicationNo={applicationNo}
					extraText={extraText}
					handleClose={() => setLeadStatusModalOpen(false)}
				/>
			)}
		</>
	);
};
