import { createRoot } from 'react-dom/client';

import { App } from './App';
const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { persistor, store } from './store/store';

root.render(
	// <React.StrictMode>
	<Provider store={store}>
		<PersistGate
			loading={null}
			persistor={persistor}
		>
			<App />
		</PersistGate>
	</Provider>

	// </React.StrictMode>
);