import { IonContent, IonFooter } from '@ionic/react';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	getLeadFlowUrl,
	lenderSpfDetailsFlow,
} from '../../../../store/reducers/lead/leadSlice';
import { CustomInput } from '../../../../components/common/custom-input/CustomInput';
import {
	ContinueLaterPopUp,
	checkFieldsValidToContinue,
	disableNumberArrows,
	fetchStateCityByPincode,
	getBlankKeyFromObject,
	getEncrypted,
	showToastAlert,
} from '../../../../helpers/functions';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import {
	customerType,
	loanPurposeBL,
	accommodationTypeBL,
	correspondingFinancialYearBL,
	TataPLRef1Schema,
	refDetailsOne,
	TataPLFormTitle,
	referenceType,
} from '../TataCapitals';
import { LenderNameForAPICall } from '../../../../helpers/lenders';
import { DynamicDropdown } from '../../../../components/common';
import { LENDER_SPECIFIC_DATA, REGEX } from '../../../../helpers/constant';
import StateCity from '../../../../components/common/StateCity';

export const PLReferenceOne: React.FC<any> = () => {
	const [modalPageClose, setModalPageClose] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const [url, setUrl] = useState('');
	const [pinCodeData, setPinCodeData] = useState<any>([]);
	const { selectedLender, lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);

	const {
		handleSubmit,
		control,
		getValues,
		formState: { errors, isValid },
		setValue,
		trigger,
	} = useForm({
		resolver: yupResolver(TataPLRef1Schema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			ReferenceFirstName1:
				lenderSpecificDetails?.lender_spf_details?.ReferenceFirstName1,
			ReferenceLastName1:
				lenderSpecificDetails?.lender_spf_details?.ReferenceLastName1,
			ReferenceMobileNumber1:
				lenderSpecificDetails?.lender_spf_details?.ReferenceMobileNumber1,
			ReferenceState1:
				lenderSpecificDetails?.lender_spf_details?.ReferenceState1,
			ReferenceCity1: lenderSpecificDetails?.lender_spf_details?.ReferenceCity1,
			ReferencePincode1:
				lenderSpecificDetails?.lender_spf_details?.ReferencePincode1,
			ReferenceAddress1:
				lenderSpecificDetails?.lender_spf_details?.ReferenceAddress1,
			ReferenceLandmark1:
				lenderSpecificDetails?.lender_spf_details?.ReferenceLandmark1,
			ApplicantRelationship1:
				lenderSpecificDetails?.lender_spf_details?.ApplicantRelationship1,
			AddressType1:
				lenderSpecificDetails?.lender_spf_details?.AddressType1 ||
				'OfficeAddress',
		},
	});
	console.log(getValues('ReferenceCity1'), getValues('ReferenceState1'));
	/**
	 * On submit to save user data and after that
	 * @param data Form data
	 */

	useEffect(() => {
		if (!location.pathname.includes('/lead-lender-specific-form-steps')) {
			return;
		} else {
			const fetchLeadFlowUrl = async () => {
				try {
					const response = await dispatch(getLeadFlowUrl());
					const url = response.payload.url;
					const pathname = new URL(url).origin;
					setUrl(pathname);
				} catch (error) {
					console.error('Error fetching lead flow URL:', error);
				}
			};
			fetchLeadFlowUrl();
		}
	}, [location.pathname]);

	const onSubmit = async (data: any) => {
		console.log(data, 'data on submit');

		const blankKey = getBlankKeyFromObject(JSON.parse(JSON.stringify(data)));
		const endpoint = '/lead-lender-specific-form-steps';
		const req: any = {
			lender_spf_details: data,
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.TATA_BL,
			step: Object.keys(blankKey).length > 0 ? 'step2' : 'step3',
			isNewLead: Object.keys(blankKey).length === 0,
			// sourceSystemURL: `${process.env.REACT_APP_UI_ENDPOINT}/lead-lender-specific-form-steps`,
			sourceSystemURL: url + endpoint,
		};

		if (lenderSpecificDetails?.lead_spf_detail_id) {
			req.lead_spf_detail_id = lenderSpecificDetails?.lead_spf_detail_id;
		}

		const encodedData = getEncrypted(
			JSON.stringify({
				ReferenceFirstName1: data?.ReferenceFirstName1,
				ReferenceLastName1: data?.ReferenceLastName1,
				ReferenceMobileNumber1: data?.ReferenceMobileNumber1,
				ReferenceState1: data?.ReferenceState1,
				ReferenceCity1: data?.ReferenceCity1,
				ReferencePincode1: data?.ReferencePincode1,
				ReferenceAddress1: data?.ReferenceAddress1,
				ReferenceLandmark1: data?.ReferenceLandmark1,
				ApplicantRelationship1: data?.ApplicantRelationship1,
				AddressType1: data?.AddressType1 || 'OfficeAddress',
			})
		);
		localStorage.setItem(LENDER_SPECIFIC_DATA, encodedData);
		dispatch(
			lenderSpfDetailsFlow({
				...lenderSpecificDetails,
				lead_spf_detail_id: lenderSpecificDetails?.lead_spf_detail_id,
				headerTitle: TataPLFormTitle.REFERENCE_TWO,
				step: 'step5',
			})
		);

		// dispatch(
		// 	lenderTataUpdateLead({
		// 		data: {
		// 			...data,
		// 			lender_name: LenderNameForAPICall.TATA_PL,
		// 			lead_spf_detail_id: lenderSpecificDetails?.lead_spf_detail_id,
		// 			lead_id: lenderSpecificDetails?.lead_id,
		// 		},
		// 	})
		// ).then(() => {
		// 	dispatch(
		// 		lenderSpfDetailsFlow({
		// 			...lenderSpecificDetails,
		// 			lead_spf_detail_id: lenderSpecificDetails?.lead_spf_detail_id,
		// 			headerTitle: TataPLFormTitle.REFERENCE_TWO,
		// 			step: 'step4',
		// 		})
		// 	);
		// });
	};

	return (
		<>
			<IonContent>
				<div className='select-product small-container bg-web'>
					<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10'>
						<form onSubmit={handleSubmit(onSubmit)}>
							{refDetailsOne.map(
								({ name, type, placeholder }: any, index: any) => {
									const errorText: any =
										errors && Object.keys(errors).length > 0 && errors;
									return (
										<div key={index}>
											{type === 'dropdown' ? (
												<div style={{ marginBottom: '15px' }}>
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, name } }) => (
															<DynamicDropdown
																label={t(`${placeholder}`)}
																data={
																	name == 'ApplicantRelationship1'
																		? referenceType
																		: name === 'customer_type'
																		? customerType
																		: name === 'loan_purpose'
																		? loanPurposeBL
																		: name === 'accommodation_type'
																		? accommodationTypeBL
																		: name === 'corresponding_financial_year'
																		? correspondingFinancialYearBL
																		: []
																}
																name={name}
																onChange={(e: any) => {
																	onChange(e);
																}}
																bindField={'value'}
																showField1={
																	name === 'loan_purpose' ? 'title' : 'value'
																}
																value={getValues(name)}
																onBlur={onBlur}
																errors={errorText[name]?.message}
															/>
														)}
														name={name}
													/>
												</div>
											) : (
												<Controller
													control={control}
													render={({ field: { onChange, onBlur, name } }) => (
														<CustomInput
															type={type}
															label={t(placeholder)}
															onBlur={onBlur}
															id={name}
															onChange={(e: any) => {
																console.log(name, 'name');

																if (name === 'ReferencePincode1') {
																	console.log(
																		e.detail.value.length,
																		'e.detail.value.length'
																	);

																	if (e.detail.value.length === 6) {
																		fetchStateCityByPincode(
																			setValue,
																			'ReferenceCity1',
																			'ReferenceState1',
																			setPinCodeData,
																			dispatch,
																			e.detail.value,
																			trigger
																		);
																	} else {
																		setValue('ReferenceState1', '');
																		setValue('ReferenceCity1', '');
																		setPinCodeData([]);
																	}
																}
																onChange(e);
															}}
															value={getValues(name)}
															onBeforeInput={(evt: any) => {
																if (type == 'number') {
																	!REGEX.ALLOWNUMBERS.test(evt.data) &&
																		evt.preventDefault();
																}
															}}
															onKeyDown={(e: any) => {
																if (type == 'number') {
																	disableNumberArrows(e);
																}
															}}
															onWheel={(e: any) => {
																if (type == 'number') {
																	e.target.blur();
																}
															}}
															className={`${
																name === 'pan_no' ? 'text-uppercase' : ''
															}${
																[
																	'ReferenceFirstName1',
																	'ReferenceLastName1',
																].includes(name)
																	? ' text-capitalize '
																	: ''
															}`}
															errors={t(errorText[name]?.message || '')}
														/>
													)}
													name={name}
												/>
											)}
										</div>
									);
								}
							)}
							<StateCity
								cityInputName='ReferenceCity1'
								stateInputName='ReferenceState1'
								control={control}
								setValue={setValue}
								pinCodeData={pinCodeData}
								trigger={trigger}
								getValues={getValues}
							/>
						</form>

						<div className={` d-none d-md-block`}>
							<CustomButton
								className='w-100 fw-bold dashboard-add-lead-button mb-3'
								expand='block'
								fill='outline'
								title={t('Continue Later')}
								onClick={() => {
									if (checkFieldsValidToContinue(errors)) {
										setModalPageClose(true);
									} else {
										showToastAlert({
											type: 'error',
											message: 'Please fix above errors',
										});
									}
								}}
							/>
							<CustomButton
								type='submit'
								className='w-100 fw-bold dashboard-add-lead-button'
								expand='block'
								title={t('Proceed')}
								disabled={!isValid}
								onClick={handleSubmit(onSubmit)}
							/>
						</div>
					</div>
				</div>

				<CustomModal
					needIonContentWraper={false}
					isOpen={modalPageClose}
					initialBreakpoint={undefined}
					handleClose={() => setModalPageClose(false)}
					breakpoints={[]}
					className='height-auto confirm-popup-middle continue-later'
					modalContent={ContinueLaterPopUp(
						() => {
							setModalPageClose(false);
						},
						() => {
							new Promise(function (myResolve) {
								setTimeout(function () {
									myResolve(history.push('/dashboard/lead'));
									onSubmit(getValues());
									setModalPageClose(false);
								}, 10);
							});
						},
						'Cancel',
						'Continue Later',
						'className',
						'Are You Sure You Want to Exit?',
						`${t('You can continue later from where you left off.')}`
					)}
					needCross={false}
					needArrow={false}
				/>
			</IonContent>

			<IonFooter className='d-block d-md-none position-relative'>
				<div
					style={{
						backgroundColor: '#fff',
						zIndex: 2,
					}}
				>
					<div className={`ion-padding`}>
						<CustomButton
							className='w-100 fw-bold dashboard-add-lead-button mb-3'
							expand='block'
							fill='outline'
							title={t('Continue Later')}
							onClick={() => {
								if (checkFieldsValidToContinue(errors)) {
									setModalPageClose(true);
								} else {
									showToastAlert({
										type: 'error',
										message: 'Please fix above errors',
									});
								}
							}}
						/>
						<div className='proceed-btn'>
							<CustomButton
								type='submit'
								className='w-100 fw-bold dashboard-add-lead-button'
								expand='block'
								title={t('Proceed')}
								disabled={!isValid}
								onClick={handleSubmit(onSubmit)}
							/>
						</div>
					</div>
				</div>
			</IonFooter>
		</>
	);
};
