import styled from 'styled-components';
import { IonChip, IonLabel } from '@ionic/react';

interface ChipProps {
  selected: boolean;
}

export const CustomChipLabel = styled(IonLabel)<ChipProps>`
font-weight: ${props => (props.selected ? '' : 'normal !important')}; 
`;


export const CustomChip = styled(IonChip) <ChipProps>`
  /* primary */
  /* color: blue; */
  color: ${props => (props.selected ? 'var(--ion-color-primary)' : '')};
  border: ${props => (props.selected ? '1px solid' : '1px')};
  padding: 8px;
  /* background-color: #e0e0e0; */
  box-shadow: ${props => (props.selected ? '2px 6px 8px rgba(0, 0, 0, 0.2)' : '')};
  
`;



export const CustomSelectedChip = styled(IonChip)`
  /* primary */
  /* color: blue; */
  color: var(--ion-color-primary);
  border: 1px solid;
  padding: 8px; /* removed quotes */
  box-shadow: 2px 6px 8px rgba(0, 0, 0, 0.2);
`;

export const CustomUnSelectedChip = styled(IonChip)`
  border: 1px ;
  padding: '8px';
  // background-color: #e0e0e0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;


export const ErrorMessage = styled.span`
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 17px;
  color: red;
  font-size: 12px;
`;


export const ErrorTitle = styled.div`
color: var(--ion-color-danger);;
`;
