import { IonContent, IonItem, IonPage } from '@ionic/react';
import { Header } from '../../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { payoutProductInfo } from '../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';

export const DmaApprovedPayoutDetails: React.FC = () => {
	const { t } = useTranslation();
	const [payoutDetails, setPayoutDetails] = useState<any>();
	const dmaSelector = useSelector((state: any) => state?.users);
	const dispatch = useDispatch();

	const getUserPayoutDetails = async () => {
		await dispatch(
			payoutProductInfo({ param: dmaSelector?.dmaFlow?.dmaId + '/0' })
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setPayoutDetails(response?.Data);
				}
			});
	};

	useEffect(() => {
		getUserPayoutDetails();
	}, []);

	return (
		<IonPage>
			<Header
				needArrow={true}
				backButton={true}
				name={t('Products & Payout Info')}
			/>
			<IonContent className='background-web'>
				<div className='bg-web my-md-4'>
					<div className='container-width-md bg-web-white  border-md-radius-10'>
						<div
							className='scroll-navbar bg-white border-bottom d-flex gap-4 position-sticky  border-md-radius-10'
							style={{
								top: 0,
								zIndex: 11,
								overflow: 'hidden',
								width: '100%',
								justifyContent: 'center',
							}}
						>
							<div className={'text-nowrap pt-2 pb-3 fw-600 activeTab'}>
								{t('Loan')}
							</div>
						</div>
						<div className='product-and-payout-info mt-4'>
							<IonItem
								className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
									<p className='mb-0 fs-14'>{t('Threshold Breached')}</p>
									<p className='mb-0 fs-14 fw-600'>
										{payoutDetails?.Loan?.threshold_breached === 1
											? 'Yes'
											: 'No'}
									</p>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
								lines='full'
							>
								<div className='d-flex flex-grow-1 justify-content-between my-3'>
									<p className='mb-0 fs-14'>{t('Owner')}</p>
									<div className='text-end'>
										<p className='mb-0 fs-14 fw-600'>
											{payoutDetails?.Loan?.owner_name
												? payoutDetails?.Loan?.owner_name
												: '-'}
										</p>
										<p className='mb-0 fs-14 heading-color'>
											{payoutDetails?.Loan?.owner_role
												? payoutDetails?.Loan?.owner_role
												: '-'}
										</p>
									</div>
								</div>
							</IonItem>
							<IonItem lines='full'>
								<div className='d-flex flex-grow-1 justify-content-between my-3'>
									<p className='mb-0 fs-14'>{t('Approved By')}</p>
									<div className='text-end'>
										<p className='mb-0 fs-14 fw-600'>
											{payoutDetails?.Loan?.approved_by_name
												? payoutDetails?.Loan?.approved_by_name
												: '-'}
										</p>
										<p className='mb-0 fs-14 heading-color'>
											{payoutDetails?.Loan?.approver_role
												? payoutDetails?.Loan?.approver_role
												: '-'}
										</p>
									</div>
								</div>
							</IonItem>
							<IonItem
								className='ion-no-padding ion-margin-horizontal mt-2 remove-ion-item-inner-padding-end'
								lines='none'
							>
								<div className='d-flex flex-grow-1 align-items-center justify-content-between'>
									<p className='mb-0 fs-14 fw-600 heading-color'>
										{t('Sub Product')}
									</p>
									<p className='mb-0 fs-14 fw-600 heading-color'>
										{t('Proposed Payout')}
									</p>
								</div>
							</IonItem>
							{payoutDetails?.Loan?.subproducts?.map(
								(subProduct: any, index: any) => {
									return (
										<IonItem
											key={index}
											className='ion-no-padding ion-margin-horizontal remove-ion-item-inner-padding-end'
											lines='full'
										>
											<div className='d-flex flex-grow-1 align-items-center justify-content-between py-2'>
												<div className='d-flex align-items-start'>
													<img
														className='mt-1'
														style={{ width: '20px' }}
														src={`images/icons/products/${subProduct.icon}.svg`}
													/>
													<div className='ms-2'>
														<p className='mb-0 fs-14 fw-600'>
															{subProduct?.category_name
																? subProduct?.category_name
																: ''}
														</p>
														<p className='mb-0 fs-14'>
															{subProduct?.reporting_name
																? subProduct?.reporting_name
																: ''}
															{subProduct?.reporting_role
																? ', ' + subProduct?.reporting_role
																: ''}
														</p>
													</div>
												</div>
												<p className='mb-0 fs-14 fw-600'>
													{subProduct?.percentage
														? subProduct?.percentage + '%'
														: ''}
												</p>
											</div>
										</IonItem>
									);
								}
							)}
						</div>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};
