import { IonContent, IonFooter, IonImg, IonPage } from '@ionic/react';
import { useEffect, useState } from 'react';
import {
	cbilConsentDetails,
	cbilRequestConsentDetails,
	leadBureauCustomer,
	leadFlow,
} from '../../../store/reducers/lead/leadSlice';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { generateCibilOtp } from '../../../store/reducers/user/userSlice';
import { useHistory } from 'react-router';
import { Header } from '../../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';

export default function LeadCustomerConsentProcessing({ isFullPage = true }) {
	const [startApiInterval, setStartApiInterval] = useState(true);
	const [startCountDown, setStartCountDown] = useState(true);
	const [consentDetails, setConsentDetails]: any = useState({
		status: true,
		message: 'Waiting for consent from customer',
		is_rejected: false,
	});
	const { t } = useTranslation();
	const leadBureauCustomerSelector = useSelector(
		(state: any) => state?.lead?.leadBureauCustomerData
	);
	const history = useHistory();
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	const dispatch = useDispatch();
	const [seconds, setSeconds] = useState(60);
	useEffect(() => {
		let timerCountDown: any;
		if (startCountDown) {
			timerCountDown = setInterval(() => {
				// Decrease the seconds by 1
				setSeconds((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0));
			}, 1000);

			// Clear the interval when the component unmounts
		}
		return () => {
			if (timerCountDown) {
				clearInterval(timerCountDown);
			}
			setStartCountDown(false);
		};
	}, [startCountDown]);

	// Reset the timer when it reaches 0
	useEffect(() => {
		if (seconds === 0 && startCountDown) {
			// Reset the timer to 60 seconds
			setSeconds(60);
		}
	}, [seconds]);

	useEffect(() => {
		if (
			window?.location?.pathname === '/customer-consent-processing' &&
			!startApiInterval
		) {
			setStartApiInterval(true);
		}
		if (
			window?.location?.pathname === '/customer-consent-processing' &&
			!startCountDown
		) {
			setStartCountDown(true);
		}
	}, [window.location.pathname]);

	useEffect(() => {
		let intervalId: any;
		if (startApiInterval) {
			intervalId = setInterval(() => {
				checkConsent();
			}, 10000);
		} else {
			if (intervalId) {
				clearInterval(intervalId);
			}
		}
		return () => {
			if (intervalId) {
				clearInterval(intervalId);
				// setConsentDetails({ ...consentDetails, status: true });
			}
			// setStartCountDown(false);
		};
	}, [startApiInterval]);

	const checkConsent = async () => {
		setStartApiInterval(false);
		dispatch(
			cbilConsentDetails({
				param: `${leadInfo?.leadId}/${leadInfo?.pccId}`,
			})
		)
			.then(unwrapResult)
			.then(async (response: any) => {
				setStartApiInterval(true);
				setConsentDetails({
					status: response?.status,
					message: response?.message
						? response?.message
						: 'Waiting for consent from customer',
					is_rejected: response?.is_rejected,
				});
				if (response?.status && response?.message) {
					setStartCountDown(false);
					setSeconds(60);
					setStartApiInterval(false);
					await dispatch(
						generateCibilOtp({
							data: {
								...leadBureauCustomerSelector?.checkScoreBody,
								lead_id: leadInfo?.leadId || leadBureauCustomerSelector?.leadId,
								mobile_no: leadInfo?.leadMobile,
							},
						})
					)
						.then(unwrapResult)
						.then(async (cbilOtpResponse: any) => {
							if (cbilOtpResponse.status) {
								if (leadInfo?.lead_consent_type === 'Customer') {
									dispatch(
										leadBureauCustomer({
											...leadBureauCustomerSelector,
											cbilResponse: {
												ChallengeConfigGUID:
													cbilOtpResponse?.ChallengeConfigGUID,
												answerKey: cbilOtpResponse?.answerKey,
												questionKey: cbilOtpResponse?.questionKey,
											},
											type: 'VERIFY_CIBIL_OTP',
											reSendCibilOtp: false,
											needCibil: true,
										})
									);
									history.push('/verify-otp-bureau-report');
								} else {
									dispatch(
										leadFlow({
											...leadInfo,
											cbilResponse: {
												ChallengeConfigGUID:
													cbilOtpResponse?.ChallengeConfigGUID,
												answerKey: cbilOtpResponse?.answerKey,
												questionKey: cbilOtpResponse?.questionKey,
											},
											step: 'step6',
											headerTitle: '',
											subHeaderStepsCounter: false,
											needCibil: true,
											needCross: false,
											needArrow: true,
											reSendCibilOtp: false,
										})
									);
								}
							}
						});
				} else if (!response?.status && response?.message) {
					setStartCountDown(false);
					setSeconds(60);
					setStartApiInterval(false);
					// history.push('/dashboard/lead');
				}
			});
	};

	const reSentConsentRequest = async () => {
		dispatch(
			cbilRequestConsentDetails({
				param: leadInfo?.leadId,
			})
		)
			.then(unwrapResult)
			.then(async (response: any) => {
				if (response.status) {
					// no else condition , already on same page
					if (response?.is_consent) {
						setStartCountDown(false);
						setSeconds(60);
						setStartApiInterval(false);
						if (response?.is_otp) {
							if (leadInfo?.lead_consent_type === 'Customer') {
								// for check score
								history.replace('/view-bureau-report');
							} else {
								history.replace('/bureau-report');
							}
						} else {
							// generate cibil otp
							// for check score
							console.log('data:', {
								...leadBureauCustomerSelector?.checkScoreBody,
								lead_id: leadInfo?.leadId || leadBureauCustomerSelector?.leadId,
								mobile_no: leadInfo?.leadMobile,
							});

							await dispatch(
								generateCibilOtp({
									data: {
										...leadBureauCustomerSelector?.checkScoreBody,
										lead_id:
											leadInfo?.leadId || leadBureauCustomerSelector?.leadId,
										mobile_no: leadInfo?.leadMobile,
									},
								})
							)
								.then(unwrapResult)
								.then(async (cbilOtpResponse: any) => {
									if (cbilOtpResponse.status) {
										if (leadInfo?.lead_consent_type === 'Customer') {
											dispatch(
												leadBureauCustomer({
													...leadBureauCustomerSelector,
													cbilResponse: {
														ChallengeConfigGUID:
															cbilOtpResponse?.ChallengeConfigGUID,
														answerKey: cbilOtpResponse?.answerKey,
														questionKey: cbilOtpResponse?.questionKey,
													},
													type: 'VERIFY_CIBIL_OTP',
													reSendCibilOtp: false,
													needCibil: true,
												})
											);
											history.push('/verify-otp-bureau-report');
										} else {
											dispatch(
												leadFlow({
													...leadInfo,
													cbilResponse: {
														ChallengeConfigGUID:
															cbilOtpResponse?.ChallengeConfigGUID,
														answerKey: cbilOtpResponse?.answerKey,
														questionKey: cbilOtpResponse?.questionKey,
													},
													lead_consent_type: 'Lead',
													step: 'step6',
													headerTitle: '',
													subHeaderStepsCounter: false,
													needCibil: true,
													needCross: false,
													needArrow: true,
													reSendCibilOtp: false,
												})
											);
										}
									}
								});
						}
					}
				}
			});
	};

	return (
		<>
			{isFullPage ? (
				<IonPage>
					<Header
						name=''
						backButton={true}
						backButtonText=''
						needArrow={true}
						needArrowOnClick={() => history.goBack()}
					/>
					<IonContent>
						<div className={`pt-5 mt-5`}>
							{/* <IonImg
								src='/images/icons/no-data-folder-icon.svg'
								style={{ height: '48px' }}
							/> */}
							<h2 className='m-0 text-center py-2'>{t('CIBIL Check')}</h2>

							<h3
								className='fs-14 text-center py-2'
								style={{ color: '#5D5E72' }}
							>
								<div className='py-2'>{t(consentDetails?.message)}</div>

								{/* <div
									className='py-2 text-decoration-underline cursor-pointer'
									onClick={checkConsent}
								>
									{t('Resent consent request')}
								</div> */}
								<div
									className='py-2 text-decoration-underline cursor-pointer'
									onClick={reSentConsentRequest}
								>
									{t('Resent consent request')}
								</div>
							</h3>
							{startCountDown && (
								<h4 className='text-center py-2 m-0 fs-16'>
									00:{seconds < 10 ? `0${seconds}` : seconds}
								</h4>
							)}
						</div>
						<div className='ion-float-right pe-2 mt-5'>
							<IonImg
								src='/images/icons/powered_by_cbil.jpg'
								style={{ width: '75px' }}
							/>
						</div>
					</IonContent>
					<IonFooter>
						{leadInfo?.from !== 'customer' && consentDetails?.is_rejected ? (
							<div className='position-absolute bottom-0 w-100 py-2'>
								<div className='text-center'>
									<CustomButton
										expand=''
										className='btn-blue'
										onClick={() => {
											history.replace('/dashboard/lead');
										}}
										title='Okay'
									/>
								</div>
							</div>
						) : (
							''
						)}
					</IonFooter>
				</IonPage>
			) : (
				<IonContent>
					<div
						className='position-relative'
						style={{ height: '100%' }}
					>
						<div
							className='d-flex flex-column justify-content-center'
							style={{ height: '400px' }}
						>
							<div className={`pt-5 mt-5`}>
								{/* <IonImg
							src='/images/icons/no-data-folder-icon.svg'
							style={{ height: '48px' }}
						/> */}

								<h2 className='m-0 text-center py-2'>{t('CIBIL Check')}</h2>

								<h3
									className='fs-14 text-center py-2 m-0'
									style={{ color: '#5D5E72' }}
								>
									<div className='py-2'>{t(consentDetails?.message)}</div>
									<div
										className='py-2 text-decoration-underline cursor-pointer'
										onClick={reSentConsentRequest}
									>
										{t('Resent consent request')}
									</div>
								</h3>
								{startCountDown && (
									<h4 className='text-center py-2 m-0 fs-16'>
										00:{seconds < 10 ? `0${seconds}` : seconds}
									</h4>
								)}
							</div>
							<div className='d-flex justify-content-end pe-2 mt-5'>
								<IonImg
									src='/images/icons/powered_by_cbil.jpg'
									style={{ width: '75px' }}
								/>
							</div>
						</div>
						{consentDetails?.is_rejected ? (
							<div className='position-absolute bottom-0 w-100 py-2'>
								<div className='text-center'>
									<CustomButton
										expand=''
										className='btn-blue'
										onClick={() => {
											history.replace('/dashboard/lead');
										}}
										title='Okay'
									/>
								</div>
							</div>
						) : (
							''
						)}
					</div>
				</IonContent>
			)}
		</>
	);
}
