import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { CustomInput } from '../../../components/common/custom-input/CustomInput';
import { CustomCheckbox } from '../../../components/common/custom-checkbox/CustomCheckbox';
import { useTranslation } from 'react-i18next';
import {
	IonItem,
	IonLabel,
	IonList,
	IonRadio,
	IonRadioGroup,
	isPlatform,
} from '@ionic/react';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { Controller, useForm } from 'react-hook-form';
import { professionalDetailsKycScema } from './KycValidationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import {
	InputFormatCurrencyValue,
	capFirstLetter,
	checkFieldsValidToContinue,
	dataURLtoFile,
	disableNumberArrows,
	fetchStateCityByPincode,
	restrictAmountChar,
	showToastAlert,
} from '../../../helpers/functions';
import './MyKyc.scss';
import CustomDropDown from '../../../components/common/custom-dropdown/CustomDropDown';
import { UploadImageCard } from './UploadImageCard';
import { useHistory, useLocation } from 'react-router';
import DropDownModal from '../../../components/common/custom-dropdown/DropDownModal';
import { useDispatch, useSelector } from 'react-redux';
import {
	imgUrlUpdate,
	kycAddressProof,
	kycBasicInfo,
	// kycDocumnetUpload,
	kycDocumnetUploadProfessionalDetail,
	kycLeadProduct,
	kycUserProfession,
	kycUserUploadImage,
} from '../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { Divider } from '../../../components/common/divider/Divider';
import { REGEX } from '../../../helpers/constant';
import StateCity from '../../../components/common/StateCity';

export default function ProfessionalDetailsKyc({ setCurrentStep }: any) {
	const [isFinance, setIsFinance] = useState<any>('');
	const [isAssociated, setIsAssociated] = useState<any>('');
	const dmaSelector = useSelector((state: any) => state?.users);
	const { t } = useTranslation();
	const location: any = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const [openUploadModal, setOpenUploadModal] = useState(false);
	const [clickImageFieldName, setClickImageFieldName] = useState<any>(null);
	const [imagePreviewUpload, setImagePreviewUpload] = useState({
		addressProofImage: false,
	});
	const [productData, setProductData] = useState([]);
	const [userProfession, setUserProfession] = useState([]);
	const [addressProof, setAddressProof] = useState([]);
	const [disablePermanentAddress, setDisablePermanentAddress] =
		useState<boolean>(false);
	const [pinCodeData, setPinCodeData] = useState<any>([]);
	const [RegisteredAsType, setRegisteredAsType] = useState<any>('');
	const [schema, setSchema] = useState<any>(professionalDetailsKycScema);
	const isBrowser = !isPlatform('capacitor');
	const [docTypeErrorMessage, setDocTypeErrorMessage] = useState({
		addressProofError: '',
	});
	const [checkIsAadhaar, setCheckIsAadhaar] = useState(false);
	const [aadhaarUrl, setAadhaarUrl] = useState('');
	const isWeb = useSelector((state: any) => state?.app?.isWeb);
	let continueLater = false;

	useEffect(() => {
		if (location.pathname !== '/kyc') {
			return;
		}
		fetchProfessionalDetailsKyc();
	}, [dmaSelector?.user_details?.id, location.pathname]);

	useEffect(() => {
		if (location.pathname !== '/kyc') {
			return;
		}
		fetchLeadProduct();
		fetchUserProfession();
		fetchUserAddrssProof();
	}, [location.pathname]);

	// react hooks form start
	const {
		handleSubmit,
		control,
		setValue,
		reset,
		trigger,
		formState: { errors, isValid },
		getValues,
		resetField,
		clearErrors,
	}: any = useForm({
		resolver: yupResolver(schema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			Type: 'AddressProof',
			Url: '',
			status: 'added',
			Data: {
				PermanentAddress: '',
				PermanentPinCode: '',
				PermanentState: '',
				PermanentCity: '',
				CurrentAddress: '',
				CurrentPinCode: '',
				CurrentCity: '',
				CurrentState: '',
				CurrentPermanentAddressSame: false,
				DSAAssociation: null,
				OfficeAddress: '',
				OfficePinCode: '',
				OfficeCity: '',
				OfficeState: '',
				AddressProof: '',
				CurrentBussines: '',
				Product: [],
				Income: '',
				finance: false,
				BusinessNature: '',
				Profession: '',
				BusinessDetail: '',
				ReferenceName1: '',
				ReferenceMobile1: '',
				ReferenceName2: '',
				ReferenceMobile2: '',
			},
		},
	});

	const onSubmit = async (data: any) => {
		const products = productData
			?.filter(({ checked }: any) => checked && checked)
			.map(({ value }: any) => value);
		const profession = userProfession
			?.filter(({ checked }: any) => checked && checked)
			.map(({ id }: any) => id);

		data.Data.Product = products;
		data.Data.Profession = parseInt(profession?.toString());
		data.Data.AddressProof = getValues('Data.AddressProof');
		data.Data.ReferenceName1 = data.Data.ReferenceName1
			? capFirstLetter(data?.Data?.ReferenceName1)
			: '';
		data.Data.ReferenceName2 = data.Data.ReferenceName2
			? capFirstLetter(data?.Data?.ReferenceName2)
			: '';
		const doc = {
			document: [
				{
					Type: 'AddressProof',
					Url: data.Url,
					Data: { ...data.Data },
					status: 'added',
				},
			],
		};
		let id;
		if (dmaSelector?.dmaFlow?.dmaId) {
			id = dmaSelector?.dmaFlow?.dmaId;
		} else {
			id = dmaSelector?.user_details?.id;
		}

		dispatch(
			kycDocumnetUploadProfessionalDetail({
				param: '/' + id,
				data: { ...doc?.document[0], continueLater: continueLater },
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status && !continueLater) {
					if (response?.status && location?.state?.from === 'update-details') {
						history.push('/update-details');
						return false;
					}
					if (response?.status) {
						setCurrentStep('step4');
					}
				} else {
					continueLater = false;
					if (dmaSelector?.dmaFlow?.dmaId) {
						history.replace('/dashboard/teams');
					} else {
						history.replace('/dashboard/home');
					}
				}
			});
	};

	const ShareModal = () => {
		const handleUploadChange = async (
			type: string,
			fieldName: string,
			e: any
		) => {
			setOpenUploadModal(false);
			// case1
			const formData = new FormData();

			// case 2
			let image: any;
			setDocTypeErrorMessage({
				addressProofError: '',
			});
			switch (type) {
				case 'file':
					if (
						e.target.files[0].type === 'image/png' ||
						e.target.files[0].type === 'image/jpeg' ||
						e.target.files[0].type === 'application/pdf'
					) {
						setDocTypeErrorMessage({
							...docTypeErrorMessage,
							addressProofError: '',
						});
						formData.append('profile', e.target.files[0]);
						dispatch(kycUserUploadImage({ data: formData }))
							.then(unwrapResult)
							.then((response: any) => {
								if (response?.status) {
									setValue(fieldName, response?.url);
									switch (fieldName) {
										case 'Url':
											trigger('Url');
											setDocTypeErrorMessage({
												...docTypeErrorMessage,
												addressProofError: '',
											});
											setImagePreviewUpload({
												...imagePreviewUpload,
												addressProofImage: true,
											});
											break;
									}
								}
							});
					} else {
						switch (fieldName) {
							case 'Url':
								setDocTypeErrorMessage({
									...docTypeErrorMessage,
									addressProofError:
										'Uploaded file is invalid. Only JPG/JPEG, PNG and PDF files are allowed.',
								});
								break;
						}
						break;
					}

					break;
				case 'photo':
					image = await Camera.getPhoto({
						quality: 75,
						allowEditing: false,
						resultType: CameraResultType.DataUrl,
						source: CameraSource.Photos,
					});
					formData.append(
						'profile',
						dataURLtoFile(
							image.dataUrl,
							`${Math.random() * 100000000000000000}.${image.format}`
						)
					);
					dispatch(kycUserUploadImage({ data: formData }))
						.then(unwrapResult)
						.then((response: any) => {
							if (response?.status) {
								setValue(fieldName, response?.url);
								switch (fieldName) {
									case 'Url':
										setImagePreviewUpload({
											...imagePreviewUpload,
											addressProofImage: true,
										});
										break;
								}
							}
						});
					break;
				case 'camera':
					image = await Camera.getPhoto({
						quality: 75,
						allowEditing: false,
						resultType: CameraResultType.DataUrl,
						source: CameraSource.Camera,
					});
					formData.append(
						'profile',
						dataURLtoFile(
							image.dataUrl,
							`${Math.random() * 100000000000000000}.${image.format}`
						)
					);
					dispatch(kycUserUploadImage({ data: formData }))
						.then(unwrapResult)
						.then((response: any) => {
							if (response?.status) {
								setValue(fieldName, response?.url);
								switch (fieldName) {
									case 'Url':
										setImagePreviewUpload({
											...imagePreviewUpload,
											addressProofImage: true,
										});
										break;
								}
							}
						});

					break;
				default:
					break;
			}
		};
		return (
			<>
				<IonList>
					{!isBrowser && (
						<IonItem lines='full'>
							<img
								src='/images/icons/kyc/Image-upload.svg'
								alt='Upload Photo'
							/>
							<IonLabel>
								<h2
									className='fs-16 ps-3 text-dark'
									onClick={(e) => {
										handleUploadChange('photo', clickImageFieldName, e);
									}}
								>
									Upload Photo
								</h2>
							</IonLabel>
						</IonItem>
					)}
					<IonItem
						lines='full'
						className='cursor-pointer'
					>
						<img
							src='/images/icons/kyc/upload-image-icon.svg'
							alt='Upload Photo'
						/>
						<IonLabel>
							<label
								htmlFor='upload-photo'
								className='cursor-pointer'
							>
								<h2 className='fs-16 ps-3 text-dark'>{t('Upload Document')}</h2>
							</label>
							<input
								type='file'
								id='upload-photo'
								accept='image/png, image/jpeg ,application/pdf'
								onChange={(e) => {
									handleUploadChange('file', clickImageFieldName, e);
								}}
							/>
						</IonLabel>
					</IonItem>
					{!isBrowser && (
						<IonItem lines='full'>
							<img
								src='/images/icons/kyc/camera-icon.svg'
								alt='Upload Photo'
							/>
							<IonLabel>
								<h2
									className='fs-16 ps-3 text-dark'
									onClick={(e) => {
										handleUploadChange('camera', clickImageFieldName, e);
									}}
								>
									Take Photo
								</h2>
							</IonLabel>
						</IonItem>
					)}
				</IonList>
			</>
		);
	};
	const fetchProfessionalDetailsKyc = async () => {
		let id;
		if (dmaSelector?.dmaFlow?.dmaId) {
			id = dmaSelector?.dmaFlow?.dmaId;
		} else {
			id = dmaSelector?.user_details?.id;
		}
		dispatch(kycBasicInfo({ param: id + `/step3` }))
			.then(unwrapResult)
			.then((response: any) => {
				setAadhaarUrl('');
				if (response?.status) {
					if (response?.data[0]) {
						setRegisteredAsType(response?.data[0]?.Data['RegisteredAs']);
					}
					if (response?.data[0]?.Data?.CurrentPermanentAddressSame) {
						setDisablePermanentAddress(true);
					}
					if (response?.data[0]?.Url) {
						setImagePreviewUpload({
							...imagePreviewUpload,
							addressProofImage: true,
						});

						dispatch(
							imgUrlUpdate({
								data: {
									url: response?.data[0]?.Url,
									expire_time: 50000,
								},
							})
						)
							.then(unwrapResult)
							.then((response: any) => {
								setValue('Url', response?.url);
							});
					}
					reset({ ...response?.data[0] });
					setAadhaarUrl(response?.data[0]?.aadhar_url);
					if (
						response?.data[0]?.is_aadhar &&
						response?.data[0]?.Data?.AddressProof === '1'
					) {
						// delete professionalDetailsKycScema.fields.Url;
						const someSchema = professionalDetailsKycScema.concat(
							yup.object().shape({
								Data: yup.object().shape({
									...professionalDetailsKycScema.fields.Data?.fields,
								}),
							})
						);
						setSchema(someSchema);
						setValue('Url', response?.data[0]?.aadhar_url);
						setCheckIsAadhaar(true);
						setImagePreviewUpload({
							...imagePreviewUpload,
							addressProofImage: true,
						});
					}
					if (response?.data[0]?.Data?.CurrentPinCode?.length === 6) {
						fetchStateCityByPincode(
							setValue,
							'Data.CurrentCity',
							'Data.CurrentState',
							setPinCodeData,
							dispatch,
							response?.data[0]?.Data?.CurrentPinCode,
							trigger,
							true,
							'CurrentPinCode'
						);
					}
					if (response?.data[0]?.Data?.PermanentPinCode?.length === 6) {
						fetchStateCityByPincode(
							setValue,
							'Data.PermanentCity',
							'Data.PermanentState',
							setPinCodeData,
							dispatch,
							response?.data[0]?.Data?.PermanentPinCode,
							trigger,
							true,
							'Prap'
						);
					}
					if (response?.data[0]?.Data?.OfficePinCode?.length === 6) {
						fetchStateCityByPincode(
							setValue,
							'Data.OfficeCity',
							'Data.OfficeState',
							setPinCodeData,
							dispatch,
							response?.data[0]?.Data?.OfficePinCode,
							trigger,
							true,
							'OfficePinCode'
						);
					}

					if (response?.data[0]?.Data?.CurrentBussines === 'Finance') {
						setIsFinance(true);
						setValue('Data.finance', true);
						setValue('Data.CurrentBussines', 'Finance');
						if (response?.data[0]?.Data?.DSAAssociation) {
							parseInt(response?.data[0]?.Data?.DSAAssociation) === 1
								? setIsAssociated(true)
								: setIsAssociated(false);
							parseInt(response?.data[0]?.Data?.DSAAssociation) === 1
								? setValue('Data.DSAAssociation', true)
								: setValue('Data.DSAAssociation', false);
						}
					} else {
						setIsFinance(null);
						setIsAssociated(null);
						setValue('Data.DSAAssociation', '');
					}
					if (response?.data[0].Data?.CurrentBussines === 'Non-Finance') {
						setIsFinance(false);
						setValue('Data.finance', false);
						setValue('Data.CurrentBussines', 'Non-Finance');
					}

					if (response?.data[0].Data?.CurrentBussines === 'Finance') {
						const someSchema = professionalDetailsKycScema.concat(
							yup.object().shape({
								Data: yup.object().shape({
									...professionalDetailsKycScema.fields.Data?.fields,
									DSAAssociation: yup
										.boolean()
										.required('Please check one of this options'),
								}),
							})
						);
						setSchema(someSchema);
					} else if (
						response?.data[0].Data?.CurrentBussines === 'Non-Finance'
					) {
						const someSchema = professionalDetailsKycScema.concat(
							yup.object().shape({
								Data: yup.object().shape({
									...professionalDetailsKycScema.fields.Data?.fields,
									BusinessNature: yup
										.string()
										.trim()
										.required('Nature of Business is required')
										.matches(REGEX.FULLNAME, 'Please Enter Valid Format'),
									Profession: yup
										.string()
										.trim()
										.required('Profession is required'),
								}),
							})
						);
						setSchema(someSchema);
					}

					if (response?.data[0].Data?.DSAAssociation === '1') {
						const someSchema = professionalDetailsKycScema.concat(
							yup.object().shape({
								Data: yup.object().shape({
									...professionalDetailsKycScema.fields.Data?.fields,
									Product: yup.array().min(1, 'Select at least 1 product'),
									Income: yup
										.number()
										.min(1, 'Amount must be greater than or equal to 1!')
										.typeError('Amount should be number')
										.required(),
								}),
							})
						);
						setSchema(someSchema);
					} else {
						const someSchema = professionalDetailsKycScema.concat(
							yup.object().shape({
								Data: yup.object().shape({
									...professionalDetailsKycScema.fields.Data?.fields,
									BusinessNature: yup
										.string()
										.trim()
										.required('Nature of Business is required')
										.matches(REGEX.FULLNAME, 'Please Enter Valid Format'),
								}),
							})
						);
						setSchema(someSchema);
					}
				}
			});
	};

	const fetchLeadProduct = async () => {
		dispatch(kycLeadProduct())
			.then(unwrapResult)
			.then((response: any) => {
				setTimeout(() => {
					if (getValues('Data.Product') != '') {
						const arrData: any = [];
						response?.data.forEach((val: any) => {
							if (getValues('Data.Product')?.includes(val.value)) {
								arrData.push({ ...val, checked: true });
							} else {
								arrData.push({ ...val });
							}
						});
						setProductData(arrData);
					} else {
						setProductData(response?.data);
					}
				}, 100);
			});
	};

	const fetchUserProfession = async () => {
		dispatch(kycUserProfession())
			.then(unwrapResult)
			.then((response: any) => {
				setTimeout(() => {
					if (getValues('Data.Profession') != '') {
						const arrData: any = [];
						response?.data.forEach((val: any) => {
							if (getValues('Data.Profession') === val?.id?.toString()) {
								arrData.push({ ...val, checked: true });
							} else {
								arrData.push({ ...val });
							}
						});
						setUserProfession(arrData);
					} else {
						setUserProfession(response?.data);
					}
				}, 100);
			});
	};

	const fetchUserAddrssProof = async () => {
		dispatch(kycAddressProof())
			.then(unwrapResult)
			.then((response: any) => {
				setAddressProof(response?.data);
			});
	};
	return (
		<section className='conatiner-spacing-md overflowX-hidden kyc-content-height'>
			<h4>{t('Address Details')}</h4>
			<p className='mt-2 mb-4'>
				{t('Registered As')}
				<b className='text-capitalize'> &nbsp;{t(RegisteredAsType)}</b>
			</p>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className=''>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, name } }) => (
							<CustomInput
								type='text'
								placeholder={t('Current Residential Address') + ' *'}
								onBlur={onBlur}
								name={name}
								onChange={onChange}
								value={getValues(name)}
								errors={t(errors?.Data?.CurrentAddress?.message)}
							/>
						)}
						name='Data.CurrentAddress'
					/>
				</div>
				<div className=''>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, name } }) => (
							<CustomInput
								type='number'
								placeholder={t('Pin Code') + ' *'}
								name={name}
								onBlur={onBlur}
								onChange={(e: any) => {
									if (e.detail.value.length === 6) {
										fetchStateCityByPincode(
											setValue,
											'Data.CurrentCity',
											'Data.CurrentState',
											setPinCodeData,
											dispatch,
											e.detail.value,
											trigger,
											true,
											'CurrentPinCode'
										);
									} else {
										setValue('Data.CurrentCity', '');
										setValue('Data.CurrentState', '');
										setPinCodeData({
											...pinCodeData,
											CurrentPinCode: [],
										});
									}
									onChange(e);
								}}
								value={getValues('Data.CurrentPinCode')}
								errors={t(errors?.Data?.CurrentPinCode?.message)}
								onBeforeInput={(evt: any) =>
									!/[0-9]/.test(evt.data) && evt.preventDefault()
								}
								onKeyDown={(e: any) => {
									disableNumberArrows(e);
								}}
								onWheel={(e: any) => e.target.blur()}
							/>
						)}
						name='Data.CurrentPinCode'
					/>
				</div>
				<div className=''>
					<StateCity
						cityInputName='Data.CurrentCity'
						stateInputName='Data.CurrentState'
						control={control}
						setValue={setValue}
						pinCodeData={pinCodeData['CurrentPinCode']}
						trigger={trigger}
						getValues={getValues}
					/>
					{getValues('Data.CurrentPinCode')?.length === 6 &&
					getValues('Data.CurrentCity') === '' ? (
						<div className='city-state-error error-text sc-ion-input-md px-3'>
							{t('City or State is not selected')}
						</div>
					) : (
						''
					)}
				</div>
				<div className=''>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, value } }) => (
							<CustomCheckbox
								name='Data.CurrentPermanentAddressSame'
								onChange={({ detail: { checked } }: any) => {
									onChange(checked);

									if (checked) {
										fetchStateCityByPincode(
											setValue,
											'Data.PermanentCity',
											'Data.PermanentState',
											setPinCodeData,
											dispatch,
											getValues('Data.CurrentPinCode'),
											trigger,
											true,
											'Prap'
										);
										setValue(
											'Data.PermanentAddress',
											getValues('Data.CurrentAddress')
										);
										setValue(
											'Data.PermanentPinCode',
											getValues('Data.CurrentPinCode')
										);
										setDisablePermanentAddress(true);
										trigger('Data.PermanentAddress');
									} else {
										setValue('Data.PermanentAddress', '');
										setValue('Data.PermanentPinCode', '');
										setValue('Data.PermanentCity', '');
										setValue('Data.PermanentState', '');
										setDisablePermanentAddress(false);
										setPinCodeData({ ...pinCodeData, Prap: [] });
									}
								}}
								value={value}
								labelPlacement='end'
								className='end-of-page'
								onBlur={onBlur}
								isChecked={value}
								children={
									<span className='text-wrap'>
										{t('Permanent Address is same as Current Address') + '.'}
									</span>
								}
							></CustomCheckbox>
						)}
						name='Data.CurrentPermanentAddressSame'
					/>
				</div>
				<div className='mt-2'>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, name } }) => (
							<CustomInput
								type='text'
								placeholder={t('Permanent Residential Address') + ' *'}
								onBlur={onBlur}
								onChange={onChange}
								name={name}
								value={getValues(name)}
								errors={t(errors?.Data?.PermanentAddress?.message)}
								disabled={disablePermanentAddress}
							/>
						)}
						name='Data.PermanentAddress'
					/>
				</div>
				<div className=''>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, name } }) => (
							<CustomInput
								type='number'
								placeholder={t('Pin Code') + ' *'}
								name={name}
								onBlur={onBlur}
								onChange={(e: any) => {
									if (e.detail.value.length === 6) {
										fetchStateCityByPincode(
											setValue,
											'Data.PermanentCity',
											'Data.PermanentState',
											setPinCodeData,
											dispatch,
											e.detail.value,
											trigger,
											true,
											'Prap'
										);
									} else {
										setValue('Data.PermanentCity', '');
										setValue('Data.PermanentState', '');
										setPinCodeData({ ...pinCodeData, Prap: [] });
									}
									onChange(e);
								}}
								value={getValues('Data.PermanentPinCode')}
								errors={t(errors?.Data?.PinCode?.message)}
								onBeforeInput={(evt: any) =>
									!/[0-9]/.test(evt.data) && evt.preventDefault()
								}
								onKeyDown={(e: any) => {
									disableNumberArrows(e);
								}}
								onWheel={(e: any) => e.target.blur()}
								disabled={disablePermanentAddress}
							/>
						)}
						name='Data.PermanentPinCode'
					/>
				</div>
				<div className=''>
					<StateCity
						cityInputName='Data.PermanentCity'
						stateInputName='Data.PermanentState'
						control={control}
						setValue={setValue}
						pinCodeData={pinCodeData['Prap']}
						trigger={trigger}
						getValues={getValues}
					/>
					{getValues('Data.PermanentPinCode')?.length === 6 &&
					getValues('Data.PermanentCity') === '' ? (
						<div className='city-state-error error-text sc-ion-input-md px-3'>
							{t('City or State is not selected')}
						</div>
					) : (
						''
					)}
				</div>
				<div className=''>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, name } }) => (
							<CustomInput
								type='text'
								placeholder={t('Office Address') + ' *'}
								onBlur={onBlur}
								onChange={onChange}
								name={name}
								value={getValues(name)}
								errors={t(errors?.Data?.OfficeAddress?.message)}
							/>
						)}
						name='Data.OfficeAddress'
					/>
				</div>
				<div className=''>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, name } }) => (
							<CustomInput
								type='number'
								placeholder={t('Pin Code') + ' *'}
								name={name}
								onBlur={onBlur}
								onChange={(e: any) => {
									if (e.detail.value.length === 6) {
										fetchStateCityByPincode(
											setValue,
											'Data.OfficeCity',
											'Data.OfficeState',
											setPinCodeData,
											dispatch,
											e.detail.value,
											trigger,
											true,
											'OfficePinCode'
										);
									} else {
										setValue('Data.OfficeCity', '');
										setValue('Data.OfficeState', '');
										setPinCodeData({ ...pinCodeData, OfficePinCode: [] });
									}
									onChange(e);
								}}
								value={getValues('Data.OfficePinCode')}
								errors={t(errors?.Data?.OfficePinCode?.message)}
								onBeforeInput={(evt: any) =>
									!/[0-9]/.test(evt.data) && evt.preventDefault()
								}
								onKeyDown={(e: any) => {
									disableNumberArrows(e);
								}}
								onWheel={(e: any) => e.target.blur()}
							/>
						)}
						name='Data.OfficePinCode'
					/>
				</div>
				<div className=''>
					<StateCity
						cityInputName='Data.OfficeCity'
						stateInputName='Data.OfficeState'
						control={control}
						setValue={setValue}
						pinCodeData={pinCodeData['OfficePinCode']}
						trigger={trigger}
						getValues={getValues}
					/>
					{getValues('Data.OfficePinCode')?.length === 6 &&
					getValues('Data.OfficeCity') === '' ? (
						<div className='city-state-error error-text sc-ion-input-md px-3'>
							{t('City or State is not selected')}
						</div>
					) : (
						''
					)}
				</div>
				{addressProof && addressProof.length > 0 && (
					<div>
						<IonLabel className='fw-500'>{t('Address Proof') + ' *'}</IonLabel>
						{/* <CustomDropDown
						{...{
							isCheckbox: false,
							title: 'Select Type of Proof',
							setData: setAddressProof,
							data: addressProof,
							placeholder: 'Search',
							isSearch: false,
							modalTitle: 'Select Type of Proof',
						}}
					/> */}
						{/* <CustomSelect
						data={addressProof}
						type='manual'
						name={'select'}
						label={'select'}
						onIonChange={(e: any) => {
							setValue(name, e.target.value);
						}}
						// manualRegister={register(name)}
						// error={errorText[name]?.message}
						className='mt-1 w-100'
					/> */}
						<Controller
							control={control}
							render={({ field: { onChange, onBlur, name } }) => (
								<DropDownModal
									// disabled={getValues('is_aadhar')}
									data={addressProof}
									name={name}
									onChange={(e: any) => {
										if (e?.detail?.value === '1' && getValues('is_aadhar')) {
											// delete professionalDetailsKycScema.fields.Url;
											const someSchema = professionalDetailsKycScema.concat(
												yup.object().shape({
													Data: yup.object().shape({
														...professionalDetailsKycScema.fields.Data?.fields,
													}),
												})
											);
											setSchema(someSchema);
											setCheckIsAadhaar(true);
											setImagePreviewUpload({
												...imagePreviewUpload,
												addressProofImage: true,
											});
											setValue('Url', aadhaarUrl);
											setTimeout(() => {
												trigger('Data.AddressProof');
												trigger('Url');
											}, 900);
										} else {
											setCheckIsAadhaar(false);
											const someSchema = professionalDetailsKycScema.concat(
												yup.object().shape({
													Url: yup
														.string()
														.required('Address Proof Image is Required.'),
													Data: yup.object().shape({
														...professionalDetailsKycScema.fields.Data?.fields,
													}),
												})
											);
											setSchema(someSchema);
											setImagePreviewUpload({
												...imagePreviewUpload,
												addressProofImage: false,
											});
											setValue('Url', '');
											setTimeout(() => trigger('Url'), 900);
										}

										onChange(e);
									}}
									value={getValues(name)}
									onBlur={onBlur}
									errors={errors[name]?.message}
								/>
							)}
							name={'Data.AddressProof'}
						/>
						<div className='my-3'>
							<UploadImageCard
								{...{
									setImagePreviewUpload,
									imagePreviewUpload,
									isImageAvilable: imagePreviewUpload?.addressProofImage,
									imgKey: 'addressProofImage',
									setClickImageFieldName,
									setOpenUploadModal,
									setValue,
									value: 'Url',
									title: t('Upload Proof'),
									src: getValues('Url'),
									disabled: checkIsAadhaar,
								}}
							/>
							{!imagePreviewUpload.addressProofImage &&
							!docTypeErrorMessage.addressProofError &&
							errors?.Url?.message ? (
								<div className=''>
									<div className='error-text sc-ion-input-md fs-12'>
										{t(errors?.Url?.message)}
									</div>
								</div>
							) : (
								docTypeErrorMessage.addressProofError && (
									<div className=''>
										<div className='error-text sc-ion-input-md fs-12 pb-2'>
											{docTypeErrorMessage.addressProofError}
										</div>
									</div>
								)
							)}
							{checkIsAadhaar && getValues('Data.AddressProof') === '1' && (
								<div className='sc-ion-input-md fs-12 pb-2 color-green'>
									The Aadhaar previously uploaded is now automatically selected
								</div>
							)}
						</div>
					</div>
				)}

				<h4 className='mb-3'>{t('Business Details')}</h4>

				<div className='mb-4'>
					<IonRadioGroup
						value={getValues('Data.CurrentBussines')}
						onIonChange={(e: any) => {
							if (e.detail.value === 'Finance') {
								delete professionalDetailsKycScema.fields.Data?.fields
									.DSAAssociation;
								delete professionalDetailsKycScema.fields.Data?.fields
									.BusinessNature;
								delete professionalDetailsKycScema.fields.Data?.fields
									.Profession;
								if (!errors) {
									trigger();
								}
								const someSchema = professionalDetailsKycScema.concat(
									yup.object().shape({
										Data: yup.object().shape({
											...professionalDetailsKycScema.fields.Data?.fields,
											DSAAssociation: yup
												.boolean()
												.required('Please check one of this options'),
										}),
									})
								);
								setSchema(someSchema);
								setIsFinance(true);
								setIsAssociated('');
								setValue('Data.CurrentBussines', 'Finance');
								setValue('Data.finance', true);
								setValue('Data.Income', '');
								const profession: any = userProfession.map((item: any) => {
									return {
										...item,
										checked: false,
									};
								});
								setUserProfession(profession);
								setValue('Data.Profession', '');
								setValue('Data.BusinessNature', '');
							} else if (e.detail.value === 'Non-Finance') {
								delete professionalDetailsKycScema.fields.Data?.fields
									.DSAAssociation;
								delete professionalDetailsKycScema.fields.Data?.fields.Income;
								delete professionalDetailsKycScema.fields.Data?.fields
									.BusinessNature;
								const someSchema = professionalDetailsKycScema.concat(
									yup.object().shape({
										Data: yup.object().shape({
											...professionalDetailsKycScema.fields.Data?.fields,
											BusinessNature: yup
												.string()
												.trim()
												.required('Business Nature is required')
												.matches(REGEX.FULLNAME, 'Please Enter Valid Format'),
											Profession: yup
												.string()
												.trim()
												.required('Profession is required'),
										}),
									})
								);
								setSchema(someSchema);
								setValue('Data.CurrentBussines', 'Non-Finance');
								setIsFinance(false);
								setValue('Data.finance', false);
								setIsAssociated('');
								const products: any = productData.map((item: any) => {
									return {
										...item,
										checked: false,
									};
								});
								setProductData(products);
								setValue('Data.Product', []);
								setIsAssociated(null);
								setValue('Data.DSAAssociation', '');
								setValue('Data.Profession', '');
								setValue('Data.BusinessNature', '');
								if (!errors) {
									trigger();
								}
							}
						}}
						className='mt-3'
						name='Data.CurrentBussines'
					>
						<IonRadio
							labelPlacement='end'
							aria-label='Finance'
							value='Finance'
							className='me-3'
							id={'finance'}
						>
							{t('Finance')}
						</IonRadio>
						<IonRadio
							labelPlacement='end'
							aria-label='Non-Finance'
							value='Non-Finance'
							id={'nonfinance'}
						>
							{t('Non-Finance')}
						</IonRadio>
					</IonRadioGroup>
					{errors?.Data?.finance && (
						<p className='error'>{t(errors?.Data?.finance?.message)}</p>
					)}
				</div>
				{isFinance && (
					<>
						<Controller
							control={control}
							render={() => (
								<>
									<div className='mb-3'>
										<p className='mb-2'>
											{t('Are you associated with other Bank/DSA?')}
										</p>
										<IonRadioGroup
											className='mt-3'
											value={
												parseInt(getValues('Data.DSAAssociation')) === 1 ||
												getValues('Data.DSAAssociation') === true
													? true
													: parseInt(getValues('Data.DSAAssociation')) === 0 ||
													  getValues('Data.DSAAssociation') === false
													? false
													: null
											}
											onIonChange={(e: any) => {
												setValue('Data.DSAAssociation', e.detail.value);
												setIsAssociated(e.detail.value);
												setValue('Data.Income', '');

												if (e.detail.value) {
													setValue('Data.BusinessNature', '');
													delete professionalDetailsKycScema.fields.Data?.fields
														.BusinessNature;
													const someSchema = professionalDetailsKycScema.concat(
														yup.object().shape({
															Data: yup.object().shape({
																...professionalDetailsKycScema.fields.Data
																	?.fields,
																Income: yup
																	.number()
																	.min(
																		1,
																		'Amount must be greater than or equal to 1!'
																	)
																	.typeError('Amount should be number')
																	.required(),
																Product: yup
																	.array()
																	.min(1, 'Select at least 1 product'),
															}),
														})
													);
													setSchema(someSchema);
													trigger('Data.DSAAssociation');
													trigger('Data.Income');
												} else {
													const products: any = productData.map((item: any) => {
														return {
															...item,
															checked: false,
														};
													});
													setProductData(products);
													setValue('Data.Product', []);

													delete professionalDetailsKycScema.fields.Data?.fields
														.Income;
													delete professionalDetailsKycScema.fields.Data?.fields
														.Product;
													const someSchema = professionalDetailsKycScema.concat(
														yup.object().shape({
															Data: yup.object().shape({
																...professionalDetailsKycScema.fields.Data
																	?.fields,
																BusinessNature: yup
																	.string()
																	.trim()
																	.required('Business Nature is required')
																	.matches(
																		REGEX.FULLNAME,
																		'Please Enter Valid Format'
																	),
															}),
														})
													);
													setSchema(someSchema);
													trigger('Data.BusinessNature');
												}
											}}
										>
											<IonRadio
												value={true}
												className='me-3'
											>
												{t('Yes')}
											</IonRadio>
											<IonRadio value={false}>{t('No')}</IonRadio>
										</IonRadioGroup>
										{errors && errors?.Data?.DSAAssociation && (
											<div className='pt-1'>
												<div className='error-text sc-ion-input-md'>
													{t(errors?.Data?.DSAAssociation?.message)}
												</div>
											</div>
										)}
									</div>
								</>
							)}
							name='Data.DSAAssociation'
						/>

						{isAssociated && (
							<>
								<div className='mb-3'>
									<p className='m-0 mb-2'>{t('a. Products you deal with *')}</p>
									<CustomDropDown
										{...{
											isCheckbox: true,
											title: t('Product'),
											setData: (data: any) => {
												setProductData(data);
												setValue(
													'Data.Product',
													data
														?.filter(({ checked }: any) => checked && checked)
														.map(({ id }: any) => id)
												);
												trigger('Data.Product');
											},
											data: productData,
											placeholder: t('Search'),
											isSearch: false,
											modalTitle: t('Select Products'),
										}}
									/>
								</div>
								<div className='mb-3'>
									<p className='m-0 mb-2'>
										{t('b. Monthly Business Volume')} *
									</p>
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, name } }) => (
											<>
												<CustomInput
													type='text'
													placeholder={t('Enter Amount')}
													onBlur={onBlur}
													onChange={(e: any) => {
														e = isNaN(e.detail.value.replaceAll(',', ''))
															? e
															: Number(e.detail.value.replace(/,/g, ''));
														onChange(e);
													}}
													name={name}
													value={
														name == 'Data.Income'
															? getValues(name)
																? InputFormatCurrencyValue(
																		isNaN(getValues(name))
																			? getValues(name)
																			: Number(getValues(name))
																  )
																: getValues(name)
															: getValues(name)
													}
													errors={t(errors?.Data?.Income?.message)}
													onBeforeInput={(evt: any) => {
														restrictAmountChar(
															evt,
															getValues('Data.Income')?.toString()
														);
													}}
												/>
											</>
										)}
										name='Data.Income'
									/>
								</div>
							</>
						)}
					</>
				)}
				{isFinance === false && (
					<>
						<IonLabel className=''>{t('Profession') + ' *'}</IonLabel>
						<Controller
							control={control}
							render={() => (
								<>
									<CustomDropDown
										{...{
											isCheckbox: false,
											title: t('Select Profession'),
											setData: (data: any) => {
												setUserProfession(data);
												setValue(
													'Data.Profession',
													data
														?.filter(({ checked }: any) => checked && checked)
														.map(({ id }: any) => id)
														.toString()
												);
												trigger('Data.Profession');
											},
											data: userProfession,
											placeholder: t('Search'),
											isSearch: false,
											modalTitle: t('Select Profession'),
										}}
									/>
								</>
							)}
							name='Data.Profession'
						/>

						{errors && errors?.Data?.Profession && (
							<div className='pt-1 ps-3'>
								<div className='error-text sc-ion-input-md'>
									{t(errors?.Data?.Profession?.message)}
								</div>
							</div>
						)}
					</>
				)}
				{isAssociated === false && isFinance && (
					<div className='mt-2'>
						<Controller
							control={control}
							render={({ field: { onChange, onBlur, name } }) => (
								<CustomInput
									type='text'
									name={name}
									placeholder={t('Nature of Business') + ' *'}
									onBlur={onBlur}
									onChange={onChange}
									value={getValues(name)}
									errors={t(errors?.Data?.BusinessNature?.message)}
								/>
							)}
							name='Data.BusinessNature'
						/>
					</div>
				)}
				{isFinance === false && (
					<div className='mt-2'>
						<Controller
							control={control}
							render={({ field: { onChange, onBlur, name } }) => (
								<CustomInput
									type='text'
									name={name}
									placeholder={t('Nature of Business') + ' *'}
									onBlur={onBlur}
									onChange={onChange}
									value={getValues(name)}
									errors={t(errors?.Data?.BusinessNature?.message)}
								/>
							)}
							name='Data.BusinessNature'
						/>
					</div>
				)}
				<Divider className='my-3' />
				<h4>{t('Reference Details')}</h4>
				<div className=''>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, name } }) => (
							<CustomInput
								type='text'
								placeholder={t('Reference 1 Name') + ' *'}
								onBlur={onBlur}
								name={name}
								onChange={onChange}
								value={getValues(name)}
								errors={t(errors?.Data?.ReferenceName1?.message)}
								className='text-capitalize'
							/>
						)}
						name='Data.ReferenceName1'
					/>
				</div>
				<div className=''>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, name } }) => (
							<CustomInput
								type='number'
								placeholder={t('Reference 1 Mobile Number') + ' *'}
								onBlur={onBlur}
								name={name}
								value={getValues(name)}
								onChange={onChange}
								errors={t(errors?.Data?.ReferenceMobile1?.message)}
								onBeforeInput={(evt: any) => {
									//Only numbers accepted on user input
									!/[0-9]/.test(evt.data) && evt.preventDefault();
									//Only accepts 10 characters on user input
									if (evt.target.value.length === 10) {
										evt.preventDefault();
									}
								}}
								onKeyDown={(e: any) => {
									disableNumberArrows(e);
								}}
								onWheel={(e: any) => e.target.blur()}
							/>
						)}
						name='Data.ReferenceMobile1'
					/>
				</div>
				<div className=''>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, name } }) => (
							<CustomInput
								type='text'
								name={name}
								placeholder={t('Reference 2 Name')}
								onBlur={onBlur}
								onChange={onChange}
								value={getValues(name)}
								errors={t(errors?.Data?.ReferenceName2?.message)}
								className='text-capitalize'
							/>
						)}
						name='Data.ReferenceName2'
					/>
				</div>
				<div className='mb-md-5'>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, name } }) => (
							<CustomInput
								type='number'
								name={name}
								placeholder={t('Reference 2 Mobile Number')}
								onBlur={onBlur}
								onChange={onChange}
								value={getValues(name)}
								errors={t(errors?.Data?.ReferenceMobile2?.message)}
								onBeforeInput={(evt: any) => {
									//Only numbers accepted on user input
									!/[0-9]/.test(evt.data) && evt.preventDefault();
									//Only accepts 10 characters on user input
									if (evt.target.value.length === 10) {
										evt.preventDefault();
									}
								}}
								onKeyDown={(e: any) => {
									disableNumberArrows(e);
								}}
								onWheel={(e: any) => e.target.blur()}
							/>
						)}
						name='Data.ReferenceMobile2'
					/>
				</div>
				<div
					className='position-fixed-md w-100 bottom-0 start-0 px-3 px-md-0 pb-3 pt-md-3 zindex'
					style={{ backgroundColor: '#fff', zIndex: 9 }}
				>
					<CustomButton
						className='w-100 fw-bold dashboard-add-lead-button mb-3'
						expand='block'
						fill='outline'
						title={t('Continue Later')}
						onClick={() => {
							if (getValues('Data.Product').length === 0) {
								setTimeout(() => {
									clearErrors('Data.Product');
								}, 200);
							}
							if (getValues('Data.Profession') === '') {
								setTimeout(() => {
									clearErrors('Data.Profession');
								}, 200);
							}
							if (getValues('Data.Income') === '') {
								setTimeout(() => {
									resetField('Data.Income');
								}, 200);
							}
							setTimeout(() => {
								if (checkFieldsValidToContinue(errors)) {
									continueLater = true;
									onSubmit(getValues());
								} else {
									showToastAlert({
										type: 'error',
										message: 'Please fix above errors',
									});
								}
							}, 200);
						}}
					/>
					<CustomButton
						type='submit'
						className='btn-blue'
						expand='block'
						title={
							location?.state?.from === 'update-details'
								? t('Update')
								: t('Continue')
						}
						disabled={!isValid}
					/>
				</div>
			</form>
			{/* Upload Photo Modal */}
			<CustomModal
				id='sharemodal'
				isOpen={openUploadModal}
				initialBreakpoint={isWeb ? 1 : 0.3}
				handleClose={() => setOpenUploadModal(false)}
				breakpoints={[0, 0.3, 0.5, 0.75]}
				modalContent={<ShareModal />}
				className={`side-modal ${isWeb ? '' : 'modal-border-radius'}`}
				needCross={false}
				needArrow={false}
			/>
		</section>
	);
}
