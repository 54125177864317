import { IonContent, IonSpinner } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	lenderBankConnect,
	lenderGstConnect,
	lenderOfferGenerate,
	lenderSpfDetailsFlow,
	saveLenderSpcfInfo,
	lenderGetOffer,
} from '../../../store/reducers/lead/leadSlice';
import { LenderNameForAPICall } from '../../../helpers/lenders';
import { OfferRejectedMsg } from '../OfferRejectedMsg/OfferRejectedMsg';
import { showInAppBrowserView } from '../../../helpers/functions';
import './ABFLForm.scss';

export const ABFLCheckOffer: React.FC<any> = () => {
	const [offerRes, setOffers] = useState<any>();
	const [isShowGST, setShowGST] = useState(false);
	const [showOfferErrMsg, setShowOfferErrMsg] = useState('');
	const dispatch = useDispatch();
	const { selectedLender, lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);
	const [spinner, setSpinner] = useState(false);
	const [bcStatus, setBCStatus] = useState('');

	useEffect(() => {
		if (!location.pathname.includes('/lead-lender-specific-form-steps')) {
			return;
		} else {
			const params = new URLSearchParams(location.search);
			const queryParams: any = params.get('status');
			setBCStatus(queryParams);

			if (
				queryParams === 'BC_COMPLETED' ||
				queryParams === 'GST_COMPLETED' ||
				(!lenderSpecificDetails?.lender_spf_details?.referenceID &&
					!queryParams)
			) {
				triggerBREANDgenerateOffer(queryParams);
			} else {
				getOffer(queryParams);
			}
		}
	}, [location.pathname]);

	/**
	 * Get offer based on customer ID
	 */
	const triggerBREANDgenerateOffer = async (queryParams: any) => {
		const req = {
			lead_spf_detail_id: lenderSpecificDetails.lead_spf_detail_id,
			customerID: lenderSpecificDetails.lender_lead_id,
			lender_name: LenderNameForAPICall.ABFL,
			riskVariables: {},
		};
		setShowOfferErrMsg('');
		setSpinner(true);
		await dispatch(lenderOfferGenerate({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				setOfferRes(result, queryParams);
			});
		setSpinner(false);
	};

	const getOffer = async (queryParams: any) => {
		const req = {
			lead_spf_detail_id: lenderSpecificDetails.lead_spf_detail_id,
			lender_name: LenderNameForAPICall.ABFL,
		};
		setShowOfferErrMsg('');
		await dispatch(lenderGetOffer({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				setOfferRes(result, queryParams);
			});
		setSpinner(false);
	};

	const setOfferRes = (result: any, queryParams: any) => {
		if (result?.status) {
			const { data } = result.data;
			setOffers(data);
			dispatch(
				lenderSpfDetailsFlow({
					...lenderSpecificDetails,
					lender_spf_details: {
						...lenderSpecificDetails?.lender_spf_details,
						referenceID: data?.referenceID,
					},
				})
			);

			if (data?.dataRequired?.gst === 'mandatory') {
				setShowGST(true);
				return;
			}

			if (
				data?.dataRequired?.gst &&
				data?.dataRequired?.gst !== 'mandatory' &&
				data?.dataRequired?.banking !== 'mandatory'
			) {
				setShowGST(true);
				return;
			}

			if (
				data?.dataRequired?.banking !== 'mandatory' &&
				data?.dataRequired?.gst !== 'mandatory' &&
				(queryParams === 'BC_COMPLETED' || queryParams === 'GST_COMPLETED')
			) {
				onSubmit(data);
				return;
			}
		} else {
			setShowOfferErrMsg(result?.message);
		}
	};

	/**
	 * Get the url for upload documents and get the best offer
	 * @param flag bank / gst
	 */
	const connectWithBankOrGST = async (flag: string) => {
		const req: any = {
			lead_spf_detail_id: lenderSpecificDetails.lead_spf_detail_id,
			customerID: lenderSpecificDetails.lender_lead_id,
			redirectURL: `${location.origin}/lead-lender-specific-form-steps`,
			program: 'BUSINESS_LOAN',
			lender_name: LenderNameForAPICall.ABFL,
		};
		if (flag === 'bank') {
			await dispatch(lenderBankConnect({ data: req }))
				.then(unwrapResult)
				.then((result: any) => {
					if (result?.status) {
						const { data } = result.data;
						showInAppBrowserView(data?.url);
						window.close();
					} else {
						setShowOfferErrMsg(result?.message);
					}
				});
		} else {
			await dispatch(lenderGstConnect({ data: req }))
				.then(unwrapResult)
				.then((result: any) => {
					if (result?.status) {
						const { data } = result.data;
						showInAppBrowserView(data?.url);
						window.close();
					} else {
						setShowOfferErrMsg(result?.message);
					}
				});
		}
	};

	/**
	 * On submit to save accepted offer details
	 * @param data form value
	 */
	const onSubmit = async (offerData?: any) => {
		const req: any = {
			lead_spf_detail_id: lenderSpecificDetails.lead_spf_detail_id,
			lead_id: leadFlowData?.leadId,
			lender_id: selectedLender[0]?.lender_id,
			lead_application_id: selectedLender[0]?.id,
			lender_name: LenderNameForAPICall.ABFL,
			lender_spf_details: {
				...lenderSpecificDetails?.lender_spf_details,
			},
			step: 'step3',
		};
		if (bcStatus) {
			req.lender_spf_details.bc_status = bcStatus;
		}
		await dispatch(saveLenderSpcfInfo({ data: req }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					dispatch(
						lenderSpfDetailsFlow({
							...lenderSpecificDetails,
							step: 'step3',
							offers: offerData,
						})
					);
				}
			});
	};

	return (
		<>
			<IonContent>
				{showOfferErrMsg ? (
					<>
						<OfferRejectedMsg message={showOfferErrMsg} />
					</>
				) : (
					<div className='select-product small-container bg-web'>
						<div className='h-100 bg-web-white container-width-md px-3 p-md-5 border-radius-10 text-center'>
							<img
								src='/images/checklist.png'
								height={120}
							/>

							<h4 className='mt-4'>
								<b>One last step before we give you an offer</b>
							</h4>

							<div>
								<small className='small_text'>
									Connect your GSTIN or your bank statement to get the highest
									possible loan amount
								</small>

								<div className='bank_statement'>
									<div
										className='statement_sec'
										onClick={() => connectWithBankOrGST('bank')}
									>
										<div className='statement_icon'>
											<img
												src='/images/statement.png'
												height={35}
											/>
										</div>
										<div className='statement_text'>
											<span>Bank Statement</span>
										</div>
									</div>
								</div>

								{isShowGST && (
									<>
										<div className='or_option'>
											<span>OR</span>
										</div>

										<div className='bank_statement'>
											<div
												className='statement_sec'
												onClick={() => connectWithBankOrGST('gst')}
											>
												<div className='statement_icon'>
													<img
														src='/images/tax.png'
														height={35}
													/>
												</div>
												<div className='statement_text'>
													<span>GSTIN</span>
												</div>
											</div>
										</div>
									</>
								)}

								{offerRes?.dataRequired?.gst !== 'mandatory' &&
									offerRes?.dataRequired?.banking !== 'mandatory' && (
										<div className='skip_now'>
											<span onClick={() => onSubmit(offerRes)}>
												Skip for now
											</span>
										</div>
									)}
							</div>
						</div>
					</div>
				)}

				{spinner && (
					<div
						className='position-fixed  w-100  d-flex justify-content-center align-items-center h-100'
						style={{
							top: '0px',
							left: '50%',
							transform: 'translate(-50%,0%)',
							backgroundColor: 'rgba(0,0,0,0.2)',
						}}
					>
						<IonSpinner name='lines-sharp' />
					</div>
				)}
			</IonContent>
		</>
	);
};
