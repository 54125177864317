import React, { createContext, useState, useEffect, ReactNode } from 'react';

interface AuthContextType {
  token: string | null;
  userId: string | null;
  mobile: string | null;
  roleType: string[];
  isIFA: boolean;
  isDMACoord: boolean;
  isCOPS: boolean;
  isCOPSExecutive: boolean;
  login: (token: string, userId: string, mobile: string, roleType: string[]) => void;
  logout: () => void;
  setToken: (token: string | null) => void;
}

export const AuthContext = createContext<AuthContextType>({
  token: null,
  userId: null,
  mobile: null,
  roleType: [],
  isIFA: false,
  isDMACoord: false,
  isCOPS: false,
  isCOPSExecutive: false,
  login: () => {},
  logout: () => {},
  setToken: () => {},
});

const AUTH_STORAGE_KEY = 'authData';

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [token, setToken] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [mobile, setMobile] = useState<string | null>(null);
  const [roleType, setRoleType] = useState<string[]>([]);
  const [isIFA, setIsIFA] = useState<boolean>(false);
  const [isDMACoord, setIsDMACoord] = useState<boolean>(false);
  const [isCOPS, setIsCOPS] = useState<boolean>(false);
  const [isCOPSExecutive, setIsCOPSExecutive] = useState<boolean>(false);

  useEffect(() => {
    const storedAuthData = localStorage.getItem(AUTH_STORAGE_KEY);
    if (storedAuthData) {
      const parsedData = JSON.parse(storedAuthData);
      setToken(parsedData.token);
      setUserId(parsedData.userId);
      setMobile(parsedData.mobile);
      setRoleType(parsedData.roleType);
      setIsIFA(parsedData.isIFA);
      setIsDMACoord(parsedData.isDMACoord);
      setIsCOPS(parsedData.isCOPS);
      setIsCOPSExecutive(parsedData.isCOPSExecutive);
    }
  }, []);

  useEffect(() => {
    if (token && userId && mobile && roleType) {
      localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify({ token, userId, mobile, roleType, isIFA, isDMACoord, isCOPS, isCOPSExecutive }));
    }
  }, [token, userId, mobile, roleType, isIFA, isDMACoord, isCOPS, isCOPSExecutive]);

  const login = (token: string, userId: string, mobile: string, roleType: string[]) => {
    setToken(token);
    setUserId(userId);
    setMobile(mobile);
    setRoleType(roleType);
    setIsIFA(roleType.includes('IFA'));
    setIsDMACoord(roleType.includes('DMA Coordinator')||roleType.includes('Manager')||roleType.includes('DMA'));
    setIsCOPS(roleType.includes('COPS'));
    setIsCOPSExecutive(roleType.includes('COPS Executive'));
  };

  const logout = () => {
    localStorage.removeItem(AUTH_STORAGE_KEY);
    setToken(null);
    setUserId(null);
    setMobile(null);
    setRoleType([]);
    setIsIFA(false);
    setIsDMACoord(false);
    setIsCOPS(false);
    setIsCOPSExecutive(false);
  };

  return (
    <AuthContext.Provider value={{ token, userId, mobile, roleType, isIFA, isDMACoord, isCOPS, isCOPSExecutive, login, logout, setToken }}>
      {children}
    </AuthContext.Provider>
  );
};
