import React from 'react';
import { useIonRouter } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';

import { Header } from '../../../components/layout/header/Header';
import { LeadsFlowGenerator } from '../../../components/common/custom-leads-flow-generator/LeadsFlowGenerator';
import { lenderSpfDetailsFlow } from '../../../store/reducers/lead/leadSlice';
import { PersonalInfoForm } from './PLForms/PersonalInfoForm';

export const InitiatePrefrLead: React.FC = () => {
	type ComponentType = React.ReactNode | React.ReactFragment;
	type StepsComponents = {
		step: string;
		component: ComponentType;
	};
	const dispatch = useDispatch();
	const router = useIonRouter();
	const lenderSpfDetails = useSelector(
		(state: any) => state?.lead?.lenderSpecificDetails
	);

	const headerNeedArrowOnClick = async () => {
		dispatch(lenderSpfDetailsFlow({}));
		router.push('/lender-specific-info');
	};

	const stepsComponents: StepsComponents[] = [
		{
			step: 'step1',
			component: <PersonalInfoForm />,
		},
	];

	const stepsHeader: ComponentType = (
		<Header
			name={'Prefr Specific Form'}
			backButton={true}
			needArrow={true}
			needCross={false}
			needArrowOnClick={() => headerNeedArrowOnClick()}
		/>
	);

	return (
		<>
			<LeadsFlowGenerator
				currentStep={lenderSpfDetails?.step}
				stepsHeader={stepsHeader}
				displayStepsCounter={lenderSpfDetails?.subHeaderStepsCounter}
				stepsComponents={stepsComponents}
			/>
		</>
	);
};
