import * as Yup from "yup";

export const ABFLOtherInfoSchema = Yup.object({
	industry: Yup.string().required('Industry is required'),
	sub_industry: Yup.string().required('Sub Industry is required'),
	nature_of_business: Yup.string().required('Nature Of Business is required'),
});

export const ABFLOfferInfoSchema = Yup.object({
	amount: Yup.string().required('Loan amount is Required'),
});

export const OtherInfo = [
	{
		"name": "industry",
		"type": "dropdown",
		"placeholder": "Industry *"
	},
	{
		"name": "sub_industry",
		"type": "dropdown",
		"placeholder": "Sub Industry *"
	},
	{
		"name": "nature_of_business",
		"type": "dropdown",
		"placeholder": "Nature Of Business *"
	}
];

export const OfferInfo = [
	{
		"name": "offer_id",
		"type": "dropdown",
		"placeholder": "Tenure *"
	},
];
