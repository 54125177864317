import React, { useEffect, useState } from 'react';
import { IonButtons, IonContent, IonImg, IonPage } from '@ionic/react';
import { Header } from '../../components/layout/header/Header';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import './filter.scss';
import { CustomCheckbox } from '../../components/common/custom-checkbox/CustomCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import {
	dmaFilter,
	filterCities,
	//filterDmaRelData,
	filterDmaTypeData,
	filterReportingManagerList,
	resetDmaFilterState,
} from '../../store/reducers/dmaSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
import { subProductsList } from '../../store/reducers/user/userSlice';
import CustomDropDown from '../../components/common/custom-dropdown/CustomDropDown';
import { disableNumberArrows, showToastAlert } from '../../helpers/functions';
import { USER_ROLES_IDS } from '../../helpers/constant';
//import { showToastAlert } from '../../helpers/functions';
export const Filters: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch: any = useDispatch();
	const [dmaType1, setDmaType] = useState<any>([]);
	//const [dmaRel1, setDmaRel] = useState<any>([]);
	const [rms, setRms] = useState([]);
	const [cities, setCities] = useState([]);
	const [new1, setNew1] = useState<any>({});
	const [name1, setName] = useState('');
	const [citySelection, setCitySelection] = useState([]);
	const [rmSelction, setRmSelction] = useState([]);
	const dmaFilterSlctr: any = useSelector((state: any) => state?.dma);
	const dmaFlow: any = useSelector((state: any) => state?.users?.dmaFlow);
	const [subProducts, setSubProducts] = useState<any>([]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isFilterValid, setFilterValid] = useState(true);
	const [createdFromDate, setCreatedFromDate] = useState('');
	const [createdToDate, setCreatedToDate] = useState('');

	useEffect(() => {
		getDmaTypeAndDmaRel();
	}, []);

	const getDmaTypeAndDmaRel = async () => {
		await dispatch(filterDmaTypeData())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					const data = result?.data?.map((el: any) => {
						return { ...el, checked: false };
					});
					setDmaType(data);
				}
			});

		/*await dispatch(filterDmaRelData())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					const data = result?.data?.map((el: any) => {
						return { ...el, checked: false };
					});
					setDmaRel(data);
				}
			});*/
		await dispatch(filterReportingManagerList({param: `?manager_type=${USER_ROLES_IDS.BTM}`}))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					const data = result?.list?.map((el: any) => {
						return {
							id: el?.id,
							name: el?.value,
							value: el?.value,
							label: el?.value,
							checked: false,
						};
					});
					setRms(data);
				}
			});
		await dispatch(filterCities({ param: '' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result.status) {
					const data: any = [];
					result?.data?.forEach((el: any) => {
						if (!['unknown'].includes(el?.name?.toLowerCase())) {
							data.push({
								id: el?.id,
								name: el?.name,
								value: el?.name,
								label: el?.name,
								checked: false,
							});
						}
					});
					setCities(data);
				}
			});
		await dispatch(subProductsList())
			.then(unwrapResult)
			.then((result: any) => {
				if (result.status) {
					const data = result?.list?.map((el: any) => {
						return {
							id: el?.id,
							name: el?.category_name,
							value: el?.category_name,
							label: el?.category_name,
							checked: false,
						};
					});
					setSubProducts(data);
				}
			});
	};

	useEffect(() => {
		setNew1({
			//dmaRel: dmaRel1,
			dmaType: dmaType1,
			dmaRm: rms,
			dmaCity: cities,
		});
	}, [dmaType1, rms, cities]); // dmaRel1,

	useEffect(() => {
		getDmaFilter('', '');
	}, [new1]);

	const buttonNames = [
		{ id: 1, value: 'DMA Type', key: 'dmaType' },
		//{ id: 2, value: 'DMA Relationship', key: 'dmaRel' },
		{ id: 3, value: 'Reporting Manager', key: 'dmaRm' },
		{ id: 4, value: 'City', key: 'dmaCity' },
		{ id: 5, value: 'Created Date Range', key: 'dmafrom' },
		{ id: 6, value: 'Sub Products', key: 'subprdctpayout' },
	];

	const filterData = [
		{
			name: 'dmaType',
			type: 'checkbox',
			optionList: new1?.dmaType,
		},
		//{ name: 'dmaRel', type: 'checkbox', optionList: new1?.dmaRel },
		{ name: 'dmaRm', type: 'autocomplete', optionList: new1?.dmaRm },
		{ name: 'dmaCity', type: 'autocomplete', optionList: new1?.dmaCity },
		{ name: 'dmafrom', type: 'date', optionList: new1?.dmaCity },
		{
			name: 'subprdctpayout',
			type: 'subprdctpayout',
			optionList: new1?.dmaCity,
		},
	];
	const getDmaFilter = async (name: any, data: any) => {
		let dmaFltr;

		if (name) {
			dmaFltr = data;
		} else {
			dmaFltr = new1?.[name1]
				?.filter(({ value, checked }: any) => checked && value)
				.map(({ value, id }: any) =>
					['dmaRm', 'dmaCity'].includes(name1) ? id : value
				)
				.toString()
				.toLowerCase();
		}

		await dispatch(
			dmaFilter({
				...dmaFilterSlctr,
				[name1]: dmaFltr,
			})
		);
	};

	const handleFilter = (e: any, name: any, data: any) => {
		if (e) {
			const data1 = new1?.[name]?.map((i: any) => {
				if (i.value === data) {
					return {
						...i,
						checked: true,
					};
				} else {
					return { ...i };
				}
			});
			setNew1({ ...new1, [name]: data1 });
		} else {
			const data1 = new1?.[name]?.map((i: any) => {
				if (i.value === data) {
					return {
						...i,
						checked: false,
					};
				} else {
					return { ...i };
				}
			});
			setNew1({ ...new1, [name]: data1 });
		}
	};

	const resetCheckBoxes = (name: any) => {
		const data1 = new1?.[name]?.map((i: any) => {
			return {
				...i,
				checked: false,
			};
		});
		setNew1({ ...new1, [name]: data1 });
	};

	const clearFilter = async () => {
		dispatch(resetDmaFilterState());
		setCitySelection([]);
		setRmSelction([]);
		getDmaTypeAndDmaRel();
		resetCheckBoxes('dmaType');
		setSelectedButton(0);
		setName('');
		setFilterValid(true);
		setCreatedFromDate('');
		setCreatedToDate('');
	};

	const [selectedButton, setSelectedButton] = useState(0);

	const handleBackButton = () => {
		const myPromise = new Promise(function (myResolve) {
			setTimeout(function () {
				myResolve(history.push('/team-list'));
			}, 10);
		});
		myPromise.then(() => {
			dispatch(resetDmaFilterState());
			setRmSelction([]);
			setCitySelection([]);
			getDmaTypeAndDmaRel();
			resetCheckBoxes('dmaType');
			setSelectedButton(0);
			setName('');
			setFilterValid(true);
			setCreatedFromDate('');
			setCreatedToDate('');
		});
	};
	useEffect(() => {
		const cityIds = citySelection?.map((t: any) => {
			return t?.id;
		});
		const updateCheckedCities = new1.dmaCity?.map((item: any) => {
			return cityIds?.includes(item?.id)
				? {
						id: item?.id,
						name: item?.name,
						value: item?.value,
						label: item?.label,
						checked: true,
				  }
				: {
						id: item?.id,
						name: item?.name,
						value: item?.value,
						label: item?.label,
						checked: false,
				  };
		});

		setNew1({
			...new1,
			dmaCity: updateCheckedCities,
		});
	}, [citySelection]);
	useEffect(() => {
		const rmIds = rmSelction?.map((t: any) => {
			return t?.id;
		});
		const updateCheckedRms = new1.dmaRm?.map((item: any) => {
			return rmIds?.includes(item?.id)
				? {
						id: item?.id,
						name: item?.name,
						value: item?.value,
						label: item?.label,
						checked: true,
				  }
				: {
						id: item?.id,
						name: item?.name,
						value: item?.value,
						label: item?.label,
						checked: false,
				  };
		});

		setNew1({
			...new1,
			dmaRm: updateCheckedRms,
		});
	}, [rmSelction]);

	useEffect(() => {
		setCitySelection([]);
		setRmSelction([]);
		getDmaTypeAndDmaRel();
		resetCheckBoxes('dmaType');
		setSelectedButton(0);
		setName('');
		setFilterValid(true);
		setCreatedFromDate(dmaFilterSlctr?.dmafrom);
		setCreatedToDate(dmaFilterSlctr.dmato);
	}, [dmaFlow?.dmaCode]);

	return (
		<IonPage>
			<Header
				backButton={true}
				needArrow={true}
				needArrowOnClick={() => {
					handleBackButton();
				}}
				needArrowImg={'/images/icons/backarrow_white_bg.png'}
				needArrowImgStyle={{ height: '26px' }}
				name={t('Filters')}
				endItem={
					<IonButtons slot='end'>
						<IonButtons
							onClick={clearFilter}
							className='fs-14 fw-600 cursor-pointer'
							style={{ color: 'var(--ion-color-primary)' }}
						>
							{t('Clear Filter')}
						</IonButtons>
					</IonButtons>
				}
			/>
			<IonContent className='border'>
				<div className='row h-100'>
					<div className='col-5 pe-0'>
						{buttonNames.map((buttonName: any, index) => (
							<button
								key={index}
								className={`filterName btn-primary m-1 fs-14 fw-600 ${
									selectedButton === index ? 'selected' : ''
								}`}
								style={{
									backgroundColor:
										selectedButton === index ? 'white' : '#f1f1f1',
									color: selectedButton === index ? '#0165E1' : '#0C0E2B',
									border: 'none',
									borderRadius: '0',
								}}
								onClick={() => {
									setSelectedButton(index);
									if (buttonName.key !== 'dmafrom') {
										setName(buttonName.key);
									} else {
										setName('');
									}

									/*if (createdFromDate === '' || createdToDate === '') {
										setCreatedFromDate('');
										setCreatedToDate('');
										getDmaFilter('dmafrom', '');
										getDmaFilter('dmato', '');
									}

									if (
										dmaFilterSlctr?.payoutProduct?.trim() === '' ||
										dmaFilterSlctr?.payoutfrom?.trim() === '' ||
										dmaFilterSlctr?.payoutto?.trim() === ''
									) {
										const spData = subProducts?.map((item: any) => {
											return {
												...item,
												checked: false,
											};
										});
										setSubProducts(spData);
										dmaFilter({
											...dmaFilterSlctr,
											payoutProduct: '',
											payoutfrom: '',
											payoutto: '',
										});
									}*/
								}}
							>
								{t(buttonName?.value)}
							</button>
						))}
					</div>
					<div className='col-7 ps-0'>
						<div className='Flex ps-3'>
							{filterData?.map((filter, index) => {
								return (
									<div
										key={filter.name}
										className={`filter-container mt-3 ${
											selectedButton === index ? 'show' : 'hide'
										}`}
									>
										{['dmaRm', 'dmaCity'].includes(name1) && (
											<Typeahead
												id={'basic-typeahead-multiple' + filter.name}
												labelKey={'name'}
												multiple
												className='pe-3'
												onChange={(e: any) => {
													name1 === 'dmaRm'
														? setRmSelction(e)
														: name1 === 'dmaCity'
														? setCitySelection(e)
														: '';
												}}
												options={
													name1 === 'dmaRm'
														? new1?.dmaRm.filter((i: any) => !i.checked)
														: name1 === 'dmaCity'
														? new1?.dmaCity.filter((i: any) => !i.checked)
														: []
												}
												placeholder={
													name1 === 'dmaRm'
														? t('Choose Reporting Managers') + ' ...'
														: name1 === 'dmaCity'
														? t('Choose Cities') + ' ...'
														: ''
												}
												selected={
													name1 === 'dmaRm'
														? rmSelction
														: name1 === 'dmaCity'
														? citySelection
														: []
												}
												paginationText={
													name1 === 'dmaRm'
														? t('Load More Managers')
														: name1 === 'dmaCity'
														? t('Load More Cities')
														: ''
												}
											/>
										)}
										<div
											className={`filterList ${
												selectedButton === index ? 'selected' : ''
											}`}
											onClick={() => setSelectedButton(index)}
											style={{
												cursor: 'pointer',
												fontWeight:
													selectedButton === index ? 'bold' : 'normal',
											}}
										>
											{/* <span className='filterName'>{filter.Name}</span> */}
										</div>
										{selectedButton === index &&
											[
												'dmaType',
												'dmaRel',
												'dmafrom',
												'dmato',
												'subprdctpayout',
											].includes(filter.name) && (
												<div className='option-list'>
													{filter.type === 'date' && (
														<>
															<CustomInput
																placeholder={t('From Date')}
																type={'date'}
																needSuffixIcon={true}
																suffixClassName={
																	'position-absolute date-of-birth-sufix'
																}
																suffixText={
																	<IonImg
																		src='/images/icons/input-dob-calendar-icon.svg'
																		alt='dob'
																	/>
																}
																value={createdFromDate}
																onFocus={() => setName('dmafrom')}
																onChange={(e: any) => {
																	if (!createdToDate && !e.detail.value) {
																		setFilterValid(true);
																	} else if (createdToDate && e.detail.value) {
																		setFilterValid(true);
																	} else {
																		setFilterValid(false);
																	}

																	if (
																		new Date(e.detail.value) >
																		new Date(
																			new Date().toISOString().split('T')[0]
																		)
																	) {
																		if (createdToDate) {
																			setFilterValid(false);
																		}
																		showToastAlert({
																			type: 'error',
																			message: 'Future date not allowed',
																		});
																		setCreatedFromDate('');
																		getDmaFilter('dmafrom', '');
																	} else if (
																		createdToDate &&
																		new Date(e.detail.value) >
																			new Date(createdToDate)
																	) {
																		setFilterValid(false);
																		showToastAlert({
																			type: 'error',
																			message: 'Future date not allowed',
																		});
																		setCreatedFromDate('');
																		getDmaFilter('dmafrom', '');
																	} else {
																		setCreatedFromDate(e.detail.value);
																		getDmaFilter('dmafrom', e.detail.value);
																	}
																}}
																outerClassName={`${'position-relative'}`}
																max={
																	dmaFilterSlctr.dmato
																		? dmaFilterSlctr.dmato
																		: new Date().toISOString().split('T')[0]
																}
																onKeyDown={(e: any) => e.preventDefault()}
															/>
															<CustomInput
																placeholder={t('To Date')}
																type={'date'}
																needSuffixIcon={true}
																suffixClassName={
																	'position-absolute date-of-birth-sufix'
																}
																suffixText={
																	<IonImg
																		src='/images/icons/input-dob-calendar-icon.svg'
																		alt='dob'
																	/>
																}
																onFocus={() => setName('dmato')}
																value={createdToDate}
																onChange={(e: any) => {
																	if (!createdFromDate && !e.detail.value) {
																		setFilterValid(true);
																	} else if (
																		createdFromDate &&
																		e.detail.value
																	) {
																		setFilterValid(true);
																	} else {
																		setFilterValid(false);
																	}
																	if (
																		new Date(e.detail.value) >
																		new Date(
																			new Date().toISOString().split('T')[0]
																		)
																	) {
																		if (createdFromDate) {
																			setFilterValid(false);
																		}
																		showToastAlert({
																			type: 'error',
																			message: 'Future date not allowed',
																		});
																		setCreatedToDate('');
																		getDmaFilter('dmato', '');
																	} else if (
																		createdFromDate &&
																		new Date(e.detail.value) <
																			new Date(createdFromDate)
																	) {
																		setFilterValid(false);
																		showToastAlert({
																			type: 'error',
																			message: 'Past date not allowed',
																		});
																		setCreatedToDate('');
																		getDmaFilter('dmato', '');
																	} else {
																		setCreatedToDate(e.detail.value);
																		getDmaFilter('dmato', e.detail.value);
																	}
																}}
																outerClassName={`${'position-relative'}`}
																min={dmaFilterSlctr.dmafrom}
																max={new Date().toISOString().split('T')[0]}
																onKeyDown={(e: any) => e.preventDefault()}
															/>
														</>
													)}
													{filter.type === 'subprdctpayout' && (
														<>
															<CustomDropDown
																{...{
																	onClick: () => setName('payoutProduct'),
																	isCheckbox: false,
																	title: t('Select Sub Product'),
																	setData: (data: any) => {
																		setSubProducts(data);
																		const val = data.filter(
																			(item: any) => item.checked
																		);
																		if (val.length > 0) {
																			getDmaFilter(
																				'payoutProduct',
																				val[0].id.toString()
																			);
																			if (
																				dmaFilterSlctr?.payoutfrom ===
																					undefined ||
																				dmaFilterSlctr?.payoutfrom === '' ||
																				dmaFilterSlctr?.payoutto ===
																					undefined ||
																				dmaFilterSlctr?.payoutto === ''
																			) {
																				setFilterValid(false);
																			} else {
																				if (
																					parseInt(dmaFilterSlctr.payoutfrom) >
																					parseInt(dmaFilterSlctr.payoutto)
																				) {
																					setFilterValid(false);
																				} else {
																					setFilterValid(true);
																				}
																			}
																		} else {
																			getDmaFilter('payoutProduct', '');
																			if (
																				dmaFilterSlctr?.payoutfrom === '' &&
																				dmaFilterSlctr?.payoutto === ''
																			) {
																				setFilterValid(true);
																			} else {
																				setFilterValid(false);
																			}
																		}
																	},
																	data: subProducts,
																	placeholder: t(
																		'Search Sub Product by “Name”'
																	),
																	isSearch: true,
																	modalTitle: t('Sub Products'),
																	listTitle: t('All Sub Products'),
																}}
															/>
															<CustomInput
																placeholder={t('Payout Range From')}
																type={'string'}
																onFocus={() => setName('payoutfrom')}
																value={dmaFilterSlctr.payoutfrom}
																maxLength={2}
																onChange={(e: any) => {
																	getDmaFilter('payoutfrom', e.detail.value);
																	if (e.detail.value) {
																		if (
																			dmaFilterSlctr.payoutProduct === '' ||
																			dmaFilterSlctr.payoutProduct ===
																				undefined ||
																			dmaFilterSlctr.payoutto === undefined ||
																			dmaFilterSlctr.payoutto === ''
																		) {
																			setFilterValid(false);
																		} else {
																			if (
																				parseInt(e.detail.value) >
																				parseInt(dmaFilterSlctr.payoutto)
																			) {
																				setFilterValid(false);
																			} else {
																				setFilterValid(true);
																			}
																		}
																	} else {
																		if (
																			dmaFilterSlctr?.payoutProduct === '' &&
																			dmaFilterSlctr?.payoutto === ''
																		) {
																			setFilterValid(true);
																		} else {
																			setFilterValid(false);
																		}
																	}
																}}
																outerClassName={`${'position-relative mt-3'}`}
																onBeforeInput={(evt: any) => {
																	//Only numbers accepted on user input
																	!/[0-9]/.test(evt.data) &&
																		evt.preventDefault();
																	//Only accepts 10 characters on user input
																	if (evt.target.value.length === 10) {
																		evt.preventDefault();
																	}
																}}
																onKeyDown={(e: any) => {
																	disableNumberArrows(e);
																}}
																onWheel={(e: any) => e.target.blur()}
															/>
															<CustomInput
																placeholder={t('Payout Range To')}
																type={'string'}
																maxLength={2}
																value={dmaFilterSlctr.payoutto}
																onFocus={() => setName('payoutto')}
																onChange={(e: any) => {
																	getDmaFilter('payoutto', e.detail.value);
																	if (e.detail.value) {
																		if (
																			dmaFilterSlctr.payoutProduct === '' ||
																			dmaFilterSlctr.payoutProduct ===
																				undefined ||
																			dmaFilterSlctr.payoutfrom === undefined ||
																			dmaFilterSlctr.payoutfrom === ''
																		) {
																			setFilterValid(false);
																		} else {
																			if (
																				parseInt(e.detail.value) <
																				parseInt(dmaFilterSlctr.payoutfrom)
																			) {
																				setFilterValid(false);
																			} else {
																				setFilterValid(true);
																			}
																		}
																	} else {
																		if (
																			dmaFilterSlctr?.payoutProduct === '' &&
																			dmaFilterSlctr?.payoutfrom === ''
																		) {
																			setFilterValid(true);
																		} else {
																			setFilterValid(false);
																		}
																	}
																}}
																outerClassName={`${'position-relative'}`}
																onBeforeInput={(evt: any) => {
																	//Only numbers accepted on user input
																	!/[0-9]/.test(evt.data) &&
																		evt.preventDefault();
																	//Only accepts 10 characters on user input
																	if (evt.target.value.length === 10) {
																		evt.preventDefault();
																	}
																}}
																onKeyDown={(e: any) => {
																	disableNumberArrows(e);
																}}
																onWheel={(e: any) => e.target.blur()}
															/>
														</>
													)}
													{!['date', 'subprdctpayout'].includes(filter?.type) &&
														filter?.optionList?.map((option: any) => {
															return (
																<div
																	className='subData mb-2'
																	key={option.id}
																>
																	<CustomCheckbox
																		name={filter?.type}
																		onChange={(e: any) => {
																			setName(filter?.name);
																			handleFilter(
																				e.detail.checked,
																				filter?.name,
																				option.value
																			);
																		}}
																		value={option.value}
																		isChecked={option.checked}
																		labelPlacement={'end'}
																	>
																		<label className=' fs-14 subData darkest-grey'>
																			{t(option.value)}
																		</label>
																	</CustomCheckbox>
																</div>
															);
														})}
												</div>
											)}
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</IonContent>
			<div className='footer border'>
				<div></div>
				<div>
					<button
						onClick={() => {
							history.push('/team-list');
						}}
						className='btn'
						disabled={!isFilterValid}
					>
						{t('Apply')}
					</button>
				</div>
			</div>
		</IonPage>
	);
};

export default Filters;
