import React, { useEffect, useState } from 'react';
import { CircularProgressBar } from '../custom-circular-progress-bar/CircularProgressBar';
import { useTranslation } from 'react-i18next';
import './CustomStepCounterHeaderBar.scss';
import { useSelector } from 'react-redux';

const stepLeadCreationLoan = [
	'Lead Creation Info',
	'Lead Basic Info',
	'OTP Verification',
];
const stepLeadCreationInsurance = [
	'Lead Creation Info',
	'Details of Proposer',
	'Basic Details of Life Assured',
	'Occupation Details of Life Assured',
];

const stepInsuranceOther = [
	'Lead Creation Info',
	'Details of Proposer',
	'Basic Details of Proposer',
	'Occupation Details of Proposer',
	'Basic Details of Life Assured',
	'Occupation Details of Life Assured',
];

const stepEfrankingInitiate = ['User Verification', 'E Franking'];

export const CustomStepsCountHeaderBar = ({
	currentStep,
	displayStepsCounter,
	headerStep,
	data,
}: any) => {
	// please data in array of {}
	const { t } = useTranslation();
	const [stepCreation, setStepCreation] = useState<any>([]);
	const leadFlowSelector = useSelector(
		(state: any) => state?.lead?.leadFlowData
	);

	useEffect(() => {
		if (
			['loans', 'loan'].includes(
				leadFlowSelector?.productType?.toLowerCase() ||
					leadFlowSelector?.productName?.toLowerCase() ||
					leadFlowSelector?.product_name?.toLowerCase()
			)
		) {
			setStepCreation(stepLeadCreationLoan);
		} else if (headerStep === 'e-franking-initiate') {
			setStepCreation(stepEfrankingInitiate);
		} else if (leadFlowSelector?.productFor === 'self') {
			setStepCreation(stepLeadCreationInsurance);
		} else {
			setStepCreation(stepInsuranceOther);
		}
	}, [
		leadFlowSelector?.productType,
		leadFlowSelector?.productFor,
		leadFlowSelector?.productName,
	]);
	const getSelectedStepData = (currentStep: any) => {
		for (let i = 0; i < data?.length; i++) {
			switch (currentStep) {
				case `step${i + 1}`:
					return {
						percentage: data[i].percentage,
						value: data[i].value + '/' + data?.length,
						text: t(data[i].text),
					};
				default:
			}
		}
	};

	return (
		<div
			style={{
				backgroundColor: '#E9F0FB',
			}}
			className={`p-2 px-3 font-semibold ${
				displayStepsCounter ? '' : 'd-none'
			}`}
		>
			{getSelectedStepData(currentStep) && (
				<div className='d-md-none d-flex align-items-center justify-content-between'>
					{t(`${getSelectedStepData(currentStep)?.text}`)}
					<CircularProgressBar
						ringBgColor='#dfdfdf'
						ringColor='#0165e1'
						strokeWidth='4'
						sqSize='40'
						percentage={getSelectedStepData(currentStep)?.percentage}
						value={getSelectedStepData(currentStep)?.value}
						textColor='black'
					/>
				</div>
			)}
			<div className='d-none d-md-block d-md-flex justify-content-center'>
				{stepCreation &&
					stepCreation.map((label: any, index: number) => {
						const active = Number(currentStep?.charAt(4)) >= index + 1;
						return (
							<React.Fragment key={`stepCreation-${index}`}>
								<span
									className={` d-flex align-items-center custom-step-counter-header${
										active ? '-active ' : '-disabled'
									}`}
								>
									<div className='d-flex justify-content-center align-items-center'>
										<span className='custom-step-counter-header-number'>
											{index + 1}
										</span>
									</div>
									{t(label)}{' '}
								</span>
								{index !== stepCreation.length - 1 && (
									<span className='custom-step-counter-header-dashed px-2'>
										---
									</span>
								)}
							</React.Fragment>
						);
					})}
			</div>
		</div>
	);
};
