//// @ts-nocheck


import { IonCardTitle, IonContent, IonLabel, IonList, IonPage } from "@ionic/react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import useApi from "../../../api/useApi";
import { CustomButton } from "../../../components/common/custom-button/CustomButton";
import CustomRadioButton from "../../../components/common/custom-radio-button/CustomRadioButton";
import { Header } from "../../../components/layout/header/Header";
import { AuthContext } from "../../../context/AuthContext";
import { useBusyLoading } from "../../../context/BusyLoadingContext";
import { GET_INSURANCE_POLICY, GET_INSURANCE_POLICY_ALL, INSURANCE_FETCH_APPLICATION_DETAILS, STATUS_FLOW, UPDATE_STATUS } from "../../../helpers/api-endpoints";
import { INSURANCE_STATUS } from "../../../helpers/constant";
import { showToastAlert } from "../../../helpers/functions";
import { ApplicationBean, Policyform, StatusUpdateRequest } from "../../../model/insurance_bean";
import { PolicyIssued, PolicyIssuedProps } from "../policy_issued/policy_issued";
import { AppStatusUpdate } from "../app_status_update/app_status_update";
import ApplicationContext from "../../../context/ApplicationContext";
import { DocumentUploadForm } from "../../../model/document_bean";




export interface ApplicationStatusProps {
    inpApplicationId?: number;
    inpPolicyId?: number;
    inpCurrentStatus?: number;
    inpCurrentStatusLabel?: string;
}



export const UpdateApplicationStatus: React.FC<ApplicationStatusProps> = ({ inpApplicationId, inpPolicyId, inpCurrentStatus, inpCurrentStatusLabel }) => {
    const [selectedValue, setSelectedValue] = useState<number | null>(null);


    const { setBusyState } = useBusyLoading();

    const location = useLocation();
    const userContext = useContext(AuthContext);
    const [statusList, setStatusList] = useState<any[]>([{
        value: INSURANCE_STATUS.CLOSED,
        name: "Closed"
    }]);

    const [showLoading, setshowLoading] = useState<boolean>(true);


    const [applicationId, setApplicationId] = useState(inpApplicationId);
    const [policyId, setPolicyId] = useState(inpPolicyId);
    // const [currentStatus, setCurrentStatus] = useState<null | number>();

    const [currentStatus, setCurrentStatus] = useState<null | number>(inpCurrentStatus ?? null);

    const [currentStatusLabel, setCurrentStatusLabel] = useState(inpCurrentStatusLabel ?? "-");

    const [policyform, setPolicyform] = useState<Policyform>();
    const [applicationBean, setApplicationBean] = useState<ApplicationBean>();
    // const {applicationBean} = useContext(ApplicationContext);

    const [showUpdateButton, setShowUpdateButton] = useState<boolean>(false);

    const [policyDocs, setPolicyDocs] = useState<DocumentUploadForm[]>([]);




    const statusUpdate =
        useApi<any>(`${UPDATE_STATUS}`);


    useEffect(() => {
        setBusyState(statusUpdate.loading); 
    }, [statusUpdate.loading]);


    useEffect(() => {
        setshowLoading(false);
        showToastAlert({ type: "error", message: statusUpdate.error?.message! });
    }, [statusUpdate.error]);



    const statusFlow =
        useApi<any>(`${STATUS_FLOW}`);


    useEffect(() => {
        setBusyState(statusFlow.loading);
    }, [statusFlow.loading]);


    useEffect(() => {
        setshowLoading(false);
        showToastAlert({ type: "error", message: statusFlow.error?.message! });
    }, [statusFlow.error]);






    const getPolicyDetails =
        useApi<any>(`${GET_INSURANCE_POLICY}`);

    useEffect(() => {
        setBusyState(getPolicyDetails.loading);
    }, [getPolicyDetails.loading]);


    useEffect(() => {        
        setshowLoading(false);
        showToastAlert({ type: "error", message: getPolicyDetails.error?.message! });
    }, [getPolicyDetails.error]);



    const getPolicyDocAndDetails =
        useApi<any>(`${GET_INSURANCE_POLICY_ALL}`);

    useEffect(() => {
        setBusyState(getPolicyDocAndDetails.loading);
    }, [getPolicyDocAndDetails.loading]);


    useEffect(() => {
        setshowLoading(false);
        showToastAlert({ type: "error", message: getPolicyDocAndDetails.error?.message! });
    }, [getPolicyDocAndDetails.error]);

    // const getPolicyDetails =
    //     useApi<any>(`${GET_INSURANCE_POLICY}`);




    const getAppDetails = useApi<any>(
        INSURANCE_FETCH_APPLICATION_DETAILS
    );

    useEffect(() => {
        setBusyState(getAppDetails.loading);
    }, [getAppDetails.loading]);


    // useEffect(() => {
    //     showToastAlert({ type: "error", message: getAppDetails.error?.message! });
    // }, [getAppDetails.error]);






    console.log("currentStatusLabel", currentStatusLabel);

    useEffect(() => {
        if (location.pathname === '/updatePolicyStatus') {
            const state = location.state as any;
            const applicationStatusProps = state?.applicationStatusProps || {};
            if (applicationId === undefined) {
                setApplicationId(applicationStatusProps.inpApplicationId);
            }
            if (policyId === undefined) {
                setPolicyId(applicationStatusProps.inpPolicyId);
            }

            if (currentStatusLabel === "-") {
                console.log("applicationStatusProps.inpCurrentStatusLabel", applicationStatusProps.inpCurrentStatusLabel);
                setCurrentStatusLabel(applicationStatusProps.inpCurrentStatusLabel);
            }
        }
    }, [location, applicationId, policyId, currentStatusLabel]);

    useEffect(() => {

        if (policyId !== undefined) {
            const payload = {
                policyId: policyId
            }
            getPolicyDetails.executeRequest("post", payload);
            getPolicyDocAndDetails.executeRequest("post", payload);
        }

        else if (applicationId !== undefined) {


            const payload = JSON.stringify({ applicationId: applicationId });
            // const payload = { applicationId: parseInt(applicationId)};

            console.log("payload", payload);

            // executeRequest("get", payload, true);
            getAppDetails.executeRequest("post", payload, true)

            // .then(

            //   () => {

            //     // console.log("executeRequest",responseData);


            //     if (getAppDetails.responseData?.data) {
            //       console.log("hbs success ", getAppDetails.responseData?.data);

            //       const appBeanFin = new ApplicationBean(getAppDetails.responseData?.data);
            //       setCurrentStatus(appBeanFin.statusId!);
            //     }
            //   }
            // );
        }

    }, [applicationId, policyId]);

    useEffect(() => {

        if (getPolicyDetails.responseData?.status) {
            // console.log("hbs getPolicyDetails.responseData?.data", getPolicyDetails.responseData?.data);
            const stubPolicyForm: Policyform = getPolicyDetails.responseData?.data;
            console.log("hbs stubPolicyForm", stubPolicyForm);
            setPolicyform(stubPolicyForm);
            setCurrentStatus(policyform?.statusId ?? null);
            // console.log("hbs policyform", policyform);


        }

    }, [getPolicyDetails.responseData, currentStatus, policyform]);


    useEffect(() => {
        if (getPolicyDocAndDetails.responseData?.status) {
            setPolicyDocs(getPolicyDocAndDetails.responseData?.data.documentMasters);
        }
    }, [getPolicyDocAndDetails.responseData, policyDocs]);


    useEffect(() => {

        if (getAppDetails.responseData?.status) {
            // console.log("hbs getPolicyDetails.responseData?.data", getPolicyDetails.responseData?.data);
            const appBeanFin = new ApplicationBean(getAppDetails.responseData?.data);
            setApplicationBean(appBeanFin);
            setCurrentStatus(appBeanFin.statusId!);

            setCurrentStatus(appBeanFin.statusId!);
            // console.log("hbs policyform", policyform);
        }

    }, [getAppDetails.responseData, currentStatus]);



    useEffect(() => {

        let stubRole: string = "";

        if (userContext.isIFA) {
            stubRole = "IFA";
        }
        else if (userContext.isDMACoord) {
            stubRole = "DMA_COORD";
        }
        else if (userContext.isCOPS) {
            stubRole = "COPS_HEAD";
        }
        else if (userContext.isCOPSExecutive) {
            stubRole = "COPS_EXEC";
        }

        console.log("hbs currentStatus fin", currentStatus);


        if (currentStatus !== undefined) {
            let payload = {
                role: stubRole,
                current_status: currentStatus,
                entity: (policyId === undefined) ? "APP" : "POL",
            }

            statusFlow.executeRequest("post", payload);
        }

    }, [currentStatus]);


    useEffect(() => {
        console.log(statusFlow.responseData);
        if (statusFlow.responseData?.status && statusFlow.responseData?.data) {


            const stubList: any[] = statusFlow.responseData?.data ?? [];

            console.log("statusFlow.responseData?.data", statusFlow.responseData?.data);


            console.log("stubList", stubList);

            let stubStatusList: any[] = [{
                value: INSURANCE_STATUS.CLOSED,
                name: "Closed"
            },
                // {
                //         value: INSURANCE_STATUS.APPROVED,
                //         name: "Test Approved"
                //     },

                //     {
                //         value: INSURANCE_STATUS.PAYMENT_DONE,
                //         name: "Test PAYMENT DONE"
                //     },

                //     {
                //         value: INSURANCE_STATUS.PAYMENT_VERIFIED,
                //         name: "Test PAYMENT VERIFIED"
                //     },

                // {
                //     value: INSURANCE_STATUS.PAYMENT_PENDING,
                //     name: "Test PAYMENT PENDING"
                // },
                // {
                //     value: INSURANCE_STATUS.ALLOCATE_PLVC_TO_CENTRAL_OPS_EXECUTIVE,
                //     name: "Test COPS"
                // },




            ];

            stubList.map((item) => {

                stubStatusList.push({
                    value: item.next_status_id,
                    name: t(item.nextStatus)
                });

            });


            setshowLoading(false);
            setStatusList(stubStatusList);
            console.log("statusList", statusList);
        }
    }, [statusFlow.responseData]);




    const history = useHistory();
    const { t } = useTranslation();

    // const data = [
    //     {
    //         value: 1,
    //         name: t(PAYMENT_DONE),
    //     },
    //     {
    //         value: 2,
    //         name: t(PLVC_ACCEPTED),
    //     },
    //     {
    //         value: 3,
    //         name: t(PLVC_REJECTED),
    //     },
    //     {
    //         value: 4,
    //         name: t(CLOSED),
    //     },
    //     {
    //         value: 5,
    //         name: t(ALLOCATE_PLVC_TO_CENTRAL_OPS_EXECUTIVE),
    //     },
    //     {
    //         value: 6,
    //         name: t(REALLOCATE_PLVC_TO_CENTRAL_OPS_EXECUTIVE),
    //     },
    //     {
    //         value: 7,
    //         name: t(INITIATE_PLVC),
    //     },
    //     {
    //         value: 8,
    //         name: t(POLICY_LOGGED_IN),
    //     },
    //     {
    //         value: 9,
    //         name: t(POLICY_ISSUED),
    //     },
    //     {
    //         value: 10,
    //         name: t(POLICY_BOND_RECEIVED),
    //     },
    //     {
    //         value: 11,
    //         name: t(RENEW_POLICY),
    //     },
    // ];






    const updateStatus = async (id: any) => {
        // const bulkIds = leadSelector?.getLeadAllSelectedIds;
        // await dispatch(
        // 	updateLeadStatus({ data: { data: bulkIds || id, status: stage } })
        // )
        // 	.then(unwrapResult)
        // 	.then((result: any) => {
        // 		if (result?.status) {
        // 			history.push('/lead-list', { select: false });
        // 		}
        // 	});
    };

    const onIonChange = (event: any) => {
        console.log(event.target.value, "===event");
        setSelectedValue(parseInt(event.target.value as string));
    };

    const renderResult = (): React.JSX.Element => {

        // setShowUpdateButton(false);

        console.log("hbs render called", selectedValue);
        if (policyId) {
            policyform!.statusId = selectedValue;

            const polForm: Policyform = policyform!;


            let policyIssuedProps: PolicyIssuedProps = {
                hidePolicyIdNumber: true,
                hidePolicyIssuanceDate: true,
                hideTotalSumAssured: true,
                hidePolicyTerm: true,
                hidePremiumPaymentTerm: true,
                hidePremiumFrequency: true,
                hidePremiumAmount: true,
                hideInsurerApplicationNo: true,
                hidePaymentReceipt: true,
                hidePaymentVideo: true,
                hidePolicyBondReceivedDate: true,
                hideRemarks: true,
                hideCloseReason: true,
                hidePLVCRejectedReason: true,
                hideCopsExecutive: true,
                hideUploadedPaymentReceipt: true,
                hideUploadedPaymentVideo: false,
                policyform: polForm!,
            };

            console.log("hbs render called before switch", selectedValue);


            switch (selectedValue) {
                case INSURANCE_STATUS.CLOSED:
                    // setShowUpdateButton(false);

                    return (
                        <PolicyIssued
                            hidePolicyIdNumber={true}
                            hidePolicyIssuanceDate={true}
                            hidePostponementDate={true}
                            hideTotalSumAssured={true}
                            hidePolicyTerm={true}
                            hidePremiumPaymentTerm={true}
                            hidePremiumFrequency={true}
                            hidePremiumAmount={true}
                            hideInsurerApplicationNo={true}
                            hidePaymentReceipt={true}
                            hidePaymentVideo={true}
                            hidePolicyBondReceivedDate={true}
                            hidePLVCRejectedReason={true}
                            hideCopsExecutive={true}
                            hideUploadedPaymentReceipt={true}
                            hideUploadedPaymentVideo={false}
                            policyform={polForm}
                            hideRemarks={false}
                            hideCloseReason={false}
                            policyDocs={(userContext.isCOPS || userContext.isCOPSExecutive) ? policyDocs : undefined}
                        />
                    );
                    break;

                case INSURANCE_STATUS.POSTPONED:
                    // setShowUpdateButton(false);

                    return (
                        <PolicyIssued
                            hidePolicyIdNumber={true}
                            hidePolicyIssuanceDate={true}
                            hideTotalSumAssured={true}
                            hidePolicyTerm={true}
                            hidePremiumPaymentTerm={true}
                            hidePremiumFrequency={true}
                            hidePremiumAmount={true}
                            hideInsurerApplicationNo={true}
                            hidePaymentReceipt={true}
                            hidePaymentVideo={true}
                            hidePolicyBondReceivedDate={true}
                            hidePLVCRejectedReason={true}
                            hideCopsExecutive={true}
                            hideUploadedPaymentReceipt={true}
                            hideUploadedPaymentVideo={false}
                            hideRemarks={true}
                            hideCloseReason={true}
                            policyform={polForm}
                            hidePostponementDate={false}
                            policyDocs={policyDocs}
                        />
                    );
                    break;
                case INSURANCE_STATUS.ALLOCATE_PLVC_TO_CENTRAL_OPS_EXECUTIVE:
                case INSURANCE_STATUS.REALLOCATE_PLVC_TO_CENTRAL_OPS_EXECUTIVE:
                    // setShowUpdateButton(false);
                    return (
                        <PolicyIssued
                            hidePolicyIdNumber={true}
                            hidePolicyIssuanceDate={true}
                            hidePostponementDate={true}
                            hideTotalSumAssured={true}
                            hidePolicyTerm={true}
                            hidePremiumPaymentTerm={true}
                            hidePremiumFrequency={true}
                            hidePremiumAmount={true}
                            hideInsurerApplicationNo={true}
                            hidePaymentReceipt={true}
                            hidePaymentVideo={true}
                            hidePolicyBondReceivedDate={true}
                            hideRemarks={true}
                            hideCloseReason={true}
                            hidePLVCRejectedReason={true}
                            hideUploadedPaymentReceipt={true}
                            hideUploadedPaymentVideo={false}
                            policyform={polForm!}
                            hideCopsExecutive={false}
                            policyDocs={policyDocs}
                        />
                    );
                    break;
                case INSURANCE_STATUS.INITIATE_PLVC:
                    // setShowUpdateButton(false);
                    return (
                        <PolicyIssued
                            hidePolicyIdNumber={true}
                            hidePolicyIssuanceDate={true}
                            hidePostponementDate={true}
                            hideTotalSumAssured={true}
                            hidePolicyTerm={true}
                            hidePremiumPaymentTerm={true}
                            hidePremiumFrequency={true}
                            hidePremiumAmount={true}
                            hideInsurerApplicationNo={true}
                            hidePaymentReceipt={true}
                            hidePaymentVideo={true}
                            hidePolicyBondReceivedDate={true}
                            hideRemarks={true}
                            hideCloseReason={true}
                            hidePLVCRejectedReason={true}
                            hideUploadedPaymentReceipt={true}
                            hideUploadedPaymentVideo={true}
                            policyform={polForm!}
                            hideCopsExecutive={true}
                            policyDocs={policyDocs}
                        // hideCopsExecutive={false}
                        />
                    );
                    break;

                case INSURANCE_STATUS.INITIATE_PLVC_COPS:
                    // setShowUpdateButton(false);
                    return (
                        <PolicyIssued
                            hidePolicyIdNumber={true}
                            hidePolicyIssuanceDate={true}
                            hidePostponementDate={true}
                            hideTotalSumAssured={true}
                            hidePolicyTerm={true}
                            hidePremiumPaymentTerm={true}
                            hidePremiumFrequency={true}
                            hidePremiumAmount={true}
                            hideInsurerApplicationNo={true}
                            hidePaymentReceipt={true}
                            hidePaymentVideo={false}
                            hidePolicyBondReceivedDate={true}
                            hideRemarks={true}
                            hideCloseReason={true}
                            hidePLVCRejectedReason={true}
                            hideUploadedPaymentReceipt={true}
                            hideUploadedPaymentVideo={false}
                            policyform={polForm!}
                            hideCopsExecutive={true}
                            policyDocs={policyDocs}
                        // hideCopsExecutive={false}
                        />
                    );
                    break;

                case INSURANCE_STATUS.PLVC_REJECTED:
                    // setShowUpdateButton(false);
                    return (
                        <PolicyIssued
                            hidePolicyIdNumber={true}
                            hidePolicyIssuanceDate={true}
                            hidePostponementDate={true}
                            hideTotalSumAssured={true}
                            hidePolicyTerm={true}
                            hidePremiumPaymentTerm={true}
                            hidePremiumFrequency={true}
                            hidePremiumAmount={true}
                            hideInsurerApplicationNo={true}
                            hidePaymentReceipt={true}
                            hidePaymentVideo={true}
                            hidePolicyBondReceivedDate={true}
                            hideCloseReason={true}
                            hideCopsExecutive={true}
                            hideUploadedPaymentReceipt={true}
                            hideUploadedPaymentVideo={false}
                            policyform={polForm!}
                            hideRemarks={false}
                            hidePLVCRejectedReason={false}
                            policyDocs={policyDocs}

                        />
                    );
                    break;

                case INSURANCE_STATUS.POLICY_BOND_RECEIVED:
                    // setShowUpdateButton(false);
                    return (
                        <PolicyIssued
                            hidePolicyIdNumber={true}
                            hidePolicyIssuanceDate={true}
                            hidePostponementDate={true}
                            hideTotalSumAssured={true}
                            hidePolicyTerm={true}
                            hidePremiumPaymentTerm={true}
                            hidePremiumFrequency={true}
                            hidePremiumAmount={true}
                            hideInsurerApplicationNo={true}
                            hidePaymentReceipt={true}
                            hidePaymentVideo={true}
                            hideRemarks={true}
                            hideCloseReason={true}
                            hidePLVCRejectedReason={true}
                            hideCopsExecutive={true}
                            hideUploadedPaymentReceipt={true}
                            hideUploadedPaymentVideo={false}
                            policyform={polForm!}
                            hidePolicyBondReceivedDate={false}
                            policyDocs={policyDocs}

                        />
                    );
                    break;

                case INSURANCE_STATUS.RENEW_POLICY:
                    // setShowUpdateButton(false);
                    return (
                        <PolicyIssued
                            hidePolicyIdNumber={true}
                            hidePolicyIssuanceDate={true}
                            hidePostponementDate={true}
                            hideTotalSumAssured={true}
                            hidePolicyTerm={true}
                            hidePremiumPaymentTerm={true}
                            hideInsurerApplicationNo={true}
                            hidePaymentReceipt={true}
                            hidePaymentVideo={true}
                            hidePolicyBondReceivedDate={true}
                            hideRemarks={true}
                            hideCloseReason={true}
                            hidePLVCRejectedReason={true}
                            hideCopsExecutive={true}
                            hideUploadedPaymentReceipt={true}
                            hideUploadedPaymentVideo={false}
                            policyform={polForm!}
                            hidePremiumFrequency={false}
                            hidePremiumAmount={false}
                            policyDocs={policyDocs}


                        />
                    );
                    break;
                case INSURANCE_STATUS.POLICY_LOGGED_IN:
                    // setShowUpdateButton(false);
                    return (
                        <PolicyIssued
                            hidePolicyIssuanceDate={true}
                            hidePostponementDate={true}
                            hideTotalSumAssured={true}
                            hidePolicyTerm={true}
                            hidePremiumPaymentTerm={true}
                            hidePremiumFrequency={true}
                            hidePremiumAmount={true}
                            hideInsurerApplicationNo={true}
                            hidePaymentReceipt={true}
                            hidePaymentVideo={true}
                            hidePolicyBondReceivedDate={true}
                            hideRemarks={true}
                            hideCloseReason={true}
                            hidePLVCRejectedReason={true}
                            hideCopsExecutive={true}
                            hideUploadedPaymentReceipt={true}
                            hideUploadedPaymentVideo={false}
                            policyform={polForm!}
                            hidePolicyIdNumber={false}
                            policyDocs={policyDocs}

                        />
                    );
                    break;

                case INSURANCE_STATUS.PAYMENT_DONE:
                    // setShowUpdateButton(false);
                    console.log("PAYMENT_DONE");
                    return (
                        <PolicyIssued
                            hidePolicyIdNumber={true}
                            hidePolicyIssuanceDate={true}
                            hidePostponementDate={true}
                            hideTotalSumAssured={true}
                            hidePolicyTerm={true}
                            hidePremiumPaymentTerm={true}
                            hidePremiumFrequency={true}
                            hidePremiumAmount={true}
                            hidePolicyBondReceivedDate={true}
                            hideRemarks={true}
                            hideCloseReason={true}
                            hidePLVCRejectedReason={true}
                            hideCopsExecutive={true}
                            policyform={polForm!}
                            hidePaymentVideo={true}
                            hideUploadedPaymentReceipt={true}
                            hideUploadedPaymentVideo={false}
                            hideInsurerApplicationNo={false}
                            hidePaymentReceipt={false}
                            policyDocs={policyDocs}

                        />
                    );
                    break;
                case INSURANCE_STATUS.PAYMENT_VERIFIED:
                    // setShowUpdateButton(false);
                    console.log("PAYMENT_VERIFIED");
                    return (
                        <PolicyIssued

                            hidePolicyIdNumber={true}
                            hidePolicyIssuanceDate={true}
                            hidePostponementDate={true}
                            hideTotalSumAssured={true}
                            hidePolicyTerm={true}
                            hidePremiumPaymentTerm={true}
                            hidePremiumFrequency={true}
                            hidePremiumAmount={true}
                            hidePolicyBondReceivedDate={true}
                            hideRemarks={true}
                            hideCloseReason={true}
                            hidePLVCRejectedReason={true}
                            hideCopsExecutive={true}
                            policyform={polForm!}
                            hidePaymentVideo={true}
                            hideUploadedPaymentVideo={true}
                            hideInsurerApplicationNo={true}
                            hidePaymentReceipt={true}
                            hideUploadedPaymentReceipt={false}
                            policyDocs={policyDocs}
                        />
                    );
                    break;
                case INSURANCE_STATUS.PLVC_ACCEPTED:
                    // setShowUpdateButton(false);
                    return (
                        <PolicyIssued
                            hidePolicyIdNumber={true}
                            hidePolicyIssuanceDate={true}
                            hidePostponementDate={true}
                            hideTotalSumAssured={true}
                            hidePolicyTerm={true}
                            hidePremiumPaymentTerm={true}
                            hidePremiumFrequency={true}
                            hidePremiumAmount={true}
                            hidePolicyBondReceivedDate={true}
                            hideRemarks={true}
                            hideCloseReason={true}
                            hidePLVCRejectedReason={true}
                            hideCopsExecutive={true}
                            policyform={polForm!}
                            hidePaymentReceipt={true}
                            hideInsurerApplicationNo={false}
                            hidePaymentVideo={true}
                            hideUploadedPaymentReceipt={true}
                            hideUploadedPaymentVideo={false}
                            policyDocs={policyDocs}
                        />
                    );
                    break;

                case INSURANCE_STATUS.POLICY_ISSUED:
                    // setShowUpdateButton(false);
                    return (
                        <PolicyIssued

                            hideInsurerApplicationNo={true}
                            hidePaymentReceipt={true}
                            hidePaymentVideo={true}
                            hidePolicyBondReceivedDate={true}
                            hideRemarks={true}
                            hideCloseReason={true}
                            hidePLVCRejectedReason={true}
                            hideCopsExecutive={true}
                            policyform={polForm!}
                            hidePolicyIdNumber={false}
                            hidePolicyIssuanceDate={false}
                            hidePostponementDate={true}
                            hidePolicyTerm={false}
                            hidePremiumPaymentTerm={false}
                            hidePremiumAmount={false}
                            hidePremiumFrequency={false}
                            hideTotalSumAssured={false}
                            hideUploadedPaymentReceipt={true}
                            hideUploadedPaymentVideo={false}
                            policyDocs={policyDocs}

                        />
                    );
                    break;

                default:
                    return (
                        <PolicyIssued
                            hidePolicyIdNumber={true}
                            hidePolicyIssuanceDate={true}
                            hidePostponementDate={true}
                            hideTotalSumAssured={true}
                            hidePolicyTerm={true}
                            hidePremiumPaymentTerm={true}
                            hidePremiumFrequency={true}
                            hidePremiumAmount={true}
                            hideInsurerApplicationNo={true}
                            hidePaymentReceipt={true}
                            hidePaymentVideo={true}
                            hidePolicyBondReceivedDate={true}
                            hideRemarks={true}
                            hideCloseReason={true}
                            hidePLVCRejectedReason={true}
                            hideCopsExecutive={true}
                            policyform={polForm!}
                            hideUploadedPaymentReceipt={true}
                            hideUploadedPaymentVideo={false}
                            policyDocs={policyDocs}
                        />
                    );
                    break;
                // setShowUpdateButton(true);
                // return <></>;
                // break;


            }

        } else {

            console.log(selectedValue);
            switch (selectedValue) {
                case INSURANCE_STATUS.CLOSED:
                    // setShowUpdateButton(false);

                    return (
                        <AppStatusUpdate applicationBean={applicationBean!} statusId={selectedValue!} hideCloseReason={false} hideEmail={false} hideRemarks={false} />
                    );
                    break;
                case INSURANCE_STATUS.SEND_EMAIL:
                case INSURANCE_STATUS.RESEND_EMAIL:
                    // setShowUpdateButton(false);

                    return (
                        <AppStatusUpdate applicationBean={applicationBean!} statusId={selectedValue!} hideCloseReason={true} hideRemarks={true} hideEmail={false} />
                    );
                    break;


                // setShowUpdateButton(false);


                default:
                    return (
                        <AppStatusUpdate applicationBean={applicationBean!} statusId={selectedValue!} hideCloseReason={true} hideRemarks={true} hideEmail={true} />
                    );
                    break;
            }


        }


    }


    const radioButtonGroup = useMemo(
        () => (


            <CustomRadioButton
                type="group"
                data={statusList}
                onIonChange={onIonChange}
                labelPlacement="end"
                justify="start"
            />

            // <CustomRadioButton
            //     type="group"
            //     data={data}
            //     onIonChange={onIonChange}
            //     labelPlacement="end"
            //     justify="start"
            // />
        ),
        [statusList]
    );

    const onUpdateClick = () => {


        let statusUpdateRequest = new StatusUpdateRequest();
        statusUpdateRequest.applicationId = applicationId;
        statusUpdateRequest.statusId = selectedValue!;

        //Remarks here

        const payload = statusUpdateRequest;
        statusUpdate.executeRequest("put", payload).then(() => {
            showToastAlert({ type: "success", message: (statusUpdate.responseData as any).message });
        });



        //   switch (selectedValue) {
        //     case POLICY_ISSUED:
        //       console.log("onProceedClick policy Issued Click", policyIssuedFormData);
        //       break;
        //     case CLOSED:
        //       console.log("onProceedClick CLosed form Click", closedFormData);
        //       break;
        //     case POLICY_LOGGED_IN:
        //       console.log(
        //         "onProceedClick policy logged in  form Click",
        //         policyLoggedInFormData
        //       );
        //     default:
        //       break;
        //   }
    };

    const onUpdatePolicyClick = () => {



        // let statusUpdateRequest = new StatusUpdateRequest();
        // statusUpdateRequest.applicationId = 43;
        // statusUpdateRequest.statusId = 24;

        // const payload = statusUpdateRequest;
        // statusUpdate.executeRequest("put", payload).then(() => {
        //     showToastAlert({ type: "success", message: statusUpdate.responseData?.message });
        // });



        //   switch (selectedValue) {
        //     case POLICY_ISSUED:
        //       console.log("onProceedClick policy Issued Click", policyIssuedFormData);
        //       break;
        //     case CLOSED:
        //       console.log("onProceedClick CLosed form Click", closedFormData);
        //       break;
        //     case POLICY_LOGGED_IN:
        //       console.log(
        //         "onProceedClick policy logged in  form Click",
        //         policyLoggedInFormData
        //       );
        //     default:
        //       break;
        //   }
    };


    return (
        <IonPage>
            <Header
                name="Update Stage"
                needCross={true}
                backButtonDisabled={false}
                backButton={true}
                needArrowOnClick={() => {
                    history.push("/lead-details");
                }}
            />
            <IonContent className="ion-padding">
                <>
                    <div className="pb-180">
                        <div className="">
                            <h6 className="fs-14 fw-bold">{t("Current Stage")}</h6>
                            <ul className="ps-3">
                                <li className="fs-16 fw-bold">{t(currentStatusLabel)}</li>
                            </ul>
                        </div>
                        <div className="mt-4">
                            <h6 className="fs-14 fw-bold">{t("Update Stage")}</h6>
                            <IonList>
                                {radioButtonGroup}
                                {selectedValue && renderResult()}
                            </IonList>

                            {showLoading && <IonCardTitle>...</IonCardTitle>}

                            <p className="px-3 d-none">
                                {t(
                                    "Use the Update Status In Bulk option to update status to different stages."
                                )}
                            </p>

                            {/* <div className="bottom-button-setter pb-8">
                                

                                    {(policyId===undefined) && <CustomButton
                                        onClick={onUpdateClick}
                                        className="mt-5"
                                        expand="block"
                                        title={t("Update")}

                                />} 


                                
                            </div> */}
                        </div>
                    </div>
                </>
            </IonContent>
        </IonPage>
    );
};

