/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { IonImg, isPlatform } from '@ionic/react';
import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import { useTranslation } from 'react-i18next';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardLeadCounts } from '../../../../store/reducers/user/userSlice';
import { eventAttributes, notifyHandler } from '../../../../helpers/functions';
import { NotifyVisitors } from 'capacitor-plugin-notifyvisitors';

export const MyLeads: React.FC = () => {
	const dispatch: any = useDispatch();
	const { t } = useTranslation();
	const [openModal, setOpenModal] = useState<false | true>(false);
	const myLeadsData = useSelector((state: any) => state?.users?.lead_count);
	const leadVideo = useSelector((state: any) => state?.users?.lead_video);
	useEffect(() => {
		getLeadCount();
	}, []);
	const getLeadCount = async () => {
		await dispatch(dashboardLeadCounts());
	};

	const handleVideoModal = () => {
		setOpenModal(true);
	};
	// Notify Visitors
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const userName = useSelector(
		(state: any) => state?.users?.homepage?.profile?.name
	);
	const userMobile = useSelector(
		(state: any) => state?.users?.user_details?.mobile
	);

	const entityType = useSelector(
		(state: any) => state?.users?.user_details?.entityType
	);
	const userLang = useSelector(
		(state: any) => state?.oneTimeScreen?.languag?.name
	);

	return (
		<section className='myleads-main bg-web-white border-md-radius-10 pt-md-3'>
			<div className='myleads'>
				<div className='info-row-one'>
					<div className=' d-flex justify-content-between'>
						<div className=''>
							<h3 className='fw-600 fs-16 m-0 darkest-grey'>{t('My Leads')}</h3>
						</div>
						<div>
							<h4
								className='fs-14 m-0 text-primary fw-600 cursor-pointer d-flex align-items-center align-items-md-start'
								onClick={handleVideoModal}
							>
								<IonImg
									className='me-2'
									src='/images/icons/small-play-logo.svg'
									alt='play'
								/>
								{t('How to Add Leads')}
							</h4>
						</div>
					</div>
					<p className='fs-14 m-0 pt-2 text-grey'>
						{t('Your leads summary this month')}
					</p>
				</div>
				<div className='info-row-two d-flex pt-2'>
					<div className='leads-total text-center flex-grow-1'>
						<h3 className='m-0 fs-14 title d-flex align-items-center justify-content-center justify-content-md-start gap-2 darkest-grey fw-600'>
							<IonImg
								src='/images/icons/total-lead-bullet-icon.svg'
								style={{ height: '8px', width: '8px' }}
							/>
							{t('Leads')}
						</h3>
						<h4 className='fw-bold m-0 fs-24 text-center text-md-start'>
							{myLeadsData?.total_count | 0}
						</h4>
					</div>
					<div className='leads-total text-center flex-grow-1'>
						<h3 className='m-0 fs-14 title d-flex align-items-center justify-content-center justify-content-md-start gap-2 darkest-grey fw-600'>
							<IonImg
								src='/images/icons/in-progress-lead-bullet-icon.svg'
								style={{ height: '8px', width: '8px' }}
							/>
							{t('In Progress')}
						</h3>
						<h4 className='fw-bold m-0 fs-24 text-center text-md-start'>
							{myLeadsData?.total_progress_count | 0}
						</h4>
					</div>
					<div className='leads-total text-center flex-grow-1'>
						<h3 className='m-0 fs-14 title d-flex align-items-center  justify-content-center justify-content-md-start gap-2 darkest-grey fw-600'>
							<IonImg
								src='/images/icons/closed-lead-bullet-icon.svg'
								style={{ height: '8px', width: '8px' }}
							/>
							{t('Closed')}
						</h3>
						<h4 className='fw-bold m-0 fs-24 text-center text-md-start'>
							{myLeadsData?.total_closed_count | 0}
						</h4>
					</div>
				</div>
				<CustomButton
					routerLink={'/dashboard/lead'}
					expand='block'
					className='btn-blue fw-600 button-width'
					fill='outline'
					title={t('View Leads')}
					style={{ marginTop: '16px' }}
					onClick={() => {
						const userAttributes = {
							attributes: {
								name: userName,
								id: userId,
								mobile: userMobile,
								user_language: userLang,
								entityType:
									entityType === 'individual'
										? 'DMA'
										: entityType === 'BTM'
										? 'employee'
										: entityType,
							},
						};
						if (isPlatform('android') || isPlatform('ios')) {
							notifyHandler('event', eventAttributes('View_Leads'));
							notifyHandler('user', userAttributes.attributes);
							const inAppData = {
								tokens: { name: userAttributes },
								customRules: {
									banerrType: 'view leads',
								},
							};
							NotifyVisitors.show(inAppData);
						} else {
							(window as any).nv('event', 'View_Leads', 10, 1);
							(window as any).nv('user', userId, userAttributes.attributes);
						}
					}}
				/>
			</div>
			<CustomModal
				id='custommodal'
				modalTitle=''
				initialBreakpoint={undefined}
				className='videoplayerpopup vertical-player'
				isOpen={openModal}
				handleClose={() => setOpenModal(false)}
				breakpoints={[]}
				modalContent={
					<iframe
						src={leadVideo}
						width='100%'
						height='100%'
						frameBorder='0'
						allow='autoplay; fullscreen; picture-in-picture'
						allowFullScreen
					></iframe>
				}
				needCross={false}
				needArrow={false}
				needIonContentWraper={false}
			/>
		</section>
	);
};
