import React, { useEffect, useState } from 'react';
import { Header } from '../../components/layout/header/Header';
import {
	IonContent,
	IonItem,
	IonList,
	IonRadioGroup,
	IonRadio,
	IonPage,
} from '@ionic/react';
import './UpdateStatus.scss';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import CustomNote from '../../components/common/custom-note/CustomNote';
import '../../components/common/custom-input/CustomInput.scss';
import './UpdateStatus.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	getMasterDispositionsUpdateStage,
	leadFlow,
	markAsClose,
} from '../../store/reducers/lead/leadSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { LEAD_STATUS } from '../../helpers/constant';
import { findCurrentStatus } from '../../helpers/functions';
import { Divider } from '../../components/common/divider/Divider';

const data = [
	{
		id: LEAD_STATUS.LOGIN,
		name: LEAD_STATUS.LOGIN_LABEL,
		disabled: true,
	},
	{
		id: LEAD_STATUS.SANCTIONED,
		name: LEAD_STATUS.SANCTIONED_LABEL,
		disabled: true,
	},
	{
		id: LEAD_STATUS.DISBURSED,
		name: LEAD_STATUS.DISBURSED_LABEL,
		disabled: true,
	},
	{
		id: LEAD_STATUS.CLOSED,
		name: LEAD_STATUS.CLOSED_LABEL,
		disabled: false,
	},
];

export const UpdateStatus: React.FC = () => {
	const [stage, setStage] = useState(null);
	const [stagesRadioButton, setStagesRadioButton] = useState(data);
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const leadFlowSelector = useSelector((state: any) => state?.lead);
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	const bulkIds = leadFlowSelector?.leadFlowData?.getLeadAllSelectedIds;
	const appsToUpdate = leadFlowSelector?.leadFlowData?.appsToUpdate;
	const bulkLenderIdes = leadFlowSelector?.selectedLender?.map(
		({ lender_id }: any) => lender_id
	);
	const leadStatus = useSelector(
		(state: any) => state?.lead?.leadFlowData?.applicationStatus
	);

	useEffect(() => {
		if (location.pathname !== '/update-status') {
			return;
		}
		getBulkUpdateLead();
	}, [leadInfo.update, location.pathname]);

	const getBulkUpdateLead = async () => {
		const isApplicationsCard =
			leadInfo?.leadStatus === LEAD_STATUS.FULFILLMENT_LABEL ||
			leadInfo?.leadStatus === LEAD_STATUS.LOGIN_LABEL ||
			leadInfo?.leadStatus === LEAD_STATUS.SANCTIONED_LABEL ||
			leadInfo?.leadStatus === LEAD_STATUS.SELECTION ||
			leadInfo?.leadStatus === LEAD_STATUS.SELECTION_LABEL;

		await dispatch(getMasterDispositionsUpdateStage())
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					const updateData = data.map((item: any) => {
						const statusName = findCurrentStatus(leadStatus).label;
						const disbledStatus =
							statusName === 'Sanctioned'
								? item.name === LEAD_STATUS.LOGIN_LABEL ||
								  item.name === LEAD_STATUS.SANCTIONED_LABEL
								: statusName === 'Login'
								? item.name === LEAD_STATUS.LOGIN_LABEL
								: statusName === LEAD_STATUS.FULFILLMENT_LABEL
								? false
								: true;
						const isMarkAsClosed = leadFlowSelector?.isMarkAsClose;
						if (item.name !== LEAD_STATUS.CLOSED_LABEL) {
							return {
								...item,
								disabled: isMarkAsClosed ? true : disbledStatus,
							};
						} else {
							return {
								...item,
							};
						}
					});
					setStagesRadioButton([
						...updateData,
						{
							id: response?.callback?.[0].id,
							name: response?.callback?.[0].value,
							disabled:
								isApplicationsCard &&
								(appsToUpdate?.length > 1 || bulkLenderIdes?.length > 1)
									? true
									: bulkIds?.length > 1
									? true
									: false,
						},
					]);
				}
			});
	};

	const dmaSelector = useSelector((state: any) => state?.users);

	const handleBackBtn = () => {
		const myPromise = new Promise(function (myResolve) {
			setTimeout(function () {
				let path = '';
				if (leadInfo?.isBulk) {
					path = '/lead-list';
				} else if (
					leadFlowSelector?.leadFlowData?.applicationStatus ===
					LEAD_STATUS.DISBURSED
				) {
					path = '/lender-specific-info';
				} else if (leadInfo?.iSBackFromDsburseAndSanction) {
					path = '/bank-visit-site';
				} else if (leadInfo?.from === 'nimbuss') {
					path =
						'/lead-details/' +
						leadInfo.leadId +
						'/' +
						dmaSelector?.user_details?.access_token;
				} else {
					path = '/lead-details';
				}
				myResolve(history.push(path));
			}, 10);
		});
		myPromise.then(() => {
			dispatch(
				leadFlow({
					...leadInfo,
					leadStatus: leadInfo?.isAllLead
						? 'all'
						: leadInfo?.isRawLead
						? LEAD_STATUS.RAW
						: leadInfo?.leadStatus,
					update: leadInfo?.update + 1 || 1,
				})
			);
			dispatch(markAsClose(false));
		});
	};

	return (
		<IonPage>
			<Header
				name={t('Update Stage')}
				needArrow={true}
				needArrowOnClick={() => {
					handleBackBtn();
				}}
				backButton={true}
			/>
			<IonContent className=''>
				<div
					className=' bg-web py-md-3'
					style={{ minHeight: '100%' }}
				>
					<div className='container-width-md conatiner-spacing-md py-3 bg-web-white border-md-radius-10'>
						<CustomNote
							{...{
								header: t('Note'),
								content: t(
									'Use the Update Status In Bulk option to update status to different stages'
								),
							}}
						/>
						<div>
							<div className='d-flex justify-content-between align-items-center mb-4 mt-3'>
								<h6
									className='fs-14 fw-bold mb-0'
									style={{ color: 'var(--text-dark-grey)' }}
								>
									{t('Current Stage')}
								</h6>
								{leadFlowSelector?.selectedLender?.length <= 1 && (
									<CustomButton
										// onClick={() => setShowRemarkModal(true)}
										className={'btn-blue fw-600'}
										fill='outline'
										style={{ height: '24px' }}
										title={t(
											findCurrentStatus(
												leadFlowSelector?.leadFlowData?.applicationStatus
													? leadFlowSelector?.leadFlowData?.applicationStatus
													: leadFlowSelector?.leadFlowData?.leadStatus ===
													  LEAD_STATUS.ALL
													? leadFlowSelector?.leadFlowData?.lead_status
													: leadFlowSelector?.leadFlowData?.leadStatus
											).label
										)}
									/>
								)}
							</div>
							<Divider />
							{!leadInfo?.addDisposition && (
								<div className='mt-4'>
									<h6
										className='fs-14 fw-bold'
										style={{ color: 'var(--text-dark-grey)' }}
									>
										{t('Update Stage')}
									</h6>
									<IonList>
										<IonRadioGroup
											name='stage'
											value={stage}
											onIonChange={({ detail: { value } }: any) => {
												setStage(value.toString());
												if (
													(value !== LEAD_STATUS.CLOSED &&
														value !== LEAD_STATUS.CALLBACKFOLLOWUP &&
														leadStatus == LEAD_STATUS.FULFILLMENT) ||
													value == LEAD_STATUS.SANCTIONED ||
													value == LEAD_STATUS.DISBURSED ||
													value === LEAD_STATUS.LOGIN
												) {
													const myPromise = new Promise(function (myResolve) {
														setTimeout(function () {
															myResolve(
																history.push('/disbursement-details-form')
															);
														}, 100);
													});
													myPromise.then(() => {
														dispatch(
															leadFlow({
																...leadInfo,
																applicationStatus:
																	findCurrentStatus(value).label,
																update: leadInfo?.update + 1 || 1,
															})
														);
														setStage(null);
													});
												}

												if (
													value === LEAD_STATUS.CLOSED ||
													value === LEAD_STATUS.CALLBACKFOLLOWUP
												) {
													const myPromise = new Promise(function (myResolve) {
														setTimeout(function () {
															myResolve(
																history.push('/close-and-disposition-form')
															);
														}, 100);
													});
													myPromise.then(() => {
														dispatch(
															leadFlow({
																...leadInfo,
																isClosedStage: value.toString(),
																update: leadInfo?.update + 1 || 1,
															})
														);
														setStage(null);
													});
												}
											}}
										>
											{stagesRadioButton &&
												stagesRadioButton?.map((item, index) => {
													return (
														<React.Fragment
															key={`stages-radio-button-${index}`}
														>
															{item.id.toString() ===
															LEAD_STATUS.CALLBACKFOLLOWUP ? (
																<>
																	<h3
																		className='fs-16 fw-bold py-2 m-0'
																		style={{ color: 'var(--text-dark-grey)' }}
																	>
																		{t('Disposition')}
																	</h3>
																	<IonItem
																		key={index}
																		lines='none'
																		className='ion-no-padding'
																	>
																		<IonRadio
																			disabled={item.disabled}
																			justify='start'
																			labelPlacement='end'
																			value={item?.id?.toString()}
																			aria-label={item?.name}
																		>
																			{t(item?.name)}
																		</IonRadio>
																	</IonItem>
																</>
															) : (
																<IonItem
																	key={index}
																	lines='full'
																	className='ion-no-padding'
																>
																	<IonRadio
																		disabled={item.disabled}
																		justify='start'
																		labelPlacement='end'
																		value={item?.id?.toString()}
																		aria-label={item?.name}
																	>
																		{t(item?.name)}
																	</IonRadio>
																</IonItem>
															)}
														</React.Fragment>
													);
												})}
										</IonRadioGroup>
									</IonList>
									<p className='px-3 d-none'>
										{t(`To update status to any different stage please use bulk status
                upload.`)}
									</p>
								</div>
							)}
						</div>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};
