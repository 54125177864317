import './ProgressHalfCircle.scss';
import { useTranslation } from 'react-i18next';

function ProgressHalfCircle({ score, scoreStroke, scoreStrokeBg }: any) {
	const radius = 50; // Radius of the half circle
	const circumference = 2 * Math.PI * radius; // Circumference of the half circle
	//percentage calculate based on score
	const percentage = ((score / 900) * 100) / 2;
	// Calculate the stroke-dashoffset to represent the progress
	const strokeDashoffset = circumference - (percentage / 100) * circumference;
	const { t } = useTranslation();

	return (
		<div
			style={{
				position: 'relative',
				display: 'flex',
				justifyContent: 'center',
			}}
		>
			<svg
				className='half-circle-progress'
				viewBox='0 -15 100 60'
			>
				<path
					fill='none'
					strokeWidth='14px'
					stroke={scoreStrokeBg || '#f3f3f3'}
					d={`M 0 50 A 50 50 0 0 1 100 50`}
				/>
				{/* filling stroke */}
				<path
					stroke={scoreStroke}
					fill='none'
					strokeWidth='14px'
					d={`M 0 50 A 50 50 0 0 1 100 50`}
					style={{
						strokeDasharray: circumference,
						strokeDashoffset: strokeDashoffset,
						transition: 'stroke-dashoffset 0.5s ease',
					}}
				/>
			</svg>
			<div
				// className='m-0 fs-14 text-black text-center py-3 '
				style={{
					position: 'absolute',
					top: '63%',
					left: '50%',
					transform: 'translate(-50%,-50%)',
				}}
				className='fs-14'
			>
				{t('Your Score')}
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					position: 'absolute',
					bottom: '-5px',
					width: '300px',
					alignItems: 'end',
				}}
			>
				<div className='fs-14'>300</div>
				<div className='fs-24'>{score}</div>
				<div className='fs-14'>900</div>
			</div>
		</div>
	);
}

export default ProgressHalfCircle;
