// import { apiService } from '../store/apiService';

export const TOKEN_NAME = 'oocrmt';
export const ROLE = 'role';
export const LANGUAGE = 'ooal';
export const UUID = 'uuid';
export const APP_VERSION = '1.0.0.83';

export const STATUS_CODE = {
	SIGNIN_SUCCESS: 'SIGNIN_SUCCESS',
	SUCCESS: 'SUCCESS',
	VERIFICATION_SUCCESSFUL: 'VERIFICATION_SUCCESSFUL',
};

export const ONBOARDING_STEP = {
	REGISTER: 'register',
	DASHBOARD: 'dashboard',
};

export const LEAD_STATUS = {
	BUREAU_LABEL: 'Bureau',
	GENERATION: 'Generation',
	SANCTIONED_LABEL: 'Sanctioned',
	PROCESSING_LABEL: 'Processing',
	FULFILLMENT_LABEL: 'Application Fulfillment',
	CLOSED_LABEL: 'Closed',
	SELECTION_LABEL: 'Lender Selection',
	DISBURSED_LABEL: 'Disbursed',
	LOGIN_LABEL: 'Login',
	UNDERPROCESS_LABEL: 'Under Process',
	PARTIALDISBURSED_LABEL: 'Partially Disbursed',
	RAW_LABEL: 'Raw Leads',
	GENERATED_LABEL: 'Leads Generated',
	APPLICATION_CLOSED_LABEL: 'Closed',
	ALL: 'all',
	GENERATED: '1',
	BUREAU: '10',
	PROPOSERDETAIL: '21',
	BASICDETAILSOFLIFEASSURED: '22',
	OCCUPATIONDETAILSOFASSURED: '23',
	DOCUMENTUPLOADED: '24',
	MOBILEVERIFICATION: '25',
	PROCESSING: '30',
	SELECTION: '40',
	CLOSED: '90',
	LOGIN: '50',
	SANCTIONED: '51',
	DISBURSED: '52',
	APPLICATION_CLOSED: '53',
	UNDERPROCESS: '60',
	PARTIALDISBURSED: '54',
	CALLBACKFOLLOWUP: '4',
	CALLBACKUPDATESTAGE: '91',
	FULFILLMENT: '70',
	RAW: '0',
	ALLOCATOR: '110',
	APPLICATION_FULFILLED: 'Application Fulfillment',
	BASIC_DETAILS_OF_LIFE_ASSURED: 'Basic Details Of Lifeassured',
	OCCUPATION_DETAILS_OF_ASSURED: 'Occupation Details Of Assured',
	DOCUMENTUPLOADED_LABEL: 'Document Uploaded',
	MOBILEVERIFICATION_LABEL: 'Mobile Verification',
	PROPOSERDETAIL_LABEL: 'Proposer Detail',
	RAW_LEAD_LABEL: 'Raw Leads',
	ALLOCATOR_LABEL: 'Allocate Leads',
	CREDIT_BUREAU: 'CUSTOMER',
};

export const disbursementType = {
	PARTIAL: 'PARTIAL',
	FULL: 'FULL',
};

export const lenderApplicationType = {
	ONLINE: 'Online',
	OFFLINE: 'Offline',
};

export const BUREAU_SCORE_TABS = {
	EQUIFAX: 'Equifax',
	CIBIL: 'CIBIL',
};

export const CREDIT_SCORE_IMPACTS = {
	HIGH_IMPACT: 'High Impact',
	MEDIUM_IMPACT: 'Medium Impact',
	LOW_IMPACT: 'Low Impact',
};

export const CREDIT_SCORE_SCALE_RATING = {
	EXCELLENT: 'excellent',
	GOOD: 'good',
	AVERAGE: 'average',
	POOR: 'poor',
};

export const PRODUCTS_TYPE = {
	LOAN: 'Loan',
	INSURANCE: 'Insurance',
};

export const PAGINATION_DETAILS = {
	PAGE_SIZE: 10,
	CURRENT_PAGE: 1,
};

export const REGEX = {
	INDIAN_MOBILE_NUMBERS: /^[6-9]{1}[0-9]{9}$/,
	PAN: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
	IFSC: /^[A-Za-z]{4}[A-Za-z0-9]{7}$/,
	ALLOWONLYCHAR: /^[a-zA-Z]+$/,
	ACCOUNTNO: /^[a-zA-Z0-9]+$/,
	EMAIL:
		/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-zA-Z]{2,6}(?:\.[a-zA-Z]{2})?)+$/,
	AADHAAR: /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
	// FULLNAME: /^[a-zA-Z]/,
	FULLNAME: /^[a-zA-Z ]*$/,
	FULLNAME_WITH_DOT: /^[a-zA-Z .]*$/,
	GST: /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}$/,
	UPPERCASE: /^[A-Z \d\W]+$/,
	ALLOWCHARANDNUMBER: /^([a-zA-Z0-9*)\s+([a-zA-Z0-9 ]*)$/,
	PINCODE: /^[0-9]{6}$/,
	ALLOWNUMBERS: /^\d+$/,
};
export const TEAMS_TABS = {
	DMA: 'DMA',
	ANDROTEAM: 'ANDROTEAM',
};
export const TIMEOUTMINS = 9;
export const OTPTIMER = 60;
export const FILE_FORMAT_VALIDATON_MESSAGE = 'Please upload a csv file only!';

export const StatusNum = {
	INIT: '1',
	CL1: '2',
	CL2: '3',
	CL3: '4',
	KYC: '10',
	KYC1: '11',
	KYC2: '12',
	KYC3: '13',
	KYC4: '14',
	PAYOUT: '20',
	PAYOUTAPPROVAL: '21',
	PAYOUTRECOMENDED: '22',
	REVISED: '23',
	PAYOUTAPPROVED: '24',
	PARENTCHILD: '30',
	VERIFICATION: '40',
	DMAULACTIVE: '120',
	REWORK: '41',
	REWORKCOMPLETED: '42',
	VERIFICATION1: '43',
	VERIFICATION2: '44',
	REWORKVERIFICATION1: '46',
	REWORKVERIFICATION2: '47',
	APPROVED: '45',
	EXISTINGAPPROVAL: '60',
	REVISEDAPPROVAL: '61',
	EXISTINGAPPROVAL2: '65',
	REVISEDAPPROVAL2: '66',
	INVITED: '70',
	ACTIVE: '80',
	DEACTIVATE: '90',
	NEWAPPROVAL: '50',
	REJECTED: '91',
	UNDERPROCESS: '100',
	UNDERPROCESSCT: '101',
	ALLOCATOR: '110',
};

export const USER_ROLES = {
	USER: 'user',
	ADMIN: 'admin',
	BUHOD: 'BUHOD',
	BUH: 'BUH',
	BUSINESSOPS: 'BOPS',
	BM: 'BM',
	CENTRALOPS: 'COPS',
	CENTRALOPSJR: 'COPSJR',
	TC: 'TC',
	DMA: 'DMA',
	DMAUL: 'DMAUL',
};

export const LeadStatus = {
	GENERATED: '1',
	BUREAU: '10',
	PROPOSERDETAIL: '21',
	BASICDETAILSOFLIFEASSURED: '22',
	OCCUPATIONDETAILSOFASSURED: '23',
	DOCUMENTUPLOADED: '24',
	MOBILEVERIFICATION: '25',
	PROCESSING: '30',
	SELECTION: '40',
	CLOSED: '90',
	LOGIN: '50',
	SANCTIONED: '51',
	DISBURSED: '52',
	UNDERPROCESS: '60',
	PARTIALDISBURSED: '54',
	CALLBACKFOLLOWUP: '4',
	CALLBACKUPDATESTAGE: '91',
	APPLICATIONSFULFILLED: '70',
	RAW: '0',
	ALLOCATOR: '110',
};

export const LeadSourceEnum = {
	MANUAL: '1',
	BULK: '2',
	CAMPAIGN: '3',
};

export const LEADS_TYPE = {
	LOAN: 'Loan',
	LOANS: 'Loans',
	INSURANCE: 'Insurance',
	Cards: 'Cards',
	REAL_ESTATE: 'Real Estate',
};

//Professional Info Fields Amount Keys
export const amountKeys = [
	'monthly_income',
	'annual_income',
	'net_profit',
	'turnover',
	'co_applicant_monthly_income',
	'net_monthly_income',
	'market_value',
	'annual_turnover',
	'agreement_value',
	'existing_loan_outstanding',
];

export const professionalEquifaxKeys = [
	'total_emi_amount',
	'credit_card_outstanding_amount',
];

export const INAPPCONFIG = 'hidenavigationbuttons=yes,hideurlbar=yes';

// export const get = apiService.GET;

// export const post = apiService.POST;

// export const put = apiService.PUT;

export const LEAD_SCORE_TYPE = {
	CBIL: 'Cibil',
	EQUIFAX: 'Equifax',
	BOTH: 'Both',
};

export const THEME_COLOR = {
	'#FFFFFF': '#F3F3F3',
	'#A1C0EF': '#D4E2F8',
	'#D0E9C8': '#E7FCE5',
	'#FFE0A7': '#FFF4DB',
	'#C0DBD5': '#DEFFF8',
};

export const ACLKeys = {
	CHECK_BUREAU: 1,
	SHARE_CHECK_BUREAU: 2,
	ADD_NEW_LEAD: 3,
	SHARE_LEAD: 4,
	RAW_BULK_UPLOAD: 5,
	DISBURSAL_BULK_UPLOAD: 6,
	CREATE_EMPLOYEE: 7,
	EXPORT_REPORT: 8,
	CREATE_DMA: 9,
	INVITE_DMA: 10,
};


export const gradientArray = [
	'linear-gradient(180deg, rgba(237,139,137,1) 0%, rgba(255,255,255,1) 100%)',
	'linear-gradient(180deg, rgba(136,181,253,1) 0%, rgba(255,255,255,1) 100%)',
	'linear-gradient(180deg, rgba(173,234,163,1) 0%, rgba(255,255,255,1) 100%)',
	'linear-gradient(180deg, rgba(251,233,196,1) 0%, rgba(255,255,255,1) 100%)',
];

export const PRODUCTS_ID = {
	INSURANCE: 2,
};

export const INSURANCE_TYPE = {
	LIFE_TERM_LIFE_INSURANCE:48,
	LIFE_ENDOWMENT:49,
	LIFE_UNIT_LINKED:50,
	LIFE_MONEYBACK:51,
	LIFE_GUARNTEED_RETURN:52,
	LIFE_PENSION:53,
	HEALTH_INDIVIDUAL: 54,
	HEALTH_FAMILY: 55,
	GENERAL_VEHICLE: 57
};

export const INSURANCE_POLICY_PROPOSED_FOR = {
	SELF: 'self',
};

export const INSURANCE_DOCUMENT_TYPE = {
	PASSPORT_SIZE_PHOTO:1,
	PAN:2,
	AADHAR_FRONT:3,
	AADHAR_BACK:4,
	INCOME_PROOF:5,
	INCOME_SURROGATES:6,
	CANCELLED_CHEQUE: 7,
	RC: 10,
	DRIVING_LICENCE: 11,
	EXISTING_POLICY: 12,
	OTHERS: 8,
	PAYMENT_RECIEPT: 13,
	PAYMENT_VIDEO: 14,

};



// '1','Draft','Draft','1'
// '2','Authorization Pending','Authorization Pending','2'
// '3','Closed','Closed','3'
// '4','Application Fulfillment','Application Fulfillment','4'
// '5','Allocated To DMA','Allocated To DMA','5'
// '6','Under Process','Under Process','6'
// '7','Approved','Approved','7'
// '8','Rejected','Rejected','8'
// '9','Resent To IFA','Resent To IFA','9'
// '10','Insurer Selection','Insurer Selection','10'
// '11','Generation','Generation','11'
// '12','Sanctioned','Sanctioned','12'
// '13','Login','Login','13'
// '14','Payment Pending','Payment Pending','14'
// '15','Payment Done','Payment Done','15'
// '16','PLVC Accepted','PLVC Accepted','16'
// '17','PLVC Rejected','PLVC Rejected','17'
// '18','Allocated To COPS Head','Allocated To COPS Head','18'
// '19','Due For Renewal','Due For Renewal','19'
// '20','Renew Policy','Renew Policy','20'
// '21','Allocate PLVC Central Ops Executive','Allocate PLVC Central Ops Executive','21'
// '22','ReAllocate PLVC Central Ops Executive','ReAllocate PLVC Central Ops Executive','22'
// '23','Initiate PLVC','Initiate PLVC','23'
// '24','Policy Bond Recieved','Policy Bond Recieved','24'
// '25','Policy Issue Pending','Policy Issue Pending','25'
// '26','Policy Logged In','Policy Logged In','26'
// '27','Policy Issued','Policy Issued','27'
// '28','Send Email','Send Email','28'
// '29','Policy Issued','Policy Issued','29'


export const INSURANCE_STATUS = {
	DRAFT:1,
	INSURER_SELECTION:10,
	AUTHORIZATION_PENDING:2,
	APPLICATION_FULFILLMENT:4,
	CLOSED:3,
	ALLOCATED_TO_DMA:5,
	APPROVED:7,
	REJECTED:8,
	RESENT_TO_IFA:9,
	ALLOCATE_PLVC_TO_CENTRAL_OPS_EXECUTIVE:21,
	INITIATE_PLVC:23,
	INITIATE_PLVC_COPS:41,
	SEND_EMAIL: 28,
	RESEND_EMAIL: 30,
	REALLOCATE_PLVC_TO_CENTRAL_OPS_EXECUTIVE:22,
	PLVC_REJECTED:17,
	POLICY_BOND_RECEIVED:24,
	RENEW_POLICY:20,
	POLICY_LOGGED_IN:26,
	PAYMENT_PENDING:14,
	PAYMENT_DONE:15,
	PAYMENT_VERIFIED:31,
	PLVC_ACCEPTED:16,
	POSTPONED:40,
	POLICY_ISSUED:27,
};

export const ADDRESS_TYPE = {
	PERMANENT:'permanent',
	CURRENT:'current',
	
};


export const REDIRECT = {
	// DASHBOARD:'/dashboard/home',
	DASHBOARD:'/dashboard/lead',
	DEFAULT:'/app_details_temp',
	
};

