/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	IonContent,
	IonLabel,
	IonPage,
	IonRadio,
	IonRadioGroup,
	IonSegment,
	IonSegmentButton,
	useIonViewDidEnter,
} from '@ionic/react';

import React, { useEffect, useRef, useState } from 'react';
import { Header } from '../../components/layout/header/Header';
import './DMADetails.scss';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
import { CustomCheckbox } from '../../components/common/custom-checkbox/CustomCheckbox';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
// import { AppContext } from '../../helpers/app-context';
import { useHistory, useLocation } from 'react-router';
// import DropDownWitoutModal from '../../components/common/custom-dropdown/DropDownWitoutModal';
import CustomDropDown from '../../components/common/custom-dropdown/CustomDropDown';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	createPayoutApi,
	dmaFlowData,
	managerList,
	subProductsList,
} from '../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	disableNumberArrows,
	ionContentScrollToTop,
	showToastAlert,
} from '../../helpers/functions';
import { StatusNum } from '../../helpers/constant';

export const ProposedPayout: React.FC = () => {
	const dmaSelector = useSelector((state: any) => state?.users);
	const [managers, setManagers] = useState<any>([]);
	const [subProducts, setSubProductss] = useState<any>([]);
	const [selectAll, setSelectAll] = useState<boolean>(false);
	const [isFlatPayout, setIsFlatPayout] = useState<boolean>(true);
	const [flatValue, setFlatValue] = useState<any>('');
	const [error, setError] = useState(true);
	const dispatch: any = useDispatch();
	const { t } = useTranslation();
	const [newpayout, setNewPayout] = useState<any>([]);

	const history = useHistory();
	const location: any = useLocation();
	// eslint-disable-next-line prefer-const
	let [selectedSubProducts, setSelectedSubProducts] = useState<any>([]);

	const contentRef = useRef<HTMLIonContentElement | null>(null);
	useIonViewDidEnter(() => {
		ionContentScrollToTop(contentRef);
	});

	// Managers Get APi and Data Creation
	useEffect(() => {
		if (location.pathname === '/proposed-payout') {
			getManagerList();
			getSubProductList();
		}
	}, [location.pathname]);
	const getManagerList = async () => {
		await dispatch(managerList())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setManagers(result?.list);
				}
			});
	};

	// get subproduct list

	const getSubProductList = async () => {
		await dispatch(subProductsList())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setSubProductss(result?.list);
				}
			});
	};

	// Create Payout Flow

	/* const createPayout=async()=>{} */

	const handleBulkSelect = (e: any) => {
		if (e.detail.checked) {
			const newdata = subProducts.map((el: any) => {
				const findIndex = selectedSubProducts.findIndex(
					(e: any) => e.sub_product_id === el?.id
				);
				return {
					sub_product_id: el?.id,
					percentage: flatValue
						? Number(flatValue)
						: selectedSubProducts[findIndex]?.percentage
						? selectedSubProducts[findIndex]?.percentage
						: 0,
				};
			});
			setSelectedSubProducts(newdata);
		} else {
			setSelectedSubProducts([]);
			setError(true);
		}
	};
	const checkIfFlatPayoutChanged = (e: any) => {
		if (e !== isFlatPayout) {
			setFlatValue('');
			const a = selectedSubProducts?.map((el: any) => {
				return {
					sub_product_id: el?.sub_product_id,
					percentage: 0,
				};
			});
			setSelectedSubProducts(a);
		}
	};
	useEffect(() => {
		if (isFlatPayout) {
			if (flatValue === '') {
				setError(true);
			}
		}
		const getId: any = managers
			?.filter((el: any) => el?.checked)
			?.map(({ id }: any) => id);
		for (let i = 0; i < selectedSubProducts?.length; i++) {
			if (
				selectedSubProducts[i].percentage === undefined ||
				selectedSubProducts[i].percentage === '' ||
				selectedSubProducts[i].percentage === 0
			) {
				setError(true);
				break;
			} else {
				setError(false);
			}
		}

		if (getId.length <= 0) {
			setError(true);
		}
		if (selectedSubProducts?.length === 0) {
			setError(true);
		}
	}, [selectedSubProducts, flatValue, managers, selectAll, isFlatPayout]);

	const setHandleThresHoldValue = (val: any) => {
		if (selectedSubProducts.length <= 0) {
			showToastAlert({
				type: 'error',
				message: `Please select Products First To Enter Payout Percentage`,
			});
		}

		selectedSubProducts.forEach((item: any, index: any) => {
			selectedSubProducts[index].percentage = val;
		});
	};

	const handleParticularProduct = (e: any, id: any) => {
		if (e.detail.checked) {
			const findProduct = subProducts?.filter((el: any) => el.id === id);
			const newProductData: any = [];
			newProductData.push(...selectedSubProducts, {
				sub_product_id: findProduct[0]?.id,
				percentage: flatValue ? Number(flatValue) : '',
			});
			setSelectedSubProducts(newProductData);
		} else {
			const data = selectedSubProducts?.filter(
				(i: any) => i.sub_product_id !== id
			);
			setSelectedSubProducts(data);
		}
	};
	const handleParticalProductPayout = (e: any, id: any) => {
		if (selectedSubProducts.length <= 0) {
			showToastAlert({
				type: 'error',
				message: `Please select Products First To Enter Payout Percentage`,
			});
		}

		const findIndex = selectedSubProducts.findIndex(
			(el: any) => el.sub_product_id === id
		);
		const data: any = selectedSubProducts;
		const row = data[findIndex];
		row.percentage = e.target.value;

		setSelectedSubProducts([...data]);
	};
	useEffect(() => {
		const ids = selectedSubProducts.map((t: any) => {
			return t?.sub_product_id;
		});
		const all = subProducts?.filter((g: any) => !ids?.includes(g.id));
		const pendingOne = all.map((item: any) => {
			return {
				sub_product_id: item?.id,
				percentage: 0,
			};
		});
		const newData = selectedSubProducts.concat(pendingOne).map((item: any) => {
			return item;
		});
		setNewPayout(newData);
	}, [selectedSubProducts, isFlatPayout, flatValue]);
	const createPayout = async () => {
		const getId = managers
			?.filter((el: any) => el?.checked)
			?.map(({ id }: any) => id);

		await dispatch(
			createPayoutApi({
				param: location?.state?.existingDmaId
					? location?.state?.existingDmaId
					: dmaSelector?.dmaFlow?.dmaId,
				data: {
					subproduct: newpayout,
					owner_id: Number(getId?.toString()),
					commission_type: isFlatPayout ? 'fixed' : 'variable',
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setTimeout(() => {
						history.replace('/team-list');
						setManagers([]);
						setSelectedSubProducts([]);
						setSubProductss([]);
						setTimeout(() => {
							dispatch(
								dmaFlowData({
									...dmaSelector?.dmaFlow,
									dmaCode: StatusNum.PAYOUT,
									update: (dmaSelector?.dmaFlow?.update || 0) + 1,
								})
							);
						}, 300);
					}, 1000);
				}
			});
	};

	const handleBackBtn = () => {
		setSelectedSubProducts([]);
		setFlatValue('');
		setManagers([]);
		setIsFlatPayout(true);
		history.push('/dashboard/teams');
	};

	return (
		<>
			<IonPage>
				<Header
					name={t('Add Proposed Products & Payout')}
					backButton={true}
					needCross={true}
					needCrossOnClick={() => {
						handleBackBtn();
					}}
				/>
				<IonContent
					className='proposed-payout-background bg-web'
					ref={contentRef}
					scrollEvents={true}
				>
					<div
						id='propose-payout'
						className='bg-web'
					>
						<div className='proposed-payout border-md-radius-10 bg-web-white mt-4'>
							<div className='mb-3 ion-padding '>
								<CustomDropDown
									{...{
										isCheckbox: false,
										title: t('Select Owner'),
										setData: setManagers,
										data: managers,
										placeholder: t('Search Owner by “Name”'),
										isSearch: true,
										modalTitle: t('Select Owner'),
									}}
								/>
								{/* <DropDownWitoutModal
							data={managers}
							title={'Select Manager'}
							setData={setManagers}
						/> */}
							</div>
							<div className='d-flex justify-content-center border border-1 border-top-0 border-start-0 border-end-0'>
								<IonSegment
									style={{ width: 'fit-content' }}
									value='default'
									mode='md'
								>
									<IonSegmentButton
										value='default'
										mode='md'
									>
										<IonLabel className='fs-16 darkest-grey fw-600'>
											{t('Loan')}
										</IonLabel>
									</IonSegmentButton>
								</IonSegment>
							</div>
							<div className='ion-padding'>
								<IonRadioGroup
									onIonChange={(e) => {
										let _e;
										if (e?.detail?.value === 'all-flat-payout') {
											_e = true;
										} else {
											_e = false;
										}
										checkIfFlatPayoutChanged(_e);
										setIsFlatPayout(_e);
									}}
									value={isFlatPayout ? 'all-flat-payout' : 'per-product'}
								>
									<IonRadio
										value={'all-flat-payout'}
										labelPlacement='end'
										justify='start'
										className='d-block'
									>
										{t('Set flat payout % for all sub-products')}
									</IonRadio>
									<IonRadio
										value={'per-product'}
										justify='start'
										labelPlacement='end'
										className='d-block'
									>
										{t('Set payout % per sub-product')}
									</IonRadio>
								</IonRadioGroup>
								{isFlatPayout && (
									<div className='d-flex justify-content-between align-items-center mt-3'>
										<h5 className='w-75 fs-14'>{t('Enter Flat payout')}</h5>
										<CustomInput
											type={'string'}
											className={'my-0 text-center'}
											suffixClassName={'mb-0'}
											maxLength={2}
											style={{
												maxWidth: '60px',
												minWidth: '60px',
												width: '100%',
												marginRight: '10px',
											}}
											value={flatValue}
											needSuffixIcon={true}
											suffixText={'%'}
											onChange={(e: any) => {
												setFlatValue(e.detail.value);
												setHandleThresHoldValue(e?.detail?.value);
											}}
											onBeforeInput={(evt: any) => {
												!/[0-9]/.test(evt.data) && evt.preventDefault();
											}}
											onKeyDown={(e: any) => {
												disableNumberArrows(e);
											}}
											onWheel={(e: any) => e.target.blur()}
										/>
									</div>
								)}

								<div className='d-flex justify-content-between my-3'>
									<h5 className='fs-14 text-grey fw-600'>{t('Sub Product')}</h5>
									<h5 className='fs-14 text-grey fw-600'>
										{t('Proposed Payout')} %
									</h5>
								</div>
								<div className='d-flex flex-column gap-2'>
									<div className='d-flex gap-3 align-items-center'>
										<CustomCheckbox
											onChange={(e: any) => {
												if (e.detail?.checked) {
													setSelectAll(true);
												} else {
													setSelectAll(false);
												}
												handleBulkSelect(e);
											}}
											isChecked={
												subProducts?.length === selectedSubProducts?.length
											}
											labelPlacement='end'
											// indeterminate={selectAll}
										>
											{t('Select All')}
										</CustomCheckbox>
									</div>
									{subProducts &&
										subProducts?.map((item: any, index: number) => {
											const findIndex = selectedSubProducts.findIndex(
												(el: any) => el.sub_product_id === item?.id
											);
											const constant = isFlatPayout
												? flatValue
												: selectedSubProducts[findIndex]?.percentage;

											return (
												<div
													key={index}
													className='d-flex gap-3 align-items-center justify-content-between'
												>
													<div className='d-flex w-75 gap-3 align-items-center'>
														<CustomCheckbox
															isChecked={selectedSubProducts.some(
																(el: any) => el.sub_product_id === item?.id
															)}
															onChange={(e: any) => {
																handleParticularProduct(e, item?.id);
															}}
															className={'mb-0 pb-0 text-wrap'}
															labelPlacement={'end'}
															children={
																<span className='text-wrap fs-14 fw-600 darkest-grey'>
																	{t(item?.category_name?.trim())}
																</span>
															}
														></CustomCheckbox>
													</div>
													<CustomInput
														type='string'
														className={'my-0 text-center'}
														suffixClassName={'mb-0'}
														maxLength={2}
														style={{
															maxWidth: '60px',
															minWidth: '60px',
															width: '100%',
															marginRight: '10px',
														}}
														value={
															selectedSubProducts.some(
																(el: any) => el.sub_product_id === item?.id
															) === true && constant
														}
														disabled={
															!isFlatPayout &&
															selectedSubProducts.some(
																(el: any) => el.sub_product_id === item?.id
															) === true
																? false
																: isFlatPayout
																? true
																: true
														}
														needSuffixIcon={true}
														onChange={(e: any) => {
															handleParticalProductPayout(e, item?.id);
														}}
														onBeforeInput={(evt: any) => {
															!/[0-9]/.test(evt.data) && evt.preventDefault();
															const a = evt?.target.value.split('');
															if (evt?.target.value) {
																if (a[0] === '0') {
																	a.shift();
																	evt.target.value = a.join(',');
																}
															}
														}}
														onKeyDown={(e: any) => {
															disableNumberArrows(e);
														}}
														onWheel={(e: any) => e.target.blur()}
														suffixText={'%'}
													/>
												</div>
											);
										})}
								</div>
							</div>
							<div className=' d-flex align-items-center justify-content-center ion-padding '>
								<CustomButton
									disabled={error}
									title={t('Submit')}
									className={'w-50 mb-2 d-none d-md-block'}
									onClick={createPayout}
								/>
							</div>
						</div>
					</div>
				</IonContent>
				<div className='ion-padding d-block d-md-none'>
					<CustomButton
						disabled={error}
						title={t('Submit')}
						className={'w-100 mb-2'}
						onClick={createPayout}
					/>
				</div>
			</IonPage>
		</>
	);
};
