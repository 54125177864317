import {
	IonButtons,
	IonContent,
	IonFooter,
	IonImg,
	IonPage,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Header } from '../../../components/layout/header/Header';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { useEffect, useState } from 'react';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	getLeadFlowUrl,
	getLenderSiteLink,
	leadFlow,
	lenderSelectionProcess,
	lenderUpdateStatus,
	markAsClose,
} from '../../../store/reducers/lead/leadSlice';
import CustomAllocateManagers from '../../../components/common/CustomAllocateManagers';
import { LEAD_STATUS } from '../../../helpers/constant';
import { ShareData } from '../../../helpers/functions';

export const BankVisitSite: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const selectedLenderData = useSelector(
		(state: any) => state.lead.selectedLender[0]
	);
	const dispatch = useDispatch();
	const [showAssignModal, setShowAssignModal] = useState<boolean>(false);
	const [lenderWebLink, setLenderWebLink] = useState<any>({});
	const leadInfo = useSelector((state: any) => state.lead.leadFlowData);
	const [url, setUrl] = useState('');

	useEffect(() => {
		if (location.pathname !== '/bank-visit-site') {
			return;
		}
		dispatch(getLeadFlowUrl())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setUrl(result?.url);
				}
			});
		getLenderWebLinkData();
	}, [location.pathname, selectedLenderData?.lender_id]);

	const getLenderWebLinkData = async () => {
		if (selectedLenderData?.lender_id) {
			await dispatch(
				getLenderSiteLink({
					param: `/${selectedLenderData?.lender_id}/${leadInfo?.selectedProductData?.product_category_id}`,
				})
			)
				.then(unwrapResult)
				.then((response: any) => {
					if (response?.status) {
						setLenderWebLink(response?.url);
					}
				});
		}
	};

	const markAs = (status: any) => {
		const myPromise = new Promise(function (myResolve) {
			setTimeout(function () {
				let path = '';
				if (status === 'Closed') {
					path = '/update-status';
				} else {
					path = '/disbursement-details-form';
				}
				myResolve(history.push(path));
			}, 10);
		});
		myPromise.then(() => {
			if (status === 'Closed') {
				dispatch(markAsClose(true));
			}
			dispatch(lenderUpdateStatus(status));
			dispatch(lenderSelectionProcess(true));
			dispatch(
				leadFlow({
					...leadInfo,
					appsToUpdate: [],
					update: leadInfo.update + 1 || 1,
					applicationStatus:
						status === 'Login'
							? LEAD_STATUS.LOGIN_LABEL
							: leadInfo?.applicationStatus,
					iSBackFromDsburseAndSanction: true,
				})
			);
		});
	};

	return (
		<IonPage>
			<Header
				name={selectedLenderData ? selectedLenderData?.lender_name : ''}
				needArrow={true}
				backButtonDisabled={false}
				backButton={true}
				needArrowOnClick={() => {
					history.push('/lender-specific-info');
				}}
				style={{ borderBottom: '1px solid #00000029' }}
				endItem={
					leadInfo?.via !== 'share' && (
						<IonButtons slot='end'>
							<IonButtons className=''>
								<IonImg
									className='cursor-pointer'
									src='/images/icons/assign-icon.svg'
									alt='assign'
									onClick={() => setShowAssignModal(true)}
								/>
							</IonButtons>
							<IonButtons className='ms-3'>
								<IonImg
									className='cursor-pointer'
									src='/images/icons/share-blue.svg'
									alt='assign'
									onClick={() => {
										const finalUrl =
											url.split('?')?.[0] +
											'?d=' +
											window.btoa(`lead_id=${leadInfo?.leadId}`);
										ShareData('', '', finalUrl);
									}}
								/>
							</IonButtons>
						</IonButtons>
					)
				}
			/>
			<IonContent>
				<div className='bg-web h-100 pt-md-3'>
					<div className='bg-web-white container-width-md py-md-3 border-md-radius-10 d-flex justify-content-center align-items-center flex-column e-franking-send-container-height'>
						<div className='text-center'>
							<img
								src='/images/icons/web-browse-icons.svg'
								alt='web browse icon'
							/>
							{lenderWebLink ? (
								<p
									style={{ color: '#5D5E72' }}
									className='fw-600 mt-3'
								>
									{t(
										'Click on visit site to continue with the application on the official website.'
									)}
								</p>
							) : (
								<p
									style={{ color: '#5D5E72' }}
									className='fw-600 mt-3'
								>
									{t('This Loan to be Processed Offline')}
								</p>
							)}
							{lenderWebLink && (
								<a
									href={lenderWebLink}
									target='_blank'
								>
									<img
										src='/images/icons/arrow-top-right-icon.svg'
										alt='web browse icon'
									/>
									<span className='fw-700 fs-16 ms-3'>{t('Visit Site')}</span>
								</a>
							)}
						</div>
						<div className='d-none d-md-block w-100 px-4 my-4 mt-5'>
							<CustomButton
								className='fw-bold dashboard-add-lead-button text-transform-none'
								expand='block'
								fill={'outline'}
								title={t('Mark as Closed')}
								onClick={() => {
									markAs('Closed');
								}}
							/>
							<CustomButton
								className=' fw-bold dashboard-add-lead-button text-transform-none'
								expand='block'
								title={t('Mark as Login')}
								onClick={() => {
									markAs('Login');
								}}
							/>
						</div>
					</div>
				</div>
			</IonContent>
			<IonFooter className='ion-padding bg-web-white d-block d-md-none'>
				<div className='d-md-flex justify-content-md-center'>
					<CustomButton
						className=' fw-bold dashboard-add-lead-button text-transform-none'
						expand='block'
						fill={'outline'}
						title={t('Mark as Closed')}
						onClick={() => {
							markAs('Closed');
						}}
					/>
					<CustomButton
						className='fw-bold dashboard-add-lead-button text-transform-none'
						expand='block'
						title={t('Mark as Login')}
						onClick={() => {
							markAs('Login');
						}}
					/>
				</div>
			</IonFooter>
			<CustomModal
				id='customModalRework'
				isOpen={showAssignModal}
				modalTitle={t('Select Manager')}
				initialBreakpoint={1}
				handleClose={() => setShowAssignModal(false)}
				breakpoints={[0, 1]}
				className='assignModal side-modal'
				modalContent={
					<CustomAllocateManagers
						eventType='Lead'
						id={leadInfo?.leadId}
						setShowAssignModal={setShowAssignModal}
					/>
				}
				scrollY={true}
				needCross={true}
				needArrow={false}
			></CustomModal>
		</IonPage>
	);
};
