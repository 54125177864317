import { IonContent, IonPage } from '@ionic/react';
import { Player } from '@lottiefiles/react-lottie-player';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import './CustomSucessfull.scss';
//import { resetLeadState } from '../../store/reducers/lead/leadSlice';
import {
	changeRefToken,
	changeToken,
} from '../../store/reducers/user/userSlice';

export default function CustomSuccessfull() {
	const history = useHistory();
	const userSelector = useSelector(
		(state: any) => state?.users?.customSuccessfull
	);
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	const dispatch = useDispatch();
	const DefaultComponent = () => {
		return <IonContent>DefaultComponent</IonContent>;
	};
	useEffect(() => {
		if (history.action === 'POP') {
			history.push('/login');
		}
	}, [history.action]);
	const DmaInviteSuccess = () => {
		// useEffect(() => {
		// 	setTimeout(() => history.replace(userSelector?.redirectTo), 2000);
		// }, []);

		const compeletdTick = (types: any) => {
			switch (types) {
				case 'DMAINVITESUCCESS':
					return 'InviteGreen';
				case 'KYCSUCCESS':
					return 'GreenTick';
				case 'AAM':
					return 'GreenTick';
				default:
					return null;
			}
		};
		return (
			<IonContent className='background-web'>
				{' '}
				<div className='bg-web d-md-flex justify-content-md-center align-items-md-center h-100 '>
					<div className='h-50 container-width-md success-container'>
						<div
							className=' bg-web-white  border-md-radius-10 d-flex ion-justify-content-center successfull-invitation px-5 ion-align-items-center'
							style={{
								flexDirection: 'column',
								background:
									'transparent linear-gradient(180deg, rgba(210, 255, 210, 1) 0%, #ffffff 35%) 0% 0% no-repeat padding-box',
							}}
						>
							<Player
								// className="pe-3"
								style={{
									width: '72px',
									marginBottom: '10px',
								}}
								onEvent={(event) => {
									if (event === 'complete') {
										if (
											leadInfo?.from === 'nimbuss' ||
											leadInfo?.from === 'customer'
										) {
											dispatch(changeToken(''));
											dispatch(changeRefToken(''));
										}
										if (userSelector?.redirectTo !== '') {
											setTimeout(() => {
												history.replace(userSelector?.redirectTo);
											}, 500);
										}
									}
								}}
								keepLastFrame={true}
								autoplay={true}
								loop={false}
								controls={false}
								src={`/images/icons/json/${compeletdTick(
									userSelector?.type
								)}.json`}
							/>
							<h2 className='text-center m-0 fs-18 darkest-grey pt-2'>
								{userSelector?.message}
							</h2>
						</div>
					</div>
				</div>
			</IonContent>
		);
	};
	const GetComponent: any = () => {
		switch (userSelector?.type) {
			case 'DMAINVITESUCCESS':
				return <DmaInviteSuccess />;
			case 'KYCSUCCESS':
				return <DmaInviteSuccess />;
			case 'AAM':
				return <DmaInviteSuccess />;
			default:
				return <DefaultComponent />;
		}
	};

	return (
		<IonPage className=''>
			<GetComponent />
		</IonPage>
	);
}
