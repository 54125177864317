// LOGIN/LOGOUT APIs
export const CRM_API_ENDPOINT =process.env.REACT_APP_API_ENDPOINT
console.log('ins CRM_API_ENDPOINT>>', CRM_API_ENDPOINT)
// export const CRM_API_ENDPOINT = "https://crmdevapi.dromecrm.com/";
//export const CRM_API_ENDPOINT = "https://crmpreprodapi.dromecrm.com/";
// export const CRM_API_ENDPOINT = "https://prodcrmapi.oneandro.com/";

export const INSURANCE_API_ENDPOINT = process.env.REACT_APP_INSURANCE_API
console.log('ins INSURANCE_API_ENDPOINT', INSURANCE_API_ENDPOINT)
//export const INSURANCE_API_ENDPOINT = "https://insuranceapi.oneandro.com:8184/";
// export const INSURANCE_API_ENDPOINT = "https://insuranceapi.dromecrm.com:8184/";
export const DROME_CRM_API_ENDPOINT = CRM_API_ENDPOINT;
export const INSURANCE_LOGIN = INSURANCE_API_ENDPOINT + "insurance/login";
export const INSURANCE_LENDER_LIST = INSURANCE_API_ENDPOINT + "insurance/allinsurancelenderlist";

export const VERIFYOTP = CRM_API_ENDPOINT+"user/verifyotp";
// export const GETMANAGERLIST = CRM_API_ENDPOINT+"user/lead_manager_info";
export const GET_TERMS_CONDITIONS = CRM_API_ENDPOINT +'lead/get_term_and_condition';
export const GETMANAGERLIST = CRM_API_ENDPOINT+"user/insurance_manager_info";
// Lead Details Activity Page Api 
export const GET_ACTIVITY_DETAILS = INSURANCE_API_ENDPOINT +"insurance/getactivity";

// Lead Details Comment Page Api
export const GET_COMMENTS = INSURANCE_API_ENDPOINT +"insurance/getcommentdetails";
export const Add_COMMENT = INSURANCE_API_ENDPOINT + "insurance/addcomment";
export const GETPLVCLIST = CRM_API_ENDPOINT+'user/central_childlist';
export const INSURANCE_APPLICATION_LEAD_DETAILS = INSURANCE_API_ENDPOINT +'insurance/insurerdets';

export const GETPINCODEDETAILS = CRM_API_ENDPOINT+'user/partner/check_pincode';

export const COPS_MANAGER_ASSIGN_LIST = CRM_API_ENDPOINT+'user/central_managerlist';




export const LOGOUT = "logout";
export const REFRESH_TOKEN = "user/token";


export const GENERATE_INSURANCE_LEAD = INSURANCE_API_ENDPOINT + "insurance/generate_lead";

export const DASHBOARD_INSURANCE_LEAD_LIST = INSURANCE_API_ENDPOINT + "insurance/dashboardleadlist";
export const DASHBOARD_INSURANCE_LEAD_LIST_COPS_HEAD = INSURANCE_API_ENDPOINT + "insurance/dashboardleadlisting";

// Company NameList
export const COMPANYNAMELIST = DROME_CRM_API_ENDPOINT +'company/company_name_list';

export const DASHBOARD_INSURANCE_LEAD_DATA = INSURANCE_API_ENDPOINT + "insurance/leaddata";

export const BASIC_DETAILS =
  INSURANCE_API_ENDPOINT + "insurance/updatebasicdetailstemp";
export const EXISTING_DETAIL =
  INSURANCE_API_ENDPOINT + "insurance/updateexistingdetailstemp";
export const FAMILY_DETAIL = INSURANCE_API_ENDPOINT + "insurance/updatefamilydetailstemp";
export const ADDRESS_DETAIL = INSURANCE_API_ENDPOINT + "insurance/updateaddressdetailstemp";
export const OCCUPATION_DETAIL = INSURANCE_API_ENDPOINT + "insurance/occupationdetailstemp";

export const ADD_INSURANCE_POLICY = INSURANCE_API_ENDPOINT + "insurance/addpolicy";
export const GET_INSURANCE_POLICY = INSURANCE_API_ENDPOINT + "insurance/getpolicydetails";
export const GET_INSURANCE_POLICY_ALL = INSURANCE_API_ENDPOINT + "insurance/getallpolicydetails";
export const GET_INSURANCE_POLICY_DISPLAY = INSURANCE_API_ENDPOINT + "insurance/getpolicy";





// OTP-PAGE APIs
export const GENERATE_OTP = DROME_CRM_API_ENDPOINT +'lead/insurance_generate_otp';
export const VERIFY_OTP = DROME_CRM_API_ENDPOINT + 'lead/insurance_verify_otp/';


export const INSURANCE_FETCH_APPLICATION_DETAILS = INSURANCE_API_ENDPOINT + "insurance/getapplicationdetails";
export const INSURANCE_DISPLAY_APPLICATION_DETAILS = INSURANCE_API_ENDPOINT + "insurance/displayApplicationDetails";



export const UPDATE_STATUS = INSURANCE_API_ENDPOINT + "insurance/updateapplicationstatus";
export const UPDATE_POLICY_STATUS = INSURANCE_API_ENDPOINT + "insurance/updatepolicy";

export const UPDATE_VERIFICATION_STATUS = INSURANCE_API_ENDPOINT + "insurance/updateverificationstatus";

export const DOCUMENT_UPLOAD = INSURANCE_API_ENDPOINT + "insurance/adddocument";

export const STATUS_FLOW = INSURANCE_API_ENDPOINT + "insurance/statusflow";

//filters
export const GET_CITIES =  CRM_API_ENDPOINT + 'user/get_cities?city=';
export const ALL_STATUS_LIST = INSURANCE_API_ENDPOINT +  'insurance/lenderstatuslist';





