import { useHistory } from 'react-router';
import { TOKEN_NAME } from '../../helpers/constant';
import { getDecrypted } from '../../helpers/functions';
import { useSelector } from 'react-redux';


export const OpenRoutes = ({ children }) => {
	const history = useHistory();
	const getTokenFromLs = useSelector(
		(state) => state.users?.user_details?.access_token
	);
	const appData = useSelector((state) => state.app);

	const isTokenNull = getTokenFromLs === undefined;
	if (getTokenFromLs === undefined) {
		return children;
	} else if (appData?.gettingStarted && isTokenNull) {
		history.replace('/login');
	} else {
		const token = localStorage.getItem(TOKEN_NAME);
		const getTokenInfo = token && JSON.parse(getDecrypted(token));
		switch (getTokenInfo['onboarding_step']) {
			case 'register':
				history.replace('/register-yourself');
				break;
			case 'register_done':
			case 'dashboard':
				history.replace('/dashboard/home');
				break;
			default:
				history.replace('/dashboard/home');
				break;
		}
	}
};
