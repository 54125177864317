import { IonContent, IonImg, IonPage } from '@ionic/react';
import React from 'react';
import { Header } from '../../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import './Efranking.scss';
import { CustomButton } from '../../../components/common/custom-button/CustomButton';
import { useHistory } from 'react-router';
// import { CircularProgressBar } from '../../../components/common/custom-circular-progress-bar/CircularProgressBar';
import { useDispatch, useSelector } from 'react-redux';
import {
	dmaFlowData,
	userEfranking,
} from '../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { CustomStepsCountHeaderBar } from '../../../components/common/custom-steps-count-header-bar/CustomStepsCountHeaderBar';

export const EfrankingInitiate: React.FC = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const dmaSelector = useSelector((state: any) => state?.users);

	const initiateEfranking = async () => {
		await dispatch(userEfranking({ param: dmaSelector?.dmaFlow?.dmaId }))
			.then(unwrapResult)
			.then(async (result: any) => {
				if (result?.status) {
					await dispatch(
						dmaFlowData({
							...dmaSelector?.dmaFlow,
							dmaResult: result,
						})
					);
					history.push('/efranking-send');
				}
			});
	};
	const stepsProgressSubHeaderData = [
		{
			percentage: 50,
			value: '1',
			text: 'E-franking',
		},
		{
			percentage: 100,
			value: '2',
			text: 'E-franking',
		},
	];
	return (
		<IonPage>
			<Header
				needArrow={true}
				backButton={true}
				name={t('')}
			/>
			<CustomStepsCountHeaderBar
				currentStep={'step2'}
				headerStep={'e-franking-initiate'}
				displayStepsCounter={true}
				data={stepsProgressSubHeaderData}
			/>
			<IonContent scrollY={false}>
				{/* <div className='primary-bg-color px-3 py-2 font-semibold d-block d-md-none'>
					<div className='d-flex align-items-center justify-content-between efranking-wrapper'>
						{t('E-franking')}
						<CircularProgressBar
							strokeWidth='4'
							sqSize='45'
							percentage={50}
							value={'1/2'}
							ringBgColor='#808080'
							ringColor='var(--ion-color-primary)'
							textColor='rgba(12, 14, 43, 1)'
						/>
					</div>
				</div> */}
				<div className=' bg-web pt-md-4 d-flex d-md-block e-franking-initiate-height align-items-center justify-content-center'>
					<div className='bg-web-white container-width-md border-md-radius-10 p-md-3'>
						<div
							className='text-center m-auto'
							style={{ maxWidth: '240px' }}
						>
							<div className='d-inline-block'>
								<IonImg
									src='/images/icons/e-franking-pending-icon.svg'
									alt=''
								></IonImg>
							</div>
							<h3 className='fs-20 fw-600 darkest-grey mt-4 mb-3'>
								{t('E-Franking pending')}
							</h3>
							<p className='fs-14 heading-color'>
								{t('Please initiate E-franking in order to approve DMA.')}
							</p>
						</div>
						<div
							className='d-flex gap-2 ion-padding flex-column d-none d-md-block m-auto'
							style={{ maxWidth: '240px' }}
						>
							<CustomButton
								className={'w-100 btn-blue fw-600'}
								title={t('Initiate E-Franking')}
								onClick={() => initiateEfranking()}
							/>
						</div>
					</div>
				</div>
			</IonContent>
			<div className='d-flex gap-2 ion-padding flex-column d-block d-md-none'>
				<CustomButton
					className={'w-100 btn-blue fw-600'}
					title={t('Initiate E-Franking')}
					onClick={() => initiateEfranking()}
				/>
			</div>
		</IonPage>
	);
};
