import React, { useEffect, useState } from 'react';
import { Header } from '../../../components/layout/header/Header';
import { LoanLeadStep1 } from './LoanLeadStep1';
import { OtpVerification } from '../../otp-screen/OtpVerification';
import { GenerateBureauReport } from './GenerateBureauReport';

import './AddLoanLeadInfo.scss';
import { useHistory, useLocation } from 'react-router';
import { CustomModal } from '../../../components/common/custom-modal/CustomModal';
import { PageExitPopUp, ShareData } from '../../../helpers/functions';
import LeadStepInfoForm from './LoanLeadStepInfoForm';
import { DashboardHome } from '../../dashboard/home/DashboardHome';
import { LeadsFlowGenerator } from '../../../components/common/custom-leads-flow-generator/LeadsFlowGenerator';
import { useDispatch, useSelector } from 'react-redux';
import ProfessionalInfoInputs from '../../professional-info/ProfessionalInfoInputs';
import { useTranslation } from 'react-i18next';
import {
	getLeadFlowUrl,
	leadFlow,
} from '../../../store/reducers/lead/leadSlice';
import { IonButtons, IonImg, useIonRouter } from '@ionic/react';
import LeadCustomerConsentProcessing from './LeadCustomerConsentProcessing';
import CustomAllocateManagers from '../../../components/common/CustomAllocateManagers';
import { unwrapResult } from '@reduxjs/toolkit';

export const InitiateLoanLead: React.FC = () => {
	type ComponentType = React.ReactNode | React.ReactFragment;
	type StepsProgressSubHeaderData = {
		percentage: number;
		value: string;
		text: string;
	};
	type StepsComponents = {
		step: string;
		component: ComponentType;
	};
	const dispatch = useDispatch();
	const location = useLocation<any>();
	const { t } = useTranslation();
	const router = useIonRouter();
	const history = useHistory();
	const [showPageExitPopup, setShowPageExitPopup] = useState<boolean>(false);
	const dmaSelector = useSelector((state: any) => state?.users);
	const leadInfo = useSelector((state: any) => state?.lead?.leadFlowData);
	const [update, setUpdate] = useState(0);

	const [showAllocateManagerModal, setShowAllocateManagerModal] =
		useState<boolean>(false);

	useEffect(() => {
		if (location.pathname !== '/loan-lead-info-steps') {
			return;
		}
		dispatch(getLeadFlowUrl())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setUrl(result?.url);
				}
			});
	}, [location.pathname]);

	const stepsProgressSubHeaderData: StepsProgressSubHeaderData[] = [
		{
			percentage: 33.33,
			value: '1',
			text: 'Lead Creation Info',
		},
		{
			percentage: 66.66,
			value: '2',
			text: 'Lead Basic Info',
		},
		{
			percentage: 100,
			value: '3',
			text: 'OTP Verification',
		},
	];
	const [url, setUrl] = useState('');

	const stepsHeader: ComponentType = (
		<Header
			name={leadInfo?.headerTitle}
			backButton={
				(leadInfo?.from === 'customer' && leadInfo?.step === 'step2') ||
				(leadInfo?.from === 'customer' && leadInfo?.step === 'step4')
					? false
					: true
			}
			needArrow={leadInfo?.needArrow}
			needCross={leadInfo?.from === 'customer' ? false : leadInfo?.needCross}
			needCrossOnClick={() => {
				setShowPageExitPopup(true);
			}}
			needArrowOnClick={async () => {
				if (leadInfo?.step === 'step5') {
					dispatch(
						leadFlow({
							...leadInfo,
							step: 'step4',
						})
					);
				} else {
					if (leadInfo?.step === 'step1') {
						setUpdate(update + 1);
						dispatch(
							leadFlow({
								...leadInfo,
								clearMobile: update + 1,
							})
						);
						router.push('/select-lead-product');
					} else if (leadInfo?.step !== 'step1') {
						if (leadInfo?.from === 'nimbuss') {
							history.push(
								'/lead-details/' +
									leadInfo.leadId +
									'/' +
									dmaSelector?.user_details?.access_token
							);
						} else if (
							leadInfo?.from === 'customer' &&
							leadInfo?.step === 'step3'
						) {
							dispatch(
								leadFlow({
									...leadInfo,
									step: 'step2',
									headerTitle: 'Add Lead',
								})
							);
						} else if (leadInfo?.from !== 'customer') {
							history.push('/dashboard/lead');
						}
					}
				}
			}}
			backButtonText=''
			endItem={
				leadInfo?.via !== 'share' &&
				leadInfo.step !== 'step1' &&
				leadInfo.step !== 'step6' ? (
					<IonButtons
						slot='end'
						className='d-flex align-items-center'
					>
						<IonImg
							className='cursor-pointer'
							src='/images/icons/assign-icon.svg'
							alt='assign'
							onClick={() => {
								setShowAllocateManagerModal(true);
							}}
						/>
						<IonImg
							className='cursor-pointer ms-3'
							src='/images/icons/share-blue.svg'
							alt='share'
							onClick={() => {
								const finalUrl =
									url.split('?')?.[0] +
									'?d=' +
									window.btoa(`lead_id=${leadInfo?.leadId}`);
								ShareData('', '', finalUrl);
							}}
						/>
					</IonButtons>
				) : (
					''
				)
			}
		/>
	);

	const stepsComponents: StepsComponents[] = [
		{
			step: 'step1',
			component: <LoanLeadStep1 />,
		},
		{
			step: 'step2',
			component: <LeadStepInfoForm />,
		},
		{
			step: 'step3',
			component: <OtpVerification isPage={false} />,
		},
		{
			step: 'step4',
			component: <GenerateBureauReport />,
		},
		{
			step: 'step5',
			component: <LeadCustomerConsentProcessing isFullPage={false} />,
		},
		{
			step: 'step6',
			component: <OtpVerification isPage={false} />,
		},
		{
			step: 'step7',
			component: <ProfessionalInfoInputs />,
		},
	];

	const pageCloseModal: ComponentType = (
		<>
			{leadInfo?.step !== 'step4' ? (
				<CustomModal
					isOpen={showPageExitPopup}
					modalTitle=''
					initialBreakpoint={undefined}
					handleClose={() => setShowPageExitPopup(false)}
					breakpoints={[]}
					className='height-auto confirm-popup-middle'
					modalContent={PageExitPopUp(
						() => {
							setShowPageExitPopup(false);
						},
						() => {
							setShowPageExitPopup(false);
							leadInfo?.from === 'nimbuss'
								? history.replace(
										'/lead-details/' +
											leadInfo.leadId +
											'/' +
											dmaSelector?.user_details?.access_token
								  )
								: history.replace('/dashboard/lead');
						},
						'Cancel',
						'Continue Later',
						'className',
						'Are You Sure You Want to Exit?',
						`${t('You can continue later from where you left off.')}`
					)}
					needIonContentWraper={false}
					needCross={false}
					needArrow={false}
				/>
			) : (
				<CustomModal
					isOpen={showPageExitPopup}
					modalTitle=''
					initialBreakpoint={undefined}
					handleClose={() => setShowPageExitPopup(false)}
					breakpoints={[]}
					className='height-auto confirm-popup-middle'
					modalContent={PageExitPopUp(
						() => {
							setShowPageExitPopup(false);
						},
						() => {
							const myPromise = new Promise(function (myResolve) {
								setTimeout(function () {
									leadInfo?.from === 'nimbuss'
										? myResolve(
												history.push(
													'/lead-details/' +
														leadInfo.leadId +
														'/' +
														dmaSelector?.user_details?.access_token
												)
										  )
										: myResolve(history.replace('/dashboard/lead'));
									setShowPageExitPopup(false);
								}, 10);
							});
							myPromise.then(() => {
								dispatch(
									leadFlow({
										...leadInfo,
										step: 'step1',
										headerTitle: '',
										subHeaderStepsCounter: false,
										reSendCibilOtp: false,
									})
								);
							});
						},
						'Cancel',
						'Continue Later',
						'className',
						'Are You Sure You Want to Exit?',
						`${t('You can continue later from where you left off.')}`
					)}
					needIonContentWraper={false}
					needCross={false}
					needArrow={false}
				/>
			)}
		</>
	);

	if (location?.pathname === '/dashboard') {
		return <DashboardHome />;
	} else {
		return (
			<>
				<LeadsFlowGenerator
					currentStep={leadInfo?.step}
					stepsHeader={stepsHeader}
					displayStepsCounter={leadInfo?.subHeaderStepsCounter}
					stepsProgressSubHeaderData={stepsProgressSubHeaderData}
					stepsComponents={stepsComponents}
					pageCloseModal={pageCloseModal}
				/>
				<CustomModal
					// id='customModalRework'
					isOpen={showAllocateManagerModal}
					modalTitle={t('Select Assignee')}
					initialBreakpoint={1}
					handleClose={() => setShowAllocateManagerModal(false)}
					breakpoints={[0, 1]}
					className='assignModal side-modal'
					modalContent={
						<CustomAllocateManagers
							eventType='Lead'
							id={leadInfo?.leadId}
							setShowAssignModal={setShowAllocateManagerModal}
							redirectTo='/dashboard/lead'
						/>
					}
					scrollY={true}
					needCross={true}
					needArrow={false}
				></CustomModal>
			</>
		);
	}
};
