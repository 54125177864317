import * as Yup from 'yup';
import { REGEX } from '../../../helpers/constant';

export const LendingKartInfoSchema = Yup.object({
	business_revenue: Yup.string()
		.required('Business Revenue is a required field')
		.matches(REGEX.ALLOWNUMBERS, 'Business Revenue should be number')
		.max(15, 'Business Revenue Maximum Length Exceeded'),
	businessRunBy: Yup.string().required('Business Run By is required'),
	rm_manager: Yup.string().required('RM Manager is required'),
	productCategory: Yup.string().required('Product Category is required'),
	documents: Yup.array().of(
		Yup.object({
			document_type: Yup.string().required('Document Type is required'),
			file: Yup.string().required('Document is required'),
		})
	),
});

export const BusinessInfo = [
	{
		name: 'business_revenue',
		type: 'number',
		placeholder: 'Business Revenue *',
		maxLength: 15,
	},
	{
		name: 'businessRunBy',
		type: 'dropdown',
		placeholder: 'Business Run By *',
	},
	{
		name: 'productCategory',
		type: 'dropdownmodal',
		placeholder: 'Product Category *',
	},
	{
		name: 'nature_of_business',
		type: 'dropdown',
		placeholder: 'Nature Of Business *',
	},
	{
		name: 'rm_manager',
		type: 'dropdown',
		placeholder: 'RM Manager *',
	},
];

export const businessRunBy = [
	{ value: 'Self' },
	{ value: 'Spouse' },
	{ value: 'Parent' },
	{ value: 'Relative' },
	{ value: 'Others' },
];

export const productCategory = [
	{
		value:
			'Advisory services (legal, business, educational, psychological etc.)',
	},
	{ value: 'Aircel distributor / R-com distributor' },
	{ value: 'Apparel, Readymade garments' },
	{ value: 'Arms and ammunition dealers' },
	{ value: 'Arts, design and other creative services' },
	{ value: 'Automobile accessories / service centre' },
	{ value: 'Automotive parts' },
	{ value: 'BPO/KPO & facility management' },
	{ value: 'Bar / Liquor' },
	{
		value:
			'CSC, photocopying and other documents and ID (Aadhar, PAN) related services, Kisan seva kendra',
	},
	{ value: 'Cab Services' },
	{ value: 'Camera, CCTV and related accessories' },
	{ value: 'Cargo and retail transport service' },
	{ value: 'Chain restaurant / verified income' },
	{ value: 'Chemist, Retail health accessories, medicines and supplements' },
	{ value: 'Clinics, hospital,nursing home, lab, gym, pet clinic etc.' },
	{ value: 'Computer, Mobile and related accessories' },
	{ value: 'Computer, mobile and camera repairing service' },
	{ value: 'Construction equipment including building, road, sewage etc.' },
	{ value: 'Construction material like cement, bricks, sand etc' },
	{ value: 'Corporate services / Corporate bookings' },
	{ value: 'Cotton bales, Textile manufacturing' },
	{ value: 'Countdown Timers, Electronic Scoreboards and Digital Clocks' },
	{ value: 'Courier' },
	{ value: 'Design, Fabrication & Painting Services' },
	{ value: 'Distributor of cooking/commercial Gas' },
	{
		value:
			'Eco-friendly solutions for industry (solar, greenhouse, biomass, recycling, etc.)',
	},
	{ value: 'Edible Oil business' },
	{
		value:
			'Educational Institute (Pre-school, school, college, coaching center, library etc.)',
	},
	{
		value:
			'Educational products (books, educational toys, stationeries, e-learning etc.)',
	},
	{ value: 'Electronic sensors, devices' },
	{ value: 'Entertainment Event' },
	{ value: 'Equipment used in agriculture' },
	{ value: 'Event Management' },
	{ value: 'Fabric (woven and non-woven) & textiles' },
	{ value: 'Facility Management Services' },
	{
		value:
			'Fibres, threads, buttons and other raw materials supplier to textile industry',
	},
	{ value: 'Film Producer' },
	{
		value:
			'Financial service providers (Chit funds/ small finance companies/Stock broking Companies)',
	},
	{
		value: 'Financial service providers (DSA,agents and other intermediators)',
	},
	{
		value: 'Financial service providers (NBFCs/ Other money lending companies)',
	},
	{ value: 'Fire fighting equipment, safety materials' },
	{ value: 'Footwear, bags, cosmetics and other fashion accessories' },
	{ value: 'Freight forwarding services' },
	{ value: 'Furniture & Fixtures (including on rent)' },
	{ value: 'Grocery/ Kirana store / Departmental Store' },
	{ value: 'Hardware & Fittings (ceramics, tiles, cables, lights etc.)' },
	{ value: 'Hardware provider to telecom industry' },
	{ value: 'Heavy machineries & Robotics' },
	{ value: 'Home & Kitchen Appliances' },
	{
		value:
			'Home Decor, interior items, kitchen accessories, toys, gift articles',
	},
	{ value: 'Hotels, resorts and club' },
	{ value: 'Housekeeping products' },
	{ value: 'Housekeeping, security & industrial labour supply' },
	{ value: 'Imitation jewellery' },
	{ value: 'Industrial and customized software, applications and ITES' },
	{ value: 'Installation and repair services for telecom products' },
	{ value: 'Intelligence Agencies/ Private Security Firms' },
	{ value: 'Interior Designers' },
	{ value: 'Jewellery, precious metals and stones' },
	{ value: 'Live Stock Trading' },
	{ value: 'Law services, astrological services, etc.' },
	{ value: 'Leather Trading' },
	{ value: 'Material handling service provider' },
	{ value: 'Medical, non-medical & wellness equipments' },
	{ value: 'Mining & Mining Products' },
	{ value: 'Mobile and related accessories' },
	{
		value:
			'Mobile recharge, Telecom, DTH, data service provider (telephone, internet,broadband, ISP, leased line etc.)',
	},
	{ value: 'Musical Instruments, bulbs, tubelights' },
	{ value: 'Networking, storage, data management and other support services' },
	{ value: 'Non-metal products like wood, paper, plastic, glass, etc' },
	{ value: 'Oils, Paint, Chemicals and petroleum products' },
	{ value: 'Other metals, alloys, minerals and their scraps' },
	{ value: 'Outdoor furniture (swings etc.)' },
	{
		value:
			'Outsourced consultancy (recruitment, research, placement, transcription, visa etc.)',
	},
	{ value: 'Pet Shop, Pet Clinic & Pet Grooming Parlour' },
	{ value: 'Petrol pump & Gas station' },
	{ value: 'Power' },
	{
		value:
			'Professional services (branding, media, architecture, saloon, beauty parlour etc.)',
	},
	{ value: 'Property dealing & management (Rent, lease or Sell)' },
	{ value: 'Raw materials and parts used for manufacturing' },
	{ value: 'Real estate developer, Civil contractor' },
	{ value: 'Renewal (other than Via)' },
	{ value: 'Repair and maintenance services' },
	{ value: 'Repair and servicing of household appliances' },
	{ value: 'Restaurants, Cafes, food outlets & catering' },
	{ value: 'Retail and standard softwares, applications and ITES' },
	{
		value:
			'Seeds, Fertilizer, Pesticides, Cattle feeds and agricultural products',
	},
	{
		value: 'Shares/Bitcoins/Old coins trading or Other speculative activities',
	},
	{ value: 'Shipping Services' },
	{ value: 'Supplier for switches,cables etc' },
	{ value: 'Support services (facilitators, intermediators, agents, etc.)' },
	{
		value:
			'Support services to industries like industrial design, warehousing, testing, repairing etc.',
	},
	{ value: 'Tax planners / Auditors' },
	{
		value:
			'Tea / coffee vending machine & Tea / Coffee powder to Government / corporates',
	},
	{ value: 'Ticket, Travel & Tour Packages' },
	{ value: 'Tobacco Products' },
	{
		value:
			'Tools and equipments including electricals,transformers, inverters and batteries',
	},
	{
		value:
			'Vegetables, Fruits, Milk and other dairy products, Spices, Sea-food and food-processing',
	},
	{ value: 'Vehicles (New/second hand)' },
	{ value: 'Via cases' },
	{ value: 'Others' },
];

export const documentTypes = [
	{
		title: 'Bank Statement',
		value: 'BankStatement-Director/Properiotor/Partner',
	},
	{ title: 'GST Certificate', value: 'GSTCertification-RegProof' },
	{ title: 'Shop & Establishment Act', value: 'ShopEstablishmentAct-RegProof' },
	{
		title: 'Municipal Corporation/Mahanagar Palika/Gram Panchayat',
		value: 'RCunderMunicipality-RegProof',
	},
	{ title: 'Udyog Aadhaar', value: 'UdyogAadhaar-RegProof' },
	{
		title: 'Drugs License/Food and drugs control certificate',
		value: 'DrugLicFdDrugControlCert-RegProof',
	},
	{ title: 'PAN Card of applicant', value: 'PanCard-Personal' },
	{ title: 'Other', value: 'Others-RegProof' },
];
