import { IonSelect, IonSelectOption } from '@ionic/react';
import './DropDownModal.scss';
import { useTranslation } from 'react-i18next';
const DropDownModal = ({
	data,
	disabled,
	setValue,
	// defaultValue,
	// errors,
	className = ' mb-3 ',
	label,
	onChange,
	onBlur,
	name,
	value,
}: any) => {
	const { t } = useTranslation();
	return (
		<IonSelect
			disabled={disabled}
			label={t(`${label ? label : 'Select'}`).toString()}
			labelPlacement='floating'
			fill='outline'
			name={name}
			// {...(defaultValue ? { value: defaultValue } : {})}
			value={value}
			aria-label='Select'
			interface='popover'
			// className={`dropdown_modal ${
			// 	errors ? 'ion-invalid ion-touched' : 'ion-valid'
			// }`}
			// onSelect={onChange}
			className={`dropdown_modal mt-2 ${className}`}
			onIonChange={(e) => {
				setValue && setValue(e.target.value);
				onChange(e);
			}}
			onIonBlur={onBlur}
			mode='md'
		>
			{data.map((item: any, index: number) => (
				<IonSelectOption
					key={`dropdown-modal-option-${index}`}
					value={item?.id}
				>
					{t(item?.value)}
				</IonSelectOption>
			))}
		</IonSelect>
	);
};

export default DropDownModal;
