import { IonImg } from '@ionic/react';
import { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { Controller } from 'react-hook-form';
import { showToastAlert } from '../../../../helpers/functions';
import { CustomInput } from '../../../../components/common/custom-input/CustomInput';
import { DynamicDropdown } from '../../../../components/common';
import { coApplicantFormTwo } from './TataHlLAP';
import CustomPopoverSearch from '../../../../components/common/custom-popover/CustomPopoverSearch';
import {
	getCompanyNameList,
	contributionPinCode,
} from '../../../../store/reducers/user/userSlice';
import { sanitizeHtml } from '../../../../helpers/functions';
import { PAGINATION_DETAILS } from '../../../../helpers/constant';
import { fetchMasterData } from '../../../../store/reducers/lead/leadSlice';

export const CoApplicantForm2: React.FC<any> = ({
	control,
	getValues,
	setValue,
	trigger,
	errors,
}) => {
	const [propertyType, setPropertyType] = useState([]);
	const [natureOfProperty, setNatureOfProperty] = useState([]);
	const [applicationType, setApplicationType] = useState([]);
	const [occupationType, setOccupationType] = useState([]);
	const [collateralSubType, setCollateralSubType] = useState([]);
	const [addressType, setAddressType] = useState([]);
	const [companyList, setCompanyList] = useState([]);
	const [employmentType, setEmploymentType] = useState([]);
	const [customerType, setCustomerType] = useState([]);
	const [indiIndiRelation, setIndiIndiRelation] = useState([]);
	const [indiCorpRelation, setIndiCorpRelation] = useState([]);
	const [corpIndiRelation, setCorpIndiRelation] = useState([]);
	const [conditionalFields, setConditionalFields] = useState({
		propertyCost: false,
		propertyAddress: false,
		doi: false,
	});
	const [loanPurpose, setLoanPurpose] = useState([]);
	const [pinCodeDataError, setPinCodeDataError] = useState<any>('');
	const [openCompanyModal, setOpenCompanyModal] = useState(false);
	const dispatch = useDispatch();
	const { lenderSpecificDetails, leadFlowData } = useSelector(
		(state: any) => state?.lead
	);

	useEffect(() => {
		let showConditionalFields: any = conditionalFields;
		getDataFromMaster();
		if (
			lenderSpecificDetails?.lender_spf_details?.customer_type === 'Corporate'
		) {
			setValue('customer_type', 'Individual');
			setValue('address_type', 'Residential Address');
		}

		if (getValues('customer_type') === 'Individual') {
			setValue('address_type', 'Residential Address');
		} else if (getValues('customer_type') === 'Corporate') {
			setValue('address_type', 'Office Address');
			showConditionalFields = {
				...showConditionalFields,
				doi: true,
			};
		}

		if (getValues('application_type') == 'Property Identified') {
			showConditionalFields = {
				...showConditionalFields,
				propertyCost: true,
				propertyAddress: true,
			};
		}
		setConditionalFields(showConditionalFields);
	}, []);

	const getDataFromMaster = async () => {
		await dispatch(fetchMasterData({ param: 'TATA_CUSTOMER_TYPE' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setCustomerType(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATAHLLAP_EMPLOYMENT_TYPE' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setEmploymentType(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATALAP_PROPERTY_TYPE' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setPropertyType(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATALAP_NATURE_OF_PROPERTY' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setNatureOfProperty(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATALAP_APPLICATION_TYPE' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setApplicationType(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATALAP_OCCUPATION_TYPE' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setOccupationType(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATALAP_COLLATERAL_SUBTYPE' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setCollateralSubType(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATALAP_ADDRESS_TYPE' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setAddressType(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATAHLLAP_RELATION_INDI_INDI' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setIndiIndiRelation(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATAHLLAP_RELATION_INDI_CORP' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setIndiCorpRelation(result?.data);
				}
			});

		await dispatch(fetchMasterData({ param: 'TATAHLLAP_RELATION_CORP_INDI' }))
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setCorpIndiRelation(result?.data);
				}
			});

		await dispatch(
			fetchMasterData({
				param:
					leadFlowData?.selectedProductData?.product_category_id == 3
						? 'TATAHL_LOAN_PURPOSE'
						: 'TATALAP_LOAN_PURPOSE',
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					setLoanPurpose(result?.data);
				}
			});
	};

	const pinCodeAction = async (value: any) => {
		setPinCodeDataError('');
		await dispatch(
			contributionPinCode({
				data: {
					pincode: value,
				},
			})
		)
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					if (!result?.list) {
						showToastAlert({
							type: 'error',
							message: 'Please Check Pin Code No.',
						});
					} else {
						showToastAlert({ type: 'success', message: result?.message });
					}
				} else {
					setPinCodeDataError(result?.message);
				}
			});
	};

	/**
	 * Get company list
	 * @param searchValue search text
	 */
	const getCompanyList = async (searchValue: any) => {
		await dispatch(
			getCompanyNameList({
				param: `?searchItem=${sanitizeHtml(
					encodeURIComponent(searchValue)
				)}&page=${PAGINATION_DETAILS.CURRENT_PAGE}&size=${
					PAGINATION_DETAILS.PAGE_SIZE
				}`,
			})
		)
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setCompanyList(response.companyListData);
				}
			});
	};

	/**
	 * Set dropdown data
	 * @param name field name
	 */
	const setDropDownOptions = (name: string) => {
		let partyRelations: any = [];
		switch (name) {
			case 'customer_type':
				return customerType;
			case 'employment_type':
				return employmentType;
			case 'property_type':
				return propertyType;
			case 'nature_of_property':
				return natureOfProperty;
			case 'application_type':
				return applicationType;
			case 'occupation_type':
				return occupationType;
			case 'collateral_sub_type':
				return collateralSubType;
			case 'loan_purpose':
				return loanPurpose;
			case 'address_type':
				return addressType;
			case 'party_relation':
				if (
					lenderSpecificDetails?.lender_spf_details?.customer_type ==
						'Individual' &&
					getValues('customer_type') == 'Individual'
				) {
					partyRelations = indiIndiRelation;
				} else if (
					lenderSpecificDetails?.lender_spf_details?.customer_type ==
						'Individual' &&
					getValues('customer_type') == 'Corporate'
				) {
					partyRelations = indiCorpRelation;
				} else if (
					lenderSpecificDetails?.lender_spf_details?.customer_type ==
						'Corporate' &&
					getValues('customer_type') == 'Individual'
				) {
					partyRelations = corpIndiRelation;
				}
				return partyRelations;
		}
	};

	/**
	 * On drop down value change set some data
	 * @param name field name
	 */
	const onDropDownChange = (name: any) => {
		if (name === 'customer_type') {
			if (getValues(name) === 'Corporate') {
				setValue('address_type', 'Office Address');
				setConditionalFields({
					...conditionalFields,
					doi: true,
				});
			} else {
				setValue('date_of_incorporation', undefined);
				setValue('address_type', 'Residential Address');
				setConditionalFields({
					...conditionalFields,
					doi: false,
				});
			}
			setValue('party_relation', '');
			trigger('party_relation');
		} else if (name === 'application_type') {
			if (getValues(name) !== 'Property Identified') {
				setValue('property_cost', undefined);
				setValue('property_address', null);
				setConditionalFields({
					...conditionalFields,
					propertyCost: false,
					propertyAddress: false,
				});
			} else {
				setConditionalFields({
					...conditionalFields,
					propertyCost: true,
					propertyAddress: true,
				});
			}
		}
		trigger(name);
	};

	const isDisabledDropDown = (name: any) => {
		if (name === 'address_type') {
			return true;
		} else if (
			name === 'customer_type' &&
			lenderSpecificDetails?.lender_spf_details?.customer_type === 'Corporate'
		) {
			return true;
		}
	};

	return (
		<>
			{coApplicantFormTwo.map(
				({ name, type, placeholder }: any, index: any) => {
					const errorText: any =
						errors && Object.keys(errors).length > 0 && errors;
					return (
						<div key={index}>
							{type === 'dropdown' ? (
								<div style={{ marginBottom: '15px' }}>
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, name } }) => (
											<DynamicDropdown
												label={t(`${placeholder}`)}
												data={setDropDownOptions(name)}
												name={name}
												onChange={(e: any) => {
													onChange(e);
													onDropDownChange(name);
												}}
												bindField={
													name === 'loan_purpose' || name === 'party_relation'
														? 'id'
														: 'value'
												}
												showField1={'value'}
												value={getValues(name)}
												onBlur={onBlur}
												disabled={isDisabledDropDown(name)}
												errors={errorText[name]?.message}
											/>
										)}
										name={name}
									/>
								</div>
							) : name === 'property_cost' || name === 'property_address' ? (
								(conditionalFields?.propertyCost ||
									conditionalFields?.propertyAddress) && (
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, name } }) => (
											<CustomInput
												type={type}
												label={t(placeholder)}
												onBlur={onBlur}
												id={name}
												onChange={(e: any) => {
													onChange(e);
												}}
												value={getValues(name)}
												errors={t(errorText[name]?.message || '')}
											/>
										)}
										name={name}
									/>
								)
							) : name === 'date_of_incorporation' ? (
								conditionalFields?.doi && (
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, name } }) => (
											<CustomInput
												type={type}
												needSuffixIcon={true}
												suffixClassName={
													'position-absolute date-of-birth-sufix'
												}
												suffixText={
													<IonImg
														src='/images/icons/input-dob-calendar-icon.svg'
														alt='dob'
													/>
												}
												outerClassName={'position-relative'}
												label={t(placeholder)}
												onBlur={onBlur}
												id={name}
												onChange={(e: any) => {
													onChange(e);
												}}
												value={getValues(name)}
												errors={t(errorText[name]?.message || '')}
											/>
										)}
										name={name}
									/>
								)
							) : name === 'company_name' ? (
								<>
									<Controller
										control={control}
										render={({ field: { onChange, onBlur } }) => (
											<CustomInput
												type={type}
												label={t(placeholder)}
												onBlur={() => {
													onBlur();
												}}
												id={name}
												onChange={(e: any) => {
													onChange(e);
													setOpenCompanyModal(true);
												}}
												value={getValues(name)}
												onFocus={() => {
													setOpenCompanyModal(true);
												}}
												errors={t(errorText[name]?.message)}
											/>
										)}
										name={name}
									/>
									<div className='mb-3'>
										<CustomPopoverSearch
											searchApi={getCompanyList}
											list={companyList}
											setList={setCompanyList}
											isOpen={openCompanyModal}
											searchListModalClose={setOpenCompanyModal}
											title={t('Company Names')}
											searchbarPlaceholder={t('Search Company by “Name”')}
											customNoDataMessage={t('Search by your company name')}
											setValue={(val: any) => {
												setValue('company_name', val);
												trigger('company_name');
											}}
										/>
									</div>
								</>
							) : (
								<Controller
									control={control}
									render={({ field: { onChange, onBlur, name } }) => (
										<CustomInput
											type={type}
											label={t(placeholder)}
											onBlur={onBlur}
											id={name}
											onChange={(e: any) => {
												onChange(e);
												if (name === 'pincode' && e.detail.value.length === 6) {
													pinCodeAction(e.detail.value);
												}
											}}
											value={getValues(name)}
											errors={
												name === 'pincode'
													? t(pinCodeDataError || errorText[name]?.message)
													: t(errorText[name]?.message)
											}
										/>
									)}
									name={name}
								/>
							)}
						</div>
					);
				}
			)}
		</>
	);
};
