/* eslint-disable @typescript-eslint/no-explicit-any */
//import { Header } from '../../components/layout/header/Header';
import { useEffect, useState } from 'react';
import './Products.scss';
import { useHistory } from 'react-router';
import {
	IonCardContent,
	IonCol,
	IonContent,
	IonGrid,
	IonImg,
	IonItem,
	IonLabel,
	IonList,
	// IonList,
	IonPage,
	IonRow,
	isPlatform,
} from '@ionic/react';
import { Header } from '../../components/layout/header/Header';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	getProductList,
	productFlowData,
} from '../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	getLenderBySubproduct,
	productFlow,
} from '../../store/reducers/lead/leadSlice';
import { CustomModal } from '../../components/common/custom-modal/CustomModal';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import { CustomNoData } from '../../components/common/custom-no-data/CustomNoData';
import { CustomSearchbar } from '../../components/common/custom-searchbar/CustomSearchbar';
import { Divider } from '../../components/common/divider/Divider';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
import {
	eventAttributes,
	notifyHandler,
	sanitizeHtml,
} from '../../helpers/functions';
import { NotifyVisitors } from 'capacitor-plugin-notifyvisitors';

interface HomeLendersData {
	name: string;
	id: number;
	cat_id: number;
	icon: string;
	lender_id: string;
	lender_prod_id: number;
	status: string;
}
interface CategoryData {
	name: string;
	id: number;
	cat_id: number;
	icon: string;
}
export const SubProducts: React.FC = () => {
	const { t } = useTranslation();
	const isWeb = useSelector((state: any) => state?.app?.isWeb);

	const history = useHistory();
	const dispatch = useDispatch();
	const [categoryList, setCategoryList] = useState<any>();
	const productSelector = useSelector(
		(state: any) => state?.users?.productFlow
	);

	const [open, setOpen] = useState(false);
	const productFlowSelector: any = useSelector(
		(state: any) => state?.lead?.productFlowData
	);
	// Notify Visitors
	const userId = useSelector((state: any) => state?.users?.user?.id);
	const userName = useSelector(
		(state: any) => state?.users?.homepage?.profile?.name
	);
	const userMobile = useSelector(
		(state: any) => state?.users?.user_details?.mobile
	);
	const entityType = useSelector(
		(state: any) => state?.users?.user_details?.entityType
	);
	const userLang = useSelector(
		(state: any) => state?.oneTimeScreen?.language?.name
	);

	useEffect(() => {
		getProductLists();
		if (isPlatform('android') || isPlatform('ios')) {
			notifyHandler('event', eventAttributes('sub_product'));
			notifyHandler('user', userAttributes.attributes);
			const inAppData = {
				tokens: { name: userAttributes },
				customRules: { banerrType: 'sub product' },
			};
			NotifyVisitors.show(inAppData);
		} else {
			(window as any).nv('event', 'sub_product', 10, 1);
			(window as any).nv('user', userId, userAttributes.attributes);
		}
	}, [productSelector?.id]);

	const userAttributes = {
		userId: userId,
		attributes: {
			name: userName,
			id: userId,
			mobile: userMobile,
			user_language: userLang,
			entityType:
				entityType === 'Individual'
					? 'DMA'
					: entityType === 'BTM'
					? 'employee'
					: entityType,
		},
	};

	// useEffect(() => {
	// 	if (isPlatform('android')) {
	// 		if (productFlowSelector?.subProductId === 1) {
	// 			notifyHandler('event', eventAttributes('personal_loan_lenders'));
	// 		} else if (productFlowSelector?.subProductId == 2) {
	// 			notifyHandler('event', eventAttributes('business_loan_lenders'));
	// 		} else if (productFlowSelector?.subProductId == 3) {
	// 			notifyHandler('event', eventAttributes('home_loan_lenders'));
	// 		} else if (productFlowSelector?.subProductId == 4) {
	// 			notifyHandler('event', eventAttributes('loan_property_lenders'));
	// 		} else {
	// 			notifyHandler('event', eventAttributes('sub_product'));
	// 		}
	// 		notifyHandler('user', userAttributes);
	// 	} else {
	// 		console.log(productFlowSelector?.subProductId);

	// 		if (productFlowSelector?.subProductId == 1) {
	// 			(window as any).nv('event', 'personal_loan_lenders', {}, 10, 1);
	// 			console.log('personal');
	// 		} else if (productFlowSelector?.subProductId == 2) {
	// 			(window as any).nv('event', 'business_loan_lenders', {}, 10, 1);
	// 		} else if (productFlowSelector?.subProductId == 3) {
	// 			(window as any).nv('event', 'home_loan_lenders', {}, 10, 1);
	// 		} else if (productFlowSelector?.subProductId == 4) {
	// 			(window as any).nv('event', 'loan_property_lenders', {}, 10, 1);
	// 		} else {
	// 			(window as any).nv('event', 'sub_product', {}, 10, 1);
	// 		}
	// 		(window as any).nv('user', userAttributes);
	// 	}
	// }, []);

	const getProductLists = async () => {
		await dispatch(getProductList())
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					const categoryData = response?.product_list.find(
						({ id }: any) => productSelector?.id === id
					);
					setCategoryList(categoryData);
				}
			});
	};

	const goBackRoute = productSelector?.isHome
		? '/dashboard/home'
		: '/dashboard/products';

	const handleBackBtn = () => {
		const myPromise = new Promise(function (myResolve) {
			setTimeout(function () {
				myResolve(history.push(goBackRoute));
			}, 10);
		});
		myPromise.then(() => {
			dispatch(productFlowData({}));
		});
	};

	// lender modal

	const [pincodeUploadModal, setPincodeUploadModal] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [selectedAlphabet, setSelectedAlphabet] = useState<string | null>(null);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [value, setValue] = useState(productFlowSelector?.searchTerm || '');

	const [normalLenders, setNormalLenders] = useState([]);
	const [topLenders, setTopLenders] = useState<any>([]);
	useEffect(() => {
		if (open === false) {
			setValue('');
		}
	}, [open]);

	const PincodeUploadModal = () => {
		const { t } = useTranslation();
		return (
			<>
				<div className='ion-padding'>
					<h6 className='fs-16 fw-bold'>{t(`Filter By Pincode`)}</h6>

					<div className='mt-3'>
						<CustomInput placeholder={t('Pincode')} />
					</div>
					<div style={{ width: '100%' }}>
						<CustomButton
							onClick={() => setPincodeUploadModal(false)}
							className='w-100'
							fill='outline'
							title={t('Cancel')}
						/>
						<CustomButton
							onClick={() => setPincodeUploadModal(false)}
							className='w-100'
							fill='solid'
							title={t('Check')}
						/>
					</div>
				</div>
			</>
		);
	};

	useEffect(() => {
		dispatch(productFlow({ ...productFlowSelector, searchTerm: value }));
	}, [value]);

	useEffect(() => {
		if (location.pathname !== '/sub-products') {
			return;
		}
		if (open) {
			dispatch(
				getLenderBySubproduct({
					param: `${
						productFlowSelector?.subProductId
							? `?subproduct_id=${productFlowSelector?.subProductId}&lender_show=${productFlowSelector.lender_show}`
							: `?lender_show=${productFlowSelector.lender_show}`
					}${
						productFlowSelector?.subProductId
							? `${
									value
										? `&searchItem=${sanitizeHtml(
												encodeURIComponent(productFlowSelector?.searchTerm)
										  )}`
										: ''
							  }`
							: `${
									value
										? `&searchLender=${sanitizeHtml(
												encodeURIComponent(productFlowSelector?.searchTerm)
										  )}`
										: ''
							  }`
					}`,
				})
			)
				.then(unwrapResult)
				.then((res: any) => {
					if (res?.status) {
						setNormalLenders(res?.normal_lenders || res?.normalLenders);
						setTopLenders(res?.top_lenders || res?.topLenders);
					}
				});
		}
	}, [location.pathname, open]);

	const handleSearch = async (inputVal: string) => {
		if (inputVal.length > 0 && inputVal.length <= 2) return;
		await dispatch(
			getLenderBySubproduct({
				param: `${
					productFlowSelector?.subProductId
						? `?subproduct_id=${productFlowSelector?.subProductId}&lender_show=${productFlowSelector.lender_show}`
						: `?lender_show=${productFlowSelector.lender_show}`
				}${
					productFlowSelector?.subProductId
						? `${
								inputVal
									? `&searchItem=${sanitizeHtml(encodeURIComponent(inputVal))}`
									: ''
						  }`
						: `${
								inputVal
									? `&searchLender=${sanitizeHtml(
											encodeURIComponent(inputVal)
									  )}`
									: ''
						  }`
				}`,
			})
		)
			.then(unwrapResult)
			.then((res: any) => {
				if (res?.status) {
					setNormalLenders(res?.normalLenders);
					setTopLenders(res?.topLenders);
				}
			});
	};

	return (
		<IonPage className='lead-list'>
			<Header
				name={t('Sub Product')}
				backButton={true}
				needArrowOnClick={() => {
					handleBackBtn();
				}}
				needArrow={true}
			/>
			<IonContent>
				<div className='dashboard-container-spacing bg-web h-100 pt-md-4'>
					<div className='bg-web-white border-radius-10 h-100 py-md-4 px-md-3'>
						{/* <IonList> */}
						<IonRow>
							{categoryList?.category?.map((item: CategoryData) => (
								<IonCol
									size='12'
									sizeMd='6'
									sizeLg='4'
									key={item.cat_id}
								>
									<IonItem
										className='border-md-gray cursor-pointer'
										onClick={() => {
											if (isPlatform('android') || isPlatform('ios')) {
												if (item?.cat_id === 1) {
													notifyHandler(
														'event',
														eventAttributes('personal_loan_lenders')
													);
													notifyHandler('user', userAttributes.attributes);

													const inAppData = {
														tokens: { name: userAttributes },
														customRules: {
															banerrType: 'personal loan lenders',
														},
													};
													NotifyVisitors.show(inAppData);
												} else if (item?.cat_id == 2) {
													notifyHandler(
														'event',
														eventAttributes('business_loan_lenders')
													);
													notifyHandler('user', userAttributes.attributes);

													const inAppData = {
														tokens: { name: userAttributes },
														customRules: {
															banerrType: 'business loan lenders',
														},
													};
													NotifyVisitors.show(inAppData);
												} else if (item?.cat_id == 3) {
													notifyHandler(
														'event',
														eventAttributes('home_loan_lenders')
													);
													notifyHandler('user', userAttributes.attributes);

													const inAppData = {
														tokens: { name: userAttributes },
														customRules: {
															banerrType: 'home loan lenders',
														},
													};
													NotifyVisitors.show(inAppData);
												} else if (item?.cat_id == 4) {
													notifyHandler(
														'event',
														eventAttributes('loan_property_lenders')
													);
													notifyHandler('user', userAttributes.attributes);

													const inAppData = {
														tokens: { name: userAttributes },
														customRules: {
															banerrType: 'loan against property',
														},
													};
													NotifyVisitors.show(inAppData);
												}
												notifyHandler('user', userAttributes);
											} else {
												if (item?.cat_id == 1) {
													(window as any).nv(
														'event',
														'personal_loan_lenders',
														10,
														1
													);
												} else if (item?.cat_id == 2) {
													(window as any).nv(
														'event',
														'business_loan_lenders',
														10,
														1
													);
												} else if (item?.cat_id == 3) {
													(window as any).nv(
														'event',
														'home_loan_lenders',
														10,
														1
													);
												} else if (item?.cat_id == 4) {
													(window as any).nv(
														'event',
														'loan_property_lenders',
														10,
														1
													);
												} else {
													(window as any).nv('event', 'sub_product', 10, 1);
												}
												(window as any).nv('user', userAttributes);
											}
											dispatch(
												productFlow({
													...productFlowSelector,
													subProductId: item?.cat_id,
													title: item?.name,
												})
											);
											if (isWeb) {
												setOpen(true);
											} else {
												history.push('/all-lenders');
											}

											// Handle navigation for other items if needed
										}}
										lines='full'
									>
										<div className='d-flex align-items-center'>
											<div
												className={`select-product-sub-icon-${categoryList['icon']} d-flex`}
											>
												<img
													src={item.icon}
													alt='icon'
													width={20}
													height={20}
												/>
											</div>
											<IonLabel className='ms-3'>
												{t(item?.name?.trim())}
											</IonLabel>
										</div>
									</IonItem>
								</IonCol>
							))}
						</IonRow>
						{/* </IonList> */}
					</div>
				</div>
			</IonContent>
			<CustomModal
				id='bulkUploadmodal'
				isOpen={open}
				breakpoints={[0, 0.35, 0.5, 1]}
				modalTitle={t('Update Stage')}
				handleClose={() => setOpen(false)}
				initialBreakpoint={isWeb ? 1 : 1}
				className='side-modal'
				modalContent={
					<div className='wrapper pt-3'>
						<IonItem lines='full'>
							<div className='w-100 text-center mb-3 d-flex justify-content-between align-items-center'>
								<h5 className='mx-0 mb-0 fs-20 fw-600'>
								{productSelector?.id == 1 ?  t(`${productFlowSelector?.title?.trim()}`) + ' ' + t('Lenders') : t(`${productFlowSelector?.title?.trim()}`) + ' ' + t('Insurers') }
								</h5>
								<IonImg
									className='mb-0'
									src='/images/icons/kyc/close-blue-icon.svg'
									alt='backarrow'
									onClick={() => {
										setOpen(false);
									}}
								/>
							</div>
						</IonItem>
						<div className=''>
							{/* Searchbar */}
							<div className='position-relative mx-3 my-3'>
								<CustomSearchbar
									searchIcon='/images/icons/searchbar-icon.svg'
									placeholder={t('Search Lender by “Name”')}
									showClearButton='focus'
									onChange={(value: any) => {
										setValue(value);
										handleSearch(value);
									}}
									value={value}
								/>
							</div>

							{/* Filter by pincode */}
							{/* 	<div className='d-flex flex-row w-100'>
						<CustomButton
							onClick={() => setPincodeUploadModal(true)}
							fill='clear'
							className='fs-14 fw-600 mb-1'
							size={'default'}
						>
							<img
								src='/images/icons/teams/location-blue.svg'
								style={{ width: 20, height: 20 }}
								alt='location'
								className='me-1'
							/>
							{t('Filter by Pincode')}
						</CustomButton>
					</div>
					<Divider className='padding-top-border' /> */}
							<div style={{ overflowY: 'scroll', height: '80vh' }}>
								{/* Top Lenders */}
								{topLenders?.length !== 0 ? (
									<IonGrid>
										{topLenders?.length &&
											topLenders?.find((el: any) => el?.status === '1') && (
												<h6 className='fs-16 fw-bold headings'>
													{t('Top Lenders')}
												</h6>
											)}{' '}
										<IonRow className='d-flex'>
											{topLenders
												?.filter((item: any) =>
													selectedAlphabet
														? item.bank_name.charAt(0).toUpperCase() ===
														  selectedAlphabet
														: true
												)
												.map((item: any, index: number) => {
													return parseInt(item.status) === 1 ? (
														<IonCol
															size='4'
															key={index}
															style={{ zIndex: 2, padding: 'unset' }}
														>
															<IonCardContent
																className='d-flex justify-content-center align-items-center flex-column ion-padding'
																id={item.id}
																style={{ cursor: 'pointer' }}
																onClick={() => {
																	dispatch(
																		productFlow({
																			...productFlowSelector,
																			lender_prod_id: item?.lender_prod_id,
																			lenderId: item?.id || item?.lender_id,
																			bankTitle: item?.name,
																			bankIcon: item?.icon,
																		})
																	);
																	setValue('');
																	setOpen(false);

																	history.push(
																		productFlowSelector.from === 'dashboard'
																			? '/lender-loan-types'
																			: '/lender-product-detail'
																	);
																}}
															>
																<div
																	className='selected-lender-bank-icon-container'
																	style={{
																		width: '50px',
																		height: '50px',
																	}}
																>
																	<div className='selected-lender-bank-icon-border d-flex justify-content-center align-items-center m-auto'>
																		<img
																			src={`${item.icon}`}
																			alt='img'
																			style={{
																				width: '49px',
																				height: '49px',
																				borderRadius: '50%',
																			}}
																		/>
																	</div>
																</div>

																<IonLabel className='text-center mt-2'>
																	{item?.name}
																</IonLabel>
															</IonCardContent>
														</IonCol>
													) : (
														''
													);
												})}
										</IonRow>
									</IonGrid>
								) : (
									''
								)}

								{/* Other Lenders */}
								{normalLenders?.length !== 0 && (
									<IonList>
										{normalLenders?.length !== 0 && (
											<h6 className='fs-16 fw-bold headings'>
												{t('Other Lenders')}
											</h6>
										)}
										{normalLenders?.map((item: HomeLendersData) => {
											return parseInt(item.status) === 1 ? (
												<IonItem
													style={{ cursor: 'pointer' }}
													key={item.id}
													onClick={() => {
														if (item.name === 'Home Loan') {
															history.push('/all-lenders');
														} else {
															dispatch(
																productFlow({
																	...productFlowSelector,
																	lender_prod_id: item?.lender_prod_id,
																	lenderId: item?.id || item?.lender_id,
																	bankTitle: item?.name,
																	bankIcon: item?.icon,
																})
															);
															setOpen(false);
															setValue('');

															history.push(
																productFlowSelector.from === 'dashboard'
																	? '/lender-loan-types'
																	: '/lender-product-detail'
															);
														}
													}}
												>
													<div
														className=' d-flex align-items-center '
														style={{
															padding: '10px 0',
														}}
													>
														<div className={` select-product-sub-icon d-flex`}>
															<IonImg
																//src='/images/icons/leads/sbi-bank.svg'
																alt='icon'
																src={`${item.icon}`}
																style={{
																	width: '40px',
																	height: '40px',
																}}
															/>
															<Divider className='padding-top-border' />
														</div>
														<IonLabel className='ms-3 text-center'>
															{item?.name}
														</IonLabel>
													</div>
												</IonItem>
											) : (
												''
											);
										})}
									</IonList>
								)}
							</div>

							{topLenders?.length === 0 && normalLenders?.length === 0 ? (
								<CustomNoData />
							) : (
								''
							)}
						</div>
					</div>
				}
				needCross={false}
				needArrow={false}
			/>{' '}
			{/* Filter By pincode modal */}
			<CustomModal
				id='pincodeUploadmodal'
				isOpen={pincodeUploadModal}
				initialBreakpoint={0.6}
				handleClose={() => setPincodeUploadModal(false)}
				breakpoints={[0, 0.35, 0.5, 0.75]}
				modalContent={<PincodeUploadModal />}
				needCross={false}
				needArrow={false}
				modalHeight='55%'
			/>
		</IonPage>
	);
};
