/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
	IonCol,
	IonGrid,
	IonImg,
	IonLabel,
	IonRow,
	IonSkeletonText,
} from '@ionic/react';
import { Player } from '@lottiefiles/react-lottie-player';
import { useTranslation } from 'react-i18next';

import { CustomButton } from '../../../../components/common/custom-button/CustomButton';
import { CustomModal } from '../../../../components/common/custom-modal/CustomModal';
import { useDispatch } from 'react-redux';
import {
	apiMyLoyalityLink,
	myLoyality,
} from '../../../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import {
	FormatDate,
	showInAppBrowserView,
} from '../../../../helpers/functions';

export const MyLoyalty: React.FC = () => {
	const dispatch: any = useDispatch();
	const [myLoyalty, setMyLoyalty] = useState<string>('-');
	const [myLoyaltyError, setMyLoyaltyError] = useState<boolean>(false);
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [openModal, setOpenModal] = useState<false | true>(false);

	useEffect(() => {
		getMyLoyalty();
	}, []);

	const getMyLoyalty = async () => {
		setLoading(true);
		await dispatch(myLoyality())
			.then(unwrapResult)
			.then((response: any) => {
				setMyLoyaltyError(!response?.status);
				if (response?.status) {
					setMyLoyalty(response?.data_res?.available_points || '-');
				}
			});
		setTimeout(() => setLoading(false), 800);
	};
	const myLoyaltyLink = async () => {
		await dispatch(apiMyLoyalityLink())
			.then(unwrapResult)
			.then((result: any) => {
				if (result?.status) {
					showInAppBrowserView(result.url);
				}
			});
	};

	return (
		<section className='my-loyalty-rewards px-md-2 pt-4 bg-web-white border-md-radius-10'>
			<IonGrid>
				<IonRow>
					<IonCol
						size='12'
						// className='pb-3'
					>
						<div className=' d-flex justify-content-between'>
							<div className=''>
								<h3 className='fw-600 text-white fs-16 m-0'>
									{loading ? (
										<IonSkeletonText
											animated={true}
											style={{ width: '150px' }}
										></IonSkeletonText>
									) : (
										t('My Rewards')
									)}
								</h3>
							</div>
							<div className=''>
								<h3 className='fw-600 text-white fs-16 m-0 cursor-pointer'>
									{loading ? (
										<IonSkeletonText
											animated={true}
											style={{ width: '150px' }}
										></IonSkeletonText>
									) : (
										<span
											onClick={() => {
												showInAppBrowserView(
													'https://www.andromedaloans.com/rewards-terms-and-condition'
												);
											}}
										>
											{t('T&C*')}
										</span>
									)}
								</h3>
							</div>
							<div
								className='d-none'
								onClick={() => setOpenModal(true)}
							>
								<h4 className='fs-14 m-0 text-white fw-600 d-flex align-items-center'>
									<IonImg
										className='me-2'
										src='/images/icons/small-play-logo-white.svg'
										alt='play'
									/>
									{t('How to Earn')}
								</h4>
							</div>
						</div>
						<p className='fs-14 m-0 text-white'>
							{loading ? (
								<IonSkeletonText
									animated={true}
									style={{ width: '180px' }}
								></IonSkeletonText>
							) : (
								<>
									{t('Last updated on')} {FormatDate(new Date(), 'dd mmm yyyy')}
								</>
							)}
						</p>
						<p className='fs-14 m-0 text-white'>
							{loading ? (
								<IonSkeletonText
									animated={true}
									style={{ width: '180px' }}
								/>
							) : (
								t('Check your loyalty rewards and redeem them soon')
							)}
						</p>
					</IonCol>
					<IonCol size='3'>
						<Player
							autoplay={true}
							loop={true}
							controls={false}
							src='/images/icons/json/LoyaltyRewardsCoin.json'
							// style={{ height: 120 }}
						/>
					</IonCol>
					<IonCol size='9'>
						<div className='text-white fs-12 fw-normal d-flex align-items-end'>
							<div className='d-inline fs-24 fw-bold'>
								{loading ? (
									<IonSkeletonText
										animated={true}
										style={{ width: '40px', height: '40px' }}
									></IonSkeletonText>
								) : (
									<h2 className='m-0'>{myLoyalty}</h2>
								)}
							</div>
							&nbsp;{' '}
							{/* <div className='d-inline fs-12'>
								{loading ? (
									<IonSkeletonText
										className='ms-2'
										animated={true}
										style={{ width: '15px' }}
									></IonSkeletonText>
								) : (
									'PTS'
								)}
							</div>{' '} */}
							<p style={{ marginBottom: '2px' }}>{t('Points Available')}</p>
						</div>
						<div className='d-flex justify-content-between'>
							<CustomButton
								onClick={() => {
									myLoyaltyLink();
								}}
								className='btn-white fs-12 fw-600'
								title={t('View Details')}
								fill='solid'
								size='small'
							/>
							<CustomButton
								className='btn-white fs-12 fw-600'
								fill='outline'
								onClick={getMyLoyalty}
								title={t('Sync Rewards')}
								style={{ color: 'white' }}
								size='small'
							/>
						</div>
					</IonCol>
				</IonRow>
				{myLoyaltyError ? (
					<IonRow>
						<IonCol
							className='px-3'
							style={{
								backgroundColor: 'var(--text-white)',
								borderRadius: '10px',
							}}
						>
							<IonLabel
								className='fs-12'
								style={{ color: 'var(--ion-input-in-valid-color)' }}
							>
								{t('User not found')}
							</IonLabel>
						</IonCol>
					</IonRow>
				) : (
					''
				)}
			</IonGrid>
			<CustomModal
				id='custommodal'
				modalTitle=''
				initialBreakpoint={undefined}
				className='videoplayerpopup vertical-player'
				isOpen={openModal}
				handleClose={() => setOpenModal(false)}
				breakpoints={[]}
				modalContent={
					<iframe
						src='https://player.vimeo.com/video/265111898?h=b1b3ab5aa2'
						width='100%'
						height='100%'
						frameBorder='0'
						allow='autoplay; fullscreen; picture-in-picture'
						allowFullScreen
					></iframe>
				}
				needCross={false}
				needArrow={false}
				needIonContentWraper={false}
			/>
		</section>
	);
};
