/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
	IonAccordion,
	IonAccordionGroup,
	IonCol,
	IonContent,
	IonFooter,
	IonGrid,
	IonItem,
	IonLabel,
	IonList,
	IonPage,
	IonRadio,
	IonRadioGroup,
	IonRow,
	useIonViewDidLeave,
} from '@ionic/react';

import './SelectLeadProduct.scss';

import { Header } from '../../components/layout/header/Header';
import { CustomButton } from '../../components/common/custom-button/CustomButton';
import {
	LEAD_STATUS,
	LeadStatus,
	PRODUCTS_TYPE,
	gradientArray,
} from '../../helpers/constant';
import { useTranslation } from 'react-i18next';
import CustomRadioButton from '../../components/common/custom-radio-button/CustomRadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { getProductList } from '../../store/reducers/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { leadFlow } from '../../store/reducers/lead/leadSlice';
import { CustomInput } from '../../components/common/custom-input/CustomInput';
import { INSURANCE_TYPE, PRODUCTS_ID } from '../insurance/helpers/constant';
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
interface ProductData {
	name: string;
	id: number;
	icon: string;
	category: CategoryData[];
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
interface CategoryData {
	name: string;
	cat_id: number;
	purpose: PurchaseData[];
	icon: string;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
interface PurchaseData {
	name: string;
	purpose_id: number;
}
export const SelectLeadProduct: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation<any>();
	const [productList, setProductList] = useState<any>();
	const leadSelector = useSelector((state: any) => state?.lead?.leadFlowData);

	const [selectedProduct, setSelectedProduct] = useState<any>(
		leadSelector?.productId || undefined
	);
	const [selectedProductName, setSelectedProductName] = useState<any>(
		leadSelector?.productName || undefined
	); // need to setDynamically
	const [selectedSubProductName, setSelectedSubProductName] = useState<any>(
		leadSelector?.subProductName || undefined
	); // need to setDynamically

	
	const [selectedCategory, setSelectedCategory] = useState<any>(
		leadSelector?.subProductId || 0
	);
	const [selectedPurchase, setSelectedPurchase] = useState<any>(
		Number(leadSelector?.purposeId) || undefined
	);

	const [othersText, setOthersText] = useState('');

	const [selectedValue, setSlectedValue] = useState(
		Number(leadSelector?.purposeId) || ''
	);
	const [otherEnable, setOtherEnable] = useState(
		leadSelector?.purposeName?.toLowerCase() === 'others' ? true : false
	);
	const dispatch = useDispatch();
	const dmaSelector = useSelector((state: any) => state?.users);
	const [scoreType, setScoreType] = useState();
	const leadTokenSelector = useSelector(
		(state: any) => state?.users?.userToken?.accessToken
	);
	useEffect(() => {
		getProductLists();
	}, []);

	useEffect(() => {
		if (location.pathname !== '/select-lead-product') {
			return;
		}
		if (leadSelector?.reset_create_lead) {
			resetFormData();
			dispatch(
				leadFlow({
					...leadSelector,
					reset_create_lead: false,
					category_name: selectedSubProductName,
					product_category_id: selectedCategory,
				})
			);
		}
	}, [location.pathname]);
	const [subItems, setSubItem] = useState<any>([]);

	const getProductLists = async () => {
		await dispatch(getProductList())
			.then(unwrapResult)
			.then((response: any) => {
				if (response?.status) {
					setProductList(response?.product_list);

					if (leadSelector?.from === 'customer') {
						setSubItem(
							response?.product_list?.[leadSelector?.productIndex]?.category?.[
								leadSelector?.subProductIndex
							]
						);
						setScoreType(
							response?.product_list?.[leadSelector?.productIndex]?.category?.[
								leadSelector?.subProductIndex
							]?.score_type
						);
					}
				}
			});
	};

	const resetFormData = () => {
		setSelectedProduct(undefined);
		setSelectedProductName(undefined);
		setSelectedCategory(0);
		setSelectedPurchase(undefined);
		setSubItem([]);
		setOthersText('');
		setSlectedValue('');
	};

	const handleSubmit = async () => {

		if(selectedProduct===PRODUCTS_ID.INSURANCE.toString()) {
			
			const props = {
                insuranceTypeId: parseInt(selectedPurchase, 10),
              };

              // console.log("ins type props",props);
        
        
              history.push('/managerSelection', {
                managerProps: props,
              });
        


		}
		else{
			const body = {
				product_id: parseInt(selectedProduct),
				product_category_id: parseInt(selectedCategory),
				category_purpose_id: parseInt(selectedPurchase),
				others: othersText,
				lead_status: leadSelector?.leadId
					? LeadStatus.GENERATED
					: LEAD_STATUS.GENERATION,
				selectedProductName: selectedProductName,
			};
	
	
	
			if (leadTokenSelector) {
				const getProductTypeUrl: any = () => {
					switch (selectedProductName) {
						case PRODUCTS_TYPE.LOAN:
							return '/loan-lead-info-steps';
						case PRODUCTS_TYPE.INSURANCE:
							return '/insurance-lead-info-steps';
						default:
							return '/loan-lead-info-steps';
					}
				};
				history.push(getProductTypeUrl(), {
					selectedProductData: body,
					step: 'step2',
					productType: selectedProductName,
				});
				dispatch(
					leadFlow({
						...leadSelector,
						selectedProductData: body,
						step: 'step1',
						headerTitle: 'Add Lead',
						subHeaderStepsCounter: true,
						leadCurrentStep: 'step1',
						productType: selectedProductName,
						productFor: 'self',
						isLenderSelected: false,
						scoreType,
						isNewLead: true,
						category_name: selectedSubProductName,
						product_category_id: selectedCategory,
					})
				);
			}
			if (location.state?.from) {
				const myPromise = new Promise(function (myResolve) {
					setTimeout(function () {
						myResolve(
							history.push('/add-invite-lead', {
								selectedProductData: body,
								step: 'step1',
								from: location?.state?.from,
							})
						);
					}, 10);
				});
				myPromise.then(() => {
					dispatch(
						leadFlow({
							selectedProductData: body,
							step: 'step1',
							leadCurrentStep: 'step1',
							needArrow: true,
							needCross: false,
							headerTitle: 'Add Lead',
							subHeaderStepsCounter: true,
							// productType: selectedProductName,
							reset_create_lead: true,
							scoreType,
							isNewLead: true,
							category_name: selectedSubProductName,
							product_category_id: selectedCategory,
						})
					);
				});
			} else if (!leadTokenSelector) {
				const getProductTypeUrl: any = () => {
					switch (selectedProductName) {
						case PRODUCTS_TYPE.LOAN:
							return '/loan-lead-info-steps';
						case PRODUCTS_TYPE.INSURANCE:
							return '/insurance-lead-info-steps';
						default:
							return '/loan-lead-info-steps';
					}
				};
				const myPromise = new Promise(function (myResolve) {
					setTimeout(function () {
						myResolve(
							history.push(getProductTypeUrl(), {
								selectedProductData: body,
								step: 'step1',
								productType: selectedProductName,
							})
						);
					}, 10);
				});
				myPromise.then(() => {
					dispatch(
						leadFlow({
							...leadSelector,
							selectedProductData: body,
							step: 'step1',
							needArrow: true,
							needCross: false,
							headerTitle: 'Add Lead',
							subHeaderStepsCounter: true,
							leadCurrentStep: 'step1',
							productType: selectedProductName,
							productFor: 'self',
							scoreType,
							reset_create_lead: true,
							isNewLead: true,
							category_name: selectedSubProductName,
							product_category_id: selectedCategory,
						})
					);
				});
				resetFormData();
			}

		}

		
		
	};
	const onChangeSelectedProducts = (e: any, purposeData: any) => {
		setScoreType(purposeData?.score_type?.toString());
		setSlectedValue(e.detail.value);
		setSelectedPurchase(e?.detail.value);
		let getOthersSelectedData = [];
		if (purposeData?.purpose) {
			getOthersSelectedData = purposeData?.purpose?.filter((x: any) => {
				return (
					x.purpose_id == e.detail.value && x.name?.toLowerCase() == 'others'
				);
			});
		}

		if (getOthersSelectedData.length) {
			setOtherEnable(true);
		} else {
			setOtherEnable(false);
			setOthersText('');
		}
		dispatch(
			leadFlow({
				...leadSelector,
			})
		);
	};
	return (
		<IonPage id='main-content'>
			<Header
				name={t('Select Product')}
				backButton={leadSelector?.from === 'customer' ? false : true}
				needArrow={true}
				backButtonText=''
				needArrowOnClick={() => {
					if (leadSelector?.from === 'nimbuss') {
						history.push(
							'/lead-details/' +
								leadSelector.leadId +
								'/' +
								dmaSelector?.user_details?.access_token
						);
					} else {
						history.push('/dashboard/lead');
					}
					resetFormData();
				}}
			/>
			<IonContent scrollY={false}>
				<div className='select-product dashboard-container-spacing bg-web h-100'>
					<IonGrid
						style={{
							borderTop: '1px solid #00000029',
							overflowY: 'auto',
							height: '100%',
							background: 'white',
						}}
					>
						<IonRow>
							{productList && productList.length > 0
								? productList?.map((item: ProductData, index: number) => {
										return (
											<IonCol
												size='6'
												sizeMd='4'
												sizeXl='3'
												className={`product-item ${
													index % 2 == 0 ? 'd-flex flex-column w-100' : ''
												} `}
												// style={{
												// 	zIndex: -1,
												// }}
												key={item?.id}
											>
												<IonList
													onClick={() => {
														setSelectedProductName(item.name);
														setSelectedProduct(item?.id?.toString());
														setSlectedValue('');
														setSubItem([]);
													}}
												>
													<div
														className={`cursor-pointer product-list text-center select-product-${
															item.icon
														} ${
															selectedProduct === item?.id.toString()
																? `select-product-${item.icon}-active`
																: ''
														}`}
														style={{ background: gradientArray[index % 4] }}
													>
														<img
															alt={item.name}
															src={item.icon}
															className='product-img mt-3'
														/>
														<div className='font-semibold fs-16 my-1'>
															{t(item.name)}
														</div>
														{item?.category.length > 0 ? (
															<div
																className='font-regular fs-12'
																style={{
																	color: '#A0A4B3',
																}}
															>
																{item?.category.length} {t('Categories')}
															</div>
														) : (
															''
														)}
													</div>
												</IonList>
												<div className='d-block d-md-none'>
													<div
														className={`${
															selectedProduct === item?.id.toString() &&
															item?.category.length > 0
																? 'my-1'
																: 'd-none'
														} subproduct`}
													>
														<IonAccordionGroup
															style={{
																width: 'calc(100vw - 56px)',
																margin: '0px 12px',
																zIndex: 1111,
															}}
															value={
																selectedSubProductName === undefined
																	? ''
																	: selectedSubProductName
															}
														>
															{item?.category.map(
																(subitem: CategoryData, index: any) => {
																	return (
																		<IonAccordion
																			value={subitem.name}
																			key={subitem.name}
																			onClick={() => {
																				
																				setSelectedSubProductName(
																					subitem?.name
																				);
																				setOthersText('');
																				setSelectedCategory(
																					subitem?.cat_id?.toString()
																				);
																			}}
																			style={{
																				zIndex: 1111,
																			}}
																		>
																			<IonItem
																				slot='header'
																				color='light'
																				style={{
																					border:
																						index === item?.category.length - 1
																							? '1px solid #dbdbdb'
																							: '',
																				}}
																			>
																				<div
																					className={`select-product-sub-icon-${item.icon} d-flex me-3`}
																				>
																					<img
																						src={subitem.icon}
																						alt='icon'
																						width={20}
																						height={20}
																					/>
																				</div>
																				<IonLabel>
																					{t(subitem.name?.trim())}
																				</IonLabel>
																			</IonItem>
																			<div
																				className='ion-padding border-1 border-light-gray border-style'
																				slot='content'
																			>
																				<IonRadioGroup
																					value={selectedValue}
																					className='w-100'
																					onIonChange={(e: any) => {
																						onChangeSelectedProducts(
																							e,
																							subitem
																						);
																					}}
																				>
																					{subitem?.purpose.map(
																						({ name, purpose_id }) => {
																							return (
																								<React.Fragment
																									key={`purpose-${name}`}
																								>
																									<CustomRadioButton
																										value={purpose_id}
																										labelPlacement='end'
																										justify='start'
																										ionRadioClassName='fs-16 w-100 py-1'
																										label={t(name?.trim())}
																									/>
																									{selectedValue ===
																										purpose_id &&
																									name.toLowerCase() ===
																										'others' ? (
																										<input
																											type='text'
																											className='p-2 mt-2'
																											placeholder={t(
																												'Others'
																											).toString()}
																											onChange={(e) => {
																												if (
																													e.target.value
																														?.length <= 20
																												) {
																													setOthersText(
																														e.target.value
																													);
																												}
																											}}
																											style={{ width: '100%' }}
																											value={othersText}
																											maxLength={20}
																										/>
																									) : (
																										<br />
																									)}
																									{/* <IonRadio
																							value={purpose_id}
																							labelPlacement='end'
																							justify='start'
																							className='fs-16 w-100'
																						>
																							{name}
																						</IonRadio> */}
																								</React.Fragment>
																							);
																						}
																					)}
																				</IonRadioGroup>
																			</div>
																		</IonAccordion>
																	);
																}
															)}
														</IonAccordionGroup>
													</div>
												</div>

												{/* </div> */}
											</IonCol>
										);
								  })
								: []}
						</IonRow>
						<div className='d-none d-md-block'>
							{productList && productList.length > 0
								? productList?.map((item: ProductData, index: number) => {
										return (
											<IonAccordionGroup
												key={index}
												value={selectedSubProductName}
											>
												<div
													className={`${
														selectedProduct === item?.id.toString() &&
														item?.category.length > 0
															? 'my-1'
															: 'd-none'
													} subproduct px-3`}
													// style={{
													// 	width: 'calc(100vw - 42px)',
													// 	zIndex: 1111,
													// }}
												>
													<IonRow>
														{item?.category.map((subitem: CategoryData) => {
															return (
																<IonCol
																	size='12'
																	sizeMd='4'
																	// sizeXl='3'
																>
																	<IonAccordion
																		value={subitem.name}
																		key={subitem.name}
																		onClick={() => {
																			setSelectedSubProductName(
																				subitem?.name
																			);
																			setOthersText('');
																			setSubItem(
																				subItems?.purpose?.length &&
																					selectedCategory ===
																						subitem?.cat_id.toString()
																					? []
																					: subitem
																			);

																			setSelectedCategory(
																				subitem?.cat_id?.toString()
																			);
																		}}
																	>
																		<IonItem
																			slot='header'
																			color='light'
																			className='w-100'
																		>
																			<div
																				className={`select-product-sub-icon-${item.icon} d-flex me-3`}
																			>
																				<img
																					src={subitem.icon}
																					alt='icon'
																					width={20}
																					height={20}
																				/>
																			</div>
																			<IonLabel>
																				{t(subitem.name?.trim())}
																			</IonLabel>
																		</IonItem>
																		{/* <div
																		className='d-block d-md-none ion-padding border-1 border-light-gray border-style'
																		slot='content'
																	>
																		<IonRadioGroup
																			value={selectedValue}
																			className='w-100'
																			onIonChange={(e) => {
																				setOthersText('');
																				setSlectedValue(e.detail.value);
																				setSelectedPurchase(e?.detail.value);
																			}}
																		>
																			<IonRow>
																				{subitem?.purpose?.map(
																					({ name, purpose_id }: any) => {
																						return (
																							// <IonCol
																							// 	size='12'
																							// 	sizeMd='4'
																							// 	sizeXl='3'
																							// >
																							<div className='my-2 w-100'>
																								<CustomRadioButton
																									value={purpose_id}
																									labelPlacement='end'
																									justify='start'
																									ionRadioClassName='fs-16 w-100'
																									label={name}
																								/>
																								{parseInt(selectedValue) ===
																									purpose_id &&
																								name.toLowerCase() ===
																									'others' ? (
																									<input
																										type='text'
																										placeholder={`Others`}
																										onChange={(e) => {
																											setOthersText(
																												e.target.value
																											);
																										}}
																										style={{ width: '100%' }}
																										value={othersText}
																									/>
																								) : (
																									<br />
																								)}
																							</div>
																							// </IonCol>
																						);
																					}
																				)}
																			</IonRow>
																		</IonRadioGroup>
																	</div> */}
																	</IonAccordion>
																</IonCol>
															);
														})}
													</IonRow>
													{subItems?.purpose?.length > 0 && (
														<div
															className='ion-padding d-none d-md-block border-1 border-light-gray border-style'
															slot='content'
														>
															<IonRadioGroup
																value={selectedValue}
																className='w-100'
																onIonChange={(e: any) => {
																	onChangeSelectedProducts(e, subItems);
																}}
																// onIonChange={(e) => {
																// 	setOthersText('');
																// 	setSlectedValue(e.detail.value);
																// 	setSelectedPurchase(e?.detail.value);
																// 	let getOthersSelectedData = [];
																// 	if (subItems?.purpose) {
																// 		getOthersSelectedData =
																// 			subItems?.purpose?.filter((x: any) => {
																// 				return (
																// 					x.purpose_id == e.detail.value &&
																// 					x.name?.toLowerCase() == 'others'
																// 				);
																// 			});
																// 	}
																// 	if (getOthersSelectedData.length) {
																// 		setOtherEnable(true);
																// 	} else {
																// 		setOtherEnable(false);
																// 		setOthersText('');
																// 	}
																// }}
															>
																<IonRow>
																	{subItems?.purpose?.map(
																		({ name, purpose_id }: any) => {
																			return (
																				<IonCol
																					size='12'
																					sizeMd='4'
																					sizeXl='3'
																				>
																					<div className='my-2 w-100'>
																						<CustomRadioButton
																							value={purpose_id}
																							labelPlacement='end'
																							justify='start'
																							ionRadioClassName='fs-16 w-100'
																							label={t(name?.trim())}
																						/>
																						{selectedValue === purpose_id &&
																						name.toLowerCase() === 'others' ? (
																							<input
																								type='text'
																								placeholder={t(
																									`Others`
																								).toString()}
																								className='p-2 mt-2'
																								onChange={(e) => {
																									if (
																										e.target.value?.length <= 20
																									) {
																										setOthersText(
																											e.target.value
																										);
																									}
																								}}
																								style={{ width: '100%' }}
																								value={othersText}
																								maxLength={20}
																							/>
																						) : (
																							<br />
																						)}
																					</div>
																				</IonCol>
																			);
																		}
																	)}
																</IonRow>
															</IonRadioGroup>
														</div>
													)}
												</div>
											</IonAccordionGroup>
										);
								  })
								: []}
						</div>
					</IonGrid>
				</div>
			</IonContent>
			<IonFooter class='button-size-md footer-button-container bg-web-white'>
				<div className='p-2 p-md-0 py-md-2'>
					<CustomButton
						expand='block'
						className={'select-product-proceed-button button-width me-md-0'}
						disabled={
							(['loans', 'loan'].includes(selectedProductName?.toLowerCase())
						||
						[INSURANCE_TYPE.LIFE_TERM_LIFE_INSURANCE,
							INSURANCE_TYPE.LIFE_ENDOWMENT,
							INSURANCE_TYPE.LIFE_UNIT_LINKED,
							INSURANCE_TYPE.LIFE_MONEYBACK,
							INSURANCE_TYPE.LIFE_GUARNTEED_RETURN,
							INSURANCE_TYPE.LIFE_PENSION,
							INSURANCE_TYPE.HEALTH_INDIVIDUAL,
							INSURANCE_TYPE.GENERAL_VEHICLE,
						].includes(parseInt(selectedPurchase))
						)
								? (!otherEnable && !selectedValue) ||
								  (otherEnable && !othersText.trim())
								: true
						}
						onClick={handleSubmit}
						title={t('Proceed')}
					/>
				</div>
			</IonFooter>
		</IonPage>
	);
};
