import * as yup from 'yup';
import { PolicyIssuedProps } from './policy_issued';




export const policy_issued_schema: any = (props: PolicyIssuedProps) => {

  console.log(props)

  return yup.object().shape({
    // policyIdNumber: props.hidePolicyIdNumber
    //   ? yup.string().notRequired()
    //   : yup.string().required('Policy ID Number is required'),
    policyNumber: props.hidePolicyIdNumber
      ? yup.string().notRequired()
      : yup.string().required('Policy ID Number is required'),  
    policyBondReceivedDate: props.hidePolicyBondReceivedDate
      ? yup.date().notRequired().max(new Date(), "Date cannot be in the future")
      : yup.date().required('Policy Bond Received Date is required').max(new Date(), "Date cannot be in the future"),
    issueDate: props.hidePolicyIssuanceDate
      ? yup.date().notRequired().max(new Date(), "Date cannot be in the future")
      : yup.date().required('Policy Issuance Date is required').max(new Date(), "Date cannot be in the future"),
    sumAssured: props.hideTotalSumAssured
      ? yup.number().notRequired()
      : yup.number().typeError('Total Sum Assured must be a number').required('Total Sum Assured is required'),
    policyTerm: props.hidePolicyTerm
      ? yup.number().notRequired()
      : yup.number().min(1, 'Policy Term must be at least 1').max(100, 'Policy Term must be at most 100').required('Policy Term is required'),
    premiumPaymentTerm: props.hidePremiumPaymentTerm
      ? yup.number().notRequired()
      : yup.number().min(1, 'Premium Payment Term must be at least 1').max(100, 'Premium Payment Term must be at most 100').required('Premium Payment Term is required'),
    postponementDate: props.hidePostponementDate
      ? yup.date().notRequired()
      : yup.date().required('Postponement Date is required'),
    premiumFrequency: props.hidePremiumFrequency
      ? yup.string().notRequired()
      : yup.string().required('Premium Frequency is required'),
    premiumAmount: props.hidePremiumAmount
      ? yup.number().notRequired()
      : yup.number().typeError('Premium Amount must be a number').required('Premium Amount is required'),
    copsExecId: props.hideCopsExecutive
      ? yup.number().notRequired()
      : yup.number().required('Mandatory Field'),
    closeReason: props.hideCloseReason
      ? yup.string().notRequired()
      : yup.string().required('Reason required'),
    plvcRejectedReason: props.hidePLVCRejectedReason
      ? yup.string().notRequired()
      : yup.string().required('Reason required'),
    remarks: props.hideRemarks
      ? yup.string().notRequired()
      : yup.string().notRequired(),
    insurerApplicationNo: props.hideInsurerApplicationNo
      ? yup.string().notRequired()
      : yup.string().required('Mandatory field'),
      paymentReciept: props.hidePaymentReceipt 
      ? yup.mixed().notRequired()
        : yup
          .object()
          .test(
            'content',
            'Payment Receipt is required',
            value =>
              !props.hidePaymentReceipt && value &&
              (value as any).content !== null &&
              (value as any).content !== undefined &&
              (value as any).content !== ''
          ),
        paymentVideo: props.hidePaymentVideo
        ? yup.mixed().notRequired()
        : yup
          .object()
          .test(
            'content',
            'Video is required',
            value =>
              !props.hidePaymentVideo && value &&
              (value as any).content !== null &&
              (value as any).content !== undefined &&
              (value as any).content !== ''
          ),  

    // paymentReceipt: props.hidePaymentReceipt
    //   ? yup.mixed().notRequired()
    //   : yup
    //     .mixed()
    //     .test(
    //       'content',
    //       'Payment Receipt is required',
    //       value =>
    //         !props.hidePaymentReceipt && value &&
    //         (value as any).content !== null &&
    //         (value as any).content !== undefined &&
    //         (value as any).content !== ''
    //     ),
    // paymentVideo: props.hidePaymentVideo
    //   ? yup.mixed().notRequired()
    //   : yup
    //     .mixed()
    //     .test(
    //       'content',
    //       'Video is required',
    //       value =>
    //         !props.hidePaymentVideo && value &&
    //         (value as any).content !== null &&
    //         (value as any).content !== undefined &&
    //         (value as any).content !== ''
    //     ),
  });
};



