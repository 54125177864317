import * as Yup from 'yup';

export const HlFormInfo: any = Yup.object({
    pincode: Yup.string()
        .trim()
        .required('Pincode Number is Required.')
        .matches(/^\d{6}$/, 'Pincode should be 6 Digits')
        .length(6, 'Pincode should be 6 Digits'),
    // state: Yup.string().required('State is Required'),
    // district: Yup.string().required('District is Required'),
    // branch: Yup.string().required('Branch is required'),
    product_type: Yup.string().required('Product Type is required'),
    sub_product: Yup.string().required('Sub Product is required'),
    purpose_of_loan: Yup.string().required('Loan Purpose is required'),
    employment_status: Yup.string().required('Employment Status is required'),
    is_property_identified: Yup.string().required('Property Identified is required')
});

export const HLFields = [
    {
        name: 'pincode',
        type: 'text',
        placeholder: 'Pincode *',
    },
    // {
    //     name: 'state',
    //     type: 'dropdown',
    //     placeholder: 'State *',
    // },
    // {
    //     name: 'district',
    //     type: 'dropdown',
    //     placeholder: 'District *',
    // },
    // {
    //     name: 'branch',
    //     type: 'dropdown',
    //     placeholder: 'Branch *',
    // },
    {
        name: 'product_type',
        type: 'dropdown',
        placeholder: 'Product Type *',
    },
    {
        name: 'sub_product',
        type: 'dropdown',
        placeholder: 'Sub Product *',
    },
    {
        name: 'purpose_of_loan',
        type: 'dropdown',
        placeholder: 'Loan Purpose *',
    },
    {
        name: 'employment_status',
        type: 'dropdown',
        placeholder: 'Employment Status *',
    },
    {
        name: 'is_property_identified',
        type: 'dropdown',
        placeholder: 'Property Identified *',
    },    
];